import React, { useEffect } from 'react';
import "../../../library/scss/rsuite-default.css";
import { makeStyles } from "@mui/styles";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Typography,
    FormLabel,
    Button, TextField
} from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { connect } from 'react-redux';
import BulletChart from '../Charts/BulletChart';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import clsx from "clsx";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { geographicalIncentive, getNewCustomers, loadAccrualVsPaymentGraph, allowedApps, dashboardAnalyticsNames, loadValuesBasedOnAppTypeAndField } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        padding: 15,
        margin: "5px 0px 16px 0px",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
    },

    select: {
        width: "100%",
        color: "#1675e0",
    },
    selectedItem: {
        borderRadius: 5,
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 34,
        paddingBottom: 5,
    },
    button: {
        marginRight: 10,
    },
    fontSetting: {
        fontSize: 18,
        color: "#333333",
    },
    spinner: {
        '& svg circle': {
            stroke: '#045FB4',
        }
    },
}));


const NewCustomerMaster = props => {
    const classes = useStyles();
    const [customerGraphData, setCustomerGraphData] = React.useState([]);
    const [maxData, setMaxData] = React.useState({});
    const [sourceDataType, setSourceDataType] = React.useState("Direct - Sales Data");
    const [endDate, setEndDate] = React.useState(null);
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([]);
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [allowedApps, setAllowedApps] = React.useState([]);
    const masterDataArray = ["Customer Master", "Employee Master", "Material Master", 'Supplier Master'];

    useEffect(() => {
        props.allowedAnalyticsApps(sessionStorage.getItem("application"));
    }, []);

    useEffect(() => {
        props.allowedApps();
    }, []);

    useEffect(() => {
        if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
            const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
                item.analytic_name === "New Master Data Incentives"
            );
            if (postingsSummaryAnalytic && postingsSummaryAnalytic.filters) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
            }
        }
    }, [props.allowedAnalyticsAppsData])

    useEffect(() => {
        if (allowedTilesFilter.length > 0) {
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
            setSourceDataType(allowedTilesFilter[0].master_data_type);
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        if (startDate && endDate && sourceDataType) {
            props.getNewCust({
                start_date: startDate,
                end_date: endDate,
                master_data_type: sourceDataType
            });
        }
    }, [allowedTilesFilter]);
    // useEffect(() => {
    //     // if (props.page !== "dashboard")
    //     if (sourceDataType && startDate && endDate) {
    //         props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, startDate, endDate);
    //     }
    // }, [allowedTilesFilter]);
    useEffect(() => {
        if (props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData]);

    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    };

    useEffect(() => {
        if (
            props.sourceDataTypeValue &&
            Object.keys(props.sourceDataTypeValue).length > 0
        ) {
            setSourceDataTypeDataFromAPI(
                props.sourceDataTypeValue["drop_down_value_keys"]
            );
        }
    }, [props.sourceDataTypeValue]);
    useEffect(() => {
        props.loadSourceDataType(sessionStorage.getItem("application"), "source_data_type");
    }, []);


    const handleStartDate = (e) => {
        setStartDate(e);
    }

    const handleEndDate = (e) => {
        setEndDate(e);
    }

    const handleRun = () => {
        if (startDate && endDate && sourceDataType) {
            props.getNewCust({
                start_date: Moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
                end_date: Moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
                master_data_type: sourceDataType
            });
        }
    }

    // useEffect(() => {
    //     // var data = {
    //     //   start_date: Moment.utc()
    //     //     .subtract(1, "months")
    //     //     .startOf("month")
    //     //     .format("MM/DD/YYYY"),
    //     //   end_date: Moment.utc()
    //     //     .endOf("month")
    //     //     .format("MM/DD/YYYY"),
    //     // };
    //     var data = {
    //         'start_date': startDate,
    //         'end_date': endDate,
    //         'master_data_type': sourceDataType
    //     }
    //     props.getNewCust(data)
    // }, [sourceDataType])



    useEffect(() => {
        if (props.newCustomer && Object.values(props.newCustomer).length > 0) {
            setCustomerGraphData(props.newCustomer);
            var max = 100;
            var maxValue = {
                'countMax': props.newCustomer.Count ? parseInt(props.newCustomer.Count) + 100 : 100,
                'salesMax': props.newCustomer.Sales ? (parseInt(props.newCustomer.Sales) + 100) : 100,
                'accrualMax': props.newCustomer.Accrual ? (parseInt(props.newCustomer.Accrual) + 100) : 100,
                'paymentMax': props.newCustomer.Payment ? (parseInt(props.newCustomer.Payment) + 100) : 100,
            };
            setMaxData(maxValue);
        }
    }, [props.newCustomer]);

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid
                    container
                    className={classes.root}
                    style={{ justifyContent: "space-between" }}
                >
                    {props.page !== "dashboard" && (
                        <Grid
                            container
                            style={{
                                margin: 10,
                                padding: 5,
                                border: "1px solid #E6E6E6",
                                borderRadius: 5,
                            }}
                        >
                            <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography classes={{ root: classes.fontSetting }}>
                                        Master Data Type
                                    </Typography>
                                    <Select
                                        value={sourceDataType}
                                        onChange={handleSourceDataType}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem,
                                        }}
                                    >
                                        {masterDataArray && masterDataArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>


                                </div>
                            </Grid>
                            <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        height: 37,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={startDate}
                                                onChange={handleStartDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        height: 37,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={endDate}
                                                onChange={handleEndDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={startDate ? startDate : false}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleRun}
                                    twoline="true"
                                    disabled={(!startDate || !endDate || !sourceDataType) ? true : false}
                                >
                                    <DirectionsRunIcon />
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
                {
                    customerGraphData && Object.values(customerGraphData).length > 0 ?
                        <div><Typography variant="h3" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" > New Master Data Incentives </Typography>
                            <BulletChart bulletChartData1={customerGraphData.Count}
                                bulletChartData2={customerGraphData.Sales}
                                bulletChartData3={customerGraphData.Accrual}
                                bulletChartData4={customerGraphData.Payment}
                                maxData={maxData}
                                page={"New Master Data Incentive"}
                            />
                        </div>
                        :
                        <div className={classes.emptyContainer}> No data</div>
                }
            </div>
        </LoadingOverlay >
    );
};

const mapStateToProps = state => {
    return {
        newCustomer: state.initialData.newCustomerData,
        allowedAppsData: state.initialData.allowedApps,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getNewCust: (data) => dispatch(getNewCustomers(data)),
        loadAccrualVsPaymentGraph: (applicationType, sourceDataType, startDate, endDate) => dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType, startDate, endDate)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps()),
        allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerMaster);
