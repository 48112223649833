import React from 'react';
import { connect } from 'react-redux';
import { TransactionDataBatchJobSetupTable } from './components';

const TransactionDataBatchJobSetup = () => {
    return (
        <div>
            <TransactionDataBatchJobSetupTable />
        </div >
    );
};
export default connect(null,null)(TransactionDataBatchJobSetup);