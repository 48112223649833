import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { promPlanSalsIncentives } from '../../../redux/actions';
import GeographicalChart from  '../../Dashboard/components/DBPromoPlan/GeographicalChart' ;
import SalesIncentives from '../../Dashboard/components/DBPromoPlan/SalesIncentives';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 16,
        [theme.breakpoints.up('md')]: {
            marginTop: 0
        }
    }
}));
const MarginAnalytics = props => {
    const classes = useStyles();
    useEffect(() => {
        var ed = Moment().subtract(0, 'months').endOf('month').format('MM/DD/YYYY');
        var sd = Moment().subtract(5, 'months').startOf('month').format('MM/DD/YYYY');
        var formData = {
            "application_type": 'Customer Rebate',
            "data_source_type": 'Direct - Sales Data',
            "posting_type": 'payment',
            "start_date": sd,
            "end_date": ed,
            "periodicity": 'month',
            "key_figure_name": 'net_value',
        }
        props.promPlanSalsIncentives(formData);
    }, []);

    return (
        <div style={{ height: '100%' }}>
            <>
            <Grid
                container
                className={classes.container}
                spacing={2}
            >
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <SalesIncentives data={props.promPlanningSalesInc} />
                </Grid>
            </Grid>
            <Grid
                container
                className={classes.container}
                spacing={2}
            >
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <GeographicalChart />
                </Grid>
            </Grid>
            </>
        </div >
    );

};

const mapStateToProps = state => {
    return {
        promPlanningSalesInc: state.initialData.promPlanningSalesInc
    }
};

const mapDispatchToProps = dispatch => {
    return {
        promPlanSalsIncentives: (data) => dispatch(promPlanSalsIncentives(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarginAnalytics);