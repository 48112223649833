import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { getBudgetingConfig } from '../../redux/actions';
import { MultiSelectDropdown } from '../../components/Inputs';
import { addPromotionMappingData } from '../../redux/actions/Promotion/AuthAction';
import AutoCompleteMultiSelect from '../../components/Inputs/AutoCompleteMultiSelect';
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const PromotionConfigAdd = props => {
    const classes = useStyles();
    const [promoConfigData, setPromoConfigData] = React.useState([]);
    const [promoType, setPromoType] = React.useState();
    const [usageCode, setUsageCode] = React.useState();
    const [salesProgramCode, setSalesProgarmCode] = React.useState([]);
    const [validationCode, setValidationCode] = React.useState([]);

    useEffect(() => {
        props.getConfigData()
    }, []);

    useEffect(() => {
        if (props.configData) {
            setPromoConfigData(props.configData)
        }
    }, [props.configData]);

    function handleUsageCode(e) {
        setUsageCode(e.target.value);
        setSalesProgarmCode([]);
    }

    function handleSalesProgramCode(value) {
        if (value && value.length > 0)
            setSalesProgarmCode(value);
        else
            setSalesProgarmCode([]);
    }

    function handleValidationCode(value) {
        if (value && value.length > 0)
            setValidationCode(value);
        else
            setValidationCode([]);
    }

    function handlePromoType(e) {
        setPromoType(e.target.value);
        setUsageCode('');
        setSalesProgarmCode([]);
        setValidationCode([]);
    }

    function handleOnClear() {
        setUsageCode('');
        setPromoType('');
        setSalesProgarmCode([]);
        setValidationCode([]);
    }

    const handleSubmit = () => {
        var formData = {
            promotion_type: promoType,
            usage_code: usageCode,
            sales_program_code: salesProgramCode,
            validation: validationCode,
        };

        props.onSubmit(formData);
    };


    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/promo-config'
                >
                    Promotion Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Promotion Mapping </Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card
                    style={{ marginTop: 15 }}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Promotion Type
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={promoType || ''}
                                        onChange={handlePromoType}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{
                                            height: 42,
                                            marginTop: 10,
                                        }}
                                    >
                                        <MenuItem value={' '} key={' '} >
                                            {' '}
                                        </MenuItem>
                                        {promoConfigData && Object.keys(promoConfigData).length > 0 && Object.keys(promoConfigData)
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}

                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Usage Code
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={usageCode || ''}
                                        onChange={handleUsageCode}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{
                                            height: 42,
                                            marginTop: 10,
                                        }}
                                    >
                                        <MenuItem value={' '} key={' '} >
                                            {' '}
                                        </MenuItem>
                                        {promoConfigData && promoType && promoConfigData[promoType] &&
                                            Object.keys(promoConfigData[promoType])
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{ marginTop: 15 }}
                                >
                                    {/* <FormLabel classes={{ root: classes.formLabel }} >
                                        Sales Program Code
                                    </FormLabel>
                                    <MultiSelectDropdown
                                        btn={true}
                                        onChange={handleSalesProgramCode}
                                        data={salesProgramCode}
                                        listArray={promoConfigData && promoType && usageCode && promoConfigData[promoType] && promoConfigData[promoType][usageCode] && promoConfigData[promoType][usageCode]['sales_program_code'] ?
                                            promoConfigData[promoType][usageCode]['sales_program_code'] : []}
                                        style={{ marginTop: -10 }} /> */}
                                    <AutoCompleteMultiSelect
                                        multiple
                                        heading={'Sales Program Code'}
                                        data={promoConfigData && promoType && usageCode && promoConfigData[promoType] && promoConfigData[promoType][usageCode] && promoConfigData[promoType][usageCode]['sales_program_code'] ? promoConfigData[promoType][usageCode]['sales_program_code'] : []}
                                        id={'sales_program_code'}
                                        onChange={handleSalesProgramCode}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{ marginTop: 15 }}
                                >
                                    {/* <FormLabel classes={{ root: classes.formLabel }} >
                                        Validation Code
                                    </FormLabel>
                                    <MultiSelectDropdown
                                        btn={true}
                                        onChange={handleValidationCode}
                                        data={validationCode}
                                        listArray={promoConfigData && promoType && usageCode && promoConfigData[promoType] && promoConfigData[promoType][usageCode] &&
                                            promoConfigData[promoType][usageCode]['validation'] && promoConfigData[promoType][usageCode]['validation']}
                                        style={{ marginTop: -10 }} /> */}
                                    <AutoCompleteMultiSelect
                                        multiple
                                        heading={'Validation Code'}
                                        data={promoConfigData && promoType && usageCode && promoConfigData[promoType] && promoConfigData[promoType][usageCode] && promoConfigData[promoType][usageCode]['validation'] ? promoConfigData[promoType][usageCode]['validation'] : []}
                                        id={'validation_code'}
                                        onChange={handleValidationCode}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.submitContainer}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                        Clear
                    </Button>
                    <Button color="primary" variant="contained" className={classes.button} onClick={handleSubmit} disabled={
                        promoType && usageCode ? false : true}>
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig('promotionType')),
        onSubmit: (data) => dispatch(addPromotionMappingData(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PromotionConfigAdd);