import React, { useEffect } from 'react';
import { makeStyles, useTheme } from "@mui/styles";
import {
    Select,
    MenuItem,
    FormControl,
    FormLabel,
    Chip,
    Input,
} from '@mui/material';
import { Grid } from '@mui/material';
import ChipInput from "material-ui-chip-input";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '5px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    rootContainer: {
        border: "1px solid",
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 35,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: "#E1E4F3",
        "&&:hover": {
            backgroundColor: "#E1E4F3",
            color: theme.palette.black,
        },
        fontFamily: "ProximaNova",
        padding: 0,
        border: 'none'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: "ProximaNova",
        //fontSize: 13,
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
    },
    chipContainer: {
        display: "flex",
        alignItems: "center",
        overflowX: "overlay",
        flexFlow: "unset",
    },
}));

const ChipDropdown = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [data, setData] = React.useState([]);
    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
    }, [props.data])
    const handleAdd = (value, type) => {
        var temp = []
        if (type === "pastedItem") {
            value.map((arg) => {
                if (arg) temp.push(arg.trim());
            });
            setData([...data, ...temp])
            props.onChange([...data, ...temp])
        }
        else {
            setData([...data, value])
            props.onChange([...data, value])
        }
    };
    const handleDelete = (value) => {
        setData(itemX => itemX.filter(item => item != value))
        props.onChange(data.filter(item => item != value))
    };
    return (
        <div>
            <Grid container className={classes.root} >
                <Grid
                    item
                    md={12}
                    xs={12}
                >
                    {props.heading && (
                        <FormLabel
                            required={props.required ? props.required : false}
                            style={{ fontSize: theme.typography.h3.fontSize }}
                        >{props.heading}
                        </FormLabel>
                    )}
                    <ChipInput
                        classes={{
                            root: classes.rootContainer,
                            chip: classes.chip,
                            input: classes.input,
                            inputRoot: classes.inputRoot,
                            label: classes.chipLabel,
                            chipContainer: classes.chipContainer,
                        }}
                        disabled={props.disabled ? props.disabled : false}
                        value={data ? data : []}
                        onAdd={(e) =>
                            handleAdd(e)
                        }
                        onDelete={(e) => handleDelete(e)}
                        onPaste={(event) => {
                            const clipboardText = event.clipboardData
                                .getData("Text")
                                .split("\n");

                            event.preventDefault();
                            handleAdd(clipboardText, 'pastedItem');
                            if (props.onPaste) {
                                props.onPaste(event);
                            }
                        }}
                        disableUnderline={true}
                        blurBehavior="add"
                    />
                </Grid>
            </Grid >
        </div>
    );

};

export default ChipDropdown;