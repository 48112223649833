import React from 'react';
import { Typography, Grid } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import ClaimConfigurationTab from './ClaimConfigurationTab';
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 23,
        marginTop: -5
    },
    dropdownContainer: {
        padding: 0,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10
        }
    }
}));
const ClaimConfigurationMain = props => {
    const classes = useStyles();
    const { className } = props;
    return (
        <div>
            <div
                className={clsx(classes.root, className)}>
                <ClaimConfigurationTab />
            </div>
        </div >
    );
};
export default ClaimConfigurationMain;