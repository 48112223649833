import React, { useEffect } from "react";
import "../../../../library/scss/rsuite-default.css";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { loadPaymentGraph } from "../../../../redux/actions";
import { connect } from "react-redux";
import Moment from "moment";
import { Chart } from "react-google-charts";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.white,
    marginTop: 5,
    borderRadius: 10,
    padding: "15px 22px",
    paddingLeft: 5,
    paddingRight: 5,
  },
  gridContainer: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
    paddingTop: 10,
  },
}));
const PaymentCharts = (props) => {
  const classes = useStyles();
  const [paymentContractTypeData, setPaymentContractTypeData] = React.useState([]);
  const [paymentContractGroupData, setPaymentContractGroupData] = React.useState([]);
  const [paymentContractSubGroupData, setPaymentContractSubGroupData] = React.useState([]);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  // useEffect(() => {
  //   props.allowedDashBoardApps(sessionStorage.getItem("application"));
  // }, []);
  useEffect(() => {
    if (props.paymentGraphContractGroupSuccessData) {
      var label = ["Contract group", "Percentage"];
      var output = props.paymentGraphContractGroupSuccessData.map(function (
        obj
      ) {
        return Object.keys(obj)
          .sort()
          .map(function (key) {
            return obj[key];
          });
      });
      const newArray = [label].concat(output);
      setPaymentContractGroupData(newArray);
    }
    else {
      setPaymentContractGroupData([])
    }
  }, [props.paymentGraphContractGroupSuccessData]);


  useEffect(() => {
    if (props.paymentGraphContractTypeSuccessData) {
      var label = ["Contract type", "Percentage"];
      var output = props.paymentGraphContractTypeSuccessData.map(function (
        obj
      ) {
        return Object.keys(obj)
          .sort()
          .map(function (key) {
            return obj[key];
          });
      });
      const newArray = [label].concat(output);
      setPaymentContractTypeData(newArray);
    }
    else {
      setPaymentContractTypeData([]);
    }
  }, [props.paymentGraphContractTypeSuccessData]);

  useEffect(() => {
    if (props.paymentGraphContractSubGroupSuccessData) {
      var label = ["Contract sub group", "Percentage"];
      var output = props.paymentGraphContractSubGroupSuccessData.map(function (
        obj
      ) {
        return Object.keys(obj)
          .sort()
          .map(function (key) {
            return obj[key];
          });
      });
      const newArray = [label].concat(output);
      setPaymentContractSubGroupData(newArray);
    }
    else {
      setPaymentContractSubGroupData([]);
    }
  }, [props.paymentGraphContractSubGroupSuccessData]);

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Postings Summary by Contract Groupings" && item.dashboard_name === "Default Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])
  // console.log("api", props.allowedDashBoardData)
  // console.log("filter", allowedTilesFilter)

  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0 && allowedTilesFilter[0].data_source_type) {
      var date = "start_date=" + Moment(allowedTilesFilter[0].start_date).format("YYYY-MM-DDTHH:mm:ss") + "&end_date=" + Moment(allowedTilesFilter[0].end_date).format("YYYY-MM-DDTHH:mm:ss");
      props.loadPaymentGraph("contract_type", date, allowedTilesFilter[0].posting_type, sessionStorage.getItem("application"), allowedTilesFilter[0].data_source_type);
      props.loadPaymentGraph("contract_group", date, allowedTilesFilter[0].posting_type, sessionStorage.getItem("application"), allowedTilesFilter[0].data_source_type);
      props.loadPaymentGraph("contract_sub_group", date, allowedTilesFilter[0].posting_type, sessionStorage.getItem("application"), allowedTilesFilter[0].data_source_type);
    }
  }, [allowedTilesFilter]);
  useEffect(() => {
    var sourceType =
      sessionStorage.getItem("application") == "Customer Rebate"
        ? "Direct - Sales Data"
        : "Direct - Purchase Data";
    var dateArray = [
      Moment().subtract(2, "months").startOf("month"),
      Moment().subtract("months").endOf("month"),
    ];
    var date =
      "start_date=" +
      Moment(dateArray[0]).format("YYYY-MM-DDTHH:mm:ss") +
      "&end_date=" +
      Moment(dateArray[1]).format("YYYY-MM-DDTHH:mm:ss");
    // props.loadPaymentGraph("contract_type", date, "payment", sessionStorage.getItem("application"), sourceType);
    // props.loadPaymentGraph("contract_group", date, "payment", sessionStorage.getItem("application"), sourceType);
    // props.loadPaymentGraph("contract_sub_group", date, "payment", sessionStorage.getItem("application"), sourceType);
  }, []);

  return (
    <Grid container>
      <Grid item md={4} xs={12} className={classes.gridContainer}>
        <div
          className={classes.root}
          style={{ marginRight: 10, marginLeft: 5 }}
        >
          <Typography variant="h2" style={{ textAlign: "center" }}>
            Contract Group
          </Typography>
          {paymentContractGroupData.length > 0 ? (
            <Chart
              height={300}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={paymentContractGroupData}
              options={{
                pieHole: 0.4,
                colors: ["#449724", "#329AF7", "#F3993E", "#DC3A2F", "#7401DF"],
                legend: { position: "top", alignment: "center" },
                chartArea: { width: "100%", height: "80%" },
              }}
              rootProps={{ "data-testid": "2" }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 209,
              }}
            >
              No data
            </div>
          )}
        </div>
      </Grid>
      <Grid item md={4} xs={12} className={classes.gridContainer}>
        <div className={classes.root} style={{ marginRight: 10 }}>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            Contract Type
          </Typography>
          <Chart
            height={300}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={paymentContractTypeData}
            options={{
              is3D: true,
              colors: ["#449724", "#329AF7", "#F3993E", "#DC3A2F", "#7401DF"],
              legend: { position: "top", alignment: "center" },
              chartArea: { width: "100%", height: "80%" },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      </Grid>
      <Grid item md={4} xs={12} className={classes.gridContainer}>
        <div className={classes.root} style={{ marginRight: 5 }}>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            Contract Objective
          </Typography>
          {paymentContractSubGroupData.length > 0 ? (
            <Chart
              height={300}
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={paymentContractSubGroupData}
              options={{
                pieHole: 0.4,
                colors: ["#449724", "#329AF7", "#F3993E", "#DC3A2F", "#7401DF"],
                legend: { position: "top", alignment: "center" },
                chartArea: { width: "100%", height: "80%" },
              }}
              rootProps={{ "data-testid": "2" }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              No data
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentGraphContractTypeSuccessData: state.initialData.paymentGraphContractTypeSuccessData,
    paymentGraphContractGroupSuccessData: state.initialData.paymentGraphContractGroupSuccessData,
    paymentGraphContractSubGroupSuccessData: state.initialData.paymentGraphContractSubGroupSuccessData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPaymentGraph: (item, dateRangeValue, type, applicationType, sourceDataType) => dispatch(loadPaymentGraph(item, dateRangeValue, type, applicationType, sourceDataType)),
    // allowedDashBoardApps: (type) => dispatch(dashboardDashBoardNames(type, "dashboard")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCharts);
