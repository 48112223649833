import React, { useEffect } from 'react';
import {
    Card, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Typography, CardHeader,
    Dialog, DialogActions, Button, IconButton, Paper, Chip
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles, styled } from '@mui/styles';
import { connect } from 'react-redux';
import Moment from 'moment';
var data = [];
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0px 0px 20px',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    stackList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 5
    },
    stackContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rootHeader: {
        paddingLeft: 0,
        marginTop: 0
    },
    tableCell: {
        textTransform: 'capitalize'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const PriceSimulationCPQResult = props => {
    const classes = useStyles();
    const { className } = props;
    const [catalogData, setCatalogData] = React.useState({});
    const [orgHeader, setOrgHeader] = React.useState([]);
    const [headerData, setHeaderData] = React.useState({});
    const [customerHeader, setCustomerHeader] = React.useState([]);
    const [materialHeader, setMaterialHeader] = React.useState([]);
    const [header, setHeader] = React.useState([]);
    useEffect(() => {
        var org = []
        var customer = []
        if (props.priceCatalogData && props.priceCatalogData.results) {
            setCatalogData(props.priceCatalogData.results.result)
            if (props.priceCatalogData.results['organization_header']) {
                org = props.priceCatalogData.results['organization_header']
                setOrgHeader(props.priceCatalogData.results['organization_header'])
            }
            if (props.priceCatalogData.results['customer_header']) {
                customer = props.priceCatalogData.results['customer_header']
                setCustomerHeader(props.priceCatalogData.results['customer_header'])
            }
            if (props.priceCatalogData.results['material_header']) {
                setMaterialHeader(props.priceCatalogData.results['material_header'])
            }
            if (props.priceCatalogData.results['price_type_header']) {
                setHeader(props.priceCatalogData.results['price_type_header'])
            }
            if (props.priceCatalogData.results.header_result)
                setHeaderData(props.priceCatalogData.results.header_result)
        }
    }, [props.priceCatalogData])

    return (
        <div>

            <div className={clsx(classes.root, className)}>
                <div style={{ padding: '19px' }}>
                    <Typography variant="h2">
                        Price Catalog Results
                    </Typography>
                </div>
                <Card>
                    <div className={classes.row} style={{ paddingBottom: 0 }}>
                       
                    </div>
                    <div className={classes.container} style={{ paddingTop: 0 }} >
                        <Grid>
                            {headerData && Object.entries(headerData).length > 0 &&
                                <TableContainer >
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tableCell}> Price Catalog Name </TableCell>
                                                <TableCell align='center'className={classes.tableCell}> Start date </TableCell>
                                                <TableCell align='center' className={classes.tableCell}> End date </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                                    <StyledTableRow>
                                                       <TableCell align='center'className={classes.tableCell}>{headerData.price_catalog_name}</TableCell>
                                                       <TableCell align='center' className={classes.tableCell}> {headerData.start_date?Moment.utc(headerData.start_date).format('MM/DD/YYYY'):''} </TableCell>
                                                       <TableCell align='center' className={classes.tableCell}> {headerData.end_date?Moment.utc(headerData.end_date).format('MM/DD/YYYY'):''} </TableCell>
                                                    </StyledTableRow>
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                }
                        </Grid>
                    </div>
                </Card>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="Line Level Details"
                            titleTypographyProps={{ variant: 'h3' }}
                            classes={{
                                root: classes.rootHeader
                            }}
                        />
                    </div>
                    <div className={classes.container} style={{ paddingTop: 0 }}>
                        <Grid>
                            {catalogData && catalogData.length > 0 &&
                                <TableContainer >
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                {orgHeader && orgHeader.length > 0 && orgHeader.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}> {item.replace(/_/g, ' ')} </TableCell>

                                                ))}
                                                {customerHeader && customerHeader.length > 0 && customerHeader.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}> {item.replace(/_/g, ' ')} </TableCell>

                                                ))}
                                                {materialHeader && materialHeader.length > 0 && materialHeader.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}> {item.replace(/_/g, ' ')} </TableCell>

                                                ))}
                                                {header && header.length > 0 && header.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}> {item.replace(/_/g, ' ')} </TableCell>

                                                ))}
                                                <TableCell align='center' className={classes.tableCell}> Net Price </TableCell>
                                                <TableCell align='center' className={classes.tableCell}> Effective From</TableCell>
                                                <TableCell align='center' className={classes.tableCell}> Effective To </TableCell>
                                            </TableRow>
                                        </TableHead><TableBody classes={{ root: classes.table }} >
                                            {catalogData && catalogData.length > 0 && catalogData.map((item, i) => {
                                                return (
                                                    <StyledTableRow>
                                                        {orgHeader&& orgHeader.map(itemX => (
                                                            Object.entries(catalogData[i]).filter(([k, v]) => k == 'organization_attributes').map((itemOrg, i) => (
                                                                <TableCell align='center'>{itemOrg[1][itemX]}</TableCell>
                                                            ))
                                                        ))}
                                                        {customerHeader&& customerHeader.map(itemX => (
                                                            Object.entries(catalogData[i]).filter(([k, v]) => k == 'customer_attributes').map((itemCust, i) => (
                                                                <><TableCell align='center'>{itemCust[1][itemX]}</TableCell> {console.log(itemCust)}</>
                                                            ))))
                                                        }
                                                        {materialHeader&&materialHeader.map(itemX => (
                                                            Object.entries(catalogData[i]).filter(([k, v]) => k == 'material_attributes').map((itemM, i) => (
                                                                <TableCell align='center'>{itemM[1][itemX]}</TableCell>
                                                            ))
                                                        ))}
                                                        {header.map(itemX => (
                                                            Object.entries(catalogData[i]).filter(([k, v]) => k == 'price_type_name_values').map((itemCust, i) => (
                                                                <TableCell align='center'>{itemCust[1][itemX]&&itemCust[1][itemX]['value']}</TableCell>
                                                            ))
                                                        ))
                                                        }
                                                        <TableCell align='center'>{item.net_value}</TableCell>
                                                        <TableCell align='center'>{item.effective_start_date?Moment.utc(item.effective_start_date).format('MM/DD/YYYY'):''}</TableCell>
                                                        <TableCell align='center'>{item.effective_end_date?Moment.utc(item.effective_end_date).format('MM/DD/YYYY'):''}</TableCell>

                                                    </StyledTableRow>

                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>

                                </TableContainer>}
                        </Grid>
                    </div>
                </Card>
            </div >
        </div >
    );

};


const mapStateToProps = state => {
    return {
        priceCatalogData: state.pricingData.catalogReport,
    }
};


export default connect(mapStateToProps, null)(PriceSimulationCPQResult);

