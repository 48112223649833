import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    TableRow,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { createPerformanceMetricWeightage, getDefaultValuesCustomerXRef, getDefaultValuesCustomer } from '../../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        // fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
             paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const AddPerformanceMetricWeightage = props => {


    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [open, setOpen] = React.useState(false);

    const [metricType, setMetricType] = React.useState('');
    const [weightagePercentage, setWeightagePercentage] = React.useState('');
    const [territoryID, setTerritoryID] = React.useState('');
    



    useEffect(() => {
        setOpen(true);
    }, []);

    const handleInitialSetup = () => {
        setOpen(false);
    }



    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [userStore, setUserStore] = React.useState([]);
    function handleSubmit(editIndex) {

        var data = {
            "metric_type":metricType,
            "territory_id": territoryID,
            "weightage_percentage":parseFloat(weightagePercentage)

        };

        props.onSubmit(data);
        handleClear();
    }
    function handleClear() {
       setWeightagePercentage('')
       setMetricType('')
       setTerritoryID('')

    }

    function handleClearAll() {
        handleClear();
        //setEditMode(false);
        setUserStore([]);
    }

    
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/performance-metric-weightage'
                >
                    Performance Metric Weightage
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Performance Weightage</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                               
                                
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}  >
                                            Metric Type
                                        </FormLabel>
                                        <OutlinedInput
                                            value={metricType}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setMetricType(e.target.value)} />
                                    </div>
                                </Grid>
                                
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            Territory ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={territoryID}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setTerritoryID(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            Weightage Percentage
                                        </FormLabel>
                                        <OutlinedInput
                                            value={weightagePercentage}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setWeightagePercentage(e.target.value)} />
                                    </div>
                                </Grid>





                            </Grid>
                        </div>
                    </form>
                </Card>


                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                    /*disabled={!productGroupId||!productGroupName ||!materialNumber ?true:false}*/
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createPerformanceMetricWeightage(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef()),
        onLoadingDefaultCustomerMaster: () => dispatch(getDefaultValuesCustomer()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        loadingAPI: state.customerData.loading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPerformanceMetricWeightage);