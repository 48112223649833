import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs,
    Card, IconButton, OutlinedInput, FormLabel, MenuItem, Select,
    Dialog, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { createAnalysisFormula, getDrivers, getAnalysisLevel } from '../../../redux/actions';
import { history } from '../../../components/Helpers';
import { DeleteForever, GetAppSharp, PublishSharp } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 5
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: '0px 15px',
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'end',
        paddingTop: 3,
        paddingBottom: 28,
    },
    paper: { minWidth: window.screen.width * .50 },
    select: {
        width: '100%',
        marginTop: 10,
        height: 'webkitFillAvailalble'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    formlabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 'webkitFillAvailalble',
        fontSize: theme.typography.h4.fontSize,
    },
}));
const AddAnalysisFormula = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [formulaName, setFormulaName] = React.useState('');
    const [formulaDes, setFormulaDes] = React.useState('');
    const [step, setStep] = React.useState([]);
    const [priceTypeName, setPriceTypeName] = React.useState([]);
    const [allFormula, setAllFormula] = React.useState([]);
    const [analysisLevel, setAnalysisLevel] = React.useState('');
    const [analysisLevelArray, setAnalysisLevelArray] = React.useState([]);
    const [analysisLevelDes, setAnalysisLevelDes] = React.useState('');
    const [stepList, setStepList] = React.useState([{ key: 'Step Total 1', 'value': true }, { key: 'Step Total 2', 'value': true }, { key: 'Step Total 3', 'value': true }, { key: 'Step Total 4', 'value': true }, { key: 'Step Total 5', 'value': true }, { key: 'Step Total 6', 'value': true }]);
    const priceTypeList = ['Base Price', 'On-Invoice Discount', 'On-Invoice Freight', 'On-Invoice Surcharge', 'Off-Invoice Revenue',
        'Off-Invoice Expense', 'Cost - Direct', 'Cost - Indirect', 'Transfer Price', "Base Sales Adjustments",
        "Price Elasticity Adjustments", "Cost Adjustments","Floor Price","Margin Target %", "Market Price", "Market Target %",]
    const revenuExpenseList = ['revenue', 'expense']

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        props.getConfigData(1, 0);
        props.getAnalysisLevel(1, 0)
        setOpen(true);
    }, []);

    function handleOnSubmit() {
        var data = []
        var step = 0
        var array = analysisLevel;
        var [first, second] = array.split('-')
        allFormula.map((item, i) => {
            step = 10 * (i + 1)

            data.push({
                ...allFormula[i]
                , 'step': step, 'formula_name': formulaName.trim(), 'formula_description': formulaDes, 'analysis_level_id': first, 'analysis_level_id_description': second
            })
        })
        props.onSubmit(data)
    }
    function handleDeleteLocally(index) {
        if (allFormula.length > 1)
            setAllFormula(item => item.filter((item, i) => i !== index))
    }
    function handleOnMove(index, type) {
        if (type == 'up') {
            setAllFormula(prevState => {
                let data = [...prevState];

                let temp = data[index - 1];
                data[index - 1] = data[index];
                data[index] = temp;
                return [...data];
            })
        }
        else {
            setAllFormula(prevState => {
                let data = [...prevState];

                let temp = data[index + 1];
                data[index + 1] = data[index];
                data[index] = temp;

                return [...data];
            })
        }
    }
    useEffect(() => {
        var newArray = []
        if (props.configData && props.configData.length > 0) {

            const sorted = props.configData.sort(function (a, b) {
                return priceTypeList.indexOf(a.driver) - priceTypeList.indexOf(b.driver);
            });
            sorted.map((item, i) => {
                var list = {
                    'driver': item.driver, 'driver_category': item.driver_category, 'driver_name': item.driver_name
                }
                if (item.driver == 'Base Price' || item.driver == 'On-Invoice Freight' || item.driver == 'On-Invoice Surcharge')
                    newArray.push({ ...list, 'revenue_expense_indicator': 'revenue' })
                else
                    newArray.push({ ...list, 'revenue_expense_indicator': 'expense' })
            })
            setAllFormula(newArray)
        }

    }, [props.configData]);
    useEffect(() => {
        var newArray = []
        if (props.analysisLevelArrayData && props.analysisLevelArrayData.records) {
            props.analysisLevelArrayData.records.map(e => {
                newArray.push(e.analysis_level_id + '-' + e.analysis_level_id_description)
            })
        }
        setAnalysisLevelArray(newArray)
    }, [props.analysisLevelArrayData])
    function handleFormulaName(newValue, index) {
        setFormulaName(newValue.target.value)
    }
    function handleFormulaDesc(newValue, index) {
        setFormulaDes(newValue.target.value)
    }
    function handleAnlysisLevel(newValue) {
        setAnalysisLevel(newValue)
        var [first, second] = newValue.split('-')
        setAnalysisLevelDes(second)
    }
    function handleCancelDialog() {
        history.push('/analysis-formula');
    }
    function handleOnChange(event, index, type) {
        allFormula[index][type] = event.target.value
        if (type == 'step_total') {
            var stepIndex = stepList.findIndex(({ key }) => key == event.target.value);

            setStepList(prevState => {
                let data = [...prevState];
                data[stepIndex]['value'] = false
                return [...data];
            })
        }
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/analysis-formula'
                >
                    Analysis Formula
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Analysis Formula</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel classes={{ root: classes.formlabel }} required
                        style={{
                            height: '50px',
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}
                    >
                        Formula Name
                    </FormLabel>
                    <OutlinedInput
                        value={formulaName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleFormulaName} />
                    <FormLabel className={classes.formlabel} required
                        style={{
                            height: '50px',
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}
                    >
                        Formula Description
                    </FormLabel>
                    <OutlinedInput
                        value={formulaDes}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleFormulaDesc} />
                    <FormLabel className={classes.formlabel} required
                        style={{
                            height: '50px',
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}
                    >
                        Analysis Level ID
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                        <Select
                            disableUnderline
                            value={analysisLevel}
                            onChange={(e) => handleAnlysisLevel(e.target.value)}
                            displayEmpty
                            MenuProps={{ ...MenuProps, autoFocus: true }}
                            classes={{
                                selectMenu: classes.selectedItem,
                            }}
                            className={clsx({
                                [classes.select]: true,
                            })}
                            style={{ textTransform: "capitalize" }}
                        >
                            {analysisLevelArray &&
                                analysisLevelArray.map((item) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                            key={item}
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </div>
                    <FormLabel className={classes.formlabel}
                        style={{
                            height: '50px',
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}
                    >
                        Analysis Level ID Description
                    </FormLabel>
                    <OutlinedInput
                        disabled={'disabled'}
                        value={analysisLevelDes}
                        classes={{ root: classes.inputTwoLine }}
                    />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={formulaName && formulaDes && analysisLevel ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer} style={{ marginTop: "15px" }}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >

                                                <TableCell align='center' className={classes.tabHead}>Driver Category</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Driver </TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Driver Name </TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Revenue Expense </TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Actions </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {allFormula && allFormula
                                                .map((itemX, index) => {
                                                    return (
                                                        <StyledTableRow >

                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{allFormula[index]['driver_category']}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}> {allFormula[index]['driver']}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}> {allFormula[index]['driver_name']}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                                <Select
                                                                    disableUnderline
                                                                    defaultValue={allFormula[index]['revenue_expense_indicator']}
                                                                    onChange={(e) => handleOnChange(e, index, 'revenue_expense_indicator')}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    classes={{
                                                                        selectMenu: classes.formlabel
                                                                    }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    SelectDisplayProps={{ style: { height: '100%' } }}
                                                                    style={{ textTransform: 'capitalize', margin: 0 }}
                                                                >
                                                                    {revenuExpenseList && revenuExpenseList
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <MenuItem value={item} name={item} style={{ textTransform: 'capitalize' }}>
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                            </TableCell>
                                                            <TableCell style={{ display: 'flex' }}>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleDeleteLocally(index)}
                                                                    size="large">
                                                                    <DeleteForever />
                                                                </IconButton>
                                                                <IconButton
                                                                    style={{ marginRight: 5 }}
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleOnMove(index, 'down')}
                                                                    disabled={index != allFormula.length - 1 ? allFormula[index]['driver'] == allFormula[index + 1]['driver'] ? false : true : true}
                                                                    size="large">
                                                                    <GetAppSharp />
                                                                </IconButton>
                                                                <IconButton
                                                                    style={{ padding: 0 }}
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleOnMove(index, 'up')}
                                                                    disabled={index != 0 ? allFormula[index]['driver'] == allFormula[index - 1]['driver'] ? false : true : true}
                                                                    size="large">
                                                                    <PublishSharp />
                                                                </IconButton>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={step && priceTypeName ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};
const mapStateToProps = state => {
    return {
        loadingAPI: state.profitOptimizationData.loading,
        configData: state.profitOptimizationData.priceTypeData,
        analysisLevelArrayData: state.profitOptimizationData.priceListMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createAnalysisFormula(data)),
        getConfigData: (pagination, limit) => dispatch(getDrivers(pagination, limit)),
        getAnalysisLevel: (pagination, limit) => dispatch(getAnalysisLevel(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAnalysisFormula);