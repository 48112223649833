import React, { useEffect, forwardRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import {
    getIndirectSalesDataDetailsById,
} from "../../redux/actions";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import MaterialTable from "material-table";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { NavLink as RouterLink } from "react-router-dom";
import { createTheme } from "@mui/material/styles";

import { DisplaySalesInvoiceDetailsPopup } from "..";

const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {},
        },
        MuiTableCell: {
            root: {
                padding: 10,
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0,
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16,
    },
    row: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    caption: {
        fontSize: 12,
    },
    paginationRoot: {
        border: "1px solid #EEEEEE",
        borderTop: 0,
    },
    description: {
        textAlign: "left",
        paddingLeft: 28,
    },
    hover: {
        cursor: "pointer",
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up("md")]: {
            paddingRight: 10,
        },
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 15,
        marginTop: 15,
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6,
        },
    },
}));

const DisplayIndirectSalesInvoiceQuery = (props) => {
    const { className } = props;
    const classes = useStyles();
    const columns = [
        {
            field: "transaction_type",
            title: "Transaction Type",
            editable: "never",
            type: "string",
            render: (rowData) =>
                rowData.id ? (
                    <div
                        style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                        }}
                        onClick={() => handlePopupClick(rowData)}
                    >
                        {rowData.transaction_type}
                    </div>
                ) : (
                    <div style={{ color: "white" }}>-</div>
                ),
        },
        {
            field: "billing_date",
            title: "Billing Date",
            type: "string",
            editable: "never",
            render: rowData => <a>{rowData.billing_date.split("T")[0]}</a>
        },
        {
            field: "billing_doc_number",
            title: "Billing Document",
            type: "string",
        },
        {
            field: "billing_doc_line",
            title: "Billing Line",
            type: "number",
        },
        {
            field: "channel_partner_id",
            title: "Channel Partner ID",
            type: "string",
        },
        {
            field: "channel_partner_name",
            title: "Channel Partner Name",
            editable: "never",
            type: "string",
        },
        {
            field: "channel_partner_quantity",
            title: "Channel Partner Quantity",
            editable: "never",
            type: "number",
        },
        {
            field: "delivery_tracking_number",
            title: "Delivery Tracking Number",
            editable: "never",
            type: "string",
        },
        {
            field: "end_customer_id",
            title: "End Customer ID",
            editable: "never",
            type: "string",
        },
        {
            field: "material_number",
            title: "Material Number",
            type: "string",
            editable: "never",
        },
        {
            field: "material_description",
            title: "Material Description",
            type: "string",
            editable: "never",
        },
        {
            field: "quantity",
            title: "Quantity",
            type: "string",
            editable: "never",
        },
        {
            field: "net_value",
            title: "Net Value",
            type: "string",
            editable: "never",
        }
    ];
    const [dataRows, setDataRows] = React.useState([]);
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => (<ChevronRight {...props} ref={ref} />)),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const CustomRouterLink = forwardRef((props, ref) => (
        <div ref={ref} style={{ flexGrow: 1 }}>
            <RouterLink {...props} />
        </div>
    ));
    useEffect(() => {
        var tempRows = [];
        if (props.AllIndirectSalesDataList && props.AllIndirectSalesDataList.records) {
            props.AllIndirectSalesDataList.records.map((e) => {
                tempRows.push({
                    id: e.ID,
                    transaction_type: e.transaction_type,
                    billing_date: e.billing_date,
                    billing_doc_number: e.billing_doc_number,
                    billing_doc_line: e.billing_doc_line,
                    channel_partner_id: e.channel_partner_id,
                    channel_partner_name: e.channel_partner_name,
                    channel_partner_quantity: e.channel_partner_quantity,
                    delivery_tracking_number: e.delivery_tracking_number,
                    end_customer_id: e.end_customer_id,

                    material_number: e.material_number,
                    material_description: e.material_description,
                    net_value: e.net_value,
                    quantity: e.quantity,
                });

            });

            setDataRows(tempRows);
        }
    }, [props.AllIndirectSalesDataList]);
    const [currentViewID, setCurrentViewID] = React.useState(0);
    const handlePopupClick = (rowData) => {
        props.getSalesDataDetailsById(rowData.id);
        setDialogOpen(true);
        setCurrentViewID(rowData.id);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <DisplaySalesInvoiceDetailsPopup
                open={dialogOpen}
                onClose={(value) => setDialogOpen(value)}
                outgoingClaimID={currentViewID}
            />
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: "50px",
                        "& svg circle": {
                            stroke: "#045FB4",
                        },
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(52, 52, 52, 0)",
                    }),
                    content: (base) => ({
                        ...base,
                        color: "black",
                    }),
                }}
                text="Loading ... Please wait ..."
                className={classes.spinner}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: 22 }}> Display Sales Data</div>
                </div>
                {props.AllIndirectSalesDataList && props.AllIndirectSalesDataList.records ? (
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                ></div>
                            ),
                        }}
                        title={" "}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 1000000,
                            pageSizeOptions: [
                                1000000,
                                2000000,
                                5000000,
                                { value: dataRows.length, label: "Show all" },
                            ],
                        }}
                    />
                ) : (
                    <div>There is no data to show now.</div>
                )}
            </LoadingOverlay>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        AllIndirectSalesDataList: state.dataSetupData.AllIndirectSalesDataList,
        IndirectSalesDataDetailsSingle: state.dataSetupData.IndirectSalesDataDetailsSingle,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getIndirectSalesDataDetailsById: (id) => dispatch(getIndirectSalesDataDetailsById(id)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayIndirectSalesInvoiceQuery);
