import React, { useEffect, forwardRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, Fab, Typography } from '@mui/material';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getInventoryData, createInventoryData } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { NavLink as RouterLink } from 'react-router-dom';
import { createTheme } from "@mui/material/styles";
import Moment from 'moment';
import { tableIcons } from '../../components/Icons/TableIcons';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../components/Dialog';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const InventoryData = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("")
    const [currentData, setCurrentData] = React.useState("")

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onDelete(currentData, currentId);
        } else {
            setOpen(false);
        }
    }
    const handleClickOpen = (rowData, id) => {
        setOpen(true)
        setCurrentId(id)
        setCurrentData(rowData)
    };
    const columns = [
        {
            field: 'channel_partner_id',
            title: 'Channel Partner ID',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'channel_partner_name',
            title: 'Channel Partner Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'material',
            title: 'Material',
            type: 'string',
        },
        {
            field: 'starting_inventory',
            title: 'Starting Inventory',
            type: 'number',
        },
        {
            field: 'ending_inventory',
            title: 'Ending Inventory',
            type: 'number',
        },
        {
            field: 'start_date',
            title: 'Start Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment(rowData.start_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'end_date',
            title: 'End Date',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} >{Moment(rowData.end_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_inventory_data-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editInventory(rowData.id)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_inventory_data-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleClickOpen(rowData, rowData.id)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ];
    useEffect(() => {
        props.getInventoryData();
    }, []);
    function editInventory(id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/inventory-data/edit/' + id,
            id: id
        });
    }
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    useEffect(() => {
        var tempRows = [];
        if (props.inventoryData && props.inventoryData.records && props.inventoryData.total_record > 0) {
            props.inventoryData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        channel_partner_id: e.channel_partner_id,
                        channel_partner_name: e.channel_partner_name,
                        material: e.material,
                        starting_inventory: e.starting_inventory,
                        ending_inventory: e.ending_inventory,
                        start_date: e.start_date,
                        end_date: e.end_date,

                    });
                })
            setDataRows(tempRows);
        }
    }, [props.inventoryData]);

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading ... Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Channel Inventory </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_inventory_data-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/inventory-data/add'
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/inventory-data/inventory-data-excel-upload'
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddBoxOutlinedIcon />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                component={CustomRouterLink}
                                to='/inventory-data/add'
                                size={'small'}
                                className={classes.buttonStyle}
                            >
                                {isDesktop ? 'Add Inventory Data' : ''}
                            </Button> */}
                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddBoxOutlinedIcon />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                component={CustomRouterLink}
                                to='/inventory-data/inventory-data-excel-upload'
                                size={'small'}
                                className={classes.buttonStyle}
                                style={{ marginLeft: 10 }}
                            >  Upload Excel
                            </Button> */}
                        </div>
                    }
                </div>
                {props.inventoryData && props.inventoryData.records && props.inventoryData.total_record > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        inventoryData: state.dataSetupData.inventoryData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getInventoryData: () => dispatch(getInventoryData()),
        onDelete: (data, id) => dispatch(createInventoryData(data, 'delete', id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InventoryData);