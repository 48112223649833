import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Paper, Input, Button, useMediaQuery, IconButton, Fab, Typography } from '@mui/material';
import { Search, Check, AddBoxOutlined, CloudUpload, LibraryAdd, PersonAdd } from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { getCustomerMasterData } from '../../../redux/actions';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0
  },
  searchInput: {
    marginRight: 47
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  rootSearch: {
    borderBottomColor: theme.palette.table.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const EmployeeMasterToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [searchText, setSearchText] = React.useState('');
  const addContract = () => {
    localStorage.removeItem('currentAddContractID');
    sessionStorage.setItem('mode', 'add');
    history.push('employee/add-employee');
  }
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  }

  return (
    <div
      {...rest}
      className={classes.root}
    >
      {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_employee-' + sessionStorage.getItem('application')) &&
        // <Button
        //   variant="contained"
        //   color="primary"
        //   startIcon={<AddBoxOutlined />}
        //   classes={{
        //     startIcon: !isDesktop && classes.startIcon,
        //     root: !isDesktop && classes.container
        //   }}
        //   component={CustomRouterLink}
        //   to='/employee/add-employee'
        //   onClick={addContract}
        // >
        //   {isDesktop ? 'Add Employee' : ''}
        // </Button> }

        <Fab aria-label="edit" variant="extended"
          size='medium'
          classes={{ root: classes.fabContainer }}
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/employee/add-employee'
          onClick={addContract}
        >
          <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
        </Fab>}

      {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_employee-' + sessionStorage.getItem('application')) &&
        // <Button
        //   variant="contained"
        //   color="primary"
        //   startIcon={<AddBoxOutlined />}
        //   classes={{
        //     startIcon: !isDesktop && classes.startIcon,
        //     root: !isDesktop && classes.container
        //   }}
        //   component={CustomRouterLink}
        //   to='/employee/employee-excel-upload'
        //   style={{ marginLeft: 10 }}
        // >
        //   Upload Excel
        // </Button> }

        <Fab aria-label="edit" variant="extended"
          size='medium'
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/employee/employee-excel-upload'
        >
          <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
        </Fab>}

      {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_employee_territory_assignment-' + sessionStorage.getItem('application')) &&

        <Fab aria-label="edit" variant="extended"
          size='medium'
          classes={{ root: classes.fabContainer }}
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/employee/employee-territory-excel-upload'
        >
          <PersonAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add Employee Territory</Typography>
        </Fab>
      }
    </div >
  );

};

const mapDispatchToProps = dispatch => {
  return {
    onSearch: (keyword) => dispatch(getCustomerMasterData(0, 100, keyword))
  }
}

export default connect(null, mapDispatchToProps)(EmployeeMasterToolbar);