import React from 'react';
import { CotXREFUserTable } from './components/CotXREF';

const CotXREF = () => {
    return (
        <div>
            <CotXREFUserTable />
        </div >
    );
};

export default CotXREF;