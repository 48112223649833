import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import {
    FormLabel, TextField, Checkbox
} from '@mui/material';
import { getEmployeeData } from '../../../redux/actions';
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        marginTop: 10
    },
    error: {
        border: '1px solid red !important'
    },
    contractContiner: {
        padding: '15px 0px 18px'
    }
}));

const EmployeeNumberComponent = forwardRef((props, ref) => {
    const classes = useStyles();
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [data, setData] = React.useState(props.defaultValue ? 0 : '');
    const [changedFlag, setChangedFlag] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [contractNumber, setContractNumber] = useState();
    useEffect(() => {
        props.getEmployeeData();
    }, [])
    useEffect(() => {
        var contracts = [];
        var tempNameArray = [];
        if (props.employeeMasterData) {
            props.employeeMasterData.records.map(item => {
                contracts.push(item.employee_number.toString())
            })
            setContractNumbersList(contracts);
        }
    }, [props.employeeMasterData])
    const handleContractNumber = (e, value) => {
        if(value.length>0){
        setContractNumber(value)
        props.onChange(value)
        }
    };
    const reset = () => {
        setData('');
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : data
        };
    });

    return (
        <div className={classes.contractContiner}>
            <FormLabel required className={classes.chargeBackLabel}> Contract Number </FormLabel>
            <Autocomplete
                limitTags={3}
                id="checkboxes-tags-demo"
                options={contractNumbersList}
                getOptionLabel={(option) => option}
                defaultValue={contractNumber}
                value={contractNumber}
                renderInput={params => (

                    <TextField {...params} variant="outlined" size="small" style={{ borderRadius: '0px !important' }} />

                )}
                classes={{
                    inputRoot: { borderRadius: '0px !important' }
                }}
                style={{ width: "100%", marginBottom: 15, marginTop: 5 }}
             //   onSelect={(event, newValue) => { handleContractNumber(event, newValue) }}
                onChange={(event, newValue) => { handleContractNumber(event, newValue) }}                
            />
        </div>
    );

});
const mapDispatchToProps = dispatch => {
    return {
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(1,0)),
    }
}
const mapStateToProps = state => {
    return {
        employeeMasterData: state.customerData.employeeMasterData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeNumberComponent);