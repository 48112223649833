import React, { useEffect, useRef } from "react";
import {
    Button, Typography, Breadcrumbs, Card, useMediaQuery
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { getPriceMaintenanceFields, getPriceListData, onApprovePriceMaintenance } from '../../../../redux/actions/Pricing/AuthAction';
import Moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import '../../../../../node_modules/jsuites/dist/jsuites.css';
import '../../../../../node_modules/jsuites/dist/jsuites.js';
import { Link } from 'react-router-dom';

import {
    Edit, ColumnDirective, ColumnsDirective, GridComponent,
    Group, Inject, Page, Sort, Resize, ExcelExport, Toolbar, PdfExport, Search, Filter, InfiniteScroll
} from '@syncfusion/ej2-react-grids';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        height: '100%'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 15,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 3,
        // paddingBottom: 28,
    },
    formlabel: {
        marginBottom: 8,
        fontSize: 13,
        textTransform: 'capitalize'
    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"

    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    input: {
        width: 42,
    },
    addRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    fontFormat:{  
        fontSize: theme.typography.h4.fontSize
    }
}));

const PricingApprovalList = props => {
    const classes = useStyles();
    const jRef = useRef(null);
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    let gridInstance;
    const [configDataAPI, setConfigDataAPI] = React.useState([]);
    const [priceListID, setPriceListID] = React.useState('');
    const [listDataArray, setListDataArray] = React.useState([]);
    const [priceListData, setPriceListData] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataRowsNew, setDataRowsNew] = React.useState([]);
    const [optionsList, setOptionsList] = React.useState([]);
    const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search'];
    const [rowCount, setRowCount] = React.useState(1000);
    const settings = { checkboxMode: 'ResetOnRowClick' };
    const priceData = history.location.data;
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setPriceListID(appId)
        if (appId) {
            props.getPriceMaintenanceFields(appId)
            props.getConfigData(null, null, priceData);
        }
    }, [])
    function titleCase(str) {
        if (str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }

    useEffect(() => {
        if (props.maintenanceFields)
            setListDataArray(props.maintenanceFields)
    }, [props.maintenanceFields])

    useEffect(() => {
        if (props.priceListData && props.priceListData.records && props.priceListData.records[0]) {
            setPriceListData(props.priceListData.records);
            setPriceTypeNameArray(props.priceListData.records[0].price_type_name)
        }
    }, [props.priceListData]);
    useEffect(() => {
        if (props.configData && props.configData.records && !props.loadingAPI) {
            setConfigDataAPI(props.configData.records);
        }
    }, [props.configData]);

    useEffect(() => {
        if (configDataAPI && listDataArray && listDataArray.value && listDataArray.value.length > 0
             &&configDataAPI.length>0&&configDataAPI[0].price_list_id==priceListID) {

            if (jRef.current && jRef.current.jexcel)
                jRef.current.jexcel.destroy();
            var columnArray = [];
            var options = []
            var rec = configDataAPI;
            console.log(configDataAPI)
            listDataArray.value
                .filter(h => priceTypeNameArray ? h !== 'price_type_name' : h)
                .filter(h => configDataAPI[0].calculation_type != 'Analytical' ? h != 'max_value' && h != 'min_value' : h)
                .filter(h => (configDataAPI[0].calculation_type == '%' || configDataAPI[0].calculation_type == 'Fixed') ? h != 'per_unit' && h != 'scale_from' && h != 'pricing_uom' && (configDataAPI[0].calculation_type == '%' ? h != 'currency' : h) : h)
                .map(h => {
                    if (h === 'start_date' || h === 'end_date' || h === 'per_unit') {
                        columnArray.push({
                            field: h,
                            title: titleCase(h.replace(/_/g, ' ')),
                            width: 100
                        });
                        options.push({
                            title: '',
                            width: 100
                        });

                    } else {
                        columnArray.push({
                            field: h,
                            title: titleCase(h.replace(/_/g, ' ')),
                            width: 150
                        });
                        options.push({
                            title: '',
                            width: 100
                        });
                    }
                })
            var headers = []
            columnArray.map(item => {
                headers.push(item.field)
            })
            setColumns(columnArray);
            setOptionsList(options)
            var tempRows = [];
            var tempObj = {};
            var tempRowsNew = [];
            var tempObjNew = {};
            configDataAPI.map((item, ix) => {
                //tempObj=item
                headers.map((h, ix2) => {
                    if (h === 'start_date' || h === 'end_date') {
                        tempObj[titleCase(h.replace(/_/g, ' '))] = Moment.utc(item[h]).format('M/D/YYYY')
                        tempObjNew[h] = Moment(item[h]).format('MM/DD/YYYY')
                    }
                    else {
                        tempObj[titleCase(h.replace(/_/g, ' '))] = item[h]
                        tempObjNew[h] = item[h]
                    }
                })
                tempObjNew['ID'] = item['ID']
                tempRowsNew[ix] = tempObjNew;
                tempObjNew = {};
            })
            // setDataRows(tempRows);
            setDataRowsNew(tempRowsNew);
            if (tempRows && tempRows.length > rowCount)
                setRowCount(tempRows.length)
        }
        else{
            setDataRowsNew([])
            setColumns([])
        }
    }, [configDataAPI, listDataArray,priceListID]);
    const approveContract = (type) => {
        if (gridInstance) {
            const selectedrowindex = gridInstance.getSelectedRowIndexes();
            const selectedrecords = gridInstance.getSelectedRecords();
            var listId = selectedrecords.map(item => item.ID)
            var data = {
                "id": listId,
                "approval_status": "acc",
                "price_list_id": priceListID
            }
            props.onApprove(data,priceData)
        }

    };

    const filterOptions = {
        type: 'Excel'
    };
    const editSettings = { mode: 'Batch', allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top' };

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/pricing-approval'
                >
                    Pricing Approvals
                </Link>
                <Typography color="textPrimary" variant='h4'>Price Maintenance Data</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <div className={classes.buttonRoot}>
                        <Button
                            variant="contained"
                            color="primary"
                            classes={{
                                startIcon: !isDesktop && classes.startIcon,
                                root: !isDesktop && classes.container
                            }}
                            onClick={() => approveContract('acc')}
                            startIcon={<CheckIcon />}
                            style={{ margin: 10 }}
                        // disabled={!selected.length > 0 && !selectedApproved.length > 0 ? true : false}
                        >
                            Approve
                        </Button>
                    </div >

                </div>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            {configDataAPI && configDataAPI.length > 0 ?
                                <GridComponent
                                    ref={grid => gridInstance = grid}
                                    //editSettings={editSettings}
                                    filterSettings={filterOptions}
                                    allowGrouping={true}
                                    toolbar={toolbarOptions}
                                    allowFiltering
                                    allowPaging={true}
                                    dataSource={dataRowsNew}
                                    height={'100%'}
                                    allowPdfExport={true} allowExcelExport={true}
                                    Settings={settings}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective type='checkbox' width='50' />
                                        {columns.map(item => {

                                            return <ColumnDirective
                                                headerText={item.title} field={item.field}
                                                format={(item.field === 'end_date' || item.field === 'start_date') ? 'MM/dd/yyyy' : null}
                                                type={(item.field === 'end_date' || item.field === 'start_date') ? 'dateTime' : null}
                                            />
                                        })}
                                    </ColumnsDirective>
                                    <Inject services={[Toolbar, PdfExport, ExcelExport, Page, Sort, Filter, Search, Group, Resize, Edit]} />
                                </GridComponent>
                                :
                                <Typography>No data to show</Typography>
                            }

                        </div>
                    </form>
                </Card>


            </div >

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onApprove: (data,priceData) => dispatch(onApprovePriceMaintenance(data,priceData)),
        getPriceMaintenanceFields: (priceListId) => dispatch(getPriceMaintenanceFields(priceListId)),
        getConfigData: (pagination, limit, appId) => dispatch(getPriceListData(pagination, limit, appId, 'approval')),
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.pricingData.loading,
        maintenanceFields: state.pricingData.priceMaintenanceFields,
        configData: state.pricingData.priceListData,
        priceListData: state.pricingData.priceListMasterData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingApprovalList);

