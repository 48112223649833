import React, { useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardHeader,
  Grid,
  Select,
  FormLabel,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import clsx from "clsx";
import { TextInputWithLabel } from "../../../../components/Inputs";
import {
  addCustomerMasterXRefUser,
  getAttributeListData,
  getDefaultValuesCustomer,
} from "../../../../redux/actions";
import { AllOtherAttributeMasterComponent } from "../../../../components/Inputs";
import countryList from "react-select-country-list";
import { AddressAutofill } from "@mapbox/search-js-react";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
  },
  container: {
    padding: "0px 10px 0px 10px",
  },
  selectRoot: {
    padding: "20px 0px 15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    marginTop: 10,
    height: 37
  },
  selectedItem: {
    color: "black",
    border: "1px solid #E0E0E0",
    width: "100%",
  },
  input: {
    width: "100%",
    paddingLeft: 10,
  },
  inputTwoLine: {
    marginTop: 7,
    width: "100%",
    paddingLeft: 10,
    height: 36,
  },
  gridContainer: {
    padding: "0px 45px 0px 45px !important",
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize
  }
}));
const GeneralData = (props) => {
  const classes = useStyles();
  useEffect(() => {
    props.onChange(formData);
  });
  const [customerName, setCustomerName] = React.useState("");
  const [customerType, setCustomerType] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [industrialType, setIndustrialType] = React.useState("");
  const [classification, setClassification] = React.useState("");
  const [classOfTrade, setClassOfTrade] = React.useState("");
  const [accountGroup, setAccountGroup] = React.useState("");
  const [headOffice, setHeadOffice] = React.useState("");
  const [paymentTerms, setPaymentTerms] = React.useState("");
  const [flexAttribute1, setFlexAttribute1] = React.useState("");
  const [flexAttribute2, setFlexAttribute2] = React.useState("");
  const [priceList, setPriceList] = React.useState("");
  const [active, setActive] = React.useState("");
  const [industryTypeArray, setIndustryTypeArray] = React.useState([]);
  const [classificationArray, setClassificationArray] = React.useState([]);
  const [classOfTradeArray, setClassOfTradeArray] = React.useState([]);
  const [accountGroupArray, setAccountGroupArray] = React.useState([]);
  const [customerTypeArray, setCustomerTypeArray] = React.useState([]);
  const [fieldLabel, setFieldLabel] = React.useState([]);
  const contractCustomisationUpdation = useRef();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };
  useEffect(() => {
    props.onLoadingDefault();
    props.getAttributeListData({
      attribute_name: "",
    });
  }, []);
  useEffect(() => {
    var newArray1 = [];
    if (props.attributeData && props.attributeData.length > 0) {
      props.attributeData
        .filter((item) => item.attribute_name === "class_of_trade")
        .map((item) => {
          newArray1.push(item.attribute_value);
        });
      setClassOfTradeArray(newArray1);
      var newArray2 = [];
      props.attributeData
        .filter((item) => item.attribute_name === "account_group")
        .map((item) => {
          newArray2.push(item.attribute_value);
        });
      setAccountGroupArray(newArray2);
      var newArray3 = [];
      props.attributeData
        .filter((item) => item.attribute_name === "customer_classification")
        .map((item) => {
          newArray3.push(item.attribute_value);
        });
      setClassificationArray(newArray3);
      var newArray4 = [];
      props.attributeData
        .filter((item) => item.attribute_name === "customer_industry")
        .map((item) => {
          newArray4.push(item.attribute_value);
        });
      setIndustryTypeArray(newArray4);
    }
  }, [props.attributeData]);
  useEffect(() => {
    if (!contractCustomisationUpdation.current) {
      var newArray5 = [];
      if (
        props.dropdownData &&
        props.dropdownData.records &&
        props.dropdownData.records.length > 0
      ) {
        props.dropdownData.records
          .filter((item) => item.field_id === "customer_type")
          .map((item) => {
            newArray5 = item.drop_down_values;
          });
        setCustomerTypeArray(newArray5);
      }
    }
  });
  useEffect(() => {
    setFieldLabel(props.data);
  }, [props.data]);
  var formData = {
    customer_name: customerName,
    customer_type: customerType,
    street: street,
    city: city,
    region: state,
    postal_code: postalCode,
    country: country,
    customer_industry: industrialType,
    customer_classification: classification,
    class_of_trade: classOfTrade,
    account_group: accountGroup,
    head_office: headOffice,
    payment_terms: paymentTerms,
    // flex_attribute1: flexAttribute1,
    // flex_attribute2: flexAttribute2,
    customer_status: active,
    price_list: priceList,
    customer_number: "1",
  };
  function handleActive(event) {
    setActive(event.target.value);
  }
  function handleCustomerName(newValue) {
    setCustomerName(newValue);
    props.onChange(formData);
  }
  function handleCustomerType(newValue) {
    if (newValue && newValue.length > 0) {
      setCustomerType(newValue);
    } else setCustomerType("");
    props.onChange(formData);
  }
  function handleStreet(event) {
    setStreet(event);
    props.onChange(formData);
  }
  function handleCity(newValue) {
    setCity(newValue.target.value);
    props.onChange(formData);
  }
  function handleState(newValue) {
    setState(newValue.target.value);
    props.onChange(formData);
  }
  function handlePostalCode(newValue) {
    setPostalCode(newValue);
    props.onChange(formData);
  }
  function handleCountry(newValue) {
    setCountry(newValue.target.value);
    props.onChange(formData);
  }
  function handleIndustrialType(newValue) {
    if (newValue && newValue.length > 0) {
      setIndustrialType(newValue);
    } else setIndustrialType("");
    props.onChange(formData);
  }
  function handleClassOfTrade(newValue) {
    if (newValue && newValue.length > 0) {
      setClassOfTrade(newValue);
    } else setClassOfTrade("");
    props.onChange(formData);
  }
  function handleClassification(newValue) {
    if (newValue && newValue.length > 0) {
      setClassification(newValue);
    } else setClassification("");
    props.onChange(formData);
  }
  function handleAccountGroup(newValue) {
    if (newValue && newValue.length > 0) {
      setAccountGroup(newValue);
    } else setAccountGroup("");
    props.onChange(formData);
  }
  function handleHeadOffice(newValue) {
    setHeadOffice(newValue);
    props.onChange(formData);
  }
  function handlePaymentTerms(newValue) {
    if (newValue && newValue.length > 0) {
      setPaymentTerms(newValue);
    } else setPaymentTerms("");
    props.onChange(formData);
  }
  // function handleFlexAttribute1(newValue) {
  //   setFlexAttribute1(newValue);
  //   props.onChange(formData);
  // }
  // function handleFlexAttribute2(newValue) {
  //   setFlexAttribute2(newValue);
  //   props.onChange(formData);
  // }
  function handlePriceList(newValue) {
    setPriceList(newValue);
    props.onChange(formData);
  }
  return (
    <div className={classes.root}>
      <Card>
        <form autoComplete="off" noValidate>
          <CardHeader
            title="HEADER FIELDS"
            titleTypographyProps={{ variant: "h3" }}
          />
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel
                  classes={{ root: classes.fontSetting }}
                  required={fieldLabel['customer_name'] ? fieldLabel['customer_name']['mandatory'] : false}
                  style={{ margin: "13px 0px 0px" }}
                >
                  {fieldLabel['customer_name'] ? fieldLabel['customer_name']['current'] : 'Customer Name'}
                </FormLabel>
                <TextInputWithLabel
                  twoline="true"
                  onChange={handleCustomerName}
                  style={{ padding: "0px 0px" }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required={fieldLabel['customer_type'] ? fieldLabel['customer_type']['mandatory'] : false} style={{ margin: '10px 0px 6px' }}>{fieldLabel['customer_type'] ? fieldLabel['customer_type']['current'] : 'customer_type'}</FormLabel>
                <AllOtherAttributeMasterComponent
                  classes={{ root: classes.fontSetting }}
                  withOutLabel
                  attributeName="customer_type"
                  value={customerType}
                  onChange={handleCustomerType}
                  prevalue={customerType}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
                style={{ paddingTop: "15px !important" }}
              >
                {/* <FormLabel required> Street Address </FormLabel> */}
                {/* <form>
                  <AddressAutofill
                    accessToken={
                      "sk.eyJ1IjoiaGFyc2hpdC1pbWEzNjAiLCJhIjoiY2xnNmxoMmE2MGVuMDNnbzl2aGc4MGwwNiJ9.Pb4F6Zk2NQbyZLBdTC4G_g"
                    }
                  > */}

                <FormLabel
                  classes={{ root: classes.fontSetting }}
                  required={fieldLabel['street'] ? fieldLabel['street']['mandatory'] : false}
                  style={{ margin: "11px 0px 0px" }}
                >
                  {fieldLabel['street'] ? fieldLabel['street']['current'] : 'Street Address'}
                </FormLabel>
                <TextInputWithLabel
                  classes={{ root: classes.fontSetting }}
                  twoline="true"
                  autoComplete="shipping address-line1"
                  onChange={handleStreet}
                />
                {/* </AddressAutofill>
                </form> */}
                {/* <TextInputWithLabel required heading={'Street Address'} twoline='true' onChange={handleStreet} /> */}
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
                style={{ marginTop: 10 }}
              >
                <FormLabel classes={{ root: classes.fontSetting }} required={fieldLabel['city'] ? fieldLabel['city']['mandatory'] : false}>{fieldLabel['city'] ? fieldLabel['city']['current'] : 'City'}</FormLabel>
                <form>
                  <AddressAutofill
                    accessToken={
                      "sk.eyJ1IjoiaGFyc2hpdC1pbWEzNjAiLCJhIjoiY2xnNmxoMmE2MGVuMDNnbzl2aGc4MGwwNiJ9.Pb4F6Zk2NQbyZLBdTC4G_g"
                    }
                  >
                    {/* <input
                                autoComplete="address-level2"
                                value={city}
                                onChange={handleCity}
                                /> */}

                    <OutlinedInput
                      autoComplete="address-level2"
                      value={city}
                      classes={{ root: classes.inputTwoLine }}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </AddressAutofill>
                </form>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <div style={{ marginTop: 15 }}>
                  <FormLabel classes={{ root: classes.fontSetting }} required={fieldLabel['country'] ? fieldLabel['country']['mandatory'] : false}>{fieldLabel['country'] ? fieldLabel['country']['current'] : 'Country'}</FormLabel>
                  <form>
                    <AddressAutofill
                      accessToken={
                        "sk.eyJ1IjoiaGFyc2hpdC1pbWEzNjAiLCJhIjoiY2xnNmxoMmE2MGVuMDNnbzl2aGc4MGwwNiJ9.Pb4F6Zk2NQbyZLBdTC4G_g"
                      }
                    >
                      <OutlinedInput
                        autocomplete="country-name"
                        value={country}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </AddressAutofill>
                  </form>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
                style={{ marginTop: 15 }}
              >
                <FormLabel classes={{ root: classes.fontSetting }} required>{fieldLabel['region'] ? fieldLabel['region']['current'] : 'Country'}</FormLabel>
                <form>
                  <AddressAutofill
                    accessToken={
                      "sk.eyJ1IjoiaGFyc2hpdC1pbWEzNjAiLCJhIjoiY2xnNmxoMmE2MGVuMDNnbzl2aGc4MGwwNiJ9.Pb4F6Zk2NQbyZLBdTC4G_g"
                    }
                  >
                    <OutlinedInput
                      value={state}
                      autocomplete="address-level1"
                      classes={{ root: classes.inputTwoLine }}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </AddressAutofill>
                </form>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                style={{ marginTop: 5 }}
                classes={{ root: classes.gridContainer }}
              >
                <form>
                  <AddressAutofill
                    accessToken={
                      "sk.eyJ1IjoiaGFyc2hpdC1pbWEzNjAiLCJhIjoiY2xnNmxoMmE2MGVuMDNnbzl2aGc4MGwwNiJ9.Pb4F6Zk2NQbyZLBdTC4G_g"
                    }
                  >
                    <FormLabel
                      classes={{ root: classes.fontSetting }}
                      required={fieldLabel['postal_code'] ? fieldLabel['postal_code']['mandatory'] : false}
                      style={{ margin: '9px 0px 0px' }}>
                      {fieldLabel['postal_code'] ? fieldLabel['postal_code']['current'] : 'Postal Code'}
                    </FormLabel>
                    <TextInputWithLabel
                      classes={{ root: classes.fontSetting }}
                      twoline="true"
                      autocomplete="postal-code"
                      onChange={handlePostalCode}
                    />{" "}
                  </AddressAutofill>
                </form>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required={fieldLabel['customer_industry'] ? fieldLabel['customer_industry']['mandatory'] : false} style={{ marginTop: '10px' }}>{fieldLabel['customer_industry'] ? fieldLabel['customer_industry']['current'] : 'customer_industry'}</FormLabel>
                <AllOtherAttributeMasterComponent
                  classes={{ root: classes.fontSetting }}
                  withOutLabel
                  attributeName={"customer_industry"}
                  value={industrialType}
                  onChange={handleIndustrialType}
                  prevalue={industrialType}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required={fieldLabel['customer_classification'] ? fieldLabel['customer_classification']['mandatory'] : false} style={{ marginTop: '10px' }}>{fieldLabel['customer_classification'] ? fieldLabel['customer_classification']['current'] : 'customer_classification'}</FormLabel>
                <AllOtherAttributeMasterComponent
                  classes={{ root: classes.fontSetting }}
                  withOutLabel
                  attributeName="customer_classification"
                  value={classification}
                  onChange={handleClassification}
                  prevalue={classification}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required={fieldLabel['class_of_trade'] ? fieldLabel['class_of_trade']['mandatory'] : false} style={{ marginTop: '10px' }}>{fieldLabel['class_of_trade'] ? fieldLabel['class_of_trade']['current'] : 'class_of_trade'}</FormLabel>
                <AllOtherAttributeMasterComponent
                  classes={{ root: classes.fontSetting }}
                  withOutLabel
                  attributeName="class_of_trade"
                  value={classOfTrade}
                  onChange={handleClassOfTrade}
                  prevalue={classOfTrade}
                />
              </Grid>

              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required={fieldLabel['account_group'] ? fieldLabel['account_group']['mandatory'] : false} style={{ marginTop: '10px' }}>{fieldLabel['account_group'] ? fieldLabel['account_group']['current'] : 'account_group'}</FormLabel>
                <AllOtherAttributeMasterComponent
                  classes={{ root: classes.fontSetting }}
                  withOutLabel
                  attributeName="account_group"
                  value={accountGroup}
                  onChange={handleAccountGroup}
                  prevalue={accountGroup}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                style={{ marginTop: 7 }}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel
                  classes={{ root: classes.fontSetting }}
                  required={fieldLabel['head_office'] ? fieldLabel['head_office']['mandatory'] : false}
                  style={{ marginBottom: 5 }}
                >
                  {fieldLabel['head_office'] ? fieldLabel['head_office']['current'] : 'Head Office'}
                </FormLabel>
                <TextInputWithLabel
                  classes={{ root: classes.fontSetting }}
                  twoline="true"
                  onChange={handleHeadOffice}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required={fieldLabel['payment_terms'] ? fieldLabel['payment_terms']['mandatory'] : false} style={{ marginTop: '10px' }}>{fieldLabel['payment_terms'] ? fieldLabel['payment_terms']['current'] : 'Payment Terms'}</FormLabel>
                <AllOtherAttributeMasterComponent
                  classes={{ root: classes.fontSetting }}
                  withOutLabel
                  attributeName={"payment_terms"}
                  value={paymentTerms}
                  onChange={handlePaymentTerms}
                // prevalue={paymentTerms}
                />
              </Grid>
              {/* <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <TextInputWithLabel
                  classes={{ root: classes.fontSetting }}
                  heading={"Flex Attribute 1"}
                  twoline="true"
                  onChange={handleFlexAttribute1}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <TextInputWithLabel
                  classes={{ root: classes.fontSetting }}
                  heading={"Flex Attribute 2"}
                  twoline="true"
                  onChange={handleFlexAttribute2}
                />
              </Grid> */}
              <Grid
                item
                md={3}
                xs={12}
                classes={{ root: classes.gridContainer }}
                style={{ marginTop: 10, marginBottom: 25 }}
              >
                <FormLabel classes={{ root: classes.fontSetting }} required={fieldLabel['customer_status'] ? fieldLabel['customer_status']['mandatory'] : false} >
                  {fieldLabel['customer_status'] ? fieldLabel['customer_status']['current'] : 'Customer Status'}
                </FormLabel>
                <Select
                  value={active}
                  onChange={handleActive}
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Inactive"}>Inactive</MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                style={{ marginTop: 5 }}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel
                  classes={{ root: classes.fontSetting }}
                  required={fieldLabel['price_list'] ? fieldLabel['price_list']['mandatory'] : false} >
                  {fieldLabel['price_list'] ? fieldLabel['price_list']['current'] : 'Price List'}
                </FormLabel>
                <TextInputWithLabel
                  classes={{ root: classes.fontSetting }}
                  twoline="true"
                  onChange={handlePriceList}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </Card>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(addCustomerMasterXRefUser(data)),
    onLoadingDefault: () => dispatch(getDefaultValuesCustomer()),
    getAttributeListData: (data) => dispatch(getAttributeListData(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    dropdownData: state.customerData.dropdownDataCustomer,
    attributeData: state.attributeListData.AttributeData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);
