import React, { useEffect, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { TextInputWithLabel } from '../../../../../components/Inputs';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getContractDetailsData } from '../../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        width: '50%'
    }
}));

const FlexFields = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const { className } = props;
    const [flexFields5Array, setFlexFields5Array] = React.useState([]);
    const [flexFields6Array, setFlexFields6Array] = React.useState([]);
    const [apiResponse2, setApiResponse2] = React.useState([]);
    const [apiResponse, setApiResponse] = React.useState([]);
    useEffect(() => {
        if (props.queryListData && props.queryListData.records && props.queryListData.records.length > 0) {
            setApiResponse(props.queryListData.records);
        }
    }, [props.queryListData]);
    useEffect(() => {
        var tempArr = [];
        if (props.labelNewAPIData && props.dropdownData && props.dropdownData.records && Object.keys(props.labelNewAPIData).length > 0) {
            var tempArr2 = props.dropdownData.records;
            props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && tempArr2 && tempArr2.length > 0 &&
                tempArr2.map((item, i) => {
                    Object.entries(props.labelNewAPIData)
                        .map(([key, value]) => {
                            if (item.field_id === key) {
                                tempArr2[i].name = value
                            }
                        })
                });
            setApiResponse2(tempArr2);
        }
    }, [props.dropdownData && props.dropdownData.records, props.labelNewAPIData]);

    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (apiResponse2 && apiResponse2.length > 0) {
                    apiResponse2.map((item) => {
                        if (item.field_id === 'flex_fields_5') {
                            setFlexFields5Array(item);
                        }
                        if (item.field_id === 'flex_fields_6') {
                            setFlexFields6Array(item);
                        }
                    })
                }
            }
        }
    }, [apiResponse2]);
    function handleFlexFields5(newValue) {
        props.onChange('flex_fields_5', newValue);
    }
    function handleFlexFields6(newValue) {
        props.onChange('flex_fields_6', newValue);
    }

    return (
        <div
            className={clsx(classes.root, className)}
        >
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="CUSTOMER SPECIFIC FIELDS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <div className={classes.container}>
                        <Grid container >
                            {flexFields5Array && flexFields5Array.enabled &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={flexFields5Array.name ? flexFields5Array.name : 'Flex Fields 5'} required twoline="true" onChange={handleFlexFields5} prevalue={props.data ? props.data.flex_fields_5 : ''} />
                                </Grid>
                            }
                            {flexFields6Array && flexFields6Array.enabled &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={flexFields6Array.name ? flexFields5Array.name : 'Flex Fields 6'} required twoline="true" onChange={handleFlexFields6} prevalue={props.data ? props.data.flex_fields_6 : ''} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div>
    );

};

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FlexFields);