import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    IconButton,
    Typography,
    Dialog, DialogActions, DialogContent, DialogContentText,
    Grid,
    Button, FormLabel, TextField, Card
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import { triggerIntegration, getIntegrationSetupData, getFilterById, imHistory, getLblDispDesFieldValue } from '../../redux/actions';
import { SearchDropDownMasterDataNew } from '../../components/Inputs';
import MaterialTable from 'material-table';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import Moment from 'moment';
import ScaleLoader from "react-spinners/ScaleLoader";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextInputWithLabel, DropdownArray } from '../../components/Inputs';
import { tableIcons } from '../../components/Icons/TableIcons';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { func } from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    roots: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '2rem',
        marginTop: '0.5rem',
        marginBottom: '5px',
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
        width: "100%",
        marginLeft: "1.5rem"
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
}));
const OndemandIntegration = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialog1, setOpenDialog1] = React.useState(false);
    const [openDialog2, setOpenDialog2] = React.useState(false);
    const [openDialog3, setOpenDialog3] = React.useState(false);
    const [integrationSetupData, setIntegrationSetupData] = React.useState([]);
    const [currentID, setCurrentID] = React.useState(0);
    const [filterN, setFilterN] = React.useState([]);
    const [filterNameArray, setFilterNameArray] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [filterName, setFilterName] = React.useState([]);
    const [filterValue, setFiltervalue] = React.useState([]);
    const [filterV, setFilterv] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [activity, setActivity] = React.useState('');
    // const [isSAPCOPA, setIsSAPCOPA] = React.useState(false);
    const [isPostingsMaterialLevel, setIsPostingsMaterialLevel] = React.useState(false);
    // const [isFinancialPostings, setIsFinancialPostings] = React.useState(false);
    const [isPostingsBillingLineLevel, setIsPostingsBillingLineLevel] = React.useState(false);
    // const [isPaymentReporting, setIsPaymentReporting] = React.useState(false);
    const [isPostingsCustomerLevel, setIsPostingsCustomerLevel] = React.useState(false);
    const [isContractHeader, setIsContractHeader] = React.useState(false);
    const [isDocumentManagement, setIsDocumentManagement] = React.useState(false);
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [creationDate, setCreationDate] = React.useState(null);
    const [postingType, setPostingType] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [calenderYearMonth, setCalenderYearMonth] = React.useState('');
    const [contractNumber, setContractNumber] = React.useState('');
    const [contractType, setContractType] = React.useState('');
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [contractGroupArray, setContractGroupArray] = React.useState([]);
    const [contractSubGroupArray, setContractSubGroupArray] = React.useState([]);
    const [contractGroup, setContractGroup] = React.useState("");
    const [contractSubGroup, setContractSubGroup] = React.useState("");
    const [createdOn, setCreatedOn] = React.useState(null);
    const [updatedOn, setUpdatedOn] = React.useState(null);
    const typeRef = useRef(null);

    useEffect(() => {
        props.getIntegrationSetupData(1, 10);
        var data = {
            "data_source": "contract_header",
            "application_type": "Customer Rebate"
        }
        props.fieldDesigner(data, 'post');
    }, []);
    useEffect(() => {
        if (props.integrationSetupData && props.integrationSetupData.records && props.integrationSetupData.records.length > 0) {
            setIntegrationSetupData(props.integrationSetupData)
        }
    }, [props.integrationSetupData]);
    useEffect(() => {
        if (props.filterNameById) {
            setFilterNameArray(props.filterNameById);
        }
    }, [props.filterNameById]);
    // function editIntegration(item) {
    //     setOpen(true);
    //     setCurrentID(item)
    //     setFilterName(item.filter_name)
    //     setFiltervalue(item.filter_value)
    //     props.getFilterById(item);
    // }
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {

            setContractTypeArray(props.dropdownData.field_label_attributes.contract_type);
            setContractGroupArray(props.dropdownData.field_label_attributes.contract_group);
            setContractSubGroupArray(props.dropdownData.field_label_attributes.contract_sub_group);
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])

    function editIntegration(item, data) {
        if (data.activity.includes('Postings - Material Level')) {
            setOpenDialog(true);
            setOpen(false);
            setCurrentID(item);
            setIsPostingsMaterialLevel(true);
            setIsPostingsBillingLineLevel(false);
            setPlannedStartDate(null);
            setCreationDate(null);
            setContractNumber('');
            setPostingType('');
            setStartDate(null);
            setEndDate(null)
            setIsPostingsCustomerLevel(false);
            setIsContractHeader(false);
            setIsDocumentManagement(false);
            setFilterName(item.filter_name);
            setFiltervalue(item.filter_value);
            props.getFilterById(item);
        } else if (data.activity.includes('Postings - Customer Level')) {
            setOpenDialog1(true);
            setOpen(false);
            setOpenDialog(false);
            setIsPostingsMaterialLevel(false);
            setIsPostingsBillingLineLevel(false);
            setPlannedStartDate(null);
            setContractNumber(null);
            setPostingType('');
            setStartDate(null);
            setEndDate(null)
            setCalenderYearMonth(null);
            setIsPostingsCustomerLevel(true);
            setIsContractHeader(false);
            setIsDocumentManagement(false);
            setCurrentID(item);
            setFilterName(item.filter_name);
            setFiltervalue(item.filter_value);
            props.getFilterById(item);
        }
        else if (data.activity.includes('Contract Header')) {
            setOpenDialog2(true);
            setOpen(false);
            setOpenDialog(false);
            setIsPostingsMaterialLevel(false);
            setIsPostingsBillingLineLevel(false);
            setPlannedStartDate(null);
            setContractNumber(null);
            setContractType('');
            setContractGroup('');
            setContractSubGroup('');
            setCreatedOn(null);
            setUpdatedOn(null);
            setPostingType('');
            setStartDate(null);
            setEndDate(null)
            setIsPostingsCustomerLevel(false);
            setIsContractHeader(true);
            setIsDocumentManagement(false);
            setCurrentID(item);
            setFilterName(item.filter_name);
            setFiltervalue(item.filter_value);
            props.getFilterById(item);
        }
        else if (data.activity.includes('Postings - Billing Line Level')) {
            setOpenDialog3(true);
            setOpen(false);
            setOpenDialog(false);
            setIsPostingsMaterialLevel(false);
            setContractNumber(null);
            setPlannedStartDate(null);
            setPostingType('');
            setStartDate(null);
            setEndDate(null)
            setIsPostingsBillingLineLevel(true);
            setIsPostingsCustomerLevel(false);
            setIsContractHeader(false);
            setIsDocumentManagement(false);
            setCurrentID(item);
            setFilterName(item.filter_name);
            setFiltervalue(item.filter_value);
            props.getFilterById(item);
        }
        else if (data.activity.includes('Document Management')) {
            setOpenDialog3(false);
            setOpen(true);
            setOpenDialog(false);
            setIsPostingsMaterialLevel(false);
            setContractNumber(null);
            setPlannedStartDate(null);
            setPostingType('');
            setStartDate(null);
            setEndDate(null)
            setIsPostingsBillingLineLevel(false);
            setIsPostingsCustomerLevel(false);
            setIsContractHeader(false);
            setIsDocumentManagement(true);
            setCurrentID(item);
            setFilterName(item.filter_name);
            setFiltervalue(item.filter_value);
            props.getFilterById(item);
        }
        else {
            setOpenDialog(false);
            setOpen(true);
            setCurrentID(item);
            setIsPostingsMaterialLevel(false);
            setIsPostingsCustomerLevel(false);
            setIsContractHeader(false);
            setIsDocumentManagement(false);
            setIsPostingsBillingLineLevel(false);
            setFilterName(item.filter_name);
            setFiltervalue(item.filter_value);
            props.getFilterById(item);
        }
    }
    async function handleSubmit(id) {
        var data = {
            "filter_name": filterN,
            "filter_value": filterV,
        };
        // var data1 = {
        //     "posting_date": Moment(plannedStartDate).format('YYYY-MM-DD'),
        //     "contract_number": parseInt(contractNumber),
        //     "created_at": Moment(creationDate).format('YYYY-MM-DD'),
        // };
        var data1 = {};

        if (plannedStartDate !== null && plannedStartDate !== undefined) {
            data1.posting_date = Moment(plannedStartDate).format('YYYY-MM-DD');
        }

        if (contractNumber !== null && contractNumber !== undefined && contractNumber !== '') {
            data1.contract_number = parseInt(contractNumber);
        }

        if (creationDate !== null && creationDate !== undefined) {
            data1.created_at = Moment(creationDate).format('YYYY-MM-DD');
        }
        Object.keys(data1).forEach(key => {
            if (data1[key] === null || data1[key] === undefined) {
                delete data1[key];
            }
        });

        var data2 = {};
        if (calenderYearMonth !== null && calenderYearMonth !== undefined) {
            data2.calendar_year_month = calenderYearMonth;
        }
        if (createdOn !== null && createdOn !== undefined) {
            data2.created_on = Moment(createdOn).format('YYYY-MM-DD');
        }
        if (updatedOn !== null && updatedOn !== undefined) {
            data2.updated_on = Moment(updatedOn).format('YYYY-MM-DD');
        }
        if (contractNumber !== null && contractNumber !== undefined && contractNumber !== '') {
            data2.contract_number = parseInt(contractNumber);
        }
        if (contractType !== null && contractType !== undefined && contractType !== '') {
            data2.contract_type = (contractType);
        }
        if (contractGroup !== null && contractGroup !== undefined && contractGroup !== '') {
            data2.contract_group = (contractGroup);
        }
        if (contractSubGroup !== null && contractSubGroup !== undefined && contractSubGroup !== '') {
            data2.contract_sub_group = (contractSubGroup);
        }
        Object.keys(data2).forEach(key => {
            if (data2[key] === null || data2[key] === undefined) {
                delete data2[key];
            }
        });
        var data3 = {};
        if (contractNumber !== null && contractNumber !== undefined && contractNumber !== '') {
            data3.contract_number = parseInt(contractNumber);
        }
        if (postingType !== null && postingType !== undefined && postingType !== '') {
            data3.posting_type = postingType;
        }
        if (startDate !== null && startDate !== undefined) {
            data3.star_date = Moment(startDate).format('MM/DD/YYYY');
        }
        if (endDate !== null && endDate !== undefined) {
            data3.end_date = Moment(endDate).format('MM/DD/YYYY');
        }
        if (plannedStartDate !== null && plannedStartDate !== undefined) {
            data3.posting_date = Moment(plannedStartDate).format('MM/DD/YYYY');
        }
        Object.keys(data3).forEach(key => {
            if (data3[key] === null || data3[key] === undefined) {
                delete data3[key];
            }
        });

        // var data2 = {
        //     "calendar_year_month": calenderYearMonth,
        //     "contract_number": parseInt(contractNumber),
        // }
        if (isPostingsMaterialLevel) {
            props.triggerIntegration(id, data1, 'Postings - Material Level');
        }
        else if (isPostingsCustomerLevel) {
            delete data2['created_on'];
            delete data2['updated_on'];
            delete data2['contract_type'];
            delete data2['contract_group'];
            delete data2['contract_sub_group'];

            props.triggerIntegration(id, data2, 'Postings - Customer Level');
        }
        else if (isContractHeader) {
            delete data2['calendar_year_month']
            props.triggerIntegration(id, data2);
        }
        else if (isPostingsBillingLineLevel) {
            props.triggerIntegration(id, data3, 'Postings - Billing Line Level');
        }
        else if (isDocumentManagement) {
            props.triggerIntegration(id, data, 'Document Management');
        }
        else {
            props.triggerIntegration(id, data);
        }
        setOpen(false);
        setOpenDialog(false);
        setOpenDialog1(false);
        setOpenDialog2(false);
        setOpenDialog3(false);
    }

    function handleClear() {
        setFilterName('');
        setFiltervalue('');
    }
    function handleDelete(id) {
        props.onDelete(id);
    }
    function handleContractType(newValue) {
        setContractType(newValue);
    }
    function handleContractGroup(newValue) {
        setContractGroup(newValue);
    }
    function handleContractSubGroup(newValue) {
        setContractSubGroup(newValue);
    }
    function handleContractNumberAC(value) {
        let newSelected = value;
        if (value.length > 0) {
            setContractNumber(newSelected);
        } else {
            setContractNumber('');
        }
    }
    function handleCreatedOnDate(newValue) {

        if (newValue && newValue.isValid()) {
            setCreatedOn(newValue);
        }
        else {
            setCreatedOn(newValue);
        }
    }
    function handleUpdatedOnDate(newValue) {

        if (newValue && newValue.isValid()) {
            setUpdatedOn(newValue);
        }
        else {
            setUpdatedOn(newValue);
        }
    }
    function handleFilterName(newValue, index) {
        filterN[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        filterV[index] = newValue;
    }
    function handleContractNumber(newValue, index) {
        filterV[index] = newValue;
    }
    function handleOnAdd() {
        filterN[addMember] = ''
        filterV[addMember] = ''
        setAddMember(addMember + 1)
    }
    function handleItem(id) {
        props.getIntegrationSetupData(id);
    }
    useEffect(() => {
        var tempRows = [];
        if (integrationSetupData && integrationSetupData.records && integrationSetupData.records.length > 0)
            integrationSetupData.records
                .filter(e => !["Postings - Material Level", "External Accrual", "External Payment", "COPA"].includes(e.activity))
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        name: e.name,
                        activity: e.activity,
                        datasource: e.datasource,
                        last_data_loaded_on: e.last_data_loaded_on,
                        filter_value_type: e.filter_value_type,
                        filter_datatype: e.filter_datatype,
                        filter_name: e.filter_name,
                        filter_value: e.filter_value,
                        password: e.password,
                        url: e.url,
                        user_id: e.user_id,
                        last_load_success: e.last_load_success
                    });
                })
        setDataRows(tempRows);
    }, [integrationSetupData]);

    const columns = [
        {
            field: 'name',
            title: 'Integration Name',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <a className={classes.hover}
                    onClick={() =>
                        props.imHistory(rowData.id)}
                    style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}> {rowData.name}</a>
        },
        {
            field: 'activity',
            title: 'Activity',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.activity}</a>
        },
        {
            field: 'datasource',
            title: 'Data Source',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.datasource}</a>
        },
        {
            field: 'name',
            title: 'Actions',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => editIntegration(rowData.id, rowData)}
                size="large">
                {props.loading && currentID == rowData.id ?
                    <ScaleLoader color="blue" height={8} />
                    :
                    <LoopIcon fontSize="small" style={{ color: 'green' }} />
                }
            </IconButton></a>
        },
    ];
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <LoadingOverlay
                    active={props.loading}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '50px',
                            '& svg circle': {
                                stroke: '#045FB4'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(52, 52, 52, 0)'
                        }),
                        content: (base) => ({
                            ...base,
                            color: 'black'
                        })
                    }}
                    text='Loading Data. Please wait ...'
                    className={classes.spinner}
                >
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Typography variant="h1" color='primary'>On-Demand Integration</Typography>
                    </div>
                    {integrationSetupData && integrationSetupData.records && integrationSetupData.records.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRows}
                            // style={{marginTop:'-18px'}}
                            options={{
                                maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        /> :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </LoadingOverlay>
            </div >
            <Dialog open={openDialog1}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>

                            <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={'Calendar Year Month'} twoline='true' style={{ marginTop: 1 }}
                                        onChange={(e) => setCalenderYearMonth(e)}
                                        value={calenderYearMonth} />
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={'Contract Number'} twoline='true' style={{ marginTop: 1 }}
                                        onChange={(e) => setContractNumber(e)}
                                        value={contractNumber} />
                                </Grid>

                            </Grid>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpenDialog1(false)} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmit(currentID)} color="primary" autoFocus variant="contained"
                        disabled={calenderYearMonth || contractNumber ? false : true}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog >
            <Dialog open={openDialog}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>

                            <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >
                                <FormLabel>
                                    Posting Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}>
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 4px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 20,
                                                    height: 36,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginRight: 237,
                                                }
                                            }}
                                            value={plannedStartDate}
                                            onChange={(e) => setPlannedStartDate(e)}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{
                                        marginLeft: '-10%',
                                        marginTop: -26,
                                        marginRight: 18
                                    }}
                                >
                                    <TextInputWithLabel heading={'Contract Number'} twoline='true' style={{ marginTop: 1 }}
                                        onChange={(e) => setContractNumber(e)}
                                        value={contractNumber} />
                                </Grid>
                                <FormLabel>
                                    Creation Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginRight: 10 }}
                                    >
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 4px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 20,
                                                    height: 36,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginRight: 237,
                                                }
                                            }}
                                            value={creationDate}
                                            onChange={(e) => setCreationDate(e)}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpenDialog(false)} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmit(currentID)} color="primary" autoFocus variant="contained"
                        disabled={plannedStartDate || creationDate || contractNumber ? false : true}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog >
            <Dialog open={open}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >   {(() => {
                                let td = [];
                                for (let i = 0; i < addMember; i++) {
                                    td.push(<>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >  <DropdownArray heading={i == 0 && 'Filter Name'}
                                            placeholder={'Please select'}
                                            twoline='true'
                                            onChange={(e) => handleFilterName(e, i)}
                                            data={filterNameArray}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        ><TextInputWithLabel heading={i == 0 && 'Value'} twoline='true' onChange={(e) => handleFilterValue(e, i)} />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ paddingTop: 20 }}
                                        >
                                            <IconButton
                                                style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 40 : 25 }}
                                                onClick={handleOnAdd}
                                                classes={{ root: classes.IconButton }}
                                                size="large">
                                                <AddBoxOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </>)
                                }
                                return td;
                            })()}
                            </Grid>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpen(false)} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmit(currentID)} color="primary" autoFocus variant="contained"
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialog2}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <Card className={classes.bodyContainer}>
                                <form
                                    autoComplete="off"
                                    noValidate
                                >
                                    <Grid container
                                    >
                                        <div style={{
                                            padding: "0px  10px 20px 10px", width: "100%"
                                        }}>
                                            <div className={classes.titleSetting}>
                                                <div style={{ alignItems: 'center' }}>
                                                    <Typography classes={{ root: classes.fontSetting1 }} variant="h2" color="secondary" style={{ marginLeft: "20px" }} >
                                                        Contracts Data
                                                        {/* <span style={{ color: 'red', marginLeft: '2px' }}>*</span> */}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                cclasses={{ root: classes.gridContainer }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "4px", marginLeft: "0px" }}>
                                                    <div style={{ width: '40%', marginTop: "-3px" }}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                                            Created on
                                                        </FormLabel>
                                                    </div>
                                                    <div style={{ width: '60%', marginLeft: "0px" }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={createdOn}
                                                                    onChange={handleCreatedOnDate}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                cclasses={{ root: classes.gridContainer }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "9px", marginLeft: "0px" }}>
                                                    <div style={{ width: '40%', marginTop: "-3px" }}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                                            Updated on
                                                        </FormLabel>
                                                    </div>
                                                    <div style={{ width: '60%', marginLeft: "0px" }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={updatedOn}
                                                                    onChange={handleUpdatedOnDate}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div style={{ width: '40%', marginTop: "8px" }}>
                                                        <FormLabel classes={{ root: classes.fontSetting }}
                                                        >
                                                            Contract Number
                                                        </FormLabel>
                                                    </div>
                                                    <div style={{ width: '61.2%', marginRight: "-5px", marginTop: "0px" }}>
                                                        <SearchDropDownMasterDataNew id='contract_number_multiple'
                                                            // multiple
                                                            prevalue={contractNumber}
                                                            // required={calculationMethod ? false : true}
                                                            table='contract_header' keyName='contract_number'
                                                            description={true}
                                                            // heading='Contract Number'
                                                            onChange={handleContractNumberAC}
                                                            applicationType={"Customer Rebate"}
                                                        />
                                                    </div>
                                                </div>

                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div style={{ width: '40%' }}>
                                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                                            {
                                                                contractTypeArray && contractTypeArray.current
                                                                    ? contractTypeArray.current
                                                                    : "Contract Type"
                                                            }
                                                        </FormLabel>
                                                    </div>
                                                    <div style={{ width: '60%', marginTop: "-7px" }}>
                                                        <DropdownArray classes={{ root: classes.fontSetting }}
                                                            // required={contractNumber && contractNumber.length > 0 ? false : true}
                                                            // heading={contractTypeArray && contractTypeArray.current ? contractTypeArray.current : 'Contract Type'}
                                                            defaultValue={contractType}
                                                            data={contractTypeArray && contractTypeArray.drop_down_values ? contractTypeArray.drop_down_values : []}
                                                            placeholder={contractTypeArray && contractTypeArray.drop_down_values && contractTypeArray.drop_down_values.length == 0 && '- Please configure contract type -'}
                                                            twoline='true' onChange={handleContractType} /*error={typeof calculationPeriodStart === 'boolean' && !calculationPeriodStart ? true : false} ref={startRef}*/ error={typeof contractType === 'boolean' && !contractType ? true : false} ref={typeRef} />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            //style={{ marginTop: -15 }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div style={{ width: '40%', marginTop: "-12px" }}>
                                                        <FormLabel
                                                            // required={contractNumber.length > 0 ? false : true}
                                                            classes={{ root: classes.fontSetting }}>
                                                            {
                                                                contractGroupArray && contractGroupArray.current
                                                                    ? contractGroupArray.current
                                                                    : "Contract Group"
                                                            }
                                                        </FormLabel>
                                                    </div>
                                                    <div style={{ width: '60%', marginTop: "-20px" }}>
                                                        <DropdownArray
                                                            classes={{ root: classes.fontSetting }}
                                                            defaultValue={contractGroup}
                                                            data={
                                                                contractGroupArray && contractGroupArray.drop_down_values
                                                                    ? contractGroupArray.drop_down_values
                                                                    : []
                                                            }
                                                            placeholder={
                                                                contractGroupArray && contractGroupArray.drop_down_values &&
                                                                contractGroupArray.drop_down_values.length == 0 &&
                                                                "- Please configure contract type -"
                                                            }
                                                            twoline="true"
                                                            onChange={handleContractGroup}
                                                            error={
                                                                typeof contractGroup === "boolean" && !contractGroup
                                                                    ? true
                                                                    : false
                                                            }
                                                            ref={typeRef}

                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            //style={{ marginTop: -15 }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div style={{ width: '40%', marginTop: "-12px" }}>
                                                        <FormLabel
                                                            // required={contractNumber.length > 0 ? false : true}
                                                            classes={{ root: classes.fontSetting }}>
                                                            {
                                                                contractSubGroupArray && contractSubGroupArray.current
                                                                    ? contractSubGroupArray.current
                                                                    : "Contract Subgroup"
                                                            }
                                                        </FormLabel>
                                                    </div>
                                                    <div style={{ width: '60%', marginTop: "-20px" }}>
                                                        <DropdownArray
                                                            classes={{ root: classes.fontSetting }}
                                                            defaultValue={contractSubGroup}
                                                            data={
                                                                contractSubGroupArray && contractSubGroupArray.drop_down_values
                                                                    ? contractSubGroupArray.drop_down_values
                                                                    : []
                                                            }
                                                            placeholder={
                                                                contractSubGroupArray && contractSubGroupArray.drop_down_values &&
                                                                contractSubGroupArray.drop_down_values.length == 0 &&
                                                                "- Please configure contract type -"
                                                            }
                                                            twoline="true"
                                                            onChange={handleContractSubGroup}
                                                            error={
                                                                typeof contractSubGroup === "boolean" && !contractSubGroup
                                                                    ? true
                                                                    : false
                                                            }
                                                            ref={typeRef}

                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </form>
                            </Card>
                            {/* <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={'Contract Number'} twoline='true' style={{ marginTop: 1 }}
                                        onChange={(e) => setContractNumber(e)}
                                        value={contractNumber} />
                                </Grid>
                            </Grid> */}
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpenDialog2(false)} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmit(currentID)} color="primary" autoFocus variant="contained"
                        disabled={contractNumber || contractType || createdOn || updatedOn || contractGroup || contractSubGroup ? false : true}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog >
            <Dialog open={openDialog3}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >
                                <FormLabel
                                    style={{
                                        marginLeft: 30,
                                        marginTop: 8,
                                        marginBottom: 0,
                                        paddingBottom: 0,
                                    }}
                                >
                                    Posting Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginRight: -20 }}
                                    >
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 4px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 20,
                                                    height: 36,
                                                    border: '1px solid #E0E0E0',
                                                    width: '90%',
                                                    marginRight: 250,
                                                }
                                            }}
                                            value={plannedStartDate}
                                            onChange={(e) => setPlannedStartDate(e)}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{
                                        marginLeft: '-12%',
                                        marginTop: -26,
                                        marginRight: 25
                                    }}
                                >
                                    <TextInputWithLabel heading={'Contract Number'} twoline='true' style={{ marginTop: 1 }}
                                        onChange={(e) => setContractNumber(e)}
                                        value={contractNumber} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{
                                        marginLeft: '-3%',
                                        marginTop: -26,
                                        // marginRight: 18
                                    }}
                                >
                                    <TextInputWithLabel heading={'Posting Type'} twoline='true' style={{ marginTop: 1 }}
                                        onChange={(e) => setPostingType(e)}
                                        value={postingType} />
                                </Grid>
                                <FormLabel
                                    style={{
                                        marginLeft: 32,
                                        marginTop: 8,
                                        marginBottom: 0,
                                        paddingBottom: 0,
                                    }}
                                >
                                    Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginRight: 10 }}
                                    >
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 4px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 20,
                                                    height: 36,
                                                    border: '1px solid #E0E0E0',
                                                    width: '90%',
                                                    marginRight: 215,
                                                }
                                            }}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e)}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <FormLabel
                                    style={{
                                        marginLeft: -125,
                                        marginTop: 8,
                                        marginBottom: 0,
                                        paddingBottom: 0,
                                        textAlign: 'left'
                                    }}
                                >
                                    End Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginRight: 10 }}
                                    >
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 4px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 20,
                                                    height: 36,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginRight: 170,
                                                }
                                            }}
                                            value={endDate}
                                            onChange={(e) => setEndDate(e)}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpenDialog3(false)} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmit(currentID)} color="primary" autoFocus variant="contained"
                        disabled={contractNumber || postingType || startDate || endDate || plannedStartDate ? false : true}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        integrationSetupData: state.customerData.integrationSetupData,
        loading: state.customerData.loading,
        filterNameById: state.customerData.filterNameById,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        triggerIntegration: (id, data, type) => dispatch(triggerIntegration(id, data, type)),
        getIntegrationSetupData: () => dispatch(getIntegrationSetupData(1, 20)),
        getFilterById: (id) => dispatch(getFilterById(id)),
        imHistory: (id) => dispatch(imHistory(id, 1, 10)),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OndemandIntegration);