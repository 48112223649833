import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, FormLabel,
    Select, MenuItem, OutlinedInput, Checkbox, Radio,
    RadioGroup, FormControlLabel, TextField, Card, CardHeader
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../../../../components/Inputs";
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { getPriceCalForList, getQualifierConfig, getCustomerMasterData, getMaterialMasterData, getDefaultValues } from '../../../../../../redux/actions';
import { Datepicker, LabelText, TextInputWithLabel, DropdownArray, MultiSelectDropdown } from '../../../../../../components/Inputs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    rootHeader: {
        paddingLeft: 0,
        marginTop: 0
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0px 0px 20px',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    select: {
        width: '100%',
        marginTop: 5
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        textTransform: 'capitalize'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        // marginTop: 5,
        height: 36,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
}));

const GeneralData = props => {


    const classes = useStyles();
    const { className } = props;
    const [quantity, setQuantity] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateRangeKey, setPricingDateRangeKey] = React.useState('pricing_date');
    const [salesOrganization, setSalesOrganization] = React.useState('');
    const [optionalFieldArray, setOptionalFieldArray] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [salesBundlePricing, setSalesBundlePricing] = React.useState('');
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [materialNumberListArray, setMaterialNumberListArray] = React.useState([]);
    const [materialNumberList, setMaterialNumberList] = React.useState([]);
    const [internalDescription, setInternalDescription] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [validFrom, setValidFrom] = React.useState('');
    const [validTo, setValidTo] = React.useState('');
    const [externalDescription, setExternalDescription] = React.useState('');
    const [currencyArray, setCurrencyArray] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const [quoteType, setQuoteType] = React.useState('');
    const [attributeValues, setAttributeValues] = React.useState({});
    const [quoteNumber, setQuoteNumber] = React.useState('');
    const [emailId, setEmailId] = React.useState('');
    const [pricingUom, setPricingUom] = React.useState('');
    const quoteTypeArray = ['Simple', 'Bundle']
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getConfigData(1, 10);
        if (!props.editMode)
            setPricingDateStart(Moment.utc(new Date().getMonth() + 1 + "/" + (new Date().getDate()) + "/" + new Date().getFullYear()))
        props.getQualifierConfig(1, 0);
        props.onCheckCustomerTable();
        props.getMaterialMasterData()
        props.onLoadingDefault()
    }, []);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = [];
            var tempNameArray = [];
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNumberList(tempArray)
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
            })
            setMaterialNumberList(tempArray)
            setMaterialNumberListArray(tempNameArray);
        }
    }, [props.materialMasterData])
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push(e.formula_name)
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {
        var newArray = [];
        if (props.optionalFieldData && props.optionalFieldData.records) {
            props.optionalFieldData.records.map(e => {
                newArray.push(e.qualifier_key)
            })
            setOptionalFieldArray(newArray)
        }
    }, [props.optionalFieldData]);
    useEffect(() => {
        if (props.tableData && props.tableData.records.length > 0) {
            props.tableData.records.map((item) => {
                if (item.field_id === 'currency') {
                    setCurrencyArray(item);
                }
            })
        }
    }, [props.tableData]);
    function handleAttributeValues(newValue, item) {
        setAttributeValues({ ...attributeValues, [item]: newValue })
    }

    const handleAttributes = (data) => {
        if (data && data.length > 0) {
            setAttributes(data)
        }
        else
            setAttributes(attributes)
    }

    function handleQuantityAdd(newValue) {
        setQuantity(newValue);
    }
    function handleQuantityDelete(newValue) {
        var deletedQuantity = quantity.filter(item => item !== newValue)
        setQuantity(deletedQuantity)
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    function handleTagsAdd(newValue) {
        setTags(newValue);
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }

    function handleInternalDescription(newValue) {
        setInternalDescription(newValue);
    }
    function handleEmailID(newValue) {
        setEmailId(newValue);
    }
    function handleExternalDescription(newValue) {
        setExternalDescription(newValue);
    }
    function handleUom(newValue) {
        setPricingUom(newValue);
    }
    function handleCurrency(e) {
        setCurrency(e.target.value);
    }

    function handleValidFrom(newValue) {
        if (newValue && newValue.isValid())
            setValidFrom(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setValidFrom(newValue);
    }

    function handleValidTo(newValue) {
        setValidTo(newValue);
    }
    function handleQuoteType(newValue) {
        setQuoteType(newValue);
    }
    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    const filterOptionsMaterial = createFilterOptions({
        stringify: (option) => materialNumberListArray[materialNumberList.indexOf(option)] + option
    });
    const primaryData = {
        quote_type: quoteType,
        internal_description: internalDescription,
        external_description: externalDescription,
        currency: currency,
        valid_from: validFrom,
        valid_to: validTo,
        tags: tags,
        pricing_attributes: attributeValues,
        email_id: emailId,
        pricing_uom: pricingUom
    }
    const quoteData = {
        pricing_calculation_formula: priceCalcFormula,
        pricing_date: pricingDateStart ? Moment.utc(pricingDateStart).local() : null,
        quantity: parseInt(quantity),
        calculation_type: "CPQ",
    }
    useEffect(() => {
        props.onChange(primaryData, quoteData, attributeValues)
    })
    useEffect(() => {
        if (props.generalData && props.editMode) {
            setQuoteNumber(props.generalData.quote_number)
            setQuoteType(props.generalData.quote_type)
            setInternalDescription(props.generalData.internal_description)
            setExternalDescription(props.generalData.external_description)
            setCurrency(props.generalData.currency)
            setValidFrom(props.generalData.valid_from && Moment.utc(props.generalData.valid_from).format('MM/DD/YYYY'))
            setValidTo(props.generalData.valid_to && Moment.utc(props.generalData.valid_to).format('MM/DD/YYYY'))
            setAttributeValues(props.generalData.pricing_attributes)
            setAttributes(props.generalData.pricing_attributes && Object.keys(props.generalData.pricing_attributes))
            setTags(props.generalData.tags)
            setPriceCalcFormula(props.generalData.pricing_calculation_formula)
            setPricingDateStart(props.generalData.pricing_date ? Moment.utc(props.generalData.pricing_date).format('MM/DD/YYYY') : null)
            setQuantity([props.generalData.quantity])
            setEmailId(props.generalData.email_id)
            setPricingUom(props.generalData.pricing_uom)
        }
    }, [props.generalData])
    return (
        <div className={clsx(classes.root, className)} >

            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="PRIMARY DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                        classes={{
                            root: classes.rootHeader
                        }}
                    />
                </div>
                <div className={classes.container} >
                    <Grid container >
                        {props.editMode &&
                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <LabelText heading={'Quote Number'} data={quoteNumber} twoline='true' required />
                            </Grid>
                        }
                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Quote Type'} data={quoteType} twoline='true' required />
                                :
                                <DropdownArray heading={'Quote Type'} style={{ marginTop: 0 }} placeholder={' '} twoline='true' {...props} onChange={handleQuoteType} data={quoteTypeArray} error={typeof quoteType === 'boolean' && !quoteType ? true : false} prevalue={quoteType} capitalize />
                            }

                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Internal Description'} data={internalDescription} twoline='true' />
                                :
                                <TextInputWithLabel heading={'Internal Description'} placeholder=' ' twoline='true' {...props} onChange={handleInternalDescription} error={typeof internalDescription === 'boolean' && !internalDescription ? true : false} prevalue={internalDescription} />
                            }
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'External Description'} data={externalDescription} twoline='true' />
                                :
                                <TextInputWithLabel heading={'External Description'} placeholder=' ' twoline='true' {...props} onChange={handleExternalDescription} error={typeof externalDescription === 'boolean' && !externalDescription ? true : false} prevalue={externalDescription} />
                            }
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ marginTop: 5 }}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Pricing Calculation Formula'} data={priceCalcFormula} twoline='true' />
                                :
                                <>
                                    <FormLabel className={classes.chargeBackLabel} required>
                                        Pricing Calculation Formula
                                    </FormLabel>
                                    <Select
                                        value={priceCalcFormula}
                                        onChange={(e) => handlePriceCalFormula(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        // style={{ marginTop: 5 }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </>
                            }
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Quote Date'} data={pricingDateStart} twoline='true' required />
                                :
                                <>
                                    <FormLabel required className={classes.chargeBackLabel}>Quote Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 5,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        height: 37
                                                    }
                                                }}
                                                value={pricingDateStart}
                                                onChange={handlePricingDateStart}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </>
                            }
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ marginTop: 6 }}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Quote Effective Date'} data={validFrom} twoline='true' />
                                :
                                <Datepicker style={{ marginTop: 3 }} heading={'Quote Effective Date'} twoline='true'{...props} onChange={handleValidFrom} error={typeof validFrom === 'boolean' && !validFrom ? true : false} prevalue={validFrom} />
                            }
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Quote Expiry Date'} data={validTo} twoline='true' />
                                :
                                <Datepicker heading={'Quote Expiry Date'} style={{ marginTop: 0 }} twoline='true' {...props} onChange={handleValidTo} error={typeof validTo === 'boolean' && !validTo ? true : false} minimum={validFrom} prevalue={validTo} />
                            }
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ marginTop: "-9px" }}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Currency'} data={currency} twoline='true' />
                                :
                                <div >
                                    <FormLabel className={classes.chargeBackLabel} required>
                                        Currency
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={currency}
                                        onChange={handleCurrency}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {currencyArray && currencyArray.drop_down_values && currencyArray.drop_down_values
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </div>
                            }
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ marginTop: "-9px" }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Quantity'} data={quantity} twoline='true' required />
                                :
                                <>
                                    <FormLabel className={classes.chargeBackLabel} required>
                                        Quantity
                                    </FormLabel>
                                    <ChipInput data={quantity} onChange={handleQuantityAdd} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={quantity}
                                        onAdd={(chips) => handleQuantityAdd(chips)}
                                        onDelete={(chip) => handleQuantityDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </>
                            }
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ marginTop: -15 }}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'UoM'} data={pricingUom} twoline='true' />
                                :
                                <TextInputWithLabel heading={'UoM'} placeholder=' ' twoline='true' {...props} onChange={handleUom} error={typeof pricingUom === 'boolean' && !pricingUom ? true : false} prevalue={pricingUom} />
                            }
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ marginTop: -15 }}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Email ID'} data={emailId} twoline='true' required />
                                :
                                <TextInputWithLabel heading={'Email ID'} placeholder=' ' twoline='true' {...props} onChange={handleEmailID} error={typeof emailId === 'boolean' && !emailId ? true : false} prevalue={emailId} />
                            }
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ marginTop: '-9px' }}
                        > {props.viewMode ?
                            <LabelText heading={'Tags'} data={tags} twoline='true' />
                            :
                            <>
                                <FormLabel className={classes.chargeBackLabel}>
                                    Tags
                                </FormLabel>
                                <ChipInput data={tags} onChange={handleTagsAdd} />
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    onAdd={(chips) => handleTagsAdd(chips)}
                                    onDelete={(chip) => handleTagsDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                            </>
                            }
                        </Grid>

                    </Grid>
                </div>
            </Card>
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="ATTRIBUTES"
                        titleTypographyProps={{ variant: 'h3' }}
                        classes={{
                            root: classes.rootHeader
                        }}
                    />
                </div>
                <div className={classes.container} >
                    <Grid container className={classes.gridContainer}>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ marginTop: -9 }}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.viewMode ?
                                <LabelText heading={'Pricing Attributes'} data={attributes && attributes.map(e => e.replace(/_/g, ' ') + '  ')} twoline='true' required capitalize />
                                :
                                <MultiSelectDropdown heading='Pricing Attributes' listArray={optionalFieldArray} data={attributes} id='attributes' onChange={handleAttributes} />
                            }
                        </Grid>

                        {attributes && attributes.length > 0 && attributes.map(item => {
                            return item == 'customer_number' ?
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.viewMode ?
                                        <LabelText heading={'Customer Number'} data={attributeValues && attributeValues[item] && attributeValues[item].join(",")} twoline='true' required />
                                        :
                                        <>
                                            <FormLabel className={classes.chargeBackLabel} required>
                                                Customer Number
                                            </FormLabel>
                                            <Autocomplete
                                                multiple
                                                limitTags={2}
                                                options={customerNumberList}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                                )}
                                                filterOptions={filterOptions}
                                                value={attributeValues && attributeValues[item]}
                                                style={{ marginBottom: 10, marginTop: 10 }}
                                                onChange={(event, newValue) => handleAttributeValues(newValue, item)}
                                                renderOption={(props, option) => (
                                                    <li {...props}>{customerNameListArray[customerNumberList.indexOf(option)] ? customerNameListArray[customerNumberList.indexOf(option)] : ''} - ({option})  </li>
                                                )}
                                                classes={{
                                                    option: {
                                                        borderBottom: `1px solid red`,
                                                        // Hover
                                                        '&[data-focus="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                        // Selected
                                                        '&[aria-selected="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                    },
                                                    listbox: {
                                                        padding: 0,
                                                    },
                                                    inputRoot: {
                                                        borderRadius: '0px !important'
                                                    }
                                                }}
                                            />
                                        </>
                                    }
                                </Grid>
                                :
                                item == 'material_number' ?
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {props.viewMode ?
                                            <LabelText heading={'Material Number'} data={attributeValues && attributeValues[item] && attributeValues[item].join(",")} twoline='true' required />
                                            :
                                            <>
                                                <FormLabel className={classes.chargeBackLabel} required>
                                                    Material Number
                                                </FormLabel>
                                                <Autocomplete
                                                    multiple
                                                    limitTags={2}
                                                    options={materialNumberList}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important', }} />
                                                    )}
                                                    filterOptions={filterOptionsMaterial}
                                                    value={attributeValues && attributeValues[item]}
                                                    style={{ marginBottom: 10, marginTop: 10 }}
                                                    onChange={(event, newValue) => handleAttributeValues(newValue, item)}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>{materialNumberListArray[materialNumberList.indexOf(option)] ? materialNumberListArray[materialNumberList.indexOf(option)] : ''} - ({option})  </li>
                                                    )}
                                                    classes={{
                                                        option: {
                                                            borderBottom: `1px solid red`,
                                                            // Hover
                                                            '&[data-focus="true"]': {
                                                                backgroundColor: 'red',
                                                                borderColor: 'transparent',
                                                            },
                                                            // Selected
                                                            '&[aria-selected="true"]': {
                                                                backgroundColor: 'red',
                                                                borderColor: 'transparent',
                                                            },
                                                        },
                                                        listbox: {
                                                            padding: 0,
                                                        },
                                                        inputRoot: {
                                                            borderRadius: '0px !important'
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                    </Grid>
                                    :
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}

                                    >
                                        {props.viewMode ?
                                            <LabelText heading={item.replace(/_/g, ' ')} data={attributeValues && attributeValues[item] && attributeValues[item].join(",")} twoline='true' required />
                                            :
                                            <>
                                                <FormLabel className={classes.chargeBackLabel}>
                                                    {item.replace(/_/g, ' ')}
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={attributeValues && attributeValues[item]}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => handleAttributeValues(e.target.value, item)}
                                                />
                                            </>
                                        }
                                    </Grid>;
                        })}

                    </Grid>
                </div>
            </Card>

        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.pricingData.priceCalForListData,
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
        optionalFieldData: state.pricingData.qualifierConfigData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit)),
        onLoadingDefault: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), null, null, null, 'Contract Designer')),
        getQualifierConfig: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);