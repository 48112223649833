import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton, Button, Fab
} from '@mui/material';
import { Edit, AddBoxOutlined, DeleteForever, CloudUpload, LibraryAdd } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getMembershipContractGroup,
    createMembershipContractGroup
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { NavLink as RouterLink } from 'react-router-dom';
import SimpleDialog from '../../../components/Dialog';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 8px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 20
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const MembershipContractGroupUserTable = props => {
    const { className } = props;
    const method = props.type
    const memberID = props.memberId
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);

    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("")

    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    const columns = [
        {
            field: 'contract_group',
            title: 'Contract Group',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_group}</a>
        },
        {
            field: 'contract_group_value',
            title: 'Contract Group Value',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_group_value}</a>
        },
        {
            field: 'contract_group_description',
            title: 'Contract Group Description',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.contract_group_description}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            hidden: method,
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_mem_create_contract_group-' + sessionStorage.getItem('application')) &&
                        <>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editMember(rowData.ID)}
                                size="large">
                                <Edit color="disabled" classes={{ root: classes.caption }} />
                            </IconButton>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleClickOpen(rowData.ID)}
                                size="large">
                                <DeleteForever color="disabled" classes={{ root: classes.caption }} />
                            </IconButton>
                        </>
                    }
                </div>

        },
    ];
    useEffect(() => {
        props.getMembershipDetails(1, 0)
    }, [])
    useEffect(() => {
        var newArray = []
        if (props.membershipData && props.membershipData.records && props.membershipData.records.length > 0) {
            setDataRows(props.membershipData.records);
            setDataCount(props.membershipData.total_record)
        }
    }, [props.membershipData]);
    function editMember(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/membership-contract-group/edit-contract/' + id,
            id: id
        });
    }

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteMembershipData(currentId);
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Contract Group </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_mem_create_contract_group-' + sessionStorage.getItem('application')) &&
                            <>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    classes={{ root: classes.fabContainer }}
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/membership-contract-group/add-contract'
                                >
                                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                                </Fab>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to={{ pathname: '/membership-contract-group-excel-upload' }}
                                >
                                    <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                                </Fab>
                            </>
                        }
                    </div>
                </div>
                {props.membershipData && props.membershipData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            sorting: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataCount, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                <SimpleDialog open={open} content='Do you want to delete this Contract Group ?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        membershipData: state.customerData.membershipContractGroupData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipDetails: (pagination, limit) => dispatch(getMembershipContractGroup(pagination, limit)),
        deleteMembershipData: (id) => dispatch(createMembershipContractGroup(null, id, 'delete')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MembershipContractGroupUserTable);