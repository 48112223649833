import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
// import { Waterfall } from '@ant-design/plots';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, AccumulationLegend, FunnelSeries, AccumulationTooltip, AccumulationDataLabel } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
const FunnelChart = (props) => {

  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: 'white',
      padding: '0px 10px',
      borderRadius: 10,
      height: '100%',
      width: '100%',
    },

  }));
  const classes = useStyles();
  const primaryxAxis = { valueType: 'Category' };
  const primaryyAxis = { title: 'Expenditure' };
  const marker = { dataLabel: { visible: false, font: { color: '#ffffff' } } };
  const connector = { color: '#5F6A6A', width: 2 };
  const tooltip = { enable: true, shared: false };
  const legendSettings = { visible: false };
  const formatter = (v) => v.toFixed(2);
  const datalabel = { visible: true };
  return (
    <div className={classes.root}>
      {/* {config && Object.keys(config).length > 0 &&
        <Waterfall {...config} />
      } */}
      {(props.data && Object.keys(props.data).length > 0) || (props.dataSource && props.dataSource.length > 0) &&
        <AccumulationChartComponent id={props.id ? props.id : 'charts'}
          // pointRender={onPointRender}
          tooltip={{ enable: true, shared: false }}
          enableSmartLabels='true' width={'100%'} height={'100%'}
        >
          <Inject services={[AccumulationLegend, FunnelSeries, AccumulationTooltip, AccumulationDataLabel]} />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective height={'100%'} width={'100%'}
              dataLabel={datalabel}
              innerRadius='40%' radius='100%'
              dataSource={props.dataSource ? props.dataSource :
                [
                  {
                    x: 'Margin Price',
                    y: props.data?.margin_price
                  },
                  {
                    x: 'Off-Invoice Price',
                    y: props.data?.off_invoice_price,
                    color: '#913175'
                  },
                  {
                    x: 'Invoice Price',
                    y: props.data?.invoice_price
                  },
                  {
                    x: 'Base Price',
                    y: props.data?.base_price
                  },
                ]
              }
              xName='x' yName='y' type='Funnel' pointColorMapping='color'>
            </AccumulationSeriesDirective>
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
        // <ChartComponent id='charts'
        // // axisLabelRender={axisLabelRender}
        // primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} tooltip={tooltip} legendSettings={legendSettings} title='Pricing Simulation Analysis'>
        //   <Inject services={[WaterfallSeries, Category, Tooltip, Zoom, Crosshair, Legend, DataLabel]} />
        //   <SeriesCollectionDirective>
        //     <SeriesDirective 
        //dataSource={
        //       [
        //         {
        //           x: 'Base Price',
        //           y: props.data?.base_price
        //         },
        //         {
        //           x: 'On-Invoice Discounts',
        //           y: props.data?.on_invoice_discounts
        //         },
        //         {
        //           x: 'On-Invoice Surcharges',
        //           y: props.data?.on_invoice_surcharges
        //         },
        //         {
        //           x: 'On-Invoice Freight',
        //           y: props.data?.on_invoice_freight
        //         },
        //         {
        //           x: 'Invoice Price',
        //           y: props.data?.invoice_price
        //         },
        //         {
        //           x: 'Off-Invoice Adjustments',
        //           y: props.data?.off_invoice_adjustments
        //         },
        //         {
        //           x: 'Off-Invoice Price',
        //           y: props.data?.off_invoice_price
        //         },
        //         {
        //           x: 'Cost',
        //           y: props.data?.cost
        //         },
        //         {
        //           x: 'Margin Price',
        //           y: props.data?.margin_price
        //         }
        //       ]
        //     }

        //       xName='x' yName='y' name='Pricing Simulation Analysis' type='Waterfall' intermediateSumIndexes={[4]} sumIndexes={[7]} marker={marker} >
        //     </SeriesDirective>
        //   </SeriesCollectionDirective>
        // </ChartComponent>
      }
    </div >
  );
};


export default connect(null, null)(FunnelChart);