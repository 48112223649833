import React, { useEffect } from 'react';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
    Select,
    Grid, MenuItem, Button, OutlinedInput,
    FormLabel, Typography, Checkbox,
    ListItemText, Input
} from '@mui/material';
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import {
    promotionAnalyticsCost, getBudgetingConfig, getCountryData, getPromotionMaterialData
} from '../../../redux/actions';
import Moment from 'moment';
import { makeStyles, useTheme } from "@mui/styles";

import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: 65
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textInput: {
        border: '1px solid #E0E0E0',
        width: '100%',
        borderRadius: 5,
        color: '#1675e0'
    },
    select: {
        width: 209
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    checked: {
        justifyContent: 'center',
        margin: '0px 5px 0px 5px'
    },
    checkedCell: {
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        marginTop: 15
    }
}));

const MonthlyTrendPromoType = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [series, setSeries] = React.useState([]);
    const [options, setOptions] = React.useState({});
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [level, setLevel] = React.useState('');
    const [listOpen, setListOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleStartDate = (e) => {
        setStartDate(e);
        var date = 'start_date=' + Moment(e).format('MM/DD/YYYY') + '&end_date=' + Moment(endDate).format('MM/DD/YYYY')
    }
    const handleEndDate = (e) => {
        setEndDate(e);
        var date = 'start_date=' + Moment(startDate).format('MM/DD/YYYY') + '&end_date=' + Moment(e).format('MM/DD/YYYY')
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const handleSubmit = () => {
        var sd = Moment.utc(startDate).format('YYYY-MM-DD')
        var ed = Moment.utc(endDate).format('YYYY-MM-DD')
        var formData = {
            "start_date": sd,
            "end_date": ed,
            "model_year": year.length > 0 ? year.toString() : null,
            "model_name": name.length > 0 ? name.toString() : null,
            "market_segment": segment.length > 0 ? segment.toString() : null,
            "country": country.length > 0 ? country : null,
            "state": promoRegionValue.length > 0 ? promoRegionValue.toString() : null,
            "division": division.length > 0 ? division.toString() : null
        }
        props.onSubmit(formData);
        setOpen(false);
    }
    useEffect(() => {
        var categories = [];
        var costAvg = [];
        var costExtraRebate = [];
        var costPromoFinance = [];
        var costRebate = [];
        var costSpiff = [];
        var costStdFinance = [];
        if (props.promoAnalyCost) {
            props.promoAnalyCost.map(item => {
                costAvg.push(item['cost_coverage'] ? item['cost_coverage'] : 0);
                costExtraRebate.push(item['cost_extra_rebate'] ? item['cost_extra_rebate'] : 0);
                costPromoFinance.push(item['cost_promo_finance'] ? item['cost_promo_finance'] : 0);
                costRebate.push(item['cost_rebate'] ? item['cost_rebate'] : 0);
                costSpiff.push(item['cost_spiff'] ? item['cost_spiff'] : 0);
                costStdFinance.push(item['cost_std_finance'] ? item['cost_std_finance'] : 0);
                categories.push(item['month'] ? item['month'] : 0);
            })

            setSeries([{
                name: 'Coverage',
                data: costAvg
            },
            {
                name: 'Extra Rebate',
                data: costExtraRebate
            },
            {
                name: 'Promo Finance',
                data: costPromoFinance
            },
            {
                name: 'Rebate',
                data: costRebate
            },
            {
                name: 'Spiff',
                data: costSpiff
            },
            {
                name: 'Standard Finance',
                data: costStdFinance
            }]);

            setOptions(
                {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    fill: {
                        opacity: 0.6
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: categories,
                        labels: {
                            formatter: function (v) {
                                return v ? v : false
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return val.toLocaleString()
                            }
                        }
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },

                }
            )
        }

    }, [props.promoAnalyCost])
    const [country, setCountry] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [states, setStates] = React.useState([]);
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setPromoDivisionArray(props.configData[0].promotion_division)
        }
    }, [props.configData]);
    useEffect(() => {
        props.getConfigData();
        props.getMaterialData();
    }, []);
    useEffect(() => {
        props.getCountryData(country)
    }, [country])
    const handleDrawerClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        var temp = []
        if (props.countryData && props.countryData.length > 0) {
            props.countryData
                .map((item, index) => {
                    temp.push(item.state)
                })
            setStates(temp)
        }
    }, [props.countryData])
    const handleCountry = (e) => {
        setCountry(e.target.value)
    }
    const [promoRegionValue, setPromoRegionValue] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [regionData, setRegionData] = React.useState({});
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (level == 'state')
                setSelected(selected.length === states.length ? [] : states);
            else if (level == 'model year')
                setSelected(selected.length === yearList.length ? [] : yearList);
            else if (level == 'model name')
                setSelected(selected.length === nameList.length ? [] : nameList);
            else
                setSelected(selected.length === segmentList.length ? [] : segmentList);
        }
        else
            setSelected(newSelected)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])
        setLevel('')
    }
    function handleOpen(type) {
        if (type == 'state')
            setSelected(promoRegionValue)
        else if (type == 'market segment')
            setSelected(segment)
        else if (type == 'model year')
            setSelected(year)
        else
            setSelected(name)
        setListOpen(true)
        setLevel(type)
    }
    function onAdd() {
        if (level == 'state')
            setPromoRegionValue(selected)
        else if (level == 'model year')
            setYear(selected)
        else if (level == 'model name')
            setName(selected)
        else
            setSegment(selected)
        setListOpen(false)
    }
    const [yearList, setYearList] = React.useState([]);
    const [nameList, setNameList] = React.useState([]);
    const [segmentList, setSegmentList] = React.useState([]);
    const [year, setYear] = React.useState([]);
    const [name, setName] = React.useState([]);
    const [segment, setSegment] = React.useState([]);
    const isAllSegment =
        segmentList && segmentList.length > 0 && selected.length === segmentList.length;
    const isAllYear =
        yearList && yearList.length > 0 && selected.length === yearList.length;
    const isAllName =
        nameList && nameList.length > 0 && selected.length === nameList.length;
    const isAllState =
        states && states.length > 0 && selected.length === states.length;
    useEffect(() => {
        var yearAll = []
        var nameAll = []
        var segmentAll = []


        if (props.materialData && props.materialData.length > 0) {
            props.materialData.map((item, index) => {
                if (!yearAll.includes(item.material_group1))
                    yearAll.push(item.material_group1)
                if (!segmentAll.includes(item.material_group3))
                    segmentAll.push(item.material_group3)
                if (!nameAll.includes(item.material_group2))
                    nameAll.push(item.material_group2)
            })
            setYearList(yearAll)
            setNameList(nameAll)
            setSegmentList(segmentAll)
        }
    }, [props.materialData])
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar style={{ position: 'absolute', marginTop: 65 }}>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <div
                // direction="column"
                // alignItems="center"
                // justify="center"
                >
                    {series && series.length > 0 && options && Object.keys(options) && Object.keys(options).length > 0 ?
                        <Chart options={options} type="bar" series={series} height={400} />

                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }

                </div>
            </main>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider
                    sx={{
                        opacity: "0.6"
                    }} />
                <Grid container className={classes.root} >
                    <Grid container style={{ margin: 10, padding: 5 }}>

                        <Grid
                            item
                            md={12}
                            xs={12}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    Start Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    End Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }} >
                                Division
                            </FormLabel>
                            <Select
                                disableUnderline
                                value={division}
                                onChange={(event) => setDivision(event.target.value)}
                                displayEmpty
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true
                                })}
                            >

                                {promoDivisionArray && promoDivisionArray
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </Grid>

                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }} >
                                Country
                            </FormLabel>
                            <Select
                                disableUnderline
                                value={country}
                                onChange={handleCountry}
                                displayEmpty
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true
                                })}
                                style={{ textTransform: 'capitalize' }}
                                disabled={props.editMode ? true : false}
                            >
                                <MenuItem value={'CA'} key={'CA'}>
                                    Canada
                                </MenuItem>
                                <MenuItem value={'US'} key={'US'}>
                                    US
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }} >
                                State
                            </FormLabel>

                            <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={promoRegionValue}
                                style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                input={<OutlinedInput />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen('state')}
                                onClose={() => setListOpen(false)}
                                open={listOpen && level == 'state'}
                                renderValue={(appType) => appType.join(", ")}

                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={isAllState} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {states && states
                                    .map(item => {
                                        return (
                                            <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        )
                                    })}
                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>


                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }} >
                                Model Year
                            </FormLabel>
                            <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={year}
                                style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                input={<OutlinedInput />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen('model year')}
                                onClose={() => setListOpen(false)}
                                open={listOpen && level == 'model year'}
                                renderValue={(appType) => appType.join(", ")}

                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={isAllYear} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {yearList && yearList
                                    .map(item => {
                                        return (
                                            <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        )
                                    })}
                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }}>
                                Modal Name
                            </FormLabel>
                            <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={name}
                                style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                input={<OutlinedInput />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen('model name')}
                                onClose={() => setListOpen(false)}
                                open={listOpen && level == 'model name'}
                                renderValue={(appType) => appType.join(", ")}

                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={isAllName} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {nameList && nameList
                                    .map(item => {
                                        return (
                                            <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        )
                                    })}
                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }}>
                                Market Segment
                            </FormLabel>
                            <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={segment}
                                style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                input={<OutlinedInput />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen('market segment')}
                                onClose={() => setListOpen(false)}
                                open={listOpen && level == 'market segment'}
                                renderValue={(appType) => appType.join(", ")}

                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={isAllSegment} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {segmentList && segmentList
                                    .map(item => {
                                        return (
                                            <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        )
                                    })}
                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>
                        </Grid>

                    </Grid >
                    <div style={{ width: '100%', borderBottom: '1px solid #e5e5e5' }} />

                    {/* <Button variant="outlined" color="primary" className={classes.button} onClick={props.onCancel}>
                        Clear
                    </Button> */}
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                        <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit} disabled={startDate && endDate ? false : true}  >
                            Apply
                        </Button>
                    </div>


                </Grid >
            </Drawer >
        </div >
    );
};


const mapStateToProps = state => {
    return {
        promoAnalyCost: state.initialData.promoAnalyCost,
        promoAnalyRevenue: state.initialData.promoAnalyRevenue,
        promoCostPer: state.initialData.promoCostPer,
        countryData: state.promotionData.countryData,
        configData: state.calculationAnalysisData.budgetData,
        materialData: state.promotionData.promotionMaterialData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(promotionAnalyticsCost(formData)),
        getConfigData: () => dispatch(getBudgetingConfig()),
        getCountryData: (country) => dispatch(getCountryData(country)),
        getMaterialData: () => dispatch(getPromotionMaterialData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyTrendPromoType);