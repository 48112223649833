import React, { useEffect, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../components/Inputs';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const GeneralData = props => {
    const classes = useStyles();
    const { className } = props;
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [supplierMasterData, setSupplierMasterData] = React.useState([]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        setSupplierMasterData(props.masdata);
    }, [props.masdata]);

    return (
        <div className={clsx(classes.root, className)}>
            <Card>
                <CardHeader
                    title="HEADER FIELDS"
                    titleTypographyProps={{ variant: 'h3' }}
                />
                {supplierMasterData &&
                    <div className={classes.container}>
                        <Grid container className={classes.gridContainer}>
                            {supplierMasterData.supplier_number &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_number'] ? fieldLabel['supplier_number']['current'] : 'Supplier Number'} data={supplierMasterData.supplier_number} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.supplier_name &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['current'] : 'Supplier Name'} data={supplierMasterData.supplier_name} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.supplier_type &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_type'] ? fieldLabel['supplier_type']['current'] : 'supplier_type'} data={supplierMasterData.supplier_type} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.street &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['street'] ? fieldLabel['street']['current'] : 'Street Address'} data={supplierMasterData.street} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.city &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['city'] ? fieldLabel['city']['current'] : 'City'} data={supplierMasterData.city} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.region &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['region'] ? fieldLabel['region']['current'] : 'State'} data={supplierMasterData.region} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.postal_code &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['postal_code'] ? fieldLabel['postal_code']['current'] : 'Postal Code'} data={supplierMasterData.postal_code} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.country &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['country'] ? fieldLabel['country']['current'] : 'Country'} data={supplierMasterData.country} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.industry_type &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_industry'] ? fieldLabel['supplier_industry']['current'] : 'supplier_industry'} data={supplierMasterData.supplier_type} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.supplier_customer_number &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_customer_number'] ? fieldLabel['supplier_customer_number']['current'] : 'Customer Number'} data={supplierMasterData.supplier_customer_number} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.supplier_status &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_status'] ? fieldLabel['supplier_status']['current'] : 'Supplier Status'} data={supplierMasterData.supplier_status} twoline='true' />
                                </Grid>
                            }
                        </Grid>
                        <Grid container className={classes.gridContainer}>
                            {supplierMasterData.created_by && supplierMasterData.created_by.username &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Created by' data={supplierMasterData.created_by.username} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.created_by && supplierMasterData.created_by.created_at && supplierMasterData.created_by.created_at != '0001-01-01T00:00:00Z' &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Created on' data={supplierMasterData.created_by.created_at} twoline='true' />
                                </Grid>
                            }

                            {supplierMasterData.created_by && supplierMasterData.created_by.username &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Changed by' data={supplierMasterData.created_by.username} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.created_by && supplierMasterData.created_by.updated_at && supplierMasterData.created_by.updated_at != '0001-01-01T00:00:00Z' &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Changed on' data={supplierMasterData.created_by.updated_at} twoline='true' />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </Card>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        // supplierMasterData: state.customerData.supplierMasterViewData
    }
}
export default connect(mapStateToProps, null)(GeneralData);