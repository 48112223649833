import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
// import { Waterfall } from '@ant-design/plots';
import {
  ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject,
  Legend, DateTime, Logarithmic, Tooltip, WaterfallSeries, DataLabel, Category, Crosshair, Zoom, ILoadedEventArgs, ITextRenderEventArgs,
  IAxisLabelRenderEventArgs, ChartTheme
} from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
const WaterFall = (props) => {
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: 10,
      height: '100%',
      width: '100%',
    },

  }));
  const classes = useStyles();
  const [max, setMax] = React.useState(0)
  const [min, setMin] = React.useState(0)
  useEffect(() => {
    if (props.data && Object.keys(props.data).length > 0) {
      var obj = [props.data?.base_price,
      props.data?.on_invoice_discounts,
      props.data?.on_invoice_surcharges,
      props.data?.on_invoice_freight,
      props.data?.invoice_price,
      props.data?.off_invoice_adjustments,
      props.data?.off_invoice_price,
      props.data?.cost,
      props.data?.margin_price
      ];
      setMax(Math.max(...obj))
      setMin(Math.min(...obj))

    }
  }, [props.data])
  const primaryxAxis = { valueType: 'Category', titleStyle: { color: 'purple' } };
  const primaryyAxis = { /*minimum: 0, maximum: max + 10, interval: (parseInt(max) / 5) + (parseInt(max) % 5),*/ titleStyle: { color: 'purple' } };
  const marker = { dataLabel: { visible: true, font: { color: '#ffffff' } } };
  const connector = { color: '#5F6A6A', width: 2 };
  const tooltip = { enable: true, shared: false };
  const legendSettings = { visible: false };
  const formatter = (v) => v.toFixed(2);
  const textRender = (args) => {
    let value = Number(args.text);
    value = value.toFixed(2)
    args.text = value
  };
  var size = { width: '100%' }
  return (
    <div className={classes.root}>
      {(props.data && Object.keys(props.data).length > 0) || (props.dataSource && props.dataSource.length > 0) &&
        <ChartComponent id={props.id ? props.id : 'charts'} width={'100%'} size={size}
          textRender={textRender} titleStyle={{ color: 'green' }} height={props.height}
          primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} tooltip={tooltip} legendSettings={legendSettings}
          title={props.title ? props.title : 'Pricing Simulation Analysis'}>
          <Inject services={[WaterfallSeries, Category, Tooltip, Zoom, Crosshair, Legend, DataLabel]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              sumIndexes={[4, 6, 8]}
              dataSource={props.dataSource ? props.dataSource :
                [
                  {
                    x: 'Base Price',
                    y: props.data?.base_price
                  },
                  {
                    x: 'On-Invoice Discounts',
                    y: props.data?.on_invoice_discounts
                  },
                  {
                    x: 'On-Invoice Surcharges',
                    y: props.data?.on_invoice_surcharges
                  },
                  {
                    x: 'On-Invoice Freight',
                    y: props.data?.on_invoice_freight
                  },
                  {
                    x: 'Invoice Price',
                    y: props.data?.invoice_price
                  },
                  {
                    x: 'Off-Invoice Adjustments',
                    y: props.data?.off_invoice_adjustments
                  },
                  {
                    x: 'Off-Invoice Price',
                    y: props.data?.off_invoice_price
                  },
                  {
                    x: 'Cost',
                    y: props.data?.cost
                  },
                  {
                    x: 'Margin Price',
                    y: props.data?.margin_price
                  }
                ]
              }

              xName={props.xName ? props.xName : 'x'} yName={props.yName ? props.yName : 'y'} name={props.name ? props.name : 'Pricing Simulation Analysis'} type='Waterfall' marker={marker} >
            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      }
    </div >
  );
};


export default connect(null, null)(WaterFall);