import React, { useState, useEffect } from "react";
import { Sidebar } from "./components";
import { BSNavbar } from "./components/SideBar/components/SidebarNav";
import { connect } from "react-redux";
import { useMediaQuery, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import {
  SidebarComponent,
  ToolbarComponent,
  ItemsDirective,
  ItemDirective,
} from "@syncfusion/ej2-react-navigations";
import { MenuComponent } from "@syncfusion/ej2-react-navigations";
import secureLocalStorage from "react-secure-storage";
import { history } from "../../components/Helpers";
import Logo from "./components/SideBar/components/Logo";
import "./Sidemen.css";
import { getProfile, getHelpDeskData } from "../../redux/actions";
import Profile from "./components/SideBar/components/Profile";
import { Divider } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 5,
    height: "100%",
    // [theme.breakpoints.down('lg')]: {
    //   marginLeft: 16,
    //   marginRight: 16,
    //   paddingRight: 0,
    //   paddingLeft: 0,
    // },
    paddingRight: 33,
    paddingLeft: 33,
  },
  shiftContent: {
    paddingLeft: 282,
    //paddingLeft: 310
  },
  content: {
    height: "100%",
  },
  hamburgerSF: {
    position: "fixed",
    right: 10,
    top: 0,
    padding: "2px !important",
    zIndex: 99999,
  },
  hamburger: {
    position: "fixed",
    right: 0,
    top: 0,
    padding: "2px !important",
  },
  divider: {
    background: theme.palette.border.divider,
    margin: "20px 0px 6px 0px",
  },
}));

const Main = (props) => {
  let sidebarobj;
  const [profileDataAPI, setProfileDataAPI] = React.useState([]);
  const pages = ["Products", "Pricing", "Blog"];
  const settings = ["Profile", "Account", "App Launcher", "Logout"];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  useEffect(() => {
    props.getProfile();
  }, []);
  useEffect(() => {
    if (props.profileData && Object.keys(props.profileData).length > 0)
      setProfileDataAPI(props.profileData);
  }, [props.profileData]);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const menuPages = [
    {
      text: "Dashboard",
      iconCss: "icon-tag icon",
      href: "/dashboard",
      displayName: "Dashboard",
    },
    {
      text: "Contracts",
      iconCss: "icon-user icon",
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_contract-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_contracts-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_contract-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_contract-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName:
        sessionStorage.getItem("application") === "Sales Commission"
          ? "Incentive Plan"
          : "Contracts",
    },
    {
      text: "Calculation Simulation",
      iconCss: "icon-user icon",
      href: "/calculation-simulation",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_calc_sim-" + sessionStorage.getItem("application")
            )
          ? false
          : "hide",
      displayName: "Calculation Simulation",
    },
    {
      text: "Supplier Claims",
      iconCss: "icon-user icon",
      href: "/supplier-incoming-claims",
      displayName: "Claims Management ",
    },
    {
      text: "Claims Management",
      iconCss: "icon-user icon",
      href: "/outgoing-claims-post",
      displayName: "Claims Management",
    },
    {
      text: "Accrual Postings",
      iconCss: "icon-user icon",
      displayName: "Accrual Postings",
      items: [{ text: "Accrual Postings" }, { text: "Accrual Approvals" }],
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_accrual_post-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_accrual_posting-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      text: "Payment Postings",
      iconCss: "icon-user icon",
      href: "#",
      items: [{ text: "Payment Postings" }, { text: "Payment Approvals" }],
      displayName: "Payment Postings",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_payment_post-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_payment_posting-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_payment_post-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      text: "Operational Reports",
      iconCss: "icon-user icon",
      href: "/select-operational-reports",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_report-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_report-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_report-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "execute_report-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Operational Reports",
    },
    {
      text: "On Demand Query",
      iconCss: "icon-user icon",
      href: "/dynamic-query",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_report-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_report-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_report-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "execute_report-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "On Demand Query",
    },
    {
      text: "Quota Management",
      iconCss: "icon-user icon",
      href: "/quota-management",
      displayName: "Quota Management",
      hidden:
        (sessionStorage.getItem("application") === "Sales Commission" &&
          secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_report-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_quota-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_quota-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_quota-" + sessionStorage.getItem("application")
              ))) ||
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_quota-" + sessionStorage.getItem("application")
            ) &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + sessionStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + sessionStorage.getItem("application")
                ))) ||
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_quota-" + sessionStorage.getItem("application")
            ) &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + sessionStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + sessionStorage.getItem("application")
                ))) ||
          (sessionStorage.getItem("application") === "Supplier Rebate" &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + sessionStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + sessionStorage.getItem("application")
                ))) ||
          (sessionStorage.getItem("application") === "Customer Rebate" &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + sessionStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + sessionStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + sessionStorage.getItem("application")
                )))
          ? false
          : "hide",
    },
    {
      text: "Performance Metric",
      iconCss: "icon-user icon",
      href: "/performance-metric",
      displayName: "Performance Metric",
      hidden:
        sessionStorage.getItem("application") === "Sales Commission" &&
          secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "get_performance_metric-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_performance_metric-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_performance_metric-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      text: "Performance Weightage",
      iconCss: "icon-user icon",
      href: "/performance-metric-weightage",
      displayName: "Performance Weightage",
      hidden:
        sessionStorage.getItem("application") === "Sales Commission" &&
          secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_performance_metric_weightage-" +
              sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_performance_metric_weightage-" +
                sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_performance_metric_weightage-" +
                sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
    },

    {
      text: "Formula Lab",
      iconCss: "icon-user icon",
      href: "/formula-lab",
      displayName: "Formula Lab",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_formula-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_formula-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_formula-" + sessionStorage.getItem("application")
              ))
          ? sessionStorage.getItem("application") === "Customer Chargeback"
            ? "hide"
            : false
          : "hide",
    },
    {
      text: "Exception Management",
      iconCss: "icon-user icon",
      href: "/exception-management",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_exception-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_exception-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_exception-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Exception Management",
    },
    {
      text: "Potential Action Items",
      iconCss: "icon-user icon",
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "get_contracts-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_incentive_plan_setup-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Potential Action Items",
    },
    {
      text: "Batch Job",
      iconCss: "icon-user icon",
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_batch_job-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_batch_job-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Batch Job",
    },
    {
      text: "Query Field",
      iconCss: "icon-user icon",
      href: "/query-field",

      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights
          ? false
          : "hide",
      displayName: "Query Field",
    },
  ];
  const menuPagesTopBar = [
    {
      text: "Contracts",
      iconCss: "icon-user icon",
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_contract-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_contracts-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_contract-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_contract-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName:
        sessionStorage.getItem("application") === "Sales Commission"
          ? "Incentive Plan"
          : "Contracts",
    },
    {
      text: "Calculation Simulation",
      iconCss: "icon-user icon",
      href: "/calculation-simulation",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_calc_sim-" + sessionStorage.getItem("application")
            )
          ? false
          : "hide",
      displayName: "Calculation Simulation",
    },
    {
      text: "Supplier Claims",
      iconCss: "icon-user icon",
      href: "/supplier-incoming-claims",
      displayName: "Claims Management ",
    },
    {
      text: "Claims Management",
      iconCss: "icon-user icon",
      href: "/outgoing-claims-post",
      displayName: "Claims Management",
    },
    {
      text: "Accrual Postings",
      iconCss: "icon-user icon",
      displayName: "Accrual Postings",
      items: [{ text: "Accrual Postings" }, { text: "Accrual Approvals" }],
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_accrual_post-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_accrual_posting-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      text: "Payment Postings",
      iconCss: "icon-user icon",
      href: "#",
      items: [{ text: "Payment Postings" }, { text: "Payment Approvals" }],
      displayName: "Payment Postings",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_payment_post-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_payment_posting-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_payment_post-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      text: "Operational Reports",
      iconCss: "icon-user icon",
      href: "/select-operational-reports",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_report-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_report-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_report-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "execute_report-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Operational Reports",
    },
    {
      text: "Exception Management",
      iconCss: "icon-user icon",
      href: "/exception-management",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_exception-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_exception-" + sessionStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_exception-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Exception Management",
    },
    {
      text: "Potential Action Items",
      iconCss: "icon-user icon",
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "get_contracts-" + sessionStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_incentive_plan_setup-" + sessionStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Potential Action Items",
    },
  ];

  let menuItems = [
    {
      text: "Overview",
      iconCss: "icon-user icon",
      items: [
        { text: "All Data" },
        { text: "Category2" },
        { text: "Category3" },
      ],
    },
    {
      text: "Notification",
      iconCss: "icon-bell-alt icon",
      items: [
        { text: "Change Profile" },
        { text: "Add Name" },
        { text: "Add Details" },
      ],
    },
    {
      text: "Info",
      iconCss: "icon-tag icon",
      items: [{ text: "Message" }, { text: "Facebook" }, { text: "Twitter" }],
    },
    {
      text: "Comments",
      iconCss: "icon-comment-inv-alt2 icon",
      items: [
        { text: "Category1" },
        { text: "Category2" },
        { text: "Category3" },
      ],
    },
    {
      text: "Bookmarks",
      iconCss: "icon-bookmark icon",
      items: [
        { text: "All Comments" },
        { text: "Add Comments" },
        { text: "Delete Comments" },
      ],
    },
    {
      text: "Images",
      iconCss: "icon-picture icon",
      items: [{ text: "Add Name" }, { text: "Add Mobile Number" }],
    },
    {
      text: "Users ",
      iconCss: "icon-user icon",
      items: [
        { text: "Mobile User" },
        { text: "Laptop User" },
        { text: "Desktop User" },
      ],
    },
    {
      text: "Settings",
      iconCss: "icon-eye icon",
      items: [
        { text: "Change Profile" },
        { text: "Add Name" },
        { text: "Add Details" },
      ],
    },
  ];
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const enableDock = true;
  const dockSize = "50px";
  const width = "220px";
  const target = ".main-menu-content";
  let folderEle =
    '<div class= "e-folder"><div class= "e-folder-name"></div></div>';
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
  //   defaultMatches: true,
  // });
  function toolbarCliked(args) {
    // if (args.item.tooltipText == "Menu") {
    sidebarobj.toggle();
    // }
  }
  const [openSidebar, setOpenSidebar] = useState(true);
  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };
  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  const shouldOpenSidebar = openSidebar;
  const handleMenuClick = (args) => {
    // if(args.element.text === 'Accrual Postings'){
    //     history.push('/accrual-approvals')
    // }
    console.log(args["aria-label"]);
  };
  const handleSettings = (e) => {
    if (e === "App Launcher") {
      history.push("/applauncher");
    }
    if (e === "Logout") {
      localStorage.setItem("loggedIn", false);
      localStorage.clear();
      secureLocalStorage.clear();
      history.push("/login");
    }
  };
  return (
    <>
      <BSNavbar />
      <main
        style={{
          //marginTop: 20,
          padding: "70px 8px 8px 8px",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
            padding: 1,
            marginTop: -2,
            height: "100%",
          }}
        >
          {children}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.profileData,
    helpDeskData: state.HelpDeskData.HelpDeskData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getProfile()),
    getAttributeMapData: () => dispatch(getHelpDeskData("noLimit")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

// import React, { useState } from 'react';
// import { Sidebar } from './components';
// import { useMediaQuery, IconButton } from '@mui/material';
// import { useTheme, makeStyles } from '@mui/styles';
// import MenuIcon from '@mui/icons-material/Menu';
// import clsx from 'clsx';

// const useStyles = makeStyles(theme => ({
//     root: {
//         paddingTop: 5,
//         height: '100%',
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: 16,
//             marginRight: 16,
//             paddingRight: 0,
//             paddingLeft: 0
//         },
//         paddingRight: 33,
//         paddingLeft: 33
//     },
//     shiftContent: {
//         paddingLeft: 282
//         //paddingLeft: 310
//     },
//     content: {
//         height: '100%'
//     },
//     hamburger: {
//         position: 'fixed',
//         right: 0,
//         top: 0,
//         padding: '2px !important'
//     }
// }));

// const Main = props => {

//     const { children } = props;
//     const classes = useStyles();
//     const theme = useTheme();
//     const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
//         defaultMatches: true
//     });
//     const [openSidebar, setOpenSidebar] = useState(isDesktop ? true : false);
//     const handleSidebarOpen = () => {
//         setOpenSidebar(!openSidebar);
//     };
//     const handleSidebarClose = () => {
//         setOpenSidebar(false);
//     };
//     const shouldOpenSidebar = openSidebar;

//     return (
//         <div
//             className={clsx({
//                 [classes.root]: true,
//                 [classes.shiftContent]: isDesktop && openSidebar,
//             })}
//         >
//             {/* Hiding header as per client request */}
//             <IconButton
//                 onClick={handleSidebarOpen}
//                 color='primary'
//                 className={classes.hamburger}
//             >
//                 <MenuIcon />
//             </IconButton>
//             <Sidebar
//                 onClose={handleSidebarClose}
//                 open={shouldOpenSidebar}
//                 variant={'temporary'}
//             />
//             <main>
//                 {children}
//             </main>
//         </div>
//     );
// };

// export default Main;
