import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    MenuItem,
    OutlinedInput
} from '@mui/material';
import { Link } from 'react-router-dom';
import { TextInputWithLabel } from '../../components/Inputs';
import { addEmployeeData } from '../../redux/actions';
import countryList from 'react-select-country-list'
import BeatLoader from "react-spinners/BeatLoader";
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '30px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        marginRight: -25,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 35,
        paddingBottom: 28,
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        //height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        // marginTop: 9
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        //  padding: '0px 45px 0px 45px !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        // height: 50,
        // display: 'flex',
        // alignItems: 'flex-end'
    },
}));

const AddEmployee = props => {
    const classes = useStyles();
    const [employeeName, setEmployeeName] = React.useState('');
    const [employeeType, setEmployeeType] = React.useState('');
    const [department, setDepartment] = React.useState('');
    const [streetAddress, setStreetAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [salesOffice, setSalesOffice] = React.useState('');
    const [terittoryID, setTerittoryID] = React.useState('');
    const [terittoryName, setTerittoryName] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [baseSalary, setBaseSalary] = React.useState('');
    const [active, setActive] = React.useState('');
    const countriesList = useMemo(() => countryList().getLabels(), [])
    function handleEmployeeName(newValue) {
        setEmployeeName(newValue.target.value);
    }
    function handleEmployeeType(newValue) {
        setEmployeeType(newValue.target.value);
    }
    function handleDepartment(newValue) {
        setDepartment(newValue.target.value);
    }
    function handleStreetAddress(event) {
        setStreetAddress(event.target.value);
    }
    function handleCity(newValue) {
        setCity(newValue.target.value);

    }
    function handleState(newValue) {
        setState(newValue.target.value);
    }
    function handlePostalCode(newValue) {
        if (newValue.target.value < 0) {
            newValue.target.value = 0
        } else {
            setPostalCode(newValue.target.value);
        }
    }
    function handleCountry(e) {
        setCountry(e.target.value);
    }
    function handleSalesOffice(newValue) {
        setSalesOffice(newValue.target.value);
    }
    function handleTerittoryID(newValue) {
        setTerittoryID(newValue.target.value);
    }
    function handleTerittoryName(newValue) {
        setTerittoryName(newValue.target.value);
    }
    function handleActive(event) {
        setActive(event.target.value);
    }
    function handleFlexAttribute1(event) {
        setFlexAttribute1(event.target.value);
    }
    function handleFlexAttribute2(event) {
        setFlexAttribute2(event.target.value);
    }
    const handleBaseSalary = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setBaseSalary(e.target.value);
        }
    }
    const handleSubmit = () => {
        var formData = {
            employee_name: employeeName,
            employee_type: employeeType,
            department: department,
            street_address: streetAddress,
            city: city,
            region: state,
            postal_code: postalCode.toString(),
            country: country,
            sales_office: salesOffice,
            territory_name: terittoryName,
            territory_id: terittoryID,
            employee_status: active,
            flex_attribute1: flexAttribute1,
            flex_attribute2: flexAttribute2,
            base_salary: Number(baseSalary)
        };
        props.onSubmit(formData);
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/employee'
                >
                    Employee Master
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Employee</Typography>
            </Breadcrumbs>
            <Card
                style={{ marginTop: 15 }}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container
                            style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                            spacing={2}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel required className={classes.formLabel}>
                                    Employee Name
                                </FormLabel>
                                <OutlinedInput
                                    value={employeeName}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleEmployeeName}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel required className={classes.formLabel}>
                                    Employee Type
                                </FormLabel>
                                <Select
                                    value={employeeType}
                                    onChange={handleEmployeeType}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'Contractor'}>
                                        Contractor
                                    </MenuItem>
                                    <MenuItem value={'Employee'}>
                                        Employee
                                    </MenuItem>
                                </Select>

                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                                required
                            >
                                <FormLabel required className={classes.formLabel}>
                                    Department
                                </FormLabel>
                                <OutlinedInput
                                    value={department}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleDepartment}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel required className={classes.formLabel}>
                                    Street Address
                                </FormLabel>
                                <OutlinedInput
                                    value={streetAddress}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleStreetAddress} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel required className={classes.formLabel}>
                                    Country
                                </FormLabel>
                                <OutlinedInput
                                    value={country}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleCountry} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel required className={classes.formLabel}>
                                    State
                                </FormLabel>
                                <OutlinedInput
                                    value={state}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleState} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel required className={classes.formLabel}>
                                    City
                                </FormLabel>
                                <OutlinedInput
                                    value={city}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleCity} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel required className={classes.formLabel}>
                                    Postal Code
                                </FormLabel>
                                <OutlinedInput
                                    value={postalCode}
                                    inputProps={{ type: "number", step: "1", min: "0" }}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handlePostalCode} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}>
                                    Sales Office
                                </FormLabel>
                                <OutlinedInput
                                    value={salesOffice}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleSalesOffice} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}>
                                    Territory Name
                                </FormLabel>
                                <OutlinedInput
                                    value={terittoryName}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleTerittoryName} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}>
                                    Territory ID
                                </FormLabel>
                                <OutlinedInput
                                    value={terittoryID}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleTerittoryID} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}>
                                    Flex Attribute 1
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute1}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleFlexAttribute1} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}>
                                    Flex Attribute 2
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute2}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleFlexAttribute2} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel className={classes.formLabel}>
                                    Base Salary
                                </FormLabel>
                                <OutlinedInput
                                    value={baseSalary}
                                    inputProps={{ type: "number", step: "1", min: "0" }}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleBaseSalary} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel className={classes.formLabel}>
                                    Employee Status
                                </FormLabel>
                                <Select
                                    value={active}
                                    onChange={handleActive}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'Active'}>
                                        Active
                                    </MenuItem>
                                    <MenuItem value={'Inactive'}>
                                        Inactive
                                    </MenuItem>
                                </Select>

                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Card>
            <div className={classes.submitContainer}>
                <Button color="primary" variant="contained" onClick={handleSubmit} disabled={employeeName
                    && streetAddress && city && state && postalCode && country && department && employeeType ? false : true}>
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Submit'
                    }
                </Button>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addEmployeeData(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);