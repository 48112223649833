import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CircleIcon from '@mui/icons-material/Circle';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../redux/actions';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader';
import { Add } from '@mui/icons-material';
import { SelectedStickers, StickerBankContainer, StickerSelectedContainer, StickerTableHeader } from './Components';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        // padding: '10px 16px',
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 8,
        //backgroundColor: '#E8E8E8'
    },

    divContainerLeft: {
        position: 'relative',
        width: '80%',
        height: '80vh',
        marginLeft: 5,
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 5,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        // backgroundColor: '#E8E8E8',
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    headerLeft: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    toggleButton: {
        marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },

    icons: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    name: {
        display: 'flex',
    },

    filter: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
        border: '1px solid #EEEEEE',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 15,
    },

    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        bottom: '50px',
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
    },

    shareIcon: {
        position: 'absolute',
        bottom: '56px',
        right: '140px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    nextIcon: {
        position: 'absolute',
        bottom: '10px',
        right: '122px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    historyButton: {
        border: '1px solid #D8D8D8',
        margin: '6px 10px',
        padding: 3,
        width: 35,
        height: 35,
    },

    tabHead: {
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
        width: '100vw'
    },

    toggleBtnContainer: {
        border: '0px',
        '&:hover': {
            color: 'black',
        },
    },

    actionIcon: {
        cursor: 'pointer',
    },
    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        // bottom: '20px',
        marginTop: 2,
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
        zIndex: 1
    },


}));


const StickerBankSelected = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { className } = props;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [stickersBankData, setStickersBankData] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [checked, setChecked] = React.useState(false);
    const [isClicked, setIsClicked] = React.useState(false);
    const [sticker, setSticker] = React.useState('');
    const [colorPicker, setColorPicker] = React.useState({});
    const [selectedStickerData, setSelectedStickerData] = React.useState({});
    const [stickerKey, setStickerKey] = React.useState({});
    const [stickersCount, setStickersCount] = React.useState();
    const [iconClicked, setIconClicked] = React.useState({});
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(() => {
        if (props.colorPicker)
            setColorPicker(props.colorPicker)
    }, [props.colorPicker]);
    useEffect(() => {
        if (props.mode == 'edit' || props.mode == 'prefill')
            setIsClicked(true)
    }, [props.mode]);

    useEffect(() => {
        // if (isClicked && productType && selectedCountry)
        //     props.getStickers(productType, selectedCountry);
        // else 
        if (productType && selectedCountry)
            props.getStickers(productType, selectedCountry);
    }, [productType, selectedCountry]);

    useEffect(() => {
        if (props.country)
            setSelectedCountry(props.country[0]);
    }, [props.country]);

    useEffect(() => {
        if (props.productLine)
            setProductType(props.productLine);
    }, [props.productLine]);
    useEffect(() => {
        if (props.stickers) {
            setSelectedStickerData(props.stickers.data);
            setStickerKey(props.stickers.keyData)
            var allIcons
            Object.values(props.stickers.data).forEach(item => {
                allIcons = { ...allIcons, [item.ID]: true }
            })
            setIconClicked(allIcons);
        }

    }, [props.stickers]);

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group'])
            // setProductType(props.filterData['material_master'][0]['material_group'] ? props.filterData['material_master'][0]['material_group'][0] : '')
        }
    }, [props.filterData]);

    useEffect(() => {
        if (props.stickersData && props.stickersData.records) {
            setStickersBankData(props.stickersData.records);
        }
        if (props.stickersData)
            setStickersCount(props.stickersData.total_record);
    }, [props.stickersData]);

    useEffect(() => {
        if (props.prefillData && props.prefillData.selected_stickers && stickersBankData && stickersBankData.length > 0) {

            var keyData = { ...stickerKey, ...props.prefillData.selected_stickers }
            setStickerKey(keyData)
            var data = selectedStickerData;
            stickersBankData.filter(item => props.prefillData.selected_stickers[item.ID])
                .map(item => {
                    var sKey = props.prefillData.selected_stickers?.[item.ID]?.name
                    data = { ...data, [sKey]: { ...item, 'name': sKey } }
                })
            setSelectedStickerData(data)
            props.onChange(data, 'selected_stickers', keyData)
        }
    }, [props.prefillData, stickersBankData]);
    useEffect(() => {
        if (!isClicked)
            props.clicked(isClicked)
    }, [isClicked]);

    // useEffect(() => {
    //     var selectedData = [...selectedStickerData]
    //     if (selectedStickerData && selectedStickerData.length > 0 && props.mode === 'edit') {
    //         selectedStickerData.forEach(item => {
    //             if (!selectedCountry.includes(item.country) || !(item.product_lines.some(item => productType.includes(item)))) {
    //                 selectedData = selectedData.filter(e => e.ID !== item.ID);
    //             }
    //         })
    //         setSelectedStickerData(selectedData);
    //         props.onChange([...selectedData], 'selected_stickers')
    //     }

    // }, [selectedCountry, productType]);

    function handleChange(e) {
        if (productType.includes(e.target.value))
            setProductType(productType.filter(item => item != e.target.value))
        else
            setProductType([...productType, e.target.value]);
    }

    function handleCountry(e) {
        // const country = e == 'CA' ? 'USA' : 'CANADA';
        setSelectedCountry(e);
    }

    function handleDefineMarket(value) {
        setSticker(value)
        //var sKey = props.stickerKeys?.[value]?.name
        props.onChange(value)
    }

    function handleTableData(item) {
        // if (selectedStickerData && !(selectedStickerData[item.ID])) {

        if (stickerKey && !(stickerKey[item.ID])) {
            var sKey = `sticker_${new Date().getTime().toString(36).substr(2, 5)}_${Math.random().toString(36).substr(2, 5)}`
            var data = { ...selectedStickerData, [sKey]: { ...item, 'name': sKey } }
            var keyData = {
                ...stickerKey,
                [item.ID]:
                //[`${'stickerID_' + Object.keys(stickerKey).length + 1}`]:
                {
                    'name': sKey, 'approved': item.is_promo_finance ? false : item.approval_status == 'Approved' ? true : false,
                    'product_lines': item.product_lines, 'country': item.country,
                    'is_promo_finance': item.is_promo_finance
                }
                ,
            }
            setSelectedStickerData(data)
            setStickerKey(keyData)
            props.onChange(data, 'selected_stickers', keyData)
            const newIconClicked = { ...iconClicked, [sKey]: true };
            setIconClicked(newIconClicked);
            var clr = getRandomColor()
            setColorPicker(prev => ({
                ...prev, [sKey]: clr
            }))
            props.handleColor({ ...colorPicker, [sKey]: clr })
        }
        // if (selectedStickerData && !(selectedStickerData.some(obj => obj.ID === item.ID))) {
        //     setSelectedStickerData([...selectedStickerData, item]);
        //     props.onChange([...selectedStickerData, item], 'selected_stickers')
        // }

    }

    function handleDeletedData(item) {
        var sKey = stickerKey?.[item.ID]?.name//convert id to sticker key
        if (selectedStickerData[sKey]) {
            delete selectedStickerData[sKey]
            delete stickerKey[item.ID]
            setSelectedStickerData(selectedStickerData)
            setStickerKey(stickerKey)
            props.onChange(selectedStickerData, 'selected_stickers', stickerKey)
            const newIconClicked = { ...iconClicked };
            if (newIconClicked.hasOwnProperty(item.ID)) {
                delete newIconClicked[item.ID];
            }
            setIconClicked(newIconClicked);
        }
    }

    function handleExpand(e) {
        setIsClicked(e);
        props.clicked(e);
    }

    function handleData(data) {
        handleTableData(data.item);
        setIconClicked(data.iconClicked);
    }
    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    function handleAdd() {
        history.push({
            pathname: '/promotion-sticker-setup/add',
            // data: { variable: selectedCountry, page: "Scenario" }
            data: { variable1: selectedCountry, variable2: Array.isArray(productType) ? productType : [productType], page: "Scenario" }
        });
    }
    return (
        <>
            <div className={classes.root}>
                <StickerTableHeader country={selectedCountry} onCountryChange={handleCountry} page={"Scenario"} productLineData={props.promoProductLines}
                    isDisabled={false} defaultDivison={productType} enabledList={props.productLine} countryList={props.country}
                    onChange={handleChange} stickerName={"Selected Stickers"}
                />

                <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between', border: '1px solid #E8E8E8' }}>
                    <div className={classes.row}>
                        <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={() => props.onChange('return')}>
                            Return
                        </Button>
                        <Typography style={{ fontSize: 13, marginTop: 3 }}>
                            <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }}></CircleIcon>
                            In Progress
                        </Typography>
                    </div>
                    <div className={classes.row} style={{ width: 450, marginRight: 100 }}>
                        {/* <TextField
                            placeholder={"Search Stickers"}
                            onChange={""}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ align: 'center' }}
                        /> */}
                    </div>
                    <div className={classes.row}>
                        <Button variant='contained' color='primary' style={{ marginRight: 10 }} onClick={handleAdd}
                        > Add Sticker</Button>
                        <Button variant='outlined' color='primary' style={{ marginRight: 10 }} onClick={() => props.handleSubmit('partial_save')}
                        > Save Scenario</Button>
                        <Button variant='contained' color='primary' onClick={props.handleNext}
                            disabled={stickersCount > 0 ? false : true}
                        >{isClicked ? 'Apply Stickers' : 'Define Market'}</Button>
                        {/* {!isClicked && 
                            // <Button variant='contained' color='primary' onClick={props.handleNext}
                            //     disabled={stickersCount > 0 ? false : true}
                            // > Apply Stickers</Button> :*}
                            <Button variant='contained' color='primary' onClick={props.handleNext}
                                disabled={stickersCount > 0 ? false : true}
                            >{isClicked?'Apply Stickers': 'Define Market'}</Button>
                       // }
                        {/* <HistoryIcon className={classes.historyButton}></HistoryIcon> */}
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    {!isClicked ?
                        <div className={classes.divContainerLeft}>
                            <LoadingOverlay
                                active={props.loading}
                                styles={{
                                    spinner: (base) => ({
                                        ...base,
                                        width: '50px',
                                        '& svg circle': {
                                            stroke: '#045FB4'
                                        }
                                    }),
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(52, 52, 52, 0)'
                                    }),
                                    content: (base) => ({
                                        ...base,
                                        color: 'black'
                                    })
                                }}
                                spinner={<HashLoader />}
                                className={classes.spinner}

                            >
                                <StickerBankContainer data={handleData} iconClicked={iconClicked} page={"Scenario"} stickerName={"Stickers Bank"}
                                    productType={productType} country={selectedCountry} promoCountry={props.country} onChange={handleCountry} />

                                {/* <Add onClick={handleAdd}
                                    className={classes.addIcon}>
                                </Add> */}

                            </LoadingOverlay>
                        </div> :
                        <div className={classes.divContainerRight}>
                            <div className={classes.headerRight}>
                                <Typography style={{ marginLeft: 5, marginTop: 23 }}>{selectedCountry}</Typography>
                                <Typography style={{ color: 'rgb(182 175 175)', marginTop: 22, marginRight: 10 }}>Stickers Bank</Typography>
                            </div>
                            <div >
                                <Table style={{ marginTop: 15 }}>
                                    <TableHead style={{ backgroundColor: "#E8E8E8", position: "sticky", top: 0, zIndex: 1 }}>
                                        <TableRow>
                                            <TableCell align='right' className={classes.tabHead}  >
                                                {stickersCount} Stickers
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <div style={{ maxHeight: '69vh', overflowY: 'auto' }}>
                                        <LoadingOverlay
                                            active={props.loading}
                                            styles={{
                                                spinner: (base) => ({
                                                    ...base,
                                                    width: '25px',
                                                    '& svg circle': {
                                                        stroke: '#045FB4'
                                                    }
                                                }),
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: 'rgba(52, 52, 52, 0)'
                                                }),
                                                content: (base) => ({
                                                    ...base,
                                                    color: 'black'
                                                })
                                            }}
                                            spinner={<HashLoader />}
                                            className={classes.spinner}

                                        >

                                            {stickersBankData && stickersBankData.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tabHead} style={{ cursor: 'pointer' }} onClick={() => handleTableData(item)}>
                                                        <LibraryAddIcon style={{ color: iconClicked && iconClicked[item.ID] ? '#ffdd42' : 'grey', marginRight: 4, marginBottom: -8 }} />
                                                        {/* {(item.promo_variability ? 'PV -' : '') + (item.version > 1 ? `V${item.version} - ${item.sticker_description}` : item.sticker_description)} */}
                                                        {item.promo_variability ? `PV - (${item.sticker_name})` : item.sticker_name}
                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                        </LoadingOverlay>
                                    </div>
                                    {/* <Add onClick={handleAdd}
                                        className={classes.addIcon}>
                                    </Add> */}
                                </Table>
                            </div>
                        </div>}
                    {isClicked ?
                        <div className={classes.divContainerLeft}>
                            <StickerSelectedContainer onExpand={handleExpand} data={selectedStickerData} handleNext={props.handleNext}
                                onDelete={handleDeletedData} country={selectedCountry} onChangeCountry={handleCountry} promoCountry={props.country}
                                onChange={handleDefineMarket} stickerName={"Selected Stickers"} colorPicker={colorPicker}
                            />
                        </div> :
                        <div className={classes.divContainerRight}>
                            <SelectedStickers onExpand={handleExpand} onDelete={handleDeletedData}
                                onChange={props.handleNext} page={true} stickerSelectedData={selectedStickerData}
                                country={selectedCountry} name={'Selected Stickers'} colorPicker={colorPicker}
                            />
                        </div>}
                </div>

            </div>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig('promotionConfig')),
        getStickers: (productType, country, id, page, limit) => dispatch(getPromoStickers(productType, country)),
        onAction: (data, type, id) => dispatch(createStickers(data, type, id)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        configData: state.calculationAnalysisData.budgetData,
        stickersData: state.promotionData.promoStickers,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerBankSelected);