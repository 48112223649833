import React, { useEffect } from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import {
    Grid, Table, TableBody, TableContainer, TableHead,
    TableRow, TableCell, Breadcrumbs, Typography,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadRebateTrendsSecondAPI, loadValuesBasedOnAppTypeAndField, getDefaultValuesAllDataNew } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '16px 0px 16px 0px'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const RebateTrends = (props) => {
    const classes = useStyles();
    const [appId, setAppId] = React.useState(0);
    const [labelNewData, setLableNewData] = React.useState([]);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setAppId(appId)
        sessionStorage.setItem('rebate-trend-second-page', true)
    }, []);

    useEffect(() => {
        props.loadSourceDataType(sessionStorage.getItem("application"), 'source_data_type');
    }, []);
    useEffect(() => {
        if (props.labelNewAPIData) {
            setLableNewData(props.labelNewAPIData.field_label_attributes
            )
        }
    }, [props.labelNewAPIData])
    const getCurrentValueForKey = (key) => {
        if (labelNewData && key in labelNewData) {
            return labelNewData[key].current;
        }
        return key;
    };
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>

                    <Grid container style={{ margin: "-15px 0px 1px 15px", borderRadius: 5 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link variant='h4' classes={{
                                root: classes.link
                            }}
                                to='/rebate-trends'
                            >
                                Rebate Trends
                            </Link>
                            <Typography color="textPrimary" variant='h4'>Contract Postings Trend</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={6}
                        className={classes.container}
                    >
                        {props.accrualVarianceGraphData && Object.keys(props.accrualVarianceGraphData).length > 0 &&
                            <div style={{ margin: "5px 10px 0px 14px" }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            {Object.keys(props.accrualVarianceGraphData)
                                                .filter(([k, v], index) => index === 0)
                                                .map(item => {
                                                    return (
                                                        <TableRow >
                                                            <TableCell align='center'>Contract No - {appId}</TableCell>
                                                            {props.accrualVarianceGraphData[item].map(detail => {
                                                                return (
                                                                    <>
                                                                        <TableCell align='center'>{detail.billing_date}</TableCell>
                                                                        <TableCell align='center'>Variance</TableCell>
                                                                    </>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    )
                                                })}

                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {Object.keys(props.accrualVarianceGraphData).map(item => {
                                                return (
                                                    <TableRow key={item}>
                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                            {getCurrentValueForKey(item) || item.replace(/_/g, ' ')}
                                                        </TableCell>
                                                        {props.accrualVarianceGraphData[item].map(detail => {
                                                            return (
                                                                <>
                                                                    <TableCell align='center'>{detail.key_figure_value ? parseFloat(detail.key_figure_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</TableCell>
                                                                    <TableCell align='center'>{parseFloat(detail.variance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                                </>
                                                            )
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }

                    </Grid>
                </Grid>
            </div >
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        accrualVarianceGraphData: state.initialData.rebateTrendsSecond,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        labelNewAPIData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadAccrualVarianceGraph: (s, e, type, applicationType, sourceDataType) => dispatch(loadRebateTrendsSecondAPI(s, e, type, applicationType, sourceDataType)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RebateTrends);