import React from "react";
import { DocLevel } from ".";
import {
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
}));

const CreateLevels = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h4"
          classes={{
            root: classes.link,
          }}
          to="/document-workflow"
        >
          Approval Workflow
        </Link>
        <Typography color="textPrimary" variant="h4">
          Add Approval Workflow
        </Typography>
      </Breadcrumbs>
      <div className={classes.bodyContainer}>
        <DocLevel mode="create" />
      </div>
    </div>
  );
};

export default CreateLevels;
