import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { WaterFall, FunnelChart, LineChartGraph } from "./Graphs";
import Moment from "moment";
import { WidthProvider, Responsive } from "react-grid-layout";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
}));
const PricingApp = (props) => {
  const classes = useStyles();

  const ResponsiveReactGridLayout = WidthProvider(Responsive);
  const originalLayouts = getFromLS("layouts") || {};

  useEffect(() => {}, []);

  function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }

  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value,
        })
      );
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <Grid container className={classes.container} spacing={2}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <WaterFall />
        </Grid>
        <Grid container lg={12} sm={12} xl={12} xs={12}>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <FunnelChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <LineChartGraph />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(null, null)(PricingApp);
