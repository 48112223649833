
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, Fab,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { createUoM, getUoMData } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../components/Dialog';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));

const UoMMain = props => {
    useEffect(() => {
        props.getAllUom(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [uomAll, setUoMAll] = React.useState([]);

    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("")

    useEffect(() => {
        if (props.uomAll) {
            setUoMAll(props.uomAll)
        }
    }, [props.uomAll])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAllUom(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAllUom(1, event.target.value);
    };
    function editUom(id) {
        history.push({
            pathname: '/uom/edit-uom/' + id,
            id: id
        });
    }

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteUom('', 'delete', currentId);
        } else {
            setOpen(false);

        }
    }

    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> UoM </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_uom-' + sessionStorage.getItem('application')) &&

                        <>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                onClick={() => history.push('/uom/add-uom')}
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                        </>
                    }
                    {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlinedIcon />}
                            classes={{
                                startIcon: !isDesktop && classes.startIcon,
                                root: !isDesktop && classes.container
                            }}
                            onClick={() => history.push('/uom/add-uom')}
                        >
                            {isDesktop ? 'Add UoM' : ''}
                        </Button> */}
                </div>
                {uomAll && uomAll.total_record > 0 ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Source UoM</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Target UoM</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Conversion Factor</TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {uomAll.records.map(item => {
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.source_uom}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.target_uom}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.conversion_factor}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_uom-' + sessionStorage.getItem('application')) &&
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => editUom(item.ID)}
                                                        size="large">
                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>}
                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_uom-' + sessionStorage.getItem('application')) &&
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => handleClickOpen(item.ID)}
                                                        size="large">
                                                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={props.uomAll && props.uomAll.records ? props.uomAll.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        />
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        uomAll: state.uomData.uomData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUom: (pagination, limit) => dispatch(getUoMData(pagination, limit)),
        deleteUom: (data, type, id) => dispatch(createUoM(data, type, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UoMMain);