import React, { useEffect } from 'react';
import {
    Grid, Button, Typography,
    FormLabel, Select, MenuItem, OutlinedInput, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getPriceCalForList, runPriceCalcForSimulation, getQualifierConfig } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from '../../../components/Inputs';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17,
        marginBottom: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 17
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 36,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
    chipcontainers: {
        marginTop: 17
    }
}));

const PriceAdjustmentSimulation = props => {
    const classes = useStyles();
    const { className } = props;
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateEnd, setPricingDateEnd] = React.useState(null);
    const [salesOrganization, setSalesOrganization] = React.useState([]);
    const [optionalFieldArray, setOptionalFieldArray] = React.useState([]);
    const [historicalStartDate, setHistoricalStartDate] = React.useState(null);
    const [historicalEndDate, setHistoricalEndDate] = React.useState(null);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getConfigData(1, 10);
        props.getQualifierConfig(1, 0);
    }, []);
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData
                .map(e => {
                    newArray.push(e.formula_name)
                })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {
        var newArray = [];
        if (props.optionalFieldData && props.optionalFieldData.records) {
            props.optionalFieldData.records.map(e => {
                newArray.push(e.qualifier_key)
            })
            setOptionalFieldArray(newArray)
        }
    }, [props.optionalFieldData]);
    const [open, setOpen] = React.useState(false);

    function handleCustomerNumber(newValue) {
        setCustomerNumber([...customerNumber, newValue])
    }
    function handleCustomerNumberDelete(newValue) {
        var deletedCustomerNumber = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomerNumber)
    }
    function handleAdd(newValue, type) {
        if (type == 'material')
            // setMaterialNumber([...materialNumber, newValue])
            setMaterialNumber(newValue)
        else
            // setSalesOrganization([...salesOrganization, newValue])
            setSalesOrganization(newValue)
    }
    function handleDelete(newValue, type) {
        if (type == 'material') {
            var deletedMaterialNumber = materialNumber.filter(item => item !== newValue)
            setMaterialNumber(deletedMaterialNumber)
        }
        else {
            var deletedSalesOrg = salesOrganization.filter(item => item !== newValue)
            setSalesOrganization(deletedSalesOrg)
        }
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }

    function handlePricingDateStart(newValue) {
        setPricingDateStart(Moment(newValue));
    }
    function handlePricingDateEnd(newValue) {
        setPricingDateEnd(Moment(newValue));
    }
    function handleHistoricalStart(newValue) {
        setHistoricalStartDate(Moment(newValue));
    }
    function handleHistoricalEnd(newValue) {
        setHistoricalEndDate(Moment(newValue));
    }

    const handleClear = () => {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setPricingDateEnd(null);
        setPricingDateStart(null);
        setPriceCalcFormula('');
        setSalesOrganization([]);
        setHistoricalStartDate(null)
        setHistoricalEndDate(null)
    }
    const handleClickOpen = () => {
        setOpen(true)
    };


    const handleDialog = (bool) => {
        if (bool) {
            const formData = {
                "formula_procedure": priceCalcFormula,
                "start_date": pricingDateStart ? Moment.utc(pricingDateStart).format('YYYY-MM-DD') : null,
                "end_date": pricingDateEnd ? Moment.utc(pricingDateEnd).format('YYYY-MM-DD') : null,
                "historical_start_date": historicalStartDate ? Moment.utc(historicalStartDate).format('YYYY-MM-DD') : null,
                "historical_end_date": historicalEndDate ? Moment.utc(historicalEndDate).format('YYYY-MM-DD') : null,

                "price_attribute": {
                    'Material Attribute': {
                        'material_number': materialNumber
                    }
                }
            };
            if (salesOrganization && salesOrganization.length > 0)
                formData["price_attribute"]['Organization Attribute'] = {
                    'sales_organization': salesOrganization
                }
            props.onSubmit(formData);
            setOpen(false);
            handleClear();
        }
        else {
            setOpen(false);
        }

    }


    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h2">
                        Price Adjustment Simulation
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}

                            >


                                <div className={classes.contractContiner}>
                                    <FormLabel required>Evaluation Period</FormLabel>
                                    <div style={{ display: 'flex' }}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: !pricingDateStart && pricingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={pricingDateStart}
                                                    onChange={handlePricingDateStart}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                        {/* </div>
                                    <div className={classes.contractContiner}> */}
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10
                                                        }
                                                    }}
                                                    value={pricingDateEnd}
                                                    onChange={handlePricingDateEnd}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={pricingDateStart ? pricingDateStart : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div className={classes.contractContiner}>
                                    <FormLabel required>Historical Period</FormLabel>
                                    <div style={{ display: 'flex' }}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: !historicalStartDate && historicalEndDate ? '1px solid red' : '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={historicalStartDate}
                                                    onChange={handleHistoricalStart}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                        {/* </div>
                                    <div className={classes.contractContiner}> */}
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10
                                                        }
                                                    }}
                                                    value={historicalEndDate}
                                                    onChange={handleHistoricalEnd}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={historicalStartDate ? historicalStartDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleAdd(chips, 'material')}
                                    onDelete={(chip) => handleDelete(chip, 'material')}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipcontainers}>
                                    <ChipInput data={materialNumber} onAdd={handleAdd} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Pricing Calculation Formula
                                </FormLabel>
                                <Select
                                    value={priceCalcFormula}
                                    onChange={(e) => handlePriceCalFormula(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            // style={{display: (optionalFieldArray.includes('sales_organization') ? 'block' : 'none')}}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Sales Organization
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={salesOrganization}
                                    onAdd={(chips) => handleAdd(chips, 'sales_org')}
                                    onDelete={(chip) => handleDelete(chip, 'sales_org')}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipcontainers}>
                                    <ChipInput data={salesOrganization} onAdd={handleAdd} />
                                </div>
                                {/* <OutlinedInput
                                    value={salesOrganization}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setSalesOrganization(e.target.value)} 
                                />                                     */}
                            </Grid>


                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    priceCalcFormula && pricingDateStart && pricingDateEnd && historicalEndDate && historicalStartDate
                                        ? false : true}

                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Price Adjustment Simulation?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay >
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.pricingData.priceCalForListData,
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
        optionalFieldData: state.pricingData.qualifierConfigData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit, 'Price Optimization')),
        onSubmit: (formData) => dispatch(runPriceCalcForSimulation(formData, 'priceAdjustmentSimulation')),
        getQualifierConfig: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PriceAdjustmentSimulation);