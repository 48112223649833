import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton,
    Dialog, FormLabel, Tooltip,
    DialogContent, Button, DialogActions, Select, MenuItem, Fab
} from '@mui/material';
import clsx from 'clsx';
import Moment from 'moment';
import { Edit, AccountBalance, LowPriority, CallSplit, CloudDownload, Send, SendOutlined, CloudUpload } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import { getOutgoingClaims, getOutgoingClaimDownloadFormat, outgoingClaimFinacialPosting, handleSplitOutgoingClaim } from '../../redux/actions';
import { connect } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getEnv } from "../../env";
import { history } from '../../components/Helpers';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    paper: { minWidth: window.screen.width * .30 },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));


const handleItemClick = (id) => {
    history.push('/customer-outgoing-claims/view-claims/' + id);

}
const OutgoingClaims = (props) => {
    const classes = useStyles();
    var baseUrl = getEnv()
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentClaimNumber, setCurrentClaimNumber] = React.useState('');
    const [supplierName, setSupplierName] = React.useState('');
    const [claimDate, setClaimDate] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false)
    const [openDialog2, setOpenDialog2] = React.useState(false)
    const [format, setFormat] = React.useState('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataRows, setDataRows] = React.useState([]);

    const handleEdit = (id) => {
        history.push('/customer-outgoing-claims/edit-claims/' + id);
    }
    useEffect(() => {
        props.getOutgoingClaims();
        props.getOutgoingClaimDownloadFormatData();
    }, []);
    const handleCancelDialog = () => {
        setCurrentClaimNumber('');
        setFormat('');
        setOpenDialog(false)
        setOpenDialog2(false)
    }
    const handleDownloadEDIFile = (item) => {
        var claimdate = Moment(item.claim_date).format('YYYY/MM/DD')
        fetch(baseUrl['BASE_URL'] + 'download-outgoing-claims-edi?claim_number=' + item.claim_number, {
            //fetch('https://demo-api.ima360.app/v1/api/download-outgoing-claims-edi?claim_number=' + item.claim_number, {

            method: 'GET',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                var date = Date.now();
                var file = item.supplier_name + "EDI-" + claimdate + "-" + item.claim_number;
                link.setAttribute('download', file); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    }
    const handleSendEmailEDIFile = (item) => {
        var claimdate = Moment(item.claim_date).format('YYYY/MM/DD')
        fetch(baseUrl['BASE_URL'] + 'download-outgoing-claims-edi?claim_number=' + item.claim_number + '&send_mail=true', {
            // fetch('https://demo-api.ima360.app/v1/api/download-outgoing-claims-edi?claim_number=' + item.claim_number+'&send_mail=true', {

            method: 'GET',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                }
                return response.json()
            })
            .then(response => {
                if (response && response.message) {
                    toast.error(response.message)
                }
                else {
                    toast.success('Email Sent Successfully.')
                }
            })
    }

    const handleDownload = (item) => {
        setOpenDialog(true);
        setCurrentClaimNumber(item.claim_number);
        setSupplierName(item.supplier_name);
        setClaimDate(Moment(item.claim_date).format('YYYY/MM/DD'))
    }

    const handleDownloadButttonConfirm = () => {
        fetch(baseUrl['BASE_URL'] + 'download-outgoing-claims?format_id=' + format + '&claim_number=' + currentClaimNumber, {
            // fetch('https://stage-api.ima360.app/v1/api/download-outgoing-claims?format_id=' + format + '&claim_number=' + currentClaimNumber, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                var date = Date.now();
                var file = supplierName + "-" + claimDate + "-" + currentClaimNumber;
                link.setAttribute('download', file + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        setOpenDialog(false)
    }
    const handleSendEmail = (item) => {
        setOpenDialog2(true);
        setCurrentClaimNumber(item.claim_number);
        setSupplierName(item.supplier_name);
        setClaimDate(Moment(item.claim_date).format('YYYY/MM/DD'))
    }

    const handleSendEmailConfirm = () => {
        fetch(baseUrl['BASE_URL'] + 'download-outgoing-claims?format_id=' + format + '&claim_number=' + currentClaimNumber + '&send_mail=true', {
            // fetch('https://stage-api.ima360.app/v1/api/download-outgoing-claims?format_id=' + format + '&claim_number=' + currentClaimNumber+'&send_mail=true', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                }
                return response.json()
            })
            .then(response => {
                if (response && response.message) {
                    toast.error(response.message)
                }
                else {
                    toast.success('Email Sent Successfully.')
                }
            })
        setOpenDialog2(false)
    }
    const handleSplit = (item) => {
        console.log(item)
        props.handleSplit(item.claim_number)
    }
    const handleReprocess = (item) => {
        var claimData = [];
        claimData.push((item.claim_number).toString())
        var data = {
            "claim_number": claimData
        }
        props.getOutgoingClaimsPayment(null, data)
    }
    useEffect(() => {
        var tempRows = [];

        if (props.outgoingClaimListData && props.outgoingClaimListData.length > 0) {
            props.outgoingClaimListData
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        claim_date: e.claim_date ? Moment(e.claim_date).format('MM/DD/YYYY') : '',
                        claim_number: e.claim_number,
                        supplier_number: e.supplier_number,
                        supplier_name: e.supplier_name,
                        claim_status: e.claim_status,
                        claim_amount: e.claim_amount ? e.claim_amount.toFixed(2) : '',
                        approved_amount: e.approved_amount ? e.approved_amount.toFixed(2) : '',

                    });
                })
            setDataRows(tempRows);
        }
    }, [props.outgoingClaimListData]);
    const columns = [
        {
            field: 'claim_date',
            title: 'Claim Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ cursor: 'pointer' }} onClick={() => handleItemClick(rowData.claim_number)}> {rowData.claim_date}</a>
        },
        {
            field: 'claim_number',
            title: 'Claim Number',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} style={{ cursor: 'pointer' }} onClick={() => handleItemClick(rowData.claim_number)}> {rowData.claim_number}</a>
        },
        {
            field: 'supplier_number',
            title: 'Supplier Number',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ cursor: 'pointer' }} onClick={() => handleItemClick(rowData.claim_number)}> {rowData.supplier_number}</a>
        },
        {
            field: 'supplier_name',
            title: 'Supplier Name',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ cursor: 'pointer' }} onClick={() => handleItemClick(rowData.claim_number)}> {rowData.supplier_name}</a>
        },
        {
            field: 'claim_status',
            title: 'Claim Status',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ cursor: 'pointer' }} onClick={() => handleItemClick(rowData.claim_number)}> {rowData.claim_status}</a>
        },

        {
            field: 'claim_amount',
            title: 'Claim Amount',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} style={{ cursor: 'pointer' }} onClick={() => handleItemClick(rowData.claim_number)}> {rowData.claim_amount}</a>
        },
        {
            field: 'approved_amount',
            title: 'Approved Amount',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} style={{ cursor: 'pointer' }} onClick={() => handleItemClick(rowData.claim_number)}> {rowData.approved_amount}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            filtering: false,
            render: rowData =>
                <div style={{ display: 'flex' }}>

                    <Tooltip title="Edit">
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleEdit(rowData.claim_number)}
                            size="large">
                            <Edit style={{ fontSize: 20 }} />
                        </IconButton>
                    </Tooltip>
                    {/* { secureLocalStorage.getItem("dYtz").collaboration_id!=0 ?'': rowData.claim_status === 'Fully Reconciled' ?
                        <Tooltip title="Payment">

                            <IconButton classes={{ root: classes.IconButton }} onClick={() => props.getOutgoingClaimsPayment(null,{"claim_number":[rowData.claim_number.toString()]})}>
                                <AccountBalance color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                            </IconButton>
                        </Tooltip>
                        :
                        <IconButton classes={{ root: classes.IconButton }} disabled>
                            <AccountBalance color="disabled" style={{ fontSize: 20, color: '#C8C8C8' }} />
                        </IconButton>
                    } */}
                    {secureLocalStorage.getItem("dYtz").collaboration_id != 0 ? '' : rowData.claim_status !== 'Fully Reconciled' ?
                        <Tooltip title="Reprocess">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleReprocess(rowData)}
                                size="large">
                                <LowPriority color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                            </IconButton>
                        </Tooltip>
                        :
                        <IconButton classes={{ root: classes.IconButton }} disabled size="large">
                            <LowPriority color="disabled" style={{ fontSize: 20, color: '#C8C8C8' }} />
                        </IconButton>
                    }
                    {secureLocalStorage.getItem("dYtz").collaboration_id != 0 ? '' : rowData.claim_status !== 'Fully Reconciled' ?
                        <Tooltip title="Split Claim">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleSplit(rowData)}
                                size="large">
                                <CallSplit color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                            </IconButton>
                        </Tooltip>
                        :
                        <IconButton classes={{ root: classes.IconButton }} disabled size="large">
                            <CallSplit color="disabled" style={{ fontSize: 20, color: '#C8C8C8' }} />
                        </IconButton>
                    }
                    {/* { secureLocalStorage.getItem("dYtz").collaboration_id!=0 ?'': <Tooltip title="Email">
                        <IconButton classes={{ root: classes.IconButton }} onClick={() => handleSendEmail(rowData)}>
                            <Send color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>}
                    {secureLocalStorage.getItem("dYtz").collaboration_id!=0 ?'': <Tooltip title="Email EDI">
                        <IconButton classes={{ root: classes.IconButton }} onClick={() => handleSendEmailEDIFile(rowData)}>
                            <SendOutlined color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>}
                   {secureLocalStorage.getItem("dYtz").collaboration_id!=0 ?'': <Tooltip title="Download">
                        <IconButton classes={{ root: classes.IconButton }} onClick={() => handleDownload(rowData)}>
                            <CloudDownload color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>}
                   {secureLocalStorage.getItem("dYtz").collaboration_id!=0 ?'':  <Tooltip title="EDI File">
                        <IconButton classes={{ root: classes.IconButton }} onClick={() => handleDownloadEDIFile(rowData)}>
                            <FileDownloadIcon color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>} */}
                </div>
        },
    ];
    return (
        <div className={classes.root}>

            <Dialog open={
                openDialog}
                style={{ margin: 15 }}
                classes={{ paper: classes.paper }}
            >

                <DialogContent classes={{
                    root: classes.dialogContent
                }}
                >
                    <div style={{ width: '90%' }}>
                        <FormLabel className={classes.formLabel} style={{ width: '100%' }} >
                            Select Format
                        </FormLabel>
                        <Select
                            value={format}
                            onChange={(e) => setFormat(e.target.value)}
                            displayEmpty
                            className={clsx({
                                [classes.select]: true
                            })}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                        >
                            {props.outgoingClaimDownloadFormatData &&
                                props.outgoingClaimDownloadFormatData.length > 0 &&
                                props.outgoingClaimDownloadFormatData
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.format_name}
                                            </MenuItem>
                                        )
                                    })}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={handleCancelDialog} color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleDownloadButttonConfirm()} color="primary" autoFocus variant="contained"
                        disabled={!format ? true : false}
                    >
                        Continue

                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialog2}
                style={{ margin: 15 }}
                classes={{ paper: classes.paper }}
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}
                >
                    <div style={{ width: '90%' }}>
                        <FormLabel className={classes.formLabel} style={{ width: '100%' }} >
                            Select Format
                        </FormLabel>
                        <Select
                            value={format}
                            onChange={(e) => setFormat(e.target.value)}
                            displayEmpty
                            className={clsx({
                                [classes.select]: true
                            })}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                        >
                            {props.outgoingClaimDownloadFormatData &&
                                props.outgoingClaimDownloadFormatData.length > 0 &&
                                props.outgoingClaimDownloadFormatData
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.format_name}
                                            </MenuItem>
                                        )
                                    })}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={handleCancelDialog} color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSendEmailConfirm()} color="primary" autoFocus variant="contained"
                        disabled={!format ? true : false}
                    >
                        Continue

                    </Button>
                </DialogActions>
            </Dialog>

            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>Outgoing Claims</Typography>
                {/* {secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_collaboration') && */}

                <div>
                    {/* <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                            onClick={() => history.push('/customer-outgoing-claim-download-format')}
                        >
                            <CloudUpload   color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Claim Submission Format</Typography>
                        </Fab> */}
                    {/* <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            onClick={() => history.push('/customer-outgoing-claims-upload-new')}
                        >
                            <CloudUpload   color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Claim Response Upload</Typography>
                         </Fab> */}
                </div>
                {/* } */}
            </div>
            {props.outgoingClaimListData && props.outgoingClaimListData.length > 0 ?
                <MaterialTable
                    components={{

                        Toolbar: (props) => (
                            <div
                                style={{
                                    height: "0px",
                                }}
                            >
                            </div>
                        ),
                    }}
                    title={' '}
                    editable={true}
                    icons={tableIcons}
                    columns={columns}
                    data={dataRows}
                    style={{ marginTop: '0.5rem' }}
                    options={{
                        search: false,
                        filtering: true,
                        headerStyle: theme.mixins.MaterialHeader,
                        cellStyle: theme.mixins.MaterialCell,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],

                    }}
                />
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
        </div>
    );
};


const mapStateToProps = state => {
    return {
        outgoingClaimListData: state.initialData.outgoingClaimListData,
        outgoingClaimDownloadFormatData: state.dataSetupData.outgoingClaimDownloadFormatData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOutgoingClaims: () => dispatch(getOutgoingClaims()),
        getOutgoingClaimsPayment: (claimNumber, data) => dispatch(outgoingClaimFinacialPosting(claimNumber, data)),
        getOutgoingClaimDownloadFormatData: () => dispatch(getOutgoingClaimDownloadFormat('download')),
        handleSplit: (claimNumber) => dispatch(handleSplitOutgoingClaim(claimNumber))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingClaims);

