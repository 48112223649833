import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { useMediaQuery, Button } from '@mui/material';
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import {
    ChartComponent, SeriesCollectionDirective, AxesDirective, AxisDirective, SeriesDirective,
    Inject, LineSeries, ChartAnnotation, ColumnSeries, AnnotationsDirective, AnnotationDirective, Category, Tooltip, SplineSeries, Legend
} from '@syncfusion/ej2-react-charts';
import { connect } from 'react-redux';
import { Browser } from '@syncfusion/ej2-base';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'capitaize'
    },
    spinner: {
        height: '100vh'
    },
}));

const NextTierChart = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }`;
    let chartInstance = useRef(null);
    const onChartLoad = (args) => {
        let chart = document.getElementById('charts');
        chart.setAttribute('title', '');
    };
    const load = (args) => {
        let selectedTheme = 'Material';
        args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');
    };
    const palette = ["#0000FF", "#CA4218", "#336600", "#660066"];
    const [openEstimator, setOpenEstimator] = React.useState(false);
    const [currentTier, setCurrentTier] = React.useState([]);
    useEffect(() => {
        setOpenEstimator(props.openDialog)
    }, [props.openDialog])

    useEffect(() => {
        if (props.data) {
            var temp = props.data.filter(item => item.current_data == true)
            if (temp[0])
                setCurrentTier([temp[0]])

        }
    }, [props.data])
    function handleCancel() {
        setOpenEstimator(false)
        props.onClose()
    }
    const axisLabelRender = (args) => {
        args.text = args.text + " %"
    };
    return (

        <div>
            <div className={clsx(classes.root, className)} >

                <Dialog onClose={handleCancel} aria-labelledby="simple-dialog-title" open={openEstimator}
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="scroll-dialog-title">Rebate Estimator Chart</DialogTitle>
                    <DialogContent dividers>
                        <div className='control-pane' style={{ marginBottom: 20 }}>
                            <style>{SAMPLE_CSS}</style>
                            <div className='control-section'>
                                <ChartComponent id='charts' style={{ textAlign: "center" }} ref={chartInstance}
                                    primaryXAxis={{ valueType: 'Category', title: 'Tier Rate', minorGridLines: { width: 0 }, majorGridLines: { width: 0 }, labelFormat: '{value} %' }}
                                    primaryYAxis={{ lineStyle: { width: 0 }, labelFormat: '$ {value}', title: 'Tier Basis' }} width={Browser.isDevice ? '100%' : '75%'} chartArea={{ border: { width: 0 } }} load={load.bind(this)}
                                    /*legendSettings={{ visible: false }} */ loaded={onChartLoad.bind(this)} tooltip={{ enable: true }} palettes={palette}>

                                    <Inject services={[LineSeries, ColumnSeries, Category, Tooltip, SplineSeries, ChartAnnotation, Legend]} />
                                    <AxesDirective>
                                        <AxisDirective majorGridLines={{ width: 0 }} rowIndex={0} opposedPosition={true} lineStyle={{ width: 0 }} majorTickLines={{ width: 0 }} name='yAxis1' labelFormat='$ {value}' title='Incentive Amount' />
                                    </AxesDirective>
                                    <SeriesCollectionDirective>
                                        <SeriesDirective dataSource={props.data} xName='next_tier_rate' yName='next_tier_basis' width={1} name='Tier Basis' type='Spline' marker={{ visible: true, width: 7, height: 7, isFilled: true, shape: 'Diamond' }} />
                                        <SeriesDirective dataSource={props.data} xName='next_tier_rate' yName='incentive_amount' name='Incentive Amount' type='Spline' marker={{ visible: true, width: 7, height: 7, isFilled: true }} yAxisName='yAxis1' width={2} />
                                        {props.estimateData && <SeriesDirective dataSource={props.estimateData} xName='next_tier_rate' yName='next_tier_basis' width={1} name='Estimated Tier Basis' type='Spline' marker={{ visible: true, width: 10, height: 10, isFilled: true, shape: 'Diamond' }} />}
                                        {props.estimateData && <SeriesDirective dataSource={props.estimateData} xName='next_tier_rate' yName='incentive_amount' name='Estimated Incentive Amount' type='Spline' marker={{ visible: true, width: 10, height: 10, isFilled: true, shape: 'Circle' }} yAxisName='yAxis1' width={2} />}

                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary" variant="outlined">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        </div>
    );

};
export default connect(null, null)(NextTierChart);
