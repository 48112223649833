import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Card,
    OutlinedInput,
    FormLabel, TextField
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getGPOAddressSearchData } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));
const GPORosterAddressSearch = props => {
    const classes = useStyles();
    const [GPOName, setGPOName] = React.useState('');
    const [GPOMemberNumber, setGPOMemberNumber] = React.useState('');
    const [GPOMemberName, setGPOMemberName] = React.useState('');
    const [facilityType, setFacilityType] = React.useState('');
    const [GLNID, setGLNID] = React.useState('');
    const [HINID, setHINID] = React.useState('');
    const [UUID, setUUID] = React.useState('');
    const [memberStartDate, setMemberStartDate] = React.useState(null);
    const [directParentMemberNumber, setDirectParentMemberNumber] = React.useState('');
    const [directParentMemberName, setDirectParentMemberName] = React.useState('');
    const [topMemberNumber, setTopMemberNumber] = React.useState('');
    const [topMemberName, setTopMemberName] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const handleGPOName = (e) => {
        setGPOName(e.target.value);
    }
    const handleGPOMemberNumber = (e) => {
        setGPOMemberNumber(e.target.value);
    }
    const handleGPOMemberName = (e) => {
        setGPOMemberName(e.target.value);
    }
    const handleGLNID = (e) => {
        setGLNID(e.target.value);
    }
    const handleHINID = (e) => {
        setHINID(e.target.value);
    }
    const handleDirectParentMemberName = (e) => {
        setDirectParentMemberName(e.target.value);
    }
    const handleStreet = (e) => {
        setStreet(e.target.value);
    }
    const handleCity = (e) => {
        setCity(e.target.value);
    }
    const handleState = (e) => {
        setState(e.target.value);
    }
    const handlePostalCode = (e) => {
        setPostalCode(e.target.value);
    }
    function handleSubmit() {
        var data = {
            "GPO_name": GPOName,
            "GPO_member_number": GPOMemberNumber,
            "GPO_member_name": GPOMemberName,
            "facility_type": facilityType,
            "ID_GLN": GLNID,
            "ID_HIN": HINID,
            "ID_UU": UUID,
            "member_start_date": Moment.utc(memberStartDate).format('MM/DD/YYYY'),
            "direct_parent_member_number": directParentMemberNumber,
            "direct_parent_member_name": directParentMemberName,
            "top_member_number": topMemberNumber,
            "top_member_name": topMemberName,
            "state": state,
            "city": city,
            "street": street,
            "postal_code": postalCode,
        };
        props.onSubmit(1, 0, data, null);
        handleClear();
    }
    function handleSubmitStatic() {
        var data = {
            "GPO_name": GPOName,
            "GPO_member_number": GPOMemberNumber,
            "GPO_member_name": GPOMemberName,
            "facility_type": facilityType,
            "ID_GLN": GLNID,
            "ID_HIN": HINID,
            "ID_UU": UUID,
            "member_start_date": Moment.utc(memberStartDate).format('MM/DD/YYYY'),
            "direct_parent_member_number": directParentMemberNumber,
            "direct_parent_member_name": directParentMemberName,
            "top_member_number": topMemberNumber,
            "top_member_name": topMemberName,
            "state": state,
            "city": city,
            "street": street,
            "postal_code": postalCode,
        };

        props.onSubmit(1, 0, data, 'static');
        handleClear();
    }
    function handleClear() {
        setGPOName('');
        setGPOMemberNumber('');
        setGPOMemberName('');
        setFacilityType('')
        setGLNID('');
        setHINID('')
        setUUID('')
        setMemberStartDate(null)
        setDirectParentMemberNumber('')
        setDirectParentMemberName('')
        setTopMemberNumber('')
        setTopMemberName('')
        setStreet('')
        setCity('')
        setState('')
        setPostalCode('')
    }
    return (
        <div className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2} style={{ padding: '16px 25px 0px 25px' }}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        GPO Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={GPOName}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleGPOName} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        GPO Member Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={GPOMemberNumber}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleGPOMemberNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        GPO Member Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={GPOMemberName}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleGPOMemberName} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Facility Type
                                    </FormLabel>
                                    <OutlinedInput
                                        value={facilityType}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setFacilityType(e.target.value)} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        GLN ID
                                    </FormLabel>
                                    <OutlinedInput
                                        value={GLNID}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleGLNID} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        HIN ID
                                    </FormLabel>
                                    <OutlinedInput
                                        value={HINID}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleHINID} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        UUID
                                    </FormLabel>
                                    <OutlinedInput
                                        value={UUID}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setUUID(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Member Start Date
                                    </FormLabel>
                                    <Grid container justifyContent="space-around">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={memberStartDate}
                                                onChange={(date) => setMemberStartDate(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Direct Parent Member Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={directParentMemberNumber}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setDirectParentMemberNumber(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Direct Parent Member Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={directParentMemberName}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleDirectParentMemberName} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Top Member Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={topMemberNumber}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setTopMemberNumber(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Top Member Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={topMemberName}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setTopMemberName(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Street Address
                                    </FormLabel>
                                    <OutlinedInput
                                        value={street}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleStreet}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        City
                                    </FormLabel>
                                    <OutlinedInput
                                        value={city}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCity}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        State
                                    </FormLabel>
                                    <OutlinedInput
                                        value={state}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleState}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                        Postal Code
                                    </FormLabel>
                                    <OutlinedInput
                                        value={postalCode}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handlePostalCode}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Card>
            <div className={classes.buttonRoot}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitStatic}>
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Static Run'
                    }
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Dynamic Run'
                    }
                </Button>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit, data, type) => dispatch(getGPOAddressSearchData(pagination, limit, data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GPORosterAddressSearch);