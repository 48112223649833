import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, Button, IconButton, TablePagination,
    Dialog, DialogContent, DialogContentText, DialogActions, Fab
} from '@mui/material';
import { Edit, Visibility, DeleteForever, AddBoxOutlined, CloudUpload, LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { createPriceListData, getAllDistinctPriceListData } from '../../../redux/actions/Pricing/AuthAction';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // fontSize: theme.typography.h4.fontSize,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const DriveMaintenanceMain = props => {
    useEffect(() => {
        props.getConfigData(1, 15);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [configData, setConfigData] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getConfigData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getConfigData(1, event.target.value);
    };

    useEffect(() => {
        var tempRows = [];
        if (props.configData && props.configData.records && props.configData.records != null) {
            setConfigData(props.configData.records);
            props.configData.records
                .sort((a, b) => a.analysis_level_id < b.analysis_level_id ? 1 : -1)
                .map(e => {
                    tempRows.push(e);
                })
            setCount(props.configData.total_record)
            setDataRows(tempRows);
        }
    }, [props.configData]);

    function editConfig(id, name) {
        sessionStorage.setItem('pricetypename', name)
        history.push({
            pathname: '/driver-maintenance/edit-list-data/' + id,
            id: id
        });
    }
    function openConfig(id, name) {
        sessionStorage.setItem('pricetypename', name)
        history.push({
            pathname: '/driver-maintenance/view-list-data/' + id,
            id: id
        });
    }


    const deleteConfig = (id) => {
        props.onDelete(id);
    }
    const [currentDelID, setCurrentDelID] = React.useState('');
    const handleDelete = (id) => {
        setCurrentDelID(id)
        setOpenConfirmDelete(true)
    }

    const columns = [
        {
            field: 'analysis_level_id',
            title: 'Analyis Level ID',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'driver_name',
            title: 'Driver Name',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'driver',
            title: 'Driver',
            editable: 'never',
            type: 'string',
        },

        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_driver_data-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editConfig(rowData.analysis_level_id, rowData.driver_name)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_driver_data-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => openConfig(rowData.analysis_level_id, rowData.driver_name)}
                            size="large">
                            <Visibility color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_driver_data-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleDelete(rowData.analysis_level_id)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>}
                    </div>
                </div>
        },
    ]
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
    function handleDialog(bool) {
        if (bool === 'No') {
            setOpenConfirmDelete(true)
        } else {
            deleteConfig(currentDelID)
            setOpenConfirmDelete(false);
        }
    }



    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Driver Maintenance </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_driver_data-' + sessionStorage.getItem('application')) &&
                        <div>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                //    component={CustomRouterLink}
                                onClick={() => history.push('/driver-maintenance/add-list-data')}
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                onClick={() => history.push('/driver-maintenance/driver-maintenance-excel-upload')}
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlined />}
                            classes={{
                                startIcon: !isDesktop && classes.startIcon,
                                root: !isDesktop && classes.container
                            }}
                            onClick={() => history.push('/driver-maintenance/driver-maintenance-excel-upload')}
                        >
                            {isDesktop ? 'Upload Excel' : ''}
                        </Button> */}
                        </div>}

                </div>
                {configData ?
                    <MaterialTable
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 15, 20]}
                                    count={count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}

                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            ),
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            pageSize: rowsPerPage,
                            count: count,
                            page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

            <Dialog open={openConfirmDelete}>
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            Are you sure you want to delete ?
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpenConfirmDelete(false)} autoFocus color="primary" variant="outlined" >
                        No
                    </Button>
                    <Button onClick={() => handleDialog('Yes')} color="primary" autoFocus variant="contained"  >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        configData: state.pricingData.distinctProfitList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getAllDistinctPriceListData(pagination, limit)),
        onDelete: (id) => dispatch(createPriceListData(null, id, null, 'delete'))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriveMaintenanceMain);