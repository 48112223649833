import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader, IconButton, FormLabel, Select,
    MenuItem, Button, Typography, Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { TextInputWithLabel } from '../../../../components/Inputs';
import { getDefaultValuesCustomer, getAttributeListData, } from '../../../../redux/actions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import { AllOtherAttributeMasterComponent, } from "../../../../components/Inputs";
const useStyles = makeStyles(theme => ({
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        width: '100%',
        padding: '0px 45px 0px 45px !important'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
    },
    container2: {
        padding: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    //new styles
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const GroupingIdentifers = (props) => {
    const classes = useStyles();
    const [customerIDTypeArray, setCustomerIDTypeArray] = React.useState([]);
    const contractCustomisationUpdation = useRef();
    const [customerChain, setCustomerChain] = React.useState('');
    const [customerHierarchy, setCustomerHierarchy] = React.useState('');
    const [customerGroup, setCustomerGroup] = React.useState('');
    const [customerGroup1, setCustomerGroup1] = React.useState('');
    const [customerGroup2, setCustomerGroup2] = React.useState('');
    const [customerGroup3, setCustomerGroup3] = React.useState('');
    const [customerGroup4, setCustomerGroup4] = React.useState('');
    const [customerGroup5, setCustomerGroup5] = React.useState('');
    const [idNumber1, setIdNumber1] = React.useState('');
    const [idNumber2, setIdNumber2] = React.useState('');
    const [idNumber3, setIdNumber3] = React.useState('');
    const [idNumber4, setIdNumber4] = React.useState('');
    const [idNumber5, setIdNumber5] = React.useState('');
    const [idNumber6, setIdNumber6] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    const [identifiersStore, setIdentifiersStore] = React.useState([]);
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    //options
    const [customerIdType, setCustomerIdType] = React.useState('');
    const [customerIdNumber, setCustomerIdNumber] = React.useState('');
    const [finalCustomerIDType, setFinalCustomerIDType] = React.useState([]);
    const [finalCustomerIDNumber, setFinalCustomerIDNumber] = React.useState([]);

    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'customer_id_type' && item.type === 'DROPDOWN.STRING') {
                            setCustomerIDTypeArray(item);
                        }

                    })
                }

            }
        }
    });
    useEffect(() => {
        props.onLoadingDefault();
        props.getAttributeListData({
            attribute_name: "",
        });
    }, []);
    function handleCustomerIDType(newValue) {
        if (newValue && newValue.length > 0) {
            // var index = newValue.indexOf("-");
            setCustomerIdType(newValue);
        } else setCustomerIdType("");
        props.onChange(formData);
    }
    function handleCustomerIDNumber(event) {
        setCustomerIdNumber(event);
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        handleCustomerIDType(item.customer_id_type);
        handleCustomerIDNumber(item.customer_id_number);
    }
    useEffect(() => {
        var tempIDType = [];
        var tempIDNumber = [];
        for (var i = 0; i < identifiersStore.length; i++) {
            tempIDType.push(identifiersStore[i].customer_id_type)
            tempIDNumber.push(identifiersStore[i].customer_id_number)
        }
        setFinalCustomerIDType(tempIDType)
        setFinalCustomerIDNumber(tempIDNumber)
    }, [identifiersStore]);
    function handleSubmit() {
        var data = {
            "customer_id_type": customerIdType,
            "customer_id_number": customerIdNumber,
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...identifiersStore];
            editedArray[editIndex] = data;
            setIdentifiersStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setIdentifiersStore([...identifiersStore, data]);
            props.onChange([...identifiersStore, data])
        }
        setCustomerIdType('');
        setCustomerIdNumber('');
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setCustomerIdType('');
        setCustomerIdNumber('');
    }
    const handleDeleteLocally = (index) => {
        setIdentifiersStore(item => item.filter((item, i) => i !== index));
        props.onChange(identifiersStore.filter((item, i) => i !== index))
    }
    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    var formData = {
        customer_id_type: finalCustomerIDType,
        customer_id_number: finalCustomerIDNumber,
        customer_chain: customerChain,
        customer_hierarchy: customerHierarchy,
        customer_group: customerGroup,
        customer_group1: customerGroup1,
        customer_group2: customerGroup2,
        customer_group3: customerGroup3,
        customer_group4: customerGroup4,
        customer_group5: customerGroup5,
        id_number01: idNumber1,
        id_number02: idNumber2,
        id_number03: idNumber3,
        id_number04: idNumber4,
        id_number05: idNumber5,
        id_number06: idNumber6,
        // flex_attribute3: flexAttribute3,
        // flex_attribute4: flexAttribute4
    }
    function handleCustomerChain(newValue) {
        if (newValue && newValue.length > 0) {
            setCustomerChain(newValue);
        } else setCustomerChain("");
        props.onChange(formData);
    }
    function handleCustomerHierarchy(newValue) {
        setCustomerHierarchy(newValue);
    }
    function handleCustomerGroup(newValue) {
        if (newValue && newValue.length > 0) {
            setCustomerGroup(newValue);
        } else setCustomerGroup("");
        props.onChange(formData);
    }
    function handleCustomerGroup1(newValue) {
        setCustomerGroup1(newValue);
    }
    function handleCustomerGroup2(newValue) {
        setCustomerGroup2(newValue);
    }
    function handleCustomerGroup3(newValue) {
        setCustomerGroup3(newValue);
    }
    function handleCustomerGroup4(newValue) {
        setCustomerGroup4(newValue);
    }
    function handleCustomerGroup5(newValue) {
        setCustomerGroup5(newValue);
    }
    function handleIdNumber1(newValue) {
        setIdNumber1(newValue);
    }
    function handleIdNumber2(newValue) {
        setIdNumber2(newValue);
    }
    function handleIdNumber3(newValue) {
        setIdNumber3(newValue);
    }
    function handleIdNumber4(newValue) {
        setIdNumber4(newValue);
    }
    function handleIdNumber5(newValue) {
        setIdNumber5(newValue);
    }
    function handleIdNumber6(newValue) {
        setIdNumber6(newValue);
    }
    // function handleFlexAttribute3(newValue) {
    //     setFlexAttribute3(newValue);
    // }
    // function handleFlexAttribute4(newValue) {
    //     setFlexAttribute4(newValue);
    // }
    return (
        <div>
            <div
                className={classes.root}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <CardHeader
                            title="GROUPINGS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <Grid container spacing={2}
                            style={{ marginBottom: 16 }}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required={fieldLabel['customer_chain'] ? fieldLabel['customer_chain']['mandatory'] : false} style={{ marginTop: '6px' }} >{fieldLabel['customer_chain'] ? fieldLabel['customer_chain']['current'] : 'Customer Chain'}</FormLabel>
                                <AllOtherAttributeMasterComponent
                                    classes={{ root: classes.fontSetting }}
                                    withOutLabel
                                    attributeName={'customer_chain'}
                                    value={customerChain}
                                    prevalue={customerChain}
                                    onChange={handleCustomerChain} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 10 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}
                                    required={fieldLabel['customer_hierarchy'] ? fieldLabel['customer_hierarchy']['mandatory'] : false} >
                                    {fieldLabel['customer_hierarchy'] ? fieldLabel['customer_hierarchy']['current'] : 'Customer Hierarchy'}

                                </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleCustomerHierarchy} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required={fieldLabel['customer_group'] ? fieldLabel['customer_group']['mandatory'] : false} style={{ marginTop: '6px' }}>{fieldLabel['customer_group'] ? fieldLabel['customer_group']['current'] : 'Customer Group'}</FormLabel>
                                <AllOtherAttributeMasterComponent
                                    classes={{ root: classes.fontSetting }} attributeName={"customer_group"}
                                    withOutLabel
                                    value={customerGroup}
                                    prevalue={customerGroup}
                                    onChange={handleCustomerGroup} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 10 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}
                                    required={fieldLabel['customer_group1'] ? fieldLabel['customer_group1']['mandatory'] : false}
                                >
                                    {fieldLabel['customer_group1'] ? fieldLabel['customer_group1']['current'] : 'Customer Group 1'}
                                </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['customer_group1'] ? fieldLabel['customer_group1']['mandatory'] : false} twoline='true' onChange={handleCustomerGroup1} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}
                                    required={fieldLabel['customer_group2'] ? fieldLabel['customer_group2']['mandatory'] : false}
                                >
                                    {fieldLabel['customer_group2'] ? fieldLabel['customer_group2']['current'] : 'Customer Group 2'}
                                </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleCustomerGroup2} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}
                                    required={fieldLabel['customer_group3'] ? fieldLabel['customer_group3']['mandatory'] : false}
                                >
                                    {fieldLabel['customer_group3'] ? fieldLabel['customer_group3']['current'] : 'Customer Group 3'}
                                </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleCustomerGroup3} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}
                                    required={fieldLabel['customer_group4'] ? fieldLabel['customer_group4']['mandatory'] : false}
                                >
                                    {fieldLabel['customer_group4'] ? fieldLabel['customer_group4']['current'] : 'Customer Group 4'}
                                </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleCustomerGroup4} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}
                                    required={fieldLabel['customer_group5'] ? fieldLabel['customer_group5']['mandatory'] : false}
                                >
                                    {fieldLabel['customer_group5'] ? fieldLabel['customer_group5']['current'] : 'Customer Group 5'}
                                </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleCustomerGroup5} />
                            </Grid>
                            {/* <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={'Flex Attribute 3'} twoline='true' onChange={handleFlexAttribute3} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={'Flex Attribute 4'} twoline='true' onChange={handleFlexAttribute4} />
                            </Grid> */}
                        </Grid>
                    </div>
                </form>

            </div >
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IDENTIFIERS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['id_number01'] ? fieldLabel['id_number01']['mandatory'] : false}
                                            style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'ID Number 1'}
                                        </FormLabel>
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleIdNumber1} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['id_number02'] ? fieldLabel['id_number02']['mandatory'] : false}
                                            style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'ID Number 2'}
                                        </FormLabel>
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleIdNumber2} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['id_number03'] ? fieldLabel['id_number03']['mandatory'] : false}
                                            style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'ID Number 3'}
                                        </FormLabel>
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleIdNumber3} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['id_number04'] ? fieldLabel['id_number04']['mandatory'] : false}
                                            style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'ID Number 4'}
                                        </FormLabel>
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleIdNumber4} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['id_number05'] ? fieldLabel['id_number05']['mandatory'] : false}
                                            style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'ID Number 5'}
                                        </FormLabel>
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleIdNumber5} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['id_number06'] ? fieldLabel['id_number06']['mandatory'] : false}
                                            style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'ID Number 6'}
                                        </FormLabel>
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleIdNumber6} />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <AllOtherAttributeMasterComponent
                                            classes={{ root: classes.fontSetting }}
                                            attributeName="customer_id_type"
                                            value={customerIdType}
                                            onChange={handleCustomerIDType}
                                            prevalue={customerIdType}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: '0.8rem' }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                Customer Identifier Number
                                            </FormLabel>

                                            <TextField
                                                value={customerIdNumber}
                                                onChange={(e) => handleCustomerIDNumber(e.target.value)}
                                                className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%', height: '2.3rem' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid> */}
                                    {/* <Grid item md={1} classes={{ root: classes.centerButton }}>
                                        <Grid container style={{ paddingTop: 26 }} >
                                            <Grid item md={6} >
                                                {editMode ?
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<EditIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                    :
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                }
                                            </Grid>
                                            <Grid item
                                                md={6}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<DeleteForeverIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.deleteIconContainer,
                                                    }}
                                                    onClick={handleRemove}
                                                >
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </div>
                        </form>

                        {/* <div className={classes.container2}>
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {identifiersStore.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Customer ID Type</TableCell>
                                                        <TableCell align='center'>Customer ID number</TableCell>
                                                        <TableCell align='center' style={{ minWidth: 100 }}>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {identifiersStore.map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <TableCell align='center'>{item.customer_id_type}</TableCell>
                                                                <TableCell align='center'>{item.customer_id_number}</TableCell>
                                                                <TableCell align='center'>
                                                                    <IconButton classes={{ root: classes.IconButton }} onClick={() => handleEditRow(item, index)}>
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => handleDeleteLocally(index)} classes={{ root: classes.IconButton }}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </div> */}

                    </Card>
                </div >
            </div>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesCustomer()),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.customerData.dropdownDataCustomer,
        attributeData: state.attributeListData.AttributeData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null)(GroupingIdentifers);