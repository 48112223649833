import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    Breadcrumbs,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { tableIcons } from '../../components/Icons/TableIcons';
import { HashLoader } from 'react-spinners';
import MaterialTable from 'material-table';
import { runContractTrends, getExternalAccuralPostingData } from '../../redux/actions';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const AccrualVsPaymentReportsTable = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([])
    const [count, setCount] = React.useState(0);
    const page = history.location.state
    const columns = [
        {
            field: 'company_code',
            title: 'Company Code',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.company_code}</a>
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_number}</a>
        },
        {
            field: 'contract_internal_description',
            title: 'Contract Internal Description',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_internal_description}</a>
        },
        // {
        //     field: 'start_date',
        //     title: 'Start Date',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}> {Moment.utc(rowData.start_date).format('MM/DD/YYYY')}</a>
        // },
        // {
        //     field: 'end_date',
        //     title: 'End Date',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}> {Moment.utc(rowData.end_date).format('MM/DD/YYYY')}</a>
        // },
        {
            field: 'posting_date',
            title: 'Posting Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.posting_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'currency',
            title: 'Currency',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.currency}</a>
        },
        {
            field: 'debit_credit_key',
            title: 'Debit Credit Key',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.debit_credit_key}</a>
        },
        {
            field: 'document_type',
            title: 'Document Type',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.document_type}</a>
        },
        {
            field: 'external_posting',
            title: 'External Posting',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.external_posting}</a>
        },
        {
            field: 'external_posting_document_number',
            title: 'External Posting Document Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.external_posting_document_number}</a>
        },
        {
            field: 'gl_account',
            title: 'Gl Account',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.gl_account}</a>
        },
        {
            field: 'incentive_amount',
            title: 'Incentive Amount',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.incentive_amount}</a>
        },
        {
            field: 'internal_reference',
            title: 'Internal Reference',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.internal_reference}</a>
        },
        {
            field: 'posting_number',
            title: 'Posting Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.posting_number}</a>
        },
        {
            field: 'posting_type',
            title: 'Posting Type',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.posting_type}</a>
        },
        {
            field: 'profit_center',
            title: 'Profit Center',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.profit_center}</a>
        },
    ];
    // useEffect(() => {
    //     props.getExternalAccuralPostingData(JSON.parse(localStorage.getItem('externalaccuralpostingfilter')))
    // }, [])
    useEffect(() => {
        if (props.financialApprovalData && props.financialApprovalData.length > 0) {
            setDataRows(props.financialApprovalData)
            setCount(props.financialApprovalData.length)
        }
    }, [props.financialApprovalData])


    return (
        <div className={clsx(classes.root, className)}>
            {page === 'OutboundPostingIntegration' ? (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{ root: classes.link }} to='/outbound-postings-integrations'>
                        Outbound Postings Integrations
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
                </Breadcrumbs>
            ) : (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{ root: classes.link }} to='/external-accural-postings'>
                        External Accrual Posting
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
                </Breadcrumbs>
            )}
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >


                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        // style={{marginTop:'-18px'}}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addContractData.loading,
        financialApprovalData: state.addMultipleConfigurationData.externalAccuralPostingData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getExternalAccuralPostingData: (data) => dispatch(getExternalAccuralPostingData(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccrualVsPaymentReportsTable);