import React, {useState, useEffect } from 'react';
import {
    Grid,Button,Typography,OutlinedInput,
    FormLabel,FormControlLabel,Checkbox,TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { postOutgoingClaimPosting } from '../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import {HashLoader,RotateLoader} from 'react-spinners';
import { MessageDialogue } from '../MessageDialogue';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider,DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
        marginTop: 5,
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    calendarLeftGridContainer: {
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        padding: '0px 45px 0px 10px'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting:{
        fontSize:theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        // marginTop: 25
    },
}));

const OutgoingClaimsPost = props => {
    const classes = useStyles();
    const { className } = props;
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [customerGroup, setCustomerGroup] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [materialGroup, setMaterialGroup] = React.useState('');
    const [billingDocNumber, setBillingDocNumber] = React.useState('');
    const [billingDocLine, setBillingDocLine] = React.useState('');
    const [splitCriteria,setSplitCriteria]= React.useState(['supplier_number']);
    const [errorList, setErrorList] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    useEffect(() => {
        if (props.postOutgoingData) {
            setErrorList(props.postOutgoingData);
            if (Object.entries(props.postOutgoingData).length > 0 && props.postOutgoingData.messageLog != null && listOpen)
                setDialog(true)
        }
    }, [props.postOutgoingData]);
    const handleClear = () => {
        setBillingDateEnd(null)
        setBillingDateStart(null)
        setSupplierNumber('')
        setCustomerNumber('')
        setCustomerGroup('')
        setMaterialNumber('')
        setMaterialGroup('')
        setBillingDocNumber('')
        setBillingDocLine('')
    }
    const handleRun = () => {

        var formData = {
            "billing_date": billingDateEnd ? { "from": Moment(billingDateStart).format('YYYY-MM-DD'), "to": Moment(billingDateEnd).format('YYYY-MM-DD') }
                : [Moment(billingDateStart).format('YYYY-MM-DD')],
            "supplier_number": supplierNumber,
            "customer_number": customerNumber,
            "customer_group": customerGroup,
            "material_number": materialNumber,
            "material_group": materialGroup,
            "billing_doc_number": billingDocNumber,
            "billing_doc_line": billingDocLine,
        }
        props.postOutgoingClaimPosting(formData,splitCriteria);
        setListOpen(true)
    }
    const handleChange=(e)=>{
        if(e.target.checked){
            setSplitCriteria([...splitCriteria,e.target.name])
        }
        else{
            var pos=splitCriteria.indexOf(e.target.name)
            if(pos>-1){
                splitCriteria.splice(pos,1)
            }
        }

    }
    function handleError() {
        setDialog(false)
        setListOpen(false)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 16 }}> Claims Posts </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.bodyContainer}>
                        <Grid container >

                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{root:classes.fontSetting}} required>Billing Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 11,
                                                        width: '100%',
                                                        border: '1px solid #E0E0E0',
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={e => setBillingDateStart(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                                <div className={classes.dateSecondColumn}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 28,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={e => setBillingDateEnd(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                                disabled={billingDateStart ? false : true}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Supplier Number
                                </FormLabel>
                                <OutlinedInput
                                    value={supplierNumber}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setSupplierNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Customer Number
                                </FormLabel>
                                <OutlinedInput
                                    value={customerNumber}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setCustomerNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Customer Group
                                </FormLabel>
                                <OutlinedInput
                                    value={customerGroup}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setCustomerGroup(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Material Number
                                </FormLabel>
                                <OutlinedInput
                                    value={materialNumber}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setMaterialNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Material Group
                                </FormLabel>
                                <OutlinedInput
                                    value={materialGroup}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setMaterialGroup(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Billing Document Number
                                </FormLabel>
                                <OutlinedInput
                                    value={billingDocNumber}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setBillingDocNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Billing Document Line
                                </FormLabel>
                                <OutlinedInput
                                    value={billingDocLine}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setBillingDocLine(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{root:classes.fontSetting}}>
                                    Split Claim Criteria
                                </FormLabel>
                                <div style={{display:'flex'}}>
                                    <FormControlLabel 
                                        control={<Checkbox  checked color="primary" disabled  />}
                                        label={<Typography classes={{root:classes.fontSetting}} > Supplier Number </Typography>}
                                        
                                    />
                                    <FormControlLabel
                                        control={<Checkbox Checked={splitCriteria.includes('billing_date')>-1?true:false} color="primary" onChange={handleChange} name="billing_date" />}
                                        label={<Typography classes={{root:classes.fontSetting}} > Billing Date </Typography>}
                                    />

                                </div>

                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear}>
                                Clear
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.button} twoline='true' onClick={handleRun}
                                disabled={billingDateStart  ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                </form>
            </div>
            {dialog&&
               <MessageDialogue  open={dialog} messageList={errorList} onChange={handleError}/>
             }
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        postOutgoingClaimPosting: (formData,splitCriteria) => dispatch(postOutgoingClaimPosting(formData,splitCriteria)),

    }
}
const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        postOutgoingData:state.initialData.postOutgoingData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingClaimsPost);