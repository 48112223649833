import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Fab,
  ToggleButton,
  ToggleButtonGroup,
  TextField
} from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import { geographicalIncentive } from "../../../../redux/actions";
import Region from "../../Charts/Region";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white, //secondary.main,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    transform: 'scale(0.486)',
    transformOrigin: 'top left',
    width: "155%",
    height: "513px"
  },
  NewDiv:{
    backgroundColor: theme.palette.white,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    height: "253px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 13,
    marginTop: "-10px",
    width: "98.5%",
    color: "grey",
    },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
    FormLabel: {
      fontSize: 13, //theme.typography.h4.fontSize,
    },
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: '1px solid #EEEEEE',
    borderRadius: 6,
    marginBottom: -9,
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    width: "131.5%",
    // marginTop: 25,
    boxShadow: "rgba(50, 50, 93, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.15) 0px 2px 4px -2px",
    height: "70px",
    padding: "7px 5px 5px 5px"
  },
  formControlStyle: {
    // border: "1px solid #8F00FF",
  },
  noBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "1px solid",
        borderColor: theme.palette.border.main,
      },
    },
  },
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: "1px solid",
      borderColor: theme.palette.border.main,
    },
  },
  spinner: {
    height: "100%",
  },
}));
const GeoAnalysis = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("accrual");
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [groupBy, setGroupBy] = React.useState('contract_number');
  const [showLoaderRegion, setShowLoaderRegion] = React.useState(true);

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Incentive Ratio by Attribute" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setPostingType(allowedTilesFilter[0].posting_type);
      setGroupBy(convertToSnakeCase(allowedTilesFilter[0].groupBy));
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate && groupBy && postingType) {
      setShowLoaderRegion(true);
      props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, groupBy);
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (props.geographicalIncentiveData) {
      setGraphData(props.geographicalIncentiveData);
    }
    setShowLoaderRegion(false);
  }, [props.geographicalIncentiveData]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
    // setShowLoaderRegion(true);
    // props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), e.target.value, groupBy);
  };
  function handleSubmit() {
    setShowLoaderRegion(true);
    props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, groupBy);
  }
  function handleStartDateNew(event) {
    if (event && event.isValid()) {
      setStartDate(event);
      //   setShowLoaderRegion(true);
      //   props.geographicalIncentive(Moment(event).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, groupBy);
    }
    else
      setStartDate(event);
  }
  function handleEndDateNew(event) {
    if (event && event.isValid()) {
      setEndDate(event);
      // setShowLoaderRegion(true);
      // props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(event).format('MM/DD/YYYY'), postingType, groupBy);
    }
    else
      setEndDate(event);
  }
  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }

  return (
    <LoadingOverlay
      active={showLoaderRegion}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div>
        <div className={classes.bodyContainer} style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, maxWidth: 200 }} size="small" className={classes.formControlStyle}>
            <InputLabel id="posting-type">Posting Type</InputLabel>
            <Select
              className={classes.selectBorder}
              style={{ border: 'none' }}
              labelId="posting-type"
              id="posting-type-select"
              value={postingType}
              label="Posting Type"
              onChange={handlePostingType}
            >
              <MenuItem value={"accrual"}>Accrual</MenuItem>
              <MenuItem value={"payment"}>Payment</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="start_date"
                id="start_date-select"
                label="Start Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.3rem',
                    border: 'none'
                  }
                }}
                value={startDate}
                onChange={date => handleStartDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="end_date"
                id="end_date-select"
                label="End Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.3rem',
                    border: 'none'
                  }
                }}
                value={endDate}
                onChange={date => handleEndDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
                minDate={startDate ? startDate : ''}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 80 }} size="small" className={classes.formControlStyle}>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "35px", marginRight: 3 }}
              onClick={handleSubmit}
              twoline="true"
              disabled={startDate && endDate && postingType ? false : true}
            >
              <DirectionsRunIcon />
            </Button>
          </FormControl>
        </div>
        {graphData && graphData.length > 0 ?
          <div className={classes.root} style={{ width: "202.5%" }}>
            <Region data={graphData} padding={"small"}/>
          </div>
          :
          <div className={classes.NewDiv}> No Data to Display !</div>
        }
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    geographicalIncentiveData: state.initialData.georgraphicalIncentive,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geographicalIncentive: (s, e, postingType, groupBy) =>
      dispatch(
        geographicalIncentive(
          s,
          e,
          postingType,
          "Customer Rebate",
          "Direct - Sales Data",
          groupBy
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoAnalysis);
