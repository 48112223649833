import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import { Accrual, Payment, AdditionalData } from './containers'
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // border: '1px solid #EEEEEE',
        borderRadius: 6,
        // marginBottom: 23
    },

}));
const FinancialPostings = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const [contractData, setContractData] = React.useState({})
    const [mandatoryStatus, setMandatoryStatus] = React.useState([]);
    const [mandatoryStatus2, setMandatoryStatus2] = React.useState([]);
    const [mandatoryStatus3, setMandatoryStatus3] = React.useState([]);
    useEffect(() => {
        if (props.data)
            setContractData(props.data)
    }, [props.data])
    useEffect(() => {
        if (props.postingSchema === 'Payment Only') {
            props.onChange('', '', [...mandatoryStatus2, ...mandatoryStatus3]);
        }
        if (props.postingSchema === 'Accrual Only') {
            props.onChange('', '', [...mandatoryStatus, ...mandatoryStatus3]);
        }
        if (props.postingSchema === 'No Postings') {
            props.onChange('', '', []);
        }
        if (props.postingSchema === 'Accrual then Payment') {
            props.onChange('', '', [...mandatoryStatus, ...mandatoryStatus2, ...mandatoryStatus3]);
        }
        if (props.postingSchema === 'Payment then Amortization') {
            props.onChange('', '', [...mandatoryStatus, ...mandatoryStatus2, ...mandatoryStatus3]);

        }
    }, [mandatoryStatus, mandatoryStatus2, mandatoryStatus3])
    useImperativeHandle(ref, () => ({
        validationCheck() {
            childRef1.current.validationCheck();
            childRef2.current.validationCheck();
        },
        getValues() {
            var accuralValues = childRef1.current.getValues();
            var paymentValues = childRef2.current.getValues();
            var additionalData = childRef3.current.getValues();
            var combined = Object.assign({}, accuralValues, paymentValues, additionalData);
            return combined;
        }
    }));
    function handleChangeAccrual(key, value, mandatory) {
        // setMandatoryStatus(checkDependency(mandatory, contractData))
        setMandatoryStatus(mandatory)
        props.onChange(key, value, [...mandatory, ...mandatoryStatus2, ...mandatoryStatus3]);

    }

    function handleChangePayment(key, value, mandatory) {
        setMandatoryStatus2(mandatory)
        props.onChange(key, value, [...mandatory, ...mandatoryStatus, ...mandatoryStatus3]);
    }
    function handleAdditionalData(key, value, mandatory) {
        setMandatoryStatus3(mandatory)
        props.onChange(key, value, [...mandatory, ...mandatoryStatus, ...mandatoryStatus2]);

    }
    return (
        <div className={clsx(classes.root, className)}>
            {(props.postingSchema != 'Payment Only' && props.postingSchema != 'No Postings') ?
                <Accrual onChange={handleChangeAccrual} savetriggered={props.savetriggered} ref={childRef1} data={contractData}
                    postingSchema={props.postingSchema} fieldData={props.fieldData} />
                : <div />}
            {(props.postingSchema != 'Accrual Only' && props.postingSchema != 'No Postings') ?
                <Payment onChange={handleChangePayment} savetriggered={props.savetriggered} ref={childRef2} data={contractData}
                    activeDropdown={props.activeDropdown} fieldData={props.fieldData} />
                : <div />}
            {(props.postingSchema != 'No Postings') ?
                <AdditionalData onChange={handleAdditionalData} savetriggered={props.savetriggered} ref={childRef3}
                    data={contractData} fieldData={props.fieldData} />
                : <div />}
        </div>
    );

});
// const mapStateToProps = state => ({
//     //data: state.addContractData.contractGeneralData,
//    // dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
// });
export default connect(null, null, null, { forwardRef: true })(FinancialPostings);