import React, { useEffect } from 'react';
import {
    Grid,
    Card,
    OutlinedInput,
    FormLabel,
    CardHeader
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { TextInputWithLabel, LabelText } from '../../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const Ima360Data = (props) => {
    const classes = useStyles();
    const [materialMasterData, setMaterialMasterData] = React.useState({});
    const [cost, setCost] = React.useState('');
    const [msrp, setMsrp] = React.useState('');
    const [pricingInventory, setPricingInventory] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    useEffect(() => {
        props.onChange(formData);
    });

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);

    var formData = {
        cost: parseFloat(cost),
        msrp: parseFloat(msrp),
        pricing_inventory_alert_qty: parseFloat(pricingInventory),
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2,
        flex_attribute3: flexAttribute3,
        flex_attribute4: flexAttribute4
    }
    //functions
    function handleCost(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setCost(e.target.value);
        }
    }
    function handleMsrp(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setMsrp(e.target.value);
        }
    }
    function handlePricingInventory(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setPricingInventory(e.target.value);
        }
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue);
    }
    function handleFlexAttribute3(newValue) {
        setFlexAttribute3(newValue);
    }
    function handleFlexAttribute4(newValue) {
        setFlexAttribute4(newValue);
    }
    useEffect(() => {
        if (props.materialMasterData) {
            setMaterialMasterData(props.materialMasterData)
            setCost(props.materialMasterData.cost)
            setMsrp(props.materialMasterData.msrp)
            setPricingInventory(props.materialMasterData.pricing_inventory_alert_qty
            )
            setFlexAttribute1(props.materialMasterData.flex_attribute1)
            setFlexAttribute2(props.materialMasterData.flex_attribute2)
            setFlexAttribute3(props.materialMasterData.flex_attribute3)
            setFlexAttribute4(props.materialMasterData.flex_attribute4)
        }
    }, [props.materialMasterData]);
    return (
        <div className={classes.root}>
            <div className={classes.bodyContainer}>
                {materialMasterData &&
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IMA360 DATA"
                                    titleTypographyProps={{ variant: 'h3' }}
                                    style={{ marginLeft: '-5px' }}
                                />
                                <Grid container
                                    style={{ padding: 10, marginTop: '-2rem' }}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <FormLabel required={fieldLabel['cost'] ? fieldLabel['cost']['mandatory'] : false} className={classes.formLabel}>
                                            {fieldLabel['cost'] ? fieldLabel['cost']['current'] : 'Cost'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={cost}
                                            inputProps={{ type: "number", step: "1", min: "0" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCost} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <FormLabel required={fieldLabel['msrp'] ? fieldLabel['msrp']['mandatory'] : false} className={classes.formLabel}>
                                            {fieldLabel['msrp'] ? fieldLabel['msrp']['current'] : 'MSRP'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={msrp}
                                            inputProps={{ type: "number", step: "1", min: "0" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMsrp} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <FormLabel required={fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['mandatory'] : false} className={classes.formLabel}>
                                            {fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['current'] : 'Pricing Inventory Alert Qty'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={pricingInventory}
                                            inputProps={{ type: "number", step: "1", min: "0" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePricingInventory}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: -10 }}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false}>
                                            {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}

                                        </FormLabel>

                                        <TextInputWithLabel twoline='true' onChange={handleFlexAttribute1} prevalue={materialMasterData.flex_attribute1} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false} >
                                            {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}

                                        </FormLabel>
                                        <TextInputWithLabel twoline='true' onChange={handleFlexAttribute2} prevalue={materialMasterData.flex_attribute2} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['mandatory'] : false}>
                                            {fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'}
                                        </FormLabel>
                                        <TextInputWithLabel twoline='true' onChange={handleFlexAttribute3} prevalue={materialMasterData.flex_attribute3} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                            required={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['mandatory'] : false}>
                                            {fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'}

                                        </FormLabel>
                                        <TextInputWithLabel twoline='true' onChange={handleFlexAttribute4} prevalue={materialMasterData.flex_attribute4} />
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>
                }
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.materialMasterViewData
    }
};
export default connect(mapStateToProps, null, null)(Ima360Data);