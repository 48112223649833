import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { ContractCell } from "./components/ContractCell";
import { Grid } from "@mui/material";
import NewContracts from "./components/NewContracts";
import MonthlyIncentiveGraph from "./components/MonthlyIncentiveGraph";
import ActiveContractsGraph from "./components/ActiveContractsGraph";
import ClaimValueAndErrorGraph from "./components/ClaimValueAndErrorGraph";
import ClaimCount from "./components/ClaimAnalytics";
import { DBPromoPlan } from "./components/DBPromoPlan";
import { GeographicalChart } from "./components/DBPromoPlan";
import { PricingApp } from "./components/PricingApp";
import { connect } from "react-redux";
import {
  getDashboardCount,
  getContractsTableDataList,
  getActiveContractsGraph,
  getMonthlyIncentiveGraph,
  loadPaymentDurationsGraph,
  loadClaimError,
  loadClaimErrorCustomerChargeBack,
  loadClaimAgingCustomerChargeBack,
  loadClaimValueAndError,
  loadClaimCountCCBSCB,
  claimErrorCount,
  getAnalysisDesignerData,
  loadAccrualVsPaymentGraph,
  geographicalIncentive,
  loadAccrualVarianceGraph,
  loadTop25Chart,
} from "../../redux/actions";
import Moment from "moment";
import RebateGraph from "./components/RebateGraph";
import { WidthProvider, Responsive } from "react-grid-layout";
//components
import SalesPaymentComparison from "../Analytics/SalesPaymentComparison";
import IncentiveTrends from "../Analytics/IncentiveTrends";
import VarianceCombined from "../Analytics/IncentiveTrends";
import Comparison from "../Analytics/Comparison";
import Top25 from "../Analytics/Top25";
import AccrualCharts from "../Analytics/AccrualCharts";
import RebateTrends from "../Analytics/RebateTrends";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};
function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
}));
const SalesCommissionDash = (props) => {
  const classes = useStyles();
  const [app, setApp] = React.useState(sessionStorage.getItem("application"));
  const [claimValueAndErrorCB, setClaimValueAndErrorCB] = React.useState({});
  const [contractTableData, setContractTableData] = React.useState([]);
  const [applicationType, setApplicationType] = React.useState("");
  const [layouts, setlayouts] = React.useState("");
  const [appSpecificData, setAppSpecificData] = React.useState([]);
  const [analysisData, setAnalysisData] = React.useState([]);

  const onLayoutChange = (layout, layouts) => {
    setlayouts(layouts);
  };
  useEffect(() => {
    if (props.analysisData && props.analysisData.length > 0) {
      var data = props.analysisData.filter(
        (item) => item.application_type === sessionStorage.getItem("application")
      );
      console.log("data", data);
      setAnalysisData(data);
    }
  }, [props.analysisData]);
  useEffect(() => {
    var appTemp = [];
    analysisData &&
      analysisData.length > 0 &&
      analysisData.map((item, index) => {
        if (item.application_type === sessionStorage.getItem("application")) {
          appTemp[index] = item["analytic_name"];
          //calling api
          if (item["analytic_name"] === "Postings Summary per Group") {
            props.loadAccrualVsPaymentGraph("passingAsParams", item["filters"]);
          }
          if (item["analytic_name"] === "Monthly Active Contracts") {
            props.loadActiveContractsGraph(item["filters"]);
          }
          if (item["analytic_name"] === "Monthly Incentive Amounts") {
            props.loadMonthlyIncentiveGraph(item["filters"]);
          }
          if (item["analytic_name"] === "Monthly Accrual vs Payment") {
            props.loadAccrualVsPaymentGraph("passingAsParams", item["filters"]);
          }
          if (item["analytic_name"] === "Monthly Gross to Net") {
            props.loadAccrualVsPaymentGraph("passingAsParams", item["filters"]);
          }
          if (item["analytic_name"] === "Contract Stats") {
            props.loadContractTable(
              0,
              20,
              sessionStorage.getItem("application"),
              item["filters"]
            );
          }
          if (item["analytic_name"] === "Geographical Incentives") {
            props.geographicalIncentive(item["filters"], "dashboard");
          }
          if (item["analytic_name"] === "Rebate Trends") {
            props.loadAccrualVarianceGraph(item["filters"], "dashboard");
          }
          if (item["analytic_name"] === "Incentive Trends") {
            props.loadAccrualVarianceGraph(item["filters"], "dashboard");
          }
          if (item["analytic_name"] === "Postings Variance") {
            props.loadAccrualVarianceGraph(item["filters"], "dashboard");
          }
          if (item["analytic_name"] === "Top Incentives") {
            props.loadAccrualVarianceGraph(item["filters"], "dashboard");
          }
          if (item["analytic_name"] === "Top Incentivesf") {
            props.loadTop25Chart(item["filters"], "dashboard");
          }
        }
      });

    setAppSpecificData(appTemp);
  }, [analysisData]);

  useEffect(() => {
    if (
      props.claimValueAndErrorCB &&
      Object.keys(props.claimValueAndErrorCB).length > 0
    ) {
      setClaimValueAndErrorCB(props.claimValueAndErrorCB);
    } else {
      setClaimValueAndErrorCB({});
    }
  }, [props.claimValueAndErrorCB]);
  useEffect(() => {
    if (props.contractTableData) setContractTableData(props.contractTableData);
  }, [props.contractTableData]);
  useEffect(() => {
    var application = sessionStorage.getItem("application");
    if (application != "Profit Optimization") {
      var ed = Moment()
        .subtract(0, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
      var sd = Moment()
        .subtract(5, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      if (application === "Customer Chargeback") {
        var data = {
          start_date: "2020-01-01T00:00:00.000Z",
          end_date: "2022-12-31T00:00:00.000Z",
          stat_level: "aggregate",
          application_type: "customer_chargeback",
        };
        props.loadDashboardCount(data, null);
      } else if (application === "Supplier Chargeback") {
        var data = {
          start_date: "2020-01-01T00:00:00.000Z",
          end_date: "2022-12-31T00:00:00.000Z",
          stat_level: "aggregate",
          application_type: "supplier_chargeback",
        };
        props.loadDashboardCount(data, null);
      } else {
        props.loadDashboardCount(sd, ed);
      }
      if (application != "Customer Rebate") {
        if (application != "Supplier Rebate")
          props.loadPaymentDurationsGraph(sd, ed);
      }
      if (
        application === "Customer Chargeback" ||
        application === "Supplier Chargeback"
      ) {
        props.loadClaimCount(sd, ed);
        props.loadClaimValueAndError(sd, ed);
      }
      if (application === "Supplier Chargeback") {
        props.loadClaimError(sd, ed);
      } else if (application === "Customer Chargeback") {
        props.loadClaimErrorCustomerChargeBack(sd, ed);
        props.loadClaimAgingCustomerChargeBack(sd, ed);
      }
      if (
        application !== "Customer Chargeback" &&
        application !== "Supplier Chargeback"
      ) {
        props.loadContractTable();
        if (
          application !== "Customer Rebate" &&
          application !== "Supplier Rebate"
        ) {
          props.loadActiveContractsGraph();
          props.loadMonthlyIncentiveGraph();
        }
      }
    }
    if (application === "Customer Chargeback") {
      setApplicationType("customer_chargeback");
    } else if (application === "Supplier Chargeback") {
      setApplicationType("supplier_chargeback");
    }
    props.getAttributeMapData();
  }, []);
  const handlCell = (type) => {
    if (type === "rejected") {
      var sd = Moment()
        .subtract(10, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      var ed = Moment().add(1, "months").endOf("month").format("YYYY-MM-DD");
      props.claimErrorCount(sd, ed, applicationType);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Grid container className={classes.container} spacing={2}>
        {appSpecificData && appSpecificData.length > 0 && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <ActiveContractsGraph
              data={props.activeContractsGraphData}
              paymentDuration={props.paymentDuration}
              claimAging={props.claimAgingCustomerChargeback}
            />
          </Grid>
        )}
        {appSpecificData.includes("Monthly Incentive Amounts") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <MonthlyIncentiveGraph
              data={props.monthlyIncentiveGraphData}
              claimError={props.claimError}
            />
          </Grid>
        )}
        {appSpecificData.includes("Postings Summary per Group") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <Comparison page="dashboard" />
          </Grid>
        )}
        {appSpecificData.includes("Monthly Accrual vs Payment") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <AccrualCharts page="dashboard" />
          </Grid>
        )}
        {appSpecificData.includes("Monthly Gross to Net") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <SalesPaymentComparison />
          </Grid>
        )}
        {appSpecificData.includes("Contract Stats") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <NewContracts />
          </Grid>
        )}
        {appSpecificData.includes("Geographical Incentives") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <GeographicalChart page={"dashboard"} />
          </Grid>
        )}
        {appSpecificData.includes("Incentive Trends") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <IncentiveTrends page={"dashboard"} />
          </Grid>
        )}
        {appSpecificData.includes("Postings Variance") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <VarianceCombined page={"dashboard"} />
          </Grid>
        )}
        {appSpecificData.includes("Top Incentives") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <Top25 page={"dashboard"} />
          </Grid>
        )}
        {appSpecificData.includes("Rebate Trends") && (
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <RebateTrends page={"dashboard"} />
          </Grid>
        )}
        RebateTrends
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardCountData: state.initialData.dashboardCountData,
    contractTableData: state.addContractData.contractAllListData,
    activeContractsGraphData: state.initialData.activeContractsGraphData,
    monthlyIncentiveGraphData: state.initialData.monthlyIncentiveGraphData,
    paymentDuration: state.initialData.paymentDuration,
    claimError: state.initialData.claimError,
    claimAgingCustomerChargeback:
      state.initialData.claimAgingCustomerChargeback,
    claimValueAndErrorCB: state.initialData.claimValueAndErrorCB,
    claimCountCCBSCB: state.initialData.claimCountCCBSCB,
    analysisData: state.AnalyticsDesignerData.AnalysisData,
    accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
    accrualVarianceGraphData: state.initialData.accrualVarianceGraphData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDashboardCount: (sd, ed) => dispatch(getDashboardCount(sd, ed)),
    loadContractTable: (params, page, app, total) =>
      dispatch(getContractsTableDataList(params, page, app, total)),
    loadActiveContractsGraph: (params) =>
      dispatch(getActiveContractsGraph(params)),
    loadPaymentDurationsGraph: (sd, ed) =>
      dispatch(loadPaymentDurationsGraph(sd, ed)),
    loadClaimError: (sd, ed) => dispatch(loadClaimError(sd, ed)),
    loadClaimErrorCustomerChargeBack: (sd, ed) =>
      dispatch(loadClaimErrorCustomerChargeBack(sd, ed)),
    loadMonthlyIncentiveGraph: () => dispatch(getMonthlyIncentiveGraph()),
    loadClaimAgingCustomerChargeBack: (sd, ed) =>
      dispatch(loadClaimAgingCustomerChargeBack(sd, ed)),
    loadClaimValueAndError: (sd, ed) =>
      dispatch(loadClaimValueAndError(sd, ed)),
    loadClaimCount: (sd, ed) => dispatch(loadClaimCountCCBSCB(sd, ed)),
    claimErrorCount: (sd, ed, applicationType) =>
      dispatch(claimErrorCount(sd, ed, applicationType)),
    getAttributeMapData: () => dispatch(getAnalysisDesignerData(1, 100)),
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType) =>
      dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType)),
    geographicalIncentive: (filters, page) =>
      dispatch(geographicalIncentive(filters, page)),
    loadAccrualVarianceGraph: (filters, page) =>
      dispatch(loadAccrualVarianceGraph(filters, page)),
    loadTop25Chart: (filters, page) => dispatch(loadTop25Chart(filters, page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesCommissionDash);
