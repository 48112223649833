import React, { useEffect, useState } from 'react';
import '../../../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, Typography, OutlinedInput, Select, FormLabel, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, BarSeries, Highlight, } from '@syncfusion/ej2-react-charts';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadVarianceCombinedNew, loadValuesBasedOnAppTypeAndField, allowedApps } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Chart } from "react-google-charts";
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Paper, Button, TextField } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LoadingOverlay from "react-loading-overlay";
import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Page, Sort, Resize, Aggregate,
    ExcelExport, Toolbar, PdfExport, Search, CommandColumn, AggregateDirective, AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, ColumnMenu, VirtualScroll,
} from '@syncfusion/ej2-react-grids';
import RingLoader from "react-spinners/RingLoader";
import { Browser } from "@syncfusion/ej2-base";
import ChipInput from 'material-ui-chip-input';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white, //secondary.main,
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        transform: 'scale(0.486)',
        transformOrigin: 'top left',
        width: "155%",
        height: "513px"
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: -9,
        transform: 'scale(0.75)',
        transformOrigin: 'top left',
        width: "131.5%",
        // marginTop: 25,
        boxShadow: "rgba(50, 50, 93, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.15) 0px 2px 4px -2px",
        height: "70px",
        padding: "7px 5px 5px 5px"
    },
    noBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "1px solid",
                borderColor: theme.palette.border.main,
            },
        },
    },
    selectBorder: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: "1px solid",
            borderColor: theme.palette.border.main,
        },
    },
    NewDiv: {
        backgroundColor: theme.palette.white,
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        // height: "233px",
        height: "100%",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // fontSize: 13,
        // marginTop: "-10px",
        width: "98.5%",
        // color: "grey",
    },
    NewDiv1: {
        backgroundColor: theme.palette.white,
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        height: "30vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // fontSize: 13,
        // marginTop: "-10px",
        width: "98.5%",
        // color: "grey",
    },
    graphContainer: {
        width: "100%",
        height: "100%",
        // marginTop: 10,
        display: "flex",
        alignSelf: "center",
    },
    rangeContainer: {
        padding: '20px 30px 20px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    select: {
        width: '90%',
        color: '#1675e0',
    },
    select2: {
        width: '150%',
        color: '#1675e0',
    },
    select4: {
        width: '70%',
        color: '#1675e0',
    },
    select1: {
        width: '60%',
        color: '#1675e0',
    },
    selectedItem: {
        borderRadius: 5
    },
    fontSetting: {
        fontSize: "13px",
        lineHeight: 1.3
    },
    gridBox: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginBottom: 10,
        //overflowX: 'hidden',
        overflowY: 'scroll'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableCell: {
        paddingLeft: 18
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 37,
        padding: 0,
        marginBottom: 14
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 24,
        paddingBottom: 5,
    },
    button: {
        marginRight: 10,
        // width: 80,
    },
    formControlStyle: {
        marginRight: "-3px"
    },
}));
const PostingsVariancebyContractGroupings = (props) => {
    let grid;
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [accrualFilter, setAccrualFilter] = React.useState();
    const [accrualFilterEnd, setAccrualFilterEnd] = React.useState();
    //
    const options = ["=", '>', '<', '>=', '<='];
    const [optionType, setOptionType] = React.useState('');
    const [output, setOutput] = React.useState('Chart');
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const [showTable, setShowTable] = useState(true);
    const [filterData, setFilterData] = React.useState([]);
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [aggregationField, setAggregationField] = React.useState('contract_type');
    const [maximumNumber, setMaximumNumber] = React.useState(100);
    const [showLoaderPostingVarianceContractGrouping, setShowLoaderPostingVarianceContractGrouping] = React.useState(true);
    const [showGraph2, setShowGraph2] = React.useState(true);
    //
    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Postings Variance by Contract Groupings" && item.dashboard_name === "Postings Summary Analysis"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
                setShowGraph2(false);
            }
        }
    }, [props.allowedDashBoardData])

    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
            setSourceDataType(allowedTilesFilter[0].data_source_type)
            setPostingType(allowedTilesFilter[0].posting_type)
            setAggregationField(convertToSnakeCase(allowedTilesFilter[0].aggregation_field))
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        if (postingType && sourceDataType && startDate && endDate) {
            setShowLoaderPostingVarianceContractGrouping(true);
            props.loadVarianceGraph(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, sessionStorage.getItem("application"), sourceDataType, aggregationField);
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        var temp = [['Aggregation Field', 'Variance']];
        setAvssGraphData(temp)
    }, []);

    useEffect(() => {
        if (props.varianceCombinedData && Object.keys(props.varianceCombinedData).length > 0) {
            const labels = ['Contract type', 'Percentage'];
            const output = Object.entries(props.varianceCombinedData).map(([key, value]) => [key, value < 0 ? 0 : value]);
            const newArray = [labels].concat(output);
            setAvssGraphData(newArray);
        }
        else {
            setAvssGraphData([]);
        }
        setShowLoaderPostingVarianceContractGrouping(false);
    }, [props.varianceCombinedData]);

    // useEffect(() => {
    //     let maxIncentiveAmountVariance = 100;
    //     if (props.varianceCombinedData && accrualFilterEnd === undefined) {
    //         if (props.varianceCombinedData && props.varianceCombinedData.length > 0) {
    //             var data = [];
    //             props.varianceCombinedData.forEach((item, index) => {
    //                 data.push({ x: item.aggregation_field, y: item.incentive_amount_variance });
    //                 // Update the maximum number
    //                 if (item.incentive_amount_variance > maxIncentiveAmountVariance) {
    //                     maxIncentiveAmountVariance = item.incentive_amount_variance;
    //                 }
    //             });
    //             var graphData = [
    //                 {
    //                     data: data,
    //                     xName: "x",
    //                     yName: "y",
    //                     legend: "Incentive Amount Variance",
    //                     type: "Column",
    //                     color: ['#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200']
    //                 },
    //             ];
    //             setAvssGraphData(graphData);
    //         }
    //         else {
    //             setAvssGraphData([]);
    //         }
    //     }
    //     else if (accrualFilterEnd !== undefined) {
    //         if (props.varianceCombinedData && props.varianceCombinedData.length > 0) {
    //             var data = [];
    //             props.varianceCombinedData.forEach((item, index) => {
    //                 data.push({ x: item.aggregation_field, y: item.incentive_amount_variance });
    //                 if (item.incentive_amount_variance > maxIncentiveAmountVariance) {
    //                     maxIncentiveAmountVariance = item.incentive_amount_variance;
    //                 }
    //             });
    //             var filteredData = data.filter(item => {
    //                 switch (optionType) {
    //                     case '=':
    //                         return item.y === accrualFilterEnd;
    //                     case '>':
    //                         return item.y > accrualFilterEnd;
    //                     case '<':
    //                         return item.y < accrualFilterEnd;
    //                     case '>=':
    //                         return item.y >= accrualFilterEnd;
    //                     case '<=':
    //                         return item.y <= accrualFilterEnd;
    //                     default:
    //                         return true; // No filter or unknown option
    //                 }
    //             });
    //             var graphData = [
    //                 {
    //                     data: filteredData,
    //                     xName: "x",
    //                     yName: "y",
    //                     legend: "Incentive Amount Variance",
    //                     type: "Column",
    //                     color: ['#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200']
    //                 },
    //             ];
    //             setAvssGraphData(graphData);
    //         }
    //         else {
    //             setAvssGraphData([]);
    //         }

    //     }
    //     setMaximumNumber(maxIncentiveAmountVariance === 100 ? 100 : maxIncentiveAmountVariance);
    // }, [props.varianceCombinedData, accrualFilterEnd]);

    // useEffect(() => {
    //     props.loadVarianceGraph(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, applicationType, sourceDataType, aggregationField, filterData);
    // }, [filterData]);

    useEffect(() => {
        var newArray = []
        var newArray1 = []
        if (props.varianceCombinedData && props.varianceCombinedData.length > 0) {
            props.varianceCombinedData.map(item => {
                const newItem = {};
                Object.entries(item).map(([key, value]) => {
                    newArray.push({ 'field': key, 'title': key.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) });
                    const formattedKey = key.replace(/(?:_| |\b)(\w)/g, function ($1) {
                        return $1.toUpperCase().replace('_', ' ');
                    });
                    newItem[formattedKey] = value;
                });
                newArray1.push(newItem);
            })
            setDataRows(newArray1);
            setDataCount(props.varianceCombinedData.length)
        }
    }, [props.varianceCombinedData]);


    useEffect(() => {
        props.allowedApps();
        props.loadSourceDataType(sessionStorage.getItem("application"), 'source_data_type');
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])

    const [postingType, setPostingType] = React.useState('accrual');
    const handlePostingType = (e) => {
        setPostingType(e.target.value);
        setVarianceType('');
    }
    const [varianceType, setVarianceType] = React.useState('Contract Variance');


    const handleAggregationField = (e) => {
        setAggregationField(e.target.value);
    }

    const handleStartDate = (e) => {
        setStartDate(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }
    const handleVarience = (e) => {
        setOptionType(e.target.value);
    }
    const handleOutput = (e) => {
        setOutput(e.target.value);
    }
    const filterSettings = { type: 'CheckBox' };
    const groupOptions = { showGroupedColumn: true };
    const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search'];
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
            grid.showSpinner();
            const exportProperties = {
                pageOrientation: 'Landscape',
                pageSize: 'A0',
                fileName: Moment.utc(new Date()).format('MM/DD/YYYY') + '.pdf',
                theme: {
                    header: {
                        bold: true,
                        fontSize: 14,
                    },
                    record: {
                        bold: false,
                        fontSize: 14,
                    },
                    caption: {
                        bold: true,
                        fontSize: 14,
                    },
                }
            };
            grid.pdfExport(exportProperties);
        }
        if (grid && args.item.id === 'grid_excelexport') {
            grid.showSpinner();
            const excelExportProperties = {
                dataSource: [...dataRows],
            };
            grid.excelExport(excelExportProperties);
        }
    };

    const pdfExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const excelExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const valueFormat = (field, data, column) => {
        if (data && data[field].includes('.')) {
            return parseFloat(data[field]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).toString()

        }
        else if (data && data[field].includes('T00:00:00Z')) {
            return Moment(data[field], true).isValid()
                ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
        }
        else
            return data[field];
    };

    function handleFilterData(newValue, type) {
        let temp = [...filterData];
        temp.push(newValue)
        setFilterData(temp);
    };
    function handleDeleteChip(value, index) {
        var deletedFilterData = filterData.filter(item => item !== value)
        setFilterData(deletedFilterData)
    };
    const loaded = (args) => {
        let chart = document.getElementById("charts");
        chart.setAttribute("title", "");
    };
    const load = (args) => {
        let selectedTheme = "Material";
        args.chart.theme = (
            selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
        )
            .replace(/-dark/i, "Dark")
            .replace(/contrast/i, "Contrast");
        if (selectedTheme === "highcontrast") {
            args.chart.series[0].marker.dataLabel.font.color = "#000000";
            args.chart.series[1].marker.dataLabel.font.color = "#000000";
            args.chart.series[2].marker.dataLabel.font.color = "#000000";
        }
    };

    function convertToSnakeCase(string) {
        return string.replace(/\s+/g, '_').toLowerCase();
    }
    function handleSubmit() {
        setShowGraph2(true);
        setShowLoaderPostingVarianceContractGrouping(true);
        props.loadVarianceGraph(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, sessionStorage.getItem("application"), sourceDataType, aggregationField);
    }
    // console.log("graph Data", avssGraphData)

    return (
        <LoadingOverlay
            active={showLoaderPostingVarianceContractGrouping}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div>
                <div className={classes.bodyContainer} style={{ display: "flex" }}>
                    <FormControl sx={{ m: 1, maxWidth: 115 }} size="small" style={{ marginLeft: "1px" }} className={classes.formControlStyle}>
                        <InputLabel>Posting Type</InputLabel>
                        <Select
                            // labelId="posting-type"
                            // id="posting-type-select"
                            // label="Posting Type"
                            className={classes.selectBorder}
                            style={{ border: 'none' }}
                            value={postingType}
                            onChange={handlePostingType}
                            label="Posting Type"
                        // className={clsx({
                        //     [classes.select]: true
                        // })}
                        // classes={{
                        //     selectMenu: classes.selectedItem
                        // }}
                        >
                            <MenuItem value={'accrual'}>
                                Accrual
                            </MenuItem>
                            <MenuItem value={'payment'}>
                                Payment
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 200 }} size="small" className={classes.formControlStyle}>
                        <InputLabel id="posting-type"> Attributes</InputLabel>
                        <Select
                            className={classes.selectBorder}
                            style={{ border: 'none' }}
                            value={aggregationField}
                            onChange={handleAggregationField}
                            label=" Attributes"
                        // className={clsx({
                        //     [classes.select4]: true
                        // })}
                        // classes={{
                        //     selectMenu: classes.selectedItem
                        // }}
                        >
                            <MenuItem value={'contract_type'}>
                                Contract Type
                            </MenuItem>
                            <MenuItem value={'contract_group'}>
                                Contract Group
                            </MenuItem>
                            <MenuItem value={'contract_sub_group'}>
                                Contract Sub Group
                            </MenuItem>
                            <MenuItem value={'calculation_method'}>
                                Calculation Method
                            </MenuItem>
                            {/* <MenuItem value={'customer_number'}>
                                Customer Number
                            </MenuItem>
                            <MenuItem value={'material_number'}>
                                Material Number
                            </MenuItem> */}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 140 }} size="small" className={classes.formControlStyle}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                disableToolbar
                                // label={'Start Date'}
                                label="Start Date"
                                clearable
                                InputProps={{
                                    padding: 0,
                                    disableUnderline: true,
                                    style: {
                                        padding: '1px 10 1px 11px',
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #E0E0E0',
                                        width: '100%',
                                        height: '2.3rem',
                                        border: 'none'
                                    }
                                }}
                                value={startDate}
                                onChange={handleStartDate}
                                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                                format="MM/DD/YYYY"
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 140 }} size="small" className={classes.formControlStyle}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                disableToolbar
                                clearable
                                // label={'End Date'}
                                label="End Date"
                                InputProps={{
                                    padding: 0,
                                    disableUnderline: true,
                                    style: {
                                        padding: '1px 10 1px 11px',
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #E0E0E0',
                                        width: '100%',
                                        height: '2.3rem',
                                        border: 'none'
                                    }
                                }}
                                value={endDate}
                                onChange={handleEndDate}
                                renderInput={(props) => <TextField  {...props} className={classes.noBorder} size='small' helperText={null} />}
                                format="MM/DD/YYYY"
                                minDate={startDate ? startDate : false}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 80 }} size="small" className={classes.formControlStyle}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            style={{ marginRight: 3 }}
                            onClick={handleSubmit}
                            twoline="true"
                            disabled={postingType && sourceDataType && startDate && endDate ? false : true}
                        >
                            <DirectionsRunIcon />
                        </Button>
                    </FormControl>
                </div>
                {output === 'Chart' && showGraph2 && props.varianceCombinedData && Object.keys(props.varianceCombinedData).length > 0 ? (
                    <>
                        <div className={classes.NewDiv}>
                            <div className={`area-chart-wrapper${classes.graphContainer}`}>
                                <Typography style={{ textAlign: 'center', marginBottom: 0, fontSize: "12px" }} color="primary" >{`Incentive Amount Variance of ${aggregationField.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}`}</Typography>
                                <Chart
                                    height={206}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={avssGraphData}
                                    options={{
                                        is3D: true,
                                        colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                        legend: { position: 'top', alignment: 'center' },
                                        chartArea: { 'width': '120%', 'height': '75%' },
                                    }}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                                {/* <ChartComponent
                                    id='chartsVar'
                                    style={{ textAlign: "center" }}
                                    legendSettings={{ enableHighlight: true }}
                                    primaryXAxis={{
                                        valueType: 'Category',
                                        labelIntersectAction: Browser.isDevice ? "None" : "Trim",
                                        labelRotation: Browser.isDevice ? -45 : 0,
                                        interval: 1,
                                        // maximum: maximumNumber + maximumNumber / 100,
                                        // interval: parseInt(maximumNumber / 10),
                                        majorGridLines: { width: 0 },
                                        majorTickLines: { width: 0 },
                                    }}
                                    primaryYAxis={{
                                        labelFormat: '{value}',
                                        // title: 'Varience',
                                        edgeLabelPlacement: 'Shift',
                                        majorTickLines: { width: 0 },
                                        lineStyle: { width: 0 }
                                    }}
                                    chartArea={{ border: { width: 0 } }}
                                    load={load.bind(this)}
                                    loaded={loaded.bind(this)}
                                    // title='Incentive Amount Varience'
                                    width={Browser.isDevice ? "100%" : "100%"}
                                    height={Browser.isDevice ? "80%" : "51%"}
                                    tooltip={{ enable: true }}>
                                    <Inject services={[BarSeries, DataLabel, Category, Legend, Tooltip, Highlight]} />
                                    <SeriesCollectionDirective>
                                        {avssGraphData &&
                                            avssGraphData.length > 0 &&
                                            avssGraphData.map((item, i) => (
                                                <SeriesDirective
                                                    key={i}
                                                    dataSource={item.data}
                                                    xName={item.xName}
                                                    yName={item.yName}
                                                    columnSpacing={0.1}
                                                    // width={2}
                                                    name='Varience'
                                                    type='Bar'
                                                // fill={'#ACC200'}
                                                />
                                            ))}
                                    </SeriesCollectionDirective>
                                </ChartComponent> */}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={classes.NewDiv1}>
                        <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                        {/* <div className={classes.NewDiv1}> No Data to Display !</div> */}
                    </div>
                )}
                {/* </Grid> */}
                {/* <Grid
                        item
                        md={12}
                        xs={6}
                        className={classes.container}
                    > */}
                {output === 'List' && props.varianceCombinedData && props.varianceCombinedData.length > 0 ? (
                    <Paper className={classes.gridBox} style={{ paddingTop: 0, }}>
                        <div style={{ marginTop: 10, overflow: 'scroll' }}>
                            {/* {console.log(dataRows)} */}
                            <GridComponent
                                dataSource={dataRows}
                                id='grid'
                                allowSorting={true} allowGrouping={true} showColumnMenu={true}
                                // allowPaging={true}
                                // pageSettings={{ pageSize: 10000 }}
                                groupSettings={groupOptions}
                                filterSettings={filterSettings}
                                allowFiltering={true}
                                ref={g => grid = g}
                                toolbar={toolbarOptions}
                                toolbarClick={toolbarClick}
                                allowPdfExport={true} allowExcelExport={true}
                                pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
                                height={window.screen.height * .64}
                                gridLines='Both' allowResizing={true}
                                enableVirtualization={true} enableColumnVirtualization={false}
                            >
                                <ColumnsDirective>
                                    {columns.map((item, order) => {
                                        return (
                                            <ColumnDirective
                                                field={item.field}
                                                valueAccessor={valueFormat}
                                                headerText={item.title}
                                                width="200px" minWidth='100px' maxWidth='300px'

                                            />
                                        );
                                    })
                                    }
                                </ColumnsDirective>
                                <Inject services={[Toolbar, PdfExport, ExcelExport, Aggregate, Group, Sort, ColumnMenu, Filter, VirtualScroll, Resize, Page]} />
                            </GridComponent>
                        </div>
                    </Paper>
                ) : (
                    <Typography variant='h4'>
                        {/* There is no data to show noww. */}
                    </Typography>
                )
                }
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        varianceCombinedData: state.initialData.varianceCombinedData,
        loading: state.initialData.loading,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadVarianceGraph: (s, e, type, applicationType, sourceDataType, aggregationField, filterData) => dispatch(loadVarianceCombinedNew(s, e, type, applicationType, sourceDataType, aggregationField, filterData, "pie")),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostingsVariancebyContractGroupings);