import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { connect } from 'react-redux';
import { getExceptionManagementAll,addExceptionManagement } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: 10
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    }
}));
const ExceptionManagementMain = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [userStore, setUserStore] = React.useState([]);    
    useEffect(() => {
        props.getExceptionManagementAll(1, 10);
    }, []);
    useEffect(() => {
        if (props.exceptionManagementAll) {
            setUserStore(props.exceptionManagementAll);
        }
    }, [props.exceptionManagementAll]);
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'style={{ marginLeft:7,marginTop:18}}>Exception Management List View</Typography>
                    
                </div>
                {userStore && userStore.length > 0 ?
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center'className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center'className={classes.tabHead}>Contract Number</TableCell>
                                    <TableCell align='center'className={classes.tabHead}>Start Date</TableCell>
                                    <TableCell align='center'className={classes.tabHead}>End Date</TableCell>
                                    <TableCell align='center'className={classes.tabHead}>Incentive Override</TableCell>
                                    <TableCell align='center'className={classes.tabHead}>Override Reason</TableCell>
                                    <TableCell align='center'className={classes.tabHead}>Comments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {userStore.map(item => {
                                    return (
                                        <StyledTableRow key={item.contract_number}>
                                            <TableCell align='center'classes={{root:classes.fontSetting}}  style={{cursor: 'pointer'}}>{item.application_type}</TableCell>
                                            <TableCell align='center'classes={{root:classes.fontSetting}}  style={{cursor: 'pointer'}}>{item.contract_number}</TableCell>
                                            <TableCell align='center'classes={{root:classes.fontSetting}}  style={{cursor: 'pointer'}}>{Moment(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell align='center'classes={{root:classes.fontSetting}}  style={{cursor: 'pointer'}}>{Moment(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell align='center'classes={{root:classes.fontSetting}}  style={{cursor: 'pointer'}}>{item.exception_value}</TableCell>
                                            <TableCell align='center'classes={{root:classes.fontSetting}}  style={{cursor: 'pointer'}}>{item.override_reason}</TableCell>
                                            <TableCell align='center'classes={{root:classes.fontSetting}}  style={{cursor: 'pointer'}}>{item.comments}</TableCell>     
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'style={{marginLeft:'16px'}}>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        exceptionManagementAll: state.customerData.exceptionManagementAll,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getExceptionManagementAll: () => dispatch(getExceptionManagementAll()),
        deleteAccrualDesigner:(data,type,id)=>dispatch(addExceptionManagement(data, type, id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExceptionManagementMain);