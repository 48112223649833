import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs, Typography, Link, useMediaQuery,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, IconButton
} from '@mui/material';
import { SearchInput } from '../../components';
import { connect } from 'react-redux';
import Moment from 'moment';
import { useHistory } from "react-router-dom";
import ChangeCircleIcon from '@mui/icons-material/Timeline';
import { runContractTrends, downloadContractTrendsResltsSheet } from '../../redux/actions';
import { MessageDialogue } from '../MessageDialogue';
import { SummarizeOutlined } from '@mui/icons-material';
import { RingLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { GetApp } from '@mui/icons-material';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '12px 10px',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18,
        fontSize: theme.typography.h4.fontSize
    },
    spinner: {
        height: '100vh'
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const tableHeads = [
    { id: 0, heading: 'Contract Number', align: 'left' },
    { id: 1, heading: 'Contract Type' },
    { id: 1, heading: 'Calculation Method' },
    { id: 2, heading: 'Contract Internal Description' },
    { id: 3, heading: 'Calculation Start Date' },
    { id: 4, heading: 'Calculation End Date' },
    { id: 4, heading: 'Posting Number' },
    { id: 5, heading: 'Posting Date' },
    { id: 9, heading: 'Incentive Amount' },
    { id: 6, heading: 'Delta Reason' },
    { id: 10, heading: 'Tags' },
    { id: 11, heading: 'Actions' },
];
const FinancialPostingsResult = props => {
    const history = useHistory();
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
    }, [props.financialPostingsResults])
    useEffect(() => {
        sessionStorage.removeItem('contractTrendsQuery')
    }, [])
    const [dialog, setDialog] = React.useState(false)
    const [errorList, setErrorList] = React.useState([])
    const [financialPostingsResults, setFinancialPostingsResults] = React.useState([])
    const [delta, setDelta] = React.useState(false)
    useEffect(() => {
        if (props.financialPostingsResults) {
            setFinancialPostingsResults(props.financialPostingsResults)
            var data = props.financialPostingsResults.filter(item => item.delta_reason != '').length;
            if (data > 0)
                setDelta(true)
        }
    }, [props.financialPostingsResults])
    useEffect(() => {
        setErrorList(props.financialPostingsErrorList)
        if (props.financialPostingsErrorList && props.financialPostingsErrorList.length > 0) {
            setDialog(true);
        }
    }, [props.financialPostingsErrorList])
    const handleCalculationAnalysis = (item) => {
        var formData = {
            "start_date": Moment.utc(item.start_date).format('MM/DD/YYYY'),
            "end_date": Moment.utc(item.end_date).format('MM/DD/YYYY'),
            "application_type": sessionStorage.getItem('application'),
            "data_source_type": 'Direct - Sales Data',
            "contract_number": item.contract_number,
            "posting_number": item.posting_number,
            "posting_type": sessionStorage.getItem('page') === 'Accrual' ? 'accrual' : 'payment'
        };
        sessionStorage.setItem("contractTrendsQuery", JSON.stringify(formData));
        props.runContractTrends(formData);
    }

    const handleSheetDownload = (item) => {
        var formData = {
            start_date: Moment.utc(item.start_date).format("MM/DD/YYYY"),
            end_date: Moment.utc(item.end_date).format("MM/DD/YYYY"),
            application_type: sessionStorage.getItem("application"),
            data_source_type: item.source_data_type,
            contract_number: item.contract_number,
            posting_number: item.posting_number,
            posting_type: sessionStorage.getItem('page') === 'Accrual' ? 'accrual' : 'payment',
            display_level: "billing",
        };
        if (formData) {
            props.downloadSheet(formData);
        }
    };
    function handleError() {
        setDialog(false)
    }
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <LoadingOverlay
                    active={props.loading}
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '50px',
                            '& svg circle': {
                                stroke: '#64C4B4'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(52, 52, 52, 0)'
                        }),
                        content: (base) => ({
                            ...base,
                            color: '#64C4B4'
                        })
                    }}
                    spinner={<RingLoader size={100} />}
                    className={classes.spinner}
                >
                    <div>
                        <div
                            className={clsx(classes.root, className)}
                        >
                            <div className={clsx({
                                [classes.row]: isDesktop,
                            })} >
                                <Typography variant="h1" color='primary'>
                                    {sessionStorage.getItem('application') && sessionStorage.getItem('application') == 'Supplier Chargeback' ? 'Claims Provision Results' : 'Financial Postings Results'}

                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* <SearchInput /> */}
                                    <div onClick={() => { setDialog(true) }} style={{
                                        cursor: "pointer",
                                        color: "red",
                                        fontSize: theme.typography.h4.fontSize,
                                        display: "flex",
                                        alignItems: "center",
                                        border: "1px solid red",
                                        padding: 4,
                                        marginLeft: 10,
                                    }}>Show Message Summary</div>
                                </div>

                            </div>
                            {dialog &&

                                <MessageDialogue open={dialog} messageList={errorList} onChange={handleError} />
                            }
                            {/**<Dialog
                    open={dialog}
                >
                    <DialogTitle>Error Summary</DialogTitle>
                    <DialogContent classes={{
                        root: { padding: '43px 37px 0px 37px !important' }
                    }}>
                        <List>
                            {errorList && errorList.map((item, index) => {
                                return (<ListItem>
                                    <ListItemText
                                        primary={[index + 1] + ' . ' + item}
                                    />
                                </ListItem>
                                )
                            })}
                        </List>

                        <DialogActions>
                            <Button color="primary" variant="outlined" onClick={() => setDialog(false)}>Continue</Button>
                        </DialogActions>
                    </DialogContent>
                        </Dialog>*/}
                            {financialPostingsResults && financialPostingsResults.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                {tableHeads
                                                    .filter(item => delta ? item : item.heading != 'Delta Reason')
                                                    .map(item => {
                                                        return (
                                                            <TableCell align={item.align ? 'left' : 'center'} className={classes.tableCell} key={item.id}>
                                                                {item.heading}
                                                            </TableCell>
                                                        )
                                                    })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {financialPostingsResults && financialPostingsResults.map(item => {
                                                return (
                                                    <StyledTableRow key={item.contract_number ? item.contract_number : ''}>
                                                        <TableCell align='left' className={classes.tableCell}>
                                                            {item.contract_number}
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{item.contract_type}</TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{item.calculation_method}</TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{item.contract_internal_description}</TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{item.posting_number ? item.posting_number : ''}</TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{Moment.utc(item.posting_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center' className={classes.tableCell}>{parseFloat(item.incentive_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                        {Array.apply(null, {
                                                            length: delta ? 1 : 0
                                                        }).map((e, i) => (
                                                            <TableCell align='center' >{item.delta_reason}</TableCell>

                                                        ))}
                                                        <TableCell align='center' className={classes.tableCell}>{item.tags ? item.tags.toString() : ''}</TableCell>
                                                        <TableCell align="center"
                                                            className={classes.tableCell}
                                                            style={{ display: 'flex' }} >
                                                            <IconButton classes={{ root: classes.IconButton }} size={'small'} onClick={() => handleCalculationAnalysis(item)}>
                                                                {/* <ChangeCircleIcon /> */}
                                                                <SummarizeOutlined fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                size={"small"}
                                                                onClick={() =>
                                                                    handleSheetDownload(item)
                                                                }
                                                            >
                                                                <GetApp fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    No Results
                                </Typography>
                            }
                        </div >
                    </div>
                </LoadingOverlay>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        runContractTrends: (formData) => dispatch(runContractTrends(formData, 'contractV3')),
        downloadSheet: (formData) => dispatch(downloadContractTrendsResltsSheet(formData)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        financialPostingsResults: state.simulationData.financialPostingsSimulationData,
        financialPostingsErrorList: state.simulationData.financialPostingsErrorList

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(FinancialPostingsResult);