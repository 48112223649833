import * as React from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  StackingColumnSeries,
  Tooltip,
  Highlight,
  DateTime,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";
import Moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import RingLoader from "react-spinners/RingLoader";

const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }`;
const StackedColumn = (props) => {
  const onChartLoad = (args) => {
    let chart = document.getElementById(props.id);
    chart.setAttribute("title", "");
  };
  const load = (args) => {
    let selectedTheme = "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast");
  };
  const axisLabelRender = (args) => {
    args.text = args.text.includes("00Z")
      ? Moment.utc(args.text).format("MMMM YYYY")
      : args.text;
    //args.text.replace("0000000", "0M").replace("000000", "M");
  };
  const palette = ["#FF0060", "#32cd32", "#F6B53F", "#6FAAB0", "#55047d"];
  return (
    <div className="control-pane">
      <style>{SAMPLE_CSS}</style>
      <div className="control-section">
        <LoadingOverlay
          active={props.loading}
          spinner={<RingLoader />}
          styles={{
            spinner: (base) => ({
              ...base,
              width: "20px",
              "& svg circle": {
                stroke: "#045FB4",
              },
            }),
            overlay: (base) => ({
              ...base,
              background: "rgba(52, 52, 52, 0)",
            }),
            content: (base) => ({
              ...base,
              color: "black",
            }),
          }}
        >
          {props.data && props.data.length > 0 && props.xAxisData ? (
            <ChartComponent
              id={props.id}
              // style={{ textAlign: "center" }}
              legendSettings={{ enableHighlight: true }}
              palettes={palette}
              primaryXAxis={{
                majorGridLines: { width: 0 },
                minorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 },
                lineStyle: { width: 0 },
                labelIntersectAction: "Rotate45",
                title:
                  props.xAxisData && props.xAxisData.title
                    ? props.xAxisData.title
                    : "",
                valueType:
                  props.xAxisData && props.xAxisData.valueType
                    ? props.xAxisData.valueType
                    : "DateTime",
                intervalType:
                  props.xAxisData && props.xAxisData.intervalType
                    ? props.xAxisData.intervalType
                    : "Months",
                interval:
                  props.xAxisData && props.xAxisData.interval
                    ? props.xAxisData.interval
                    : 1,
                minimum:
                  props.xAxisData && props.xAxisData.minimum
                    ? props.xAxisData.minimum
                    : new Date(new Date().getFullYear(), 0, 1),
                maximum:
                  props.xAxisData && props.xAxisData.maximum
                    ? props.xAxisData.maximum
                    : new Date(new Date().getFullYear(), 11, 31),
                labelFormat:
                  props.xAxisData && props.xAxisData.labelFormat
                    ? props.xAxisData.labelFormat
                    : "MMM",
              }}
              primaryYAxis={{
                title: props.yAxistitle,
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 }, 
                majorGridLines: { width: 1 },
                minorGridLines: { width: 1 },
                minorTickLines: { width: 0 },
                labelFormat: "{value}",
              }}
              width={"100%"}
              chartArea={{ border: { width: 0 } }}
              load={load.bind(this)}
              title={props.title}
              loaded={onChartLoad.bind(this)}
              tooltip={{
                enable: true,
                format: "${point.x}: ${point.y}",
              }}
              //axisLabelRender={axisLabelRender.bind(this)}
            >
              <Inject
                services={[
                  StackingColumnSeries,
                  Category,
                  Legend,
                  Tooltip,
                  Highlight,
                  DateTime,
                ]}
              />
              <SeriesCollectionDirective>
                {props.data &&
                  props.data.length > 0 &&
                  props.data.map((item, index) => (
                    <SeriesDirective
                      dataSource={item.data}
                      xName={item.xName}
                      yName={item.yName}
                      name={item.legend}
                      columnWidth={'30px'}
                      border={{ width: 1, color: "white" }}
                      type="StackingColumn"
                    />
                  ))}
              </SeriesCollectionDirective>
            </ChartComponent>
          ) : ( 
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
                fontSize: 13,
                color: "grey",
              }}
            >
              No Data to Display !
            </div>
           )
          } 
        </LoadingOverlay>
      </div>
    </div>
  );
};
export default StackedColumn;
