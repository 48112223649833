import React, { useEffect } from 'react';
import {
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Select,
    MenuItem,
    TablePagination,
    Checkbox, FormLabel
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import {getCountryData } from '../../../../../redux/actions';
import { connect } from 'react-redux';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '5px 0px 0px 0px'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 'webkitFillAvailalble'
    },

    stickycolhead: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 0,
        backgroundColor: '#ffffff',
        width: 55
    },

    stickycolbody: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 0,
        backgroundColor: '#ffffff',
        width: 55,

    },
    stickycolhead1: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 55,
        backgroundColor: '#ffffff',
        maxWidth: 230,
        minWidth: 140,
        width: 230
    },

    stickycolbody1: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 55,
        backgroundColor: '#ffffff',

    },
    stickycolhead2: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        top: 150,
    },

    stickycolbody2: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 210,
        backgroundColor: '#ffffff',
        width: 250
    },
    checked: {
        justifyContent: 'center',
        margin: '0px 5px 0px 5px'
    },
    checkedCell: {
        display: 'flex',
        flexDirection: 'column',
    }
}))

const RegionList = (props) => {


    const classes = useStyles();
    const [promoFields, setPromoFields] = React.useState([]);
    const [regionData, setRegionData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [isAllSelected, setAllSeleted] = React.useState(false);
    const [checkedList, setCheckedList] = React.useState([]);
    const [checkedRegion, setCheckedRegion] = React.useState([]);
    const [promotionName, setPromotionName] = React.useState([]);
    const [countryDetails, setCountryDetails] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 25
            },
            '&:nth-of-type(even)': {
                height: 30
            }
        }
    }))(TableRow);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        if (props.promoData && props.promoData.length > 0) {
            setPromoFields(props.promoData)
        }
    }, [props.promoData]);
    useEffect(() => {
        props.getCountryData(localStorage.getItem('Country'))
    }, [localStorage.getItem('Country')])

   useEffect(() => {
        setRegionData(props.regionData)
    }, [props.regionData])

    function handleOnChange(event, states, list, idx, materialId) {
        var description = ''
        if (event.target.value) {
            var desc = promoFields.filter(item => item.promotion_name == event.target.value)
            description = desc[0]['promotion_description']
        }
            regionData.map((reg, index1) => {
                if (reg.ID==list.ID) {
                    if (regionData[index1]['promotion_state'].some(k => k.state === states.state)) {
                        regionData[index1]['promotion_state'][idx]['promotion_name'] = event.target.value
                        regionData[index1]['promotion_state'][idx]['promotion_description'] = description

                    }
                }
            })
      
        setRegionData(regionData)
        props.onChange(regionData)
    }

    const handleSelect = (event, checked) => {
        let newSelected = [];
        if (!checked) {
            regionData.map(item => {
                newSelected = newSelected.concat(checkedList, item.ID);
            })
            setCheckedList(newSelected);
        }
        else {
            setCheckedList([])
        }
        setAllSeleted(!isAllSelected)
    }

    const handleClick = (event, name, type) => {
        let list = []
        if (type == 'region')
            list = checkedRegion
        else
            list = checkedList
        let newSelected = [];
        const selectedIndex = list.indexOf(name);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(list, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(list.slice(1));
        } else if (selectedIndex === list.length - 1) {
            newSelected = newSelected.concat(list.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                list.slice(0, selectedIndex),
                list.slice(selectedIndex + 1),
            );
        }
        if (type == 'region') {
            setCheckedRegion(newSelected)
        }
        else {
            setCheckedList(newSelected);
            if (isAllSelected)
                setAllSeleted(false)
            if (newSelected.length === regionData.length)
                setAllSeleted(true)
        }
    };
    const isSelected = (name, type) => type == 'model' ? checkedList.indexOf(name) !== -1 : checkedRegion.indexOf(name) !== -1;

    function handlePromotionForAll(event) {
        var description = ''
        var stateArr = []
        if (event.target.value) {
            var desc = promoFields.filter(item => item.promotion_name == event.target.value)
            description = desc[0]['promotion_description']
        }
        
        countryDetails && countryDetails.map((item, i) => {
            if (checkedRegion && checkedRegion.length > 0) {
                if (checkedRegion.includes(item.state))
                    stateArr.push(item.state)
            }
            else {
                if (states.includes(item.state))
                    stateArr.push(item.state)
            }
        })
        regionData && regionData.map((itemX, i) => {
            if (checkedList.length > 0) {
                if (checkedList.includes(itemX.ID)) {
                    
                            regionData[i]['promotion_state'] && regionData[i]['promotion_state'].map((item, index) => {
                                if (stateArr.includes(item.state)) {
                                    regionData[i]['promotion_state'][index]['promotion_name'] = event.target.value
                                    regionData[i]['promotion_state'][index]['promotion_description'] = description
                                }

                            })
                        
                    
                }
            }
            if (checkedRegion.length > 0 && checkedList.length == 0) {
                    regionData[i]['promotion_state'] && regionData[i]['promotion_state'].map((item, index) => {
                        if (stateArr.includes(item.state)) {
                            regionData[i]['promotion_state'][index]['promotion_name'] = event.target.value
                            regionData[i]['promotion_state'][index]['promotion_description'] = description

                        }

                    })

            }
        })
        setRegionData(regionData)
        setCheckedList([])
        setCheckedRegion([])
        setAllSeleted(false)
        props.onChange(regionData)

    }
   
  
  
    useEffect(() => {
        var temp = []
        if (props.countryData && props.countryData.length > 0) {
            setCountryDetails(props.countryData)
            props.countryData
                .map((item, index) => {
                    temp.push(item.state)
                })

            setStates(temp)
        }
    }, [props.countryData])
   
  
    return (
        <div
            className={classes.root}>
            <div className={classes.container}>
                <Grid container
                    style={{ marginBottom: 5, padding: '5px 10px 0px 10px' }}
                    spacing={2}
                >
                    <Grid
                        item
                        md={2}
                        xs={12}

                    >
                        <FormLabel variant="h5" className={classes.formDiv}>
                            Promotion Name
                        </FormLabel>
                        <div className={classes.dropdownContainer}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={promotionName}
                                onChange={handlePromotionForAll}
                                disabled={checkedList.length > 0 || checkedRegion.length > 0 ? false : true}
                                style={{ maxHeight: "50px", width: '100%', marginTop: 10 }}
                            >
                                <MenuItem value=""></MenuItem>
                                {promoFields && promoFields
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.promotion_name} style={{ textTransform: 'capitalize' }}>
                                                {item.promotion_name}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>

                    </Grid>
                </Grid>
            </div>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>

                        <Grid container spacing={2}
                            style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                        >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        className={classes.checkbox}
                                                        defaultChecked size="small"
                                                        onChange={(event) => handleSelect(event, isAllSelected)}
                                                        checked={isAllSelected}
                                                        key='all'
                                                        selected={isAllSelected}
                                                    />
                                                </TableCell>
                                                <TableCell align='center' className={classes.stickycolhead}>Model Year</TableCell>
                                                 <TableCell align='center' className={classes.stickycolhead1}>Market Segment</TableCell>
                                                
                                                {states && states.length > 0 && states
                                                    .sort()
                                                    .map(item => {
                                                        const isRegionSelected = isSelected(item, 'region');
                                                        return (
                                                            <TableCell align='center' style={{ maxWidth: 100, width: 100, padding: '1px,0px ' }}
                                                                onClick={(event) => handleClick(event, item, 'region')}>
                                                                <div className={classes.checkedCell}>
                                                                    <label>{item}</label>
                                                                    <Checkbox
                                                                        className={classes.checked}
                                                                        defaultChecked size="small"
                                                                        checked={isRegionSelected}
                                                                        key={item}
                                                                        selected={isRegionSelected}

                                                                    />
                                                                </div>
                                                            </TableCell>
                                                        )
                                                    })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {regionData && regionData
                                                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                                                .map((itemX, index) => {
                                                    const isItemSelected = isSelected(itemX.ID, 'model');
                                                    return (
                                                        <StyledTableRow >
                                                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, itemX.ID, 'model')}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    defaultChecked size="small"
                                                                    checked={isItemSelected}
                                                                    key={itemX.ID}
                                                                    selected={isItemSelected}
                                                                />
                                                            </TableCell>
                                                            <TableCell align='center' className={classes.stickycolbody}>{itemX.material_group1}</TableCell>
                                                             <TableCell align='center' className={classes.stickycolbody1} style={{ width: '118px' }}>{itemX.material_group3} </TableCell>
                                                            
                                                            {states && states.length > 0 && states.map(item => (
                                                                itemX['promotion_state'] && itemX['promotion_state'].map((state, i) => {
                                                                    if (itemX['promotion_state'][i] && itemX['promotion_state'][i]['state'] == item) {
                                                                        return (
                                                                            <TableCell align='center' style={{ padding: 0, margin: 0 }}>
                                                                                <Select
                                                                                    disableUnderline
                                                                                    defaultValue={state.promotion_name}
                                                                                    onChange={(e) => handleOnChange(e, state, itemX, i, index)}
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    SelectDisplayProps={{ style: { height: '100%' } }}
                                                                                    style={{ textTransform: 'capitalize', margin: 0 }}
                                                                                >
                                                                                    <MenuItem value='' name={item} style={{ textTransform: 'capitalize' }}>

                                                                                    </MenuItem>
                                                                                    {promoFields && promoFields
                                                                                        .map((item) => {
                                                                                            return (
                                                                                                <MenuItem value={item.promotion_name} name={item} style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.promotion_name}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                </Select>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                })))
                                                            }


                                                        </StyledTableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                        </Grid>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 20, 25, 30]}
                            component="div"
                            count={regionData && regionData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </form>
            </Card>
        </div >
    );

};

const mapStateToProps = state => ({
    countryData: state.promotionData.countryData,
});

const mapDispatchToProps = dispatch => {
    return {
        getCountryData: (country) => dispatch(getCountryData(country)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(RegionList);