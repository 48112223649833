import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Button, FormLabel, Breadcrumbs, Link, Typography,
    Dialog, OutlinedInput, DialogActions, TextareaAutosize
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getDefaultValuesKeyFigure,
    submitQuery, getDefaultValuesAllDataNew,
    loadValuesBasedOnAppTypeAndField, getQueryDetails
} from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        textAlign: 'center'
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const EditQuery = props => {
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [mappingValuesSet, setMappingValuesSet] = React.useState([]);
    const [Name, setName] = React.useState('');
    const [Description, setDescription] = React.useState('');
    const [Query, setQuery] = React.useState('');
    const [newArrayOfKeys, setNewArrayOfKeys] = React.useState([]);
    const [newArrayOfValues, setNewArrayOfValues] = React.useState([]);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getQueryDetails(appId)
    }, []);
    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
            props.tableData.records.forEach(function (item) {
                if (item.drop_down_value_keys)
                    tempKeys.push(...item.drop_down_value_keys);
                if (item.drop_down_values)
                    tempValues.push(...item.drop_down_values);
            });
            setNewArrayOfKeys(tempKeys);
            setNewArrayOfValues(tempValues);
        } else {
            setNewArrayOfKeys([]);
            setNewArrayOfValues([]);
        }
    }, [props.tableData]);
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && newArrayOfKeys && newArrayOfKeys.length > 0) {
            newArrayOfKeys.map((item, index) => {
                if (props.labelNewAPIData[item]) {
                    setMappingValuesSet((mappingValuesSet) => ({
                        ...mappingValuesSet,
                        [item]: props.labelNewAPIData[item]['current']
                    }));
                } else {
                    setMappingValuesSet((mappingValuesSet) => ({
                        ...mappingValuesSet,
                        [item]: newArrayOfValues[index]
                    }));
                }
            })
        } else {
            setMappingValuesSet([])
        }
    }, [newArrayOfKeys, props.labelNewAPIData])
    const [open, setOpen] = useState(true);
    const handleSubmitQuery = () => {
        var data = {
            "name": Name,
            "description": Description,
            "query": Query
        }
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        props.submitQuery(data, appId, 'put');
    }
    useEffect(() => {

        if (props.QueryDetailsData && Object.keys(props.QueryDetailsData).length > 0) {
            setName(props.QueryDetailsData.Name);
            setDescription(props.QueryDetailsData.Description);
            setQuery(props.QueryDetailsData.Query);
        }
    }, [props.QueryDetailsData]);
    const handleClear = () => {
        setName([]);
        setDescription([]);
        setQuery([]);
    }
    return (
        <div className={clsx(classes.root, className)}>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel style={{ marginRight: 10 }}>
                        Name
                    </FormLabel>
                    <OutlinedInput
                        inputProps={{ min: "0", step: "1" }}
                        classes={{ root: classes.inputTwoLine }}
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div style={{ padding: 20 }}>
                    <FormLabel style={{ marginRight: 10 }}>
                        Description
                    </FormLabel>
                    <OutlinedInput
                        inputProps={{ min: "0", step: "1" }}
                        classes={{ root: classes.inputTwoLine }}
                        value={Description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={() => history.push('/query-field')}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={() => setOpen(false)}
                        disabled={Name && Description ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ padding: 29 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h1' classes={{
                        root: classes.link
                    }} component="button"
                        onClick={() => history.push('/query-field')}
                    >
                        Query
                    </Link>
                    <Typography color="textPrimary" variant='h1'>&ensp;Edit Query</Typography>
                </Breadcrumbs>
                <Typography variant='h3' style={{ fontSize: '1rem', position: "absolute", marginTop: 20 }} > Query </Typography>
                <TextareaAutosize
                    value={Query}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{
                        width: '95%', marginTop: 18,
                        marginLeft: '4rem',
                        paddingLeft: 10
                    }}
                />
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitQuery}
                        disabled={Query.length === 0 ? true : false}
                    >
                        Submit
                    </Button>
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        tableData: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        QueryDetailsData: state.QueryFieldData.queryDetailsData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'QueryField')),
        submitQuery: (data, id, type) => dispatch(submitQuery(data, id, type)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getQueryDetails: (id) => dispatch(getQueryDetails(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditQuery);