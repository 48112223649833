import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Breadcrumbs,Typography,
    Table,TableCell,TableHead,TableRow,TableContainer,TableBody,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        // padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const PriceSimulationReportResult = props => {
    const history = useHistory();
    const classes = useStyles();
    const { className } = props;

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
               <Link
                     variant="h5"
                     classes={{
                       root: classes.link,
                     }}
                     to="/price-simulation-report"
                >
                    Price Simulation report
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}
            >
                {props.priceCalForSimulationData && props.priceCalForSimulationData.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell className={classes.tableCell}> Customer Number </TableCell>
                                    <TableCell className={classes.tableCell}> Material Number </TableCell>
                                    <TableCell className={classes.tableCell}> Pricing Date </TableCell>
                                    <TableCell className={classes.tableCell}> Competitive Price </TableCell>
                                    <TableCell className={classes.tableCell}> Contract Price </TableCell>
                                    <TableCell className={classes.tableCell}> Scale Price </TableCell>
                                    <TableCell className={classes.tableCell}> Promotion Discount </TableCell>
                                    <TableCell className={classes.tableCell}> Material Discount </TableCell>
                                    <TableCell className={classes.tableCell}> Customer Discount </TableCell>
                                    <TableCell className={classes.tableCell}> Freight Charges </TableCell>
                                    <TableCell className={classes.tableCell}> Technical Support </TableCell>
                                    <TableCell className={classes.tableCell}> Volume Rebate </TableCell>
                                    <TableCell className={classes.tableCell}> Product Cost </TableCell>
                                    <TableCell className={classes.tableCell}> G&A </TableCell>
                                    
                                    
                                    
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }} >
                                {props.priceCalForSimulationData && props.priceCalForSimulationData.map(item => {
                                    return (
                                        <StyledTableRow>
                                            <TableCell align='center'>{item.customer_number}</TableCell>
                                            <TableCell align='center'>{item.material_number}</TableCell>
                                            <TableCell align='center'>{item.pricing_date}</TableCell>
                                            <TableCell align='center'>{item['Competitive Price'] ? item['Competitive Price']['value']:''}</TableCell>
                                            <TableCell align='center'>{item['Contract Price'] ? item['Contract Price']['value']:''}</TableCell>
                                            <TableCell align='center'>{item['Scale Price'] ? item['Scale Price']['value']:''}</TableCell>
                                            <TableCell align='center'>{item['Promotion Discount'] ? item['Promotion Discount']['value'] : ''}</TableCell>
                                            <TableCell align='center'>{item['Material Discount'] ? item['Material Discount']['value'] : ''}</TableCell>
                                            <TableCell align='center'>{item['Customer Discount'] ? item['Customer Discount']['value'] : ''}</TableCell>
                                            <TableCell align='center'>{item['Freight Charges'] ? item['Freight Charges']['value'] : ''}</TableCell>
                                            <TableCell align='center'>{item['Technical Support'] ? item['Technical Support']['value'] : ''}</TableCell>
                                            <TableCell align='center'>{item['Volume Rebate'] ? item['Volume Rebate']['value'] : ''}</TableCell>
                                            <TableCell align='center'>{item['Product Cost'] ? item['Product Cost']['value'] : ''}</TableCell>
                                            <TableCell align='center'>{item['G&A'] ? item['G&A']['value'] : ''}</TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No Results
                    </Typography>
                }
            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
    }
};
export default connect(mapStateToProps,null)(PriceSimulationReportResult);