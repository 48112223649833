import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { fetchDocumentHistory } from '../../../../redux/actions';
import { connect } from 'react-redux';
import DocHistory from '../../../DocumentManagement/Components/DocHistory';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    headerRoot: {
        margin: 0,
        padding: '10px 0 0 0'
    },
    headerRootTimeline: {
        margin: 0,
        padding: '10px 0 0 40px',
        textAlign: 'center'
    }
}));

const ApprovalStatus = props => {
    const classes = useStyles();
    const [steps, setSteps] = React.useState(['New', 'Submit for approval']);
    const [history, setHistory] = React.useState([]);

    useEffect(() => {
        if (props.historyData && props.historyData.length) {
            setHistory(props.historyData)
        }
    }, [props.historyData]);
    useEffect(() => {
        if (props.approvalData && props.approvalData.contract_number) {
            var data = {
                "functionality": "contract_header",
                "functionality_id": props.approvalData.contract_number,
                "application_type": sessionStorage.getItem('application')
            }
            props.getHistory(data)
        }
    }, [props.approvalData]);


    return (
        <div className={classes.root}>
            <Card>
                {props.approvalData &&
                    <div className={classes.container}>
                        <Grid container className={classes.gridContainer}>

                            {(history && history.length > 0) &&
                                < Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DocHistory data={history} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </Card>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        historyData: state.DocumentManagement.documentHistory,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHistory: (data) => dispatch(fetchDocumentHistory(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalStatus);