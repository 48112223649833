import React, { useEffect } from 'react';
import {
    Button, Typography, FormControlLabel, Checkbox, IconButton,
    Grid, Box, Stepper, Step, StepLabel
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import '../../Styles/custom.css'
import { DefineMarket } from '../../PromotionScenario/components/MarketDefinition/Components';
import { PromoMultiSelect, HorizontalDropdown } from '../../Components';
import RegionSelection from './RegionSelection';
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 25
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: 25
    },
    filterItem: {
        display: 'flex',
    },
}))
const Definition = (props) => {
    const classes = useStyles();
    // const steps = getSteps();
    const history = useHistory();
    const [exclude, setExclude] = React.useState(false);
    const [modelYear, setModelYear] = React.useState('');
    const [country, setCountry] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [locationData, setLocationData] = React.useState([]);
    const [locDetails, setLocDetails] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [selectedModels, setSelectedModels] = React.useState([]);
    const [defaultExpanded, setDefaultExpanded] = React.useState([]);
    const [selectedModelItems, setSelectedModelItems] = React.useState([]);
    const [regionEn, setRegionEn] = React.useState(false);
    useEffect(() => {
        console.log(props.defineMarket)
        if (props.defineData) {
            if (props.defineData.custom_group_type == 'model grouping') {
                setSelectedModelItems(props.defineData.model)
                setSelectedModels(props.defineData.model_display)
                setDefaultExpanded(props.defineData.model_display ? Object.keys(props.defineData.model_display) : [])
            }
            if (props.defineData.custom_group_type == 'state grouping') {
                setSelectedModelItems(props.defineData.location)
                setSelectedModels(props.defineData.location_display)
                setDefaultExpanded(props.defineData.location_display ? Object.keys(props.defineData.location_display) : [])
                setCountry(props.defineData.country)
                setRegion(props.defineData.region)
                handleLocation(props.defineData.country, props.defineData.region, 'edit')
            }
            setModelYear(props.defineData.model_year)
            setExclude(props.defineData.exclude)
        }

    }, [props.defineData])
    const handleLocation = (loc, reg, type) => {
        if (loc && reg && props.locationData) {
            var data = props.locationData && props.locationData.filter((item, i) => loc.includes(item.category)).flatMap((item, i) => item.children)
            console.log(data)
            if (data) {
                setLocationData(data.filter((item, i) => reg.includes(item.category)))
                if (type == 'edit') {
                    setLocDetails(data.filter((item, i) => reg.includes(item.category)).flatMap((item, i) => item.relationship))
                }
            }
        }
    }
    function handleModelYears(value) {
        setModelYear(value)
        props.onChange('model_year', value)
    }
    function handleExclusion(event) {
        setExclude(event.target.checked)
        if (selectedModelItems)
            handleSummit(selectedModelItems, selectedModels, event.target.checked)
    }
    const handleSummit = (selected, child, exclusion) => {
        if (props.customType == 'state grouping') {
            var data = {
                'location': selected,
                'location_display': child,
                'exclude': exclusion,
                'country': country,
                'region': region
            }
        }
        else {
            var data = {
                'models': selected,
                'model_display': child,
                'exclude': exclusion
            }
        }
        if (props.customType == 'model grouping')
            props.onChange('model', data)
        else
            props.onChange('location', data)
    }
    const handleClick = (type) => {
        if (type == 'region') {
            setRegionEn(!regionEn)
            const isExpanded = !regionEn
            setExpanded(isExpanded ? [...expanded, type] : []);
        }
        else {
            setAnchorEl(!anchorEl)
            const isExpanded = !anchorEl
            setExpanded(isExpanded ? [...expanded, type] : []);
        }
    };
    function handleCheckedData(selected, child, type) {
        setAnchorEl(false)
        if (type !== 'cancel') {
            setSelectedModelItems(selected)
            setSelectedModels(child)
            handleSummit(selected, child, exclude)
            setAnchorEl(false)
            setDefaultExpanded(child ? Object.keys(child) : [])
        }
    };
    function handleRegion(reg, loc) {
        if (reg && loc) {
            setRegion(reg)
            setLocDetails(loc)
            props.onChange('region', reg)
            var cntry = loc && loc.flatMap((item, i) => item.country)
            setCountry([... new Set(cntry)])
            handleLocation(cntry, reg)
        }
        setRegionEn(false)
    }

    return (
        <div className={classes.root}>
            <FormControlLabel className='promo-font' style={{ color: '#4E4E4E' }}
                control={
                    <Checkbox checked={exclude}
                        onChange={(e) => handleExclusion(e)}
                    />}
                label='Do a model exclusion' />
            {exclude ?
                <Typography className='setup-label' style={{ marginTop: -8 }}>Pick the group you want to exclude the model from</Typography> :
                <Typography className='setup-label' style={{ marginTop: -8 }}>Pick the models you want to group together</Typography>}

            <div className={`filter-menu-container ${classes.filterBox}`}>
                <Typography className='promo-font' color='#19170F'>Filter</Typography>
                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="28" viewBox="0 0 2 28" fill="none">
                    <path d="M1 1L0.999999 27" stroke="#E2E2E2" stroke-width="0.8" stroke-linecap="round" />
                </svg>
                {props.customType == 'model grouping' &&
                    <>
                        <div className={`${classes.filterItem}`}>
                            <Typography variant='h4' color='grey'>Model Year</Typography>
                            <PromoMultiSelect id='model-year' onChange={(value) => handleModelYears(value)}
                                options={props.modelYears ? props.modelYears : []} prevalue={modelYear ? modelYear : []} />
                        </div>
                        <div>
                            <div className={classes.filterItem}>
                                <Typography variant='h4' color='grey'>Model Selection</Typography>
                                <IconButton onClick={() => handleClick('model_filter')}>{anchorEl ? <ExpandLess /> : <ExpandMore />}</IconButton>
                            </div>
                            {anchorEl &&
                                <HorizontalDropdown head='Model Selection' id={'model'} data={props.models ? props.models : []} expand={expanded} onSubmit={handleCheckedData}
                                    prevalue={selectedModels} selected={selectedModelItems} type='model_filter' />}
                        </div>
                    </>
                }
                {props.customType == 'state grouping' &&
                    <>
                        <div >
                            <div className={classes.filterItem}>
                                <Typography variant='h4' color='grey'>Region</Typography>
                                <IconButton onClick={() => handleClick('region')}>{regionEn ? <ExpandLess /> : <ExpandMore />}</IconButton>

                            </div>
                            {regionEn &&
                                <div>
                                    <RegionSelection data={props.locationData ? props.locationData : []}
                                        onChange={handleRegion} region={region} locDetails={locDetails} />
                                </div>
                            }
                        </div>
                        <div>
                            <div className={classes.filterItem}>
                                <Typography variant='h4' color='grey'>State Selection</Typography>
                                <IconButton onClick={() => handleClick('location_filter')}>{anchorEl ? <ExpandLess /> : <ExpandMore />}</IconButton>
                            </div>{console.log(props.locationData)}
                            {anchorEl &&
                                <HorizontalDropdown id={'location'} data={locationData ? locationData : []} expand={expanded} onSubmit={handleCheckedData}
                                    prevalue={selectedModels} selected={selectedModelItems} type='location_filter' />}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        // locationData: state.promotionData.locationFilters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Definition);