import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
    navBgColor: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: 5,
        borderRadius: 10,
        marginRight: 17,
        marginLeft: 17
    }
}));
const handleClick = (pge) => {
    history.push('/' + pge)
}

const Pricing = (props) => {
    const classes = useStyles();
    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', fontSize: '16px', margin: 'auto' }}
            navbarScroll
        >
            <Nav.Link onClick={(() => { handleClick('pricing-dashboard') })} style={{ borderRight: '1px solid lightgrey' }} >Dashboard</Nav.Link>
            <NavDropdown title="Price Maintenance" style={{ borderRight: '1px solid lightgrey' }}>
                <NavDropdown.Item onClick={(() => { handleClick('pricing-maintenance') })} >
                    Price Maintenance
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(() => { handleClick('pricing-maintenance/pricing-maintenance-excel-upload') })} >
                    Upload Excel
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(() => { handleClick('pricing-approval') })} >
                    Pricing Approval
                </NavDropdown.Item>
                {/* <NavDropdown.Item onClick={(() => { handleClick('external-data-excel-upload-pricing') })} >
                    Upload External Data
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(() => { handleClick('build-analysis-data-pricing') })}>
                    Build Analysis Data
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClick('external-config-pricing')}>
                    External Config
                </NavDropdown.Item> */}
                <NavDropdown title=" Product Feature " className={classes.navBgColor} //style={{marginLeft:'17px',marginRight:'17px'}}
                >
                    <NavDropdown.Item onClick={() => handleClick('product-feature-pricing')} > Product Feature Pricing</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClick('product-feature-calculate')} > Product Feature Calculation </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title=" Market Price " className={classes.navBgColor} //style={{marginLeft:'17px',marginRight:'17px'}}
                >
                    <NavDropdown.Item onClick={() => handleClick('competitor-data')}>
                        Competition Sales Data
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClick('list-price')} >Competition List Pricing</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClick('pricing-market-price')} >
                        Market Price Calculation
                    </NavDropdown.Item>
                </NavDropdown>




            </NavDropdown >
            <NavDropdown title="Price Management" style={{ borderRight: '1px solid lightgrey' }}>
                <NavDropdown.Item onClick={() => handleClick('price-simulation-cpq')}>Price Simulation</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/pricing-calculation")}>
                    Price Points
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClick('price-catalog')}  >
                    Price Catalog
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClick('EDI-file')} >Price Catalog EDI File</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClick('price-policy-exceptions-report')} >
                    Price Policy Exceptions
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Price Optimization" style={{ borderRight: '1px solid lightgrey' }}>
                <NavDropdown.Item onClick={() => handleClick('price-simulation-historical')} >Historical</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClick('price-simulation-forecast')} >Future Simulation</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClick('price-elasticity')} >Price Elasticity</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleClick('price-adjustment-simulation')} >Price Adjustment Simulation</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="CPQ" id="basic-nav-dropdown" style={{ borderRight: '1px solid lightgrey' }}>
                {/* <NavDropdown.Item onClick={() => handleClick('price-simulation-cpq')}>Quick Quote</NavDropdown.Item> */}
                <NavDropdown.Item onClick={() => handleClick('price-simulation-detailed-quote')} >Comprehensive Quote</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Reporting & Analytics" id="basic-nav-dropdown" style={{ borderRight: '1px solid lightgrey' }} >
                <NavDropdown.Item onClick={() => history.push("/price-simulation-report")}>Simulation Report</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/price-analytics')}>Margin-Actual/Target/Forecast</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/price-revenue-margin-analytics')}>
                    Margin Contribution
                </NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => history.push('margin-analytics')}>
                    Margin Analytics
                </NavDropdown.Item> */}
                <NavDropdown.Item onClick={() => history.push('/price-waterfallchart')}>
                    Gross to Net
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/customer-margin')}>
                    Portfolio Margin
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/profitability-chart')}>
                    Profitability Trend
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/top-growing-declining-performers')}>
                    Growing/Declining Stats
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/margin-price-percentage')}>
                    Margin Price Diff Stats
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/margin-regional-analysis')}>
                    Margin-Regional Analysis
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/analytics-main')}>
                    Predefined Analytics
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Price Contracts" id="basic-nav-dropdown" style={{ borderRight: '1px solid lightgrey' }}>
                <NavDropdown.Item onClick={() => history.push("/contract-setup")}>
                    Contract Setup
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/approval-reject")}>
                    Contract Approval
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/documents")}>
                    Document Management
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Utilities" id="basic-nav-dropdown" style={{ borderRight: '1px solid lightgrey' }}>
                <NavDropdown.Item onClick={() => history.push("/pricing-batchjob-setup")}>
                    Batch Job Setup
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/pricing-batchjob-status")}>
                    Batch Job Status
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/message-logs")}>Message Log</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/error-logs")}>Error Log</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/help-desk-cp")}>On Demand Videos</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Configuration" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => history.push("/pricing-qualifier-config")}>
                    Price Attribute
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/pricing-type-config")}>Price Types</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/pricing-list-master")}>
                    Price List Master
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/pricing-calculation-formula")}>
                    Formula Procedure
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/pricing-formula")}>
                    Formula - Price Points
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);