import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, DialogTitle,
    Dialog, DialogActions, DialogContent, Button,
    List, ListItem, ListItemText, Fab, useMediaQuery
} from '@mui/material';
import clsx from "clsx";
import { getBatchJobStatus } from '../../redux/actions';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import Moment from 'moment';
import { Refresh } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    errorMsg: {
        cursor: 'pointer',
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid red',
        padding: 4,
        marginLeft: 10,
        marginRight: 10,
        width: '110px'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    }
}));

const PromoBatchJobStatus = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });
    const [dialog, setDialog] = React.useState(false);
    const [errorList, setErrorList] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        props.getBatchJobStatusData(sessionStorage.getItem("application"));
    }, []);

    useEffect(() => {
        var newArray = []
        if (props.batchJobStatusData && props.batchJobStatusData.length > 0) {
            props.batchJobStatusData.map(e => {
                var obj = {
                    'app_type': e.app_type,
                    'batch_job_type': e.batch_job_type,
                    'batch_job_name': e.batch_job_name,
                    'planned_start_calendar': e.planned_start_calendar,
                    'planned_start_date': e.planned_start_date,
                    "planned_start_time": e.planned_start_time,
                    'actual_start': e.batch_job_status !== null ? e.batch_job_status[0].actual_start : '',
                    'job_status': e.batch_job_status !== null ? e.batch_job_status[0].job_status : '',
                    'completion_time': e.batch_job_status !== null ? e.batch_job_status[0].completion_time : '',
                    'error_list': e.batch_job_status !== null ? e.batch_job_status[0].error_list : '',
                    'success_list': e.batch_job_status !== null ? e.batch_job_status[0].success_list : '',
                };
                newArray.push(obj)
            })
            setDataRows(newArray)
        }

    }, [props.batchJobStatusData])


    const columns = [
        {
            field: 'app_type',
            title: 'Application Type',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.app_type}</a>
        },
        {
            field: 'batch_job_type',
            title: 'Batch Job Type',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.batch_job_type}</a>
        },
        {
            field: 'batch_job_name',
            title: 'Batch Job Name',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.batch_job_name}</a>
        },
        {
            field: 'planned_start_calendar',
            title: 'Planned Start Calendar',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.planned_start_calendar}</a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            },
        },
        {
            field: 'planned_start_date',
            title: 'Planned Start',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {Moment.utc(rowData.planned_start_date).format('MM/DD/YYYY') + '\n' + Moment.utc(rowData.planned_start_date).local().format('HH:mm:ss')}</a>
        },
        {
            field: 'actual_start',
            title: 'Actual Start',
            editable: 'never',
            type: 'string',
            render: rowData => ((rowData.job_status && rowData.job_status.length > 0) ? (<a className={classes.hover} > {Moment.utc(rowData.actual_start).local().format('MM/DD/YYYY HH:mm:ss')}</a>) : (<a align='center' classes={{ root: classes.fontSetting }} style={{ color: 'grey' }}>Not yet started</a>))
        },
        {
            field: 'job_status',
            title: 'Job Status',
            editable: 'never',
            type: 'string',
            render: rowData => ((rowData.job_status && rowData.job_status.length > 0) ? (<a className={classes.hover} > {rowData.job_status}</a>) : (<a align='center' classes={{ root: classes.fontSetting }} style={{ color: 'grey' }}>Not yet started</a>))
        },
        {
            field: 'completion_time',
            title: 'Completion Time',
            editable: 'never',
            type: 'string',
            render: rowData => ((rowData.job_status && rowData.job_status !== 'Running' && rowData.job_status.length > 0) ? (<a className={classes.hover} > {Moment.utc(rowData.completion_time).local().format('MM/DD/YYYY HH:mm:ss')}</a>) : (<a align='center' classes={{ root: classes.fontSetting }} style={{ color: 'grey' }}></a>))
        },
        {
            field: 'error_list',
            title: 'Error Details',
            editable: 'never',
            type: 'string',
            render: rowData => ((rowData.error_list && rowData.error_list.length > 0) ? (<a className={classes.errorMsg} onClick={() => viewErrorList(rowData.error_list, 'Error Summary')} style={{ color: 'red', marginLeft: '3.5em' }} > Show Error Summary</a>) : '')
        },
        {
            field: 'success_list',
            title: 'Success Details',
            editable: 'never',
            type: 'string',
            render: rowData => ((rowData.success_list && rowData.success_list.length > 0) ? (<a className={classes.errorMsg} onClick={() => viewErrorList(rowData.success_list, 'Success Summary')} style={{ color: 'green', marginLeft: '3.5em' }} > Show Summary</a>) : '')
        },

    ];

    function viewErrorList(error, title) {
        setErrorList(error)
        setDialog(true)
        setTitle(title)
    }

    function handleRefresh() {
        props.getBatchJobStatusData(sessionStorage.getItem("application"));
    }

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<HashLoader />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div>
                <div className={classes.root}>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: 0 }}>
                        <Typography variant="h1" color='primary' style={{ marginTop: 6 }}> Batch Job Status </Typography>
                        <div
                            className={clsx({
                                [classes.row]: isDesktop,
                            })}
                        >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                onClick={handleRefresh}
                            >
                                <Refresh color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Refresh</Typography>
                            </Fab>
                        </div>
                    </div>
                    {dataRows && dataRows.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRows}
                            style={{ marginTop: '-1px' }}
                            options={{
                                maxBodyHeight: '100vh',
                                emptyRowsWhenPaging: false,
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 15,
                                pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        />
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </div>
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={dialog}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent classes={{
                        root: { padding: '43px 37px 0px 37px !important' }
                    }}>
                        <List>
                            {errorList && errorList.length > 0 ? errorList.map((item, index) => {
                                return (<ListItem>
                                    <ListItemText
                                        primary={[index + 1] + ' . ' + item}
                                    />
                                </ListItem>
                                )
                            }) :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </List>

                        <DialogActions>
                            <Button color="primary" variant="outlined" onClick={() => setDialog(false)}>Continue</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.customerData.loading,
        batchJobStatusData: state.customerData.batchJobStatus,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBatchJobStatusData: (type) => dispatch(getBatchJobStatus(type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoBatchJobStatus);