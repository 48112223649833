import React, { useEffect, useRef } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button, ToggleButton, ToggleButtonGroup, Chip, Tabs, Tab,
    Tooltip
} from '@mui/material';
import { ExpandMore, ExpandLess, ChevronRight, ChevronLeft } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { StickerSelectedContainer, StickerTableHeader } from '../../../GlobalSetup/Components';
import { getBudgetingConfig, getCustomGroups, getMaterialFilters } from '../../../../../redux/actions';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PromoMultiSelect, NestedDropdown, NestedDropdown2 } from '../../../Components';
import { PromoRegion } from './Components';
import BeatLoader from "react-spinners/BeatLoader";
import { accessInnerChildren, generateCombinations } from '../../../Components/ManipulateData';
import { CustomGroups } from '../MarketDefinition';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        height: '100%',
        overflowX: 'hidden'
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 7,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    mainHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
    },
    tableHeaderTop: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 40,
        border: '1px solid #f5f5f5',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5'
    },
    tableHeaderBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        border: '1px solid #f5f5f5',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5'
    },

    sellButton: {
        border: '1px solid #D8D8D8',
        margin: '-5px 10px',
        padding: 3,
        width: 35,
        height: 35,
        cursor: 'pointer',
    },

    refreshButton: {
        border: '1px solid #D8D8D8',
        margin: '-1px 45px',
        padding: 3,
        width: 35,
        height: 35,
        cursor: 'pointer',
    },

    span: {
        marginLeft: 5,
        fontSize: '.9rem'
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #E2E2E2',
        height: '48px',
        borderRadius: '8px',
        marginTop: 19,
        padding: '8px',
        width: 'fit-content',
        marginBottom: 42,
        backgroundColor: theme.palette.white,
        marginLeft: 10,
    },

    filterItem: {
        display: 'flex',
        marginRight: 12
    },
    filterContainer: {
        marginRight: 12,
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        marginTop: 30,
        marginLeft: '5px',
        width: 'auto',
        overflowX: 'scroll'
    },
    chipHighlighter: {
        borderColor: theme.palette.primary.main,
        border: "2px solid"
    }

}));






const GridMain = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const divRef = useRef(null);
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [modelYear, setModelYear] = React.useState('2020');
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [customEn, setCustomEn] = React.useState(false)
    const [activeCustom, setActiveCustom] = React.useState(false);
    const [activeSticker, setActiveSticker] = React.useState([]);
    const [customId, setCustomId] = React.useState([]);
    const [definedSticker, setDefinedSticker] = React.useState([]);

    const [selectedModels, setSelectedModels] = React.useState([]);
    const [selectedModelItems, setSelectedModelItems] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [gridPageFilter, setGridPageFilter] = React.useState([]);
    const [costData, setCostData] = React.useState({});
    const [changeStickerMode, SetChangeStickerMode] = React.useState(false);
    const [currentSticker, setCurrentSticker] = React.useState('');
    const [changedSticker, setChangedSticker] = React.useState('');
    const [currentLoc, setCurrentLoc] = React.useState({});
    const [currentModel, setCurrentModel] = React.useState({});
    const [gridData, setGridData] = React.useState('');
    const [prefillArray, setPrefillArray] = React.useState('');
    const [prefillId, setPrefillId] = React.useState('');
    const [overflowed, setOverflowed] = React.useState(false)
    const [active, setActive] = React.useState('');

    // useEffect(() => {
    //     //props.getConfigData();
    //     setSelectedCountry(props.country)
    // }, [props.country]);
    useEffect(() => {
        setCostData(props.costingData)
    }, [props.costingData]);
    useEffect(() => {
        // props.getFilters()
        if (props.modelYears && props.modelYears.length > 0)
            setModelYear(props.modelYears[0])
    }, [props.modelYears]);
    useEffect(() => {
        if (props.definedData && props.stickers) {
            var data = props.stickers && Object.entries(props.stickers)
                .filter(([key, value]) => props.definedData && Object.keys(props.definedData).includes(key) && { [key]: value })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setDefinedSticker(data)
            if (activeSticker && !props.definedData[activeSticker])
                setActiveSticker('')
        }
    }, [props.definedData, props.stickers])
    useEffect(() => {
        if (props.page == 'grid-menu' && gridData && productType) {
            var data = []
            gridData.filter((item, i) => item.category == productType && item)
                .map(item => { data = item.children })
            setPrefillArray(data)
        }

    }, [gridData, productType])
    useEffect(() => {
        if (props.data)
            setGridData(props.data)
    }, [props.data]);
    useEffect(() => {
        if (props.productLine)
            setProductType(props.productLine[0])
        else {
            if (props.promoProductLines && !productType)
                setProductType(props.promoProductLines[0])
        }
    }, [props.promoProductLines, props.productLine]);

    useEffect(() => {
        // Prevent browser's default back/forward navigation when the page loads
        window.history.pushState(null, null, window.location.pathname);

        const handlePopState = (event) => {
            // Prevent back/forward navigation by re-pushing the current state
            window.history.pushState(null, null, window.location.pathname);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const preventSwipeNavigation = (event) => {
            if (event.deltaY < 0 || event.deltaX < 0) {
                // Push the state again if scrolling up or to the left
                window.history.pushState(null, null, window.location.pathname);
            }
        };
        // Add the event listener for scroll events to detect swiping
        window.addEventListener('wheel', preventSwipeNavigation);
        return () => {
            window.removeEventListener('wheel', preventSwipeNavigation);
        };
    }, []);

    function handleChange(e) {
        setProductType(e.target.value);
    }
    const handleChangeSticker = (sticker, model, loc) => {
        var country = definedSticker?.[sticker] ? definedSticker[sticker].country : ''
        setSelectedCountry(country)
        SetChangeStickerMode(true)
        setCurrentSticker(sticker)
        //setCurrentSticker(sticker && sticker.ID)
        setCurrentModel(model)
        setCurrentLoc(loc)

    }
    const handleOnRemove = (sticker, model, loc) => {
        //var id = sticker
        //var stickerkey = props.stickerKeys && props.stickerKeys[id] ? props.stickerKeys[id].name : ''
        const selected = accessInnerChildren(model)
        var gridData = handleUpdateData(sticker, model, loc, selected?.children)
        var displayData = handleUpdateDisplay(sticker, model, loc, selected?.relationships)
        var region = loc && loc.relationship && Object.values(loc.relationship).join("_")
        var count = displayData[sticker]?.location?.[region] ? (parseInt(displayData[sticker]['location'][region]) - parseInt(Object.keys(selected.children).length)) : 0
        if (count > 0) {
            displayData[sticker]['location'][region] = count;
        }
        else {
            delete displayData[sticker]?.location?.[region];
            delete gridData[sticker]?.location?.[region];
        }
        var formData = {
            grid_data: gridData,
            display_data: displayData
        }
        props.handleDefine(formData, 'grid')
    }

    const handleUpdateData = (sticker, selectedModel, location, selected) => {
        var data = props.definedData && sticker ? props.definedData[sticker] : {}
        var region = location && location.relationship && Object.values(location.relationship).join("_")
        //var changedStickerkey = props.stickerKeys && props.stickerKeys[changedSticker] ? props.stickerKeys[changedSticker].name : ''
        var productline = selectedModel.fields?.product_line && selectedModel.fields.product_line;
        if (changedSticker) {
            var includedData = props.definedData && props.definedData[changedSticker] ? props.definedData[changedSticker] : {} //changed sticker
        }
        selected && Object.entries(selected).map(([item, relation]) => {
            if (data['model'][item]) {
                data['model'][item] = { ...data['model'][item], 'exclude': [...data['model'][item]['exclude'] ? data['model'][item]['exclude'] : [], region] }
                if (data['model'][item]['include'] && data['model'][item]['include'][region])
                    delete data['model'][item]['include']['region']
            }
            if (changedSticker) {
                if (includedData['model'] && includedData['model'][item]) { // add the model and region to new sticker--changed sticker
                    includedData['model'][item] = {
                        ...includedData['model'][item], 'include': {
                            ...includedData['model'][item]['include'] ? includedData['model'][item]['include'] : {},
                            [region]: location.relationship
                        }
                    }
                    var exData = includedData['model'][item]['exclude']
                    if (exData && exData.includes(region)) // remove if this region included the excluded one
                        includedData['model'][item]['exclude'] = exData.filter(item => item != region)
                }
                else {
                    var loc = includedData['location'] ? Object.keys(includedData['location']) : []
                    includedData['model'] = {
                        ...includedData['model'], [item]: { ...relation, 'product_line': productline, 'include': { [region]: location.relationship }, 'exclude': loc },
                        // 'exclude': loc
                    }
                    // includedData['model'] = {
                    //     ...includedData['model'], [item]: { ...selectedModel.relationship, 'product_line': productline, 'include': { [region]: location.relationship }, 'exclude': loc },
                    //     // 'exclude': loc
                    // }
                }
            }
        })
        var formData = {
            //grid_data: {
            [sticker]: data

        }
        if (changedSticker) {
            formData[changedSticker] = includedData
        }
        return formData
    }
    const handleUpdateDisplay = (sticker, selectedModel, location, selected) => {
        var display = props.gridFilter && sticker ? props.gridFilter[sticker] : {}
        var region = location && location.relationship && Object.values(location.relationship).join("_")
        var changedStickerkey = props.stickerKeys && props.stickerKeys[changedSticker] ? props.stickerKeys[changedSticker].name : ''

        if (changedSticker) {
            var includedDisplay = props.gridFilter && props.definedData[changedSticker] ? props.gridFilter[changedSticker] : {}
        }
        var productline = selectedModel.fields?.product_line && selectedModel.fields.product_line;
        var array = Object.values(selectedModel.relationship)
        const combinations = generateCombinations(array, productline);
        selected && combinations && [...Object.keys(selected), ...combinations].map((item, i) => {
            if (display['model'][item]) {
                display['model'][item] = { ...display['model'][item], 'exclude': [...display['model'][item]['exclude'] ? display['model'][item]['exclude'] : [], region] }
                if (display['model'][item]['include'] && display['model'][item]['include'].includes(region))
                    display['model'][item]['include'] = display['model'][item]['include'].filter(item => item != region)
            }
            if (changedSticker && Object.keys(selected).includes(item)) {
                if (includedDisplay['model'] && includedDisplay['model'][item]) { // add the model and region to new sticker--changed sticker
                    includedDisplay['model'][item] = {
                        ...includedDisplay['model'][item], 'include': [
                            ...includedDisplay['model'][item]['include'] ? includedDisplay['model'][item]['include'] : [],
                            region //[region]: location.relationship
                        ]
                    }
                    var exDisplay = includedDisplay['model'][item]['exclude']
                    if (exDisplay && exDisplay.includes(region)) // remove if this region included the excluded one
                        includedDisplay['model'][item]['exclude'] = exDisplay.filter(item => item != region)
                }
                else {
                    var loc = includedDisplay['location'] ? Object.keys(includedDisplay['location']) : []
                    includedDisplay['model'] = {
                        ...includedDisplay['model'], [item]: {/* ...selectedModel.relationship, 'product_line': productline,'include': { [region]: location.relationship }*/ 'include': [region], 'exclude': loc },
                        // 
                    }
                }
            }
        })
        var formData = {
            // display_data: {
            [sticker]: display
            //  }
        }
        if (changedSticker) {
            formData[changedSticker] = includedDisplay
        }
        return formData

    }

    const handleOnSave = (sticker) => {
        if (sticker) {
            //var sKey = props.stickerKeys?.[sticker]?.name
            setChangedSticker(sticker)
        }
        else {
            //change sticker
            var region = currentLoc && currentLoc.relationship && Object.values(currentLoc.relationship).join("_")
            var currentStickerkey = props.stickerKeys && props.stickerKeys[currentSticker] ? props.stickerKeys[currentSticker].name : ''
            var changedStickerkey = props.stickerKeys && props.stickerKeys[changedSticker] ? props.stickerKeys[changedSticker].name : ''
            const selected = accessInnerChildren(currentModel)
            var gridData = handleUpdateData(currentSticker, currentModel, currentLoc, selected?.children)
            var displayData = handleUpdateDisplay(currentSticker, currentModel, currentLoc, selected?.relationships)
            var locCount = displayData[currentSticker]?.location?.[region] ? parseInt(displayData[currentSticker]['location'][region]) : 0
            var count = locCount - Object.keys(selected.children).length
            if (count > 0) {
                displayData[currentSticker]['location'][region] = count;
            }
            else {
                delete displayData[currentSticker]?.location?.[region];
                delete gridData[currentSticker]?.location?.[region];
            }
            if (changedSticker) {
                var includedcount = (displayData[changedSticker]?.location?.[region] ? parseInt(displayData[changedSticker]['location'][region]) : 0) + Object.keys(selected.children).length
                if (displayData[changedSticker])
                    // displayData[changedSticker]'location'
                    displayData[changedSticker]['location'] = { ...displayData[changedSticker] && displayData[changedSticker]['location'] ? displayData[changedSticker]['location'] : {}, [region]: includedcount };
            }
            var formData = {
                grid_data: gridData,
                display_data: displayData
            }
            props.handleDefine(formData, 'grid')
            handleReset()
        }
    }
    function handleReset() {
        setChangedSticker('')
        setCurrentModel({})
        setCurrentLoc({})
        setCurrentSticker('')
        SetChangeStickerMode(false)
    }
    function handleModelYear(event, value) {
        setModelYear(value)
    }

    const handleClick = (type) => {
        if (type == 'model') {
            setAnchorEl(!anchorEl)
            const isExpanded = !anchorEl
            setExpanded(isExpanded ? [...expanded, 'model_filter'] : []);
        }
        if (type == 'custom') {
            setCustomEn(!customEn)
            setActiveCustom(true)
            const isExpanded = !customEn
            setExpanded(isExpanded ? [...expanded, 'custom_filter'] : []);
        }
        if (type == 'location') {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
    };

    const handleActiveSticker = (e, value) => {
        if (activeSticker.includes(value)) {
            setActiveSticker(activeSticker.filter(item => item != value))
            //setActiveStickerID(activeStickerID.filter(item => item != value.ID))
        }
        else {
            setActiveSticker([...activeSticker, value])
            //setActiveStickerID([...activeStickerID, value.ID])
        }
    }
    function handleCheckedData(selected, child, type) {
        setAnchorEl(false)
        if (type !== 'cancel') {
            //for applying grid page filter
            setGridPageFilter(prev => ({
                ...prev,
                'model': child
            }))
            setSelectedModels(child)
        }
    }
    function handleCheckedLoc(selected, child, type) {
        setAnchorloc(false)
        if (type !== 'cancel') {
            setGridPageFilter(prev => ({
                ...prev,
                'location': child
            }))
            setSelectedLoc(child)
        }
    }
    function handleCustomData(data) {
        if (data)
            props.getCustomGroupById(data)
        setCustomId(data)
        setCustomEn(false)
    }
    useEffect(() => {
        if (activeCustom) {
            if (props.customDetails && props.customDetails.records && customId) {
                var model = {}
                var modelDisplay = {}
                var loc = {}
                var locDisplay = {}
                var mFlag = 0;
                var lFlag = 0;
                props.customDetails.records.map((item, index) => {
                    if (item.models) {
                        mFlag++
                        if (item.exclude) {
                            item.models && Object.keys(item.models).map(itemx =>
                                delete model[itemx]
                            )
                            item.model_display && Object.keys(item.model_display).map(itemx =>
                                delete modelDisplay[itemx]
                            )
                        }
                        else {
                            model = { ...item.models, ...model }
                            modelDisplay = { ...item.model_display, ...modelDisplay }

                        }

                    }
                    else {
                        if (item.location) {
                            lFlag++;
                            if (item.exclude) {
                                item.location && Object.keys(item.location).map(itemx =>
                                    delete loc[itemx]
                                )
                                item.location_display && Object.keys(item.location_display).map(itemx =>
                                    delete locDisplay[itemx]
                                )
                            }
                            else {
                                loc = { ...item.location, ...loc }
                                locDisplay = { ...item.location_display, ...locDisplay }

                            }

                        }
                    }
                })
                if (mFlag > 0) {
                    setGridPageFilter(prev => ({
                        ...prev,
                        'model': modelDisplay
                    }))
                    setSelectedModels(modelDisplay)
                }
                if (lFlag > 0) {
                    setGridPageFilter(prev => ({
                        ...prev,
                        'location': locDisplay
                    }))
                    setSelectedLoc(locDisplay)
                }
            }
        }
    }, [props.customDetails])
    function handleReturn() {
        if (changeStickerMode) {
            SetChangeStickerMode(false)
            handleReset()
        }
        else
            props.handleGrid('return')
    }

    function handleCancel() {
        if (props.page === 'grid-menu')
            history.push('/promotion-dashboard')
    }

    function handleDeleteSticker() {

    }

    function handlePrefill(id) {
        setPrefillId(id)
        props.prefill(id)
    }

    useEffect(() => {
        const checkOverflow = (element) => {
            if (element.scrollWidth > 956) {
                setOverflowed(true)
            } else {
                setOverflowed(false)
            }
        };

        if (divRef.current) {
            checkOverflow(divRef.current);
        }
    }, [props.stickers, props.definedData, props.colorPicker]);

    const handleScrollLeft = () => {
        if (divRef.current) {
            divRef.current.scrollLeft -= 100;
        }
    };

    const handleScrollRight = () => {
        if (divRef.current) {
            divRef.current.scrollLeft += 100;
        }
    };
    const handleSubmit = (type) => {
        props.handleGrid(type)
        setActive(type)
    }
    function handleRefresh() {
        props.handleGrid('refresh')
        setGridPageFilter({})
    }
    return (
        <>
            <div className={classes.root}>
                <StickerTableHeader data={prefillArray} handlePrefill={handlePrefill} productLineData={props.promoProductLines}
                    defaultDivison={productType} onChange={handleChange} isDisabled={changeStickerMode ? true : false} enabledList={changeStickerMode ? [] : props.productLine} />

                <div className={classes.mainHeader}>
                    <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div className={classes.row}>
                            <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={props.page ? handleCancel : handleReturn}>
                                Return
                            </Button>
                            <Typography style={{ fontSize: 13, marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }} />
                                In Progress
                            </Typography>
                        </div>
                        {changeStickerMode && <div className={classes.row}>
                            <Button variant='text' color='primary' onClick={handleReturn} style={{ marginRight: 5 }}>Cancel</Button>
                            <Button variant='contained' color='primary' onClick={() => handleOnSave('')} disabled={changedSticker ? false : true}>Apply Sticker</Button>
                        </div>
                        }
                    </div>
                    {!changeStickerMode &&
                        <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between', width: '35%' }}>
                            <div className={classes.row}>
                                <Typography>
                                    <MonetizationOnOutlinedIcon />
                                </Typography>
                                <Typography style={{ marginRight: 70 }}>
                                    (In use) Currency Rate 0.73
                                </Typography>

                            </div>
                            <Typography>
                                <SellOutlinedIcon className={classes.sellButton} />
                            </Typography>
                        </div>
                    }
                </div>
                {changeStickerMode ?
                    <StickerSelectedContainer page='grid' data={props.stickers} handleNext={handleOnSave}
                        onDelete={handleDeleteSticker} country={selectedCountry} /*onChangeCountry={handleOnSave}*/ currentSticker={currentSticker}
                        stickerName={"Selected Stickers"} colorPicker={props.colorPicker}
                    />
                    :
                    <>
                        <div className={classes.mainHeader}>
                            <div className={classes.row}>
                                <AutorenewIcon className={classes.refreshButton} onClick={handleRefresh} />
                                <Typography style={{ margin: 5 }} >Tot. Promo Cost:</Typography>
                                <Typography style={{ margin: 5 }} >
                                    {props.loader ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loader} size={6} />
                                        :
                                        <> <span>{costData && costData.summary && costData.summary.total_cost ? costData.summary.total_cost.toLocaleString() : 0}</span> <span> {props.currency}</span></>
                                    }
                                </Typography>
                                <Typography style={{ margin: 5 }}>| Tot Vol. {costData && costData.summary && costData.summary.total_volume ? costData.summary.total_volume.toFixed(2) : 0}
                                </Typography>
                                <Typography style={{ margin: 5 }} > Tot Avg/Unit. <> <span>{costData && costData.summary && costData.summary.avg_cost_per_volume ?
                                    parseFloat(
                                        costData.summary.avg_cost_per_volume
                                    ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : 0}</span> <span > {" " + props.currency}</span> | </> </Typography>
                                <Typography color='grey' variant='h4' style={{ margin: 5 }}> Breakdown {" >"}</Typography>
                            </div>
                            <div className={classes.row}>

                                <Button variant='outlined' color='primary' onClick={() => handleSubmit('save')}
                                > {props.loading && active == 'save' ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                    : 'Save Scenario'
                                    }</Button>
                                <Button style={{ marginLeft: 10 }} variant="contained" onClick={() => handleSubmit('review')}>
                                    {props.loading && active == 'review' ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                        : '  Review Scenario'
                                    }
                                </Button>
                                <Button style={{ marginLeft: 10 }} onClick={handleCancel} variant="outlined">Cancel</Button>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#f5f5f5', marginLeft: 5 }}>
                            <div className={classes.tableHeaderTop}>
                                <div style={{ marginLeft: 35, marginTop: 20, color: 'grey', display: 'flex' }}>
                                    <Typography style={{ padding: 0 }}>In Use Stickers</Typography>
                                    <div style={{ position: 'relative', marginLeft: 5, width: '70vw', height: '35px' }}>
                                        <div ref={divRef} style={{ height: '100%', width: '80%', overflowX: 'auto', whiteSpace: 'nowrap', paddingRight: '20px' }}>
                                            {//props.stickers && props.stickers.length > 0 && props.stickerKeys && props.stickers
                                                //.filter((item, i) => props.definedData && props.stickerKeys[item.ID] && Object.keys(props.definedData).includes(props.stickerKeys[item.ID]['name']) && item)
                                                definedSticker && Object.entries(definedSticker)
                                                    .map(([key, option]) => (
                                                        <Chip
                                                            key={option.ID}
                                                            id={option.ID}
                                                            // label={(option.promo_variability ? 'PV -' : '') + (option.version > 1 ? `V${option.version} - ${option.sticker_description}` : option.sticker_description)}
                                                            label={option.promo_variability ? `PV - (${option.sticker_name})` : option.sticker_name}
                                                            variant="outlined"
                                                            onClick={(e) => handleActiveSticker(e, key)}
                                                            sx={{ backgroundColor: 'white' }}
                                                            icon={<CircleIcon style={{ color: props.colorPicker && props.colorPicker[key] }} size='small' />}
                                                            className={activeSticker.includes(key) && classes.chipHighlighter}
                                                            style={{ margin: '0px 3px 4px 0px' }}

                                                        />

                                                    ))

                                            }
                                        </div>
                                        <div style={{ position: 'absolute', right: 0, top: 0, width: '20%', height: '100%', display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                            {overflowed &&
                                                <>
                                                    <Tooltip title='previous' style={{ cursor: 'pointer' }}><ChevronLeft onClick={handleScrollLeft} /></Tooltip>
                                                    <Tooltip title='next' style={{ cursor: 'pointer' }}><ChevronRight onClick={handleScrollRight} /></Tooltip>
                                                    ...{props.stickers && Object.entries(props.stickers).filter(([key, value]) => props.definedData && Object.keys(props.definedData).includes(key)).length} stickers
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={{ marginLeft: 35, marginTop: 20 }}>
                                    <MoreVertIcon className={classes.refreshButton} />
                                </div> */}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 75 }}>
                                <div className={classes.tableHeaderBottom}>
                                    <Tabs
                                        value={modelYear}
                                        onChange={handleModelYear}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs"
                                        style={{ position: 'relative', marginTop: 25, zindex: -1 }}
                                    >
                                        {props.modelYears && props.modelYears.length > 0 && props.modelYears.sort().map(item =>
                                            <Tab value={item} label={item}
                                                sx={{
                                                    '&.Mui-selected': {
                                                        backgroundColor: 'white',
                                                        height: 37,
                                                        // borderRadius: 5,
                                                        //marginLeft: -1,
                                                        border: 'none',
                                                        //marginTop: 2
                                                    },
                                                    '&:not(.Mui-selected)': {
                                                        border: 'none',
                                                    },
                                                }}
                                            />
                                        )}
                                    </Tabs>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 44, gap: 4 }}>
                                    <div className={classes.filterBox} >
                                        <Typography variant='h4' color='black' style={{ marginRight: 12 }}>Filter</Typography>

                                        <div className={classes.filterContainer}>
                                            <div className={classes.filterItem}>
                                                <Typography variant='h4' color='grey'>Model Selection</Typography>
                                                <IconButton onClick={() => handleClick('model')}>
                                                    {anchorEl ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            </div>
                                            {anchorEl &&
                                                <NestedDropdown2 head='Model Selection' id={'model'} data={props.modelData} expand={expanded} onSubmit={handleCheckedData}
                                                    prevalue={selectedModels} selected={selectedModelItems} type='model_filter' />}
                                        </div>
                                        <div className={classes.filterContainer}>
                                            <div className={classes.filterItem}>
                                                <Typography variant='h4' color='grey'>Location Selection</Typography>
                                                <IconButton onClick={() => handleClick('location')}>
                                                    {anchorloc ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton>
                                            </div>
                                            {anchorloc &&
                                                <NestedDropdown2 head='Location Selection' id={'locations'} data={props.locations} expand={expanded} onSubmit={handleCheckedLoc}
                                                    prevalue={selectedLoc} selected={selectedLocItems} type='loc_filter' />
                                            }
                                        </div>

                                    </div>
                                    <div className={classes.filterContainer} style={{ marginTop: 19 }}>
                                        <div className={`custom-container`}>
                                            <GroupsIcon />
                                            <Typography variant='h4' color='grey'>Custom Group</Typography>
                                            <IconButton onClick={() => handleClick('custom')}>{customEn ? <ExpandLess /> : <ExpandMore />}</IconButton>

                                        </div>
                                        {customEn &&
                                            <div>
                                                <CustomGroups customGroup={props.customGroup} productLine={props.productLine} onChange={handleCustomData} customId={customId} />
                                            </div>
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {(props.page != 'grid-menu' || prefillId) &&
                            <div className={classes.gridContainer}>
                                <PromoRegion country={props.country} modelYear={modelYear} definedData={props.definedData} modelData={props.modelData}
                                    gridFilter={props.gridFilter} pageFilter={gridPageFilter} definedSticker={definedSticker} handleDefine={props.handleDefine}
                                    activeSticker={activeSticker} colorPicker={props.colorPicker} locationData={props.locations}
                                    handleChangeSticker={handleChangeSticker} handleOnDelete={handleOnRemove} stickers={props.stickers}
                                />
                            </div>}
                    </>
                }
            </div >
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        // getConfigData: () => dispatch(getBudgetingConfig()),
        getFilters: () => dispatch(getMaterialFilters()),
        getCustomGroupById: (id) => dispatch(getCustomGroups(id))
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
        loader: state.promotionData.oncostingLoad,
        costingData: state.promotionData.costingSimulationResult,
        modelData: state.promotionData.modelFilters,
        loading: state.promotionData.loading,
        customDetails: state.promotionData.customGroupList
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GridMain);