import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Card,
    IconButton,
    Select,
    MenuItem,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getFilterNameList, getIntegrationSetupData, getDefaultValues, triggerIntegration, getExternalAccuralPostingData, getExternalPaymentPostingData } from '../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { DropdownArray, TextInputWithLabel } from '../../components/Inputs';
import ClearIcon from '@mui/icons-material/Clear';
import { useHistory } from "react-router-dom";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ExternalAccuralPostingForm, ExternalPaymentPostingForm, TlpForm } from '../FinancialPostings/containers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        height: 40
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 13,
        height: 36
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
}));

const OutboundPostingsIntegrations = props => {
    const afRef = useRef(null);
    const classes = useStyles();
    const history = useHistory();
    const [userStore, setUserStore] = React.useState([]);
    const [integration, setIntegration] = React.useState([]);
    const [integration1, setIntegration1] = React.useState([]);
    const [batchJobName, setBatchJobName] = React.useState('');
    const [batchJobNameID, setBatchJobNameID] = React.useState('');
    const [filterValue, setFiltervalue] = React.useState([]);
    const [filterValue2, setFiltervalue2] = React.useState([]);
    const [filterName, setFilterName] = React.useState([]);
    const [filterNameArray, setFilterNameArray] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [activityArray, setActivityArray] = React.useState([]);
    const [isCOPA, setisCOPA] = React.useState(false);
    const [isSAPCOPA, setIsSAPCOPA] = React.useState(false);
    const [isPaymentPosting, setisPaymentPosting] = React.useState(false);
    const [isBillingPosting, setIsBillingPosting] = React.useState(false);
    const [isAccrualPosting, setIsAccrualPosting] = React.useState(false);
    const [copadata, setcopadata] = React.useState([]);
    const [paymentData, setpaymnetData] = React.useState();
    const [accrualData, setAccrualData] = React.useState([]);
    const [typeAPI, setTypeAPI] = React.useState('')

    useEffect(() => {
        props.getIntegrationSetupData(1, 0);
        // props.onLoadingDefault(sessionStorage.getItem('application'));
        // props.onLoadingDefault("Customer Rebate");
    }, []);
    useEffect(() => {
        if (props.filterNameList) {
            setFilterNameArray(props.filterNameList);
        }
    }, [props.filterNameList]);
    useEffect(() => {
        if (props.integrationSetupData && props.integrationSetupData.records.length > 0) {
            var newArray = []
            var newArray1 = []
            var newActivityArray = []
            props.integrationSetupData.records
                .filter(e => ["Postings - Material Level", "External Accrual", "External Payment", "COPA", "Postings - Standard Billing Line Level"].includes(e.activity))
                .map((item, index) => {
                    newArray.push(item.name)
                    newArray1.push(item.name + '(' + item.ID + ')')
                    newActivityArray.push(item.ID + '(' + item.activity)
                })
            setIntegration(newArray)
            setIntegration1(newArray1)
            setActivityArray(newActivityArray)
        }
    }, [props.integrationSetupData])
    function handleClear() {
        setAddMember(1);
    }
    function handleOnSubmit() {
        var date = [];
        filterValue.map((item, index) => {
            date.push(filterValue[index].concat("To", filterValue2[index]))
        })
        var data = {
            // "integration_id": parseInt(batchJobName.split('(').pop()),
            "integration_id": parseInt(batchJobNameID),
            "integration_filters": filterName,
            "integration_filter_values": date,
        }
        if (typeAPI === 'External Accrual') {
            props.onSubmit1(accrualData.filters, batchJobNameID)
        }
        else if (typeAPI === 'External Payment') {
            props.onSubmit2(paymentData.filters, batchJobNameID)
        }
        else {
            // props.onSubmit(batchJobNameID, isCOPA || isSAPCOPA ? copadata.filters : (isPaymentPosting ? paymentData.filters : (isAccrualPosting ? accrualData.filters : data)), typeAPI)
            props.onSubmit(batchJobNameID, isCOPA || isSAPCOPA ? copadata.filters : (isBillingPosting ? copadata.filters : (isPaymentPosting ? paymentData.filters : (isAccrualPosting ? accrualData.filters : data))), typeAPI);

            // console.log(batchJobNameID, isCOPA || isSAPCOPA ? copadata.filters : (isPaymentPosting ? paymentData.filters : (isAccrualPosting ? accrualData.filters : data)), typeAPI)
        }

    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    function handleBatchJobName(newValue) {
        setBatchJobName(newValue)
        const match = integration1.find(item => item.startsWith(newValue + "("));
        if (match) {
            const numericValue = match.match(/\((\d+)\)/)[1];
            setBatchJobNameID(numericValue);
            // using the BacthJobNameID to get the activity
            var data = numericValue;
            const activity = activityArray.find(item => item.startsWith(data + "("));
            const [numeric, type] = activity.split("(")
            setTypeAPI(type)
            // if activity Contains COPA then set isCOPA to true
            if (activity.includes("COPA")) {
                setisCOPA(true);
                setisPaymentPosting(false);
                setIsAccrualPosting(false);
                setIsSAPCOPA(false);
                setIsBillingPosting(false);
            }
            else if (activity.includes("Postings - Material Level")) {
                setIsSAPCOPA(true);
                setisPaymentPosting(false);
                setIsAccrualPosting(false);
                setisCOPA(false);
                setIsBillingPosting(false);
            }
            else if (activity.includes("External Payment")) {
                setisCOPA(false);
                setisPaymentPosting(true);
                setIsAccrualPosting(false);
                setIsSAPCOPA(false);
                setIsBillingPosting(false);
            }
            else if (activity.includes("External Accrual")) {
                setisCOPA(false);
                setisPaymentPosting(false);
                setIsAccrualPosting(true);
                setIsSAPCOPA(false);
                setIsBillingPosting(false);
            }
            else if (activity.includes("Postings - Standard Billing Line Level")) {
                setisCOPA(false);
                setisPaymentPosting(false);
                setIsAccrualPosting(false);
                setIsSAPCOPA(false);
                setIsBillingPosting(true);
            }

            else {
                setisCOPA(false);
                setisPaymentPosting(false);
                setIsAccrualPosting(false);
                setIsSAPCOPA(false);
                setIsBillingPosting(false);
            }
        }
    }
    function handleFilterName(newValue, index) {
        filterName[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        filterValue[index] = newValue;
    }
    function handleFilterValue2(newValue, index) {
        filterValue2[index] = newValue;
    }
    function handleOnAdd() {
        filterName[addMember] = ''
        filterValue[addMember] = ''
        filterValue2[addMember] = ''
        setAddMember(addMember + 1)
    }
    const handleDeleteLocally = (e, index) => {
        setFilterName(item => item.filter((item, i) => i !== index));
        setFiltervalue(item => item.filter((item, i) => i != index));
        setFiltervalue2(item => item.filter((item, i) => i != index));
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
    }

    return (
        <div className={classes.root}>
            <div className={classes.bodyContainer} >
                <div style={{
                    marginBottom: batchJobName ? '-5rem' : '0rem'
                }}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingBottom: 39 }}
                            >   <div className={classes.selectRoot}>
                                    <Typography variant="h1" color='primary'>Outbound Postings Integrations</Typography>
                                    <Select
                                        onChange={(e) => handleBatchJobName(e.target.value)}
                                        value={batchJobName}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >   <MenuItem value="">
                                            {props.placeholder}
                                        </MenuItem>
                                        {integration.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} name={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                        </Grid>
                        {!isCOPA && !isPaymentPosting && !isAccrualPosting && !isSAPCOPA && !isBillingPosting
                            && <form
                                autoComplete="off"
                                noValidate
                            >
                                <div className={classes.container}>
                                    <Grid container spacing={2} >
                                        {(() => {
                                            let td = [];
                                            for (let i = 0; i < addMember; i++) {
                                                td.push(<>
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >   <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Filter Name'}
                                                        placeholder={'Please select'}
                                                        twoline='true'
                                                        onChange={(e) => handleFilterName(e, i)}
                                                        data={filterNameArray}
                                                        prevalue={'init'} />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <TextInputWithLabel classes={{ root: classes.formLabel }}
                                                            heading={i == 0 && 'Start Date and Time'}
                                                            twoline='true'
                                                            onChange={(e) => handleFilterValue(e, i)}
                                                            data={filterValue}
                                                            type="datetime-local"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <TextInputWithLabel classes={{ root: classes.formLabel }}
                                                            heading={i == 0 && 'End Date and Time'}
                                                            type="datetime-local"
                                                            twoline='true'
                                                            onChange={(e) => handleFilterValue2(e, i)}
                                                            data={filterValue2}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={1}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                        style={{ paddingTop: 20, marginTop: 15 }}
                                                    >
                                                        <IconButton
                                                            style={{ marginTop: i == 0 ? 32 : 10 }}
                                                            onClick={(e) => handleDeleteLocally(e, i)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <ClearIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 32 : 10 }}
                                                            onClick={handleOnAdd}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <AddBoxOutlinedIcon />
                                                        </IconButton>

                                                    </Grid>
                                                </>)
                                            }
                                            return td;
                                        })()}

                                    </Grid>
                                </div>
                            </form>
                        }
                        {
                            isCOPA &&
                            <Grid >
                                <TlpForm application_type="Customer Rebate" button_hidden="true" creaded_on={true} setFormat3={setcopadata} />
                            </Grid>
                        }
                        {
                            isSAPCOPA &&

                            <Grid >
                                <TlpForm heading={'Postings - Material Level'} creaded_on={false} application_type="Customer Rebate" button_hidden="true" setFormat3={setcopadata} />
                            </Grid>
                        }
                        {
                            isBillingPosting &&

                            <Grid >
                                <TlpForm heading={'Postings - Standard Billing Line Level'} creaded_on={false} application_type="Customer Rebate" button_hidden="true" setFormat3={setcopadata} />
                            </Grid>
                        }
                        {
                            isPaymentPosting &&
                            <Grid style={{ marginTop: '1.2rem' }} >
                                <ExternalPaymentPostingForm application_type="Customer Rebate" button_hidden="true" setPayload1={setpaymnetData} />
                            </Grid>
                        }
                        {
                            isAccrualPosting &&
                            <Grid style={{ marginTop: '1.2rem' }} >
                                <ExternalAccuralPostingForm application_type="Customer Rebate" button_hidden="true" setNewPayload={setAccrualData} />
                            </Grid>
                        }
                    </Card >
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        integrationSetupData: state.customerData.integrationSetupData,
        filterNameList: state.customerData.filterNameList,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data, type) => dispatch(triggerIntegration(id, data, type)),
        onSubmit1: (data, id) => dispatch(getExternalAccuralPostingData(data, id)),
        onSubmit2: (data, id) => dispatch(getExternalPaymentPostingData(data, id)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        getFilterNameList: (activity, date) => dispatch(getFilterNameList(activity, date)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OutboundPostingsIntegrations);