import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Card } from '@mui/material';
import { addCustomerMasterXRefUser, getDefaultValuesCustomer, updateIncomingClaimData } from '../../../../redux/actions';
import { editIncomingClaim } from '../../../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import clsx from 'clsx';
import 'react-edit-text/dist/index.css';
import PopupContainer from '../PopupContainer';

const useStyles = makeStyles(theme => ({
    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
    rowData: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px'
    }
}));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const GeneralData = props => {
    const classes = useStyles();
    const [dataRows, setDataRows] = React.useState([]);
    const { className } = props;
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.editIncomingClaim(appId);

    }, []);
    const theme = useTheme();
    useEffect(() => {
        var tempRows = [];
        if (props.incomingClaimDetailData && props.incomingClaimDetailData.length > 0)
            props.incomingClaimDetailData
                .filter(e => e.claim_status == 'Adjusted' || e.claim_status == 'Approved')
                .sort((a, b) => a.unique_identifier > b.unique_identifier ? 1 : -1)
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        unique_identifier: e.unique_identifier,
                        claim_amount: e.claim_amount,
                        calculated_amount: e.calculated_amount,
                        approved_amount: e.approved_amount,
                        end_customer_id: e.end_customer_id,
                        material_id_number: e.material_id_number,
                        contract_number: e.contract_number,
                        claim_status: e.claim_status,
                        claim_action: e.claim_action,
                        claim_error: e.claim_error,
                        material_number: e.material_number,
                        pricing_date: e.pricing_date,
                        adjustment_code: e.adjustment_code,
                        material_description: e.material_description,
                        channel_partner_end_customer_name: e.channel_partner_end_customer_name,
                    });
                })
        setDataRows(tempRows);
    }, [props.incomingClaimDetailData]);
    const [currentViewID, setCurrentViewID] = React.useState(0);
    const handlePopupClick = (rowData) => {
        setDialogOpen(true);
        setCurrentViewID(rowData.unique_identifier);
    }

    const columns = [
        {
            field: 'unique_identifier',
            title: 'Unique Identifier',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.unique_identifier ? <div style={{
                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
            }} onClick={() => handlePopupClick(rowData)}>{rowData.unique_identifier}</div> : <div style={{ color: 'white' }}>-</div>

        },
        {
            field: 'end_customer_id',
            title: 'End Customer ID',
            render: rowData => rowData.end_customer_id ? rowData.end_customer_id : <div style={{ color: 'white' }}>-</div>,
            type: 'string',
            editable: 'never',
        },
        {
            field: 'channel_partner_end_customer_name',
            title: 'End Customer Name',
            render: rowData => rowData.channel_partner_end_customer_name ? rowData.channel_partner_end_customer_name : <div style={{ color: 'white' }}>-</div>,
            type: 'string',
            editable: 'never',
        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.material_number ? rowData.material_number : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'material_description',
            title: 'Material Description',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.material_description ? rowData.material_description : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'number',
            editable: 'never',
            render: rowData => rowData.contract_number ? rowData.contract_number : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'claim_amount',
            title: 'Claim Amount',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.claim_amount ? rowData.claim_amount.toFixed(2) : ''
        },
        {
            field: 'calculated_amount',
            title: 'Calculated Amount',
            type: 'number',
            editable: 'never',
            render: rowData => rowData.calculated_amount ? rowData.calculated_amount.toFixed(2) : ''
        },
        {
            field: 'approved_amount',
            title: 'Approved Amount',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.approved_amount ? rowData.approved_amount.toFixed(2) : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'adjustment_code',
            title: 'Adjustment Code',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.adjustment_code ? rowData.adjustment_code : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'claim_status',
            title: 'Claim Status',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.claim_status ? rowData.claim_status : <div style={{ color: 'white' }}>-</div>,
        },
    ];
    const [dialogOpen, setDialogOpen] = React.useState(false)

    const handleEditSingleCell = (item) => {
        console.log(item);
        var temp = {};
        props.incomingClaimDetailData.map((item2) => {
            if (item2.ID === item.ID) {
                temp = item2
            }

        })
        props.updateIncomingClaimData(temp);
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider className={clsx(classes.rootMain, className)}>
                <PopupContainer open={dialogOpen} onClose={(value) => setDialogOpen(value)} incomingClaimID={currentViewID} />

                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div>
                                <MaterialTable
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    columns={columns}
                                    data={dataRows}
                                    style={{ marginTop: -60 }}

                                    options={{
                                        search: false,
                                        filtering: true,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                                    }}
                                    cellEditable={{
                                        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                            return new Promise((resolve, reject) => {
                                                var currentObject = props.incomingClaimDetailData.find(item => item.ID === rowData.id);
                                                currentObject[columnDef.field] = newValue;
                                                handleEditSingleCell(currentObject)
                                                setTimeout(resolve, 1000);
                                            });
                                        },
                                        onCellAddApproved: (newValue, oldValue, rowData, columnDef) => {
                                            return new Promise((resolve, reject) => {
                                                var currentObject = props.incomingClaimDetailData.find(item => item.ID === rowData.id);
                                                currentObject[columnDef.field] = newValue;
                                                console.log(currentObject[columnDef.field])
                                                props.updateIncomingClaimData(rowData.id, currentObject)
                                                setTimeout(resolve, 1000);
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addCustomerMasterXRefUser(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomer()),
        editIncomingClaim: (id) => dispatch(editIncomingClaim(id)),
        updateIncomingClaimData: (formData) => dispatch(updateIncomingClaimData(formData)),
    }
}

const mapStateToProps = state => {
    return {
        incomingClaimDetailData: state.initialData.incomingClaimDetailData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);