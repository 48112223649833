import React from 'react';
import { connect } from 'react-redux';
import { ProfitOptBatchJobSetupTable } from './components';

const ProfitOptBatchJobSetup = () => {
    return (
        <div>
            <ProfitOptBatchJobSetupTable />
        </div >
    );
};
export default connect(null,null)(ProfitOptBatchJobSetup);