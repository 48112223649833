import React from 'react';
import { makeStyles } from "@mui/styles";
import { history } from '../../../../../../components/Helpers';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    logo: {
        width: '65%',
        paddingTop: 10,
        marginBottom: -5
    }
}));

const Logo = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <div
            {...rest}
            className={classes.root}
        >
            <img src={require('../../../../../../library/images/new_logo.png')} className={classes.logo} alt=''
            onClick={()=>history.push('/dashboard')}  style={{ cursor: 'pointer'}}/>
        </div>
    );
    
};

export default Logo;
