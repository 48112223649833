import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Typography, IconButton, Paper, Button,
    AccordionActions
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        zIndex: 1000,
        width: 300
        //position: absolute;
        //display: 'flex',
        // flexDirection: 'column'
        //backgroundColor: 'transparent'
    },
    dropdownContainer: {
        maxHeight: '350px', // Set maximum height
        overflowY: 'auto', // Enable vertical scrolling
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,
        backgroundColor: 'white'
    },
    accordionMenu: {
        padding: 10,
        maxHeight: 30,
        margin: 0,
        // borderBottom: "0.8px solid #E2E2E2",
    },

    accordionHead: {
        padding: 0,
        minHeight: 30,
        margin: " 0px !important",
        width: 'fit-content'
        // backgroundColor: 'transparent'
        //  borderBottom: "0.8px solid #E2E2E2",
    },
    accordionAction: {
        backgroundColor: 'white',
        border: "0.8px solid #E2E2E2",
        margin: '0px 5px'
    },
    accordionRoot: {
        backgroundColor: 'transparent',
        margin: 0,

    },
    muiExpand: {
        minHeight: "30px !important",
        margin: '0px !important'
    }
}));

const SingleMultiLevelList = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState([]);
    const [checkedData, setCheckedData] = useState('');

    useEffect(() => {
        setExpanded(props.expand)
    }, [props.expand])
    useEffect(() => {
        setCheckedData(props.prevalue ? props.prevalue : '')
    }, [props.prevalue])

    const handleCheckboxChange = (item) => (event) => {
        event.stopPropagation(); // Stop event propagation
        setCheckedData(item.category)
        setExpanded([])
        if (event.target.checked)
            props.onSubmit(item.category, item.id)
        else
            props.onSubmit()


    };


    const handleAccordionChange = (panel, type) => (event, isExpanded) => {
        if (type == 'menu-head')
            setExpanded(isExpanded ? [...expanded, panel] : []);
        else
            setExpanded(isExpanded ? [...expanded, panel + "-" + type] : expanded.filter(item => item != panel + "-" + type));
    };

    const renderNestedItems = (items) => {
        return items && items.map((item, index) => (
            <div key={index}>
                {item.children && item.children.length > 0 ? (
                    <Accordion
                        expanded={expanded.includes(item.category + "-" + item.type)}
                        onChange={handleAccordionChange(item.category, item.type)}
                        style={{
                            margin: 0, borderBottom: (item.type == 'product_line')
                                && "0.8px solid #E2E2E2"
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            className={classes.accordionMenu}
                            style={{ padding: item.type == 'material_group3' || item.type == 'state' ? 20 : 10 }}
                        >
                            <Typography variant='h4' color='black'
                            //color={selectedChild.includes(Object.values(item.relationship).join("_").toString()) ? 'black' : 'grey'}
                            >
                                {/* {(item.type !== 'product_line') && <Checkbox
                                    // checked={
                                    //     selectedChild.includes(Object.values(item.relationship).join("_").toString()) ? true : false
                                    // }
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={handleCheckboxChange(
                                        item
                                    )}
                                />} */}
                                {item.category}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}
                        >
                            {renderNestedItems(item.children)}
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <div style={{ display: 'flex', paddingLeft: 5 }}>
                        <Typography variant='h4' color='black'>
                            {' '}
                            <Checkbox
                                checked={checkedData == item.id ? true : false
                                    //     //selectedChild && selectedChild.length > 0 && selectedChild.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) ? true : false
                                }
                                onChange={handleCheckboxChange(item)}
                            />
                            {item.category}
                        </Typography>
                    </div>
                )}
            </div>
        ));
    };


    const handleCancel = (event) => {
        event.stopPropagation();
        setExpanded([])
        props.onSubmit(null, null, 'cancel')
        // Handle cancel button click
    };

    return (
        <div className={classes.root}>
            {expanded && expanded.length > 0 &&
                <Accordion
                    id={props.id}
                    expanded={expanded.includes(props.type)}
                    // onChange={handleAccordionChange('model_filter', 'menu-head')}
                    className={classes.accordionRoot}
                >
                    <AccordionSummary
                        //expandIcon={<ExpandMore />}
                        className={classes.accordionHead}
                        classes={{
                            expanded: classes.muiExpand, // Override styles when Accordion is expanded
                        }}
                        style={{ display: 'none' }}
                    >
                        <Typography variant='h4' color='grey'>
                            {props.head}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 5 }}>
                        <div className={classes.dropdownContainer}>
                            {renderNestedItems(props.data)}
                        </div>
                    </AccordionDetails>
                </Accordion>
            }
        </div>
    );
};

export default SingleMultiLevelList;
