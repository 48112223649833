import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from '@mui/material';
import { Edit, DeleteForever } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getContractXRefData, getContractXRefDetails, deleteCustomerMasterData, } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { ContractXREFToolbar } from './';
import { useHistory } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const ContractXREFUserTable = props => {
    useEffect(() => {
        props.getCustomerMasterXRef(1, 10);
    }, []);
    useEffect(() => {
        if (props.contractXRefData && props.contractXRefData.records && props.contractXRefData.records.length > 0) {
            setDataRows(props.contractXRefData.records);
        }
    }, [props.contractXRefData]);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);

    // const deleteData = (id) => {
    //     props.deleteCustomerMasterData(id, 'ContractXREF')
    // }
    const handleOpenDialog = (id) => {
        setSelectedId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedId(null);
    };

    const handleDelete = () => {
        if (selectedId) {
            props.deleteCustomerMasterData(selectedId, 'ContractXREF');
            handleCloseDialog();
        }
    };

    function editCustomer(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/contract-xref/edit-contract/' + id,
            id: id
        });
    }
    function viewMembership(id) {
        props.getContractXRefDetails(id);
        history.push({
            pathname: '/contract-xref/view-contract/' + id,
            id: id
        });
    }

    const columns = [
        {
            field: 'channel_partner_id',
            title: 'Channel Partner ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.channel_partner_id}</a>
        },
        {
            field: 'channel_partner_contract_id',
            title: 'Channel Partner Contract ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.channel_partner_contract_id}</a>
        },
        {
            field: 'ima_contract_id',
            title: 'IMA Contract ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.ima_contract_id}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editCustomer(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleOpenDialog(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>

        },
    ];

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Contract XREF </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract_xref-' + sessionStorage.getItem('application')) &&
                        <ContractXREFToolbar />}
                </div>
                {props.contractXRefData && props.contractXRefData.records && props.contractXRefData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} variant='contained' color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        contractXRefData: state.customerData.contractXRefData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomerMasterXRef: (pagination, limit) => dispatch(getContractXRefData(pagination, limit, '')),
        getContractXRefDetails: (id) => dispatch(getContractXRefDetails(id)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'ContractXREF')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractXREFUserTable);