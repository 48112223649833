import React, { useEffect, forwardRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    useMediaQuery, Button, IconButton, Fab, Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload, SearchSharp, Edit } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import { deleteCoverageMappingData, getCoverageMappingData } from '../../redux/actions/Promotion/AuthAction';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const CoverageCostMapping = props => {

    const { className } = props;
    const theme = useTheme();
    const history = useHistory();
    const page = history.location.state;
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    useEffect(() => {
        props.getCoverageCostData()
    }, []);

    const columns = [
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.country}</a>
        },
        {
            field: 'state',
            title: 'State',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.state}</a>
        },
        {
            field: 'coverage_type',
            title: 'Coverage Type',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.coverage_type}</a>
        },
        {
            field: 'promotion_type',
            title: 'Promotion Type',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.promotion_type}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    <>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editCoverageMapping(rowData.id)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => props.deleteCoverageMapping(rowData.id)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    </>
                </div>
        },
    ];
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        var tempRows = [];
        if (props.coverageMappingData && props.coverageMappingData.records && props.coverageMappingData.records.length > 0) {
            props.coverageMappingData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        country: e.country,
                        coverage_type: e.coverage_type,
                        state: e.state,
                        promotion_type: e.promotion_type,
                    });
                })
            setDataRows(tempRows);
        }
    }, [props.coverageMappingData]);


    const addContract = () => {
        localStorage.removeItem('currentAddContractID');
        sessionStorage.setItem('mode', 'add');
    }

    function editCoverageMapping(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/coverage-cost-mapping/edit-coverage-cost-mapping/' + id,
            state: 'editCoverageMapping',
            id: id
        });
        localStorage.setItem('coverageMappingEditId', id);
    }


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Coverage Cost Mapping </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            component={CustomRouterLink}
                            to='/coverage-cost-mapping/excel-upload'
                            onClick={addContract}
                        >
                            <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                        </Fab>
                        {/* <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            component={CustomRouterLink}
                            to='/coverage-cost-mapping/search'
                        >
                            <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                        </Fab> */}
                    </div>
                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div className={classes.noDataContainer}>
                        Display Coverage Cost Mapping by clicking on Search Icon.
                    </div>
                }

            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        // CoverageMappingData: state.promotionData.promotionsSearchData,
        coverageMappingData: state.promotionData.coverageMappingListData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteCoverageMapping: (id) => dispatch(deleteCoverageMappingData(id)),
        getCoverageCostData: () => dispatch(getCoverageMappingData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverageCostMapping);