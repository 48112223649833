import React, { useState, useEffect, useRef, forwardRef } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@mui/styles";
import { connect } from "react-redux";
import {
  Typography, Breadcrumbs, Fab
} from '@mui/material';
import { submitExcel } from '../../redux/actions';
import Moment from 'moment';
import { Link } from 'react-router-dom';

import 'jsuites';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: 29,
    marginTop: 16,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: 'none',
    border: 0,
    fontSize: 16,
    paddingRight: 10
  },
  spdsht: { width: '100%', overflow: 'scroll', writingMode: 'vertical-rl' },
  header: {
  }
}));

const SpreadsheetCreatorListPrice = (props) => {
  const { className } = props;
  const jRef = useRef(null);
  const options = {
    data: [[]],
    minDimensions: [12, 100],
    columns: [
      {
        title: 'Material Number',
        width: '130',
        options: { style: 'font-size: 12px' }
      },
      {
        title: 'Material Description',
        width: '160'
      },
      {
        title: 'Competitor Name',
        width: '140'
      },
      {
        title: 'Competitor Product',
        width: '150'
      },
      {
        title: 'Customer Group',
        width: '130'
      },
      {
        title: 'Customer Chain',
        width: '130'
      },
      {
        title: 'Customer Number',
        width: '140'
      },
      {
        title: 'Territory Name',
        width: '120'
      },
      {
        title: 'Competitor List Price',
        width: '160'
      },
      {
        title: 'Start Date',
        width: '80'
      },
      {
        title: 'End Date',
        width: '80'
      },
      {
        title: 'Weightage',
        width: '90'
      }
    ],
    rowResize: true,
    search: true,
    columnSorting: true,
    filters: true,
    allowComments: true,
    columnDrag: true,
    // tableOverflow: true,
    tableWidth: "100%",
  };

  const classes = useStyles();
  const handleSubmitExcel = () => {
    var dataFromTable = jRef.current.jexcel.getData();
    var tempArr = [];
    var tempObj = {};
    dataFromTable
      .filter(e => e)
      .map((item, i) => {
        if (item.filter(e => e).length > 0) {
          tempObj['material_number'] = item[0] && item[0] ? item[0] : ""
          tempObj['material_description'] = item[1] && item[1] ? item[1] : ""
          tempObj['competitor_name'] = item[2] && item[2] ? item[2] : ""
          tempObj['competitor_product'] = item[3] && item[3] ? item[3] : ""
          tempObj['customer_group'] = item[4] && item[4] ? item[4] : ""
          tempObj['customer_chain'] = item[5] && item[5] ? item[5] : ""
          tempObj['customer_number'] = item[6] && item[6] ? item[6] : ""
          tempObj['territory_name'] = item[7] && item[7] ? item[7] : ""
          tempObj['competitor_list_price'] = item[8] && item[8] ? parseFloat(parseFloat(item[8]).toFixed(2)) : 0.0
          tempObj['start_date'] = item[9] ? Moment.utc(item[9]).format() : '0001-01-01T00:00:00Z'
          tempObj['end_date'] = item[10] ? Moment.utc(item[10]).format() : '0001-01-01T00:00:00Z'
          tempObj['weightage'] = item[11] && item[11] ? parseFloat(parseFloat(item[11]).toFixed(2)) : 0.0
          tempArr[i] = tempObj;
          tempObj = {};
        }
      })
      console.log(tempArr)
    props.submitExcel(tempArr);
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link variant='h4' classes={{
          root: classes.link
        }}
          to='/list-price'
        >
          List Price Data
        </Link>
        <Typography color="textPrimary" variant='h4'>&ensp;Create List Price Data</Typography>
      </Breadcrumbs>
      <div style={{ width: '100%', overflow: 'scroll' }}>
        {/* <div ref={jRef} /> */}
      </div>
      <Fab variant="extended" color="primary" onClick={handleSubmitExcel} style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
      }}>
        Create
      </Fab>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExcel: (data) => dispatch(submitExcel(data,'List Price'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpreadsheetCreatorListPrice);