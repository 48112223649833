import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, Button, IconButton, TableCell,
    Dialog, DialogActions, Tooltip, Paper
} from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormatListBulletedRounded, GraphicEqRounded } from '@mui/icons-material';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from "moment";
import { InvertColors, Details } from '@mui/icons-material';
import { createPriceListData, getAllDistinctPriceListData, getDrivers } from '../../../redux/actions';
import secureLocalStorage from 'react-secure-storage';
import { Table } from '../../../components/Inputs';
import { WaterFall, Funnel } from '../../Pricing/PriceSimulation(CPQ)/Graphs';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        // fontSize: theme.typography.h4.fontSize,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 10,
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    togglebtn: {
        marginRight: 5,
        //border: '1px solid',
        //borderColor: theme.palette.primary.main
    }
}));

const GrossToNetResult = props => {
    useEffect(() => {
        props.getConfigData(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [columns, setColumns] = React.useState([]);
    const [typeOfChart, setTypeOfChart] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [graphData, setGraphData] = React.useState({});
    const [view, setView] = React.useState('');
    const [heading, setHeading] = React.useState([])

    const handleAnalytics = (record, type) => {
        setTypeOfChart(type);
        setOpen(true);
        var data = []
        props.headings && props.headings
            .filter(item => (props.attribute.includes(item) == false) && item)
            .map(item => {
                data.push({ 'x': item, 'y': record[item] })
            })
        console.log(data)
        setGraphData(data);
    }
    useEffect(() => {
        var temp = []
        if (props.data && props.data.length > 0 && props.headings) {
            props.headings
                .map(item => {
                    temp.push({
                        field: item,
                        title: <div style={{ textTransform: 'capitalize' }}>{item.replace(/_/g, ' ')}</div>,
                        editable: 'never',
                        render: (rowData) => (<div>{(item == 'start_date' || item == 'end_date') ?
                            rowData[item] ? Moment.utc(rowData[item]).format('MM/DD/YYYY') : ''
                            : typeof (rowData[item]) == 'number' ? parseFloat(rowData[item]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : rowData[item]
                        }</div>)
                    })
                })
            temp.push(
                {
                    field: 'Analytics',
                    title: 'Analytics',
                    editable: 'never',
                    render: (rowData) => (
                        < TableCell style={{ display: 'flex' }} sticky="true">
                            <Tooltip
                                title="Waterfall Chart"
                            >
                                <IconButton
                                    onClick={() => handleAnalytics(rowData, 'waterfall')}
                                    classes={{ root: classes.IconButton }}
                                    size="large">
                                    <InvertColors color="disabled" style={{ fontSize: 20 }} />
                                </IconButton>
                            </Tooltip>
                            {props.type == 'driver_category' &&
                                <Tooltip
                                    title="Funnel Chart"
                                >
                                    <IconButton
                                        onClick={() => handleAnalytics(rowData, 'funnel')}
                                        classes={{ root: classes.IconButton }}
                                        size="large">
                                        <Details color="disabled" style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip>}
                        </TableCell >
                    )
                })
            setColumns(temp)
        }
    }, [props.data])
    useEffect(() => {
        if (props.view) {
            setView(props.view)
            if (props.view == 'chart' && props.data && props.data.length > 0) {
                var data = []
                props.headings && props.headings
                    .filter(item => (props.attribute.includes(item) == false) && item)
                    .map(item => {
                        data.push({ 'x': item, 'y': props.data && props.data[0][item] })
                    })
                setGraphData(data);
            }
        }
    }, [props.view, props.attribute, props.data])
    const handleChange = (event, nextView) => {
        setView(nextView);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={classes.root}>
                    <div className={classes.row}>
                        <ToggleButtonGroup
                            color="primary"
                            value={view}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            size={'medium'}

                        >
                            <Tooltip title="List">
                                <ToggleButton variant='outlined' value="list" className={classes.togglebtn}>
                                    <FormatListBulletedRounded />
                                </ToggleButton>
                            </Tooltip>
                            <Tooltip title="Chart">
                                <ToggleButton variant='outlined' value="chart" disabled={props.view == 'list' ? true : false} className={classes.togglebtn}>
                                    <GraphicEqRounded />
                                </ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                    </div>
                    <Dialog
                        classes={{ paper: classes.paperContainer }}
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                        fullWidth={true}
                        maxWidth={'xl'}
                        height='1000px'
                    >
                        {typeOfChart === 'waterfall' ?
                            <WaterFall dataSource={graphData} id='detailed-chart' name='Gross to Net Analysis' />
                            :
                            <Funnel dataSource={graphData} id='detailed-chart' />
                        }

                        <DialogActions>
                            <Button onClick={() => setOpen(false)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {props.data && props.data.length > 0 ?
                    <>
                        {view == 'list' &&
                            <Paper style={{ overflowX: 'auto', top: 30, height: '700px', paddingTop: 0 }}>
                                <Table columns={columns} dataRows={props.data.filter((item, index) => index != props.data.length - 1)} />
                            </Paper>
                        }
                        {view == 'chart' &&
                            <WaterFall dataSource={graphData} id='gross_net_chart'
                                name='Gross to Net Analysis' title='Gross to Net Analysis'
                                height='650px'
                            />
                        }
                    </>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        configData: state.profitOptimizationData.priceTypeData,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getDrivers(pagination, limit)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrossToNetResult);