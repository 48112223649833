import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  FormLabel,
  TextField,
  Checkbox,
  Button,
  Paper,
} from "@mui/material";
import { getContractsTableDataList } from "../../../redux/actions";
import { connect } from "react-redux";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    marginTop: 10,
  },
  error: {
    border: "1px solid red !important",
  },
  contractContiner: {
    padding: "15px 0px 18px",
  },
  dropdownAction: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: "1px solid #ddd",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  btn: {
    marginRight: 25,
    padding: "10px,15px",
  },
  autocompleteInputRoot: {
    "&.MuiAutocomplete-inputRoot": {
      padding: "4.5px !important", // Adjust as needed
    },
  },
  autocompleteInput: {
    padding: "6px",
  },
}));

const CustomerNumbrAC = forwardRef((props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const [contractNumbersList, setContractNumbersList] = React.useState([]);
  const [internalDescriptionArray, setInternalDescriptionArray] =
    React.useState([]);
  const [data, setData] = React.useState(props.defaultValue ? 0 : "");
  const [changedFlag, setChangedFlag] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [contractSelected, setContractSelected] = React.useState("");
  const [contractNumber, setContractNumber] = useState([]);
  const [label, setLabel] = React.useState(props.withOutLabel ? false : true);
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      internalDescriptionArray[contractNumbersList.indexOf(option)] + option,
  });
  useEffect(() => {
    if (props.page && props.app) props.contractsList(props.page, props.app);
    else props.contractsList();
  }, [props.page, props.app]);
  useEffect(() => {
    var contracts = [];
    var tempNameArray = [];
    if (props.contractData) {
      props.contractData.map((item) => {
        contracts.push(item.contract_number.toString());
        tempNameArray.push(item.internal_description);
      });
      setContractNumbersList(contracts);
      setInternalDescriptionArray(tempNameArray);
    }
  }, [props.contractData]);

  const handleContractNumber = (e, value, type) => {

    if (type == "single") {
      setContractSelected(value);
      props.onChange(value);
    } else {
      if (type == "clear") {
        setSelected([]);
        setContractNumber([]);
        props.onChange([]);
      } else if (type == "remove-option") {
        setSelected(value);
        setContractNumber(value);
        props.onChange(value);
      } else {
        let newSelected = value;
        if (value.length > 0) {
          setSelected(newSelected);
          // setContractNumber(newSelected.map(Number));
        } else {
          setSelected([]);
          // setContractNumber([]);
        }
      }
    }
  };
  const reset = () => {
    setData("");
  };
  useEffect(() => {
    setChangedFlag(0);
  }, []);
  useImperativeHandle(ref, () => {
    return {
      reset: reset,
      value: !changedFlag && props.prevalue ? props.prevalue : data,
    };
  });

  useEffect(() => {
    if (props.prevalue && props.multiple) {
      setSelected(props.prevalue);
      setContractNumber(props.prevalue);
    }
    else {
      if (props.prevalue)
        setContractSelected(props.prevalue)
    }
  }, [props.prevalue]);
  function onAdd() {
    setContractNumber(selected);
    props.onChange(selected);
  }
  function onCancel(type) {
    // if(type=='clear'){
    //     setSelected([])
    //     setContractNumber([])
    //   props.onChange([])
    // }
    // else{
    setSelected(contractNumber);
    props.onChange(contractNumber);
    // }
  }

  return (
    <div className={classes.contractContiner}>
      {label && (
        <FormLabel
          required={props.required ? props.required : false}
          style={{ fontSize: theme.typography.h3.fontSize }}
        >
          {" "}
          Contract Number{" "}
        </FormLabel>
      )}
      {props.multiple ? (
        <Autocomplete
          multiple
          limitTags={3}
          id="checkboxes-tags-demo"
          size="small"
          options={contractNumbersList}
          getOptionLabel={(option) => option}
          defaultValue={contractNumber}
          value={selected}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              style={{
                borderRadius: "0px !important",
                paddingBottom: "4px !important",
              }}
            />
          )}
          filterOptions={filterOptions}
          onClose={() => setSelected(contractNumber)}
          // onInputChange={(e,value,reason)=>handleContractNumber(e,value,reason)}
          // classes={{
          //   inputRoot: { borderRadius: "0px !important" },
          // }}
          classes={{
            inputRoot: classes.autocompleteInputRoot,
          }}
          InputProps={{
            classes: { input: classes.autocompleteInput },
            disableUnderline: true
          }}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                color="primary"
                checked={selected}
              />
              {internalDescriptionArray[contractNumbersList.indexOf(option)]
                ? internalDescriptionArray[contractNumbersList.indexOf(option)]
                : ""}{" "}
              - ({option})
            </li>
          )}
          style={{ width: "100%", marginBottom: 15, marginTop: 5 }}
          onChange={(event, newValue, reason) => {
            handleContractNumber(event, newValue, reason);
          }}
          PaperComponent={({ children }) => {
            return (
              <Paper>
                {children}
                <div className={classes.dropdownAction}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.btn}
                    onMouseDown={onCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    onMouseDown={onAdd}
                  >
                    Apply
                  </Button>
                </div>
              </Paper>
            );
          }}
        />
      ) : (
        <Autocomplete
          id="checkboxes-tags-demo"
          size="small"
          options={contractNumbersList}
          getOptionLabel={(option) => option}
          defaultValue={contractSelected}
          value={contractSelected}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              style={{
                borderRadius: "0px !important",
                paddingBottom: "4px !important",
              }}
            />
          )}
          filterOptions={filterOptions}
          classes={{
            inputRoot: { borderRadius: "0px !important" },
          }}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {internalDescriptionArray[contractNumbersList.indexOf(option)]
                ? internalDescriptionArray[contractNumbersList.indexOf(option)]
                : ""}{" "}
              - ({option})
            </li>
          )}
          style={{ width: "100%", marginBottom: 15, marginTop: 5 }}
          onChange={(event, newValue) => {
            handleContractNumber(event, newValue, "single");
          }}
        />
      )}
    </div>
  );
});

const mapDispatchToProps = (dispatch) => {
  return {
    contractsList: (page, app) =>
      dispatch(getContractsTableDataList(null, page, app)),
  };
};

const mapStateToProps = (state) => {
  return {
    contractData: state.addContractData.contractAllListData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNumbrAC);
