import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery,
    Breadcrumbs, Grid, Button, FormLabel, TextField, RadioGroup, FormControlLabel, Radio, Box
} from '@mui/material';
import { format } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { connect } from 'react-redux';
import { generateMassData } from '../../redux/actions';
import { RotateLoader, RingLoader } from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';
import Moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: '0px 10px 0px 10px',
        marginBottom: 20
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    button: {
        marginLeft: '32rem',
        marginTop: '-44px',
        marginBottom: '1rem'
    },
    spinner: {
        height: '80vh'
    },
    radioContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 0 10px 0',
    },
    radioLabel: {
        marginLeft: 4,
    },
}));

const GenerateMassData = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [billingDate, setBillingDate] = React.useState(null);
    const [noOfRecords, setNoOfRecords] = React.useState('');
    const [tableName, setTableName] = React.useState('');
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const handleRun = () => {
        var formattedBillingDate = billingDate
        ? Moment.utc(billingDate).format("YYYY-MM-DD")
        : "";
        let data = {
            'billing_date': formattedBillingDate,
            'no_of_records': parseInt(noOfRecords, 10),
            'table_name': tableName,
        };
        props.onSubmit(data);
        handleClear();
    };

    function handleClear() {
        setBillingDate(null);
        setNoOfRecords('');
        setTableName('');
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="primary" variant='h1'>Generate Mass Data</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container >
                    <Grid
                        item
                        md={12}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px", padding: '0.5rem', borderRadius: '5px' }}
                    >
                        <Grid
                            item
                            md={3}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <FormLabel required classes={{ root: classes.fontSetting }}>
                                Billing Date
                            </FormLabel>
                            <div>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        height: 36,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={billingDate}
                                                onChange={(newValue) => setBillingDate(newValue)}
                                                renderInput={(params) => <TextField {...params} />}
                                                format="YYYY/MM/DD"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ marginTop: '1rem' }}
                        >
                            <FormLabel required classes={{ root: classes.fontSetting }}>
                                Number of Records
                            </FormLabel>
                            <div>
                                <TextField
                                    type="number"
                                    value={noOfRecords}
                                    onChange={(e) => setNoOfRecords(e.target.value)}
                                    fullWidth
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ marginTop: '1rem' }}
                        >
                            <FormLabel required classes={{ root: classes.fontSetting }}>
                                Select Data Type
                            </FormLabel>
                            <RadioGroup
                                row
                                value={tableName}
                                onChange={(e) => setTableName(e.target.value)}
                            >
                                <Box className={classes.radioContainer}>
                                    <FormControlLabel
                                        value="direct_sales_data"
                                        control={<Radio color="primary" />}
                                        label="Direct Sales Data"
                                        classes={{ label: classes.radioLabel }}
                                    />
                                </Box>
                                <Box className={classes.radioContainer} style={{marginLeft:"20px"}}>
                                    <FormControlLabel
                                        value="staging_direct_sales_data"
                                        control={<Radio color="primary" />}
                                        label="Staging Direct Sales Data"
                                        classes={{ label: classes.radioLabel }}
                                    />
                                </Box>
                            </RadioGroup>
                        </Grid>

                        <Button disabled={!tableName || !billingDate || !noOfRecords ? true : false}
                            variant="contained" color="primary" className={classes.button} onClick={handleRun}>
                            {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                            }
                        </Button>
                    </Grid>
                </Grid>
            </div >
        </LoadingOverlay >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(generateMassData(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateMassData);
