import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Card,
    FormLabel,
    Select,
    MenuItem,
    IconButton, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { getMasterReportData, getAttributeListData, getCustomerMasterData } from '../../redux/actions';
import { connect } from 'react-redux';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextInputWithLabel } from '../../components/Inputs';
// import MomentUtils from '@date-io/moment';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import BeatLoader from "react-spinners/BeatLoader";
import momentt from 'moment-timezone';
import Moment from 'moment';
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
}));
const MasterReportSearch = props => {
    const classes = useStyles();
    const [secondaryCN, setSecondaryCN] = React.useState([]);
    const [topParentMN, setTopParentMN] = React.useState([]);
    const [customerName, setCustomerName] = React.useState([]);
    const [primaryCN, setPrimaryCN] = React.useState([]);
    const [actionCode, setActionCode] = React.useState([]);
    const [tertiaryCN, setTertiaryCN] = React.useState([]);
    const [quarterneyCN, setQuarterneyCN] = React.useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [actionEffectiveData, setActionEffectiveData] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue)
    }
    function handleCustomerNumberDelete(newValue) {
        var deletedCustomerNumber = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomerNumber)
    }
    function handleSecondaryCN(newValue) {
        setSecondaryCN(newValue)
    }
    function handleSecondaryCNDelete(newValue) {
        var deletedSecondaryCN = secondaryCN.filter(item => item !== newValue)
        setSecondaryCN(deletedSecondaryCN)
    }
    function handleCustomerName(newValue) {
        setCustomerName(newValue)
    }
    function handleCustomerNameDelete(newValue) {
        var deletedCustomerName = customerName.filter(item => item !== newValue)
        setCustomerName(deletedCustomerName)
    }
    function handleTopParentMN(newValue) {
        setTopParentMN(newValue)
    }
    function handleTopParentMNDelete(newValue) {
        var deletedTopParentMN = topParentMN.filter(item => item !== newValue)
        setTopParentMN(deletedTopParentMN)
    }
    function handlePrimaryCN(newValue) {
        setPrimaryCN(newValue)
    }
    function handlePrimaryCNDelete(newValue) {
        var deletedPrimaryCN = primaryCN.filter(item => item !== newValue)
        setPrimaryCN(deletedPrimaryCN)
    }
    function handleTertiaryCN(newValue) {
        setTertiaryCN(newValue)
    }
    function handleTertiaryCNDelete(newValue) {
        var deletedTertiaryCN = tertiaryCN.filter(item => item !== newValue)
        setTertiaryCN(deletedTertiaryCN)
    }
    function handleQuarterneryCN(newValue) {
        setQuarterneyCN(newValue)
    }
    function handleQuarterneryCNDelete(newValue) {
        var deletedQuarterneryCN = quarterneyCN.filter(item => item !== newValue)
        setQuarterneyCN(deletedQuarterneryCN)
    }
    function handleActionCode(newValue) {
        setActionCode(newValue)
    }
    function handleActionCodeDelete(newValue) {
        var deletedActionCode = actionCode.filter(item => item !== newValue)
        setActionCode(deletedActionCode)
    }
    const handleActionEffectiveData = (value) => {
        setActionEffectiveData(value);
    }
    function handleSubmit() {
        var data = {
            "customer_number.in": customerNumber ? customerNumber.toString() : '',
            "customer_name.in": customerName ? customerName.toString() : '',
            "top_parent_member_number.in": topParentMN ? topParentMN.toString() : '',
            "primary_contract_number.in": primaryCN ? primaryCN.toString() : '',
            "secondary_contract_number.in": secondaryCN ? secondaryCN.toString() : '',
            "tertiary_contract_number.in": tertiaryCN ? tertiaryCN.toString() : '',
            "quarternery_contract_number.in": quarterneyCN ? quarterneyCN.toString() : '',
            "action_code.in": actionCode ? actionCode.toString() : '',
            "action_effective_date": actionEffectiveData ? Moment.utc(actionEffectiveData).format("MM/DD/YYYY") : '',
        };
        props.onSubmit(data);
        handleClear();
    }
    function handleClear() {
        setCustomerNumber([])
        setCustomerName([])
        setTopParentMN([])
        setPrimaryCN([])
        setSecondaryCN([])
        setTertiaryCN([])
        setQuarterneyCN([])
        setActionCode([])
        setActionEffectiveData(null);
    }
    function handleClearAll() {
        handleClear();
        setEditMode(false);
    }
    return (
        <div className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2} style={{ padding: '16px 25px 0px 25px' }}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Customer Number
                                    </FormLabel>
                                    <ChipInput data={customerNumber} onChange={handleCustomerNumber} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={customerNumber}
                                        onAdd={(chips) => handleCustomerNumber(chips)}
                                        onDelete={(chip) => handleCustomerNumberDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Customer Name
                                    </FormLabel>
                                    <ChipInput data={customerName} onChange={handleCustomerName} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={customerName}
                                        onAdd={(chips) => handleCustomerName(chips)}
                                        onDelete={(chip) => handleCustomerNameDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Top Parent Member Number
                                    </FormLabel>
                                    <ChipInput data={topParentMN} onChange={handleTopParentMN} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={topParentMN}
                                        onAdd={(chips) => handleTopParentMN(chips)}
                                        onDelete={(chip) => handleTopParentMNDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Primary Contract Number
                                    </FormLabel>
                                    <ChipInput data={primaryCN} onChange={handlePrimaryCN} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={primaryCN}
                                        onAdd={(chips) => handlePrimaryCN(chips)}
                                        onDelete={(chip) => handlePrimaryCNDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Secondary Contract Number
                                    </FormLabel>
                                    <ChipInput data={secondaryCN} onChange={handleSecondaryCN} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={secondaryCN}
                                        onAdd={(chips) => handleSecondaryCN(chips)}
                                        onDelete={(chip) => handleSecondaryCNDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Tertiary Contract Number
                                    </FormLabel>
                                    <ChipInput data={tertiaryCN} onChange={handleTertiaryCN} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={tertiaryCN}
                                        onAdd={(chips) => handleTertiaryCN(chips)}
                                        onDelete={(chip) => handleTertiaryCNDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Quarternery Contract Number
                                    </FormLabel>
                                    <ChipInput data={quarterneyCN} onChange={handleQuarterneryCN} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={quarterneyCN}
                                        onAdd={(chips) => handleQuarterneryCN(chips)}
                                        onDelete={(chip) => handleQuarterneryCNDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Action Code
                                    </FormLabel>
                                    <ChipInput data={actionCode} onChange={handleActionCode} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={actionCode}
                                        onAdd={(chips) => handleActionCode(chips)}
                                        onDelete={(chip) => handleActionCodeDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Action Effective Date
                                    </FormLabel>
                                    <Grid container justifyContent="space-around">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '2px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={actionEffectiveData}
                                                onChange={date => handleActionEffectiveData(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Card>
            <div className={classes.buttonRoot}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                    disabled={customerNumber.length || customerName.length || topParentMN.length || primaryCN.length || secondaryCN.length || tertiaryCN.length || quarterneyCN.length || actionCode.length || actionEffectiveData > 0 ? false : true}
                >
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Run'
                    }
                </Button>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
        attributeData: state.attributeListData.AttributeData,
        customerMasterData: state.customerData.customerMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getMasterReportData(data)),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MasterReportSearch);