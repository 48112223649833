import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Grid
} from '@mui/material';
import { connect } from 'react-redux';
import PromotionBudgetRevenue from './components/PromotionBudgetRevenue';
import PromotionAnalyticsCost from './components/PromotionAnalyticsCost';
import PromotionCostPercentage from './components/PromotionCostPercentage';
import {
    promotionAnalyticsCost,
    promotionRevenueAnalytics,
    promotionCostPercentage, getDashboardCount
} from '../../../redux/actions';
import Moment from 'moment';
import { DBPromoPlan } from '../../Dashboard/components/DBPromoPlan';
import { ContractCell } from '../../Dashboard/components/ContractCell';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 16,
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
        },
        height: "100%",
    },
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: -20,
        borderRadius: 10,
        padding: "27px 10px 27px 10px",
    },
    pdRoot: {
        backgroundColor: "white",
        // padding: "10px 30px",
        borderRadius: 10,
        boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
        //paddingTop: 10,
    },
    formLabel: {
        width: '100%',
        paddingLeft: 20,
        paddingTop: 10,
        fontSize: theme.typography.h1.fontSize,
        display: 'flex',
        justifyContent: 'center',

    },
    activeContracts: {
        backgroundColor: '#38d138'
    },
    expiredContracts: {
        backgroundColor: '#d43333'
    },
    profitability: {
        backgroundColor: '#b37109'
    },
    totalContracts: {
        backgroundColor: theme.palette.primary.main
    },
    waitingApprovals: {
        backgroundColor: '#FFBC03'
    },
    newContracts: {
        backgroundColor: '#7843DF'
    },
    contractCellContainer: {
        [theme.breakpoints.down('lg')]: {
            padding: '0px 16px 18px 16px !important'
        }
    }
}));
const Analytics = props => {
    const classes = useStyles();
    useEffect(() => {
        var ed = Moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
        var sd = Moment().subtract(10, 'months').startOf('month').format('YYYY-MM-DD');

        var edRev = Moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
        var sdRev = Moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');

        var nextmonth = Moment().add(1, 'M').endOf("month").format('YYYY-MM-DD');
        var nextMonthStart = Moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD');

        props.loadDashboardCount(sd, ed);
        props.promotionAnalyticsCost({ "start_date": sd, "end_date": ed });
        props.promotionRevenueAnalytics(sdRev, edRev);
        props.promotionCostPercentage(nextMonthStart, nextmonth);
    }, [])
    return (
        <div style={{ height: sessionStorage.getItem('application') === 'Promotions' ? '100%' : 'auto' }}>
            <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.activeContracts} heading={'Channel Partner Units'} value={props.dashboardCountData && props.dashboardCountData.channel_partner_units ? (props.dashboardCountData.channel_partner_units) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.waitingApprovals} heading={'End Customer Units'} value={props.dashboardCountData && props.dashboardCountData.end_customer_units ? (props.dashboardCountData.end_customer_units) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.newContracts} heading={'Promotion Budget'} value={props.dashboardCountData && props.dashboardCountData.promotion_budget ? (props.dashboardCountData.promotion_budget) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.expiredContracts} heading={'Promotion Spent'} value={props.dashboardCountData && props.dashboardCountData.promotion_spent ? (props.dashboardCountData.promotion_spent) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.totalContracts} heading={'Total Revenue'} value={props.dashboardCountData && props.dashboardCountData.total_revenue ? (props.dashboardCountData.total_revenue) : 0} />
                </div>
                {/* <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.profitability} heading={'Profitability'} value={props.dashboardCountData && props.dashboardCountData.promotion_spent ? parseInt(props.dashboardCountData.promotion_spent).toLocaleString() : 0} />
                </div> */}
            </div>
            <div className={classes.root}>
                <Grid
                    container
                    className={classes.container}
                    spacing={1}
                // style={{ padding: 8, marginTop: 0 }}
                >
                    <Grid
                        item
                        lg={12}
                        sm={12}
                        xl={12}
                        xs={12}
                    >
                        <div className={classes.pdRoot}>
                            <PromotionAnalyticsCost promoAnalyCost={props.promoAnalyCost} />
                        </div>
                    </Grid>

                    <Grid
                        item
                        lg={6}
                        sm={12}
                        xl={6}
                        xs={12}
                    >
                        <div className={classes.pdRoot} style={{ padding: 3 }}>
                            <PromotionBudgetRevenue promoAnalyCost={props.promoAnalyRevenue} />
                        </div>
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        sm={12}
                        xl={6}
                        xs={12}
                    >
                        <div className={classes.pdRoot} style={{ padding: 3 }}>
                            <PromotionCostPercentage promoAnalyCost={props.promoCostPer} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        dashboardCountData: state.initialData.dashboardCountData,
        promoAnalyCost: state.initialData.promoAnalyCost,
        promoAnalyRevenue: state.initialData.promoAnalyRevenue,
        promoCostPer: state.initialData.promoCostPer
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadDashboardCount: (sd, ed) => dispatch(getDashboardCount(sd, ed, 'Promotions')),
        promotionAnalyticsCost: (sd, ed) => dispatch(promotionAnalyticsCost(sd, ed)),
        promotionRevenueAnalytics: (sd, ed) => dispatch(promotionRevenueAnalytics('analytics', sd, ed)),
        promotionCostPercentage: (sd, ed) => dispatch(promotionCostPercentage('analytics', sd, ed))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);