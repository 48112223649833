import React from 'react';
import { makeStyles } from "@mui/styles";
import { Grid, Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card:{
    borderRadius: 10, 
    color: 'white', 
    boxShadow: '0 10px 24px rgba(0,0,0,0.44), 0 10px 10px rgba(0,0,0,0.10)'
  }
}));

const TileLayout = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3} style={{ padding: 30 }}>
          <Typography variant="h3">Rejection Reason</Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>

          {props.claimErrorCount && props.claimErrorCount.rejection_reason && props.claimErrorCount.rejection_reason.map(d => {
            return (
              <Grid item spacing={2} md={3} style={{ paddingTop: 20 }}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h3" component="div">
                      {d.rejection_error} - {d.rejection_reason_description}
                    </Typography>
                    <Typography variant="h4" component="div">
                      Claim Lines : {d.claim_count}
                    </Typography>
                    <Typography variant="h4" component="div">
                      Claim Value  : {d.claim_value}
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
      <Grid container item xs={12} spacing={3} style={{ padding: 30 }}>
          <Typography variant="h3">Claim Error</Typography>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          {props.claimErrorCount && props.claimErrorCount.claim_error && props.claimErrorCount.claim_error.map(d => {
            return (
              <Grid item spacing={2} md={3} style={{ paddingTop: 20 }}>
                <Card style={{ borderRadius: 10, color: 'white', boxShadow: '0 10px 24px rgba(0,0,0,0.44), 0 10px 10px rgba(0,0,0,0.10)' }}>
                  <CardContent>
                    <Typography variant="h3" component="div">
                      {d.claim_error}
                    </Typography>
                    <Typography variant="h4" component="div">
                      Claim Count : {d.claim_count}
                    </Typography>
                    <Typography variant="h4" component="div">
                      Claim Value  : {d.claim_value}
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    claimErrorCount: state.addMultipleConfigurationData.claimErrorCount,
  }
};

export default connect(mapStateToProps,null)(TileLayout);
