import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getIncomingClaimResponseFormatFields
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    }
}));

const IncomingClaimFormatViewQuery = props => {
    const classes = useStyles();
    const [claimData, setClaimData] = React.useState(sessionStorage.getItem('incoming-claim-format') ? JSON.parse(sessionStorage.getItem('incoming-claim-format')) : []);

    useEffect(() => {
        props.getIncomingClaimFormatFields();
    }, []);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">

                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/supplier-incoming-claim-format'
                >
                    Incoming Claim Response Format
                </Link>
                <Typography color="textPrimary" variant='h4'>View Format</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="h3" color={'primary'}>
                                    {claimData ? claimData['format_name'] : ''}
                                </Typography>
                            </div>
                            {claimData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Field Name</TableCell>
                                                        <TableCell align='center' style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(claimData).length > 0 && Object.entries(claimData)

                                                        .filter(([k, v]) => k != 'ID' && k != 'format_name' && k != 'organization_id' && k != 'created_by_id' && k != 'updated_by_id' && k != 'id' && v > 0)
                                                        .sort(function (a, b) {
                                                            return a[1] < b[1] ? -1 : 1;
                                                        })
                                                        .map(([k, v]) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell style={{ width: 70 }}>
                                                                        {JSON.parse(sessionStorage.getItem('incoming-claim-format'))[k] ? JSON.parse(sessionStorage.getItem('incoming-claim-format'))[k] : ''}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getIncomingClaimFormatFields: () => dispatch(getIncomingClaimResponseFormatFields()),
    }
}

const mapStateToProps = state => {
    return {
        incomingClaimFormatFields: state.dataSetupData.incomingClaimFormatFields
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingClaimFormatViewQuery);