import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { addInventoryDataFormat, getInventoryDataFormatFields } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
             paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const InventoryAddQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [queryObject, setQueryObject] = React.useState({});
    const [userStore, setUserStore] = React.useState([]);
    const [editMode, setEditMode] = React.useState(false);
    const [channelPartnerId,setChannelPartnerId] = React.useState('');
    const [startDate,setStartDate] = React.useState('');
    const [material,setMaterial] = React.useState('');
    const [endDate,setEndDate] = React.useState('');
    const [startingInventory,setstartingInventory]=React.useState('');
    const [endingInventory,setEndingInventory] = React.useState('');
    const [defaultValue,setDefaultValue]=React.useState({});
    const requiredFields=['channel_partner_id','end_date','ending_inventory','material','start_date','starting_inventory']
    useEffect(() => {
        setOpen(true);
        props.getInventoryDataFields();
    }, []);
    const handleDefaultValue = (e) => {
        setDefaultValue(e.target.value);
    }
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName.trim() });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if(propName!='format_name')
            {
                 queryObject[propName] =''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
        setDefaultValue({ ...defaultValue });
        
    }
    function handleOnSubmit() {
        delete queryObject['id'];
        for (var propName in queryObject) {
            if (queryObject[propName] ==='' || queryObject[propName] === null || queryObject[propName] === undefined) {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit({...queryObject,'format_name': queryName,'default_values':defaultValue});
    }
    function handleClearAll() {
        handleClear();
        setChannelPartnerId('');
        setEndDate('');
        setStartDate('');
        setEndingInventory('');
        setstartingInventory('');
        setMaterial('');
        setEditMode(false);
        setUserStore([]);
    }
    function handleCancelDialog() {
        history.push('/inventory-data/inventory-data-excel-upload');
    }
    const handleChange = (event, item) => {
        setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        if(item==='channel_partner_id'){
            setChannelPartnerId(event.target.value)
        }
        if(item==='end_date'){
            setEndDate(event.target.value)
        }
        if(item==='ending_inventory'){
            setEndingInventory(event.target.value)
        }
        if(item==='material'){
            setMaterial(event.target.value)
        }
        if(item==='start_date'){
            setStartDate(event.target.value)
        }
        if(item==='starting_inventory'){
            setstartingInventory(event.target.value)
        }

    };
    const handleChange2 = (event, item) => {
        setDefaultValue({...defaultValue,[item]: event.target.value });
        if(item==='channel_partner_id'){
            setChannelPartnerId(event.target.value)
        }
        if(item==='end_date'){
            setEndDate(event.target.value)
        }
        if(item==='ending_inventory'){
            setEndingInventory(event.target.value)
        }
        if(item==='material'){
            setMaterial(event.target.value)
        }
        if(item==='start_date'){
            setStartDate(event.target.value)
        }
        if(item==='starting_inventory'){
            setstartingInventory(event.target.value)
        }
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/inventory-data/inventory-data-excel-upload'
                >
                    Channel Inventory Format
                </Link>
                <Typography color="textPrimary" variant='h1'>Add Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.queryListData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.queryListData &&
                                                        props.queryListData
                                                            .filter(item => item != 'id' && item != 'format_name' && item != '')
                                                            .sort()
                                                            .map((item) => {
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell classes={{root:classes.fontSetting}} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        { requiredFields.includes(item)? <p>{item.replace(/_/g, ' ')}<span style={{color: 'red'}}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                            </TableCell>
                                                                        <TableCell classes={{root:classes.fontSetting}}style={{ width: 70 }}>
                                                                            <TextField
                                                                               value={queryObject[item] }
                                                                                type="number"
                                                                                variant="outlined"
                                                                                onChange={(e) => handleChange(e, item)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell classes={{root:classes.fontSetting}}style={{ width: 70, paddingLeft: 10  }}>
                                                                        <TextField
                                                                            value={defaultValue[item]}
                                                                            type="string"
                                                                             variant="outlined"
                                                                            onChange={(e) => handleChange2(e,item)}  
                                                                        />
                                                                    </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                      disabled={ (channelPartnerId || defaultValue['channel_partner_id']) && (material|| defaultValue['material']) && (startDate || defaultValue['start_date']) &&(endDate || defaultValue['end_date']) && (startingInventory ||defaultValue['starting_inventory'] )&& (endingInventory || defaultValue['ending_inventory']) ? false : true} >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        queryListData: state.dataSetupData.inventoryFormatFieldsData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getInventoryDataFields: () => dispatch(getInventoryDataFormatFields()),
        onSubmit: (data) => dispatch(addInventoryDataFormat(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InventoryAddQuery);