import * as React from "react";
import { useEffect } from "react";
import { Browser } from "@syncfusion/ej2-base";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  AccumulationTooltip,
  Inject,
} from "@syncfusion/ej2-react-charts";
import { colors } from "../../../components/Icons/ColorList";
const PieChart = (props) => {
  const load = (args) => {
    let selectedTheme = "Material";
    args.accumulation.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast");
  };

  return (
    <div className="control-pane">
      {props.data && props.data.length > 0 ? (
        <div className="control-section" style={{ paddingTop: props.height === "small" ? "30px": undefined}}>
          <AccumulationChartComponent
            id="pie-chart"
            legendSettings={{ visible: true, reverse: true }}
            enableSmartLabels={true}
            title=""
            enableBorderOnMouseMove={false}
            enableAnimation={true}
            load={load.bind(this)}
            tooltip={{
              enable: true,
              format: "<b>${point.x}</b><br/>Total : <b>${point.y} </b>",
            }}
          >
            <Inject
              services={[
                AccumulationLegend,
                PieSeries,
                AccumulationDataLabel,
                AccumulationTooltip,
              ]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={props.data}
                xName={props.dimension && props.dimension.xName} //"GroupBy"
                yName={props.dimension && props.dimension.yName} //"Total"
                innerRadius="20%"
                tooltipMappingName={
                  props.dimension && props.dimension.tooltipMappingName
                } //"GroupBy"
                //startAngle={270}
                //endAngle={90}
                dataLabel={{
                  visible: true,
                  position: /*Browser.isDevice ?*/ "Inside", //: "Outside",
                  name: "text",
                  enableRotation: true,
                  font: { fontWeight: "600" },
                  connectorStyle: { length: "20px", type: "Curve" },
                }}
                palettes={colors}
              />
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: props.height === "small" ? 455 : 300,
            fontSize: 27,
            color: "grey",
          }}
        >
          No Data to Display !
        </div>
      )}
    </div>
  );
};
export default PieChart;
