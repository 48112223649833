import React from 'react';
import { connect } from 'react-redux';
import { PricingBatchJobSetupTable } from './components';

const PricingBatchJobSetup = () => {
    return (
        <div>
            <PricingBatchJobSetupTable />
        </div >
    );
};
export default connect(null,null)(PricingBatchJobSetup);