//not used
import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Button,
    Card,
    Grid,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    CardHeader,
    FormLabel,
    Select,
    MenuItem,
    Input, Fab
} from '@mui/material';
import { ThemeProvider, StyledEngineProvider, createTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { SearchInput } from '../../../components';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DropdownArray, LabelText } from '../../../components/Inputs';
import {
    addMultipleConfiguration, getDefaultValues, secondGetConfiguration, putMultipleConfiguration,
    getDefaultValuesCustomerXRef, getDefaultValuesCustomer, getDefaultValuesMaterialXRef, allowedApps,
    getContractsTableDataList
} from '../../../redux/actions';
import ChipInput from 'material-ui-chip-input';
import { ApplicationTypes } from '../../../library/constants'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',

    },
    submitContainer: {
        marginRight: -25,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 35,
        paddingBottom: 28
    },
    button: {
        marginRight: 25,
        width: 140
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'left'
        }
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        // backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize

    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    textInputRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        width: '100%'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },

}));

const MultipleConfigurationTab = props => {
    const [contractsList, setContractsList] = React.useState([]);
    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
    useEffect(() => {
        props.onLoadingDefaultCustomerXRef();
        props.onLoadingDefaultCustomer();
        props.onLoadingDefaultMaterialXRef();
        props.allowedApps();
    }, []);

    useEffect(() => {
        setContractsList(props.contractablesdata);
    }, [props.contractablesdata])

    const classes = useStyles();
    const { className } = props;
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    const refDescription = useRef(null);
    const [fieldValue, setFieldValue] = React.useState('');
    const [putActive, setPutActive] = React.useState(false);
    const [fieldValueKeys, setFieldValueKeys] = React.useState([]);
    const [descriptionValue, setDescriptionValue] = React.useState('');
    const [selectedRowId, setSelectedRowId] = React.useState('');
    const [selectedRowKey, setSelectedRowKey] = React.useState([]);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [secondApiDropDownValues, setSecondApiDropDownValues] = React.useState([]);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [applicationType, setApplicationType] = React.useState('Customer Rebate');
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    useEffect(() => {
        props.onCheckContractsTable(0, 'Designer Studio', allowedApps[0]);
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            props.onLoadingDefault(props.allowedAppsData[0], '', '', '', 'Contract Designer');
        }
    }, [props.allowedAppsData])

    useEffect(() => {
        if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
            setTableData(props.tableData.records)
        }
    }, [props.tableData])
    const handleApplicationtype = (newValue) => {
        props.onCheckContractsTable(0, 'Designer Studio', newValue);
        setApplicationType(newValue);
        props.onLoadingDefault(newValue, '', '', '', 'Contract Designer');
    }
    function handleRowClick(row, index) {
        setPutActive(false);
        setSelectedRowName(row);
        setSelectedRowKey(props.tableData.available_field_name_keys[index]);
        props.secondGetFunction(props.tableData.available_field_name_keys[index]);
    }

    function handleRowClickAlreadyDefined(row, index) {
        setDisableEdit(true);
        setSecondApiDropDownValues(row.drop_down_values);
        setSelectedRowId(row.ID)
        setPutActive(true);
        setSelectedRowName(row.name);
        setSelectedRowKey(row.field_id);
        setFieldValue(row.drop_down_value_keys);
        setDescriptionValue(row.description)
        props.secondGetFunction(tableData[index].field_id);
    }

    const toolTipTheme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "2em",
                        color: "yellow",
                        backgroundColor: "red"
                    }
                }
            }
        }
    });

    function onSubmit() {
        if (props.tableData.length !== 0) {
            //write what to do when success
        } else {
            toast.error('Please add atleast one configuration.');
        }
    }
    const handleFieldValue = (newValue) => {

        setDisableEdit(false);
        setFieldValue([...fieldValue, newValue]);
        setFieldValueKeys(newValue)
    }
    const handleDeleteFieldValue = (newValue) => {
        setDisableEdit(false);
        var keep = true;
        contractsList.map(item => {
            if (item.contract_type === newValue) {
                setTooltipIsOpen(true);
                keep = false;
                setTimeout(
                    () => setTooltipIsOpen(false),
                    4000
                );
            }
        })
        if (keep) {
            keep = true;
            var deleteFieldValue = fieldValue.filter(item => item !== newValue)
            setFieldValue(deleteFieldValue);
        }

    }
    function handleSubmit() {
        if (props.secondApiData && props.secondApiData.records[0] && props.secondApiData.records[0].drop_down_values.length) {
            var indexArray = new Array();
            for (var z = 0; z < fieldValue.length; z++) {
                for (var i = 0; i < props.secondApiData.records[0].drop_down_values.length; i++) {
                    if (props.secondApiData.records[0].drop_down_values[i] === fieldValue[z]) {
                        indexArray.push(props.secondApiData.records[0].drop_down_value_keys[i]);
                    }
                }
            }
        }
        var data = {
            "form_id": "IMA_CONFIG_CONTRACT",
            "description": descriptionValue,
            "name": selectedRowName,
            "field_id": selectedRowKey,
            "section": 0,
            "drop_down_values": fieldValue,
            "drop_down_value_keys": fieldValue,
            "type": "string"
        }
        if (putActive) {
            props.onMultipleConfigurationPut(data, selectedRowId, applicationType);
        } else {
            props.onMultipleConfigurationAdd(data);
        }
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div style={{ marginTop: '-18px' }}>
                <Grid container classes={{ root: classes.flexEnd }} >
                    <Typography variant="h1"
                        color='primary' style={{ marginLeft: 10, marginTop: 10 }}
                    > Contract Dropdown Designer </Typography>

                </Grid>
            </div>
            <div
                className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>

                <Card >
                    <form
                        autoComplete="off"
                        noValidate
                    >

                        <div className={classes.container} >
                            <Grid container spacing={3} >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.dropdownContainer}
                                >
                                    {allowedApps && allowedApps.length > 0 &&
                                        <DropdownArray classes={{ root: classes.formLabel }} heading={'Application Type'}
                                            prevalue={allowedApps[0]}
                                            twoline='true' onChange={handleApplicationtype} data={allowedApps} />
                                    }
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Field Name' data={selectedRowName ? selectedRowName != 'Target type' ? selectedRowName : 'Commitment Type' : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        container className={classes.textInputRoot}
                                        style={{ marginTop: 0 }}
                                    >
                                        <Grid
                                            item
                                            className={classes.gridContainer}
                                            style={{ padding: "15px 0px 15px" }}
                                        >
                                            <FormLabel error={false}>
                                                Field Value
                                            </FormLabel>
                                            <StyledEngineProvider injectFirst>
                                                <ThemeProvider theme={toolTipTheme}>
                                                    <Tooltip
                                                        open={tooltipIsOpen}
                                                        title="This contract type is used in one or more contracts"
                                                        classes={{
                                                            tooltip: {
                                                                fontSize: "2em",
                                                                color: "yellow",
                                                                backgroundColor: "red"
                                                            }
                                                        }}
                                                    >
                                                        <ChipInput
                                                            classes={{
                                                                root: classes.rootContainer,
                                                                chip: classes.chip,
                                                                input: classes.input,
                                                                inputRoot: classes.inputRoot,
                                                                label: classes.chipLabel
                                                            }}
                                                            value={fieldValue}
                                                            onAdd={(chips) => handleFieldValue(chips)}
                                                            onDelete={(chip) => handleDeleteFieldValue(chip)}
                                                            disableUnderline={true}
                                                            className={clsx({
                                                                [classes.textInput]: true
                                                            })}
                                                            blurBehavior='add'
                                                        />
                                                    </Tooltip>
                                                </ThemeProvider>
                                            </StyledEngineProvider>
                                        </Grid>
                                    </Grid>
                                }


                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        className={classes.buttonContainer}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                            classes={{
                                                root: classes.checkIconContainer
                                            }}
                                            onClick={handleSubmit}
                                        >
                                            Edit
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EDITABLE VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        {/* <SearchInput /> */}
                    </div>

                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {tableData && tableData.length > 0 ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell classes={{ root: classes.leftAlign }}>Field Name</TableCell>
                                                    <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {tableData
                                                    .filter(item => item.field_id != 'attribute_name' && item.field_id != 'key_figure_name' && item.field_id != 'tier_evaluation_level')
                                                    .filter(item => item.type === 'DROPDOWN.STRING')
                                                    .filter(item => item.editable === true)
                                                    .map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item.ID} onClick={() => handleRowClickAlreadyDefined(item, index)} style={{ cursor: 'pointer' }}>
                                                                <TableCell classes={{ root: classes.leftAlign }}>{item.name != 'Target type' ? item.name : 'Commitment Type'}</TableCell>
                                                                <TableCell classes={{ root: classes.leftAlign }}>{item.drop_down_values.toString()}</TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography variant='h4'>
                                        There is no data to show now.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </div>

                    <div className={classes.row} >
                        <CardHeader
                            title="NON EDITABLE VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        {/* <SearchInput /> */}
                    </div>

                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {tableData && tableData.length > 0 ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell classes={{ root: classes.leftAlign }}>Field Name</TableCell>
                                                    <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {tableData
                                                    .filter(item => item.field_id != 'attribute_name' && item.field_id != 'key_figure_name' && item.field_id != 'tier_evaluation_level')
                                                    .filter(item => item.editable === false)
                                                    .filter(item => item.type === 'DROPDOWN.STRING')
                                                    .map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item.ID} style={{ cursor: 'pointer' }}>
                                                                <TableCell classes={{ root: classes.leftAlign }}>{item.name}</TableCell>
                                                                <TableCell classes={{ root: classes.leftAlign }}>{item.drop_down_values.toString()}</TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography variant='h4'>
                                        There is no data to show now.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div >
    );

};

const mapStateToProps = state => {
    return {
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
        secondApiData: state.addMultipleConfigurationData.secondApiData,
        dropdownData: state.customerData.dropdownData,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        dropdownDataMaterialMasterXRef: state.customerData.dropdownDataMaterialXRef,
        allowedAppsData: state.initialData.allowedApps,
        contractablesdata: state.addContractData.contractAllListData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onMultipleConfigurationAdd: (data) => dispatch(addMultipleConfiguration(data)),
        onMultipleConfigurationPut: (data, id, applicationType) => dispatch(putMultipleConfiguration(data, id, applicationType)),
        secondGetFunction: (data) => dispatch(secondGetConfiguration(data)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        onLoadingDefaultCustomerXRef: () => dispatch(getDefaultValuesCustomerXRef()),
        onLoadingDefaultCustomer: () => dispatch(getDefaultValuesCustomer()),
        onLoadingDefaultMaterialXRef: () => dispatch(getDefaultValuesMaterialXRef()),
        allowedApps: () => dispatch(allowedApps()),
        onCheckContractsTable: (pagination, page, app) => dispatch(getContractsTableDataList(pagination, page, app)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleConfigurationTab);