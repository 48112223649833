import React, { useEffect, useState } from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { dashboardAnalyticsNames, dashboardDashBoardNames } from '../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import { history } from "../../components/Helpers";
import "./components/Launcherstyle.css";
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    FormLabel,
    MenuItem,
    Select,
    OutlinedInput
} from '@mui/material';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "#dee0e2fb",
        height: "90vh"
    },

    // root: {
    //     [theme.breakpoints.up("md")]: {
    //         height: "100vh",
    //     },
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     backgroundImage: theme.palette.gradient.appLauncher,
    // },
    innerContainer: {
        [theme.breakpoints.down('xl')]: {
            minWidth: 0,
            // marginTop: 10,
            marginRight: 15,
            fontSize: 10,
            alignItems: 'center'
        },
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0,
    },
    vectorParent: {
        borderRadius: "var(--br-smi)",
        width: "220px",
        fontSize: "16px",
        fontFamily: "Poppins",
        borderRadius: "13px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        padding: "10px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        flexDirection: "column",
        cursor: "pointer",
        margin: "8px",
        overflow: "hidden",
        position: "relative",

    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: 84,
        width: 210,
        justifyContent: "center",
        "&:hover": {
            transform: "scale(1.1)",
            transition: "0.6s",
            cursor: "pointer",
            animationDuration: "4s",
            animationDelay: "2s",
        },
        color: "white",
        margin: "8px 16px 8px 8px",
        borderRadius: 9,

        backgroundColor: theme.palette.appLauncher.paper,
    },

    disabled: {
        pointerEvents: "none",
        opacity: 0.4,
    },
    mgBottom10: {
        marginBottom: 10,
        color: "#A9B3E4",
    },
    h4: {
        marginTop: 30,
        paddingLeft: 15,
    },
    tabStyle: {
        backgroundColor: theme.palette.appLauncher.tabStyle,
        padding: 12,
        borderRadius: "10px 10px 0 0",
        color: theme.palette.appLauncher.tabStyleFont,
        width: 170,
        fontSize: "0.76rem",
    },
    tabContainer: {
        padding: '8px 10px 8px 15px',
        borderRadius: "8px 8px 8px 8px",
        marginLeft: 20,
        marginRight: 20,
        marginTop: "-12.125rem"
        //width:'100%'
    },
    tabContainer1: {
        padding: '8px 10px 8px 15px',
        borderRadius: "8px 8px 8px 8px",
        marginLeft: 20,
        marginRight: 20,
        // marginTop: "-18.125rem"
        //width:'100%'
    },
    button: {
        position: "absolute",
        top: 10,
        right: 10,
        backgroundColor: theme.palette.primary.main,
    },
    switchTheme: {
        color: "black",
    },
    subContainer: {
        // display: "flex",
        // flexDirection: "row",
        // backgroundImage: theme.palette.appLauncher.subContainer,
        // borderRadius: "0px 20px 20px 20px",
        // padding: 15,
    },
    adminContainer: {
        padding: '30px 10px 8px 15px',
        borderRadius: "8px 8px 8px 8px",
        marginLeft: 20,
        marginRight: 20,
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'center',
        height: window.screen.height,
    },
    formDiv: {
        fontSize: "1.5em",
        padding: '6px'
    },
}));
const AnalyticsMain = (props) => {
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);
    const [paymentCalculationSchemaData, setPaymentCalculationSchemaData] = React.useState([]);
    const [paymentContractTypeData, setPaymentContractTypeData] = React.useState([]);
    const [paymentContractGroupData, setPaymentContractGroupData] = React.useState([]);
    const [windowHeight, setWindowHeight] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [allowedTiles, setAllowedTiles] = React.useState([]);
    const [allowedDashBoardTiles, setAllowedDashBoardTiles] = React.useState([]);


    useEffect(() => {
        props.allowedApps(sessionStorage.getItem("application"));
        props.allowedDashBoardApps(sessionStorage.getItem("application"));
        sessionStorage.setItem('rebate-trend-second-page', false)
    }, []);

    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedTiles(props.allowedAppsData)
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            // setAllowedDashBoardTiles(props.allowedDashBoardData)
            const uniqueDashboardNames = new Set();
            const filteredTiles = props.allowedDashBoardData.filter(tile => {
                if (!uniqueDashboardNames.has(tile.dashboard_name)) {
                    uniqueDashboardNames.add(tile.dashboard_name);
                    return true;
                }
                return false;
            });

            setAllowedDashBoardTiles(filteredTiles);
        }
    }, [props.allowedDashBoardData])


    useEffect(() => {
        function handleResize() {
            // Calculate the adjusted window height based on the zoom level
            const adjustedHeight = window.innerHeight / window.devicePixelRatio;
            setWindowHeight(adjustedHeight);
            const adjustedWidth = window.innerWidth / window.devicePixelRatio;
            setWindowWidth(adjustedWidth);
        }

        // Add a listener for window resize events
        window.addEventListener("resize", handleResize);

        // Call the resize handler once to set the initial height
        handleResize();

        // Remove the listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleBoxClick = (value) => {
        if (value === "Postings Summary by Contract Groupings") {
            history.push("/analytics");
        } else if (value == "Monthly Accrual vs Payment") {
            history.push("/analytics-comparison");
        } else if (value == "Monthly Gross to Net") {
            history.push("/sales-payment-comparison");
        } else if (value === "Incentive Ratio by Attribute") {
            history.push("/geographical-chart-us");
        } else if (value === "Top Incentives by Attribute") {
            history.push("/top25");
        } else if (value === "Postings Variance by Attribute") {
            history.push("/rebate-trends");
        } else if (value === "Postings Variance by Contract Groupings") {
            history.push("/variance-combined");
        } else if (value === "Trending by Contract Number") {
            history.push("/incentive-trends");
        } else if (value === "New Master Data Incentives") {
            history.push("/new-master-data-incentives");
        } else if (value === "Incentive Stats") {
            history.push("/incentive-stats");
        } else if (value === "Contract Stats") {
            history.push("/contract-stats");
        } else if (value === "Monthly Active Contracts") {
            history.push("/monthly-active-contracts");
        } else {
            history.push("#");
        }
    };
    const handleBoxClickDashBoard = (value) => {
        if (value === "Posting Summary Analysis") {
            history.push("/posting-summary-analysis");
        } else if (value === "Summarized Dashboard") {
            history.push("/rebate-analytics");
        } else if (value === "Postings Detail Analysis") {
            history.push("/posting-detail-analysis");
        }
        else {
            history.push("#");
        }
    };
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >

            {/* <div className="desktop1"> */}
            <div className={classes.root}>

                <div className="mainContainer">
                    <div className={classes.innerContainer}>
                        <div className={classes.tabContainer}>
                            <div className="subContainer">

                                <div className="functionalApps">Analytics</div>
                                <Grid container spacing={2} style={{ marginLeft: "10px", marginTop: "5px" }}>

                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Postings Summary by Contract Groupings") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #4CA1AF 0%, #C4E0E5 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Postings Summary by Contract Groupings")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/analytics_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #7DEAFB 0%, #55A2AE 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/analtics_icon.png"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}

                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Incentive Stats") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #E1950F 0%, #F3CE2A 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Incentive Stats")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/cc-bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #FFBB3F 0%, #E29810 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/layer-11.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Monthly Gross to Net") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #2C78E5 0%, #62B1F5 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Monthly Gross to Net")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/price_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #559DFF 0%, #317DE6 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/vector10.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Incentive Ratio by Attribute") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #1CA155 0%, #4CD887 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Incentive Ratio by Attribute")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/sc_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(90deg, #1CA155 0%, #4CD887 104.79%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/Vector_sc.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Top Incentives by Attribute") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #B8C14D 0%, #F2FF59 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Top Incentives by Attribute")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/cp_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #F4FF69 0%, #BAC34D 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/cp.png"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}

                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Postings Variance by Attribute") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #EACDA3 0%, #D6AE7B 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Postings Variance by Attribute")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/profit_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #FFD292 0%, #BE8F49 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/Vector_profit.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Postings Variance by Contract Groupings") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #F28396 0%, #FFA9A9 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Postings Variance by Contract Groupings")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/sr_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #FFB9C5 0%, #F38497 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/Vector_sr.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Monthly Active Contracts") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #00BFB7 0%, #00FFE0 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Monthly Active Contracts")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/scharge_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(90deg, #00BFB7 0%, #00FFE0 104.79%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/scb.png"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Contract Stats") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #887BD6 0%, #AFB7FF 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Contract Stats")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/promo_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(90deg, #887BD6 0%, #AFB7FF 104.79%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/Vector_promo.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "New Master Data Incentives") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #56AB2F 0%, #A8E063 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("New Master Data Incentives")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/or_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(90deg, #56AB2F 0%, #A8E063 104.79%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/or.png"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Monthly Accrual vs Payment") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #6048AB 0%, #AD90FF 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Monthly Accrual vs Payment")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/md_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #A284FF 0%, #6149AC 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/Vector_master.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedTiles && allowedTiles.map(analytic => {
                                        if (analytic.analytic_name === "Trending by Contract Number") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #A5834D 0%, #FFD9B6 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClick("Trending by Contract Number")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">{analytic.analytic_description ? analytic.analytic_description : analytic.analytic_name}</p>
                                                        </b>
                                                        <img
                                                            alt="bgLayer"
                                                            src="/td_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #F0CE98 0%, #A98652 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/Vector_tr.svg"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Grid>
                            </div>
                        </div>
                        <div className={classes.tabContainer1}>
                            <div className="subContainer">
                                <div className="functionalApps">DashBoard</div>
                                <Grid container spacing={2} style={{ marginLeft: "10px", marginTop: "5px" }}>
                                    {allowedDashBoardTiles && allowedDashBoardTiles.map(dashboard => {
                                        if (dashboard.dashboard_name === "Postings Summary Analysis") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #2D79E5 0%, #5DACF4 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClickDashBoard("Posting Summary Analysis")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">Posting Summary Analysis</p>
                                                        </b>
                                                        <img
                                                            alt=""
                                                            src="/ds_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #549BFE 0%, #337FE7 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/ds.png"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedDashBoardTiles && allowedDashBoardTiles.map(dashboard => {
                                        if (dashboard.dashboard_name === "Summarized Dashboard") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #F28396 0%, #FFD9B6 104.79%, #FEA7A8 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClickDashBoard("Summarized Dashboard")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">Summarized Dashboard</p>
                                                        </b>
                                                        <img
                                                            alt=""
                                                            src="/security_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #FFB9C5 0%, #F4899B 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/sc.png"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {allowedDashBoardTiles && allowedDashBoardTiles.map(dashboard => {
                                        if (dashboard.dashboard_name === "Postings Detail Analysis") {
                                            return (
                                                <div
                                                    className={classes.vectorParent}
                                                    style={{
                                                        background: "linear-gradient(90deg, #BF10E2 0%, #E85EFB 104.79%)",
                                                        height: windowHeight * 0.15 + "px",
                                                        width: windowWidth / 4 + "px",
                                                    }}
                                                    onClick={() => handleBoxClickDashBoard("Postings Detail Analysis")}
                                                >
                                                    <div className="samelinediv">
                                                        <b className="customer-rebate">
                                                            <p className="customer">Postings Detail Analysis</p>
                                                        </b>
                                                        <img
                                                            alt=""
                                                            src="/is_bg.svg"
                                                            style={{ height: windowHeight * 0.14 + "px" }}
                                                        />
                                                        <div className="flexEnd">
                                                            <div
                                                                className="frame-child"
                                                                style={{
                                                                    background: "linear-gradient(180deg, #E76DFF 0%, #B902D7 100%)",
                                                                    height: windowHeight * 0.067 + "px",
                                                                    width: windowHeight * 0.067 + "px",
                                                                }}
                                                            >
                                                                <img
                                                                    className="layer-1-icon"
                                                                    alt=""
                                                                    src="/is.png"
                                                                />
                                                            </div>
                                                            <div className="drop-icon">
                                                                <img
                                                                    className="frame-item"
                                                                    alt=""
                                                                    src="/group-10.svg"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Grid>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </LoadingOverlay >

    );
};

const mapStateToProps = state => {
    return {
        // loading: state.initialData.loading,
        allowedAppsData: state.initialData.dashboardanalyticsname,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        allowedApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),
        allowedDashBoardApps: (type) => dispatch(dashboardDashBoardNames(type, "dashboard")),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsMain);