import Palette from '../palette';

export default {
    underline: {
        "&:before": {
            borderBottom: '0px solid',
            borderColor: Palette.border.main
        },
        "&:after": {
            borderBottom: '0px solid'
        },
        "&&&&:hover:before": {
            borderBottom: '0px solid',
            borderColor: Palette.border.main
        }
    }
}
