import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    updatePromotionMappingFormatData, getPromotionMappingFormatDataById
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const PromotionMappingEditQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [editID, setEditID] = React.useState(0);
    const [queryObject, setQueryObject] = React.useState({});
    const [imaFieldName, setImaFieldName] = React.useState('');
    const [imaFieldValue, setImaFieldValue] = React.useState('');
    const [targetFieldName, setTargetFieldName] = React.useState('');
    const [targetFieldValue, setTargetFieldValue] = React.useState('');

    useEffect(() => {
        setOpen(true);
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getPromotionFormatDataById(appId);

    }, []);

    useEffect(() => {
        if (props.promotionData) {
            setQueryName(props.promotionData.format_name)
            setQueryObject(props.promotionData)
            setImaFieldName(props.promotionData.ima_field_name)
            setImaFieldValue(props.promotionData.ima_field_value)
            setTargetFieldName(props.promotionData.target_field_name)
            setTargetFieldValue(props.promotionData.target_field_value)
        }
    }, [props.promotionData]);
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        queryObject['id'] = parseInt(editID)
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        props.onSubmit(editID, queryObject);
    }
    function handleClearAll() {
        handleClear();
        setImaFieldName('')
        setImaFieldValue('')
        setTargetFieldName('')
        setTargetFieldValue('')
    }
    function handleCancelDialog() {
        history.push('/promo-mapping/promo-mapping-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'ima_field_name') {
            setImaFieldName(event.target.value)
        }
        if (item == 'ima_field_value') {
            setImaFieldValue(event.target.value)
        }
        if (item == 'target_field_name') {
            setTargetFieldName(event.target.value)
        }
        if (item == 'target_field_value') {
            setTargetFieldValue(event.target.value)
        }

    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/promo-mapping/promo-mapping-excel-upload'
                >
                    Promotion External Mapping Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.promotionData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.entries(props.promotionData)
                                                        .filter(([k, v]) => k != 'format_name' && k != '' && k != 'id')
                                                        .map(([k, v]) => {
                                                            return (
                                                                <TableRow key={k}>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        <p>{k.replace(/_/g, ' ')}<span style={{ color: 'red' }}> &nbsp;*</span></p>
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[k] ? queryObject[k] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, k)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={imaFieldName && imaFieldValue && targetFieldName && targetFieldValue ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updatePromotionMappingFormatData(id, data)),
        getPromotionFormatDataById: (id) => dispatch(getPromotionMappingFormatDataById(id))
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        promotionData: state.promotionData.promoMapFormatSingleData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionMappingEditQuery);