import React, { useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import {
    Grid,
    Button,
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    IconButton,
    OutlinedInput,
    MenuItem,
    Select,
    FormLabel, TextField
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import { getEligibilityRulesTableData, getDefaultValuesAllDataNew, editEligibilityRules, getDefaultValues, getDefaultValuesAllData } from '../../../../redux/actions';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { DropdownArray } from '../../../../components/Inputs';
var deleteFlag = false;

momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26,

    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        marginTop: 50
    },
    container2: {
        padding: 10,

    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '20px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        //marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    formDiv: {
        height: 32,
        display: 'flex',
        alignItems: 'flex-end'
    }
}));

const TargetRules = forwardRef((props, ref) => {
    // useEffect(() => {
    //     if (localStorage.getItem('mode') === 'edit')
    //         props.onCheckEligibilityRulesTable();
    // }, []);
    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [eligibilityStore, setEligibilityStore] = React.useState([]);
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [contractID, setContractID] = React.useState('');
    const [targetType, setTargetType] = React.useState('');
    const [targetValue, setTargetValue] = React.useState('');
    const [validFrom, setValidFrom] = React.useState(null);
    const [validTo, setValidTo] = React.useState(null);
    useEffect(() => {
        if (props.startDate)
            setValidFrom(props.startDate)
        if (props.endDate)
            setValidTo(props.endDate)

    }, [props.startDate, props.endDate])

    const targetValues = {
        contract_id: contractID,
        target_type: targetType,
        target_value: targetValue,
        effective_start_date: validFrom,
        effective_end_date: validTo
    };
    useImperativeHandle(ref, () => ({
        submit() {
            props.submitValues(targetValues);
        }
    }));
    // useEffect(() => {
    //     props.submitValues(targetValues);
    // });

    function handleValidFrom(newValue) {
        if (newValue && newValue.isValid())
            setValidFrom(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setValidFrom(newValue);
    }
    function handleValidTo(newValue) {
        if (newValue && newValue.isValid())
            setValidTo(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setValidTo(newValue);
    }

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    const editEligibilityRule = (rule, index) => {
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setContractID(rule.contract_id);
        setTargetType(rule.target_type);
        setTargetValue(rule.target_value);
        setValidFrom(rule.effective_start_date);
        setValidTo(rule.effective_end_date);
    }

    function handleSubmit(id) {
        // if (attributeNameValue && option && effectiveStartDate && effectiveEndDate && attributeValue.length > 0) {
        var data = {
            "target_type": targetType,
            "target_value": parseFloat(targetValue),
            "effective_start_date": validFrom,
            "effective_end_date": validTo
        };
        if (id || id === 0) {
            var editedArray = [...eligibilityStore];
            editedArray[editIndex] = data;
            setEligibilityStore(editedArray);
            props.submitValues(editedArray)
            localStorage.removeItem('editableRuleMode');

        } else {
            setEligibilityStore([...eligibilityStore, data]);
            props.submitValues([...eligibilityStore, data])
        }
        handleRemove();
    }

    function handleRemove() {
        setEditMode(false);
        setContractID('');
        setTargetType('');
        setTargetValue('');
        if (validFrom != props.startDate)
            setValidFrom(null);
        else
            setValidFrom(props.startDate)
        if (validTo != props.endDate)
            setValidTo(null);
        else
            setValidTo(props.endDate)
    }
    const contractCustomisationUpdation = useRef();
    const [targetTypeArray, setTargetTypeArray] = React.useState([]);
    // useEffect(() => {
    //     if (props.formFields && props.formFields.length > 0) {
    //         props.formFields
    //             .map((item) => {
    //                 if (item.field_id === 'target_type') {
    //                     setTargetTypeArray(item)
    //                 }
    //             })
    //     }

    // }, [props.formFields]);

    const handleDeleteLocally = (index) => {
        deleteFlag = true;
        setEligibilityStore(item => item.filter((item, i) => i !== index));
    }

    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...eligibilityStore];
            props.submitValues(edited);
            deleteFlag = false;
        }
    }, [eligibilityStore])

    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={1} style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.selectRoot} >
                                        <FormLabel required className={classes.chargeBackLabel}>
                                            Commitment Type
                                        </FormLabel>
                                        <Select
                                            value={targetType}
                                            onChange={(e) => setTargetType(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>

                                            </MenuItem>
                                            {props.fieldData && props.fieldData['target_type'] && props.fieldData['target_type']['drop_down_values'] &&
                                                props.fieldData['target_type']['drop_down_values'].map(item => {
                                                    return (
                                                        <MenuItem value={item} key={item} >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel required className={classes.formDiv}>
                                        Commitment Value
                                    </FormLabel>
                                    <OutlinedInput
                                        value={targetValue}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setTargetValue(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 0 }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Valid From
                                        </FormLabel>
                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={validFrom}
                                                    onChange={handleValidFrom}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={props.startDate ? props.startDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 0 }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Valid to
                                        </FormLabel>
                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 10 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={validTo}
                                                    onChange={handleValidTo}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>

                                </Grid>
                                <Grid item md={2} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingBottom: 15 }} >
                                        {editMode ?
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<EditIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                onClick={() => handleSubmit(editIndex)}
                                                disabled={!targetType || !targetValue || !(validFrom && Moment(validFrom).isValid() && validTo && (Moment(validTo).isValid()) && (Moment(validTo) >= Moment(validFrom)) && (Moment(validFrom) >= Moment(props.startDate)))}
                                            >
                                            </Button>
                                            :
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<CheckIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                onClick={() => handleSubmit('')}
                                                disabled={!targetType || !targetValue || !(validFrom && Moment(validFrom).isValid() && validTo && (Moment(validTo).isValid()) && (Moment(validTo) >= Moment(validFrom)) && (Moment(validFrom) >= Moment(props.startDate)))}

                                            >
                                            </Button>
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DeleteForeverIcon />}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                root: classes.deleteIconContainer,
                                            }}
                                            onClick={handleRemove}
                                            style={{ marginLeft: 10 }}
                                        >
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="COMMITMENT RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {eligibilityStore && eligibilityStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' >Commitment Type</TableCell>
                                                <TableCell align='center'>Commitment Value</TableCell>
                                                <TableCell align='center' >Valid From</TableCell>
                                                <TableCell align='center' >Valid To</TableCell>
                                                <TableCell align='center'>Options</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {eligibilityStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={item.ID}>
                                                        <TableCell align='center'>{item.target_type}</TableCell>
                                                        <TableCell align='center'>{item.target_value}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton
                                                                onClick={() => editEligibilityRule(item, index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteLocally(index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <DeleteForeverIcon />
                                                            </IconButton>

                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
            {/* Eligibility Rules Table Ends here */}
        </div>
    );

});


export default connect(null, null, null, { forwardRef: true })(TargetRules);
