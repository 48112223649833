import React, { useEffect } from 'react';
import {
    IconButton,
    Button,
    Typography,
    Input,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Grid,
    OutlinedInput,
    FormLabel,
    ListItemText,
    Checkbox, useMediaQuery,
    Chip, Fab
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import { connect } from 'react-redux';
import { postApprovalGroup, onLoadingUserRoleAccessList, getApprovalGroupData, putApprovalGroup } from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        //  marginBottom: 23,
        // marginTop: -20,
        padding: '20px 16px',
    },
    container: {
        padding: 10
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    table: {
        marginRight: 15
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    rootButton: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 14,
        marginTop: '-10px'
    },
    textRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '93%',
        paddingLeft: 10,
        height: 35
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    spinner: {
        height: '100vh'
    },
}));

const ApprovalGroup = props => {
    useEffect(() => {
        props.onLoadingUserRoleAccessList();
        props.getApprovalGroupData()
    }, []);
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const [editId, setEditId] = React.useState('');
    const [userArray, setUserArray] = React.useState([]);
    const [approvalGroupName, setApprovalGroupName] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [listOpen, setListOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [usersId, setUsersId] = React.useState([]);
    const [approvalGroup, setApprovalGroup] = React.useState([]);
    const [userRoleAccess, setUserRoleAccess] = React.useState([]);
    const [sendEmail, setSendEmail] = React.useState('');
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [deleteItemId, setDeleteItemId] = React.useState(null);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const isAllSelected =
        userRoleAccess.length > 0 && selected.length === userRoleAccess.length;
    const handleApprovalGroupName = (e) => {
        setApprovalGroupName(e.target.value);
    }
    const handleUserName = (event) => {
        setUserArray(event.target.value);
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);

    useEffect(() => {
        if (props.userRoleAccessData) {
            // setUserRoleAccess(props.userRoleAccessData)
            // let newSelected = []
            // props.userRoleAccessData.map(item => {
            //     newSelected = newSelected.concat(item.email);
            // })
            setUsersId(props.userRoleAccessData)
        }
    }, [props.userRoleAccessData]);
    useEffect(() => {
        if (props.approvalGroupData) {
            setApprovalGroup(props.approvalGroupData)
        }
    }, [props.approvalGroupData]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const handleSubmit = () => {
        var formData = {
            "name": approvalGroupName,
            "send_to_emails": userArray,
            "users": userArray,
            "send_email": sendEmail
        }
        if (editId) {
            props.putApprovalGroup(formData, editId);
            setEditId('');
            setApprovalGroupName('');
            setUserArray([]);
            setSendEmail('')
        }
        else
            props.postApprovalGroup(formData);
        setOpen(false);
        setApprovalGroupName('');
        setUserArray([]);
        setSendEmail('')
    }
    const editApprovalGroup = (item) => {
        setOpen(true);
        setApprovalGroupName(item.name);
        setEditId(item.ID);
        setUserArray(item.send_to_emails)
        setSendEmail(item.send_email)
    }
    function cancelEdit() {
        setOpen(false)
        setEditId('');
        setApprovalGroupName('');
        setUserArray([]);
        setSendEmail('')
    }

    const openConfirmDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setConfirmDeleteOpen(true);
    };

    const closeConfirmDeleteDialog = () => {
        setDeleteItemId(null);
        setConfirmDeleteOpen(false);
    };

    const handleDelete = () => {
        props.putApprovalGroup('', deleteItemId, 'delete');
        closeConfirmDeleteDialog();
    };

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div>

                <div className={clsx(classes.root, className)}>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10, flex: 'right' }} > Approval Group </Typography>
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_approval_groups-' + sessionStorage.getItem('application')) &&
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                //    component={CustomRouterLink}
                                onClick={() => setOpen(true)}
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>}
                    </div>
                    <Dialog open={open} fullWidth
                        maxWidth="lg">
                        <div style={{ padding: 20 }}>
                            <Grid
                                item
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required>
                                    Approval Group Label
                                </FormLabel>
                                <OutlinedInput
                                    value={approvalGroupName}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleApprovalGroupName}
                                />
                            </Grid>
                        </div>
                        <div style={{ width: '90%', marginLeft: 22 }}>
                            <FormLabel classes={{ root: classes.formLabel }} required>
                                User Name
                            </FormLabel>
                            <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                input={<OutlinedInput id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                style={{ width: '100%', marginTop: 8, height: userArray.length > 7 ? userArray.length * 9 : 38 }}
                                value={userArray}
                                onChange={handleUserName}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                            >
                                {usersId && usersId.length > 0 && usersId.map((item) => (
                                    <MenuItem key={item.id} value={item.email}>
                                        <Checkbox
                                            color='primary'
                                            checked={userArray.indexOf(item.email) > -1}
                                        />
                                        <ListItemText primary={item.email} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div style={{ width: '90%', marginLeft: 22, marginTop: '1rem' }}>
                            <FormLabel className={classes.formLabel}>
                                Send Email
                            </FormLabel>
                            <Select
                                value={sendEmail}
                                onChange={(e) => setSendEmail(e.target.value)}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                <MenuItem value={'true'} key={'true'} style={{ textTransform: 'capitalize' }}> Yes </MenuItem>
                                <MenuItem value={'false'} key={'false'} style={{ textTransform: 'capitalize' }}> No </MenuItem>
                            </Select>
                        </div>
                        <DialogActions>
                            <Button onClick={cancelEdit} color="primary">
                                Cancel
                            </Button>
                            <Button color="primary" onClick={handleSubmit} disabled={userArray.length > 0 && approvalGroupName ? false : true}>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {approvalGroup && approvalGroup.records
                        && approvalGroup.records.length > 0 ?
                        <div style={{ margin: 15 }}>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead >
                                        <TableRow >
                                            <TableCell align='center' className={classes.tabHead}>Approval Group</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>User Name</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {approvalGroup.records.map(item => {
                                            return (
                                                <StyledTableRow>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}
                                                        style={{
                                                            width: '80px',

                                                        }}
                                                    >{item.name}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{
                                                        wordBreak: 'break-word',
                                                        whiteSpace: 'normal',
                                                        width: '500px',

                                                    }}>{item.send_to_emails.join(",")}</TableCell>
                                                    <TableCell align='center'
                                                        style={{
                                                            width: '20px',

                                                        }}
                                                    >
                                                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_approval_groups-' + sessionStorage.getItem('application')
                                                        ) && (
                                                                <>
                                                                    <IconButton
                                                                        onClick={() => editApprovalGroup(item)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        classes={{ root: classes.IconButton }}
                                                                        // onClick={() => props.putApprovalGroup('', item.ID, 'delete')}
                                                                        onClick={() => openConfirmDeleteDialog(item.ID)}
                                                                        size="large">
                                                                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        :
                        <Typography variant='h4' style={{ textAlign: 'center', padding: 15 }}>
                            There is no data to show now.
                        </Typography>
                    }
                </div>
            </div >
            <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog}>
                <div style={{ padding: 20 }}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this item?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDeleteDialog} variant='outlined' color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant='contained'
                            color="primary"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </LoadingOverlay >
    );
};

const mapDispatchToProps = dispatch => {
    return {
        postApprovalGroup: (data) => dispatch(postApprovalGroup(data)),
        putApprovalGroup: (data, editId, type) => dispatch(putApprovalGroup(data, editId, type)),
        onLoadingUserRoleAccessList: () => dispatch(onLoadingUserRoleAccessList()),
        getApprovalGroupData: () => dispatch(getApprovalGroupData())
    }
}

const mapStateToProps = state => {
    return {
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        loading: state.addMultipleConfigurationData.loading,
        userRoleAccessData: state.addMultipleConfigurationData.userRoleAccessData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalGroup);