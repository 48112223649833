import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel, Input, Checkbox, ListItemText, Button,
    Select, MenuItem, OutlinedInput, Tooltip
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown, Datepicker, LabelText, TextInputWithLabel, DropdownArray, MultiSelectDropdown, DropdownKeyValue } from '../../components/Inputs';
import { connect } from 'react-redux';
import { getDefaultValues, getDefaultValuesAllDataNew } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // border: '1px solid #EEEEEE',
        borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        // padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 15,
        height: 75,
        maxHeight: 75
    },
    error: {
        color: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 150
    },
    data: {
        color: theme.palette.text.grey,
        // marginTop: 5,
        width: '100%',
        alignItems: 'center',
        height: 36,
        border: '1px solid #E0E0E0',
        display: 'flex',
        padding: '8px 10px 9px 10px'
    },
    boxRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    boxGrid: {
        width: '100%'
    },
    inputTwoLine: {
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
        //marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'

    },
    formlabelContainer: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        marginBottom: 0

    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        // paddingBottom: 10
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    selectedItem: {
        // color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '0.5rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));
const HeaderFieldsContiner = forwardRef((props, ref) => {
    const currentApplication = sessionStorage.getItem('application')
    const contractCustomisationUpdation = useRef();
    const classes = useStyles();
    const { className } = props;
    //new fields
    const [calculationMethod, setCalculationMethod] = React.useState(currentApplication === 'Pricing' ? 'Price Difference' : '');
    const [incentiveRateType, setIncentiveRateType] = React.useState(currentApplication === 'Pricing' ? 'Contract' : '');
    const [tierType, setTierType] = React.useState('');
    const [tierEvaluationLevel, setTierEvaluationLevel] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application') ? sessionStorage.getItem('application') : '');
    const [contractType, setContractType] = React.useState('');
    const [firstTimeFlag, setFirstTimeFlag] = React.useState(true);
    const [contractGroup, setContractGroup] = React.useState('');
    const [internalDescription, setInternalDescription] = React.useState('');
    const [contractObjective, setContractObjective] = React.useState('');
    const [contractStrategy, setContractStrategy] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [validFrom, setValidFrom] = React.useState('');
    const [contractSubtype, setContractSubtype] = React.useState('');
    const [contractSubgroup, setContractSubgroup] = React.useState('');
    const [externalDescription, setExternalDescription] = React.useState('');
    const [approvedStatus, setApprovedStatus] = React.useState('');
    const [externalReferance, setExternalReferance] = React.useState('');
    const [validTo, setValidTo] = React.useState('');
    const [maximumAmountPeriod, setMaximumAmountPeriod] = React.useState('');
    const applicationRef = useRef(null);
    const contractTypeRef = useRef(null);
    const contractGroupRef = useRef(null);
    const internalDescriptionRef = useRef(null);
    const currencyRef = useRef(null);
    const internalReferanceRef = useRef(null);
    const validFromRef = useRef(null);
    const contractSubtypeRef = useRef(null);
    const contractSubgroupRef = useRef(null);
    const externalDescriptionRef = useRef(null);
    const approvedStatusRef = useRef(null);
    const externalReferanceRef = useRef(null);
    const validToRef = useRef(null);

    const [fieldData, setFieldData] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }


    const [target, setTarget] = React.useState('No');
    const [incentiveAmount, setIncentiveAmount] = React.useState('');

    const [postingsApproval, setPostingsApproval] = React.useState('');
    const [contractPurpose, setContractPurpose] = React.useState('');
    const [quotaLevel, setQuotaLevel] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [postingSchema, setPostingSchema] = React.useState(currentApplication === 'Pricing' ? 'No Postings' : '');
    const [calcRuleModifier, setCalcRuleModifier] = React.useState('No');
    const [legacyContractId, setLegacyContractId] = React.useState('');
    const [goLiveDate, setGoLiveDate] = React.useState('');
    const [flexFields1, setFlexFields1] = React.useState('');
    function handleFlexFields1(newValue) {
        setFlexFields1(newValue);
    }
    const [flexFields2, setFlexFields2] = React.useState('');
    function handleFlexFields2(newValue) {
        setFlexFields2(newValue);
    }
    const [flexFields3, setFlexFields3] = React.useState('');
    function handleFlexFields3(newValue) {
        setFlexFields3(newValue);
    }
    const [tierPeriod, setTierPeriod] = React.useState('');

    const [tierEvaluationLevelArray, setTierEvaluationLevelArray] = React.useState(['contract']);
    const [tierEvaluationLevelLabel, setTierEvaluationLevelLabel] = React.useState({ 'contract': 'Contract' });
    const [maximumAmountLevel, setMaximumAmountLevel] = React.useState('');
    const [evaluateSalesBundle, setEvaluateSalesBundle] = React.useState('No');
    const [contractDependency, setContractDependency] = React.useState('No');
    const [workflow, setWorkflow] = React.useState('');
    const [contractOrigin, setContractOrigin] = React.useState('');
    const [negotiatingOutcome, setNegotiatingOutcome] = React.useState('');
    const [negotiatingReason, setNegotiatingReason] = React.useState('');
    function handleTierPeriod(newValue) {
        setTierPeriod(newValue);
    }
    function handleWorkflow(newValue) {
        setWorkflow(newValue);
    }
    useEffect(() => {
        var appType = sessionStorage.getItem('application');
        if (appType == 'Supplier Rebate')
            props.onLoadingDefaultTier(sessionStorage.getItem('application'), 'Direct - Purchase Data');
        else if (appType == 'Supplier Chargeback')
            props.onLoadingDefaultTier(sessionStorage.getItem('application'), 'Indirect - Sales Data');
        else
            props.onLoadingDefaultTier(sessionStorage.getItem('application'), 'Direct - Sales Data');
    }, [])

    useEffect(() => {
        props.onChange(headerValues, headerValuesRequired, mandatroyFields);
        // if (localStorage.getItem('mode') === 'edit' && firstTimeFlag) {
        //     setFirstTimeFlag(false);
        // }
    });

    // useEffect(() => {
    //     props.onLoadingDefault();
    // }, []);
    useEffect(() => {
        if (props.dropdownTierData && props.dropdownTierData.records && props.dropdownTierData.records.length > 0) {
            if (props.dropdownTierData.records[0] && props.dropdownTierData.records[0].field_id === 'tier_evaluation_level') {
                if (props.dropdownTierData.records[0].drop_down_value_keys)
                    setTierEvaluationLevelArray(props.dropdownTierData.records[0].drop_down_value_keys);
                if (props.dropdownTierData.records[0].label_names)
                    setTierEvaluationLevelLabel(props.dropdownTierData.records[0].label_names);
                //setTierEvaluationLevelLabel({ 'contract': 'Contract', ...props.dropdownTierData.records[0].label_names });

            }
        }
    }, [props.dropdownTierData]);

    const [exclusiveFormula, setExclusiveFormula] = React.useState(false);

    var headerValues = {
        application_type: applicationType,
        contract_type: contractType,
        //calculation_schema: contractSubtype,
        contract_group: contractGroup,
        internal_description: internalDescription,
        contract_objective: contractObjective,
        contract_strategy: contractStrategy,
        currency: currency,
        valid_from: validFrom,
        contract_sub_group: contractSubgroup,
        external_description: externalDescription,
        approval_status: approvedStatus,
        external_reference: externalReferance,
        valid_to: validTo,
        contract_purpose: contractPurpose,
        source_data_type: sourceDataType,
        posting_schema: postingSchema,
        legacy_contract_id: legacyContractId,
        go_live_date: goLiveDate,
        negotiation_reason: negotiatingReason,
        negotiation_outcome: negotiatingOutcome,
        flex_fields_1: flexFields1,
        flex_fields_2: flexFields2,
        flex_fields_3: flexFields3,
        tier_period: tierPeriod,
        commitment_achievement: target,
        maximum_incentive_amount: incentiveAmount ? parseFloat(incentiveAmount) : 0,
        maximum_amount_level: maximumAmountLevel ? maximumAmountLevel : '',
        calculation_method: calculationMethod,
        incentive_rate_type: incentiveRateType,
        tier_type: tierType,
        tier_evaluation_level: tierEvaluationLevel,
        formula_exclusive: exclusiveFormula,
        evaluate_sales_bundle: evaluateSalesBundle,
        quota_level: quotaLevel,
        maximum_amount_period: maximumAmountPeriod,
        contract_dependency: contractDependency,
        calculation_rule_modifier: calcRuleModifier,
        workflow_name: workflow,
        contract_origin: contractOrigin,
    };
    var headerValuesRequired = {
        applicationType: applicationType,
        contractType: contractType,
        internalDescription: internalDescription,
        currency: currency,
        validFrom: validFrom,
        contractSubtype: contractSubtype,
        validTo: validTo,
        calculationMethod: calculationMethod
    };

    useImperativeHandle(ref, () => ({
        validationCheck() {
            if (!applicationRef.current.value || applicationRef.current.value === '')
                setApplicationType(false);
            if (!contractTypeRef.current.value || contractTypeRef.current.value === '')
                setContractType(false);
            if (!internalDescriptionRef.current.value || contractTypeRef.current.value === '')
                setInternalDescription(false);
            if (!currencyRef.current.value || contractTypeRef.current.value === '')
                setCurrency(false);
            if (!validFromRef.current.value || contractTypeRef.current.value === '')
                setValidFrom(false);
            if (!contractSubtypeRef.current.value || contractTypeRef.current.value === '')
                setContractSubtype(false);
            if (!validToRef.current.value || contractTypeRef.current.value === '')
                setValidTo(false);
        },
        getHeaderValues() {
            headerValues = {
                application_type: applicationRef.current.value,
                contract_type: contractTypeRef.current.value,
                contract_group: contractGroupRef.current.value,
                internal_description: internalDescriptionRef.current.value,
                currency: currencyRef.current.value,
                internal_reference: internalReferanceRef.current.value,
                valid_from: validFromRef.current.value,
                calculation_schema: contractSubtypeRef.current.value,
                contract_sub_group: contractSubgroupRef.current.value,
                external_description: externalDescriptionRef.current.value,
                approval_status: approvedStatusRef.current.value,
                external_reference: externalReferanceRef.current.value,
                valid_to: validToRef.current.value,
            };
            return headerValues;
        },
        getHeaderValuesRequired() {
            headerValuesRequired = {
                applicationType: applicationRef.current.value,
                contractType: contractTypeRef.current.value,
                internalDescription: internalDescriptionRef.current.value,
                currency: currencyRef.current.value,
                validFrom: validFromRef.current.value,
                contractSubtype: contractSubtypeRef.current.value,
                validTo: validToRef.current.value
            };
            return headerValuesRequired;
        }
    }));
    useEffect(() => {
        if (sessionStorage.getItem('application') == 'Supplier Chargeback')
            setContractSubtype('Price Difference');
    }, [])

    function handleContractType(newValue) {
        setContractType(newValue);
    }
    function handleContractGroup(newValue) {
        setContractGroup(newValue);
    }
    function handleInternalDescription(newValue) {
        setInternalDescription(newValue);
        setExternalDescription(newValue)
    }
    function handleContractObjective(newValue) {
        setContractObjective(newValue);
    }
    function handleContractStrategy(newValue) {
        setContractStrategy(newValue);
    }
    function handleContractOrigin(newValue) {
        setContractOrigin(newValue)
    }
    function handleCurrency(e) {
        setCurrency(e.target.value);
    }

    function handleValidFrom(newValue) {
        if (newValue && newValue.isValid())
            setValidFrom(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setValidFrom(newValue);
    }
    function handleContractSubgroup(newValue) {
        setContractSubgroup(newValue);
    }
    function handleExternalDescription(e) {
        setExternalDescription(e.target.value);
    }

    function handleValidTo(newValue) {
        setValidTo(newValue);
    }
    function handleContractPurpose(e) {
        setContractPurpose(e.target.value);
        if (e.target.value === 'Model')
            setPostingSchema('No Postings');
    }
    function handleSourceDataType(e) {
        setSourceDataType(e.target.value);
        setTierEvaluationLevelArray(['contract'])
        props.onLoadingDefaultTier(sessionStorage.getItem('application'), e.target.value);
    }
    function handlePostingSchema(e) {
        setPostingSchema(e.target.value);
        if (e.target.value === 'No Postings') {
            setPostingsApproval('No Approvals');
        }
        else {
            setPostingsApproval('');
        }
    }
    function handleLegacyContractId(newValue) {
        setLegacyContractId(newValue);
    }
    function handleGoLiveDate(newValue) {
        if (newValue && newValue.isValid())
            setGoLiveDate(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setGoLiveDate(newValue);
    }
    function handleNegotiatingReason(newValue) {
        setNegotiatingReason(newValue);
    }
    function handleNegotiatingOutcome(newValue) {
        setNegotiatingOutcome(newValue);
    }

    const [apiResponse, setApiResponse] = React.useState([]);
    useEffect(() => {
        if (props.queryListData && props.queryListData.records && props.queryListData.records.length > 0) {
            setApiResponse(props.queryListData.records);
        }
    }, [props.queryListData]);
    var executed = false;
    const [generlaDataListofFields, setGeneralDataListofFields] = React.useState(
        [
            // 'application_type',
            // 'approval_status',
            // 'external_reference',
            'contract_type',
            'internal_description',
            'contract_purpose',
            'currency',
            'valid_from',
            'valid_to',
            'posting_schema',
            'contract_group',
            'contract_sub_group',
            'contract_objective',
            'contract_strategy',
            'contract_origin',
            'workflow_name',

            'external_description',
            'flex_fields_1',
            'flex_fields_2',
            'flex_fields_3',
            'legacy_contract_id',
            'go_live_date',
            'negotiation_reason',
            'negotiation_outcome',

            'source_data_type',
            'calculation_method',
            'incentive_rate_type',



            // 'commitment_achievement',
            // 'contract_dependency',
            // 'calculation_rule_modifier',
            // 'formula_exclusive',
            // 'evaluate_sales_bundle',
            // 'maximum_amount_level',
            // 'maximum_amount_period',
            // 'maximum_incentive_amount',
            // 'tier_period',
            // 'tier_type',
            // 'tier_evaluation_level',
            // 'quota_level',
        ]
    )
    const [mandatroyFields, setManddatoryFields] = React.useState([])
    useEffect(() => {
        let tempArray = []
        if (props.fieldData && Object.values(props.fieldData) && Object.values(props.fieldData).length > 0) {
            Object.values(props.fieldData)
                .filter(item => generlaDataListofFields.includes(item.key))
                .map(item => {
                    if (item.mandatory) {
                        tempArray.push(item.key)
                    }
                })
        }
        setManddatoryFields(tempArray)
        setFieldData(props.fieldData)


    }, [props.fieldData, generlaDataListofFields]);
    useEffect(() => {
        if (props.contractDefaultData) {
            if (props.contractDefaultData.calculation_method && props.contractDefaultData.calculation_method.length > 0) {
                setCalculationMethod(props.contractDefaultData.calculation_method);
                if (props.contractDefaultData.calculation_method === 'Quota Achievement') {
                    // if (!generlaDataListofFields.includes('quota_level')) {
                    setGeneralDataListofFields(prevFields => ['quota_level', 'commitment_achievement',
                        'contract_dependency',
                        'calculation_rule_modifier',
                        'formula_exclusive',
                        'evaluate_sales_bundle',
                        'maximum_amount_level',
                        'maximum_amount_period',
                        'maximum_incentive_amount', ...prevFields]);
                    setGeneralDataListofFields(prevFields => prevFields.filter(item => ![
                        'tier_period',
                        'tier_type',
                        'tier_evaluation_level'
                    ].includes(item)));
                    // }
                }
                if (props.contractDefaultData.calculation_method === 'Tiered Growth') {
                    // if (!generlaDataListofFields.includes('tier_type')) {
                    setGeneralDataListofFields(prevFields => ['contract_dependency',
                        'calculation_rule_modifier',
                        'formula_exclusive',
                        'evaluate_sales_bundle',
                        'maximum_amount_level',
                        'maximum_amount_period',
                        'maximum_incentive_amount',
                        'tier_period',
                        'tier_type',
                        'tier_evaluation_level', ...prevFields]);
                    // }
                }
                if (props.contractDefaultData.calculation_method === 'Multi Axis') {
                    // if (!generlaDataListofFields.includes('tier_type')) {
                    setGeneralDataListofFields(prevFields => ['contract_dependency',
                        'calculation_rule_modifier',
                        'formula_exclusive',
                        'evaluate_sales_bundle',
                        'maximum_amount_level',
                        'maximum_amount_period',
                        'maximum_incentive_amount',
                        'tier_period',
                        'tier_type',
                        'tier_evaluation_level', ...prevFields]);
                    // }
                }
                if (props.contractDefaultData.calculation_method === 'Tiered Volume') {
                    // if (!generlaDataListofFields.includes('tier_type')) {
                    setGeneralDataListofFields(prevFields => ['contract_dependency',
                        'calculation_rule_modifier',
                        'formula_exclusive',
                        'evaluate_sales_bundle',
                        'maximum_amount_level',
                        'maximum_amount_period',
                        'maximum_incentive_amount',
                        'tier_period',
                        'tier_type',
                        'tier_evaluation_level', ...prevFields]);
                    // }
                }
                if (props.contractDefaultData.calculation_method === 'Price Point') {
                    // if (!generlaDataListofFields.includes('calculation_rule_modifier')) {
                    setGeneralDataListofFields(prevFields => ['calculation_rule_modifier', ...prevFields]);

                    setGeneralDataListofFields(prevFields => prevFields.filter(item => !['contract_dependency',
                        'formula_exclusive',
                        'evaluate_sales_bundle',
                        'maximum_amount_level',
                        'maximum_amount_period',
                        'maximum_incentive_amount',
                        'tier_period',
                        'tier_type',
                        'tier_evaluation_level'
                    ].includes(item)));

                    // }
                }
                if (props.contractDefaultData.calculation_method === 'Fixed Amount') {
                    // if (!generlaDataListofFields.includes('contract_dependency')) {
                    setGeneralDataListofFields(prevFields => ['commitment_achievement',
                        'contract_dependency',
                        'calculation_rule_modifier',
                        'formula_exclusive',
                        'evaluate_sales_bundle', ...prevFields]);

                    setGeneralDataListofFields(prevFields => prevFields.filter(item => ![
                        'maximum_amount_level',
                        'maximum_amount_period',
                        'maximum_incentive_amount',
                        'tier_period',
                        'tier_type',
                        'tier_evaluation_level'
                    ].includes(item)));

                    // }
                }
                if (props.contractDefaultData.calculation_method === 'Flat Rate') {
                    // if (!generlaDataListofFields.includes('contract_dependency')) {
                    setGeneralDataListofFields(prevFields => ['commitment_achievement',
                        'contract_dependency',
                        'calculation_rule_modifier',
                        'formula_exclusive',
                        'evaluate_sales_bundle',
                        'maximum_amount_level',
                        'maximum_amount_period',
                        'maximum_incentive_amount', ...prevFields]);
                    setGeneralDataListofFields(prevFields => prevFields.filter(item => ![
                        'tier_period',
                        'tier_type',
                        'tier_evaluation_level'
                    ].includes(item)));
                }
            }
            if (props.contractDefaultData.tier_type && props.contractDefaultData.tier_type.length > 0 && (props.contractDefaultData.calculation_method === 'Tiered Growth' || props.contractDefaultData.calculation_method === 'Tiered Volume' || props.contractDefaultData.calculation_method === 'Multi Axis')) {
                setTierType(props.contractDefaultData.tier_type);
            }
            setContractPurpose(props.contractDefaultData.contract_purpose);
            if (currentApplication === 'Pricing') {
                setPostingSchema('No Postings');
            } else {
                setPostingSchema(props.contractDefaultData.posting_schema);
            }
            setPostingsApproval(props.contractDefaultData.posting_approval);
            setCurrency(props.contractDefaultData.currency);
            setSourceDataType(props.contractDefaultData.source_data_type);
            setIncentiveRateType(props.contractDefaultData.incentive_rate_type);
            setExclusiveFormula(props.contractDefaultData.formula_exclusive === 'Yes' ? true : false)
            setWorkflow(props.contractDefaultData.workflow_name)
            if (props.contractDefaultData.source_data_type) {
                props.onLoadingDefaultTier(sessionStorage.getItem('application'), props.contractDefaultData.source_data_type);
            }
        } else {
            setContractPurpose('');
            if (currentApplication === 'Pricing') {
                setPostingSchema('No Postings');
            } else {
                setPostingSchema('');
            }
            setPostingsApproval('');
            setCurrency('');
            setSourceDataType('');
        }
    }, [props.contractDefaultData]);
    const handleTierType = (e) => {
        setTierType(e.target.value)
        if (calculationMethod === 'Tiered Growth' && e.target.value === 'Simple') {
            setContractSubtype('Tiered Growth - Simple')
        }
        if (calculationMethod === 'Tiered Growth' && e.target.value === 'Graduated') {
            setContractSubtype('Tiered Growth - Graduated')
        }
        if (calculationMethod === 'Tiered Volume' && e.target.value === 'Simple') {
            setContractSubtype('Tiered Growth - Simple')
        }
        if (calculationMethod === 'Tiered Volume' && e.target.value === 'Graduated') {
            setContractSubtype('Tiered Growth - Graduated')
        }
    }
    const handleIncentiveRateType = (e) => {
        setIncentiveRateType(e.target.value)
        //console.log(e.target.value === incentiveRateType)
        if (calculationMethod === 'Flat Rate' && e.target.value === 'Contract') {
            setContractSubtype('Flat % - One Rate per Contract')
        }
        if (calculationMethod === 'Flat Rate' && e.target.value === 'Attribute') {
            setContractSubtype('Flat % - Multiple Rates per Contract')
        }
        if (calculationMethod === 'Fixed Amount' && e.target.value === 'Contract') {
            setContractSubtype('Flat $ - One lumpsum Amount per Contract')
        }
        if (calculationMethod === 'Fixed Amount' && e.target.value === 'Attribute') {
            setContractSubtype('Flat $ - One lumpsum Amount per Contract')
        }
        if (calculationMethod === 'Price Difference' && e.target.value === 'Attribute') {
            setContractSubtype('Price Difference')
        }
        if (calculationMethod === 'Tiered Growth' && (e.target.value === 'Contract' || e.target.value === 'Attribute')) {
            setContractSubtype('Price Difference')
        }
    }
    const [calculationMethodDisable, setCalculationMethodDisable] = React.useState(currentApplication === 'Pricing' ? true : false);
    const [incentiveRateTypeDisable, setIncentiveRateTypeDisable] = React.useState(currentApplication === 'Pricing' ? true : false);

    useEffect(() => {
        if (calculationMethod && (props.eligibilityRules.length > 0 || props.calculationRules.length > 0)) {
            setCalculationMethodDisable(true);
        }
        if (incentiveRateType && (props.eligibilityRules.length > 0 || props.calculationRules.length > 0)) {
            setIncentiveRateTypeDisable(true);
        }
    }, [props.eligibilityRules, props.calculationRules]);
    const handleCalculationMethod = (e) => {
        setIncentiveRateType('');
        setMaximumAmountPeriod('')
        setCalculationMethod(e.target.value);
        if (e.target.value === 'Price Difference' || e.target.value === 'External Calculation') {
            setIncentiveRateType('Contract')
        }
        if (e.target.value === 'Price Difference' || e.target.value === 'External Calculation') {
            setExclusiveFormula('');
            setTarget('No');
            setEvaluateSalesBundle('');
            setMaximumAmountLevel('');
            setExternalDescription('');
            setFlexFields1('');
            setFlexFields2('');
            setFlexFields3('');
            setLegacyContractId('');
            setGoLiveDate('');
            setIncentiveAmount('');
        }
        if (e.target.value === 'Quota Achievement') {
            // if (!generlaDataListofFields.includes('quota_level')) {
            setGeneralDataListofFields(prevFields => ['quota_level', 'commitment_achievement',
                'contract_dependency',
                'calculation_rule_modifier',
                'formula_exclusive',
                'evaluate_sales_bundle',
                'maximum_amount_level',
                'maximum_amount_period',
                'maximum_incentive_amount', ...prevFields]);
            setGeneralDataListofFields(prevFields => prevFields.filter(item => ![
                'tier_period',
                'tier_type',
                'tier_evaluation_level'
            ].includes(item)));
            // }
        }
        if (e.target.value === 'Tiered Growth') {
            // if (!generlaDataListofFields.includes('tier_type')) {
            setGeneralDataListofFields(prevFields => ['contract_dependency',
                'calculation_rule_modifier',
                'formula_exclusive',
                'evaluate_sales_bundle',
                'maximum_amount_level',
                'maximum_amount_period',
                'maximum_incentive_amount',
                'tier_period',
                'tier_type',
                'tier_evaluation_level', ...prevFields]);
            // }
        }
        if (e.target.value === 'Multi Axis') {
            // if (!generlaDataListofFields.includes('tier_type')) {
            setGeneralDataListofFields(prevFields => ['contract_dependency',
                'calculation_rule_modifier',
                'formula_exclusive',
                'evaluate_sales_bundle',
                'maximum_amount_level',
                'maximum_amount_period',
                'maximum_incentive_amount',
                'tier_period',
                'tier_type',
                'tier_evaluation_level', ...prevFields]);
            // }
        }
        if (e.target.value === 'Tiered Volume') {
            // if (!generlaDataListofFields.includes('tier_type')) {
            setGeneralDataListofFields(prevFields => ['contract_dependency',
                'calculation_rule_modifier',
                'formula_exclusive',
                'evaluate_sales_bundle',
                'maximum_amount_level',
                'maximum_amount_period',
                'maximum_incentive_amount',
                'tier_period',
                'tier_type',
                'tier_evaluation_level', ...prevFields]);
            // }
        }
        if (e.target.value === 'Price Point') {
            // if (!generlaDataListofFields.includes('calculation_rule_modifier')) {
            setGeneralDataListofFields(prevFields => ['calculation_rule_modifier', ...prevFields]);

            setGeneralDataListofFields(prevFields => prevFields.filter(item => !['contract_dependency',
                'formula_exclusive',
                'evaluate_sales_bundle',
                'maximum_amount_level',
                'maximum_amount_period',
                'maximum_incentive_amount',
                'tier_period',
                'tier_type',
                'tier_evaluation_level'
            ].includes(item)));

            // }
        }
        if (e.target.value === 'Fixed Amount') {
            // if (!generlaDataListofFields.includes('contract_dependency')) {
            setGeneralDataListofFields(prevFields => ['commitment_achievement',
                'contract_dependency',
                'calculation_rule_modifier',
                'formula_exclusive',
                'evaluate_sales_bundle', ...prevFields]);

            setGeneralDataListofFields(prevFields => prevFields.filter(item => ![
                'maximum_amount_level',
                'maximum_amount_period',
                'maximum_incentive_amount',
                'tier_period',
                'tier_type',
                'tier_evaluation_level'
            ].includes(item)));

            // }
        }
        if (e.target.value === 'Flat Rate') {
            // if (!generlaDataListofFields.includes('contract_dependency')) {
            setGeneralDataListofFields(prevFields => ['commitment_achievement',
                'contract_dependency',
                'calculation_rule_modifier',
                'formula_exclusive',
                'evaluate_sales_bundle',
                'maximum_amount_level',
                'maximum_amount_period',
                'maximum_incentive_amount', ...prevFields]);
            setGeneralDataListofFields(prevFields => prevFields.filter(item => ![
                'tier_period',
                'tier_type',
                'tier_evaluation_level'
            ].includes(item)));
            // setGeneralDataListofFields(['commitment_achievement',
            //     'contract_dependency',
            //     'calculation_rule_modifier',
            //     'formula_exclusive',
            //     'evaluate_sales_bundle',
            //     'maximum_amount_level',
            //     'maximum_amount_period',
            //     'maximum_incentive_amount', ...generlaDataListofFields])
            // }
        }
    }

    useEffect(() => {
        if (calculationMethod && calculationMethod !== 'Flat Rate' && calculationMethod !== 'Fixed Amount' && calculationMethod !== 'Multi Axis') {
            setIncentiveRateType('Contract')
        }
        if (calculationMethod === 'Tiered Growth' || calculationMethod === 'Tiered Volume' || calculationMethod === 'Multi Axis')
            setTierEvaluationLevel(['contract'])
    }, [calculationMethod])
    const handleQuotaLevel = (data) => {
        if (data && data.length > 0) {
            setQuotaLevel(data)
        }
        else
            setQuotaLevel(quotaLevel)
    }

    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === tierEvaluationLevelArray.length ? [] : tierEvaluationLevelArray);
            setAllSelect(selected.length === tierEvaluationLevelArray.length ? false : true)

        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === tierEvaluationLevelArray.length ? true : false)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setTierEvaluationLevel(selected)
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(tierEvaluationLevel)
        setAllSelect(tierEvaluationLevelArray && tierEvaluationLevelArray.length > 0 && tierEvaluationLevel.length === tierEvaluationLevelArray.length)

    }
    function handleMaximumAmountLevel(value) {
        setMaximumAmountLevel(value)
        setMaximumAmountPeriod('')
        setIncentiveAmount('')
    }
    function handleMaximumAmountPeriod(value) {
        setMaximumAmountPeriod(value)
        setIncentiveAmount('')
    }
    return (
        <div className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="PRIMARY DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container}>
                        <Grid container
                            style={{ marginBottom: 16, marginTop: -5 }}
                        >
                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: -10 }}
                            >
                                <LabelText classes={{ root: classes.fontSetting }}
                                    heading={props.fieldData && props.fieldData['application_type'] && props.fieldData['application_type']['current'] ?
                                        props.fieldData['application_type']['current'] : 'Application Type'}
                                    data={sessionStorage.getItem('application')} twoline='true' border={true} />
                            </Grid>
                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <Grid container
                                    className={clsx(classes.boxRoot, className)}
                                >
                                    <Grid item classes={{ item: classes.boxGrid }}>
                                        <FormLabel {...props} classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['contract_number'] ? props.fieldData['contract_number']['mandatory'] : false} >
                                            {props.fieldData && props.fieldData['contract_number'] && props.fieldData['contract_number']['current'] ? props.fieldData['contract_number']['current'] : 'Contract Number'}
                                        </FormLabel>
                                        <div className={classes.data} >
                                            Auto Generated
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {

                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['contract_type'] ? props.fieldData['contract_type']['mandatory'] : false}
                                        heading={props.fieldData && props.fieldData['contract_type'] && props.fieldData['contract_type']['current'] ? props.fieldData['contract_type']['current'] : 'Contract Type'}
                                        placeholder={' '} twoline='true' {...props} onChange={handleContractType} data={props.fieldData && props.fieldData['contract_type'] && props.fieldData['contract_type']['drop_down_values']}
                                        error={typeof contractType === 'boolean' && !contractType ? true : false} ref={contractTypeRef} prevalue={props.datageneral ? props.datageneral.contract_type : ''}
                                        disabled={contractType && contractType.length > 0 ? true : false}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['internal_description'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }} style={{ marginTop: -5 }}
                                        mandatory={props.fieldData && props.fieldData['internal_description'] ? props.fieldData['internal_description']['mandatory'] : false}
                                        heading={props.fieldData['internal_description']['current'] ? props.fieldData && props.fieldData['internal_description']['current'] : 'Internal Description'}
                                        placeholder=' ' twoline='true' {...props} onChange={handleInternalDescription} error={typeof internalDescription === 'boolean' && !internalDescription ? true : false}
                                        ref={internalDescriptionRef} prevalue={props.datageneral ? props.datageneral.internal_description : ''} />

                                </Grid>
                            }

                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{
                                    paddingTop: 5,
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['contract_purpose'] ? props.fieldData['contract_purpose']['mandatory'] : false}>
                                        {props.fieldData && props.fieldData['contract_purpose'] && props.fieldData['contract_purpose']['current'] ?
                                            props.fieldData['contract_purpose']['current'] : 'Contract Purpose'}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={contractPurpose}
                                        onChange={handleContractPurpose}
                                        //displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData && props.fieldData['contract_purpose'] &&
                                            props.fieldData['contract_purpose']['drop_down_reference_value'] &&
                                            props.fieldData['contract_purpose']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                            {item.key}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </div>

                            </Grid>

                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{
                                    paddingTop: 5,
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['currency'] ? props.fieldData['currency']['mandatory'] : false}>
                                        {props.fieldData && props.fieldData['currency'] && props.fieldData['currency']['current'] ? props.fieldData['currency']['current'] : 'Currency'}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={currency}
                                        onChange={handleCurrency}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                        style={{ marginTop: -5 }}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData && props.fieldData['currency'] && props.fieldData['currency']['drop_down_reference_value'] && props.fieldData['currency']['drop_down_reference_value']
                                            && props.fieldData['currency']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                            {item.key}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </div>
                            </Grid>

                            {props.fieldData && props.fieldData['valid_from'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['valid_from'] ? props.fieldData['valid_from']['mandatory'] : false}
                                        heading={props.fieldData['valid_from']['current'] ? props.fieldData['valid_from']['current'] : 'Valid From'}
                                        twoline='true'{...props} onChange={handleValidFrom} error={typeof validFrom === 'boolean' && !validFrom ? true : false} ref={validFromRef}
                                        prevalue={props.datageneral && props.datageneral.valid_from != '0001-01-01T00:00:00Z' ? props.datageneral.valid_from : ''} maximum={validTo} />
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['valid_to'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['valid_to'] ? props.fieldData['valid_to']['mandatory'] : false}
                                        heading={props.fieldData['valid_to']['current'] ? props.fieldData['valid_to']['current'] : 'Valid To'} twoline='true' {...props}
                                        onChange={handleValidTo} error={typeof validTo === 'boolean' && !validTo ? true : false} minimum={validFrom} ref={validToRef}
                                        prevalue={props.datageneral && props.datageneral.valid_from != '0001-01-01T00:00:00Z' ? props.datageneral.valid_to : ''} />
                                </Grid>
                            }
                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{
                                    //paddingTop: 5,
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['posting_schema'] ? props.fieldData['posting_schema']['mandatory'] : false}>
                                        {props.fieldData && props.fieldData['posting_schema'] &&
                                            props.fieldData['posting_schema']['current'] ? props.fieldData['posting_schema']['current'] : 'Posting Schema'
                                        }
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={postingSchema}
                                        defaultValue={postingSchema}
                                        onChange={handlePostingSchema}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                        disabled={currentApplication === 'Pricing' ? true : false}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData && props.fieldData['posting_schema'] &&
                                            props.fieldData['posting_schema']['drop_down_reference_value'] &&
                                            props.fieldData['posting_schema']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} >
                                                            {item.key}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </div>

                            </Grid>

                            {props.fieldData && props.fieldData['contract_group'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['contract_group']['current']} placeholder={' '}
                                        required={props.fieldData && props.fieldData['contract_group'] ? props.fieldData['contract_group']['mandatory'] : false}
                                        data={props.fieldData['contract_group']['drop_down_values'] ? props.fieldData['contract_group']['drop_down_values'] : []}
                                        twoline='true' onChange={handleContractGroup} ref={contractGroupRef} prevalue={props.datageneral ? props.datageneral.contract_group : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_sub_group'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['contract_sub_group']['current']} placeholder={' '} twoline='true' onChange={handleContractSubgroup}
                                        required={props.fieldData && props.fieldData['contract_sub_group'] ? props.fieldData['contract_sub_group']['mandatory'] : false}
                                        data={props.fieldData['contract_sub_group']['drop_down_values'] ? props.fieldData['contract_sub_group']['drop_down_values'] : []}
                                        ref={contractSubgroupRef} prevalue={props.datageneral ? props.datageneral.contract_sub_group : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_objective'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['contract_objective']['drop_down_reference_value'] && props.fieldData['contract_objective']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['contract_objective'] ? props.fieldData['contract_objective']['mandatory'] : false}>
                                                {props.fieldData['contract_objective']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={contractObjective}
                                                onChange={(e) => setContractObjective(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['contract_objective']['drop_down_reference_value'] && props.fieldData['contract_objective']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['contract_objective']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} style={{ marginTop: -5 }}
                                            mandatory={props.fieldData && props.fieldData['contract_objective'] ? props.fieldData['contract_objective']['mandatory'] : false}
                                            heading={props.fieldData['contract_objective']['current'] ? props.fieldData && props.fieldData['contract_objective']['current'] : 'Contract Objective'}
                                            placeholder=' ' twoline='true' {...props} onChange={handleContractObjective}
                                            prevalue={props.datageneral ? props.datageneral.contract_objective : ''} />
                                    }

                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_strategy'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['contract_strategy']['drop_down_reference_value'] && props.fieldData['contract_strategy']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['contract_strategy'] ? props.fieldData['contract_strategy']['mandatory'] : false}>
                                                {props.fieldData['contract_strategy']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={contractStrategy}
                                                onChange={(e) => setContractStrategy(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['contract_strategy']['drop_down_reference_value'] && props.fieldData['contract_strategy']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['contract_strategy']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} style={{ marginTop: -5 }}
                                            mandatory={props.fieldData && props.fieldData['contract_strategy'] ? props.fieldData['contract_strategy']['mandatory'] : false}
                                            heading={props.fieldData['contract_strategy']['current'] ? props.fieldData && props.fieldData['contract_strategy']['current'] : 'Contract Strategy'}
                                            placeholder=' ' twoline='true' {...props} onChange={handleContractStrategy}
                                            prevalue={props.datageneral ? props.datageneral.contract_strategy : ''} />
                                    }

                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_origin'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['contract_origin']['drop_down_reference_value'] && props.fieldData['contract_origin']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['contract_origin'] ? props.fieldData['contract_origin']['mandatory'] : false} >
                                                {props.fieldData['contract_origin']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={contractOrigin}
                                                onChange={(e) => setContractOrigin(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['contract_origin']['drop_down_reference_value'] && props.fieldData['contract_origin']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['contract_origin']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} style={{ marginTop: -5 }}
                                            mandatory={props.fieldData && props.fieldData['contract_origin'] ? props.fieldData['contract_origin']['mandatory'] : false}
                                            heading={props.fieldData['contract_origin']['current'] ? props.fieldData && props.fieldData['contract_origin']['current'] : 'Contract Origin'}
                                            placeholder=' ' twoline='true' {...props} onChange={handleContractOrigin}
                                            prevalue={props.datageneral ? props.datageneral.contract_origin : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['workflow_name'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.formLabel }} labelFormat={'desc'}
                                        required={props.fieldData && props.fieldData['workflow_name'] ? props.fieldData['workflow_name']['mandatory'] : false}
                                        heading={props.fieldData && props.fieldData['workflow_name'] && props.fieldData['workflow_name']['current'] ? props.fieldData['workflow_name']['current'] : 'Workflow Name'}
                                        twoline='true' onChange={handleWorkflow} data={props.fieldData['workflow_name']['drop_down_reference_value']} prevalue={workflow} />
                                </Grid>
                            }

                        </Grid>
                    </div>
                </form>
            </Card>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="CALCULATION DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container}>
                        <Grid container
                            style={{ marginBottom: 16, marginTop: 10 }}
                        >


                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            // style={{ marginBottom:10}}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['source_data_type'] ? props.fieldData['source_data_type']['mandatory'] : false}>
                                    {props.fieldData && props.fieldData['source_data_type'] && props.fieldData['source_data_type']['current'] ?
                                        props.fieldData['source_data_type']['current'] : 'Source Data Type'}
                                </FormLabel>
                                <Select
                                    disableUnderline
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    className={clsx({
                                        [classes.select]: true,
                                        [classes.error]: props.error
                                    })}
                                >
                                    <MenuItem value="" style={{ height: 30 }}>
                                    </MenuItem>

                                    {props.fieldData && props.fieldData['source_data_type'] && props.fieldData['source_data_type']['drop_down_reference_value'] &&
                                        props.fieldData['source_data_type']['drop_down_reference_value']
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                        {item.key}
                                                    </MenuItem>
                                                )
                                            })
                                    }
                                </Select>
                            </Grid>


                            {props.fieldData && props.fieldData['calculation_method'] &&//calculationMethodArray &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{marginBottom:10}}
                                ><Tooltip arrow title={<h3 //style={{ color: "lightblue" }}
                                >This controls how calculation is performed e.g., Tiered Volume uses single tier and Multi Axis perform tier calculation for multi tiers.</h3>}
                                // title='This controls how calculation is performed e.g., Tiered Volume uses single tier and Multi Axis perform tier calculation for multi tiers.'
                                >
                                        <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['calculation_method'] ? props.fieldData['calculation_method']['mandatory'] : false}>
                                            {props.fieldData['calculation_method']['current']}
                                        </FormLabel>
                                    </Tooltip>
                                    <Select
                                        disableUnderline
                                        value={calculationMethod}
                                        onChange={handleCalculationMethod}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                        disabled={calculationMethodDisable}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData['calculation_method']['drop_down_reference_value'] && props.fieldData['calculation_method']['drop_down_reference_value'].length > 0 &&
                                            props.fieldData['calculation_method']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }} >
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['incentive_rate_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{marginBottom:10}}
                                >

                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['incentive_rate_type'] ? props.fieldData['incentive_rate_type']['mandatory'] : false}>
                                        {props.fieldData && props.fieldData['incentive_rate_type'] && props.fieldData['incentive_rate_type']['current'] ? props.fieldData['incentive_rate_type']['current'] : 'Incentive Rate Type'}
                                    </FormLabel>
                                    <Select
                                        key={incentiveRateType}
                                        disableUnderline
                                        value={incentiveRateType}
                                        onChange={handleIncentiveRateType}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                        disabled={

                                            (calculationMethod && calculationMethod !== 'Flat Rate' && calculationMethod !== 'Fixed Amount' && calculationMethod !== 'Multi Axis')
                                                ?
                                                true
                                                : false}
                                    // incentiveRateTypeDisable}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData['incentive_rate_type']['drop_down_reference_value'].length > 0 &&
                                            props.fieldData['incentive_rate_type']['drop_down_reference_value']
                                                .filter(item => !((calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference' || calculationMethod === 'Multi Axis' ||
                                                    calculationMethod === 'Tiered Growth' || calculationMethod === 'Price Point' || calculationMethod === 'External Calculation')
                                                    && item.key === 'Attribute') && !(calculationMethod != 'Multi Axis' && item.key === "Conditional Attribute"))
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}
                                                            disabled={
                                                                (calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference')
                                                                    && item.key === 'Attribute'
                                                                    ? true :
                                                                    false

                                                            }
                                                        >
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>

                                </Grid>
                            }
                            {/* {props.fieldData && props.fieldData['incentive_rate_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['incentive_rate_type'] ? props.fieldData['incentive_rate_type']['mandatory'] : false}>
                                        {props.fieldData && props.fieldData['incentive_rate_type'] &&
                                            props.fieldData['incentive_rate_type']['current'] ?
                                            props.fieldData['incentive_rate_type']['current'] : 'Incentive Rate Type'
                                        }
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={incentiveRateType}
                                        onChange={handleIncentiveRateType}
                                        // displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        disabled={

                                            (calculationMethod && calculationMethod !== 'Flat Rate' && calculationMethod !== 'Fixed Amount' && calculationMethod !== 'Multi Axis')
                                                ?
                                                true
                                                : false}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData && props.fieldData['incentive_rate_type'] && props.fieldData['incentive_rate_type']['drop_down_reference_value'].length > 0 &&
                                            props.fieldData['incentive_rate_type']['drop_down_reference_value']
                                                .filter(item => !((calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference' || calculationMethod === 'Multi Axis' ||
                                                    calculationMethod === 'Tiered Growth' || calculationMethod === 'Price Point' || calculationMethod === 'External Calculation')
                                                    && item.key === 'Attribute') && !(calculationMethod != 'Multi Axis' && item.key === "Conditional Attribute"))
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}
                                                            disabled={
                                                                (calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference')
                                                                    && item.key === 'Attribute'
                                                                    ? true :
                                                                    false

                                                            }
                                                        >
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>

                                </Grid>
                            } */}
                            {(calculationMethod === 'Tiered Growth' || calculationMethod === 'Tiered Volume' || calculationMethod === 'Multi Axis') &&
                                props.fieldData && props.fieldData['tier_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{marginBottom:10}}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['tier_type'] ? props.fieldData['tier_type']['mandatory'] : false}>
                                        {props.fieldData && props.fieldData['tier_type'] && props.fieldData['tier_type']['current'] ? props.fieldData['tier_type']['current'] : 'Tier Type'}
                                    </FormLabel>
                                    <Select
                                        disabled={!calculationMethod ? true : false}
                                        disableUnderline
                                        value={tierType}
                                        onChange={handleTierType}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData && props.fieldData['tier_type'] && props.fieldData['tier_type']['drop_down_reference_value'] && props.fieldData['tier_type']['drop_down_reference_value'].length > 0 &&
                                            props.fieldData['tier_type']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                            {item.key}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                            }
                            {(calculationMethod === 'Tiered Growth' || calculationMethod === 'Tiered Volume' || calculationMethod === 'Multi Axis') &&
                                props.fieldData && props.fieldData['tier_period']
                                ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{paddingTop:5}}
                                >
                                    <DropdownKeyValue classes={{ root: classes.formLabel }} labelFormat={'desc'}
                                        required={props.fieldData && props.fieldData['tier_period'] ? props.fieldData['tier_period']['mandatory'] : false}
                                        heading={props.fieldData && props.fieldData['tier_period'] && props.fieldData['tier_period']['current'] ? props.fieldData['tier_period']['current'] : 'Tier Period'}
                                        twoline='true' onChange={handleTierPeriod} data={props.fieldData['tier_period']['drop_down_reference_value']} />
                                </Grid>
                                :
                                ''
                            }
                            {(calculationMethod === 'Tiered Growth' || calculationMethod === 'Tiered Volume' || calculationMethod === 'Multi Axis') &&
                                props.fieldData && props.fieldData['tier_evaluation_level'] &&//contractData['tier_evaluation_level']&&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingBottom: 0 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['tier_evaluation_level'] ? props.fieldData['tier_evaluation_level']['mandatory'] : false}>
                                        {props.fieldData['tier_evaluation_level'] && props.fieldData['tier_evaluation_level']['current'] ?
                                            props.fieldData['tier_evaluation_level']['current'] : 'Tier Evaluation Level'}
                                    </FormLabel>

                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        multiple
                                        value={tierEvaluationLevel ? tierEvaluationLevel : []}
                                        style={{ maxHeight: "50px", width: '100%', marginTop: 0, textTransform: 'capitalize' }}
                                        input={<OutlinedInput sx={{ padding: '4px' }} />}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={() => handleOpen()}
                                        onClose={() => setSelectOpen(false)}
                                        open={selectOpen}
                                        renderValue={(appType) => {
                                            var temp = []
                                            appType && appType.map((item, i) => {
                                                if (tierEvaluationLevelLabel && tierEvaluationLevelLabel[item]) {
                                                    temp.push(tierEvaluationLevelLabel[item])
                                                }
                                                else {
                                                    temp.push(item.toString().replace(/_/g, ' '))
                                                }
                                            })
                                            return temp.join(",")
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize', display: 'flex', flexDirection: 'row' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={allSelect} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {tierEvaluationLevelArray && tierEvaluationLevelArray.length > 0 && tierEvaluationLevelLabel &&
                                            Object.entries(tierEvaluationLevelLabel)
                                                .map(([k, v]) => {
                                                    return (

                                                        <MenuItem onClick={(event) => handleSelectValues(event, k)} value={k} key={k} style={{ textTransform: 'capitalize' }}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected.indexOf(k) > -1} />
                                                            <ListItemText primary={v} />{/*item != 'contract' && checkLabel(item) ? checkLabel(item) : item.replace(/_/g, ' ')*/}
                                                        </MenuItem>
                                                    )
                                                })}
                                        <MenuItem value=' ' disabled></MenuItem>
                                        <div className={classes.dropdownAction}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </Grid>
                            }
                            {calculationMethod === 'Quota Achievement' && props.fieldData && props.fieldData['quota_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <MultiSelectDropdown classes={{ root: classes.formlabelContainer }}
                                        required={props.fieldData && props.fieldData['quota_level'] ? props.fieldData['quota_level']['mandatory'] : false}
                                        heading={props.fieldData['quota_level']['current']}
                                        listArray={props.fieldData['quota_level']['drop_down_reference_value'] &&
                                            props.fieldData['quota_level']['drop_down_reference_value'].map(item => item.key)}
                                        data={quotaLevel} id='quota_level' onChange={handleQuotaLevel} capitalize
                                    />
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && props.fieldData && props.fieldData['commitment_achievement'] &&
                                calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingBottom: 0 }}
                                //style={{marginBottom:10}}
                                >

                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['commitment_achievement'] ? props.fieldData['commitment_achievement']['mandatory'] : false}>
                                        {props.fieldData['commitment_achievement']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={target}
                                        onChange={(e) => setTarget(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value={'No'} key={'No'} >No</MenuItem>
                                        <MenuItem value={'Yes - All'} key={'Yes - All'}>Yes - All</MenuItem>
                                        <MenuItem value={'Yes - Any'} key={'Yes - Any'}>Yes - Any</MenuItem>
                                    </Select>
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                props.fieldData && props.fieldData['contract_dependency'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingBottom: 0 }}
                                >

                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['contract_dependency'] ? props.fieldData['contract_dependency']['mandatory'] : false} >
                                        {props.fieldData['contract_dependency']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={contractDependency}
                                        onChange={(e) => setContractDependency(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        {props.fieldData['contract_dependency']['drop_down_reference_value'] && props.fieldData['contract_dependency']['drop_down_reference_value'].length > 0 &&
                                            props.fieldData['contract_dependency']['drop_down_reference_value']
                                                .map(item => (
                                                    <MenuItem value={item.key} key={item.key} >
                                                        {item.key}
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['calculation_rule_modifier'] && calculationMethod != 'External Calculation' && calculationMethod != 'Price Difference' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['calculation_rule_modifier'] ? props.fieldData['calculation_rule_modifier']['mandatory'] : false}
                                        heading={props.fieldData && props.fieldData['calculation_rule_modifier'] && props.fieldData['calculation_rule_modifier']['current'] ? props.fieldData['calculation_rule_modifier']['current'] : 'Calculation Rule Modifier'}
                                        placeholder={' '} twoline='true' {...props} onChange={(value) => setCalcRuleModifier(value)}
                                        data={['Yes', 'No']} prevalue={calcRuleModifier}
                                    />
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && props.fieldData && props.fieldData['formula_exclusive'] &&
                                calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{marginBottom:10}}
                                >

                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['formula_exclusive'] ? props.fieldData['formula_exclusive']['mandatory'] : false}  >
                                        {props.fieldData['formula_exclusive']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={exclusiveFormula}
                                        onChange={(e) => setExclusiveFormula(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value={false} key={false} >No</MenuItem>
                                        <MenuItem value={true} key={true}>Yes</MenuItem>
                                    </Select>
                                </Grid>
                            }

                            {calculationMethod != 'Price Point' && props.fieldData && props.fieldData['evaluate_sales_bundle']
                                && calculationMethod != 'Price Difference' && calculationMethod != 'External Calculation' &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{marginBottom:10,marginTop:10}}
                                >

                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['evaluate_sales_bundle'] ? props.fieldData['evaluate_sales_bundle']['mandatory'] : false} >
                                        {props.fieldData['evaluate_sales_bundle']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={evaluateSalesBundle}
                                        onChange={(e) => setEvaluateSalesBundle(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        <MenuItem value='' key=''>
                                        </MenuItem>
                                        {props.fieldData['evaluate_sales_bundle']['drop_down_reference_value'] && props.fieldData['evaluate_sales_bundle']['drop_down_reference_value'].length > 0
                                            && props.fieldData['evaluate_sales_bundle']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                            {item.key}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Fixed Amount' && calculationMethod != 'Price Difference' &&
                                calculationMethod != 'External Calculation' && props.fieldData && props.fieldData['maximum_amount_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{marginBottom:10}}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['maximum_amount_level'] ? props.fieldData['maximum_amount_level']['mandatory'] : false}>
                                        {props.fieldData['maximum_amount_level']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={maximumAmountLevel}
                                        onChange={(e) => handleMaximumAmountLevel(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ textTransform: 'capitalize' }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value="" key="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData['maximum_amount_level']['drop_down_reference_value'] &&
                                            props.fieldData['maximum_amount_level']['drop_down_reference_value'].length > 0
                                            && props.fieldData['maximum_amount_level']['drop_down_reference_value'].map((item) => {
                                                return (
                                                    <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                        {item.desc}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                            }

                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Difference' && calculationMethod != 'Fixed Amount' &&
                                calculationMethod != 'External Calculation' && props.fieldData && props.fieldData['maximum_amount_period'] && maximumAmountLevel != '' ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{marginBottom:10}}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['maximum_amount_period'] ? props.fieldData['maximum_amount_period']['mandatory'] : false}>
                                        {props.fieldData['maximum_amount_period']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={maximumAmountPeriod}
                                        onChange={(e) => handleMaximumAmountPeriod(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value="" key="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {props.fieldData['maximum_amount_period']['drop_down_reference_value'] && props.fieldData['maximum_amount_period']['drop_down_reference_value'].length > 0 &&
                                            props.fieldData['maximum_amount_period']['drop_down_reference_value']
                                                .filter(item => (!(maximumAmountLevel != 'customer_number' && item == 'Payment Period - Different Amount') && !((calculationMethod !== 'Tiered Volume' && calculationMethod !== 'Multi Axis' && calculationMethod !== 'Tiered Growth')
                                                    && item == 'Payment Period - Tier')))
                                                .map(item => (
                                                    <MenuItem value={item.key} key={item.key} >
                                                        {item.key}
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </Grid>
                                :
                                null
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Difference' && calculationMethod != 'Fixed Amount' &&
                                calculationMethod != 'External Calculation' && props.fieldData && props.fieldData['maximum_incentive_amount'] && maximumAmountPeriod === 'Payment Period - One Amount' && maximumAmountPeriod != '' ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{marginBottom:10}}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['maximum_incentive_amount'] ? props.fieldData['maximum_incentive_amount']['mandatory'] : false}
                                        heading={props.fieldData['maximum_incentive_amount']['current']}
                                        twoline='true' onChange={(e) => setIncentiveAmount(e)} />
                                </Grid>
                                :
                                null
                            }
                            {calculationMethod != 'Price Point' && calculationMethod != 'Price Difference' && calculationMethod != 'Fixed Amount' &&
                                calculationMethod != 'External Calculation' && props.fieldData && props.fieldData['maximum_incentive_amount'] && maximumAmountPeriod === 'Contract Period - One Amount' && maximumAmountPeriod != '' ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                // style={{marginBottom:10}}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['maximum_incentive_amount'] ? props.fieldData['maximum_incentive_amount']['mandatory'] : false}
                                        heading={props.fieldData['maximum_incentive_amount']['current']}
                                        twoline='true' onChange={(e) => setIncentiveAmount(e)} />
                                </Grid>
                                :
                                null
                            }


                        </Grid>
                    </div>
                </form>
            </Card>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="ADDITIONAL DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container}>
                        <Grid container
                            style={{ marginBottom: 16 }}
                        >
                            {props.fieldData && props.fieldData['external_description'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['external_description'] ? props.fieldData['external_description']['mandatory'] : false}>
                                        {props.fieldData['external_description']['current']}
                                    </FormLabel>
                                    <OutlinedInput
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleExternalDescription}
                                        value={externalDescription}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['negotiation_reason'] &&
                                < Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['negotiation_reason'] ? props.fieldData['negotiation_reason']['mandatory'] : false}
                                        heading={props.fieldData['negotiation_reason']['current']} placeholder={' '} data={props.fieldData['negotiation_reason']['drop_down_values']} twoline='true'
                                        onChange={handleNegotiatingReason} prevalue={props.data && props.data.datageneral ? props.data.datageneral.negotiation_reason : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['negotiation_outcome'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['negotiation_outcome']['current']}
                                        required={props.fieldData && props.fieldData['negotiation_outcome'] ? props.fieldData['negotiation_outcome']['mandatory'] : false}
                                        placeholder={' '} data={props.fieldData['negotiation_outcome']['drop_down_values']} twoline='true' onChange={handleNegotiatingOutcome}
                                        prevalue={props.data && props.data.datageneral ? props.data.datageneral.negotiation_outcome : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_1'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_1']['drop_down_reference_value'] && props.fieldData['flex_fields_1']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['flex_fields_1'] ? props.fieldData['flex_fields_1']['mandatory'] : false} >
                                                {props.fieldData['flex_fields_1']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields1}
                                                onChange={(e) => setFlexFields1(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_1']['drop_down_reference_value'] && props.fieldData['flex_fields_1']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_1']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            mandatory={props.fieldData && props.fieldData['flex_fields_1'] ? props.fieldData['flex_fields_1']['mandatory'] : false}
                                            heading={props.fieldData['flex_fields_1']['current']} placeholder={' '} twoline='true' onChange={handleFlexFields1} />}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_2'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_2']['drop_down_reference_value'] && props.fieldData['flex_fields_2']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['flex_fields_2'] ? props.fieldData['flex_fields_2']['mandatory'] : false} >
                                                {props.fieldData['flex_fields_2']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields2}
                                                onChange={(e) => setFlexFields2(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_2']['drop_down_reference_value'] && props.fieldData['flex_fields_2']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_2']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            mandatory={props.fieldData && props.fieldData['flex_fields_2'] ? props.fieldData['flex_fields_2']['mandatory'] : false}
                                            heading={props.fieldData['flex_fields_2']['current']} placeholder={' '} twoline='true' onChange={handleFlexFields2} />}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_3'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_3']['drop_down_reference_value'] && props.fieldData['flex_fields_3']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['flex_fields_3'] ? props.fieldData['flex_fields_3']['mandatory'] : false}>
                                                {props.fieldData['flex_fields_3']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields3}
                                                onChange={(e) => setFlexFields3(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_3']['drop_down_reference_value'] && props.fieldData['flex_fields_3']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_3']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </>
                                        :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            mandatory={props.fieldData && props.fieldData['flex_fields_3'] ? props.fieldData['flex_fields_3']['mandatory'] : false}
                                            heading={props.fieldData['flex_fields_3']['current']} placeholder={' '} twoline='true' onChange={handleFlexFields3} />}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['legacy_contract_id'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['legacy_contract_id'] ? props.fieldData['legacy_contract_id']['mandatory'] : false}
                                        heading={props.fieldData['legacy_contract_id']['current']} placeholder={' '} twoline='true' onChange={handleLegacyContractId} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['go_live_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['go_live_date'] ? props.fieldData['go_live_date']['mandatory'] : false}
                                        heading={props.fieldData['go_live_date']['current']} twoline='true' {...props} onChange={handleGoLiveDate} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>

        </div >
    );

});

const mapStateToProps = state => ({
    datageneral: state.addContractData.contractGeneralData,
    //fieldData: state.addMultipleConfigurationData.multipleConfigurationData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    dropdownTierData: state.addMultipleConfigurationData.tierEvaluationData,
});

const mapDispatchToProps = dispatch => {
    return {
        //onLoadingDefault: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), null, null, null, 'Contract Designer')),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        onLoadingDefaultTier: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'tier_evaluation_level')),

    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(HeaderFieldsContiner);