import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery, IconButton, Tooltip
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { Edit, Details } from '@mui/icons-material';
import MaterialTable from 'material-table';
import Moment from 'moment';
import { getPricePolicyExceptionsReport, runPriceCalcForSimulation } from '../../../redux/actions';
import { tableIcons } from '../../../components/Icons/TableIcons';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    }
}));

const PricePolicyExceptionsReport = props => {
    useEffect(() => {
        props.getConfigData(1, 1000);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [configData, setConfigData] = React.useState([]);
    const [salesOrg, setSaleOrg] = React.useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getConfigData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getConfigData(1, event.target.value);
    };
    useEffect(() => {
        var tempRows = [];
        if (props.configData && props.configData.records) {
            setConfigData(props.configData.records);
            var active = props.configData.records.filter(e => e.sales_organization).length > 0
            setSaleOrg(active)
            props.configData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        customer_number: e.customer_number,
                        material_number: e.material_number,
                        pricing_date: e.pricing_date,
                        calculation_simulation_number: e.calculation_simulation_number,
                        margin_price: e.margin_price,
                        margin_price_percentage: e.margin_price_percentage,
                        margin_target_percentage: e.margin_target_percentage,
                        market_price: e.market_price,
                        market_price_percentage: e.market_price_percentage,
                        market_target_percentage: e.market_target_percentage,
                        floor_price: e.floor_price,
                        invoice_price: e.invoice_price,
                        simulation_category: e.simulation_category,
                        formula_name: e.formula_name,
                        sales_organization: e.sales_organization
                    });
                })
            setCount(props.configData.total_record)
            setDataRows(tempRows);
        }
    }, [props.configData]);

    const viewQuote = (item) => {
        console.log(item)
        var data = {
            id: item.id,
            simulation_category: item.simulation_category
        }
        props.calculateQuote(data, item.calculation_simulation_number, item.formula_name)
    }
    const columns = [
        ...(salesOrg ? [{
            field: 'sales_organization',
            title: 'Sales Organization',
            editable: 'never',
            type: 'string',
        }] : []),
        {
            field: 'customer_number',
            title: 'Customer Number',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
        },
        {
            field: 'pricing_date',
            title: 'Pricing Date',
            type: 'string',
            render: (rowData) => <a>{Moment.utc(rowData.pricing_date).format('YYYY-DD-MM')}</a>
        },
        {
            field: 'margin_price',
            title: 'Margin price',
            type: 'string',
        },
        {
            field: 'margin_price_percentage',
            title: 'Margin Price %',
            type: 'string',
        },
        {
            field: 'margin_target_percentage',
            title: 'Margin Target',
            type: 'string',
        },
        {
            field: 'market_price',
            title: 'Market Price',
            type: 'string',
        },
        {
            field: 'market_price_percentage',
            title: 'Market Price %',
            type: 'string',
        },
        {
            field: 'market_target_percentage',
            title: 'Market Target',
            type: 'string',
        },
        {
            field: 'floor_price',
            title: 'Floor Price',
            type: 'string',
        },
        {
            field: 'invoice_price',
            title: 'Invoice Price',
            type: 'string',
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div className={classes.actionStyle}>
                    <Tooltip title="Quote Details">
                        <IconButton classes={{ root: classes.IconButton }} onClick={() => viewQuote(rowData)}
                            disabled={rowData.hereIsTheUpdatedContractIDToShow ? true : false}
                            size={'small'}
                        >
                            <Details color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    </Tooltip>


                </div>
        }

    ]
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h2"> Price Policy Exceptions Report</Typography>
                </div>
                {configData ?
                    <MaterialTable
                        components={{
                            // Pagination: props => (
                            //     <TablePagination
                            //         {...props}
                            //         rowsPerPageOptions={[5, 10, 20]}
                            //         count={count}
                            //         page={page}
                            //         rowsPerPage={rowsPerPage}
                            //         onChangePage={handleChangePage}

                            //         onChangeRowsPerPage={handleChangeRowsPerPage}
                            //     />
                            // ),
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        // style={{ textTransform: 'capitalize' }}
                        style={{ marginTop: -5 }}
                        options={{
                            search: false,
                            filtering: true,
                            paging: false,
                            // pageSize: rowsPerPage,
                            // count: count,
                            // page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        configData: state.pricingData.pricePolicyExcepData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPricePolicyExceptionsReport(pagination, limit)),
        calculateQuote: (formData, simNo, formula) => dispatch(runPriceCalcForSimulation(formData, 'price-policy', null, simNo, formula)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PricePolicyExceptionsReport);