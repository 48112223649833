// import React, { useEffect } from 'react';
// import { makeStyles } from "@mui/styles";
// import {
//     Typography
// } from '@mui/material';
// import Chart from "react-apexcharts";
// import Moment from 'moment';
// import {
//     promPlanSalsIncentives
// } from '../../../../redux/actions';
// import { connect } from 'react-redux';

// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: 'white',
//         padding: '20px 30px 20px 30px',
//         borderRadius: 10
//     }
// }));

// const SalesIncentives = props => {
//     const classes = useStyles();
//     const [chartOptionsPP, setChartOptionsPP] = React.useState({});
//     const [chartSeriesPP, setChartSeriesPP] = React.useState([]);
//     const [k, setK] = React.useState([]);
//     useEffect(() => {
//         var tempCost = {};
//         var tempIncentiveAmount = {};
//         var tempKF = {};
//         var tempCost = {};
//         var tempProfit = {};
//         var dataTempCost = [];
//         var dataTempProfit = [];
//         var dataTempIncentiveAmount = [];
//         var dataTempKF = [];
//         if (props.data && props.data.length > 0) {
//             props.data.map((item, i) => {
//                 tempCost['x'] = item.period;
//                 tempCost['y'] = item.cost;
//                 tempProfit['x'] = item.period;
//                 tempProfit['y'] = item.profit;
//                 tempIncentiveAmount['x'] = item.period;
//                 tempIncentiveAmount['y'] = item.incentive_amount;
//                 tempKF['x'] = item.period;
//                 tempKF['y'] = item.key_figure_value;
//                 dataTempCost[i] = tempCost
//                 dataTempProfit[i] = tempProfit;
//                 dataTempIncentiveAmount[i] = tempIncentiveAmount
//                 dataTempKF[i] = tempKF;
//                 tempCost = {}
//                 tempProfit = {}
//                 tempKF = {}
//                 tempIncentiveAmount = {}
//             })

//             setChartOptionsPP({
//                 chart: {
//                     type: "line",
//                     animations: {
//                         enabled: true,
//                         easing: 'easeinout',
//                         speed: 800,
//                         animateGradually: {
//                             enabled: true,
//                             delay: 150
//                         },
//                         dynamicAnimation: {
//                             enabled: true,
//                             speed: 350
//                         }
//                     },
//                 },
//                 xaxis: {
//                     type: "date"
//                 },
//                 stroke: {
//                     curve: "smooth",
//                     width: 1
//                 },
//                 yaxis: {
//                     labels: {
//                         formatter: function (val) {
//                             var value = val.toLocaleString();
//                             return value
//                         }
//                     }
//                 }
//             })

//             setChartSeriesPP([
//                 {
//                     name: "Cost",
//                     data: dataTempCost

//                 },
//                 {
//                     name: "Profit",
//                     data: dataTempProfit

//                 },
//                 {
//                     name: "Incentive Amount",
//                     data: dataTempIncentiveAmount

//                 },
//                 {
//                     name: "Revenue",
//                     data: dataTempKF

//                 }
//             ])
//         }

//     }, [props.data])


//     return (
//         <div className={classes.root}>
//             <Typography variant="h2" > {'Sales Incentives'} </Typography>
//             {props.data && props.data.length > 0 ?
//                 <Chart options={chartOptionsPP} type="line" series={chartSeriesPP} height={265} />
//                 :
//                 <div style={{ height: 270,display:'flex',justifyContent:'center',alignItems: 'center'
//                 }}>
//                     <Typography variant='h4'>
//                         There is no data to show now.
//                     </Typography>
//                 </div>
//             }
//         </div>
//     );
// };


// const mapStateToProps = state => {
//     return {
//         promPlanningSalesInc: state.initialData.dashboardCountData,
//     }
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         promPlanSalsIncentives: (sd, ed) => dispatch(promPlanSalsIncentives(sd, ed)),
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(SalesIncentives);
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../../Analytics/Charts//SplineChart";
import Moment from "moment";
import {
    promPlanSalsIncentives
} from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        padding: "20px 30px 20px 30px",
        borderRadius: 10,
    },
    formLabel: {
        fontSize: 13, //theme.typography.h4.fontSize,
        textTransform: "capitalize",
    },
    chartHead: {
        width: "100%",
        paddingLeft: 20,
        // paddingTop: 10,
        fontSize: theme.typography.h2.fontSize,
        display: "flex",
        justifyContent: "center",
        marginBottom: 30,
    },
}));
const AccrualPaymentClaim = (props) => {
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);

    console.log(props.data)
    useEffect(() => {
        if (props.data) {
            if (
                props.data &&
                (
                    (props.data.key_figure_values &&
                        props.data.key_figure_values !== null &&
                        props.data.key_figure_values.length > 0) ||
                    (props.data.costs &&
                        props.data.costs !== null &&
                        props.data.costs.length > 0) ||
                    (props.data.incentive_amounts &&
                        props.data.incentive_amounts !== null &&
                        props.data.incentive_amounts.length > 0) ||
                    (props.data.profit &&
                        props.data.profit !== null &&
                        props.data.profit.length > 0))
            ) {
                var data = [
                    {
                        data:
                            props.data &&
                                props.data.key_figure_values
                                ? props.data.key_figure_values
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Revenue",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#0000FF",
                    },
                    {
                        data:
                            props.data &&
                                props.data.costs
                                ? props.data.costs
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Cost",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#CA4218",
                    },
                    {
                        data:
                            props.data &&
                                props.data.incentive_amounts
                                ? props.data.incentive_amounts
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Incentive Amounts",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#1cfcf1",
                    },
                    {
                        data:
                            props.data &&
                                props.data.profit
                                ? props.data.profit
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Profit",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#fff836",
                    },
                ];
                setAvssGraphData(data);
            } else setAvssGraphData([]);
        } else setAvssGraphData([]);
    }, [props.data]);
    return (
        <div className={classes.root}>
            <Typography variant="h2" className={classes.chartHead}>
                Revenue-Profit Trending
            </Typography>
            <div style={{ marginTop: "-15px" }}>
                <SplineChart id="claim-chart" data={avssGraphData}
                    min={props.data.end} max={props.data.start}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        promPlanSalsIncentives: state.initialData.dashboardCountData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        promPlanSalsIncentives: (sd, ed) => dispatch(promPlanSalsIncentives(sd, ed)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccrualPaymentClaim);
