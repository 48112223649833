import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    List, ListItem, ListItemText, Fab
} from '@mui/material';
import { AddBoxOutlined, DeleteForever, Edit, Visibility, Publish, LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { deletePromotionCurrencyFormatData, getPromotionCurrencyFormatData, uploadPromotionCurrencyDataFile } from '../../redux/actions/Promotion/AuthAction';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fontSizeicon: {
        fontSize: 20
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const PromotionCurrencyExcelUpload = props => {

    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [promotionCurrency, setPromotionCurrency] = useState([]);
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)

    const handleDrop = (event, item) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadPromotionCurrency(currentEditableID, data);
        setOpen(true)
    }

    useEffect(() => {
        props.getPromotionCurrencyFormatData();
        sessionStorage.removeItem('dynamic_filter_fields');
    }, []);

    useEffect(() => {
        if (props.promotionCurrencyFormatData) {
            setPromotionCurrency(props.promotionCurrencyFormatData)
        }
    }, [props.promotionCurrencyFormatData]);

    useEffect(() => {
        if (props.promotionCurrencyErrorList) {
            setErrorList(props.promotionCurrencyErrorList)
            if (Object.entries(props.promotionCurrencyErrorList).length > 0 && props.promotionCurrencyErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.promotionCurrencyErrorList])


    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        localStorage.setItem('promo-currency-query', JSON.stringify(item))
        history.push('/promo-currency/excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        localStorage.setItem('promo-currency-query', JSON.stringify(item))
        history.push('/promo-currency/excel-upload/view/' + item.id);
    }
    function deleteQuery(item) {
        props.deletePromotionCurrencyFormatData(item.id);
    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>
                    Promotion Currency Rates Excel Upload</Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={() => history.push('/promo-currency/excel-upload/add-query')}
                >
                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                </Fab>
            </div>
            {errorList && errorList.length > 0 ?
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>

                    <div onClick={() => { setErrorDialogOpen(true) }} style={{
                        cursor: 'pointer', color: 'red', fontSize: 10, display: 'flex',
                        alignItems: 'center',
                        border: '1px solid red',
                        padding: 4,
                        marginLeft: 10
                    }}>Show Error Summary</div>
                </div> : ''}
            {
                promotionCurrency && promotionCurrency.length > 0 ?
                    <TableContainer style={{ marginTop: -20 }}>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {promotionCurrency
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.id} >
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.id}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' width={300}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <Edit color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <Visibility color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleDrop(e)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runQuery(item)}
                                                            size="large">
                                                            <Publish color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => deleteQuery(item)}
                                                            size="large">
                                                            <DeleteForever color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
            <Dialog
                fullWidth
                maxWidth="xs"
                open={errorDialogOpen}
            >
                <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                    <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                </DialogActions>
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>

                    <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        promotionCurrencyFormatData: state.promotionData.promotionCurrencyFormatData,
        promotionCurrencyErrorList: state.promotionData.promotionCurrencyErrorList,
        loading: state.promotionData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadPromotionCurrency: (id, file) => dispatch(uploadPromotionCurrencyDataFile(id, file)),
        deletePromotionCurrencyFormatData: (id) => dispatch(deletePromotionCurrencyFormatData(id)),
        getPromotionCurrencyFormatData: () => dispatch(getPromotionCurrencyFormatData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionCurrencyExcelUpload);