import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Typography
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);
const OrganizationData = props => {
    const classes = useStyles();
    const { className } = props;
    const [supplierMasterData, setSupplierMasterData] = React.useState([]);
    useEffect(() => {
        setSupplierMasterData(props.masdata);
    }, [props.masdata]);
    console.log(supplierMasterData)
    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <div className={classes.container}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {supplierMasterData && supplierMasterData.organizations && supplierMasterData.organizations.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead}>Purchase Organization</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Company Code</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Flex Attribute 3</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Flex Attribute 4</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Alternate Payee</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Head Office</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {supplierMasterData.organizations.map((item) => {
                                                return (
                                                    <StyledTableRow key={item.ID}>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}> {item.purchase_org}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}> {item.company_code}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}> {item.flex_attribute_3}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}> {item.flex_attribute_4}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}> {item.alternate_payee}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}> {item.head_office}</TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );

};
const mapStateToProps = state => {
    return {
        // supplierMasterData: state.customerData.supplierMasterViewData
    }
}
export default connect(mapStateToProps)(OrganizationData);