
import React, { useEffect, useRef } from "react";
import {
    Grid, Button, Typography, Breadcrumbs, Card, FormLabel, Dialog,
    DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { createPriceListData, createPriceListDataAll, getPriceFieldValues, getPriceMaintenanceFields, getPriceListData, getPriceListMaster, getPriceFilterData } from '../../../redux/actions/Pricing/AuthAction';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import '../../../../node_modules/jsuites/dist/jsuites.css';
import '../../../../node_modules/jsuites/dist/jsuites.js';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, Inject, Page, Sort, Resize, ExcelExport, Toolbar, PdfExport, Search, Filter } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {
    RangeDirective, getRangeIndexes, getRangeAddress,
    SpreadsheetComponent, getCell, SheetsDirective, SheetDirective, ColumnsDirective as ColumnsDirectiveS,
    RangesDirective, ColumnDirective as ColumnDirectiveS, RowsDirective, RowDirective, CellsDirective,
    CellDirective
} from '@syncfusion/ej2-react-spreadsheet';
import { MultiSelectDropdown } from "../../../components/Inputs";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        overflow: 'scroll',
        height: '100%'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: '0px 15px 15px 15px',
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        //padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formlabel: {
        paddingTop: 15,
        marginBottom: 12,
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    input: {
        width: 42,
    },
    addRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    }
}));

const AddPriceListData = props => {
    const classes = useStyles();
    const history = useHistory();
    var grid;
    var spreadsheet;
    const scrollSettings = { isFinite: true, height: '100%' };
    const [columns, setColumns] = React.useState([]);
    // const jRef = useRef(null);
    const [priceListID, setPriceListID] = React.useState('');
    const [listDataArray, setListDataArray] = React.useState([]);
    const [formData, setFormData] = React.useState({});
    const [priceListData, setPriceListData] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [excelDialog, setExcelDialog] = React.useState(false);
    const [rowCount, setRowCount] = React.useState(50);
    const [filterList, setFilterList] = React.useState({});
    const [colCount, setColCount] = React.useState(0);

    function handleOnSubmit() {
        var data = {
            "price_fields": listDataArray.price_type_fields
            ,
            "filters": formData
        }
        props.getConfigData(data);
        //var name = localStorage.getItem('pricetypename')
        //props.getConfigData(null, null, { ...formData, 'price_list_id': priceListID, 'price_type_name': name });
        setFormData({})
        setExcelDialog(true)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        if (props.maintenanceFields) {
            setListDataArray(props.maintenanceFields)
            if (props.maintenanceFields.filter_fields) {
                var filter = props.maintenanceFields.filter_fields.filter(item => item != 'start_date' && item != 'end_date')
                var data = {
                    'filter_fields': filter,
                    "price_fields": props.maintenanceFields.price_type_fields,
                }
                props.getFilterdata(data)
            }
        }
    }, [props.maintenanceFields])
    useEffect(() => {
        if (props.filterArray)
            setFilterList(props.filterArray)

    }, [props.filterArray])
    useEffect(() => {
        if (props.priceListData && props.priceListData.records && props.priceListData.records[0]) {
            setPriceListData(props.priceListData.records);
            setPriceTypeNameArray(props.priceListData.records[0].price_type_name)
        }
    }, [props.priceListData]);

    const [dataRows, setDataRows] = React.useState([]);
    function titleCase(str) {
        if (str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }
    useEffect(() => {
        if (props.configData && props.configData.length > 0 && listDataArray && listDataArray.value && listDataArray.value.length > 0) {
            var temp = [];
            var tempSingle = [];
            var columnArray = [];
            if (rowCount < props.configData.length)
                setRowCount(props.configData.length)
            var rec = props.configData;
            rec.map((item, i) => {
                if (item.market_min != 0) {
                    rec[i].min_value = item.market_min
                } else if (item.margin_min != 0) {
                    rec[i].min_value = item.margin_min
                } else {
                    rec[i].min_value = 0;
                }
                if (item.market_max != 0) {
                    rec[i].max_value = item.market_max
                } else if (item.margin_max != 0) {
                    rec[i].max_value = item.margin_max
                } else {
                    rec[i].max_value = 0;
                }
            })

            rec.map(item => {
                listDataArray.qualifier_labels
                    .filter(h => priceTypeNameArray ? h.key !== 'price_type_name' : h)
                    .filter(h => props.configData[0].calculation_type && props.configData[0].calculation_type != 'Analytical' ? h.key != 'max_value' && h.key != 'min_value' : h)
                    .filter(h => (props.configData[0].calculation_type && props.configData[0].calculation_type == '%' || props.configData[0].calculation_type == 'Fixed') ? h.key != 'per_unit'/* && h.key != 'scale_from' */ && h.key != 'pricing_uom' && (props.configData[0].calculation_type == '%' ? h.key != 'currency' : h) && (props.configData[0].calculation_type == 'Fixed' ? h.key != 'scale_from' : h) : h)
                    .map(h => {
                        if (item[h.key]) {
                            if (h.key === 'start_date' || h.key === 'end_date') {
                                tempSingle.push(Moment.utc(item[h.key]).format('MM/DD/YYYY'))
                            } else {
                                tempSingle.push(item[h.key])
                            }
                        } else {
                            tempSingle.push('0');
                        }
                    })
                temp.push(tempSingle);
                tempSingle = [];
            })
            listDataArray.qualifier_labels
                .filter(h => priceTypeNameArray ? h.key !== 'price_type_name' : h)
                .filter(h => props.configData[0].calculation_type && props.configData[0].calculation_type != 'Analytical' ? h.key != 'max_value' && h.key != 'min_value' : h)
                .filter(h => (props.configData[0].calculation_type && props.configData[0].calculation_type == '%' || props.configData[0].calculation_type == 'Fixed') ? h.key != 'per_unit'/* && h.key != 'scale_from' */ && h.key != 'pricing_uom' && (props.configData[0].calculation_type == '%' ? h.key != 'currency' : h) && (props.configData[0].calculation_type == 'Fixed' ? h.key != 'scale_from' : h) : h)
                .map(h => {
                    if (h.key === 'start_date' || h.key === 'end_date' || h.key === 'per_unit') {
                        columnArray.push({
                            field: h.key,
                            title: h.value,
                            width: 100
                        });

                    } else {
                        columnArray.push({
                            field: h.key,
                            title: h.value,
                            width: 150
                        });
                    }
                })
            var headers = []
            columnArray.map(item => {
                headers.push(item.field)
            })
            setColumns(columnArray);
            setColCount(columnArray.length)
            //setOptionsList(options)
            var tempRows = [];
            var tempObj = {};
            var tempRowsNew = [];
            var tempObjNew = {};
            props.configData.map((item, ix) => {
                //tempObj=item
                columnArray.map((h, ix2) => {
                    if (h.field === 'start_date' || h.field === 'end_date') {
                        tempObj[h.title] = Moment.utc(item[h.field]).format('M/D/YYYY')
                        tempObjNew[h.field] = Moment(item[h.field]).format('MM/DD/YYYY')
                    }
                    else {
                        tempObj[h.title] = item[h.field]
                        tempObjNew[h.field] = item[h.field]
                    }
                })
                // tempRows['price_list_id'] = priceListID
                //tempRows['price_type_name'] = localStorage.getItem('pricetypename')
                tempRows[ix] = tempObj;
                tempObj = {};
                // tempRowsNew['price_list_id'] = priceListID
                //tempRowsNew['price_type_name'] = localStorage.getItem('pricetypename')
                tempRowsNew[ix] = tempObjNew;
                tempObjNew = {};
            })
            setDataRows(tempRows);
            //  setDataRowsNew(tempRowsNew);
            if (tempRows && tempRows.length > rowCount)
                setRowCount(tempRows.length)
        }
    }, [props.configData, listDataArray]);
    function handleFormData(value, key) {
        if ((key == 'start_date' || key == 'end_date') && value) {
            setFormData({ ...formData, [key]: Moment(value).local().format('YYYY-MM-DD') })
        }
        else {
            if (value && value.length > 0)
                setFormData({ ...formData, [key]: value })
        }
    }
    //Syncfusion controls
    const filterOptions = {
        type: 'Excel'
    };
    function contextMenuBeforeOpen() {
        spreadsheet.removeContextMenuItems(["Insert Column", 'Delete Column', 'Hide Column', 'Cut', 'Paste', 'Paste Special', 'Hyperlink']); //Items that needs to be removed, Set `true` if the given `text` is a unique id.
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/pricing-maintenance'
                >
                    Price Maintenance
                </Link>
                <Typography color="textPrimary" variant='h4'>View Price List Data</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {listDataArray && listDataArray.filter_fields && listDataArray.filter_fields.length > 0 &&
                                    listDataArray.qualifier_labels && listDataArray.qualifier_labels.length > 0 &&
                                    listDataArray.qualifier_labels
                                        .filter(item => listDataArray.filter_fields.includes(item.key))
                                        .map((item) => {
                                            return (
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>

                                                        {item.key == 'start_date' || item.key == 'end_date' ?
                                                            <>
                                                                <FormLabel className={classes.formlabel}>
                                                                    {item.value}
                                                                </FormLabel>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '3px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%',
                                                                                    marginTop: 6
                                                                                }
                                                                            }}
                                                                            value={formData[item.key] ? formData[item.key] : null}
                                                                            onChange={(e) => handleFormData(e, item.key)}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                            </>
                                                            :
                                                            <>
                                                                <MultiSelectDropdown classes={{ root: classes.fontSetting }} heading={item.value} listArray={filterList && filterList[item.key]} data={formData[item.key] ? formData[item.key] : []} id={item.key} onChange={(e) => handleFormData(e, item.key)} capitalize />

                                                                { /* : item == 'price_type_name' ?

                                                                <Select
                                                                    disableUnderline
                                                                    value={formData[item] ? formData[item] : ''}
                                                                    onChange={(e) => handleFormData(e.target.value, item)}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    style={{ textTransform: 'capitalize', marginTop: 0 }}
                                                                >

                                                                    {priceTypeNameArray && priceTypeNameArray.length > 0 && priceTypeNameArray
                                                                        .map((item) => {
                                                                            return (
                                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                                :
                                                                <OutlinedInput
                                                                    value={formData[item] ? formData[item] : ''}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) => handleFormData(e.target.value, item)} />
                                                                    */}
                                                            </>
                                                        }
                                                    </div>
                                                </Grid>
                                            );
                                        })}
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                    <Dialog
                        classes={{ paper: classes.paperContainer }}
                        open={excelDialog}
                        onClose={() => setExcelDialog(false)}
                        aria-labelledby="responsive-dialog-title"
                        fullScreen
                    >
                        <DialogContent style={{ justifyContent: 'center', display: 'flex' }}>
                            <DialogContentText>
                                {dataRows && dataRows.length > 0 && columns &&
                                    <div style={{ height: '100%' }}>
                                        <SpreadsheetComponent

                                            scrollSettings={scrollSettings}
                                            ref={(ssObj) => { spreadsheet = ssObj }}
                                            enableVirtualization={true}
                                            allowNumberFormatting={true}
                                            allowDataValidation={true}
                                            showPager={false}
                                            isFinite={true}
                                            width={window.screen.width + 30}
                                            allowEditing={false}
                                            contextMenuBeforeOpen={contextMenuBeforeOpen}
                                        >
                                            <SheetsDirective>
                                                <SheetDirective
                                                    name="Price List"
                                                    colCount={colCount}
                                                    rowCount={rowCount}
                                                >

                                                    <RowsDirective>
                                                        <RowDirective  >
                                                            <CellsDirective allowEditing={false}>
                                                                {columns && columns
                                                                    .map((item, index) => {
                                                                        return (

                                                                            <CellDirective
                                                                                index={index}
                                                                                headerText={item.title}
                                                                                value={item.title}
                                                                                field={item.field}
                                                                                style={{
                                                                                    color: '#F2F2F2',
                                                                                    backgroundColor: '#095eb5',//localStorage.getItem('appTheme')!='Light'&&localStorage.getItem('appTheme'), 
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            />
                                                                        )
                                                                    })}
                                                            </CellsDirective>

                                                        </RowDirective>
                                                    </RowsDirective>
                                                    <ColumnsDirectiveS>
                                                        {columns && columns
                                                            .map(item => {
                                                                return <ColumnDirective
                                                                    width={window.screen.width / (columns.length)}
                                                                    headerText={item.title} field={item.field}
                                                                    format={(item.field === 'end_date' || item.field === 'start_date') ? 'mm-dd-yyyy' : null}
                                                                    type={(item.field === 'end_date' || item.field === 'start_date') ? 'shortDate' : null}
                                                                />
                                                            })}
                                                    </ColumnsDirectiveS>
                                                    <RangesDirective>
                                                        <RangeDirective dataSource={dataRows}></RangeDirective>
                                                    </RangesDirective>
                                                </SheetDirective>
                                            </SheetsDirective>
                                        </SpreadsheetComponent>


                                    </div>
                                }

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setExcelDialog(false)} color="primary" variant="outlined">
                                Back
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>


        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, priceListId, id, type) => dispatch(createPriceListData(data, priceListId, id, type)),
        onSubmitAll: (data) => dispatch(createPriceListDataAll(data)),
        getFieldValues: (key) => dispatch(getPriceFieldValues(key)),
        getPriceMaintenanceFields: (priceListId) => dispatch(getPriceMaintenanceFields(priceListId)),
        // getConfigData: (pagination, limit, appId) => dispatch(getPriceListData(pagination, limit, appId, 'editPriceList')),
        getPriceListMaster: (pagination, limit, id) => dispatch(getPriceListMaster(pagination, limit, id)),
        getConfigData: (formData) => dispatch(getPriceListData(null, null, formData, 'bulkEdit')),
        getFilterdata: (data) => dispatch(getPriceFilterData(data))
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.pricingData.loading,
        qualifierNameList: state.pricingData.priceQualifierNameList,
        priceListId: state.pricingData.priceTypeNameList,
        // maintenanceFields: state.pricingData.priceMaintenanceFields,
        maintenanceFields: state.pricingData.pricingFields,
        configData: state.pricingData.priceListData,
        priceListData: state.pricingData.priceListMasterData,
        filterArray: state.pricingData.priceFilterData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPriceListData);

