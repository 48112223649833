import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Switch,
    TextField
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getDynamicQueryFields, postDynamicQuery } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const ViewSalesInvoiceQuery = props => {
    const classes = useStyles();
    const [salesQueryData, setSalesQueryData] = React.useState(sessionStorage.getItem('sales-invoice-query') ? JSON.parse(sessionStorage.getItem('sales-invoice-query')) : []);
    useEffect(() => {
        // props.getDynamicQueryFields();
    }, []);
    useEffect(() => {
        if (props.dynamicQueryDetailsData) {
        }
    }, [props.dynamicQueryDetailsData]);

    return (
        <div className={classes.root}>
            <Typography color="textPrimary" variant='h1'>View Query</Typography>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="h3" color={'primary'}>
                                    {salesQueryData ? salesQueryData['format_name'] : ''}
                                </Typography>
                            </div>
                            {salesQueryData ?
                                <div
                                >
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead}>Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(salesQueryData).length > 0 && Object.entries(salesQueryData)
                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'org_id' && k != 'organization_id' && v == 1 && k != 'changed_by_id')
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center'> {v} </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {Object.keys(salesQueryData).length > 0 && Object.entries(salesQueryData)

                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'org_id' && k != 'organization_id' && v > 1 && k != 'ID' && k != 'changed_by_id')
                                                        .sort(function (first, second) {
                                                            return first[1] - second[1];
                                                        })
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell align='center'> {v} </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getDynamicQueryFields: () => dispatch(getDynamicQueryFields()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsData,
        dynamicQueryDetailsData: state.operationalReportsData.dynamicQueryDetailsData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSalesInvoiceQuery);