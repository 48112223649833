import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import { Button, Card, Typography, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, FormLabel, Select, MenuItem, OutlinedInput, } from '@mui/material';
import { connect } from 'react-redux';
import ChipInput from 'material-ui-chip-input';
import { LabelText } from '../../components/Inputs';
import { allowedApps, getDefaultValues, deleteAggregationLevel, loadValuesBasedOnAppTypeAndField, getClaimConfiguration, createClaimConfiguration } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 25,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        width: '100%'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
        paddingTop: 8
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));
const ClaimConfigurationTab = props => {
    const [inputValue, setInputValue] = React.useState('');
    const [fieldValue, setFieldValue] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [putActive, setPutActive] = React.useState(false);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [positiveTolerance, setPositiveTolerance] = React.useState('');
    const [negativeTolerance, setNegativeTolerance] = React.useState('');
    const [splitCriteria, setSplitCriteria] = React.useState([]);
    const [claimAdjustment, setClaimAdjustment] = React.useState([]);
    const [deliveryCheck, setDeliveryCheck] = React.useState(false);
    const [inventoryCheck, setInventoryCheck] = React.useState(false);
    const [claimConfigData, setClaimConfigData] = React.useState([])
    const [acquisitionPrice, setAcquisitionPrice] = React.useState('')
    const [contractPrice, setContractPrice] = React.useState('')
    const [paymentSchema, setPaymentSchema] = React.useState('')
    const [customerIdType, setCustomerIdType] = React.useState('')
    const [materialIdType, setMaterialIdType] = React.useState('')
    const [supplierIdType, setSupplierIdType] = React.useState('')
    const [senderMailAddress, setSenderMailAddress] = React.useState('')
    const [currentID, setCurrentID] = React.useState(0);
    const [accrualSchema, setAccrualSchema] = React.useState('')
    const classes = useStyles();
    const { className } = props;
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        props.onLoadingDefault(sessionStorage.getItem('application'));
        props.getClaimData();

    }, []);
    useEffect(() => {
        if (props.claimData && props.claimData.length > 0) {
            setClaimConfigData(props.claimData[0])
            setCurrentID(props.claimData[0].id)
            setPositiveTolerance(props.claimData[0].postive_tolerance)
            setNegativeTolerance(props.claimData[0].negative_tolerance)
            setClaimAdjustment(props.claimData[0].claim_adjustment_codes)
            setDeliveryCheck(props.claimData[0].delivery_tracking_check)
            setSplitCriteria(props.claimData[0].split_claim_criteria)
            setInventoryCheck(props.claimData[0].inventory_check)
            setAcquisitionPrice(props.claimData[0].acquisition_price)
            setContractPrice(props.claimData[0].contract_price)
            setPaymentSchema(props.claimData[0].payment_schema)
            setCustomerIdType(props.claimData[0].customer_id_type)
            setMaterialIdType(props.claimData[0].material_id_type)
            setSupplierIdType(props.claimData[0].supplier_id_type)
            setSenderMailAddress(props.claimData[0].sender_mail_address)
        }
    }, [props.claimData])
    const handleChange = (fieldName) => {
        setSelectedRowName(fieldName);
        if (fieldName == 'positive_tolerance') {
            setInputValue(positiveTolerance)
        }
        if (fieldName == 'negative_tolerance') {
            setInputValue(negativeTolerance)
        }
        if (fieldName == 'split_claim_criteria') {
            setFieldValue(splitCriteria)
        }
        if (fieldName == 'claim_adjustment_codes') {
            setFieldValue(claimAdjustment)
        }
        if(fieldName=='delivery_tracking_check'){
            setSelectedValue(deliveryCheck)
        }
       if(fieldName=='inventory_check'){
            setSelectedValue(inventoryCheck)
        }
        if (fieldName == 'acquisition_price') {
            setInputValue(acquisitionPrice)
        }
        if (fieldName == 'contract_price') {
            setInputValue(contractPrice)
        }
        if (fieldName == 'payment_schema') {
            setSelectedValue(paymentSchema)
        }
        if (fieldName == 'accrual_schema') {
            setSelectedValue(accrualSchema)
        }
        if (fieldName == 'customer_id_type') {
            setInputValue(customerIdType)
        }
        if (fieldName == 'material_id_type') {
            setInputValue(materialIdType)
        }
        if (fieldName == 'supplier_id_type') {
            setInputValue(supplierIdType)
        }
        if (fieldName == 'sender_mail_address') {
            setInputValue(senderMailAddress)
        }
    };
    function handleOnSelect(event) {
        setSelectedValue(event.target.value)
        if (selectedRowName == 'acquisition_price') {
            setAcquisitionPrice(event.target.value)
        }
        else if (selectedRowName == 'contract_price') {
            setContractPrice(event.target.value)
        }
        else if (selectedRowName == 'payment_schema') {
            setPaymentSchema(event.target.value);
        }
        else if (selectedRowName == 'delivery_tracking_check') {
            setDeliveryCheck(event.target.value)
        }
        else if (selectedRowName == 'inventory_check') {
            setInventoryCheck(event.target.value);
        }
        else {
            setAccrualSchema(event.target.value)
        }
    }
    function handleSubmit(clear) {
        var data;
        if (clear === 'clear') {
            data = {
                "postive_tolerance": selectedRowName == 'positive_tolerance' ? 0 : parseFloat(positiveTolerance),
                "negative_tolerance": selectedRowName == 'negative_tolerance' ? 0 : parseFloat(negativeTolerance),
                "split_claim_criteria": selectedRowName == 'split_claim_criteria' ? [] : splitCriteria,
                "claim_adjustment_codes": selectedRowName == 'claim_adjustment_codes' ? [] : claimAdjustment,
                "delivery_tracking_check":selectedRowName=='delivery_tracking_check'? false:deliveryCheck,
                "inventory_check":selectedRowName=='inventory_check'?false:inventoryCheck,
                "acquisition_price": selectedRowName == 'acquisition_price' ? '' : acquisitionPrice,
                "contract_price": selectedRowName == 'contract_price' ? '' : contractPrice,
                "payment_schema": selectedRowName == 'payment_schema' ? '' : paymentSchema,
                "accrual_schema": selectedRowName == 'accrual_schema' ? '' : accrualSchema,
                "customer_id_type": selectedRowName == 'customer_id_type' ? '' : customerIdType,
                "material_id_type": selectedRowName == 'material_id_type' ? '' : materialIdType,
                "supplier_id_type": selectedRowName == 'supplier_id_type' ? '' : supplierIdType,
                "sender_mail_address": selectedRowName == 'sender_mail_address' ? '' : senderMailAddress,
            };
        } else {
            data = {
                "postive_tolerance": positiveTolerance ? parseFloat(positiveTolerance) : 0,
                "negative_tolerance": negativeTolerance ? parseFloat(negativeTolerance) : 0,
                "split_claim_criteria": splitCriteria,
                "claim_adjustment_codes": claimAdjustment,
                "delivery_tracking_check": deliveryCheck,
                "inventory_check": inventoryCheck,
                "acquisition_price": acquisitionPrice,
                "contract_price": contractPrice,
                "payment_schema": paymentSchema,
                "accrual_schema": accrualSchema,
                "customer_id_type": customerIdType,
                "material_id_type": materialIdType,
                "supplier_id_type": supplierIdType,
                "sender_mail_address": senderMailAddress,
            };
        }
        if (currentID != 0) {
            props.onSubmit(data, 'edit', currentID);
        }
        else {
            props.onSubmit(data, 'create');
        }
        setPutActive(false)
        setSelectedRowName('');
        setSelectedValue('');
        setEditMode(false)
        setCurrentID(0)
        setFieldValue([])
        setInputValue('')
        setSelectedValue(false)
    }
    const handleFieldValue = (newValue) => {
        setFieldValue([...fieldValue, newValue]);
        if (selectedRowName == 'claim_adjustment_codes') {
            setClaimAdjustment([...claimAdjustment, newValue]);
        }
        else {
            setSplitCriteria([...splitCriteria, newValue]);
        }
    }
    const handleDeleteFieldValue = (newValue) => {
        // setDisableEdit(false);
        var deleteFieldValue = fieldValue.filter(item => item !== newValue)
        setFieldValue(deleteFieldValue)
        if (selectedRowName == 'claim_adjustment_codes') {
            setClaimAdjustment(deleteFieldValue);
        }
        else {
            setSplitCriteria(deleteFieldValue);
        }
    }
    function handleInput(e) {
        if (selectedRowName == 'positive_tolerance') {
            setPositiveTolerance(e.target.value)
        }
        if (selectedRowName == 'negative_tolerance') {
            setNegativeTolerance(e.target.value)
        }
        if (selectedRowName == 'acquisition_price') {
            setAcquisitionPrice(e.target.value)
        }
        if (selectedRowName == 'contract_price') {
            setContractPrice(e.target.value)
        }
        if (selectedRowName == 'payment_schema') {
            setPaymentSchema(e.target.value)
        }
        if (selectedRowName == 'customer_id_type') {
            setCustomerIdType(e.target.value)
        }
        if (selectedRowName == 'material_id_type') {
            setMaterialIdType(e.target.value)
        }
        if (selectedRowName == 'supplier_id_type') {
            setSupplierIdType(e.target.value)
        }
        if (selectedRowName == 'sender_mail_address') {
            setSenderMailAddress(e.target.value)
        }
        setInputValue(e.target.value)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Configuration</Typography>
            </div>
            <div className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>

                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container} >
                            <Grid container >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Field Name' capitalize={true} data={selectedRowName ? selectedRowName.replace(/_/g, ' ') : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        style={{ paddingLeft: 15, marginTop: 20 }}
                                    >
                                        <FormLabel error={false}>
                                            Field Value
                                        </FormLabel>
                                        <div>
                                            {(selectedRowName == 'acquisition_price' || selectedRowName == 'contract_price') &&
                                                <OutlinedInput
                                                    value={inputValue != 0 ? inputValue : ''}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleInput}
                                                />
                                                // <Select
                                                //     value={selectedValue}
                                                //     onChange={handleOnSelect}
                                                //     displayEmpty
                                                //     style={{ width: '100%', marginTop: 0, textTransform: 'capitalize', }}
                                                //     className={clsx({
                                                //         [classes.select]: true
                                                //     })}
                                                //     classes={{
                                                //         selectMenu: classes.selectedItem
                                                //     }}
                                                // >
                                                //     <MenuItem value={'Invoice'} key={'Invoice'} >
                                                //         Invoice
                                                //     </MenuItem>
                                                //     <MenuItem value={'Contract'} key={'Contract'} >
                                                //         Contract
                                                //     </MenuItem>
                                                // </Select>
                                            }
                                            {(selectedRowName == 'split_claim_criteria' || selectedRowName == 'claim_adjustment_codes') &&
                                                <ChipInput
                                                    classes={{
                                                        root: classes.rootContainer,
                                                        chip: classes.chip,
                                                        input: classes.input,
                                                        inputRoot: classes.inputRoot,
                                                        label: classes.chipLabel
                                                    }}
                                                    value={fieldValue}
                                                    onAdd={(chips) => handleFieldValue(chips)}
                                                    onDelete={(chip) => handleDeleteFieldValue(chip)}
                                                    disableUnderline={true}
                                                    className={clsx({
                                                        [classes.textInput]: true
                                                    })}
                                                    blurBehavior='add'
                                                />
                                            }
                                            {(selectedRowName == 'positive_tolerance' || selectedRowName == 'negative_tolerance' || selectedRowName == 'customer_id_type' || selectedRowName == 'material_id_type' || selectedRowName == 'supplier_id_type' || selectedRowName == 'sender_mail_address') &&
                                                <OutlinedInput
                                                    value={inputValue != 0 ? inputValue : ''}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleInput}
                                                />
                                            }
                                            {(selectedRowName == 'payment_schema' || selectedRowName == 'accrual_schema') &&
                                                <Select
                                                    value={selectedValue}
                                                    onChange={handleOnSelect}
                                                    displayEmpty
                                                    style={{ width: '100%', marginTop: 0, textTransform: 'capitalize', }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value={'Post All Claims'} key={'Post All Claims'} >
                                                        Post All Claims
                                                    </MenuItem>
                                                    <MenuItem value={'Post Approved Claims'} key={'Post Approved Claims'} >
                                                        Post Approved Claims
                                                    </MenuItem>
                                                    <MenuItem value={'Post Approved/Unprocessed Claims'} key={'Post Approved/Unprocessed Claims'} >
                                                        Post Approved/Unprocessed Claims
                                                    </MenuItem>
                                                </Select>
                                            }
                                            {(selectedRowName == 'inventory_check' || selectedRowName == 'delivery_tracking_check') &&
                                                <Select
                                                    value={selectedValue}
                                                    onChange={handleOnSelect}
                                                    displayEmpty
                                                    style={{ width: '100%', marginTop: 0, textTransform: 'capitalize', }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value={true} key={true} >
                                                        True
                                                    </MenuItem>
                                                    <MenuItem value={false} key={false} >
                                                        False
                                                    </MenuItem>
                                                </Select>
                                            }
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.buttonContainer}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ padding: 5 }}
                                        startIcon={<CheckIcon />}
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        //disabled={!putActive}
                                        onClick={() => handleSubmit()}
                                    > Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        startIcon={<CheckIcon />}
                                        //disabled={!putActive}
                                        onClick={() => handleSubmit('clear')}
                                    >Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Field Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('positive_tolerance')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Positive Tolerance
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.postive_tolerance && claimConfigData.postive_tolerance != 0 ? claimConfigData.postive_tolerance : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('negative_tolerance')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Negative Tolerance
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.negative_tolerance && claimConfigData.negative_tolerance != 0 ? claimConfigData.negative_tolerance : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('split_claim_criteria')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Split Claim Criteria
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.split_claim_criteria ? claimConfigData.split_claim_criteria.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('claim_adjustment_codes')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Claim Adjustment Codes
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.claim_adjustment_codes ? claimConfigData.claim_adjustment_codes.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('delivery_tracking_check')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Delivery Tracking Check
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.delivery_tracking_check ? claimConfigData.delivery_tracking_check.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('inventory_check')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Inventory Check
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.inventory_check ? claimConfigData.inventory_check.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('acquisition_price')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Acquisition Price Source
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 && props.claimData[0].acquisition_price ? props.claimData[0].acquisition_price.replace(/_/g, ' ') : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('contract_price')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Contract Price Source
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 && props.claimData[0].contract_price ? props.claimData[0].contract_price.replace(/_/g, ' ') : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('accrual_schema')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Accrual Schema
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 ? props.claimData[0].accrual_schema?.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('payment_schema')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Payment Schema
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 ? props.claimData[0].payment_schema.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('customer_id_type')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Customer ID Type
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 && props.claimData[0].customer_id_type ? props.claimData[0].customer_id_type.replace(/_/g, ' ') : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('material_id_type')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Material ID Type
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 && props.claimData[0].material_id_type ? props.claimData[0].material_id_type.replace(/_/g, ' ') : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('supplier_id_type')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Supplier ID Type
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 && props.claimData[0].supplier_id_type ? props.claimData[0].supplier_id_type.replace(/_/g, ' ') : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('sender_mail_address')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Sender Email Address
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 && props.claimData[0].sender_mail_address ? props.claimData[0].sender_mail_address : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        claimData: state.calculationAnalysisData.claimData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, field, type) => dispatch(createClaimConfiguration(data, field, type)),
        getClaimData: () => dispatch(getClaimConfiguration()),
        onDelete: (id) => dispatch(deleteAggregationLevel(id)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClaimConfigurationTab);