import React from 'react';
import { connect } from 'react-redux';
import { MembershipContractXREFUserTable } from './components';
const MembershipContractXREF = (props) => {
    return (
        <div>
            <MembershipContractXREFUserTable/>
        </div >
    );
};
export default connect(null, null)(MembershipContractXREF);