import React, { useEffect } from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, Typography, OutlinedInput, FormLabel, MenuItem, Select } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadContractVarianceGraph, loadValuesBasedOnAppTypeAndField,allowedApps } from '../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Chart } from "react-google-charts";
import { DateRangePicker } from 'rsuite';
import { endOfMonth, subMonths, startOfMonth } from 'date-fns';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '16px 0px 16px 0px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 32px 0px 20px',
        alignItems: 'center'
    },
    rangeContainer: {
        padding: '20px 30px 20px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        color: '#1675e0',
    },
    selectedItem: {
        borderRadius: 5
    }
}));
const ContractVariance = (props) => {
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);
    const [dateRangeValue, setDateRangeValue] = React.useState([new Date('2020-06-01T05:30:00'), new Date('2020-10-01T05:30:00')]);
    const handleDateRange = (value) => {
        setDateRangeValue(value);
        props.loadContractVarianceGraph(Moment(value[0]).format('MM/YYYY'), Moment(value[1]).format('MM/YYYY'), null, applicationType, sourceDataType);
    }
    const [accrualFilter, setAccrualFilter] = React.useState();
    const [accrualFilterEnd, setAccrualFilterEnd] = React.useState();
    useEffect(() => {
        setDateRangeValue([startOfMonth(subMonths(new Date(), 2)), endOfMonth(subMonths(new Date(), 1))]);
        var temp = [['Contract Type', 'Variance']];
        setAvssGraphData(temp)
        props.loadContractVarianceGraph(Moment(startOfMonth(subMonths(new Date(), 2))).format('MM/YYYY'), Moment(startOfMonth(subMonths(new Date(), 1))).format('MM/YYYY'), null, 'Customer Rebate', 'Direct - Sales Data');

    }, []);

    useEffect(() => {
        if (props.contractVarianceGraphDataloadContractVarianceGraph && props.contractVarianceGraphDataloadContractVarianceGraph.length > 0) {
            var temp = [['contract_type', 'incentive_amount_variance']];
            props.contractVarianceGraphDataloadContractVarianceGraph && props.contractVarianceGraphDataloadContractVarianceGraph.forEach(e => {
                temp.push([e.contract_type, e.incentive_amount_variance])
            })
            setAvssGraphData(temp);
        }
    }, [props.contractVarianceGraphDataloadContractVarianceGraph]);

    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [applicationType, setApplicationType] = React.useState('Customer Rebate');
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        props.loadContractVarianceGraph(Moment(dateRangeValue[0]).format('MM/YYYY'), Moment(dateRangeValue[1]).format('MM/YYYY'), null, applicationType, e.target.value);
    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
    }, []);
    useEffect(()=>{
        props.allowedApps();
    },[])
    
    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    return (
        <div className={classes.rootDiv}>
            <Grid container className={classes.root}>
                <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        style={{ paddingRight: 15 }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel
                                style={{ color: 'grey', fontSize: 14, marginBottom: 5 }} >
                                Application Type
                            </FormLabel>
                            <Select
                                value={applicationType}
                                onChange={handleApplicationtype}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {allowedApps.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        xs={12}
                        style={{ paddingRight: 15 }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel
                                style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}>
                                Source Data Type
                            </FormLabel>
                            <Select
                                value={sourceDataType}
                                onChange={handleSourceDataType}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {sourceDataTypeDataFromAPI
                                    .map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        style={{ paddingRight: 15 }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}>
                                Date
                            </FormLabel>
                            <DateRangePicker
                                block
                                hoverRange="month" ranges={[]}
                                placeholder="Date range"
                                width={500}
                                value={dateRangeValue}
                                onChange={handleDateRange}
                                style={{ paddingRight: 48 }}
                                placement="auto"
                            />
                        </div>
                    </Grid>

                    <Grid
                        item
                        md={4}
                        xs={12}
                        style={{ paddingRight: 15 }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}>
                                Variance between
                            </FormLabel>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 33 }}>
                                <OutlinedInput
                                    value={accrualFilter}
                                    type={'number'}
                                    inputProps={{ min: "0", step: "1" }}
                                    onChange={e => setAccrualFilter(e.target.value)}
                                    style={{ borderRadius: 5 }}
                                />
                                <Typography variant="h5" style={{ textAlign: 'center', margin: 5, color: 'grey' }} > And</Typography>

                                <OutlinedInput
                                    value={accrualFilterEnd}
                                    type={'number'}
                                    inputProps={{ min: "0", step: "1" }}
                                    onChange={e => setAccrualFilterEnd(e.target.value)}
                                    style={{ borderRadius: 5 }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    item
                    md={12}
                    xs={6}
                    className={classes.container}
                >
                    {props.contractVarianceGraphDataloadContractVarianceGraph && props.contractVarianceGraphDataloadContractVarianceGraph.length > 0 ?
                        <div>
                            <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" > Contract Variance </Typography>
                            
                            {accrualFilter && accrualFilterEnd
                                ?
                                avssGraphData.filter(item => (item[0] === 'contract_type')
                                    || (item[1] <= accrualFilterEnd && item[1] >= accrualFilter)
                                    || (Math.abs(item[1]) <= accrualFilterEnd && Math.abs(item[1]) >= accrualFilter)
                                ).length > 1
                                    ?
                                    <Chart
                                        height={600}
                                        chartType="BarChart"
                                        loader={<div>Loading Chart</div>}
                                        data={accrualFilter && accrualFilterEnd ? avssGraphData.filter(item => (item[0] === 'contract_type')
                                            || (item[1] <= accrualFilterEnd && item[1] >= accrualFilter)
                                            || (Math.abs(item[1]) <= accrualFilterEnd && Math.abs(item[1]) >= accrualFilter)
                                        ) : []}
                                        rootProps={{ 'data-testid': '2' }}
                                        options={{
                                            legend: { position: 'bottom', alignment: 'center' },
                                            chartArea: { 'height': '80%' },
                                            colors: ['#E89948'],
                                            hAxis: { minValue: 0 }
                                        }}
                                    />
                                    :
                                    <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" > No data to show</Typography>
                                :
                                <div>
                                    <Chart
                                        height={600}
                                        chartType="BarChart"
                                        loader={<div>Loading Chart</div>}
                                        data={avssGraphData}
                                        rootProps={{ 'data-testid': '2' }}
                                        options={{
                                            legend: { position: 'bottom', alignment: 'center' },
                                            chartArea: { 'height': '80%' },
                                            colors: ['#E89948'],
                                            hAxis: { minValue: 0 }

                                        }}
                                    />
                                </div>
                            }
                        </div>
                        :
                        <Typography variant="\5" style={{ textAlign: 'center' }} color="primary" > No data to show</Typography>
                    }
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        contractVarianceGraphDataloadContractVarianceGraph: state.initialData.contractVarianceGraphData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadContractVarianceGraph: (s, e, type, applicationType, sourceDataType) => dispatch(loadContractVarianceGraph(s, e, 'cv', applicationType, sourceDataType)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractVariance);