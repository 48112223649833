import React from 'react';
import { connect } from 'react-redux';
import { EmployeeMasterUserTable } from './components';

const EmployeeMaster = props => {
    return (
        <div>
            <EmployeeMasterUserTable />
        </div >
    );
};
export default connect(null,null)(EmployeeMaster);