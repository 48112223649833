import React, { useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import TabContext from "@mui/lab/TabContext";
//import TabList from "@mui/material//TabList";
import { connect } from "react-redux";
import momentt from "moment-timezone";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Breadcrumbs,
  Typography,
  Button,
  useMediaQuery,
  Fab, Paper, FormLabel, Switch
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { downloadContractTrendsResltsSheet } from "../../../redux/actions";
import MaterialTable from "material-table";
import clsx from "clsx";
import { tableIcons } from "../../../components/Icons/TableIcons";
import { Link } from "react-router-dom";
import { single } from "validate.js";
import GetAppIcon from "@mui/icons-material/GetApp";

momentt.tz.setDefault("Etc/UTC");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 10,
    // padding: 29,
    // marginTop: 16,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    // marginTop: 25,
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize,
  },
  toggleButton: {
    border: '1px solid',
    borderRadius: 25,
    height: 34,
    width: 61,
    display: 'flex',
    alignItems: 'center',
  },
}));

const theme2 = createTheme({
  overrides: {
    MuiTabPanel: {
      root: {
        padding: 0,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#CBD1E3 !important",
        "& .MuiTableCell-head": {
          padding: 5,
        },
      },
    },
    MuiTableCell: {
      root: {
        textAlign: "center !important",
      },
    },
  },
  headerStyle: {
    overflowX: "initial"
    //height:700
  }
});
const ContractTrendsResult = (props) => {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [contractHeads, setContractHeads] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [apiData, setApiData] = React.useState(0);
  const [shrinkData, setShrinkData] = React.useState(false);
  const classes = useStyles();
  const contractType = history.location.data;
  const rebateHeading = [
    "Rebate Formula",
    "Rebate Formula Value",
    "Incentive Basis",
    "Incentive Rate",
    "Incentive Calculated",
    "Incentive Amount",
  ];
  const handleSheetDownload = () => {
    var formData = JSON.parse(sessionStorage.getItem("contractTrendsQuery"));
    if (formData) {
      console.log("formd", formData);
      props.downloadSheet(formData);
    }
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setValue(newValue);
  };
  const handleShrinkChecked = (newValue) => {
    setShrinkData(newValue);
  };

  useEffect(() => {
    if (contractType.type == "contractV1") {
      if (props.contractTrendsResult && props.contractTrendsResult.length > 0) {
        var heading = [];
        props.contractTrendsResult.map((item) => {
          heading.push(Object.keys(item)[0] ? Object.keys(item)[0] : null);
        });
        setContractHeads(heading);
        setApiData(props.contractTrendsResult);
      } else {
        setContractHeads([]);
        setApiData(0);
      }
    }
  }, [props.contractTrendsResult]);
  useEffect(() => {
    if (contractType.type != "contractV1") {
      if (
        props.contractTrendsV2Result &&
        props.contractTrendsV2Result.length > 0
      ) {
        var heading = [];
        props.contractTrendsV2Result.map((item) => {
          heading.push(Object.keys(item)[0] ? Object.keys(item)[0] : null);
        });
        setContractHeads(heading);
        setApiData(props.contractTrendsV2Result);
      }
      // else {
      //   setContractHeads([]);
      //   setApiData(0);
      // }
    }
  }, [props.contractTrendsV2Result]);

  return <>
    <Fab
      variant="extended"
      color="primary"
      onClick={handleSheetDownload}
      style={{
        position: "fixed",
        bottom: 10,
        right: 10,
      }}
    >
      <GetAppIcon />
      Download
    </Fab>
    {apiData && Object.keys(apiData).length > 0 && (
      <div >
        <Box sx={{ width: shrinkData === false ? "100%" : "150%", height: '100%', background: "white" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", justifyContent: "space-between" }} /*style={{position:'fixed',top:70}} */ >
            <Tabs
              value={activeTab}
              onChange={handleChange}
              // variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"

            >
              {contractHeads.map((item) => {
                return (
                  <Tab label={item} {...a11yProps(0)} style={{ padding: 20 }} />
                );
              })}
            </Tabs>
            <div>
              <div style={{ display: 'flex', marginTop: "15px", marginRight: "15px" }}>
                <Typography style={{ marginLeft: 5 }}>Summary</Typography>
                <span className={classes.toggleButton} >
                  <Switch
                    checked={shrinkData}
                    onClick={(e) => handleShrinkChecked(e.target.checked)}
                    disabled={false} />
                </span>
                <Typography>Details</Typography>
              </div>
            </div>
          </Box>

          {contractHeads.map((singleItem, ix) => {
            return (
              <TabPanel value={activeTab} index={ix} /*style={{position:'absolute',top:155,width:'100%',height:'700px'}}*/>
                <div style={{ overflowY: 'scroll', height: window.screen.height * .85/*'600px'*/ }}>

                  <TableContainer>
                    {shrinkData ?
                      <Table
                        stickyHeader
                        classes={{ root: classes.fontSetting }}
                        style={{ marginTop: "0rem" }}

                      >
                        <TableHead >
                          <TableRow >
                            {apiData[ix] &&
                              apiData[ix][singleItem] &&
                              apiData[ix][singleItem][0] &&
                              Object.keys(apiData[ix][singleItem][0]).map(function (
                                key,
                                index
                              ) {
                                return apiData[ix][singleItem][0][key]["attribute"] &&
                                  Object.keys(
                                    apiData[ix][singleItem][0][key]["attribute"]
                                  ).map((item) => {
                                    return (
                                      <TableCell
                                        classes={{ root: classes.fontSetting }}
                                        style={{
                                          textTransform: "capitalize",
                                          padding: 10,
                                          width: 'auto',
                                          position: 'sticky', top: 0
                                        }}
                                      >
                                        {item.replace(/_/g, " ")}
                                      </TableCell>
                                    );
                                  });
                              })}

                            {apiData[ix] &&
                              apiData[ix][singleItem] &&
                              apiData[ix][singleItem][0] &&
                              Object.keys(apiData[ix][singleItem][0]).map(function (
                                key,
                                index
                              ) {
                                return apiData[ix][singleItem][0][key]["tier"] &&
                                  Object.keys(
                                    apiData[ix][singleItem][0][key]["tier"]
                                  )
                                    .sort(function (a, b) {
                                      return apiData[ix]['tier_key_sort_order'].indexOf(a) - apiData[ix]['tier_key_sort_order'].indexOf(b);
                                    })
                                    .map((item) => {
                                      return (
                                        <TableCell
                                          classes={{ root: classes.fontSetting }}
                                          style={{ textTransform: "capitalize", width: 'auto', position: 'sticky', top: 0 }}
                                        >
                                          {item.replace(/_/g, " ")}
                                        </TableCell>
                                      );
                                    });
                              })}
                            {apiData[ix] &&
                              apiData[ix][singleItem] &&
                              apiData[ix][singleItem][0] &&
                              Object.keys(apiData[ix][singleItem][0]).map(function (
                                key,
                                index
                              ) {
                                // return rebateHeading.map((item) => {
                                return (
                                  apiData[ix][singleItem][0][key]["rebate"] &&
                                  Object.keys(
                                    apiData[ix][singleItem][0][key]["rebate"]
                                  )
                                    // .sort(
                                    //   (a, b) =>
                                    //     rebateHeading.indexOf(a) -
                                    //     rebateHeading.indexOf(b)
                                    // )
                                    .sort(function (a, b) {
                                      return apiData[ix]['rebate_key_sort_order'].indexOf(a) - apiData[ix]['rebate_key_sort_order'].indexOf(b);
                                    })
                                    .map((item) => {
                                      return (
                                        <TableCell
                                          classes={{ root: classes.fontSetting }}
                                          style={{ width: 'auto', position: 'sticky', top: 0 }}
                                        >
                                          {item}
                                        </TableCell>
                                      );
                                    })
                                );
                              })}
                          </TableRow>
                        </TableHead>

                        <TableBody //sstyle={{position:'relative',tableLayout:'fixed',width:'100%',top:40}}
                        >
                          {apiData[ix] &&
                            apiData[ix][singleItem] &&
                            apiData[ix][singleItem].map((mainItem, mainIx) => {
                              return (
                                <TableRow>
                                  {mainItem &&
                                    Object.keys(mainItem) &&
                                    Object.keys(mainItem).map(function (
                                      key,
                                      index
                                    ) {
                                      return (
                                        mainItem[key] &&
                                        mainItem[key]["attribute"] &&
                                        Object.entries(
                                          mainItem[key]["attribute"]
                                        ).map(([key, value]) => {
                                          return (
                                            <TableCell
                                              classes={{
                                                root: classes.fontSetting,
                                              }}
                                            >
                                              {value}{" "}
                                            </TableCell>
                                          );
                                        })
                                      );
                                    })}

                                  {mainItem &&
                                    Object.keys(mainItem) &&
                                    Object.keys(mainItem).map(function (
                                      key,
                                      index
                                    ) {
                                      return (
                                        mainItem[key] &&
                                        mainItem[key]["tier"] &&
                                        Object.entries(mainItem[key]["tier"])
                                          .sort(function (a, b) {
                                            return apiData[ix]['tier_key_sort_order'].indexOf(a[0]) - apiData[ix]['tier_key_sort_order'].indexOf(b[0]);
                                          })

                                          .map(
                                            ([key, value]) => {
                                              return (
                                                <TableCell
                                                  classes={{
                                                    root: classes.fontSetting,
                                                  }}
                                                >

                                                  {
                                                    // value && parseFloat(value)
                                                    //   ? parseFloat(
                                                    //       value
                                                    //     ).toLocaleString(undefined, {
                                                    //       minimumFractionDigits: 2,
                                                    //       maximumFractionDigits: 2,
                                                    //     })
                                                    //   : 
                                                    value
                                                  }{" "}
                                                </TableCell>
                                              );
                                            }
                                          )
                                      );
                                    })}

                                  {
                                    /*rebateHeading.map((mh) => {*/
                                    // return (
                                    mainItem &&
                                    Object.keys(mainItem) &&
                                    Object.keys(mainItem).map(function (
                                      key,
                                      index
                                    ) {
                                      return (
                                        mainItem[key] &&
                                        mainItem[key]["rebate"] &&
                                        Object.entries(mainItem[key]["rebate"])
                                          // .sort(
                                          //   ([k1, v1], [k2, v2]) =>
                                          //     rebateHeading.indexOf(k1) -
                                          //     rebateHeading.indexOf(k2)
                                          // )
                                          .sort(function (a, b) {
                                            return apiData[ix]['rebate_key_sort_order'].indexOf(a[0]) - apiData[ix]['rebate_key_sort_order'].indexOf(b[0]);
                                          })
                                          .map(([key, value]) => {
                                            return (
                                              // mh == key && (
                                              <TableCell
                                                classes={{
                                                  root: classes.fontSetting,
                                                }}
                                              >
                                                {
                                                  // (value.includes(' ')||value.includes('+')||value.includes('-')||value.includes('/')||value.includes('*'))?
                                                  // value:
                                                  // value && parseFloat(value)
                                                  //   ? parseFloat(
                                                  //       value
                                                  //     ).toLocaleString(undefined, {
                                                  //       minimumFractionDigits: 2,
                                                  //       maximumFractionDigits: 2,
                                                  //     })
                                                  //   :
                                                  value}{" "}
                                              </TableCell>
                                              // )
                                            );
                                          })
                                      );
                                    })
                                  }
                                  {/* );
                           // })}
                          */}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                      :
                      <Table stickyHeader
                        classes={{ root: classes.fontSetting }}
                        style={{ marginTop: "0rem" }}

                      >
                        <TableHead >
                          <TableRow >
                            {apiData[ix] &&
                              apiData[ix][singleItem] &&
                              apiData[ix][singleItem][0] &&
                              Object.keys(apiData[ix][singleItem][0]).map(function (
                                key,
                                index
                              ) {
                                return apiData[ix][singleItem][0][key]["attribute"] &&
                                  Object.keys(
                                    apiData[ix][singleItem][0][key]["attribute"]
                                  ).map((item) => {
                                    return (
                                      <TableCell
                                        classes={{ root: classes.fontSetting }}
                                        style={{
                                          textTransform: "capitalize",
                                          padding: 10,
                                          width: 'auto',
                                          position: 'sticky', top: 0
                                        }}
                                      >
                                        {item.replace(/_/g, " ")}
                                      </TableCell>
                                    );
                                  });
                              })}

                            {apiData[ix] &&
                              apiData[ix][singleItem] &&
                              apiData[ix][singleItem][0] &&
                              Object.keys(apiData[ix][singleItem][0]).map(function (
                                key,
                                index
                              ) {
                                return apiData[ix][singleItem][0][key]["tier"] &&
                                  Object.keys(
                                    apiData[ix][singleItem][0][key]["tier"]
                                  )
                                    .filter((tierKey) =>
                                      apiData[ix]["tier_key_sort_order_shrink"].includes(tierKey)
                                    )
                                    .sort(function (a, b) {
                                      return apiData[ix]['tier_key_sort_order_shrink'].indexOf(a) - apiData[ix]['tier_key_sort_order_shrink'].indexOf(b);
                                    })
                                    .map((item) => {
                                      return (
                                        <TableCell
                                          classes={{ root: classes.fontSetting }}
                                          style={{ textTransform: "capitalize", width: 'auto', position: 'sticky', top: 0 }}
                                        >
                                          {item.replace(/_/g, " ")}
                                        </TableCell>
                                      );
                                    });
                              })}
                            {apiData[ix] &&
                              apiData[ix][singleItem] &&
                              apiData[ix][singleItem][0] &&
                              Object.keys(apiData[ix][singleItem][0]).map(function (
                                key,
                                index
                              ) {
                                // return rebateHeading.map((item) => {
                                return (
                                  apiData[ix][singleItem][0][key]["rebate"] &&
                                  Object.keys(
                                    apiData[ix][singleItem][0][key]["rebate"]
                                  )
                                    // .sort(
                                    //   (a, b) =>
                                    //     rebateHeading.indexOf(a) -
                                    //     rebateHeading.indexOf(b)
                                    // )
                                    .filter((tierKey) =>
                                      apiData[ix]["rebate_key_sort_order_shrink"].includes(tierKey)
                                    )
                                    .sort(function (a, b) {
                                      return apiData[ix]['rebate_key_sort_order_shrink'].indexOf(a) - apiData[ix]['rebate_key_sort_order_shrink'].indexOf(b);
                                    })
                                    .map((item) => {
                                      return (
                                        <TableCell
                                          classes={{ root: classes.fontSetting }}
                                          style={{ width: 'auto', position: 'sticky', top: 0 }}
                                        >
                                          {item}
                                        </TableCell>
                                      );
                                    })
                                );
                              })}
                          </TableRow>
                        </TableHead>

                        <TableBody //sstyle={{position:'relative',tableLayout:'fixed',width:'100%',top:40}}
                        >
                          {apiData[ix] &&
                            apiData[ix][singleItem] &&
                            apiData[ix][singleItem].map((mainItem, mainIx) => {
                              return (
                                <TableRow>
                                  {mainItem &&
                                    Object.keys(mainItem) &&
                                    Object.keys(mainItem).map(function (
                                      key,
                                      index
                                    ) {
                                      return (
                                        mainItem[key] &&
                                        mainItem[key]["attribute"] &&
                                        Object.entries(
                                          mainItem[key]["attribute"]
                                        ).map(([key, value]) => {
                                          return (
                                            <TableCell
                                              classes={{
                                                root: classes.fontSetting,
                                              }}
                                            >
                                              {value}{" "}
                                            </TableCell>
                                          );
                                        })
                                      );
                                    })}

                                  {mainItem &&
                                    Object.keys(mainItem) &&
                                    Object.keys(mainItem).map(function (
                                      key,
                                      index
                                    ) {
                                      // return (
                                      //   mainItem[key] &&
                                      //   mainItem[key]["tier"] &&
                                      //   Object.entries(mainItem[key]["tier"])
                                      //     .sort(function (a, b) {
                                      //       return apiData[ix]['tier_key_sort_order'].indexOf(a[0]) - apiData[ix]['tier_key_sort_order'].indexOf(b[0]);
                                      //     })
                                      return (
                                        mainItem[key] &&
                                        mainItem[key]["tier"] &&
                                        Object.entries(mainItem[key]["tier"])
                                          .filter(([tierKey]) =>
                                            apiData[ix]["tier_key_sort_order_shrink"].includes(tierKey)
                                          )
                                          .sort(function (a, b) {
                                            return (
                                              apiData[ix]["tier_key_sort_order"].indexOf(a[0]) -
                                              apiData[ix]["tier_key_sort_order"].indexOf(b[0])
                                            );
                                          })

                                          .map(
                                            ([key, value]) => {
                                              return (
                                                <TableCell
                                                  classes={{
                                                    root: classes.fontSetting,
                                                  }}
                                                >

                                                  {
                                                    // value && parseFloat(value)
                                                    //   ? parseFloat(
                                                    //       value
                                                    //     ).toLocaleString(undefined, {
                                                    //       minimumFractionDigits: 2,
                                                    //       maximumFractionDigits: 2,
                                                    //     })
                                                    //   : 
                                                    value
                                                  }{" "}
                                                </TableCell>
                                              );
                                            }
                                          )
                                      );
                                    })}

                                  {
                                    /*rebateHeading.map((mh) => {*/
                                    // return (
                                    mainItem &&
                                    Object.keys(mainItem) &&
                                    Object.keys(mainItem).map(function (
                                      key,
                                      index
                                    ) {
                                      return (
                                        mainItem[key] &&
                                        mainItem[key]["rebate"] &&
                                        Object.entries(mainItem[key]["rebate"])
                                          .filter(([tierKey]) =>
                                            apiData[ix]["rebate_key_sort_order_shrink"].includes(tierKey)
                                          )
                                          .sort(function (a, b) {
                                            return (
                                              apiData[ix]["rebate_key_sort_order_shrink"].indexOf(a[0]) -
                                              apiData[ix]["rebate_key_sort_order_shrink"].indexOf(b[0])
                                            );
                                          })
                                          .map(([key, value]) => {
                                            return (
                                              // mh == key && (
                                              <TableCell
                                                classes={{
                                                  root: classes.fontSetting,
                                                }}
                                              >
                                                {
                                                  // (value.includes(' ')||value.includes('+')||value.includes('-')||value.includes('/')||value.includes('*'))?
                                                  // value:
                                                  // value && parseFloat(value)
                                                  //   ? parseFloat(
                                                  //       value
                                                  //     ).toLocaleString(undefined, {
                                                  //       minimumFractionDigits: 2,
                                                  //       maximumFractionDigits: 2,
                                                  //     })
                                                  //   :
                                                  value}{" "}
                                              </TableCell>
                                              // )
                                            );
                                          })
                                      );
                                    })
                                  }
                                  {/* );
                           // })}
                          */}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    }
                  </TableContainer>
                </div>
              </TabPanel>
            );
          })}
        </Box>
      </div>
    )}
  </>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadSheet: (formData) =>
      dispatch(downloadContractTrendsResltsSheet(formData)),
  };
};

const mapStateToProps = (state) => {
  return {
    contractTrendsResult:
      state.addMultipleConfigurationData.contractTrendsResult,
    contractTrendsV2Result:
      state.addMultipleConfigurationData.contractTrendsV2Result,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractTrendsResult);
