import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Typography, IconButton, Paper, Button,
    AccordionActions
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { accessInnerChildren, generateCombinations } from './ManipulateData';

const useStyles = makeStyles(theme => ({
    root: {
        //position: 'sticky',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2,
        position: 'absolute',
        marginTop: 20,

        //backgroundColor: 'transparent'
    },
    dropdownContainer: {
        maxHeight: '350px', // Set maximum height
        overflowY: 'auto', // Enable vertical scrolling
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,
        backgroundColor: 'white'
    },
    accordionMenu: {
        padding: 10,
        maxHeight: 30,
        margin: 0,
        // borderBottom: "0.8px solid #E2E2E2",
    },

    accordionHead: {
        padding: 0,
        minHeight: 30,
        margin: " 0px !important",
        width: 'fit-content'
        // backgroundColor: 'transparent'
        //  borderBottom: "0.8px solid #E2E2E2",
    },
    accordionAction: {
        backgroundColor: 'white',
        border: "0.8px solid #E2E2E2",
        margin: '0px 5px'
    },
    accordionRoot: {
        backgroundColor: 'transparent',
        margin: 0,

    },
    muiExpand: {
        minHeight: "30px !important",
        margin: '0px !important'
    }
}));

const NestedDropdown = (props) => {
    const classes = useStyles();


    const [checkedItems, setCheckedItems] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [anchorEl, setAnchorEl] = useState(false);
    const [checkedData, setCheckedData] = useState([]);
    const [selectedChild, setSelectedChild] = useState([]);

    useEffect(() => {
        setExpanded(props.expand)
    }, [props.expand])
    useEffect(() => {
        setSelectedChild(props.prevalue ? props.prevalue : [])
        setCheckedItems(props.selected ? props.selected : [])
    }, [props.prevalue, props.selected])

    const handleCheckboxChange = (item) => (event) => {
        event.stopPropagation(); // Stop event propagation

        if (item.children) {
            if (event.target.checked) {
                setCheckedData([...checkedData, Object.values(item.relationship).join("_")])
                const selected = accessInnerChildren(item)//access all childs
                console.log(selected)
                // setCheckedItems({...checkedItems, ...(selected && selected.children)})
                setCheckedItems(prev => ({
                    ...prev, ...(selected && selected.children)
                }))
                setSelectedChild(prev => ({
                    ...prev, ...(selected && selected.relationships)
                }))
                // setSelectedChild([...selectedChild, ...(selected && selected.relationships)])
            }
            else {
                setCheckedData(checkedData.filter(itemx => itemx != item.relationship))
                var data = checkedItems && Object.entries(checkedItems).filter(([k, v]) => !k.startsWith(Object.values(item.relationship).join("_"))).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                setCheckedItems(data)
                var array = Object.values(item.relationship)
                const combinations = generateCombinations(array, item.fields && item.fields.product_line)// select all the parents
                var childData = selectedChild && Object.entries(selectedChild).filter(
                    ([itemx, value]) => !itemx.toString().startsWith(Object.values(item.relationship).join("_")) && !(combinations.includes(itemx)))
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                setSelectedChild(childData);

            }
        }
        else {
            var key = [...Object.values(item.relationship), ...item.fields?.product_line ? item.fields.product_line : []].join("_").toString()
            if (event.target.checked) {
                setCheckedItems(prev => ({
                    ...prev, [key]: { ...item.relationship, 'product_line': item.fields?.product_line ? item.fields.product_line : [] }
                }))
                setSelectedChild(prev => ({
                    ...prev, [key]: {}
                }))
                setCheckedData([...checkedData, item.relationship])
            }
            else {
                setCheckedData(checkedData.filter(itemx => itemx != item.relationship))
                var data = checkedItems && Object.entries(checkedItems).filter(([k, v]) => k != key)
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                setCheckedItems(data)
                var array = Object.values(item.relationship)
                const combinations = generateCombinations(array, item.fields && item.fields.product_line);
                var child = Object.entries(selectedChild).filter(([itemx, value]) => !(combinations.includes(itemx))).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                setSelectedChild(child)
            }
        }

    };

    const handleAccordionChange = (panel, type) => (event, isExpanded) => {
        if (type == 'menu-head')
            setExpanded(isExpanded ? [...expanded, panel] : []);
        else
            setExpanded(isExpanded ? [...expanded, panel + "-" + type] : expanded.filter(item => item != panel + "-" + type));
    };

    const renderNestedItems = (items) => {
        return items && items.map((item, index) => {
            var key = [...Object.values(item.relationship), ...(item.fields?.product_line ? item.fields.product_line : [])].join("_")
            return (
                <div key={index}>
                    {item.children && item.children.length > 0 ? (
                        <Accordion
                            expanded={expanded.includes(item.category + "-" + item.type)}
                            onChange={handleAccordionChange(item.category, item.type)}
                            style={{
                                margin: 0, borderBottom: (item.type == 'material_group1' || item.type == 'material_group2' || item.type == 'country' || item.type == 'region')
                                    && "0.8px solid #E2E2E2"
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                className={classes.accordionMenu}
                                style={{ padding: item.type == 'material_group3' || item.type == 'state' ? 20 : 10 }}
                            >
                                <Typography variant='h4'
                                    color={selectedChild[key] ? 'black' : 'grey'}
                                >
                                    {(item.type !== 'material_group1' || item.type == 'country') && <Checkbox
                                        checked={
                                            selectedChild[key] ? true : false
                                        }
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={handleCheckboxChange(
                                            item
                                        )}
                                    />}
                                    {item.category}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: 0 }}
                            >
                                {renderNestedItems(item.children)}
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <div style={{ display: 'flex', paddingLeft: 30 }}>
                            <Typography variant='h4' color={checkedData && checkedData.indexOf(item.relationship) > -1 ? 'black' : 'grey'}>
                                {' '}
                                <Checkbox
                                    checked={selectedChild[key] ? true : false
                                        //selectedChild && selectedChild.length > 0 && selectedChild.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) ? true : false
                                    }
                                    onChange={handleCheckboxChange(item)}
                                />
                                {item.category}
                            </Typography>
                        </div>
                    )}
                </div>
            )
        });
    };


    const handleCancel = (event) => {
        event.stopPropagation();
        setExpanded([])
        props.onSubmit(null, null, 'cancel')
        // Handle cancel button click
    };

    const handleConfirm = (event) => {
        setExpanded([])
        props.onSubmit(checkedItems, selectedChild)
        //props.onSubmit(checkedData, checkedItems, selectedChild)
    };
    const handleClick = () => {
        setAnchorEl(!anchorEl)
        const isExpanded = !anchorEl
        setExpanded(isExpanded ? [...expanded, 'model_filter'] : []);
    };
    return (
        <div className={classes.root}>
            {expanded && expanded.length > 0 &&
                <Accordion
                    id={props.id}
                    expanded={expanded.includes(props.type)}
                    // onChange={handleAccordionChange('model_filter', 'menu-head')}
                    className={classes.accordionRoot}
                >
                    <AccordionSummary
                        //expandIcon={<ExpandMore />}
                        className={classes.accordionHead}
                        classes={{
                            expanded: classes.muiExpand, // Override styles when Accordion is expanded
                        }}
                        style={{ display: 'none' }}
                    >
                        <Typography variant='h4' color='grey'>
                            {props.head}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 5 }}>
                        <div className={classes.dropdownContainer}>
                            {renderNestedItems(props.data)}
                        </div>
                    </AccordionDetails>
                    {/* <AccordionActions className={classes.accordionAction}> */}
                    <Paper
                        style={{
                            padding: '10px',
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: 'white',
                            justifyContent: 'flex-end',
                            display: 'flex',
                            zindex: 2
                        }}
                        className={classes.accordionAction}
                    >
                        <Button onClick={handleCancel} style={{ cursor: 'pointer' }}>Cancel</Button>
                        <Button onClick={handleConfirm} style={{ cursor: 'pointer' }} variant="contained" sx={{ ml: 1 }}>
                            Confirm
                        </Button>
                    </Paper>
                    {/* </AccordionActions> */}
                </Accordion>
            }
        </div>
    );
};

export default NestedDropdown;
