import React from 'react';
import { connect } from 'react-redux';
import { ContractXREFUserTable } from './components';

const ContractXREF = () => {

    return (
        <div>
            <ContractXREFUserTable />
        </div >
    );
};
export default connect(null,null)(ContractXREF);