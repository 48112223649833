import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Link
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {  getMembershipDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
}));
const EditMembership = props => {
    const classes = useStyles();
    const history = useHistory();
    const [membershipOwnerId, setMembershipOwnerId] = React.useState(false);
    const [membershipOwnerName, setMembershipOwnerName] = React.useState(false);
    const [membershipOwnerIdType, setMembershipOwnerIdType] = React.useState('');
    const [membershipOwnerIdNumber, setMembershipOwnerIdNumber] = React.useState('');
    const [classOfTrade, setClassOfTrade] = React.useState('');
    const [memberIdType, setMemberIdType] = React.useState('');
    const [memberIdNumber, setMemberIdNumber] = React.useState('');
    const [memberId, setMemberId] = React.useState('');
    const [memberNumber, setMemberNumber] = React.useState('');
    const [memberName, setMemberName] = React.useState('');
    const [membershipStartDate, setMembershipStartDate] = React.useState(null);
    const [membershipEndDate, setMembershipEndDate] = React.useState(null);
    const [reference, setReference] = React.useState('');
    const [editID, setEditID] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [action, setAction] = React.useState('');
    const [actionDescription, setActionDescription] = React.useState('');
    const [parentMemberID, setParentMemberID] = React.useState('');
    const [topParentMemberID, setTopParentMemberID] = React.useState('');
    const [secondaryClassofTrade, setSecondaryClassofTrade] = React.useState('');
    const [organizationStatus, setOrganizationStatus] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [memberStatus, setMemberStatus] = React.useState('');
    const [relationshipToTopParent,setRelationshipToTopParent] = React.useState('');
    const [actionEffectiveData, setActionEffectiveData] = React.useState(null);
    const [imaClassOfTrade, setImaClassOfTrade] = React.useState(''); 
    const [parentCustomerNumber,setParentCustomerNumber] = React.useState('');
    const [topParentCustomerNumber,setTopParentCustomerNumber] = React.useState('');
    const [directparentMemberNumber, setDirectParentMemberNumber] = React.useState('');
    const [parentMemberName,setParentMemberName] = React.useState('');
    const [topParentMemberNumber, setTopParentMemberNumber] = React.useState('');
    const [topParentMemberName, setTopParentMemberName] = React.useState('');
    const [contractNumber,setContractNumber] = React.useState('');
    const [contractDescription,setContractDescription] = React.useState('');
    const [contractType,setContractType] = React.useState('');
    const [contractStartDate,setContractStartDate] = React.useState(null);
    const [contractEndDate,setContractEndDate] = React.useState(null);
    const [priecProgram,setPriceProgram] = React.useState('');
    const [priecProgramDes,setPriceProgramDes] = React.useState('');
    const [priceStartDate,setPriceStartDate] = React.useState(null);
    const [priceEndDate,setPriceEndDate] = React.useState(null);
    const [tierNum,setTierNum] = React.useState('');
    const [tierDes,setTierDes] = React.useState('');
    const [tierStatus,setTierStatus] = React.useState('');
    const [customerName, setCustomerName] = React.useState('');
    const [gpoName,setGPOName] = React.useState('');
    const [IDGLN,setIDGLN] = React.useState('');
    const [IDHIN,setIDHIN] = React.useState('');
    const [memberID, setMemberID] = React.useState([]);
    const [memberIDType, setMemberIDType] = React.useState([]);
    const [actionCode,setActionCode] = React.useState('');
    const [priority,setPriority] = React.useState('')
    const [gpoMemberNumber,setGPOMemberNumber] = React.useState('');
    const [gpoMemberName,setGPOMemberName] = React.useState('');
    useEffect(() => {
         var pathname = window.location.pathname;
         var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
         editableId = appId;
         if (appId)
             props.getMembershipDetails(appId);
    }, []);
    useEffect(() => {

        if (props.membership) {
            setEditID(props.membership.ID)
            setCustomerNumber(props.membership.customer_number)
            setCustomerName(props.membership.customer_name)
            setStreet(props.membership.street)
            setCity(props.membership.city)
            setState(props.membership.state)
            setPostalCode(props.membership.postal_code)
            setCountry(props.membership.country)
            setGPOName(props.membership.GPO_name)
            setGPOMemberNumber(props.membership.gpo_member_number)
            setGPOMemberName(props.membership.gpo_member_name)
            setActionCode(props.membership.action_code)
            if(props.membership.action_effective_date!= '0001-01-01T00:00:00Z'){
                setActionEffectiveData(props.membership.action_effective_date)
           }
           if(props.membership.membership_start_date!= '0001-01-01T00:00:00Z') {
                setMembershipStartDate(props.membership.membership_start_date);
           }
           setMemberStatus(props.membership.member_status)
            setIDGLN(props.membership.ID_GLN)
            setIDHIN(props.membership.ID_HIN)
            setClassOfTrade(props.membership.class_of_trade);
            setDirectParentMemberNumber(props.membership.direct_parent_member_number)
            setParentMemberName(props.membership.parent_member_name)
            setParentCustomerNumber(props.membership.parent_customer_number)
            setTopParentMemberNumber(props.membership.top_parent_member_number)
            setTopParentMemberName(props.membership.top_parent_member_name)
            setTopParentCustomerNumber(props.membership.top_parent_customer_number)
            setContractNumber(props.membership.contract_number)
            setContractDescription(props.membership.contract_description)
            setContractType(props.membership.contract_type)
            if(props.membership.contract_start_date!= '0001-01-01T00:00:00Z'){
                setContractStartDate(props.membership.contract_start_date)
           }
           if(props.membership.contract_end_date!= '0001-01-01T00:00:00Z') {
                setContractEndDate(props.membership.contract_end_date);
           }
            setPriceProgram(props.membership.price_program)
            setPriceProgramDes(props.membership.price_program_description)
            if(props.membership.price_Start_date!= '0001-01-01T00:00:00Z'){
                setPriceStartDate(props.membership.price_Start_date)
           }
           if(props.membership.price_end_date!= '0001-01-01T00:00:00Z') {
                setPriceEndDate(props.membership.price_end_date);
           }
           setTierNum(props.membership.tier_number)
           setTierDes(props.membership.tier_description)
           setTierStatus(props.membership.tier_status)
           setPriority(props.membership.priority)
           if(props.membership.membership_end_date!= '0001-01-01T00:00:00Z'){
            setMembershipEndDate(props.membership.membership_end_date);
           }
           setOrganizationStatus(props.membership.organization_status)
           setImaClassOfTrade(props.membership.ima_class_of_trade)
           setRelationshipToTopParent(props.membership.relationship_to_top_parent)
           setReference(props.membership.reference)
           setSecondaryClassofTrade(props.membership.secondary_class_of_trade)
           setMemberIDType(props.membership.member_id_type);
           setMemberID(props.membership.member_id_number);
           setMembershipOwnerId(props.membership.membership_owner_id);
           setMembershipOwnerIdNumber(props.membership.membership_owner_id_number)
           setMembershipOwnerIdType(props.membership.membership_owner_id_type)
           setMembershipOwnerName(props.membership.membership_owner_name); 
        }
    }, [props.membership])
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    component="button"  
                    onClick={()=> { history.push('/membership')}}
                >
                    Master Roster
                </Link>
                <Typography color="textPrimary" variant='h1'>View Member</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container  spacing={2} style={{paddingLeft:10}}>
                    
                    {customerNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Customer Number' data={customerNumber} twoline='true' />
                            </Grid>
                        }
                    {customerName &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Customer Name' data={customerName} twoline='true' />
                            </Grid>
                        }
                        {street &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Street Address' data={street} twoline='true' />
                            </Grid>
                        }
                        {city &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='City' data={city} twoline='true' />
                            </Grid>
                        }
                        {state &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='State' data={state} twoline='true' />
                            </Grid>
                        }
                        {postalCode &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Postal Code' data={postalCode} twoline='true' />
                            </Grid>
                        }
                        {country &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Country' data={country} twoline='true' />
                            </Grid>
                        }
                        {gpoName &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='GPO Name' data={gpoName} twoline='true' />
                            </Grid>
                        }
                         {gpoMemberNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='GPO Member Number' data={gpoMemberNumber} twoline='true' />
                            </Grid>
                        }
                         {gpoMemberName &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='GPO Member Name' data={gpoMemberName} twoline='true' />
                            </Grid>
                        }
                        {actionCode &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Action Code' data={actionCode} twoline='true' />
                            </Grid>
                        }
                         {actionEffectiveData &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Action Effective Date' data={Moment(actionEffectiveData).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                         {membershipStartDate &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Membership Start Date' data={Moment(membershipStartDate).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {memberStatus &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Member Status' data={memberStatus} twoline='true' />
                            </Grid>
                        }
                        {IDGLN &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='ID GLN' data={IDGLN} twoline='true' />
                            </Grid>
                        }
                        {IDHIN &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='ID HIN' data={IDHIN} twoline='true' />
                            </Grid>
                        }
                        {classOfTrade &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Class of Trade' data={classOfTrade} twoline='true' />
                            </Grid>
                        }
                        {directparentMemberNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Direct Parent Member Number' data={directparentMemberNumber} twoline='true' />
                            </Grid>
                        }
                     {parentMemberName &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Parent Member Name' data={parentMemberName} twoline='true' />
                            </Grid>
                        }
                        {parentCustomerNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Parent Customer Number' data={parentCustomerNumber} twoline='true' />
                            </Grid>
                        }
                          {topParentMemberNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Top Parent Member Number' data={topParentMemberNumber} twoline='true' />
                            </Grid>
                        }
                          {topParentMemberName &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Top Parent Member Name' data={topParentMemberName} twoline='true' />
                            </Grid>
                        }
                        {topParentCustomerNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Top Parent Customer Number' data={topParentCustomerNumber} twoline='true' />
                            </Grid>
                        }
                        {contractNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Contract Number' data={contractNumber} twoline='true' />
                            </Grid>
                        }
                         {contractDescription &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Contract Description' data={contractDescription} twoline='true' />
                            </Grid>
                        }
                         {contractType &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Contract Type' data={contractType} twoline='true' />
                            </Grid>
                        }
                         {contractStartDate &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Contract Start Date' data={Moment(contractStartDate).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                         {contractEndDate &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Contract End Date' data={Moment(contractEndDate).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {tierNum &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Tier Number' data={tierNum} twoline='true' />
                            </Grid>
                        }
                        {tierDes &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Tier Description' data={tierDes} twoline='true' />
                            </Grid>
                        }
                        {tierStatus &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Tier Status' data={tierStatus} twoline='true' />
                            </Grid>
                        }
                          {priority &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Priority' data={priority} twoline='true' />
                            </Grid>
                        }
                        {membershipEndDate &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Membership End Date' data={Moment(membershipEndDate).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {organizationStatus &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Organization Status' data={organizationStatus} twoline='true' />
                            </Grid>
                        }
                        {imaClassOfTrade &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='IMA Class of Trade' data={imaClassOfTrade} twoline='true' />
                            </Grid>
                        }
                        {relationshipToTopParent &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Relationship to Top Parent' data={relationshipToTopParent} twoline='true' />
                            </Grid>
                        }
                         {reference &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Reference' data={reference} twoline='true' />
                            </Grid>
                        }
                         {secondaryClassofTrade &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Secondary Class of Trade' data={secondaryClassofTrade} twoline='true' />
                            </Grid>
                        }
                         {membershipOwnerId &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Membership Owner ID' data={membershipOwnerId} twoline='true' />
                            </Grid>
                        }
                         {membershipOwnerIdNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Membership Owner ID Number' data={membershipOwnerIdNumber} twoline='true' />
                            </Grid>
                        }
                         {membershipOwnerIdType &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Membership Owner ID Type' data={membershipOwnerIdType} twoline='true' />
                            </Grid>
                        }
                         {membershipOwnerName &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Membership Owner Name' data={membershipOwnerName} twoline='true' />
                            </Grid>
                        }
                         {memberIdType && memberIdType != "" ?
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Member ID Type' data={memberIdType + "\n"} twoline='true' />
                            </Grid>
                            : ""
                        }
                        {memberIdNumber && memberIdNumber != "" ?
                            <Grid
                                item
                                md={3}
                                xs={12}
                            > <LabelText classes={{root:classes.formLabel}} heading='Member ID Number' data={memberIdNumber + "\n"} twoline='true' />
                            </Grid>
                            : ""
                        }                              
                    </Grid>
                </div>         
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        membership: state.customerData.membershipDetailsData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipDetails: (id) => dispatch(getMembershipDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMembership);