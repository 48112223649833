import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader, MenuItem,
    FormLabel, Select, OutlinedInput
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { TextInputWithLabel } from '../../../../components/Inputs';
import { getDefaultValuesSupplier, getAttributeListData, getCustomerMasterViewDetails, getSupplierMasterDropDown } from '../../../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        width: '100%',
        padding: '0px 45px 0px 45px !important',
        marginTop: "15px"
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    selectedItemDropdown: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 9
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
}));

const Ima360Data = (props) => {
    const classes = useStyles();
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const [claimSubmissionExcel, setClaimSubmissionExcel] = React.useState('');
    const [claimResponseExcel, setClaimResponseExcel] = React.useState('');
    const [claimSubmissionEDI, setClaimSubmissionEDI] = React.useState('');
    const [claimResponseEDI, setClaimResponseEDI] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [FlexAttribute2, setFlexAttribute2] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [claimSubmissionExcelFormat, setClaimSubmissionExcelFormat] = React.useState([]);
    const [claimResponseExcelFormat, setClaimResponseExcelFormat] = React.useState([]);
    const [claimSubmissionEDIFormat, setClaimSubmissionEDIFormat] = React.useState([]);
    const [claimResponseEDIFormat, setClaimResponseEDIFormat] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        props.getSupplierMasterDropDown();
    }, []);
    useEffect(() => {
        if (props.supplierDropDown) {
            if (props.supplierDropDown.claim_response_excel_format !== null) {
                setClaimResponseExcelFormat(props.supplierDropDown.claim_response_excel_format.map(item => item.FormatName));
            }
            if (props.supplierDropDown.claim_submission_excel_format !== null) {
                setClaimSubmissionExcelFormat(props.supplierDropDown.claim_submission_excel_format.map(item => item.FormatName))
            }
            if (props.supplierDropDown.claim_submission_edi_mappings !== null) {
                setClaimSubmissionEDIFormat(props.supplierDropDown.claim_submission_edi_mappings.map(item => item.FormatName))
            }
            if (props.supplierDropDown.claim_response_edi_mappings !== null) {
                setClaimResponseEDIFormat(props.supplierDropDown.claim_response_edi_mappings.map(item => item.FormatName))
            }
        }
    }, [props.supplierDropDown]);
    var formData = {
        claim_submission_excel_format: claimSubmissionExcel,
        claim_response_excel_format: claimResponseExcel,
        claim_submission_edi_map: claimSubmissionEDI,
        claim_response_edi_map: claimResponseEDI,
        claim_email_address: emailAddress,
        flex_attribute_1: flexAttribute1,
        flex_attribute_2: FlexAttribute2
    }
    //functions
    function handleClaimSubmissionExcelFormat(newValue) {
        setClaimSubmissionExcel(newValue);
    }
    function handleClaimResponseExcelFormat(newValue) {
        setClaimResponseExcel(newValue);
    }
    function handleClaimSubmissionEDIFormat(newValue) {
        setClaimSubmissionEDI(newValue);
    }
    function handleClaimResponseEDIFormat(newValue) {
        setClaimResponseEDI(newValue);
    }
    function handleEmailAddress(newValue) {
        setEmailAddress(newValue.target.value);
    }
    function handleClaimResponseExcel(newValue) {
        setClaimResponseExcel(newValue);
    }
    function handleClaimResponseEDI(newValue) {
        setClaimResponseEDI(newValue);
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue.target.value);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue.target.value);
    }
    return (
        <div
            className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <CardHeader
                            title="IMA360"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <Grid container spacing={2}
                            style={{ marginBottom: 16 }}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.formLabel} required={fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['mandatory'] : false } style={{ marginTop: 7 }}>
                                    {fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['current'] : 'Claim Submission - Excel Format'}
                                </FormLabel>
                                <Select
                                    value={claimSubmissionExcel}
                                    onChange={(e) => handleClaimSubmissionExcelFormat(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {claimSubmissionExcelFormat && claimSubmissionExcelFormat.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['mandatory'] : false} heading={fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['current'] : 'Claim Submission - Excel Format'} twoline='true' onChange={handleClaimExcelFormat} prevalue={supplierMasterData.claim_submission_excel_format} /> */}
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.formLabel} required={fieldLabel['claim_submission_edi_map'] ? fieldLabel['claim_submission_edi_map']['mandatory'] : false} style={{ marginTop: 7 }}>
                                    {fieldLabel['claim_submission_edi_map'] ? fieldLabel['claim_submission_edi_map']['current'] : 'Claim Submission - EDI Map'}
                                </FormLabel>
                                <Select
                                    value={claimSubmissionEDI}
                                    onChange={(e) => handleClaimSubmissionEDIFormat(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {claimSubmissionEDIFormat && claimSubmissionEDIFormat.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.formLabel} required={fieldLabel['claim_response_excel_format'] ? fieldLabel['claim_response_excel_format']['mandatory'] : false} style={{ marginTop: 7 }}>
                                    {fieldLabel['claim_response_excel_format'] ? fieldLabel['claim_response_excel_format']['current'] : 'Claim Response - Excel Format'}
                                </FormLabel>
                                <Select
                                    value={claimResponseExcel}
                                    onChange={(e) => handleClaimResponseExcelFormat(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {claimResponseExcelFormat && claimResponseExcelFormat.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.formLabel} required={fieldLabel['claim_response_edi_map'] ? fieldLabel['claim_response_edi_map']['mandatory'] : false} style={{ marginTop: 7 }}>
                                    {fieldLabel['claim_response_edi_map'] ? fieldLabel['claim_response_edi_map']['current'] : 'Claim Response - EDI Map'}
                                </FormLabel>
                                <Select
                                    value={claimResponseEDI}
                                    onChange={(e) => handleClaimResponseEDIFormat(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {claimResponseEDIFormat && claimResponseEDIFormat.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['claim_email_address'] ? fieldLabel['claim_email_address']['mandatory'] : false} heading={fieldLabel['claim_email_address'] ? fieldLabel['claim_email_address']['current'] : 'Claim Email Address'} twoline='true' onChange={handleEmailAddress} /> */}
                                <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['claim_email_address'] ? fieldLabel['claim_email_address']['mandatory'] : false} style={{ marginTop: 7 }}>
                                    {fieldLabel['claim_email_address'] ? fieldLabel['claim_email_address']['current'] : 'Claim Email Address'}
                                </FormLabel>
                                <OutlinedInput
                                    value={emailAddress}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleEmailAddress} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} twoline='true' onChange={handleFlexAttribute1} /> */}
                                <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false} style={{ marginTop: 7 }}>
                                    {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute1}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleFlexAttribute1} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} twoline='true' onChange={handleFlexAttribute2} /> */}
                                <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false} style={{ marginTop: 7 }}>
                                    {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}
                                </FormLabel>
                                <OutlinedInput
                                    value={FlexAttribute2}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleFlexAttribute2} />
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );
};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesSupplier()),
        getSupplierMasterDropDown: () => dispatch(getSupplierMasterDropDown()),
    }
}
const mapStateToProps = state => {
    return {
        supplierMasterData: state.customerData.supplierMasterViewData,
        supplierDropDown: state.customerData.supplierMasterDropDown,
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null)(Ima360Data);