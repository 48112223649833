import React, { useEffect, useRef, useMemo } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
import { editCustomerMasterXRefUser, getCustomerMasterXRefDetails, getDefaultValuesCustomerXRef } from '../../redux/actions';
import { connect } from 'react-redux';
import { DropdownArrayForMaster } from '../../components/Inputs';
import countryList from 'react-select-country-list'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import BeatLoader from "react-spinners/BeatLoader";
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        // marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    selectedItem2: {
        color: 'black',
        height: 35,
        border: '1px solid #E0E0E0',
        width: '100%',
        // marginTop: 9
    }
}));

const EditCustomerXMaster = props => {
    const classes = useStyles();
    const countriesList = useMemo(() => countryList().getLabels(), [])
    const [channelPartnerTypeArray, setChannelPartnerTypeArray] = React.useState([]);
    const contractCustomisationUpdation = useRef();
    const [organizationId, setOrganizationId] = React.useState('');
    const [channelPartnerType, setChannelPartnerType] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerEndCustomerId, setChannelPartnerEndCustomerId] = React.useState('');
    const [imaEndCustomerId, setImaEndCustomerId] = React.useState('');
    const [streetAddress, setStreetAddress] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    const [city, setCity] = React.useState('');
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getCustomerMasterXRefDetails(appId);
    }, []);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'channel_partner_type') {
                            setChannelPartnerTypeArray(item);
                        }
                    })
                }
            }
        }
    });
    useEffect(() => {
        if (props.customerMasterXRefData) {
            setChannelPartnerType(props.customerMasterXRefData.channel_partner_type);
            setChannelPartnerId(props.customerMasterXRefData.channel_partner_id);
            setChannelPartnerEndCustomerId(props.customerMasterXRefData.channel_partner_end_customer_id);
            setImaEndCustomerId(props.customerMasterXRefData.ima_end_customer_id);
            setStreetAddress(props.customerMasterXRefData.street_address);
            setCity(props.customerMasterXRefData.city);
            setRegion(props.customerMasterXRefData.region);
            setPostalCode(props.customerMasterXRefData.postal_code);
            setCountry(props.customerMasterXRefData.country);
        }
    }, [props.customerMasterXRefData]);
    function handleChannelPartnerType(event) {
        setChannelPartnerType(event);
    }
    function handleChannelPartnerId(event) {
        setChannelPartnerId(event.target.value);
    }
    function handleChannelPartnerEndCustomerId(event) {
        setChannelPartnerEndCustomerId(event.target.value);
    }
    function handleStreetAddress(event) {
        setStreetAddress(event.target.value);
    }
    function handlePostalCode(event) {
        setPostalCode(event.target.value);
    }
    function handleCity(event) {
        setCity(event.target.value);
    }
    function handleImaEndCustomerId(event) {
        setImaEndCustomerId(event.target.value);
    }
    function handleClear() {
        setOrganizationId('');
        setChannelPartnerType('');
        setChannelPartnerId('');
        setChannelPartnerEndCustomerId('');
        setStreetAddress('');
        setCity('');
        setRegion('');
        setPostalCode('');
        setCountry('');
        setImaEndCustomerId('');
    }
    function handleOnSubmit() {
        var data = {
            "channel_partner_type": channelPartnerType,
            "channel_partner_id": channelPartnerId,
            "channel_partner_end_customer_id": channelPartnerEndCustomerId,
            "ima_end_customer_id": imaEndCustomerId,
            "street_address": streetAddress,
            "region": region,
            "postal_code": postalCode,
            "country": country,
            "city": city
        };
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/customer-x-master'
                >
                    Customer Master XREF
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Customer XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {channelPartnerTypeArray && channelPartnerTypeArray.enabled &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <FormLabel className={classes.formLabel}>
                                            Channel Partner Type
                                        </FormLabel>
                                        <DropdownArrayForMaster heading={''} placeholder={channelPartnerTypeArray.drop_down_values && channelPartnerTypeArray.drop_down_values.length > 0 ? 'Select Channel Partner Type' : '- Please Channel Partner Type'} twoline='true' onChange={handleChannelPartnerType} data={channelPartnerTypeArray.drop_down_values}
                                            prevalue={channelPartnerType} />
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner End Customer ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerEndCustomerId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerEndCustomerId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA End Customer ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaEndCustomerId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleImaEndCustomerId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Street Address
                                        </FormLabel>
                                        <OutlinedInput
                                            value={streetAddress}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleStreetAddress} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            City
                                        </FormLabel>
                                        <OutlinedInput
                                            value={city}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCity} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Country
                                        </FormLabel>
                                        <CountryDropdown
                                            value={country}
                                            onChange={(val) => setCountry(val)}
                                            classes={
                                                classes.selectedItem2
                                            }
                                            priorityOptions={['US']}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Region
                                        </FormLabel>
                                        <RegionDropdown
                                            classes={
                                                classes.selectedItem2
                                            }
                                            country={country}
                                            value={region}
                                            onChange={(val) => setRegion(val)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Postal Code
                                        </FormLabel>
                                        <OutlinedInput
                                            value={postalCode}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePostalCode} />
                                    </div>
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={
                            channelPartnerId &&
                                channelPartnerEndCustomerId && imaEndCustomerId
                                ? false : true
                        }
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        customerMasterXRefData: state.customerData.customerMasterXRefViewData,
        dropdownData: state.customerData.dropdownData,
        loadingAPI: state.customerData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(editCustomerMasterXRefUser(data, editableId)),
        getCustomerMasterXRefDetails: (id) => dispatch(getCustomerMasterXRefDetails(id)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerXMaster);