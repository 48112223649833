import React, { useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Dropdown, Datepicker } from '../../../components/Inputs';
import clsx from 'clsx';
import Moment from 'moment';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // borderTop: '1px solid #EEEEEE',
        borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        marginTop: 16
    },
    data: {
        color: theme.palette.text.grey,
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        marginBottom: 30
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
        // marginTop: 25
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));

const AdminDataContainer = forwardRef((props, ref) => {
    useEffect(() => {
        if (props.editable)
            props.onChange(adminValues);
    });
    const classes = useStyles();
    const { className } = props;
    const createdByRef = useRef(null);
    const createdOnRef = useRef(null);
    const changedByRef = useRef(null);
    const changedOnRef = useRef(null);
    const [createdBy, setCreatedBy] = React.useState('');
    const [createdOn, setCreatedOn] = React.useState('');
    const [changedBy, setChangedBy] = React.useState('');
    const [changedOn, setChangedOn] = React.useState('');
    const [contractData, setContractData] = React.useState({});
    const adminValues = {
        createdBy: createdBy,
        createdOn: createdOn,
        changedBy: changedBy,
        changedOn: changedOn
    };
    const reset = () => {
        setCreatedBy('');
        setCreatedOn('');
        setChangedBy('');
        setChangedOn('');
        createdByRef.current.reset();
        createdOnRef.current.reset();
        changedByRef.current.reset();
        changedOnRef.current.reset();
    };

    useImperativeHandle(ref, () => {
        return {
            reset: reset
        };
    });

    function handleCreatedBy(newValue) {
        setCreatedBy(newValue);
    }
    function handleCreatedOn(newValue) {
        setCreatedOn(newValue);
    }
    function handleChangedBy(newValue) {
        setChangedBy(newValue);
    }
    function handleChangedOn(newValue) {
        setChangedOn(newValue);
    }

    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])

    function checkLabel(name) {
        if (Object.keys(labelNewAPIData).length > 0) {
            var data = Object.entries(labelNewAPIData).filter(([key, name]) => key == name).map(([key, value]) => value.current)
            if (data && data.length > 0)
                return data[0]
            else
                return false

        }

    }
    useEffect(() => {
        if (props.contractData)
            setContractData(props.contractData)
    }, [props.contractData])

    return (
        <div
            className={clsx(classes.root, className)}
        >
            {props.editable ?
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                        style={{ marginTop: '-1.5rem' }}
                    >
                        <CardHeader
                            title="ADMIN DATA"
                            titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                            className={classes.titleSetting}
                        />
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{ root: classes.fontSetting }} heading='Created By' twoline='true' placeholder='Select' onChange={handleCreatedBy} data={createdBy} ref={createdByRef} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading='Created On' twoline='true' onChange={handleCreatedOn} ref={createdOnRef} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{ root: classes.fontSetting }} heading='Changed By' twoline='true' placeholder='Select' onChange={handleChangedBy} data={Moment.utc(changedBy).format('MM/DD/YYYY HH:mm')} ref={changedByRef} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading='Changed On' twoline='true' onChange={handleChangedOn} data={Moment.utc(changedOn).format('MM/DD/YYYY HH:mm')} ref={changedOnRef} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                :
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                        style={{ marginTop: '-1.5rem' }}
                    >
                        <CardHeader
                            title="ADMIN DATA"
                            titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                            className={classes.titleSetting}
                        />
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}  >
                                        {checkLabel('created_by') ? checkLabel('created_by') : 'Created By'}
                                    </FormLabel>
                                    <div className={classes.data} >
                                        {contractData && contractData.created_by && contractData.created_by.username ? contractData.created_by.username : ''}
                                    </div>
                                </Grid>
                                {contractData && contractData.updated_by && contractData.updated_by.username &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                            {checkLabel('updated_by') ? checkLabel('updated_by') : 'Changed By'}
                                        </FormLabel>
                                        <div className={classes.data}>
                                            {contractData && contractData.updated_by && contractData.updated_by.username ? contractData.updated_by.username : ''}
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} >
                                        {checkLabel('created_at') ? checkLabel('created_at') : 'Created At'}
                                    </FormLabel>
                                    <div className={classes.data} >
                                        {contractData && contractData.created_at ? Moment(contractData.created_at).local().format('MM/DD/YYYY HH:mm') : ''}
                                    </div>
                                </Grid>
                                {(contractData && contractData.updated_at && contractData.created_at && contractData.updated_at != contractData.created_at) &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} style={{ marginTop: 4 }}>
                                            {checkLabel('updated_at') ? checkLabel('updated_at') : 'Updated At'}
                                        </FormLabel>
                                        <div className={classes.data}>
                                            {contractData && contractData.updated_at ? Moment(contractData.updated_at).local().format('MM/DD/YYYY HH:mm') : ''}
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </form>
                </Card>
            }
        </div>
    );

});

const mapStateToProps = state => ({
    contractData: state.addContractData.contractGeneralData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
});

export default connect(mapStateToProps, null)(AdminDataContainer);
