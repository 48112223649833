import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    TablePagination,
    IconButton, Dialog, FormLabel,
    DialogContent, Button, DialogActions, Select, MenuItem, Fab
} from '@mui/material';
import Moment from 'moment';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import { getIncomingClaimResponseFormat, getIncomingClaimsQueryList, getIncomingClaims, editIncomingClaim, handleSplit, getIncomingClaimsPayment } from '../../redux/actions';
import { connect } from 'react-redux';
import { AccountBalance, LowPriority, CallSplit, CloudDownload, CloudUpload } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from "@mui/material/Tooltip";
import { history } from '../../components/Helpers';
import { getEnv } from "../../env"
import clsx from 'clsx';
import { tableIcons } from '../../components/Icons/TableIcons';
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        color: '#636363'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    paper: { minWidth: window.screen.width * .30 },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));


const IncomingClaims = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const handleEdit = (id) => {
        history.push('/supplier-incoming-claims/edit-claims/' + id);
    }
    var baseUrl = getEnv()
    const [currentClaimNumber, setCurrentClaimNumber] = React.useState('');
    const [dataRows, setDataRows] = React.useState([]);
    const handleCancelDialog = () => {
        setCurrentClaimNumber('');
        setFormat('');
        setOpenDialog(false)
    }
    const handleDownload = (claimNumber) => {
        setOpenDialog(true);
        setCurrentClaimNumber(claimNumber);
    }
    useEffect(() => {
        var tempRows = [];

        if (props.incomingClaimListData && props.incomingClaimListData.length > 0) {
            props.incomingClaimListData
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        claim_date: e.claim_date ? Moment(e.claim_date).format('MM/DD/YYYY') : '',
                        claim_number: e.claim_number,
                        channel_partner_claim_number: e.channel_partner_claim_number,
                        claim_status: e.claim_status,
                        channel_partner_id: e.channel_partner_id,
                        channel_partner_name: e.channel_partner_name,
                        channel_partner_name: e.channel_partner_name,
                        claim_amount: e.claim_amount ? e.claim_amount.toFixed(2) : '',
                        calculated_amount: e.calculated_amount ? e.calculated_amount.toFixed(2) : '',
                        approved_amount: e.approved_amount ? e.approved_amount.toFixed(2) : '',
                        credit_memo: e.credit_memo

                    });
                })
            setDataRows(tempRows);
        }
    }, [props.incomingClaimListData]);
    const columns = [
        {
            field: 'claim_date',
            title: 'Claim Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.claim_date}</a>
        },
        {
            field: 'claim_number',
            title: 'Claim Number',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} > {rowData.claim_number}</a>
        },
        {
            field: 'channel_partner_claim_number',
            title: 'CP Claim Number',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.channel_partner_claim_number}</a>
        },
        {
            field: 'claim_status',
            title: 'Claim Status',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.claim_status}</a>
        },
        {
            field: 'channel_partner_id',
            title: 'CP ID',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.channel_partner_id}</a>
        },
        {
            field: 'channel_partner_name',
            title: 'CP Name',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} >{rowData.channel_partner_name}</a>
        },
        {
            field: 'claim_amount',
            title: 'Claim Amount',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} > {rowData.claim_amount}</a>
        },
        {
            field: 'calculated_amount',
            title: 'Calculated Amount',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} > {rowData.calculated_amount}</a>
        },
        {
            field: 'approved_amount',
            title: 'Approved Amount',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} > {rowData.approved_amount}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            filtering: false,
            render: rowData =>
                <div style={{ display: 'flex' }}>
                    <Tooltip title="Edit">
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleEdit(rowData.claim_number)}
                            size="large">
                            <EditIcon color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>
                    {rowData.claim_status === 'Fully Reconciled' ?
                        <Tooltip title="Payment">

                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => props.getIncomingClaimsPayment(rowData.claim_number)}
                                size="large">
                                <AccountBalance color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                            </IconButton>
                        </Tooltip>
                        :
                        <IconButton classes={{ root: classes.IconButton }} disabled size="large">
                            <AccountBalance color="disabled" style={{ fontSize: 20, color: '#C8C8C8' }} />
                        </IconButton>
                    }
                    <Tooltip title="Reprocess">
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleReprocess(rowData)}
                            size="large">
                            <LowPriority color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Split Claim">
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleSplit(rowData)}
                            size="large">
                            <CallSplit color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleDownload(rowData.claim_number)}
                            size="large">
                            <CloudDownload color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="EDI File">
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleDownloadEDIFile(rowData)}
                            size="large">
                            <FileDownloadIcon color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                        </IconButton>
                    </Tooltip>
                </div>
        },
    ];
    const handleDownloadEDIFile = (item) => {
        var claimdate = Moment(item.claim_date).format('YYYY/MM/DD')
        fetch(baseUrl['BASE_URL'] + 'download-incoming-claims-edi?claim_number=' + item.claim_number, {
            //fetch('https://demo-api.ima360.app/v1/api/download-incoming-claims-edi?claim_number=' + item.claim_number, {

            method: 'GET',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                var date = Date.now();
                var file = "EDI-" + claimdate;
                link.setAttribute('download', file); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    }
    const handleDownloadButttonConfirm = () => {
        fetch(baseUrl['BASE_URL'] + 'download-incoming-claims?claim_number=' + currentClaimNumber + '&format_id=' + format, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                var date = Date.now();
                link.setAttribute('download', date + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        setOpenDialog(false)
    }
    const handleSplit = (item) => {
        console.log(item)
        props.handleSplit(item.claim_number)
    }
    const handleReprocess = (item) => {
        props.editIncomingClaim(item.claim_number, 'reconcile')
    }


    useEffect(() => {
        props.getIncomingClaims(0, 10);
        props.getIncomingClaimResponseFormat();
    }, []);
    const [openDialog, setOpenDialog] = React.useState(false)
    const [format, setFormat] = React.useState('')
    return (
        <div className={classes.root}>

            <Dialog open={
                openDialog}
                style={{ margin: 15 }}
                classes={{ paper: classes.paper }}
            >

                <DialogContent classes={{
                    root: classes.dialogContent
                }}
                >
                    <div style={{ width: '90%' }}>
                        <FormLabel className={classes.formLabel} style={{ width: '100%' }} >
                            Select Format
                        </FormLabel>
                        <Select
                            value={format}
                            onChange={(e) => setFormat(e.target.value)}
                            displayEmpty
                            className={clsx({
                                [classes.select]: true
                            })}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                        >
                            {props.incomingClaimFormatData &&
                                props.incomingClaimFormatData.length > 0 &&
                                props.incomingClaimFormatData
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.id} key={item.format_name}>
                                                {item.format_name}
                                            </MenuItem>
                                        )
                                    })}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={handleCancelDialog} color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleDownloadButttonConfirm()} color="primary" autoFocus variant="contained"
                        disabled={!format ? true : false}
                    >
                        Continue

                    </Button>
                </DialogActions>
            </Dialog>

            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'> Incoming Claims</Typography>
                <div>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        className={classes.fabContainer}
                        onClick={() => history.push('/supplier-incoming-claims-upload')}
                    >
                        <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Claim Upload</Typography>
                    </Fab>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        className={classes.fabContainer}
                        onClick={() => history.push('/supplier-incoming-claim-format')}
                    >
                        <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Response Format</Typography>
                    </Fab>
                </div>
            </div>

            {props.incomingClaimListData && props.incomingClaimListData.length > 0 ?
                <MaterialTable
                    components={{
                        Toolbar: (props) => (
                            <div
                                style={{
                                    height: "0px",
                                }}
                            >
                            </div>
                        ),
                    }}
                    title={' '}
                    editable={true}
                    icons={tableIcons}
                    columns={columns}
                    data={dataRows}
                    options={{
                        columnResizable: true,
                        search: false,
                        filtering: true,
                        headerStyle: theme.mixins.MaterialHeader,
                        cellStyle: theme.mixins.MaterialCell,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                    }}
                />
                :
                <Typography variant='h4'>
                    No claims have been added
                </Typography>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        incomingClaimListData: state.initialData.incomingClaimListData,
        queryListData: state.dataSetupData.incomingClaimsAllData,
        incomingClaimFormatData: state.dataSetupData.incomingClaimFormatData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getIncomingClaims: (page, limit) => dispatch(getIncomingClaims(page, limit)),
        editIncomingClaim: (id, reconcile) => dispatch(editIncomingClaim(id, reconcile)),
        getIncomingClaimsPayment: (claimNumber) => dispatch(getIncomingClaimsPayment(claimNumber)),
        handleSplit: (claimNumber) => dispatch(handleSplit(claimNumber)),
        getSalesInvoiceQueryList: () => dispatch(getIncomingClaimsQueryList()),
        getIncomingClaimResponseFormat: () => dispatch(getIncomingClaimResponseFormat()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncomingClaims);