import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Link,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { createPerformanceMetric,getPerformanceMetricSingleItem,getDefaultValues ,getEmployeeData } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Datepicker, TextInputWithLabel, DropdownArray } from '../../../components/Inputs';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { history } from '../../../components/Helpers';
import BeatLoader from "react-spinners/BeatLoader";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        // fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const AddPerformanceMetric = props => {


    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [open, setOpen] = React.useState(false);

    const [employeeNumber, setEmployeeNumber] = React.useState('');
    const [employeeName, setEmployeeName] = React.useState('');
    const [territoryID, setTerritoryID] = React.useState('');
    const [currentPeriodSales, setCurrentPeriodSales] = React.useState('');
    const [priorPeriodSales, setPriorPeriodSales] = React.useState('');
    const [currentPeriodMarketShare, setCurrentPeriodMarketShare] = React.useState('');
    const [priorPeriodMarketShare, setPriorPeriodMarketShare] = React.useState('');
    const [currentPeriodStartDate, setCurrentPeriodStartDate] = React.useState(null);
    const [currentPeriodEndDate, setCurrentPeriodEndDate] = React.useState(null);
    const [priorPeriodStartDate, setPriorPeriodStartDate] = React.useState(null);
    const [priorPeriodEndDate, setPriorPeriodEndDate] = React.useState(null);
    const [editId,setEditID]= React.useState(0);
    const [quotaLevel, setQuotaLevel] = React.useState('');
    const [quotaLevelData, setQuotaLevelData] = React.useState([]);


    useEffect(() => {
        setOpen(true);
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        //editableId = appId;
        setEditID(appId)
        if (appId)
            props.performanceMetricSingleItem(appId);
        props.getDefaultValues('Sales Commission')
        props.getEmployeeData();
    }, []);

    const handleInitialSetup = () => {
        setOpen(false);
    }
    function handleEmployeeName(id) {
        let data;
        if (props.employeeMasterData && props.employeeMasterData.total_record > 0) {
            data = props.employeeMasterData.records.filter((item, index) => item.employee_number == id)
            setEmployeeName(data[0].employee_name)
        }

    }
    const handleEmployeeNumber = (value) => {
        setEmployeeNumber(value);
        handleEmployeeName(value)
    }

useEffect(()=>{
    if(props.performanceMetricData){
        setEmployeeNumber(props.performanceMetricData.employee_number)
        setEmployeeName(props.performanceMetricData.employee_name)
        setTerritoryID(props.performanceMetricData.territory_id)
        setCurrentPeriodEndDate(props.performanceMetricData.current_period_end_date)
        setCurrentPeriodStartDate(props.performanceMetricData.current_period_start_date)
        setPriorPeriodStartDate(props.performanceMetricData.prior_period_start_date)
        setPriorPeriodEndDate(props.performanceMetricData.priorPeriodEndDate)
        setCurrentPeriodSales(props.performanceMetricData.current_period_sales)
        setPriorPeriodSales(props.performanceMetricData.prior_period_sales)
        setCurrentPeriodMarketShare(props.performanceMetricData.current_period_makert_share)
        setPriorPeriodMarketShare(props.performanceMetricData.prior_period_makert_share)
    }
},[props.performanceMetricData])

    const handleStartDate = (value) => {
        setCurrentPeriodStartDate(value);
    }
    const handleEndDate = (value) => {
        setCurrentPeriodEndDate(value);
    }
    const handlePriorStartDate = (value) => {
        setPriorPeriodStartDate(value);
    }
    const handlePriorEndDate = (value) => {
        setPriorPeriodEndDate(value);
    }

    useEffect(()=>{
        if(props.quotaLevelData && props.quotaLevelData.records[0].drop_down_values)
          setQuotaLevelData(props.quotaLevelData.records[0].drop_down_values)

    },[props.quotaLevelData])
  

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [userStore, setUserStore] = React.useState([]);
    function handleSubmit(editIndex) {

        var data = {
            "employee_number": employeeNumber,
            "employee_name": employeeName,
            "current_period_start_date": currentPeriodStartDate ? Moment(currentPeriodStartDate).local().format('YYYY-MM-DD') : '',
            "current_period_end_date": currentPeriodEndDate ? Moment(currentPeriodEndDate).local().format('YYYY-MM-DD') : '',
            "prior_period_start_date": priorPeriodStartDate ? Moment(priorPeriodStartDate).local().format('YYYY-MM-DD') : '',
            "prior_period_end_date": priorPeriodEndDate ? Moment(priorPeriodEndDate).local().format('YYYY-MM-DD') : '',
            "current_period_sales":parseFloat(currentPeriodSales) ,
            "prior_period_sales": parseFloat(priorPeriodSales),
            "current_period_makert_share": parseFloat(currentPeriodMarketShare),
            "prior_period_makert_share": parseFloat(priorPeriodMarketShare),
            "territory_id": territoryID,
            "quota_level": quotaLevel

        };

        props.onSubmit(data,editId);
        handleClear();
    }
    function handleClear() {
        setEmployeeName('')
        setEmployeeNumber('')
        setCurrentPeriodEndDate(null)
        setCurrentPeriodStartDate(null)
        setPriorPeriodEndDate(null)
        setPriorPeriodStartDate(null)
        setCurrentPeriodMarketShare('')
        setPriorPeriodMarketShare('')
        setPriorPeriodSales('')
        setCurrentPeriodSales('')
        setTerritoryID('')
        setQuotaLevel('')

    }

    function handleClearAll() {
        handleClear();
        //setEditMode(false);
        setUserStore([]);
    }

  
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/performance-metric'
                >
                    Performance Metric
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Performance Metric</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <div style={{ marginTop: 20, paddingTop:15, display: 'flex', flexDirection: 'column' }}>
                                        <FormLabel required>
                                            Quota Level
                                        </FormLabel>
                                        <Select
                                            value={quotaLevel}
                                            style={{marginTop:0}}
                                            onChange={(event) => setQuotaLevel(event.target.value)}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                             {quotaLevelData
                                                .map(item => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ display: quotaLevel == 'Employee Number' ? 'block' : 'none' }} 
                                >
                                    <div style={{ marginTop: 20 }}>
                                        <TextInputWithLabel heading={'Employee Number'} twoline='true' data={employeeNumber} onChange={handleEmployeeNumber} prevalue={employeeNumber} required/>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ display: quotaLevel == 'Employee Number' ? 'block' : 'none' }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Employee Name
                                        </FormLabel>
                                        <OutlinedInput
                                            readOnly
                                            value={employeeName}
                                            classes={{ root: classes.inputTwoLine }} 
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ display: quotaLevel == 'Territory ID' ? 'block' : 'none' }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Territory ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={territoryID}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setTerritoryID(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}  >
                                            Current Period Sales
                                        </FormLabel>
                                        <OutlinedInput
                                            value={currentPeriodSales}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setCurrentPeriodSales(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}  >
                                            Prior Period Sales
                                        </FormLabel>
                                        <OutlinedInput
                                            value={priorPeriodSales}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setPriorPeriodSales(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Current Period Makert Share
                                        </FormLabel>
                                        <OutlinedInput
                                            value={currentPeriodMarketShare}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setCurrentPeriodMarketShare(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Prior Period Makert Share
                                        </FormLabel>
                                        <OutlinedInput
                                            value={priorPeriodMarketShare}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setPriorPeriodMarketShare(e.target.value)} />
                                    </div>
                                </Grid>
                               


                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            Current  Start Date
                                        </FormLabel>
                                        <Grid container justifyContent="space-around">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={currentPeriodStartDate}
                                                    onChange={date => handleStartDate(date)}
                                                    format="MM/dd/yyyy"
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Current Period End Date
                                        </FormLabel>
                                        <Grid container justifyContent="space-around">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={currentPeriodEndDate}
                                                    onChange={date => handleEndDate(date)}
                                                    minDate={currentPeriodStartDate ? currentPeriodStartDate : ''}
                                                    format="MM/dd/yyyy"
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            Prior Period Start Date
                                        </FormLabel>
                                        <Grid container justifyContent="space-around">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={priorPeriodStartDate}
                                                    onChange={date => handlePriorStartDate(date)}
                                                    format="MM/dd/yyyy"
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Prior Period End Date
                                        </FormLabel>
                                        <Grid container justifyContent="space-around">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={priorPeriodEndDate}
                                                    onChange={date => handlePriorEndDate(date)}
                                                    minDate={priorPeriodStartDate ? priorPeriodStartDate : ''}
                                                    format="MM/dd/yyyy"
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>
                                    </div>
                                </Grid>



                            </Grid>
                        </div>
                    </form>
                </Card>


                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                   disabled={(territoryID || employeeNumber)&&quotaLevel ?false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data,id) => dispatch(createPerformanceMetric(data,'edit',id)),
        performanceMetricSingleItem:(id) => dispatch(getPerformanceMetricSingleItem(id)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit)),
        getDefaultValues: (applicationType) => dispatch(getDefaultValues(applicationType, null, 'on', 'quota_level'))
   
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        performanceMetricData: state.performanceMetricData.performanceMetricSingleData,
        loadingAPI: state.customerData.loading,
        employeeMasterData: state.customerData.employeeMasterData,
        quotaLevelData: state.addMultipleConfigurationData.quotaLevelData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPerformanceMetric);