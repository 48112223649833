import React, { useEffect } from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, Typography} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadPaymentGraph, loadValuesBasedOnAppTypeAndField } from '../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Chart } from "react-google-charts";
import { endOfMonth, subMonths, startOfMonth } from 'date-fns';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '16px 0px 16px 0px'
    }
}));
const ContractGroupSubgroup = (props) => {
    const classes = useStyles();
    const [paymentContractGroupData, setPaymentContractGroupData] = React.useState([]);
    const [paymentContractGroupAccrualData, setPaymentContractGroupAccrualData] = React.useState([]);
    const [paymentContractSubGroupAccrualData, setPaymentContractSubGroupAccrualData] = React.useState([]);
    const [paymentContractSubGroupData, setPaymentContractSubGroupData] = React.useState([]);
    const [dateRangeValue, setDateRangeValue] = React.useState([new Date('2020-06-01T05:30:00'), new Date('2020-10-01T05:30:00')]);
    useEffect(() => {
        setDateRangeValue([startOfMonth(subMonths(new Date(), 2)), endOfMonth(new Date())]);

        props.loadSourceDataType('Customer Rebate', 'source_data_type');
        var dateArray = [startOfMonth(subMonths(new Date(), 2)), endOfMonth(new Date())]
        var date = 'start=' + Moment.utc(dateArray[0]).local().format('YYYY-MM-DDTHH:mm:ss') + '&end=' + Moment.utc(dateArray[1]).local().format('YYYY-MM-DDTHH:mm:ss')

        props.loadPaymentGraph('contract_group', date, 'payment', 'Customer Rebate', 'Direct - Sales Data');
        props.loadPaymentGraph('contract_group', date, 'accrual', 'Customer Rebate', 'Direct - Sales Data');
        props.loadPaymentGraph('contract_sub_group', date, 'payment', 'Customer Rebate', 'Direct - Sales Data');
        props.loadPaymentGraph('contract_sub_group', date, 'accrual', 'Customer Rebate', 'Direct - Sales Data');
    }, []);

    useEffect(() => {
        const months = [];
        var i;
        var label = ['Month', 'Accrual', 'Payment'];
        var accrualData = props.accrualPaymentGraphData.accrual_result;
        var paymentData = props.accrualPaymentGraphData.payment_result;
        const dateEnd = Moment();
        const dateStart = Moment().subtract(11, 'month');
        while (dateEnd.diff(dateStart, 'months') >= 0) {
            months.push([dateStart.format('MMM YYYY')])
            dateStart.add(1, 'month')
        }
        months.forEach(e => {
            e.push(0, 0)
        })
        months.unshift(label);
        if (props.accrualPaymentGraphData &&
            props.accrualPaymentGraphData.accrual_result && props.accrualPaymentGraphData.accrual_result.length > 0
        ) {
            for (i = 0; i < 12; i++) {
                accrualData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][1] = e.Total
                    }
                })
                paymentData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][2] = e.Total
                    }
                })
            }
        }
    }, [props.accrualPaymentGraphData]);

     
    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
    }, []);



    useEffect(() => {
        if (props.paymentGraphContractGroupSuccessData) {
            var label = ['Contract group', 'Percentage'];
            var output = props.paymentGraphContractGroupSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
           setPaymentContractGroupData(newArray);
        }
    }, [props.paymentGraphContractGroupSuccessData]);
    useEffect(() => {
        if (props.paymentGraphContractGroupAccrualSuccessData) {
            var label = ['Contract group', 'Percentage'];
            var output = props.paymentGraphContractGroupAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractGroupAccrualData(newArray);
        }
    }, [props.paymentGraphContractGroupAccrualSuccessData]);
    useEffect(() => {
        if (props.paymentGraphContractSubGroupAccrualSuccessData) {
            var label = ['Contract sub group', 'Percentage'];
            var output = props.paymentGraphContractSubGroupAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)  
            setPaymentContractSubGroupAccrualData(newArray);
        }
    }, [props.paymentGraphContractSubGroupAccrualSuccessData]);
    useEffect(() => {
        if (props.paymentGraphContractSubGroupSuccessData) {
            var label = ['Contract sub group', 'Percentage'];
            var output = props.paymentGraphContractSubGroupSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractSubGroupData(newArray);
        }
    }, [props.paymentGraphContractSubGroupSuccessData]);


    return (
        <div className={classes.rootDiv}>
       

            <Grid container className={classes.root} style={{ justifyContent: 'space-between' }}>
   
                <Grid container className={classes.root}>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" >Payment - Contract Group</Typography>
                        {paymentContractGroupData.length > 0 ?
                            <Chart
                                height={300}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={paymentContractGroupData}
                                options={{
                                    pieHole: 0.4,
                                    colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                    legend: { position: 'top', alignment: 'center' },
                                    chartArea: { 'width': '100%', 'height': '80%' },
                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                            :
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center', alignItems: 'center', height: 209
                            }}>
                                No data
                            </div>
                        }
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" >Accrual - Contract Group</Typography>
                        {paymentContractGroupAccrualData.length > 0 ?
                            <Chart
                                height={300}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={paymentContractGroupAccrualData}
                                options={{
                                    pieHole: 0.4,
                                    colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                    legend: { position: 'top', alignment: 'center' },
                                    chartArea: { 'width': '100%', 'height': '80%' },
                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                            :
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center', alignItems: 'center', height: 209
                            }}>
                                No data
                            </div>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" >Payment - Sub Contract Group</Typography>
                        {paymentContractSubGroupData.length > 0 ?
                            <Chart
                                height={300}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={paymentContractSubGroupData}
                                options={{
                                    pieHole: 0.4,
                                    colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                    legend: { position: 'top', alignment: 'center' },
                                    chartArea: { 'width': '100%', 'height': '80%' },
                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                            :
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center', alignItems: 'center', height: 209
                            }}>
                                No data
                            </div>
                        }
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" >Accrual - Sub Contract Group</Typography>
                        {paymentContractSubGroupAccrualData.length > 0 ?
                            <Chart
                                height={300}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={paymentContractSubGroupAccrualData}
                                options={{
                                    pieHole: 0.4,
                                    colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                    legend: { position: 'top', alignment: 'center' },
                                    chartArea: { 'width': '100%', 'height': '80%' },
                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                            :
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center', alignItems: 'center', height: 209
                            }}>
                                No data
                            </div>
                        }
                    </Grid>
                </Grid>
            </Grid >

        </div >
    );
};

const mapStateToProps = state => {
    return {
        accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
        paymentGraphContractGroupSuccessData: state.initialData.paymentGraphContractGroupSuccessData,
        paymentGraphContractGroupAccrualSuccessData: state.initialData.paymentGraphContractGroupAccrualSuccessData,
        paymentGraphContractSubGroupSuccessData: state.initialData.paymentGraphContractSubGroupSuccessData,
        paymentGraphContractSubGroupAccrualSuccessData: state.initialData.paymentGraphContractSubGroupAccrualSuccessData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadPaymentGraph: (item, dateRangeValue, type, applicationType, sourceDataType) => dispatch(loadPaymentGraph(item, dateRangeValue, type, applicationType, sourceDataType)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractGroupSubgroup);