import React from 'react';
import { makeStyles } from "@mui/styles";
import {
    Grid,
    FormLabel,
} from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    rootContainer: {
        padding: '5px 0px 5px',
        display: 'flex',
        alignItems: 'center'
    },
    data: {
        color: theme.palette.text.grey,
        //marginTop: 5, 
        width: '100%',
        alignItems: 'center',
        height: 36,
        display: 'flex'
    },
    dataSingle: {
        color: theme.palette.text.grey,
        fontSize: theme.typography.h3.fontSize,
        // marginTop: 10,
        width: '100%',
        alignItems: 'center',
        height: 36,
        display: 'flex'
    },
    dataBorder: {
        color: theme.palette.text.grey,
        //marginTop: 10,
        width: '100%',
        alignItems: 'center',
        height: 36,
        border: '1px solid #E0E0E0',
        display: 'flex',
        paddingLeft: 10
    },
}));

const LabelText = props => {
    const classes = useStyles();
    const { className } = props;

    return (
        <div>
            {props.twoline ?
                <Grid container
                    className={clsx(classes.root, className)}
                >
                    <Grid item style={{ width: '100%' }}>
                        {props.heading &&
                            <FormLabel {...props}>
                                {props.heading ? props.heading : ''}
                            </FormLabel>
                        }
                        <div className={props.border ? classes.dataBorder : classes.data} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                            {props.data ? props.data : ''}
                        </div>
                    </Grid>
                </Grid>
                :
                <Grid container className={classes.rootContainer}>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {props.heading &&
                            <FormLabel {...props}>
                                {props.heading}
                            </FormLabel>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        className={classes.dataSingle}
                        style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}
                    >
                        {props.data}
                    </Grid>
                </Grid>
            }
        </div>
    );

};

export default LabelText;