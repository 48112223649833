import { createTheme } from '@mui/material';
import palette from './palette';
import typography from './typography';
import overrides from './overridesLight';

const light = createTheme({
  palette,
  typography,
  overrides,
  shadows: Array(25).fill("none") //removing shadows globally,
});

export default light;