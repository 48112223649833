import React, { useRef, useEffect } from 'react';
import {
    Typography, Breadcrumbs, Stepper, Step, StepButton, StepConnector, StepLabel, Button, Grid, useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import {
    GeneralData, FinancialPostings, TargetRules, Miscellaneous, /*EligibilityRules,*/ CalculationRules,
    ApprovalStatus, InternalNotes, ContractDependency, MultiplePayment, CalculationModifier
} from './components';
import { EligibilityRules } from '../ContractSetup/Containers';
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';
import secureLocalStorage from "react-secure-storage";
import {
    newAPIForAccRe, getFormulasList, getPriceFormula, getContractDetailsData, getDefaultValuesAllDataNew, getDefaultValues,
    putContractGeneralData, getLblDispDesFieldValue, fetchDocumentStatus, getDefaultValuesKeyFigure, getReleaseLockDetails
} from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import { Prompt } from 'react-router';
import { ConfirmPrompt } from '../../components/Containers';
const OutsideClickHandler = ({ onClickOutside, children }) => {
    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            } else {
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [onClickOutside]);

    return <div ref={ref}>{children}</div>;
};
var generalData = {};
var financialData = {};
var miscellaneousData = {};
var approvalStatusData = {};
var incentiveData = {};
var paymentData = {};
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: -20,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto',
        marginTop: 10
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));
const tabs = {
    generalData: 0,
    financialPostings: 1,
    TargetRules: 2,
    contractDependency: 3,
    eligibilityRules: 4,
    calculationRules: 5,
    Reporting: 6,
    notesAndAttachments: 7,
    approvalStatus: 8,
};

const AddContract = props => {
    const currentApplication = sessionStorage.getItem('application')
    useEffect(() => {
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
        props.getFormulasList();
        var url = window.location.href;
        if (url.indexOf("status") != -1)
            setActiveStep(5)
        sessionStorage.setItem("functionality-name", 'contract')
    }, []);
    const [targetTabEnabled, setTargetTabEnabled] = React.useState(false);
    const [eligibilityEnabled, setEligibilityEnabled] = React.useState(false);
    const [contractDependencyEnabled, setContractDependencyEnabled] = React.useState(false);
    const [calcRuleEnabled, setCalcRuleEnabled] = React.useState(true);
    const [calcModifierEnabled, setCalcModifierEnabled] = React.useState(false);
    const [multiPaymentRuleEnabled, setMultiPaymentRuleEnabled] = React.useState(false);
    const history = useHistory();
    const location = useLocation();
    const [showModal, setShowModal] = React.useState(false);
    const [currentLoc, setCurrentLoc] = React.useState(location?.pathname);
    const [nextRoute, setNextRoute] = React.useState(null);
    const [onBLock, setOnBLock] = React.useState(false);

    useEffect(() => {
        const unblock = history.block((nextLocation) => {
            if (nextLocation.pathname !== currentLoc && onBLock) {
                // Show the modal and set the next route
                setShowModal(true);
                setCurrentLoc(nextLocation.pathname)
                setNextRoute(nextLocation.pathname);
                return false; // Prevent navigation
            }
        });

        // Cleanup function to unblock navigation when the component unmounts
        return () => {
            unblock();
        };
    }, [history, currentLoc, onBLock]);

    const confirmNavigation = () => {
        sessionStorage.removeItem("functionality-name")
        setShowModal(false);
        if (nextRoute) {
            history.push(nextRoute);
            if (nextRoute == '/login') {
                localStorage.setItem("loggedIn", false);
                localStorage.clear();
                secureLocalStorage.clear();
            } // Navigate to the new route
        }

    };

    const cancelNavigation = () => {
        setShowModal(false);
        setCurrentLoc(location.pathname)
        setNextRoute(null); // Clear the next route
    };



    function getSteps() {
        if (currentApplication === 'Pricing') {
            return ['Primary*', ...(targetTabEnabled ? ['Commitment Rules*'] : []), ...(contractDependencyEnabled ? ['Contract Dependency*'] : []),
                ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), ...(calcModifierEnabled ? ['Calculation Rule Modifiers*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        } else {
            return ['Primary*', 'Financial Postings*', ...(multiPaymentRuleEnabled ? ['Flexible Payments*'] : []), ...(targetTabEnabled ? ['Commitment Rules*'] : []),
                ...(contractDependencyEnabled ? ['Contract Dependency*'] : []),
                ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), ...(calcModifierEnabled ? ['Calculation Rule Modifiers*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        }

    }

    //var mode = localStorage.getItem('mode');
    const classes = useStyles();
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const [saveTriggered, setSaveTriggered] = React.useState('');
    const [eligibilityData, setEligibilityData] = React.useState([]);
    const [calculationData, setCalculationData] = React.useState([]);
    const [internalNotesData, setInternalNotesData] = React.useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [approvalStatusDisabled, setApprovalStatusDisabled] = React.useState(false);
    const [calculationSchema, setCalculationSchema] = React.useState('');
    const skipped = new Set()
    const [targetRulesData, setTargetRulesData] = React.useState([]);
    const steps = getSteps();
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [contractData, setContractData] = React.useState({});
    const [approvalStatus, setApprovalStatus] = React.useState('');
    const [contractFormFields, setContractFormFields] = React.useState({});
    const [comments, setComments] = React.useState([]);
    const [approvalID, setApprovalID] = React.useState(0);
    const page = history.location.state
    const totalSteps = () => {
        return getSteps().length;
    };
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const skippedSteps = () => {
        return skipped.size;
    };
    const [blockNavigation, setBlockNavigation] = React.useState(false);
    // useEffect(() => {
    //     if (blockNavigation) {
    //         window.onbeforeunload = () => true
    //     } else {
    //         window.onbeforeunload = undefined
    //     }
    // }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setBlockNavigation(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setContractFormFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    useEffect(() => {
        if (activeStep == steps.length - 1) {
            var approveForm = {
                "application_type": contractData.application_type,
                "functionality": "contract_header",
                "workflow_name": contractData.workflow_name,
                "status_description": contractData.contract_status

            }
            props.getApprovalWorkflow(approveForm)
        }
    }, [activeStep])
    useEffect(() => {
        if (props.data && Object.entries(props.data).length > 0) {
            setContractData(props.data)
            if (props.data.commitment_achievement && props.data.commitment_achievement != 'No')
                setTargetTabEnabled(true)
            else
                setTargetTabEnabled(false)
            if (props.data && props.data.calculation_method && props.data.incentive_rate_type && (props.data.calculation_method === 'Flat Rate' || props.data.calculation_method === 'Fixed Amount') && props.data.incentive_rate_type === 'Attribute') {
                setEligibilityEnabled(false)
            } else {
                setEligibilityEnabled(true)
            }
            if (props.data.contract_dependency == 'Yes') {
                setContractDependencyEnabled(true);
            } else {
                setContractDependencyEnabled(false);
            }
            if (props.data.payment_level == 'Flexible Payment'// && props.data.payment_partner_role != ''

            ) {
                setMultiPaymentRuleEnabled(true);

            } else {
                setMultiPaymentRuleEnabled(false);
            }
            if (props.data.calculation_rule_modifier == 'Yes') {
                setCalcModifierEnabled(true);

            } else {
                setCalcModifierEnabled(false);
            }
            if (props.data.calculation_method === 'External Calculation')
                setCalcRuleEnabled(false)
            props.onLoadingDefault(props.data.application_type, props.data.source_data_type)
            props.onLoadingDefaultCR(props.data.application_type, props.data.source_data_type)

        }
        if (props.data && props.data.calculation_method) {
            setCalculationMethod(props.data.calculation_method)
        }

    }, [props.data])
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const completedSteps = () => {
        return completed.size;
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleClickOutside = () => {
        setOnBLock(true)
        handleRelaseLock();
    };
    const handleComplete = () => {
        setBlockNavigation(false);
        setOnBLock(false)
        handleRelaseLock();
        if (contractData && contractData.incentive_amount && contractData.incentive_amount) {
            incentiveData = {
                "incentive_amount": contractData.incentive_amount,
                "incentive_rate": contractData.incentive_rate
            }
        }
        paymentData = {
            "accrual_amount": contractData.accrual_amount,
            "payment_amount": contractData.payment_amount
        }

        var dataFromAPI = contractData;
        dataFromAPI.approval_group_id = approvalStatusData.approval_group_id;
        dataFromAPI.approval_status = approvalStatusData.approval_status;
        dataFromAPI.contract_status = approvalStatusData.contract_status;
        dataFromAPI.notes = [...internalNotesData, ...comments];
        if ((approvalStatusData.approval_status === 'submit_approval' || approvalStatusData.approval_status === 'acc' || approvalStatusData.approval_status === 'rej')
            && (approvalStatusData.approval_group_id === 0 || !approvalStatusData.approval_group_id)) {
            alert('Please select approval group')
        } else {
            if (contractData.is_update_approval_contract === true) {
                var data = {
                    'notes': comments,
                    'contract_status': contractData.contract_status
                }
                // var completeData = Object.assign(contractNumber, targetRulesObject, generalData, financialData, targetRulesData, miscellaneousData, eligibilityRulesObject, calculationRulesObject, approvalStatusData, internalNotesObject, incentiveData, paymentData);
                if (approvalStatusData.approval_status === 'acc') {
                    props.newAPIForAccRe('acc', data, dataFromAPI.id);
                } else if (approvalStatusData.approval_status === 'rej') {
                    props.newAPIForAccRe('rej', data, dataFromAPI.id);
                }
                else {
                    props.onPut(dataFromAPI, page, dataFromAPI.id);
                }
            } else {
                props.onPut(dataFromAPI, page, dataFromAPI.id);
            }
        }
    };
    function handleRelaseLock() {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        let data = {
            "id": appId,
            "lock": "contract_data"
        }
        props.getReleaseLockDetails(data)
    }
    const handleDiscard = () => {
        setOnBLock(true)
        if (page == 'contract-approval')
            history.push('/approval-reject');
        else
            history.push("/contract-setup");
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/contract-setup");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    function handleGeneralDataChange(key, value) {
        if (key === 'commitment_achievement') {
            setTargetTabEnabled(value);
        }
        generalData[key] = value
        setCalculationSchema(value);
        checkValues();
    };
    function handleFinancialDataChange(key, value) {
        financialData[key] = value;
        checkValues();
    };
    function handleMiscellaneousDataChange(key, value) {
        miscellaneousData[key] = value;
    };
    function handleTargetRules(values) {
        setTargetRulesData(values)
    };
    function handleEligibilityRules(values) {
        setEligibilityData(values);
        checkValues();
    };
    function handleCalculationRules(values) {
        setCalculationData(values);
        checkValues();
    };
    function handleApprovalStatus(key, value) {
        if (key === 'contract_status')
            setApprovalStatus(value)
        if (key === 'approval_group_id')
            setApprovalID(value)
        approvalStatusData[key] = value;
    };
    function handleInternalNotes(value) {
        setComments(value)
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        if (!targetTabEnabled) {
            if (eligibilityEnabled) {
                var allSteps = [
                    <GeneralData savetriggered={saveTriggered} ref={childRef1} data={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings savetriggered={saveTriggered} ref={childRef2} postingSchema={generalData['posting_schema']} data={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData} />] : []),
                    <EligibilityRules calculationMethod={calculationMethod ? calculationMethod : ''} calculationSchema={contractData && contractData.calculation_schema ? contractData.calculation_schema : ''} data={contractData} mode='view' />,
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={calculationMethod ? calculationMethod : ''} calculationSchema={calculationSchema} data={contractData} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous ref={childRef3} miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes data={contractData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            }
            else {
                var allSteps = [
                    <GeneralData savetriggered={saveTriggered} ref={childRef1} data={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings savetriggered={saveTriggered} ref={childRef2} postingSchema={generalData['posting_schema']} data={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData} />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={calculationMethod ? calculationMethod : ''}
                        calculationSchema={calculationSchema} data={contractData} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous ref={childRef3} miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes data={contractData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            }
        } else {
            if (eligibilityEnabled) {
                var allSteps = [
                    <GeneralData savetriggered={saveTriggered} ref={childRef1} data={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings savetriggered={saveTriggered} ref={childRef2} postingSchema={generalData['posting_schema']} data={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    <TargetRules data={contractData} fieldData={contractFormFields} />,
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData} />] : []),
                    <EligibilityRules calculationMethod={calculationMethod} calculationSchema={contractData && contractData.calculation_schema ? contractData.calculation_schema : ''} data={contractData} mode='view' />,
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={calculationMethod} calculationSchema={calculationSchema}
                        data={contractData} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous ref={childRef3} miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes data={contractData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            }
            else {
                var allSteps = [
                    <GeneralData savetriggered={saveTriggered} ref={childRef1} data={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings savetriggered={saveTriggered} ref={childRef2} postingSchema={generalData['posting_schema']} data={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    <TargetRules fieldData={contractFormFields} data={contractData} />,
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData} />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={contractData && contractData.calculation_method ? contractData.calculation_method : ''}
                        calculationSchema={calculationSchema} data={contractData} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous ref={childRef3} miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes data={contractData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            }
        }

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    function checkValues() {
        var d1 = new Date(generalData.valid_to);
        var d2 = new Date(generalData.valid_from);
        if (
            generalData.contract_type && generalData.calculation_schema && generalData.application_type && generalData.internal_description && generalData.currency && generalData.valid_from && generalData.valid_to && generalData.contract_purpose && generalData.source_data_type && generalData.posting_schema && generalData.posting_approval && financialData.internal_reference && financialData.accrual_frequency && financialData.accrual_level && financialData.accrual_gl_account_debit && financialData.accrual_gl_account_credit && financialData.copa_level && financialData.payment_frequency && financialData.payment_calendar && financialData.payment_terms && financialData.payment_partner_role && financialData.payment_level && financialData.payment_method && financialData.payment_partner && eligibilityData && eligibilityData.length > 0 && calculationData && calculationData.length > 0 && d1 >= d2
        ) {
            setSaveDisabled(false)
        } else {
            setSaveDisabled(true)
        }
    }

    useEffect(() => {
        if (props.data) {
            generalData = {
                application_type: props.data.application_type,
                contract_type: props.data.contract_type,
                calculation_schema: props.data.calculation_schema,
                contract_group: props.data.contract_group,
                internal_description: props.data.internal_description,
                currency: props.data.currency,
                internal_reference: props.data.internal_reference,
                valid_from: props.data.valid_from,
                contract_sub_type: props.data.contract_sub_type,
                contract_sub_group: props.data.contract_sub_group,
                external_description: props.data.external_description,
                approval_status: props.data.approval_status,
                approval_group_id: props.data.approval_group,
                external_reference: props.data.external_reference,
                valid_to: props.data.valid_to,
                contract_purpose: props.data.contract_purpose,
                source_data_type: props.data.source_data_type,
                posting_schema: props.data.posting_schema,
                posting_approval: props.data.posting_approval,
                legacy_contract_id: props.data.legacy_contract_id,
                go_live_date: props.data.go_live_date,
                flex_fields_1: props.data.flex_fields_1,
                flex_fields_2: props.data.flex_fields_2,
                flex_fields_3: props.data.flex_fields_3,
                calculation_method: props.data.calculation_method,
            };
            financialData = {
                accrual_frequency: props.data.accrual_frequency,
                accrual_level: props.data.accrual_level,
                copa_level: props.data.copa_level,
                accrual_calendar: props.data.accrual_calendar,
                accrual_gl_account_credit: props.data.accrual_gl_account_credit,
                accrual_gl_account_debit: props.data.accrual_gl_account_debit,
                payment_frequency: props.data.payment_frequency,
                payment_level: props.data.payment_level,
                payment_terms: props.data.payment_terms,
                payment_calendar: props.data.payment_calendar,
                payment_method: props.data.payment_method,
                payment_partner_role: props.data.payment_partner_role,
                payment_partner: props.data.payment_partner,
                internal_reference: props.data.internal_reference,
                postings_block: props.data.postings_block,
                postings_start_date: props.data.postings_start_date,
                postings_end_date: props.data.postings_end_date,
                accrual_credit_profit_center: props.data.accrual_credit_profit_center,
                accrual_debit_profit_center: props.data.accrual_debit_profit_center,
                auto_run_ppa: props.data.auto_run_ppa,
                flex_fields_4: props.data.flex_fields_4,
                flex_fields_5: props.data.flex_fields_5,
                external_reference: props.data.external_reference,
                suppress_negative_rebate: props.data.suppress_negative_rebate,
                accrual_run: props.data.accrual_run,
                payment_document_type: props.data.payment_document_type,
                payment_run: props.data.payment_run,
                posting_approval: props.data.posting_approval
            };
            miscellaneousData = {
                pricing_strategy: props.data.pricing_strategy,
                negotiation_reason: props.data.negotiation_reason,
                outcome_date: props.data.outcome_date,
                contract_owner: props.data.contract_owner,
                contract_manager: props.data.contract_manager,
                sales_manager: props.data.sales_manager,
                finance_manager: props.data.finance_manager,
                internal_signed_date: props.data.internal_signed_date,
                external_contact_email: props.data.external_contact_email,
                negotiation_outcome: props.data.negotiation_outcome,
                contract_owner_type: props.data.contract_owner_type,
                contract_analyst: props.data.contract_analyst,
                sales_analyst: props.data.sales_analyst,
                finance_analyst: props.data.finance_analyst,
                external_delivery_options: props.data.external_delivery_options,
                external_signed_date: props.data.external_signed_date,
                external_contact_phone: props.data.external_contact_phone,
                image_reference_id: props.data.image_reference_id,
                flex_fields_6: props.data.flex_fields_6,
                flex_fields_7: props.data.flex_fields_7,
                flex_fields_8: props.data.flex_fields_8,
                flex_fields_9: props.data.flex_fields_9,
                flex_fields_10: props.data.flex_fields_10
            };
            setEligibilityData(props.data.eligibility_rules);
            setCalculationData(props.data.calculation_rules);
            approvalStatusData = ({
                "approval_status": props.data.approval_status,
                "approval_group_id": props.data.approval_group_id,
                "contract_status": props.data.contract_status
            })
            //setApprovalStatus(props.data.contract_status)
            if (props.data.notes) {
                setInternalNotesData(props.data.notes.filter(item => item.note_type != "Submit Decision"))
                setComments(props.data.notes.filter(item => item.note_type == "Submit Decision"))

            }
            checkValues();
        }
    }, [props.data]);

    return (
        <OutsideClickHandler onClickOutside={handleClickOutside}>
            <div>
                <div className={classes.root}>
                    <LoadingOverlay
                        active={props.loading}
                        spinner={<HashLoader />}
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: "50px",
                                "& svg circle": {
                                    stroke: "#045FB4",
                                },
                            }),
                            overlay: (base) => ({
                                ...base,
                                background: "rgba(52, 52, 52, 0)",
                            }),
                            content: (base) => ({
                                ...base,
                                color: "black",
                            }),
                        }}
                    >
                        {/* <Prompt
                            when={blockNavigation}
                            message='Are you sure you want to exit this screen ?'
                        /> */}
                        <div className={classes.stepper}>
                            <Stepper alternativeLabel nonLinear activeStep={activeStep}
                                classes={{ root: classes.stepperContainer }}
                                connector={
                                    <StepConnector
                                        classes={{
                                            line: classes.connectorLine,
                                            alternativeLabel: classes.connectorAlternativeLabel
                                        }}
                                    />
                                }
                            >
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const buttonProps = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}
                                            classes={{ horizontal: classes.horizontal }}
                                        >
                                            <StepButton
                                                onClick={handleStep(index)}
                                                completed={isStepComplete(index)}
                                                {...buttonProps}
                                                classes={{ root: classes.stepButton }}
                                            >
                                                <StepLabel>
                                                    {label}
                                                </StepLabel>
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>
                        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {activeStep !== 0 &&
                                    <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                        Previous Step
                                    </Button>
                                }
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                            >
                                <Button variant="outlined" color="primary" onClick={handleComplete}
                                    className={clsx({
                                        [classes.button]: true,
                                        [classes.shiftContent]: !isDesktop,
                                    })}
                                    disabled={!approvalStatus || !approvalID ? true : false}
                                >
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                    {props.loading ? '' : 'Save'}
                                </Button>
                                <Button variant="outlined" color="primary" onClick={handleDiscard} className={classes.button}
                                    disabled={props.loading ? true : approvalStatusDisabled}
                                >
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                    {props.loading ? '' : 'Discard'}
                                </Button>
                                {!isLastStep() &&
                                    <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}>
                                        {activeStep === tabs.notesAndAttachments ? 'Done' : 'Next Step'}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <ConfirmPrompt
                            show={showModal}
                            message="Are you sure you want to leave this page?" // Custom message
                            onConfirm={confirmNavigation}
                            onCancel={cancelNavigation}
                        />
                    </LoadingOverlay>
                </div>
            </div>
        </OutsideClickHandler>
    );

};

const mapStateToProps = state => {
    return {
        eligibilityRulesData: state.addContractData.eligibilityRulesData,
        calculationRulesData: state.addContractData.calculationRulesData,
        contractData: state.addContractData.contractDetailData,
        data: state.addContractData.contractGeneralData,
        loading: state.addContractData.loading,
        //tableData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPut: (data, page, id) => dispatch(putContractGeneralData(data, page, null, null, id)),
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        newAPIForAccRe: (status, data, id) => dispatch(newAPIForAccRe(status, null, id, data)),
        getFormulasList: () => dispatch(getFormulasList(1, 1000)),
        getPricingCalculationFormula: (page, limit) => dispatch(getPriceFormula(page, limit)),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getApprovalWorkflow: (data) => dispatch(fetchDocumentStatus(data)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
        getReleaseLockDetails: (data) => dispatch(getReleaseLockDetails(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContract);