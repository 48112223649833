import React from 'react';
import { MaterialMasterUserTable } from './components/MaterialMaster';

const MaterialMaster = () => {
    return (
        <div>
            <MaterialMasterUserTable />
        </div >
    );
};

export default MaterialMaster;