import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const DesignerStudio = (props) => {
    const handleClick = (pge) => {
        history.push(pge)
    }

    const application = sessionStorage.getItem("application");
    const hasRight = (right) => {
        const user = secureLocalStorage.getItem("dYtz");
        return (
            user &&
            user.role &&
            user.role.rights &&
            user.role.rights.includes(`${right}-${application}`)
        );
    };
    const hasPredefinedRight = hasRight("get_pre_defined_queries");
    const hasAnalyticsDesigner = hasRight("get_analytics_designer");
    const hasDashboardDesigner = hasRight("get_dashboard_designer");
    const hasProcessReportingDesigner = hasRight("get_process_reporting_designer");
    const hasUiDesigner = hasRight("get_ui_designer");
    const hasConfiguration = hasRight("get_configuration");
    const hasApprovalGroup = hasRight("get_approval_groups");
    const hasApprovalLevels = hasRight("get_approval_levels");
    const hasApprovalWorkflow = hasRight("get_approval_workflow");
    const hasWorkflowAssignment = hasRight("get_workflow_assignment");
    const hasAccuralDesigner = hasRight("get_accrual_designer");
    const hasPaymentDesigner = hasRight("get_payment_designer");
    const hasHolidayCalendar = hasRight("get_holiday_calendar");
    const hasFinancialClose = hasRight("get_financial_close");
    const hasFiscalYearDefinition = hasRight("get_fiscal_year_definition");
    const hasStatementDefault = hasRight("get_statement_default");
    const hasGlobalEligibility = hasRight("get_global_eligibility");
    const hasKeyFigureMapping = hasRight("get_key_figure_mapping");
    const hasAttributeMapping = hasRight("get_attribute_mapping");
    const hasBasisMapping = hasRight("get_basis_mapping");
    const hasCalculationAnalysis = hasRight("get_calculation_analysis");
    const hasEligibilityDesigner = hasRight("get_eligibility_designer");
    const hasCalculationDesigner = hasRight("get_calculation_designer");
    const hasTierEvaluationLevelDesigner = hasRight("get_tier_evaluation_level_designer");
    const hasDefaultDesigner = hasRight("get_default_designer");
    const hasContractAttribute = hasRight("get_contract_attribute");
    const hasMandatoryAttribute = hasRight("get_mandatory_attribute");

    const menuItems = [

        {
            'Contract Designer': [
                // { 'Dropdown Designer': '/multiple-configuration' },
                // { 'Display Designer': '/field-display-configuration' },
                ...(hasEligibilityDesigner ? [{ 'Eligibility Designer': '/eligibility-designer' }] : []),
                ...(hasCalculationDesigner ? [{ 'Calculation Designer': '/keyfigure-configuration' }] : []),
                ...(hasTierEvaluationLevelDesigner ? [{ 'Tier Evaluation Designer': '/tier-evaluation-level-designer' }] : []),
                ...(hasDefaultDesigner ? [{ 'Default Designer': '/contract-default-designer' }] : []),
                ...(hasContractAttribute ? [{ "Contract Attributes": "/contract-attributes" }] : []),
                ...(hasMandatoryAttribute ? [{ "Mandatory Eligibility Rules": "/mandatory-eligibility-rules" }] : []),
            ]
        },
        {
            'Calculation Designer': [
                ...(hasGlobalEligibility ? [{ 'Global Eligibility': '/sales-basis' }] : []),
                ...(hasKeyFigureMapping ? [{ 'Key Figure Mapping': '/key-figure-mapping' }] : []),
                ...(hasAttributeMapping ? [{ 'Attribute Mapping': '/attribute-mapping' }] : []),
                ...(hasBasisMapping ? [{ 'Basis Mapping': '/basis-mapping' }] : []),
                ...(hasCalculationAnalysis ? [{ 'Calculation Analysis': '/calculation-analysis' }] : []),
            ]
        },
        // {
        //     'Claim Designer': [
        //         { 'Configuration': '/claim-configuration' },
        //         { 'Rejection Reasons': '/rejection-reasons' },
        //         // { '': '/' },
        //     ]
        // },
        {
            'Postings Designer': [
                ...(hasAccuralDesigner ? [{ 'Accrual Designer': '/accrual-designer' }] : []),
                ...(hasPaymentDesigner ? [{ 'Payment Designer': '/payment-designer' }] : []),
                ...(hasHolidayCalendar ? [{ 'Holiday Calendar': '/holiday-calendar' }] : []),
                ...(hasFinancialClose ? [{ 'Financial Close': '/financial-close' }] : []),
                ...(hasFiscalYearDefinition ? [{ 'Fiscal Year Definition': '/fiscal-year-definition' }] : []),
                ...(hasStatementDefault ? [{ 'Statement Defaults': '/org-defaults' }] : []),
            ]
        },
        {
            'Approval Designer': [
                ...(hasApprovalGroup ? [{ 'Approval Groups': '/approval-group' }] : []),
                ...(hasApprovalLevels ? [{ 'Approval Levels': '/approval-limits' }] : []),
                ...(hasApprovalWorkflow ? [{ 'Approval Workflow': '/document-workflow' }] : []),
                ...(hasWorkflowAssignment ? [{ 'Workflow Assignment': '/workflow-assignment' }] : []),
            ]
        },
        {
            'Analytics Designer': [
                ...(hasAnalyticsDesigner ? [{ 'Analytics Designer': '/analytics-designer' }] : []),
                ...(hasDashboardDesigner ? [{ 'Dashboard Designer': '/dashboard-designer' }] : []),
                ...(hasProcessReportingDesigner ? [{ "Process Reporting Designer": "/reporting-by-function" }] : []),
                ...(hasPredefinedRight ? [{ 'Predefined Queries': '/query-field' }] : []),
                // { 'Predefined Queries': '/query-field' },
            ]
        },
        ...(hasUiDesigner ? [{ 'UI Designer': '/label-display-designer' }] : []),
        ...(hasConfiguration ? [{ 'Configuration': '/config-designer' }] : []),
        // {
        //     'UI Designer': '/label-display-designer'
        // },
        // {
        //     'Configuration': '/config-designer'
        // }

    ]

    // const DesignerStudio = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};
const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignerStudio);