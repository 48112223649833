import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MasterDataSearch from "./MasterDataSearch"
import { getLblDispDesFieldValue } from '../../redux/actions';



const CoverageMappingSearch = props => {

    const [allowedField, setAllowedField] = React.useState([]);
    const [requiredField, setRequiredField] = React.useState([]);
    const [attributeNameArray, setAttributeNameArray] = React.useState([]);

    useEffect(() => {
        var data = { "data_source": "coverage_cost_mappings" }
        props.getLblDispDesFieldValue(data, 'post');
    }, []);


    useEffect(() => {
        var mandatory = []
        var requiredFields = []
        let newArray2 = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    if (item.mandatory) {
                        newArray2.push({
                            current: item.current,
                            data_type: item.data_type,
                            default: item.default,
                            display: item.display,
                            drop_down_reference: item.drop_down_reference,
                            drop_down_reference_value: item.drop_down_reference_value,
                            drop_down_table: item.drop_down_table,
                            ignore_dropdown: item.ignore_dropdown,
                            ignore_fields: item.ignore_fields,
                            field_id: item.key,
                            name: item.current,
                            mandatory: item.mandatory,
                            required: item.required,
                            type: item.type,
                        })

                    }
                    if (item.mandatory) {
                        mandatory.push(item.key)
                        requiredFields.push(item.current)
                    }

                })

            setAllowedField(mandatory)
            setRequiredField(requiredFields);
            setAttributeNameArray(newArray2)
        }
    }, [props.tableData]);



    return (
        <>
            <MasterDataSearch data={attributeNameArray} title={"Coverage Cost Mapping"} table={"coverage_cost_mappings"}></MasterDataSearch>
        </>
    );
};

const mapStateToProps = state => {
    return {
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverageMappingSearch);

