import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    TextField,
    OutlinedInput
} from '@mui/material';
import { Link } from 'react-router-dom';
import { getCoverageCostSingleItem, updateCoverageCostData } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const CoverageCostEdit = props => {
    const classes = useStyles();
    const [country, setCountry] = React.useState('');
    const [productType, setProductType] = React.useState('');
    const [coverageType, setCoverageType] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [coverageLength, setCoverageLength] = React.useState('');
    const [orderReason, setOrderReason] = React.useState('');
    const [coverageCost, setCoverageCost] = React.useState('');
    const [validFrom, setValidFrom] = React.useState(null);
    const [validTo, setValidTo] = React.useState(null);

    function handleCoverage(newValue) {
        setCoverageType(newValue.target.value);
    }
    function handleCountry(newValue) {
        setCountry(newValue.target.value);
    }
    function handleProductType(newValue) {
        setProductType(newValue.target.value);
    }
    function handleMaterial(newValue) {
        setMaterialNumber(newValue.target.value);
    }
    function handleOrderReason(newValue) {
        setOrderReason(newValue.target.value);
    }
    function handleCoverageLength(newValue) {
        setCoverageLength(newValue.target.value);
    }
    function handleCoverageCost(newValue) {
        setCoverageCost(newValue.target.value);
    }
    function handleValidFrom(newValue) {
        setValidFrom(newValue);
    }
    function handleValidTo(newValue) {
        setValidTo(newValue);
    }

    function handleOnClear() {
        setOrderReason('')
        setCountry('')
        setProductType('')
        setCoverageType('')
        setMaterialNumber('')
        setCoverageCost('')
        setCoverageLength('')
        setValidFrom(null)
        setValidTo(null)
    }

    const handleSubmit = () => {
        var formData = {
            coverage_type: coverageType,
            country: country,
            product_line: productType,
            coverage_cost: Number(coverageCost),
            coverage_length: Number(coverageLength),
            order_reason: orderReason,
            material_number: materialNumber,
            valid_from: validFrom,
            valid_to: validTo,


        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(formData, appId);
    };

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleData(appId);
    }, []);

    useEffect(() => {
        if (props.coverageCostDataSingle) {
            setMaterialNumber(props.coverageCostDataSingle.material_number)
            setCoverageType(props.coverageCostDataSingle.coverage_type);
            setCountry(props.coverageCostDataSingle.country);
            setProductType(props.coverageCostDataSingle.product_line);
            setCoverageCost(props.coverageCostDataSingle.coverage_cost);
            setCoverageLength(props.coverageCostDataSingle.coverage_length);
            setOrderReason(props.coverageCostDataSingle.order_reason)
            setValidFrom(props.coverageCostDataSingle.valid_from)
            setValidTo(props.coverageCostDataSingle.valid_to)
        }
    }, [props.coverageCostDataSingle]);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/coverage-cost'
                >
                    Coverage Cost
                </Link>
                <Typography color="textPrimary" variant='h4'>Coverage Cost Edit </Typography>
            </Breadcrumbs>
            {
                <div className={classes.bodyContainer}>
                    <Card
                        style={{ marginTop: 15 }}>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container
                                    style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Material Number
                                        </FormLabel>
                                        <OutlinedInput
                                            value={materialNumber}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMaterial}
                                            readOnly
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Country
                                        </FormLabel>
                                        <OutlinedInput
                                            value={country}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCountry} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Product Line
                                        </FormLabel>
                                        <OutlinedInput
                                            value={productType}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleProductType} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Coverage Type
                                        </FormLabel>
                                        <OutlinedInput
                                            value={coverageType}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCoverage}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Coverage Length
                                        </FormLabel>
                                        <OutlinedInput
                                            value={coverageLength}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCoverageLength}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "15px" }}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Order Reason
                                        </FormLabel>
                                        <OutlinedInput
                                            value={orderReason}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleOrderReason}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "15px" }}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Coverage Cost
                                        </FormLabel>
                                        <OutlinedInput
                                            value={coverageCost}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCoverageCost}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Valid From
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 35,
                                                        }
                                                    }}
                                                    value={validFrom}
                                                    onChange={date => handleValidFrom(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Valid To
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 35,
                                                        }
                                                    }}
                                                    value={validTo}
                                                    onChange={date => handleValidTo(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.submitContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                            Clear
                        </Button>
                        <Button color="primary" variant="contained" className={classes.button} onClick={handleSubmit}
                            disabled={coverageType && country && orderReason && validFrom && validTo && coverageCost && coverageLength ? false : true}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        coverageCostDataSingle: state.dataSetupData.coverageCostDataSingle,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(updateCoverageCostData(data, id)),
        getSingleData: (id) => dispatch(getCoverageCostSingleItem(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoverageCostEdit);