import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer,
    TableBody, TextField, OutlinedInput, FormLabel,
    Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    loadMaterialMasterFormatFields, postMaterialMasterQuery, getMaterialMasterQuery
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const PurchaseInvoiceEditQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        // setQueryObject({ ...queryObject, 'format_name': queryName.trim() });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('material-master-query') ? JSON.parse(sessionStorage.getItem('material-master-query')) : []);
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [materialDescription, setMaterialDescription] = React.useState('');
    const [materialType, setMaterialType] = React.useState('');
    const [baseUom, setBaseUom] = React.useState('');
    const [formatType, setFormatType] = React.useState(sessionStorage.getItem('material-master-type') ? JSON.parse(sessionStorage.getItem('material-master-type')) : [])
    const [sourceUom, setSourceUom] = React.useState('');
    const [targetUom, setTargetUom] = React.useState('');
    const [conversionFactor, setConversionFactor] = React.useState('');
    const [salesOrganization, setSalesOrganization] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [distributionChannel, setDistributionChannel] = React.useState('');
    const requiredFields = [
        'material_number', 'material_description', 'material_type', 'base_uom', 'source_uom', 'target_uom', 'conversion_factor', 'distribution_channel', 'sales_organization', 'division'
    ]
    const [defaultValue, setDefaultValue] = React.useState({});
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        props.loadSalesDataFormatFields(formatType);
        props.getMaterialMasterQuery(purchaseQueryDataStorage.id);
    }, [formatType]);

    useEffect(() => {
        if (props.materialMasterSingleData) {
            if (props.materialMasterSingleData.default_values != null) {
                setDefaultValue(props.materialMasterSingleData.default_values);
                setMaterialNumber(props.materialMasterSingleData.default_values.material_number);
                setMaterialDescription(props.materialMasterSingleData.default_values.material_description);
                setMaterialType(props.materialMasterSingleData.default_values.material_type);
                setBaseUom(props.materialMasterSingleData.default_values.base_uom);
                setSourceUom(props.materialMasterSingleData.default_values.source_uom);
                setTargetUom(props.materialMasterSingleData.default_values.target_uom);
                setConversionFactor(props.materialMasterSingleData.default_values.conversion_factor);
                setDivision(props.materialMasterSingleData.default_values.division);
                setDistributionChannel(props.materialMasterSingleData.default_values.distribution_channel);
                setSalesOrganization(props.materialMasterSingleData.default_values.sales_organization);
            }
            if (props.materialMasterSingleData.format !== 0)
                setQueryObject(props.materialMasterSingleData.format)
            if (props.materialMasterSingleData.format) {
                setQueryName(props.materialMasterSingleData.format.format_name)
                // if(props.materialMasterSingleData.format.material_number!==0)
                setMaterialNumber(props.materialMasterSingleData.format.material_number);
                // if(props.materialMasterSingleData.format.material_description!==0)
                setMaterialDescription(props.materialMasterSingleData.format.material_description);
                // if(props.materialMasterSingleData.format.material_type!==0)
                setMaterialType(props.materialMasterSingleData.format.material_type);
                // if(props.materialMasterSingleData.format.base_uom!==0)
                setBaseUom(props.materialMasterSingleData.format.base_uom);
                // if(props.materialMasterSingleData.format.source_uom!==0)
                setSourceUom(props.materialMasterSingleData.format.source_uom);
                // if(props.materialMasterSingleData.format.target_uom!==0)
                setTargetUom(props.materialMasterSingleData.format.target_uom);
                // if(props.materialMasterSingleData.format.conversion_factor!==0)
                setConversionFactor(props.materialMasterSingleData.format.conversion_factor);
                // if(props.materialMasterSingleData.format.division!==0)
                setDivision(props.materialMasterSingleData.format.division);
                // if(props.materialMasterSingleData.format.distribution_channel!==0)
                setDistributionChannel(props.materialMasterSingleData.format.distribution_channel);
                // if(props.materialMasterSingleData.format.sales_organization!==0)
                setSalesOrganization(props.materialMasterSingleData.format.sales_organization);
            }

        }
    }, [props.materialMasterSingleData]);
    const handleDefaultValue = (e) => {
        setDefaultValue(e.target.value);
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit({ ...queryObject, 'format_name': queryName, 'default_values': defaultValue }, formatType, purchaseQueryDataStorage.id);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
            setQueryObject({ ...queryObject });
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setDefaultValue({ ...defaultValue });
    }
    function handleClearAll() {
        handleClear();
        setMaterialNumber('')
        setMaterialDescription('')
        setMaterialType('')
        setBaseUom('')
        setConversionFactor('')
        setSourceUom('')
        setTargetUom('')
        setDivision('')
        setDistributionChannel('')
        setSalesOrganization('')

    }
    function handleCancelDialog() {
        history.push('/material-master/material-master-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'material_number') {
            setMaterialNumber(event.target.value)
        }
        if (item === 'material_description') {
            setMaterialDescription(event.target.value)
        }
        if (item === 'material_type') {
            setMaterialType(event.target.value)
        }
        if (item === 'base_uom') {
            setBaseUom(event.target.value)
        }
        if (item === 'source_uom') {
            setSourceUom(event.target.value)
        }
        if (item === 'target_uom') {
            setTargetUom(event.target.value)
        }
        if (item === 'conversion_factor') {
            setConversionFactor(event.target.value)
        }
        if (item === 'sales_organization') {
            setSalesOrganization(event.target.value)
        }
        if (item === 'distribution_channel') {
            setDistributionChannel(event.target.value)
        }
        if (item === 'division') {
            setDivision(event.target.value)
        }
    };
    const handleChange2 = (event, item) => {
        const fieldMappings = {
            'material_number': setMaterialNumber,
            'material_description': setMaterialDescription,
            'material_type': setMaterialType,
            'base_uom': setBaseUom,
            'source_uom': setSourceUom,
            'target_uom': setTargetUom,
            'conversion_factor': setConversionFactor,
            'sales_organization': setSalesOrganization,
            'distribution_channel': setDistributionChannel,
            'division': setDivision,
        };

        setDefaultValue({ ...defaultValue, [item]: event.target.value });

        const setField = fieldMappings[item];
        if (setField) {
            setField(event.target.value);

            if (event.target.value.length === 0) {
                setField(" ");
            }
        }
    };

    console.log(props.materialMasterSingleData)
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/material-master/material-master-excel-upload'
                >
                    Material Master Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.salesInvoiceQuery ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.salesInvoiceQuery
                                                        .filter(item => item != 'id' && item != 'format_name' && item != 'organization_id' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }} classes={{ root: classes.fontSetting }} >
                                                                        {requiredFields && requiredFields.includes(item) ? <p>{item.replace(/_/g, ' ')}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: 70 }} classes={{ root: classes.fontSetting }}>
                                                                        <TextField
                                                                            value={queryObject && queryObject[item] ? queryObject[item] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                        // defaultValue={JSON.parse(localStorage.getItem('material-master-query'))[item] ? JSON.parse(localStorage.getItem('material-master-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70, paddingLeft: 10 }}>
                                                                        <TextField
                                                                            value={defaultValue && defaultValue[item] ? defaultValue[item] : ''}
                                                                            type="string"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange2(e, item)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={formatType === 'Material Master Data' ? ((materialNumber || defaultValue['material_number']) && (materialType || defaultValue['material_type']) && (materialDescription || defaultValue['material_description']) && (baseUom || defaultValue['base_uom']) ? false : true)
                            : formatType === 'Material Master Organization Data' ? ((materialNumber || defaultValue['material_number']) && (distributionChannel || defaultValue['distribution_channel']) && (division || defaultValue['division']) && (salesOrganization || defaultValue['sales_organization']) ? false : true)
                                : ((materialNumber || defaultValue['material_number']) && (sourceUom || defaultValue['source_uom']) && (targetUom || defaultValue['target_uom']) && (conversionFactor || defaultValue['conversion_factor']) ? false : true)}> Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, format, id) => dispatch(postMaterialMasterQuery(data, format, id, 'put')),
        getMaterialMasterQuery: (id) => dispatch(getMaterialMasterQuery(id)),
        loadSalesDataFormatFields: (formatType) => dispatch(loadMaterialMasterFormatFields(formatType))
    }
}

const mapStateToProps = state => {
    return {
        salesInvoiceQuery: state.dataSetupData.materialMasterQueryFieldsData,
        materialMasterSingleData: state.dataSetupData.materialMasterSingleData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceEditQuery);