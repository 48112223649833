import React, { useEffect } from 'react';
import '../../../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadPaymentGraph } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Chart } from "react-google-charts";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'

    }
}));
const PaymentCharts = (props) => {
    const classes = useStyles();
    const [paymentCalculationSchemaData, setPaymentCalculationSchemaData] = React.useState([]);
    const [paymentContractTypeData, setPaymentContractTypeData] = React.useState([]);
    const [paymentContractGroupData, setPaymentContractGroupData] = React.useState([]);
    const [paymentContractSubGroupData, setPaymentContractSubGroupData] = React.useState([]);

    useEffect(() => {
        const months = [];
        var i;
        var label = ['Month', 'Accrual', 'Payment'];
        var accrualData = props.accrualPaymentGraphData.accrual_result;
        var paymentData = props.accrualPaymentGraphData.payment_result;
        const dateEnd = Moment();
        const dateStart = Moment().subtract(11, 'month');
        while (dateEnd.diff(dateStart, 'months') >= 0) {
            months.push([dateStart.format('MMM YYYY')])
            dateStart.add(1, 'month')
        }
        months.forEach(e => {
            e.push(0, 0)
        })
        months.unshift(label);
        if (props.accrualPaymentGraphData &&
            props.accrualPaymentGraphData.accrual_result && props.accrualPaymentGraphData.accrual_result.length > 0
        ) {
            for (i = 0; i < 12; i++) {
                accrualData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][1] = e.Total
                    }
                })
                paymentData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][2] = e.Total
                    }
                })
            }
        }
    }, [props.accrualPaymentGraphData]);

    //rename pie keys
    useEffect(() => {
        if (props.paymentGraphCalculationSchemaSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphCalculationSchemaSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentCalculationSchemaData(newArray);
        }

    }, [props.paymentGraphCalculationSchemaSuccessData]);
    useEffect(() => {
        if (props.paymentGraphContractGroupSuccessData) {
            var label = ['Contract group', 'Percentage'];
            var output = props.paymentGraphContractGroupSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractGroupData(newArray);
        }
    }, [props.paymentGraphContractGroupSuccessData]);

    useEffect(() => {
        if (props.paymentGraphContractTypeSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphContractTypeSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractTypeData(newArray);
        }
    }, [props.paymentGraphContractTypeSuccessData]);

    useEffect(() => {
        if (props.paymentGraphContractSubGroupSuccessData) {
            var label = ['Contract sub group', 'Percentage'];
            var output = props.paymentGraphContractSubGroupSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractSubGroupData(newArray);
        }
    }, [props.paymentGraphContractSubGroupSuccessData]);


    return (

        <Grid container className={classes.root}>
            {props.data === 'Contract Group' && <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}

            >
                <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Contract Group</Typography>
                {paymentContractGroupData.length > 1 ?
                    <Chart
                        height={400}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={paymentContractGroupData}
                        options={{
                            pieHole: 0.4,
                            colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                            legend: { position: 'top', alignment: 'center' },
                            chartArea: { 'width': '100%', 'height': '80%' },
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />
                    :
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', alignItems: 'center', height: "46vh"
                    }}>
                        <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                    </div>
                }
            </Grid>}
            {props.data === 'Sub Contract Group' && <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}

            >
                <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Sub Contract Group</Typography>
                {paymentContractSubGroupData.length > 1 ?
                    <Chart
                        height={400}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={paymentContractSubGroupData}
                        options={{
                            pieHole: 0.4,
                            colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                            legend: { position: 'top', alignment: 'center' },
                            chartArea: { 'width': '100%', 'height': '80%' },
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />
                    :
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', alignItems: 'center', height: "46vh"
                    }}>
                        <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                    </div>
                }
            </Grid>}

            {props.data === 'Contract Type' && <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}

            >
                <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Contract Type</Typography>
                {paymentContractTypeData.length > 1 ?
                    <Chart
                        height={400}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={paymentContractTypeData}
                        options={{
                            is3D: true,
                            colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                            legend: { position: 'top', alignment: 'center' },
                            chartArea: { 'width': '100%', 'height': '80%' },
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />
                    :
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', alignItems: 'center', height: "46vh"
                    }}>
                        <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                    </div>
                }
            </Grid>}

            {props.data === 'Calculation Method' && <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}

            >
                <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Calculation Method</Typography>
                {paymentCalculationSchemaData.length > 1 ?
                    <Chart
                        height={400}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={paymentCalculationSchemaData}
                        options={{
                            is3D: true,
                            colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                            legend: { position: 'top', alignment: 'center' },
                            chartArea: { 'width': '100%', 'height': '80%' },
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />
                    :
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', alignItems: 'center', height: "46vh"
                    }}>
                        <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                    </div>
                }
            </Grid>}
        </Grid>


    );
};

const mapStateToProps = state => {
    return {
        accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
        paymentGraphContractTypeSuccessData: state.initialData.paymentGraphContractTypeSuccessData,
        paymentGraphCalculationSchemaSuccessData: state.initialData.paymentGraphCalculationSchemaSuccessData,
        paymentGraphContractGroupSuccessData: state.initialData.paymentGraphContractGroupSuccessData,
        paymentGraphContractSubGroupSuccessData: state.initialData.paymentGraphContractSubGroupSuccessData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadPaymentGraph: (item, dateRangeValue, type, applicationType, sourceDataType) => dispatch(loadPaymentGraph(item, dateRangeValue, type, applicationType, sourceDataType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCharts);