import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography, OutlinedInput, FormLabel, Radio, RadioGroup, FormControlLabel, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, DropdownArray, Dropdown } from '../../components/Inputs';
import SimpleDialog from '../../components/Dialog';
import { runBuildIndirectSalesData, getDefaultValues, getAllIndirectSalesDataList } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import LoadingOverlay from 'react-loading-overlay';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        // width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const DisplayIndirectSalesFilter = props => {
    const classes = useStyles();
    const { className } = props;
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [billingDocumentNumber, setBillingDocumentNumber] = React.useState([]);
    const [billingDocumentLine, setBillingDocumentLine] = React.useState([]);
    const [distributionChannel, setDistributionChannel] = React.useState([]);
    const [division, setDivision] = React.useState([]);
    const [employeeNumber, setEmployeeNumber] = React.useState([]);
    const [employeeName, setEmployeeName] = React.useState([]);
    const [terittoryID, setTerittoryID] = React.useState([]);
    const [salesDistrict, setSalesDistrict] = React.useState([]);
    const [salesOffice, setSalesOffice] = React.useState([]);
    const [salesGroup, setSalesGroup] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [salesTracingDateStart, setSalesTracingDateStart] = React.useState(null);
    const [salesTracingDateEnd, setSalesTracingDateEnd] = React.useState(null);
    const [resaleDateStart, setResaleDateStart] = React.useState(null);
    const [resaleDateEnd, setResaleDateEnd] = React.useState(null);
    const [billingDocMultiple, setBillingDocMultiple] = React.useState([]);
    const [billingDocLineMultiple, setBillingDocLineMultiple] = React.useState([]);
    const [billingDocLineRange, setBillingDocLineRange] = React.useState('');
    const [companyCodeMultiple, setCompanyCodeMultiple] = React.useState([]);
    const [salesOrgMultiple, setSalesOrgMultiple] = React.useState([]);
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [productHierarchyMultiple, setProductHierarchyMultiple] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);
    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    const [channelPartnerId, setChannelPartnerId] = React.useState([]);
    const [uniqueIdentifier, setUniqueIdentifier] = React.useState([]);
    const [channelPartnerEndCustomerId, setChannelPartnerEndCustomerId] = React.useState([]);
    const [channelPartnerEndCustomerName, setChannelPartnerEndCustomerName] = React.useState([]);
    const [channelPartnerMaterial, setChannelPartnerMaterial] = React.useState([]);
    const [channelPartnerName, setChannelPartnerName] = React.useState([]);
    const [city, setCity] = React.useState([]);
    // const [companyCode,setCompanyCode]=React.useState([]);
    const [country, setCountry] = React.useState([]);
    const [currency, setCurrency] = React.useState([]);
    const [customerGroup, setCustomerGroup] = React.useState([]);
    const [customerIndustry, setCustomerIndustry] = React.useState([]);
    const [endCustomerId, setEndCustomerId] = React.useState([]);
    const [materialDescription, setMaterialDescription] = React.useState([]);
    const [postalCode, setPostalCode] = React.useState([]);
    const [postingDate, setPostingDate] = React.useState([]);
    const [pricingDate, setPricingDate] = React.useState([]);

    const [region, setRegion] = React.useState([]);
    // const [resaleDate,setResaleDate]=React.useState([]);


    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    const [open, setOpen] = useState(false);
    const formData = {
        "billing_date.range": (billingDateStart ? Moment(billingDateStart).local().format('YYYY-MM-DD') : '') + ',' + (billingDateEnd ? Moment(billingDateEnd).local().format('YYYY-MM-DD') : ''),
        "billing_doc_number.in": billingDocumentNumber.toString(),
        "billing_doc_line.in": billingDocumentLine.toString(),
        "sales_Tracing_date.range": (salesTracingDateStart ? Moment(salesTracingDateStart).local().format('YYYY-MM-DD') : '') + ',' + (salesTracingDateEnd ? Moment(salesTracingDateEnd).local().format('YYYY-MM-DD') : ''),
        "resale_date.range": (resaleDateStart ? Moment(resaleDateStart).local().format('YYYY-MM-DD') : '') + ',' + (resaleDateEnd ? Moment(resaleDateEnd).local().format('YYYY-MM-DD') : ''),
        "company_code.in": companyCodeMultiple.toString(),
        "sales_org.in": salesOrgMultiple.toString(),
        "calendar_year_month.in": calendarYearMonth.toString(),
        "material_number.in": materialNumberMultiple.toString(),
        "material_group.in": materialGroupMultiple.toString(),
        "product_hierarchy.in": productHierarchyMultiple.toString(),
        "plant.in": plantMultiple.toString(),
        "profit_center.in": profitCenterMultiple.toString(),
        "flex_attribute1.in": flexAttribute1.toString(),
        "flex_attribute2.in": flexAttribute2.toString(),
        "customer_number.in": customerNumber.toString(),
        "distribution_channel.in": distributionChannel.toString(),
        "division.in": division.toString(),
        "employee_number.in": employeeNumber.toString(),
        "employee_name.in": employeeName.toString(),
        "territory_id.in": terittoryID.toString(),
        "sales_district.in": salesDistrict.toString(),
        "sales_office.in": salesOffice.toString(),
        "sales_group.in": salesGroup.toString(),
        "channel_partner_id": channelPartnerId.toString(),
        "unique_identifier": uniqueIdentifier.toString(),
        "channel_partner_end_customer_id": channelPartnerEndCustomerId.toString(),
        "channel_partner_end_customer_name": channelPartnerEndCustomerName.toString(),
        "channel_partner_id": channelPartnerId.toString(),
        "channel_partner_material": channelPartnerMaterial.toString(),
        "channel_partner_name": channelPartnerName.toString(),
        "city": city.toString(),

        "country": country.toString(),
        "currency": currency.toString(),
        "customer_group": customerGroup.toString(),
        "customer_industry": customerIndustry.toString(),
        "end_customer_id": endCustomerId.toString(),
        "material_description": materialDescription.toString(),
        "posting_date": postingDate.toString(),
        "postal_code": postalCode.toString(),
        "pricing_date": pricingDate.toString(),


        "region": region.toString(),

    };
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData, 'delete', radioGroupValue,);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setRadioGroupValue('');
        setBillingDocumentNumber([]);
        setBillingDocumentLine([]);
        setDistributionChannel([]);
        setDivision([]);
        setEmployeeNumber([]);
        setEmployeeName([]);
        setTerittoryID([]);
        setSalesDistrict([]);
        setSalesOffice([]);
        setSalesGroup([]);
        setFlexAttribute1([]);
        setFlexAttribute2([]);
        setCustomerNumber([]);
        setBillingDateStart(null);
        setBillingDateEnd(null);
        setBillingDocMultiple([]);
        setBillingDocLineMultiple([]);
        setBillingDocLineRange('');
        setCompanyCodeMultiple([]);
        setSalesOrgMultiple([]);
        setMaterialNumberMultiple([]);
        setCalendarYearMonth([]);
        setMaterialGroupMultiple([]);
        setProductHierarchyMultiple([]);
        setPlantMultiple([]);
        setProfitCenterMultiple([]);
        setSalesTracingDateStart(null)
        setSalesTracingDateEnd(null)
        setResaleDateStart(null)
        setResaleDateEnd(null)
        setChannelPartnerId([])
        setUniqueIdentifier([]);
        setChannelPartnerEndCustomerId([]);
        setChannelPartnerEndCustomerName([]);
        setChannelPartnerMaterial([]);
        setChannelPartnerName([]);
        setCity([]);

        setCountry([]);
        setCurrency([]);
        setCustomerGroup([]);
        setCustomerIndustry([]);
        setEndCustomerId([]);
        setMaterialDescription([]);
        setPostalCode([]);
        setPostingDate([]);
        setPricingDate([]);
        setRegion([]);
    }
    const handleClickOpen = (arg) => {
        if (arg === 'SF') {
            sessionStorage.setItem('Syncfusion', true);
        } else {
            sessionStorage.removeItem('Syncfusion');
        }
        setOpen(true)
    };
    const handleCompanyCodeMultiple = (newValue) => {
        setCompanyCodeMultiple(newValue);
    }
    const handleSalesOrgMultiple = (newValue) => {
        setSalesOrgMultiple(newValue);
    }
    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }
    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }
    const handleProductHierarchyMultiple = (newValue) => {
        setProductHierarchyMultiple(newValue);
    }
    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }

    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);

    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
    }
    const handleTerittoryID = (newValue) => {
        setTerittoryID(newValue);
    }
    function handleCalendarYearMonth(newValue) {
        setCalendarYearMonth(newValue)
    }
    function handleChannelPartnerId(newValue) {
        setChannelPartnerId(newValue)
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue)
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue)
    }
    function handleUniqueIdentifier(newValue) {
        setUniqueIdentifier(newValue)
    }
    function handleBillingDocumentNumber(newValue) {
        setBillingDocumentNumber(newValue)
    }
    function handleBillingDocumentLine(newValue) {
        setBillingDocumentLine(newValue)
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue)
    }
    function handleDistributionChannel(newValue) {
        setDistributionChannel(newValue)
    }
    function handleDivision(newValue) {
        setDivision(newValue)
    }
    function handleEmployeeNumber(newValue) {
        setEmployeeNumber(newValue)
    }
    function handleEmployeeName(newValue) {
        setEmployeeName(newValue)
    }
    function handleSalesDistrict(newValue) {
        setSalesDistrict(newValue)
    }
    function handleSalesOffice(newValue) {
        setSalesOffice(newValue)
    }
    function handleSalesGroup(newValue) {
        setSalesGroup(newValue)
    }
    function handleRegion(newValue) {
        setRegion(newValue)
    }


    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Display Indirect Sales Data </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Calendar Year Month</FormLabel>
                                    <ChipInput data={calendarYearMonth} onChange={handleCalendarYearMonth} />

                                </div>
                            </Grid><Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 20 }}
                                className={classes.calendarLeftGridContainer}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>Billing Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={date => handleBillingDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={date => handleBillingDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 20 }}
                                className={classes.calendarLeftGridContainer}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>Sales Tracing Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !salesTracingDateStart && salesTracingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={salesTracingDateStart}
                                                onChange={date => setSalesTracingDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={salesTracingDateEnd}
                                                onChange={date => setSalesTracingDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={salesTracingDateStart ? salesTracingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 20 }}
                                className={classes.calendarLeftGridContainer}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>Resale Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !resaleDateStart && resaleDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={resaleDateStart}
                                                onChange={date => setResaleDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={resaleDateEnd}
                                                onChange={date => setResaleDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={resaleDateStart ? resaleDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Channel Partner ID</FormLabel>
                                    <ChipInput data={channelPartnerId} onChange={handleChannelPartnerId} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Number</FormLabel>
                                    <ChipInput data={customerNumber} onChange={handleCustomerNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Plant</FormLabel>
                                    <ChipInput data={plantMultiple} onChange={handlePlantMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Profit Center</FormLabel>
                                    <ChipInput data={profitCenterMultiple} onChange={handleProfitCenterMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Number</FormLabel>
                                    <ChipInput data={materialNumberMultiple} onChange={handleMaterialNumberMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Group</FormLabel>
                                    <ChipInput data={materialGroupMultiple} onChange={handleMaterialGroupMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Product Hierarchy</FormLabel>
                                    <ChipInput data={productHierarchyMultiple} onChange={handleProductHierarchyMultiple} />

                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 1</FormLabel>
                                    <ChipInput data={flexAttribute1} onChange={handleFlexAttribute1} />

                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Unique Identifier</FormLabel>
                                    <ChipInput data={uniqueIdentifier} onChange={handleUniqueIdentifier} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Number</FormLabel>
                                    <ChipInput data={billingDocumentNumber} onChange={handleBillingDocumentNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing  Line</FormLabel>
                                    <ChipInput data={billingDocumentLine} onChange={handleBillingDocumentLine} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 2</FormLabel>
                                    <ChipInput data={flexAttribute2} onChange={handleFlexAttribute2} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Company Code</FormLabel>
                                    <ChipInput data={companyCodeMultiple} onChange={handleCompanyCodeMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Org</FormLabel>
                                    <ChipInput data={salesOrgMultiple} onChange={handleSalesOrgMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Distribution Channel</FormLabel>
                                    <ChipInput data={distributionChannel} onChange={handleDistributionChannel} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Division</FormLabel>
                                    <ChipInput data={division} onChange={handleDivision} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Number</FormLabel>
                                    <ChipInput data={employeeNumber} onChange={handleEmployeeNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Name</FormLabel>
                                    <ChipInput data={employeeName} onChange={handleEmployeeName} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Territory ID</FormLabel>
                                    <ChipInput data={terittoryID} onChange={handleTerittoryID} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales District</FormLabel>
                                    <ChipInput data={salesDistrict} onChange={handleSalesDistrict} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Office</FormLabel>
                                    <ChipInput data={salesOffice} onChange={handleSalesOffice} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Group</FormLabel>
                                    <ChipInput data={salesGroup} onChange={handleSalesGroup} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Region</FormLabel>
                                    <ChipInput data={region} onChange={handleRegion} />

                                </div>
                            </Grid>


                            {/* <Grid
                            item
                            md={12}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                <FormControlLabel
                                    value="Staging sales data"
                                    control={<Radio color="primary" />}
                                    label="Delete Staging Sales Data"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                                <FormControlLabel
                                    value="Sales data"
                                    control={<Radio color="primary" />}
                                    label="Delete Sales Data"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                            </RadioGroup>
                        </Grid> */}

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            {/* <Button
                                disabled={(

                                    calendarYearMonth.length > 0
                                    || channelPartnerEndCustomerId.length>0 
                                   
                                    || channelPartnerEndCustomerName.length > 0 || channelPartnerId.length > 0 || channelPartnerMaterial.length > 0 || channelPartnerName.length > 0
                                    || city.length > 0  || companyCodeMultiple.length > 0 || country.length > 0 || currency.length > 0
                                   || customerGroup.length > 0 || customerIndustry.length > 0 
                                    || distributionChannel.length > 0 
                                    || division.length > 0 || employeeName.length > 0 
                                    || employeeNumber.length > 0 || endCustomerId.length > 0 
                                    || materialDescription.length > 0 
                                     || materialNumberMultiple.length > 0 || materialGroupMultiple.length > 0
                                    || productHierarchyMultiple.length > 0 || postalCode.length > 0 || postingDate.length > 0|| pricingDate.length>0 ||
                                    profitCenterMultiple.length>0 || 
                                    region.length>0 || (resaleDateStart!=null) || (resaleDateEnd!=null) || salesDistrict.length>0 || salesGroup.length>0
                                    || salesOffice.length>0 || salesOrgMultiple.length>0 || (salesTracingDateStart!=null)
                                    || (salesTracingDateEnd!=null) || terittoryID.length >0
                                ) ? false : true}
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button> */}
                            <Button
                                disabled={(

                                    calendarYearMonth.length > 0
                                    || channelPartnerEndCustomerId.length > 0

                                    || channelPartnerEndCustomerName.length > 0 || channelPartnerId.length > 0 || channelPartnerMaterial.length > 0 || channelPartnerName.length > 0
                                    || city.length > 0 || companyCodeMultiple.length > 0 || country.length > 0 || currency.length > 0
                                    || customerGroup.length > 0 || customerIndustry.length > 0
                                    || distributionChannel.length > 0
                                    || division.length > 0 || employeeName.length > 0
                                    || employeeNumber.length > 0 || endCustomerId.length > 0
                                    || materialDescription.length > 0
                                    || materialNumberMultiple.length > 0 || materialGroupMultiple.length > 0
                                    || productHierarchyMultiple.length > 0 || postalCode.length > 0 || postingDate.length > 0 || pricingDate.length > 0 ||
                                    profitCenterMultiple.length > 0 ||
                                    region.length > 0 || (resaleDateStart != null) || (resaleDateEnd != null) || salesDistrict.length > 0 || salesGroup.length > 0
                                    || salesOffice.length > 0 || salesOrgMultiple.length > 0 || (salesTracingDateStart != null)
                                    || (salesTracingDateEnd != null) || customerNumber.length > 0 || flexAttribute1.length > 0 || flexAttribute2.length > 0
                                    || terittoryID.length > 0 || uniqueIdentifier.length > 0 || billingDocumentNumber.length > 0 || billingDocumentLine.length > 0 || (billingDateStart != null) || (billingDateEnd != null)
                                ) ? false : true}
                                variant="contained" color="primary" className={classes.button} onClick={() => handleClickOpen('SF')} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run with Syncfusion'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content={'Do you want to run ' + radioGroupValue + '?'} handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(getAllIndirectSalesDataList(formData)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        AllIndirectSalesDataList: state.dataSetupData.AllIndirectSalesDataList,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayIndirectSalesFilter);
