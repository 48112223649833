import React, { useState } from 'react';
import validate from 'validate.js';
import { makeStyles } from "@mui/styles";
import {
    Grid, Button, TextField, Typography,Paper
} from '@mui/material';
import Box from "@mui/material/Box";
import { connect } from 'react-redux';
import { resetPassword, resetPasswordOrganization } from '../../redux/actions';
import { history } from '../../components/Helpers';
import PasswordStrengthBar from 'react-password-strength-bar';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};
const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        //backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '100%',
        width:'100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center'
    },
    contentBodyLeft:{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.main,
    },
    contentBody: {
        flexGrow: 1,
       //width:'100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xl')]: {
            justifyContent: 'center'
        }
    },
    form: {
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        //color: 'white'
        color:theme.palette.primary.main,
    },
    textField: {
        height: 55,
        paddingLeft: 10,
        backgroundColor: 'white',
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    },
    logoContainer: {
        justifyContent: 'center',
        display: 'flex'
    },
    logo: {
        [theme.breakpoints.down('xl')]: {
            width: window.screen.width * .50
        },
        [theme.breakpoints.up('md')]: {
            width: window.screen.width * .20
        },
    },
    backToLogin: {
        color: 'blue',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}));

const ForgotPassword = (props) => {

    const classes = useStyles();
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [changePasswordDisabled, setChangePasswordDisabled] = React.useState(true);
    const [token, setToken] = React.useState('39b58131f65aaad857d237fb9cd497eb1e027707-89fb-41d6-86d9-60385da444d7');
    const handleSignIn = event => {
        event.preventDefault();
        props.onChangePassword(password, token);
    };
    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    const handlePassword = event => {
        setPassword(event.target.value);
        if (event.target.value === confirmPassword && event.target.value.length >= 8) {
            setChangePasswordDisabled(false);
        } else {
            setChangePasswordDisabled(true);
        }
    }
    const handleConfirmPassword = event => {
        setConfirmPassword(event.target.value);
        if (event.target.value === password && event.target.value.length >= 8) {
            setChangePasswordDisabled(false);
        } else {
            setChangePasswordDisabled(true);
        }
    }
    const handleChange = event => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleResetpassword = (event) => {
        event.preventDefault();
        var url = window.location.href;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        var status = urlParams.get('status');
        var token = urlParams.get('token');
        var criteria = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$")
        if (criteria.test(confirmPassword)) {
            if (status = "user") {
                props.onChangePasswordOrganization(password, token);
            }
            else {
                props.onChangePassword(password, token);
            }
        }
        else {
            alert(' Password must contain atleast 8 characters including Uppercase,Lowercase, Numbers and Symbols.')
        }
    }
    const handleLogin = () => {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(confirmPassword)) {
            history.push('login');
        } else {
            alert('Password should containt atleast one symbol')
        }
    }
    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={0}
                lg={12}
                xs={12}
                //direction="column"
                alignItems="center"
                //justify="center"
                classes={{
                    root: classes.root
                }}
            >
                <Grid
                    className={classes.contentBodyLeft}
                    item
                    xs={12}
                    sm={4}
                    md={7}
                   component={Paper}
                   elevation={6}
                   square
                >
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            
                                <div className={classes.logoContainer}>
                                    <img
                                        alt="Logo"
                                        src={require("../../library/images/new_logo.png")}
                                        className={classes.logo}
                                    />
                                </div>
                            </div>
                    </div>
                </Grid>
                <Grid
                    className={classes.content}
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <Box
                               sx={{
                                my: 8,
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                                   <LockOutlinedIcon />
                                </Avatar>
                                <Typography
                                    className={classes.title}
                                    variant="h2"
                                    align='center'
                                >
                                    Reset Password
                                </Typography>
                                <form
                                className={classes.form}
                                onSubmit={handleResetpassword}
                                >
                               
                                <TextField
                                    fullWidth
                                    // helperText={
                                    //     hasError('password') ? formState.errors.password[0] : ' '
                                    // }
                                    // label="New Password"
                                    name="password"
                                    onChange={handlePassword}
                                    type="password"
                                    value={password}
                                    variant="outlined"
                                    InputProps={{
                                        className: classes.textField,
                                    }}
                                    placeholder="Password"
                                />
                                <div style={{ fontSize: 12, margin: '5px 0px 5px 0px', color: 'grey' }}>
                                    Password must contain atleast 8 characters including Uppercase,Lowercase, Numbers and Symbols.
                                </div>
                                <PasswordStrengthBar
                                    password={password}
                                    minLength={8}
                                />
                                <TextField
                                    fullWidth
                                    placeholder="Confirm Password"
                                    helperText={
                                        hasError('password') ? formState.errors.password[0] : ' '
                                    }
                                    // label="Confirm New Password"
                                    name="confirm password"
                                    onChange={handleConfirmPassword}
                                    type="password"
                                    value={confirmPassword}
                                    variant="outlined"
                                    InputProps={{
                                        className: classes.textField,
                                    }}
                                    style={{ marginTop: 10 }}
                                />
                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    disabled={changePasswordDisabled}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    CHANGE PASSWORD
                                </Button>
                                <h5 onClick={handleLogin} className={classes.backToLogin}>
                                    Back to login
                                </h5>
                            </form>
                            </Box>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePassword: (password, token) => dispatch(resetPassword(password, token)),
        onChangePasswordOrganization: (password, token) => dispatch(resetPasswordOrganization(password, token))
    }
}

export default connect(null, mapDispatchToProps)(ForgotPassword);

{/*
 <div className={classes.root}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                classes={{
                    root: classes.root
                }}
            >
                <Grid
                    className={classes.content}
                    item
                    lg={12}
                    xs={12}
                >
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <form
                                className={classes.form}
                                onSubmit={handleResetpassword}
                            >
                                <div className={classes.logoContainer}>
                                    <img
                                        alt="Logo"
                                        src={require("../../library/images/new_logo.png")}
                                        className={classes.logo}
                                    />
                                </div>
                                <Typography
                                    className={classes.title}
                                    variant="h2"
                                    align='center'
                                >
                                    Reset Password
                                </Typography>
                                <TextField
                                    fullWidth
                                    // helperText={
                                    //     hasError('password') ? formState.errors.password[0] : ' '
                                    // }
                                    // label="New Password"
                                    name="password"
                                    onChange={handlePassword}
                                    type="password"
                                    value={password}
                                    variant="outlined"
                                    InputProps={{
                                        className: classes.textField,
                                    }}
                                    placeholder="Password"
                                />
                                <div style={{ fontSize: 12, margin: '5px 0px 5px 0px', color: 'white' }}>
                                    Password must contain atleast 8 characters including Uppercase,Lowercase, Numbers and Symbols.
                                </div>
                                <PasswordStrengthBar
                                    password={password}
                                    minLength={8}
                                />
                                <TextField
                                    fullWidth
                                    placeholder="Confirm Password"
                                    helperText={
                                        hasError('password') ? formState.errors.password[0] : ' '
                                    }
                                    // label="Confirm New Password"
                                    name="confirm password"
                                    onChange={handleConfirmPassword}
                                    type="password"
                                    value={confirmPassword}
                                    variant="outlined"
                                    InputProps={{
                                        className: classes.textField,
                                    }}
                                    style={{ marginTop: 10 }}
                                />
                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    disabled={changePasswordDisabled}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    CHANGE PASSWORD
                                </Button>
                                <h5 onClick={handleLogin} className={classes.backToLogin}>
                                    Back to login
                                </h5>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
 */}