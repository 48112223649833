import React, { useState, useRef, useEffect, forwardRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  useMediaQuery,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { connect } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { uploadOffInvoiceCostFile } from "../../redux/actions";
import LoadingOverlay from "react-loading-overlay";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { createTheme } from "@mui/material/styles";
import HashLoader from "react-spinners/HashLoader";
import { NavLink as RouterLink } from "react-router-dom";
const theme = createTheme({});
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: 29,
    marginTop: 16,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  spinner: {
    height: "100vh",
  },
}));
const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));
const OffInvoiceCost = (props) => {
  const { className } = props;
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorList, setErrorList] = React.useState([]);
  useEffect(() => {
    if (
      props.offInvoiceCostErrorList &&
      props.offInvoiceCostErrorList.length > 0
    ) {
      setErrorList(props.offInvoiceCostErrorList);
      setErrorDialogOpen(true);
    }
  }, [props.offInvoiceCostErrorList]);
  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        spinner={<HashLoader />}
        className={classes.spinner}
      // text='Loading ... Please wait ...'
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: 22 }}> Off Invoice Cost Upload</div>
          <div
            className={clsx({
              [classes.row]: isDesktop,
            })}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddBoxOutlinedIcon />}
              classes={{
                startIcon: !isDesktop && classes.startIcon,
                root: !isDesktop && classes.container,
              }}
              component={CustomRouterLink}
              to='/off-Invoice-cost/off-Invoice-cost-excel-upload' >
              {isDesktop ? "Upload" : ""}
            </Button>
          </div>
        </div>
      </LoadingOverlay>
      <Dialog open={errorDialogOpen}>
        <DialogTitle>Error Summary</DialogTitle>
        <DialogContent
          classes={{
            root: { padding: "43px 37px 0px 37px !important" },
          }}>
          <List>
            {errorList &&
              errorList.map((item, index) => {
                return (
                  <ListItem>
                    <ListItemText primary={[index + 1] + " . " + item} />
                  </ListItem>
                );
              })}
          </List>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => (setErrorDialogOpen(false), setErrorList(null))}>
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.dataSetupData.loading,
    offInvoiceCostErrorListData:
      state.dataSetupData.offInvoiceCostErrorListData,
    offInvoiceCostErrorList: state.dataSetupData.offInvoiceCostErrorList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadOffInvoiceCostFile: (file) =>
      dispatch(uploadOffInvoiceCostFile(file)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OffInvoiceCost);