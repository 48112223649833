import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid, Checkbox, FormLabel
} from '@mui/material';
import { LabelText } from '../../../../../components/Inputs';
import Moment from 'moment';
import { getBudgetingConfig } from '../../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '5px 45px 0px 45px !important'
    },
    checkBoxContainer: {
        paddingLeft: 0,
    }

}));

const SetupData = props => {
    const classes = useStyles();
    const [promotionNumber, setPromotionNumber] = React.useState('');
    const [promotionTheme, setPromotionTheme] = React.useState('');
    const [promotionCategory, setPromotionCategory] = React.useState('');
    const [promotionType, setPromotionType] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [promotionStartDate, setPromotionStartDate] = React.useState(null);
    const [promotionEndDate, setPromotionEndDate] = React.useState(null);
    const [reservationStartDate, setReservationStartDate] = React.useState(null);
    const [reservationEndDate, setReservationEndDate] = React.useState(null);
    const [registrationStartDate, setRegistrationStartDate] = React.useState(null);
    const [registrationEndDate, setRegistrationEndDate] = React.useState(null);
    const [promoFields, setPromoFields] = React.useState([]);
    const [promoCurrency, setPromoCurrency] = React.useState('');
    const [costingCurrency, setCostingCurrency] = React.useState('');
    const [costingDesc, setCostingDesc] = React.useState('');
    const [exclusivePromotion, setExclusivePromotion] = React.useState(false);
    const [depositAmount, setDepositAmount] = React.useState(0);

    useEffect(() => {

        if (props.promoData) {

            setPromotionNumber(props.promoData.costing_number)
            setPromotionTheme(props.promoData.promotion_theme)
            setPromotionCategory(props.promoData.promotion_category)
            setPromotionType(props.promoData.promotion_type)
            setCountry(props.promoData.country)
            localStorage.setItem("Country", props.promoData.country);
            setDivision(props.promoData.division)
            setPromotionStartDate(props.promoData.promotion_start_date != '0001-01-01T00:00:00Z' ? props.promoData.promotion_start_date : null)
            setPromotionEndDate(props.promoData.promotion_end_date != '0001-01-01T00:00:00Z' ? props.promoData.promotion_end_date : null)
            setReservationStartDate(props.promoData.reservation_start_date != '0001-01-01T00:00:00Z' ? props.promoData.reservation_start_date : null)
            setReservationEndDate(props.promoData.reservation_end_date != '0001-01-01T00:00:00Z' ? props.promoData.reservation_end_date : null)
            setRegistrationStartDate(props.promoData.registration_start_date != '0001-01-01T00:00:00Z' ? props.promoData.registration_start_date : null)
            setRegistrationEndDate(props.promoData.registration_end_date != '0001-01-01T00:00:00Z' ? props.promoData.registration_end_date : null)
            setPromoFields(props.promoData.promotion)
            setPromoCurrency(props.promoData.promotion_currency)
            setCostingCurrency(props.promoData.costing_currency)
            setCostingDesc(props.promoData.costing_description)
            setDepositAmount(props.promoData.deposit_amount ? props.promoData.deposit_amount : 0)
            setExclusivePromotion(props.promoData.exclusive_promotion)

        }

    }, [props.promoData]);

    return (
        <div>
            <div className={classes.root}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="HEADER FIELDS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <LabelText heading='Costing Number' data={promotionNumber} twoline='true' />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}

                                >
                                    <LabelText heading='Promotion Theme' data={promotionTheme} twoline='true' />

                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <LabelText heading=' Promotion Category' data={promotionCategory} twoline='true' />

                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <LabelText heading='Promotion Type' data={promotionType} twoline='true' />


                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <LabelText heading='Costing Description' data={costingDesc} twoline='true' />


                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <LabelText heading='Country' data={country} twoline='true' />


                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <LabelText heading='Division' data={division} twoline='true' />


                                </Grid>
                                {promotionStartDate &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginBottom: 20 }}
                                    >
                                        <LabelText heading='Promotion Start Date' data={Moment.utc(promotionStartDate).format('MM/DD/YYYY')} twoline='true' />


                                    </Grid>
                                }
                                {promotionEndDate &&

                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginBottom: 20 }}
                                    >
                                        <LabelText heading='Promotion End Date' data={Moment.utc(promotionEndDate).format('MM/DD/YYYY')} twoline='true' />

                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <LabelText heading='Promotion Currency' data={promoCurrency} twoline='true' />


                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <LabelText heading='Costing Currency' data={costingCurrency} twoline='true' />


                                </Grid>
                                {reservationStartDate &&

                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginBottom: 20 }}

                                    >
                                        <LabelText heading='Reservation Start Date' data={Moment.utc(reservationStartDate).format('MM/DD/YYYY')} twoline='true' />
                                    </Grid>
                                }
                                {reservationEndDate &&


                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginBottom: 20 }}
                                    >
                                        <LabelText heading='Reservation End Date' data={Moment.utc(reservationEndDate).format('MM/DD/YYYY')} twoline='true' />


                                    </Grid>
                                }
                                {registrationStartDate &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginBottom: 20 }}
                                    >
                                        <LabelText heading='Registration Start Date' data={Moment.utc(registrationStartDate).format('MM/DD/YYYY')} twoline='true' />


                                    </Grid>
                                }
                                {registrationEndDate &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <LabelText heading='Registration End Date' data={Moment.utc(registrationEndDate).format('MM/DD/YYYY')} twoline='true' />

                                    </Grid>
                                }
                                {depositAmount ?
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <LabelText heading='Deposit Amount' data={depositAmount} twoline='true' />
                                    </Grid>
                                    : ''
                                }
                                {exclusivePromotion ?
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <div>
                                            <Checkbox
                                                checked={exclusivePromotion}
                                                readOnly
                                                color='primary'
                                                className={classes.checkBoxContainer}
                                                classes={{
                                                    root: classes.formControlRoot
                                                }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            <FormLabel>
                                                Exclusive Promotion
                                            </FormLabel>
                                        </div>
                                    </Grid>
                                    : ''
                                }
                            </Grid>
                        </div>


                    </form>
                </Card>

            </div>

        </div>
    );

};
const mapStateToProps = state => {
    return {

        configData: state.calculationAnalysisData.budgetData,
        promoData: state.promotionData.promotionSetupSingleData,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SetupData);