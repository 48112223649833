import React, { useEffect } from "react";
import Moment from 'moment';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Select, MenuItem, FormLabel, Typography, Button,
    AppBar, Toolbar, CssBaseline, Divider, IconButton, OutlinedInput
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { getAnaylsisFormula, getAttributesConfig, getGrossToNet } from "../../../redux/actions";
import MultiSelectDropdown from "../../../components/Inputs/MultiSelectDropdown";
import { AllOtherAttributeMasterComponent, ChipDropdown, SearchDropdown } from "../../../components/Inputs";
import GrossToNetResult from "./GrossToNetResult";
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px',
    },
    select: {
        width: '100%',
        color: '#1675e0',
        backgroundColor: 'white',
    },
    selectedItem: {
        borderRadius: 5,
    },
    fontSetting: {
        fontSize: '16px',
        textTransform: 'capitalize'
    },
    spinner: {
        height: '100vh'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 28,
        paddingBottom: 30,
    },
    button: {
        marginRight: 10,
    },
    drawer: {
        position: 'absolute',
        flexShrink: 0,
        zIndex: 998
    },
    drawerPaper: {
        marginTop: 65
    },
    drawerIcon: {
        marginRight: 10
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        fontSize: theme.typography.h2.fontSize
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        fontSize: theme.typography.h2.fontSize,
        paddingLeft: 10
    },
    header: {
        fontSize: theme.typography.h1.fontSize
    },
    inputTwoLine: {
        paddingLeft: 10,
        width: '100%'
    },
    selectInput: {
        width: '100%',
        color: '#1675e0',
        backgroundColor: 'white',
        borderRadius: 5
    },
}));

const GrossToNet = (props) => {

    const theme = useTheme();
    const [simulationType, setSimulationType] = React.useState('Historical - Actual');
    const [viewFormat, setViewFormat] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [formulaList, setFormulaList] = React.useState([]);
    const [formula, setFormula] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [filterData, setFilterData] = React.useState({});
    const [grossNetData, setGrossNetData] = React.useState([]);
    const [onSubmit, setOnSubmit] = React.useState(false);
    const [analysisType, setAnalysisType] = React.useState('driver_category');
    const [open, setOpen] = React.useState(true);
    const [tag, setTag] = React.useState('')
    const [attributeLabels, setAttributeLabels] = React.useState([])
    const [heading, setHeading] = React.useState([])
    const classes = useStyles();
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleStartDate = (e) => {
        if (e && e.isValid()) {
            setStartDate(e);
        } else {
            setStartDate(null);
        }
    }
    const handleEndDate = (e) => {
        if (e && e.isValid()) {
            setEndDate(e);
        } else {
            setEndDate(null);
        }
    }

    useEffect(() => {
        setStartDate(Moment().subtract(1, 'months').startOf('month'));
        setEndDate(Moment().subtract(1, 'months').endOf('month'));
    }, [])

    useEffect(() => {
        props.getConfigData()
        props.getAttributeData()
    }, [])

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setFormulaList(props.configData.map(item => item.formula_name));
        }
    }, [props.configData])
    useEffect(() => {
        if (props.attributesData && props.attributesData.length > 0) {
            setAttributeArray(props.attributesData.map(item => item.qualifier_key));
        }
    }, [props.attributesData])
    useEffect(() => {
        var attr = []
        if (props.grossNetData && props.grossNetData.length > 0 && onSubmit) {
            setGrossNetData(props.grossNetData);
            if (props.grossNetData[props.grossNetData.length - 1] && props.grossNetData[props.grossNetData.length - 1]['keys']) {
                attributes.map(item => {
                    attr.push(props.grossNetData[props.grossNetData.length - 1]['keys'][item])
                })
            }
            if (props.grossNetData[props.grossNetData.length - 2] && props.grossNetData[props.grossNetData.length - 2]['sort']) {
                setHeading(props.grossNetData[props.grossNetData.length - 2]['sort'])

            }
            setAttributeLabels(attr)
            setOnSubmit(false)
            if (props.grossNetData.length == 3)
                setViewFormat('chart')
            else
                setViewFormat('list')
        }
        else {
            setGrossNetData([])
            setOnSubmit(false)
        }
    }, [props.grossNetData])
    const handleFilterData = (value, key) => {
        setFilterData(prevState => ({
            ...prevState,
            [key]: value
        }))
    }
    function handleClose() {
        console.log("trrr")
        setOpen(false)
    }
    const handleTag = (e) => {
        setTag(e.target.value)
    }
    const handleRun = () => {
        setOnSubmit(true)
        setOpen(false)
        var sd = startDate ? Moment.utc(startDate).toISOString()?.split("T")[0].concat("T00:00:00.000Z") : '';
        var ed = endDate ? Moment.utc(endDate).toISOString()?.split("T")[0].concat("T23:59:59.000Z") : '';
        var data = {
            "formula_name": formula,
            "start_date": sd,
            "end_date": ed,
            "simulation_category": simulationType,
            "attributes": attributes,
            "filter": filterData,
            "tags": tag
        }
        props.onSubmit(data, analysisType)
    }
    return (
        <LoadingOverlay
            active={onSubmit}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}

                >
                    <Toolbar style={{ position: 'absolute', marginTop: 65, display: open && 'none' }}>
                        <IconButton
                            color="black"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={classes.drawerIcon}
                            size="large">
                            <Menu />
                        </IconButton>
                        <Typography className={{ root: classes.header }} variant="h1" color='primary'> Gross to Net Analysis </Typography>
                    </Toolbar>

                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="top"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <Typography variant="h3" color='primary'> Gross to Net Analysis </Typography>
                        <IconButton onClick={handleClose} size="large">
                            {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    </div>
                    <Grid container className={classes.root}>
                        <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                            <Grid container
                                item
                                md={3}
                                xs={12}
                            >

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Start Date</FormLabel>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Grid container justifyContent="space-around">
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            borderRadius: 5,
                                                            color: '#1675e0'
                                                        }
                                                    }}
                                                    value={startDate}
                                                    onChange={handleStartDate}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>End Date</FormLabel>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Grid container justifyContent="space-around">
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            borderRadius: 5,
                                                            color: '#1675e0'
                                                        }
                                                    }}
                                                    value={endDate}
                                                    minDate={startDate ? startDate : false}
                                                    onChange={handleEndDate}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container
                                item
                                md={filters && filters.length > 0 ? 6 : 5}
                                xs={12}
                            >
                                <Grid
                                    item
                                    md={simulationType == "Historical - What-If" ? 3 : 4}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Simulation Category</FormLabel>
                                        <Select
                                            value={simulationType}
                                            onChange={(e) => setSimulationType(e.target.value)}
                                            label='Posting Type'
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}

                                        >
                                            <MenuItem value={"Historical - Actual"}>
                                                Historical - Actual
                                            </MenuItem>
                                            <MenuItem value={"Historical - What-If"}>
                                                Historical - What-If
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                {simulationType == "Historical - What-If" &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        style={{ paddingRight: 15 }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <FormLabel classes={{ root: classes.fontSetting }} >Tag</FormLabel>
                                            <OutlinedInput
                                                value={tag}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleTag}
                                            />
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required >Analysis Type</FormLabel>
                                        <Select
                                            value={analysisType}
                                            onChange={(e) => setAnalysisType(e.target.value)}
                                            label='analysis Type'
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}

                                        >
                                            <MenuItem value={"driver_name"}>
                                                Driver Name
                                            </MenuItem>
                                            <MenuItem value={"driver_sub_category"}>
                                                Driver Sub-category
                                            </MenuItem>
                                            <MenuItem value={"driver_category"}>
                                                Driver Category
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={simulationType == "Historical - What-If" ? 4 : 5}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Formula</FormLabel>
                                        <Select
                                            value={formula}
                                            onChange={(e) => setFormula(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {formulaList && formulaList.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container
                                item
                                md={(filters && filters.length > 0) || simulationType == "Historical - What-If" ? 3 : 3}
                                xs={12}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required>Attributes</FormLabel>
                                    <div style={{ marginTop: -24 }}>
                                        <MultiSelectDropdown id='attribute-list' capitalize={true}
                                            listArray={attributeArray} onChange={(value => setAttributes(value))}
                                            data={attributes} class={classes.selectInput}
                                        />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} >Filters</FormLabel>
                                    <div style={{ marginTop: -24 }}>
                                        <MultiSelectDropdown id='filter-list' capitalize={true}
                                            listArray={attributeArray} onChange={(value => setFilters(value))}
                                            data={filters} class={classes.selectInput}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            {filters && filters.length > 0 &&
                                filters.map(item => (
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ paddingRight: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} >{item.replace(/_/g, ' ')}</FormLabel>

                                        {item == 'material_number' || item == 'customer_number' || item == 'supplier_number' || item == 'employee_number' ?

                                            <div style={{ marginTop: -5 }}>
                                                <SearchDropdown id='payment_single' multiple
                                                    table={item == 'supplier_number' ? 'supplier_master' : item == 'employee_number' ? 'employee_master'
                                                        : item == 'material_number' ? 'material_master' : 'customer_master'}
                                                    keyName={item}
                                                    description={true}
                                                    onChange={(value) => handleFilterData(value, item)}
                                                />
                                            </div>
                                            :
                                            item === 'customer_chain' || item === 'customer_group' || item === 'material_group' || item === 'product_hierarchy' || item === 'company_code' || item === 'sales_org' || item === 'supplier_group' ?
                                                <div style={{ marginTop: -10 }}>
                                                    <AllOtherAttributeMasterComponent attributeName={item} value={filterData[item]} onChange={(e) => handleFilterData(e, item)} prevalue={filterData[item]} withOutLabel={true} id={item} multiple />
                                                </div>
                                                :
                                                <div style={{ marginTop: -15 }}>
                                                    <ChipDropdown id={item} data={filterData[item]} onChange={(value) => handleFilterData(value, item)}
                                                    />
                                                </div>
                                        }
                                    </Grid>
                                ))
                            }
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleRun}
                                    twoline="true"
                                    disabled={(!startDate || !endDate || !simulationType || !analysisType
                                        || !formula || attributes.length == 0) ? true : false}

                                >
                                    <DirectionsRunIcon />
                                </Button>
                            </div>
                        </Grid>


                    </Grid>
                </Drawer>
                {grossNetData && grossNetData.length > 0 &&
                    <GrossToNetResult data={grossNetData} view={viewFormat}
                        attribute={attributeLabels} headings={heading} type={analysisType} />
                }
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.profitOptimizationData.loadGrossNet,
        configData: state.profitOptimizationData.priceCalForListData,
        attributesData: state.profitOptimizationData.qualifierConfigData,
        grossNetData: state.profitOptimizationData.grossNetData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, analysisType) => dispatch(getGrossToNet(data, analysisType)),
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        getAttributeData: () => dispatch(getAttributesConfig()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrossToNet);