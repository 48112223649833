import React from 'react';
import { ContractSetupTable } from './components';

const ContractSetup = props => {

    return (
        <div>
            <ContractSetupTable />
        </div>
    );
};

export default ContractSetup;