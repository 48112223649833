import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import {
    FormLabel, MenuItem, Select, Grid,
    Table, TableCell, TableHead, TableRow, TableContainer, ListItemText,
    TableBody, OutlinedInput, IconButton, Button, Typography, Checkbox, Input
} from '@mui/material';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader, RotateLoader } from 'react-spinners'
import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import { getMasterDataByOperation, loadValuesBasedOnAppTypeAndField, getDefaultValuesAllData, getDefaultValues, getDefaultValuesAllDataNew, getContractsTableDataBySearchEligibilty, getLblDispDesFieldValue } from '../../redux/actions';
import { MultiSelectDropdown } from '../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '10px 29px 29px 29px',
        margin: '30px 0px 30px'
    },
    container: {
        paddingTop: 18,
        width: '100%'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30,
        width: '100%'
    },
    button: {
        marginRight: 25,
        width: 140,
        height: 36
    },
    gridContainer: {
        paddingRight: 15,
        marginBottom: 20
    },

    label: {
        marginTop: 10
    },
    select: {
        color: '#1675e0',
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
}));
var td = [];
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const MasterDataFilters = props => {


    const classes = useStyles();
    const { className } = props;
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [addeligiblity, setAddEligibility] = React.useState(1)
    const [validItem, setValidItem] = React.useState(true)
    const [formData, setFormData] = React.useState([])
    const [attributeNameArray, setAttributeNameArray] = React.useState([])
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const optionData = ['EQUAL', 'NOT EQUAL', 'RANGE', 'NOT RANGE']
    var eligibility = { attribute_name: '', attribute_value: [] }
    const [allowedApps, setAllowedApps] = React.useState([sessionStorage.getItem('application')]);
    const [attributes, setAttributes] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [incentiveRateTypeArray, setIncentiveRateTypeArray] = React.useState([]);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [attributesData, setAttributesData] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [filterData, setFilterData] = React.useState({});
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        var data = {
            // "key": sessionStorage.getItem('action-data'),
            // "name": sessionStorage.getItem('action-data').replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ')})
            "data_source": sessionStorage.getItem('action-data')
        }
        props.getLblDispDesFieldValue(data, 'post');
    }, []);
    useEffect(() => {
        if (props.tableData && props.tableData.field_label_attributes && props.tableData.field_label_attributes) {
            var listData = [{ 'field_id': 'contract_id', 'name': 'Contract ID', 'type': 'STRING', 'drop_down_value_keys': [] }]
            Object.values(props.tableData.field_label_attributes)
                .map((item, i) => {
                    var name = ''
                    var data = props.tableData.field_label_attributes && Object.entries(props.tableData.field_label_attributes).filter(([k, v]) => k === item.key)
                    name = item.current
                    if (item.key != 'key_figure_name' && item.key != 'attribute_name' && item.key != 'application_type' &&
                        item.key != 'calculation_method' && item.key != 'incentive_rate_type') {
                        listData.push({ 'field_id': item.key, 'name': name, 'type': "STRING", 'drop_down_value_keys': item.drop_down_reference_value })
                        // listData.push({ 'field_id': item.key, 'name': name, 'type': item.key.includes('date')?"TIME":item.drop_down_reference_value.length>0 ? "DROPDOWN.STRING" :"STRING", 'drop_down_value_keys': item.drop_down_reference_value })
                        if (item.key === "approval_status") {
                            var temp = ['New', 'Submit for Approval', 'Accepted', 'Rejected', 'Changes Accepted']
                            // listData.push({ 'field_id': item.key, 'name': name, 'type': item.key.includes('date')?"TIME":item.drop_down_reference_value.length>0 ? "DROPDOWN.STRING" :"STRING", 'drop_down_value_keys': temp })
                        }
                    }
                })
            setAttributeArray(listData.filter((v, i, a) => a.findIndex(v2 => (v2.name === v.name)) === i))
        }

    }, [props.tableData])
    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === attributeArray.length ? [] : attributeArray.map((item, i) => item.field_id));
            setAllSelect(selected.length === attributeArray.length ? false : true)
            setSelectedDetail(selected.length === attributeArray.length ? [] : attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
        //setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
        //setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [level]: selected })
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])

    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(attributeArray && attributeArray.length > 0 && attributes.length === attributeArray.length)
    }
    function handleSubmit() {
        formData.map((item, index) => {

            if (item.attribute_name === '' && item.attribute_value.length === 0) {
                delete formData[index]
            }

        })
        // var obj = {
        //     "filter_fields": {
        //         ...filterData
        //     }
        // }
        // Object.entries(filterData).map(([key, value]) => {
        //     console.log(value[0].includes(',') ? value.toString() : value.toString())
        //     obj[key.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', '') })] = value[0].includes(',') ? value.toString() : value + ','.toString()
        // })
        const obj = {};
        Object.entries(filterData).forEach(([key, value]) => {
            const oldValue = value.oldValue || '';
            const newValue = value.newValue || '';
            obj[key] = `${oldValue},${newValue}`;
        });

        for (const key in obj) {
            if (!obj[key].includes(',')) {
                obj[key] = '';
            }
        }

        props.onSubmit(sessionStorage.getItem('action-data'), 'UPDATE', 'Search', obj)
    }

    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
            setSourceDataType(props.sourceDataTypeValue['drop_down_value_keys'][0])
        }
    }, [props.sourceDataTypeValue])

    useEffect(() => {
        if (props.attributeData && props.attributeData.records && props.attributeData.records.length > 0) {
            if (props.attributeData.records[0].label_names)
                setAttributeNameArray(props.attributeData.records[0].label_names);
        }
    }, [props.attributeData]);
    const handleStartDate = (value) => {
        setStartDate(value);
    }
    const handleEndDate = (value) => {
        setEndDate(value);
    }
    function handleDeleteLocally(index) {
        formData.map((item, i) => {
            if (i == index - 1) {
                delete formData[i]
            }

        })
        setAddEligibility(addeligiblity - 1)
        checkvalues()
    }
    function handleChangeValue(field, index, value) {

        if (field == 'attribute_value')
            value = [value]
        formData[index - 1][field] = value
        setFormData([...formData])
        checkvalues()

    }
    function handleOnAdd() {
        var newValue = formData
        newValue = [...newValue, eligibility]
        setFormData(newValue);
        setAddEligibility(addeligiblity + 1)
        setValidItem(false)
    }

    function checkvalues() {
        formData.map((item, index) => {
            if (item.attribute_name === '' || item.attribute_value.length === 0 || item.option === '' || item.condition_id === 0) {
                setValidItem(false)
            }
            else
                setValidItem(true)

        })

    }
    // console.log("filter", filterData)
    const handleFilterData = (data, item, type) => {
        let temp = { ...filterData };
        if (type === 'oldValue' || type === 'newValue') {
            temp[item.field_id] = {
                ...temp[item.field_id],
                [type]: data
            };
        } else {
            temp[item.field_id] = data;
        }
        setFilterData(temp);
    };

    // const handleFilterData = (data, item, type) => {
    //     // console.log("1", data)
    //     // console.log("2", item)
    //     // console.log("3", type)
    //     var chipData = []
    //     var temp = {}
    //     if (type == 'start_date' || type == 'end_date') {
    //         temp = filterData[item.field_id] ? filterData[item.field_id] : {}
    //         setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
    //     }
    //     if (type == 'oldValue') {
    //         // temp = filterData[item.field_id] ? filterData[item.field_id] : {}
    //         // setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
    //         chipData.push({ [item.field_id]: { 'oldValue': data } })

    //     }
    //     if (type == 'newValue') {
    //         temp = filterData[item.field_id] ? filterData[item.field_id] : {}
    //         // setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
    //         chipData.push({ [item.field_id]: { 'newValue': data } })
    //         // if(chipData )
    //     }
    //     if (data && data.length > 0) {
    //         if (type == 'chip') {
    //             chipData = filterData[item.field_id] ? filterData[item.field_id] : []
    //             chipData = [...chipData, data]
    //             setFilterData({ ...filterData, [item.field_id]: chipData })
    //         }
    //         else
    //             setFilterData({ ...filterData, [item.field_id]: data })
    //     }
    //     console.log(chipData)
    // }
    const handleDeleteFilterData = (newValue, index, item) => {
        var deleteFilterData = filterData[item.field_id].filter(item => item !== newValue)
        setFilterData({ ...filterData, [item.field_id]: deleteFilterData })
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <div style={{
                    marginTop: "-1rem",
                    position: "absolute",
                    marginLeft: "3rem"
                }}>
                    <Typography variant="h1" color='primary'> {sessionStorage.getItem('action-data').replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} Search</Typography>
                </div>
                <Grid container className={classes.root}>
                    <Grid container style={{ margin: 10, padding: 20, border: '1px solid #E6E6E6', borderRadius: 5, display: 'block' }}>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ display: 'flex' }}
                        >
                            <FormLabel style={{ color: 'grey', marginRight: 20, paddingTop: 5 }}>
                                Select Filters
                            </FormLabel>
                            <Select
                                labelId="demo-mutiple-name-label"
                                id={'filter-list'}
                                multiple
                                value={attributes ? attributes : []}
                                style={{ maxHeight: "50px", width: '70%', color: 'grey' }}
                                input={<OutlinedInput />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen()}
                                onClose={() => setSelectOpen(false)}
                                open={selectOpen}
                                renderValue={(filter) => {
                                    let type = []
                                    filter.map(itemX => {
                                        attributeArray.filter(item => item.field_id == itemX).map((item, i) => {
                                            if (item.name)
                                                type.push(item.name)
                                        })

                                    })
                                    return type.join(',')
                                }}
                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={allSelect} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {attributeArray && attributeArray.length > 0 && attributeArray
                                    .sort((a, b) => a.name > b.name ? 1 : -1)
                                    .map((item, i) => {
                                        return (

                                            <MenuItem onClick={(event) => handleSelectValues(event, item.field_id, item)} value={item.field_id} key={item.field_id} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected && selected.indexOf(item.field_id) > -1}
                                                />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        )
                                    })}
                                <MenuItem value='' ></MenuItem>

                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>
                        </Grid>
                        {attributesData &&
                            attributesData.map((item, i) => {
                                return (
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {item.type == 'DROPDOWN.STRING' ?
                                            <MultiSelectDropdown capitalize={true} heading={item.name} listArray={item.drop_down_value_keys} data={filterData[item.field_id] ? filterData[item.field_id] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                            :
                                            item.type == 'BOOL' ?
                                                <div style={{ paddingTop: 15 }}>
                                                    <FormLabel required>
                                                        {item.name}
                                                    </FormLabel>
                                                    <Select
                                                        value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                        onChange={(e) => handleFilterData(e.target.value, item)}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >
                                                        <MenuItem value={'true'} key={'true'}>
                                                            Yes
                                                        </MenuItem>
                                                        <MenuItem value={'false'} key={'false'}>
                                                            No
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                                :
                                                item.type == 'TIME' ?
                                                    <div style={{ paddingTop: 15 }}>
                                                        <FormLabel>
                                                            {item.name}
                                                        </FormLabel>
                                                        <div style={{ display: 'flex' }}>
                                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                <Grid container justifyContent="space-around">
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        clearable
                                                                        InputProps={{
                                                                            padding: 0,
                                                                            disableUnderline: true,
                                                                            style: {
                                                                                padding: '1px 0 1px 11px',
                                                                                alignSelf: 'center',
                                                                                alignItems: 'center',
                                                                                marginTop: 10,
                                                                                border: '1px solid #E0E0E0',
                                                                                width: '100%',
                                                                                marginRight: 5
                                                                            }
                                                                        }}
                                                                        value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                        onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                        format="MM/DD/YYYY"
                                                                    />
                                                                </Grid>
                                                            </MuiPickersUtilsProvider>
                                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                <Grid container justifyContent="space-around">
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        clearable
                                                                        InputProps={{
                                                                            padding: 0,
                                                                            disableUnderline: true,
                                                                            style: {
                                                                                padding: '1px 0 1px 11px',
                                                                                alignSelf: 'center',
                                                                                alignItems: 'center',
                                                                                marginTop: 10,
                                                                                border: '1px solid #E0E0E0',
                                                                                width: '100%'
                                                                            }
                                                                        }}
                                                                        value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                        onChange={(e) => handleFilterData(e, item, 'end_date')}

                                                                        format="MM/DD/YYYY"
                                                                    />
                                                                </Grid>
                                                            </MuiPickersUtilsProvider>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ paddingTop: 15 }}>
                                                        <FormLabel>
                                                            {item.name}
                                                        </FormLabel>
                                                        <div style={{ display: 'flex', gap: '15px', width: "140%" }}>
                                                            <OutlinedInput
                                                                // value={filterData[item.field_id] && filterData[item.field_id]['oldValue'] ? filterData[item.field_id]['oldValue'] : ''}
                                                                placeholder='Old Value '
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => handleFilterData(e.target.value, item, 'oldValue')}
                                                            />
                                                            <OutlinedInput
                                                                // value={filterData[item.field_id] && filterData[item.field_id]['newValue'] ? filterData[item.field_id]['newValue'] : ''}
                                                                placeholder='New Value '
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => handleFilterData(e.target.value, item, 'newValue')}
                                                            />
                                                        </div>
                                                        {/* <ChipInput
                                                            classes={{
                                                                root: classes.rootContainer,
                                                                chip: classes.chip,
                                                                input: classes.input,
                                                                inputRoot: classes.inputRoot,
                                                                chipContainer: classes.chipContainer,
                                                                label: classes.chipLabel
                                                            }}
                                                            placeholder='Old Value , New Value'
                                                            value={filterData[item.field_id] ? filterData[item.field_id] : []}
                                                            onAdd={(chips) => handleFilterData(chips, item, 'chip')}
                                                            onDelete={(chip, index) => handleDeleteFilterData(chip, index, item)}
                                                            disableUnderline={true}
                                                            className={clsx({
                                                                [classes.textInput]: true,
                                                                //[classes.error]: contractNumberMultiple.length === 0 && contractNumberRange
                                                            })}
                                                        /> */}
                                                    </div>
                                        }
                                    </Grid>
                                );
                            })
                        }





                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                            disabled={applicationType && validItem ? false : true}>

                            {props.loading ?
                                <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                :
                                'Run'
                            }
                        </Button>
                    </div>
                </Grid>


            </div >
        </LoadingOverlay >
    );

};

const mapStateToProps = state => {
    return {
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        attributeData: state.addMultipleConfigurationData.attributeNameData,
        dropdownKeyFigureData: state.addMultipleConfigurationData.keyFigureNameData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        loading: state.promotionData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (appfunctionality, operation, type, data) => dispatch(getMasterDataByOperation(appfunctionality, operation, type, data)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataFilters);