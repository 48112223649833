import React, { useEffect, forwardRef, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    MenuItem,
    FormLabel,
    IconButton,
    Select,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { getProductFeatureData } from '../../../../redux/actions/ProductFeature/AuthAction'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import DropdownArray from '../../../../components/Inputs/DropdownArray'
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    //new styles
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const ProductFeature = forwardRef((props) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const [organizationStore, setOrganizationStore] = React.useState([]);
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    //options
    const [productFeature, setProductFeature] = React.useState('');
    const [productFeatureValues, setProductFeatureValues] = React.useState('');
    //dropdown
    const [productFeatureDropdown, setProductFeatureDropdown] = React.useState([]);
    const [productFeatureValueDropdown, setProductFeatureValueDropdown] = React.useState([]);
    const [currentEditId, setCurrentEditId] = React.useState('');
    useEffect(() => {
        props.getAllProcductFeature(1, 10);
    }, []);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        var tempArray = [];
        if (props.productFeatureAll && props.productFeatureAll.records && props.productFeatureAll.records.length > 0) {
            props.productFeatureAll.records.map((item) => {
                tempArray.push(item.product_feature)
            })
            setProductFeatureDropdown(tempArray)
        }
    }, [props.productFeatureAll]);
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.product_features) {
            setOrganizationStore(props.materialMasterData.product_features);
        }
    }, [props.materialMasterData]);
    useEffect(() => {
        props.onChange(organizationStore);
    });
    function handleProductFeature(event) {
        setProductFeature(event);
        var tempArray = [];
        if (props.productFeatureAll && props.productFeatureAll.records && props.productFeatureAll.records.length > 0) {
            props.productFeatureAll.records.map((item) => {
                if (item.product_feature === event)
                    item.product_feature_values.map((item) => {
                        tempArray.push(item)
                    })
            })
            setProductFeatureValueDropdown(tempArray)
        }
    }
    function handleProductFeatureValues(event) {
        setProductFeatureValues(event);
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        handleProductFeature(item.product_feature);
        handleProductFeatureValues(item.product_feature_values);
        if (item.ID)
            setCurrentEditId(item.ID);
    }
    var data = {
        "product_feature": productFeature,
        "product_feature_values": productFeatureValues,
    };
    function handleSubmit() {
        if (currentEditId)
            data["ID"] = currentEditId;
        if (editIndex || editIndex === 0) {
            var editedArray = [...organizationStore];
            editedArray[editIndex] = data;
            setOrganizationStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setOrganizationStore([...organizationStore, data]);
            props.onChange([...organizationStore, data]);
        }
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setProductFeature('');
        setProductFeatureValues('');
        setCurrentEditId('');
    }
    const handleDeleteLocally = (index) => {
        setOrganizationStore(item => item.filter((item, i) => i !== index));
        props.onChange(organizationStore.filter((item, i) => i !== index))
    }
    return (
        <div>
            {/* Eligibility Rules Add */}
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Product Feature*
                                        </FormLabel>
                                        <Select
                                            value={productFeature}
                                            onChange={(e) => handleProductFeature(e.target.value)}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {productFeatureDropdown && productFeatureDropdown.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Product Feature Value*
                                        </FormLabel>
                                        <Select
                                            value={productFeatureValues}
                                            onChange={(e) => handleProductFeatureValues(e.target.value)}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {productFeatureValueDropdown && productFeatureValueDropdown.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item md={1} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingTop: 46, marginTop: '0.5rem' }} >
                                        <Grid item md={6} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={productFeature && productFeatureValues ? false : true}
                                                    onClick={() => handleSubmit()}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={productFeature && productFeatureValues ? false : true}
                                                    onClick={() => handleSubmit()}
                                                >
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item
                                            md={6}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="Product Feature"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {organizationStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead} >Product Feature</TableCell>
                                                <TableCell align='center' className={classes.tabHead} >Product Feature Value</TableCell>
                                                <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {organizationStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.product_feature}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.product_feature_values}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleEditRow(item, index)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteLocally(index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>

        </div>
    );

});
const mapDispatchToProps = dispatch => {
    return {
        getAllProcductFeature: (pagination, limit) => dispatch(getProductFeatureData(pagination, limit)),
    }
}
const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.materialMasterViewData,
        productFeatureAll: state.productFeatureData.productFeatureData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null)(ProductFeature);