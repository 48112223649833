export const TableArray=[
    {name:'Direct Sales',key:'direct_sales_data'},
    {name:'Direct Sales - Incentives',key:'direct_sales_financial_postings'},
    {name:'Indirect Sales',key:'indirect_sales_data'},
    {name:'Indirect Sales - Incentives',key:'indirect_sales_financial_postings'},
    {name:'Purchase Data',key:'purchase_data'},
    {name:'Purchase Data - Incentives',key:'purchase_financial_postings'},
]
export const FieldArray={
    direct_sales_data:['net_value','quantity', 'flex_key_figure1','flex_key_figure2','flex_key_figure3','flex_key_ figure4','flex_key_ figure5'],
    direct_sales_financial_postings:['incentive_amount'],
    indirect_sales_data:['acquisition_price', 'approved_amount', 'contract_price', 'claim_amount','incentive_amount','net_value','quantity','flex_key_figure1','flex_key_figure2','flex_key_figure3','flex_key_figure4','flex_key_figure5'],
    indirect_sales_financial_postings:['incentive_amount', 'claim_amount', 'approved_amount'],
    purchase_data:['po_net_value','ir_net_value','gr_net_value','unit_price','po_qty','flex_key_figure1','flex_key_figure2','flex_key_figure3','flex_key_figure4','flex_key_figure5'],
    purchase_financial_postings:['incentive_amount']

}
export const AggregationLevel=['material_number','material_iD_type','material_iD_number','customer_number','customer_group']