import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RingLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    lastRow: {
        borderBottom: '2px solid black'
    },
    text: {
        color: 'var(--iconss, #7F7F7F)',
        fontFamily: 'IBM Plex Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.176px',
        fontFeatureSettings: '"liga" off, "clsg" off',
    }
}));



const PreMatureTable = (props) => {
    const classes = useStyles();
    const [promoState, setPromoState] = React.useState({});

    const promoData = [
        {
            region: "USA West",
            states: [
                { state: "AK", promoCost: "18,750", promoState: "Promo Running", endDate: "05-31-2024" },
                { state: "AZ", promoCost: "50,000", promoState: "Promo Running", endDate: "05-31-2024" },
                { state: "CA", promoCost: "50,000", promoState: "Promo Running", endDate: "05-31-2024" },
                { state: "CO", promoCost: "37,500", promoState: "Promo Running", endDate: "05-31-2024" },
            ],
        },
        {
            region: "USA Center",
            states: [
                { state: "IA", promoCost: "37,500", promoState: "Promo Running", endDate: "05-31-2024" },
                { state: "IL", promoCost: "50,000", promoState: "Promo Running", endDate: "05-31-2024" },
            ],
        },
        {
            region: "USA East",
            states: [
                { state: "AK", promoCost: "18,750", promoState: "Promo Running", endDate: "05-31-2024" },
                { state: "AZ", promoCost: "50,000", promoState: "Promo Running", endDate: "05-31-2024" },
                { state: "CA", promoCost: "50,000", promoState: "Promo Running", endDate: "05-31-2024" },
                { state: "CO", promoCost: "37,500", promoState: "Promo Running", endDate: "05-31-2024" },
            ],
        },
    ];

    const StickerApplied = () => {
        return (
            <div>
                <div className='promo-font' >$500 + (0.99% / 36mo OR 3.99% / 60mo)</div>
                <div className='promo-font' >$1,500 + (0.99% / 36mo OR 3.99% / 60mo)</div>
                <div className='promo-font' >$1,000 + (0.99% / 36mo OR 3.99% / 60mo)</div>
            </div>
        );
    };

    const handlePromoStateChange = (state, region, value) => {
        setPromoState((prevState) => ({
            ...prevState,
            [region]: {
                ...(prevState[region] || {}),
                [state]: value,
            },
        }));
    };

    const totalRows = promoData.reduce((acc, region) => acc + region.states.length, 0);

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "30px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader />}
        >
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='promo-font' style={{ textAlign: 'center' }}>STICKERS APPLIED</TableCell>
                            <TableCell className='promo-font' style={{ textAlign: 'center' }}>REGIONS</TableCell>
                            <TableCell className='promo-font' style={{ textAlign: 'center' }}>STATES</TableCell>
                            <TableCell className='promo-font' style={{ textAlign: 'center' }}>PROMOTION COST</TableCell>
                            <TableCell className='promo-font' style={{ textAlign: 'center' }}>PROMOTION STATE</TableCell>
                            <TableCell className='promo-font' style={{ textAlign: 'center' }}>END DATE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {promoData.map((regionData, regionIndex, allRegions) =>
                            regionData.states.map((stateData, stateIndex) => {
                                const isLastRowInRegion = stateIndex === regionData.states.length - 1;
                                const isLastRowInTable =
                                    regionIndex === allRegions.length - 1 &&
                                    stateIndex === regionData.states.length - 1;
                                const shouldApplyBorder = isLastRowInRegion && !isLastRowInTable;

                                return (
                                    <TableRow
                                        key={`${regionData.region}-${stateData.state}`}
                                        className={shouldApplyBorder ? classes.lastRow : ''}
                                    >

                                        {regionIndex === 0 && stateIndex === 0 && (
                                            <TableCell rowSpan={totalRows} style={{ textAlign: 'center' }}>
                                                <StickerApplied />
                                            </TableCell>
                                        )}


                                        {stateIndex === 0 && (
                                            <TableCell className='promo-font' rowSpan={regionData.states.length} style={{ textAlign: 'center' }}>
                                                {regionData.region}
                                            </TableCell>
                                        )}

                                        <TableCell className='promo-font' style={{ textAlign: 'center' }}>
                                            {stateData.state}
                                        </TableCell>
                                        <TableCell className='promo-font' style={{ textAlign: 'center' }}>
                                            {stateData.promoCost}
                                        </TableCell>
                                        <TableCell className='promo-font' style={{ textAlign: 'center', width: 190 }}>
                                            <Select
                                                value={promoState[regionData.region]?.[stateData.state] || stateData.promoState}
                                                onChange={(e) =>
                                                    handlePromoStateChange(stateData.state, regionData.region, e.target.value)
                                                }
                                                style={{
                                                    backgroundColor: '#f5f5f5',
                                                    borderRadius: '4px',
                                                }}
                                                className='promo-font'
                                            >
                                                <MenuItem className='promo-font' value="Promo Running">Promo Running</MenuItem>
                                                <MenuItem className='promo-font' value="End Prematurely">End Prematurely</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell className='promo-font' style={{ textAlign: 'center' }}>
                                            {stateData.endDate}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
    }
};


export default connect(mapStateToProps, null)(PreMatureTable);
