import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid, Typography, Paper } from "@mui/material";
import { CircularProgress } from "@mui/material";
import AnimatedNumbers from "react-animated-numbers";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    "&:hover": {
      transform: "scale(1.1)",
      transition: "0.6s",
      cursor: "pointer",
      animationDuration: "4s",
      animationDelay: "2s",
    },
  },
  content: {
    color: "#FFFFFF",
    fontSize: "1.8rem",
  },
  nopadding: {
    padding: 0,
    paddingTop: 20,
    paddingLeft: 20,
    "&:last-child": {
      paddingBottom: 0,
      paddingTop: 5,
      paddingLeft: 21,
    },
  },
  zero: {
    lineHeight: 1,
    paddingBottom: 9,
  },
  title: {
    fontSize: 16,
    color: "white",
    opacity: 0.8,
  },
  animatedNumber: {
    fontSize: "1.8rem",
    color: "white",
  },
}));
const Loading = () => {
  return <CircularProgress size={12} color="secondary" />;
};

const ContractCell = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const value = props.value !== undefined && props.value !== null ? props.value : 0;
  const formattedValue = value.toLocaleString();


  return (
    <Card
      {...rest}
      className={props.className}
      classes={{
        root: classes.root,
      }}
    >
      <CardContent
        classes={{
          root: classes.nopadding,
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              gutterBottom
              variant="caption"
            >
              {props.heading}
            </Typography>
            {/* <div className={classes.animatedNumber}>
              <AnimatedNumbers
                includeComma
                animateToNumber={value}
                // fontStyle={{
                //   // fontSize: fontSize: "1.8rem",
                //   color: "white",
                // }}

                locale="en-US"
                configs={[
                  { mass: 1, tension: 220, friction: 100 },
                  { mass: 1, tension: 180, friction: 130 },
                  { mass: 1, tension: 280, friction: 90 },
                  { mass: 1, tension: 180, friction: 135 },
                  { mass: 1, tension: 260, friction: 100 },
                  { mass: 1, tension: 210, friction: 180 },
                ]}
              />
            </div> */}
            <Typography
              variant="subtitle1"
              classes={{ subtitle1: classes.zero }}
              className={classes.content}
            >
              {props.value ? formattedValue : <Loading />}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ContractCell.propTypes = {
  className: PropTypes.string,
  classc: PropTypes.string,
};

export default ContractCell;
