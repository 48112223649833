
import React, { useEffect, useRef } from "react";
import {
    Grid, Button, Typography, Breadcrumbs, Card, FormLabel, OutlinedInput, Select, MenuItem, Fab, Dialog,
    DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { createPriceListData, createPriceListDataAll, getPriceFieldValues, getPriceListData, getDriverFormatFields,getPriceListMaster } from '../../../redux/actions';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Link } from 'react-router-dom';
import {
    RangeDirective, getRangeIndexes,getRangeAddress,
    SpreadsheetComponent, getCell, SheetsDirective, SheetDirective, ColumnsDirective,
    RangesDirective, ColumnDirective, RowsDirective, RowDirective, CellsDirective,
    CellDirective
} from '@syncfusion/ej2-react-spreadsheet';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        overflow: 'scroll'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 15,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formlabel: {
        marginBottom: 8,
        fontSize:theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    input: {
        width: 42,
    },
    addRow: {
        display: 'flex',
        flexDirection: 'row'
    },
}));

const AddPriceListData = props => {
    const classes = useStyles();
    const jRef = useRef(null);
    const [priceListID, setPriceListID] = React.useState('');
    const [listDataArray, setListDataArray] = React.useState([]);
    const [formData, setFormData] = React.useState({});
    const [priceListData, setPriceListData] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [excelDialog, setExcelDialog] = React.useState(false);
    const scrollSettings = { isFinite: true };
    let spreadsheet;
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setPriceListID(appId)
        if (appId) {
            props.getDriverFormatFields(appId)
        }
    }, [])

    function handleOnSubmit() {
        var name = sessionStorage.getItem('pricetypename')
        props.getConfigData(null, null, { ...formData, 'analysis_level_id': priceListID, 'driver_name': name });
        setFormData({})
        setExcelDialog(true)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        if (props.maintenanceFields)
            setListDataArray(props.maintenanceFields)
    }, [props.maintenanceFields])

    useEffect(() => {
        if (props.priceListData && props.priceListData.records && props.priceListData.records[0]) {
            setPriceListData(props.priceListData.records);
            setPriceTypeNameArray(props.priceListData.records[0].price_type_name)
        }
    }, [props.priceListData]);

    const [options, setOptions] = React.useState({})
    const [dataRows, setDataRows] = React.useState([]);
    const filterOptions = {
        type: 'Excel'
    };
    var grid;
    const [columns, setColumns] = React.useState([]);

    function titleCase(str) {
        if (str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }
    useEffect(() => {
        if (props.configData && props.configData.records && props.configData.records.length > 0 && listDataArray && listDataArray.value && listDataArray.value.length > 0 && !props.loadingAPI) {
            // setConfigDataAPI(props.configData);
            if (jRef.current && jRef.current.jexcel)
                jRef.current.jexcel.destroy();
            var temp = [];
            var tempSingle = [];
            var columnArray = [];
            var options = []
            var rec = props.configData.records;
            rec.map(item => {
                listDataArray.value
                    .map(h => {
                        if (item[h]) {
                            if (h.key === 'start_date' || h.key === 'end_date') {
                                tempSingle.push(Moment.utc(item[h.key]).format('MM/DD/YYYY'))
                            } else {
                                tempSingle.push(item[h.key])
                            }
                        } else {
                            tempSingle.push('0');
                        }
                    })
                temp.push(tempSingle);
                tempSingle = [];
            })
            listDataArray.value
                // .filter(h => h !== 'price_type_name')
                .map(h => {
                    if (h === 'start_date' || h === 'end_date' || h === 'per_unit') {
                        columnArray.push({
                            field: h,
                            title: h.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ')}),
                            width: 100
                        });
                        options.push({
                            title: '',
                            width: 100
                        });

                    } else {
                        columnArray.push({
                            field: h,
                            title: h.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ')}),
                            width: 150
                        });
                        options.push({
                            title: '',
                            width: 100
                        });
                    }
                })
            var headers = []
            columnArray.map(item => {
                headers.push(item.field)
            })
            setColumns(columnArray);
            var tempRows = [];
            var tempObj = {};
            var tempRowsNew = [];
            var tempObjNew = {};
            props.configData.records.map((item, ix) => {
                //tempObj=item
                columnArray.map((h, ix2) => {
                    if (h.field === 'start_date' || h.field === 'end_date') {
                        tempObj[h.title] = Moment.utc(item[h.field]).format('M/D/YYYY')
                        tempObjNew[h.field] = Moment(item[h.field]).format('MM/DD/YYYY')
                    }
                    else {
                        tempObj[h.title] = item[h.field]
                        tempObjNew[h.field] = item[h.field]
                    }
                })
                tempRows[ix] = tempObj;
                tempObj = {};
                tempRowsNew[ix] = tempObjNew;
                tempObjNew = {};
            })
            setDataRows(tempRows);
        }
        else
            setDataRows([])
    }, [props.configData, listDataArray]);
    function handleFormData(value, key) {
        if ((key == 'start_date' || key == 'end_date') && value) {
            setFormData({ ...formData, [key]: Moment(value).local().format('YYYY-MM-DD') })
        }
        else
            setFormData({ ...formData, [key]: value })
    }
    function contextMenuBeforeOpen() {
        spreadsheet.removeContextMenuItems(["Insert Column", 'Delete Column', 'Hide Column', 'Cut', 'Paste', 'Paste Special', 'Hyperlink']); //Items that needs to be removed, Set `true` if the given `text` is a unique id.
    };
    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>View Driver Maintenance Data</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {listDataArray && listDataArray.value && listDataArray.value.length > 0 &&
                                    listDataArray.value
                                        .filter(item => item != 'price_type_name')
                                        .map((item) => {
                                            return (
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.formlabel}>
                                                            {item.replace(/_/g, ' ')}
                                                        </FormLabel>
                                                        {item == 'start_date' || item == 'end_date' ?
                                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                <Grid container justifyContent="space-around">
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        clearable
                                                                        InputProps={{
                                                                            padding: 0,
                                                                            disableUnderline: true,
                                                                            style: {
                                                                                padding: '1px 0 1px 11px',
                                                                                alignSelf: 'center',
                                                                                alignItems: 'center',
                                                                                border: '1px solid #E0E0E0',
                                                                                width: '100%',
                                                                                marginTop:'3px'
                                                                            }
                                                                        }}
                                                                        value={formData[item] ? formData[item] : null}
                                                                        onChange={(e) => handleFormData(e, item)}
                                                                        format="MM/DD/YYYY"
                                                                    />
                                                                </Grid>
                                                            </MuiPickersUtilsProvider>
                                                            : item == 'price_type_name' ?

                                                                <Select
                                                                    disableUnderline
                                                                    value={formData[item] ? formData[item] : ''}
                                                                    onChange={(e) => handleFormData(e.target.value, item)}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    style={{ textTransform: 'capitalize', marginTop: 0 }}
                                                                >

                                                                    {priceTypeNameArray && priceTypeNameArray.length > 0 && priceTypeNameArray
                                                                        .map((item) => {
                                                                            return (
                                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                                :
                                                                <OutlinedInput
                                                                    value={formData[item] ? formData[item] : ''}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) => handleFormData(e.target.value, item)} />
                                                        }
                                                    </div>
                                                </Grid>
                                            );
                                        })}


                            </Grid>

                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            </div>

            <Dialog
                    classes={{ paper: classes.paperContainer }}
                    open={excelDialog}
                    onClose={() => setExcelDialog(false)}
                    aria-labelledby="responsive-dialog-title"
                    fullScreen
                >
                    <DialogContent>
                        <DialogContentText>
                        {dataRows && dataRows.length > 0 && columns &&
                            <div style={{ display: 'flex', justifyContent: 'center',height: '100%' }}>
                                <SpreadsheetComponent
                                    scrollSettings={scrollSettings}
                                    ref={(ssObj) => { spreadsheet = ssObj }}
                                    enableVirtualization={true}
                                    allowNumberFormatting={true}
                                    allowDataValidation={true}
                                    showPager={false}
                                    isFinite={true}
                                    width={window.screen.width + 30}
                                    height={window.screen.height-70}
                                    allowEditing={false}
                                    contextMenuBeforeOpen={contextMenuBeforeOpen}
                                >
                                    <SheetsDirective>
                                        <SheetDirective
                                            name="Price List"
                                            colCount={columns && columns.length}
                                            rowCount={dataRows && dataRows.length}
                                            >
                                            <RowsDirective>
                                                <RowDirective >
                                                    <CellsDirective allowEditing={false}>
                                                        {columns && columns.map((item, index) => {
                                                            return (
                                                                <CellDirective
                                                                index={index}
                                                                headerText={item.title}
                                                                value={item.title}
                                                                field={item.field}
                                                                width={window.screen.width / (columns.length)}
                                                                style={{
                                                                    color: '#F2F2F2',
                                                                    backgroundColor: '#095eb5',//localStorage.getItem('appTheme')!='Light'&&localStorage.getItem('appTheme'), 
                                                                    textAlign: 'center',
                                                                }}
                                                                />
                                                            )
                                                        })}
                                                    </CellsDirective>
                                                </RowDirective>
                                                </RowsDirective>
                                                <ColumnsDirective>
                                                    {columns && columns
                                                        .map(item => {
                                                            return <ColumnDirective
                                                                width={window.screen.width / (columns.length)}
                                                                headerText={item.title} field={item.field}
                                                                format={(item.field === 'end_date' || item.field === 'start_date') ? 'mm-dd-yyyy' : null}
                                                                type={(item.field === 'end_date' || item.field === 'start_date') ? 'shortDate' : null}
                                                            />
                                                        })}
                                                </ColumnsDirective>
                                                {/* <RowDirective >
                                                    <CellsDirective >
                                                        {optionsSFPredefined && optionsSFPredefined.map((item, index) => {
                                                            return (
                                                                <CellDirective
                                                                    index={index}
                                                                    value={item}
                                                                />
                                                            )
                                                        })}
                                                    </CellsDirective>
                                                </RowDirective> */}
                                                <RangesDirective>
                                                    <RangeDirective dataSource={dataRows}></RangeDirective>
                                                </RangesDirective>
                                            
                                            
                                            {/* <ColumnsDirective>
                                                {optionsSFPredefined.map(item => {
                                                    return (<ColumnDirective width={window.screen.width/optionsSFPredefined.length}></ColumnDirective>)
                                                })}
                                            </ColumnsDirective> */}
                                        </SheetDirective>
                                    </SheetsDirective>
                                </SpreadsheetComponent>
                            </div>
                        }                   
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setExcelDialog(false)} color="primary" variant="outlined">
                            Back
                        </Button>
                    </DialogActions>
                </Dialog>

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, priceListId, id, type) => dispatch(createPriceListData(data, priceListId, id, type)),
        getDriverFormatFields: (priceListId) => dispatch(getDriverFormatFields(priceListId)),
        getConfigData: (pagination, limit, appId) => dispatch(getPriceListData(pagination, limit, appId, 'editDriverMaintenance')),
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.pricingData.loading,
        priceListId: state.pricingData.priceTypeNameList,
        maintenanceFields: state.profitOptimizationData.driverFormatFields,
        configData: state.pricingData.priceListData,
        priceListData: state.pricingData.priceListMasterData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPriceListData);

