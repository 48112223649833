import React, { useEffect, useRef, useMemo } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addCustomerMasterXRefUser, getProfile, uploadMigrationManagementData } from '../../redux/actions';
import { DropdownArray, Dropdown, DropdownKeyValue } from "../../components/Inputs";
import RingLoader from 'react-spinners/RingLoader';
import LoadingOverlay from "react-loading-overlay";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import FileUploadIcon from '@mui/icons-material/FileUpload';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: "10px 0px 0px 0px",
        marginTop: 5,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
        width: "60%",
        marginLeft: "1.5rem",
        marginTop: "2rem"
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30,
        width: "60%"
    },
    selectedItem2: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        // marginTop: 9,
    },
    inputTwoLine: {
        // marginTop: 5,
        width: '100%',
        paddingLeft: 10
    },
}));

const UploadMigrationManagement = props => {
    const classes = useStyles();
    const inputFile = useRef(null)
    const contractCustomisationUpdation = useRef();
    const [name, setName] = React.useState('');
    const [sourceSystem, setSourceSystem] = React.useState('');
    const [requester, setRequester] = React.useState('');
    const [referenceDocumentID, setReferenceDocumentID] = React.useState('');
    const [selectedObjects, setSelectedObjects] = React.useState('');
    const selectedObjectsArray = [{ desc: 'Integration Setups', key: 'integration_setups' }, { desc: 'UI Designer', key: 'config_ui_designer' }, { desc: 'Attribute Maps', key: 'config_attribute_maps' }, { desc: 'Key Figure Maps', key: 'config_key_figure_maps' },]

    useEffect(() => {
        props.getProfile();
    }, []);

    useEffect(() => {
        if (props.profileData && Object.keys(props.profileData).length > 0) {
            setRequester(props.profileData.id)
        }
    }, [props.profileData]);

    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.onUpload(data, requester, selectedObjects);
        event.target.value = '';
    }

    function runQuery(item) {
        inputFile.current.click();
    }

    function handleNames(event) {
        setName(event.target.value);
    }
    function handleSourceSystem(event) {
        setSourceSystem(event.target.value);
    }
    function handleReferenceDocumentID(event) {
        setReferenceDocumentID(event.target.value)
    }
    function handleSelectedObjects(event) {
        setSelectedObjects(event);
    }
    function handleRequester(event) {
        setRequester(event.target.value);
    }
    function handleClear() {
        setName('');
        setSourceSystem('');
        setReferenceDocumentID('');
        setSelectedObjects('');
        // setRequester('');
    }

    function handleClearAll() {
        handleClear();
    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#045FB4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "black",
                }),
            }}
            // text="Loading results..."
            className={classes.spinner}
        >
            <div className={classes.root}>
                <div style={{ marginLeft: 20 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link variant='h4' classes={{
                            root: classes.link
                        }}
                            to='/migration-management'
                        > Migration Management
                        </Link>
                        <Typography color="textPrimary" variant='h4'>Upload Migration Management</Typography>
                    </Breadcrumbs>
                </div>
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container>
                            <div style={{
                                padding: "0px  10px 20px 10px", width: "100%"
                            }}>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel className={classes.formLabel} >
                                                Name
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <OutlinedInput
                                                value={name}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleNames} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel className={classes.formLabel} >
                                                Source System
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <OutlinedInput
                                                value={sourceSystem}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleSourceSystem} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel className={classes.formLabel} >
                                                Requester
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <OutlinedInput
                                                disabled
                                                value={requester}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleRequester} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel className={classes.formLabel} >
                                                Reference Document ID
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <OutlinedInput
                                                value={referenceDocumentID}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleReferenceDocumentID} />
                                        </div>
                                    </div>
                                </Grid>
                                {/* <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel className={classes.formLabel}  >
                                                Selected Objects
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <DropdownKeyValue
                                                classes={{ root: classes.fontSetting }}
                                                defaultValue={selectedObjects}
                                                data={
                                                    selectedObjectsArray &&
                                                        selectedObjectsArray
                                                        ? selectedObjectsArray
                                                        : []
                                                }
                                                twoline="true"
                                                onChange={handleSelectedObjects}
                                                error={
                                                    typeof selectedObjects === "boolean" &&
                                                        !selectedObjects
                                                        ? true
                                                        : false
                                                }
                                                // ref={subTypeRef}
                                                labelFormat={'desc'}
                                            />
                                        </div>
                                    </div>
                                </Grid> */}
                            </div>
                        </Grid>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <input type='file'
                        accept=".xlsx, .xls, .csv, .json"
                        id='file' ref={inputFile} style={{ display: 'none' }}
                        onChange={(e) => handleDrop(e)} />
                    <Button variant="contained" color="primary" className={classes.button} startIcon={<FileUploadIcon />} onClick={() => runQuery()}
                    // disabled={selectedObjects
                    //     && requester
                    //     ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            : 'Upload'
                        }
                    </Button>
                </div >
            </div>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        profileData: state.profileData.profileData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onUpload: (data, requester, selectedObjects) => dispatch(uploadMigrationManagementData(data, requester, selectedObjects)),
        getProfile: () => dispatch(getProfile()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadMigrationManagement);