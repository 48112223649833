import React from 'react';
import { ProductGroupUserTable } from './components';

const MaterialMaster = () => {
    return (
        <div>
            <ProductGroupUserTable />
        </div >
    );
};

export default MaterialMaster;