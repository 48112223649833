import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, ChartTheme, AreaSeries,
    Legend, Category, Tooltip, ColumnSeries, ILoadedEventArgs, DataLabel, Highlight
} from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';

const SyncfusionChart = (props) => {
    const [revenueData, setRevenueData] = React.useState([]);
    const [marginData, setMarginData] = React.useState([]);
    const [maxY, setMaxY] = React.useState(200);
    useEffect(() => {
        var revenue = []
        var margin = []
        var rev = 0
        var high = 0
        var mappingName
        if (props.analysisLevel) {
            var mappingName = props.analysisLevel.replace(/_/g, ' ').split(" ")
            for (var i = 0; i < mappingName.length; i++) {
                mappingName[i] = mappingName[i].charAt(0).toUpperCase() + mappingName[i].slice(1);

            }
        }
        if (props.data && props.analysisLevel && !props.loading) {
            if (props.data.margin_contributions && Object.entries(props.data.margin_contributions).length > 0) {
                Object.entries(props.data.margin_contributions).map(([k, v]) => {
                    margin.push({ x: parseInt(k), y: parseInt(v), toolTipMappingName: mappingName.join(" "), color: '#449724' })
                    if (high < v)
                        high = v
                })
            }
            if (props.data.revenue_contributions && Object.entries(props.data.revenue_contributions).length > 0) {
                Object.entries(props.data.revenue_contributions).map(([k, v]) => {
                    revenue.push({ x: parseInt(k), y: parseInt(v), toolTipMappingName: mappingName.join(" "), color: '#F3993E' })
                    if (high < v)
                        high = v
                })
            }
            rev = high % 10 == 0 ? high + 10 : (high + high % 10)
            setMaxY(rev)
            setMarginData(margin)
            setRevenueData(revenue)
        }
    }, [props.data]);
    return (
        <div style={{ padding: 0 }} >
            <ChartComponent id={props.id} style={{ textAlign: "center", textTransForm: 'Capitalize' }} legendSettings={{ enableHighlight: true }}
                primaryXAxis={{
                    labelIntersectAction: Browser.isDevice ? 'None' : 'Trim', labelRotation: Browser.isDevice ? -45 : 0,
                    valueType: 'Double', interval: 10,
                    maximum: 105,
                    majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, edgeLabelPlacement: 'Shift', labelFormat: '{value}%',
                }}
                primaryYAxis={{
                    title: 'Counts',
                    valueType: 'Double',
                    majorTickLines: { width: 0 }, lineStyle: { width: 0 }, maximum: maxY, interval: 10,
                }} chartArea={{ border: { width: 0 } }}
                tooltip={{
                    enable: true,
                    header: "<b>${point.tooltip}</b>", shared: true,
                }}
                width={'100%'}
                height={props.id == 'combined-column' || 'chart-customer' ? '100%' : '80%'}
                title={props.id == 'combined-column' ? 'Revenue-Margin Contributions' : ' '}

            >
                <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
                <SeriesCollectionDirective >
                    <SeriesDirective dataSource={marginData} tooltipMappingName='toolTipMappingName' xName='x' columnSpacing={0.1} yName='y' name='Margin' type='Column' pointColorMapping='color' marker={{ dataLabel: { visible: true, position: 'Outer', font: { color: 'blue' } } }}>
                    </SeriesDirective>
                    <SeriesDirective dataSource={revenueData} tooltipMappingName='toolTipMappingName' xName='x' columnSpacing={0.1} yName='y' name='Revenue' type='Column' pointColorMapping='color' marker={{ dataLabel: { visible: true, position: 'Outer', font: { color: 'blue' } } }}>
                    </SeriesDirective>
                </SeriesCollectionDirective>
            </ChartComponent>
        </div >
    );
}

export default connect(null, null)(SyncfusionChart);