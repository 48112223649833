import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Grid,
    FormLabel, TextField
} from '@mui/material';
//import momentt from 'moment-timezone';
import moment from 'moment';
//import MomentUtils from '@date-io/moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
//momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        margin: 0,
        width: '100%'
    },
    grid: {
        alignSelf: 'center'
    }
}));

const Datepicker = forwardRef((props, ref) => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [changedFlag, setChangedFlag] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorMessage2, setErrorMessage2] = React.useState('');
    const reset = () => {
        setSelectedDate(null)
    };

    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : selectedDate
        };
    });
    function handleDateChange(event) {
        setChangedFlag(1);
        setSelectedDate(event);
        props.onChange(event);
        if (event && event.isValid()) {
            const date1 = new Date(event.toISOString().split('T')[0] + 'T00:00:00.000Z');
            const minDate = props.minimum ? new Date(props.minimum) : null;
            const maxDate = props.maximum ? new Date(props.maximum) : null;
            let errorMessage = '';
            let errorMessage2 = '';

            if (minDate && date1 < minDate) {
                errorMessage = "Valid To can't be before the Valid From";
                setErrorMessage2('');
            } else if (minDate && date1.getTime() === minDate.getTime()) {
                errorMessage = "Valid From and Valid To can't be Same";
                setErrorMessage2('');
            } else if (maxDate && date1 > maxDate) {
                errorMessage2 = "Valid From can't be before the Valid To";
                setErrorMessage('');
            }
            setErrorMessage(errorMessage);
            setErrorMessage2(errorMessage2);
        }
        else {
            setErrorMessage("Invalid date format. Please use mm/dd/yyyy.")
            setErrorMessage2("Invalid date format. Please use mm/dd/yyyy.")
        }

    }

    return (
        <div>
            {props.twoline ?
                <div className={classes.root}>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        className={classes.grid}
                    >
                        {props.heading && <FormLabel {...props} error={false} required={props.mandatory}>
                            {props.heading}
                        </FormLabel>}
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <Grid container justifyContent="space-around">
                                {props.minimum ?
                                    <DatePicker
                                        disableToolbar
                                        clearable
                                        // sx={{ margin: 2, padding: 2 }}
                                        InputProps={{
                                            padding: 0,

                                            disableUnderline: true,
                                            style: {
                                                //padding: '1px 10px 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                height: 36,
                                                width: '100%'
                                            }
                                        }}
                                        value={props.prevalue ? props.prevalue : selectedDate}
                                        onChange={handleDateChange}
                                        renderInput={(props) => <TextField {...props} sx={{ padding: "4px" }} justifyContent='center' helperText={errorMessage} />}
                                        minDate={props.minimum ? props.minimum : ''}
                                        format='MM/DD/YYYY'
                                    />
                                    :
                                    <DatePicker
                                        disableToolbar
                                        clearable
                                        //sx={{ margin: 2, padding: 2 }}
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                // padding: '1px 10px 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                width: '100%'
                                            }
                                        }}
                                        value={props.prevalue ? props.prevalue : selectedDate}
                                        onChange={handleDateChange}
                                        renderInput={(props) =>
                                            <TextField {...props} sx={{ padding: "4px" }} justifyContent='center' helperText={errorMessage2} />}
                                        format='MM/DD/YYYY'
                                    />
                                }
                                {/* {props.minDate} */}
                            </Grid>
                        </LocalizationProvider>

                    </Grid>
                </div>
                :
                <div className={classes.root}>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        className={classes.grid}
                    >
                        {props.heading && <FormLabel {...props} >
                            {props.heading}
                        </FormLabel>}
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container justifyContent="space-around">
                                <DatePicker
                                    disableToolbar
                                    clearable
                                    // sx={{ margin: 2, padding: 2 }}
                                    InputProps={{
                                        padding: 0,
                                        disableUnderline: true,
                                        style: {
                                            //padding: '1px 10px 1px 11px',
                                            alignSelf: 'center',
                                            alignItems: 'center',
                                            border: '1px solid #E0E0E0',
                                            width: '100%'
                                        }
                                    }}
                                    value={selectedDate}
                                    onChange={date => handleDateChange(date)}
                                    renderInput={(props) => <TextField {...props} sx={{ padding: "4px" }} justifyContent='center' helperText={null} />}
                                    minDate={new Date()}
                                    format="MM/dd/yyyy"
                                    inputVariant="outlined"
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </div>
            }
        </div>
    );

});

export default Datepicker;