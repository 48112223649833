import React, { useEffect } from 'react';
import {
    Grid, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getLblDispDesFieldValue
} from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { history } from '../../components/Helpers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const ExternalFormulaResultsViewQuery = props => {
    const classes = useStyles();
    const [externalFormat, setExternalFormat] = React.useState(history.location.format ? history.location.format : []);

    const [externalFields, setExternalFields] = React.useState([]);
    useEffect(() => {
        var data = { "data_source": "external_formula_results" };
        props.loadListControlData(data, 'post');
    }, []);
    useEffect(() => {
        var newArray = []
        if (props.listControlData) {
            Object.values(props.listControlData.field_label_attributes)
                .filter(item => item.sort_details || item.mandatory)
                .sort((a, b) => a.sort_details && a.sort_details > b.sort_details && b.sort_details ? 1 : -1)
                .map((item) => {
                    newArray.push(item)
                })
            setExternalFields(newArray)
        }
    }, [props.listControlData])
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>View Format</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {externalFormat && externalFields ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.fontSetting} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.fontSetting} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {externalFields
                                                        .filter(item => item.key != 'format_name' && item.key != '' && externalFormat[item.key] != 0)
                                                        .sort((a, b) => externalFormat[a.key] > externalFormat[b.key] ? 1 : -1)
                                                        .map((item) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell className={classes.fontSetting} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{item.current}</TableCell>
                                                                    <TableCell className={classes.fontSetting} style={{ width: 70 }}>
                                                                        {externalFormat[item.key]}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        loadListControlData: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        listControlData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalFormulaResultsViewQuery);