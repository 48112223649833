import React, { useEffect } from 'react';
import {
    Card,
    Grid,
    IconButton, FormLabel, Select,
    MenuItem,
    CardHeader,
    Button, Typography, Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { TextInputWithLabel } from '../../../../components/Inputs';
import { getContractDetailsData, getDefaultValuesMaterial, getLblDispDesFieldValue } from '../../../../redux/actions';
import { connect } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { AllOtherAttributeMasterComponent } from "../../../../components/Inputs";
import { sort } from '@syncfusion/ej2-react-charts';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        width: '100%',
        padding: '0px 45px 0px 45px !important'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
    },
    container2: {
        padding: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const GroupingIdentifers = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        props.onLoadingDefault()
    }, [])

    //variable declarations
    const [productHierarchy, setProductHierarchy] = React.useState('');
    const [materialGroup, setMaterialGroup] = React.useState('');
    const [materialGroup1, setMaterialGroup1] = React.useState('');
    const [materialGroup2, setMaterialGroup2] = React.useState('');
    const [materialGroup3, setMaterialGroup3] = React.useState('');
    const [materialGroup4, setMaterialGroup4] = React.useState('');
    const [materialGroup5, setMaterialGroup5] = React.useState('');
    const [materialIdentifierType1, setMaterialIdentifierType1] = React.useState('');
    const [materialIdentifierNumber1, setMaterialIdentifierNumber1] = React.useState('');
    const [materialIdentifierType2, setMaterialIdentifierType2] = React.useState('');
    const [materialIdentifierNumber2, setMaterialIdentifierNumber2] = React.useState('');
    const [idNumber1, setIdNumber1] = React.useState('');
    const [idNumber2, setIdNumber2] = React.useState('');
    const [idNumber3, setIdNumber3] = React.useState('');
    const [idNumber4, setIdNumber4] = React.useState('');
    const [idNumber5, setIdNumber5] = React.useState('');
    const [idNumber6, setIdNumber6] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [materialIDTypeArray, setMaterialIDTypeArray] = React.useState([]);
    const [sortedData, setSortedData] = React.useState([]);
    // const [sortedData, setFieldLabel] = React.useState([]);

    // const MenuProps = {
    //     PaperProps: {
    //         style: {
    //             maxHeight: 250,
    //         }
    //     }
    // }
    // const StyledTableRow = withStyles((theme) => ({
    //     root: {
    //         '&:nth-of-type(odd)': {
    //             backgroundColor: theme.palette.table.row,
    //             height: 46
    //         },
    //         '&:nth-of-type(even)': {
    //             height: 40
    //         }
    //     }
    // }))(TableRow);
    const [identifiersStore, setIdentifiersStore] = React.useState([]);
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    //options
    const [customerIdType, setCustomerIdType] = React.useState('');
    const [customerIdNumber, setCustomerIdNumber] = React.useState('');
    const [finalCustomerIDType, setFinalCustomerIDType] = React.useState([]);
    const [finalCustomerIDNumber, setFinalCustomerIDNumber] = React.useState([]);

    useEffect(() => {
        var data = { "data_source": "material_master" };
        props.getLblDispDesFieldValue(data, 'post');
    }, [])
    useEffect(() => {
        if (props.tableData) {
            setSortedData(props.tableData.field_label_attributes);
        }
    }, [props.tableData])
    function handleCustomerIDType(newValue) {
        if (newValue && newValue.length > 0) {
            setCustomerIdType(newValue);
        } else setCustomerIdType("");
        props.onChange(formData);
    }
    function handleCustomerIDNumber(event) {
        setCustomerIdNumber(event);
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        handleCustomerIDType(item.customer_id_type);
        handleCustomerIDNumber(item.customer_id_number);
    }
    useEffect(() => {
        var tempIDType = [];
        var tempIDNumber = [];
        for (var i = 0; i < identifiersStore.length; i++) {
            tempIDType.push(identifiersStore[i].customer_id_type)
            tempIDNumber.push(identifiersStore[i].customer_id_number)
        }
        setFinalCustomerIDType(tempIDType)
        setFinalCustomerIDNumber(tempIDNumber)
    }, [identifiersStore]);
    function handleSubmit() {
        var data = {
            "customer_id_type": customerIdType,
            "customer_id_number": customerIdNumber,
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...identifiersStore];
            editedArray[editIndex] = data;
            setIdentifiersStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setIdentifiersStore([...identifiersStore, data]);
            props.onChange([...identifiersStore, data])
        }
        setCustomerIdType('');
        setCustomerIdNumber('');
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setCustomerIdType('');
        setCustomerIdNumber('');
    }
    const handleDeleteLocally = (index) => {
        setIdentifiersStore(item => item.filter((item, i) => i !== index));
        props.onChange(identifiersStore.filter((item, i) => i !== index))
    }
    useEffect(() => {
        props.onChange(formData);
    });
    // useEffect(() => {
    //     setFieldLabel(props.data);
    // }, [props.data]);
    // console.log(sortedData)
    // console.log(sortedData)
    var formData = {
        material_id_type: finalCustomerIDType,
        material_id_number: finalCustomerIDNumber,
        product_hierarchy: productHierarchy,
        material_group: materialGroup,
        material_group1: materialGroup1,
        material_group2: materialGroup2,
        material_group3: materialGroup3,
        material_group4: materialGroup4,
        material_group5: materialGroup5,
        material_id_type1: materialIdentifierType1,
        material_id_number1: materialIdentifierNumber1,
        material_id_type2: materialIdentifierType2,
        material_id_number2: materialIdentifierNumber2,
        id_number01: idNumber1,
        id_number02: idNumber2,
        id_number03: idNumber3,
        id_number04: idNumber4,
        id_number05: idNumber5,
        id_number06: idNumber6,
        // flex_attribute3: flexAttribute3,
        // flex_attribute4: flexAttribute4
    }
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'material_id_type' && item.type === 'DROPDOWN.STRING') {
                    setMaterialIDTypeArray(item);
                }

            })
        }

    }, [props.dropdownData]);
    //functions
    function handleProductHierarchy(newValue) {
        setProductHierarchy(newValue);
    }
    function handleMaterialGroup(newValue) {
        setMaterialGroup(newValue);
    }
    function handleMaterialGroup1(newValue) {
        setMaterialGroup1(newValue);
    }
    function handleMaterialGroup2(newValue) {
        setMaterialGroup2(newValue);
    }
    function handleMaterialGroup3(newValue) {
        setMaterialGroup3(newValue);
    }
    function handleMaterialGroup4(newValue) {
        setMaterialGroup4(newValue);
    }
    function handleMaterialGroup5(newValue) {
        setMaterialGroup5(newValue);
    }
    function handleIdNumber1(newValue) {
        setIdNumber1(newValue);
    }
    function handleIdNumber2(newValue) {
        setIdNumber2(newValue);
    }
    function handleIdNumber3(newValue) {
        setIdNumber3(newValue);
    }
    function handleIdNumber4(newValue) {
        setIdNumber4(newValue);
    }
    function handleIdNumber5(newValue) {
        setIdNumber5(newValue);
    }
    function handleIdNumber6(newValue) {
        setIdNumber6(newValue);
    }

    // function handleFlexAttribute3(newValue) {
    //     setFlexAttribute3(newValue);
    // }
    // function handleFlexAttribute4(newValue) {
    //     setFlexAttribute4(newValue);
    // }

    return (
        <div>
            <div
                className={classes.root}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <CardHeader
                            title="GROUPINGS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <Grid container spacing={2}
                            style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['product_hierarchy'] ? sortedData['product_hierarchy']['mandatory'] : false} heading={sortedData['product_hierarchy'] ? sortedData['product_hierarchy']['current'] : 'Product Hierarchy'} twoline='true' onChange={handleProductHierarchy} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['material_group'] ? sortedData['material_group']['mandatory'] : false} heading={sortedData['material_group'] ? sortedData['material_group']['current'] : 'Material Group'} twoline='true' onChange={handleMaterialGroup} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['material_group1'] ? sortedData['material_group1']['mandatory'] : false} heading={sortedData['material_group1'] ? sortedData['material_group1']['current'] : 'Material Group 1'} twoline='true' onChange={handleMaterialGroup1} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['material_group2'] ? sortedData['material_group2']['mandatory'] : false} heading={sortedData['material_group2'] ? sortedData['material_group2']['current'] : 'Material Group 2'} twoline='true' onChange={handleMaterialGroup2} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['material_group3'] ? sortedData['material_group3']['mandatory'] : false} heading={sortedData['material_group3'] ? sortedData['material_group3']['current'] : 'Material Group 3'} twoline='true' onChange={handleMaterialGroup3} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['material_group4'] ? sortedData['material_group4']['mandatory'] : false} heading={sortedData['material_group4'] ? sortedData['material_group4']['current'] : 'Material Group 4'} twoline='true' onChange={handleMaterialGroup4} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['material_group5'] ? sortedData['material_group5']['mandatory'] : false} heading={sortedData['material_group5'] ? sortedData['material_group5']['current'] : 'Material Group 5'} twoline='true' onChange={handleMaterialGroup5} />
                            </Grid>

                            {/* <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={'Flex Attribute 3'} twoline='true' onChange={handleFlexAttribute3} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={'Flex Attribute 4'} twoline='true' onChange={handleFlexAttribute4} />
                            </Grid> */}
                        </Grid>
                    </div>
                </form>
            </div>
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IDENTIFIERS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['id_number01'] ? sortedData['id_number01']['mandatory'] : false} heading={sortedData['id_number01'] ? sortedData['id_number01']['current'] : 'ID Number 1'} twoline='true' onChange={handleIdNumber1} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['id_number02'] ? sortedData['id_number02']['mandatory'] : false} heading={sortedData['id_number02'] ? sortedData['id_number02']['current'] : 'ID Number 2'} twoline='true' onChange={handleIdNumber2} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['id_number03'] ? sortedData['id_number03']['mandatory'] : false} heading={sortedData['id_number03'] ? sortedData['id_number03']['current'] : 'ID Number 3'} twoline='true' onChange={handleIdNumber3} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['id_number04'] ? sortedData['id_number04']['mandatory'] : false} heading={sortedData['id_number04'] ? sortedData['id_number04']['current'] : 'ID Number 4'} twoline='true' onChange={handleIdNumber4} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['id_number05'] ? sortedData['id_number05']['mandatory'] : false} heading={sortedData['id_number05'] ? sortedData['id_number05']['current'] : 'ID Number 5'} twoline='true' onChange={handleIdNumber5} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={sortedData['id_number06'] ? sortedData['id_number06']['mandatory'] : false} heading={sortedData['id_number06'] ? sortedData['id_number06']['current'] : 'ID Number 6'} twoline='true' onChange={handleIdNumber6} />
                                    </Grid>

                                    {/* <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <AllOtherAttributeMasterComponent
                                            classes={{ root: classes.fontSetting }}
                                            attributeName="material_id_type"
                                            value={customerIdType}
                                            onChange={handleCustomerIDType}
                                            prevalue={customerIdType}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "0.8rem" }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                Material ID number
                                            </FormLabel>

                                            <TextField
                                                value={customerIdNumber}
                                                onChange={(e) => handleCustomerIDNumber(e.target.value)}
                                                className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item md={1} classes={{ root: classes.centerButton }}>
                                        <Grid container style={{ paddingTop: 26 }} >
                                            <Grid item md={6} >
                                                {editMode ?
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<EditIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                    :
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                }
                                            </Grid>
                                            <Grid item
                                                md={6}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<DeleteForeverIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.deleteIconContainer,
                                                    }}
                                                    onClick={handleRemove}
                                                >
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </div>
                        </form>

                        {/* <div className={classes.container2}>
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {identifiersStore.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Material ID Type</TableCell>
                                                        <TableCell align='center'>Material ID number</TableCell>
                                                        <TableCell align='center' style={{ minWidth: 100 }}>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {identifiersStore.map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <TableCell align='center'>{item.customer_id_type}</TableCell>
                                                                <TableCell align='center'>{item.customer_id_number}</TableCell>
                                                                <TableCell align='center'>
                                                                    <IconButton classes={{ root: classes.IconButton }} onClick={() => handleEditRow(item, index)}>
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => handleDeleteLocally(index)} classes={{ root: classes.IconButton }}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </div> */}

                    </Card>
                </div >
            </div >
        </div>
    );

};

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    dropdownData: state.customerData.dropdownDataMaterial,
    tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        onLoadingDefault: () => dispatch(getDefaultValuesMaterial()),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(GroupingIdentifers);
