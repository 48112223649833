import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import BarChart from "../../../Analytics/Charts/BarChart";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: "20px 30px 20px 30px",
    borderRadius: 10,
  },
  chartContainer: {
    marginTop: 30,
    height: 250,
  },
  chartHead: {
    width: "100%",
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: theme.typography.h2.fontSize,
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
  },
}));

const ActiveContractsGraph = (props) => {
  const classes = useStyles();
  const [series, setSeries] = React.useState([]);
  const [options, setOptions] = React.useState({});
  const dataSC = {
    labels: props.data ? props.data.x_axis : "",
    datasets: [
      {
        label: "This year",
        data: props.data ? props.data.x_axis_values : [],
        backgroundColor: "#6FDEF6",
        barThickness: 6,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };
  const optionsSC = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    cornerRadius: 30,
  };
  const [paymentDuration, setPaymentDuration] = React.useState({});
  const [k, setK] = React.useState([]);
  const [claimAgingData, setClaimAgingData] = React.useState([]);

  useEffect(() => {
    if (props.paymentDuration && Object.keys(props.paymentDuration)) {
      setPaymentDuration(props.paymentDuration);
      setK(Object.keys(props.paymentDuration));
      var data1 = [];
      var data2 = [];
      props.paymentDuration &&
        Object.entries(props.paymentDuration).map((item, index) => {
          data1.push({ x: item[0], y: item[1]["processed"] });
          data2.push({ x: item[0], y: item[1]["non_processed"] });
        });
      var graphData = [
        {
          data: data1,
          xName: "x",
          yName: "y",
          legend: "Processed",
          type: "Column",
        },
        {
          data: data2,
          xName: "x",
          yName: "y",
          legend: " Non Processed",
          type: "Column",
        },
      ];
      setClaimAgingData(graphData);
    }
  }, [props.paymentDuration]);

  useEffect(() => {
    var data1 = [];
    var data2 = [];
    if (
      props.paymentDuration &&
      Object.keys(props.paymentDuration).length > 0
    ) {
      var convertedArr = Object.entries(props.paymentDuration).map((e) => e[1]);
      convertedArr.map((item) => {
        data1.push(item["processed"]);
        data2.push(item["non_processed"]);
      });
      setSeries([
        {
          name: "Processed",
          data: data1,
        },
        {
          name: "Non Processed",
          data: data2,
        },
      ]);
      setOptions({
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: k,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        fill: {
          opacity: 1,
        },
      });
    }
  }, [k]);

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.chartHead}>
        {" "}
        {sessionStorage.getItem("application") === "Supplier Chargeback" ||
        sessionStorage.getItem("application") === "Customer Chargeback"
          ? "Claim Aging"
          : "Active Contracts"}{" "}
      </Typography>
      <div //className={classes.chartContainer}
      >
        {sessionStorage.getItem("application") === "Supplier Chargeback" ||
        sessionStorage.getItem("application") === "Customer Chargeback" ? (
          <BarChart data={claimAgingData} />
        ) : (
          // series &&
          // series.length > 0 &&
          // options &&
          // Object.keys(options) &&
          // Object.keys(options).length > 0 ? (
          //   <Chart options={options} type="bar" series={series} height={250} />
          // ) : (
          //   <div
          //     style={{
          //       display: "flex",
          //       justifyContent: "center",
          //       alignItems: "center",
          //       height: 300,
          //       fontSize: 13,
          //       color: "grey",
          //     }}
          //   >
          //     No Data to Display !
          //   </div>
          // )
          ''
        )}
      </div>
    </div>
  );
};

ActiveContractsGraph.propTypes = {
  className: PropTypes.string,
};

export default ActiveContractsGraph;
