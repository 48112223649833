import React from 'react';
import { HeaderFieldsContainer } from '../../containers';
import { connect } from 'react-redux';

const GeneralData = React.forwardRef((props, ref) => {
    function handleChange(key, value) {
        props.onChange(key, value);
    }

    return (
        <div>
            <HeaderFieldsContainer required onChange={handleChange} savetriggered={props.savetriggered} ref={ref} 
            contractData={props.data} fieldData={props.fieldData}/>
        </div>
    );

});

export default connect(null, null, null, { forwardRef: true })(GeneralData);