import React, { useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TextField,
  OutlinedInput,
  FormLabel,
  Switch,
  Dialog,
  DialogActions,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import {
  getDefaultValuesAllDataNew,
  getCalculationSimulationDataFields,
  getDynamicQueryFields,
  postDynamicQuery,
  getDynamicQuerySalesDataFields,
  getDefaultValuesAllData,
  getPurchaseDataFields,
  loadValuesBasedOnAppTypeAndField,
  getDynamicQueryFieldsNew,
  getDefaultValues,
  getAllApprovalDesigner,
  allowedApps,
  getTableDeterminationData,
} from "../../redux/actions";
import { connect } from "react-redux";
import { history } from "../../components/Helpers";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { RingLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "0px 5px 0px 5px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    // marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  container: {
    padding: 10,
  },
  gridContainer: {
    // padding: "0px 16px 0px 16px",
    marginTop: '-1rem'
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  inputTwoLine: {
    marginTop: 10,
    width: "100%",
    paddingLeft: 10,
  },
  error: {
    border: "1px solid red !important",
  },
  formLabel: {
    fontSize: theme.typography.h3.fontSize,
    display: "flex",
    alignItems: "flex-end",
  },
  deleteIcon: {
    backgroundColor: "red",
    borderRadius: 0,
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 28,
    paddingTop: 30,
  },
  paper: { minWidth: window.screen.width * 0.5 },
  select: {
    width: "100%",
    marginTop: 10,
  },
  selectedItem: {
    color: theme.palette.text.grey,
  },
  center: {
    textAlign: "center",
  },
  tableStyle: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize,
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize
  },
  customListPadding: {
    "& .MuiList-padding": {
      paddingBottom: "30px", // Adjust the value as needed
    },
  },
}));

const AddDynamicQuery = (props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState("");
  const location = useLocation();

  useEffect(() => {
    // setCurrentPage(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    var [first, second, third] = location.pathname.split("/");
    setCurrentPage(third);
  }, [location]);
  const [disabledItems, setDisabledItems] = React.useState([]);
  const handleInitialSetup = () => {
    var tempObj = {};

    setKeyValuePairObjectAll(apiArrayFieldsConcatinated.sort((a, b) => a.label > b.label ? 1 : -1));
    var dis = [];
    props.formFieldsAllData &&
      props.formFieldsAllData.records.length > 0 &&
      props.formFieldsAllData.records.map((item) => {
        if (item.contract_display === false) {
          dis.push(item.field_id);
        }
      });
    setDisabledItems(dis);
    if (dis.length == 0) {
      setKeyValuePairObject(apiArrayFieldsConcatinated.sort((a, b) => a.label > b.label ? 1 : -1));
    }
    setOpen(false);
  };
  useEffect(() => {
    props.allowedApps();
    props.getTableDeterminationData();
  }, []);

  const [allowedApps, setAllowedApps] = React.useState([]);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps([
        ...props.allowedAppsData,
      ]);
      if (props.allowedAppsData.includes(sessionStorage.getItem('application'))) {
        setApplicationType(sessionStorage.getItem('application'))
        setAppDisabled(true)
      }
    }
  }, [props.allowedAppsData]);
  useEffect(() => {

    if (disabledItems.length > 0) {
      const filtered = keyValuePairObjectAll
        .filter((item, index) => !disabledItems.includes(item.key))
        .reduce((obj, key) => {
          console.log(key)
          obj[key] = keyValuePairObjectAll[key];
          return obj;
        }, {});
      setKeyValuePairObject(filtered);
    }
  }, [disabledItems]);
  const [open, setOpen] = React.useState(false);
  const [queryName, setQueryName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [tableName, setTableName] = React.useState("");
  const [groupedFields, setGroupedFields] = React.useState('');
  const [filterFieldsArray, setFilterFieldsArray] = React.useState([
    ...(location.pathname.substring(location.pathname.lastIndexOf("/") + 1) ===
      "add-collaboration-portal"
      ? ["customer_number", "supplier_number"]
      : []),
  ]);
  const [tableDeterminationArray, setTableDeterminationArray] = React.useState([]);
  const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
  const [averageFieldsArray, setAverageFieldsArray] = React.useState([]);
  const [outputFieldsArray, setOutputFieldsArray] = React.useState({});
  const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
  const [sortOrderArray, setSortOrderArray] = React.useState([]);
  const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] = React.useState([]);
  const [keyValuePairObjectAll, setKeyValuePairObjectAll] = React.useState([]);
  const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
  const [sortCounter, setSortCounter] = React.useState(1);
  const [sortCounterEnabler, setSortCounterEnabler] = React.useState(true);
  const [appDisabled, setAppDisabled] = React.useState(false);
  const [dynamicFields, setDynamicFields] = React.useState([]);

  const handleQueryName = (e) => {
    setQueryName(e.target.value);
  };
  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (
      props.dynamicQueryFieldsData/* &&
      props.dynamicQueryFieldsData.numeric_fields*/
    ) {
      setDynamicFields(props.dynamicQueryFieldsData)
      var concatinated = []
      //  = props.dynamicQueryFieldsData.numeric_fields
      //   .concat(props.dynamicQueryFieldsData.string_fields)
      //   .concat(props.dynamicQueryFieldsData.date_fields);
      if (props.dynamicQueryFieldsData.numeric_fields && props.dynamicQueryFieldsData.numeric_fields.length > 0)
        concatinated.push(...props.dynamicQueryFieldsData.numeric_fields)
      if (props.dynamicQueryFieldsData.string_fields && props.dynamicQueryFieldsData.string_fields.length > 0)
        concatinated.push(...props.dynamicQueryFieldsData.string_fields)
      if (props.dynamicQueryFieldsData.date_fields && props.dynamicQueryFieldsData.date_fields.length > 0)
        concatinated.push(...props.dynamicQueryFieldsData.date_fields)

      setApiArrayFieldsConcatinated(concatinated);
    }
  }, [props.dynamicQueryFieldsData]);

  useEffect(() => {
    if (props.tableDetermination && props.tableDetermination.length > 0) {
      setTableDeterminationArray(props.tableDetermination);
    }
  }, [props.tableDetermination]);
  useEffect(() => {
    if (sessionStorage.getItem('application') === "Sales Commission") {
      if (currentPage === "add-collaboration-portal") {
        setFilterFieldsArray(["employee_number"]);
      }
    }
  }, [currentPage])
  const [editMode, setEditMode] = React.useState(false);
  const [userStore, setUserStore] = React.useState([]);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
        overflowY: 'scroll',
        scrollBehaviour: ' smooth',
        MarginTop: 0,
        menuPlacement: "top"
      }
    }, getContentAnchorEl: null,
  }

  function handleOnSubmit() {
    var unsortedFields = [];
    if (
      outputFieldsArray ||
      (sumFieldsArray.length > 0 &&
        filterFieldsArray.length === 0 &&
        outputFieldsArray.length === 0 &&
        aggregateFieldsArray.length === 0)
    ) {
      for (var propName in outputFieldsArray) {
        unsortedFields.push(propName);
        if (outputFieldsArray[propName] === 0) {
          delete outputFieldsArray[propName];
        }
      }
      var allFields = {
        filter_fields: filterFieldsArray,
        sum_fields: sumFieldsArray,
        sort_fields: outputFieldsArray,
        output_fields: unsortedFields,
        groupby_fields: aggregateFieldsArray,
        format_name: queryName,
        table_name: props.dynamicQueryFieldsData.table_name,
        query_description: description,
        avg_fields: averageFieldsArray,
        query_type:
          currentPage === "add-partner-statement"
            ? 3
            : currentPage === "add-collaboration-portal"
              ? 2
              : 1,
        app_type: applicationType,
        //"data_source_type": sourceDataType,
        //"data_source": functionalityName,
        table_description: tableName,
      };
      if (currentPage === "add-partner-statement") {
        props.onSubmitPartnerStatement(allFields);
      } else if (currentPage === "add-collaboration-portal") {
        props.onSubmitCollaborationPortal(allFields);
      } else {
        props.onSubmit(allFields);
      }
    } else {
      alert("Please select atleast one output or only sum field");
    }
  }
  function handleClearAll() {
    setSortOrderArray([]);
    setEditMode(false);
    setUserStore([]);
    setFilterFieldsArray([]);
    setAggregateFieldsArray([]);
    setAverageFieldsArray([]);
    setOutputFieldsArray([]);
    setSumFieldsArray([]);
  }
  function handleCancelDialog() {
    // history.push('/dynamic-query');
    {
      currentPage === "add-collaboration-portal"
        ? history.push("/collaboration-portal")
        : currentPage === "add-partner-statement"
          ? history.push("/partner-statement")
          : history.push("/dynamic-query");
    }
  }
  const handleChange = (event, item, type) => {
    if (type === "filter_fields") {
      if (filterFieldsArray.includes(item)) {
        setFilterFieldsArray(
          filterFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        setFilterFieldsArray([...filterFieldsArray, item]);
      }
    }
    if (type === "aggregate_fields") {
      if (aggregateFieldsArray.includes(item)) {
        setAggregateFieldsArray(
          aggregateFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        setAggregateFieldsArray([...aggregateFieldsArray, item]);
        if (sortCounterEnabler) {
          setOutputFieldsArray({
            ...outputFieldsArray,
            [item]: sortCounter,
          }); //aggregate and output should be in sync
          setSortCounter((prevCount) => prevCount + 1);
        } else {
          setOutputFieldsArray({ ...outputFieldsArray, [item]: null }); //aggregate and output should be in sync
        }
      }
    }
    if (type === "avg_fields") {
      if (averageFieldsArray.includes(item)) {
        setAverageFieldsArray(
          averageFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        if (sortCounterEnabler) {
          setOutputFieldsArray({
            ...outputFieldsArray,
            [item]: sortCounter,
          });
          setSortCounter((prevCount) => prevCount + 1);
        } else {
          setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
        }
        setAverageFieldsArray([...averageFieldsArray, item]);
      }
    }
    if (type === "sum_fields") {
      if (sumFieldsArray.includes(item)) {
        setSumFieldsArray(sumFieldsArray.filter((item1) => item1 !== item));
      } else {
        if (sortCounterEnabler) {
          setOutputFieldsArray({
            ...outputFieldsArray,
            [item]: sortCounter,
          });

          setSortCounter((prevCount) => prevCount + 1);
        } else {
          setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
        }
        setSumFieldsArray([...sumFieldsArray, item]);
      }
    }

    if (type === "output_fields") {
      if (outputFieldsArray.hasOwnProperty(item)) {
        setSortCounterEnabler(false);
        var tempArray = outputFieldsArray;
        delete tempArray[item];
        setOutputFieldsArray({ ...tempArray });
      } else {
        if (sortCounterEnabler) {
          setOutputFieldsArray({ ...outputFieldsArray, [item]: sortCounter });
        } else {
          setOutputFieldsArray({ ...outputFieldsArray, [item]: sortCounter });
        }
        setSortCounter((prevCount) => prevCount + 1);
      }
    }
    if (type === "sort_order") {
      setSortCounterEnabler(false);
      setSortOrderArray(event.target.value);
      setOutputFieldsArray({
        ...outputFieldsArray,
        [item]: event.target.value ? parseInt(event.target.value) : null,
      });
      event.preventDefault();
    }
  };
  const [functionalityName, setFunctionalityName] = React.useState("");
  const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeArray(props.sourceDataTypeValue["drop_down_value_keys"]);
    }
  }, [props.sourceDataTypeValue]);
  const handleFunctionalityName = (e) => {
    setFunctionalityName(e.target.value);
    props.getDynamicQueryFields(
      applicationType,
      sourceDataType,
      e.target.value,
      tableName
    );
  };

  const [allOutput, setAllOutput] = React.useState(false);
  const handleAllOutput = (event) => {
    var tempObj = {};
    var tempArray = [];
    var i = 1;
    setAllOutput(event.target.checked);
    if (event.target.checked === true) {
      if (keyValuePairObject.length > 0) {
        // Object.entries(keyValuePairObject)
        keyValuePairObject && keyValuePairObject
          .sort()
          .filter((item, index) => item.key != "")
          .map((item, index) => {
            tempArray.push(item.key)
            tempObj[item.key] = i;
            ++i;
          })
      }
      setOutputFieldsArray(tempObj);
    } else {
      setOutputFieldsArray(tempObj);
    }
  };
  const [applicationType, setApplicationType] = React.useState('');
  const [sourceDataType, setSourceDataType] = React.useState("");
  const handleApplicationType = (e) => {
    setApplicationType(e.target.value);
    setSourceDataType("");
    setSourceDataTypeArray([]);
  };

  function handleTableName(e) {
    setTableName(e.target.value);
    var data = tableDeterminationArray && tableDeterminationArray.filter(item => item.table_description == e.target.value).map(e => e.grouping_required)
    if (data && data[0])
      setGroupedFields(data[0])
    props.getDynamicQueryFields(applicationType, e.target.value);
  }
  return (
    <LoadingOverlay
      active={props.loading}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#64C4B4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "#64C4B4",
        }),
      }}
      spinner={<RingLoader size={100} />}
      className={classes.spinner}
    >
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            variant="h4"
            classes={{
              root: classes.link,
            }}
            to={
              currentPage === "add-collaboration-portal"
                ? "/collaboration-portal"
                : currentPage === "add-partner-statement"
                  ? "/partner-statement"
                  : "/dynamic-query"
            }
          >
            {currentPage === "add-partner-statement"
              ? "Partner Statement"
              : currentPage === "add-collaboration-portal"
                ? "On Demand Query"
                : "On Demand Query"}
          </Link>
          <Typography color="textPrimary" variant="h4">
            Add Query
          </Typography>
        </Breadcrumbs>
        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="simple-dialog-title"
          open={open}
          disableEscapeKeyDown
          classes={{ paper: classes.paper }}>
          <div style={{ padding: 20 }}>
            <FormLabel className={classes.formLabel} required>
              Query Name
            </FormLabel>
            <OutlinedInput
              value={queryName}
              classes={{ root: classes.inputTwoLine }}
              onChange={handleQueryName}
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormLabel className={classes.formLabel} required>
              Description
            </FormLabel>
            <OutlinedInput
              value={description}
              // inputProps={{ min: "0", step: "1" }}
              classes={{ root: classes.inputTwoLine }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormLabel className={classes.formLabel} required>
              Application Type
            </FormLabel>
            <Select
              value={applicationType}
              onChange={handleApplicationType}
              className={clsx({
                [classes.select]: true,
              })}
              classes={{
                selectMenu: classes.selectedItem,
              }}
              MenuProps={{ ...MenuProps, autoFocus: true }}
              disabled={appDisabled}
            >
              {allowedApps.map((item) => {
                return (
                  <MenuItem
                    value={item}
                    key={item}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div style={{ padding: 20 }}>
            <FormLabel className={classes.formLabel} required>
              Data Source
            </FormLabel>
            <Select
              value={tableName}
              onChange={handleTableName}
              className={clsx({
                [classes.select]: true,
              })}
              MenuProps={{ ...MenuProps, autoFocus: true }}
              style={{ textTransform: "capitalize" }}
              classes={{
                selectMenu: classes.selectedItem,
              }}
            >
              <MenuItem value="">Select Option</MenuItem>
              {tableDeterminationArray &&
                tableDeterminationArray.length > 0 &&
                tableDeterminationArray
                  .filter(item => (item.application_type && item.application_type.includes(applicationType)) || item.application_type == null)
                  .map((item) => {
                    return (
                      <MenuItem
                        value={item.table_description}
                        key={item.table_decription}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.table_description.replace(/_/g, " ")}
                      </MenuItem>
                    );
                  })}
            </Select>
          </div>



          <DialogActions>
            <Button color="primary" onClick={handleCancelDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleInitialSetup}
              disabled={
                queryName && tableName && applicationType && description
                  ? false
                  : true
              }
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.bodyContainer}>
          <Card>
            <form autoComplete="off" noValidate>
              <div className={classes.container}>
                <Grid container spacing={2}></Grid>
              </div>
            </form>
          </Card>

          <div className={classes.container2}>
            <Grid container>
              <Grid item xs={12} classes={{ root: classes.gridContainer }}>
                {apiArrayFieldsConcatinated &&
                  apiArrayFieldsConcatinated.length > 0 ? (
                  <Paper className={classes.root}>
                    <div style={{ margin: 15 }}>
                      <TableContainer style={{ maxHeight: 550, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                className={classes.tableStyle}
                              >
                                Field Name
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableStyle}
                              >
                                Filter
                              </TableCell>{console.log(groupedFields)}
                              {(/*(applicationType != 'Master Data' && applicationType != 'Transaction Data') ||*/ groupedFields == true) &&
                                <>
                                  <TableCell
                                    align="center"
                                    className={classes.tableStyle}
                                  >
                                    Aggregate
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableStyle}
                                  >
                                    Average
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableStyle}
                                  >
                                    Sum
                                  </TableCell>
                                </>
                              }
                              <TableCell
                                align="center"
                                className={classes.tableStyle}
                              >
                                <FormControlLabel
                                  value="external"
                                  control={
                                    <Checkbox
                                      color="primary"
                                      onChange={handleAllOutput}
                                      checked={allOutput}
                                    />
                                  }
                                  label={
                                    <div style={{ fontSize: 14 }}>Output</div>
                                  }
                                  labelPlacement="right"
                                  style={{ fontSize: 14 }}
                                  classes={{ root: "fontSize: 14px" }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableStyle}
                              >
                                Sort Order
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody classes={{ root: classes.table }}>
                            {//Object.entries(keyValuePairObject)
                              keyValuePairObject && keyValuePairObject.length > 0 && keyValuePairObject
                                .sort()
                                .filter((item, index) => item.key != "" && item.display)
                                .map((item, index) => {
                                  return (
                                    <TableRow key={item.key}>
                                      <TableCell classes={{ root: classes.fontSetting }}
                                        style={{
                                          textAlign: "center",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.label}
                                      </TableCell>
                                      <TableCell classes={{ root: classes.fontSetting }} align="center">
                                        <Switch
                                          checked={filterFieldsArray.includes(item.key)}
                                          color="primary"
                                          onChange={(e) =>
                                            handleChange(e, item.key, "filter_fields")
                                          }
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                          disabled={
                                            currentPage ===
                                              "add-collaboration-portal"
                                              ? applicationType ===
                                                "Sales Commission"
                                                ? item.key === "employee_number"
                                                  ? true
                                                  : false
                                                : item.key === "customer_number" ||
                                                  item.key === "supplier_number"
                                                  ? true
                                                  : false
                                              : false
                                          }
                                        />
                                      </TableCell>
                                      {(/*(applicationType != 'Master Data' && applicationType != 'Transaction Data') ||*/ groupedFields) &&
                                        <>
                                          <TableCell classes={{ root: classes.fontSetting }} align="center">
                                            <Switch
                                              checked={aggregateFieldsArray.includes(
                                                item.key
                                              )}
                                              color="primary"
                                              onChange={(e) =>
                                                handleChange(e, item.key, "aggregate_fields")
                                              }
                                              inputProps={{
                                                "aria-label": "primary checkbox",
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell classes={{ root: classes.fontSetting }} align="center">
                                            <Switch
                                              checked={averageFieldsArray.includes(item.key)}
                                              color="primary"
                                              onChange={(e) =>
                                                handleChange(e, item.key, "avg_fields")
                                              }
                                              inputProps={{
                                                "aria-label": "primary checkbox",
                                              }}
                                              disabled={dynamicFields && dynamicFields.numeric_fields &&
                                                dynamicFields.numeric_fields.findIndex(({ key }) => key == item.key) > -1 ? false : true}
                                            />
                                          </TableCell>
                                          <TableCell classes={{ root: classes.fontSetting }} align="center">
                                            <Switch
                                              checked={sumFieldsArray.includes(item.key)}
                                              color="primary"
                                              onChange={(e) =>
                                                handleChange(e, item.key, "sum_fields")
                                              }
                                              inputProps={{
                                                "aria-label": "primary checkbox",
                                              }}
                                              disabled={dynamicFields && dynamicFields.numeric_fields &&
                                                dynamicFields.numeric_fields.findIndex(({ key }) => key == item.key) > -1 ? false : true}
                                            />
                                          </TableCell>
                                        </>
                                      }
                                      <TableCell classes={{ root: classes.fontSetting }} align="center">
                                        <Switch
                                          checked={outputFieldsArray.hasOwnProperty(
                                            item.key
                                          )}
                                          color="primary"
                                          onChange={(e) =>
                                            handleChange(e, item.key, "output_fields")
                                          }
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                        <TextField
                                          key={["recipient", item.key].join("_")}
                                          type="number"
                                          variant="outlined"
                                          value={
                                            outputFieldsArray[item.key] ||
                                              outputFieldsArray[item.key] === 0
                                              ? outputFieldsArray[item.key]
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleChange(e, item.key, "sort_order")
                                          }
                                          disabled={
                                            outputFieldsArray.hasOwnProperty(item.key)
                                              ? false
                                              : true
                                          }
                                          inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Paper>
                ) : (
                  <Typography variant="h4">
                    There is no data to show now.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
          <div className={classes.buttonRoot}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={handleClearAll}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleOnSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTableDeterminationData: () => dispatch(getTableDeterminationData()),
    getDynamicQuerySalesDataFields: () =>
      dispatch(getDynamicQuerySalesDataFields()),
    getCalculationSimulationDataFields: () =>
      dispatch(getCalculationSimulationDataFields()),
    onSubmit: (data) => dispatch(postDynamicQuery(data, null, 'add-dynamic-query')),
    onSubmitPartnerStatement: (data) =>
      dispatch(postDynamicQuery(data, null, "partner")),
    getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
    getPurchaseDataFields: () => dispatch(getPurchaseDataFields()),
    getDynamicQueryFields: (applicationType, tableName) => dispatch(getDynamicQueryFieldsNew(applicationType, tableName)),
    onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, null, null, "Display Designer")),
    allowedApps: () => dispatch(allowedApps()),
    onSubmitCollaborationPortal: (data) => dispatch(postDynamicQuery(data, "", "collaboration")),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    dropdownData: state.customerData.dropdownData,
    dropdownDataCustomer: state.customerData.dropdownDataCustomer,
    dynamicQueryFieldsData:
      state.operationalReportsData.dynamicQueryFieldsDataNew,
    calculationFieldsData: state.customerData.calculationSimulationData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    queryTypes: state.operationalReportsData.queryTypesSuccess,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    formFieldsAllData:
      state.addMultipleConfigurationData.multipleConfigurationData,
    allowedAppsData: state.initialData.allowedApps,
    tableDetermination: state.operationalReportsData.tableDetermination,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicQuery);
