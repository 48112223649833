import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    TableRow,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { editBom, getDefaultValuesCustomerXRef, getDefaultValuesCustomer, getBomDetails, deleteBom } from '../../redux/actions';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import Moment from 'moment';
import { Link } from 'react-router-dom';

var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        // fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    }
}));

const ViewBom = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [topMaterialNumber,setTopMaterialNumber] = React.useState('');
    const [topMaterialName,setTopMaterialName] = React.useState('');
    const [materialNumber,setMaterialNumber] = React.useState('');
    const [materialName,setMaterialName] = React.useState('');
    const [quantity, setQuantity] = React.useState('');
    const [supplierNumber,setSupplierNumber] = React.useState('');
    const [supplierName,setSupplierName] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [incomingRoyalty, setIncomingRoyalty] = React.useState(false);
    const [outgoingRoyalty, setOutgoingRoyalty] = React.useState(false);
    const [notes, setNotes] = React.useState('');
    const [baseUom,setBaseUom] = React.useState('');
    const [materialCost,setMaterialCost] = React.useState('');
    const [salesBom,setSalesBom] = React.useState('');

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getBomDetails(appId);
    }, []);
    useEffect(() => {
        if (props.bom) {
            setTopMaterialNumber(props.bom.top_material_number);
            setTopMaterialName(props.bom.top_material_name);
            setMaterialNumber(props.bom.material_number);
            setMaterialName(props.bom.material_name);
            setQuantity(props.bom.quantity);
            setSupplierNumber(props.bom.supplier_number);
            setSupplierName(props.bom.supplier_name);
            if(props.bom.start_date!='0001-01-01T00:00:00Z'){
                setStartDate(props.bom.start_date);
            }
            if(props.bom.end_date!='0001-01-01T00:00:00Z'){
                setEndDate(props.bom.end_date);
            }
            setIncomingRoyalty(props.bom.incoming_royalty);
            setOutgoingRoyalty(props.bom.outgoing_royalty);
            setNotes(props.bom.notes);
            setBaseUom(props.bom.base_uom);
            if(props.bom.material_cost != 0)
            setMaterialCost(props.bom.material_cost);
            setSalesBom(props.bom.sales_uom);
        }
        // setUserStore(props.bom)
    }, [props.bom])

    useEffect(() => {
        props.onLoadingDefault();
        props.onLoadingDefaultCustomerMaster();
    }, []);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [userStore, setUserStore] = React.useState([]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/bom'
                >
                    Sales Bundle
                </Link>
                <Typography color="textPrimary" variant='h1'>View Sales Bundle</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container spacing={2} style={{paddingLeft:10}} >
                    { topMaterialNumber &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Top Material Number' data={topMaterialNumber} twoline='true' />
                    </Grid>
                    }
                    { topMaterialName &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Top Material Name' data={topMaterialName} twoline='true' />
                    </Grid>
                    }
                    { materialNumber &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Material Number' data={materialNumber} twoline='true' />
                    </Grid>
                    }
                    { materialName &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Material Name' data={materialName} twoline='true' />
                    </Grid>
                    }
                    { quantity &&
                    <Grid 
                        item 
                        md={3}
                        xs={12}
                    >
                         <LabelText classes={{root:classes.formLabel}} heading='Quantity' data={quantity} twoline='true' />
                    </Grid>
                    }
                    { supplierNumber &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Supplier Number' data={supplierNumber} twoline='true' />
                    </Grid>
                    }
                    { supplierName &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Supplier Name' data={supplierName} twoline='true' />
                    </Grid>
                    }
                    {startDate &&
                    <Grid
                         item
                         md={3}
                        xs={12}
                    >
                         <LabelText classes={{root:classes.formLabel}} heading='Start Date' data={Moment(startDate).local().format('MM/DD/YYYY')} twoline='true' />
                    </Grid>
                    }
                    {endDate &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                        >
                        <LabelText classes={{root:classes.formLabel}} heading='End Date' data={Moment(endDate).local().format('MM/DD/YYYY')} twoline='true' />
                    </Grid>
                    }
                    { incomingRoyalty &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Incoming Royalty' data={incomingRoyalty==true ? 'Yes':'No'} twoline='true' />
                    </Grid>
                    }
                    { outgoingRoyalty &&
                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <LabelText classes={{root:classes.formLabel}} heading='Outgoing Royalty' data={outgoingRoyalty==true ? 'Yes':'No'} twoline='true' />
                    </Grid>
                    }
                    { notes &&
                    <Grid 
                        item 
                        md={3}
                        xs={12}
                    >
                         <LabelText classes={{root:classes.formLabel}} heading='Notes' data={notes} twoline='true' />
                    </Grid>
                    }
                    { baseUom &&
                    <Grid 
                        item 
                        md={3}
                        xs={12}
                    >
                         <LabelText classes={{root:classes.formLabel}} heading='Base Uom' data={baseUom} twoline='true' />
                    </Grid>
                    }
                    { materialCost &&
                    <Grid 
                        item 
                        md={3}
                        xs={12}
                    >
                         <LabelText classes={{root:classes.formLabel}} heading='Material Cost' data={materialCost} twoline='true' />
                    </Grid>
                    }
                    { salesBom &&
                    <Grid 
                        item 
                        md={3}
                        xs={12}
                    >
                         <LabelText classes={{root:classes.formLabel}} heading='Sales BoM' data={salesBom} twoline='true' />
                    </Grid>
                    }
                    </Grid>

                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getBomDetails: (id) => dispatch(getBomDetails(id)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef()),
        onLoadingDefaultCustomerMaster: () => dispatch(getDefaultValuesCustomer()),
    }
}

const mapStateToProps = state => {
    return {
        bom: state.customerData.bomDetailsData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewBom);