import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import "../BSNavbar.css";
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const CustomerRebate = (props) => {

  const handleClick = (pge) => {
    if (pge === "/reporting-on-roll") {
      history.push({
        pathname: "/reporting-on-roll",
        state: "deleteData",
      });
    }
    else {
      history.push(pge);
    }
  };


  const application = sessionStorage.getItem("application");

  const hasRight = (right) => {
    const user = secureLocalStorage.getItem("dYtz");
    return (
      user.role.rights &&
      user.role.rights.includes(`${right}-${application}`)
    );
  };

  const hasBatchJobRight = hasRight("get_batch_job");
  const hasApprovalRight = hasRight("approve_accrual_posting");
  const hasPaymentApprovalRight = hasRight("approve_payment_posting");
  const hasBatchJobStatusRight = hasRight("get_batch_job_status");
  const hasExceptionRight = hasRight("get_exception");
  const hasAnalyticsRight = hasRight("get_analytics");
  const hasExceptionApprovalRights = hasRight("approve_exception");
  const hasFormulaRight = hasRight("get_formula");
  const hasContractApprovalRight = hasRight("approve_contract");
  const hasContractSetupRight = hasRight("get_contracts");
  const hasAccrualPostingRight = hasRight("run_accrual_post");
  const hasPaymentPostingRight = hasRight("run_payment_post");
  const hasDisplayReportingRight = hasRight("get_report");
  const hasQuotaManagementRight = hasRight("get_quota");
  const hasContractLogRight = hasRight("get_contract_change_log");
  const hasExpiringContractRight = hasRight("get_expiring_contract");
  const hasExpiredContractRight = hasRight("get_expired_contract");
  const hasDocumentManagemetRight = hasRight("get_document_management");
  const hasPartenerStatementRight = hasRight("get_partner_statement");
  const hasExternalFormulaRight = hasRight("get_external_formula");
  const hasCalculationAnalysisRight = hasRight("run_calculation_analysis");
  const hasCalculationSimRight = hasRight("run_calc_sim");
  const hasRebateEstimatorRight = hasRight("get_rebate_estimator");
  const hasOperationalReportsRight = hasRight("get_operational_reports");
  const hasOndemandAnalyticsRight = hasRight("get_on_demand_analytics");

  const hasPredefinedRight = hasRight("get_predefined_query");
  const hasProcessRight = hasRight("get_process_reporting");
  const hasOnDemandVideosRight = hasRight("get_on_demand_videos");
  const hasDocumentation = hasRight("display_documentation");
  const hasFaq = hasRight("display_FAQs");
  const hasLogDefect = hasRight("display_log_defects");
  const hasPostingMaintenance = hasRight("display_posting_maintenance");
  const hasDailySimulation = hasRight("display_daily_simulation");
  const hasExternalAccrualPostingRight = hasRight("get_external_accrual_posting");
  const hasExternalPaymentPostingRight = hasRight("get_external_payment_posting");
  const hasCustomerRebateCalculationRight = hasRight("get_compare_rebate_calculation");

  const commonMenuItems = [
    { Dashboard: "/dashboard" },
  ];

  const customerRebateMenuItems = [
    {
      Contracts: [
        ...(hasContractSetupRight ? [{ "Contract Setup": "/contract-setup" }] : []),
        ...([{ "Contract Approval": "/approval-reject" }]),
        ...(hasContractLogRight ? [{ "Contract Log": '/contract-change-reports' }] : []),
        ...(hasExpiringContractRight ? [{ "Expiring Contracts": "/expiring-contracts" }] : []),
        ...(hasExpiredContractRight ? [{ "Expired Contracts": "/expired-contracts" }] : []),
        ...(hasDocumentManagemetRight ? [{ "Document Management": "/documents" }] : []),
      ],
    },
    {
      "Calculation Management": [
        ...(hasFormulaRight ? [{ "Formula Lab": "/formula-lab" }] : []),
        ...(hasCalculationSimRight ? [{ "Calculation Simulation": "/calculation-simulation" }] : []),
        ...(hasCalculationAnalysisRight ? [{ "Calculation Analysis": "/contract-trends" }] : []),
        ...(hasExceptionRight ? [{ 'Exception Management': '/exception-management' }] : []),
        ...(hasExceptionApprovalRights ? [{ "Exception Approval": "/exception-approval" }] : []),
        ...(hasQuotaManagementRight ? [{ "Quota Management": "/quota-management" }] : []),
        ...(hasExternalFormulaRight ? [{ "External Formula": "/external-formula-results" }] : []),
        ...(hasRebateEstimatorRight ? [{ "Rebate Estimator": "/next-tier-simulation" }] : []),
      ],
    },
    {
      Accruals: [
        ...(hasAccrualPostingRight ? [{ "Accrual Postings": "/financial-postings" }] : []),
        ...(hasApprovalRight ? [{ 'Accrual Approvals': '/accrual-approvals' }] : []),
        ...(hasExternalAccrualPostingRight ? [{ "External Accrual Posting": "/external-accural-postings" }] : []),
      ],
    },
    {
      Payments: [
        ...(hasPaymentPostingRight ? [{ "Payment Postings": "/payment-postings" }] : []),
        ...(hasPaymentApprovalRight ? [{ 'Payment Approvals': '/payment-approvals' }] : []),
        ...(hasExternalPaymentPostingRight ? [{ "External Payment Posting": "/external-payment-postings" }] : []),
        ...(hasPartenerStatementRight ? [{ "Partner Statement": "/partner-statement" }] : []),
      ],
    },
    {
      "Reporting & Analytics": [
        ...(hasOperationalReportsRight ? [{ "Operational Reports": "/select-operational-reports" }] : []),
        ...(hasDisplayReportingRight ? [{ "On Demand Queries": "/dynamic-query" }] : []),
        ...(hasOndemandAnalyticsRight ? [{ "On Demand Analytics": "/on-demand-analytics" }] : []),
        ...(hasPredefinedRight ? [{ "Predefined Queries": "/predefined-query" }] : []),
        ...(hasAnalyticsRight ? [{ 'Analytics & Dashboard': '/analytics-main' }] : []),
        ...(hasProcessRight ? [{ "Process Reporting": "/reporting-on-roll" }] : []),
      ],
    },
    {
      Utilities: [
        ...(hasBatchJobRight ? [{ 'Batch Job Setup': '/batchjob-setup' }] : []),
        ...(hasBatchJobStatusRight ? [{ 'Batch Job Status': '/batchjob-status' }] : []),
        // ...(hasOnDemandVideosRight ? [{ "On Demand Videos": "/help-desk-cp" }] : []),
        ...(hasCustomerRebateCalculationRight ? [{ "Compare Rebate Calculation": "/compare-rebate-calculation" }] : []),
        ...(hasPostingMaintenance ? [{ "Postings Maintenance": "/postings-maintenance" }] : []),
        // ...([{ "Postings Maintenance": "/postings-maintenance" }]),
        // ...([{ "Daily Simulation": "/daily-simulation" }]),
        ...(hasDailySimulation ? [{ "Daily Simulation": "/daily-simulations" }] : []),
        // ...([{ "Daily Simulation": "/daily-simulations" }]),
      ],
    },
    {
      "⍰ Help & Support": [
        // ...([{ "Documentation": "/documentation" }]),
        ...(hasDocumentation ? [{ "Documentation": "/documentation" }] : []),
        ...(hasOnDemandVideosRight ? [{ "On Demand Videos": "/help-desk-cp" }] : []),
        ...(hasFaq ? [{ "FAQs": "/faqs" }] : []),
        ...(hasLogDefect ? [{ "Log Defect": "/log-defect" }] : []),
        // ...([{ "FAQs": "/faqs" }]),
        // ...([{ "Log Defect": "/log-defect" }]),
      ],
    },
  ];

  const supplierRebateMenuItems = [
    {
      Contracts: [
        { "Contract Setup": "/contract-setup" },
        { "Contract Approval": "/approval-reject" },
        { "Contract Log": '/contract-change-reports' },
        { "Expiring Contracts": "/expiring-contracts" },
        { "Expired Contracts": "/expired-contracts" },
        { "Document Management": "/documents" },

      ],
    },
    {
      "Calculation Management": [
        { "Formula Lab": "/formula-lab" },
        { "Calculation Simulation": "/calculation-simulation" },
        { "Calculation Analysis": "/contract-trends" },
        { "Exception Management": "/exception-management" },
        { "Exception Approval": "/exception-approval" },
        { "Quota Management": "/quota-management" },
        { "External Formula": "/external-formula-results" },
        { "Rebate Estimator": "/next-tier-simulation" },
      ],
    },
    {
      Accruals: [
        { "Accrual Postings": "/financial-postings" },
        { "Accrual Approvals": "/accrual-approvals" },
        { "External Accrual Posting": "/external-accural-postings" },
      ],
    },
    {
      Payments: [
        { "Payment Postings": "/payment-postings" },
        { "Payment Approvals": "/payment-approvals" },
        { "External Payment Posting": "/external-payment-postings" },
        { "Partner Statement": "/partner-statement" },
      ],
    },
    {
      "Reporting & Analytics": [
        { "Operational Reports": "/select-operational-reports" },
        { "On Demand Queries": "/dynamic-query" },
        { "On Demand Analytics": "/on-demand-analytics" },
        { "Predefined Queries": "/predefined-query" },
        { "Analytics & Dashboard": "/analytics-main" },
        { "Process Reporting": "/reporting-on-roll" },
      ],
    },
    {
      "Utilities": [
        { "Batch Job Setup": "/batchjob-setup" },
        { "Batch Job Status": "/batchjob-status" },
        { "On Demand Videos": "/help-desk-cp" },
        { "Compare Rebate Calculation": "/compare-rebate-calculation" },
      ],
    },
  ];
  const menuItems = [
    ...commonMenuItems,
    ...(application === "Customer Rebate" ? customerRebateMenuItems : []),
    ...(application === "Supplier Rebate" ? supplierRebateMenuItems : []),
  ];

  return (
    <Nav
      className="me-auto my-2 my-lg-0"
      style={{
        maxHeight: "100px",
        display: "flex",
        justifyContent: "space-between",
        margin: "auto",
      }}
      navbarScroll
    >
      {menuItems.map((item, i) => {
        if (typeof Object.values(item)[0] === "string")
          return (
            <Nav.Link
              onClick={() => {
                handleClick(Object.values(item)[0]);
              }}
              style={{
                borderRight:
                  i !== menuItems.length - 1 ? "1px solid lightgrey" : "none ",
              }}
            >
              {Object.keys(item)[0]}
            </Nav.Link>
          );
        else {
          return (
            <NavDropdown
              title={Object.keys(item)[0]}
              style={{
                borderRight:
                  i !== menuItems.length - 1 ? "1px solid lightgrey" : "none ",
              }}
            >
              {Object.values(item)[0].map((e) => {
                return (
                  <NavDropdown.Item
                    onClick={() => {
                      handleClick(Object.values(e)[0]);
                    }}
                  >
                    {Object.keys(e)[0]}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          );
        }
      })}
    </Nav>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.profileData,
    helpDeskData: state.HelpDeskData.HelpDeskData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRebate);
