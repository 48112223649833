import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";

const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [
    { 'Dashboard': '/dashboard' },

    {
        'Source Data': [
            { 'Build Analysis Drivers': '/build-analysis-data' },
            { 'Maintain Analysis Drivers': '/driver-maintenance' },
            { 'Upload External Data': '/external-data-excel-upload' },
            { 'Forecast Source - Upload': '/historical-sales-upload' },
            { 'Forecast Source - Build ': '/build-forecast-data' }
        ]
    },




    {
        'AI Models': [
            { 'Anomaly Run': '/anomaly-run' },
            { 'Sales Forecast - Run': '/forecasting-sales' },
            { 'Sales Forecast - Upload': '/sales-forecast-upload' }
        ]
    },
    {
        'Margin Analysis': [
            { 'Margin Simulation': '/margin-analysis' },
            { 'Margin Policy Exceptions': '/margin-policy-exceptions' },
            { 'Median Margin': '/median-margin' },
            { 'Revenue Margin Analysis': '/revenue-margin-analysis' },
            { 'Gross to Net': '/gross-to-net' },
            { 'Gross to Net Trend': { pathname: '/margin-trending', data: 'No' } },
            { 'Revenue - Profit Trending': '/promotion-sales-analysis' },
            { 'Regional Analysis': '/regional-analysis' },
            { 'Margin by Attribute': '/margin-by-attribute' }
        ]
    },
    ,
    {
        'Margin Forecast': [
            { 'Profitablity Forecasting': '#' },
            { 'Customer Rebate Forecast': '/customer-rebate-forecast' },
            { 'Promotion Plan': '/promotion-trends' },]
    },
    {
        'Reporting & Analytics': [
            { 'Sales Forecast': '/display-forecast' },
            { 'Anomaly': '#' },
            { 'Margin Forecast': '#' },
            { 'Stock Chart': '/stock-chart' },
            { 'Market Share': '/market-share' },
            { 'Time to Market': '/time-to-market' },
            { "Predefined Analytics": "/analytics-main" },

        ]
    },
    {
        'Utilities': [
            { 'Batch Job Setup': '/profitopt-batchjob-setup' },
            { 'Batch Job Status': '/pricing-batchjob-status' },
            { "On Demand Videos": "/help-desk-cp" },
        ]
    },
    {
        'Configuration': [
            { 'Attributes': '/attributes-config' },
            { 'Drivers': '/drivers' },
            { 'Analysis Level': '/analysis-level' },
            { 'Analysis Formula': '/analysis-formula' },
            { 'Build Analysis Drivers': '/external-config' },
            { 'AI Model - Anomaly': '/anomaly-config' },
            { 'AI Model - Sales Forecast': '/forecasting-config' },
        ]
    },
]

const ProfitOptimization = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', fontSize: '16px', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >{Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ', zIndex: 999 }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfitOptimization);