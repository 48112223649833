import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    FormLabel,
    MenuItem,
    Select,
    OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { addHelpDesk, allowedApps } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginLeft: 6

    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
        padding: '6px'
    },
}));

const AddHelpDeskCP = props => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const classes = useStyles();
    const [applicationType, setApplicationType] = React.useState('');
    // const [allowedApps, setAllowedApps] = React.useState([]);
    const allowedApps = [sessionStorage.getItem("application")];
    const [businessProcess, setBusinessProcess] = React.useState('');
    const [topic, setTopic] = React.useState('');
    const [videoLink, setVideoLink] = React.useState('');
    useEffect(() => {
        props.allowedApps();
    }, [])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            // setAllowedApps(props.allowedAppsData);
            // setApplicationType(props.allowedAppsData[0])
            setApplicationType(sessionStorage.getItem("application"))
        }
    }, [props.allowedAppsData])

    // console.log(sessionStorage.getItem("application"))

    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
    }
    function handleOnSubmit() {
        var data = {
            "application_type": applicationType,
            "business_process": businessProcess,
            "topic": topic,
            "link": videoLink.replace("share", "embed"),
        };
        props.onSubmit(data);
    }
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>Add On Demand Video</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formDiv} required>
                                        Application Type
                                    </FormLabel>
                                    <div className={classes.selectRoot} style={{ marginTop: '0px' }}>
                                        <Select
                                            value={applicationType}
                                            onChange={handleApplicationtype}
                                            displayEmpty
                                            style={{ textTransform: 'capitalize' }}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {allowedApps.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Business Process
                                        </FormLabel>
                                        <OutlinedInput
                                            value={businessProcess}
                                            onChange={(e) => setBusinessProcess(e.target.value)}
                                            style={{ marginLeft: 6, paddingLeft: 4 }}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Topic
                                        </FormLabel>
                                        <OutlinedInput
                                            value={topic}
                                            onChange={(e) => setTopic(e.target.value)}
                                            style={{ marginLeft: 6, paddingLeft: 4 }}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Video Link
                                        </FormLabel>
                                        <OutlinedInput
                                            value={videoLink}
                                            onChange={(e) => setVideoLink(e.target.value)}
                                            style={{ marginLeft: 6, paddingLeft: 4 }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={topic && applicationType && videoLink && businessProcess ? false : true
                        }
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        allowedAppsData: state.initialData.allowedApps,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addHelpDesk(data, null, null, 'cp')),
        allowedApps: () => dispatch(allowedApps()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddHelpDeskCP);