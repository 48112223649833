import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import {
    Typography,
    useMediaQuery,
} from '@mui/material';
import Moment from 'moment';
import MaterialTable from 'material-table';
import LoadingOverlay from 'react-loading-overlay';
import { tableIcons } from '../../components/Icons/TableIcons';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
}));
const MasterDataDashboardDetails = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [dataRows, setDataRows] = React.useState([]);
    const [identifierHead, setIdentifierHead] = React.useState('')
    const [showFiled, setShowField] = React.useState('');
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        var tempRows = [];
        if (props.masterDataOperation && props.masterDataOperation.records.length > 0)
            props.masterDataOperation.records
                .map(e => {
                    setIdentifierHead(e.app_functionality === 'customer_master' ? 'Customer Number' :
                        e.app_functionality === 'material_master' ? 'Material Number' :
                            e.app_functionality === 'supplier_master' ? 'Supplier Number' : ''
                    )
                    setShowField(e.fields_updated)
                    tempRows.push({
                        id: e.ID,
                        CreatedAt: e.CreatedAt,
                        app_functionality: e.app_functionality,
                        user_email: e.user_email,
                        fields_updated: e.fields_updated == null ? '' : e.fields_updated,
                        identifier: e.identifier,
                    });
                })
        setDataRows(tempRows);
    }, [props.masterDataOperation]);
    const columns = [
        {
            field: 'identifier',
            title: identifierHead,
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.identifier}</a>
        },
        showFiled == null ? {} : {
            field: 'fields_updated',
            title: 'Fields Updated(Previous,New)',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Object.entries(rowData.fields_updated).map(([key, value]) => {
                return <div> {key} = {value.toString().split(',').join(' -> ')} </div>
            })}</a>
        },
        {
            field: 'CreatedAt',
            title: 'Created At',
            type: 'string',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.CreatedAt).local().format('MM/DD/YYYY HH:mm A')}</a>

        },
        {
            field: 'user_email',
            title: 'User Email',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.user_email}</a>
        },
        {
            field: 'app_functionality',
            title: 'App Functionality',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} > {rowData.app_functionality.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} </a>
        },
    ];
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <LoadingOverlay
                    active={props.loading}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '50px',
                            '& svg circle': {
                                stroke: '#045FB4'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(52, 52, 52, 0)'
                        }),
                        content: (base) => ({
                            ...base,
                            color: 'black'
                        })
                    }}
                    text='Loading contracts. Please wait ...'
                    className={classes.spinner}
                >
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Typography variant="h2" color='primary'> Master Data Changes History</Typography>
                    </div>
                    {props.masterDataOperation && props.masterDataOperation.records.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns.filter(value => Object.keys(value).length !== 0)}
                            data={dataRows}
                            options={{
                                maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 15,
                                pageSizeOptions: [15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        /> :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </LoadingOverlay>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        masterDataOperation: state.dataSetupData.masterDataOperation,
    }
};
export default connect(mapStateToProps, null)(MasterDataDashboardDetails);