import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    TextField,
    Button,
    Grid,
    Container,
    Card,
    CardContent,
    MenuItem,
    useMediaQuery,
} from '@mui/material';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 10,
        marginTop: 16,
        padding: theme.spacing(3),
    },
    title: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    formWrapper: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        borderRadius: '8px',
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    loggedDefects: {
        marginTop: theme.spacing(3),
    },
    card: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));

const LogDefect = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [defect, setDefect] = useState({
        title: '',
        steps: '',
        expected: '',
        actual: '',
        severity: '',
        priority: '',
        environment: '',
    });
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const [defectsList, setDefectsList] = useState([]);

    const handleChange = (e) => {
        setDefect({ ...defect, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setDefectsList([...defectsList, defect]);
        setDefect({
            title: '',
            steps: '',
            expected: '',
            actual: '',
            severity: '',
            priority: '',
            environment: '',
        });
    };

    return (
        <div className={clsx(classes.root)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' className={classes.largeTitle} style={{ marginTop: -5, marginLeft: 16 }}>Log a Defect</Typography>
            </div>
            <div className={classes.formWrapper}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Title"
                                name="title"
                                value={defect.title}
                                onChange={handleChange}
                                required
                                className={classes.formField}
                                style={{ padding : 10 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Steps to Reproduce"
                                name="steps"
                                multiline
                                rows={4}
                                value={defect.steps}
                                onChange={handleChange}
                                required
                                className={classes.formField}
                                style={{ padding : 10 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Expected Result"
                                name="expected"
                                value={defect.expected}
                                onChange={handleChange}
                                required
                                className={classes.formField}
                                style={{ padding : 10 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Actual Result"
                                name="actual"
                                value={defect.actual}
                                onChange={handleChange}
                                required
                                className={classes.formField}
                                style={{ padding : 10 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                label="Severity"
                                name="severity"
                                value={defect.severity}
                                onChange={handleChange}
                                required
                                className={classes.formField}
                                style={{ padding : 10 }}
                            >
                                <MenuItem value="Low">Low</MenuItem>
                                <MenuItem value="Medium">Medium</MenuItem>
                                <MenuItem value="High">High</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                label="Priority"
                                name="priority"
                                value={defect.priority}
                                onChange={handleChange}
                                required
                                className={classes.formField}
                                style={{ padding : 10 }}
                            >
                                <MenuItem value="P1">P1</MenuItem>
                                <MenuItem value="P2">P2</MenuItem>
                                <MenuItem value="P3">P3</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Environment"
                                name="environment"
                                value={defect.environment}
                                onChange={handleChange}
                                className={classes.formField}
                                style={{ padding : 10 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Log Defect
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>

            <div className={classes.loggedDefects}>
                <Typography variant="h5">
                    Logged Defects
                </Typography>
                {defectsList.map((def, index) => (
                    <Card key={index} className={classes.card}>
                        <CardContent>
                            <Typography variant="subtitle1"><strong>Title:</strong> {def.title}</Typography>
                            <Typography variant="body2"><strong>Severity:</strong> {def.severity}</Typography>
                            <Typography variant="body2"><strong>Priority:</strong> {def.priority}</Typography>
                            <Typography variant="body2"><strong>Environment:</strong> {def.environment}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LogDefect);
