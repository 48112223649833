import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { updateCompetitorDataFormatData, getCompetitorDataFormatFields, getLblDispDesFieldValue, getCompetitorDataFormatDataById } from "../../../redux/actions";
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const ListPriceDataUploadEditQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [companyId, setCompanyId] = React.useState('');
    const [netValue, setNetValue] = React.useState('');
    const [billingDate, setBillingDate] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('competitor-data-query') ? JSON.parse(sessionStorage.getItem('competitor-data-query')) : []);
    const [queryObject, setQueryObject] = React.useState({});
    const [defaultValue, setDefaultValue] = React.useState({});
    const [allowedField, setAllowedField] = React.useState(false);
    const [sortedData, setSortedData] = React.useState([]);
    const [requiredFields, setRequiredFields] = React.useState([]);
    useEffect(() => {
        setOpen(true);
        props.getCompetitorDataFormatFields();
        props.getCompetitorDataFormatDataById(purchaseQueryDataStorage.id)
        var data = { "data_source": "Pricing Calculation" }
        props.getLblDispDesFieldValue(data, 'post');
    }, []);
    useEffect(() => {
        if (props.configData && props.configData.format) {
            setQueryName(props.configData.format.format_name)
            setQueryObject(props.configData.format)
            if (props.configData.default_values != null) {
                setDefaultValue(props.configData.default_values)
                setCompanyId(props.configData.default_values.company_id)
                setBillingDate(props.configData.default_values.billing_date)
                setNetValue(props.configData.default_values.net_value)
                setMaterialNumber(props.configData.default_values.material_number)
            }
            if (props.configData.format.company_id !== 0)
                setCompanyId(props.configData.format.company_id)
            if (props.configData.format.billing_date !== 0)
                setBillingDate(props.configData.format.billing_date)
            if (props.configData.format.net_value !== 0)
                setNetValue(props.configData.format.net_value)
            if (props.configData.format.material_number !== 0)
                setMaterialNumber(props.configData.format.material_number)
        }
    }, [props.configData]);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes).map(item => {
                if (item.mandatory) {
                    newArray.push(item.current)
                }
            })
            setRequiredFields(newArray)
        }
    }, [props.tableData])
    useEffect(() => {
        var newArray = []
        if (sortedData && Object.values(sortedData).length > 0) {
            Object.values(sortedData).map(item => {
                if (requiredFields.includes(item.current)) {
                    if (queryObject.hasOwnProperty(item.key) || defaultValue.hasOwnProperty(item.key)) {
                        newArray.push(true)
                    }
                }
                if (newArray.length === requiredFields.length) {
                    setAllowedField(true)
                }
            })

        }
    }, [queryObject, defaultValue])
    useEffect(() => {
        var tempObj = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes).map(e => {
                if (e.sort_details) {
                    tempObj.push(e)
                }
            })
            setSortedData(tempObj.sort((a, b) => a.sort_details > b.sort_details ? 1 : -1))
        }

    }, [props.queryListData, props.tableData])
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleOnSubmit() {
        delete queryObject['id'];
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, { ...queryObject, 'format_name': queryName, 'default_values': defaultValue });
    }
    function handleClearAll() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
        setDefaultValue({ ...defaultValue });
        setCompanyId('')
        setNetValue('')
        setBillingDate('')
        setMaterialNumber('')
    }
    function handleCancelDialog() {
        history.push('/list-price/list-price-excel-upload');
    }
    const handleChange = (event, item) => {
        setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        if (item === 'company_id')
            setCompanyId(event.target.value)
        if (item === "billing_date")
            setBillingDate(event.target.value)
        if (item === "net_value")
            setNetValue(event.target.value)
        if (item === "material_number")
            setMaterialNumber(event.target.value)
    };
    const handleChange2 = (event, item) => {
        setDefaultValue({ ...defaultValue, [item]: event.target.value });
        if (item === 'company_id')
            setCompanyId(event.target.value)
        if (item === "billing_date")
            setBillingDate(event.target.value)
        if (item === "net_value")
            setNetValue(event.target.value)
        if (item === "material_number")
            setMaterialNumber(event.target.value)
    };
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, { ...queryObject, 'format_name': queryName, 'default_values': defaultValue });
    }
    function handleClearAll() {
        setCompanyId('')
        setNetValue('')
        setBillingDate('')
        setMaterialNumber('')
    }
    function handleCancelDialog() {
        history.push('/list-price/list-price-excel-upload');
    }
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>Edit Format</Typography>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {sortedData && Object.values(sortedData).length > 0 ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.values(sortedData)
                                                        // .filter(item => item != 'format_name'&& item != 'default_values' && item != 'organization_id' && item!= 'created_by_id' && item !='updated_by_id' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        {requiredFields.includes(item.current) ? <p>{item.current}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.current}
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item.key] ? queryObject[item.key] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item.key)}
                                                                            defaultValue={JSON.parse(sessionStorage.getItem('competitor-data-query'))[item] ? JSON.parse(sessionStorage.getItem('competitor-data-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={defaultValue[item.key] ? defaultValue[item.key] : ''}
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange2(e, item.key)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        //   disabled={!billingDate || !materialNumber || !companyId ||!netValue? true:false}
                        disabled={!allowedField}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        queryListData: state.dataSetupData.competitorDataFormatFieldData,
        configData: state.dataSetupData.competitorDataSingleFormatData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateCompetitorDataFormatData(id, data, 'List Price')),
        getCompetitorDataFormatFields: () => dispatch(getCompetitorDataFormatFields('List Price')),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getCompetitorDataFormatDataById: (id) => dispatch(getCompetitorDataFormatDataById(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListPriceDataUploadEditQuery);