import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField, Button,
} from "@mui/material";
import { connect } from 'react-redux';
import BulletChart from '../../Charts/BulletChart';
import Moment from 'moment';
import { geographicalIncentive, getNewCustomers, loadAccrualVsPaymentGraph } from '../../../../redux/actions';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white, //secondary.main,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    transform: 'scale(0.635)',
    transformOrigin: 'top left',
    width: "155%",
    marginTop: "-13px",
    // padding: "20px 0px 90px 0px",
    // height: "20rem"
    height: "253px"
  },
  NewDiv:{
    backgroundColor: theme.palette.white,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    height: "253px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 13,
    marginTop: "-15px",
    color: "grey",
    width: "98.5%",
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: '1px solid #EEEEEE',
    borderRadius: 6,
    marginBottom: 5,
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    width: "131.5%",
    // marginTop: 25,
    boxShadow: "rgba(50, 50, 93, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.15) 0px 2px 4px -2px",
    height: "70px",
    padding: "7px 5px 5px 5px"
  },
  formControlStyle: {
    // border: "1px solid #8F00FF",
  },
  noBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "1px solid",
        borderColor: theme.palette.border.main,
      },
    },
  },
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: "1px solid",
      borderColor: theme.palette.border.main,
    },
  },
}));
const NewCustomer = props => {
  const classes = useStyles();
  const [customerGraphData, setCustomerGraphData] = React.useState([]);
  const [maxData, setMaxData] = React.useState({});
  const [type, setType] = React.useState("Customer Master");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const masterDataArray = ["Customer Master", "Employee Master", "Material Master", 'Supplier Master'];
  const [showLoaderNew, setShowLoaderNew] = React.useState(true);


  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "New Master Data Incentives" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData]);

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      // setStartDate(allowedTilesFilter[0].start_date);
      // setEndDate(allowedTilesFilter[0].end_date);
      setType(allowedTilesFilter[0].master_data_type);
    }
  }, [allowedTilesFilter]);


  useEffect(() => {
    if (startDate && endDate) {
      var data = {
        'start_date': Moment(startDate).format('MM/DD/YYYY'),
        'end_date': Moment(endDate).format('MM/DD/YYYY'),
        'master_data_type': type
      }
      setShowLoaderNew(true);
      props.getNewCust(data);
    }

  }, [allowedTilesFilter]);



  useEffect(() => {
    setShowLoaderNew(false);
    if (props.newCustomer && Object.values(props.newCustomer).length > 0) {
      setCustomerGraphData(props.newCustomer)
      var max = 100
      var maxValue = {
        'countMax': props.newCustomer.Count ? parseInt(props.newCustomer.Count) + 100 : 100,
        'salesMax': props.newCustomer.Sales ? (parseInt(props.newCustomer.Sales) + 100) : 100,
        'accrualMax': props.newCustomer.Accrual ? (parseInt(props.newCustomer.Accrual) + 100) : 100,
        'paymentMax': props.newCustomer.Payment ? (parseInt(props.newCustomer.Payment) + 100) : 100,
      }
      setMaxData(maxValue)
    }
  }, [props.newCustomer]);

  const handleType = ((e) => {
    setType(e.target.value);
    // if (startDate && endDate) {
    //   var data = {
    //     'start_date': Moment(startDate).format('MM/DD/YYYY'),
    //     'end_date': Moment(endDate).format('MM/DD/YYYY'),
    //     'master_data_type': e.target.value,
    //   }
    //   setShowLoaderNew(true);
    //   props.getNewCust(data);
    // }
  });

  const isAllZero = (data) => {
    return Object.values(data).every(value => value === 0);
  }

  function handleSubmit() {
    if (startDate && endDate) {
      var data = {
        'start_date': Moment(startDate).format('MM/DD/YYYY'),
        'end_date': Moment(endDate).format('MM/DD/YYYY'),
        'master_data_type': type,
      }
      setShowLoaderNew(true);
      props.getNewCust(data);
    }
  }

  function handleStartDateNew(event) {
    if (event && event.isValid()) {
      setStartDate(event);
      // var data = {
      //   'start_date': Moment(event).format('MM/DD/YYYY'),
      //   'end_date': Moment(endDate).format('MM/DD/YYYY'),
      //   'master_data_type': type,
      // }
      // setShowLoaderNew(true);
      // props.getNewCust(data);
    }
    else
      setStartDate(event);
  }
  function handleEndDateNew(event) {
    if (event && event.isValid()) {
      setEndDate(event);
      // var data = {
      //   'start_date': Moment(startDate).format('MM/DD/YYYY'),
      //   'end_date': Moment(event).format('MM/DD/YYYY'),
      //   'master_data_type': type,
      // }
      // setShowLoaderNew(true);
      // props.getNewCust(data);
    }
    else
      setEndDate(event);
  }

  return (
    <LoadingOverlay
      active={showLoaderNew}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div style={{ height: "270px" }}>
        <div className={classes.bodyContainer} style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, maxWidth: 200 }} size="small" className={classes.formControlStyle}>
            <InputLabel id="posting-type" color="primary" >
              Type
            </InputLabel>
            <Select
              className={classes.selectBorder}
              labelId="posting-type"
              id="posting-type-select"
              value={type}
              label="Posting Type"
              onChange={handleType}
              style={{ border: 'none' }}
              color="primary"
            // width="100%"
            >
              {masterDataArray && masterDataArray.map((item, index) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="start_date"
                id="start_date-select"
                label="Start Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.3rem',
                    border: 'none'
                  }
                }}
                value={startDate}
                onChange={date => handleStartDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="end_date"
                id="end_date-select"
                label="End Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.3rem',
                    border: 'none'
                  }
                }}
                value={endDate}
                onChange={date => handleEndDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
                minDate={startDate ? startDate : ''}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 80 }} size="small" className={classes.formControlStyle}>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "35px", marginRight: 3 }}
              onClick={handleSubmit}
              twoline="true"
              disabled={startDate && endDate && type ? false : true}
            >
              <DirectionsRunIcon />
            </Button>
          </FormControl>
        </div>
        {customerGraphData && Object.values(customerGraphData).length > 0
          && !isAllZero(customerGraphData)
          ?
          <div className={classes.root}>
            <BulletChart bulletChartData1={customerGraphData.Count}
              bulletChartData2={customerGraphData.Sales}
              bulletChartData3={customerGraphData.Accrual}
              bulletChartData4={customerGraphData.Payment}
              maxData={maxData}
              chart={"small"}
            />
          </div>
          :
          <div className={classes.NewDiv}> No Data to Display !</div>
        }
      </div>
    </LoadingOverlay>
  );
};
const mapStateToProps = state => {
  return {
    loading: state.initialData.loading,
    newCustomer: state.initialData.newCustomerData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getNewCust: (data) => dispatch(getNewCustomers(data)),
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType) => dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);