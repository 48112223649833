import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput, FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    loadCustomerMasterFormatFields, postCustomerMasterQuery, getCustomerMasterQuery
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const PurchaseInvoiceEditQuery = props => {
    const classes = useStyles();

    const handleInitialSetup = () => {
        // setQueryObject({ ...queryObject, 'format_name': queryName.trim() });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');

    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('customer-master-query') ? JSON.parse(sessionStorage.getItem('customer-master-query')) : []);
    const [supplierName, setSupplierName] = React.useState('');
    const [supplierType, setSupplierType] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [formatType, setFormatType] = React.useState(sessionStorage.getItem('customer-master-type') ? JSON.parse(sessionStorage.getItem('customer-master-type')) : [])
    const [headOffice, setHeadOffice] = React.useState('');
    const [purchaseOrg, setPurchaseOrg,] = React.useState('');
    const [alternatePayee, setAlternatePayee] = React.useState('');
    const [companyCode, setCompanyCode] = React.useState('');
    const [defaultValue, setDefaultValue] = React.useState({});
    const requiredFields = [
        'supplier_name', 'supplier_type', 'street', 'city', 'country', 'state', 'postal_code',
        'supplier_number', 'head_office', 'purchase_org', 'alternate_payee', 'company_code'
    ]
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        props.loadSalesDataFormatFields(formatType);
        props.getCustomerMasterQuery(purchaseQueryDataStorage.id, formatType);
    }, [formatType]);
    const handleDefaultValue = (e) => {
        setDefaultValue(e.target.value);
    }
    useEffect(() => {
        if (props.customerMasterSingleData) {
            setQueryObject(purchaseQueryDataStorage)
            if (props.customerMasterSingleData.default_values !== null) {
                setDefaultValue(props.customerMasterSingleData.default_values)

                setSupplierNumber(props.customerMasterSingleData?.default_values?.supplier_number)
                setSupplierName(props.customerMasterSingleData?.default_values?.supplier_name)
                setSupplierType(props.customerMasterSingleData?.default_values?.supplier_type)
                setStreet(props.customerMasterSingleData?.default_values?.street)
                setCity(props.customerMasterSingleData?.default_values?.city)
                setCountry(props.customerMasterSingleData?.default_values?.country)
                setState(props.customerMasterSingleData?.default_values?.region)
                setPostalCode(props.customerMasterSingleData?.default_values?.postal_code)
                setHeadOffice(props.customerMasterSingleData?.default_values?.head_office)
                setPurchaseOrg(props.customerMasterSingleData?.default_values?.purchase_org)
                setAlternatePayee(props.customerMasterSingleData?.default_values?.alternate_payee)
                setCompanyCode(props.customerMasterSingleData?.default_values?.company_code)
            }

            setQueryName(purchaseQueryDataStorage.format_name)
            setSupplierNumber(purchaseQueryDataStorage.supplier_number)
            setSupplierName(purchaseQueryDataStorage.supplier_name)
            setSupplierType(purchaseQueryDataStorage.supplier_type)
            setStreet(purchaseQueryDataStorage.street)
            setCity(purchaseQueryDataStorage.city)
            setCountry(purchaseQueryDataStorage.country)
            setState(purchaseQueryDataStorage.region)
            setPostalCode(purchaseQueryDataStorage.postal_code)
            setHeadOffice(purchaseQueryDataStorage.head_office)
            setPurchaseOrg(purchaseQueryDataStorage.purchase_org)
            setAlternatePayee(purchaseQueryDataStorage.alternate_payee)
            setCompanyCode(purchaseQueryDataStorage.company_code)
        }
    }, [props.customerMasterSingleData, purchaseQueryDataStorage]);

    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setDefaultValue({ ...defaultValue });
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === '' || queryObject[propName] === null || queryObject[propName] === undefined) {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit({ ...queryObject, 'format_name': queryName, 'default_values': defaultValue }, purchaseQueryDataStorage.id, formatType);

    }
    function handleClearAll() {
        handleClear();
        setSupplierNumber('')
        setSupplierName('')
        setSupplierType('')
        setCity('')
        setStreet('')
        setPostalCode('')
        setCountry('')
        setState('')
        setHeadOffice('')
        setPurchaseOrg('')
        setAlternatePayee('')
        setCompanyCode('')
    }
    function handleCancelDialog() {
        history.push('/supplier-master/supplier-master-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'supplier_number') {
            setSupplierNumber(event.target.value)
        }
        if (item === 'supplier_name') {
            setSupplierName(event.target.value)
        }
        if (item === 'supplier_type') {
            setSupplierType(event.target.value)
        }
        if (item === 'city') {
            setCity(event.target.value)
        }
        if (item === 'street') {
            setStreet(event.target.value)
        }
        if (item === 'postal_code') {
            setPostalCode(event.target.value)
        }
        if (item === 'country') {
            setCountry(event.target.value)
        }
        if (item === 'region') {
            setState(event.target.value)
        }
        if (item === 'head_office') {
            setHeadOffice(event.target.value)
        }
        if (item === 'purchase_org') {
            setPurchaseOrg(event.target.value)
        }
        if (item === 'alternate_payee') {
            setAlternatePayee(event.target.value)
        }
        if (item === 'company_code') {
            setCompanyCode(event.target.value)
        }
    };
    const handleChange2 = (event, item) => {
        const fieldMappings = {
            'supplier_number': setSupplierNumber,
            'supplier_name': setSupplierName,
            'supplier_type': setSupplierType,
            'city': setCity,
            'street': setStreet,
            'postal_code': setPostalCode,
            'country': setCountry,
            'region': setState,
            'head_office': setHeadOffice,
            'purchase_org': setPurchaseOrg,
            'alternate_payee': setAlternatePayee,
            'company_code': setCompanyCode,
        };

        setDefaultValue({ ...defaultValue, [item]: event.target.value });

        const setField = fieldMappings[item];
        if (setField) {
            setField(event.target.value);

            if (event.target.value.length === 0) {
                setField(" ");
            }
        }
    };

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/supplier-master/supplier-master-excel-upload'
                >
                    Supplier Master Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.salesInvoiceQuery ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.salesInvoiceQuery
                                                        .filter(item => item != 'id' && item != 'format_name' && item != 'organization_id' && item != '')
                                                        ?.map((item) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        {requiredFields.includes(item) ? <p>{item.replace(/_/g, ' ')}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject?.[item] || ''}
                                                                            // value={queryObject[item]? queryObject[item]:0}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                            defaultValue={JSON.parse(sessionStorage.getItem('customer-master-query'))[item] ? JSON.parse(sessionStorage.getItem('customer-master-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70, paddingLeft: 10 }}>
                                                                        <TextField
                                                                            value={defaultValue?.[item] || ''}
                                                                            type="string"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange2(e, item)}
                                                                        />
                                                                    </TableCell>

                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={formatType === 'Supplier Master Organization Data' ? ((supplierNumber || defaultValue?.['supplier_number']) && (alternatePayee || defaultValue?.['alternate_payee']) && (headOffice || defaultValue?.['head_office']) && (companyCode || defaultValue?.['company_code']) && (purchaseOrg || defaultValue?.['purchase_org']) ? false : true) : (supplierName || defaultValue?.['supplier_name']) && (supplierNumber || defaultValue?.['supplier_number']) && (supplierType || defaultValue?.['supplier_type']) && (street || defaultValue?.['street']) && (state || defaultValue?.['region']) && (city || defaultValue?.['city'])
                            && (postalCode || defaultValue?.['postal_code']) && (country || defaultValue?.['country']) ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id, formatType) => dispatch(postCustomerMasterQuery(data, id, 'put', 'SupplierMaster', formatType)),
        loadSalesDataFormatFields: (formatType) => dispatch(loadCustomerMasterFormatFields('SupplierMaster', formatType)),
        getCustomerMasterQuery: (id, formatType) => dispatch(getCustomerMasterQuery(id, formatType)),
    }
}

const mapStateToProps = state => {
    return {
        salesInvoiceQuery: state.dataSetupData.customerMasterQueryFieldsData,
        customerMasterSingleData: state.dataSetupData.customerMasterSingleData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceEditQuery);