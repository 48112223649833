import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    FormLabel,
    TextField,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem, ListItemText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { DropdownArray, Dropdown, ChipInput } from '../../../components/Inputs';
import SimpleDialog from '../../../components/Dialog';
import { runPromotionPosting } from '../../../redux/actions';
import { connect } from 'react-redux';
import RotateLoader from "react-spinners/RotateLoader";
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader'
import { getBudgetingConfig, getPromotionSetup } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        //borderRadius: 3,
        // marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        padding: '15px 0px 18px'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    autocompleteInputRoot: {
        "&.MuiAutocomplete-inputRoot": {
            padding: "4.5px !important", // Adjust as needed
        },
    },
    autocompleteInput: {
        padding: "6px",
    },
}));

const CostPostingForm = props => {
    const classes = useStyles();
    const { className } = props;
    const [selected, setSelected] = React.useState([]);
    const [promoStart, setPromoStart] = useState(null);
    const [promoEnd, setPromoEnd] = useState(null);

    useEffect(() => {
        props.getConfigData();
        props.getPromoData(1, 0)
    }, []);
    useEffect(() => {
        setPromoStart(Moment.utc().add(1, 'months').startOf('month'));
        setPromoEnd(Moment.utc().add(1, 'months').endOf('month'));
        setPostingDate(Moment.utc().add(1, 'months').startOf('month'));
    }, []);

    useEffect(() => {
        if (props.postingError) {
            setErrorList(props.postingError);
            if (Object.entries(props.postingError).length > 0 && props.postingError.error_list != null && listOpen)
                setDialog(true)
        }
    }, [props.postingError]);
    function handleError() {
        setDialog(false)
        setListOpen(false)
    }
    const [promotionType, setPromotionType] = React.useState([]);
    const [promotionTheme, setPromotionTheme] = React.useState([]);
    const [promoThemeArray, setPromoThemeArray] = React.useState([]);
    const [promoTypeArray, setPromoTypeArray] = React.useState([]);
    const [costingArray, setcostingArray] = React.useState([]);
    const [costingNumber, setCostingNumber] = React.useState('');
    const [postingDate, setPostingDate] = React.useState(null);
    const [tags, setTags] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [open, setOpen] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [listOpen, setListOpen] = useState(false);

    useEffect(() => {
        var tempRows = [];
        if (props.promoData && props.promoData.total_record > 0 && props.promoData.records)
            props.promoData.records
                .map(e => {
                    tempRows.push(e.costing_number)
                })

        setcostingArray(tempRows);
    }, [props.promoData]);
    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setPromoThemeArray(props.configData[0].promotion_theme)
            setPromoTypeArray(props.configData[0].promotion_type)
        }
    }, [props.configData]);
    const handleDialog = (bool) => {
        if (bool) {
            var sd = Moment.utc(promoStart).toISOString().split('T')[0].concat('T00:00:00.000Z');
            var ed = Moment.utc(promoEnd).toISOString().split('T')[0].concat('T23:59:59.000Z');
            var pd = Moment.utc(postingDate).toISOString().split('T')[0].concat('T00:00:00.000Z');

            const formData = {
                "application_type": sessionStorage.getItem('application'),
                "promotion_theme": promotionTheme,
                "promotion_type": promotionType,
                "costing_number": costingNumber,
                "promotion_start_date": sd,
                "promotion_end_date": ed,
                "posting_date": pd,
                "tags": tags,
                "is_posting": true,
            };
            setOpen(false);
            props.onSubmit(formData);
            setListOpen(true)
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setCostingNumber([])
        setSelected([])
        setPromotionTheme('')
        setPromotionType('')
        setPromoStart(null)
        setPromoEnd(null)
        setPostingDate(null)
        setTags([])
    }
    const handleClickOpen = () => {
        if (costingNumber.length > 0) {
            if (promoEnd && promoStart) {
                setOpen(true)
            } else {
                if (!promoEnd)
                    setPromoEnd(false);
                if (!promoStart)
                    setPromoStart(false);
            }
        }
        else if (promoEnd && promoStart)
            setOpen(true);
        else {
            if (!promoEnd)
                setPromoEnd(false);
            if (!promoStart)
                setPromoStart(false);
        }
    };
    function handleTagsAdd(newValue) {
        // setTags([...tags, newValue]);
        setTags(newValue)
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }
    function handlePromoStart(newValue) {
        if (newValue && newValue.isValid()) {
            setPromoStart(Moment(newValue).format('MM/DD/YYYY'));
            setPromoEnd(Moment(newValue).endOf('month').format('MM/DD/YYYY'))
        }
        else {
            setPromoStart(newValue)
            if (!newValue)
                setPromoEnd(null)
        }
    }
    function handlePromoEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setPromoEnd(Moment(newValue).format('MM/DD/YYYY'));
        }
        else {
            setPromoEnd(newValue)
        }
    }
    function handleCostingNumber(e, value) {
        let newSelected = value;
        if (value.length > 0) {
            setSelected(newSelected)
            setCostingNumber(newSelected.map(Number));
        }
        else {
            setSelected([])
            setCostingNumber([]);
        }
    }
    function handlePromoType(newValue) {
        setPromotionType(newValue);
    }
    function handlePromoTheme(newValue) {
        setPromotionTheme(newValue);
    }
    function handlePostingDate(newValue) {
        if (newValue && newValue.isValid()) {
            setPostingDate(Moment(newValue).format('MM/DD/YYYY'));
        }
        else {
            setPostingDate(newValue);
        }
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary'>
                        Costing Postings
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >

                                <Dropdown heading={'Application Type'} classes={{ root: classes.fontSetting }} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >

                                <DropdownArray heading={'Promotion Theme'} style={{ marginBottom: "12px" }} classes={{ root: classes.fontSetting }} data={promoThemeArray} placeholder={promoThemeArray && promoThemeArray.length > 0 ? 'Select Promotion Theme' : '- Please configure Promotion Theme -'} twoline='true' onChange={handlePromoTheme} />

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: "-24px" }}
                            >

                                <DropdownArray heading={'Promotion Type'} classes={{ root: classes.fontSetting }} data={promoTypeArray} placeholder={promoTypeArray && promoTypeArray.length > 0 ? 'Select Promotion Type' : '- Please configure Promotion Type -'} twoline='true' onChange={handlePromoType} prevalue={promotionType}
                                    style={{ paddingTop: 5 }} />

                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: "-20px" }}
                            >
                                <div className={classes.contractContiner}>
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: "11px" }} required className={classes.chargeBackLabel}> Costing Number </FormLabel>
                                    <Autocomplete
                                        multiple
                                        limitTags={3}
                                        size="small"
                                        id="checkboxes-tags-demo"
                                        options={costingArray.map(String)}
                                        defaultValue={selected}
                                        value={selected}
                                        renderInput={params => (

                                            <TextField {...params} variant="outlined" size="small" style={{ borderRadius: '0px !important' }} />

                                        )}
                                        // classes={{
                                        //     inputRoot: { borderRadius: '0px !important' }
                                        // }}
                                        classes={{
                                            inputRoot: classes.autocompleteInputRoot,
                                        }}
                                        InputProps={{
                                            classes: { input: classes.autocompleteInput },
                                            disableUnderline: true
                                        }}
                                        disableCloseOnSelect
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected} />
                                                {option}

                                            </li>
                                        )}
                                        style={{ width: "100%", marginTop: -2 }}
                                        onChange={(event, newValue) => { handleCostingNumber(event, newValue) }}
                                    />
                                </div>
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingBottom: 15 }}

                            >
                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                    Promotion Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    height: 37,
                                                    //marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={promoStart}
                                            onChange={handlePromoStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingBottom: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                    Promotion End Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    height: 37,
                                                    //marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={promoEnd}
                                            onChange={handlePromoEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            minDate={promoStart ? promoStart : ''}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingBottom: 20 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel} >
                                    Posting Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    height: 37,
                                                    //marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={postingDate}
                                            onChange={handlePostingDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingBottom: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                    Tags
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    onAdd={(chips) => handleTagsAdd(chips)}
                                    onDelete={(chip) => handleTagsDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <ChipInput data={tags} onChange={handleTagsAdd} />
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                disabled={costingNumber.length == 0 || !promoStart || !promoEnd || !postingDate ? true : false}
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to post the costing results?' handleDialog={handleDialog} />
                <Dialog
                    open={dialog}
                    classes={{ paper: classes.paperContainer }}
                >
                    <DialogTitle >Error Summary</DialogTitle>
                    <DialogContent classes={{
                        root: { padding: '43px 37px 0px 37px !important' }
                    }}>

                        <List>
                            {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                                return (<ListItem>
                                    <ErrorOutlineIcon style={{ color: '#FF1A1A', marginRight: 5 }} />
                                    <ListItemText style={{ color: '#FF1A1A' }}
                                        primary={item}
                                    />
                                </ListItem>
                                )
                            })}
                        </List>

                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        </LoadingOverlay>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runPromotionPosting(formData)),
        getConfigData: () => dispatch(getBudgetingConfig()),
        getPromoData: (pagination, limit) => dispatch(getPromotionSetup(pagination, limit)),
    }
}
const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
        promoData: state.promotionData.promotionSetupData,
        postingError: state.promotionData.postingFailedList,
        loading: state.promotionData.loading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CostPostingForm);
