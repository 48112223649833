import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
    IconButton, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { getMembershipData, getAttributeListData, getCustomerMasterData } from '../../redux/actions';
import { connect } from 'react-redux';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextInputWithLabel } from '../../components/Inputs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 35
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));
const ViewMembershipByFilter = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [membershipOwnerId, setMembershipOwnerId] = React.useState('');
    const [membershipOwnerName, setMembershipOwnerName] = React.useState('');
    const [membershipOwnerIdType, setMembershipOwnerIdType] = React.useState('');
    const [membershipOwnerIdNumber, setMembershipOwnerIdNumber] = React.useState('');
    const [classOfTrade, setClassOfTrade] = React.useState('');
    const [classOfTradeArray, setClassOfTradeArray] = React.useState([]);
    const [memberID, setMemberID] = React.useState([]);
    const [memberIDType, setMemberIDType] = React.useState([]);
    const [memberNumber, setMemberNumber] = React.useState('');
    const [memberName, setMemberName] = React.useState('');
    const [membershipStartDate, setMembershipStartDate] = React.useState(null);
    const [membershipEndDate, setMembershipEndDate] = React.useState(null);
    const [country, setCountry] = React.useState('');
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [action, setAction] = React.useState('');
    const [actionDescription, setActionDescription] = React.useState('');
    const [parentMemberID, setParentMemberID] = React.useState('');
    const [topParentMemberID, setTopParentMemberID] = React.useState('');
    const [secondaryClassofTrade, setSecondaryClassofTrade] = React.useState('');
    const [organizationStatus, setOrganizationStatus] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [memberStatus, setMemberStatus] = React.useState('');
    const [addMember, setAddMember] = React.useState(1);
    const [relationshipToTopParent, setRelationshipToTopParent] = React.useState('');
    const [actionEffectiveData, setActionEffectiveData] = React.useState(null);
    const [customerNumberArray, setCustomerNumberArray] = React.useState([]);
    const [imaClassOfTrade, setImaClassOfTrade] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        setOpen(true);
        props.getCustomerMasterData()
        props.getAttributeListData({
            attribute_name: ''
        })
    }, []);
    useEffect(() => {
        var newArray = []
        if (props.attributeData && props.attributeData.length > 0) {
            props.attributeData
                .filter(item => item.attribute_name === 'class_of_trade')
                .map((item) => {
                    newArray.push(item.attribute_value)
                })
        }
        setClassOfTradeArray(newArray)
    }, [props.attributeData]);
    useEffect(() => {
        var newArray = []
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            props.customerMasterData.records.map((item) => {
                newArray.push(item.customer_name + '-' + item.customer_number)
            })
        }
        if (newArray.length != 0) {
            setCustomerNumberArray(newArray)
        }
    }, [props.customerMasterData]);
    function handleMemberIDType(newValue, index) {
        memberIDType[index] = newValue
    }
    function handleMemberID(newValue, index) {
        memberID[index] = newValue;
    }
    const handleMembershipOwnerName = (e) => {
        setMembershipOwnerName(e.target.value);
    }
    const handleMembershipOwnerId = (e) => {
        setMembershipOwnerId(e.target.value);
    }
    const handleMembershipOwnerIdType = (e) => {
        setMembershipOwnerIdType(e.target.value);
    }
    const handleMembershipOwnerIdNumber = (e) => {
        setMembershipOwnerIdNumber(e.target.value);
    }
    const handleClassOfTrade = (value) => {
        setClassOfTrade(value);
    }
    const handleMemberNumber = (e) => {
        setMemberNumber(e.target.value);
    }
    const handleMemberName = (e) => {
        setMemberName(e.target.value);
    }
    const handleCustomerNumber = (value) => {
        setCustomerNumber(value);
    }
    const handleCountry = (e) => {
        setCountry(e.target.value);
    }
    const handleStreet = (e) => {
        setStreet(e.target.value);
    }
    const handleCity = (e) => {
        setCity(e.target.value);
    }
    const handleState = (e) => {
        setState(e.target.value);
    }
    const handlePostalCode = (e) => {
        setPostalCode(e.target.value);
    }
    const handleParentMemberID = (e) => {
        setParentMemberID(e.target.value);
    }
    const handleTopParentMemberID = (e) => {
        setTopParentMemberID(e.target.value);
    }
    const handleMemberStatus = (value) => {
        setMemberStatus(value);
    }
    const handleAction = (value) => {
        setAction(value);
    }
    const handleActionDescription = (e) => {
        setActionDescription(e.target.value);
    }
    const handleSecondaryClassofTrade = (value) => {
        setSecondaryClassofTrade(value);
    }
    const handleOrganizationStatus = (value) => {
        setOrganizationStatus(value);
    }
    const handleRelationshipToTopParent = (value) => {
        setRelationshipToTopParent(value);
    }
    const handleActionEffectiveData = (value) => {
        setActionEffectiveData(value);
    }
    const handleMembershipStartDate = (value) => {
        setMembershipStartDate(value)
    }
    const handleMembershipEndDate = (value) => {
        setMembershipEndDate(value)
    }
    const handleIMAClassOfTrade = (e) => {
        setImaClassOfTrade(e.target.value);
    }
    function handleOnAdd() {

        memberIDType[addMember] = ''
        memberID[addMember] = ''
        setAddMember(addMember + 1)
    }
    function handleSubmit() {
        var data = {
            "membership_owner_id": membershipOwnerId,
            "membership_owner_name": membershipOwnerName,
            "membership_owner_id_type": membershipOwnerIdType,
            "membership_owner_id_number": membershipOwnerIdNumber,
            "class_of_trade": classOfTrade,
            "membership_start_date": membershipStartDate,
            "membership_end_date": membershipEndDate,
            "member_id_type": memberIDType,
            "member_id_number": memberID,
            "member_number": memberNumber,
            "member_name": memberName,
            "action": action,
            "action_description": actionDescription,
            "parent_member_id": parentMemberID,
            "top_parent_member_id": topParentMemberID,
            "secondary_class_of_trade": secondaryClassofTrade,
            "country": country,
            "state": state,
            "city": city,
            "street": street,
            "postal_code": postalCode,
            "organization_status": organizationStatus,
            "customer_number": customerNumber.split('-').pop(),
            "member_status": memberStatus,
            "relationship_to_top_parent": relationshipToTopParent,
            "action_effective_date": actionEffectiveData,
            "ima_class_of_trade": imaClassOfTrade,
        };
        props.onSubmit(1, 0, "displaybyFilter", data);
        handleClear();
    }
    function handleClear() {
        for (var i = 0; i < memberIDType.length; i++) {
            memberIDType[i] = null
        }
        setMemberNumber('');
        setMemberName('');
        setMembershipStartDate(null);
        setMembershipEndDate(null);
        setEditMode(false);
        setMembershipOwnerId('');
        setMembershipOwnerName('');
        setMembershipOwnerIdType('')
        setMembershipOwnerIdNumber('')
        setClassOfTrade('');
        setCustomerNumber('')
        setStreet('')
        setCity('')
        setState('')
        setPostalCode('')
        setCountry('')
        setMemberStatus('')
        setAction('')
        setActionDescription('')
        setParentMemberID('')
        setTopParentMemberID('')
        setOrganizationStatus('')
        setSecondaryClassofTrade('')
        setRelationshipToTopParent('')
        setActionEffectiveData(null);
        setImaClassOfTrade('');
    }
    function handleClearAll() {
        handleClear();
        setEditMode(false);
    }
    return (
        <div className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2} style={{ padding: '16px 25px 0px 25px' }}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Membership Owner ID
                                    </FormLabel>
                                    <OutlinedInput
                                        value={membershipOwnerId}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleMembershipOwnerId} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Membership Owner Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={membershipOwnerName}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleMembershipOwnerName} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Membership Owner ID Type
                                    </FormLabel>
                                    <OutlinedInput
                                        value={membershipOwnerIdType}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleMembershipOwnerIdType} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Membership Owner ID Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={membershipOwnerIdNumber}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleMembershipOwnerIdNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Member Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={memberNumber}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleMemberNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Member Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={memberName}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleMemberName} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Class of Trade
                                    </FormLabel>
                                    <Select
                                        value={classOfTrade}
                                        onChange={(e) => handleClassOfTrade(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {classOfTradeArray && classOfTradeArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Secondary Class of Trade
                                    </FormLabel>
                                    <Select
                                        value={secondaryClassofTrade}
                                        onChange={(e) => handleSecondaryClassofTrade(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {classOfTradeArray && classOfTradeArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        IMA Class of Trade
                                    </FormLabel>
                                    <OutlinedInput
                                        value={imaClassOfTrade}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleIMAClassOfTrade}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Customer Number
                                    </FormLabel>
                                    <Select
                                        value={customerNumber}
                                        onChange={(e) => handleCustomerNumber(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {customerNumberArray && customerNumberArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Street Address
                                    </FormLabel>
                                    <OutlinedInput
                                        value={street}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleStreet}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        City
                                    </FormLabel>
                                    <OutlinedInput
                                        value={city}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCity}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        State
                                    </FormLabel>
                                    <OutlinedInput
                                        value={state}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleState}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Postal Code
                                    </FormLabel>
                                    <OutlinedInput
                                        value={postalCode}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handlePostalCode}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Country
                                    </FormLabel>
                                    <OutlinedInput
                                        value={country}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCountry}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Membership Start Date
                                    </FormLabel>
                                    <Grid container justifyContent="space-around">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={membershipStartDate}
                                                    onChange={date => handleMembershipStartDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>

                                    </Grid>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Membership End Date
                                    </FormLabel>
                                    <Grid container justifyContent="space-around">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={membershipEndDate}
                                                    onChange={date => handleMembershipEndDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    minDate={membershipStartDate ? membershipStartDate : ''}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>

                                    </Grid>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Parent Member Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={parentMemberID}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleParentMemberID}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Top Parent Member ID
                                    </FormLabel>
                                    <OutlinedInput
                                        value={topParentMemberID}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleTopParentMemberID}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Action
                                    </FormLabel>
                                    <Select
                                        value={action}
                                        onChange={(e) => handleAction(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={'Add'} key={'Add'}>
                                            Add
                                        </MenuItem>
                                        <MenuItem value={'Change'} key={'Change'}>
                                            Change
                                        </MenuItem>
                                        <MenuItem value={'Terminated'} key={'Terminated'}>
                                            Terminated
                                        </MenuItem>

                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Action Description
                                    </FormLabel>
                                    <OutlinedInput
                                        value={actionDescription}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleActionDescription}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Organization Status
                                    </FormLabel>
                                    <Select
                                        value={organizationStatus}
                                        onChange={(e) => handleOrganizationStatus(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={'For Profit'} key={'For Profit'}>
                                            For Profit
                                        </MenuItem>
                                        <MenuItem value={'Not for Profit'} key={'Not for Profit'}>
                                            Not for Profit
                                        </MenuItem>

                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Member Status
                                    </FormLabel>
                                    <Select
                                        value={memberStatus}
                                        onChange={(e) => handleMemberStatus(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={'Active'} key={'Active'}>
                                            Active
                                        </MenuItem>
                                        <MenuItem value={'Inactive'} key={'Inactive'}>
                                            Inactive
                                        </MenuItem>

                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Relationship to Top Parent
                                    </FormLabel>
                                    <Select
                                        value={relationshipToTopParent}
                                        onChange={(e) => handleRelationshipToTopParent(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={'Owned'} key={'Owned'}>
                                            Owned
                                        </MenuItem>
                                        <MenuItem value={'Affiliated'} key={'Affiliated'}>
                                            Affiliated
                                        </MenuItem>

                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Action Effective Date
                                    </FormLabel>
                                    <Grid container justifyContent="space-around">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={actionEffectiveData}
                                                onChange={date => handleActionEffectiveData(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/dd/yyyy"
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}
                            style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                        >   {(() => {
                            let td = [];
                            for (let i = 0; i < addMember; i++) {
                                td.push(<>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel className={classes.formLabel} heading={i == 0 && 'Member ID Type'} twoline='true' onChange={(e) => handleMemberIDType(e, i)} data={memberIDType && memberIDType.length > 0 ? memberIDType[i] : ''} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <TextInputWithLabel className={classes.formLabel} heading={i == 0 && 'Member ID'} twoline='true' onChange={(e) => handleMemberID(e, i)} data={memberID && memberID[i]} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ paddingTop: 20 }}
                                    >
                                        <IconButton
                                            style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 42 : 25 }}
                                            onClick={handleOnAdd}
                                            classes={{ root: classes.IconButton }}
                                            size="large">
                                            <AddBoxOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </>)
                            }
                            return td;
                        })()}
                        </Grid>
                    </div>
                </form>
            </Card>
            <div className={classes.buttonRoot}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Run'
                    }
                </Button>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
        attributeData: state.attributeListData.AttributeData,
        customerMasterData: state.customerData.customerMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit, keyword, data) => dispatch(getMembershipData(pagination, limit, keyword, data)),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewMembershipByFilter);