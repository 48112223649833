import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import SettingsIcon from '@mui/icons-material/Settings';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListIcon from '@mui/icons-material/List';
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { FilePresentOutlined, HelpOutline, MoneyOutlined, Notifications, QueueOutlined } from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
    navBgColor: {
        backgroundColor: theme.palette.primary.main
    },
    customDropdown: {
        '& .dropdown-toggle::after': {
            display: 'none !important',
        },
    },
    arrow: {
        color: 'black',
        marginBottom: -5,
    }
}));

const handleClick = (pge) => {
    if (pge)
        history.push(pge)
}

const menuItems = [
    // { 'Dashboard': '/promotion-dashboard' },
    {

        'Your WorkSpace': [
            { 'Scenario List': '/promotion-dashboard', icon: <ListIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'Create New Scenario': '/#' },
            { 'Stickers Bank': '/promotion-sticker-setup', icon: <SellOutlinedIcon style={{ marginBottom: -7, marginRight: 6, padding: 1 }} /> },
            { 'Grid': '/grid-main', icon: <GridOnOutlinedIcon style={{ marginBottom: -7, marginRight: 6, padding: 2 }} /> },
            { 'Reviewing Scenario': '/reviewing-scenario', icon: <VerifiedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
        ]
    },
    {
        'Approval Flow': [
            { 'Takes Rate Approval': '/takes-rate-approval', icon: <NoteAddOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'Costing Approval': '/costing-approval', icon: <MoneyOutlined style={{ marginBottom: -6, marginRight: 6 }} /> },
            // { 'Vistex Files Generator': '', icon: <CloudDownloadOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
        ]
    },
    {
        'Reporting': [
            { 'Scenario Comparison': '', icon: <BalanceOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'Current Running Promotions': '', icon: <EqualizerOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'Elasticities': '', icon: <TimelineOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'PDF Library': '', icon: <FilePresentOutlined style={{ marginBottom: -6, marginRight: 6 }} /> },
        ]
    },
    {
        'Configuration': [
            { 'Business Rules': '/#' },
            // { 'Take Rate Bank': '/promotion-sticker-setup', icon: <QueueOutlined style={{ marginBottom: -7, marginRight: 6, padding: 1 }} /> },
            { 'Access & User Management': '', icon: <SettingsIcon style={{ marginBottom: -7, marginRight: 6, padding: 2 }} /> },
        ]
    },
    {
        'Master Data': [
            { 'Material Master': '/material-master-promo' },
            { 'Material Pricing': '/material-pricing' },
            { 'Coverage Cost': '/coverage-cost' },
            { 'Coverage Type Mapping': '/coverage-cost-mapping' },
            { 'Promo Finance Cost': '/finance-cost' },
            { 'Standard Finance Cost': '/standard-finance-cost' },
            { 'Promotion Territory': '/promotion-territory' },
            { 'Promotion Currency Rates': '/promo-currency' },
            { 'Sales Forecast': '/sales-forecast-main' },

        ]
    },
    {
        'Utilities': [
            { 'Batch Job Status': '/promo-batchjob-status' },
            { 'Costing Download File': '/costing-download' },
        ]
    },

    { 'Help & Support': '', icon: <HelpOutline fontSize="small" style={{ marginBottom: -4, marginRight: 2 }} /> },

    { 'Notifications': '', icon: <Notifications fontSize="small" style={{ marginBottom: -4, marginRight: 2 }} /> },


]

const subMenuItems = [
    {
        'Budgeting': [
            { 'Global Setup': '/promotion-global-setup' },
            { 'Promotion Setup': '/promotion-setup' },
            { 'Costing Simulation': '/costing-simulation' },
            { 'Costing Postings': '/costing-postings' },
            { 'Costing Approvals': '/costing-approvals' }
        ],
        icon: <LocalAtmOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} />
    },
    {
        'Analytics': [
            { 'Promotion Type': '/monthly-prom-type' },
            { 'Cost to Revenue': '/cost-to-revenue' },
            { 'Cost Allocation': '/cost-allocation' },
            { 'Regional Analysis': '/geographical-chart-us' },
            { 'Market Share': '/market-share' },
            { 'Time to Market': '/time-to-market' }
        ],
        icon: <AnalyticsOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} />
    },

]

const subScenarioItems = [
    // {
    //     'Scenario List': [
    //         { 'Sand Box': '/promotion-scenario-sand-box' },
    //         { 'Approved Scenarios': '/promotion-scenario-list' },
    //         { 'History': '/promotion-scenario-history' },
    //     ],
    //     icon: <ListIcon style={{ marginBottom: -6, marginRight: 6 }} />
    // },
    {
        'Create New Scenario': [
            { 'Manual': '/promotion-scenario-setup' },
            { 'From Recommendor': '' },
        ],
        icon: <DifferenceOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} />
    }
]

const subBusinessItems = [
    {
        'Business Rules': [
            { 'Custom Group Definition': '/custom-group' },
            { 'Config Tables': '' },
            { 'Elasticity Definition': '' },
            { 'Decision Tree': '' },
        ], icon: <BookOutlinedIcon style={{ marginBottom: -5, marginRight: 6, padding: 2 }} />
    }
]

const subMasterItems = [
    {
        'Config Tables': [
            // { 'Dropdown Designer': '/promo-configuration' },
            { 'Promotion Mapping': '/promo-config' },
            { 'Promotion External Mapping': '/promo-mapping' },
        ]
    },
]

const subVistexItems = [
    {
        'Vistex Related': [
            { 'Vistex Approval': '' },
            { 'Vistex Files Generator': '' },
        ], icon: <CloudDownloadOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} />
    }
]

const PromotionSticker = (props) => {
    const classes = useStyles();

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', fontSize: '16px', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {item.icon}
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map((e, ind) => {
                                return (
                                    Object.keys(item)[0] == 'Your WorkSpace' && ind == 1 ?
                                        (subScenarioItems.map((item) => {
                                            return (
                                                <NavDropdown title={
                                                    <>
                                                        <span style={{ color: 'black' }}>{item.icon}</span>
                                                        <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                                        <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                                    </>
                                                }
                                                    className={classes.customDropdown}
                                                    style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '230px' }}>
                                                    {Object.values(item)[0].map(e => {
                                                        return (
                                                            <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                                {Object.keys(e)[0]}
                                                            </NavDropdown.Item>
                                                        )

                                                    })}
                                                </NavDropdown>
                                            )
                                        })) : (
                                            Object.keys(item)[0] == 'Configuration' && ind == 0 ?
                                                (subBusinessItems.map(item => {
                                                    return (
                                                        <>
                                                            <NavDropdown title={
                                                                <>
                                                                    <span style={{ color: 'black' }}>{item.icon}</span>
                                                                    <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                                                    <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                                                </>
                                                            } className={classes.customDropdown}
                                                                style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '140px' }}>
                                                                {Object.values(item)[0].map((e, subInd) => {
                                                                    return (
                                                                        Object.keys(e)[0] == 'Config Tables' && subInd == 1 ?
                                                                            subMasterItems.map((item) => {
                                                                                return (
                                                                                    <NavDropdown title={
                                                                                        <>
                                                                                            <span style={{ color: 'black' }}>{Object.keys(e)[0]}</span>
                                                                                            <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                                                                        </>
                                                                                    }
                                                                                        className={classes.customDropdown}
                                                                                        style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '230px' }}>
                                                                                        {Object.values(item)[0].map(e => {
                                                                                            return (
                                                                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                                                                    {Object.keys(e)[0]}
                                                                                                </NavDropdown.Item>
                                                                                            )
                                                                                        })}
                                                                                    </NavDropdown>
                                                                                )
                                                                            }) :
                                                                            <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                                                {Object.keys(e)[0]}
                                                                            </NavDropdown.Item>
                                                                    )

                                                                })}
                                                            </NavDropdown>
                                                        </>
                                                    )
                                                })) : (
                                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                                        {e.icon}
                                                        {Object.keys(e)[0]}
                                                    </NavDropdown.Item>
                                                )
                                        )
                                )
                            })}
                            {Object.keys(item)[0] == 'Utilities' && subMenuItems.map(item => {
                                return (
                                    <NavDropdown title={
                                        <>
                                            <span style={{ color: 'black' }}>{item.icon}</span>
                                            <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                            <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                        </>
                                    } className={classes.customDropdown}
                                        style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '150px' }}>
                                        {Object.values(item)[0].map(e => {
                                            return (
                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                    {Object.keys(e)[0]}
                                                </NavDropdown.Item>
                                            )

                                        })}
                                    </NavDropdown>
                                )
                            })}
                            {Object.keys(item)[0] == 'Approval Flow' && subVistexItems.map(item => {
                                return (
                                    <NavDropdown title={
                                        <>
                                            <span style={{ color: 'black' }}>{item.icon}</span>
                                            <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                            <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                        </>
                                    }
                                        className={classes.customDropdown}
                                        style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '230px' }}>
                                        {Object.values(item)[0].map(e => {
                                            return (
                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                    {Object.keys(e)[0]}
                                                </NavDropdown.Item>
                                            )

                                        })}
                                    </NavDropdown>
                                )
                            })}
                            {/* {Object.keys(item)[0] == 'Configuration' && subBusinessItems.map(item => {
                                return (
                                    <>
                                        <NavDropdown title={
                                            <>
                                                <span style={{ color: 'black' }}>{item.icon}</span>
                                                <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                                <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                            </>
                                        } className={classes.customDropdown}
                                            style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '140px' }}>
                                            {Object.values(item)[0].map(e => {
                                                return (
                                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                        {Object.keys(e)[0]}
                                                    </NavDropdown.Item>
                                                )

                                            })}
                                            {subMasterItems.map(item => {
                                                return (
                                                    <NavDropdown title={
                                                        <>
                                                            <span style={{ color: 'black' }}>{item.icon}</span>
                                                            <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                                            <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                                        </>
                                                    } style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '140px' }}>
                                                        {Object.values(item)[0].map(e => {
                                                            return (
                                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                                    {Object.keys(e)[0]}
                                                                </NavDropdown.Item>
                                                            )
                                                        })}
                                                    </NavDropdown>
                                                )
                                            })}
                                        </NavDropdown>
                                    </>
                                )
                            })} */}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSticker);