import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { runContractReports, downloadSheet } from '../../../redux/actions';
import { connect } from 'react-redux';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Typography,
    Breadcrumbs,
    Link,
    useMediaQuery,
    Button
} from '@mui/material';
import Moment from 'moment';
import { history } from '../../../components/Helpers';
import { Paper, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
        marginBottom: 15
    },
    rootSearch: {
        borderBottomColor: theme.palette.table.border,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        alignItems: 'center',
        display: 'flex',
        alignSelf: 'flex-end',
        borderRadius: 0,
        padding: 0,
        minWidth: 200,
        [theme.breakpoints.down('lg')]: {
            minWidth: 0,
            marginTop: 10,
            marginRight: 15,
        },
    },
    input: {
        fontSize: 14,
        flexGrow: 1,
        lineHeight: '16px',
        letterSpacing: '-0.05px',
        paddingLeft: 10,
    },
    iconButton: {
        padding: 0,
    },
    icon: {
        fontSize: 20,
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const ContractReportsTable = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractDataArray, setContractDataArray] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.loadContractDetails(1, event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.loadContractDetails(newPage + 1, rowsPerPage);
    };
    const handleSheetDownload = () => {
        props.downloadSheet(contractDataArray.records);
    }
    const [searchText, setSearchText] = React.useState('');
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    }
    useEffect(() => {
        if (props.contractCompleteData) {
            setContractDataArray(props.contractCompleteData);
        }
    }, [props.contractCompleteData]);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/contract-reports')}
                >
                    Contract Reports
            </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}
            >
                <div className={clsx({
                    [classes.row]: isDesktop,
                })} >
                    <Typography variant="h2"> Contract Reports </Typography>
                    <div className={clsx({ [classes.searchInput]: isDesktop })}>
                        <Paper
                            className={clsx(classes.rootSearch, className)}
                        >
                            <SearchIcon className={classes.icon} />
                            <Input
                                className={classes.input}
                                disableUnderline
                                placeholder="Search"
                                onChange={handleSearchOnChange}
                            />
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="Close"
                                onClick={() => props.loadContractDetails(0, 10, searchText)}
                                size="large">
                                <CheckIcon className={classes.icon} />
                            </IconButton>
                        </Paper>
                    </div>
                </div>
                {props.contractData && props.contractData.records && props.contractData.records.length > 0 ?
                    <TableContainer className={classes.tableContaier}>
                        <Table className={classes.table}>
                            <TableHead >
                                <TableRow >
                                    <TableCell>Contract ID</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                    <TableCell>Incentive Rate</TableCell>
                                    <TableCell>Incentive Amount</TableCell>
                                    <TableCell>Calculation Schema</TableCell>
                                    <TableCell>Contract Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }} >
                                {props.contractData.records.map((item) => {
                                    return (
                                        <StyledTableRow key={item.id}>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>{item.internal_description}</TableCell>
                                            <TableCell>{Moment.utc(item.valid_from).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell>{Moment.utc(item.valid_to).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell>{item.incentive_rate && item.incentive_rate.toFixed(2)}</TableCell>
                                            <TableCell>{item.incentive_amount && item.incentive_amount.toFixed(2)}</TableCell>
                                            <TableCell>{item.calculation_schema}</TableCell>
                                            <TableCell>{item.contract_type}</TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </div >
            {props.contractData && props.contractData.records && props.contractData.records.length > 0 &&
                <TablePagination
                    component="div"
                    count={props.contractData.total_record}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        caption: classes.caption,
                        root: classes.paginationRoot
                    }}
                />
            }
            {props.contractCompleteData &&
                <div className={classes.buttonContainer}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                </div>
            }
        </div >
    );

};

const mapStateToProps = state => {
    return {
        contractData: state.operationalReportsData.contractReportsData,
        contractCompleteData: state.operationalReportsData.contractReportsCompleteData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadContractDetails: (pagination, limit, keyword) => dispatch(runContractReports(pagination, limit, keyword)),
        downloadSheet: (data) => dispatch(downloadSheet(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractReportsTable);