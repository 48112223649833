import React, { useEffect, forwardRef, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    OutlinedInput,
    FormLabel,
    IconButton
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
    error: {
        border: '1px solid red !important'
    },
    //new styles
    formLabel: {
        fontSize: 13,
        height: 30
    }
}));

const OrganizationData = forwardRef((props) => {

    const [organizationStore, setOrganizationStore] = React.useState([]);
    const [salesUnit, setSalesUnit] = React.useState('');
    const [volumeRebateGroup, setVolumeRebateGroup] = React.useState('');
    const [pricingReferenceMaterial, setPricingReferenceMaterial] = React.useState('');
    const [materialPricingGroup, setMaterialPricingGroup] = React.useState('');
    const [flexAttribute5, setFlexAttribute5] = React.useState('');
    const [flexAttribute6, setFlexAttribute6] = React.useState('');
    const [flexAttribute7, setFlexAttribute7] = React.useState('');
    const [flexAttribute8, setFlexAttribute8] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    //options
    const [salesOrganization, setSalesOrganization] = React.useState('');
    const [distributionChannel, setDistributionChannel] = React.useState('');
    const [division, setDivision] = React.useState('');

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    function handleSalesOrganization(event) {
        setSalesOrganization(event.target.value);
    }
    function handleDistributionChannel(event) {
        setDistributionChannel(event.target.value);
    }
    function handleDivision(event) {
        setDivision(event.target.value);
    }
    function handleSalesUnit(event) {
        setSalesUnit(event.target.value);
    }
    function handleVolumeRebateGroup(event) {
        setVolumeRebateGroup(event.target.value);
    }

    function handlePricingReferenceMaterial(event) {
        setPricingReferenceMaterial(event.target.value);
    }
    function handleMaterialPricingGroup(event) {
        setMaterialPricingGroup(event.target.value);
    }
    function handleFlexAttribute5(event) {
        setFlexAttribute5(event.target.value);
    }
    function handleFlexAttribute6(event) {
        setFlexAttribute6(event.target.value);
    }
    function handleFlexAttribute7(event) {
        setFlexAttribute7(event.target.value);
    }
    function handleFlexAttribute8(event) {
        setFlexAttribute8(event.target.value);
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        setSalesOrganization(item.sales_organization);
        setDistributionChannel(item.distribution_channel);
        setDivision(item.division);
        setSalesUnit(item.sales_unit);
        setVolumeRebateGroup(item.volume_rebate_group);
        setPricingReferenceMaterial(item.pricing_reference_material);
        setMaterialPricingGroup(item.material_pricing_group);
        setFlexAttribute5(item.flex_attribute_5);
        setFlexAttribute6(item.flex_attribute_6);
        setFlexAttribute7(item.flex_attribute_7);
        setFlexAttribute8(item.flex_attribute_8);
    }

    function handleSubmit() {
        var data = {
            "sales_organization": salesOrganization,
            "distribution_channel": distributionChannel,
            "division": division,
            "sales_unit": salesUnit,
            "volume_rebate_group": volumeRebateGroup,
            "pricing_reference_material": pricingReferenceMaterial,
            "material_pricing_group": materialPricingGroup,
            "flex_attribute_5": flexAttribute5,
            "flex_attribute_6": flexAttribute6,
            "flex_attribute_7": flexAttribute7,
            "flex_attribute_8": flexAttribute8,
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...organizationStore];
            editedArray[editIndex] = data;
            setOrganizationStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setOrganizationStore([...organizationStore, data]);
            props.onChange([...organizationStore, data])
        }
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setSalesOrganization('');
        setDistributionChannel('');
        setDivision('');
        setSalesUnit('');
        setVolumeRebateGroup('');
        setPricingReferenceMaterial('');
        setMaterialPricingGroup('');
        setFlexAttribute5('');
        setFlexAttribute6('');
        setFlexAttribute7('');
        setFlexAttribute8('');
    }
    const handleDeleteLocally = (index) => {

        setOrganizationStore(item => item.filter((item, i) => i !== index));
        props.onChange(organizationStore.filter((item, i) => i !== index))
    }

    return (
        <div>
            {/* Eligibility Rules Add */}
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Sales Organization*
                                        </FormLabel>
                                        <OutlinedInput
                                            value={salesOrganization}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleSalesOrganization} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Distribution Channel*
                                        </FormLabel>
                                        <OutlinedInput
                                            value={distributionChannel}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleDistributionChannel} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Division*
                                        </FormLabel>
                                        <OutlinedInput
                                            value={division}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleDivision} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Sales Unit
                                        </FormLabel>
                                        <OutlinedInput
                                            value={salesUnit}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleSalesUnit} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Volume Rebate Group
                                        </FormLabel>
                                        <OutlinedInput
                                            value={volumeRebateGroup}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleVolumeRebateGroup} />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Pricing Reference Material
                                        </FormLabel>
                                        <OutlinedInput
                                            value={pricingReferenceMaterial}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePricingReferenceMaterial} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Material Pricing Group
                                        </FormLabel>
                                        <OutlinedInput
                                            value={materialPricingGroup}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMaterialPricingGroup} />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 5
                                        </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute5}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute5} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 6
                                        </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute6}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute6} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 7
                                        </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute7}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute7} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 8
                                        </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute8}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute8} />
                                    </div>
                                </Grid>
                                <Grid item md={1} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingTop: 46 }} >
                                        <Grid item md={6} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={salesOrganization && distributionChannel && division ? false : true}
                                                    onClick={() => handleSubmit()}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={salesOrganization && distributionChannel && division ? false : true}
                                                    onClick={() => handleSubmit()}
                                                >
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item
                                            md={6}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="ORGANIZATION DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {organizationStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'>Sales Organization</TableCell>
                                                <TableCell align='center'>Distribution Channel</TableCell>
                                                <TableCell align='center'>Division</TableCell>
                                                <TableCell align='center'>Sales Unit</TableCell>
                                                <TableCell align='center'>Volume Rebate Group</TableCell>
                                                <TableCell align='center'>Pricing Reference Material</TableCell>
                                                <TableCell align='center'>Material Pricing Group</TableCell>
                                                <TableCell align='center'>Flex Attribute 5</TableCell>
                                                <TableCell align='center'>Flex Attribute 6</TableCell>
                                                <TableCell align='center'>Flex Attribute 7</TableCell>
                                                <TableCell align='center'>Flex Attribute 8</TableCell>
                                                <TableCell align='center' style={{ minWidth: 100 }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {organizationStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center'>{item.sales_organization}</TableCell>
                                                        <TableCell align='center'>{item.distribution_channel}</TableCell>
                                                        <TableCell align='center'>{item.division}</TableCell>
                                                        <TableCell align='center'>{item.sales_unit}</TableCell>
                                                        <TableCell align='center'>{item.volume_rebate_group}</TableCell>
                                                        <TableCell align='center'>{item.pricing_reference_material}</TableCell>
                                                        <TableCell align='center'>{item.material_pricing_group}</TableCell>
                                                        <TableCell align='center'>{item.flex_attribute_5}</TableCell>
                                                        <TableCell align='center'>{item.flex_attribute_6}</TableCell>
                                                        <TableCell align='center'>{item.flex_attribute_7}</TableCell>
                                                        <TableCell align='center'>{item.flex_attribute_8}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleEditRow(item, index)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteLocally(index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>

        </div>
    );

});



const mapStateToProps = state => {
    return {
        tableData: state.addContractData.eligibilityRulesData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};

export default connect(mapStateToProps, null)(OrganizationData);