import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Fab,
  TextField
} from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import {
  geographicalIncentive,
  loadAccrualVsPaymentGraph,
  loadTop25Chart,
} from "../../../../redux/actions";
import BarChart from "../../Charts/BarChart";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white, //secondary.main,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    width: "99%",
    height: "253px"
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: '1px solid #EEEEEE',
    borderRadius: 6,
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    width: "132%",
    marginBottom: -10,
    // marginTop: 25,
    boxShadow: "rgba(50, 50, 93, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.15) 0px 2px 4px -2px",
    height: "70px",
    padding: "7px 5px 5px 5px"
  },
  formControlStyle: {
    // border: "1px solid #8F00FF",
    // marginRight: "-3px"
  },
  noBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "1px solid",
        borderColor: theme.palette.border.main,
      },
    },
  },
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: "1px solid",
      borderColor: theme.palette.border.main,
    },
  },
}));
const TopStats = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("accrual");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [attribute, setAttribute] = React.useState("contract_number");
  const dimension = { yAxisTitle: "Incentive  Amount" };
  const [showLoaderTopStats, setShowLoaderTopStats] = React.useState(true);

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Top Incentives by Attribute" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData]);

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      // setStartDate(allowedTilesFilter[0].start_date);
      // setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setPostingType(allowedTilesFilter[0].posting_type);
      // setAttribute(convertToSnakeCase(allowedTilesFilter[0].groupBy));
    }
  }, [allowedTilesFilter]);
  useEffect(() => {
    if (startDate && endDate && sourceDataType && postingType) {
      var data = {
        application_type: sessionStorage.getItem("application"),
        data_source_type: sourceDataType,
        start_date: Moment(startDate).format('MM/DD/YYYY'),
        end_date: Moment(endDate).format('MM/DD/YYYY'),
        posting_type: postingType,
        aggregation_attribute: attribute,
      };
      setShowLoaderTopStats(true);
      props.loadTop25Chart(data);
    }
  }, [allowedTilesFilter]);


  useEffect(() => {
    if (props.top25Data) {
      if (props.top25Data[attribute] && props.top25Data[attribute].length > 0) {
        var graphData = [
          {
            data: props.top25Data[attribute],
            xName: "value",
            yName: "incentive_amount",
            legend: attribute.replace(/_/g, " "),
            type: "Column",
          },
        ];
        setGraphData(graphData);
      } else {
        setGraphData([]);
      }
    }
    setShowLoaderTopStats(false);
  }, [props.top25Data]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
    // var data = {
    //   application_type: sessionStorage.getItem("application"),
    //   data_source_type: sourceDataType,
    //   start_date: Moment(startDate).format('MM/DD/YYYY'),
    //   end_date: Moment(endDate).format('MM/DD/YYYY'),
    //   posting_type: e.target.value,
    //   aggregation_attribute: attribute,
    // };
    // setShowLoaderTopStats(true);
    // props.loadTop25Chart(data);
  };
  function handleSubmit() {
    var data = {
      application_type: sessionStorage.getItem("application"),
      data_source_type: sourceDataType,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: postingType,
      aggregation_attribute: attribute,
    };
    setShowLoaderTopStats(true);
    props.loadTop25Chart(data);
  }

  // const handleAttribute = (e) => {
  //   setAttribute(e.target.value);
  //   var data = {
  //     application_type: sessionStorage.getItem("application"),
  //     data_source_type: sourceDataType,
  //     start_date: Moment(startDate).format('MM/DD/YYYY'),
  //     end_date: Moment(endDate).format('MM/DD/YYYY'),
  //     posting_type: postingType,
  //     aggregation_attribute: e.target.value,
  //   };
  //   props.loadTop25Chart(data);
  // };
  function handleStartDateNew(event) {
    if (event && event.isValid()) {
      setStartDate(event);
      // var data = {
      //   application_type: sessionStorage.getItem("application"),
      //   data_source_type: sourceDataType,
      //   start_date: Moment.utc(event).format("MM/DD/YYYY"),
      //   end_date: Moment.utc(endDate).format("MM/DD/YYYY"),
      //   posting_type: postingType,
      //   aggregation_attribute: attribute,
      // };
      // setShowLoaderTopStats(true);
      // props.loadTop25Chart(data);
    }
    else
      setStartDate(event);
  }
  function handleEndDateNew(event) {
    if (event && event.isValid()) {
      setEndDate(event);
      // var data = {
      //   application_type: sessionStorage.getItem("application"),
      //   data_source_type: sourceDataType,
      //   start_date: Moment.utc(startDate).format("MM/DD/YYYY"),
      //   end_date: Moment.utc(event).format("MM/DD/YYYY"),
      //   posting_type: postingType,
      //   aggregation_attribute: attribute,
      // };
      // setShowLoaderTopStats(true);
      // props.loadTop25Chart(data);
    }
    else
      setEndDate(event);
  }
  const handlePeriod = (type) => {
    var sDate;
    var eDate;
    if (type == "month") {
      sDate = Moment.utc()
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else if (type == "quarter") {
      sDate = Moment.utc()
        .subtract(3, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(3, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else {
      sDate = Moment.utc().startOf("year").format("MM/DD/YYYY");
      eDate = Moment.utc().endOf("year").format("MM/DD/YYYY");
    }
    // props.loadTop25Chart({ ...data, start_date: sDate, end_date: eDate });
    var data = {
      application_type: sessionStorage.getItem("application"),
      data_source_type: sourceDataType,
      start_date: sDate,
      end_date: eDate,
      posting_type: postingType,
      aggregation_attribute: attribute,
    };
    props.loadTop25Chart(data);
  };

  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }


  return (
    <LoadingOverlay
      active={showLoaderTopStats}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div>
        <div className={classes.bodyContainer} style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, maxWidth: 150 }} size="small" className={classes.formControlStyle}>
            <InputLabel id="posting-type" >Posting Type</InputLabel>
            <Select
              className={classes.selectBorder}
              style={{ border: 'none' }}
              labelId="posting-type"
              id="posting-type-select"
              value={postingType}
              label="Posting Type"
              onChange={handlePostingType}
            >
              <MenuItem value={"accrual"}>Accrual</MenuItem>
              <MenuItem value={"payment"}>Payment</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl sx={{ m: 1, maxWidth: 220 }} size="small" className={classes.formControlStyle}>
          <InputLabel id="posting-type">Attributes</InputLabel>
          <Select
            className={classes.selectBorder}
            style={{ border: 'none' }}
            labelId="attributes"
            id="attributes-select"
            value={attribute}
            label="Attribute"
            onChange={handleAttribute}
          >
            <MenuItem value={"contract_number"}>Contract Number</MenuItem>
            <MenuItem value={"supplier_number"}>Supplier Number</MenuItem>
            <MenuItem value={"material_number"}>Material Number</MenuItem>
            <MenuItem value={"customer_number"}>Customer Number</MenuItem>
          </Select>
        </FormControl> */}
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="start_date"
                id="start_date-select"
                label="Start Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.17rem',
                    border: 'none'
                  }
                }}
                value={startDate}
                onChange={date => handleStartDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="end_date"
                id="end_date-select"
                label="End Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.17rem',
                    border: 'none'
                  }
                }}
                value={endDate}
                onChange={date => handleEndDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
                minDate={startDate ? startDate : ''}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 80 }} size="small" className={classes.formControlStyle}>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "35px", marginRight: 3 }}
              onClick={handleSubmit}
              twoline="true"
              disabled={startDate && endDate && postingType ? false : true}
            >
              <DirectionsRunIcon />
            </Button>
          </FormControl>
          {/* <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Fab
            size="small"
            color="primary"
            aria-label="monthly"
            onClick={() => handlePeriod("month")}
          >
            <span style={{ marginLeft: 6 }}>M</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="quarterly"
            onClick={() => handlePeriod("quarter")}
          >
            <span style={{ marginLeft: 9 }}>Q</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="yearly"
            onClick={() => handlePeriod("year")}
          >
            <span style={{ marginLeft: 9 }}>Y</span>
          </Fab>
        </Box> */}
        </div>
        <div className={classes.root}>
          <BarChart
            data={graphData}
            attribute={attribute}
            dimension={dimension}
            height="small"
          />
        </div>
      </div>
    </LoadingOverlay>
  );
};
const mapStateToProps = (state) => {
  return {
    accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
    top25Data: state.initialData.top25,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTop25Chart: (data) => dispatch(loadTop25Chart(data, "dashboard")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopStats);
