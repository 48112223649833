import React from 'react';
import { DetailCPQ } from '../PriceSimulation(CPQ)/CPQResult/components';

const QuoteDetail = props => {

    return (
        <div>
            <DetailCPQ page="price-policy" active={true} />
        </div>
    );
};

export default QuoteDetail;