import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    Button, IconButton, Typography, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload, Edit } from '@mui/icons-material';
import { deleteFinanceCostData, getPromoFinanceCostData, getPromoFinanceCostDetails } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import { useHistory } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';
import Moment from 'moment';
const theme = createTheme({
    overrides: {

        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const FinanceCost = props => {
    useEffect(() => {
        props.getPromoFinanceCostData();
    }, []);
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const addContract = () => {
        localStorage.removeItem('currentAddContractID');
        sessionStorage.setItem('mode', 'add');
    }

    function viewFinanceCostData(id) {
        props.getPromoFinanceCostDetails(id);
        history.push({
            pathname: '/finance-cost/view-finance-cost/' + id,
            id: id
        });
    }

    function editFinanceCost(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/finance-cost/edit-finance-cost/' + id,
            state: 'editFinanceCost',
            id: id
        });
        localStorage.setItem('financeCostEditId', id);
    }

    const columns = [
        {
            field: 'finance_name',
            title: 'Finance Name',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFinanceCostData(rowData.id)}> {rowData.finance_name}</a>

        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFinanceCostData(rowData.id)} > {rowData.country}</a>
        },
        {
            field: 'interest_rate',
            title: 'Interest Rate',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFinanceCostData(rowData.id)}> {rowData.interest_rate}</a>
        },
        {
            field: 'fin_term_structure',
            title: 'Finance Term Structure',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFinanceCostData(rowData.id)}> {rowData.fin_term_structure}</a>
        },
        {
            field: 'finance_cost',
            title: 'Finance Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFinanceCostData(rowData.id)}> {rowData.finance_cost}</a>
        },
        {
            field: 'valid_from',
            title: 'Valid From',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.valid_from).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'valid_to',
            title: 'Valid To',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.valid_to).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_finance_cost-' + sessionStorage.getItem('application')) &&
                        <>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editFinanceCost(rowData.id)}
                                size="large">
                                <Edit color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => props.deleteFinanceCostData(rowData.id)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        </>}
                </div>
        },
    ];
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        var tempRows = [];
        if (props.promoFinanceCostData && props.promoFinanceCostData.records && props.promoFinanceCostData.records.length > 0)
            props.promoFinanceCostData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        finance_name: e.finance_name,
                        country: e.country,
                        interest_rate: e.interest_rate,
                        fin_term_structure: e.fin_term_structure,
                        finance_cost: e.finance_cost,
                        valid_from: e.valid_from,
                        valid_to: e.valid_to
                    });
                })
        setDataRows(tempRows);
    }, [props.promoFinanceCostData]);
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Finance Cost </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_finance_cost-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/finance-cost/finance-cost-excel-upload'
                                onClick={addContract}
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlined />}
                            classes={{
                            startIcon: !isDesktop && classes.startIcon,
                            root: !isDesktop && classes.container
                            }}
                            component={CustomRouterLink}
                            to='/finance-cost/finance-cost-excel-upload'
                            onClick={addContract}
                            style={{ marginLeft: 10 }}
                        >
                            Upload Excel
                        </Button> */}
                        </div>}
                </div>
                {props.promoFinanceCostData && props.promoFinanceCostData.records && props.promoFinanceCostData.total_record > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        promoFinanceCostData: state.promotionData.promoFinanceCostData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPromoFinanceCostData: () => dispatch(getPromoFinanceCostData()),
        getPromoFinanceCostDetails: (id) => dispatch(getPromoFinanceCostDetails(id)),
        deleteFinanceCostData: (id) => dispatch(deleteFinanceCostData(id)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceCost);