import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    Checkbox
} from '@mui/material';
import { connect } from 'react-redux';
import { getAllDistinctPriceListData, getPriceMaintenanceFields, getPriceListData, getContractsTableData, newAPIForAccRe, getAccessData, changeRequestDetails, getContractViewData, downloadSheet, batchApprove, onBulkApprovePriceMaintenance } from '../../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from "@mui/material/Tooltip";
import ChangeCircleIcon from '@mui/icons-material/BubbleChart';
import HashLoader from 'react-spinners/HashLoader'

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer',
        fontSize: theme.typography.h4.fontSize

    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    spinner: {
        height: '100vh'
    },
    table: {
        fontSize: theme.typography.h4.fontSize
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));

const PricingApproval = props => {
    useEffect(() => {
        props.getConfigData(1, 0)
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [selectedApproved, setSelectedApproved] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [priceData, setPriceData] = React.useState([]);
    const [formData, setFormData] = React.useState([]);
    useEffect(() => {
        var temp = []
        if (props.configData && props.configData.records) {
            //setPriceData(props.configData)
            props.configData.records
                .sort((a, b) => a.price_list_id < b.price_list_id ? 1 : -1)
                .map((item, i) => {
                    temp.push({ id: i + 1, ...item })
                })
            setPriceData(temp)
        }
        else
            setPriceData([])

    }, [props.configData])
    const handleCheckAll = () => {
        var data = [];
        var IDS = [];
        if (!isCheckAll) {
            priceData && priceData.map(item => {
                IDS.push(item.id)
                data.push({ 'price_list_id': item.price_list_id, "price_type": item.price_type, "price_type_name": item.price_type_name })
            })

            setSelected(IDS);
            setFormData(data)
        } else {
            setSelected([]);
            setFormData([])
        }
        setIsCheckAll(!isCheckAll);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getConfigData(newPage + 1, rowsPerPage);
    };
    const approveContract = (type) => {
        var data = {
            "price_fields": formData,
            "approval_status": type
        }
        props.onApprove(data)
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getConfigData(1, event.target.value);
    };

    const handleClick = (event, name, item, type) => {
        let newSelected = [];
        let data = []
        const selectedIndex = selected.indexOf(name);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
            data = data.concat(formData, { 'price_list_id': item.price_list_id, "price_type": item.price_type, "price_type_name": item.price_type_name })

        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            data = data.concat(formData.slice(1));

        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            data = data.concat(formData.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            data = data.concat(
                formData.slice(0, selectedIndex),
                formData.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        setFormData(data)
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handlePriceList = (e, item) => {
        e.stopPropagation()
        props.getPriceMaintenanceFields(item.price_list_id)
        //props.getPriceListData(1,0,id)
        history.push({
            pathname: '/pricing-approval/' + item.price_list_id,
            data: { 'id': item.price_list_id, 'priceName': item.price_type_name, 'calcType': item.calculation_type },
            id: item.price_list_id
        });
    }
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginTop: 16 }}> Pricing Approval </Typography>
                    {priceData && priceData.length > 0 &&
                        <div className={classes.buttonRoot}>
                            <Button
                                variant="contained"
                                color="primary"
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={() => approveContract('acc')}
                                startIcon={<CheckIcon />}
                                style={{ margin: 10 }}
                                disabled={!selected.length > 0 && !selectedApproved.length > 0 ? true : false}
                            >
                                Approve
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ClearIcon />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={() => approveContract('rej')}
                                style={{ margin: 10, backgroundColor: '#FA5858' }}
                                disabled={!selected.length > 0 && !selectedApproved.length > 0 ? true : false}
                            >
                                Reject
                            </Button>
                        </div >
                    }
                </div>
                {priceData && priceData.length > 0 ?
                    <TableContainer style={{ marginTop: -15 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow classes={{ root: classes.table }}>
                                    <TableCell className={classes.tabHead}>
                                        <Checkbox
                                            onChange={handleCheckAll}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={classes.tabHead}
                                        align='center'
                                        classes={{ root: classes.cID }}
                                    >Price List ID</TableCell>
                                    <TableCell
                                        className={classes.tabHead}
                                        align='center'
                                        classes={{ root: classes.cID }}
                                    >Price List ID Description</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Price Type Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Price Type </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Calculation Type </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Number of Records </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {priceData && priceData
                                    .sort((a, b) => a.id < b.id ? 1 : -1)
                                    .map((item, i) => {
                                        const isItemSelected = isSelected(item.id);
                                        return (
                                            <StyledTableRow key={item.contract_number} className={classes.hover}
                                                hover
                                                onClick={(event) => handleClick(event, item.id, item)}
                                            >
                                                <TableCell classes={{ root: classes.fontSetting }} padding="checkbox">
                                                    <Checkbox
                                                        color='primary'
                                                        checked={isItemSelected}
                                                        key={item.price_list_id}
                                                        selected={isItemSelected}

                                                    />
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }} align='center' >
                                                    {item.price_list_id}
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                    {item.price_list_id_description}
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }}>{item.price_type_name}</TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }}>{item.price_type}</TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }}>{item.calculation_type}</TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }} onClick={(e) => handlePriceList(e, item)}
                                                    style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}>
                                                    {item.number_of_records}</TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={props.configData && props.configData.total_record ? props.configData.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        />
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

PricingApproval.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = state => {
    return {
        configData: state.pricingData.distinctPriceList,
        loading: state.pricingData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getAllDistinctPriceListData(pagination, limit, 'approval')),
        onApprove: (data) => dispatch(onBulkApprovePriceMaintenance(data)),
        getPriceMaintenanceFields: (priceListId) => dispatch(getPriceMaintenanceFields(priceListId)),
        getPriceListData: (pagination, limit, appId) => dispatch(getPriceListData(pagination, limit, appId, 'editPriceList')),

        batchApprove: (items, status, itemsApproved) => dispatch(batchApprove(items, status, itemsApproved)),
        changeRequestDetails: (id, updatedId, type) => dispatch(changeRequestDetails(id, updatedId, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingApproval);