
import * as React from "react";
import { useRef } from "react";
import { MapsTooltip, LayersDirective, LayerDirective, MapsComponent, Inject, Legend, Zoom, DataLabel } from '@syncfusion/ej2-react-maps';
import usa from "../Analytics/Charts/usa.json";

const SAMPLE_CSS = `
    .e-play-icon::before {
        content: "\\e34b";
    }

    .e-view.fluent .e-play-icon::before, .e-view.fluent-dark .e-play-icon::before {
        content: '\\e75d';
    }

    .e-view.fabric .e-play-icon::before, .e-view.fabric-dark .e-play-icon::before
    {
        content: '\\e7df';
    }

    .e-view.bootstrap .e-play-icon::before {
        content: '\\ebd2';
    }

    .e-view.bootstrap4 .e-play-icon::before {
        content: '\\e743';
    }

    .e-view.tailwind .e-play-icon::before, .e-view.tailwind-dark .e-play-icon::before {
        content: '\\e76c';
    }

    .e-view.highcontrast .e-play-icon::before {
        content: '\\ebf9';
    }

    .e-view.bootstrap5 .e-play-icon::before, .e-view.bootstrap5-dark .e-play-icon::before {
        content: '\\e75d';
    }`;
const GeoChart = (props) => {
    let mapInstance = useRef(null);
    let colorMap = [
        {
            from: 1,
            to: 10000,
            color: '#64CCC5',
            label: '<0.01M',
        },
        {
            from: 10000,
            to: 100000,
            color: '#b0cde1',
            label: '0.01M- 0.1M',
        },
        {
            from: 100000,
            to: 1000000,
            color: '#F78CA2',
            label: '0.1M - 1M',
        },
        {
            from: 1000000,
            to: 2500000,
            color: '#8E8FFA',
            label: '1M - 2.5M',
        },
        {
            from: 2500000,
            to: 5000000,
            color: '#A6FF96',
            label: '2.5M - 5M',
        },
        {
            from: 5000000,
            to: 7500000,
            color: '#3182bd',
            label: '5M - 7.5M',
        },
        {
            from: 7500000,
            to: 10000000,
            color: '#FA9884',
            label: '7.5M - 10M',
        },
        {
            color: '#4c96cb',

        }
    ];
    const onMapsLoad = () => {
        let maps = document.getElementById('maps');
        if (maps) {
            maps.setAttribute("title", "");
        }
        // maps.setAttribute('title', '');
    };
    const load = (args) => {
    };
    const tooltipRender = (args) => {
        if (!args.options.data) {
            args.cancel = true;
        }
    };

    return (<div className='control-pane'>
        <style>{SAMPLE_CSS}</style>
        <div className='control-section row'>
            <div className='col-md-12'>
                <MapsComponent id="maps" height="510" tooltipRender={tooltipRender} loaded={onMapsLoad} load={load} allowPrint={true} ref={mapInstance}
                    useGroupingSeparator={true} format={"n"} legendSettings={{
                        visible: true, mode: 'Interactive', position: 'Bottom', height: '10', width: '80%', title: 'Net Value',
                        labelDisplayMode: 'Trim', alignment: 'Center', textStyle: { color: '#757575' }
                    }} zoomSettings={{ enable: true, enablePanning: true }}
                >
                    <Inject services={[Legend, MapsTooltip, Zoom, DataLabel]} />
                    <LayersDirective>
                        <LayerDirective shapeData={usa} shapePropertyPath='iso_3166_2' shapeDataPath='region' dataSource={props.data} tooltipSettings={{
                            visible: 'point.region' ? true : false, valuePath: 'incentive_ratio',
                            format: 'State :  ${name} <br> Incentive Ratio :  ${incentive_ratio} <br> Incentive Amount : ${incentive_amount} <br> Net Value :  ${net_value} <br>'
                        }}
                            shapeSettings={{ colorValuePath: 'net_value', fill: '#FBFFB1', colorMapping: colorMap }}
                            dataLabelSettings={{
                                visible: true,
                                labelPath: 'iso_3166_2',
                                smartLabelMode: 'Trim'
                            }}
                        />
                    </LayersDirective>
                </MapsComponent>

            </div>

        </div>
    </div>);
};
export default GeoChart;

