import React, { useEffect, useRef } from "react";
import {
  Grid, Button, Typography, Breadcrumbs,
  Card, FormLabel, Checkbox,
  MenuItem, Select, OutlinedInput,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles, withStyles } from "@mui/styles";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import {
  createPriceTypeConfig,
  getPriceTypeConfigById,
} from "../../../redux/actions/Pricing/AuthAction";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  container: {
    padding: 15,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  selectRoot: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    height: 36
  },
  selectedItem: {
    color: theme.palette.text.grey,
    marginTop: 10,
    width: "100%",
  },
  inputTwoLine: {
    marginTop: 3,
    width: "100%",
    paddingLeft: 10,
    height: 35,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 28,
  },
  formDiv: {
    fontSize: theme.typography.h3.fontSize,
  },
  rootSelect: {
    marginTop: 0,
    paddingTop: 6,
    paddingBottom: 6,
  },
  dropdownContainer: {
    marginTop: '2px',
  },
  formlabel: {
    marginBottom: 8,
    fontSize: theme.typography.h3.fontSize,
  },

}));

const AddQualifierConfig = (props) => {
  const classes = useStyles();

  useEffect(() => {
    var pathname = window.location.pathname;
    var appId = pathname.substring(pathname.lastIndexOf("/") + 1);
    setEditID(appId);
    if (appId) props.getConfigData(appId);
  }, []);

  const [editID, setEditID] = React.useState(0);
  const [priceTypeName, setPriceTypeName] = React.useState("");
  const [priceType, setPriceType] = React.useState("");
  const [calculationType, setCalculationType] = React.useState("");
  const [externalPriceTypeName, setExternalPriceTypeName] = React.useState("");
  const [scales, setScales] = React.useState("");
  const [priceTypeCategory, setPriceTypeCategory] = React.useState("");

  const priceTypeArray = [
    "Base Price",
    "On-Invoice Discount",
    "On-Invoice Surcharge",
    "On-Invoice Freight",
    "Off-Invoice Revenue",
    "Off-Invoice Expense",
    "Cost - Indirect",
    "Cost - Direct",
    "Cost - Extra",
    "Floor Price",
    "Margin Target %",
    "Market Price",
    "Market Target %",
    "Transfer Price",
    "Base Sales Adjustments",
    "Price Elasticity Adjustments",
    "Cost Adjustments"
  ];
  const calculationTypeArray = ["Qty", "%", "Fixed"];
  const priceTypeCategoryArray = [
    "Base Price",
    "Cost",
    "Analytical",
    "Off-Invoice Adjustments",
    "On-Invoice Adjustments",
  ];
  function handlePriceType(value) {
    setPriceType(value);
    if (value === 'Base Price') {
      setPriceTypeCategory('Base Price')
    }
    else if (value === 'Cost - Indirect' || value === 'Cost - Direct' || value === 'Cost - Extra') {
      setPriceTypeCategory('Cost')
    }
    else if (value === 'Margin Target %' || value === 'Market Price' || value === 'Market Target %' || value === 'Floor Price') {
      setPriceTypeCategory('Analytical')
    }
    else if (value === 'Off-Invoice Revenue' || value === 'Off-Invoice Expense') {
      setPriceTypeCategory('Off-Invoice Adjustments')
    }
    else if (value === 'On-Invoice Discount' || value === 'On-Invoice Surcharge' || value === 'On-Invoice Freight') {
      setPriceTypeCategory('On-Invoice Adjustments')
    }
    else if (value === 'Transfer Price') {
      setPriceTypeCategory('Transfer Price')
    }
    else {
      setPriceTypeCategory("Optimization")
    }

  }
  function handleOnSubmit() {
    var data = {
      price_type_name: priceTypeName,
      price_type: priceType,
      calculation_type: calculationType,
      scales: scales == "true" ? true : false,
      external_price_type_name: externalPriceTypeName,
      price_type_category: priceTypeCategory,
    };
    props.onSubmit(data, editID);
  }
  useEffect(() => {
    if (props.configData) {
      setPriceTypeName(props.configData.price_type_name);
      setExternalPriceTypeName(props.configData.external_price_type_name);
      setPriceType(props.configData.price_type);
      setCalculationType(props.configData.calculation_type);
      setScales(props.configData.scales);
      setPriceTypeCategory(props.configData.price_type_category);
    }
  }, [props.configData]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h1"
          classes={{
            root: classes.link,
          }}
          to="/pricing-type-config"
        >
          Price Types
        </Link>
        <Typography color="textPrimary" variant="h4">
          Edit Price Types
        </Typography>
      </Breadcrumbs>

      <div className={classes.bodyContainer}>
        <Card>
          <form autoComplete="off" noValidate>
            <div className={classes.container}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formlabel} required>
                      Price Type Name
                    </FormLabel>
                    <OutlinedInput
                      value={priceTypeName}
                      classes={{ root: classes.inputTwoLine }}
                      onChange={(e) => setPriceTypeName(e.target.value)}
                    />
                  </div>
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Price Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={priceType}
                        onChange={(e) => handlePriceType(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                          root: classes.rootSelect
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        {priceTypeArray &&
                          priceTypeArray.map((item) => {
                            return (
                              <MenuItem
                                value={item}
                                key={item}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel variant="h5" className={classes.formlabel}>
                      Price Type Category
                    </FormLabel>
                    {/* <div className={classes.dropdownContainer}> */}
                    <OutlinedInput
                      value={priceTypeCategory}
                      classes={{ root: classes.inputTwoLine }}
                      onChange={(e) => setPriceTypeCategory(e.target.value)}
                      disabled={'disabled'}
                    />
                    {/* </div> */}
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Calculation Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={calculationType}
                        onChange={(e) => setCalculationType(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                          root: classes.rootSelect
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        {calculationTypeArray &&
                          calculationTypeArray.map((item) => {
                            return (
                              <MenuItem
                                value={item}
                                key={item}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Scales
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={scales?.toString()}
                        onChange={(e) => setScales(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                          root: classes.rootSelect
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        <MenuItem
                          value={"true"}
                          key={"true"}
                          style={{ textTransform: "capitalize" }}
                        >
                          Yes
                        </MenuItem>
                        <MenuItem
                          value={"false"}
                          key={"false"}
                          style={{ textTransform: "capitalize" }}
                        >
                          No
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                </Grid>

                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formlabel}>
                      External Price Type Name
                    </FormLabel>
                    <OutlinedInput
                      value={externalPriceTypeName}
                      classes={{ root: classes.inputTwoLine }}
                      onChange={(e) => setExternalPriceTypeName(e.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </Card>

        <div className={classes.buttonRoot}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOnSubmit}
            disabled={
              priceTypeName &&
                priceType &&
                calculationType &&
                scales?.toString()
                ? false
                : true
            }
          >
            {props.loadingAPI ? (
              <BeatLoader
                color={"rgb(54, 215, 183)"}
                loading={props.loadingAPI}
                size={6}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data, id) => dispatch(createPriceTypeConfig(data, id, "edit")),
    getConfigData: (id) => dispatch(getPriceTypeConfigById(id)),
  };
};

const mapStateToProps = (state) => {
  return {
    loadingAPI: state.pricingData.loading,
    configData: state.pricingData.priceTypeSingleData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQualifierConfig);
