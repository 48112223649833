import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton, Button, Fab
} from '@mui/material';
import { Edit, AddBoxOutlined, DeleteForever, CloudUpload, LibraryAdd } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getMembershipContractXref,
    createMembershipContractXref, getLblDispDesFieldValue
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { NavLink as RouterLink } from 'react-router-dom';
import SimpleDialog from '../../../components/Dialog';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 8px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 20
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const MembershipContractXREFUserTable = props => {
    const { className } = props;
    const method = props.type
    const memberID = props.memberId
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);

    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("")

    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    const [columns, setColumns] = React.useState([]);
    const action = {
        field: 'Actions',
        title: 'Actions',
        type: 'string',
        hidden: method,
        render: rowData =>
            <div style={{ whiteSpace: 'nowrap' }}>
                <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => editMember(rowData.ID)}
                    size="large">
                    <Edit color="disabled" classes={{ root: classes.caption }} />
                </IconButton>
                <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => handleClickOpen(rowData.ID)}
                    size="large">
                    <DeleteForever color="disabled" classes={{ root: classes.caption }} />
                </IconButton>
            </div>

    }

    useEffect(() => {
        props.getMembershipDetails(1, 0)
        var data = { "key": "membership_contract_xref", "name": "Membership Contract XREF" }
        props.getLblDispDesFieldValue(data, 'post');
    }, [])
    useEffect(() => {
        if (props.membershipData && props.membershipData.records && props.membershipData.records.length > 0) {
            setDataRows(props.membershipData.records);
            setDataCount(props.membershipData.total_record)
        }
    }, [props.membershipData]);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    newArray.push({
                        field: item.key,
                        title: item.current,
                        type: item.key != 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                        render: (rowData) => <div>{item.key == 'contract_end_date' || item.key == 'contract_start_date' || item.key == 'member_end_date' ?
                            Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    })

                })
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])
    function editMember(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/membership-contract/edit-contract/' + id,
            id: id
        });
    }

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteMembershipData(currentId);
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Contract XREF </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            component={CustomRouterLink}
                            to='/membership-contract/add-contract'
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            component={CustomRouterLink}
                            to={{ pathname: '/membership/membership-excel-upload', state: 'MembershipContractXREFFormats' }}
                        >
                            <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                        </Fab>
                    </div>
                </div>
                {columns && columns.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-9px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            sorting: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataCount, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                <SimpleDialog open={open} content='Do you want to delete this Contract?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        membershipData: state.customerData.membershipContractXREFData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipDetails: (pagination, limit) => dispatch(getMembershipContractXref(pagination, limit)),
        deleteMembershipData: (id) => dispatch(createMembershipContractXref(null, id, 'delete')),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MembershipContractXREFUserTable);