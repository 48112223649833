import React, { useEffect } from 'react';
import {
    Grid,
    Breadcrumbs,
    Typography, Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../components/Inputs';
import { connect } from 'react-redux';
import { getExternalFormulaViewDetails } from '../../../redux/actions';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '20px 10px 0px 10px',
        backgroundColor: 'white',
        marginTop: 10
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));
const ViewExternal = props => {
    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [externalData, setExternalData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);

    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.externalFormulaView) {
            Object.values(props.externalFormulaView)
                .filter(item => item?.key)
                .map((item) => {
                    mandatory.push(item.key)
                    requiredFields.push(item)
                })
            console.log(mandatory)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.externalFormulaView])
    console.log(allowedField)
    console.log(requiredField)



    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getExternalFormulaViewDetails(appId);
    }, []);

    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Button variant='h4' classes={{
                    root: classes.link
                }}
                    style={{ backgroundColor: "#FFFFFF", color: "#007bff" }}
                    // to='/external-formula-results'
                    onClick={() => {
                        console.log('History:', history);
                        history && history.push({ pathname: '/external-formula-results', type: 'edit' });
                    }}
                >
                    External Formula Results
                </Button>
                <Typography color="textPrimary" variant='h4'>View External Formula</Typography>
            </Breadcrumbs>
            {
                props.externalFormulaView &&
                <div className={classes.container}>
                    <Grid container className={classes.gridContainer}>
                        {props.externalFormulaView.customer_number &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Number' data={props.externalFormulaView.customer_number} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.formula_name &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Formula Name' data={props.externalFormulaView.formula_name} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.formula_result &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Formula Result' data={props.externalFormulaView.formula_result} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.start_date &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText heading='Start Date' data={moment.utc(props.externalFormulaView.start_date).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.end_date &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText heading='End Date' data={moment.utc(props.externalFormulaView.end_date).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.material_number &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Material Number' data={props.externalFormulaView.material_number} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.customer_chain &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Chain' data={props.externalFormulaView.customer_chain} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.customer_classification &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Classification' data={props.externalFormulaView.customer_classification} twoline='true' />
                            </Grid>
                        }

                        {props.externalFormulaView.customer_group &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Group' data={props.externalFormulaView.customer_group} twoline='true' />
                            </Grid>
                        }

                        {props.externalFormulaView.customer_group1 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Group 1' data={props.externalFormulaView.customer_group1} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.customer_group2 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Group 2' data={props.externalFormulaView.customer_group2} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.customer_group3 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Group 3' data={props.externalFormulaView.customer_group3} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.customer_group4 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Group 4' data={props.externalFormulaView.customer_group4} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.customer_group5 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Customer Group 5' data={props.externalFormulaView.customer_group5} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute01 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 1' data={props.externalFormulaView.flex_attribute01} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute02 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 2' data={props.externalFormulaView.flex_attribute02} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute03 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 3' data={props.externalFormulaView.flex_attribute03} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute04 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 4' data={props.externalFormulaView.flex_attribute04} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute05 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 5' data={props.externalFormulaView.flex_attribute05} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute06 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 6' data={props.externalFormulaView.flex_attribute06} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute07 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 7' data={props.externalFormulaView.flex_attribute07} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute08 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 8' data={props.externalFormulaView.flex_attribute08} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute09 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 9' data={props.externalFormulaView.flex_attribute09} twoline='true' />
                            </Grid>
                        }
                        {props.externalFormulaView.flex_attribute10 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Flex Attribute 10' data={props.externalFormulaView.flex_attribute10} twoline='true' />
                            </Grid>
                        }
                    </Grid>
                </div>
            }
        </div >
    );

}
const mapStateToProps = state => {
    return {
        externalFormulaView: state.externalFormulaResultsData.externalFormulaViewDetail,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getExternalFormulaViewDetails: (id) => dispatch(getExternalFormulaViewDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewExternal);