import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
    Button,
    Grid,
    Card,
    OutlinedInput,
    FormLabel,
    Checkbox,
    MenuItem,
    Select, Input, ListItemText, Typography, TextField
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { getSalesForecastData, getPromoTerritoryData, getMaterialMasterData } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import BeatLoader from "react-spinners/BeatLoader";
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    spinner: {
        height: '100vh'
    },
    formLabel: {
        //height: 50,
        //display: 'flex',
        //alignItems: 'flex-end',
        marginTop: 13,
        fontSize: theme.typography.h3.fontSize
    },
    selectRoot: {
        //padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },

    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const SalesForecast = props => {
    const { className } = props;
    const classes = useStyles();
    const [country, setCountry] = React.useState([]);
    const [state, setState] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [materialNumber, setMaterailNumber] = React.useState([]);
    const [stateArray, setStateArray] = React.useState([]);
    const [countryArray, setCountryArray] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [selected2, setSelected2] = React.useState([]);
    const [selected3, setSelected3] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [open2, setOpen2] = React.useState('');
    const [open3, setOpen3] = React.useState('');
    const [materailNumberArray, setMaterailNumberArray] = React.useState([]);
    const isAllSelected = stateArray && stateArray.length > 0 && selected.length === stateArray.length;
    const isAllSelected2 = countryArray && countryArray.length > 0 && selected2.length === countryArray.length;
    const isAllSelected3 = materailNumberArray && materailNumberArray.length > 0 && selected3.length === materailNumberArray.length;
    useEffect(() => {
        props.getPromoTerritoryData();
        props.getMaterialMasterData(1, 0);
    }, []);
    useEffect(() => {
        var newArray = [];
        var newArray2 = [];
        if (props.promoTerritoryData && props.promoTerritoryData.records && props.promoTerritoryData.records.length > 0)
            props.promoTerritoryData.records
                .map(e => {
                    newArray.push(e.state)
                    newArray2.push(e.country)
                })
        setStateArray([...new Set(newArray)])
        setCountryArray([...new Set(newArray2)])
    }, [props.promoTerritoryData]);
    useEffect(() => {
        var newArray = []
        if (props.materialMasterData && props.materialMasterData.records.length > 0)
            props.materialMasterData.records
                .map(e => {
                    newArray.push(e.material_number)
                })
        setMaterailNumberArray([...new Set(newArray)])
    }, [props.materialMasterData]);
    const handleCountry = (e) => {
        setCountry(e.target.value);
    }
    const handleState = (e) => {
        setState(e.target.value);
    }
    const hanldeMaterialNumber = (e) => {
        setMaterailNumber(e.target.value)
    }

    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
    }
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === countryArray.length ? [] : countryArray);
        }
        else
            setSelected(newSelected)

    }
    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected2(selected2.length === stateArray.length ? [] : stateArray);
        }
        else
            setSelected2(newSelected)
    }
    const handleSelectValues3 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected3.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected3, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected3.slice(1));
        } else if (selectedIndex === selected3.length - 1) {
            newSelected = newSelected.concat(selected3.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected3.slice(0, selectedIndex),
                selected3.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected3(selected3.length === materailNumberArray.length ? [] : materailNumberArray);
        }
        else
            setSelected3(newSelected)
    }
    function onAdd() {
        setCountry(selected)
        setOpen(false)
        setSelected([])
    }
    function onAdd2() {
        setState(selected2)
        setOpen2(false)
        setSelected2([])
    }
    function onAdd3() {
        setMaterailNumber(selected3)
        setOpen3(false)
        setSelected3([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])

    }
    function onCancel2() {
        setOpen2(false)
        setSelected2([])
    }
    function onCancel3() {
        setOpen3(false)
        setSelected3([])
    }
    function handleOpen() {
        setSelected(country)
        setOpen(true)
    }
    function handleOpen2() {
        setSelected2(state)
        setOpen2(true)
    }
    function handleOpen3() {
        setSelected3(materialNumber)
        setOpen3(true)
    }
    function handleClear() {
        setCountry([]);
        setState([]);
        setBillingDateStart(null);
        setBillingDateEnd(null);
        setMaterailNumber([]);
    }
    var data = {
        "billing_date.range": (billingDateStart ? Moment(billingDateStart).local().format('YYYY-MM-DD') : '') + ',' + (billingDateEnd ? Moment(billingDateEnd).local().format('YYYY-MM-DD') : ''),
        "country.in": country.length > 0 ? country.toString() : null,
        "state.in": state.length > 0 ? state.toString() : null,
        "material_number.in": materialNumber.length > 0 ? materialNumber.toString() : null
    };
    function handleSubmit() {
        props.onSubmit(data);
        handleClear();
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}

        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>Sales Forecast</Typography>
                            <div className={classes.container}>
                                <Grid container spacing={2} style={{ padding: '16px 25px 0px 25px' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ marginTop: 30 }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }}>Billing Start Date</FormLabel>
                                        <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: "37px",
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%'
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={date => handleBillingDateStart(date)}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ marginTop: 30 }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }}>Billing End Date</FormLabel>
                                        <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: "37px",
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginLeft: 10,
                                                                marginRight: 15
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={date => handleBillingDateEnd(date)}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                Country
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple
                                                    value={country}
                                                    style={{ height: "37px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                    input={<OutlinedInput />}
                                                    onOpen={() => handleOpen()}
                                                    onClose={() => setOpen(false)}
                                                    open={open}
                                                    renderValue={(appType) => {
                                                        var qualifier = []
                                                        appType
                                                            .map(item => {
                                                                qualifier.push(item)

                                                            })
                                                        return qualifier.join(", ")
                                                    }}
                                                    className={classes.multiSelect}

                                                >
                                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={isAllSelected} />
                                                        <ListItemText primary='Select All' />
                                                    </MenuItem>
                                                    {countryArray && countryArray
                                                        .map(item => {
                                                            return (
                                                                <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={selected.indexOf(item) > -1} />
                                                                    <ListItemText primary={item} />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    <div className={classes.dropdownbottom}>
                                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                            Cancel
                                                        </Button>

                                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                State
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple
                                                    value={state}
                                                    style={{ height: "37px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                    input={<OutlinedInput />}
                                                    onOpen={() => handleOpen2()}
                                                    onClose={() => setOpen2(false)}
                                                    open={open2}
                                                    renderValue={(appType) => {
                                                        var priceType = []
                                                        appType
                                                            .map(item => {
                                                                priceType.push(item)

                                                            })
                                                        return priceType.join(", ")
                                                    }}
                                                    className={classes.multiSelect}

                                                >
                                                    <MenuItem value='all' onClick={(event) => handleSelectValues2(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={isAllSelected2} />
                                                        <ListItemText primary='Select All' />
                                                    </MenuItem>
                                                    {stateArray && stateArray
                                                        .map(item => {
                                                            return (
                                                                <MenuItem onClick={(event) => handleSelectValues2(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={selected2.indexOf(item) > -1} />
                                                                    <ListItemText primary={item} />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    <div className={classes.dropdownbottom}>
                                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel2}>
                                                            Cancel
                                                        </Button>

                                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd2} >
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                Material Number
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple
                                                    value={materialNumber}
                                                    style={{ height: "37px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                    input={<OutlinedInput />}
                                                    onOpen={() => handleOpen3()}
                                                    onClose={() => setOpen3(false)}
                                                    open={open3}
                                                    renderValue={(appType) => {
                                                        var priceType = []
                                                        appType
                                                            .map(item => {
                                                                priceType.push(item)

                                                            })
                                                        return priceType.join(", ")
                                                    }}
                                                    className={classes.multiSelect}

                                                >
                                                    <MenuItem value='all' onClick={(event) => handleSelectValues3(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={isAllSelected3} />
                                                        <ListItemText primary='Select All' />
                                                    </MenuItem>
                                                    {materailNumberArray && materailNumberArray
                                                        .map(item => {
                                                            return (
                                                                <MenuItem onClick={(event) => handleSelectValues3(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={selected3.indexOf(item) > -1} />
                                                                    <ListItemText primary={item} />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    <div className={classes.dropdownbottom}>
                                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel3}>
                                                            Cancel
                                                        </Button>

                                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd3} >
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card >
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                            disabled={materialNumber.length > 0 || (billingDateStart && billingDateEnd) || country.length > 0 || state.length > 0 ? false : true}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Run'
                            }
                        </Button>
                    </div>
                </div >
            </div >
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        salesForecastData: state.dataSetupData.salesForecastData,
        salesForecastError: state.dataSetupData.salesForecastErrorList,
        salesForecast: state.dataSetupData.salesForecast,
        promoTerritoryData: state.promotionData.promoTerritoryData,
        materialMasterData: state.customerData.materialMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getSalesForecastData(data)),
        getPromoTerritoryData: () => dispatch(getPromoTerritoryData()),
        getMaterialMasterData: (pagination, limit) => dispatch(getMaterialMasterData('NoLimit', limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesForecast);