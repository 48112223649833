import Palette from '../palette';

export default {
    root: {
        color: Palette.text.primary
    },
    asterisk: {
        color: 'red',
        '&$error': {
            color: 'red'
        },
        fontSize: 13
    }
}