import React, { useRef, useState, useEffect } from "react";
import {
    Grid,
    Button,
    Typography,
    FormLabel,
    TextField,
    Checkbox,
    Dialog,
    DialogActions,
    OutlinedInput, Card,
} from "@mui/material";
import { createMuiTheme } from "@mui/material";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Dropdown, DropdownKeyValue, ChipInput, DropdownArray, MultiSelectDropdownKeyVal } from "../../components/Inputs";
import { ContractNumberAC } from "../../components/Inputs";
import SimpleDialog from "../../components/Dialog";
import { toast } from 'react-toastify';
import { SearchDropDownMasterDataNew, SearchDropDownMasterData } from "../../components/Inputs";
import {
    runCalculationSimulation,
    getDefaultValues,
    getContractsTableDataList,
    addBatchJobSetup, getLblDispDesFieldValue, getConfigurationDesigner
} from "../../redux/actions";
import { connect } from "react-redux";
import 'moment-timezone';
import Moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader, RotateLoader } from "react-spinners";
import { history } from "../../components/Helpers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { runDailySimulation } from "../../redux/actions/Simulations/CalculationSimulation/AuthAction";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: "10px 0px 0px 0px",
        marginTop: 5,
    },
    row: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    container: {
        padding: 10,
    },
    gridContainer: {
        padding: "0px 10px 0px 10px",
        marginTop: 0,
        display: 'block !important',
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: "3rem",
        paddingBottom: 30,
        width: "60%"

    },
    button: {
        marginRight: 25,
        width: 140,
        // fontSize: "1.1 em",
        fontFamily: "arial !important"
    },
    rootContainer: {
        border: "1px solid",
        borderColor: theme.palette.border.main,
        borderRadius: -12,
        height: 36,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: "#E1E4F3",
        "&&:hover": {
            backgroundColor: "#E1E4F3",
            color: theme.palette.black,
        },
        fontFamily: "ProximaNova",
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: "ProximaNova",
        fontSize: 13,
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
    },
    chipContainer: {
        display: "flex",
        alignItems: "center",
        overflowX: "overlay",
        flexFlow: "unset",
    },
    chipLabel: {
        fontFamily: "ProximaNova",
    },
    contractContiner: {
        padding: "15px 0px 18px",
    },
    spinner: {
        // height: "100vh",
    },
    fontSetting: {
        // fontSize: theme.typography.h3.fontSize,
        fontSize: "1.1 em",
        fontFamily: "arial !important"
    },
    fontSetting1: {
        fontSize: "1.3em",
        fontFamily: "arial !important"
    },
    dropdownContainer: {
        fontSize: theme.typography.h3.fontSize,
        marginBottom: 3
    },
    paper: { minWidth: window.screen.width * 0.5 },
    inputTwoLine: {
        marginTop: 10,
        width: "100%",
        paddingLeft: 10,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
        width: "60%",
        marginLeft: "1.5rem"
        // marginTop: 25,
        // padding: "1rem",
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        borderRadius: '1rem',
        height: '36px'
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '2rem',
        marginTop: '0.5rem',
        marginBottom: '5px',
        // marginLeft: '1rem',
        // marginRight: '1rem'
    }
}));
const DailySimulations = (props) => {
    const classes = useStyles();
    const { className } = props;
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [contractGroupArray, setContractGroupArray] = React.useState([]);
    const [contractSubGroupArray, setContractSubGroupArray] = React.useState([]);
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [suppressOnlineEnable, setSuppressOnlineEnable] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState("");
    const [contractGroup, setContractGroup] = React.useState("");
    const [contractSubGroup, setContractSubGroup] = React.useState("");
    const [calculationPeriodStart, setCalculationPeriodStart] = useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = useState(null);
    const [contractNumber, setContractNumber] = useState([]);
    const [contractType, setContractType] = useState("");
    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const [startValid, setStartValid] = useState(true);
    const [endValid, setEndValid] = useState(true);
    const typeRef = useRef(null);
    const subTypeRef = useRef(null);
    const [masterValue, setMasterValue] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const isContractNumberMandatory = suppressOnlineEnable.includes('Yes');
    const [aggregateBy, setAggregateBy] = useState([]);
    const [attributeNameArray, setAttributeNameArray] = React.useState([]);

    useEffect(() => {
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
    }, []);

    useEffect(() => {
        props.getClaimData();
        props.onLoadingDefault(applicationType, 'Direct - Sales Data');
    }, []);

    useEffect(() => {
        setCalculationPeriodStart(
            Moment.utc().startOf('month')
        );
        setCalculationPeriodEnd(Moment.utc().endOf('day'));
    }, []);

    useEffect(() => {
        if (props.claimData && props.claimData.length > 0) {
            props.claimData.forEach(data => {
                if (data.key_name === "suppress_online_run") {
                    if (data.config_fields != null) {
                        setSuppressOnlineEnable(data.config_fields)
                    }
                    else {
                        setSuppressOnlineEnable([])
                    }
                }
            });
        }
    }, [props.claimData]);

    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records.length > 0) {
            setAttributeNameArray(Object.entries(props.dropdownAttributeData.records[0].label_names).map(([key, desc]) => ({ key, desc })))
        }
    }, [props.dropdownAttributeData])

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            setContractTypeArray(props.dropdownData.field_label_attributes.contract_type);
            setContractGroupArray(props.dropdownData.field_label_attributes.contract_group);
            setContractSubGroupArray(props.dropdownData.field_label_attributes.contract_sub_group);
            setCalculationMethodArray(props.dropdownData.field_label_attributes.calculation_method);
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])


    var sd = calculationPeriodStart
        ? Moment.utc(calculationPeriodStart)
            .toISOString()
            ?.split("T")[0]
            .concat("T00:00:00.000Z")
        : "";
    var ed = calculationPeriodEnd
        ? Moment.utc(calculationPeriodEnd)
            .toISOString()
            ?.split("T")[0]
            .concat("T23:59:59.000Z")
        : "";
    const formData = {
        application_type: applicationType,
        aggregate_by: aggregateBy,
        contract_number: contractNumber,
        contract_type: contractType,
        contract_group: contractGroup,
        contract_sub_group: contractSubGroup,
        calculation_method: calculationMethod,
        calculation_period_start: sd,
        calculation_period_end: ed,
        tags: tags,
    };


    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            formData['contract_number'] = contractNumber.map(Number);
            var temp = [];


            if (masterValue && masterValue.length > 0 && (applicationType === 'Customer Rebate' || applicationType === 'Supplier Rebate')) {
                temp = [
                    {
                        "attribute_name": applicationType === 'Customer Rebate' ? "customer_number" : "supplier_number",
                        "attribute_value": masterValue,
                        "attribute_type": "Attribute"
                    }
                ]
                formData['eligibility_rules'] = [
                    {
                        "option": "EQUAL",
                        "effective_start_date": sd,
                        "effective_end_date": ed,
                        "enabled": true,
                        "type": "Both",
                        "sequence": 0,
                        "attribute_key_values": temp
                    }
                ];
            }
            if (open) {
                props.onSubmit(formData);
            }
            // handleClear();
        } else {
            setOpen(false);
        }
    };
    const handleClear = () => {
        // startRef.current.reset();
        // endRef.current.reset();
        // typeRef.current.reset();
        subTypeRef.current.reset();
        setContractNumber([]);
        setContractType('');
        setContractGroup('');
        setContractSubGroup('');
        setCalculationMethod('');
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setMasterValue([]);
        setTags([]);
        setAggregateBy([]);
    };
    const handleClickOpen = () => {
        if (calculationPeriodStart <= calculationPeriodEnd) {
            setOpen(true);
        }
        else {
            toast.error('Please Enter a Valid Calculation Period!');
        }
    };


    function handleCalculationPeriodStart(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodStart(newValue);
            setStartValid(true);
        } else {
            setCalculationPeriodStart(newValue);
            if (!newValue) setCalculationPeriodEnd(null);

            setStartValid(false);
        }
    }

    function handleCalculationPeriodEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodEnd(newValue);
            setEndValid(true);
        } else {
            setCalculationPeriodEnd(newValue);
            setEndValid(false);
        }
    }

    function handleContractNumberAC(value) {
        if (value.length > 0) {
            setContractNumber(value);
        } else {
            setContractNumber([]);
        }
    }
    function handleAggregate(newValue) {
        if (newValue && newValue.length > 0)
            setAggregateBy(newValue)
        else
            setAggregateBy([])
    }
    function handleTags(newValue) {
        setTags(newValue)
    }
    function handleContractType(newValue) {
        setContractType(newValue);
    }
    function handleContractGroup(newValue) {
        setContractGroup(newValue);
    }
    function handleContractSubGroup(newValue) {
        setContractSubGroup(newValue);
    }
    function handleCalculationMethod(newValue) {
        setCalculationMethod(newValue);
    }


    function handleMasterdata(value) {
        setMasterValue(value)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography classes={{ root: classes.fontSetting1 }} variant="h1" color="primary" style={{ marginLeft: 22 }}>
                        Daily Simulation
                    </Typography>
                </div>
                {/* <form autoComplete="off" noValidate> */}
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container
                        >
                            <div style={{
                                padding: "0px  10px 20px 10px", width: "100%"
                            }}>
                                <div className={classes.titleSetting}
                                    style={{
                                    }}>
                                    <div style={{ alignItems: 'center' }}>
                                        <Typography classes={{ root: classes.fontSetting1 }} variant="h2" color="secondary" style={{ marginLeft: "20px" }} >
                                            Contracts Data <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                                        </Typography>
                                    </div>
                                </div>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: -5 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "8px" }}>
                                            <FormLabel
                                                // required={calculationMethod || contractType ? false : true}
                                                classes={{ root: classes.fontSetting }}>
                                                Contract Number
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <SearchDropDownMasterDataNew id='contract'
                                                prevalue={contractNumber}
                                                multiple
                                                table='contract_header' keyName='contract_number'
                                                description={true}
                                                onChange={handleContractNumberAC}
                                                applicationType={applicationType}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{ marginTop: -15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel
                                                // required={contractNumber.length > 0 ? false : true}
                                                classes={{ root: classes.fontSetting }}>
                                                {
                                                    contractTypeArray && contractTypeArray.current
                                                        ? contractTypeArray.current
                                                        : "Contract Type"
                                                }
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-9px" }}>
                                            <DropdownArray
                                                classes={{ root: classes.fontSetting }}
                                                defaultValue={contractType}
                                                data={
                                                    contractTypeArray && contractTypeArray.drop_down_values
                                                        ? contractTypeArray.drop_down_values
                                                        : []
                                                }
                                                placeholder={
                                                    contractTypeArray && contractTypeArray.drop_down_values &&
                                                    contractTypeArray.drop_down_values.length == 0 &&
                                                    "- Please configure contract type -"
                                                }
                                                twoline="true"
                                                onChange={handleContractType}
                                                error={
                                                    typeof contractType === "boolean" && !contractType
                                                        ? true
                                                        : false
                                                }
                                                ref={typeRef}

                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{ marginTop: -15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "-12px" }}>
                                            <FormLabel
                                                // required={contractNumber.length > 0 ? false : true}
                                                classes={{ root: classes.fontSetting }}>
                                                {
                                                    contractGroupArray && contractGroupArray.current
                                                        ? contractGroupArray.current
                                                        : "Contract Group"
                                                }
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-20px" }}>
                                            <DropdownArray
                                                classes={{ root: classes.fontSetting }}
                                                defaultValue={contractGroup}
                                                data={
                                                    contractGroupArray && contractGroupArray.drop_down_values
                                                        ? contractGroupArray.drop_down_values
                                                        : []
                                                }
                                                placeholder={
                                                    contractGroupArray && contractGroupArray.drop_down_values &&
                                                    contractGroupArray.drop_down_values.length == 0 &&
                                                    "- Please configure contract type -"
                                                }
                                                twoline="true"
                                                onChange={handleContractGroup}
                                                error={
                                                    typeof contractGroup === "boolean" && !contractGroup
                                                        ? true
                                                        : false
                                                }
                                                ref={typeRef}

                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{ marginTop: -15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "-12px" }}>
                                            <FormLabel
                                                // required={contractNumber.length > 0 ? false : true}
                                                classes={{ root: classes.fontSetting }}>
                                                {
                                                    contractSubGroupArray && contractSubGroupArray.current
                                                        ? contractSubGroupArray.current
                                                        : "Contract Subgroup"
                                                }
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-20px" }}>
                                            <DropdownArray
                                                classes={{ root: classes.fontSetting }}
                                                defaultValue={contractSubGroup}
                                                data={
                                                    contractSubGroupArray && contractSubGroupArray.drop_down_values
                                                        ? contractSubGroupArray.drop_down_values
                                                        : []
                                                }
                                                placeholder={
                                                    contractSubGroupArray && contractSubGroupArray.drop_down_values &&
                                                    contractSubGroupArray.drop_down_values.length == 0 &&
                                                    "- Please configure contract type -"
                                                }
                                                twoline="true"
                                                onChange={handleContractSubGroup}
                                                error={
                                                    typeof contractSubGroup === "boolean" && !contractSubGroup
                                                        ? true
                                                        : false
                                                }
                                                ref={typeRef}

                                            />
                                        </div>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: -12 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "-12px" }}>
                                            <FormLabel
                                                // required={contractNumber.length > 0 ? false : true}
                                                classes={{ root: classes.fontSetting }}>
                                                {
                                                    calculationMethodArray && calculationMethodArray.current
                                                        ? calculationMethodArray.current
                                                        : "Calculation Method"
                                                }
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-19px" }}>
                                            <DropdownKeyValue
                                                classes={{ root: classes.fontSetting }}
                                                defaultValue={calculationMethod}
                                                data={
                                                    calculationMethodArray &&
                                                        calculationMethodArray.drop_down_reference_value
                                                        ? calculationMethodArray.drop_down_reference_value
                                                        : []
                                                }
                                                twoline="true"
                                                onChange={handleCalculationMethod}
                                                error={
                                                    typeof calculationMethod === "boolean" &&
                                                        !calculationMethod
                                                        ? true
                                                        : false
                                                }
                                                ref={subTypeRef}
                                                labelFormat={'desc'}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </form>
                </Card>

                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container
                        >
                            <div style={{
                                padding: "0px 10px 20px 10px", width: "100%"
                            }}>
                                <div className={classes.titleSetting}
                                >
                                    <Typography classes={{ root: classes.fontSetting1 }} variant="h2" color="secondary" style={{ marginLeft: "20px" }}  >
                                        Calculation Data
                                    </Typography>
                                </div>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ marginTop: 10 }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "0px" }}>
                                            <FormLabel required classes={{ root: classes.fontSetting }}>
                                                Calculation Period
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-4px", display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                // padding: '1px 10px 1px 11px',
                                                                alignSelf: "center",
                                                                alignItems: "center",
                                                                border: "1px solid #E0E0E0",
                                                                width: "95%",
                                                                marginBottom: 5,
                                                                height: 37,
                                                                marginLeft: -11,
                                                            },
                                                        }}
                                                        value={calculationPeriodStart}
                                                        onChange={handleCalculationPeriodStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                // padding: '1px 10px 1px 11px',
                                                                alignSelf: "center",
                                                                alignItems: "center",
                                                                border: "1px solid #E0E0E0",
                                                                width: "95%",
                                                                marginBottom: 5,
                                                                height: 37,
                                                                marginRight: -11
                                                            },
                                                        }}
                                                        value={calculationPeriodEnd}
                                                        onChange={handleCalculationPeriodEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        minDate={
                                                            calculationPeriodStart ? calculationPeriodStart : ""
                                                        }
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </Grid>

                                {applicationType == 'Customer Rebate' &&
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div style={{ width: '40%' }}>
                                                <FormLabel classes={{ root: classes.fontSetting }}>
                                                    Customer Number
                                                </FormLabel>
                                            </div>
                                            <div style={{ width: '60%' }}>
                                                <SearchDropDownMasterDataNew id='456'
                                                    table='customer_master' keyName='customer_number'
                                                    description={true}
                                                    multiple
                                                    prevalue={masterValue}
                                                    onChange={handleMasterdata}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                }
                                {applicationType == 'Supplier Rebate' &&
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div style={{ width: '40%' }}>
                                                <FormLabel classes={{ root: classes.fontSetting }}>
                                                    Supplier Number
                                                </FormLabel>
                                            </div>
                                            <div style={{ width: '60%' }}>
                                                <SearchDropDownMasterDataNew id='789'
                                                    table='supplier_master' keyName='supplier_number'
                                                    description={true}
                                                    prevalue={masterValue}
                                                    multiple
                                                    onChange={handleMasterdata}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: '20px' }}>
                                                Aggregate By
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-20px" }}>
                                            <MultiSelectDropdownKeyVal
                                                listArray={attributeNameArray && attributeNameArray.length > 0 ? attributeNameArray : []}
                                                data={aggregateBy} id='aggregate_by' onChange={handleAggregate} capitalize />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                Tags
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-10px" }}>
                                            <ChipInput data={tags} onChange={handleTags} />
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </form>
                </Card>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        twoline="true"
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                    <Button
                        disabled={
                            !(
                                startValid &&
                                endValid &&
                                (
                                    contractType ||
                                    calculationMethod ||
                                    contractGroup ||
                                    contractSubGroup ||
                                    (contractNumber && contractNumber.length > 0)
                                )
                            )
                        }
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleClickOpen}
                        twoline="true"
                    >
                        {props.loading ? (
                            <RotateLoader
                                color={"rgb(54, 215, 183)"}
                                loading={props.loading}
                                size={2}
                            />
                        ) : (
                            "Run Online"
                        )}
                    </Button>

                </div>
                <SimpleDialog
                    open={open}
                    content="Do you want to run daily simulation?"
                    handleDialog={handleDialog}
                />
            </div>
        </LoadingOverlay >
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (formData) => dispatch(runDailySimulation(formData)),
        onSubmitBatchJob: (data) => dispatch(addBatchJobSetup(data)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        onCheckContractsTable: (app) => dispatch(getContractsTableDataList(null, "Designer Studio", app)),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getClaimData: () => dispatch(getConfigurationDesigner()),
    };
};

const mapStateToProps = (state) => {
    return {
        loading: state.simulationData.loading,
        // dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        contractTabledataComplete: state.addContractData.contractAllListData,
        claimData: state.addMultipleConfigurationData.configdesigner,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DailySimulations);
