import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getDynamicQueryFields, loadPurchaseDataFormatFields, postPurchaseDataQuery, fetchSalesInvoiceQuerySingle } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const PurchaseInvoiceEditQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('purchase-invoice') ? JSON.parse(sessionStorage.getItem('purchase-invoice')) : []);
    const [transactionType, setTransactionType] = React.useState('');
    const [purcahseOrder, setPurchaseOrder] = React.useState('');
    const [purchaseOrderLine, setPurchaseOrderLine] = React.useState('');
    const [poDate, setPODate] = React.useState('');
    const [queryObject, setQueryObject] = React.useState({});
    const [editMode, setEditMode] = React.useState(false);
    const [userStore, setUserStore] = React.useState([]);
    const [defaultValue, setDefaultValue] = React.useState({});
    const [allowedField, setAllowedField] = React.useState([]);
    const [currentId, setCurrentId] = React.useState(0);
    const [sortedData, setSortedData] = React.useState([]);
    const [requiredFields, setRequiredFields] = React.useState([]);


    useEffect(() => {
        setOpen(true);
        props.fetchSalesInvoiceQuerySingle(purchaseQueryDataStorage.id)
        props.loadPurchaseDataFormatFields({ "table_name": "Direct - Purchase Data" });
    }, []);




    useEffect(() => {
        if (props.salesInvoiceQuery) {
            setCurrentId(props.salesInvoiceQuery.format.id)
            // setSalesQueryData(props.salesInvoiceQuery)
            setQueryObject(props.salesInvoiceQuery.format)

            if (props.salesInvoiceQuery.default_values != null) {
                setDefaultValue(props.salesInvoiceQuery.default_values)
                setTransactionType(props.salesInvoiceQuery.default_values.transaction_type);
            }
            if (props.salesInvoiceQuery.format.format_name) {
                setQueryName(props.salesInvoiceQuery.format.format_name);
            }
            if (props.salesInvoiceQuery.format.transaction_type) {
                setTransactionType(props.salesInvoiceQuery.format.transaction_type);
            }

        }
    }, [props.salesInvoiceQuery]);

    useEffect(() => {
        var tempObj = []
        let req = []
        if (props.tableData && props.tableData.field_label_attributes) {
            Object.values(props.tableData.field_label_attributes).map(e => {
                tempObj.push(e)
                if (e.mandatory) {
                    req.push(e.key)
                }
            })
            setRequiredFields(req)
            setSortedData(tempObj);
        }
    }, [props.tableData])
    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName] === 0) {
                delete obj[propName];
            }
        }
        return obj
    }

    useEffect(() => {
        var newArray = []
        if (clean(defaultValue) && clean(queryObject) && requiredFields) {
            Object.keys(defaultValue).map(item => {
                if (requiredFields.includes(item)) {
                    newArray.push(requiredFields.includes(item))
                }
            })
            Object.keys(queryObject).map(item => {
                if (requiredFields.includes(item)) {
                    newArray.push(requiredFields.includes(item))
                }
            })
            if (newArray.length > 0 && (newArray.length == requiredFields.length)) {
                setAllowedField(true)
            }

        }

    }, [queryObject, defaultValue, requiredFields])
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName.trim() });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    // function handleClear() {
    //     for (var propName in queryObject) {
    //         if(propName!='format_name')
    //         {
    //              queryObject[propName] =''
    //         }
    //     }
    // }
    function handleClear() {
        for (var propName in queryObject) {

            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
        setDefaultValue({ ...defaultValue });
        setAllowedField(false)
    }

    const handleDefaultValue = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
        if (item === 'transaction_type') {
            setTransactionType(event.target.value)
        }
    };
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === '' || queryObject[propName] === null || queryObject[propName] === undefined) {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit({ ...queryObject, 'format_name': queryName, 'default_values': defaultValue }, currentId);
    }
    function handleClearAll() {
        handleClear();
        setTransactionType('')
        setPurchaseOrder('')
        setPurchaseOrderLine('')
        setPODate('')
        setEditMode(false);
        setUserStore([]);
    }
    function handleCancelDialog() {
        history.push('/purchase-invoice');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'transaction_type') {
            setTransactionType(event.target.value)
        }
    };

    return (
        <div className={classes.root}>

            <Typography color="textPrimary" variant='h1'>Edit Format</Typography>

            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.salesInvoiceQuery ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.values(sortedData)
                                                        // .filter(item => item != 'id' && item != 'format_name' && item != '')
                                                        // .sort((a, b) => a > b?1:-1)
                                                        .map((item) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        {requiredFields.includes(item.key) ? <p>{item.current}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.current}
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item.key] ? queryObject[item.key] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item.key)}
                                                                            defaultValue={JSON.parse(sessionStorage.getItem('purchase-invoice'))[item.key] ? JSON.parse(sessionStorage.getItem('purchase-invoice'))[item.key] : ''}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70, paddingLeft: 10 }}>
                                                                        <TextField
                                                                            value={defaultValue[item.key] ? defaultValue[item.key] : ''}
                                                                            type="string"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleDefaultValue(e, item.key)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={!setAllowedField ? true : false}>
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        tableData: state.dataSetupData.salesPurchaseQueryFieldsData,
        salesInvoiceQuery: state.dataSetupData.salesInvoiceQuerySingleData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(postPurchaseDataQuery(data, id, 'put')),
        loadPurchaseDataFormatFields: (data) => dispatch(loadPurchaseDataFormatFields(data)),
        fetchSalesInvoiceQuerySingle: (id) => dispatch(fetchSalesInvoiceQuerySingle(id, "purchase"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceEditQuery);