import React from 'react';
import { connect } from 'react-redux';
import { MembershipUserTable } from './components';

const Membership = () => {
    return (
        <div>
            <MembershipUserTable />
        </div >
    );
};

export default connect(null, null)(Membership);