import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getDefaultValues, getPriceFormula, runPriceCalculation } from '../../../redux/actions';
import {
    FormLabel,
    MenuItem,
    Select,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    OutlinedInput,
    Input, Checkbox, ListItemText,
    IconButton,
    Button, TextField
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '10px 29px 29px 29px',
        margin: '30px 0px 30px'
    },
    container: {
        paddingTop: 18,
        width: '100%'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30,
        width: '100%'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    gridContainer: {
        paddingRight: 15,
        marginBottom: 20
    },
    label: {
        // marginTop: 10,
        fontSize: theme.typography.h3.fontSize,
        color: 'grey',
        marginBottom: 5
    },
    select: {
        color: '#1675e0',
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 36,
        padding: 0,
        width: "100%"
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    }
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const PriceCalculationForm = props => {

    const classes = useStyles();
    const [formula, setFormula] = React.useState([]);
    const [formulaList, setFormulaList] = React.useState([]);
    const [aggregationPeriod, setAggregationPeriod] = React.useState('');
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [addeligiblity, setAddEligibility] = React.useState(1)
    const [validItem, setValidItem] = React.useState(true)
    const [formData, setFormData] = React.useState([])
    const [attributeNameArray, setAttributeNameArray] = React.useState([])
    const [selected, setSelected] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const optionData = ['EQUAL', 'NOT EQUAL', 'RANGE', 'NOT RANGE']
    var eligibility = { attribute_names: [], option: '', sequence: 0, attribute_values: [] }
    const isAllSelected =
        formulaList && selected && formulaList.length > 0 && selected.length === formulaList.length;

    useEffect(() => {
        var temp = []
        if (props.formulaList && props.formulaList.records) {
            props.formulaList.records.map(item => {
                temp.push(item.formula_name)
            })
            setFormulaList(temp);
        }
    }, [props.formulaList]);
    function handleSubmit() {

        formData.map((item, index) => {
            if (item.attribute_names.length === 0 && item.attribute_values.length === 0 && item.option === '' && item.sequence === 0) {
                delete formData[index]
            }

        })
        var data = {
            "formula": formula,
            "aggregation_period": aggregationPeriod,
            "start_date": Moment(startDate).local(),
            "end_date": Moment(endDate).local(),
            "filters": formData.filter(value => Object.keys(value).length != 0)
        }
        props.onSubmit(data)
    }

    useEffect(() => {
        props.getAttributeData('attribute_name');
        props.getFormulaList(1, 0)
        setFormData([eligibility])
    }, []);

    useEffect(() => {
        var newArray = []
        if (props.attributeData && props.attributeData.records && props.attributeData.records.length > 0) {
            props.attributeData.records
                // .filter((item) => item.app_source_type === 'Pricing')
                .map((item) => {
                    if (item.app_source_type === 'Pricing' && item.field_id === 'attribute_name')
                        newArray.push(item.drop_down_value_keys)
                })

            setAttributeNameArray(newArray[0])
            // setAttributeNameArray(props.attributeData.records[0].drop_down_value_keys);
        }
    }, [props.attributeData]);


    const handleStartDate = (value) => {
        setStartDate(value);
    }
    const handleEndDate = (value) => {
        setEndDate(value);
    }
    function handleDeleteLocally(index) {
        setFormData(item => item.filter((item, i) => i !== index));
        setAddEligibility(addeligiblity - 1)
        checkvalues()
    }
    function handleChangeValue(field, index, value) {

        if (field == 'attribute_values' || field == 'attribute_names') {
            value = [value]
        }
        formData[index][field] = value
        setFormData([...formData])
        checkvalues()
    }
    function handleOnAdd() {
        var newValue = formData
        newValue = [...newValue, eligibility]
        setFormData(newValue);
        setAddEligibility(addeligiblity + 1)
        setValidItem(false)
    }

    function checkvalues() {
        formData.map((item, index) => {
            if (item.attribute_name.length === 0 || item.attribute_value.length === 0) {
                setValidItem(false)
            }
            else
                setValidItem(true)

        })

    }

    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === formulaList.length ? [] : formulaList);
        }
        else
            setSelected(newSelected)

    }
    function onAdd() {

        setFormula(selected)
        setOpen(false)
        setSelected([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])

    }
    function handleOpen() {
        setSelected(formula)
        setOpen(true)
    }

    return (
        <div className={classes.rootDiv}>
            <Grid container className={classes.root}>
                <Grid container style={{ margin: 10, padding: 20, border: '1px solid #E6E6E6', borderRadius: 5 }}>

                    <Grid
                        item
                        md={3}
                        xs={12}
                        className={classes.gridContainer}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel className={classes.label} required>
                                Formula Name
                            </FormLabel>
                            <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                multiple
                                value={formula}
                                style={{ maxHeight: "50px", height: 36, width: '100%', textTransform: 'capitalize' }}
                                input={<OutlinedInput />}
                                onOpen={() => handleOpen()}
                                onClose={() => setOpen(false)}
                                open={open}
                                renderValue={(appType) => {
                                    var qualifier = []
                                    appType
                                        .map(item => {
                                            qualifier.push(item.replace(/_/g, ' '))

                                        })
                                    return qualifier.join(", ")
                                }}
                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={isAllSelected} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {formulaList && formulaList
                                    .map(item => {
                                        return (
                                            <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                            </MenuItem>
                                        );
                                    })}
                                <div className={classes.dropdownbottom}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>


                            </Select>

                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        className={classes.gridContainer}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel
                                className={classes.label} required>
                                Aggregation Period
                            </FormLabel>
                            <Select
                                value={aggregationPeriod}
                                onChange={(event) => setAggregationPeriod(event.target.value)}
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                <MenuItem value={'monthly'}>
                                    Monthly
                                </MenuItem>

                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        className={classes.gridContainer}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel required className={classes.label}>
                                Start Date
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container justifyContent="space-around">
                                    <DatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '0px 11px 0px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                width: '100%',
                                                height: 37
                                            }
                                        }}
                                        value={startDate}
                                        onChange={handleStartDate}
                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                        format="MM/DD/YYYY"
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        className={classes.gridContainer}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel required className={classes.label}>
                                End Date
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container justifyContent="space-around">
                                    <DatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '0px 11px 0px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                width: '100%',
                                                height: 37
                                            }
                                        }}
                                        value={endDate}
                                        minDate={startDate ? startDate : null}
                                        onChange={handleEndDate}
                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                        format="MM/DD/YYYY"
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </div>

                    </Grid>
                </Grid>
                <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.gridContainer}
                >
                    <div className={classes.container} >
                        <TableContainer>
                            <Table>
                                <TableHead >
                                    <TableRow>
                                        <TableCell align='center'>Attribute Name <span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center'>Attribute Value<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center' style={{ width: '160px' }}>Option<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center' style={{ width: '160px' }}>Sequence<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center' >Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }} >
                                    {(() => {
                                        let td = [];
                                        formData.map((formItem, i) => {
                                            td.push(<>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <Select
                                                            value={formItem.attribute_names ? formItem.attribute_names : ''}
                                                            onChange={(event) => handleChangeValue('attribute_names', i, event.target.value)}
                                                            style={{ width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            {attributeNameArray
                                                                .map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                            {item.replace(/_/g, ' ')}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <ChipInput
                                                            classes={{
                                                                root: classes.rootContainer,
                                                                chip: classes.chip,
                                                                input: classes.input,
                                                                inputRoot: classes.inputRoot,
                                                                label: classes.chipLabel,
                                                                chipContainer: classes.chipContainer,
                                                            }}
                                                            value={formItem['attribute_values'] ? formItem['attribute_values'] : ''}
                                                            onAdd={(chips) => handleChangeValue('attribute_values', i, chips)}
                                                            disableUnderline={true}
                                                        />

                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            value={formItem.option ? formItem.option : ''}
                                                            onChange={(event) => handleChangeValue('option', i, event.target.value)}
                                                            style={{ width: '100%', marginTop: 10 }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            {optionData
                                                                .map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item} key={index}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <OutlinedInput
                                                            value={formItem.sequence != 0 ? formItem.sequence : ''}
                                                            style={{ width: '100%', marginTop: 10 }}
                                                            inputProps={{ min: "0", step: "1" }}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(event) => handleChangeValue('sequence', i, parseInt(event.target.value))}
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() => handleDeleteLocally(i)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForever />
                                                        </IconButton>
                                                        <IconButton
                                                            style={{ visibility: i == addeligiblity - 1 ? 'visible' : 'hidden', padding: 0 }}
                                                            onClick={handleOnAdd}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <AddBoxOutlined />
                                                        </IconButton>

                                                    </TableCell>
                                                </StyledTableRow>

                                            </>);
                                        })
                                        return td;
                                    })()}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                        disabled={startDate && endDate && formula && aggregationPeriod ? false : true}>

                        {props.loading ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                            :
                            'Run'
                        }
                    </Button>
                </div>
            </Grid>


        </div >
    );

};

const mapStateToProps = state => {
    return {
        formulaList: state.pricingData.priceFormulaList,
        attributeData: state.addMultipleConfigurationData.attributeNameData,
        loading: state.pricingData.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFormulaList: (page, limit) => dispatch(getPriceFormula(page, limit)),
        onSubmit: (data) => dispatch(runPriceCalculation(data)),
        getAttributeData: (field) => dispatch(getDefaultValues('noKeyWords', null, 'on', field)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCalculationForm);

