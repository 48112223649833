import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { Accrual, Payment, AdditionalData } from './containers'
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    
}));
const FinancialPostings = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();

    useImperativeHandle(ref, () => ({
        validationCheck() {
            childRef1.current.validationCheck();
            childRef2.current.validationCheck();
        },
        getValues() {
            var accuralValues = childRef1.current.getValues();
            var paymentValues = childRef2.current.getValues();
            var flexValues = childRef3.current.getValues();
            var combined = Object.assign({}, accuralValues, paymentValues, flexValues);
            return combined;
        }
    }));

    function handleChangeAccrual(key, value) {
        props.onChange(key, value);
    }
    function handleChangePayment(key, value) {
        props.onChange(key, value);
    }
    return (
        <div className={clsx(classes.root, className)}>
            {(props.postingSchema && props.postingSchema != 'Payment Only' && props.postingSchema != 'No Postings') &&
                <Accrual onChange={handleChangeAccrual} savetriggered={props.savetriggered} ref={childRef1} data={props.data} postingSchema={props.postingSchema} fieldData={props.fieldData}/>
            }
            {(props.postingSchema && props.postingSchema != 'Accrual Only' && props.postingSchema != 'No Postings') &&
                <Payment onChange={handleChangePayment} savetriggered={props.savetriggered} ref={childRef2} data={props.data} fieldData={props.fieldData}/>
            }
            {(props.postingSchema && props.postingSchema != 'No Postings') &&
                <AdditionalData data={props.data} fieldData={props.fieldData} />
            }
        </div>
    );

});


export default connect(null,null)(FinancialPostings);