import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Select,
    MenuItem,
    FormControl,
    FormLabel,
} from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    select: {
        width: '100%',
        // marginTop: 5
    },
    selectedItem: {
        width: '100%'
    },
    error: {
        border: '1px solid red !important'
    },
    disabled: {
        color: 'black !important'
    },
    menuPaper: {
        maxHeight: 300
    },
    subheader: {
        marginBottom: 8
    },
    headContainer: {
        whiteSpace: 'nowrap',
        paddingRight: 10,
        color: theme.palette.text.grey
    }
}));

const DropdownArray = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const [data, setData] = React.useState(props.defaultValue ? 0 : '');
    const [changedFlag, setChangedFlag] = React.useState(0);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    }
    const handleChange = (event) => {
        setData(event.target.value);
        setChangedFlag(1);
        props.onChange(event.target.value);
    };
    const reset = () => {
        setData('');
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useEffect(() => {
        setData(props.defaultValue)
    }, [props.defaultValue]);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : data
        };
    });

    return (
        <div>
            {props.twoline ?
                <Grid container className={classes.root}>
                    <Grid
                        item
                        style={{
                            width: '100%',
                        }}
                    >
                        {props.heading &&
                            <FormLabel {...props} error={false} disabled={false} required={props.required} className={classes.subheader} >
                                {props.heading ? props.heading : ''}
                            </FormLabel>
                        }
                        {/* <FormControl {...props}> */}
                        <Select
                            id={props.id}
                            onChange={handleChange}
                            value={!changedFlag && props.prevalue ? props.prevalue : data}
                            displayEmpty
                            classes={{
                                selectMenu: props.class ? props.class : classes.selectedItem
                            }}
                            disabled={props.disabled ? props.disabled : false}
                            className={clsx({
                                [classes.select]: true,
                            })}
                            style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}
                            MenuProps={{
                                ...MenuProps, autoFocus: true

                            }}
                        >
                            {props.placeholder ?
                                <MenuItem value="" style={{ height: 30, textTransform: props.capitalize ? 'capitalize' : 'none' }} >
                                    {props.placeholder}
                                </MenuItem>
                                :
                                <MenuItem value="" style={{ height: 30 }} >
                                </MenuItem>
                            }
                            {props.data && props.data.length > 0 && props.data.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index} name={item} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                        {item.replace(/_/g, ' ')}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {/* </FormControl> */}
                    </Grid>
                </Grid >
                :
                <Grid container className={clsx(classes.root, className)}>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        //style={{ display: 'flex' }}
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <FormLabel {...props} required={props.required} className={classes.headContainer}>
                                {props.heading}
                            </FormLabel>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <Select
                                disableUnderline
                                onChange={handleChange}
                                displayEmpty
                                classes={{
                                    selectMenu: props.class ? props.class : classes.selectedItem,
                                    disabled: classes.disabled
                                }}
                                disabled={props.disabled}
                                value={!changedFlag && props.prevalue ? props.prevalue : data}
                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                style={{ width: '100%' }}
                            >
                                {props.placeholder &&
                                    <MenuItem value="">
                                        {props.placeholder}
                                    </MenuItem>
                                }
                                {props.data.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                            {item.replace(/_/g, ' ')}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid >
            }
        </div>
    );

});

export default DropdownArray;