import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const TransactionData = (props) => {
    const handleClick = (pge) => {
        history.push(pge)
    }

    const application = sessionStorage.getItem("application");
    const hasRight = (right) => {
        const user = secureLocalStorage.getItem("dYtz");
        return (
            user.role.rights &&
            user.role.rights.includes(`${right}-${application}`)
        );
    };

    const hasUploadSalesRight = hasRight("post_direct_sales_data");
    const hasBuildSalesRight = hasRight("build_direct_sales_data");
    const hasDirectSalesDataRight = hasRight("get_direct_sales_data");
    const hasUploadExternalRight = hasRight("get_upload_external_calculations");
    const hasUploadExtRight = hasRight("get_upload_external_postings");
    const hasUploadIndirectSalesRight = hasRight("post_indirect_sales_data");
    const hasUploadBuildIndirectSalesRight = hasRight("build_indirect_sales_data");
    const hasUploadDisplayIndirectSalesRight = hasRight("get_indirect_sales_data");
    const hasUploadPurchaseDataRight = hasRight("post_purchase_data");
    const hasBuildPurchaseRight = hasRight("build_purchase_data");
    const hasPurchaseRight = hasRight("get_purchase_data");
    const hasCommitmentDataRight = hasRight("get_commitment_data");
    const hasInventoryDataRight = hasRight("get_inventory_data");
    const hasDemandSupplyDataRight = hasRight("get_demand_supply");
    const hasCompetitorDataRight = hasRight("get_competitor_data");
    const hasOnDemandQueryRight = hasRight("get_report");
    const hasPredefinedQueryRight = hasRight("get_predefined_query");
    const hasBatchJobRight = hasRight("get_batch_job");
    const hasBatchJobStatusRight = hasRight("get_batch_job_status");

    const menuItems = [
        {
            'Direct - Sales Data': [
                // { 'Upload Sales Data': '/sales-inovoice-query' },
                // { 'Build Sales Data': '/build-sales-invoice' },
                // { 'Display Sales Data': '/display-sales-invoice' },
                // { 'Upload External Calculations': '/upload-external-calculation-main' },
                // { 'Upload External Postings': '/upload-external-posting-main' },
                ...(hasUploadSalesRight ? [{ 'Upload Sales Data': '/sales-inovoice-query' }] : []),
                ...(hasBuildSalesRight ? [{ 'Build Sales Data': '/build-sales-invoice' }] : []),
                ...(hasDirectSalesDataRight ? [{ 'Display Sales Data': '/display-sales-invoice' }] : []),
                ...(hasUploadExternalRight ? [{ 'Upload External Calculations': '/upload-external-calculation-main' }] : []),
                ...(hasUploadExtRight ? [{ 'Upload External Postings': '/upload-external-posting-main' }] : [])

            ]
        },
        {
            'Indirect - Sales Data': [
                // { 'Upload Indirect Sales Data': '/indirect-sales-inovoice-query' },
                //{ 'Build Indirect Sales Data': '/build-indirect-sales-invoice' },
                //{ 'Display Indirect Sales Data': '/display-indirect-sales-invoice' },
                ...(hasUploadIndirectSalesRight ? [{ 'Upload Indirect Sales Data': '/indirect-sales-inovoice-query' }] : []),
                ...(hasUploadBuildIndirectSalesRight ? [{ 'Build Indirect Sales Data': '/build-indirect-sales-invoice' }] : []),
                ...(hasUploadDisplayIndirectSalesRight ? [{ 'Display Indirect Sales Data': '/display-indirect-sales-invoice' }] : []),


            ]
        },
        {
            'Purchase Data': [
                // { 'Upload Purchase Data': '/purchase-invoice' },
                // { 'Build Purchase Data': '/build-purchase-invoice' },
                // { 'Display Purchase Data': '/display-purchase-invoice' },
                ...(hasUploadPurchaseDataRight ? [{ 'Upload Purchase Data': '/purchase-invoice' }] : []),
                ...(hasBuildPurchaseRight ? [{ 'Build Purchase Data': '/build-purchase-invoice' }] : []),
                ...(hasPurchaseRight ? [{ 'Display Purchase Data': '/display-purchase-invoice' }] : []),

            ]
        },
        // { 'Commitment Data': '/target-invoice-query' },
        // { 'Inventory Data': '/inventory-data' },
        // { 'Demand Supply': '/demand-supply' },
        // { 'Competitor Data': '/competitor-data' },
        ...(hasCommitmentDataRight ? [{ 'Commitment Data': '/target-invoice-query' }] : []),
        ...(hasInventoryDataRight ? [{ 'Inventory Data': '/inventory-data' }] : []),
        ...(hasDemandSupplyDataRight ? [{ 'Demand Supply': '/demand-supply' }] : []),
        ...(hasCompetitorDataRight ? [{ 'Competitor Data': '/competitor-data' }] : []),

        {
            'Reporting & Analytics': [
                // { 'On Demand Query': '/dynamic-query' },
                // { 'Predefined Query': '/predefined-query' },
                ...(hasOnDemandQueryRight ? [{ 'On Demand Query': '/dynamic-query' }] : []),
                ...(hasPredefinedQueryRight ? [{ 'Predefined Query': '/predefined-query' }] : [])
            ]
        },
        {
            'Utilities': [
                // { 'Batch Job': '/transaction-data-batchjob-setup' },
                // { 'Batch Job Status': '/transaction-data-batchjob-status' }
                ...(hasBatchJobRight ? [{ 'Batch Job': '/transaction-data-batchjob-setup' }] : []),
                ...(hasBatchJobStatusRight ? [{ 'Batch Job Status': '/transaction-data-batchjob-status' }] : [])
            ]
        }
    ]

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionData);