import React, { useEffect, useRef, useMemo } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select,
    CardHeader
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import { addAccrualDesigner, getDefaultValues, getAllApprovalDesigner, getAccrualDesignerSingleItem, allowedApps, getLblDispDesFieldValue, getDefaultValuesAllData } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
var editableId;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 40
    },
    inputTwoLine: {
        marginTop: 2,
        width: '100%',
        paddingLeft: 10,
        height: 40,

    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    dropdownContainer: {
        marginTop: 17
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2rem',
        marginBottom: '1.5rem',
        marginLeft: '0rem',
        // marginRight: '1rem'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        // display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        // display: 'flex'
    },
}));

const EditAccrualDesigner = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [tierProjection, setTierProjection] = React.useState('');
    const [assignmentNumber, setAssignmentNumber] = React.useState('');
    const [referenceNumber, setReferenceNumber] = React.useState('');
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [accrualAggregation, setAccrualAggregation] = React.useState('');
    const [accrualLevelArray, setaccrualLevelArray] = React.useState([]);
    const [approvalContractsContractArray, setApprovalContractsContractArray] = React.useState([]);
    const [reversalDocumentTypeArray, setReversalDocumentTypeArray] = React.useState([]);
    const [formFields, setFormFields] = React.useState({});
    const targetSystemArray = ['SAP', 'Oracle', 'Infor'];
    const documentAndPricingDateArray = ['System Date', 'Posting Date'];
    const paymentPartnersArray = ['Sold to Party', 'Ship to Party', 'Payer', 'Bill to Party'];
    const tierProjectionArray = ['Yes', 'No'];
    const referenceNumberArray = { 'contract_number': 'Contract Number', 'contract_internal_description': 'Contract Internal Description' };
    const assignmentNumberArray = { 'contract_type': 'Contract Type', 'contract_number': 'Contract Number', 'contract_internal_description': 'Contract Internal Description' };
    const [contractType, setContractType] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    const [currentContractArray, setCurrentContractArray] = React.useState([]);
    const [targetSystem, setTargetSystem] = React.useState('');
    const [documentType, setDocumentType] = React.useState('');
    const [documentDate, setDocumentDate] = React.useState('');
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [reversalDocumentType, setReversalDocumentType] = React.useState('');
    const [creditAccount, setCreditAccount] = React.useState('');
    const [debitAccount, setDebitAccount] = React.useState('');
    const [creditProfitCenter, setCreditProfitCenter] = React.useState('');
    const [debitProfitCenter, setDebitProfitCenter] = React.useState('');
    const [primaryAnchorArray, setPrimaryAnchorArray] = React.useState([]); //'Contract Type', 'Contract Group', 'Contract Subgroup', 'Calculation Method', 'Contract Objective', 'Contract Strategy'
    const [primaryAnchor, setPrimaryAnchor] = React.useState('');
    const [dynamicField, setDynamicField] = React.useState([]);
    const [flag, setFlag] = React.useState(false)
    const [sourceAttribute, setSourceAttribute] = React.useState('');
    const [profitCenterSource, setProfitCenterSource] = React.useState('');
    const [clearingAccount, setClearingAccount] = React.useState('');
    const [gLAccount, setGLAccount] = React.useState('');
    const [clearingProfitCenter, setClearingProfitCenter] = React.useState('');
    const [profitCenter, setProfitCenter] = React.useState('');
    const [attributeValue, setAttributeValue] = React.useState('');
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const [option, setOption] = React.useState('');
    const [pLAccountStore, setPLAccountStore] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [copaAttribute, setCopaAttribute] = React.useState('');
    const [copaAttribute2, setCopaAttribute2] = React.useState('');
    const [copaAttribute3, setCopaAttribute3] = React.useState('');
    const [copaAttribute4, setCopaAttribute4] = React.useState('');
    const [copaAttribute5, setCopaAttribute5] = React.useState('');
    const [copaAttribute6, setCopaAttribute6] = React.useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getAccrualDesignerSingleItem(appId);
    }, []);

    useEffect(() => {
        if (props.items) {
            setUserStore([props.items])
            setApplicationType(props.items['app_type']);
            setContractType(props.items[props.items['primary_anchor']]);
            // setAccrualLevel(items['accrual_level']);
            // setTargetSystem(items['target_system']);
            setPrimaryAnchor(props.items['primary_anchor'])
            setDynamicField(props.items[props.items['primary_anchor']])
            setDocumentType(props.items['document_type']);
            setDocumentDate(props.items['document_date']);
            // setTierProjection(props.items['tier_projection']);
            setReferenceNumber(props.items['reference_number']);
            setAssignmentNumber(props.items['assignment_number']);
            // setAccrualAggregation(props.items['accrual_aggregation']);
            setReversalDocumentType(props.items['reversal_document_type']);
            // setCreditAccount(props.items['credit_account']);
            // setDebitAccount(props.items['debit_account']);
            // setCreditProfitCenter(props.items['credit_profit_center']);
            // setDebitProfitCenter(props.items['d  ebit_profit_center']);
            props.onLoadingDefault(props.items['app_type'], '', '', '', 'Contract Designer');
            if (props.items['multiple_attribute_filters'] && props.items['multiple_attribute_filters'].length > 0) {
                setPLAccountStore(props.items['multiple_attribute_filters'])
            }
            setProfitCenterSource(props.items['profit_center_source'])
            setProfitCenter(props.items['profit_center'])
            setSourceDataType(props.items['source_data_type'])
            setClearingAccount(props.items['clearing_account'])
            setClearingProfitCenter(props.items['clearing_profit_center'])
            setCopaAttribute(props.items['copa_attribute01'])
            setCopaAttribute2(props.items['copa_attribute02'])
            setCopaAttribute3(props.items['copa_attribute03'])
            setCopaAttribute4(props.items['copa_attribute04'])
            setCopaAttribute5(props.items['copa_attribute05'])
            setCopaAttribute6(props.items['copa_attribute06'])
            var data = {
                "data_source": "contract_header",
                "application_type": props.items['app_type']
            }
            props.fieldDesigner(data, 'post');
            setFlag(true)
            primaryAnchorArray && Object.values(primaryAnchorArray)
                .map(item => {
                    if (item.key == primaryAnchor)
                        setDynamicField(item)
                })
        }
    }, [props.items]);

    useEffect(() => {

        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'accrual_level') {
                    setaccrualLevelArray(item);
                }
                // if (item.field_id === 'contract_type' && item.app_source_type === applicationType) {
                //     setContractTypeArray(item);
                // }
                if (item.field_id === 'reversal_document_type') {
                    setReversalDocumentTypeArray(item);
                }
            })
        }
    }, [props.dropdownData]);
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setFormFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    useEffect(() => {
        if (applicationType && sourceDataType) {
            props.getDefaultValuesAllData('attribute_name', sourceDataType, applicationType);
        }

    }, [applicationType, sourceDataType]);
    useEffect(() => {
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {

            setKeyValuePairObjectNew(props.formFieldsAllData.records[0].label_names)
        }
    }, [props.formFieldsAllData])
    // useEffect(() => {
    //     if (props.dropdownData && props.dropdownData.field_label_attributes && props.dropdownData.field_label_attributes.contract_type) {
    //         const contractTypeValues = props.dropdownData.field_label_attributes.contract_type.drop_down_values || [];
    //         setContractTypeArray(contractTypeValues);
    //     }
    // }, [props.dropdownData]);
    const handlePrimaryAnchor = (e) => {
        setPrimaryAnchor(e.target.value)
        setFlag(true)

    }
    useEffect(() => {
        primaryAnchorArray && Object.values(primaryAnchorArray)
            .map(item => {
                if (item.key == primaryAnchor)
                    setDynamicField(item)
            })
    }, [dynamicField, primaryAnchor, primaryAnchorArray])
    useEffect(() => {
        var newArray = []
        if (props.dropdownData) {
            Object.values(props.dropdownData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    // if (item.mandatory || item.sort_details) {
                    if (item.key == 'contract_objective' || item.key == 'contract_group' || item.key == 'contract_strategy' || item.key == 'contract_type' || item.key == 'contract_sub_group' || item.key == 'calculation_method') {
                        newArray.push(item)
                    }
                    if (item.key == 'source_data_type') {
                        setSourceDataTypeArray(item)
                    }
                })
            setPrimaryAnchorArray(newArray.sort((a, b) => {
                return a.sort_details - b.sort_details;
            }))
        }
    }, [props.dropdownData]);

    const editsourceAttributeData = (item) => {
        const index = pLAccountStore.indexOf(item);
        setEditIndex(index);
        setEditMode(true);
        setSourceAttribute(item.source_attribute);
        setOption(item.option);
        setAttributeValue(item.attribute_value);
        setGLAccount(item.gl_account);
    }
    const handleDeleteLocally = (index) => {
        setPLAccountStore(item => item.filter((item, i) => i !== index));
    }
    const handleApplicationType = (newValue) => {
        setApplicationType(newValue.target.value);
        setContractType('')
        props.onLoadingDefault(newValue.target.value, '', '', '', 'Contract Designer');
        var data = {
            "data_source": "contract_header",
            "application_type": newValue.target.value
        }
        props.fieldDesigner(data, 'post');
    }

    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])
    function handleClear() {
        setApplicationType('');
        setDocumentType('');
        setDocumentDate('');
        setReferenceNumber('');
        setAssignmentNumber('');
        setProfitCenterSource('');
        setClearingAccount('');
        setReversalDocumentType('');
        setClearingProfitCenter('')
        setProfitCenter('')
        setContractType('')
        setSourceDataType('')
        setPrimaryAnchor('');
        setFlag(false)
        setCopaAttribute('')
        setCopaAttribute2('')
        setCopaAttribute3('')
        setCopaAttribute4('')
        setCopaAttribute5('')
        setCopaAttribute6('')
        handleRemove();
    }
    function handleOnSubmit() {
        var data = {
            "app_type": applicationType,
            "source_data_type": sourceDataType,
            "document_type": documentType,
            "document_date": documentDate,
            "reference_number": referenceNumber,
            "assignment_number": assignmentNumber,

            "reversal_document_type": reversalDocumentType.toString(),
            "clearing_account": clearingAccount,
            "clearing_profit_center": clearingProfitCenter,
            "profit_center_source": profitCenterSource,
            "primary_anchor": primaryAnchor,
            [primaryAnchor]: contractType,
            "profit_center": profitCenter,
            "multiple_attribute_filters": pLAccountStore,
            "copa_attribute01": copaAttribute,
            "copa_attribute02": copaAttribute2,
            "copa_attribute03": copaAttribute3,
            "copa_attribute04": copaAttribute4,
            "copa_attribute05": copaAttribute5,
            "copa_attribute06": copaAttribute6,
            // "contract_type": contractType,
            // "accrual_level": accrualLevel,
            // "target_system": targetSystem,
            // "tier_projection": sourceAttribute,
            // "gl_account": gLAccount,
            // "debit_profit_center": attributeValue,



        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(data, 'edit', editableId);

        handleClear();
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    function handleSubmit(id) {
        var data = {
            "option": option,
            "gl_account": gLAccount,
            "attribute_value": attributeValue,
            "source_attribute": sourceAttribute,
        };
        console.log(id)
        if (id || id === 0) {
            var editedArray = [...pLAccountStore];
            editedArray[editIndex] = data;
            setPLAccountStore(editedArray);
            // setPLAccountStore([...pLAccountStore, data]);
        } else {
            setPLAccountStore([...pLAccountStore, data]);
        }
        handleRemove();
    }
    function handleRemove() {
        setEditMode(false);
        setOption('');
        setSourceAttribute('');
        setAttributeValue('')
        setGLAccount('')
    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/accrual-designer'
                    >
                        Accrual Designer
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Edit Accrual</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    className={classes.dropdownContainer}
                                    style={{ marginTop: 0 }}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Application Type
                                    </FormLabel>
                                    <Select
                                        value={applicationType}
                                        onChange={handleApplicationType}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {allowedApps
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        {sourceDataTypeArray.current}
                                    </FormLabel>
                                    <Select
                                        value={sourceDataType}
                                        onChange={(e) => setSourceDataType(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {sourceDataTypeArray.drop_down_reference_value && sourceDataTypeArray.drop_down_reference_value.length > 0 && sourceDataTypeArray.drop_down_reference_value.map((sourceDataTypeArray, index) => {
                                            return (
                                                <MenuItem value={sourceDataTypeArray.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {sourceDataTypeArray.desc}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    className={classes.dropdownContainer}
                                    style={{ marginTop: 0 }}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Primary Anchor
                                    </FormLabel>
                                    <Select
                                        value={primaryAnchor}
                                        onChange={handlePrimaryAnchor}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {primaryAnchorArray && Object.values(primaryAnchorArray)
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem value={item.key} key={index}>
                                                        {item.current}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                {dynamicField &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        style={{ display: flag ? 'block' : 'none' }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            {dynamicField.current}
                                        </FormLabel>
                                        {dynamicField.drop_down_reference_value && dynamicField.drop_down_reference_value.length > 0 ?
                                            <Select
                                                value={contractType}
                                                onChange={(e) => setContractType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {dynamicField.drop_down_reference_value.map((dynamicField, index) => {
                                                    return (
                                                        <MenuItem value={dynamicField.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {dynamicField.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            :
                                            <OutlinedInput
                                                value={contractType}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setContractType(e.target.value)} />}

                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Document Type
                                    </FormLabel>
                                    <OutlinedInput
                                        value={documentType}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setDocumentType(e.target.value)} />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Reversal Document Type
                                    </FormLabel>
                                    <OutlinedInput
                                        value={reversalDocumentType}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setReversalDocumentType(e.target.value)} />

                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Document Date
                                    </FormLabel>
                                    <Select
                                        value={documentDate}
                                        onChange={(e) => setDocumentDate(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {documentAndPricingDateArray &&
                                            documentAndPricingDateArray
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} >
                                        Reference Number
                                    </FormLabel>
                                    <Select
                                        value={referenceNumber}
                                        onChange={(e) => setReferenceNumber(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {referenceNumberArray &&
                                            Object.entries(referenceNumberArray)
                                                .map(([key, value], index) => {
                                                    return (
                                                        <MenuItem value={key} key={index}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} >
                                        Assignment Number
                                    </FormLabel>
                                    <Select
                                        value={assignmentNumber}
                                        onChange={(e) => setAssignmentNumber(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {assignmentNumberArray &&
                                            Object.entries(assignmentNumberArray)
                                                .map(([key, value], index) => {
                                                    return (
                                                        <MenuItem value={key} key={index}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        Clearing Account
                                    </FormLabel>
                                    <OutlinedInput
                                        value={clearingAccount}
                                        //inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setClearingAccount(e.target.value)} />
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} >
                                        Clearing Profit Center
                                    </FormLabel>
                                    <OutlinedInput
                                        value={clearingProfitCenter}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setClearingProfitCenter(e.target.value)} />
                                </Grid>
                            </Grid>
                            <CardHeader
                                title="COPA Attributes"
                                titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                                className={classes.titleSetting}
                            />
                            <Grid container style={{ marginTop: '-2.5rem' }} spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        COPA Attribute 1
                                    </FormLabel>
                                    <Select
                                        value={copaAttribute}
                                        onChange={(e) => setCopaAttribute(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                            Object.entries(keyValuePairObjectNew)
                                                .sort()
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                > <FormLabel className={classes.formLabel}>
                                        COPA Attribute 2
                                    </FormLabel>
                                    <Select
                                        value={copaAttribute2}
                                        onChange={(e) => setCopaAttribute2(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                            Object.entries(keyValuePairObjectNew)
                                                .sort()
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        COPA Attribute 3
                                    </FormLabel>
                                    <Select
                                        value={copaAttribute3}
                                        onChange={(e) => setCopaAttribute3(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                            Object.entries(keyValuePairObjectNew)
                                                .sort()
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                ><FormLabel className={classes.formLabel}>
                                        COPA Attribute 4
                                    </FormLabel>
                                    <Select
                                        value={copaAttribute4}
                                        onChange={(e) => setCopaAttribute4(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                            Object.entries(keyValuePairObjectNew)
                                                .sort()
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                ><FormLabel className={classes.formLabel}>
                                        COPA Attribute 5
                                    </FormLabel>
                                    <Select
                                        value={copaAttribute5}
                                        onChange={(e) => setCopaAttribute5(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                            Object.entries(keyValuePairObjectNew)
                                                .sort()
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        COPA Attribute 6
                                    </FormLabel>
                                    <Select
                                        value={copaAttribute6}
                                        onChange={(e) => setCopaAttribute6(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                            Object.entries(keyValuePairObjectNew)
                                                .sort()
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                            </Grid>
                            <CardHeader
                                title="P&L Account and Profit Center determination"
                                titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                                className={classes.titleSetting}
                            />
                            <Grid container style={{ marginTop: '-2.5rem' }} spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Profit Center Source
                                    </FormLabel>
                                    <Select
                                        value={profitCenterSource}
                                        onChange={(e) => setProfitCenterSource(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value='Default'>
                                            Default
                                        </MenuItem>
                                        {sourceDataType === 'Direct - Sales Data' &&
                                            <MenuItem value='Direct - Sales Data'>
                                                Direct - Sales Data
                                            </MenuItem>
                                        }
                                        {sourceDataType === 'Indirect - Sales Data' &&
                                            <MenuItem value='Indirect - Sales Data'>
                                                Indirect - Sales Data
                                            </MenuItem>
                                        }
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{ display: profitCenterSource !== 'Direct - Sales Data' && profitCenterSource !== 'Indirect - Sales Data' ? 'block' : 'none' }}
                                >
                                    <FormLabel className={classes.formLabel} >
                                        Profit Center
                                    </FormLabel>
                                    <OutlinedInput
                                        value={profitCenter}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setProfitCenter(e.target.value)} />
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Source Attribute
                                    </FormLabel>
                                    <Select
                                        value={sourceAttribute}
                                        onChange={(e) => setSourceAttribute(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                            Object.entries(keyValuePairObjectNew)
                                                .sort()
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Option
                                    </FormLabel>
                                    <Select
                                        value={option}
                                        onChange={(e) => setOption(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value='EQUAL'>
                                            EQUAL
                                        </MenuItem>
                                        <MenuItem value='NOT EQUAL'>
                                            NOT EQUAL
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Attribute Value
                                    </FormLabel>
                                    <OutlinedInput
                                        value={attributeValue}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setAttributeValue(e.target.value)} />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required >
                                        GL Account
                                    </FormLabel>
                                    <OutlinedInput
                                        value={gLAccount}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setGLAccount(e.target.value)} />
                                </Grid>
                                <Grid item
                                    md={2}
                                    xs={12}
                                    style={{ marginTop: '4rem' }}
                                //container style={{ paddingBottom: 15, display: 'flex', marginTop: "-0.1rem" }} 
                                >
                                    <FormLabel></FormLabel>
                                    {editMode ?
                                        <Button
                                            color='secondary'
                                            variant="contained"
                                            startIcon={<EditIcon />}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                root: classes.checkIconContainer
                                            }}
                                            disabled={sourceAttribute && option && attributeValue && gLAccount ? false : true}
                                            onClick={() => handleSubmit(editIndex)}
                                        >
                                        </Button>
                                        :
                                        <Button
                                            color='secondary'
                                            variant="contained"
                                            startIcon={<CheckIcon />}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                root: classes.checkIconContainer
                                            }}
                                            disabled={sourceAttribute && option && attributeValue && gLAccount ? false : true}
                                            onClick={() => handleSubmit('')}
                                        >
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<DeleteForeverIcon />}
                                        classes={{
                                            startIcon: classes.startIcon,
                                            root: classes.deleteIconContainer,
                                        }}
                                        onClick={handleRemove}
                                        style={{ marginLeft: 10 }}
                                    >
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                    <div className={classes.container2} style={{ marginTop: '-0.9rem' }} >
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {pLAccountStore && pLAccountStore.length > 0 ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell classes={{ root: classes.leftAlign }}>Source Attribute</TableCell>
                                                    <TableCell align='center'>Options</TableCell>
                                                    <TableCell align='center'>Attribute Value</TableCell>
                                                    <TableCell align='center'>GL Account</TableCell>
                                                    <TableCell align='center'>Actions</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {pLAccountStore
                                                    .map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item}>
                                                                <TableCell align='center'>{keyValuePairObjectNew[item.source_attribute]}</TableCell>
                                                                <TableCell align='center'>{item.option}</TableCell>
                                                                <TableCell align='center'>{item.attribute_value}</TableCell>
                                                                <TableCell align='center'>{item.gl_account}</TableCell>
                                                                <TableCell align='center' style={{ display: 'flex' }}>
                                                                    <IconButton
                                                                        onClick={() => editsourceAttributeData(item, index, 'CB')}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() => handleDeleteLocally(index)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography variant='h4' style={{ marginTop: '2rem' }}>
                                        There is no data to show now.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button}
                            onClick={props.loadingAPI ? '' : handleOnSubmit}
                            disabled={
                                primaryAnchor && sourceDataType && documentType && documentDate && reversalDocumentType && applicationType && contractType && profitCenterSource && pLAccountStore.length > 0 ? false : true
                            }>
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Submit'
                            }
                        </Button>
                    </div>

                </div >
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(addAccrualDesigner(data, type, id)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getAccrualDesignerSingleItem: (id) => dispatch(getAccrualDesignerSingleItem(id)),
        allowedApps: () => dispatch(allowedApps()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        items: state.customerData.accrualDesignerSingle,
        loadingDropdownValues: state.addMultipleConfigurationData.loading,
        allowedAppsData: state.initialData.allowedApps,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccrualDesigner);