import Palette from '../palette';

export default {
    indicator: {
        display: 'none'
    },
    fixed: {
        borderBottom: '2px solid',
        borderBottomColor: Palette.tabs.tabActive
    },
    scrollable: {
        borderBottom: '2px solid',
        borderBottomColor: Palette.tabs.tabActive,
        marginBottom: '0px !important'
    }
}