import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { FormLabel, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { ContractCell } from "../../Dashboard/components/ContractCell";
import Moment from "moment";
import {
  GeoAnalysis,
  RebateTrends,
  NewCustomer,
  TopStats,
  RebateObjective,
  MonthalyActiveContracts,
} from "./Components";
import { getRebateDashboardData, dashboardDashBoardNames } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "-25px",
    borderRadius: 10,
    // padding: "27px 10px 27px 10px",
    padding: "10px",
    // transform: 'scale(0.5)',
    // transformOrigin: 'top left',
    width: '100%',
    // height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    height: "93vh"
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  gridContainer: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
    // paddingTop: 5,
    // height: '20rem',
    height: '350px',
    // marginTop: "20px",
  },
  formLabel: {
    width: '100%',
    paddingLeft: 10,
    paddingTop: 5,
    fontSize: "1.2rem",
    display: 'flex',
    justifyContent: 'center',
  },
}));
const RebateAnalytics = (props) => {
  const classes = useStyles();
  const [dashboardData, setDashboardData] = React.useState({});
  // useEffect(() => {
  //   // Set the default zoom level when the component mounts
  //   document.body.style.zoom = '61%'; // Adjust the zoom level as needed

  //   // Clean-up function to unset zoom when component unmounts
  //   return () => {
  //     document.body.style.zoom = ''; // Reset zoom when component unmounts
  //   };
  // }, []);

  // useEffect(() => {
  //   var data = {
  //     start_date: Moment.utc()
  //       .subtract(1, "months")
  //       .startOf("month")
  //       .format("MM/DD/YYYY"),
  //     end_date: Moment.utc()
  //       .subtract(1, "months")
  //       .endOf("month")
  //       .format("MM/DD/YYYY"),
  //   };

  //   props.getDashboardData(data);
  // }, []);
  useEffect(() => {
    if (props.dasboardList) setDashboardData(props.dasboardList);
  }, [props.dasboardList]);

  useEffect(() => {
    props.allowedDashBoardApps(sessionStorage.getItem("application"));
  }, []);

  return (
    <div className={classes.root}>
      {/* <div style={{ flex: 1, display: "flex" }}>
        <div
          style={{ flex: 0.2, padding: 4 }}
          classes={{ root: classes.contractCellContainer }}
        >
          <ContractCell
            className={classes.activeContracts}
            heading={"Sales"}
            value={
              dashboardData && dashboardData["Sales"]
                ? dashboardData["Sales"]
                : "0"
            }
          />
        </div>
        <div
          style={{ flex: 0.2, padding: 4 }}
          classes={{ root: classes.contractCellContainer }}
        >
          <ContractCell
            className={classes.newContracts}
            heading={"Rebate"}
            value={
              dashboardData && dashboardData["Rebate"]
                ? dashboardData["Rebate"]
                : "0"
            }
          />
        </div>
        <div
          style={{ flex: 0.2, padding: 4 }}
          classes={{ root: classes.contractCellContainer }}
        >
          <ContractCell
            className={classes.waitingApprovals}
            heading={"Rebate Basis"}
            value={
              dashboardData && dashboardData["IncentiveBasis"]
                ? dashboardData["IncentiveBasis"]
                : "0"
            }
          />
        </div>
        <div
          style={{ flex: 0.2, padding: 4 }}
          classes={{ root: classes.contractCellContainer }}
        >
          <ContractCell
            className={classes.expiredContracts}
            heading={"Accrual"}
            value={
              dashboardData && dashboardData["Accrual"]
                ? dashboardData["Accrual"]
                : "0"
            }
          />
        </div>
        <div
          style={{ flex: 0.2, padding: 4 }}
          classes={{ root: classes.contractCellContainer }}
        >
          <ContractCell
            className={classes.totalContracts}
            heading={"Payment"}
            value={
              dashboardData && dashboardData["Payment"]
                ? dashboardData["Payment"]
                : "0"
            }
          />
        </div>
      </div> */}
      <div>
        <Grid container spacing={0.5}>
          <Grid item md={4} xs={12} className={classes.gridContainer} style={{ marginTop: "20px" }}>
            <Typography color="primary" className={classes.formLabel} style={{ padding: "10px 0 5px 0" }}>
              {" "}
              New Customer
            </Typography>
            <div >
              <NewCustomer />
            </div>

          </Grid>
          <Grid item md={4} xs={12} className={classes.gridContainer} style={{ marginTop: "20px" }}>
            <Typography color="primary" className={classes.formLabel} style={{ padding: "10px 0 5px 0" }}>
              {" "}
              Monthly Active Contracts
            </Typography>
            <MonthalyActiveContracts />
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridContainer} style={{ marginTop: "20px" }}>
            <Typography color="primary" className={classes.formLabel} style={{ padding: "10px 0 5px 0" }}>
              {" "}
              Accrual vs Payment
            </Typography>
            <RebateTrends />
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridContainer}>
            <Typography color="primary" className={classes.formLabel} style={{ padding: "15px 0 5px 0" }}>
              Top Stat
            </Typography>
            <TopStats />
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridContainer}>
            <Typography color="primary" className={classes.formLabel} style={{ padding: "15px 0 5px 0" }}>
              Postings Distribution{" "}
            </Typography>
            <RebateObjective />
          </Grid>
          <Grid item md={4} xs={12} className={classes.gridContainer}>
            <Typography color="primary" className={classes.formLabel} style={{ padding: "15px 0 5px 0" }}>
              {" "}
              Region{" "}
            </Typography>
            <GeoAnalysis />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    dasboardList: state.initialData.dashboardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardData: (data) => dispatch(getRebateDashboardData(data)),
    allowedDashBoardApps: (type) => dispatch(dashboardDashBoardNames(type, "dashboard")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateAnalytics);
