import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Link,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem, Checkbox, Input,
    ListItemText,
} from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { AddBoxOutlined, Edit, DeleteForever, LibraryAdd, CloudUpload, SearchSharp } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getCustomerMasterData, deleteCustomerMasterData, getLblDispDesFieldValue, getQueryRefById, updateQueryRefDataMaster, getDropdownValidation } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { getCustomerMasterViewDetails } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import { NavLink as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../components/Dialog';
import HashLoader from "react-spinners/HashLoader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import MomentUtils from '@date-io/moment';
import MasterDataComponent from '../../components/Inputs/FilledComponents/MasterDataComponent';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput, SearchDropDownMasterDataNew } from "../../components/Inputs";
import RingLoader from "react-spinners/RingLoader";
import { MultiSelectDropdownKeyMaster } from '../../components/Inputs';
import RotateLoader from "react-spinners/RotateLoader";
import DynamicQueryFormRef from '../DynamicQuery/DynamicQueryFormRef';
import FilterDialoge from '../DynamicQuery/FilterDialoge';
import { toast } from 'react-toastify';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10,
        marginTop: '-1rem'
    },
    container: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    gridContainer: {
        padding: "0px 15px 0px 10px",
    },
    gridContainerOuter: {
        padding: "0px 0px 0px 0px",
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 160
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 8,
        height: 38,
        padding: 0,
        width: "98.5%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: "100%",
        backgroundColor: "white",
    },
    select1: {
        width: "100%",
        backgroundColor: "white",
        // marginTop: "-15px"
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    paper: { minWidth: window.screen.width * .50 },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    baseColor: {
        color: theme.palette.primary.main,
    },
    inputTwoLineOutlined: {
        width: '100%',
        paddingLeft: 10,
        height: 38,
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: "100%",
        textTransform: "capitalize",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        paddingBottom: 16,
        width: '100%'
    },
    spinner: {
        height: '90vh'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    multiSelect: {
        marginTop: 10
    },
    multiSelect1: {
        marginTop: 0
    },
    textInput: {
        marginTop: 8,
    },
    flex: {
        flex: 1
    },
    selectRoot: {
        padding: "0px 0px 15px 15px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const CustomerMasterSearch = props => {
    const classes = useStyles();
    const { className } = props;
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [mandatoryData, setMandatoryData] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [filterData, setFilterData] = React.useState({});
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [trigger, setTigger] = React.useState(false);
    const [filterID, setFilterID] = React.useState('');
    const [filterName, setFilterName] = React.useState("");
    const currentQueryID = "customer_master";
    const options = ["range", "not equal", "pattern"];
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    useEffect(() => {
        var data = { "data_source": "customer_master" }
        props.getLblDispDesFieldValue(data, 'post');
    }, []);

    // useEffect(() => {
    //     var data = [];
    //     if (filterData.customer_number && filterData.customer_number.filter_value) {
    //         data = filterData.customer_number.filter_value;
    //         var data1 = {
    //             key: "customer_number",
    //             check: data
    //         }
    //         props.getDropdownValuesValidation("customer_master", data1);
    //     }
    // }, [filterData.customer_number]);

    // useEffect(() => {
    //     const ParseData = JSON.parse(sessionStorage.getItem("dropdownvalidation"))
    //     if (ParseData && ParseData.not_exist && ParseData.not_exist.length > 0) {
    //         if (props.dropdownValueValidation && props.dropdownValueValidation.not_exist && props.dropdownValueValidation.not_exist.length > 0 && props.dropdownValueValidation.not_exist.length < 4) {
    //             const invalidMaterials = props.dropdownValueValidation.not_exist.join(', ');
    //             toast.error(`Customer Numbers: ${invalidMaterials} is not found in the database.`);
    //         }
    //         else if (props.dropdownValueValidation && props.dropdownValueValidation.not_exist && props.dropdownValueValidation.not_exist.length > 3) {
    //             toast.error(`Customer Numbers is not found in the database.`);
    //         }
    //     }
    // }, [props.dropdownValueValidation]);


    useEffect(() => {
        let listData = []
        let listData1 = []
        if (props.tableData && props.tableData.field_label_attributes) {
            Object.values(props.tableData.field_label_attributes)
                // .filter(item => item.key != 'key_figure_names' && item.key != 'attribute_names' && item.key != 'accrual_amount' && item.key != 'change_request_id'
                //     && item.key != 'chargeback_calculation_rules' && item.key != 'has_active_update_request' && item.key != 'is_update_approval_contract' && item.key != 'payment_amount'
                //     && item.key != 'update_contract_id' && item.key != 'update_request_closed' && item.key != 'target_rules'
                // )
                .map((item) => {
                    if (item.sort_list) {
                        listData1.push({
                            'field_id': item.key, 'sort_list': item.sort_list || 999, 'name': item.current, 'type': item.type
                            , 'drop_down_value_keys': item.type === 'DROPDOWN.SINGLE' ?
                                item.drop_down_reference_value
                                : []
                        })
                    }
                    else {
                        listData.push({
                            'field_id': item.key, 'sort_list': item.sort_list || 999, 'name': item.current, 'type': item.type
                            // , 'drop_down_value_keys': item.type === 'DROPDOWN.STRING' ?
                            , 'drop_down_value_keys': item.type === 'DROPDOWN.SINGLE' ?
                                item.drop_down_reference_value
                                : []
                        })
                    }
                })
            setAttributeArray(listData.filter((v, i, a) => a.findIndex(v2 => (v2.field_id === v.field_id)) === i))
            setMandatoryData(listData1.filter((v, i, a) => a.findIndex(v2 => (v2.field_id === v.field_id)) === i))
        }
    }, [props.tableData])

    useEffect(() => {
        var temp = [];
        var range
        if (props.predefinedFilter && props.predefinedFilter.filter_fields && filterName == props.predefinedFilter.filter_name) {
            setFilterData(props.predefinedFilter.filter_fields)
            setFilterName(props.predefinedFilter.filter_name)
            Object.values(props.predefinedFilter.filter_fields).map((item, i) => {
                temp.push(item.filter_name)
                // if (item.filter_name === "attribute_name") {
                // setAttributes(item.filter_name)
                // }
                // temp = { ...temp, [item.filter_name]: item.filter_value && item.filter_value.from ? item.filter_value.from : item.filter_value }
                // if (item.filter_value && item.filter_value.to)
                //     range = { ...range, [item.filter_name]: item.filter_value.to }
            })
            // setAttributes(temp)
            // setDynamicFieldValues(temp)
            // setDynamicFieldValuesRange(range)
        }
        else {
            // setDynamicFieldValues({})
            setFilterData({})
            // setDynamicFieldValuesRange({})
        }
    }, [props.predefinedFilter])

    // const handleOption = (e, item) => {
    //     setFilterData({
    //         ...filterData, [item.field_id]: {
    //             ...filterData[item.field_id],
    //             "filter_option": e
    //         }
    //     })
    // }
    const handleOption = (e, item) => {
        if (filterData[item.field_id] && filterData[item.field_id].filter_option !== 'range') {
            setFilterData({
                ...filterData,
                [item.field_id]: {
                    ...filterData[item.field_id],
                    filter_option: e,
                    filter_value: []
                }
            });
        } else {
            setFilterData({
                ...filterData,
                [item.field_id]: {
                    ...filterData[item.field_id],
                    filter_option: e,
                    filter_value: []
                }
            });
        }
    };
    const handleRangeFilterData = (data, item, data2) => {
        setFilterData({
            ...filterData, [item.field_id]:
            {
                ...filterData[item.field_id],
                "filter_name": item.field_id,
                "filter_value": {
                    "from": data.toString(), "to": data2
                }
            }

        })
    }

    const handleFilterData = (data, item, type) => {
        let chipData = []
        let temp = {}

        if (type == 'start_date' || type == 'end_date') {
            temp = filterData[item.field_id] ? filterData[item.field_id] : {}
            setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
        }
        if (data && data.length > 0) {
            if (type == 'chip') {
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": data // Directly assign data
                    }
                });
                // chipData = filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []
                // chipData = [...chipData, data]
                // setFilterData({
                //     ...filterData, [item.field_id]: {
                //         ...filterData[item.field_id],
                //         "filter_name": item.field_id,
                //         "filter_value": chipData
                //     }
                // })
            }
            else if (type == 'NUMERIC')
                setFilterData({
                    ...filterData, [item.field_id]:
                    {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": Number(data)
                    }
                })
            else
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": data
                    }
                })
        }

    }

    const handleDeleteFilterData = (newValue, index, item) => {
        let deleteFilterData = filterData[item.field_id]['filter_value'].filter(item => item !== newValue)
        setFilterData({
            ...filterData, [item.field_id]: {
                ...filterData[item.field_id],
                "filter_name": item.field_id,
                "filter_value": deleteFilterData


            }
        })
    }

    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === attributeArray.length ? [] : attributeArray.map((item) => item.field_id));
            setAllSelect(selected.length === attributeArray.length ? false : true)
            setSelectedDetail(selected.length === attributeArray.length ? [] : attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        setSelectedDetail([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(attributeArray && attributeArray.length > 0 && attributes.length === attributeArray.length)
        setSelectedDetail(attributesData)
    }
    const handleClickOpen = () => {
        setOpen(true)
    };

    const combinedData = [...mandatoryData, ...attributesData];
    combinedData.sort((a, b) => a.sort_list - b.sort_list);
    const handleClear = () => {
        setAttributesData([])
        setAttributes([])
        setFilterData({})
    }
    const formData = {
        "filter_fields": {
            ...filterData
        }
    };
    const handleSearch = (type) => {
        if (type === 'Save Search') {
            setOpenFilter(true)
        }
        if (type === 'Save Search & Run') {
            setOpenFilter(true)
            setTigger(true)
        }
    }
    const handleFilter = (item) => {
        setFilterID(item.id)
        setFilterName(item.filter_name)
        props.getPredefinedFilter(item.id)
    }

    const setDefaultFilterOptions = (filterData) => {
        const updatedFilterData = { ...filterData };
        Object.keys(updatedFilterData).forEach(field_id => {
            if (!updatedFilterData[field_id].filter_option || updatedFilterData[field_id].filter_option === "") {
                updatedFilterData[field_id].filter_option = "equal";
            }
        });
        return updatedFilterData;
    };
    const handleSaveAndRun = (name, type) => {
        setOpenFilter(false);
        const updatedFilterData = setDefaultFilterOptions(filterData);
        if (updatedFilterData) {
            const formData1 = {
                query_id: "customer_master",
                filter_name: name,
                filter_fields: updatedFilterData,
            };
            if (type === 'edit') {
                formData1['ID'] = filterID;
            }
            props.saveAndRun(type, formData1);
            if (trigger) {
                props.onSubmit(1, 0, 'search', formData);
                sessionStorage.setItem('search-data', JSON.stringify(formData));
            }
        }
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            const updatedFilterData = setDefaultFilterOptions(filterData);
            const formData = {
                filter_fields: {
                    ...updatedFilterData
                }
            };
            props.onSubmit(1, 0, 'search', formData);
            sessionStorage.setItem('search-data', JSON.stringify(formData));
        } else {
            setOpen(false);
        }
    };
    console.log("filter", filterData)
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <Typography color="primary" variant='h1'>Search Customer Master</Typography>
                <Grid container style={{ marginBottom: 20, marginTop: '2rem' }}>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex' }}
                    >
                        <FormLabel classes={{ root: classes.fontSetting }} style={{ marginRight: 20, paddingTop: 5 }}>
                            Select Required Filters
                        </FormLabel>
                        <Select
                            labelId="demo-mutiple-name-label"
                            id={'filter-list'}
                            multiple
                            value={attributes ? attributes : []}
                            style={{ maxHeight: "50px", width: '67.5%', color: 'grey' }}
                            input={<OutlinedInput />}
                            MenuProps={{ ...MenuProps, autoFocus: true }}
                            onOpen={() => handleOpen()}
                            onClose={() => setSelectOpen(false)}
                            open={selectOpen}
                            renderValue={(filter) => {
                                let type = []
                                filter.map(itemX => {
                                    attributeArray.filter(item => item.field_id == itemX).map((item) => {
                                        if (item.name)
                                            type.push(item.name)
                                    })

                                })
                                return type.join(',')
                            }}
                            className={classes.multiSelect1}

                        >
                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                <Checkbox
                                    color='primary'
                                    checked={allSelect} />
                                <ListItemText primary='Select All' />
                            </MenuItem>
                            {attributeArray && attributeArray.length > 0 && attributeArray
                                .sort((a, b) => a.name > b.name ? 1 : -1)
                                .map((item) => {
                                    return (
                                        <MenuItem onClick={(event) => handleSelectValues(event, item.field_id, item)} value={item.field_id} key={item.field_id} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={selected && selected.indexOf(item.field_id) > -1}
                                            />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    )
                                })}
                            <MenuItem value='' ></MenuItem>

                            <div className={classes.dropdownAction}>
                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                    Cancel
                                </Button>

                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                    Apply
                                </Button>
                            </div>
                        </Select>
                    </Grid>
                </Grid>
                <div style={{ display: 'flex' }}>
                    <div className={classes.container} style={{ width: '80%' }}>
                        {/* <div className={classes.bodyContainer} > */}
                        <Grid container>
                            {combinedData?.map((item, i) => {
                                return <>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainerOuter }}
                                        style={{ display: "flex" }}
                                    >
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{
                                                position: 'relative'
                                            }}
                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }}
                                                style={{
                                                    marginTop: '0.7rem'
                                                }}>
                                                <Typography variant="h3" color='primary' style={{
                                                    marginTop: '-0.6rem',
                                                    //  position: 'absolute'
                                                }}>{i == 0 && "Filter Name"}</Typography>
                                                {
                                                    i == 0 ? <div style={{ marginTop: '1rem' }} > {item.name} </div> : item.name
                                                }
                                            </FormLabel>
                                        </Grid>
                                        <Grid
                                            md={2}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel
                                                classes={{ root: classes.fontSetting }}
                                            >
                                                <Typography variant="h3" color='primary'>{i === 0 && "Option"}</Typography>
                                            </FormLabel>
                                            <Select
                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_option'] ? filterData[item.field_id]['filter_option'] : []}
                                                onChange={(e) => handleOption(e.target.value, item)}
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                displayEmpty
                                                style={{ marginTop: i === 0 ? '7px' : '-15px' }}
                                                className={clsx({
                                                    [classes.select1]: true,
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem,
                                                }}
                                            >
                                                <MenuItem value=""></MenuItem>
                                                {options &&
                                                    options
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem
                                                                    value={item}
                                                                    key={item}
                                                                    style={{ textTransform: "capitalize" }}
                                                                >
                                                                    {item.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                            </Select>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel
                                                classes={{ root: classes.fontSetting }}
                                            >
                                                <Typography variant="h3" color='primary'>{i == 0 && "Value"}</Typography>
                                            </FormLabel>

                                            {item.type == 'DROPDOWN.STRING' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                <div style={{ marginTop: i === 0 ? '-3px' : '-22px', }}>
                                                    <MultiSelectDropdownKeyMaster capitalize={true}
                                                        listArray={item.drop_down_value_keys} data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                                </div>
                                                :
                                                item.type == 'BOOL' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                    <div>

                                                        <Select
                                                            value={filterData[item.field_id] ? filterData[item.field_id.filter_value] : ''}
                                                            onChange={(e) => handleFilterData(e.target.value, item)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value={'true'} key={'true'}>
                                                                Yes
                                                            </MenuItem>
                                                            <MenuItem value={'false'} key={'false'}>
                                                                No
                                                            </MenuItem>
                                                        </Select>
                                                    </div>
                                                    :
                                                    item.type == 'TIME' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                        <div>
                                                            <div style={{ display: 'flex' }}>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginTop: 10,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%',
                                                                                    marginRight: 5
                                                                                }
                                                                            }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                            onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginTop: 10,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                            onChange={(e) => handleFilterData(e, item, 'end_date')}

                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>
                                                        :
                                                        item.type == "DROPDOWN.SINGLE" && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                            <div
                                                                style={{ marginTop: i === 0 ? '-3px' : '-22px', }}
                                                            >
                                                                <MultiSelectDropdownKeyMaster capitalize={true}
                                                                    listArray={item.drop_down_value_keys} data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                                            </div>
                                                            : item.field_id == "customer_number" && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                <div
                                                                    style={{ marginTop: i === 0 ? '7px' : '-19px', marginBottom: i === 0 ? '-5px' : '-22px', }}
                                                                >
                                                                    <SearchDropDownMasterDataNew id='tags-standard'
                                                                        table={"customer_number"}
                                                                        keyName={"customer_number"}
                                                                        multiple
                                                                        description={true}
                                                                        onChange={(e) =>
                                                                            handleFilterData(e, item)
                                                                        }
                                                                        prevalue={
                                                                            filterData[item.field_id]
                                                                                ? filterData[item.field_id]['filter_value']
                                                                                : []
                                                                        }
                                                                    // applicationType={sessionStorage.getItem('application')}
                                                                    // applicationType={"Customer Rebate"}
                                                                    />
                                                                </div>
                                                                : item.field_id == "customer_number" && (!filterData[item.field_id] || !filterData[item.field_id]['filter_option'] || filterData[item.field_id]['filter_option'] === '') ?
                                                                    <div
                                                                        style={{ marginTop: i === 0 ? '7px' : '-19px', marginBottom: i === 0 ? '-5px' : '-22px', }}
                                                                    >
                                                                        <SearchDropDownMasterDataNew id='tags-standard'
                                                                            table={"customer_master"}
                                                                            keyName={"customer_number"}
                                                                            multiple
                                                                            description={true}
                                                                            onChange={(e) =>
                                                                                handleFilterData(e, item)
                                                                            }
                                                                            prevalue={
                                                                                filterData[item.field_id]
                                                                                    ? filterData[item.field_id]['filter_value']
                                                                                    : []
                                                                            }
                                                                        // applicationType={sessionStorage.getItem('application')}
                                                                        // applicationType={"Customer Rebate"}
                                                                        />
                                                                    </div>
                                                                    // <div>
                                                                    //     <Select
                                                                    //         value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                    //         onChange={(e) => handleFilterData(e.target.value, item)}
                                                                    //         displayEmpty
                                                                    //         className={clsx({
                                                                    //             [classes.select]: true
                                                                    //         })}
                                                                    //         classes={{
                                                                    //             selectMenu: classes.selectedItem
                                                                    //         }}
                                                                    //     >
                                                                    //         {/* <MenuItem value="" style={{ height: 30 }}>
                                                                    //         </MenuItem>
                                                                    //         {item.drop_down_value_keys && Object.values(item.drop_down_value_keys).length > 0 && Object.values(item.drop_down_value_keys)
                                                                    //             .map((item) => {
                                                                    //                 return (
                                                                    //                     <MenuItem value={item.key} key={item.key} >
                                                                    //                         {item.desc}
                                                                    //                     </MenuItem>
                                                                    //                 )
                                                                    //             })} */}
                                                                    //         <MenuItem value="" style={{ height: 30 }}></MenuItem>
                                                                    //         {item.drop_down_value_keys && item.drop_down_value_keys.length > 0 && item.drop_down_value_keys.map((item) => (
                                                                    //             <MenuItem value={item.key} key={item.key}>
                                                                    //                 {item.desc}
                                                                    //             </MenuItem>
                                                                    //         ))}


                                                                    //     </Select>
                                                                    // </div>
                                                                    : item.type == 'NUMERIC' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                        <div style={{ marginRight: 5, marginTop: i === 0 ? '7px' : '-15px', }}>
                                                                            <OutlinedInput
                                                                                type={'number'}
                                                                                classes={{ root: classes.inputTwoLineOutlined }}
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : ''}
                                                                                onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                                            />

                                                                        </div>
                                                                        :
                                                                        !filterData[item.field_id] || !filterData[item.field_id]['filter_option'] || filterData[item.field_id]['filter_option'] === '' ?
                                                                            <div style={{ marginTop: i === 0 ? '7px' : '-14px' }}>
                                                                                <div>
                                                                                    <ChipInput
                                                                                        paste
                                                                                        data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                                        onChange={newValue => handleFilterData(newValue, item, 'chip')}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                                <div style={{ marginTop: i === 0 ? '7px' : '-14px', padding: "0px 5px 0px 0px" }}>
                                                                                    <div>
                                                                                        <ChipInput paste data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} onChange={newValue => handleFilterData(newValue, item, 'chip')} />
                                                                                        {/* <ChipInput
                                                                                classes={{
                                                                                    root: classes.rootContainer,
                                                                                    chip: classes.chip,
                                                                                    input: classes.input,
                                                                                    inputRoot: classes.inputRoot,
                                                                                    label: classes.chipLabel,
                                                                                    chipContainer: classes.chipContainer,
                                                                                }}
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                                onAdd={(chips) => handleFilterData(chips, item, 'chip')}
                                                                                onDelete={(chip, index) => handleDeleteFilterData(chip, index, item)}
                                                                                disableUnderline={true}
                                                                                className={clsx({
                                                                                    [classes.textInput]: true,
                                                                                })}
                                                                            /> */}

                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div style={{
                                                                                    marginRight: 5,
                                                                                    //  marginTop: "-15px"
                                                                                    marginTop: i === 0 ? '7px' : '-15px',
                                                                                }}>
                                                                                    <OutlinedInput
                                                                                        classes={{ root: classes.inputTwoLineOutlined }}
                                                                                        value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : ''}
                                                                                        onChange={(e) => handleRangeFilterData(e.target.value, item)}
                                                                                    />
                                                                                </div>
                                            }

                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ display: filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] == 'range' ? 'block' : 'none' }}
                                        >
                                            <FormLabel
                                                classes={{ root: classes.fontSetting }}
                                            >
                                                <Typography variant="h3" color='primary'>{i == 0 && "Range"}</Typography>
                                            </FormLabel>
                                            <div style={{ marginRight: 5, marginTop: i === 0 ? '7px' : '-15px', }}>
                                                <OutlinedInput
                                                    classes={{ root: classes.inputTwoLineOutlined }}
                                                    value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['to'] ? filterData[item.field_id]['filter_value']['to'] : ''}
                                                    onChange={(e) => handleRangeFilterData(filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : '', item, e.target.value)}
                                                />
                                            </div>
                                        </Grid>

                                    </Grid >
                                </>;
                            })
                            }
                        </Grid >
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button}
                                // onClick={handleClickOpen} 
                                onClick={handleDialog}
                                twoline='true'
                            >
                                {
                                    props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Run'
                                }
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => handleSearch('Save Search')}
                                twoline="true"
                            >
                                {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                    :
                                    'Save Search'
                                }
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => handleSearch('Save Search & Run')}
                                twoline="true"
                            >
                                {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                    :
                                    'Save Search & Run'
                                }
                            </Button>
                        </div>
                    </div>
                    <div style={{ width: '20%' }} className={classes.container}>
                        <DynamicQueryFormRef
                            id={currentQueryID}
                            onChange={handleFilter}
                        />
                    </div>
                </div>
            </div >
            {
                openFilter &&
                <FilterDialoge open={openFilter} filterName={filterName}
                    action={filterID ? 'edit' : 'create'}
                    onChange={handleSaveAndRun}
                    onCancel={() => setOpenFilter(false)}
                />
            }
            {/* <SimpleDialog open={open} content='Do you want to search customer master data?' handleDialog={handleDialog} /> */}
        </LoadingOverlay >
    );
};

const mapStateToProps = state => {
    return {
        customerMasterData: state.customerData.customerMasterData,
        loading: state.addMultipleConfigurationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        predefinedFilter: state.operationalReportsData.dynamicQueryFilterData,
        dropdownValueValidation: state.attributeListData.dropdownDataValidation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit, keyword, data) => dispatch(getCustomerMasterData(pagination, limit, keyword, data)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getPredefinedFilter: (id) => dispatch(getQueryRefById(id)),
        saveAndRun: (type, data) => dispatch(updateQueryRefDataMaster(type, data)),
        getDropdownValuesValidation: (table, data) => dispatch(getDropdownValidation(table, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMasterSearch);