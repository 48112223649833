import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import {
    Typography, Breadcrumbs, Link, Grid,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },

}));
const FormulaLabMain = props => {

    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [userStore, setUserStore] = React.useState(sessionStorage.getItem('formula-details') ? JSON.parse(sessionStorage.getItem('formula-details')) : []);

    return (
        <div className={clsx(classes.root, className)}>

            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' style={{ cursor: 'pointer' }} classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/formula-lab')}
                >
                    Formula Lab
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;View Formula</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {userStore ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead}>Formula ID</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Formula Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Formula Expression</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            <StyledTableRow>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{userStore.ID}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{userStore.formula_name}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{userStore.formula && userStore.formula.join(' ').replace(/_/g, ' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{userStore.application_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{userStore.source_data_type}</TableCell>
                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    No Data
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                </div>
            </div >
        </div >
    );
};

export default connect(null, null)(FormulaLabMain);