import React from 'react';
import { SvgIcon } from '@mui/material';

const OperationalIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M3.4999 3.75012C3.19492 3.75012 2.94727 3.49805 2.94727 3.18762V0.5625C2.94727 0.25207 3.19492 0 3.4999 0C3.80489 0 4.05254 0.25207 4.05254 0.5625V3.18762C4.05254 3.49805 3.80524 3.75012 3.4999 3.75012Z" fill='#B4BDE9'  className={props.className} />
        <path d="M7.18429 3.75012C6.8793 3.75012 6.63165 3.49805 6.63165 3.18762V0.5625C6.63165 0.25207 6.8793 0 7.18429 0C7.48928 0 7.73693 0.25207 7.73693 0.5625V3.18762C7.73693 3.49805 7.48928 3.75012 7.18429 3.75012Z" fill='#B4BDE9'  className={props.className} />
        <path d="M10.5001 3.75012C10.1951 3.75012 9.94745 3.49805 9.94745 3.18762V0.5625C9.94745 0.25207 10.1951 0 10.5001 0C10.8051 0 11.0527 0.25207 11.0527 0.5625V3.18762C11.0527 3.49805 10.8051 3.75012 10.5001 3.75012Z" fill='#B4BDE9'  className={props.className} />
        <path d="M11.2368 13.8751H3.4999C3.19492 13.8751 2.94727 13.6231 2.94727 13.3126C2.94727 13.0022 3.19492 12.7501 3.4999 12.7501H11.2368C11.5418 12.7501 11.7894 13.0022 11.7894 13.3126C11.7894 13.6231 11.5421 13.8751 11.2368 13.8751Z" fill='#B4BDE9'  className={props.className} />
        <path d="M11.2368 10.8749H3.4999C3.19492 10.8749 2.94727 10.6228 2.94727 10.3124C2.94727 10.0019 3.19492 9.74988 3.4999 9.74988H11.2368C11.5418 9.74988 11.7894 10.0019 11.7894 10.3124C11.7894 10.6228 11.5421 10.8749 11.2368 10.8749Z" fill='#B4BDE9'  className={props.className} />
        <path d="M7.5528 7.875H3.4999C3.19492 7.875 2.94727 7.62293 2.94727 7.3125C2.94727 7.00207 3.19492 6.75 3.4999 6.75H7.55245C7.85744 6.75 8.10509 7.00207 8.10509 7.3125C8.10543 7.62293 7.85778 7.875 7.5528 7.875Z" fill='#B4BDE9'  className={props.className} />
        <path d="M11.9739 18H2.02645C0.908396 18 0 17.0754 0 15.9374V3.56237C0 2.42472 0.908396 1.49976 2.02645 1.49976H11.9739C13.0916 1.49976 14.0003 2.42437 14.0003 3.56237V15.9374C14 17.0754 13.0916 18 11.9739 18ZM2.02645 2.62511C1.51871 2.62511 1.10527 3.04558 1.10527 3.56237V15.9374C1.10527 16.4542 1.51871 16.875 2.02645 16.875H11.9739C12.4816 16.875 12.8951 16.4542 12.8951 15.9374V3.56237C12.8951 3.04558 12.4816 2.62476 11.9739 2.62476H2.02645V2.62511Z" fill='#B4BDE9'  className={props.className} />
    </SvgIcon>
)

export default OperationalIcon;
