import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getEDIMappings } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const EdiMappingView = props => {
    const classes = useStyles();
    const [sortedData, setSortedData] = React.useState([]);
    useEffect(() => {
        var tempObj = []
        if (props.ediSegmentDataID) {
            Object.values(props.ediSegmentDataID).map(e => {
                tempObj.push(e)
            })
            setSortedData(tempObj);
        }
    }, [props.ediSegmentDataID])
    const handleSourceFieldName = (event, index, type) => {
        sortedData[index][type] = (event.target.value).toString();
    };
    const handleDefaultValue = (event, index, type) => {
        sortedData[index][type] = (event.target.value).toString();
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/edi-mappings'
                >
                    Edi Mappings
                </Link>
                <Typography color="textPrimary" variant='h4'>View</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div>
                                <div style={{ margin: 15 }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell align='center' className={classes.tabHead}>Segment ID</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Segment Name</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Property</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Repeat</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Loop Start</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Loop End</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Ref Des</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Field Sequence</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Field Name</TableCell>
                                                    <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Source Field Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {sortedData && sortedData
                                                    // .filter((itmX,index)=> sortedData[index]['default_value']!=='')
                                                    .map((itemX, index) => {
                                                        return (
                                                            <TableRow >
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['segment_id']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['segment_name']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['property']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['repeat']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['loop_start']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['loop_end']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['ref_des']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['field_sequence']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['field_name']}</TableCell>
                                                                <TableCell align='center' >
                                                                    <OutlinedInput twoline='true'
                                                                        value={sortedData[index]['default_value']}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align='center' >
                                                                    <OutlinedInput twoline='true'
                                                                        value={sortedData[index]['source_field_name']}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getEDIMappings: (formatType) => dispatch(getEDIMappings(formatType)),
    }
}

const mapStateToProps = state => {
    return {
        ediSegmentData: state.pricingData.edimappingData,
        ediSegmentDataID: state.pricingData.configEdiMappingsData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EdiMappingView);