import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery,
    TableRow, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getBatchJobSetup, addCustomerMasterXRefUser, addBatchJobSetup } from '../../../redux/actions';
import { BatchJobSetupToolbar } from '.';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';
import secureLocalStorage from "react-secure-storage";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '22px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    spinner: {
        height: '100vh'
    },
}));

const IntegrationSetupUserTable = props => {
    useEffect(() => {
        if (sessionStorage.getItem("application") === 'Transaction Data') {
            props.getBatchJobSetup('Sales Data');
            sessionStorage.setItem('batchJob-Name', 'Sales Data')
        } else {
            sessionStorage.setItem('batchJob-Name', sessionStorage.getItem("application"))
            props.getBatchJobSetup(sessionStorage.getItem("application"));
        }
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [batchJobData, setBatchJobData] = React.useState([])
    const [dataRows, setDataRows] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [deleteItemId, setDeleteItemId] = React.useState(null);
    useEffect(() => {
        var newArray = []
        if (props.integrationSetupData && props.integrationSetupData.length > 0) {
            props.integrationSetupData.map(e => {
                var obj = {
                    'ID': e.ID,
                    'app_type': e.app_type,
                    'batch_job_name': e.batch_job_name,
                    'batch_job_type': e.batch_job_type,
                    'planned_start_calendar': e.planned_start_calendar,
                    'planned_date_type': e.planned_date_type,
                    'planned_start_date': e.planned_start_date,
                    'planned_start_time': e.planned_start_time,
                    'frequency': e.frequency,
                    'created_by': e.created_by,
                    'changed_by': e.changed_by,
                    'CreatedAt': e.CreatedAt,
                    'UpdatedAt': e.UpdatedAt,
                };
                newArray.push(obj)
            })
            setDataRows(newArray)
        }

    }, [props.integrationSetupData])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getBatchJobSetup(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getBatchJobSetup(1, event.target.value);
    };
    const handleDeleteClick = (id) => {
        setDeleteItemId(id);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDeleteItemId(null);
    };

    const handleDeleteConfirm = () => {
        props.deleteData('', 'delete', deleteItemId);
        handleDialogClose();
    };

    const columns = [
        {
            field: 'app_type',
            title: 'Application Type',
            editable: 'never',
            type: 'string',
            render: rowData => <a align='center' className={classes.hover} > {rowData.app_type}</a>
        },
        {
            field: 'batch_job_name',
            title: 'Batch Job Name',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.batch_job_name}</a>
        },
        {
            field: 'batch_job_type',
            title: 'Batch Job Type',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.batch_job_type}</a>
        },
        {
            field: 'planned_start_calendar',
            title: 'Planned Start Calendar',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.planned_start_calendar}</a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            }
        },
        {
            field: 'planned_date_type',
            title: 'Planned Date Type',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.planned_date_type}</a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            }
        },
        {
            field: 'planned_start_date',
            title: 'Planned Start Date',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} >
                {/* {Moment.utc(rowData.planned_start_date).format('MM/DD/YYYY') + '\n' + rowData.planned_start_time} */}
                {Moment.utc(rowData.planned_start_date).local().format('MM/DD/YYYY HH:mm:ss')}
            </a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            }
        },
        {
            field: 'frequency',
            title: 'Frequency',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.frequency}</a>
        },
        {
            field: 'created_by',
            title: 'Created By',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.created_by}</a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            }
        },
        {
            field: 'changed_by',
            title: 'Changed By',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {rowData.changed_by}</a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            }
        },
        {
            field: 'CreatedAt',
            title: 'Created On',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {Moment.utc(rowData.CreatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            }
        },
        {
            field: 'UpdatedAt',
            title: 'Changed On',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover} > {Moment.utc(rowData.UpdatedAt).isSame(rowData.CreatedAt) ? "" : Moment.utc(rowData.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</a>,
            headerStyle: {
                width: 150,
                whiteSpace: 'pre-line',
            }
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => viewFilter(rowData)}
                        size="large">
                        <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    {/* <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editIntegration(rowData)}
                        size="large">
                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton> */}
                    {secureLocalStorage.getItem("dYtz").role &&
                        secureLocalStorage.getItem("dYtz").role.rights &&
                        secureLocalStorage
                            .getItem("dYtz")
                            .role.rights.includes(
                                "put_batch_job-" + sessionStorage.getItem("application")
                            ) && (
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editIntegration(rowData)}
                                size="large">
                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        )}
                    {secureLocalStorage.getItem("dYtz").role &&
                        secureLocalStorage.getItem("dYtz").role.rights &&
                        secureLocalStorage
                            .getItem("dYtz")
                            .role.rights.includes(
                                "put_batch_job-" + sessionStorage.getItem("application")
                            ) && (
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleDeleteClick(rowData.ID)}
                                size="large">
                                <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        )}
                </div>

        },
    ];
    function viewFilter(item) {
        if (sessionStorage.getItem("application") === 'Transaction Data') {
            history.push('/transaction-data-batchjob-setup/view-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Pricing') {
            history.push('/pricing-batchjob-setup/view-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Profit Optimization') {
            history.push('/profitopt-batchjob-setup/view-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Integration Studio') {
            history.push('/integration-batchjob-setup/view-integration-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Customer Chargeback') {
            history.push('/view-batchjob-setup-customer-chargeback/' + item.ID)
        } else {
            history.push('/batchjob-setup/view-batchjob-setup/' + item.ID)
        }
    }

    function editIntegration(item) {
        if (sessionStorage.getItem("application") === 'Transaction Data') {
            history.push('/transaction-data-batchjob-setup/edit-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Pricing') {
            history.push('/pricing-batchjob-setup/edit-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Profit Optimization') {
            history.push('/profitopt-batchjob-setup/edit-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Integration Studio') {
            history.push('/integration-batchjob-setup/edit-integration-batchjob-setup/' + item.ID)
        } else if (sessionStorage.getItem("application") === 'Customer Chargeback') {
            history.push('/edit-batchjob-setup-customer-chargeback/' + item.ID)
        } else {
            history.push('/batchjob-setup/edit-batchjob-setup/' + item.ID)
        }

    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<HashLoader />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Batch Job Setup</Typography>
                    <BatchJobSetupToolbar />
                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        // style={{ marginTop: '-10px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            emptyRowsWhenPaging: false,
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }

            </div >
            <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteConfirm} variant="contained" color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.customerData.loading,
        integrationSetupData: state.customerData.batchJobAll,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBatchJobSetup: (type) => dispatch(getBatchJobSetup(type)),
        onSubmit: (data) => dispatch(addCustomerMasterXRefUser(data)),
        deleteData: (data, type, id) => dispatch(addBatchJobSetup(data, type, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSetupUserTable);