import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getDefaultValues, getAllApprovalDesigner, addContractDefaultDesigner, getContractDefaultDetails, getApprovalGroupData } from '../../redux/actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    }
}));
const ViewContractDefaultDesigner = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [approvalContractsContractArray, setApprovalContractsContractArray] = React.useState([]);
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [copaLevel, setCopaLevel] = React.useState('');
    const [accrualGLAC, setAccrualGLAC] = React.useState('');
    const [accrualGLAD, setAccrualGLAD] = React.useState('');
    const [accrualCPC, setAccrualCPC] = React.useState('');
    const [accrualDPC, setAccrualDPC] = React.useState('');
    const [approvalGroupArray, setapprovalGroupArray] = React.useState([]);
    const [accrualLevelArray, setAccrualLevelArray] = React.useState([]);
    const [copaLevelArray, setCopaLevelArray] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [contractType, setContractType] = React.useState('');
    const [approvalGroup, setApprovalGroup] = React.useState('');
    const [variancePercentage, setVariancePercentage] = React.useState('');
    const [thresholdValue, setThresholdValue] = React.useState('');
    const [overrideValue, setOverrideValue] = React.useState('');
    const [ppaValue, setPpaValue] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    const [currentContractArray, setCurrentContractArray] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault();
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getContractDefaultDetails(appId)
    }, []);
    useEffect(() => {
        if (props.contractDefaultDetail) {
            setUserStore([props.contractDefaultDetail])
        }
    }, [props.approvalData]);
    useEffect(() => {
        props.onLoadingDefault();
        props.getAllApprovalDesigner(1, 130);
        props.getApprovalGroupData();
    }, []);
    useEffect(() => {
        if (props.approvalData && props.approvalData.length > 0) {
            props.approvalData.map((item) => setApprovalContractsContractArray(approvalContractsContractArray => [...approvalContractsContractArray, item.contract_type]));
        }
    }, [props.approvalData]);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'approval_group') {
                            setapprovalGroupArray(item);
                        }
                        if (item.field_id === 'accrual_level') {
                            setAccrualLevelArray(item);
                        }
                        if (item.field_id === 'copa_level') {
                            setCopaLevelArray(item);
                        }
                    })
                }
            }
        }
    });
    function handleContractType(e) {
        setContractType(e.target.value);
    }
    function handleSubmit(editIndex) {
        setCurrentContractArray([...currentContractArray, contractType]);
        var data = {
            "contract_type": contractType,
            "accrual_level": accrualLevel,
            "copa_level": copaLevel,
            "approval_group": approvalGroup,
            "accrual_gl_account_credit": accrualGLAC,
            "accrual_gl_account_debit": accrualGLAD,
            "accrual_credit_profit_center": accrualCPC,
            "accrual_debit_profit_center": accrualDPC
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...userStore];
            editedArray[editIndex] = data;
            setUserStore(editedArray);
        }
        else {
            setUserStore([...userStore, data]);
        }
        handleClear();
    }
    function handleClear() {
        setContractType('');
        setAccrualLevel('');
        setCopaLevel('');
        setApprovalGroup('');
        setAccrualGLAC('');
        setAccrualGLAD('');
        setAccrualCPC('');
        setAccrualDPC('');
    }
    function handleDeleteRow(id, item) {
        setCurrentContractArray(currentContractArray.filter((e) => (e !== item.contract_type)));
        const newArray = [...userStore];
        newArray.splice(id, 1)
        setUserStore([...newArray])
    }
    function handleOnSubmit() {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(userStore, 'put', appId)
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        setContractType(item.contract_type);
        setAccrualLevel(item.accrual_level);
        setCopaLevel(item.copa_level);
        setApprovalGroup(item.approval_group);
        setAccrualGLAC(item.accrual_gl_account_credit);
        setAccrualGLAD(item.accrual_gl_account_debit);
        setAccrualCPC(item.accrual_credit_profit_center);
        setAccrualDPC(item.accrual_debit_profit_center);
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/contract-default-designer'
                >
                    Default Designer
                </Link>
                <Typography color="textPrimary" variant='h4'>View Contract Default</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {userStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'>Application Type</TableCell>
                                                <TableCell align='center'>Contract Type</TableCell>
                                                <TableCell align='center'>Accrual Level</TableCell>
                                                <TableCell align='center'>Copa Level</TableCell>
                                                <TableCell align='center'>Accrual GL Account Credit</TableCell>
                                                <TableCell align='center'>Accrual GL Account Debit</TableCell>
                                                <TableCell align='center'>Accrual Credit Profit Center</TableCell>
                                                <TableCell align='center'>Accrual Debit Profit Center</TableCell>
                                                <TableCell align='center'>Contract Purpose</TableCell>
                                                <TableCell align='center'>Postings Schema</TableCell>
                                                <TableCell align='center'>Currency</TableCell>
                                                <TableCell align='center'>Source Data Type</TableCell>
                                                <TableCell align='center'>Accrual Frequency</TableCell>
                                                <TableCell align='center'>Accrual Calendar</TableCell>
                                                <TableCell align='center'>Payment Frequency</TableCell>
                                                <TableCell align='center'>Payment Calendar</TableCell>
                                                <TableCell align='center'>Payment Method</TableCell>
                                                <TableCell align='center'>Payment Partner Role</TableCell>
                                                <TableCell align='center'>Payment Terms</TableCell>
                                                <TableCell align='center'>Approval Group</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {userStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center'>{item.app_type}</TableCell>
                                                        <TableCell align='center'>{item.contract_type}</TableCell>
                                                        <TableCell align='center'>{item.accrual_level}</TableCell>
                                                        <TableCell align='center'>{item.copa_level}</TableCell>
                                                        <TableCell align='center'>{item.accrual_gl_account_credit}</TableCell>
                                                        <TableCell align='center'>{item.accrual_gl_account_debit}</TableCell>
                                                        <TableCell align='center'>{item.accrual_credit_profit_center}</TableCell>
                                                        <TableCell align='center'>{item.accrual_debit_profit_center}</TableCell>
                                                        <TableCell align='center'>{item.contract_purpose}</TableCell>
                                                        <TableCell align='center'>{item.posting_schema}</TableCell>
                                                        <TableCell align='center'>{item.currency}</TableCell>
                                                        <TableCell align='center'>{item.source_data_type}</TableCell>
                                                        <TableCell align='center'>{item.accrual_frequency}</TableCell>
                                                        <TableCell align='center'>{item.accrual_calendar}</TableCell>
                                                        <TableCell align='center'>{item.payment_frequency}</TableCell>
                                                        <TableCell align='center'>{item.payment_calendar}</TableCell>
                                                        <TableCell align='center'>{item.payment_method}</TableCell>
                                                        <TableCell align='center'>{item.payment_partner_role}</TableCell>
                                                        <TableCell align='center'>{item.payment_terms}</TableCell>
                                                        <TableCell align='center'>{item.approval_group}</TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    No Data
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        contractDefaultDetail: state.customerData.contractDefaultDetail,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addContractDefaultDesigner(data)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        getAllApprovalDesigner: (pagination, limit) => dispatch(getAllApprovalDesigner(pagination, limit)),
        getContractDefaultDetails: (id) => dispatch(getContractDefaultDetails(id)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewContractDefaultDesigner);