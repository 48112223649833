import React, { useEffect } from "react";
import Moment from 'moment';
import { timeToMarket } from '../../../../../redux/actions';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Chart from "react-google-charts";

const WaterFall = (props) => {


  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: 'white',
      padding: '20px 30px 20px 30px',
      borderRadius: 10
    },

  }));
  const classes = useStyles();
  const dataGoogle = [
    ["Day", "", "", "", ""],
    ["Mon", 50, 0, 0, 0],
    ["Tue", 31, 38, 55, 66],
    ["Wed", 50, 55, 77, 80],
    ["Thu", 77, 77, 66, 50],
    ["Fri", 68, 66, 22, 15]
  ];
  
  const optionsGoogle = {
    legend: "none",
    bar: { groupWidth: "100%" }, // Remove space between bars.
    candlestick: {
      fallingColor: { strokeWidth: 0, fill: "#a52714" }, // red
      risingColor: { strokeWidth: 0, fill: "#0f9d58" } // green
    }
  };
  const data = [
    {
      quarter: 'Base Price',
      value: 100.50,
    },
    {
      quarter: 'On-Invoice Discounts',
      value: 10.05,
    },
    {
      quarter: 'On-Invoice Surcharges',
      value: 1.05,
    },
    {
      quarter: 'On-Invoice Freight',
      value: 3.50,
    },
    {
      quarter: 'Invoice Price',
      value: 95,
    },
    {
      quarter: 'Off-Invoice Adjustments',
      value: 15.75,
    },
    {
      quarter: 'Off-Invoice Price',
      value: 79.25
    },
    {
      quarter: 'Cost',
      value: 60.25
    },
    {
      quarter: 'Margin Price',
      value: 19.00
    },
  ];

  const formatter = (v) => `${v}`;

  const annotations = [];
  const config = {
    data,
    padding: 'auto',
    xField: 'quarter',
    yField: 'value',
    meta: {
      value: {

      }
    },
    total: false,
    xAxis: {
      label: {
        style: {
          fontSize: 10
        },
      },
    },
    annotations,
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2">Waterfall</Typography>
      <Chart
        chartType="CandlestickChart"
        width="100%"
        height="300px"
        data={dataGoogle}
        options={optionsGoogle}
      />
    </div>
  );
};

export default connect(null, null)(WaterFall);