import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { fpHistory, getProfile, runContractTrends } from '../../redux/actions';
import { connect } from 'react-redux';
import {
    Card, CardHeader, Select, MenuItem,
    Avatar,
    Divider
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Moment from 'moment';
import { history } from '../../components/Helpers';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FormLabel } from 'react-bootstrap';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    spinner: {
        height: '100vh'
    },
    textField: {
        //marginTop: 10,
        marginBottom: 10
    },
    cardRoot: {
        backgroundColor: theme.palette.white,
        boxShadow: '1px 2px 4px #9E9E9E',
        borderRadius: 6,
        margin: '15px 5px 0px 8px',
        padding: 15,
        overflow: 'hidden',
        width: '95%'
    },
    headerRoot: {
        margin: 0,
        padding: '10px 0 15px 0'
    },
    commentBox: {
        fontSize: theme.typography.h4.fontSize,
        color: 'grey'
    },
    dropdownbox: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 5
    }

}));


const ContractComments = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [comments, setComments] = React.useState('');
    const { className } = props;
    const [profileDataAPI, setProfileDataAPI] = React.useState({});
    const [notesArray, setNotesArray] = React.useState([]);
    const [contractNumber, setContractNumber] = React.useState({});
    const [enable, setEnable] = React.useState(false);
    useEffect(() => {
        props.getProfile();
    }, []);
    useEffect(() => {
        if (props.notes) {
            setNotesArray(props.notes)
        }
    }, [props.notes]);
    useEffect(() => {
        if (props.selectedIds) {
            setContractNumber(props.selectedIds && props.selectedIds[0])
        }
    }, [props.selectedIds]);
    useEffect(() => {
        if (props.profileData && Object.keys(props.profileData).length > 0) {
            setProfileDataAPI(props.profileData)
        }
    }, [props.profileData]);
    function handleComments(value) {
        setComments(value)
        if (!enable)
            setEnable(true)
    }
    function handleSend() {
        setEnable(false)
        var data = {
            "text": comments,
            "note_type": "Submit Decision",
            "contract_id": props.page == 'approval' ? contractNumber.id : props.contractId,
            "contract_number": props.page == 'approval' ? contractNumber.contract_number : props.contractNumber
        }
        setNotesArray([...notesArray, data])
        //setComments('')
        props.onChange(data)


    }
    return (
        <Card>
            {(props.approvalStatus == 'submit_approval') &&
                <>
                    <CardHeader
                        title="COMMENTS"
                        titleTypographyProps={{ variant: 'h3' }}
                        classes={{ root: classes.headerRoot }}
                    />
                    {/* {!props.editable && notesArray.length == 0 &&
                <div className={classes.commentBox}>
                    No Comments to show
                </div>
            } */}

                    <div style={{ display: 'flex' }}>
                        {/* <Avatar
                        style={{ marginRight: 15 }}
                        sx={{ width: 24, height: 24 }}
                        src={profileDataAPI && profileDataAPI.profile_image_path}
                    /> */}
                        <div style={{ width: '100%' }}>
                            {/* {props.page == 'approval' &&
                            <div className={classes.dropdownbox}>
                                <Select
                                    disableUnderline
                                    onChange={(e) => setContractNumber(e.target.value)}
                                    // displayEmpty
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    value={contractNumber}
                                >
                                    {props.selectedIds && props.selectedIds.length > 0 &&
                                        props.selectedIds
                                            .map((item, i) => {
                                                return (
                                                    <MenuItem value={item} key={item.id}>
                                                        {item.contract_number} - {item.internal_description}
                                                    </MenuItem>
                                                )
                                            })}
                                </Select>
                            </div>} */}
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                fullWidth
                                minRows={3}
                                maxRows={4}
                                variant='filled'
                                value={comments}
                                placeholder='Enter comments here...'
                                onChange={(e) => handleComments(e.target.value)}
                                //onClick={() => setEnable(true)}

                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {enable &&
                                            <IconButton aria-label="comments" onClick={handleSend} size="large">
                                                <SendIcon color={comments ? 'primary' : 'disabled'} />
                                            </IconButton>}
                                    </InputAdornment>
                                }}
                                style={{ backgroundColor: enable && 'white' }}
                            />
                        </div>


                    </div>
                </>
            }
            {/* {(notesArray && notesArray.length > 0) &&
                <div className={classes.cardRoot}>
                    {notesArray.map((item, index) =>
                        <div>
                            <div style={{ display: 'flex' }}>
                                <Avatar
                                    style={{ marginRight: 15 }}
                                    sx={{ width: 24, height: 24 }}
                                    src={item.created_by ? item.created_by.profile_image_path : profileDataAPI ? profileDataAPI.profile_image_path : ''}
                                />
                                <div>
                                    <FormLabel style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{item.created_by ? item.created_by.name : profileDataAPI ? profileDataAPI.name : ''}</FormLabel>
                                    {props.page == 'approval' && <div className={classes.textField}>{item.contract_number} - </div>}
                                    <div className={classes.textField}>{item.text}</div>
                                </div>
                            </div>

                            {index < notesArray.length - 1 &&
                                <Divider style={{ marginBottom: 10 }} />
                            }
                        </div>
                    )}
                </div>
            } */}
        </Card>
    );

};
const mapStateToProps = state => {
    return {
        profileData: state.profileData.profileData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getProfile: () => dispatch(getProfile()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractComments);