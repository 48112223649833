import React from 'react';
import { connect } from 'react-redux';
import { IntegrationFilterUserTable } from './components';
const IntegrationFilter = () => {
    return (
        <div>
            <IntegrationFilterUserTable />
        </div >
    );
};
export default connect(null, null)(IntegrationFilter);