import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Fab,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
} from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import { loadTop25Chart, loadPaymentGraph } from "../../../../redux/actions";
import PieChart from "../../Charts/PieChart";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white, //secondary.main,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    transform: 'scale(0.49)',
    transformOrigin: 'top left',
    width: "201.5%",
    height: "513px"
  },
  formLabel: {
    fontSize: 13, //theme.typography.h4.fontSize,
    textTransform: "capitalize",
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: '1px solid #EEEEEE',
    borderRadius: 6,
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    width: "131.5%",
    marginBottom: -8,
    // marginTop: 25,
    boxShadow: "rgba(50, 50, 93, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.15) 0px 2px 4px -2px",
    height: "70px",
    padding: "7px 5px 5px 5px"
  },
  formControlStyle: {
    // border: "1px solid #8F00FF",
    marginRight: "-3px"
  },
  noBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "1px solid",
        borderColor: theme.palette.border.main,
      },
    },
  },
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: "1px solid",
      borderColor: theme.palette.border.main,
    },
  },
  spinner: {
    height: "100%",
  },
}));
const RebateObjective = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("accrual");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [attribute, setAttribute] = React.useState("contract_type");
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [showLoaderRebateObjective, setShowLoaderRebateObjective] = React.useState(true);

  const dimension = {
    xName: "GroupBy",
    yName: "Total",
    tooltipMappingName: "GroupBy",
  };

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Postings Summary by Contract Groupings" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      // setStartDate(allowedTilesFilter[0].start_date);
      // setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setPostingType(allowedTilesFilter[0].posting_type);
      setAttribute(convertToSnakeCase(allowedTilesFilter[0].groupBy));
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate && sourceDataType && postingType) {
      var data = {
        groupBy: attribute,
        start_date: Moment(startDate).format('MM/DD/YYYY'),
        end_date: Moment(endDate).format('MM/DD/YYYY'),
        posting_type: postingType,
        form_id: "IMA_CONFIG_MULTIPLE",
        application_type: "Customer Rebate",
        data_source_type: sourceDataType,
      };
      setShowLoaderRebateObjective(true);
      props.loadPaymentGraph(data);
    }
  }, [allowedTilesFilter]);

  function handleSubmit() {
    var data = {
      groupBy: attribute,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: postingType,
      form_id: "IMA_CONFIG_MULTIPLE",
      application_type: "Customer Rebate",
      data_source_type: sourceDataType,
    };
    setShowLoaderRebateObjective(true);
    props.loadPaymentGraph(data);
  }
  useEffect(() => {
    if (props.incentiveData) {
      if (props.incentiveData.payment_result)
        setGraphData(props.incentiveData.payment_result);
    }
    setShowLoaderRebateObjective(false);
  }, [props.incentiveData]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
    // var data = {
    //   groupBy: attribute,
    //   start_date: Moment(startDate).format('MM/DD/YYYY'),
    //   end_date: Moment(endDate).format('MM/DD/YYYY'),
    //   posting_type: e.target.value,
    //   form_id: "IMA_CONFIG_MULTIPLE",
    //   application_type: "Customer Rebate",
    //   data_source_type: sourceDataType,
    // };
    // setShowLoaderRebateObjective(true);
    // props.loadPaymentGraph(data);
  };

  const handleAttribute = (e) => {
    setAttribute(e.target.value);
    // var data = {
    //   groupBy: e.target.value,
    //   start_date: Moment(startDate).format('MM/DD/YYYY'),
    //   end_date: Moment(endDate).format('MM/DD/YYYY'),
    //   posting_type: postingType,
    //   form_id: "IMA_CONFIG_MULTIPLE",
    //   application_type: "Customer Rebate",
    //   data_source_type: sourceDataType,
    // };
    // setShowLoaderRebateObjective(true);
    // props.loadPaymentGraph(data);
  };

  const handlePeriod = (type) => {
    var sDate;
    var eDate;
    if (type == "month") {
      sDate = Moment.utc()
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else if (type == "quarter") {
      sDate = Moment.utc()
        .subtract(3, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(3, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else {
      sDate = Moment.utc().startOf("year").format("MM/DD/YYYY");
      eDate = Moment.utc().endOf("year").format("MM/DD/YYYY");
    }

    var data = {
      groupBy: attribute,
      start_date: sDate,
      end_date: eDate,
      posting_type: postingType,
      form_id: "IMA_CONFIG_MULTIPLE",
      application_type: "Customer Rebate",
      data_source_type: sourceDataType,
    };

    props.loadPaymentGraph(data);
  };
  function handleStartDateNew(event) {
    if (event && event.isValid()) {
      setStartDate(event);
      // var data = {
      //   groupBy: attribute,
      //   start_date: Moment.utc(event).format("MM/DD/YYYY"),
      //   end_date: Moment.utc(endDate).format("MM/DD/YYYY"),
      //   posting_type: postingType,
      //   form_id: "IMA_CONFIG_MULTIPLE",
      //   application_type: "Customer Rebate",
      //   data_source_type: sourceDataType,
      // };
      // setShowLoaderRebateObjective(true);
      // props.loadPaymentGraph(data);
    }
    else
      setStartDate(event);
  }
  function handleEndDateNew(event) {
    if (event && event.isValid()) {
      setEndDate(event);
      //   var data = {
      //     groupBy: attribute,
      //     start_date: Moment.utc(startDate).format("MM/DD/YYYY"),
      //     end_date: Moment.utc(event).format("MM/DD/YYYY"),
      //     posting_type: postingType,
      //     form_id: "IMA_CONFIG_MULTIPLE",
      //     application_type: "Customer Rebate",
      //     data_source_type: sourceDataType,
      //   };
      //   setShowLoaderRebateObjective(true);
      //   props.loadPaymentGraph(data);
    }
    else
      setEndDate(event);
  }
  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }


  return (
    <LoadingOverlay
      active={showLoaderRebateObjective}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div>
        <div className={classes.bodyContainer} style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, maxWidth: 115 }} size="small" style={{ marginLeft: "1px" }} className={classes.formControlStyle}>
            <InputLabel id="posting-type" color="primary">
              Posting Type
            </InputLabel>
            <Select
              className={classes.selectBorder}
              style={{ border: 'none' }}
              labelId="posting-type"
              id="posting-type-select"
              value={postingType}
              label="Posting Type"
              onChange={handlePostingType}
              color="primary"
              width="100%"
            >
              <MenuItem value={"accrual"}>Accrual</MenuItem>
              <MenuItem value={"payment"}>Payment</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 200 }} size="small" className={classes.formControlStyle}>
            <InputLabel id="attribute" color="primary">
              Attributes
            </InputLabel>
            <Select
              className={classes.selectBorder}
              style={{ border: 'none' }}
              labelId="attribute"
              id="attribute-select"
              value={attribute}
              label="Attribute Type"
              onChange={handleAttribute}
              color="primary"
              width="100%"
            >
              <MenuItem value={"contract_group"}>Contract Group</MenuItem>
              <MenuItem value={"contract_type"}>Contract Type</MenuItem>
              <MenuItem value={"calculation_method"}>Calculation Method</MenuItem>
              <MenuItem value={"contract_sub_group"}>Contract Objective</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 140 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="start_date"
                id="start_date-select"
                label="Start Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.17rem',
                    border: 'none'
                  }
                }}
                value={startDate}
                onChange={date => handleStartDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 140 }} size="small" style={{ marginRight: "1px" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="end_date"
                id="end_date-select"
                label="End Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.17rem',
                    border: 'none'
                  }
                }}
                value={endDate}
                onChange={date => handleEndDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
                minDate={startDate ? startDate : ''}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 80 }} size="small" className={classes.formControlStyle}>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "34px", marginRight: 3 }}
              onClick={handleSubmit}
              twoline="true"
              disabled={startDate && endDate && sourceDataType && postingType && attribute ? false : true}
            >
              <DirectionsRunIcon />
            </Button>
          </FormControl>

          {/* <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Fab
            size="small"
            color="primary"
            aria-label="monthly"
            onClick={() => handlePeriod("month")}
          >
            <span style={{ marginLeft: 6 }}>M</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="quarterly"
            onClick={() => handlePeriod("quarter")}
          >
            <span style={{ marginLeft: 9 }}>Q</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="yearly"
            onClick={() => handlePeriod("year")}
          >
            <span style={{ marginLeft: 9 }}>Y</span>
          </Fab>
        </Box> */}
        </div>
        {/* <div>
        <ToggleButtonGroup
          color="primary"
          value={attribute}
          exclusive
          onChange={handleAttribute}
          aria-label="Platform"
          size="small"
        >
          <ToggleButton
            className={classes.formLabel}
            size="small"
            value="contract_group"
            style={{ textTransform: "capitalize" }}
          >
            Contract Group
          </ToggleButton>
          <ToggleButton
            className={classes.formLabel}
            style={{ textTransform: "capitalize" }}
            value="contract_type"
          >
            Contract Type
          </ToggleButton>
          <ToggleButton
            className={classes.formLabel}
            value="calculation_method"
            style={{ textTransform: "capitalize" }}
          >
            Calculation Method
          </ToggleButton>
          <ToggleButton
            className={classes.formLabel}
            value="contract_sub_group"
            style={{ textTransform: "capitalize" }}
          >
            Contract Objective
          </ToggleButton>
        </ToggleButtonGroup>
      </div> */}
        <div className={classes.root}  >
          <PieChart data={graphData} dimension={dimension} height={"small"} />
        </div>
      </div>
    </LoadingOverlay>
  );
};
const mapStateToProps = (state) => {
  return {
    incentiveData: state.initialData.incentiveData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPaymentGraph: (data) =>
      dispatch(loadPaymentGraph(data, null, "dashboard")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateObjective);
