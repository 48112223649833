import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button, IconButton,
    Typography, FormLabel, TextField, Grid, Accordion, AccordionDetails, AccordionSummary, AccordionActions,
    Tooltip
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { FileDownload, Add, ExpandCircleDownOutlined, FolderOpenOutlined, Edit, ContentCopy, Delete, CommentOutlined, Route, ExitToApp, GroupAddOutlined, Folder } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { createTheme } from "@mui/material/styles";
import MaterialTable from 'material-table';
import Moment from 'moment';
import { StickerTableHeader } from '../GlobalSetup/Components';
import { createPromotionScenario, createScenarioList, getMaterialFilters, getScenarioList } from '../../../redux/actions';
import '../Styles/global.css'
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        // borderRadius: 10,
        // padding: '20px 16px',
        // height: '130vh',
        // maxHeight: '460vh'
    },
    hover: {
        cursor: 'pointer',
        color: 'var(--White-Mode-Main-text, #19170F)',
        fontFeatureSettings: '"liga" off, "clig" off',
        fontFamily: '"IBM Plex Sans"',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '-0.084px',
    },

    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80px',
    },
    addIcon: {
        color: 'black',
        backgroundColor: theme.palette.primary.main,
        // border: '1px solid',
        borderRadius: '28px',
        padding: '9px',
        // position: 'absolute',
        marginBottom: -5,
        marginTop: 8,
        // right: '50px',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        fontSize: 60,
        zIndex: 1,
        marginRight: 50,
    },
    addButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'fixed',
        bottom: 140,
        right: -22,
        zIndex: 1000,
    },
    container: {
        backgroundColor: '#f8f8f8',
        // marginLeft: 5,
        padding: 20,

    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        border: '1px solid #E8E8E8',
    },
    folder: {
        marginRight: theme.spacing(1),
    },
    folderClosed: {
        color: '#7F7F7F'
    },
    spanRight: {
        marginTop: '3px',
        marginLeft: 'auto',
        marginRight: '10px',
        border: '1px solid black',
        borderRadius: '16px',
        padding: '2px 10px',
        maxWidth: '150px',
        minWidth: '70px',
        height: '22px',
        color: 'var(--White-Mode-Main-text, #19170F)',
        textAlign: 'right',
        fontFeatureSettings: '"liga" off, "clig" off',
        fontFamily: '"IBM Plex Sans"',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',

    },
    RouteIcon: {
        transform: 'rotate(90deg) scaleX(-1)',
    },
    titleName: {
        marginTop: -4,
        color: 'var(--White-Mode-Secondary-text, #8D8D8D)',
        fontFeatureSettings: '"liga" off, "clig" off',
        fontFamily: '"IBM Plex Sans"',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.176px',
    },
    titles: {
        color: 'var(--written-part-filter, #A7A7A7)',
        fontFeatureSettings: '"liga" off, "clig" off',
        fontFamily: '"IBM Plex Sans"',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        marginLeft: 18
    },
    headerTitle: {
        height: '63px',
        borderTop: '1px solid #E2E2E2',
        borderBottom: '1px solid #E2E2E2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'

    },
}));


const PromotionDashboard = props => {
    const { className } = props;
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const passedProductLine = location.data && location.data.productLine;
    const isVisited = location.data && location.data.page;
    const [dataRowsApproved, setdataRowsApproved] = React.useState([]);
    const [dataRowsPending, setdataRowsPending] = React.useState([]);
    const [dataRowsHistory, setdataRowsHistory] = React.useState([]);
    const [dataRowsInProgress, setdataRowsInProgress] = React.useState([]);
    const [dataRowsCompleted, setdataRowsCompleted] = React.useState([]);
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [extendedData, setExtendedData] = React.useState({});
    const [extendedEndDate, setExtendedEndDate] = React.useState(null);
    const [extendedStartDate, setExtendedStartDate] = React.useState(null);
    const [selectedFolder, setSelectedFolder] = React.useState(['In Progress']);

    const columns = [
        {
            field: 'month',
            title: 'Months',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.month}</a>
        },
        {
            field: 'creation_date',
            title: 'Creation Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.creation_date).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.creation_date).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'scenario_name',
            title: 'Scenario Name',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.scenario_name}</a>,
            cellStyle: { minWidth: 300 },
        },
        // {
        //     field: 'created_by',
        //     title: 'Created By',
        //     type: 'string',
        //     render: (rowData) => <a className={classes.hover} > {rowData.created_by}</a>,
        // },
        {
            field: 'country',
            title: 'Region',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.country && rowData.country.join(",")}</a>,
        },
        {
            field: 'product_lines',
            title: 'Product Lines',
            type: 'string',
            render: (rowData) => <a className={classes.hover} ><Tooltip title={rowData.product_lines.join(', ')}>{rowData.product_lines.join(', ')}</Tooltip> </a>

        },
        // {
        //     field: 'costing',
        //     title: 'Costing',
        //     type: 'number',
        //     render: (rowData) => <a className={classes.hover} >{rowData.costing ? parseFloat(
        //         rowData.costing
        //     ).toLocaleString(undefined, {
        //         minimumFractionDigits: 2,
        //         maximumFractionDigits: 2,
        //     }) : 0}</a>
        // },
        // {
        //     field: 'promotion_type',
        //     title: 'Promotion Type',
        //     type: 'string',
        //     render: (rowData) => <a className={classes.hover} > {rowData.promotion_type}</a>
        // },
        {
            field: 'promotion_start_date',
            title: 'Start Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.promotion_start_date).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.promotion_start_date).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'promotion_end_date',
            title: 'End Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.promotion_end_date).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.promotion_end_date).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'update_date',
            title: 'Last Update',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.update_date).local().format('MM/DD/YYYY HH:mm:ss')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.update_date).local().format('MM/DD/YYYY HH:mm:ss');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'approval_status',
            title: 'Status',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.approval_status}</a>,
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            cellStyle: { minWidth: 200 },
            render: rowData =>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {rowData.processed_status === "In Progress" &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <Tooltip title='Edit'><Edit color="disabled" style={{ fontSize: 20 }} onClick={() => handleEdit(rowData)} /></Tooltip>
                        </IconButton>}
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large" >
                        <Tooltip title='Copy'><ContentCopy color="disabled" style={{ fontSize: 20 }} onClick={() => handleClone(rowData)} /></Tooltip>
                    </IconButton>
                    {rowData.processed_status === "In Progress" &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <Tooltip title='Delete'> <Delete color="disabled" style={{ fontSize: 20 }} onClick={() => handleDelete(rowData)} /></Tooltip>
                        </IconButton>}
                    {!(rowData.processed_status === "History" || rowData.processed_status === "In Progress") &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <Tooltip title='Comment'><CommentOutlined color="disabled" style={{ fontSize: 20 }} onClick={() => handleDelete(rowData)} /></Tooltip>
                        </IconButton>}
                    {!(rowData.processed_status === "History" || rowData.processed_status === "In Progress") &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <Tooltip title='Download'><FileDownload color="disabled" style={{ fontSize: 20 }} /></Tooltip>
                        </IconButton>
                    }
                    {rowData.processed_status === "Completed" &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <Tooltip title='Extended'><ExitToApp color="disabled" style={{ fontSize: 20 }} /></Tooltip>
                        </IconButton>
                    }
                    {/* <IconButton
                        classes={{ root: classes.RouteIcon }}
                        size="large">
                        <Route color="disabled" style={{ fontSize: 20 }} onClick={() => handlePreMature(rowData)} />
                    </IconButton> */}
                </div >
        },
    ];

    useEffect(() => {
        props.getFilters()
    }, []);

    useEffect(() => {
        if (passedProductLine)
            setProductType(passedProductLine[0])
    }, [passedProductLine]);

    useEffect(() => {
        if (productType)
            props.getScenarioListData(productType);
    }, [productType]);

    useEffect(() => {
        var tempRowsInProgress = [];
        var tempRowsCompleted = [];
        var tempRowsPending = [];
        var tempRowsApproved = [];
        var tempRowsHistory = [];

        if (props.sandBoxListData && Object.keys(props.sandBoxListData).length > 0) {
            Object.keys(props.sandBoxListData).map(key => {
                if (key === 'Approved' && props.sandBoxListData.Approved && props.sandBoxListData.Approved.length > 0) {
                    props.sandBoxListData.Approved.map(e => {
                        tempRowsApproved.push({
                            id: e.id,
                            scenario_name: e.scenario_name,
                            created_by: e.created_by,
                            country: e.country,
                            product_lines: e.product_lines,
                            promotion_type: e.promotion_type,
                            costing: e.costing,
                            promotion_start_date: e.promotion_start_date,
                            promotion_end_date: e.promotion_end_date,
                            creation_date: e.creation_date,
                            update_date: e.update_date,
                            month: e.month,
                            approval_status: e.approval_status,
                            processed_status: e.processed_status,
                        });
                    });
                } else if (key === 'Pending' && props.sandBoxListData.Pending && props.sandBoxListData.Pending.length > 0) {
                    props.sandBoxListData.Pending.map(e => {
                        tempRowsPending.push({
                            id: e.id,
                            scenario_name: e.scenario_name,
                            created_by: e.created_by,
                            country: e.country,
                            product_lines: e.product_lines,
                            promotion_type: e.promotion_type,
                            costing: e.costing,
                            promotion_start_date: e.promotion_start_date,
                            promotion_end_date: e.promotion_end_date,
                            creation_date: e.creation_date,
                            update_date: e.update_date,
                            month: e.month,
                            approval_status: e.approval_status,
                            processed_status: e.processed_status,
                        });
                    });
                } else if (key === 'Completed' && props.sandBoxListData.Completed && props.sandBoxListData.Completed.length > 0) {
                    props.sandBoxListData.Completed.map(e => {
                        tempRowsCompleted.push({
                            id: e.id,
                            scenario_name: e.scenario_name,
                            created_by: e.created_by,
                            country: e.country,
                            product_lines: e.product_lines,
                            promotion_type: e.promotion_type,
                            costing: e.costing,
                            promotion_start_date: e.promotion_start_date,
                            promotion_end_date: e.promotion_end_date,
                            creation_date: e.creation_date,
                            update_date: e.update_date,
                            month: e.month,
                            approval_status: e.approval_status,
                            processed_status: e.processed_status,
                        });
                    });
                } else if (key === 'In Progress' && props.sandBoxListData[key] && props.sandBoxListData[key].length > 0) {
                    props.sandBoxListData[key].map(e => {
                        tempRowsInProgress.push({
                            id: e.id,
                            scenario_name: e.scenario_name,
                            created_by: e.created_by,
                            country: e.country,
                            product_lines: e.product_lines,
                            promotion_type: e.promotion_type,
                            costing: e.costing,
                            promotion_start_date: e.promotion_start_date,
                            promotion_end_date: e.promotion_end_date,
                            creation_date: e.creation_date,
                            update_date: e.update_date,
                            month: e.month,
                            approval_status: e.approval_status,
                            processed_status: e.processed_status,
                        });
                    });
                } else if (key === 'History' && props.sandBoxListData.History && props.sandBoxListData.History.length > 0) {
                    props.sandBoxListData.History.map(e => {
                        tempRowsHistory.push({
                            id: e.id,
                            scenario_name: e.scenario_name,
                            created_by: e.created_by,
                            country: e.country,
                            product_lines: e.product_lines,
                            promotion_type: e.promotion_type,
                            costing: e.costing,
                            promotion_start_date: e.promotion_start_date,
                            promotion_end_date: e.promotion_end_date,
                            creation_date: e.creation_date,
                            update_date: e.update_date,
                            month: e.month,
                            approval_status: e.approval_status,
                            processed_status: e.processed_status,
                        });
                    });
                }
            });

            setdataRowsApproved(tempRowsApproved);
            setdataRowsPending(tempRowsPending);
            setdataRowsCompleted(tempRowsCompleted);
            setdataRowsHistory(tempRowsHistory);
            setdataRowsInProgress(tempRowsInProgress);
        } else {
            setdataRowsApproved([]);
            setdataRowsPending([]);
            setdataRowsCompleted([]);
            setdataRowsHistory([]);
            setdataRowsInProgress([]);
        }
    }, [props.sandBoxListData]);



    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group']);
            if (props.filterData['material_master'][0]['material_group'] && !isVisited)
                setProductType(props.filterData['material_master'][0]['material_group'][0])
        }
    }, [props.filterData]);

    function handleChange(e) {
        setProductType(e.target.value);
    }

    function handleAdd() {
        history.push('/promotion-scenario-setup')
    }

    function handleEdit(item) {
        props.fetchScenario(item.id, 'edit')
    }

    function handlePreMature(item) {
        history.push('/end-premature-scenario')
    }

    function handleClone(e) {
        var formData = {
            'ID': e.id,
            'product_line': e.product_lines
        }
        props.sceanrioList(null, 'clone', formData)
    }

    function handleDelete(e) {
        var formData = {
            'ID': e.id,
            'product_line': e.product_lines
        }
        props.sceanrioList(null, 'delete', formData)
    }

    // function handleExtend(e) {
    //     setExtendedData(e)
    //     setOpen(true)
    //     setExtendedStartDate(Moment.utc(e.promotion_end_date).add(1, 'days').format('MMMM DD, YYYY'))
    // }

    // function handleClose() {
    //     setOpen(false)
    //     setExtendedEndDate(null)
    // }

    // function handleSubmit() {
    //     setOpen(false);
    //     var formData = {
    //         'ID': extendedData.id,
    //         'product_line': extendedData.product_lines,
    //         'extended_scenario': true,
    //         'promotion_start_date': Moment.utc(extendedStartDate).format('MM/DD/YYYY'),
    //         'promotion_end_date': Moment.utc(extendedEndDate).format('MM/DD/YYYY'),
    //     }
    //     props.sceanrioList(null, 'extended', formData)
    // }

    // function handleExtendedEndDate(e) {
    //     setExtendedEndDate(e);
    // }

    const renderTableContent = (dataRows, columns, table) => {
        if (dataRows && dataRows.length > 0) {
            return (
                <>
                    <MaterialTable
                        components={{
                            Toolbar: (props) => <div style={{ height: '0px' }}></div>,
                        }}
                        title=" "
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginLeft: '-16px', marginRight: '-16px', marginTop: '-23px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: {
                                ...theme.mixins.MaterialHeader,
                                fontFamily: '"IBM Plex Sans"',
                            },
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    {/* {table &&
                        <div className={classes.addButton}>
                            <Tooltip title='Create new scenario'><Add onClick={handleAdd} className={classes.addIcon}></Add></Tooltip>
                        </div>
                    } */}
                </>
            );
        } else {
            return <div>
                <div className={classes.noDataContainer}>
                    There is no data to show now.
                </div>
                {/* {table &&
                    <div className={classes.addButton}>
                        <Add onClick={handleAdd} className={classes.addIcon}></Add>
                    </div>
                } */}
            </div>

        }
    };

    const handleExpand = (event, key) => {
        event.stopPropagation();
        if (!selectedFolder.includes(key))
            setSelectedFolder([...selectedFolder, key])
        else
            setSelectedFolder(selectedFolder.filter(item => item != key))
    }

    return (
        <div className={clsx(classes.root, className)}>

            <StickerTableHeader bg productLineData={productLinesList} defaultDivison={productType} onChange={handleChange} stickerName={""} />
            <div className={classes.headerTitle}>
                <Typography className={classes.titles}>SCENARIO LIST</Typography>
                <IconButton style={{ marginRight: 40 }}><GroupAddOutlined /></IconButton>
            </div>
            <div className={classes.addButton}>
                <Tooltip title='Create new scenario'><Add onClick={handleAdd} className={classes.addIcon}></Add></Tooltip>
            </div>
            <div className={classes.container}>
                <Accordion defaultExpanded style={{ marginBottom: 10 }}
                    expanded={selectedFolder && selectedFolder.includes('In Progress')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined color={selectedFolder && selectedFolder.includes('In Progress') ? 'primary' : 'default'} onClick={(event) => handleExpand(event, 'In Progress')} />}
                        aria-controls="table1-content"
                        id="table1-header"
                        className={classes.accordionSummary}
                    >
                        {selectedFolder && selectedFolder.includes('In Progress') ? <FolderOpenOutlined color="primary" className={classes.folder} /> : <Folder className={classes.folderClosed} />}
                        <Typography className={classes.titleName}>In Progress</Typography>
                        <div className={classes.spanRight}>{dataRowsInProgress && dataRowsInProgress.length}  Entries</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderTableContent(dataRowsInProgress, columns, 'In Progress')}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ marginBottom: 10 }}
                    expanded={selectedFolder && selectedFolder.includes('Pending')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined color={selectedFolder && selectedFolder.includes('Pending') ? 'primary' : 'default'} onClick={(event) => handleExpand(event, 'Pending')} />}
                        aria-controls="table2-content"
                        id="table2-header"
                        className={classes.accordionSummary}
                    >
                        {selectedFolder && selectedFolder.includes('Pending') ? <FolderOpenOutlined color="primary" className={classes.folder} /> : <Folder className={classes.folderClosed} />}
                        <Typography className={classes.titleName}> Pending </Typography>
                        <div className={classes.spanRight}>{dataRowsPending && dataRowsPending.length} Entries</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderTableContent(dataRowsPending, columns)}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ marginBottom: 10 }}
                    expanded={selectedFolder && selectedFolder.includes('Approved')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined color={selectedFolder && selectedFolder.includes('Approved') ? 'primary' : 'default'} onClick={(event) => handleExpand(event, 'Approved')} />}
                        aria-controls="table3-content"
                        id="table3-header"
                        className={classes.accordionSummary}
                    >
                        {selectedFolder && selectedFolder.includes('Approved') ? <FolderOpenOutlined color="primary" className={classes.folder} /> : <Folder className={classes.folderClosed} />}
                        <Typography className={classes.titleName}> Approved </Typography>
                        <div className={classes.spanRight}>{dataRowsApproved && dataRowsApproved.length}  Entries</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderTableContent(dataRowsApproved, columns)}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ marginBottom: 10 }}
                    expanded={selectedFolder && selectedFolder.includes('Completed')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined color={selectedFolder && selectedFolder.includes('Completed') ? 'primary' : 'default'} onClick={(event) => handleExpand(event, 'Completed')} />}
                        aria-controls="table4-content"
                        id="table4-header"
                        className={classes.accordionSummary}
                    >
                        {selectedFolder && selectedFolder.includes('Completed') ? <FolderOpenOutlined color="primary" className={classes.folder} /> : <Folder className={classes.folderClosed} />}
                        <Typography className={classes.titleName}> Completed</Typography>
                        <div className={classes.spanRight}>{dataRowsCompleted && dataRowsCompleted.length}  Entries</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderTableContent(dataRowsCompleted, columns)}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ marginBottom: 10 }}
                    expanded={selectedFolder && selectedFolder.includes('History')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined color={selectedFolder && selectedFolder.includes('History') ? 'primary' : 'default'} onClick={(event) => handleExpand(event, 'History')} />}
                        aria-controls="table5-content"
                        id="table5-header"
                        className={classes.accordionSummary}
                    >
                        {selectedFolder && selectedFolder.includes('History') ? <FolderOpenOutlined color="primary" className={classes.folder} /> : <Folder className={classes.folderClosed} />}
                        <Typography className={classes.titleName}> History</Typography>
                        <div className={classes.spanRight}>{dataRowsHistory && dataRowsHistory.length}  Entries</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderTableContent(dataRowsHistory, columns)}
                    </AccordionDetails>
                </Accordion>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        sandBoxListData: state.promotionData.scenarioListData,
        filterData: state.promotionData.promoFilters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        getScenarioListData: (productLine) => dispatch(getScenarioList(productLine)),
        fetchScenario: (id, page) => dispatch(createPromotionScenario(null, id, 'fetch_id', page)),
        sceanrioList: (data, type, value) => dispatch(createScenarioList(data, type, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionDashboard);