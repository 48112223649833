import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MaterialTable from "material-table";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography, Grid, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Article } from '@mui/icons-material';
import { Link } from '@mui/material';
import { tableIcons } from "../../../components/Icons/TableIcons";
import Moment from 'moment';
import { FormLabel } from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    notesDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 10,
        marginBottom: 10
    },
    dividerColor: {
        background: theme.palette.border.main,
        margin: '6px 0px 10px 0px'
    },
    dialogTitleRoot: {
        padding: '9px 24px'
    },
    dialogController: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
    },
    buttonContainer: {
        fontSize: 12,
    },
    dialogTitle: {
        marginLeft: 5,
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.primary.main
    }

}));

const NotesContainer = (props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [notesArray, setNotesArray] = React.useState([]);
    const [notes, setNotes] = useState('');
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    const columns = [
        {
            field: "text",
            title: "Text",
            render: (rowData) => (
                <div style={{ padding: 5, justifyContent: 'center' }}>
                    <Tooltip arrow title={<h3>{rowData.text}</h3>}>
                        {rowData.text.length > 45 ? rowData.text.slice(0, 45) + '...' : rowData.text}
                        {rowData.text.length > 45 && (
                            <Link
                                component="button"
                                variant="body2"
                                onClick={(e) => handleToggleExpand(e, rowData.text)}
                                className={classes.buttonContainer}
                            >
                                Read More
                            </Link>
                        )}
                    </Tooltip>
                </div>
            )
        },
        {
            field: "attachments",
            title: "File",
            render: (rowData) => (
                <div>
                    {rowData.attachments && rowData.attachments.length > 0 &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {rowData && rowData.attachments[0].substring(rowData.attachments[0]).split('.').pop() === 'pdf' ?

                                <a href={rowData.attachments[0]} target="_blank" style={{ display: 'flex' }}>
                                    <img src={require('../../../library/images/pdf.png')} height={25} style={{ paddingRight: 10 }} />
                                    {rowData.file_name}
                                </a>
                                :
                                rowData && rowData.attachments[0].substring(rowData.attachments[0]).split('.').pop() === 'xlsx' ?
                                    <a href={rowData.attachments[0]} target="_blank" style={{ display: 'flex' }}>
                                        <img src={require('../../../library/images/excel.png')} height={25} style={{ paddingRight: 10 }} />
                                        {rowData.file_name}
                                    </a>
                                    :
                                    <a href={rowData.attachments[0]} target="_blank" style={{ display: 'flex' }}>
                                        <img src={require('../../../library/images/image_logo.png')} height={25} style={{ paddingRight: 10 }} />
                                        {rowData.file_name}
                                    </a>
                            }
                        </div>
                    }
                </div>
            )
        },
        {
            field: "created_by",
            title: "Created By",
            render: (rowData) => (
                <div >
                    {rowData.ID ? rowData.created_by && rowData.created_by.username
                        :
                        sessionStorage.getItem('loginData') ?
                            JSON.parse(sessionStorage.getItem('loginData')).email : ''
                    }
                </div>
            )
        },
        {
            field: "created_on",
            title: "Created On",
            render: (rowData) => (
                <div >
                    {Moment(rowData.created_on).format('MM/DD/YYYY hh:mm:ss')
                    }
                </div>
            )
        },
        {
            field: "Actions",
            title: "Actions",
            type: "string",
            editable: "never",
            sorting: false,
            render: (rowData, index) => (
                <div>
                    <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() => handleRemoveCard(rowData.tableData.id)}
                        size="large">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            )
        }
    ]

    const handleRemoveCard = (index) => {

        props.onChange([
            ...props.data.slice(0, index),
            ...props.data.slice(index + 1, props.data.length)
        ]);
        setNotesArray([
            ...props.data.slice(0, index),
            ...props.data.slice(index + 1, props.data.length)
        ]);


    }
    function handleToggleExpand(event, text) {
        event.preventDefault();
        setOpen(true)
        setNotes(text)
    }
    function handleClose() {
        setOpen(false)
    }
    return (
        <div
            className={clsx(classes.root, className)}>
            {props.data && props.data.length > 0 &&
                <MaterialTable
                    components={{
                        Toolbar: (props) => (
                            <div
                                style={{
                                    height: "0px",
                                }}
                            ></div>
                        ),
                    }}
                    title={" "}
                    editable={true}
                    icons={tableIcons}
                    columns={props.mode != 'view' ? columns : columns.filter(item => item.field != 'Actions')}
                    data={props.data}
                    style={{ marginTop: '-18px', fontSize: theme.typography.h4.fontSize }}
                    options={{
                        search: false,
                        sorting: true,
                        emptyRowsWhenPaging: false,
                        headerStyle: theme.mixins.MaterialHeader,
                        cellStyle: theme.mixins.MaterialCell,
                        filtering: true,

                        //   pageSize: 10,
                        //   pageSizeOptions: [
                        //     10,
                        //     20,
                        //     50,
                        //     { value: props.data.length, label: "Show all" },
                        // ],
                    }}
                />
            }
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" className={{ root: classes.dialogTitleRoot }}>
                    <Grid container direction="row" style={{ justifyContent: 'space-between' }} >
                        <div style={{ display: 'flex' }}><Article />
                            <FormLabel className={classes.dialogTitle}>Note</FormLabel>
                        </div>
                        <Box>
                            <IconButton onClick={handleClose} style={{ paddingTop: 5 }} size="large">
                                <Close />
                            </IconButton>
                        </Box>
                    </Grid>
                </DialogTitle>


                <DialogContent dividers>
                    <Typography gutterBottom>
                        {notes}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div >
    );

}


export default connect(null, null)(NotesContainer);