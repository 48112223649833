import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Card, Button, Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { reConcile, updateOutgoingClaimData, getClaimConfiguration, outgoingClaimFinacialPosting } from '../../../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import 'react-edit-text/dist/index.css';
import { PopupContainer } from '..';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    rootMain: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    }
}));

const ErrorLines = props => {

    const classes = useStyles();
    const { className } = props;
    const [dataRows, setDataRows] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [claimAdjustmentCodes, setClaimAdjustmentCodes] = React.useState([]);
    const [onEdit, setOnEdit] = React.useState(false);
    const [currentID, setCurrentID] = React.useState('');
    const tableRef = useRef()
    const theme = useTheme();
    const [filteredData, setFilterData] = React.useState([]);
    useEffect(() => {
        props.getClaimData();
    }, []);

    useEffect(() => {
        var tempRows = [];
        if (props.outgoingClaimDetailData && props.outgoingClaimDetailData.length > 0)
            props.outgoingClaimDetailData
                .filter(e => (e.claim_status == 'Rejected' || e.claim_status == 'Disputed'))
                .sort((a, b) => a.unique_identifier > b.unique_identifier ? 1 : -1)
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        unique_identifier: e.unique_identifier,
                        claim_amount: e.claim_amount,
                        approved_amount: e.approved_amount,
                        end_customer_id: e.end_customer_id,
                        end_customer_name: e.end_customer_name,
                        contract_number: e.contract_number,
                        claim_status: e.claim_status,
                        claim_error: e.claim_error,
                        adjustment_code: e.adjustment_code,
                        material_number: e.material_number,
                        material_description: e.material_description,
                        rejection_reason: e.rejection_reason,

                    });

                })
        setDataRows(tempRows);
    }, [props.outgoingClaimDetailData]);
    useEffect(() => {

        if (props.claimData && props.claimData.length > 0) {
            setClaimAdjustmentCodes(props.claimData[0].claim_adjustment_codes)
        }

    }, [props.claimData])

    const [currentViewID, setCurrentViewID] = React.useState(0);
    const handlePopupClick = (rowData) => {
        setDialogOpen(true);
        setCurrentViewID(rowData.id);

    }

    const columns = [

        {
            field: 'unique_identifier',
            title: 'Unique Identifier',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.unique_identifier ? <div style={{
                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
            }} onClick={() => handlePopupClick(rowData)}>{rowData.unique_identifier}</div> : <div style={{ color: 'white' }}>-</div>
        },

        {
            field: 'end_customer_id',
            title: 'End Customer ID',
            type: 'string',
            render: rowData => rowData.end_customer_id ? rowData.end_customer_id : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'end_customer_name',
            title: 'End Customer Name',
            type: 'string',
            editable: 'never'
        },
        {
            field: 'material_number',
            title: '  Material Number',
            type: 'string',
            render: rowData => rowData.material_number ? rowData.material_number : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'material_description',
            title: '  Material Description',
            type: 'string',
            editable: 'never'
        },
        {
            field: 'contract_number',
            title: '  Contract Number',
            type: 'string',
            render: rowData => rowData.contract_number ? rowData.contract_number : <div style={{ color: 'white' }}>-</div>
        },

        {
            field: 'claim_amount',
            title: 'Claim Amount',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.claim_amount ? rowData.claim_amount.toFixed(2) : ''

        },

        {
            field: 'approved_amount',
            title: 'Approved Amount',
            type: 'numeric',
            editable: 'onUpdate',
            render: rowData => rowData.approved_amount ? rowData.approved_amount : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'adjustment_code',
            title: 'Adjustment Code',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }} onClick={(e) => handleSelectcell(e, rowData.id)}>
                <Select
                    style={{ display: onEdit && currentID == rowData.id ? 'inline-block' : 'none' }}
                    value={rowData.adjustment_code}
                    onChange={(e) => handleAdjustmentCode(e, rowData)}
                    displayEmpty
                    className={clsx({
                        [classes.select]: true
                    })}
                    classes={{
                        selectMenu: classes.selectedItem
                    }}
                >
                    {claimAdjustmentCodes.map((item) => {
                        return (
                            <MenuItem value={item} key={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>

                <div style={{ display: onEdit ? currentID != rowData.id ? 'inline-block' : 'none' : 'inline-block' }}>
                    {rowData.adjustment_code ?
                        <label style={{ textDecoration: 'underline dotted', cursor: 'pointer' }}>{rowData.adjustment_code}</label>
                        :
                        <div style={{ cursor: 'pointer' }}>-</div>
                    }
                </div>

            </div>
        },
        {
            field: 'rejection_reason',
            title: 'Rejection Reason',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'claim_status',
            title: 'Claim Status',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.claim_status}</div>
        },

    ];
    useEffect(() => {
        if (props.tableData && props.tableData.field_label_attributes) {
            Object.values(props.tableData.field_label_attributes).map(item => {
                if (item.display) {
                    filteredData.push(item.key)
                }
                columns.map(e => {
                    if (item.key === e.field) {
                        e.title = item.current
                    }
                })
            })
            filteredData.filter(function (elem, pos) { return filteredData.indexOf(elem) == pos })
        }
    }, [props.tableData, columns])
    const handleReconcile = () => {
        var temp = [];
        var claimN = []
        var uniqueID = []
        selectedRows.map((item1) => {
            props.outgoingClaimDetailData.map((item2) => {
                if (item2.ID === item1.id) {
                    temp.push(item2)
                    claimN.push((item2.claim_number).toString())
                    uniqueID.push((item2.unique_identifier).toString())
                }

            })
        })
        var data = {
            "claim_number": claimN,
            "unique_identifier": uniqueID
        }
        // var pathname = window.location.pathname;
        // var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        // props.reConcile(temp, appId);
        props.getOutgoingClaimsPayment(null, data)
        // setSelectedRows([]);
        // tableRef.current.onAllSelected(false)
    }
    const handleSelectcell = (e, id) => {
        setOnEdit(!onEdit)
        setCurrentID(id)

    }
    const handleAdjustmentCode = (event, rowData) => {
        var currentObject = {}
        props.outgoingClaimDetailData.map((item, index) => {
            if (item.ID === rowData.id) {
                currentObject = item
            }
        })
        currentObject['adjustment_code'] = event.target.value
        props.updateOutgoingClaimData(rowData.id, currentObject)
        setOnEdit(false)
        setCurrentID('')

    }

    const handleEditSingleCell = (item) => {
        var temp = {};
        props.outgoingClaimDetailData.map((item2) => {
            if (item2.ID === item.ID) {
                temp = item2
            }

        })
        props.updateOutgoingClaimData(item.ID, temp);
    }

    const [dialogOpen, setDialogOpen] = React.useState(false)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider className={clsx(classes.rootMain, className)}>
                <PopupContainer open={dialogOpen} onClose={(value) => setDialogOpen(value)} outgoingClaimID={currentViewID} />

                <div className={clsx(classes.root, className)}>
                    <Button size="small" onClick={handleReconcile} autoFocus color="primary"
                        variant="contained" disableRipple
                        startIcon={<RotateLeftIcon />}>
                        Reprocess
                    </Button>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div>
                                <MaterialTable
                                    tableRef={tableRef}
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    columns={columns.filter(item => filteredData.includes(item.field))}
                                    data={dataRows}
                                    style={{ marginTop: -60 }}
                                    options={{
                                        filtering: true,
                                        selection: true,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                        searchFieldStyle: {
                                            fontSize: 13
                                        },
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                                    }}
                                    onSelectionChange={(rows) => setSelectedRows(rows)}
                                    cellEditable={secureLocalStorage.getItem("dYtz").collaboration_id != 0 ? false : {
                                        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                            return new Promise((resolve, reject) => {
                                                var currentObject = props.outgoingClaimDetailData.find(item => item.ID === rowData.id);
                                                currentObject[columnDef.field] = newValue;
                                                handleEditSingleCell(currentObject)
                                                setTimeout(resolve, 1000);
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        reConcile: (data, claimNumber) => dispatch(reConcile(data, claimNumber)),
        updateOutgoingClaimData: (id, formData) => dispatch(updateOutgoingClaimData(id, formData)),
        getClaimData: () => dispatch(getClaimConfiguration()),
        getOutgoingClaimsPayment: (claimNumber, data) => dispatch(outgoingClaimFinacialPosting(claimNumber, data)),
    }
}

const mapStateToProps = state => {
    return {
        outgoingClaimDetailData: state.initialData.outgoingClaimDetailData,
        claimData: state.calculationAnalysisData.claimData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLines);