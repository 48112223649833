import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Select, MenuItem, Input, OutlinedInput
} from '@mui/material';
import { connect } from 'react-redux';
import { getDefaultValuesFinancialSimulation, putChangeEnabledNew, getAccessData } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '18px 29px 29px 29px',
        margin: '30px 0px 30px'
    },
    container: {
        paddingTop: 18,
    },
    rootDropdown: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        borderTop: '0.1px solid',
        borderTopColor: theme.palette.border.main,
        paddingTop: 5,
        paddingBottom: 5
    },
    dropdownContainer: {
        marginRight: 36
    }
}));

const FieldDisplayConfiguration = props => {
    useEffect(() => {
        props.onLoadingDefault();
        props.getAccessData();
    }, []);

    const classes = useStyles();
    const { className } = props;
    const handleSelectMultiple = (event, item) => {
        var formData = {
            "id": item.ID,
            "contract_display": event.target.value
        }
        props.onChangeEnabled(formData);
    };

    return (
        <div>
            <Typography variant="h1"> Financial Display Designer</Typography>
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.container}>
                    {props.tableData && props.tableData.records && props.tableData.records.length > 0 && props.tableData.records.map((item) => (
                        <div className={clsx({
                            [classes.rootDropdown]: true
                        })}
                        >
                            <div className={classes.label}>{item.name}</div>
                            <div className={classes.dropdownContainer}>
                                {props.accessData && props.accessData.records && props.accessData.records.length > 0 &&
                                    <Select
                                        defaultValue={item.enabled}
                                        input={<OutlinedInput />}
                                        onChange={(event) => handleSelectMultiple(event, item)}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={false} key={false}>No</MenuItem>
                                        <MenuItem value={true} key={true}>Yes</MenuItem>
                                    </Select>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );

};

const mapStateToProps = state => {
    return {
        tableData: state.addMultipleConfigurationData.financialSimulationDefaultData,
        accessData: state.addMultipleConfigurationData.accessData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesFinancialSimulation()),
        onChangeEnabled: (id, enabled) => dispatch(putChangeEnabledNew(id, enabled)),
        getAccessData: () => dispatch(getAccessData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDisplayConfiguration);