import React, { useEffect } from 'react';
import Moment from 'moment';
import clsx from 'clsx';
import { makeStyles, useTheme } from "@mui/styles";
import {
    Typography, Breadcrumbs
} from '@mui/material';
import { connect } from 'react-redux';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import ContractCell from './components/ContractCell';
import {
    getSupplierMasterData, claimErrorCount,
    getDashboardCount, runDisputedClaim
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    activeContracts: {
        backgroundColor: '#38d138'
    },
    expiredContracts: {
        backgroundColor: '#d43333'
    },
    totalContracts: {
        backgroundColor: theme.palette.primary.main
    },
    waitingApprovals: {
        backgroundColor: '#FFBC03'
    },
    newContracts: {
        backgroundColor: '#7843DF'
    },
    contractCellContainer: {
        [theme.breakpoints.down('lg')]: {
            padding: '0px 16px 18px 16px !important'
        }
    },
    hyperLink: {
        color: 'blue',
        textDecoration: 'underline'
    },
    spinner: {
        height: '80vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    tableFontSetting: {
        fontSize: theme.typography.h4.fontSize,
    }
}));

const ClaimStatusResult = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const data = sessionStorage.getItem('filterData')
    const columns = [
        data.includes('supplier_number') ? {
            field: 'supplier_number',
            title: 'Supplier Number',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.supplier_number && rowData.supplier_number.toString()}</a>
        } : {},
        data.includes('customer_number') ? {
            field: 'customer_number',
            title: 'Customer Number',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.customer_number && rowData.customer_number.toString()}</a>
        } : {},
        data.includes('material_number') ? {
            field: 'material_number',
            title: 'Material Number',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.material_number && rowData.material_number.toString()}</a>
        } : {},
        {
            field: 'new',
            title: 'New',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.new}</a>
        },
        {
            field: 'submitted',
            title: 'Submitted',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.submitted}</a>
        },
        {
            field: 'rejected',
            title: 'Rejected',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleRejected(rowData)}>
                <div style={{ color: 'blue', textDecoration: 'underline' }}>{rowData.rejected}</div>
            </a>
        },
        {
            field: 'adjusted',
            title: 'Adjusted',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.adjusted}</a>
        },
        {
            field: 'approved',
            title: 'Approved',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.approved}</a>
        },
        {
            field: 'total',
            title: 'Total',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.total}</a>

        }
    ];
    const handleCell = (type) => {
        if (type === 'rejected') {
            var sd = Moment(sessionStorage.getItem('start_date1')).format('MM/DD/YYYY');
            var ed = Moment(sessionStorage.getItem('end_date1')).format('MM/DD/YYYY');
            props.claimErrorCount(sd, ed, supplierNumber)
        }
    }
    const [dashboardCountData, setDashboardCountData] = React.useState({});
    useEffect(() => {
        setDashboardCountData(props.dashboardCountData);
        setDataRows(props.dashboardCountData)
    }, [props.dashboardCountData])
    const handleRejected = (item) => {
        var formData = {
            "claim_date": { "start_date": Moment(sessionStorage.getItem('start_date1')).format('YYYY-MM-DD'), "end_date": Moment(sessionStorage.getItem('end_date1')).format('YYYY-MM-DD') },
            "supplier_number": [item.supplier_number],
            "claim_status": ["Rejected"],
        }
        props.onsubmit(formData);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        variant="h4"
                        classes={{
                            root: classes.link,
                        }}
                        color='Primary'
                        to="/claim-status-search"
                    >Claim Status</Link>
                    <Typography color="textPrimary" variant="h4">Results </Typography>
                </Breadcrumbs>
                {sessionStorage.getItem('stat_level') === 'aggregate' && dashboardCountData && dashboardCountData[0] && Object.keys(dashboardCountData[0]).length > 0 ?
                    <div style={{ flex: 1, display: 'flex' }}>
                        <div style={{ flex: 0.2, padding: 4 }}
                            classes={{ root: classes.contractCellContainer }}
                        >
                            <ContractCell className={classes.activeContracts} heading={'Approved'} value={dashboardCountData[0].approved ? dashboardCountData[0].approved : 0} />
                        </div>
                        <div style={{ flex: 0.2, padding: 4 }}
                            classes={{ root: classes.contractCellContainer }}
                        >
                            <ContractCell className={classes.newContracts} heading={'Adjusted'} value={dashboardCountData[0].adjusted ? dashboardCountData[0].adjusted : 0} />
                        </div>
                        <div style={{ flex: 0.2, padding: 4 }}
                            classes={{ root: classes.contractCellContainer }}
                        >
                            <ContractCell className={classes.waitingApprovals} heading={'In Progress'} value={dashboardCountData[0].in_progress ? dashboardCountData[0].in_progress : 0} />
                        </div>
                        <div style={{ flex: 0.2, padding: 4 }}
                            classes={{ root: classes.contractCellContainer }}
                        >
                            <ContractCell className={classes.expiredContracts} heading={'Rejected'} value={dashboardCountData[0].rejected ? dashboardCountData[0].rejected : 0} onClick={() => handleCell('rejected')} />
                        </div>
                        <div style={{ flex: 0.2, padding: 4 }}
                            classes={{ root: classes.contractCellContainer }}
                        >
                            <ContractCell className={classes.totalContracts} heading={'Total Claim Lines'} value={dashboardCountData[0].total ? dashboardCountData[0].total : 0} />
                        </div>
                    </div>
                    : dashboardCountData && dashboardCountData.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns.filter(value => Object.keys(value).length !== 0)}
                            data={dataRows}
                            options={{
                                search: false,
                                sorting: false,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                filtering: true,
                                pageSize: 15,
                                pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        />
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                }
            </div>
        </LoadingOverlay>
    );
}


const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        supplierMasterData: state.customerData.supplierMasterData,
        dashboardCountData: state.initialData.dashboardCountData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        claimErrorCount: (sd, ed) => dispatch(claimErrorCount(sd, ed)),
        loadDashboardCount: (sd, ed, supplier, type, page) => dispatch(getDashboardCount(sd, ed, supplier, type, page)),
        onsubmit: (formData) => dispatch(runDisputedClaim(formData)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ClaimStatusResult);