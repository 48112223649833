import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Breadcrumbs
} from '@mui/material';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {
    runContractReports, runDynamicQuery, runCalculationReports, runFinancialReports,
    runInvoiceReports, getQueryListAdmin, loadTargetData
} from '../../redux/actions';
import Moment from 'moment';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tableRoot: {
        display: 'flex', flexDirection: 'column'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const SelectOperationalReports = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [currentTargetData, setCurrentTargetData] = React.useState({});

    useEffect(() => {
        props.loadTargetData();
    }, [])

    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/select-operational-reports'
                >
                    Operational Reports
                </Link>
                <Typography color="textPrimary" variant='h4'>Report</Typography>
            </Breadcrumbs>
            {props.targetData && props.targetData[0] && (Object.keys(props.targetData[0]).length > 0) ?
                <TableContainer>
                    <Table>
                        <TableHead >
                            <TableRow  >
                                <TableCell>Contract Number</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Sold to party</TableCell>
                                <TableCell>Target Type</TableCell>
                                <TableCell>Target</TableCell>
                                <TableCell>Ship to party</TableCell>
                                <TableCell>Payer</TableCell>
                                <TableCell>Bill to party</TableCell>
                                <TableCell>Supplier number</TableCell>
                                <TableCell>Material number</TableCell>
                                <TableCell>Employee number</TableCell>
                                <TableCell>Calender year month</TableCell>
                                <TableCell>Created at</TableCell>
                                <TableCell>updated at</TableCell>
                                <TableCell>Deleted at</TableCell>
                                <TableCell>ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }} >
                            {props.targetData && props.targetData.map(item => {
                                return (
                                    <StyledTableRow>
                                        <TableCell>{item['contract_numer']}</TableCell>
                                        <TableCell>{item['start_date'] === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item['start_date']).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{item['end_date'] === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item['end_date']).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{item['sold_to_party']}</TableCell>
                                        <TableCell>{item['target_type']}</TableCell>
                                        <TableCell>{item['target']}</TableCell>
                                        <TableCell>{item['ship_to_party']}</TableCell>
                                        <TableCell>{item['payer']}</TableCell>
                                        <TableCell>{item['bill_to_party']}</TableCell>
                                        <TableCell>{item['supplier_number']}</TableCell>
                                        <TableCell>{item['material_numer']}</TableCell>
                                        <TableCell>{item['employee_number']}</TableCell>
                                        <TableCell>{item['calendar_year_month'] === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item['calendar_year_month']).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{item['created_at'] === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item['created_at']).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{item['updated_at'] === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item['updated_at']).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{item['deleted_at'] === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item['deleted_at']).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell>{item['ID']}</TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" > No data to show</Typography>
            }

        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        queryListAdminData: state.operationalReportsData.queryListAdminSuccessData,
        targetData: state.operationalReportsData.targetData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        runQueryWithoutFilters: (data) => dispatch(runDynamicQuery(data)),
        loadContractDetails: () => dispatch(runContractReports(1, 10)),
        loadContractOverlap: () => dispatch(runContractReports(1, 10, '', 'overlap')),
        loadCalculationSimulation: () => dispatch(runCalculationReports(1, 10, '')),
        loadFinancialReports: () => dispatch(runFinancialReports(1, 10)),
        loadInvoiceDetails: () => dispatch(runInvoiceReports(1, 10)),
        getQueryListAdmin: () => dispatch(getQueryListAdmin()),
        runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id)),
        loadTargetData: () => dispatch(loadTargetData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOperationalReports);