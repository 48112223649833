import React, { useEffect, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,IconButton,Dialog,Grid,FormLabel,DialogContent, DialogTitle, Box, Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { LabelText } from '../../components/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import 'react-edit-text/dist/index.css';
import Moment from 'moment';
import { getSalesDataDetailsById } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    rootMain: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 9
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        width: '100%'
    },
    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
    rootIcon: {
        padding: 0
    },
    data: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
        alignItems: 'center',
        height: 36,
        display: 'flex'
    },
    root1: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    baseColor:{
        color:theme.palette.primary.main,
    }
}));

const DisplaySalesInvoiceDetailsPopup = props => {
    const classes = useStyles();
    const { className } = props;

    const [outgoingClaimData, setOutgoingClaimData] = React.useState([]);

    useEffect(() => {

        if (props.outgoingClaimID && props.outgoingClaimDetailData) {
            var data = props.outgoingClaimDetailData
            setOutgoingClaimData(data)
        }
    }, [props.outgoingClaimDetailData, props.outgoingClaimID])

    console.log("popup", props.outgoingClaimDetailData)
    console.log("array", outgoingClaimData)
    const keywords = [
        "transaction_type",
        "calendar_year_month",
        "billing_date",
        "billing_doc_number",
        "billing_doc_line",
        "customer_number",
        "material_number",
        "net_value",
        "quantity",
        "cost",
        "base_uom",
        "bill_to_party",
        "billed_uom",
        "billing_doc_cancelled",
        "document_changed_on",
        "document_created_on",
        "ima360_load_by",
        "ima360_load_on",
        "billing_type",
        "cancelled_billing_doc",
        "company_code",
        "controlling_area",
        "cost_center",
        "country",
        "currency",
        "customer_chain",
        "customer_classification",
        "customer_group",
        "customer_industry",
        "customer_name",
        "debit_credit_indicator",
        "delivery_doc_line",
        "delivery_doc_number",
        "delivery_type",
        "distribution_channel",
        "division",
        "document_category",
        "employee_name",
        "employee_number",
        "fiscal_year_month",
        "higher_level_bom_item",
        "ima_ineligible",
        "ima_ineligible_application",
        "item_category",
        "material_description",
        "material_group",
        "material_group1",
        "material_group2",
        "material_type",
        "membership_owner_id",
        "mfr_part_number",
        "order_reason",
        "original_invoice_for_return",
        "original_invoice_line_for_return",
        "payer",
        "payment_terms",
        "pricing_procedure",
        "product_hierarchy",
        "profit_center",
        "region",
        "sales_district",
        "sales_group",
        "sales_office",
        "sales_order_line",
        "sales_order_number",
        "sales_order_type",
        "sales_org",
        "ship_to_party",
        "sold_to_party",
        "source_system",
        "supplier_customer_number",
        "supplier_group",
        "supplier_name",
        "supplier_number",
        "delivery_tracking_number",
        "membership_owner_name",
        "product_group_id",
        "product_group_name",
        "class_of_trade",

    ]

    const editableLines = []

    function handleClose() {
        props.onClose(false)
    }

    return (
        <Dialog open={
            props.open}
            fullScreen
            style={{ margin: 15, height: 'auto' }}
        >
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <h2 className={classes.baseColor}>  Sales Invoice Details</h2>
                    <Box>
                        <IconButton onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>

            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialogContent
            }}
            >
                {/* {JSON.stringify(currentViewData)} */}
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container >
                                {outgoingClaimData &&
                                    keywords.map(item => {

                                        return (
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                {editableLines.includes(item) ?
                                                    <Grid container
                                                        className={clsx(classes.root1)}
                                                        style={{ paddingTop: 10 }}
                                                    >
                                                        <Grid item style={{ width: '100%', marginLeft: -5, paddingRight: 25 }}>
                                                            <FormLabel style={{ paddingLeft: 5, textTransform: 'capitalize', paddingTop: 15 }}>
                                                                {outgoingClaimData && Object.keys(outgoingClaimData).includes(item) ? item.replace(/_/g, ' ') : ''}
                                                            </FormLabel>
                                                            <div className={classes.data}
                                                            >
                                                                {
                                                                    <div style={{ whiteSpace: 'nowrap', paddingLeft: 10 }} >
                                                                        <Select

                                                                            value={outgoingClaimData && outgoingClaimData[item] ? outgoingClaimData[item] : ''}

                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >
                                                                        </Select>

                                                                        <div style={{ display: 'block', paddingLeft: 10 }}>
                                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                                                <div style={{ marginTop: 2, marginRight: 5 }}>
                                                                                    {outgoingClaimData && outgoingClaimData[item] ? outgoingClaimData[item] : ''}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                }
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    :
                                                    <LabelText heading={outgoingClaimData && Object.keys(outgoingClaimData).includes(item) ? item.replace(/_/g, ' ') : ''} style={{ textTransform: 'capitalize' }} data={outgoingClaimData && outgoingClaimData[item] ? Moment(outgoingClaimData[item], Moment.ISO_8601, true).isValid() && outgoingClaimData[item].toString().charAt(outgoingClaimData[item].length - 1) === 'Z' ? Moment(outgoingClaimData[item]).format('MM/DD/YYYY') : outgoingClaimData[item] : ''} twoline='true' />
                                                }
                                            </Grid>
                                        );
                                    }

                                    )}

                            </Grid>
                        </div>
                    </form>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getSalesDataDetailsById: (id) => dispatch(getSalesDataDetailsById(id)),
    }
}
const mapStateToProps = state => {
    return {
        outgoingClaimDetailData: state.dataSetupData.SalesDataDetailsSingle
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySalesInvoiceDetailsPopup);