import Palette from '../palette';
import typography from '../typography'

export default {
    root: {
        border: '1px solid',
        borderColor:  Palette.border.main,
        padding: '7px 0 8px 10px',
        fontSize:typography.h3.fontSize
    }
}