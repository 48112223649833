import React, { useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TextField,
  OutlinedInput,
  FormLabel,
  Switch,
  Dialog,
  DialogActions,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import {
  getDefaultValuesAllDataNew,
  getCalculationSimulationDataFields,
  getDynamicQueryFields,
  postDynamicQuery,
  getDynamicQuerySalesDataFields,
  getPurchaseDataFields,
  getDefaultValuesAllData,
  getDynamicQuery,
  getDynamicQueryFieldsNew,
} from "../../redux/actions";
import { connect } from "react-redux";
import { history } from "../../components/Helpers";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  container: {
    padding: 10,
  },
  gridContainer: {
    padding: "0px 16px 0px 16px",
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  error: {
    border: "1px solid red !important",
  },
  deleteIcon: {
    backgroundColor: "red",
    borderRadius: 0,
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 28,
    paddingTop: 30,
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: 10,
    },
  },
  formControlRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 6,
  },
  paper: { minWidth: window.screen.width * 0.5 },
  select: {
    width: "100%",
    marginTop: 10,
  },
  center: {
    textAlign: "center",
  },
  tabHead: {
    backgroundColor: theme.palette.primary.main,
    fontSize:theme.typography.h4.fontSize
  },
}));

const ViewDynamicQuery = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [currentPage, setCurrentPage] = React.useState("");

  useEffect(() => {
    var [first, second, third] = location.pathname.split("/");
    setCurrentPage(third);
  }, [location]);

  const [open, setOpen] = React.useState(false);
  const [queryName, setQueryName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [bomPartName, setBomPartName] = React.useState("");
  const [bomDescription, setBomDescription] = React.useState("");
  const [bomPartNumber, setBomPartNumber] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [uom, setUom] = React.useState("");
  const [incomingRoyalty, setIncomingRoyalty] = React.useState("");
  const [outgoingRoyalty, setOutgoingRoyalty] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
  const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
  const [averageFieldsArray, setAverageFieldsArray] = React.useState([]);
  const [outputFieldsArray, setOutputFieldsArray] = React.useState({});
  const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
  const [sortOrderArray, setSortOrderArray] = React.useState([]);
  const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] =
    React.useState([]);
  const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
  const [currentEditId, setCurrentEditId] = React.useState("");
  useEffect(() => {
    var pathname = window.location.pathname;
    var appId = pathname.substring(pathname.lastIndexOf("/") + 1);
    if (appId) props.getDynamicQuery(appId);
    setOpen(true);
    props.getDefaultValuesAllData();
  }, []);
  const [arrayOfKeysSorted, setArrayOfKeysSorted] = React.useState([]);
  const [tableName, setTableName] = React.useState("");
  useEffect(() => {
    if (props.dynamicQueryDetailsData) {
      if (props.dynamicQueryDetailsData.query_type) {
        if (props.dynamicQueryDetailsData.query_type === 3) {
          // setCurrentPage('partner-statement')
        } else {
          // setCurrentPage('dynamic-query')
        }
      }
      // setCurrentPage(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
      // props.onLoadingLabelDesignerNew(
      //   "field-label-designer",
      //   props.dynamicQueryDetailsData.app_type,
      //   props.dynamicQueryDetailsData.data_source_type
      // );
      props.getDynamicQueryFields(
        props.dynamicQueryDetailsData.app_type,
        props.dynamicQueryDetailsData.table_description
      );
      // props.getDynamicQueryFields(props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.data_source_type, props.dynamicQueryDetailsData.data_source)
      setDescription(props.dynamicQueryDetailsData.query_description);
      setQueryName(props.dynamicQueryDetailsData.format_name);
      setFilterFieldsArray(props.dynamicQueryDetailsData.filter_fields);
      setAverageFieldsArray(props.dynamicQueryDetailsData.avg_fields);
      setSumFieldsArray(props.dynamicQueryDetailsData.sum_fields);
      setAggregateFieldsArray(props.dynamicQueryDetailsData.groupby_fields);
      setCurrentEditId(props.dynamicQueryDetailsData.id);
      setTableName(props.dynamicQueryDetailsData.table_name);
      if (props.dynamicQueryDetailsData && props.dynamicQueryDetailsData.sort_fields) {
        setArrayOfKeysSorted( props.dynamicQueryDetailsData.sort_fields);
        var arrayToObject = Object.assign({}, ...props.dynamicQueryDetailsData.sort_fields.map((x) => ({ [x.sort_field]: x.sort_order })));
        setOutputFieldsArray(arrayToObject);
      }
    }
  }, [props.dynamicQueryDetailsData]);
  const [editIndex, setEditIndex] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);
  function handleClear() {
    setEditIndex("");
    setEditMode("");
    setBomPartNumber("");
    setBomPartName("");
    setQuantity("");
    setUom("");
    setIncomingRoyalty("");
    setOutgoingRoyalty("");
    setNotes("");
  }
  function handleOnSubmit() {
    var unsortedFields = [];
    if (
      outputFieldsArray ||
      (sumFieldsArray.length > 0 &&
        filterFieldsArray.length === 0 &&
        outputFieldsArray.length === 0 &&
        aggregateFieldsArray.length === 0)
    ) {
      for (var propName in outputFieldsArray) {
        unsortedFields.push(propName);
        if (outputFieldsArray[propName] === 0) {
          delete outputFieldsArray[propName];
        }
      }
      var allFields = {
        filter_fields: filterFieldsArray,
        sum_fields: sumFieldsArray,
        avg_fields: averageFieldsArray,
        sort_fields: outputFieldsArray,
        output_fields: unsortedFields,
        groupby_fields: aggregateFieldsArray,
        format_name: queryName,
        table_name: tableName,
        id: currentEditId,
        query_description: description,
      };
      if (currentPage === "partner-statement") {
        props.onSubmitPartnerStatement(allFields, "edit");
      } else {
        props.onSubmit(allFields, "edit");
      }
    } else {
      alert("Please select atleast one output or only sum field");
    }
  }
  const handleChange = (event, item, type) => {
    if (type === "filter_fields") {
      if (filterFieldsArray.includes(item)) {
        setFilterFieldsArray(
          filterFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        setFilterFieldsArray([...filterFieldsArray, item]);
      }
    }
    if (type === "aggregate_fields") {
      if (aggregateFieldsArray.includes(item)) {
        if (sumFieldsArray.includes(item)) {
          if (outputFieldsArray.hasOwnProperty(item)) {
          }
        } else {
          setAggregateFieldsArray(
            aggregateFieldsArray.filter((item1) => item1 !== item)
          );
        }
      } else {
        setAggregateFieldsArray([...aggregateFieldsArray, item]);
      }
    }
    if (type === "sum_fields") {
      if (sumFieldsArray.includes(item)) {
        setSumFieldsArray(sumFieldsArray.filter((item1) => item1 !== item));
      } else {
        setSumFieldsArray([...sumFieldsArray, item]);
      }
    }
    if (type === "avg_fields") {
      if (averageFieldsArray.includes(item)) {
        setAverageFieldsArray(
          averageFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        setAverageFieldsArray([...averageFieldsArray, item]);
      }
    }
    if (type === "output_fields") {
      if (outputFieldsArray.hasOwnProperty(item)) {
        var tempArray = outputFieldsArray;
        delete tempArray[item];
        setOutputFieldsArray({ ...tempArray });
      } else {
        setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
      }
    }
    if (type === "sort_order") {
      setSortOrderArray(event.target.value);
      setOutputFieldsArray({
        ...outputFieldsArray,
        [item]: event.target.value ? parseInt(event.target.value) : null,
      });
      event.preventDefault();
    }
  };
  const [functionalityName, setFunctionalityName] = React.useState("");
  const handleFunctionalityName = (e) => {
    setFunctionalityName(e.target.value);
    if (e.target.value === "Financial Postings Data") {
      props.getDynamicQueryFields();
    } else if (e.target.value === "Sales Data") {
      props.getDynamicQuerySalesDataFields();
    } else {
      props.getCalculationSimulationDataFields();
    }
  };
  const [allOutput, setAllOutput] = React.useState(false);
  
  const [rearrange, setRearrange] = React.useState(false);

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h4"
          classes={{
            root: classes.link,
          }}
          component="button"
          onClick={() => {
            history.push("/on-demand-analytics");
          }}
        >
          On Demand Analytics
        </Link>
        <Typography color="textPrimary" variant="h4">
          View Query
        </Typography>
      </Breadcrumbs>
      <div className={classes.bodyContainer}>
        <Card>
          <form autoComplete="off" noValidate>
            <div className={classes.container}>
              <Grid container spacing={2}></Grid>
            </div>
          </form>
        </Card>

        <div className={classes.container2}>
          <Grid container>
            <Grid item xs={12} classes={{ root: classes.gridContainer }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h3" color={"primary"}>
                  {props.dynamicQueryDetailsData &&
                  props.dynamicQueryDetailsData.format_name
                    ? props.dynamicQueryDetailsData.format_name
                    : ""}
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color={rearrange ? "primary" : "secondary"}
                    className={classes.button}
                    size="small"
                    startIcon={<EditSharpIcon />}
                    onClick={() => setRearrange(!rearrange)}
                  >
                    Re-arrange
                  </Button>
                </div>
              </div>
              {arrayOfKeysSorted && arrayOfKeysSorted.length > 0 ? (
                <Paper className={classes.root}>
                  <div style={{ margin: 15 }}>
                    <TableContainer style={{ overflowX: "initial" }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tabHead}
                            >
                              Field Name
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tabHead}
                            >
                              Filter
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tabHead}
                            >
                              Aggregate
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tabHead}
                            >
                              Average
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tabHead}
                            >
                              Sum
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tabHead}
                            >
                              Output
                            </TableCell>
                            {/* <TableCell align='center' className={classes.tabHead}> <FormControlLabel
                                                            value="external"
                                                            control={<Checkbox color="primary"
                                                                onChange={handleAllOutput}
                                                                checked={allOutput}
                                                            />}
                                                            label={<div style={{ fontSize: 14 }}>Output</div>}
                                                            labelPlacement="right"
                                                            style={{ fontSize: 14 }}
                                                            classes={{ root: 'fontSize: 14px' }}
                                                        /></TableCell> */}
                            <TableCell align="center">Sort Order</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                          {arrayOfKeysSorted && arrayOfKeysSorted.length > 0 && arrayOfKeysSorted.map(item => {
                              return (
                                <TableRow key={item}>
                                  <TableCell
                                    style={{
                                      textAlign: "center",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.label_name}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={
                                        filterFieldsArray &&
                                        filterFieldsArray.includes(item.sort_field)
                                          ? filterFieldsArray.includes(item.sort_field)
                                          : ""
                                      }
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                      onChange={
                                        rearrange
                                          ? (e) =>
                                              handleChange(
                                                e,
                                                item.sort_field,
                                                "filter_fields"
                                              )
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={
                                        aggregateFieldsArray &&
                                        aggregateFieldsArray.includes(item.sort_field)
                                          ? aggregateFieldsArray.includes(item.sort_field)
                                          : ""
                                      }
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                      onChange={
                                        rearrange
                                          ? (e) =>
                                              handleChange(
                                                e,
                                                item.sort_field,
                                                "aggregate_fields"
                                              )
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={
                                        averageFieldsArray &&
                                        averageFieldsArray.includes(item.sort_field)
                                          ? averageFieldsArray.includes(item.sort_field)
                                          : ""
                                      }
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                      onChange={
                                        rearrange
                                          ? (e) =>
                                              handleChange(
                                                e,
                                                item.sort_field,
                                                "avg_fields"
                                              )
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={
                                        sumFieldsArray &&
                                        sumFieldsArray.includes(item.sort_field)
                                          ? sumFieldsArray.includes(item.sort_field)
                                          : ""
                                      }
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                      onChange={
                                        rearrange
                                          ? (e) =>
                                              handleChange(
                                                e,
                                                item.sort_field,
                                                "sum_fields"
                                              )
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={
                                        outputFieldsArray &&
                                        outputFieldsArray.hasOwnProperty(item.sort_field)
                                          ? outputFieldsArray.hasOwnProperty(
                                              item.sort_field
                                            )
                                          : ""
                                      }
                                      color="primary"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                      onChange={
                                        rearrange
                                          ? (e) =>
                                              handleChange(
                                                e,
                                                item.sort_field,
                                                "output_fields"
                                              )
                                          : null
                                      }
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: 70 }}>
                                    <TextField
                                      key={["recipient", item.sort_field].join("_")}
                                      type="number"
                                      variant="outlined"
                                      value={
                                        outputFieldsArray[item.sort_field] ||
                                        outputFieldsArray[item.sort_field] === 0
                                          ? outputFieldsArray[item.sort_field]
                                          : ""
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }}
                                      onChange={
                                        rearrange
                                          ? (e) =>
                                              handleChange(
                                                e,
                                                item.sort_field,
                                                "sort_order"
                                              )
                                          : null
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Paper>
              ) : (
                <Typography variant="h4">
                  There is no data to show now.
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        {rearrange && (
          <div className={classes.buttonRoot}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => setRearrange(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleOnSubmit}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDynamicQuerySalesDataFields: () =>
      dispatch(getDynamicQuerySalesDataFields()),
    getCalculationSimulationDataFields: () =>
      dispatch(getCalculationSimulationDataFields()),
    onSubmit: (data, edit) => dispatch(postDynamicQuery(data, edit)),
    getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
    onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) =>
      dispatch(
        getDefaultValuesAllDataNew(command, applicationType, sourceDataType)
      ),
    getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
    getPurchaseDataFields: () => dispatch(getPurchaseDataFields()),
    getDynamicQueryFields: (
      applicationType,
      sourceDataType,
      functionalityName
    ) =>
      dispatch(
        getDynamicQueryFieldsNew(
          applicationType,
          sourceDataType,
          functionalityName
        )
      ),
    onSubmitPartnerStatement: (data) =>
      dispatch(postDynamicQuery(data, null, "partner")),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    dropdownData: state.customerData.dropdownData,
    dropdownDataCustomer: state.customerData.dropdownDataCustomer,
    dynamicQueryFieldsData:
      state.operationalReportsData.dynamicQueryFieldsDataNew,
    calculationFieldsData: state.customerData.calculationSimulationData,
    formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    dynamicQueryDetailsData:
      state.operationalReportsData.dynamicQueryDetailsData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDynamicQuery);
