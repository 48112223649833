import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  FormLabel,
  MenuItem,
  Select,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  addCollaborationDesigner,
  allowedApps
} from "../../redux/actions";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  container: {
    padding: 10,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  selectRoot: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  select: {
    width: "100%",
  },
  selectedItem: {
    color: theme.palette.text.grey,
    marginTop: 10,
    width: "100%",
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 28,
  },
  dropdownContainer: {
    maxHeight: 50,
    overflowY: "scroll",
  },
  btn: {
    marginRight: 25,
    padding: "10px,15px",
  },
  formLabel: {
    fontSize: theme.typography.h3.fontSize,
    height: 30,
    display: "flex",
    alignItems: "flex-end",
  },
}));
const AddCollaborationDesigner = (props) => {
  const classes = useStyles();
  const [applicationType, setApplicationType] = React.useState("");
  const [allowedApps, setAllowedApps] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [filtersArray, setFiltersArray] = React.useState([]);
  const [functionality, setFunctionality] = React.useState("");
  const [accessType, setAccessType] = React.useState("");
  const [identifier, setIdentifier] = React.useState("");
  const functionalityArray = ["Exception Management", "Claim Reconcile", "Contract SetUp", "On Demand Queries", "On Demand Videos", "GPT", "Agent Chat", "Calculation Analysis"];
  const [accessTypeArray, setAccessTypeArray] = React.useState(["Create", "Change", "Display"]);
  const [identifierArray, setIdentifierArray] = React.useState(["Contract Number", "Customer Number", "Supplier Number", "Employee Number"]);
  const [attributeObjectAPI, setAttributeObjectAPI] = React.useState([]);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        overflowY: 'scroll',
        scrollBehaviour: ' smooth',
        MarginTop: 0,
        menuPlacement: "top"
      }
    }, getContentAnchorEl: null,
  }
  useEffect(() => {
    props.allowedApps();
  }, []);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
      setApplicationType(props.allowedAppsData[0]);
      setIdentifierArray(["Customer Number"])
    }
  }, [props.allowedAppsData]);
  const handleApplicationtype = (e) => {
    setApplicationType(e.target.value);
    setIdentifierArray(["Contract Number", "Customer Number", "Supplier Number", "Employee Number"]);
  };
  function handleOnSubmit() {
    Object.entries(attributeObjectAPI).map(([key, value]) => {
      if (filters.includes(key)) {
      } else {
        delete attributeObjectAPI[key];
      }
    });
    var atObj = attributeObjectAPI;

    atObj["application_type"] = applicationType;
    var temp = [];
    temp.push(accessType)
    var data = {
      app_type: applicationType,
      functionality: functionality,
      access_type: temp,
      identifier: identifier,
    };
    props.onSubmit(data);
  }
  const handleFunctionality = (e) => {
    setFunctionality(e.target.value);
    if (e.target.value === 'On Demand Videos' || e.target.value === 'GPT' || e.target.value === 'Calculation Analysis' || e.target.value === 'On Demand Queries') {
      setAccessTypeArray(["Display"])
    }
    else {
      setAccessTypeArray(["Create", "Change", "Display"]);
    }
  };
  const handleAccessType = (e) => {
    setAccessType(e.target.value);
  };
  const handleIdentifier = (e) => {
    setIdentifier(e.target.value);
  };
  return (
    <div className={classes.root}>
      <Typography color="primary" variant="h1">
        Add Collaboration Designer
      </Typography>
      <div className={classes.bodyContainer}>
        <Card>
          <form autoComplete="off" noValidate>
            <div className={classes.container}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formLabel} required>
                    Application Type
                  </FormLabel>
                  <Select
                    value={applicationType}
                    onChange={handleApplicationtype}
                    displayEmpty
                    style={{ textTransform: "capitalize" }}
                    MenuProps={{ ...MenuProps, autoFocus: true }}
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    {allowedApps.map((item, index) => {
                      return (
                        <MenuItem
                          value={item}
                          key={index}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formLabel} required>
                      Functionality
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={functionality}
                        onChange={handleFunctionality}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                      >
                        {functionalityArray &&
                          functionalityArray.map((item) => {
                            return (
                              <MenuItem value={item} key={item}>
                                {item.replace(/-/g, " ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formLabel} required>
                      Access Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={accessType}
                        onChange={handleAccessType}
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        displayEmpty
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                      >
                        {accessTypeArray &&
                          accessTypeArray.map((item) => {
                            return (
                              <MenuItem value={item} key={item}>
                                {item.replace(/-/g, " ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formLabel} required>
                      Identifier
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={identifier}
                        onChange={handleIdentifier}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                      >
                        {identifierArray &&
                          identifierArray.map((item) => {
                            return (
                              <MenuItem value={item} key={item}>
                                {item.replace(/-/g, " ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </Card>
        <div className={classes.buttonRoot}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOnSubmit}
            disabled={applicationType && functionality && accessType && identifier ? false : true}
          >
            {props.loadingAPI ? (
              <BeatLoader
                color={"rgb(54, 215, 183)"}
                loading={props.loadingAPI}
                size={6}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    loadingAPI: state.customerData.loading,
    allowedAppsData: state.initialData.allowedApps
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(addCollaborationDesigner(data)),
    allowedApps: () => dispatch(allowedApps())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCollaborationDesigner);