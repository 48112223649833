import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem, OutlinedInput,
    TextField
} from '@mui/material';
import SubmitContainer from '../../../components/Containers/SubmitContainer';
import { runInvoiceReports, getDefaultValues } from '../../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ChipInput from 'material-ui-chip-input';
import { toast } from 'react-toastify';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Moment from 'moment';
import MomentUtils from '@date-io/moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10
    },
    container: {
        paddingTop: 18
    },
    leftGridContainer: {
        paddingRight: 10
    },
    calendarLeftGridContainer: {
        display: 'flex',
        flexDirection: 'row',
        // marginTop: 15,
        padding: '0px 10px 0px 10px'
    },
    rightGridContainer: {
        paddingLeft: 10
    },
    submitContainer: {
        marginTop: 15
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        //marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        //marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontSize: theme.typography.h4.fontSize
    },
    dropdownSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 15
    },
    multiSelect: {
        marginTop: 10
    },
    textInput: {
        marginTop: 10
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    textInputHeight: {
        height: 17
    },
    error: {
        border: '1px solid red !important'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const InvoiceReportsTab = props => {
    const classes = useStyles();
    const { className } = props;
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [billingDocMultiple, setBillingDocMultiple] = React.useState([]);
    const [billingDocRange, setBillingDocRange] = React.useState('');
    const [billingDocLineMultiple, setBillingDocLineMultiple] = React.useState([]);
    const [billingDocLineRange, setBillingDocLineRange] = React.useState('');
    const [billingType, setBillingType] = React.useState([]);
    const [companyCodeMultiple, setCompanyCodeMultiple] = React.useState([]);
    const [salesOrgMultiple, setSalesOrgMultiple] = React.useState([]);
    const [soldToPartyMultiple, setSoldToPartyMultiple] = React.useState([]);
    const [soldToPartyRange, setSoldToPartyRange] = React.useState('');
    const [vendorMultiple, setVendorMultiple] = React.useState([]);
    const [vendorRange, setVendorRange] = React.useState('');
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [materialNumberRange, setMaterialNumberRange] = React.useState('');
    const [salesOrderTypeMultiple, setSalesOrderTypeMultiple] = React.useState([]);
    const [imaEligible, setImaEligible] = React.useState('');
    const [itemCategory, setItemCategory] = React.useState([]);
    const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [customerChainMultiple, setCustomerChainMultiple] = React.useState([]);
    const [customerGroupMultiple, setCustomerGroupMultiple] = React.useState([]);
    const [vendorGroupMultiple, setVendorGroupMultiple] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [materialTypeMultiple, setMaterialTypeMultiple] = React.useState([]);
    const [productHierarchyMultiple, setProductHierarchyMultiple] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);
    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    const [billingDocCreatedStart, setBillingDocCreatedStart] = React.useState(null);
    const [billingDocCreatedEnd, setBillingDocCreatedEnd] = React.useState(null);
    const [billingDocExtractedStart, setBillingDocExtractedStart] = React.useState(null);
    const [billingDocExtractedEnd, setBillingDocExtractedEnd] = React.useState(null);
    const [disableBillingDocRange, setDisableBillingDocRange] = React.useState(false);
    const [disableVendorRange, setDisableVendorRange] = React.useState(false);
    const [billingDocNumberKey, setBillingDocNumberKey] = React.useState('');
    const [billingDocCreatedStartTime, setBillingDocCreatedStartTime] = React.useState(null);
    const [billingDocCreatedEndTime, setBillingDocCreatedEndTime] = React.useState(null);
    const [billingDocExtractedStartTime, setBillingDocExtractedStartTime] = React.useState(null);
    const [billingDocExtractedEndTime, setBillingDocExtractedEndTime] = React.useState(null);





    const handleMultipleBillingDoc = (newValue) => {
        setBillingDocMultiple(newValue);
        if (billingDocMultiple.length > 0) {
            setDisableBillingDocRange(true);
            setBillingDocRange('');
            setBillingDocNumberKey('billing_doc_number.in');
        } else {
            setDisableBillingDocRange(false);
        }
    };
    const handleMultipleBillingDocLine = (newValue) => {
        setBillingDocLineMultiple(newValue);
    };
    /*const handleBillingType = (event) => {
        setBillingTypeMultiple(event.target.value);
    }*/
    const handleCompanyCodeMultiple = (newValue) => {
        setCompanyCodeMultiple(newValue);
    }
    const handleSalesOrgMultiple = (newValue) => {
        setSalesOrgMultiple(newValue);
    }
    const handleCustomerChainMultiple = (newValue) => {
        setCustomerChainMultiple(newValue);
    }
    const handleCustomerGroupMultiple = (newValue) => {
        setCustomerGroupMultiple(newValue);
    }
    const handleSoldToPartyMultiple = (newValue) => {
        setSoldToPartyMultiple(newValue);
    }
    const handleVendorMultiple = (newValue) => {
        setVendorMultiple(newValue);
    }
    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }
    const handleSalesOrderTypeMultiple = (newValue) => {
        setSalesOrderTypeMultiple(newValue);
    }
    const handleImaEligible = (event) => {
        setImaEligible(event.target.value);
    };
    /* const handleItemCategoryMultiple = (event) => {
         setItemCategoryMultiple(event.target.value);
     }*/
    const handleVendorGroupMultiple = (newValue) => {
        setVendorGroupMultiple(newValue);
    }
    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }
    const handleMaterialTypeMultiple = (newValue) => {
        setMaterialTypeMultiple(newValue);
    }
    const handleProductHierarchyMultiple = (newValue) => {
        setProductHierarchyMultiple(newValue);
    }
    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }
    const options = [
        "option 1",
        "option 2",
        "option 3"
    ];

    /* useEffect(() => {
         if (!contractCustomisationUpdation.current) {
             {
                 if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                     props.dropdownData.records.map((item) => {
                         if (item.field_id === 'application_type') {
                             setApplicationTypeArray(item);
                         }
                         if (item.field_id === 'calculation_schema') {
                             setCalculationSchemaArray(item);
                         }
                         if (item.field_id === 'contract_type') {
                             setContractTypeArray(item);
                         }
                         if (item.field_id === 'billing_type') {
                             setBillingTypeArray(item);
                         }
                         //new
                         if (item.field_id === 'calculation_simulation_number') {
                             setCalculationSimulationNumberArray(item);
                         }
                         if (item.field_id === 'billing_date') {
                             setBillingDateArray(item);
                         }
                         if (item.field_id === 'billing_doc_number') {
                             setBillingDocNumberArray(item);
                         }
                         if (item.field_id === 'billing_doc_line') {
                             setBillingDocLineArray(item);
                         }
                         if (item.field_id === 'contract_number') {
                             setContractNumberArray(item);
                         }
                         if (item.field_id === 'calculation_schema') {
                             setCalculationSchemaArray(item);
                         }
                         // if (item.field_id === 'contract_type') {
                         //     setContractTypeArray(item);
                         // }
                         if (item.field_id === 'start_date') {
                             setStartDateArray(item);
                         }
                         if (item.field_id === 'end_date') {
                             setEndDateArray(item);
                         }
                         if (item.field_id === 'item_category') {
                             setItemCategoryArray(item);
                         }
                         if (item.field_id === 'company_code') {
                             setCompanyCodeArray(item);
                         }
                         if (item.field_id === 'sales_org') {
                             setSalesOrgArray(item);
                         }
                         if (item.field_id === 'distribution_channel') {
                             setDistributionChannelArray(item);
                         }
                         if (item.field_id === 'division') {
                             setDivisionArray(item);
                         }
                         if (item.field_id === 'fiscal_year_month') {
                             setFiscalYearMonthArray(item);
                         }
                         if (item.field_id === 'calendar_year_month') {
                             setCalendarYearMonthArray(item);
                         }
                         if (item.field_id === 'sold_to_party') {
                             setSoldToPartyArray(item);
                         }
                         if (item.field_id === 'customer_chain') {
                             console.log(item)
                             setCustomerChainArray(item);
                         }
                         if (item.field_id === 'customer_group') {
                             setCustomerGroupArray(item);
                         }
                         if (item.field_id === 'supplier_group') {
                             setVendorGroupArray(item);
                         }
                         if (item.field_id === 'supplier_number') {
                             setVendorArray(item);
                         }
                         if (item.field_id === 'material_number') {
                             setMaterialNumberArray(item);
                         }
                         if (item.field_id === 'material_group') {
                             setMaterialGroupArray(item);
                         }
                         if (item.field_id === 'material_type') {
                             setMaterialTypeArray(item);
                         }
                         if (item.field_id === 'product_hierarchy') {
                             setProductHierarchyArray(item);
                         }
                         if (item.field_id === 'sales_order_type') {
                             setSalesOrderTypeArray(item);
                         }
                         if (item.field_id === 'profit_center') {
                             setProfitCenterArray(item);
                         }
                         if (item.field_id === 'plant') {
                             setPlantArray(item);
                         }
                     })
                 }
             }
         }
     }); */

    //new fiscalYearMonth
    const [fiscalYearMonth, setFiscalYearMonth] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateEnd, setPricingDateEnd] = React.useState(null);
    const [customerName, setCustomerName] = React.useState([]);
    const [materialDescription, setMaterialDescription] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [supplierName, setSupplierName] = React.useState([]);
    const [billingDocumentNumber, setBillingDocumentNumber] = React.useState([]);
    const [billingDocumentLine, setBillingDocumentLine] = React.useState([]);
    const [distributionChannel, setDistributionChannel] = React.useState([]);
    const [division, setDivision] = React.useState([]);
    const [employeeNumber, setEmployeeNumber] = React.useState([]);
    const [employeeName, setEmployeeName] = React.useState([]);
    const [terittoryID, setTerittoryID] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [salesDistrict, setSalesDistrict] = React.useState([]);
    const [salesOffice, setSalesOffice] = React.useState([]);
    const [salesGroup, setSalesGroup] = React.useState([]);
    const [orderReason, setOrderReason] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [netValue, setNetValue] = React.useState([]);
    const [incentiveBasis1, setIncentiveBasis1] = React.useState([]);
    const [flexKeyFigure1, setFlexKeyFigure1] = React.useState([]);
    const [flexKeyFigure2, setFlexKeyFigure2] = React.useState([]);
    const [supplierCustomerNumber, setSupplierCustomerNumber] = React.useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');
    const [pricingDateRangeKey, setPricingDateRangeKey] = React.useState('pricing_date');

    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(newValue);
        if (billingDateEnd) {
            setPricingDateRangeKey('pricing_date.range');
        } else {
            setPricingDateRangeKey('pricing_date');
        }
    }
    const handlePricingDateEnd = (newValue) => {
        setPricingDateEnd(newValue);
        if (newValue) {
            setPricingDateRangeKey('pricing_date.range');
        } else {
            setPricingDateRangeKey('pricing_date');
        }
    }


    const onSubmitHandler = () => {
        var formData = {
            [billingDateRangeKey]: (billingDateStart ? Moment(billingDateStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + (billingDateEnd ? ',' + Moment(billingDateEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            // "billing_doc_number.in": billingDocMultiple.toString(),
            "ima_ineligible": imaEligible.toString(),
            "billing_doc_line_key.in": billingDocLineMultiple.toString(),
            "billing_type.in": billingType.toString(),
            "item_category.in": itemCategory.toString(),
            "company_code.in": companyCodeMultiple.toString(),
            "sales_org.in": salesOrgMultiple.toString(),
            "calendar_year_month.in": calendarYearMonth.toString(),
            "sold_to_party.in": soldToPartyMultiple.toString(),
            "customer_chain.in": customerChainMultiple.toString(),
            "customer_group.in": customerGroupMultiple.toString(),
            "supplier_number.in": vendorMultiple.toString(),
            "supplier_group.in": vendorGroupMultiple.toString(),
            "material_number.in": materialNumberMultiple.toString(),
            "material_group.in": materialGroupMultiple.toString(),
            "material_type.in": materialTypeMultiple.toString(),
            "product_hierarchy.in": productHierarchyMultiple.toString(),
            "sales_order_type.in": salesOrderTypeMultiple.toString(),
            "plant.in": plantMultiple.toString(),
            "profit_center.in": profitCenterMultiple.toString(),
            "billing_document_created_date.range": (billingDocCreatedStart ? Moment(billingDocCreatedStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + ',' + (billingDocCreatedEnd ? Moment(billingDocCreatedEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "billing_document_created_time.range": (billingDocCreatedStartTime ? Moment(billingDocCreatedStartTime).format('01:01:001THH:mm:ss').concat('Z') : '') + ',' + (billingDocCreatedEndTime ? Moment(billingDocCreatedEndTime).format('01:01:001THH:mm:ss').concat('Z') : ''),
            "billing_document_extracted_date.range": (billingDocExtractedStart ? Moment(billingDocExtractedStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + ',' + (billingDocExtractedEnd ? Moment(billingDocExtractedEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "billing_document_extracted_time.range": (billingDocExtractedStartTime ? Moment(billingDocExtractedStartTime).format('01:01:001THH:mm:ss').concat('Z') : '') + ',' + (billingDocExtractedEndTime ? Moment(billingDocExtractedEndTime).format('01:01:001THH:mm:ss').concat('Z') : ''),
            //new
            "fiscal_year_month.in": fiscalYearMonth.toString(),
            [pricingDateRangeKey]: (pricingDateStart ? Moment(pricingDateStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + (pricingDateEnd ? ',' + Moment(pricingDateEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "customer_name.in": customerName.toString(),
            "material_description.in": materialDescription.toString(),
            "supplier_number.in": supplierNumber.toString(),
            "supplier_name.in": supplierName.toString(),
            "supplier_customer_number.in": supplierCustomerNumber.toString(),
            "billing_doc_number.in": billingDocumentNumber.toString(),
            "billing_doc_line.in": billingDocumentLine.toString(),
            "distribution_channel.in": distributionChannel.toString(),
            "division.in": division.toString(),
            "employee_number.in": employeeNumber.toString(),
            "employee_name.in": employeeName.toString(),
            "terittory_id.in": terittoryID.toString(),
            "region.in": region.toString(),
            "sales_district.in": salesDistrict.toString(),
            "sales_office.in": salesOffice.toString(),
            "sales_group.in": salesGroup.toString(),
            "order_reason.in": orderReason.toString(),
            "flex_attribute1.in": flexAttribute1.toString(),
            "flex_attribute2.in": flexAttribute2.toString(),
            "net_value.in": netValue.toString(),
            "incentive_basis1.in": incentiveBasis1.toString(),
            "flex_key_figure1.in": flexKeyFigure1.toString(),
            "flex_key_figure2.in": flexKeyFigure2.toString(),
            "customer_number.in": customerNumber.toString(),

        };
        if ((!billingDateStart && billingDateEnd) ||
            (billingDocMultiple.length === 0 && billingDocRange) ||
            (billingDocLineMultiple.length === 0 && billingDocLineRange) ||
            (!billingDocCreatedStart && billingDocCreatedEnd) ||
            (!billingDocExtractedStart && billingDocExtractedEnd) ||
            (vendorMultiple.length === 0 && vendorRange) ||
            (soldToPartyMultiple.length === 0 && soldToPartyRange) ||
            (materialNumberMultiple.length === 0 && materialNumberRange) ||
            (!billingDocCreatedStartTime && billingDocCreatedEndTime) ||
            (!billingDocExtractedStartTime && billingDocExtractedEndTime)
        ) {
            toast.error('Please fill all required fields.');
        } else {
            sessionStorage.setItem("InvoiceReportsData", JSON.stringify(formData));
            props.onSubmit(0, 10, formData);
        }
    }
    const onCancelHandler = () => {
        setBillingDateStart(null);
        setBillingDateEnd(null);
        setBillingDocMultiple([]);
        setBillingDocRange('');
        setImaEligible([]);
        setBillingDocLineMultiple([]);
        setBillingDocLineRange('');
        setBillingType([]);
        setItemCategory([]);
        setCompanyCodeMultiple([]);
        setSalesOrgMultiple([]);
        setCalendarYearMonth([]);
        setSoldToPartyMultiple([]);
        setSoldToPartyRange([]);
        setCustomerChainMultiple([]);
        setCustomerGroupMultiple([]);
        setVendorMultiple([]);
        setVendorRange('');
        setMaterialNumberMultiple([]);
        setMaterialNumberRange('');
        setMaterialGroupMultiple([]);
        setMaterialTypeMultiple([]);
        setProductHierarchyMultiple([]);
        setProductHierarchyMultiple([]);
        setPlantMultiple([]);
        setProfitCenterMultiple([]);
        setBillingDocCreatedStart(null);
        setBillingDocCreatedEnd(null);
        setBillingDocExtractedStart(null);
        setBillingDocExtractedEnd(null);
        setSalesOrderTypeMultiple([]);

        setPricingDateStart(null);
        setPricingDateEnd(null);

    }

    return (
        <LoadingOverlay
            active={props.loading}
            spinner
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            text='Loading Invoice reports. Please wait...'
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary'> Invoice Reports </Typography>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Calendar Year Month</FormLabel>
                                    {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calendarYearMonth}
                                                onChange={date => setCalendarYearMonth(date)}
                                                format="MM/dd/yyyy"
                                            />
                                        </Grid>
                                            </MuiPickersUtilsProvider>*/}
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setCalendarYearMonth(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Fiscal Year Month</FormLabel>
                                    {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={fiscalYearMonth}
                                                onChange={date => setFiscalYearMonth(date)}
                                                format="MM/dd/yyyy"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>*/}
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFiscalYearMonth(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Date</FormLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={date => handleBillingDateStart(date)}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.dateSecondColumn} style={{ marginTop: 16 }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 17,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={date => handleBillingDateEnd(date)}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Pricing Date</FormLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={pricingDateStart}
                                                onChange={date => handlePricingDateStart(date)}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.dateSecondColumn}>
                                    <FormLabel style={{ color: 'white' }}>Pricing Date</FormLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={pricingDateEnd}
                                                onChange={date => handlePricingDateEnd(date)}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateEnd}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Number</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setCustomerNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Name</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setCustomerName(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Chain</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleCustomerChainMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Group</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleCustomerGroupMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Number</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialNumberMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Description</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setMaterialDescription(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Product Hierarchy</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleProductHierarchyMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Group</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialGroupMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Number</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSupplierNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Name</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSupplierName(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Group</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleVendorGroupMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Customer Number</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSupplierCustomerNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Number</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setBillingDocumentNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Line</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setBillingDocumentLine(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Type</FormLabel>
                                    {/*<Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={billingTypeMultiple}
                                        input={<OutlinedInput />}
                                        onChange={handleBillingType}
                                        className={classes.multiSelect}
                                    >
                                        {options.map((name) => (
                                            <MenuItem key={name} value={name} >
                                                {name}
                                            </MenuItem>
                                        ))}
                                        </Select>*/}
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setBillingType(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Item Category</FormLabel>
                                    {/*<Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={itemCategoryMultiple}
                                        input={<OutlinedInput />}
                                        onChange={handleItemCategoryMultiple}
                                        className={classes.multiSelect}
                                    >
                                        {options.map((name) => (
                                            <MenuItem key={name} value={name} >
                                                {name}
                                            </MenuItem>
                                        ))}
                                        </Select>*/}
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setItemCategory(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Company Code</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleCompanyCodeMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Org</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleSalesOrgMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Distribution Channel</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setDistributionChannel(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Division</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setDivision(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Number</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setEmployeeNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Name</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setEmployeeName(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Terittory ID</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setTerittoryID(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Region</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setRegion(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales District</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSalesDistrict(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Office</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSalesOffice(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Group</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSalesGroup(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Order Reason</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setOrderReason(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Plant</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handlePlantMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Profit Center</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleProfitCenterMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 1</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexAttribute1(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 2</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexAttribute2(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Net Value</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setNetValue(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Incentive Basis 1</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setIncentiveBasis1(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Key Figure 1</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexKeyFigure1(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Key Figure 2</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexKeyFigure2(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Created Date</FormLabel>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}
                                        >
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: !billingDocCreatedStart && billingDocCreatedEnd ? '1px solid ted' : '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={billingDocCreatedStart}
                                                    onChange={date => setBillingDocCreatedStart(date)}
                                                    format="MM/DD/YYYY"
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}
                                        >
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10
                                                        }
                                                    }}
                                                    value={billingDocCreatedEnd}
                                                    onChange={date => setBillingDocCreatedEnd(date)}
                                                    format="MM/DD/YYYY"
                                                    minDate={billingDocCreatedStart}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </div>
                                </div>
                                {/* <div className={classes.dateSecondColumn}>
                                <FormLabel style={{color: 'white'}}>Billing Document Created Date</FormLabel>
                                    
                                </div> */}
                            </Grid>
                            {/*<Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Billing Document Created Time</FormLabel>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}

                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardTimePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: !billingDocCreatedStartTime && billingDocCreatedEndTime ? '1px solid ted' : '1px solid #E0E0E0',
                                                                width: '100%'
                                                            }
                                                        }}
                                                        value={billingDocCreatedStartTime}
                                                        onChange={date => setBillingDocCreatedStartTime(date)}
                                                        keyboardIcon={<AccessTimeIcon />}
                                                        variant="dialog"
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}

                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardTimePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginLeft: 10
                                                            }
                                                        }}
                                                        value={billingDocCreatedEndTime}
                                                        onChange={date => setBillingDocCreatedEndTime(date)}
                                                        variant="dialog"
                                                        keyboardIcon={<AccessTimeIcon />}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </div>
                                </div>
                             </Grid>*/}
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Extracted Date</FormLabel>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}

                                        >
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Grid container justifyContent="space-around">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: !billingDocExtractedStart && billingDocExtractedEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%'
                                                            }
                                                        }}
                                                        value={billingDocExtractedStart}
                                                        onChange={date => setBillingDocExtractedStart(date)}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}
                                        >
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Grid container justifyContent="space-around">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginLeft: 10
                                                            }
                                                        }}
                                                        value={billingDocExtractedEnd}
                                                        onChange={date => setBillingDocExtractedEnd(date)}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDocCreatedStart}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>

                            {/*<Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Billing Document Extracted Time</FormLabel>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardTimePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: !billingDocExtractedStartTime && billingDocExtractedEndTime ? '1px solid ted' : '1px solid #E0E0E0',
                                                                width: '100%'
                                                            }
                                                        }}
                                                        value={billingDocExtractedStartTime}
                                                        onChange={date => setBillingDocExtractedStartTime(date)}
                                                        keyboardIcon={<AccessTimeIcon />}
                                                        variant="dialog"
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            className={classes.calendarLeftGridContainer}
                                            style={{ padding: 0 }}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Grid container justify="space-around">
                                                    <KeyboardTimePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginLeft: 10
                                                            }
                                                        }}
                                                        value={billingDocExtractedEndTime}
                                                        onChange={date => setBillingDocExtractedEndTime(date)}
                                                        variant="dialog"
                                                        keyboardIcon={<AccessTimeIcon />}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </div>
                                </div>
                          </Grid>*/}

                            {/* <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Billing Doc Number</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMultipleBillingDoc(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{root:classes.fontSetting}}>IMA Ineligible</FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        value={imaEligible}
                                        input={<OutlinedInput />}
                                        onChange={handleImaEligible}
                                        className={classes.multiSelect}
                                    >
                                        {options.map((name) => (
                                            <MenuItem key={name} value={name} >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Billing Doc Line</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMultipleBillingDocLine(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            
                            
                            
                           

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '50%', marginRight: 15 }}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Sold To Party</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleSoldToPartyMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            
                            
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Supplier</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleVendorMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            
                            
                            
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Material Type</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialTypeMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                            
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{root:classes.fontSetting}}>Sales Order Type</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleSalesOrderTypeMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid> */}



                        </Grid>
                    </div>
                </div>
                <div className={classes.submitContainer}>
                    <SubmitContainer onSubmit={onSubmitHandler} onCancel={onCancelHandler} loadingAPI={props.loading} />
                </div>
            </div >
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (initial, total) => dispatch(runInvoiceReports(initial, total, '')),
        getDefaultValuesAllData: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), sessionStorage.getItem('operationalReportsSourceDataType'), null, null, 'Display Designer')),

    }
}

const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReportsTab);