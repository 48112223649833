import React from 'react';
import { SupplierPaymentPostingForm } from './containers';

const FinancialPostings = props => {

    return (
        <SupplierPaymentPostingForm />
    );

};

export default FinancialPostings;