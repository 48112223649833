import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles,useTheme,styled } from '@mui/styles';
import {
    Breadcrumbs, Typography, CardHeader,Card,Grid,Chip,Paper
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory,Link } from "react-router-dom";
import { Stack, Box } from '@mui/material';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 29px,29px,29px',
        marginTop: 16,
        height:'100vh'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stackList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 5,
        // paddingRight:'5rem'
    },
    stackContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },    
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    border: '1px solid',
    borderColor: theme.palette.text.secondary,
    justifyContent: 'space-between',
    display: 'flex'
}));
const MarginTrendingResult = props => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [summary, setSummary] = React.useState({});

    useEffect(() => {
        if (history && history.location && history.location.data) {
            setSummary(history.location.data)
        }
    }, [history])
    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                     to={{ pathname: '/margin-trending', data: 'Yes' }}
                >
                    Gross to Net Trend
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}
            >
                {summary&& Object.entries(summary) &&Object.entries(summary).length>0 ?
              <Card>
              <div className={classes.row} >
                  <CardHeader
                      title="Gross to Net Trend Summary"
                      titleTypographyProps={{ variant: 'h3' }}
                      classes={{
                          root: classes.rootHeader
                      }}
                  />
              </div>
              <Grid
                  item
                  md={12}
                  xs={12}
                  classes={{ root: classes.gridContainer }}
                  style={{ display: 'flex' }}
              >
                  <Grid
                      item
                      md={12}
                      xs={12}
                      classes={{ root: classes.gridContainer }}
                  >

                      <Box sx={{ width: '100%' }}>
                          <Stack direction="column" spacing={1} justifyContent="space-between">
                              <Item style={{display: 'block', backgroundColor: '#FFF4D2', borderColor: '#FFF4D2' }}>
                                  <div className={classes.stackContainer}>
                                    {
                                        Object.entries(summary.driver_name).map((key,value)=> {
                                            return (
                                            <div className={classes.stackList} >
                                                 <label > {key[0]}</label>
                                      <Chip label={key[1]} color="primary" variant="outlined" /> </div> 
                                            )
                                        })
                                    }
                                      
                                  </div>
                              </Item>
                              <Item style={{ display: 'block', backgroundColor: '#f2d2f8', borderColor: '#f2d2f8' }}>
                                  <div className={classes.stackContainer}>
                                  {
                                        Object.entries(summary.driver_sub_category).map((key,value)=> {
                                            return (
                                            <div className={classes.stackList} >
                                                 <label > {key[0]}</label>
                                      <Chip label={key[1]} color="primary" variant="outlined" /> </div> 
                                            )
                                        })
                                    }
                                  </div>
                              </Item>
                              <Item style={{ display: 'block', backgroundColor: '#d8f8d2', borderColor: '#d8f8d2' }}>
                                  <div className={classes.stackContainer}>
                                  {
                                        Object.entries(summary.driver_category).map((key,value)=> {
                                            return (
                                            <div className={classes.stackList} >
                                                 <label > {key[0]}</label>
                                      <Chip label={key[1]} color="primary" variant="outlined" /> </div> 
                                            )
                                        })
                                    }
                                  </div>
                              </Item>
                          </Stack>
                      </Box>

                  </Grid>
              </Grid>


          </Card>
                   

                    :
                    <Typography variant='h4'> 
                        No Results
                    </Typography>
                }
            </div >
        </div >
    );
};
export default connect(null, null)(MarginTrendingResult);