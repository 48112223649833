import React, { useEffect } from 'react';
import {
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Select,
    MenuItem, OutlinedInput,
    TablePagination,
    Checkbox, FormLabel, Input, ListItemText, Button
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { getPromotionMaterialData, getCountryData } from '../../../../../redux/actions';
import { connect } from 'react-redux';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '5px 0px 0px 0px'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 'webkitFillAvailalble'
    },

    stickycolhead: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 0,
        backgroundColor: '#ffffff',
        width: 55
    },

    stickycolbody: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 0,
        backgroundColor: '#ffffff',
        width: 55,

    },
    stickycolhead1: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 55,
        backgroundColor: '#ffffff',
        maxWidth: 230,
        minWidth: 140,
        width: 230
    },

    stickycolbody1: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 55,
        backgroundColor: '#ffffff',

    },
    stickycolhead2: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 210,
        backgroundColor: '#ffffff',
        minWidth: 250
    },

    stickycolbody2: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 210,
        backgroundColor: '#ffffff',
        width: 250
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    checked: {
        justifyContent: 'center',
        margin: '0px 5px 0px 5px'
    },
    checkedCell: {
        display: 'flex',
        flexDirection: 'column',
    }
}))

const Region = (props) => {


    const classes = useStyles();
    const [promoFields, setPromoFields] = React.useState([]);
    const [regionData, setRegionData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [isAllSelected, setAllSeleted] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [checkedList, setCheckedList] = React.useState([]);
    const [checkedRegion, setCheckedRegion] = React.useState([]);
    const [promotionName, setPromotionName] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [countryDetails, setCountryDetails] = React.useState([]);
    const [state, setState] = React.useState([]);
    const [listArray, setListArray] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [belt, setBelt] = React.useState([]);
    const [year, setYear] = React.useState([]);
    const [name, setName] = React.useState([]);
    const [segment, setSegment] = React.useState([]);
    const [nameList, setNameList] = React.useState([]);
    const [segmentList, setSegmentList] = React.useState([]);
    const [level, setLevel] = React.useState('');
    const [ModelArray, setModelArray] = React.useState([]);
    const [promoRegionLevel, setPromoRegionLevel] = React.useState('Country');
    const [promoRegionValue, setPromoRegionValue] = React.useState([]);
    const [promoModelLevel, setPromoModelLevel] = React.useState('Market Segment');
    const [promoModelValue, setPromoModelValue] = React.useState([]);
    const [promoMaterials, setPromoMaterials] = React.useState([]);
    const [modelYear, setModelYear] = React.useState([]);
    const [start, setStart] = React.useState('');
    const promoLevelArray = ['Country', 'Region', 'Belt', 'State']
    const promoModelArray = [{ 'value': 'Model Year', 'key': 'Model Year' }, { 'value': 'Model Year + Model Name', 'key': 'Model Name' }, { 'value': 'Model Year + Market Segment', 'key': 'Market Segment' }]
    const isAllRegion =
        listArray && listArray.length > 0 && selected.length === listArray.length;
    const isAllModel =
        ModelArray && ModelArray.length > 0 && selected.length === ModelArray.length;
    const isAllYear =
        year && year.length > 0 && selected.length === year.length;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 25
            },
            '&:nth-of-type(even)': {
                height: 30
            }
        }
    }))(TableRow);


    useEffect(() => {
        if (props.promoData && props.promoData.length > 0) {
            setPromoFields(props.promoData)
        }
    }, [props.promoData]);
    useEffect(() => {
        props.getCountryData(localStorage.getItem('Country'))
    }, [localStorage.getItem('Country')])

    useEffect(() => {
        setPromoMaterials(props.promoMaterials)
        setRegionData(props.regionData)
        setFilterData(props.promoFilter)
        setStart(props.start)
    }, [props.regionData, props.promoFilter])

    useEffect(() => {

        var temp = []
        var beltArr = []
        var regionArr = []
        if (props.countryData && props.countryData.length > 0) {
            setCountryDetails(props.countryData)
            props.countryData
                .map((item, index) => {
                    temp.push(item.state)
                    if (!regionArr.includes(item.region))
                        regionArr.push(item.region)
                    if (!beltArr.includes(item.belt))
                        beltArr.push(item.belt)
                })

            setState(temp)
            setRegion(regionArr)
            setBelt(beltArr)
        }
        handleLevel(promoRegionLevel)
    }, [props.countryData])
    useEffect(() => {
        var yearArr = []
        var nameArr = []
        var nameAll = []
        var segmentArr = []
        var segmentAll = []
        if (props.materialData && props.materialData.length > 0) {
            props.materialData.map((item, index) => {
                var t1 = item.material_group1 + "-" + item.material_group2
                var t2 = item.material_group1 + "-" + item.material_group2 + "-" + item.material_group3
                if (!yearArr.includes(item.material_group1))
                    yearArr.push(item.material_group1)
                if (!segmentAll.includes(item.material_group3))
                    segmentAll.push(item.material_group3)
                if (!nameAll.includes(item.material_group2))
                    nameAll.push(item.material_group2)
                segmentArr.push({ 'year': item.material_group1, 'name': item.material_group2, 'value': t2, 'key': item.material_group3 })
                nameArr.push({ 'year': item.material_group1, 'value': t1, 'key': item.material_group2 })
            })
            setYear(yearArr)
            setModelYear(yearArr)
            setName(nameArr)
            setSegment(segmentArr)
            setSegmentList(segmentAll)
            setNameList(nameAll)
            setModelArray(segmentAll)
            setPromoModelValue(segmentAll)
            handleInitialData(segmentAll, 'start', promoRegionValue, yearArr)
        }
    }, [props.materialData])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    function handleOnChange(event, states, list, idx) {
        var description = ''
        if (event.target.value) {
            var desc = promoFields.filter(item => item.promotion_name == event.target.value)
            description = desc[0]['promotion_description']
        }
        var materialIndex = filterData.findIndex(({ ID }) => ID == list.ID)
        filterData[materialIndex]['filter'][idx]['promotion_name'] = event.target.value
        if ((promoRegionLevel == 'State')) {
            regionData.map((reg, index1) => {
                if (promoModelLevel == 'Model Year' ? reg.material_group1 == list.material_group1 :
                    promoModelLevel == 'Market Segment' ? (reg.material_group1 == list.material_group1 && reg.material_group2 == list.material_group2 && reg.material_group3 == list.material_group3) :
                        (reg.material_group1 == list.material_group1 && reg.material_group2 == list.material_group2)) {
                    var promoId = regionData[index1]['promotion_state'].findIndex(({ state }) => state == states.state)
                    if (promoId != -1) {
                        regionData[index1]['promotion_state'][promoId]['promotion_name'] = event.target.value
                        regionData[index1]['promotion_state'][promoId]['promotion_description'] = description
                    }

                }
            })
        }
        else {

            var arry = []
            countryDetails.map((item, index) => {
                if (item.region == states.state && (promoRegionLevel == 'Region'))
                    arry.push(item.state)
                if (item.belt == states.state && (promoRegionLevel == 'Belt'))
                    arry.push(item.state)
            })
            if (arry.length > 0 && (promoRegionLevel != 'Country')) {
                regionData.map((reg, index1) => {
                    if (promoModelLevel == 'Model Year' ? reg.material_group1 == list.material_group1 :
                        promoModelLevel == 'Market Segment' ? (reg.material_group1 == list.material_group1 && reg.material_group2 == list.material_group2 && reg.material_group3 == list.material_group3) :
                            (reg.material_group1 == list.material_group1 && reg.material_group2 == list.material_group2)) {
                        regionData[index1]['promotion_state'].map((item, index) => {
                            if (arry.includes(item.state)) {
                                regionData[index1]['promotion_state'][index]['promotion_name'] = event.target.value
                                regionData[index1]['promotion_state'][index]['promotion_description'] = description
                            }

                        })
                    }
                })
            }
            else {
                regionData.map((reg, index1) => {
                    if (promoModelLevel == 'Model Year' ? reg.material_group1 == list.material_group1 :
                        promoModelLevel == 'Market Segment' ? (reg.material_group1 == list.material_group1 && reg.material_group2 == list.material_group2 && reg.material_group3 == list.material_group3) :
                            (reg.material_group1 == list.material_group1 && reg.material_group2 == list.material_group2)) {

                        if (regionData[index1]['promotion_state']) {
                            regionData[index1]['promotion_state'].map((item, index) => {
                                regionData[index1]['promotion_state'][index]['promotion_name'] = event.target.value
                                regionData[index1]['promotion_state'][index]['promotion_description'] = description
                            })
                        }
                        else {
                            var promoList = []
                            state.map(item => {
                                promoList.push({ 'promotion_name': event.target.value, 'promotion_description': description, 'state': item })
                            })
                            regionData[index1]['promotion_state'] = promoList
                        }
                    }
                })
            }
        }
        setRegionData(regionData)
        props.onChange(regionData, filterData)
    }

    const handleSelect = (event, checked) => {
        let newSelected = [];
        if (!checked) {
            filterData.map(item => {
                newSelected = newSelected.concat(checkedList, item.ID);
            })
            setCheckedList(newSelected);
        }
        else {
            setCheckedList([])
        }
        setAllSeleted(!isAllSelected)
    }

    const handleClick = (event, name, type) => {
        let list = []
        if (type == 'region')
            list = checkedRegion
        else
            list = checkedList
        let newSelected = [];
        const selectedIndex = list.indexOf(name);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(list, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(list.slice(1));
        } else if (selectedIndex === list.length - 1) {
            newSelected = newSelected.concat(list.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                list.slice(0, selectedIndex),
                list.slice(selectedIndex + 1),
            );
        }
        if (type == 'region') {
            setCheckedRegion(newSelected)
        }
        else {
            setCheckedList(newSelected);
            if (isAllSelected)
                setAllSeleted(false)
            if (newSelected.length === filterData.length)
                setAllSeleted(true)
        }
    };
    const isSelected = (name, type) => type == 'model' ? checkedList.indexOf(name) !== -1 : checkedRegion.indexOf(name) !== -1;

    function handlePromotionForAll(event) {
        var description = ''
        var stateArr = []
        if (event.target.value) {
            var desc = promoFields.filter(item => item.promotion_name == event.target.value)
            description = desc[0]['promotion_description']
        }
        countryDetails && countryDetails.map((item, i) => {
            if (checkedRegion && checkedRegion.length > 0) {
                if (checkedRegion.includes(item[promoRegionLevel.toLowerCase()]))
                    stateArr.push(item.state)
            }
            else {
                if (promoRegionValue.includes(item[promoRegionLevel.toLowerCase()]))
                    stateArr.push(item.state)
            }
        })
        filterData.map((itemX, i) => {
            if (checkedList.length > 0) {
                if (checkedList.includes(filterData[i]['ID'])) {
                    filterData[i] && filterData[i]['filter'].map((item, idx) => {
                        if (checkedRegion && checkedRegion.length > 0) {
                            if (checkedRegion.includes(item.state) && promoRegionValue.includes(item.state)) {
                                filterData[i]['filter'][idx]['promotion_name'] = event.target.value
                                filterData[i]['filter'][idx]['promotion_description'] = description
                            }
                        }
                        else {
                            if (promoRegionValue.includes(item.state)) {
                                filterData[i]['filter'][idx]['promotion_name'] = event.target.value
                                filterData[i]['filter'][idx]['promotion_description'] = description
                            }
                        }
                    })
                    regionData && regionData.map((reg, index1) => {
                        if (promoModelLevel == 'Model Year' ? reg.material_group1 == itemX.material_group1 :
                            promoModelLevel == 'Market Segment' ? (reg.material_group1 == itemX.material_group1 && reg.material_group2 == itemX.material_group2 && reg.material_group3 == itemX.material_group3) :
                                (reg.material_group1 == itemX.material_group1 && reg.material_group2 == itemX.material_group2)) {
                            regionData[index1]['promotion_state'] && regionData[index1]['promotion_state'].map((item, index) => {
                                if (stateArr.includes(item.state)) {
                                    regionData[index1]['promotion_state'][index]['promotion_name'] = event.target.value
                                    regionData[index1]['promotion_state'][index]['promotion_description'] = description
                                }

                            })
                        }
                    })
                }
            }
            if (checkedRegion.length > 0 && checkedList.length == 0) {
                if (modelYear.includes(filterData[i]['material_group1'])) {
                    filterData[i] && filterData[i]['filter'].map((item, idx) => {
                        if (checkedRegion.includes(item.state)) {
                            filterData[i]['filter'][idx]['promotion_name'] = event.target.value
                            filterData[i]['filter'][idx]['promotion_description'] = description
                        }
                    })
                }
                regionData && regionData.map((reg, index1) => {
                    if (modelYear.includes(regionData[index1]['material_group1'])) {
                        regionData[index1]['promotion_state'] && regionData[index1]['promotion_state'].map((item, index) => {
                            if (stateArr.includes(item.state)) {
                                regionData[index1]['promotion_state'][index]['promotion_name'] = event.target.value
                                regionData[index1]['promotion_state'][index]['promotion_description'] = description
                            }

                        })
                    }

                })
            }
        })
        setFilterData(filterData)
        setRegionData(regionData)
        setCheckedList([])
        setCheckedRegion([])
        setAllSeleted(false)
        props.onChange(regionData, filterData)

    }
    const handlePromoRegionLevel = (event) => {
        setPromoRegionLevel(event.target.value)
        if (event.target.value != 'Country') {
            setPromoRegionValue([])
            setSelected([])
            handleLevel(event.target.value)
        }
        else {
            setSelected([localStorage.getItem('Country')])
            setPromoRegionValue([localStorage.getItem('Country')])
            handleInitialData(promoModelValue, 'false', [localStorage.getItem('Country')])
        }

    }
    function handleLevel(level) {
        if (level == 'State')
            setListArray(state)
        else if (level == 'Belt')
            setListArray(belt)
        else if (level == 'Region')
            setListArray(region)
        else {
            setListArray([])
            setSelected([localStorage.getItem('Country')])
            setPromoRegionValue([localStorage.getItem('Country')])
            onAdd()
        }
    }
    const handleFilterList = (type) => {

        setModelYear(year)
        if (type == 'Model Year') {
            setPromoModelValue(year)
            handleInitialData(year, 'false', promoRegionValue, year, 'Model Year')
        }
        else if (type == 'Model Name') {
            setPromoModelValue(nameList)
            setModelArray(nameList)
            handleInitialData(nameList, 'false', promoRegionValue, year, 'Model Name')
        }
        else {
            setPromoModelValue(segmentList)
            setModelArray(segmentList)
            handleInitialData(segmentList, 'false', promoRegionValue, year, 'Market Segment')
        }
        setStart(false)
    }
    const handlePromoModelLevel = (event) => {
        setSelected([])
        setPromoModelLevel(event.target.value)
        handleFilterList(event.target.value)
    }
    function modelLevel(initial) {
        setOpen(false)
        if (initial != 'initial')
            setModelYear(selected)
        onAdd()
        if (promoModelLevel == 'Market Segment') {
            var temp = []
            segment.map((item, i) => {
                if (selected.includes(item.year) && !temp.includes(item.key)) {
                    temp.push(item.key)
                }
            })
            setModelArray(temp)
        }
        else {
            var temp = []
            name.map((item, i) => {
                if (selected.includes(item.year) && !temp.includes(item.key)) {
                    temp.push(item.key)
                }
            })
            setModelArray(temp)
        }
    }
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            if (level == 'region')
                setSelected(selected.length === listArray.length ? [] : listArray);
            else if (level == 'model year')
                setSelected(selected.length === year.length ? [] : year);
            else
                setSelected(selected.length === ModelArray.length ? [] : ModelArray);
        }
        else
            setSelected(newSelected)
    }
    function onAdd() {
        setOpen(false)
        if (level == 'model') {
            if (start)
                handleInitialData(selected, 'start', promoRegionValue, modelYear)
            else
                handleInitialData(selected, 'false', promoRegionValue, modelYear)
            setPromoModelValue(selected)
        }
        else if (level == 'model year') {
            if (promoModelLevel == 'Model Year') {

                if (start)
                    handleInitialData(selected, 'start', promoRegionValue, selected)
                else
                    handleInitialData(selected, 'false', promoRegionValue, selected)
                setPromoModelValue(selected)
            }
            else {
                if (start)
                    handleInitialData(promoModelValue, 'start', promoRegionValue, selected)
                else
                    handleInitialData(promoModelValue, 'false', promoRegionValue, selected)
            }
        }
        else {
            setPromoRegionValue(selected)
            if (start)
                handleInitialData(promoModelValue, 'start', selected, modelYear)
            else
                handleInitialData(promoModelValue, 'false', selected, modelYear)
        }
        setStart(false)
    }
    function onCancel() {
        setOpen(false)
        setSelected([])
        setLevel('')
    }
    function handleOpen(type) {
        if (type == 'model')
            setSelected(promoModelValue)
        else if (type == 'model year')
            setSelected(modelYear)
        else
            setSelected(promoRegionValue)
        setOpen(true)
        setLevel(type)
    }

    //filter using model
    function handleInitialData(values, initial, regionValue, year, model) {
        if (!model)
            model = promoModelLevel
        var temp2 = [...filterData]
        if (values && values.length > 0) {
            values.map(list => {
                if (model == 'Model Year') {
                    if (filterData.findIndex(({ material_group1, model_level }) => material_group1 == list && model_level == 'Model Year') == -1) {
                        var data = regionData.filter(item => item.material_group1 == list)
                        data.map((item, index) => {
                            if (temp2.findIndex(({ material_group1, model_level }) => material_group1 == item.material_group1 && model_level == 'Model Year') == -1) {
                                temp2.push({ 'material_group1': list, 'model_level': 'Model Year', 'filter': [] })
                            }
                        })
                    }
                }
                else if (model == 'Model Name') {
                    var data = []
                    if (year && year.length > 0) {
                        year.map((k, i) => {
                            if (filterData.findIndex(({ material_group1, material_group2, model_level }) => material_group1 == k && material_group2 == list && model_level == 'Model Name') == -1) {
                                data = regionData.filter(item => item.material_group2 == list && year.includes(item.material_group1))
                                data.map((item, index) => {
                                    if (temp2.findIndex(({ material_group1, material_group2, model_level }) => material_group1 == item.material_group1 && material_group2 == item.material_group2 && model_level == 'Model Name') == -1) {
                                        temp2.push({ 'material_group1': item['material_group1'], 'material_group2': item['material_group2'], 'model_level': 'Model Name', 'filter': [] })

                                    }
                                })
                            }

                        })
                    }
                    else {
                        if (filterData.findIndex(({ material_group2, model_level }) => material_group2 == list && model_level == 'ModelName') == -1) {
                            data = regionData.filter(item => item.material_group2 == list)
                            data.map((item, index) => {
                                if (temp2.findIndex(({ material_group1, material_group2, model_level }) => material_group1 == item.material_group1 && material_group2 == item.material_group2 && model_level == 'Model Name') == -1) {
                                    temp2.push({ 'material_group1': item['material_group1'], 'material_group2': item['material_group2'], 'model_level': 'Model Name', 'filter': [] })

                                }
                            })
                        }
                    }
                }
                else {
                    var data = []
                    if (year && year.length > 0) {
                        year.map((k, i) => {
                            if (filterData.findIndex(({ material_group1, material_group3, model_level }) => material_group1 == k && material_group3 == list && model_level == 'Market Segment') == -1) {
                                data = regionData.filter(item => item.material_group3 == list && year.includes(item.material_group1))
                                data.map((item, index) => {
                                    if (temp2.findIndex(({ material_group1, material_group2, material_group3, model_level }) =>
                                        material_group1 == item.material_group1 && material_group2 == item.material_group2
                                        && material_group3 == item.material_group3 && model_level == 'Market Segment') == -1) {

                                        temp2.push({ 'material_group1': item['material_group1'], 'material_group2': item['material_group2'], 'material_group3': item['material_group3'], 'model_level': 'Market Segment', 'filter': item['filter'] ? item['filter'] : [] })

                                    }
                                })
                            }

                        })
                    }
                    else {
                        if (filterData.findIndex(({ material_group3, model_level }) => material_group3 == list && model_level == 'Market Segment') == -1) {
                            data = regionData.filter(item => item.material_group3 == list)
                            data.map((item, index) => {
                                if (temp2.findIndex(({ material_group1, material_group2, material_group3, model_level }) =>
                                    material_group1 == item.material_group1 && material_group2 == item.material_group2
                                    && material_group3 == item.material_group3 && model_level == 'MarketSegment') == -1) {

                                    temp2.push({ 'material_group1': item['material_group1'], 'material_group2': item['material_group2'], 'material_group3': item['material_group3'], 'model_level': 'Market Segment', 'filter': item['filter'] ? item['filter'] : [] })
                                }
                            })
                        }
                    }

                }
            })
            setFilterData(temp2)
        }

        if (initial == 'start')
            handlePromoData(regionValue)
        else
            if (temp2 && temp2.length > 0)
                handleMaterialFilter(temp2, initial, regionValue)
            else
                handleMaterialFilter(filterData, initial, regionValue)
    };
    function handleMaterialFilter(temp2, initial, regionValue) {
        var level = promoRegionLevel.toLowerCase()
        if (initial == 'initial') {
            temp2.map((k, i) => {
                filterData[i] = k
                filterData[i]['ID'] = i
                var filter = []
                promoRegionValue.map(list => {
                    var stateArr = []
                    state.map(item => {
                        if (item[level] == list)
                            stateArr.push(item.state)
                    })
                    var data = []
                    var promotion = []
                    promotion = promoMaterials.filter((itemX, idx) => (itemX.material_group1 == k.material_group1) || (itemX.material_group1 == k.material_group1 && itemX.material_group2 == k.material_group2)
                        || (itemX.material_group1 == k.material_group1 && itemX.material_group2 == k.material_group2 && itemX.material_group3 == k.material_group3))
                    if (promotion && promotion.length > 0)
                        data = promotion[0]['promotion_state'].filter((item, idx) => stateArr.includes(item.state))

                    if (data && data.length > 0)
                        filter.push({ 'promotion_name': data[0]['promotion_name'], 'promotion_decription': data[0]['promotion_description'], 'state': list })
                })


                filterData[i]['filter'] = filter
            })
            setFilterData(filterData)
        }
        else {
            var promoFilter = []
            if (temp2 && temp2.length > 0) {
                var filter = []
                var flag
                temp2.map((item, i) => {
                    promoFilter[i] = item
                    promoFilter[i]['ID'] = i
                    filter = temp2[i]['filter'] ? temp2[i]['filter'] : []
                    regionValue && regionValue.map((k, index) => {
                        flag = 0
                        temp2[i]['filter'] && temp2[i]['filter'].map((item, idx) => {
                            if (k === item.state) {
                                flag = 1
                            }
                        })
                        if (flag == 0)
                            filter.push({ 'promotion_name': '', 'promotion_description': '', 'state': k })

                    })
                    promoFilter[i]['filter'] = filter
                })
                setFilterData(promoFilter)
            }


        }
    }
    function handlePromoData(regionValue) {

        if (filterData && filterData.length > 0) {

            filterData.map((item, index) => {
                filterData[index]['ID'] = index
                var filter = []
                regionValue && regionValue.map((k, i) => {
                    filter.push({ 'promotion_name': '', 'promotion_description': '', 'state': k })
                })
                filterData[index]['filter'] = filter
            })
            setFilterData(filterData)
        }

    }
    return (
        <div
            className={classes.root}>
            <div className={classes.container}>
                <Grid container
                    style={{ marginBottom: 5, padding: '5px 10px 0px 10px' }}
                    spacing={2}
                >
                    <Grid
                        item
                        md={2}
                        xs={12}

                    >
                        <FormLabel variant="h5" className={classes.formDiv}>
                            Promotion Name
                        </FormLabel>
                        <div className={classes.dropdownContainer}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={promotionName}
                                onChange={handlePromotionForAll}
                                disabled={checkedList.length > 0 || checkedRegion.length > 0 ? false : true}
                                style={{ maxHeight: "50px", width: '100%', marginTop: 10 }}
                            >
                                <MenuItem value=""></MenuItem>
                                {promoFields && promoFields
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.promotion_name} style={{ textTransform: 'capitalize' }}>
                                                {item.promotion_name}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>

                    </Grid>
                    <Grid
                        item
                        md={2}
                        xs={12}
                    >
                        <FormLabel style={{ paddingBottom: 10 }} required>
                            Promotion Region Level
                        </FormLabel>
                        <Select
                            disableUnderline
                            value={promoRegionLevel}
                            onChange={handlePromoRegionLevel}
                            displayEmpty
                            MenuProps={{ ...MenuProps, autoFocus: true }}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                            className={clsx({
                                [classes.select]: true
                            })}
                            style={{ textTransform: 'capitalize' }}
                        >

                            {promoLevelArray && promoLevelArray
                                .map((item) => {
                                    return (
                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </Grid>
                    {promoRegionLevel && promoRegionLevel != 'Country' &&
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <div>
                                <FormLabel variant="h5" style={{ paddingBottom: 10 }} required>
                                    {promoRegionLevel}
                                </FormLabel>
                                <div className={classes.dropdownContainer}>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={promoRegionValue}
                                        style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                        input={<OutlinedInput />}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={() => handleOpen('region')}
                                        onClose={() => setOpen(false)}
                                        open={open && level == 'region'}
                                        renderValue={(appType) => appType.join(", ")}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllRegion} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {listArray && listArray
                                            .map(item => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item) > -1} />
                                                        <ListItemText primary={item} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownAction}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>


                                    </Select>
                                </div>
                            </div>
                        </Grid>
                    }

                    <Grid
                        item
                        md={3}
                        xs={12}
                    >
                        <FormLabel style={{ paddingBottom: 10 }}>
                            Promotion Model Level
                        </FormLabel>
                        <Select
                            disableUnderline
                            value={promoModelLevel}
                            onChange={handlePromoModelLevel}
                            displayEmpty
                            MenuProps={{ ...MenuProps, autoFocus: true }}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                            className={clsx({
                                [classes.select]: true
                            })}
                            style={{ textTransform: 'capitalize' }}
                        >

                            {promoModelArray && promoModelArray
                                .map((item) => {
                                    return (
                                        <MenuItem value={item.key} key={item.value} style={{ textTransform: 'capitalize' }}>
                                            {item.value}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </Grid>
                    {promoModelLevel &&
                        <Grid
                            item
                            md={1}
                            xs={12}
                        >
                            <div>
                                <FormLabel variant="h5" style={{ paddingBottom: 10 }}>
                                    Model Year
                                </FormLabel>
                                <div className={classes.dropdownContainer}>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={modelYear}
                                        style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                        input={<OutlinedInput />}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={() => handleOpen('model year')}
                                        onClose={() => setOpen(false)}
                                        open={open && level == 'model year'}
                                        renderValue={(appType) => appType.join(", ")}

                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllYear} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {year && year
                                            .map(item => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item) > -1} />
                                                        <ListItemText primary={item} />
                                                    </MenuItem>
                                                )
                                            })}

                                        <div className={classes.dropdownAction}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={modelLevel} >
                                                Apply
                                            </Button>
                                        </div>


                                    </Select>
                                </div>
                            </div>
                        </Grid>
                    }
                    {promoModelLevel && promoModelLevel != 'Model Year' &&
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <div>
                                <FormLabel variant="h5" style={{ paddingBottom: 10 }}>
                                    {promoModelLevel}
                                </FormLabel>
                                <div className={classes.dropdownContainer}>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={promoModelValue}
                                        style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                        input={<OutlinedInput />}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={() => handleOpen('model')}
                                        onClose={() => setOpen(false)}
                                        open={open && level == 'model'}
                                        renderValue={(appType) => appType.join(", ")}

                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllModel} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {ModelArray && ModelArray
                                            .map(item => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item) > -1} />
                                                        <ListItemText primary={item} />
                                                    </MenuItem>
                                                )
                                            })}

                                        <div className={classes.dropdownAction}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>


                                    </Select>
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid >
            </div >
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>

                        <Grid container spacing={2}
                            style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                        >
                            {(promoRegionLevel || promoModelLevel) &&
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        className={classes.checkbox}
                                                        defaultChecked size="small"
                                                        onChange={(event) => handleSelect(event, isAllSelected)}
                                                        checked={isAllSelected}
                                                        key='all'
                                                        selected={isAllSelected}
                                                    />
                                                </TableCell>
                                                <TableCell align='center' className={classes.stickycolhead}>Model Year</TableCell>
                                                {((promoModelLevel != 'Model Year' && !props.editMode) || promoModelValue && promoModelValue.length < 0) && <TableCell align='center' className={classes.stickycolhead1}>
                                                    {promoModelLevel == 'Model Name' ? 'Model Name' : 'Market Segment'}</TableCell>}
                                                {props.editMode && <TableCell align='center' className={classes.stickycolhead2} >Market Segment </TableCell>}

                                                {promoRegionValue && promoRegionValue.length > 0 && promoRegionValue
                                                    .sort()
                                                    .map(item => {
                                                        const isRegionSelected = isSelected(item, 'region');
                                                        return (
                                                            <TableCell align='center' style={{ maxWidth: 100, width: 100, padding: '1px,0px ' }}
                                                                onClick={(event) => handleClick(event, item, 'region')}>
                                                                <div className={classes.checkedCell}>
                                                                    <label>{item}</label>
                                                                    <Checkbox
                                                                        className={classes.checked}
                                                                        defaultChecked size="small"
                                                                        checked={isRegionSelected}
                                                                        key={item}
                                                                        selected={isRegionSelected}

                                                                    />
                                                                </div>
                                                            </TableCell>
                                                        )
                                                    })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {filterData && filterData
                                                .filter((item, i) => item.model_level == promoModelLevel)
                                                .filter((item, i) => promoModelLevel == 'Model Year' ? promoModelValue.includes(item.material_group1) : promoModelLevel == 'Model Name' ? promoModelValue.includes(item.material_group2) && modelYear.includes(item.material_group1) : promoModelValue.includes(item.material_group3) && modelYear.includes(item.material_group1))
                                                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                                                .map((itemX, index) => {
                                                    const isItemSelected = isSelected(itemX.ID, 'model');
                                                    return (
                                                        <StyledTableRow >
                                                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, itemX.ID, 'model')}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    defaultChecked size="small"
                                                                    checked={isItemSelected}
                                                                    key={itemX.ID}
                                                                    selected={isItemSelected}
                                                                />
                                                            </TableCell>
                                                            <TableCell align='center' className={classes.stickycolbody}>{itemX.material_group1}</TableCell>
                                                            {(promoModelLevel != 'Model Year' && !props.editMode) && <TableCell align='center' className={classes.stickycolbody1} style={{ width: '118px' }}>{promoModelLevel == 'Model Name' ? itemX.material_group2 : itemX.material_group3} </TableCell>}
                                                            {props.editMode && <TableCell align='center' className={classes.stickycolbody2}>{itemX.material_group3} </TableCell>}

                                                            {promoRegionValue && promoRegionValue.length > 0 && promoRegionValue.map(item => (
                                                                itemX['filter'] && itemX['filter'].map((state, i) => {
                                                                    if (itemX['filter'][i] && itemX['filter'][i]['state'] == item) {
                                                                        return (
                                                                            <TableCell align='center' style={{ padding: 0, margin: 0 }}>
                                                                                <Select
                                                                                    disableUnderline
                                                                                    defaultValue={state.promotion_name}
                                                                                    onChange={(e) => handleOnChange(e, state, itemX, i)}
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    SelectDisplayProps={{ style: { height: '100%' } }}
                                                                                    style={{ textTransform: 'capitalize', margin: 0 }}
                                                                                >
                                                                                    <MenuItem value='' name={item} style={{ textTransform: 'capitalize' }}>

                                                                                    </MenuItem>
                                                                                    {promoFields && promoFields
                                                                                        .map((item) => {
                                                                                            return (
                                                                                                <MenuItem value={item.promotion_name} name={item} style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.promotion_name}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                </Select>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                })))
                                                            }


                                                        </StyledTableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            }
                        </Grid>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 20, 25, 30]}
                            component="div"
                            count={filterData && filterData.filter((item, i) => item.model_level == promoModelLevel)
                                .filter((item, i) => promoModelLevel == 'Model Year' ? promoModelValue.includes(item.material_group1) : promoModelLevel == 'Model Name' ? promoModelValue.includes(item.material_group2) && modelYear.includes(item.material_group1) : promoModelValue.includes(item.material_group3) && modelYear.includes(item.material_group1))
                                .length
                            }
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </form>
            </Card>
        </div >
    );

};

const mapStateToProps = state => ({
    materialData: state.promotionData.promotionMaterialData,
    countryData: state.promotionData.countryData,
    materialData: state.promotionData.promotionMaterialData,
});

const mapDispatchToProps = dispatch => {
    return {
        getMaterialData: () => dispatch(getPromotionMaterialData()),
        getCountryData: (country) => dispatch(getCountryData(country)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(Region);