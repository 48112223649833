import React, { useEffect } from 'react';
import {
    Grid, Typography, Breadcrumbs,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getPaymentDesignerSingleItem } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const EditPaymentDesigner = props => {
    const classes = useStyles();
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getAccrualDesignerSingleItem(appId);
    }, []);


    useEffect(() => {
        if (props.items) {
            setUserStore([props.items])
        }
    }, [props.items]);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [userStore, setUserStore] = React.useState([]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/payment-designer'
                >
                    Payment Designer
                </Link>
                <Typography color="textPrimary" variant='h4'>View Payment</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {userStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Contract Type</TableCell>
                                                {/* <TableCell align='center'className={classes.tabHead}>Payment Level</TableCell>
                                                <TableCell align='center'className={classes.tabHead}>Target System</TableCell> */}
                                                <TableCell align='center' className={classes.tabHead}>Document Type</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Sales Org</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Distribution Channel</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Division</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Order Reason</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Material Number</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Payment Processing Days</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Document Date</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Pricing Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {userStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.app_type}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.contract_type}</TableCell>
                                                        {/* <TableCell align='center'classes={{root:classes.fontSetting}}>{item.payment_level}</TableCell>
                                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.target_system}</TableCell> */}
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.document_type}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.sales_org}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.distribution_channel}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.division}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.order_reason}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.material_number}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.payment_processing_days}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.document_date}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.pricing_date}</TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    No Data
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getAccrualDesignerSingleItem: (id) => dispatch(getPaymentDesignerSingleItem(id)),
    }
}

const mapStateToProps = state => {
    return {
        items: state.customerData.paymentDesignerSingle
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentDesigner);