import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    Button, IconButton, Typography, Fab,
    Select,
    MenuItem,
    Tooltip
} from '@mui/material';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import moment from 'moment';
const theme = createTheme({
    overrides: {

        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    },

});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const CostingDownloadFile = props => {

    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);

    const columns = [
        {
            field: 'scenario_number',
            title: 'Scenario Number',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.scenario_number}</a>

        },
        {
            field: 'product_line',
            title: 'Product Line',
            type: 'string',
            render: (rowData) => <a className={classes.hover}  > {rowData.product_line}</a>
        },
        {
            field: 'material_group1',
            title: 'Model Year',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.material_group1}</a>
        },
        {
            field: 'material_group2',
            title: 'Material Group 2',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > <Tooltip title={rowData.material_group2}>{rowData.material_group2}</Tooltip></a>
        },
        {
            field: 'material_group4',
            title: 'Material Group 4',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > <Tooltip title={rowData.material_group4}>{rowData.material_group4}</Tooltip></a>
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.country}</a>
        },
        {
            field: 'state',
            title: 'State',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.state}</a>
        },
        {
            field: 'billing_date',
            title: 'Billing Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {moment.utc(rowData.billing_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'sticker_description',
            title: 'Sticker Description',
            type: 'string',
            render: (rowData) => <a className={classes.hover} ><Tooltip title={rowData.sticker_description}>{rowData.sticker_description}</Tooltip></a>
        },
        {
            field: 'promotion_start_date',
            title: 'Promotion Start Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {moment.utc(rowData.promotion_start_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'promotion_end_date',
            title: 'Promotion End Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {moment.utc(rowData.promotion_end_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'brp_total_forecast_volume',
            title: 'BRP Forecast Volume',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.brp_total_forecast_volume}</a>
        },
        {
            field: 'ima_total_forecast_volume',
            title: 'IMA Forecast Volume',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.ima_total_forecast_volume}</a>
        },
        {
            field: 'brp_total_rebate_cost',
            title: 'BRP Rebate Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.brp_total_rebate_cost}</a>
        },
        {
            field: 'ima_total_rebate_cost',
            title: 'IMA Rebate Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.ima_total_rebate_cost}</a>
        },
        {
            field: 'brp_total_costing_coverage_cost',
            title: 'BRP Costing Coverage Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.brp_total_costing_coverage_cost}</a>
        },
        {
            field: 'ima_total_costing_coverage_cost',
            title: 'IMA Costing Coverage Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.ima_total_costing_coverage_cost}</a>
        },
        {
            field: 'brp_total_extra_rebate_cost',
            title: 'BRP Extra Rebate Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.brp_total_extra_rebate_cost}</a>
        },
        {
            field: 'ima_total_extra_rebate_cost',
            title: 'IMA Extra Rebate Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.ima_total_extra_rebate_cost}</a>
        },
        {
            field: 'brp_total_promo_finance_cost',
            title: 'BRP Promo Finance Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.brp_total_promo_finance_cost}</a>
        },
        {
            field: 'ima_total_promo_finance_cost',
            title: 'IMA Promo Finance Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.ima_total_promo_finance_cost}</a>
        },
        {
            field: 'brp_total_standard_finance_cost',
            title: 'BRP Standard Finance Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.brp_total_standard_finance_cost}</a>
        },
        {
            field: 'ima_total_standard_finance_cost',
            title: 'IMA Standard Finance Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.ima_total_standard_finance_cost}</a>
        },
        {
            field: 'brp_total_spiff_cost',
            title: 'BRP Spiff Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.brp_total_spiff_cost}</a>
        },
        {
            field: 'ima_total_spiff_cost',
            title: 'IMA Spiff Cost',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.ima_total_spiff_cost}</a>
        },
        // {
        //     field: 'Actions',
        //     title: 'Actions',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData =>
        //         <div>
        //             {/* {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_sales_forecast-' + sessionStorage.getItem('application')) && */}
        //             <>
        //                 <IconButton
        //                     classes={{ root: classes.IconButton }}
        //                     onClick={() => editSalesForecast(rowData.id)}
        //                     size="large">
        //                     <Edit color="disabled" style={{ fontSize: 20 }} />
        //                 </IconButton>
        //                 <IconButton
        //                     classes={{ root: classes.IconButton }}
        //                     onClick={() => props.deleteSalesForecast(rowData.id)}
        //                     size="large">
        //                     <DeleteForever color="disabled" style={{ fontSize: 20 }} />
        //                 </IconButton>
        //             </>
        //             {/* } */}
        //         </div>
        // },
    ];


    useEffect(() => {
        var tempRows = [];
        if (props.costingComparisonDownloadList && props.costingComparisonDownloadList.length > 0)
            props.costingComparisonDownloadList
                .map(e => {
                    tempRows.push({
                        scenario_number: e.scenario_number,
                        product_line: e.product_line,
                        material_group1: e.material_group1,
                        material_group2: e.material_group2,
                        material_group4: e.material_group4,
                        country: e.country,
                        state: e.state,
                        billing_date: e.billing_date,
                        sticker_description: e.sticker_description,
                        promotion_start_date: e.promotion_start_date,
                        promotion_end_date: e.promotion_end_date,
                        brp_total_forecast_volume: e.brp_total_forecast_volume,
                        ima_total_forecast_volume: e.ima_total_forecast_volume,
                        brp_total_rebate_cost: e.brp_total_rebate_cost,
                        ima_total_rebate_cost: e.ima_total_rebate_cost,
                        brp_total_extra_rebate_cost: e.brp_total_extra_rebate_cost,
                        ima_total_extra_rebate_cost: e.ima_total_extra_rebate_cost,
                        ima_total_promo_finance_cost: e.ima_total_promo_finance_cost,
                        brp_total_promo_finance_cost: e.brp_total_promo_finance_cost,
                        ima_total_standard_finance_cost: e.ima_total_standard_finance_cost,
                        brp_total_standard_finance_cost: e.brp_total_standard_finance_cost,
                        ima_total_costing_coverage_cost: e.ima_total_costing_coverage_cost,
                        brp_total_costing_coverage_cost: e.brp_total_costing_coverage_cost,
                        ima_total_spiff_cost: e.ima_total_spiff_cost,
                        brp_total_spiff_cost: e.brp_total_spiff_cost,
                    });
                })
        setDataRows(tempRows);
    }, [props.costingComparisonDownloadList]);


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Costing Data After Comparison </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {/* <input
                            type="file"
                            ref={fileInputRef}
                            accept=".xlsx, .xls, .csv, .zip"
                            id='file'
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileChange(e, 'upload')}
                        />
                        <Fab
                            aria-label="edit"
                            variant="extended"
                            size="medium"
                            className={classes.fabContainer}
                            onClick={handleFileUpload}
                        >
                            <CloudUpload color="primary" />
                            <Typography color="primary" style={{ marginLeft: 3 }}>File Upload</Typography>
                        </Fab> */}
                    </div>
                </div>
                {
                    props.loading ?
                        <div className={classes.noDataContainer}>
                            Please wait... Data is loading...
                        </div>
                        : props.costingComparisonDownloadList && props.costingComparisonDownloadList.length > 0 ?
                            <MaterialTable
                                components={{
                                    Toolbar: (props) => (
                                        <div
                                            style={{
                                                height: "0px",
                                            }}
                                        >
                                        </div>
                                    ),
                                }}
                                title={' '}
                                editable={true}
                                icons={tableIcons}
                                columns={columns}
                                data={dataRows}
                                // style={{ marginTop: '-18px' }}
                                options={{
                                    maxBodyHeight: '100vh',
                                    search: false,
                                    filtering: true,
                                    headerStyle: theme.mixins.MaterialHeader,
                                    cellStyle: theme.mixins.MaterialCell,
                                    pageSize: 15,
                                    pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                                }}
                            />
                            :
                            <div className={classes.noDataContainer}>
                                There is no data to show now.
                            </div>
                }
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        costingComparisonDownloadList: state.promotionData.costingComparisonDownload,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // costingComparisonFileUpload: (scenarioNumber, isDownload, file) => dispatch(uploadCostingAfterComparisonDataFile(scenarioNumber, isDownload, file)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostingDownloadFile);