import React, { useEffect, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import TabContext from "@mui/lab/TabContext";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Resize,
  ExcelExport,
  Toolbar,
  PdfExport,
  Search,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";

import TabList from "@mui/lab/TabList";
// import {
//   GridComponent,
//   ColumnsDirective,
//   ColumnDirective,
// } from "@syncfusion/ej2-react-grids";

import { connect } from "react-redux";
import momentt from "moment-timezone";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Breadcrumbs,
  Typography,
  Button,
  useMediaQuery,
  Fab,
} from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { downloadContractTrendsResltsSheet } from "../../../redux/actions";
import MaterialTable from "material-table";
import clsx from "clsx";
import { tableIcons } from "../../../components/Icons/TableIcons";
import { Link } from "react-router-dom";
import { single } from "validate.js";
import GetAppIcon from "@mui/icons-material/GetApp";

momentt.tz.setDefault("Etc/UTC");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 10,
    // padding: 29,
    // marginTop: 16,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    // marginTop: 25,
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

const theme2 = createTheme({
  overrides: {
    MuiTabPanel: {
      root: {
        padding: 0,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#CBD1E3 !important",
        "& .MuiTableCell-head": {
          padding: 5,
        },
      },
    },
    MuiTableCell: {
      root: {
        textAlign: "center !important",
      },
    },
  },
});
const ContractTrendsResult = (props) => {
  var grid = useRef(null);
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [contractHeads, setContractHeads] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [apiData, setApiData] = React.useState(0);
  const classes = useStyles();
  const contractType = history.location.data;
  const rebateHeading = [
    "Rebate Formula",
    "Rebate Formula Value",
    "Incentive Basis",
    "Incentive Rate",
    "Incentive Calculated",
    "Incentive Amount",
  ];
  const handleSheetDownload = () => {
    var formData = JSON.parse(sessionStorage.getItem("contractTrendsQuery"));
    if (formData) {
      props.downloadSheet(formData);
    }
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setValue(newValue);
  };
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    allowEditOnDblClick: false,
  };
  const validationRule = { required: true };
  const filterSettings = { type: "Menu" };
  useEffect(() => {
    if (contractType.type == "contractV1") {
      if (props.contractTrendsResult && props.contractTrendsResult.length > 0) {
        var heading = [];
        props.contractTrendsResult.map((item) => {
          heading.push(Object.keys(item)[0] ? Object.keys(item)[0] : null);
        });
        setContractHeads(heading);
        setApiData(props.contractTrendsResult);
      } else {
        setContractHeads([]);
        setApiData(0);
      }
    }
  }, [props.contractTrendsResult]);

  const [reabateArraySF, setReabateArraySF] = React.useState([]);
  const [attributeArrayComplete, setAttributeArrayComplete] = React.useState(
    []
  );
  const [attributeArrayName, setAttributeArrayName] = React.useState([]);

  const [tab1, setTab1] = React.useState([]);
  const [tab2, setTab2] = React.useState([]);
  const [tab3, setTab3] = React.useState([]);
  const [tabName1, setTabName1] = React.useState([]);
  const [tabName2, setTabName2] = React.useState([]);
  const [tabName3, setTabName3] = React.useState([]);
  useEffect(() => {
    if (contractType.type == "contractV2") {
      if (
        props.contractTrendsV2Result &&
        props.contractTrendsV2Result.length > 0
      ) {
        var heading = [];
        props.contractTrendsV2Result.map((item) => {
          heading.push(Object.keys(item)[0] ? Object.keys(item)[0] : null);
        });
        setContractHeads(heading);
        setApiData(props.contractTrendsV2Result);
      } else {
        setContractHeads([]);
        setApiData(0);
      }
    }
  }, [props.contractTrendsV2Result]);

  const toolbarClick = (args) => {
    if (grid && args.item.id === "grid_pdfexport") {
      grid.pdfExport();
    }
    if (grid && args.item.id === "grid_excelexport") {
      grid.excelExport();
    }
  };
  const toolbarOptions = ["PdfExport", "ExcelExport", "Search", "Sort"];
  const filterOptions = {
    type: "Excel",
  };
  useEffect(() => {
    var tabTemp1 = [];
    var tabTemp2 = [];
    var tabTemp3 = [];
    var temp1 = {};
    var temp2 = {};
    var temp3 = {};
    var tabBody1 = [];
    var tabBody2 = [];
    var tabBody3 = [];

    var tabBody1Single = [];
    var tabBody2Single = [];
    var tabBody3Single = [];

    contractHeads.map((singleItem, ix) => {
      apiData[ix] &&
        apiData[ix][singleItem] &&
        apiData[ix][singleItem][0] &&
        Object.keys(apiData[ix][singleItem][0]).map(function (key, index) {
          return (
            apiData[ix][singleItem][0][key]["attribute"] &&
            Object.keys(apiData[ix][singleItem][0][key]["attribute"]).map(
              (item) => {
                if (ix === 0) tabTemp1.push(item);
                if (ix === 1) tabTemp2.push(item);
                if (ix === 2) tabTemp3.push(item);
              }
            )
          );
        });

      apiData[ix] &&
        apiData[ix][singleItem] &&
        apiData[ix][singleItem][0] &&
        Object.keys(apiData[ix][singleItem][0]).map(function (key, index) {
          return (
            apiData[ix][singleItem][0][key]["tier"] &&
            Object.keys(apiData[ix][singleItem][0][key]["tier"]).map((item) => {
              if (ix === 0) tabTemp1.push(item);
              if (ix === 1) tabTemp2.push(item);
              if (ix === 2) tabTemp3.push(item);
            })
          );
        });
      apiData[ix] &&
        apiData[ix][singleItem] &&
        apiData[ix][singleItem][0] &&
        Object.keys(apiData[ix][singleItem][0]).map(function (key, index) {
          return (
            apiData[ix][singleItem][0][key]["rebate"] &&
            Object.keys(apiData[ix][singleItem][0][key]["rebate"])
              .sort(
                (a, b) => rebateHeading.indexOf(a) - rebateHeading.indexOf(b)
              )
              .map((item) => {
                if (ix === 0) tabTemp1.push(item);
                if (ix === 1) tabTemp2.push(item);
                if (ix === 2) tabTemp3.push(item);
              })
          );
        });

      //bopdyt

      apiData[ix] &&
        apiData[ix][singleItem] &&
        apiData[ix][singleItem].map((mainItem) => {
          mainItem &&
            Object.keys(mainItem) &&
            Object.keys(mainItem).map((key, index) => {
              mainItem[key] &&
                mainItem[key]["attribute"] &&
                Object.entries(mainItem[key]["attribute"]).map(
                  ([key, value]) => {
                    if (ix === 0) {
                      temp1[key] = value;
                    }
                    if (ix === 1) {
                      temp2[key] = value;
                    }
                    if (ix === 2) {
                      temp3[key] = value;
                    }
                  }
                );

              mainItem[key] &&
                mainItem[key]["tier"] &&
                Object.entries(mainItem[key]["tier"]).map(
                  ([key, value]) => {
                    if (ix === 0) {
                      temp1[key] = value;
                    }
                    if (ix === 1) {
                      temp2[key] = value;
                    }
                    if (ix === 2) {
                      temp3[key] = value;
                    }
                  }
                );

              mainItem[key] &&
                mainItem[key]["rebate"] &&
                Object.entries(mainItem[key]["rebate"]).map(
                  ([key, value]) => {
                    if (ix === 0) {
                      temp1[key] = value;
                    }
                    if (ix === 1) {
                      temp2[key] = value;
                    }
                    if (ix === 2) {
                      temp3[key] = value;
                    }
                  }
                );
            }
            );
          if (ix === 0)
            tabBody1Single.push(temp1)
          if (ix === 1)

            tabBody2Single.push(temp2)
          if (ix === 2)

            tabBody3Single.push(temp3)

          temp1 = {}
          temp2 = {}
          temp3 = {}

        });


    });

    console.log('temp11', tabBody1Single)
    setTabName1(tabTemp1);
    setTabName2(tabTemp2);
    setTabName3(tabTemp3);
    setTab1(tabBody1Single);
    setTab2(tabBody2Single);
    setTab3(tabBody3Single);
  }, [apiData, contractHeads]);

  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        onClick={handleSheetDownload}
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
        }}
      >
        <GetAppIcon />
        Download
      </Fab>
      {apiData && Object.keys(apiData).length > 0 && (
        <Box sx={{ width: "100%", background: "white" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {contractHeads.map((item) => {
                return (
                  <Tab label={item} {...a11yProps(0)} style={{ padding: 20 }} />
                );
              })}
            </Tabs>
          </Box>

          {/* {JSON.stringify(tab2)} */}

          {contractHeads.map((singleItem, ix) => {
            return (
              <TabPanel value={activeTab} index={ix}>
                {ix == 0 ?
                  <GridComponent
                    dataSource={tab1}
                    allowPaging={true}
                    pageSettings={{ pageSize: 6 }}
                    allowGrouping={true}
                    allowFiltering={true}
                    toolbar={['PdfExport', 'ExcelExport', 'Search']}
                    allowPdfExport={true} allowExcelExport={true}
                    toolbarClick={toolbarClick}
                    ref={g => grid = g}
                  >
                    <ColumnsDirective>
                      {
                        tabName1
                          .map((column, index) => {
                            return (
                              <ColumnDirective
                                key={index}
                                field={column}
                                headerText={column}
                                width={100}
                              />
                            );
                          })}
                    </ColumnsDirective>
                    <Inject services={[Toolbar, PdfExport, ExcelExport, Page, Sort, Filter, Search, Group, Resize]} />
                  </GridComponent>
                  :
                  ix === 1 ?
                    <GridComponent
                      dataSource={tab2}
                      allowPaging={true}
                      pageSettings={{ pageSize: 6 }}
                    >
                      <ColumnsDirective>
                        {
                          tabName2
                            .map((column, index) => {
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={column}
                                  headerText={column}
                                  width={100}
                                />
                              );
                            })}
                      </ColumnsDirective>
                    </GridComponent>
                    :
                    ix === 2 ?
                      <GridComponent
                        dataSource={tab3}
                        allowPaging={true}
                        pageSettings={{ pageSize: 6 }}
                      >
                        <ColumnsDirective>
                          {
                            tabName3
                              .map((column, index) => {
                                return (
                                  <ColumnDirective
                                    key={index}
                                    field={column}
                                    headerText={column}
                                    width={100}
                                  />
                                );
                              })}
                        </ColumnsDirective>
                      </GridComponent>
                      :
                      ''}
              </TabPanel>
            );
          })}
        </Box>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadSheet: (formData) =>
      dispatch(downloadContractTrendsResltsSheet(formData)),
  };
};

const mapStateToProps = (state) => {
  return {
    contractTrendsResult:
      state.addMultipleConfigurationData.contractTrendsResult,
    contractTrendsV2Result:
      state.addMultipleConfigurationData.contractTrendsV2Result,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractTrendsResult);
