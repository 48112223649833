import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    IconButton,
} from '@mui/material';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { createProductFeaturePricing, getProductFeatureData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { useHistory } from 'react-router-dom';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Clear } from '@mui/icons-material';
import momentt from 'moment-timezone';
import { Link } from 'react-router-dom';
import { DropdownArray, TextInputWithLabel } from '../../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 6,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: theme.typography.h6.fontSize,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 28,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    formDiv: {
        fontSize: 13
    }
}));

const AddProductFeaturePricing = props => {
    const classes = useStyles();
    const history = useHistory();
    const [productFeature, setProductFeature] = React.useState([]);
    const [productValue, setProductValue] = React.useState([]);
    const [productFeatureArray, setProductFeatureArray] = React.useState([]);
    const [productValuesArray, setProductValuesArray] = React.useState([]);
    const [cost, setCost] = React.useState([]);
    const [marketPrice, setMarketPrice] = React.useState([]);
    const [listPrice, setListPrice] = React.useState([]);
    const [startDate, setStartDate] = React.useState([]);
    const [endDate, setEndDate] = React.useState([]);
    const format = { product_feature: '', product_feature_value: '', cost: 0, market_price: 0, list_price: 0, start_date: null, end_date: null }
    const [allData, setAllData] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [buttonStatus, setButtonStatus] = React.useState(false);
    useEffect(() => {
        props.getProductFeatureData(1, 0);
        setAllData([format])
    }, [])
    useEffect(() => {
        var newArray = []
        if (props.productFeatureAll && props.productFeatureAll.records && props.productFeatureAll.records.length > 0) {
            props.productFeatureAll.records.map(item => {
                newArray.push(item.product_feature)
            })
            setProductFeatureArray(newArray)
        }
    }, [props.productFeatureAll])
    function handleProductFeature(newValue, index) {
        allData[index]['product_feature'] = (newValue)
        if (props.productFeatureAll && props.productFeatureAll.records && props.productFeatureAll.records.length > 0) {
            props.productFeatureAll.records.map(item => {
                if (item.product_feature === newValue) {
                    setProductValuesArray(item.product_feature_values)
                }
            })
        }
    }
    function handleProductValue(newValue, index) {
        allData[index]['product_feature_value'] = (newValue)
    }
    function handleCost(newValue, index) {
        allData[index]['cost'] = parseInt(newValue)
    }
    function handleMarketPrice(newValue, index) {
        allData[index]['market_price'] = parseInt(newValue)
    }
    function handleListPrice(newValue, index) {
        allData[index]['list_price'] = parseInt(newValue)
    }
    function handleStartDate(newValue, index) {
        allData[index]['start_date'] = (newValue)
    }
    function handleEndDate(newValue, index) {
        allData[index]['end_date'] = (newValue)
        setButtonStatus(allData[index]['product_feature'] !== '' &&
            allData[index]['product_feature_value'] !== '' &&
            allData[index]['cost'] !== '0' &&
            allData[index]['market_price'] !== '0' &&
            allData[index]['list_price'] !== '0' &&
            allData[index]['start_date'] !== null ? true : false)
    }
    function handleOnAdd() {
        setAllData([...allData, format])
        setAddMember(addMember + 1)
    }

    const handleDeleteLocally = (e, index) => {
        setProductFeature(item => item.filter((item, i) => i !== index));
        setProductValue(item => item.filter((item, i) => i !== index));
        setCost(item => item.filter((item, i) => i != index));
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
        setAllData(allData.filter((item, i) => i != index));
    }
    function handleClearAll() {
        setAllData([format]);
    }
    function handleOnSubmit() {
        props.onSubmit(allData);
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    // component="button"
                    to="/product-feature-pricing"
                >
                    Product Feature Pricing
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Product Feature</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid container spacing={2}
                                    style={{ marginBottom: 16, padding: '16px 16px 0px 16px' }}
                                >
                                    {(() => {
                                        let td = [];
                                        for (let i = 0; i < addMember; i++) {
                                            td.push(<>
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs={12}
                                                    spacing={2}
                                                    style={{ display: 'flex' }}
                                                >
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        style={{ marginRight: 8, marginTop: 0 }}
                                                    >  <DropdownArray heading={i == 0 && 'Product Feature'}
                                                        placeholder={'Select'}
                                                        twoline='true'
                                                        onChange={(e) => handleProductFeature(e, i)}
                                                        data={productFeatureArray && productFeatureArray.length > 0 ? productFeatureArray : []}
                                                        required />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        style={{ marginRight: 8, marginLeft: 8, marginTop: 0 }}
                                                    >
                                                        <DropdownArray heading={i == 0 && 'Product Feature Value'}
                                                            placeholder={'Select'}
                                                            twoline='true'
                                                            onChange={(e) => handleProductValue(e, i)}
                                                            data={productValuesArray && productValuesArray.length > 0 ? productValuesArray : []}
                                                            required />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        style={{ marginRight: 8, marginLeft: 8 }}
                                                    >   <TextInputWithLabel heading={i == 0 && 'Cost'} twoline='true' onChange={(e) => handleCost(e, i)} required />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        style={{ marginRight: 8, marginLeft: 8 }}
                                                    >   <TextInputWithLabel heading={i == 0 && 'Market Price'} twoline='true' onChange={(e) => handleMarketPrice(e, i)} required />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        style={{ marginRight: 8, marginLeft: 8 }}
                                                    >   <TextInputWithLabel heading={i == 0 && 'List Price'} twoline='true' onChange={(e) => handleListPrice(e, i)} required />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >   <TextInputWithLabel heading={i == 0 && 'Start Date'} type="date" format="MM/dd/yyyy" twoline='true' onChange={(e) => handleStartDate(e, i)} required />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >   <TextInputWithLabel heading={i == 0 && 'End Date'} type="date" format="MM/dd/yyyy" twoline='true' onChange={(e) => handleEndDate(e, i)} required />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                    style={{ paddingTop: 20 }}
                                                >
                                                    <IconButton
                                                        style={{ marginTop: i == 0 ? 32 : 10 }}
                                                        onClick={(e) => handleDeleteLocally(e, i)}
                                                        classes={{ root: classes.IconButton }}
                                                        size="large">
                                                        <Clear />
                                                    </IconButton>
                                                    <IconButton
                                                        style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 32 : 10 }}
                                                        onClick={handleOnAdd}
                                                        classes={{ root: classes.IconButton }}
                                                        size="large">
                                                        <AddBoxOutlinedIcon />
                                                    </IconButton>

                                                </Grid>

                                            </>)
                                        }
                                        return td;
                                    })()}
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    {/* <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button> */}
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={buttonStatus ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        productFeatureAll: state.productFeatureData.productFeatureData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createProductFeaturePricing(data)),
        getProductFeatureData: (pagination, limit) => dispatch(getProductFeatureData(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProductFeaturePricing);