import React from 'react';
import { Typography,Grid } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import FinancialCloseTab  from './FinancialCloseTab';
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 23,
        marginTop: -20,
        padding: '20px 16px',
    },
    dropdownContainer: {
        padding: 0,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10
        }
    }
}));
const FinancialClose = props => {
    const classes = useStyles();
    const { className } = props;
    return (
        <div>
            <div
                className={clsx(classes.root, className)}>
            <FinancialCloseTab />
            </div>
        </div >
    );
};
export default FinancialClose;