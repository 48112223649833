import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card, IconButton, Dialog,Grid,FormLabel,
    DialogContent, DialogTitle, Box, Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { LabelText } from '../../../components/Inputs';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import Moment from 'moment';
import { updateOutgoingClaimData, getClaimConfiguration } from '../../../redux/actions';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    
    select: {
        width: '100%',
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',

    },
    gridContainer: {
        width: '100%'
    },
    data: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
        alignItems: 'center',
        height: 36,
        display: 'flex'
    },
    root1: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    baseColor:{
        color:theme.palette.primary.main,
    },
    fontSettings:{
        // fontSize:theme.typography.h3.fontSize
    }
}));

const PopupContainer = props => {
    const classes = useStyles();
    const [outgoingClaimData, setOutgoingClaimData] = React.useState([]);
    const [claimAdjustmentCodes, setClaimAdjustmentCodes] = React.useState([]);
    const [onEdit, setOnEdit] = React.useState(false);
    const [editableLines,setEditableLines] = React.useState([]);
    const [filteredData,setFilterData] = React.useState([]);
    const [disLable,setDisLable] = React.useState({});
    useEffect(() => {
        if (props.outgoingClaimID && props.outgoingClaimDetailData && props.outgoingClaimDetailData.length > 0) {
            var data = props.outgoingClaimDetailData.filter(item => item.ID == parseInt(props.outgoingClaimID))
            setOutgoingClaimData(data)
        }
    }, [props.outgoingClaimDetailData, props.outgoingClaimID])

    const keywords = [
        'unique_identifier',
        'claim_number',
        'claim_date',
        'billing_date',
        'pricing_date',
        'response_date',
        
        'contract_number',
        'acquisition_price',
        'contract_price',
        'quantity',
        'claim_per_unit',
        'claim_amount',
        
        'supplier_contract_number',
        'supplier_acquisition_price',
        'supplier_contract_price',
        'supplier_claim_quantity',
        'adjusted_amount',
        'approved_amount',
        
        'claim_reason',
        'claim_status',
        'adjustment_code',
        'rejection_reason',
        'claim_error',
        'notes',
        
        'supplier_number',
        'supplier_name',
        'supplier_id_number',
        'end_customer_id',
        'end_customer_name',
        'end_customer_id_number',
        
        'material_number',
        'material_description',
        'material_id_number',
        'membership_owner_id',
        'membership_owner_name',
        'membership_owner_id_number',
        
        'customer_name',
        'street_address',
        'city',
        'region',
        'postal_code',
        'country',
        
        'billing_doc_number',
        'billing_doc_line',
        'net_value',
        'currency',
        'billing_doc_cancelled',
        'debit_credit_indicator',
        
        'company_code',
        'sales_org',
        'distribution_channel',
        'division',
        'profit_center',
        'cost_center',
        
        'class_of_trade',
        'customer_classification',
        'customer_group',
        'customer_industry',
        'delivery_tracking_number',
        'maximum_quantity_allowed',
        
        'customer_number',
        'document_category',
        'order_reason',
        'calendar_year_month',
        'fiscal_year_month',
        'plant',
        
        'claim_submission_type',
        'resubmit_number',
        'debit_memo',
        'credit_memo',
        'product_hierarchy',
        'material_group',
        
        'flex_attribute01',
        'flex_attribute02',
        'flex_attribute03',
        'flex_attribute04',
        'flex_attribute05',
        'mfr_part_number',
        
        'flex_key_figure01',
        'flex_key_figure02',
        'flex_key_figure03',
        'flex_key_figure04',
        'flex_key_figure05',
        'unit_price',
        
        'supplier_customer_number',
        'supplier_group',
        'cancelled_billing_doc',
        'original_invoice_for_return',
        'original_invoice_line_for_return',
        'billed_uom',
        
        'transaction_type',
        'processed',
        'created_at',
        'updated_at',
        'created_by',
        'changed_by'        

    ]
    useEffect(()=>{
        if(props.tableData&&props.tableData.field_label_attributes){
            Object.values(props.tableData.field_label_attributes).map(item=>{
                if(item.display){
                    filteredData.push(item.key)
                    disLable[item.key]=item.current

                }
            })
            filteredData.filter(function(elem, pos) {return filteredData.indexOf(elem) == pos})
        }
    },[props.tableData])
    useEffect(() => {
        props.getClaimData();
        {secureLocalStorage.getItem("dYtz").collaboration_id!=0 ?
        setEditableLines(['supplier_contract_number', 'supplier_acquisition_price', 'supplier_contract_price','rejection_reason','notes','response_date','supplier_claim_quantity','approved_amount','credit_memo'])
        :
        setEditableLines( ['pricing_date','contract_number','acquisition_price','contract_price','quantity','adjusted_amount','adjustment_code','rejection_reason','notes','end_customer_id','material_number','membership_owner_id'
        ])
        }
    }, []);
    useEffect(() => {

        if (props.claimData && props.claimData.length > 0) {
            setClaimAdjustmentCodes(props.claimData[0].claim_adjustment_codes)
        }

    }, [props.claimData])
    const handleSelectcell = (e) => {
        setOnEdit(!onEdit)

    }
    const handleAdjustmentCode = (event) => {
        var currentObject = {}
        outgoingClaimData.map((item, index) => {
            if (item.ID === props.outgoingClaimID) {
                currentObject = item
            }
        })
        currentObject['adjustment_code'] = event.target.value
        props.updateOutgoingClaimData(props.outgoingClaimID, currentObject)
        setOnEdit(false)

    }
    const handleOnSaveEdit = ({ name, value}) => {
        var data = outgoingClaimData;
        let newKey
        Object.entries(disLable).map((key,value)=>{
            if(name==key[1]){
                newKey=key[0]
            }
        })
        if (typeof (outgoingClaimData[0][newKey]) === 'number') {
            data[0][newKey] = parseFloat(value);
        } else {
            data[0][newKey] = value;
        }
        props.updateOutgoingClaimData(props.outgoingClaimID, data[0]);
    }
    function handleClose() {
        props.onClose(false)
    }

    return (
        <Dialog open={
            props.open}
            fullScreen
            style={{ margin: 15, height: 'auto' }}
        >
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <h2 className={classes.baseColor}>  Outgoing Claim Details</h2>
                    <Box>
                        <IconButton onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>

            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialogContent
            }}
            >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div>
                            <Grid container >
                                {outgoingClaimData &&
                                    keywords
                                    .filter(item=>filteredData.includes(item))
                                    .map(item => {

                                        return (
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >


                                                {editableLines.includes(item) ?
                                                    <Grid container
                                                        className={clsx(classes.root1)}
                                                        style={{ marginTop:'-0.8rem' }}
                                                    >
                                                        <Grid item style={{ width: '100%', marginLeft: -5, paddingRight: 25 }}>
                                                            <FormLabel className={classes.fontSettings}  style={{ paddingLeft: 5, textTransform: 'capitalize', paddingTop: 15 }}>
                                                                {outgoingClaimData[0] && Object.keys(outgoingClaimData[0]).includes(item) ? disLable[item] : ''}
                                                            </FormLabel>
                                                            <div className={classes.data}
                                                            >
                                                                {item == 'adjustment_code' ?
                                                                    <div style={{ whiteSpace: 'nowrap',paddingLeft:10 }} >
                                                                        <Select
                                                                            style={{ display: onEdit ? 'inline-block' : 'none' }}
                                                                            value={outgoingClaimData[0] && outgoingClaimData[0][item] ? outgoingClaimData[0][item] : ''}
                                                                            onChange={(e) => handleAdjustmentCode(e)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >
                                                                            {claimAdjustmentCodes.map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item} key={item}>
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>

                                                                        <div style={{ display: onEdit ? 'none' : 'block',paddingLeft:10 }}>
                                                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                                                <div style={{marginTop:2,marginRight:5}}>
                                                                                    {outgoingClaimData[0] && outgoingClaimData[0][item] ? outgoingClaimData[0][item] : ''}
                                                                                </div>
                                                                                <div style={{ color: 'black' }}>
                                                                                    <EditIcon  fontSize="small" onClick={handleSelectcell}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    <EditText
                                                                        name={disLable[item]}
                                                                        defaultValue={outgoingClaimData[0] && outgoingClaimData[0][item] ? Moment(outgoingClaimData[0][item], Moment.ISO_8601, true).isValid() && outgoingClaimData[0][item].charAt(outgoingClaimData[0][item].length - 1) === 'Z' ? Moment(outgoingClaimData[0][item]).format('MM/DD/YYYY') : outgoingClaimData[0][item] : ''}
                                                                        inputClassName='bg-success'
                                                                        onSave={handleOnSaveEdit}
                                                                        showEditButton

                                                                    />
                                                                }
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    :
                                                    <LabelText className={classes.fontSettings} heading={outgoingClaimData[0] && Object.keys(outgoingClaimData[0]).includes(item) ? disLable[item] : ''} style={{ textTransform: 'capitalize' }} data={outgoingClaimData[0] && outgoingClaimData[0][item] ? Moment(outgoingClaimData[0][item], Moment.ISO_8601, true).isValid() && outgoingClaimData[0][item].toString().charAt(outgoingClaimData[0][item].length - 1) === 'Z' ? Moment(outgoingClaimData[0][item]).format('MM/DD/YYYY') : outgoingClaimData[0][item] : ''} twoline='true' />
                                                }
                                            </Grid>
                                        )
                                    }

                                    )}

                            </Grid>
                        </div>
                    </form>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        updateOutgoingClaimData: (id, formData) => dispatch(updateOutgoingClaimData(id, formData)),
        getClaimData: () => dispatch(getClaimConfiguration()),
    }
}

const mapStateToProps = state => {
    return {
        outgoingClaimDetailData: state.initialData.outgoingClaimDetailData,
        claimData: state.calculationAnalysisData.claimData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);