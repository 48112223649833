import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Button, Typography, useMediaQuery, IconButton,
    Dialog, DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import { Edit, DeleteForever } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { createProductGroup, getProductGroupData, getLblDispDesFieldValue } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import { useTour } from "@reactour/tour";
import LoadingOverlay from 'react-loading-overlay';
import { ProductGroupToolbar } from './';
import * as loom from '@loomhq/loom-embed';
import Modal from '@mui/material/Modal';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../../components/Dialog';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    }
}));

const ProductGroupUserTable = props => {
    useEffect(() => {
        var data = { "data_source": "product_group_master" };
        props.getProductGroupData(1, 10);
        props.loadSourceDataType(data, 'post');


    }, []);
    const { setIsOpen } = useTour();
    useEffect(() => {
        setIsOpen(true)
    }, [])
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [openModal, setOpenModal] = React.useState(false)
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);

    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("");
    const [columns, setColumns] = React.useState([]);

    useEffect(() => {
        if (props.productGroup && props.productGroup.records && props.productGroup.total_record > 0) {
            setDataRows(props.productGroup.records)
        }
    }, [props.productGroup]);

    function editProductGroup(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/product-group/edit-product/' + id,
            id: id
        });
    }
    function viewMembership(id) {
        history.push({
            pathname: '/product-group/view-product/' + id,
            id: id
        });
    }

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            console.log(currentId);
            props.deleteProductGroupData(null, currentId);

        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };




    const action = {

        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_product_group-' + sessionStorage.getItem('application')) &&
                    <>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editProductGroup(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>

                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleClickOpen(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    </>
                }
            </div>
    }

    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    newArray.push({
                        field: item.key,
                        title: item.current,
                        type: item.key != 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                        render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
                            Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    })

                })
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <Dialog open={openModal}>
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <div style={{ width: '100%', height: '100%' }}><iframe src="https://www.loom.com/embed/73ec272073984a91b6dc826ff0251041" frameborder="0" style={{ width: '100%', height: '100%' }}></iframe></div>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                    }}>
                        <Button onClick={() => setOpenModal(false)} color="primary" autoFocus variant="contained"  >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={clsx({
                    [classes.row]: isDesktop
                })}
                >
                    <Typography variant="h1" color='primary'> Product Group </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_product_group-' + sessionStorage.getItem('application')) &&
                        <ProductGroupToolbar />}
                </div>

                {
                    dataRows ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRows}
                            style={{ marginTop: '0px' }}
                            options={{
                                maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 15,
                                pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                            }}

                        />
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />

            </LoadingOverlay >

        </div >
    );
};

const mapStateToProps = state => {
    return {
        productGroup: state.customerData.productGroupData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getProductGroupData: (pagination, limit) => dispatch(getProductGroupData(pagination, limit)),
        deleteProductGroupData: (data, id) => dispatch(createProductGroup(data, 'delete', id)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroupUserTable);