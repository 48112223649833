import Palette from '../palette';

export default {
  root: {
    '& $text': {
      fontSize: 12
    },
    '&$active': {
      border: '1px solid',
      borderColor: Palette.primary.main,
      padding: 5,
      margin: -5,
      borderRadius: 26,
      backgroundColor: '#FFF',
      zIndex: 100
    },
    width: 26,
    height: 26
  },
};