import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, FormLabel, OutlinedInput, Select, MenuItem, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { runPriceCalcForSimulation, getSimulationDropdown } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { ChipInput, TextInputWithLabel } from '../../../components/Inputs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
    chipContainers: {
        marginBottom: 21
    }
}));

const PriceSimulationReport = props => {
    const classes = useStyles();
    const { className } = props;
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [simulationNumber, setSimulationNumber] = React.useState('');
    const [simulationNumberArray, setSimulationNumberArray] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [simulationCategory, setSimulationCategory] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const [createdAt, setCreatedAt] = React.useState(null);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        setPricingDateStart(Moment.utc(new Date().getMonth() + 1 + "/" + (new Date().getDate()) + "/" + new Date().getFullYear()))
        // const simulationAPIBody={
        //     "created_at":createdAt,
        //     "tags" : tags,
        //     "simulation_category":simulationCategory
        //     }
        //     props.getSimulationDropdown(simulationAPIBody)
    }, []);
    useEffect(() => {
        const simulationAPIBody = {
            "created_at": createdAt,
            "tags": tags,
            "simulation_category": simulationCategory
        };
        props.getSimulationDropdown(simulationAPIBody)
    }, [createdAt, tags, simulationCategory])
    useEffect(() => {
        var newArray = [];
        if (props.pricingSimulationDropdown && props.pricingSimulationDropdown.length > 0)
            props.pricingSimulationDropdown.map((key, item) => {
                newArray.push(item);
            })
        setSimulationNumberArray(newArray)

    }, [props.pricingSimulationDropdown])
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue)
        // setCustomerNumber([...customerNumber, newValue])
    }
    function handleCustomerNumberDelete(newValue) {
        var deletedCustomerNumber = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomerNumber)
    }
    function handleMaterialNumber(newValue) {
        // setMaterialNumber([...materialNumber, newValue])
        setMaterialNumber(newValue)
    }
    function handleMaterialNumberDelete(newValue) {
        var deletedMaterialNumber = materialNumber.filter(item => item !== newValue)
        setMaterialNumber(deletedMaterialNumber)
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    const handleSimulationNumber = (newValue) => {
        setSimulationNumber(newValue.target.value)
    }

    const handleTags = (e) => {
        // setTags([...tags, e])
        setTags(e)
    }
    function handleTagDelete(newValue) {
        var deletedTag = tags.filter(item => item !== newValue)
        setTags(deletedTag)
    }
    const handleCreatedAt = (e) => {
        setCreatedAt(Moment(e));
    }
    const handleSimulationCategory = (e) => {
        setSimulationCategory(e.target.value);
        if (e.target.value === 'CPQ') {
            const simulationAPIBody = {
                "created_at": createdAt,
                "tags": tags,
                "simulation_category": e.target.value
            };
            props.getSimulationDropdown(simulationAPIBody)
        }
    }

    const handleClear = () => {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setPricingDateStart(null);
        setSimulationNumberArray([]);
        setSimulationNumber('');
        setTags([]);
        setCreatedAt(null);
        setSimulationCategory('');
    }
    const handleClickOpen = () => {
        setOpen(true)

    };

    const handleDialog = () => {
        const formData = {
            "customer_number": customerNumber,
            "material_number": materialNumber,
            "simulation_number": simulationNumber.length != 0 ? parseFloat(simulationNumber) : 1,
            "pricing_date": Moment.utc(pricingDateStart).local(),
            // "created_at":createdAt,
            // "tags" : tags,
            "simulation_category": simulationCategory
        };
        setOpen(false);
        props.onSubmit(formData, 'PriceSimulationReport');
        handleClear();
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h2">
                        Price Simulation Report
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerNumber}
                                    onAdd={(chips) => handleCustomerNumber(chips)}
                                    onDelete={(chip) => handleCustomerNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipContainers}>
                                    <ChipInput data={customerNumber} onAdd={handleCustomerNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleMaterialNumber(chips)}
                                    onDelete={(chip) => handleMaterialNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipContainers}>
                                    <ChipInput data={materialNumber} onAdd={handleMaterialNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Tags
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    onAdd={(chips) => handleTags(chips)}
                                    onDelete={(chip) => handleTagDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipContainers}>
                                    <ChipInput data={tags} onAdd={handleTags} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            ><FormLabel required>Pricing Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={pricingDateStart}
                                            onChange={handlePricingDateStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            ><FormLabel>Created At</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={createdAt}
                                            onChange={handleCreatedAt}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}>
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formDiv}>
                                        Simulation Category
                                    </FormLabel>
                                    <Select
                                        value={simulationCategory}
                                        onChange={handleSimulationCategory}
                                        style={{ textTransform: 'capitalize' }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={'CPQ'} key={'CPQ'}>
                                            {'CPQ'}
                                        </MenuItem>
                                        <MenuItem value={'price catalog'} key={'price catalog'}>
                                            {'Price Catalog'}
                                        </MenuItem>
                                        <MenuItem value={'sales data - forecast'} key={'sales data - forecast'}>
                                            {'Sales Data - Forecast'}
                                        </MenuItem>
                                        <MenuItem value={'sales data - historical'} key={'sales data - historical'}>
                                            {'Sales Data - Historical'}
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ display: (simulationCategory === 'CPQ' ? 'block' : 'none') }}
                            >
                                <div className={classes.selectRoot} style={{ marginTop: 20, marginLeft: 12 }} >
                                    <FormLabel className={classes.formDiv}>
                                        Simulation Number
                                    </FormLabel>
                                    <Select
                                        value={simulationNumber}
                                        onChange={handleSimulationNumber}
                                        style={{ textTransform: 'capitalize' }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >{simulationNumberArray && simulationNumberArray.length > 0 && simulationNumberArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                    </Select>
                                </div>
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={pricingDateStart ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Price Simulation Report?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay >
    );
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData, type) => dispatch(runPriceCalcForSimulation(formData, type)),
        getSimulationDropdown: (simulationAPIBody) => dispatch(getSimulationDropdown(simulationAPIBody)),
    }
}
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
        pricingSimulationDropdown: state.pricingData.simulationNumberDropdown,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceSimulationReport);