import React, { useEffect, useRef } from 'react';
import {
    Breadcrumbs,
    Card
} from '@mui/material';

import { makeStyles } from "@mui/styles";
import { promPlanSalsIncentives  } from '../../redux/actions';
import { connect } from 'react-redux';

import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader'
import { StockChartComponent, StockChartSeriesCollectionDirective, StockChartSeriesDirective, Inject, DateTime, Tooltip, RangeTooltip, Crosshair, LineSeries, SplineSeries, CandleSeries, HiloOpenCloseSeries, HiloSeries, RangeAreaSeries, Trendlines } from '@syncfusion/ej2-react-charts';
import { EmaIndicator, RsiIndicator, BollingerBands, TmaIndicator, MomentumIndicator, SmaIndicator, AtrIndicator, AccumulationDistributionIndicator, MacdIndicator, StochasticIndicator, Export } from '@syncfusion/ej2-react-charts';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
        
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    paper: { minWidth: window.screen.width * .50 },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 34,
        padding: 0,
        marginTop: 10
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginRight: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black,
            
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    select: {
        width: '100%',
        marginTop: 10,
        
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset',
        borderRadius: 5,
        
        
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'

    },
    spinner: {
        height: '100vh'
    },
    fontSetting:{
        fontSize:theme.typography.h3.fontSize
    }
}));

const StockChart = props => {
    const classes = useStyles();

    const tooltipRender = (args) => {
        if (args.text.split('<br/>')[4]) {
            let target = parseInt(args.text.split('<br/>')[4].split('<b>')[1].split('</b>')[0]);
            let value = (target / 100000000).toFixed(1) + 'B';
            args.text = args.text.replace(args.text.split('<br/>')[4].split('<b>')[1].split('</b>')[0], value);
        }
    };
    const [chartData,setChartData]= React.useState([])
  useEffect(()=>{
    var newArray=[]
    if(props.promPlanningSalesInc && props.promPlanningSalesInc.profit){
        props.promPlanningSalesInc.profit.map(item=>{
            newArray.push({x:new Date(item.period),'margin':item.data})
        })
    }
    setChartData(newArray)
  },[props.promPlanningSalesInc])
    useEffect(() => {
        var ed = Moment().subtract(0, 'months').endOf('month').format('MM/DD/YYYY');
        var sd = Moment().subtract(5, 'months').startOf('month').format('MM/DD/YYYY');
        var formData = {
            "application_type": 'Customer Rebate',
            "data_source_type": 'Direct - Sales Data',
            "posting_type": 'payment',
            "start_date": sd,
            "end_date": ed,
            "periodicity": 'month',
            "key_figure_name": 'net_value',
        }
        props.promPlanSalsIncentives(formData);
    }, []);



    const [listOpen, setListOpen] = React.useState(false);
  
    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    return (
        <LoadingOverlay
        active={props.loading}
        styles={{
            spinner: (base) => ({
                ...base,
                width: '50px',
                '& svg circle': {
                    stroke: '#64C4B4'
                }
            }),
            overlay: (base) => ({
                ...base,
                background: 'rgba(52, 52, 52, 0)'
            }),
            content: (base) => ({
                ...base,
                color: '#64C4B4'
            })
        }}
        spinner={<HashLoader />}
        className={classes.spinner}
        >
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                        <StockChartComponent id='stockchartdefault'
                         primaryXAxis={{ valueType: 'DateTime', majorGridLines: { width: 0 }, majorTickLines: { color: 'transparent' }, crosshairTooltip: { enable: true } }} 
                         primaryYAxis={{ labelFormat: 'n0', lineStyle: { width: 0 }, rangePadding: 'None', majorTickLines: { height: 0 } }} 
                         chartArea={{ border: { width: 0 } }} 
                         tooltip={{ enable: true, shared: true }} 
                         tooltipRender={tooltipRender} 
                         crosshair={{ enable: true }} 
                        //  load={load.bind(this)} 
                         title='AAPL Stock Price'
                         >
                    <Inject services={[DateTime, Tooltip, RangeTooltip, Crosshair, LineSeries, SplineSeries, CandleSeries, HiloOpenCloseSeries, HiloSeries, RangeAreaSeries, Trendlines, EmaIndicator, RsiIndicator, BollingerBands, TmaIndicator, MomentumIndicator, SmaIndicator, AtrIndicator, Export, AccumulationDistributionIndicator, MacdIndicator, StochasticIndicator]}/>
                    <StockChartSeriesCollectionDirective>
                        <StockChartSeriesDirective dataSource={chartData} xName='x' type='Candle' animation={{ enable: true }}/>
                    </StockChartSeriesCollectionDirective>
                </StockChartComponent>
                        </div>
                    </form>
                </Card>
                
            </div >
        </div >
        </LoadingOverlay >
    );
};
const mapDispatchToProps = dispatch => {
    return {
        promPlanSalsIncentives: (data) => dispatch(promPlanSalsIncentives(data)),        
    }
}
const mapStateToProps = state => {
    return {
        loading: state.forecastData.loading,
        promPlanningSalesInc: state.initialData.promPlanningSalesInc
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(StockChart);