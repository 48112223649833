import React, { useEffect } from 'react';
import {
    Typography, Card, Table, TableCell,
    TableHead, TableRow, TableContainer, TableBody, IconButton,
    OutlinedInput, Grid, Button, Select, MenuItem,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createGlobalSetup, getGlobalSetupData } from '../../../redux/actions';
import secureLocalStorage from "react-secure-storage";
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: '0px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    // tableHeader:{
    //     position: 'sticky',
    //     left: 0,
    //     background: 'white',
    //     zIndex: 1,
    // },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },
}));

const GlobalSetup = (props) => {

    const classes = useStyles();
    const { className } = props;
    const [promoFields, setPromoFields] = React.useState([]);
    const [addMaterial, setAddMaterial] = React.useState(1);
    const [editMode, setEditMode] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const format = {
        country: '',
        promotion_name: '',
        promotion_description: '',
        benefit_rebate: 0, benefit_coverage_type: '', benefit_coverage_length: 0, benefit_spiff: 0, benefit_promo_finance_interest: 0, benefit_promo_finance_term_structure: '', benefit_extra_rebate: 0,
        takerate_global: 0, takerate_rebate: 0, takerate_coverage: 0, takerate_spiff: 0, takerate_std_finance: 0, takerate_promo_finance: 0, takerate_extra_rebate: 0
    }
    const removedFields = ['ID', 'CreatedAt', 'UpdatedAt', 'DeletedAt', 'created_by_id', 'organization_id', 'updated_by_id']
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    useEffect(() => {
        props.getGlobalSetupData();
    }, []);

    useEffect(() => {
        if (props.budgetData && props.budgetData.records && props.budgetData.records.length > 0) {
            setEditMode(true)
            setPromoFields(props.budgetData.records.sort((a, b) => a.ID > b.ID ? 1 : -1))
            setAddMaterial(props.budgetData.records.length)
            setDisabled(false)
        }
        else {
            setPromoFields([format])
            setEditMode(false)
        }
    }, [props.budgetData]);


    function handleChangeValue(event, id) {

        if (event.target.name == 'country') {
            promoFields[id][event.target.name] = event.target.value
        }
        else {
            var index = event.target.id;
            if (event.target.name == 'benefit_coverage_type' || event.target.name == 'benefit_promo_finance_term_structure' || event.target.name == 'promotion_name' || event.target.name == 'promotion_description' || event.target.name == 'country') {
                promoFields[index][event.target.name] = event.target.value
            }
            else {
                promoFields[index][event.target.name] = parseFloat(event.target.value)
            }
        }
        if ((promoFields.filter((item, i) => item.country == '' || item.promotion_name == '')).length > 0)
            setDisabled(true)
        else
            setDisabled(false)
    }

    function handleOnAdd() {
        var newValue = promoFields
        newValue = [...newValue, format]
        setPromoFields(newValue);
        setAddMaterial(addMaterial + 1)
    }
    function handleSubmit() {
        var savedData = promoFields ?
            promoFields.filter((item, i) => item.country != '' && item.promotion_name != '')
            : []
        if (editMode) {
            savedData.map((item, index) => {

                if (item['ID']) {
                    for (var propName in removedFields) {
                        delete savedData[index][propName]
                    }
                }
            })
            props.onSubmit(savedData, 'edit')
        }
        else
            props.onSubmit(savedData)
    }
    function handleDeleteLocally(id, index) {
        setPromoFields(item => item.filter((item, i) => i !== index));
        if (addMaterial - 1 > 0)
            setAddMaterial(addMaterial - 1)
        else
            handleOnAdd()
    }
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary'>
                        Global Setup

                    </Typography>
                </div>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >

                        <div className={classes.container} >

                            <TableContainer style={{ maxHeight: 700, marginTop: -20 }}>
                                <Table>
                                    <TableHead style={{ backgroundColor: "#fff", position: "sticky", top: 0, zIndex: 1 }}>
                                        <TableRow>
                                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 80 }} rowSpan={2} >Country</TableCell>
                                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 80 }} rowSpan={2} >Promotion Name</TableCell>
                                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 150 }} rowSpan={2} >Promotion Description</TableCell>
                                            <TableCell align='center' className={classes.tabHead} colSpan={7}>Cost</TableCell>
                                            <TableCell align='center' className={classes.tabHead} colSpan={7}>Take Rate</TableCell>
                                            <TableCell align='center' className={classes.tabHead} style={{ width: 30, paddingLeft: 1, paddingRight: 1 }} rowSpan={2}>Actions</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" className={classes.tabHead}>Rebate</TableCell>
                                            <TableCell align="center" className={classes.tabHead} style={{ minWidth: 30 }}>Cov Type</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>Cov Length</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>SPIFF</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>F2 Interest</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>F2 Fin Structure</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>Extra</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>Global</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>Rebate</TableCell>
                                            <TableCell align="center" className={classes.tabHead} style={{ minWidth: 50 }}>Cov</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>SPIFF</TableCell>
                                            <TableCell align="center" className={classes.tabHead} style={{ minWidth: 50 }}>Std Fin</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>Promo Fin</TableCell>
                                            <TableCell align="center" className={classes.tabHead}>Extra</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {

                                            promoFields && promoFields
                                                .map((formItem, i) => {
                                                    return (
                                                        <StyledTableRow key={i}>
                                                            <TableCell >
                                                                <Select
                                                                    disableUnderline
                                                                    defaultValue={promoFields[i]['country'] ? promoFields[i]['country'] : ''}
                                                                    onChange={(e) => handleChangeValue(e, i)}
                                                                    key={i}
                                                                    id={i}
                                                                    name={'country'}
                                                                    displayEmpty
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    style={{ width: '100%' }}

                                                                >
                                                                    <MenuItem value={'CA'} key={'CA'}>
                                                                        Canada
                                                                    </MenuItem>
                                                                    <MenuItem value={'US'} key={'US'}>
                                                                        US
                                                                    </MenuItem>
                                                                </Select>

                                                            </TableCell>
                                                            <TableCell >
                                                                <OutlinedInput twoline='true'
                                                                    //key={i}
                                                                    name={'promotion_name'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    onClick={e => e.target.focus()}
                                                                    defaultValue={promoFields[i]['promotion_name'] ? promoFields[i]['promotion_name'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell >
                                                                <OutlinedInput twoline='true'
                                                                    style={{ width: '100%' }}
                                                                    key={i}
                                                                    name={'promotion_description'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    onClick={e => e.target.focus()}
                                                                    defaultValue={promoFields[i]['promotion_description'] ? promoFields[i]['promotion_description'] : ''}
                                                                />

                                                            </TableCell>

                                                            <TableCell >
                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'benefit_rebate'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    onClick={e => e.target.focus()}
                                                                    defaultValue={formItem['benefit_rebate'] ? formItem['benefit_rebate'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>
                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    name={'benefit_coverage_type'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_coverage_type'] ? formItem['benefit_coverage_type'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'benefit_coverage_length'}
                                                                    onClick={e => e.target.focus()}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_coverage_length'] ? formItem['benefit_coverage_length'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'benefit_spiff'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_spiff'] ? formItem['benefit_spiff'] : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'benefit_promo_finance_interest'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_promo_finance_interest'] ? formItem['benefit_promo_finance_interest'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    name={'benefit_promo_finance_term_structure'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_promo_finance_term_structure'] ? formItem['benefit_promo_finance_term_structure'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'benefit_extra_rebate'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_extra_rebate'] ? formItem['benefit_extra_rebate'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'takerate_global'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_global'] ? formItem['takerate_global'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'takerate_rebate'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_rebate'] ? formItem['takerate_rebate'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'takerate_coverage'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_coverage'] ? formItem['takerate_coverage'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'takerate_spiff'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_spiff'] ? formItem['takerate_spiff'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'takerate_std_finance'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_std_finance'] ? formItem['takerate_std_finance'] : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'takerate_promo_finance'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_promo_finance'] ? formItem['takerate_promo_finance'] : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell>

                                                                <OutlinedInput twoline='true'
                                                                    key={i}
                                                                    type={'number'}
                                                                    name={'takerate_extra_rebate'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_extra_rebate'] ? formItem['takerate_extra_rebate'] : ''}
                                                                />

                                                            </TableCell>

                                                            <TableCell style={{ display: 'flex', paddingTop: 30, paddingBottom: 30 }}>
                                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_global_setup-' + sessionStorage.getItem('application')) &&
                                                                    <IconButton
                                                                        classes={{ root: classes.IconButton }}
                                                                        onClick={() => handleDeleteLocally(formItem['ID'], i)}
                                                                        size="large">
                                                                        <DeleteForever />
                                                                    </IconButton>}
                                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_global_setup-' + sessionStorage.getItem('application')) &&
                                                                    <IconButton
                                                                        style={{ visibility: i === addMaterial - 1 ? 'visible' : 'hidden', padding: 0 }}
                                                                        onClick={handleOnAdd}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <AddBoxOutlined />
                                                                    </IconButton>}

                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );


                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </form>
                </Card>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_global_setup-' + sessionStorage.getItem('application')) &&
                    <Grid
                        item
                        md={12}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >  <Button variant="contained" color='primary' className={classes.button} onClick={handleSubmit}
                        disabled={disabled}
                    >

                            {props.loading ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                :
                                'Save'
                            }
                        </Button>
                    </Grid>}
            </div >

        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type) => dispatch(createGlobalSetup(data, null, type)),
        onDelete: (id) => dispatch(createGlobalSetup(null, id, 'delete')),
        getGlobalSetupData: () => dispatch(getGlobalSetupData()),
    }
}

const mapStateToProps = state => {
    return {
        budgetData: state.promotionData.globalSetupData,
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSetup);