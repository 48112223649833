import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from "@mui/styles";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 3,
        paddingBottom: 28
    },
    button: {
        marginRight: 25,
        width: 140
    }
}));

const SubmitContainer = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Button variant="outlined" color="primary" className={classes.button} onClick={props.onCancel}>
                Clear
            </Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={props.onSubmit} disabled={props.loadingAPI ? true : false}>
                {props.loadingAPI ?
                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                    :
                    'Submit'
                }
            </Button>
        </div>
    );

}

export default SubmitContainer;