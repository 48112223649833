import React from 'react';
import {
    Card,
    CardHeader,
    Grid,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { TextInputWithLabel } from '../../components/Inputs';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        paddingLeft: 16,
        paddingRight: 16
    }
}));

const CustomerSpecifiedFieldsContainer = props => {
    const classes = useStyles();
    const data = [
        { id: 1, value: "Flex Field 1" },
        { id: 2, value: "Flex Field 2" },
        { id: 3, value: "Flex Field 3" },
        { id: 4, value: "Flex Field 4" },
    ];
    const { className } = props;

    return (
        <div
            className={clsx(classes.root, className)}
        >
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="CUSTOMER SPECIFIC FIELDS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <div className={classes.container}>
                        <Grid container >
                            {data.map((item, index) => (
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    key={item.id}
                                >
                                    <TextInputWithLabel heading={item.value} twoline='true' />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </form>
            </Card>
        </div>
    );

};

export default CustomerSpecifiedFieldsContainer;