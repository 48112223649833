import React, { useEffect } from 'react';
import {
    Typography,
    Breadcrumbs,
    Button,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { createAttributeData, getAttributeListData, updateAttributeData } from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import { AttributeData } from './components';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    }
}));

const AddAttribute = props => {
    const classes = useStyles();
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const [data, setData] = React.useState(null);
    var generalData = {};
    const history = useHistory();
    const theme = useTheme();
    const { id } = useParams();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    useEffect(() => {
        if (id)
            setData(props.attributeData);
    }, []);

    const handleComplete = () => {
        id ? props.updateAttributeData(id, generalData) : props.onSubmit(generalData);
    };
    const handleCancel = () => {
        history.push("/attributes");
    };

    const checkValues = () => {
        if (generalData.attribute_name && generalData.attribute_value) {
            setSaveDisabled(false)
        } else {
            setSaveDisabled(true)
        }
    }

    function handleGeneralDataChange(values) {
        generalData = values;
        checkValues();
    };

    return (
        <div>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/attributes'
                    >
                        Attributes and Description Data Setup
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp;{id ? 'Edit Data' : 'Add Data'}</Typography>
                </Breadcrumbs>
                <div className={classes.instructions}>
                    <AttributeData onChange={handleGeneralDataChange} data={id && data ? data : ''} />
                </div>
                <Grid container >
                    <Grid
                        item
                        md={12}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Button variant="outlined" color="primary" onClick={props.loading ? '' : handleComplete}
                            className={clsx({
                                [classes.button]: true,
                                [classes.shiftContent]: !isDesktop,
                            })}
                            disabled={saveDisabled}
                        >
                            {props.loading ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                id ? 'Update' : 'Save'
                            }
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleCancel} className={classes.button}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createAttributeData(data)),
        updateAttributeData: (id, data) => dispatch(updateAttributeData(id, data)),
        getAttributeDataByName: (data) => dispatch(getAttributeListData(data)),
    }
}

const mapStateToProps = state => {
    return {
        attributeData: state.attributeListData.editAttributeData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttribute);