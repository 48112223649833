import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    FormLabel,
    Checkbox,
    MenuItem,
    Select, OutlinedInput, Input, ListItemText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { createAnalysisLevel, getAnalysisLevelValues } from '../../../redux/actions';
import { getApprovalGroupData } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 15,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 9,
        width: '100%',
        paddingLeft: 10,
        height: 37
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll"
    },
    formlabel: {
        marginBottom: 8,
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },

}));

const AddAnalysisLevel = props => {
    const classes = useStyles();
    useEffect(() => {
        props.getFieldValues('qualifier_key')
        props.getFieldValues('driver_name')
        props.getApprovalGroup()
    }, [])

    const [priceTypeName, setPriceTypeName] = React.useState([]);
    const [qualifierName, setQualifierName] = React.useState([]);
    const [approvalGroup, setapprovalGroup] = React.useState(0);
    const [externalMappingRule, setExternalMappingRule] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [selected2, setSelected2] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [open2, setOpen2] = React.useState('');
    const [approvalGroupArray, setApprovalGroupArray] = React.useState([]);
    const [qualifierNameArray, setQualifierNameArray] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [priority, setPriority] = React.useState('');
    const [priceListIdDescription, setPriceListIdDescription] = React.useState('');

    const isAllSelected =
        qualifierNameArray && qualifierNameArray.length > 0 && selected.length === qualifierNameArray.length;

    const isAllSelected2 =
        priceTypeNameArray && priceTypeNameArray.length > 0 && selected2.length === priceTypeNameArray.length;
    function handleOnSubmit() {
        var data = {
            "qualifier_key": qualifierName,
            "analysis_level_id_description": priceListIdDescription,
            "driver_name": priceTypeName,
            "external_mapping_rule": externalMappingRule,
        }
        props.onSubmit(data);
    }

    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === qualifierNameArray.length ? [] : qualifierNameArray);
        }
        else
            setSelected(newSelected)

    }
    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected2(selected2.length === priceTypeNameArray.length ? [] : priceTypeNameArray);
        }
        else
            setSelected2(newSelected)
    }
    function onAdd() {
        setQualifierName(selected)
        setOpen(false)
        setSelected([])
    }
    function onAdd2() {
        setPriceTypeName(selected2)
        setOpen2(false)
        setSelected2([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])

    }
    function onCancel2() {
        setOpen2(false)
        setSelected2([])
    }
    function handleOpen() {
        setSelected(qualifierName)
        setOpen(true)
    }
    function handleOpen2() {
        setSelected2(priceTypeName)
        setOpen2(true)
    }
    useEffect(() => {
        var newArray = []
        if (props.qualifierNameList && props.qualifierNameList.length > 0)
            props.qualifierNameList.map(item => {
                newArray.push(item.qualifier_key)
            })
        setQualifierNameArray(newArray)
    }, [props.qualifierNameList])

    useEffect(() => {
        var newArray = []
        if (props.priceTypeNameList && props.priceTypeNameList.length > 0)
            props.priceTypeNameList.map(item => {
                newArray.push(item.driver_name)
            })
        setPriceTypeNameArray(newArray)
    }, [props.priceTypeNameList])
    useEffect(() => {
        var newArray = []
        if (props.approvalGroup && props.approvalGroup.length > 0) {
            props.approvalGroup
                .map(item => {
                    newArray.push({ 'name': item.name, 'id': item.ID })

                })

        }
        setApprovalGroupArray(newArray)
    }, [props.approvalGroup])
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    return (
        <LoadingOverlay
            active={props.loadingAPI}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}

        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/analysis-level'
                    >
                        Analysis Level
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Add Analysis Level</Typography>
                </Breadcrumbs>

                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formlabel}>
                                                Analysis Level ID Description
                                            </FormLabel>
                                            <OutlinedInput
                                                value={priceListIdDescription}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setPriceListIdDescription(e.target.value)} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel variant="h5" className={classes.formDiv} required>
                                                Attribute Name
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple
                                                    value={qualifierName}
                                                    style={{ height: "37px", width: '100%', marginTop: 9, textTransform: 'capitalize' }}
                                                    input={<OutlinedInput />}
                                                    onOpen={() => handleOpen()}
                                                    onClose={() => setOpen(false)}
                                                    open={open}
                                                    renderValue={(appType) => {
                                                        var qualifier = []
                                                        appType
                                                            .map(item => {
                                                                qualifier.push(item.replace(/_/g, ' '))

                                                            })
                                                        return qualifier.join(", ")
                                                    }}
                                                    className={classes.multiSelect}

                                                >
                                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={isAllSelected} />
                                                        <ListItemText primary='Select All' />
                                                    </MenuItem>
                                                    {qualifierNameArray && qualifierNameArray
                                                        .map(item => {
                                                            return (
                                                                <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={selected.indexOf(item) > -1} />
                                                                    <ListItemText primary={item.replace(/_/g, ' ')} />
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    <div className={classes.dropdownbottom}>
                                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                            Cancel
                                                        </Button>

                                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                            Apply
                                                        </Button>
                                                    </div>


                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formDiv} required>
                                                Driver Name
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple
                                                    value={priceTypeName}
                                                    style={{ height: "37px", width: '100%', marginTop: 9, textTransform: 'capitalize' }}
                                                    input={<OutlinedInput />}
                                                    onOpen={() => handleOpen2()}
                                                    onClose={() => setOpen2(false)}
                                                    open={open2}
                                                    renderValue={(appType) => {
                                                        var priceType = []
                                                        appType
                                                            .map(item => {
                                                                priceType.push(item.replace(/_/g, ' '))

                                                            })
                                                        return priceType.join(", ")
                                                    }}
                                                    className={classes.multiSelect}

                                                >
                                                    <MenuItem value='all' onClick={(event) => handleSelectValues2(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={isAllSelected2} />
                                                        <ListItemText primary='Select All' />
                                                    </MenuItem>
                                                    {priceTypeNameArray && priceTypeNameArray
                                                        .map(item => {
                                                            return (
                                                                <MenuItem onClick={(event) => handleSelectValues2(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={selected2.indexOf(item) > -1} />
                                                                    <ListItemText primary={item.replace(/_/g, ' ')} />
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    <div className={classes.dropdownbottom}>
                                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel2}>
                                                            Cancel
                                                        </Button>

                                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd2} >
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formlabel}>
                                                External Mapping Rule
                                            </FormLabel>
                                            <OutlinedInput
                                                value={externalMappingRule}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setExternalMappingRule(e.target.value)} />
                                        </div>
                                    </Grid>
                                </Grid>

                            </div>
                        </form>
                    </Card>

                    <div className={classes.buttonRoot}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                            disabled={qualifierName.length > 0 && priceTypeName ? false : true}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Submit'
                            }
                        </Button>
                    </div>

                </div >
            </div >
        </LoadingOverlay >
    );

};
const mapStateToProps = state => {
    return {
        loadingAPI: state.profitOptimizationData.loading,
        qualifierNameList: state.profitOptimizationData.priceQualifierNameList,
        priceTypeNameList: state.profitOptimizationData.priceTypeNameList,
        approvalGroup: state.addMultipleConfigurationData.approvalGroupData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createAnalysisLevel(data)),
        getFieldValues: (key) => dispatch(getAnalysisLevelValues(key)),
        getApprovalGroup: () => dispatch(getApprovalGroupData())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAnalysisLevel);