import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Breadcrumbs,
    Grid,
    FormLabel,
    OutlinedInput,
    Button,
    Card

} from '@mui/material';
import { connect } from 'react-redux';
import { createTerritory, getSingleTerritoryData } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import SortableTree, { removeNodeAtPath, getFlatDataFromTree, changeNodeAtPath, addNodeUnderParent, toggleExpandedForAll } from 'react-sortable-tree';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        padding: 20
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    btn: {
        marginTop: '-4rem',
        marginRight: 25,
        width: 140
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: 28,
        paddingTop: 30,
        width: '100%'
    },

    nodeContainer: {
        padding: 20,
        marginTop: 30,
        width: '100%',
        overflowY: 'scroll ',
        flex: 1,
        height: '90vh',
        marginLeft: '-30px'


    },
    treeFont: {
        font: '13px',
        color: '#0D1333',
        padding: '10px',
        //height: 35,
        width: window.screen.width * .55,
    },
}));

const ViewTerritory = props => {
    useEffect(() => {

        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditableId(appId);
        if (appId)
            props.getSingleTerritoryData(appId);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [treeData, setTreeData] = React.useState([]);
    const [territoryName, setTerritoryName] = React.useState('');
    const [territoryID, setTerritoryID] = React.useState('');
    const [territoryType, setTerritoryType] = React.useState(sessionStorage.getItem('territory-type'))
    const [editableId, setEditableId] = React.useState(0);
    const [valid, setValid] = React.useState(false);

    useEffect(() => {
        var data = []
        data.push({ ...props.territoryData, 'expanded': true })
        setTreeData(data)
    }, [props.territoryData]);
    function recursiveData() {
        console.log(treeData)
        const valid = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey,
            ignoreCollapsed: false,
        }).filter(item => item.node.territory_name ? item.node.territory_name == '' : (!item.node.name || item.node.name === ''))
        if (valid.length > 0)
            setValid(true)
        else
            setValid(false)

    }
    const handleSubmit = () => {
        const flatData = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey,
            ignoreCollapsed: false,
        }).filter(item => item.node.name && item.node.name !== '').map((item) => (
            {
                hierarchy_level: item.treeIndex + 1,
                territory_name: item.node.territory_name ? item.node.territory_name : item.node.name,
                parent_territory_name: item.parentNode && item.parentNode.territory_name ? item.parentNode.territory_name : '',
                parent_territory_id: item.parentNode && item.parentNode.territory_id ? item.parentNode.territory_id : '',
                territory_id: item.node.territory_id ? item.node.territory_id : item.node.territoryID,
                territory_type: territoryType,
                territory_details: item.node.detail
            }
        ));

        props.createTerritory(flatData, editableId, 'edit');
    }

    function handleClearAll() {
        setTerritoryID('')
        setTerritoryName('')
    }
    function handleOnSubmit() {
        var data = {
            "territory_name": territoryName,
            "territory_id": territoryID,
            "territory_type": territoryType
        }
        props.createTerritory(data, editableId, 'edit');
    }
    const handleTreeData = (data) => {
        setTreeData(data)
        recursiveData()
    }

    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/terittory'
                >
                    Territory
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Territory</Typography>
            </Breadcrumbs>
            <Grid container>
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ height: '100vh' }}
                >
                    {treeData ?
                        <div style={{ height: '100%', position: 'relative' }}>
                            <Button variant="contained" color="primary" style={{ float: 'right' }} className={classes.btn} disabled={valid ? true : false}
                                onClick={handleSubmit}>
                                Save
                            </Button>
                            <div className={classes.nodeContainer}>
                                <SortableTree
                                    treeData={treeData}
                                    style={{ marginTop: 5, align: 'center' }}
                                    onChange={treeData => handleTreeData(treeData)}

                                    isVirtualized={false}
                                    generateNodeProps={({ node, path }) => ({
                                        title: (
                                            <div className={classes.treeFont}>
                                                <input
                                                    style={{ fontSize: '1.1rem', marginRight: 10 }}
                                                    value={node.territory_name}
                                                    placeholder={'Territory Name'}
                                                    onChange={event => {
                                                        const name = event.target.value;
                                                        handleTreeData(changeNodeAtPath({
                                                            treeData: treeData,
                                                            path,
                                                            getNodeKey,
                                                            newNode: { ...node, name },
                                                        }))
                                                    }}
                                                />
                                                <input
                                                    style={{ fontSize: '1.1rem', marginRight: 10, width: 150 }}
                                                    value={node.territory_id}
                                                    placeholder={'Territory ID'}
                                                    onChange={event => {
                                                        const territoryID = event.target.value;
                                                        handleTreeData(changeNodeAtPath({
                                                            treeData: treeData,
                                                            path,
                                                            getNodeKey,
                                                            newNode: { ...node, territoryID },
                                                        }))
                                                    }}
                                                />
                                                <input
                                                    style={{ fontSize: '1.1rem', width: '320px' }}
                                                    value={node.territory_details}
                                                    placeholder={'Territory Details'}
                                                    onChange={event => {
                                                        const detail = event.target.value;
                                                        handleTreeData(changeNodeAtPath({
                                                            treeData: treeData,
                                                            path,
                                                            getNodeKey,
                                                            newNode: { ...node, detail },
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        ),

                                        buttons: [
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="span"
                                                onClick={() =>
                                                    handleTreeData(addNodeUnderParent({
                                                        treeData: treeData,
                                                        parentKey: path[path.length - 1],
                                                        expandParent: true,
                                                        getNodeKey,
                                                        newNode: {
                                                            title: '',
                                                        },
                                                    }).treeData)
                                                }
                                                className={classes.buttonAdd}
                                                classes={{
                                                    root: classes.button
                                                }}
                                                size="large">
                                                <AddCircleOutlineIcon />
                                            </IconButton>,
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="span"
                                                onClick={() =>
                                                    handleTreeData(removeNodeAtPath({
                                                        treeData: treeData,
                                                        path,
                                                        getNodeKey,
                                                    }))
                                                }
                                                className={classes.buttonDelete}
                                                classes={{
                                                    root: classes.button
                                                }}
                                                size="large">
                                                <RemoveCircleOutlineIcon />
                                            </IconButton>,
                                        ],
                                    })}
                                />
                            </div>
                        </div>
                        :
                        <Typography style={{ marginTop: 20 }} variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </Grid>
            </Grid>
            {/*<div className={classes.bodyContainer}
                style={{ paddingTop: 20, display: territoryType == 'Linear' ? 'block' : 'none' }}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Territory Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={territoryName}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setTerritoryName(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Territory ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={territoryID}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setTerritoryID(e.target.value)} />
                                    </div>
                                </Grid>
                                <div className={classes.buttonRoot}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={handleClearAll} >
                                        Clear
                                    </Button>


                                    <Button variant="contained" color="primary" className={classes.btn} onClick={handleOnSubmit}

                                        disabled={territoryName ? false : true}>
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </div>
                    </form>
                </Card>

                </div>*/}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        territoryData: state.customerData.territorySingleData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSingleTerritoryData: (id) => dispatch(getSingleTerritoryData(id)),
        createTerritory: (data, id) => dispatch(createTerritory(data, id, 'edit'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTerritory);
