import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,

} from '@mui/material';
import React, { useEffect } from 'react';
import clsx from 'clsx';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import { makeStyles } from '@mui/styles';
import { createProductFeature } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 3,
        width: "100%",
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 6,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
    },
    inputTwoLine: {
        // marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 28,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    }
}));

const AddProductFeature = props => {
    const classes = useStyles();

    const [productFeature, setProductFeature] = React.useState('');
    const [productValues, setProductValues] = React.useState([]);

    function handleClearAll() {
        setProductFeature('')
        setProductValues([])
    }
    function handleOnSubmit() {
        var data = [
            {
                "product_feature": productFeature,
                "product_feature_values": productValues,
            }
        ]
        props.onSubmit(data);
    }
    function handleProduct(newValue) {
        setProductValues(newValue)
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/product-feature'
                >
                    Product feature
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Product Feature</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formDiv} required>
                                        Product Feature
                                    </FormLabel>
                                    <OutlinedInput
                                        value={productFeature}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setProductFeature(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >

                                    {/* <div className={classes.selectRoot}> */}
                                    <FormLabel className={classes.formDiv}>
                                        Product Feature Values
                                    </FormLabel>
                                    <ChipInput data={productValues} onChange={handleProduct} />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        //value={tags}
                                        onChange={(e) => setProductValues(e)}
                                        disableUnderline={true}
                                    /> */}
                                    {/* </div> */}

                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>


                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={productValues && productFeature ? false : true}  >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createProductFeature(data)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProductFeature);