import React, { useEffect } from 'react';
import Chart from "react-apexcharts";
import clsx from 'clsx';
import {

    TablePagination, Grid, Select, FormLabel, MenuItem, Button, OutlinedInput,
    Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper,
    Divider, CssBaseline, Toolbar, IconButton, Drawer, AppBar, Input,
} from '@mui/material';
import { makeStyles, useTheme } from "@mui/styles";
import { getDefaultValuesKeyFigure, marketShareNew, allowedApps, getAttributeListData } from '../../redux/actions';
import { connect } from 'react-redux';
import { ChevronLeft, ChevronRight, BarChart, List, Menu } from '@mui/icons-material'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { indexFinder, ColumnsDirective, ColumnDirective, AccumulationChartComponent, AccumulationLegend, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, getElement, Inject, AccumulationDataLabel, AccumulationTooltip, PieSeries, AccumulationAnnotation, } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { ColumnDirective as ColumnDirectiveF, ColumnsDirective as ColumnsDirectiveF, GridComponent } from '@syncfusion/ej2-react-grids';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        fontSize: theme.typography.h2.fontSize
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        position: 'absolute',
        flexShrink: 0,
        zIndex: 998
    },
    drawerPaper: {
        marginTop: 65
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        fontSize: theme.typography.h2.fontSize,
        paddingLeft: 10
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    select: {
        width: '100%'
    },
    selectedItem: {
        textTransform: 'capitalize'
    },
    multiSelect: {
        textTransform: 'capitalize',
        width: '100%'
    },
    dropdownColumn: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 28,
        paddingBottom: 30,
        marginTop: '-0.5rem'
    }
}));

const MarketShare = (props) => {
    const classes = useStyles();
    let grid;
    var pointIndex;
    const [sfData, setSfData] = React.useState([]);
    useEffect(() => {
        props.getAttributeListData({
            attribute_name: ''
        })
    }, []);

    const dlChartTemplate = (args) => {
        return (<div id="templateWrap">
            <div>{
                (args && args.point && args.point.index && props.marketShare &&
                    props.marketShare[args.point.index]
                    && props.marketShare[args.point.index].quantity_percentage ?
                    <div>{parseFloat(props.marketShare[args.point.index].quantity_percentage).toFixed(2)}</div>
                    : 0
                )
            }</div>
        </div>);
    }
    let dataLabel = {
        visible: true, position: 'Inside', enableRotation: false, connectorStyle: { type: 'Curve', length: '5%' }, font: { fontWeight: '600', color: 'white' }
    };
    let title = 'Market Share';
    function chartTemplate(args) {
        return (
            <div>Material Number : {args.argsData && args.argsData.point && args.argsData.point.index && props.marketShare &&
                props.marketShare[args.argsData.point.index]
                && props.marketShare[args.argsData.point.index].material_number ?
                props.marketShare[args.argsData.point.index].material_number
                : 0}</div>
        );
    }

    let pie;
    let isparent = true;
    function onTextRender(args) {
        args.text = args.point.x.replace(/_/g, ' ');
    }
    function onChartMouseClick(args) {
        let index = indexFinder(args.target);
        if (isparent && document.getElementById('pie-chart_Series_' + index.series + '_Point_' + index.point)) {
            isparent = false;
            console.log(sfData[index.point].z)
            pie.series[0].dataSource = sfData[index.point].z;
            pie.title = 'Value Share Vs Market Share';
            document.getElementById('text').innerHTML = 'Value Share Vs Market Share';
            pie.annotations = [{
                content: '<div id="back" style="cursor:pointer; padding: 3px; width: 30px; height: 30px;">', region: 'Series', x: '50%', y: '50%'
            }];
            pie.series[0].innerRadius = '30%';
            pie.series[0].radius = Browser.isDevice ? '90%' : '80%';
            pie.series[0].explode = false;
            pie.series[0].dataLabel.connectorStyle.length = '20px';
            pie.series[0].dataLabel.position = Browser.isDevice ? 'Inside' : 'Outside';
            pie.series[0].dataLabel.position = Browser.isDevice ? 'Inside' : 'Outside';
            pie.series[0].dataLabel.enableRotation = true;
            pie.series[0].dataLabel.font.color = '';
            pie.legendSettings.visible = false;
            pie.visibleSeries[0].explodeIndex = null;
            pie.enableSmartLabels = true;
            pie.refresh();
            document.getElementById('category').style.visibility = 'visible';
            document.getElementById('symbol').style.visibility = 'visible';
            document.getElementById('text').style.visibility = 'visible';
        }
        if (args.target.indexOf('back') > -1) {
            hide(document.getElementById(args.target));
        }
    }

    function onClick(e) {
        hide(e.target);
    }
    function hide(target) {
        pie.series[0].dataSource = sfData;
        pie.series[0].innerRadius = '0%';
        isparent = true;
        pie.series[0].explode = false;
        pie.annotations = [];
        pie.annotationModule['annotations'] = [];
        pie.series[0].dataLabel = dataLabel;
        pie.title = title;
        pie.legendSettings.visible = false;
        pie.enableSmartLabels = false;
        pie.refresh();
        target.style.visibility = 'hidden';
        document.getElementById('category').style.visibility = 'hidden';
        document.getElementById('symbol').style.visibility = 'hidden';
        document.getElementById('text').style.visibility = 'hidden';
    }
    function onChartLoad(args) {
        document.getElementById('pie-chart').setAttribute('title', '');
    }
    ;
    function load(args) {
        let selectedTheme = window.location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.accumulation.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).
            replace(/-dark/i, "Dark");
        if (selectedTheme === 'highcontrast' || selectedTheme.indexOf('dark') > -1) {
            args.accumulation.series[0].dataLabel.font.color = "white";
            if (args.accumulation.annotations[0] && !isparent) {
                args.accumulation.annotations[0].content = '<div id= "white" style="cursor:pointer;padding:3px;width:30px; height:30px;"><img src="./src/chart/images/white.png" id="back" /><div>';
            }
        }
    }


    function onPointClick(args) {
        if (getElement(pie.element.id + '_Series_' + args.seriesIndex + '_Point_' + args.pointIndex)) {
            this.pie.series[0].dataSource = sfData[args.pointIndex].z;
            this.pie.title = sfData[args.pointIndex].z[0].title;
            pointIndex = args.pointIndex;
            this.pie.series[0].name = 'Level 2';
            this.pie.series[0].innerRadius = '30%';
            this.pie.annotations = [
                {
                    content: '<div id="back" style="cursor:pointer;padding:3px;width:30px; height:30px;">' +
                        '<img src="https://ej2.syncfusion.com/javascript/demos/src/chart/images/back.png" id="back" />',
                    region: 'Series',
                    x: '50%',
                    y: '50%',
                },
            ];
            // this.pie.pointClick = click;
        }
        this.grid.dataSource = pie.series[0].dataSource;
        this.grid.columns[0].headerText = sfData[args.pointIndex].x;
        this.grid.refresh();
        this.pie.refresh();
    }

    function onClick(e) {
        hide(e.target);
    }
    function hide(target) {
        pie.series[0].dataSource = sfData;
        pie.series[0].innerRadius = '0%';
        isparent = true;
        pie.series[0].explode = false;
        pie.annotations = [];
        pie.annotationModule['annotations'] = [];
        pie.series[0].dataLabel = dataLabel;
        pie.title = title;
        pie.legendSettings.visible = false;
        pie.enableSmartLabels = false;
        pie.refresh();
        target.style.visibility = 'hidden';
        document.getElementById('category').style.visibility = 'hidden';
        document.getElementById('symbol').style.visibility = 'hidden';
        document.getElementById('text').style.visibility = 'hidden';
    }
    function onChartLoad(args) {
        document.getElementById('pie-chart').setAttribute('title', '');
    }
    ;
    function load(args) {
        let selectedTheme = window.location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.accumulation.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).
            replace(/-dark/i, "Dark");
        if (selectedTheme === 'highcontrast' || selectedTheme.indexOf('dark') > -1) {
            args.accumulation.series[0].dataLabel.font.color = "white";
            if (args.accumulation.annotations[0] && !isparent) {
                args.accumulation.annotations[0].content = '<div id= "white" style="cursor:pointer;padding:3px;width:30px; height:30px;"><img src="./src/chart/images/white.png" id="back" /><div>';
            }
        }
    }
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [keyFigureData, setKeyFigureData] = React.useState([]);
    const [chartOptionsPP, setChartOptionsPP] = React.useState({});
    const [chartSeriesPP, setChartSeriesPP] = React.useState([]);
    const [marketShare, setMarketShare] = React.useState([]);
    useEffect(() => {
        setSunBurstData({});
        props.allowedApps();
    }, [])
    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    useEffect(() => {
        props.onLoadingDefaultCR('Customer Rebate', 'Direct - Sales Data');
    }, []);
    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            if (props.dropdownDataCR.records[0].field_id === 'key_figure_name') {
                setKeyFigureData(props.dropdownDataCR.records[0].drop_down_value_keys);
            }
        }
    }, [props.dropdownDataCR])
    const [mappingValuesSetKF, setMappingValuesSetKF] = React.useState({});

    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            var commonKeys = props.dropdownDataCR.records[0].drop_down_value_keys;
            var keys = props.dropdownDataCR.records[0].drop_down_values;
            var myobj = {};
            keys.map(function (obj, index) {
                myobj[commonKeys[index]] = obj;
            });
            setMappingValuesSetKF(myobj);
        }

    }, [keyFigureData]);
    const [loadGraph, setLoadGraph] = React.useState(false);

    const handleSubmit = () => {
        setLoadGraph(true);
        setToggleValue('value_share');
        Object.entries(attributeObjectAPI).map(([key, value]) => {
            if (attributeName.includes(key)) {

            } else {
                delete attributeObjectAPI[key]
            }
        })
        var formData = {
            "start_date": Moment(startDate).format('MM/DD/YYYY'),
            "end_date": Moment(endDate).format('MM/DD/YYYY'),
            "periodicity": periodicity,
            "attribute_name": attributeName
        }
        props.onSubmit(formData, filter);
        setOpen(false);
    }

    const SAMPLE_CSS = `
    #category:hover {
        cursor: pointer;
    }`;
    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    const [competitor, setCompetitor] = React.useState([]);
    const [periodicity, setPeriodicity] = React.useState('');
    const [sunBurstData, setSunBurstData] = React.useState({})
    const attributeNames = ['customer_classification', 'customer_group', 'customer_industry', 'class_of_trade', 'material_group', 'material_number', 'product_hierarchy', 'region', 'country']
    useEffect(() => {
        var labelsArray = [];
        var filterArray = [];
        props.marketShare && props.marketShare.length > 0 && props.marketShare.map(item => {
            if (!filterArray.includes(item[attributeName])) {
                filterArray.push(item[attributeName]);
            }
        })
        setMarketShare([]);
        if (periodicity) {
            if (props.marketShare && props.marketShare.length > 0) {
                setMarketShare(props.marketShare);

                props.marketShare.map(item => {
                    if (!labelsArray.includes(item['company_name'])) {
                        labelsArray.push(item['company_name']);
                    }
                })
                var finalArr = []
                labelsArray.map(carName => {
                    finalArr.push({
                        'name': carName,
                        "data": filterArray.map(x => {
                            return toggleValueReturn(carName, x, toggleValue)
                        })
                    })
                })
                setChartSeriesPP(finalArr)
                setChartOptionsPP(
                    {
                        chart: {
                            type: 'bar',
                            height: 'auto',
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded'
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        },
                        xaxis: {
                            categories: filterArray,
                        },
                        animations: {
                            enabled: true,
                            easing: 'easeinout',
                            speed: 800,
                            animateGradually: {
                                enabled: true,
                                delay: 150
                            },
                            dynamicAnimation: {
                                enabled: true,
                                speed: 350
                            }
                        },
                        fill: {
                            opacity: 1
                        }
                    }
                )
            } else {
                setMarketShare([]);
                setChartSeriesPP([]);
                setChartOptionsPP({});
            }
        }
        else {
            if (props.marketShare && Object.keys(props.marketShare).length > 0) {
                var final = [];
                var tempArr = [];
                var competitorTmp = [];
                var lvl1APIData = props.marketShare[Object.keys(props.marketShare)[0]];
                var lvl1AP2Data = props.marketShare[Object.keys(props.marketShare)[1]];
                //level1
                setMarketShare(props.marketShare[Object.keys(props.marketShare)[0]]);
                props.marketShare[Object.keys(props.marketShare)[0]]
                    // .slice(0, 10)
                    .map(e => {
                        tempArr.push(
                            {
                                x: e.competitor_name, y: e.total_quantity ? parseFloat(e.total_quantity).toFixed(2) : 0,
                                // y: e.total_quantity,

                                z: [
                                    { x: 'Quantity Share', y: e.quantity_percentage },
                                    { x: 'Value Share', y: e.total_quantity }
                                ],
                            }
                        )
                    })



                // props.marketShare[Object.keys(props.marketShare)[0]]
                //     .slice(0, 10)
                //     .map(e => {
                //         tempArr.push(
                //             {
                //                 x: attributeName[0], y: e.quantity_percentage ? parseFloat(e.quantity_percentage).toFixed(2) : 0, y: e.total_quantity,
                //                 z: [
                //                     { x: 'Quantity Share', y: e.quantity_percentage },
                //                     { x: 'Value Share', y: e.total_quantity }
                //                 ],
                //             }
                //         )
                //     })







                // var tempArr = [];
                // //level 1
                // var namesLvl1 = [];
                // lvl1APIData.map(item => {
                //     namesLvl1.push(item.quantity_percentage ? parseFloat(item.quantity_percentage).toFixed(2) : 0)
                // })
                // if (attributeName.length === 1 ?
                //     props.marketShare[Object.keys(props.marketShare)[0]]
                //         .slice(0, 10)
                //         .map(e => {
                //             tempArr.push(
                //                 {
                //                     x: attributeName[0], y: e.quantity_percentage ? parseFloat(e.quantity_percentage).toFixed(2) : 0, y: e.total_quantity,
                //                     z: [
                //                         { x: 'Quantity Share', y: e.quantity_percentage },
                //                         { x: 'Value Share', y: e.total_quantity }
                //                     ],
                //                 }
                //             )
                //         })
                //     :
                //     props.marketShare[Object.keys(props.marketShare)[1]]
                //         .slice(0, 10)
                //         .map(e => {
                //             tempArr.push(
                //                 {
                //                     x: attributeName[1], y: e.quantity_percentage ? parseFloat(e.quantity_percentage).toFixed(2) : 0, y: e.total_quantity,
                //                     z: [
                //                         { x: 'Quantity Share', y: e.quantity_percentage },
                //                         { x: 'Value Share', y: e.total_quantity }
                //                     ],
                //                 }
                //             )
                //         })
                // )


                //     var finalArr =
                setSfData(tempArr);
                // console.log(tempArr)
            } else {
                setMarketShare([]);
                setChartSeriesPP([]);
                setChartOptionsPP({});
            }
        }
    }, [props.marketShare])

    function toggleValueReturn(carName, carType, toggleVal) {
        var val = 0;
        props.marketShare.map(function (el) {
            if (el.company_name === carName &&
                el[attributeName] === carType) {
                val = el[toggleVal]
            }
        });
        return val
    }
    useEffect(() => {
        props.onSubmit('reset_store');
    }, []);
    const handlePeriodicity = (e) => {
        setPeriodicity(e.target.value);
        setMarketShare([]);
    }
    const handleStartDate = (e) => {
        setStartDate(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }
    const [toggleValue, setToggleValue] = React.useState('value_share');
    const [attributeData, setAttributeData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [attributeName, setAttributeName] = React.useState([]);
    const [filter, setFilter] = React.useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleToggleButton = (event, newVal) => {
        setToggleValue(newVal);

        var filterArray = [];
        props.marketShare.map(item => {
            if (!filterArray.includes(item[attributeName])) {
                filterArray.push(item[attributeName]);
            }
        })
        var labelsArray = [];
        props.marketShare.map(item => {
            if (!labelsArray.includes(item['company_name'])) {
                labelsArray.push(item['company_name']);
            }
        })
        var finalArr = []
        labelsArray.map(carName => {
            finalArr.push({
                'name': carName,
                "data": filterArray.map(x => {
                    return toggleValueReturn(carName, x, newVal)
                })
            })
        })
        setChartSeriesPP(finalArr)
        setChartOptionsPP(
            {
                chart: {
                    type: 'bar',
                    height: 'auto',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: filterArray,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
                fill: {
                    opacity: 1
                }
            }
        )
    }
    const [resultType, setResultType] = React.useState('chart');
    const handleResultType = (event, nextView) => {
        setResultType(nextView);
    };
    const handleAttributeName = (e) => {

        if (e.target.value.length <= 2) {
            setAttributeName(e.target.value);
        }
        setMarketShare([]);


    }
    const handleFilter = (val, arg, index) => {

        var final = filter
        final[arg] = val.target.value;
        setFilter(final)
        console.log("hj", final)
    }

    useEffect(() => {
        setAttributeData(props.attributeData)
    }, [props.attributeData]);

    const datalabel = { visible: true, name: 'text', position: 'Outside' };

    const dropdownPopulator = attributeName.map((arg, index) => {
        var newArray = [];
        var inx = index;
        if (props.attributeData && props.attributeData.length > 0) {
            props.attributeData
                .filter(item => item.attribute_name === arg)
                .map((item) => {
                    newArray.push(item.attribute_value)
                })
        }
        return (
            <Grid
                item
                md={2}
                xs={12}
                style={{ paddingRight: 15 }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                    <FormLabel required style={{ color: 'black', fontSize: 15, marginBottom: 5, textTransform: 'capitalize' }}>
                        {arg.replace(/_/g, ' ')}
                    </FormLabel>
                    <div style={{ width: '100%', marginRight: 15 }}>
                        <Select
                            value={filter[arg]}
                            onChange={(val) => handleFilter(val, arg, index)}
                            displayEmpty
                            className={clsx({
                                [classes.select]: true
                            })}
                            limitTags={2}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                        >
                            {newArray.map(item => {
                                return (
                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                        {item.replace(/_/g, ' ')}
                                    </MenuItem>
                                );
                            })
                            }

                        </Select>
                    </div>
                </div>
            </Grid>
        );
    });


    return (
        <div className={classes.root}>

            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar style={{ position: 'absolute', marginTop: 65, display: open && 'none' }}>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                        size="large">
                        <Menu />
                    </IconButton>
                    <Typography className={{ root: classes.header }} variant="h1" color='primary' style={{ marginLeft: '1rem' }}> Market Share </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.drawerHeader} />
            {periodicity ?
                <div >

                    {loadGraph === true && marketShare && marketShare.length > 0 ?
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 20 }}>
                                <ToggleButtonGroup
                                    value={toggleValue}
                                    exclusive
                                    size={'small'}
                                    onChange={handleToggleButton}
                                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
                                >
                                    <ToggleButton value="value_share" aria-label="left aligned">
                                        Value Share
                                    </ToggleButton>
                                    <ToggleButton value="quantity_share" aria-label="centered">
                                        Quantity Share
                                    </ToggleButton>
                                </ToggleButtonGroup>

                                <ToggleButtonGroup value={resultType} exclusive onChange={handleResultType}
                                    style={{ display: 'flex', justifyContent: 'left', flexDirection: 'row' }}
                                    size={'small'}>
                                    <ToggleButton value="chart" aria-label="list">
                                        <BarChart />
                                    </ToggleButton>
                                    <ToggleButton value="list" aria-label="module">
                                        <List />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                            {resultType === 'chart' ?
                                <Chart options={chartOptionsPP} type="bar" series={chartSeriesPP} />
                                :
                                <div>
                                    {marketShare && marketShare.length > 0 &&
                                        <Paper>
                                            <TableContainer style={{ marginTop: 10 }}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Company Name</TableCell>
                                                            <TableCell align="right" style={{ textTransform: 'capitalize' }}>{attributeName.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell align="right">Periodicity</TableCell>
                                                            <TableCell align="right">Quantity Share</TableCell>
                                                            <TableCell align="right">Value Share</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {props.marketShare && props.marketShare.length > 0 && props.marketShare.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">
                                                                        {row.company_name}
                                                                    </TableCell>
                                                                    <TableCell align="right">{row[attributeName]}</TableCell>
                                                                    <TableCell align="right">{row.periodicity}</TableCell>
                                                                    <TableCell align="right">{row.quantity_share}</TableCell>
                                                                    <TableCell align="right">{row.value_share}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component="div"
                                                count={props.marketShare.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    }
                                </div>
                            }


                        </div>
                        :
                        <Typography variant='h4' style={{ marginTop: "5rem" }}>
                            There is no data to show now.
                        </Typography>}
                </div>
                :
                <div>
                    {loadGraph && sfData.length > 0 ?
                        <div className='control-pane'>
                            <style>
                                {SAMPLE_CSS}
                            </style>
                            <div className='control-section'>
                                <div id="link">
                                    <a id="category" onClick={onClick.bind(this)} style={{ visibility: 'hidden', display: 'inline-block' }}>
                                        Market Share - Quantity Percentage
                                    </a>
                                    <p style={{ visibility: 'hidden', display: 'inline-block' }} id="symbol">&nbsp;&gt;&gt;&nbsp;</p>
                                    <p id="text" style={{ display: 'inline-block' }}></p>
                                </div>

                                {/* <AccumulationChartComponent enableSmartLabels='true' id='pie-chart'
                                        ref={grid}
                                        pointClick={onPointClick}
                                        legendSettings={{ visible: false }} tooltip={{ enable: false, format: '${point.x} <br> ${point.y} %' }} 
                                        chartMouseClick={onChartMouseClick.bind(this)} textRender={onTextRender.bind(this)} load={load.bind(this)} loaded={onChartLoad.bind(this)}
                                    >
                                        <Inject services={[AccumulationDataLabel, AccumulationLegend, AccumulationTooltip, PieSeries, AccumulationAnnotation]} />
                                        <AccumulationSeriesCollectionDirective>
                                            <AccumulationSeriesDirective dataSource={sfData} xName='x' yName='y' dataLabel={datalabel}></AccumulationSeriesDirective>
                                        </AccumulationSeriesCollectionDirective>
                                    </AccumulationChartComponent>; */}

                                <AccumulationChartComponent
                                    id='pie-chart' ref={pie}
                                    title='Market Share' enableSmartLabels={true}
                                    tooltip={{ enable: true, format: 'Total Quantity :' + '${point.y}' }}
                                    legendSettings={{ visible: true, title: 'Competitor Name' }}
                                    chartMouseClick={onChartMouseClick.bind(this)} textRender={onTextRender.bind(this)} load={load.bind(this)} loaded={onChartLoad.bind(this)}
                                >
                                    <Inject services={[AccumulationDataLabel, AccumulationLegend, AccumulationTooltip, PieSeries, AccumulationAnnotation]} />
                                    <AccumulationSeriesCollectionDirective >
                                        <AccumulationSeriesDirective
                                            startAngle='0' endAngle='360' explode={true} explodeOffset='10%' explodeIndex={3} name='Project'
                                            legendShape='Rectangle' dataSource={sfData} xName='x' yName='y'
                                            dataLabel={{
                                                visible: true,
                                                position: 'Outside'
                                            }}
                                        >
                                        </AccumulationSeriesDirective>
                                    </AccumulationSeriesCollectionDirective>
                                </AccumulationChartComponent>
                                {/* {attributeName.length == 2 ?
                                        <ColumnsDirective>
                                            <ColumnDirective field='x' headerText={attributeName[0]} type='string'></ColumnDirective>
                                            <ColumnDirective field='y' headerText={attributeName[1]} type='string'></ColumnDirective>
                                        </ColumnsDirective>
                                        :
                                        <ColumnsDirective>
                                            <ColumnDirective field='x' headerText={attributeName[0]} type='string'></ColumnDirective>
                                        </ColumnsDirective>
                                    } */}
                                {/* <GridComponent ref={grid} dataSource={sfData} height='350' >
                                        <ColumnsDirective>
                                            <ColumnDirective field='x' headerText='Vehicle' type='string'></ColumnDirective>
                                            <ColumnDirective field='y' headerText='Sales' type='string'></ColumnDirective>
                                        </ColumnsDirective>
                                    </GridComponent> */}
                                {/* {attributeName.length == 2 ?
                                        <ColumnsDirective>
                                            <ColumnDirective field='x' headerText={attributeName[0]} type='string'></ColumnDirective>
                                            <ColumnDirective field='y' headerText={attributeName[1]} type='string'></ColumnDirective>
                                        </ColumnsDirective>
                                        :
                                        <ColumnsDirective>
                                            <ColumnDirective field='x' headerText={attributeName[0]} type='string'></ColumnDirective>
                                        </ColumnsDirective>
                                    } */}


                                {/* <AccumulationChartComponent id='pie-chart' ref={(scope) => { pie = scope; }}
                                        enableSmartLabels='true'
                                        title='Market Share'
                                        tooltip={{
                                            enable: true,
                                        }}
                                        chartMouseClick={onChartMouseClick.bind(this)} textRender={onTextRender.bind(this)} load={load.bind(this)} loaded={onChartLoad.bind(this)}
                                    >
                                        <Inject services={[AccumulationDataLabel, AccumulationLegend, AccumulationTooltip, PieSeries, AccumulationAnnotation]} />
                                        <AccumulationSeriesCollectionDirective >
                                            <AccumulationSeriesDirective startAngle='0' endAngle='360'
                                                legendShape='Rectangle' dataSource={sfData} xName='x' yName='y'
                                                dataLabel={{
                                                    visible: true,
                                                }}
                                                radius='100%'>
                                            </AccumulationSeriesDirective>
                                        </AccumulationSeriesCollectionDirective>
                                    </AccumulationChartComponent> */}
                                {/* {attributeName.length == 2 ?
                                        <ColumnsDirective>
                                            <ColumnDirective field='x' headerText={attributeName[0]} type='string'></ColumnDirective>
                                            <ColumnDirective field='y' headerText={attributeName[1]} type='string'></ColumnDirective>
                                        </ColumnsDirective>
                                        :
                                        <ColumnsDirective>
                                            <ColumnDirective field='x' headerText={attributeName[0]} type='string'></ColumnDirective>
                                        </ColumnsDirective>
                                    } */}
                            </div>
                        </div>
                        :
                        <Typography variant='h4' style={{ marginTop: "5rem" }}>
                            There is no data to show now.
                        </Typography>}


                </div>
            }

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="top"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <Typography variant="h3" color='primary'> Market Share </Typography>
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider
                    sx={{
                        opacity: "0.6"
                    }} />
                <Grid container className={classes.root} >
                    <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    Start Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 1,
                                                    color: '#1675e0',
                                                    // width: 269
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    End Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 1,
                                                    color: '#1675e0',
                                                    // width: 269
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    Analysis Level
                                </FormLabel>
                                <Grid container justifyContent="space-around">
                                    <Select
                                        id="attribute-name"
                                        multiple
                                        limit={2}
                                        value={attributeName}
                                        onChange={handleAttributeName}
                                        input={<OutlinedInput />}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        limitTags={2}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        renderValue={(appType) => {
                                            var qualifier = []
                                            appType
                                                .map(item => {
                                                    qualifier.push(item.replace(/_/g, ' '))
                                                })
                                            return qualifier.join(", ")
                                        }}
                                    >
                                        {attributeNames &&
                                            attributeNames.map(item => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item.replace(/_/g, ' ')}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }}>
                                    Periodicity
                                </FormLabel>
                                <Select
                                    value={periodicity}
                                    onChange={handlePeriodicity}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    displayEmpty
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={''} >
                                        Cumulative
                                    </MenuItem>
                                    <MenuItem value={'month'}>
                                        Month
                                    </MenuItem>
                                    <MenuItem value={'quarter'}>
                                        Quarter
                                    </MenuItem>

                                </Select>
                            </div>
                        </Grid>
                        {/* <Grid 
                        item
                        md={3}
                        xs={12}
                        > */}
                        {dropdownPopulator}
                        {/* </Grid> */}

                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmit}
                                twoline="true"
                                disabled={startDate && endDate && attributeName ? false : true}
                            >
                                <DirectionsRunIcon />
                            </Button>
                        </div>
                    </Grid>

                    {/* <div style={{ width: '100%', borderBottom: '1px solid #e5e5e5' }} />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                        <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit} disabled={startDate && endDate && attributeName ? false : true}  >
                            Apply
                        </Button>
                    </div> */}
                </Grid>
            </Drawer >
        </div >
    );
}


const mapStateToProps = state => {
    return {
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        allowedAppsData: state.initialData.allowedApps,
        marketShare: state.initialData.marketShare,
        attributeData: state.attributeListData.AttributeData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
        // onSubmit: (data) => dispatch(marketShareNew(data)),
        onSubmit: (data, filter) => dispatch(marketShareNew(data, filter)),
        allowedApps: () => dispatch(allowedApps()),
        getAttributeListData: (data) => dispatch(getAttributeListData(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MarketShare);