import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    TextField,
    OutlinedInput
} from '@mui/material';
import { Link } from 'react-router-dom';
import { getPromoFinanceCostDetails, updateFinanceCostData } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const FinanceCostEdit = props => {
    const classes = useStyles();
    const [financeName, setFinanceName] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [interestRate, setInterestRate] = React.useState('');
    const [finTermStructure, setFinTermStructure] = React.useState('');
    const [financeCost, setFinanceCost] = React.useState('');
    const [validFrom, setValidFrom] = React.useState(null);
    const [validTo, setValidTo] = React.useState(null);

    function handleFinanceName(newValue) {
        setFinanceName(newValue.target.value);
    }
    function handleCountry(newValue) {
        setCountry(newValue.target.value);
    }
    function handleInterestRate(newValue) {
        setInterestRate(newValue.target.value);
    }
    function handleFinTermStructure(newValue) {
        setFinTermStructure(newValue.target.value);
    }
    function handleFinanceCost(newValue) {
        setFinanceCost(newValue.target.value);
    }
    function handleValidFrom(newValue) {
        setValidFrom(newValue);
    }
    function handleValidTo(newValue) {
        setValidTo(newValue);
    }
    function handleOnClear() {
        setFinanceName('')
        setCountry('')
        setFinTermStructure('')
        setFinanceCost('')
        setInterestRate('')
        setValidFrom(null)
        setValidTo(null)
    }
    const handleSubmit = () => {
        var formData = {
            finance_name: financeName,
            country: country,
            interest_rate: Number(interestRate),
            finance_cost: Number(financeCost),
            fin_term_structure: finTermStructure,
            valid_from: validFrom,
            valid_to: validTo,

        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(formData, appId);
    };
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleFinanceCostData(appId);
    }, []);
    useEffect(() => {
        if (props.financeCostDataSingle) {
            if (props.financeCostDataSingle) {
                setFinanceCost(props.financeCostDataSingle.finance_cost);
                setFinanceName(props.financeCostDataSingle.finance_name);
                setInterestRate(props.financeCostDataSingle.interest_rate);
                setCountry(props.financeCostDataSingle.country);
                setFinTermStructure(props.financeCostDataSingle.fin_term_structure);
                setValidFrom(props.financeCostDataSingle.valid_from);
                setValidTo(props.financeCostDataSingle.valid_to);
            }
        }
    }, [props.financeCostDataSingle]);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/finance-cost'
                >
                    Finance Cost
                </Link>
                <Typography color="textPrimary" variant='h4'>Finance Cost Edit </Typography>
            </Breadcrumbs>
            {
                <div className={classes.bodyContainer}>
                    <Card
                        style={{ marginTop: 15 }}>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container
                                    style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "15px" }}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Finance Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={financeName}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFinanceName}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Country
                                        </FormLabel>
                                        <OutlinedInput
                                            value={country}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCountry} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Interest Rate
                                        </FormLabel>
                                        <OutlinedInput
                                            value={interestRate}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleInterestRate}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Finance Term Structure
                                        </FormLabel>
                                        <OutlinedInput
                                            value={finTermStructure}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFinTermStructure}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Finance Cost
                                        </FormLabel>
                                        <OutlinedInput
                                            value={financeCost}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFinanceCost}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Valid From
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 35,
                                                        }
                                                    }}
                                                    value={validFrom}
                                                    onChange={date => handleValidFrom(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Valid To
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 35,
                                                        }
                                                    }}
                                                    value={validTo}
                                                    onChange={date => handleValidTo(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.submitContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                            Clear
                        </Button>
                        <Button color="primary" variant="contained" className={classes.button} onClick={handleSubmit}
                            disabled={financeName && financeCost && finTermStructure && country && interestRate && validFrom && validTo ? false : true}>
                            Submit
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        financeCostDataSingle: state.promotionData.promoFinanceCostDetailsData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(updateFinanceCostData(data, id)),
        getSingleFinanceCostData: (id) => dispatch(getPromoFinanceCostDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FinanceCostEdit);