import React, { useEffect } from 'react';
import {
    Typography,Breadcrumbs,
    Table,TableBody,TableContainer, TableHead, TableRow, Paper, TableCell
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { getContractDetailsData } from '../../redux/actions';
import Moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        flex: 1
    }
}));


const ChangeRequestDetail = props => {
    const classes = useStyles();
    const [oldDataAPI, setOldDataAPI] = React.useState({});
    const [newDataAPI, setNewDataAPI] = React.useState({});
    const [changedObject, setChangedObject] = React.useState({});

    useEffect(() => {
        if (props.oldData && Object.keys(props.oldData).length != 0) {
            setOldDataAPI({ ...props.oldData })
        }
    }, [props.oldData])
    useEffect(() => {
        if (props.changedData && Object.keys(props.changedData).length != 0) {
            setNewDataAPI({ ...props.changedData })
        }
    }, [props.changedData])

    useEffect(() => {
        delete oldDataAPI['calculation_rules'];
        delete oldDataAPI['eligibility_rules'];
        delete oldDataAPI['target_rules'];
        delete oldDataAPI['chargeback_calculation_rules'];
        delete oldDataAPI['created_by'];
        delete oldDataAPI['updated_by'];
        delete oldDataAPI['notes'];
        delete oldDataAPI['owned_by'];
        delete oldDataAPI['search_tokens'];
        delete oldDataAPI['Prototype'];
        delete oldDataAPI['created_at'];
        delete oldDataAPI['updated_at'];
        delete oldDataAPI['id'];

        delete newDataAPI['calculation_rules'];
        delete newDataAPI['eligibility_rules'];
        delete newDataAPI['target_rules'];
        delete newDataAPI['chargeback_calculation_rules'];
        delete newDataAPI['created_by'];
        delete newDataAPI['updated_by'];
        delete newDataAPI['notes'];
        delete newDataAPI['owned_by'];
        delete newDataAPI['search_tokens'];
        delete newDataAPI['Prototype'];
        delete newDataAPI['created_at'];
        delete newDataAPI['updated_at'];
        delete newDataAPI['id'];

        Object.keys(oldDataAPI).forEach(key => {
            if (oldDataAPI[key] !== newDataAPI[key]) {
                setChangedObject(state => ({
                    ...state,
                    [key]: newDataAPI[key]
                }))
            }
        });
    }, [oldDataAPI || newDataAPI])
    const heading = ['ID', 'accrual_amount', 'payment_amount', 'application_type', 'Valid From', 'Valid To', 'contract_type', 'calculation_schema',
        'contract_group', 'contract_sub_group', 'contract_purpose', 'source_data_type', 'posting_schema', 'posting_approval',
        'internal_description', 'currency', 'accrual_calendar', 'accrual_gl_account_debit', 'accrual_gl_account_credit',
        'accrual_credit_profit_center', 'accrual_debit_profit_center'
    ]

    return (
        <div className={classes.root}>

            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to={'/contract-setup'}
                >
                    {'Contract Setup'}
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Compare Value</Typography>
            </Breadcrumbs>

            <div className={classes.container}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {heading.map(item => {
                                    return <>
                                        <TableCell align='center' style={{ textAlign: 'center', textTransform: 'capitalize' }}>{item.replace(/_/g, ' ')}</TableCell>
                                    </>;
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {JSON.parse(localStorage.getItem('ComparedContracts')).map((item, i) => {
                                return (
                                    <TableRow>
                                        <TableCell align='center'>{item.id}</TableCell>
                                        <TableCell align='center'>{item.accrual_amount}</TableCell>
                                        <TableCell align='center'>{item.payment_amount}</TableCell>
                                        <TableCell align='center'>{item.application_type}</TableCell>
                                        <TableCell align='center'>{Moment.utc(item.valid_from).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell align='center'>{Moment.utc(item.valid_to).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell align='center'>{item.contract_type}</TableCell>
                                        <TableCell align='center'>{item.calculation_schema}</TableCell>
                                        <TableCell align='center'>{item.contract_group}</TableCell>
                                        <TableCell align='center'>{item.contract_sub_group}</TableCell>
                                        <TableCell align='center'>{item.contract_purpose}</TableCell>
                                        <TableCell align='center'>{item.source_data_type}</TableCell>
                                        <TableCell align='center'>{item.posting_schema}</TableCell>
                                        <TableCell align='center'>{item.posting_approval}</TableCell>
                                        <TableCell align='center'>{item.internal_description}</TableCell>
                                        <TableCell align='center'>{item.currency}</TableCell>
                                        <TableCell align='center'>{item.accrual_calendar}</TableCell>
                                        <TableCell align='center'>{item.accrual_gl_account_debit}</TableCell>
                                        <TableCell align='center'>{item.accrual_gl_account_credit}</TableCell>
                                        <TableCell align='center'>{item.accrual_credit_profit_center}</TableCell>
                                        <TableCell align='center'>{item.accrual_debit_profit_center}</TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div >
    );

};

const mapStateToProps = state => {
    return {
        oldData: state.addContractData.contractGeneralData,
        changedData: state.addContractData.changedContractData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getcontractViewdetails: (id) => dispatch(getContractDetailsData(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestDetail);