import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Typography, useMediaQuery,
  TablePagination, Button, IconButton, Fab
} from "@mui/material";
import { Edit, DeleteForever, AddBoxOutlined, LibraryAdd } from "@mui/icons-material";
import MaterialTable from "material-table";
import { tableIcons } from "../../../components/Icons/TableIcons";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import {
  createPriceTypeConfig,
  getPriceTypeConfig,
} from "../../../redux/actions/Pricing/AuthAction";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '20px 16px',
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: 10,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1
  }
}));

const PriceTypeConfigMain = (props) => {
  useEffect(() => {
    props.getConfigData(1, 0);
  }, []);
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const [dataRows, setDataRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [configData, setConfigData] = React.useState([]);

  const priceTypeList = ['Base Price', 'On-Invoice Discount', 'On-Invoice Freight', 'On-Invoice Surcharge', 'Off-Invoice Revenue', 'Off-Invoice Expense', 'Cost - Direct', 'Cost - Indirect', "Cost - Extra", "Margin Target %", "Market Price",
    "Market Target %", "Transfer Price", "Optimization",]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.getConfigData(newPage + 1, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    props.getConfigData(1, event.target.value);
  };

  useEffect(() => {
    var tempRows = [];
    if (props.configData && props.configData.records) {
      setConfigData(props.configData.records);
      const sorted = props.configData.records.sort((a, b) => a.price_type_name > b.price_type_name ? 1 : -1).sort(function (a, b) {
        return priceTypeList.indexOf(a.price_type) - priceTypeList.indexOf(b.price_type);
      });
      sorted.map((e) => {
        tempRows.push({
          id: e.ID,
          price_type_name: e.price_type_name,
          price_type: e.price_type,
          calculation_type: e.calculation_type,
          scales: e.scales == true ? "Yes" : "No",
          external_price_type_name: e.external_price_type_name,
          price_type_category: e.price_type_category,
        });
      });
      setCount(props.configData.total_record);
      setDataRows(tempRows);
    }
  }, [props.configData]);

  function editConfig(id) {
    history.push({
      pathname: "/pricing-type-config/edit-type-config/" + id,
      id: id,
    });
  }

  const deleteConfig = (id) => {
    props.onDelete(id);
  };

  const columns = [
    {
      field: "price_type_name",
      title: "Price Type Name",
      editable: "never",
      type: "string",
    },
    {
      field: "price_type",
      title: "Price Type",
      type: "string",
      editable: "never",
    },
    {
      field: "price_type_category",
      title: "Price Type Category",
      type: "string",
      editable: "never",
    },
    {
      field: "calculation_type",
      title: "Calculation Type",
      type: "string",
      editable: "never",
    },
    {
      field: "scales",
      title: "Scales",
      type: "string",
      editable: "never",
    },
    {
      field: "external_price_type_name",
      title: "External Price Type Name",
      editable: "never",
      type: "string",
    },
    {
      field: "Actions",
      title: "Actions",
      type: "string",
      editable: "never",
      render: (rowData) => (
        <div>
          {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_type-' + sessionStorage.getItem('application')) &&
            <IconButton
              classes={{ root: classes.IconButton }}
              onClick={() => editConfig(rowData.id)}
              size="large">
              <Edit color="disabled" style={{ fontSize: 20 }} />
            </IconButton>}
          {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_type-' + sessionStorage.getItem('application')) &&
            <IconButton
              classes={{ root: classes.IconButton }}
              onClick={() => deleteConfig(rowData.id)}
              size="large">
              <DeleteForever color="disabled" style={{ fontSize: 20 }} />
            </IconButton>}
        </div>
      ),
    },
  ];

  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        text="Loading . Please wait ..."
        className={classes.spinner}
      >
        <div
          className={clsx({
            [classes.row]: isDesktop,
          })}
        >
          <Typography variant="h1" color="primary"> Price Types </Typography>
          {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_type-' + sessionStorage.getItem('application')) &&
            <Fab aria-label="edit" variant="extended"
              size='medium'
              classes={{ root: classes.fabContainer }}
              className={classes.fabContainer}
              // component={CustomRouterLink}
              onClick={() => history.push("/pricing-type-config/add-type-config")}
            >
              <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
            </Fab>}
        </div>

        {configData ? (
          <MaterialTable
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    height: "0px",
                  }}
                ></div>
              ),
            }}
            title={" "}
            editable={true}
            icons={tableIcons}
            columns={columns}
            data={dataRows}
            style={{ marginTop: '-18px' }}
            options={{
              maxBodyHeight: '100vh',
              search: false,
              filtering: true,
              paging: false,
              // pageSize: rowsPerPage,
              // count: count,
              // page: page,
              headerStyle: theme.mixins.MaterialHeader,
              cellStyle: theme.mixins.MaterialCell,
              // pageSize: 10,
              // pageSizeOptions: [10, 20, 50],
            }}
          />
        ) : (
          <Typography variant="h4">There is no data to show now.</Typography>
        )}
      </LoadingOverlay>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    configData: state.pricingData.priceTypeData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfigData: (pagination, limit) => dispatch(getPriceTypeConfig(pagination, limit)),
    onDelete: (id) => dispatch(createPriceTypeConfig(null, id, "delete")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceTypeConfigMain);
