import React, { useEffect, forwardRef } from 'react';
import {
    Grid, Typography, Card, Table, TableCell, TableHead, TableRow,
    TableContainer, TableBody, CardHeader,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import {
    getAttributeListData, getDefaultValues,
    getDefaultValuesKeyFigure
} from '../../../../redux/actions';
import Moment from 'moment';
import momentt from 'moment-timezone';

var deleteFlag = false;
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    uploadIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        height: 36,
        display: 'flex'
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        marginTop: 50
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
    },
    caption: {
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    formDiv: {
        height: 41,
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    borderRad0: {
        borderRadius: '0px !important'
    },
    conditionID: {
        display: (sessionStorage.getItem('application') === 'Customer Chargeback' ||
            sessionStorage.getItem('application') === 'Supplier Chargeback' ||
            sessionStorage.getItem('application') === 'Pricing')
            ? 'none' : null
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const CalculationModifier = forwardRef((props) => {
    const [mappingValuesSet, setMappingValuesSet] = React.useState({});

    useEffect(() => {
        props.getAttributeListData({
            attribute_name: ''
        })
    }, [])
    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            if (props.dropdownDataCR.records[0].field_id === 'key_figure_name') {
                setMappingValuesSet(props.dropdownDataCR.records[0].label_names);
            }
        }
    }, [props.dropdownDataCR])

    const classes = useStyles();
    const [modifierData, setModifierData] = React.useState([]);
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeNameObject, setAttributeNameObject] = React.useState([]);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        if (props.data) {
            if (props.data.contract_calculation_rule_modifiers && props.data.contract_calculation_rule_modifiers.length > 0)
                setModifierData(props.data.contract_calculation_rule_modifiers);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.sourceDataType) {
            props.onLoadingDefault(sessionStorage.getItem('application'), props.sourceDataType);
            props.onLoadingDefaultCR(sessionStorage.getItem('application'), props.sourceDataType);

        }
    }, [props.sourceDataType])
    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records[0]) {
            if (props.dropdownAttributeData.records[0].field_id === "attribute_name") {
                setAttributeNameData(props.dropdownAttributeData.records[0].label_names);
                setAttributeNameObject(props.dropdownAttributeData.records[0])
            }
        }
    }, [props.dropdownAttributeData])

    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...modifierData];
            props.onChange(edited);
            deleteFlag = false;
        }
    }, [modifierData])

    //add operation
    const [attributeArray, setAttributeArray] = React.useState([]);
    return (
        <div>

            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="CALCULATION RULE MODIFIERS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {modifierData.length > 0 ?
                                <TableContainer>

                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' >Calculation Type</TableCell>
                                                <TableCell align='center' >KeyFigure Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                <TableCell align='center'>Attribute Values</TableCell>
                                                <TableCell align='center' >Start Date</TableCell>
                                                <TableCell align='center' >End Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {modifierData
                                                .map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={item.ID}>
                                                            <TableCell align='center'>{item.calculation_type}</TableCell>
                                                            <TableCell align='center'>{mappingValuesSet && mappingValuesSet[item.key_figure_name] ? mappingValuesSet[item.key_figure_name] : item.key_figure_name}</TableCell>
                                                            <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                {attributeNameData && attributeNameData[item.attribute_name] ? attributeNameData[item.attribute_name] : item.attribute_name.replace(/_/g, ' ')
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center' classes={{ root: classes.leftAlign }}>{item.attribute_values ? item.attribute_values.join(",") : ''}</TableCell>

                                                            <TableCell align='center'>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                            <TableCell align='center'>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>



                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div >
    );

});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
        loading: state.dataSetupData.loading,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationModifier);