import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    TextField,
    OutlinedInput
} from '@mui/material';
import { Link } from 'react-router-dom';
import { getSingleStandardFinanceData, updateStandardFinanceData } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const StandardFinanceEdit = props => {
    const classes = useStyles();
    const [country, setCountry] = React.useState('');
    const [productLine, setProductLine] = React.useState('');
    const [standardCost, setStandardCost] = React.useState('');
    const [validFrom, setValidFrom] = React.useState(null);
    const [validTo, setValidTo] = React.useState(null);
    const [stdRate, setStdRate] = React.useState('');
    const [promoRate, setPromoRate] = React.useState('');

    function handleStdRate(newValue) {
        setStdRate(newValue.target.value);
    }
    function handlePromoRate(newValue) {
        setPromoRate(newValue.target.value);
    }
    function handleCountry(newValue) {
        setCountry(newValue.target.value);
    }
    function handleProductLine(newValue) {
        setProductLine(newValue.target.value);
    }
    function handleStandardCost(newValue) {
        setStandardCost(newValue.target.value);
    }
    function handleValidFrom(newValue) {
        setValidFrom(newValue);
    }
    function handleValidTo(newValue) {
        setValidTo(newValue);
    }
    function handleOnClear() {
        setCountry('')
        setStandardCost('')
        setProductLine('')
        setValidFrom(null)
        setValidTo(null)
        setStdRate('')
        setPromoRate('')
    }
    const handleSubmit = () => {
        var formData = {
            country: country,
            product_line: productLine,
            standard_cost: Number(standardCost),
            valid_from: validFrom,
            valid_to: validTo,
            standard_takerate: Number(stdRate),
            standard_takerate_with_promo_finance: Number(promoRate),
        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(formData, appId);
    };
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleStandardFinanceData(appId);
    }, []);
    useEffect(() => {
        if (props.standardFinanceDataSingle) {
            if (props.standardFinanceDataSingle) {
                setStandardCost(props.standardFinanceDataSingle.standard_cost);
                setProductLine(props.standardFinanceDataSingle.product_line);
                setCountry(props.standardFinanceDataSingle.country);
                setValidFrom(props.standardFinanceDataSingle.valid_from);
                setValidTo(props.standardFinanceDataSingle.valid_to);
                setStdRate(props.standardFinanceDataSingle.standard_takerate);
                setPromoRate(props.standardFinanceDataSingle.standard_takerate_with_promo_finance);
            }
        }
    }, [props.standardFinanceDataSingle]);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/standard-finance-cost'
                >
                    Standard Finance Cost
                </Link>
                <Typography color="textPrimary" variant='h4'>Standard Finance Cost Edit </Typography>
            </Breadcrumbs>
            {
                <div className={classes.bodyContainer}>
                    <Card
                        style={{ marginTop: 15 }}>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container
                                    style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Country
                                        </FormLabel>
                                        <OutlinedInput
                                            value={country}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCountry} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Product Line
                                        </FormLabel>
                                        <OutlinedInput
                                            value={productLine}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleProductLine}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Standard Cost
                                        </FormLabel>
                                        <OutlinedInput
                                            value={standardCost}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleStandardCost}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Standard Takerate
                                        </FormLabel>
                                        <OutlinedInput
                                            value={stdRate}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleStdRate}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Standard TR With Promo Finance
                                        </FormLabel>
                                        <OutlinedInput
                                            value={promoRate}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePromoRate}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Valid From
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 35,
                                                        }
                                                    }}
                                                    value={validFrom}
                                                    onChange={date => handleValidFrom(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Valid To
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 35,
                                                        }
                                                    }}
                                                    value={validTo}
                                                    onChange={date => handleValidTo(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.submitContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                            Clear
                        </Button>
                        <Button color="primary" variant="contained" className={classes.button} onClick={handleSubmit}
                            disabled={stdRate && promoRate && standardCost && country && productLine && validFrom && validTo ? false : true}>
                            Submit
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        standardFinanceDataSingle: state.promotionData.standardFinanceDataSingle,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(updateStandardFinanceData(data, id)),
        getSingleStandardFinanceData: (id) => dispatch(getSingleStandardFinanceData(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StandardFinanceEdit);