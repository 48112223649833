import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Grid,
    Button,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { connect } from 'react-redux';
import { getDeleteMasterData, getTableName, getDeleteMasterDataField } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink as RouterLink } from 'react-router-dom';
import SimpleDialog from '../../components/Dialog';
import BeatLoader from "react-spinners/BeatLoader";
import RingLoader from "react-spinners/RingLoader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import { ChipInput } from "../../components/Inputs";
// import ChipInput from 'material-ui-chip-input';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 30px 0px'
    },
    container: {
        backgroundColor: theme.palette.white,
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
        width: '100%',
        height: '100%'
    },
    rootDropdown: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: 67,
        borderTop: '0.1px solid',
        borderTopColor: theme.palette.border.main,
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: '7px'
        // height:'100%'
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    headerContainer: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        // justifyContent: 'space-between',
        padding: '0px 47px 12px 10px',
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.primary.main,
        position: 'sticky',
        top: '64px',
        zIndex: 1,
    },
    select: {
        color: '#1675e0',
        backgroundColor: theme.palette.white,
    },
    spinner: {
        // height: '100vh'
    },
    formControlRoot: {
        // display: 'flex', 
        justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    // rootContainer: {
    //     border: '1px solid',
    //     borderColor: theme.palette.border.main,
    //     borderRadius: 3,
    //     marginTop: 10,
    //     padding: 0,
    // },
    // chipLabel: {
    //     fontFamily: 'ProximaNova'
    // },
    // chip: {
    //     height: 21,
    //     margin: 0,
    //     marginLeft: 8,
    //     marginBottom: 8,
    //     marginTop: '-2rem',
    //     backgroundColor: '#E1E4F3',
    //     '&&:hover': {
    //         backgroundColor: '#E1E4F3',
    //         color: theme.palette.black
    //     },
    //     fontFamily: 'ProximaNova',
    //     padding: 0
    // },
    // input: {
    //     padding: 0,
    //     height: 21,
    //     fontFamily: 'ProximaNova',
    //     fontSize: 13
    // },
    // inputRoot: {
    //     height: 21,
    //     paddingLeft: 8,
    //     paddingBottom: 25,
    //     paddingTop: 8
    // },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        width: '100%'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset', width: '340px'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14
    },
    spinner: {
        height: '100vh'
    },
    customCardRoot: {
        backgroundColor: '#fff',
        color: '#000000',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        boxShadow: 'none',
        // Remove overflow property
        overflow: 'unset', // or 'visible'
    },
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const DeleteMasterdata = props => {
    const classes = useStyles();
    const [sourceDataType, setSourceDataType] = React.useState(["CustomerDataUploadFormat"]);
    const [open, setOpen] = useState(false);
    const [allowedField, setAllowedField] = React.useState([]);
    const [allowedFieldData, setAllowedFieldData] = React.useState({});



    useEffect(() => {
        props.getDeleteMasterDataField(sourceDataType);
    }, [sourceDataType])

    useEffect(() => {
        if (props.deletemasterdatafields) {
            let a = props.deletemasterdatafields.filter(field => field !== "")
            setAllowedField(a);
        }
    }, [props.deletemasterdatafields]);



    const handleSubmit = () => {
        if (allowedFieldData['start_date'])
            allowedFieldData['start_date'] = Moment.utc(allowedFieldData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        if (allowedFieldData['end_date'])
            allowedFieldData['end_date'] = Moment.utc(allowedFieldData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        setOpen(true)
    }
    // const handleDialog = (bool) => {
    //     if (bool) {
    //         setOpen(false);
    //         const formData = {};
    //         Object.keys(allowedFieldData).forEach((key) => {
    //             if (key === 'start_date' || key === 'end_date') {
    //                 formData[key] = allowedFieldData[key].toString();
    //             } else {
    //                 formData[`${key}.in`] = allowedFieldData[key].toString();
    //             }
    //         });
    //         props.getDeleteMasterData(sourceDataType, formData);
    //         setAllowedFieldData({})
    //     } else {
    //         setOpen(false);

    //     }
    // }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            const formData = {};
            Object.keys(allowedFieldData).forEach((key) => {
                if (key === 'start_date' || key === 'end_date') {
                    formData[key] = allowedFieldData[key].toString();
                } else {
                    formData[`${key}.in`] = transformFieldValue(key, allowedFieldData[key].toString());
                }
            });
            props.getDeleteMasterData(sourceDataType, formData);
            setAllowedFieldData({})
        } else {
            setOpen(false);
        }
    };

    const transformFieldValue = (key, value) => {
        if (key === 'app_functionality') {
            return value.toLowerCase().replace(/ /g, '_');
        } else if (key === 'operation') {
            return value.toUpperCase();
        } else if (key === 'user_email') {
            return value.toLowerCase();
        }
        return value;
    }

    // function handleDeleteMasterDataInput(newValue, key, type) {
    //     console.log("1", newValue)
    //     setAllowedFieldData((prevData) => ({
    //         ...prevData,
    //         [key]: [...(prevData[key] || []), newValue],
    //     }));
    // }
    function handleDeleteMasterDataInput(newValue, key, type) {
        console.log("1", newValue);
        setAllowedFieldData((prevData) => ({
            ...prevData,
            [key]: newValue,
        }));
    }
    function handleDeleteMasterDataDelete(newValue, key) {
        setAllowedFieldData((prevData) => {
            const currentData = prevData[key] || [];
            const updatedArray = currentData.filter((item) => item !== newValue);
            return {
                ...prevData,
                [key]: updatedArray,
            };
        });
    }

    function handleClearAll() {
        setAllowedFieldData({})
    }
    const handleDeleteMasterData = (value, key) => {
        setAllowedFieldData({ ...allowedFieldData, [key]: value })
    }
    const handleSourceDataType = (e) => {
        setAllowedFieldData({})
        setSourceDataType(e.target.value);
    }
    const toolTipTheme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "2em",
                        color: "yellow",
                        backgroundColor: "red"
                    }
                }
            }
        }
    });
    console.log(allowedField)
    return (
        <div className={classes.rootDiv} style={{ backgroundColor: 'white' }}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <Grid container className={classes.root} style={{ backgroundColor: 'white' }}>
                    <Grid container style={{ margin: 10, backgroundColor: 'white', padding: 10, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid
                            item
                            md={5}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.formLabel }} style={{ marginBottom: 5 }}>
                                    Source Data Type
                                </FormLabel>
                                <Select
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={"CustomerDataUploadFormat"}>Customer Master</MenuItem>
                                    <MenuItem value={"SupplierDataFormats"}>Supplier Master</MenuItem>
                                    <MenuItem value={"MaterialMasterDataFormat"}>Material Master</MenuItem>
                                    <MenuItem value={"MasterDataChanges"}>Master Data History Changes</MenuItem>
                                </Select>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={classes.bodyContainer}>
                        <Card classes={{ root: classes.customCardRoot }}>
                            <form
                                autoComplete="off"
                                noValidate
                            >
                                <div className={classes.container} style={{ marginTop: -25 }}>
                                    <Grid container spacing={2}>
                                        {allowedField && allowedField.length > 0 &&
                                            allowedField.map((item, index) => (
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.formLabel}>
                                                            {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                        </FormLabel>
                                                        {item == 'start_date' || item == 'end_date' ?
                                                            <Grid container justifyContent="space-around">
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        clearable
                                                                        InputProps={{
                                                                            padding: 0,
                                                                            disableUnderline: true,
                                                                            style: {
                                                                                padding: '1px 0 1px 11px',
                                                                                alignSelf: 'center',
                                                                                alignItems: 'center',
                                                                                marginTop: 10,
                                                                                border: '1px solid #E0E0E0',
                                                                                width: '100%'
                                                                            }
                                                                        }}
                                                                        value={allowedFieldData[item] ? allowedFieldData[item] : null}
                                                                        onChange={date => handleDeleteMasterData(date, item)}
                                                                        minDate={item == 'end_date' && allowedFieldData['start_date'] ? allowedFieldData['start_date'] : ''}
                                                                        format="MM/DD/YYYY"
                                                                    />
                                                                </MuiPickersUtilsProvider>

                                                            </Grid>
                                                            :
                                                            <ChipInput data={allowedFieldData[item] || []} onChange={newValue => handleDeleteMasterDataInput(newValue, item)} />
                                                            // <ChipInput
                                                            //     classes={{
                                                            //         root: classes.rootContainer,
                                                            //         chip: classes.chip,
                                                            //         input: classes.input,
                                                            //         inputRoot: classes.inputRoot,
                                                            //         chipContainer: classes.chipContainer,
                                                            //         label: classes.chipLabel
                                                            //     }}
                                                            //     value={allowedFieldData[item] || []}
                                                            //     onAdd={(chips) => handleDeleteMasterDataInput(chips, item)}
                                                            //     onDelete={(chip) => handleDeleteMasterDataDelete(chip, item)}
                                                            //     disableUnderline={true}
                                                            //     className={clsx({
                                                            //         [classes.textInput]: true
                                                            //     })}
                                                            // />
                                                        }
                                                    </div>
                                                </Grid>

                                            ))
                                        }
                                    </Grid>
                                </div>
                            </form>
                        </Card>
                        <div className={classes.buttonRoot}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmit}
                                disabled={Object.entries(allowedFieldData).length >= 1 ? false : true}
                            >
                                {props.loadingAPI ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                    :
                                    'Delete'
                                }
                            </Button>
                        </div>
                        <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
                    </div>
                </Grid >
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        deletedata: state.addMultipleConfigurationData.deletemasterdata,
        deletemasterdatafields: state.addMultipleConfigurationData.deletemasterdatafield,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getTableName()),
        getDeleteMasterData: (type, data) => dispatch(getDeleteMasterData(type, data)),
        getDeleteMasterDataField: (type) => dispatch(getDeleteMasterDataField(type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMasterdata);