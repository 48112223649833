import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Select, MenuItem, FormLabel, Typography, Button, TextField } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import { Grid } from '@mui/material';
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from "moment";
import {
  geographicalIncentive,
  loadAccrualVsPaymentGraph,
  loadTop25Chart,
} from "../../../../redux/actions";
import BarChart from "../../Charts/BarChart";

const useStyles = makeStyles((theme) => ({

  periodButtonsContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 1, // Ensure it's above the rest of the content
  },

  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  root: {
    padding: '15px 0px 15px 0px',
    display: 'flex',
    alignItems: 'center'
  },
  rootDiv: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    margin: '0px 0px 16px 0px',
  },
  select: {
    width: "100%",
    color: "#1675e0",
  },
  selectedItem: {
    borderRadius: 5,
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 31,
    paddingBottom: 5,
  },
  button: {
    marginRight: 10,
    // width: 80,
  },
}));
const TopStatus = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("accrual");
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [attribute, setAttribute] = React.useState("customer_number");
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const dimension = { yAxisTitle: "Incentive  Amount" };

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Top Incentives by Attribute" && item.dashboard_name === "Postings Detail Analysis"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      setStartDate(allowedTilesFilter[0].start_date);
      setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type)
      setPostingType(allowedTilesFilter[0].posting_type)
      // setAttribute(convertToSnakeCase(allowedTilesFilter[0].groupBy))
      if (allowedTilesFilter[0].groupBy) {
        setAttribute(convertToSnakeCase(allowedTilesFilter[0].groupBy));
      }
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate && sourceDataType && postingType) {
      var data = {
        application_type: sessionStorage.getItem("application"),
        data_source_type: sourceDataType,
        start_date: Moment(startDate).format('MM/DD/YYYY'),
        end_date: Moment(endDate).format('MM/DD/YYYY'),
        posting_type: postingType,
        aggregation_attribute: attribute,
      };
      props.loadTop25Chart(data);
    }
  }, [allowedTilesFilter]);



  useEffect(() => {
    if (props.top25Data) {
      if (props.top25Data[attribute] && props.top25Data[attribute].length > 0) {
        var graphData = [
          {
            data: props.top25Data[attribute],
            xName: "value",
            yName: "incentive_amount",
            legend: attribute.replace(/_/g, " "),
            type: "Column",
          },
        ];
        setGraphData(graphData);
      } else {
        setGraphData([]);
      }
    }
  }, [props.top25Data]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
  };
  const handleAttribute = (e) => {
    setAttribute(e.target.value);
  };
  const handleStartDate = (e) => {
    setStartDate(e);
  }
  const handleEndDate = (e) => {
    setEndDate(e);
  }
  const handlePeriod = (type) => {
    var sDate;
    var eDate;
    if (type == "month") {
      sDate = Moment.utc()
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else if (type == "quarter") {
      sDate = Moment.utc()
        .subtract(3, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(3, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else {
      sDate = Moment.utc().startOf("year").format("MM/DD/YYYY");
      eDate = Moment.utc().endOf("year").format("MM/DD/YYYY");
    }
    // props.loadTop25Chart({ ...data, start_date: sDate, end_date: eDate });
    var data = {
      application_type: sessionStorage.getItem("application"),
      data_source_type: sourceDataType,
      start_date: sDate,
      end_date: eDate,
      posting_type: postingType,
      aggregation_attribute: attribute,
    };
    props.loadTop25Chart(data);
  };

  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }
  function handleSubmit() {
    var data = {
      application_type: sessionStorage.getItem("application"),
      data_source_type: sourceDataType,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: postingType,
      aggregation_attribute: attribute,
    };
    props.loadTop25Chart(data);
  }
  return (
    <div className={classes.rootDiv}>
      <Grid container className={classes.root}>
        <div className={classes.periodButtonsContainer}>

          <Grid container style={{ display: 'flex', flexWrap: 'nowrap', padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}
          >
            <Grid
              item
              md={3}
              xs={12}
              style={{ paddingRight: 15 }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          // padding: '1px 0 1px 11px',
                          alignSelf: 'center',
                          alignItems: 'center',
                          border: '1px solid #E0E0E0',
                          width: '100%',
                          borderRadius: 5,
                          height: 37,
                          color: '#1675e0'
                        }
                      }}
                      value={startDate}
                      onChange={handleStartDate}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              style={{ paddingRight: 15 }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          // padding: '1px 0 1px 11px',
                          alignSelf: 'center',
                          alignItems: 'center',
                          border: '1px solid #E0E0E0',
                          width: '100%',
                          borderRadius: 5,
                          height: 37,
                          color: '#1675e0'
                        }
                      }}
                      value={endDate}
                      minDate={startDate ? startDate : false}
                      onChange={handleEndDate}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              style={{ paddingRight: 15 }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography classes={{ root: classes.fontSetting }} >Attributes</Typography>
                <Select
                  value={attribute}
                  onChange={handleAttribute}
                  displayEmpty
                  className={clsx({
                    [classes.select]: true
                  })}
                  classes={{
                    selectMenu: classes.selectedItem
                  }}
                >
                  <MenuItem value={"material_group"}>Material Group</MenuItem>
                  <MenuItem value={"customer_group"}>Customer Group</MenuItem>
                  <MenuItem value={"material_number"}>Material Number</MenuItem>
                  <MenuItem value={"customer_number"}>Customer Number</MenuItem>
                  <MenuItem value={"contract_number"}>Contract Number</MenuItem>
                  <MenuItem value={"supplier_number"}>Supplier Number</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              style={{ paddingRight: 15 }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography classes={{ root: classes.fontSetting }} >Posting Type</Typography>
                <Select
                  value={postingType}
                  onChange={handlePostingType}
                  // label='Posting Type'
                  className={clsx({
                    [classes.select]: true
                  })}
                  classes={{
                    selectMenu: classes.selectedItem
                  }}

                >
                  <MenuItem value={"accrual"}>Accrual</MenuItem>
                  <MenuItem value={"payment"}>Payment</MenuItem>
                </Select>
              </div>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                twoline="true"
                disabled={startDate && endDate && sourceDataType && postingType ? false : true}
              >
                <DirectionsRunIcon />
              </Button>
            </div>
          </Grid>
          {/* <div style={{ display: "flex", marginTop: 10, }}>
          <FormControl sx={{ m: 1, maxWidth: 120 }} size="small">
            <InputLabel id="posting-type">Posting Type</InputLabel>
            <Select
              labelId="posting-type"
              id="posting-type-select"
              value={postingType}
              label="Posting Type"
              onChange={handlePostingType}
            >
              <MenuItem value={"accrual"}>Accrual</MenuItem>
              <MenuItem value={"payment"}>Payment</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small">
            <InputLabel id="posting-type">Attributes</InputLabel>
            <Select
              labelId="attributes"
              id="attributes-select"
              value={attribute}
              label="Attribute"
              onChange={handleAttribute}
            >
              <MenuItem value={"material_group"}>Material Group</MenuItem>
              <MenuItem value={"customer_group"}>Customer Group</MenuItem>
              <MenuItem value={"material_number"}>Material Number</MenuItem>
              <MenuItem value={"customer_number"}>Customer Number</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <Fab
              size="small"
              color="secondary"
              aria-label="monthly"
              onClick={() => handlePeriod("month")}
            >
              M
            </Fab>
            <Fab
              size="small"
              color="secondary"
              aria-label="quarterly"
              onClick={() => handlePeriod("quarter")}
            >
              Q
            </Fab>
            <Fab
              size="small"
              color="secondary"
              aria-label="yearly"
              onClick={() => handlePeriod("year")}
            >
              Y
            </Fab>
          </Box>
        </div> */}
        </div>
        <div>
          <BarChart
            data={graphData}
            attribute={attribute}
            dimension={dimension}
          />
        </div>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
    top25Data: state.initialData.top25,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTop25Chart: (data) => dispatch(loadTop25Chart(data, "dashboard")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopStatus);
