import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getIntegrationBatchJobSetup,addIntegrationBatchJobSetup } from '../../../redux/actions';
import { IntegrationBatchJobSetupToolbar } from '.';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    }
}));

const IntegrationSetupUserTable = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    useEffect(() => {
        props.getBatchJobSetup(1, 10);
    }, []);
    useEffect(() => {
        if (props.integrationSetupData) {
            setContractsTableComplete(props.integrationSetupData);
        }
    }, [props.integrationSetupData]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getBatchJobSetup(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getBatchJobSetup(1, event.target.value);
    };
    function viewFilter(item){
        history.push('/integration-batchjob-setup/view-integration-batchjob-setup/'+item.ID)
    }
    function editIntegration(item) {
        history.push('/integration-batchjob-setup/edit-integration-batchjob-setup/'+item.ID)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>Integration Batch Job Setup</Typography>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application')) &&
                <IntegrationBatchJobSetupToolbar /> }
            </div>
            {props.integrationSetupData && props.integrationSetupData.length > 0 ?
                <TableContainer style={{marginTop:-20}}>
                    <Table> 
                        <TableHead >
                            <TableRow >
                                <TableCell align='center'className={classes.tabHead}>Application Type</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Batch Job Name</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Batch Job Type</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Planned Date Type</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Planned Start Date</TableCell>                                
                                <TableCell align='center'className={classes.tabHead}>Planned Start Time</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Frequency</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Created By</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Changed By</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Created On</TableCell>
                                <TableCell align='center'className={classes.tabHead}>Changed On</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.integrationSetupData
                            .sort((a, b) => a.ID> b.ID ? 1 : -1)
                            .map(item => {
                                return (
                                    <StyledTableRow key={item.ID}>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.app_type}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.batch_job_name}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.batch_job_type}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.planned_date_type}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{Moment(item.planned_start_date).format('MM/DD/YYYY') }</TableCell>                                        
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{(item.planned_start_time).split(":00")}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.frequency}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.created_by}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.changed_by}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{Moment(item.CreatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</TableCell>
                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{Moment(item.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss') }</TableCell>
                                        <TableCell align='center'>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                          <IconButton
                                              classes={{ root: classes.IconButton }}
                                              onClick={() => viewFilter(item)}
                                              size="large">
                                                <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                                            </IconButton>
                                            {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_batch_job-'+sessionStorage.getItem('application')) &&
                                            <IconButton
                                                classes={{ root: classes.IconButton }}
                                                onClick={() => editIntegration(item)}
                                                size="large">
                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                            </IconButton> }
                                            {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_batch_job-'+sessionStorage.getItem('application')) &&
                                            <IconButton
                                                classes={{ root: classes.IconButton }}
                                                onClick={() => props.deleteData('','delete',item.ID)}
                                                size="large">
                                                    <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton> }
                                            </div>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={props.integrationSetupData && props.integrationSetupData.length ? props.integrationSetupData.length : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    />
                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                    </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        integrationSetupData: state.customerData.integrationbatchJobAll,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getBatchJobSetup: (pagination, limit) => dispatch(getIntegrationBatchJobSetup(pagination, limit)),
        deleteData:(data,type,id) => dispatch(addIntegrationBatchJobSetup(data,type,id)),
        onEdit: (data,id) => dispatch(addIntegrationBatchJobSetup(data,'edit',id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSetupUserTable);