import React, { useEffect } from 'react';
import {
    Button
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import MarketDefinition from '../MarketDefinition/MarketDefinition';
import { connect } from 'react-redux';
import { StickerBank, StickerBankSelected } from '../../../GlobalSetup'
import { getMaterialFilters } from '../../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1
    }
}))
const Stickers = (props) => {

    const classes = useStyles();
    const { className } = props;
    const [defineMarketEnable, setDefineMarketEnable] = React.useState(false);
    const [isClicked, setIsClicked] = React.useState(false);
    const [sticker, setSticker] = React.useState('');
    const [stickerKey, setStickerKey] = React.useState({});
    const [selectedStickers, setSelectedStickers] = React.useState([]);

    function hanleSticker(value, type, keyData) {
        if (value == 'return') {
            props.handleBack()
            setIsClicked(false)
        }
        else if (type == 'selected_stickers') {
            setSelectedStickers(value)
            props.onChange(value, null, keyData)
            setStickerKey(keyData)
        }
        else {
            setSticker(value)
            setDefineMarketEnable(true)
            props.onChange(selectedStickers, value)
        }

    }
    function handleDefine(value) {
        setDefineMarketEnable(false)
    }

    function handleClick(e) {
        setIsClicked(e);
    }
    function handleNext() {
        var stickerId = selectedStickers && Object.values(selectedStickers).length > 0 && Object.values(selectedStickers)[0].name
        props.onChange(selectedStickers, stickerId, stickerKey)
        setDefineMarketEnable(true)
    }

    return (
        <div style={{ paddingTop: 0 }}>
            <StickerBankSelected clicked={handleClick} country={props.country} productLine={props.productLine}
                promoProductLines={props.promoProductLines} colorPicker={props.colorPicker} mode={props.mode}
                onChange={hanleSticker} handleNext={handleNext} stickers={{ 'data': selectedStickers, 'keyData': stickerKey }}
                handleColor={props.handleColor} prefillData={props.prefillData} handleSubmit={props.handleSubmit} />
        </div>
    )

}
const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters())
    }
}

const mapStateToProps = state => {
    return {
        filterData: state.promotionData.promoFilters,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Stickers);
