const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  gradient: {
    appLauncher: "linear-gradient(90deg, #082B4D 20%, #A2CFF2 100%)",
  },
  notification: {
    grey: "#DADFF2",
  },
  primary: {
    main: "#445AC7", //old theme
  },
  secondary: {
    main: "#FBFBFB",
  },
  icon: {
    active: "#B4BDE9",
  },
  text: {
    primary: "#000000",
    grey: "#828282",
    helper: "#4F4F4F",
    yellow: "#F2F5A9",
  },
  table: {
    row: "rgba(242, 242, 242, 0.3)",
    border: "#828282",
  },
  border: {
    main: "#E0E0E0",
    divider: "#7F8CC4",
  },
  button: {
    red: "#EB5757",
    green: "#27AE60",
  },
  tabs: {
    tabActive: "#858AA3",
    text: "#ECEFF8",
  },
};
