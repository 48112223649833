import React, { useEffect, useRef } from "react";
import {
    Input, Grid, Button, Typography, Breadcrumbs,Card, FormLabel, OutlinedInput, Select, MenuItem, Dialog,
    DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { createPriceListDataAll, getDriverFormatFields, getPriceListData } from '../../../redux/actions';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import {
    RangeDirective, getRangeIndexes,getRangeAddress,
    SpreadsheetComponent, getCell, SheetsDirective, SheetDirective, ColumnsDirective,
    RangesDirective, ColumnDirective, RowsDirective, RowDirective, CellsDirective,
    CellDirective
} from '@syncfusion/ej2-react-spreadsheet';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 15,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formlabel: {
        marginBottom: 8,
        fontSize:theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"

    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    input: {
        width: 42,
    },
    addRow: {
        display: 'flex',
        flexDirection: 'row'
    }
}));

const AddPriceListData = props => {
    const classes = useStyles();
    const jRef = useRef(null);
    const [priceListID, setPriceListID] = React.useState('');
    const [submitClicked, setSubmitClicked] = React.useState(false);
    const [listDataArray, setListDataArray] = React.useState([]);
    const [formData, setFormData] = React.useState({});
    const [priceListData, setPriceListData] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [excelDialog, setExcelDialog] = React.useState(false);
    const [addNRows, setAddNRows] = React.useState(5)
    const [dataRows, setDataRows] = React.useState([]);
    const [heading, setHeading] = React.useState([]);
    const [updatedValuesAPI,setUpdatedValuesAPI] = React.useState([]);
    const [columns, setColumns] = React.useState({})
    const [optionsSFPredefined, setOptionsSFPredefined] = React.useState([])
    const scrollSettings = { isFinite: true };
    const [rowCount, setRowCount] = React.useState(1000);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setPriceListID(appId)
        if (appId) {
            props.getDriverFormatFields(appId)
        }
    }, [])
    function titleCase(str) {
        if (str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }
    function handleOnSubmit() {
        setSubmitClicked(true)
        var name = sessionStorage.getItem('pricetypename')
        props.getConfigData(null, null, { ...formData, 'analysis_level_id': priceListID, 'driver_name': name });
        setFormData({})
        setExcelDialog(true)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        if (props.maintenanceFields)
            setListDataArray(props.maintenanceFields)
    }, [props.maintenanceFields])

    useEffect(() => {
        if (props.priceListData && props.priceListData.records && props.priceListData.records[0]) {
            setPriceListData(props.priceListData.records);
            setPriceTypeNameArray(props.priceListData.records[0].price_type_name)
        }
    }, [props.priceListData]);
    const updatedSheet = () => {
        var json = [];
        var obj = {};
        var ssObj = spreadsheet;
        var usedRange = ssObj.getActiveSheet().usedRange;
        var selIndex = [1, 0, usedRange.rowIndex, usedRange.colIndex];
        var configDataAPI = props.configData.records;
        var range =
            ssObj.getActiveSheet().name + '!' + getRangeAddress([1, 0, selIndex[2], selIndex[3]]);
        ssObj.getData(range).then((value) => {
            value.forEach((cell, key) => {
                var indexes = getRangeIndexes(key);
                if (cell && selIndex[2] >= indexes[0]) {
                    var charCurrent = 'A';
                    columns.map(item => {
                        if (key.indexOf(charCurrent) > -1) {
                            if (item.field == 'start_date' || item.field == 'end_date')
                                obj[item.field] = cell.value ? Moment(ExcelDateToJSDate(cell.value)).format('MM/DD/YYYY') : '';
                            else if (item.field == 'driver_value')
                                obj[item.field] = cell.value ? parseFloat(cell.value) : 0;
                            else
                                obj[item.field] = cell.value ? cell.value.toString() : '';
                        }
                        charCurrent = String.fromCharCode(charCurrent.charCodeAt() + 1)
                    });
                    if (indexes[1] === selIndex[3]) {
                        json.push(obj);
                        obj = {};
                    }
                }
            });
            configDataAPI && configDataAPI.map((item, ix) => {
                if (json[ix])
                    json[ix]['ID'] = item.ID
            })
            props.onSubmitAll(json);
            setExcelDialog(false);
        });
    }
    function ExcelDateToJSDate(serial) {
        var hours = Math.floor((serial % 1) * 24);
        var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
        return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    }
    useEffect(() => {
        if (submitClicked && props.configData && props.configData.records && props.configData.records.length > 0 && listDataArray && listDataArray.value && listDataArray.value.length > 0 && !props.loadingAPI) {
            // setConfigDataAPI(props.configData);
            if (jRef.current && jRef.current.jexcel)
                jRef.current.jexcel.destroy();
            var temp = [];
            var tempSingle = [];
            var columnArray = [];
            var options = []
            var rec = props.configData.records;
            rec.map(item => {
                listDataArray.value
                    .map(h => {
                        if (item[h]) {
                            if (h.key === 'start_date' || h.key === 'end_date') {
                                tempSingle.push(Moment.utc(item[h.key]).format('MM/DD/YYYY'))
                            } else {
                                tempSingle.push(item[h.key])
                            }
                        } else {
                            tempSingle.push('0');
                        }
                    })
                temp.push(tempSingle);
                tempSingle = [];
            })
            listDataArray.value
                // .filter(h => h !== 'price_type_name')
                .map(h => {
                    if (h === 'start_date' || h === 'end_date' || h === 'per_unit') {
                        columnArray.push({
                            field: h,
                            title: h.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ')}),
                            width: 100
                        });
                        options.push({
                            title: '',
                            width: 100
                        });

                    } else {
                        columnArray.push({
                            field: h,
                            title: h.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ')}),
                            width: 150
                        });
                        options.push({
                            title: '',
                            width: 100
                        });
                    }
                })
            var headers = []
            columnArray.map(item => {
                headers.push(item.field)
            })
            setColumns(columnArray);
            var tempRows = [];
            var tempObj = {};
            var tempRowsNew = [];
            var tempObjNew = {};
            props.configData.records.map((item, ix) => {
                //tempObj=item
                columnArray.map((h, ix2) => {
                    if (h.field === 'start_date' || h.field === 'end_date') {
                        tempObj[h.title] = Moment.utc(item[h.field]).format('M/D/YYYY')
                        tempObjNew[h.field] = Moment(item[h.field]).format('MM/DD/YYYY')
                    }
                    else {
                        tempObj[h.title] = item[h.field]
                        tempObjNew[h.field] = item[h.field]
                    }
                })
                tempRows[ix] = tempObj;
                tempObj = {};
                tempRowsNew[ix] = tempObjNew;
                tempObjNew = {};
            })
            setDataRows(tempRows);
            if (tempRows && tempRows.length > rowCount)
                setRowCount(tempRows.length)
        }
        else
            setDataRows([])
    }, [props.configData, listDataArray]);
    function handleFormData(value, key) {
        if ((key == 'start_date' || key == 'end_date') && value) {
            setFormData({ ...formData, [key]: Moment(value).local().format('YYYY-MM-DD') })
        }
        else
            setFormData({ ...formData, [key]: value })
    }
    const addRow = () => {
        setRowCount(rowCount + addNRows)
    };

    //syncfusion
    let spreadsheet;
    const oncreated = () => {
        var ssObj = spreadsheet;
        var nextCell = 'A';
        heading.map((item, index) => {
            ssObj.updateCell({ value: item.replace(/_/g, ' ').toUpperCase() }, nextCell + '1');
            nextCell = String.fromCharCode(nextCell.charCodeAt(0) + 1)
        })
        spreadsheet.setCellStyle(1, 'A', { wrap: true });
    }
    
    // Triggers before going to the editing mode.
    const oncellEdit = (args) => {
        // console.log(args['beforeActionData'])
    }
        ;
    // Trigger before saving the edited cell content.
    const onbeforeCellSave = (args) => {
        // Prevent saving the edited changes in 4th(Rate) column.
        if (args.address.includes('1')) {
            args.cancel = true;
            // Manually removes the editable state without saving the changes. Use `endEdit` method if you want to save the changes.
            spreadsheet.closeEdit();
        }
    }
    function contextMenuBeforeOpen() {
        spreadsheet.removeContextMenuItems(["Insert Column", 'Delete Column', 'Hide Coulmn']);
    };
    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>Edit Driver Maintenance Data</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {listDataArray && listDataArray.value && listDataArray.value.length > 0 &&
                                    listDataArray.value
                                        .filter(item => item != 'driver_name')
                                        .map((item) => {
                                            return (
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.formlabel}>
                                                            {item.replace(/_/g, ' ')}
                                                        </FormLabel>
                                                        {item == 'start_date' || item == 'end_date' ?
                                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                <Grid container justifyContent="space-around">
                                                                    <KeyboardDatePicker
                                                                        disableToolbar
                                                                        clearable
                                                                        InputProps={{
                                                                            padding: 0,
                                                                            disableUnderline: true,
                                                                            style: {
                                                                                padding: '1px 0 1px 11px',
                                                                                alignSelf: 'center',
                                                                                alignItems: 'center',
                                                                                border: '1px solid #E0E0E0',
                                                                                width: '100%',
                                                                                marginTop:'3px'
                                                                            }
                                                                        }}
                                                                        value={formData[item] ? formData[item] : null}
                                                                        onChange={(e) => handleFormData(e, item)}
                                                                        format="MM/DD/YYYY"
                                                                    />
                                                                </Grid>
                                                            </MuiPickersUtilsProvider>
                                                            : item == 'price_type_name' ?

                                                                <Select
                                                                    disableUnderline
                                                                    value={formData[item] ? formData[item] : ''}
                                                                    onChange={(e) => handleFormData(e.target.value, item)}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    style={{ textTransform: 'capitalize', marginTop: 0 }}
                                                                >

                                                                    {priceTypeNameArray && priceTypeNameArray.length > 0 && priceTypeNameArray
                                                                        .map((item) => {
                                                                            return (
                                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                                :
                                                                <OutlinedInput
                                                                    value={formData[item] ? formData[item] : ''}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) => handleFormData(e.target.value, item)} />
                                                        }
                                                    </div>
                                                </Grid>
                                            );
                                        })}


                            </Grid>

                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

                <Dialog
                    classes={{ paper: classes.paperContainer }}
                    open={excelDialog}
                    onClose={() => setExcelDialog(false)}
                    aria-labelledby="responsive-dialog-title"
                    fullScreen
                >
                    <DialogContent>
                        <DialogContentText>
                        {dataRows && dataRows.length > 0 && columns &&
                            <div style={{ display: 'flex', justifyContent: 'center',height: '100%' }}>
                                <SpreadsheetComponent
                                    scrollSettings={scrollSettings}
                                    ref={(ssObj) => { spreadsheet = ssObj }}
                                    enableVirtualization={true}
                                    allowNumberFormatting={true}
                                    allowDataValidation={true}
                                    showPager={false}
                                    isFinite={true}
                                    width={window.screen.width + 30}
                                    height={window.screen.height-70}
                                    allowEditing={true}
                                    contextMenuBeforeOpen={contextMenuBeforeOpen}
                                >
                                    <SheetsDirective>
                                        <SheetDirective
                                            name="Price List"
                                            colCount={columns && columns.length}
                                            rowCount={rowCount}
                                            >
                                            <RowsDirective>
                                                <RowDirective >
                                                    <CellsDirective allowEditing={false}>
                                                        {columns && columns.map((item, index) => {
                                                            return (
                                                                <CellDirective
                                                                index={index}
                                                                headerText={item.title}
                                                                value={item.title}
                                                                field={item.field}
                                                                width={window.screen.width / (columns.length)}
                                                                style={{
                                                                    color: '#F2F2F2',
                                                                    backgroundColor: '#095eb5',//localStorage.getItem('appTheme')!='Light'&&localStorage.getItem('appTheme'), 
                                                                    textAlign: 'center',
                                                                }}
                                                                />
                                                            )
                                                        })}
                                                    </CellsDirective>
                                                </RowDirective>
                                                </RowsDirective>
                                                <ColumnsDirective>
                                                    {columns && columns
                                                        .map(item => {
                                                            return <ColumnDirective
                                                                width={window.screen.width / (columns.length)}
                                                                headerText={item.title} field={item.field}
                                                                format={(item.field === 'end_date' || item.field === 'start_date') ? 'mm-dd-yyyy' : null}
                                                                type={(item.field === 'end_date' || item.field === 'start_date') ? 'shortDate' : null}
                                                            />
                                                        })}
                                                </ColumnsDirective>
                                                {/* <RowDirective >
                                                    <CellsDirective >
                                                        {optionsSFPredefined && optionsSFPredefined.map((item, index) => {
                                                            return (
                                                                <CellDirective
                                                                    index={index}
                                                                    value={item}
                                                                />
                                                            )
                                                        })}
                                                    </CellsDirective>
                                                </RowDirective> */}
                                                <RangesDirective>
                                                    <RangeDirective dataSource={dataRows}></RangeDirective>
                                                </RangesDirective>
                                            
                                            
                                            {/* <ColumnsDirective>
                                                {optionsSFPredefined.map(item => {
                                                    return (<ColumnDirective width={window.screen.width/optionsSFPredefined.length}></ColumnDirective>)
                                                })}
                                            </ColumnsDirective> */}
                                        </SheetDirective>
                                    </SheetsDirective>
                                </SpreadsheetComponent>
                            </div>
                        }                   
                            <div className={classes.addRow} style={{ width: '100%'}}>
                                <div style={{ display: 'flex' }}>

                                    <Input
                                        className={classes.input}
                                        value={addNRows}
                                        margin="dense"
                                        onChange={(e) => setAddNRows(e.target.value)}
                                        inputProps={{
                                            step: 10,
                                            min: 0,
                                            max: 100,
                                            type: 'number',
                                            //'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                    <Button variant="string" onClick={addRow}> Add More Rows </Button>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={updatedSheet} color="primary" variant="contained">
                            Update
                        </Button>
                        <Button onClick={() => setExcelDialog(false)} color="primary" variant="outlined">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmitAll: (data) => dispatch(createPriceListDataAll(data)),
        getDriverFormatFields: (analysisListId) => dispatch(getDriverFormatFields(analysisListId)),
        getConfigData: (pagination, limit, appId) => dispatch(getPriceListData(pagination, limit, appId, 'editDriverMaintenance')),
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.pricingData.loading,
        priceListId: state.pricingData.priceTypeNameList,
        maintenanceFields: state.profitOptimizationData.driverFormatFields,
        configData: state.pricingData.priceListData,
        priceListData: state.pricingData.priceListMasterData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPriceListData);

