import React from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { connect } from 'react-redux';
import { Chart } from "react-google-charts";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '16px 0px 16px 0px'
    }
}));
const ContractVariance = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.rootDiv}>
            <Chart
                chartType="GeoChart"
                data={[
                    ['Country', 'Incentive Amount'],
                    ['CA', 2],
                    ['BM', -8],
                    ['US',-23],
                    ['PM',34],
                    ['GL',78]

                ]}
                options={{
                    region: '021', // Africa
                    colorAxis: { colors: ['#00853f', 'black', '#e31b23'] },
                    backgroundColor: '#ECEFF8',
                    datalessRegionColor: '#f8bbd0',
                    defaultColor: '#E89948',
                    chartArea: { 'height': '80%' },
                }}
                // Note: you will need to get a mapsApiKey for your project.
                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                mapsApiKey="YOUR_KEY_HERE"
                rootProps={{ 'data-testid': '4' }}
            />
        </div>
    );
};

export default connect(null,null)(ContractVariance);