import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    OutlinedInput,
    FormLabel,
    Select,
    ListItemText,MenuItem, Checkbox,TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { getClaimError, runMultipleClaims, getSupplierMasterData, getCustomerMasterData, getOutgoingClaims} from '../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader';
import RotateLoader from "react-spinners/RotateLoader";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider,DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    calendarLeftGridContainer: {
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        padding: '0px 45px 0px 10px'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    spinner: {
        height: '100vh'
    },
    fontSettings:{
        fontSize:theme.typography.h3.fontSize
    }
}));

const MultipleClaimsForm = props => {
    const classes = useStyles();
    const { className } = props;
    const [claimDateStart, setClaimDateStart] = React.useState(null);
    const [claimDateEnd, setClaimDateEnd] = React.useState(null);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [channelPartnerID, setChannelPartnerID] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [rejectionReason, setRejectionReason] = React.useState([]);
    const [claimError, setClaimError] = React.useState([]);
    const [claimStatus, setClaimStatus] = React.useState([]);
    const [claimNumber, setClaimNumber] = React.useState([]);
     const status=['Review','Approved','Adjusted','Rejected']
    const handleClear = () => {
        setBillingDateEnd(null)
        setBillingDateStart(null)
        setClaimDateEnd(null)
        setClaimDateStart(null)
        setChannelPartnerID('')
        setCustomerNumber([])
        setRejectionReason([])
        setClaimError([])
        setClaimStatus([])
        setClaimNumber([])

    }
    function handleOpen() {
        setSelected(rejectionReason)
        setListOpen(true)
    }
    function handleOpenCE() {
        setSelectedCE(claimError)
        setListOpenClaimError(true)
    }
    function handleOpenStatus() {
        setSelectedStatus(claimStatus)
        setListOpenClaimStatus(true)
    }
    function handleOpenCustomer() {
        setSelectedCustomer(customerNumber)
        setListOpenCustomer(true)
    }
    function handleOpenClaimNumber() {
        setSelectedClaim(claimNumber)
        setListOpenClaimNumber(true)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,


    }
    var formData = {
        "billing_date.range": (billingDateEnd ? Moment(billingDateStart).local().format('YYYY-MM-DD')+'T00:00:00'+','+Moment(billingDateEnd).local().format('YYYY-MM-DD')+'T00:00:00'
            : billingDateStart ? Moment(billingDateStart).local().format('YYYY-MM-DD')+'T00:00:00'+','+Moment(billingDateStart).local().format('YYYY-MM-DD')+'T00:00:00'  : null),

        "claim_date.range": (claimDateEnd ? Moment(claimDateStart).local().format('YYYY-MM-DD')+'T00:00:00'+','+Moment(claimDateEnd).local().format('YYYY-MM-DD')+'T00:00:00'
            : claimDateStart ? Moment(claimDateStart).local().format('YYYY-MM-DD')+'T00:00:00'+','+Moment(claimDateStart).local().format('YYYY-MM-DD')+'T00:00:00':null),

        "channel_partner_id" : channelPartnerID?channelPartnerID:null,
        "end_customer_id_number.in": customerNumber.length>0?customerNumber.toString():null,
        "rejection_reason.in": rejectionReason.length>0?rejectionReason.toString():null,
        "claim_error.in": claimError.length>0?claimError.toString():null,
        "claim_status.in": claimStatus.length>0?claimStatus.toString():null,
        "claim_number.in":claimNumber.length>0?claimNumber.toString():null
    }
    const handleRun = () => {
        
        
        props.onsubmit(formData)   
    }
    
    const [listOpen, setListOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [listOpenSupplier, setListOpenSupplier] = React.useState('');
    const [selectedSupplier, setSelectedSupplier] = React.useState([]);
    const [listOpenCustomer, setListOpenCustomer] = React.useState('');
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [listOpenClaimError, setListOpenClaimError] = React.useState('');
    const [selectedCE, setSelectedCE] = React.useState([]);
    const [listOpenClaimStatus, setListOpenClaimStatus] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState([]);
    const [listOpenClaimNumber, setListOpenClaimNumber] = React.useState('');
    const [selectedClaim, setSelectedClaim] = React.useState([]);

    useEffect(() => {
        props.getClaimError();
        props.getCustomerMasterData();
        props.getOutgoingClaims()
    }, [])
     const [claimErrArr, setClaimErrArr] = React.useState([]);
    useEffect(() => {
        if (props.claimErrorList && props.claimErrorList[0] && props.claimErrorList[0]['rejection_reason']) {
            const unique = props.claimErrorList[0]['rejection_reason']
                .map(e => e['claim_error'])

            const filtered = unique.filter((item, index) => unique.indexOf(item) === index)
            setClaimErrArr(filtered);
        }
    }, [props.claimErrorList])

    const handleRejectionReason = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected)
    };
    const handleClaimError = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedCE.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCE, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCE.slice(1));
        } else if (selectedIndex === selectedCE.length - 1) {
            newSelected = newSelected.concat(selectedCE.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCE.slice(0, selectedIndex),
                selectedCE.slice(selectedIndex + 1),
            );
        }
        setSelectedCE(newSelected)
    };
    const handleClaimStatus = (event, value, selectedList,type) => {
        let newSelected = [];
        const selectedIndex = selectedList.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedList, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedList.slice(1));
        } else if (selectedIndex === selectedList.length - 1) {
            newSelected = newSelected.concat(selectedList.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedList.slice(0, selectedIndex),
                selectedList.slice(selectedIndex + 1),
            );
        }
        if(type=='claim status')
         setSelectedStatus(newSelected)
        if(type=='claim number')
         setSelectedClaim(newSelected)
    };

    const handleSupplierNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedSupplier.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedSupplier, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedSupplier.slice(1));
        } else if (selectedIndex === selectedSupplier.length - 1) {
            newSelected = newSelected.concat(selectedSupplier.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedSupplier.slice(0, selectedIndex),
                selectedSupplier.slice(selectedIndex + 1),
            );
        }
        setSelectedSupplier(newSelected)
    };
    const handleCustomerNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedCustomer.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCustomer, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCustomer.slice(1));
        } else if (selectedIndex === selectedCustomer.length - 1) {
            newSelected = newSelected.concat(selectedCustomer.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCustomer.slice(0, selectedIndex),
                selectedCustomer.slice(selectedIndex + 1),
            );
        }
        setSelectedCustomer(newSelected)
    };
    

    function onAdd(item) {
        if (item === 'customer') {
            setCustomerNumber(selectedCustomer)
            setListOpenCustomer(false)
        } else if (item === 'claim error') {
            setClaimError(selectedCE)
            setListOpenClaimError(false)
        } else if (item === 'claim status') {
            setClaimStatus(selectedStatus)
            setListOpenClaimStatus(false)
        }else if (item === 'claim number') {
            setClaimNumber(selectedClaim)
            setListOpenClaimNumber(false)
        }
        else {
            setRejectionReason(selected)
            setListOpen(false)
        }
    }
    function onCancel(item) {
        if (item === 'supplier') {
            setListOpenSupplier(false)
            setSelectedSupplier([])
        } else if (item === 'customer') {
            setListOpenCustomer(false)
            setSelectedCustomer([])
        } else if (item === 'claim error') {
            setListOpenClaimError(false)
            setSelectedCE([])
        } else if (item === 'claim status') {
            setSelectedStatus([])
            setListOpenClaimStatus(false)
        }else if (item === 'claim number') {
            setSelectedClaim([])
            setListOpenClaimNumber(false)
        }else {
            setListOpen(false)
            setSelected([])
        }
    }
    const [claimNumberArray, setClaimNumberArray] = React.useState([]);
    const [customerNameArray, seCustomerNameArray] = React.useState([]);
    const [customerMasterArrayMaterialNumber, setCustomerMasterArrayMaterialNumber] = React.useState([]);

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerMasterArrayMaterialNumber(tempArray)
            seCustomerNameArray(tempNameArray)
        }
    }, [props.customerMasterData])
   
    useEffect(() => {
        if (props.outgoingClaimListData &&  props.outgoingClaimListData.length > 0) {
            var tempArray = []
            props.outgoingClaimListData.map((item) => {
                tempArray.push(item.claim_number);
            })
            setClaimNumberArray(tempArray)
        }
    }, [props.outgoingClaimListData])
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary'> Multiple Claims </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel className={classes.fontSettings} required>Claim Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 15,
                                                        width: '100%',
                                                        border: '1px solid #E0E0E0',
                                                    }
                                                }}
                                                value={claimDateStart}
                                                onChange={e => setClaimDateStart(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                                <div className={classes.dateSecondColumn}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 33,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={claimDateEnd}
                                                onChange={e => setClaimDateEnd(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={claimDateStart ? claimDateStart : ''}
                                                disabled={claimDateStart ? false : true}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel className={classes.fontSettings}>Billing Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 15,
                                                        width: '100%',
                                                        border: '1px solid #E0E0E0',
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={e => setBillingDateStart(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                                <div className={classes.dateSecondColumn}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 33,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={e => setBillingDateEnd(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                                disabled={billingDateStart ? false : true}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel className={classes.fontSettings}>
                                    Claim Number
                                </FormLabel>
                                <Select
                                    value={claimNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenClaimNumber}
                                    onClose={() => setListOpenClaimNumber(false)}
                                    open={listOpenClaimNumber}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {claimNumberArray?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleClaimStatus(event, item,selectedClaim,'claim number')}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedClaim.indexOf(item) > -1} />
                                                <ListItemText style={{marginTop:10}} primary={item} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('claim number')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('claim number')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel className={classes.fontSettings}>
                                    Channel Partner ID
                                </FormLabel>
                                <OutlinedInput
                                    value={channelPartnerID}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setChannelPartnerID(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel className={classes.fontSettings}>
                                    End Customer Number
                                </FormLabel>
                                <Select
                                    value={customerNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenCustomer}
                                    onClose={() => setListOpenCustomer(false)}
                                    open={listOpenCustomer}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {customerMasterArrayMaterialNumber?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleCustomerNumber(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedCustomer.indexOf(item) > -1} />
                                                <ListItemText style={{marginTop:10}} primary={customerNameArray[customerMasterArrayMaterialNumber.indexOf(item)] + ' (' + item + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('customer')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('customer')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel className={classes.fontSettings}>
                                    Rejection Reason
                                </FormLabel>

                                <Select
                                    value={rejectionReason}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpen}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {props.claimErrorList[0] && props.claimErrorList[0]['rejection_reason']?.map((item) => {
                                        return (
                                            <MenuItem value={item.rejection_reason_code} key={item.rejection_reason_code} style={{ textTransform: 'capitalize' }} onClick={(event) => handleRejectionReason(event, item.rejection_reason_code)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item.rejection_reason_code) > -1} />
                                                <ListItemText style={{marginTop:10}} primary={item.rejection_reason_code + ' (' + item.rejection_reason_description + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel className={classes.fontSettings}>
                                    Claim Error
                                </FormLabel>
                                <Select
                                    value={claimError}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenCE}
                                    onClose={() => setListOpenClaimError(false)}
                                    open={listOpenClaimError}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {claimErrArr
                                        .map((item) => {
                                            return (
                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleClaimError(event, item)}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selectedCE.indexOf(item) > -1} />
                                                    <ListItemText style={{marginTop:10}} primary={item} />
                                                </MenuItem>
                                            )
                                        })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('claim error')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('claim error')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel className={classes.fontSettings} required>
                                    Claim Status
                                </FormLabel>
                                    <Select
                                    value={claimStatus}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenStatus}
                                    onClose={() => setListOpenClaimStatus(false)}
                                    open={listOpenClaimStatus}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {status
                                        .map((item) => {
                                            return (
                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleClaimStatus(event, item,selectedStatus,'claim status')}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selectedStatus.indexOf(item) > -1} />
                                                    <ListItemText style={{marginTop:10}} primary={item} />
                                                </MenuItem>
                                            )
                                        })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('claim status')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('claim status')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>


                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear}>
                                Clear
                            </Button>
                            <Button variant="contained" color="primary"
                                className={classes.button} twoline='true' onClick={handleRun}
                                disabled={claimDateStart && (claimStatus.length>0? true :false)  ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onsubmit:(formData) => dispatch(runMultipleClaims(formData)),
        getClaimError: () => dispatch(getClaimError()),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getOutgoingClaims: () => dispatch(getOutgoingClaims()),
    }
}
const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        claimErrorList: state.calculationAnalysisData.claimError,
        customerMasterData: state.customerData.customerMasterData,
        outgoingClaimListData: state.initialData.outgoingClaimListData,
        multipleClaims:state.dataSetupData.multipleClaims,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleClaimsForm);