import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Card
} from '@mui/material';
import { addCustomerMasterXRefUser, getDefaultValuesCustomer, editOutgoingClaim, updateIncomingClaimData } from '../../../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Moment from 'moment';
import PopupContainer from '../PopupContainer';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles(theme => ({

    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },

}));

const CustomerMaterial = props => {
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.editOutgoingClaim(appId);

    }, []);
    const classes = useStyles();
    const theme = useTheme();
    const [dataRows, setDataRows] = React.useState([]);
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [currentViewID, setCurrentViewID] = React.useState(0);
    const [filteredData, setFilterData] = React.useState([]);
    useEffect(() => {
        var tempRows = [];
        if (props.outgoingClaimDetailData && props.outgoingClaimDetailData.length > 0)
            props.outgoingClaimDetailData
                .sort((a, b) => a.unique_identifier > b.unique_identifier ? 1 : -1)
                .map(e => {
                    tempRows.push({
                        unique_identifier: e.unique_identifier,
                        id: e.ID,
                        street_address: e.street_address,
                        city: e.city,
                        region: e.region,
                        postal_code: e.postal_code,
                        country: e.country,
                        end_customer_id: e.end_customer_id,
                        debit_credit_indicator: e.debit_credit_indicator,
                        billed_uom: e.billed_uom,
                        quantity: e.quantity,
                        acquisition_price: e.acquisition_price,
                        contract_price: e.contract_price,
                        currency: e.currency,
                        base_uom: e.base_uom,
                        membership_owner_id: e.membership_owner_id,
                        membership_owner_name: e.membership_owner_name,
                        end_customer_id_type: e.end_customer_id_type,
                        end_customer_id_number: e.end_customer_id_number,
                        material_id_type: e.material_id_type,
                        material_id_number: e.material_id_number,
                        class_of_trade: e.class_of_trade,
                        company_code: e.company_code,
                        sales_org: e.sales_org,
                        distribution_channel: e.distribution_channel,
                        division: e.division,
                        claim_number: e.claim_number,
                        claim_amount: e.claim_amount,
                        approved_amount: e.approved_amount,
                        claim_error: e.claim_error,
                        claim_date: e.claim_date,
                        response_date: e.response_date,
                        pricing_date: e.pricing_date,
                        claim_status: e.claim_status,
                        rejection_reason: e.rejection_reason,
                        action_code: e.action_code,
                        adjustment_code: e.adjustment_code,
                        claim_reason: e.claim_reason,
                        contract_number: e.contract_number,
                        claim_per_unit: e.claim_per_unit,
                        material_number: e.material_number,
                        material_description: e.material_description,
                        supplier_number: e.supplier_number,
                        supplier_name: e.supplier_name,
                        claim_submission_type: e.claim_submission_type,
                        credit_memo: e.credit_memo,
                        posting_date: e.posting_date,
                        billing_date: e.billing_date,
                        billing_doc_number: e.billing_doc_number,
                        billing_doc_line: e.billing_doc_line,
                        net_value: e.net_value,
                        calendar_year_month: e.calendar_year_month,
                        fiscal_year_month: e.fiscal_year_month,
                        customer_classification: e.customer_classification,
                        customer_group: e.customer_group,
                        customer_industry: e.customer_industry,
                        material_group: e.material_group,
                        product_hierarchy: e.product_hierarchy,
                        order_reason: e.order_reason,
                        delivery_tracking_number: e.delivery_tracking_number,
                        maximum_quantity_allowed: e.maximum_quantity_allowed,
                        mfr_part_number: e.mfr_part_number,
                        original_invoice_for_return: e.original_invoice_for_return,
                        original_invoice_line_for_return: e.original_invoice_line_for_return,
                        billing_doc_cancelled: e.billing_doc_cancelled,
                        cancelled_billing_doc: e.cancelled_billing_doc,
                        document_category: e.document_category,
                        profit_center: e.profit_center,
                        cost_center: e.cost_center,
                        plant: e.plant,
                        supplier_customer_number: e.supplier_customer_number,
                        supplier_group: e.supplier_group,
                        flex_attribute01: e.flex_attribute01,
                        flex_attribute02: e.flex_attribute02,
                        flex_attribute03: e.flex_attribute03,
                        flex_attribute04: e.flex_attribute04,
                        flex_attribute05: e.flex_attribute05,
                        flex_key_figure01: e.flex_key_figure01,
                        flex_key_figure02: e.flex_key_figure02,
                        flex_key_figure03: e.flex_key_figure03,
                        flex_key_figure04: e.flex_key_figure04,
                        flex_key_figure05: e.flex_key_figure05,
                        unit_price: e.unit_price,
                        customer_number: e.customer_number,
                        customer_name: e.customer_name,
                        transaction_type: e.transaction_type,
                        created_by: e.created_by,
                        changed_by: e.changed_by,
                        supplier_contract_number: e.supplier_contract_number,
                        supplier_acquisition_price: e.supplier_acquisition_price,
                        supplier_contract_price: e.supplier_contract_price,
                        supplier_claim_quantity: e.supplier_claim_quantity,
                        adjusted_amount: e.adjusted_amount,
                        notes: e.notes,
                        resubmit_number: e.resubmit_number,
                        debit_memo: e.debit_memo,
                        supplier_response_received: e.supplier_response_received,
                        supplier_id_type: e.supplier_id_type,
                        supplier_id_number: e.supplier_id_number,
                        membership_owner_id_type: e.membership_owner_id_type,
                        membership_owner_id_number: e.membership_owner_id_number,
                        channel_partner_id: e.channel_partner_id,
                        channel_partner_name: e.channel_partner_name,
                        channel_partner_type: e.channel_partner_type,
                        channel_partner_id_type: e.channel_partner_id_type,
                        channel_partner_id_number: e.channel_partner_id_number,
                        processed: e.processed,
                        created_at: e.created_at,
                        updated_at: e.updated_at,
                        created_on: e.created_on,
                        changed_on: e.changed_on,
                        end_customer_name: e.end_customer_name,
                    });
                })
        setDataRows(tempRows);
    }, [props.outgoingClaimDetailData]);

    const handlePopupClick = (rowData) => {
        setDialogOpen(true);
        setCurrentViewID(rowData.id);
    }

    const columns = [
        {
            field: 'unique_identifier',
            title: 'Unique Identifier',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.unique_identifier ? <div style={{
                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
            }} onClick={() => handlePopupClick(rowData)}>{rowData.unique_identifier}</div> : <div style={{ color: 'white' }}>-</div>

        },
        {
            field: 'claim_number',
            title: 'Claim Number',
            editable: 'never',
            type: 'numeric',
        },
        {
            field: 'claim_date',
            title: 'Claim Date',
            editable: 'never',
            type: 'date',
            render: rowData => rowData.claim_date ? Moment(rowData.claim_date).format('MM/DD/YYYY') : ''
        },
        {
            field: 'pricing_date',
            title: 'Pricing Date',
            type: 'date',
            // editable: 'never',
            render: rowData => rowData.pricing_date ? Moment(rowData.pricing_date).format('MM/DD/YYYY') : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'response_date',
            title: 'Response Date',
            type: 'date',
            editable: 'never',
            render: rowData => rowData.response_date ? Moment(rowData.response_date).format('MM/DD/YYYY') : ''
        },
        {
            field: 'posting_date',
            title: 'Posting Date',
            type: 'date',
            editable: 'never',
            render: rowData => rowData.posting_date ? Moment(rowData.posting_date).format('MM/DD/YYYY') : ''
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.contract_number ? rowData.contract_number : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'acquisition_price',
            title: 'Acquistion Price',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.acquisition_price ? rowData.acquisition_price : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'contract_price',
            title: 'Contract Price',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.contract_price ? rowData.contract_price : <div style={{ color: 'white' }}>-</div>,

        },
        {
            field: 'quantity',
            title: 'Quantity',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.quantity ? rowData.quantity : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'billed_uom',
            title: 'Billed UOM',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'claim_amount',
            title: 'Claim Amount',
            editable: 'never',
            type: 'numeric',

            render: rowData => rowData.claim_amount ? parseFloat(rowData.claim_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
        },
        {
            field: 'supplier_contract_number',
            title: 'Supplier Contract Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_acquisition_price',
            title: 'Supplier Acquisition Price',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_contract_price',
            title: 'Supplier Contract Price',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_claim_quantity',
            title: 'Supplier Claim Quantity',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'base_uom',
            title: 'Base UOM',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'approved_amount',
            title: 'Approved Amount',
            editable: 'never',
            type: 'numeric',
        },
        {
            field: 'adjusted_amount',
            title: 'Adjusted Amount',
            // editable: 'never',
            type: 'numeric',
            render: rowData => rowData.adjusted_amount ? rowData.adjusted_amount : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'adjustment_code',
            title: 'Adjustment Code',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.adjustment_code ? rowData.adjustment_code : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'claim_status',
            title: 'Claim Status',
            type: 'string',
            editable: 'never',

        },
        {
            field: 'rejection_reason',
            title: 'Rejection Reason',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.rejection_reason ? rowData.rejection_reason : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'claim_error',
            title: 'Claim Error',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'notes',
            title: 'Notes',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.notes ? rowData.notes : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'claim_submission_type',
            title: 'Claim Submission Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'resubmit_number',
            title: 'Resubmit Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'debit_memo',
            title: 'Debit Memo',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_response_received',
            title: 'Supplier Response Received',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'credit_memo',
            title: 'Credit Memo',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'claim_per_unit',
            title: 'Claim Per Unit',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_number',
            title: 'Supplier Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_name',
            title: 'Supplier Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'end_customer_id',
            title: 'End Customer ID',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.end_customer_id ? rowData.end_customer_id : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'end_customer_name',
            title: 'End Customer Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'membership_owner_id',
            title: 'Membership Owner ID',
            type: 'string',
            render: rowData => rowData.membership_owner_id ? rowData.membership_owner_id : <div style={{ color: 'white' }}>-</div>

        },
        {
            field: 'membership_owner_name',
            title: 'Membership Owner Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_id_type',
            title: 'Supplier ID Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_id_number',
            title: 'Supplier ID Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'end_customer_id_type',
            title: 'End Customer ID Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'end_customer_id_number',
            title: 'End Customer ID Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'membership_owner_id_type',
            title: 'Membership Owner ID Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'membership_owner_id_number',
            title: 'Membership Owner ID Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
            // editable: 'never',
            render: rowData => rowData.material_number ? rowData.material_number : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'material_description',
            title: 'Material Description',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'material_id_type',
            title: 'Material ID Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'material_id_number',
            title: 'Material ID Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'material_group',
            title: 'Material Group',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'product_hierarchy',
            title: 'Product Hierarchy',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'customer_name',
            title: 'Customer Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'street_address',
            title: 'Street Address',
            editable: 'never',
            type: 'string',

        },
        {
            field: 'city',
            title: 'City',
            editable: 'never',
            type: 'string',

        },
        {
            field: 'region',
            title: 'Region',
            editable: 'never',
            type: 'string',

        },
        {
            field: 'postal_code',
            title: 'Postal Code',
            editable: 'never',
            type: 'number',

        },
        {
            field: 'country',
            title: 'Country',
            editable: 'never',
            type: 'string',

        },
        {
            field: 'billing_date',
            title: 'Billing Date',
            type: 'date',
            editable: 'never',
            render: rowData => rowData.billing_date ? Moment(rowData.billing_date).format('MM/DD/YYYY') : ''
        },
        {
            field: 'billing_doc_number',
            title: 'Billing Doc Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'billing_doc_line',
            title: 'Billing Doc Line',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'net_value',
            title: 'Net Value',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'currency',
            title: 'Currency',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'debit_credit_indicator',
            title: 'Debit Credit Indicator',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'company_code',
            title: 'Company Code',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'sales_org',
            title: 'Sales Org',
            type: 'string',
            editable: 'never',
        },

        {
            field: 'distribution_channel',
            title: 'Distribution Channel',
            type: 'string',
            editable: 'never',
        },

        {
            field: 'division',
            title: 'Division',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'profit_center',
            title: 'Profit Center',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'cost_center',
            title: 'Cost Center',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'class_of_trade',
            title: 'Class Of Trade',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.class_of_trade ? rowData.class_of_trade : ''
        },
        {
            field: 'customer_classification',
            title: 'Customer Classification',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'customer_group',
            title: 'Customer Group',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'customer_industry',
            title: 'Customer Industry',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'delivery_tracking_number',
            title: 'Delivery Tracking Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'maximum_quantity_allowed',
            title: 'Maximum Quantity Allowed',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'customer_number',
            title: 'Customer Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'document_category',
            title: 'Document Category',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'order_reason',
            title: 'Order Reason',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'calendar_year_month',
            title: 'Calendar Year Month',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'fiscal_year_month',
            title: 'Fiscal Year Month',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'plant',
            title: 'Plant',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'channel_partner_id',
            title: 'Channel Partner ID',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'channel_partner_name',
            title: 'Channel Partner Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'channel_partner_type',
            title: 'Channel Partner Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'channel_partner_id_type',
            title: 'Channel Partner ID Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'channel_partner_id_number',
            title: 'Channel Partner ID Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'claim_reason',
            title: 'Claim Reason',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_attribute01',
            title: 'Flex Attribute01',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_attribute02',
            title: 'Flex Attribute02',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_attribute03',
            title: 'Flex Attribute03',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_attribute04',
            title: 'Flex Attribute04',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_attribute05',
            title: 'Flex Attribute05',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'mfr_part_number',
            title: 'Mfr Part Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_key_figure01',
            title: 'Flex Key Figure01',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_key_figure02',
            title: 'Flex Key Figure02',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_key_figure03',
            title: 'Flex Key Figure03',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_key_figure04',
            title: 'Flex Key Figure04',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'flex_key_figure05',
            title: 'Flex Key Figure05',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'unit_price',
            title: 'Unit Price',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_customer_number',
            title: 'Supplier Customer Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'supplier_group',
            title: 'Supplier Group',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'billing_doc_cancelled',
            title: 'Billing Doc Cancelled',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'cancelled_billing_doc',
            title: 'Cancelled Billing Doc',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'original_invoice_for_return',
            title: 'Original Invoice For Return',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'original_invoice_line_for_return',
            title: 'Original Invoice Line For Return',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'transaction_type',
            title: 'Transaction Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'processed',
            title: 'Processed',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'created_at',
            title: 'Created At',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.created_at ? Moment(rowData.created_at).format('MM/DD/YYYY') : ''
        },
        {
            field: 'updated_at',
            title: 'Updated At',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.updated_at ? Moment(rowData.updated_at).format('MM/DD/YYYY') : ''
        },
        {
            field: 'created_by',
            title: 'Created By',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'changed_by',
            title: 'Changed By',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'action_code',
            title: 'Action Code',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'created_on',
            title: 'Created On',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.created_on ? Moment(rowData.created_on).format('MM/DD/YYYY') : ''
        },
        {
            field: 'changed_on',
            title: 'Changed On',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.changed_on ? Moment(rowData.changed_on).format('MM/DD/YYYY') : ''
        },

    ];
    useEffect(() => {
        if (props.tableData && props.tableData.field_label_attributes) {
            Object.values(props.tableData.field_label_attributes).map(item => {
                if (item.display) {
                    filteredData.push(item.key)
                }
                columns.map(e => {
                    if (item.key === e.field) {
                        e.title = item.current
                    }
                })
            })
            filteredData.filter(function (elem, pos) { return filteredData.indexOf(elem) == pos })
        }
    }, [props.tableData, columns])
    const handleEditSingleCell = (item) => {
        var temp = {};
        props.outgoingClaimDetailData.map((item2) => {
            if (item2.ID === item.ID) {
                temp = item2
            }
        })
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider>
                <PopupContainer open={dialogOpen} onClose={(value) => setDialogOpen(value)} outgoingClaimID={currentViewID} />
                <div className={classes.root}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div>
                                <MaterialTable
                                    title={' '}
                                    // editable={true}
                                    icons={tableIcons}
                                    columns={columns.filter(item => filteredData.includes(item.field))}
                                    data={dataRows}
                                    style={{ marginTop: -60 }}
                                    options={{
                                        filtering: true,
                                        search: false,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                        searchFieldStyle: {
                                            fontSize: 13
                                        },
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                                    }}
                                    cellEditable={
                                        secureLocalStorage.getItem("dYtz").collaboration_id != 0 ? false : {
                                            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                return new Promise((resolve, reject) => {
                                                    var currentObject = props.outgoingClaimDetailData.find(item => item.ID === rowData.id);
                                                    currentObject[columnDef.field] = newValue;
                                                    handleEditSingleCell(currentObject)
                                                    setTimeout(resolve, 1000);
                                                });
                                            }
                                        }}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        editOutgoingClaim: (id) => dispatch(editOutgoingClaim(id)),
        updateIncomingClaimData: (id, formData) => dispatch(updateIncomingClaimData(id, formData))
    }
}

const mapStateToProps = state => {
    return {
        outgoingClaimDetailData: state.initialData.outgoingClaimDetailData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMaterial);