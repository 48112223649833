import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import {
    Typography
} from '@mui/material';
import Chart from "react-apexcharts";
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '20px 30px 20px 30px',
        borderRadius: 10
    }
}));

const ClaimCount = props => {
    const classes = useStyles();
    const [series, setSeries] = React.useState([]);
    const [options, setOptions] = React.useState({});
    const [k, setK] = React.useState([]);
    useEffect(() => {
        if (props.paymentDuration && (props.paymentDuration.error || props.paymentDuration.no_error) && props.paymentDuration && Object.keys(props.paymentDuration).length > 0) {
            var noOfCount = 0;
            var noOfCount1 = 0;
            var keys = [];
            if (props.paymentDuration && props.paymentDuration.error && props.paymentDuration.error.length > 0) {
                noOfCount = props.paymentDuration.error.length;
            }
            if (props.paymentDuration && props.paymentDuration.no_error && props.paymentDuration.no_error.length > 0) {
                noOfCount1 = props.paymentDuration.no_error.length;
            }
            if (noOfCount > noOfCount1) {
                props.paymentDuration.error && props.paymentDuration.error.map(item => {
                    keys.push(Moment.utc(item.month).format('MMMM YYYY'))
                })
                setK(keys);
            } else if (noOfCount1 > noOfCount) {
                props.paymentDuration.no_error && props.paymentDuration.no_error.map(item => {
                    keys.push(Moment.utc(item.month).format('MMMM YYYY'))
                })
                setK(keys);
            } else if (noOfCount1 === noOfCount) {
                props.paymentDuration.no_error && props.paymentDuration.no_error.map(item => {
                    keys.push(Moment.utc(item.month).format('MMMM YYYY'))
                })
                setK(keys);
            } else {
                setK([])
            }
        } else {
            setK([]);
            setSeries([])
            setOptions({})
        }

    }, [props.paymentDuration])

    useEffect(() => {
        if (k.length > 0) {
            var sortedDate = k.sort((a, b) => Moment(a) - Moment(b))
            var count = [];
            var count1 = [];
            var countTotal = [];
            var noOfCount = 0;
            var noOfCount1 = 0;
            if (props.paymentDuration && props.paymentDuration.error && props.paymentDuration.error.length > 0) {
                noOfCount = props.paymentDuration.error.length;
            }
            if (props.paymentDuration && props.paymentDuration.no_error && props.paymentDuration.no_error.length > 0) {
                noOfCount1 = props.paymentDuration.no_error.length;
            }
            if (props.paymentDuration && Object.keys(props.paymentDuration).length > 0) {

                if (noOfCount > noOfCount1) {
                    props.paymentDuration.error && props.paymentDuration.error.map(item => {
                        count.push(item['claim_value'])
                    })

                    sortedDate.map((date, index) => {
                        var value = findValue(date, props.paymentDuration.no_error)
                        count[index] = value
                    })

                } else if (noOfCount1 > noOfCount) {
                    props.paymentDuration && props.paymentDuration.no_error && props.paymentDuration.no_error.map(item => {
                        count1.push(item['claim_value'])
                    })
                    sortedDate.map((date, index) => {
                        var value = findValue(date, props.paymentDuration.error)
                        count[index] = value
                    })
                } else if (noOfCount1 === noOfCount1) {
                    props.paymentDuration.error && props.paymentDuration.error.map(item => {
                        count.push(item['claim_value'])
                    })
                    props.paymentDuration.no_error && props.paymentDuration.no_error.map(item => {
                        count1.push(item['claim_value'])
                    })
                }
                if (count1.length === 0 && count.length > 0) {
                    for (var i = 0; i < count.length; i++) {
                        count1.push(0);
                    }
                }
                if (count.length === 0 && count1.length > 0) {
                    for (var i = 0; i < count1.length; i++) {
                        count.push(0);
                    }
                }
                countTotal = count && count.map(function (num, idx) {
                    return num + count1[idx];
                });
                setSeries([{
                    name: 'Claim Lines - Error',
                    data: count
                },
                {
                    name: 'Claim Lines -  No Error',
                    data: count1
                }
                ])
                setOptions(
                    {
                        chart: {
                            type: 'bar',
                            height: 350,
                            stacked: true
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '55%',
                                endingShape: 'rounded'
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        },
                        xaxis: {
                            categories: k,
                        },
                        animations: {
                            enabled: true,
                            easing: 'easeinout',
                            speed: 800,
                            animateGradually: {
                                enabled: true,
                                delay: 150
                            },
                            dynamicAnimation: {
                                enabled: true,
                                speed: 350
                            }
                        },
                        fill: {
                            opacity: 1
                        },
                    }
                )
            } else {
                setSeries([]);
                setOptions({});
            }
        }
    }, [k])
    function findValue(date, data) {
        var x = 0;
        data && data.map(item => {
            if (Moment.utc(item.month).format('MMMM YYYY') === date) {
                x = item['claim_value'];
            }
        })
        return x;
    }

    return (
        <div className={classes.root}>
            <Typography variant="h2" > {'Claim Lines'} {props.paymentDuration && props.paymentDuration.total_claim_count ? <label style={{ fontSize: 14, color: 'grey' }}>  ({props.paymentDuration.total_claim_count})</label> : ''} </Typography>
            <Chart options={options} type="bar" series={series} height={250} />
        </div>
    );
};

ClaimCount.propTypes = {
    className: PropTypes.string
};

export default ClaimCount;