import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Card } from '@mui/material';
import { addCustomerMasterXRefUser, getDefaultValuesCustomer, updateIncomingClaimData } from '../../../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import PopupContainer from '../PopupContainer';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({

    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
    rowData: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px'
    }

}));

const CustomerMaterial = props => {
    const classes = useStyles();
    const [dataRows, setDataRows] = React.useState([]);
    const theme = useTheme();
    useEffect(() => {
        var i;
        var tempRows = [];
        if (props.incomingClaimDetailData && props.incomingClaimDetailData.length > 0)

            props.incomingClaimDetailData.forEach(row => {
                var newArray = []

                keywords.map(item => {
                    Object.entries(row).map(([key, value]) => {
                        if (key == item) {
                            newArray[item] = value
                        }
                    })

                })
                tempRows.push({ ...newArray })
            })

        setDataRows(tempRows);
    }, [props.incomingClaimDetailData]);
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [currentViewID, setCurrentViewID] = React.useState(0);
    const [column, setColumn] = React.useState([]);
    const handlePopupClick = (rowData) => {
        setDialogOpen(true);
        setCurrentViewID(rowData.unique_identifier);
    }
    const keywords = [
        'unique_identifier', 'claim_number', 'channel_partner_claim_number', 'claim_date', 'pricing_date', 'posting_date', 'contract_number', 'acquistion_price',
        'contract_price', 'quantity', 'calculated_amount', 'approved_amount', 'channel_partner_contract_id', 'channel_partner_acquisition_price', 'channel_partner_contract_price', 'channel_partner_quantity',
        'billed_uom', 'claim_amount', 'adjusted_amount', 'adjustment_code', 'claim_status', 'rejection_reason', 'claim_error', 'notes', 'channel_partner_type', 'channel_partner_id',
        'channel_partner_name', 'supplier_id_type', 'supplier_id_number', 'channel_partner_debit_memo', 'claim_submission_type', 'resubmit_number', 'credit_memo', ' claim_per_unit',
        'base_uom', 'customer_number', 'channel_partner_end_customer_id', 'channel_partner_end_customer_name', 'end_customer_id', 'end_customer_name', 'membership_owner_id', 'membership_owner_name',
        'channel_partner_id_type', 'channel_partner_id_number', 'end_customer_id_type', 'end_customer_id_number', 'membership_owner_id_type', 'membership_owner_id_number', 'material_number',
        'material_description', 'material_id_type', 'material_id_number', 'channel_partner_material', 'product_hierarchy', 'customer_name', 'street_address', 'city', 'region', 'postal_code', 'country'
        , 'billing_date', 'billing_doc_number', 'billing_doc_line', 'net_value', 'currency', 'debit_credit_indicator', 'company_code', 'sales_org', 'distribution_channel', 'division', 'profit_center',
        'cost_center', 'class_of_trade', 'customer_classification', 'customer_group', 'customer_industry', 'delivery_tracking_number', 'maximum_quantity_allowed',
        'flex_attribute1', 'flex_attribute2', 'flex_attribute3', 'flex_attribute4', 'flex_attribute5', 'plant', 'resale_date', 'flex_key_figure1', 'flex_key_figure2', 'flex_key_figure3', 'flex_key_figure4', 'flex_key_figure5', 'unit_price', 'billing_doc_cancelled', 'cancelled_billing_doc', 'original_invoice_for_return',
        'original_invoice_line_for_return', 'calendar_year_month', 'fiscal_year_month', 'transaction_type', 'processed', 'createdAt', 'updatedAt', 'created_by', 'changed_by',
    ]
    const editableLines = [
        'end_customer_id',
        'pricing_date',
        'contract_number',
        'acquistion_price',
        'contract_price',
        'quantity',
        'approved_amount',
        'adjusted_amount',
        'adjustment_code',
        'rejection_reason',
        'notes',
        'membership_owner_id',
        'material_number',
    ]
    useEffect(() => {
        var heads = []
        keywords.map(item => {
            heads.push({
                field: item,
                title: item.replace(/_/g, ' '),
                editable: editableLines.includes(item) ? 'update' : 'never',
                render: rowData => item == 'unique_identifier' ? (rowData.unique_identifier ? <div style={{
                    color: 'blue', textDecoration: 'underline', cursor: 'pointer'
                }} onClick={() => handlePopupClick(rowData)}>{rowData.unique_identifier}</div> : <div style={{ color: 'white' }}>-</div>)
                    :
                    rowData[item] ? Moment(rowData[item], Moment.ISO_8601, true).isValid() && rowData[item].toString().charAt(rowData[item].length - 1) === 'Z' ? Moment(rowData[item]).format('MM/DD/YYYY') :
                        <div className={classes.rowData}>{rowData[item]}</div> : ''
            })
        })
        setColumn(heads)

    }, [])

    const handleEditSingleCell = (item) => {
        var temp = {};
        props.incomingClaimDetailData.map((item2) => {
            if (item2.ID === item.ID) {
                temp = item2
            }
        })
        props.updateIncomingClaimData(temp);
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider>
                <PopupContainer open={dialogOpen} onClose={(value) => setDialogOpen(value)} incomingClaimID={currentViewID} />
                <div className={classes.root}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div>
                                <MaterialTable
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    columns={column}
                                    data={dataRows}
                                    style={{ marginTop: -60 }}
                                    options={{
                                        filtering: true,
                                        search: false,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                        searchFieldStyle: {
                                            fontSize: 13
                                        },
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                                    }}
                                    cellEditable={{
                                        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                            return new Promise((resolve, reject) => {
                                                var currentObject = props.incomingClaimDetailData.find(item => item.ID === rowData.id);
                                                currentObject[columnDef.field] = newValue;
                                                handleEditSingleCell(currentObject)
                                                setTimeout(resolve, 1000);
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        updateIncomingClaimData: (id, formData) => dispatch(updateIncomingClaimData(id, formData))
    }
}

const mapStateToProps = state => {
    return {
        incomingClaimDetailData: state.initialData.incomingClaimDetailData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMaterial);