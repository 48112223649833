import Palette from '../palette';

export default {
    "root": {
        "&$selected": {
            "backgroundColor": Palette.tabs.tabActive,
            "color": Palette.tabs.text,
            "&:hover": {
                "backgroundColor": Palette.tabs.tabActive,
                "color": Palette.tabs.text
            },
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7
        },
    }
}