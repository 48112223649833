
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, TablePagination,
    Button, IconButton, Fab
} from '@mui/material';

import { AddBoxOutlined, Edit, DeleteForever } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createClaimError, getClaimError } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { LibraryAdd, CloudUpload } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const ClaimErrorMain = props => {
    useEffect(() => {
        props.getAllUom(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [currentId, setCurrentID] = React.useState('');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAllUom(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAllUom(1, event.target.value);
    };
    function editUom(id, rejectionReasonCode) {
        history.push({
            pathname: '/rejection-reasons/edit-rejection/' + id,
            id: id,
            state: rejectionReasonCode
        });
    }
    function addeditUom(id) {
        history.push({
            pathname: '/rejection-reasons/add-edit-rejection/' + id,
            id: id
        });
    }
    const deleteData = (item) => {
        props.deleteData(item.rejection_reason_code, 'delete', currentId)
    }

    const [uomAll, setUoMAll] = React.useState([]);
    useEffect(() => {
        if (props.uomAll) {
            setUoMAll(props.uomAll)
            setCurrentID(props.uomAll[0]?.id)
        }
    }, [props.uomAll])

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Rejection Reasons </Typography>
                    {uomAll && uomAll.length > 0 && uomAll[0]['rejection_reason'] != null ?

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={() => addeditUom(currentId)}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                        :

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={() => history.push('/rejection-reasons/add-rejection')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                    }
                </div>
                {uomAll && uomAll.length > 0 && uomAll[0]['rejection_reason'] != null ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Rejection Reason Code</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Rejection Reason Description</TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Claim Error</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {uomAll[0]['rejection_reason'].slice().sort((a, b) => a.rejection_reason_code.localeCompare(b.rejection_reason_code))?.map((item) => {
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.rejection_reason_code}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.rejection_reason_description}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.claim_error}</TableCell>
                                            <TableCell align='center' >
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => editUom(currentId, item.rejection_reason_code)}
                                                    size="large">
                                                    <Edit color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => deleteData(item)}
                                                    size="large">
                                                    <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })
                                }
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            component="div"
                            count={props.uomAll && props.uomAll.length > 0 && props.uomAll[0]['rejection_reason']?.length ? props.uomAll[0]['rejection_reason']?.length : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        uomAll: state.calculationAnalysisData.claimError,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUom: (pagination, limit) => dispatch(getClaimError(pagination, limit)),
        deleteData: (data, type, id) => dispatch(createClaimError(data, type, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimErrorMain);