import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Typography,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { LabelText } from '../../../../components/Inputs';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        marginBottom: 16, padding: '16px 25px 0px 25px'
    },
    tableContainer: {
        display: 'flex'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 10
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);
const GroupingIdentifiers = props => {
    const classes = useStyles();
    const { className } = props;
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [customerMasterData, setCustomerMasterData] = React.useState([]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        setCustomerMasterData(props.masdata);
    }, [props.masdata]);

    return (
        <div className={clsx(classes.root, className)}>
            {customerMasterData &&
                <div className={classes.container}>
                    <Grid container className={classes.gridContainer}>

                        {customerMasterData.customer_chain &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText heading={fieldLabel['customer_chain'] ? fieldLabel['customer_chain']['current'] : 'Customer Chain'} classes={{ root: classes.formLabel }} data={customerMasterData.customer_chain} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.customer_hierarchy &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_hierarchy'] ? fieldLabel['customer_hierarchy']['current'] : 'Customer Hierarchy'} data={customerMasterData.customer_hierarchy} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.customer_group &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_group'] ? fieldLabel['customer_group']['current'] : 'Customer Group'} data={customerMasterData.customer_group} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.customer_group1 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_group1'] ? fieldLabel['customer_group1']['current'] : 'Customer Group 1'} data={customerMasterData.customer_group1} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.customer_group2 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_group2'] ? fieldLabel['customer_group2']['current'] : 'Customer Group 2'} data={customerMasterData.customer_group2} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.customer_group3 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_group3'] ? fieldLabel['customer_group3']['current'] : 'Customer Group 3'} data={customerMasterData.customer_group3} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.customer_group4 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_group4'] ? fieldLabel['customer_group4']['current'] : 'Customer Group 4'} data={customerMasterData.customer_group4} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.customer_group5 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_group5'] ? fieldLabel['customer_group5']['current'] : 'Customer Group 5'} data={customerMasterData.customer_group5} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.id_number01 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'Id Number 1'} data={customerMasterData.id_number01} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.id_number02 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'Id Number 2'} data={customerMasterData.id_number02} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.id_number03 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'Id Number 3'} data={customerMasterData.id_number03} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.id_number04 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'Id Number 4'} data={customerMasterData.id_number04} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.id_number05 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'Id Number 5'} data={customerMasterData.id_number05} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.id_number06 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'Id Number 6'} data={customerMasterData.id_number06} twoline='true' />
                            </Grid>
                        }
                        {/* {customerMasterData.flex_attribute3 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] :'Flex Attribute 3'} data={customerMasterData.flex_attribute3} twoline='true' />
                            </Grid>
                        }
                        {customerMasterData.flex_attribute4 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] :'Flex Attribute 4'} data={customerMasterData.flex_attribute4} twoline='true' />
                            </Grid>
                        } */}
                    </Grid>
                    {/* <Grid
                        item
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        {customerMasterData && customerMasterData.customer_id_type && customerMasterData.customer_id_number.length > 0 ?
                            <TableContainer className={classes.tableContainer}>
                                <Table className={classes.table}>
                                    <TableHead >
                                        <TableRow >
                                            <TableCell align='center' className={classes.tabHead}>{fieldLabel['customer_id_type'] ? fieldLabel['customer_id_type']['current'] : "customer_id_type"}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {customerMasterData.customer_id_type.map((item) => {
                                            return (
                                                <StyledTableRow>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}> {item}</TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                <Table className={classes.table}>
                                    <TableHead >
                                        <TableRow >
                                            <TableCell align='center' className={classes.tabHead}> {fieldLabel['customer_id_number'] ? fieldLabel['customer_id_number']['current'] : 'Customer Identifier Number'}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {customerMasterData.customer_id_number.map((item) => {
                                            return (
                                                <StyledTableRow>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}> {item}</TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <Typography variant='h4'>
                            </Typography>
                        }
                    </Grid> */}
                </div>
            }
        </div>
    );

}

const mapStateToProps = state => {
    return {
        // customerMasterData: state.customerData.customerMasterViewData
    }
}

export default connect(mapStateToProps, null)(GroupingIdentifiers);