import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid,
    Select,
    MenuItem,
    FormLabel, OutlinedInput,
} from '@mui/material';
import { TextInputWithLabel, LabelText } from '../../../../components/Inputs';
import clsx from 'clsx';
import { AllOtherAttributeMasterComponentNew } from "../../../../components/Inputs";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    gridContainer: {
        padding: '0px 45px 0px 45px !important'
    },
    inputTwoLine: {
        marginTop: 16,
        width: '100%',
        paddingLeft: 10,
        height: "35px",
        marginBottom: "15px"
    },
}));
const GeneralData = props => {
    const classes = useStyles();
    //variable declarations
    const { className } = props;
    const [materialMasterData, setMaterialMasterData] = React.useState({});
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [materialDescription, setMaterialDescription] = React.useState('');
    const [materialType, setMaterialType] = React.useState('');
    const [supplier, setSupplier] = React.useState('');
    const [supplierMaterialNumber, setSupplierMaterialNumber] = React.useState('');
    const [baseUom, setBaseUom] = React.useState('');
    const [externalMaterialGroup, setExternalMaterialGroup] = React.useState('');
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [active, setActive] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [productHierarchy, setProductHierarchy] = React.useState('');
    const [materialGroup, setMaterialGroup] = React.useState('');
    const [materialGroup1, setMaterialGroup1] = React.useState('');
    const [materialGroup2, setMaterialGroup2] = React.useState('');
    const [materialGroup3, setMaterialGroup3] = React.useState('');
    const [materialGroup4, setMaterialGroup4] = React.useState('');
    const [materialGroup5, setMaterialGroup5] = React.useState('');
    const [idNumber1, setIdNumber1] = React.useState('');
    const [idNumber2, setIdNumber2] = React.useState('');
    const [idNumber3, setIdNumber3] = React.useState('');
    const [idNumber4, setIdNumber4] = React.useState('');
    const [idNumber5, setIdNumber5] = React.useState('');
    const [idNumber6, setIdNumber6] = React.useState('');
    const [cost, setCost] = React.useState('');
    const [msrp, setMsrp] = React.useState('');
    const [pricingInventory, setPricingInventory] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    useEffect(() => {
        props.onChange(formData);
    });

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);

    useEffect(() => {
        if (props.materialMasterData) {
            setMaterialMasterData(props.materialMasterData)
            setMaterialNumber(props.materialMasterData.material_number)
            setMaterialDescription(props.materialMasterData.material_description)
            setMaterialType(props.materialMasterData.material_type)
            setBaseUom(props.materialMasterData.base_uom)
            setExternalMaterialGroup(props.materialMasterData.external_material_group)
            setSupplierMaterialNumber(props.materialMasterData.supplier_material_number)
            setSupplier(props.materialMasterData.supplier_name)
            setActive(props.materialMasterData.material_status)
            setSupplierNumber(props.materialMasterData.supplier_number)
            setProductHierarchy(props.materialMasterData.product_hierarchy)
            setMaterialGroup(props.materialMasterData.material_group)
            setMaterialGroup1(props.materialMasterData.material_group1)
            setMaterialGroup2(props.materialMasterData.material_group2)
            setMaterialGroup3(props.materialMasterData.material_group3)
            setMaterialGroup4(props.materialMasterData.material_group4)
            setMaterialGroup5(props.materialMasterData.material_group5)
            setIdNumber1(props.materialMasterData.id_number01);
            setIdNumber2(props.materialMasterData.id_number02);
            setIdNumber3(props.materialMasterData.id_number03);
            setIdNumber4(props.materialMasterData.id_number04);
            setIdNumber5(props.materialMasterData.id_number05);
            setIdNumber6(props.materialMasterData.id_number06);
            setCost(props.materialMasterData.cost)
            setMsrp(props.materialMasterData.msrp)
            setPricingInventory(props.materialMasterData.pricing_inventory_alert_qty
            )
            setFlexAttribute1(props.materialMasterData.flex_attribute1)
            setFlexAttribute2(props.materialMasterData.flex_attribute2)
            setFlexAttribute3(props.materialMasterData.flex_attribute3)
            setFlexAttribute4(props.materialMasterData.flex_attribute4)
        }
    }, [props.materialMasterData]);
    var formData = {
        material_number: materialNumber,
        material_description: materialDescription,
        material_type: materialType,
        supplier_name: supplier,
        supplier_material_number: supplierMaterialNumber,
        base_uom: baseUom,
        external_material_group: externalMaterialGroup,
        supplier_number: supplierNumber,
        material_status: active,
        product_hierarchy: productHierarchy,
        material_group: materialGroup,
        material_group1: materialGroup1,
        material_group2: materialGroup2,
        material_group3: materialGroup3,
        material_group4: materialGroup4,
        material_group5: materialGroup5,
        id_number01: idNumber1,
        id_number02: idNumber2,
        id_number03: idNumber3,
        id_number04: idNumber4,
        id_number05: idNumber5,
        id_number06: idNumber6,
        cost: parseFloat(cost),
        msrp: parseFloat(msrp),
        pricing_inventory_alert_qty: parseFloat(pricingInventory),
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2,
        flex_attribute3: flexAttribute3,
        flex_attribute4: flexAttribute4
    }
    //functions 
    function handleMaterialNumber(newValue) {
        setMaterialNumber(newValue);
    }
    function handleMaterialDescription(newValue) {
        setMaterialDescription(newValue);
    }
    function handleMaterialType(newValue) {
        if (newValue && newValue.length > 0) {
            setMaterialType(newValue);
        } else setMaterialType("");
        props.onChange(formData);
    }
    function handleSupplier(newValue) {
        setSupplier(newValue);
    }
    function handleSupplierMaterialNumber(newValue) {
        setSupplierMaterialNumber(newValue);
    }
    function handleBaseUom(newValue) {
        setBaseUom(newValue);
    }
    function handletExternalMaterialGroup(newValue) {
        setExternalMaterialGroup(newValue);
    }
    function handleActive(event) {
        setActive(event.target.value);
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber(newValue);
    }
    function handleProductHierarchy(newValue) {
        setProductHierarchy(newValue);
    }
    function handleMaterialGroup(newValue) {
        setMaterialGroup(newValue);
    }
    function handleMaterialGroup1(newValue) {
        setMaterialGroup1(newValue);
    }
    function handleMaterialGroup2(newValue) {
        setMaterialGroup2(newValue);
    }
    function handleMaterialGroup3(newValue) {
        setMaterialGroup3(newValue);
    }
    function handleMaterialGroup4(newValue) {
        setMaterialGroup4(newValue);
    }
    function handleMaterialGroup5(newValue) {
        setMaterialGroup5(newValue);
    }
    function handleIdNumber1(newValue) {
        setIdNumber1(newValue);
    }
    function handleIdNumber2(newValue) {
        setIdNumber2(newValue);
    }
    function handleIdNumber3(newValue) {
        setIdNumber3(newValue);
    }
    function handleIdNumber4(newValue) {
        setIdNumber4(newValue);
    }
    function handleIdNumber5(newValue) {
        setIdNumber5(newValue);
    }
    function handleIdNumber6(newValue) {
        setIdNumber6(newValue);
    }
    function handleCost(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setCost(e.target.value);
        }
    }
    function handleMsrp(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setMsrp(e.target.value);
        }
    }
    function handlePricingInventory(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setPricingInventory(e.target.value);
        }
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue);
    }
    function handleFlexAttribute3(newValue) {
        setFlexAttribute3(newValue);
    }
    function handleFlexAttribute4(newValue) {
        setFlexAttribute4(newValue);
    }
    return (
        <div>
            <div className={classes.root}>
                {materialMasterData &&
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <CardHeader
                                title="HEADER FIELDS"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <div className={classes.container}>
                                <Grid container
                                    style={{ marginBottom: 16, marginTop: "10px", padding: '0px 25px 0px 25px' }}
                                    spacing={2}
                                >
                                    {fieldLabel['material_number'] && fieldLabel['material_number']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required>
                                                {fieldLabel['material_number'] ? fieldLabel['material_number']['current'] : 'Material Number'}
                                            </FormLabel>
                                            <AllOtherAttributeMasterComponentNew
                                                page={'masterData'}
                                                withOutLabel
                                                attributeName="material_number"
                                                value="materialNumber"
                                                onChange={handleMaterialNumber}
                                                prevalue={materialMasterData.material_number}
                                                twoline='true'
                                            />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_description'] && fieldLabel['material_description']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required>
                                                {fieldLabel['material_description'] ? fieldLabel['material_description']['current'] : 'Material Description'}
                                            </FormLabel>
                                            <AllOtherAttributeMasterComponentNew
                                                page={'masterData'}
                                                withOutLabel
                                                attributeName="material_description"
                                                value="materialDescription"
                                                onChange={handleMaterialDescription}
                                                prevalue={materialMasterData.material_description}
                                                twoline='true'
                                            />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_type'] && fieldLabel['material_type']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            // classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: -10 }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['material_type'] ? fieldLabel['material_type']['mandatory'] : false} style={{ marginTop: '11.5px' }}>{fieldLabel['material_type'] ? fieldLabel['material_type']['current'] : 'Material Type'}</FormLabel>
                                            <AllOtherAttributeMasterComponentNew
                                                page={'masterData'}
                                                twoline='true'
                                                withOutLabel
                                                attributeName="material_type"
                                                value={materialType}
                                                onChange={handleMaterialType}
                                                prevalue={materialMasterData.material_type} />
                                        </Grid>
                                    )}
                                    {fieldLabel['supplier_name'] && fieldLabel['supplier_name']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['mandatory'] : false}>
                                                {fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['current'] : 'Supplier Name'}
                                            </FormLabel>

                                            <AllOtherAttributeMasterComponentNew
                                                page={'masterData'}
                                                twoline='true'
                                                withOutLabel
                                                attributeName="supllier_name"
                                                value={supplier}
                                                onChange={handleSupplier} prevalue={materialMasterData.supplier_name} />
                                        </Grid>
                                    )}
                                    {fieldLabel['supplier_material_number'] && fieldLabel['supplier_material_number']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_material_number'] ? fieldLabel['supplier_material_number']['mandatory'] : false} >
                                                {fieldLabel['supplier_material_number'] ? fieldLabel['supplier_material_number']['current'] : 'Supplier Material Number'}
                                            </FormLabel>

                                            <AllOtherAttributeMasterComponentNew
                                                page={'masterData'}
                                                twoline='true'
                                                withOutLabel
                                                attributeName="supllier_material_number"
                                                value={supplierMaterialNumber}
                                                onChange={handleSupplierMaterialNumber} prevalue={materialMasterData.supplier_material_number} />
                                        </Grid>
                                    )}
                                    {fieldLabel['base_uom'] && fieldLabel['base_uom']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['base_uom'] ? fieldLabel['base_uom']['mandatory'] : false} >
                                                {fieldLabel['base_uom'] ? fieldLabel['base_uom']['current'] : 'Base UoM'}
                                            </FormLabel>


                                            <AllOtherAttributeMasterComponentNew
                                                page={'masterData'}
                                                twoline='true'
                                                withOutLabel
                                                attributeName="base_uom"
                                                value={baseUom}
                                                onChange={handleBaseUom} prevalue={materialMasterData.base_uom} />
                                        </Grid>
                                    )}
                                    {fieldLabel['external_material_group'] && fieldLabel['external_material_group']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['external_material_group'] ? fieldLabel['external_material_group']['mandatory'] : false} >
                                                {fieldLabel['external_material_group'] ? fieldLabel['external_material_group']['current'] : 'External Material Group'}
                                            </FormLabel>

                                            <AllOtherAttributeMasterComponentNew
                                                page={'masterData'}
                                                twoline='true'
                                                withOutLabel
                                                attributeName="external_material_group"
                                                value={externalMaterialGroup}
                                                onChange={handletExternalMaterialGroup} prevalue={materialMasterData.external_material_group} />
                                        </Grid>
                                    )}
                                    {/* <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                   // classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} twoline='true' onChange={handleFlexAttribute1} prevalue={materialMasterData.flex_attribute1} />
                                </Grid>
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                   // classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} twoline='true' onChange={handleFlexAttribute2} prevalue={materialMasterData.flex_attribute2} />
                                </Grid> */}
                                    {fieldLabel['material_status'] && fieldLabel['material_status']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            //// classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 0 }}
                                        >
                                            <FormLabel required={fieldLabel['material_status'] ? fieldLabel['material_status']['mandatory'] : false} classes={{ root: classes.formLabel }}>
                                                {fieldLabel['material_status'] ? fieldLabel['material_status']['current'] : 'Material Status'}
                                            </FormLabel>
                                            <Select
                                                value={active}
                                                onChange={handleActive}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value={"Active"}>
                                                    Active
                                                </MenuItem>
                                                <MenuItem value={"Inactive"}>
                                                    Inactive
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                    )}
                                    {fieldLabel['supplier_number'] && fieldLabel['supplier_number']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            // classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: '-0.1rem' }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_number'] ? fieldLabel['supplier_number']['mandatory'] : false}>
                                                {fieldLabel['supplier_number'] ? fieldLabel['supplier_number']['current'] : 'Supplier Number'}
                                            </FormLabel>
                                            <AllOtherAttributeMasterComponentNew
                                                twoline='true'
                                                withOutLabel
                                                attributeName="supplier_number"
                                                value={supplierNumber}
                                                onChange={handleSupplierNumber} prevalue={materialMasterData.supplier_number} />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                }
            </div>
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="GROUPINGS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2} style={{ marginTop: "10px", padding: '0px 25px 0px 25px' }}>
                                    {fieldLabel['product_hierarchy'] && fieldLabel['product_hierarchy']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['product_hierarchy'] ? fieldLabel['product_hierarchy']['mandatory'] : false}>
                                                {fieldLabel['product_hierarchy'] ? fieldLabel['product_hierarchy']['current'] : 'Product Hierarchy'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleProductHierarchy} prevalue={materialMasterData.product_hierarchy} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group'] && fieldLabel['material_group']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['material_group'] ? fieldLabel['material_group']['mandatory'] : false} >
                                                {fieldLabel['material_group'] ? fieldLabel['material_group']['current'] : 'Material Group'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleMaterialGroup} prevalue={materialMasterData.material_group} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group1'] && fieldLabel['material_group1']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['material_group1'] ? fieldLabel['material_group1']['mandatory'] : false}>
                                                {fieldLabel['material_group1'] ? fieldLabel['material_group1']['current'] : 'Material Group 1'}

                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleMaterialGroup1} prevalue={materialMasterData.material_group1} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group2'] && fieldLabel['material_group2']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['material_group2'] ? fieldLabel['material_group2']['mandatory'] : false} >
                                                {fieldLabel['material_group2'] ? fieldLabel['material_group2']['current'] : 'Material Group 2'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleMaterialGroup2} prevalue={materialMasterData.material_group2} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group3'] && fieldLabel['material_group3']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['material_group3'] ? fieldLabel['material_group3']['mandatory'] : false}>
                                                {fieldLabel['material_group3'] ? fieldLabel['material_group3']['current'] : 'Material Group 3'}

                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleMaterialGroup3} prevalue={materialMasterData.material_group3} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group4'] && fieldLabel['material_group4']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['material_group4'] ? fieldLabel['material_group4']['mandatory'] : false} >
                                                {fieldLabel['material_group4'] ? fieldLabel['material_group4']['current'] : 'Material Group 4'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleMaterialGroup4} prevalue={materialMasterData.material_group4} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group5'] && fieldLabel['material_group5']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['material_group5'] ? fieldLabel['material_group5']['mandatory'] : false} >
                                                {fieldLabel['material_group5'] ? fieldLabel['material_group5']['current'] : 'Material Group 5'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleMaterialGroup5} prevalue={materialMasterData.material_group5} />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div >
            </div >
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IDENTIFIERS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2} style={{ marginTop: "10px", padding: '0px 25px 0px 25px' }}>
                                    {fieldLabel['id_number01'] && fieldLabel['id_number01']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number01'] ? fieldLabel['id_number01']['mandatory'] : false}>
                                                {fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'ID Number 1'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleIdNumber1} prevalue={materialMasterData.id_number01} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number02'] && fieldLabel['id_number02']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number02'] ? fieldLabel['id_number02']['mandatory'] : false}>
                                                {fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'ID Number 2'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleIdNumber2} prevalue={materialMasterData.id_number02} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number03'] && fieldLabel['id_number03']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['id_number03'] ? fieldLabel['id_number03']['mandatory'] : false}  >
                                                {fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'ID Number 3'}
                                            </FormLabel>
                                            <TextInputWithLabel
                                                twoline='true' onChange={handleIdNumber3} prevalue={materialMasterData.id_number03} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number04'] && fieldLabel['id_number04']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['id_number04'] ? fieldLabel['id_number04']['mandatory'] : false}  >
                                                {fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'ID Number 4'}
                                            </FormLabel>
                                            <TextInputWithLabel
                                                twoline='true' onChange={handleIdNumber4} prevalue={materialMasterData.id_number04} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number05'] && fieldLabel['id_number05']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['id_number05'] ? fieldLabel['id_number05']['mandatory'] : false}>
                                                {fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'ID Number 5'}
                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleIdNumber5} prevalue={materialMasterData.id_number05} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number06'] && fieldLabel['id_number06']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel classes={{ root: classes.formLabel }}
                                                required={fieldLabel['id_number06'] ? fieldLabel['id_number06']['mandatory'] : false} >
                                                {fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'ID Number 6'}

                                            </FormLabel>
                                            <TextInputWithLabel twoline='true' onChange={handleIdNumber6} prevalue={materialMasterData.id_number06} />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div >
            </div >
            {fieldLabel && ((fieldLabel['cost'] && fieldLabel['cost']['display']) || (fieldLabel['msrp'] && fieldLabel['msrp']['display']) || (fieldLabel['pricing_inventory_alert_qty'] && fieldLabel['pricing_inventory_alert_qty']['display']) || (fieldLabel['flex_attribute1'] && fieldLabel['flex_attribute1']['display']) || (fieldLabel['flex_attribute2'] && fieldLabel['flex_attribute2']['display']) || (fieldLabel['flex_attribute3'] && fieldLabel['flex_attribute3']['display']) || (fieldLabel['flex_attribute4'] && fieldLabel['flex_attribute4']['display'])) && (
                <div>
                    <div className={clsx(classes.root, className)}>
                        <Card>
                            <form
                                autoComplete="off"
                                noValidate
                            >
                                <div className={classes.container}>
                                    <CardHeader
                                        title="IMA360 DATA"
                                        titleTypographyProps={{ variant: 'h3' }}

                                    />
                                    <Grid container spacing={2} style={{ marginTop: "10px", padding: '0px 25px 0px 25px' }}>
                                        {fieldLabel['cost'] && fieldLabel['cost']['display'] && (
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            >
                                                <FormLabel required={fieldLabel['cost'] ? fieldLabel['cost']['mandatory'] : false} className={classes.formLabel}>
                                                    {fieldLabel['cost'] ? fieldLabel['cost']['current'] : 'Cost'}
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={cost}
                                                    inputProps={{ type: "number", step: "1", min: "0" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleCost} />
                                            </Grid>
                                        )}
                                        {fieldLabel['msrp'] && fieldLabel['msrp']['display'] && (
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            >
                                                <FormLabel required={fieldLabel['msrp'] ? fieldLabel['msrp']['mandatory'] : false} className={classes.formLabel}>
                                                    {fieldLabel['msrp'] ? fieldLabel['msrp']['current'] : 'MSRP'}
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={msrp}
                                                    inputProps={{ type: "number", step: "1", min: "0" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleMsrp} />
                                            </Grid>
                                        )}
                                        {fieldLabel['pricing_inventory_alert_qty'] && fieldLabel['pricing_inventory_alert_qty']['display'] && (
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            >
                                                <FormLabel required={fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['mandatory'] : false} className={classes.formLabel}>
                                                    {fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['current'] : 'Pricing Inventory Alert Qty'}
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={pricingInventory}
                                                    inputProps={{ type: "number", step: "1", min: "0" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handlePricingInventory}
                                                />
                                            </Grid>
                                        )}
                                        {fieldLabel['flex_attribute1'] && fieldLabel['flex_attribute1']['display'] && (
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            // style={{ marginTop: -10 }}
                                            // classes={{ root: classes.gridContainer }}
                                            >
                                                <FormLabel classes={{ root: classes.formLabel }}
                                                    required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false}>
                                                    {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}

                                                </FormLabel>

                                                <TextInputWithLabel twoline='true' onChange={handleFlexAttribute1} prevalue={materialMasterData.flex_attribute1} />
                                            </Grid>
                                        )}
                                        {fieldLabel['flex_attribute2'] && fieldLabel['flex_attribute2']['display'] && (
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            // classes={{ root: classes.gridContainer }}
                                            >
                                                <FormLabel classes={{ root: classes.formLabel }}
                                                    required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false} >
                                                    {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}

                                                </FormLabel>
                                                <TextInputWithLabel twoline='true' onChange={handleFlexAttribute2} prevalue={materialMasterData.flex_attribute2} />
                                            </Grid>
                                        )}
                                        {fieldLabel['flex_attribute3'] && fieldLabel['flex_attribute3']['display'] && (
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            // classes={{ root: classes.gridContainer }}
                                            >
                                                <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['mandatory'] : false}>
                                                    {fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'}
                                                </FormLabel>
                                                <TextInputWithLabel twoline='true' onChange={handleFlexAttribute3} prevalue={materialMasterData.flex_attribute3} />
                                            </Grid>
                                        )}
                                        {fieldLabel['flex_attribute4'] && fieldLabel['flex_attribute4']['display'] && (
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={12}
                                            // classes={{ root: classes.gridContainer }}
                                            >
                                                <FormLabel classes={{ root: classes.formLabel }}
                                                    required={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['mandatory'] : false}>
                                                    {fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'}

                                                </FormLabel>
                                                <TextInputWithLabel twoline='true' onChange={handleFlexAttribute4} prevalue={materialMasterData.flex_attribute4} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </form>
                        </Card>
                    </div >
                </div >
            )}
        </div>
    );
};
const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.materialMasterViewData
    }
};
export default connect(mapStateToProps, null, null)(GeneralData);