import React, { useRef, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addCotXRefUser, getMembershipData } from '../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
}));

const AddCotXRef = props => {
    const classes = useStyles();
    const [materialStore, setMaterialStore] = React.useState([]);
    const [membershipOwnerId, setMembershipOwnerId] = React.useState('');
    const [membershipOwnerName, setMembershipOwnerName] = React.useState('');
    const [membershipOwnerClassofTrade, setMembershipOwnerClassofTrade] = React.useState('');
    const [imaClassOfTrade, setImaClassOfTrade] = React.useState('');
    const [imaClassOfTradeDescription, setImaClassOfTradeDescription] = React.useState('');
    const [membershipOwnerNameArray, setMembershipOwnerNameArray] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getMembership();
    }, []);
    useEffect(() => {
        var newArray = []
        if (props.membershipData && props.membershipData.records && props.membershipData.records.length > 0) {
            props.membershipData.records.map((item) => {
                newArray.push(item.membership_owner_id + '-' + item.membership_owner_name)
            })
        }
        setMembershipOwnerNameArray(newArray);
    }, [props.membershipData]);
    // function handleMembershipOwnerId(event) {
    //     var split = event.split('-');
    //     var first = split[0];
    //     var last = split[split.length - 1];
    //     setMembershipOwnerId(event);
    //     setMembershipOwnerName(last)

    // }
    function handleMembershipOwnerId(event) {
        setMembershipOwnerId(event.target.value);
    }
    function handleMembershipOwnerName(event) {
        setMembershipOwnerName(event.target.value);
    }
    function handleMembershipOwnerClassofTrade(event) {
        setMembershipOwnerClassofTrade(event.target.value);
    }
    function handleImaClassOfTradeDescription(event) {
        setImaClassOfTradeDescription(event.target.value);
    }
    function handleImaClassOfTrade(event) {
        setImaClassOfTrade(event.target.value);
    }
    function handleClear() {
        setMembershipOwnerId('');
        setMembershipOwnerName('');
        setMembershipOwnerClassofTrade('');
        setImaClassOfTrade('');
        setImaClassOfTradeDescription('');
    }

    function handleOnSubmit() {
        var split = membershipOwnerId.split('-');
        var first = split[0];
        var formData = {
            "channel_partner_id": first,
            "channel_partner_name": membershipOwnerName,
            "channel_partner_class_of_trade": membershipOwnerClassofTrade,
            "ima_class_of_trade": imaClassOfTrade,
            "ima_class_of_trade_description": imaClassOfTradeDescription,
        }
        props.onSubmit(formData);
    }
    function handleOnClear() {
        setMaterialStore([]);
        handleClear();
    }


    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/cot-xref'
                >
                    COT XREF
                </Link>
                <Typography color="textPrimary" variant='h4'>Add COT XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={membershipOwnerId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMembershipOwnerId} />
                                    </div>
                                </Grid>
                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner ID
                                        </FormLabel>
                                        <Select
                                            value={membershipOwnerId}
                                            onChange={(e) => handleMembershipOwnerId(e.target.value)}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {membershipOwnerNameArray && membershipOwnerNameArray.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid> */}
                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Channel Partner Name
                                        </FormLabel>
                                        <OutlinedInput
                                            disabled={'disabled'}
                                            value={membershipOwnerName}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMembershipOwnerName} />
                                    </div>
                                </Grid> */}
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner Class of Trade
                                        </FormLabel>
                                        <OutlinedInput
                                            value={membershipOwnerClassofTrade}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMembershipOwnerClassofTrade} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA Class of Trade
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaClassOfTrade}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleImaClassOfTrade} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA Class of Trade Description
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaClassOfTradeDescription}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleImaClassOfTradeDescription} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={membershipOwnerClassofTrade && membershipOwnerId &&
                            imaClassOfTrade && imaClassOfTradeDescription ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addCotXRefUser(data)),
        getMembership: () => dispatch(getMembershipData()),
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
        membershipData: state.customerData.membershipData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCotXRef);