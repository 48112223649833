import React, { useState, useEffect } from 'react';
import {
    Grid, Button, FormLabel, Select, MenuItem, Input, ListItemText,
    Checkbox, OutlinedInput, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown, MultiSelectDropdownKeyVal, TextInputWithLabel } from '../../../components/Inputs';
import { ChipInput } from '../../../components/Inputs';
import { connect } from 'react-redux';
import Moment from 'moment';
import momentt from 'moment-timezone';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        //width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    chargeBackLabel: {
        paddingTop: 15,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: 15
    },
    spinner: {
        height: '100vh'
    },
    heading: {
        padding: '10px 5px',
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 30,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    inputTwoLineOutlined: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 36,
    },
}));



const UpdateField = props => {

    const classes = useStyles();
    const { className } = props;
    const [filterData, setFilterData] = React.useState({});
    const [attributes, setAttributes] = React.useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);

    const tierPeriodArray = ['Current Period', 'Previous Period', 'Previous 12 months'];
    const tierGrowthPeriodArray = ['Previous Year Same Period', 'Previous Period'];


    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        console.log(selectedIndex)
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === props.attributeArray.length ? [] : props.attributeArray.map((item, i) => item.field_id));
            setAllSelect(selected.length === props.attributeArray.length ? false : true)
            setSelectedDetail(selected.length === props.attributeArray.length ? [] : props.attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === props.attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
        //setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
        //setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [level]: selected })
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        setSelectedDetail([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(props.attributeArray && props.attributeArray.length > 0 && attributes.length === props.attributeArray.length)
        setSelectedDetail(attributesData)
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const handleFilterData = (data, item, type) => {

        if (item.type == 'TIME') {
            setFilterData({ ...filterData, [item.field_id]: Moment.utc(data).format('MM/DD/YYYY') })
        }
        if (data && data.length > 0) {
            setFilterData({ ...filterData, [item.field_id]: data })
        }
        if (item.type == 'NUMERIC') {
            setFilterData({ ...filterData, [item.field_id]: Number(data) })
        }
        else {
            setFilterData({ ...filterData, [item.field_id]: data })
        }
        props.onChange(filterData)
    }
    sessionStorage.setItem('updatedFields', JSON.stringify(filterData))
    return (
        <div className={classes.container}>
            <Grid container style={{ marginBottom: 20 }}>
                <Grid
                    item
                    md={12}
                    xs={12}
                    classes={{ root: classes.gridContainer }}
                    style={{ display: 'flex' }}
                >
                    <FormLabel style={{ color: 'grey', marginRight: 20, paddingTop: 5 }}>
                        Select Required Fields
                    </FormLabel>
                    <Select
                        labelId="demo-mutiple-name-label"
                        id={'filter-list'}
                        multiple
                        value={attributes ? attributes : []}
                        style={{ color: 'grey', maxHeight: "50px", width: '70%' }}
                        input={<OutlinedInput />}
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        onOpen={() => handleOpen()}
                        onClose={() => setSelectOpen(false)}
                        open={selectOpen}
                        renderValue={(filter) => {
                            let type = []
                            filter.map(itemX => {
                                props.attributeArray.filter(item => item.field_id == itemX).map((item, i) => {
                                    if (item.name)
                                        type.push(item.name)
                                })

                            })
                            return type.join(',')
                        }}
                        className={classes.multiSelect}

                    >
                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                            <Checkbox
                                color='primary'
                                checked={allSelect} />
                            <ListItemText primary='Select All' />
                        </MenuItem>
                        {props.attributeArray && props.attributeArray.length > 0 && props.attributeArray
                            .sort()
                            .map((item, i) => {
                                return (

                                    <MenuItem onClick={(event) => handleSelectValues(event, item.field_id, item)} value={item.field_id} key={item.field_id} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={selected && selected.indexOf(item.field_id) > -1}
                                        />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                )
                            })}
                        <MenuItem value='' ></MenuItem>

                        <div className={classes.dropdownAction}>
                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                Cancel
                            </Button>

                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                Apply
                            </Button>
                        </div>
                    </Select>
                </Grid>
            </Grid>
            <Grid container >
                {attributesData &&
                    attributesData.map((item, i) => {
                        return (
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {item.field_id == 'tier_evaluation_level' || item.field_id == 'quota_level' ?
                                    <MultiSelectDropdownKeyVal heading={item.name} listArray={item.drop_down_value_keys} data={filterData[item.field_id] ? filterData[item.field_id] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                    :
                                    item.type == 'DROPDOWN.STRING' ?
                                        <MultiSelectDropdownKeyVal capitalize={true} heading={item.name} listArray={item.drop_down_value_keys} data={filterData[item.field_id] ? filterData[item.field_id] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                        :
                                        item.type == 'BOOL' ?
                                            <div style={{ paddingTop: 15 }}>
                                                <FormLabel required>
                                                    {item.name}
                                                </FormLabel>
                                                <Select
                                                    value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                    onChange={(e) => handleFilterData(e.target.value, item)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value={'true'} key={'true'}>
                                                        Yes
                                                    </MenuItem>
                                                    <MenuItem value={'false'} key={'false'}>
                                                        No
                                                    </MenuItem>
                                                </Select>
                                            </div>
                                            :
                                            item.type == 'TIME' ?
                                                <div style={{ paddingTop: 15 }}>
                                                    <FormLabel>
                                                        {item.name}
                                                    </FormLabel>
                                                    <div style={{ display: 'flex' }}>
                                                        {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <Grid container justify="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 0 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        marginTop: 10,
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%',
                                                                                        marginRight: 5
                                                                                    }
                                                                                }}
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                                onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider> */}
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            marginTop: 10,
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                    onChange={(e) => handleFilterData(e, item, 'end_date')}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                                :
                                                item.type == "DROPDOWN.SINGLE" ?
                                                    <div style={{ paddingTop: 15 }}>
                                                        <FormLabel required>
                                                            {item.name}
                                                        </FormLabel>
                                                        <Select
                                                            value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                            onChange={(e) => handleFilterData(e.target.value, item)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {item.drop_down_value_keys && Object.values(item.drop_down_value_keys).length > 0 && Object.values(item.drop_down_value_keys)
                                                                .map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.key} key={item} >
                                                                            {item.desc}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </div>
                                                    :

                                                    item.type == 'NUMERIC' ?
                                                        <div style={{ paddingTop: 15 }}>
                                                            <FormLabel>
                                                                {item.name}
                                                            </FormLabel>
                                                            <OutlinedInput
                                                                type={'number'}
                                                                classes={{ root: classes.inputTwoLineOutlined }}
                                                                value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                                onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                            />
                                                        </div>
                                                        :
                                                        <div style={{ paddingTop: 15, marginTop: '0.6rem' }}>
                                                            <FormLabel>
                                                                {item.name}
                                                            </FormLabel>
                                                            <ChipInput data={filterData[item.field_id] ? filterData[item.field_id] : []} onChange={(e) => handleFilterData(e, item)} />
                                                        </div>
                                    // <>
                                    //     <TextInputWithLabel heading={item.name} twoline='true' onChange={(e) => handleFilterData(e, item)} />
                                    // </>
                                }
                            </Grid>
                        );
                    })
                }
            </Grid>
        </div >
    );

};


export default connect(null, null)(UpdateField);