import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,

    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {getPerformanceMetricFormatFields, addPerformanceMetricFormatData } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const PerformancemetricAddQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const requiredFields=[
        //'product_group_id','product_group_name','material_number'
    ]
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    //newfields
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        props.getPerformanceMetricFormatFields();
    }, []);
    function handleOnSubmit() {
        delete queryObject['id'];
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === "") {
                delete queryObject[propName];
            }
        }
        props.onSubmit(queryObject);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if(propName !='format_name')
            {
                 queryObject[propName] =''
            }
        }
        setQueryObject({ ...queryObject });
    }
    function handleCancelDialog() {
        history.push('/performance-metric/performance-metric-excel-upload');
    }
    const handleChange = (event, item) => {
        setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/performance-metric/performance-metric-excel-upload'
                >
                   Performance Metric Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.queryListData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Field Name</TableCell>
                                                        <TableCell align='center' style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.queryListData
                                                        .filter(item => item != 'format_name' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    { requiredFields.includes(item)? <p>{item.replace(/_/g, ' ')}<span style={{color: 'red'}}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                           
                                                                    </TableCell>
                                                                    <TableCell style={{ width: 70 }}>
                                                                        <TextField
                                                                           value={queryObject[item]}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                    /* disabled={!materialNumber || !productGroupId ||!productGroupName? true:false}*/>
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addPerformanceMetricFormatData(data)),
        getPerformanceMetricFormatFields: () => dispatch(getPerformanceMetricFormatFields()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        queryListData: state.performanceMetricData.performanceMetricFormatFieldData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformancemetricAddQuery);