import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";

const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [
    { 'Dashboard': '/dashboard' },
    {
        'Incentive Plan': [
            { 'Incentive Plan Setup': '/contract-setup' },
            { 'Incentive Plan Approval': '/approval-reject' },
            { 'Expiring Contracts': '/expiring-contracts' },
            { 'Expired Contracts': '/expired-contracts' },
            { "Document Management": "/documents" },
        ]
    },
    // {
    //     'Claims Management': [
    //         { 'Create Claims': '/outgoing-claims-post' },
    //         { 'Manage Single Claim': '/customer-outgoing-claims' },
    //         { 'Manage Multiple Claims': '/customer-outgoing-disputed-claims' },
    //         { 'Claim Status': '/claim-status' },
    //     ]
    // },
    {
        'Calculation Management': [
            { 'Formula Lab': '/formula-lab' },
            { 'Exception Management': '/exception-management' },
            { 'Quota Management': '/quota-management' },
            { 'Performance Metric': '/performance-metric' },
            { 'Performance Weightage': '/performance-metric-weightage' },
            { 'Calculation Simulation': '/calculation-simulation' },
            { "Calculation Analysis": "/contract-trends" },
            { "Next Tier Simulation": "/next-tier-simulation" },
        ]
    },
    {
        'Accruals': [
            { 'Accrual Postings': '/financial-postings' },
            { 'Accrual Approvals': '/accrual-approvals' }
        ]
    },
    {
        'Payments': [
            { 'Payment Postings': '/payment-postings' },
            { 'Payment Approvals': '/payment-approvals' },
            { 'Partner Statement': '/partner-statement' }
        ]
    },

    {
        'Reporting & Analytics': [
            { 'On Demand Query': '/dynamic-query' },
            { 'Operational Reports': '/select-operational-reports' },
            { 'Analytics': '/analytics' },
            { "Predefined Analytics": "/analytics-main" },
        ]
    },
    {
        'Utilities': [
            { 'Batch Job': '/batchjob-setup' },
            { 'Batch Job Status': '/batchjob-status' },
            { "On Demand Videos": "/help-desk-cp" },
        ]
    }
]

const CustomerChargeback = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerChargeback);