import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput, FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { updateCustomerMastrXrefFormatData, getCustomerMastrXrefFormatFields, getCustomerMasterQuery, getCustomerMastrXrefFormatDataById } from '../../redux/actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },

    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },

    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const DirectSalesExternalCalculationFormatEditQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [queryObject, setQueryObject] = React.useState({});
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [incentiveAmount, setIncentiveAmount] = React.useState('');
    const [quantitys, setQuantitys] = React.useState('');
    const [defaultValue, setDefaultValue] = React.useState({});
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('upload-external-calculation') ? JSON.parse(sessionStorage.getItem('upload-external-calculation')) : []);
    useEffect(() => {
        setOpen(true);
        props.getCustomerMastrXrefFormatFields();
        props.getCustomerMastrXrefFormatDataById(purchaseQueryDataStorage.id);
    }, []);
    useEffect(() => {
        if (props.customerMasterXRefFormatDataById) {
            setQueryName(props.customerMasterXRefFormatDataById.format.format_name)
            if (props.customerMasterXRefFormatDataById.default_values != null) {
                setDefaultValue(props.customerMasterXRefFormatDataById.default_values)
            }
            setQueryObject(props.customerMasterXRefFormatDataById.format)
            setCustomerNumber(props.customerMasterXRefFormatDataById.format.customer_number)
            setMaterialNumber(props.customerMasterXRefFormatDataById.format.material_number)
            setIncentiveAmount(props.customerMasterXRefFormatDataById.format.incentive_amount)
            setQuantitys(props.customerMasterXRefFormatDataById.format.quantity)
        }
    }, [props.customerMasterXRefFormatDataById]);
    const handleDefaultValue = (e) => {
        setDefaultValue(e.target.value);
    }
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName !== 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setDefaultValue({ ...defaultValue });
        setQueryObject({ ...queryObject });
        setCustomerNumber('')
        setMaterialNumber('')
        setIncentiveAmount('')
        setQuantitys('')
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === '' || queryObject[propName] === null || queryObject[propName] === undefined) {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, { ...queryObject, 'format_name': queryName, 'default_values': defaultValue });
    }
    const requiredFields = [
        'quantity', 'material_number',
        'customer_number', 'incentive_amount'
    ]
    var replaceChars = {
        "_": " ",
        "postal_code": "Postal Code",
        "id": "ID",
        "ima": "IMA"
    };
    function handleClearAll() {
        handleClear();
    }
    function handleCancelDialog() {
        history.push('/upload-external-calculation-main');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'material_number') {
            setMaterialNumber(event.target.value);
        }
        if (item === 'customer_number') {
            setCustomerNumber(event.target.value);
        }
        if (item === 'incentive_amount') {
            setIncentiveAmount(event.target.value);
        }
        if (item === 'quantity') {
            setQuantitys(event.target.value);
        }
    };
    const handleChange2 = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
        if (item === 'material_number') {
            setMaterialNumber(event.target.value);
        }
        if (item === 'customer_number') {
            setCustomerNumber(event.target.value);
        }
        if (item === 'incentive_amount') {
            setIncentiveAmount(event.target.value);
        }
        if (item === 'quantity') {
            setQuantitys(event.target.value);
        }
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/upload-external-calculation-main'
                >
                    Direct - Sales External Calculation
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >{props.queryListData ?
                            <div>
                                <div style={{ margin: 15 }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                    <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {props.queryListData
                                                    .filter(item => item !== 'id' && item !== 'format_name' && item !== 'organization_id' && item !== 'updated_by_id' && item !== 'created_by_id' && item !== '')
                                                    .map((item) => {
                                                        return (
                                                            <TableRow key={item}>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    {requiredFields.includes(item) ? <p>{item.replace(/_|postal_code|id|ima/g, function (match) { return replaceChars[match] })}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.replace(/_|postal_code|id|ima/g, function (match) { return replaceChars[match] })}
                                                                </TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                    <TextField
                                                                        value={queryObject[item] ? queryObject[item] : ''}
                                                                        type="number"
                                                                        variant="outlined"
                                                                        onChange={(e) => handleChange(e, item)}
                                                                        defaultValue={JSON.parse(sessionStorage.getItem('upload-external-calculation'))[item] ? JSON.parse(sessionStorage.getItem('upload-external-calculation'))[item] : ''}
                                                                    />
                                                                </TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70, paddingLeft: 10 }}>
                                                                    <TextField
                                                                        value={defaultValue[item]}
                                                                        type="string"
                                                                        variant="outlined"
                                                                        onChange={(e) => handleChange2(e, item)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            :
                            <Typography variant='h4'>
                                There is no data to show now.
                            </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={(customerNumber || defaultValue['customer_number']) && (materialNumber || defaultValue['material_number']) && (incentiveAmount || defaultValue['incentive_amount']) && (quantitys || defaultValue['quantity'])
                            ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateCustomerMastrXrefFormatData(id, data, "DirectSalesExternalCalculationFormat")),
        getCustomerMastrXrefFormatFields: () => dispatch(getCustomerMastrXrefFormatFields('DirectSalesExternalCalculationFormat')),
        // getCustomerMasterQuery: (id) => dispatch(getCustomerMasterQuery(id)),
        getCustomerMastrXrefFormatDataById: (id) => dispatch(getCustomerMastrXrefFormatDataById(id, 'DirectSalesExternalCalculationFormat'))
    }
}
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        queryListData: state.customerData.customerMasterXRefFormatFieldData,
        customerMasterSingleData: state.customerData.customerMasterSingleData,
        customerMasterXRefFormatDataById: state.customerData.customerMasterXRefFormatDataById
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DirectSalesExternalCalculationFormatEditQuery);