import React from 'react';
import {
    Typography, Breadcrumbs,
    Stepper, Step, StepButton, StepConnector, StepLabel,
    Button, Grid
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ReconcilledLines, ErrorLines, LineDetails } from './components';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { addCustomerMasterUser } from '../../redux/actions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 0px 27px 0px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));
const tabs = {
    generalData: 0,
    groupingIdentifiers: 1,
    OrganizationData: 2
};

function getSteps() {
    return ['Reconciled Lines', 'Review', 'Rejected', 'All Lines'];
}

const AddCustomer = props => {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const skipped = new Set()
    const steps = getSteps();
    const history = useHistory();
    const totalSteps = () => {
        return getSteps().length;
    };
    const theme = useTheme();
    const skippedSteps = () => {
        return skipped.size;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleStep = (step) => () => {
        // if (step === tabs.eligibilityRules && (localStorage.getItem('mode') === 'edit')) {
        //     props.onCheckEligibillityRulesTable(localStorage.getItem('currentAddContractID'));
        // } else if (step === tabs.calculationRules && (localStorage.getItem('mode') === 'edit')) {
        //     props.onCheckCalculationRulesTable(localStorage.getItem('currentAddContractID'));
        // }
        setActiveStep(step);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const completedSteps = () => {
        return completed.size;
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/customer-master");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        const allSteps = [
            <ReconcilledLines />,
            <ErrorLines claimStatus={'Review'} />,
            <ErrorLines claimStatus={'Rejected'} />,
            <LineDetails />,
        ];

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    return (
        <div>
            <div className={classes.root}>
                {/* <Breadcrumbs aria-label="breadcrumb" style={{marginLeft: 29}}>
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/supplier-incoming-claims'
                    >
                        Incoming Claims
                </Link> */}
                <Typography color="primary" variant='h1'>Edit Incoming Claims</Typography>
                {/* </Breadcrumbs> */}
                <div className={classes.stepper}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}
                        classes={{ root: classes.stepperContainer }}
                        connector={
                            <StepConnector
                                classes={{
                                    line: classes.connectorLine,
                                    alternativeLabel: classes.connectorAlternativeLabel
                                }}
                            />
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}
                                    classes={{ horizontal: classes.horizontal }}
                                >
                                    <StepButton
                                        onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                        classes={{ root: classes.stepButton }}
                                    >
                                        <StepLabel>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                <Grid container >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        {activeStep !== 0 &&
                            <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                Previous Step
                            </Button>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        {activeStep != tabs.OrganizationData &&
                            <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}>
                                {activeStep === tabs.OrganizationData ? 'Done' : 'Next Step'}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        contractData: state.addContractData.contractDetailData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addCustomerMasterUser(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);