import React, { useEffect } from 'react';
import {
    Typography, Switch, Stack, FormControlLabel, FormGroup, Checkbox,
    InputAdornment, IconButton, TextField, FormControl, ToggleButton, ToggleButtonGroup,
    Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Accordion,
    AccordionSummary,
    AccordionDetails, Chip,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ExpandMore, AddCircleOutline } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getPromoTypes, getMarketFilters, getModels } from '../../../../redux/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../../Styles/custom.css'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        paddingBottom: 16,
        width: '100%',
        paddingLeft: 36, paddingTop: 21
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 15,
    },
    rightContainer: {
        // backgroundColor: '#F8F8F8'
    },
    leftContainer: {
        marginTop: '12px',
        padding: '0px 45px',
        backgroundColor: '#F8F8F8'

    },
    defineContainer: {
        marginTop: '12px',
        // padding: '0px 45px',
        backgroundColor: '#F8F8F8',
        maxHeight: 634,
        height: 634,
        overflowY: 'auto',
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,

    },
    chipContainer: {
        // paddingLeft: 30,
        display: 'flex',
        flexDirection: 'row',
        // paddingBottom: 10,
        backgroundColor: '#F8F8F8',
        padding: '20px 0px 20px 30px !important'
    },
    subContainer: {
        padding: '10px 16px 16px 16px',
        alignItems: 'center',
        //marginLeft: 30,
    },
    iconContainer: {
        marginTop: 40
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },

    searchBox: {
        backgroundColor: theme.palette.white
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #E2E2E2',
        height: '48px',
        borderRadius: '8px',
        marginTop: 10,
        padding: '8px',
        width: 'fit-content',
        marginBottom: 42
    },

    filterItem: {
        display: 'flex',
        marginRight: 12
    },
    filterContainer: {
        marginRight: 12,
        //width: '25%',
        //marginTop: -5,
        display: 'flex',
        flexDirection: 'column'
    },
    rightHeader: {
        width: '100%',
        // justifyContent: 'space-between'
    },
    chip: {
        backgroundColor: 'white',
        border: '1px solid #E2E2E2'
    }
}));
const Market = (props) => {

    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [definedModels, setDefinedModels] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [locations, setLocations] = React.useState([]);

    const [marketExpanded, setMarketExpanded] = React.useState([]);
    const [modelYear, setModelYear] = React.useState('');
    const [displaySelected, setDisplaySelected] = React.useState(true);
    const [selectedModelItems, setSelectedModelItems] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [region, setRegion] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [itemOn, setItemOn] = React.useState('');
    const [regionList, setRegionList] = React.useState([]);
    const [stickerModels, setStickerModels] = React.useState([]);
    const [defaultExpanded, setDefaultExpanded] = React.useState([]);


    useEffect(() => {
        if (props.modelData && props.modelData.length > 0) {
            setModels(props.modelData)
            setDefinedModels(props.modelData)
        }
    }, [props.modelData]);
    useEffect(() => {
        if (props.regions && props.regions.length > 0) {
            setRegionList(props.regions)
            setRegion(props.regions[0])
        }
    }, [props.regions])

    useEffect(() => {
        if (props.locationData && props.locationData.length > 0) {
            var region = props.locationData.flatMap((item, index) => item.children)
            // setRegionList(region.map((item, index) => item.category))
            setLocations(region)
        }
    }, [props.locationData]);
    useEffect(() => {
        if (props.defineMarket) {
            if (props.defineMarket?.models)
                setSelectedModelItems(props.defineMarket.models)
            else {
                setSelectedLocItems(props.defineMarket['location'])
                setRegionList(props.defineMarket.region)
            }
        }
        else {
            setSelectedLocItems([])
            setSelectedModelItems([])
        }
    }, [props.defineMarket])
    useEffect(() => {
        if (props.defineMarketChild) {
            //setSelectedLoc(props.defineMarketChild['location'])
            if (props.defineMarketChild.model_display) {
                setStickerModels(props.defineMarketChild.model_display)
                setDefaultExpanded(props.defineMarketChild.model_display ? Object.keys(props.defineMarketChild.model_display) : [])
            }
            else {
                setSelectedLoc(props.defineMarketChild.location_display)
                setDefaultExpanded(props.defineMarketChild.location_display ? Object.keys(props.defineMarketChild.location_display) : [])

            }
        }
        else {
            setSelectedLoc([])
            setStickerModels([])
            setModelYear([])
        }
    }, [props.defineMarketChild])

    useEffect(() => {
        if (props.modelYears)
            setModelYear(props.modelYears?.[0])
    }, [props.modelYears])

    function handleModelYear(event) {
        setModelYear(event.target.value)
    }
    function handleRegion(event) {
        setRegion(event.target.value)
    }

    function handleDisplay(event) {
        setDisplaySelected(event.target.checked ? false : true);
    };
    function handleClose() {
        setOpen(false)
        setItemOn('')
    }
    function handleSubmit() {
        var loc = [];
        var model = [];
        setOpen(false)
        var key = [...Object.values(itemOn.relationship), ...itemOn.fields?.product_line ? itemOn.fields.product_line : []].join("_").toString()
        if (displaySelected) {
            if (props.customType == 'model grouping') {

                delete stickerModels[key]
                delete selectedModelItems[key]
                props.handleDefineMarket(selectedModelItems, stickerModels)
            }
            else {
                delete selectedLoc[key]
                delete selectedLocItems[key]
                props.handleDefineMarket(selectedLocItems, selectedLoc)
            }
        }
        else {
            if (props.customType == 'model grouping') {
                var model = { ...selectedModelItems, [key]: { ...itemOn.relationship, 'product_line': itemOn.fields?.product_line } }
                var child = { ...stickerModels, [key]: {} }
                setSelectedModelItems(model)
                setStickerModels(child)
                props.handleDefineMarket(model, child)
            }
            else {
                var loc = { ...selectedLocItems, [key]: itemOn.relationship }
                setSelectedLocItems(loc)
                var child = { ...selectedLoc, [key]: {} }
                setSelectedLoc(child)
                props.handleDefineMarket(loc, child)
            }
        }
        setItemOn('')
    }
    function handleChipItem(item) {
        setOpen(true)
        setItemOn(item)
    }


    const handleAccordionChange = (panel, type, relationship) => (event, isExpanded) => {
        if (isExpanded) {
            if (displaySelected) {
                setDefaultExpanded(defaultExpanded.filter(item => item != relationship))
            }
            else {
                setDefaultExpanded([...defaultExpanded, relationship])
            }
        }
        else {
            if (displaySelected) {
                setDefaultExpanded([...defaultExpanded, relationship])

            }
            else {
                setDefaultExpanded(defaultExpanded.filter(item => item != relationship))
            }
        }
        setMarketExpanded(isExpanded ? [...marketExpanded, panel + "-" + type] : marketExpanded.filter(item => item != panel + "-" + type));
    };

    const renderNestedItems = (items) => {

        return items && items.filter((item, index) =>
            props.customType == 'model grouping' ?
                displaySelected ? stickerModels && Object.keys(stickerModels).length > 0 ? Object.keys(stickerModels).some(filterItem => filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`)) : '' :
                    !(stickerModels && Object.keys(stickerModels).includes([...Object.values(item.relationship), ...item.fields?.product_line ? item.fields.product_line : []].join("_").toString())
                        && Object.keys(stickerModels).some(filterItem => filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`)))
                :
                displaySelected ? selectedLoc && Object.keys(selectedLoc).length > 0 ? Object.keys(selectedLoc).some(filterItem => filterItem.startsWith(`${Object.values(item.relationship).join("_")}`)) : '' :
                    !(Object.keys(selectedLoc).includes(`${Object.values(item.relationship).join("_")}`))
        )
            .map((item, index) => {
                var key = [...Object.values(item.relationship), ...item.fields?.product_line ? item.fields.product_line : []].join("_").toString()
                return (
                    <>

                        {item.type == 'material_group1' || item.type == 'region' ?
                            renderNestedItems(item.children)
                            :
                            item.children && item.children.length > 0 ? (
                                <div key={index} style={{ flexGrow: 1, width: '100%' }} >
                                    <Accordion
                                        expanded={displaySelected ? !(defaultExpanded.includes(key)) : defaultExpanded.includes(key)}//marketExpanded.includes(item.category + "-" + item.type)
                                        onChange={handleAccordionChange(item.category, item.type, key)}
                                        style={{ margin: 0, borderBottom: "0.8px solid #E2E2E2", zindex: 1 }}
                                        fullWidth
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore color={marketExpanded.includes(item.category + "-" + item.type) ? 'primary' : 'black'} />}
                                            className={classes.accordionMenu}
                                            style={{ paddingLeft: item.type == 'material_group3' ? 20 : 10 }}
                                        >
                                            <Typography variant='h4'
                                                color={'grey'}
                                            >
                                                {item.category}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ padding: 0,/* display: item.type == 'material_group3' && 'flex':'block', flexDirection: item.type == 'material_group3' && 'row'*/ }}
                                            className={(item.type == 'material_group3') && classes.chipContainer}
                                        >
                                            {renderNestedItems(item.children)}
                                        </AccordionDetails>
                                    </Accordion>
                                </div >
                            ) :
                                displaySelected ?
                                    (<div style={{ paddingLeft: 30, marginRight: 5 }}>
                                        <Chip
                                            label={item.category}
                                            variant="outlined"
                                            className={classes.chip}
                                            sx={{ backgroundColor: 'white' }}
                                            //onClick={handleClick}
                                            onDelete={() => handleChipItem(item)}

                                        />
                                    </div>
                                    )
                                    :
                                    (props.customType == 'model grouping' ? (Object.keys(selectedModelItems).indexOf(key) < 0) :
                                        (Object.keys(selectedLocItems).indexOf(key) < 0)) && (
                                        <div style={{ paddingLeft: 30, marginRight: 5 }}>
                                            <Chip
                                                label={item.category}
                                                variant="outlined"
                                                onDelete={() => handleChipItem(item)}
                                                deleteIcon={<AddCircleOutline />}
                                                sx={{ backgroundColor: 'white' }}
                                            //onClick={handleClick}

                                            />
                                        </div>
                                    )
                        }
                    </>
                )
            });
    };
    return (
        <div className={classes.root}>
            <Grid container item spacing={1}>
                <Grid
                    item xs={12} md={12}
                    className={classes.rightContainer}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                    </div>
                    <div className={classes.rightHeader}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className='filter-box'>
                            <div>
                                {props.customType == 'model grouping' ?
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={modelYear}
                                        exclusive
                                        onChange={handleModelYear}
                                        aria-label="Platform"
                                        style={{ position: 'relative', zindex: -1 }}
                                    >
                                        {props.modelYears && props.modelYears.map(item =>
                                            <ToggleButton value={item}>{item}</ToggleButton>

                                        )
                                        }
                                    </ToggleButtonGroup>
                                    :
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={region}
                                        exclusive
                                        onChange={handleRegion}
                                        aria-label="Platform"
                                        style={{ position: 'relative', zindex: -1 }}
                                    >
                                        {regionList && regionList.map(item =>
                                            <ToggleButton value={item}>{item}</ToggleButton>
                                        )}
                                    </ToggleButtonGroup>
                                }
                            </div>
                            <div>
                                <FormControlLabel
                                    value={displaySelected}
                                    control={<Checkbox checked={displaySelected ? false : true} />}
                                    label={<Typography variant='h4' color='grey'>{props.customType == 'model grouping' ? 'Display Unselected Models' : 'Display Unselected States'}</Typography>}
                                    labelPlacement="end"
                                    onChange={handleDisplay}
                                />
                            </div>

                        </div>

                        <div className={classes.defineContainer} style={{ position: 'relative', zindex: -1, backgroundColor: '#F8F8F8', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', paddingTop: props.customType !== 'model grouping' ? 10 : 0 }} className={props.customType != 'model grouping' && classes.chipContainer}>
                                {
                                    props.customType == 'model grouping' ?
                                        stickerModels && Object.keys(stickerModels).length > 0 &&
                                        renderNestedItems(models.filter((item, index) => item.category == modelYear))
                                        :
                                        selectedLoc && Object.keys(selectedLoc).length > 0 &&
                                        renderNestedItems(locations && locations.filter((item, index) => item.category == region))
                                }
                            </div>
                        </div>
                        {/* {console.log(stickerModels)} */}
                    </div>
                </Grid>
            </Grid >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                    {displaySelected ? 'REMOVE FROM SELECTION ?' : 'ADD TO SELECTION ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Chip
                            label={itemOn.category}
                            variant="outlined"
                            className={classes.chip}
                            sx={{ backgroundColor: 'white' }}

                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleSubmit}>yes</Button>
                </DialogActions>
            </Dialog>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getPromotionTypes: () => dispatch(getPromoTypes()),
        getMarketFilters: (table, data) => dispatch(getMarketFilters(table, data)),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter'))
    }
}

const mapStateToProps = state => {
    return {
        promotionTypes: state.promotionData.promoTypes,
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Market);