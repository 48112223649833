import React, { useEffect } from 'react';
import {
    Typography, FormControl,
    OutlinedInput, Button, Select, MenuItem, InputAdornment
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ErrorOutlineOutlined, NavigateNext, SaveOutlined, SellOutlined, Troubleshoot, LocationOnOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createStickers, getBudgetingConfig, getModels } from '../../../../../redux/actions';
import { Header, DefineMarket } from './Components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { toast } from 'react-toastify';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    },
    container: {
        height: 80,
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '0.8px solid #E2E2E2',
        padding: '23px 32px',
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    link: {
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        cursor: 'pointer'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    subContainer: {
        // backgroundColor: '#F8F8F8',
        display: 'flex',

    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    countryContainer: {
        display: 'flex',
        minWidth: '174px !important',
        padding: '8px 8px 8px 12px',
        alignItems: 'center',
        gap: '12px',
        borderRadius: '4px',
        border: '0.8px solid #E2E2E2',
        background: ' #FFF',
        marginRight: 10
    }
}));

const MarketDefinition = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const { className } = props;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productLine, setProductLine] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([]);
    const [productType, setProductType] = React.useState([]);
    const [modelYear, setModelYear] = React.useState([]);
    const [stickerName, setStickerName] = React.useState('');
    const [sticker, setSticker] = React.useState('');
    const [headerData, setHeaderData] = React.useState({});
    const [defineMarket, setDefineMarket] = React.useState({});
    const [defineMarketChild, setDefineMarketChild] = React.useState({});
    const [saved, setSaved] = React.useState(false);
    const [country, setCountry] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState();
    const countries = ['USA', 'CA'];

    useEffect(() => {
        if (sticker) {
            //var key = props.stickerKeys && props.stickerKeys[sticker] ? props.stickerKeys[sticker]?.name : ''
            setDefineMarket(props.defineMarket && props.defineMarket[sticker] ? props.defineMarket[sticker] : '')
            setDefineMarketChild(props.defineMarketChild && props.defineMarketChild[sticker] ? props.defineMarketChild[sticker] : '')
        }
    }, [props.defineMarket, props.defineMarketChild, sticker]);

    useEffect(() => {
        if (props.sticker) {
            setSticker(props.sticker)
        }
    }, [props.sticker]);

    useEffect(() => {
        if (sticker && props.stickers[sticker]) {
            // var data = props.stickers && props.stickers.filter(item => item.ID == sticker)
            //if (data) {
            setProductType(props.stickers[sticker]['product_lines'])
            setCountry(props.stickers[sticker]['country'])
            var tempArr = []
            props.productLines && props.productLines.length > 0 && props.productLines.forEach(element => {
                if (props.stickers[sticker]['product_lines'] && props.stickers[sticker]['product_lines'].includes(element)) {
                    tempArr.push(element)
                }
            });
            setProductLine(tempArr)
            //}
        }
        else {
            setProductType([])
            // setCountry('')
        }
    }, [sticker]);
    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData[0].promotion_division)
        }
    }, [props.configData]);
    function handleHeaderData(value) {
        if (value == 'return')
            props.onChange('return')
        else
            setHeaderData(value)
        //setCountry(value)

    }
    function handleStickerData(value, type) {
        if (type == 'material_group1') {
            setModelYear(value)
            setDefineMarketChild(prev => ({
                ...prev,
                'model_year': value
            }))
        }
        else
            setStickerData(value)
    }
    function handleSticker(value) {
        setSticker(value)
        setSaved(false)
    }
    function handleCountry(e) {
        setCountry(e.target.value)
        var data = props.stickers && Object.values(props.stickers).filter((item, index) => item.country == e.target.value ? item : '')

        setSticker(data?.[0]?.name)
    }
    function handleApplyGrid(type) {
        console.log(defineMarketChild)
        console.log(defineMarket)
        if (defineMarketChild?.model && Object.keys(defineMarketChild['model']).length == 0 || defineMarketChild?.location && Object.keys(defineMarketChild['location']).length == 0) {
            toast.warn("Incorrect Market. Missing locations or models. Try Again!!")
        }
        var loc = { ...defineMarketChild && defineMarketChild['location'] ? defineMarketChild['location'] : {} }
        var model_len = defineMarket && defineMarket['model'] ? Object.keys(defineMarket['model']).length : 0
        defineMarket && defineMarket['location'] && Object.entries(defineMarket.location).map(([key, value]) => {
            loc = { ...loc, [key]: model_len }
        })
        if (defineMarket && defineMarket['model'] && Object.entries(defineMarket['model']).length > 0) {
            var data = {
                grid_data: { [sticker]: { ...defineMarket/*market, 'product_lines': productLine*/ } },
                display_data: { [sticker]: { ...defineMarketChild, 'location': loc } }
            }

            props.onChange(data, type, sticker)
        }
        else {
            // if (type == 'market')
            props.onChange(null, type, sticker)
        }
        setSaved(true)

    }
    function handleDefineMarket(value, child, type, year) {
        setDefineMarket(prev => ({
            ...prev,
            [type]: value
        }))
        setDefineMarketChild(prev => ({
            ...prev,
            [type]: child,
        }))
        if (year)
            defineMarketChild['model_year'] = year
        setSaved(false)
    }
    return (
        <div className={classes.root}>
            <div>
                <Header onChange={handleHeaderData} stickerName={stickerName}
                    handleBack={props.handleBack} countryList={props.country} country={country}
                />
            </div>
            <div className='border-line'></div>
            <div className={classes.container} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.subContainer}>

                    <Select
                        id={'selected-country'}
                        value={country}
                        onChange={handleCountry}
                        style={{ backgroundColor: 'white', borderRadius: 5 }}
                        className={`${classes.countryContainer} promo-font`}
                        input={<OutlinedInput startAdornment={<InputAdornment position="start"><LocationOnOutlined /></InputAdornment>} />}
                    >
                        {props.country && props.country
                            .map(item =>
                                <MenuItem value={item}>{item == 'CA' ? 'CANADA' : item}</MenuItem>
                            )
                        }
                    </Select>

                    <FormControl variant="outlined" fullWidth>{console.log(props.stickers)}
                        <Select
                            value={sticker}
                            onChange={(e) => handleSticker(e.target.value)}
                            label="Select"
                            input={<OutlinedInput startAdornment={<InputAdornment position="start"><SellOutlined /></InputAdornment>} />}
                            style={{ width: '300px' }}
                        >
                            <MenuItem value={''}></MenuItem>
                            {props.stickers && Object.values(props.stickers).filter((item, index) => item.country == country ? item : '').map((item, index) => (
                                <MenuItem key={item.sticker_description} value={item.name} style={{ display: 'flex' }}>
                                    <CircleIcon style={{ color: props.colorPicker && props.colorPicker[item.name] }} fontSize='12px' />
                                    {/* {(item.promo_variability ? 'PV -' : '') + (item.version > 1 ? `V${item.version} - ${item.sticker_description}` : item.sticker_description)} */}
                                    {item.promo_variability ? `PV - (${item.sticker_name})` : item.sticker_name}
                                </MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ display: 'flex', marginLeft: 46 }}>
                        <ErrorOutlineOutlined /> <Typography variant='h5' color='black' style={{ whiteSpace: 'nowrap' }}>you are currently defining the market {productLine && productLine.length > 0 ? 'for' : ''} {productLine && productLine.join(', ').replace(/,([^,]*)$/, ' and$1')} sticker in {country && country == 'CA' ? 'CANADA' : 'USA'} </Typography>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    {/* <Button variant='Outlined' color='primary' style={{ marginRight: 5 }}> Cancel</Button> */}
                    <Button variant={saved ? 'contained' : 'outlined'} color='primary' onClick={() => handleApplyGrid('save')}
                        disabled={!sticker || (defineMarketChild?.model && Object.keys(defineMarketChild['model']).length == 0 || defineMarketChild?.location && Object.keys(defineMarketChild['location']).length == 0
                        ) ? true : false}
                        style={{ marginRight: 5 }}
                    >
                        {saved ? <><DoneOutlineIcon style={{ marginRight: 2 }} /> Saved</> : 'Save Market'}
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => handleApplyGrid('market')} disabled={!sticker ? true : false}> Access Grid</Button>
                </div>

            </div>
            <DefineMarket onChange={handleStickerData} filterData={props.filters} customGroup={props.customGroup} productLine={productLine}
                defineMarket={sticker && props.defineMarket && props.defineMarket[sticker] ? props.defineMarket[sticker] : defineMarket}
                defineMarketChild={sticker && props.defineMarketChild && props.defineMarketChild[sticker] ? props.defineMarketChild[sticker] : defineMarketChild}
                handleDefineMarket={handleDefineMarket} locationData={props.locations}
                modelYears={props.modelYears} modelData={props.modelData} country={country}
            />

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
        createSticker: (data) => dispatch(createStickers(data, 'create')),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter'))
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketDefinition);


// ['160_2020_Spyde', '160_2020_Spyde_spyde1', '160_2020_Spyde_mat1', '160_2022_Spyde', '160_2022_Spyde_nkm', '160_2020_def',
//     '161_2020_Spyde', '161_2020_Spyde_spyde1', '162_2020_Spyde_mat1', '163_2022_Spyde', '164_2022_Spyde_nkm', '161_2020_def'
// ]