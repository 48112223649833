import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, FormLabel, TextField, Chip,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../../components/Dialog';
import { runProductFeatureCalculate, getMaterialMasterData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
    AutocompleteInput: {
        '&.MuiAutoComplete-inputRoot': {
            padding: '3px !important'
        },
        paddingBottom: 0
    }
}));

const ProductFeatureCalculate = props => {
    const classes = useStyles();
    const { className } = props;
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [materialNumberListArray, setMaterialNumberListArray] = React.useState([]);
    const [materialNumberList, setMaterialNumberList] = React.useState([]);
    const [pricingDate, setPricingDate] = React.useState(null);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getMaterialMasterData()
    }, []);
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
            })
            setMaterialNumberList(tempArray)
            setMaterialNumberListArray(tempNameArray);
        }
    }, [props.materialMasterData])

    function handleMaterialNumber(newValue) {
        //setMaterialNumber([...materialNumber, newValue])
        setMaterialNumber(newValue)
    }
    const handleClear = () => {
        setMaterialNumber([]);
        setPricingDate(null);
    }
    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleDialog = () => {
        var pd = []
        pd.push(Moment.utc(pricingDate).toISOString().split('T')[0])
        const formData = {
            "material_number": materialNumber,
            "pricing_date": pd
        };
        setOpen(false);
        props.onSubmit(formData);
        handleClear();
    }

    const filterOptionsMaterial = createFilterOptions({
        stringify: (option) => materialNumberListArray[materialNumberList.indexOf(option)] + option
    });

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h2">
                        Product Feature Calculation
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Material Number
                                </FormLabel>
                                <Autocomplete
                                    multiple
                                    options={materialNumberList}
                                    getOptionLabel={(option) => option}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" size="small" classes={{ root: classes.chip }} label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" size="small" sx={{ padding: 0, marginBottom: 0 }} />
                                    )}
                                    filterOptions={filterOptionsMaterial}
                                    value={materialNumber}
                                    style={{ marginBottom: 10, marginTop: 10, height: 36 }}
                                    onChange={(event, newValue) => handleMaterialNumber(newValue)}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>{materialNumberListArray[materialNumberList.indexOf(option)] ? materialNumberListArray[materialNumberList.indexOf(option)] : ''} - ({option})  </li>
                                    )}
                                    classes={{
                                        option: {
                                            borderBottom: `1px solid red`,
                                            // Hover
                                            '&[data-focus="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                            // Selected
                                            '&[aria-selected="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                        },
                                        listbox: {
                                            padding: 0,
                                        },
                                        inputRoot: {
                                            borderRadius: '0px !important'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required >Pricing Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '7px 13px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    //marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={pricingDate}
                                            onChange={(e) => { setPricingDate(e) }}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    materialNumber && pricingDate ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Product Feature Calculate?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay >
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        materialMasterData: state.customerData.materialMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runProductFeatureCalculate(formData)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductFeatureCalculate);