import React from 'react';
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
import { BooleanDropdown } from '../../../components/Inputs';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        borderTop: '0.1px solid',
        borderTopColor: theme.palette.border.main,
        paddingTop: 5,
        paddingBottom: 5
    },
    borderBottom: {
        borderBottom: '0.5px solid',
        borderBottomColor: theme.palette.border.main
    },
    dropDown: {
        padding: 3,
        borderRadius: 3
    },
    dropdownContainer: {
        marginRight: 36
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const Selection = (props => {
    const classes = useStyles();
    const [selectValue, setSelectValue] = React.useState(false);

    function handleSelect(newValue) {
        setSelectValue(newValue);
    }

    return (
        <div className={clsx({
            [classes.root]: true,
            [classes.borderBottom]: props.bottom
        })}
        >
            <Typography variant="h5" className={classes.title}>
                {props.title}
            </Typography>
            <div className={classes.dropdownContainer}>
                <BooleanDropdown className={classes.dropDown} onChange={handleSelect} />
            </div>
        </div>
    );

});

export default Selection;