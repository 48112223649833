import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Paper,
    TablePagination
} from '@mui/material';
import { tableIcons } from '../Icons/TableIcons';
import MaterialTable, { MTableHeader } from 'material-table';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    data: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
        alignItems: 'center',
        height: 36,
        display: 'flex'
    },
    tableHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
}));

const LabelText = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    return (
        <div>
            <MaterialTable
                components={{

                    Pagination: props => {
                        props.page && (
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[5, 10, 20]}
                                count={props.count}
                                page={props.page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={props.handleChangePage}

                                onRowsPerPageChange={props.handleChangeRowsPerPage}
                            />
                        )
                    },
                    Toolbar: (props) => (
                        <div
                            style={{
                                height: "0px",
                            }}
                        ></div>
                    )
                }}
                title={' '}
                editable={true}
                icons={tableIcons}
                columns={props.columns}
                data={props.dataRows}

                options={{
                    search: false,
                    sorting: true,
                    emptyRowsWhenPaging: false,
                    maxBodyHeight: '700px',
                    headerStyle: theme.mixins.MaterialHeader,
                    cellStyle: theme.mixins.MaterialCell,
                    filtering: true,

                    pageSize: 50,
                    pageSizeOptions: [
                        15,
                        20,
                        50,
                        { value: props.dataRows.length, label: "Show all" },
                    ],
                }}
            />
        </div>
    );

};

export default LabelText;
