import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    FormLabel,
    Grid,
} from '@mui/material';
import { TextInputWithLabel } from '../../../../components/Inputs';
import { getDefaultValuesCustomer, getAttributeListData, getCustomerMasterViewDetails } from '../../../../redux/actions';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    selectedItemDropdown: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 9
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        padding: '0px 45px 0px 45px !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 10
    },
}));

const GeneralData = props => {
    const classes = useStyles();
    const [customerMasterData, setCustomerMasterData] = React.useState({})
    const [claimResponseExcel, setClaimResponseExcel] = React.useState('');
    const [claimResponseEDI, setClaimResponseEDI] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    useEffect(() => {
        props.onLoadingDefault();
    }, []);

    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        if (props.customerMasterData) {
            setCustomerMasterData(props.customerMasterData)
            setClaimResponseExcel(props.customerMasterData.claim_reponse_excel_format);
            setClaimResponseEDI(props.customerMasterData.claim_reponse_edi_format);
            setEmailAddress(props.customerMasterData.email_address);
            setFlexAttribute1(props.customerMasterData.flex_attribute_1);
            setFlexAttribute2(props.customerMasterData.flex_attribute_2);
            setFlexAttribute3(props.customerMasterData.flex_attribute_3);
            setFlexAttribute4(props.customerMasterData.flex_attribute_4);
        }

    }, [props.customerMasterData]);
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    var formData = {
        claim_reponse_excel_format: claimResponseExcel,
        claim_reponse_edi_format: claimResponseEDI,
        email_address: emailAddress,
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2,
        flex_attribute3: flexAttribute3,
        flex_attribute4: flexAttribute4,
    }
    //functions 
    function handleClaimResponseExcel(newValue) {
        setClaimResponseExcel(newValue);
        props.onChange(formData);
    }
    function handleClaimResponseEDI(newValue) {
        setClaimResponseEDI(newValue);
        props.onChange(formData);
    }
    function handleEmailAddress(newValue) {
        setEmailAddress(newValue);
        props.onChange(formData);
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue);
        props.onChange(formData);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue);
        props.onChange(formData);
    }
    function handleFlexAttribute3(newValue) {
        setFlexAttribute3(newValue);
    }
    function handleFlexAttribute4(newValue) {
        setFlexAttribute4(newValue);
    }
    return (
        <div className={classes.root}>
            {customerMasterData &&
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <CardHeader
                                title="IMA360 DATA"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <Grid container
                                style={{ marginBottom: 16, margin: 10 }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}>
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['email_address'] ? fieldLabel['email_address']['mandatory'] : false}
                                    >
                                        {fieldLabel['email_address'] ? fieldLabel['email_address']['current'] : 'Email Address'}
                                    </FormLabel>
                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleEmailAddress}
                                        prevalue={customerMasterData.email_address} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}>
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['claim_reponse_excel_format'] ? fieldLabel['claim_reponse_excel_format']['mandatory'] : false}
                                    >
                                        {fieldLabel['claim_reponse_excel_format'] ? fieldLabel['claim_reponse_excel_format']['current'] : 'Claim Reponse Excel Format'}
                                    </FormLabel>

                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleClaimResponseExcel}
                                        prevalue={customerMasterData.claim_reponse_excel_format} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}>
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['claim_reponse_edi_format'] ? fieldLabel['claim_reponse_edi_format']['mandatory'] : false}
                                    >
                                        {fieldLabel['claim_reponse_edi_format'] ? fieldLabel['claim_reponse_edi_format']['current'] : 'Claim Response EDI Format'}

                                    </FormLabel>
                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleClaimResponseEDI}
                                        prevalue={customerMasterData.claim_reponse_edi_format} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    // style={{ marginTop: '-4px' }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        // style={{ marginTop: '5px' }}
                                        required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false}
                                    >
                                        {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}
                                    </FormLabel>

                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleFlexAttribute1}
                                        prevalue={customerMasterData.flex_attribute1} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        style={{ marginTop: '5px' }}
                                        required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false}
                                    >
                                        {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}
                                    </FormLabel>

                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleFlexAttribute2}
                                        prevalue={customerMasterData.flex_attribute2} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        style={{ marginTop: '5px' }}
                                        required={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['mandatory'] : false}
                                    >
                                        {fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'}
                                    </FormLabel>

                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleFlexAttribute3}
                                        prevalue={customerMasterData.flex_attribute3} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        style={{ marginTop: '5px' }}
                                        required={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['mandatory'] : false}>
                                        {fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'}
                                    </FormLabel>

                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleFlexAttribute4}
                                        prevalue={customerMasterData.flex_attribute4} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        customerMasterData: state.customerData.customerMasterViewData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesCustomer()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null)(GeneralData);