import React, { useEffect } from 'react';
import Moment from 'moment';
import clsx from 'clsx';
import { makeStyles, useTheme } from "@mui/styles";
import {
    Grid, Select, FormLabel, MenuItem, Button, OutlinedInput,
    Typography, Checkbox, ListItemText, Input, Radio, RadioGroup,
    FormControlLabel, TextField
} from '@mui/material';
import { connect } from 'react-redux';
import {
    getSupplierMasterData, claimErrorCount,
    getDashboardCount, runDisputedClaim, getMaterialMasterData, getCustomerMasterData,
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
        marginTop: 5,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    container: {
        padding: 10
    },
    contractCellContainer: {
        [theme.breakpoints.down('lg')]: {
            padding: '0px 16px 18px 16px !important'
        }
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    tableFontSetting: {
        fontSize: theme.typography.h4.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        padding: '0.7rem'
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));

const ClaimStatusSearch = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,
    }
    const [applicatioType, setApplicationType] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [listOpenMaterial, setListOpenMaterial] = React.useState('');
    const [selectedMaterial, setSelectedMaterial] = React.useState([]);
    const [listOpenCustomer, setListOpenCustomer] = React.useState('');
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [customerNameArray, seCustomerNameArray] = React.useState([]);
    const [materialNameArray, setMaterialNameArray] = React.useState([]);
    const [statLevel, setStatLevel] = React.useState([]);
    const [statLevel2, setStatLevel2] = React.useState('');
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [supplierNameArray, seSupplierNameArray] = React.useState([]);
    const [supplierMasterArrayMaterialNumber, setSupplierMasterArrayMaterialNumber] = React.useState([]);
    const [listOpenSupplier, setListOpenSupplier] = React.useState('');
    const [selectedSupplier, setSelectedSupplier] = React.useState([]);
    const [open3, setOpen3] = React.useState('');
    const startLevelArray = {
        "customer_number": "Customer Number",
        "material_number": "Material Number",
        "supplier_number": "Supplier Number"
    }
    const [selected3, setSelected3] = React.useState([]);
    const isAllSelected3 = startLevelArray && Object.entries(startLevelArray).length > 0 && selected3.length === Object.entries(startLevelArray).length;
    const [customerMasterArrayMaterialNumber, setCustomerMasterArrayMaterialNumber] = React.useState([]);
    const [materialMasterArrayMaterialNumber, setMaterialMasterArrayMaterialNumber] = React.useState([]);
    const [viewBy, setViewBy] = React.useState('');
    useEffect(() => {
        setDashboardCountData(props.dashboardCountData);
    }, [props.dashboardCountData])
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setSupplierMasterArrayMaterialNumber(tempArray)
            seSupplierNameArray(tempNameArray)
        }
    }, [props.supplierMasterData])
    useEffect(() => {
        if (application === 'Customer Chargeback') {
            setApplicationType('customer_chargeback')
        } else {
            setApplicationType('supplier_chargeback')
        }
        props.getSupplierMasterData();
        props.getCustomerMasterData();
        props.getMaterialMasterData();
    }, [])
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerMasterArrayMaterialNumber(tempArray)
            seCustomerNameArray(tempNameArray)
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
            })
            setMaterialMasterArrayMaterialNumber(tempArray)
            setMaterialNameArray(tempNameArray)
        }
    }, [props.materialMasterData])
    const handleMaterialNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedMaterial.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedMaterial, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedMaterial.slice(1));
        } else if (selectedIndex === selectedMaterial.length - 1) {
            newSelected = newSelected.concat(selectedMaterial.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedMaterial.slice(0, selectedIndex),
                selectedMaterial.slice(selectedIndex + 1),
            );
        }
        setSelectedMaterial(newSelected)
    };
    const handleCustomerNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedCustomer.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCustomer, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCustomer.slice(1));
        } else if (selectedIndex === selectedCustomer.length - 1) {
            newSelected = newSelected.concat(selectedCustomer.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCustomer.slice(0, selectedIndex),
                selectedCustomer.slice(selectedIndex + 1),
            );
        }
        setSelectedCustomer(newSelected)
    };
    function handleOpenMaterial() {
        setSelectedMaterial(materialNumber)
        setListOpenMaterial(true)
    }
    function handleOpenCustomer() {
        setSelectedCustomer(customerNumber)
        setListOpenCustomer(true)
    }
    function onAdd(item) {
        if (item === 'material') {
            setMaterialNumber(selectedMaterial)
            setListOpenMaterial(false)
        } else if (item === 'customer') {
            setCustomerNumber(selectedCustomer)
            setListOpenCustomer(false)
        } else {
            setDashboardCountData({});
            setSupplierNumber(selectedSupplier)
            setListOpenSupplier(false)
        }
    }
    function onCancel(item) {
        if (item === 'material') {
            setListOpenMaterial(false)
            setSelectedMaterial([])
        } else if (item === 'customer') {
            setListOpenCustomer(false)
            setSelectedCustomer([])
        } else {
            setListOpenSupplier(false)
            setSelectedSupplier([])
        }
    }
    const handleStartDate = (e) => {
        setDashboardCountData({});
        setStartDate(e);
        var date = 'start_date=' + Moment(e).format('MM/DD/YYYY') + '&end_date=' + Moment(endDate).format('MM/DD/YYYY')
    }
    const handleEndDate = (e) => {
        setDashboardCountData({});
        setEndDate(e);
        var date = 'start_date=' + Moment(startDate).format('MM/DD/YYYY') + '&end_date=' + Moment(e).format('MM/DD/YYYY')
    }
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    var application = sessionStorage.getItem('application');

    const handleSubmit = () => {
        setApplyPressed(true);
        var data = {
            "start_date": Moment.utc(startDate).toISOString().split('T')[0].concat('T00:00:00.000Z'),
            "end_date": Moment.utc(endDate).toISOString().split('T')[0].concat('T00:00:00.000Z'),
            "supplier_number": supplierNumber,
            "stat_level": statLevel.length > 0 ? statLevel.toString() : statLevel2,
            "application_type": applicatioType,
            "material_number": materialNumber,
            "customer_number": customerNumber,
        }
        var sd = Moment(startDate).format('MM/DD/YYYY');
        var ed = Moment(endDate).format('MM/DD/YYYY');
        props.loadDashboardCount(data, viewBy, null, 'claim Status', 'customer');
        sessionStorage.setItem('stat_level', statLevel)
        sessionStorage.setItem('filterData', JSON.stringify(statLevel))
        sessionStorage.setItem('stat_level', statLevel2)
        sessionStorage.setItem('start_date1', startDate)
        sessionStorage.setItem('end_date1', endDate)
        handleClear()
    }
    const handleClear = () => {
        setStartDate(null)
        setEndDate(null)
        setSupplierNumber([])
        setStatLevel([])
        setStatLevel2('')
        setMaterialNumber([])
        setCustomerNumber([])
    }
    function handleOpenSupplier() {
        setSelectedSupplier(supplierNumber)
        setListOpenSupplier(true)
    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected3.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected3, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected3.slice(1));
        } else if (selectedIndex === selected3.length - 1) {
            newSelected = newSelected.concat(selected3.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected3.slice(0, selectedIndex),
                selected3.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected3(selected3.length === Object.entries(startLevelArray).length ? [] : Object.entries(startLevelArray).map(([key, value]) => key));
        }
        else
            setSelected3(newSelected)
    }
    function handleOpen() {
        setSelected3(statLevel)
        setOpen3(true)
    }
    function onCancel2() {
        setOpen3(false)
        setSelected3([])
    }
    function onAdd2() {
        setStatLevel(selected3)
        setOpen3(false)
        setSelected3([])
    }
    function handleClose() {
        setOpen3(false)
    }
    const handleSupplierNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedSupplier.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedSupplier, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedSupplier.slice(1));
        } else if (selectedIndex === selectedSupplier.length - 1) {
            newSelected = newSelected.concat(selectedSupplier.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedSupplier.slice(0, selectedIndex),
                selectedSupplier.slice(selectedIndex + 1),
            );
        }
        setSelectedSupplier(newSelected)
    };

    const handleCell = (type) => {
        if (type === 'rejected') {
            var sd = Moment(startDate).format('MM/DD/YYYY');
            var ed = Moment(endDate).format('MM/DD/YYYY');
            props.claimErrorCount(sd, ed, supplierNumber)
        }
    }
    const [dashboardCountData, setDashboardCountData] = React.useState({});
    const [applyPressed, setApplyPressed] = React.useState(false);

    const handleStatLevel = (e) => {
        setDashboardCountData({});
        setStatLevel(e.target.value);
    }
    const handleRejected = (item) => {
        var formData = {
            "claim_date": { "start_date": Moment(startDate).local().format('YYYY-MM-DD'), "end_date": Moment(endDate).local().format('YYYY-MM-DD') },
            "supplier_number": item.supplier_number,
            "claim_status": ["Rejected"],
        }
        props.onsubmit(formData);
    }
    function handleRadioGroup(newValue) {
        setStatLevel2(newValue.target.value);
    }
    return (
        <div className={classes.root}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#64C4B4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: '#64C4B4'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >   <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 16 }}> Claim Status</Typography>
                </div>
                <div className={classes.bodyContainer}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            md={6}
                            xs={12}

                        >
                            <div className={classes.selectRoot}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '1px 11px 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                width: '100%',
                                                borderRadius: 5,
                                                color: '#1675e0'
                                            }
                                        }}
                                        value={startDate}
                                        onChange={date => handleStartDate(date)}
                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                        format="MM/DD/YYYY"
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}

                        >
                            <div className={classes.selectRoot}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    End Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '1px 11px 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                width: '100%',
                                                borderRadius: 5,
                                                color: '#1675e0'
                                            }
                                        }}
                                        value={endDate}
                                        onChange={date => handleEndDate(date)}
                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                        format="MM/DD/YYYY"
                                        minDate={startDate ? startDate : false}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <div className={classes.selectRoot}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    Stat Level
                                </FormLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    disabled={statLevel2.length > 0 ? true : false}
                                    multiple
                                    value={statLevel}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    style={{ maxHeight: "50px", width: '100%', height: '2.3rem', marginTop: '0.2rem' }}
                                    // style={{ marginTop: '2rem', marginLeft: '-8rem' }}
                                    input={<OutlinedInput />}
                                    onOpen={() => handleOpen()}
                                    onClose={handleClose}
                                    open={open3}
                                    renderValue={(appType) => {
                                        var priceType = []
                                        priceType = Object.entries(startLevelArray).filter(([key, value]) => appType.includes(key)).map(([key, value]) => value)
                                        return priceType.join(", ")
                                    }}
                                    className={classes.multiSelect}

                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all',)}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected3} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {startLevelArray && Object.entries(startLevelArray).length > 0 &&
                                        Object.entries(startLevelArray)
                                            .map(([key, value]) => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, key)} value={key} key={key}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected3.indexOf(key) > -1} />
                                                        <ListItemText primary={value} />
                                                    </MenuItem>
                                                )
                                            })}
                                    <div className={classes.dropdownbottom}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel2}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd2} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div className={classes.selectRoot}>
                                <FormLabel className={classes.chargeBackLabel} required>
                                    View By
                                </FormLabel>
                                <Select
                                    value={viewBy}
                                    onChange={(e) => setViewBy(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >

                                    <MenuItem value={'claim_value'} key={'claim_value'}> Claim Value </MenuItem>
                                    <MenuItem value={'claim_lines'} key={'claim_lines'}> Claim Lines </MenuItem>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ display: statLevel.includes('customer_number') ? 'block' : 'none' }}
                        > <div className={classes.selectRoot}>
                                <FormLabel classes={{ root: classes.fontSetting }}
                                    style={{ marginBottom: 5 }} >
                                    Customer Number
                                </FormLabel>
                                <Select
                                    value={customerNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenCustomer}
                                    onClose={() => setListOpenCustomer(false)}
                                    open={listOpenCustomer}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {customerMasterArrayMaterialNumber?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleCustomerNumber(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedCustomer.indexOf(item) > -1} />
                                                <ListItemText primary={customerNameArray[customerMasterArrayMaterialNumber.indexOf(item)] + ' (' + item + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('customer')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('customer')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ display: statLevel.includes('material_number') ? 'block' : 'none' }}
                        > <div className={classes.selectRoot}>
                                <FormLabel classes={{ root: classes.fontSetting }}
                                    style={{ marginBottom: 5 }}>
                                    Material Number
                                </FormLabel>
                                <Select
                                    value={materialNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenMaterial}
                                    onClose={() => setListOpenMaterial(false)}
                                    open={listOpenMaterial}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {materialMasterArrayMaterialNumber?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleMaterialNumber(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedMaterial.indexOf(item) > -1} />
                                                <ListItemText primary={materialNameArray[materialMasterArrayMaterialNumber.indexOf(item)] + '(' + item + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('material')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('material')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ display: statLevel.includes('supplier_number') ? 'block' : 'none' }}
                        >
                            <div className={classes.selectRoot}>
                                <FormLabel classes={{ root: classes.fontSetting }}
                                    style={{ marginBottom: 5 }}>
                                    Supplier Number
                                </FormLabel>
                                <Select
                                    value={supplierNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenSupplier}
                                    onClose={() => setListOpenSupplier(false)}
                                    open={listOpenSupplier}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {supplierMasterArrayMaterialNumber?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSupplierNumber(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedSupplier.indexOf(item) > -1} />
                                                <ListItemText primary={supplierNameArray[supplierMasterArrayMaterialNumber.indexOf(item)] + '(' + item + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('supplier')}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('supplier')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={statLevel2}>
                                <FormControlLabel
                                    disabled={statLevel.length > 0 ? true : false}
                                    value="aggregate"
                                    control={<Radio color="primary" />}
                                    label={<Typography classes={{ root: classes.chargeBackLabel }} > Aggregate </Typography>}
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </div >
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                        disabled={(!startDate || !endDate || !statLevel || !viewBy) ? true : false}
                    >
                        Run
                    </Button>
                </div>

            </LoadingOverlay >
        </div >
    );
}


const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        supplierMasterData: state.customerData.supplierMasterData,
        dashboardCountData: state.initialData.dashboardCountData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        claimErrorCount: (sd, ed) => dispatch(claimErrorCount(sd, ed)),
        loadDashboardCount: (sd, ed, supplier, type, page) => dispatch(getDashboardCount(sd, ed, supplier, type, page)),
        onsubmit: (formData) => dispatch(runDisputedClaim(formData)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: (pagination, limit) => dispatch(getMaterialMasterData(pagination, 0)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ClaimStatusSearch);