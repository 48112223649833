import React, { useEffect } from 'react';
import {
    Typography, Button, Dialog, DialogActions, DialogContent,
    DialogTitle, DialogContentText, Checkbox, TextField
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { ErrorOutlineOutlined, WarningAmberRounded } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
const useStyles = makeStyles(theme => ({

    errorIcon: {
        width: 50,
        height: 36,
        border: '.5px solid red',
        borderRadius: '50%',
        background: 'red',
        paddingLeft: 5,
        paddingTop: 3,
        marginTop: 12,
        marginRight: 6,

    }

}));

const SendRequestDialog = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const [comment, setComment] = React.useState('')


    function handleClose() {
        setOpen(false)
    }

    function handleComment(e) {
        setComment(e)
    }

    function handleSend() {
        setOpen(false)
    }


    return (
        <div className={classes.root}>
            {
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                >
                    <DialogTitle id="dialog-title">
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.errorIcon}><WarningAmberRounded style={{ color: 'white' }} /> </div>
                                <Typography fontSize='17px' color='red'>Are you sure you want to send the scenario for official approval?</Typography>
                            </div>
                            <hr></hr>
                            <Typography color='grey' fontSize='13px'>SCENARIO TO PUSH</Typography>
                            <Typography color='black'>V2-PWC-Retail-Apr05-2024-Launch</Typography>
                            <hr></hr>
                            <div style={{ display: 'flex', marginBottom: 25 }}>
                                <ErrorOutlineOutlined style={{ color: 'red' }} /> <Typography variant='h5' color='red'>Becarefull, this action is irreversible</Typography>
                            </div>
                            <Typography color='grey' fontSize='13px'>SHARE A COMMENT WITH APPROVERS</Typography>
                            <TextField
                                id="outlined-textarea"
                                placeholder="Add your comments here..."
                                multiline
                                rows={4}
                                onChange={handleComment}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: 10 }}>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='contained' onClick={handleSend} >Yes, Send Request</Button>
                    </DialogActions>
                </Dialog>
            }
        </div >
    );

};



export default SendRequestDialog;