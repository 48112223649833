import React, { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import {
    FormLabel, TextField,
} from '@mui/material';
import {getAttributeListData} from '../../../redux/actions';
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
const useStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        marginTop: 10
    },
    error: {
        border: '1px solid red !important'
    },
    contractContiner: {
        padding: '15px 0px 18px'
    }
}));
const MaterialGroupComponent = forwardRef((props, ref) => {
    const classes = useStyles();
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [contractNumber, setContractNumber] = React.useState('');
    useEffect(() => {
        var temp= [];
        if (props.attributeData) {
            props.attributeData.map(item => {
                if(item.attribute_name==='material_group')
                temp.push(item.attribute_value+" "+item.attribute_desc)
            })
            setContractNumbersList(temp);
        }
    }, [props.employeeMasterData])
    const handleContractNumber = (e, value) => {
        if (value.length > 0) {
            var index = value.indexOf(' ');
             setContractNumber(value.substring(0, index));
        }
        else {
            setContractNumber('');
        }
        props.onChange(value)
    };
    return (
        <div className={classes.contractContiner}>
            <FormLabel required className={classes.chargeBackLabel}> Contract Number </FormLabel>
            <Autocomplete
                limitTags={3}
                id="tags-standard"
                options={contractNumbersList}
                getOptionLabel={(option) => option}
                defaultValue={contractNumber}
                value={contractNumber}
                closeOnChange
                renderInput={params => (

                    <TextField {...params} variant="standard" size="small" style={{ borderRadius: '0px !important' }} />

                )}
                classes={{
                    inputRoot: { borderRadius: '0px !important' }
                }}
                style={{ width: "100%", marginBottom: 15, marginTop: 5 }}
                //onSelect={(event, value) => { handleContractNumber(event, value) }}
                onChange={(event, value) => { handleContractNumber(event, value) }}
            />
        </div>
    );

});
const mapDispatchToProps = dispatch => {
    return {
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
    }
}
const mapStateToProps = state => {
    return {
        attributeData: state.attributeListData.AttributeData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MaterialGroupComponent);