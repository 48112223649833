import React from 'react';
import { connect } from 'react-redux';
import { MembershipSecondUserTable } from './components';

const MembershipSecond = (props) => {
    return (
        <div>
            <MembershipSecondUserTable type={props.location.type.method} memberId={props.location.type.memberID}/>
        </div >
    );
};

export default connect(null, null)(MembershipSecond);