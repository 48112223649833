import React, { useRef, useEffect } from 'react';
import {
    Stepper,
    Step,
    StepButton,
    StepConnector,
    StepLabel,
    Button,
    Grid,
    useMediaQuery,
    Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { GeneralData, DetailCPQ, ApprovalStatus, InternalNotes } from './components';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';

import BeatLoader from "react-spinners/BeatLoader";
import { createComprehensiveQuote, getComprehensiveQuoteById, runPriceCalcForSimulation } from '../../../../redux/actions';
import { dataBound } from '@syncfusion/ej2-react-grids';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '10px 29px 27px 29px',
        display:'flex',
        flexDirection:'column'

    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        //marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height:'auto'
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        //padding: 10,
        
    },
    pageControls: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        },
        padding:10
    }
}));


const CPQResult = props => {
    const history = useHistory();
    const [viewMode, setViewMode] = React.useState(false);
    var type=props.history.location.state?.type
    var primaryData={}
    var quoteCal={}
    var quoteData={}
    var statusData={}
    function getSteps() {
       
            return ['Primary','Quote', 'Notes and Attachments', 'Approval Status*'];
    }
   
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [quoteDetailData, setQuoteDetailData] = React.useState([]);
    const [simulationData, setSimulationData] = React.useState({});
    const [note, setNote] = React.useState([]);
    const skipped = new Set()
    const steps = getSteps();
    const tabs={
        generalData:0,
        detailedCPQ:1,
        notesAndAttachments: 2,
        approvalStatus: 3
        
    }
    const theme = useTheme();
    const skippedSteps = () => {
        return skipped.size;
    };
    const completedSteps = () => {
        return completed.size;
    };
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const totalSteps = () => {
        return getSteps().length;
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleComplete = () => {
        var data={...primaryData,
                    ...simulationData,
                    'ID':quoteDetailData.ID,
                 "notes":note,
                 "quote_number":quoteDetailData.quote_number,
                 simulation_category:'CPQ',
                 ...statusData
                }
       
        props.onSubmit(data,quoteDetailData.ID);
    };
    const handleDiscard = () => {
        history.push('/price-simulation-detailed-quote')
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const handleGeneralData=(data,quote,attr)=>{
        primaryData={...data,...quote}
        quoteCal={...quote,...attr}
    }
    const handleDetailCPQData=(data)=>{
        quoteData=data
        setSimulationData(data)
    }
    const handleInternalData=(data)=>{
        setNote(data)
    }
    const handleApprovalData=(data)=>{
        statusData=data
    }
    const handleNext = () => {
       if(activeStep == tabs.generalData&&!viewMode){
        history.push(window.location.pathname)
        props.calculateQuote(quoteCal)
        
       }
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    useEffect(()=>{
        if(props.quoteData){
             setQuoteDetailData(props.quoteData)
             setNote(props.quoteData.notes)
             statusData={'approval_status': props.quoteData.approval_status, 'approval_group_id': props.quoteData.approval_group_id}
            quoteData={'simulation_number':props.quoteData.simulation_number,'quote_amount':props.quoteData.quote_amount}
            }
            if(props.quoteData.approval_status=='submit_approval'||type=='viewQuote')
              setViewMode(true)
            else
            setViewMode(false)
            if(props.quoteData.simulation_number)
             props.calculateQuote(null,props.quoteData.simulation_number,props.quoteData.pricing_calculation_formula)
    },[props.quoteData])
    function getStepContent(step) {
        var allSteps;
                allSteps = [
                    <GeneralData id={props.match.params.id} onChange={handleGeneralData} generalData={quoteDetailData} editMode={true} viewMode={viewMode}/>,
                    <DetailCPQ onChange={handleDetailCPQData} active={activeStep == tabs.detailedCPQ?true:false} />,
                    <InternalNotes onChange={handleInternalData} notes={note} editMode={true} viewMode={viewMode}/>,
                    <ApprovalStatus onChange={handleApprovalData} data={quoteDetailData} editMode={true} viewMode={viewMode} type={type}/>
                ];
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    return (
        <div>
            <div className={classes.root}>
          
                <div className={classes.stepper}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}
                        classes={{ root: classes.stepperContainer }}
                        connector={
                            <StepConnector
                                classes={{
                                    line: classes.connectorLine,
                                    alternativeLabel: classes.connectorAlternativeLabel
                                }}
                            />
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}
                                    classes={{ horizontal: classes.horizontal }}
                                >
                                    <StepButton
                                        onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                        classes={{ root: classes.stepButton }}
                                    >
                                        <StepLabel>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>

                {type!=='viewQuote'&&
                    <Grid container >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        {activeStep !== 0 &&
                            <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                Previous Step
                            </Button>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                    {activeStep != tabs.generalData &&
                        <Button variant="outlined" color="primary" onClick={handleComplete}
                            className={clsx({
                                [classes.button]: true,
                                [classes.shiftContent]: !isDesktop,
                            })}
                           // disabled={saveDisabled}
                            disabled={props.loading === true ? true : false}
                        >
                            {props.loading ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                :
                                ''
                            }
                            {props.loading ? '' : 'Save'}
                        </Button>
                        }
                        <Button variant="outlined" color="primary" onClick={handleDiscard} className={classes.button}>
                            Discard
                        </Button>
                        {activeStep != tabs.approvalStatus &&
                            <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}
                           //disabled={(!calculationMethod||!postingSchema|| !sourceDataType)?true:false}
                            >
                                {activeStep === tabs.generalData&&!viewMode ? 'Calculate Prices' : 'Next Step'}
                            </Button>
                        }
                    </Grid>
                </Grid>}
            </div>
        </div>
    );

};



const mapStateToProps = state => {
    return {
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
        quoteData:state.pricingData.comprehensiveQuoteSingleData
    }

};
const mapDispatchToProps = dispatch => {
    return {
        calculateQuote: (formData,simNo,formula) => dispatch(runPriceCalcForSimulation(formData,'details','edit',simNo,formula)),
        onSubmit: (formData,id) => dispatch(createComprehensiveQuote(formData,id,'edit')),
        getQuoteData: (id) => dispatch(getComprehensiveQuoteById(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CPQResult);