import React, { useImperativeHandle, forwardRef } from 'react';
import { makeStyles } from "@mui/styles";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import {
    Grid,
    FormControl,
    FormLabel,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 15,
        paddingBottom: 15,
        width: '100%'
    },
    grid: {
        alignSelf: 'center'
    },
}));

const Timepicker = forwardRef((props, ref) => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const reset = () => {
        setSelectedDate(null)
    };
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: selectedDate
        };
    });

    return (
        <div>
            <Grid container className={classes.root}>
                <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.grid}
                >
                    <FormLabel {...props} >
                        {props.heading}
                    </FormLabel>
                    <FormControl >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                                <KeyboardTimePicker
                                    disableToolbar
                                    clearable
                                    InputProps={{
                                        padding: 0,
                                        disableUnderline: true,
                                        style: {
                                            padding: '1px 0 1px 11px',
                                            alignSelf: 'center',
                                            alignItems: 'center',
                                            marginTop: 10,
                                            border: '1px solid #E0E0E0',
                                            width: '100%'
                                        }
                                    }}
                                    value={selectedDate}
                                    onChange={date => handleDateChange(date)}
                                    keyboardIcon={<AccessTimeIcon />}
                                    variant="dialog"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );

});

export default Timepicker;