import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getExceptionManagementAll, addExceptionManagement, postCollaborationPortal, getLblDispDesFieldValue, getExceptionManagementViewDetails } from '../../redux/actions';
import { batchExceptionApprove } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Toolbar2 } from '.';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import MaterialTable from "material-table";
import secureLocalStorage from 'react-secure-storage';
import { tableIcons } from '../../components/Icons/TableIcons';
import VisibilityIcon from "@mui/icons-material/Visibility";
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    container: {
        padding: 10
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const ExceptionManagementMain = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [editMode, setEditMode] = React.useState(false);
    const [currentEditableId, setCurrentEditableId] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const action = {
        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div style={{ whiteSpace: 'nowrap' }}>
                <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => viewException(rowData.ID)}
                    size="large">
                    <VisibilityIcon color="grey" style={{ fontSize: 20 }} />
                </IconButton>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_exception-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editCustomer(rowData.ID)}
                        size="large"
                        disabled={rowData.approval_status === 'acc' || rowData.approval_status === 'rej'}>
                        <EditIcon color="grey" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_exception-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => props.deleteAccrualDesigner(rowData, 'delete', rowData.ID)}
                        size="large"
                        disabled={rowData.approval_status === 'acc' || rowData.approval_status === 'rej'}>
                        <DeleteForeverIcon color="grey" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_exception-' + sessionStorage.getItem('application')) &&
                    <Tooltip title="Reject" placement="top">
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => rejectException(rowData.ID)}
                            size="large"
                            disabled={rowData.approval_status !== 'acc'}>
                            <CancelIcon color="grey" style={{ fontSize: 20 }} />
                        </IconButton>
                    </Tooltip>
                }
            </div>
    }
    useEffect(() => {
        props.getExceptionManagementAll(null, 'list', sessionStorage.getItem('application'));
        if (secureLocalStorage.getItem("dYtz").collaboration_id != '0') {
            props.postCollaborationPortal(null, secureLocalStorage.getItem("dYtz").collaboration_id, 'get')
        }
        var data = { "data_source": "incentive_overrides" };
        props.loadSourceDataType(data, 'post');
    }, []);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {

                    //     if (item.sort_list || item.mandatory) {
                    //         newArray.push({
                    //             field: item.key,
                    //             title: item.current,
                    //             type: item.key != 'formula_result' ? 'string' : 'Number',
                    //             sort_order: item.sort_list,
                    //             editable: 'never',
                    //             render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
                    //                 Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    //         })
                    //     }

                    // })
                    if (item.sort_list || item.mandatory) {
                        const columnDefinition = {
                            field: item.key,
                            title: item.current,
                            type: item.key !== 'formula_result' ? 'string' : 'Number',
                            sort_order: item.sort_list,
                            editable: 'never',
                        };
                        if (item.key === 'exception_type') {
                            columnDefinition.render = (rowData) => {
                                if (rowData[item.key]) {
                                    const words = rowData[item.key].split('_');
                                    const formattedExceptionType = words
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ');
                                    return <div>{formattedExceptionType}</div>;
                                }
                                return "";
                            };
                        }
                        else if (item.key === 'exception_value') {
                            columnDefinition.render = (rowData) => {
                                if (rowData[item.key]) {
                                    const words = rowData.exception_value && rowData.exception_value.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') });
                                    return <div>{words}</div>;
                                }
                                return "";
                            };
                        }
                        else if (item.key === 'approval_status') {
                            columnDefinition.render = (rowData) => {
                                if (rowData[item.key] === 'acc') {
                                    return <div>Approved</div>;
                                }
                                else if (rowData[item.key] === 'rej') {
                                    return <div>Rejected</div>;
                                }
                                else if (rowData[item.key]) {
                                    const words = rowData[item.key].split('_');
                                    const formattedExceptionType = words
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ');
                                    return <div>{formattedExceptionType}</div>;
                                }
                                return "";
                            };
                        }
                        else if (item.key === 'start_date' || item.key === 'end_date') {
                            columnDefinition.render = (rowData) => (
                                <div>
                                    {Moment.utc(rowData[item.key]).format('MM/DD/YYYY')}
                                </div>
                            );
                        } else {
                            columnDefinition.render = (rowData) => (
                                <div>{rowData[item.key]}</div>
                            );
                        }

                        newArray.push(columnDefinition);
                    }
                });
            newArray.push(action)

            setColumns(newArray.sort((a, b) => {
                return a.sort_order - b.sort_order;
            }))
        }
    }, [props.tableData])
    useEffect(() => {
        var temp1 = [];
        var temp = [];
        if (props.collaborationPortalData && props.collaborationPortalData.records && props.collaborationPortalData.records[0].customer_number) {
            temp1 = props.collaborationPortalData.records[0].customer_number
        }
        if (props.exceptionManagementAll) {
            props.exceptionManagementAll
                .map(item => {
                    if (item.exception_mapping != null && item.exception_mapping != undefined && temp1.some(ai => Object.values(item.exception_mapping)[0].includes(ai))) {
                        temp.push(item)
                    }
                })
            setDataRows(temp)
        }
    }, [props.exceptionManagementAll, props.collaborationPortalData]);
    useEffect(() => {
        if (props.exceptionManagementAll) {
            setDataRows(props.exceptionManagementAll)
        }
    }, [props.exceptionManagementAll])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getExceptionManagementAll(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getExceptionManagementAll(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    function editCustomer(id) {
        history.push({
            pathname: '/exception-management/edit/' + id,
            id: id
        });
    }
    function viewException(id) {
        props.getExceptionManagementViewDetails(id)
        history.push({
            pathname: '/exception-management/view/' + id,
            id: id
        });
    }
    const rejectException = (ID) => {
        const idArray = [ID];
        props.batchExceptionReject(idArray, 'rej', null);
        setTimeout(() => {
            // props.getExceptionManagementAll();
            props.getExceptionManagementAll(null, 'list', sessionStorage.getItem('application'));
        }, 1000);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginTop: 8, marginLeft: 3 }} > Exception Management</Typography>
                    {/* { secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_exception-'+sessionStorage.getItem('application')) && */}
                    <Toolbar2 />
                </div>
                {dataRows && dataRows.length > 0 ?

                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                ></div>
                            ),
                        }}
                        title={" "}
                        editable={true}
                        icons={tableIcons}
                        columns={columns.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.title === value.title
                            ))
                        )}
                        data={dataRows}
                        options={{
                            search: false,
                            sorting: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            filtering: true,
                            pageSize: 15,
                            pageSizeOptions: [
                                15,
                                20,
                                50,
                                { value: dataRows.length, label: "Show all" },
                            ],
                        }}
                    />

                    :
                    <Typography variant='h4' color='textPrimary' style={{ marginLeft: 16, marginTop: 16 }}>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        exceptionManagementAll: state.customerData.exceptionManagementAll,
        collaborationPortalData: state.addMultipleConfigurationData.collaborationPortalData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getExceptionManagementAll: (id, type, appType) => dispatch(getExceptionManagementAll(id, type, appType)),
        deleteAccrualDesigner: (data, type, id) => dispatch(addExceptionManagement(data, type, id)),
        postCollaborationPortal: (data, editId, type) => dispatch(postCollaborationPortal(data, editId, type)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getExceptionManagementViewDetails: (id) => dispatch(getExceptionManagementViewDetails(id)),
        batchExceptionReject: (items, status, itemsApproved) => dispatch(batchExceptionApprove(items, status, itemsApproved)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExceptionManagementMain);
