import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, IconButton, Fab, Typography, TablePagination,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import { connect } from 'react-redux';
import { getMaterialMasterData, getMaterialMasterViewDetails, deleteCustomerMasterData, getLblDispDesFieldValue } from '../../../../redux/actions';
import { useHistory } from "react-router-dom";
import { Edit, AddBoxOutlined, DeleteForever, CloudUpload, LibraryAdd, SearchSharp } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { NavLink as RouterLink } from 'react-router-dom';
import { createTheme } from "@mui/material/styles";
import { tableIcons } from '../../../../components/Icons/TableIcons';
import secureLocalStorage from 'react-secure-storage';
import HashLoader from "react-spinners/HashLoader";
import RingLoader from "react-spinners/RingLoader";
import Moment from 'moment';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));

const MaterialMasterUserTable = props => {
    // useEffect(() => {
    //     if (appId === 'material-master-promo')
    //         props.getMaterialMasterData(1, 10);
    // }, []);
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const type = history.location.state;
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    //
    const [count, setCount] = React.useState(0);
    // const [columns,setColumns] = React.useState([]);
    const [currentId, setCurrentId] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [columns2, setColumns2] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false); // Dialog control state
    const [deleteId, setDeleteId] = React.useState(null);
    //
    var pathname = window.location.pathname;
    var appId = pathname.substring(pathname.lastIndexOf('/') + 1);

    // const columns = appId === 'material-master-promo' ?
    //     [
    //         {
    //             field: 'material_number',
    //             title: 'Material Number',
    //             editable: 'never',
    //             type: 'numeric',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.material_number}</a>
    //         },
    //         {
    //             field: 'material_description',
    //             title: 'Material Description',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.material_description}</a>
    //         },
    //         {
    //             field: 'material_type',
    //             title: 'Material Type',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.material_type}</a>
    //         },
    //         {
    //             field: 'base_uom',
    //             title: 'Base UOM',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.base_uom}</a>
    //         },
    //         {
    //             field: 'product_hierarchy',
    //             title: 'Product Hierarchy',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.product_hierarchy}</a>
    //         },
    //         {
    //             field: 'material_group1',
    //             title: 'Material Group 1',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.material_group1}</a>
    //         },
    //         {
    //             field: 'material_group2',
    //             title: 'Material Group 2',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.material_group2}</a>
    //         },
    //         {
    //             field: 'material_group3',
    //             title: 'Material Group 3',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id, rowData.material_number)}> {rowData.material_group3}</a>
    //         },


    //         {
    //             field: 'Actions',
    //             title: 'Actions',
    //             type: 'string',
    //             editable: 'never',
    //             render: rowData =>
    //                 <div>
    //                     {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-' + sessionStorage.getItem('application')) &&
    //                         <IconButton classes={{ root: classes.IconButton }} onClick={() => editMaterial(rowData.id, rowData.material_number)}>
    //                             <Edit color="disabled" style={{ fontSize: 20 }} />
    //                         </IconButton>}
    //                     {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-' + sessionStorage.getItem('application')) &&
    //                         <IconButton classes={{ root: classes.IconButton }} onClick={() => deleteData(rowData.id)}>
    //                             <DeleteForever color="disabled" style={{ fontSize: 20 }} />
    //                         </IconButton>}
    //                 </div>
    //         },
    //     ]
    //     :
    //     [
    //         {
    //             field: 'material_number',
    //             title: 'Material Number',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_number}</a>
    //         },
    //         {
    //             field: 'material_description',
    //             title: 'Material Description',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_description}</a>
    //         },
    //         {
    //             field: 'material_type',
    //             title: 'Material Type',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_type}</a>
    //         },
    //         {
    //             field: 'base_uom',
    //             title: 'Base UoM',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.base_uom}</a>
    //         },
    //         {
    //             field: 'product_hierarchy',
    //             title: 'Product Hierarchy',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.product_hierarchy}</a>
    //         },
    //         {
    //             field: 'material_group',
    //             title: 'Material Group',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_group}</a>
    //         },
    //         {
    //             field: 'material_id_type',
    //             title: 'Material ID Type',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_id_type.toString()}</a>
    //         },
    //         {
    //             field: 'material_id_number',
    //             title: 'Material ID Number',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_id_number.toString()}</a>
    //         },
    //         {
    //             field: 'supplier_name',
    //             title: 'Supplier Name',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.supplier_name}</a>
    //         },
    //         {
    //             field: 'supplier_number',
    //             title: 'Supplier Number',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.supplier_number}</a>
    //         },
    //         {
    //             field: 'cost',
    //             title: 'Cost',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.cost}</a>
    //         },
    //         {
    //             field: 'external',
    //             title: 'External',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.external}</a>
    //         },
    //         {
    //             field: 'external_material_group',
    //             title: 'External Material Group',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.external_material_group}</a>
    //         },
    //         {
    //             field: 'flex_attribute1',
    //             title: 'Flex Attribute 1',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.flex_attribute1}</a>
    //         },
    //         {
    //             field: 'flex_attribute2',
    //             title: 'Flex Attribute 2',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.flex_attribute2}</a>
    //         },
    //         {
    //             field: 'flex_attribute3',
    //             title: 'Flex Attribute 3',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.flex_attribute3}</a>
    //         },
    //         {
    //             field: 'flex_attribute4',
    //             title: 'Flex Attribute 4',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.flex_attribute4}</a>
    //         },
    //         {
    //             field: 'id_number01',
    //             title: 'Id Number 01',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.id_number01}</a>

    //         },
    //         {
    //             field: 'id_number02',
    //             title: 'Id Number 02',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.id_number02}</a>

    //         },
    //         {
    //             field: 'id_number03',
    //             title: 'Id Number 03',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.id_number03}</a>

    //         },
    //         {
    //             field: 'id_number04',
    //             title: 'Id Number 04',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.id_number04}</a>

    //         },
    //         {
    //             field: 'id_number05',
    //             title: 'Id Number 05',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.id_number05}</a>

    //         },
    //         {
    //             field: 'id_number06',
    //             title: 'Id Number 06',
    //             editable: 'never',
    //             type: 'string',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.id_number06}</a>
    //         },
    //         {
    //             field: 'material_group2',
    //             title: 'Material Group 2',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_group2}</a>
    //         },
    //         {
    //             field: 'material_group1',
    //             title: 'Material Group 1',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_group1}</a>
    //         },
    //         {
    //             field: 'material_group3',
    //             title: 'Material Group 3',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_group3}</a>
    //         },
    //         {
    //             field: 'material_group4',
    //             title: 'Material Group 4',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_group4}</a>
    //         },
    //         {
    //             field: 'material_group5',
    //             title: 'Material Group 5',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_group5}</a>
    //         },
    //         {
    //             field: 'material_status',
    //             title: 'Material Status',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.material_status}</a>
    //         },
    //         {
    //             field: 'msrp',
    //             title: 'MSRP',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.msrp}</a>
    //         },
    //         {
    //             field: 'product_features',
    //             title: 'Product Features',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.product_features.toString()}</a>
    //         },
    //         {
    //             field: 'supplier_material_number',
    //             title: 'Supplier Material Number',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.supplier_material_number}</a>
    //         },
    //         {
    //             field: 'pricing_inventory_alert_qty',
    //             title: 'Pricing Inventory Alert Qty',
    //             type: 'string',
    //             editable: 'never',
    //             render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData.pricing_inventory_alert_qty}</a>
    //         },
    //         {
    //             field: 'Actions',
    //             title: 'Actions',
    //             type: 'string',
    //             editable: 'never',
    //             render: rowData =>
    //                 <div>
    //                     {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-' + sessionStorage.getItem('application')) &&
    //                         <IconButton classes={{ root: classes.IconButton }} onClick={() => editMaterial(rowData.ID, rowData.material_number)}>
    //                             <Edit color="disabled" style={{ fontSize: 20 }} />
    //                         </IconButton>}
    //                     {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-' + sessionStorage.getItem('application')) &&
    //                         <IconButton classes={{ root: classes.IconButton }} onClick={() => deleteData(rowData.ID)}>
    //                             <DeleteForever color="disabled" style={{ fontSize: 20 }} />
    //                         </IconButton>}
    //                 </div>
    //         },
    //     ];



    //       
    const [dataRows, setDataRows] = React.useState([]);
    //
    useEffect(() => {
        if (type === 'displayAPICALL') {
            var data = { "data_source": "material_master" };
            props.getLblDispDesFieldValue(data, 'post');
        }
    }, [type]);

    useEffect(() => {
        sessionStorage.setItem('dropdownvalidation', JSON.stringify([]))
    }, []);

    function editMaterial(id, materialNo) {
        navigateToEdit(id);
        sessionStorage.setItem('MaterialNumber', materialNo);
    }

    function navigateToEdit(id) {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);

        // localStorage.setItem('materialMasterEditId', id);
        if (appId === 'material-master-promo') {
            history.push({
                pathname: '/material-master-promo/edit-material/' + id,
                id: id
            });
        } else {
            history.push({
                pathname: '/material-master/edit-material/' + id,
                id: id
            });
        }

        // localStorage.setItem('currentAddContractID', id);
    }
    function handleItemClick(id, materialNo) {
        sessionStorage.setItem('MaterialNumber', materialNo);
        props.getMaterialMasterViewDetails(id);
    }
    // const deleteData = (id) => {
    //     props.deleteCustomerMasterData(id)
    // }
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));

    useEffect(() => {
        var tempRows = [];

        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);

        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length >= 0)
            setDataRows(props.materialMasterData.records);

    }, [props.materialMasterData]);
    useEffect(() => {
        var newArray = []
        var newArray2 = ['Actions']
        var tempObj = []

        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter((item, i) => item.sort_list)
                .sort((a, b) => {
                    return a.sort_list - b.sort_list;
                })
                .map((item, index) => {
                    if (item.sort_list) {
                        newArray2.push(item.default)
                        // tempObj.push(item)
                        if (item.key === 'product_features') {
                            tempObj.push({
                                field: item.key,
                                title: item.current,
                                type: 'string',
                                editable: 'never',
                                render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {""}</a>
                            });
                        }
                        else {
                            tempObj.push({
                                field: item.key,
                                title: item.current,
                                type: 'string',
                                editable: 'never',
                                render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData[item.key]}</a>
                            });
                        }

                    }

                })
            tempObj.push({
                field: 'Actions',
                title: 'Actions',
                type: 'string',
                editable: 'never',
                render: rowData =>
                    <div>
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editMaterial(rowData.ID, rowData.material_number)}
                                size="large">
                                <Edit color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>}
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => deleteData(rowData.ID)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>}
                    </div>
            })
            // {
            //     columns && columns.length > 0 && columns
            //         .filter(item => newArray2.includes(item.title))
            //         .map((item, index) => {
            //             newArray.push(item)
            //             Object.values(tempObj).map(e => {
            //                 if (e.default == item.title) {
            //                     item['title'] = e.current
            //                     item['sort_order'] = e.sort_list
            //                 }
            //             })

            //         })
            // }
            // if (newArray.length > 1) {
            //     setColumns2(newArray.sort((a, b) => {
            //         return a.sort_order - b.sort_order;
            //     }))
            // }
            setColumns2(tempObj)
        }
    }, [props.tableData])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getMaterialMasterData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setRowsPerPage(event.target.value);
        props.getMaterialMasterData(1, event.target.value);
    };
    //
    const handleClickOpen = (id) => {
        setDeleteId(id);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setDeleteId(null);
    };

    const handleConfirmDelete = () => {
        props.deleteCustomerMasterData(deleteId);
        handleClose();
    };

    const deleteData = (id) => {
        handleClickOpen(id);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Material Master </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_master-' + sessionStorage.getItem('application')) &&
                            <>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    classes={{ root: classes.fabContainer }}
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to={appId === 'material-master-promo' ?
                                        '/material-master-promo/add-material'
                                        :
                                        '/material-master/add-material'
                                    }
                                >
                                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                                </Fab>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/material-master/material-master-excel-upload'
                                >
                                    <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                                </Fab>
                            </>
                        }
                        {//(appId != 'material-master-promo') &&
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                component={CustomRouterLink}
                                to='/material-master/search-material'
                            >
                                <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                            </Fab>
                        }
                    </div>
                </div>

                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns2}
                        // columns={columns2.filter((value, index, self) =>
                        //     index === self.findIndex((t) => (
                        //         t.title === value.title
                        //     ))
                        // )}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div className={classes.noDataContainer}>
                        Display Materials by clicking on Search Icon.
                    </div>
                }
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.materialMasterData,
        loading: state.customerData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMaterialMasterData: (pagination, limit) => dispatch(getMaterialMasterData('NoLimit', limit)),
        getMaterialMasterViewDetails: (id) => dispatch(getMaterialMasterViewDetails(id)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'MaterialMaster')),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialMasterUserTable);