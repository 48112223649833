// import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
// import clsx from 'clsx';
// import { makeStyles, useTheme, withStyles } from '@mui/styles';
// import {
//     Typography,
//     useMediaQuery,
//     Table,
//     TableCell,
//     TableHead,
//     TableRow,
//     TableContainer,
//     TableBody,
//     TablePagination,
//     Button,
//     Checkbox
// } from '@mui/material';
// import { connect } from 'react-redux';
// import { getContractsTableData, cloneContract, getContractViewData, batchApproveFinancial, getFinacialPostingsApprovalData } from '../../redux/actions';
// import { useHistory } from "react-router-dom";
// import Moment from 'moment';
// import { getContractDetailsData } from '../../redux/actions';
// import LoadingOverlay from 'react-loading-overlay';
// import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from '@mui/icons-material/Clear';

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.table.row,
//             height: 46
//         },
//         '&:nth-of-type(even)': {
//             height: 40
//         },
//         tableRow: {
//             "&$hover:hover": {
//                 backgroundColor: "blue"
//             }
//         },
//         tableCell: {
//             "$hover:hover &": {
//                 color: "pink"
//             }
//         },
//         hover: {}
//     }
// }))(TableRow);

// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: theme.palette.white,
//         borderRadius: 10,
//         padding: 29,
//         marginTop: 16
//     },
//     row: {
//         flexDirection: 'row',
//         display: 'flex',
//         justifyContent: 'space-between',
//         marginBottom: 10
//     },
//     caption: {
//         fontSize: 12
//     },
//     paginationRoot: {
//         border: '1px solid #EEEEEE',
//         borderTop: 0
//     },
//     description: {
//         textAlign: 'left',
//         paddingLeft: 28
//     },
//     hover: {
//         cursor: 'pointer'
//     },
//     IconButton: {
//         padding: 0,
//         [theme.breakpoints.up('md')]: {
//             paddingRight: 10
//         }
//     },
//     buttonContainer: {
//         display: 'flex',
//         justifyContent: 'flex-end',
//         marginBottom: 15,
//         marginTop: 15
//     },
//     buttonRoot: {
//         flexDirection: 'row',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'space-between'
//     },
// }));

// const FinancialPostingReportsApproval = props => {
//     useEffect(() => {
//         props.getFinacialPostingsApprovalData();
//     }, []);
//     const { className } = props;
//     const classes = useStyles();
//     const theme = useTheme();
//     const history = useHistory();
//     const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
//         defaultMatches: true
//     });
//     const [page, setPage] = React.useState(0);
//     const [selected, setSelected] = React.useState([]);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);
//     const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
//     const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//         props.onCheckContractsTable(newPage + 1, rowsPerPage);
//     };
//     const approveContract = () => {
//         props.batchApproveFinancial(selected, 'acc')
//     };
//     const rejectContract = () => {
//         props.batchApproveFinancial(selected, 'rej')
//     };
//     const handleChangeRowsPerPage = (event, newValue) => {
//         setPage(0);
//         setRowsPerPage(event.target.value);
//         props.onCheckContractsTable(1, event.target.value);
//     };

//     useEffect(() => {
//         if (props.contractTabledataComplete) {
//             setContractsTableComplete(props.contractTabledataComplete);
//         }
//     }, [props.contractTabledataComplete]);

//     const handleClick = (event, name) => {
//         const selectedIndex = selected.indexOf(name);
//         let newSelected = [];
//         if (selectedIndex === -1) {
//             newSelected = newSelected.concat(selected, name);
//         } else if (selectedIndex === 0) {
//             newSelected = newSelected.concat(selected.slice(1));
//         } else if (selectedIndex === selected.length - 1) {
//             newSelected = newSelected.concat(selected.slice(0, -1));
//         } else if (selectedIndex > 0) {
//             newSelected = newSelected.concat(
//                 selected.slice(0, selectedIndex),
//                 selected.slice(selectedIndex + 1),
//             );
//         }
//         setSelected(newSelected);
//     };
//     const isSelected = (name) => selected.indexOf(name) !== -1;

//     return (
//         <div className={clsx(classes.root, className)}>
//             <div className={clsx({
//                 [classes.row]: isDesktop
//             })} >
//                 <Typography variant="h2"> Financial Postings Approval  Reports</Typography>
//             </div>
//             {props.financialApprovalData && props.financialApprovalData.records && props.financialApprovalData.records.length > 0 ?
//                 <TableContainer>
//                     <Table>
//                         <TableHead >
//                             <TableRow >
//                                 <TableCell align='center'>Contract Type</TableCell>
//                                 <TableCell align='center'>Contract ID</TableCell>
//                                 <TableCell align='center'>Description </TableCell>
//                                 <TableCell align='center'>Calculation Start Date </TableCell>
//                                 <TableCell align='center'>Calculation End Date </TableCell>
//                                 <TableCell align='center'>Posting Type </TableCell>
//                                 <TableCell align='center'>Posting Number </TableCell>
//                                 <TableCell align='center'>Posting Date </TableCell>
//                                 <TableCell align='center'>Tier Basis </TableCell>
//                                 <TableCell align='center'>Incentive Basis </TableCell>
//                                 <TableCell align='center'>Incentive Rate </TableCell>
//                                 <TableCell align='center'>Incentive Amount </TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody classes={{ root: classes.table }}>
//                             {props.financialApprovalData.records.map(item => {
//                                 const isItemSelected = isSelected(item.ID);
//                                 return (
//                                     <StyledTableRow key={item.contract_number} className={classes.hover}
//                                         hover
//                                         onClick={(event) => handleClick(event, item.ID)}
//                                     >
//                                         <TableCell align='center'>{item.contract_type}</TableCell>
//                                         <TableCell align='left' className={classes.tableCell} style={{textAlign: 'center'}}>
//                                             {item.contract_number}
//                                         </TableCell>
//                                         <TableCell align='center'>{item.contract_internal_description}</TableCell>
//                                         <TableCell align='center'>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
//                                         <TableCell align='center'>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
//                                         <TableCell align='center'>{item.posting_type}</TableCell>
//                                         <TableCell align='center'>{item.posting_number}</TableCell>
//                                         <TableCell align='center'>{Moment.utc(item.posting_date).format('MM/DD/YYYY')}</TableCell>
//                                         <TableCell align='center'>{item.tier_basis}</TableCell>
//                                         <TableCell align='center'>{item.incentive_basis}</TableCell>
//                                         <TableCell align='center'>{item.incentive_rate}</TableCell>
//                                         <TableCell align='center'>{item.incentive_amount}</TableCell>
//                                     </StyledTableRow>
//                                 );
//                             })}
//                         </TableBody>
//                     </Table>
                    
//                 </TableContainer>
//                 :
//                 <Typography variant='h4'>
//                    There is no data to show now.
//                     </Typography>
//             }
//         </div >
//     );
// };

// const mapStateToProps = state => {
//     return {
//         loading: state.addContractData.loading,
//         contractTabledataComplete: state.addContractData.contractListDataComplete,
//         financialApprovalData: state.addMultipleConfigurationData.financialApprovalData,
//     }
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         onCheckContractsTable: (pagination, limit) => dispatch(getContractsTableData(pagination, limit, 'submit')),
//         getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
//         getcontractViewdetails: (id) => dispatch(getContractViewData(id)),
//         batchApproveFinancial: (items, status) => dispatch(batchApproveFinancial(items, status)),
//         // approval
//         getFinacialPostingsApprovalData: () => dispatch(getFinacialPostingsApprovalData()),
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(FinancialPostingReportsApproval);

import React from 'react';
import {
    Typography,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {  FinancialSummaryTab } from './containers';
import { Dropdown, DropdownArray } from '../../components/Inputs';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        // border: '1px solid #EEEEEE',
        borderRadius: 10,
        marginBottom: 23,
        marginTop: 14
    },
    dropdownContainer: {
        padding: 0,
        marginTop: -5,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

const FinancialPostingReports = props => {
    const classes = useStyles();
    const { className } = props;
    const [value, setValue] = React.useState(0);

    return (
            
            <div
                className={clsx(classes.root, className)}>
                <FinancialSummaryTab />
            </div>
    );

};

export default FinancialPostingReports;