import React from 'react';
import { FinancialPostingsForm } from './containers';

const FinancialPostings = props => {

    return (
        <FinancialPostingsForm />
    );

};

export default FinancialPostings;