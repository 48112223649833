import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Card,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getBatchJobDetail } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { LabelText } from '../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
}));

const ViewIntegrationBatchJobSetup = props => {
    const classes = useStyles();
    const [integrationName, setIntegrationName] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getBatchJobDetail(appId);

    }, []);
    useEffect(() => {
        if (props.batchJobDetail) {
            setApplicationType(props.batchJobDetail.app_type);
            setBatchJobType(props.batchJobDetail.batch_job_type);
            setBatchJobName(props.batchJobDetail.batch_job_name);
            setPlannedStartDate(props.batchJobDetail.planned_start_date);
            setFrequency(props.batchJobDetail.frequency);
            setPlannedStartTime(props.batchJobDetail.planned_start_time.split(":00"))
            setPlannedDateType(props.batchJobDetail.planned_date_type)
            if (props.batchJobDetail.batch_filter_criteria) {

                props.batchJobDetail.batch_filter_criteria.map((item, index) => {
                    setIntegrationName(item.name)

                })
            }
        }
    }, [props.batchJobDetail])
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>View Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}
                            >   {applicationType &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                > <LabelText heading='Application Type' data={applicationType} twoline='true' />
                                </Grid>
                                }
                                {batchJobType &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    > <LabelText heading='Batch Job Type' data={batchJobType} twoline='true' />
                                    </Grid>
                                }
                                {batchJobName &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    > <LabelText heading='Batch Job Name' data={batchJobName} twoline='true' />
                                    </Grid>
                                }
                                {plannedDateType &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    > <LabelText heading='Planned Date Type' data={plannedDateType} twoline='true' />
                                    </Grid>
                                }
                                {frequency &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    > <LabelText heading='Frequency' data={frequency} twoline='true' />
                                    </Grid>
                                }

                                {plannedStartDate &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    > <LabelText heading='Planned Start Date ' data={Moment(plannedStartDate).format('MM/DD/YYYY')} twoline='true' />
                                    </Grid>
                                }
                                {plannedStartTime &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    > <LabelText heading='Planned Start Time' data={plannedStartTime} twoline='true' />
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}
                            >  {integrationName &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                > <LabelText heading='Integration Name' data={integrationName} twoline='true' />
                                </Grid>
                                }
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        batchJobDetail: state.customerData.batchJobDetail,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getBatchJobDetail: (id) => dispatch(getBatchJobDetail(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewIntegrationBatchJobSetup);