import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Button,
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    FormLabel,
} from '@mui/material';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import { LabelText } from '../../components/Inputs';
import {
    getDefaultValues, getBudgetingConfig, createBudgetClaimConfiguration,

} from '../../redux/actions';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    button: {
        marginRight: 25,
        width: 140
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 25,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    caption: {
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    gridContainer: {
        width: '100%'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: -4,
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
        paddingTop: 8
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    fontSettings: {
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'left',
        paddingLeft: 28
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'left',
        paddingLeft: 28
    },
}));

const ClaimConfigurationTab = props => {
    useEffect(() => {
        props.onLoadingDefault(sessionStorage.getItem('application'));
        props.getClaimData();

    }, []);
    const [fieldValue, setFieldValue] = React.useState([]);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [claimConfigData, setClaimConfigData] = React.useState({})
    const [promotionTheme, setPromotionTheme] = React.useState([]);
    const [promotionCategory, setPromotionCategory] = React.useState([]);
    const [promotionType, setPromotionType] = React.useState([]);
    const [promotionDivision, setPromotionDivision] = React.useState([]);
    const [usageCode, setUsageCode] = React.useState([]);
    const [salesProgramCode, setSalesProgrameCode] = React.useState([]);

    const classes = useStyles();
    const { className } = props;
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);

    const [currentID, setCurrentID] = React.useState(0);


    useEffect(() => {

        if (props.claimData && props.claimData.length > 0) {
            setClaimConfigData(props.claimData[0])
            setCurrentID(props.claimData[0].id)
            setPromotionTheme(props.claimData[0].promotion_theme)
            setPromotionCategory(props.claimData[0].promotion_category)
            setPromotionType(props.claimData[0].promotion_type)
            setPromotionDivision(props.claimData[0].promotion_division)
            setUsageCode(props.claimData[0].usage_code)
            setSalesProgrameCode(props.claimData[0].sales_program_code)
        }

    }, [props.claimData])

    const handleChange = (fieldName) => {
        setSelectedRowName(fieldName);
        if (fieldName == 'promotion_theme') {
            setFieldValue(promotionTheme)
        }
        if (fieldName == 'promotion_category') {
            setFieldValue(promotionCategory)
        }
        if (fieldName == 'promotion_type') {
            setFieldValue(promotionType)
        }
        if (fieldName == 'promotion_division') {
            setFieldValue(promotionDivision)
        }
        if (fieldName == 'usage_code') {
            setFieldValue(usageCode)
        }
        if (fieldName == 'sales_program_code') {
            setFieldValue(salesProgramCode)
        }
    };

    function handleSubmit(clear) {
        var data;
        if (clear === 'clear') {
            data = {
                "promotion_theme": selectedRowName == 'promotion_theme' ? [] : promotionTheme,
                "promotion_category": selectedRowName == 'promotion_category' ? [] : promotionCategory,
                "promotion_type": selectedRowName == 'promotion_type' ? [] : promotionType,
                "promotion_division": selectedRowName == 'promotion_division' ? [] : promotionDivision,
                "usage_code": selectedRowName == 'usage_code' ? [] : usageCode,
                "sales_program_code": selectedRowName == 'sales_program_code' ? [] : salesProgramCode
            };

        } else {
            data = {
                "promotion_theme": promotionTheme,
                "promotion_category": promotionCategory,
                "promotion_type": promotionType,
                "promotion_division": promotionDivision,
                "usage_code": usageCode,
                "sales_program_code": salesProgramCode
            };
        }
        if (currentID != 0) {
            props.onSubmit(data, 'edit', currentID);
        }
        else {
            props.onSubmit(data, 'create');
        }
        setSelectedRowName('');
        setCurrentID(0)
        setFieldValue([])

    }

    const handleFieldValue = (newValue) => {
        // setFieldValue([...fieldValue, newValue]);
        setFieldValue(newValue);
        if (selectedRowName == 'promotion_category') {
            // setPromotionCategory([...promotionCategory, newValue]);
            setPromotionCategory(newValue);
        }
        if (selectedRowName == 'promotion_division') {
            setPromotionDivision(newValue);
        }
        if (selectedRowName == 'promotion_theme') {
            setPromotionTheme(newValue);
        }
        if (selectedRowName == 'promotion_type') {
            setPromotionType(newValue);
        }
        if (selectedRowName == 'usage_code') {
            setUsageCode(newValue)
        }
        if (selectedRowName == 'sales_program_code') {
            setSalesProgrameCode(newValue)
        }
    }
    const handleDeleteFieldValue = (newValue) => {
        var deleteFieldValue = fieldValue.filter(item => item !== newValue)
        setFieldValue(deleteFieldValue)
        if (selectedRowName == 'promotion_category') {
            setPromotionCategory(deleteFieldValue);
        }
        if (selectedRowName == 'promotion_division') {
            setPromotionDivision(deleteFieldValue);
        }
        if (selectedRowName == 'promotion_theme') {
            setPromotionTheme(deleteFieldValue);
        }
        if (selectedRowName == 'promotion_type') {
            setPromotionType(deleteFieldValue);
        }
    }


    return (
        <div className={clsx(classes.root, className)}>

            <div
                className={clsx(classes.addRoot, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container >

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    style={{ paddingLeft: 10, marginTop: -6 }}
                                >
                                    <LabelText classes={{ root: classes.fontSetting }} heading='Field Name' capitalize={true} data={selectedRowName ? selectedRowName.replace(/_/g, ' ') : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        style={{ paddingLeft: 15, marginTop: 10 }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} error={false}>
                                            Field Value
                                        </FormLabel>
                                        <div>
                                            <ChipInput data={fieldValue} onChange={handleFieldValue} />
                                            {/* <ChipInput
                                                classes={{
                                                    root: classes.rootContainer,
                                                    chip: classes.chip,
                                                    input: classes.input,
                                                    inputRoot: classes.inputRoot,
                                                    label: classes.chipLabel
                                                }}
                                                value={fieldValue}
                                                onAdd={(chips) => handleFieldValue(chips)}
                                                onDelete={(chip) => handleDeleteFieldValue(chip)}
                                                disableUnderline={true}
                                                className={clsx({
                                                    [classes.textInput]: true
                                                })}
                                                blurBehavior='add'
                                            /> */}
                                        </div>


                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.buttonContainer}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ padding: 5 }}
                                        startIcon={<CheckIcon />}
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        onClick={() => handleSubmit()}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        startIcon={<CheckIcon />}
                                        onClick={() => handleSubmit('clear')}

                                    >
                                        Reset
                                    </Button>


                                </Grid>

                            </Grid>

                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>

                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >

                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell className={classes.tabHead} style={{ width: '40%' }}>Field Name</TableCell>
                                                <TableCell className={classes.tabHead}>Field Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody  >
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('promotion_category')}>
                                                <TableCell classes={{ root: classes.fontSettings }}>
                                                    Promotion Category
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSettings }} onClick={() => handleChange('promotion_category')}>
                                                    {claimConfigData && claimConfigData.promotion_category ? claimConfigData.promotion_category.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('promotion_division')}>
                                                <TableCell classes={{ root: classes.fontSettings }}>
                                                    Promotion Division
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSettings }} onClick={() => handleChange('promotion_division')}>
                                                    {claimConfigData && claimConfigData.promotion_division ? claimConfigData.promotion_division.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('promotion_theme')}>

                                                <TableCell classes={{ root: classes.fontSettings }}>
                                                    Promotion Theme
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSettings }} onClick={() => handleChange('promotion_theme')}>
                                                    {claimConfigData && claimConfigData.promotion_theme ? claimConfigData.promotion_theme.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('promotion_type')}>
                                                <TableCell classes={{ root: classes.fontSettings }}>
                                                    Promotion Type
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSettings }} onClick={() => handleChange('promotion_type')}>
                                                    {claimConfigData && claimConfigData.promotion_type ? claimConfigData.promotion_type.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('usage_code')}>
                                                <TableCell classes={{ root: classes.fontSettings }}>
                                                    Usage Code
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSettings }} onClick={() => handleChange('usage_code')}>
                                                    {claimConfigData && claimConfigData.usage_code ? claimConfigData.usage_code.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('sales_program_code')}>
                                                <TableCell classes={{ root: classes.fontSettings }}>
                                                    Sales Program Code
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSettings }} onClick={() => handleChange('sales_program_code')}>
                                                    {claimConfigData && claimConfigData.sales_program_code ? claimConfigData.sales_program_code.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Grid>
                    </div>

                </Card>
            </div >
        </div >
    );

};

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        claimData: state.calculationAnalysisData.budgetData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, field, type) => dispatch(createBudgetClaimConfiguration(data, field, type)),
        getClaimData: () => dispatch(getBudgetingConfig()),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimConfigurationTab);