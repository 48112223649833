import React, { useEffect } from 'react';
import Chart from "react-apexcharts";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import clsx from 'clsx';
import { makeStyles, useTheme } from  "@mui/styles";
import {
    Paper, TablePagination, Grid, Select, FormLabel, MenuItem, Button,
    TableCell, Typography, TableContainer, Table, TableHead, TableRow, TableBody,
    Checkbox, ListItemText, Divider, TextField, Drawer, AppBar, Toolbar, CssBaseline, IconButton,
} from '@mui/material';
import { loadValuesBasedOnAppTypeAndField, getDefaultValues, getDefaultValuesKeyFigure, timeToMarket, getCustomerMasterData, allowedApps } from '../../redux/actions';
import { connect } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Menu, ChevronLeft, ChevronRight, BarChart, List } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { AllOtherAttributeMasterComponent, ChipDropdown, SearchDropdown } from "../../components/Inputs";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        fontSize: theme.typography.h2.fontSize
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        position: 'absolute',
        flexShrink: 0,
        zIndex: 998
    },
    drawerPaper: {
        marginTop: 65
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        fontSize: theme.typography.h2.fontSize,
        paddingLeft: 10
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textInput: {
        border: '1px solid #E0E0E0',
        width: '100%',
        borderRadius: 5,
        color: '#1675e0'
    },
    select: {
        width: '100%'
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    table: {
        marginTop: 0
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    buttonContainer:{
        justifyContent: "center",
        display: "flex",
        paddingTop: 28,
        paddingBottom: 30,
        marginTop:'-0.5rem',
        marginLeft:'1rem'
    }
}));

const TimeToMarket = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,
    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [mappingValuesSet, setMappingValuesSet] = React.useState({});
    const [listOpen, setListOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [usersId, setUsersId] = React.useState([]);
    const isAllSelected =
        Object.keys(mappingValuesSet).length > 0 && selected.length === Object.keys(mappingValuesSet).length;
    const [chartOptionsPP, setChartOptionsPP] = React.useState({});
    const [chartSeriesPP, setChartSeriesPP] = React.useState([]);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === Object.keys(mappingValuesSet).length ? [] : usersId);
            return;
        }
        setSelected(newSelected)

    }
    function onAdd() {
        setAttributeName(selected)
        setListOpen(false)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])
    }
    const handleStartDate = (e) => {
        setStartDate(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }
    function handleOpen() {
        setSelected(attributeName)
        setListOpen(true)
    }
    useEffect(() => {
        // props.onSubmit('reset_store');
        props.allowedApps();
    }, [])


    useEffect(() => {
        props.onLoadingDefaultCR('Customer Rebate', 'Direct - Sales Data');
        props.onLoadingDefault('Customer Rebate', 'Direct - Sales Data');
        props.onCheckCustomerTable();
    }, []);


    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [calculationLevel, setCalculationLevel] = React.useState(null);
    const attributeNames = [
        'material_group', 'material_number', 'product_hierarchy'
    ]

    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
    }, []);

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = [];
            var tempNameArray = [];
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNumberList(tempArray)
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])

    const handleSubmit = () => {

        Object.entries(attributeObjectAPI).map(([key, value]) => {
            if (attributeName.includes(key)) {

            } else {
                delete attributeObjectAPI[key]
            }
        })
        var formData = {
            "start_date": Moment(startDate).format('MM/DD/YYYY'),
            "end_date": Moment(endDate).format('MM/DD/YYYY'),
            "aggregate_field": calculationLevel,
            "attributes": attributeObjectAPI
        }
        props.onSubmit(formData);
        setOpen(false);
    }
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeName, setAttributeName] = React.useState([]);
    const [resultType, setResultType] = React.useState('chart');
    const handleResultType = (event, nextView) => {
        setResultType(nextView);
    };
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records[0]) {
            if (props.dropdownData.records[0].field_id === "attribute_name") {
                setAttributeNameData(props.dropdownData.records[0].drop_down_value_keys);
            }
        }
    }, [props.dropdownData])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records[0]) {
            var commonKeys = props.dropdownData.records[0].drop_down_value_keys;
            var keys = props.dropdownData.records[0].drop_down_values;
            var myobj = {};
            keys.map(function (obj, index) {
                myobj[commonKeys[index]] = obj;
            });
            setMappingValuesSet(myobj);
            setUsersId(commonKeys)
        }

    }, [attributeNameData]);
    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    const handleAttributeValues = (value, item) => {
        var attributeObject = [];
        attributeObject.push({
            [item]: value
        });
        if (item === 'customer_number') {
            value = [value]
        }
        setAttributeObjectAPI(prevState => ({
            ...prevState,
            [item]: value
        }));
    }
    const [filterItem, setFilterItem] = React.useState('');
    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    const [timeToMarket, setTimeToMarket] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleCalculationLevel = (e) => {
        setCalculationLevel(e.target.value);
        setTimeToMarket([]);
    }
    useEffect(() => {
        setTimeToMarket(props.timeToMarket)
        if (props.timeToMarket && props.timeToMarket.length > 0 && props.timeToMarket[0]) {
            var forDeletion = ['delivery_doc_number', 'fastest', 'slowest', 'average']
            var keys = Object.keys(props.timeToMarket[0]);
            var arr = keys.filter(item => !forDeletion.includes(item));
            setFilterItem(arr);
        }
    }, [props.timeToMarket])
    useEffect(() => {
        var falstestArr = [];
        var slowestArr = [];
        var averageArr = [];
        var filteringArr = [];
        if (props.timeToMarket && Object.keys(props.timeToMarket).length > 0) {
            props.timeToMarket.map(item => {
                falstestArr.push(item['fastest']);
                slowestArr.push(item['slowest']);
                averageArr.push(item['average']);
                filteringArr.push(item[filterItem]);
            })
            setChartSeriesPP([{
                name: 'Fastest',
                data: falstestArr
            }, {
                name: 'Slowest',
                data: slowestArr
            },
            {
                name: 'Average',
                data: averageArr
            }])
            setChartOptionsPP(
                {
                    chart: {
                        type: 'bar',
                        height: 'auto'
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: filteringArr,
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    fill: {
                        opacity: 1
                    }
                }
            )
        }

    }, [filterItem])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar style={{ position: 'absolute', marginTop: 65, display: open && 'none' }}>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                        size="large">
                        <Menu />
                    </IconButton>
                    <Typography className={{ root: classes.header }} variant="h1" color='primary' style={{marginLeft:'1rem'}}> Time to Market </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="top"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                <Typography variant="h3" color='primary'> Time to Market </Typography>
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider
                    sx={{
                        opacity: "0.6"
                    }} />
                <Grid container className={classes.root} >
                <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                    <Grid container
                                item
                                md={5}
                                xs={12}
                            >
                        <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    Start Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    // width: 269
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    End Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    // width: 269
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5, marginRight: 15 }}>
                                    Analysis Level
                                </FormLabel>
                                <Select
                                    value={calculationLevel}
                                    onChange={handleCalculationLevel}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    <MenuItem value="" style={{ height: 30 }} />
                                    {attributeNames &&
                                        attributeNames.map(item => {
                                            return (
                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                    {item.replace(/_/g, ' ')}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom:5}}>
                                    Filter Criteria
                                </FormLabel>
                                <Select
                                    value={attributeName}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpen}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                        Object.entries(mappingValuesSet).map(([key, value]) => {
                                            return (
                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, key)}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selected.indexOf(key) > -1} />
                                                    <ListItemText primary={value} />
                                                </MenuItem>
                                            )
                                        })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>

                        {attributeName.map((item) => {
                            return (
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    {/* <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}> */}
                                        <FormLabel required
                                            style={{ color: 'grey', fontSize: 14, marginBottom: 5, textTransform: 'capitalize' }}>
                                            {item.replace(/_/g, ' ')}
                                        </FormLabel>
                                        {item == 'material_number' || item == 'customer_number' || item == 'supplier_number' || item == 'employee_number' ?
                                            <div style={{ marginTop: -5 }}>
                                                <SearchDropdown id='payment_single' multiple
                                                    table={item == 'supplier_number' ? 'supplier_master' : item == 'employee_number' ? 'employee_master'
                                                        : item == 'material_number' ? 'material_master' : 'customer_master'}
                                                    keyName={item}
                                                    description={true}
                                                    onChange={(value) => handleAttributeValues(value, item)}
                                                />
                                            </div>
                                            :
                                            item === 'customer_chain' || item === 'customer_group' || item === 'material_group' || item === 'product_hierarchy' || item === 'company_code' || item === 'sales_org' || item === 'supplier_group' ?
                                                <div style={{ marginTop: -10 }}>
                                                    <AllOtherAttributeMasterComponent attributeName={item} value={attributeObjectAPI[item]} onChange={(e) => handleAttributeValues(e, item)} prevalue={attributeObjectAPI[item]} withOutLabel={true} id={item} multiple />
                                                </div>
                                                :
                                                <div style={{ marginTop: -15 }}>
                                                    <ChipDropdown id={item} data={attributeObjectAPI[item]} onChange={(value) => handleAttributeValues(value, item)}
                                                    />
                                                </div>
                                            }
                                </Grid>
                            );
                        })}
                        <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleSubmit}
                                    twoline="true"
                                    disabled={startDate && endDate && calculationLevel ? false : true} 
                                >
                                    <DirectionsRunIcon />
                                </Button>
                            </div>
                    </Grid>
                </Grid>
            </Drawer>
            <div>
                    {timeToMarket && timeToMarket.length > 0 ?
                        <div>
                            <ToggleButtonGroup value={resultType} exclusive onChange={handleResultType}
                                style={{ display: 'flex', justifyContent: 'left', flexDirection: 'row',marginTop:'3rem' }}
                                size={'small'}>
                                <ToggleButton value="chart" aria-label="list">
                                    <BarChart />
                                </ToggleButton>
                                <ToggleButton value="list" aria-label="module">
                                    <List />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {resultType === 'chart' ?
                                <Chart options={chartOptionsPP} type="bar" series={chartSeriesPP} />
                                :
                                <div>
                                    {timeToMarket && timeToMarket.length > 0 &&
                                        <Paper>
                                            <TableContainer style={{ marginTop: 10 }}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="right" style={{ textTransform: 'capitalize' }}>{calculationLevel.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell align="right">Average</TableCell>
                                                            <TableCell align="right">Fastest</TableCell>
                                                            <TableCell align="right">Slowest</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {timeToMarket && timeToMarket.length > 0 && timeToMarket.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">
                                                                        {row[calculationLevel]}
                                                                    </TableCell>
                                                                    <TableCell align="right">{row.average}</TableCell>
                                                                    <TableCell align="right">{row.fastest}</TableCell>
                                                                    <TableCell align="right">{row.slowest}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component="div"
                                                count={timeToMarket.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    }
                                </div>
                            }
                        </div>

                        :
                        <Typography variant='h4' style={{marginTop:'4rem',marginLeft:'4rem'}}>
                            There is no data to show now.
                        </Typography>
                    }

                </div>
        </div >
    );
}


const mapStateToProps = state => {
    return {
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        dropdownData: state.addMultipleConfigurationData.attributeNameData,
        customerMasterData: state.customerData.customerMasterData,
        timeToMarket: state.initialData.timeToMarket
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
        onSubmit: (data) => dispatch(timeToMarket(data)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
        allowedApps: () => dispatch(allowedApps())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TimeToMarket);