import React, { useEffect } from 'react';
import {
    Card,
    CardHeader,
    Grid,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../../components/Inputs';
import Moment from 'moment';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const Accrual = props => {

    const classes = useStyles();
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })
    }

    return (
        <div>
            <div className={clsx(classes.root)}
                style={{
                    display: (
                        props.data && props.data.auto_run_ppa && props.fieldData && props.fieldData.auto_run_ppa ||
                        props.data.postings_start_date && props.data.postings_start_date != '0001-01-01T00:00:00Z' && props.fieldData.postings_start_date ||
                        props.data.postings_end_date && props.data.postings_end_date != '0001-01-01T00:00:00Z' && props.fieldData.postings_end_date ||
                        props.data.postings_block && props.fieldData.postings_block ||
                        props.data.posting_approval && props.fieldData.posting_approval ||
                        props.fieldData.suppress_negative_rebate
                    ) ? 'block' : 'none'
                }}
            >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="ADDITIONAL DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            {props.data && props.fieldData &&
                                <div className={classes.container}>
                                    <Grid container className={classes.gridContainer}>

                                        {props.data && props.data.auto_run_ppa && props.fieldData && props.fieldData.auto_run_ppa &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.auto_run_ppa.current} data={props.data.auto_run_ppa} twoline='true' />
                                            </Grid>
                                        }


                                        {props.data.postings_start_date && props.data.postings_start_date != '0001-01-01T00:00:00Z' && props.fieldData.postings_start_date &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.postings_start_date.current} data={Moment.utc(props.data.postings_start_date).format('MM/DD/YYYY')} twoline='true' />
                                            </Grid>
                                        }
                                        {props.data.postings_end_date && props.data.postings_end_date != '0001-01-01T00:00:00Z' && props.fieldData.postings_end_date &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.postings_end_date.current} data={Moment.utc(props.data.postings_end_date).format('MM/DD/YYYY')} twoline='true' />
                                            </Grid>
                                        }
                                        {props.data.postings_block && props.fieldData.postings_block &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.postings_block.current} data={props.data.postings_block} twoline='true' />
                                            </Grid>
                                        }
                                        {props.data.posting_approval && props.fieldData.posting_approval &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.posting_approval.current} data={props.data.posting_approval} twoline='true' />
                                            </Grid>
                                        }
                                        {props.fieldData.suppress_negative_rebate &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.suppress_negative_rebate.current}
                                                    data={props.data.suppress_negative_rebate && props.data.suppress_negative_rebate == true ? 'Yes' : 'No'} twoline='true' />
                                            </Grid>
                                        }
                                    </Grid>
                                </div>
                            }
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );

}

const mapStateToProps = state => ({
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
});

const mapDispatchToProps = dispatch => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Accrual);