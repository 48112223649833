import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, IconButton, Card, CardHeader, FormLabel, Select, MenuItem, Input, ListItemText,
    Checkbox, OutlinedInput, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown, MultiSelectDropdownKeyVal } from '../../../components/Inputs';

import { getDefaultValues, getDefaultValuesAllDataNew, massUpdateContract, getLblDispDesFieldValue } from '../../../redux/actions';
import AddIcon from '@mui/icons-material/Add';
import { ChipInput, SearchDropDownMasterDataNew } from '../../../components/Inputs';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { RotateLoader, HashLoader } from "react-spinners";
import Autocomplete from '@mui/material/Autocomplete';
import UpdateField from './UpdateField';
import { CalculationRules } from '../../AddContract/components';
import { EligibilityRules } from '../Containers'
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        //width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        // height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    chargeBackLabel: {
        paddingTop: 15,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: 15
    },
    spinner: {
        height: '100vh'
    },
    heading: {
        padding: '10px 5px',
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 30,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    baseColor: {
        color: theme.palette.primary.main,
    },
    inputTwoLineOutlined: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 36,
    },
}));



const MassContractUpdateForm = props => {

    const currentApplication = sessionStorage.getItem('application')
    const classes = useStyles();
    const { className } = props;
    //new fields
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState(currentApplication === 'Pricing' ? 'Price Difference' : '');
    const [incentiveRateTypeArray, setIncentiveRateTypeArray] = React.useState([]);
    const [incentiveRateType, setIncentiveRateType] = React.useState(currentApplication === 'Pricing' ? 'Contract' : '');
    const tierPeriodArray = ['Current Period', 'Previous Period', 'Previous 12 months'];
    const tierGrowthPeriodArray = ['Previous Year Same Period', 'Previous Period'];

    const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [labelNewAPIData, setLabelNewAPIData] = React.useState([]);
    const [calculationMethodDisable, setCalculationMethodDisable] = React.useState(currentApplication === 'Pricing' ? true : false);
    const [incentiveRateTypeDisable, setIncentiveRateTypeDisable] = React.useState(currentApplication === 'Pricing' ? true : false);

    const [filterData, setFilterData] = React.useState({});
    const [attributes, setAttributes] = React.useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [eligibilityData, setEligibilityData] = React.useState([]);
    const [calculationData, setCalculationData] = React.useState([]);
    const [updatedData, setUpdatedData] = React.useState({});
    const [activeCalc, setActiveCalc] = React.useState(false);
    const [activeEligibility, setActiveEligibility] = React.useState(false);
    const [activeFilter, setActiveFilter] = React.useState(false);
    const [activeUpdate, setActiveUpdate] = React.useState(false);
    const [dropdownAttributeData, setDropdownAttributeData] = React.useState([]);
    useEffect(() => {
        var data = { "data_source": "contract_header", "application_type": "Customer Rebate" };
        props.getLblDispDesFieldValue(data, 'post');
    }, []);
    useEffect(() => {
        if (currentApplication && sourceDataType) {
            props.onLoadingDefault(currentApplication, sourceDataType);
        }
    }, [currentApplication, sourceDataType]);
    useEffect(() => {
        var obj = []
        // if (props.dropdownAttributeData && props.dropdownAttributeData.records) {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records.length > 0 && props.dropdownAttributeData.records[0].label_names) {
            Object.entries(props.dropdownAttributeData.records[0].label_names).map((item) => {
                obj.push({ 'desc': item[1], 'key': item[0] })
            })
            setDropdownAttributeData(obj)
        }
        else {
            setDropdownAttributeData([])
        }
    }, [props.dropdownAttributeData])
    var executed = false;
    useEffect(() => {
        //{ 'field_id': 'contract_number', 'name': 'Contract Number', 'type': 'string', 'drop_down_value_keys': [] }
        var listData = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                // .filter(item => item.key != 'key_figure_names' && item.key != 'attribute_names' && item.key != 'accrual_amount' && item.key != 'change_request_id'
                //     && item.key != 'chargeback_calculation_rules' && item.key != 'has_active_update_request' && item.key != 'is_update_approval_contract' && item.key != 'payment_amount'
                //     && item.key != 'update_contract_id' && item.key != 'update_request_closed' && item.key != 'target_rules'
                // )
                .filter(item => item.key != 'contract_dependency' && item.key != 'calculation_rule_modifier' && item.key != 'commitment_achievement' && item.key != 'commitment_type')
                .map((item, i) => {
                    if (item.key != 'key_figure_name' && item.key != 'attribute_name' && item.key != 'application_type' &&
                        item.key != 'calculation_method' && item.key != 'incentive_rate_type' && item.key != 'approval_status' && item.key != 'tier_evaluation_level' && item.key != 'tier_period' && item.key != 'tier_type') {
                        var newArray = []
                        listData.push({
                            'field_id': item.key, 'name': item.key == "approval_group_id" ? "Approval Group" : item.current, 'type': item.key === 'contract_purpose' || item.key === 'accrual_level' || item.key === 'accrual_frequency'
                                || item.key === 'contract_dependency' || item.key === 'amortization_value' || item.key === 'commitment_achievement'
                                || item.key === 'copa_level' || item.key === 'currency' || item.key === 'evaluate_sales_bundle' || item.key === 'maximum_amount_level'
                                || item.key === 'maximum_amount_period' || item.key === 'pass_through_payment_partner_role' || item.key === 'pass_through' || item.key === 'payment_aggregation_level'
                                || item.key === 'payment_frequency' || item.key === 'payment_level' || item.key === 'payment_method' || item.key === 'payment_partner_role'
                                || item.key === 'posting_approval' || item.key === 'postings_block' || item.key === 'sales_analyst'
                                || item.key === 'source_data_type' //|| item.key === 'tier_period' || item.key === 'tier_type'
                                ? 'DROPDOWN.SINGLE' : item.type,
                            'drop_down_value_keys': item.drop_down_reference_value ?
                                item.drop_down_reference_value
                                : []
                        })

                    }
                    if (item.key === "approval_status") {
                        // var temp = ['New', 'Submit for Approval', 'Accepted', 'Rejected', 'Changes Accepted']
                        var temp = [
                            {
                                "desc": "New",
                                "key": "new"
                            },
                            {
                                "desc": "Submit for approval",
                                "key": "submit_approval"
                            },
                            {
                                "desc": "Accepted",
                                "key": "acc"
                            },
                            {
                                "desc": "Rejected",
                                "key": "rej"
                            },
                            {
                                "desc": "Changes Approved",
                                "key": "changes_acc"
                            }
                        ]

                        listData.push({ 'field_id': item.key, 'name': item.current, 'type': 'DROPDOWN.SINGLE', 'drop_down_value_keys': temp })
                    }
                    if (item.key === 'source_data_type') {
                        setSourceDataTypeArray({ ...item.drop_down_reference_value, 'edited_name': item.current });
                    }
                    if (item.key === 'tier_evaluation_level' && (calculationMethod === 'Tiered Volume' || calculationMethod === 'Tiered Growth')) {
                        listData.push({ 'field_id': item.key, 'name': item.current, 'type': item.type, 'drop_down_value_keys': dropdownAttributeData })
                    }
                    if (item.key === 'tier_type' && (calculationMethod === 'Tiered Volume' || calculationMethod === 'Tiered Growth')) {
                        listData.push({
                            'field_id': item.key, 'name': item.current, 'type': 'DROPDOWN.SINGLE', 'drop_down_value_keys': item.drop_down_reference_value ? item.drop_down_reference_value : []
                        })
                    }
                    if (item.key === 'tier_period' && (calculationMethod === 'Tiered Volume' || calculationMethod === 'Tiered Growth')) {
                        listData.push({
                            'field_id': item.key, 'name': item.current, 'type': 'DROPDOWN.SINGLE', 'drop_down_value_keys': item.drop_down_reference_value ? item.drop_down_reference_value : []
                        })
                    }
                    if (item.key === 'calculation_method') {
                        setCalculationMethodArray({ ...item.drop_down_reference_value, 'edited_name': item.current });
                    }
                    if (item.key === 'incentive_rate_type') {
                        setIncentiveRateTypeArray({ ...item.drop_down_reference_value, 'edited_name': item.current });
                    }


                })
            setAttributeArray(listData.filter((v, i, a) => a.findIndex(v2 => (v2.field_id === v.field_id)) === i))
        }
    }, [props.tableData, dropdownAttributeData, calculationMethod])
    const handleSubmit = () => {
        var formData = {
            "filters": {
                "application_type": currentApplication,
                "calculation_method": calculationMethod,
                "source_data_type": sourceDataType,
                "incentive_rate_type": incentiveRateType,
                ...filterData
            },
            "update_fields": JSON.parse(sessionStorage.getItem('updatedFields')),
            "eligibility_rules": eligibilityData,
            "calculation_rules": calculationData
        }
        props.onSubmit(currentApplication, formData)
    };

    function handleSourceDataType(e) {
        setSourceDataType(e.target.value)
    }



    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === attributeArray.length ? [] : attributeArray.map((item, i) => item.field_id));
            setAllSelect(selected.length === attributeArray.length ? false : true)
            setSelectedDetail(selected.length === attributeArray.length ? [] : attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
        //setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
        //setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [level]: selected })
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        setSelectedDetail([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(attributeArray && attributeArray.length > 0 && attributes.length === attributeArray.length)
        setSelectedDetail(attributesData)
    }
    const handleCalculationMethod = (e) => {
        setIncentiveRateType('');
        setCalculationMethod(e.target.value);
        if (e.target.value !== 'Flat Rate' && e.target.value !== 'Fixed Amount') {
            setIncentiveRateType('Contract')
        }
    }
    const handleIncentiveRateType = (e) => {
        setIncentiveRateType(e.target.value)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const handleFilterData = (data, item, type) => {
        var chipData = []
        var temp = {}
        if (type == 'start_date' || type == 'end_date') {
            temp = filterData[item.field_id] ? filterData[item.field_id] : {}
            setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
        }
        if (data && data.length > 0) {
            if (type == 'chip') {
                chipData = filterData[item.field_id] ? filterData[item.field_id] : []
                chipData = [...chipData, data]
                setFilterData({ ...filterData, [item.field_id]: chipData })
            }
            else if (type == 'NUMERIC')
                setFilterData({ ...filterData, [item.field_id]: Number(data) })
            else
                setFilterData({ ...filterData, [item.field_id]: data })
        }
    }
    function handleEligibilityRules(values) {

        setEligibilityData(values);
    };
    function handleCalculationRules(values) {
        setCalculationData(values);

    };
    function handleUpdateField(values) {
        setUpdatedData(values)
    }
    function handleActiveCalc() {
        if (calculationMethod && incentiveRateType)
            setActiveCalc(!activeCalc)
    }
    function handleActiveEligibility() {
        if (calculationMethod && incentiveRateType)
            setActiveEligibility(!activeEligibility)
    }
    return (
        <div
            className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <Typography variant="h1"> Mass Contract Update</Typography>
            </div>
            <div className={classes.container}>
                <Grid container >
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ marginTop: "-2px" }}
                    >
                        <Dropdown heading={'Application Type'} style={{ padding: '0.4rem' }} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <div className={classes.chargeBackLabel}>
                            <FormLabel required>
                                {sourceDataTypeArray.edited_name}
                            </FormLabel>
                            <Select
                                value={sourceDataType}
                                onChange={handleSourceDataType}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {sourceDataTypeArray && Object.values(sourceDataTypeArray).length > 0 && Object.values(sourceDataTypeArray)
                                    .map((item, index) => {
                                        return (
                                            <MenuItem value={item.key} key={index}>
                                                {item.desc}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <div className={classes.chargeBackLabel} >
                            <FormLabel required>
                                {calculationMethodArray.edited_name}
                            </FormLabel>
                            <Select
                                disableUnderline
                                value={calculationMethod}
                                onChange={handleCalculationMethod}
                                displayEmpty
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true,
                                    [classes.error]: props.error
                                })}
                                disabled={calculationMethodDisable}
                            >
                                <MenuItem value="" style={{ height: 30 }}>
                                </MenuItem>
                                {calculationMethodArray && Object.values(calculationMethodArray).length > 0 && Object.values(calculationMethodArray)
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.key} key={item} style={{ textTransform: 'capitalize' }} >
                                                {item.desc}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <div className={classes.chargeBackLabel}>
                            <FormLabel required>
                                {incentiveRateTypeArray.edited_name}
                            </FormLabel>
                            <Select
                                disableUnderline
                                value={incentiveRateType}
                                onChange={handleIncentiveRateType}
                                displayEmpty
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true,
                                    [classes.error]: props.error
                                })}
                                disabled={

                                    (calculationMethod && calculationMethod !== 'Flat Rate' && calculationMethod !== 'Fixed Amount')
                                        ?
                                        true
                                        :
                                        incentiveRateTypeDisable}
                            >
                                <MenuItem value="" style={{ height: 30 }}>
                                </MenuItem>
                                {incentiveRateTypeArray && Object.values(incentiveRateTypeArray).length > 0 && Object.values(incentiveRateTypeArray)
                                    .filter(item => !((calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference' || calculationMethod === 'Multi Axis' || calculationMethod === 'Tiered Growth' || calculationMethod === 'Pricing Calculation')
                                        && item === 'Attribute'))
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}
                                                disabled={
                                                    (calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference')
                                                        && item.key === 'Attribute'
                                                        ? true :
                                                        false

                                                }
                                            >
                                                {item.desc}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>

                    </Grid>
                </Grid>
            </div>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<>
                            <IconButton
                                aria-label="add"
                                onClick={() => setActiveFilter(!activeFilter)}
                                size="large">
                                <AddIcon color="primary" />
                                <span className={classes.baseColor} style={{ fontSize: 18 }}>ADD FILTERS</span>
                            </IconButton>
                        </>
                        }
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    {activeFilter ?
                        <div className={classes.container}>
                            <Grid container style={{ marginBottom: 20 }}>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: 'flex' }}
                                >
                                    <FormLabel style={{ color: 'grey', marginRight: 20, paddingTop: 5 }}>
                                        Select Required Filters
                                    </FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id={'filter-list'}
                                        multiple
                                        value={attributes ? attributes : []}
                                        style={{ maxHeight: "50px", width: '70%', color: 'grey' }}
                                        input={<OutlinedInput />}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={() => handleOpen()}
                                        onClose={() => setSelectOpen(false)}
                                        open={selectOpen}
                                        renderValue={(filter) => {
                                            let type = []
                                            filter.map(itemX => {
                                                attributeArray.filter(item => item.field_id == itemX).map((item, i) => {
                                                    if (item.name)
                                                        type.push(item.name)
                                                })

                                            })
                                            return type.join(',')
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={allSelect} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {attributeArray && attributeArray.length > 0 && attributeArray
                                            .sort((a, b) => a.name > b.name ? 1 : -1)
                                            .map((item, i) => {
                                                return (

                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.field_id, item)} value={item.field_id} key={item.field_id} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected && selected.indexOf(item.field_id) > -1}
                                                        />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <MenuItem value='' ></MenuItem>

                                        <div className={classes.dropdownAction}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container >
                                {attributesData &&
                                    attributesData.map((item, i) => {
                                        return (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                {item.type == 'DROPDOWN.STRING' ?
                                                    <div>
                                                        <MultiSelectDropdownKeyVal capitalize={true} heading={item.name} listArray={item.drop_down_value_keys} data={filterData[item.field_id] ? filterData[item.field_id] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                                    </div>
                                                    :
                                                    item.type == 'BOOL' ?
                                                        <div style={{ paddingTop: 15 }}>
                                                            <FormLabel required>
                                                                {item.name}
                                                            </FormLabel>
                                                            <Select
                                                                value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                                onChange={(e) => handleFilterData(e.target.value, item)}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                            >
                                                                <MenuItem value={'true'} key={'true'}>
                                                                    Yes
                                                                </MenuItem>
                                                                <MenuItem value={'false'} key={'false'}>
                                                                    No
                                                                </MenuItem>
                                                            </Select>
                                                        </div>
                                                        :
                                                        item.type == 'TIME' ?
                                                            <div style={{ paddingTop: 15 }}>
                                                                <FormLabel>
                                                                    {item.name}
                                                                </FormLabel>
                                                                <div style={{ display: 'flex' }}>
                                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <KeyboardDatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 0 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        marginTop: 10,
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%',
                                                                                        marginRight: 5
                                                                                    }
                                                                                }}
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                                onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </MuiPickersUtilsProvider>
                                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <KeyboardDatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 0 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        marginTop: 10,
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                                onChange={(e) => handleFilterData(e, item, 'end_date')}

                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </MuiPickersUtilsProvider>
                                                                </div>
                                                            </div>
                                                            :
                                                            item.type == "DROPDOWN.SINGLE" ?
                                                                <div style={{ paddingTop: 15 }}>
                                                                    <FormLabel required>
                                                                        {item.name}
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={filterData[item.field_id]}
                                                                        onChange={(e) => handleFilterData(e.target.value, item)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                        </MenuItem>
                                                                        {item.drop_down_value_keys && Object.values(item.drop_down_value_keys).length > 0 && Object.values(item.drop_down_value_keys)
                                                                            .map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item.key} key={item.key} >
                                                                                        {item.desc}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}


                                                                    </Select>
                                                                </div>
                                                                :
                                                                item.type == 'NUMERIC' ?
                                                                    <div style={{ paddingTop: 15 }}>
                                                                        <FormLabel>
                                                                            {item.name}
                                                                        </FormLabel>
                                                                        <OutlinedInput
                                                                            type={'number'}
                                                                            classes={{ root: classes.inputTwoLineOutlined }}
                                                                            value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                                            onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                                        />

                                                                    </div> :
                                                                    item.field_id == 'contract_number' ?
                                                                        <div style={{ paddingTop: 15 }}>
                                                                            <FormLabel>
                                                                                {item.name}
                                                                            </FormLabel>
                                                                            {/* <OutlinedInput
                                                                                type={'number'}
                                                                                classes={{ root: classes.inputTwoLineOutlined }}
                                                                                value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                                                onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                                            /> */}
                                                                            <div style={{ marginTop: "11px" }}>
                                                                                <SearchDropDownMasterDataNew id='contract'
                                                                                    prevalue={filterData[item.field_id] ? filterData[item.field_id] : []}
                                                                                    multiple
                                                                                    table='contract_header' keyName='contract_number'
                                                                                    description={true}
                                                                                    onChange={(e, newValue) =>
                                                                                        handleFilterData(e, item)
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div> :
                                                                        <div style={{ paddingTop: 15, marginTop: '0.6rem' }}>
                                                                            <FormLabel>
                                                                                {item.name}
                                                                            </FormLabel>
                                                                            <ChipInput data={filterData[item.field_id] ? filterData[item.field_id] : []} onChange={(e) => handleFilterData(e, item)} />
                                                                        </div>
                                                }
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </div>
                        : ''}
                </form>
            </Card>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<><IconButton
                            aria-label="settings"
                            onClick={() => setActiveUpdate(!activeUpdate)}
                            size="large">
                            <AddIcon color="primary" />
                            <span className={classes.baseColor} style={{ fontSize: 18 }}>ADD UPDATE FIELDS</span>
                        </IconButton></>}
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    {activeUpdate ?
                        <UpdateField attributeArray={attributeArray && attributeArray.filter(item => item.field_id != 'contract_number')} onChange={handleUpdateField} />
                        : ''}
                </form>
            </Card>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<>
                            <IconButton
                                aria-label="settings"
                                onClick={handleActiveEligibility}
                                disabled={calculationMethod && incentiveRateType ? false : true}
                                size="large">
                                <AddIcon color={calculationMethod && incentiveRateType ? "primary" : "disabled"} />
                                <span style={{ color: calculationMethod && incentiveRateType ? "#445AC7" : 'grey', fontSize: 18 }}>ADD ELIGIBILITY RULES</span>

                            </IconButton>
                        </>}
                        titleTypographyProps={{ variant: 'caption' }}
                    />
                    {activeEligibility ?
                        <EligibilityRules onChange={handleEligibilityRules} calculationMethod={calculationMethod} sourceDataType={sourceDataType} />
                        : ''}
                </form>
            </Card>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<>
                            <IconButton
                                aria-label="settings"
                                onClick={handleActiveCalc}
                                disabled={calculationMethod && incentiveRateType ? false : true}
                                size="large">
                                <AddIcon color={calculationMethod && incentiveRateType ? "primary" : "disabled"} />
                                <span style={{ color: calculationMethod && incentiveRateType ? "#445AC7" : 'grey', fontSize: 18 }}>ADD CALCULATION RULES</span>
                            </IconButton>
                        </>}
                        titleTypographyProps={{ variant: 'h3' }}

                    />

                    {activeCalc == true ?
                        <CalculationRules onChange={handleCalculationRules} calculationMethod={calculationMethod} incentiveRateType={incentiveRateType} sourceDataType={sourceDataType} />
                        : ''}
                </form>
            </Card>
            <div className={classes.container}>
                <div className={classes.buttonContainer}>

                    <Button
                        variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                    >
                        {props.loading ?
                            <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                            :
                            'Update Contracts'
                        }
                    </Button>
                </div>
            </div>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (appType, formData) => dispatch(massUpdateContract(appType, formData)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'tier_evaluation_level')),
    }
}
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        dropdownAttributeData: state.addMultipleConfigurationData.tierEvaluationData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MassContractUpdateForm);