import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getAttributeMappingData, deleteAttributeMapping } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MaterialTable from "material-table";
import { tableIcons } from '../../components/Icons/TableIcons';
import secureLocalStorage from 'react-secure-storage';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const AttributeMapping = props => {
    useEffect(() => {
        props.getAttributeMapData(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const columns = [
        {
            title: 'Source Data Type',
            field: 'source_data_type',
            render: rowData => (
                <div style={{ cursor: 'pointer', textTransform: 'capitalize' }} className={classes.hover}>
                    {rowData.source_data_type}
                </div>
            )
        },
        {
            title: 'Source Attribute',
            field: 'attribute_name',
            render: rowData => (
                <div style={{ cursor: 'pointer', textTransform: 'capitalize' }} className={classes.hover}>
                    {rowData.multiple_attribute_filters?.[0]?.attribute_name.replace('_', ' ') || ''}
                </div>
            )
        },
        {
            title: 'Option',
            field: 'options',
            render: rowData => (
                <div style={{ cursor: 'pointer', textTransform: 'capitalize' }} className={classes.hover}>
                    {rowData.multiple_attribute_filters?.[0]?.options?.toString() || ''}
                </div>
            )
        },
        {
            title: 'Source Attribute Values',
            field: 'attribute_values',
            render: rowData => (
                <div style={{ cursor: 'pointer', textTransform: 'capitalize', whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'anywhere', }} className={classes.hover}>
                    {rowData.multiple_attribute_filters?.[0]?.attribute_values?.toString() || ''}
                </div>
            )
        },
        {
            title: 'Update Condition',
            field: 'update_condition',
            render: rowData => (
                <div style={{ cursor: 'pointer', textTransform: 'capitalize' }} className={classes.hover} >
                    {rowData.update_condition}
                </div>
            )
        },
        {
            title: 'Target Attribute',
            field: 'labeled_mapped_attribute',
            render: rowData => (
                <div style={{ cursor: 'pointer', textTransform: 'capitalize' }} className={classes.hover} >
                    {rowData.labeled_mapped_attribute?.toString() || ''}
                </div>
            )
        },
        {
            title: 'Target Attribute Values',
            field: 'target_attribute_values',
            render: rowData => (
                <div style={{ cursor: 'pointer', textTransform: 'capitalize' }} className={classes.hover} >
                    {rowData.target_attribute_values}
                </div>
            )
        },
        {
            title: "Actions",
            field: "actions",
            render: rowData => (
                <>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => viewAttribute(rowData.ID)}
                        size="large">
                        <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_attribute_mapping-' + sessionStorage.getItem('application')
                    ) && (
                            <>
                                <IconButton
                                    classes={{ root: classes.IconButton }}
                                    onClick={() => editAttribute(rowData.ID)}
                                    size="large">
                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                </IconButton>
                                <IconButton
                                    classes={{ root: classes.IconButton }}
                                    // onClick={() => deleteData(rowData.ID)}
                                    onClick={() => handleDeleteClick(rowData.ID)}
                                    size="large">
                                    <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                </IconButton>
                            </>
                        )}
                </>
            )
        }
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAttributeMapData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        console.log(event.target.value)
        setRowsPerPage(event.target.value);
        props.getAttributeMapData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.attributeMapData) {
            setAttributeData(props.attributeMapData.records);
        }
    }, [props.attributeMapData]);

    function editAttribute(id) {
        history.push({
            pathname: '/attribute-mapping/edit-attribute/' + id,
            id: id
        });
    }
    function viewAttribute(id) {
        history.push({
            pathname: '/attribute-mapping/view-attribute/' + id,
            id: id
        });
    }
    // const deleteData = (id) => {
    //     props.deleteAttributeMapping(id);
    // }
    const [salesBasisAll, setSalesBasisAll] = React.useState([]);
    const arrayItem = [];

    const handleDeleteClick = (id) => {
        setSelectedId(id);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        props.deleteAttributeMapping(selectedId);
        setOpenDialog(false);
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
    };


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Attribute Mapping </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_attribute_mapping-' + sessionStorage.getItem('application')) &&
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            onClick={() => history.push('/attribute-mapping/add-attribute')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>}
                </div>
                {attributeData && attributeData.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: props => (
                                <div style={{ height: "0px" }} />
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={attributeData}
                        options={{
                            search: false,
                            filtering: true,
                            paging: false,
                            emptyRowsWhenPaging: true,
                            toolbarButtonAlignment: 'left',
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: {
                                fontSize: '.90rem',
                                textAlign: "center",
                                borderRight: "1px solid #EEEEEE",
                                color: "black !important",
                                padding: '5px 0px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 800,
                                width: 30,
                                minWidth: 5,
                            },
                        }}
                        style={{ marginTop: '-0.5rem' }}
                    /> :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                {/* {attributeData && attributeData.length > 0 &&
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                    </div>
                } */}
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this attribute mapping?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        attributeMapData: state.AttributeMapData.AttributeMapData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAttributeMapData: (pagination, limit) => dispatch(getAttributeMappingData(pagination, limit)),
        deleteAttributeMapping: (id) => dispatch(deleteAttributeMapping(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributeMapping);