import React from 'react';
import { connect } from 'react-redux';
import { MembershipByMemeber } from './components';

const MembershipDisplayByMemeber = (props) => {
    return (
        <div>
            <MembershipByMemeber />
        </div >
    );
};

export default connect(null, null)(MembershipDisplayByMemeber);