import React from 'react';
import { PostingsMaintenanceFormResult } from './containers';

const PostingsMaintenanceResult = props => {
    
    return (
        <PostingsMaintenanceFormResult />
    );

};

export default PostingsMaintenanceResult;