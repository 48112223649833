import React, { useEffect } from "react";
import "../../library/scss/rsuite-default.css";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import {
  Grid,
  Typography,
  OutlinedInput,
  Select,
  Button,
  FormLabel,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell, TextField
} from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  loadRebateTrendsSecondAPI,
  loadSalesVsPaymentGraph,
  loadAccrualVarianceGraph,
  loadValuesBasedOnAppTypeAndField,
  allowedApps,
  getDefaultValuesAllDataNew, getLblDispDesFieldValue,
  dashboardAnalyticsNames, getAllOrganizationUsers, loadAccrualVarianceChart
} from "../../redux/actions";
import { connect } from "react-redux";
import Moment from "moment";
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import { endOfMonth, subMonths, startOfMonth } from "date-fns";
import clsx from "clsx";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { tableIcons } from '../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 0px 15px 0px",
    display: "flex",
    alignItems: "center",
  },
  rootDiv: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    margin: "5px 0px 16px 0px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    color: "#1675e0",
  },

  selectedItem: {
    borderRadius: 5,
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 34,
    paddingBottom: 5,
    marginLeft: "-10px"
  },
  button: {
    marginRight: 10,
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize,
  },
  spinner: {
    height: '90vh'
  },
}));
const RebateTrends = (props) => {
  const classes = useStyles();
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.table.row,
        height: 46,
      },
      "&:nth-of-type(even)": {
        height: 40,
      },
    },
  }))(TableRow);
  // const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
  //   defaultMatches: true
  // });
  const theme = useTheme();
  const [startDate, setStartDate] = React.useState(null);
  const [startDatee, setStartDatee] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [postingType, setPostingType] = React.useState("accrual");
  const [accrualFilter, setAccrualFilter] = React.useState();
  const [accrualFilterEnd, setAccrualFilterEnd] = React.useState();
  const options = ["=", '>', '<', '>=', '<='];
  const [varienceType, setVarienceType] = React.useState();
  const [dateRangeValue, setDateRangeValue] = React.useState([
    new Date("2020-06-01T05:30:00"),
    new Date("2020-10-01T05:30:00"),
  ]);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [apiGraphData, setApiGraphData] = React.useState([]);
  const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([]);
  const [allowedApps, setAllowedApps] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState("Direct - Sales Data");
  const [groupBy, setGroupBy] = React.useState('contract_number');
  const [groupByUpdated, setGroupByUpdated] = React.useState('contract_number');
  const [pageShow, setPageShow] = React.useState('');
  const [contractGroup, setContractGroup] = React.useState("");
  const [contractGroupArray, setContractGroupArray] = React.useState([]);
  const [rebateAnalyst, setRebateAnalyst] = React.useState("");
  const [rebateAnalystArray, setRebateAnalystArray] = React.useState([]);
  const [rebateManager, setRebateManager] = React.useState("");
  const columns = [
    {
      field: 'aggregation_field',
      title: 'Contract Number',
      // render: (rowData) => <a className={classes.hover}
      //   style={{
      //     cursor: groupByUpdated === "contract_number" ? "pointer" : "default",
      //     textDecoration: groupByUpdated === "contract_number" ? "underline" : "none",
      //     color: groupByUpdated === "contract_number" ? "blue" : "inherit",
      //   }}
      //   onClick={() => groupByUpdated === "contract_number" && handleTrendsAPI(rowData.aggregation_field)
      //   } > {rowData.aggregation_field}</a>
      render: (rowData) => <a className={classes.hover}>{rowData.aggregation_field}</a>

    },
    {
      field: 'contract_internal_description',
      title: 'Contract Description',
      render: (rowData) => <a className={classes.hover}> {rowData.contract_internal_description}</a>
    },
    {
      field: 'contract_type',
      title: 'Contract Type',
      render: (rowData) => <a className={classes.hover}>{rowData.contract_type}</a>

    },
    {
      field: 'tier_basis_variance',
      title: 'Tier Basis Variance(%)',
      render: (rowData) => <a className={classes.hover} > {rowData.tier_basis_variance}</a>
    },
    {
      field: 'incentive_basis_variance',
      title: 'Incentive Basis Variance(%)',
      render: (rowData) => <a className={classes.hover} > {rowData.incentive_basis_variance}</a>
    },
    {
      field: 'incentive_amount_variance',
      title: 'Incentive Amount Variance(%)',
      render: (rowData) => <a className={classes.hover} > {rowData.incentive_amount_variance}</a>
    },
    {
      field: 'current_period_incentive_amount',
      title: 'Current Incentive Amount',
      render: (rowData) => <a className={classes.hover}> {rowData.current_period_incentive_amount}</a>
    },
    {
      field: 'prior_period_incentive_amount',
      title: 'Prior Incentive Amount',
      render: (rowData) => <a className={classes.hover}> {rowData.prior_period_incentive_amount}</a>
    },
    {
      field: 'Action',
      title: 'Actions',
      render: rowData => <>
        <IconButton
          classes={{ root: classes.IconButton }}
          onClick={() => handleGraphPageAPI(rowData.aggregation_field)}
          size="large">
          <BarChartIcon color="disabled" style={{ fontSize: 20 }} />
        </IconButton>
      </>
    }
  ]


  useEffect(() => {
    props.allowedAnalyticsApps(sessionStorage.getItem("application"));
    props.getUsers();
    var data = {
      "data_source": "contract_header",
      "application_type": sessionStorage.getItem('application')
    }
    props.fieldDesigner(data, 'post');
  }, []);

  useEffect(() => {
    if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
      const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
        item.analytic_name === "Postings Variance by Attribute"
      );
      if (postingsSummaryAnalytic && postingsSummaryAnalytic.filters) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedAnalyticsAppsData])



  const handlePostingType = (e) => {
    setPostingType(e.target.value);
  };
  const handleContractGroup = (e) => {
    setContractGroup(e.target.value);
  };
  const handleRebateAnalyst = (e) => {
    setRebateAnalyst(e.target.value);
  };
  const handleRebateManager = (e) => {
    setRebateManager(e.target.value);
  };

  // useEffect(() => {
  //   setDateRangeValue([
  //     startOfMonth(subMonths(new Date(), 2)),
  //     endOfMonth(subMonths(new Date(), 1)),
  //   ]);
  //   setStartDate(Moment().subtract(2, "months").startOf("month"));
  //   setEndDate(Moment().subtract(1, "months").endOf("month"));
  // }, []);

  useEffect(() => {
    props.allowedApps();
    props.loadSourceDataType(sessionStorage.getItem("application"), "source_data_type");
    if (sessionStorage.getItem('rebate-trend-second-page') === "true") {
      setPageShow(true);
    }
  }, []);

  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);

  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      if (sessionStorage.getItem('rebate-trend-second-page') === "true") {
        setStartDate(Moment(sessionStorage.getItem('start-date-rebate-trend-second-page')).add(1, 'days').format('MM/DD/YYYY'));
        setEndDate(Moment(sessionStorage.getItem('end-date-rebate-trend-second-page')).add(1, 'days').format('MM/DD/YYYY'));
      }
      else {
        setStartDate(allowedTilesFilter[0].start_date);
        setEndDate(allowedTilesFilter[0].end_date);
      }
      // setGroupBy(convertToSnakeCase(allowedTilesFilter[0].groupBy))
      // setGroupByUpdated(convertToSnakeCase(allowedTilesFilter[0].groupBy))
      setSourceDataType(allowedTilesFilter[0].data_source_type)
      setPostingType(allowedTilesFilter[0].posting_type)
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
      setContractGroupArray(props.dropdownData.field_label_attributes.contract_group);
    }
  }, [props.dropdownData && props.dropdownData.field_label_attributes])

  useEffect(() => {
    if (props.page !== "dashboard" && postingType && sourceDataType && startDate && endDate) {
      // props.loadAccrualVarianceGraph(
      //   Moment(startDate).format("MM/DD/YYYY"),
      //   Moment(endDate).format("MM/DD/YYYY"),
      //   postingType,
      //   sessionStorage.getItem("application"),
      //   sourceDataType,
      //   groupBy
      // );
    }
  }, [allowedTilesFilter]);

  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }



  const handleApplicationtype = (e) => {
    setSourceDataType("");
    setSourceDataTypeDataFromAPI([]);
    props.loadSourceDataType(e.target.value, "source_data_type");
    setApplicationType(e.target.value);
  };

  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
  };

  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);

  useEffect(() => {
    if (props.orgUsers && props.orgUsers.length > 0) {
      var temp = [];
      props.orgUsers.map(item => {
        temp.push(item.username);
      })
      setRebateAnalystArray(temp);
    }
  }, [props.orgUsers]);


  const handleTrendsAPI = (id) => {
    setPageShow(true);
    var data = { "data_source": sourceDataType }
    props.getLblDispDesFieldValue(data, 'post');
    props.loadRebateTrendsAPI(
      Moment(endDate).subtract(5, "months").startOf("month").format("MM/DD/YYYY"),
      Moment(endDate).format("MM/DD/YYYY"),
      postingType,
      applicationType,
      sourceDataType,
      id,
      true
    );
  };
  const handleGraphPageAPI = (id) => {
    setPageShow(true);
    var data = { "data_source": sourceDataType }
    props.getLblDispDesFieldValue(data, 'post');
    props.loadRebateTrendsAPI(
      Moment(endDate).subtract(5, "months").startOf("month").format("MM/DD/YYYY"),
      Moment(endDate).format("MM/DD/YYYY"),
      postingType,
      applicationType,
      sourceDataType,
      id,
      false
    )
    props.loadAccrualVarianceChart(
      Moment(endDate).subtract(5, "months").startOf("month").format("MM/DD/YYYY"),
      Moment(endDate).format("MM/DD/YYYY"),
      postingType,
      applicationType,
      sourceDataType,
      groupBy,
      // contractGroup,
      // rebateAnalyst,
      // rebateManager,
      id
    );

  };

  // const handleGroupBy = (e) => {
  //   setGroupBy(e.target.value);
  // }

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleVarience = (e) => {
    setVarienceType(e.target.value);
  }


  const filterData = (item) => {
    if (varienceType === "=") {
      return (
        item.tier_basis_variance == accrualFilterEnd ||
        item.incentive_basis_variance == accrualFilterEnd ||
        item.incent_amount_variance == accrualFilterEnd
      );
    } else if (varienceType === ">") {
      return (
        item.tier_basis_variance > accrualFilterEnd ||
        item.incentive_basis_variance > accrualFilterEnd ||
        item.incent_amount_variance > accrualFilterEnd
      );
    } else if (varienceType === "<") {
      return (
        item.tier_basis_variance < accrualFilterEnd ||
        item.incentive_basis_variance < accrualFilterEnd ||
        item.incent_amount_variance < accrualFilterEnd
      );
    } else if (varienceType === ">=") {
      return (
        item.tier_basis_variance >= accrualFilterEnd ||
        item.incentive_basis_variance >= accrualFilterEnd ||
        item.incent_amount_variance >= accrualFilterEnd
      );
    } else if (varienceType === "<=") {
      return (
        item.tier_basis_variance <= accrualFilterEnd ||
        item.incentive_basis_variance <= accrualFilterEnd ||
        item.incent_amount_variance <= accrualFilterEnd
      );
    }
    return true;
  };
  // const filteredData = props.accrualVarianceGraphApiData.filter((item) =>
  //   accrualFilterEnd !== undefined ? filterData(item) : true
  // );
  useEffect(() => {
    if (props.accrualVarianceGraphApiData) {
      setApiGraphData(props.accrualVarianceGraphApiData);
    }
    else {
      setApiGraphData([]);
    }
  }, [props.accrualVarianceGraphApiData]);

  const filteredData = apiGraphData && apiGraphData
    ? apiGraphData.filter((item) =>
      accrualFilterEnd !== undefined ? filterData(item) : true
    )
    : [];

  const handleRun = () => {
    setGroupByUpdated(groupBy);
    setPageShow(true);
    sessionStorage.setItem('start-date-rebate-trend-second-page', startDate);
    sessionStorage.setItem('end-date-rebate-trend-second-page', endDate);
    props.loadAccrualVarianceGraph(
      Moment(startDate).format("MM/DD/YYYY"),
      Moment(endDate).format("MM/DD/YYYY"),
      postingType,
      applicationType,
      sourceDataType,
      groupBy,
      contractGroup,
      rebateAnalyst,
      rebateManager
    );
  }

  return (
    <LoadingOverlay
      active={props.loading}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#64C4B4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: '#64C4B4'
        })
      }}
      spinner={<HashLoader />}
      className={classes.spinner}
    >
      <div className={classes.rootDiv}>
        <Grid container className={classes.root}>
          {props.page !== "dashboard" && (
            <Grid
              container
              style={{
                margin: 10,
                padding: 5,
                border: "1px solid #E6E6E6",
                borderRadius: 5,
              }}
            >
              {/* <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography classes={{ root: classes.fontSetting }}>
                  Application Type
                </Typography>
                <Select
                  value={applicationType}
                  onChange={handleApplicationtype}
                  displayEmpty
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                >
                  {allowedApps
                    .filter(
                      (item) =>
                        item !== "Customer Chargeback" &&
                        item !== "Promotions" &&
                        item !== "Supplier Chargeback" &&
                        item !== "Pricing" &&
                        item !== "Profit Optimization"
                    )
                    .map((item, index) => {
                      return (
                        <MenuItem
                          value={item}
                          key={index}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </Grid> */}

              <Grid container md={12} xs={12} style={{ paddingRight: 15 }}>
                <Grid item md={1.5} xs={12} style={{ paddingRight: 15 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel required classes={{ root: classes.fontSetting }}>
                      Source Data Type
                    </FormLabel>
                    <Select
                      value={sourceDataType}
                      onChange={handleSourceDataType}
                      displayEmpty
                      className={clsx({
                        [classes.select]: true,
                      })}
                      classes={{
                        selectMenu: classes.selectedItem,
                      }}
                    >
                      {sourceDataTypeDataFromAPI.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </Grid>
                <Grid item md={1.2} xs={12} style={{ paddingRight: 15 }}>
                  <FormLabel required classes={{ root: classes.fontSetting }}>
                    Posting Type
                  </FormLabel>
                  <Select
                    value={postingType}
                    onChange={handlePostingType}
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    <MenuItem value={"accrual"}>Accrual</MenuItem>
                    <MenuItem value={"payment"}>Payment</MenuItem>
                  </Select>
                </Grid>

                <Grid item md={1.2} xs={12} style={{ paddingRight: 15 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel required classes={{ root: classes.fontSetting }}>
                      Start Date
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Grid container justifyContent="space-around">
                        <DatePicker
                          disableToolbar
                          clearable
                          InputProps={{
                            padding: 0,
                            disableUnderline: true,
                            style: {
                              // padding: "1px 0 1px 11px",
                              alignSelf: "center",
                              alignItems: "center",
                              border: "1px solid #E0E0E0",
                              width: "100%",
                              borderRadius: 5,
                              height: 37,
                              color: "#1675e0",
                            },
                          }}
                          value={startDate}
                          onChange={handleStartDate}
                          renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                          format="MM/DD/YYYY"
                        />
                      </Grid>
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item md={1.2} xs={12} style={{ paddingRight: 15 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel required classes={{ root: classes.fontSetting }}>
                      End date
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Grid container justifyContent="space-around">
                        <DatePicker
                          disableToolbar
                          clearable
                          InputProps={{
                            padding: 0,
                            disableUnderline: true,
                            style: {
                              // padding: "1px 0 1px 11px",
                              alignSelf: "center",
                              alignItems: "center",
                              border: "1px solid #E0E0E0",
                              width: "100%",
                              borderRadius: 5,
                              height: 37,
                              color: "#1675e0",
                            },
                          }}
                          value={endDate}
                          onChange={handleEndDate}
                          renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                          format="MM/DD/YYYY"
                          minDate={startDate ? startDate : false}
                        />
                      </Grid>
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item md={1.2} xs={12} style={{ paddingRight: 15 }}>
                  <FormLabel classes={{ root: classes.fontSetting }}>
                    {
                      contractGroupArray && contractGroupArray.current
                        ? contractGroupArray.current
                        : "Contract Group"
                    }
                  </FormLabel>
                  <Select
                    value={contractGroup}
                    onChange={handleContractGroup}
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    <MenuItem value=""></MenuItem>
                    {contractGroupArray && contractGroupArray.drop_down_values && contractGroupArray.drop_down_values.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
                  <FormLabel classes={{ root: classes.fontSetting }}>
                    {
                      props.dropdownData && props.dropdownData.field_label_attributes && props.dropdownData.field_label_attributes.contract_analyst && props.dropdownData.field_label_attributes.contract_analyst.current
                        ? props.dropdownData.field_label_attributes.contract_analyst.current
                        : "Rebate Analyst"
                    }
                  </FormLabel>
                  <Select
                    value={rebateAnalyst}
                    onChange={handleRebateAnalyst}
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    <MenuItem value=""></MenuItem>
                    {rebateAnalystArray &&
                      rebateAnalystArray
                        .map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </Grid>
                <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
                  <FormLabel classes={{ root: classes.fontSetting }}>
                    {
                      props.dropdownData && props.dropdownData.field_label_attributes && props.dropdownData.field_label_attributes.contract_manager && props.dropdownData.field_label_attributes.contract_manager.current
                        ? props.dropdownData.field_label_attributes.contract_manager.current
                        : "Rebate Manager"
                    }
                  </FormLabel>
                  <Select
                    value={rebateManager}
                    onChange={handleRebateManager}
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    <MenuItem value=""></MenuItem>
                    {rebateAnalystArray &&
                      rebateAnalystArray
                        .map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </Grid>
                <Grid item md={1.2} xs={12}  >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel style={{ marginLeft: 20 }} classes={{ root: classes.fontSetting }}>
                      Variance
                    </FormLabel>

                    <div
                      style={{
                        width: "86%",
                        display: "flex",
                        flexDirection: "row",
                        height: 37,
                        // marginLeft: 23,
                      }}
                    >
                      <Select
                        value={varienceType}
                        onChange={handleVarience}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                      >
                        <MenuItem value=""></MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <OutlinedInput
                        value={accrualFilterEnd}
                        type={"number"}
                        inputProps={{ min: "0", step: "1" }}
                        onChange={(e) => setAccrualFilterEnd(e.target.value)}
                        style={{ borderRadius: 5, height: 36, marginLeft: 10 }}
                      />
                    </div>
                  </div>
                </Grid>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleRun}
                    twoline="true"
                    disabled={(!startDate || !endDate || !postingType || !sourceDataType) ? true : false}
                  >
                    <DirectionsRunIcon />
                  </Button>
                </div>
              </Grid>

            </Grid>
          )}
          <Grid item md={12} xs={6} className={classes.container}>
            {!props.loading && props.accrualVarianceGraphApiData && (pageShow === true) &&
              props.accrualVarianceGraphApiData.length > 0 ? (
              <div style={{ margin: 10 }}>
                <MaterialTable
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          height: "0px",
                        }}
                      >
                      </div>
                    ),
                  }}
                  title={' '}
                  editable={true}
                  icons={tableIcons}
                  columns={columns}
                  data={filteredData}
                  options={{
                    search: false,
                    filtering: true,
                    paging: false,
                    //  pageSize: rowsPerPage,
                    //  count:count,
                    //  page:page,
                    headerStyle: theme.mixins.MaterialHeader,
                    // cellStyle: theme.mixins.MaterialCell,
                    cellStyle: {
                      ...theme.mixins.MaterialCell,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    },
                    //  pageSize: 15,
                    //  pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                  }}
                // style={{ marginTop: -15 }}
                />
              </div>
            ) :
              <Typography variant='h4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                There is no data to show now.
              </Typography>
            }
          </Grid>
        </Grid>


      </div >
    </LoadingOverlay >
  );
};

const mapStateToProps = (state) => {
  return {
    // accrualVarianceGraphApiData: state.initialData.loadAccrualVarianceGraph,
    accrualVarianceGraphApiData: state.initialData.accrualVarianceGraphData,
    loading: state.initialData.loading,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    allowedAppsData: state.initialData.allowedApps,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname,
    dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    orgUsers: state.operationalReportsData.orgUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSalesVsPaymentGraph: () => dispatch(loadSalesVsPaymentGraph()),
    loadAccrualVarianceGraph: (s, e, type, applicationType, sourceDataType, groupBy, contractGroup, rebateAnalyst, rebateManager) => dispatch(loadAccrualVarianceGraph(s, e, type,
      applicationType, sourceDataType, "postingType", groupBy, contractGroup, rebateAnalyst, rebateManager)),

    loadAccrualVarianceChart: (s, e, type, applicationType, sourceDataType, groupBy, id) => dispatch(loadAccrualVarianceChart(s, e, type,
      applicationType, sourceDataType, groupBy, id)),

    loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    loadRebateTrendsAPI: (s, e, type, applicationType, sourceDataType, id) => dispatch(loadRebateTrendsSecondAPI(s, e, type, applicationType, sourceDataType, id)),
    allowedApps: () => dispatch(allowedApps()),
    allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),
    fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    getUsers: () => dispatch(getAllOrganizationUsers()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateTrends);
