import React, { useRef, useState, useEffect } from "react";
import {
    Typography, Dialog, DialogContent, DialogContentText, DialogActions,
    Radio, RadioGroup, FormControlLabel, Button, FormLabel, OutlinedInput
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { getQueryRefData } from "../../redux/actions";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16,
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        color: 'grey',
        paddingRight: 10

    },
    inputTwoLine: {
        paddingLeft: 10
    }
}))
const FilterDialoge = (props) => {
    const classes = useStyles();
    const { className } = props;
    const [filterName, setFilterName] = useState([]);
    const [actionType, setActionType] = useState('');
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (props.filterName)
            setFilterName(props.filterName)
        if (props.action)
            setActionType(props.action)
        if (props.open)
            setOpen(props.open)
    }, [props.filterName, props.action, props.open])

    const handleConfirm = (event, index) => {
        props.onChange(filterName, actionType)
    };
    const handleRadioGroup = (event) => {
        setActionType(event.target.value)
        if (event.target.value == 'create')
            setFilterName('')
        else
            setFilterName(props.filterName)
    }
    return (
        <div>
            <Dialog open={open}>
                <DialogContent
                    classes={{
                        root: classes.dialogContent,
                    }}
                >
                    <Typography variant="h4">
                        <DialogContentText>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                className={classes.radioContainer}
                                onChange={handleRadioGroup}
                                value={actionType}
                            >
                                <FormControlLabel
                                    value="create"
                                    control={<Radio color="primary" />}
                                    label="New"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                                <FormControlLabel
                                    value="edit"
                                    control={<Radio color="primary" />}
                                    label="Update"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                            </RadioGroup>
                            <FormLabel classes={{ root: classes.fontSetting }}>Filter Name</FormLabel>
                            <OutlinedInput
                                classes={{ root: classes.inputTwoLine }}
                                type={'string'}
                                value={filterName}
                                onChange={(e) => setFilterName(e.target.value)}
                            />
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialog,
                    }}
                >
                    <Button
                        onClick={props.onCancel}
                        autoFocus
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        color="primary"
                        autoFocus
                        variant="contained"
                        disabled={!filterName ? true : false}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};


export default FilterDialoge;
