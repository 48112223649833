import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, FormLabel, MenuItem, Input,
    Select, Dialog, DialogActions, Fab, DialogContent, DialogContentText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { getPriceFieldValues, createPriceListData, getPriceMaintenanceFields, getPriceListMaster, getPriceTypeConfig, createPriceListDataAll } from '../../../redux/actions/Pricing/AuthAction';
import '../../../../node_modules/jsuites/dist/jsuites.css';
import '../../../../node_modules/jsuites/dist/jsuites.js';
import { useHistory } from "react-router-dom";
import {
    getRangeAddress, getRangeIndexes,
    SpreadsheetComponent, getCell, SheetsDirective, SheetDirective, ColumnsDirective,
    RangesDirective, ColumnDirective, RowsDirective, RowDirective, CellsDirective, RangeDirective,
    CellDirective,
    setColumn
} from '@syncfusion/ej2-react-spreadsheet';
import Moment from 'moment';
import { Link } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bg: {
        backgroundColor: theme.palette.primary.main,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: theme.typography.h6.fontSize,
        paddingRight: 10
    },
    container: {
        padding: 15,
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"
    },
    fabStyle: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    input: {
        width: 42,
    },
    addRow: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '30px'
    }
}));

const AddPriceListData = props => {
    const classes = useStyles();
    let spreadsheet;
    const jRef = useRef(null);
    const history = useHistory();
    var mode = history.location.state?.mode;
    var type = history.location.state?.type;
    const [priceFields, setPriceFields] = React.useState([]);
    const [priceTypeFieldsArray, setPriceTypeFieldsArray] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [listData, setListData] = React.useState([]);
    const [priceTypeCategory, setPriceTypeCategory] = React.useState([]);
    const [calculationType, setCalculationType] = React.useState('');
    const [optionsSF, setOptionsSF] = React.useState({})
    const [optionsSFPredefined, setOptionsSFPredefined] = React.useState([])
    const [apiFields, setApiFields] = React.useState([])
    const [addNRows, setAddNRows] = React.useState(5);
    const [dataRows, setDataRows] = React.useState([]);
    const [submitClicked, setSubmitClicked] = React.useState(mode == 'bulk-add' ? true : false);
    const [colCount, setColCount] = React.useState(0);
    useEffect(() => {
        setDialogue(true)
        if (mode != 'bulk-add')
            props.getFieldValues('price_list_id')
    }, [])
    useEffect(() => {
        if (mode != 'bulk-add' && props.maintenanceFields && props.maintenanceFields.qualifier_labels && props.maintenanceFields.qualifier_labels.length > 0 && priceListID)
            setPriceFields(props.maintenanceFields.qualifier_labels)
        else {
            if (props.bulkMaintenanceFields && props.bulkMaintenanceFields.qualifier_labels && props.bulkMaintenanceFields.qualifier_labels.length > 0 && props.bulkMaintenanceFields.price_type_fields) {
                setPriceTypeFieldsArray(props.bulkMaintenanceFields.price_type_fields)
                setPriceFields(props.bulkMaintenanceFields.qualifier_labels)
            }
        }
    }, [props.maintenanceFields, props.bulkMaintenanceFields])
    function titleCase(str) {
        if (str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }
    useEffect(() => {
        var tempRows = [];
        if (props.listData && props.listData.records) {
            props.listData.records.map(e => {
                tempRows.push(...e.price_type_name)
            })
            setListData([...tempRows]);
        }
    }, [props.listData]);
    function handlePriceList(value) {
        var temp = []
        setPriceListID(value)
        props.getPriceMaintenanceFields(value)
        props.getPriceListData(1, 0, value)
        listData
            .filter(item => item.price_list_id === value)
            .map((item, i) => {
                temp.push([...item.price_type_name])
            })
        setPriceTypeNameArray(temp)
    }

    function handleContinue() {
        setSubmitClicked(true);
        setDialogue(false);
    }
    useEffect(() => {
        if (props.configData && props.configData.records && props.configData.records[0]) {
            if (props.configData.records[0]['price_type_category'])
                setPriceTypeCategory(props.configData.records[0]['price_type_category'])
            if (props.configData.records[0]['calculation_type'])
                setCalculationType(props.configData.records[0]['calculation_type'])
        }
    }, [props.configData])
    useEffect(() => {
        if (submitClicked && priceFields && priceFields.length > 0) {

            var columnArray = [];
            var dataArray = []
            var optionsTemp = {
                data: [dataArray],
                columns: columnArray,
                rowResize: true,
                search: true,
                columnSorting: true,
                allowComments: true,
                columnDrag: true,
                tableOverflow: true,
                csvHeaders: true,
                tableWidth: screenSize.dynamicWidth,
                tableHeight: window.innerHeight * .80 + 'px',
            }
            var optionsTempPre = [];
            var tempAPIFields = [];
            var preData = {}
            priceFields
                //.filter(h =>((type =='priceType_based'|| priceTypeName))? h !== 'price_type_name' : h)
                .filter(h => (type != 'priceType_based' && priceTypeCategory != 'Analytical') ? h.key != 'max_value' && h.key != 'min_value' : h)
                .filter(h => (type != 'priceType_based' && (calculationType == '%' || calculationType == 'Fixed')) ? h.key != 'per_unit' /*&& h.key != 'scale_from'*/ && h.key != 'pricing_uom' && (calculationType == '%' ? h.key != 'currency' : h) && (calculationType == 'Fixed' && h.key != 'scale_from') : h)
                .map(h => {
                    if (h.key === 'start_date' || h.key === 'end_date') {
                        tempAPIFields.push(h.key);
                        columnArray.push({
                            title: h.value,//titleCase(h.replace(/_/g, ' ')),
                            field: h.key,
                            //  width: 300,
                            type: 'date',
                            render: (rowData) => <a className={classes.hover} > {Moment(rowData.h.key).format('YYYY-DD-MM')}</a>

                        });
                    }
                    else if (h.key == 'currency' || h.key == 'scale_from' || h.key == 'min_value' || h.key == 'max_value') {
                        tempAPIFields.push(h.key);
                        columnArray.push({
                            title: h.value,//titleCase(h.replace(/_/g, ' ')),
                            field: h.key,
                            // width: 300,

                        });
                    }
                    else if (h.key === 'per_unit' || h.key === 'uom') {
                        tempAPIFields.push(h.key);
                        columnArray.push({
                            title: h.value,//titleCase(h.replace(/_/g, ' ')),
                            field: h.key,
                            //width: 300,
                        });
                    }
                    else {
                        tempAPIFields.push(h.key);
                        columnArray.push({ title: h.value /*titleCase(h.replace(/_/g, ' '))*/, field: h.key, });
                    }
                    if (h.key === 'per_unit' || h.key === 'scale_from') {
                        optionsTempPre.push({ title: h.value, type: 'string', head: h.key })
                        preData[h.value] = 1
                    }
                    else if (h.key === 'currency') {
                        optionsTempPre.push({ title: h.value, type: 'string', head: h.key })
                        preData[h.value] = 'USD'
                    }
                    else if (h === 'uom') {
                        optionsTempPre.push({ title: h.value, type: 'string', head: h.key })
                        preData[h.value] = 'Each'
                    }
                    else if (h === 'start_date' || h === 'end_date') {
                        optionsTempPre.push({ title: h.value, type: 'date', head: h.key })
                        preData[h.value] = ''
                    }
                    else {
                        optionsTempPre.push({ title: h.value, type: 'string', head: h.key })
                        preData[h.value] = ''
                    }
                })
            setOptionsSF(optionsTemp)
            setOptionsSFPredefined(optionsTempPre)
            setColCount(optionsTempPre.length)
            setApiFields(tempAPIFields);
            setDataRows([preData])
        }
    }, [priceFields, submitClicked]);
    const [dialogue, setDialogue] = React.useState(false);
    const [priceListID, setPriceListID] = React.useState('');
    const [priceTypeName, setPriceTypeName] = React.useState('');
    const [priceListArray, setPriceListArray] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(1000);
    const [screenSize, getDimension] = React.useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    useEffect(() => {
        if (props.priceListId)
            setPriceListArray(props.priceListId)
    }, [props.priceListId])

    function handleCancelDialog() {
        history.push('/pricing-maintenance')
    }
    function handlePriceTypeName(value) {
        setPriceTypeName(value)
        props.getPriceTypeData(1, 0, value)
    }
    const onCellEdit = (args) => {
        if (args.address.includes('1')) {
            args.cancel = true;
        }
    }
    const scrollSettings = { isFinite: true };
    const rowStyle =
    {
        verticalAlign: 'middle', textAlign: 'center', fontSize: '16pt', fontWeight: 'bold',
        border: '1px solid #e0e0e0', backgroundColor: '#EEEEEE', color: '#279377'
    }
    let grid;
    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns();
        }
    };

    const handleSubmitExcel = () => {
        var json = [];
        var obj = {};
        var ssObj = spreadsheet;
        var usedRange = ssObj.getActiveSheet().usedRange;
        var selIndex = [1, 0, usedRange.rowIndex, usedRange.colIndex];
        var range =
            ssObj.getActiveSheet().name + '!' + getRangeAddress([1, 0, selIndex[2], selIndex[3]]);
        ssObj.getData(range).then((value) => {
            value.forEach((cell, key) => {
                var indexes = getRangeIndexes(key);
                if (cell && selIndex[2] >= indexes[0]) {
                    var charCurrent = 'A';
                    apiFields.map(item => {
                        if (key.indexOf(charCurrent) > -1) {
                            if (item == 'start_date' || item == 'end_date')
                                obj[item] = cell.value ? Moment(ExcelDateToJSDate(cell.value)).format('MM/DD/YYYY') : '';
                            else if (item == 'price_type_value')
                                obj[item] = cell.value ? parseFloat(cell.value) : 0;
                            else
                                obj[item] = cell.value ? cell.value.toString() : '';
                        }
                        if (mode != 'bulk-add') {
                            obj['price_list_id'] = priceListID
                            obj['price_type_name'] = priceTypeName
                        }
                        charCurrent = String.fromCharCode(charCurrent.charCodeAt() + 1)
                    });
                    if (indexes[1] === selIndex[3]) {
                        json.push(obj);
                        obj = {};
                    }
                }
            });
            if (mode != 'bulk-add') {
                props.onSubmit(json);
            }
            else {
                var formData = {
                    "price_list_data_request": json,
                    "price_fields": priceTypeFieldsArray,
                }

                props.onBulkAdd(formData);
            }
        });
    }
    function ExcelDateToJSDate(serial) {
        var hours = Math.floor((serial % 1) * 24);
        var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
        return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    }
    function addRow() {
        setRowCount(rowCount + addNRows)
    }
    function contextMenuBeforeOpen() {
        spreadsheet.removeContextMenuItems(["Insert Column", 'Delete Column', 'Hide Column']); //Items that needs to be removed, Set `true` if the given `text` is a unique id.
    };
    const cancel = () => {
        history.push('/pricing-maintenance')
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/pricing-maintenance'
                >
                    Price Maintenance
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Price Maintenance Data</Typography>
            </Breadcrumbs>
            <Dialog
                classes={{ paper: classes.paperContainer }}
                open={true}
                aria-labelledby="responsive-dialog-title"
                fullScreen
            >
                <DialogContent style={{ justifyContent: 'center', display: 'flex' }}>
                    {//optionsSF && Object.keys(optionsSF).length > 0 &&
                        optionsSFPredefined && optionsSFPredefined.length > 0 && colCount != 0 &&
                        <>
                            <SpreadsheetComponent
                                id="add-list"
                                scrollSettings={scrollSettings}
                                ref={(ssObj) => { spreadsheet = ssObj }}
                                enableVirtualization={true}
                                allowNumberFormatting={true}
                                allowDataValidation={true}
                                showPager={false}
                                isFinite={true}
                                //cellEdit={onCellEdit}
                                width={window.screen.width + 30}
                                contextMenuBeforeOpen={contextMenuBeforeOpen}
                            >
                                <SheetsDirective>
                                    <SheetDirective
                                        name="Price List"
                                        colCount={colCount}
                                        rowCount={rowCount}
                                    >
                                        <RowsDirective>
                                            <RowDirective  >
                                                <CellsDirective allowEditing={false}>
                                                    {//optionsSF && optionsSF.columns && optionsSF.columns.map((item, index) => {
                                                        optionsSFPredefined.map((item, i) => {
                                                            return (

                                                                <CellDirective
                                                                    headerText={item.title}
                                                                    value={item.title}
                                                                    field={item.head}
                                                                    width={window.screen.width / (optionsSFPredefined.length)}
                                                                    style={{
                                                                        color: '#F2F2F2',
                                                                        backgroundColor: '#095eb5',//localStorage.getItem('appTheme')!='Light'&&localStorage.getItem('appTheme'), 
                                                                        textAlign: 'center'
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                </CellsDirective>
                                            </RowDirective>
                                        </RowsDirective>

                                        <ColumnsDirective>
                                            {optionsSFPredefined.map((item, i) => {
                                                return (<ColumnDirective
                                                    field={item.head}
                                                    headerText={item.title}
                                                    width={window.screen.width / (optionsSFPredefined.length)}
                                                    format={item.head == 'start_date' ? 'mm-dd-yyyy' : (item.head == 'end_date' ? 'mm-dd-yyyy' : null)}
                                                    type={item.head == 'start_date' ? 'date' : (item.head == 'end_date' ? 'date' : null)}
                                                    editType={(item.head == 'start_date' || item.head == 'end_date') ? 'datepickeredit' : ''}
                                                ></ColumnDirective>)
                                            })}
                                        </ColumnsDirective>
                                        <RangesDirective>
                                            <RangeDirective dataSource={dataRows}></RangeDirective>
                                        </RangesDirective>
                                    </SheetDirective>
                                </SheetsDirective>
                            </SpreadsheetComponent>

                        </>
                    }

                </DialogContent>
                <DialogActions>
                    <div className={classes.addRow} style={{ width: '100%' }}>
                        <div style={{ display: 'flex' }}>

                            <Input
                                className={classes.input}
                                value={addNRows}
                                margin="dense"
                                onChange={(e) => setAddNRows(e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 0,
                                    max: 100,
                                    type: 'number',
                                    //'aria-labelledby': 'input-slider',
                                }}
                            />
                            <Button variant="string" onClick={addRow}> Add More Rows </Button>
                        </div>
                    </div>
                    <Button onClick={handleSubmitExcel} color="primary" variant="contained" >
                        Create
                    </Button>
                    <Button onClick={cancel} color="primary" variant="outlined">
                        Back
                    </Button>
                </DialogActions>
            </Dialog>
            {mode != 'bulk-add' &&
                <div className={classes.bodyContainer}>
                    <Dialog
                        onClose={() => setDialogue(false)}
                        aria-labelledby="simple-dialog-title"
                        open={dialogue}
                        disableEscapeKeyDown
                        classes={{ paper: classes.paperContainer }}>
                        <div className={classes.container}>
                            <Grid container spacing={2} style={{ padding: '16px 25px 16px 25px' }}>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Price List ID
                                    </FormLabel>
                                    <Select
                                        onChange={(e) => handlePriceList(e.target.value)}
                                        value={priceListID}

                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        {priceListArray && priceListArray.value && priceListArray.value
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Price Type Name
                                    </FormLabel>
                                    <Select
                                        onChange={(e) => handlePriceTypeName(e.target.value)}
                                        value={priceTypeName}

                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        {priceListID && listData && listData
                                            .map(item => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>

                                </Grid>
                            </Grid>
                        </div>
                        <DialogActions>
                            <Button color="primary" variant="outlined" onClick={handleCancelDialog}>
                                Cancel
                            </Button>
                            <Button color="primary" variant="contained"
                                onClick={handleContinue} disabled={priceListID && priceTypeName ? false : true}>
                                Continue
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            }
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createPriceListData(data)),
        onBulkAdd: (data) => dispatch(createPriceListDataAll(data)),
        getFieldValues: (key) => dispatch(getPriceFieldValues(key)),
        getPriceMaintenanceFields: (priceListId) => dispatch(getPriceMaintenanceFields(priceListId)),
        getPriceListData: (pagination, limit, id) => dispatch(getPriceListMaster(pagination, limit, id)),
        getPriceTypeData: (pagination, limit, name) => dispatch(getPriceTypeConfig(pagination, limit, name)),
    }
}

const mapStateToProps = state => {
    return {
        priceListId: state.pricingData.priceTypeNameList,
        maintenanceFields: state.pricingData.priceMaintenanceFields,
        listData: state.pricingData.priceListMasterData,
        configData: state.pricingData.priceTypeData,
        bulkMaintenanceFields: state.pricingData.pricingFields,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPriceListData);