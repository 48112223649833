import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    TableRow,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions,
    Checkbox,
    Chip,
    Input,
    MenuItem,
    Select,
    ListItemText,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import ChipInput from 'material-ui-chip-input';
import { makeStyles, withStyles } from '@mui/styles';
import { getAllForecastLevelDropDwon, displayForecast, getAllForecastLevelData } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader'
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',

    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,

    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,

    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,

    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {

        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    paper: { minWidth: window.screen.width * .50 },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 34,
        padding: 0,
        marginTop: 10
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginRight: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black,

        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    select: {
        width: '100%',
        marginTop: 10,

    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset',
        borderRadius: 5,


    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'

    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const ForecastingSales = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [forecasteLevel, setForecastLevel] = React.useState('');
    const [forecastingAttributesArr, setForecastingAttributesArr] = React.useState([]);
    const [forecastAttributesArray, setForecastAttributesArray] = React.useState([]);
    const [forecastAttrTemp, setForecastAttrTemp] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const handleForecastLevel = (e) => {
        setForecastAttrTemp([]);
        setForecastingAttributesArr([]);
        //  setForecastingPeriod('');
        props.forecastLevelData.map(item => {
            if (item.forecasting_level === e) {
                setForecastLevel(item.forecasting_level);
                setForecastAttributesArray(item.forecasting_attributes);
                //      setForecastTargetArray([item.forecasting_target]);
                setForecastAttributesArray(item.forecasting_attributes);
                //   setForecastPeriodArray([item.forecasting_period])
            }
        })
    }
    useEffect(() => {
        setOpen(true);
        props.getAllForecastLevelDropDwon();
        // setForecastingPeriod('')
    }, []);

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    function handleOnSubmit() {
        var finalObj = attributeObjectAPI;
        var objectKeys = [];
        objectKeys = Object.keys(finalObj);
        forecastingAttributesArr.map(item => {
            if (objectKeys.includes(item)) {
                if (finalObj[item] = []) {
                    finalObj[item] = ['*'];
                }
            } else {
                finalObj[item] = ['*'];
                setAttributeObjectAPI(finalObj);
            }
        })
        var data = {
            "forecast_level": forecasteLevel,
            //"forecasting_attributes": finalObj,
            "start_date": Moment(startDate).format('MM-DD-YYYY'),
            "end_date": Moment(endDate).format('MM-DD-YYYY'),
        }
        Object.assign(data, finalObj);
        props.onSubmit(data);
        history.push('/display-forecast-result');
    }

    useEffect(() => {
        props.getAllForecastLevelData();
    }, [])
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,
    }
    function handleOpen() {
        setSelected(forecastAttrTemp)
        setListOpen(true)
    }
    const [listOpen, setListOpen] = React.useState(false);

    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    const handleAttributeValues = (value, item) => {
        var attributeObject = [];
        attributeObject.push({
            [item]: value
        });
        if (item === 'customer_number') {
            value = value
        }
        setAttributeObjectAPI(prevState => ({
            ...prevState,
            [item]: value
        }));

    }
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setForecastAttrTemp(newSelected)
        setSelected(newSelected)
        var keys = Object.keys(attributeObjectAPI);
        var object = attributeObjectAPI
        keys.map(item => {
            if (!newSelected.includes(item)) {
                delete attributeObjectAPI[item];
            }
        })

    }
    const handleForecastingAttributes = (e) => {
        setForecastingAttributesArr(e.target.value)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h1' color='primary' classes={{
                        root: classes.link
                    }}
                        to='/display-forecast'
                    >
                        <Typography color="Primary" variant='h1'>Display Forecast</Typography>
                    </Link>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}>
                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel}>
                                            Forecast Level
                                        </FormLabel>
                                        <Select
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            value={forecasteLevel}
                                            onChange={(e) => handleForecastLevel(e.target.value)}
                                        >
                                            {props.forecastLevelData && props.forecastLevelData.length > 0
                                                && props.forecastLevelData
                                                    ?.map((item, index) => (
                                                        <MenuItem key={index} value={item.forecasting_level ? item.forecasting_level : null}>
                                                            {item.forecasting_level ? item.forecasting_level : ''}
                                                        </MenuItem>
                                                    ))}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}>
                                        <div className={classes.selectRoot}>
                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel} required >
                                                Start Date
                                            </FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginTop: 2
                                                            }
                                                        }}
                                                        value={startDate}
                                                        onChange={date => setStartDate(date)}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}>
                                        <div className={classes.selectRoot}>
                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel} required >
                                                End Date
                                            </FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 2,
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%'
                                                            }
                                                        }}
                                                        value={endDate}
                                                        onChange={date => setEndDate(date)}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={startDate ? startDate : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>
                                    {/* after selecting forecast level */}
                                    {forecasteLevel &&
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}>
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel}>
                                                    Forecasting Attribute
                                                </FormLabel>
                                                <Select
                                                    value={forecastingAttributesArr}
                                                    displayEmpty
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    onOpen={handleOpen}
                                                    onClose={() => setListOpen(false)}
                                                    open={listOpen}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    renderValue={(selected) => (
                                                        <div className={classes.chips}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value.replace(/_/g, ' ')} className={classes.chip} />
                                                            ))}
                                                        </div>
                                                    )}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    onChange={handleForecastingAttributes}
                                                    multiple
                                                >
                                                    {forecastAttributesArray &&
                                                        forecastAttributesArray.map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize', }} onClick={(event) => handleSelectValues(event, item)}>
                                                                    <Checkbox
                                                                        color='primary'
                                                                        checked={selected.indexOf(item) > -1 ? true : false} />
                                                                    <ListItemText primary={item.replace(/_/g, ' ')} />
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </div>
                                        </Grid>
                                    }
                                    {forecastAttrTemp.map((item) => {
                                        return (
                                            <Grid
                                                item
                                                md={4}
                                                xs={12}
                                                style={{ paddingRight: 15, }}
                                            >
                                                <FormLabel
                                                    className={classes.formLabel}
                                                    style={{ textTransform: 'capitalize', }}>
                                                    {item.replace(/_/g, ' ')}
                                                </FormLabel>
                                                <ChipInput
                                                    classes={{
                                                        root: classes.rootContainer,
                                                        chip: classes.chip,
                                                        input: classes.input,
                                                        inputRoot: classes.inputRoot,
                                                        chipContainer: classes.chipContainer,
                                                        label: classes.chipLabel
                                                    }}
                                                    onChange={(value) => handleAttributeValues(value, item)}
                                                    disableUnderline={true}
                                                    className={clsx({
                                                        [classes.textInput]: true
                                                    })}
                                                    style={{ height: 39 }}  //display: item != 'customer_number' ? 'block' : 'none' 

                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                    <div className={classes.buttonRoot}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                            disabled={startDate && endDate && forecasteLevel
                                ? false : true}
                        >
                            Submit
                        </Button>
                    </div>
                </div >
            </div >
        </LoadingOverlay >
    );
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(displayForecast(data)),
        getAllForecastLevelDropDwon: () => dispatch(getAllForecastLevelDropDwon()),
        getAllForecastLevelData: () => dispatch(getAllForecastLevelData()),
    }
}
const mapStateToProps = state => {
    return {
        dropdwonForeCast: state.forecastData.dropdwonForeCast,
        loading: state.forecastData.loading,
        forecastLevelData: state.forecastData.foreCastLevelData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ForecastingSales);