import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, TextField, OutlinedInput, FormLabel, Switch, Dialog, DialogActions, Select, MenuItem, Paper, Checkbox, FormControlLabel
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import {
    getDefaultValuesAllDataNew,
    getCalculationSimulationDataFields, getDynamicQueryFields,
    postDynamicQuery, getDynamicQuerySalesDataFields, getPurchaseDataFields, getDefaultValuesAllData, getDynamicQuery,
    getDynamicQueryFieldsNew,
    getDefaultValues
} from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { history } from '../../components/Helpers';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        display: 'flex',
        alignItems: 'flex-end'
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 },
    select: {
        width: '100%',
        marginTop: 10
    },
    center: {
        textAlign: 'center'
    },
    tableCellStyle: {
        backgroundColor: theme.palette.primary.main
    }
}));

const AddDynamicQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(true);
    const [queryName, setQueryName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [bomPartName, setBomPartName] = React.useState('');
    const [bomDescription, setBomDescription] = React.useState('');
    const [bomPartNumber, setBomPartNumber] = React.useState('');
    const [quantity, setQuantity] = React.useState('');
    const [uom, setUom] = React.useState('');
    const [incomingRoyalty, setIncomingRoyalty] = React.useState('');
    const [outgoingRoyalty, setOutgoingRoyalty] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
    const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
    const [averageFieldsArray, setAverageFieldsArray] = React.useState([]);
    const [outputFieldsArray, setOutputFieldsArray] = React.useState({});
    const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
    const [sortOrderArray, setSortOrderArray] = React.useState([]);
    const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] = React.useState([]);
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const [currentEditId, setCurrentEditId] = React.useState('');
    const [keyValuePairObjectAll, setKeyValuePairObjectAll] = React.useState([]);
    const [disabledItems, setDisabledItems] = React.useState([]);
    const [maximumSortOrder, setMaximumSortOrder] = React.useState([]);
    const [sortCounterEnabler, setSortCounterEnabler] = React.useState(true);
    const [sortCounter, setSortCounter] = React.useState(1);

    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getDynamicQuery(appId);
        setOpen(true);
        props.getDefaultValuesAllData();
    }, []);
    useEffect(() => {
        var tempObj = {};
        if (apiArrayFieldsConcatinated.length > 0 && props.labelNewAPIData) {
            apiArrayFieldsConcatinated.map((item1) => {
                Object.entries(props.labelNewAPIData).map(([key, value]) => {
                    if (item1 === key) {
                        tempObj[key] = value['current'];
                    }
                })
            })
        }
        setKeyValuePairObjectAll(tempObj);

        var dis = [];
        props.formFieldsAllData && props.formFieldsAllData.records.length > 0 && props.formFieldsAllData.records.map((item) => {
            if (item.contract_display === false) {
                dis.push(item.field_id)
            }
        })
        setDisabledItems(dis);
        if (dis.length == 0) {
            setKeyValuePairObject(tempObj);
        }
    }, [apiArrayFieldsConcatinated, props.formFieldsAllData])
    useEffect(() => {
        if (disabledItems.length > 0) {
            const filtered = Object.keys(keyValuePairObjectAll)
                .filter(key => !disabledItems.includes(key))
                .reduce((obj, key) => {
                    obj[key] = keyValuePairObjectAll[key];
                    return obj;
                }, {});
            setKeyValuePairObject(filtered);
        }
    }, [disabledItems])
    useEffect(() => {
        if (props.dynamicQueryFieldsData && props.dynamicQueryFieldsData.numeric_fields) {
            var concatinated = props.dynamicQueryFieldsData.numeric_fields.concat(props.dynamicQueryFieldsData.string_fields)
                .concat(props.dynamicQueryFieldsData.date_fields)
            setApiArrayFieldsConcatinated(concatinated)
        }
    }, [props.dynamicQueryFieldsData]);

    const [tableName, setTableName] = React.useState('');
    useEffect(() => {
        if (props.dynamicQueryDetailsData && Object.keys(props.dynamicQueryDetailsData).length > 0) {
            props.onLoadingDefault(props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.data_source_type, null, null, 'Display Designer');
            props.onLoadingLabelDesignerNew('field-label-designer', props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.data_source_type)
            props.getDynamicQueryFields(props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.data_source_type, props.dynamicQueryDetailsData.data_source)
            setTableName(props.dynamicQueryDetailsData.table_name);
            setDescription(props.dynamicQueryDetailsData.query_description);
            setQueryName(props.dynamicQueryDetailsData.format_name);
            setFilterFieldsArray(props.dynamicQueryDetailsData.filter_fields);
            setAverageFieldsArray(props.dynamicQueryDetailsData.avg_fields);
            setSumFieldsArray(props.dynamicQueryDetailsData.sum_fields);
            setAggregateFieldsArray(props.dynamicQueryDetailsData.groupby_fields);
            setCurrentEditId(props.dynamicQueryDetailsData.id);
            if (props.dynamicQueryDetailsData && props.dynamicQueryDetailsData.sort_fields) {
                var arrayToObject = Object.assign({}, ...props.dynamicQueryDetailsData.sort_fields.map((x) => ({ [x.sort_field]: x.sort_order })));
                setOutputFieldsArray(arrayToObject);
            }
            const maxValueOfY = Math.max(...props.dynamicQueryDetailsData.sort_fields.map(o => o.sort_order), 0);
            if (maxValueOfY) {
                setMaximumSortOrder(maxValueOfY + 1);
                setSortCounter(maxValueOfY + 1);
            }
        }
    }, [props.dynamicQueryDetailsData]);
    const [editIndex, setEditIndex] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [userStore, setUserStore] = React.useState([]);
    function handleClear() {
        setEditIndex('')
        setEditMode('');
        setBomPartNumber('');
        setBomPartName('');
        setQuantity('');
        setUom('');
        setIncomingRoyalty('');
        setOutgoingRoyalty('');
        setNotes('');
    }
    function handleOnSubmit() {
        var unsortedFields = [];
        if (outputFieldsArray || (sumFieldsArray.length > 0 && filterFieldsArray.length === 0
            && outputFieldsArray.length === 0 && aggregateFieldsArray.length === 0)) {
            for (var propName in outputFieldsArray) {
                unsortedFields.push(propName);
                if (outputFieldsArray[propName] === 0) {
                    delete outputFieldsArray[propName];
                }
            }
            var allFields = {
                "filter_fields": filterFieldsArray,
                "sum_fields": sumFieldsArray,
                "avg_fields": averageFieldsArray,
                "sort_fields": outputFieldsArray,
                "output_fields": unsortedFields,
                "groupby_fields": aggregateFieldsArray,
                "format_name": queryName,
                "table_name": tableName,
                "id": currentEditId,
                "query_description": description
            };
            props.onSubmit(allFields, 'edit');
        } else {
            alert('Please select atleast one output or only sum field')
        }
    }
    function handleClearAll() {
        handleClear();
        setEditMode(false);
        setUserStore([]);
    }
    function handleCancelDialog() {
        history.push('/partner-statement');
    }
    const handleChange = (event, item, type) => {
        if (type === 'filter_fields') {
            if (filterFieldsArray.includes(item)) {
                setFilterFieldsArray(filterFieldsArray.filter(item1 => item1 !== item));
            } else {
                setFilterFieldsArray([...filterFieldsArray, item]);
            }
        }
        if (type === 'aggregate_fields') {
            if (aggregateFieldsArray.includes(item)) {
                if (sumFieldsArray.includes(item)) {
                    if (outputFieldsArray.hasOwnProperty(item)) {
                    }
                } else {
                    setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
                }
            } else {
                setAggregateFieldsArray([...aggregateFieldsArray, item]);
                setOutputFieldsArray({ ...outputFieldsArray, [item]: null });//aggregate and output should be in sync
            }
        }
        if (type === 'sum_fields') {
            if (sumFieldsArray.includes(item)) {
                setSumFieldsArray(sumFieldsArray.filter(item1 => item1 !== item));
            } else {
                setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
                setSumFieldsArray([...sumFieldsArray, item]);
            }
        }
        if (type === 'avg_fields') {
            if (averageFieldsArray.includes(item)) {
                setAverageFieldsArray(averageFieldsArray.filter(item1 => item1 !== item));
            } else {
                setOutputFieldsArray({ ...outputFieldsArray, [item]: 0 });
                setAverageFieldsArray([...averageFieldsArray, item]);
            }
        }
        if (type === 'output_fields') {
            if (outputFieldsArray.hasOwnProperty(item)) {
                var tempArray = outputFieldsArray;
                delete tempArray[item];
                setOutputFieldsArray({ ...tempArray })
            } else {
                if (sortCounterEnabler) {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: sortCounter });
                } else {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
                }
                setSortCounter(prevCount => prevCount + 1);
            }
        }
        if (type === 'sort_order') {
            setSortCounterEnabler(false);
            setSortOrderArray(event.target.value)
            setOutputFieldsArray({ ...outputFieldsArray, [item]: event.target.value ? parseInt(event.target.value) : null });
            event.preventDefault();
        }
    };
    const [functionalityName, setFunctionalityName] = React.useState('');
    const handleFunctionalityName = (e) => {
        setFunctionalityName(e.target.value);
        if (e.target.value === 'Financial Postings Data') {
            props.getDynamicQueryFields();
        } else if (e.target.value === 'Sales Data') {
            props.getDynamicQuerySalesDataFields();
        } else {
            props.getCalculationSimulationDataFields();
        }
    }
    const [allOutput, setAllOutput] = React.useState(false);
    const handleAllOutput = (event) => {
        var tempObj = {};
        var tempArray = [];
        var i = 1;
        setAllOutput(event.target.checked);
        if (event.target.checked === true) {

            if (apiArrayFieldsConcatinated.length > 0 && props.labelNewAPIData) {
                apiArrayFieldsConcatinated.map((item1) => {
                    Object.entries(props.labelNewAPIData)
                        .sort()
                        .map(([key, value]) => {
                            if (item1 === key) {
                                tempObj[key] = 0;
                            }
                            else if (sessionStorage.getItem('dq_table_name') === 'purchase_data') {
                                tempObj[item1] = 0;
                            }
                        })
                })
            }
            {
                Object.entries(keyValuePairObject)
                    .sort()
                    .filter(([key, value]) => key != "")
                    .map(([key, value]) => {
                        tempArray.push(key)
                        tempObj[key] = i;
                        ++i;
                    })
            }
            setOutputFieldsArray(tempObj);
            setAggregateFieldsArray(tempArray);
        } else {
            setOutputFieldsArray(tempObj);
            setAggregateFieldsArray([]);
        }
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/partner-statement'
                >
                    Partner Statement
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Query</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Query Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName}
                    />
                </div>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Description
                    </FormLabel>
                    <OutlinedInput
                        value={description}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName && description ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.dynamicQueryFieldsData && props.dynamicQueryFieldsData.numeric_fields ?
                                <Paper className={classes.root}>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer style={{ overflowX: "initial" }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tableCellStyle}>Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tableCellStyle}>Filter</TableCell>
                                                        <TableCell align='center' className={classes.tableCellStyle}>Aggregate</TableCell>
                                                        <TableCell align='center' className={classes.tableCellStyle}>Average</TableCell>
                                                        <TableCell align='center' className={classes.tableCellStyle}>Sum</TableCell>
                                                        <TableCell align='center' className={classes.tableCellStyle}> <FormControlLabel
                                                            value="external"
                                                            control={<Checkbox color="primary"
                                                                onChange={handleAllOutput}
                                                                checked={allOutput}
                                                            />}
                                                            label={<div style={{ fontSize: 14 }}>Output</div>}
                                                            labelPlacement="right"
                                                            style={{ fontSize: 14 }}
                                                            classes={{ root: 'fontSize: 14px' }}
                                                        /></TableCell>
                                                        <TableCell align='center'>Sort Order</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.entries(keyValuePairObject)
                                                        .sort()
                                                        .map(([key, value]) => {
                                                            return (
                                                                <TableRow key={key}>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{value}</TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={filterFieldsArray && filterFieldsArray.includes(key) ? filterFieldsArray.includes(key) : ''}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'filter_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={aggregateFieldsArray && aggregateFieldsArray.includes(key) ? aggregateFieldsArray.includes(key) : ''}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'aggregate_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={averageFieldsArray && averageFieldsArray.includes(key) ? averageFieldsArray.includes(key) : ''}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'avg_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={sumFieldsArray && sumFieldsArray.includes(key) ? sumFieldsArray.includes(key) : ""}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'sum_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={outputFieldsArray && outputFieldsArray.hasOwnProperty(key) ? outputFieldsArray.hasOwnProperty(key) : ''}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'output_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell style={{ width: 70 }}>
                                                                        <TextField
                                                                            key={['recipient', key].join('_')}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            value={(outputFieldsArray && outputFieldsArray[key]) ? (outputFieldsArray[key] || outputFieldsArray[key] === 0 ? outputFieldsArray[key] : '') : ''}
                                                                            onChange={(e) => handleChange(e, key, 'sort_order')}
                                                                            disabled={outputFieldsArray.hasOwnProperty(key) ? false : true}
                                                                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                                        />
                                                                    </TableCell>

                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Paper>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getDynamicQuerySalesDataFields: () => dispatch(getDynamicQuerySalesDataFields()),
        getCalculationSimulationDataFields: () => dispatch(getCalculationSimulationDataFields()),
        onSubmit: (data, edit) => dispatch(postDynamicQuery(data, edit, 'partner')),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
        getPurchaseDataFields: () => dispatch(getPurchaseDataFields()),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getDynamicQueryFields: (applicationType, sourceDataType, functionalityName) => dispatch(getDynamicQueryFieldsNew(applicationType, sourceDataType, functionalityName)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, null, null, 'Display Designer')),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsDataNew,
        calculationFieldsData: state.customerData.calculationSimulationData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        dynamicQueryDetailsData: state.operationalReportsData.dynamicQueryDetailsData,
        formFieldsAllData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicQuery);