import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    MenuItem,
    OutlinedInput
} from '@mui/material';
import { Link } from 'react-router-dom';
import { TextInputWithLabel, LabelText } from '../../components/Inputs';
import { putMaterialPricingData, getSingleMaterialPricingData } from '../../redux/actions';
import countryList from 'react-select-country-list'
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const MaterialPricingEdit = props => {
    const classes = useStyles();
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [currencyName, setCurrencyName] = React.useState('');
    const [dealerCost, setDealerCost] = React.useState('');
    const [msrpData, setMsrpData] = React.useState('');
    const [country, setCountry] = React.useState('');
    const currency = ['USD', 'CAD', 'EUR'];

    function handleCurrencyName(newValue) {
        setCurrencyName(newValue.target.value);
    }
    function handleDealerCost(newValue) {
        setDealerCost(newValue.target.value);
    }
    function handleMsrpData(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setMsrpData(e.target.value);
        }
        // setMsrpData(event.target.value);
    }
    function handleCountry(e) {
        setCountry(e.target.value);
    }
    function handleOnClear() {
        setCurrencyName('')
        setDealerCost('')
        setCountry('')
        setMsrpData('')
    }
    const handleSubmit = () => {
        var formData = {
            material_number: materialNumber,
            currency: currencyName,
            dealer_cost: dealerCost,
            msrp: Number(msrpData),
            // postal_code: postalCode.toString(),
            country: country,
            // base_salary: Number(baseSalary)
        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(formData, appId);
    };
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleMaterialPricingData(appId);
    }, []);
    useEffect(() => {
        if (props.materialPricingDataSingle) {
            if (props.materialPricingDataSingle) {
                setMaterialNumber(props.materialPricingDataSingle.material_number);
                setCurrencyName(props.materialPricingDataSingle.currency);
                setDealerCost(props.materialPricingDataSingle.dealer_cost);
                setMsrpData(props.materialPricingDataSingle.msrp);
                setCountry(props.materialPricingDataSingle.country);
            }
        }
    }, [props.materialPricingDataSingle]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/material-pricing'
                >
                    Material Pricing
                </Link>
                <Typography color="textPrimary" variant='h4'>Material Pricing </Typography>
            </Breadcrumbs>
            {props.materialPricingDataSingle &&
                <div className={classes.bodyContainer}>
                    <Card
                        style={{ marginTop: 15 }}>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container
                                    style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "15px" }}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Material Number
                                        </FormLabel>
                                        <OutlinedInput
                                            value={props.materialPricingDataSingle.material_number}
                                            classes={{ root: classes.inputTwoLine }}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Country
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={country}
                                            onChange={handleCountry}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{
                                                height: 35,
                                                marginTop: 10,
                                            }}
                                        >
                                            <MenuItem value={'USA'} key={'USA'} style={{ textTransform: 'capitalize' }}>USA</MenuItem>
                                            <MenuItem value={'CA'} key={'CA'} style={{ textTransform: 'capitalize' }}>CA</MenuItem>

                                        </Select>
                                        {/* <OutlinedInput
                                            value={country}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCountry} /> */}
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Currency
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={currencyName}
                                            onChange={handleCurrencyName}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{
                                                height: 35,
                                                marginTop: 10,
                                            }}
                                        >
                                            {currency && currency.length > 0 && currency
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                        {/* <OutlinedInput
                                            value={currencyName}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCurrencyName}
                                            prevalue={props.materialPricingDataSingle.currency} /> */}
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Dealer Cost
                                        </FormLabel>
                                        <OutlinedInput
                                            value={dealerCost}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleDealerCost}
                                            prevalue={props.materialPricingDataSingle.dealer_cost}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Msrp
                                        </FormLabel>
                                        <OutlinedInput
                                            value={msrpData}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMsrpData}
                                            prevalue={props.materialPricingDataSingle.msrp}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.submitContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                            Clear
                        </Button>
                        <Button color="primary" variant="contained" className={classes.button} onClick={handleSubmit} disabled={currencyName
                            && msrpData && country && dealerCost && materialNumber ? false : true}>
                            Submit
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        materialPricingDataSingle: state.promotionData.materialPricingDataSingle,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(putMaterialPricingData(data, id)),
        getSingleMaterialPricingData: (id) => dispatch(getSingleMaterialPricingData(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MaterialPricingEdit);