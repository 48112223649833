// import React, { useEffect } from "react";
// import clsx from "clsx";
// import { makeStyles, useTheme, withStyles } from "@mui/styles";
// import {
//   Breadcrumbs,
//   Typography,
//   Link,
//   useMediaQuery,
//   Table,
//   TableCell,
//   TableHead,
//   TableRow,
//   TableContainer,
//   TableBody,
//   Button,
//   Paper,
//   Fab,
//   TablePagination,
// } from "@mui/material";
// import { SearchInput } from "../../components";
// import { connect } from "react-redux";
// import {
//   getOrgDefaults,
//   downloadPredefinedQuery,
//   getDefaultValuesAllDataNew,
// } from "../../redux/actions";
// import Moment from "moment";
// import BeatLoader from "react-spinners/BeatLoader";
// import { PDFExport } from "@progress/kendo-react-pdf";
// import GetAppIcon from "@mui/icons-material/GetApp";
// import { toast } from "react-toastify";
// import { Zoom } from "react-toastify";
// import LoadingOverlay from "react-loading-overlay";
// import { RotateLoader, HashLoader } from "react-spinners";
// import {
//   ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Aggregate,
//   ExcelExport, Toolbar, PdfExport, Search, CommandColumn, AggregateDirective, AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, ColumnMenu, VirtualScroll
// } from '@syncfusion/ej2-react-grids';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.white,
//     borderRadius: 10,
//     padding: '10px 10px 0px 10px',
//     //width:'100%',
//     width: 'fit-content',
//     overflow: 'scroll'

//   },
//   gridBox: {
//     backgroundColor: theme.palette.white,
//     borderRadius: 10,
//     padding: '10px 10px 0px 10px',
//     marginBottom: 10,
//     //overflowX: 'hidden',
//     overflowY: 'scroll'
//   },
//   row: {
//     flexDirection: 'row',
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
//   tableCell: {
//     paddingLeft: 18
//   },
//   buttonContainer: {
//     display: 'flex',
//   },
//   button: {
//     marginLeft: 15
//   },
//   sumContainer: {
//     // backgroundColor: theme.palette.primary.main,
//     // color: 'white',
//     color: theme.palette.primary.main
//   },
//   cell: {
//     backgroundColor: 'white !important',
//     background: 'white !important',
//     color: 'black'
//   },
//   fabContainer: {
//     marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
//     borderColor: theme.palette.primary.main,
//     // position:'absolute',
//     zIndex: 1
//   },
//   fontSetting: {
//     fontSize: theme.typography.h4.fontSize
//   }
// }));

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.table.row,
//       height: 46,
//     },
//     "&:nth-of-type(even)": {
//       height: 40,
//     },
//   },
// }))(TableRow);

// const PredefinedQueryResults = (props) => {
//   let grid;
//   const classes = useStyles();
//   const { className } = props;
//   const theme = useTheme();
//   const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
//     defaultMatches: true,
//   });
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const [dataRows, setDataRows] = React.useState([]);
//   const [dataCount, setDataCount] = React.useState(0);
//   const [columns, setColumns] = React.useState([]);
//   const [sortedArray, setSortedArray] = React.useState([])
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };
//   const handleSheetDownload = () => {
//     var pathname = window.location.pathname;
//     var appId = pathname.substring(pathname.lastIndexOf("/") + 1);
//     var data = {
//       id: appId,
//     };
//     if (appId) props.downloadDynamicQuery(data);
//   };
//   useEffect(() => {
//     if (
//       props.queryResultsFinalData &&
//       props.queryResultsFinalData[1] &&
//       props.queryResultsFinalData[1].app_type &&
//       props.queryResultsFinalData[1].data_source_type
//     )
//       props.onLoadingLabelDesignerNew(
//         "field-label-designer",
//         props.queryResultsFinalData[1].app_type,
//         props.queryResultsFinalData[1].data_source_type
//       );
//   }, []);

//   useEffect(() => {
//     props.getOrgDefaults();
//   }, []);
//   useEffect(() => {
//     var newArray = []
//     if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
//       //   Object.values(props.queryResultsFinalData.splice(props.queryResultsFinalData.length-1,props.queryResultsFinalData.length)).map(item=>{
//       //     Object.values(item).map(e=>{
//       //         setSortedArray(e)
//       //     })
//       // })

//       props.queryResultsFinalData.map(item => {
//         Object.entries(item).map(([key, value]) => {
//           if (key == 'column_order') {
//             setSortedArray(value)
//           }
//         })
//       })
//       Object.values(props.queryResultsFinalData).map(item => {
//         Object.keys(item)
//           .filter(item => item !== 'column_order')
//           .map((e, index) => {
//             newArray.push({ 'field': e, 'title': e ? e.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : e })
//           })

//       })
//       setColumns(newArray.sort(function (a, b) {
//         return sortedArray.indexOf(a.field) - sortedArray.indexOf(b.field);
//       }).filter((value, index, self) =>
//         index === self.findIndex((t) => (
//           t.title === value.title
//         ))
//       ))

//       setDataRows(props.queryResultsFinalData.splice(0, props.queryResultsFinalData.length - 1));
//       setDataCount(props.queryResultsFinalData.length)
//     }
//   }, [props.queryResultsFinalData]);
//   console.log("datarows", dataRows)
//   console.log(sortedArray)
//   const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search']
//   const toolbarClick = (args) => {
//     if (grid && args.item.id === 'grid_pdfexport') {
//       grid.showSpinner();
//       const exportProperties = {
//         pageOrientation: 'Landscape',
//         pageSize: 'A0',
//         fileName: Moment.utc(new Date()).format('MM/DD/YYYY') + '.pdf',
//         theme: {
//           header: {
//             bold: true,
//             fontSize: 14,
//           },
//           record: {
//             bold: false,
//             fontSize: 14,
//           },
//           caption: {
//             bold: true,
//             fontSize: 14,
//           },
//         }
//       };
//       grid.pdfExport(exportProperties);
//     }
//     if (grid && args.item.id === 'grid_excelexport') {
//       grid.showSpinner();
//       const excelExportProperties = {
//         dataSource: [...dataRows],
//       };
//       grid.excelExport(excelExportProperties);
//     }
//   };

//   const pdfExportComplete = () => {
//     if (grid) {
//       grid.hideSpinner();
//     }
//   };
//   const excelExportComplete = () => {
//     if (grid) {
//       grid.hideSpinner();
//     }
//   };
//   const filterSettings = { type: 'CheckBox' };
//   const groupOptions = { showGroupedColumn: true }
//   const valueFormat = (field, data, column) => {
//     if (typeof (data[field]) == 'number') {
//       return parseFloat(data[field]).toLocaleString(undefined, {
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2,
//       }).toString()
//     }
//     else if (data && data[field].includes('T00:00:00Z')) {
//       return Moment(data[field], true).isValid()
//         ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
//     }
//     else {
//       return data[field];
//     }
//   };
//   const dataBound = () => {
//     if (grid) {
//       grid.autoFitColumns([
//         'customer_name',
//         'street',
//         'GPO_member_name',
//         'action_code',
//         'city',
//       ])

//     }
//   };

//   return (
//     <LoadingOverlay
//       active={props.loading}
//       styles={{
//         spinner: (base) => ({
//           ...base,
//           width: "50px",
//           "& svg circle": {
//             stroke: "#64C4B4",
//           },
//         }),
//         overlay: (base) => ({
//           ...base,
//           background: "rgba(52, 52, 52, 0)",
//         }),
//         content: (base) => ({
//           ...base,
//           color: "#64C4B4",
//         }),
//       }}
//       spinner={<HashLoader />}
//       className={classes.spinner}
//     >
//       {props.queryResultsFinalData && props.queryResultsFinalData.length > 0 ?
//         <Paper className={classes.gridBox} style={{ paddingTop: 0, }}>
//           <div style={{ marginTop: 10, overflow: 'scroll' }}>
//             <GridComponent
//               dataSource={dataRows}
//               id='grid'
//               dataBound={dataBound}
//               allowSorting={true} allowGrouping={true} showColumnMenu={true}
//               allowPaging={true}
//               pageSettings={{ pageSize: 10000 }}
//               groupSettings={groupOptions}
//               filterSettings={filterSettings}
//               allowFiltering={true}
//               ref={g => grid = g}
//               toolbar={toolbarOptions}
//               toolbarClick={toolbarClick}
//               allowPdfExport={true} allowExcelExport={true}
//               pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
//               height={window.screen.height * .64}
//               gridLines='Both' allowResizing={true}
//             // enableVirtualization={true} enableColumnVirtualization={false}
//             >
//               <ColumnsDirective>
//                 {columns
//                   .sort(function (a, b) {
//                     return sortedArray.indexOf(a.field) - sortedArray.indexOf(b.field);
//                   })
//                   .map((item, order) => {
//                     return (
//                       <ColumnDirective
//                         field={item.field}
//                         valueAccessor={valueFormat}
//                         headerText={item.title}
//                         width="200px" minWidth='100px' maxWidth='300px'

//                       />
//                     );
//                   })
//                 }
//               </ColumnsDirective>
//               <Inject services={[Toolbar, PdfExport, ExcelExport, Aggregate, Group, Sort, ColumnMenu, Filter, VirtualScroll, Resize, Page]} />
//             </GridComponent>
//           </div>
//         </Paper>
//         :
//         <Typography variant='h4'>
//           There is no data to show now.
//         </Typography>

//       }
//     </LoadingOverlay>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     loading: state.operationalReportsData.loading,
//     queryResultsFinalData:
//       state.operationalReportsData.predefinedResultsFinalData,
//     labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
//     orgDefaults: state.addMultipleConfigurationData.orgDefaults,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     downloadPredefinedQuery: (data) => dispatch(downloadPredefinedQuery(data)),
//     onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) =>
//       dispatch(
//         getDefaultValuesAllDataNew(command, applicationType, sourceDataType)
//       ),
//     getOrgDefaults: () => dispatch(getOrgDefaults()),
//   };
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(PredefinedQueryResults);

import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Paper,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  PdfExport,
  ExcelExport,
  Aggregate,
  Group,
  Sort,
  ColumnMenu,
  Filter,
  VirtualScroll,
  Resize,
  Page
} from '@syncfusion/ej2-react-grids';
import Moment from "moment";
import { connect } from "react-redux";
import { getOrgDefaults, downloadPredefinedQuery, getDefaultValuesAllDataNew } from "../../redux/actions";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '10px 10px 0px 10px',
    width: 'fit-content',
    overflow: 'scroll'
  },
  gridBox: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '10px 10px 0px 10px',
    marginBottom: 10,
    overflowY: 'scroll'
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

const PredefinedQueryResults = (props) => {
  let grid;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), { defaultMatches: true });
  const [dataRows, setDataRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [sortedArray, setSortedArray] = React.useState([]);

  useEffect(() => {
    if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
      const finalData = [...props.queryResultsFinalData];
      const columnOrder = finalData.find(item => item.column_order)?.column_order || [];
      setSortedArray(columnOrder);

      const newColumns = Object.keys(finalData[0])
        .filter(key => key !== 'column_order')
        .map(key => ({
          field: key,
          title: key.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })
        }))
        .sort((a, b) => columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field))
        .filter((value, index, self) => index === self.findIndex(t => t.title === value.title));

      setColumns(newColumns);
      setDataRows(finalData.filter(item => !item.column_order));
    }
  }, [props.queryResultsFinalData]);

  const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search'];
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'grid_pdfexport') {
      const exportProperties = {
        pageOrientation: 'Landscape',
        pageSize: 'A0',
        fileName: Moment.utc(new Date()).format('MM/DD/YYYY') + '.pdf',
        theme: {
          header: { bold: true, fontSize: 14 },
          record: { bold: false, fontSize: 14 },
          caption: { bold: true, fontSize: 14 }
        }
      };
      grid.pdfExport(exportProperties);
    }
    if (grid && args.item.id === 'grid_excelexport') {
      const excelExportProperties = { dataSource: [...dataRows] };
      grid.excelExport(excelExportProperties);
    }
  };

  const valueFormat = (field, data, column) => {
    if (typeof data[field] === 'number') {
      return parseFloat(data[field]).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).toString();
    } else if (data[field] && data[field].includes('T00:00:00Z')) {
      return Moment(data[field], true).isValid()
        ? Moment.utc(data[field]).format('MM/DD/YYYY').toString()
        : '';
    } else {
      return data[field];
    }
  };

  return (
    <LoadingOverlay
      active={props.loading}
      spinner={<HashLoader />}
      className={classes.spinner}
    >
      {dataRows && dataRows.length > 0 ? (
        <Paper className={classes.gridBox} style={{ paddingTop: 0 }}>
          <div style={{ marginTop: 10, overflow: 'scroll' }}>
            <GridComponent
              dataSource={dataRows}
              id='grid'
              allowSorting={true}
              allowGrouping={true}
              showColumnMenu={true}
              allowPaging={true}
              pageSettings={{ pageSize: 10000 }}
              groupSettings={{ showGroupedColumn: true }}
              filterSettings={{ type: 'CheckBox' }}
              allowFiltering={true}
              ref={g => grid = g}
              toolbar={toolbarOptions}
              toolbarClick={toolbarClick}
              allowPdfExport={true}
              allowExcelExport={true}
              height={window.screen.height * .64}
              gridLines='Both'
              allowResizing={true}
            >
              <ColumnsDirective>
                {columns.map((item, order) => (
                  <ColumnDirective
                    key={order}
                    field={item.field}
                    valueAccessor={valueFormat}
                    headerText={item.title}
                    width="200px"
                    minWidth='100px'
                    maxWidth='300px'
                  />
                ))}
              </ColumnsDirective>
              <Inject services={[Toolbar, PdfExport, ExcelExport, Aggregate, Group, Sort, ColumnMenu, Filter, VirtualScroll, Resize, Page]} />
            </GridComponent>
          </div>
        </Paper>
      ) : (
        <Typography variant='h4'>
          There is no data to show now.
        </Typography>
      )}
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => ({
  loading: state.operationalReportsData.loading,
  queryResultsFinalData: state.operationalReportsData.predefinedResultsFinalData,
  labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
  orgDefaults: state.addMultipleConfigurationData.orgDefaults,
});

const mapDispatchToProps = (dispatch) => ({
  downloadPredefinedQuery: (data) => dispatch(downloadPredefinedQuery(data)),
  onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) =>
    dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
  getOrgDefaults: () => dispatch(getOrgDefaults()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedQueryResults);

