import React, { useRef, useEffect } from 'react';
import {
    Typography, Stepper, Step, StepButton,
    StepConnector, StepLabel, Button,
    Grid, useMediaQuery,
    Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import HashLoader from "react-spinners/HashLoader";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { GeneralData, InternalNotes, ApprovalStatus } from './Components';
import { addDocumentData, getDefaultValues, getLblDispDesFieldValue, fetchDocumentStatus } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: -20,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto',
        marginTop: 10
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    pageControls: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));
const tabs = {
    generalData: 0,
    notesAndAttachments: 1,
    approvalStatus: 2

}


const UpdateDocument = props => {

    const classes = useStyles();
    const theme = useTheme();
    const currentApplication = sessionStorage.getItem('application')
    const [internalNotesData, setInternalNotesData] = React.useState([]);
    const [documentFormFields, setDocumentFormFields] = React.useState([])
    const [generalData, setGeneralData] = React.useState([]);
    const [approvalStatusData, setApprovalStatusData] = React.useState({});
    const [mode, setMode] = React.useState('');
    const [docStatusData, setDocStatusData] = React.useState({});
    const [statusFields, setStatusFields] = React.useState({});
    const [comments, setComments] = React.useState([]);
    const [documentId, setDocumentId] = React.useState(0);
    const [saveEnable, setSaveEnable] = React.useState(false);
    const [mandatoryfields, setManadatoryFields] = React.useState([]);
    const [approvalStatus, setApprovalStatus] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const skipped = new Set()
    const steps = getSteps();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        var data = {
            "data_source": "document_header",
            "application_type": "Customer Rebate"
        }
        props.loadListControlData(data, 'post');

    }, []);


    useEffect(() => {
        if (props.tableData && props.tableData.field_label_attributes) {
            setStatusFields({
                'document_status': props.tableData.field_label_attributes['document_status'],
                'document_manager': props.tableData.field_label_attributes['document_manager'],
                'document_analyst': props.tableData.field_label_attributes['document_analyst'],
            })
            setDocumentFormFields(Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_details || item.mandatory)
                .sort(function (a, b) {
                    if (a.sort_details < 0 || b.sort_details < 0)
                        return b.sort_details - a.sort_details;
                    else
                        return a.sort_details - b.sort_details;
                })
            )
            setManadatoryFields(Object.values(props.tableData.field_label_attributes).filter((item) => item.mandatory).map(e => e.key))
        }

    }, [props.tableData && props.tableData.field_label_attributes])

    useEffect(() => {
        if (props.documentData) {
            setGeneralData(props.documentData)
            var approveForm = {
                "application_type": currentApplication,
                "functionality": "document_header",
                "workflow_name": props.documentData.workflow_name,
                "status_description": props.documentData.document_status

            }
            props.getDocStatus(approveForm)
            setApprovalStatusData({
                document_status: props.documentData.document_status,
                document_analyst: props.documentData.document_analyst,
                document_manager: props.documentData.document_manager,
                approval_status: props.documentData.approval_status
            })
            setDocumentId(props.documentData.document_id)
            setInternalNotesData(props.documentData.notes)
            setMode('update')
            // checkValues()
        }
    }, [props.documentData])
    useEffect(() => {
        if (props.docStatus && approvalStatusData && approvalStatusData.document_status) {
            var data = props.docStatus.filter(item => item.status_description === approvalStatusData.document_status)
            if (data && data[0]) {
                setDocStatusData(data[0])
                setApprovalStatus(data[0].approval_status)
            }
        }

    }, [props.docStatus])
    useEffect(() => {
        if (generalData && Object.keys(generalData).length > 0) {
            var requiredData = Object.entries(generalData).filter(([k, v]) => mandatoryfields.includes(k) && v != '')
            setSaveEnable((requiredData.length == mandatoryfields.length) ? true : false)
        }
    }, [generalData, saveEnable])
    // function checkValues() {
    //     if (generalData && Object.keys(generalData).length > 0) {
    //         var requiredData = Object.entries(generalData).filter(([k, v]) => mandatoryfields.includes(k) && v != '')

    //         setSaveEnable((requiredData.length == mandatoryfields.length) ? true : false)
    //     }
    // }
    function getSteps() {
        return ['Primary*', 'Notes and Attachments', 'Document Status*'];

    }
    const skippedSteps = () => {
        return skipped.size;
    };
    const isLastStep = () => {
        return activeStep === getSteps().length - 1;
    };
    const handleStep = (step) => () => {

        setActiveStep(step);
    };
    const allStepsCompleted = () => {
        return completedSteps() === getSteps().length - skippedSteps();
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const completedSteps = () => {
        return completed.size;
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleComplete = () => {
        var data = {
            application_type: sessionStorage.getItem('application'),
            ...generalData,
            notes: [...internalNotesData, ...comments]
        }
        props.onSubmit(data, generalData.ID, 'edit');
    };
    const handleDiscard = () => {
        history.push("/documents");
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/documents");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    function handleGeneralDataChange(key, value) {

        setGeneralData({ ...generalData, [key]: value })
        // checkValues()
    };
    function handleNotes(note) {
        setInternalNotesData(note)
    };
    function handleComments(note) {
        setComments(note)
    };

    function getStepContent(step) {
        var allSteps = [
            <GeneralData onChange={handleGeneralDataChange} fieldData={documentFormFields}
                mode={mode} generalData={generalData} status={approvalStatus}
            />,
            <InternalNotes onChange={handleNotes} mode={mode} generalData={generalData} status={approvalStatus} />,
            <ApprovalStatus onChange={handleGeneralDataChange} mode={mode} docId={documentId}
                onAddComment={handleComments}
                fieldData={statusFields} docStatus={docStatusData}
                approvalStatusData={approvalStatusData} />
        ]
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }
    return (
        <div>
            <LoadingOverlay
                active={props.loadingFields || props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: "50px",
                        "& svg circle": {
                            stroke: "#045FB4",
                        },
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(52, 52, 52, 0)",
                    }),
                    content: (base) => ({
                        ...base,
                        color: "black",
                    }),
                }}
                className={classes.spinner}
            >
                <div className={classes.stepper}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}
                        classes={{ root: classes.stepperContainer }}
                        connector={
                            <StepConnector
                                classes={{
                                    line: classes.connectorLine,
                                    alternativeLabel: classes.connectorAlternativeLabel
                                }}
                            />
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}
                                    classes={{ horizontal: classes.horizontal }}
                                >
                                    <StepButton
                                        onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                        classes={{ root: classes.stepButton }}
                                    >
                                        <StepLabel>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                <Grid container >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        {activeStep !== 0 &&
                            <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                Previous Step
                            </Button>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Button variant="outlined" color="primary" onClick={handleComplete}
                            className={clsx({
                                [classes.button]: true,
                                [classes.shiftContent]: !isDesktop,
                            })}
                            disabled={saveEnable ? false : true}
                        >
                            {props.loading ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                :
                                ''
                            }
                            {props.loading ? '' : 'Save'}
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleDiscard} className={classes.button}>
                            Discard
                        </Button>
                        {!isLastStep() &&
                            <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}
                            // disabled={(!calculationMethod||!postingSchema|| !sourceDataType)?true:false}
                            >
                                Next Step
                            </Button>
                        }
                    </Grid>
                </Grid>
            </LoadingOverlay>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        loadingFields: state.addMultipleConfigurationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        documentData: state.DocumentManagement.documentSingleData,
        docStatus: state.DocumentManagement.documentStatus,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id, type) => dispatch(addDocumentData(data, id, type)),
        loadListControlData: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        onLoadingFormField: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), null, null, null, 'Contract Designer')),
        getDocStatus: (docType) => dispatch(fetchDocumentStatus(docType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDocument);