import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {

    useMediaQuery,
    TableRow,
    Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getPerformanceMetricData, createPerformanceMetric } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MaterialTable from 'material-table';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import ViewColumn from '@mui/icons-material/ViewColumn';
import CheckIcon from '@mui/icons-material/Check';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { NavLink as RouterLink } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import Moment from 'moment';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    }
}));

const PerformanceMetricMain = props => {
    useEffect(() => {
        props.getPerformanceMetricData(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const columns = [
        {
            field: 'employee_number',
            title: 'Employee Number',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.employee_number}</a>
        },
        {
            field: 'employee_name',
            title: 'Employee name',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.employee_name}</a>
        },
        {
            field: 'territory_id',
            title: 'Territory ID',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.territory_id}</a>
        },
        {
            field: 'current_period_sales',
            title: 'Current Period Sales',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.current_period_sales}</a>
        },
        {
            field: 'prior_period_sales',
            title: 'Prior Period Sales',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.prior_period_sales}</a>
        },
        {
            field: 'current_period_makert_share',
            title: 'Current Period Makert Share',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.current_period_makert_share}</a>
        },
        {
            field: 'prior_period_makert_share',
            title: 'Prior Period Makert Share',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.prior_period_makert_share}</a>
        },
        {
            field: 'current_period_start_date',
            title: 'Current Period Start Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {Moment(rowData.current_period_start_date).local().format('YYYY-MM-DD')}</a>
        },
        {
            field: 'current_period_end_date',
            title: 'Current Period End Date',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}>{Moment(rowData.current_period_end_date).local().format('YYYY-MM-DD')}</a>
        },

        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_performance_metric-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editPerformanceMetric(rowData.id)}
                            size="large">
                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_performance_metric-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => props.deletePerformanceMetricData(rowData, rowData.id)}
                            size="large">
                            <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataRows, setDataRows] = React.useState([]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getPerformanceMetricData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getPerformanceMetricData(1, event.target.value);
    };
    /* const handleSheetDownload = () => {
         if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
             props.downloadSheet(contractsTableComplete.records);
     }*/
    /*  useEffect(() => {
          if (props.PerformanceMetricData) {
              setPerformanceMetric(props.quotaData);
          }
      }, [props.quotaData]);*/

    function editPerformanceMetric(id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/performance-metric/edit-performance-metric/' + id,
            id: id
        });

    }


    function handleItemClick(id) {
        // props.getMaterialMasterViewDetails(id);
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));

    useEffect(() => {
        var tempRows = [];
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        if (props.performanceMetricData && props.performanceMetricData.total_record > 0 && props.performanceMetricData.records)
            props.performanceMetricData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        employee_number: e.employee_number,
                        employee_name: e.employee_name,
                        current_period_start_date: e.current_period_start_date,
                        current_period_end_date: e.current_period_end_date,
                        prior_period_start_date: e.prior_period_start_date,
                        prior_period_end_date: e.prior_period_end_date,
                        current_period_sales: e.current_period_sales,
                        prior_period_sales: e.prior_period_sales,
                        current_period_makert_share: e.current_period_makert_share,
                        prior_period_makert_share: e.prior_period_makert_share,
                        territory_id: e.territory_id,

                    });

                })
        console.log(props.performanceMetricData)
        setDataRows(tempRows);
    }, [props.performanceMetricData]);
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading. Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 22 }}> Performance Metric</div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_performance_metric-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddBoxOutlinedIcon />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                component={CustomRouterLink}
                                to='/performance-metric/add-performance-metric'
                                size={'small'}
                                className={classes.buttonStyle}

                            >
                                {isDesktop ? 'Add Performance Metric' : ''}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddBoxOutlinedIcon />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                component={CustomRouterLink}
                                to='/performance-metric/performance-metric-excel-upload'
                                size={'small'}
                                className={classes.buttonStyle}
                                style={{ marginLeft: 10 }}

                            >
                                Upload Excel
                            </Button>
                        </div>}
                </div>
                {props.performanceMetricData && props.performanceMetricData.records && props.performanceMetricData.total_record > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        performanceMetricData: state.performanceMetricData.performanceMetricData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPerformanceMetricData: (pagination, limit) => dispatch(getPerformanceMetricData(pagination, limit)),
        deletePerformanceMetricData: (data, id) => dispatch(createPerformanceMetric(data, 'delete', id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceMetricMain);