import React from 'react';
import {
    Typography,
    Breadcrumbs,
    Stepper,
    Step,
    StepButton,
    StepConnector,
    StepLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GeneralData, ErrorLines, LineDetails, InitialData } from './components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));
const tabs = {
    generalData: 0,
    groupingIdentifiers: 1,
    OrganizationData: 2
};

function getSteps() {
    return ['Reconciled', 'No Response', 'Rejected', 'All Lines'];
}

const ViewOutgoingClaims = props => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const skipped = new Set()
    const steps = getSteps();

    const handleStep = (step) => () => {
        // if (step === tabs.eligibilityRules && (localStorage.getItem('mode') === 'edit')) {
        //     props.onCheckEligibillityRulesTable(localStorage.getItem('currentAddContractID'));
        // } else if (step === tabs.calculationRules && (localStorage.getItem('mode') === 'edit')) {
        //     props.onCheckCalculationRulesTable(localStorage.getItem('currentAddContractID'));
        // }
        setActiveStep(step);
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        const allSteps = [
            <GeneralData />,
            <InitialData />,
            <ErrorLines />,
            <LineDetails />
        ];

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    return (
        <div>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/customer-outgoing-claims'
                    >
                        Outgoing Claims
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp;{'View Outgoing Claims'}</Typography>
                </Breadcrumbs>
                <div className={classes.stepper}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}
                        classes={{ root: classes.stepperContainer }}
                        connector={
                            <StepConnector
                                classes={{
                                    line: classes.connectorLine,
                                    alternativeLabel: classes.connectorAlternativeLabel
                                }}
                            />
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}
                                    classes={{ horizontal: classes.horizontal }}
                                >
                                    <StepButton
                                        onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                        classes={{ root: classes.stepButton }}
                                    >
                                        <StepLabel>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>

            </div>
        </div>
    );

};

export default connect(null, null)(ViewOutgoingClaims);