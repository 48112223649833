import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    TablePagination,
    Typography,
    DialogTitle,
    Dialog, DialogActions, DialogContent, Button
} from '@mui/material';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getErrorLogs } from '../../redux/actions';
import MaterialTable from 'material-table';
import { createTheme } from "@mui/material/styles";
import Moment from 'moment';
import { tableIcons } from '../../components/Icons/TableIcons';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    }
}));

const ErrorLog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dialog, setDialog] = React.useState(false);
    const [errorList, setErrorList] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const columns = [
        {
            field: 'file_type',
            title: 'File Type',
            editable: 'never',
            type: 'string',

        },
        {
            field: 'format_id',
            title: 'Format ID',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'file_name',
            title: 'File Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'user_name',
            title: 'User Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'updated_at',
            title: 'Created At',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} > {Moment(rowData.updated_at).local().format('YYYY-MM-DD HH:MM:SS')}</a>
        },
        {
            field: 'Error List',
            title: 'Error List',
            type: 'string',
            editable: 'never',
            filtering: false,
            render: rowData =>
                <div onClick={() => viewErrorList(rowData.error_list, 'Error Summary')} style={{
                    cursor: 'pointer', color: 'red', fontSize: 10, display: 'flex',
                    alignItems: 'center',
                    border: '1px solid red',
                    padding: 4,
                    marginLeft: 10,
                    marginRight: 10,
                    width: '120px'
                }}>Show Error Summary
                </div>
        },
    ];
    useEffect(() => {
        props.getErrorLog(1, 10);
    }, []);
    function viewErrorList(error, title) {
        setErrorList(error)
        setDialog(true)
        setTitle(title)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getErrorLog(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        console.log(event.target.value)
        setRowsPerPage(event.target.value);
        props.getErrorLog(1, event.target.value);
    };
    useEffect(() => {
        var tempRows = [];
        if (props.errorLogData && props.errorLogData.records && props.errorLogData.total_record > 0) {
            props.errorLogData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        file_type: e.file_type,
                        format_id: e.format_id,
                        file_name: e.file_name,
                        user_name: e.user_name,
                        error_list: e.error_list,
                        updated_at: e.UpdatedAt
                    });
                })
            setDataRows(tempRows);
            setCount(props.errorLogData.total_record)
        }
    }, [props.errorLogData]);
    return (
        <div>
            <Typography variant="h1" color='primary' style={{ marginLeft: 16, marginTop: 18 }}>Build Data Log</Typography>
            <div className={classes.root}>
                {props.errorLogData && props.errorLogData.records && props.errorLogData.total_record > 0 ?
                    <MaterialTable
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    count={count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}

                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            ),
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            pageSize: rowsPerPage,
                            count: count,
                            page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            //pageSize: 10,
                            //pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </div>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={dialog}
            >
                <DialogActions
                    style={{
                        position: 'absolute', marginLeft: 320
                    }} >
                    <Button color="primary" variant="outlined" onClick={() => setDialog(false)}>Continue</Button>
                </DialogActions>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>
                    <List>
                        {errorList && errorList.length > 0 ? errorList.map((item, index) => {
                            return (<ListItem>
                                <ListItemText
                                    primary={[index + 1] + ' . ' + item}
                                />
                            </ListItem>
                            )
                        }) :
                            <Typography variant='h4'>
                                There is no data to show now.
                            </Typography>
                        }
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        errorLogData: state.auditLabData.errorLogData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getErrorLog: (pagination, limit) => dispatch(getErrorLogs(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorLog);