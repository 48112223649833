import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Select,
    MenuItem,
    FormControl,
    FormLabel,
} from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0 0px 15px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    error: {
        border: '1px solid red !important'
    }
}));

const DropdownArrayForMaster = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const [data, setData] = React.useState(props.defaultValue ? 0 : '');
    const [changedFlag, setChangedFlag] = React.useState(0);
    const handleChange = (event) => {
        setData(event.target.value);
        setChangedFlag(1);
        props.onChange(event.target.value);
    };
    const reset = () => {
        setData('');
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : data
        };
    });

    return (
        <div>
            {props.twoline ?
                <Grid container className={classes.root}>
                    <Grid
                        item
                        style={{
                            width: '100%',
                        }}
                    >
                        <FormLabel {...props} error={false}>
                            {props.heading}
                        </FormLabel>
                        <FormControl {...props}>
                            <Select
                                onChange={handleChange}
                                value={!changedFlag && props.prevalue ? props.prevalue : data}
                                displayEmpty
                                className={classes.select}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true,
                                    [classes.error]: props.error
                                })}
                            >
                                {props.placeholder &&
                                    <MenuItem value="">
                                        {props.placeholder}
                                    </MenuItem>
                                }
                                {props.data && props.data.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index} name={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid >
                :
                <Grid container className={clsx(classes.root, className)}>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <FormLabel {...props}>
                            {props.heading}
                        </FormLabel>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <FormControl >
                            <Select
                                disableUnderline
                                onChange={handleChange}
                                displayEmpty
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                value={!changedFlag && props.prevalue ? props.prevalue : data}
                            >
                                {props.placeholder &&
                                    <MenuItem value="">
                                        {props.placeholder}
                                    </MenuItem>
                                }
                                {props.data.map((item) => {
                                    return (
                                        <MenuItem value={item.key} key={item.key}>
                                            {item.value}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid >
            }
        </div>
    );

});

export default DropdownArrayForMaster;