import React ,{ useEffect}from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card, OutlinedInput, FormLabel
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {  createClaimError } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 28,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    formDiv: {
        fontSize:theme.typography.h3.fontSize
    }
}));

const AddClaimError = props => {
    const classes = useStyles();
    const [rejectionReasonCode, setRejectionReasonCode] = React.useState('');
    const [rejectionReasonDescription, setRejectionReasonDescription] = React.useState('');
    const [claimError, setClaimError] = React.useState('');
    const [allDataNew, setAllDataNew] = React.useState([]);
    function handleClearAll() {
        setRejectionReasonCode([])
        setRejectionReasonDescription([])
        setClaimError([])
    }
    useEffect(() => {
        if (props.uomData && props.uomData.rejection_reason ) {
           setClaimError(props.uomData.rejection_reason[0].claim_error)
           setRejectionReasonCode(props.uomData.rejection_reason[0].rejection_reason_code)
           setRejectionReasonDescription(props.uomData.rejection_reason[0].rejection_reason_description)
           setAllDataNew(props.uomData.rejection_reason)
        }
    }, [props.uomData]);
    function handleOnSubmit() {
        var data = [
            {
                "rejection_reason_code": rejectionReasonCode,
                "rejection_reason_description": rejectionReasonDescription,
                "claim_error": claimError
            }
        ]
        props.onSubmit(data, 'create');
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/rejection-reasons'
                >
                    Rejection Reasons
                </Link>
                <Typography color="Primary" variant='h4'>Add Rejection Reasons</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Rejection Reason Code
                                    </FormLabel>
                                    <OutlinedInput
                                        value={rejectionReasonCode}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setRejectionReasonCode(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Rejection Reason Description
                                    </FormLabel>
                                    <OutlinedInput
                                        value={rejectionReasonDescription}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setRejectionReasonDescription(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Claim Error
                                    </FormLabel>
                                    <OutlinedInput
                                        value={claimError}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setClaimError(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={
                            !rejectionReasonCode || !claimError || !rejectionReasonDescription ? true : false
                        }
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, field, type) => dispatch(createClaimError(data, field, type)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClaimError);