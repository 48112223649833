import React, { useEffect, forwardRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import {
    getPurchaseSalesDataDetailsById,
} from "../../redux/actions";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import MaterialTable from "material-table";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { NavLink as RouterLink } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ColumnDirective, ColumnsDirective, GridComponent, Group, Inject, Page, Sort, Resize, ExcelExport, Toolbar, PdfExport, Search, Filter } from '@syncfusion/ej2-react-grids';
import { DisplaySalesInvoiceDetailsPopup } from "..";

const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {},
        },
        MuiTableCell: {
            root: {
                padding: 10,
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0,
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16,
    },
    row: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    caption: {
        fontSize: 12,
    },
    paginationRoot: {
        border: "1px solid #EEEEEE",
        borderTop: 0,
    },
    description: {
        textAlign: "left",
        paddingLeft: 28,
    },
    hover: {
        cursor: "pointer",
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up("md")]: {
            paddingRight: 10,
        },
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 15,
        marginTop: 15,
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6,
        },
    },
}));

const DisplayPurchaseSalesInvoiceQuery = (props) => {
    const { className } = props;
    const classes = useStyles();
    var grid;
    const columns = [
        {
            field: "transaction_type",
            title: "Transaction Type",
            editable: "never",
            type: "string",
            render: (rowData) =>
                rowData.id ? (
                    <div
                        style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                        }}
                        onClick={() => handlePopupClick(rowData)}
                    >
                        {rowData.transaction_type}
                    </div>
                ) : (
                    <div style={{ color: "white" }}>-</div>
                ),
        },
        {
            field: "supplier_number",
            title: "Supplier Number",
            type: "string",
            editable: "never",
        },
        {
            field: "supplier_name",
            title: "Supplier Name",
            type: "string",
            editable: "never",
        },
        {
            field: "purchase_org",
            title: "Purchase Organisation",
            type: "string",
            editable: "never",
        },
        {
            field: "purchase_order",
            title: "Purchase Order",
            type: "string",
            editable: "never",
        },
        {
            field: "purchase_order_line",
            title: "Purchase Order Line",
            type: "string",
            editable: "never",
        },
        {
            field: "material_number",
            title: "Material Number",
            type: "string",
            editable: "never",
        },
        {
            field: "material_description",
            title: "Material Description",
            type: "string",
            editable: "never",
        },
        {
            field: "po_qty",
            title: "PO Quantity",
            type: "number",
            editable: "never",
        },
        {
            field: "po_net_value",
            title: "PO Net Value",
            type: "number",
            editable: "never",
        },
        {
            field: "gr_date",
            title: "GR Date",
            type: "string",
            editable: "never",
        },
        {
            field: "gr_document_number",
            title: "GR Document Number",
            type: "string",
            editable: "never",
        },
        {
            field: "gr_line_number",
            title: "GR Line Number",
            type: "string",
            editable: "never",
        },
        {
            field: "gr_qty",
            title: "GR Quantity",
            type: "number",
            editable: "never",
        },
        {
            field: "gr_net_value",
            title: "GR Net Value",
            type: "number",
            editable: "never",
        },
        {
            field: "ir_date",
            title: "IR Date",
            type: "string",
            editable: "never",
        },
        {
            field: "ir_document_number",
            title: "IR Document Number",
            type: "string",
            editable: "never",
        },
        {
            field: "ir_line_number",
            title: "IR Line Number",
            type: "string",
            editable: "never",
        },
        {
            field: "ir_qty",
            title: "IR Quantity",
            type: "number",
            editable: "never",
        },
        {
            field: "ir_net_value",
            title: "IR Net Value",
            type: "number",
            editable: "never",
        },
        {
            field: "material_group",
            title: "Material Group",
            type: "string",
            editable: "never",
        },
        {
            field: "company_code",
            title: "Company Code",
            type: "string",
            editable: "never",
        },
        {
            field: "profit_center",
            title: "Profit Center",
            type: "string",
            editable: "never",
        },
        {
            field: "flex_attribute1",
            title: "Flex Attribute1",
            type: "string",
            editable: "never",
        },
        {
            field: "flex_attribute2",
            title: "Flex Attribute2",
            type: "string",
            editable: "never",
        }
    ];
    const [dataRows, setDataRows] = React.useState([]);
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => (<ChevronRight {...props} ref={ref} />)),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
        )),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
        )),
        ThirdStateCheck: forwardRef((props, ref) => (
            <Remove {...props} ref={ref} />
        )),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const CustomRouterLink = forwardRef((props, ref) => (
        <div ref={ref} style={{ flexGrow: 1 }}>
            <RouterLink {...props} />
        </div>
    ));
    useEffect(() => {
        var tempRows = [];
        if (props.AllPurchaseSalesDataList && props.AllPurchaseSalesDataList.records) {
            props.AllPurchaseSalesDataList.records.map((e) => {
                tempRows.push({
                    id: e.ID,
                    transaction_type: e.transaction_type,

                    supplier_number: e.supplier_number,
                    supplier_name: e.supplier_name,
                    purchase_org: e.purchase_org,
                    purchase_order: e.purchase_order,
                    purchase_order_line: e.purchase_order_line,
                    material_number: e.material_number,
                    material_description: e.material_description,
                    po_qty: e.po_qty,
                    po_net_value: e.po_net_value,
                    gr_date: e.gr_date,
                    gr_document_number: e.gr_document_number,
                    gr_line_number: e.gr_line_number,
                    gr_qty: e.gr_qty,
                    gr_net_value: e.gr_net_value,
                    ir_date: e.ir_date,
                    ir_document_number: e.ir_document_number,
                    ir_line_number: e.ir_line_number,
                    ir_qty: e.ir_qty,
                    ir_net_value: e.ir_net_value,
                    company_code: e.company_code,
                    material_group: e.material_group,
                    profit_center: e.profit_center,
                    flex_attribute1: e.flex_attribute1,
                    flex_attribute2: e.flex_attribute2
                });

            });

            setDataRows(tempRows);
        }
    }, [props.AllPurchaseSalesDataList]);
    const [currentViewID, setCurrentViewID] = React.useState(0);
    const handlePopupClick = (rowData) => {
        props.getPurchaseSalesDataDetailsById(rowData.id);
        setDialogOpen(true);
        setCurrentViewID(rowData.id);
    };
    const filterOptions = {
        type: 'Excel'
    };
    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([
                'transaction_type',
                'supplier_number',
                'supplier_name',
                'purchase_org',
                'purchase_order',
                'purchase_order_line',
                'material_number',
                'material_description',
                'po_qty',
                'po_net_value',
                'gr_date',
                'gr_document_number',
                'gr_line_number',
                'gr_qty',
                'gr_net_value',
                'ir_date',
                'ir_document_number',
                'ir_line_number',
                'ir_qty',
                'ir_net_value',
                'material_group',
                'company_code',
                'profit_center',
                'flex_attribute1',
                'flex_attribute2'

            ])

        }
    };
    return (
        <div className={clsx(classes.root, className)}>
            <DisplaySalesInvoiceDetailsPopup
                open={dialogOpen}
                onClose={(value) => setDialogOpen(value)}
                outgoingClaimID={currentViewID}
            />
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: "50px",
                        "& svg circle": {
                            stroke: "#045FB4",
                        },
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(52, 52, 52, 0)",
                    }),
                    content: (base) => ({
                        ...base,
                        color: "black",
                    }),
                }}
                text="Loading ... Please wait ..."
                className={classes.spinner}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: 22 }}> Display Purchase Data</div>
                </div>
                {props.AllPurchaseSalesDataList && props.AllPurchaseSalesDataList.records ? (
                    <GridComponent id='grid'
                        filterSettings={filterOptions}
                        allowGrouping={true}
                        allowFiltering
                        dataBound={dataBound}
                        dataSource={dataRows} height={270} toolbar={['PdfExport', 'ExcelExport', 'Search']} allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} >
                        <ColumnsDirective>
                            {columns.map(item => {
                                return <ColumnDirective
                                    headerText={item.title} field={item.field}
                                    format={item.field === 'gr_date' || item.field === 'ir_date' ? 'dd/MM/yyyy' : null}
                                    type={item.field === 'gr_date' || item.field === 'ir_date' ? 'dateTime' : null}
                                />
                            })}
                        </ColumnsDirective>
                        <Inject services={[Toolbar, PdfExport, ExcelExport, Page, Sort, Filter, Search, Group, Resize]} />
                    </GridComponent>
                    // <MaterialTable
                    //     components={{
                    //         Toolbar: (props) => (
                    //             <div
                    //                 style={{
                    //                     height: "0px",
                    //                 }}
                    //             ></div>
                    //         ),
                    //     }}
                    //     title={" "}
                    //     editable={true}
                    //     icons={tableIcons}
                    //     columns={columns}
                    //     data={dataRows}
                    //     options={{
                    //         search: false,
                    //         filtering: true,
                    //         headerStyle: {
                    //             backgroundColor: "#0B3662",
                    //             color: "white",
                    //             whiteSpace: "nowrap",
                    //             fontSize: 12,
                    //             padding: 3,
                    //             textAlign: "center",
                    //         },
                    //         cellStyle: {
                    //             fontSize: 12,
                    //             textAlign: "center",
                    //             borderRight: "1px solid #EEEEEE",
                    //         },
                    //         pageSize: 0,
                    //         pageSizeOptions: [
                    //             10,
                    //             20,
                    //             50,
                    //             { value: dataRows.length, label: "Show all" },
                    //         ],
                    //     }}
                    // />
                ) : (
                    <div>There is no data to show now.</div>
                )}
            </LoadingOverlay>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        AllPurchaseSalesDataList: state.dataSetupData.AllPurchaseSalesDataList,
        PurchaseSalesDataDetailsSingle: state.dataSetupData.PurchaseSalesDataDetailsSingle,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPurchaseSalesDataDetailsById: (id) => dispatch(getPurchaseSalesDataDetailsById(id)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayPurchaseSalesInvoiceQuery);
