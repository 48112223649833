import React, { useRef, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    OutlinedInput,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, DropdownArray, Dropdown } from '../../components/Inputs';
import SimpleDialog from '../../components/Dialog';
import { getDefaultValues, getAllPurchaseSalesDataList } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import LoadingOverlay from 'react-loading-overlay';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 5
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    calendarLeftGridContainer: {
        paddingTop: 20
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }

}));

const DisplayPurchaseInvoice = props => {
    const classes = useStyles();
    const { className } = props;

    const [poDateStart, setPODateStart] = React.useState(null);
    const [poDateEnd, setPODateEnd] = React.useState(null);
    const [grDateStart, setGRDateStart] = React.useState(null);
    const [grDateEnd, setGRDateEnd] = React.useState(null);
    const [irDateStart, setIRDateStart] = React.useState(null);
    const [irDateEnd, setIRDateEnd] = React.useState(null);
    const [asnDateStart, setASNDateStart] = React.useState(null);
    const [asnDateEnd, setASNDateEnd] = React.useState(null);
    const [transactionType, setTransactionType] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [purchaseOrder, setPurchaseOrder] = React.useState([]);
    const [purchaseOrderLine, setPurchaseOrderLine] = React.useState([]);
    const [grDocumentNumber, setGRDocumentNumber] = React.useState([]);
    const [grLineNumber, setGRLineNumber] = React.useState([]);
    const [irDocumentNumber, setIRDocumentNumber] = React.useState([]);
    const [irLineNumber, setIRLineNumber] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [companyCodeMultiple, setCompanyCodeMultiple] = React.useState([]);
    const [purchaseOrg, setPurchaseOrg] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);
    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [adrDateStart, setAdrDateStart] = React.useState(null);
    const [adrDateEnd, setAdrDateEnd] = React.useState(null);
    const [createdAt, setCreatedAt] = React.useState(null);
    const [grCreatedOn, setGrCreatedOn] = React.useState(null);
    const [irCreatedOn, setIrCreatedOn] = React.useState(null);
    const [materialDescription, setMaterialDescription] = React.useState([]);
    const [mfrPartNumber, setMfrPartNumber] = React.useState([]);
    const [poCreatedOn, setPoCreatedOn] = React.useState(null);
    const [pricingDate, setpricingDate] = React.useState(null);
    const [productHierarchy, setProductHierarchy] = React.useState([]);
    const [purchaseGroup, setPurchaseGroup] = React.useState([]);
    const [supplierCustomerNumber, setSupplierCustomerNumber] = React.useState([]);
    const [supplierGroup, setSupplierGroup] = React.useState([]);
    const [supplierName, setSupplierName] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);



    const [open, setOpen] = useState(false);
    const formData = {
        "po_date.range": (poDateStart ? Moment(poDateStart).local().format('YYYY-MM-DD') : '') + ',' + (poDateEnd ? Moment(poDateEnd).local().format('YYYY-MM-DD') : ''),
        "gr_date.range": (grDateStart ? Moment(grDateStart).local().format('YYYY-MM-DD') : '') + ',' + (grDateEnd ? Moment(grDateEnd).local().format('YYYY-MM-DD') : ''),
        "ir_date.range": (irDateStart ? Moment(irDateStart).local().format('YYYY-MM-DD') : '') + ',' + (irDateEnd ? Moment(irDateEnd).local().format('YYYY-MM-DD') : ''),
        "asn_date.range": (asnDateStart ? Moment(asnDateStart).local().format('YYYY-MM-DD') : '') + ',' + (asnDateEnd ? Moment(asnDateEnd).local().format('YYYY-MM-DD') : ''),
        "transaction_type.in": transactionType.toString(),
        "supplier_number.in": supplierNumber.toString(),
        "material_number.in": materialNumberMultiple.toString(),
        "material_group.in": materialGroupMultiple.toString(),
        "purchase_order.in": purchaseOrder.toString(),
        "purchase_order_line.in": purchaseOrderLine.toString(),
        "gr_document_number.in": grDocumentNumber.toString(),
        "gr_line_number.in": grLineNumber.toString(),
        "ir_document_number.in": irDocumentNumber.toString(),
        "ir_line_number.in": irLineNumber.toString(),
        "flex_attribute1.in": flexAttribute1.toString(),
        "flex_attribute2.in": flexAttribute2.toString(),
        "company_code.in": companyCodeMultiple.toString(),
        "purchase_org.in": purchaseOrg.toString(),
        "plant.in": plantMultiple.toString(),
        "profit_center.in": profitCenterMultiple.toString(),
        "calendar_year_month": calendarYearMonth.toString(),
        "company_code": companyCodeMultiple.toString(),
        "created_at": createdAt ? Moment(createdAt).local().format('YYYY-MM-DD') : '',
        "gr_created_on": grCreatedOn ? Moment(grCreatedOn).local().format('YYYY-MM-DD') : '',
        "ir_created_on": irCreatedOn ? Moment(irCreatedOn).local().format('YYYY-MM-DD') : '',
        "material_description": materialDescription.toString(),
        "mfr_part_number": mfrPartNumber.toString(),
        "po_created_on": poCreatedOn ? Moment(poCreatedOn).local().format('YYYY-MM-DD') : '',
        "pricing_date": pricingDate ? Moment(pricingDate).local().format('YYYY-MM-DD') : '',
        "product_hierarchy": productHierarchy.toString(),
        "purchase_group": purchaseGroup.toString(),
        "purchase_order": purchaseOrder.toString(),
        "purchase_order_line": purchaseOrderLine.toString(),
        "supplier_customer_number": supplierCustomerNumber.toString(),
        "supplier_group": supplierGroup.toString(),
        "supplier_name": supplierName.toString(),
        "supplier_number": supplierNumber.toString(),
    };
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setRadioGroupValue('');
        setPODateStart(null);
        setPODateEnd(null);
        setGRDateStart(null);
        setGRDateEnd(null);
        setIRDateStart(null);
        setIRDateEnd(null);
        setASNDateStart(null);
        setASNDateEnd(null);
        setTransactionType([]);
        setSupplierNumber([]);
        setMaterialGroupMultiple([])
        setMaterialNumberMultiple([])
        setPurchaseOrder([])
        setPurchaseOrderLine([])
        setGRDocumentNumber([])
        setGRLineNumber([])
        setIRDocumentNumber([])
        setIRLineNumber([])
        setFlexAttribute1([]);
        setFlexAttribute2([]);
        setCompanyCodeMultiple([]);
        setPurchaseOrg([]);
        setPlantMultiple([]);
        setProfitCenterMultiple([]);
        setCalendarYearMonth([]);
        setAdrDateStart(null);
        setAdrDateEnd(null);
        setCreatedAt(null);
        setGrCreatedOn(null);
        setIrCreatedOn(null);
        setMaterialDescription([]);
        setMfrPartNumber([]);
        setPoCreatedOn(null);
        setpricingDate(null);
        setProductHierarchy([]);
        setPurchaseGroup([]);
        setSupplierCustomerNumber([]);
        setSupplierGroup([]);
        setSupplierName([]);
    }
    const handleClickOpen = (arg) => {
        if (arg === 'SF') {
            sessionStorage.setItem('Syncfusion', true);
        } else {
            sessionStorage.removeItem('Syncfusion');
        }
        setOpen(true)
    };





    const handleCompanyCodeMultiple = (newValue) => {
        setCompanyCodeMultiple(newValue);
    }

    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }

    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }

    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }
    const handleSetGRDateStart = (newValue) => {
        setGRDateStart(newValue);
    }
    const handleSetGRDateEnd = (newValue) => {
        setGRDateEnd(newValue);
    }
    function handleTransactionType(newValue) {
        setTransactionType(newValue)
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber(newValue)
    }
    function handlePurchaseOrder(newValue) {
        setPurchaseOrder(newValue)
    }
    function handlePurchaseOrderLine(newValue) {
        setPurchaseOrderLine(newValue)
    }
    function handleGRDocumentNumber(newValue) {
        setGRDocumentNumber(newValue)
    }
    function handleGRLineNumber(newValue) {
        setGRLineNumber(newValue)
    }
    function handleIRDocumentNumber(newValue) {
        setIRDocumentNumber(newValue)
    }
    function handleIRLineNumber(newValue) {
        setIRLineNumber(newValue)
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue)
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue)
    }
    function handlePurchaseOrg(newValue) {
        setPurchaseOrg(newValue)
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 7 }}> Display Purchase Data </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >


                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>PO Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !poDateStart && poDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={poDateStart}
                                                onChange={date => setPODateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={poDateEnd}
                                                onChange={date => setPODateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={poDateStart ? poDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>GR Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !grDateStart && grDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={grDateStart}
                                                onChange={date => handleSetGRDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={grDateEnd}
                                                onChange={date => handleSetGRDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={grDateStart ? grDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>IR Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !irDateStart && irDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={irDateStart}
                                                onChange={date => setIRDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={irDateEnd}
                                                onChange={date => setIRDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={irDateStart ? irDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>ASN Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !asnDateStart && asnDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={asnDateStart}
                                                onChange={date => setASNDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={asnDateEnd}
                                                onChange={date => setASNDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={asnDateStart ? asnDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Transaction Type</FormLabel>
                                    <ChipInput data={transactionType} onChange={handleTransactionType} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Number</FormLabel>
                                    <ChipInput data={supplierNumber} onChange={handleSupplierNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Number</FormLabel>
                                    <ChipInput data={materialNumberMultiple} onChange={handleMaterialNumberMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Group</FormLabel>
                                    <ChipInput data={materialGroupMultiple} onChange={handleMaterialGroupMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Purchase Order</FormLabel>
                                    <ChipInput data={purchaseOrder} onChange={handlePurchaseOrder} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Purchase Order Line</FormLabel>
                                    <ChipInput data={purchaseOrderLine} onChange={handlePurchaseOrderLine} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>GR Document Number</FormLabel>
                                    <ChipInput data={grDocumentNumber} onChange={handleGRDocumentNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>GR Line Number</FormLabel>
                                    <ChipInput data={grLineNumber} onChange={handleGRLineNumber} />

                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>IR Document Number</FormLabel>
                                    <ChipInput data={irDocumentNumber} onChange={handleIRDocumentNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>IR Line Number</FormLabel>
                                    <ChipInput data={irLineNumber} onChange={handleIRLineNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 1</FormLabel>
                                    <ChipInput data={flexAttribute1} onChange={handleFlexAttribute1} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 2</FormLabel>
                                    <ChipInput data={flexAttribute2} onChange={handleFlexAttribute2} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Company Code</FormLabel>
                                    <ChipInput data={companyCodeMultiple} onChange={handleCompanyCodeMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Purchase Org</FormLabel>
                                    <ChipInput data={purchaseOrg} onChange={handlePurchaseOrg} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Plant</FormLabel>
                                    <ChipInput data={plantMultiple} onChange={handlePlantMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 10 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Profit Center</FormLabel>
                                    <ChipInput data={profitCenterMultiple} onChange={handleProfitCenterMultiple} />

                                </div>
                            </Grid>





                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {/* <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                <FormControlLabel
                                    value="Staging purchase data"
                                    control={<Radio color="primary" />}
                                    label="Delete Staging Purchase Data"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                                <FormControlLabel
                                    value="Purchase data"
                                    control={<Radio color="primary" />}
                                    label="Delete Purchase Data"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                            </RadioGroup> */}
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            {/* <Button
                            disabled={(
                                calendarYearMonth.length > 0 ||
                                 (adrDateStart!=null) || (adrDateEnd!=null) || (asnDateStart!=null) || (asnDateEnd!=null) || companyCodeMultiple.length>0
                                 || (createdAt!=null) || (grCreatedOn!=null) || (grDateStart!=null) || (grDateEnd!=null) || grDocumentNumber.length>0 ||
                                 grLineNumber.length>0 || (irCreatedOn!=null) || (irDateStart!=null) || (irDateEnd!=null) || irDocumentNumber.length>0 ||
                                 irLineNumber.length>0 || materialDescription.length>0 || materialGroupMultiple.length>0 || materialNumberMultiple.length>0 || mfrPartNumber.length>0
                                 || (poCreatedOn !=null) || (poDateStart!=null) ||(poDateEnd!=null) || (pricingDate!=null) || (productHierarchy.length>0) ||
                                 profitCenterMultiple.length>0 || purchaseGroup.length>0 || purchaseOrder.length>0 || purchaseOrderLine.length>0 ||
                                 purchaseOrg.length>0 || supplierCustomerNumber.length>0 || supplierGroup.length>0 || supplierName.length>0 ||
                                 supplierNumber.length>0 || transactionType.length>0 ||
                            ) ? false: true}
                            variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                            {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                            }
                        </Button> */}
                            <Button
                                disabled={(
                                    calendarYearMonth.length > 0 ||
                                    (adrDateStart != null) || (adrDateEnd != null) || (asnDateStart != null) || (asnDateEnd != null) || companyCodeMultiple.length > 0
                                    || (createdAt != null) || (grCreatedOn != null) || (grDateStart != null) || (grDateEnd != null) || grDocumentNumber.length > 0 ||
                                    grLineNumber.length > 0 || (irCreatedOn != null) || (irDateStart != null) || (irDateEnd != null) || irDocumentNumber.length > 0 ||
                                    irLineNumber.length > 0 || materialDescription.length > 0 || materialGroupMultiple.length > 0 || materialNumberMultiple.length > 0 || mfrPartNumber.length > 0
                                    || (poCreatedOn != null) || (poDateStart != null) || (poDateEnd != null) || (pricingDate != null) || (productHierarchy.length > 0) ||
                                    profitCenterMultiple.length > 0 || purchaseGroup.length > 0 || purchaseOrder.length > 0 || purchaseOrderLine.length > 0 ||
                                    purchaseOrg.length > 0 || supplierCustomerNumber.length > 0 || supplierGroup.length > 0 || supplierName.length > 0 ||
                                    supplierNumber.length > 0 || flexAttribute1.length > 0 || flexAttribute2.length > 0 || transactionType.length > 0
                                ) ? false : true}
                                variant="contained" color="primary" className={classes.button} onClick={() => handleClickOpen('SF')} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run with Syncfusion'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content={'Do you want to run ' + radioGroupValue + '?'} handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        AllPurchaseDataList: state.dataSetupData.AllPurchaseDataList,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(getAllPurchaseSalesDataList(formData)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DisplayPurchaseInvoice);