import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import { Edit, DeleteForever } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getTerritoryListData, createTerritory } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { Toolbar } from './components';
import secureLocalStorage from 'react-secure-storage';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));

const TerittoryTable = props => {
    useEffect(() => {
        props.getTerritoryData(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedTerritory, setSelectedTerritory] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getTerritoryData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getTerritoryData(1, event.target.value);
    };

    function navigateToview(id, type) {
        history.push({
            pathname: '/terittory/view-terittory/' + id,

        });
        sessionStorage.setItem('territory-type', type);
    }
    function editTerritory(id, type) {
        history.push({
            pathname: '/terittory/edit-terittory/' + id,

        });
        sessionStorage.setItem('territory-type', type);
    }
    const handleDeleteClick = (territory) => {
        setSelectedTerritory(territory);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        if (selectedTerritory) {
            props.createTerritory(selectedTerritory, selectedTerritory.ID);
        }
        setOpenDialog(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Territory </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_territory_master-' + sessionStorage.getItem('application')) &&
                        <Toolbar />}
                </div>
                {props.territoryData && props.territoryData.records.length > 0 ?

                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Territory Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {props.territoryData.records
                                    .filter(item => item.parent_territory_name == '')
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.ID} className={classes.hover}>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => navigateToview(item.ID, item.territory_type)}>{item.ID}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => navigateToview(item.ID, item.territory_type)}>{item.territory_name}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_territory_master-' + sessionStorage.getItem('application')) &&
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => editTerritory(item.ID, item.territory_type)}
                                                                size="large">
                                                                <Edit color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>}
                                                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_territory_master-' + sessionStorage.getItem('application')) &&
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                // onClick={() => props.createTerritory(item, item.ID)}
                                                                onClick={() => handleDeleteClick(item)}
                                                                size="large">
                                                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>}
                                                    </div>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={props.territoryData ? props.territoryData.records.length : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        />
                    </TableContainer>

                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">
                Confirm Deletion
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    Are you sure you want to delete this territory?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} variant="outlined" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} variant='contained' color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        territoryData: state.customerData.territoryList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTerritoryData: (pagination, limit) => dispatch(getTerritoryListData(pagination, limit)),
        createTerritory: (data, id) => dispatch(createTerritory(data, id, 'delete'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TerittoryTable);

