import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery,
    Breadcrumbs, Grid, Button, FormLabel
} from '@mui/material';
import { connect } from 'react-redux';
import { updatePostings } from '../../redux/actions';
import { RotateLoader, RingLoader } from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';
import { SearchDropDownMasterDataNew } from '../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: '0px 10px 0px 10px',
        marginBottom: 20
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    fabContainer: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
    },
    button: {
        marginLeft: '31rem',
        marginTop: '-3.2rem',
        marginBottom: '1rem'
    },
    spinner: {
        height: '80vh'
    },
}));
const UpdatePostings = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [contractNumber, setContractNumber] = useState([]);
    const [applicationType, setApplicationType] = React.useState('Customer Rebate');
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const handleRun = () => {
        let data = {
            'contract_number': contractNumber
        }
        props.onSubmit(data);
        handleClear();
    }
    function handleClear() {
        setContractNumber([])
    }
    function handleContractNumberAC(value) {
        if (value.length > 0) {
            setContractNumber(value);
        } else {
            setContractNumber([]);
        }
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="primary" variant='h1'>Update Postings</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container >
                    <Grid
                        item
                        md={12}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px", padding: '0.5rem', borderRadius: '5px' }}
                    >
                        <Grid
                            item
                            md={3}
                            xs={12}
                            classes={{ root: classes.gridContainer }}

                        >
                            <FormLabel
                                // required={calculationMethod || contractType ? false : true}
                                required
                                classes={{ root: classes.fontSetting }}>
                                Contract Number
                            </FormLabel>
                            <div>
                                <SearchDropDownMasterDataNew id='123'
                                    prevalue={contractNumber}
                                    multiple
                                    table='contract_header' keyName='contract_number'
                                    description={true}
                                    onChange={handleContractNumberAC}
                                    applicationType={applicationType}
                                />
                            </div>
                        </Grid>

                        <Button disabled={!contractNumber.length > 0 ? true : false}
                            variant="contained" color="primary" className={classes.button} onClick={handleRun}
                            twoline='true'>
                            {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                            }
                        </Button>
                    </Grid>
                </Grid>
            </div >
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(updatePostings(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePostings);