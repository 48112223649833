//need to check where it is used
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Breadcrumbs, Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import SimpleDialog from '../../components/Dialog';
import { useHistory, Link } from "react-router-dom";
import { getAttributesConfig, getDrivers, getAnaylsisFormula, runProfitCalcForSimulation, getGrossToNet } from '../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import { Grid, FormLabel, MenuItem, Select, Button } from '@mui/material';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import ChipInput from 'material-ui-chip-input';
import { RotateLoader, HashLoader } from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import { MultiSelectDropdown } from '../../components/Inputs';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '23px 0px 16px 0px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18,
        textTransform: 'capitalize'
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    select: {
        width: '100%',
        color: '#1675e0',
        backgroundColor: 'white',
        marginTop: 9
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 8,
        height: '2.4rem',
        padding: 0,
        marginBottom: 14
    },
    rootContainer1: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: -8,
        height: '2.4rem',
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 35,
        paddingBottom: 30,
    },
    button: {
        marginRight: 10,
        width: 80,
    },
    spinner: {
        height: '100vh'
    },

}));


const FlexibleAnalysisResult = props => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [headings, setHeadings] = React.useState([]);
    const [attribute, setAttribute] = React.useState([]);
    const [flexibleData, setFlexibleData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [calculationSimulationNumber, setCalculationSimulationNumber] = React.useState([]);
    const [tag, setTag] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [radioGroupValue, setRadioGroupValue] = React.useState('Historical - Actual');
    const [analysisType, setAnalysisType] = React.useState('driver_category');
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const head = ['start_date', 'end_date', 'simulation_category', 'formula_name', 'tableData', 'margin_price', 'margin_price_percentage', 'margin_target_percentage']

    // const priceTypeList = ['Base Price', 'On-Invoice Discount', 'On-Invoice Freight', 'On-Invoice Surcharge', 'Off-Invoice', 'Cost - Direct', 'Cost - Indirect']
    const priceTypeList = ['Base Price', 'On-Invoice Discount', 'On-Invoice Freight', 'On-Invoice Surcharge', 'Off-Invoice Revenue',
        'Off-Invoice Expense', 'Cost - Direct', 'Cost - Indirect', 'Transfer Price', 'Base Sales Adjustments',
        'Price Elasticity Adjustments', 'Cost Adjustments']

    useEffect(() => {
        props.getAttributeData(1, 0)
        props.getConfigData(1, 0)
        props.getConfigDataValue(1, 10);
        setColumns([])
        setFlexibleData([])
    }, [])

    useEffect(() => {
        var newArray = [];
        if (props.configDataValue) {
            props.configDataValue.map(e => {
                newArray.push({ 'formula': e.formula_name, 'analysis_level_id': e.analysis_level_id })
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configDataValue])
    useEffect(() => {
        var newArray = []
        var temp = []
        if (props.configData && props.configData.length > 0) {
            const sorted = props.configData.sort(function (a, b) {
                return priceTypeList.indexOf(a.driver) - priceTypeList.indexOf(b.driver);
            });
            sorted.map((e, i) => {
                if (e.driver_name)
                    newArray.push(e.driver_name)
            })
        }
        if (props.attributeData && props.attributeData.length) {
            props.attributeData.map((item, i) => {
                temp.push(item.qualifier_key)
            })
        }
        setAttributeArray(temp)
        setHeadings([...temp, 'calculation_simulation_number', 'start_date', 'end_date', ...newArray, 'final_result', 'margin_price', 'margin_price_percentage'
        ])
    }, [props.configData, props.attributeData])

    useEffect(() => {
        var attr = []
        let newArray = []
        var temp = []
        // if (props.flexibleAnalysisSimulationData && props.flexibleAnalysisSimulationData[0] && headings && headings.length > 0) {
        //     Object.keys(props.flexibleAnalysisSimulationData[0]).map(item => {
        //         newArray.push({ 'field': item, 'title': item.includes('_') ? item.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : item })
        //     })
        //     const marginTargetIndex = newArray.findIndex(item => item.field === 'Margin Target %');

        //     // If "Margin Target %" exists, move it to the end with the last columnOrder
        //     if (marginTargetIndex !== -1) {
        //         newArray[marginTargetIndex].tableData = { columnOrder: newArray.length - 1 };
        //     }
        //     attr = Object.entries(props.flexibleAnalysisSimulationData[0]).filter(([k, v]) => attribute.includes(k)).map(([k, v]) => k)
        //     var sorted = {}
        //     var sorted1 = {}
        //     sorted = props.flexibleAnalysisSimulationData.map((item, i) => {
        //         sorted1 = Object.entries(item).sort(function ([k1, v1], [k2, v2]) {
        //             return headings.indexOf(k1) - headings.indexOf(k2);
        //         })
        //             .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        //         if (sorted1['Contract Sales']) {
        //             sorted1['Contract Sales'] = Number(sorted1['Contract Sales']).toLocaleString();
        //         }
        //         if (sorted1['Short Term Discount']) {
        //             sorted1['Short Term Discount'] = Number(sorted1['Short Term Discount']).toLocaleString();
        //         }
        //         if (sorted1['Loyalty Discount']) {
        //             sorted1['Loyalty Discount'] = Number(sorted1['Loyalty Discount']).toLocaleString();
        //         }
        //         if (sorted1['Mark-Up']) {
        //             sorted1['Mark-Up'] = Number(sorted1['Mark-Up']).toLocaleString();
        //         }
        //         if (sorted1['Chargeback']) {
        //             sorted1['Chargeback'] = Number(sorted1['Chargeback']).toLocaleString();
        //         }
        //         if (sorted1['FFS']) {
        //             sorted1['FFS'] = Number(sorted1['FFS']).toLocaleString();
        //         }
        //         if (sorted1['SLP']) {
        //             sorted1['SLP'] = Number(sorted1['SLP']).toLocaleString();
        //         }
        //         if (sorted1['Prompt Pay Discount']) {
        //             sorted1['Prompt Pay Discount'] = Number(sorted1['Prompt Pay Discount']).toLocaleString();
        //         }
        //         if (sorted1['WAC']) {
        //             sorted1['WAC'] = Number(sorted1['WAC']).toLocaleString();
        //         }
        //         if (sorted1['margin_price']) {
        //             sorted1['margin_price'] = Number(sorted1['margin_price']).toLocaleString();
        //         }
        //         return sorted1;
        //     })
        //     setColumns(newArray)
        //     const sortedArray = sortByThenBy(sorted, attr)
        //     setFlexibleData(sortedArray)
        // }
        if (props.flexibleAnalysisSimulationData && props.flexibleAnalysisSimulationData.length > 0) {
            setFlexibleData(props.flexibleAnalysisSimulationData)
            attributes.map(item => {
                temp.push({
                    field: item,
                    title: <div style={{ textTransform: 'capitalize' }}>{item.replace(/_/g, ' ')}</div>,
                    editable: 'never'
                })
            })
            Object.keys(props.flexibleAnalysisSimulationData[0])
                .filter(item => !(head.includes(item)) && !(attributes.includes(item)))
                .map(item => {
                    temp.push({
                        field: item,
                        title: <div style={{ textTransform: 'capitalize' }}>{item.replace(/_/g, ' ')}</div>,
                        editable: 'never',
                        render: (rowData) => (<div>{(item == 'start_date' || item == 'end_date') ?
                            rowData[item] ? Moment.utc(rowData[item]).format('MM/DD/YYYY') : ''
                            : typeof (rowData[item]) == 'number' ? parseFloat(rowData[item]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : rowData[item]
                        }</div>)
                    })
                })
            temp.push(
                // {
                //     field: 'final_result',
                //     title: 'Final Result',
                //     editable: 'never'
                // },
                {
                    field: 'margin_price',
                    title: 'Margin Price',
                    editable: 'never'
                },
                {
                    field: 'margin_price_percentage',
                    title: 'Margin Price Percentage',
                    editable: 'never'
                },
                {
                    field: 'margin_target_percentage',
                    title: 'Margin Target Percentage',
                    editable: 'never'
                })
            setColumns(temp)
        }
        else {
            setColumns([])
            setFlexibleData([])
        }
    }, [props.flexibleAnalysisSimulationData, headings])
    function sortByThenBy(arr, props) {
        return arr.sort(function (a, b) {
            return props.reduce(function (bool, k) {
                return bool || (a[k] - b[k]);
            }, 0);
        })
    }

    function handleBillingDateStart(newValue) {
        setBillingDateStart(Moment(newValue));
    }
    function handleBillingDateEnd(newValue) {
        setBillingDateEnd(Moment(newValue));
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue);
    }
    function handleChipInput(newValue, type) {
        if (type == 'tag')
            setTag([...tag, newValue])
        else
            setCalculationSimulationNumber([...calculationSimulationNumber, newValue])
    }
    function handleChipInputDelete(newValue, type) {
        if (type == 'tag') {
            var tags = tag.filter(item => item !== newValue)
            setTag(tags)
        }
        else {
            var deletedCalculationSimulationNumber = calculationSimulationNumber.filter(item => item !== newValue)
            setCalculationSimulationNumber(deletedCalculationSimulationNumber)
        }
    }
    const handleClear = () => {
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setPriceCalcFormula([]);
        setCalculationSimulationNumber([]);
        setTag([]);
    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleDialog = (bool) => {
        if (bool) {

            const formData = {
                "formula_name": priceCalcFormula,
                'simulation_category': radioGroupValue,
                "start_date": Moment.utc(billingDateStart).local(),
                "end_date": Moment.utc(billingDateEnd).local(),
                'tags': tag,
                'calculation_simulation_number': calculationSimulationNumber ? Number(calculationSimulationNumber[0]) : '',
                'attributes': attributes
            };
            props.runProfitSimulation(formData, analysisType)
            setOpen(false);
        }
        else {
            setOpen(false);
        }

    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 18 }}>
                        Margin Simulation Report
                    </Typography>
                </div>
                <Grid container className={classes.root}>

                    <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 10 }}

                        >   <FormLabel classes={{ root: classes.fontSetting }} required >Start Date</FormLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Grid container justifyContent="space-around">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '1px 0 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                marginTop: 10,
                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                width: '100%',
                                                borderRadius: 5,
                                                color: '#1675e0'
                                            }
                                        }}
                                        value={billingDateStart}
                                        onChange={handleBillingDateStart}
                                        format="MM/DD/YYYY"
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>

                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 10 }}
                        //style={{marginTop:'-20px'}}
                        >   <FormLabel classes={{ root: classes.fontSetting }} required >End Date</FormLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Grid container justifyContent="space-around">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '1px 0 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                marginTop: 10,
                                                border: '1px solid #E0E0E0',
                                                width: '100%',
                                                borderRadius: 5,
                                                color: '#1675e0'
                                            }
                                        }}
                                        value={billingDateEnd}
                                        onChange={handleBillingDateEnd}
                                        format="MM/DD/YYYY"
                                        minDate={billingDateStart ? billingDateStart : ''}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 10 }}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                Analysis Formula
                            </FormLabel>
                            <Select
                                value={priceCalcFormula}
                                onChange={(e) => handlePriceCalFormula(e.target.value)}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                    return (
                                        <MenuItem value={item.formula} key={item.formula}>
                                            {item.formula + "-" + item.analysis_level_id}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 10 }}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                Simulation Number
                            </FormLabel>
                            <ChipInput
                                classes={{
                                    root: classes.rootContainer,
                                    chip: classes.chip,
                                    input: classes.input,
                                    inputRoot: classes.inputRoot,
                                    chipContainer: classes.chipContainer,
                                    label: classes.chipLabel
                                }}
                                value={calculationSimulationNumber}
                                onAdd={(chips) => handleChipInput(chips, 'calSimulation number')}
                                onDelete={(chip) => handleChipInputDelete(chip, 'calSimulation number')}
                                disableUnderline={true}
                                blurBehavior='add'
                            />
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 10 }}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginLeft: "4.813rem" }} className={classes.chargeBackLabel} >
                                Tags
                            </FormLabel>
                            <ChipInput
                                classes={{
                                    root: classes.rootContainer,
                                    chip: classes.chip,
                                    input: classes.input,
                                    inputRoot: classes.inputRoot,
                                    chipContainer: classes.chipContainer,
                                    label: classes.chipLabel
                                }}
                                value={tag}
                                onAdd={(chips) => handleChipInput(chips, 'tag')}
                                onDelete={(chip) => handleChipInputDelete(chip, 'tag')}
                                disableUnderline={true}
                                blurBehavior='add'
                            />
                        </Grid>
                        <Grid
                            item
                            md={1}
                            xs={12}
                            style={{ paddingRight: 10 }}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                Analysis Type
                            </FormLabel>
                            <Select
                                value={analysisType}
                                onChange={(e) => setAnalysisType(e.target.value)}
                                label='analysis Type'
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}

                            >
                                <MenuItem value={"driver_name"}>
                                    Driver Level
                                </MenuItem>
                                <MenuItem value={"driver_catergory"}>
                                    Category Level
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                Attributes</FormLabel>
                            <div style={{ marginTop: -22 }}>
                                <MultiSelectDropdown id='attribute-list' capitalize={true}
                                    listArray={attributeArray} onChange={(value => setAttributes(value))}
                                    data={attributes}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                Simulation Type
                            </FormLabel>
                            <Select
                                value={radioGroupValue}
                                onChange={(e) => handleRadioGroup(e.target.value)}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                <MenuItem value={'Historical - Actual'} >
                                    Historical - Actual
                                </MenuItem>
                                <MenuItem value={'Historical - What-If'}>
                                    Historical - What-If
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={1}
                            xs={12}
                            classes={{ root: classes.buttonContainer }}
                            justifyContent="flex-end"
                        >
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    priceCalcFormula && billingDateStart && billingDateEnd && calculationSimulationNumber.length > 0 && radioGroupValue ? false : true}
                            > {props.loading ? <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                : 'Run'
                                }
                            </Button>
                        </Grid>
                    </Grid>


                    {columns && columns.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            // .filter(column => column.field !== 'start_date' && column.field !== 'end_date' && column.field !== 'calculation_simulation_number' && column.field !== 'formula_name')
                            // .sort(function (a, b) {
                            //     return headings.indexOf(a.field) - headings.indexOf(b.field);
                            // })}
                            data={flexibleData}
                            enablePagination={false}
                            enableRowVirtualization
                            // style={{marginTop:'-18px'}}
                            options={{
                                maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                paging: false,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                            }}
                        />


                        :
                        <Typography variant='h4'>
                            No Results
                        </Typography>
                    }
                </Grid >
                <SimpleDialog open={open} content='Do you want to submit Margin Simulation ?' handleDialog={handleDialog} />
            </div >
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.profitOptimizationData.loading,
        //flexibleAnalysisSimulationData: state.profitOptimizationData.flexibleAnalysisSimulationData,
        attributeData: state.profitOptimizationData.qualifierConfigData,
        configData: state.profitOptimizationData.priceTypeData,
        configDataValue: state.profitOptimizationData.priceCalForListData,
        flexibleAnalysisSimulationData: state.profitOptimizationData.grossNetData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAttributeData: (pagination, limit) => dispatch(getAttributesConfig(pagination, limit)),
        getConfigData: (pagination, limit) => dispatch(getDrivers(pagination, limit)),
        getConfigDataValue: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        //runProfitSimulation: (formData) => dispatch(runProfitCalcForSimulation(formData)),
        runProfitSimulation: (formData, type) => dispatch(getGrossToNet(formData, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FlexibleAnalysisResult);










