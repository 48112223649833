import React, { useEffect} from 'react';
import {
    Grid,Typography,Breadcrumbs,Link,Checkbox,
    Card,Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { makeStyles,withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import {getPriceCalForListByName } from '../../../redux/actions/Pricing/AuthAction';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 10,
        padding: '1px 5px 1px 5px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 5
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding:'0px,15px',
    }

}));

const ViewPriceCalculationFormula = props => {
    const classes = useStyles(); 
   
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getPriceCalForListByName(appId);
    }, [])

     const [allData,setAllData] = React.useState([]);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

   
    useEffect(() => {
        if(props.configDataByName){
            setAllData(props.configDataByName)
        }
    }, [props.configDataByName])

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                component="button"
                onClick={()=> {history.push('/pricing-calculation-formula')}}
                >
                    Formula Procedure
                </Link>
                <Typography color="textPrimary" variant='h4'>View Formula</Typography>
            </Breadcrumbs>
            
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            
                        <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                            >
                               <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >

                                                <TableCell align='center' >Price Type Category</TableCell>
                                                <TableCell align='center' >Price Type </TableCell>
                                                <TableCell align='center' >Price Type Name </TableCell>
                                                <TableCell align='center' >Calculation Type </TableCell>
                                                <TableCell align='center' >Revenue Expense </TableCell>
                                                <TableCell align='center' >Step Total </TableCell>
                                                <TableCell align='center' >Calculate Based On </TableCell>
                                                <TableCell align='center' style={{ width: 60, maxWidth: 60 }}>Priority</TableCell>
                                                <TableCell align='center' style={{ width: 60, maxWidth: 60 }}>Display</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {allData && allData
                                                .map((itemX, index) => {
                                                    return (
                                                        <StyledTableRow >

                                                            <TableCell align='center' >{allData[index]['price_type_category']}</TableCell>
                                                            <TableCell align='center' > {allData[index]['price_type']}</TableCell>
                                                            <TableCell align='center' > {allData[index]['price_type_name']}</TableCell>
                                                            <TableCell align='center' > {allData[index]['calculation_type']}</TableCell>
                                                            <TableCell align='center' > {allData[index]['revenue_expense_indicator']}</TableCell>
                                                            <TableCell align='center' >{allData[index]['step_total']} </TableCell>
                                                            <TableCell align='center' >{allData[index]['calculate_based_on']}</TableCell>
                                                            <TableCell align='center' >{allData[index]['formula_priority']!=0?allData[index]['formula_priority']:''}</TableCell>
                                                            <TableCell align='center' >
                                                            <Checkbox
                                                                disabled
                                                                checked={allData[index]['display_enabled']}
                                                                color='primary'
                                                                classes={{
                                                                    root: classes.formControlRoot
                                                                }}
                                                                style={{ paddingTop: 10 }}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Grid>
                        </div>
                    </form>
                </Card>

                
            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getPriceCalForListByName:(name) => dispatch(getPriceCalForListByName(name))
    }
}

const mapStateToProps = state => {
    return {
        configDataByName:state.pricingData.priceListCalForDataByName,
        
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPriceCalculationFormula);