import React from 'react';
import {
    Grid, Button, Typography, FormLabel,
    Select, MenuItem, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getPriceElasticity } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from '../../../components/Inputs';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 2,
        height: 37,
        //padding: '2px 6px',
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
}));

const PriceElasticity = props => {
    const classes = useStyles();
    const { className } = props;
    const [historicalStartDate, setHistoricalStartDate] = React.useState(null);
    const [historicalEndDate, setHistoricalEndDate] = React.useState(null);
    const [evaluationStartDate, setEvaluationStartDate] = React.useState(null);
    const [evaluationEndDate, setEvaluationEndDate] = React.useState(null);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [analysisLevel, setAnalysisLevel] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const priceCalCForArray = ['material_number', 'material_group', 'product_hierarchy'];
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    function handleCustomersAdd(newValue) {
        // setCustomerNumber([...customerNumber, newValue]);
        setCustomerNumber(newValue)
    }
    function handleCustomersDelete(newValue) {
        var deletedCustomers = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomers)
    }
    function handleAnalysisLevel(newValue) {
        setAnalysisLevel(newValue)
    }
    const handleHistoricalStartDate = (newValue) => {
        setHistoricalStartDate(Moment(newValue));
    }
    const handleHistoricalEndDate = (newValue) => {
        setHistoricalEndDate(Moment(newValue));
    }
    const handleEvaluationStartDate = (newValue) => {
        setEvaluationStartDate(Moment(newValue));
    }
    const handleEvaluationEndDate = (newValue) => {
        setEvaluationEndDate(Moment(newValue));
    }
    function handleMaterialsAdd(newValue) {
        // setMaterialNumber([...materialNumber, newValue]);
        setMaterialNumber(newValue)
    }
    function handleMaterialsDelete(newValue) {
        var deletedMaterials = materialNumber.filter(item => item !== newValue)
        setMaterialNumber(deletedMaterials)
    }

    const handleClear = () => {
        setHistoricalStartDate(null);
        setHistoricalEndDate(null);
        setEvaluationStartDate(null);
        setEvaluationEndDate(null);
        setAnalysisLevel('');
        setCustomerNumber([]);
        setMaterialNumber([]);
    }
    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleDialog = (bool) => {
        const formData = {
            "analysis_level": analysisLevel,
            "historical_start_date": Moment.utc(historicalStartDate).format('MM/DD/YYYY'),
            "evaluation_start_date": Moment.utc(evaluationStartDate).format('MM/DD/YYYY'),
            "historical_end_date": Moment.utc(historicalEndDate).format('MM/DD/YYYY'),
            "evaluation_end_date": Moment.utc(evaluationEndDate).format('MM/DD/YYYY'),
            "filters": {}
        };
        var data = {}
        if (materialNumber.length > 0) {
            data['material_number'] = materialNumber
        }
        if (customerNumber.length > 0) {
            data['customer_number'] = customerNumber
        }
        if (Object.keys(data).length > 0) {
            formData['filters'] = data
        }
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h2">
                        Price Elasticity
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            ><FormLabel required>Historical Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={historicalStartDate}
                                            onChange={handleHistoricalStartDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            ><FormLabel required>Historical End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={historicalEndDate}
                                            minDate={historicalStartDate ? historicalStartDate : ''}
                                            onChange={handleHistoricalEndDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '1rem' }}
                            ><FormLabel required>Evaluation Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={evaluationStartDate}
                                            onChange={handleEvaluationStartDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '1rem' }}
                            ><FormLabel required>Evaluation End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={evaluationEndDate}
                                            minDate={evaluationStartDate ? evaluationStartDate : ''}
                                            onChange={handleEvaluationEndDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Analysis Level
                                </FormLabel>
                                <Select
                                    value={analysisLevel}
                                    onChange={(e) => handleAnalysisLevel(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                {item.replace(/_/g, ' ')}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerNumber}
                                    onAdd={(chips) => handleCustomersAdd(chips)}
                                    onDelete={(chip) => handleCustomersDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div style={{ marginTop: 10 }}>
                                    <ChipInput data={customerNumber} onAdd={handleCustomersAdd} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '-2px' }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleMaterialsAdd(chips)}
                                    onDelete={(chip) => handleMaterialsDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <ChipInput data={materialNumber} onAdd={handleMaterialsAdd} />
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    historicalStartDate && historicalEndDate && analysisLevel && evaluationStartDate && evaluationEndDate ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Price Elasticity ?' handleDialog={handleDialog} />
            </div >
        </LoadingOverlay >
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(getPriceElasticity(formData)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PriceElasticity);