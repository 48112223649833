import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../../Analytics/Charts//SplineChart";
import Moment from "moment";
import {
  geographicalIncentive,
  loadAccrualVsPaymentGraph,
} from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: "20px 30px 20px 30px",
    borderRadius: 10,
  },
  formLabel: {
    fontSize: 13, //theme.typography.h4.fontSize,
    textTransform: "capitalize",
  },
  chartHead: {
    width: "100%",
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: theme.typography.h2.fontSize,
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
}));
const AccrualPaymentClaim = (props) => {
  const classes = useStyles();
  const [avssGraphData, setAvssGraphData] = React.useState([]);

  useEffect(() => {
    props.loadAccrualVsPaymentGraph(props.data);
  }, []);
  useEffect(() => {
    if (props.accrualPaymentGraphData) {
      if (
        props.accrualPaymentGraphData &&
        ((props.accrualPaymentGraphData.accrual_result &&
          props.accrualPaymentGraphData.accrual_result !== null &&
          props.accrualPaymentGraphData.accrual_result.length > 0) ||
          (props.accrualPaymentGraphData.payment_result &&
            props.accrualPaymentGraphData.payment_result !== null &&
            props.accrualPaymentGraphData.payment_result.length > 0))
      ) {
        var data = [
          {
            data:
              props.accrualPaymentGraphData &&
                props.accrualPaymentGraphData.accrual_result
                ? props.accrualPaymentGraphData.accrual_result
                : [],
            xName: "Date",
            yName: "Total",
            legend: "Accrual",
            type: "Spline",
            shape: "Diamond",
            color: "#0000FF",
          },
          {
            data:
              props.accrualPaymentGraphData &&
                props.accrualPaymentGraphData.payment_result
                ? props.accrualPaymentGraphData.payment_result
                : [],
            xName: "Date",
            yName: "Total",
            legend: "Payment",
            type: "Spline",
            shape: "Diamond",
            color: "#CA4218",
          },
        ];
        setAvssGraphData(data);
        //setAvssGraphData(props.accrualPaymentGraphData);
      } else setAvssGraphData([]);
    } else setAvssGraphData([]);
  }, [props.accrualPaymentGraphData]);
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.chartHead}>
        Accrual v/s Payment
      </Typography>
      <SplineChart id="claim-chart" data={avssGraphData} min={props.accrualPaymentGraphData.end} max={props.accrualPaymentGraphData.start} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAccrualVsPaymentGraph: (data) =>
      dispatch(loadAccrualVsPaymentGraph(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccrualPaymentClaim);
