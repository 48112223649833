
import * as React from "react";
import { useEffect } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, BubbleSeries, Tooltip, DataLabel } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';

const pointMaterialColors = ['#00bdae', '#404041', '#357cd2', '#e56590', '#f8b883', '#70ad47', '#dd8abd', '#7f84e8', '#7bb4eb',
    '#ea7a57', '#404041', '#00bdae'];
const bubbleMaterialColors = ["rgba(0, 189, 174, 0.5)", "rgba(64, 64, 65, 0.5)", "rgba(53, 124, 210, 0.5)", "rgba(229, 101, 144, 0.5)", "rgba(248, 184, 131, 0.5)", "rgba(112, 173, 71, 0.5)", "rgba(221, 138, 189, 0.5)",
    "rgba(127, 132, 232, 0.5)", "rgba(123, 180, 235, 0.5)", "rgba(234, 122, 87, 0.5)", "rgba(64, 64, 65, 0.5)", "rgba(0, 189, 174, 0.5)"];
export let pointRender = (args) => {
    args.fill = bubbleMaterialColors[args.point.index % 10];
    args.border.color = pointMaterialColors[args.point.index % 10];
}
const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }
    ellipse[id*=_Trackball_0] {

        strokeWidth: 1 !important;
    }`;

const BubbleChart = (props) => {
    const onChartLoad = (args) => {
        let chart = document.getElementById('charts');
        chart.setAttribute('title', '');
    };
    const load = (args) => {
        let selectedTheme = 'Material';
        args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');
    };
    return (<div className='control-pane'>
        <style>{SAMPLE_CSS}</style>
        <div className='control-section' style={{ width: '100%', height: '100%' }}>
            <ChartComponent id='charts' style={{ textAlign: "center" }} useGroupingSeparator={true}
                primaryXAxis={{ valueType: 'Double', minimum: 100, title: props.xtitle, titleStyle: { color: 'purple' } }}
                load={load.bind(this)} titleStyle={{ color: 'green' }}
                primaryYAxis={{ title: props.ytitle, titleStyle: { color: 'purple' } }}
                width={'100%'} height={props.height} title={props.title} pointRender={pointRender}
                legendSettings={{ visible: false }} loaded={onChartLoad.bind(this)} tooltip={{
                    enableMarker: false, enable: true, header: "<b>Revenue Margin Analysis</b>",
                    format: "Base Price : <b>${point.x}%</b> <br/>Margin Price Percentage : <b>${point.y}</b><br/>Margin Price : <b>${point.size}</b>"
                }}>
                <Inject services={[BubbleSeries, Tooltip, DataLabel]} />
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={props.data} type='Bubble' minRadius={2} maxRadius={4} border={{ width: 2 }} xName={props.xName} yName={props.yName} size={props.size} marker={{ dataLabel: { visible: true, name: props.attribute, position: 'Middle', font: { fontWeight: '500', color: '#ffffff' } } }} />
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    </div>);
};
export default BubbleChart;