import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    TableRow,
    Button,
    Dialog,
    DialogActions,
    TextField, Grid,
    FormLabel,
    OutlinedInput
} from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { uploadSalesForecastBatchJob, uploadSalesForecastFile } from '../../redux/actions/Forecasting/AuthAction';
import PublishIcon from '@mui/icons-material/Publish';
import BeatLoader from "react-spinners/BeatLoader";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    gridContainer: {
        border: '1px solid  rgb(224, 224, 224)',
        marginTop: 20,
        marginBottom: 20,
        padding: 30,
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    paper: {
        minWidth: window.screen.width * .50
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
}));
const SalesForecastFileUpload = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)
    const listData = [{ id: 1 }]
    const [deleteFrom, setDeleteFrom] = React.useState(null);
    const [deleteTo, setDeleteTo] = React.useState(null);
    const [batchJobName, setBatchJobName] = React.useState("");
    const [open2, setOpen2] = React.useState(false);
    const inputBatchFile = useRef(null)
    const [currentBatchFile, setCurrentBatchFile] = useState(undefined);
    const [currentBatchFileName, setCurrentBatchFileName] = useState('');
    const [formatBatch, setFormatBatch] = useState('');
    const [currentBatchEditableID, setCurrentBatchEditableID] = useState('');


    useEffect(() => {
        if (props.forecastErrorList) {
            setErrorList(props.forecastErrorList)
            if (Object.entries(props.forecastErrorList).length > 0 && props.forecastErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.forecastErrorList])

    const handleDrop = (event, item) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.salesForecastDataUploadNew(data, deleteFrom ? Moment.utc(deleteFrom).format('DD-MM-YYYY') : "", deleteTo ? Moment.utc(deleteTo).format('DD-MM-YYYY') : "");
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
        setOpen(true)
        setDeleteFrom(null);
        setDeleteTo(null);
    }
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }

    const handleBatchDrop = (event, item) => {
        setCurrentBatchFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentBatchFile(data);
        event.target.value = '';
        setFormatBatch('')
        setOpen(true)
        setOpen2(true)
    }
    function runBatchQuery(item) {
        setCurrentBatchEditableID(item.id);
        inputBatchFile.current.click()
    }


    function handleDeleteFrom(e) {
        setDeleteFrom(e);
    };

    function handleDeleteTo(e) {
        setDeleteTo(e);
    };

    const handleBatchJobName = (e) => {
        setBatchJobName(e.target.value);
    }
    function handleCancelDialog() {
        setOpen2(false)
        setBatchJobName('');
        setDeleteFrom(null);
        setDeleteTo(null);
    }
    function handleBatchOnSubmit() {
        setOpen2(false)
        setBatchJobName('');
        props.uploadSalesForecastBatch(currentBatchFile, deleteFrom ? Moment.utc(deleteFrom).format('DD-MM-YYYY') : "", deleteTo ? Moment.utc(deleteTo).format('DD-MM-YYYY') : "", batchJobName);
        setCurrentBatchFile(undefined);
        setCurrentBatchFileName('');
        setDeleteFrom(null);
        setDeleteTo(null);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>Sales Forecast File Upload</Typography>
            </div>

            <div className={classes.gridContainer}>
                <Grid container md={12} xs={12} gap={2} >
                    <Grid item md={5.9} xs={12} >
                        <FormLabel>
                            Time Period
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container justifyContent="space-around">
                                <DatePicker
                                    disableToolbar
                                    clearable
                                    InputProps={{
                                        padding: 0,
                                        disableUnderline: true,
                                        style: {
                                            // padding: "1px 0 1px 11px",
                                            alignSelf: "center",
                                            alignItems: "center",
                                            border: "1px solid #E0E0E0",
                                            width: "100%",
                                            borderRadius: 5,
                                            height: 37,
                                        },
                                    }}
                                    value={deleteFrom}
                                    onChange={handleDeleteFrom}
                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                    format="MM/DD/YYYY"
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={5.9} xs={12}>
                        <FormLabel style={{ marginBottom: 29 }}>

                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container justifyContent="space-around">
                                <DatePicker
                                    disableToolbar
                                    clearable
                                    InputProps={{
                                        padding: 0,
                                        disableUnderline: true,
                                        style: {
                                            // padding: "1px 0 1px 11px",
                                            alignSelf: "center",
                                            alignItems: "center",
                                            border: "1px solid #E0E0E0",
                                            width: "100%",
                                            borderRadius: 5,
                                            height: 37,
                                        },
                                    }}
                                    value={deleteTo}
                                    onChange={handleDeleteTo}
                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                    format="MM/DD/YYYY"
                                    minDate={deleteFrom ? deleteFrom : false}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained" color="primary" className={classes.button}
                        twoline='true'>
                        {
                            props.loading ? (
                                <BeatLoader color='black' loading={props.loading} />
                            ) : (
                                listData
                                    .sort((a, b) => (a.id < b.id ? 1 : -1))
                                    .map(item => (
                                        <React.Fragment key={item.id}>
                                            <input
                                                type='file'
                                                accept=".xlsx, .xls, .csv, .zip"
                                                id='file'
                                                ref={inputFile}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleDrop(e, item)}
                                            />
                                            <IconButton
                                                classes={{ root: classes.IconButton }}
                                                onClick={() => runQuery(item)}
                                                size="large"
                                            >
                                                <PublishIcon style={{ color: 'white', fontSize: 20 }} />
                                                <span style={{ color: 'white', fontSize: 12 }}>Upload File</span>
                                            </IconButton>
                                        </React.Fragment>
                                    ))
                            )
                        }

                    </Button>
                    <Button
                        variant="contained" color="primary" className={classes.button}
                        twoline='true'>
                        {
                            props.loading ? (
                                <BeatLoader color='black' loading={props.loading} />
                            ) : (
                                listData
                                    .sort((a, b) => (a.id < b.id ? 1 : -1))
                                    .map(item => (
                                        <React.Fragment key={item.id}>
                                            <input
                                                type='file'
                                                accept=".xlsx, .xls, .csv, .zip"
                                                id='file'
                                                ref={inputBatchFile}
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleBatchDrop(e, item)}
                                            />
                                            <IconButton
                                                classes={{ root: classes.IconButton }}
                                                onClick={() => runBatchQuery(item)}
                                                size="large"
                                            >
                                                <UploadFile style={{ color: 'white', fontSize: 20 }} />
                                                <span style={{ color: 'white', fontSize: 12 }}>Create Batch Job</span>
                                            </IconButton>
                                        </React.Fragment>
                                    ))
                            )
                        }

                    </Button>
                </div>
            </div>
            <Dialog
                onClose={() => setOpen2(false)}
                aria-labelledby="simple-dialog-title"
                open={open2}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Batch Job Name
                    </FormLabel>
                    <OutlinedInput
                        value={batchJobName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleBatchJobName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleBatchOnSubmit} disabled={batchJobName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        // queryListData: state.forecastData.forecastFormatList,
        loading: state.forecastData.loading,
        forecastErrorList: state.forecastData.forecastErrorList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        salesForecastDataUploadNew: (file, s, e) => dispatch(uploadSalesForecastFile(file, s, e)),
        uploadSalesForecastBatch: (file, s, e, jobName) => dispatch(uploadSalesForecastBatchJob(file, s, e, jobName)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesForecastFileUpload);