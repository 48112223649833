import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
} from '@mui/material';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Paper } from '@mui/material';
import LoadingOverlay from "react-loading-overlay";
import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Aggregate,
    ExcelExport, Toolbar, PdfExport, Search, CommandColumn, AggregateDirective, AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, ColumnMenu, VirtualScroll
} from '@syncfusion/ej2-react-grids';
import { RingLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        //width:'100%',
        width: 'fit-content',
        overflow: 'scroll'

    },
    gridBox: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginBottom: 10,
        //overflowX: 'hidden',
        overflowY: 'scroll'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableCell: {
        paddingLeft: 18
    },
    buttonContainer: {
        display: 'flex',
    },
    button: {
        marginLeft: 15
    },
    sumContainer: {
        // backgroundColor: theme.palette.primary.main,
        // color: 'white',
        color: theme.palette.primary.main
    },
    cell: {
        backgroundColor: 'white !important',
        background: 'white !important',
        color: 'black'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    spinner: {
        // height: '100vh'
    },
}));


var enabledFormFields = [];
const DocumentManagementSearchResult = props => {
    let grid;
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const [sortedArray,setSortedArray] = React.useState([])
    const [dataWidth,setDataWidth] = React.useState([])
    useEffect(() => {
        var newArray = []
        var newArray2=[]
        if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
            props.queryResultsFinalData.map(item => {
                Object.entries(item).map(([key, value]) => {
                    if(key!=='column_order'){
                        newArray2.push(key)
                        newArray.push({ 'field': key, 'title': key.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) })
                        }
                        else {
                            setSortedArray(value)
                        }

                })
            })
            setColumns(newArray.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.title === value.title
                ))
            ))
            setDataWidth(newArray2)
            setDataRows(props.queryResultsFinalData);
            setDataCount(props.queryResultsFinalData.length)
        }
    }, [props.queryResultsFinalData]);
    const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search']
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
            grid.showSpinner();
            const exportProperties = {
                pageOrientation: 'Landscape',
                pageSize: 'A0',
                fileName: Moment.utc(new Date()).format('MM/DD/YYYY') + '.pdf',
                theme: {
                    header: {
                        bold: true,
                        fontSize: 14,
                    },
                    record: {
                        bold: false,
                        fontSize: 14,
                    },
                    caption: {
                        bold: true,
                        fontSize: 14,
                    },
                }
            };
            grid.pdfExport(exportProperties);
        }
        if (grid && args.item.id === 'grid_excelexport') {
            grid.showSpinner();
            const excelExportProperties = {
                dataSource: [...dataRows],
            };
            grid.excelExport(excelExportProperties);
        }
    };

    const pdfExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const excelExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const filterSettings = { type: 'CheckBox' };
    const groupOptions = { showGroupedColumn: true }
    const valueFormat = (field, data, column) => {
        if (data && typeof(data[field])=='number') {
            return parseFloat(data[field]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).toString()

        }
        else if (data && data[field].includes('T00:')) {
            return Moment(data[field], true).isValid()
                ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
        }
        else
            return data[field];
    };
    const dataBound = () => {
        if (grid) {
          grid.autoFitColumns(dataWidth)
    
        }
      };
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            {props.queryResultsFinalData && props.queryResultsFinalData.length > 0 ?
                <div className={clsx(classes.root, className)} >
                    <Paper className={classes.gridBox} style={{ paddingTop: 0, }}>
                    <div style={{ marginTop: 10, overflow: 'scroll' }}>
                        <GridComponent
                            dataSource={dataRows}
                            id='grid'
                            allowSorting={true} allowGrouping={true} showColumnMenu={true}
                            // allowPaging={true}
                            // pageSettings={{ pageSize: 10000 }}
                            dataBound={dataBound} 
                            groupSettings={groupOptions}
                            filterSettings={filterSettings}
                            allowFiltering={true}
                            ref={g => grid = g}
                            toolbar={toolbarOptions}
                            toolbarClick={toolbarClick}
                            allowPdfExport={true} allowExcelExport={true}
                            pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
                            height={window.screen.height * .80}
                            gridLines='Both' allowResizing={true}
                            enableVirtualization={true} enableColumnVirtualization={false}
                        >
                            <ColumnsDirective>
                                {columns
                                .sort(function(a,b){
                                    return sortedArray.indexOf(a.field) - sortedArray.indexOf(b.field);
                                })
                                .map((item, order) => {
                                    return (
                                        <ColumnDirective
                                            field={item.field}                                    
                                            headerText={item.title}
                                            width="200px" minWidth='100px' maxWidth='300px'
                                            // valueAccessor={valueFormat}
                                        />
                                    );
                                })
                                }
                            </ColumnsDirective>
                            <Inject services={[Toolbar, PdfExport, ExcelExport, Aggregate, Group, Sort, ColumnMenu, Filter, VirtualScroll, Resize, Page]} />
                        </GridComponent>
                    </div>
                </Paper>
                </div>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>

            }
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        queryResultsFinalData: state.DocumentManagement.documentSearchData,
        loading: state.DocumentManagement.loading
    }
};
export default connect(mapStateToProps, null)(DocumentManagementSearchResult);