import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Button,
    Grid,
    Breadcrumbs,
    IconButton, Fab
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline, LibraryAdd } from '@mui/icons-material';
import 'react-sortable-tree/style.css';
import SortableTree, { removeNodeAtPath, getFlatDataFromTree, changeNodeAtPath, addNodeUnderParent } from 'react-sortable-tree';
import { createTerritory } from '../../redux/actions';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: "27px 29px 27px 29px",

    },
    buttonDelete: {
        color: '#8A0829',
    },
    buttonAdd: {
        color: '#21610B',
    },
    button: {
        padding: 0
    },
    link: {

        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },

    paper: {
        minWidth: window.screen.width * .50,
        padding: "30px 30px 10px 30px"

    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30,
        width: '100%'
    },

    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        padding: 20
    },

    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    btn: {
        marginTop: '-4rem',
        marginRight: 25,
        width: 140
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: 28,
        paddingTop: 30,
        width: '100%'
    },
    nodeContainer: {
        padding: 20,
        marginTop: 30,
        width: '100%',
        overflowY: 'scroll ',
        flex: 1,
        height: '90vh',
        marginLeft: "-30px"

    },
    treeFont: {
        font: '13px',
        color: '#0D1333',
        padding: '10px',
        //height: 35,
        width: window.screen.width * .55,
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        marginBottom: '-3.75rem',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const Tree = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const [treeData, setTreeData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [territoryType, setTerritoryType] = React.useState('');
    const [valid, setValid] = React.useState(true);
    useEffect(() => {
        setOpen(true);
    }, []);
    function recursiveData() {
        console.log(treeData)

        const valid = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey,
            ignoreCollapsed: false,
        }).filter(item => !item.node.name || item.node.name === '')
        //map(item=>{console.log(item)})
        if (valid.length > 0)
            setValid(true)
        else
            setValid(false)

    }
    const handleTreeData = (data) => {
        setTreeData(data)
        recursiveData()
    }
    const handleStartCreation = () => {
        setTreeData([...treeData, { title: '' }])
    }
    const handleSubmit = () => {
        const flatData = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey,
            ignoreCollapsed: false,
        }).filter(item => item.node.name && item.node.name !== '').map((item) => (
            {
                hierarchy_level: item.treeIndex + 1,
                territory_name: item.node.name,
                parent_territory_name: item.parentNode && item.parentNode.name ? item.parentNode.name : "",
                parent_territory_id: item.parentNode && item.parentNode.id ? item.parentNode.id : "",
                territory_id: item.node.id,
                territory_type: territoryType,
                territory_details: item.node.detail
            }

        ));

        props.createTerritory(flatData);
    }
    return (
        <div>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/terittory'
                    >
                        Territory
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Create Territory</Typography>
                </Breadcrumbs>
                <Grid container>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{ height: '100vh' }}
                    >
                        {treeData.length > 0 ?
                            <div>{console.log(valid)}
                                <Button variant="contained" color="primary" style={{ float: 'right' }} className={classes.btn} disabled={valid ? true : false}
                                    onClick={handleSubmit} >
                                    Save
                                </Button>
                                <div className={classes.nodeContainer}>

                                    <SortableTree
                                        style={{ marginTop: 5 }}
                                        treeData={treeData}
                                        onChange={handleTreeData}
                                        isVirtualized={false}
                                        generateNodeProps={({ node, path }) => ({
                                            title: (
                                                <div className={classes.treeFont}>
                                                    <input
                                                        style={{ fontSize: '1.1rem', marginRight: 10 }}
                                                        value={node.name}
                                                        placeholder={'Territory Name'}
                                                        onChange={event => {
                                                            const name = event.target.value;
                                                            handleTreeData(changeNodeAtPath({
                                                                treeData: treeData,
                                                                path,
                                                                getNodeKey,
                                                                newNode: { ...node, name },
                                                            }))
                                                        }}
                                                    />
                                                    <input
                                                        style={{ fontSize: '1.1rem', marginRight: 10, width: 150 }}
                                                        placeholder={'Territory ID'}
                                                        onChange={event => {
                                                            const id = event.target.value;
                                                            handleTreeData(changeNodeAtPath({
                                                                treeData: treeData,
                                                                path,
                                                                getNodeKey,
                                                                newNode: { ...node, id },
                                                            }))
                                                        }}
                                                    />
                                                    <input
                                                        style={{ fontSize: '1.1rem', width: '320px' }}
                                                        placeholder={'Territory Details'}
                                                        onChange={event => {
                                                            const detail = event.target.value;
                                                            handleTreeData(changeNodeAtPath({
                                                                treeData: treeData,
                                                                path,
                                                                getNodeKey,
                                                                newNode: { ...node, detail },
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            ),

                                            buttons: [
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    onClick={() =>
                                                        handleTreeData(addNodeUnderParent({
                                                            treeData: treeData,
                                                            parentKey: path[path.length - 1],
                                                            expandParent: true,
                                                            getNodeKey,
                                                            newNode: {
                                                                title: '', name: ''
                                                            },
                                                        }).treeData)
                                                    }
                                                    className={classes.buttonAdd}
                                                    classes={{
                                                        root: classes.button
                                                    }}
                                                    size="large">
                                                    <AddCircleOutline />
                                                </IconButton>,
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    onClick={() =>
                                                        handleTreeData(removeNodeAtPath({
                                                            treeData: treeData,
                                                            path,
                                                            getNodeKey,
                                                        }))
                                                    }
                                                    className={classes.buttonDelete}
                                                    classes={{
                                                        root: classes.button
                                                    }}
                                                    size="large">
                                                    <RemoveCircleOutline />
                                                </IconButton>,
                                            ],
                                        })}
                                    />
                                </div>
                            </div>
                            :
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                style={{ float: 'right' }}
                                className={classes.fabContainer}
                                onClick={handleStartCreation}>

                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Start Creating Territory</Typography>
                            </Fab>
                            // <Button variant="contained" color="primary" style={{ float: 'right' }}
                            //     onClick={handleStartCreation}>
                            //     Start Creating Territory
                            // </Button>

                        }


                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createTerritory: (data) => dispatch(createTerritory(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tree);