import React, { useEffect } from 'react';
import {
    Button, Typography, Select, MenuItem,
    FormControl, FormGroup, FormControlLabel, Checkbox,
    Grid, Box, Stepper, Step, StepLabel,
    OutlinedInput,
    TextField
} from '@mui/material';
import { ArrowBackIos, Circle, OutboundOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import '../../Styles/custom.css'
import { MultiSelectDropdown } from '../../../../components/Inputs';
import { getBudgetingConfig } from '../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        height: '138vh'
        //height: '100%'
    },
    container: {
        marginTop: 28,
        display: 'flex',
        justifyContent: 'space-between'
    },
    typeContainer: {
        marginTop: 20
    }
}))

const SettingUp = (props) => {
    const classes = useStyles();
    // const steps = getSteps();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const [productLine, setProductLine] = React.useState('');
    const [promoTypesData, setPromoTypesData] = React.useState([]);
    const [promotionType, setPromotionType] = React.useState([]);
    const [modelYear, setModelYear] = React.useState('');
    const [customGroup, setCustomGroup] = React.useState('model grouping');
    const [customGroupName, setCustomGroupName] = React.useState('');
    const [customGroupValue, setCustomGroupValue] = React.useState('');
    const modelType = { 'engine': 'Engine', 'length': 'Length', 'package': 'Package', 'platform': 'Platform' }
    const stateType = { 'states': 'Listing States', 'region': 'Naming the region' }
    const mixedType = { 'state_model': 'Select state grouping per model' }
    useEffect(() => {
        props.getConfigData()
    }, [])
    useEffect(() => {
        if (props.configData) {

            setPromoTypesData(Object.keys(props.configData))
        }
    }, [props.configData]);
    useEffect(() => {
        if (props.setupData) {
            setPromotionType(props.setupData.promotion_type)
            setProductLine(props.setupData.product_line)
            setCustomGroup(props.setupData.custom_group_type)
            setCustomGroupName(props.setupData.custom_group_type_name)
            setCustomGroupValue(props.setupData.custom_group_type_value)
        }
    }, [props.setupData]);
    function handlePromoType(value) {
        if (value && value.length >= 0) {
            console.log(value)
            setPromotionType(value)
            props.onChange('promotion_type', value)
        }
    }
    function handlecustomGroup(type) {
        setCustomGroup(type)
        props.onChange('custom_group_type', type)
    }
    function handleCustomGroupValue(event) {
        setCustomGroupValue(event.target.value)
        props.onChange('custom_group_type_value', event.target.value)
    }
    function handleCustomName(event) {
        setCustomGroupValue('')
        props.onChange('custom_group_type_value', '')
        if (event.target.checked) {
            setCustomGroupName(event.target.name)
            props.onChange('custom_group_type_name', event.target.name)
        }
        else {
            setCustomGroupName('')
            props.onChange('custom_group_type_name', '')
        }
    }
    function handleProductLine(value) {
        setProductLine(value)
        props.onChange('product_line', value)
    }
    return (
        <div>
            <div>
                <Typography className='setup-label'>1/What type of grouping do you want to do?</Typography>
                <Grid item container xs={12} md={12} className={classes.container}>
                    <Grid item xs={12} md={3}>
                        <Button className='btn-custom promo-font' onClick={() => handlecustomGroup('model grouping')}>MODEL GROUP</Button>
                        {customGroup == 'model grouping' && modelType && <FormGroup className={classes.typeContainer}>
                            {Object.entries(modelType).map(([key, value]) => (
                                <Box display="flex"  >
                                    <FormControlLabel className='promo-font' style={{ color: customGroupName == key ? '#19170F' : '#4E4E4E' }}
                                        control={
                                            <Checkbox checked={customGroupName == key} name={key}
                                                onChange={(e) => handleCustomName(e)}
                                            />
                                        }
                                        label={value} />
                                    {customGroupName == key &&
                                        <TextField className='rule-type-fill promo-font' variant="standard" value={customGroupValue} onChange={handleCustomGroupValue} />}
                                </Box>
                            ))}
                        </FormGroup>
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button className='btn-custom promo-font' onClick={() => handlecustomGroup('state grouping')}>STATE GROUP</Button>
                        {customGroup == 'state grouping' && stateType &&
                            <FormGroup className={classes.typeContainer}>
                                {Object.entries(stateType).map(([key, value]) => (
                                    <Box display="flex" >
                                        <FormControlLabel className='promo-font' style={{ color: customGroupName == key ? '#19170F' : '#4E4E4E' }}
                                            control={
                                                <Checkbox checked={customGroupName == key} name={key}
                                                    onChange={(e) => handleCustomName(e)}
                                                />}
                                            label={value} />
                                        {customGroupName == key &&
                                            <TextField className='rule-type-fill promo-font' variant="standard" value={customGroupValue} onChange={handleCustomGroupValue} />}
                                    </Box>
                                ))}
                            </FormGroup>
                        }
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Button className='btn-custom promo-font' onClick={() => handlecustomGroup('both')}>COMBINATION OF MODEL AND STATE GROUP</Button>
                        {customGroup == 'both' && mixedType &&
                            <FormGroup className={classes.typeContainer}>
                                {Object.entries(mixedType).map(([key, value]) => (
                                    <Box display="flex" >
                                        <FormControlLabel className='promo-font' style={{ color: customGroupName == key ? '#19170F' : '#4E4E4E' }}
                                            control={
                                                <Checkbox checked={customGroupName == key} name={key}
                                                    onChange={(e) => handleCustomName(e)}
                                                />}
                                            label={value} />
                                        {customGroupName == key &&
                                            <TextField className='rule-type-fill promo-font' variant="standard" value={customGroupValue} onChange={handleCustomGroupValue} />}
                                    </Box>
                                ))}
                            </FormGroup>
                        }
                    </Grid>
                </Grid>
            </div>
            <div>
                <Typography className='setup-label' style={{ marginBottom: 13 }}>2/What promotion type is it applicable for? </Typography>
                <div style={{ width: 241 }}>
                    <MultiSelectDropdown onChange={handlePromoType} data={promotionType && promotionType.length > 0 ? promotionType : []} listArray={promoTypesData} />
                </div>

            </div>
            <div>
                <Typography className='setup-label' style={{ marginBottom: 28 }}>3/Pick your Product Lines you want this grouping to be applied to </Typography>
                <Select
                    disableUnderline
                    value={productLine}
                    onChange={(e) => handleProductLine(e.target.value)}
                    displayEmpty
                    //MenuProps={{ ...MenuProps, autoFocus: true }}
                    classes={{
                        selectMenu: classes.selectedItem
                    }}
                    style={{ textTransform: 'capitalize', width: 241 }}
                >
                    {props.productLines && props.productLines.map(item => (

                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                            {item}
                        </MenuItem>

                    ))

                    }

                </Select>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        // locationData: state.promotionData.locationFilters,
        configData: state.calculationAnalysisData.budgetData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig('promotionType')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingUp);