import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Typography, IconButton, Card, CardHeader, Tooltip, Radio, RadioGroup,
    FormControlLabel, FormLabel, Select, MenuItem, Input, ListItemText,
    TextField, Checkbox, OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown, MultiSelectDropdown } from '../../../components/Inputs';
import SimpleDialog from '../../../components/Dialog';
import { getOutgoingClaims, getLblDispDesFieldValue, runOutgoingClaimPostings, getDefaultValues, getDefaultValuesAllDataNew, massUpdateMembership } from '../../../redux/actions';
import AddIcon from '@mui/icons-material/Add';
import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { RotateLoader, HashLoader } from "react-spinners";
import UpdateField from './UpdateField';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        //width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    chargeBackLabel: {
        paddingTop: 15,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: 15
    },
    spinner: {
        height: '100vh'
    },
    heading: {
        padding: '10px 5px',
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    baseColor: {
        color: theme.palette.primary.main,
    }
}));



const MembershipMassUpdateForm = props => {

    const currentApplication = sessionStorage.getItem('application')
    const classes = useStyles();
    const { className } = props;
    //new fields
    const [calculationMethod, setCalculationMethod] = React.useState(currentApplication === 'Pricing' ? 'Price Difference' : '');
    const [incentiveRateType, setIncentiveRateType] = React.useState(currentApplication === 'Pricing' ? 'Contract' : '');
    const tierPeriodArray = ['Current Period', 'Previous Period', 'Previous 12 months'];
    const tierGrowthPeriodArray = ['Previous Year Same Period', 'Previous Period'];

    const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [labelNewAPIData, setLabelNewAPIData] = React.useState([]);
    const [filterData, setFilterData] = React.useState({});
    const [attributes, setAttributes] = React.useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [eligibilityData, setEligibilityData] = React.useState([]);
    const [calculationData, setCalculationData] = React.useState([]);
    const [updatedData, setUpdatedData] = React.useState({});
    const [activeCalc, setActiveCalc] = React.useState(false);
    const [activeEligibility, setActiveEligibility] = React.useState(false);
    const [activeFilter, setActiveFilter] = React.useState(false);
    const [activeUpdate, setActiveUpdate] = React.useState(false);

    useEffect(() => {
        // props.onLoadingDefault();
        var data = { "key": "membership_master", "name": "Membership Master" }
        props.onLoadingDropDown(data, 'post');
    }, []);
    useEffect(() => {
        // props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), sourceDataType)
    }, [sourceDataType])
    useEffect(() => {
        if (props.labelNewAPIData)
            setLabelNewAPIData(props.labelNewAPIData)
    }, [props.labelNewAPIData]);
    var executed = false;
    // console.log(attributeArray)
    useEffect(() => {

        if (props.tableData && props.tableData && Object.values(props.tableData.field_label_attributes).length > 0) {
            var listData = []
            //{ 'field_id': 'contract_id', 'name': 'Contract ID', 'type': 'STRING', 'drop_down_value_keys': [] }
            Object.entries(props.tableData.field_label_attributes)
                // .filter(item => item.field_id != 'key_figure_names' && item.field_id != 'attribute_names' && item.field_id !='accrual_amount' && item.field_id!='change_request_id'
                // && item.field_id!='chargeback_calculation_rules' && item.field_id!='has_active_update_request' && item.field_id!='is_update_approval_contract'&& item.field_id!='payment_amount'
                // && item.field_id!='update_contract_id' && item.field_id!='update_request_closed' && item.field_id!='target_rules'
                // )
                .map((item, i) => {
                    // console.log(item[1]['current'])
                    var name = ''
                    var data = props.labelNewAPIData && Object.entries(props.labelNewAPIData).filter(([k, v]) => k === item.field_id)
                    if (item) {
                        name = item ? item[1]['current'] : ''
                    }
                    else
                        name = item.name
                    if (item) {
                        listData.push({ 'field_id': item[0], 'name': item[1]['current'], 'type': (item[1]['current'].includes('Date') ? 'TIME' : 'STRING'), 'drop_down_value_keys': item.drop_down_value_keys })
                        // if(item.field_id==="approval_status"){
                        //     var temp = ['New','Submit for Approval','Accepted','Rejected','Changes Accepted']
                        //     listData.push({ 'field_id': item.field_id, 'name': name, 'type': item.type, 'drop_down_value_keys': temp })
                        // }
                    }

                    // if (item.field_id === 'source_data_type') {
                    //     setSourceDataTypeArray({ ...item, 'edited_name': name });
                    // }

                    // if (item.field_id === 'calculation_method') {
                    //     setCalculationMethodArray({ ...item, 'edited_name': name });
                    // }
                    // if (item.field_id === 'incentive_rate_type') {
                    //     setIncentiveRateTypeArray({ ...item, 'edited_name': name });
                    // }


                })
            setAttributeArray(listData.filter((v, i, a) => a.findIndex(v2 => (v2.name === v.name)) === i))
        }

    }, [props.tableData])
    const handleSubmit = () => {
        var formData = {
            "filters": {
                // "application_type":currentApplication,
                // "calculation_method": calculationMethod,
                // "source_data_type": sourceDataType,
                // "incentive_rate_type":incentiveRateType,
                ...filterData
            },
            "update_fields": JSON.parse(sessionStorage.getItem('updatedFields')),
            // "eligibility_rules":eligibilityData,
            // "calculation_rules": calculationData
        }
        props.onSubmit(formData)
        setFilterData({})
        // console.log(formData)
    };

    function handleSourceDataType(e) {
        setSourceDataType(e.target.value)
    }



    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === attributeArray.length ? [] : attributeArray.map((item, i) => item.field_id));
            setAllSelect(selected.length === attributeArray.length ? false : true)
            setSelectedDetail(selected.length === attributeArray.length ? [] : attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
        //setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
        //setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [level]: selected })
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        setSelectedDetail([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(attributeArray && attributeArray.length > 0 && attributes.length === attributeArray.length)
        setSelectedDetail(attributesData)
    }
    const handleCalculationMethod = (e) => {
        setIncentiveRateType('');
        setCalculationMethod(e.target.value);
        if (e.target.value !== 'Flat Rate' && e.target.value !== 'Fixed Amount') {
            setIncentiveRateType('Contract')
        }
    }
    const handleIncentiveRateType = (e) => {
        setIncentiveRateType(e.target.value)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: '15rem',
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const handleFilterData = (data, item, type) => {
        var chipData = []
        var temp = {}
        if (type == 'start_date' || type == 'end_date') {
            temp = filterData[item.field_id] ? filterData[item.field_id] : {}
            setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
        }
        if (data && data.length > 0) {
            if (type == 'chip') {
                chipData = filterData[item.field_id] ? filterData[item.field_id] : []
                chipData = [...chipData, data]
                setFilterData({ ...filterData, [item.field_id]: chipData })
            }
            else
                setFilterData({ ...filterData, [item.field_id]: data })
        }
    }
    const handleDeleteFilterData = (newValue, index, item) => {
        var deleteFilterData = filterData[item.field_id].filter(item => item !== newValue)
        setFilterData({ ...filterData, [item.field_id]: deleteFilterData })
    }
    function handleEligibilityRules(values) {

        setEligibilityData(values);
    };
    function handleCalculationRules(values) {
        setCalculationData(values);

    };
    function handleUpdateField(values) {
        setUpdatedData(values)
    }
    function handleActiveCalc() {
        if (calculationMethod && incentiveRateType)
            setActiveCalc(!activeCalc)
    }
    function handleActiveEligibility() {
        if (calculationMethod && incentiveRateType)
            setActiveEligibility(!activeEligibility)
    }
    return (
        <div
            className={clsx(classes.root, className)}>
            <div className={classes.row}>
                <Typography variant="h2"> Mass Membership Update</Typography>
            </div>
            {/* <div className={classes.container}>
                <Grid container >
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <Dropdown heading={'Application Type'} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <div className={classes.chargeBackLabel}>
                            <FormLabel required>
                                {sourceDataTypeArray.edited_name}
                            </FormLabel>
                            <Select
                                value={sourceDataType}
                                onChange={handleSourceDataType}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {sourceDataTypeArray && sourceDataTypeArray.drop_down_values && sourceDataTypeArray.drop_down_values
                                    .map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <div className={classes.chargeBackLabel} >
                            <FormLabel required>
                                {calculationMethodArray.edited_name}
                            </FormLabel>
                            <Select
                                disableUnderline
                                value={calculationMethod}
                                onChange={handleCalculationMethod}
                                displayEmpty
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true,
                                    [classes.error]: props.error
                                })}
                                disabled={calculationMethodDisable}
                            >
                                <MenuItem value="" style={{ height: 30 }}>
                                </MenuItem>
                                {calculationMethodArray && calculationMethodArray.drop_down_values && calculationMethodArray.drop_down_values
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} >
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <div className={classes.chargeBackLabel}>
                            <FormLabel required>
                                {incentiveRateTypeArray.edited_name}
                            </FormLabel>
                            <Select
                                disableUnderline
                                value={incentiveRateType}
                                onChange={handleIncentiveRateType}
                                displayEmpty
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true,
                                    [classes.error]: props.error
                                })}
                                disabled={

                                    (calculationMethod && calculationMethod !== 'Flat Rate' && calculationMethod !== 'Fixed Amount')
                                        ?
                                        true
                                        :
                                        incentiveRateTypeDisable}
                            >
                                <MenuItem value="" style={{ height: 30 }}>
                                </MenuItem>
                                {incentiveRateTypeArray && incentiveRateTypeArray.drop_down_values && incentiveRateTypeArray.drop_down_values
                                    .filter(item => !((calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference' || calculationMethod === 'Multi Axis' || calculationMethod === 'Tiered Growth' || calculationMethod === 'Pricing Calculation')
                                        && item === 'Attribute'))
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}
                                                disabled={
                                                    (calculationMethod === 'Tiered Volume' || calculationMethod === 'Price Difference')
                                                        && item === 'Attribute'
                                                        ? true :
                                                        false

                                                }
                                            >
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>

                    </Grid>
                </Grid>
            </div> */}
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<>
                            <IconButton
                                aria-label="add"
                                onClick={() => setActiveFilter(!activeFilter)}
                                size="large">
                                {/* <AddIcon color="primary" /> */}
                                <span className={classes.baseColor} style={{ fontSize: 18 }}>ADD FILTERS</span>
                            </IconButton>
                        </>
                        }
                        titleTypographyProps={{ variant: 'h3' }}
                    />

                    <div className={classes.container}>
                        <Grid container style={{ marginBottom: 20 }}>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex' }}
                            >
                                <FormLabel style={{ color: 'grey', marginRight: 20, paddingTop: 5 }}>
                                    Select Required Filters
                                </FormLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id={'filter-list'}
                                    multiple
                                    value={attributes ? attributes : []}
                                    style={{ maxHeight: "50px", width: '30%', color: 'grey' }}
                                    input={<OutlinedInput />}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={() => handleOpen()}
                                    onClose={() => setSelectOpen(false)}
                                    open={selectOpen}
                                    renderValue={(filter) => {
                                        let type = []
                                        filter.map(itemX => {
                                            attributeArray.filter(item => item.field_id == itemX).map((item, i) => {
                                                if (item.name)
                                                    type.push(item.name)
                                            })

                                        })
                                        return type.join(',')
                                    }}
                                    className={classes.multiSelect}

                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={allSelect} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {attributeArray && attributeArray.length > 0 && attributeArray
                                        .sort((a, b) => a.name > b.name ? 1 : -1)
                                        .map((item, i) => {
                                            //console.log(item)
                                            return (

                                                <MenuItem onClick={(event) => handleSelectValues(event, item.field_id, item)} value={item.field_id} key={item.field_id} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selected && selected.indexOf(item.field_id) > -1}
                                                    />
                                                    <ListItemText primary={item.name} />
                                                </MenuItem>
                                            )
                                        })}
                                    <MenuItem value='' ></MenuItem>

                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container >
                            {attributesData &&
                                attributesData.map((item, i) => {
                                    return (
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            {item.type == 'DROPDOWN.STRING' ?
                                                <MultiSelectDropdown capitalize={true} heading={item.name} listArray={item.drop_down_value_keys} data={filterData[item.field_id] ? filterData[item.field_id] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                                :
                                                item.type == 'BOOL' ?
                                                    <div style={{ paddingTop: 15 }}>
                                                        <FormLabel required>
                                                            {item.name}
                                                        </FormLabel>
                                                        <Select
                                                            value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                            onChange={(e) => handleFilterData(e.target.value, item)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value={'true'} key={'true'}>
                                                                Yes
                                                            </MenuItem>
                                                            <MenuItem value={'false'} key={'false'}>
                                                                No
                                                            </MenuItem>
                                                        </Select>
                                                    </div>
                                                    :
                                                    item.type == 'TIME' ?
                                                        <div style={{ paddingTop: 15 }}>
                                                            <FormLabel>
                                                                {item.name}
                                                            </FormLabel>
                                                            <div style={{ display: 'flex' }}>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginTop: 10,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%',
                                                                                    marginRight: 5
                                                                                }
                                                                            }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                            onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginTop: 10,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                            onChange={(e) => handleFilterData(e, item, 'end_date')}

                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{ paddingTop: 15 }}>
                                                            <FormLabel>
                                                                {item.name}
                                                            </FormLabel>
                                                            <ChipInput
                                                                classes={{
                                                                    root: classes.rootContainer,
                                                                    chip: classes.chip,
                                                                    input: classes.input,
                                                                    inputRoot: classes.inputRoot,
                                                                    chipContainer: classes.chipContainer,
                                                                    label: classes.chipLabel
                                                                }}
                                                                value={filterData[item.field_id] ? filterData[item.field_id] : []}
                                                                onAdd={(chips) => handleFilterData(chips, item, 'chip')}
                                                                onDelete={(chip, index) => handleDeleteFilterData(chip, index, item)}
                                                                disableUnderline={true}
                                                                className={clsx({
                                                                    [classes.textInput]: true,
                                                                    //[classes.error]: contractNumberMultiple.length === 0 && contractNumberRange
                                                                })}
                                                            />
                                                        </div>
                                            }
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<><IconButton
                            aria-label="settings"
                            onClick={() => setActiveUpdate(!activeUpdate)}
                            size="large">
                            {/* <AddIcon color="primary" /> */}
                            <span className={classes.baseColor} style={{ fontSize: 18 }}>ADD UPDATE FIELDS</span>
                        </IconButton></>}
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <UpdateField attributeArray={attributeArray && attributeArray.filter(item => item.field_id != 'contract_number')} onChange={handleUpdateField} />
                </form>
            </Card>
            {/* <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<>
                            <IconButton aria-label="settings" onClick={handleActiveEligibility} disabled={calculationMethod && incentiveRateType ? false : true}>
                                <AddIcon color={calculationMethod && incentiveRateType ? "primary" : "disabled"} />
                                <span style={{ color: calculationMethod && incentiveRateType ? "#445AC7" : 'grey', fontSize: 18 }}>ADD ELIGIBILITY RULES</span>

                            </IconButton>
                        </>}
                        titleTypographyProps={{ variant: 'caption' }}
                    />
                    {activeEligibility ?
                        <EligibilityRules onChange={handleEligibilityRules} calculationMethod={calculationMethod} sourceDataType={sourceDataType} />
                        : ''}
                </form>
            </Card> */}
            {/* <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title={<>
                            <IconButton aria-label="settings" onClick={handleActiveCalc} disabled={calculationMethod && incentiveRateType ? false : true}>
                                <AddIcon color={calculationMethod && incentiveRateType ? "primary" : "disabled"} />
                                <span style={{ color: calculationMethod && incentiveRateType ? "#445AC7" : 'grey', fontSize: 18 }}>ADD CALCULATION RULES</span>
                            </IconButton>
                        </>}
                        titleTypographyProps={{ variant: 'h3' }}

                    />

                    {activeCalc == true ?
                        <CalculationRules onChange={handleCalculationRules} calculationMethod={calculationMethod} incentiveRateType={incentiveRateType} sourceDataType={sourceDataType}/>
                        : ''}
                </form>
            </Card> */}
            <div className={classes.container}>
                <div className={classes.buttonContainer}>

                    <Button
                        variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                    >
                        {props.loading ?
                            <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                            :
                            'Update Membership'
                        }
                    </Button>
                </div>
            </div>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(massUpdateMembership(formData)),
        // getOutgoingClaims: () => dispatch(getOutgoingClaims()),
        // onLoadingDefault: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), null, null, null, 'Contract Designer')),
        // onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        onLoadingDropDown: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),

    }
}


const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        outgoingClaimListData: state.initialData.outgoingClaimListData,
        // tableData: state.addMultipleConfigurationData.multipleConfigurationData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipMassUpdateForm);