import React from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,

} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createUoM } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 28,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    }
}));

const AddUoM = props => {
    const classes = useStyles();

    const [sourceUom, setSourceUom] = React.useState('');
    const [targetUom, setTargetUom] = React.useState('');
    const [conversionFactor, setConversionFactor] = React.useState('');

    function handleClearAll() {
        setSourceUom('')
        setTargetUom('')
        setConversionFactor('')
    }

    function handleOnSubmit() {
        var data = [
            {
                "source_uom": sourceUom,
                "target_uom": targetUom,
                "conversion_factor": Number(conversionFactor)
            }
        ]

        props.onSubmit(data);

    }



    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/uom'
                >
                    UoM
                </Link>
                <Typography color="textPrimary" variant='h4'>Add UoM</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel variant="h3" className={classes.formDiv} required>
                                        Source UoM
                                    </FormLabel>
                                    <OutlinedInput
                                        value={sourceUom}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setSourceUom(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel variant="h3" className={classes.formDiv} required>
                                        Target UoM
                                    </FormLabel>
                                    <OutlinedInput
                                        value={targetUom}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setTargetUom(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel variant="h3" className={classes.formDiv} required>
                                        Conversion Factor
                                    </FormLabel>
                                    <OutlinedInput
                                        value={conversionFactor}
                                        type={"number"}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setConversionFactor(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card>


                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={sourceUom && targetUom && conversionFactor ? false : true}  >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createUoM(data)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUoM);