import React, { useRef, useState, useEffect } from "react";
import {
    Typography,
} from "@mui/material";
import { IconButton, List, ListItemButton, ListItemText, ListItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { getQueryRefData, updateQueryRefData } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16,
    }
}))
const DynamicQueryFormRef = (props) => {
    const classes = useStyles();
    const { className } = props;
    const [dynamicFilterList, setDynamicFilterList] = useState([]);
    const [selected, setSelected] = useState('');
    useEffect(() => {
        if (props.id)
            props.getQueryRef(props.id)
    }, [props.id])
    useEffect(() => {
        if (props.dynamicQueryFilter && props.dynamicQueryFilter.records)
            setDynamicFilterList(props.dynamicQueryFilter.records)
    }, [props.dynamicQueryFilter])
    const handleListItemClick = (item) => {
        setSelected(item.filter_name);
        props.onChange(item)
    };
    return (
        <div>
            <div className={classes.row}>
                <Typography variant="h3" color="primary">Saved Searches
                </Typography>
            </div>
            {dynamicFilterList && dynamicFilterList.length > 0 ?
                <div>
                    <List component="nav" aria-label="secondary">
                        {dynamicFilterList.map((item, i) => (
                            <ListItem
                                style={{ paddingTop: 4, paddingBottom: 1, marginBottom: -25 }}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => props.onDelete(props.id, item.id)}
                                        size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemButton
                                    key={item.id}
                                    selected={selected === item.filter_name}
                                    onClick={() => handleListItemClick(item)}
                                >
                                    <ListItemText primary={item.filter_name} />
                                </ListItemButton>
                            </ListItem>))
                        }
                    </List>
                </div>
                :
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 300,
                    fontSize: 13,
                    color: "grey",
                }}>There is no predefined filters</div>
            }
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQueryRef: (id) => dispatch(getQueryRefData(id)),
        onDelete: (queryId, id) => dispatch(updateQueryRefData('delete', queryId, id))
    };
};

const mapStateToProps = (state) => {
    return {
        dynamicQueryFilter: state.operationalReportsData.dynamicQueryFilters,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQueryFormRef);
