import React, { useEffect } from "react";
import "../../library/scss/rsuite-default.css";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Select,
  FormLabel,
  Button,
  MenuItem,
  Typography, TextField
} from "@mui/material";
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Inject, AccumulationDataLabel } from '@syncfusion/ej2-react-charts';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  loadTop25Chart,
  loadValuesBasedOnAppTypeAndField,
  allowedApps, dashboardAnalyticsNames
} from "../../redux/actions";
import { connect } from "react-redux";
import Moment from "moment";
import Chart from "react-apexcharts";
import { endOfMonth, subMonths, startOfMonth } from "date-fns";
import clsx from "clsx";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    alignItems: "center",
  },
  rootDiv: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    margin: "5px 0px 16px 0px",
  },
  rangeContainer: {
    padding: "20px 0px 20px 30px",
    display: "flex",
    flexDiretion: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  select: {
    width: "100%",
    color: "#1675e0",
  },
  selectedItem: {
    borderRadius: 5,
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 34,
    paddingBottom: 5,
  },
  button: {
    marginRight: 10,
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize,
  },
  spinner: {
    height: '100vh'
  },
}));
const ContractGroupSubgroup = (props) => {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [contractNumberData, setContractNumberData] = React.useState([]);
  const [customerNumberData, setCustomerNumberData] = React.useState([]);
  const [materialNumberData, setMaterialNumberData] = React.useState([]);
  const [supplierNumberData, setSupplierNumberData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("Accrual");
  const [aggregationField, setAggregationField] = React.useState('contract_number')
  const [limit, setLimit] = React.useState('5')
  const [dateRangeConverted, setDateRangeConverted] = React.useState("");
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  // const [dateRangeValue, setDateRangeValue] = React.useState([
  //   new Date("2020-06-01T05:30:00"),
  //   new Date("2020-10-01T05:30:00"),
  // ]);

  useEffect(() => {
    if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
      const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
        item.analytic_name === "Top Incentives by Attribute"
      );
      if (postingsSummaryAnalytic && postingsSummaryAnalytic.filters) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedAnalyticsAppsData])


  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      setStartDate(allowedTilesFilter[0].start_date);
      setEndDate(allowedTilesFilter[0].end_date);
      setAggregationField(convertToSnakeCase(allowedTilesFilter[0].groupBy));
      setSourceDataType(allowedTilesFilter[0].data_source_type)
      setPostingType(allowedTilesFilter[0].posting_type)
    }
  }, [allowedTilesFilter]);

  function convertToSnakeCase(string) {
    return string.replace(/\s+/g, '_').toLowerCase();
  }
  useEffect(() => {
    // setStartDate(Moment().subtract(1, "months").startOf("month"));
    // setEndDate(Moment().subtract(1, "months").endOf("month"));
    // setDateRangeValue([
    //   startOfMonth(subMonths(new Date(), 1)),
    //   endOfMonth(new Date()),
    // ]);
    // var date =
    //   "start_date=" +
    //   Moment().subtract(1, "months").startOf("month").format("MM/DD/YYYY") +
    //   "&end_date=" +
    //   Moment().subtract(1, "months").endOf("month").format("MM/DD/YYYY");
    if (postingType && sourceDataType && startDate && endDate && aggregationField && applicationType && limit) {
      var date =
        "start_date=" +
        Moment(startDate).format("MM/DD/YYYY") +
        "&end_date=" +
        Moment(endDate).format("MM/DD/YYYY");
      setDateRangeConverted(date);
      props.loadTop25Chart(postingType, date, applicationType, sourceDataType, aggregationField, limit);
    }
  }, [allowedTilesFilter]);

  const [sourceDataType, setSourceDataType] = React.useState(
    "Direct - Sales Data"
  );
  const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([]);
  const handleApplicationtype = (e) => {
    setSourceDataType("");
    setSourceDataTypeDataFromAPI([]);
    props.loadSourceDataType(e.target.value, "source_data_type");
    setApplicationType(e.target.value);
  };

  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
  };

  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);

  useEffect(() => {
    props.loadSourceDataType(sessionStorage.getItem("application"), "source_data_type");
    props.allowedAnalyticsApps(sessionStorage.getItem("application"));
  }, []);

  useEffect(() => {
    props.allowedApps();
  }, []);

  const [allowedApps, setAllowedApps] = React.useState([]);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);


  var op = {
    chart: {
      type: "pie",
    },
    labels: ["Apple", "Mango", "Orange", "Watermelon"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const [optionsContractNumber, setOptionsContractNumber] = React.useState({});
  const [seriesContractNumber, setSeriesContractNumber] = React.useState([]);

  const [optionsCustomerNumber, setOptionsCustomerNumber] = React.useState({});
  const [seriesCustomerNumber, setSeriesCustomerNumber] = React.useState([]);

  const [optionsMaterialNumber, setOptionsMaterialNumber] = React.useState({});
  const [seriesMaterialNumber, setSeriesMaterialNumber] = React.useState([]);

  const [optionsRegionNumber, setOptionsRegionNumber] = React.useState({});
  const [seriesRegionNumber, setSeriesRegionNumber] = React.useState([]);

  useEffect(() => {
    if (props.top25Data) {
      if (
        props.top25Data &&
        props.top25Data.length > 0
      ) {
        var label = ["x", "y"];
        var output = props.top25Data.map(function (obj) {
          return Object.keys(obj).map(function (key) {
            return obj[key];
          });
        });

        var tempL = [];
        var seriesL = [];
        props.top25Data.map((item) => {
          tempL.push(item.value);
          seriesL.push(item.incentive_amount);
        });
        setSeriesContractNumber(seriesL);

        var newArray = output.map(function (item) {
          var obj = {};
          label.forEach(function (key, index) {
            obj[key] = item[index];
            obj['text'] = obj['x'] + ' : ' + obj['y'];
          });
          return obj;
        });
        setContractNumberData(newArray);
        setOptionsContractNumber({
          chart: {
            width: "100%",
            type: "pie",
          },
          legend: {
            // position: "left",
            // horizontalAlign: "left",
            show: false,
          },
          labels: tempL,
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        });

        var tempCN = [];
        var seriesCN = [];

        props.top25Data.map((item) => {
          tempCN.push(item.value);
          seriesCN.push(item.incentive_amount);
        });
        setSeriesCustomerNumber(seriesCN);
        var newArray1 = output.map(function (item) {
          var obj = {};
          label.forEach(function (key, index) {
            obj[key] = item[index];
            obj['text'] = obj['x'] + ' : ' + obj['y'];
          });
          return obj;
        });
        setCustomerNumberData(newArray1);
        setOptionsCustomerNumber({
          chart: {
            type: "pie",
          },
          labels: tempCN,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          legend: {
            // position: "left",
            // horizontalAlign: "left",
            show: false,
          },
        });

        var tempMN = [];
        var seriesMN = [];

        props.top25Data.map((item) => {
          tempMN.push(item.value);
          seriesMN.push(item.incentive_amount);
        });
        setSeriesMaterialNumber(seriesMN);
        var newArray2 = output.map(function (item) {
          var obj = {};
          label.forEach(function (key, index) {
            obj[key] = item[index];
            obj['text'] = obj['x'] + ' : ' + obj['y'];
          });
          return obj;
        });
        setMaterialNumberData(newArray2);
        setOptionsMaterialNumber({
          chart: {
            width: "100%",
            type: "pie",
          },
          labels: tempMN,
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          legend: {
            // position: "left",
            // horizontalAlign: "left",
            show: false,
          },
        });

        var tempR = [];
        var seriesR = [];

        props.top25Data.map((item) => {
          tempR.push(item.value);
          seriesR.push(item.incentive_amount);
        });
        setSeriesRegionNumber(seriesR);
        var newArray3 = output.map(function (item) {
          var obj = {};
          label.forEach(function (key, index) {
            obj[key] = item[index];
            obj['text'] = obj['x'] + ' : ' + obj['y'];
          });
          return obj;
        });
        setSupplierNumberData(newArray3)
        setOptionsRegionNumber({
          chart: {
            width: "100%",
            type: "pie",
          },
          labels: tempR,
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          legend: {
            // position: "left",
            // horizontalAlign: "left",
            show: false,
          },
        });
      }

      // if (
      //   props.top25Data &&
      //   props.top25Data.length > 0
      // ) {
      //   var label = ["Customer Number", "Incentive Amount"];
      //   var output = props.top25Data.map(function (obj) {
      //     return Object.keys(obj).map(function (key) {
      //       return obj[key];
      //     });
      //   });
      //   const newArray = [label].concat(output);
      //   setCustomerNumberData(newArray);
      // }

      // if (
      //   props.top25Data &&
      //   props.top25Data.length > 0
      // ) {
      //   var label = ["Material Number", "Incentive Amount"];
      //   var output = props.top25Data.map(function (obj) {
      //     return Object.keys(obj).map(function (key) {
      //       return obj[key];
      //     });
      //   });
      //   const newArray = [label].concat(output);
      //   setMaterialNumberData(newArray);
      // }

      // if (props.top25Data && props.top25Data.length > 0) {
      //   var label = ["Region", "Incentive Amount"];
      //   var output = props.top25Data.map(function (obj) {
      //     return Object.keys(obj).map(function (key) {
      //       return obj[key];
      //     });
      //   });
      //   const newArray = [label].concat(output);
      //   setRegionData(newArray);
      // }
    }
  }, [props.top25Data]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleAggregationField = (e) => {
    setAggregationField(e.target.value)
  }

  const handleLimit = (e) => {
    setLimit(e.target.value)
  }

  const handleRun = () => {
    var date =
      "start_date=" +
      Moment(startDate).format("MM/DD/YYYY") +
      "&end_date=" +
      Moment(endDate).format("MM/DD/YYYY");

    if (postingType && sourceDataType && startDate && endDate && aggregationField && applicationType && limit) {
      props.loadTop25Chart(postingType, date, applicationType, sourceDataType, aggregationField, limit);
    }
  }

  const datalabel = { visible: true, name: 'text', position: 'Outside' };

  return (
    <LoadingOverlay
      active={props.loading}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div className={classes.rootDiv}>
        <Grid container className={classes.root}>
          <Grid
            container
            style={{
              margin: 10,
              padding: 5,
              border: "1px solid #E6E6E6",
              borderRadius: 5,
            }}
          >
            {/* <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography classes={{ root: classes.fontSetting }}>
                  Application Type
                </Typography>
                <Select
                  value={applicationType}
                  onChange={handleApplicationtype}
                  displayEmpty
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                >
                  {allowedApps
                    .filter(
                      (item) =>
                        item !== "Customer Chargeback" &&
                        item !== "Promotions" &&
                        item !== "Supplier Chargeback" &&
                        item !== "Pricing" &&
                        item !== "Profit Optimization"
                    )
                    .map((item, index) => {
                      return (
                        <MenuItem
                          value={item}
                          key={index}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </Grid> */}
            <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography classes={{ root: classes.fontSetting }}>
                  Source Data Type
                </Typography>
                <Select
                  value={sourceDataType}
                  onChange={handleSourceDataType}
                  displayEmpty
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                >
                  {sourceDataTypeDataFromAPI.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Grid>

            <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography classes={{ root: classes.fontSetting }}>
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          // padding: "1px 0 1px 11px",
                          alignSelf: "center",
                          alignItems: "center",
                          border: "1px solid #E0E0E0",
                          width: "100%",
                          borderRadius: 5,
                          height: 37,
                          color: "#1675e0",
                        },
                      }}
                      value={startDate}
                      onChange={handleStartDate}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography classes={{ root: classes.fontSetting }}>
                  End Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          // padding: "1px 0 1px 11px",
                          alignSelf: "center",
                          alignItems: "center",
                          border: "1px solid #E0E0E0",
                          width: "100%",
                          borderRadius: 5,
                          height: 37,
                          color: "#1675e0",
                        },
                      }}
                      value={endDate}
                      onChange={handleEndDate}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                      minDate={startDate ? startDate : false}
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Posting Type
              </Typography>
              <Select
                value={postingType}
                onChange={handlePostingType}
                className={clsx({
                  [classes.select]: true,
                })}
                classes={{
                  selectMenu: classes.selectedItem,
                }}
              >
                <MenuItem value={"accrual"}>Accrual</MenuItem>
                <MenuItem value={"payment"}>Payment</MenuItem>
              </Select>
            </Grid>
            <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Aggregation Field
              </Typography>
              <Select
                value={aggregationField}
                onChange={handleAggregationField}
                className={clsx({
                  [classes.select]: true
                })}
                classes={{
                  selectMenu: classes.selectedItem
                }}
              >
                <MenuItem value={'contract_number'} >
                  Contract Number
                </MenuItem>
                <MenuItem value={'customer_number'}>
                  Customer Number
                </MenuItem>
                <MenuItem value={'material_number'} >
                  Material Number
                </MenuItem>
                <MenuItem value={'supplier_number'}>
                  Supplier Number
                </MenuItem>
              </Select>
            </Grid>
            <Grid item md={1} xs={12} style={{ paddingRight: 15 }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Limit
              </Typography>
              <Select
                value={limit}
                onChange={handleLimit}
                className={clsx({
                  [classes.select]: true
                })}
                classes={{
                  selectMenu: classes.selectedItem
                }}
              >
                <MenuItem value={'5'} >
                  5
                </MenuItem>
                <MenuItem value={'10'}>
                  10
                </MenuItem>
                <MenuItem value={'25'} >
                  25
                </MenuItem>
              </Select>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleRun}
                twoline="true"
                disabled={(!startDate || !endDate || !aggregationField || !postingType || !sourceDataType || !applicationType || !limit) ? true : false}

              >
                <DirectionsRunIcon />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.root}>
          {aggregationField === 'contract_number' && <Grid
            item
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                // paddingLeft: "15%" 
              }}
              color="primary"
            >
              Contract Number
            </Typography>
            {props.top25Data &&
              props.top25Data &&
              props.top25Data.length > 0 ? (
              <AccumulationChartComponent id='charts' height='550' tooltip={{ enable: true, header: "Contract Number" }} enableSmartLabels='true'>
                <Inject services={[AccumulationDataLabel, AccumulationTooltip]} />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective dataSource={contractNumberData} xName='x' yName='y' dataLabel={datalabel}></AccumulationSeriesDirective>
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 209,
                }}
              >
                No data
              </div>
            )}
          </Grid>}

          {aggregationField === 'customer_number' && <Grid
            item
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h3"
              style={{ textAlign: "center" }}
              color="primary"
            >
              Customer Number
            </Typography>
            {props.top25Data &&
              props.top25Data &&
              props.top25Data.length > 0 ? (
              <AccumulationChartComponent id='charts' height='550' tooltip={{ enable: true, header: "Customer Number" }} enableSmartLabels='true'>
                <Inject services={[AccumulationDataLabel, AccumulationTooltip]} />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective dataSource={customerNumberData} xName='x' yName='y' dataLabel={datalabel}></AccumulationSeriesDirective>
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 209,
                }}
              >
                No data
              </div>
            )}
          </Grid>}

          {aggregationField === 'material_number' && <Grid
            item
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h3"
              style={{ textAlign: "center" }}
              color="primary"
            >
              Material Number
            </Typography>
            {props.top25Data &&
              props.top25Data &&
              props.top25Data.length > 0 ? (
              <AccumulationChartComponent id='charts' height='550' tooltip={{ enable: true, header: "Material Number" }} enableSmartLabels='true'>
                <Inject services={[AccumulationDataLabel, AccumulationTooltip]} />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective dataSource={materialNumberData} xName='x' yName='y' dataLabel={datalabel}></AccumulationSeriesDirective>
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 209,
                }}
              >
                No data
              </div>
            )}
          </Grid>}

          {aggregationField === 'supplier_number' && <Grid
            item
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h3"
              style={{ textAlign: "center" }}
              color="primary"
            >
              Supplier Number
            </Typography>
            {props.top25Data &&
              props.top25Data &&
              props.top25Data.length > 0 ? (
              <AccumulationChartComponent id='charts' height='550' tooltip={{ enable: true, header: "Supplier Number" }} enableSmartLabels='true'>
                <Inject services={[AccumulationDataLabel, AccumulationTooltip]} />
                <AccumulationSeriesCollectionDirective>
                  <AccumulationSeriesDirective dataSource={supplierNumberData} xName='x' yName='y' dataLabel={datalabel}></AccumulationSeriesDirective>
                </AccumulationSeriesCollectionDirective>
              </AccumulationChartComponent>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 209,
                }}
              >
                No data
              </div>
            )}
          </Grid>}
        </Grid>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.initialData.loading,
    top25Data: state.initialData.top25,
    sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    allowedAppsData: state.initialData.allowedApps,
    allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTop25Chart: (type, date, applicationType, sourceDataType, aggregationField, limit) => dispatch(loadTop25Chart(type, date, applicationType, sourceDataType, aggregationField, limit)),
    loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    allowedApps: () => dispatch(allowedApps()),
    allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractGroupSubgroup);
