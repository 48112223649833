import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject,
    DataLabel, BarSeries, Category, Legend, Tooltip, ILoadedEventArgs, ChartTheme, Highlight
} from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';

const MarginPriceDiffChart = (props) => {
    const [topPerformers, setTopPerformers] = React.useState([]);
    const [bottomPerformers, setBottomPerformers] = React.useState([]);
    // const [forecastData, setForecastData] = React.useState([]);
    console.log(props);
   const size = { width: '100%', height: '100%' }
    useEffect(() => {
        var top=[]
        var bottom=[]
        if(props.data && props.data.length>0){
            props.data.map((item,i)=>{
    
                top.push({x:item.margin_price_percentage+" "+item[props.analysisLevel],y:item.margin_price_difference,color:'#449724'})
                //bottom.push({x:item.category+" "+item[props.analysisLevel],y:item.quantity_percentage,color:'#F3993E'});
            //    forecast.push({x:item[props.analysisLevel],y:item.margin_forecast_percentage,color:'#F3993E'})
            })
           setTopPerformers(top)
           setBottomPerformers(bottom)
           //setForecastData(forecast)
        }
    }, [props.data]);
    console.log(topPerformers)
    console.log(bottomPerformers)
    return (
        <div style={{width:'100%',height:'100%',padding:0}}>
            <ChartComponent  style={{ textAlign: "center" }} legendSettings={{ enableHighlight: true }}
                primaryXAxis={{
                    valueType: 'Category',
                    majorGridLines: { width: 0 },
                }}
                primaryYAxis={{
                    labelFormat: '{value}%',
                    edgeLabelPlacement: 'Shift',
                    majorTickLines: { width: 0 },
                    lineStyle: { width: 0 },
                }}
                //size = {size}
                chartArea={{ border: { width: 0 } }}
               width={Browser.isDevice ? '100%' : props.height?props.height:'85%'}
               height='500px'
               tooltip={{ enable: true }}
                >
                <Inject services={[BarSeries, DataLabel, Category, Legend, Tooltip, Highlight]} />
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={topPerformers} xName='x' yName='y' type='Bar' columnSpacing={0.1} name="Margin Price Difference" width={2} pointColorMapping='color'
                    >
                    </SeriesDirective>
                    {/* <SeriesDirective dataSource={bottomPerformers} xName='x' yName='y' type='Bar' columnSpacing={0.1} name="Bottom 10 Performer" width={2} pointColorMapping='color'
                    >
                    </SeriesDirective> */}
                </SeriesCollectionDirective>
            </ChartComponent>
        </div >
    );
}

export default connect(null, null)(MarginPriceDiffChart);