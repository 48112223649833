import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem,
    TextField,
    Button
} from '@mui/material';
import SubmitContainer from '../../../components/Containers/SubmitContainer';
import { runTargetReports, getDefaultValues, getDefaultValuesTargetData, getDefaultValuesAllData } from '../../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { RotateLoader, RingLoader } from "react-spinners";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput, SearchDropDownMasterDataNew } from "../../../components/Inputs";
import { toast } from 'react-toastify';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10
    },
    container: {
        paddingTop: 18
    },
    leftGridContainer: {
        paddingRight: 10
    },
    calendarLeftGridContainer: {
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        padding: '10px 45px 0px 45px'
    },
    rightGridContainer: {
        paddingLeft: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        //marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    dropdownSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 15
    },
    multiSelect: {
        marginTop: 10
    },
    textInput: {
        // marginTop: 10
        height: 35
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 36,
        padding: 0,
        marginBottom: 14,
    },
    textInputHeight: {
        height: 25
    },
    textField: {
        width: '231px !important'
    },
    error: {
        border: '1px solid red !important'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const TargetReportsTab = props => {
    const classes = useStyles();
    const { className } = props;
    const contractCustomisationUpdation = useRef();
    const [applicationTypeArray, setApplicationTypeArray] = React.useState([]);
    const [calculationSchemaArray, setCalculationSchemaArray] = React.useState([]);
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [billingTypeArray, setBillingTypeArray] = React.useState([]);
    const [billingDateArray, setBillingDateArray] = React.useState([]);
    const [calculationSimulationNumberArray, setCalculationSimulationNumberArray] = React.useState([]);
    const [billingDocNumberArray, setBillingDocNumberArray] = React.useState([]);
    const [billingDocLineArray, setBillingDocLineArray] = React.useState([]);
    const [contractNumberArray, setContractNumberArray] = React.useState([]);
    const [startDateArray, setStartDateArray] = React.useState([]);
    const [endDateArray, setEndDateArray] = React.useState([]);
    const [itemCategoryArray, setItemCategoryArray] = React.useState([]);
    const [companyCodeArray, setCompanyCodeArray] = React.useState([]);
    const [salesOrgArray, setSalesOrgArray] = React.useState([]);
    const [distributionChannelArray, setDistributionChannelArray] = React.useState([]);
    const [divisionArray, setDivisionArray] = React.useState([]);
    const [fiscalYearMonthArray, setFiscalYearMonthArray] = React.useState([]);
    const [calendarYearMonthArray, setCalendarYearMonthArray] = React.useState([]);
    const [soldToPartyArray, setSoldToPartyArray] = React.useState([]);
    const [customerChainArray, setCustomerChainArray] = React.useState([]);
    const [customerGroupArray, setCustomerGroupArray] = React.useState([]);
    const [vendorGroupArray, setVendorGroupArray] = React.useState([]);
    const [vendorArray, setVendorArray] = React.useState([]);
    const [materialNumberArray, setMaterialNumberArray] = React.useState([]);
    const [materialGroupArray, setMaterialGroupArray] = React.useState([]);
    const [materialTypeArray, setMaterialTypeArray] = React.useState([]);
    const [productHierarchyArray, setProductHierarchyArray] = React.useState([]);
    const [salesOrderTypeArray, setSalesOrderTypeArray] = React.useState([]);
    const [profitCenterArray, setProfitCenterArray] = React.useState([]);
    const [plantArray, setPlantArray] = React.useState([]);
    const [customerNumberArray, setCustomerNumberArray] = React.useState([]);

    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'application_type') {
                            setApplicationTypeArray(item);
                        }
                        if (item.field_id === 'calculation_schema') {
                            setCalculationSchemaArray(item);
                        }
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'billing_type') {
                            setBillingTypeArray(item);
                        }
                        //new
                        if (item.field_id === 'calculation_simulation_number') {
                            setCalculationSimulationNumberArray(item);
                        }
                        if (item.field_id === 'billing_date') {
                            setBillingDateArray(item);
                        }
                        if (item.field_id === 'billing_doc_number') {
                            setBillingDocNumberArray(item);
                        }
                        if (item.field_id === 'billing_doc_line') {
                            setBillingDocLineArray(item);
                        }
                        if (item.field_id === 'contract_number') {
                            setContractNumberArray(item);
                        }
                        if (item.field_id === 'calculation_schema') {
                            setCalculationSchemaArray(item);
                        }
                        // if (item.field_id === 'contract_type') {
                        //     setContractTypeArray(item);
                        // }
                        if (item.field_id === 'start_date') {
                            setStartDateArray(item);
                        }
                        if (item.field_id === 'end_date') {
                            setEndDateArray(item);
                        }
                        if (item.field_id === 'item_category') {
                            setItemCategoryArray(item);
                        }
                        if (item.field_id === 'company_code') {
                            setCompanyCodeArray(item);
                        }
                        if (item.field_id === 'sales_org') {
                            setSalesOrgArray(item);
                        }
                        if (item.field_id === 'distribution_channel') {
                            setDistributionChannelArray(item);
                        }
                        if (item.field_id === 'division') {
                            setDivisionArray(item);
                        }
                        if (item.field_id === 'fiscal_year_month') {
                            setFiscalYearMonthArray(item);
                        }
                        if (item.field_id === 'calendar_year_month') {
                            setCalendarYearMonthArray(item);
                        }
                        if (item.field_id === 'sold_to_party') {
                            setSoldToPartyArray(item);
                        }
                        if (item.field_id === 'customer_chain') {
                            // console.log(item)
                            setCustomerChainArray(item);
                        }
                        if (item.field_id === 'customer_group') {
                            setCustomerGroupArray(item);
                        }
                        if (item.field_id === 'supplier_group') {
                            setVendorGroupArray(item);
                        }
                        if (item.field_id === 'supplier_number') {
                            setVendorArray(item);
                        }
                        if (item.field_id === 'material_number') {
                            setMaterialNumberArray(item);
                        }
                        if (item.field_id === 'material_group') {
                            setMaterialGroupArray(item);
                        }
                        if (item.field_id === 'material_type') {
                            setMaterialTypeArray(item);
                        }
                        if (item.field_id === 'product_hierarchy') {
                            setProductHierarchyArray(item);
                        }
                        if (item.field_id === 'sales_order_type') {
                            setSalesOrderTypeArray(item);
                        }
                        if (item.field_id === 'profit_center') {
                            setProfitCenterArray(item);
                        }
                        if (item.field_id === 'plant') {
                            setPlantArray(item);
                        }
                    })
                }
            }
        }
    });
    const [flexAttribute1Array, setFlexAttribute1Array] = React.useState([]);
    const [flexAttribute2Array, setFlexAttribute2Array] = React.useState([]);
    const [netValueArray, setNetValueArray] = React.useState([]);
    const [incentiveBasis1Array, setIncentiveBasis1Array] = React.useState([]);
    const [flexKeyFigure1Array, setFlexKeyFigure1Array] = React.useState([]);
    const [flexKeyFigure2Array, setFlexKeyFigure2Array] = React.useState([]);
    const [billingDocumentCreatedDateArray, setBillingDocumentCreatedDateArray] = React.useState([]);
    const [billingDocumentCreatedTimeArray, setBillingDocumentCreatedTimeArray] = React.useState([]);
    const [billingDocumentExtractedTimeArray, setBillingDocumentExtractedTimeArray] = React.useState([]);
    const [billingDocumentExtractedDateArray, setBillingDocumentExtractedDateArray] = React.useState([]);


    useEffect(() => {
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {
            props.formFieldsAllData.records.map((item) => {
                if (item.field_id === 'flex_attribute1') {
                    setFlexAttribute1Array(item)
                }
                if (item.field_id === 'flex_attribute2') {
                    setFlexAttribute2Array(item)
                }
                if (item.field_id === 'net_value') {
                    setNetValueArray(item)
                }
                if (item.field_id === 'incentive_basis1') {
                    setIncentiveBasis1Array(item)
                }
                if (item.field_id === 'flex_key_figure1') {
                    setFlexKeyFigure1Array(item)
                }
                if (item.field_id === 'billing_document_created_date') {
                    setBillingDocumentCreatedDateArray(item);
                }
                if (item.field_id === 'billing_document_created_time') {
                    setBillingDocumentCreatedTimeArray(item);
                }
                if (item.field_id === 'billing_document_extracted_time') {
                    setBillingDocumentExtractedTimeArray(item);
                }
                if (item.field_id === 'billing_document_extracted_date') {
                    setBillingDocumentExtractedDateArray(item);

                }


            })

        }

    }, [props.formFieldsAllData])

    const [calculationSimulationNumberMultiple, setCalculationSimulationNumberMultiple] = React.useState([]);
    const [calculationSimulationNumberRange, setCalculationSimulationNumberRange] = React.useState('');
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [contractNumberMultiple, setContractNumberMultiple] = React.useState([]);
    const [contractNumberRange, setContractNumberRange] = React.useState('');
    // const [applicationType, setApplicationType] = React.useState(localStorage.getItem('application'));
    const [contractType, setContractType] = React.useState('');
    const [contractSubType, setContractSubType] = React.useState([]);
    const [calculationStartDate, setCalculationStartDate] = React.useState(null);
    const [calculationEndDate, setCalculationEndDate] = React.useState(null);
    const [billingType, setBillingType] = React.useState([]);
    const [itemCategory, setItemCategory] = React.useState([]);
    const [companyCode, setCompanyCode] = React.useState([]);
    const [salesOrg, setSalesOrg] = React.useState([]);
    const [distributionChannel, setDistributionChannel] = React.useState([]);
    const [division, setDivision] = React.useState([]);
    const [fiscalYearMonth, setFiscalYearMonth] = React.useState(null);
    const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [soldToPartyMultiple, setSoldToPartyMultiple] = React.useState([]);
    const [customerChain, setCustomerChain] = React.useState([]);
    const [customerGroup, setCustomerGroup] = React.useState([]);
    const [calculationSimulationCreatedBy, setCalculationSimulationCreatedBy] = React.useState('');
    const [calculationSimulationCreatedDate, setCalculationSimulationCreatedDate] = React.useState(null);
    const [calculationSimulationCreatedTime, setCalculationSimulationCreatedTime] = React.useState(null);
    const handleCalculationSimulationNumberMultiple = (newValue) => {
        setCalculationSimulationNumberMultiple(newValue);
    };
    const handleContractNumberMultiple = (newValue) => {
        setContractNumberMultiple(newValue);
        if (contractNumberMultiple.length > 0) {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(true);
            setContractNumberRange('');
        } else {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(false);
        }
    };
    const handleDeleteContractNumberMultiple = (value, index) => {
        var deletedContractNumber = contractNumberMultiple.filter(item => item !== value)
        setContractNumberMultiple(deletedContractNumber)
        if (contractNumberMultiple.length === 2)
            setDisableContractNumberRange(false);
    }
    const handleContractNumberRange = (event) => {
        setContractNumberRange(event.target.value);
        if (event.target.value) {
            setContractNumberKey('contract_number.range');
        } else {
            setContractNumberKey('contract_number.in');
        }
    }
    // const handleApplicationtype = (event) => {
    //     setApplicationType(event.target.value);
    // }
    const handleContractType = (event) => {
        setContractType(event.target.value);
    }
    const handleContractSubType = (event) => {
        setContractSubType(event.target.value);
    }
    /* const handleBillingType = (event) => {
         setBillingType(event.target.value);
     }*/
    const handleItemCategory = (event) => {
        setItemCategory(event.target.value);
    }
    const handleCompanyCode = (newValue) => {
        setCompanyCode(newValue);
    }
    const handleSalesOrg = (newValue) => {
        setSalesOrg(newValue);
    }
    const handleDistributionChannel = (newValue) => {
        setDistributionChannel(newValue);
    }
    const handleDivision = (newValue) => {
        setDivision(newValue);
    }
    const handleSoldToPartyMultiple = (newValue) => {
        setSoldToPartyMultiple(newValue);
    }
    const handleCustomerChain = (newValue) => {
        setCustomerChain(newValue);
    }
    const handleCustomerGroup = (newValue) => {
        setCustomerGroup(newValue);
    }
    const handleCalculationSimulationCreatedBy = (newValue) => {
        setCalculationSimulationCreatedBy(newValue);
    }
    const [billingDocNumberMultiple, setBillingDocNumberMultiple] = React.useState([]);
    const [billingDocLineMultiple, setBillingDocLineMultiple] = React.useState([]);
    const [vendorMultiple, setVendorMultiple] = React.useState([]);
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [salesOrderTypeMultiple, setSalesOrderTypeMultiple] = React.useState([]);
    const [vendorGroupMultiple, setVendorGroupMultiple] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [materialTypeMultiple, setMaterialTypeMultiple] = React.useState([]);
    const [productHierarchyMultiple, setProductHierarchyMultiple] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);

    const [soldToParty, setSoldToParty] = React.useState([]);
    const [shipToParty, setShipToParty] = React.useState([]);
    const [billToParty, setBillToParty] = React.useState([]);
    const [payer, setPayer] = React.useState([]);


    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    const [contractNumberKey, setContractNumberKey] = React.useState('');
    const [disableContractNumberRange, setDisableContractNumberRange] = React.useState(false);
    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');
    useEffect(() => {
        // props.onLoadingDefault();
        // props.onLoadingDefaultDropdown();
        props.getDefaultValuesAllData();
    }, []);
    const handleMultipleBillingDocLine = (newValue) => {
        setBillingDocLineMultiple(newValue);
    };
    const handleMultipleBillingDocNumber = (newValue) => {
        setBillingDocNumberMultiple(newValue);
    };
    const handleVendorMultiple = (newValue) => {
        setVendorMultiple(newValue);
    }
    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }
    const handleSalesOrderTypeMultiple = (newValue) => {
        setSalesOrderTypeMultiple(newValue);
    }
    const handleVendorGroupMultiple = (newValue) => {
        setVendorGroupMultiple(newValue);
    }
    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }
    const handleMaterialTypeMultiple = (newValue) => {
        setMaterialTypeMultiple(newValue);
    }
    const handleProductHierarchyMultiple = (newValue) => {
        setProductHierarchyMultiple(newValue);
    }
    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleSoldToParty = (newValue) => {
        setSoldToParty(newValue);
    }
    const handleShipToParty = (newValue) => {
        setShipToParty(newValue);
    }
    const handleBillToParty = (newValue) => {
        setBillToParty(newValue);
    }
    const handlePayer = (newValue) => {
        setPayer(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const options = [
        "option 1",
        "option 2",
        "option 3"
    ];
    const applicationTypeData = [
        "Customer Rebate",
        "Supplier Rebate",
        "Sales Commission",
        "Royalty'"
    ];
    const contractTypeData = [
        'Flat Rate',
        'Flat Amount',
        'Volume Tier',
        'Growth Tier'
    ];
    const contractSubtypeData = [
        'Fee for Service',
        'Compliance',
        'Marketing Development Fund',
        'Channel Performance',
        'Price Protection'
    ];

    //new fields
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateEnd, setPricingDateEnd] = React.useState(null);
    const [pricingDateRangeKey, setPricingDateRangeKey] = React.useState('pricing_date');
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [targetType, setTargetType] = React.useState([]);
    // const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [materialDescription, setMaterialDescription] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [supplierName, setSupplierName] = React.useState([]);
    const [supplierGroup, setSupplierGroup] = React.useState([]);
    const [supplierCustomerNumber, setSupplierCustomerNumber] = React.useState([]);
    const [employeeNumber, setEmployeeNumber] = React.useState([]);
    const [employeeName, setEmployeeName] = React.useState([]);
    const [terittoryID, setTerittoryID] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [salesOffice, setSalesOffice] = React.useState([]);
    const [salesGroup, setSalesGroup] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [netValue, setNetValue] = React.useState([]);
    const [incentiveBasis1, setIncentiveBasis1] = React.useState([]);
    const [incentiveRate, setIncentiveRate] = React.useState([]);
    const [incentiveAmount, setIncentiveAmount] = React.useState([]);
    const [flexKeyFigure1, setFlexKeyFigure1] = React.useState([]);
    const [flexKeyFigure2, setFlexKeyFigure2] = React.useState([]);
    const [salesDistrict, setSalesDistrict] = React.useState([]);
    const [orderReason, setOrderReason] = React.useState([]);
    const [billingDocCreatedStart, setBillingDocCreatedStart] = React.useState(null);
    const [billingDocCreatedEnd, setBillingDocCreatedEnd] = React.useState(null);
    const [billingDocExtractedStart, setBillingDocExtractedStart] = React.useState(null);
    const [billingDocExtractedEnd, setBillingDocExtractedEnd] = React.useState(null);
    const [billingDocCreatedStartTime, setBillingDocCreatedStartTime] = React.useState(null);
    const [billingDocCreatedEndTime, setBillingDocCreatedEndTime] = React.useState(null);
    const [billingDocExtractedStartTime, setBillingDocExtractedStartTime] = React.useState(null);
    const [billingDocExtractedEndTime, setBillingDocExtractedEndTime] = React.useState(null);


    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(newValue);
        if (billingDateEnd) {
            setPricingDateRangeKey('pricing_date.range');
        } else {
            setPricingDateRangeKey('pricing_date');
        }
    }
    const handlePricingDateEnd = (newValue) => {
        setPricingDateEnd(newValue);
        if (newValue) {
            setPricingDateRangeKey('pricing_date.range');
        } else {
            setPricingDateRangeKey('pricing_date');
        }
    }

    const onSubmitHandler = () => {
        var formData = {
            // "incentive_basis.in": incentiveBasis.toString(),
            // "sold_to_party": soldToParty.toString(),
            // "ship_to_party": shipToParty.toString(),
            // "bill_to_party": billToParty.toString(),
            // "payer":payer.toString(),



            // Sold to Party Ship to Party Bill to Party Payer,
            "calculation_simulation_number.in": calculationSimulationNumberMultiple.toString(),
            [billingDateRangeKey]: (billingDateStart ? Moment(billingDateStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + (billingDateEnd ? ',' + Moment(billingDateEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "billing_doc_number.in": billingDocNumberMultiple.toString(),
            "billing_doc_line.in": billingDocLineMultiple.toString(),
            [contractNumberKey]: contractNumberRange ? contractNumberMultiple.toString() + ',' + contractNumberRange : contractNumberMultiple.toString(),
            // "application_type": applicationType,
            "calculation_schema": contractType,
            "contract_type.in": contractSubType.toString(),
            "start_date": calculationStartDate ? Moment(calculationStartDate).toISOString().split('T')[0].concat('T00:00:00.00Z') : '',
            "end_date": calculationEndDate ? Moment(calculationEndDate).toISOString().split('T')[0].concat('T23:59:59.00Z') : '',
            "billing_type.in": billingType.toString(),
            "item_category.in": itemCategory.toString(),
            "company_code.in": companyCode.toString(),
            "sales_org": salesOrg.toString(),
            "distribution_channel": distributionChannel.toString(),
            "division.in": division.toString(),
            "fiscal_year_month": fiscalYearMonth ? Moment(fiscalYearMonth).toISOString().split('T')[0].concat('T00:00:00.00Z') : '',
            // "calendar_year_month": calendarYearMonth ? Moment(calendarYearMonth).toISOString().split('T')[0].concat('T00:00:00.00Z') : '',
            "customer_chain.in": customerChain.toString(),
            "customer_group.in": customerGroup.toString(),
            // "supplier_group.in": vendorGroupMultiple.toString(),
            "vendor.in": vendorMultiple.toString(),
            "material_number.in": materialNumberMultiple.toString(),
            "material_group.in": materialGroupMultiple.toString(),
            "material_type.in": materialTypeMultiple.toString(),
            "product_hierarchy.in": productHierarchyMultiple.toString(),
            "sales_order_type.in": salesOrderTypeMultiple.toString(),
            "profit_center.in": profitCenterMultiple.toString(),
            "plant.in": plantMultiple.toString(),

            "sold_to_party.in": soldToParty.toString(),
            "ship_to_party.in": shipToParty.toString(),
            "bill_to_party.in": billToParty.toString(),
            "payer.in": payer.toString(),

            "calculation_simulation_created_by": calculationSimulationCreatedBy,
            "calculation_simulation_created_date": calculationSimulationCreatedDate ? Moment(calculationSimulationCreatedDate).toISOString().split('T')[0].concat('T00:00:00.00Z') : '',
            "calculation_simulation_created_time": calculationSimulationCreatedTime ? Moment(calculationSimulationCreatedTime).format('01:01:001THH:mm:ss').concat('Z') : '',
            //new fields
            [pricingDateRangeKey]: (pricingDateStart ? Moment(pricingDateStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + (pricingDateEnd ? ',' + Moment(pricingDateEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "customer_number.in": customerNumber.toString(),
            "target_type.in": targetType.toString(),
            "calendar_year_month.in": calendarYearMonth.toString(),
            "material_description.in": materialDescription.toString(),
            "supplier_number.in": supplierNumber.toString(),
            "supplier_name.in": supplierName.toString(),
            "supplier_group.in": supplierGroup.toString(),
            "supplier_customer_number.in": supplierCustomerNumber.toString(),
            "employee_number.in": employeeNumber.toString(),
            "employee_name.in": employeeName.toString(),
            "territory_id.in": terittoryID.toString(),
            "region.in": region.toString(),
            "sales_office.in": salesOffice.toString(),
            "sales_group.in": salesGroup.toString(),
            "flex_attribute1.in": flexAttribute1.toString(),
            "flex_attribute2.in": flexAttribute2.toString(),
            "net_value.in": netValue.toString(),
            "incentive_basis1.in": incentiveBasis1,
            "incentive_rate.in": incentiveRate.toString(),
            "incentive_amount.in": incentiveAmount.toString(),
            "flex_key_figure1.in": flexKeyFigure1.toString(),
            "flex_key_figure2.in": flexKeyFigure2.toString(),
            "sales_district.in": salesDistrict.toString(),
            "order_reason.in": orderReason.toString(),
            "billing_document_created_date.range": (billingDocCreatedStart ? Moment(billingDocCreatedStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + ',' + (billingDocCreatedEnd ? Moment(billingDocCreatedEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "billing_document_created_time.range": (billingDocCreatedStartTime ? Moment(billingDocCreatedStartTime).format('01:01:001THH:mm:ss').concat('Z') : '') + ',' + (billingDocCreatedEndTime ? Moment(billingDocCreatedEndTime).format('01:01:001THH:mm:ss').concat('Z') : ''),
            "billing_document_extracted_date.range": (billingDocExtractedStart ? Moment(billingDocExtractedStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + ',' + (billingDocExtractedEnd ? Moment(billingDocExtractedEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "billing_document_extracted_time.range": (billingDocExtractedStartTime ? Moment(billingDocExtractedStartTime).format('01:01:001THH:mm:ss').concat('Z') : '') + ',' + (billingDocExtractedEndTime ? Moment(billingDocExtractedEndTime).format('01:01:001THH:mm:ss').concat('Z') : ''),
        };
        // if ((calculationSimulationNumberMultiple.length === 0 && calculationSimulationNumberRange) ||
        //     (!billingDateStart && billingDateEnd) ||
        //     (contractNumberMultiple.length === 0 && contractNumberRange)) {
        //     toast.error("Please fill all the required fields");
        // } else {
        sessionStorage.setItem("TargetReportsData", JSON.stringify(formData));
        props.onSubmit(0, 10);
        onCancelHandler();
        // }
    }
    const onCancelHandler = () => {
        setCalculationSimulationNumberMultiple([]);
        setCalculationSimulationNumberRange('');
        setBillingDateStart(null);
        setBillingDateEnd(null);
        setBillingDocNumberMultiple([]);
        setBillingDocLineMultiple([]);
        setContractNumberMultiple([]);
        setContractNumberRange('');
        // setApplicationType('');
        setContractType('');
        setContractSubType([]);
        setCalculationStartDate(null);
        setCalculationEndDate(null);
        setBillingType([]);
        setItemCategory([]);
        setCompanyCode([]);
        setSalesOrg([]);
        setDistributionChannel([]);
        setDivision([]);
        setFiscalYearMonth(null);
        setCalendarYearMonth([]);
        setSoldToPartyMultiple([]);
        setCustomerChain([]);
        setCustomerGroup([]);
        setVendorMultiple([]);
        setMaterialNumberMultiple([]);
        setMaterialGroupMultiple([]);
        setMaterialTypeMultiple([]);
        setProductHierarchyMultiple([]);
        setSalesOrderTypeMultiple([]);
        setProfitCenterMultiple([]);
        setPlantMultiple([]);

        setSoldToParty([]);
        setShipToParty([]);
        setBillToParty([]);
        setPayer([]);




        setCalculationSimulationCreatedBy('');
        setCalculationSimulationCreatedDate(null);
        setCalculationSimulationCreatedTime(null);
    }
    function handleCalendarYearMonth(newValue) {
        setCalendarYearMonth(newValue)
    }
    function handleTargetType(newValue) {
        setTargetType(newValue)
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue)
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber(newValue)
    }
    function handleEmployeeNumber(newValue) {
        setEmployeeNumber(newValue)
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary'>Commitment Details (Beta)</Typography>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} >
                                    <FormLabel classes={{ root: classes.fontSetting }} >Contract Number</FormLabel>
                                    <div style={{ flexDirection: 'row', display: 'flex', width: "100%" }}>
                                        {/* <ChipInput paste data={contractNumberMultiple} onChange={handleContractNumberMultiple} /> */}
                                        <div style={{
                                            marginTop: "-5px", width: "60%"
                                        }}>
                                            <SearchDropDownMasterDataNew id='contract'
                                                prevalue={contractNumberMultiple}
                                                multiple
                                                table='contract_header' keyName='contract_number'
                                                description={true}
                                                onChange={handleContractNumberMultiple}
                                            />
                                        </div>
                                        {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={contractNumberMultiple}
                                        onAdd={(chips) => handleContractNumberMultiple(chips)}
                                        onDelete={(chip, index) => handleDeleteContractNumberMultiple(chip, index)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true,
                                            [classes.error]: contractNumberMultiple.length === 0 && contractNumberRange
                                        })}
                                    /> */}
                                        <div style={{
                                            width: "30%"
                                        }}>
                                            <TextField
                                                className={clsx({
                                                    [classes.textInput]: true,
                                                    // [classes.textField]: true,
                                                })}
                                                InputProps={{ classes: { input: classes.textInputHeight } }}
                                                variant="outlined"
                                                value={contractNumberRange}
                                                onChange={handleContractNumberRange}
                                                disabled={disableContractNumberRange}
                                                style={{ marginLeft: 5, width: "50%" }}
                                                required
                                            />
                                        </div>
                                    </div>

                                </div>

                            </Grid>
                            {startDateArray &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} >Start Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            // marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationStartDate}
                                                    onChange={date => setCalculationStartDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                            }
                            {endDateArray &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} >End Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            // marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationEndDate}
                                                    // onChange={date => setCalculationEndDate(date)}
                                                    onChange={date => {
                                                        // Format the date to ISO 8601 format
                                                        const isoFormattedDate = date ? date.endOf('day').toISOString() : null;
                                                        setCalculationEndDate(isoFormattedDate);
                                                    }}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                            }
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Calendar Year Month</FormLabel>
                                    <ChipInput paste data={calendarYearMonth} onChange={handleCalendarYearMonth} />

                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Target Type</FormLabel>
                                    <ChipInput paste data={targetType} onChange={handleTargetType} />

                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Customer Number</FormLabel>
                                    {/* <ChipInput paste data={customerNumber} onChange={handleCustomerNumber} /> */}
                                    <div style={{
                                        marginTop: "-5px"
                                    }}>
                                        <SearchDropDownMasterDataNew id='customer_number'
                                            table='customer_master' keyName='customer_number'
                                            description={true}
                                            multiple
                                            prevalue={customerNumber}
                                            onChange={handleCustomerNumber}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Material Number</FormLabel>
                                    {/* <ChipInput paste data={materialNumberMultiple} onChange={handleMaterialNumberMultiple} /> */}
                                    <div style={{
                                        marginTop: "-5px"
                                    }}>
                                        <SearchDropDownMasterDataNew id='material_number'
                                            table='material_master' keyName='material_number'
                                            description={true}
                                            multiple
                                            prevalue={materialNumberMultiple}
                                            onChange={handleMaterialNumberMultiple}
                                        />
                                    </div>

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Supplier Number</FormLabel>
                                    {/* <ChipInput paste data={supplierNumber} onChange={handleSupplierNumber} /> */}
                                    <div style={{
                                        marginTop: "-5px"
                                    }}>
                                        <SearchDropDownMasterDataNew id='supplier_number'
                                            table='supplier_master' keyName='supplier_number'
                                            description={true}
                                            multiple
                                            prevalue={supplierNumber}
                                            onChange={handleSupplierNumber}
                                        />
                                    </div>

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Sold to Party</FormLabel>
                                    <ChipInput paste data={soldToParty} onChange={handleSoldToParty} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Ship to Party</FormLabel>
                                    <ChipInput paste data={shipToParty} onChange={handleShipToParty} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Bill to Party</FormLabel>
                                    <ChipInput paste data={billToParty} onChange={handleBillToParty} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Payer</FormLabel>
                                    <ChipInput paste data={payer} onChange={handlePayer} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Employee Number</FormLabel>
                                    {/* <ChipInput paste data={employeeNumber} onChange={handleEmployeeNumber} /> */}
                                    <div style={{
                                        marginTop: "-5px"
                                    }}>
                                        <SearchDropDownMasterDataNew id='employee_number'
                                            table='employee_master' keyName='employee_number'
                                            description={true}
                                            multiple
                                            prevalue={employeeNumber}
                                            onChange={handleEmployeeNumber}
                                        />
                                    </div>

                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler} >
                        Run
                    </Button>
                </div>
            </div >
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit) => dispatch(runTargetReports(pagination, limit, '')),
        onLoadingDefault: () => dispatch(getDefaultValuesTargetData()),
        getDefaultValuesAllData: (applicationType) => dispatch(getDefaultValues(sessionStorage.getItem('application'), 'Direct - Sales Data', null, null, 'Display Designer')),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetReportsTab);