import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, TextField,
    Card, OutlinedInput, FormLabel, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Checkbox
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addPricingBatchJobSetup, getDefaultValues, getPricingBatchJobDetail, getPriceCalForList, getIntegrationSetupData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import ChipInput from 'material-ui-chip-input';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
        marginTop: 20

    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
}));

const EditProfitOptBatchJobSetup = props => {
    const classes = useStyles();
    const [batchSetupID, setBatchSetupID] = React.useState(0);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [quantity, setQuantity] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [calculationDateType, setCalculationDateType] = React.useState('');
    const [numDays, setNumDays] = React.useState(0);
    const history = useHistory();
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }

    useEffect(() => {
        if (props.batchJobDetail && props.batchJobDetail.app_type) {
            setBatchSetupID(props.batchJobDetail.ID)
            setBatchJobType(props.batchJobDetail.batch_job_type);
            setBatchJobName(props.batchJobDetail.batch_job_name);
            setPlannedStartDate(props.batchJobDetail.planned_start_date);
            setFrequency(props.batchJobDetail.frequency);
            setPlannedStartTime(props.batchJobDetail.planned_start_time)
            setPlannedDateType(props.batchJobDetail.planned_date_type)
            setPlannedStartCalendar(props.batchJobDetail.planned_start_calendar)
            if (props.batchJobDetail.batch_filter_criteria) {
                props.batchJobDetail.batch_filter_criteria.map((item, index) => {
                    setCustomerNumber(item.customer_number)
                    setMaterialNumber(item.material_number)
                    setPriceCalcFormula(item.formula)
                    setQuantity([item.quantity])
                    setPricingDateStart(Moment.utc(item.pricing_date).local())
                    setNumDays(item.num_days)
                    setCalculationDateType(item.calculation_date_type)
                })
            }
        }
    }, [props.batchJobDetail])

    useEffect(() => {
        props.getConfigData(1, 10);
        setPricingDateStart(Moment.utc(new Date().getMonth() + 1 + "/" + (new Date().getDate()) + "/" + new Date().getFullYear()))
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getBatchJobDetail(appId);

    }, []);
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push(e.formula_name)
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {

        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'accrual_calendar') {
                    setPlannedStartCalendarArray(item)
                }
            })
        }
    }, [props.dropdownData])
    const BatchJobTypesArray = [
        'Sales Forecast'
    ];
    const frequencyTypesArray = [
        'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    function handleCustomerNumber(newValue) {
        setCustomerNumber([...customerNumber, newValue])
    }
    function handleCustomerNumberDelete(newValue) {
        var deletedCustomerNumber = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomerNumber)
    }
    function handleMaterialNumber(newValue) {
        setMaterialNumber([...materialNumber, newValue])
    }
    function handleMaterialNumberDelete(newValue) {
        var deletedMaterialNumber = materialNumber.filter(item => item !== newValue)
        setMaterialNumber(deletedMaterialNumber)
    }
    function handleQuantityAdd(newValue) {
        setQuantity([...quantity, newValue]);
    }
    function handleQuantityDelete(newValue) {
        var deletedQuantity = quantity.filter(item => item !== newValue)
        setQuantity(deletedQuantity)
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    function handleContinue() {
        setOpen(false);
    }
    function handleOnSubmit() {

        var batchJobFilter = [{
            "customer_number": customerNumber,
            "material_number": materialNumber,
            "formula": priceCalcFormula,
            "quantity": parseInt(quantity),
            "pricing_date": Moment.utc(pricingDateStart).local(),
            "num_days": parseInt(numDays),
            "calculation_date_type": calculationDateType
        }]
        var data = {
            "app_type": "Pricing",
            "batch_job_type": batchJobType,
            "batch_job_name": batchJobName,
            "planned_date_type": plannedDateType,
            "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').local().format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().local().format('YYYY-MM-DDThh:mm:ssZ'),
            "planned_start_calendar": plannedStartCalendar,
            "planned_start_time": plannedStartTime.concat(":00"),
            "frequency": frequency,
            "batch_filter_criteria": batchJobFilter
        }
        var app = 'Sales Forecast'
        props.onSubmit(data, 'edit', batchSetupID, app);

    }
    function handleClearAll() {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setQuantity([]);
        setPriceCalcFormula('');
        setPricingDateStart(null);
        setCalculationDateType('');
    }

    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value.concat(":00"))
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/profitopt-batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Automatic Integration' ? 'none' : 'flex') }}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ display: (batchJobType === 'Sales Forecast' ? 'block' : 'none') }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Calculation Date Type
                                        </FormLabel>
                                        <Select
                                            value={calculationDateType}
                                            onChange={(e) => setCalculationDateType(e.target.value)}
                                            displayEmpty
                                            disabled={!frequency ? true : false}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >

                                            <MenuItem value='1 Day Back'>
                                                1 Day Back
                                            </MenuItem>
                                            <MenuItem value='2 Day Back'>
                                                2 Day Back
                                            </MenuItem>
                                            <MenuItem value='Custom Day Back'>
                                                Custom Day Back
                                            </MenuItem>

                                        </Select>
                                    </div>
                                </Grid>
                                {calculationDateType === 'Custom Day Back' &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <FormLabel>
                                            Number of Days
                                        </FormLabel>
                                        <OutlinedInput
                                            value={numDays}
                                            type={'number'}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => setNumDays(e.target.value)}
                                        />
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Sales Forecast' ? 'block' : 'none') }}
                                >
                                    <FormLabel className={classes.chargeBackLabel} required>
                                        Customer Number
                                    </FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={customerNumber}
                                        onAdd={(chips) => handleCustomerNumber(chips)}
                                        onDelete={(chip) => handleCustomerNumberDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Sales Forecast' ? 'block' : 'none') }}
                                >
                                    <FormLabel className={classes.chargeBackLabel} required>
                                        Material Number
                                    </FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={materialNumber}
                                        onAdd={(chips) => handleMaterialNumber(chips)}
                                        onDelete={(chip) => handleMaterialNumberDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Sales Forecast' ? 'block' : 'none') }}
                                >
                                    <FormLabel className={classes.chargeBackLabel} required>
                                        Quantity
                                    </FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={quantity}
                                        onAdd={(chips) => handleQuantityAdd(chips)}
                                        onDelete={(chip) => handleQuantityDelete(chip)}
                                        disableUnderline={true}
                                        blurBehavior='add'
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Sales Forecast' ? 'block' : 'none') }}
                                >
                                    <FormLabel className={classes.chargeBackLabel} required>
                                        Pricing Calculation Formula
                                    </FormLabel>
                                    <Select
                                        value={priceCalcFormula}
                                        onChange={(e) => handlePriceCalFormula(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Sales Forecast' ? 'block' : 'none') }}
                                ><FormLabel required>Pricing Date</FormLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={pricingDateStart}
                                                onChange={handlePricingDateStart}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card >

                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Type
                                            </FormLabel>
                                            <Select
                                                value={batchJobType}
                                                onChange={(e) => setBatchJobType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {BatchJobTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => setFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {frequencyTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>


                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Name
                                            </FormLabel>
                                            <OutlinedInput
                                                value={batchJobName}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setBatchJobName(e.target.value)} />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => setPlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency ? true : false}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                <MenuItem value='static'>
                                                    Static
                                                </MenuItem>
                                                <MenuItem value='dynamic'
                                                    style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>

                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Grid container justifyContent="space-around">
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 0,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: '34px'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select
                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                    .filter(item => item != 'Manual')
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>

                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>

                                            <TextField
                                                id="time"
                                                type="time"
                                                //defaultValue=""
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                // className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>


                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/profitopt-batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => history.push('/forecasting-sales')} color="primary" autoFocus variant="contained"
                            disabled={!batchJobType || !batchJobName || !plannedStartTime || !frequency || !plannedDateType ? true
                                : plannedStartDate || plannedStartCalendar ? false : true
                            }
                        >
                            Continue

                        </Button>
                    </DialogActions>
                </Dialog>



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>


                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}

                        disabled={
                            batchJobType === 'Sales Forecast' && calculationDateType && customerNumber && materialNumber && priceCalcFormula && quantity && pricingDateStart ? false : true


                        }
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );


};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id, app) => dispatch(addPricingBatchJobSetup(data, type, id, app)),
        getBatchJobDetail: (id) => dispatch(getPricingBatchJobDetail(id)),
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        batchJobDetail: state.customerData.pricingBatchJobDetail,
        configData: state.pricingData.priceCalForListData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfitOptBatchJobSetup);