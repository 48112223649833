import React, { useEffect } from 'react';
import {
    Card, CardHeader, Grid,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../../components/Inputs';
import Moment from 'moment';
import { getDefaultValuesAllDataNew } from '../../../../../redux/actions'
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const Accrual = props => {

    const classes = useStyles();
    // const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    // useEffect(() => {
    //     if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
    //         setLabelNewAPIData(props.labelNewAPIData)
    //     }
    // }, [props.labelNewAPIData])
    // function checkLabel(name) {
    //     return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
    //         .map(([key, value]) => {
    //             if (key == name) {
    //                 return labelNewAPIData[name]['current']
    //             }
    //         })
    // }

    return (
        <div
            className={clsx(classes.root)}
            style={{
                display: (props.data.accrual_level && props.fieldData.accrual_level ||
                    props.data.copa_level && props.fieldData.copa_level ||
                    props.data.accrual_frequency && props.fieldData.accrual_frequency ||
                    props.data.accrual_calendar && props.data.accrual_calendar != '0001-01-01T00:00:00Z' && props.fieldData.accrual_calendar ||
                    props.data.internal_reference && props.fieldData.internal_reference ||
                    props.data.accrual_gl_account_credit && props.fieldData.accrual_gl_account_credit ||
                    props.data.accrual_gl_account_debit && props.fieldData.accrual_gl_account_debit ||
                    props.data.accrual_credit_profit_center && props.fieldData.accrual_credit_profit_center ||
                    props.data.accrual_debit_profit_center && props.fieldData.accrual_debit_profit_center ||
                    props.data && props.data.accrual_run && props.fieldData.accrual_run ||
                    props.data && props.data.flex_fields_4 && props.fieldData.flex_fields_4 ||
                    props.data.posting_schema == 'Payment then Amortization'
                ) ? 'block' : 'none'
            }}
        >
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="ACCRUAL"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <div className={classes.container}>
                        {props.data && props.fieldData &&
                            <div className={classes.container}>
                                <Grid container className={classes.gridContainer}>
                                    {props.data.accrual_level && props.fieldData.accrual_level &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_level.current} data={props.data.accrual_level} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.copa_level && props.fieldData.copa_level &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.copa_level.current} data={props.data.copa_level} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.accrual_frequency && props.fieldData.accrual_frequency &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_frequency.current} data={props.data.accrual_frequency} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.accrual_calendar && props.data.accrual_calendar != '0001-01-01T00:00:00Z' && props.fieldData.accrual_calendar &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_calendar.current} data={props.data.accrual_calendar} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.internal_reference && props.fieldData.internal_reference &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.internal_reference.current} data={props.data.internal_reference} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.accrual_gl_account_credit && props.fieldData.accrual_gl_account_credit &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_gl_account_credit.current} data={props.data.accrual_gl_account_credit} twoline='true' />
                                        </Grid>
                                    }

                                    {props.data.accrual_gl_account_debit && props.fieldData.accrual_gl_account_debit &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_gl_account_debit.current} data={props.data.accrual_gl_account_debit} twoline='true' />
                                        </Grid>
                                    }

                                    {props.data.accrual_credit_profit_center && props.fieldData.accrual_credit_profit_center &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_credit_profit_center.current} data={props.data.accrual_credit_profit_center} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.accrual_debit_profit_center && props.fieldData.accrual_debit_profit_center &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_debit_profit_center.current} data={props.data.accrual_debit_profit_center} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data && props.data.accrual_run && props.fieldData.accrual_run &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.accrual_run.current} data={props.data.accrual_run} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data && props.data.flex_fields_4 && props.fieldData.flex_fields_4 &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.flex_fields_4.current} data={props.data.flex_fields_4} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.posting_schema == 'Payment then Amortization' &&
                                        <>
                                            {(props.data && props.data.short_term_period && props.fieldData.short_term_period) ?
                                                <Grid
                                                    item
                                                    md={2.4}
                                                    xs={6}
                                                >
                                                    <LabelText heading={props.fieldData.short_term_period.current} data={props.data.short_term_period} twoline='true' />
                                                </Grid>
                                                : ''
                                            }
                                            {(props.data && props.data.long_term_period && props.fieldData.long_term_period) ?
                                                <Grid
                                                    item
                                                    md={2.4}
                                                    xs={6}
                                                >
                                                    <LabelText heading={props.fieldData.long_term_period.current} data={props.data.long_term_period} twoline='true' />
                                                </Grid>
                                                : ''
                                            }
                                            {props.data && props.data.short_term_account && props.fieldData.short_term_account &&
                                                <Grid
                                                    item
                                                    md={2.4}
                                                    xs={6}
                                                >
                                                    <LabelText heading={props.fieldData.short_term_account.current} data={props.data.short_term_account} twoline='true' />
                                                </Grid>
                                            }
                                            {props.data && props.data.long_term_account && props.fieldData.long_term_account &&
                                                <Grid
                                                    item
                                                    md={2.4}
                                                    xs={6}
                                                >
                                                    <LabelText heading={props.fieldData.long_term_account.current} data={props.data.long_term_account} twoline='true' />
                                                </Grid>
                                            }
                                            {props.data && props.data.amortization_value && props.fieldData.amortization_value &&
                                                <Grid
                                                    item
                                                    md={2.4}
                                                    xs={6}
                                                >
                                                    <LabelText heading={props.fieldData.amortization_value.current} data={props.data.amortization_value} twoline='true' />
                                                </Grid>
                                            }
                                        </>
                                    }
                                </Grid>
                            </div>
                        }
                    </div>
                </form>
            </Card>
        </div>
    );

}

// const mapDispatchToProps = dispatch => {
//     return {
//        // onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
//     }
// }

// const mapStateToProps = state => {
//     return {
//         labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
//     }
// }

export default connect(null, null)(Accrual);