import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Typography, Breadcrumbs, Link, Grid,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { getSingleAnamolyConfig } from '../../redux/actions/AnamolyConfig/AuthAction';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));
const ViewAnamolyConfig = props => {

    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [userStore, setUserStore] = React.useState(localStorage.getItem('anamoly-config') ? JSON.parse(localStorage.getItem('anamoly-config')) : []);
    const [aimodelName, setaiModelName] = React.useState('');
    const [anamolyName, setAnamolyName] = React.useState('');
    const [anamolyDescription, setAnamolyDescription] = React.useState('');
    const [sourceData, setSourceData] = React.useState('');
    const [evaluationDate, setEvaluationDate] = React.useState('');
    const [attributes, setAttributes] = React.useState([]);
    const [keyFigures, setKeyFigures] = React.useState([]);
    const [contaminationFactor, setContaminationFactor] = React.useState('');
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleAnamolyConfig(appId)
    }, []);
    useEffect(() => {
        if (props.queryListData) {
            setAnamolyName(props.queryListData.KeyValueAttrs['anamoly_name'])
            setAnamolyDescription(props.queryListData.KeyValueAttrs['anamoly_description'])
            setaiModelName(props.queryListData.KeyValueAttrs['ai_model_name'])
            setSourceData(props.queryListData.KeyValueAttrs['source_data'])
            setEvaluationDate(props.queryListData.KeyValueAttrs['evaluation_date'])
            setAttributes(props.queryListData.KeyValueAttrs['attributes'])
            setKeyFigures(props.queryListData.KeyValueAttrs['key_figures'])
            setContaminationFactor(props.queryListData.KeyValueAttrs['contamination_factor'])
        }
    }, [props.queryListData]);
    return (
        <div className={clsx(classes.root, className)}>
            <Typography color="primary" variant='h1'>&ensp;View Anomaly</Typography>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.queryListData ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead}>Anomaly Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Anomaly Description</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>AI Model Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Source Data</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Evaluation Date</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Attribute</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Key Figures</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Contamination Factor</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            <StyledTableRow>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{anamolyName}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{anamolyDescription}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{aimodelName}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{sourceData}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(evaluationDate).format('MM/DD/YYYY')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{attributes}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{keyFigures}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{contaminationFactor}</TableCell>
                                            </StyledTableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    No Data
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                </div>
            </div >
        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.AnamolyData.anamolySingleData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getSingleAnamolyConfig: (id) => dispatch(getSingleAnamolyConfig(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewAnamolyConfig);