import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useState,
} from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
    FormLabel,
    TextField,
    Checkbox,
    Button,
    Paper, Chip
} from "@mui/material";
import { getContractsTableDataList, getDropdownList } from "../../../redux/actions";
import { connect } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
//import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { Search, Add, Done } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
    select: {
        width: "100%",
        marginTop: 10,
    },
    error: {
        border: "1px solid red !important",
    },
    input: {
        padding: '4px', // Adjust the padding as needed
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    option: {
        padding: "2px 6px"
    }
}));

const SearchDropDownMaterialMasterDataNew = forwardRef((props, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const [changedFlag, setChangedFlag] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [keyField, setKeyField] = React.useState("");

    const [listData, setListData] = useState({});
    const [multiData, setMultiData] = React.useState([]);
    const [data, setData] = React.useState("");
    const [searchedValue, setSearchedValue] = React.useState('');
    const [add, setAdd] = React.useState(true);
    const [openDropdown, setOpenDropdown] = React.useState(false);
    useEffect(() => {
        var temp = { 'contract_number': [], 'customer_number': [], 'material_number': [], 'supplier_number': [], 'employee_number': [] }
        setListData(temp)
        var data = {
            page: 1,
            limit: 100,
            value: "",
            key: props.keyName,
            description: props.description,
            application_type: props.applicationType
        }
        setKeyField(props.keyName)
        // if (props.table && props.keyName)
        //     props.getDropdownValues(props.table, data)
    }, [props.table, props.description, props.keyName])
    useEffect(() => {
        if (props.prevalue && props.multiple)
            setMultiData(props.prevalue)
        else {
            if (props.prevalue)
                setData(props.prevalue)
            else
                setData('')
        }
    }, [props.prevalue])
    const filterOptions = (options, { inputValue }) => {
        return (
            inputValue ? options.filter(
                (option) =>
                (option.key.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option.desc.toLowerCase().includes(inputValue.toLowerCase()))
            )
                : options);
    };

    useEffect(() => {
        if (props.dropdownValue && props.dropdownValue.records) {
            setListData(prevState => ({
                ...prevState,
                [props.keyName]: props.dropdownValue.records
            }))
        }
        else
            setListData(prevState => ({
                ...prevState,
                [props.keyName]: []
            }))
        // setListData([])
    }, [props.dropdownValue]);

    const handleSelectedData = (e, value, type) => {
        setAdd(true)
        if (type == "clear") {
            setSearchedValue('')
            // handleSearch('nil')
            setData({ key: '', desc: '' })
            props.onChange('')
        }
        else {
            setData(value);
            props.onChange(value.key);
        }
    }


    const handleMultipleSelecton = (e, value, type) => {
        setAdd(true)
        if (type == "clear") {
            setSelected([]);
            setMultiData([]);
            setSearchedValue('')
            // handleSearch('nil')
            props.onChange([]);
        } else if (type == "removeOption") {
            setSelected(value);
            setMultiData(value);
            props.onChange(value.map(item => item.key));
        } else {
            let newSelected = value;
            if (value.length > 0) {
                setSelected(newSelected);
                // setContractNumber(newSelected.map(Number));
            } else {

                setSelected([]);
                // setContractNumber([]);
            }
        }

    };
    const reset = () => {
        setData("");
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : data,
        };
    });

    useEffect(() => {
        if (props.prevalue && props.multiple) {
            if (Array.isArray(props.prevalue) && props.prevalue.length > 0 &&
                typeof props.prevalue[0] === 'object' && props.prevalue[0] !== null) {

                setSelected(props.prevalue);
                setMultiData(props.prevalue);
            }
            else {
                var newData = props.prevalue.map(item => ({
                    key: item,
                    desc: ''
                }));
                setSelected(newData);
                setMultiData(newData);
            }

        }
        else {
            if (props.prevalue) {
                if (typeof props.prevalue == 'object')
                    setData(props.prevalue)
                else
                    setData({ 'key': props.prevalue, 'desc': '' })
            }
        }
    }, [props.prevalue]);
    function onAdd() {
        setMultiData(selected);
        props.onChange(selected.map(item => item.key));
    }
    function onCancel(type) {
        setSelected(multiData);
        props.onChange(multiData.map(item => item.key));
    }
    const handleSearch = (newValue) => {
        var data = {
            page: 1,
            limit: 500,
            value: newValue == 'nil' ? '' : searchedValue,
            key: props.keyName,
            description: props.description,
            application_type: props.applicationType
        }
        props.getDropdownValues(props.table, data)
    }
    const handleInputChange = (e, type) => {
        let temp = []
        setAdd(true)
        if (type === "pastedItem") {
            e.map((arg) => {
                if (arg) {
                    temp.push({ key: arg.trim(), desc: '' });
                }
            });
            setMultiData([...multiData, ...temp]);
            setSelected([...selected, ...temp])
        }
        if (e.key === 'Enter' && searchedValue != '') {
            setSelected([...selected, { 'key': searchedValue, 'desc': '' }])
            setMultiData([...multiData, { 'key': searchedValue, 'desc': '' }]);
            props.onChange([...(multiData.map(item => item.key)), searchedValue]);
        }
        // if (e && e.target && e.target.value == '') {
        //     handleSearch('nil')
        //     // setSearchedValue(e.target.value)
        // }
        else {
            setSearchedValue(e?.target?.value)
        }
    }
    const handleAdd = (type) => {
        // handleSearch('nil')
        if (type == 'multiple' && searchedValue != '') {
            setSelected([...selected, { 'key': searchedValue, 'desc': '' }])
            setMultiData([...multiData, { 'key': searchedValue, 'desc': '' }]);
            props.onChange([...(multiData.map(item => item.key)), searchedValue]);
        }
        else {
            if (searchedValue != '') {
                setData({ 'key': searchedValue, 'desc': '' })
                props.onChange(searchedValue)
            }
        }
        setSearchedValue('')
        setAdd(false)
    }
    const handleOpen = () => {
        setListData(prevState => ({
            ...prevState,
            [props.keyName]: []
        }))
        // var data = {
        //     page: 1,
        //     limit: 100,
        //     value: "",
        //     key: props.keyName,
        //     description: props.description,
        //     application_type: props.applicationType
        // }
        // if (props.table)
        //     props.getDropdownValues(props.table, data)
    }
    const handleSearchButtonClick = () => {
        setOpenDropdown(true); // Show dropdown when search button is clicked
        handleSearch();
    }
    return (
        <div className={classes.contractContiner}>
            {props.heading && (
                <FormLabel
                    required={props.required ? props.required : false}
                    style={{ fontSize: theme.typography.h3.fontSize }}
                >{props.heading}
                </FormLabel>
            )}
            {props.multiple ? (
                <Autocomplete
                    multiple
                    limitTags={3}
                    id={props.id ? props.id : "checkboxes-tags-demo"}
                    size="small"
                    variant="Outlined"
                    options={listData && listData[props.keyName] ? listData[props.keyName] : []}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option?.key
                    }}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    defaultValue={multiData}
                    value={selected}
                    selectOnFocus
                    clearOnBlur
                    onOpen={handleOpen}
                    open={openDropdown}
                    onClose={() => setOpenDropdown(false)}
                    // onClose={() => setSelected(multiData)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            style={{
                                borderRadius: "0px !important",
                                // paddingBottom: "4px !important",
                            }}
                            value={searchedValue}
                            onKeyDown={handleInputChange}
                            onChange={handleInputChange}
                            onPaste={(event) => {
                                const clipboardText = event.clipboardData
                                    .getData("Text")
                                    .split("\n");

                                event.preventDefault();
                                handleInputChange(clipboardText, 'pastedItem');
                                if (props.onPaste) {
                                    props.onPaste(event);
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment.props.children[0]}
                                        {/* <InputAdornment position="start">
                                            {add ?
                                                <Add onClick={() => handleAdd('multiple')} size="small" style={{ cursor: "pointer", marginLeft: 5 }} />
                                                :
                                                <Done color='primary' size="small" style={{ marginLeft: 5 }} />
                                            }
                                        </InputAdornment> */}
                                        <InputAdornment position="start">
                                            <Search color={props.loading ? 'primary' : 'black'} size="small" onClick={handleSearchButtonClick} style={{ cursor: "pointer", marginLeft: 5 }} />
                                        </InputAdornment>
                                        {params.InputProps.endAdornment.props.children[1]}

                                    </>),
                                style: { padding: '3px 6px' },
                                disableUnderline: true
                            }}
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        selected.map((option, index) => (
                            <Chip variant="outlined" size="small" classes={{ root: classes.chip }}
                                label={option.key} {...getTagProps({ index })}
                            />
                        ))
                    }
                    filterOptions={filterOptions}
                    classes={{
                        inputRoot: { borderRadius: "0px !important", padding: 0 },
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                color="primary"
                                checked={selected}
                            />
                            {option.desc ? option.key + " - ( " + option.desc + " )" : option.key}
                        </li>
                    )}
                    style={{
                        width: "100%", marginBottom: 15, marginTop: 5
                        // overflowY: 'auto',
                    }}
                    noOptionsText={
                        props.loading ?
                            'Loading' :
                            listData && listData[props.keyName] && listData[props.keyName].length > 0
                                ? 'No matching options found. Please try with search !'
                                : 'No Record found'
                    }
                    onChange={(event, newValue, reason) => {
                        handleMultipleSelecton(event, newValue, reason);
                    }}
                    PaperComponent={({ children }) => {
                        return (
                            <Paper>
                                {children}
                                <div className={classes.dropdownAction}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.btn}
                                        onMouseDown={onCancel}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.btn}
                                        onMouseDown={onAdd}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </Paper>
                        );
                    }}
                />
            ) : (
                <Autocomplete
                    id={props.id ? props.id : 'demo-search'}
                    loading={props.loading}
                    size="small"
                    options={listData && listData[props.keyName] ? listData[props.keyName] : []}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option?.key
                    }}
                    //isOptionEqualToValue={(option, value) => option.key === value.key}
                    isOptionEqualToValue={(option, value) =>
                        option?.key === value?.key
                    }
                    selectOnFocus
                    clearOnBlur
                    defaultValue={data}
                    value={data}
                    filterOptions={filterOptions}
                    onOpen={handleOpen}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            className={classes.input}
                            style={{
                                borderRadius: "0px !important",
                                paddingBottom: "4px !important",
                            }}
                            value={searchedValue}
                            onChange={handleInputChange}
                            InputProps={{
                                ...params.InputProps,
                                style: { padding: "4px" },
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment.props.children[0]}
                                        <InputAdornment position="start">
                                            {add ?
                                                <Add onClick={handleAdd} style={{ cursor: "pointer", marginLeft: 5 }}
                                                    disabled={searchedValue ? false : true} size="small" />
                                                :
                                                <Done color='primary' style={{ marginLeft: 5 }} size="small" />
                                            }
                                        </InputAdornment>
                                        <InputAdornment position="start">
                                            <Search color={props.loading ? 'primary' : 'black'} size="small" onClick={handleSearch} style={{ cursor: "pointer", marginLeft: 5 }} />
                                        </InputAdornment>
                                        {params.InputProps.endAdornment.props.children[1]}
                                    </>),
                                style: { padding: '3px 6px' },
                                disableUnderline: true
                            }}
                        />
                    )}
                    classes={{
                        inputRoot: { borderRadius: "0px !important" },
                    }}
                    noOptionsText={
                        props.loading ?
                            'Loading' :
                            listData && listData[props.keyName] && listData[props.keyName].length > 0
                                ? 'No matching options found. Please try with search !'
                                : 'No Record found'
                    }

                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.desc ? option.key + " - ( " + option.desc + " )" : option.key}
                        </li>
                    )}
                    style={{ width: "100%", marginBottom: 15, marginTop: 5 }}
                    onChange={(event, newValue, reason) => {
                        handleSelectedData(event, newValue, reason);
                    }}
                />
            )}
        </div>
    );
});

const mapDispatchToProps = (dispatch) => {
    return {
        getDropdownValues: (table, data) => dispatch(getDropdownList(table, data, "Master Data")),
    };
};

const mapStateToProps = (state) => {
    return {
        dropdownValue: state.attributeListData.dropdownMasterData,
        loading: state.attributeListData.dropdownLoading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDropDownMaterialMasterDataNew);
