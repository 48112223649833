import React, { useEffect } from 'react';
import {
    Card, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Typography, CardHeader,
    Dialog, DialogActions, Button, IconButton, Paper, Chip
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles, styled } from '@mui/styles';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import Moment from 'moment';
import {getPriceCalForListByName} from '../../../redux/actions/Pricing/AuthAction';
var data = [];
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0px 0px 20px',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    stackList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 5
    },
    stackContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rootHeader: {
        paddingLeft: 0,
        marginTop: 0
    },
    tableCell: {
        textTransform: 'capitalize'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const PriceAdjustmentSimulationResult = props => {
    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [adjustmentData, setAdjustmentData] = React.useState({});
    const [orgHeader, setOrgHeader] = React.useState([]);
    const [headerData, setHeaderData] = React.useState({});
    const [customerHeader, setCustomerHeader] = React.useState([]);
    const [materialHeader, setMaterialHeader] = React.useState([]);
    const [header, setHeader] = React.useState([]);
    const [formulaHeader,setFormulaHeader] = React.useState([]);
    const [displayHeader, setDisplayHeader] = React.useState({});
    useEffect(() =>{
        const formula=history.location.formula;
        if(formula){
            props.getPriceCalFormula(formula)
        }
    },[])
    useEffect(() =>{
        if(props.formula&&props.formula.length>0){
           var name= props.formula.map((item,i)=>item.price_type_name)
           setFormulaHeader(name)
        }
    },[props.formula])
    useEffect(() => {
        var org = []
        var customer = []
        if (props.priceAdjustmentData && props.priceAdjustmentData.results) {
            setAdjustmentData(props.priceAdjustmentData.results.result)
            if (props.priceAdjustmentData.results['organization_header']) {
                org = props.priceAdjustmentData.results['organization_header']
                setOrgHeader(props.priceAdjustmentData.results['organization_header'])
            }
            if (props.priceAdjustmentData.results['customer_header']) {
                customer = props.priceAdjustmentData.results['customer_header']
                setCustomerHeader(props.priceAdjustmentData.results['customer_header'])
            }
            if (props.priceAdjustmentData.results['material_header']) {
                setMaterialHeader(props.priceAdjustmentData.results['material_header'])
            }
            if (props.priceAdjustmentData.results['display_headers']) {
                setHeaderData(props.priceAdjustmentData.results['display_headers'])
                setDisplayHeader(props.priceAdjustmentData.results['display_headers'])
                var headerData=[]
                Object.entries(props.priceAdjustmentData.results['display_headers']).map(([key,value])=>{
                        headerData.push(...value)
                    })
                    setHeader(headerData)
            }
        }
    }, [props.priceAdjustmentData])
    useEffect(()=>{
        var headerList=[]
        if(formulaHeader&&headerData){
        var sortedHeader=Object.entries(headerData) .sort(function ([a1,v1], [a2,v2]) {
            return formulaHeader.indexOf(a1) - formulaHeader.indexOf(a2);
        }).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
        Object.entries(sortedHeader).map(([key,value])=>{
            headerList.push(...value)
        })
        setHeader(headerList)
        setDisplayHeader(sortedHeader)
    }
    },[headerData,formulaHeader])

    return (
        <div>

            <div className={clsx(classes.root, className)}>
                <div style={{ padding: '19px' }}>
                    <Typography variant="h2">
                        Price Adjustment Simulation Results
                    </Typography>
                </div>

                <Card>

                    <div className={classes.container} style={{ paddingTop: 0,overflowY: 'scroll',height:window.screen.height*.9}}>
                        <Grid>
                            {adjustmentData && adjustmentData.length > 0 &&
                                // <TableContainer >
                                    <Table stickyHeader>
                                        <TableHead >
                                            <TableRow >
                                                {orgHeader && orgHeader.length > 0 && orgHeader.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}> {item.replace(/_/g, ' ')} </TableCell>

                                                ))}
                                                {customerHeader && customerHeader.length > 0 && customerHeader.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}> {item.replace(/_/g, ' ')} </TableCell>

                                                ))}
                                                {materialHeader && materialHeader.length > 0 && materialHeader.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}> {item.replace(/_/g, ' ')} </TableCell>

                                                ))}
                                                {header && header.length > 0 && header.map(item => (
                                                    <TableCell align='center' className={classes.tableCell}>{item}</TableCell>

                                                ))}
                                                <TableCell align='center' className={classes.tableCell}> New Price </TableCell>
                                            </TableRow>
                                        </TableHead><TableBody classes={{ root: classes.table }} >
                                            {adjustmentData && adjustmentData.length > 0 && adjustmentData.map((item, i) => {
                                                return (
                                                    <StyledTableRow>
                                                        {orgHeader && orgHeader.map(itemX => (
                                                            Object.entries(adjustmentData[i]).filter(([k, v]) => k == 'organization_attributes').map((itemOrg, i) => (
                                                                <TableCell align='center'>{itemOrg[1][itemX]}</TableCell>
                                                            ))
                                                        ))}
                                                        {customerHeader && customerHeader.map(itemX => (
                                                            Object.entries(adjustmentData[i]).filter(([k, v]) => k == 'customer_attributes').map((itemCust, i) => (
                                                                <><TableCell align='center'>{itemCust[1][itemX]}</TableCell> {console.log(itemCust)}</>
                                                            ))))
                                                        }
                                                        {materialHeader && materialHeader.map(itemX => (
                                                            Object.entries(adjustmentData[i]).filter(([k, v]) => k == 'material_attributes').map((itemM, i) => (
                                                                <TableCell align='center'>{itemM[1][itemX]}</TableCell>
                                                            ))
                                                        ))}
                                                       
                                                        {
                                                               Object.entries(displayHeader).length>0&&Object.entries(displayHeader).map(([key,value]) => (
                                                                    adjustmentData[i]['price_type_name_values'][key]&&
                                                                    Object.entries(adjustmentData[i]['price_type_name_values'][key]) .sort(function ([a1,v1], [a2,v2]) { 
                                                                return value.indexOf(a1) - value.indexOf(a2);
                                                                })
                                                              .filter(([k,v])=>value.includes(k))
                                                               .map(([k,v])=>(
                                                                    <TableCell align='center'>{v&&parseFloat(v) ? parseFloat(v).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :v}</TableCell>
                                                                ))
                                                                
                                                            ))
                                                        } 
                                                        <TableCell align='center'>{item.net_value ? parseFloat(item.net_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>

                                                    </StyledTableRow>

                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                            }
                        </Grid>
                    </div>
                </Card>
            </div >
        </div >
    );

};


const mapStateToProps = state => {
    return {
        priceAdjustmentData: state.pricingData.priceAdjustSimulationReport,
        formula: state.pricingData.priceListCalForDataByName,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getPriceCalFormula: (name) => dispatch(getPriceCalForListByName(name)),
        }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceAdjustmentSimulationResult);

