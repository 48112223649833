import React from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  dialog: {
    justifyContent: 'center',
    padding: '20px 20px 27px 20px'
  },
  dialogContent: {
    padding: '43px 37px 0px 37px !important'
  }
}));

const SimpleDialog = ({ content, open, handleDialog }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogContent classes={{
        root: classes.dialogContent
      }}>
        <Typography variant="h4">
          <DialogContentText>
            {content}
          </DialogContentText>
        </Typography>
      </DialogContent>
      <DialogActions classes={{
        root: classes.dialog
      }}>
        <Button onClick={() => handleDialog(false)} autoFocus color="primary" variant="outlined" >
          No
        </Button>
        <Button onClick={() => handleDialog(true)} color="primary" autoFocus variant="contained"  >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  
}

export default SimpleDialog;