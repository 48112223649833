import React, { useEffect } from "react";
import {
  Typography,
  Breadcrumbs,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { getAggregationLevelById } from "../../redux/actions";
import { connect } from "react-redux";
import { useHistory,Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  fontSetting:{
    fontSize:theme.typography.h4.fontSize
},
tabHead: {
    backgroundColor: theme.palette.primary.main,
    fontSize:theme.typography.h4.fontSize
},
}));
const ViewCalculationAnalysis = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentId, setCurrentId] = React.useState("");
  const [applicationType, setApplicationType] = React.useState("");
  const [sourceDataType, setSourceDataType] = React.useState("");
  const [secondLevel, setSecondLevel] = React.useState([]);
  const [thirdLevel, setThirdLevel] = React.useState([]);
  const [fourthLevel, setFourthLevel] = React.useState([]);
  const [fifthLevel, setFifthLevel] = React.useState([]);
  const [contractType, setContractType] = React.useState("");
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.table.row,
        height: 46,
      },
      "&:nth-of-type(even)": {
        height: 40,
      },
    },
  }))(TableRow);
  useEffect(() => {
    var pathname = window.location.pathname;
    var appId = pathname.substring(pathname.lastIndexOf("/") + 1);
    if (appId) props.getAggregationLevelById(appId);
  }, []);
  useEffect(() => {
    if (props.aggregationLevelSingleData) {
      setCurrentId(props.aggregationLevelSingleData.id);
      setApplicationType(props.aggregationLevelSingleData.application_type);
      setSourceDataType(props.aggregationLevelSingleData.data_source_type);
      setContractType(props.aggregationLevelSingleData.contract_type);
      if (props.aggregationLevelSingleData.second_level !== null)
        setSecondLevel(props.aggregationLevelSingleData.second_level);
      if (props.aggregationLevelSingleData.third_level !== null)
        setThirdLevel(props.aggregationLevelSingleData.third_level);
      if (props.aggregationLevelSingleData.fourth_level !== null)
        setFourthLevel(props.aggregationLevelSingleData.fourth_level);
      if (props.aggregationLevelSingleData.fifth_level !== null)
        setFifthLevel(props.aggregationLevelSingleData.fifth_level);
    }
  }, [props.aggregationLevelSingleData]);
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h4"
          classes={{
            root: classes.link,
          }}
          to="/calculation-analysis"
        >
          Calculation Analysis
        </Link>
        <Typography color="textPrimary" variant="h4">
          View Calculation Analysis
        </Typography>
      </Breadcrumbs>
      {props.aggregationLevelSingleData &&
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tabHead}>Application Type</TableCell>
                <TableCell align="center" className={classes.tabHead}>Source Data Type</TableCell>
                <TableCell align="center" className={classes.tabHead}>Contract Type</TableCell>
                <TableCell align="center" className={classes.tabHead}>First Level Name</TableCell>
                <TableCell align="center" className={classes.tabHead}>Second Level</TableCell>
                <TableCell align="center" className={classes.tabHead}>Second Level Name</TableCell>
                <TableCell align="center" className={classes.tabHead}>Third Level</TableCell>
                <TableCell align="center" className={classes.tabHead}>Third Level Name</TableCell>
                <TableCell align="center" className={classes.tabHead}>Fourth Level</TableCell>
                <TableCell align="center" className={classes.tabHead}>Fourth Level Name</TableCell>
                <TableCell align="center" className={classes.tabHead}>Fifth Level</TableCell>
                <TableCell align="center" className={classes.tabHead}>Fifth Leve Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody classes={{ root: classes.table }}>
              <StyledTableRow key={currentId}>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {applicationType}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {sourceDataType}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}}
                  align="center"
                  style={{ cursor: "pointer", textTransform: "capitalize" }}
                >
                  {contractType}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {props.aggregationLevelSingleData.first_level_name ? props.aggregationLevelSingleData.first_level_name : ""}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {secondLevel ? secondLevel.toString().replace(/_/g, " ") : ""}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {props.aggregationLevelSingleData.second_level_name ? props.aggregationLevelSingleData.second_level_name : ""}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {thirdLevel ? thirdLevel.toString().replace(/_/g, " ") : ""}
                </TableCell>
                 <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {props.aggregationLevelSingleData.third_level_name ? props.aggregationLevelSingleData.third_level_name : ""}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {fourthLevel ? fourthLevel.toString().replace(/_/g, " ") : ""}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {props.aggregationLevelSingleData.fourth_level_name ? props.aggregationLevelSingleData.fourth_level_name : ""}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {fifthLevel ? fifthLevel.toString().replace(/_/g, " ") : ""}
                </TableCell>
                <TableCell classes={{root:classes.fontSetting}} align="center" style={{ cursor: "pointer" }}>
                  {props.aggregationLevelSingleData.fourth_level_name ? props.aggregationLevelSingleData.fifth_level_name : ""}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loadingAPI: state.customerData.loading,
    aggregationLevelSingleData:
      state.calculationAnalysisData.aggregationLevelSingleData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAggregationLevelById: (id) => dispatch(getAggregationLevelById(id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCalculationAnalysis);
