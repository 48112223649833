import React, { useEffect } from 'react';
import {
    Typography,
    Breadcrumbs,
    Button,
    Grid,
    useMediaQuery, FormLabel, Select, OutlinedInput, Checkbox,
    ListItemText, MenuItem, Card
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { createAttributeHierarchyData, getAttributeListData, updateAttributeData } from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import { AttributeData } from './components';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    gridContainer: {
        // [theme.breakpoints.down('lg')]: {
        //     display: 'flex',
        //     justifyContent: 'center'
        // }
        padding: '0px 45px 0px 45px !important'
    },
    shiftContent: {
        marginLeft: 0
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    container: {
        padding: '10px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    inputTwoLine: {
        // marginTop: 10,
        width: '100%',
        height: 35
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        borderRadius: '1rem',
        height: '36px'
    },
}));

const AddAttribute = props => {
    const classes = useStyles();
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    var generalData = {};
    const history = useHistory();
    const theme = useTheme();
    const { id } = useParams();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [attributeName, setAttributeName] = React.useState([]);
    const [open2, setOpen2] = React.useState("");
    const [selected2, setSelected2] = React.useState([]);
    const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = React.useState([]);
    const [dynamicFieldValues, setDynamicFieldValues] = React.useState([]);
    const attributeNameArray = [
        { key: "accrual_credit_profit_center", value: "Accrual Credit Profit Center" },
        { key: "accrual_debit_profit_center", value: "Accrual Debit Profit Center" },
        { key: "accrual_document_type", value: "Accrual Document Type" },
        { key: "accrual_gl_account_credit", value: "Accrual GL Account Credit" },
        { key: "accrual_gl_account_debit", value: "Accrual GL Account Debit" },
        { key: "currency", value: "Currency" },
        { key: "payment_document_type", value: "Payment Document Type" },
        { key: "payment_terms", value: "Payment Terms" },
        { key: "customer_classification", value: "Customer Classification" },
        { key: "class_of_trade", value: "Class Of Trade" },
        { key: "account_group", value: "Account Group" },
        { key: "customer_chain", value: "Customer Chain" },
        { key: "customer_group", value: "Customer Group" },
        { key: "customer_group1", value: "Customer Group 1" },
        { key: "customer_group2", value: "Customer Group 2" },
        { key: "customer_group3", value: "Customer Group 3" },
        { key: "customer_group4", value: "Customer Group 4" },
        { key: "customer_group5", value: "Customer Group 5" },
        { key: "customer_industry", value: "Customer Industry" },
        { key: "material_type", value: "Material Type" },
        { key: "base_uom", value: "Base Uo M" },
        { key: "external_material_group", value: "External Material Group" },
        { key: "product_hierarchy", value: "Product Hierarchy" },
        { key: "material_group", value: "Material Group" },
        { key: "material_group1", value: "Material Group 1" },
        { key: "material_group2", value: "Material Group 2" },
        { key: "material_group3", value: "Material Group 3" },
        { key: "material_group4", value: "Material Group 4" },
        { key: "material_group5", value: "Material Group 5" },
        { key: "supplier_type", value: "Supplier Type" },
        { key: "supplier_industry", value: "Supplier Industry" },
        { key: "billed_uom", value: "Billed Uo M" },
        { key: "billing_type", value: "Billing Type" },
        { key: "company_code", value: "Company Code" },
        { key: "controlling_area", value: "Controlling Area" },
        { key: "cost_center", value: "Cost Center" },
        { key: "delivery_type", value: "Delivery Type" },
        { key: "distribution_channel", value: "Distribution Channel" },
        { key: "division", value: "Division" },
        { key: "document_category", value: "Document Category" },
        { key: "item_category", value: "Item Category" },
        { key: "order_reason", value: "Order Reason" },
        { key: "plant", value: "Plant" },
        { key: "profit_center", value: "Profit Center" },
        { key: "sales_district", value: "Sales District" },
        { key: "sales_group", value: "Sales Group" },
        { key: "sales_office", value: "Sales Office" },
        { key: "sales_order_type", value: "Sales Order Type" },
        { key: "sales_org", value: "Sales Org" },
        { key: 'contract_owner', value: 'Contract Owner' },
        { key: "customer_id_type", value: "Customer ID Type" },
        { key: "customer_type", value: "Customer Type" },
        { key: "channel_partner_type", value: "Channel Partner Type" },
        { key: "supplier_id_type", value: "Supplier ID Type" },
        { key: "material_id_type", value: "Material ID Type" },
        { key: "price_list", value: "Price List" },
        { key: "action_code", value: "Action Code" },
        { key: "member_status", value: "Member Status" },
        { key: "current_tier_status", value: "Current Tier Status" },
        { key: "accounts_type", value: "Accounts Type" },
        { key: "price_group", value: "Price Group" },
        { key: "exception_reason", value: "Exception Reason" },
    ];
    const sortedAttributeNameArray = attributeNameArray.sort((a, b) => a.value.localeCompare(b.value));
    const isAllSelected2 =
        sortedAttributeNameArray &&
        sortedAttributeNameArray.length > 0 && selected2.length === sortedAttributeNameArray.length;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const handleComplete = () => {
        // props.onSubmit({ "attributes": [dynamicFieldValuesFormSubmit] });
        const formattedAttributes = Object.keys(dynamicFieldValuesFormSubmit).length > 0 && Object.keys(dynamicFieldValuesFormSubmit).map(key => {
            const item = dynamicFieldValuesFormSubmit[key];
            return {
                attribute_value: item.attribute_value,
                attribute_name: item.attribute_name,
                attribute_desc: item.attribute_desc
            };
        });
        props.onSubmit({ "attributes": formattedAttributes });
    };
    const handleCancel = () => {
        history.push("/attributes-hierarchy");
    };

    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            // Toggle Select All
            if (selected2.length === sortedAttributeNameArray.length) {
                newSelected = [];
            } else {
                newSelected = sortedAttributeNameArray.map(item => item.key);
            }
            setSelected2(newSelected);
        } else setSelected2(newSelected);
    };

    function handleOpen2() {
        setSelected2(attributeName);
        setOpen2(true);
    }
    function onAdd2() {
        setAttributeName(selected2);
        setOpen2(false);
        setSelected2([]);
    }
    function onCancel2() {
        setOpen2(false);
        setSelected2([]);
    }

    const handleDynamicFieldValues = (e, fieldName, type, page) => {
        if (!dynamicFieldValuesFormSubmit[fieldName]) {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: { filter_name: fieldName },
            });
        }

        // Reset the filter value when the filter option changes
        if (type === "attributeValue") {
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: '' });
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: {
                    ...dynamicFieldValuesFormSubmit[fieldName],
                    attribute_value: e,
                    attribute_name: fieldName,
                    // attribute_desc: '' // Reset filter value
                },
            });
        } else if (type === "attributeDescription") {
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: '' });
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: {
                    ...dynamicFieldValuesFormSubmit[fieldName],
                    // attribute_value: e,
                    attribute_desc: e,
                    attribute_name: fieldName,
                },
            });
        }
    };
    // console.log("1", attributeName)
    // console.log("23", dynamicFieldValuesFormSubmit)
    return (
        <div>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/attributes-hierarchy'
                    >
                        Attributes Data Setup
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Add Data</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        cclasses={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel className={classes.chargeBackLabel} >
                                            Attribute Name
                                        </FormLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={attributeName}
                                            style={{ width: "98.5%", textTransform: "capitalize" }}
                                            input={<OutlinedInput sx={{ padding: '4px' }} />}
                                            onOpen={() => handleOpen2()}
                                            onClose={() => setOpen2(false)}
                                            open={open2}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            renderValue={(appType) => {
                                                var priceType = [];
                                                appType.map((item) => {
                                                    priceType.push(item.replace(/_/g, " "));
                                                });
                                                return priceType.join(", ");
                                            }}
                                        >
                                            <MenuItem
                                                value="all"
                                                onClick={(event) => handleSelectValues2(event, "all")}
                                                style={{ textTransform: "capitalize", display: 'flex', justifyContent: 'center' }}
                                            >
                                                <Checkbox color="primary" checked={isAllSelected2} />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {sortedAttributeNameArray && sortedAttributeNameArray.map(({ key, value }) => (
                                                <MenuItem onClick={(event) => handleSelectValues2(event, key)} value={key} key={key}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selected2.indexOf(key) > -1}
                                                    />
                                                    <ListItemText primary={value} />
                                                </MenuItem>
                                            ))}
                                            <div className={classes.dropdownAction}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.btn}
                                                    onClick={onCancel2}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btn}
                                                    onClick={onAdd2}
                                                >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </Grid>
                                    {attributeName &&
                                        attributeName.length > 0 &&
                                        attributeName
                                            .map((item, ix) => {
                                                return <>
                                                    <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainerOuter }}
                                                        style={{ display: "flex" }}
                                                    >
                                                        <Grid
                                                            item
                                                            md={3}
                                                            xs={12}
                                                            classes={{ root: classes.gridContainer }}
                                                            style={{
                                                                position: 'relative'
                                                            }}
                                                        >

                                                            <FormLabel classes={{ root: classes.fontSetting }}
                                                                // required={ix === 0 ? false : ix === 4 ? false : true}
                                                                style={{
                                                                    marginTop: '0.7rem'
                                                                }}>
                                                                <Typography variant="h3" color='primary' className={ix == 0 && classes.fontSetup} style={{ marginTop: '-0.6rem', width: '16.5rem' }}>{ix == 0 && "Attribute Name"}</Typography>
                                                                {
                                                                    ix == 0 ? <div style={{ marginTop: '10px' }} > {item.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                                                                        {/* <span style={{ color: 'red' }}>*</span>  */}
                                                                    </div> : <div style={{ marginTop: '-10px' }}> {item.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}</div>
                                                                }
                                                            </FormLabel>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={3}
                                                            xs={12}
                                                            classes={{ root: classes.gridContainer }}
                                                        >
                                                            <FormLabel
                                                                classes={{ root: classes.fontSetting }}
                                                            >
                                                                <Typography variant="h3" color='primary' style={{ width: '19.3rem' }} className={ix == 0 && classes.fontSetup}>{ix === 0 && "Attribute Value"}</Typography>
                                                            </FormLabel>
                                                            <div
                                                                style={{ marginTop: ix === 0 ? '0px' : '-1.5rem' }}>
                                                                <OutlinedInput
                                                                    value={dynamicFieldValuesFormSubmit[
                                                                        item
                                                                    ] &&
                                                                        dynamicFieldValuesFormSubmit[item][
                                                                        "attribute_value"
                                                                        ]
                                                                        ? dynamicFieldValuesFormSubmit[
                                                                        item
                                                                        ]["attribute_value"]
                                                                        : ""}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) =>
                                                                        handleDynamicFieldValues(
                                                                            e.target.value,
                                                                            item,
                                                                            "attributeValue"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={3}
                                                            xs={12}
                                                            classes={{ root: classes.gridContainer }}
                                                        >
                                                            <FormLabel
                                                                classes={{ root: classes.fontSetting }}
                                                            >
                                                                <Typography variant="h3" color='primary' style={{ width: '19.3rem' }} className={ix == 0 && classes.fontSetup}>{ix === 0 && "Attribute Description"}</Typography>
                                                            </FormLabel>
                                                            <div
                                                                style={{ marginTop: ix === 0 ? '0px' : '-1.5rem' }}>
                                                                <OutlinedInput
                                                                    value={dynamicFieldValuesFormSubmit[
                                                                        item
                                                                    ] &&
                                                                        dynamicFieldValuesFormSubmit[item][
                                                                        "attribute_desc"
                                                                        ]
                                                                        ? dynamicFieldValuesFormSubmit[
                                                                        item
                                                                        ]["attribute_desc"]
                                                                        : ""}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) =>
                                                                        handleDynamicFieldValues(
                                                                            e.target.value,
                                                                            item,
                                                                            "attributeDescription"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid >

                                                </>;
                                            })}
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
                                    >
                                        <Button variant="outlined" color="primary" onClick={handleComplete}
                                            className={clsx({
                                                [classes.button]: true,
                                                [classes.shiftContent]: !isDesktop,
                                            })}
                                            // disabled={saveDisabled}
                                            disabled={!attributeName.length || !attributeName.every(key => dynamicFieldValuesFormSubmit[key]?.attribute_value)}
                                        >
                                            {props.loading ?
                                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                                :
                                                id ? 'Update' : 'Save'
                                            }
                                        </Button>
                                        <Button variant="outlined" color="primary" onClick={handleCancel} className={classes.button}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createAttributeHierarchyData(data)),
        updateAttributeData: (id, data) => dispatch(updateAttributeData(id, data)),
        getAttributeDataByName: (data) => dispatch(getAttributeListData(data)),
    }
}

const mapStateToProps = state => {
    return {
        attributeData: state.attributeListData.editAttributeData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttribute);