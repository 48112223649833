import React from 'react';
import {PostingForm } from './component';

const AccrualPostings = props => {

    return (
        <PostingForm  page={'accrual'}/>
    );

};

export default AccrualPostings;