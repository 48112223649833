//not used.
import React, { useEffect, forwardRef, useRef, useState } from 'react';
import {
    Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton, OutlinedInput, Select, MenuItem, FormLabel, TextField, Chip
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import {
    getEligibilityRulesTableData, editEligibilityRules, getDefaultValuesAttributeValue, getDefaultValuesAllData,
    uploadEligibilityRules, getCustomerMasterData, getMaterialMasterData, getSupplierMasterData, getEmployeeData, getAttributeListData, fetchDropdownConfig
} from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { Publish, CloudUpload } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Tooltip from "@mui/material/Tooltip";
import ReplayIcon from '@mui/icons-material/Replay';
import { AllOtherAttributeMasterComponent, SearchDropdown } from '../../../../components/Inputs';
import ka from 'date-fns/locale/ka/index';

momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        marginTop: 56
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    formDiv: {
        height: 41,
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-end'
    },
    conditionID: {
        display: (sessionStorage.getItem('application') === 'Customer Chargeback' || sessionStorage.getItem('application') === 'Pricing' ||
            sessionStorage.getItem('application') === 'Supplier Chargeback') ? 'none' : null
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    uploadIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        height: 36,
        display: 'flex'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const EligibilityRules = forwardRef((props) => {
    const [currentApplication, setCurrentApplication] = React.useState(sessionStorage.getItem('application'));
    const [attributeValuesArrayMaterialNumber, setAttributeValuesArrayMaterialNumber] = React.useState([]);
    const [employeeMasterArrayMaterialNumber, setEmployeeMasterArrayMaterialNumber] = React.useState([]);
    const [employeeNameListArray, setEmployeeNameListArray] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            },
        },
    };
    // useEffect(() => {
    //     props.getAttributeListData({
    //         attribute_name: ''
    //     })

    // }, [])
    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.employeeMasterData.records.map((item) => {
                tempArray.push(item.employee_number);
                tempNameArray.push(item.employee_name);
            })
            setEmployeeMasterArrayMaterialNumber(tempArray)
            setEmployeeNameListArray(tempNameArray);
        }
    }, [props.employeeMasterData])
    useEffect(() => {
        //props.getCustomerMasterData();
        // props.getMaterialMasterData();
        //props.getSupplierMasterData();
        //props.getEmployeeData();
    }, []);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
            })
            setAttributeValuesArray(tempArray)
        }
    }, [props.customerMasterData])
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var customerTempArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
                customerTempArray.push(item.customer_name)
            })
            setAttributeValuesArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    const [supplierMasterArrayMaterialNumber, setSupplierMasterArrayMaterialNumber] = React.useState([]);
    const [materialNameListArray, setMaterialNameListArray] = React.useState([]);

    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var materialTempArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
                materialTempArray.push(item.material_description)
            })
            setAttributeValuesArrayMaterialNumber(tempArray)
            setMaterialNameListArray(tempNameArray);
        }
    }, [props.materialMasterData])
    const [supplierNameArray, seSupplierNameArray] = React.useState([]);
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setSupplierMasterArrayMaterialNumber(tempArray)
            seSupplierNameArray(tempNameArray)
        }
    }, [props.supplierMasterData])


    const [value, setValue] = React.useState([]);
    const [attributeValuesArray, setAttributeValuesArray] = React.useState([]);

    const contractCustomisationUpdation = useRef();
    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [attributeName, setAttributeName] = React.useState('');
    const [option, setOption] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);
    const [eligibility, setEligibility] = React.useState('Eligible');
    const [attributeValue, setAttributeValue] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [eligibilityStore, setEligibilityStore] = React.useState([]);
    const [attributeNameEnableHelper, setAttributeNameEnableHelper] = React.useState(false);
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeNameObject, setAttributeNameObject] = React.useState([]);
    const [attributeNameValue, setAttributeNameValue] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [sequenceNumber, setSequenceNumber] = React.useState(1);
    const [idValue, setIdValue] = React.useState('');
    const [eligibilityTypeTier, setEligibilityTypeTier] = React.useState('');
    const inputFile = useRef(null)
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [ruleID, setRuleID] = React.useState(0);
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const [maximumAmount, setMaximumAmount] = React.useState('');
    const handleDrop = (event) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.uploadEligibilityRules(data, props.calculationMethod, sessionStorage.getItem('application'), props.sourceDataType);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
    }
    function uploadER() {
        inputFile.current.click();
    }
    useEffect(() => {
        if (props.uploadEligibilityRulesData && props.uploadEligibilityRulesData.data) {
            var x = props.uploadEligibilityRulesData.data;
            x.forEach(function (v) { delete v.ID });
            setEligibilityStore([...eligibilityStore, ...props.uploadEligibilityRulesData.data]);
            props.onChange([...eligibilityStore, ...props.uploadEligibilityRulesData.data])
        }
    }, [props.uploadEligibilityRulesData])

    // useEffect(() => {
    //     if (props.activeDropdown) {
    //         setActiveDropdown(props.activeDropdown)
    //     }
    // }, [props.activeDropdown])
    // useEffect(() => {
    //     if ((attributeNameValue === 'customer_number' || attributeNameValue === 'ship_to_party' || attributeNameValue === 'bill_to_party'
    //         || attributeNameValue === 'sold_to_party' || attributeNameValue === 'payer') && activeDropdown['customer_number']) {
    //         props.getCustomerMasterData();
    //     }
    //     else if (activeDropdown && activeDropdown[attributeNameValue]) {
    //         if (attributeNameValue === 'material_number' && activeDropdown['material_number']) {
    //             props.getMaterialMasterData();
    //         } else if (attributeNameValue === 'supplier_number' && activeDropdown['supplier_number']) {
    //             props.getSupplierMasterData();
    //         }
    //         else
    //             props.getEmployeeData();
    //     }

    // }, [attributeNameValue, activeDropdown])
    //dummy datas
    const optionData = [
        { key: 'EQUAL', value: 'EQUAL' },
        { key: 'NOT EQUAL', value: 'NOT EQUAL' }
    ];
    const eligibiltyTypeData = ['Tier', 'Rebate', 'Both']
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const editEligibilityRule = (rule, index) => {
        if (rule.ID) {
            setIdValue(rule.ID);
        } else {
            setIdValue('');
        }
        if (rule.rule_id) {
            setRuleID(rule.rule_id);
        } else {
            setRuleID(0);
        }
        if (rule && rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_name) {
            var name = rule.attribute_key_values[0].attribute_name
            setAttributeName(rule.attribute_key_values[0].attribute_name);
            if (name == 'customer_number' || name === 'ship_to_party' || name === 'bill_to_party'
                || name === 'sold_to_party' || name === 'payer')
                setAttributeNameValue('customer_number')
            else
                if (name == 'employee_number' || name == 'supplier_number' || name == 'material_number')
                    setAttributeNameValue(name)
        }
        if (rule && rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_value) {
            setAttributeValue(rule.attribute_key_values[0].attribute_value);
        }
        setAttributeNameEnableHelper(true)
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setOption(rule.option);
        setSequenceNumber(rule.sequence);
        setEffectiveStartDate(rule.effective_start_date);
        setEffectiveEndDate(rule.effective_end_date);
        setMaximumAmount(rule.maximum_amount)
        setEligibility(rule.enabled === true ? 'Eligible' : 'Not Eligible');
        // setAttributeValue(rule.attribute_value);
        setEligibilityTypeTier(rule.type);
    }
    function handleAttributename(event) {
        setAttributeValue('');

        //     if ((event.target.value === 'customer_number'||event.target.value === 'ship_to_party'||event.target.value === 'bill_to_party'
        //     ||event.target.value === 'sold_to_party'||event.target.value === 'payer')&&activeDropdown['customer_number']) {
        //         props.getCustomerMasterData();
        //     } 
        //    else if(activeDropdown&&activeDropdown[event.target.value]){
        //      if (event.target.value === 'material_number'&&activeDropdown['material_number']) {
        //         props.getMaterialMasterData();
        //     } else if (event.target.value === 'supplier_number'&&activeDropdown['supplier_number']) {
        //         props.getSupplierMasterData();
        //     }
        //     else
        //     props.getEmployeeData()
        // }
        // if (event.target.value == 'customer_number' || event.target.value === 'ship_to_party' || event.target.value === 'bill_to_party'
        //     || event.target.value === 'sold_to_party' || event.target.value === 'payer')
        //     setAttributeNameValue('customer_number')
        // else
        //     if (event.target.value == 'employee_number' || event.target.value == 'supplier_number' || event.target.value == 'material_number')
        //         setAttributeNameValue(event.target.value)
        //setAttributeNameValue(event.target.value);
        setAttributeName(event.target.value)
        setAttributeNameEnableHelper((event.target.value || event.target.value === 0) ? (event.target.value + 1) : 0);
    }
    function handleOption(event) {
        setOption(event.target.value);
    }
    function handleEffectiveStartDate(event) {
        if (event && event.isValid())
            setEffectiveStartDate(event.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setEffectiveStartDate(event);
    }
    function handleEffectiveEndDate(newValue) {
        if (newValue && newValue.isValid())
            setEffectiveEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setEffectiveEndDate(newValue)
    }

    function handleSubmit(id) {
        if (attributeName && option && effectiveStartDate && effectiveEndDate && attributeValue.length > 0) {
            var data = {
                "option": option,
                "effective_start_date": effectiveStartDate,
                "effective_end_date": effectiveEndDate,
                "type": eligibilityTypeTier,
                "maximum_amount": maximumAmount ? Number(maximumAmount) : 0,
                "attribute_key_values": [{
                    "attribute_name": attributeName,
                    "attribute_value": attributeValue,
                    "attribute_type": 'Attribute'
                }]
            };
            if (sequenceNumber) {
                data.sequence = parseInt(sequenceNumber)
            } else {
                data.sequence = 0
            }
            if (idValue) {
                data.ID = idValue;
                data.rule_id = ruleID
            }
            if (id || id === 0) {
                var editedArray = [...eligibilityStore];
                editedArray[editIndex] = data;
                setEligibilityStore(editedArray);
                props.onChange(editedArray)
                localStorage.removeItem('editableRuleMode');

            } else {
                setEligibilityStore([...eligibilityStore, data]);
                props.onChange([...eligibilityStore, data])
            }

            handleRemove();
        } else {
            toast.error("Please fill all the mandatory fields.");
            if (!attributeName || attributeName === '')
                setAttributeName(false);
            if (!option || option === '')
                setOption(false);
            if (!effectiveStartDate || effectiveStartDate === '' || effectiveStartDate === 'null')
                setEffectiveStartDate(false);
            if (!effectiveEndDate || effectiveEndDate === '' || effectiveEndDate === 'null')
                setEffectiveEndDate(false);
            if (!eligibility || eligibility === '')
                setEligibility(false);
            if (!attributeValue || attributeValue === '')
                setAttributeValue(false);
        }
    }
    function handleRemove() {
        setEditMode(false);
        setAttributeValue('');
        setAttributeName('');
        setEligibility('');
        if (effectiveStartDate != props.startDate)
            setEffectiveStartDate(null);
        if (effectiveEndDate != props.endDate)
            setEffectiveEndDate(null);
        setOption('');
        setSequenceNumber(1);
        setEligibilityTypeTier('');
        setIdValue('')
        setRuleID(0);
        setAttributeArray([]);
        setMaximumAmount('')
    }
    const [calcMethod, setCalcMethod] = React.useState('')
    useEffect(() => {
        if (props.data && props.data.eligibility_rules) {
            setEligibilityStore(props.data.eligibility_rules);
            setEffectiveStartDate(props.data.valid_from)
            setEffectiveEndDate(props.data.valid_to)
        }
        if (props.data && props.data.calculation_method) {
            setCalcMethod(props.data.calculation_method)
        }
    }, [props.data]);

    useEffect(() => {
        if (props.sourceDataType) {
            props.getDefaultValuesAllData('attribute_name', props.sourceDataType, sessionStorage.getItem('application'));
            props.onLoadingDefault(sessionStorage.getItem('application'), props.sourceDataType);
        }
    }, [props.sourceDataType])

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records[0]) {
            setAttributeNameData(props.dropdownData.records[0].label_names);
            setAttributeNameObject(props.dropdownData.records[0])
        }
    }, [props.dropdownData])
    const handleDeleteLocally = (index) => {
        var eligibilityData = eligibilityStore.filter((item, i) => i !== index)
        setEligibilityStore(eligibilityData);
        props.onChange(eligibilityData)
    }
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    useEffect(() => {
        var tempObj = {};
        if (props.dropdownData && props.dropdownData.records && props.formFieldsAllData && props.formFieldsAllData.records) {
            props.dropdownData.records.map((item1) => {
                if (item1.field_id === 'attribute_name') {
                    item1.drop_down_value_keys.map((item3) => {
                        props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.map((item4) => {
                            if (item3 === item4.field_id) {
                                tempObj[item4.field_id] = item4.name;
                            }
                        })
                    })
                    setKeyValuePairObject(tempObj);
                }
            })
        }
        setKeyValuePairObject(tempObj);

    }, [props.formFieldsAllData, props.dropdownData]);

    useEffect(() => {
        if (props.startDate)
            setEffectiveStartDate(props.startDate)
        if (props.endDate)
            setEffectiveEndDate(props.endDate)

    }, [props.startDate, props.endDate])

    //add operation
    const [attributeArray, setAttributeArray] = React.useState([]);

    const filter = createFilterOptions()


    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key === name) {
                    return labelNewAPIData[name]['current']
                }
                else if (value.default == name) {
                    return value.current
                }
            })

    }
    const handleAttributeValueFA = (e, type) => {
        var val = '';
        if (type === 'outlined') {
            val = e.target.value
        }
        else if (type === 'attr') {
            val = e;
            if (e && e.length > 0) {
                var index = e.indexOf('-');
                if (index != -1)
                    val = e.substring(0, index - 1)
            }
        }
        else {
            val = e;
        }
        setAttributeValue(val)
    }
    return (
        <div>
            {/* Eligibility Rules Add */}
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={1} >
                                {/*  for tear calculation */}
                                <Grid
                                    item
                                    md={(calcMethod === 'Tiered Volume' || calcMethod === 'Tiered Growth' || calcMethod === 'Multi Axis') ? 3 : 2}
                                    xs={12}
                                >
                                    <Grid container spacing={1} >
                                        {((calcMethod === 'Tiered Volume' || calcMethod === 'Tiered Growth' || calcMethod === 'Multi Axis')
                                        ) &&

                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formDiv} required>
                                                        Eligibility Type
                                                    </FormLabel>
                                                    <Select
                                                        value={eligibilityTypeTier}
                                                        onChange={(e) => setEligibilityTypeTier(e.target.value)}
                                                        displayEmpty
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >
                                                        <MenuItem value="" style={{ height: 30 }}>
                                                        </MenuItem>
                                                        {eligibiltyTypeData
                                                            .map(item => {
                                                                return (
                                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>

                                                </div>
                                                {/* <Typography variant='h6'>A brief description corresponding to the selected attribute.</Typography> */}
                                            </Grid>
                                        }
                                        <Grid
                                            item
                                            md={(calcMethod === 'Tiered Volume' || calcMethod === 'Tiered Growth' || calcMethod === 'Multi Axis') ? 6 : 12}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                    Attribute Name
                                                </FormLabel>
                                                <Select
                                                    value={attributeName}
                                                    onChange={handleAttributename}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value="">
                                                        {attributeNameData && Object.keys(attributeNameData).length > 0 ? '' : '- Please configure -'}
                                                    </MenuItem>
                                                    {attributeNameData && Object.keys(attributeNameData).length > 0 &&
                                                        Object.entries(attributeNameData).map(([key, value]) => {
                                                            return (
                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                            Option
                                        </FormLabel>
                                        <Select
                                            value={option}
                                            onChange={handleOption}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem>
                                            {optionData.map((item) => {
                                                return (
                                                    <MenuItem value={item.key} key={item.key} disabled={item.disabled}>
                                                        {item.value}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                    Start Date
                                                </FormLabel>
                                                <MuiPickersUtilsProvider libInstance={momentt} utils={MomentUtils}>
                                                    <Grid container justifyContent="space-around">
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 0 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 10,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={effectiveStartDate}
                                                            onChange={date => handleEffectiveStartDate(date)}
                                                            format="MM/DD/YYYY"
                                                            minDate={props.startDate ? props.startDate : ''}
                                                        />
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                    End Date
                                                </FormLabel>
                                                <MuiPickersUtilsProvider libInstance={momentt} utils={MomentUtils}>
                                                    <Grid container justifyContent="space-around">
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 0 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 10,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={effectiveEndDate}
                                                            onChange={date => handleEffectiveEndDate(date)}
                                                            format="MM/DD/YYYY"
                                                            minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                        />
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                >
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            md={3}
                                            style={{
                                                width: '100%',
                                                display: (currentApplication === 'Customer Chargeback' || currentApplication === 'Pricing' ||
                                                    currentApplication === 'Supplier Chargeback') ? 'none' : null
                                            }}
                                        >
                                            <Tooltip title='Same Condition ID = OR Different Condition ID = AND'>
                                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.formDiv}>
                                                    Condition ID
                                                </FormLabel>
                                            </Tooltip>
                                            <OutlinedInput
                                                inputProps={{
                                                    type: 'number'
                                                }}
                                                value={sequenceNumber}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={e => setSequenceNumber(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={9}
                                        >
                                            <Grid container spacing={1} >
                                                <Grid item md={attributeName == 'customer_number' && props.maximumAmountPeriod == 'Payment Period - Different Amount' ? 5 : 6}>
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                            Attribute Value
                                                        </FormLabel>
                                                        {(attributeName === 'sold_to_party'
                                                            || attributeName === 'ship_to_party'
                                                            || attributeName === 'payer'
                                                            || attributeName === 'bill_to_party'
                                                            || attributeName === 'customer_number')
                                                            ?
                                                            <SearchDropdown id='customer_single'
                                                                table={'customer_master'}
                                                                keyName={'customer_number'}
                                                                description={true}
                                                                onChange={(value) => handleAttributeValueFA(value)}
                                                                prevalue={attributeValue}
                                                            />

                                                            :
                                                            attributeName === 'material_number' ?
                                                                <SearchDropdown id='material_single'
                                                                    table={'material_master'}
                                                                    keyName={'material_number'}
                                                                    description={true}
                                                                    onChange={(value) => handleAttributeValueFA(value)}
                                                                    prevalue={attributeValue}
                                                                />
                                                                :
                                                                attributeName === 'supplier_number' ?
                                                                    <SearchDropdown id='supplier_single'
                                                                        table={'supplier_master'}
                                                                        keyName={'supplier_number'}
                                                                        description={true}
                                                                        onChange={(value) => handleAttributeValueFA(value)}
                                                                        prevalue={attributeValue}
                                                                    />
                                                                    :
                                                                    attributeName === 'employee_number' && activeDropdown && activeDropdown[attributeName] ?
                                                                        <SearchDropdown id='employee_single'
                                                                            table={'employee_master'}
                                                                            keyName={'employee_number'}
                                                                            description={true}
                                                                            onChange={(value) => handleAttributeValueFA(value)}
                                                                            prevalue={attributeValue}
                                                                        />
                                                                        : attributeName === 'customer_chain' || attributeName === 'customer_group' || attributeName === 'material_group' || attributeName === 'product_hierarchy' || attributeName === 'company_code' || attributeName === 'sales_org' || attributeName === 'supplier_group' ?
                                                                            <AllOtherAttributeMasterComponent attributeName={attributeName} value={attributeValue} onChange={(e) => handleAttributeValueFA(e, 'attr')} prevalue={attributeValue} withOutLabel={true} id={attributeName} />

                                                                            :
                                                                            <OutlinedInput
                                                                                value={attributeValue}
                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                onChange={(e) => handleAttributeValueFA(e, 'outlined')}
                                                                            />

                                                        }
                                                    </div>

                                                </Grid>
                                                {((attributeName == 'customer_number' && props.maximumAmountPeriod == 'Payment Period - Different Amount') || maximumAmount != 0) &&
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.formDiv}>
                                                                Max Amt
                                                            </FormLabel>
                                                            <OutlinedInput
                                                                inputProps={{
                                                                    type: 'number'
                                                                }}
                                                                value={maximumAmount}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={e => setMaximumAmount(e.target.value)}
                                                            />
                                                        </div>
                                                    </Grid>
                                                }
                                                <Grid item md={4} classes={{ root: classes.centerButton }}>
                                                    <Grid container style={{ paddingBottom: 15 }} >
                                                        <FormLabel></FormLabel>
                                                        {editMode ?
                                                            <Button
                                                                color='secondary'
                                                                variant="contained"
                                                                startIcon={<EditIcon />}
                                                                classes={{
                                                                    startIcon: classes.startIcon,
                                                                    root: classes.checkIconContainer
                                                                }}
                                                                disabled={!attributeNameEnableHelper || !option || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (Moment(effectiveStartDate) >= Moment(props.startDate))) || !(attributeValue && attributeValue.length > 0) ? true : false}
                                                                onClick={() => handleSubmit(editIndex)}
                                                            >
                                                            </Button>
                                                            :
                                                            <Button
                                                                color='secondary'
                                                                variant="contained"
                                                                startIcon={<CheckIcon />}
                                                                classes={{
                                                                    startIcon: classes.startIcon,
                                                                    root: classes.checkIconContainer
                                                                }}
                                                                disabled={!attributeNameEnableHelper || !option || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (Moment(effectiveStartDate) >= Moment(props.startDate))) || !(attributeValue && attributeValue.length > 0) ? true : false}
                                                                onClick={() => handleSubmit('')}
                                                            >
                                                            </Button>
                                                        }
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            startIcon={<DeleteForeverIcon />}
                                                            classes={{
                                                                startIcon: classes.startIcon,
                                                                root: classes.deleteIconContainer,
                                                            }}
                                                            onClick={handleRemove}
                                                            style={{ marginLeft: 10 }}
                                                        >
                                                        </Button>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleDrop(e)} />
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            startIcon={<CloudUpload />}
                                                            classes={{
                                                                startIcon: classes.startIcon,
                                                                root: classes.uploadIconContainer
                                                            }}
                                                            onClick={() => uploadER()}
                                                            style={{ marginLeft: 10 }}
                                                        >
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="ELIGIBILITY RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {eligibilityStore.length > 0 ?
                                <TableContainer>
                                    {(calcMethod === 'Tiered Volume' || calcMethod === 'Tiered Growth' || calcMethod === 'Multi Axis') ?
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell align='center' >Eligibility Type</TableCell>
                                                    <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                    <TableCell align='center' >Option</TableCell>
                                                    <TableCell align='center' >Start Date</TableCell>
                                                    <TableCell align='center' >End Date</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.conditionID }}>Condition ID</TableCell>
                                                    <TableCell align='center'>Attribute Value</TableCell>
                                                    {eligibilityStore.filter(item => item.maximum_amount && item.maximum_amount != 0).length > 0 &&
                                                        <TableCell align='center'>Max Amt</TableCell>
                                                    }
                                                    <TableCell align='center'>Options</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {eligibilityStore
                                                    .sort(function (a, b) {
                                                        return eligibiltyTypeData.indexOf(a.type) - eligibiltyTypeData.indexOf(b.type);
                                                    })
                                                    .map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item.ID}>
                                                                <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.type && item.type.replace(/_/g, ' ')}</TableCell>
                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                    {item.attribute_key_values && item.attribute_key_values.length > 0 && item.attribute_key_values.map((it2, ix) => {
                                                                        return attributeNameData && attributeNameData[it2.attribute_name] ? attributeNameData[it2.attribute_name] : it2.attribute_name.replace(/_/g, ' ');
                                                                    })}
                                                                </TableCell>
                                                                <TableCell align='center'>{item.option}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.leftAlign }}>{item.sequence}</TableCell>
                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                    {item.attribute_key_values && item.attribute_key_values.length > 0 && item.attribute_key_values.map((it2, ix) => {
                                                                        return (
                                                                            it2.attribute_value ? it2.attribute_value : it2.attribute_value
                                                                        )
                                                                    })}

                                                                </TableCell>
                                                                {eligibilityStore.filter(item => item.maximum_amount && item.maximum_amount != 0).length > 0 &&
                                                                    <TableCell align='center'>{item.maximum_amount && item.maximum_amount != 0 ?
                                                                        parseFloat(
                                                                            item.maximum_amount
                                                                        ).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }) : ''}
                                                                    </TableCell>
                                                                }
                                                                <TableCell align='center'>
                                                                    <IconButton
                                                                        onClick={() => editEligibilityRule(item, index)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() => handleDeleteLocally(index)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                        :
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                    <TableCell align='center' >Option</TableCell>
                                                    <TableCell align='center' >Start Date</TableCell>
                                                    <TableCell align='center' >End Date</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.conditionID }}>Condition ID</TableCell>
                                                    <TableCell align='center'>Attribute Value</TableCell>
                                                    {eligibilityStore.filter(item => item.maximum_amount && item.maximum_amount != 0).length > 0 &&
                                                        <TableCell align='center'>Max Amt</TableCell>
                                                    }
                                                    <TableCell align='center'>Options</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {eligibilityStore
                                                    .map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item.ID}>
                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_name ?
                                                                    attributeNameData && attributeNameData[item.attribute_key_values[0].attribute_name] ? attributeNameData[item.attribute_key_values[0].attribute_name] : item.attribute_key_values[0].attribute_name.replace(/_/g, ' ') : ''
                                                                }</TableCell>
                                                                <TableCell align='center'>{item.option}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.conditionID }}>{item.sequence}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.leftAlign }}>{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value && item.attribute_key_values[0].attribute_value.toString()}</TableCell>
                                                                {eligibilityStore.filter(item => item.maximum_amount && item.maximum_amount != 0).length > 0 &&
                                                                    <TableCell align='center'>{item.maximum_amount && item.maximum_amount != 0 ?
                                                                        parseFloat(
                                                                            item.maximum_amount
                                                                        ).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }) : ''}
                                                                    </TableCell>
                                                                }
                                                                <TableCell align='center'>
                                                                    <IconButton
                                                                        onClick={() => editEligibilityRule(item, index)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() => handleDeleteLocally(index)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>

                                    }
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );

});

const mapDispatchToProps = dispatch => {
    return {
        //onEligibilityRulesEdit: (data, id) => dispatch(editEligibilityRules(data, id)),
        //onCheckEligibilityRulesTable: () => dispatch(getEligibilityRulesTableData(localStorage.getItem('currentAddContractID'))),
        uploadEligibilityRules: (file, calculationMethod, appType, sourceDataType) => dispatch(uploadEligibilityRules(file, calculationMethod, appType, sourceDataType)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValuesAttributeValue(applicationType, sourceDataType, 'on', 'attribute_name')),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit)),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
    }
}

const mapStateToProps = state => {
    return {
        tableData: state.addContractData.eligibilityRulesData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationDataAV,
        //data: state.addContractData.contractGeneralData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        uploadEligibilityRulesData: state.dataSetupData.uploadEligibilityRulesData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
        supplierMasterData: state.customerData.supplierMasterData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        employeeMasterData: state.customerData.employeeMasterData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityRules);