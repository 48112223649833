import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    TablePagination,
    Button, IconButton, Fab
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { DeleteForever, AddBoxOutlined, Edit } from '@mui/icons-material'
import { createAnalysisLevel, getAnalysisLevel, getApprovalGroupData } from '../../../redux/actions';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
}));

const AnalysisLevelMain = props => {
    useEffect(() => {
        props.getConfigData(1, 10);
        props.getApprovalGroup()
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [configData, setConfigData] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getConfigData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getConfigData(1, event.target.value);
    };
    useEffect(() => {
        var tempRows = [];
        if (props.configData && props.configData.records) {
            setConfigData(props.configData.records);
            props.configData.records
                .map(e => {
                    var approval = [];
                    if (props.approvalGroup && props.approvalGroup.records) {
                        approval = props.approvalGroup.records.filter(item => item.ID == e.approval_group_id)
                    }
                    tempRows.push({
                        id: e.ID,
                        analysis_level_id: e.analysis_level_id,
                        analysis_level_id_description: e.analysis_level_id_description,
                        driver_name: e.driver_name,
                        qualifier_key: e.qualifier_key,
                        approval_group: approval && approval[0] ? approval[0].name : '',
                        external_mapping_rule: e.external_mapping_rule,
                        priority: e.priority
                    });
                })
            setCount(props.configData.total_record)
            setDataRows(tempRows);
        }
    }, [props.configData]);
    function editConfig(id) {
        history.push({
            pathname: '/analysis-level/edit-level/' + id,
            id: id
        });
    }
    const deleteConfig = (id) => {
        props.onDelete(id);
    }
    const columns = [
        {
            field: 'analysis_level_id',
            title: 'Analysis Level ID',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'analysis_level_id_description',
            title: 'Analysis Level ID Description',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'qualifier_key',
            title: 'Attribute Name',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a>{rowData.qualifier_key ? rowData.qualifier_key.toString().replace(/_/g, ' ') : ''}</a>
        },
        {
            field: 'driver_name',
            title: 'Driver Name',
            editable: 'never',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a>{rowData.driver_name ? rowData.driver_name.toString().replace(/_/g, ' ') : ''}</a>
        },
        {
            field: 'external_mapping_rule',
            title: 'External Mapping Rule',
            type: 'string',
            editable: 'never',
        },
        // {
        //     field: 'approval_group',
        //     title: 'Approval Group',
        //     type: 'string',
        //     editable: 'never',
        // },
        // {
        //     field: 'priority',
        //     title: 'Priority',
        //     type: 'number',
        //     editable: 'never',
        // },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_level-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editConfig(rowData.id)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_level-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => deleteConfig(rowData.id)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ]
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Analysis Level </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_analysis_level-' + sessionStorage.getItem('application')) &&
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            onClick={() => history.push('/analysis-level/add-level')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>}

                </div>
                {configData ?
                    <MaterialTable
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    count={count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}

                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            ),
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ textTransform: 'capitalize', marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            pageSize: rowsPerPage,
                            count: count,
                            page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        configData: state.profitOptimizationData.priceListMasterData,
        approvalGroup: state.addMultipleConfigurationData.approvalGroupData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getAnalysisLevel(pagination, limit)),
        onDelete: (id) => dispatch(createAnalysisLevel(null, id, 'delete')),
        getApprovalGroup: () => dispatch(getApprovalGroupData())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalysisLevelMain);