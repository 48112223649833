import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from "@mui/styles";
import { editCompetitorData, getLblDispDesFieldValue } from "../../redux/actions";
import { connect } from 'react-redux';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import { MasterDataComponent } from '../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        // fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
}));
const EditCompetitorData = props => {
    const classes = useStyles();
    const [membershipFields, setMembershipFields] = React.useState([]);
    const [membershipData, setMembershipData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);
    const [editID, setEditID] = React.useState(0);
    useEffect(() => {
        var data = { "data_source": "competitor_sales_data" }
        props.getLblDispDesFieldValue(data, 'post');
    }, []);
    useEffect(() => {
        if (props.competitorSingleData) {
            setEditID(props.competitorSingleData.ID)
            setMembershipData(props.competitorSingleData)
        }
    }, [props.competitorSingleData])
    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_details)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    if (item.mandatory || item.sort_details) {
                        newArray.push(item)
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setMembershipFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.tableData])
    function handleSubmit(editIndex) {
        if (membershipData['start_date'])
            membershipData['start_date'] = Moment.utc(membershipData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        if (membershipData['end_date'])
            membershipData['end_date'] = Moment.utc(membershipData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        props.onSubmit([membershipData], editID);
        handleClear();
    }
    function handleClear() {
        setMembershipData({})
    }
    const handleMembershipData = (value, key, type) => {
        if (type == 'reference' && value) {
            value = value.key
        }
        else {
            if ((key == 'weightage' || key == 'competitor_list_price') && value)
                value = Number(value)
        }

        setMembershipData({ ...membershipData, [key]: value })
    }
    function handleClearAll() {
        handleClear();
    }
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>Edit Competitor Data</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {membershipFields && membershipFields.length > 0 &&
                                    membershipFields.map((item, index) => (

                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel} required={requiredField.includes(item.current)}>
                                                    {item.current}
                                                </FormLabel>
                                                {item.key == 'start_date' || item.key == 'end_date' ?
                                                    <Grid container justifyContent="space-around">
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        // padding: '0px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        marginTop: 10,
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={membershipData[item.key] ? membershipData[item.key] : null}
                                                                onChange={date => handleMembershipData(date, item.key)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                minDate={item.key == 'end_date' && membershipData['start_date'] ? membershipData['start_date'] : item.key == 'member_end_date' && membershipData['member_start_date'] ? membershipData['member_start_date'] : item.key == 'action_effective_date'}
                                                                format="MM/DD/YYYY"
                                                            />
                                                        </LocalizationProvider>

                                                    </Grid>
                                                    :
                                                    item.drop_down_values && item.drop_down_values.length > 0 ?
                                                        <Select
                                                            value={membershipData[item.key] ? membershipData[item.key] : ''}
                                                            onChange={(e) => handleMembershipData(e.target.value, item.key)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            style={{ marginTop: 8 }}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >   {item.drop_down_values.map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                        </Select>
                                                        :
                                                        item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                            <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key} attributeList={item.drop_down_reference_value ? item.drop_down_reference_value : []} onChange={(value) => handleMembershipData(value, item.key, 'reference')} />
                                                            :
                                                            <OutlinedInput
                                                                value={membershipData[item.key] ? membershipData[item.key] : ''}
                                                                type={item.key == 'weightage' || item.key == 'competitor_list_price' ? 'Number' : 'String'}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => handleMembershipData(e.target.value, item.key)} />
                                                }
                                            </div>
                                        </Grid>

                                    ))
                                }
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} disabled={Object.entries(membershipData).filter(([k, v]) => allowedField.includes(k) && v).length == allowedField.length ? false : true} onClick={handleSubmit}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            : 'Save'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.dataSetupData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        competitorSingleData: state.dataSetupData.competitorSingleData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(editCompetitorData(data, id)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCompetitorData);