import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput, FormLabel, Switch, Dialog, DialogActions, Select, MenuItem, Paper, Checkbox, FormControlLabel, IconButton
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import {
    getCalculationSimulationDataFields, getDynamicQueryFields,
    postDynamicQuery, getDynamicQuerySalesDataFields, getPurchaseDataFields, getDefaultValuesAllData, getDynamicQuery,
    getDynamicQueryFieldsNew, getTableDeterminationData
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { useLocation } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import { HashLoader, RotateLoader } from "react-spinners";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: "0px 5px 0px 5px",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        // marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        // padding: '0px 16px 0px 16px'
        marginTop: '-1rem'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    error: {
        border: '1px solid red !important'
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    paper: { minWidth: window.screen.width * .50 },
    select: {
        width: '100%',
        marginTop: 10
    },
    center: {
        textAlign: 'center'
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const ViewDynamicQuery = props => {
    const classes = useStyles();
    const location = useLocation();
    const [currentPage, setCurrentPage] = React.useState('');

    useEffect(() => {
        var [first, second, third] = (location.pathname).split("/");
        setCurrentPage(third)
    }, [location])

    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [queryData, setQueryData] = React.useState({});
    const [isViewQuery, setIsViewQuery] = React.useState(false);
    const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
    const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
    const [averageFieldsArray, setAverageFieldsArray] = React.useState([]);
    const [outputFieldsArray, setOutputFieldsArray] = React.useState({});
    const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
    const [sortOrderArray, setSortOrderArray] = React.useState([]);
    const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] = React.useState([]);
    const [currentEditId, setCurrentEditId] = React.useState('');
    const [rearrange, setRearrange] = React.useState(false);
    const [dynamicFields, setDynamicFields] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState('');
    const [groupedFields, setGroupedFields] = React.useState('');
    const [tableDeterminationArray, setTableDeterminationArray] = React.useState([]);
    const [tableDesc, setTableDesc] = React.useState('');

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getDynamicQuery(appId);
        setOpen(true);
        props.getDefaultValuesAllData();
        props.getTableDeterminationData();
        if (history.location.dynamicType == 'Re-arrange')
            setRearrange(true)
        else
            setRearrange(false)
    }, []);
    useEffect(() => {
        if (props.tableDetermination && props.tableDetermination.length > 0) {
            setTableDeterminationArray(props.tableDetermination);
        }
    }, [props.tableDetermination]);
    useEffect(() => {
        if (tableDesc && tableDeterminationArray.length > 0) {
            var data = tableDeterminationArray && tableDeterminationArray.filter(item => item.table_description == tableDesc).map(e => e.grouping_required)
            if (data) {
                setGroupedFields(data[0])
            }
            else
                setGroupedFields('')
        }
    }, [tableDesc, tableDeterminationArray]);
    useEffect(() => {
        if (props.dynamicQueryFieldsData) {
            setDynamicFields(props.dynamicQueryFieldsData)
            var concatinated = []
            if (props.dynamicQueryFieldsData.numeric_fields && props.dynamicQueryFieldsData.numeric_fields.length > 0)
                concatinated.push(...props.dynamicQueryFieldsData.numeric_fields)
            if (props.dynamicQueryFieldsData.string_fields && props.dynamicQueryFieldsData.string_fields.length > 0)
                concatinated.push(...props.dynamicQueryFieldsData.string_fields)
            if (props.dynamicQueryFieldsData.date_fields && props.dynamicQueryFieldsData.date_fields.length > 0)
                concatinated.push(...props.dynamicQueryFieldsData.date_fields)
            setApiArrayFieldsConcatinated(concatinated)
        }
    }, [props.dynamicQueryFieldsData]);


    const [tableName, setTableName] = React.useState('');
    useEffect(() => {
        if (props.dynamicQueryDetailsData) {
            if (props.dynamicQueryDetailsData.query_type) {
                if (props.dynamicQueryDetailsData.query_type === 3) {
                    // setCurrentPage('partner-statement')
                } else {
                    // setCurrentPage('dynamic-query')
                }
            }
            // setGroupedFields('')
            props.getDynamicQueryFields(props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.table_description)
            setDescription(props.dynamicQueryDetailsData.query_description);
            setQueryName(props.dynamicQueryDetailsData.format_name);
            setTableDesc(props.dynamicQueryDetailsData.table_description);
            setApplicationType(props.dynamicQueryDetailsData.app_type)
            if (props.dynamicQueryDetailsData.filter_fields)
                setFilterFieldsArray(props.dynamicQueryDetailsData.filter_fields.map((item, i) => item.sort_field));
            setAverageFieldsArray(props.dynamicQueryDetailsData.avg_fields);
            setSumFieldsArray(props.dynamicQueryDetailsData.sum_fields);
            setAggregateFieldsArray(props.dynamicQueryDetailsData.groupby_fields);
            setQueryData({
                'filter_fields': props.dynamicQueryDetailsData.filter_fields ? props.dynamicQueryDetailsData.filter_fields.map((item, i) => item.sort_field) : [],
                'sum_fields': props.dynamicQueryDetailsData.sum_fields ? props.dynamicQueryDetailsData.sum_fields : [],
                'avg_fields': props.dynamicQueryDetailsData.avg_fields ? props.dynamicQueryDetailsData.avg_fields : [],
                'groupby_fields': props.dynamicQueryDetailsData.groupby_fields ? props.dynamicQueryDetailsData.groupby_fields : [],
                'output_fields': props.dynamicQueryDetailsData.output_fields ? props.dynamicQueryDetailsData.output_fields : [],
            })
            setCurrentEditId(props.dynamicQueryDetailsData.id);
            setTableName(props.dynamicQueryDetailsData.table_name);
            if ((props.dynamicQueryDetailsData.filter_fields && props.dynamicQueryDetailsData.filter_fields.length > 0) ||
                (props.dynamicQueryDetailsData.avg_fields && props.dynamicQueryDetailsData.avg_fields.length > 0) ||
                (props.dynamicQueryDetailsData.sum_fields && props.dynamicQueryDetailsData.sum_fields.length > 0) ||
                (props.dynamicQueryDetailsData.groupby_fields && props.dynamicQueryDetailsData.groupby_fields.length > 0) ||
                (props.dynamicQueryDetailsData.sort_fields && props.dynamicQueryDetailsData.sort_fields.length > 0)) {
                setIsViewQuery(true)
            }

            if (props.dynamicQueryDetailsData && props.dynamicQueryDetailsData.sort_fields) {
                var arrayToObject = Object.assign({}, ...props.dynamicQueryDetailsData.sort_fields.map((x) => ({ [x.sort_field]: x.sort_order })));
                setOutputFieldsArray(arrayToObject);
                setSortOrderArray(props.dynamicQueryDetailsData.sort_fields.sort((a, b) => a.sort_order > b.sort_order ? 1 : -1).map((x) => x.sort_field))
            }
        }
    }, [props.dynamicQueryDetailsData]);

    function handleOnSubmit() {
        var unsortedFields = [];
        if (outputFieldsArray || (sumFieldsArray.length > 0 && filterFieldsArray.length === 0
            && outputFieldsArray.length === 0 && aggregateFieldsArray.length === 0)) {
            for (var propName in outputFieldsArray) {
                unsortedFields.push(propName);
                if (outputFieldsArray[propName] === 0) {
                    delete outputFieldsArray[propName];
                }
            }
            var allFields = {
                "filter_fields": filterFieldsArray,
                "sum_fields": sumFieldsArray,
                "avg_fields": averageFieldsArray,
                "sort_fields": outputFieldsArray,
                "output_fields": unsortedFields,
                "groupby_fields": aggregateFieldsArray,
                "format_name": queryName,
                "table_name": tableName,
                "id": currentEditId,
                "query_description": description
            };
            if (currentPage === 'partner-statement') {
                props.onSubmitPartnerStatement(allFields, 'edit', currentPage);
            } else {
                props.onSubmit(allFields, 'edit', currentPage);
            }
        } else {
            alert('Please select atleast one output or only sum field')
        }
    }
    const handleChange = (event, item, type) => {
        if (type === 'filter_fields') {
            if (filterFieldsArray.includes(item)) {
                setFilterFieldsArray(filterFieldsArray.sort(item1 => item1 !== item));
            } else {
                setFilterFieldsArray([...filterFieldsArray, item]);
            }
        }
        if (type === 'aggregate_fields') {
            if (aggregateFieldsArray.includes(item)) {
                if (sumFieldsArray.includes(item)) {
                    if (outputFieldsArray.hasOwnProperty(item)) {
                    }
                } else {
                    setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
                }
            } else {
                setAggregateFieldsArray([...aggregateFieldsArray, item]);
            }
        }
        if (type === 'sum_fields') {
            if (sumFieldsArray.includes(item)) {
                setSumFieldsArray(sumFieldsArray.filter(item1 => item1 !== item));
            } else {
                setSumFieldsArray([...sumFieldsArray, item]);
            }
        }
        if (type === 'avg_fields') {
            if (averageFieldsArray.includes(item)) {
                setAverageFieldsArray(averageFieldsArray.filter(item1 => item1 !== item));
            } else {
                setAverageFieldsArray([...averageFieldsArray, item]);
            }
        }
        if (type === 'output_fields') {
            if (outputFieldsArray.hasOwnProperty(item)) {
                var tempArray = outputFieldsArray;
                delete tempArray[item];
                setOutputFieldsArray({ ...tempArray })
            } else {
                setOutputFieldsArray({ ...outputFieldsArray, [item]: null });

            }
        }
        if (type === 'sort_order') {
            //   setSortOrderArray(event.target.value)
            setOutputFieldsArray({ ...outputFieldsArray, [item]: event.target.value ? parseInt(event.target.value) : null });
            event.preventDefault();
        }
    };
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to={
                            currentPage === 'view-collaboration-portal' ? '/collaboration-portal' :
                                currentPage === 'view-partner-statement' ? '/partner-statement' : '/dynamic-query'
                        }
                    >
                        {currentPage === 'view-partner-statement' ? 'Partner Statement' :
                            currentPage === 'view-collaboration-portal' ? 'On Demand Query' :
                                'On Demand Query'}
                    </Link>
                    <Typography color="textPrimary" variant='h4'>{rearrange ? 'Re-arrange Query' : 'View Query'}</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.container2}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Typography variant="h3" color={'primary'}>
                                        {props.dynamicQueryDetailsData && props.dynamicQueryDetailsData.format_name ? props.dynamicQueryDetailsData.format_name : ''}
                                    </Typography>

                                </div>
                                {apiArrayFieldsConcatinated && apiArrayFieldsConcatinated.length > 0 && isViewQuery ?
                                    <Paper className={classes.root}>
                                        <div style={{ margin: 15 }}>
                                            <TableContainer style={{ overflowX: "initial", top: 50 }}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell align='center' className={classes.tabHead}>Field Name</TableCell>
                                                            <TableCell align='center' className={classes.tabHead}>Filter</TableCell>
                                                            {(groupedFields == true) &&
                                                                <>
                                                                    <TableCell align='center' className={classes.tabHead}>Aggregate</TableCell>
                                                                    <TableCell align='center' className={classes.tabHead}>Average</TableCell>
                                                                    <TableCell align='center' className={classes.tabHead}>Sum</TableCell>
                                                                </>
                                                            }
                                                            <TableCell align='center' className={classes.tabHead}>Output</TableCell>
                                                            <TableCell align='center' className={classes.tabHead} >Sort Order</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody classes={{ root: classes.table }} >
                                                        {apiArrayFieldsConcatinated && apiArrayFieldsConcatinated.length > 0 && isViewQuery &&
                                                            apiArrayFieldsConcatinated
                                                                .filter(item => (queryData['filter_fields'].includes(item.key) || queryData['avg_fields'].includes(item.key) || queryData['sum_fields'].includes(item.key) ||
                                                                    queryData['groupby_fields'].includes(item.key) || queryData['output_fields'].includes(item.key)) && item.display && item)
                                                                .sort(function (a, b) {
                                                                    if (sortOrderArray.indexOf(a.key) < 0 || sortOrderArray.indexOf(b.key) < 0)
                                                                        return sortOrderArray.indexOf(b.key) - sortOrderArray.indexOf(a.key);
                                                                    else
                                                                        return sortOrderArray.indexOf(a.key) - sortOrderArray.indexOf(b.key);
                                                                })
                                                                .map(item => {
                                                                    return (
                                                                        <TableRow key={item.key}>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{item.label/*keyValuePairObject[item]*/}</TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                <Switch
                                                                                    checked={filterFieldsArray && filterFieldsArray.includes(item.key) ? filterFieldsArray.includes(item.key) : ''}
                                                                                    color='primary'
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                    onChange={rearrange ? (e) => handleChange(e, item.key, 'filter_fields') : null}
                                                                                />
                                                                            </TableCell>
                                                                            {(groupedFields == true) &&
                                                                                <>
                                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                        <Switch
                                                                                            checked={aggregateFieldsArray && aggregateFieldsArray.includes(item.key) ? aggregateFieldsArray.includes(item.key) : ''}
                                                                                            color='primary'
                                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                            onChange={rearrange ? (e) => handleChange(e, item.key, 'aggregate_fields') : null}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                        <Switch
                                                                                            checked={averageFieldsArray && averageFieldsArray.includes(item.key) ? averageFieldsArray.includes(item.key) : ''}
                                                                                            color='primary'
                                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                            onChange={rearrange ? (e) => handleChange(e, item.key, 'avg_fields') : null}
                                                                                            disabled={dynamicFields && dynamicFields.numeric_fields &&
                                                                                                dynamicFields.numeric_fields.findIndex(({ key }) => key == item.key) > -1 ? false : true}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                        <Switch
                                                                                            checked={sumFieldsArray && sumFieldsArray.includes(item.key) ? sumFieldsArray.includes(item.key) : ""}
                                                                                            color='primary'
                                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                            onChange={rearrange ? (e) => handleChange(e, item.key, 'sum_fields') : null}
                                                                                            disabled={dynamicFields && dynamicFields.numeric_fields &&
                                                                                                dynamicFields.numeric_fields.findIndex(({ key }) => key == item.key) > -1 ? false : true}
                                                                                        />
                                                                                    </TableCell>
                                                                                </>
                                                                            }
                                                                            <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                <Switch
                                                                                    checked={outputFieldsArray && outputFieldsArray.hasOwnProperty(item.key) ? outputFieldsArray.hasOwnProperty(item.key) : ''}
                                                                                    color='primary'
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                    onChange={rearrange ? (e) => handleChange(e, item.key, 'output_fields') : null}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                                <TextField
                                                                                    key={['recipient', item.key].join('_')}
                                                                                    type="number"
                                                                                    variant="outlined"
                                                                                    value={outputFieldsArray[item.key] || outputFieldsArray[item.key] === 0 ? outputFieldsArray[item.key] : ''}
                                                                                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                                                    onChange={rearrange ? (e) => handleChange(e, item.key, 'sort_order') : null}
                                                                                />
                                                                            </TableCell>

                                                                        </TableRow>
                                                                    );
                                                                })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Paper>
                                    :
                                    <Typography variant='h4'>
                                        There is no data to show now.
                                    </Typography>
                                }
                            </Grid>

                        </Grid>
                    </div>
                    {rearrange &&
                        <div className={classes.buttonRoot}>
                            <Button variant="outlined" color="primary" className={classes.button} onClick={() => history.push('/dynamic-query')} >
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                                Submit
                            </Button>
                        </div>
                    }
                </div >
            </div >
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getDynamicQuerySalesDataFields: () => dispatch(getDynamicQuerySalesDataFields()),
        getCalculationSimulationDataFields: () => dispatch(getCalculationSimulationDataFields()),
        onSubmit: (data, edit, currentPage) => dispatch(postDynamicQuery(data, edit, currentPage)),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
        getPurchaseDataFields: () => dispatch(getPurchaseDataFields()),
        getDynamicQueryFields: (applicationType, sourceDataType, functionalityName) => dispatch(getDynamicQueryFieldsNew(applicationType, sourceDataType, functionalityName)),
        onSubmitPartnerStatement: (data) => dispatch(postDynamicQuery(data, null, 'partner')),
        getTableDeterminationData: () => dispatch(getTableDeterminationData()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsDataNew,
        calculationFieldsData: state.customerData.calculationSimulationData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        dynamicQueryDetailsData: state.operationalReportsData.dynamicQueryDetailsData,
        tableDetermination: state.operationalReportsData.tableDetermination,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDynamicQuery);