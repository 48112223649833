import React from 'react';
import { connect } from 'react-redux';
import PricingAnalytics from './PricingAnalytics';


const CustomerLevel = props => {

    return (
        <div>
            <PricingAnalytics/>
        </div >
    );
}

export default connect(null,null)(CustomerLevel);