import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    TableRow,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { loadValuesBasedOnAppTypeAndField, getSingleSalesBasis, getDefaultValuesCustomerXRef, getCustomerMasterData, getMaterialMasterData, getSupplierMasterData, getDefaultValuesCustomer, getDefaultValues, addSalesBasis, allowedApps, getLblDispDesFieldValue, getEmployeeData } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        height: 35
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },

    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        marginTop: 10,
        height: 35,
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    },
    inputTwoLine: {
        width: '100%',
        marginTop: 8,
        paddingLeft: 10
    },
}));

const EditSalesBasis = props => {
    const classes = useStyles();
    const [contractType, setContractType] = React.useState('');
    const [attributeName, setAttributeName] = React.useState('');
    const [universalRuleID, setUniversalRuleID] = React.useState(0);

    function handleAttributeValue(event) {
        setAttributeValue(event);
    }
    function handleDeleteAttributeValue(value) {
        var deleteAttValue = attributeValue.filter(item => item !== value)
        setAttributeValue(deleteAttValue)
    }

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSalesBasisDataSingle(appId)
        props.onLoadingDefaultAll();
    }, []);

    useEffect(() => {
        if (props.salesBasisSingle && Object.keys(props.salesBasisSingle).length > 0) {
            props.loadSourceDataType(props.salesBasisSingle['app_type'], 'source_data_type');
            setApplicationType(props.salesBasisSingle['app_type']);
            setContractType(props.salesBasisSingle['contract_type']);
            setSourceDataType(props.salesBasisSingle['data_source_type']);
            setAttributeName(props.salesBasisSingle['attribute_name']);
            setOptionData(props.salesBasisSingle['option']);
            setEffectiveStartDate(props.salesBasisSingle['effective_start_date']);
            setEffectiveEndDate(props.salesBasisSingle['effective_end_date']);
            setAttributeValue(props.salesBasisSingle['attribute_value']);
            setConditionID(props.salesBasisSingle['condition_id']);
            setUniversalRuleID(props.salesBasisSingle['ID']);
            var data = {
                "data_source": "contract_header",
                "application_type": props.salesBasisSingle['app_type']
            }
            props.fieldDesigner(data, 'post');
            if (props.salesBasisSingle['app_type'] && props.salesBasisSingle['data_source_type']) {
                props.onLoadingDefaultAttributeName(props.salesBasisSingle['app_type'], props.salesBasisSingle['data_source_type']);
            }
            if ((props.salesBasisSingle['attribute_name'] === 'customer_number' || props.salesBasisSingle['attribute_name'] === 'ship_to_party' || props.salesBasisSingle['attribute_name'] === 'bill_to_party'
                || props.salesBasisSingle['attribute_name'] === 'sold_to_party' || props.salesBasisSingle['attribute_name'] === 'payer')) {
                props.getCustomerMasterData();
            }
            else if (props.salesBasisSingle['attribute_name'] === 'material_number') {
                props.getMaterialMasterData();
            }
            else if (props.salesBasisSingle['attribute_name'] === 'supplier_number') {
                props.getSupplierMasterData();
            }
            else {
                props.getEmployeeData();
            }

        }
    }, [props.salesBasisSingle]);
    useEffect(() => {
        props.allowedApps();

    }, []);

    async function handleAttributename(event) {
        setAttributeValue('');
        console.log(event)
        if ((event.target.value === 'customer_number' || event.target.value === 'ship_to_party' || event.target.value === 'bill_to_party'
            || event.target.value === 'sold_to_party' || event.target.value === 'payer')) {
            await props.getCustomerMasterData();
        }
        else if (event.target.value === 'material_number') {
            await props.getMaterialMasterData();
        }
        else if (event.target.value === 'supplier_number') {
            await props.getSupplierMasterData();
        }
        else {
            await props.getEmployeeData();
        }
        setAttributeName(event.target.value);
    }


    const [attributeNameArray, setAttributeNameArray] = React.useState([]);
    const [attributeValue, setAttributeValue] = React.useState([]);
    const [attributeValuesArray, setAttributeValuesArray] = React.useState([]);
    const [attributeValuesArrayMaterialNumber, setAttributeValuesArrayMaterialNumber] = React.useState([]);
    const [conditionID, setConditionID] = React.useState('');

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var customerTempArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
                customerTempArray.push(item.customer_name)
            })
            setAttributeValuesArray(tempArray);
        }
    }, [props.customerMasterData])

    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var materialTempArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
                materialTempArray.push(item.material_description)
            })
            setAttributeValuesArrayMaterialNumber(tempArray)
            // setMaterialNameListArray(tempNameArray);
        }

    }, [props.materialMasterData])
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setAttributeValuesArrayMaterialNumber(tempArray)
            // seSupplierNameArray(tempNameArray)
        }
    }, [props.supplierMasterData])


    function handleOnSubmit() {
        var data = {
            "contract_type": contractType,
            "app_type": applicationType,
            "data_source_type": sourceDataType,
            "attribute_name": attributeName,
            "option": optionData,
            "effective_start_date": effectiveStartDate,
            "effective_end_date": effectiveEndDate,
            "attribute_value": attributeValue,
            "condition_id": parseInt(conditionID)
        };
        props.onSubmit(data, 'put', universalRuleID);

    }
    function handleClearAll() {
        setApplicationType('');
        setSourceDataType('');
        setAttributeName([]);
        setOptionData('');
        setEffectiveStartDate(null);
        setEffectiveEndDate(null);
        setAttributeValue('');
        setConditionID('');
        setContractType('')
    }

    const [allowedApps, setAllowedApps] = React.useState([]);
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [optionData, setOptionData] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);

    const optionDataArray = [
        { key: 'EQUAL', value: 'EQUAL' },
        { key: 'NOT EQUAL', value: 'NOT EQUAL' },

    ];
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && props.dropdownData.field_label_attributes.contract_type) {
            const contractTypeValues = props.dropdownData.field_label_attributes.contract_type.drop_down_values || [];
            setContractTypeArray(contractTypeValues);
        }
    }, [props.dropdownData]);
    // useEffect(() => {
    //     if (props.dropdownDataAll && props.dropdownDataAll.records && props.dropdownDataAll.records.length > 0) {
    //         props.dropdownDataAll.records.map((item) => {
    //             if (item.field_id === 'contract_type' && item.app_source_type === applicationType) {
    //                 setContractTypeArray(item);
    //             }
    //         })
    //     }
    // }, [props.dropdownDataAll]);
    useEffect(() => {
        if (props.attributeNameData && props.attributeNameData.records && props.attributeNameData.records.length > 0) {
            props.attributeNameData.records.map((item) => {
                if (item.field_id === 'attribute_name') {
                    setAttributeNameArray(item);
                }
            })
        }
    }, [props.attributeNameData]);
    function handleEffectiveStartDate(event) {
        if (event && event.isValid())
            setEffectiveStartDate(event.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setEffectiveStartDate(event);
    }
    function handleEffectiveEndDate(newValue) {
        if (newValue && newValue.isValid())
            setEffectiveEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setEffectiveEndDate(newValue)
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        setAttributeName('')
        props.onLoadingDefaultAttributeName(applicationType, e.target.value);
    }
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])

    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    const handleApplicationType = (e) => {
        setSourceDataTypeDataFromAPI([]);
        props.onLoadingDefaultAll();
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
        setContractType('')
        setSourceDataType('')
        var data = {
            "data_source": "contract_header",
            "application_type": e.target.value
        }
        props.fieldDesigner(data, 'post');

    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/sales-basis'
                >
                    Global Eligibility
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Global Eligibility</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Application Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={applicationType}
                                                onChange={handleApplicationType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ height: 36, marginTop: 2 }}
                                            >
                                                {allowedApps
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Contract Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={contractType}
                                                onChange={(e) => setContractType(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ height: 36, marginTop: 2 }}
                                            >
                                                {contractTypeArray && contractTypeArray.length > 0 &&
                                                    contractTypeArray
                                                        .map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={index}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Source Data Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={sourceDataType}
                                                onChange={handleSourceDataType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ height: 36, marginTop: 2 }}
                                            >
                                                {sourceDataTypeDataFromAPI
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv}>
                                        Conditon ID
                                    </FormLabel>
                                    <OutlinedInput
                                        value={conditionID}
                                        type={"number"}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setConditionID(e.target.value)}
                                        placeholder={props.placeholder}
                                        style={{
                                            marginTop: 1,
                                            height: 36
                                        }} />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Attribute Name
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={attributeName}
                                                onChange={(e) => handleAttributename(e)}
                                                displayEmpty
                                                style={{ textTransform: 'capitalize' }}
                                                MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null, PaperProps: { style: { maxHeight: 300 } }, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                                {attributeNameArray && attributeNameArray.drop_down_value_keys && attributeNameArray.drop_down_value_keys.length > 0 &&
                                                    attributeNameArray.drop_down_value_keys
                                                        .map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Option
                                        </FormLabel>
                                        <Select
                                            value={optionData}
                                            onChange={(e) => setOptionData(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {optionDataArray.map((item) => {
                                                return (
                                                    <MenuItem value={item.key} key={item.key}>
                                                        {item.value}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '1px 13px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            // marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={effectiveStartDate}
                                                    onChange={date => handleEffectiveStartDate(date)}
                                                    renderInput={(props) => <TextField {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            End Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '1px 13px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            // marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={effectiveEndDate}
                                                    onChange={date => handleEffectiveEndDate(date)}
                                                    renderInput={(props) => <TextField {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>

                                <Grid item md={2}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Attribute Value
                                        </FormLabel>
                                        {attributeName === 'sold_to_party'
                                            || attributeName === 'ship_to_party'
                                            || attributeName === 'payer'
                                            || attributeName === 'bill_to_party'
                                            || attributeName === 'customer_number'
                                            // || attributeName === 'material_number'
                                            ?
                                            <div className={classes.selectRoot}>
                                                <Select
                                                    value={attributeValue}
                                                    MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null, PaperProps: { style: { maxHeight: 300 } }, autoFocus: true }}
                                                    // onChange={(e) => setAttributeValue(e.target.value)}
                                                    onChange={(e) => setAttributeValue([e.target.value])}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                // style={{ marginTop: 10 }}
                                                >
                                                    <MenuItem value="">
                                                    </MenuItem>
                                                    {attributeValuesArray
                                                        .map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>

                                            </div>
                                            :
                                            attributeName === 'material_number'
                                                || attributeName === 'supplier_number'

                                                ?
                                                <div className={classes.selectRoot}>
                                                    <Select
                                                        value={attributeValue}
                                                        onChange={(e) => setAttributeValue([e.target.value])}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    // multiple
                                                    >
                                                        <MenuItem value="">
                                                        </MenuItem>
                                                        {attributeValuesArrayMaterialNumber
                                                            .map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>

                                                </div>
                                                :
                                                <ChipInput data={attributeValue} onChange={handleAttributeValue} />
                                            // <ChipInput
                                            //     classes={{
                                            //         root: classes.rootContainer,
                                            //         chip: classes.chip,
                                            //         input: classes.input,
                                            //         inputRoot: classes.inputRoot,
                                            //         label: classes.chipLabel,
                                            //         chipContainer: classes.chipContainer,
                                            //     }}
                                            //     value={attributeValue}
                                            //     onAdd={(chips) => handleAttributeValue(chips)}
                                            //     onDelete={(chip) => handleDeleteAttributeValue(chip)}
                                            //     disableUnderline={true}
                                            // />

                                        }
                                    </div>
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card>



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} style={{ marginRight: 10 }}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={
                            !attributeName || !applicationType || !contractType || !sourceDataType || !effectiveStartDate || !effectiveEndDate
                                // || !attributeValue.length > 0 
                                || !optionData ? true : false
                        }
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(addSalesBasis(data, type, id)),
        onLoadingDefaultAll: () => dispatch(getDefaultValues('noKeyWords')),
        onLoadingDefaultAttributeName: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        getSalesBasisDataSingle: (id) => dispatch(getSingleSalesBasis(id)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),

    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
        dropdownDataAll: state.addMultipleConfigurationData.multipleConfigurationData,
        attributeNameData: state.addMultipleConfigurationData.attributeNameData,
        salesBasisSingle: state.customerData.salesBasisSingle,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        employeeMasterData: state.customerData.employeeMasterData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
        supplierMasterData: state.customerData.supplierMasterData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSalesBasis);