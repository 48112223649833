import React from 'react';
import { PostingsMaintenanceForm } from './containers';

const PostingsMaintenance = props => {
    
    return (
        <PostingsMaintenanceForm />
    );

};

export default PostingsMaintenance;