import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    Checkbox, Dialog, DialogTitle, Box
} from '@mui/material';
import { connect } from 'react-redux';
// import { getContractViewData, massUpdateContractAction } from '../../../redux/actions';
import {deleteFinancialPosting, getPostings } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from "@mui/material/Tooltip";
import { Visibility, Close } from '@mui/icons-material';

import HashLoader from 'react-spinners/HashLoader'
import ViewContract from '../../ViewContract/ViewContract';
import { MessageDialogue } from '../../MessageDialogue';
import { logBase } from '@syncfusion/ej2-react-charts';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16,
        marginBottom: 16
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    spinner: {
        height: '100vh'
    },
    table: {
        height: "100%",
        // width: "100%"
    },
    list: {},
    thead: {},
    tbody: {
        width: "100%",
        height: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        // alignItems: "center",
        boxSizing: "border-box",
        //maxWidth: "100%",
        //width: "100%"
    },
    paper: { minWidth: window.screen.width, height: '100%' },
    headerRow: {},
    cell: {
        display: "block",
        flexGrow: 0,
        flexShrink: 0
    },
    tableCell: {
        paddingLeft: 10,
        paddingRight: 10,
        textTransform: 'capitalize',
        flexWrap: "nowrap",
        //height:'auto'
    },
    expandingCell: {
        flex: 1
    },
}));


const PostingsMaintenanceFormResult = props => {

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [selected, setSelected] = React.useState([]);
    const [selectedApproved, setSelectedApproved] = React.useState([]);
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [postings, setPostings] = React.useState([])
    const [selectedPostings, setSelectedPostings] = React.useState([])
    const handleCheckAll = () => {
        var IDS = [];
        if (!isCheckAll) {
            setSelected(postings.map(post => post.id));
            setSelectedPostings(postings);
        } else {
            setSelected([]);
            setSelectedPostings([]);
        }
        setIsCheckAll(!isCheckAll);
    };

    useEffect(() => {
        console.log("api data",props.postingsData);
        if (Array.isArray(props.postingsData) && props.postingsData.length > 0) {
          setPostings(props.postingsData);
        }
        else{
            setPostings([]);
        }
      }, [props.postingsData]);
    //   console.log("stored data",postings);
      


    // useEffect(() => {
    //     let data = JSON.parse(sessionStorage.getItem("DynamicFieldValues"))
    //     props.getPostings(data)
    //   },[]);

    const handleDelete = () => {
        const adjustedData = selectedPostings.map(({ id, ...rest }) => {
            return {
                ...rest,
                contract_number: [rest.contract_number],
                posting_type: [rest.posting_type]
            };
        });
        props.onSubmit(adjustedData)  
    };
    // const handleClick = (event, id, item) => {
    //     const selectedIndex = selected.indexOf(id);
    //     let newSelected = [];
    //     let newSelectedPostings = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, id);
    //         newSelectedPostings = newSelectedPostings.concat(selectedPostings, item);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //         newSelectedPostings = newSelectedPostings.concat(selectedPostings.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //         newSelectedPostings = newSelectedPostings.concat(selectedPostings.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(
    //             selected.slice(0, selectedIndex),
    //             selected.slice(selectedIndex + 1),
    //         );
    //         newSelectedPostings = newSelectedPostings.concat(
    //             selectedPostings.slice(0, selectedIndex),
    //             selectedPostings.slice(selectedIndex + 1),
    //         );
    //     }

    //     setSelected(newSelected);
    //     setSelectedPostings(newSelectedPostings);
    // };
    const handleClick = (event, id, item) => {
        event.stopPropagation();
    
        const selectedIndex = selected.indexOf(id);
        let newSelected = [...selected];
        let newSelectedPostings = [...selectedPostings];
    
        if (selectedIndex === -1) {
            newSelected.push(id);
            newSelectedPostings.push(item);
        } else {
            newSelected.splice(selectedIndex, 1);
            newSelectedPostings.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
        setSelectedPostings(newSelectedPostings);
    };
  
    // console.log("selected postings",selectedPostings);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const headings = postings && postings.length > 0 ? Object.keys(postings[0]).filter(item => item !== 'id') : [];
    

    
    
    return (
        <div className={clsx(classes.root, props.className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div className={classes.headerContainer} style={{ justifyContent: 'space-between' }}>
                    <Typography variant="h2">Postings Maintenance Results</Typography>
                    {postings && postings.length > 0 &&
                        <div className={classes.buttonRoot}>
                            <Button
                                variant="contained"
                                color="primary"
                                classes={{
                                    startIcon: !props.isDesktop && classes.startIcon,
                                    root: !props.isDesktop && classes.container
                                }}
                                onClick={handleDelete}
                                startIcon={<CheckIcon />}
                                style={{ margin: 10 }}
                                disabled={!(selected.length > 0 || selectedApproved.length > 0)}
                            >
                                Delete
                            </Button>
                        </div>
                    }
                </div>
                {postings && postings.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            onChange={handleCheckAll}
                                        />
                                    </TableCell>
                                    {headings.map(item => (
                                        <TableCell align='center' key={item} className={classes.cID} style={{ textTransform: 'capitalize' }}>
                                            {item.replace(/_/g, ' ')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.table}>
                                {postings.map((item, i) => (
                                    <TableRow key={item.id} className={classes.hover} hover onClick={(event) => handleClick(event, item.id, item)}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color='primary'
                                                checked={isSelected(item.id)}
                                                onClick={(event) => {
                                                event.stopPropagation(); // Prevents the event from affecting other elements
                                                handleClick(event, item.id, item);
                                                }}
                                            />
                                        </TableCell>
                                        {headings.map((key) => (
                                            <TableCell align='center' key={key}>
                                                {item[key]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to Delete.
                    </Typography>
                }
            </LoadingOverlay>
        </div>
    );
};


const mapStateToProps = state => {
    return {
        loading: state.addContractData.loading,
        postingsData: state.addMultipleConfigurationData.postingsData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(deleteFinancialPosting(data)),
        getPostings: (data) => dispatch(getPostings(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostingsMaintenanceFormResult);


