import React from 'react';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Table, TableCell,
    TableHead, TableRow, TableContainer, TableBody, IconButton,
    Button, Dialog, DialogActions, DialogContent, FormLabel
} from '@mui/material';
import { connect } from 'react-redux';
import Close from '@mui/icons-material/Close';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
   
    paperContainer: {
        minWidth: window.screen.width * .40,
        padding: "10px 30px 10px 30px"

    },
    
}));
const MessageDialogue = props => {

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    function handleError() {
       props.onChange()
    }

    return (
        <Dialog
            //fullWidth
            maxWidth="xl"
            open={props.open}
            classes={{ paper: classes.paperContainer }}
        >   <DialogActions style={{ justifyContent: 'space-between', borderBottom: '2px solid #C0C0C0' }}>
                <FormLabel style={{ fontSize: 18 }}>Message Log </FormLabel>
                <IconButton classes={{ root: classes.IconButton }} onClick={handleError} size="large">
                    <Close color="disabled" />
                </IconButton>
            </DialogActions>

            <DialogContent classes={{
                root: { padding: '43px 37px 0px 0px !important' }
            }}>
               {console.log(props.messageList)}
                {((props.messageList && props.messageList.messageLog && props.messageList.messageLog.length > 0)) ?
                    <div style={{ height:'100%'}}>
                    <TableContainer style={{ overflowY: "scroll" }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center'>Functionality</TableCell>
                                    <TableCell align='center'>Transaction Number</TableCell>
                                    <TableCell align='center'>Input Type</TableCell>
                                    <TableCell align='center'>Input Number</TableCell>
                                    <TableCell align='center'>Message Type</TableCell>
                                    <TableCell align='center'>Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>

                                {props.messageList && props.messageList.messageLog && props.messageList.messageLog
                                    .map(item => {

                                        return (

                                            <StyledTableRow key={item.id} >
                                                <TableCell align='center' classes={{ root: classes.hover }}>{item.functionality}</TableCell>
                                                <TableCell align="center" >{item.transaction_number}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.hover }}>{item.input_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.hover }}>{item.input_number}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.hover }}>{item.message_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.hover }}>{item.message_description}</TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </div>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
                }
            </DialogContent>
            <DialogActions style={{justifyContent:'flex-end'}}>
                            <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                    </DialogActions>
        </Dialog>
    );
};


export default connect(null, null)(MessageDialogue);