import React, { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import {
    Container,
    Grid,
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography, Link,
    TextField,
    ButtonGroup,
    CardHeader,
    IconButton,
    Select,
    MenuItem,
    FormLabel,
} from '@mui/material';
import { LabelText } from '../../components/Inputs';
import { getProfile, editProfile, uploadProfileImage, resetPassword } from '../../redux/actions';
import { Done, PowerSettingsNew } from '@mui/icons-material';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { CustomThemeContext } from '../../theme/CustomThemeProvider'
import Compress from "browser-image-compression";
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Skeleton, Stack } from '@mui/material';
import { history } from '../../components/Helpers';
import PasswordStrengthBar from 'react-password-strength-bar';
import secureLocalStorage from "react-secure-storage";
import { ColorPicker, createColor, ColorBox } from "material-ui-color";
import clsx from 'clsx';
import typography from '../../theme/typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import momentTZ from 'moment-timezone';
import { CountryDropdown } from 'react-country-region-selector';
import { RingLoader } from 'react-spinners';
const timeZonesList = momentTZ.tz.names() ? momentTZ.tz.names() : '';

const useStyles = makeStyles(theme => ({
    avatar: {
        height: 150,
        width: 150,
        marginBottom: 10,
    },
    profileName: {
        marginTop: 10
    },
    zeroPadding: {
        padding: 0
    },
    underline: {
        borderBottom: '1px solid blue',
        padding: '3px 0px 3px'
    },
    textField: {
        paddingLeft: 10,
        backgroundColor: 'white',
    },
    logoutDiv: {
        display: 'flex',
        flexDirection: 'row'
    },
    root: {
        backgroundColor: theme.palette.white,
        //border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        padding: '20px 16px',
        "&.ColorPicker-MuiPopover-paper ": {
            bottom: '100px',
            right: '100px',
            top: 'auto !important',
            left: 'auto !important'
        }
    },
    themeButton: {
        maxwidth: 80,
        maxHeight: 80
    },
    ColorPicker: {
        position: "absolute",
        zIndex: 1,
        right: 50,
        bottom: 50,
        width: 100,
        height: 100,
        '&.ColorPicker-MuiPopover-paper': {
            bottom: '100px',
            right: '100px',
            top: 'auto !important',
            left: 'auto!important',
        }
    },
    colorPickerRoot: {
        height: '100px !important',
        width: 100,
    },
    gridContainer: {
        //paddingTop: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    boxContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 13,
        padding: 5
    },
    label: {
        padding: 10
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.h2.fontSize,
        marginBottom: 10,
        // marginTop: 5
    },
    themeContainer: {

    },
    selectedItem: {
        width: '50%'
    }
}));

const ProfileView = (props) => {
    //theme
    const theme = useTheme();
    const { currentTheme, setTheme } = useContext(CustomThemeContext)
    let inputFile = React.createRef();
    const [country, setCountry] = React.useState('');
    const [language, setLanguage] = React.useState('');
    const [timezone, setTimezone] = React.useState('');
    const languageArray = [
        ' Arabic', 'English', 'French', 'German', 'Hindi', 'Spanish', 'Japanese'
    ]
    const { className } = props;
    const colorCode = [{ key: 'violet', value: '#8F00FF' }, { key: 'green', value: '#264F1B' }, { key: 'light', value: '#32009A' }, { key: 'yellow', value: '#FFC400' }, { key: 'dark', value: '#0B3662' }]

    const handleThemeChange = (color, type) => {
        localStorage.setItem('appTheme', 'color')
        var code = colorCode.filter((item, i) => item.key == color).map(item => item.value)
        //if(type=='global-color')

        // setTheme(color)
        if (type == 'theme') {
            setColor(...code)
        }
        else {
            setGlobalColor(...code)
            localStorage.setItem('navclr', ...code)
        }

    }
    const classes = useStyles();
    const [editMode, setEditMode] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [website, setWebsite] = React.useState('');
    const [organization, setOrganization] = React.useState('');
    const [dialog, setDialog] = useState(false);
    const [profileDataAPI, setProfileDataAPI] = React.useState([]);
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [reTypePasswod, setReTypePassword] = React.useState('');
    const [color, setColor] = useState(createColor("#000"));
    const [activeTheme, setActiveTheme] = useState(createColor("#000"));
    const [globalColor, setGlobalColor] = useState(createColor("#000"));
    const [open, setOpen] = React.useState(false);
    const [currentID, setCurrentID] = React.useState(0);
    const handleEditProfile = () => {
        setUserName(props.profileData.username);
        setEmail(props.profileData.email);
        setWebsite(props.profileData.organization.website);
        setOrganization(props.profileData.organization.website);
        setEditMode(true);
    }
    const handleUserName = (event) => {
        setUserName(event.target.value);
    }
    const handleEmail = (event) => {
        setEmail(event.target.value);
    }
    const handleWebsite = (event) => {
        setWebsite(event.target.value);
    }
    const handleOrganization = (event) => {
        setOrganization(event.target.value);
    }
    const submitEdit = () => {

        var formData = {
            "username": userName,
            "email": email,
            "website": website,
            "organization": organization,
            "color_theme": color,
            "global_color": globalColor
        };
        for (var propName in formData) {
            if (formData[propName] === '') {
                delete formData[propName];
            }
        }
        props.editProfile(props.profileData.id, formData);
    }
    async function handleImageUpload(event) {
        const file = event.target.files[0]
        const options = {
            maxSizeMB: .5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        Compress(file, options)
            .then(compressedBlob => {
                compressedBlob.lastModifiedDate = new Date()
                const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })
                const data = new FormData();
                data.append('image', convertedBlobFile);
                props.uploadProfileImage(secureLocalStorage.getItem('dYtz').id, data);
            })
            .catch(e => {
            })
    }
    const handleRemoveUpload = () => {
        props.uploadProfileImage(secureLocalStorage.getItem('dYtz').id, '', 'delete');
    }
    const handleReset = () => {
        setDialog(true);
    }
    const handleDialog = (status) => {
        if (status) {
            var criteria = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$")
            if (criteria.test(newPassword)) {
                props.resetPassword(oldPassword, newPassword);
            } else if (newPassword !== reTypePasswod) {
                alert(' Password MisMatch')
            }
            else {
                alert(' Password must contain atleast 8 characters including Uppercase,Lowercase, Numbers and Symbols.')
            }
            setOldPassword('');
            setNewPassword('');
            setReTypePassword('');
        } else {
            setOldPassword('');
            setNewPassword('');
            setReTypePassword('');
            setDialog(false);
        }
    }
    function runQuery() {
        console.log(inputFile)
        //inputFile.current.click();
    }
    const triggerLogout = () => {
        localStorage.setItem('loggedIn', false)
        localStorage.clear();
        secureLocalStorage.clear();
        history.push('/login');
    }
    useEffect(() => {
        props.getProfile();
    }, []);
    useEffect(() => {
        if (props.profileData && Object.keys(props.profileData).length > 0) {
            setCurrentID(props.profileData.id)
            setProfileDataAPI(props.profileData)
            setTimezone(props.profileData.timezone)
            setCountry(props.profileData.country)
            setLanguage(props.profileData.language)
            setUserName(props.profileData.username);
            setEmail(props.profileData.email);
            setColor(props.profileData.color_theme)
            setGlobalColor(props.profileData.global_color)
            localStorage.setItem('navclr', props.profileData.global_color)

        }
    }, [props.profileData]);
    const handleChange = (value, type) => {
        setOpen(false)
        if (type == 'theme')
            setColor('#' + value.hex);
        else
            setGlobalColor('#' + value.hex);
        localStorage.setItem('dynamic', '#' + value.hex);

    };
    function handleCustomized() {
        setOpen(true)
    }
    const onSave = () => {

        var formData = {
            "username": userName,
            "email": email,
            "website": website,
            "organization": organization,
            "color_theme": color,
            "global_color": globalColor,
            "country": country,
            "language": language,
            "timezone": timezone

        };
        props.editProfile(currentID, formData);
        props.getProfile();
        history.goBack();
    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={3}>
                        <Grid
                            item
                            lg={4}
                            md={6}
                            xs={12}
                        >
                            <Card variant="outlined" style={{ marginBottom: 20 }}>
                                <CardContent>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Avatar
                                            className={classes.avatar}
                                            src={profileDataAPI && profileDataAPI.profile_image_path}
                                        />
                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h3"
                                                className={classes.profileName}
                                            >
                                                {profileDataAPI && profileDataAPI.username}
                                            </Typography>
                                            {/* <IconButton size='small'
                                                variant="contained"
                                                component="label"
                                                style={{ marginLeft: 10 }}
                                                onClick={triggerLogout}
                                            >
                                                <PowerSettingsNew fontSize="small" style={{ color: '#FF0040' }} />
                                            </IconButton> */}
                                        </div>
                                    </Box>
                                </CardContent>
                                <Divider
                                    sx={{
                                        opacity: "0.6"
                                    }} />
                                <CardActions style={{ padding: '15px 8px', height: '6rem' }}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        variant="contained"
                                        component="label"
                                        style={{ background: color }}
                                    >
                                        Change Picture
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={handleImageUpload}
                                            accept="image/x-png,image/jpg,image/jpeg"
                                        />
                                    </Button>
                                    <Button
                                        color="secondary"
                                        fullWidth
                                        variant="contained"
                                        component="label"
                                        onClick={handleRemoveUpload}
                                        style={{ color: color }}
                                    >
                                        Remove
                                    </Button>
                                </CardActions>
                            </Card>
                            <Card variant="outlined" style={{ width: '100%', height: '22rem' }}>
                                <CardHeader
                                    title={'Reset Password'}
                                    classes={{
                                        title: classes.title,
                                    }}
                                    titleTypographyProps={{ variant: 'h3', color: theme.palette.primary.main }}
                                    style={{ /*fontSize: 16,*/ padding: '5px 0px 15px 10px', margin: 0, color: theme.palette.primary.main }}
                                />
                                <CardContent style={{ padding: '0px 16px 16px 16px' }}>
                                    <Typography variant="h4" width='100%'>
                                        <div>
                                            <TextField
                                                fullWidth
                                                name="password"
                                                type="password"
                                                value={oldPassword}
                                                variant="outlined"
                                                InputProps={{
                                                    className: classes.textField,
                                                }}
                                                placeholder="Old Password"
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                            <TextField
                                                fullWidth
                                                placeholder="New Password"
                                                name="new password"
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                type="password"
                                                value={newPassword}
                                                variant="outlined"
                                                InputProps={{
                                                    className: classes.textField,
                                                }}
                                                style={{ marginTop: 15 }}
                                            />
                                            <TextField
                                                fullWidth
                                                placeholder="Re-type Password"
                                                name="re-type password"
                                                onChange={(e) => setReTypePassword(e.target.value)}
                                                type="password"
                                                value={reTypePasswod}
                                                variant="outlined"
                                                InputProps={{
                                                    className: classes.textField,
                                                }}
                                                style={{ marginTop: 15 }}
                                            />
                                            <div style={{ fontSize: 12, margin: '10px 0px 10px 0px', color: 'grey' }}>
                                                Password must contain atleast 8 characters including Uppercase, Lowercase, Numbers and Symbols.
                                            </div>
                                            <PasswordStrengthBar
                                                password={newPassword}
                                                minLength={8}
                                            />
                                        </div>
                                    </Typography>

                                </CardContent>
                                <CardActions style={{ padding: '15px 8px' }}>
                                    <div className={classes.gridContainer}>
                                        <Button onClick={() => handleDialog(false)} autoFocus color="primary" variant="outlined" style={{ marginRight: 10 }}>
                                            Cancel
                                        </Button>
                                        <Button onClick={() => handleDialog(true)} color="primary" autoFocus variant="contained" disabled={newPassword.length >= 8 && oldPassword != newPassword && oldPassword ? false : true} >
                                            Yes
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>

                        </Grid>
                        <Grid
                            item
                            lg={8}
                            md={6}
                            xs={12}
                        >
                            {!editMode ?
                                <div>
                                    <Card variant="outlined" style={{ marginBottom: 10, height: '20.5rem' }}>
                                        <CardHeader
                                            title={'Personal Information'}
                                            classes={{
                                                title: classes.title,
                                            }}
                                            // titleTypographyProps={{ variant: 'h5' }}
                                            // style={{ padding: '5px 0px 15px 15px', margin: 0 }}
                                            style={{ marginTop: '-0.5rem' }}
                                        />
                                        <CardContent >
                                            <Grid
                                                container
                                                style={{ marginLeft: '-3rem' }}
                                                spacing={1}
                                            // style={{ padding: '0px 16px 10px 16px' }}
                                            >
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // xs={2} sm={4} md={4}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    <LabelText style={{ marginLeft: '4rem' }} heading='User Name' data={profileDataAPI && profileDataAPI.username}
                                                        className={classes.zeroPadding}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    <LabelText style={{ marginLeft: '4.5rem' }} heading='User ID' data={profileDataAPI && profileDataAPI.id} className={classes.zeroPadding} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    <LabelText style={{ marginLeft: '4rem' }} heading='Email' data={profileDataAPI && profileDataAPI.email} className={classes.zeroPadding} />
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    <LabelText style={{ marginLeft: '4.5rem' }} heading='Created On' data={profileDataAPI && Moment.utc(profileDataAPI.created_at).format('MM/DD/YYYY')} className={classes.zeroPadding} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    <LabelText style={{ marginLeft: '4rem' }} heading='Changed On' data={profileDataAPI && Moment.utc(profileDataAPI.updated_at).format('MM/DD/YYYY')} className={classes.zeroPadding} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    <FormLabel style={{ marginLeft: '4.5rem' }}>
                                                        Country
                                                    </FormLabel>
                                                    <CountryDropdown
                                                        value={country}
                                                        style={{ width: '9rem', marginLeft: '3rem' }}
                                                        onChange={(val) => setCountry(val)}
                                                        classes={
                                                            classes.region
                                                        }
                                                        priorityOptions={['US']}
                                                    />
                                                    {/* <LabelText style={{marginLeft:'4.5rem'}} heading='Country' data={profileDataAPI && profileDataAPI.country} className={classes.zeroPadding} /> */}
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    <FormLabel style={{ marginLeft: '4rem' }} >
                                                        Language
                                                    </FormLabel>
                                                    {/*<OutlinedInput
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setLanguage(e.target.value)}
                                        value={language}
                                    />*/}
                                                    <Select
                                                        value={language}
                                                        onChange={e => setLanguage(e.target.value)}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        style={{ marginLeft: '11rem', marginTop: '-2.4rem' }}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >
                                                        <MenuItem value="">
                                                            Select Language
                                                        </MenuItem>
                                                        {languageArray.map(item => {
                                                            return (
                                                                <MenuItem value={item} key={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {/* <LabelText style={{marginLeft:'4rem'}} heading='Language' data={profileDataAPI && profileDataAPI.language} className={classes.zeroPadding} /> */}
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={6}
                                                // style={{ padding: '0px 10px' }}
                                                >
                                                    {/* <LabelText style={{marginLeft:'4.5rem'}} heading='Timezone' data={profileDataAPI && profileDataAPI.timezone} className={classes.zeroPadding} /> */}
                                                    <FormLabel style={{ marginLeft: '4.5rem' }} >
                                                        Timezone
                                                    </FormLabel>

                                                    <Autocomplete
                                                        id="checkboxes-tags-demo"
                                                        options={timeZonesList}
                                                        getOptionLabel={(option) => option}
                                                        defaultValue={profileDataAPI && profileDataAPI.timezone}
                                                        value={timezone}
                                                        renderInput={params => (

                                                            <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />

                                                        )}
                                                        classes={{
                                                            inputRoot: classes.borderRad0
                                                        }}

                                                        renderOption={(props, option) => (
                                                            <li {...props}>
                                                                {option}
                                                            </li>
                                                        )}
                                                        style={{ width: '8.9rem', marginLeft: '11.7rem', marginTop: '-2.4rem' }}
                                                        onChange={(event, newValue) => { setTimezone(newValue) }}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </CardContent>

                                    </Card>

                                    <Card variant="outlined" style={{ width: '100%', height: '22rem', marginTop: 20 }}>
                                        <CardHeader
                                            title={'Appearance'}
                                            classes={{
                                                title: classes.title,
                                            }}
                                            titleTypographyProps={{ variant: 'h3', color: theme.palette.primary.main }}
                                            style={{ /*fontSize: 16,*/ padding: '9px 0px 40px 23px', margin: 0, color: theme.palette.primary.main }}
                                        />
                                        <CardContent style={{ padding: '0px 16px 5px 16px' }}>
                                            <Grid
                                                container
                                                spacing={3}
                                            >
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <LabelText heading='Menu Color' style={{ paddingLeft: '3rem', marginTop: '1rem' }} twoline='true' className={classes.zeroPadding} />
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={8}
                                                    xs={12}
                                                    style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5, height: 90 }}
                                                >       <Button variant={globalColor == '#8F00FF' ? 'contained' : 'outlined'}
                                                    style={{
                                                        color: globalColor == '#8F00FF' ? 'white' : '#8F00FF',
                                                        backgroundColor: globalColor == '#8F00FF' ? '#8F00FF' : '',
                                                        borderColor: '#8F00FF'
                                                    }}
                                                    onClick={() => handleThemeChange('violet', 'global-color')}>Violet
                                                    </Button>
                                                    <Button variant={globalColor == '#264F1B' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: globalColor == '#264F1B' ? 'white' : '#264F1B',
                                                            backgroundColor: globalColor == '#264F1B' ? '#264F1B' : '',
                                                            borderColor: '#264F1B'
                                                        }}
                                                        onClick={() => handleThemeChange('green', 'global-color')}>Green</Button>
                                                    <Button variant={globalColor == '#32009A' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: globalColor == '#32009A' ? 'white' : '#32009A',
                                                            backgroundColor: globalColor == '#32009A' ? '#32009A' : '', //#445AC7
                                                            borderColor: '#32009A'
                                                        }}
                                                        onClick={() => handleThemeChange('light', 'global-color')}>Light</Button>
                                                    {/* <Button variant={globalColor == '#4F4F61' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: globalColor == '#4F4F61' ? 'black' : '#4F4F61',
                                                            backgroundColor: globalColor == '#4F4F61' ? '#4F4F61' : '',
                                                            borderColor: '#4F4F61'
                                                        }}
                                                        onClick={() => handleThemeChange('gray', 'global-color')}> Gray</Button> */}
                                                    <Button variant={globalColor == '#FFC400' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: globalColor == '#FFC400' ? 'white' : '#FFC400',
                                                            backgroundColor: globalColor == '#FFC400' ? '#FFC400' : '',
                                                            borderColor: '#FFC400'

                                                        }}
                                                        onClick={() => handleThemeChange('yellow', 'global-color')}>Yellow</Button>
                                                    <Button variant={globalColor == '#0B3662' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: globalColor == '#0B3662' ? 'white' : '#0B3662',
                                                            backgroundColor: globalColor == '#0B3662' ? '#0B3662' : '',
                                                            borderColor: '#0B3662'

                                                        }}
                                                        onClick={() => handleThemeChange('dark', 'global-color')}>Dark</Button>
                                                    <Button variant={'contained'} component="label"
                                                        style={{
                                                            color: 'white',
                                                            backgroundColor: globalColor,
                                                            borderColor: globalColor

                                                        }}>
                                                        <div className={classes.colorDiv} style={{ display: 'none' }}>
                                                            <ColorPicker className={classes.colorDiv} classes={{ root: classes.colorPickerRoot }} value={globalColor} onChange={(e) => handleChange(e, 'global-color')} hideTextfield /></div>
                                                        Custom</Button>

                                                </Grid>
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <LabelText heading='Global Color' style={{ paddingLeft: '3rem', marginTop: '1rem' }} twoline='true' className={classes.zeroPadding} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={8}
                                                    xs={12}
                                                    style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5, height: 90 }}
                                                >       <Button variant={color == '#8F00FF' ? 'contained' : 'outlined'}
                                                    style={{
                                                        color: color == '#8F00FF' ? 'white' : '#8F00FF',
                                                        backgroundColor: color == '#8F00FF' ? '#8F00FF' : '',
                                                        borderColor: '#8F00FF'
                                                    }}
                                                    onClick={() => handleThemeChange('violet', 'theme')}>Violet
                                                    </Button>
                                                    <Button variant={color == '#264F1B' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: color == '#264F1B' ? 'white' : '#264F1B',
                                                            backgroundColor: color == '#264F1B' ? '#264F1B' : '',
                                                            borderColor: '#264F1B'
                                                        }}
                                                        onClick={() => handleThemeChange('green', 'theme')}>Green</Button>
                                                    <Button variant={color == '#32009A' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: color == '#32009A' ? 'white' : '#32009A',
                                                            backgroundColor: color == '#32009A' ? '#32009A' : '', //#445AC7
                                                            borderColor: '#32009A'
                                                        }}
                                                        onClick={() => handleThemeChange('light', 'theme')}>Light</Button>
                                                    {/* <Button variant={color == '#4F4F61' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: color == '#4F4F61' ? 'black' : '#4F4F61',
                                                            backgroundColor: color == '#4F4F61' ? '#4F4F61' : '',
                                                            borderColor: '#4F4F61'
                                                        }}
                                                        onClick={() => handleThemeChange('gray', 'theme')}> Gray</Button> */}
                                                    <Button variant={color == '#FFC400' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: color == '#FFC400' ? 'white' : '#FFC400',
                                                            backgroundColor: color == '#FFC400' ? '#FFC400' : '',
                                                            borderColor: '#FFC400'

                                                        }}
                                                        onClick={() => handleThemeChange('yellow', 'theme')}>Yellow</Button>
                                                    <Button variant={color == '#0B3662' ? 'contained' : 'outlined'}
                                                        style={{
                                                            color: color == '#0B3662' ? 'white' : '#0B3662',
                                                            backgroundColor: color == '#0B3662' ? '#0B3662' : '',
                                                            borderColor: '#0B3662'

                                                        }}
                                                        onClick={() => handleThemeChange('dark', 'theme')}>Dark</Button>

                                                    <Button variant={'contained'} component="label"
                                                        style={{
                                                            color: 'white',
                                                            backgroundColor: color,
                                                            borderColor: color

                                                        }}>
                                                        <div style={{ display: 'none' }}> <ColorPicker className={classes.ColorPicker} style={{ height: 15 }} value={color} onChange={(e) => handleChange(e, 'theme')} hideTextfield /></div>
                                                        Custom
                                                    </Button>

                                                </Grid>

                                            </Grid>
                                        </CardContent>
                                        <CardActions style={{ padding: '3px 8px', justifyContent: 'center' }}>
                                            <Button size='large' color='primary' variant='contained' onClick={onSave}><span>Save</span></Button>

                                        </CardActions>
                                    </Card>
                                </div>
                                :
                                <Card>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField label="User Name" onChange={handleUserName} value={userName} className={classes.underline} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField label="Email" onChange={handleEmail} value={email} className={classes.underline} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField label="Website" onChange={handleWebsite} value={website} className={classes.underline} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                xs={12}
                                            >
                                                <TextField label="Organization" onChange={handleOrganization} value={organization} className={classes.underline} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider
                                        sx={{
                                            opacity: "0.6"
                                        }} />
                                    {!editMode ?
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            p={2}
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={handleEditProfile}
                                            >
                                                Edit details
                                            </Button>
                                        </Box>
                                        :
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            p={2}
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={submitEdit}
                                                disabled={userName && email ? false : true}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    }
                                </Card>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </LoadingOverlay >
    );

};

const mapStateToProps = state => {
    return {
        profileData: state.profileData.profileData,
        loading: state.profileData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getProfile: () => dispatch(getProfile()),
        editProfile: (id, data) => dispatch(editProfile(id, data)),
        uploadProfileImage: (id, file, type) => dispatch(uploadProfileImage(id, file, type)),
        resetPassword: (op, np) => dispatch(resetPassword(op, np)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);