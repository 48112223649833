import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Grid,
    FormLabel,
    OutlinedInput
} from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    inputTwoLine: {
        // marginTop: 5,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        width: '100%'
    },
    error: {
        border: '1px solid red !important'
    }
}));

const TextInputWithLabel = forwardRef((props, ref) => {
    const classes = useStyles();
    const [data, setData] = React.useState('');
    const [changedFlag, setChangedFlag] = React.useState(0);
    const handleChange = (event) => {
        setChangedFlag(1);
        setData(event.target.value);
        props.onChange(event.target.value);
    };
    const reset = () => {
        setData('');
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : data
        };
    });

    return (
        <div>
            {props.twoline ?
                <Grid container className={classes.root}>
                    <Grid
                        item
                        className={classes.gridContainer}
                    >
                        {props.heading &&
                            <FormLabel {...props} error={false} required={props.mandatory} style={{ textTransform: 'capitalize' }}>
                                {props.heading}
                            </FormLabel>
                        }
                        <OutlinedInput
                            value={!changedFlag && props.prevalue ? props.prevalue : data}
                            classes={{ root: classes.inputTwoLine }}
                            key={props.key ? props.key : ''}
                            className={clsx({
                                [classes.error]: props.error
                            })}
                            type={props.type}
                            inputProps={props.inputProps}
                            style={{ height: 36 }}
                            placeholder={props.placeholder} onChange={handleChange}
                            onKeyPress={props.onKeyPress}
                            disabled={props.disabled ? props.disabled : false}
                        />
                    </Grid>
                </Grid>
                :
                <Grid container className={classes.root}>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <FormLabel {...props}>
                            {props.heading}
                        </FormLabel>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <OutlinedInput classes={{ root: classes.input }} placeholder={props.placeholder} />
                    </Grid>
                </Grid>
            }
        </div>
    );

});

export default TextInputWithLabel;