import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Select, MenuItem, Button, OutlinedInput,
    FormControl, Input, ListItemText,
    FormLabel, TextField, Checkbox,
} from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    select: {
        width: '100%',
        marginTop: 5
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    error: {
        border: '1px solid red !important'
    },
    disabled: {
        color: 'black !important'
    },
    menuPaper: {
        maxHeight: 300
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));

const MultiSelectDropdownKeyVal = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const [data, setData] = React.useState(props.data ? props.data : []);
    const [changedFlag, setChangedFlag] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    const reset = () => {
        setData([]);
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : data
        };
    });
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (props.format == 'object')
                setSelected(selected.length === Object.values(props.listArray).length ? [] : Object.entries(props.listArray).map(([k, v]) => {
                    return k
                }));
            else
                setSelected(selected.length === Object.values(props.listArray).length ? [] : Object.values(props.listArray).map(item => {
                    return item.key
                }));
            setAllSelect(selected.length === Object.values(props.listArray).length ? false : true)

        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === Object.values(props.listArray).length ? true : false)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setData(selected)
        props.onChange(selected)
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        props.onChange([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(props.data)
        setAllSelect(Object.values(props.listArray) && Object.values(props.listArray).length > 0 && props.data.length === Object.values(props.listArray).length)

    }
    return (
        <div>

            <Grid container className={classes.root}>
                <Grid
                    item
                    style={{
                        width: '100%',
                    }}
                >
                    {props.heading &&
                        <FormLabel {...props} error={false} disabled={false} className={props.className}>
                            {props.heading ? props.heading : ''}
                        </FormLabel>}
                    <FormControl {...props}>

                        {props.format == 'object' ?
                            <Select
                                labelId="demo-mutiple-name-label"
                                id={props.id}
                                multiple
                                value={props.data && props.data.length > 0 ? props.data : []}
                                style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: props.capitalize ? 'capitalize' : 'none' }}
                                input={<OutlinedInput sx={{ padding: '4px' }} />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen()}
                                onClose={() => setSelectOpen(false)}
                                open={selectOpen}
                                renderValue={(appType) => (
                                    appType && appType.map((item, i) => props.listArray[item])
                                ).join(",")}
                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={allSelect} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {props.listArray && Object.entries(props.listArray).length > 0 && Object.entries(props.listArray)
                                    .map(([key, value]) => {
                                        return (

                                            <MenuItem onClick={(event) => handleSelectValues(event, key)} value={key} key={key} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(key) > -1} />
                                                <ListItemText primary={value} />
                                            </MenuItem>
                                        )
                                    })
                                }
                                <MenuItem value=' ' disabled></MenuItem>
                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>
                            :
                            <Select
                                labelId="demo-mutiple-name-label"
                                id={props.id}
                                multiple
                                value={props.data ? props.data : []}
                                style={{ maxHeight: "50px", width: '100%', height: '2.3rem', marginTop: 10, textTransform: props.capitalize ? 'capitalize' : 'none' }}
                                input={<OutlinedInput />}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                onOpen={() => handleOpen()}
                                onClose={() => setSelectOpen(false)}
                                open={selectOpen}
                                renderValue={(appType) => (
                                    appType && appType.map((item, i) => (i != 0 ? "," : "") + item.replace(/_/g, ' '))
                                )}
                                className={classes.multiSelect}

                            >
                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                    <Checkbox
                                        color='primary'
                                        checked={allSelect} />
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {props.listArray && Object.values(props.listArray).length > 0 && Object.values(props.listArray)
                                    .map(item => {
                                        return (

                                            <MenuItem onClick={(event) => handleSelectValues(event, item.key)} value={item.key} key={item.key} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item.key) > -1} />
                                                <ListItemText primary={item.desc} />
                                            </MenuItem>
                                        )
                                    })}
                                <MenuItem value=' ' disabled></MenuItem>
                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Select>
                        }
                    </FormControl>
                </Grid>
            </Grid >

        </div >
    );

});

export default MultiSelectDropdownKeyVal;