import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, DialogContentText, Fab
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getCompetitorDataFormatData,
    deleteCompetitorDataFormatData,
    uploadCompetitorDataFile, getCompetitorDataFormatDataById
} from "../../redux/actions";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeatLoader from "react-spinners/BeatLoader";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { AddBoxOutlined, Publish, Edit, Visibility, DeleteForever, LibraryAdd } from '@mui/icons-material';
import { cloneMasterQuery } from '../../redux/actions/DataSetup/DataSetupTransaction/AuthAction';
import SimpleDialog from '../../components/Dialog/SimpleDialog';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const CompetitorDataUpload = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [productGroup, setProductGroup] = useState([]);
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const inputBatchFile = useRef(null)
    const [currentBatchFile, setCurrentBatchFile] = useState(undefined);
    const [currentBatchFileName, setCurrentBatchFileName] = useState('');
    const [formatBatch, setFormatBatch] = useState('');
    const [currentBatchEditableID, setCurrentBatchEditableID] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false)
    const [currentId, setCurrentId] = React.useState("");
    useEffect(() => {
        if (props.CompetitorDataFormatData) {
            setProductGroup(props.competitorDataFormatData)
        }
    }, [props.competitorDataFormatData]);
    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadCompetitorDataFile(currentEditableID, data);
        setOpen(true)
    }
    const handleBatchDrop = (event, item) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadCompetitorDataFile(currentBatchEditableID, data);
        setOpen(true)
    }
    function runBatchQuery(item) {
        setCurrentBatchEditableID(item.id);
        inputBatchFile.current.click();
    }
    useEffect(() => {
        props.getCompetitorDataFormatData();
        sessionStorage.removeItem('dynamic_filter_fields');
    }, []);

    useEffect(() => {
        if (props.competitorDataErrorList) {
            setErrorList(props.competitorDataErrorList)
            if (Object.entries(props.competitorDataErrorList).length > 0 && props.competitorDataErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.competitorDataErrorList])
    //dynamic query functions
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        sessionStorage.setItem('competitor-data-query', JSON.stringify(item))
        // props.getCompetitorDataFormatDataById(item.id)
        history.push('/competitor-data/competitor-data-excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        sessionStorage.setItem('competitor-data-query', JSON.stringify(item))
        history.push('/competitor-data/competitor-data-excel-upload/view/' + item.id);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpenDelete(false);
            props.deleteCompetitorDataFormatData(currentId);
        } else {
            setOpenDelete(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpenDelete(true)
        setCurrentId(Id)
    };

    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }
    const clone = (item) => {
        props.cloneMasterQuery(item.id);
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>
                    Competitor Data Excel Upload</Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={() => history.push('/competitor-data/competitor-data-excel-upload/add-query')}
                >
                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                </Fab>
            </div>
            {
                props.competitorDataFormatData && props.competitorDataFormatData.length > 0 ?
                    <TableContainer style={{ marginTop: -20 }}>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {props.competitorDataFormatData
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.id} >
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' width={300}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => editQuery(item)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <IconButton onClick={() => clone(item)} classes={{ root: classes.IconButton }} size={'small'}>
                                                                <img src={require('../../library/images/Copy.png')} alt='' />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => viewQuery(item)}
                                                                size="large">
                                                                <VisibilityIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <input type='file'
                                                                accept=".xlsx, .xls, .csv"
                                                                id='file' ref={inputFile} style={{ display: 'none' }}
                                                                onChange={(e) => handleDrop(e)} />
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => runQuery(item)}
                                                                size="large">
                                                                <PublishIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <input type='file'
                                                                accept=".xlsx, .xls, .csv"
                                                                id='file' ref={inputBatchFile} style={{ display: 'none' }}
                                                                onChange={(e) => handleBatchDrop(e, item)} />
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => runBatchQuery(item, 'Material Master Data')}
                                                                size="large">
                                                                <UploadFileIcon color="disabled" classes={{ root: classes.caption }} disabled={true} />
                                                            </IconButton>
                                                            <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => {
                                                                        handleClickOpen(item.id);
                                                                    }}
                                                                    size="large">
                                                                    <DeleteForever color="disabled" style={{ fontSize: 20 }} classes={{ root: classes.caption }} disabled={true} />
                                                                </IconButton>
                                                                <SimpleDialog open={openDelete} content='Do you want to delete Format?' handleDialog={handleDialog} />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
            <Dialog
                fullWidth
                maxWidth="xs"
                open={errorDialogOpen}
            >
                <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                    <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                </DialogActions>
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>
                    <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        competitorDataFormatData: state.dataSetupData.competitorDataFormatData,
        loading: state.dataSetupData.loading,
        competitorDataErrorList: state.dataSetupData.competitorDataErrorList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        uploadCompetitorDataFile: (id, file) => dispatch(uploadCompetitorDataFile(id, file)),
        deleteCompetitorDataFormatData: (id) => dispatch(deleteCompetitorDataFormatData(id)),
        getCompetitorDataFormatData: () => dispatch(getCompetitorDataFormatData()),
        getCompetitorDataFormatDataById: (id) => dispatch(getCompetitorDataFormatDataById(id)),
        cloneMasterQuery: (id) => dispatch(cloneMasterQuery(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompetitorDataUpload);