import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    Button, IconButton, Typography, Fab,
    Select,
    MenuItem,
    FormControl, Dialog, DialogActions, DialogContent, DialogContentText,
    OutlinedInput,
    InputAdornment,
    FormLabel
} from '@mui/material';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload, SearchSharp, Edit, CloudDownload, List, ArrowDropUpTwoTone, ArrowDropDownTwoTone } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import { useHistory } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import { uploadCostingAfterComparisonDataFile, getDropdownScenarioData } from '../../redux/actions/Promotion/AuthAction';
import { InputLabel } from '@material-ui/core';
import SingleSelectMultiLevel from './SingleSelectMultiLevel';
const theme = createTheme({
    overrides: {

        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    },

});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
    prefillOutlined: {
        width: '100%',
        height: '37px',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const CostingDownloadFile = props => {

    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const page = history.location.state;
    const fileInputRef = useRef(null);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [uploadedFileData, setUploadedFileData] = React.useState('');
    const [scenarios, setScenarios] = React.useState('');
    const [scenariosID, setScenariosID] = React.useState('');
    const [currentFileName, setCurrentFileName] = useState('');
    const [anchorE1, setAnchorE1] = React.useState(false);
    const [scenarioExpand, setScenarioExpand] = React.useState([]);
    const [previousScenarios, setPreviousScenarios] = React.useState([]);


    useEffect(() => {
        props.getDropdownScenarioData();
    }, []);

    useEffect(() => {
        if (props.costingDropdownDataList)
            setPreviousScenarios(props.costingDropdownDataList)
        else
            setPreviousScenarios([])
    }, [props.costingDropdownDataList]);


    const handleFileUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        const fileName = event.target.files[0].name.split('.').slice(0, -1).join('.');
        setCurrentFileName(fileName)
        if (uploadedFile) {
            const tempData = new FormData();
            tempData.append('file', uploadedFile,);
            setUploadedFileData(tempData)
        }
        setOpen(true)
    };

    function handleDownload() {
        props.costingComparisonFileUpload(scenariosID, true, uploadedFileData, currentFileName)
        setOpen(false)
    }

    function handleView() {
        props.costingComparisonFileUpload(scenariosID, false, uploadedFileData, currentFileName)
        history.push('/costing-list-screen')
    }

    function handleClose() {
        setOpen(false)
    }

    function handleScenario(scenario, id) {
        setScenariosID(id)
        setScenarios(scenario)
    }

    const handleClick = (type) => {
        if (type) {
            setAnchorE1(!anchorE1)
            const isExpanded = !anchorE1
            setScenarioExpand(isExpanded ? [...scenarioExpand, 'scenario_filter'] : []);
        }
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Costing File Download After Comparison  </Typography>

                </div>
                <div style={{
                    display: 'flex', marginBottom: 5, marginTop: 50, justifyContent: "center", height: 80,
                }}>
                    <div style={{ width: "40%" }}>
                        <div className={classes.headerItem}>
                            <FormLabel color='black' >Select a Scenario</FormLabel>
                            <div className={classes.selectBox}>
                                <OutlinedInput
                                    twoline='true'
                                    value={scenarios}
                                    readOnly
                                    placeholder='None Selected'
                                    onClick={() => handleClick('scenario')}
                                    className={classes.prefillOutlined}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClick('scenario')}
                                            >
                                                {anchorE1 ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                            {anchorE1 &&
                                <div style={{ position: 'relative' }}>
                                    <SingleSelectMultiLevel head='Scenario' id={'scenario'} data={previousScenarios} expand={scenarioExpand} onSubmit={handleScenario}
                                        prevalue={scenarios} type='scenario_filter' />
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept=".xlsx, .xls, .csv, .zip"
                        id='file'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <Fab
                        aria-label="edit"
                        variant="extended"
                        size="medium"
                        className={classes.fabContainer}
                        onClick={handleFileUpload}
                    >
                        <CloudUpload color="primary" />
                        <Typography color="primary" style={{ marginLeft: 3 }}>File Upload</Typography>
                    </Fab>
                </div>
            </LoadingOverlay>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'xs'}
            >
                <DialogContent
                    classes={{
                        root: classes.dialogContent,
                    }}
                >
                    <Typography variant="h4">
                        <DialogContentText>
                            Please Choose to View or Download
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialog,
                    }}
                >
                    <Button
                        onClick={() => handleView()}
                        autoFocus
                        color="primary"
                        variant="outlined"
                    >
                        View
                    </Button>
                    <Button
                        onClick={() => handleDownload()}
                        color="primary"
                        autoFocus
                        variant="contained"
                    >
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        costingComparisonDownloadList: state.promotionData.costingComparisonDownload,
        costingDropdownDataList: state.promotionData.costingDropdownData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        costingComparisonFileUpload: (scenarioNumber, isDownload, file, name) => dispatch(uploadCostingAfterComparisonDataFile(scenarioNumber, isDownload, file, name)),
        getDropdownScenarioData: () => dispatch(getDropdownScenarioData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostingDownloadFile);