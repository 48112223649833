import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { editContractXRefUser, getContractXRefDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
}));

const EditContractXREF = props => {
    const classes = useStyles();
    const [organizationId, setOrganizationId] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerContractId, setChannelPartnerContractId] = React.useState('');
    const [imaContractId, setImaContractId] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getContractXRefDetails(appId);
    }, []);
    useEffect(() => {
        if (props.customerMasterXRefData) {
            setChannelPartnerId(props.customerMasterXRefData.channel_partner_id);
            setChannelPartnerContractId(props.customerMasterXRefData.channel_partner_contract_id);
            setImaContractId(props.customerMasterXRefData.ima_contract_id);
        }
    }, [props.customerMasterXRefData]);
    function handleChannelPartnerId(event) {
        setChannelPartnerId(event.target.value);
    }
    function handleChannelPartnerContractId(event) {
        setChannelPartnerContractId(event.target.value);
    }
    function handleImaContractId(event) {
        setImaContractId(event.target.value);
    }
    function handleClear() {
        setOrganizationId('');
        setChannelPartnerId('');
        setChannelPartnerContractId('');
        setImaContractId('');

    }
    function handleOnSubmit() {
        var data = {
            // "organization_id": organizationId,
            "channel_partner_id": channelPartnerId,
            "channel_partner_contract_id": channelPartnerContractId,
            "ima_contract_id": imaContractId,
        };
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/contract-xref'
                >
                    Contract XREF
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Contract XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner Contract ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerContractId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerContractId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA Contract ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaContractId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleImaContractId} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={channelPartnerId && channelPartnerContractId
                            && imaContractId
                            ? false : true
                        }
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(editContractXRefUser(data, editableId)),
        getContractXRefDetails: (id) => dispatch(getContractXRefDetails(id)),
    }
}

const mapStateToProps = state => {
    return {
        customerMasterXRefData: state.customerData.contractXRefViewData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContractXREF);