import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  DataLabel,
  BarSeries,
  Category,
  Legend,
  Tooltip,
  ILoadedEventArgs,
  ChartTheme,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Breadcrumbs,
  Typography,
  Link,
  useMediaQuery,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Button,
  Paper,
  Fab,
  TablePagination,
} from "@mui/material";
import { SearchInput } from "../../components";
import {
  getOrgDefaults,
  downloadDynamicQuery,
  getDefaultValuesAllDataNew,
  getDynamicQuery,
} from "../../redux/actions";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationTooltip,
  IAccLoadedEventArgs,
  AccumulationTheme,
  AccumulationDataLabel,
} from "@syncfusion/ej2-react-charts";
import {
  indexFinder,
  ColumnsDirective,
  getElement,
  AccumulationAnnotation,
} from "@syncfusion/ej2-react-charts";

const DynamicQueryResults = (props) => {
  const [xaxis, setXaxis] = React.useState("");
  const [yaxis, setYaxis] = React.useState("");
  let dataLabel = {
    visible: true,
    position: "Inside",
    enableRotation: false,
    connectorStyle: { type: "Curve", length: "5%" },
    font: { fontWeight: "600", color: "white" },
  };
  const [topPerformers, setTopPerformers] = React.useState([]);
  const [bottomPerformers, setBottomPerformers] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [sfData, setSfData] = React.useState([]);
  const [queryListDataAPI, setQueryListDataAPI] = React.useState({});
  const [label1, setLabel1] = React.useState("");
  const [label2, setLabel2] = React.useState("");
  const [maximumNumber,setMaximumNumber] = React.useState(100);
  let title = "PIE CHART";

  let pie;
  let isparent = true;
  function onTextRender(args) {
    args.text = args.point.x.replace(/_/g, " ");
  }
  function hide(target) {
    pie.series[0].dataSource = sfData;
    pie.series[0].innerRadius = "0%";
    isparent = true;
    pie.series[0].explode = false;
    pie.annotations = [];
    pie.annotationModule["annotations"] = [];
    pie.series[0].dataLabel = dataLabel;
    pie.title = title;
    pie.legendSettings.visible = false;
    pie.enableSmartLabels = false;
    pie.refresh();
    target.style.visibility = "hidden";
    document.getElementById("category").style.visibility = "hidden";
    document.getElementById("symbol").style.visibility = "hidden";
    document.getElementById("text").style.visibility = "hidden";
  }
  // function onChartLoad(args) {
  //   document.getElementById("pie-chart").setAttribute("title", "");
  // }
  function load(args) {
    let selectedTheme = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    ).replace(/-dark/i, "Dark");
    if (
      selectedTheme === "highcontrast" ||
      selectedTheme.indexOf("dark") > -1
    ) {
      args.accumulation.series[0].dataLabel.font.color = "white";
      if (args.accumulation.annotations[0] && !isparent) {
        args.accumulation.annotations[0].content =
          '<div id= "white" style="cursor:pointer;padding:3px;width:30px; height:30px;"><img src="./src/chart/images/white.png" id="back" /><div>';
      }
    }
  }
  useEffect(() => {
    setQueryListDataAPI(props.queryListData);
  }, [props.queryListData]);

  useEffect(() => {
    var top = [];
    var bottom = [];
    if (props.data && props.data.length > 0) {
      props.data.map((item, i) => {
        top.push({
          x: item.margin_price_percentage + " " + item[props.analysisLevel],
          y: item.margin_price_difference,
          color: "#449724",
        });
      });
      setTopPerformers(top);
      setBottomPerformers(bottom);
    }
  }, [props.data]);
  const primaryxAxis = {
    minimum: 2005,
    maximum: 2012,
    interval: 1,
    title: xaxis,
  };
  const primaryyAxis = {
    minimum: 3,
    maximum: 12,
    interval: 1,
    title: yaxis,
    labelFormat: "{value}%",
  };

  useEffect(() => {
    if (props.queryResultsFinalData && props.queryResultsFinalData[0]&& props.queryResultsFinalData[3]) {
      // var keysx = Object.keys(props.queryResultsFinalData[0][0]);
      // console.log(props.queryResultsFinalData[3]['sort_fields'])
      if (props.queryResultsFinalData[3]['sort_fields']) setXaxis(props.queryResultsFinalData[3]['sort_fields'][0].label);
      if (props.queryResultsFinalData[3]['sort_fields']) setYaxis(props.queryResultsFinalData[3]['sort_fields'][1].label);
      var pieData = [];
      var temp = {};
      var label1t;
      var label2t;
      // setData(props.queryResultsFinalData[0].slice(0, -1));
      var newArray=[]
      var max=[];
      props.queryResultsFinalData[0].slice(0,-1).map(item=>{
        max.push(Object.values(item)[1])
        newArray.push({[props.queryResultsFinalData[3]['sort_fields'][0].label]:Object.values(item)[0],[props.queryResultsFinalData[3]['sort_fields'][1].label]:Object.values(item)[1]})
      })
      setData(newArray)
      setMaximumNumber(Math.max(...max))
      props.queryResultsFinalData[0]
        .slice(0, -1)
        .filter((item, ix) => ix <= 20)
        .map((item, index) => {
          label1t = Object.keys(item)[0];
          label2t = Object.keys(item)[1];
          temp["x"] = item[label1t];
          temp["y"] = item[label2t];
          temp["z"] = label1t;
          pieData.push(temp);
          temp = {};
          setLabel1(label1t);
          setLabel2(label2t);
        });
      setSfData(pieData);
    }
  }, [props.queryResultsFinalData]);
  const ChartCode = () => {
    return (
      <ChartComponent
        id="charts"
        primaryXAxis={{
          valueType: "Category",
          majorGridLines: { width: 0 },
          title: xaxis ? xaxis : "",
        }}
        primaryYAxis={{
          title: yaxis ? yaxis : "",
          majorTickLines: { width: 0 },
          lineStyle: { width: 0 },
          maximum: maximumNumber+maximumNumber/100,
          interval: parseInt(maximumNumber/10),
        }}
        tooltip={{
          enable: true,
          header: xaxis ? xaxis + "-" + "${point.x}" : "",
          format: yaxis
            ? yaxis + "-" + "${point.y}"
            : "" + "${point.y}",
        }}
        title="Dynamic analysis"
      >
        <Inject services={[BarSeries, Legend, Tooltip, DataLabel, Category]} />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={data}
            xName={xaxis}
            yName={yaxis}
            // name="Dynamic Charts"
            type="Column"
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    );
  };
  function onChartMouseClick(args) {
    let index = indexFinder(args.target);
    if (
      isparent &&
      document.getElementById(
        "pie-chart_Series_" + index.series + "_Point_" + index.point
      )
    ) {
      isparent = false;
      console.log("sfData", sfData);
      pie.series[0].dataSource =
        sfData[index.point] && sfData[index.point].z
          ? sfData[index.point].z
          : "";
      pie.title = "Pie Chart";
      document.getElementById("text").innerHTML = "Pie Chart";
      pie.annotations = [
        {
          content:
            '<div id="back" style="cursor:pointer; padding: 3px; width: 30px; height: 30px;">',
          region: "Series",
          x: "50%",
          y: "50%",
        },
      ];
      pie.series[0].innerRadius = "30%";
      pie.series[0].radius = Browser.isDevice ? "90%" : "80%";
      pie.series[0].explode = false;
      pie.series[0].dataLabel.connectorStyle.length = "20px";
      pie.series[0].dataLabel.position = Browser.isDevice
        ? "Inside"
        : "Outside";
      pie.series[0].dataLabel.position = Browser.isDevice
        ? "Inside"
        : "Outside";
      pie.series[0].dataLabel.enableRotation = true;
      pie.series[0].dataLabel.font.color = "";
      pie.legendSettings.visible = false;
      pie.visibleSeries[0].explodeIndex = null;
      pie.enableSmartLabels = true;
      pie.refresh();
      document.getElementById("category").style.visibility = "visible";
      document.getElementById("symbol").style.visibility = "visible";
      document.getElementById("text").style.visibility = "visible";
    }
    if (args.target.indexOf("back") > -1) {
      hide(document.getElementById(args.target));
    }
  }
  const PieChartCode = () => {
    return (
      <AccumulationChartComponent
        id="pie-chart"
        width="100%"
        height="100%"
        ref={pie}
        title="Pie Chart"
        enableSmartLabels={true}
        tooltip={{
          enable: true,
          format: label2.replace(/_/g, " ") + " : " + "${point.y}",
        }}
        legendSettings={{
          visible: true,
          position: "Bottom",
          title: label1 ? label1.replace(/_/g, " ") : "",
        }}
        textRender={onTextRender.bind(this)}
        load={load.bind(this)}
        // loaded={onChartLoad.bind(this)}
      >
        <Inject
          services={[
            AccumulationDataLabel,
            AccumulationLegend,
            AccumulationTooltip,
            PieSeries,
            AccumulationAnnotation,
          ]}
        />
        <AccumulationSeriesCollectionDirective>
          <AccumulationSeriesDirective
            startAngle="0"
            endAngle="360"
            explode={true}
            explodeOffset="10%"
            explodeIndex={3}
            name={label1 ? label1.replace(/_/g, " ") : ""}
            legendShape="Rectangle"
            dataSource={sfData}
            xName="x"
            yName="y"
            dataLabel={{
              visible: true,
              position: "Outside",
            }}
          ></AccumulationSeriesDirective>
        </AccumulationSeriesCollectionDirective>
      </AccumulationChartComponent>
    );
  };

  return (
    <div style={{ width: "100%", height: "100%", padding: 0 }}>
      {data && data.length > 0 && xaxis && yaxis ? (
        queryListDataAPI && queryListDataAPI["chart_type"] === "Bar Chart" ? (
          <ChartCode />
        ) : queryListDataAPI["chart_type"] === "Pie Chart" ? (
          <PieChartCode />
        ) : (
          "Please select chart type"
        )
      ) : (
        "No data"
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    queryResultsFinalData: state.operationalReportsData.queryResultsFinalData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    orgDefaults: state.addMultipleConfigurationData.orgDefaults,
    queryListData: state.operationalReportsData.dynamicQueryDetailsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadDynamicQuery: (data) => dispatch(downloadDynamicQuery(data)),
    onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) =>
      dispatch(
        getDefaultValuesAllDataNew(command, applicationType, sourceDataType)
      ),
    getOrgDefaults: () => dispatch(getOrgDefaults()),
    getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicQueryResults);
