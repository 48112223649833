import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
// import {
//     Typography, useMediaQuery, IconButton, Fab
// } from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Edit, DeleteForever, LibraryAdd } from '@mui/icons-material';
import { getFormulasList, getFormulaDetails, submitFormula } from '../../redux/actions';
import secureLocalStorage from 'react-secure-storage';
import { NavLink as RouterLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import { toast } from "react-toastify";
import { tableIcons } from '../../components/Icons/TableIcons';
import {
    Typography, useMediaQuery, IconButton, Fab, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    }
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const FormulaLabMain = props => {

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [apiResponse2, setApiResponse2] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const columns = [
        {
            field: 'source_data_type',
            title: 'Source Data Type',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFormula(rowData)}> {rowData.source_data_type}</a>
        },
        {
            field: 'ID',
            title: 'Formula ID',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFormula(rowData)}> {rowData.ID}</a>
        },
        {
            field: 'formula_name',
            title: 'Formula Name',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFormula(rowData)}> {rowData.formula_name && rowData.formula_name.length > 63 ? <div style={{
                overflowY: 'auto',
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',

            }}>{rowData.formula_name}</div>
                :
                rowData.formula_name

            }</a>
        },
        {
            field: 'formula',
            title: 'Formula Expression',
            render: (rowData) => <a className={classes.hover} onClick={() => viewFormula(rowData)}> {rowData.formula && rowData.formula.length > 30 ? <div style={{
                overflowY: 'auto',
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',

            }}>{rowData.formula}</div>
                : rowData.formula

            }</a>
        },
        // {
        //     field: 'application_type',
        //     title: 'Application Type',
        //     render: (rowData) => <a className={classes.hover} onClick={() => viewFormula(rowData)}> {rowData.application_type}</a>
        // },
        {
            field: 'Action',
            title: 'Actions',
            render: rowData => <>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleEdit(rowData.ID, rowData)}
                        size="large">
                        <Edit color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                }
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        // onClick={() => handleDelete(rowData, rowData.ID)}
                        onClick={() => confirmDelete(rowData)}
                        size="large">
                        <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                }
            </>
        }
    ]
    useEffect(() => {
        props.getFormulasList(1, 0);
    }, []);
    useEffect(() => {
        if (props.queryListData && props.queryListData.records && props.queryListData.records.length > 0) {
            setApiResponse2(props.queryListData.records);
        }
    }, [props.queryListData]);
    const confirmDelete = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);
    };
    const handleDelete = () => {
        if (selectedItem.enable_actions) {
            props.deleteFormula(selectedItem, selectedItem.ID);
        } else {
            toast.error("Found formula used in contracts, thus cannot be removed or edited");
        }
        setOpenDialog(false);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedItem(null);
    };
    
    const handleEdit = (id, item) => {
        if (item.enable_actions) {
            props.getFormulaDetails(id);
            sessionStorage.setItem('formula-details', JSON.stringify(item));
        }
        else {
            toast.error("Found formula used in contracts, thus cannot be removed or edited")
        }
    }
    function viewFormula(item) {
        sessionStorage.setItem('formula-details', JSON.stringify(item))
        history.push({
            pathname: '/formula-lab/view-formula-lab/' + item.ID,
            id: item.ID
        });
    }


    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{ marginTop: 10 }} > Formula Lab</Typography>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula-' + sessionStorage.getItem('application')) &&
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            component={CustomRouterLink}
                            to='/formula-lab/add-formula-lab'
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                    </div>
                }
            </div>
            {props.queryListData && props.queryListData.records && props.queryListData.records.length > 0 ?
                <MaterialTable
                    components={{
                        Toolbar: (props) => (
                            <div
                                style={{
                                    height: "0px",
                                }}
                            >
                            </div>
                        ),
                    }}
                    title={' '}
                    editable={true}
                    icons={tableIcons}
                    columns={columns}
                    data={apiResponse2}
                    options={{
                        search: false,
                        filtering: true,
                        paging: false,
                        headerStyle: theme.mixins.MaterialHeader,
                        cellStyle: {
                            fontSize: '.90rem',
                            textAlign: "center",
                            borderRight: "1px solid #EEEEEE",
                            color: "black !important",
                            padding: '5px 0px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 800,
                            width: 30,
                            minWidth: 5,
                        },
                    }}
                    style={{ marginTop: '-1rem' }}
                />
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
                
            }
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Formula?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this formula?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} variant="contained" color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.FormulaLabData.formulaListData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFormulasList: (pagination, limit) => dispatch(getFormulasList(pagination, limit)),
        getFormulaDetails: (id) => dispatch(getFormulaDetails(id)),
        deleteFormula: (data, id) => dispatch(submitFormula(data, id, 'delete'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormulaLabMain);