import React, { useEffect } from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import {
    Grid, Table, TableBody, TableContainer, TableHead,
    TableRow, TableCell, Breadcrumbs, Typography,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadRebateTrendsSecondAPI, getLblDispDesFieldValue, loadValuesBasedOnAppTypeAndField, getDefaultValuesAllDataNew } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import { ChartComponent, SeriesCollectionDirective, Highlight, SeriesDirective, Inject, Legend, Category, ColumnSeries, Tooltip } from '@syncfusion/ej2-react-charts';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '16px 0px 16px 0px'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    chartContainer: {
        marginTop: 5,
        // height: 250,
        width: "100%"
    },
    gridContainer: {
        boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
        paddingTop: 10,
    },
}));
const RebateTrends = (props) => {
    const classes = useStyles();
    const [appId, setAppId] = React.useState(0);
    const [labelNewData, setLableNewData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartData2, setChartData2] = React.useState([]);
    const [chartData3, setChartData3] = React.useState([]);
    const [chartData4, setChartData4] = React.useState([]);
    const [chartData5, setChartData5] = React.useState([]);
    const [chartData6, setChartData6] = React.useState([]);
    const [maximumNumber, setMaximumNumber] = React.useState(10);
    const [maximumNumber1, setMaximumNumber1] = React.useState(10);
    const [flexKeyFigures, setFlexKeyFigures] = React.useState([]);
    const [formula, setFormula] = React.useState([]);

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setAppId(appId)
        sessionStorage.setItem('rebate-trend-second-page', true);

    }, []);

    useEffect(() => {
        if (props.accrualVarianceChartApiData && props.accrualVarianceChartApiData.length > 0) {
            // Extracting data from apiResponse
            const transformedData = props.accrualVarianceChartApiData.map(item => ({
                x: item.billing_date,
                y: item.incentive_amount_variance,
            }));
            setChartData(transformedData);
            const transformedData1 = props.accrualVarianceChartApiData.map(item => ({
                x: item.billing_date,
                y: item.tier_basis_variance,
            }));
            setChartData2(transformedData1);
            const transformedData2 = props.accrualVarianceChartApiData.map(item => ({
                x: item.billing_date,
                y: item.incentive_basis_variance,
            }));
            setChartData3(transformedData2);
            const transformedData3 = props.accrualVarianceChartApiData.map(item => ({
                x: item.billing_date,
                y: item.incentive_amount_variance_absolute,
            }));
            setChartData4(transformedData3);
            const transformedData4 = props.accrualVarianceChartApiData.map(item => ({
                x: item.billing_date,
                y: item.tier_basis_variance_absolute,
            }));
            setChartData5(transformedData4);
            const transformedData5 = props.accrualVarianceChartApiData.map(item => ({
                x: item.billing_date,
                y: item.incentive_basis_variance_absolute,
            }));
            setChartData6(transformedData5);

            // Calculate and set the maximum number
            const maxVal = Math.max(...props.accrualVarianceChartApiData.map(item => item.incentive_amount_variance));
            if (maxVal > 10) {
                setMaximumNumber(maxVal);
            }
            const maxVal1 = Math.max(...props.accrualVarianceChartApiData.map(item => item.incentive_amount_variance_absolute));
            if (maxVal1 > 10) {
                setMaximumNumber1(maxVal1);
            }
        }
    }, [props.accrualVarianceChartApiData])

    useEffect(() => {
        if (props.labelNewAPIData) {
            setLableNewData(props.labelNewAPIData.field_label_attributes
            )
        }
    }, [props.labelNewAPIData])

    // useEffect(() => {
    // const newFlexKeyFigures = {};
    // let newFormula = {};

    // Object.keys(props.accrualVarianceGraphData).forEach(key => {
    //     if (Array.isArray(props.accrualVarianceGraphData[key])) {
    //         newFlexKeyFigures[key] = props.accrualVarianceGraphData[key];
    //     } else if (key === 'formula') {
    //         newFormula = props.accrualVarianceGraphData[key];
    //     }
    // });
    // setFlexKeyFigures(newFlexKeyFigures);
    // setFormula(newFormula);
    // }, [props.accrualVarianceGraphData]);
    useEffect(() => {
        if (Object.keys(props.accrualVarianceGraphData).length > 0) {
            const newFlexKeyFigures = {};
            let newFormula = {};

            Object.keys(props.accrualVarianceGraphData).forEach(key => {
                if (Array.isArray(props.accrualVarianceGraphData[key])) {
                    newFlexKeyFigures[key] = props.accrualVarianceGraphData[key];
                } else if (key === 'formula') {
                    newFormula = props.accrualVarianceGraphData[key];
                }
            });

            setFlexKeyFigures(newFlexKeyFigures);
            setFormula(newFormula);
        } else {
            setFlexKeyFigures([]);
            setFormula([]);
        }
    }, [props.accrualVarianceGraphData]);



    const rebate = formula.Rebate
    const tier = formula.Tier



    let trendTitle = 'Contract Sales Drivers Trend';

    if (tier) {
        trendTitle += `: Tier Formula - ${tier}`;
    }

    if (rebate) {
        if (tier) {
            trendTitle += ` // Rebate Formula - ${rebate}`;
        } else {
            trendTitle += `: Rebate Formula - ${rebate}`;
        }
    }

    const getCurrentValueForKey = (key) => {
        if (labelNewData && key in labelNewData) {
            return labelNewData[key].current;
        }
        return key;
    };
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>

                    <Grid container style={{ margin: "-15px 0px 1px 15px", borderRadius: 5 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link variant='h4' classes={{
                                root: classes.link
                            }}
                                to='/rebate-trends'
                            >
                                Rebate Trends
                            </Link>
                            <Typography color="textPrimary" variant='h4'>Contract Postings Chart</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <div className={classes.chartContainer}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12} className={classes.gridContainer}>
                                <Typography variant="h2" color='primary' style={{ textAlign: "center", marginBottom: 20 }}>
                                    {`Variance % Of Contract Number - ${appId}`}
                                </Typography>
                                <ChartComponent
                                    id={"trend-chart"}
                                    style={{ textAlign: "center" }}
                                    primaryXAxis={{
                                        majorGridLines: { width: 0 },
                                        minorGridLines: { width: 0 },
                                        majorTickLines: { width: 0 },
                                        minorTickLines: { width: 0 },
                                        interval: 1,
                                        lineStyle: { width: 0 },
                                        valueType: 'Category'
                                    }}
                                    primaryYAxis={{
                                        lineStyle: { width: 0 },
                                        // maximum: maximumNumber + maximumNumber / 100,
                                        // interval: parseInt(maximumNumber / 5),
                                        majorTickLines: { width: 0 },
                                        majorGridLines: { width: 1 },
                                        minorGridLines: { width: 1 },
                                        minorTickLines: { width: 0 },
                                        labelFormat: '{value}',
                                        // title:props.text
                                    }}
                                    legendSettings={{ enableHighlight: true }}
                                    width={'100%'}
                                    height='55%'
                                    chartArea={{ border: { width: 0 } }}
                                    tooltip={{ enable: true }}
                                >
                                    <Inject services={[ColumnSeries, Category, Legend, Tooltip, Highlight]} />
                                    <SeriesCollectionDirective>
                                        <SeriesDirective dataSource={chartData} xName='x' columnSpacing={0.1} yName='y' name='Incentive Amount Variance(%)' width={2} type="Column" fill='#2485FA'>
                                        </SeriesDirective>
                                        <SeriesDirective dataSource={chartData2} xName='x' columnSpacing={0.1} yName='y' name='Tier Basis Variance(%)' width={2} type="Column" fill='#FEC200'>
                                        </SeriesDirective>
                                        <SeriesDirective dataSource={chartData3} xName='x' columnSpacing={0.1} yName='y' name='Incentive Basis Variance(%)' width={2} type="Column" fill='#ACC200'>
                                        </SeriesDirective>
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.gridContainer}>
                                <Typography variant="h2" color='primary' style={{ textAlign: "center", marginBottom: 20 }}>
                                    {`Variance Amount Of Contract Number - ${appId}`}
                                </Typography>
                                <ChartComponent
                                    id={"trend-chart1"}
                                    style={{ textAlign: "center" }}
                                    primaryXAxis={{
                                        majorGridLines: { width: 0 },
                                        minorGridLines: { width: 0 },
                                        majorTickLines: { width: 0 },
                                        minorTickLines: { width: 0 },
                                        interval: 1,
                                        lineStyle: { width: 0 },
                                        valueType: 'Category'
                                    }}
                                    primaryYAxis={{
                                        lineStyle: { width: 0 },
                                        // maximum: maximumNumber1 + maximumNumber1 / 100,
                                        // interval: parseInt(maximumNumber1 / 5),
                                        majorTickLines: { width: 0 },
                                        majorGridLines: { width: 1 },
                                        minorGridLines: { width: 1 },
                                        minorTickLines: { width: 0 },
                                        labelFormat: '{value}',
                                        // title:props.text
                                    }}
                                    legendSettings={{ enableHighlight: true }}
                                    width={'100%'}
                                    height='55%'
                                    chartArea={{ border: { width: 0 } }}
                                    tooltip={{ enable: true }}
                                >
                                    <Inject services={[ColumnSeries, Category, Legend, Tooltip, Highlight]} />
                                    <SeriesCollectionDirective>
                                        <SeriesDirective dataSource={chartData4} xName='x' columnSpacing={0.1} yName='y' name='Incentive Amount Variance' width={2} type="Column" fill='#2485FA'>
                                        </SeriesDirective>
                                        {/* <SeriesDirective dataSource={chartData5} xName='x' columnSpacing={0.1} yName='y' name='Tier Basis Variance' width={2} type="Column" fill='#FEC200'>
                                        </SeriesDirective> */}
                                        <SeriesDirective dataSource={chartData6} xName='x' columnSpacing={0.1} yName='y' name='Incentive Basis Variance' width={2} type="Column" fill='#ACC200'>
                                        </SeriesDirective>
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </Grid>
                            <Grid container style={{ margin: "20px 0 -10px 30px", borderRadius: 5 }} justifyContent="left" alignItems="center">
                                <Typography variant="h2" color='primary' style={{ textAlign: "center", marginBottom: 10 }}>
                                    {/* {`Contract Sales Drivers Trend: Tier Formula - ${tier},  Rebate Formula - ${rebate}`} */}
                                    {trendTitle}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={6}
                                className={classes.container}
                            >
                                {flexKeyFigures && Object.keys(flexKeyFigures).length > 0 &&
                                    <div style={{ margin: "-20px 10px 0px 14px" }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    {Object.keys(flexKeyFigures)
                                                        .filter(([k, v], index) => index === 0)
                                                        .map(item => {
                                                            return (
                                                                <TableRow >
                                                                    <TableCell align='center'>Contract No - {appId}</TableCell>
                                                                    {flexKeyFigures && flexKeyFigures[item].map(detail => {
                                                                        return (
                                                                            <>
                                                                                <TableCell align='center'>{detail.billing_date}</TableCell>
                                                                                <TableCell align='center'>Variance</TableCell>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </TableRow>
                                                            )
                                                        })}

                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(flexKeyFigures).map(item => {
                                                        return (
                                                            <TableRow key={item}>
                                                                <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                    {getCurrentValueForKey(item) || item.replace(/_/g, ' ')}
                                                                </TableCell>
                                                                {flexKeyFigures[item].map(detail => {
                                                                    return (
                                                                        <>
                                                                            <TableCell align='center'>{detail.key_figure_value ? parseFloat(detail.key_figure_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</TableCell>
                                                                            <TableCell align='center'>{parseFloat(detail.variance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                                        </>
                                                                    )
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                }

                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </div >
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        accrualVarianceChartApiData: state.initialData.accrualVarianceChartData,
        accrualVarianceGraphData: state.initialData.rebateTrendsSecond,
        labelNewAPIData: state.addMultipleConfigurationData.defaultValueLblDisp,
        // dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        // sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadAccrualVarianceGraph: (s, e, type, applicationType, sourceDataType) => dispatch(loadRebateTrendsSecondAPI(s, e, type, applicationType, sourceDataType)),
        // loadRebateTrendsAPI: (s, e, type, applicationType, sourceDataType, id) => dispatch(loadRebateTrendsSecondAPI(s, e, type, applicationType, sourceDataType, id)),
        // getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        // fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        // loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        // onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RebateTrends);