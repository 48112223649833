import React, { useEffect, forwardRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    useMediaQuery, Button, IconButton, Fab, Typography,
    Tooltip
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload, SearchSharp, Edit } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';
import Moment from 'moment';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));



const DailySimulationsResult = props => {

    const { className } = props;
    const theme = useTheme();
    const history = useHistory();
    const page = history.location.state;
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);



    const columns = [
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.contract_number}</a>
        },
        {
            field: 'start_date',
            title: 'Start Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.start_date}</a>
        },
        {
            field: 'end_date',
            title: 'End Date',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.end_date}</a>
        },
        // {
        //     field: 'customer_number',
        //     title: 'Customer Number',
        //     type: 'string',
        //     render: (rowData) => <a className={classes.hover} > {rowData.customer_number}</a>
        // },
        // {
        //     field: 'attribute_name',
        //     title: 'Aggregation Attribute Name',
        //     type: 'string',
        //     render: (rowData) => <a className={classes.hover} > {rowData.attribute_name}</a>
        // },
        {
            field: 'attribute_name',
            title: 'Aggregation Attribute Name',
            type: 'string',
            render: (rowData) => {
                // Extract the attribute name from the array and ensure it's a string
                const attributeName = Array.isArray(rowData.attribute_name) ? rowData.attribute_name[0] : '';

                // Convert the attribute name to a human-readable format
                const formattedName = attributeName && attributeName.length > 0
                    ? attributeName
                        .replace(/_/g, ' ') // Replace underscores with spaces
                        .split(' ') // Split into words
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
                        .join(' ') // Join words back into a single string
                    : ''; // If length is 0, set formattedName to an empty string

                return (
                    <a className={classes.hover}>
                        <Tooltip title={formattedName}>{formattedName}</Tooltip>
                    </a>
                );
            }
        },
        {
            // field: 'attribute_value',
            field: 'attribute_value',
            title: 'Aggregation Attribute Value',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.attribute_value}</a>
        },
        {
            field: 'calculation_type',
            title: 'Calculation Type',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.calculation_type}</a>
        },
        // {
        //     field: 'formula',
        //     title: 'Formula',
        //     type: 'string',
        //     // cellStyle: { minWidth: 300 },
        //     render: (rowData) => {
        //         var cleanFormula = rowData.formula.replace(/{|}/g, '');
        //         cleanFormula = cleanFormula
        //             .replace(/_/g, ' ')
        //             .split(' ')
        //             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        //             .join(' ');
        //         return <a className={classes.hover}><Tooltip title={cleanFormula}>{cleanFormula}</Tooltip></a>;
        //     }
        // },
        {
            field: 'formula_name',
            title: 'Formula Name',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.formula_name}</a>
        },
        {
            field: 'formula_value',
            title: 'Formula Value',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.formula_value}</a>
        },
        {
            field: 'incentive_basis',
            title: 'Incentive Basis',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.incentive_basis}</a>
        },
        {
            field: 'incentive_amount',
            title: 'Incentive Amount',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.incentive_amount}</a>
        },
        // {
        //     field: 'Actions',
        //     title: 'Actions',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData =>
        //         <div>
        //             <>
        //                 <IconButton
        //                     classes={{ root: classes.IconButton }}
        //                     // onClick={() => editCoverageMapping(rowData.id)}
        //                     size="large">
        //                     <Edit color="disabled" style={{ fontSize: 20 }} />
        //                 </IconButton>
        //                 <IconButton
        //                     classes={{ root: classes.IconButton }}
        //                     // onClick={() => props.deleteCoverageMapping(rowData.id)}
        //                     size="large">
        //                     <DeleteForever color="disabled" style={{ fontSize: 20 }} />
        //                 </IconButton>
        //             </>
        //         </div>
        // },
    ];

    // console.log(props.dailySimulationData)
    useEffect(() => {
        var tempRows = [];
        if (props.dailySimulationData && props.dailySimulationData.records && props.dailySimulationData.records.length > 0) {
            props.dailySimulationData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        contract_number: e.contract_number,
                        // customer_number: e && e.attribute_key_values && e.attribute_key_values["Customer Number"] ? e.attribute_key_values["Customer Number"] : '',
                        attribute_name: e && e.attribute_key_values && e.attribute_key_values ? Object.keys(e.attribute_key_values) : '',
                        attribute_value: e && e.attribute_key_values && e.attribute_key_values ? Object.values(e.attribute_key_values) : '',
                        calculation_type: e.calculation_type,
                        formula: e.formula,
                        formula_name: e.formula_name,
                        formula_value: e.formula_value,
                        incentive_basis: e.incentive_basis,
                        incentive_amount: e.incentive_amount,
                        start_date: Moment.utc(e.start_date).format('MM/DD/YYYY'),
                        end_date: Moment.utc(e.end_date).format('MM/DD/YYYY'),
                    });
                })
            setDataRows(tempRows);
        }
    }, [props.dailySimulationData]);
    console.log("props.dailySimulationData", props.dailySimulationData);



    const addContract = () => {
        localStorage.removeItem('currentAddContractID');
        sessionStorage.setItem('mode', 'add');
    }

    function editCoverageMapping(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        // history.push({
        //     pathname: '/coverage-cost-mapping/edit-coverage-cost-mapping/' + id,
        //     state: 'editCoverageMapping',
        //     id: id
        // });
        // localStorage.setItem('coverageMappingEditId', id);
    }


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Typography variant="h1" color='primary'> Daily Simulation Report Result </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {/* <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            component={CustomRouterLink}
                            to='/coverage-cost-mapping/excel-upload'
                            onClick={addContract}
                        >
                            <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                        </Fab> */}
                        {/* <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            component={CustomRouterLink}
                            to='/coverage-cost-mapping/search'
                        >
                            <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                        </Fab> */}
                    </div>
                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div className={classes.noDataContainer}>
                        There is no data to show now.
                    </div>
                }

            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        dailySimulationData: state.addMultipleConfigurationData.dailySimulationsReportData,
    }
};

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DailySimulationsResult);