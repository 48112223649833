import React, { useEffect, forwardRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    useMediaQuery, Button, IconButton, Fab, Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload, SearchSharp, Edit } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import { getCoverageCostData, deleteCoverageCostData } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import secureLocalStorage from 'react-secure-storage';
import Moment from 'moment';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const CoverageCost = props => {
    // useEffect(() => {
    //     props.getCoverageCostData();
    // }, []);
    const { className } = props;
    const theme = useTheme();
    const history = useHistory();
    const page = history.location.state;
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const columns = [
        {
            field: 'product_line',
            title: 'Product Line',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.product_line}</a>
        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.material_number}</a>
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.country}</a>
        },
        {
            field: 'coverage_type',
            title: 'Coverage Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.coverage_type}</a>

        },
        {
            field: 'coverage_length',
            title: 'Coverage Length',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.coverage_length}</a>
        },
        {
            field: 'order_reason',
            title: 'Order Reason',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.order_reason}</a>
        },
        {
            field: 'coverage_cost',
            title: 'Coverage Cost',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.coverage_cost}</a>
        },
        {
            field: 'valid_from',
            title: 'Valid From',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.valid_from).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'valid_to',
            title: 'Valid To',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.valid_to).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_coverage_cost-' + sessionStorage.getItem('application')) &&
                        <>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editCoverageCost(rowData.id)}
                                size="large">
                                <Edit color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>

                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => props.deleteCoverageCost(rowData.id)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        </>}
                </div>
        },
    ];
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        var tempRows = [];
        if (page === "CoverageCost" && props.coverageCostData && props.coverageCostData.records && props.coverageCostData.records.length > 0) {
            props.coverageCostData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        product_line: e.product_line,
                        country: e.country,
                        coverage_type: e.coverage_type,
                        coverage_length: e.coverage_length,
                        order_reason: e.order_reason,
                        material_number: e.material_number,
                        coverage_cost: e.coverage_cost,
                        valid_from: e.valid_from,
                        valid_to: e.valid_to
                    });
                })
            setDataRows(tempRows);
        }
    }, [props.coverageCostData]);


    const addContract = () => {
        localStorage.removeItem('currentAddContractID');
        sessionStorage.setItem('mode', 'add');
    }

    function editCoverageCost(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/coverage-cost/edit-coverage-cost/' + id,
            state: 'editCoverageCost',
            id: id
        });
        localStorage.setItem('coverageCostEditId', id);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Coverage Cost </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_coverage_cost-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/coverage-cost/coverage-cost-excel-upload'
                                onClick={addContract}
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                component={CustomRouterLink}
                                to='/coverage-cost/search'
                            >
                                <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                            </Fab>
                            {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlined />}
                            classes={{
                            startIcon: !isDesktop && classes.startIcon,
                            root: !isDesktop && classes.container
                            }}
                            component={CustomRouterLink}
                            to='/coverage-cost/coverage-cost-excel-upload'
                            onClick={addContract}
                            style={{ marginLeft: 10 }}
                        >
                            Upload Excel
                        </Button> */}
                        </div>}
                </div>
                {page === "CoverageCost" && dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div className={classes.noDataContainer}>
                        Display Coverage Cost by clicking on Search Icon.
                    </div>
                }

            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        coverageCostData: state.promotionData.promotionsSearchData,
        salesForecast: state.dataSetupData.uploadCoverageCost,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // getCoverageCostData: () => dispatch(getCoverageCostData()),
        deleteCoverageCost: (id) => dispatch(deleteCoverageCostData(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverageCost);