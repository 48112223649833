import React, { useEffect } from 'react';
import {
    Grid, Button, Card, CardHeader,
    Typography, FormLabel,
    Select, MenuItem, OutlinedInput, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getPriceCalForList, runPriceCalcForSimulation, getQualifierConfig, getPriceCatalogFilter } from '../../../redux/actions';
import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import RotateLoader from "react-spinners/RotateLoader";
import HashLoader from 'react-spinners/HashLoader'
import MasterDataComponent from '../../../components/Inputs/FilledComponents/MasterDataComponent';


momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 18,
        height: 36
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
    filterContainer: {
        marginBottom: 10,
        display: 'flex'
    }
}));

const PriceCatalog = props => {
    const classes = useStyles();
    const { className } = props;
    const [catalogData, setCatalogData] = React.useState({});
    const [catalogFormData, setCatalogFormData] = React.useState({});
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [quantity, setQuantity] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCatalogName, setPriceCatalogName] = React.useState('');
    const [activeFilters, setActiveFilters] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [catalogFilters, setcatalogFilters] = React.useState({});
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateEnd, setPricingDateEnd] = React.useState(null);
    const [customerFilter, setCustomerFilter] = React.useState([]);
    const type = ['organization Attribute', 'Customer Attribute', 'Material Attribute', 'Flex Attribute']
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getConfigData(1, 10);
        setPricingDateStart(Moment.utc(new Date().getMonth() + 1 + "/" + (new Date().getDate()) + "/" + new Date().getFullYear()))

    }, []);
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData
                .filter((item, i) => item.formula_type == 'Price Catalog')
                .map(e => {
                    newArray.push(e.formula_name)
                })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {
        if (props.filterFields) {
            setcatalogFilters(props.filterFields);
            if (props.filterFields['Customer Attribute'] && props.filterFields['Customer Attribute'].length > 0) {
                var custFilter = {}
                props.filterFields['Customer Attribute'].map(item => {
                    custFilter = { ...custFilter, [item.key]: [] }
                })
                //setCustomerFilter(custFilter)
                setCatalogFormData({ ...catalogFormData, ['Customer Attribute']: custFilter })
            }
            setActiveFilters(false)
        }
    }, [props.filterFields])
    const [open, setOpen] = React.useState(false);

    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
        setPriceCatalogName(newValue)
        setActiveFilters(true)
        props.getFilterData(newValue)
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    const handlePricingDateEnd = (newValue) => {
        setPricingDateEnd(Moment(newValue));
    }

    function handleAttributeValue(value, key, type) {
        console.log(value)
        var temp = []
        setCatalogData((prevState) => ({
            ...prevState,
            [type]: { ...catalogData[type], [key]: value }
        }));

        value.map((item, i) => temp.push(item.key))
        setCatalogFormData((prevState) => ({
            ...prevState,
            [type]: { ...catalogFormData[type], [key]: temp }
        }));

    }
    function handleVirtual(value) {
        setMaterialNumber(value)
    }
    const handleClear = () => {
        setPriceCalcFormula('');
        setPricingDateStart(null);
        setPricingDateEnd(null);
        setPriceCatalogName('')
        setCatalogFormData({})
        setCatalogData({})
    }
    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleDialog = () => {
        /*if (catalogFormData['Customer Attribute']) {
            var filter = []
            var data = { ...catalogFormData['Customer Attribute'] }
            filter = catalogFormData['Customer Attribute'] && Object.keys(catalogFormData['Customer Attribute'])
            customerFilter.map(item => {
                if (!filter.includes(item)) {
                    data = { ...data, [item]: [''] }
                }
            })
            catalogFormData['Customer Attribute']=data
        }
        console.log(data)*/
        const formData = {
            "formula_procedure": priceCalcFormula,
            "price_catalog_name": priceCatalogName,
            "start_date": pricingDateStart ? Moment.utc(pricingDateStart).format('YYYY-MM-DD') : null,
            "end_date": pricingDateEnd ? Moment.utc(pricingDateEnd).format('YYYY-MM-DD') : null,
            "price_attribute": Object.entries(catalogFormData).length > 0 ? catalogFormData : null
        };
        setOpen(false);
        setSubmit(true)
        props.onSubmit(formData);
    }


    return (
        <LoadingOverlay
            active={props.loading && !activeFilters}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography classes={{ root: classes.headFont }} variant="h1" color='primary' >
                        Price Catalog
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >

                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Pricing Calculation Formula
                                </FormLabel>
                                <Select
                                    value={priceCalcFormula}
                                    onChange={(e) => handlePriceCalFormula(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Effective  Date
                                </FormLabel>
                                <div style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={pricingDateStart}
                                                onChange={handlePricingDateStart}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 5
                                                    }
                                                }}
                                                value={pricingDateEnd}
                                                onChange={handlePricingDateEnd}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                disabled={!pricingDateStart ? true : false}
                                                minDate={pricingDateStart}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Price Catalog Name
                                </FormLabel>
                                <OutlinedInput
                                    value={priceCatalogName}
                                    style={{ marginTop: 10 }}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setPriceCatalogName(e.target.value)}
                                />
                            </Grid>
                            {/* <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Material Number
                                </FormLabel>
                                <MasterDataComponent attributeName={'material_number'} withOutLabel={true} id={'virtual_demo'} multiple onChange={handleVirtual}/>
                            </Grid>*/}
                        </Grid>
                        <LoadingOverlay
                            active={props.loading && activeFilters}
                            styles={{
                                spinner: (base) => ({
                                    ...base,
                                    width: '50px',
                                    height: 'auto',
                                    '& svg circle': {
                                        stroke: '#64C4B4'
                                    }
                                }),
                                overlay: (base) => ({
                                    ...base,
                                    background: 'rgba(52, 52, 52, 0)'
                                }),
                                content: (base) => ({
                                    ...base,
                                    color: '#64C4B4'
                                })
                            }}
                            spinner={<HashLoader />}

                        >
                            {priceCalcFormula && Object.entries(catalogFilters) && Object.entries(catalogFilters).length > 0 &&
                                Object.entries(catalogFilters)
                                    .sort(function ([k1, v1], [k2, v2]) {
                                        return type.indexOf(k1) - type.indexOf(k2);
                                    })
                                    .map(([key, value]) => (
                                        <Card>
                                            <CardHeader
                                                title={key}
                                                titleTypographyProps={{ variant: 'h3', color: 'primary' }}
                                                style={{ paddingLeft: 0 }}
                                                color='primary'
                                            />
                                            <Grid container className={classes.filterContainer}>
                                                {value && value.length > 0 && value.map((item, i) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            md={4}
                                                            xs={12}
                                                            classes={{ root: classes.gridContainer }}
                                                        >
                                                            <FormLabel className={classes.chargeBackLabel}
                                                                required={key == 'Organization Attribute' || key == 'Customer Attribute' ? true : false}>
                                                                {item.label}
                                                            </FormLabel>
                                                            {item.value && item.value.length > 0 ?
                                                                <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key} multiple attributeList={item.value ? item.value : []} onChange={(value) => handleAttributeValue(value, item.key, key)} />
                                                                :
                                                                <ChipInput
                                                                    classes={{
                                                                        root: classes.rootContainer,
                                                                        chip: classes.chip,
                                                                        input: classes.input,
                                                                        inputRoot: classes.inputRoot,
                                                                        chipContainer: classes.chipContainer,
                                                                        label: classes.chipLabel
                                                                    }}
                                                                    onChange={(value) => handleAttributeValue(value, item.key, key)}
                                                                    //value={filterData[item.field_id] ? filterData[item.field_id] : []}
                                                                    // onAdd={(chips) => handleFilterData(chips, item, 'chip')}
                                                                    // onDelete={(chip,index) => handleDeleteFilterData(chip,index,item)}
                                                                    disableUnderline={true}
                                                                    className={clsx({
                                                                        [classes.textInput]: true,
                                                                    })}
                                                                />

                                                            }
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Card>
                                    ))}
                        </LoadingOverlay>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    priceCalcFormula && pricingDateStart && pricingDateEnd &&
                                        (catalogFilters['Organization Attribute'] ? catalogFormData['Organization Attribute'] && Object.values(catalogFormData['Organization Attribute']).filter(item => item.length !== 0).length > 0 : true) &&
                                        (catalogFilters['Customer Attribute'] ? catalogFormData['Customer Attribute'] && Object.values(catalogFormData['Customer Attribute']).filter(item => item.length != 0).length > 0 : true)
                                        ? false : true}

                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading && submit} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Price Catalog ?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay >
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.pricingData.priceCalForListData,
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
        optionalFieldData: state.pricingData.qualifierConfigData,
        filterFields: state.pricingData.catalogFilterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit, 'price catalog')),
        onSubmit: (formData) => dispatch(runPriceCalcForSimulation(formData, 'priceCatalog')),
        getQualifierConfig: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
        getFilterData: (formula) => dispatch(getPriceCatalogFilter(formula)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PriceCatalog);