import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, TextField,
    Card, OutlinedInput, FormLabel, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, ListItemText, Checkbox,
    Chip,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addPricingBatchJobSetup, getIntegrationSetupData, getPriceCalForList } from '../../../../redux/actions';
import { getAllForecastLevelDropDwon, getAllForecastLevelData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import momentt from 'moment-timezone';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ChipInput from 'material-ui-chip-input';
import { useHistory, Link } from "react-router-dom";
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
        marginTop: 20

    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        lineheight: "1.1876rem",


    },
    inputTwoLine: {
        marginTop: 2,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    select1: {
        width: '100%',
        marginTop: 2
    },
    selectedItem: {
        color: theme.palette.text.grey,
        height: '26px'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 18,
        margin: 5,
        marginLeft: 8,
        marginBottom: 4,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: -23,
        height: 37,
        padding: 0,
        marginTop: 19,
        marginBottom: 14
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 2,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
}));

const AddProfitOptBatchJobSetup = props => {
    const classes = useStyles();

    const [openEff, setOpenEff] = React.useState(false);
    const [forecasteLevel, setForecastLevel] = React.useState('');
    const [forecastingPeriod, setForecastingPeriod] = React.useState('');
    const [forecastingAttributesArr, setForecastingAttributesArr] = React.useState([]);
    const [forecastingTarget, setForecastingTarget] = React.useState('');
    const [forecastPeriodArray, setForecastPeriodArray] = React.useState([]);
    const [forecastAttributesArray, setForecastAttributesArray] = React.useState([]);
    const [forecastTargetArray, setForecastTargetArray] = React.useState([]);
    const [forecastAttrTemp, setForecastAttrTemp] = React.useState([]);
    const [selected, setSelected] = React.useState([]);


    const handleForecastLevel = (e) => {
        setForecastAttrTemp([]);
        setForecastingAttributesArr([]);
        setForecastingPeriod('');
        props.forecastLevelData.map(item => {
            if (item.forecasting_level === e) {
                setForecastLevel(item.forecasting_level);
                setForecastAttributesArray(item.forecasting_attributes);
                setForecastTargetArray([item.forecasting_target]);
                setForecastAttributesArray(item.forecasting_attributes);
                setForecastPeriodArray([item.forecasting_period])
            }
        })
    }
    const handleForecastingPeriod = (e) => {
        setForecastingPeriod(e.target.value)
    }
    const handleForecastingTarget = (e) => {
        setForecastingTarget(e.target.value)
    }

    useEffect(() => {
        setOpenEff(true);
        props.getAllForecastLevelDropDwon();
        setForecastingPeriod('')
    }, []);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [salesStartDate, setSalesStartDate] = React.useState(null);
    const [salesEndDate, setSalesEndDate] = React.useState(null);

    useEffect(() => {
        props.getAllForecastLevelData();
    }, [])
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    function handleOpen() {
        setSelected(forecastAttrTemp)
        setListOpen(true)
    }
    const [listOpen, setListOpen] = React.useState(false);

    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    const handleAttributeValues = (value, item) => {
        var attributeObject = [];
        attributeObject.push({
            [item]: value
        });
        if (item === 'customer_number') {
            value = [value]
        }
        setAttributeObjectAPI(prevState => ({
            ...prevState,
            [item]: value
        }));

    }
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setForecastAttrTemp(newSelected)
        setSelected(newSelected)
        var keys = Object.keys(attributeObjectAPI);
        var object = attributeObjectAPI
        keys.map(item => {
            if (!newSelected.includes(item)) {
                delete attributeObjectAPI[item];
            }
        })

    }

    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    const handleForecastingAttributes = (e) => {
        setForecastingAttributesArr(e.target.value)
    }

    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [quantity, setQuantity] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [calculationDateType, setCalculationDateType] = React.useState('');
    const [numDays, setNumDays] = React.useState(0);
    const history = useHistory();
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }

    useEffect(() => {
        props.getIntegrationSetupData();
        props.getConfigData(1, 10);
        setPricingDateStart(Moment.utc(new Date().getMonth() + 1 + "/" + (new Date().getDate()) + "/" + new Date().getFullYear()))
    }, []);
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push(e.formula_name)
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {

        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'accrual_calendar') {
                    setPlannedStartCalendarArray(item)
                }
            })
        }
    }, [props.dropdownData])
    const BatchJobTypesArray = [
        'Margin Simulation'
    ];
    const frequencyTypesArray = [
        'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    function handleCustomerNumber(newValue) {
        setCustomerNumber([...customerNumber, newValue])
    }
    function handleCustomerNumberDelete(newValue) {
        var deletedCustomerNumber = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomerNumber)
    }
    function handleMaterialNumber(newValue) {
        setMaterialNumber([...materialNumber, newValue])
    }
    function handleMaterialNumberDelete(newValue) {
        var deletedMaterialNumber = materialNumber.filter(item => item !== newValue)
        setMaterialNumber(deletedMaterialNumber)
    }
    function handleQuantityAdd(newValue) {
        setQuantity([...quantity, newValue]);
    }
    function handleQuantityDelete(newValue) {
        var deletedQuantity = quantity.filter(item => item !== newValue)
        setQuantity(deletedQuantity)
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    function handleContinue() {
        setOpen(false);
    }

    function handleClear() {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setQuantity([]);
        setPriceCalcFormula('');
        setPricingDateStart(null);
        setCalculationDateType('');
    }
    function handleOnSubmit() {
        var finalObj = attributeObjectAPI;
        var objectKeys = [];
        objectKeys = Object.keys(finalObj);
        forecastingAttributesArr.map(item => {
            if (objectKeys.includes(item)) {
                if (finalObj[item] == []) {
                    finalObj[item] = ['*'];
                }
            } else {
                finalObj[item] = ['*'];
                setAttributeObjectAPI(finalObj);
            }
        })
        var data = {
            "app_type": sessionStorage.getItem('application'),
            "batch_job_type": batchJobType,
            "batch_job_name": batchJobName,
            "planned_date_type": plannedDateType,
            "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').local().format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().local().format('YYYY-MM-DDThh:mm:ssZ'),
            "planned_start_calendar": plannedStartCalendar,
            "planned_start_time": plannedStartTime.concat(":00"),
            "frequency": frequency,
            "batch_filter_criteria": [{
                "filters": {
                    "forecasting_level": forecasteLevel,
                    "forecasting_period": forecastingPeriod,
                    "forecasting_attributes": finalObj,
                    "forecasting_target": forecastingTarget,
                    "start_date": Moment(startDate).format('MM-DD-YYYY'),
                    "end_date": Moment(endDate).format('MM-DD-YYYY'),
                    "sales_data_start_date": Moment(salesStartDate).format('MM-DD-YYYY'),
                    "sales_data_end_date": Moment(salesEndDate).format('MM-DD-YYYY')
                }
            }]
        }
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
    }

    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value)
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/profitopt-batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <FormLabel className={classes.formLabel}>
                                        Forecast Level
                                    </FormLabel>
                                    <Select
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        value={forecasteLevel}
                                        onChange={(e) => handleForecastLevel(e.target.value)}
                                    >
                                        {props.forecastLevelData && props.forecastLevelData.length > 0
                                            && props.forecastLevelData
                                                ?.map((item, index) => (
                                                    <MenuItem key={index} value={item.forecasting_level ? item.forecasting_level : null}>
                                                        {item.forecasting_level ? item.forecasting_level : ''}
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required >
                                            Sales Data Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginTop: 2,
                                                            height: 37
                                                        }
                                                    }}
                                                    value={salesStartDate}
                                                    onChange={date => setSalesStartDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required >
                                            Sales Data End Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 2,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 37
                                                        }
                                                    }}
                                                    value={salesEndDate}
                                                    onChange={date => setSalesEndDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={salesStartDate ? salesStartDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required >
                                            Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginTop: 2,
                                                            height: 37
                                                        }
                                                    }}
                                                    value={startDate}
                                                    onChange={date => setStartDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required >
                                            End Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 2,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: 37
                                                        }
                                                    }}
                                                    value={endDate}
                                                    onChange={date => setEndDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={startDate ? startDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                {/* after selecting forecast level */}
                                {forecasteLevel &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        style={{ marginTop: "-8px" }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Forecasting Attribute
                                            </FormLabel>
                                            <Select
                                                value={forecastingAttributesArr}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                onOpen={handleOpen}
                                                onClose={() => setListOpen(false)}
                                                open={listOpen}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                renderValue={(selected) => (
                                                    <div className={classes.chips}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value.replace(/_/g, ' ')} className={classes.chip} />
                                                        ))}
                                                    </div>
                                                )}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                onChange={handleForecastingAttributes}
                                                multiple
                                            >
                                                {forecastAttributesArray &&
                                                    forecastAttributesArray.map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked={selected.indexOf(item) > -1 ? true : false} />
                                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                {/* <div className={classes.dropdownAction}>
                                                    <Button variant="outlined" color="primary" className={classes.btn}
                                                        onClick={() => setListOpen(false)}
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button variant="contained" color="primary" className={classes.btn}
                                                        onClick={onAdd}
                                                    >
                                                        Apply
                                                    </Button>
                                                </div> */}
                                            </Select>
                                        </div>
                                    </Grid>
                                }
                                {forecastAttrTemp.map((item) => {
                                    return (
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            style={{ paddingRight: 15, marginTop: "-8px" }}
                                        >
                                            <FormLabel
                                                className={classes.formLabel}
                                                style={{ textTransform: 'capitalize' }}>
                                                {item.replace(/_/g, ' ')}
                                            </FormLabel>
                                            <ChipInput
                                                classes={{
                                                    root: classes.rootContainer,
                                                    chip: classes.chip,
                                                    input: classes.input,
                                                    inputRoot: classes.inputRoot,
                                                    chipContainer: classes.chipContainer,
                                                    label: classes.chipLabel
                                                }}
                                                onChange={(value) => handleAttributeValues(value, item)}
                                                disableUnderline={true}
                                                className={clsx({
                                                    [classes.textInput]: true
                                                })}
                                                style={{ display: item != 'customer_number' ? 'block' : 'none' }}
                                            />
                                            <Autocomplete
                                                options={customerNumberList}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                                )}
                                                filterOptions={filterOptions}
                                                value={attributeObjectAPI['customer_number']}
                                                style={{ marginTop: 19, marginBottom: 10, display: item != 'customer_number' ? 'none' : 'block' }}
                                                onChange={(event, newValue) => handleAttributeValues(newValue, item)}
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                        {customerNameListArray[customerNumberList.indexOf(option)] ? customerNameListArray[customerNumberList.indexOf(option)] : ''} - ({option})
                                                    </li>
                                                )}

                                                classes={{
                                                    option: {
                                                        borderBottom: `1px solid red`,
                                                        // Hover
                                                        '&[data-focus="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                        // Selected
                                                        '&[aria-selected="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                    },
                                                    listbox: {
                                                        padding: 0,
                                                    },
                                                    inputRoot: {
                                                        borderRadius: '0px !important'
                                                    }
                                                }}
                                            />

                                        </Grid>
                                    );
                                })}
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Forecasting Target
                                        </FormLabel>
                                        <Select
                                            className={clsx({
                                                [classes.select1]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            value={forecastingTarget}
                                            onChange={handleForecastingTarget}
                                        >
                                            {forecastTargetArray && forecastTargetArray.map((item) => (
                                                <MenuItem key={item} value={item} style={{ textTransform: 'capitalize' }}>
                                                    {item.replace(/_/g, ' ')}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Forecasting Period
                                        </FormLabel>
                                        <Select
                                            className={clsx({
                                                [classes.select1]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            value={forecastingPeriod}
                                            onChange={handleForecastingPeriod}
                                        >
                                            {forecastPeriodArray?.map((item) => (
                                                <MenuItem key={item} value={item} style={{ textTransform: 'capitalize' }}>
                                                    {item.replace(/_/g, ' ')}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card >

                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Type
                                            </FormLabel>
                                            <Select
                                                value={batchJobType}
                                                onChange={(e) => setBatchJobType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {BatchJobTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => setFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {frequencyTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>


                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required >
                                                Batch Job Name
                                            </FormLabel>
                                            <OutlinedInput
                                                style={{ height: '37px' }}
                                                value={batchJobName}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setBatchJobName(e.target.value)} />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => setPlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency ? true : false}
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                <MenuItem value='static'>
                                                    Static
                                                </MenuItem>
                                                <MenuItem value='dynamic'
                                                    style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>

                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none' }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 0,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: '34px'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none' }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select

                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select1]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                    .filter(item => item != 'Manual')
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>

                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>

                                            <TextField
                                                id="time"
                                                type="time"
                                                //defaultValue=""
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                // className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%', height: '37px' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>


                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/profitopt-batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleContinue()} color="primary" autoFocus variant="contained"
                            disabled={!batchJobType || !batchJobName || !plannedStartTime || !frequency || !plannedDateType ? true
                                : plannedStartDate || plannedStartCalendar ? false : true
                            }
                        >
                            Continue

                        </Button>
                    </DialogActions>
                </Dialog>



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>


                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}

                        disabled={startDate && endDate && forecastingTarget && forecastingAttributesArr && forecastingAttributesArr.length > 0 &&
                            forecastingPeriod && salesStartDate && salesEndDate
                            ? false : true}
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addPricingBatchJobSetup(data)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit)),
        getAllForecastLevelDropDwon: () => dispatch(getAllForecastLevelDropDwon()),
        getAllForecastLevelData: () => dispatch(getAllForecastLevelData()),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        integrationSetupData: state.customerData.integrationSetupData,
        configData: state.pricingData.priceCalForListData,
        dropdwonForeCast: state.forecastData.dropdwonForeCast,
        loading: state.forecastData.loading,
        forecastLevelData: state.forecastData.foreCastLevelData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProfitOptBatchJobSetup);