import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { createTheme } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import { NavLink as RouterLink } from "react-router-dom";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { addSalesBasis, getBomDetails, deleteCustomerMasterData, getAllSalesBasis } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Visibility } from '@mui/icons-material';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';
import Moment from 'moment';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    caption: {
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    }, paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    addColor: {
        color: 'black',
        marginRight: 20,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const SetupSalesBasisSeperate = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    useEffect(() => {
        props.getAllSalesBasis(1, 10);
    }, []);
    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);
    useEffect(() => {
        if (props.salesBasisAll) {
            setSalesBasisAll(props.salesBasisAll)
        }
    }, [props.salesBasisAll])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getMembership(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getMembership(1, event.target.value);
    };
    const cloneContract = (id) => {
        props.cloneContract(id);
    }
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    function editMember(id, bomMaterial, bomDescription) {
        props.getMembershipDetails(id, bomMaterial, bomDescription)
        navigateToEdit(id, bomMaterial, bomDescription);
    }
    function navigateToEdit(id, ownerId, ownerName, classOfTrade) {
        history.push({
            pathname: '/sales-basis/edit-sales-basis/' + id,
            id: id
        });
    }
    function viewGlobalEligibility(item) {
        var id = item.ID;
        history.push({
            pathname: '/sales-basis/view-global-eligiblilty/' + id,
            id: id
        });
    }
    // const deleteData = (id) => {
    //     props.deleteSalesBasis('', 'delete', id);
    // }
    const [salesBasisAll, setSalesBasisAll] = React.useState([]);

    const handleDeleteClick = (id) => {
        setSelectedId(id);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        props.deleteSalesBasis('', 'delete', selectedId);
        setOpenDialog(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Global Eligibility</Typography>
                    {/* <div style={{ fontSize: 22,marginLeft:10,marginTop:10}}>Global Eligibility</div> */}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_global_eligibility-' + sessionStorage.getItem('application')) &&
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={() => history.push('/sales-basis/add-sales-basis')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>}
                </div>
                {salesBasisAll && salesBasisAll.length > 0 ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Contract Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Condition ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Attribute Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Option</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Effective Start Date</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Effective End Date</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Attribute Value</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Options</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {salesBasisAll.map(item => {
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{item.app_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{item.contract_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{item.data_source_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{item.condition_id}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }} >{item.attribute_name.replace(/_/g, ' ')}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{item.option}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }} >{item.attribute_value.toString()}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => viewGlobalEligibility(item)}
                                                    size="large">
                                                    <Visibility color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_global_eligibility-' + sessionStorage.getItem('application')
                                                ) && (
                                                        <>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => editMember(item.ID, item.bom_material, item.bom_description)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                // onClick={() => deleteData(item.ID)}
                                                                onClick={() => handleDeleteClick(item.ID)}
                                                                size="large">
                                                                <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </>
                                                    )}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        salesBasisAll: state.customerData.salesBasisAll,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAllSalesBasis: (pagination, limit) => dispatch(getAllSalesBasis(pagination, limit)),
        getMembershipDetails: (id, bomMaterial, bomDescription) => dispatch(getBomDetails(id, bomMaterial, bomDescription)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'SalesBundle')),
        deleteSalesBasis: (data, type, id) => dispatch(addSalesBasis(data, type, id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SetupSalesBasisSeperate);