import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Card,
    CardContent,
    CardActions,
    Button,
    Grid
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {
    runContractReports, runDynamicQuery, runCalculationReports, runFinancialReports,
    runInvoiceReports, getQueryListAdmin, loadTargetData
} from '../../redux/actions';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 10,
        marginTop: 16,
        padding: theme.spacing(2),
    },
    card: {
        borderRadius: 10,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 220,
        width: '100%',
    },
    cardContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    gridContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    gridItem: {
        display: 'flex',
    },
    button: {
        marginTop: theme.spacing(1),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15,
    },
}));
const SelectOperationalReports = props => {
    const [flag, setFlag] = React.useState(true);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [targetReportsData, setTargetReportsData] = React.useState([]);
    const [operationalReportsData, setOperationalReportsData] = React.useState([
        {
            id: 'R005', functionality: 'Financial Postings',
            sourceDataType: '',
            reportName: 'Postings Summary',
            reportDescription: 'List all the postings details as well as external postings status and document numbers',
            url: '/financial-approvals-reports'
        },
        {
            id: 'R017', functionality: 'Customer Payment Summary',
            sourceDataType: '',
            reportName: 'Customer Payment Summary',
            reportDescription: 'List all the Payment details as well as external Payment status and document numbers',
            url: '/customer-payment-summary-reports'
        },
        {
            id: 'R010', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Accrual vs. Payment',
            reportDescription: 'List the Accrual and Payment amount per contract per period to help identify the difference between Accrual and Payment',
            url: '/accrual-vs-Payment-reports'
        },
        {
            id: 'R015', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Postings Approval History',
            reportDescription: 'This report will show the approvals history for the Audit purpose',
            url: '/approval-posting-history-resports'
        },
        {
            id: 'R013', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Potential Duplicate Payments',
            reportDescription: 'Help identify any duplicate payments based on flexible attributes and key figures for which exact same amount was posted',
            url: '/duplicate-payment-resports'
        },
        {
            id: 'R012', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Incentive Variance Analysis',
            reportDescription: 'Incentive Variance Analysis by Attribute',
            url: '/incentive-variance-analysis-resports'
        },
        {
            id: 'R001', functionality: 'Calculation Simulations',
            sourceDataType: '',
            reportName: 'Simulation Summary',
            reportDescription: 'List all the simulation summary executed for the contract by simulation number',
            url: '/calculation-summary-reports'
        },
        // {
        //     id: 'R002', functionality: 'Calculation Simulations',
        //     sourceDataType: 'Direct - Purchase Data',
        //     reportName: 'Simulations Details',
        //     reportDescription: 'Simulation details by purchase line',
        //     url: '/calculation-simulation-reports'
        // },
        // {
        //     id: 'R007', functionality: 'Contract Setup',
        //     sourceDataType: '',
        //     reportName: 'Contract Change',
        //     reportDescription: 'Contract Change Log',
        //     url: '/contract-change-reports'
        // },
        // {
        //     id: 'R004', functionality: 'Contract Setup',
        //     sourceDataType: '',
        //     reportName: 'Contract List',
        //     reportDescription: 'Search Contracts by Flexible Criteria',
        //     url: '/search-contract'
        // },
        // {
        //     id: 'R003', functionality: 'Contract Setup',
        //     sourceDataType: '',
        //     reportName: 'Contract Details',
        //     reportDescription: 'Contract header details',
        //     url: '/contract-reports'
        // },
        // {
        //     id: 'R016', functionality: 'Direct Sales Data',
        //     sourceDataType: 'Direct Sales Data',
        //     reportName: 'Evaluate Contract Formulas',
        //     reportDescription: 'Calculate formula values based on formulas assigned to customer in Contract',
        //     url: '/calulate-formula-values-by-contract-resports'
        // },
        {
            id: 'R008', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Evaluate Static Formulas',
            reportDescription: 'Calculate formula values for any attribute by providing relevant filters',
            url: '/calulate-formula-values-resports'
        },
        {
            id: 'R009', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Evaluate Dynamic Formulas',
            reportDescription: 'Dynamically build and calculate ratio by providing two key figures for any attribute',
            url: '/ratio-key-figures-resports'
        },
        {
            id: 'R011', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Top N Analysis',
            reportDescription: 'Analyze Top values by any attributes and for any Key figure',
            url: '/top-N-attributes-resports'
        },
        {
            id: 'R016', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Daily Simulation Report',
            reportDescription: 'Calculate formula values based on formulas assigned to customer in Contract',
            url: '/daily-simulations-report'
        },
        {
            id: 'R006', functionality: 'Commitment Data',
            sourceDataType: 'Commitment Data',
            reportName: 'Commitment Details (Beta)',
            reportDescription: 'Display all the commitment data received by Contract',
            url: '/target-data-reports'
        },
        {
            id: 'R014', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Anamoly Detection (Beta)',
            reportDescription: 'Detect any anamolies in the sales data or postings',
            url: '/anamoly-reports'
        },
    ]);

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    //dynamic query functions
    function runQuery(url, type, item) {
        sessionStorage.setItem('operationalReportsSourceDataType', item.sourceDataType);
        if (type === 'API') {
            sessionStorage.removeItem('dyamicQueryDownloadData')
            var data = {
                id: item.allItem.id.toString()
            };
            if (item.allItem && item.allItem.filter_fields && item.allItem.filter_fields.length > 0) {
                var object = { id: item.allItem.id, fields: item.allItem.filter_fields }
                sessionStorage.setItem('dynamic_filter_fields', JSON.stringify(object));
                history.push({ pathname: '/dynamic-query/dynamic-query-form', filterValues: item.allItem.filter_fields });
            } else {
                props.runDynamicQuery(data, item.allItem.id);
            }
        } else {
            history.push(url);
        }
    }
    function runQueryWithoutFilters(item) {
        if (item === 'Contract Details') {
            props.loadContractDetails(1, 10, 'noFilter');
        } else if (item === 'Calculation Simulation') {
            props.loadCalculationSimulation(1, 10, 'noFilter');
        }
        else if (item === 'Contract Overlap') {
            props.loadContractOverlap(1, 10, 'noFilter');
        } else if (item === 'Direct Sales Data - Postings Details') {
            props.loadCalculationSimulation(1, 10, 'noFilter');
        } else if (item === 'Postings Details') {
            props.loadFinancialReports(1, 10, 'noFilter');
        } else if (item === 'Sales Invoice Details') {
            props.loadInvoiceDetails(1, 10, 'noFilter');
        }
        else if (item === 'Postings summary') {
            history.push('/financial-approvals-reports')
        }
    }


    useEffect(() => {
        var temp = [];
        if (props.queryListAdminData && props.queryListAdminData.length > 0 && flag) {
            props.queryListAdminData.map((item, index) => {
                setFlag(false);
                temp.push({
                    'id': item.id, 'functionality': item.table_name, 'reportName': item.format_name,
                    'url': item.format_name, 'reportDescription': item.query_description,
                    'type': 'API',
                    'allItem': item
                });
                setOperationalReportsData([...operationalReportsData, ...temp]);
            });
        }
    }, [props.queryListAdminData]);
    useEffect(() => {
        var temp = [];
        if (props.targetData && props.targetData.length > 0 && flag) {
            props.targetData.map((item, index) => {
                setFlag(false);
                temp.push({
                    'id': item.id, 'functionality': 'Target Data Table', 'reportName': 'Target Report',
                    'url': '', 'reportDescription': 'Report for ID - ' + item.ID,
                    'type': 'API',
                    'allItem': item
                });
                setTargetReportsData([...targetReportsData, ...temp]);
            });
        }
    }, [props.targetData]);
    useEffect(() => {
        // props.getQueryListAdmin();
    }, []);
    const runTargetData = (item) => {
        history.push('/select-operational-reports/target-reports');
    }
    const runContractNumberVarianceReports = (item) => {
        history.push('/select-operational-reports/contract-number-variance-reports');
    }

    // const sortedReports = operationalReportsData.sort((a, b) =>
    //     a.reportName.localeCompare(b.reportName)
    // );

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{ marginTop: -5, marginLeft: 16 }}> Operational Reports </Typography>
            </div>
            <Grid container spacing={3} className={classes.gridContainer}>
                {operationalReportsData.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.gridItem}>
                        <Card key={item.id} className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h1">{item.reportName}</Typography>
                                <Typography variant="subtitle1">Data Source - {item.functionality}</Typography>
                                <Typography variant="body2" style={{ fontSize: '14px' }}>{item.reportDescription}</Typography>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button
                                    onClick={() => runQuery(item.url, item.type, item)}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<FlightTakeoffIcon />}>
                                    Run
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        queryListAdminData: state.operationalReportsData.queryListAdminSuccessData,
        targetData: state.operationalReportsData.targetData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        runQueryWithoutFilters: (data) => dispatch(runDynamicQuery(data)),
        loadContractDetails: (start, end, keyword) => dispatch(runContractReports(start, end, keyword)),
        loadContractOverlap: (start, end, keyword) => dispatch(runContractReports(start, end, keyword, 'overlap')),
        loadCalculationSimulation: () => dispatch(runCalculationReports(1, 10, '')),
        loadFinancialReports: (start, end, keyword) => dispatch(runFinancialReports(start, end, keyword)),
        loadInvoiceDetails: (start, end, keyword) => dispatch(runInvoiceReports(start, end, keyword)),
        // getQueryListAdmin: () => dispatch(getQueryListAdmin()),
        runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOperationalReports);