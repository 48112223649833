// CustomModal.js
import React from 'react';
import './Prompt.css'; // Custom styles for the modal
import { Dialog, Typography } from '@mui/material';

const ConfirmPrompt = ({ show, title, message, onConfirm, onCancel }) => {
    if (!show) return null;

    return (
        <Dialog open={show}>
            <div className="modal-overlay">
                <div className="modal-content">
                    <Typography variant='h3'>{message}</Typography> {/* Customizable message */}
                    <div className="modal-buttons">
                        <button onClick={onConfirm} className="confirm-button">
                            Yes
                        </button>
                        <button onClick={onCancel} className="cancel-button">
                            No
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmPrompt;
