import React from 'react';
import { connect } from 'react-redux';
import { IntegrationBatchJobSetupTable } from './components';
const IntegrationSetup = () => {
    return (
        <div>
            <IntegrationBatchJobSetupTable />
        </div >
    );
};
export default connect(null, null)(IntegrationSetup);