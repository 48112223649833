import React, { useEffect, useRef} from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getDefaultValues, getApprovalDesignerDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 16px 16px'
    }
}));
const EditApprovalDesigner = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [userStore, setUserStore] = React.useState([]);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        props.onLoadingDefault();
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getApprovalDesignerDetails(appId)
    }, []);
    useEffect(() => {
        if (props.approvalData) {
            if (props.approvalData) {
                // setClassOfTrade(props.membershipData[0].class_of_trade);
            }
            setUserStore([props.approvalData])
        }
    }, [props.approvalData]);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                    })
                }
            }
        }
    });
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/approval-limits'
                >
                    Approval Limits
                </Link>
                <Typography color="textPrimary" variant='h4'>View Approval</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {userStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'>Contract Type</TableCell>
                                                <TableCell align='center'>Posting Type</TableCell>
                                                <TableCell align='center'>Approval Level</TableCell>
                                                <TableCell align='center'>Approval Group</TableCell>
                                                <TableCell align='center'>Variance Percentage</TableCell>
                                                <TableCell align='center'>Threshold Value</TableCell>
                                                <TableCell align='center'>Override Value</TableCell>
                                                <TableCell align='center'>PPA Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {userStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center'>{item.contract_type}</TableCell>
                                                        <TableCell align='center'>{item.posting_type}</TableCell>
                                                        <TableCell align='center'>{item.approval_level}</TableCell>
                                                        <TableCell align='center'>{item.approval_group}</TableCell>
                                                        <TableCell align='center'>{item.variance_percentage}</TableCell>
                                                        <TableCell align='center'>{item.threshold_value}</TableCell>
                                                        <TableCell align='center'>{item.override_value}</TableCell>
                                                        <TableCell align='center'>{item.ppa_value}</TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    No Data
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetDetail
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValues()),
        getApprovalDesignerDetails: (id) => dispatch(getApprovalDesignerDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditApprovalDesigner);