
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { createProductFeaturePricing, getProductFeaturePricingData } from '../../../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AddBoxOutlined, LibraryAdd, CloudUpload } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';
import Moment from 'moment';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        // justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));

const ProductFeatureMainPricing = props => {
    useEffect(() => {
        props.getAllProcductFeature(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [productFeatureAll, setProductFeatureAll] = React.useState([]);

    useEffect(() => {
        if (props.productFeatureAll) {
            setProductFeatureAll(props.productFeatureAll)
        }
    }, [props.productFeatureAll])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAllProcductFeature(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAllProcductFeature(1, event.target.value);
    };
    function editProductFeature(id) {
        history.push({
            pathname: '/product-feature-pricing/edit-product-feature/' + id,
            id: id
        });
    }
    const deleteData = (id) => {
        props.deleteProductFeature('', 'delete', id);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                {
                    // secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('post_product_feature-'+localStorage.getItem('application')) &&
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h2" style={{ marginLeft: 10, marginTop: 8 }}> Product Feature Pricing </Typography>
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                onClick={() => history.push('/product-feature-pricing/add-product-feature')}
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                onClick={() => history.push('/product-feature-pricing-excel-upload')}
                            >
                                <CloudUpload color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            {/* <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxOutlinedIcon />}
                        classes={{
                            startIcon: !isDesktop && classes.startIcon,
                            root: !isDesktop && classes.container
                        }}
                        onClick={() => history.push('/product-feature-pricing/add-product-feature')}
                        style={{ marginRight: 10 }}
                    >
                        {isDesktop ? 'Add Product Feature' : ''}
                    </Button> 
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxOutlinedIcon />}
                        classes={{
                            startIcon: !isDesktop && classes.startIcon,
                            root: !isDesktop && classes.container
                        }}
                        onClick={() => history.push('/product-feature-pricing-excel-upload')}
                    >
                        {isDesktop ? 'Upload Excel' : ''}
                    </Button>
                    </div>*/}
                        </div>
                    </div>
                }

                {productFeatureAll && productFeatureAll.total_record > 0 ?
                    <TableContainer style={{ marginTop: -27 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Product Feature</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Product Feature Value</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Cost</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Market Price</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>List Price</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Start Date</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>End Date</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {productFeatureAll.records.map(item => {
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.product_feature}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.product_feature_value}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.cost}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.market_price}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.list_price}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.start_date !== "" ? Moment.utc(item.start_date).format('MM/DD/YYYY') : ''}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.end_date !== "" ? Moment.utc(item.end_date).format('MM/DD/YYYY') : ''}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                {
                                                    //  secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('put_product_feature-'+localStorage.getItem('application')) &&
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => editProductFeature(item.ID)}
                                                        size="large">
                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>}
                                                {
                                                    // secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('put_product_feature-'+localStorage.getItem('application')) &&
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => deleteData(item.ID)}
                                                        size="large">
                                                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={props.productFeatureAll && props.productFeatureAll.records ? props.productFeatureAll.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        />
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        productFeatureAll: state.pricingData.productFeaturePricingData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllProcductFeature: (pagination, limit) => dispatch(getProductFeaturePricingData(pagination, limit)),
        deleteProductFeature: (data, type, id) => dispatch(createProductFeaturePricing(data, type, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFeatureMainPricing);