import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Card,
    CardContent,
    CardActions,
    Button,
    Grid,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {
    runContractReports, runDynamicQuery, runCalculationReports, runFinancialReports,
    runInvoiceReports, getQueryListAdmin, loadTargetData
} from '../../redux/actions';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 10,
        marginTop: 16,
        padding: theme.spacing(2),
    },
    card: {
        borderRadius: 10,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 220,
        width: '100%',
    },
    cardContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    gridContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    gridItem: {
        display: 'flex',
    },
    button: {
        marginTop: theme.spacing(1),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15,
    },
    largeTitle: {
        fontSize: '10rem', 
    },
    bigCard: {
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        padding: theme.spacing(1),
        borderRadius: '8px',
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(4),
    },
    bigCardTitle: {
        // marginBottom: theme.spacing(2),
        // fontWeight: 'bold',
        fontSize: '5rem',
        color: theme.palette.primary.main,
    },
}));
const Documentation = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedDescription, setSelectedDescription] = React.useState('');
    const [documentName, setDocumentName] = React.useState('');
    const [contractsAndDocumentManagement, setContractsAndDocumentManagement] = React.useState([
        {
            documentName: 'Document Management',
            reportDescription: 'Document Management is the first step in Contract Management. It allows integration with document management systems e.g., Nuxeo to view contractual agreements. This integration can also trigger workflow within IMA360 by automatically assigning the document to a user to initiate contract set up.',
        },
        {
            documentName: 'Contract Setup',
            reportDescription: 'Contract Setup facilitates the creation of contract(s) using the Add option or Mass Uploadoption. Created contract(s) can be editedmanually or using the Mass Update option formultiple contracts. Created contract(s) can bdownloaded, searched for and deleted (if nopostings).',
        },
        {
            documentName: 'Contract Approval',
            reportDescription: 'Once a contract is created, it must be approved as a pre-requisite to posting accruals or payments. Contract Approval allows user to Approve or Reject the created contract(s)',
        },
        {
            documentName: 'Contract Log',
            reportDescription: 'Contract Log allows users to view Changes Pending Approval or All Changes of edited/updated contract(s).',
        },
    ])
    const [calculationManagement, setCalculationManagement] = React.useState([
        {
            documentName: 'Formula Lab',
            reportDescription: 'Formula Lab enables users to create/add formulas to be used for calculations. Formulas created can be edited or deleted if they are not used in any contract.',
        },
        {
            documentName: 'Calculation Simulation',
            reportDescription: 'Calculation Simulation can be performed after the contract is setup by running it online or scheduling a batch job. Simulation can be done even if the Contract is not approved. This allows users to validate the setup of the contract prior to initiation of financial postings. There are three levels: Summary, Customer and Material.',
        },
        {
            documentName: 'Calculation Analysis',
            reportDescription: 'Calculation Analysis can be performed after Calculation Simulation, Accrual or Payment by entering a simulation number. This retrieves an already stored Calculation. Analysis results can also be downloaded to an Excel file for doing further analysis.',
        },
        {
            documentName: 'External Formula Results',
            reportDescription: 'External Formulas are created in Formula Lab, but the system doesn’t calculate the formula values. Formula values are received from external systems or uploaded via excel files e.g., GPR/BPR.',
        },
        {
            documentName: 'Exception Management',
            reportDescription: 'Exception Management allows users to override the Rebate Amount, Rebate Rate or override the Formula value calculated by the system e.g., system calculates GCR of 12% and it can be overridden to 15%. Exceptions can be entered for Individual customers or Contract as a whole.',
        },
        {
            documentName: 'Exception Approval',
            reportDescription: 'Exception Management entries must be approved before the new values will be used by the system. Any changes made to an existing exception would also require approval.',
        },
        // {
        //     documentName: 'Quota Management (Beta)',
        //     reportDescription: 'Quota Management functionality can be used to calculate the Rebate based on the Target achievement instead of the specific volume e.g., we can maintain certain volume as quota and then maintain the Rebate % based on the achievement %',
        // },
        // {
        //     documentName: 'Rebate Estimator (Beta)',
        //     reportDescription: '',
        // },
    ])
    const [financialPostings, setFinancialPostings] = React.useState([
        
        {
            documentName: 'Accruals',
            reportDescription: 'Accruals are posted to record the financial liability and impact to revenue every month. Delta accrual functionality allows for a differential amount to be posted in case of certain contract adjustments, missed sales data or exceptions.',
        },
        {
            documentName: 'External Accrual Postings',
            reportDescription: 'Accrual Postings are followed by External Accrual Posting that reflects in SAP Financials. Accrual designer configuration will control which G/L accounts, document types, posting keys and COPA characteristics will be used to post the Accruals in SAP.',
        },
        {
            documentName: 'Payments',
            reportDescription: 'Payment Postings are done after Accruals are posted. FI-Credit Memo, Check or EFT are available options. Partner Statement(s) can be created for Payments.',
        },
        {
            documentName: 'External Payment Postings',
            reportDescription: 'Payment Postings are followed b External Payment Posting that will reflect in SAP Financials. Payment designer configuration will control which G/L accounts, document types and posting keys will be used to post the Payments in SAP.',
        },
    ])
    const [reportingAndAnalytics, setReportingAndAnalytics] = React.useState([
        {
            documentName: 'Operational Reports',
            reportDescription: 'Operational Reports are pre-delivered reports with software and requires development by the vendor. Operational reports are available for Contracts, Calculation, Postings and other operations.',
        },
        {
            documentName: 'On Demand Query',
            reportDescription: 'On Demand Queries facilitate verification of sales data and calculations.',
        },
        {
            documentName: 'On Demand Analytics',
            reportDescription: 'On Demand Analytics are graphical reports (Bar Charts or Pie Chart) that can be configured by the users.',
        },
        {
            documentName: 'Predefined Query',
            reportDescription: 'Predefined Queries can be created by the IT Support team by utilizing SQL queries. Turnaround time to create these queries will be faster than creating a new operational report.',
        },
        {
            documentName: 'Analytics & DashBoard',
            reportDescription: 'Analytics and Dashboard facilitates the review of information in the Graphical way. Analytics are one chart per tile and Dashboard include four or more Charts in on UI. Some examples are Postings Variance by Attribute, Posting Summary Analysis and Summarized Dashboard.',
        },
        {
            documentName: 'Process Reporting',
            reportDescription: 'Process Reporting can be utilized to group the operational reports, On Demand Queries, Analytics etc. into categories like Month End Close, Executive Reporting and Customer Statement. These categories can be created easily in the Designer Studio.',
        },
    ])
    const [masterData, setMasterData] = React.useState([
        {
            documentName: 'Customer Master',
            reportDescription: 'Customer Master consists of Master Data required to setup contracts. Customer(s) can be added, uploaded, searched for and edited and deleted. However, the current approach is to get all the master data from the Source System and to not allow any edits to maintain data integrity.',
        },
        {
            documentName: 'Material Master',
            reportDescription: 'Material Master constitutes of Master Data required to setup contracts. Material(s) can be added, uploaded, searched for, edited and deleted. However, the current approach is to get all the master data from the Source System and to not allow any edits to maintain data integrity.',
        },
        {
            documentName: 'Supplier Mater',
            reportDescription: 'Supplier Master constitutes of Master Data required to setup contracts. Supplier(s) can be added, uploaded, searched for, edited and deleted. However, the current approach is to get all the master data from the Source System and to not allow any edits to maintain data integrity.',
        },
        {
            documentName: 'Attribute Master',
            reportDescription: 'Attributes Data allows to bring the descriptions and dropdown values from the Source system e.g., Material groups, Material Price groups, Plants etc.',
        },
    ])
    const [salesDataAndKeyFigures, setSalesDataAndKeyFigures] = React.useState([
        {
            documentName: 'Sales Data',
            reportDescription: 'Sales Data is pushed from Source System to IMA360 at the line item level on a daily basis and does NOT allow any edits to maintain data integrity.',
        },
        {
            documentName: 'Sales Key Figures',
            reportDescription: 'As Sales Data is integrated into IMA360, sales values can be mapped to target Key figures based on mapping rules.',
        },
        
    ]);

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    
    const truncateDescription = (description) => {
        const words = description.split(' ');
        if (words.length <= 25) return description;
        return `${words.slice(0, 25).join(' ')}...`;
    };
    const handleReadMore = (item) => {
        setDocumentName(item.documentName);
        setSelectedDescription(item.reportDescription);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' className={classes.largeTitle} style={{ marginTop: -5, marginLeft: 16 }}>Documentation</Typography>
            </div>
            <Card className={classes.bigCard}>
            <CardContent>
                <Typography variant="h1" className={classes.bigCardTitle}>
                    Contracts and Document Management
                </Typography>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {contractsAndDocumentManagement.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.gridItem}>
                            <Card key={item.id} className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h1">{item.documentName}</Typography>
                                    <Typography variant="body2">
                                        {truncateDescription(item.reportDescription)}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        onClick={() => handleReadMore(item)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
            <Card className={classes.bigCard}>
            <CardContent>
                <Typography variant="h1" className={classes.bigCardTitle}>
                    Calculation Management
                </Typography>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {calculationManagement.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.gridItem}>
                            <Card key={item.id} className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h1">{item.documentName}</Typography>
                                    <Typography variant="body2">
                                        {truncateDescription(item.reportDescription)}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        onClick={() => handleReadMore(item)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
            <Card className={classes.bigCard}>
            <CardContent>
                <Typography variant="h1" className={classes.bigCardTitle}>
                    Financial Postings
                </Typography>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {financialPostings.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.gridItem}>
                            <Card key={item.id} className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h1">{item.documentName}</Typography>
                                    <Typography variant="body2">
                                        {truncateDescription(item.reportDescription)}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        onClick={() => handleReadMore(item)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
            <Card className={classes.bigCard}>
            <CardContent>
                <Typography variant="h1" className={classes.bigCardTitle}>
                    Reporting and Analytics
                </Typography>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {reportingAndAnalytics.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.gridItem}>
                            <Card key={item.id} className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h1">{item.documentName}</Typography>
                                    <Typography variant="body2">
                                        {truncateDescription(item.reportDescription)}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        onClick={() => handleReadMore(item)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
            <Card className={classes.bigCard}>
            <CardContent>
                <Typography variant="h1" className={classes.bigCardTitle}>
                    Master Data
                </Typography>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {masterData.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.gridItem}>
                            <Card key={item.id} className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h1">{item.documentName}</Typography>
                                    <Typography variant="body2">
                                        {truncateDescription(item.reportDescription)}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        onClick={() => handleReadMore(item)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
            <Card className={classes.bigCard}>
            <CardContent>
                <Typography variant="h1" className={classes.bigCardTitle}>
                    Sales Data And Key Figures
                </Typography>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {salesDataAndKeyFigures.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index} className={classes.gridItem}>
                            <Card key={item.id} className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h1">{item.documentName}</Typography>
                                    <Typography variant="body2">
                                        {truncateDescription(item.reportDescription)}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Button
                                        onClick={() => handleReadMore(item)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle style={{ fontSize: '1.5rem' }}>{documentName}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{selectedDescription}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
const mapStateToProps = state => {
    // return {
    //     loading: state.operationalReportsData.loading,
    //     queryListAdminData: state.operationalReportsData.queryListAdminSuccessData,
    //     targetData: state.operationalReportsData.targetData
    // }
};
const mapDispatchToProps = dispatch => {
    // return {
    //     runQueryWithoutFilters: (data) => dispatch(runDynamicQuery(data)),
    //     loadContractDetails: (start, end, keyword) => dispatch(runContractReports(start, end, keyword)),
    //     loadContractOverlap: (start, end, keyword) => dispatch(runContractReports(start, end, keyword, 'overlap')),
    //     loadCalculationSimulation: () => dispatch(runCalculationReports(1, 10, '')),
    //     loadFinancialReports: (start, end, keyword) => dispatch(runFinancialReports(start, end, keyword)),
    //     loadInvoiceDetails: (start, end, keyword) => dispatch(runInvoiceReports(start, end, keyword)),
    //     // getQueryListAdmin: () => dispatch(getQueryListAdmin()),
    //     runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id))
    // }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);