import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, Fab, Typography } from '@mui/material';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getDemandSupply, createDemandSupply, getLblDispDesFieldValue } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { NavLink as RouterLink } from 'react-router-dom';
import { createTheme } from "@mui/material/styles";
import Moment from 'moment';
import { tableIcons } from '../../components/Icons/TableIcons';
import secureLocalStorage from 'react-secure-storage';
import RingLoader from "react-spinners/RingLoader";
import SimpleDialog from '../../components/Dialog';


const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const DemandSupply = props => {
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("");
    const [count, setCount] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const [page, setPage] = React.useState(0);

    const action = {

        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_demand_supply-' + sessionStorage.getItem('application')) &&
                    <>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editInventory(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>

                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleClickOpen(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    </>
                }
            </div>
    }

    useEffect(() => {
        var data = { "data_source": "demand_supply_data" };
        props.getDemandSupply();
        props.loadSourceDataType(data, 'post');
    }, []);
    function editInventory(id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/demand-supply/edit/' + id,
            id: id
        });
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onDelete(null, currentId)
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    useEffect(() => {
        var tempRows = [];
        if (props.demandSupply && props.demandSupply.records && props.demandSupply.total_record > 0) {
            setCount(props.demandSupply.total_record)
            setDataRows(props.demandSupply.records);
        }
    }, [props.demandSupply]);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    newArray.push({
                        field: item.key,
                        title: item.current,
                        type: item.key != 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                        render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
                            Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    })

                })
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Demand Supply </Typography>

                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_demand_supply-' + sessionStorage.getItem('application')) &&
                            <>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    classes={{ root: classes.fabContainer }}
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/demand-supply/add'
                                >
                                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                                </Fab>

                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/demand-supply/demand-supply-excel-upload'
                                >
                                    <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                                </Fab>
                            </>
                        }
                        {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlinedIcon />}
                            classes={{
                                startIcon: !isDesktop && classes.startIcon,
                                root: !isDesktop && classes.container
                            }}
                            component={CustomRouterLink}
                            to='/demand-supply/add'
                            size={'small'}
                            className={classes.buttonStyle}
                        >
                            {isDesktop ? 'Add Demand Supply' : ''}
                        </Button> */}
                        {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlinedIcon />}
                            classes={{
                                startIcon: !isDesktop && classes.startIcon,
                                root: !isDesktop && classes.container
                            }}
                            component={CustomRouterLink}
                            to='/demand-supply/demand-supply-excel-upload'
                            size={'small'}
                            className={classes.buttonStyle}
                            style={{ marginLeft: 10 }}
                        >  Upload Excel
                        </Button> */}
                    </div>

                </div>
                {props.demandSupply && props.demandSupply.records && props.demandSupply.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        demandSupply: state.dataSetupData.demandSupply,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getDemandSupply: () => dispatch(getDemandSupply()),
        onDelete: (data, id) => dispatch(createDemandSupply(data, 'delete', id)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DemandSupply);