import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
// import { Funnel, FUNNEL_CONVERSATION_FIELD } from '@ant-design/plots';

const FunnelChart = (props) => {

  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: 'white',
      padding: '20px 30px 20px 30px',
      borderRadius: 10,
      marginLeft: 7,
      marginRight: 7
    },

  }));
  const classes = useStyles();

  const data = [
    {
      stage: 'Base Price',
      number: 253,
    },
    {
      stage: 'Invoice Price',
      number: 151,
    },
    {
      stage: 'Off-Invoice Price',
      number: 113,
    },
    {
      stage: 'Margin',
      number: 87,
    }
  ];
  const config = {
    data: data,
    xField: 'stage',
    yField: 'number',
    legend: false,
  };
  return (
    <div className={classes.root}>
      <Typography variant="h2">Funnel</Typography>
      {/* <Funnel {...config} style={{ height: 315 }} /> */}
    </div>
  );
};


export default connect(null, null)(FunnelChart);