import * as React from "react";
import { useState, useEffect } from "react";
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import validate from "validate.js";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";
import { login, forgotPassword, Oktalogin } from "../../redux/actions";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import GridLoader from "react-spinners/GridLoader";
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { getEnv } from "../../env";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();
const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};
const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100vh",
      backgroundColor: theme.palette.primary?.main,
    },
    content: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentBody: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down('xl')]: {
        justifyContent: "center",
      },
    },
    form: {
      [theme.breakpoints.down('lg')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    title: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: "white",
    },
    textField: {
      height: 55,
      paddingLeft: 10,
      backgroundColor: "white",
    },
    signInButton: {
      margin: theme.spacing(2, 0),
    },
    logoContainer: {
      justifyContent: "center",
      display: "flex",
    },
    logo: {
      [theme.breakpoints.down('xl')]: {
        width: window.screen.width * 0.5,
      },
      [theme.breakpoints.up("md")]: {
        width: window.screen.width * 0.2,
      },
    },
    forgotPasswordContainer: {
      display: "flex",
      justifyContent: "flex-end",
      fontSize: 14,
      color: "white",
      cursor: "pointer",
    },
    bgColor: {
      backgroundColor: theme.palette.primary.main
    },
    button: {
      color: theme.palette.primary.main
    }
  };
});
const Login = (props) => {
  const classes = useStyles();
  var baseUrl = getEnv()
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);
  //     console.log({
  //         email: data.get('email'),
  //         password: data.get('password'),
  //     });
  // };
  const handleSignIn = (event) => {
    localStorage.setItem("darkTheme", true);
    event.preventDefault();
    sessionStorage.setItem('loginData', JSON.stringify(formState.values))
    if (formState.values.email.length > 0) {
      props.onLogin(formState.values);
    }
  };

  const handleEmail = (event) => {
    formState.values.email = event.target.value;
    handleChange(event);
  };
  const handlePassword = (event) => {
    formState.values.password = event.target.value;
    handleChange(event);
  };
  const handleChange = (event) => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleForgotPassword = () => {
    if (formState.values.email && !hasError("email")) {
      props.forgotPassword(formState.values.email);
    } else {
      if (!hasError("email")) {
        toast.error("Please enter email");
      }
    }
  };
  const { authState, oktaAuth } = useOktaAuth();
  const loginWithRedirect = () =>
    oktaAuth.signInWithRedirect({ originalUri: "/dashboard" });
  const logOut = () => oktaAuth.signOut();

  const buttonText = authState?.isAuthenticated ? "Logout" : "Login";
  const btnLogic = authState?.isAuthenticated ? logOut : loginWithRedirect;
  // var config = {
  //   // Required config
  //   issuer: baseUrl['OKTA_DOMAIN'] + '/oauth2/default',
  //   clientId: baseUrl['OKTA_CLIENT_ID'],
  //   redirectUri: baseUrl['OKTA_REDIRECT_URI'] + '/oauth2/callback/home',
  //   // issuer: 'https://trial-9065551.okta.com/oauth2/default',
  //   // clientId: '0oae0b3g5uZMIbRJa697',
  //   // redirectUri: 'http://localhost:3000/login/oauth2/callback/home',
  //   // redirectUri: baseUrl['BASE_URL'] + 'login/oauth2/callback/home',
  //   // Use authorization_code flow
  //   responseType: 'code',
  //   pkce: false
  // };


  const oktaData = JSON.parse(sessionStorage.getItem('okta-transaction-storage'))
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    var data = {}
    if (oktaData && Object.entries(oktaData).length > 0 && params && params.size > 0) {
      data["grant_type"] = "authorization_code";
      data['code_challenge'] = oktaData.codeChallenge;
      data['code_verifier'] = oktaData.codeVerifier;
      data['code'] = params.get('code');
      data['state'] = params.get('state');
      props.Oktalogin(data)
    }

  }, [window.location.search])
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LoadingOverlay active={props.loading} spinner={<GridLoader />}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                // backgroundImage: 'url(https://source.unsplash.com/random)',
                // backgroundColor: theme.palette.primary.main,
                backgroundRepeat: "no-repeat",
                // backgroundColor: (t) =>
                //   t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={classes.bgColor}
            >
              <img
                alt="Logo"
                src={require("../../library/images/new_logo.png")}
                className={classes.logo}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSignIn}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formState.values.email || ""}
                    onChange={handleEmail}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={formState.values.password || ""}
                    autoComplete="current-password"
                    onChange={handlePassword}
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onSubmit={handleSignIn}
                  //className={classes.button}
                  >
                    Sign In
                  </Button>
                  {/* <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onSubmit={handleOktaLogin}
                  //className={classes.button}
                  >
                    Login with Okta
                  </Button> */}
                  {/* <div>Okta React</div> */}
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={btnLogic}>Login with Okta</Button>
                  <Grid container>
                    <Grid item xs>
                      <Link className={classes.button} onClick={handleForgotPassword} variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="#" variant="body2"></Link>
                    </Grid>
                  </Grid>
                  {/* <Copyright sx={{ mt: 5 }} /> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loginData.loadingLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userData) => dispatch(login(userData)),
    Oktalogin: (userData) => dispatch(Oktalogin(userData)),
    forgotPassword: (username) => dispatch(forgotPassword(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
