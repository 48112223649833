import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { updateOffInvoiceCostFormatData, getOffInvoiceCostFormatFields } from "../../redux/actions";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const OffInvoiceCostUploadEditQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [offInvoiceCostType, setOffInvoiceCostType] = React.useState('');
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('offinvoice-cost-query') ? JSON.parse(sessionStorage.getItem('offinvoice-cost-query')) : []);
    const [queryObject, setQueryObject] = React.useState({});
    const requiredFields = [
        'off_invoice_cost_type', 'start_date', 'end_date', 'amount']
    useEffect(() => {
        setOpen(true);
        props.getOffInvoiceCostFormatFields();
    }, []);
    useEffect(() => {
        if (purchaseQueryDataStorage) {
            setQueryName(purchaseQueryDataStorage.format_name)
            setQueryObject(purchaseQueryDataStorage)
            setOffInvoiceCostType(purchaseQueryDataStorage.off_invoice_cost_type)
            setStartDate(purchaseQueryDataStorage.start_date)
            setEndDate(purchaseQueryDataStorage.end_date)
            setAmount(purchaseQueryDataStorage.amount)
        }
    }, [purchaseQueryDataStorage]);
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, queryObject);
    }
    function handleClearAll() {
        handleClear();
        setStartDate('')
        setEndDate('')
        setAmount('')
        setOffInvoiceCostType('')
    }
    function handleCancelDialog() {
        history.push('/off-Invoice-cost/off-Invoice-cost-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'off_invoice_cost_type')
            setOffInvoiceCostType(event.target.value)
        if (item === "start_date")
            setStartDate(event.target.value)
        if (item === "end_date")
            setEndDate(event.target.value)
        if (item === "amount")
            setAmount(event.target.value)
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/off-Invoice-cost/off-Invoice-cost-excel-upload'>  OffInvoice Cost Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >   {props.queryListData ?
                            <div>
                                <div style={{ margin: 15 }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell align='center'>Field Name</TableCell>
                                                    <TableCell align='center' style={{ width: 200 }}>Sequence Number</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {props.queryListData
                                                    .filter(item => item != 'format_name' && item != '')
                                                    .map((item) => {
                                                        return (
                                                            <TableRow key={item}>
                                                                <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    {requiredFields.includes(item) ? <p>{item.replace(/_/g, ' ')}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                </TableCell>
                                                                <TableCell style={{ width: 70 }}>
                                                                    <TextField
                                                                        value={queryObject[item] ? queryObject[item] : ''}
                                                                        type="number"
                                                                        variant="outlined"
                                                                        onChange={(e) => handleChange(e, item)}
                                                                        defaultValue={JSON.parse(sessionStorage.getItem('offinvoice-cost-query'))[item] ? JSON.parse(sessionStorage.getItem('offinvoice-cost-query'))[item] : ''}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div> :
                            <Typography variant='h4'>
                                There is no data to show now.
                            </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={!amount || !startDate || !endDate || !offInvoiceCostType ? true : false}>
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        queryListData: state.dataSetupData.offInvoiceCostFormatFieldData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateOffInvoiceCostFormatData(id, data)),
        getOffInvoiceCostFormatFields: () => dispatch(getOffInvoiceCostFormatFields()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OffInvoiceCostUploadEditQuery);