import React from "react";
import { Typography, Grid } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import OffInvoiceTab from "./OffInvoiceTab";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 23,
    marginTop: 16,
  }
}));

const OffInvoiceMain = (props) => {
  const classes = useStyles();
  const { className } = props;

  return (
    <div>
      <Grid container spacing={1} classes={{ root: classes.flexEnd }}>
        <Grid item md={6} xs={12}>
          <Typography variant="h1"> Off Invoice Config </Typography>
        </Grid>
      </Grid>
      <div className={clsx(classes.root, className)}>
        <OffInvoiceTab />
      </div>
    </div>
  );
};

export default OffInvoiceMain;