import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import { Button, Card, Typography, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, FormLabel, Select, MenuItem, OutlinedInput, } from '@mui/material';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import { LabelText } from '../../components/Inputs';
import { deleteAggregationLevel, createMasterDataConfiguration } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    root: {
        backgroundColor: theme.palette.white,
        padding: '18px 29px 29px 29px',
        margin: '20px 0px 30px',
        marginBottom: 10
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 40,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    inputTwoLine: {
        marginTop: 2,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        width: '100%'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
        paddingTop: 8
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'left',
        paddingLeft: 28
    },
    fontSettings: {
        fontSize: theme.typography.h3.fontSize,
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));
const MasterDataConfiguration = props => {
    const [inputValue, setInputValue] = React.useState('');
    const [fieldValue, setFieldValue] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [putActive, setPutActive] = React.useState(false);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [contractPriority, setContractPriority] = React.useState([]);
    const [GPOPriority, setGPOPriority] = React.useState([]);
    const [updateActionCode, setUpdateActionCode] = React.useState(false);
    const [pricingInventory, setPricingInventory] = React.useState(false);
    const [claimConfigData, setClaimConfigData] = React.useState([])
    const [currentID, setCurrentID] = React.useState(0);
    // const [inputValue, setInputValue] = React.useState('');
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        if (props.claimData && props.claimData.length > 0) {
            setPricingInventory(props.claimData[0].pricing_inventory_alert_qty)
            setClaimConfigData(props.claimData[0])
            setCurrentID(props.claimData[0].id)
            if (props.claimData[0].GPO_priority !== null)
                setGPOPriority(props.claimData[0].GPO_priority)
            setUpdateActionCode(props.claimData[0].update_membership_tier_activation_action_code)
            if (props.claimData[0].contract_priority !== null)
                setContractPriority(props.claimData[0].contract_priority)


        }
    }, [props.claimData])
    const handleChange = (fieldName) => {
        setSelectedRowName(fieldName);
        if (fieldName == 'contract_priority') {
            setFieldValue(contractPriority)
        }
        if (fieldName == 'GPO_priority') {
            setFieldValue(GPOPriority)
        }
        if (fieldName == 'update_membership_tier_activation_action_code') {
            setSelectedValue(updateActionCode)
        }
        if (fieldName == 'pricing_inventory_alert_qty') {
            setInputValue(pricingInventory)
        }

    };
    function handleInput(e) {
        setInputValue(e.target.value)
        if (selectedRowName == 'pricing_inventory_alert_qty') {
            // console.log(e.target.value)
            setPricingInventory(e.target.value)

        }

    }
    // console.log(InputValue)
    function handleOnSelect(event) {
        setSelectedValue(event.target.value)
        if (selectedRowName == 'update_membership_tier_activation_action_code') {
            setUpdateActionCode(event.target.value)
        }
    }
    function handleSubmit(clear) {
        var data;
        if (clear === 'clear') {
            data = {
                "pricing_inventory_alert_qty": selectedRowName == 'pricing_inventory_alert_qty' ? 0 : parseFloat(pricingInventory),
                "contract_priority": selectedRowName == 'contract_priority' ? [] : contractPriority,
                "GPO_priority": selectedRowName == 'GPO_priority' ? [] : GPOPriority,
                "update_membership_tier_activation_action_code": selectedRowName == 'update_membership_tier_activation_action_code' ? false : updateActionCode,
            };
        } else {
            data = {
                "pricing_inventory_alert_qty": pricingInventory ? parseFloat(pricingInventory) : 0,
                "contract_priority": contractPriority,
                "GPO_priority": GPOPriority,
                "update_membership_tier_activation_action_code": updateActionCode,
            };
        }
        console.log(data)
        if (currentID != 0) {
            props.onSubmit(data, 'edit', currentID);
        }
        else {
            props.onSubmit(data, 'create');
        }
        setPutActive(false)
        setSelectedRowName('');
        setSelectedValue('');
        setEditMode(false)
        setCurrentID(0)
        setFieldValue([])
        setInputValue('')
        setSelectedValue(false)
    }
    const handleFieldValue = (newValue) => {
        setFieldValue(newValue);
        if (selectedRowName == 'GPO_priority') {
            setGPOPriority(newValue);
        }
        else {
            setContractPriority(newValue);
        }
    }
    const handleDeleteFieldValue = (newValue) => {
        // setDisableEdit(false);
        var deleteFieldValue = fieldValue.filter(item => item !== newValue)
        setFieldValue(deleteFieldValue)
        if (selectedRowName == 'GPO_priority') {
            setGPOPriority(deleteFieldValue);
        }
        else {
            setContractPriority(deleteFieldValue);
        }
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h1" color='primary'> Configuration</Typography>
            </div>
            <div className={clsx(classes.addRoot, className)} style={{ marginTop: '25px' }}>

                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container} >
                            <Grid container >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <LabelText className={classes.fontSettings} heading='Field Name' capitalize={true} data={selectedRowName ? selectedRowName.replace(/_/g, ' ') : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        style={{ paddingLeft: 15, marginTop: 20 }}
                                    >
                                        <FormLabel error={false}>
                                            Field Value
                                        </FormLabel>
                                        <div>
                                            {(selectedRowName == 'update_membership_tier_activation_action_code') &&
                                                <Select
                                                    value={selectedValue}
                                                    onChange={handleOnSelect}
                                                    displayEmpty
                                                    style={{ width: '100%', marginTop: 0, textTransform: 'capitalize', }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value={true} key={true} >
                                                        True
                                                    </MenuItem>
                                                    <MenuItem value={false} key={false} >
                                                        False
                                                    </MenuItem>
                                                </Select>
                                            }
                                            {(selectedRowName == 'pricing_inventory_alert_qty') &&
                                                <OutlinedInput
                                                    value={inputValue != 0 ? inputValue : ''}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleInput}
                                                />
                                            }
                                            {(selectedRowName == 'contract_priority' || selectedRowName == 'GPO_priority') &&
                                                <ChipInput data={fieldValue} onChange={handleFieldValue} />
                                                // <ChipInput
                                                //     classes={{
                                                //         root: classes.rootContainer,
                                                //         chip: classes.chip,
                                                //         input: classes.input,
                                                //         inputRoot: classes.inputRoot,
                                                //         label: classes.chipLabel
                                                //     }}
                                                //     value={fieldValue}
                                                //     onAdd={(chips) => handleFieldValue(chips)}
                                                //     onDelete={(chip) => handleDeleteFieldValue(chip)}
                                                //     disableUnderline={true}
                                                //     className={clsx({
                                                //         [classes.textInput]: true
                                                //     })}
                                                //     blurBehavior='add'
                                                // />
                                            }
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.buttonContainer}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ padding: 5 }}
                                        startIcon={<CheckIcon />}
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        //disabled={!putActive}
                                        onClick={() => handleSubmit()}
                                    > Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        startIcon={<CheckIcon />}
                                        //disabled={!putActive}
                                        onClick={() => handleSubmit('clear')}
                                    >Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell className={classes.tabHead} classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Field Name</TableCell>
                                                <TableCell className={classes.tabHead} classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('update_membership_tier_activation_action_code')}>
                                                <TableCell classes={{ root: classes.fontSetting }} >
                                                    Update Action Code
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 ? props.claimData[0].update_membership_tier_activation_action_code.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('contract_priority')}>
                                                <TableCell classes={{ root: classes.fontSetting }}>
                                                    Contract Priority
                                                </TableCell>
                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.contract_priority ? claimConfigData.contract_priority.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('GPO_priority')}>
                                                <TableCell classes={{ root: classes.fontSetting }}>
                                                    GPO Priority
                                                </TableCell>
                                                <TableCell className={classes.fontSetting} classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {claimConfigData && claimConfigData.GPO_priority ? claimConfigData.GPO_priority.toString() : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('pricing_inventory_alert_qty')}>
                                                <TableCell classes={{ root: classes.fontSetting }}>
                                                    Pricing Inventory Alert Qty
                                                </TableCell>
                                                <TableCell className={classes.fontSetting} classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.claimData && props.claimData.length > 0 ? props.claimData[0].pricing_inventory_alert_qty : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        claimData: state.dataSetupData.MasterDataConfig,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, field, type) => dispatch(createMasterDataConfiguration(data, field, type)),
        // onDelete: (id) => dispatch(deleteAggregationLevel(id)),
        onLoadingDefault: () => dispatch(createMasterDataConfiguration(null, 'getAll', null)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MasterDataConfiguration);