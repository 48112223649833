import React, { useRef, useEffect } from 'react';
import {
    Typography, Breadcrumbs,
    Stepper, Step,
    StepButton, StepConnector, StepLabel, Button,
    Grid,
    useMediaQuery, Dialog, DialogContent, DialogContentText, DialogActions, FormLabel
} from '@mui/material';
import LoadingOverlay from "react-loading-overlay";
import { makeStyles, useTheme } from '@mui/styles';
import { GeneralData, FinancialPostings, TargetRules, Miscellaneous, CalculationRules, ApprovalStatus, InternalNotes, ContractDependency, MultiplePayment, CalculationModifier } from './components';
import { EligibilityRules } from '../ContractSetup/Containers';
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
    getGeneralUOM, getCustomerMasterData, addContractGeneralData, getContractDetailsData, getPriceFormula,
    getDefaultValuesAllDataNew, newAPIForAccRe, getEligibilityRulesTableData, getCalculationRulesTableData,
    putContractGeneralData, uploadEligibilityRulesClear, fetchDropdownConfig, getDefaultValues, getAttributeListData,
    getLblDispDesFieldValue, fetchDocumentStatus, getReleaseLockDetails, getEligibilityRulesValidateData
} from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import RingLoader from "react-spinners/RingLoader";
import secureLocalStorage from "react-secure-storage";
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';
import { toast } from 'react-toastify';
import { ConfirmPrompt } from '../../components/Containers';
const OutsideClickHandler = ({ onClickOutside, children }) => {
    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            } else {
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [onClickOutside]);

    return <div ref={ref}>{children}</div>;
};
var generalData = {};
var financialData = {};
var miscellaneousData = {};
var approvalStatusData = {};
var incentiveData = {};
var paymentData = {};
var internalNotesData = {};
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: -20,
        borderRadius: 10,
        padding: '27px 29px 27px 29px',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto',
        marginTop: -10
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    pageControls: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        },
        marginBottom: 20
    },
    shiftContent: {
        marginLeft: 0
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    },
    spinner: {
        // height: '100vh'
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center'
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    boldText: {
        fontWeight: 'bold', // Make the text bold
    },
}));
const tabs =
    sessionStorage.getItem('application') === 'Pricing' ?
        {
            generalData: 0,
            TargetRules: 1,
            contractDependency: 2,
            eligibilityRules: 3,
            calculationRules: 4,
            calculationModifier: 5,
            Reporting: 6,
            notesAndAttachments: 7,
            approvalStatus: 8

        }
        :
        {
            generalData: 0,
            financialPostings: 1,
            MultiplePayments: 2,
            TargetRules: 3,
            contractDependency: 4,
            eligibilityRules: 5,
            calculationRules: 6,
            calculationModifier: 7,
            Reporting: 8,
            notesAndAttachments: 9,
            approvalStatus: 10

        };

const AddContract = props => {
    const currentApplication = sessionStorage.getItem('application')
    const [active, setActive] = React.useState(false);
    useEffect(() => {
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
        sessionStorage.setItem("functionality-name", 'contract')
    }, [])
    useEffect(() => {
        if (!active) {
            props.getGeneralUOM();
            props.uploadEligibilityRulesClear();
        }
    }, [active])
    useEffect(() => {
        var url = window.location.href;
        if (url.indexOf("status") != -1)
            setActiveStep(5)
    }, []);

    const [dataFromAPI, setDataFromAPI] = React.useState({});
    useEffect(() => {
        if (props.data && Object.keys(props.data).length > 0)
            setDataFromAPI(props.data);
    }, [props.data])
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [targetTabEnabled, setTargetTabEnabled] = React.useState(false);
    const [eligibilityEnabled, setEligibilityEnabled] = React.useState(true);
    const [calcRuleEnabled, setCalcRuleEnabled] = React.useState(true);
    const [contractData, setContractData] = React.useState({});
    const [contractDependencyEnabled, setContractDependencyEnabled] = React.useState(false);
    const [contractFormFields, setContractFormFields] = React.useState([])

    const [calcModifierEnabled, setCalcModifierEnabled] = React.useState(false);
    const [multiPaymentRuleEnabled, setMultiPaymentRuleEnabled] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [paymentPartnerType, setPaymentPartnerType] = React.useState([]);
    const [paymentAggregationLevel, setPaymentAggregationLevel] = React.useState([]);
    const [calcModifierData, setCalcModifierData] = React.useState([]);
    const [multiPaymentData, setMultiPaymentData] = React.useState([]);
    const [nextSetupEnabled, setNextSetupEnabled] = React.useState(false);
    const [nextSetupEnabled2, setNextSetupEnabled2] = React.useState(false);
    const [nextSetupEnabled3, setNextSetupEnabled3] = React.useState(false);
    const [onSubmit, setOnSubmit] = React.useState(false);


    const history = useHistory();
    const location = useLocation();
    const [showModal, setShowModal] = React.useState(false);
    const [currentLoc, setCurrentLoc] = React.useState(location?.pathname);
    const [nextRoute, setNextRoute] = React.useState(null);
    const [onBLock, setOnBLock] = React.useState(false);
    const [eligibilityRulesValidate, setEligibilityRulesValidate] = React.useState(false);
    const [eligibilityRuleMessagePopup, setEligibilityRulesMessagePopup] = React.useState(false);
    const [eligibilityMessage, setEligibilityMessage] = React.useState('');
    useEffect(() => {

        const unblock = history.block((nextLocation) => {
            if (nextLocation.pathname !== currentLoc && onBLock) {
                // Show the modal and set the next route
                setShowModal(true);
                setCurrentLoc(nextLocation.pathname)
                setNextRoute(nextLocation.pathname);
                return false; // Prevent navigation
            }
        });

        // Cleanup function to unblock navigation when the component unmounts
        return () => {
            unblock();
        };

    }, [history, currentLoc, onBLock]);

    const confirmNavigation = () => {
        sessionStorage.removeItem("functionality-name")
        setShowModal(false);
        if (nextRoute) {
            history.push(nextRoute);
            if (nextRoute == '/login') {
                localStorage.setItem("loggedIn", false);
                localStorage.clear();
                secureLocalStorage.clear();
            } // Navigate to the new route
        }

    };

    const cancelNavigation = () => {
        setShowModal(false);
        setCurrentLoc(location.pathname)
        setNextRoute(null); // Clear the next route
    };



    // useEffect(() => {
    //     if (blockNavigation) {
    //         window.onbeforeunload = () => true
    //     } else {
    //         window.onbeforeunload = undefined
    //     }
    // }, []);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setBlockNavigation(true);
    //     }, 3000);
    //     return () => clearTimeout(timer);
    // }, []);
    useEffect(() => {
        if (props.data && Object.keys(props.data).length > 0) {
            setContractData(props.data)
            setPostingSchema(props.data.posting_schema);
            setIncentiveRateType(props.data.incentive_rate_type)
            setMaximumAmountPeriod(props.data.maximum_amount_period)
            setStartDate(props.data.valid_from)
            setEndDate(props.data.valid_to)
            if (props.data.calculation_method === 'External Calculation')
                setCalcRuleEnabled(false)
            if (props.data.calculation_method === 'Price Point') {
                props.getPricingCalculationFormula();
            }
            if (props.data.commitment_achievement === 'Yes - All' || props.data.commitment_achievement === 'Yes - Any')
                setTargetTabEnabled(true);
            setSourceDataType(props.data.source_data_type);
            if (props.data.calculation_schema)
                setCalculationSchema(props.data.calculation_schema)
            if ((props.data.calculation_method === 'Flat Rate' || props.data.calculation_method === 'Fixed Amount') && props.data.incentive_rate_type === 'Attribute') {
                setEligibilityEnabled(false)
            } else {
                setEligibilityEnabled(true)
            }
            if (props.data.contract_dependency == 'Yes') {
                setContractDependencyEnabled(true);
            } else {
                setContractDependencyEnabled(false);
            }
        }


    }, [props.data])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setContractFormFields(tempObject)
            setActive(true)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    function getSteps() {
        if (currentApplication === 'Pricing') {
            return ['Primary*', ...(targetTabEnabled ? ['Commitment Rules*'] : []), ...(contractDependencyEnabled ? ['Contract Dependency*'] : []),
                ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), ...(calcModifierEnabled ? ['Calculation Rule Modifiers*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        } else {
            return ['Primary*', 'Financial Postings*', ...(multiPaymentRuleEnabled ? ['Flexible Payments*'] : []), ...(targetTabEnabled ? ['Commitment Rules*'] : []),
                ...(contractDependencyEnabled ? ['Contract Dependency*'] : []),
                ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), ...(calcModifierEnabled ? ['Calculation Rule Modifiers*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        }

    }

    // var mode = localStorage.getItem('mode');
    const classes = useStyles();
    const [postingSchema, setPostingSchema] = React.useState('');
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const [saveTriggered, setSaveTriggered] = React.useState('');
    const [eligibilityData, setEligibilityData] = React.useState([]);
    const [eligibilityData2, setEligibilityData2] = React.useState([]);
    const [calculationData, setCalculationData] = React.useState([]);
    const [internalNotesData, setInternalNotesData] = React.useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [saveDisabled, setSaveDisabled] = React.useState(false);
    const [approvalStatusDisabled, setApprovalStatusDisabled] = React.useState(false);
    const [calculationSchema, setCalculationSchema] = React.useState('');
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [maximumAmountPeriod, setMaximumAmountPeriod] = React.useState('');
    const skipped = new Set()
    const [targetRulesData, setTargetRulesData] = React.useState([]);
    const steps = getSteps();
    // const history = useHistory();
    const [incentiveRateType, setIncentiveRateType] = React.useState('');
    const [contractDependencyData, setContractDependencyData] = React.useState([]);
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const [comments, setComments] = React.useState([]);
    const [formFields, setFormFields] = React.useState([]);
    const [genralDataMandatory, setGeneralDataMandatory] = React.useState([]);
    const [financialDataMandatory, setFinancialDataMandatory] = React.useState([]);
    const [miscellaneousDataMandatory, setMiscellaneousDataMandatory] = React.useState([]);


    useEffect(() => {
        if ((contractDependencyEnabled || targetTabEnabled) && formFields.length == 0) {
            var fields = ['adjustment_period', 'adjustment_driver', 'adjusted_driver', 'target_type']
            props.onLoadingFormField(sessionStorage.getItem('application'), fields)
        }
    }, [contractDependencyEnabled, targetTabEnabled])
    useEffect(() => {
        if (props.formFields && props.formFields.records)
            setFormFields(props.formFields.records)
    }, [props.formFields])

    const totalSteps = () => {
        return getSteps().length;
    };
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const skippedSteps = () => {
        return skipped.size;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
        if (steps[step] == "Calculation Rules*") {
            if (eligibilityRulesValidate && eligibilityData2 && eligibilityData2.length > 0) {
                const updatedData = eligibilityData.map(item => {
                    const { tableData, ...rest } = item;
                    return rest;
                });
                props.getEligibilityRulesValidateData(updatedData, 'Toast');
                setEligibilityRulesValidate(false)
            }
        }
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const completedSteps = () => {
        return completed.size;
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleClickOutside = () => {
        setOnBLock(true)
        handleRelaseLock();
    };
    const handleComplete = () => {
        var state = history.location.state
        setOnSubmit(true);
        setOnBLock(false)
        handleRelaseLock();
        var contractNumber = { "contract_number": dataFromAPI && dataFromAPI.contract_number && dataFromAPI.contract_number ? dataFromAPI.contract_number : 0 }
        var eligibilityRulesObject = { "eligibility_rules": eligibilityData };
        var targetRulesObject = { "target_rules": targetRulesData };
        var contractDependencyRules = { "contract_dependency_rules": generalData.contract_dependency == 'Yes' ? contractDependencyData : [] };
        var calculationRuleModfier = { "contract_calculation_rule_modifiers": generalData.calculation_rule_modifier == 'Yes' ? calcModifierData : [] };
        var multiplePayment = { "contract_multiple_payment_rules": financialData.payment_level == 'Flexible Payment' ? multiPaymentData : [] };
        var calculationRulesObject = { "calculation_rules": calculationData };
        var internalNotesObject = { "notes": [...internalNotesData, ...comments] };
        if (dataFromAPI && dataFromAPI.incentive_amount && dataFromAPI.incentive_amount) {
            incentiveData = {
                "incentive_amount": dataFromAPI.incentive_amount,
                "incentive_rate": dataFromAPI.incentive_rate
            }
        }
        paymentData = {
            "accrual_amount": dataFromAPI.accrual_amount,
            "payment_amount": dataFromAPI.payment_amount
        }
        // if ((approvalStatusData.approval_status === 'submit_approval' || approvalStatusData.approval_status === 'acc' || approvalStatusData.approval_status === 'rej')
        //     && (approvalStatusData.approval_group_id === 0 || !approvalStatusData.approval_group_id)) {
        //     alert('Please select approval group')
        // } else {
        if (dataFromAPI.is_update_approval_contract === true) {
            var completeData = Object.assign(contractNumber, targetRulesObject, generalData, financialData, targetRulesData, miscellaneousData, eligibilityRulesObject, calculationRulesObject, approvalStatusData, internalNotesObject,
                incentiveData, paymentData, targetRulesObject, contractDependencyRules, calculationRuleModfier, multiplePayment);
            var data = {
                'contract_status': dataFromAPI.contract_status,
                'notes': comments
            }
            if (approvalStatusData.approval_status === 'acc') {
                props.newAPIForAccRe('acc', data, dataFromAPI.id, history.location.state);
            } else if (approvalStatusData.approval_status === 'rej') {
                props.newAPIForAccRe('rej', data, dataFromAPI.id, history.location.state);
            }
            else {
                props.onPut(completeData, null, null, state, dataFromAPI.id);
            }
        } else {
            var completeData = Object.assign(contractNumber, targetRulesObject, generalData, financialData, targetRulesData, miscellaneousData, eligibilityRulesObject, calculationRulesObject, approvalStatusData,
                internalNotesObject, incentiveData, paymentData, targetRulesObject, contractDependencyRules, calculationRuleModfier, multiplePayment);

            if (dataFromAPI.approval_status === 'acc' || dataFromAPI.approval_status === 'changes_acc') {
                completeData['IsUpdateApprovalContract'] = true;
                props.onPut(completeData, 'CR', null, state, dataFromAPI.id);
            } else {
                props.onPut(completeData, null, null, state, dataFromAPI.id);
            }
        }

    };
    const handleEligiRules = () => {
        const updatedData = eligibilityData.map(item => {
            const { tableData, ...rest } = item;
            return rest;
        });
        props.getEligibilityRulesValidateData(updatedData);
    }
    function handleWarningDialog(bool) {
        if (bool) {
            handleComplete();
            setEligibilityRulesMessagePopup(false);
        } else {
            setEligibilityRulesMessagePopup(false);
        }
    }
    function handleRelaseLock() {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        let data = {
            "id": appId,
            "lock": "contract_data"
        }
        props.getReleaseLockDetails(data)
    }
    const handleDiscard = () => {
        setOnBLock(true);
        props.uploadEligibilityRulesClear();
        if (history.location.state === "searchByContract") {
            history.push({
                pathname: "/contract-setup",
                state: "searchByContract",
            });
        }
        else {
            history.push("/contract-setup");
        }
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/contract-setup");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
        if (steps[newActiveStep] == "Calculation Rules*") {
            if (eligibilityRulesValidate && eligibilityData2 && eligibilityData2.length > 0) {
                const updatedData = eligibilityData.map(item => {
                    const { tableData, ...rest } = item;
                    return rest;
                });
                props.getEligibilityRulesValidateData(updatedData, 'Toast');
                setEligibilityRulesValidate(false)
            }
        }
    };
    function checkDependency(arr, obj) {
        for (let i = 0; i < arr.length; i++) {
            if (obj[arr[i]] === "" || obj[arr[i]] === undefined) {
                return false;
            }
        }
        return true;
    }
    function handleGeneralDataChange(key, value, mandatoryFields) {
        setGeneralDataMandatory(mandatoryFields)
        if (key === 'calculation_method') {
            setCalculationMethod(value);
        }
        if (key === 'posting_schema') {
            setPostingSchema(value);
        }
        if (key === 'incentive_rate_type') {
            setIncentiveRateType(value);
        }
        if (contractData.calculation_method && contractData.incentive_rate_type && (contractData.calculation_method === 'Flat Rate' || contractData.calculation_method === 'Fixed Amount') && contractData.incentive_rate_type === 'Attribute') {
            setEligibilityEnabled(false)
        } else {
            setEligibilityEnabled(true)
        }
        if (key === 'commitment_achievement') {
            if (value != 'No') {
                setTargetTabEnabled(true);
            } else {
                setTargetRulesData([]);
                setTargetTabEnabled(false);
            }

        }
        if (key === 'maximum_amount_period') {
            setMaximumAmountPeriod(value);
        }
        if (key) {
            generalData[key] = value
        }
        setCalculationSchema(value);
        if (key === 'source_data_type')
            setSourceDataType(value);
        if (key === 'valid_from')
            setStartDate(value);
        if (key === 'valid_to')
            setEndDate(value);
        if (key == 'contract_dependency') {
            if (value == 'Yes') {
                setContractDependencyEnabled(true);
            }
            else {
                setContractDependencyEnabled(false);
            }
        }
        if (key === 'calculation_rule_modifier') {
            if (value == 'Yes') {
                setCalcModifierEnabled(true);

            } else {
                setCalcModifierEnabled(false);
                setCalcModifierData([])
            }
        }
        checkValues();
        setNextSetupEnabled(checkDependency(mandatoryFields, generalData))
    };
    function handleFinancialDataChange(key, value, mandatoryFields) {
        var fields = mandatoryFields;
        setFinancialDataMandatory(mandatoryFields)
        if (key)
            financialData[key] = value;
        if (financialData['payment_level'] == 'Flexible Payment' // financialData[key] == 'Flexible Payment' // && financialData['payment_partner_role'] != ''

        ) {
            setMultiPaymentRuleEnabled(true);
            // setMultiPaymentData([])

        } else {
            setMultiPaymentRuleEnabled(false);
            setMultiPaymentData([])
        }
        if (key == 'payment_method')
            setPaymentMethod(value)
        // if (key == 'payment_partner_role')
        //     setPaymentPartnerType(value)
        if (key == 'payment_aggregation_level') {
            setPaymentAggregationLevel(value)
        }

        if (key == 'payment_level' && value != 'Flexible Payment') {
            fields = mandatoryFields && mandatoryFields.filter(item => item != 'payment_aggregation_level')
        }
        if (key == 'payment_level' && value == 'Flexible Payment') {
            fields = mandatoryFields && mandatoryFields.filter(item => item != 'payment_partner_role')
        }
        setNextSetupEnabled2(checkDependency(fields, financialData))
        checkValues();

    };
    function handleMiscellaneousDataChange(key, value, mandatoryFields) {
        if (key)
            miscellaneousData[key] = value;
        setMiscellaneousDataMandatory(mandatoryFields)
        checkValues();
        setNextSetupEnabled3(checkDependency(mandatoryFields, miscellaneousData))
    };
    function handleTargetRules(values) {
        setTargetRulesData(values)
        checkValues();
    };
    function handleontractDependency(values) {
        setContractDependencyData(values)
        checkValues();
    }
    function handleEligibilityRules(values) {
        setEligibilityData(values);
        setEligibilityData2(values)
        setEligibilityRulesValidate(true)
        checkValues();
    };
    function handleCalculationRules(values) {
        setCalculationData(values);
        checkValues();
    };
    function handleApprovalStatus(key, value) {
        approvalStatusData[key] = value;
    };
    function handleInternalNotes(value, type) {
        if (type == 'attachments')
            setInternalNotesData(value)
        else
            setComments(value)

    };
    function handleCalculationModifier(values) {
        setCalcModifierData(values)
        checkValues();
    }
    function handleMultiplePayment(values) {
        setMultiPaymentData(values)
        checkValues();
    }
    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        if (!targetTabEnabled) {
            if (eligibilityEnabled) {
                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange} savetriggered={saveTriggered} ref={childRef1} fieldData={contractFormFields} datageneral={contractData} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange}
                        savetriggered={saveTriggered} ref={childRef2} postingSchema={generalData['posting_schema']} activeDropdown={activeDropdown} fieldData={contractFormFields} data={contractData} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment} data={contractData}
                        payment_method={paymentMethod} partnerType={paymentAggregationLevel} //partnerType={paymentPartnerType} 
                        fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ? [
                        <ContractDependency onChange={handleontractDependency} description={contractData.internal_description} formFields={formFields}
                            startDate={startDate} endDate={endDate} contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData}
                        />] : []),
                    <EligibilityRules onChange={handleEligibilityRules} calculationSchema={calculationSchema} data={contractData}
                        sourceDataType={sourceDataType} startDate={startDate} endDate={endDate} activeDropdown={activeDropdown}
                        calculationMethod={contractData && contractData.calculation_method ? contractData.calculation_method : ''} maximumAmountPeriod={maximumAmountPeriod}
                    />,
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={contractData && contractData.calculation_method ? contractData.calculation_method : ''} onChange={handleCalculationRules}
                        incentiveRateType={incentiveRateType} sourceDataType={sourceDataType} activeDropdown={activeDropdown}
                        startDate={startDate} endDate={endDate} maximumAmountPeriod={maximumAmountPeriod} data={contractData} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier} sourceDataType={sourceDataType}
                        startDate={startDate} endDate={endDate} formFields={formFields} data={contractData}
                    />] : []),
                    <Miscellaneous onChange={handleMiscellaneousDataChange} ref={childRef3} data={contractData} fieldData={contractFormFields} />,
                    <InternalNotes submitValues={handleInternalNotes} data={contractData} attachments={internalNotesData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            } else {
                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange} savetriggered={saveTriggered} ref={childRef1} fieldData={contractFormFields} datageneral={contractData} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange} savetriggered={saveTriggered}
                        ref={childRef2} postingSchema={generalData['posting_schema']} activeDropdown={activeDropdown} fieldData={contractFormFields} data={contractData} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment} data={contractData}
                        payment_method={paymentMethod} partnerType={paymentAggregationLevel} //partnerType={paymentPartnerType} 
                        fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ?
                        [<ContractDependency onChange={handleontractDependency} description={contractData.internal_description} formFields={formFields}
                            startDate={startDate} endDate={endDate} contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData}
                        />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={contractData && contractData.calculation_method ? contractData.calculation_method : ''} onChange={handleCalculationRules}
                        incentiveRateType={incentiveRateType} sourceDataType={sourceDataType} activeDropdown={activeDropdown}
                        startDate={startDate} endDate={endDate} maximumAmountPeriod={maximumAmountPeriod} data={contractData} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier} sourceDataType={sourceDataType}
                        startDate={startDate} endDate={endDate} formFields={formFields} data={contractData}
                    />] : []),
                    <Miscellaneous onChange={handleMiscellaneousDataChange} ref={childRef3} data={contractData} fieldData={contractFormFields} />,
                    <InternalNotes submitValues={handleInternalNotes} data={contractData} attachments={internalNotesData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            }
        } else {
            if (eligibilityEnabled) {

                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange} savetriggered={saveTriggered} ref={childRef1} fieldData={contractFormFields} datageneral={contractData} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange} savetriggered={saveTriggered} ref={childRef2}
                        postingSchema={generalData['posting_schema']} activeDropdown={activeDropdown} fieldData={contractFormFields} data={contractData} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment} data={contractData}
                        payment_method={paymentMethod} partnerType={paymentAggregationLevel} //partnerType={paymentPartnerType}
                        fieldData={contractFormFields}
                    />] : []),
                    <TargetRules submitValues={handleTargetRules} startDate={startDate} endDate={endDate} fieldData={contractFormFields} data={contractData}
                        formFields={formFields} />,
                    ...(contractDependencyEnabled ?
                        [<ContractDependency onChange={handleontractDependency} description={contractData.internal_description} formFields={formFields}
                            startDate={startDate} endDate={endDate} contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData}
                        />] : []),
                    <EligibilityRules onChange={handleEligibilityRules} calculationSchema={calculationSchema} sourceDataType={sourceDataType}
                        calculationMethod={contractData && contractData.calculation_method ? contractData.calculation_method : ''}
                        startDate={startDate} endDate={endDate} activeDropdown={activeDropdown} data={contractData} maximumAmountPeriod={maximumAmountPeriod}
                    />,
                    ...(calcRuleEnabled ? [<CalculationRules onChange={handleCalculationRules} incentiveRateType={incentiveRateType} calculationMethod={contractData && contractData.calculation_method ? contractData.calculation_method : ''} sourceDataType={sourceDataType}
                        startDate={startDate} endDate={endDate} maximumAmountPeriod={maximumAmountPeriod} activeDropdown={activeDropdown} data={contractData} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier} sourceDataType={sourceDataType}
                        startDate={startDate} endDate={endDate} formFields={formFields} data={contractData}
                    />] : []),
                    <Miscellaneous onChange={handleMiscellaneousDataChange} ref={childRef3} data={contractData} fieldData={contractFormFields} />,
                    <InternalNotes submitValues={handleInternalNotes} data={contractData} attachments={internalNotesData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            } else {
                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange} savetriggered={saveTriggered} ref={childRef1} fieldData={contractFormFields} datageneral={contractData} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange} savetriggered={saveTriggered} ref={childRef2}
                        postingSchema={generalData['posting_schema']} activeDropdown={activeDropdown} fieldData={contractFormFields} data={contractData} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment} data={contractData}
                        payment_method={paymentMethod} partnerType={paymentAggregationLevel} //partnerType={paymentPartnerType} 
                        fieldData={contractFormFields}
                    />] : []),
                    <TargetRules submitValues={(values) => handleTargetRules(values)} startDate={startDate} endDate={endDate} fieldData={contractFormFields} data={contractData}
                        formFields={formFields} />,
                    ...(contractDependencyEnabled ?
                        [<ContractDependency onChange={handleontractDependency} description={contractData.internal_description} formFields={formFields}
                            startDate={startDate} endDate={endDate} contractId={contractData.contract_number} fieldData={contractFormFields} data={contractData}
                        />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={contractData && contractData.calculation_method ? contractData.calculation_method : ''}
                        onChange={handleCalculationRules} incentiveRateType={incentiveRateType} sourceDataType={sourceDataType} data={contractData}
                        startDate={startDate} endDate={endDate} maximumAmountPeriod={maximumAmountPeriod} activeDropdown={activeDropdown} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier} sourceDataType={sourceDataType}
                        startDate={startDate} endDate={endDate} formFields={formFields} data={contractData}
                    />] : []),
                    <Miscellaneous onChange={handleMiscellaneousDataChange} ref={childRef3} data={contractData} fieldData={contractFormFields} />,
                    <InternalNotes submitValues={handleInternalNotes} data={contractData} attachments={internalNotesData} />,
                    <ApprovalStatus submitValues={handleApprovalStatus} data={contractData} submitComments={handleInternalNotes} comments={comments} />
                ];
            }
        }
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }
    useEffect(() => {
        if (nextSetupEnabled || nextSetupEnabled2 || nextSetupEnabled3) {
            checkValues()
        }
    }, [nextSetupEnabled, nextSetupEnabled2, nextSetupEnabled3])
    function checkValues() {
        var d1 = new Date(generalData.valid_to);
        var d2 = new Date(generalData.valid_from);
        if (generalData['posting_schema'] === 'Accrual Only' || generalData['posting_schema'] === 'Settlement Only'
            || generalData['posting_schema'] === 'No Postings' || generalData['posting_schema'] === 'Payment Only'
        ) {
            if (generalData['posting_schema'] === 'Accrual Only') {
                if (
                    // (approvalStatusData.approval_status/* === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id)*/)
                    // approvalStatusData.approval_status
                    // && generalData.contract_type
                    // && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    // && generalData.application_type
                    // && generalData.internal_description
                    // && generalData.currency
                    // && generalData.valid_from
                    // && generalData.valid_to
                    // && generalData.contract_purpose
                    // && generalData.source_data_type
                    // && generalData.posting_schema
                    // && generalData.workflow_name
                    // && financialData.accrual_frequency
                    // && financialData.accrual_level
                    // && financialData.copa_level
                    // && 
                    d1 >= d2 &&
                    nextSetupEnabled && nextSetupEnabled2 && nextSetupEnabled3

                ) {
                    setSaveDisabled(false)
                } else {
                    setSaveDisabled(true)
                }
            } else if (generalData['posting_schema'] === 'Settlement Only') {
                if (
                    // (approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                    // approvalStatusData.approval_status &&
                    // generalData.contract_type
                    // && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    // && generalData.application_type
                    // && generalData.internal_description
                    // && generalData.currency
                    // && generalData.valid_from
                    // && generalData.valid_to
                    // && generalData.contract_purpose
                    // && generalData.source_data_type
                    // && generalData.posting_schema
                    // && generalData.workflow_name
                    // && financialData.payment_frequency && financialData.payment_calendar
                    // && financialData.payment_terms && financialData.payment_partner_role
                    // && financialData.payment_level && financialData.payment_method
                    // && financialData.payment_partner
                    // && financialData.payment_terms
                    // && d1 >= d2
                    // && financialData.payment_due_date
                    nextSetupEnabled && nextSetupEnabled2 && nextSetupEnabled3
                    //  && d1 >= d2
                    //&& approvalStatusData.approval_group_id
                ) {
                    setSaveDisabled(false)
                } else {
                    setSaveDisabled(true)
                }

            }
            if (generalData['posting_schema'] === 'Payment Only') {
                if (
                    // (approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                    // approvalStatusData.approval_status &&
                    // generalData.contract_type
                    // && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    // && generalData.application_type
                    // && generalData.internal_description
                    // && generalData.currency
                    // && generalData.valid_from
                    // && generalData.valid_to
                    // && generalData.contract_purpose
                    // && generalData.source_data_type
                    // && generalData.posting_schema
                    // && generalData.workflow_name
                    // && financialData.payment_level
                    nextSetupEnabled && nextSetupEnabled2 && nextSetupEnabled3
                    // && d1 >= d2
                    // && financialData.payment_due_date
                    //&& approvalStatusData.approval_group_id
                ) {
                    setSaveDisabled(false)
                } else {

                    setSaveDisabled(true)
                }
            }

            else if (generalData['posting_schema'] === 'No Postings') {

                if (
                    // (approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                    // approvalStatusData.approval_status &&
                    // generalData.contract_type
                    // && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    // && generalData.application_type
                    // && generalData.internal_description
                    // && generalData.currency
                    // && generalData.valid_from
                    // && generalData.valid_to
                    // && generalData.contract_purpose
                    // && generalData.source_data_type
                    // && generalData.posting_schema
                    nextSetupEnabled && nextSetupEnabled3 && d1 >= d2
                    // && generalData.workflow_name
                    //   && approvalStatusData.approval_group_id
                ) {
                    setSaveDisabled(false)
                } else {
                    setSaveDisabled(true)
                }
            }
        } else {
            if (
                //(approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                // approvalStatusData.approval_status &&
                // generalData.contract_type
                // && generalData.calculation_method
                // && generalData.incentive_rate_type
                // && generalData.application_type
                // && generalData.internal_description
                // && generalData.currency
                // && generalData.valid_from
                // && generalData.valid_to
                // && generalData.contract_purpose
                // && generalData.source_data_type
                // && generalData.posting_schema
                // && financialData.accrual_frequency
                // && financialData.accrual_level
                // && financialData.copa_level
                // && financialData.payment_frequency
                // && financialData.payment_terms
                // && financialData.payment_level && financialData.payment_method
                // && financialData.payment_due_date
                nextSetupEnabled && nextSetupEnabled2 && nextSetupEnabled3
                // && d1 >= d2
                // && generalData.workflow_name
                //   && approvalStatusData.approval_group_id
            ) {
                setSaveDisabled(false)
            } else {
                setSaveDisabled(true)
            }
        }
        if (generalData.calculation_method === 'Tiered Growth' || generalData.calculation_method === 'Tiered Volume') {
            if (!generalData.tier_type) {
                setSaveDisabled(true)
            }
            if (!generalData.tier_period) {
                setSaveDisabled(true)
            }
        }
        // if (financialData.payment_level === 'Individual Payment') {
        //     if (!financialData.payment_partner_role || !financialData.payment_frequency || !financialData.payment_method || !financialData.payment_terms) {
        //         setSaveDisabled(true)
        //     }
        // } else if (financialData.payment_level === 'Aggregated Payment') {
        //     if (!financialData.payment_partner_role || !financialData.payment_partner || !financialData.payment_frequency || !financialData.payment_method || !financialData.payment_terms) {
        //         setSaveDisabled(true)
        //     }
        // }
        // if (!approvalStatusData.approval_group_id)
    }
    useEffect(() => {
        if ((calculationMethod === 'Flat Rate' || calculationMethod === 'Fixed Amount') && incentiveRateType === 'Attribute') {
        } else {
            if (!eligibilityData.length > 0)
                setSaveDisabled(true)
        }
    }, [eligibilityData])
    useEffect(() => {
        if (activeStep == steps.length - 1) {
            var approveForm = {
                "application_type": generalData.application_type,
                "functionality": "contract_header",
                "workflow_name": generalData.workflow_name,
                "status_description": approvalStatusData.contract_status

            }
            props.getApprovalWorkflow(approveForm)
        }
    }, [activeStep])
    useEffect(() => {
        if (props.data) {
            setCalculationMethod(props.data.calculation_method);
            setCalcModifierData(props.data.contract_calculation_rule_modifiers)
            setMultiPaymentData(props.data.contract_multiple_payment_rules)
            generalData = {
                application_type: props.data.application_type,
                contract_type: props.data.contract_type,
                calculation_method: props.data.calculation_method,
                incentive_rate_type: props.data.incentive_rate_type,
                tier_type: props.data.tier_type,
                contract_group: props.data.contract_group,
                internal_description: props.data.internal_description,
                currency: props.data.currency,
                internal_reference: props.data.internal_reference,
                valid_from: props.data.valid_from,
                contract_sub_type: props.data.contract_sub_type,
                contract_sub_group: props.data.contract_sub_group,
                contract_objective: props.data.contract_objective,
                contract_strategy: props.data.contract_strategy,
                external_description: props.data.external_description,
                negotiation_outcome: props.data.negotiation_outcome,
                negotiation_reason: props.data.negotiation_reason,
                contract_origin: props.data.contract_origin,
                approval_status: props.data.approval_status,
                approval_group_id: props.data.approval_group,
                external_reference: props.data.external_reference,
                valid_to: props.data.valid_to,
                contract_purpose: props.data.contract_purpose,
                source_data_type: props.data.source_data_type,
                posting_schema: props.data.posting_schema,
                posting_approval: props.data.posting_approval,
                legacy_contract_id: props.data.legacy_contract_id,
                go_live_date: props.data.go_live_date,
                flex_fields_1: props.data.flex_fields_1,
                flex_fields_2: props.data.flex_fields_2,
                flex_fields_3: props.data.flex_fields_3,
                flex_fields_4: props.data.flex_fields_4,
                flex_fields_5: props.data.flex_fields_5,
                accrual_run: props.data.accrual_run,
                payment_document_type: props.data.payment_document_type,
                payment_run: props.data.payment_run,
                posting_approval: props.data.posting_approval,
                accrual_amount: props.data.accrual_amount,
                accrual_document_type: props.data.accrual_document_type,
                accrual_run: props.data.accrual_run,
                change_request_id: props.data.change_request_id,
                created_by_id: props.data.created_by_id,
                expires_in: props.data.expires_in,
                is_update_approval_contract: props.data.is_update_approval_contract,
                maximum_incentive_amount: props.data.maximum_incentive_amount,
                maximum_amount_level: props.data.maximum_amount_level,
                organization: props.data.organization,
                organization_id: props.data.organization_id,
                payment_amount: props.data.payment_amount,
                payment_document_type: props.data.payment_document_type,
                payment_run: props.data.payment_run,
                search_tokens: props.data.search_tokens,
                commitment_achievement: props.data.commitment_achievement,
                target_rules: props.data.target_rules,
                tier_evaluation_level: props.data.tier_evaluation_level,
                tier_period: props.data.tier_period,
                update_contract_id: props.data.update_contract_id,
                update_request_closed: props.data.update_request_closed,
                updated_at: props.data.updated_at,
                updated_by: props.data.updated_by,
                updated_by_id: props.data.updated_by_id,
                created_by: props.data.created_by,
                formula_exclusive: props.data.formula_exclusive,
                evaluate_sales_bundle: props.data.evaluate_sales_bundle,
                quota_level: props.data.quota_level,
                maximum_amount_period: props.data.maximum_amount_period,
                contract_dependency: props.data.contract_dependency,
                calculation_rule_modifier: props.data.calculation_rule_modifier,
                workflow_name: props.data.workflow_name
            };
            financialData = {
                accrual_frequency: props.data.accrual_frequency,
                accrual_level: props.data.accrual_level,
                copa_level: props.data.copa_level,
                accrual_calendar: props.data.accrual_calendar,
                accrual_gl_account_credit: props.data.accrual_gl_account_credit,
                accrual_gl_account_debit: props.data.accrual_gl_account_debit,
                payment_frequency: props.data.payment_frequency,
                payment_level: props.data.payment_level,
                payment_aggregation_level: props.data.payment_aggregation_level,
                payment_terms: props.data.payment_terms,
                payment_calendar: props.data.payment_calendar,
                payment_method: props.data.payment_method,
                payment_partner_role: props.data.payment_partner_role,
                payment_partner: props.data.payment_partner,
                internal_reference: props.data.internal_reference,
                postings_block: props.data.postings_block,
                postings_start_date: props.data.postings_start_date,
                postings_end_date: props.data.postings_end_date,
                accrual_credit_profit_center: props.data.accrual_credit_profit_center,
                accrual_debit_profit_center: props.data.accrual_debit_profit_center,
                auto_run_ppa: props.data.auto_run_ppa,
                flex_fields_4: props.data.flex_fields_4,
                flex_fields_5: props.data.flex_fields_5,
                accrual_run: props.data.accrual_run,
                payment_document_type: props.data.payment_document_type,
                payment_run: props.data.payment_run,
                posting_approval: props.data.posting_approval,
                external_reference: props.data.external_reference,
                pass_through: props.data.pass_through,
                pass_through_percentage: props.data.pass_through_percentage,
                pass_through_payment_partner_role: props.data.pass_through_payment_partner_role,
                short_term_period: props.data.short_term_period,
                long_term_period: props.data.long_term_period,
                short_term_account: props.data.short_term_account,
                long_term_account: props.data.long_term_account,
                amortization_value: props.data.amortization_value,
                suppress_negative_rebate: props.data.suppress_negative_rebate,
                payment_due_date: props.data.payment_due_date
            };
            miscellaneousData = {
                pricing_strategy: props.data.pricing_strategy,
                outcome_date: props.data.outcome_date,
                contract_owner: props.data.contract_owner,
                contract_manager: props.data.contract_manager,
                sales_manager: props.data.sales_manager,
                finance_manager: props.data.finance_manager,
                internal_signed_date: props.data.internal_signed_date,
                external_contact_email: props.data.external_contact_email,
                contract_owner_type: props.data.contract_owner_type,
                contract_analyst: props.data.contract_analyst,
                sales_analyst: props.data.sales_analyst,
                finance_analyst: props.data.finance_analyst,
                external_delivery_options: props.data.external_delivery_options,
                external_signed_date: props.data.external_signed_date,
                external_contact_phone: props.data.external_contact_phone,
                image_reference_id: props.data.image_reference_id,
                document_id: props.data.document_id,
                contract_start_date: props.data.contract_start_date,
                contract_end_date: props.data.contract_end_date,
                flex_fields_6: props.data.flex_fields_6,
                flex_fields_7: props.data.flex_fields_7,
                flex_fields_8: props.data.flex_fields_8,
                flex_fields_9: props.data.flex_fields_9,
                flex_fields_10: props.data.flex_fields_10,
                external_contact_name: props.data.external_contact_name,
                external_contract_type: props.data.external_contract_type,
                external_contract_number: props.data.external_contract_number
            };
            if (props.data && props.data.notes) {
                setInternalNotesData(props.data.notes.filter(item => item.note_type != "Submit Decision"))
                setComments(props.data.notes.filter(item => item.note_type == "Submit Decision"))
            }
            setEligibilityData(props.data.eligibility_rules);
            setTargetRulesData(props.data.target_rules);
            setCalculationData(props.data.calculation_rules);
            setContractDependencyData(props.data.contract_dependency_rules)
            setPaymentMethod(props.data.payment_method)
            setPaymentPartnerType(props.data.payment_partner_role)
            setPaymentAggregationLevel(props.data.payment_aggregation_level)
            //}
            approvalStatusData = ({
                "approval_status": props.data.approval_status,
                "approval_group_id": props.data.approval_group_id,
                "level_completed": props.data.level_completed,
                "contract_status": props.data.contract_status


            })
            if (props.data['payment_level'] == 'Flexible Payment' // && props.data['payment_partner_role'] != ''
            ) {
                setMultiPaymentRuleEnabled(true);

            } else {
                setMultiPaymentRuleEnabled(false);
            }
            if (props.data.calculation_rule_modifier == 'Yes') {
                setCalcModifierEnabled(true);

            } else {
                setCalcModifierEnabled(false);
            }
            checkValues();
            setNextSetupEnabled(checkDependency(genralDataMandatory, generalData))
            setNextSetupEnabled2(checkDependency(financialDataMandatory, financialData))
            setNextSetupEnabled3(checkDependency(miscellaneousDataMandatory, miscellaneousData))
        }
    }, [props.data]);
    useEffect(() => {
        if (props.eligbiilityMessage && props.eligbiilityMessage.length == 0) {
            handleComplete();
        }
        if (props.eligbiilityMessage && props.eligbiilityMessage.length > 0) {
            setEligibilityRulesMessagePopup(true)
            setEligibilityMessage(props.eligbiilityMessage)
            setEligibilityRulesValidate(false);
        }
    }, [props.eligbiilityMessage])
    return (
        <OutsideClickHandler onClickOutside={handleClickOutside}>
            <div>
                {eligibilityRuleMessagePopup &&
                    <Dialog open={eligibilityRuleMessagePopup}>
                        <DialogContent classes={{
                            root: classes.dialogContent
                        }}>
                            <Typography variant="h4" className={classes.boldText}>
                                <DialogContentText>
                                    <Typography variant='h2' color="primary" > Are you sure you want to Save this Contract? </Typography>
                                    <div style={{ padding: 20 }}>
                                        <FormLabel style={{ fontSize: '16px', margin: "5px" }} >{eligibilityMessage}</FormLabel>
                                    </div>
                                </DialogContentText>
                            </Typography>
                        </DialogContent>
                        <DialogActions classes={{
                            root: classes.dialogActions
                        }}>
                            <Button onClick={() => handleWarningDialog(true)} color="primary" autoFocus variant='outlined'  >
                                Yes
                            </Button>
                            <Button onClick={() => handleWarningDialog(false)} variant='contained' autoFocus >
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                <div className={classes.root}>
                    <LoadingOverlay
                        active={props.loading}
                        spinner={<RingLoader size={100} />}
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: "50px",
                                "& svg circle": {
                                    stroke: "#045FB4",
                                },
                            }),
                            overlay: (base) => ({
                                ...base,
                                background: "rgba(52, 52, 52, 0)",
                            }),
                            content: (base) => ({
                                ...base,
                                color: "black",
                            }),
                        }}
                        className={classes.spinner}
                    >
                        {/* <Prompt
                            when={blockNavigation}
                            message='Are you sure you want to exit this screen ?'

                        /> */}
                        <div className={classes.stepper}>
                            <Stepper alternativeLabel nonLinear activeStep={activeStep}
                                classes={{ root: classes.stepperContainer }}
                                connector={
                                    <StepConnector
                                        classes={{
                                            line: classes.connectorLine,
                                            alternativeLabel: classes.connectorAlternativeLabel
                                        }}
                                    />
                                }
                            >
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const buttonProps = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}
                                            classes={{ horizontal: classes.horizontal }}
                                        >
                                            <StepButton
                                                onClick={handleStep(index)}
                                                completed={isStepComplete(index)}
                                                {...buttonProps}
                                                classes={{ root: classes.stepButton }}
                                            >
                                                <StepLabel>
                                                    {label}
                                                </StepLabel>
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>
                        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {activeStep !== 0 &&
                                    <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                        Previous Step
                                    </Button>
                                }
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                            >
                                <Button variant="outlined" color="primary" onClick={handleEligiRules}
                                    className={clsx({
                                        [classes.button]: true,
                                        [classes.shiftContent]: !isDesktop,
                                    })}
                                    disabled={saveDisabled ? true :
                                        ((calculationMethod != 'External Calculation' && calculationData && calculationData.length === 0) || (targetTabEnabled && targetRulesData.length === 0) ||
                                            (contractDependencyEnabled && contractDependencyData.length === 0) || (multiPaymentRuleEnabled && multiPaymentData.length == 0)
                                            || (calcModifierEnabled && calcModifierData.length == 0)
                                        ) ? true : false
                                    }
                                > {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                    :
                                    ''
                                    }
                                    {props.loading ? '' : 'Save'}
                                </Button>
                                <Button variant="outlined" color="primary" onClick={handleDiscard} className={classes.button}>
                                    Discard
                                </Button>
                                {!isLastStep() &&
                                    <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}
                                        // disabled={(!calculationMethod || !postingSchema || !sourceDataType) ? true : false}
                                        disabled={nextSetupEnabled == true ? false : true}
                                    >
                                        {activeStep === tabs.notesAndAttachments ? 'Done' : 'Next Step'}
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                        <ConfirmPrompt
                            show={showModal}
                            title="Confirm Navigation" // Custom title
                            message="Are you sure you want to leave this page?" // Custom message
                            onConfirm={confirmNavigation}
                            onCancel={cancelNavigation}
                        />
                    </LoadingOverlay>
                </div>
            </div>
        </OutsideClickHandler>
    );

};

const mapStateToProps = state => {
    return {
        eligibilityRulesData: state.addContractData.eligibilityRulesData,
        eligbiilityMessage: state.addContractData.eligbiilityMessage,
        calculationRulesData: state.addContractData.calculationRulesData,
        contractData: state.addContractData.contractDetailData,
        data: state.addContractData.contractGeneralData,
        loading: state.addContractData.loading,
        dropdownConfig: state.financialCloseData.dropdownConfig,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        formFields: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addContractGeneralData(data)),
        onPut: (data, status, newApi, state, id) => dispatch(putContractGeneralData(data, status, newApi, state, id)),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        //getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        // onCheckEligibillityRulesTable: (id) => dispatch(getEligibilityRulesTableData(id)),
        // onCheckCalculationRulesTable: (id) => dispatch(getCalculationRulesTableData(id)),
        //onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        uploadEligibilityRulesClear: () => dispatch(uploadEligibilityRulesClear()),
        newAPIForAccRe: (status, data, id, state) => dispatch(newAPIForAccRe(status, null, id, data, state)),
        getGeneralUOM: () => dispatch(getGeneralUOM()),
        getPricingCalculationFormula: (page, limit) => dispatch(getPriceFormula(page, limit)),
        onLoadingFormField: (app, data) => dispatch(getDefaultValues(app, null, null, data, 'filter-fields')),
        //getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        getApprovalWorkflow: (data) => dispatch(fetchDocumentStatus(data)),
        getReleaseLockDetails: (data) => dispatch(getReleaseLockDetails(data)),
        getEligibilityRulesValidateData: (data, type) => dispatch(getEligibilityRulesValidateData(data, type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContract);