import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { connect } from 'react-redux';
import { getCollaborationDesignerData, deleteCollaborationDesigner } from '../../redux/actions'
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { LibraryAdd, CloudUpload } from '@mui/icons-material';
import { AddBoxOutlined, DeleteForever, Edit } from '@mui/icons-material';
import RingLoader from "react-spinners/RingLoader";
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    spinner: {
        height: '100vh'
    },
}));

const CollaborationDesignerMain = props => {
    useEffect(() => {
        props.getCollaborationData(1, 15);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [selectedCollaborationId, setSelectedCollaborationId] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getCollaborationData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getCollaborationData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.collaborationData) {
            setAttributeData(props.collaborationData.records);
        }
    }, [props.collaborationData]);
    function editAttribute(id) {
        history.push({
            pathname: '/collaboration-designer/edit-collaboration/' + id,
            id: id
        });
    }
    function viewAttribute(id) {
        history.push({
            pathname: '/collaboration-designer/view-collaboration/' + id,
            id: id
        });
    }
    // const deleteData = (id) => {
    //     props.deleteCollaborationDesigner(id);
    // }
    const handleDeleteClick = (id) => {
        setSelectedCollaborationId(id);
        setOpenConfirmDialog(true);
    };

    const handleConfirmDelete = () => {
        if (selectedCollaborationId) {
            props.deleteCollaborationDesigner(selectedCollaborationId);
        }
        setOpenConfirmDialog(false);
    };

    const handleCloseDialog = () => {
        setOpenConfirmDialog(false);
    };
    
        
    const arrayItem = [];
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}

                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Collaboration Designer </Typography>

                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        //    component={CustomRouterLink}
                        onClick={() => history.push('/collaboration-designer/add-collaboration')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>

                </div>
                {attributeData && attributeData.length > 0 ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Functionality</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Access Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Identifier </TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {attributeData.map(item => {
                                    // arrayItem.push(item.application_type)
                                    // arrayItem.sort(function (a, b) {
                                    //     return a.localeCompare(b); //using String.prototype.localCompare()
                                    //   });
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer' }}>
                                                {item.app_type}{
                                                }</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer' }}>
                                                {item.functionality}{
                                                }</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.access_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.identifier}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => editAttribute(item.ID)}
                                                    size="large">
                                                    <Edit color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => handleDeleteClick(item.ID)}
                                                    size="large">
                                                    <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={props.collaborationData && props.collaborationData.records ? props.collaborationData.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        />
                        {props.collaborationData &&
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                            </div>
                        }
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
                open={openConfirmDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this collaboration?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant='contained'
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        collaborationData: state.CollaborationDesignerData.CollaborationData,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        getCollaborationData: (pagination, limit) => dispatch(getCollaborationDesignerData(pagination, limit)),
        deleteCollaborationDesigner: (id) => dispatch(deleteCollaborationDesigner(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CollaborationDesignerMain);