import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    TableRow,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions,
    Checkbox,
    Chip,
    Input,
    MenuItem,
    Select,
    ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { getAllForecastLevelDropDwon, createForecastLevel, getForecastLevelById } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '80%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 8.4,
        padding: '12px 5px 14px 13px'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    paper: { minWidth: window.screen.width * .50 },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
}));

const EditConfig = props => {
    const classes = useStyles();
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getForecastLevelById(appId);
    }, []);
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [editID, setEditID] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [forecasteLevel, setForecastLevel] = React.useState('');
    const [forecasteLevelDes, setForecastLevelDes] = React.useState('');
    const [forecastingPeriod, setForecastingPeriod] = React.useState('');
    const [forecastingAttributes, setForecastingAttributes] = React.useState([]);
    const [forecastingTarget, setForecastingTarget] = React.useState('');
    const [forecastPeriodArray, setForecastPeriodArray] = React.useState([]);
    const [forecastAttributesArray, setForecastAttributesArray] = React.useState([]);
    const [forecastTargetArray, setForecastTargetArray] = React.useState([]);
    const [aiAlgorithm, setAiAlgorithm] = React.useState('');
    const aiAlgorithmArray = ['ARIMA', 'PROPHET', 'SARIMA'];
    useEffect(() => {
        if (props.dropdwonForeCast) {
            setForecastPeriodArray(props.dropdwonForeCast.date_fields)
            setForecastAttributesArray(props.dropdwonForeCast.string_fields)
            setForecastTargetArray(props.dropdwonForeCast.numeric_fields)
        }
    }, [props.dropdwonForeCast]);
    const handleAiAlgorithm = (e) => {
        setAiAlgorithm(e.target.value);
    }
    const handleForecastLevel = (e) => {
        setForecastLevel(e.target.value);
    }
    const handleForecastLevelDes = (e) => {
        setForecastLevelDes(e.target.value);
    }
    const handleForecastingPeriod = (e) => {
        setForecastingPeriod(e.target.value)
    }
    const handleForecastingTarget = (e) => {
        setForecastingTarget(e.target.value)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,
    }
    const [listOpen, setListOpen] = React.useState('');
    const [type, setType] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    // const [analysislevel, setAnalysisLevel] = React.useState([]);
    const isAllSelected =
        forecastAttributesArray && forecastAttributesArray.length > 0 && selected.length === forecastAttributesArray.length;

    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === forecastAttributesArray ? [] : forecastAttributesArray);
            return;
        }
        setSelected(newSelected)

    }
    function onAdd() {
        setForecastingAttributes(selected)
        setListOpen(false)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])
        setType('')

    }

    function handleOpen(type) {
        setType(type)
        setSelected(forecastingAttributes)
        setListOpen(true)
    }
    useEffect(() => {
        setOpen(true);
        props.getAllForecastLevelDropDwon();
    }, []);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    function handleOnSubmit() {
        var data = {
            "forecasting_level": forecasteLevel,
            "forecasting_level_description": forecasteLevelDes,
            "forecasting_period": forecastingPeriod,
            "forecasting_attributes": forecastingAttributes,
            "forecasting_target": forecastingTarget,
            "ai_algorithm": aiAlgorithm,
        }
        props.onSubmit(data, editID);
    }
    function handleCancelDialog() {
        history.push('/forecasting-config');
    }
    function humanize(str) {
        var i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
    useEffect(() => {
        if (props.foreCastLevelSingleData) {
            setForecastLevel(props.foreCastLevelSingleData.forecasting_level)
            setForecastLevelDes(props.foreCastLevelSingleData.forecasting_level_description)
            setForecastingPeriod(props.foreCastLevelSingleData.forecasting_period)
            props.foreCastLevelSingleData && props.foreCastLevelSingleData.forecasting_attributes && props.foreCastLevelSingleData.forecasting_attributes.length > 0 &&
                setForecastingAttributes(props.foreCastLevelSingleData.forecasting_attributes);
            setForecastingTarget(props.foreCastLevelSingleData.forecasting_target)
            setAiAlgorithm(props.foreCastLevelSingleData.ai_algorithm)
        }
    }, [props.foreCastLevelSingleData]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    variant="h4"
                    classes={{
                        root: classes.link,
                    }}
                    to="/forecasting-config"
                >
                    AI Model - Sales Forecast
                </Link>
                <Typography color="textPrimary" variant="h4">
                    Edit Sales Forecast
                </Typography>
            </Breadcrumbs>
            {/* <Typography color="primary" variant='h1'>Edit Config</Typography> */}
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Forecast Level
                    </FormLabel>
                    <OutlinedInput
                        value={forecasteLevel}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleForecastLevel} />
                    <FormLabel className={classes.formLabel} required>
                        Forecast Level Description
                    </FormLabel>
                    <OutlinedInput
                        value={forecasteLevelDes}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleForecastLevelDes} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={forecasteLevel && forecasteLevelDes ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required style={{ textTransform: 'capitalize' }}>
                                            Forecasting Period
                                        </FormLabel>
                                        <Select
                                            style={{ height: 37 }}
                                            value={forecastingPeriod}
                                            onChange={handleForecastingPeriod}
                                        >
                                            {forecastPeriodArray?.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={humanize(item)} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Forecasting Attributes
                                        </FormLabel>
                                        <Select
                                            value={forecastingAttributes}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            onOpen={() => handleOpen('analysis_level')}
                                            onClose={() => setListOpen(false)}
                                            open={listOpen && type == 'analysis_level'}
                                            renderValue={(level) => level.toString().replace(/_/g, ' ')}
                                            style={{ textTransform: 'capitalize', marginTop: '0', width: '100%' }}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            multiple
                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={isAllSelected} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {forecastAttributesArray?.map(item => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item) > -1} />
                                                        <ListItemText primary={item.replace(/_/g, ' ')} />
                                                    </MenuItem>
                                                );
                                            })}
                                            <div className={classes.dropdownAction}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Forecasting Target
                                        </FormLabel>
                                        <Select
                                            style={{ height: 37 }}
                                            value={forecastingTarget}
                                            onChange={handleForecastingTarget}
                                        >
                                            {forecastTargetArray?.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item && humanize(item)} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            AI Model Name
                                        </FormLabel>
                                        <Select
                                            style={{ height: 37 }}
                                            value={aiAlgorithm}
                                            onChange={handleAiAlgorithm}
                                        >
                                            {aiAlgorithmArray?.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item && item.replace(/_/g, ' ')} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={forecastingPeriod && forecastingAttributes && aiAlgorithm
                            && forecastingTarget
                            ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(createForecastLevel(data, id, 'edit')),
        getForecastLevelById: (id) => dispatch(getForecastLevelById(id)),
        getAllForecastLevelDropDwon: () => dispatch(getAllForecastLevelDropDwon()),
    }
}
const mapStateToProps = state => {
    return {
        dropdwonForeCast: state.forecastData.dropdwonForeCast,
        loading: state.forecastData.loading,
        foreCastLevelSingleData: state.forecastData.foreCastLevelSingleData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditConfig);