import React, { useEffect } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import ChipInput from 'material-ui-chip-input';
import clsx from 'clsx';
import { makeStyles, useTheme } from "@mui/styles";
import {
    Grid, Select, FormLabel, MenuItem, Button, Typography, Checkbox,
    ListItemText, Drawer, AppBar, Toolbar, TextField, CssBaseline, Divider, IconButton
} from '@mui/material';
import { getCustomerMasterData, getQualifierConfig, getMarginPriceDiff } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';
import MarginPriceDiffChart from '../../Analytics/SyncFusion/MarginPriceDiffChart';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: 65
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        width: '100%'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textInput: {
        border: '1px solid #E0E0E0',
        width: '100%',
        borderRadius: 5,
        color: '#1675e0'
    },
    select: {
        width: 209
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 30,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    }
}));

const MarginPricePercentage = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        props.getConfigData(1, 0);
        props.onCheckCustomerTable();
    }, []);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,


    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [periodicity, setPeriodicity] = React.useState('day');
    const [listOpen, setListOpen] = React.useState('');
    const [type, setType] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [analysislevel, setAnalysisLevel] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const analysisLevelArray = ['customer_number', 'customer_group', 'customer_chain', 'material_number', 'material_group', 'product_hierarchy']
    const [keyFigureName, setKeyFigureName] = React.useState('');
    const isAllSelected =
        analysisLevelArray.length > 0 && selected.length === analysisLevelArray.length;


    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (type == 'filter')
                setSelected(selected.length === analysisLevelArray ? [] : analysisLevelArray);
            else
                setSelected(selected.length === analysisLevelArray ? [] : analysisLevelArray);
            return;
        }
        setSelected(newSelected)

    }
    function onAdd() {
        if (type == 'filter')
            setFilters(selected)
        else
            setAnalysisLevel(selected)
        setListOpen(false)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])
        setType('')

    }
    const handleStartDate = (e) => {
        setStartDate(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }
    const handleKeyFigureName = (value) => {
        setKeyFigureName(value);
    }
    function handleOpen(type) {
        setType(type)
        if (type == 'filter')
            setSelected(filters)
        else
            setSelected(analysislevel)
        setListOpen(true)
    }

    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [forecastStartDate, setForecastStartDate] = React.useState(null);
    const [forecastEndDate, setForecastEndDate] = React.useState(null);
    const [filterList, setFilterList] = React.useState([]);
    const [active, setActive] = React.useState(false);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = [];
            var tempNameArray = [];
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNumberList(tempArray)
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.configData && props.configData.records) {
            const list = props.configData.records.map(e => e.qualifier_key)
            setFilterList(list);
        }
    }, [props.configData]);

    const handleSubmit = () => {
        setActive(true)

        filterList.map((item) => {
            if (filters.includes(item)) {

            } else {
                delete attributeObjectAPI[item]
            }
        })
        var sdate = Moment.utc(startDate).format('MM/DD/YYYY')
        var edate = Moment.utc(endDate).format('MM/DD/YYYY')
        var formData = {
            "start_date": sdate,
            "end_date": edate,
            "attribute_name": analysislevel,
            "simulation_category": keyFigureName,
            "filter": attributeObjectAPI,
        }
        props.onSubmit(formData);
        setOpen(false);
    }
    const handleAnalysisLevelValues = (value, item) => {
        var attributeObject = [];
        attributeObject.push({
            [item]: value
        });
        if (item === 'customer_number') {
            value = [value]
        }
        setAttributeObjectAPI(prevState => ({
            ...prevState,
            [item]: value
        }));

    }

    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar style={{ position: 'absolute', marginTop: 65 }}>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                        size="large">
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                {/* console.log() */}
                {active ?
                    <div style={{ height: '100%', padding: 0 }}>
                        <MarginPriceDiffChart id={'chart'}
                            height='100%'
                            data={props.pricingData}
                            analysisLevel={analysislevel && analysislevel.length > 0 ? analysislevel[0] : ''}
                        />

                    </div>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }

            </main>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider
                    sx={{
                        opacity: "0.6"
                    }} />
                <Grid container className={classes.root} >
                    <Grid container style={{ margin: 10, padding: 5 }}>
                        <Grid
                            item
                            md={12}
                            xs={12}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }} required={forecastStartDate && forecastEndDate ? false : true}>
                                    Start Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}
                                    required={forecastStartDate && forecastEndDate ? false : true}>
                                    End Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }} required>
                                    Simulation Category
                                </FormLabel>
                                <Select
                                    disableUnderline
                                    value={keyFigureName}
                                    onChange={(e) => handleKeyFigureName(e.target.value)}
                                    displayEmpty
                                    style={{ marginTop: 6 }}
                                >
                                    <MenuItem value={'CPQ'} key={'CPQ'}>
                                        {'CPQ'}
                                    </MenuItem>
                                    <MenuItem value={'price catalog'} key={'price catalog'}>
                                        {'Price Catalog'}
                                    </MenuItem>
                                    <MenuItem value={'sales data - forecast'} key={'sales data - forecast'}>
                                        {'Sales Data - Forecast'}
                                    </MenuItem>
                                    <MenuItem value={'sales data - historical'} key={'sales data - historical'}>
                                        {'Sales Data - Historical'}
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }} required>
                                    Analysis Level
                                </FormLabel>
                                <Select
                                    value={analysislevel}

                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={() => handleOpen('analysis_level')}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen && type == 'analysis_level'}
                                    renderValue={(level) => level.toString().replace(/_/g, ' ')}
                                    style={{ textTransform: 'capitalize' }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {analysisLevelArray.map(item => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                            </MenuItem>
                                        );
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }}>
                                    Filters
                                </FormLabel>
                                <Select
                                    value={filters}

                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={() => handleOpen('filter')}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen && type == 'filter'}
                                    renderValue={(level) => level.toString().replace(/_/g, ' ')}
                                    style={{ textTransform: 'capitalize' }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {filterList && filterList.map(item => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                            </MenuItem>
                                        );
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        {filters && filters.length > 0 &&
                            filters.map((item) => {
                                return (
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                            <FormLabel
                                                style={{ color: 'grey', fontSize: 14, marginBottom: 5, textTransform: 'capitalize' }}>
                                                {item.replace(/_/g, ' ')}
                                            </FormLabel>
                                            <ChipInput
                                                classes={{
                                                    root: classes.rootContainer,
                                                    chip: classes.chip,
                                                    input: classes.input,
                                                    inputRoot: classes.inputRoot,
                                                    chipContainer: classes.chipContainer,
                                                    label: classes.chipLabel
                                                }}
                                                onChange={(value) => handleAnalysisLevelValues(value, item)}
                                                disableUnderline={true}
                                                className={clsx({
                                                    [classes.textInput]: true
                                                })}
                                                style={
                                                    item != 'customer_number' ?
                                                        { display: 'block', marginRight: 15, width: 209 } : { display: 'none' }
                                                }
                                            />

                                            <Autocomplete
                                                options={customerNumberList}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                                )}
                                                filterOptions={filterOptions}
                                                value={attributeObjectAPI['customer_number']}
                                                style={{ marginBottom: 10, display: item != 'customer_number' ? 'none' : 'block', width: 209 }}
                                                onChange={(event, newValue) => handleAnalysisLevelValues(newValue, item)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        {customerNameListArray[customerNumberList.indexOf(option)] ? customerNameListArray[customerNumberList.indexOf(option)] : ''} - ({option})
                                                    </li>)
                                                }
                                                classes={{
                                                    option: {
                                                        borderBottom: `1px solid red`,
                                                        // Hover
                                                        '&[data-focus="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                        // Selected
                                                        '&[aria-selected="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                    },
                                                    listbox: {
                                                        padding: 0,
                                                    },
                                                    inputRoot: {
                                                        borderRadius: '0px !important'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <div style={{ width: '100%', borderBottom: '1px solid #e5e5e5' }} />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                        <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit}
                            disabled={startDate && endDate && analysislevel && analysislevel.length > 0 && keyFigureName ? false : true} >
                            Apply
                        </Button>
                    </div>
                </Grid>
            </Drawer>
        </div >
    );
}


const mapStateToProps = state => {
    return {
        customerMasterData: state.customerData.customerMasterData,
        pricingData: state.initialData.topBottomPerformer,
        loading: state.initialData.loading,
        configData: state.pricingData.qualifierConfigData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getMarginPriceDiff(data)),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
        getConfigData: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MarginPricePercentage);