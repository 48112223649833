import React, { useEffect, useRef, useMemo } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select,
    CardHeader
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { addAccrualDesigner, getDefaultValues, getAllApprovalDesigner, getAccrualDesignerSingleItem, getDefaultValuesAllData } from '../../redux/actions';
import { connect } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
import { LabelText } from '../../components/Inputs';
var editableId;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    error: {
        border: '1px solid red !important'
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2rem',
        // marginBottom: '1.5rem',
        marginLeft: '0rem',
        // marginRight: '1rem'
    },
}));

const ViewAccrualDesigner = props => {
    const classes = useStyles();
    const [userStore, setUserStore] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const [pLAccountStore, setPLAccountStore] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getAccrualDesignerSingleItem(appId);
    }, []);
    useEffect(() => {
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {

            setKeyValuePairObjectNew(props.formFieldsAllData.records[0].label_names)
        }
    }, [props.formFieldsAllData])
    useEffect(() => {
        if (props.items) {
            setUserStore([props.items])
            setPLAccountStore(props.items['multiple_attribute_filters'])
            setApplicationType(props.items['app_type']);
            setSourceDataType(props.items['source_data_type'])
        }
    }, [props.items]);
    useEffect(() => {
        if (applicationType && sourceDataType) {
            props.getDefaultValuesAllData('attribute_name', sourceDataType, applicationType);
        }

    }, [applicationType, sourceDataType]);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/accrual-designer'
                >
                    Accrual Designer
                </Link>
                <Typography color="textPrimary" variant='h4'>View Accrual</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            md={12}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {userStore.length > 0 ?

                                userStore.map((item, index) => {
                                    return (
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow key={index} >
                                                        <TableCell align='center' className={classes.tabHead} >Application Type</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Source Data Type</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Primary Anchor</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >{item.primary_anchor?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Document Type</TableCell>
                                                        {/* <TableCell align='center' className={classes.tabHead} >Tier Projection</TableCell> */}
                                                        <TableCell align='center' className={classes.tabHead} >Reference Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Assignment Number</TableCell>
                                                        {/* <TableCell align='center' className={classes.tabHead} >Accrual Aggregation</TableCell> */}
                                                        <TableCell align='center' className={classes.tabHead} > Reversal Document Type</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Clearing Account</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Clearing Profit Center</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Profit Center Source</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Profit Center</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >

                                                    <StyledTableRow key={index}>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.app_type}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.source_data_type}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.primary_anchor?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item[item.primary_anchor]}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.document_type}</TableCell>
                                                        {/* <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.tier_projection}</TableCell> */}
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.reference_number?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.assignment_number?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                        {/* <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.accrual_aggregation}</TableCell> */}
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.reversal_document_type}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.clearing_account}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.clearing_profit_center}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.profit_center_source}</TableCell>
                                                        <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.profit_center}</TableCell>
                                                    </StyledTableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    );
                                })
                                :
                                <Typography variant='h4'>
                                    No Data
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.container2} style={{ display: userStore && userStore[0] && (userStore[0]['copa_attribute01'] || userStore[0]['copa_attribute02'] || userStore[0]['copa_attribute03'] || userStore[0]['copa_attribute04'] || userStore[0]['copa_attribute05'] || userStore[0]['copa_attribute06']) ? 'block' : 'none' }} >
                    <CardHeader
                        title="COPA Attributes"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <Grid container style={{ marginLeft: '2rem' }} spacing={2}>
                        {userStore && userStore[0] && userStore[0]['copa_attribute01'] ?
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <LabelText heading='COPA Attribute 1' data={userStore[0]['copa_attribute01']?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} twoline='true' />

                            </Grid> : ''}
                        {userStore && userStore[0] && userStore[0]['copa_attribute02'] ?
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <LabelText heading='COPA Attribute 2' data={userStore[0]['copa_attribute02']?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} twoline='true' />
                            </Grid> : ''}
                        {userStore && userStore[0] && userStore[0]['copa_attribute03'] ?
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <LabelText heading='COPA Attribute 3' data={userStore[0]['copa_attribute03']?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} twoline='true' />

                            </Grid> : ''}
                        {userStore && userStore[0] && userStore[0]['copa_attribute04'] ?
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <LabelText heading='COPA Attribute 4' data={userStore[0]['copa_attribute04']?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} twoline='true' />
                            </Grid> : ''}


                        {userStore && userStore[0] && userStore[0]['copa_attribute05'] ?
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <LabelText heading='COPA Attribute 5' data={userStore[0]['copa_attribute05']?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} twoline='true' />

                            </Grid> : ''}
                        {userStore && userStore[0] && userStore[0]['copa_attribute06'] ?
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <LabelText heading='COPA Attribute 6' data={userStore[0]['copa_attribute06']?.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })} twoline='true' />
                            </Grid> : ''}

                    </Grid>
                </div>
                <div className={classes.container2} >
                    <CardHeader
                        title="P&L Account and Profit Center determination"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {pLAccountStore && pLAccountStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell classes={{ root: classes.leftAlign }}>Source Attribute</TableCell>
                                                <TableCell align='center'>Options</TableCell>
                                                <TableCell align='center'>Attribute Value</TableCell>
                                                <TableCell align='center'>GL Account</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {pLAccountStore
                                                .map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={item}>
                                                            <TableCell align='center'>{keyValuePairObjectNew[item.source_attribute]}</TableCell>
                                                            <TableCell align='center'>{item.option}</TableCell>
                                                            <TableCell align='center'>{item.attribute_value}</TableCell>
                                                            <TableCell align='center'>{item.gl_account}</TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4' style={{ marginTop: '2rem' }}>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {

        onLoadingDefault: () => dispatch(getDefaultValues()),
        getAllApprovalDesigner: (pagination, limit) => dispatch(getAllApprovalDesigner(pagination, limit)),
        getAccrualDesignerSingleItem: (id) => dispatch(getAccrualDesignerSingleItem(id)),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        items: state.customerData.accrualDesignerSingle,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAccrualDesigner);