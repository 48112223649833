import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton,
    OutlinedInput,
    FormLabel,
    Checkbox,
    MenuItem,
    Select
} from '@mui/material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
import { allowedApps, loadValuesBasedOnAppTypeAndField, getDefaultValues, addBasisMappingDefaultDesigner, getDefaultValuesAllData, getSingleBasisMapping, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { ApplicationTypes } from '../../library/constants'
import { BasisArray } from './BasisList'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        height: 40,

    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        height: 28,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    paper: { minWidth: window.screen.width * .50 },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll"
    }
}));

const AddAttribute = props => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [editID, setEditID] = React.useState(0);
    const type = []
    const dateType = []
    useEffect(() => {
        setOpen(true);
        props.onLoadingDefaultAll();
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getSingleBasisMapping(appId)
    }, []);

    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])


    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && props.dropdownData.field_label_attributes.contract_type) {
            const contractTypeValues = props.dropdownData.field_label_attributes.contract_type.drop_down_values || [];
            setContractTypeArray(contractTypeValues);
        }
    }, [props.dropdownData]);

    useEffect(() => {
        if (props.basisMapData) {
            setApplicationType(props.basisMapData.application_type)
            setSourceDataType(props.basisMapData.source_data_type)
            setContractType(props.basisMapData.contract_type)
            setTransactionType(props.basisMapData.transaction_type)
            setEvaluationDateType(props.basisMapData.evaluation_date_type)
            props.loadSourceDataType(props.basisMapData.application_type, 'source_data_type');
            props.getDefaultValuesAllData('attribute_name', props.basisMapData.source_data_type, props.basisMapData.application_type);
            var data = {
                "data_source": "contract_header",
                "application_type": props.basisMapData.application_type
            }
            props.fieldDesigner(data, 'post');
        }
    }, [props.basisMapData]);

    const [applicationType, setApplicationType] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [contractType, setContractType] = React.useState('');
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [transactionType, setTransactionType] = React.useState('');
    const [evaluationDateType, setEvaluationDateType] = React.useState('');


    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);





    function clearAll() {
        setSourceDataType('');
    }


    function handleOnSubmit() {
        var data = {
            "application_type": applicationType,
            "source_data_type": sourceDataType,
            "contract_type": contractType,
            "transaction_type": transactionType,
            "evaluation_date_type": evaluationDateType


        };
        props.onSubmit(data, editID);

    }

    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    const handleTransactionType = (value) => {
        setEvaluationDateType('')
        setTransactionType(value)
    }

    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        setTransactionType('')
        setEvaluationDateType('')
        props.getDefaultValuesAllData('attribute_name', e.target.value, applicationType);
        props.onLoadingDefaultAttributeName(applicationType, e.target.value);
    }
    const handleApplicationType = (e) => {
        setApplicationType(e.target.value);
        setSourceDataType('')
        setContractType('')
        setSourceDataTypeDataFromAPI([]);
        props.onLoadingDefaultAll();
        props.loadSourceDataType(e.target.value, 'source_data_type');
        //props.onLoadingDefaultAttributeName(e.target.value, sourceDataType);
        //  props.getDefaultValuesAllData('attribute_name', sourceDataType, e.target.value);
        var data = {
            "data_source": "contract_header",
            "application_type": e.target.value
        }
        props.fieldDesigner(data, 'post');

    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            }
        }
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/basis-mapping'
                >
                    Basis Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit  Basis Mapping</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Application Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={applicationType}
                                                onChange={handleApplicationType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                                {allowedApps.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Source Data Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={sourceDataType}
                                                onChange={handleSourceDataType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >

                                                {sourceDataTypeDataFromAPI && sourceDataTypeDataFromAPI
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Contract Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={contractType}
                                                onChange={(e) => setContractType(e.target.value)}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                                {contractTypeArray && contractTypeArray.length > 0 &&
                                                    contractTypeArray
                                                        .map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={index}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Transaction Type
                                        </FormLabel>
                                        <Select
                                            value={transactionType}
                                            onChange={(e) => handleTransactionType(e.target.value)}
                                            style={{ textTransform: 'capitalize' }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {BasisArray && Array.from(new Set(BasisArray
                                                .filter((item) => item.sourceDataType === sourceDataType) // Filter by sourceDataType
                                                .map(item => item.transactionType)))
                                                .filter((type, index, self) => self.indexOf(type) === index) // Filter out duplicates
                                                .map((type) => {
                                                    const isVisible = BasisArray.some(item => item.transactionType === type && (item.transactionType !== 'CLAIM' || (applicationType === 'Customer Rebate' || applicationType === 'Sales Commission')));
                                                    return (
                                                        <MenuItem value={type} key={type} style={{ display: isVisible ? 'block' : 'none', textTransform: 'capitalize' }}>
                                                            {type}
                                                        </MenuItem>
                                                    )
                                                })}

                                            {/* {BasisArray && BasisArray
                                                .filter((item) => item.sourceDataType === sourceDataType)
                                                .map((item) => {
                                                    var check = false
                                                    if (type.indexOf(item.transactionType) == -1) {
                                                        type.push(item.transactionType)
                                                        check = true
                                                    }

                                                    return (
                                                        <MenuItem value={item.transactionType} key={item.transactionType}
                                                            style={{ display: !check ? 'none' : (item.transactionType == 'CLAIM' ? (applicationType == 'Customer Rebate' || applicationType == 'Sales Commission') ? 'block' : 'none' : 'block'), textTransform: 'capitalize' }}
                                                        >
                                                            {item.transactionType}
                                                        </MenuItem>
                                                    )
                                                })} */}
                                        </Select>
                                    </div>
                                </Grid>

                                <Grid item md={3}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv} >
                                            Evaluation Date Type
                                        </FormLabel>
                                        <Select
                                            value={evaluationDateType}
                                            style={{ maxHeight: "50px", textTransform: 'capitalize' }}
                                            onChange={(e) => setEvaluationDateType(e.target.value)}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >

                                            {BasisArray && BasisArray
                                                .filter((item) => item.transactionType === transactionType)
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item.evalutionTypeKey} key={item.evalutionTypeKey} style={{ textTransform: 'capitalize' }}>
                                                            {item.evalutionType}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>


                                    </div>
                                </Grid>


                            </Grid>

                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={sourceDataType && applicationType && contractType && transactionType && evaluationDateType ? false : true
                        }>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(addBasisMappingDefaultDesigner(data, 'edit', id)),
        getSingleBasisMapping: (id) => dispatch(getSingleBasisMapping(id)),
        onLoadingDefaultAll: () => dispatch(getDefaultValues('noKeyWords')),
        onLoadingDefaultAttributeName: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
        allowedApps: () => dispatch(allowedApps()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        attributeNameData: state.addMultipleConfigurationData.attributeNameData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        dropdownDataAll: state.addMultipleConfigurationData.multipleConfigurationData,
        basisMapData: state.BasisMapData.BasisMapDataSingle,
        allowedAppsData: state.initialData.allowedApps,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttribute);