import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    TablePagination,
    Breadcrumbs,
    Link,
    Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { TableArray} from './FormulaFields';
import { getQueryList, runDynamicQuery, getDefaultValuesAllDataNew, getFormulasList, getFormulaDetails,submitFormula,getPriceFormulaById } from '../../../redux/actions';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main
    }
}));
const FormulaLabMain = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [userStore, setUserStore] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getFormulaList(appId);
    }, []);
    useEffect(() => {
        if (props.formulaList) {
           setUserStore(props.formulaList)
        }
    }, [props.formulaList]);
    function handleFormula(item){
        var temp=[]
        var label=[]
        if (item.formula && item.formula_type) {
            item.formula_type
                .map((i, index) => {
                    if (i == "op" || i == "const")
                        temp.push(item.formula[index])
                    else {
                         label = TableArray.filter(value => value.key == i)
                        var field = label&&label[0]?label[0].name&&label[0].name.replace(/_/g, ' ') + "[" + item.formula[index].replace(/_/g, ' ') + "]":''
                        temp.push(field)
                    }
                })
                return temp;
        }
    }
    
    return (
        <div className={clsx(classes.root, className)}>
            
                 <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4'style={{ cursor: 'pointer'}} classes={{
                        root: classes.link
                    }}
                        onClick={() => history.push('/pricing-formula')}
                    >
                       Formula - Price Points
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp;View Formula</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {userStore ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                            <TableCell align='center' className={classes.tabHead}>Formula ID</TableCell>
                                           <TableCell align='center' className={classes.tabHead}>Formula Name</TableCell>
                                           <TableCell align='center' className={classes.tabHead}>Formula Expression</TableCell>
                
                                        </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                           <StyledTableRow>
                                                <TableCell align='center' style={{  textTransform: 'capitalize' }}>{userStore.ID}</TableCell>
                                                <TableCell align='center'  style={{  textTransform: 'capitalize' }}>{userStore.formula_name}</TableCell>
                                                <TableCell align='center'  style={{  textTransform: 'capitalize' }}>{userStore.formula && handleFormula(userStore)}</TableCell>
                                                </StyledTableRow>
                                          
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    No Data
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                </div>
            </div >
        </div >
    );
};

const mapStateToProps = state => {
    return {
        formulaList: state.pricingData.priceFormulaSingle,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFormulaList: (id) => dispatch(getPriceFormulaById(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormulaLabMain);