import React, { useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery,
    TableRow,
    Breadcrumbs, Fab
} from '@mui/material';
import { CloudUpload, DownloadForOfflineRounded } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { kafkaUpload } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const KafkaUpload = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    // const handleDrop = (event) => {
    //     const data = new FormData();
    //     data.append('file', event.target.files[0]);
    //     props.uploadSalesData(data);
    //     event.target.value = '';
    // }
    // function runQuery(item) {
    //     inputFile.current.click();
    // }
    const uploadPage = () => {
        history.push("/migration-management-upload");
    };
    const downloadPage = () => {
        history.push("/migration-management-download");
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="primary" variant='h1'>Migration Management</Typography>
                </Breadcrumbs>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    {/* <input type='file'
                        accept=".xlsx, .xls, .csv"
                        id='file' ref={inputFile} style={{ display: 'none' }}
                        onChange={(e) => handleDrop(e)} /> */}
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={uploadPage}
                    >
                        <CloudUpload color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                    </Fab>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        className={classes.fabContainer}
                        onClick={downloadPage}
                    >
                        <DownloadForOfflineRounded color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Download</Typography>
                    </Fab>
                </div>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        uploadSalesData: (file, currentEditableID) => dispatch(kafkaUpload(file, currentEditableID)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(KafkaUpload);