import React, { useEffect } from 'react';
import {
    Typography, Breadcrumbs, Stepper, Step, StepButton, StepConnector, StepLabel,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    GeneralData, TargetRules, FinancialPostings, Miscellaneous, //EligibilityRules,
    CalculationRules, ApprovalStatus, InternalNotes, ContractDependency, MultiplePayment, CalculationModifier
} from './components';
import { EligibilityRules } from '../ContractSetup/Containers';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import { getContractDetailsData, getPriceFormula, getFormulasList, getDefaultValuesAllDataNew, getDefaultValues, getLblDispDesFieldValue, getDefaultValuesKeyFigure } from '../../redux/actions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: -20,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto',
        marginTop: 10
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: 'white'
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));


const ViewContract = props => {
    const mode = props.mode ? props.mode : ''
    console.log(mode)
    const currentApplication = sessionStorage.getItem('application')
    const [calculationSchema, setCalculationSchema] = React.useState('');
    const [targetTabEnabled, setTargetTabEnabled] = React.useState(false);
    const [eligibilityEnabled, setEligibilityEnabled] = React.useState(true);
    const [calcRuleEnabled, setCalcRuleEnabled] = React.useState(true);
    const [incentiveRateType, setIncentiveRateType] = React.useState('');
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [contractData, setContractData] = React.useState({});
    const [contractDependencyEnabled, setContractDependencyEnabled] = React.useState(false);
    const [contractFormFields, setContractFormFields] = React.useState({});
    const [calcModifierEnabled, setCalcModifierEnabled] = React.useState(false);
    const [multiPaymentRuleEnabled, setMultiPaymentRuleEnabled] = React.useState(false);
    useEffect(() => {
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
    }, [])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setContractFormFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    useEffect(() => {
        if (props.contractData) {
            setContractData(props.contractData)
            setCalculationSchema(props.contractData.calculation_schema)
            setCalculationMethod(props.contractData.calculation_method)
            setIncentiveRateType(props.contractData.incentive_rate_type)
            if (props.contractData.calculation_method === 'Pricing Calculation') {
                props.getPricingCalculationFormula();
            }

            if (props.contractData && props.contractData.commitment_achievement && props.contractData.commitment_achievement != 'No') {
                setTargetTabEnabled(true);
            } else {
                setTargetTabEnabled(false);
            }
            if (props.contractData && props.contractData.calculation_method && props.contractData.incentive_rate_type && (props.contractData.calculation_method === 'Flat Rate' || props.contractData.calculation_method === 'Fixed Amount') && props.contractData.incentive_rate_type === 'Attribute') {
                setEligibilityEnabled(false)
            } else {
                setEligibilityEnabled(true)
            }
            if (props.contractData.contract_dependency == 'Yes') {
                setContractDependencyEnabled(true);
            } else {
                setContractDependencyEnabled(false);
            }
            if (props.contractData['payment_level'] == 'Flexible Payment' //&& props.contractData['payment_partner_role'] != ''

            ) {
                setMultiPaymentRuleEnabled(true);

            } else {
                setMultiPaymentRuleEnabled(false);
            }
            if (props.contractData.calculation_rule_modifier == 'Yes') {
                setCalcModifierEnabled(true);

            } else {
                setCalcModifierEnabled(false);
            }
            if (props.contractData.calculation_method === 'External Calculation')
                setCalcRuleEnabled(false)
            props.onLoadingDefault(props.contractData.application_type, props.contractData.source_data_type)
            props.onLoadingDefaultCR(props.contractData.application_type, props.contractData.source_data_type)
        }
    }, [props.contractData])

    useEffect(() => {
        props.getcontractdetails(mode != 'massUpdate' ? props.match.params.id : props.id);
        props.getFormulasList();

    }, []);

    function getSteps() {
        if (currentApplication === 'Pricing') {
            return ['Primary*', ...(targetTabEnabled ? ['Commitment Rules*'] : []), ...(contractDependencyEnabled ? ['Contract Dependency*'] : []),
                ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), ...(calcModifierEnabled ? ['Calculation Rule Modifiers*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        } else {
            return ['Primary*', 'Financial Postings*', ...(multiPaymentRuleEnabled ? ['Flexible Payments*'] : []), ...(targetTabEnabled ? ['Commitment Rules*'] : []),
                ...(contractDependencyEnabled ? ['Contract Dependency*'] : []),
                ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), ...(calcModifierEnabled ? ['Calculation Rule Modifiers*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        }

    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const skipped = new Set()
    const steps = getSteps();
    const navParams = useLocation();
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        var allSteps;
        if (contractData && contractData.commitment_achievement && contractData.commitment_achievement != 'No') {
            if (eligibilityEnabled) {
                allSteps = [
                    <GeneralData id={mode != 'massUpdate' ? props.match.params.id : props.id} contractData={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings contractData={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    <TargetRules data={contractData} />,
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} />] : []),
                    <EligibilityRules calculationMethod={calculationMethod} incentiveRateType={incentiveRateType} data={contractData} mode='view' />,
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={calculationMethod} calculationSchema={calculationSchema} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes contractData={contractData} />,
                    <ApprovalStatus approvalData={contractData} />
                ];
            } else {
                allSteps = [
                    <GeneralData id={mode != 'massUpdate' ? props.match.params.id : props.id} contractData={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings contractData={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    <TargetRules data={contractData} />,
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={calculationMethod} calculationSchema={calculationSchema} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes contractData={contractData} />,
                    <ApprovalStatus approvalData={contractData} />
                ];


            }
        } else {
            if (eligibilityEnabled) {
                allSteps = [
                    <GeneralData id={mode != 'massUpdate' ? props.match.params.id : props.id} contractData={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings contractData={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} />] : []),
                    <EligibilityRules calculationMethod={calculationMethod} incentiveRateType={incentiveRateType} data={contractData} mode='view' />,
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={calculationMethod} calculationSchema={calculationSchema} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes contractData={contractData} />,
                    <ApprovalStatus approvalData={contractData} />
                ];

            } else {
                allSteps = [
                    <GeneralData id={mode != 'massUpdate' ? props.match.params.id : props.id} contractData={contractData} fieldData={contractFormFields} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings contractData={contractData} fieldData={contractFormFields} />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment data={contractData} fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ? [
                        <ContractDependency contractId={contractData.contract_number} fieldData={contractFormFields} />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules calculationMethod={calculationMethod} calculationSchema={calculationSchema} />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier data={contractData}
                    />] : []),
                    <Miscellaneous miscellaneousData={contractData} fieldData={contractFormFields} />,
                    <InternalNotes contractData={contractData} />,
                    <ApprovalStatus approvalData={contractData} />
                ];
            }
        }
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    return (
        <div>
            <div className={classes.root}>
                <LoadingOverlay
                    active={props.loading}
                    spinner={<HashLoader />}
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: "50px",
                            "& svg circle": {
                                stroke: "#045FB4",
                            },
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: "rgba(52, 52, 52, 0)",
                        }),
                        content: (base) => ({
                            ...base,
                            color: "black",
                        }),
                    }}
                    className={classes.spinner}
                >
                    <div className={classes.stepper}>
                        <Stepper alternativeLabel nonLinear activeStep={activeStep}
                            classes={{ root: classes.stepperContainer }}
                            connector={
                                <StepConnector
                                    classes={{
                                        line: classes.connectorLine,
                                        alternativeLabel: classes.connectorAlternativeLabel
                                    }}
                                />
                            }
                        >
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const buttonProps = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}
                                        classes={{ horizontal: classes.horizontal }}
                                    >
                                        <StepButton
                                            onClick={handleStep(index)}
                                            completed={isStepComplete(index)}
                                            {...buttonProps}
                                            classes={{ root: classes.stepButton }}
                                        >
                                            <StepLabel>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                    <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                </LoadingOverlay>
            </div>
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        getFormulasList: () => dispatch(getFormulasList(1, 1000)),
        getPricingCalculationFormula: (page, limit) => dispatch(getPriceFormula(page, limit)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),

        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        eligibilityRulesData: state.addContractData.eligibilityRulesData,
        calculationRulesData: state.addContractData.calculationRulesData,
        contractData: state.addContractData.contractGeneralData,
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
        loading: state.addContractData.loading,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewContract);