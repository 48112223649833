import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { ExceptionManagementAuditMain } from "../../views/ExceptionManagementAudit/index";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { getEnv } from "../../env";
import {
  Dashboard as DashboardView,
  ContractSetup as ContractSetupView,
  ContractSetupList as ContractSetupListView,
  ApprovalReject as ApprovalRejectView,
  ExceptionApprovalReject as ExceptionApprovalRejectView,
  Login as LoginView,
  ForgotPassword as ForgotPasswordView,
  AppLauncher as AppLauncherView,
  ApplauncherNew as AppLauncherNewView,
  AddContract as AddContractView,
  EditContract as EditContractView,
  SubmitForApprovalEdit as EditContractSubmitView,
  ViewContract as ViewContractView,
  ChangeRequestDetail as ChangeRequestDetailView,
  ChangeRequestCompare as ChangeRequestCompareView,
  ChangeRequestCompareAPI as ChangeRequestCompareAPIView,
  CompareContract as CompareContractView,
  CalculationSimulationForm as CalculationSimulationView,
  OperationalReports as OperationalReportsView,
  FinancialPostingsForm as FinancialPostingsView,
  ExternalAccuralPostingForm as ExternalAccuralPostingView,
  CompareRebateCalculationForm as CompareRebateCalculationView,
  OutboundPostingsIntegrations as OutboundPostingsIntegrationsView,
  PostingsMaintenanceForm as PostingsMaintenanceFormView,
  PostingsMaintenanceFormResult as PostingsMaintenanceFormResultView,

  DailySimulationForm as DailySimulationFormView,
  ExternalPaymentPostingForm as ExternalPaymentPostingView,
  ExternalAccuralPostingResult as ExternalAccuralPostingResultView,
  ExternalPaymentPostingResult as ExternalPaymentPostingResultView,
  CompareRebateCalculationResult as CompareRebateCalculationResultView,
  PaymentPostingsForm as PaymentPostingsView,
  SupplierPaymentPostingForm as SupplierPaymentPostingsView,
  Configuration as ConfigurationView,
  AccessNew as AccessView,
  OrganisationAccess as OrganisationAccessView,
  UserAccess as UserAccessView,
  ApprovalGroup as ApprovalGroupView,
  CollaborationID as CollaborationIDView,
  OrgDefaults as OrgDefaultsView,
  UpcomingFeature as UpcomingFeature,
  CalculationSimulationResult as CalculationResultsView,
  DataSetupTransaction as DataSetupTransactionView,
  SalesInvoiceQueryList as SalesInvoiceQueryListView,
  UploadExternalCalculationsMain as UploadExternalCalculationsMainView,
  UploadExternalCalculationsAdd as UploadExternalCalculationsAddView,
  UploadExternalCalculationsEdit as UploadExternalCalculationsEditView,
  UploadExternalCalculationsView as UploadExternalCalculationsViewView,
  UploadExternalPostingMain as UploadExternalPostingMainView,
  UploadExternalPostingAdd as UploadExternalPostingAddView,
  UploadExternalPostingEdit as UploadExternalPostingEditView,
  UploadExternalPostingView as UploadExternalPostingViewView,
  ViewSalesInvoiceQuery as ViewSalesInvoiceQueryView,
  ViewIndirectSalesInvoiceQuery as ViewIndirectSalesInvoiceQueryView,
  ViewTargetInvoiceQuery as ViewTargetInvoiceQueryView,
  IndirectSalesInvoiceQueryList as IndirectSalesInvoiceQueryListView,
  IndirectSalesInvoiceAddQuery as IndirectSalesInvoiceAddQueryView,
  BuildIndirectSalesQuery as BuildIndirectSalesQueryView,
  DeleteIndirectSalesQuery as DeleteIndirectSalesQueryView,
  SalesInvoiceEditQuery as SalesInvoiceEditQueryView,
  IndirectSalesInvoiceEditQuery as IndirectSalesInvoiceEditQueryView,
  BuildSalesInvoiceQuery as BuildSalesInvoiceQueryView,
  DeleteSalesInvoiceQuery as DeleteSalesInvoiceQueryView,
  DisplaySalesInvoiceQuery as DisplaySalesInvoiceQueryView,
  DisplaySalesInvoiceQuerySF as DisplaySalesInvoiceQuerySFView,
  displaySalesDataFilters as DisplaySalesDataFilterView,
  DisplayIndirectSalesFilter as DisplayIndirectSalesFilterView,
  DisplayPurchaseInvoice as DisplayPurchaseInvoiceView,
  DisplayIndirectSalesInvoiceQuerySF as DisplayIndirectSalesInvoiceQuerySFView,
  DisplayIndirectSalesInvoiceQuery as DisplayIndirectSalesInvoiceQueryView,
  DisplayPurchaseSalesInvoiceQuery as DisplayPurchaseSalesInvoiceQueryView,
  DisplayPurchaseSalesInvoiceQuerySF as DisplayPurchaseSalesInvoiceQuerySFView,
  // DisplayIndirectSalesDataFilter as DisplayIndirectSalesDataFilterView,
  TargetInvoiceEditQuery as TargetInvoiceEditQueryView,
  SalesInvoiceAddQuery as SalesInvoiceAddQueryView,
  MaterialMasterAddQuery as MaterialMasterAddQueryView,
  MaterialMasterViewQuery as MaterialMasterViewQueryView,
  CustomerMasterViewQuery as CustomerMasterViewQueryView,
  CustomerMasterSearch as CustomerMasterSearchView,
  DeleteMasterdata as DeleteMasterdataView,
  DeleteExternalFormulaResult as DeleteExternalFormulaResultView,
  CustomerMasteXREFViewQuery as CustomerXREFMasterViewQueryView,
  MaterialMasterEditQuery as MaterialMasterEditQueryView,
  CustomerMasterEditQuery as CustomerMasterEditQueryView,
  CustomerMasterXREFEditQuery as CustomerMasterXREFEditQueryView,
  CustomerMasterAddQuery as CustomerMasterAddQueryView,
  CustomerMasterXREFAddQuery as CustomerMasterXREFAddQueryView,
  TargetInvoiceAddQuery as TargetInvoiceAddQueryView,
  TargetInvoiceQueryList as TargetInvoiceQueryListView,
  TargetInvoice as TargetInvoiceListView,
  TargetInvoiceSearch as TargetInvoiceSearchView,
  AddTargetInvoice as AddTargetInvoiceView,
  EditTargetInvoice as EditTargetInvoiceView,
  ChannelPartnerSales as ChannelPartnerSalesView,
  POS as POSView,
  PurchaseInvoiceQueryList as PurchaseInvoiceView,
  PurchaseInvoiceAddQuery as PurchaseInvoiceAddQueryView,
  PurchaseInvoiceEditQuery as PurchaseInvoiceEditQueryView,
  ViewPurchaseInvoiceQuery as ViewPurchaseInvoiceQueryView,
  BuildPurchaseInvoice as BuildPurchaseInvoiceView,
  DeletePurchaseInvoice as DeletePurchaseInvoiceView,
  IncomingClaim as IncomingClaimView,
  FinancialPostingsSimulationResult as FinancialResultsView,
  CalculationSummaryReports as CalculationSummaryReportsView,
  DeleteCalculationSimulationData as DeleteCalculationSimulationDataView,
  CalculationSummaryReportsTable as CalculationSummaryReportsTableView,
  DailySimulationsResult as DailySimulationsResultView,
  DailySimulationsReport as DailySimulationsReportView,
  DailySimulations as DailySimulationsView,
  ProfileView,
  //ContractCustomisation as ContractCustomisationView,
  // FieldDisplayConfiguration as FieldDisplayConfigurationView,
  FieldLabelConfiguration as FieldLabelConfigurationView,
  ViewCustomer as ViewCustomerMasterView,
  CustomerMaster as CustomerMasterView,
  CustomerMasterExcelUpload as CustomerMasterExcelUploadView,
  CustomerMasterXREFExcelUpload as CustomerMasterXREFExcelUploadView,
  CustomerXMaster as CustomerXMasterView,
  MaterialMaster as MaterialMasterView,
  MaterialMasterSearch as MaterialMasterSearchView,
  AddCustomer as AddCustomerView,
  AddMaterial as AddMaterialView,
  AddCustomerXRef as AddCustomerXRefView,
  EditCustomerXRefMaster as EditCustomerXRefMasterView,
  ViewCustomerXRefMaster as ViewCustomerXRefMasterView,
  //Attributes
  Attributes as AttributesView,
  AddAttribute as AddAttributeView,
  AttributesHierarchy as AttributesHierarchyView,
  AddAttributesHierarchy as AddAttributesHierarchyView,
  ViewAttributesHierarchy as ViewAttributesHierarchyView,
  EditAttributesHierarchy as EditAttributesHierarchyView,
  //reports tables
  FinancialReportsTable as financialReportsResultView,
  TargetReportsTable as targetReportsResultView,
  CalculationReportsTable as calculationSimulationReportsResultView,
  InvoiceReportsTable as invoiceReportsResultView,
  ContractReportsTable as contractReportsResultView,
  ContractOverlapReportsTable as contractOverlapReportsResultView,
  ContractChangeReportsTable as contractChangeReportsResultView,
  EditCustomer as EditCustomerView,
  EditMaterial as EditMaterialView,
  ViewMaterial as ViewMaterialMasterView,
  MaterialXMaster as MaterialXMasterView,
  MaterialMasterExcelUpload as MaterialMasterExcelUploadView,
  AddMaterialMasterXRef as AddMaterialMasterXRefView,
  EditMaterialMasterXRef as EditMaterialMasterXRefView,
  ViewMaterialMasterXRef as ViewMaterialMasterXRefView,

  //supplier rmaster
  SupplierMaster as SupplierMasterView,
  AddSupplier as AddSupplierView,
  ViewSupplier as ViewSupplierMasterView,
  EditSupplier as EditSupplierView,
  SupplierMasterSearch as SupplierMasterSearchView,
  Membership as MembershipView,
  MembershipSecond as MembershipSecondView,
  AddMembership as AddMembershipView,
  EditMembership as EditMembershipView,
  ViewMembership as ViewMembershipView,
  MembershipHierarchy as MembershipHierarchyView,
  MembershipTreeData as MembershipTreeDataView,
  MembershipDisplayByMemeber as MembershipDisplayByMemeberView,
  ViewMembershipByFilter as ViewMembershipByFilterView,
  MasterReportSearch as MasterReportSearchView,
  MasterReportResult as MasterReportResultView,
  MembershipContractXREF as MembershipContractXREFView,
  AddmembershipContractXREF as AddMembershipContractXREFView,
  EditMembershipContractXREF as EditMembershipContractXREF,
  MembershipContractGroup as MembershipContractGroupView,
  AddmembershipContractGroup as AddMembershipContractGroupView,
  EditMembershipContractGroup as EditMembershipContractGroupView,
  MembershipContractGroupExcelUpload as MembershipContractGroupExcelUploadView,
  MembershipContractGroupViewQuery as MembershipContractGroupViewQueryView,
  MembershipContractGroupEditQuery as MembershipContractGroupEditQueryView,
  MembershipContractGroupAddQuery as MembershipContractGroupAddQueryView,
  MembershipMassUpdateForm as MembershipMassUpdateFormView,
  MembershipMassUpdateResult as MembershipMassUpdateResultView,
  GPORosterAddressSearch as GPORosterAddressSearchView,
  GPORosterAddressSearchResult as GPORosterAddressSearchResultView,
  TierActivationAddressSearch as TierActivationAddressSearchView,
  TierActivationAddressSearchResult as TierActivationAddressSearchResultView,
  AddBom as AddBomView,
  BomTreeData as BomTreeView,
  BomHierarchy as BomHierarchyView,
  EditBom as EditBomView,
  ViewBom as ViewBomView,
  CalculationDisplayDesigner as CalculationDisplayDesignerView,
  CalculationLabelDesigner as CalculationLabelDesignerView,
  FinancialDisplayDesigner as FinancialDisplayDesignerView,
  FinancialLabelDesigner as FinancialLabelDesignerView,
  PaymentDesigner as PaymentDesignerView,
  AddPaymentDesigner as AddPaymentDesignerView,
  EditPaymentDesigner as EditPaymentDesignerView,
  ViewPaymentDesigner as ViewPaymentDesignerView,
  AccrualDesigner as AccrualDesignerView,
  AddAccrualDesigner as AddAccrualDesignerView,
  EditAccrualDesigner as EditAccrualDesignerView,
  ViewAccrualDesigner as ViewAccrualDesignerView,
  HolidayCalendar as HolidayCalendarView,
  FinancialClose as FinancialCloseView,
  Bom as BomView,
  //CommonDisplayDesigner as CommonDisplayDesignerView,
  //employee master
  EmployeeMaster as EmployeeMasterView,
  AddEmployee as AddEmployeeMasterView,
  ViewEmployee as ViewEmployeeView,
  EditEmployee as EditEmployeeView,
  // Integration  studio
  IntegrationSetup as IntegrationSetupView,
  IntegrationManager as IntegrationManagerView,
  AddIntegrationSetup as AddIntegrationSetupView,
  EditIntegrationSetup as EditIntegrationSetupView,
  IMHistory as IMHistoryView,
  IntegrationFilter as IntegrationFilterView,
  AddIntegrationFilter as AddIntegrationFilterView,
  IntegrationBatchJobSetup as IntegrationBatchJobSetupView,
  IntegrationAddBatchJobSetup as AddIntegrationBatchJobSetupView,
  IntegrationEditBatchJobSetup as EditIntegrationBatchJobSetupView,
  IntegrationViewBatchJobSetup as ViewIntegrationBatchJobSetupView,
  Tree as TreeView,
  TreeTable as TreeTableView,
  ViewTerritory as TerritoryTreeView,
  EditTerritory as EditTerritoryView,
  SupplierIncomingClaims as SupplierIncomingClaimsView,
  incomingClaimsAddQuery as SupplierIncomingAddClaimsView,
  incomingClaimsQueryList as SupplierIncomingClaimsUploadView,
  MultipleClaimsForm as MultipleClaimsFormView,
  MultipleClaimsResult as MultipleClaimsResultView,
  CustomerOutgoingClaims as CustomerOutgoingClaimsView,
  CustomerOutgoingClaimsUpload as CustomerOutgoingClaimsUploadView,
  CustomerIncomingClaimsUpload as CustomerIncomingClaimsUploadView,
  EditIncomingClaims as EditIncomingClaimsView,
  incomingClaimsEditQuery as EditIncomingClaimsQueryView,
  ViewIncomingClaimsQuery as ViewIncomingClaimsView,
  EditOutgoingClaims as EditOutgoingClaimsView,
  CustomerOutgoingClaimsPost as CustomerOutgoingClaimsPostView,
  ViewOutgoingClaims as ViewOutgoingClaimsView,
  DisputedClaimForm as DisputedClaimFormView,
  DisputedClaimResult as DisputedClaimResultView,
  ClaimSubmissions as ClaimSubmissionsView,
  ClaimSubmissionsResult as ClaimSubmissionsResultView,
  PaymentPostingForm as OutgoingPaymentPostingFormView,
  AccrualPosting as OutgoingAccrualPostingFormView,
  //reports
  ContractReports as ContractReportsView,
  TargetDataReports as TargetReportsView,
  RatioKeyFiguresReposts as RatioKeyFiguresReportsView,
  RatioKeyFiguresTable as RationKeyFiguresReportsResultView,
  CalculateFormulaValuesReports as CalculateFormulaValuesReportsView,
  CalculateFormulaValuesTable as CalculateFormulaValuesReportsResultView,
  CalculateFormulaValuesByContractReports as CalculateFormulaValuesByContractReportsView,
  CalculateFormulaValuesByContractTable as CalculateFormulaValuesByContractReportsResultView,
  TopNAttributesReports as TopNAttributesReportsView,
  TopNAttributesTable as TopNAttributesReportsResultView,
  IncentiveVarianceAnalysisReports as IncentiveVarianceAnalysisReportsView,
  IncentiveVarianceAnalysisTable as IncentiveVarianceAnalysisReportsResultView,
  DuplicatePaymentReports as DuplicatePaymentReportsView,
  DuplicatePaymentTable as DuplicatePaymentReportsResultView,
  AnamolyReports as AnamolyReportsView,
  AnamolyTable as AnamolyReportsResultView,
  ApprovalPostingHistoryReports as ApprovalPostingHistoryReportsView,
  ApprovalPostingHistoryTable as ApprovalPostingHistoryReportsResultView,
  CalculationSimulationReports as CalculationSimulationReportsView,
  FinancialPostingReports as FinancialPostingsReportsView,
  InvoiceDetailReports as InvoiceDetailsReportsView,
  ContractOverlapReports as ContractOverlapReportsView,
  ContractChangeReports as ContractChangeReportsView,
  FinancialPostingsApprovalsAccrual as FinancialPostingsApprovalsAccrualView,
  FinancialPostingsApprovalsPayment as FinancialPostingsApprovalsPaymentView,
  SelectOperationalReports as SelectOperationalReportsView,
  SelectOperationalTargetReports as SelectOperationalTargetReportsView,
  ContractNumberVarianceReports as ContractNumberVarianceReportsView,

  //sales forecast format
  SalesForecastAddFormat as SalesForecastFormatAddView,
  SalesForecastEditFormat as SalesForecastFormatEditView,
  SalesForecastViewFormat as SalesForecastFormatSingleView,
  Syncfusion as SyncfusionView,

  //dynamic query
  DynamicQuery as DynamicQueryView,
  AddDynamicQuery as AddDynamicQueryView,
  EditDynamicQuery as EditDynamicQueryView,
  ViewDynamicQuery as ViewDynamicQueryView,
  DynamicQueryResults as DynamicQueryResultsView,
  DynamicQueryResultsSF as DynamicQueryResultsSFView,
  DynamicQueryForm as DynamicQueryFormView,

  //ondemand analytics
  DynamicAnalytics as DynamicAnalyticsView,
  AddDynamicAnalytics as AddOnDemandAnalyticsView,
  EditDynamicAnalytics as EditOnDemandAnalyticsView,
  OnDemandAnalyticsForm as OnDemandAnalyticsFormView,
  ViewDynamicAnalytics as ViewOnDemandAnalyticsView,
  DynamicAnalyticsResults as OnDemandAnalyticsResultsView,
  DynamicAnalyticsForm as DynamicAnalyticsFormView,
  Analytics as AnalyticsView,
  AnalyticsMain as AnalyticsMainView,
  Top25 as Top25View,
  Comparison as ComparisonView,
  IncentiveStats as IncentiveStatsView,
  ContractStats as ContractStatsView,
  MonthlyActiveContracts as MonthlyActiveContractsView,
  IncentiveTrends as IncentiveTrendsView,
  SalesPaymentComparison as SalesPaymentComparisonView,
  GeographicalChart as GeographicalChartView,
  GeographicalChartUS as GeographicalChartUSView,
  VarianceCombined as VarianceCombinedView,
  AccrualVariance as AccrualVarianceView,
  RebateTrends as RebateTrendsView,
  RebateTrendsSecondPage as RebateTrendsSecondPageView,
  RebateTrendsChartPage as RebateTrendsChartPageView,
  PaymentVariance as PaymentVarianceView,
  ContractVariance as ContractVarianceView,
  ContractVariancePayment as ContractVariancePaymentView,
  SoldToPartyVariance as SoldToPartyVarianceView,
  SoldToPartyVariancePayment as SoldToPartyVariancePaymentView,
  MaterialNumberVariance as MaterialNumberVarianceView,
  MaterialNumberVariancePayment as MaterialNumberVariancePaymentView,
  AddFormula as AddFormulaLabView,
  EditFormula as EditFormulaLabView,
  FormulaLabMain as FormulaLabMainView,
  ViewFormula as ViewFormulaLabView,
  AddQuery as AddQueryFieldView,
  EditQuery as EditQueryFieldView,
  QueryFieldMain as QueryFieldMainView,
  ViewQuery as ViewQueryFieldView,
  PredefinedQuery as PredefinedQueryView,
  PredefinedQueryResults as PredefinedQueryResultsView,

  //approval designer
  ApprovalDesignerMain as ApprovalDesignerMainView,
  AddApprovalDesigner as AddApprovalDesignerView,
  EditApprovalDesigner as EditApprovalDesignerView,
  ViewApprovalDesigner as ViewApprovalDesignerView,
  //Work Flow Assignments
  WorkFlowAssignmentsMain as WorkFlowAssignmentsMainView,
  AddWorkFlowAssignments as AddWorkFlowAssignmentsView,
  EditWorkFlowAssignments as EditWorkFlowAssignmentsView,
  ViewWorkFlowAssignments as ViewWorkFlowAssignmentsView,
  //contract default designer
  ContractDefaultDesignerMain as ContractDefaultDesignerMainView,
  AddContractDefaultDesigner as AddContractDefaultDesignerView,
  EditContractDefaultDesigner as EditContractDefaultDesignerView,
  ViewContractDefaultDesigner as ViewContractDefaultDesignerView,
  //expiring contracts
  ExpiringContractsSetup as ExpiringContractsView,
  ExpiredContractsSetup as ExpiredContractsView,
  SearchContract as SearchContractView,
  //new partner statement
  PartnerStatementNew as PartnerStatementView,
  PartnerStatementNewForm as PartnerStatementNewFormView,
  PartnerStatementNewResult as PartnerStatementNewResultView,
  AddPartnerStatement as AddPartnerStatementView,
  EditPartnerStatement as EditPartnerStatementView,
  ViewPartnerStatement as ViewPartnerStatementView,
  //collaboration portal
  CollaborationPortalNew as CollaborationPortalView,
  AddCollaborationPortal as AddCollaborationPortalView,
  EditCollaborationPortal as EditCollaborationPortalView,
  ViewCollaborationPortal as ViewCollaborationPortalView,
  FinancialPostingReportsApproval as FinancialPostingReportsApprovalView,
  CustomerPaymentSummaryReports as CustomerPaymentSummaryReportsView,
  AccrualVsPaymentReports as AccrualVsPaymentReportsView,
  AccrualVsPaymentReportsTable as AccrualVsPaymentReportsTableView,
  CollaborationPortalForm as CollaborationPortalFormView,
  CollaborationPortalResult as CollaborationPortalResultView,
  //material x master
  MaterialXMasterExcelUpload as MaterialXMasterExcelUploadView,
  MaterialXMasterViewQuery as MaterialXMasterViewQueryView,
  MaterialXMasterEditQuery as MaterialXMasterEditQueryView,
  MaterialXMasterAddQuery as MaterialXMasterAddQueryView,
  //supplier master
  SupplierMasterExcelUpload as SupplierMasterExcelUploadView,
  SupplierMasterViewQuery as SupplierMasterViewQueryView,
  SupplierMasterEditQuery as SupplierMasterEditQueryView,
  SupplierMasterAddQuery as SupplierMasterAddQueryView,
  //supplier master
  MembershipExcelUpload as MembershipExcelUploadView,
  MembershipViewQuery as MembershipViewQueryView,
  MembershipEditQuery as MembershipEditQueryView,
  MembershipAddQuery as MembershipAddQueryView,
  //sales Bundle
  BomExcelUpload as BomExcelUploadView,
  BomAddQuery as BomAddQueryView,
  BomViewQuery as BomViewQueryView,
  BomEditQuery as BomEditQueryView,

  //employee
  EmployeeExcelUpload as EmployeeExcelUploadView,
  EmployeeViewQuery as EmployeeViewQueryView,
  EmployeeEditQuery as EmployeeEditQueryView,
  EmployeeAddQuery as EmployeeAddQueryView,
  EmployeeTerritoryExcelUpload as EmployeeTerritoryExcelUploadView,
  //sales Basis
  AddSalesBasis as AddSalesBasisView,
  EditSalesBasis as EditSalesBasisView,
  ViewSalesBasis as SalesBasisView,
  //outgoing claims
  OutgoingClaimsUploadNew as OutgoingClaimsUploadNewView,
  OutgoingClaimsAddQuery as CustomerOutgoingAddClaimsView,
  OutgoingClaimsEditQuery as OutgoingClaimsEditQueryView,
  ViewOutgoingClaimsQuery as ViewOutgoingClaimsQueryView,
  SupplierClaimStatus as supplierChargebackClaimsStatus,
  FinancialSummaryReportsTable as FinancialSummaryReportsTableView,
  FinancialSummaryReportsResults as FinancialSummaryReportsResultsView,
  CustomerPaymentSummaryResults as CustomerPaymentSummaryReportsResultsView,
  BatchJobStatus as BatchJobStatusView,
  BatchJobStatusSecondPage as BatchJobStatusSecondPageView,
  PromoBatchJobStatus as PromoBatchJobStatusView,
  CostingDownloadFile as CostingDownloadFileView,
  CostingListScreen as CostingListScreenView,
  BatchJobSetup as BatchJobSetupView,
  AddBatchJobSetup as AddBatchJobSetupView,
  EditBatchJobSetup as EditBatchJobSetupView,
  ViewBatchJobSetup as ViewBatchJobSetupView,
  AddCCBatchJobSetup as AddCCBatchJobSetupView,
  EditCCBatchJobSetup as EditCCBatchJobSetupView,
  ViewCCBatchJobSetup as ViewCCBatchJobSetupView,
  ExceptionManagementMain as ExceptionManagementView,
  AddExceptionManagement as AddExceptionManagementView,
  EditExceptionManagement as EditExceptionManagementView,
  ViewsExceptionManagement as ViewsExceptionManagementView,
  AccrualApprovals as AccrualApprovalsView,
  PaymentApprovals as PaymentApprovalsView,
  PostingFilterTab as PostingFilterTabView,
  IntegrationStatus as IntegrationStatusView,
  OnDemandIntegration as OnDemandIntegrationView,
  //key figure mapping
  KeyFigureMain as KeyFigureMainView,
  AddKeyFigure as AddKeyFigureView,
  EditKeyFigure as EditKeyFigureView,
  ViewKeyFigure as ViewKeyFigureView,
  ContractTrends as ContractTrendsView,
  ContractTrendsResult as ContractTrendsResultView,
  ContractTrendsResultSF as ContractTrendsResultSFView,
  ContractTrendsCP as ContractTrendsCPView,
  FPHistory as FPHistoryView,
  CalculationAnalysis as CalculationAnalysisView,
  AddCalculationAnalysis as AddCalculationAnalysisView,
  EditCalculationAnalysis as EditCalculationAnalysisView,
  ViewCalculationAnalysis as ViewCalculationAnalysisView,
  //product Group
  ProductGroup as ProductGroupView,
  AddProductGroup as AddProductGroupView,
  EditProductGroup as EditProductGroupView,
  ViewProductGroup as ViewProductGroupView,
  ProductGroupExcelUpload as ProductGroupExcelUploadView,
  ProductGroupViewQuery as ProductGroupViewQueryView,
  ProductGroupEditQuery as ProductGroupEditQueryView,
  ProductGroupAddQuery as ProductGroupAddQueryView,
  // Contract XREF
  ContractXREF as ContractXREFView,
  AddContractXREF as AddContractXREFView,
  EditContractXREF as EditContractXREFView,
  ViewContractXREF as ViewContractXREFView,

  //Attribute mapping
  AttributeMappingMain as AttributeMappingMainView,
  AddAttributeMapping as AddAttributeMappingView,
  EditAttributeMapping as EditAttributeMappingView,
  ViewAttributeMapping as ViewAttributeMappingView,

  //Basis Mapping
  BasisMappingMain as BasisMappingMainView,
  AddBasisMapping as AddBasisMappingView,
  EditBasisMapping as EditBasisMappingView,
  ViewBasisMapping as ViewBasisMappingView,

  //quota
  QuotaManagementMain as QuotaManagementMainView,
  AddQuota as AddQuotaView,
  EditQuota as EditQuotaView,
  ViewQuota as ViewQuotaView,
  QuotaManagementExcelUpload as QuotaManagementExcelUploadView,
  QuotaManagementViewQuery as QuotaManagementViewQueryView,
  QuotaManagementEditQuery as QuotaManagementEditQueryView,
  QuotaManagementAddQuery as QuotaManagementAddQueryView,
  // external formula results
  ExternalFormulaResultsMain as ExternalFormulaResultsMainView,
  AddExternalFormula as AddExternalFormulaView,
  EditExternalFormula as EditExternalFormulaView,
  ViewExternalFormula as ViewExternalFormulaView,
  ExternalFormulaResultsExcelUpload as ExternalFormulaResultsExcelUploadView,
  ExternalFormulaResultsViewQuery as ExternalFormulaResultsViewQueryView,
  ExternalFormulaResultsEditQuery as ExternalFormulaResultsEditQueryView,
  ExternalFormulaResultsAddQuery as ExternalFormulaResultsAddQueryView,
  ExternalFormulaResultsSearch as ExternalFormulaResultsSearchView,
  PerformanceMetricMain as PerformanceMetricMainView,
  AddPerformanceMetric as AddPerformanceMetricView,
  EditPerformanceMetric as EditPerformanceMetricView,
  PerformanceMetricExcelUpload as PerformanceMetricExcelUploadView,
  PerformanceMetricViewQuery as PerformanceMetricViewQueryView,
  PerformanceMetricEditQuery as PerformanceMetricEditQueryView,
  PerformanceMetricAddQuery as PerformanceMetricAddQueryView,
  PerformanceMetricWeightageMain as PerformanceMetricWeightageMainView,
  AddPerformanceMetricWeightage as AddPerformanceMetricWeightageView,
  EditPerformanceMetricWeightage as EditPerformanceMetricWeightageView,
  PerformanceMetricWeightageExcelUpload as PerformanceMetricWeightageExcelUploadView,
  PerformanceMetricWeightageViewQuery as PerformanceMetricWeightageViewQueryView,
  PerformanceMetricWeightageEditQuery as PerformanceMetricWeightageEditQueryView,
  PerformanceMetricWeightageAddQuery as PerformanceMetricWeightageAddQueryView,
  //UoM
  UoMMain as UoMMainView,
  AddUoM as AddUoMView,
  EditUoM as EditUoMView,
  //product feature
  ProductFeatureMain as ProductFeatureMainView,
  AddProductFeature as AddProductFeatureView,
  EditProductFeature as EditProductFeatureView,
  ProductFeatureCalculate as ProductFeatureCalculateView,
  ProductFeatureCalculateResult as ProductFeatureCalculateResultView,
  FiscalYear as FiscalYearView,
  CurrencyConversion as CurrencyConversionView,

  //territory upload
  TerritoryExcelUpload as TerritoryExcelUploadView,
  TerritoryAddQuery as TerritoryAddQueryView,
  TerritoryEditQuery as TerritoryEditQueryView,
  TerritoryViewQuery as TerritoryViewQueryView,

  //promotion
  PromotionForm as PromotionFormView,
  PromotionResult as PromotionResultView,

  // external inputs
  FinanceCost as FinanceCostView,
  FinanceCostEdit as FinanceCostEditView,
  FinanceCostExcelUpload as FinanceCostExcelUploadView,
  FinanceCostViewQuery as FinanceCostViewQueryView,
  FinanceCostEditQuery as FinanceCostEditQueryView,
  FinanceCostAddQuery as FinanceCostAddQueryView,
  StandardFinance as StandardFinanceView,
  StandardFinanceExcelUpload as StandardFinanceExcelUploadView,
  StandardFinanceViewQuery as StandardFinanceViewQueryView,
  StandardFinanceEditQuery as StandardFinanceEditQueryView,
  StandardFinanceAddQuery as StandardFinanceAddQueryView,
  StandardFinanceEdit as StandardFinanceEditView,
  PromotionTerritory as PromotionTerritoryView,
  PromotionTerritoryEdit as PromotionTerritoryEditView,
  PromotionTerritoryExcelUpload as PromotionTerritoryExcelUploadView,
  PromotionTerritoryViewQuery as PromotionTerritoryViewQueryView,
  PromotionTerritoryEditQuery as PromotionTerritoryEditQueryView,
  PromotionTerritoryAddQuery as PromotionTerritoryAddQueryView,
  ViewFinanceCost as ViewFinanceCostView,

  //incoming claim response format
  IncomingClaimFormatAddQuery as SupplierIncomingClaimFormatAddView,
  IncomingClaimFormatMain as IncomingClaimFormatView,
  IncomingClaimFormatEditQuery as SupplierIncomingClaimFormatEditView,
  IncomingClaimFormatViewQuery as ViewIncomingClaimFormatView,

  //outgoing claim download format
  OutgoingClaimDownloadFormatAddQuery as CustomerOutgoingClaimDownloadFormatAddView,
  OutgoingClaimDownloadFormatMain as OutgoingClaimDownloadFormatView,
  OutgoingClaimDownloadFormatEditQuery as CustomerOutgoingClaimDownloadFormatEditView,
  OutgoingClaimDownloadFormatViewQuery as ViewOutgoingClaimDownloadFormatView,
  EdiMappings as EdiMappingsView,
  EdiMappingAdd as EdiMappingAddView,
  EdiMappingEdit as EdiMappingEditView,
  EdiMappingView as EdiMappingViewView,
  ContractMassUpload as ContractMassUploadView,
  MassContractUpdateForm as MassContractUpdateFormView,
  MassContractUpdateResult as MassContractUpdateResultView,
  //inventory
  InventoryData as InventoryDataView,
  AddInventoryData as AddInventoryDataView,
  EditInventoryData as EditInventoryDataView,
  InventoryDataUpload as InventoryDataUploadView,
  ViewInventoryData as ViewInventoryDataView,
  InventoryDataEditQuery as InventoryDataEditQueryView,
  InventoryDataAddQuery as InventoryDataAddQueryView,

  //demand supply
  DemandSupply as DemandSupplyView,
  AddDemandSupply as AddDemandSupplyView,
  EditDemandSupply as EditDemandSupplyView,
  DemandSupplyUpload as DemandSupplyUploadView,
  ViewDemandSupply as ViewDemandSupplyView,
  DemandSupplyEditQuery as DemandSupplyEditQueryView,
  DemandSupplyAddQuery as DemandSupplyAddQueryView,

  // Competitor Data
  CompetitorData as CompetitorDataView,
  EditCompetitorData as EditCompetitorDataView,
  SpreadsheetCreatorSF as SpreadsheetCreatorSFView,
  SpreadsheetCreatorListPrice as SpreadsheetCreatorListPriceView,

  // Off Invoice Cost
  OffInvoiceCost as OffInvoiceCostView,
  OffInvoiceCostUpload,
  OffInvoiceCostUploadViewQuery,
  OffInvoiceCostUploadEditQuery,
  OffInvoiceCostUploadAddQuery,
  BuildOffInvoiceCostQuery as BuildOffInvoiceCostQueryView,
  ClaimConfigurationMain as ClaimConfigurationMainView,
  // Rejection Reasons
  ClaimErrorMain as ClaimErrorMainView,
  // UoMMain as UoMMainView,
  AddClaimError as AddClaimErrorView,
  EditClaimError as EditClaimErrorView,
  AddEditClaimError as AddEditClaimErrorView,
  PromoPlanSalesIncentives as PromoPlanSalesIncentivesView,
  TimeToMarket as TimeToMarketView,
  MarketShare as MarketShareView,
  IntegrationMapping as IntegrationMappingView,
  AddIntegrationMapping as AddIntegrationMappingView,
  EditIntegrationMapping as EditIntegrationMappingView,
  ViewIntegrationMapping as ViewIntegrationMappingView,
  PromoPlanClaimConfigurationMain as PromoConfigurationView,
  SalesForecast as SalesForecastingView,
  SalesForecastDisplay as SalesForecastingDisplayView,
  SalesForecastExcelUpload as SalesForecastExcelUploadView,
  SalesForecastViewQuery as SalesForecastViewQueryView,
  SalesForecastEditQuery as SalesForecastEditQueryView,
  SalesForecastAddQuery as SalesForecastAddQueryView,
  ForecastingSales as ForecastingSalesView,
  DisplayForecastResult as ViewDisplayForecastResult,
  DisplayForecastResultView as ViewDisplayForecastResultView,
  StockChart as StockChartView,
  CoverageCost as CoverageCostView,
  MasterDataSearch as MasterDataSearchView,
  CoverageCostSearch as CoverageCostSearchView,
  MaterialPricingSearch as MaterialPricingSearchView,
  SalesForecastSearch as SalesForecastSearchView,
  CoverageCostExcelUpload as CoverageCostExcelUploadView,
  CoverageCostViewQuery as CoverageCostViewQueryView,
  CoverageCostEditQuery as CoverageCostEditQueryView,
  CoverageCostAddQuery as CoverageCostAddQueryView,
  CoverageCostMapping as CoverageCostMappingView,
  CoverageCostMappingAdd as CoverageMappingAddQueryView,
  CoverageCostMappingEdit as CoverageMappingEditQueryView,
  CoverageCostMappingView as CoverageMappingViewQueryView,
  CoverageCostEdit as CoverageCostEditView,
  CoverageMappingEdit as CoverageMappingEditView,
  CoverageMappingExcelUpload as CoverageMappingExcelUploadView,
  CoverageMappingSearch as CoverageMappingSearchView,
  PromotionConfig as PromotionConfigView,
  PromotionConfigAdd as PromotionConfigAddView,
  PromotionConfigEdit as PromotionConfigEditView,
  PromotionConfigExcelUpload as PromotionConfigExcelUploadView,
  PromotionConfigAddQuery as PromotionConfigAddQueryView,
  PromotionConfigEditQuery as PromotionConfigEditQueryView,
  PromotionConfigViewQuery as PromotionConfigViewQueryView,
  MaterialPricing as MaterialPricingView,
  MaterialPricingEdit as MaterialPricingEditView,
  MaterialPricingExcelUpload as MaterialPricingExcelUploadView,
  MaterialPricingAddQuery as MaterialPricingAddQueryView,
  MaterialPricingEditQuery as MaterialPricingEditQueryView,
  MaterialPricingViewQuery as MaterialPricingViewQueryView,
  PromotionCurrency as PromotionCurrencyView,
  PromotionCurrencyExcelUpload as PromotionCurrencyExcelUploadView,
  PromotionCurrencyAddQuery as PromotionCurrencyAddQueryView,
  PromotionCurrencyEditQuery as PromotionCurrencyEditQueryView,
  PromotionCurrencyViewQuery as PromotionCurrencyViewQueryView,
  PromotionCurrencyEdit as PromotionCurrencyEditView,
  //error logs
  ErrorLog as ErrorLogView,
  MessageLog as MessageLogView,
  //pricing formula
  AddPricingFormula as AddPricingFormulaView,
  EditPricingFormula as EditPricingFormulaView,
  FormulaBuilderMain as FormulaBuilderMainView,
  ViewPricingFormula as ViewPricingFormulaView,

  // Market Price
  MarketPrice as MarketPriceView,
  // list Price
  ListPriceData as ListPriceDataView,
  AddCompetitorListPriceData as ListPriceDataAddView,
  EditListPriceData as ListPriceDataEditView,
  ListPriceDataUpload as ListPriceDataUploadView,
  ListPriceDataUploadAddQuery as ListPriceDataUploadAddQueryView,
  ListPriceDataUploadEditQuery as ListPriceDataUploadEditQueryView,
  ListPriceDataUploadViewQuery as ListPriceDataUploadViewQueryView,
  //price default config
  AddQualifierConfig as AddQualifierConfigView,
  EditQualifierConfig as EditQualifierConfigView,
  QualifierConfigMain as QualifierConfigMainView,
  //price type config
  AddTypeConfig as AddTypeConfigView,
  EditTypeConfig as EditTypeConfigView,
  PriceTypeConfigMain as PriceTypeConfigMainView,
  //price list master
  AddPriceList as AddPriceListView,
  EditPriceList as EditPriceListView,
  PriceListMasterMain as PriceListMasterMainView,

  //price maintenance
  AddPriceListData as AddPriceListDataView,
  EditPriceListData as EditPriceListDataView,
  EditPriceListDataSheet as EditPriceListDataSheetView,
  ViewPriceListData as ViewPriceListDataView,
  PriceListDataMain as PriceListDataMainView,
  PricingDashboard as PricingDashboardView,
  PriceListDataExcelUpload as PriceListDataExcelUploadView,
  PriceListDataViewQuery as PriceListDataViewQueryView,
  PriceListDataEditQuery as PriceListDataEditQueryView,
  PriceListDataAddQuery as PriceListDataAddQueryView,
  PriceListMaintenanceAddQuery as PriceListMaintenanceAddQueryView,
  PriceListMaintenanceEditQuery as PriceListMaintenanceEditQueryView,
  PricingApproval as PricingApprovalView,
  PricingApprovalList as PricingApprovalListView,
  //price calculation
  PriceCalculationForm as PriceCalculationFormView,
  //offinvoice config
  OffInvoiceMain as OffInvoiceMainView,
  //promotion
  PromotionSetupMain as PromotionSetupMainView,
  PromotionSetup as AddPromotionSetupView,
  EditPromotionSetup as EditPromotionSetupView,
  ViewPromotionSetup as ViewPromotionSetupView,
  GlobalSetup as GlobalSetupView,
  AddStickers as AddStickersView,
  EditStickers as EditStickersView,
  StickerBank as StickerBankView,
  Grid as GridView,
  TakesRateApproval as TakesRateApprovalView,
  CostingApproval as CostingApprovalView,
  PromotionScenario as PromotionScenarioView,
  PromotionScenarioEdit as PromotionScenarioEditView,
  ScenarioListScreen as ScenarioListScreenView,
  SandBoxListScreen as SandBoxListScreenView,
  HistoryListScreen as HistoryListScreenView,
  PromotionDashboard as PromotionDashboardView,
  EndPreMaturely as EndPreMaturelyView,
  CreateCustomGroup as CreateCustomGroupView,
  UpdateCustomGroup as UpdateCustomGroupView,
  CustomGroup as CustomGroupView,
  //promotion Mapping
  PromotionMapping as PromotionMappingView,
  AddPromotion as AddPromotionMappingView,
  EditPromotion as EditPromotionMappingView,
  PromotionMappingExcelUpload as PromotionMappingExcelUploadView,
  PromotionMappingViewQuery as PromotionMappingViewQueryView,
  PromotionMappingEditQuery as PromotionMappingEditQueryView,
  PromotionMappingAddQuery as PromotionMappingAddQueryView,
  PromotionReview as PromotionReviewView,
  // price Calculation Formula
  PriceCalculationFormula as priceCalculationFormulaView,
  AddPriceCalculationFormula as AddPriceCalculationFormulaView,
  EditPriceCalculationFormula as EditPriceCalculationFormulaView,
  ViewPriceCalculationFormula as ViewPriceCalculationFormulaView,

  // forecaste config
  ForecastingConfigMain as ForecastingConfigMainView,
  AddConfig as AddConfigView,
  EditConfig as EditConfigView,
  ViewConfig as ViewConfigView,
  CustomerRebateForecast as CustomerRebateForecastView,
  CustomerRebateForecastResult as CustomerRebateForecastResultView,
  //anamoly config
  AnamolyConfigMain as AnamolyConfigMainView,
  AddAnamolyConfig as AddAnamolyConfigView,
  EditAnamolyConfig as EditAnamolyConfigView,
  ViewAnamolyConfig as ViewAnamolyConfigView,
  AnamolyRun as RunAnamolyConfigView,
  // Historical Sales Upload
  HistoricalSalesUpload as HistoricalSalesUploadView,
  SalesForecastMain as SalesForecastMainView,
  SalesForecastUpload as SalesForecastUploadView,
  SalesForecastFileUpload as SalesForecastFileUploadView,
  SalesForecastEdit as SalesForecastEditView,
  BuildForecastData as BuildForecastDataView,
  CostSimulationForm as CostSimulationFormView,
  CostPostingForm as CostPostingFormView,
  CostApprovalForm as CostApprovalFormView,

  // Price Simulation (CPQ)
  PriceSimulationCPQ as PriceSimulationCPQView,
  PriceSimulationCPQResult as PriceSimulationCPQResultView,
  PriceElasticity as PriceElasticityView,
  PriceElasticityResult as PriceElasticityResultView,
  PriceSimulationReport as PriceSimulationReportView,
  PriceSimulationReportResult as PriceSimulationReportResultView,
  PriceCatalog as PriceCatalogView,
  PriceCatalogResult as PriceCatalogResultView,
  PriceAdjustmentSimulation as PriceAdjustmentSimulationView,
  PriceAdjustmentSimulationResult as PriceAdjustmentSimulationResultView,
  EDIFile as EDIFileView,
  CustomerMargin as CustomerMarginView,
  CustomerMarginResult as CustomerMarginResultView,
  PriceSimulationHistorical as PriceSimulationHistoricalView,
  PriceSimulationForecast as PriceSimulationForecastView,
  CostApprovalResult as CostApprovalsView,
  PromoPlanAnalytics as PromoPlanAnalyticsView,
  CPQResult as CPQResultView,
  ComprehensiveQuote as ComprehensiveQuoteView,
  EditComprehensiveQuote as EditComprehensiveQuoteView,
  RejectedDashboard as RejectedDashboardView,
  ClaimStatusResult as ClaimStatusResultView,
  ClaimStatusSearch as ClaimStatusSearchView,
  CostAllocationPromType as CostAllocationPromTypeView,
  MonthlyTrendCostToRevenue as MonthlyTrendCostToRevenueView,
  MonthlyTrendPromType as MonthlyTrendPromTypeView,
  //cot xref
  CotXREF as CotXREFView,
  AddCotXRef as AddCotXRefView,
  EditCotXRef as EditCotXRefView,
  ViewCotXRef as ViewCotXRefView,
  CotXREFExcelUpload as CotXREFExcelUploadView,
  CotXREFViewQuery as CotXREFViewQueryView,
  CotXREFEditQuery as CotXREFEditQueryView,
  CotXREFAddQuery as CotXREFAddQueryView,
  // profit optimization config
  AddAttributesConfig as AddAttributesConfigView,
  EditAttributesConfig as EditAttributesConfigView,
  AttributesConfigMain as AttributesConfigMainView,
  AddDrivers as AddDriversView,
  EditDrivers as EditDriversView,
  DriversMain as DriversMainView,
  AddAnalysisLevel as AddAnalysisLevelView,
  EditAnalysisLevel as EditAnalysisLevelView,
  AnalysisLevelMain as AnalysisLevelMainView,
  AnalysisFormula as AnalysisFormulaView,
  AddAnalysisFormula as AddAnalysisFormulaView,
  EditAnalysisFormula as EditAnalysisFormulaView,
  ViewAnalysisFormula as ViewAnalysisFormulaView,
  // Analytics Designer
  AnalyticsDesignerMain as AnalyticsDesignerMainView,
  ReportingByFunctionMain as ReportingByFunctionMainView,
  ReportingOnRoll as ReportingOnRollView,
  DashboardDesignerMain as DashboardDesignerMainView,
  AddAnalyticsDesigner as AddAnalyticsDesignerView,
  AddReportingByFunction as AddReportingByFunctionView,
  AddDashboardDesigner as AddDashboardDesignerView,
  EditAnalyticsDesigner as EditAnalyticsDesignerView,
  EditDashboardDesigner as EditDashboardDesignerView,
  EditReportingByFunction as EditReportingByFunctionView,
  ViewAnalyticsDesigner as ViewAnalyticsDesignerView,
  ViewReportByFuction as ViewReportByFuctionView,
  ViewDashBoardDesigner as ViewDashBoardDesignerView,
  HelpDeskMainCP as HelpDeskMainCPView,
  Documentation as DocumentationView,
  FAQs as FAQsView,
  LogDefect as LogDefectView,
  AddHelpDeskCP as AddHelpDeskCPView,
  EditHelpDeskCP as EditHelpDeskCPView,
  //driver maintenance
  AddDriverMaintenance as AddDriverMaintenanceView,
  EditDriverMaintenance as EditDriverMaintenanceView,
  ViewDriverMaintenance as ViewDriverMaintenanceView,
  DriverMaintenanceMain as DriverMaintenanceMainView,
  DriverMaintenanceExcelUpload as DriverMaintenanceExcelUploadView,
  DriverMaintenanceViewQuery as DriverMaintenanceViewQueryView,
  DriverMaintenanceEditQuery as DriverMaintenanceEditQueryView,
  DriverMaintenanceAddQuery as DriverMaintenanceAddQueryView,
  PricePolicyExceptionsReportMain as PricePolicyExceptionsReportMainView,
  MarginAnalysis as MarginAnalysisView,
  FlexibleAnalysisResult as FlexibleAnalysisResultView,
  MarginPolicyExceptions as MarginPolicyExceptionsView,
  MarginPolicyExceptionResult as MarginPolicyExceptionResultView,
  MarginTrending as MarginTrendingView,
  MarginTrendingResult as MarginTrendingResultView,
  MarginByAttribute as MarginByAttributeView,
  RegionalAnalysis as RegionalAnalysisView,
  PricingBatchJobSetup as PricingBatchJobSetupView,
  AddPricingBatchJobSetup as AddPricingBatchJobSetupView,
  EditPricingBatchJobSetup as EditPricingBatchJobSetupView,
  ViewPricingBatchJobSetup as ViewPricingBatchJobSetupView,
  ProfitOptBatchJobSetup as ProfitOptBatchJobSetupView,
  AddProfitOptBatchJobSetup as AddProfitOptBatchJobSetupView,
  EditProfitOptBatchJobSetup as EditProfitOptBatchJobSetupView,
  ViewProfitOptBatchJobSetup as ViewProfitOptBatchJobSetupView,
  BuildAnalysisDataQuery as BuildAnalysisDataQueryView,
  ExternalDataAddQuery as ExternalDataAddQueryView,
  ExternalDataEditQuery as ExternalDataEditQueryView,
  ViewExternalDataQuery as ViewExternalDataQueryView,
  ExternalDataQueryList as ExternalDataQueryListView,
  CustomerLevel as CustomerLevelAnalyticsView,
  RevenueMarginAnalytics as RevenueMarginAnalyticsView,
  topGrowwingDecliningPerformers as topGrowwingDecliningPerformersView,
  MarginPricePercentage as MarginPricePercentageView,
  MarginAnalytics as MarginAnalyticsView,
  PricingWaterfallChart as PricingWaterfallChartView,
  ProfitabilityChart as ProfitabilityChartView,
  MarginRegionalAnalysis as MarginRegionalAnalysisView,
  AddExternalConfig as AddExternalConfigView,
  EditExternalConfig as EditExternalConfigView,
  ExternalConfigMain as ExternalConfigMainView,
  BuildPricingAnalysisDataQuery as BuildPricingAnalysisDataQueryView,
  ExternalPricingDataAddQuery as ExternalPricingDataAddQueryView,
  ExternalPricingDataEditQuery as ExternalPricingDataEditQueryView,
  ViewExternalPricingDataQuery as ViewExternalPricingDataQueryView,
  ExternalPricingDataQueryList as ExternalPricingDataQueryListView,
  AddPricingExternalConfig as AddPricingExternalConfigView,
  EditPricingExternalConfig as EditPricingExternalConfigView,
  ExternalPricingConfigMain as ExternalPricingConfigMainView,
  DefaultPage as DefaultPageView,
  MasterDataFilters as MasterDataFiltersView,
  MasterDataDashboardDetails as MasterDataDashboardDetailsView,
  ProductFeatureMainPricing as ProductFeatureMainPricingView,
  AddProductFeaturePricing as AddProductFeaturePricingView,
  EditProductFeaturePricing as EditProductFeaturePricingView,
  ProductFeatureDataExcelUpload as ProductFeatureDataExcelUploadView,
  ProductFeatureDataAddQuery as ProductFeatureDataAddQueryView,
  ProductFeatureDataEditQuery as ProductFeatureDataEditQueryView,
  ProductFeatureDataViewQuery as ProductFeatureDataViewQueryView,
  MasterDataConfiguration as MasterDataConfigurationView,
  //collaboration designer
  CollaborationDesignerMain as CollaborationDesignerMainView,
  AddCollaborationDesigner as AddCollaborationDesignerView,
  EditCollaborationDesigner as EditCollaborationDesignerView,
  ViewCollaborationDesigner as ViewCollaborationDesignerView,
  NextTierForm as NextTierFormView,
  NextTierResult as NextTierResultView,
  TierEvaluationDesigner as TierEvaluationDesignerView,
  ChatGptMain as ChatGptView,
  TransactionDataBatchJobSetup as TransactionDataBatchJobSetupView,
  AddTransactionDataBatchJobSetup as AddTransactionDataBatchJobSetupView,
  EditTransactionDataBatchJobSetup as EditTransactionDataBatchJobSetupView,
  ViewTransactionDataBatchJobSetup as ViewTransactionDataBatchJobSetupView,
  CommunityChatMain as CommunityChatMainView,
  CommunityChatQA as CommunityChatQAView,
  QuoteDetail as QuoteDetailView,
  DocumentManagementMain as DocumentManagementMainView,
  CreateDocument as CreateDocumentView,
  UpdateDocument as UpdateDocumentView,
  ViewDocument as ViewDocumentView,
  DocumentManagementSearch as DocumentManagementSearchView,
  DocumentManagementSearchResult as DocumentManagementSearchResultView,
  DocumentLevels as DocumentLevelsView,
  CreateLevels as CreateLevelsView,
  UpdateLevels as UpdateLevelsView,
  ViewLevels as ViewLevelsView,
  RebateAnalytics as RebateAnalyticsView,
  PostingSummaryAnalysis as PostingSummaryAnalysisView,
  PostingDetailAnalytics as PostingDetailAnalyticsView,
  NewMasterDataIncentives as NewMasterDataIncentivesView,
  CalculationSimulationMaintenance as CalculationSimulationMaintenanceView,
  CalcSimulationResult as CalcSimulationResultView,
  ContractTrendsResultDetail as ContractTrendsResultDetailView,
  GrossToNet as GrossToNetView,
  MedianMargin as MedianMarginView,
  RevenueMargin as RevenueMarginView,
  ApplicationLogs as ApplicationLogsView,
  ContractAttributes as ContractAttributesView,
  MandatoryEligibilityRules as MandatoryEligibilityRulesView,
  UpdatePostings as UpdatePostingsView,
  GenerateMassData as GenerateMassDataView,
  MigrationManagement as MigrationManagementView,
  MigrationManagementDownLoad as MigrationManagementDownLoadView,
  MigrationManagementUpload as MigrationManagementUploadView,
  KafkaUpload as KafkaUploadView,
} from "../../views";

import {
  MultipleConfigurationSeperate as MultipleconfigurationTabView,
  SetupSalesBasisSeperate as SetupSalesBasisTabView,
  AttributeCounts as AttributeCountsView,
  KeyfigureConfiguration as KeyfigureConfigurationView,
  //LabelDesignerNew as LabelDesignerView,
  LabelDisplayDesigner as LabelDisplayDesignerView,
  ConfigurationDesign as ConfigDesignerView,
} from "../../views/MultipleConfigurationSeperate";

import { RouteWithLayout } from "./RouteWithLayout";
import { PublicRoute } from "./PublicRoute";
import { Main as MainLayout } from "../../layouts";
import { Launcher as LauncherLayout } from "../../layouts";
import { Router } from "react-router-dom";
import { history } from "../Helpers";
import CompetitorDataUploadAddQuery from "../../views/CompetitorData/CompetitorDataUploadAddQuery";
import CompetitorDataUploadEditQuery from "../../views/CompetitorData/CompetitorDataUploadEditQuery";
import CompetitorDataUploadViewQuery from "../../views/CompetitorData/CompetitorDataUploadViewQuery";
import CompetitorDataUpload from "../../views/CompetitorData/CompetitorDataUpload";

const Routes = (props) => {
  var baseUrl = getEnv()
  const oktaAuth = new OktaAuth({
    issuer: baseUrl['OKTA_DOMAIN'],
    //issuer: 'https://trial-9065551.okta.com/oauth2/default',
    clientId: baseUrl['OKTA_CLIENT_ID'],
    redirectUri: baseUrl['OKTA_REDIRECT_URI'],
    scopes: ['openid', 'profile', 'email', 'offline_access', 'ima360GCI_Roles'],
  });
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  return (
    <Router history={history}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <RouteWithLayout path="/login/callback" component={LoginCallback} />
          {/* <SecureRoute path="/dashboard" exact={true} component={DashboardView} /> */}
          {/* <RouteWithLayout path="/" component={LoginView} /> */}
          <PublicRoute component={LoginView} path="/login" />
          <PublicRoute component={ForgotPasswordView} path="/reset-password" />
          {/* <RouteWithLayout
          exact
          component={AppLauncherView}
          layout={LauncherLayout}
          path="/applauncher"
        /> */}
          <RouteWithLayout
            exact
            component={AppLauncherNewView}
            layout={LauncherLayout}
            path="/applauncher"
          />
          new
          {/* <Redirect exact from="/" to="/dashboard" /> */}
          <Redirect exact from="/" to="/applauncher" /> {/*  Instead of dashbaord redirect to applauncher */}
          <RouteWithLayout
            exact
            component={DashboardView}
            layout={MainLayout}
            path="/dashboard"
          />
          <RouteWithLayout
            exact
            component={ContractSetupView}
            layout={MainLayout}
            path="/contract-setup"
          />
          <RouteWithLayout
            exact
            component={ContractSetupListView}
            layout={MainLayout}
            path="/contract-setup-list"
          />
          <RouteWithLayout
            exact
            component={ApprovalRejectView}
            layout={MainLayout}
            path="/approval-reject"
          />
          <RouteWithLayout
            exact
            component={ExceptionApprovalRejectView}
            layout={MainLayout}
            path="/exception-approval"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestCompareView}
            layout={MainLayout}
            path="/approval-reject/change-request/:id"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestCompareAPIView}
            layout={MainLayout}
            path="/contract-setup/compare/:id"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestCompareAPIView}
            layout={MainLayout}
            path="/approval-reject/compare/:id"
          />
          <RouteWithLayout
            exact
            component={AddContractView}
            layout={MainLayout}
            path="/contract-setup/add-contract"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestDetailView}
            layout={MainLayout}
            path="/contract-setup/change-request-detail/:id"
          />
          <RouteWithLayout
            exact
            component={EditContractView}
            layout={MainLayout}
            path="/contract-setup/edit-contract/:id"
          />
          <RouteWithLayout
            exact
            component={EditContractSubmitView}
            layout={MainLayout}
            path="/contract-setup/edit-contract-submit-approval/:id"
          />
          <RouteWithLayout
            exact
            component={ViewContractView}
            layout={MainLayout}
            path="/contract-setup/view-contract/:id"
          />
          <RouteWithLayout
            exact
            component={CompareContractView}
            layout={MainLayout}
            path="/contract-setup/compare-contracts"
          />
          <RouteWithLayout
            exact
            component={ViewContractView}
            layout={MainLayout}
            path="/operational-reports/view-contract/:id"
          />
          <RouteWithLayout
            exact
            component={CalculationSimulationView}
            layout={MainLayout}
            path="/calculation-simulation"
          />
          <RouteWithLayout
            exact
            component={CalculationResultsView}
            layout={MainLayout}
            path="/calculation-results"
          />
          <RouteWithLayout
            exact
            component={FinancialResultsView}
            layout={MainLayout}
            path="/financial-results"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingReportsApprovalView}
            layout={MainLayout}
            path="/financial-approvals-reports"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingReportsApprovalView}
            layout={MainLayout}
            path="/financial-approvals-reports/result"
          />
          <RouteWithLayout
            exact
            component={FinancialSummaryReportsResultsView}
            layout={MainLayout}
            path="/financial-approvals-reports/results"
          />
          <RouteWithLayout
            exact
            component={CustomerPaymentSummaryReportsView}
            layout={MainLayout}
            path="/customer-payment-summary-reports"
          />
          <RouteWithLayout
            exact
            component={CustomerPaymentSummaryReportsResultsView}
            layout={MainLayout}
            path="/customer-payment-summary-reports/results"
          />
          <RouteWithLayout
            exact
            component={AccrualVsPaymentReportsView}
            layout={MainLayout}
            path="/accrual-vs-Payment-reports"
          />
          <RouteWithLayout
            exact
            component={AccrualVsPaymentReportsTableView}
            layout={MainLayout}
            path="/accrual-vs-Payment-reports-result"
          />
          <RouteWithLayout
            exact
            component={DailySimulationsView}
            layout={MainLayout}
            path="/daily-simulations"
          />
          <RouteWithLayout
            exact
            component={DailySimulationsReportView}
            layout={MainLayout}
            path="/daily-simulations-report"
          />
          <RouteWithLayout
            exact
            component={DailySimulationsResultView}
            layout={MainLayout}
            path="/daily-simulations-result"
          />
          <RouteWithLayout
            exact
            component={CalculationSummaryReportsView}
            layout={MainLayout}
            path="/calculation-summary-reports"
          />
          <RouteWithLayout
            exact
            component={DeleteCalculationSimulationDataView}
            layout={MainLayout}
            path="/delete-calculation-simulation-data"
          />
          <RouteWithLayout
            exact
            component={CalculationSummaryReportsTableView}
            layout={MainLayout}
            path="/calculation-summary-reports/results"
          />
          <RouteWithLayout
            exact
            component={OperationalReportsView}
            layout={MainLayout}
            path="/operational-reports"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingsView}
            layout={MainLayout}
            path="/financial-postings"
          />
          <RouteWithLayout
            exact
            component={PaymentPostingsView}
            layout={MainLayout}
            path="/payment-postings"
          />
          <RouteWithLayout
            exact
            component={SupplierPaymentPostingsView}
            layout={MainLayout}
            path="/supplier-payment-postings"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingsApprovalsAccrualView}
            layout={MainLayout}
            path="/financial-postings-approvals"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryView}
            layout={MainLayout}
            path="/partner-statement"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryFormView}
            layout={MainLayout}
            path="/partner-statement/partner-statement-form"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryResultsView}
            layout={MainLayout}
            path="/partner-statement/partner-statement-form/results/:id"
          />
          <RouteWithLayout
            exact
            component={AddDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/add-partner-statement"
          />
          <RouteWithLayout
            exact
            component={EditDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/edit-partner-statement/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/view-partner-statement/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/re-arrange-partner-statement/:id"
          />
          <RouteWithLayout
            exact
            component={ConfigurationView}
            layout={MainLayout}
            path="/configuration"
          />
          <RouteWithLayout
            exact
            component={AccessView}
            layout={MainLayout}
            path="/access"
          />
          <RouteWithLayout
            exact
            component={OrganisationAccessView}
            layout={MainLayout}
            path="/organisation-setup"
          />
          <RouteWithLayout
            exact
            component={UserAccessView}
            layout={MainLayout}
            path="/user-role"
          />
          <RouteWithLayout
            exact
            component={AppLauncherNewView}
            layout={LauncherLayout}
            path="/applauncher"
            n
          />
          new
          {/* <Redirect exact from="/" to="/dashboard" />
          <RouteWithLayout
            exact
            component={DashboardView}
            layout={MainLayout}
            path="/dashboard"
          /> */}
          <RouteWithLayout
            exact
            component={ContractSetupView}
            layout={MainLayout}
            path="/contract-setup"
          />
          <RouteWithLayout
            exact
            component={ApprovalRejectView}
            layout={MainLayout}
            path="/approval-reject"
          />
          <RouteWithLayout
            exact
            component={ExceptionApprovalRejectView}
            layout={MainLayout}
            path="/exception-approval"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestCompareView}
            layout={MainLayout}
            path="/approval-reject/change-request/:id"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestCompareAPIView}
            layout={MainLayout}
            path="/contract-setup/compare/:id"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestCompareAPIView}
            layout={MainLayout}
            path="/approval-reject/compare/:id"
          />
          <RouteWithLayout
            exact
            component={AddContractView}
            layout={MainLayout}
            path="/contract-setup/add-contract"
          />
          <RouteWithLayout
            exact
            component={ChangeRequestDetailView}
            layout={MainLayout}
            path="/contract-setup/change-request-detail/:id"
          />
          <RouteWithLayout
            exact
            component={EditContractView}
            layout={MainLayout}
            path="/contract-setup/edit-contract/:id"
          />
          <RouteWithLayout
            exact
            component={EditContractSubmitView}
            layout={MainLayout}
            path="/contract-setup/edit-contract-submit-approval/:id"
          />
          <RouteWithLayout
            exact
            component={ViewContractView}
            layout={MainLayout}
            path="/contract-setup/view-contract/:id"
          />
          <RouteWithLayout
            exact
            component={CompareContractView}
            layout={MainLayout}
            path="/contract-setup/compare-contracts"
          />
          <RouteWithLayout
            exact
            component={ViewContractView}
            layout={MainLayout}
            path="/operational-reports/view-contract/:id"
          />
          <RouteWithLayout
            exact
            component={CalculationSimulationView}
            layout={MainLayout}
            path="/calculation-simulation"
          />
          <RouteWithLayout
            exact
            component={CalculationResultsView}
            layout={MainLayout}
            path="/calculation-results"
          />
          <RouteWithLayout
            exact
            component={FinancialResultsView}
            layout={MainLayout}
            path="/financial-results"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingReportsApprovalView}
            layout={MainLayout}
            path="/financial-approvals-reports"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingReportsApprovalView}
            layout={MainLayout}
            path="/financial-approvals-reports/result"
          />
          <RouteWithLayout
            exact
            component={FinancialSummaryReportsResultsView}
            layout={MainLayout}
            path="/financial-approvals-reports/results"
          />
          <RouteWithLayout
            exact
            component={AccrualVsPaymentReportsView}
            layout={MainLayout}
            path="/accrual-vs-Payment-reports"
          />
          <RouteWithLayout
            exact
            component={AccrualVsPaymentReportsTableView}
            layout={MainLayout}
            path="/accrual-vs-Payment-reports-result"
          />
          <RouteWithLayout
            exact
            component={CalculationSummaryReportsView}
            layout={MainLayout}
            path="/calculation-summary-reports"
          />
          <RouteWithLayout
            exact
            component={DeleteCalculationSimulationDataView}
            layout={MainLayout}
            path="/delete-calculation-simulation-data"
          />
          <RouteWithLayout
            exact
            component={CalculationSummaryReportsTableView}
            layout={MainLayout}
            path="/calculation-summary-reports/results"
          />
          <RouteWithLayout
            exact
            component={OperationalReportsView}
            layout={MainLayout}
            path="/operational-reports"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingsView}
            layout={MainLayout}
            path="/financial-postings"
          />
          <RouteWithLayout
            exact
            component={ExternalAccuralPostingView}
            layout={MainLayout}
            path="/external-accural-postings"
          />
          <RouteWithLayout
            exact
            component={CompareRebateCalculationView}
            layout={MainLayout}
            path="/compare-rebate-calculation"
          />
          <RouteWithLayout
            exact
            component={OutboundPostingsIntegrationsView}
            layout={MainLayout}
            path="/outbound-postings-integrations"
          />
          <RouteWithLayout
            exact
            component={PostingsMaintenanceFormView}
            layout={MainLayout}
            path="/postings-maintenance"
          />
          <RouteWithLayout
            exact
            component={PostingsMaintenanceFormResultView}
            layout={MainLayout}
            path="/postings-result"
          />
          <RouteWithLayout
            exact
            component={DailySimulationFormView}
            layout={MainLayout}
            path="/daily-simulation"
          />
          <RouteWithLayout
            exact
            component={ExternalPaymentPostingView}
            layout={MainLayout}
            path="/external-payment-postings"
          />
          <RouteWithLayout
            exact
            component={ExternalAccuralPostingResultView}
            layout={MainLayout}
            path="/external-accural-postings-result"
          />
          <RouteWithLayout
            exact
            component={ExternalPaymentPostingResultView}
            layout={MainLayout}
            path="/external-payment-postings-result"
          />
          <RouteWithLayout
            exact
            component={CompareRebateCalculationResultView}
            layout={MainLayout}
            path="/compare-rebate-calculation-result"
          />
          <RouteWithLayout
            exact
            component={PaymentPostingsView}
            layout={MainLayout}
            path="/payment-postings"
          />
          <RouteWithLayout
            exact
            component={SupplierPaymentPostingsView}
            layout={MainLayout}
            path="/supplier-payment-postings"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingsApprovalsAccrualView}
            layout={MainLayout}
            path="/financial-postings-approvals"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryView}
            layout={MainLayout}
            path="/partner-statement"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryFormView}
            layout={MainLayout}
            path="/partner-statement/partner-statement-form"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryResultsView}
            layout={MainLayout}
            path="/partner-statement/partner-statement-form/results/:id"
          />
          <RouteWithLayout
            exact
            component={AddDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/add-partner-statement"
          />
          <RouteWithLayout
            exact
            component={EditDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/edit-partner-statement/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/view-partner-statement/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/partner-statement/re-arrange-partner-statement/:id"
          />
          <RouteWithLayout
            exact
            component={ConfigurationView}
            layout={MainLayout}
            path="/configuration"
          />
          <RouteWithLayout
            exact
            component={AccessView}
            layout={MainLayout}
            path="/access"
          />
          <RouteWithLayout
            exact
            component={OrganisationAccessView}
            layout={MainLayout}
            path="/organisation-setup"
          />
          <RouteWithLayout
            exact
            component={UserAccessView}
            layout={MainLayout}
            path="/user-role"
          />
          {/* <RouteWithLayout
          exact
          component={ContractCustomisationView}
          layout={MainLayout}
          path="/contract-customisation"
        /> */}
          {/* <RouteWithLayout
          exact
          component={FieldDisplayConfigurationView}
          layout={MainLayout}
          path="/field-display-configuration"
        /> */}
          <RouteWithLayout
            exact
            component={FieldLabelConfigurationView}
            layout={MainLayout}
            path="/field-label-configuration"
          />
          <RouteWithLayout
            exact
            component={AttributeCountsView}
            layout={MainLayout}
            path="/eligibility-designer"
          />
          <RouteWithLayout
            exact
            component={KeyfigureConfigurationView}
            layout={MainLayout}
            path="/keyfigure-configuration"
          />
          <RouteWithLayout
            exact
            component={DataSetupTransactionView}
            layout={MainLayout}
            path="/transaction-data"
          />
          <RouteWithLayout
            exact
            component={UploadExternalCalculationsMainView}
            layout={MainLayout}
            path="/upload-external-calculation-main"
          />
          <RouteWithLayout
            exact
            component={UploadExternalCalculationsAddView}
            layout={MainLayout}
            path="/upload-external-calculation-add"
          />
          <RouteWithLayout
            exact
            component={UploadExternalCalculationsEditView}
            layout={MainLayout}
            path="/upload-external-calculation-edit/:id"
          />
          <RouteWithLayout
            exact
            component={UploadExternalCalculationsViewView}
            layout={MainLayout}
            path="/upload-external-calculation-view/:id"
          />
          <RouteWithLayout
            exact
            component={UploadExternalPostingMainView}
            layout={MainLayout}
            path="/upload-external-posting-main"
          />
          <RouteWithLayout
            exact
            component={UploadExternalPostingAddView}
            layout={MainLayout}
            path="/upload-external-posting-add"
          />
          <RouteWithLayout
            exact
            component={UploadExternalPostingEditView}
            layout={MainLayout}
            path="/upload-external-posting-edit/:id"
          />
          <RouteWithLayout
            exact
            component={UploadExternalPostingViewView}
            layout={MainLayout}
            path="/upload-external-posting-view/:id"
          />
          <RouteWithLayout
            exact
            component={SalesInvoiceQueryListView}
            layout={MainLayout}
            path="/sales-inovoice-query"
          />
          <RouteWithLayout
            exact
            component={BuildSalesInvoiceQueryView}
            layout={MainLayout}
            path="/build-sales-invoice"
          />
          <RouteWithLayout
            exact
            component={DeleteSalesInvoiceQueryView}
            layout={MainLayout}
            path="/delete-sales-invoice"
          />
          <RouteWithLayout
            exact
            component={DisplaySalesInvoiceQueryView}
            layout={MainLayout}
            path="/display-sales-invoice-data"
          />
          <RouteWithLayout
            exact
            component={DisplaySalesInvoiceQuerySFView}
            layout={MainLayout}
            path="/display-sales-invoice-data-sf"
          />
          <RouteWithLayout
            exact
            component={DisplaySalesDataFilterView}
            layout={MainLayout}
            path="/display-sales-invoice"
          />
          <RouteWithLayout
            exact
            component={DisplaySalesDataFilterView}
            layout={MainLayout}
            path="/display-sales-invoice-sf"
          />
          <RouteWithLayout
            exact
            component={IndirectSalesInvoiceQueryListView}
            layout={MainLayout}
            path="/indirect-sales-inovoice-query"
          />
          <RouteWithLayout
            exact
            component={BuildIndirectSalesQueryView}
            layout={MainLayout}
            path="/build-indirect-sales-invoice"
          />
          <RouteWithLayout
            exact
            component={DeleteIndirectSalesQueryView}
            layout={MainLayout}
            path="/delete-indirect-sales-invoice"
          />
          <RouteWithLayout
            exact
            component={DisplayIndirectSalesFilterView}
            layout={MainLayout}
            path="/display-indirect-sales-invoice"
          />
          <RouteWithLayout
            exact
            component={DisplayIndirectSalesInvoiceQuerySFView}
            layout={MainLayout}
            path="/display-indirect-sales-invoice-data-sf"
          />
          <RouteWithLayout
            exact
            component={DisplayIndirectSalesInvoiceQueryView}
            layout={MainLayout}
            path="/display-indirect-sales-invoice-data"
          />
          <RouteWithLayout
            exact
            component={DisplayPurchaseSalesInvoiceQuerySFView}
            layout={MainLayout}
            path="/display-purchase-sales-invoice-data-sf"
          />
          <RouteWithLayout
            exact
            component={DisplayPurchaseSalesInvoiceQueryView}
            layout={MainLayout}
            path="/display-purchase-sales-invoice-data"
          />
          <RouteWithLayout
            exact
            component={TargetInvoiceListView}
            layout={MainLayout}
            path="/target-invoice-query"
          />
          <RouteWithLayout
            exact
            component={TargetInvoiceSearchView}
            layout={MainLayout}
            path="/target-invoice-query-search"
          />
          <RouteWithLayout
            exact
            component={TargetInvoiceQueryListView}
            layout={MainLayout}
            path="/target-invoice-query/excel-upload"
          />
          <RouteWithLayout
            exact
            component={AddTargetInvoiceView}
            layout={MainLayout}
            path="/target-invoice-query/add-commitment-data"
          />
          <RouteWithLayout
            exact
            component={EditTargetInvoiceView}
            layout={MainLayout}
            path="/target-invoice-query/edit-commitment-data/:id"
          />
          <RouteWithLayout
            exact
            component={TargetInvoiceAddQueryView}
            layout={MainLayout}
            path="/target-invoice-query/excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={ViewSalesInvoiceQueryView}
            layout={MainLayout}
            path="/sales-inovoice-query/view/:id"
          />
          <RouteWithLayout
            exact
            component={ViewIndirectSalesInvoiceQueryView}
            layout={MainLayout}
            path="/indirect-sales-inovoice-query/view/:id"
          />
          <RouteWithLayout
            exact
            component={ViewTargetInvoiceQueryView}
            layout={MainLayout}
            path="/target-invoice-query/excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={TargetInvoiceEditQueryView}
            layout={MainLayout}
            path="/target-invoice-query/excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={SalesInvoiceEditQueryView}
            layout={MainLayout}
            path="/sales-inovoice-query/edit/:id"
          />
          <RouteWithLayout
            exact
            component={IndirectSalesInvoiceEditQueryView}
            layout={MainLayout}
            path="/indirect-sales-inovoice-query/edit/:id"
          />
          <RouteWithLayout
            exact
            component={SalesInvoiceAddQueryView}
            layout={MainLayout}
            path="/sales-inovoice-query/add-query"
          />
          <RouteWithLayout
            exact
            component={IndirectSalesInvoiceAddQueryView}
            layout={MainLayout}
            path="/indirect-sales-inovoice-query/add-query"
          />
          <RouteWithLayout
            exact
            component={MaterialMasterAddQueryView}
            layout={MainLayout}
            path="/material-master/material-master-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={MaterialMasterEditQueryView}
            layout={MainLayout}
            path="/material-master/material-master-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={MaterialMasterViewQueryView}
            layout={MainLayout}
            path="/material-master/material-master-excel-upload/view/:id"
          />
          {/* customer master upload */}
          <RouteWithLayout
            exact
            component={CustomerMasterAddQueryView}
            layout={MainLayout}
            path="/customer-master/customer-master-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={CustomerMasterViewQueryView}
            layout={MainLayout}
            path="/customer-master/customer-master-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={CustomerMasterEditQueryView}
            layout={MainLayout}
            path="/customer-master/customer-master-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={CustomerMasterSearchView}
            layout={MainLayout}
            path="/customer-master/search-customer"
          />
          <RouteWithLayout
            exact
            component={DeleteMasterdataView}
            layout={MainLayout}
            path="/delete-master-data"
          />
          <RouteWithLayout
            exact
            component={DeleteExternalFormulaResultView}
            layout={MainLayout}
            path="/delete-external-formula-result"
          />
          {/* customer master xref */}
          <RouteWithLayout
            exact
            component={CustomerMasterXREFExcelUploadView}
            layout={MainLayout}
            path="/customer-x-master/customer-x-master-excel-upload"
          />
          <RouteWithLayout
            exact
            component={CustomerXREFMasterViewQueryView}
            layout={MainLayout}
            path="/customer-x-master/customer-x-master-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={CustomerMasterXREFEditQueryView}
            layout={MainLayout}
            path="/customer-x-master/customer-x-master-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={CustomerMasterXREFAddQueryView}
            layout={MainLayout}
            path="/customer-x-master/customer-x-master-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={ViewPurchaseInvoiceQueryView}
            layout={MainLayout}
            path="/purchase-invoice/view/:id"
          />
          <RouteWithLayout
            exact
            component={PurchaseInvoiceEditQueryView}
            layout={MainLayout}
            path="/purchase-invoice/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PurchaseInvoiceView}
            layout={MainLayout}
            path="/purchase-invoice"
          />
          <RouteWithLayout
            exact
            component={PurchaseInvoiceAddQueryView}
            layout={MainLayout}
            path="/purchase-invoice/add-query"
          />
          <RouteWithLayout
            exact
            component={BuildPurchaseInvoiceView}
            layout={MainLayout}
            path="/build-purchase-invoice"
          />
          <RouteWithLayout
            exact
            component={DeletePurchaseInvoiceView}
            layout={MainLayout}
            path="/delete-purchase-invoice"
          />
          <RouteWithLayout
            exact
            component={DisplayPurchaseInvoiceView}
            layout={MainLayout}
            path="/display-purchase-invoice"
          />
          <RouteWithLayout
            exact
            component={POSView}
            layout={MainLayout}
            path="/pos"
          />
          <RouteWithLayout
            exact
            component={ChannelPartnerSalesView}
            layout={MainLayout}
            path="/channel-partner-sales"
          />
          <RouteWithLayout
            exact
            component={IncomingClaimView}
            layout={MainLayout}
            path="/incoming-claim"
          />
          <RouteWithLayout
            exact
            component={UpcomingFeature}
            layout={MainLayout}
            path="/master-data"
          />
          {/* attributes */}
          <RouteWithLayout
            exact
            component={AttributesHierarchyView}
            layout={MainLayout}
            path="/attributes-hierarchy"
          />
          <RouteWithLayout
            exact
            component={AddAttributesHierarchyView}
            layout={MainLayout}
            path="/attributes-hierarchy/add-attribute-data"
          />
          <RouteWithLayout
            exact
            component={ViewAttributesHierarchyView}
            layout={MainLayout}
            path="/attributes-hierarchy/view-attribute-data/:id"
          />
          <RouteWithLayout
            exact
            component={EditAttributesHierarchyView}
            layout={MainLayout}
            path="/attributes-hierarchy/edit-attribute-data/:id"
          />
          <RouteWithLayout
            exact
            component={AttributesView}
            layout={MainLayout}
            path="/attributes"
          />
          <RouteWithLayout
            exact
            component={AttributesView}
            layout={MainLayout}
            path="/attributes/excel-upload"
          />
          <RouteWithLayout
            exact
            component={AddAttributeView}
            layout={MainLayout}
            path="/attributes/add-attribute-data"
          />
          <RouteWithLayout
            exact
            component={AddAttributeView}
            layout={MainLayout}
            path="/attributes/edit-attribute-data/:id"
          />
          <RouteWithLayout
            exact
            component={CustomerMasterView}
            layout={MainLayout}
            path="/customer-master"
          />
          <RouteWithLayout
            exact
            component={CustomerMasterExcelUploadView}
            layout={MainLayout}
            path="/customer-master/customer-master-excel-upload"
          />
          {/* material x master */}
          <RouteWithLayout
            exact
            component={MaterialXMasterExcelUploadView}
            layout={MainLayout}
            path="/material-x-master/material-x-master-excel-upload"
          />
          <RouteWithLayout
            exact
            component={MaterialXMasterViewQueryView}
            layout={MainLayout}
            path="/material-x-master/material-x-master-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={MaterialXMasterEditQueryView}
            layout={MainLayout}
            path="/material-x-master/material-x-master-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={MaterialXMasterAddQueryView}
            layout={MainLayout}
            path="/material-x-master/material-x-master-excel-upload/add-query"
          />
          {/* supplier master */}
          <RouteWithLayout
            exact
            component={SupplierMasterExcelUploadView}
            layout={MainLayout}
            path="/supplier-master/supplier-master-excel-upload"
          />
          <RouteWithLayout
            exact
            component={SupplierMasterViewQueryView}
            layout={MainLayout}
            path="/supplier-master/supplier-master-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={SupplierMasterEditQueryView}
            layout={MainLayout}
            path="/supplier-master/supplier-master-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={SupplierMasterAddQueryView}
            layout={MainLayout}
            path="/supplier-master/supplier-master-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={SupplierMasterSearchView}
            layout={MainLayout}
            path="/supplier-master/search-supplier"
          />
          {/* membership */}
          <RouteWithLayout
            exact
            component={MembershipExcelUploadView}
            layout={MainLayout}
            path="/membership/membership-excel-upload"
          />
          <RouteWithLayout
            exact
            component={MembershipViewQueryView}
            layout={MainLayout}
            path="/membership/membership-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipEditQueryView}
            layout={MainLayout}
            path="/membership/membership-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipAddQueryView}
            layout={MainLayout}
            path="/membership/membership-excel-upload/add-query"
          />
          {/* sales Bundle */}
          <RouteWithLayout
            exact
            component={BomExcelUploadView}
            layout={MainLayout}
            path="/bom/bom-excel-upload"
          />
          <RouteWithLayout
            exact
            component={BomViewQueryView}
            layout={MainLayout}
            path="/bom/bom-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={BomEditQueryView}
            layout={MainLayout}
            path="/bom/bom-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={BomAddQueryView}
            layout={MainLayout}
            path="/bom/bom-excel-upload/add-query"
          />
          {/* employee */}
          <RouteWithLayout
            exact
            component={EmployeeExcelUploadView}
            layout={MainLayout}
            path="/employee/employee-excel-upload"
          />
          <RouteWithLayout
            exact
            component={EmployeeTerritoryExcelUploadView}
            layout={MainLayout}
            path="/employee/employee-territory-excel-upload"
          />
          <RouteWithLayout
            exact
            component={EmployeeViewQueryView}
            layout={MainLayout}
            path="/employee/employee-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={EmployeeEditQueryView}
            layout={MainLayout}
            path="/employee/employee-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={EmployeeAddQueryView}
            layout={MainLayout}
            path="/employee/employee-excel-upload/add-query"
          />
          {/* customer master XREF */}
          <RouteWithLayout
            exact
            component={CustomerXMasterView}
            layout={MainLayout}
            path="/customer-x-master"
          />
          <RouteWithLayout
            exact
            component={AddCustomerXRefView}
            layout={MainLayout}
            path="/customer-x-master/add-customer-xref"
          />
          <RouteWithLayout
            exact
            component={EditCustomerXRefMasterView}
            layout={MainLayout}
            path="/customer-x-master/edit-customermaster-xref/:id"
          />
          <RouteWithLayout
            exact
            component={ViewCustomerXRefMasterView}
            layout={MainLayout}
            path="/customer-x-master/view-customermaster-xref/:id"
          />
          <RouteWithLayout
            exact
            component={MaterialMasterView}
            layout={MainLayout}
            path="/material-master"
          />
          <RouteWithLayout
            exact
            component={MaterialMasterView}
            layout={MainLayout}
            path="/material-master-promo"
          />
          <RouteWithLayout
            exact
            component={MaterialMasterExcelUploadView}
            layout={MainLayout}
            path="/material-master/material-master-excel-upload"
          />
          <RouteWithLayout
            exact
            component={AnalyticsView}
            layout={MainLayout}
            path="/analytics"
          />
          <RouteWithLayout
            exact
            component={AnalyticsMainView}
            layout={MainLayout}
            path="/analytics-main"
          />
          {/* <RouteWithLayout
                    exact
                    component={ContractGroupSubgroupView}
                    layout={MainLayout}
                    path="/contract-group-subgroup"
                /> */}
          <RouteWithLayout
            exact
            component={Top25View}
            layout={MainLayout}
            path="/top25"
          />
          <RouteWithLayout
            exact
            component={ComparisonView}
            layout={MainLayout}
            path="/analytics-comparison"
          />
          <RouteWithLayout
            exact
            component={IncentiveStatsView}
            layout={MainLayout}
            path="/incentive-stats"
          />
          <RouteWithLayout
            exact
            component={ContractStatsView}
            layout={MainLayout}
            path="/contract-stats"
          />
          <RouteWithLayout
            exact
            component={MonthlyActiveContractsView}
            layout={MainLayout}
            path="/monthly-active-contracts"
          />
          <RouteWithLayout
            exact
            component={IncentiveTrendsView}
            layout={MainLayout}
            path="/incentive-trends"
          />
          <RouteWithLayout
            exact
            component={SalesPaymentComparisonView}
            layout={MainLayout}
            path="/sales-payment-comparison"
          />
          <RouteWithLayout
            exact
            component={GeographicalChartView}
            layout={MainLayout}
            path="/georgraphical-chart"
          />
          <RouteWithLayout
            exact
            component={GeographicalChartUSView}
            layout={MainLayout}
            path="/geographical-chart-us"
          />
          <RouteWithLayout
            exact
            component={VarianceCombinedView}
            layout={MainLayout}
            path="/variance-combined"
          />
          <RouteWithLayout
            exact
            component={AccrualVarianceView}
            layout={MainLayout}
            path="/accrual-variance"
          />
          <RouteWithLayout
            exact
            component={RebateTrendsView}
            layout={MainLayout}
            path="/rebate-trends"
          />
          <RouteWithLayout
            exact
            component={RebateTrendsSecondPageView}
            layout={MainLayout}
            path="/rebate-trends/incentive-trends/:id"
          />
          <RouteWithLayout
            exact
            component={RebateTrendsChartPageView}
            layout={MainLayout}
            path="/rebate-trends/chart-page/:id"
          />
          <RouteWithLayout
            exact
            component={PaymentVarianceView}
            layout={MainLayout}
            path="/payment-variance"
          />
          <RouteWithLayout
            exact
            component={ContractVarianceView}
            layout={MainLayout}
            path="/contract-variance"
          />
          <RouteWithLayout
            exact
            component={ContractVariancePaymentView}
            layout={MainLayout}
            path="/contract-variance-payment"
          />
          <RouteWithLayout
            exact
            component={SoldToPartyVarianceView}
            layout={MainLayout}
            path="/sold-to-party-variance"
          />
          <RouteWithLayout
            exact
            component={SoldToPartyVariancePaymentView}
            layout={MainLayout}
            path="/sold-to-party-variance-payment"
          />
          <RouteWithLayout
            exact
            component={MaterialNumberVarianceView}
            layout={MainLayout}
            path="/material-number-variance"
          />
          <RouteWithLayout
            exact
            component={MaterialNumberVariancePaymentView}
            layout={MainLayout}
            path="/material-number-variance-payment"
          />
          <RouteWithLayout
            exact
            component={financialReportsResultView}
            layout={MainLayout}
            path="/financial-postings-reports/financial-reports"
          />
          <RouteWithLayout
            exact
            component={calculationSimulationReportsResultView}
            layout={MainLayout}
            path="/calculation-simulation-reports/calculation-reports"
          />
          <RouteWithLayout
            exact
            component={invoiceReportsResultView}
            layout={MainLayout}
            path="/invoice-details-reports/invoice-reports"
          />
          <RouteWithLayout
            exact
            component={contractReportsResultView}
            layout={MainLayout}
            path="/contract-reports/contract-reports-results"
          />
          <RouteWithLayout
            exact
            component={TargetReportsView}
            layout={MainLayout}
            path="/target-data-reports"
          />
          <RouteWithLayout
            exact
            component={targetReportsResultView}
            layout={MainLayout}
            path="/target-data-reports/target-reports-results"
          />
          <RouteWithLayout
            exact
            component={RatioKeyFiguresReportsView}
            layout={MainLayout}
            path="/ratio-key-figures-resports"
          />
          <RouteWithLayout
            exact
            component={RationKeyFiguresReportsResultView}
            layout={MainLayout}
            path="/ratio-key-figures-reports-results"
          />
          <RouteWithLayout
            exact
            component={TopNAttributesReportsView}
            layout={MainLayout}
            path="/top-N-attributes-resports"
          />
          <RouteWithLayout
            exact
            component={TopNAttributesReportsResultView}
            layout={MainLayout}
            path="/top-N-attributes-reports-results"
          />
          <RouteWithLayout
            exact
            component={IncentiveVarianceAnalysisReportsView}
            layout={MainLayout}
            path="/incentive-variance-analysis-resports"
          />
          <RouteWithLayout
            exact
            component={IncentiveVarianceAnalysisReportsResultView}
            layout={MainLayout}
            path="/incentive-variance-analysis-reports-results"
          />
          <RouteWithLayout
            exact
            component={DuplicatePaymentReportsView}
            layout={MainLayout}
            path="/duplicate-payment-resports"
          />
          <RouteWithLayout
            exact
            component={DuplicatePaymentReportsResultView}
            layout={MainLayout}
            path="/duplicate-payment-reports-results"
          />
          <RouteWithLayout
            exact
            component={AnamolyReportsView}
            layout={MainLayout}
            path="/anamoly-reports"
          />
          <RouteWithLayout
            exact
            component={AnamolyReportsResultView}
            layout={MainLayout}
            path="/anamoly-reports-results"
          />
          <RouteWithLayout
            exact
            component={ApprovalPostingHistoryReportsView}
            layout={MainLayout}
            path="/approval-posting-history-resports"
          />
          <RouteWithLayout
            exact
            component={ApprovalPostingHistoryReportsResultView}
            layout={MainLayout}
            path="/approval-posting-history-reports-results"
          />
          <RouteWithLayout
            exact
            component={CalculateFormulaValuesReportsView}
            layout={MainLayout}
            path="/calulate-formula-values-resports"
          />
          <RouteWithLayout
            exact
            component={CalculateFormulaValuesReportsResultView}
            layout={MainLayout}
            path="/calulate-formula-values-resports-results"
          />
          <RouteWithLayout
            exact
            component={CalculateFormulaValuesByContractReportsView}
            layout={MainLayout}
            path="/calulate-formula-values-by-contract-resports"
          />
          <RouteWithLayout
            exact
            component={CalculateFormulaValuesByContractReportsResultView}
            layout={MainLayout}
            path="/calulate-formula-values-by-contract-resports-results"
          />
          <RouteWithLayout
            exact
            component={FinancialSummaryReportsTableView}
            layout={MainLayout}
            path="/financial-approvals-reports/result"
          />
          <RouteWithLayout
            exact
            component={contractOverlapReportsResultView}
            layout={MainLayout}
            path="/contract-overlap-reports/contract-overlap-reports"
          />
          <RouteWithLayout
            exact
            component={contractChangeReportsResultView}
            layout={MainLayout}
            path="/contract-change-reports/result"
          />
          <RouteWithLayout
            exact
            component={ProfileView}
            layout={MainLayout}
            path="/profile"
          />
          <RouteWithLayout
            exact
            component={MultipleconfigurationTabView}
            layout={MainLayout}
            path="/multiple-configuration"
          />
          <RouteWithLayout
            exact
            component={SetupSalesBasisTabView}
            layout={MainLayout}
            path="/sales-basis"
          />
          <RouteWithLayout
            exact
            component={AddCustomerView}
            layout={MainLayout}
            path="/customer-master/add-customer"
          />
          <RouteWithLayout
            exact
            component={AddMaterialView}
            layout={MainLayout}
            path="/material-master/add-material"
          />
          <RouteWithLayout
            exact
            component={AddMaterialView}
            layout={MainLayout}
            path="/material-master-promo/add-material"
          />
          {/* customers */}
          <RouteWithLayout
            exact
            component={ViewCustomerMasterView}
            layout={MainLayout}
            path="/customer-master/view-customer/:id"
          />
          <RouteWithLayout
            exact
            component={EditCustomerView}
            layout={MainLayout}
            path="/customer-master/edit-customer/:id"
          />
          {/* materials */}
          <RouteWithLayout
            exact
            component={EditMaterialView}
            layout={MainLayout}
            path="/material-master/edit-material/:id"
          />
          <RouteWithLayout
            exact
            component={EditMaterialView}
            layout={MainLayout}
            path="/material-master-promo/edit-material/:id"
          />
          <RouteWithLayout
            exact
            component={ViewMaterialMasterView}
            layout={MainLayout}
            path="/material-master/view-material/:id"
          />
          <RouteWithLayout
            exact
            component={ViewMaterialMasterView}
            layout={MainLayout}
            path="/material-master-promo/view-material/:id"
          />
          {/* material x master */}
          <RouteWithLayout
            exact
            component={MaterialXMasterView}
            layout={MainLayout}
            path="/material-x-master"
          />
          <RouteWithLayout
            exact
            component={AddMaterialMasterXRefView}
            layout={MainLayout}
            path="/material-x-master/add-materialmaster-xref"
          />
          <RouteWithLayout
            exact
            component={MaterialMasterSearchView}
            layout={MainLayout}
            path="/material-master/search-material"
          />
          <RouteWithLayout
            exact
            component={EditMaterialMasterXRefView}
            layout={MainLayout}
            path="/material-x-master/edit-materialmaster-xref/:id"
          />
          <RouteWithLayout
            exact
            component={ViewMaterialMasterXRefView}
            layout={MainLayout}
            path="/material-x-master/view-materialmaster-xref/:id"
          />
          {/* supplier */}
          <RouteWithLayout
            exact
            component={SupplierMasterView}
            layout={MainLayout}
            path="/supplier-master"
          />
          <RouteWithLayout
            exact
            component={AddSupplierView}
            layout={MainLayout}
            path="/supplier-master/add-supplier"
          />
          <RouteWithLayout
            exact
            component={ViewSupplierMasterView}
            layout={MainLayout}
            path="/supplier-master/view-supplier/:id"
          />
          <RouteWithLayout
            exact
            component={EditSupplierView}
            layout={MainLayout}
            path="/supplier-master/edit-supplier/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipView}
            layout={MainLayout}
            path="/membership"
          />
          <RouteWithLayout
            exact
            component={AddMembershipView}
            layout={MainLayout}
            path="/membership/add-membership"
          />
          <RouteWithLayout
            exact
            component={EditMembershipView}
            layout={MainLayout}
            path="/membership/edit-member/:id"
          />
          <RouteWithLayout
            exact
            component={ViewMembershipView}
            layout={MainLayout}
            path="/membership/view-member/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipHierarchyView}
            layout={MainLayout}
            path="/membership/hierarchy/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipTreeDataView}
            layout={MainLayout}
            path="/membership/membership-tree/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipSecondView}
            layout={MainLayout}
            path="/membership/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipDisplayByMemeberView}
            layout={MainLayout}
            path="/membership-by-member"
          />
          <RouteWithLayout
            exact
            component={ViewMembershipByFilterView}
            layout={MainLayout}
            path="/membership-by-filter"
          />
          <RouteWithLayout
            exact
            component={MasterReportSearchView}
            layout={MainLayout}
            path="/master-report"
          />
          <RouteWithLayout
            exact
            component={MasterReportResultView}
            layout={MainLayout}
            path="/master-report-result"
          />
          <RouteWithLayout
            exact
            component={GPORosterAddressSearchView}
            layout={MainLayout}
            path="/gpo-address-search"
          />
          <RouteWithLayout
            exact
            component={GPORosterAddressSearchResultView}
            layout={MainLayout}
            path="/gpo-address-search-result"
          />
          <RouteWithLayout
            exact
            component={TierActivationAddressSearchView}
            layout={MainLayout}
            path="/tier-address-search"
          />
          <RouteWithLayout
            exact
            component={TierActivationAddressSearchResultView}
            layout={MainLayout}
            path="/tier-address-search-result"
          />
          <RouteWithLayout
            exact
            component={MembershipContractXREFView}
            layout={MainLayout}
            path="/membership-contract"
          />
          <RouteWithLayout
            exact
            component={AddMembershipContractXREFView}
            layout={MainLayout}
            path="/membership-contract/add-contract"
          />
          <RouteWithLayout
            exact
            component={EditMembershipContractXREF}
            layout={MainLayout}
            path="/membership-contract/edit-contract/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipMassUpdateFormView}
            layout={MainLayout}
            path="/mass-membership-update"
          />
          <RouteWithLayout
            exact
            component={MembershipMassUpdateResultView}
            layout={MainLayout}
            path="/mass-membership-update-result"
          />
          {/* designers */}
          <RouteWithLayout
            exact
            component={CalculationDisplayDesignerView}
            layout={MainLayout}
            path="/calculation-display-designer"
          />
          <RouteWithLayout
            exact
            component={CalculationLabelDesignerView}
            layout={MainLayout}
            path="/calculation-label-designer"
          />
          <RouteWithLayout
            exact
            component={FinancialDisplayDesignerView}
            layout={MainLayout}
            path="/financial-display-designer"
          />
          <RouteWithLayout
            exact
            component={FinancialLabelDesignerView}
            layout={MainLayout}
            path="/financial-label-designer"
          />
          <RouteWithLayout
            exact
            component={PaymentDesignerView}
            layout={MainLayout}
            path="/payment-designer"
          />
          <RouteWithLayout
            exact
            component={AccrualDesignerView}
            layout={MainLayout}
            path="/accrual-designer"
          />
          <RouteWithLayout
            exact
            component={HolidayCalendarView}
            layout={MainLayout}
            path="/holiday-calendar"
          />
          <RouteWithLayout
            exact
            component={FinancialCloseView}
            layout={MainLayout}
            path="/financial-close"
          />
          <RouteWithLayout
            exact
            component={FiscalYearView}
            layout={MainLayout}
            path="/fiscal-year-definition"
          />
          <RouteWithLayout
            exact
            component={AddPaymentDesignerView}
            layout={MainLayout}
            path="/payment-designer/add-payment"
          />
          <RouteWithLayout
            exact
            component={EditPaymentDesignerView}
            layout={MainLayout}
            path="/payment-designer/edit-payment/:id"
          />
          <RouteWithLayout
            exact
            component={ViewPaymentDesignerView}
            layout={MainLayout}
            path="/payment-designer/view-payment/:id"
          />
          <RouteWithLayout
            exact
            component={EditAccrualDesignerView}
            layout={MainLayout}
            path="/accrual-designer/edit-accrual/:id"
          />
          {
            <RouteWithLayout
              exact
              component={ViewAccrualDesignerView}
              layout={MainLayout}
              path="/accrual-designer/view-accrual/:id"
            />
          }
          <RouteWithLayout
            exact
            component={AddAccrualDesignerView}
            layout={MainLayout}
            path="/accrual-designer/add-accrual"
          />
          <RouteWithLayout
            exact
            component={BomView}
            layout={MainLayout}
            path="/bom"
          />
          <RouteWithLayout
            exact
            component={AddBomView}
            layout={MainLayout}
            path="/bom/add-bom"
          />
          <RouteWithLayout
            exact
            component={BomTreeView}
            layout={MainLayout}
            path="/bom/bom-tree/:id"
          />
          <RouteWithLayout
            exact
            component={BomHierarchyView}
            layout={MainLayout}
            path="/bom/hierarchy/:id"
          />
          <RouteWithLayout
            exact
            component={EditBomView}
            layout={MainLayout}
            path="/bom/edit-bom/:id"
          />
          <RouteWithLayout
            exact
            component={ViewBomView}
            layout={MainLayout}
            path="/bom/view-bom/:id"
          />
          <RouteWithLayout
            exact
            component={ApprovalGroupView}
            layout={MainLayout}
            path="/approval-group"
          />
          <RouteWithLayout
            exact
            component={CollaborationIDView}
            layout={MainLayout}
            path="/collaboration-id"
          />
          <RouteWithLayout
            exact
            component={OrgDefaultsView}
            layout={MainLayout}
            path="/org-defaults"
          />
          {/* employee master data */}
          <RouteWithLayout
            exact
            component={EmployeeMasterView}
            layout={MainLayout}
            path="/employee"
          />
          <RouteWithLayout
            exact
            component={AddEmployeeMasterView}
            layout={MainLayout}
            path="/employee/add-employee"
          />
          <RouteWithLayout
            exact
            component={ViewEmployeeView}
            layout={MainLayout}
            path="/employee/view-employee/:id"
          />
          <RouteWithLayout
            exact
            component={EditEmployeeView}
            layout={MainLayout}
            path="/employee-master/edit-employee/:id"
          />
          {/* integration studio */}
          <RouteWithLayout
            exact
            component={IntegrationSetupView}
            layout={MainLayout}
            path="/integration-setup"
          />
          <RouteWithLayout
            exact
            component={IntegrationManagerView}
            layout={MainLayout}
            path="/integration-manager"
          />
          <RouteWithLayout
            exact
            component={AddIntegrationSetupView}
            layout={MainLayout}
            path="/integration-setup/add-integration-setup"
          />
          <RouteWithLayout
            exact
            component={KafkaUploadView}
            layout={MainLayout}
            path="/kafka-upload"
          />
          <RouteWithLayout
            exact
            component={MigrationManagementView}
            layout={MainLayout}
            path="/migration-management"
          />
          <RouteWithLayout
            exact
            component={MigrationManagementUploadView}
            layout={MainLayout}
            path="/migration-management-upload"
          />
          <RouteWithLayout
            exact
            component={MigrationManagementDownLoadView}
            layout={MainLayout}
            path="/migration-management-download"
          />
          <RouteWithLayout
            exact
            component={ApplicationLogsView}
            layout={MainLayout}
            path="/application-logs"
          />
          <RouteWithLayout
            exact
            component={UpdatePostingsView}
            layout={MainLayout}
            path='/update-postings'
          />
          <RouteWithLayout
            exact
            component={GenerateMassDataView}
            layout={MainLayout}
            path='/generate_mass_data'
          />
          <RouteWithLayout
            exact
            component={IntegrationFilterView}
            layout={MainLayout}
            path="/integration-filter"
          />
          <RouteWithLayout
            exact
            component={AddIntegrationFilterView}
            layout={MainLayout}
            path="/integration-filter/add-integration-filter"
          />
          <RouteWithLayout
            exact
            component={TreeTableView}
            layout={MainLayout}
            path="/terittory"
          />
          <RouteWithLayout
            exact
            component={TreeView}
            layout={MainLayout}
            path="/terittory/add-terittory"
          />
          <RouteWithLayout
            exact
            component={TerritoryTreeView}
            layout={MainLayout}
            path="/terittory/view-terittory/:id"
          />
          <RouteWithLayout
            exact
            component={EditTerritoryView}
            layout={MainLayout}
            path="/terittory/edit-terittory/:id"
          />
          <RouteWithLayout
            exact
            component={TerritoryExcelUploadView}
            layout={MainLayout}
            path="/territory/territory-excel-upload"
          />
          <RouteWithLayout
            exact
            component={TerritoryAddQueryView}
            layout={MainLayout}
            path="/territory/territory-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={TerritoryEditQueryView}
            layout={MainLayout}
            path="/territory/territory-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={TerritoryViewQueryView}
            layout={MainLayout}
            path="/territory/territory-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={SupplierIncomingClaimsView}
            layout={MainLayout}
            path="/supplier-incoming-claims"
          />
          <RouteWithLayout
            exact
            component={SupplierIncomingAddClaimsView}
            layout={MainLayout}
            path="/supplier-incoming-claims-upload/add-claim"
          />
          <RouteWithLayout
            exact
            component={EditIncomingClaimsView}
            layout={MainLayout}
            path="/supplier-incoming-claims/edit-claims/:id"
          />
          <RouteWithLayout
            exact
            component={ViewIncomingClaimsView}
            layout={MainLayout}
            path="/supplier-incoming-claims-upload/view-claims/:id"
          />
          <RouteWithLayout
            exact
            component={EditIncomingClaimsQueryView}
            layout={MainLayout}
            path="/supplier-incoming-claims-upload/edit-claims-query/:id"
          />
          <RouteWithLayout
            exact
            component={MultipleClaimsFormView}
            layout={MainLayout}
            path="/supplier-multiple-claims"
          />
          <RouteWithLayout
            exact
            component={MultipleClaimsResultView}
            layout={MainLayout}
            path="/supplier-multiple-claims-result"
          />
          <RouteWithLayout
            exact
            component={EditOutgoingClaimsView}
            layout={MainLayout}
            path="/customer-outgoing-claims/edit-claims/:id"
          />
          <RouteWithLayout
            exact
            component={ViewOutgoingClaimsView}
            layout={MainLayout}
            path="/customer-outgoing-claims/view-claims/:id"
          />
          <RouteWithLayout
            exact
            component={DisputedClaimFormView}
            layout={MainLayout}
            path="/customer-outgoing-disputed-claims"
          />
          <RouteWithLayout
            exact
            component={DisputedClaimResultView}
            layout={MainLayout}
            path="/customer-outgoing-disputed-claims-result"
          />
          <RouteWithLayout
            exact
            component={ClaimSubmissionsView}
            layout={MainLayout}
            path="/claim-submissions"
          />
          <RouteWithLayout
            exact
            component={ClaimSubmissionsResultView}
            layout={MainLayout}
            path="/claims-submissions-result"
          />
          <RouteWithLayout
            exact
            component={OutgoingPaymentPostingFormView}
            layout={MainLayout}
            path="/outgoing-claims-payment-postings"
          />
          <RouteWithLayout
            exact
            component={OutgoingAccrualPostingFormView}
            layout={MainLayout}
            path="/outgoing-claims-accrual-postings"
          />
          <RouteWithLayout
            exact
            component={SupplierIncomingClaimsUploadView}
            layout={MainLayout}
            path="/supplier-incoming-claims-upload"
          />
          <RouteWithLayout
            exact
            component={CustomerOutgoingClaimsView}
            layout={MainLayout}
            path="/customer-outgoing-claims"
          />
          <RouteWithLayout
            exact
            component={CustomerOutgoingClaimsPostView}
            layout={MainLayout}
            path="/outgoing-claims-post"
          />
          <RouteWithLayout
            exact
            component={supplierChargebackClaimsStatus}
            layout={MainLayout}
            path="/supplierChargeback-claim-status"
          />
          <RouteWithLayout
            exact
            component={CustomerIncomingClaimsUploadView}
            layout={MainLayout}
            path="/customer-incoming-claims-upload"
          />
          <RouteWithLayout
            exact
            component={CustomerOutgoingClaimsUploadView}
            layout={MainLayout}
            path="/customer-outgoing-claims-upload"
          />
          <RouteWithLayout
            exact
            component={ContractReportsView}
            layout={MainLayout}
            path="/contract-reports"
          />
          <RouteWithLayout
            exact
            component={SelectOperationalReportsView}
            layout={MainLayout}
            path="/select-operational-reports"
          />
          <RouteWithLayout
            exact
            component={SelectOperationalTargetReportsView}
            layout={MainLayout}
            path="/select-operational-reports/target-reports"
          />
          <RouteWithLayout
            exact
            component={ContractNumberVarianceReportsView}
            layout={MainLayout}
            path="/select-operational-reports/contract-number-variance-reports"
          />
          <RouteWithLayout
            exact
            component={ContractOverlapReportsView}
            layout={MainLayout}
            path="/contract-overlap-reports"
          />
          <RouteWithLayout
            exact
            component={ContractChangeReportsView}
            layout={MainLayout}
            path="/contract-change-reports"
          />
          <RouteWithLayout
            exact
            component={CalculationSimulationReportsView}
            layout={MainLayout}
            path="/calculation-simulation-reports"
          />
          <RouteWithLayout
            exact
            component={FinancialPostingsReportsView}
            layout={MainLayout}
            path="/financial-postings-reports"
          />
          <RouteWithLayout
            exact
            component={InvoiceDetailsReportsView}
            layout={MainLayout}
            path="/invoice-details-reports"
          />
          {/* dynamic query starts */}
          <RouteWithLayout
            exact
            component={DynamicQueryView}
            layout={MainLayout}
            path="/dynamic-query"
          />
          <RouteWithLayout
            exact
            component={DynamicAnalyticsView}
            layout={MainLayout}
            path="/on-demand-analytics"
          />
          <RouteWithLayout
            exact
            component={AddDynamicQueryView}
            layout={MainLayout}
            path="/dynamic-query/add-dynamic-query"
          />
          <RouteWithLayout
            exact
            component={EditDynamicQueryView}
            layout={MainLayout}
            path="/dynamic-query/edit-dynamic-query/:id"
          />
          <RouteWithLayout
            exact
            component={AddOnDemandAnalyticsView}
            layout={MainLayout}
            path="/on-demand-analytics/add-on-demand-analytics"
          />
          <RouteWithLayout
            exact
            component={EditOnDemandAnalyticsView}
            layout={MainLayout}
            path="/on-demand-analytics/edit-on-demand-analytics/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/dynamic-query/view-dynamic-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/dynamic-query/re-arrange-dynamic-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewOnDemandAnalyticsView}
            layout={MainLayout}
            path="/on-demand-analytics/view-on-demand-analytics/:id"
          />
          <RouteWithLayout
            exact
            component={OnDemandAnalyticsFormView}
            layout={MainLayout}
            path="/on-demand-analytics/on-demand-analytics-form"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryFormView}
            layout={MainLayout}
            path="/dynamic-query/dynamic-query-form"
          />
          {/* <RouteWithLayout
          exact
          component={DynamicQueryFormView}
          layout={MainLayout}
          path="/dynamic-query/dynamic-query-form-sf"
        /> */}
          <RouteWithLayout
            exact
            component={DynamicQueryResultsView}
            layout={MainLayout}
            path="/dynamic-query/dynamic-query-form/results/:id"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryResultsSFView}
            layout={MainLayout}
            path="/dynamic-query/dynamic-query-form-sf/results/:id"
          />
          <RouteWithLayout
            exact
            component={OnDemandAnalyticsResultsView}
            layout={MainLayout}
            path="/on-demand-analytics/on-demand-analytics-form/results/:id"
          />
          <RouteWithLayout
            exact
            component={FormulaLabMainView}
            layout={MainLayout}
            path="/formula-lab"
          />
          <RouteWithLayout
            exact
            component={QueryFieldMainView}
            layout={MainLayout}
            path="/query-field"
          />
          <RouteWithLayout
            exact
            component={ExpiringContractsView}
            layout={MainLayout}
            path="/expiring-contracts"
          />
          <RouteWithLayout
            exact
            component={ExpiredContractsView}
            layout={MainLayout}
            path="/expired-contracts"
          />
          <RouteWithLayout
            exact
            component={AddFormulaLabView}
            layout={MainLayout}
            path="/formula-lab/add-formula-lab"
          />
          <RouteWithLayout
            exact
            component={EditFormulaLabView}
            layout={MainLayout}
            path="/formula-lab/edit-formula-lab/:id"
          />
          <RouteWithLayout
            exact
            component={ViewFormulaLabView}
            layout={MainLayout}
            path="/formula-lab/view-formula-lab/:id"
          />
          <RouteWithLayout
            exact
            component={AddQueryFieldView}
            layout={MainLayout}
            path="/query-field/add-query-field"
          />
          <RouteWithLayout
            exact
            component={EditQueryFieldView}
            layout={MainLayout}
            path="/query-field/edit-query-field/:id"
          />
          <RouteWithLayout
            exact
            component={ViewQueryFieldView}
            layout={MainLayout}
            path="/query-field/view-query-field/:id"
          />
          <RouteWithLayout
            exact
            component={CollaborationDesignerMainView}
            layout={MainLayout}
            path="/collaboration-designer"
          />
          <RouteWithLayout
            exact
            component={AddCollaborationDesignerView}
            layout={MainLayout}
            path="/collaboration-designer/add-collaboration"
          />
          <RouteWithLayout
            exact
            component={EditCollaborationDesignerView}
            layout={MainLayout}
            path="/collaboration-designer/edit-collaboration/:id"
          />
          <RouteWithLayout
            exact
            component={ViewCollaborationDesignerView}
            layout={MainLayout}
            path="/collaboration-designer/view-collaboration/:id"
          />
          <RouteWithLayout
            exact
            component={ApprovalDesignerMainView}
            layout={MainLayout}
            path="/approval-limits"
          />
          {/*predefined query*/}
          <RouteWithLayout
            exact
            component={PredefinedQueryView}
            layout={MainLayout}
            path="/predefined-query"
          />
          <RouteWithLayout
            exact
            component={PredefinedQueryResultsView}
            layout={MainLayout}
            path="/predefined-query/predefined-query-form/results/:id"
          />
          {/* approval designer */}
          <RouteWithLayout
            exact
            component={AddApprovalDesignerView}
            layout={MainLayout}
            path="/approval-limits/add-approval"
          />
          <RouteWithLayout
            exact
            component={EditApprovalDesignerView}
            layout={MainLayout}
            path="/approval-limits/edit-approval/:id"
          />
          <RouteWithLayout
            exact
            component={ViewApprovalDesignerView}
            layout={MainLayout}
            path="/approval-limits/view-approval/:id"
          />
          {/* contract default designer */}
          <RouteWithLayout
            exact
            component={ContractDefaultDesignerMainView}
            layout={MainLayout}
            path="/contract-default-designer"
          />
          <RouteWithLayout
            exact
            component={AddContractDefaultDesignerView}
            layout={MainLayout}
            path="/contract-default-designer/add-contract-default"
          />
          <RouteWithLayout
            exact
            component={EditContractDefaultDesignerView}
            layout={MainLayout}
            path="/contract-default-designer/edit-contract-default/:id"
          />
          <RouteWithLayout
            exact
            component={ViewContractDefaultDesignerView}
            layout={MainLayout}
            path="/contract-default-designer/view-contract-default/:id"
          />
          {/* <RouteWithLayout
          exact
          component={LabelDesignerView}
          layout={MainLayout}
          path="/label-designer"
        /> */}
          <RouteWithLayout
            exact
            component={LabelDisplayDesignerView}
            layout={MainLayout}
            path="/label-display-designer"
          />
          <RouteWithLayout
            exact
            component={ContractAttributesView}
            layout={MainLayout}
            path="/contract-attributes"
          />
          <RouteWithLayout
            exact
            component={MandatoryEligibilityRulesView}
            layout={MainLayout}
            path="/mandatory-eligibility-rules"
          />
          <RouteWithLayout
            exact
            component={ConfigDesignerView}
            layout={MainLayout}
            path="/config-designer"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryView}
            layout={MainLayout}
            path="/collaboration-portal"
          />
          <RouteWithLayout
            exact
            component={AddDynamicQueryView}
            layout={MainLayout}
            path="/collaboration-portal/add-collaboration-portal"
          />
          <RouteWithLayout
            exact
            component={EditDynamicQueryView}
            layout={MainLayout}
            path="/collaboration-portal/edit-collaboration-portal/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/collaboration-portal/view-collaboration-portal/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDynamicQueryView}
            layout={MainLayout}
            path="/collaboration-portal/re-arrange-collaboration-portal/:id"
          />
          {/* collaboration portal */}
          <RouteWithLayout
            exact
            component={DynamicQueryFormView}
            layout={MainLayout}
            path="/collaboration-portal/collaboration-portal-form"
          />
          <RouteWithLayout
            exact
            component={DynamicQueryResultsView}
            layout={MainLayout}
            path="/collaboration-portal/collaboration-portal-form/results/:id"
          />
          <RouteWithLayout
            exact
            component={AddSalesBasisView}
            layout={MainLayout}
            path="/sales-basis/add-sales-basis"
          />
          <RouteWithLayout
            exact
            component={EditSalesBasisView}
            layout={MainLayout}
            path="/sales-basis/edit-sales-basis/:id"
          />
          <RouteWithLayout
            exact
            component={SalesBasisView}
            layout={MainLayout}
            path="/sales-basis/view-global-eligiblilty/:id"
          />
          {/* incoming claims new ui */}
          <RouteWithLayout
            exact
            component={OutgoingClaimsUploadNewView}
            layout={MainLayout}
            path="/customer-outgoing-claims-upload-new"
          />
          <RouteWithLayout
            exact
            component={CustomerOutgoingAddClaimsView}
            layout={MainLayout}
            path="/customer-outgoing-claims-upload-new/add-claim"
          />
          <RouteWithLayout
            exact
            component={OutgoingClaimsEditQueryView}
            layout={MainLayout}
            path="/customer-outgoing-claims-upload-new/edit-claims-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewOutgoingClaimsQueryView}
            layout={MainLayout}
            path="/customer-outgoing-claims-upload-new/view-claims/:id"
          />
          <RouteWithLayout
            exact
            component={BatchJobSetupView}
            layout={MainLayout}
            path="/batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={BatchJobStatusView}
            layout={MainLayout}
            path="/batchjob-status"
          />
          <RouteWithLayout
            exact
            component={PromoBatchJobStatusView}
            layout={MainLayout}
            path="/promo-batchjob-status"
          />
          <RouteWithLayout
            exact
            component={CostingDownloadFileView}
            layout={MainLayout}
            path="/costing-download"
          />
          <RouteWithLayout
            exact
            component={CostingListScreenView}
            layout={MainLayout}
            path="/costing-list-screen"
          />
          <RouteWithLayout
            exact
            component={AddBatchJobSetupView}
            layout={MainLayout}
            path="/batchjob-setup/add-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={EditBatchJobSetupView}
            layout={MainLayout}
            path="/batchjob-setup/edit-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={ViewBatchJobSetupView}
            layout={MainLayout}
            path="/batchjob-setup/view-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={AddCCBatchJobSetupView}
            layout={MainLayout}
            path="/add-batchjob-setup-customer-chargeback"
          />
          <RouteWithLayout
            exact
            component={EditCCBatchJobSetupView}
            layout={MainLayout}
            path="/edit-batchjob-setup-customer-chargeback/:id"
          />
          <RouteWithLayout
            exact
            component={ViewCCBatchJobSetupView}
            layout={MainLayout}
            path="/view-batchjob-setup-customer-chargeback/:id"
          />
          {/* <RouteWithLayout
          exact
          component={CommonDisplayDesignerView}
          layout={MainLayout}
          path="/common-display-designer"
        /> */}
          {/* Exception maagement */}
          <RouteWithLayout
            exact
            component={ExceptionManagementView}
            layout={MainLayout}
            path="/exception-management"
          />
          <RouteWithLayout
            exact
            component={AddExceptionManagementView}
            layout={MainLayout}
            path="/exception-management/add"
          />
          <RouteWithLayout
            exact
            component={EditExceptionManagementView}
            layout={MainLayout}
            path="/exception-management/edit/:id"
          />
          <RouteWithLayout
            exact
            component={ViewsExceptionManagementView}
            layout={MainLayout}
            path="/exception-management/view/:id"
          />
          <RouteWithLayout
            exact
            component={ExceptionManagementAuditMain}
            layout={MainLayout}
            path="/exception-management-view"
          />
          <RouteWithLayout
            exact
            component={AccrualApprovalsView}
            layout={MainLayout}
            path="/accrual-approvals/accrual-reports"
          />
          <RouteWithLayout
            exact
            component={PostingFilterTabView}
            layout={MainLayout}
            path="/accrual-approvals"
          />
          <RouteWithLayout
            exact
            component={PostingFilterTabView}
            layout={MainLayout}
            path="/payment-approvals"
          />
          <RouteWithLayout
            exact
            component={PaymentApprovalsView}
            layout={MainLayout}
            path="/payment-approvals/payment-reports"
          />
          <RouteWithLayout
            exact
            component={KeyFigureMainView}
            layout={MainLayout}
            path="/key-figure-mapping"
          />
          <RouteWithLayout
            exact
            component={AddKeyFigureView}
            layout={MainLayout}
            path="/key-figure-mapping/add-key-figure"
          />
          <RouteWithLayout
            exact
            component={EditKeyFigureView}
            layout={MainLayout}
            path="/key-figure-mapping/edit-key-figure/:id"
          />
          <RouteWithLayout
            exact
            component={ViewKeyFigureView}
            layout={MainLayout}
            path="/key-figure-mapping/view-key-figure/:id"
          />
          <RouteWithLayout
            exact
            component={ContractTrendsView}
            layout={MainLayout}
            path="/contract-trends"
          />
          <RouteWithLayout
            exact
            component={ContractTrendsResultView}
            layout={MainLayout}
            path="/contract-trends/contract-trends-result"
          />
          <RouteWithLayout
            exact
            component={ContractTrendsResultSFView}
            layout={MainLayout}
            path="/contract-trends/contract-trends-result-sf"
          />
          <RouteWithLayout
            exact
            component={ContractTrendsCPView}
            layout={MainLayout}
            path="/contract-trends-cp"
          />
          <RouteWithLayout
            exact
            component={FPHistoryView}
            layout={MainLayout}
            path="/contract-setup/fp-history/:id"
          />
          <RouteWithLayout
            exact
            component={AddCalculationAnalysisView}
            layout={MainLayout}
            path="/add-calculation-analysis"
          />
          <RouteWithLayout
            exact
            component={EditCalculationAnalysisView}
            layout={MainLayout}
            path="/edit-calculation-analysis/:id"
          />
          <RouteWithLayout
            exact
            component={ViewCalculationAnalysisView}
            layout={MainLayout}
            path="/view-calculation-analysis/:id"
          />
          <RouteWithLayout
            exact
            component={CalculationAnalysisView}
            layout={MainLayout}
            path="/calculation-analysis"
          />
          {/*product group */}
          <RouteWithLayout
            exact
            component={ProductGroupView}
            layout={MainLayout}
            path="/product-group"
          />
          <RouteWithLayout
            exact
            component={AddProductGroupView}
            layout={MainLayout}
            path="/product-group/add-product"
          />
          <RouteWithLayout
            exact
            component={EditProductGroupView}
            layout={MainLayout}
            path="/product-group/edit-product/:id"
          />
          <RouteWithLayout
            exact
            component={ViewProductGroupView}
            layout={MainLayout}
            path="/product-group/view-product/:id"
          />
          <RouteWithLayout
            exact
            component={ProductGroupExcelUploadView}
            layout={MainLayout}
            path="/product-group/product-group-excel-upload"
          />
          <RouteWithLayout
            exact
            component={ProductGroupViewQueryView}
            layout={MainLayout}
            path="/product-group/product-group-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={ProductGroupEditQueryView}
            layout={MainLayout}
            path="/product-group/Product-group-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={ProductGroupAddQueryView}
            layout={MainLayout}
            path="/product-group/Product-group-excel-upload/add-query"
          />
          {/* Contract XREF */}
          <RouteWithLayout
            exact
            component={ContractXREFView}
            layout={MainLayout}
            path="/contract-xref"
          />
          <RouteWithLayout
            exact
            component={AddContractXREFView}
            layout={MainLayout}
            path="/contract-xref/add-contract"
          />
          <RouteWithLayout
            exact
            component={EditContractXREFView}
            layout={MainLayout}
            path="/contract-xref/edit-contract/:id"
          />
          <RouteWithLayout
            exact
            component={ViewContractXREFView}
            layout={MainLayout}
            path="/contract-xref/view-contract/:id"
          />
          {/* <RouteWithLayout
                    exact
                    component={AddProductGroupView}
                    layout={MainLayout}
                    path="/product-group/add-product"
                />
                <RouteWithLayout
                    exact
                    component={EditProductGroupView}
                    layout={MainLayout}
                    path="/product-group/edit-product/:id"
                />
                <RouteWithLayout
                    exact
                    component={ViewProductGroupView}
                    layout={MainLayout}
                    path="/product-group/view-product/:id"
                />
                <RouteWithLayout
                    exact
                    component={ProductGroupExcelUploadView}
                    layout={MainLayout}
                    path="/product-group/product-group-excel-upload"
                />
                <RouteWithLayout
                    exact
                    component={ProductGroupViewQueryView}
                    layout={MainLayout}
                    path="/product-group/product-group-excel-upload/view/:id"
                />
                <RouteWithLayout
                    exact
                    component={ProductGroupEditQueryView}
                    layout={MainLayout}
                    path="/product-group/Product-group-excel-upload/edit/:id"
                />
                <RouteWithLayout
                    exact
                    component={ProductGroupAddQueryView}
                    layout={MainLayout}
                    path="/product-group/Product-group-excel-upload/add-query"
                /> */}
          <RouteWithLayout
            exact
            component={AttributeMappingMainView}
            layout={MainLayout}
            path="/attribute-mapping"
          />
          <RouteWithLayout
            exact
            component={AddAttributeMappingView}
            layout={MainLayout}
            path="/attribute-mapping/add-attribute"
          />
          <RouteWithLayout
            exact
            component={EditAttributeMappingView}
            layout={MainLayout}
            path="/attribute-mapping/edit-attribute/:id"
          />
          <RouteWithLayout
            exact
            component={ViewAttributeMappingView}
            layout={MainLayout}
            path="/attribute-mapping/view-attribute/:id"
          />
          {/*basis mapping */}
          <RouteWithLayout
            exact
            component={BasisMappingMainView}
            layout={MainLayout}
            path="/basis-mapping"
          />
          <RouteWithLayout
            exact
            component={AddBasisMappingView}
            layout={MainLayout}
            path="/basis-mapping/add-basis"
          />
          <RouteWithLayout
            exact
            component={EditBasisMappingView}
            layout={MainLayout}
            path="/basis-mapping/edit-basis/:id"
          />
          <RouteWithLayout
            exact
            component={ViewBasisMappingView}
            layout={MainLayout}
            path="/basis-mapping/view-basis/:id"
          />
          {/*quota */}
          <RouteWithLayout
            exact
            component={QuotaManagementMainView}
            layout={MainLayout}
            path="/quota-management"
          />
          <RouteWithLayout
            exact
            component={AddQuotaView}
            layout={MainLayout}
            path="/quota-management/add-quota"
          />
          <RouteWithLayout
            exact
            component={EditQuotaView}
            layout={MainLayout}
            path="/quota-management/edit-quota/:id"
          />

          <RouteWithLayout
            exact
            component={ViewQuotaView}
            layout={MainLayout}
            path="/quota-management/view-quota/:id"
          />


          <RouteWithLayout
            exact
            component={QuotaManagementExcelUploadView}
            layout={MainLayout}
            path="/quota-management/quota-management-excel-upload"
          />
          <RouteWithLayout
            exact
            component={QuotaManagementViewQueryView}
            layout={MainLayout}
            path="/quota-management/quota-management-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={QuotaManagementEditQueryView}
            layout={MainLayout}
            path="/quota-management/quota-management-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={QuotaManagementAddQueryView}
            layout={MainLayout}
            path="/quota-management/quota-management-excel-upload/add-query"
          />
          {/* External Formula Results */}
          <RouteWithLayout
            exact
            component={ExternalFormulaResultsMainView}
            layout={MainLayout}
            path="/external-formula-results"
          />
          <RouteWithLayout
            exact
            component={AddExternalFormulaView}
            layout={MainLayout}
            path="/external-formula-results/add-external"
          />
          <RouteWithLayout
            exact
            component={EditExternalFormulaView}
            layout={MainLayout}
            path="/external-formula-results/edit-external/:id"
          />
          <RouteWithLayout
            exact
            component={ViewExternalFormulaView}
            layout={MainLayout}
            path="/external-formula-results/view-external/:id"
          />
          <RouteWithLayout
            exact
            component={ExternalFormulaResultsExcelUploadView}
            layout={MainLayout}
            path="/external-formula-results/external-formula-results-excel-upload"
          />
          <RouteWithLayout
            exact
            component={ExternalFormulaResultsViewQueryView}
            layout={MainLayout}
            path="/external-formula-results/external-formula-results-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={ExternalFormulaResultsEditQueryView}
            layout={MainLayout}
            path="/external-formula-results/external-formula-results-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={ExternalFormulaResultsAddQueryView}
            layout={MainLayout}
            path="/external-formula-results/external-formula-results-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={ExternalFormulaResultsSearchView}
            layout={MainLayout}
            path="/external-formula-search"
          />
          {/*performance metric */}
          <RouteWithLayout
            exact
            component={PerformanceMetricMainView}
            layout={MainLayout}
            path="/performance-metric"
          />
          <RouteWithLayout
            exact
            component={AddPerformanceMetricView}
            layout={MainLayout}
            path="/performance-metric/add-performance-metric"
          />
          <RouteWithLayout
            exact
            component={EditPerformanceMetricView}
            layout={MainLayout}
            path="/performance-metric/edit-performance-metric/:id"
          />
          {/*<RouteWithLayout
                    exact
                    component={ViewPerformanceMetricView}
                    layout={MainLayout}
                    path="/performance-metric/view-performance-metric/:id"
                />*/}
          <RouteWithLayout
            exact
            component={PerformanceMetricExcelUploadView}
            layout={MainLayout}
            path="/performance-metric/performance-metric-excel-upload"
          />
          <RouteWithLayout
            exact
            component={PerformanceMetricViewQueryView}
            layout={MainLayout}
            path="/performance-metric/performance-metric-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PerformanceMetricEditQueryView}
            layout={MainLayout}
            path="/performance-metric/performance-metric-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PerformanceMetricAddQueryView}
            layout={MainLayout}
            path="/performance-metric/performance-metric-excel-upload/add-query"
          />
          {/*performance metric Weightage */}
          <RouteWithLayout
            exact
            component={PerformanceMetricWeightageMainView}
            layout={MainLayout}
            path="/performance-metric-weightage"
          />
          <RouteWithLayout
            exact
            component={AddPerformanceMetricWeightageView}
            layout={MainLayout}
            path="/performance-metric-weightage/add-performance-metric-weightage"
          />
          <RouteWithLayout
            exact
            component={EditPerformanceMetricWeightageView}
            layout={MainLayout}
            path="/performance-metric-weightage/edit-performance-metric-weightage/:id"
          />
          {/*<RouteWithLayout
                    exact
                    component={ViewPerformanceMetricWeightageView}
                    layout={MainLayout}
                    path="/performance-metricWeightage/view-performance-metricWeightage/:id"
                />*/}
          <RouteWithLayout
            exact
            component={PerformanceMetricWeightageExcelUploadView}
            layout={MainLayout}
            path="/performance-metric-weightage/performance-metric-weightage-excel-upload"
          />
          <RouteWithLayout
            exact
            component={PerformanceMetricWeightageViewQueryView}
            layout={MainLayout}
            path="/performance-metric-weightage/performance-metric-weightage-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PerformanceMetricWeightageEditQueryView}
            layout={MainLayout}
            path="/performance-metric-weightage/performance-metric-weightage-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PerformanceMetricWeightageAddQueryView}
            layout={MainLayout}
            path="/performance-metric-weightage/performance-metric-weightage-excel-upload/add-query"
          />
          {/*uom*/}
          <RouteWithLayout
            exact
            component={UoMMainView}
            layout={MainLayout}
            path="/uom"
          />
          <RouteWithLayout
            exact
            component={AddUoMView}
            layout={MainLayout}
            path="/uom/add-uom"
          />
          <RouteWithLayout
            exact
            component={EditUoMView}
            layout={MainLayout}
            path="/uom/edit-uom/:id"
          />
          {/*product feature*/}
          <RouteWithLayout
            exact
            component={ProductFeatureMainView}
            layout={MainLayout}
            path="/product-feature"
          />
          <RouteWithLayout
            exact
            component={AddProductFeatureView}
            layout={MainLayout}
            path="/product-feature/add-product-feature"
          />
          <RouteWithLayout
            exact
            component={EditProductFeatureView}
            layout={MainLayout}
            path="/product-feature/edit-product-feature/:id"
          />
          <RouteWithLayout
            exact
            component={CurrencyConversionView}
            layout={MainLayout}
            path="/currency-conversion"
          />
          <RouteWithLayout
            exact
            component={PromotionFormView}
            layout={MainLayout}
            path="/promotion-trends"
          />
          <RouteWithLayout
            exact
            component={PromotionResultView}
            layout={MainLayout}
            path="/promotion-trends-results"
          />
          {/* External Inputs */}
          <RouteWithLayout
            exact
            component={FinanceCostView}
            layout={MainLayout}
            path="/finance-cost"
          />
          <RouteWithLayout
            exact
            component={FinanceCostEditView}
            layout={MainLayout}
            path="/finance-cost/edit-finance-cost/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionTerritoryEditView}
            layout={MainLayout}
            path="/promotion-territory/edit-promotion-territory/:id"
          />
          <RouteWithLayout
            exact
            component={FinanceCostExcelUploadView}
            layout={MainLayout}
            path="/finance-cost/finance-cost-excel-upload"
          />
          <RouteWithLayout
            exact
            component={FinanceCostViewQueryView}
            layout={MainLayout}
            path="/finance-cost/finance-cost-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={FinanceCostEditQueryView}
            layout={MainLayout}
            path="/finance-cost/finance-cost-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={FinanceCostAddQueryView}
            layout={MainLayout}
            path="/finance-cost/finance-cost-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={ViewFinanceCostView}
            layout={MainLayout}
            path="/finance-cost/view-finance-cost/:id"
          />
          <RouteWithLayout
            exact
            component={StandardFinanceView}
            layout={MainLayout}
            path="/standard-finance-cost"
          />
          <RouteWithLayout
            exact
            component={StandardFinanceExcelUploadView}
            layout={MainLayout}
            path="/standard-finance-cost/excel-upload"
          />
          <RouteWithLayout
            exact
            component={StandardFinanceViewQueryView}
            layout={MainLayout}
            path="/standard-finance-cost/excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={StandardFinanceEditQueryView}
            layout={MainLayout}
            path="/standard-finance-cost/excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={StandardFinanceAddQueryView}
            layout={MainLayout}
            path="/standard-finance-cost/excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={StandardFinanceEditView}
            layout={MainLayout}
            path="/standard-finance-cost/edit-standard-finance/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionTerritoryView}
            layout={MainLayout}
            path="/promotion-territory"
          />
          <RouteWithLayout
            exact
            component={PromotionTerritoryExcelUploadView}
            layout={MainLayout}
            path="/promotion-territory/promotion-territory-excel-upload"
          />
          <RouteWithLayout
            exact
            component={PromotionTerritoryViewQueryView}
            layout={MainLayout}
            path="/promotion-territory/promotion-territory-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionTerritoryEditQueryView}
            layout={MainLayout}
            path="/promotion-territory/promotion-territory-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionTerritoryAddQueryView}
            layout={MainLayout}
            path="/promotion-territory/promotion-territory-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={SupplierIncomingClaimFormatAddView}
            layout={MainLayout}
            path="/supplier-incoming-claim-format/add-claims-query"
          />
          <RouteWithLayout
            exact
            component={SupplierIncomingClaimFormatEditView}
            layout={MainLayout}
            path="/supplier-incoming-claim-format/edit-claims-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewIncomingClaimFormatView}
            layout={MainLayout}
            path="/supplier-incoming-claim-format/view-claims-query/:id"
          />
          <RouteWithLayout
            exact
            component={IncomingClaimFormatView}
            layout={MainLayout}
            path="/supplier-incoming-claim-format"
          />
          <RouteWithLayout
            exact
            component={CustomerOutgoingClaimDownloadFormatAddView}
            layout={MainLayout}
            path="/customer-outgoing-claim-download-format/add-query"
          />
          <RouteWithLayout
            exact
            component={CustomerOutgoingClaimDownloadFormatEditView}
            layout={MainLayout}
            path="/customer-outgoing-claim-download-format/edit-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewOutgoingClaimDownloadFormatView}
            layout={MainLayout}
            path="/customer-outgoing-claim-download-format/view-query/:id"
          />
          <RouteWithLayout
            exact
            component={OutgoingClaimDownloadFormatView}
            layout={MainLayout}
            path="/customer-outgoing-claim-download-format"
          />
          <RouteWithLayout
            exact
            component={EdiMappingsView}
            layout={MainLayout}
            path="/edi-mappings"
          />
          <RouteWithLayout
            exact
            component={EdiMappingAddView}
            layout={MainLayout}
            path="/edi-mappings-add"
          />
          <RouteWithLayout
            exact
            component={EdiMappingEditView}
            layout={MainLayout}
            path="/edi-mappings-edit/:id"
          />
          <RouteWithLayout
            exact
            component={EdiMappingViewView}
            layout={MainLayout}
            path="/edi-mappings-view/:id"
          />
          <RouteWithLayout
            exact
            component={ContractMassUploadView}
            layout={MainLayout}
            path="/mass-upload"
          />
          <RouteWithLayout
            exact
            component={MassContractUpdateFormView}
            layout={MainLayout}
            path="/mass-contract-update"
          />
          <RouteWithLayout
            exact
            component={MassContractUpdateResultView}
            layout={MainLayout}
            path="/mass-contract-update-result"
          />
          <RouteWithLayout
            exact
            component={InventoryDataView}
            layout={MainLayout}
            path="/inventory-data"
          />
          <RouteWithLayout
            exact
            component={AddInventoryDataView}
            layout={MainLayout}
            path="/inventory-data/add"
          />
          <RouteWithLayout
            exact
            component={EditInventoryDataView}
            layout={MainLayout}
            path="/inventory-data/edit/:id"
          />
          <RouteWithLayout
            exact
            component={InventoryDataUploadView}
            layout={MainLayout}
            path="/inventory-data/inventory-data-excel-upload"
          />
          <RouteWithLayout
            exact
            component={InventoryDataAddQueryView}
            layout={MainLayout}
            path="/inventory-data/inventory-data-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={InventoryDataEditQueryView}
            layout={MainLayout}
            path="/inventory-data/inventory-data-excel-upload/edit-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewInventoryDataView}
            layout={MainLayout}
            path="/inventory-data/inventory-data-excel-upload/view-query/:id"
          />
          {/*demand supply*/}
          <RouteWithLayout
            exact
            component={DemandSupplyView}
            layout={MainLayout}
            path="/demand-supply"
          />
          <RouteWithLayout
            exact
            component={AddDemandSupplyView}
            layout={MainLayout}
            path="/demand-supply/add"
          />
          <RouteWithLayout
            exact
            component={EditDemandSupplyView}
            layout={MainLayout}
            path="/demand-supply/edit/:id"
          />
          <RouteWithLayout
            exact
            component={DemandSupplyUploadView}
            layout={MainLayout}
            path="/demand-supply/demand-supply-excel-upload"
          />
          <RouteWithLayout
            exact
            component={DemandSupplyAddQueryView}
            layout={MainLayout}
            path="/demand-supply/demand-supply-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={DemandSupplyEditQueryView}
            layout={MainLayout}
            path="/demand-supply/demand-supply-excel-upload/edit-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDemandSupplyView}
            layout={MainLayout}
            path="/demand-supply/demand-supply-excel-upload/view-query/:id"
          />
          {/* Competitor Data */}
          <RouteWithLayout
            exact
            component={CompetitorDataView}
            layout={MainLayout}
            path="/competitor-data"
          />
          <RouteWithLayout
            exact
            component={EditCompetitorDataView}
            layout={MainLayout}
            path="/competitor-data/edit/:id"
          />
          <RouteWithLayout
            exact
            component={CompetitorDataUpload}
            layout={MainLayout}
            path="/competitor-data/competitor-data-excel-upload"
          />
          <RouteWithLayout
            exact
            component={CompetitorDataUploadAddQuery}
            layout={MainLayout}
            path="/competitor-data/competitor-data-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={CompetitorDataUploadEditQuery}
            layout={MainLayout}
            path="/competitor-data/competitor-data-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={CompetitorDataUploadViewQuery}
            layout={MainLayout}
            path="/competitor-data/competitor-data-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={SpreadsheetCreatorSFView}
            layout={MainLayout}
            path="/spreadsheet-creator-sf"
          />
          <RouteWithLayout
            exact
            component={SpreadsheetCreatorListPriceView}
            layout={MainLayout}
            path="/spreadsheet-creator-listPrice"
          />
          {/* Off Invoice Cost */}
          <RouteWithLayout
            exact
            component={OffInvoiceCostView}
            layout={MainLayout}
            path="/off-Invoice-cost"
          />
          <RouteWithLayout
            exact
            component={OffInvoiceCostUpload}
            layout={MainLayout}
            path="/off-Invoice-cost/off-Invoice-cost-excel-upload"
          />
          <RouteWithLayout
            exact
            component={OffInvoiceCostUploadViewQuery}
            layout={MainLayout}
            path="/off-Invoice-cost/off-Invoice-cost-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={OffInvoiceCostUploadEditQuery}
            layout={MainLayout}
            path="/off-Invoice-cost/off-Invoice-cost-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={OffInvoiceCostUploadAddQuery}
            layout={MainLayout}
            path="/off-Invoice-cost/off-Invoice-cost-excel-upload/add"
          />
          <RouteWithLayout
            exact
            component={BuildOffInvoiceCostQueryView}
            layout={MainLayout}
            path="/build-off-Invoice-cost"
          />
          <RouteWithLayout
            exact
            component={ClaimConfigurationMainView}
            layout={MainLayout}
            path="/claim-configuration"
          />
          {/* Rejection Reasons */}
          <RouteWithLayout
            exact
            component={ClaimErrorMainView}
            layout={MainLayout}
            path="/rejection-reasons"
          />
          <RouteWithLayout
            exact
            component={AddClaimErrorView}
            layout={MainLayout}
            path="/rejection-reasons/add-rejection"
          />
          <RouteWithLayout
            exact
            component={EditClaimErrorView}
            layout={MainLayout}
            path="/rejection-reasons/edit-rejection/:id"
          />
          <RouteWithLayout
            exact
            component={AddEditClaimErrorView}
            layout={MainLayout}
            path="/rejection-reasons/add-edit-rejection/:id"
          />
          <RouteWithLayout
            exact
            component={PromoPlanSalesIncentivesView}
            layout={MainLayout}
            path="/promotion-sales-analysis"
          />
          <RouteWithLayout
            exact
            component={TimeToMarketView}
            layout={MainLayout}
            path="/time-to-market"
          />
          <RouteWithLayout
            exact
            component={PromoConfigurationView}
            layout={MainLayout}
            path="/promo-configuration"
          />
          <RouteWithLayout
            exact
            component={SalesForecastingView}
            layout={MainLayout}
            path="/sales-forecasting"
          />
          <RouteWithLayout
            exact
            component={SalesForecastingDisplayView}
            path="/sales-forecasting-display"
            layout={MainLayout}
          />
          <RouteWithLayout
            exact
            component={SalesForecastExcelUploadView}
            layout={MainLayout}
            path="/sales-forecasting/sales-forecasting-excel-upload"
          />
          <RouteWithLayout
            exact
            component={SalesForecastViewQueryView}
            layout={MainLayout}
            path="/sales-forecasting/sales-forecasting-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={SalesForecastEditQueryView}
            layout={MainLayout}
            path="/sales-forecasting/sales-forecasting-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={SalesForecastAddQueryView}
            layout={MainLayout}
            path="/sales-forecasting/sales-forecasting-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={ForecastingSalesView}
            layout={MainLayout}
            path="/forecasting-sales"
          />
          <RouteWithLayout
            exact
            component={ViewDisplayForecastResultView}
            layout={MainLayout}
            path="/display-forecast-result"
          />
          <RouteWithLayout
            exact
            component={ViewDisplayForecastResult}
            layout={MainLayout}
            path="/display-forecast"
          />
          <RouteWithLayout
            exact
            component={StockChartView}
            layout={MainLayout}
            path="/stock-chart"
          />
          <RouteWithLayout
            exact
            component={CoverageCostView}
            layout={MainLayout}
            path="/coverage-cost"
          />
          <RouteWithLayout
            exact
            component={MasterDataSearchView}
            layout={MainLayout}
            path="/promotions-master/search"
          />
          <RouteWithLayout
            exact
            component={CoverageCostSearchView}
            layout={MainLayout}
            path="/coverage-cost/search"
          />
          <RouteWithLayout
            exact
            component={MaterialPricingSearchView}
            layout={MainLayout}
            path="/material-pricing/search"
          />
          <RouteWithLayout
            exact
            component={SalesForecastSearchView}
            layout={MainLayout}
            path="/sales-forecast/search"
          />
          <RouteWithLayout
            exact
            component={CoverageCostExcelUploadView}
            layout={MainLayout}
            path="/coverage-cost/coverage-cost-excel-upload"
          />
          <RouteWithLayout
            exact
            component={CoverageCostViewQueryView}
            layout={MainLayout}
            path="/coverage-cost/coverage-cost-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={CoverageCostEditQueryView}
            layout={MainLayout}
            path="/coverage-cost/coverage-cost-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={CoverageCostAddQueryView}
            layout={MainLayout}
            path="/coverage-cost/coverage-cost-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={CoverageCostMappingView}
            layout={MainLayout}
            path="/coverage-cost-mapping"
          />
          <RouteWithLayout
            exact
            component={CoverageCostEditView}
            layout={MainLayout}
            path="/coverage-cost/edit-coverage-cost/:id"
          />
          <RouteWithLayout
            exact
            component={CoverageMappingEditView}
            layout={MainLayout}
            path="/coverage-cost-mapping/edit-coverage-cost-mapping/:id"
          />
          <RouteWithLayout
            exact
            component={CoverageMappingExcelUploadView}
            layout={MainLayout}
            path="/coverage-cost-mapping/excel-upload"
          />
          <RouteWithLayout
            exact
            component={CoverageMappingViewQueryView}
            layout={MainLayout}
            path="/coverage-cost-mapping/excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={CoverageMappingEditQueryView}
            layout={MainLayout}
            path="/coverage-cost-mapping/excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={CoverageMappingAddQueryView}
            layout={MainLayout}
            path="/coverage-cost-mapping/excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={CoverageMappingSearchView}
            layout={MainLayout}
            path="/coverage-cost-mapping/search"
          />
          <RouteWithLayout
            exact
            component={PromotionConfigView}
            layout={MainLayout}
            path="/promo-config"
          />
          <RouteWithLayout
            exact
            component={PromotionConfigAddView}
            layout={MainLayout}
            path="/promo-config/add-promo"
          />
          <RouteWithLayout
            exact
            component={PromotionConfigEditView}
            layout={MainLayout}
            path="/promo-config/edit-promo/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionConfigExcelUploadView}
            layout={MainLayout}
            path="/promo-config/excel-upload"
          />
          <RouteWithLayout
            exact
            component={PromotionConfigAddQueryView}
            layout={MainLayout}
            path="/promo-config/excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={PromotionConfigEditQueryView}
            layout={MainLayout}
            path="/promo-config/excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionConfigViewQueryView}
            layout={MainLayout}
            path="/promo-config/excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={MaterialPricingView}
            layout={MainLayout}
            path="/material-pricing"
          />
          <RouteWithLayout
            exact
            component={MaterialPricingEditView}
            layout={MainLayout}
            path="/material-pricing/edit-material/:id"
          />
          <RouteWithLayout
            exact
            component={MaterialPricingExcelUploadView}
            layout={MainLayout}
            path="/material-pricing/excel-upload"
          />
          <RouteWithLayout
            exact
            component={MaterialPricingAddQueryView}
            layout={MainLayout}
            path="/material-pricing/excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={MaterialPricingEditQueryView}
            layout={MainLayout}
            path="/material-pricing/excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={MaterialPricingViewQueryView}
            layout={MainLayout}
            path="/material-pricing/excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionCurrencyView}
            layout={MainLayout}
            path="/promo-currency"
          />
          <RouteWithLayout
            exact
            component={PromotionCurrencyExcelUploadView}
            layout={MainLayout}
            path="/promo-currency/excel-upload"
          />
          <RouteWithLayout
            exact
            component={PromotionCurrencyAddQueryView}
            layout={MainLayout}
            path="/promo-currency/excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={PromotionCurrencyEditQueryView}
            layout={MainLayout}
            path="/promo-currency/excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionCurrencyViewQueryView}
            layout={MainLayout}
            path="/promo-currency/excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionCurrencyEditView}
            layout={MainLayout}
            path="/promo-currency/edit-currency/:id"
          />
          <RouteWithLayout
            exact
            component={ViewFinanceCostView}
            layout={MainLayout}
            path="/finance-cost/view-finance-cost/:id"
          />
          <RouteWithLayout
            exact
            component={MarketShareView}
            layout={MainLayout}
            path="/market-share"
          />
          <RouteWithLayout
            exact
            component={MarketShareView}
            layout={MainLayout}
            path="/market-share"
          />
          <RouteWithLayout
            exact
            component={IntegrationMappingView}
            layout={MainLayout}
            path="/integration-mapping"
          />
          <RouteWithLayout
            exact
            component={AddIntegrationMappingView}
            layout={MainLayout}
            path="/integration-mapping/add"
          />
          <RouteWithLayout
            exact
            component={EditIntegrationMappingView}
            layout={MainLayout}
            path="/integration-mapping/edit/:id"
          />
          <RouteWithLayout
            exact
            component={ViewIntegrationMappingView}
            layout={MainLayout}
            path="/integration-mapping/view/:id"
          />
          <RouteWithLayout
            exact
            component={IMHistoryView}
            layout={MainLayout}
            path="/integration-manager/im-history/:id"
          />
          <RouteWithLayout
            exact
            component={BatchJobSetupView}
            layout={MainLayout}
            path="/integration-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={BatchJobStatusView}
            layout={MainLayout}
            path="/integration-batchjob-status"
          />
          <RouteWithLayout
            exact
            component={OnDemandIntegrationView}
            layout={MainLayout}
            path="/on-demand-integration"
          />
          <RouteWithLayout
            exact
            component={IntegrationStatusView}
            layout={MainLayout}
            path="/integration-status"
          />
          <RouteWithLayout
            exact
            component={AddIntegrationBatchJobSetupView}
            layout={MainLayout}
            path="/integration-batchjob-setup/add-integration-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={EditIntegrationBatchJobSetupView}
            layout={MainLayout}
            path="/integration-batchjob-setup/edit-integration-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={ViewIntegrationBatchJobSetupView}
            layout={MainLayout}
            path="/integration-batchjob-setup/view-integration-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={ErrorLogView}
            layout={MainLayout}
            path="/error-logs"
          />
          <RouteWithLayout
            exact
            component={MessageLogView}
            layout={MainLayout}
            path="/message-logs"
          />
          <RouteWithLayout
            exact
            component={FormulaBuilderMainView}
            layout={MainLayout}
            path="/pricing-formula"
          />
          <RouteWithLayout
            exact
            component={AddPricingFormulaView}
            layout={MainLayout}
            path="/pricing-formula/add-formula"
          />
          <RouteWithLayout
            exact
            component={EditPricingFormulaView}
            layout={MainLayout}
            path="/pricing-formula/edit-formula/:id"
          />
          <RouteWithLayout
            exact
            component={ViewPricingFormulaView}
            layout={MainLayout}
            path="/pricing-formula/view-formula/:id"
          />
          <RouteWithLayout
            exact
            component={QualifierConfigMainView}
            layout={MainLayout}
            path="/pricing-qualifier-config"
          />
          <RouteWithLayout
            exact
            component={AddQualifierConfigView}
            layout={MainLayout}
            path="/pricing-qualifier-config/add-qualifier-config"
          />
          <RouteWithLayout
            exact
            component={EditQualifierConfigView}
            layout={MainLayout}
            path="/pricing-qualifier-config/edit-qualifier-config/:id"
          />
          <RouteWithLayout
            exact
            component={PriceTypeConfigMainView}
            layout={MainLayout}
            path="/pricing-type-config"
          />
          <RouteWithLayout
            exact
            component={AddTypeConfigView}
            layout={MainLayout}
            path="/pricing-type-config/add-type-config"
          />
          <RouteWithLayout
            exact
            component={EditTypeConfigView}
            layout={MainLayout}
            path="/pricing-type-config/edit-type-config/:id"
          />
          <RouteWithLayout
            exact
            component={PriceListMasterMainView}
            layout={MainLayout}
            path="/pricing-list-master"
          />
          <RouteWithLayout
            exact
            component={AddPriceListView}
            layout={MainLayout}
            path="/pricing-list-master/add-list-master"
          />
          <RouteWithLayout
            exact
            component={EditPriceListView}
            layout={MainLayout}
            path="/pricing-list-master/edit-list-master/:id"
          />
          <RouteWithLayout
            exact
            component={PriceCalculationFormView}
            layout={MainLayout}
            path="/pricing-calculation"
          />
          <RouteWithLayout
            exact
            component={MarketPriceView}
            layout={MainLayout}
            path="/pricing-market-price"
          />
          <RouteWithLayout
            exact
            component={ListPriceDataView}
            layout={MainLayout}
            path="/list-price"
          />
          <RouteWithLayout
            exact
            component={ListPriceDataAddView}
            layout={MainLayout}
            path="/list-price/add"
          />
          <RouteWithLayout
            exact
            component={ListPriceDataEditView}
            layout={MainLayout}
            path="/list-price/edit/:id"
          />
          <RouteWithLayout
            exact
            component={ListPriceDataUploadView}
            layout={MainLayout}
            path="/list-price/list-price-excel-upload"
          />
          <RouteWithLayout
            exact
            component={ListPriceDataUploadAddQueryView}
            layout={MainLayout}
            path="/list-price/list-price-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={ListPriceDataUploadEditQueryView}
            layout={MainLayout}
            path="/list-price/list-price-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={ListPriceDataUploadViewQueryView}
            layout={MainLayout}
            path="/list-price/list-price-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PriceListDataMainView}
            layout={MainLayout}
            path="/pricing-maintenance"
          />
          <RouteWithLayout
            exact
            component={PricingDashboardView}
            layout={MainLayout}
            path="/pricing-dashboard"
          />
          <RouteWithLayout
            exact
            component={AddPriceListDataView}
            layout={MainLayout}
            path="/pricing-maintenance/add-list-data"
          />
          <RouteWithLayout
            exact
            component={EditPriceListDataView}
            layout={MainLayout}
            path="/pricing-maintenance/edit-list-data/:id"
          />
          <RouteWithLayout
            exact
            component={EditPriceListDataSheetView}
            layout={MainLayout}
            path="/pricing-maintenance/edit-list-data-all"
          />
          <RouteWithLayout
            exact
            component={EditPriceListDataSheetView}
            layout={MainLayout}
            path="/pricing-maintenance/view-list-data-all"
          />
          <RouteWithLayout
            exact
            component={ViewPriceListDataView}
            layout={MainLayout}
            path="/pricing-maintenance/view-list-data/:id"
          />
          <RouteWithLayout
            exact
            component={PriceListDataExcelUploadView}
            layout={MainLayout}
            path="/pricing-maintenance/pricing-maintenance-excel-upload"
          />
          <RouteWithLayout
            exact
            component={PriceListDataViewQueryView}
            layout={MainLayout}
            path="/pricing-maintenance/pricing-maintenance-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PriceListDataEditQueryView}
            layout={MainLayout}
            path="/pricing-maintenance/pricing-maintenance-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PriceListDataAddQueryView}
            layout={MainLayout}
            path="/pricing-maintenance/pricing-maintenance-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={PriceListMaintenanceEditQueryView}
            layout={MainLayout}
            path="/pricing-maintenance/pricing-maintenance-pricetype-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PriceListMaintenanceAddQueryView}
            layout={MainLayout}
            path="/pricing-maintenance/pricing-maintenance-pricetype-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={OffInvoiceMainView}
            layout={MainLayout}
            path="/pricing-offinvoice-config"
          />
          {/* price calculation formula */}
          <RouteWithLayout
            exact
            component={priceCalculationFormulaView}
            layout={MainLayout}
            path="/pricing-calculation-formula"
          />
          <RouteWithLayout
            exact
            component={AddPriceCalculationFormulaView}
            layout={MainLayout}
            path="/pricing-calculation-formula/add-formula"
          />
          <RouteWithLayout
            exact
            component={EditPriceCalculationFormulaView}
            layout={MainLayout}
            path="/pricing-calculation-formula/edit-formula/:id"
          />
          <RouteWithLayout
            exact
            component={ViewPriceCalculationFormulaView}
            layout={MainLayout}
            path="/pricing-calculation-formula/view-formula/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionSetupMainView}
            layout={MainLayout}
            path="/promotion-setup"
          />
          <RouteWithLayout
            exact
            component={AddPromotionSetupView}
            layout={MainLayout}
            path="/promotion-setup/add-setup"
          />
          <RouteWithLayout
            exact
            component={EditPromotionSetupView}
            layout={MainLayout}
            path="/promotion-setup/edit-setup/:id"
          />
          <RouteWithLayout
            exact
            component={ViewPromotionSetupView}
            layout={MainLayout}
            path="/promotion-setup/view-setup/:id"
          />
          <RouteWithLayout
            exact
            component={GlobalSetupView}
            layout={MainLayout}
            path="/promotion-global-setup"
          />
          <RouteWithLayout
            exact
            component={StickerBankView}
            layout={MainLayout}
            path="/promotion-sticker-setup"
          />
          <RouteWithLayout
            exact
            component={GridView}
            layout={MainLayout}
            path="/grid-main"
          />
          <RouteWithLayout
            exact
            component={PromotionReviewView}
            layout={MainLayout}
            path="/reviewing-scenario"
          />
          <RouteWithLayout
            exact
            component={TakesRateApprovalView}
            layout={MainLayout}
            path="/takes-rate-approval"
          />
          <RouteWithLayout
            exact
            component={CostingApprovalView}
            layout={MainLayout}
            path="/costing-approval"
          />
          <RouteWithLayout
            exact
            component={AddStickersView}
            layout={MainLayout}
            path="/promotion-sticker-setup/add"
          />
          <RouteWithLayout
            exact
            component={EditStickersView}
            layout={MainLayout}
            path="/promotion-sticker-setup/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionScenarioView}
            layout={MainLayout}
            path="/promotion-scenario-setup"
          />
          <RouteWithLayout
            exact
            component={PromotionScenarioEditView}
            layout={MainLayout}
            path="/promotion-scenario-setup/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionDashboardView}
            layout={MainLayout}
            path="/promotion-dashboard"
          />
          <RouteWithLayout
            exact
            component={EndPreMaturelyView}
            layout={MainLayout}
            path="/end-premature-scenario"
          />
          <RouteWithLayout
            exact
            component={ScenarioListScreenView}
            layout={MainLayout}
            path="/promotion-scenario-list"
          />
          <RouteWithLayout
            exact
            component={SandBoxListScreenView}
            layout={MainLayout}
            path="/promotion-scenario-sand-box"
          />
          <RouteWithLayout
            exact
            component={HistoryListScreenView}
            layout={MainLayout}
            path="/promotion-scenario-history"
          />
          <RouteWithLayout
            exact
            component={CustomGroupView}
            layout={MainLayout}
            path="/custom-group"
          />
          <RouteWithLayout
            exact
            component={CreateCustomGroupView}
            layout={MainLayout}
            path="/create-custom-group"
          />
          <RouteWithLayout
            exact
            component={UpdateCustomGroupView}
            layout={MainLayout}
            path="/update-custom-group/:id"
          />
          {/* Forecasting Config */}
          <RouteWithLayout
            exact
            component={ForecastingConfigMainView}
            layout={MainLayout}
            path="/forecasting-config"
          />
          <RouteWithLayout
            exact
            component={AddConfigView}
            layout={MainLayout}
            path="/forecasting-config/add-config"
          />
          <RouteWithLayout
            exact
            component={EditConfigView}
            layout={MainLayout}
            path="/forecasting-config/edit-config/:id"
          />
          <RouteWithLayout
            exact
            component={ViewConfigView}
            layout={MainLayout}
            path="/forecasting-config/view-config/:id"
          />
          <RouteWithLayout
            exact
            component={CustomerRebateForecastView}
            layout={MainLayout}
            path="/customer-rebate-forecast"
          />
          <RouteWithLayout
            exact
            component={CustomerRebateForecastResultView}
            layout={MainLayout}
            path="/customer-rebate-forecast-result"
          />
          <RouteWithLayout
            exact
            component={AnamolyConfigMainView}
            layout={MainLayout}
            path="/anomaly-config"
          />
          <RouteWithLayout
            exact
            component={AddAnamolyConfigView}
            layout={MainLayout}
            path="/anomaly-config/add-anomaly-config"
          />
          <RouteWithLayout
            exact
            component={EditAnamolyConfigView}
            layout={MainLayout}
            path="/anomaly-config/edit-anomaly-config/:id"
          />
          <RouteWithLayout
            exact
            component={ViewAnamolyConfigView}
            layout={MainLayout}
            path="/anomaly-config/view-anomaly-config/:id"
          />
          <RouteWithLayout
            exact
            component={RunAnamolyConfigView}
            layout={MainLayout}
            path="/anomaly-run"
          />
          {/* Historical Sales Upload */}
          <RouteWithLayout
            exact
            component={HistoricalSalesUploadView}
            layout={MainLayout}
            path="/historical-sales-upload"
          />
          <RouteWithLayout
            exact
            component={SalesForecastMainView}
            layout={MainLayout}
            path="/sales-forecast-main"
          />
          <RouteWithLayout
            exact
            component={SalesForecastEditView}
            layout={MainLayout}
            path="/sales-forecast-main/edit-sales-forecast/:id"
          />
          <RouteWithLayout
            exact
            component={SalesForecastUploadView}
            layout={MainLayout}
            path="/sales-forecast-upload"
          />
          <RouteWithLayout
            exact
            component={SalesForecastFileUploadView}
            layout={MainLayout}
            path="/sales-forecast-file-upload"
          />
          <RouteWithLayout
            exact
            component={BuildForecastDataView}
            layout={MainLayout}
            path="/build-forecast-data"
          />
          <RouteWithLayout
            exact
            component={CostSimulationFormView}
            layout={MainLayout}
            path="/costing-simulation"
          />
          {/* Price Simulation (CPQ) */}
          <RouteWithLayout
            exact
            component={PriceSimulationCPQView}
            layout={MainLayout}
            path="/price-simulation-cpq"
          />
          <RouteWithLayout
            exact
            component={PriceSimulationCPQResultView}
            layout={MainLayout}
            path="/price-simulation-results"
          />
          <RouteWithLayout
            exact
            component={PriceElasticityView}
            layout={MainLayout}
            path="/price-elasticity"
          />
          <RouteWithLayout
            exact
            component={PriceElasticityResultView}
            layout={MainLayout}
            path="/price-elasticity-results"
          />
          <RouteWithLayout
            exact
            component={PriceSimulationReportView}
            layout={MainLayout}
            path="/price-simulation-report"
          />
          <RouteWithLayout
            exact
            component={PriceSimulationReportResultView}
            layout={MainLayout}
            path="/price-simulation-report-results"
          />
          <RouteWithLayout
            exact
            component={PriceCatalogView}
            layout={MainLayout}
            path="/price-catalog"
          />
          <RouteWithLayout
            exact
            component={PriceCatalogResultView}
            layout={MainLayout}
            path="/price-catalog-report-results"
          />
          <RouteWithLayout
            exact
            component={PriceAdjustmentSimulationView}
            layout={MainLayout}
            path="/price-adjustment-simulation"
          />
          <RouteWithLayout
            exact
            component={PriceAdjustmentSimulationResultView}
            layout={MainLayout}
            path="/price-adjustment-simulation-results"
          />
          <RouteWithLayout
            exact
            component={EDIFileView}
            layout={MainLayout}
            path="/EDI-file"
          />
          <RouteWithLayout
            exact
            component={CustomerMarginView}
            layout={MainLayout}
            path="/customer-margin"
          />
          <RouteWithLayout
            exact
            component={CustomerMarginResultView}
            layout={MainLayout}
            path="/customer-margin-results"
          />
          <RouteWithLayout
            exact
            component={PriceSimulationHistoricalView}
            layout={MainLayout}
            path="/price-simulation-historical"
          />
          <RouteWithLayout
            exact
            component={PriceSimulationForecastView}
            layout={MainLayout}
            path="/price-simulation-forecast"
          />
          <RouteWithLayout
            exact
            component={CostApprovalFormView}
            layout={MainLayout}
            path="/costing-approvals"
          />
          <RouteWithLayout
            exact
            component={CostApprovalsView}
            layout={MainLayout}
            path="/costing-approvals/costing-reports"
          />
          <RouteWithLayout
            exact
            component={CostPostingFormView}
            layout={MainLayout}
            path="/costing-postings"
          />
          <RouteWithLayout
            exact
            component={PromoPlanAnalyticsView}
            layout={MainLayout}
            path="/promo-plan-analytics"
          />
          <RouteWithLayout
            exact
            component={RejectedDashboardView}
            layout={MainLayout}
            path="/rejected-dashboard"
          />
          <RouteWithLayout
            exact
            component={ClaimStatusResultView}
            layout={MainLayout}
            path="/claim-status-result"
          />
          <RouteWithLayout
            exact
            component={ClaimStatusSearchView}
            layout={MainLayout}
            path="/claim-status-search"
          />
          {/* sales forecast add format */}
          <RouteWithLayout
            exact
            component={SalesForecastFormatAddView}
            layout={MainLayout}
            path="/historical-sales-upload/add-format"
          />
          <RouteWithLayout
            exact
            component={SalesForecastFormatEditView}
            layout={MainLayout}
            path="/historical-sales-upload/edit-format/:id"
          />
          <RouteWithLayout
            exact
            component={SalesForecastFormatSingleView}
            layout={MainLayout}
            path="/historical-sales-upload/view-format/:id"
          />
          <RouteWithLayout
            exact
            component={MonthlyTrendPromTypeView}
            layout={MainLayout}
            path="/monthly-prom-type"
          />
          <RouteWithLayout
            exact
            component={CostAllocationPromTypeView}
            layout={MainLayout}
            path="/cost-allocation"
          />
          <RouteWithLayout
            exact
            component={MonthlyTrendCostToRevenueView}
            layout={MainLayout}
            path="/cost-to-revenue"
          />
          {/* cot xref */}
          <RouteWithLayout
            exact
            component={CotXREFView}
            layout={MainLayout}
            path="/cot-xref"
          />
          <RouteWithLayout
            exact
            component={AddCotXRefView}
            layout={MainLayout}
            path="/cot-xref/add-cot-xref"
          />
          <RouteWithLayout
            exact
            component={EditCotXRefView}
            layout={MainLayout}
            path="/cot-xref/edit-cot-xref/:id"
          />
          <RouteWithLayout
            exact
            component={ViewCotXRefView}
            layout={MainLayout}
            path="/cot-xref/view-cot-xref/:id"
          />
          <RouteWithLayout
            exact
            component={CotXREFExcelUploadView}
            layout={MainLayout}
            path="/cot-xref/cot-xref-excel-upload"
          />
          <RouteWithLayout
            exact
            component={CotXREFViewQueryView}
            layout={MainLayout}
            path="/cot-xref/cot-xref-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={CotXREFEditQueryView}
            layout={MainLayout}
            path="/cot-xref/cot-xref-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={CotXREFAddQueryView}
            layout={MainLayout}
            path="/cot-xref/cot-xref-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={PromotionMappingView}
            layout={MainLayout}
            path="/promo-mapping"
          />
          <RouteWithLayout
            exact
            component={AddPromotionMappingView}
            layout={MainLayout}
            path="/promo-mapping/add-promotion"
          />
          <RouteWithLayout
            exact
            component={EditPromotionMappingView}
            layout={MainLayout}
            path="/promo-mapping/edit-promotion/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionMappingExcelUploadView}
            layout={MainLayout}
            path="/promo-mapping/promo-mapping-excel-upload"
          />
          <RouteWithLayout
            exact
            component={PromotionMappingViewQueryView}
            layout={MainLayout}
            path="/promo-mapping/promo-mapping-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionMappingEditQueryView}
            layout={MainLayout}
            path="/promo-mapping/promo-mapping-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={PromotionMappingAddQueryView}
            layout={MainLayout}
            path="/promo-mapping/promo-mapping-excel-upload/add-query"
          />
          {/* profit optimization */}
          <RouteWithLayout
            exact
            component={AnalysisFormulaView}
            layout={MainLayout}
            path="/analysis-formula"
          />
          <RouteWithLayout
            exact
            component={AddAnalysisFormulaView}
            layout={MainLayout}
            path="/analysis-formula/add-formula"
          />
          <RouteWithLayout
            exact
            component={EditAnalysisFormulaView}
            layout={MainLayout}
            path="/analysis-formula/edit-formula/:id"
          />
          <RouteWithLayout
            exact
            component={ViewAnalysisFormulaView}
            layout={MainLayout}
            path="/analysis-formula/view-formula/:id"
          />
          <RouteWithLayout
            exact
            component={AnalysisLevelMainView}
            layout={MainLayout}
            path="/analysis-level"
          />
          <RouteWithLayout
            exact
            component={AddAnalysisLevelView}
            layout={MainLayout}
            path="/analysis-level/add-level"
          />
          <RouteWithLayout
            exact
            component={EditAnalysisLevelView}
            layout={MainLayout}
            path="/analysis-level/edit-level/:id"
          />
          <RouteWithLayout
            exact
            component={DriversMainView}
            layout={MainLayout}
            path="/drivers"
          />
          <RouteWithLayout
            exact
            component={AddDriversView}
            layout={MainLayout}
            path="/drivers/add-drivers"
          />
          <RouteWithLayout
            exact
            component={EditDriversView}
            layout={MainLayout}
            path="/drivers/edit-drivers/:id"
          />
          <RouteWithLayout
            exact
            component={AttributesConfigMainView}
            layout={MainLayout}
            path="/attributes-config"
          />
          <RouteWithLayout
            exact
            component={AddAttributesConfigView}
            layout={MainLayout}
            path="/attributes-config/add-attributes-config"
          />
          <RouteWithLayout
            exact
            component={EditAttributesConfigView}
            layout={MainLayout}
            path="/attributes-config/edit-attributes-config/:id"
          />
          {/* Analytics Designer */}
          <RouteWithLayout
            exact
            component={AnalyticsDesignerMainView}
            layout={MainLayout}
            path="/analytics-designer"
          />
          <RouteWithLayout
            exact
            component={ReportingByFunctionMainView}
            layout={MainLayout}
            path="/reporting-by-function"
          />
          <RouteWithLayout
            exact
            component={ReportingOnRollView}
            layout={MainLayout}
            path="/reporting-on-roll"
          />
          <RouteWithLayout
            exact
            component={DashboardDesignerMainView}
            layout={MainLayout}
            path="/dashboard-designer"
          />
          <RouteWithLayout
            exact
            component={AddAnalyticsDesignerView}
            layout={MainLayout}
            path="/analytics-designer/add-analytics"
          />
          <RouteWithLayout
            exact
            component={AddReportingByFunctionView}
            layout={MainLayout}
            path="/reporting-by-function/add-reporting"
          />
          <RouteWithLayout
            exact
            component={AddDashboardDesignerView}
            layout={MainLayout}
            path="/dashboard-designer/add-dashboard"
          />
          {/* <RouteWithLayout
                    exact
                    component={AddAnalyticsSyncFusionDesignerView}
                    layout={MainLayout}
                    path="/analytics-designer/add-analytics-sf"
                /> */}
          <RouteWithLayout
            exact
            component={EditAnalyticsDesignerView}
            layout={MainLayout}
            path="/analytics-designer/edit-analytics/:id"
          />
          <RouteWithLayout
            exact
            component={EditDashboardDesignerView}
            layout={MainLayout}
            path="/dashboard-designer/edit-analytics/:id"
          />
          <RouteWithLayout
            exact
            component={EditReportingByFunctionView}
            layout={MainLayout}
            path="/report-by-function/edit-report/:id"
          />
          <RouteWithLayout
            exact
            component={ViewAnalyticsDesignerView}
            layout={MainLayout}
            path="/analytics-designer/view-analytics/:id"
          />
          <RouteWithLayout
            exact
            component={ViewReportByFuctionView}
            layout={MainLayout}
            path="/report-by-function/view-report/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDashBoardDesignerView}
            layout={MainLayout}
            path="/dashboard-designer/view-analytics/:id"
          />
          <RouteWithLayout
            exact
            component={HelpDeskMainCPView}
            layout={MainLayout}
            path="/help-desk-cp"
          />
          <RouteWithLayout
            exact
            component={DocumentationView}
            layout={MainLayout}
            path="/documentation"
          />
          <RouteWithLayout
            exact
            component={FAQsView}
            layout={MainLayout}
            path="/faqs"
          />
          <RouteWithLayout
            exact
            component={LogDefectView}
            layout={MainLayout}
            path="/log-defect"
          />
          <RouteWithLayout
            exact
            component={AddHelpDeskCPView}
            layout={MainLayout}
            path="/help-desk-cp/add-help-cp"
          />
          <RouteWithLayout
            exact
            component={EditHelpDeskCPView}
            layout={MainLayout}
            path="/help-desk-cp/edit-help-cp/:id"
          />
          <RouteWithLayout
            exact
            component={AddDriverMaintenanceView}
            layout={MainLayout}
            path="/driver-maintenance/add-list-data"
          />
          <RouteWithLayout
            exact
            component={DriverMaintenanceMainView}
            layout={MainLayout}
            path="/driver-maintenance"
          />
          <RouteWithLayout
            exact
            component={EditDriverMaintenanceView}
            layout={MainLayout}
            path="/driver-maintenance/edit-list-data/:id"
          />
          <RouteWithLayout
            exact
            component={ViewDriverMaintenanceView}
            layout={MainLayout}
            path="/driver-maintenance/view-list-data/:id"
          />
          <RouteWithLayout
            exact
            component={DriverMaintenanceExcelUploadView}
            layout={MainLayout}
            path="/driver-maintenance/driver-maintenance-excel-upload"
          />
          <RouteWithLayout
            exact
            component={DriverMaintenanceViewQueryView}
            layout={MainLayout}
            path="/driver-maintenance/driver-maintenance-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={DriverMaintenanceEditQueryView}
            layout={MainLayout}
            path="/driver-maintenance/driver-maintenance-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={DriverMaintenanceAddQueryView}
            layout={MainLayout}
            path="/driver-maintenance/driver-maintenance-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={PricePolicyExceptionsReportMainView}
            layout={MainLayout}
            path="/price-policy-exceptions-report"
          />
          <RouteWithLayout
            exact
            component={MarginAnalysisView}
            layout={MainLayout}
            path="/margin-analysis"
          />
          <RouteWithLayout
            exact
            component={MarginAnalysisView}
            layout={MainLayout}
            path="/flexible-analysis-report"
          />
          <RouteWithLayout
            exact
            component={FlexibleAnalysisResultView}
            layout={MainLayout}
            path="/margin-analysis-result"
          />
          <RouteWithLayout
            exact
            component={MarginPolicyExceptionsView}
            layout={MainLayout}
            path="/margin-policy-exceptions"
          />
          <RouteWithLayout
            exact
            component={MarginPolicyExceptionResultView}
            layout={MainLayout}
            path="/margin-policy-exceptions-result"
          />
          <RouteWithLayout
            exact
            component={MarginTrendingView}
            layout={MainLayout}
            path="/margin-trending"
          />
          <RouteWithLayout
            exact
            component={MarginTrendingResultView}
            layout={MainLayout}
            path="/margin-trending-result"
          />
          <RouteWithLayout
            exact
            component={MarginByAttributeView}
            layout={MainLayout}
            path="/margin-by-attribute"
          />
          <RouteWithLayout
            exact
            component={RegionalAnalysisView}
            layout={MainLayout}
            path="/regional-analysis"
          />
          <RouteWithLayout
            exact
            component={BatchJobSetupView}
            layout={MainLayout}
            path="/pricing-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={BatchJobStatusView}
            layout={MainLayout}
            path="/pricing-batchjob-status"
          />
          <RouteWithLayout
            exact
            component={AddPricingBatchJobSetupView}
            layout={MainLayout}
            path="/pricing-batchjob-setup/add-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={EditPricingBatchJobSetupView}
            layout={MainLayout}
            path="/pricing-batchjob-setup/edit-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={ViewPricingBatchJobSetupView}
            layout={MainLayout}
            path="/pricing-batchjob-setup/view-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={BatchJobSetupView}
            layout={MainLayout}
            path="/profitopt-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={AddProfitOptBatchJobSetupView}
            layout={MainLayout}
            path="/profitopt-batchjob-setup/add-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={EditProfitOptBatchJobSetupView}
            layout={MainLayout}
            path="/profitopt-batchjob-setup/edit-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={ViewProfitOptBatchJobSetupView}
            layout={MainLayout}
            path="/profitopt-batchjob-setup/view-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={CustomerLevelAnalyticsView}
            layout={MainLayout}
            path="/price-analytics"
          />
          <RouteWithLayout
            exact
            component={RevenueMarginAnalyticsView}
            layout={MainLayout}
            path="/price-revenue-margin-analytics"
          />
          <RouteWithLayout
            exact
            component={topGrowwingDecliningPerformersView}
            layout={MainLayout}
            path="/top-growing-declining-performers"
          />
          <RouteWithLayout
            exact
            component={MarginPricePercentageView}
            layout={MainLayout}
            path="/margin-price-percentage"
          />
          <RouteWithLayout
            exact
            component={MarginAnalyticsView}
            layout={MainLayout}
            path="/margin-analytics"
          />
          <RouteWithLayout
            exact
            component={PricingWaterfallChartView}
            layout={MainLayout}
            path="/price-waterfallchart"
          />
          <RouteWithLayout
            exact
            component={ProfitabilityChartView}
            layout={MainLayout}
            path="/profitability-chart"
          />
          <RouteWithLayout
            exact
            component={MarginRegionalAnalysisView}
            layout={MainLayout}
            path="/margin-regional-analysis"
          />
          <RouteWithLayout
            exact
            component={BuildAnalysisDataQueryView}
            layout={MainLayout}
            path="/build-analysis-data"
          />
          <RouteWithLayout
            exact
            component={ExternalDataQueryListView}
            layout={MainLayout}
            path="/external-data-excel-upload"
          />
          <RouteWithLayout
            exact
            component={ExternalDataAddQueryView}
            layout={MainLayout}
            path="/external-data-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={ExternalDataEditQueryView}
            layout={MainLayout}
            path="/external-data-excel-upload/edit-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewExternalDataQueryView}
            layout={MainLayout}
            path="/external-data-excel-upload/view-query/:id"
          />
          <RouteWithLayout
            exact
            component={SyncfusionView}
            layout={MainLayout}
            path="/sync-fusion"
          />
          <RouteWithLayout
            exact
            component={ExternalConfigMainView}
            layout={MainLayout}
            path="/external-config"
          />
          <RouteWithLayout
            exact
            component={AddExternalConfigView}
            layout={MainLayout}
            path="/external-config/add-external"
          />
          <RouteWithLayout
            exact
            component={EditExternalConfigView}
            layout={MainLayout}
            path="/external-config/edit-external/:id"
          />
          <RouteWithLayout
            exact
            component={SearchContractView}
            layout={MainLayout}
            path="/search-contract"
          />
          <RouteWithLayout
            exact
            component={BuildPricingAnalysisDataQueryView}
            layout={MainLayout}
            path="/build-analysis-data-pricing"
          />
          <RouteWithLayout
            exact
            component={ExternalPricingDataQueryListView}
            layout={MainLayout}
            path="/external-data-excel-upload-pricing"
          />
          <RouteWithLayout
            exact
            component={ExternalPricingDataAddQueryView}
            layout={MainLayout}
            path="/external-data-excel-upload-pricing/add-query"
          />
          <RouteWithLayout
            exact
            component={ExternalPricingDataEditQueryView}
            layout={MainLayout}
            path="/external-data-excel-upload-pricing/edit-query/:id"
          />
          <RouteWithLayout
            exact
            component={ViewExternalPricingDataQueryView}
            layout={MainLayout}
            path="/external-data-excel-upload-pricing/view-query/:id"
          />
          <RouteWithLayout
            exact
            component={ExternalPricingConfigMainView}
            layout={MainLayout}
            path="/external-config-pricing"
          />
          <RouteWithLayout
            exact
            component={AddPricingExternalConfigView}
            layout={MainLayout}
            path="/external-config-pricing/add-external"
          />
          <RouteWithLayout
            exact
            component={EditPricingExternalConfigView}
            layout={MainLayout}
            path="/external-config-pricing/edit-external/:id"
          />
          <RouteWithLayout
            exact
            component={DefaultPageView}
            layout={MainLayout}
            path="/default-page"
          />
          <RouteWithLayout
            exact
            component={MasterDataDashboardDetailsView}
            layout={MainLayout}
            path="/master-data-details"
          />
          <RouteWithLayout
            exact
            component={MasterDataFiltersView}
            layout={MainLayout}
            path="/master-data-filters"
          />
          <RouteWithLayout
            exact
            component={CPQResultView}
            layout={MainLayout}
            path="/price-simulation-detailed-quote/create-quote"
          />
          <RouteWithLayout
            exact
            component={ComprehensiveQuoteView}
            layout={MainLayout}
            path="/price-simulation-detailed-quote"
          />
          <RouteWithLayout
            exact
            component={EditComprehensiveQuoteView}
            layout={MainLayout}
            path="/price-simulation-detailed-quote/edit-quote/:id"
          />
          <RouteWithLayout
            exact
            component={EditComprehensiveQuoteView}
            layout={MainLayout}
            path="/price-simulation-detailed-quote/view-quote/:id"
          />
          <RouteWithLayout
            exact
            component={ProductFeatureMainPricingView}
            layout={MainLayout}
            path="/product-feature-pricing"
          />
          <RouteWithLayout
            exact
            component={AddProductFeaturePricingView}
            layout={MainLayout}
            path="/product-feature-pricing/add-product-feature"
          />
          <RouteWithLayout
            exact
            component={EditProductFeaturePricingView}
            layout={MainLayout}
            path="/product-feature-pricing/edit-product-feature/:id"
          />
          <RouteWithLayout
            exact
            component={ProductFeatureCalculateView}
            layout={MainLayout}
            path="/product-feature-calculate"
          />
          <RouteWithLayout
            exact
            component={ProductFeatureCalculateResultView}
            layout={MainLayout}
            path="/product-feature-calculate-result"
          />
          <RouteWithLayout
            exact
            component={ProductFeatureDataExcelUploadView}
            layout={MainLayout}
            path="/product-feature-pricing-excel-upload"
          />
          <RouteWithLayout
            exact
            component={ProductFeatureDataViewQueryView}
            layout={MainLayout}
            path="/product-feature-pricing-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={ProductFeatureDataEditQueryView}
            layout={MainLayout}
            path="/product-feature-pricing-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={ProductFeatureDataAddQueryView}
            layout={MainLayout}
            path="/product-feature-pricing-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={PricingApprovalView}
            layout={MainLayout}
            path="/pricing-approval"
          />
          <RouteWithLayout
            exact
            component={PricingApprovalListView}
            layout={MainLayout}
            path="/pricing-approval/:id"
          />
          <RouteWithLayout
            exact
            component={MasterDataConfigurationView}
            layout={MainLayout}
            path="/master-data-config"
          />
          <RouteWithLayout
            exact
            component={NextTierFormView}
            layout={MainLayout}
            path="/next-tier-simulation"
          />
          <RouteWithLayout
            exact
            component={NextTierResultView}
            layout={MainLayout}
            path="/next-tier-simulation-result"
          />
          <RouteWithLayout
            exact
            component={TierEvaluationDesignerView}
            layout={MainLayout}
            path="/tier-evaluation-level-designer"
          />
          <RouteWithLayout
            exact
            component={ChatGptView}
            layout={MainLayout}
            path="/chat-gpt"
          />
          <RouteWithLayout
            exact
            component={BatchJobSetupView}
            layout={MainLayout}
            path="/transaction-data-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={BatchJobStatusView}
            layout={MainLayout}
            path="/transaction-data-batchjob-status"
          />
          <RouteWithLayout
            exact
            component={BatchJobStatusSecondPageView}
            layout={MainLayout}
            path="/transaction-data-batchjob-status/second-page/:id"
          />
          <RouteWithLayout
            exact
            component={AddTransactionDataBatchJobSetupView}
            layout={MainLayout}
            path="/transaction-data-batchjob-setup/add-batchjob-setup"
          />
          <RouteWithLayout
            exact
            component={EditTransactionDataBatchJobSetupView}
            layout={MainLayout}
            path="/transaction-data-batchjob-setup/edit-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={ViewTransactionDataBatchJobSetupView}
            layout={MainLayout}
            path="/transaction-data-batchjob-setup/view-batchjob-setup/:id"
          />
          <RouteWithLayout
            exact
            component={CommunityChatMainView}
            layout={MainLayout}
            path="/community"
          />
          <RouteWithLayout
            exact
            component={CommunityChatQAView}
            layout={MainLayout}
            path="/community-chat"
          />
          <RouteWithLayout
            exact
            component={MembershipContractGroupView}
            layout={MainLayout}
            path="/membership-contract-group"
          />
          <RouteWithLayout
            exact
            component={AddMembershipContractGroupView}
            layout={MainLayout}
            path="/membership-contract-group/add-contract"
          />
          <RouteWithLayout
            exact
            component={EditMembershipContractGroupView}
            layout={MainLayout}
            path="/membership-contract-group/edit-contract/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipContractGroupExcelUploadView}
            layout={MainLayout}
            path="/membership-contract-group-excel-upload"
          />
          <RouteWithLayout
            exact
            component={MembershipContractGroupViewQueryView}
            layout={MainLayout}
            path="/membership-contract-group-excel-upload/view/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipContractGroupEditQueryView}
            layout={MainLayout}
            path="/membership-contract-group-excel-upload/edit/:id"
          />
          <RouteWithLayout
            exact
            component={MembershipContractGroupAddQueryView}
            layout={MainLayout}
            path="/membership-contract-group-excel-upload/add-query"
          />
          <RouteWithLayout
            exact
            component={QuoteDetailView}
            layout={MainLayout}
            path="/quote-view/:id"
          />
          <RouteWithLayout
            exact
            component={DocumentManagementMainView}
            layout={MainLayout}
            path="/documents"
          />
          <RouteWithLayout
            exact
            component={CreateDocumentView}
            layout={MainLayout}
            path="/documents/create"
          />
          <RouteWithLayout
            exact
            component={DocumentManagementSearchView}
            layout={MainLayout}
            path="/documents-search"
          />
          <RouteWithLayout
            exact
            component={DocumentManagementSearchResultView}
            layout={MainLayout}
            path="/documents-search-result"
          />
          <RouteWithLayout
            exact
            component={ViewDocumentView}
            layout={MainLayout}
            path="/documents/view/:id"
          />
          <RouteWithLayout
            exact
            component={UpdateDocumentView}
            layout={MainLayout}
            path="/documents/update/:id"
          />
          <RouteWithLayout
            exact
            component={UpdateDocumentView}
            layout={MainLayout}
            path="/documents/submit-approval/:id"
          />
          <RouteWithLayout
            exact
            component={DocumentLevelsView}
            layout={MainLayout}
            path="/document-workflow"
          />
          <RouteWithLayout
            exact
            component={CreateLevelsView}
            layout={MainLayout}
            path="/document-workflow/create"
          />
          <RouteWithLayout
            exact
            component={UpdateLevelsView}
            layout={MainLayout}
            path="/document-workflow/update/:id"
          />
          <RouteWithLayout
            exact
            component={ViewLevelsView}
            layout={MainLayout}
            path="/document-workflow/view/:id"
          />
          <RouteWithLayout
            exact
            component={RebateAnalyticsView}
            layout={MainLayout}
            path="/rebate-analytics"
          />
          <RouteWithLayout
            exact
            component={PostingSummaryAnalysisView}
            layout={MainLayout}
            path="/posting-summary-analysis"
          />
          <RouteWithLayout
            exact
            component={PostingDetailAnalyticsView}
            layout={MainLayout}
            path="/posting-detail-analysis"
          />
          <RouteWithLayout
            exact
            component={NewMasterDataIncentivesView}
            layout={MainLayout}
            path="/new-master-data-incentives"
          />
          <RouteWithLayout
            exact
            component={GrossToNetView}
            layout={MainLayout}
            path="/gross-to-net"
          />
          <RouteWithLayout
            exact
            component={MedianMarginView}
            layout={MainLayout}
            path="/median-margin"
          />
          <RouteWithLayout
            exact
            component={RevenueMarginView}
            layout={MainLayout}
            path="/revenue-margin-analysis"
          />
          <RouteWithLayout
            exact
            component={AddWorkFlowAssignmentsView}
            layout={MainLayout}
            path="/workflow-assignment/add-assignment"
          />
          <RouteWithLayout
            exact
            component={EditWorkFlowAssignmentsView}
            layout={MainLayout}
            path="/workflow-assignment/edit-assignment/:id"
          />
          <RouteWithLayout
            exact
            component={ViewWorkFlowAssignmentsView}
            layout={MainLayout}
            path="/workflow-assignment/view-assignment/:id"
          />
          <RouteWithLayout
            exact
            component={WorkFlowAssignmentsMainView}
            layout={MainLayout}
            path="/workflow-assignment"
          />
          {/* <RouteWithLayout
          exact
          component={CalculationSimulationMaintenanceView}
          layout={MainLayout}
          path="/calc-simulation-maintenance"
        />
        <RouteWithLayout
          exact
          component={CalcSimulationResultView}
          layout={MainLayout}
          path="/calc-maintenance-result"
        />
        <RouteWithLayout
          exact
          component={ContractTrendsResultDetailView}
          layout={MainLayout}
          path="/contract-trends-maintenance"
              />*/}
          <Redirect to="/not-found" />
        </Switch>
      </Security>
    </Router>
  );
};

export default Routes;
