import React, { useEffect } from 'react';
import Chart from "react-apexcharts";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import clsx from 'clsx';
import { makeStyles, useTheme } from  "@mui/styles";
import {
    Grid, Select, FormLabel, MenuItem, Button, Typography, Checkbox,
    ListItemText, TextField, Drawer, AppBar, Toolbar, CssBaseline, Divider, IconButton
} from '@mui/material';
import { loadValuesBasedOnAppTypeAndField, getDefaultValues, getDefaultValuesKeyFigure, promPlanSalsIncentives, getCustomerMasterData, allowedApps } from '../../redux/actions';
import { connect } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { AllOtherAttributeMasterComponent, ChipDropdown, SearchDropdown } from "../../components/Inputs";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        position: 'absolute',
        flexShrink: 0,
        zIndex: 998
    },
    drawerPaper: {
        marginTop: 65
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        fontSize: theme.typography.h2.fontSize,
        paddingLeft: 10
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textInput: {
        border: '1px solid #E0E0E0',
        width: '100%',
        borderRadius: 5,
        color: '#1675e0'
    },
    select: {
        width: "100%"
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    buttonContainer:{
        justifyContent: "center",
        display: "flex",
        paddingTop: 28,
        paddingBottom: 30,
        marginTop:'-0.7rem'
    }
}));

const PromoPlanSalsIncentives = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,


    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [postingType, setPostingType] = React.useState('Accrual');
    const [keyFigureData, setKeyFigureData] = React.useState([]);
    const [mappingValuesSet, setMappingValuesSet] = React.useState({});
    const [keyFigureNameMR, setKeyFigureNameMR] = React.useState('');
    const [periodicity, setPeriodicity] = React.useState('day');
    const [listOpen, setListOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [usersId, setUsersId] = React.useState([]);
    const isAllSelected =
        Object.keys(mappingValuesSet).length > 0 && selected.length === Object.keys(mappingValuesSet).length;
    const [chartOptionsPP, setChartOptionsPP] = React.useState({});
    const [chartSeriesPP, setChartSeriesPP] = React.useState([]);
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === Object.keys(mappingValuesSet).length ? [] : usersId);
            return;
        }
        setSelected(newSelected)

    }
    function onAdd() {
        setAttributeName(selected)
        setListOpen(false)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])

    }
    const handleStartDate = (e) => {
        setStartDate(e);
        var date = 'start_date=' + Moment(e).format('MM/DD/YYYY') + '&end_date=' + Moment(endDate).format('MM/DD/YYYY')
    }
    const handleEndDate = (e) => {
        setEndDate(e);
        var date = 'start_date=' + Moment(startDate).format('MM/DD/YYYY') + '&end_date=' + Moment(e).format('MM/DD/YYYY')
    }
    function handleOpen() {
        setSelected(attributeName)
        setListOpen(true)
    }
    useEffect(() => {
        // props.onSubmit('reset_store');
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    useEffect(() => {
        props.onLoadingDefaultCR('Customer Rebate', 'Direct - Sales Data');
        props.onLoadingDefault('Customer Rebate', 'Direct - Sales Data');
        props.onCheckCustomerTable();
    }, []);
    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            if (props.dropdownDataCR.records[0].field_id === 'key_figure_name') {
                setKeyFigureData(props.dropdownDataCR.records[0].drop_down_value_keys);
            }
        }
    }, [props.dropdownDataCR])
    const [mappingValuesSetKF, setMappingValuesSetKF] = React.useState({});

    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            var commonKeys = props.dropdownDataCR.records[0].drop_down_value_keys;
            var keys = props.dropdownDataCR.records[0].drop_down_values;
            var myobj = {};
            keys.map(function (obj, index) {
                myobj[commonKeys[index]] = obj;
            });
            setMappingValuesSetKF(myobj);
        }

    }, [keyFigureData]);

    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [applicationType, setApplicationType] = React.useState('Customer Rebate');
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        props.onLoadingDefaultCR(applicationType, e.target.value);
        props.onLoadingDefault(applicationType, e.target.value);
    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
    }, []);

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = [];
            var tempNameArray = [];
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNumberList(tempArray)
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    const handlePostingType = (e) => {
        setPostingType(e.target.value);
    }

    const handleSubmit = () => {

        Object.entries(attributeObjectAPI).map(([key, value]) => {
            if (attributeName.includes(key)) {

            } else {
                delete attributeObjectAPI[key]
            }
        })
        var formData = {
            "application_type": applicationType,
            "data_source_type": sourceDataType,
            "posting_type": postingType,
            "start_date": Moment.utc(startDate).format('MM/DD/YYYY'),
            "end_date": Moment.utc(endDate).format('MM/DD/YYYY'),
            "periodicity": periodicity,
            "key_figure_name": keyFigureNameMR,
            "attributes": attributeObjectAPI
        }
        props.onSubmit(formData);
        setOpen(false);
    }
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeName, setAttributeName] = React.useState([]);

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records[0]) {
            if (props.dropdownData.records[0].field_id === "attribute_name") {
                setAttributeNameData(props.dropdownData.records[0].drop_down_value_keys);
            }
        }
    }, [props.dropdownData])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records[0]) {
            var commonKeys = props.dropdownData.records[0].drop_down_value_keys;
            var keys = props.dropdownData.records[0].drop_down_values;
            var myobj = {};
            keys.map(function (obj, index) {
                myobj[commonKeys[index]] = obj;
            });
            setMappingValuesSet(myobj);
            setUsersId(commonKeys)
        }

    }, [attributeNameData]);
    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    const handleAttributeValues = (value, item) => {
        var attributeObject = [];
        attributeObject.push({
            [item]: value
        });
        if (item === 'customer_number') {
            value = [value]
        }
        setAttributeObjectAPI(prevState => ({
            ...prevState,
            [item]: value
        }));

    }

    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    useEffect(() => {
        var tempCost = {};
        var tempIncentiveAmount = {};
        var tempKF = {};
        var tempCost = {};
        var tempProfit = {};
        var dataTempCost = [];
        var dataTempProfit = [];
        var dataTempIncentiveAmount = [];
        var dataTempKF = [];
        if (props.promPlanningSalesInc && props.promPlanningSalesInc.length > 0) {
            props.promPlanningSalesInc.map((item, i) => {
                tempCost['x'] = item.period;
                tempCost['y'] = item.cost;

                tempProfit['x'] = item.period;
                tempProfit['y'] = item.profit;

                tempIncentiveAmount['x'] = item.period;
                tempIncentiveAmount['y'] = item.incentive_amount;

                tempKF['x'] = item.period;
                tempKF['y'] = item.key_figure_value;

                dataTempCost[i] = tempCost
                dataTempProfit[i] = tempProfit;
                dataTempIncentiveAmount[i] = tempIncentiveAmount
                dataTempKF[i] = tempKF;

                tempCost = {}
                tempProfit = {}
                tempKF = {}
                tempIncentiveAmount = {}
            })

            setChartOptionsPP({
                chart: {
                    type: "line",
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                },
                xaxis: {
                    type: "date"
                },
                stroke: {
                    curve: "smooth",
                    width: 1
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            var value = val.toLocaleString();
                            return value
                        }
                    }
                }
            })

            setChartSeriesPP([
                {
                    name: "Cost",
                    data: dataTempCost

                },
                {
                    name: "Profit",
                    data: dataTempProfit

                },
                {
                    name: "Incentive Amount",
                    data: dataTempIncentiveAmount

                },
                {
                    name: "Revenue",
                    data: dataTempKF

                }
            ])
        }

    }, [props.promPlanningSalesInc])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar style={{ position: 'absolute', marginTop: 65, display: open && 'none' }}>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                        size="large">
                        <Menu />
                    </IconButton>
                    <Typography className={{ root: classes.header }} variant="h1" color='primary' style={{marginLeft:'1rem'}}> Revenue - Profit Trending </Typography>
                </Toolbar>
            </AppBar>
                <div>
                    {props.promPlanningSalesInc && props.promPlanningSalesInc.length > 0 ?
                        <Chart options={chartOptionsPP} type="line" series={chartSeriesPP} />

                        :
                        <Typography variant='h4'style={{marginTop:'5rem'}}>
                            There is no data to show now.
                        </Typography>
                    }

                </div>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="top"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                <Typography variant="h3" color='primary'> Revenue - Profit Trending </Typography>
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider
                    sx={{
                        opacity: "0.6"
                    }} />
                
                <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                <Grid container
                            item
                            md={4}
                            xs={12}>

                        <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} required
                                    style={{ marginBottom: 5 }}>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} required style={{ marginBottom: 5 }}>
                                    Source Data Type
                                </FormLabel>
                                <Select
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {sourceDataTypeDataFromAPI
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} required
                                    style={{ marginBottom: 5 }}>
                                    Posting Type
                                </FormLabel>
                                <Select
                                    value={postingType}
                                    onChange={handlePostingType}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'accrual'}>
                                        Accrual
                                    </MenuItem>
                                    <MenuItem value={'payment'}>
                                        Payment
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>
                        </Grid>
                        <Grid container
                            item
                            md={3}
                            xs={12}>

                        
                        <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} required style={{ marginBottom: 5 }}>
                                    Start Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    // borderRadius: 5,
                                                    color: '#1675e0',
                                                    // width: 209
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} required style={{ marginBottom: 5 }}>
                                    End Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    // borderRadius: 5,
                                                    color: '#1675e0',
                                                    // width: 209
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        </Grid>
                        <Grid container
                            item
                            md={4}
                            xs={12}>

                        
                        <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} required style={{ marginBottom: 5 }}>
                                    Periodicity
                                </FormLabel>
                                <Select
                                    value={periodicity}
                                    onChange={(e) => setPeriodicity(e.target.value)}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'day'}>
                                        Day
                                    </MenuItem>
                                    <MenuItem value={'month'}>
                                        Month
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} required style={{ marginBottom: 5 }}>
                                    Sales Driver
                                </FormLabel>
                                <Select
                                    value={keyFigureNameMR}
                                    onChange={(event) => setKeyFigureNameMR(event.target.value)}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value="">
                                        {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                    </MenuItem>
                                    {mappingValuesSetKF && Object.keys(mappingValuesSetKF).length > 0 &&
                                        Object.entries(mappingValuesSetKF).map(([key, value]) => {
                                            return (
                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                    {value}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel classes={{ root: classes.formLabel }} style={{ marginBottom: 5, marginRight: 15 }}>
                                    Filter Criteria
                                </FormLabel>
                                <Select
                                    value={attributeName}

                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpen}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                        Object.entries(mappingValuesSet).map(([key, value]) => {
                                            return (
                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, key)}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selected.indexOf(key) > -1} />
                                                    <ListItemText primary={value} />
                                                </MenuItem>
                                            )
                                        })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        </Grid>

                        {attributeName.map((item) => {
                            return (
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    {/* <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}> */}
                                        <FormLabel
                                            style={{ color: 'grey', fontSize: 14, marginBottom: 5, textTransform: 'capitalize' }}>
                                            {item.replace(/_/g, ' ')}
                                        </FormLabel>
                                        {item == 'material_number' || item == 'customer_number' || item == 'supplier_number' || item == 'employee_number' ?
                                            <div style={{ marginTop: -5 }}>
                                                <SearchDropdown id='payment_single' multiple
                                                    table={item == 'supplier_number' ? 'supplier_master' : item == 'employee_number' ? 'employee_master'
                                                        : item == 'material_number' ? 'material_master' : 'customer_master'}
                                                    keyName={item}
                                                    description={true}
                                                    onChange={(value) => handleAttributeValues(value, item)}
                                                />
                                            </div>
                                            :
                                            item === 'customer_chain' || item === 'customer_group' || item === 'material_group' || item === 'product_hierarchy' || item === 'company_code' || item === 'sales_org' || item === 'supplier_group' ?
                                                <div style={{ marginTop: -10 }}>
                                                    <AllOtherAttributeMasterComponent attributeName={item} value={attributeObjectAPI[item]} onChange={(e) => handleAttributeValues(e, item)} prevalue={attributeObjectAPI[item]} withOutLabel={true} id={item} multiple />
                                                </div>
                                                :
                                                <div style={{ marginTop: -15 }}>
                                                    <ChipDropdown id={item} data={attributeObjectAPI[item]} onChange={(value) => handleAttributeValues(value, item)}
                                                    />
                                                </div>
                                            }
                                    {/* </div> */}
                                </Grid>
                            );
                        })}
                        <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleSubmit}
                                    twoline="true"
                                    // disabled={startDate && endDate && calculationLevel ? false : true} 
                                >
                                    <DirectionsRunIcon />
                                </Button>
                            </div>
                    </Grid>
                    </Drawer>
                    {/* <div style={{ width: '100%', borderBottom: '1px solid #e5e5e5' }} />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                        <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit}  >
                            Apply
                        </Button>
                    </div> */}
                
            
        </div >
    );
}


const mapStateToProps = state => {
    return {
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        dropdownData: state.addMultipleConfigurationData.attributeNameData,
        customerMasterData: state.customerData.customerMasterData,
        allowedAppsData: state.initialData.allowedApps,
        promPlanningSalesInc: state.initialData.promPlanningSalesInc
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
        onSubmit: (data) => dispatch(promPlanSalsIncentives(data)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
        allowedApps: () => dispatch(allowedApps())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PromoPlanSalsIncentives);