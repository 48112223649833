import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Paper, Input, Fab, Typography } from '@mui/material';
import { Search, Check, AddBoxOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Edit, DeleteForever, LibraryAdd, CloudUpload, SearchSharp } from '@mui/icons-material';
import { addContractGeneralData, getCustomerMasterXRefData } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 16px',
  },
  searchInput: {
    marginRight: 47
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  rootSearch: {
    borderBottomColor: theme.palette.table.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    zIndex: 1
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize
  }
}));
const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));
const Toolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [searchText, setSearchText] = React.useState('');
  const addContract = () => {
    localStorage.removeItem('currentAddContractID');
    sessionStorage.setItem('mode', 'add');
    // history.push('contract-setup/add-contract');
  }
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  }
  return (
    <div
      {...rest}
      className={classes.root}
    >
      {/* <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => props.onSearch(searchText)}
                      >
                        <SearchSharp   color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                      </Fab> */}

      <Fab aria-label="edit" variant="extended"
        size='medium'
        classes={{ root: classes.fabContainer }}
        className={classes.fabContainer}
        //    component={CustomRouterLink}
        onClick={() => history.push('/contract-default-designer/add-contract-default')}
      >
        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
      </Fab>
    </div >
  );
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (data) => dispatch(addContractGeneralData(data)),
    onSearch: (keyword) => dispatch(getCustomerMasterXRefData(0, 100, keyword))
  }
}
export default connect(null, mapDispatchToProps)(Toolbar);