import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, Fab, Typography } from '@mui/material';
import { Edit, Storage, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getBomData, deleteCustomerMasterData, getLblDispDesFieldValue, getSalesBundleData } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { NavLink as RouterLink } from 'react-router-dom';
import { createTheme } from "@mui/material/styles";
import Moment from 'moment';
import { tableIcons } from '../../../components/Icons/TableIcons';
import RingLoader from "react-spinners/RingLoader";
import SimpleDialog from '../../../components/Dialog';
import secureLocalStorage from 'react-secure-storage';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));


const BomUserTable = props => {
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("");
    const [columns, setColumns] = React.useState([]);

    const action = {

        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_sales_bundle-' + sessionStorage.getItem('application')) &&
                    <>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => SalesHierarchy(rowData, 'tree')}
                            size="large">
                            <Storage color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>

                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editInventory(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>

                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleClickOpen(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    </>
                }
            </div>
    }

    useEffect(() => {
        var data = { "data_source": "sales_bundle_master" };
        props.getBom(1, 10);
        props.loadSourceDataType(data, 'post');
    }, []);

    function editInventory(id) {
        // localStorage.setItem('currentID', id);
        history.push({
            pathname: '/bom/edit-bom/' + id,
            id: id
        });
    }

    function SalesHierarchy(item, type) {
        props.getSalesBundleData(item.top_material_number)
        if (type == 'hierarchy') {
            history.push({
                pathname: '/bom/hierarchy/' + item.top_material_number,
                data: item
            });
        }
        else {
            history.push({
                pathname: '/bom/bom-tree/' + item.top_material_number,
                data: item
            });
        }
    }


    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteCustomerMasterData(currentId)
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };


    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));


    useEffect(() => {
        if (props.bomData && props.bomData.records && props.bomData.total_record > 0) {
            setDataRows(props.bomData.records);
        }
    }, [props.bomData]);


    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    if (item.key == 'incoming_royalty' || item.key == 'outgoing_royalty') {
                        newArray.push({
                            field: item.key,
                            title: item.current,
                            type: 'string',
                            editable: 'never',
                            render: (rowData) => <div> {rowData[item.key] == true ? 'Yes' : 'No'}</div>
                        })
                    }
                    else {
                        newArray.push({
                            field: item.key,
                            title: item.current,
                            type: item.key != 'formula_result' ? 'string' : 'Number',
                            editable: 'never',
                            render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
                                Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                        })
                    }

                })
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Sales Bundle </Typography>

                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_sales_bundle-' + sessionStorage.getItem('application')) &&
                            <>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    classes={{ root: classes.fabContainer }}
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/bom/add-bom'
                                >
                                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                                </Fab>

                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/bom/bom-excel-upload'
                                >
                                    <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                                </Fab>
                            </>
                        }
                    </div>

                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};


const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        bomData: state.customerData.bomData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBom: (pagination, limit) => dispatch(getBomData(pagination, limit, '')),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'SalesBundle')),
        getSalesBundleData: (top_material_number) => dispatch(getSalesBundleData(top_material_number)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BomUserTable);