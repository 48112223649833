import React, { useEffect, forwardRef, useRef } from 'react';
import {
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '26px 0px 26px 0px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
  
}));

const AssignSticker = forwardRef((props) => {

    const classes = useStyles();
    const { className } = props;
    const [promoFields, setPromoFields] = React.useState([]);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    useEffect(() => {
        if (props.budgetData.promotion_budget_benefits && props.budgetData.promotion_budget_benefits.length > 0) {    
                setPromoFields(props.budgetData.promotion_budget_benefits)
        }
    }, [props.budgetData]);
 
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                       
                        <div className={classes.container} >

                            {promoFields && promoFields.length > 0?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell align='center' style={{ minWidth: 150 }} rowSpan={2}>Promotion Name</TableCell>
                                                <TableCell align='center' style={{ minWidth: 180 }} rowSpan={2} >Promotion Description</TableCell>

                                                <TableCell align='center' colSpan={7}>Cost</TableCell>
                                                <TableCell align='center' colSpan={7}>Take Rate</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">Rebate</TableCell>
                                                <TableCell align="center" >Cov Type</TableCell>
                                                <TableCell align="center">Cov Length</TableCell>
                                                <TableCell align="center">SPIFF</TableCell>
                                                <TableCell align="center">F2 Interest</TableCell>
                                                <TableCell align="center">F2 Fin Structure</TableCell>
                                                <TableCell align="center"style={{ minWidth:50 }}>Extra</TableCell>
                                                <TableCell align="center">Global</TableCell>
                                                <TableCell align="center">Rebate</TableCell>
                                                <TableCell align="center">Cov</TableCell>
                                                <TableCell align="center">SPIFF</TableCell>
                                                <TableCell align="center">Std Fin</TableCell>
                                                <TableCell align="center">Promo Fin</TableCell>
                                                <TableCell align="center"style={{ minWidth:50 }}>Extra</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {(() => {
                                                let td = [];


                                                promoFields && promoFields

                                                    .map((formItem, i) => {


                                                        td.push(<>
                                                            <StyledTableRow key={i}>
                                                                <TableCell >
                                                                    {promoFields[i]['promotion_name'] ? promoFields[i]['promotion_name'] : ''}
                                                                </TableCell>
                                                                <TableCell >
                                                                    {promoFields[i]['promotion_description'] ? promoFields[i]['promotion_description'] : ''}
                                                                </TableCell>
                                                                <TableCell >
                                                                    {promoFields[i]['benefit_rebate'] ? promoFields[i]['benefit_rebate'] : ''}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {promoFields[i]['benefit_coverage_type'] ? promoFields[i]['benefit_coverage_type'] : ''}

                                                                </TableCell>
                                                                <TableCell>

                                                                    {promoFields[i]['benefit_coverage_length'] ? promoFields[i]['benefit_coverage_length'] : ''}

                                                                </TableCell>
                                                                <TableCell>

                                                                    {promoFields[i]['benefit_spiff'] ? promoFields[i]['benefit_spiff'] : ''}
                                                                </TableCell>
                                                                <TableCell>

                                                                    {promoFields[i]['benefit_promo_finance_interest'] ? promoFields[i]['benefit_promo_finance_interest'] : ''}

                                                                </TableCell>
                                                                <TableCell>

                                                                    {promoFields[i]['benefit_promo_finance_term_structure'] ? promoFields[i]['benefit_promo_finance_term_structure'] : ''}

                                                                </TableCell>
                                                                <TableCell>
                                                                    {promoFields[i]['benefit_extra_rebate'] ? promoFields[i]['benefit_extra_rebate'] : ''}

                                                                </TableCell>
                                                                <TableCell>
                                                                    {promoFields[i]['takerate_global'] ? promoFields[i]['takerate_global'] : ''}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {promoFields[i]['takerate_rebate'] ? promoFields[i]['takerate_rebate'] : ''}

                                                                </TableCell>
                                                                <TableCell>

                                                                    {promoFields[i]['takerate_coverage'] ? promoFields[i]['takerate_coverage'] : ''}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {promoFields[i]['takerate_spiff'] ? promoFields[i]['takerate_spiff'] : ''}

                                                                </TableCell>
                                                                <TableCell>

                                                                    {promoFields[i]['takerate_std_finance'] ? promoFields[i]['takerate_std_finance'] : ''}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {promoFields[i]['takerate_promo_finance'] ? promoFields[i]['takerate_promo_finance'] : ''}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {promoFields[i]['takerate_extra_rebate'] ? promoFields[i]['takerate_extra_rebate'] : ''}
                                                                </TableCell>


                                                            </StyledTableRow>

                                                        </>);

                                                    })
                                                return td;

                                            })()}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography>
                                    There is no data to show
                                </Typography>
                            }

                        </div>
                    </form>
                </Card>
            </div >
        </div>
    );

});



const mapStateToProps = state => {
    return {
        budgetData: state.promotionData.promotionSetupSingleData,
    }
};

export default connect(mapStateToProps, null)(AssignSticker);