import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs,
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton,
    Input,
    Dialog,
    DialogActions,
    Button,
    Select,
    MenuItem,
    Grid,
    OutlinedInput,
    FormLabel, FormControlLabel, Radio, RadioGroup
} from '@mui/material';
import { TextInputWithLabel } from '../../components/Inputs';
import { connect } from 'react-redux';
import Moment from 'moment';
import { history } from '../../components/Helpers';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { savePromotionPlan } from '../../redux/actions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '15px 10px 15px 10px',
        alignItems: 'center'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    inputContainer: {
        width: '90%',
        paddingLeft: 15,
        marginRight: 0,
        height: 'inherit',
        justifyContent: 'center',
        fontSize: 14,
        color: 'black'
    },
    input: {
        color: 'grey',
        width: '90%',
        paddingLeft: 15,
        marginRight: 0,
        height: 'inherit',
        justifyContent: 'center',
        fontSize: 14
    },
    paper: {
        minWidth: window.screen.width * .30,
        padding: "30px 30px 10px 30px"

    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "30px 30px 10px 30px"
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const PromotionResult = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [open, setOpen] = React.useState(false);
    const [locked, setLocked] = React.useState(false);
    const [selected, setSelected] = React.useState([])
    const [ratio, setRatio] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [active, setActive] = React.useState(false);
    const [promotionData, setPromotionData] = React.useState([])
    const [keyFigure, setKeyFigure] = React.useState(localStorage.getItem('key-figure')? localStorage.getItem('key-figure').replace(/['"]+/g, '') : '');
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [operator, setOperator] = React.useState('+');
    const [save, setSave] = React.useState(false);
    const [plannedStartDate, setPlannedStartDate] = React.useState(null)
    const [plannedEndDate, setPlannedEndDate] = React.useState(null)
    const [description, setDescription] = React.useState('');
    const [level, setLevel] = React.useState(localStorage.getItem('promotion-level')? localStorage.getItem('promotion-level').replace(/['"]+/g, '') : '');

   

    useEffect(() => {
        var data = []
        if (props.promotionData) {
            setPromotionData(props.promotionData)
        }

    }, [props.promotionData])
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    function editIncentiveRatio(item) {
        setOpen(true)
        setSelected(item)
    }
    function handleCancelDialog() {
        setOpen(false)
        setRatio('')
        setActive(false)
        setAmount('')
        setLocked(false)
        setRadioGroupValue('')
        setOperator('+')
        setSave(false)
        setPlannedStartDate(null)
        setPlannedEndDate(null)
        setDescription('')
    }
    function handleSumbitDialog() {

        if (radioGroupValue) {
            if (radioGroupValue == 'static') {
                promotionData.map((item, index) => {
                    promotionData[index]['planned_incentive_ratio'] = ratio;
                    var incentiveAmount = checkamount(item.key_figure_value, ratio)
                    promotionData[index]['planned_incentive_amount'] = incentiveAmount;

                })
            }
            else {
                var value = 0
                if (ratio.includes('%')) {
                    var value = parseFloat(ratio.split('%'))
                }
                else {
                    value = ratio
                }
                promotionData.map((item, index) => {
                    var newRatio = 0
                    var current = item['planned_incentive_ratio'] ? item['planned_incentive_ratio'] : item['incentive_ratio']
                    if (ratio.includes('%')) {
                        value = (value * parseFloat(current)) / 100
                    }
                    if (operator == '+') {
                        newRatio = parseFloat(current) + parseFloat(value)
                        setRatio(newRatio)
                    }
                    else {
                        newRatio = parseFloat(current) - parseFloat(value)
                        setRatio(newRatio)
                    }
                    promotionData[index]['planned_incentive_ratio'] = newRatio.toString();
                    var incentiveAmount = checkamount(item.key_figure_value, newRatio)
                    promotionData[index]['planned_incentive_amount'] = incentiveAmount;

                })
            }

        }
        else {
            console.log(level)
            promotionData.map((item, index) => {

                if (item.promolevel == selected.promolevel) {
                    promotionData[index]['planned_incentive_ratio'] = ratio;
                    promotionData[index]['planned_incentive_amount'] = amount;
                }
            })
        }
        setPromotionData(promotionData)
        handleCancelDialog()
    }
    function handleAdd() {

        if (keyFigure) {
            var key = selected.key_figure_value
            var value = checkamount(key, ratio)
            setAmount(value)
        }
        setActive(true)
    }
    function checkamount(keyFigureValue, currentRatio) {
        var value = (Number(keyFigureValue) * parseFloat(currentRatio)) / 100
        return value
    }
    const handleStartDate = (value) => {
        setPlannedStartDate(value);
    }
    const handleEndDate = (value) => {
        setPlannedEndDate(value);
    }
    function handleOnSumbit() {

        promotionData.map((item, index) => {
            if (!item.planned_incentive_ratio || !item.planned_incentive_amount) {
                promotionData[index]['planned_incentive_ratio'] = item.incentive_ratio;
                promotionData[index]['planned_incentive_amount'] = parseFloat(item.incentive_amount);
            }
            promotionData[index]['incentive_amount'] = parseFloat(item.incentive_amount);
            promotionData[index]['start_date'] = Moment.utc(item.start_date).format('MM/DD/YYYY')
            promotionData[index]['end_date'] = Moment.utc(item.end_date).format('MM/DD/YYYY')
            promotionData[index]['planned_start_date'] = Moment(plannedStartDate).local().format('MM/DD/YYYY');
            promotionData[index]['planned_end_date'] = Moment(plannedEndDate).local().format('MM/DD/YYYY');
            promotionData[index]['promotion_description'] = description;
            promotionData[index]['key_figure_name'] = keyFigure;
            promotionData[index]['promotion_level'] = level;
            promotionData[index]['promotion_value'] = item.promolevel
            promotionData[index]['profitability'] = parseFloat(item.profitability_rate)
            promotionData[index]['profit'] = parseFloat(item.profit)
            promotionData[index]['cost'] = parseFloat(item.cost)
            delete  promotionData[index]['promolevel']
            delete  promotionData[index]['profitability_rate']
        })
        
        props.onSave(promotionData)
       
    }
    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb" style={{marginTop:'1.4rem',marginLeft:'1.1rem'}}>
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/promotion-trends'
                >
                    Promotion
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ display: 'flex' }}>

                    <TextInputWithLabel heading={'Planned Incentive Ratio'} twoline='true' onChange={(e) => setRatio(e)}
                        prevalue={selected.planned_incentive_ratio ? parseFloat(selected.planned_incentive_ratio).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : selected.incentive_ratio} />

                    <Button
                        color='secondary'
                        variant="contained"
                        startIcon={<CheckIcon />}
                        style={{ marginTop: 38, height: 40, width: 40, marginLeft: 10 }}
                        classes={{
                            startIcon: classes.startIcon,
                            root: classes.checkIconContainer
                        }}
                        disabled={ratio ? false : true} onClick={() => handleAdd()}
                    >
                    </Button>
                </div>
                <div style={{ display: active ? 'block' : 'none' }}>
                    <FormLabel style={{ color: 'grey' }}>Planned Incentive Amount</FormLabel>
                    <div style={{ paddingTop: 10, margin: 0, color: 'grey' }}>
                        {parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </div>

                </div>

                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" onClick={handleSumbitDialog} disabled={ratio && amount ? false : true}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={() => setLocked(false)}
                aria-labelledby="simple-dialog-title"
                open={locked}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <RadioGroup aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                    <FormControlLabel
                        value="static"
                        control={<Radio color="primary" />}
                        label="Static Incentive Ratio"

                    />
                    <div style={{ width: '63%' }}>
                        <TextInputWithLabel twoline='true' onChange={(e) => setRatio(e)} style={{ marginLeft: 30 }}
                            disabled={radioGroupValue != 'static' ? true : false}
                        />
                    </div>
                    <FormControlLabel
                        value="dynamic"
                        control={<Radio color="primary" />}
                        label="Dynamic Incentive Ratio"


                    />
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginLeft: 30 }} >
                        <Select
                            value={operator}
                            onChange={(e) => setOperator(e.target.value)}
                            style={{ marginTop: 3 }}
                            displayEmpty
                            className={clsx({
                                [classes.select]: true
                            })}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                        >

                            <MenuItem value={'+'} key={'add'}>
                                +
                            </MenuItem>
                            <MenuItem value={'-'} key={'sub'}>
                                -
                            </MenuItem>
                        </Select>
                        <TextInputWithLabel twoline='true' onChange={(e) => setRatio(e)}
                            disabled={radioGroupValue != 'dynamic' ? true : false}
                        />
                    </div>
                </RadioGroup>


                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained"
                        onClick={handleSumbitDialog} disabled={ratio ? false : true}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={() => setSave(false)}
                aria-labelledby="simple-dialog-title"
                open={save}
                disableEscapeKeyDown
                classes={{ paper: classes.paperContainer }}>
                <div className={classes.container}>
                    <Grid container spacing={2} style={{ padding: '16px 25px 16px 25px' }}>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <FormLabel className={classes.formLabel} required>
                                Planned Start Date
                            </FormLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Grid container justifyContent="space-around">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '1px 0 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                width: '100%'
                                            }
                                        }}
                                        value={plannedStartDate}
                                        onChange={handleStartDate}
                                        format="MM/DD/YYYY"
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <FormLabel className={classes.formLabel} required>
                                Planned End Date
                            </FormLabel>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Grid container justifyContent="space-around">
                                    <KeyboardDatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                            padding: 0,
                                            disableUnderline: true,
                                            style: {
                                                padding: '1px 0 1px 11px',
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #E0E0E0',
                                                width: '100%'
                                            }
                                        }}
                                        value={plannedEndDate}
                                        onChange={handleEndDate}
                                        format="MM/DD/YYYY"
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>

                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <FormLabel className={classes.formLabel} required>
                                Promotion Description
                            </FormLabel>
                            <OutlinedInput
                                value={description}
                                inputProps={{ min: "0", step: "1" }}
                                classes={{ root: classes.inputTwoLine }}
                                onChange={(e) => setDescription(e.target.value)}
                            />

                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained"
                        onClick={handleOnSumbit} disabled={plannedStartDate && plannedEndDate && description ? false : true}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={clsx(classes.root, className)} >
                <div className={clsx({
                    [classes.row]: isDesktop,
                })} >
                    <Typography variant="h2"> Promotion Results </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <Button style={{ marginRight: 20 }} color="primary" variant="contained" onClick={() => setLocked(true)} >
                            Adjust Incentive Ratio
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => setSave(true)}>
                            Save
                        </Button>
                    </div>
                </div>

                {promotionData && promotionData.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow style={{ textTransform: 'capitalize' }}>
                                    <TableCell align='center' className={classes.tableCell} >Application Type</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Start Date</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >End Date</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Posting Type</TableCell>
                                    {level && <TableCell align='center' className={classes.tableCell} > {level.replace(/_/g, ' ')}</TableCell>}
                                    {keyFigure && <TableCell align='center' className={classes.tableCell} > {keyFigure.replace(/_/g, ' ')}</TableCell>}
                                    <TableCell align='center' className={classes.tableCell} >Incentive Amount</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Cost</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Profit</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Profitability</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Incentive Ratio</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Planned Incentive Amount</TableCell>
                                    <TableCell align='center' className={classes.tableCell} >Planned Incentive Ratio</TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }} >
                                {promotionData && promotionData.map(item => {
                                    return (
                                        <StyledTableRow >
                                            <TableCell align='center'>{item.application_type}</TableCell>
                                            <TableCell align='center'>{(Moment.utc(item.start_date).format('MM/DD/YYYY')).toString()}</TableCell>
                                            <TableCell align='center'>{(Moment.utc(item.end_date).format('MM/DD/YYYY')).toString()}</TableCell>
                                            <TableCell align='center'>{item.posting_type}</TableCell>
                                            {level && <TableCell align='center'>{item.promolevel}</TableCell>}
                                            {keyFigure && <TableCell align='center'>{item.key_figure_value}</TableCell>}
                                            <TableCell align='center'>{parseFloat(item.incentive_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                            <TableCell align='center'>{item.cost}</TableCell>
                                            <TableCell align='center'>{item.profit}</TableCell>
                                            <TableCell align='center'>{item.profitability_rate}</TableCell>
                                            <TableCell align='center'>{item.incentive_ratio}</TableCell>
                                            <TableCell align='center' style={{ color: item.planned_incentive_amount && item.planned_incentive_amount != item.incentive_amount ? 'black' : 'grey' }}>
                                                {parseFloat(item.planned_incentive_amount ? item.planned_incentive_amount : item.incentive_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </TableCell>
                                            <TableCell align='center' style={{ display: 'flex', height: 'inherit' }} readOnly={false}>
                                                <Input
                                                    value={item.planned_incentive_ratio ? parseFloat(item.planned_incentive_ratio).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : item.incentive_ratio}
                                                    inputProps={{ className: item.planned_incentive_ratio && item.planned_incentive_ratio != item.incentive_ratio ? classes.inputContainer : classes.input }}
                                                />
                                                <IconButton
                                                    style={{ alignItems: 'right', padding: 0 }}
                                                    onClick={() => editIncentiveRatio(item)}
                                                    size="large">
                                                    <EditIcon style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </TableCell>

                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSave: (data) => dispatch(savePromotionPlan(data))
    }
}


const mapStateToProps = state => {
    return {
        promotionData: state.promotionData.promotionData
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(PromotionResult);
