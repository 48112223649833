import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    Button, Breadcrumbs, Fab
} from '@mui/material';
import { Edit, Visibility, AddBoxOutlined, Publish, DeleteForever } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {

    getOutgoingClaimDownloadFormat,
    outgoingClaimDownloadFormat, uploadEDIFile
} from '../../../redux/actions';
import { Search, Check, CloudUpload, LibraryAdd, PersonAdd } from '@mui/icons-material';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    }
}));
const IncomingClaimFormatMain = props => {
    useEffect(() => {
        props.getOutgoingClaimDownloadFormatData();
    }, []);
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [quota, setQuota] = useState([]);

    useEffect(() => {
        if (props.outgoingClaimDownloadFormatData) {
            setQuota(props.outgoingClaimDownloadFormatData)
        }
    }, [props.outgoingClaimDownloadFormatData]);

    function editQuery(item) {
        sessionStorage.setItem('outgoing-claim-format', JSON.stringify(item))
        history.push('/customer-outgoing-claim-download-format/edit-query/' + item.id);

    }
    function viewQuery(item) {
        sessionStorage.setItem('outgoing-claim-format', JSON.stringify(item))
        history.push('/customer-outgoing-claim-download-format/view-query/' + item.id);
    }
    function deleteQuery(item) {
        props.deleteOutgoingClaimDownloadFormatData(item, item.id);
    }
    function handleClickUploadEvent() {
        inputFile.current.click();
    }
    const handleFileUpload = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadEDIFile(data);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/customer-outgoing-claims'
                    >
                        Outgoing Claim
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Outgoing Claim Download Format</Typography>
                </Breadcrumbs>
                <input type='file'
                    accept=".xlsx, .xls, .csv"
                    id='file' ref={inputFile} style={{ display: 'none' }}
                    onChange={(e) => handleFileUpload(e)} />
                <div style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => history.push('/customer-outgoing-claim-download-format/add-query')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                    {/* <Fab aria-label="edit" variant="extended"
                    size='medium'
                    className={classes.fabContainer}
                    onClick={() => handleClickUploadEvent()}
                    >
                    <CloudUpload   color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload EDI Format</Typography>
                    </Fab> */}
                </div>
            </div>

            {
                quota && quota.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center'>Format ID</TableCell>
                                    <TableCell align='center'>Format</TableCell>
                                    <TableCell align='center'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {quota
                                    .sort((a, b) => a.id > b.id ? 1 : -1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.id} >
                                                <TableCell align='center' style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                                <TableCell align='center' style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' width={300}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <Edit color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <Visibility color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>

                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => deleteQuery(item)}
                                                            size="large">
                                                            <DeleteForever color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        outgoingClaimDownloadFormatData: state.dataSetupData.outgoingClaimDownloadFormatData,
        loading: state.dataSetupData.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteOutgoingClaimDownloadFormatData: (data, id) => dispatch(outgoingClaimDownloadFormat('download', data, id, 'delete')),
        getOutgoingClaimDownloadFormatData: () => dispatch(getOutgoingClaimDownloadFormat('download')),
        uploadEDIFile: (file) => dispatch(uploadEDIFile(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomingClaimFormatMain);