import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery, Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { getCurrencyConversionData } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { tableIcons } from '../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { createTheme } from "@mui/material/styles";
import Moment from 'moment';

const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    }
}));

const CurrencyConversion = props => {
    useEffect(() => {
        props.getCurrencyConversionData();
    }, []);
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [date, setDate] = React.useState(null);
    const [base, setBase] = React.useState('');
    const columns = [
        {
            field: 'date',
            title: 'Date',
            editable: 'never',
            filtering: false,
            render: (rowData) => <a className={classes.hover} >{Moment(date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'currency',
            title: 'Currency',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} >{rowData.currency}</a>
        },
        {
            field: 'conversion',
            title: 'Rate ( In ' + base + ' )',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} >{rowData.conversion}</a>
        },

    ];
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        var tempRows = [];

        if (props.conversionData) {
            setBase(props.conversionData.base)
            setDate(props.conversionData.date)
            Object.entries(props.conversionData.rates)
                .map(item => {
                    tempRows.push({
                        currency: item[0],
                        conversion: item[1]
                    });


                })
            setDataRows(tempRows);
        }
    }, [props.conversionData]);
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading ... Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Currency Conversion </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >

                    </div>
                </div>
                {props.conversionData ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '16px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        conversionData: state.customerData.currencyData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrencyConversionData: () => dispatch(getCurrencyConversionData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyConversion);