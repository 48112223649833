import React from 'react';
import { Typography, Grid } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {  AttributeCountingTab } from '../Configuration/containers';
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 23,
        marginTop: -20,
        padding: '20px 16px',
    }
}));
const AttributeCounts = props => {
    const classes = useStyles();
    const { className } = props;
    return (
        <div>
            <div  className={clsx(classes.root, className)}>
             <AttributeCountingTab/>
            </div>
        </div >
    );
};
export default AttributeCounts;