import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton, Button,
    Checkbox
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { connect } from 'react-redux';
import { getExceptionManagementAll, addExceptionManagement } from '../../../redux/actions';
import { getExceptionsTableData, newAPIForAccRe, getAccessData, changeExceptionRequestDetails, getExceptionViewData, downloadSheet, batchExceptionApprove } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Toolbar2 } from '.';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import secureLocalStorage from 'react-secure-storage';
import Tooltip from "@mui/material/Tooltip";
import ChangeCircleIcon from '@mui/icons-material/BubbleChart';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '5px 0px 0px 0px',
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: -16
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    container: {
        padding: 10
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const ExceptionManagementMain = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [userStore, setUserStore] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    useEffect(() => {
        props.getExceptionManagementAll(1, 10);
    }, []);
    useEffect(() => {
        if (props.exceptionManagementAll) {
            setUserStore(props.exceptionManagementAll);
        }
    }, [props.exceptionManagementAll]);
    const handleCheckAll = () => {
        var IDS = [];
        props.exceptionManagementAll && props.exceptionManagementAll.map(item => {
            IDS.push(item.ID)
        })
        setSelected(IDS);
        if (selected.length > 0) {
            setSelected([])
        }
    }
    const approveException = () => {
        props.batchExceptionApprove(selected, 'acc', null);
        setTimeout(() => {
            props.getExceptionManagementAll();
        }, 1000);
    };
    const rejectException = () => {
        props.batchExceptionApprove(selected, 'rej', null);
        setTimeout(() => {
            props.getExceptionManagementAll();
        }, 1000);
    };
    const handleClick = (event, name, item, type) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(name);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Exception Approval</Typography>
                    {/* { secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_exception-'+localStorage.getItem('application'))  */}
                    {userStore && userStore.length > 0 &&
                        <div className={classes.buttonRoot}>
                            <Button
                                variant="contained"
                                color="primary"
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={approveException}
                                startIcon={<CheckIcon />}
                                style={{ margin: 10 }}
                                disabled={!selected.length > 0 ? true : false}
                            >
                                Approve
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ClearIcon />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={rejectException}
                                style={{ margin: 10, backgroundColor: '#FA5858' }}
                                disabled={!selected.length > 0 ? true : false}
                            >
                                Reject
                            </Button>
                        </div >
                    }
                </div>
                {userStore && userStore.length > 0 ?
                    <TableContainer style={{ marginTop: -15 }}>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell>
                                        <Checkbox
                                            onChange={handleCheckAll}
                                        />
                                    </TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Application Type</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Contract Number</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Exception Identifier</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Rule Exception</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Identifier Value</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Start Date</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>End Date </TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Exception Type</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Exception Value</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>Exception  Reason</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {userStore.map(item => {
                                    const isItemSelected = isSelected(item.ID);
                                    return (
                                        <StyledTableRow key={item.ID} className={classes.hover}
                                            hover
                                            onClick={(event) => handleClick(event, item.ID, item)}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color='primary'
                                                    checked={isItemSelected}
                                                    key={item.ID}
                                                    selected={isItemSelected}
                                                />
                                            </TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.application_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.contract_number}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.exception_mapping != null && Object.keys(item.exception_mapping).length > 0 ? Object.keys(item.exception_mapping).toString().replace(/_/g, ' ') + '' : ''}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>
                                                {item.exception_mapping && item.exception_mapping.rule_exception ? Object.entries(item.exception_mapping.rule_exception).toString() : ''}
                                            </TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>
                                                {Object.keys(item.exception_mapping).map(key => {
                                                    if (key !== "rule_exception" && item.exception_mapping[key] != null) {
                                                        if (Array.isArray(item.exception_mapping[key])) {
                                                            return item.exception_mapping[key].join(', ');
                                                        } else if (typeof item.exception_mapping[key] === 'object') {
                                                            // Display only the "key" property if it's an object
                                                            return item.exception_mapping[key].key;
                                                        } else {
                                                            return item.exception_mapping[key].toString();
                                                        }
                                                    }
                                                    return '';
                                                }).filter(Boolean).join(', ')}
                                            </TableCell>

                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{Moment(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{Moment(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.exception_type.replace(/_/g, ' ')}</TableCell>
                                            {/* <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.exception_value}</TableCell> */}
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>
                                                {item.exception_value && parseFloat(item.exception_value).toLocaleString()}
                                            </TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.exception_reason}</TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.customerData.loading,
        exceptionManagementAll: state.customerData.exceptionManagementAll,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getExceptionManagementAll: () => dispatch(getExceptionManagementAll()),
        batchExceptionApprove: (items, status, itemsApproved) => dispatch(batchExceptionApprove(items, status, itemsApproved)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExceptionManagementMain);