import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Table, TableCell, TableHead,
    TableRow, TableContainer, TableBody, Button, Dialog, IconButton,
    DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, DialogContentText, Fab
} from '@mui/material';
import { Publish, AddBoxOutlined, Visibility, DeleteForever, Edit, LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getProductGroupFormatData,
    deleteProductGroupFormatData,
    uploadProductGroupFile
} from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { cloneMasterQuery } from '../../redux/actions/DataSetup/DataSetupTransaction/AuthAction';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const ProductGroupExcelUpload = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [productGroup, setProductGroup] = useState([]);
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)
    const inputBatchFile = useRef(null)
    const [openDelete, setOpenDelete] = useState(false);
    const [currentBatchEditableID, setCurrentBatchEditableID] = useState('');
    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadproductGroupFile(currentEditableID, data);
        setOpen(true)
    }
    const handleBatchDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadproductGroupFile(currentBatchEditableID, data);
        setOpen(true)
    }

    useEffect(() => {
        props.getProductGroupFormatData();
        sessionStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        props.getProductGroupFormatData();
        sessionStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        if (props.productGroupFormatData) {
            setProductGroup(props.productGroupFormatData)
        }
    }, [props.productGroupFormatData]);
    useEffect(() => {
        if (props.productGroupErrorList) {
            setErrorList(props.productGroupErrorList)
            if (Object.entries(props.productGroupErrorList).length > 0 && props.productGroupErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.productGroupErrorList])
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function runBatchQuery(item) {
        setCurrentBatchEditableID(item.id);
        inputBatchFile.current.click();
    }
    function editQuery(item) {
        sessionStorage.setItem('product-group-query', JSON.stringify(item))
        history.push('/product-group/product-group-excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        sessionStorage.setItem('product-group-query', JSON.stringify(item))
        history.push('/product-group/product-group-excel-upload/view/' + item.id);
    }
    function deleteQuery(item) {
        props.deleteproductGroupFormatData(item.id);
    }
    const clone = (item) => {
        props.cloneMasterQuery(item.id);
    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>
                    Product Group Excel Upload</Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={() => history.push('/product-group/product-group-excel-upload/add-query')}
                >
                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                </Fab>
            </div>
            {
                productGroup && productGroup.length > 0 ?
                    <TableContainer style={{ marginTop: -20 }}>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {productGroup
                                    .sort(function (a, b) {
                                        return a.id > b.id ? 1 : -1;
                                    })
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.id} >
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' width={300}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => editQuery(item)}
                                                                size="large">
                                                                <Edit color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <IconButton onClick={() => clone(item)} classes={{ root: classes.IconButton }} size={'small'}>
                                                                <img src={require('../../library/images/Copy.png')} alt='' />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => viewQuery(item)}
                                                                size="large">
                                                                <Visibility color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <input type='file'
                                                                accept=".xlsx, .xls, .csv"
                                                                id='file' ref={inputFile} style={{ display: 'none' }}
                                                                onChange={(e) => handleDrop(e)} />
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => runQuery(item)}
                                                                size="large">
                                                                <Publish color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <input type='file'
                                                                accept=".xlsx, .xls, .csv"
                                                                id='file' ref={inputBatchFile} style={{ display: 'none' }}
                                                                onChange={(e) => handleBatchDrop(e)} />
                                                            {/* <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => runBatchQuery(item)}
                                                                size="large">
                                                                <UploadFileIcon color="disabled" classes={{ root: classes.caption }} disabled={true} />
                                                            </IconButton> */}
                                                            <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => {
                                                                        setOpenDelete(true);
                                                                    }}
                                                                    size="large">
                                                                    <DeleteForever color="disabled" classes={{ root: classes.caption }} disabled={true} />
                                                                </IconButton>
                                                                <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>

                                                                    <DialogContent classes={{
                                                                        root: classes.dialogContent
                                                                    }}>
                                                                        <Typography variant="h4">
                                                                            <DialogContentText>
                                                                                Do you want to delete data?
                                                                            </DialogContentText>
                                                                        </Typography>
                                                                    </DialogContent>
                                                                    <DialogActions classes={{
                                                                        root: classes.dialog
                                                                    }}>
                                                                        <Button onClick={() => setOpenDelete(false)} autoFocus color="primary" variant="outlined">No</Button>
                                                                        <Button onClick={() => {
                                                                            deleteQuery(item);
                                                                            setOpenDelete(false);
                                                                        }} color="primary" autoFocus variant="contained">Yes</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
            <Dialog
                fullWidth
                maxWidth="xs"
                open={errorDialogOpen}
            >
                <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                    <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                </DialogActions>
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>

                    <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        productGroupFormatData: state.customerData.productGroupFormatData,
        loading: state.dataSetupData.loading,
        productGroupErrorList: state.customerData.productGroupErrorList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadproductGroupFile: (id, file) => dispatch(uploadProductGroupFile(id, file)),
        deleteproductGroupFormatData: (id) => dispatch(deleteProductGroupFormatData(id)),
        getProductGroupFormatData: () => dispatch(getProductGroupFormatData()),
        cloneMasterQuery: (id) => dispatch(cloneMasterQuery(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroupExcelUpload);