import React, { useEffect, useRef } from 'react';
import {
    Card, Button, Divider
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DialogTitle from '@mui/material/DialogTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import MaterialTable from "material-table";
import { tableIcons } from "../../../../components/Icons/TableIcons";
import { NotesContainer } from '../../../SharedPages';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        padding: 15
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    notesDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 10
    },
    cardRoot: {
        backgroundColor: theme.palette.white,
        boxShadow: '1px 2px 4px #9E9E9E',
        borderRadius: 6,
        margin: '10px 20px 10px 20px',
        padding: 15
    },
    commentHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'grey',
        fontSize: 13
    },
    dividerColor: {
        background: theme.palette.border.main,
        margin: '6px 0px 10px 0px'
    },
    dialogTitleRoot: {
        padding: '9px 24px'
    },
    dialogController: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

const InternalNotes = (props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    {props.contractData && props.contractData.notes && props.contractData.notes.filter(item => item.note_type != "Submit Decision").length > 0 &&
                        <div>
                            <NotesContainer data={props.contractData.notes.filter(item => item.note_type == "Comment")} mode='view' />
                            {/* <MaterialTable
                                 components={{
                                  Toolbar: (props) => (
                                    <div
                                      style={{
                                        height: "0px",
                                      }}
                                    ></div>
                                    ),
                                  }}
                                title={" "}
                                editable={true}
                                icons={tableIcons}
                                columns={columns}
                                data={props.contractData.notes.filter(item => item.note_type != "Submit Decision")}
                                style={{marginTop:'-18px',fontSize: theme.typography.h4.fontSize}}
                                options={{
                                  search: false,
                                  sorting:true,
                                  emptyRowsWhenPaging:false,
                                  headerStyle: { 
                                    fontSize: theme.typography.h4.fontSize ,
                                    whiteSpace: 'nowrap',
                                    padding:'5px 0px 5px 2px', 
                                    textAlign: "center",},
                                  filtering: true,
                                  cellStyle: {
                                    fontSize: theme.typography.h4.fontSize,
                                    textAlign: "center",
                                    borderRight: "1px solid #EEEEEE",
                                    color: "black !important",
                                    padding:'5px 0px',
                                   whiteSpace: 'nowrap',
                                    overflow:'hidden',
                                    textOverflow:'ellipsis',
                                    maxWidth:150,
                                    width:80,
                                    minWidth:50,
                                   
                                  },
                                }} 
                              />*/}
                            {/* {props.contractData.notes.filter(item => item.note_type != "Submit Decision").map(item => (
                                <div className={classes.cardRoot}>
                                    <div className={classes.commentHeader}>
                                        <div>{item.created_by.username}</div>
                                        <div>{Moment(item.CreatedAt).local().format("MM/DD/YYYY hh:mm:ss")}</div>
                                    </div>
                                    <Divider classes={{ root: classes.dividerColor }} />
                                    <div>
                                        {item.text}
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        {item.attachments && item.attachments.length > 0 &&
                                            <div>
                                                {item && item.attachments[0].substring(item.attachments[0]).split('.').pop() === 'pdf' &&
                                                    <a href={item.attachments[0]} target="_blank">
                                                        <img src={require('../../../../library/images/pdf.png')} height={25} style={{ paddingRight: 10 }} />
                                                    </a>
                                                }
                                                {item && item.attachments[0].substring(item.attachments[0]).split('.').pop() === 'xlsx' &&
                                                    <a href={item.attachments[0]} target="_blank">
                                                        <img src={require('../../../../library/images/excel.png')} height={25} style={{ paddingRight: 10 }} />
                                                    </a>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))} */}
                        </div>
                    }
                </form>
            </Card>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
                maxWidth={'lg'}>
                <DialogTitle id="form-dialog-title" onClose={handleClose} classes={{ root: classes.dialogTitleRoot }}>
                    <div style={{ fontSize: 17 }}> Add Notes and Attachments</div>
                </DialogTitle>
                <DialogContent dividers>
                    <TextareaAutosize aria-label="minimum height" minRows={5} placeholder="Enter your notes here" style={{ width: '100%' }} />
                    <div className={classes.dialogController}>
                        <div>
                            <Button
                                component="label"
                                endIcon={<AttachFileIcon />}
                            >
                                Add Attachment
                                <input
                                    type="file"
                                    hidden
                                    accept=".doc,.docx,.pdf"
                                />
                            </Button>
                        </div>
                        <div>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Save Note
                            </Button>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );

}


export default connect(null, null, null)(InternalNotes);