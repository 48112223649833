import React, { useEffect } from 'react';
import {
    Button, Typography,
    Grid, Box, Stepper, Step, StepLabel,
    OutlinedInput, TextField
} from '@mui/material';
import { ArrowBackIos, Circle, OutboundOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import '../Styles/custom.css'
import { CustomMarket, Definition, Market, SettingUp } from './Components';
import { createCustomGroup, getMaterialFilters, getModels } from '../../../redux/actions';
import { DefineMarket } from '../PromotionScenario/components/MarketDefinition/Components';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        height: '138vh'
        //height: '100%'
    },
    customContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    settingsContainer: {
        marginTop: '13px',
        display: 'flex'
    },
    settingsLeftContainer: {
        background: '#F8F8F8',
        height: '903px',
        padding: '17px 38px'
    }
}))
const CreateCustomGroup = (props) => {
    const classes = useStyles();
    // const steps = getSteps();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const [modelYearArray, setModelYearArray] = React.useState([]);
    const [modelYear, setModelYear] = React.useState([]);
    const [productLineArray, setProductLineArray] = React.useState([]);
    const [productLine, setProductLine] = React.useState('');
    const [models, setModels] = React.useState({});
    const [customData, setCustomData] = React.useState({});
    const [customGroup, setCustomGroup] = React.useState('');
    const [customGroupName, setCustomGroupName] = React.useState('');
    const [customName, setCustomName] = React.useState('');
    const [customGroupValue, setCustomGroupValue] = React.useState('');
    const [ruleNameSetup, setRuleNameSetup] = React.useState([]);
    const [ruleNameDef, setRuleNameDef] = React.useState([]);
    const [ruleName, setRuleName] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [regionList, setRegionList] = React.useState([]);

    const steps = ['SETTING UP GROUPING', 'DEFINE GROUPING']

    useEffect(() => {
        props.getFilters();
        var location = {
            table_name: 'territory'
        }
        props.getFilterData('territory', location)
    }, [])

    function handleReturn() {
        history.push('/custom-group')
    }

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setModelYearArray(props.filterData['material_master'][0]['material_group1'])
            setProductLineArray(props.filterData['material_master'][0]['material_group'])
        }
    }, [props.filterData]);
    useEffect(() => {
        if (modelYear && modelYear.length > 0 && productLine) {
            var model = {
                table_name: 'material_master',
                filter: {
                    material_group1: modelYear,
                    material_group: productLine
                }
            }
            props.getFilterData('material_master', model)
        }
    }, [modelYear, productLine])
    useEffect(() => {
        if (props.modelData && props.modelData.length > 0) {

            setModels(props.modelData)
        }
    }, [props.modelData]);

    useEffect(() => {
        if (props.locationData) {
            setLocations(props.locationData)
            var region = props.locationData.flatMap((item, index) => item.children)
            setRegionList(region.map((item, index) => item.category))
        }
    }, [props.locationData])

    useEffect(() => {
        let setupData = []
        let defData = []

        if (customData) {
            if (customGroup) {
                var name = (customGroup === 'model grouping' ? 'Model Group' : customGroup === 'both' ? 'Both' : 'State Group')
                setupData.push(name)
            }
            if (customGroupName)
                setupData.push(customGroupName)
            if (customGroupValue)
                setupData = [...setupData, ...customGroupValue.split(",")]


            if (modelYear) {
                modelYear.map(item => {
                    var lastTwoDigits = item.toString().slice(-2)
                    defData.push(`Y${lastTwoDigits}`)
                })
            }
            if (customData.exclude)
                defData.push('Exclusion Model')
        }

        setRuleNameSetup(setupData)
        setRuleNameDef(defData)
        setRuleName([...setupData, ...defData])

    }, [customGroup, customGroupName, customGroupValue, modelYear, customData && customData.exclude])


    useEffect(() => {
        if (props.customData) {
            setCustomData(props.customData)
            setProductLine(props.customData.product_line)
            setCustomGroup(props.customData.custom_group_type)
            setCustomGroupName(props.customData.custom_group_type_name)
            setCustomGroupValue(props.customData.custom_group_type_value?.join(",").toString())
            setCustomName(props.customData.custom_group_name)
            setRuleName(props.customData.business_rule_name)
            setModelYear(props.customData.model_year)
            setRegion(props.customData.region)
        }

    }, [props.customData])

    const handleNext = () => {
        if (activeStep == steps.length - 1)
            setActiveStep(0)
        else
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };


    function handleDefineMarket(value, child) {
        if (customGroup == 'model grouping') {
            setCustomData(prev => ({
                ...prev,
                'models': value,
                'model_display': child
            }))
        }
        else {
            setCustomData(prev => ({
                ...prev,
                'location': value,
                'location_display': child
            }))
        }
    }
    function handleSetting(key, value) {
        setCustomData(prev => ({
            ...prev,
            [key]: value
        }))

        if (key == 'product_line')
            setProductLine(value)
        if (key == 'custom_group_type')
            setCustomGroup(value)
        if (key == 'custom_group_type_name')
            setCustomGroupName(value)
        if (key == 'custom_group_type_value')
            setCustomGroupValue(value)

    }
    function handleDefinition(key, value) {
        if (key == 'model_year')
            setModelYear(value)
        else if (key == 'region')
            setRegion(value)
        else {
            setCustomData(prev => ({
                ...prev,
                ...value
            }))
        }
    }
    function handleCustomName(event) {
        setCustomName(event.target.value)
    }
    function handleSubmit() {
        delete customData['CreatedAt'];
        delete customData['DeletedAt'];
        delete customData['UpdatedAt'];
        var data = {
            ...customData,
            'custom_group_type': customGroup,
            'custom_group_type_value': customGroupValue.split(","),
            'model_year': modelYear,
            'business_rule_name': [...ruleNameSetup, ...ruleNameDef],
            'custom_group_name': customName
        }

        props.onSubmit(data, customData.ID)
    }
    function getStepContent(step) {
        var allSteps = [
            <SettingUp productLines={productLineArray} onChange={handleSetting} setupData={customData} />,
            <Definition modelYears={modelYearArray} defineData={customData} locationData={locations}
                models={models} onChange={handleDefinition} customType={customGroup}
            />
        ]
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }
    return (
        <div className={classes.root}>
            <div className='sub-header-container'>
                <div style={{ display: 'flex' }}>
                    <Button className='return-button promo-font' variant="outlined" onClick={handleReturn}
                        startIcon={<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none" className='arrowLeft-icon'>
                            <path d="M5.24355 1.77083L2.01022 5.00417L5.24355 8.2375C5.56855 8.5625 5.56855 9.0875 5.24355 9.4125C4.91855 9.7375 4.39355 9.7375 4.06855 9.4125L0.243554 5.5875C-0.0814459 5.2625 -0.0814459 4.7375 0.243554 4.4125L4.06855 0.5875C4.39355 0.2625 4.91855 0.2625 5.24355 0.5875C5.56022 0.9125 5.56855 1.44583 5.24355 1.77083Z" fill="#4E4E4E" />
                        </svg>}
                    >
                        Return
                    </Button>
                    <Typography className='promo-font status-label'>
                        <Circle style={{ marginLeft: 15, height: 4, color: 'red' }} />
                        In Progress
                    </Typography>
                </div>

            </div>
            <div className={`custom-header-container ${classes.customContainer}`}>
                <div>
                    <Typography>
                        <span className='promo-font rule-name'>BUSINESS RULE NAME</span>
                        <span className='promo-font rule-info'>(this field will partially be filled out while building the rule)</span>
                    </Typography>
                    <div className='rule-name-container'  >
                        {/* {ruleName &&
                            ruleName.map(item => (
                                <div className='promo-font rule-name-box '>
                                    {item}
                                </div>
                            ))} */}
                        <TextField className='rule-name-fill' variant="standard" value={customName} onChange={handleCustomName} />
                    </div>

                </div>
                <div className={classes.customContainer} style={{ marginTop: 29 }}>
                    <Button variant='text' className='cancel-btn promo-font' onClick={handleReturn}>Cancel</Button>
                    <Button className='create-btn promo-font' variant='contained' onClick={handleSubmit}>Update Custom Group</Button>
                </div>
            </div>
            <Grid className={classes.settingsContainer}>
                <Grid container xs={12} md={6} className={classes.settingsLeftContainer}>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} >
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps} >
                                        <StepLabel {...labelProps} className='stepper-head'>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                        <React.Fragment>

                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '151px' }}>
                                <Button onClick={handleNext} className='create-btn promo-font' variant='contained' >
                                    {activeStep === steps.length - 1 ? 'Previous Step' : 'Next Step'}
                                </Button>
                            </Box>
                        </React.Fragment>

                    </Box>
                </Grid>
                <Grid container xs={12} md={6}>
                    <CustomMarket
                        defineMarket={customData} modelData={models}
                        defineMarketChild={customData}
                        regions={region && region.length > 0 ? region : regionList}
                        handleDefineMarket={handleDefineMarket} locationData={locations}
                        customType={customGroup}
                        modelYears={modelYear && modelYear.length > 0 ? modelYear : modelYearArray}
                    />
                </Grid>
            </Grid>

        </div>
    );
}
const mapStateToProps = state => {
    return {
        //locationData: state.promotionData.locationFilters,
        filterData: state.promotionData.promoFilters,
        modelData: state.promotionData.modelFilters,
        customData: state.promotionData.customGroupById,
        locationData: state.promotionData.locationFilters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter')),
        onSubmit: (data, id) => dispatch(createCustomGroup(data, id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomGroup);