import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button, Tooltip
} from '@mui/material';
import { FirstPage, Send, Share } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../../redux/actions';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 7,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        bottom: '50px',
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
    },

    shareIcon: {
        position: 'absolute',
        bottom: '56px',
        right: '140px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    nextIcon: {
        position: 'absolute',
        // bottom: '10px',
        right: '70px',
        width: '150px',
        height: '30px',
        cursor: 'pointer',
        zIndex: 1,
        marginTop: 5,
        // border: '1px solid #D8D8D8'
    },
    tabHead: {
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
        width: '100vw'
    },
    send: {
        fontSize: theme.typography.h4.fontSize,
        background: '#e8e8e880',
        width: '100vw',
    },
    sendIcon: {
        width: '30px',
        padding: 2,
        cursor: 'pointer',
        border: '1px solid #D8D8D8',
        background: 'white',
        marginLeft: 10,
    },
    sendBox: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    actionIcon: {
        cursor: 'pointer',
    },
}));


const SelectedStickers = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [stickersBankData, setStickersBankData] = React.useState();
    const [stickersCount, setStickersCount] = React.useState();
    const [name, setName] = React.useState();

    useEffect(() => {
        if (props.country)
            setSelectedCountry(props.country);
    }, [props.country]);

    useEffect(() => {
        if (props.name)
            setName(props.name);
    }, [props.name]);

    useEffect(() => {
        if (props.stickerSelectedData) {
            console.log(props.stickerSelectedData)
            setStickersBankData(props.stickerSelectedData);
            setStickersCount(props.stickerSelectedData.length);
        }
    }, [props.stickerSelectedData]);


    function handleClick() {
        if (props.page)
            props.onExpand(true);
    }

    function handleDelete(item) {
        props.onDelete(item);
    }

    function handleDefineMarket() {
        props.onChange();
    }

    // function getRandomColor() {
    //     const letters = '0123456789ABCDEF';
    //     let color = '#';
    //     for (let i = 0; i < 6; i++) {
    //         color += letters[Math.floor(Math.random() * 16)];
    //     }
    //     return color;
    // };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.headerRight}>
                    <FirstPage style={{ marginLeft: 10, marginTop: 25, border: '1px solid #D6D6D6', cursor: 'pointer' }} onClick={handleClick}></FirstPage>
                    <Typography style={{ marginLeft: -60, marginTop: 22 }}>{selectedCountry}</Typography>
                    <Typography style={{ color: 'rgb(182 175 175)', marginTop: 22, marginRight: 10 }}>{name}</Typography>

                </div>
                {/* <div className={classes.divContainerRight}> */}
                <div >{console.log(props.stickerSelectedData)}
                    <Table style={{ borderBottom: stickersBankData && Object.keys(stickersBankData).length == 0 && '0px' }}>
                        <TableHead style={{ backgroundColor: "#E8E8E8", position: "sticky", top: 0, zIndex: 1 }}>
                            <TableRow>
                                <TableCell align='right' className={classes.tabHead}  >
                                    {stickersCount} Stickers
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableRow>

                        </TableRow>
                        <div style={{ maxHeight: '69vh', minHeight: '50vh', overflowY: 'auto', }}>

                            {stickersBankData && Object.entries(stickersBankData).filter(([key, item]) => item.country == selectedCountry).map(([key, item]) => (
                                <TableRow key={key}>
                                    <TableCell className={classes.tabHead} style={{ cursor: 'pointer' }}>
                                        <CircleIcon style={{ marginLeft: 15, marginBottom: -3, height: 16, color: props.colorPicker && props.colorPicker[key] }} />
                                        {/* {(item.promo_variability ? 'PV -' : '') + (item.version > 1 ? `V${item.version} - ${item.sticker_description}` : item.sticker_description)} */}
                                        {item.promo_variability ? `PV - (${item.sticker_name})` : item.sticker_name}
                                    </TableCell>
                                    <TableCell>
                                        <DeleteIcon
                                            className={classes.actionIcon}
                                            onClick={() => handleDelete(item)}>
                                        </DeleteIcon>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </div>
                    </Table>

                </div>

            </div >
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedStickers);