
import React, { useEffect } from 'react';
import { BulletChartComponent, BulletRangeCollectionDirective, BulletRangeDirective, BulletTooltip, Inject } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { makeStyles } from "@mui/styles";
import { Divider } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: 'black',
        color: 'white',
        padding: 10
    },
    tooltip1: {
        backgroundColor: 'black',
        color: 'white',
        padding: 0
    }

}))
const BulletChart = (props) => {
    const classes = useStyles();
    const [bulletChartMax1, setBulletChartMax1] = React.useState(100);
    const [bulletChartMax2, setBulletChartMax2] = React.useState(100);
    const [bulletChartMax3, setBulletChartMax3] = React.useState(100);
    const [bulletChartMax4, setBulletChartMax4] = React.useState(100);
    useEffect(() => {
        var max = 100
        if (props.bulletChartData1)
            setBulletChartMax1(max + parseInt(props.bulletChartData1))
        if (props.bulletChartData2)
            setBulletChartMax2(max + parseInt(props.bulletChartData2))
        if (props.bulletChartData3)
            setBulletChartMax3(max + parseInt(props.bulletChartData3))
        if (props.bulletChartData4)
            setBulletChartMax4(max + parseInt(props.bulletChartData4))

    }, [props.bulletChartData1, props.bulletChartData2, props.bulletChartData3, props.bulletChartData4])

    function countTemplate(prop) {
        return (
            <div className={props.chart === 'small' ? classes.tooltip1 : classes.tooltip}>
                <div>Count:</div><Divider
                    sx={{
                        opacity: "0.6"
                    }} />
                <div>{prop.value}</div>
            </div>
        );
    }
    function salesTemplate(prop) {
        return (
            <div className={props.chart === 'small' ? classes.tooltip1 : classes.tooltip}>
                <div>Sales:</div><Divider
                    color='white'
                    sx={{
                        opacity: "0.6"
                    }} />
                <div>{prop.value}</div>
            </div>
        );
    }
    function accrualTemplate(prop) {
        return (
            <div className={props.chart === 'small' ? classes.tooltip1 : classes.tooltip}>
                <div>Accrual:</div><Divider
                    color='white'
                    sx={{
                        opacity: "0.6"
                    }} />
                <div>{prop.value}</div>
            </div>
        );
    }
    function paymentTemplate(prop) {
        return (
            <div className={props.chart === 'small' ? classes.tooltip1 : classes.tooltip}>
                <div>Payment: </div>
                <Divider
                    color='white'
                    sx={{
                        opacity: "0.6"
                    }} />
                <div>{prop.value}</div>
            </div>
        );
    }
    return (<div className='control-pane'>

        <div className='control-section'>
            {(props.bulletChartData1 || props.bulletChartData2 || props.bulletChartData3 || props.bulletChartData4) && props.maxData ?
                <>

                    <BulletChartComponent id='Count' style={{ textAlign: "center" }} width={Browser.isDevice ? '100%' : '80%'} load={bulletLoad.bind(this)}
                        animation={{ enable: false }} tooltip={{ enable: true, template: countTemplate }} valueField='value' targetField='target'
                        title='Count' enableGroupSeparator={true} valueFill='#0000FF' majorTickLines={{ color: '#0000FF', height: 10 }}
                        margin={{ left: Browser.isDevice ? 10 : 19 }} titlePosition={Browser.isDevice ? 'Top' : 'Left'}
                        dataSource={[{ value: props.bulletChartData1, target: props.bulletChartData1 ? props.bulletChartData1 : 0 }]}>
                        <Inject services={[BulletTooltip]} />
                        <BulletRangeCollectionDirective>
                            <BulletRangeDirective color='#FFFFFF' end={props.maxData.countMax}></BulletRangeDirective>
                        </BulletRangeCollectionDirective>
                    </BulletChartComponent>


                    <BulletChartComponent id='Sales' style={{ textAlign: "center" }} width={Browser.isDevice ? '100%' : '80%'} load={bulletLoad.bind(this)}
                        animation={{ enable: false }} tooltip={{ enable: true, template: salesTemplate }} valueField='value' targetField='target'
                        title='Sales' enableGroupSeparator={true} valueFill='#C4C24A' majorTickLines={{ color: '#C4C24A', height: 10 }}
                        margin={{ left: Browser.isDevice ? 10 : 19 }} titlePosition={Browser.isDevice ? 'Top' : 'Left'}
                        dataSource={[{ value: props.bulletChartData2, target: props.bulletChartData2 ? props.bulletChartData2 : 0 }]}>
                        <Inject services={[BulletTooltip]} />
                        <BulletRangeCollectionDirective>
                            <BulletRangeDirective color="#FFFFFF" end={props.maxData.salesMax}></BulletRangeDirective>
                        </BulletRangeCollectionDirective>
                    </BulletChartComponent>

                    <BulletChartComponent id='Accrual' style={{ textAlign: "center" }} width={Browser.isDevice ? '100%' : '80%'} load={bulletLoad.bind(this)}
                        animation={{ enable: false }} tooltip={{ enable: true, template: accrualTemplate }} valueField='value' targetField='target'
                        title='Accrual' enableGroupSeparator={true} valueFill='#CA4218' majorTickLines={{ color: '#CA4218', height: 10 }}
                        margin={{ left: Browser.isDevice ? 10 : 22 }} titlePosition={Browser.isDevice ? 'Top' : 'Left'}
                        dataSource={[{ value: props.bulletChartData3, target: props.bulletChartData3 ? props.bulletChartData3 : 0 }]}>
                        <Inject services={[BulletTooltip]} />
                        <BulletRangeCollectionDirective>
                            <BulletRangeDirective color="#FFFFFF" end={props.maxData.accrualMax}></BulletRangeDirective>
                        </BulletRangeCollectionDirective>
                    </BulletChartComponent>

                    <BulletChartComponent id='Payment' style={{ textAlign: "center" }} width={Browser.isDevice ? '100%' : '80%'} load={bulletLoad.bind(this)}
                        animation={{ enable: false }} tooltip={{ enable: true, template: paymentTemplate }} valueField='value' targetField='target'
                        title='Payment' enableGroupSeparator={true} valueFill='#6FAAB0' majorTickLines={{ color: '#6FAAB0', height: 10 }}
                        margin={{ left: Browser.isDevice ? 10 : 19 }} titlePosition={Browser.isDevice ? 'Top' : 'Left'}
                        dataSource={[{ value: props.bulletChartData4, target: props.bulletChartData4 ? props.bulletChartData4 : 0 }]}>
                        <Inject services={[BulletTooltip]} />
                        <BulletRangeCollectionDirective color='#0000FF'>
                            <BulletRangeDirective color='#FFFFFF' end={props.maxData.paymentMax}>

                            </BulletRangeDirective>
                        </BulletRangeCollectionDirective>
                    </BulletChartComponent>
                </>
                :
                <div
                    style={
                        props.page === "New Master Data Incentive"
                            ? { fontSize: "16px", height: "50vh", justifyContent: "center", alignItems: "center", display: "flex" }
                            : { fontSize: "16px" }
                    }
                >
                    No data to show
                </div>
            }
        </div>
    </div>);
    function bulletLoad(args) {
        let chartId = ['Count', 'Sales', 'Accrual', 'Payment'];
        for (let ids of chartId) {
            let chart = document.getElementById(ids);
            if (chart) {  // Check if chart is not null
                chart.setAttribute('title', '');
            }
            // chart.setAttribute('title', '');
        }
        let selectedTheme = 'Material';
        args.bulletChart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/light/i, 'Light').replace(/contrast/i, 'Contrast');
    }
}
export default BulletChart;