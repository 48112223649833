import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Select,
    MenuItem,
    FormControl
} from '@mui/material';
import { Grid } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    selectedItem: {
        fontSize: 14,
        padding: '5.5px 10px 5.5px 12px',
        borderRadius: 5
    },
    error: {
        border: '1px solid red !important'
    },
    menuItem: {
        fontSize: 14
    },
    formControl: {
        width: 72
    }
}));

const BooleanDropdown = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const [data, setData] = React.useState(props.defaultValue ? 0 : '');
    const handleChange = (event) => {
        setData(event.target.value);
        props.onChange(event.target.value);
    };
    const reset = () => {
        setData('');
    };
    useImperativeHandle(ref, () => {
        return {
            reset: reset
        };
    });

    return (
        <Grid container className={clsx(classes.root, className)}>
            <Grid
                item
                md={6}
                xs={6}
            >
            </Grid>
            <Grid
                item
                md={6}
                xs={6}
            >
                <FormControl className={classes.formControl}>
                    <Select
                        disableUnderline
                        onChange={handleChange}
                        displayEmpty
                        defaultValue={0}
                        classes={{
                            selectMenu: classes.selectedItem
                        }}
                    >
                        <MenuItem value={0} className={classes.menuItem}>No</MenuItem>
                        <MenuItem value={1} className={classes.menuItem}>Yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid >
    );

});

export default BooleanDropdown;