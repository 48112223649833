import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone'
import { Button } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import { incomingClaimDataUpload } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
    },
    container: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        padding: 50
    },
    iconContainer: {
        justifyContent: 'center',
        display: 'flex'
    },
    spinner: {
        height: '100vh'
    }
}));

const IncomingClaim = (props) => {
    const classes = useStyles();
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');

    const handleDrop = (event) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('sheet', event.target.files[0]);
        setCurrentFile(data);
    }
    const handleUpload = () => {
        props.uploadIncomingClaim(currentFile);
        setCurrentFile(undefined);
        setCurrentFileName('');
    }

    return (
        <div className={classes.root}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Uploading Sales Data. Please wait ...'
                className={classes.spinner}
            >
                <Dropzone
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    {({ getRootProps, getInputProps }) => (
                        <div className={classes.root}>
                            <div {...getRootProps({ className: 'dropzone' })} className={classes.container}>
                                <div className={classes.iconContainer}>
                                    <BackupIcon />
                                </div>
                                <input {...getInputProps()} onChange={handleDrop} />
                                <p>Drag 'n' drop the excel files here (.xlsx format), or click to select file.</p>
                            </div>
                            <aside style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                                <Button variant="contained" color="primary" style={{ marginTop: 10 }} onClick={handleUpload} disabled={currentFile ? false : true}>Upload</Button>
                                {currentFileName}
                            </aside>
                        </div>
                    )}
                </Dropzone>
            </LoadingOverlay>
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        uploadIncomingClaim: (file) => dispatch(incomingClaimDataUpload(file))
    }
}

const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingClaim);