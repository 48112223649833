import React from 'react';
import { connect } from 'react-redux';
import { CustomerXMasterUserTable } from './components';

const CustomerXMaster = () => {

    return (
        <div>
            <CustomerXMasterUserTable />
        </div >
    );
};

export default connect(null,null)(CustomerXMaster);