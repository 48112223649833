import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    Checkbox, Dialog, DialogActions
} from '@mui/material';
import { connect } from 'react-redux';
import { getContractsTableData, newAPIForAccRe, getAccessData, changeRequestDetails, getContractViewData, downloadSheet, batchApprove, getContractDetailsData, getLblDispDesFieldValue, runcontractChangeReports } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from "@mui/material/Tooltip";
import ChangeCircleIcon from '@mui/icons-material/BubbleChart';
import HashLoader from 'react-spinners/HashLoader'
import ApprovalIcon from '@mui/icons-material/Approval';
import { ContractComments } from '../../ContractSetup';
import secureLocalStorage from 'react-secure-storage';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '29px 0px 0px 0px',
        marginTop: 16,
        marginBottom: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: -40
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dialogPaper: {
        width: '70%',
        maxHeight: '80vh',
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const ContractSetupTable = props => {
    useEffect(() => {
        props.onCheckContractsTable(1, 0);
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [selectedApproved, setSelectedApproved] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [notes, setNotes] = React.useState([]);
    const [contractIds, setContractIds] = React.useState([]);
    const hasRight = (right) => {
        const user = secureLocalStorage.getItem("dYtz");
        return (
            user.role.rights &&
            user.role.rights.includes(`${right}-${sessionStorage.getItem("application")}`)
        );
    };
    const hasContractApprovalRight = hasRight("approve_contract");
    const [contractFormFields, setContractFormFields] = React.useState([]);
    const [columns, setColumns] = React.useState([
        {
            field: contractFormFields?.contract_number?.current ? contractFormFields['contract_number']['key'] : 'contract_number',
            title: contractFormFields?.contract_number?.current ? contractFormFields['contract_number']['current'] : 'Contract Number',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.contract_number ? rowData.contract_number : ''}</a>
        },
        {
            field: contractFormFields?.change_request_id?.current ? contractFormFields['change_request_id']['key'] : 'change_request_id',
            title: contractFormFields?.change_request_id?.current ? contractFormFields['change_request_id']['current'] : 'Pending Change',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.change_request_id ? rowData.change_request_id : ''}</a>
        },
        {
            field: contractFormFields?.calculation_method?.current ? contractFormFields['calculation_method']['key'] : 'calculation_method',
            title: contractFormFields?.calculation_method?.current ? contractFormFields['calculation_method']['current'] : 'Calculation Method',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.calculation_method ? rowData.calculation_method : ''}</a>
        },
        {
            field: contractFormFields?.contract_type?.current ? contractFormFields['contract_type']['key'] : 'contract_type',
            title: contractFormFields?.contract_type?.current ? contractFormFields['contract_type']['current'] : 'Contract Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.contract_type ? rowData.contract_type : ''}</a>
        },
        {
            field: contractFormFields?.internal_description?.current ? contractFormFields['internal_description']['key'] : 'internal_description',
            title: contractFormFields?.internal_description?.current ? contractFormFields['internal_description']['current'] : 'Internal Description',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.internal_description ? rowData.internal_description : ''}</a>
        },
        {
            field: contractFormFields?.contract_status?.current ? contractFormFields['contract_status']['key'] : 'contract_status',
            title: contractFormFields?.approval_status?.current ? contractFormFields['approval_status']['current'] : 'Approval Status',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.contract_status ? rowData.contract_status.replace(/_/g, ' ') : ''}</a>
        },
        {
            field: contractFormFields?.contract_analyst?.current ? contractFormFields['contract_analyst']['key'] : 'contract_analyst',
            title: contractFormFields?.contract_analyst?.current ? contractFormFields['contract_analyst']['current'] : 'Rebate Analyst',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.contract_analyst ? rowData.contract_analyst : ''}</a>
        },
        {
            field: contractFormFields?.contract_manager?.current ? contractFormFields['contract_manager']['key'] : 'contract_manager',
            title: contractFormFields?.contract_manager?.current ? contractFormFields['contract_manager']['current'] : 'Rebate Manager',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.contract_manager ? rowData.contract_manager : ''}</a>
        },
        {
            field: contractFormFields?.accrual_amount?.current ? contractFormFields['accrual_amount']['key'] : 'accrual_amount',
            title: contractFormFields?.accrual_amount?.current ? contractFormFields['accrual_amount']['current'] : 'Accrual Amount',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.accrual_amount ? rowData.accrual_amount : 0}</a>
        },
        {
            field: contractFormFields?.payment_amount?.current ? contractFormFields['payment_amount']['key'] : 'payment_amount',
            title: contractFormFields?.payment_amount?.current ? contractFormFields['payment_amount']['current'] : 'Payment Amount',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.payment_amount ? rowData.payment_amount : 0}</a>
        },
        {
            field: contractFormFields?.valid_from?.current ? contractFormFields['valid_from']['key'] : 'valid_from',
            title: contractFormFields?.valid_from?.current ? contractFormFields['valid_from']['current'] : 'Start Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.valid_from ? rowData.valid_from === '0001-01-01T00:00:00Z' ? '' : Moment.utc(rowData.valid_from).format('MM/DD/YYYY') : ''}</a>
        },
        {
            field: contractFormFields?.valid_to?.current ? contractFormFields['valid_to']['key'] : 'valid_to',
            title: contractFormFields?.valid_to?.current ? contractFormFields['valid_to']['current'] : 'End Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.valid_to ? rowData.valid_to === '0001-01-01T00:00:00Z' ? '' : Moment.utc(rowData.valid_to).format('MM/DD/YYYY') : ''}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <span style={{ display: hasContractApprovalRight ? 'block' : 'none' }} >
                        {rowData.update_contract_id ?
                            <Tooltip title='Show change history'>
                                <IconButton classes={{ root: classes.IconButton }} size={'small'}
                                    onClick={
                                        () => handleChangeLog(rowData)
                                        //      history.push({
                                        //     pathname: '/approval-reject/compare/' + rowData.update_contract_id,
                                        //     page: 'contract-approval',
                                        // })
                                    }
                                >
                                    <ChangeCircleIcon style={{ cursor: 'auto' }} />
                                </IconButton>
                            </Tooltip>
                            :
                            <ChangeCircleIcon style={{ color: '#E2E2E2' }} />
                        }
                        <Tooltip title='Approve Contract'>
                            <IconButton classes={{ root: classes.IconButton }} size={'small'}
                                onClick={() => handleContractView(rowData.id)}
                            >
                                <ApprovalIcon style={{ cursor: 'auto' }} />
                            </IconButton>
                        </Tooltip>
                    </span>
                </div>

        },

    ]);
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {

            setContractFormFields(props.dropdownData.field_label_attributes)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes, columns])
    const handleCheckAll = () => {
        var IDSApproved = [];
        var IDS = [];
        if (!isCheckAll) {
            props.contractablesdata && props.contractablesdata.records.map(item => {
                if (item.is_update_approval_contract) {
                    IDSApproved.push(item.id)
                } else {
                    IDS.push(item.id)
                }
            })
            setSelectedApproved(IDSApproved);
            setSelected(IDS);
        } else {
            setSelectedApproved([]);
            setSelected([]);
        }
        setIsCheckAll(!isCheckAll);
    }
    const handleChangeLog = (data) => {
        console.log(data)
        var formData = {
            "contract_number.in": data.contract_number,
            "current_zone": moment.tz.guess() ? moment.tz.guess() : '',
        };

        sessionStorage.setItem("ContractChangeReportsData", JSON.stringify(formData));
        sessionStorage.setItem("ContractChangeReportType", JSON.stringify('all-changes'));
        props.runcontractChangeReports(1, 0, 'all-changes');
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.onCheckContractsTable(newPage + 1, rowsPerPage);
    };
    const approveIssueResolutionContract = () => {
        props.batchApprove(selected, 'new', selectedApproved, notes, 'Issue Resolution')
    };
    const approveContract = () => {
        props.batchApprove(selected, 'acc', selectedApproved, notes)
    };
    const rejectContract = () => {
        setContractIds([...selected, ...selectedApproved])
        setOpen(true)
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.onCheckContractsTable(1, event.target.value);
    };
    const confirmReject = () => {
        setOpen(false)
        props.batchApprove(selected, 'rej', selectedApproved, notes)
        setNotes([])
    }
    const handleClick = (event, name, item, type) => {
        let newSelected = [];
        let newSelectedApproved = [];
        if (type === 'update_approval_contract') {
            const selectedIndex = selectedApproved.indexOf(name);
            if (selectedIndex === -1) {
                newSelectedApproved = newSelectedApproved.concat(selectedApproved, name);
            } else if (selectedIndex === 0) {
                newSelectedApproved = newSelectedApproved.concat(selectedApproved.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelectedApproved = newSelectedApproved.concat(selectedApproved.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelectedApproved = newSelectedApproved.concat(
                    selectedApproved.slice(0, selectedIndex),
                    selectedApproved.slice(selectedIndex + 1),
                );
            }
            setSelectedApproved(newSelectedApproved);
        } else {
            const selectedIndex = selected.indexOf(name);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, name);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
            setSelected(newSelected);
        }
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const isSelectedApproved = (name) => selectedApproved.indexOf(name) !== -1;
    const [contractablesdata, setContractablesdata] = React.useState([]);
    useEffect(() => {
        if (props.contractablesdata && props.contractablesdata.records) {
            setContractablesdata(props.contractablesdata)
        }
    }, [props.contractablesdata])
    function handleNotes(value) {
        setNotes([...notes, value])
    }
    function handleCancel() {
        setOpen(false)
        setNotes([])
    }
    function handleContractView(id) {
        props.getcontractdetails(id)
        history.push({
            pathname: "/contract-setup/edit-contract-submit-approval/" + id,
            state: "contract-approval",
        })
        sessionStorage.setItem("mode", "edit");
        localStorage.setItem("currentAddContractID", id);
    }
    function handleSelectedRows(rows) {
        let selectedArray = []
        let selectedApprovedArray = []
        rows.map(item =>
            item.is_update_approval_contract === false ?
                selectedArray.push(item.id)
                :
                selectedApprovedArray.push(item.id)
        )
        setSelected(selectedArray)
        setSelectedApproved(selectedApprovedArray)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography style={{ marginTop: 15, marginLeft: 10 }} variant="h1" color='primary'> {sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan Approval' : 'Contract Approval'} </Typography>
                    {contractablesdata && contractablesdata.records && contractablesdata.records.length > 0 &&
                        <div className={classes.buttonRoot} style={{ display: hasContractApprovalRight ? 'block' : 'none' }} >
                            <Button
                                variant="contained"
                                color="primary"
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={approveContract}
                                startIcon={<CheckIcon />}
                                style={{ margin: 10 }}
                                disabled={!selected.length > 0 && !selectedApproved.length > 0 ? true : false}
                            >
                                Approve
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={approveIssueResolutionContract}
                                startIcon={<CheckIcon />}
                                style={{ margin: 10 }}
                                disabled={!selected.length > 0 && !selectedApproved.length > 0 ? true : false}
                            >
                                Issue Resolution
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ClearIcon />}
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={rejectContract}
                                style={{ margin: 10, backgroundColor: '#FA5858' }}
                                disabled={!selected.length > 0 && !selectedApproved.length > 0 ? true : false}
                            >
                                Reject
                            </Button>
                        </div >
                    }
                </div>
                {
                    contractablesdata && contractablesdata.records && contractablesdata.records.length > 0 ?
                        // <TableContainer style={{ marginTop: -18 }} >
                        //     <Table>
                        //         <TableHead >
                        //             <TableRow >
                        //                 <TableCell>
                        //                     <Checkbox
                        //                         onChange={handleCheckAll}
                        //                         disabled={!hasContractApprovalRight}
                        //                     />
                        //                 </TableCell>
                        //                 <TableCell
                        //                     align='center' classes={{ root: classes.fontSetting }}
                        //                 >Contract Number</TableCell>
                        //                 <TableCell
                        //                     align='center' classes={{ root: classes.fontSetting }}
                        //                 >Pending Change</TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Calculation Method</TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Contract Type </TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Internal Description </TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Approval Status </TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Accrual Amount </TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Payment Amount </TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                        //                 <TableCell align='center' classes={{ root: classes.fontSetting }} >Actions</TableCell>
                        //             </TableRow>
                        //         </TableHead>
                        //         <TableBody classes={{ root: classes.table }}>
                        //             {contractablesdata && contractablesdata.records && contractablesdata.records
                        //                 .sort((a, b) => a.contract_number < b.contract_number ? 1 : -1)
                        //                 .map(item => {
                        //                     const isItemSelected = isSelected(item.id);
                        //                     const isItemSelectedApproved = isSelectedApproved(item.id);
                        //                     return (
                        //                         <StyledTableRow key={item.contract_number} className={classes.hover}
                        //                             hover
                        //                             onClick={item.is_update_approval_contract === false ?
                        //                                 (event) => handleClick(event, item.id, item)
                        //                                 :
                        //                                 (event) => handleClick(event, item.id, item, 'update_approval_contract')
                        //                             }
                        //                         >
                        //                             <TableCell padding="checkbox">
                        //                                 {item.is_update_approval_contract === false ?
                        //                                     hasContractApprovalRight ?
                        //                                         <Checkbox
                        //                                             color='primary'
                        //                                             checked={isItemSelected}
                        //                                             key={item.id}
                        //                                             selected={isItemSelected}
                        //                                         />
                        //                                         : <Checkbox
                        //                                             color='primary'
                        //                                             disabled
                        //                                         />
                        //                                     :
                        //                                     hasContractApprovalRight ?
                        //                                         <Checkbox
                        //                                             color='primary'
                        //                                             checked={isItemSelectedApproved}
                        //                                             key={item.id}
                        //                                             selected={isItemSelectedApproved}
                        //                                         />
                        //                                         : <Checkbox
                        //                                             color='primary'
                        //                                             disabled
                        //                                         />

                        //                                 }
                        //                             </TableCell>
                        //                             <TableCell align='center' classes={{ root: classes.fontSetting }}  >
                        //                                 {item.contract_number}
                        //                             </TableCell>
                        //                             <TableCell align='center' classes={{ root: classes.fontSetting }} >
                        //                                 {item.change_request_id != 0 ? item.change_request_id : ''}
                        //                             </TableCell>
                        //                             <TableCell classes={{ root: classes.description }}>{item.calculation_method}</TableCell>
                        //                             <TableCell classes={{ root: classes.description }}>{item.contract_type}</TableCell>
                        //                             <TableCell classes={{ root: classes.description }}>{item.internal_description}</TableCell>
                        //                             <TableCell classes={{ root: classes.description }}>{item.approval_level_status/*item.approval_status === 'new' ? 'New' : item.approval_status === 'acc' ? 'Accepted' : item.approval_status === 'rej' ? 'Rejected' : 'Submit For Approval'*/}</TableCell>
                        //                             <TableCell classes={{ root: classes.description }}>{item.approval_amount ? item.approval_amount : 0}</TableCell>
                        //                             <TableCell classes={{ root: classes.description }}>{item.payment_amount ? item.payment_amount : 0}</TableCell>
                        //                             <TableCell align='center' classes={{ root: classes.fontSetting }} > {item.valid_from === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item.valid_from).format('MM/DD/YYYY')} </TableCell>
                        //                             <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.valid_to === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item.valid_to).format('MM/DD/YYYY')}</TableCell>
                        //                             <TableCell style={{ display: 'flex', justifyContent: 'space-around' }}>
                        //                                 <span style={{ display: hasContractApprovalRight ? 'block' : 'none' }} >
                        //                                     {item.update_contract_id ?
                        //                                         <Tooltip title='Show change history'>
                        //                                             <IconButton classes={{ root: classes.IconButton }} size={'small'}
                        //                                                 onClick={
                        //                                                     () => history.push({
                        //                                                         pathname: '/approval-reject/compare/' + item.update_contract_id,
                        //                                                         page: 'contract-approval',
                        //                                                     })
                        //                                                 }
                        //                                             >
                        //                                                 <ChangeCircleIcon style={{ cursor: 'auto' }} />
                        //                                             </IconButton>
                        //                                         </Tooltip>
                        //                                         :
                        //                                         <ChangeCircleIcon style={{ color: '#E2E2E2' }} />
                        //                                     }
                        //                                     <Tooltip title='Approve Contract'>
                        //                                         <IconButton classes={{ root: classes.IconButton }} size={'small'}
                        //                                             onClick={() => handleContractView(item.id)}
                        //                                         >
                        //                                             <ApprovalIcon style={{ cursor: 'auto' }} />
                        //                                         </IconButton>
                        //                                     </Tooltip>
                        //                                 </span>
                        //                             </TableCell>

                        //                         </StyledTableRow>
                        //                     );
                        //                 })}
                        //         </TableBody>
                        //     </Table>
                        //     <TablePagination
                        //         component="div"
                        //         count={props.contractablesdata && props.contractablesdata.total_record ? props.contractablesdata.total_record : 0}
                        //         page={page}
                        //         rowsPerPage={rowsPerPage}
                        //         onPageChange={handleChangePage}
                        //         rowsPerPageOptions={rowsPerPageOptions}
                        //         onRowsPerPageChange={handleChangeRowsPerPage}
                        //         classes={{
                        //             caption: classes.caption,
                        //             root: classes.paginationRoot
                        //         }}
                        //     />
                        // </TableContainer>
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                            marginTop: '-1rem'
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={contractablesdata && contractablesdata.records ? contractablesdata.records : ''}
                            // style={{ marginTop: '0px' }}
                            options={{
                                // maxBodyHeight: '100vh',
                                search: false,
                                headerStyle: {
                                    fontSize: '.90rem',
                                    whiteSpace: 'wrap',
                                    padding: '5px 0px 5px 10px',
                                    textAlign: 'center',
                                    color: 'white',
                                    borderRight: "1px solid #EEEEEE",
                                },
                                filtering: true,
                                cellStyle: {
                                    fontSize: '.90rem',
                                    textAlign: "center",
                                    borderRight: "1px solid #EEEEEE",
                                    color: "black !important",
                                    padding: '5px 0px',
                                    whiteSpace: 'wrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 100,
                                    width: 40,
                                    minWidth: 20,

                                },
                                selection: hasContractApprovalRight ? true : false,
                                pageSize: 10,
                                pageSizeOptions: [
                                    15,
                                    20,
                                    50,
                                    { value: contractablesdata && contractablesdata.records ? contractablesdata.records.length : '0', label: "Show all" },
                                ],
                            }}
                            // onSelectionChange={(rows) => setSelected(rows.map(item => item.id))}
                            onSelectionChange={(rows) => handleSelectedRows(rows)}

                        />
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                }

            </LoadingOverlay >
            <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open} classes={{ paper: classes.dialogPaper }}>
                <div style={{ padding: 20, width: '100%' }}>
                    <ContractComments onChange={handleNotes} notes={notes} approvalStatus='submit_approval'
                        editable={true} page={"approval"}
                        selectedIds={contractIds && contractablesdata.records ? contractablesdata.records.filter(item => contractIds.includes(item.id)) : []}
                    />
                </div>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button color="primary" variant='contained' onClick={confirmReject}
                    >
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

ContractSetupTable.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = state => {
    return {
        contractablesdata: state.addContractData.contractListData,
        loading: state.addContractData.loading,
        contractTabledataComplete: state.addContractData.contractListDataComplete,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckContractsTable: (pagination, limit) => dispatch(getContractsTableData(pagination, limit, 'submit')),
        batchApprove: (items, status, itemsApproved, notes, type) => dispatch(batchApprove(items, status, itemsApproved, notes, type)),
        changeRequestDetails: (id, updatedId, type) => dispatch(changeRequestDetails(id, updatedId, type)),
        getAccessData: () => dispatch(getAccessData()),
        newAPIForAccRe: (status, bulk, id) => dispatch(newAPIForAccRe(status, bulk, id)),
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        runcontractChangeReports: (pagination, limit, type) => dispatch(runcontractChangeReports(pagination, limit, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSetupTable);