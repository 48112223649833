import { useEffect, useRef } from "react";
import * as React from "react";
import { MapsTooltip, LayersDirective, LayerDirective, MapsComponent, Inject, Legend, Zoom, DataLabel } from '@syncfusion/ej2-react-maps';
import usa from "../Analytics/Charts/usa.json";
import Moment from 'moment';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Select, MenuItem, FormLabel, Typography, Button,Drawer,
    AppBar, Toolbar, CssBaseline, Divider, IconButton } from '@mui/material';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { getAnaylsisFormula, getAttributesConfig, getGrossToNet } from "../../redux/actions";
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const SAMPLE_CSS = `
    .e-play-icon::before {
        content: "\\e34b";
    }

    .e-view.fluent .e-play-icon::before, .e-view.fluent-dark .e-play-icon::before {
        content: '\\e75d';
    }

    .e-view.fabric .e-play-icon::before, .e-view.fabric-dark .e-play-icon::before
    {
        content: '\\e7df';
    }

    .e-view.bootstrap .e-play-icon::before {
        content: '\\ebd2';
    }

    .e-view.bootstrap4 .e-play-icon::before {
        content: '\\e743';
    }

    .e-view.tailwind .e-play-icon::before, .e-view.tailwind-dark .e-play-icon::before {
        content: '\\e76c';
    }

    .e-view.highcontrast .e-play-icon::before {
        content: '\\ebf9';
    }

    .e-view.bootstrap5 .e-play-icon::before, .e-view.bootstrap5-dark .e-play-icon::before {
        content: '\\e75d';
    }`;
    
const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px',
    },
    select: {
        width: '100%',
        color: '#1675e0',
        backgroundColor: 'white'
    },
    selectedItem: {
        borderRadius: 5
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    spinner: {
        height: '90vh'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 24,
        paddingBottom: 30,
    },
    button: {
        marginRight: 10,
        width: 80,
    },
    drawer: {
        position: 'absolute',
        flexShrink: 0,
        zIndex: 998
    },
    drawerPaper: {
        marginTop: 65
    },
    drawerIcon: {
        marginRight: 10
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        fontSize: theme.typography.h2.fontSize
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        fontSize: theme.typography.h2.fontSize
    },
}));

const RegionalAnalysis = (props) => {
    const theme = useTheme();
    const [offsets, setOffsets] = React.useState([]);
    const [simulationType, setSimulationType] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [formulaList, setFormulaList] = React.useState([]);
    const [formula, setFormula] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [filterData, setFilterData] = React.useState({});
    const [grossNetData, setGrossNetData] = React.useState([]);
    const [onSubmit, setOnSubmit] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [tempData,setTempData] = React.useState('');
    const classes = useStyles();
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleStartDate = (e) => {
        if (e && e.isValid()) {
            setStartDate(e);
        } else {
            setStartDate(null);
        }
    }
    const handleEndDate = (e) => {
        if (e && e.isValid()) {
            setEndDate(e);
        } else {
            setEndDate(null);
        }
    }

    useEffect(() => {
        setStartDate(Moment().subtract(1, 'months').startOf('month'));
        setEndDate(Moment().subtract(1, 'months').endOf('month'));
    }, [])

    useEffect(() => {
        props.getConfigData()
    }, [])

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setFormulaList(props.configData.map(item => item.formula_name));
        }
    }, [props.configData])
    useEffect(() => {
        if (props.grossNetData && props.grossNetData.length > 0 && onSubmit) {
            setGrossNetData(props.grossNetData);
            props.grossNetData.map(item=>{   
                if(item.keys){
                    setTempData(item.keys.margin_price_percentage)
                }               
            })
            setOnSubmit(false)
        }
        else
            setGrossNetData([])
    }, [props.grossNetData])
    const handleRun = () => {
        setOnSubmit(true)
        setOpen(false)
        var sd = startDate ? Moment.utc(startDate).toISOString()?.split("T")[0].concat("T00:00:00.000Z") : '';
        var ed = endDate ? Moment.utc(endDate).toISOString()?.split("T")[0].concat("T23:59:59.000Z") : '';
        var data = {
            "formula_name": formula,
            "start_date": sd,
            "end_date": ed,
            "simulation_category": simulationType,
            "attributes": ['region'],
        }
        props.onSubmit(data)
    }

    let mapInstance = useRef(null);
    let colorMap = [
        {
            from: -1000000,
            to: 0,
            color: '#64CCC5',
            label: '<0%',
        },
        {
            from: 0,
            to: 25,
            color: '#8E8FFA',
            label: '0% - 25%',
        },
        {
            from: 25,
            to: 50,
            color: '#A6FF96',
            label: '25% - 50%',
        },
        {
            from: 50,
            to: 75,
            color: '#3182bd',
            label: '50% - 75%',
        },
        {
            from: 75,
            to: 100,
            color: '#FA9884',
            label: '75% - 100%',
        },
        {
            color: '#b0cde1',

        }
    ];

    const onMapsLoad = () => {
        let maps = document.getElementById('maps');
        if (maps) {
            maps.setAttribute("title", "");
        }
    };

    const load = (args) => {
    };

    const tooltipRender = (args) => {
        if (!args.options.data) {
            args.cancel = true;
        }
    }

    return (
        <LoadingOverlay
            active={onSubmit}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
            <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar style={{ position: 'absolute', marginTop: 65, display: open && 'none' }}>
                        <IconButton
                            color="black"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={clsx(open && classes.hide)}
                            size="large">
                            <Menu />
                        </IconButton>
                        <Typography style={{ marginLeft:'1rem' }} variant="h3" color='primary'> Regional Analysis </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="top"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <Typography variant="h3" color='primary'> Regional Analysis </Typography>
                        <IconButton onClick={() => setOpen(false)} size="large">
                            {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    </div>
                <Grid container className={classes.root}>
                    <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid container
                            item
                            md={filters && filters.length > 0 ? 8 : 7}
                            xs={12}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Formula Name</Typography>
                                    <Select
                                        value={formula}
                                        onChange={(e) => setFormula(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {formulaList && formulaList.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Simulation Category</Typography>
                                    <Select
                                        value={simulationType}
                                        onChange={(e) => setSimulationType(e.target.value)}
                                        label='Posting Type'
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}

                                    >
                                        <MenuItem value={"Historical - Actual"}>
                                            Historical - Actual
                                        </MenuItem>
                                        <MenuItem value={"Historical - What-If"}>
                                            Historical - What-If
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={startDate}
                                                onChange={handleStartDate}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={endDate}
                                                minDate={startDate ? startDate : false}
                                                onChange={handleEndDate}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleRun}
                                twoline="true"
                            >
                                <DirectionsRunIcon />
                            </Button>
                        </div>
                    </Grid>

                </Grid>
                </Drawer>
                {grossNetData && grossNetData.length > 0 ?
                        <div style={{marginTop:'2.688rem'}}>
                            <style>{SAMPLE_CSS}</style>
                            <div className='control-section row'>
                                <div className='col-md-12'>
                                    <MapsComponent id="maps" height="625" tooltipRender={tooltipRender} loaded={onMapsLoad} load={load} allowPrint={true} ref={mapInstance}
                                        useGroupingSeparator={true} format={"n"} legendSettings={{
                                            visible: true, mode: 'Interactive', position: 'Bottom', height: '10', width: '80%', title: 'Margin %',
                                            labelDisplayMode: 'Trim', alignment: 'Center', textStyle: { color: '#757575' }
                                        }} zoomSettings={{ enable: true, enablePanning: true }}
                                    >
                                        <Inject services={[Legend, MapsTooltip, Zoom, DataLabel]} />
                                        <LayersDirective>
                                            <LayerDirective shapeData={usa} shapePropertyPath='iso_3166_2' shapeDataPath='Region' dataSource={grossNetData} tooltipSettings={{
                                                visible: 'point.Region' ? true : false, valuePath: 'Margin %',
                                                format: 'State :  ${name} <br> Margin % :  ${Margin %} <br> Net Margin :  ${Net Margin} <br>'
                                            }}
                                                shapeSettings={{ colorValuePath: 'Margin %', fill: '#FBFFB1', colorMapping: colorMap }}
                                                dataLabelSettings={{
                                                    visible: true,
                                                    labelPath: 'iso_3166_2',
                                                    smartLabelMode: 'Trim'
                                                }}
                                            />
                                        </LayersDirective>
                                    </MapsComponent>

                                </div>

                            </div>
                        </div>
                        
                        :
                        <Typography variant='h4' style={{marginTop:'5rem'}} >
                            No Data
                        </Typography>
                    }
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.profitOptimizationData.loadGrossNet,
        configData: state.profitOptimizationData.priceCalForListData,        
        grossNetData: state.profitOptimizationData.grossNetData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getGrossToNet(data)),
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionalAnalysis);