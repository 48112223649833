import React, { useEffect, forwardRef, useRef } from 'react';
import {
    Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton, OutlinedInput, Select, MenuItem, FormLabel, Dialog, DialogActions, DialogContent, DialogContentText, TextField
} from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import {
    getEmployeeData, getGeneralUOM, getPriceFormula, getMaterialMasterData, getSupplierMasterData, getCustomerMasterData, getDefaultValues, getDefaultValuesKeyFigure, getFormulasList,
    getDefaultValuesAllData, getDefaultValuesAllDataNew, getAttributeListData, uploadCalculationRules, getDefaultValuesAttributeValue
} from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AllOtherAttributeMasterComponent, SearchDropDownMasterDataNew, FormulaLabAutoComplete } from '../../../../components/Inputs';
import { CloudUpload } from '@mui/icons-material';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 26,
        marginBottom: 23
    },
    container: {
        padding: '26px 0px 26px 26px'
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },

    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 5px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    inputTwoLine: {
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    error: {
        border: '1px solid red !important'
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    removeButton: {
        color: theme.palette.button.red,
        padding: '0px 2px'
    },
    formDiv: {
        height: 41,
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-end'
    },
    dollorIconActive: {
        minWidth: 0,
        padding: '6px 9px',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: 'green'
        },
        height: 36,
        display: 'flex',
        backgroundColor: 'green'
    },
    dollorIconDeactive: {
        minWidth: 0,
        padding: '6px 9px',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: '#CEF6CE'
        },
        height: 36,
        display: 'flex',
        backgroundColor: '#CEF6CE'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    uploadIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        height: 36,
        display: 'flex'
    },
}));

const CalculationRules = forwardRef((props) => {
    const currentApplication = sessionStorage.getItem('application');
    const [keyFigureArrayMXS, setKeyFigureArrayMXS] = React.useState([]);
    const [attributeArrayMXS, setAttributeArrayMXS] = React.useState([]);
    const [formulaArrayMXS, setFormulaArrayMXS] = React.useState([]);
    const [calculationStore, setCalculationStore] = React.useState([]);
    const [calculationStoreMX, setCalculationStoreMX] = React.useState([]);
    const [keyFigureArrayMXSColumns, setKeyFigureArrayMXSColumns] = React.useState([]);
    const [attributeArrayMXSColumns, setAttributeArrayMXSColumns] = React.useState([]);
    const [formulaArrayMXSColumns, setFormulaArrayMXSColumns] = React.useState([]);
    const [keyFigureObject, setKeyFigureObject] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeNameObject, setAttributeNameObject] = React.useState([]);
    const [keyFigureKeyArray, setKeyFigureKeyArray] = React.useState([]);
    //add timing 
    const [calculationStoreScaleAPI, setCalculationStoreScaleAPI] = React.useState([]);
    const [timingRow, setTimingRow] = React.useState([{}]);
    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
    const inputFile = useRef(null)
    const [currentFile, setCurrentFile] = React.useState(undefined);
    const [currentFileName, setCurrentFileName] = React.useState('');
    const [attributeCount, setAttributeCount] = React.useState('');
    const [attributeNameArray, setAttributeNameArray] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    function checkRate(e) {
        if (e > 100) {
            setTooltipIsOpen(true)
            setTimeout(
                () => setTooltipIsOpen(false),
                4000
            );
        }
    }
    function hasNull(element, index, array) {
        return Object.keys(element).some(
            function (key) {
                return element[key] === "";
            }
        );
    }

    const handleDeleteLocally = (index, type) => {
        if (type === 'Multi Axis') {
            setCalculationStoreMX(item => item.filter((item, i) => i !== index));
        } else {
            setCalculationStore(item => item.filter((item, i) => i !== index));
        }
        props.onChange(item => item.filter((item, i) => i !== index))
    }
    useEffect(() => {
        props.getGeneralUOM();
        props.getFormulasList();
        // props.getAttributeListData({
        //     attribute_name: ''
        // })
        if (props.calculationMethod === 'Price Point') {
            props.getPricingCalculationFormula();
        }
    }, []);
    useEffect(() => {
        if (props.startDate)
            setEffectiveStartDate(props.startDate)
        if (props.endDate)
            setEffectiveEndDate(props.endDate)

    }, [props.startDate, props.endDate])
    // useEffect(() => {
    //     if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
    //         props.dropdownData.records
    //             .filter(item => item.app_source_type == sessionStorage.getItem('application') && item.data_source_type == props.sourceDataType && item.field_id === 'key_figure_name')
    //             .map((item) => {
    //                 setKeyFigureData(item.drop_down_value_keys);
    //                 setKeyFigureKeyArray(item.drop_down_value_keys);
    //                 setKeyFigureObject(item);
    //             })
    //     }
    // }, [props.sourceDataType, props.dropdownData])
    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records[0]) {
            if (props.dropdownAttributeData.records[0].field_id === "attribute_name") {
                setAttributeNameData(props.dropdownAttributeData.records[0].drop_down_value_keys);
                setAttributeNameArray(props.dropdownAttributeData.records[0].label_names);
                setAttributeNameObject(props.dropdownAttributeData.records[0])
            }
        }
    }, [props.dropdownAttributeData])
    // useEffect(() => {
    //     if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records[0]) {
    //         if (props.dropdownAttributeData.records[0].field_id === "attribute_name") {
    //             setAttributeNameArray(props.dropdownAttributeData.records[0].label_names);
    //         }
    //     }
    // }, [props.dropdownAttributeData])

    const [mappingValuesSet, setMappingValuesSet] = React.useState({});
    const [mappingValuesSetAttribute, setMappingValuesSetAttribute] = React.useState({});

    const [keyFigureArrayMXSAPI, setKeyFigureArrayMXSAPI] = React.useState([]);
    const [attributeArrayMXSAPI, setAttributeArrayMXSAPI] = React.useState([]);
    const [formulaArrayMXSAPI, setFormulaArrayMXSAPI] = React.useState([]);
    const [operationArray, setOperationArray] = React.useState([]);
    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [keyfigurename, setKeyfigurename] = React.useState('');
    const [calculation, setCalculation] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);
    const [rate, setRate] = React.useState('');
    const [uom, setUom] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [validateError, setValidateError] = React.useState(false);
    const [keyFigureData, setKeyFigureData] = React.useState([]);
    const [keyfigureEnableHelper, setKeyfigureEnableHelper] = React.useState(false);
    const [attributeNameValue, setAttributeNameValue] = React.useState([]);
    const [attributeNameMR, setAttributeNameMR] = React.useState('');
    const [attributeValueMR, setAttributeValueMR] = React.useState('');
    const [keyFigureNameMR, setKeyFigureNameMR] = React.useState('');
    const [rateMR, setRateMR] = React.useState('');
    const [editAttributeValue, setEditAttributeValue] = React.useState('');
    const [uomMR, setUomMR] = React.useState('');
    const [rateTypeMR, setRateTypeMR] = React.useState('');
    const [startDateMR, setStartDateMR] = React.useState(null);
    const [endDateMR, setEndDateMR] = React.useState(null);
    const [rateType, setRateType] = React.useState('');
    const [rateTypeCB, setRateTypeCB] = React.useState(props.calculationMethod === 'Price Difference' ? '$' : '');
    const [rateTypeScale, setRateTypeScale] = React.useState('');
    const [keyfigureEnableHelperMR, setKeyfigureEnableHelperMR] = React.useState(false);
    const [keyfigureValueMR, setKeyfigureValueMR] = React.useState([]);
    const [calculationTypeTier, setCalculationTypeTier] = React.useState('');
    const [formulaTypeTier, setFormulaTypeTier] = React.useState('');
    const [maximumAmount, setMaximumAmount] = React.useState('');
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const calculationTypeData = ['Tier', 'Rebate', 'Both']
    const calculationTypeDataMXS = ['Tier', 'Rebate']
    const formulaTypeDataTier = [
        { key: 'Key Figure', value: 'Key Figure' },
        { key: 'Formula', value: 'Formula' }
    ];
    const formulaTypeDataRebate = [
        { key: 'Key Figure', value: 'Key Figure' }
    ];
    var flag = { 'customer_number': false, 'material_number': false, 'supplier_number': false, 'employee_number': false }

    useEffect(() => {
        if (props.sourceDataType && attributeNameData && attributeNameData.length == 0) {
            props.onLoadingAttribute(sessionStorage.getItem('application'), props.sourceDataType);
        }
    }, [props.sourceDataType])
    // useEffect(() => {

    //     if (activeDropdown) {
    //         if (attributeNameValue.includes('customer_number') && activeDropdown['customer_number'] && !flag.customer_number) {
    //             flag['customer_number'] = true
    //             props.getCustomerMasterData();
    //         }

    //         if (attributeNameValue.includes('material_number') && activeDropdown['material_number'] && !flag['material_number']) {
    //             flag['material_number'] = true
    //             props.getMaterialMasterData();

    //         } if (attributeNameValue.includes('supplier_number') && activeDropdown['supplier_number'] && !flag['supplier_number']) {
    //             flag['supplier_number'] = true
    //             props.getSupplierMasterData();
    //         }
    //         if (attributeNameValue.includes('employee_number') && activeDropdown['employee_number'] && !flag['employee_number']) {
    //             flag['employee_number'] = true
    //             props.getEmployeeData();
    //         }
    //     }

    // }, [attributeNameValue, activeDropdown])
    function handleAttributeNameMR(event) {
        if ((event.target.value == 'customer_number' || event.target.value === 'ship_to_party' || event.target.value === 'bill_to_party'
            || event.target.value === 'sold_to_party' || event.target.value === 'payer') && !(attributeNameValue.includes('customer_number'))) {
            // setAttributeNameValue('customer_number')
            setAttributeNameValue([...attributeNameValue, 'customer_number'])
        }
        else
            if ((event.target.value == 'employee_number' || event.target.value == 'supplier_number' || event.target.value == 'material_number') && !(attributeNameValue.includes(event.target.value)))
                setAttributeNameValue([...attributeNameValue, event.target.value])
        //setAttributeNameValue(event.target.value)
        setAttributeNameMR(event.target.value);
        setAttributeValueMR('');
    }
    function handleAttributeValueMR(e, type) {
        if (type === 'attr') {
            var val = e
            if (e && e.length > 0) {
                var index = e.indexOf('-');
                if (index != -1)
                    val = e.substring(0, index - 1)
            }
            setAttributeValueMR(val)
        }
        else
            setAttributeValueMR(e.target.value);
    }
    function handleKeyFigurenameMR(event) {
        setKeyfigureValueMR(keyFigureObject.drop_down_value_keys[event.target.value]);
        setKeyFigureNameMR(event.target.value);
        setKeyfigureEnableHelperMR((event.target.value || event.target.value === 0) ? (event.target.value + 1) : 0);

    }
    function handleRateMR(event) {
        if (event.target.value != 0 || event.target.value === '') {
            checkRate(event.target.value);
            setRateMR(event.target.value);
        }
    }
    function handleUomMR(event) {
        setUomMR(event.target.value);
    }
    function handleStartDateMR(event) {
        setStartDateMR(event);
    }
    function handleEndDateMR(event) {
        setEndDateMR(event);
    }
    //cb
    const [attributeNameCB, setAttributeNameCB] = React.useState('');
    const [attributeValueCB, setAttributeValueCB] = React.useState('');
    const [acquisitionPriceCB, setAcquisitionPriceCB] = React.useState('');
    const [contractPriceCB, setContractPriceCB] = React.useState('');
    const [uomCB, setUomCB] = React.useState('');
    const [startDateCB, setStartDateCB] = React.useState(null);
    const [endDateCB, setEndDateCB] = React.useState(null);
    const [idValue, setIdValue] = React.useState('');
    const [keyFigureNameAPI, setKeyFigureNameAPI] = React.useState('');
    const [ruleID, setRuleID] = React.useState(0);
    function handleAcquisitionPriceCB(event) {
        setAcquisitionPriceCB(event.target.value);
    }
    function handleContractPriceCB(event) {
        setContractPriceCB(event.target.value);
    }
    function handleUomCB(event) {
        setUomCB(event.target.value);
    }
    function handleStartDateCB(event) {
        setStartDateCB(event);
    }
    function handleEndDateCB(event) {
        setEndDateCB(event);
    }
    //scale items
    const [keyFigureNameScale, setKeyFigureNameScale] = React.useState('');
    const [keyfigureValueScale, setKeyfigureValueScale] = React.useState('');
    const [rateScale, setRateScale] = React.useState('');
    const [uomScale, setUomScale] = React.useState('');
    const [startDateScale, setStartDateScale] = React.useState(null);
    const [endDateScale, setEndDateScale] = React.useState(null);
    const [currentEditCalculationRuleScale, setCurrentEditCalculationRuleScale] = React.useState([]);
    const [formulaName, setFormulaName] = React.useState('');

    function handleKeyFigurenameScale(event) {
        setKeyfigureValueScale(keyFigureObject.drop_down_value_keys[event.target.value]);
        setKeyFigureNameScale(event.target.value);
    }
    function handleUomScale(event) {
        setUomScale(event.target.value);
    }
    function handleStartDateScale(event) {
        setStartDateScale(event);
    }
    function handleEndDateScale(event) {
        setEndDateScale(event);
    }
    function handleRateScale(event) {
        if (event.target.value != 0 || event.target.value === '') {
            checkRate(event.target.value);
            setRateScale(event.target.value);
        }
    }
    const uomData = [
        { key: '$', value: '$' },
        { key: '%', value: '%' }
    ];
    const operationData = [
        { key: '+', value: '+' },
        { key: '-', value: '-' }
    ];
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    useEffect(() => {
        if (props.sourceDataType)
            props.onLoadingDefaultCR(sessionStorage.getItem('application'), props.sourceDataType);
    }, [props.sourceDataType])

    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            setKeyFigureData(props.dropdownDataCR.records[0].drop_down_value_keys);
            setKeyFigureObject(props.dropdownDataCR.records[0])
            setMappingValuesSet(props.dropdownDataCR.records[0].label_names);
        }
    }, [props.dropdownDataCR])

    const clone = (item) => {
        var temp = item
        if (item.ID) {
            delete temp.ID;
            delete temp.CreatedAt;
            delete temp.DeletedAt;
            delete temp.UpdatedAt;
            delete temp.rule_id;
        }

        setCalculationStoreMX([...calculationStoreMX, temp])
        const index = calculationStoreMX.map(el => el.type).lastIndexOf("Tier");
        editcalculationRule(temp, index + 1, 'Multi Axis')
        toast.success("Calculation rule copied successfully")
    }
    const editcalculationRule = (rule, index, type) => {
        var name = ''
        if (editIndex !== index) {
            setEditIndex(index);
            setEditMode(true);
            if (rule.ID && index != -1) {
                setIdValue(rule.ID);
            } else {
                setIdValue('');
            }
            if (rule.rule_id) {
                setRuleID(rule.rule_id);
            } else {
                setRuleID(0);
            }
            setEditableData(rule)
            if (type === 'FA') {
                name = rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_name;
                setAttributeNameMR(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_name);
                setAttributeValueMR(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_value);
                setKeyFigureNameMR(rule.key_figure_names && rule.key_figure_names.length > 0 && rule.key_figure_names[0]);

                setRateMR(rule.rate);
                setUomMR(rule.incentive_uom);
                setRateTypeMR(rule.rate_type);
                setStartDateMR(rule.effective_start_date);
                setEndDateMR(rule.effective_end_date);
            }
            if (type === 'Quota') {
                var result;
                setEditAttributeValue(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_value)
                setRate(rule.rate);
                setKeyfigurename(rule.key_figure_names && rule.key_figure_names.length > 0 && rule.key_figure_names[0])
                setUom(rule.incentive_uom);
                setRateType(rule.rate_type);
                setEffectiveStartDate(rule.effective_start_date);
                setEffectiveEndDate(rule.effective_end_date);
                setTimingRow(rule.timing);
                if (rule && rule.key_figure_names) {
                    result = rule.key_figure_names
                        .filter((item, index) => index != 0)
                        .map((name, index) => {
                            return {
                                'key_figure_names': name,
                                'operation': rule.operations[index]
                            }
                        })
                }
                setOperationArray(result);
            }
            if (type === 'CB') {
                setAttributeNameCB('material_number');
                if (!attributeNameValue.includes('material_number'))
                    setAttributeNameValue([...attributeNameValue, 'material_number'])
                var value = ''
                rule.attribute_key_values && rule.attribute_key_values.filter(item => item.attribute_value).map(e =>
                    value = e.attribute_value)
                setAttributeValueCB(value)
                setAcquisitionPriceCB(rule.attribute_key_values && rule.attribute_key_values.filter(item => item.acquisition_price).map(e => e.acquisition_price))
                setContractPriceCB(rule.attribute_key_values && rule.attribute_key_values.filter(item => item.contract_price).map(e => e.contract_price))
                setRateTypeCB(rule.rate_type);
                setUomCB(rule.incentive_uom);
                setStartDateCB(rule.effective_start_date);
                setEndDateCB(rule.effective_end_date);


            }
            if (type === 'MR') {
                name = rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_name
                setAttributeNameMR(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_name);
                setAttributeValueMR(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_value);
                setKeyFigureNameMR(rule.key_figure_names && rule.key_figure_names.length > 0 && rule.key_figure_names[0]);
                setRateMR(rule.rate);
                setUomMR(rule.incentive_uom);
                setRateTypeMR(rule.rate_type);
                setStartDateMR(rule.effective_start_date);
                setEndDateMR(rule.effective_end_date);
                setFormulaName(rule.formula_id)
                setFormulaTypeTier(rule.formula_type)

            }
            if (type === 'BOM Explosion') {
                setAttributeNameMR('material_number');
                if (!attributeNameValue.includes('material_number'))
                    setAttributeNameValue([...attributeNameValue, 'material_number'])
                setAttributeValueMR(rule.attribute_value);
                setKeyFigureNameMR(rule.key_figure_names[0]);
                setRateMR(rule.rate);
                setUomMR(rule.incentive_uom);
                setRateTypeMR(rule.rate_type)
                setStartDateMR(rule.effective_start_date);
                setEndDateMR(rule.effective_end_date);

            }
            if (type === 'Tiered Volume - Simple') {
                setRateTypeScale(rule.rate_type);
                if (rule.type === 'Rebate') {
                    setEditAttributeValue('');
                } else {
                    setEditAttributeValue(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_value)
                }
                if (rule.maximum_amount)
                    setMaximumAmount(rule.maximum_amount)
                if (rule && rule.key_figure_names) {
                    const result = rule.key_figure_names
                        .filter((item, index) => index != 0)
                        .map((name, index) => {
                            return {
                                'key_figure_names': name,
                                'operation': rule.operations[index]
                            }
                        })
                    setOperationArray(result);
                }
                setCurrentEditCalculationRuleScale(rule);
                setRateScale(rule.rate);
                if (rule && rule.key_figure_names && rule.key_figure_names[0]) //need to test
                    setKeyFigureNameScale(rule.key_figure_names[0]);
                setUomScale(rule.incentive_uom);
                setStartDateScale(rule.effective_start_date);
                setEndDateScale(rule.effective_end_date);
                setCalculationTypeTier(rule.type);
                setFormulaTypeTier(rule.formula_type);
                setFormulaName(rule.formula_id);

            }
            else if (type === 'Multi Axis') {
                var master = attributeNameValue
                setRateTypeScale(rule.rate_type);
                if (rule.maximum_amount)
                    setMaximumAmount(rule.maximum_amount)
                if (rule.type === 'Rebate') {
                    setEditAttributeValue('');
                } else {
                    setEditAttributeValue(rule.attribute_values && rule.attribute_values[0] && rule.attribute_values[0])
                }

                if (rule && rule.key_fig_api) {
                    const result = rule.key_fig_api
                        .map((name, index) => {
                            return {
                                'key': name.key,
                                'value': name.value
                            }
                        })
                    setKeyFigureArrayMXS(result);
                    setKeyFigureArrayMXSAPI(result);
                }
                if (rule && rule.attri_api) {
                    const result = rule.attri_api
                        .map((name, index) => {
                            if ((name.key == 'customer_number' || name.key === 'ship_to_party' || name.key === 'bill_to_party'
                                || name.key === 'sold_to_party' || name.key === 'payer') && !(master.includes('customer_number')))
                                master.push('customer_number')
                            else
                                if ((name.key == 'employee_number' || name.key == 'supplier_number' || name.key == 'material_number') && !(master.includes(name.key)))
                                    master.push(name.key)

                            return {
                                'key': name.key,
                                'value': name.value
                            }
                        })
                    setAttributeArrayMXS(result);
                    setAttributeArrayMXSAPI(result);
                    setAttributeNameValue([...attributeNameValue, ...master])
                    console.log(master)
                }
                if (rule && rule.form_api) {
                    const result = rule.form_api
                        .map((name, index) => {
                            return {
                                'key': name.key,
                                'value': name.value
                            }
                        })
                    setFormulaArrayMXS(result);
                    setFormulaArrayMXSAPI(result);
                }
                var result;
                if (rule && rule.key_figure_names) {
                    result = rule.key_figure_names
                        .filter((item, index) => index != 0)
                        .map((name, index) => {
                            return {
                                'key_figure_names': name,
                                'operation': rule.operations[index]
                            }
                        })
                }
                setOperationArray(result);
                setCurrentEditCalculationRuleScale(rule);
                setRateScale(rule.rate);
                setStartDateScale(rule.effective_start_date);
                setEndDateScale(rule.effective_end_date);
                setCalculationTypeTier(rule.type);
                setFormulaTypeTier(rule.formula_type);
                setFormulaName(rule.formula_id);
                setKeyFigureNameScale(rule.key_figure_names && rule.key_figure_names[0] && rule.key_figure_names[0])
                setAttributeNameMR(rule.attribute_name)
                setAttributeCount(rule.attribute_count)
            }

            else {
                if (props.calculationMethod === 'Price Point' || rule.formula_id) {
                    setFormulaName(rule.formula_id)
                } else {
                    if (rule && rule.key_figure_names && rule.key_figure_names[0])
                        setKeyfigurename(rule.key_figure_names[0]);
                    if (rule && rule.key_figure_names) {
                        const result = rule.key_figure_names
                            .filter((item, index) => index != 0)
                            .map((name, index) => {
                                return {
                                    'key_figure_names': name,
                                    'operation': rule.operations[index]
                                }
                            })
                        setOperationArray(result);
                    }
                }

                setCalculation(rule.calculation_type);
                setEffectiveStartDate(rule.effective_start_date);
                setEffectiveEndDate(rule.effective_end_date);
                setRate(rule.rate);
                setUom(rule.incentive_uom);
                setRateType(rule.rate_type)
                setFormulaTypeTier(rule.formula_type)
            }
            if (name) {
                if ((name == 'customer_number' || name === 'ship_to_party' || name === 'bill_to_party'
                    || name === 'sold_to_party' || name === 'payer') && !attributeNameValue.includes('customer_number'))
                    setAttributeNameValue([...attributeNameValue, 'customer_number'])
                else
                    if ((name == 'employee_number' || name == 'supplier_number' || name == 'material_number') && !attributeNameValue.includes(name))
                        setAttributeNameValue([...attributeNameValue, name])
            }
        } else {
            alert('Already editing the same rule.')
        }
    }
    function handleDropdown(name) {
        if (name) {
            if ((name == 'customer_number' || name === 'ship_to_party' || name === 'bill_to_party'
                || name === 'sold_to_party' || name === 'payer') && !attributeNameValue.includes('customer_number'))
                setAttributeNameValue([...attributeNameValue, 'customer_number'])
            else
                if ((name == 'employee_number' || name == 'supplier_number' || name == 'material_number') && !attributeNameValue.includes(name))
                    setAttributeNameValue([...attributeNameValue, name])
        }
    }
    function handleKeyfigurename(event) {
        //setKeyFigureNameAPI(keyFigureKeyArray[keyFigureData.indexOf(event.target.value)])
        setKeyfigurename(event.target.value);
        setKeyfigureEnableHelper((event.target.value || event.target.value === 0) ? (event.target.value + 1) : 0);
    }
    function handleEffectiveStartDate(event) {
        if (event && event.isValid())
            setEffectiveStartDate(event.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setEffectiveStartDate(event)
    }
    function handleEffectiveEndDate(newValue) {
        if (newValue && newValue.isValid())
            setEffectiveEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setEffectiveEndDate(newValue)
    }
    function handleRate(event) {
        if (event.target.value != 0 || event.target.value === '') {
            checkRate(event.target.value);
            setRate(event.target.value);
        }
    }
    function handleUom(event) {
        setUom(event.target.value);
    }
    function handleRemoveQuota() {
        setKeyfigurename('');
        setUom('');
        setRateType('');
        setRate('');
        setTimingRow([{}]);
        if (startDateScale != props.startDate)
            setEffectiveStartDate(null);
        if (endDateScale != props.endDate)
            setEffectiveEndDate(null);
        setEditIndex('');
        setEditMode(false);
        setTimingRow([{}]);
        setOperationArray([]);
        setKeyFigureData([]);
        setEditAttributeValue('')
        setIdValue('')
        setRuleID(0)

    }
    function handleSubmit(id, type) {
        if (type === 'CB') {
            if (attributeValueCB && (currentApplication != 'Pricing' ? acquisitionPriceCB : true)
                && contractPriceCB && startDateCB && endDateCB) {
                var data = {
                    "attribute_name": 'material_number',
                    "incentive_uom": uomCB,
                    "rate_type": rateTypeCB,
                    "effective_start_date": startDateCB,
                    "effective_end_date": endDateCB,
                    "attribute_key_values": [
                        {
                            "attribute_type": 'Attribute',
                            "attribute_name": 'material_number',
                            "attribute_value": attributeValueCB,
                            "acquisition_price": acquisitionPriceCB ? parseFloat(acquisitionPriceCB) : '',
                            "contract_price": parseFloat(contractPriceCB),
                        }
                    ]
                };
                if (idValue) {
                    data.ID = idValue;
                    data.rule_id = ruleID
                }
                if (id || id === 0) {
                    var editedArray = [...calculationStore];
                    editedArray[editIndex] = data;
                    setCalculationStore(editedArray);
                    props.onChange(editedArray)
                    localStorage.removeItem('editableRuleMode');
                } else {
                    setCalculationStore([...calculationStore, data]);
                    props.onChange([...calculationStore, data])
                }
                handleRemoveCB();
            } else {
            }
        } else if (type === 'MR') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            keyfigArr.push(keyFigureNameMR);
            var data = {
                "key_figure_names": formulaTypeTier !== 'Formula' ? keyfigArrAPI : [],
                "rate": parseFloat(rateMR),
                "incentive_uom": uomMR,
                "rate_type": rateTypeMR,
                "effective_start_date": startDateMR,
                "effective_end_date": endDateMR,
                "attribute_key_values": [{
                    "attribute_type": 'Attribute',
                    "attribute_name": attributeNameMR,
                    "attribute_value": attributeValueMR,
                }],
                "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                "formula_type": formulaTypeTier
            };
            if (idValue) {
                data.ID = idValue;
                data.rule_id = ruleID
            }
            if (id || id === 0) {
                var editedArray = [...calculationStore];
                editedArray[editIndex] = data;
                setCalculationStore(editedArray);
                props.onChange(editedArray)
                localStorage.removeItem('editableRuleMode');
            } else {
                setCalculationStore([...calculationStore, data]);
                props.onChange([...calculationStore, data])
            }
            handleRemoveMR();

        }

        else if (type === 'Tiered Volume - Simple') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];
            var data = {}

            //keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyFigureNameScale)])
            keyfigArr.push(keyFigureNameScale);
            operationArray.forEach(function (item, index) {
                // keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });
            if (calculationTypeTier != 'Rebate') {

                if (formulaTypeTier === 'Formula' && formulaName) {
                    data = {
                        "type": calculationTypeTier,
                        "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                        "key_figure_names": formulaTypeTier === 'Formula' ? [] : keyfigArr,
                        "operations": opArr,
                        "rate": parseFloat(rateScale),
                        "incentive_uom": uomScale,
                        "rate_type": type === 'Rebate' ? '' : rateTypeScale,
                        "effective_start_date": startDateScale,
                        "effective_end_date": endDateScale,
                        "formula_type": formulaTypeTier,
                        "maximum_amount": maximumAmount ? parseFloat(maximumAmount) : 0,
                        "attribute_key_values": [{
                            "attribute_type": "Formula",
                            "attribute_name": formulaName.toString(),
                            "attribute_value": Number(editAttributeValue)
                        },
                            // {
                            //     'priority': ''
                            // }
                        ]
                    };
                }
                else {
                    var attributeKeyValue = []
                    var temp
                    keyfigArr?.map((single, ix2) => {
                        temp = {};
                        temp.attribute_type = 'Key Figure';
                        temp.attribute_name = single;
                        temp.attribute_value = Number(editAttributeValue)
                        attributeKeyValue.push(temp);
                    })
                    //attributeKeyValue.push({ 'priority': '' });
                    data = {
                        "type": calculationTypeTier,
                        "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                        "key_figure_names": formulaTypeTier === 'Formula' ? [] : keyfigArr,
                        "operations": opArr,
                        //"attribute_values": [editAttributeValue],
                        //"attribute_names": keyfigArr,
                        "rate": parseFloat(rateScale),
                        "incentive_uom": uomScale,
                        "rate_type": type === 'Rebate' ? '' : rateTypeScale,
                        "effective_start_date": startDateScale,
                        "effective_end_date": endDateScale,
                        "formula_type": formulaTypeTier,
                        "attribute_key_values": attributeKeyValue,
                        "maximum_amount": maximumAmount ? parseFloat(maximumAmount) : 0,
                    };

                }

            } else {
                data = {
                    "type": calculationTypeTier,
                    "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                    "key_figure_names": formulaTypeTier === 'Formula' ? [] : keyfigArr,
                    "operations": opArr,
                    "effective_start_date": startDateScale,
                    "effective_end_date": endDateScale,
                    "formula_type": formulaTypeTier,
                };
            }
            var duplicate = []
            if (idValue) {
                data.ID = idValue;
                data.rule_id = ruleID
            }
            if (id || id === 0) {
                var editedArray = [...calculationStore];
                editedArray[editIndex] = data;
                duplicate = editedArray
            }
            else {
                duplicate = [...calculationStore, data]

            }
            //var priority = 0
            const order = ({ attribute_key_values }) => attribute_key_values && attribute_key_values.find(o => 'attribute_value' in o)['attribute_value'];
            // duplicate.sort((a, b) => order(a) - order(b))
            //     //sort((a, b) => (a.attribute_key_values && a.attribute_key_values[0] && a.attribute_key_values[0]['attribute_value']) > (b.attribute_key_values && b.attribute_key_values[0] && b.attribute_key_values[0]['attribute_value']) ? 1 : -1)
            //     .map((item, i) => {
            //         if (item.attribute_key_values && item.attribute_key_values.length > 0 && type != 'Rebate') {
            //             priority = priority + 1
            //             duplicate[i]['attribute_key_values'][item.attribute_key_values.length - 1] = { 'priority': priority }
            //         }
            //     })
            setCalculationStore(duplicate)
            props.onChange(duplicate)
            handleRemoveScale();
        }

        else if (type === 'Quota') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];

            // keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyfigurename)])
            keyfigArr.push(keyfigurename);
            operationArray.forEach(function (item, index) {
                //keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });

            if ((keyfigurename)

                && effectiveStartDate && effectiveEndDate) {
                var data = {
                    "key_figure_names": keyfigArr,
                    "incentive_uom": uom,
                    "rate": parseFloat(rate),
                    "effective_start_date": effectiveStartDate,
                    "effective_end_date": effectiveEndDate,
                    "operations": opArr,
                    "rate_type": rateType,
                    "attribute_key_values": [{
                        "attribute_type": 'Key Figure',
                        "attribute_name": 'attainment_percentage',
                        "attribute_value": parseFloat(editAttributeValue)
                    }]
                };
                if (idValue) {
                    data.ID = idValue;
                    data.rule_id = ruleID
                }
                if (id || id === 0) {
                    var editedArray = calculationStore;
                    calculationStore[id] = data;
                    var duplicated = [...calculationStore, data];
                    props.onChange(duplicated);
                    props.onChange(calculationStore);
                } else {
                    setCalculationStore([...calculationStore, data]);
                    var duplicated = [...calculationStore, data];
                    props.onChange(duplicated);
                }
            }
            handleRemoveQuota();
        }


        else if (type === 'BOM Explosion') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            //keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyFigureNameMR)])
            keyfigArr.push(keyFigureNameMR);
            if (attributeValueMR && keyFigureNameMR
                && rateMR && startDateMR && endDateMR) {
                var data = {
                    "attribute_name": 'material_number',
                    "attribute_value": attributeValueMR,
                    "key_figure_names": keyfigArr,
                    "rate": parseFloat(rateMR),
                    "incentive_uom": uomMR,
                    "rate_type": rateTypeMR,
                    "effective_start_date": startDateMR,
                    "effective_end_date": endDateMR
                };
                if (idValue) {
                    data.ID = idValue;
                    data.rule_id = ruleID
                }
                if (id || id === 0) {
                    var editedArray = [...calculationStore];
                    editedArray[editIndex] = data;
                    setCalculationStore(editedArray);
                    props.onChange(editedArray)
                    localStorage.removeItem('editableRuleMode');
                    setEditMode(false);
                } else {
                    setCalculationStore([...calculationStore, data]);
                    props.onChange([...calculationStore, data])
                }
                handleRemoveMR();
            } else {
            }
        }
        else if (type === 'FA') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            //keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyFigureNameMR)])
            keyfigArr.push(keyFigureNameMR);
            if (attributeValueMR && keyFigureNameMR
                && rateMR && startDateMR && endDateMR) {
                var data = {
                    "key_figure_names": keyfigArr,
                    "rate": parseFloat(rateMR),
                    "incentive_uom": uomMR,
                    "rate_type": rateTypeMR,
                    "effective_start_date": startDateMR,
                    "effective_end_date": endDateMR,
                    "attribute_key_values": [{
                        "attribute_type": 'Attribute',
                        "attribute_name": attributeNameMR,
                        "attribute_value": attributeValueMR,
                    }]
                };
                if (idValue) {
                    data.ID = idValue;
                    data.rule_id = ruleID
                }
                if (id || id === 0) {
                    var editedArray = [...calculationStore];
                    editedArray[editIndex] = data;
                    setCalculationStore(editedArray);
                    props.onChange(editedArray)
                    localStorage.removeItem('editableRuleMode');
                } else {
                    setCalculationStore([...calculationStore, data]);
                    props.onChange([...calculationStore, data])
                }
                handleRemoveMR();
            }

        }
        else if (type === 'Multi Axis') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];
            keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyFigureNameScale)])
            keyfigArr.push(keyFigureNameScale);

            operationArray && operationArray.forEach(function (item, index) {
                keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });
            var keyfigValArr = [];
            var calcStor = calculationStoreMX;
            var data = {}
            if (calculationTypeTier === 'Rebate') {
                data = {
                    "key_figure_names": formulaTypeTier === 'Formula' ? [] : keyfigArr,//[keyFigureNameScale],
                    "effective_start_date": startDateScale,
                    "effective_end_date": endDateScale,
                    "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                    "type": "Rebate",
                    "rate_type": '%',
                    "formula_type": formulaTypeTier,
                    "operations": opArr,
                };
            } else {
                data = {
                    "key_figure_values": keyFigureArrayMXS,
                    "attribute_values": attributeArrayMXS,
                    "formula_values": formulaArrayMXS,
                    "rate": parseFloat(rateScale),
                    "effective_start_date": startDateScale,
                    "effective_end_date": endDateScale,
                    "type": "Tier",
                    "rate_type": '%',
                    "attri_api": attributeArrayMXSAPI,
                    "key_fig_api": keyFigureArrayMXSAPI,
                    "form_api": formulaArrayMXSAPI,
                    "maximum_amount": maximumAmount ? parseFloat(maximumAmount) : 0,
                    'attribute_name': attributeNameMR,
                    'attribute_count': attributeCount ? parseFloat(attributeCount) : 0
                };
            }
            if (idValue) {
                data.ID = idValue;
                data.rule_id = ruleID
            }
            var duplicateArray = []
            if (id || id === 0) {
                var editedArray = calculationStoreMX;
                duplicateArray = calculationStoreMX;
                calculationStoreMX[id] = data;
            } else {
                duplicateArray = [...calculationStoreMX, data]
                setCalculationStoreMX([...calculationStoreMX, data]);
            }


            var apiArray = [];
            var apiArray = [];
            var tempKF = 0;
            var tempA = 0;
            var tempF = 0;
            duplicateArray.map((itemMain, index) => {
                if (itemMain.key_fig_api && itemMain.key_fig_api.length > tempKF) {
                    tempKF = itemMain.key_fig_api.length
                    setKeyFigureArrayMXSColumns(itemMain.key_fig_api.length);
                }
                if (itemMain.attri_api && itemMain.attri_api.length > tempA) {
                    tempA = itemMain.attri_api.length
                    setAttributeArrayMXSColumns(itemMain.attri_api.length);
                }
                if (itemMain.form_api && itemMain.form_api.length > tempF) {
                    tempF = itemMain.form_api.length
                    setFormulaArrayMXSColumns(itemMain.form_api.length);
                }
                if (itemMain.type === "Tier") {
                    var tempKFN = [];
                    var tempCondition = []
                    itemMain.key_fig_api && itemMain.key_fig_api.map(item => {
                        tempKFN.push({ 'attribute_name': item.key, 'attribute_value': item.value ? parseFloat(item.value) : 0, 'attribute_type': 'Key Figure' })

                    })
                    itemMain.attri_api && itemMain.attri_api.map(item => {
                        tempKFN.push({ 'attribute_name': item.key, 'attribute_value': item.value, 'attribute_type': 'Attribute' })


                    })
                    itemMain.form_api && itemMain.form_api.map(item => {
                        tempKFN.push({ 'attribute_name': item.key ? item.key.toString() : '', 'attribute_value': item.value ? parseInt(item.value) : 0, 'attribute_type': 'Formula' })

                    })
                    if (props.incentiveRateType === 'Conditional Attribute') {
                        tempCondition.push({
                            "attribute_name": itemMain.attribute_name,
                            "attribute_type": "Counter",
                            "attribute_value": itemMain.attribute_count,
                            "attribute_conditions": tempKFN
                        },
                            // { 'priority': index + 1 }
                        )
                    }
                    // else
                    //     tempKFN.push({ 'priority': index + 1 })
                    var temp = {
                        "attribute_key_values": props.incentiveRateType === 'Conditional Attribute' ? tempCondition : tempKFN,
                        "rate": parseFloat(itemMain.rate),
                        "effective_start_date": itemMain.effective_start_date,
                        "effective_end_date": itemMain.effective_end_date,
                        "type": "Tier",
                        "rate_type": '%',
                        'maximum_amount': parseFloat(itemMain.maximum_amount)
                    }
                    if (itemMain.ID) {
                        temp.ID = itemMain.ID
                        temp.rule_id = ruleID
                    }
                    apiArray.push(temp)
                } else {
                    if (itemMain.formula_id)
                        apiArray.push(itemMain)
                    else {
                        var attributeKey = []
                        var temp = {}
                        itemMain.key_figure_names?.map((key, ix2) => {
                            temp = {};
                            temp.attribute_type = 'Key Figure';
                            temp.attribute_name = key;
                            temp.attribute_value = 0;
                            attributeKey.push(temp);
                        })
                        //attributeKey.push({ 'priority': '' })
                        var dataScaleAPI = {
                            "key_figure_names": itemMain.key_figure_names,
                            "effective_start_date": itemMain.effective_start_date,
                            "effective_end_date": itemMain.effective_end_date,
                            "formula_type": 'Key Figure',
                            "type": itemMain.type,
                            "rate_type": itemMain.rate_type,
                            "operations": itemMain.operations,
                            "attribute_key_values": attributeKey

                        };
                        apiArray.push(dataScaleAPI);
                    }

                }
                // var withIndex = apiArray;
                // withIndex.map((itemIndex, ix) => {
                //     withIndex[ix]['attribute_name'] = (ix + 10).toString();
                // })
                props.onChange(apiArray);
            })
            handleRemoveMXS();
        }
        else {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];
            keyfigArr.push(keyfigurename);
            // keyfigArrAPI.push(keyfigurename);;
            operationArray.forEach(function (item, index) {
                // keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });
            if (effectiveStartDate && effectiveEndDate && rate) {
                var data = {
                    "calculation_type": calculation,
                    "effective_start_date": effectiveStartDate,
                    "effective_end_date": effectiveEndDate,
                    "rate": parseFloat(rate),
                    "incentive_uom": uom,
                    "rate_type": rateType,
                    "enabled": true,
                    // "key_figure_names": keyfigArrAPI,
                    "operations": opArr,
                    "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                    "formula_type": formulaTypeTier
                };
                if (props.calculationMethod === 'Price Point') {
                    data.formula_id = formulaName
                } else {
                    data.key_figure_names = formulaTypeTier !== 'Formula' ? keyfigArr : []
                }
                if (idValue) {
                    data.ID = idValue;
                    data.rule_id = ruleID
                }
                if (id || id === 0) {
                    var editedArray = [...calculationStore];
                    editedArray[editIndex] = data;
                    setCalculationStore(editedArray);
                    props.onChange(editedArray)
                    localStorage.removeItem('editableRuleMode');
                } else {
                    setCalculationStore([...calculationStore, data]);
                    props.onChange([...calculationStore, data])
                }
                handleRemove();
            } else {
                toast.error("Please fill all the mandatory fields.");
                if (!keyfigurename || keyfigurename === '')
                    setKeyfigurename(false);
                if (!calculation || calculation === '')
                    setCalculation(false);
                if (!effectiveStartDate || effectiveStartDate === '' || effectiveStartDate === 'null')
                    setEffectiveStartDate(false);
                if (!effectiveEndDate || effectiveEndDate === '' || effectiveEndDate === 'null')
                    setEffectiveEndDate(false);
                if (!rate || rate === '')
                    setRate(false);
                if (!uom || uom === '')
                    setUom(false);
            }
        }
    }
    function handleRemove() {
        setEditIndex('');
        setEditMode(false);
        setKeyfigurename('');
        setCalculation('');
        if (effectiveStartDate != props.startDate)
            setEffectiveStartDate(null);
        if (effectiveEndDate != props.endDate)
            setEffectiveEndDate(null);
        setRate('');
        setUom('');
        setOperationArray([]);
        setIdValue('')
        setRuleID(0);
        setRateType('');
        setFormulaName('')
        setFormulaTypeTier('')
    }
    function handleRemoveCB() {
        setEditIndex('');
        setAttributeNameCB('');
        setAttributeValueCB('');
        setAcquisitionPriceCB('');
        setContractPriceCB('');
        setUomCB('');
        if (props.calculationMethod != 'Price Difference')
            setRateTypeCB('');
        if (startDateCB != props.startDate)
            setStartDateCB(null);
        if (endDateCB != props.endDate)
            setEndDateCB(null);
        setEditIndex('');
        setEditMode(false);
        setIdValue('')
        setRuleID(0)
    }
    function handleRemoveMR() {
        setAttributeNameMR('');
        setAttributeValueMR('');
        setKeyFigureNameMR('');
        setRateMR('');
        setUomMR('');
        setRateTypeMR('');
        if (startDateMR != props.startDate)
            setStartDateMR(null);
        if (endDateMR != props.endDate)
            setEndDateMR(null);
        setEditIndex('');
        setEditMode(false);
        setIdValue('')
        setRuleID(0)
        setFormulaName('')
        setFormulaTypeTier('')
    }
    function handleRemoveScale() {
        setOperationArray([]);
        setCalculationTypeTier('');
        setFormulaTypeTier('');
        setKeyFigureNameScale('');
        setUomScale('');
        setRateTypeScale('');
        if (startDateScale != props.startDate)
            setStartDateScale(null);
        if (endDateScale != props.endDate)
            setEndDateScale(null);
        setEditIndex('');
        setEditMode(false);
        setTimingRow([{}]);
        setCurrentEditCalculationRuleScale([]);
        setIdValue('')
        setEditAttributeValue('');
        setRateScale('');
        setRuleID(0);
        setMaximumAmount('')
    }
    function handleRemoveMXS() {
        setKeyFigureNameScale('');
        setUomScale('');
        setRateTypeScale('');
        setEditIndex('');
        setEditMode(false);
        setTimingRow([{}]);
        setFormulaName('');
        setOperationArray([]);
        setKeyFigureArrayMXSAPI([]);
        setAttributeArrayMXSAPI([]);
        setFormulaArrayMXSAPI([]);
        setKeyFigureArrayMXS([]);
        setAttributeArrayMXS([]);
        setFormulaArrayMXS([]);
        setCalculationTypeTier('');
        setRateScale('');
        setIdValue('')
        setRuleID(0)
        setMaximumAmount('')
        if (startDateScale != props.startDate)
            setStartDateScale(null);
        if (endDateScale != props.endDate)
            setEndDateScale(null);
        setAttributeNameMR('');
        setAttributeCount('');
    }
    const handleAddOperation = (type) => {
        if (type == 'keyFigure') {
            var data = {
                key: "",
                value: "",
            };
            setKeyFigureArrayMXS([...keyFigureArrayMXS, data]);
            setKeyFigureArrayMXSAPI([...keyFigureArrayMXSAPI, data]);
        } else if (type === 'attribute') {
            var data = {
                key: "",
                value: "",
            };
            setAttributeArrayMXS([...attributeArrayMXS, data]);
            setAttributeArrayMXSAPI([...attributeArrayMXSAPI, data]);
        } else if (type === 'formula') {
            var data = {
                key: "",
                value: ""
            };
            setFormulaArrayMXS([...formulaArrayMXS, data]);
            setFormulaArrayMXSAPI([...formulaArrayMXSAPI, data]);
        } else {
            var data = {
                key_figure_names: "",
                operation: ""
            };
            setOperationArray([...operationArray, data]);
        }
    }
    const handleOperationChange = (e, index, type, name) => {
        if (type == 'keyFigure') {
            const { name, value } = e.target;
            const list = [...keyFigureArrayMXS];
            list[index][name] = value;
            if (name == 'key') {
                list[index]['value'] = ''
            }
            setKeyFigureArrayMXS(list);
            setKeyFigureArrayMXSAPI(list);
        } else if (type === 'attribute') {
            const list = [...attributeArrayMXS];
            if (name)
                list[index][name] = e;
            else {
                const { name, value } = e.target;
                handleDropdown(value)
                list[index][name] = value;
                if (name == 'key') {
                    list[index]['value'] = ''
                }
                addAttributeValue(value)
            }
            setAttributeArrayMXS(list);
            setAttributeArrayMXSAPI(list);
        } else if (type === 'formula') {
            const { name = 'key', value = e } = e?.target || {};
            setFormulaArrayMXS(prevList => {
                const updatedList = prevList.map((item, idx) =>
                    idx === index
                        ? {
                            ...item,
                            [name]: name === 'key' ? String(value) : Number(value),
                            ...(name === 'key' ? { value: '' } : {})
                        }
                        : item
                );
                setFormulaArrayMXS(updatedList);
                setFormulaArrayMXSAPI(updatedList);

                return updatedList;
            });
        }

        else if (type === 'attr') {
            const list = [...attributeArrayMXS];
            var val = e
            if (e && e.length > 0) {
                var i = e.indexOf('-');
                if (i != -1)
                    val = e.substring(0, i - 1)
            }
            if (name == 'key') {
                list[index]['value'] = ' '
            }
            list[index][name] = val;
            setAttributeArrayMXS(list);
            setAttributeArrayMXSAPI(list);

        }
        else {
            const { name, value } = e.target;
            const list = [...operationArray];
            list[index][name] = value;
            setOperationArray(list);
        }
    };
    const handleRemoveOperation = (index, type) => {
        if (type === 'keyFigure') {
            const list = [...keyFigureArrayMXS];
            list.splice(index, 1);
            setKeyFigureArrayMXS(list);
            setKeyFigureArrayMXSAPI(list);
        } else if (type === 'attribute') {
            const list = [...attributeArrayMXS];
            list.splice(index, 1);
            setAttributeArrayMXS(list);
            setAttributeArrayMXSAPI(list);
        } else if (type === 'formula') {
            const list = [...formulaArrayMXS];
            list.splice(index, 1);
            setFormulaArrayMXS(list);
            setFormulaArrayMXSAPI(list);
        } else {
            const list = [...operationArray];
            list.splice(index, 1);
            setOperationArray(list);
        }
    };
    function addAttributeValue(value) {
        if (activeDropdown && activeDropdown[value]) {
            if ((value == 'customer_number' || value == 'bill_to_party' || value == 'sold_to_party') && activeDropdown && activeDropdown['customer_number']) {
                setAttributeValuesList(attributeValuesArray)
                setAttributeNameList(customerNameListArray)
            }
            if (value == 'material_number') {
                setAttributeValuesList(attributeValuesArrayMaterialNumber)
                setAttributeNameList(materialNameListArray)
            }
            if (value == 'supplier_number') {
                setAttributeValuesList(supplierMasterArrayMaterialNumber)
                setAttributeNameList(supplierNameArray)
            }
            if (value == 'employee_number') {
                setAttributeValuesList(employeeMasterArrayMaterialNumber)
                setAttributeNameList(employeeNameListArray)
            }
        }
    }
    useEffect(() => {
        if (props.data && props.data.calculation_method === 'Multi Axis') {
            var calcRules = props.data.calculation_rules;

            if (props.data && props.data.calculation_rules && props.data.calculation_method === 'Multi Axis') {
                const order = ({ attribute_key_values }) => attribute_key_values && attribute_key_values.find(o => 'priority' in o)['priority'];
                props.data.calculation_rules && props.data.calculation_rules.length > 0 && props.data.calculation_rules
                    .sort((a, b) => order(a) - order(b))
                    .map((itemMain, index) => {
                        var tempKF = 0;
                        var rule;
                        var tempF = 0;
                        var tempKFArr = [];
                        var tempAArr = [];
                        var tempFArr = [];
                        itemMain.type === 'Tier' && itemMain.attribute_key_values && itemMain.attribute_key_values
                            .map((itemX, i) => {
                                if (props.data.incentive_rate_type == 'Conditional Attribute') {
                                    itemX.attribute_conditions && itemX.attribute_conditions
                                        .map((itemY, i) => {
                                            if (itemY.attribute_type === 'Key Figure') {
                                                tempKF = tempKF + 1;
                                                var tempA = {
                                                    key: itemY.attribute_name,
                                                    value: itemY.attribute_value
                                                }
                                                tempKFArr.push(tempA);
                                            }
                                            else if (itemY.attribute_type === 'Formula') {
                                                tempF = tempF + 1;
                                                var tempC = {
                                                    key: itemY.attribute_name,
                                                    value: itemY.attribute_value
                                                }
                                                tempFArr.push(tempC);
                                            }
                                        })
                                    if (itemX.attribute_name) {
                                        calcRules[index]['attribute_name'] = itemX.attribute_name;
                                        calcRules[index]['attribute_count'] = itemX.attribute_value;
                                    }


                                }
                                else {
                                    if (itemX.attribute_type === 'Key Figure') {
                                        tempKF = tempKF + 1;
                                        var tempA = {
                                            key: itemX.attribute_name,
                                            value: itemX.attribute_value
                                        }
                                        tempKFArr.push(tempA);
                                    } else if (itemX.attribute_type === 'Attribute') {
                                        tempA = tempA + 1;
                                        var tempB = {
                                            key: itemX.attribute_name,
                                            value: itemX.attribute_value
                                        }
                                        tempAArr.push(tempB);
                                    }
                                    else {
                                        if (itemX.attribute_type === 'Formula') {
                                            tempF = tempF + 1;
                                            var tempC = {
                                                key: itemX.attribute_name,
                                                value: itemX.attribute_value
                                            }
                                            tempFArr.push(tempC);
                                        }
                                    }
                                }

                            })
                        calcRules[index]['key_fig_api'] = tempKFArr;
                        calcRules[index]['attri_api'] = tempAArr;
                        calcRules[index]['form_api'] = tempFArr;
                    })
                setCalculationStoreMX(calcRules)
            }

            calcRules.map(itemMain => {
                var tempKL = 0;
                var tempAL = 0;
                var tempFL = 0;
                if (itemMain.key_fig_api && itemMain.key_fig_api.length > tempKL) {
                    setKeyFigureArrayMXSColumns(itemMain.key_fig_api.length);
                }
                if (itemMain.attri_api && itemMain.attri_api.length > tempAL) {
                    setAttributeArrayMXSColumns(itemMain.attri_api.length);
                }
                if (itemMain.form_api && itemMain.form_api.length > tempFL) {
                    setFormulaArrayMXSColumns(itemMain.form_api.length);
                }
            })

        }
        else if (props.data && props.data.calculation_method && props.data.calculation_method != 'Multi Axis') {
            // if (props.data && props.data.chargeback_calculation_rules && props.data.chargeback_calculation_rules.length > 0) {
            //     setCalculationStore(props.data.chargeback_calculation_rules);
            // }
            // else
            if (props.data && props.data.calculation_rules && props.data.calculation_rules.length > 0) {
                setCalculationStore(props.data.calculation_rules);
                setCalculationStoreScaleAPI(props.data.calculation_rules);
            }
            if ((props.data && props.data.calculation_method && (props.data.calculation_method === 'Tiered Volume' || props.data.calculation_method === 'Tiered Growth'))) {
                const order = ({ attribute_key_values }) => attribute_key_values && attribute_key_values.find(o => 'attribute_value' in o) && attribute_key_values.find(o => 'attribute_value' in o)['attribute_value'];
                var ruleData = props.data.calculation_rules.sort((a, b) => order(a) - order(b))
                setCalculationStore(ruleData)
            }
        }
    }, [props.data]);
    useEffect(() => {
        if (props.calculationMethod === 'BOM Explosion' || props.calculationMethod === 'Price Difference') {
            setAttributeNameMR('material_number');
            if (!attributeNameValue.includes('material_number'))
                setAttributeNameValue([...attributeNameValue, 'material_number'])
        }
    }, [props.calculationMethod]);
    const handleCalculationTypeTier = (e, type) => {
        setUomScale('');
        setCalculationTypeTier(e.target.value);
        if (type == 'Multi Axis' && e.target.value == 'Tier') {
            const index = calculationStoreMX.map(el => el.type).lastIndexOf("Tier");
            if (index > -1) {
                editcalculationRule(calculationStoreMX[index], -1, 'Multi Axis')
                setEditMode(false)
            }
        }
    }
    const [keyValuePairObjectKeyFigure, setKeyValuePairObjectKeyFigure] = React.useState([]);
    // useEffect(() => {
    //     console.log(props.formFieldsAllData)
    //     var tempObj = {};
    //     if (props.dropdownData && props.dropdownData.records && props.formFieldsAllData && props.formFieldsAllData.records) {
    //         props.dropdownData.records.map((item1) => {
    //             if (item1.field_id === 'key_figure_name') {
    //                 item1.drop_down_value_keys && item1.drop_down_value_keys.map((item3) => {
    //                     props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.map((item4) => {
    //                         if (item3 === item4.field_id) {
    //                             tempObj[item4.field_id] = item4.name;
    //                         }
    //                     })
    //                 })
    //             }
    //         })
    //     }
    //     setKeyValuePairObjectKeyFigure(tempObj);
    // }, [props.formFieldsAllData, props.dropdownData]);

    // useEffect(() => {
    //     if (props.data && props.data.eligibility_rules) {
    //         setEffectiveStartDate(props.data.valid_from)
    //         setEffectiveEndDate(props.data.valid_to)
    //         setStartDateCB(props.data.valid_from)
    //         setEndDateCB(props.data.valid_to)
    //         setStartDateMR(props.data.valid_from);
    //         setEndDateMR(props.data.valid_to);
    //         setStartDateScale(props.data.valid_from);
    //         setEndDateScale(props.data.valid_to);
    //     }


    // }, props.data);

    const [contractAPIDetails, setContractAPIDetails] = React.useState({});
    useEffect(() => {
        if (props.data && Object.keys(props.data).length > 0) {
            setContractAPIDetails(props.data)
            if (props.data.calculation_method == 'Price Difference') {
                // setAttributeNameValue([...attributeNameValue,'material_number'])
                setRateTypeCB('$')
            }
            else {
                setRateTypeCB('')
            }
        }

    }, [props.data])
    const [attributeValuesList, setAttributeValuesList] = React.useState([]);
    const [attributeNameList, setAttributeNameList] = React.useState([]);

    const [attributeValuesArray, setAttributeValuesArray] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var customerTempArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
                customerTempArray.push(item.customer_name)
            })
            setAttributeValuesArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    const [employeeMasterArrayMaterialNumber, setEmployeeMasterArrayMaterialNumber] = React.useState([]);
    const [employeeNameListArray, setEmployeeNameListArray] = React.useState([]);
    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.employeeMasterData.records.map((item) => {
                tempArray.push(item.employee_number);
                tempNameArray.push(item.employee_name);
            })
            setEmployeeMasterArrayMaterialNumber(tempArray)
            setEmployeeNameListArray(tempNameArray);
        }
    }, [props.employeeMasterData])
    const filter = createFilterOptions()
    const filterOptionsEmployer = createFilterOptions({
        stringify: (option) => employeeNameListArray[employeeMasterArrayMaterialNumber.indexOf(option)] + option
    });
    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[attributeValuesArray.indexOf(option)] + option
    });
    const filterOptionsMaterial = createFilterOptions({
        stringify: (option) => materialNameListArray[attributeValuesArrayMaterialNumber.indexOf(option)] + option
    });
    const filterOptionsSupplier = createFilterOptions({
        stringify: (option) => supplierNameArray[supplierMasterArrayMaterialNumber.indexOf(option)] + option
    });
    const [supplierMasterArrayMaterialNumber, setSupplierMasterArrayMaterialNumber] = React.useState([]);
    const [materialNameListArray, setMaterialNameListArray] = React.useState([]);
    const [attributeValuesArrayMaterialNumber, setAttributeValuesArrayMaterialNumber] = React.useState([]);

    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
            })
            setAttributeValuesArrayMaterialNumber(tempArray)
            setMaterialNameListArray(tempNameArray);
        }
    }, [props.materialMasterData])
    const [supplierNameArray, seSupplierNameArray] = React.useState([]);
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setSupplierMasterArrayMaterialNumber(tempArray)
            seSupplierNameArray(tempNameArray)
        }
    }, [props.supplierMasterData])

    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});

    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })
    }
    const [generalUOMAll, setGeneralUOMAll] = React.useState([]);

    useEffect(() => {
        if (props.generalUOMAll && props.generalUOMAll.length > 0) {
            setGeneralUOMAll(props.generalUOMAll);
        }
    }, [props.generalUOMAll])
    const handleDrop = (event) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.uploadCalculationRules(data, props.calculationMethod, sessionStorage.getItem('application'), props.sourceDataType);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
    }
    function uploadMX() {
        inputFile.current.click();
    }
    useEffect(() => {
        if (props.uploadedRules && props.uploadedRules.data) {

            var priority = 1
            var x = props.uploadedRules.data;
            x.forEach(function (v) { delete v.ID });
            var calcRules = [...calculationStoreMX, ...props.uploadedRules.data]
            calcRules && calcRules
                .map((itemMain, index) => {
                    var tempKF = 0;
                    var tempA = 0;
                    var tempF = 0;
                    var tempKFArr = [];
                    var tempAArr = [];
                    var tempFArr = [];
                    itemMain.type === 'Tier' && itemMain.attribute_key_values && itemMain.attribute_key_values.map((it, i) => {
                        if (it.attribute_type === 'Key Figure') {
                            tempKF = tempKF + 1;
                            var tempA = {
                                key: it.attribute_name,
                                value: it.attribute_value
                            }
                            tempKFArr.push(tempA);
                        } else if (it.attribute_type === 'Attribute') {
                            tempA = tempA + 1;
                            var tempB = {
                                key: it.attribute_name,
                                value: it.attribute_value
                            }
                            tempAArr.push(tempB);
                        } else {
                            if (it.attribute_type === 'Formula') {
                                tempF = tempF + 1;
                                var tempC = {
                                    key: it.attribute_name,
                                    value: it.attribute_value
                                }
                                tempFArr.push(tempC);
                            }
                        }
                        // if (it.priority) {
                        //     calcRules[index]['attribute_key_values'][i]['priority'] = priority
                        //     priority++
                        // }
                    })
                    calcRules[index]['key_fig_api'] = tempKFArr;
                    calcRules[index]['attri_api'] = tempAArr;
                    calcRules[index]['form_api'] = tempFArr;

                })
            setCalculationStoreMX(calcRules)
            props.onChange(calcRules)
            calcRules.map(itemMain => {
                var tempKL = 0;
                var tempAL = 0;
                var tempFL = 0;
                if (itemMain.key_fig_api && itemMain.key_fig_api.length > tempKL) {
                    setKeyFigureArrayMXSColumns(itemMain.key_fig_api.length);
                }
                if (itemMain.attri_api && itemMain.attri_api.length > tempAL) {
                    setAttributeArrayMXSColumns(itemMain.attri_api.length);
                }
                if (itemMain.form_api && itemMain.form_api.length > tempFL) {
                    setFormulaArrayMXSColumns(itemMain.form_api.length);
                }
            })
        }
    }, [props.uploadedRules])
    return (
        <div>
            <div
                className={clsx(classes.root, className)}>
                {contractAPIDetails && contractAPIDetails.calculation_method && contractAPIDetails.calculation_method === 'Price Difference' ?
                    //chargeback

                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={1} md={12} >
                                    <Grid
                                        container
                                        md={4}
                                        style={{ width: '100%', paddingTop: 5 }}
                                        spacing={1}

                                    >
                                        <Grid
                                            item
                                            md={5}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    Attribute Name
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={attributeNameArray && attributeNameArray['material_number'] ? attributeNameArray['material_number'] : 'Material Number'}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    style={{ fontSize: 14 }}
                                                    disabled
                                                />

                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={7}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    Attribute Value
                                                </FormLabel>
                                                <SearchDropDownMasterDataNew id='material_single'
                                                    table={'material_master'}
                                                    keyName={'material_number'}
                                                    description={true}
                                                    onChange={(value) => setAttributeValueCB(value)}
                                                    prevalue={attributeValueCB}
                                                />

                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={3}
                                        style={{ width: '100%', paddingTop: 5 }}
                                        spacing={1}

                                    >
                                        <Grid
                                            item
                                            md={5}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required={currentApplication === 'Pricing' ? false : true} className={classes.chargeBackLabel}>
                                                    Acquisition Price
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={acquisitionPriceCB}
                                                    type={'number'}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleAcquisitionPriceCB} />
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    Contract Price
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={contractPriceCB}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleContractPriceCB} />
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    Rate Type
                                                </FormLabel>
                                                <Select
                                                    value={rateTypeCB}
                                                    onChange={(e) => setRateTypeCB(e.target.value)}
                                                    displayEmpty
                                                    disabled={props.calculationMethod == 'Price Difference' ? true : false}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                    </MenuItem>
                                                    {uomData.map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key}>
                                                                {item.value}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    {rateTypeCB === '$' &&
                                        <Grid
                                            item
                                            md={1}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                    UoM
                                                </FormLabel>
                                                <Select
                                                    value={uomCB}
                                                    onChange={handleUomCB}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                    </MenuItem>
                                                    {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map(item => {
                                                        return (
                                                            <MenuItem value={item} key={item}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </Grid>
                                    }
                                    <Grid
                                        item
                                        md={4}
                                        style={{ width: '100%' }}
                                        container
                                        spacing={1}
                                    >

                                        <Grid
                                            item
                                            md={2}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    Start Date
                                                </FormLabel>
                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 10 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={startDateCB}
                                                            onChange={date => handleStartDateCB(date)}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={props.startDate ? props.startDate : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={2}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    End Date
                                                </FormLabel>
                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 10 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={endDateCB}
                                                            onChange={date => handleEndDateCB(date)}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={startDateCB ? startDateCB : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                        >
                                            <Grid container style={{ paddingTop: 40 }} >
                                                {editMode ?
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<EditIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={(!contractPriceCB || !attributeValueCB ||
                                                            (currentApplication === 'Pricing' ? null : (!acquisitionPriceCB))
                                                            || // !(startDateCB && (Moment(startDateCB).isValid()))|| !(endDateCB && (Moment(endDateCB).isValid())) 
                                                            !(startDateCB && Moment(startDateCB).isValid() && endDateCB && (Moment(endDateCB).isValid()) && (Moment(endDateCB) >= Moment(startDateCB)) && (Moment(startDateCB) >= Moment(props.startDate)))
                                                            || !rateTypeCB)
                                                            ? true : false}
                                                        onClick={() => handleSubmit(editIndex, 'CB')}
                                                    >
                                                    </Button>
                                                    :
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={(!contractPriceCB || !attributeValueCB ||
                                                            (currentApplication === 'Pricing' ? null : (!acquisitionPriceCB))
                                                            || !(startDateCB && Moment(startDateCB).isValid() && endDateCB && (Moment(endDateCB).isValid()) && (Moment(endDateCB) >= Moment(startDateCB)) && (Moment(startDateCB) >= Moment(props.startDate)))   //!(startDateCB && Moment(startDateCB).isValid())|| !(endDateCB && (Moment(endDateCB).isValid()))
                                                            || !rateTypeCB)
                                                            ? true : false}
                                                        onClick={() => handleSubmit('', 'CB')}
                                                    >
                                                    </Button>
                                                }
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<DeleteForeverIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.deleteIconContainer,
                                                    }}
                                                    onClick={() => handleRemoveCB()}
                                                    style={{ marginLeft: 10 }}
                                                >
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>
                    :
                    contractAPIDetails && contractAPIDetails.calculation_method && contractAPIDetails.calculation_method === 'Fixed Amount' && contractAPIDetails.incentive_rate_type === 'Attribute' ?
                        <Card>
                            <form
                                autoComplete="off"
                                noValidate
                            >
                                <div className={classes.container}>
                                    <Grid container spacing={1} >
                                        <Grid
                                            container
                                            spacing={1}
                                            md={5}
                                        // style={{ marginTop: 0, paddingRight: 2 }}
                                        >
                                            <Grid
                                                item
                                                md={3}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                        Attribute Name
                                                    </FormLabel>
                                                    <Select
                                                        value={attributeNameMR}
                                                        onChange={handleAttributeNameMR}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    >
                                                        <MenuItem value="">
                                                            {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                        </MenuItem>{console.log(attributeNameArray)}
                                                        {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                            Object.entries(attributeNameArray).map(([key, value]) => {
                                                                return (
                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                        {value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={5}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                        Attribute Value
                                                    </FormLabel>
                                                    {(attributeNameMR === 'sold_to_party' || attributeNameMR === 'bill_to_party' || attributeNameMR === 'customer_number')
                                                        ?
                                                        <SearchDropDownMasterDataNew id='customer_single'
                                                            table={'customer_master'}
                                                            keyName={'customer_number'}
                                                            description={true}
                                                            onChange={(value) => setAttributeValueMR(value)}
                                                            prevalue={attributeValueMR}
                                                        />
                                                        :
                                                        (attributeNameMR === 'material_number' || attributeNameMR === 'supplier_number' || attributeNameMR === 'employee_number')
                                                            ?

                                                            <SearchDropDownMasterDataNew id='material_single'
                                                                table={(attributeNameMR === 'supplier_number') ? 'supplier_master' :
                                                                    (attributeNameMR === 'material_number') ? 'material_master' : 'employee_master'}
                                                                keyName={attributeNameMR}
                                                                description={true}
                                                                onChange={(value) => setAttributeValueMR(value)}
                                                                prevalue={attributeValueMR}
                                                            />

                                                            :
                                                            attributeNameMR === 'customer_chain' || attributeNameMR === 'customer_group' || attributeNameMR === 'material_group' || attributeNameMR === 'product_hierarchy' || attributeNameMR === 'company_code' || attributeNameMR === 'sales_org' || attributeNameMR === 'supplier_group' ?
                                                                <AllOtherAttributeMasterComponent attributeName={attributeNameMR} value={attributeValueMR} onChange={(e) => handleAttributeValueMR(e, 'attr')} prevalue={attributeValueMR} withOutLabel={true} id={attributeNameMR} />

                                                                :
                                                                <OutlinedInput
                                                                    value={attributeValueMR}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={handleAttributeValueMR} />
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={4}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                        Key Figure Name
                                                    </FormLabel>
                                                    <Select
                                                        value={keyFigureNameMR}
                                                        onChange={handleKeyFigurenameMR}
                                                        displayEmpty
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >
                                                        <MenuItem value="">
                                                            {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                        </MenuItem>
                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                            Object.entries(mappingValuesSet)
                                                                .sort()
                                                                .map(([key, value]) => {
                                                                    return (
                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                            {value}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                    </Select>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            md={1}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    Rate
                                                </FormLabel>
                                                <Tooltip
                                                    open={tooltipIsOpen}
                                                    title="Rate should be less than or equal to 100"
                                                >
                                                    <OutlinedInput
                                                        value={rateMR}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={handleRateMR}
                                                        type={'number'} />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={1}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                    Rate Type
                                                </FormLabel>
                                                <Select
                                                    value={rateTypeMR}
                                                    onChange={(e) => setRateTypeMR(e.target.value)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                    </MenuItem>
                                                    {uomData.map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key}>
                                                                {item.value}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </Grid>
                                        {rateTypeMR === '$' &&
                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                        UoM
                                                    </FormLabel>
                                                    <Select
                                                        value={uomMR}
                                                        onChange={handleUomMR}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    >
                                                        <MenuItem value="" style={{ height: 30 }}>

                                                        </MenuItem>
                                                        {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item}
                                                                    disabled={item === '%' ? true : false}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </Grid>
                                        }
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            container spacing={1}
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                        Start Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={startDateMR}
                                                                onChange={date => handleStartDateMR(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={props.startDate ? props.startDate : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                        End Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={endDateMR}
                                                                onChange={date => handleEndDateMR(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={startDateMR ? startDateMR : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            md={1}
                                            xs={12}
                                            style={{ width: '100%', display: 'flex', paddingTop: 40 }}
                                        >
                                            <FormLabel></FormLabel>

                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !keyFigureNameMR ||
                                                        !rateMR || !rateTypeMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (Moment(startDateMR) >= Moment(props.startDate)))
                                                        ? true : false}
                                                    onClick={() => handleSubmit(editIndex, 'FA')}
                                                    size={'small'}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !keyfigureEnableHelperMR ||
                                                        !rateMR || !rateTypeMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (Moment(startDateMR) >= Moment(props.startDate)))
                                                        ? true : false}
                                                    onClick={() => handleSubmit('', 'FA')}
                                                    size={'small'}
                                                >
                                                </Button>
                                            }
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={() => handleRemoveMR()}
                                                style={{ marginLeft: 5 }}
                                                size={'small'}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        </Card>
                        :
                        contractAPIDetails && contractAPIDetails.calculation_method && contractAPIDetails.calculation_method === 'Flat Rate' && contractAPIDetails.incentive_rate_type === 'Attribute'
                            ?
                            <Card>
                                <form
                                    autoComplete="off"
                                    noValidate
                                >
                                    <div className={classes.container}>
                                        <Grid container spacing={1} >
                                            <Grid
                                                container
                                                spacing={1}
                                                md={7}
                                            //style={{ marginTop: 0, paddingRight: 2 }}
                                            >
                                                <Grid
                                                    item
                                                    md={3}>
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Attribute Name
                                                        </FormLabel>
                                                        <Select
                                                            value={attributeNameMR}
                                                            onChange={handleAttributeNameMR}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        >
                                                            <MenuItem value="">
                                                                {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                            </MenuItem>
                                                            {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                Object.entries(attributeNameArray).map(([key, value]) => {
                                                                    return (
                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                            {value}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}>
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Attribute Value
                                                        </FormLabel>
                                                        {(attributeNameMR === 'customer_number' || attributeNameMR === 'sold_to_party' || attributeNameMR === 'bill_to_party') ?

                                                            <SearchDropDownMasterDataNew id='customer_single'
                                                                table={'customer_master'}
                                                                keyName={'customer_number'}
                                                                description={true}
                                                                onChange={(value) => setAttributeValueMR(value)}
                                                                prevalue={attributeValueMR}
                                                            />
                                                            :

                                                            (attributeNameMR === 'material_number' || attributeNameMR === 'supplier_number' || attributeNameMR === 'employee_number')
                                                                ?

                                                                <SearchDropDownMasterDataNew id='material_single'
                                                                    table={(attributeNameMR === 'supplier_number') ? 'supplier_master' :
                                                                        (attributeNameMR === 'material_number') ? 'material_master' : 'employee_master'}
                                                                    keyName={attributeNameMR}
                                                                    description={true}
                                                                    onChange={(value) => setAttributeValueMR(value)}
                                                                    prevalue={attributeValueMR}
                                                                />

                                                                : attributeNameMR === 'customer_chain' || attributeNameMR === 'customer_group' || attributeNameMR === 'material_group' || attributeNameMR === 'product_hierarchy' || attributeNameMR === 'company_code' || attributeNameMR === 'sales_org' || attributeNameMR === 'supplier_group' ?
                                                                    <AllOtherAttributeMasterComponent attributeName={attributeNameMR} value={attributeValueMR} onChange={(e) => handleAttributeValueMR(e, 'attr')} prevalue={attributeValueMR} withOutLabel={true} id={attributeNameMR} />

                                                                    :
                                                                    <OutlinedInput
                                                                        value={attributeValueMR}
                                                                        classes={{ root: classes.inputTwoLine }}
                                                                        onChange={handleAttributeValueMR} />
                                                        }
                                                    </div>
                                                </Grid>


                                                < Grid
                                                    item
                                                    md={2}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.chargeBackLabel} required>
                                                            Formula Type
                                                        </FormLabel>
                                                        <Select
                                                            value={formulaTypeTier}
                                                            onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {formulaTypeDataTier
                                                                .map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                            {item.value}
                                                                        </MenuItem>
                                                                    )
                                                                })}


                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                            {formulaTypeTier === 'Formula' ? 'Formula' : 'Key Figure Name'}
                                                        </FormLabel>
                                                        {formulaTypeTier === 'Formula' ?
                                                            <FormulaLabAutoComplete
                                                                data={(props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0)
                                                                    ? props.formulaListData.records.map(item => ({ desc: item.formula_name, key: item.ID }))
                                                                    : []}
                                                                id={'formula_name_1'}
                                                                onChange={(value) => setFormulaName(value)}
                                                                prevalue={formulaName}
                                                            />
                                                            // <Select
                                                            //     value={formulaName}
                                                            //     onChange={(e) => setFormulaName(e.target.value)}
                                                            //     displayEmpty
                                                            //     className={clsx({
                                                            //         [classes.select]: true
                                                            //     })}
                                                            //     classes={{
                                                            //         selectMenu: classes.selectedItem
                                                            //     }}
                                                            //     style={{ textTransform: 'capitalize' }}
                                                            //     MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            // >
                                                            //     <MenuItem value="" style={{ height: 30 }}>
                                                            //     </MenuItem>
                                                            //     {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                            //         return (
                                                            //             <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                            //                 {item.formula_name}
                                                            //             </MenuItem>
                                                            //         )
                                                            //     })}
                                                            // </Select>
                                                            :
                                                            <Select
                                                                value={keyFigureNameMR}
                                                                onChange={handleKeyFigurenameMR}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            >
                                                                <MenuItem value="">
                                                                    {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                </MenuItem>
                                                                {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                    Object.entries(mappingValuesSet)
                                                                        .sort()
                                                                        .map(([key, value]) => {
                                                                            return (
                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                    {value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                            </Select>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Rate
                                                        </FormLabel>
                                                        <Tooltip
                                                            open={tooltipIsOpen}
                                                            title="Rate should be less than or equal to 100"
                                                        >
                                                            <OutlinedInput
                                                                value={rateMR}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={handleRateMR}
                                                                type={'number'} />
                                                        </Tooltip>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%', marginTop: -5 }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                        Rate Type
                                                    </FormLabel>
                                                    <Select
                                                        value={rateTypeMR}
                                                        onChange={(e) => setRateTypeMR(e.target.value)}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >
                                                        <MenuItem value="" style={{ height: 30 }}>
                                                        </MenuItem>
                                                        {uomData.map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key}>
                                                                    {item.value}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </Grid>
                                            {rateTypeMR === '$' &&
                                                <Grid
                                                    item
                                                    md={1}
                                                    style={{ width: '100%', marginTop: -5 }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                            UoM
                                                        </FormLabel>
                                                        <Select
                                                            value={uomMR}
                                                            onChange={handleUomMR}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>

                                                            </MenuItem>
                                                            {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}
                                                                        disabled={keyFigureNameMR != 'quantity' && item === '$' ? true : false}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid
                                                container
                                                spacing={1}
                                                md={3}
                                            // style={{ marginTop: 0, paddingRight: 2 }}
                                            >
                                                <Grid
                                                    item
                                                    md={6}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Start Date
                                                        </FormLabel>
                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={startDateMR}
                                                                    onChange={date => handleStartDateMR(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={props.startDate ? props.startDate : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            End Date
                                                        </FormLabel>
                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={endDateMR}
                                                                    onChange={date => handleEndDateMR(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={startDateMR ? startDateMR : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                xs={12}
                                                style={{ width: '100%', display: 'flex', paddingTop: 40 }}
                                            >

                                                {editMode ?
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<EditIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !formulaTypeTier || (formulaTypeTier == 'Formula' ? !formulaName : !keyFigureNameMR) ||
                                                            !rateMR || !rateTypeMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (Moment(startDateMR) >= Moment(props.startDate)))
                                                            ? true : false}
                                                        onClick={() => handleSubmit(editIndex, 'MR')}
                                                        size={'small'}
                                                    >
                                                    </Button>
                                                    :
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !formulaTypeTier || (formulaTypeTier == 'Formula' ? !formulaName : !keyfigureEnableHelperMR) ||
                                                            !rateMR || !rateTypeMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (Moment(startDateMR) >= Moment(props.startDate)))
                                                            ? true : false}
                                                        onClick={() => handleSubmit('', 'MR')}
                                                        size={'small'}
                                                    >
                                                    </Button>
                                                }
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<DeleteForeverIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.deleteIconContainer,
                                                    }}
                                                    onClick={() => handleRemoveMR()}
                                                    style={{ marginLeft: 5 }}
                                                    size={'small'}
                                                >
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </form>
                            </Card>
                            :
                            contractAPIDetails && contractAPIDetails.calculation_schema && contractAPIDetails.calculation_schema === 'BOM Explosion' ?
                                <Card>
                                    <form
                                        autoComplete="off"
                                        noValidate
                                    >
                                        <div className={classes.container}>
                                            <Grid container spacing={1} >
                                                <Grid
                                                    item
                                                    md={2}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.formDiv}>
                                                            Attribute Name
                                                        </FormLabel>
                                                        <div style={{ marginTop: 20 }}>
                                                            {attributeNameArray && attributeNameArray['material_number'] ? attributeNameArray['material_number'] : 'Material Number'}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Attribute Value
                                                        </FormLabel>
                                                        <SearchDropDownMasterDataNew id='material_single'
                                                            table={'material_master'}
                                                            keyName={'material_number'}
                                                            description={true}
                                                            onChange={(value) => handleAttributeValueMR(value, 'attr-mat')}
                                                            prevalue={attributeValueMR}
                                                        />
                                                        {/* <OutlinedInput
                                                            value={attributeValueMR}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={handleAttributeValueMR} /> */}
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            {keyFigureObject.name}
                                                        </FormLabel>
                                                        <Select
                                                            value={keyFigureNameMR}
                                                            onChange={handleKeyFigurenameMR}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        >
                                                            <MenuItem value="">
                                                                {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                            </MenuItem>
                                                            {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                Object.entries(mappingValuesSet)
                                                                    .sort()
                                                                    .map(([key, value]) => {
                                                                        return (
                                                                            <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                {value}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Rate
                                                        </FormLabel>
                                                        <Tooltip
                                                            open={tooltipIsOpen}
                                                            title="Rate should be less than or equal to 100"
                                                        >
                                                            <OutlinedInput
                                                                value={rateMR}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={handleRateMR}
                                                                type={'number'} />
                                                        </Tooltip>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Rate Type
                                                        </FormLabel>
                                                        <Select
                                                            value={rateTypeMR}
                                                            onChange={(e) => setRateTypeMR(e.target.value)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {uomData.map((item) => {
                                                                return (
                                                                    <MenuItem value={item.key} key={item.key}>
                                                                        {item.value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                {rateTypeMR === '$' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                UoM
                                                            </FormLabel>
                                                            <Select
                                                                value={uomMR}
                                                                onChange={handleUomMR}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            >
                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                </MenuItem>
                                                                {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item} key={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Grid>
                                                }
                                                <Grid
                                                    item
                                                    md={1.5}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Start Date
                                                        </FormLabel>
                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={startDateMR}
                                                                    onChange={date => handleStartDateMR(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={props.startDate ? props.startDate : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1.5}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            End Date
                                                        </FormLabel>
                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={endDateMR}
                                                                    onChange={date => handleEndDateMR(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={startDateMR ? startDateMR : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>

                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1}
                                                    style={{ width: '100%', display: 'flex', paddingTop: 40 }}
                                                >
                                                    {editMode ?
                                                        <Button
                                                            color='secondary'
                                                            variant="contained"
                                                            startIcon={<EditIcon />}
                                                            size={'small'}
                                                            classes={{
                                                                startIcon: classes.startIcon,
                                                                root: classes.checkIconContainer
                                                            }}
                                                            disabled={!(attributeValueMR && attributeValueMR.length > 0) ||
                                                                !rateMR || !rateTypeMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (Moment(startDateMR) >= Moment(props.startDate)))
                                                                || !keyfigureValueMR
                                                                ? true : false}
                                                            onClick={() => handleSubmit(editIndex, 'BOM Explosion')}
                                                        >
                                                        </Button>
                                                        :
                                                        <Button
                                                            color='secondary'
                                                            variant="contained"
                                                            size={'small'}
                                                            startIcon={<CheckIcon />}
                                                            classes={{
                                                                startIcon: classes.startIcon,
                                                                root: classes.checkIconContainer
                                                            }}
                                                            disabled={!(attributeValueMR && attributeValueMR.length > 0) || !keyfigureValueMR ||
                                                                !rateMR || !rateTypeMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (Moment(startDateMR) >= Moment(props.startDate)))
                                                                ? true : false}
                                                            onClick={() => handleSubmit('', 'BOM Explosion')}
                                                        >
                                                        </Button>
                                                    }
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<DeleteForeverIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.deleteIconContainer,
                                                        }}
                                                        onClick={() => handleRemoveMR()}
                                                        style={{ marginLeft: 5 }}
                                                    >
                                                    </Button>
                                                    {/* </Grid> */}
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </form>
                                </Card>
                                :
                                (contractAPIDetails && contractAPIDetails.calculation_method && (contractAPIDetails.calculation_method === 'Tiered Volume' || contractAPIDetails.calculation_method === 'Tiered Growth'))
                                    ?
                                    <Card>
                                        <div className={classes.container}>
                                            <Grid container spacing={1} >
                                                <Grid
                                                    item
                                                    md={1.5}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                                            Calculation Type
                                                        </FormLabel>
                                                        <Select
                                                            value={calculationTypeTier}
                                                            onChange={(e) => setCalculationTypeTier(e.target.value)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {calculationTypeData
                                                                .map(item => {
                                                                    return (
                                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1.5}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                                            Formula Type
                                                        </FormLabel>
                                                        <Select
                                                            value={formulaTypeTier}
                                                            onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {calculationTypeTier === 'Tier' || calculationTypeTier === 'Both' || calculationTypeTier === 'Rebate' ?
                                                                formulaTypeDataTier
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                {item.value}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                :
                                                                formulaTypeDataRebate
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                {item.value}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                            }
                                                        </Select>
                                                    </div>
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={1.5}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            {formulaTypeTier === 'Formula' ? 'Formula' : keyFigureObject.name}
                                                        </FormLabel>
                                                        {formulaTypeTier === 'Formula' ?
                                                            <FormulaLabAutoComplete
                                                                data={(props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0)
                                                                    ? props.formulaListData.records.map(item => ({ desc: item.formula_name, key: item.ID }))
                                                                    : []}
                                                                id={'formula_name_2'}
                                                                onChange={(value) => setFormulaName(value)}
                                                                prevalue={formulaName}
                                                            />
                                                            // <Select
                                                            //     value={formulaName}
                                                            //     onChange={(e) => setFormulaName(e.target.value)}
                                                            //     displayEmpty
                                                            //     className={clsx({
                                                            //         [classes.select]: true
                                                            //     })}
                                                            //     classes={{
                                                            //         selectMenu: classes.selectedItem
                                                            //     }}
                                                            //     MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            // >
                                                            //     <MenuItem value="" style={{ height: 30 }}>
                                                            //     </MenuItem>
                                                            //     {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                            //         return (
                                                            //             <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                            //                 {item.formula_name}
                                                            //             </MenuItem>
                                                            //         )
                                                            //     })}
                                                            // </Select>
                                                            :
                                                            <Select
                                                                value={keyFigureNameScale}
                                                                onChange={handleKeyFigurenameScale}
                                                                displayEmpty
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                            >
                                                                <MenuItem value="">
                                                                    {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                </MenuItem>
                                                                {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                    Object.entries(mappingValuesSet)
                                                                        .sort()
                                                                        .map(([key, value]) => {
                                                                            return (
                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                    {value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                            </Select>
                                                        }
                                                    </div>
                                                </Grid>
                                                {operationArray.length > 0 &&
                                                    operationArray.map((x, i) => {
                                                        return (
                                                            <Grid
                                                                container
                                                                md={3}
                                                                xs={12}
                                                                spacing={1}
                                                                style={{ margin: 0 }}
                                                            >

                                                                <Grid
                                                                    item
                                                                    md={4}
                                                                >
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                            Operation
                                                                        </FormLabel>
                                                                        <Select
                                                                            name="operation"
                                                                            value={x.operation}
                                                                            onChange={e => handleOperationChange(e, i)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {operationData.map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item.value} key={item.value}>
                                                                                        {item.value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={8}
                                                                >
                                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                        {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                                    </FormLabel>
                                                                    <Select
                                                                        name="key_figure_names"
                                                                        value={x.key_figure_names}
                                                                        onChange={e => handleOperationChange(e, i)}
                                                                        displayEmpty
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        <MenuItem value="">
                                                                            {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                        </MenuItem>
                                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                            Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                return (
                                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                        {value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                    </Select>
                                                                </Grid>
                                                                <Button color="primary" size="small"
                                                                    classes={{
                                                                        root: classes.removeButton
                                                                    }}
                                                                    startIcon={<DeleteForeverIcon />}
                                                                    onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                                {calculationTypeTier != 'Rebate' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                From
                                                            </FormLabel>
                                                            <OutlinedInput
                                                                value={editAttributeValue}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => setEditAttributeValue(e.target.value)} />
                                                        </div>
                                                    </Grid>
                                                }
                                                {calculationTypeTier != 'Rebate' &&
                                                    <Grid
                                                        item
                                                        md={0.5}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                Rate
                                                            </FormLabel>
                                                            <Tooltip
                                                                open={tooltipIsOpen}
                                                                title="Rate should be less than or equal to 100"
                                                            >
                                                                <OutlinedInput
                                                                    value={rateScale}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={handleRateScale} />
                                                            </Tooltip>
                                                        </div>
                                                    </Grid>
                                                }
                                                {props.maximumAmountPeriod == 'Payment Period - Tier' && calculationTypeTier && calculationTypeTier != 'Rebate' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel required className={classes.chargeBackLabel} classes={{ root: classes.fontSetting }} >
                                                                Maximum Amount
                                                            </FormLabel>
                                                            <OutlinedInput
                                                                value={maximumAmount}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => setMaximumAmount(e.target.value)} />
                                                        </div>
                                                    </Grid>
                                                }
                                                {calculationTypeTier != 'Rebate' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                Rate Type
                                                            </FormLabel>
                                                            <Select
                                                                value={rateTypeScale}
                                                                onChange={(e) => setRateTypeScale(e.target.value)}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                            >
                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                </MenuItem>
                                                                {uomData.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.key} key={item.key}>
                                                                            {item.value}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Grid>
                                                }
                                                {calculationTypeTier != 'Rebate' && rateTypeScale === '$' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                UoM
                                                            </FormLabel>
                                                            <Select
                                                                value={uomScale}
                                                                onChange={handleUomScale}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            >
                                                                <MenuItem value="" style={{ height: 30 }}>

                                                                </MenuItem>
                                                                {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item} key={item}
                                                                            disabled={(item === '$' && keyFigureNameScale != 'quantity') ? true : false}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Grid>
                                                }
                                                <Grid
                                                    item
                                                    md={1.5}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            Start Date
                                                        </FormLabel>

                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={startDateScale}
                                                                    onChange={date => handleStartDateScale(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={props.startDate ? props.startDate : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1.5}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                            End Date
                                                        </FormLabel>
                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={endDateScale}
                                                                    onChange={date => handleEndDateScale(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={startDateScale ? startDateScale : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>

                                                </Grid>
                                                {/* <Grid
                                                item
                                                md={1}
                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
                                            >
                                                <IconButton onClick={() => addTiming()} classes={{ root: classes.IconButton }}>
                                                    <MonetizationOnIcon color="disabled" style={{ fontSize: 24, paddingBottom: 10 }} />
                                                </IconButton>
                                            </Grid> */}
                                                <Grid
                                                    item
                                                    md={2}
                                                    style={{ width: '100%' }}
                                                >
                                                    <FormLabel className={classes.chargeBackLabel}>
                                                        {' '}
                                                    </FormLabel>

                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            {editMode ?
                                                                <Button
                                                                    color='secondary'
                                                                    variant="contained"
                                                                    startIcon={<EditIcon />}
                                                                    size={'small'}
                                                                    classes={{
                                                                        startIcon: classes.startIcon,
                                                                        root: classes.checkIconContainer
                                                                    }}
                                                                    disabled={
                                                                        calculationTypeTier != 'Rebate' ?
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !rateTypeScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))
                                                                                    || !editAttributeValue || !rateScale || (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false)) ? true : false) :
                                                                                ((!calculationTypeTier || !formulaTypeTier /*|| !keyFigureNameScale*/ || !rateTypeScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))
                                                                                    || !editAttributeValue || !rateScale || (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false)) ? true : false)

                                                                            :
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false) :
                                                                                ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false)
                                                                    }
                                                                    onClick={() => handleSubmit(editIndex, 'Tiered Volume - Simple')}
                                                                >
                                                                </Button>
                                                                :
                                                                <Button
                                                                    color='secondary'
                                                                    variant="contained"
                                                                    size={'small'}
                                                                    startIcon={<CheckIcon />}
                                                                    classes={{
                                                                        startIcon: classes.startIcon,
                                                                        root: classes.checkIconContainer
                                                                    }}
                                                                    disabled={
                                                                        calculationTypeTier != 'Rebate' ?
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !rateTypeScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate))) || !editAttributeValue
                                                                                    || !rateScale || (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false)) ? true : false) :
                                                                                ((!calculationTypeTier || !formulaTypeTier /*|| !keyFigureNameScale*/ || !rateTypeScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))
                                                                                    || !editAttributeValue || !rateScale || (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false)) ? true : false)

                                                                            :
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false) :
                                                                                ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false)
                                                                    }
                                                                    onClick={() => handleSubmit('', 'Tiered Volume - Simple')}
                                                                >
                                                                </Button>
                                                            }
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                startIcon={<DeleteForeverIcon />}
                                                                classes={{
                                                                    startIcon: classes.startIcon,
                                                                    root: classes.deleteIconContainer,
                                                                }}
                                                                onClick={() => handleRemoveScale()}
                                                                style={{ marginLeft: 5 }}
                                                            >
                                                            </Button>
                                                        </div>

                                                        {formulaTypeTier != 'Formula' && calculationTypeTier != 'Tier' &&
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Tooltip title='Add Key Figure'>
                                                                    <Button color="primary" size="small"
                                                                        startIcon={<AddIcon />}
                                                                        onClick={handleAddOperation}> Key Figure</Button>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Card>

                                    :

                                    (contractAPIDetails && (contractAPIDetails.calculation_method === 'Quota Achievement' || contractAPIDetails.calculation_method === 'PPM')) ?
                                        <Card>
                                            <form
                                                autoComplete="off"
                                                noValidate
                                            >
                                                <div className={classes.container}>
                                                    <Grid container spacing={1} >
                                                        <Grid
                                                            item
                                                            md={2}
                                                            xs={12}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                    {keyFigureObject.name ? keyFigureObject.name : 'Key Figure Name'}
                                                                </FormLabel>

                                                                <Select
                                                                    value={keyfigurename}
                                                                    onChange={handleKeyfigurename}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >

                                                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                        Object.entries(mappingValuesSet)
                                                                            .sort()
                                                                            .map(([key, value]) => {
                                                                                return (
                                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                        {value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                </Select>
                                                            </div>
                                                        </Grid>
                                                        {operationArray.length > 0 &&
                                                            operationArray.map((x, i) => {
                                                                return (
                                                                    <Grid
                                                                        container
                                                                        md={3}
                                                                        xs={12}
                                                                        spacing={1}
                                                                        style={{ margin: 0 }}
                                                                    >

                                                                        <Grid
                                                                            item
                                                                            md={4}
                                                                        >
                                                                            <div className={classes.selectRoot}>
                                                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                    Operation
                                                                                </FormLabel>
                                                                                <Select
                                                                                    name="operation"
                                                                                    value={x.operation}
                                                                                    onChange={e => handleOperationChange(e, i)}
                                                                                    displayEmpty
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                >
                                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                                    </MenuItem>
                                                                                    {operationData.map((item) => {
                                                                                        return (
                                                                                            <MenuItem value={item.value} key={item.value}>
                                                                                                {item.value}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}
                                                                                </Select>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            md={8}
                                                                        >
                                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                                            </FormLabel>
                                                                            <Select
                                                                                name="key_figure_names"
                                                                                value={x.key_figure_names}
                                                                                onChange={e => handleOperationChange(e, i)}
                                                                                displayEmpty
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                    {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                </MenuItem>
                                                                                {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                    Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                        return (
                                                                                            <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                {value}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}
                                                                            </Select>
                                                                        </Grid>
                                                                        <Button color="primary" size="small"
                                                                            classes={{
                                                                                root: classes.removeButton
                                                                            }}
                                                                            startIcon={<DeleteForeverIcon />}
                                                                            onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                                    </Grid>
                                                                );
                                                            })

                                                        }

                                                        <Grid
                                                            item
                                                            md={1}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                    Rate Type
                                                                </FormLabel>
                                                                <Select
                                                                    value={rateType}
                                                                    onChange={(e) => setRateType(e.target.value)}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                    </MenuItem>
                                                                    {uomData.map((item) => {
                                                                        return (
                                                                            <MenuItem value={item.key} key={item.key}>
                                                                                {item.value}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </div>
                                                        </Grid>
                                                        {rateType === '$' &&
                                                            <Grid
                                                                item
                                                                md={1}
                                                                xs={12}>
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                        UoM
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={uom}
                                                                        onChange={handleUom}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                        </MenuItem>
                                                                        {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                            return (
                                                                                <MenuItem value={item} key={item}
                                                                                    disabled={(item.value === '%' && props.calculationMethod === 'Fixed Amount') || (item.value === '$' && props.calculationMethod === 'Flat Rate' && keyfigurename != 'quantity') ? true : false}
                                                                                >
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>
                                                        }
                                                        <Grid
                                                            item
                                                            md={1}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                    From
                                                                </FormLabel>
                                                                <OutlinedInput
                                                                    value={editAttributeValue}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) => setEditAttributeValue(e.target.value)} />
                                                            </div>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            md={1}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                    Rate
                                                                </FormLabel>
                                                                <Tooltip
                                                                    open={tooltipIsOpen}
                                                                    title="Rate should be less than or equal to 100"
                                                                >
                                                                    <OutlinedInput
                                                                        value={rate}
                                                                        classes={{ root: classes.inputTwoLine }}
                                                                        onChange={(e) => setRate(e.target.value)} />
                                                                </Tooltip>
                                                            </div>
                                                        </Grid>


                                                        <Grid
                                                            item
                                                            md={1.5}
                                                            xs={12}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                    Start Date
                                                                </FormLabel>
                                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 10 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={effectiveStartDate}
                                                                            onChange={date => handleEffectiveStartDate(date)}
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                            minDate={props.startDate ? props.startDate : ''}
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={1.5}
                                                            xs={12}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                    End Date
                                                                </FormLabel>
                                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 10 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={effectiveEndDate}
                                                                            onChange={date => handleEffectiveEndDate(date)}
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                            minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <FormLabel className={classes.chargeBackLabel}>
                                                                {' '}
                                                            </FormLabel>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    {editMode ?
                                                                        <Button
                                                                            color='secondary'
                                                                            variant="contained"
                                                                            startIcon={<EditIcon />}
                                                                            size={'small'}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: classes.checkIconContainer
                                                                            }}
                                                                            disabled={!keyfigurename || !rateType || !rate || !editAttributeValue
                                                                                || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate))
                                                                                    && (Moment(effectiveStartDate) >= Moment(props.startDate))) ? true : false}
                                                                            onClick={() => handleSubmit(editIndex, 'Quota')}
                                                                        >
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            color='secondary'
                                                                            variant="contained"
                                                                            size={'small'}
                                                                            startIcon={<CheckIcon />}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: classes.checkIconContainer
                                                                            }}
                                                                            disabled={!keyfigurename || !rateType || !rate || !editAttributeValue || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid())
                                                                                && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (Moment(effectiveStartDate) >= Moment(props.startDate))) ? true : false}
                                                                            onClick={() => handleSubmit('', 'Quota')}
                                                                        >
                                                                        </Button>
                                                                    }
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={<DeleteForeverIcon />}
                                                                        classes={{
                                                                            startIcon: classes.startIcon,
                                                                            root: classes.deleteIconContainer,
                                                                        }}
                                                                        onClick={() => handleRemoveQuota()}
                                                                        style={{ marginLeft: 5 }}
                                                                    >
                                                                    </Button>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <Tooltip title='Add Key Figure'>
                                                                        <Button color="primary" size="small"
                                                                            startIcon={<AddIcon />}
                                                                            onClick={handleAddOperation}>Key Figure</Button>
                                                                    </Tooltip>

                                                                </div>
                                                            </div>

                                                        </Grid>

                                                    </Grid>
                                                </div>
                                            </form>
                                        </Card>
                                        :
                                        contractAPIDetails.calculation_method === 'Multi Axis' ?
                                            <div>
                                                <Card>
                                                    <div className={classes.container}>
                                                        <Grid container spacing={1} >
                                                            <Grid
                                                                item
                                                                md={2}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                                                        Calculation Type
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={calculationTypeTier}
                                                                        onChange={(e) => handleCalculationTypeTier(e, 'Multi Axis')}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        {/* <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem> */}
                                                                        {calculationTypeDataMXS
                                                                            .map(item => {
                                                                                return (
                                                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>

                                                            {calculationTypeTier === 'Rebate' ?
                                                                <>

                                                                    <Grid
                                                                        item
                                                                        md={2}
                                                                        style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                                    >
                                                                        <div className={classes.selectRoot}>
                                                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                                                                Formula Type
                                                                            </FormLabel>
                                                                            <Select
                                                                                value={formulaTypeTier}
                                                                                onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                                                displayEmpty
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                </MenuItem>
                                                                                {formulaTypeDataTier
                                                                                    .map((item, index) => {
                                                                                        return (
                                                                                            <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                {item.value}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}


                                                                            </Select>
                                                                        </div>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        md={2}
                                                                        style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                                    >
                                                                        <div className={classes.selectRoot}>
                                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                {formulaTypeTier === 'Formula' ? 'Formula' : keyFigureObject.name}
                                                                            </FormLabel>
                                                                            {formulaTypeTier === 'Formula' ?
                                                                                // <Select
                                                                                //     value={formulaName}
                                                                                //     onChange={(e) => setFormulaName(e.target.value)}
                                                                                //     displayEmpty
                                                                                //     className={clsx({
                                                                                //         [classes.select]: true
                                                                                //     })}
                                                                                //     classes={{
                                                                                //         selectMenu: classes.selectedItem
                                                                                //     }}
                                                                                //     style={{ textTransform: 'capitalize' }}
                                                                                //     MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                // >
                                                                                //     <MenuItem value="" style={{ height: 30 }}>
                                                                                //     </MenuItem>
                                                                                //     {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                                //         return (
                                                                                //             <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                //                 {item.formula_name}
                                                                                //             </MenuItem>
                                                                                //         )
                                                                                //     })}
                                                                                // </Select>
                                                                                <FormulaLabAutoComplete
                                                                                    data={(props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0)
                                                                                        ? props.formulaListData.records.map(item => ({ desc: item.formula_name, key: item.ID }))
                                                                                        : []}
                                                                                    id={'formula_name_3'}
                                                                                    onChange={(value) => setFormulaName(value)}
                                                                                    prevalue={formulaName}
                                                                                />
                                                                                :
                                                                                <Select
                                                                                    value={keyFigureNameScale}
                                                                                    onChange={handleKeyFigurenameScale}
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                    style={{ textTransform: 'capitalize' }}
                                                                                >
                                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                                        {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                    </MenuItem>
                                                                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                        Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                            return (
                                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                    {value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                </Select>
                                                                            }
                                                                        </div>
                                                                    </Grid>

                                                                    {operationArray.length > 0 &&
                                                                        operationArray.map((x, i) => {
                                                                            return (
                                                                                <Grid
                                                                                    container
                                                                                    md={3}
                                                                                    xs={12}
                                                                                    spacing={1}
                                                                                    style={{ margin: 0 }}
                                                                                >

                                                                                    <Grid
                                                                                        item
                                                                                        md={4}
                                                                                    >
                                                                                        <div className={classes.selectRoot}>
                                                                                            <FormLabel required className={classes.chargeBackLabel}>
                                                                                                Operation
                                                                                            </FormLabel>
                                                                                            <Select
                                                                                                name="operation"
                                                                                                value={x.operation}
                                                                                                onChange={e => handleOperationChange(e, i)}
                                                                                                displayEmpty
                                                                                                className={clsx({
                                                                                                    [classes.select]: true
                                                                                                })}
                                                                                                classes={{
                                                                                                    selectMenu: classes.selectedItem
                                                                                                }}
                                                                                            >
                                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                                    {/* Select operation */}
                                                                                                </MenuItem>
                                                                                                {operationData.map((item) => {
                                                                                                    return (
                                                                                                        <MenuItem value={item.value} key={item.value}>
                                                                                                            {item.value}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })}
                                                                                            </Select>
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        md={8}
                                                                                    >
                                                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                                                            {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                                                        </FormLabel>
                                                                                        <Select
                                                                                            name="key_figure_names"
                                                                                            value={x.key_figure_names}
                                                                                            onChange={e => handleOperationChange(e, i)}
                                                                                            displayEmpty
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                            className={clsx({
                                                                                                [classes.select]: true
                                                                                            })}
                                                                                            classes={{
                                                                                                selectMenu: classes.selectedItem
                                                                                            }}
                                                                                        >
                                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                                                {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                            </MenuItem>
                                                                                            {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                                Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                                    return (
                                                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                            {value}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Grid>
                                                                                    <Button color="primary" size="small"
                                                                                        classes={{
                                                                                            root: classes.removeButton
                                                                                        }}
                                                                                        startIcon={<DeleteForeverIcon />}
                                                                                        onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                                                </Grid>
                                                                            );
                                                                        })

                                                                    }
                                                                    <Grid
                                                                        item
                                                                        md={1.5}
                                                                        style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                                    >
                                                                        <div className={classes.selectRoot}>
                                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                Start Date
                                                                            </FormLabel>

                                                                            <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                                <Grid container justifyContent="space-around">
                                                                                    <DatePicker
                                                                                        disableToolbar
                                                                                        clearable
                                                                                        InputProps={{
                                                                                            padding: 0,
                                                                                            disableUnderline: true,
                                                                                            style: {
                                                                                                padding: '1px 10 1px 11px',
                                                                                                alignSelf: 'center',
                                                                                                alignItems: 'center',
                                                                                                border: '1px solid #E0E0E0',
                                                                                                width: '100%'
                                                                                            }
                                                                                        }}
                                                                                        value={startDateScale}
                                                                                        onChange={date => handleStartDateScale(date)}
                                                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                        format="MM/DD/YYYY"
                                                                                        minDate={props.startDate ? props.startDate : ''}
                                                                                    />
                                                                                </Grid>
                                                                            </LocalizationProvider>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        md={1.5}
                                                                        style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                                    >
                                                                        <div className={classes.selectRoot}>
                                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                End Date
                                                                            </FormLabel>
                                                                            <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                                <Grid container justifyContent="space-around">
                                                                                    <DatePicker
                                                                                        disableToolbar
                                                                                        clearable
                                                                                        InputProps={{
                                                                                            padding: 0,
                                                                                            disableUnderline: true,
                                                                                            style: {
                                                                                                padding: '1px 10 1px 11px',
                                                                                                alignSelf: 'center',
                                                                                                alignItems: 'center',
                                                                                                border: '1px solid #E0E0E0',
                                                                                                width: '100%'
                                                                                            }
                                                                                        }}
                                                                                        value={endDateScale}
                                                                                        onChange={date => handleEndDateScale(date)}
                                                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                        format="MM/DD/YYYY"
                                                                                        minDate={startDateScale ? startDateScale : ''}
                                                                                    />
                                                                                </Grid>
                                                                            </LocalizationProvider>
                                                                        </div>

                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        md={2}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <FormLabel className={classes.chargeBackLabel}>
                                                                            {' '}
                                                                        </FormLabel>
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                {editMode ?
                                                                                    <Button
                                                                                        color='secondary'
                                                                                        variant="contained"
                                                                                        startIcon={<EditIcon />}
                                                                                        size={'small'}
                                                                                        classes={{
                                                                                            startIcon: classes.startIcon,
                                                                                            root: classes.checkIconContainer
                                                                                        }}
                                                                                        onClick={() => handleSubmit(editIndex, 'Multi Axis')}
                                                                                        disabled={
                                                                                            calculationTypeTier == 'Rebate' ?
                                                                                                formulaTypeTier === 'Formula' ?
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false) :
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false)

                                                                                                : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate))) || !rateScale) ? true : false)
                                                                                        }
                                                                                    >
                                                                                    </Button>
                                                                                    :
                                                                                    <Button
                                                                                        color='secondary'
                                                                                        variant="contained"
                                                                                        size={'small'}
                                                                                        startIcon={<CheckIcon />}
                                                                                        classes={{
                                                                                            startIcon: classes.startIcon,
                                                                                            root: classes.checkIconContainer
                                                                                        }}
                                                                                        disabled={
                                                                                            calculationTypeTier == 'Rebate' ?
                                                                                                formulaTypeTier === 'Formula' ?
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false) :
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false)

                                                                                                : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate))) || !rateScale) ? true : false)
                                                                                        }
                                                                                        onClick={() => handleSubmit('', 'Multi Axis')}
                                                                                    >
                                                                                    </Button>
                                                                                }
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    startIcon={<DeleteForeverIcon />}
                                                                                    classes={{
                                                                                        startIcon: classes.startIcon,
                                                                                        root: classes.deleteIconContainer,
                                                                                    }}
                                                                                    onClick={() => handleRemoveMXS()}
                                                                                    style={{ marginLeft: 5 }}
                                                                                >
                                                                                </Button>
                                                                                <input type='file'
                                                                                    accept=".xlsx, .xls, .csv"
                                                                                    id='file' ref={inputFile} style={{ display: 'none' }}
                                                                                    onChange={(e) => handleDrop(e)} />
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    startIcon={<CloudUpload />}
                                                                                    classes={{
                                                                                        startIcon: classes.startIcon,
                                                                                        root: classes.uploadIconContainer
                                                                                    }}
                                                                                    onClick={() => uploadMX()}
                                                                                    style={{ marginLeft: 10 }}
                                                                                >
                                                                                </Button>
                                                                            </div>
                                                                            {formulaTypeTier != 'Formula' && calculationTypeTier == 'Rebate' &&
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <Tooltip title='Add Key Figure'>
                                                                                        <Button color="primary" size="small"
                                                                                            startIcon={<AddIcon />}
                                                                                            onClick={handleAddOperation}>Key Figure</Button>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <>
                                                                    {props.incentiveRateType === 'Conditional Attribute' &&
                                                                        <>
                                                                            <Grid
                                                                                item
                                                                                md={2}
                                                                            >
                                                                                <FormLabel className={classes.chargeBackLabel} required>
                                                                                    Attribute Name
                                                                                </FormLabel>
                                                                                <Select
                                                                                    name="attribute"
                                                                                    value={attributeNameMR}
                                                                                    onChange={e => setAttributeNameMR(e.target.value)}
                                                                                    displayEmpty
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                >
                                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                                        {attributeNameArray && Object.entries(attributeNameArray).length > 0 ? '' : '- Please configure -'}
                                                                                    </MenuItem>

                                                                                    {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                                        Object.entries(attributeNameArray).map(([key, value]) => {
                                                                                            return (
                                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                    {value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                </Select>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                md={1}
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                <div className={classes.selectRoot}>
                                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                                        Attribute Count
                                                                                    </FormLabel>
                                                                                    <OutlinedInput
                                                                                        value={attributeCount}
                                                                                        classes={{ root: classes.inputTwoLine }}
                                                                                        onChange={(e) => setAttributeCount(e.target.value)} />
                                                                                </div>
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                    {props.maximumAmountPeriod == 'Payment Period - Tier' && calculationTypeTier && calculationTypeTier != 'Rebate' &&
                                                                        <Grid
                                                                            item
                                                                            md={1}
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            <div className={classes.selectRoot}>
                                                                                <FormLabel required classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                                    Maximum Amount
                                                                                </FormLabel>
                                                                                <OutlinedInput
                                                                                    value={maximumAmount}
                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                    onChange={(e) => setMaximumAmount(e.target.value)} />
                                                                            </div>
                                                                        </Grid>
                                                                    }
                                                                    <Grid
                                                                        item
                                                                        md={1}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <div className={classes.selectRoot}>
                                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                Rate
                                                                            </FormLabel>
                                                                            <OutlinedInput
                                                                                value={rateScale}
                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                onChange={(e) => setRateScale(e.target.value)} />
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        md={1.5}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <div className={classes.selectRoot}>
                                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                Start Date
                                                                            </FormLabel>
                                                                            <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                                <Grid container justifyContent="space-around">
                                                                                    <DatePicker
                                                                                        disableToolbar
                                                                                        clearable
                                                                                        InputProps={{
                                                                                            padding: 0,
                                                                                            disableUnderline: true,
                                                                                            style: {
                                                                                                padding: '1px 10 1px 11px',
                                                                                                alignSelf: 'center',
                                                                                                alignItems: 'center',
                                                                                                border: '1px solid #E0E0E0',
                                                                                                width: '100%'
                                                                                            }
                                                                                        }}
                                                                                        value={startDateScale}
                                                                                        onChange={date => handleStartDateScale(date)}
                                                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                        format="MM/DD/YYYY"
                                                                                        minDate={props.startDate ? props.startDate : ''}
                                                                                    />
                                                                                </Grid>
                                                                            </LocalizationProvider>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        md={1.5}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <div className={classes.selectRoot}>
                                                                            <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                End Date
                                                                            </FormLabel>
                                                                            <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                                <Grid container justifyContent="space-around">
                                                                                    <DatePicker
                                                                                        disableToolbar
                                                                                        clearable
                                                                                        InputProps={{
                                                                                            padding: 0,
                                                                                            disableUnderline: true,
                                                                                            style: {
                                                                                                padding: '1px 10 1px 11px',
                                                                                                alignSelf: 'center',
                                                                                                alignItems: 'center',
                                                                                                border: '1px solid #E0E0E0',
                                                                                                width: '100%'
                                                                                            }
                                                                                        }}
                                                                                        value={endDateScale}
                                                                                        onChange={date => handleEndDateScale(date)}
                                                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                        format="MM/DD/YYYY"
                                                                                        minDate={startDateScale ? startDateScale : ''}
                                                                                    />
                                                                                </Grid>
                                                                            </LocalizationProvider>
                                                                        </div>

                                                                    </Grid>
                                                                    {keyFigureArrayMXS && keyFigureArrayMXS.length > 0 &&
                                                                        keyFigureArrayMXS.map((x, i) => {
                                                                            return (
                                                                                <>
                                                                                    <Grid
                                                                                        item
                                                                                        md={2}
                                                                                    >
                                                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                                            {props.incentiveRateType === 'Conditional Attribute' ? 'Conditional Key Figure Name' : keyFigureObject.name ? keyFigureObject.name : 'Key Figure Name'}
                                                                                        </FormLabel>
                                                                                        <Select
                                                                                            name="key"
                                                                                            value={x.key}
                                                                                            onChange={e => handleOperationChange(e, i, 'keyFigure')}
                                                                                            displayEmpty
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                            className={clsx({
                                                                                                [classes.select]: true
                                                                                            })}
                                                                                            classes={{
                                                                                                selectMenu: classes.selectedItem
                                                                                            }}
                                                                                        >
                                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                                                {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                            </MenuItem>
                                                                                            {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                                Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                                    return (
                                                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                            {value}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        md={2}
                                                                                        style={{ display: 'flex', flexDirection: 'column' }}
                                                                                    >
                                                                                        <div className={classes.selectRoot}>
                                                                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                                                Key Figure Value
                                                                                            </FormLabel>

                                                                                            <OutlinedInput
                                                                                                value={x.value}
                                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                                name="value"
                                                                                                onChange={e => handleOperationChange(e, i, 'keyFigure')}
                                                                                                displayEmpty
                                                                                                className={clsx({
                                                                                                    [classes.select]: true
                                                                                                })}

                                                                                            />

                                                                                        </div>

                                                                                        <Button color="primary" size="small"
                                                                                            classes={{
                                                                                                root: classes.removeButton
                                                                                            }}
                                                                                            startIcon={<DeleteForeverIcon />}
                                                                                            onClick={() => handleRemoveOperation(i, 'keyFigure')}>Remove</Button>

                                                                                    </Grid>


                                                                                </>
                                                                            );
                                                                        })

                                                                    }

                                                                    {attributeArrayMXS && attributeArrayMXS.length > 0 &&
                                                                        attributeArrayMXS.map((x, i) => {
                                                                            return (
                                                                                <>
                                                                                    <Grid
                                                                                        item
                                                                                        md={2}
                                                                                    >
                                                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                                            Attribute Name
                                                                                        </FormLabel>
                                                                                        <Select
                                                                                            name="key"
                                                                                            value={x.key}
                                                                                            onChange={e => handleOperationChange(e, i, 'attribute')}
                                                                                            displayEmpty
                                                                                            className={clsx({
                                                                                                [classes.select]: true
                                                                                            })}
                                                                                            classes={{
                                                                                                selectMenu: classes.selectedItem
                                                                                            }}
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                        >
                                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                                                {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                                                            </MenuItem>

                                                                                            {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                                                Object.entries(attributeNameArray).map(([key, value]) => {
                                                                                                    return (
                                                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                            {value}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        md={2}
                                                                                        style={{ display: 'flex', flexDirection: 'column' }}
                                                                                    >
                                                                                        <div className={classes.selectRoot}>
                                                                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                                                Attribute Value
                                                                                            </FormLabel>
                                                                                            {(x.key == 'customer_number' || x.key == 'material_number' || x.key == 'supplier_number'
                                                                                                || x.key == 'employee_number' || x.key == 'sold_to_party' || x.key == 'bill_to_party') ?
                                                                                                <div style={{ marginTop: -10 }}>
                                                                                                    <SearchDropDownMasterDataNew id='material_single'
                                                                                                        table={(x.key === 'supplier_number') ? 'supplier_master' :
                                                                                                            (x.key === 'material_number') ? 'material_master' : (x.key === 'employee_number') ? 'employee_master' : 'customer_master'}
                                                                                                        keyName={x.key}
                                                                                                        description={true}
                                                                                                        onChange={(value) => handleOperationChange(value, i, 'attribute', "value")}
                                                                                                        prevalue={x.value}
                                                                                                    />
                                                                                                </div>
                                                                                                :
                                                                                                x.key === 'customer_chain' || x.key === 'customer_group' || x.key === 'material_group' || x.key === 'product_hierarchy' || x.key === 'company_code' || x.key === 'sales_org' || x.key === 'supplier_group' ?
                                                                                                    <div style={{ marginTop: -10 }}>
                                                                                                        <AllOtherAttributeMasterComponent attributeName={x.key} value={attributeArrayMXS[i] && attributeArrayMXS[i]['value']} onChange={(e) => handleOperationChange(e, i, 'attr', "value")} prevalue={attributeArrayMXS[i] && attributeArrayMXS[i]['value']} withOutLabel={true} id={x.key} />
                                                                                                    </div>
                                                                                                    :
                                                                                                    <OutlinedInput
                                                                                                        value={attributeArrayMXS[i] && attributeArrayMXS[i]['value']}
                                                                                                        classes={{ root: classes.inputTwoLine }}
                                                                                                        name="value"
                                                                                                        onChange={e => handleOperationChange(e, i, 'attribute')}
                                                                                                        displayEmpty
                                                                                                        className={clsx({
                                                                                                            [classes.select]: true
                                                                                                        })}
                                                                                                    />
                                                                                            }
                                                                                        </div>

                                                                                        <Button color="primary" size="small"
                                                                                            classes={{
                                                                                                root: classes.removeButton
                                                                                            }}
                                                                                            startIcon={<DeleteForeverIcon />}
                                                                                            onClick={() => handleRemoveOperation(i, 'attribute')}>Remove</Button>

                                                                                    </Grid>
                                                                                </>
                                                                            );
                                                                        })

                                                                    }
                                                                    {formulaArrayMXS && formulaArrayMXS.length > 0 &&
                                                                        formulaArrayMXS.map((x, i) => {
                                                                            return (
                                                                                <>
                                                                                    <Grid
                                                                                        item
                                                                                        md={2}
                                                                                    >
                                                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                                            {props.incentiveRateType === 'Conditional Attribute' ? 'Conditional Formula Name' : 'Formula Name'}
                                                                                        </FormLabel>
                                                                                        <FormulaLabAutoComplete
                                                                                            data={(props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0)
                                                                                                ? props.formulaListData.records.map(item => ({ desc: item.formula_name, key: item.ID }))
                                                                                                : []}
                                                                                            id={i}
                                                                                            name="key"
                                                                                            onChange={e => handleOperationChange(e, i, 'formula')}
                                                                                            prevalue={Number(x.key)}
                                                                                        />

                                                                                        {/* <Select
                                                                                            name="key"
                                                                                            value={x.key}
                                                                                            onChange={e => handleOperationChange(e, i, 'formula')}
                                                                                            displayEmpty
                                                                                            className={clsx({
                                                                                                [classes.select]: true
                                                                                            })}
                                                                                            classes={{
                                                                                                selectMenu: classes.selectedItem
                                                                                            }}
                                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                        >
                                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                                                {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                                                            </MenuItem>

                                                                                            {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                                                return (
                                                                                                    <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                                        {item.formula_name}
                                                                                                    </MenuItem>
                                                                                                )
                                                                                            })}
                                                                                        </Select> */}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        md={2}
                                                                                        style={{ display: 'flex', flexDirection: 'column' }}
                                                                                    >
                                                                                        <div className={classes.selectRoot}>
                                                                                            <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                                                Formula Value
                                                                                            </FormLabel>
                                                                                            <OutlinedInput
                                                                                                value={x.value}
                                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                                name="value"
                                                                                                onChange={e => handleOperationChange(e, i, 'formula')}
                                                                                                displayEmpty
                                                                                                className={clsx({
                                                                                                    [classes.select]: true
                                                                                                })}
                                                                                            />
                                                                                        </div>

                                                                                        <Button color="primary" size="small"
                                                                                            classes={{
                                                                                                root: classes.removeButton
                                                                                            }}
                                                                                            startIcon={<DeleteForeverIcon />}
                                                                                            onClick={() => handleRemoveOperation(i, 'formula')}>Remove</Button>

                                                                                    </Grid>


                                                                                </>
                                                                            );
                                                                        })

                                                                    }
                                                                    <Grid
                                                                        item
                                                                        md={2}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <FormLabel className={classes.chargeBackLabel}>
                                                                            {' '}
                                                                        </FormLabel>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                {editMode ?
                                                                                    <Button
                                                                                        color='secondary'
                                                                                        variant="contained"
                                                                                        startIcon={<EditIcon />}
                                                                                        size={'small'}
                                                                                        classes={{
                                                                                            startIcon: classes.startIcon,
                                                                                            root: classes.checkIconContainer
                                                                                        }}
                                                                                        disabled={
                                                                                            calculationTypeTier == 'Rebate' ?
                                                                                                formulaTypeTier === 'Formula' ?
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false) :
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false)

                                                                                                : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate))) || !rateScale
                                                                                                    || (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false) || (props.incentiveRateType === 'Conditional Attribute' ? (!attributeNameMR && !attributeCount) : false) ||
                                                                                                    ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.length == 0) && (attributeArrayMXSAPI && attributeArrayMXSAPI.length == 0) && (formulaArrayMXSAPI && formulaArrayMXSAPI.length == 0)) ? true
                                                                                                    :
                                                                                                    ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.filter((k, i) => k.key == '' || k.value.toString() == '').length != 0) ||
                                                                                                        (attributeArrayMXSAPI && attributeArrayMXSAPI.filter((k, i) => k.key == '' || k.value == '').length != 0) ||
                                                                                                        (formulaArrayMXSAPI && formulaArrayMXSAPI.filter((k, i) => k.key == '' || k.value.toString() == '').length != 0))) ? true : false)
                                                                                        }
                                                                                        // disabled={(calculationTypeTier === 'Rebate' && (!keyFigureNameScale || !formulaName)) ? true : false}
                                                                                        onClick={() => handleSubmit(editIndex, 'Multi Axis')}
                                                                                    >
                                                                                    </Button>
                                                                                    :
                                                                                    <Button
                                                                                        color='secondary'
                                                                                        variant="contained"
                                                                                        size={'small'}
                                                                                        startIcon={<CheckIcon />}
                                                                                        classes={{
                                                                                            startIcon: classes.startIcon,
                                                                                            root: classes.checkIconContainer
                                                                                        }}
                                                                                        disabled={
                                                                                            calculationTypeTier == 'Rebate' ?
                                                                                                formulaTypeTier === 'Formula' ?
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false) :
                                                                                                    ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate)))) ? true : false)

                                                                                                : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (Moment(startDateScale) >= Moment(props.startDate))) || !rateScale
                                                                                                    || (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false) || (props.incentiveRateType === 'Conditional Attribute' ? (!attributeNameMR && !attributeCount) : false) ||
                                                                                                    ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.length == 0) && (attributeArrayMXSAPI && attributeArrayMXSAPI.length == 0) && (formulaArrayMXSAPI && formulaArrayMXSAPI.length == 0)) ? true
                                                                                                    :
                                                                                                    ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.filter((k, i) => k.key == '' || k.value == '').length != 0) ||
                                                                                                        (attributeArrayMXSAPI && attributeArrayMXSAPI.filter((k, i) => k.key == '' || k.value == '').length != 0) ||
                                                                                                        (formulaArrayMXSAPI && formulaArrayMXSAPI.filter((k, i) => k.key == '' || k.value.toString() == '').length != 0))) ? true : false)
                                                                                        }
                                                                                        onClick={() => handleSubmit('', 'Multi Axis')}
                                                                                    >
                                                                                    </Button>
                                                                                }
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    startIcon={<DeleteForeverIcon />}
                                                                                    classes={{
                                                                                        startIcon: classes.startIcon,
                                                                                        root: classes.deleteIconContainer,
                                                                                    }}
                                                                                    onClick={() => handleRemoveMXS()}
                                                                                    style={{ marginLeft: 5 }}
                                                                                >
                                                                                </Button>
                                                                                <input type='file'
                                                                                    accept=".xlsx, .xls, .csv"
                                                                                    id='file' ref={inputFile} style={{ display: 'none' }}
                                                                                    onChange={(e) => handleDrop(e)} />
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    startIcon={<CloudUpload />}
                                                                                    classes={{
                                                                                        startIcon: classes.startIcon,
                                                                                        root: classes.uploadIconContainer
                                                                                    }}
                                                                                    onClick={() => uploadMX()}
                                                                                    style={{ marginLeft: 10 }}
                                                                                >
                                                                                </Button>
                                                                            </div>

                                                                        </div>
                                                                    </Grid>


                                                                </>
                                                            }
                                                        </Grid>
                                                    </div>
                                                </Card >
                                                {/* mxs */}
                                                {calculationTypeTier === 'Tier' &&
                                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 20 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Tooltip title='Add Key Figure'>
                                                                <Button color="primary" size="small"
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => handleAddOperation('keyFigure')}>Key Figure</Button>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Tooltip title='Add Attribute'>
                                                                <Button color="primary" size="small"
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => handleAddOperation('attribute')}> Attribute</Button>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Tooltip title='Add Formula'>
                                                                <Button color="primary" size="small"
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => handleAddOperation('formula')}>Formula</Button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <Card>
                                                <form
                                                    autoComplete="off"
                                                    noValidate
                                                >
                                                    <div className={classes.container}>
                                                        <Grid container spacing={1} >
                                                            {props.calculationMethod == 'Flat Rate' && props.incentiveRateType == 'Contract' &&
                                                                < Grid
                                                                    item
                                                                    md={2}
                                                                // style={{ padding: '0px 10px 0px 10px' }}
                                                                >
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel className={classes.chargeBackLabel} required>
                                                                            Formula Type
                                                                        </FormLabel>
                                                                        <Select
                                                                            value={formulaTypeTier}
                                                                            onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {formulaTypeDataTier
                                                                                .map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}


                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {props.calculationMethod === 'Price Point' ?
                                                                <Grid
                                                                    item
                                                                    md={2}
                                                                    xs={12}>
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                            Formula
                                                                        </FormLabel>
                                                                        <FormulaLabAutoComplete
                                                                            data={(props.pricingFormulaList && props.pricingFormulaList.records && props.pricingFormulaList.records.length > 0)
                                                                                ? props.pricingFormulaList.records.map(item => ({ desc: item.formula_name, key: item.ID }))
                                                                                : []}
                                                                            id={'formula_name_4'}
                                                                            onChange={(value) => setFormulaName(value)}
                                                                            prevalue={formulaName}
                                                                        />
                                                                        {/* <Select
                                                                            value={formulaName}
                                                                            onChange={(e) => setFormulaName(e.target.value)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                            style={{ textTransform: 'capitalize' }}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {props.pricingFormulaList && props.pricingFormulaList.records && props.pricingFormulaList.records.length && props.pricingFormulaList.records.length > 0 && props.pricingFormulaList.records.map((item, index) => {
                                                                                return (
                                                                                    <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                        {item.formula_name}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select> */}
                                                                    </div>
                                                                </Grid>
                                                                :
                                                                <Grid
                                                                    item
                                                                    md={2}
                                                                    xs={12}
                                                                >
                                                                    <div className={classes.selectRoot}>

                                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                                            {formulaTypeTier === 'Formula' ? 'Formula' : 'Key Figure Name'}
                                                                        </FormLabel>
                                                                        {formulaTypeTier === 'Formula' ?
                                                                            <FormulaLabAutoComplete
                                                                                data={(props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0)
                                                                                    ? props.formulaListData.records.map(item => ({ desc: item.formula_name, key: item.ID }))
                                                                                    : []}
                                                                                id={'formula_name_5'}
                                                                                onChange={(value) => setFormulaName(value)}
                                                                                prevalue={formulaName}
                                                                            />
                                                                            // <Select
                                                                            //     value={formulaName}
                                                                            //     onChange={(e) => setFormulaName(e.target.value)}
                                                                            //     displayEmpty
                                                                            //     className={clsx({
                                                                            //         [classes.select]: true
                                                                            //     })}
                                                                            //     classes={{
                                                                            //         selectMenu: classes.selectedItem
                                                                            //     }}
                                                                            //     style={{ textTransform: 'capitalize' }}
                                                                            //     MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            // >
                                                                            //     <MenuItem value="" style={{ height: 30 }}>
                                                                            //     </MenuItem>
                                                                            //     {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                            //         return (
                                                                            //             <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                            //                 {item.formula_name}
                                                                            //             </MenuItem>
                                                                            //         )
                                                                            //     })}
                                                                            // </Select>
                                                                            :
                                                                            <Select
                                                                                value={keyfigurename}
                                                                                onChange={handleKeyfigurename}
                                                                                displayEmpty
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                            >
                                                                                {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                    Object.entries(mappingValuesSet)
                                                                                        .sort()
                                                                                        .map(([key, value]) => {
                                                                                            return (
                                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                    {value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                            </Select>
                                                                        }
                                                                    </div>
                                                                </Grid>

                                                            }

                                                            {operationArray.length > 0 &&
                                                                operationArray.map((x, i) => {
                                                                    return (
                                                                        <Grid
                                                                            container
                                                                            md={3}
                                                                            xs={12}
                                                                            spacing={1}
                                                                            style={{ margin: 0 }}
                                                                        >

                                                                            <Grid
                                                                                item
                                                                                md={4}
                                                                            >
                                                                                <div className={classes.selectRoot}>
                                                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                        Operation
                                                                                    </FormLabel>
                                                                                    <Select
                                                                                        name="operation"
                                                                                        value={x.operation}
                                                                                        onChange={e => handleOperationChange(e, i)}
                                                                                        displayEmpty
                                                                                        className={clsx({
                                                                                            [classes.select]: true
                                                                                        })}
                                                                                        classes={{
                                                                                            selectMenu: classes.selectedItem
                                                                                        }}
                                                                                    >
                                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                                        </MenuItem>
                                                                                        {operationData.map((item) => {
                                                                                            return (
                                                                                                <MenuItem value={item.value} key={item.value}>
                                                                                                    {item.value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                    </Select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                md={8}
                                                                            >
                                                                                <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                                    {keyFigureObject.name}
                                                                                </FormLabel>
                                                                                <Select
                                                                                    name="key_figure_names"
                                                                                    value={x.key_figure_names}
                                                                                    onChange={e => handleOperationChange(e, i)}
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                >
                                                                                    <MenuItem value="" >
                                                                                        {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                    </MenuItem>
                                                                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                        Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                            return (
                                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                    {value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                </Select>
                                                                            </Grid>
                                                                            <Button color="primary" size="small"
                                                                                classes={{
                                                                                    root: classes.removeButton
                                                                                }}
                                                                                startIcon={<DeleteForeverIcon />}
                                                                                onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                                        </Grid>
                                                                    );
                                                                })

                                                            }
                                                            <Grid
                                                                item
                                                                md={1}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                        Rate
                                                                    </FormLabel>
                                                                    <Tooltip
                                                                        open={tooltipIsOpen}
                                                                        title="Rate should be less than or equal to 100"
                                                                    >
                                                                        <OutlinedInput
                                                                            value={rate}
                                                                            type={'number'}
                                                                            inputProps={{ min: "0", step: "1" }}
                                                                            classes={{ root: classes.inputTwoLine }}
                                                                            onChange={handleRate} />
                                                                    </Tooltip>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={1}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                        Rate Type
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={rateType}
                                                                        onChange={(e) => setRateType(e.target.value)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                        </MenuItem>
                                                                        {uomData.map((item) => {
                                                                            return (
                                                                                <MenuItem value={item.key} key={item.key}>
                                                                                    {item.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>
                                                            {rateType === '$' &&
                                                                <Grid
                                                                    item
                                                                    md={1}
                                                                    xs={12}>
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                                                            UoM
                                                                        </FormLabel>
                                                                        <Select
                                                                            value={uom}
                                                                            onChange={handleUom}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>

                                                                            </MenuItem>
                                                                            {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item} key={item}
                                                                                        disabled={item === '%' && contractAPIDetails && contractAPIDetails.calculation_method === 'Fixed Amount' ? true : false}>
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            <Grid
                                                                item
                                                                md={1.5}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                        Start Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveStartDate}
                                                                                onChange={date => handleEffectiveStartDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                minDate={props.startDate ? props.startDate : ''}
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={1.5}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel classes={{ root: classes.fontSetting }} required className={classes.chargeBackLabel}>
                                                                        End Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveEndDate}
                                                                                onChange={date => handleEffectiveEndDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={2}
                                                                xs={12}
                                                            >
                                                                <Grid container spacing={2}>
                                                                    <Grid
                                                                        item
                                                                        md={12}
                                                                    >
                                                                        <Grid container spacing={1} >
                                                                            <Grid item md={12} >
                                                                                <Grid container style={{ paddingBottom: 15, marginTop: 40 }} >
                                                                                    <Grid item md={12} style={{ display: 'flex' }}>
                                                                                        {editMode ?
                                                                                            <Button
                                                                                                color='secondary'
                                                                                                variant="contained"
                                                                                                startIcon={<EditIcon />}
                                                                                                classes={{
                                                                                                    startIcon: classes.startIcon,
                                                                                                    root: classes.checkIconContainer
                                                                                                }}
                                                                                                disabled={(props.calculationMethod === 'Fixed Amount' ? !keyfigurename : !(props.calculationMethod === 'Price Point' || formulaTypeTier === 'Formula' ?
                                                                                                    formulaName : formulaTypeTier && keyfigurename)) ||
                                                                                                    !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) &&
                                                                                                        (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true))
                                                                                                    || !rate || !rateType || operationArray.some(hasNull) ? true : false}

                                                                                                onClick={() => handleSubmit(editIndex)}
                                                                                            >
                                                                                            </Button>
                                                                                            :
                                                                                            <Button
                                                                                                color='secondary'
                                                                                                variant="contained"
                                                                                                startIcon={<CheckIcon />}
                                                                                                classes={{
                                                                                                    startIcon: classes.startIcon,
                                                                                                    root: classes.checkIconContainer
                                                                                                }}
                                                                                                disabled={(props.calculationMethod === 'Fixed Amount' ? !keyfigurename : !(props.calculationMethod === 'Price Point' || formulaTypeTier === 'Formula' ?
                                                                                                    formulaName : formulaTypeTier && keyfigurename)) ||
                                                                                                    !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) &&
                                                                                                        (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true))
                                                                                                    || !rate || !rateType || operationArray.some(hasNull) ? true : false}

                                                                                                onClick={() => handleSubmit('')}
                                                                                            >
                                                                                            </Button>
                                                                                        }
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            startIcon={<DeleteForeverIcon />}
                                                                                            classes={{
                                                                                                startIcon: classes.startIcon,
                                                                                                root: classes.deleteIconContainer,
                                                                                            }}
                                                                                            onClick={handleRemove}
                                                                                            style={{ marginLeft: 10 }}
                                                                                        >
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    {props.calculationMethod != 'Price Point' && formulaTypeTier != 'Formula' &&
                                                                                        <Tooltip title='Add Key Figure'>
                                                                                            <Button color="primary" size="small"
                                                                                                startIcon={<AddIcon />}
                                                                                                onClick={handleAddOperation}> Key Figure</Button>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </form>
                                            </Card>
                }
            </div >
            {/* calculation Rules Add ends here */}
            {/* calculation Rules Table starts here */}
            <Card style={{ marginTop: '-1rem' }}>
                <div className={classes.row} style={{ marginTop: '-1rem' }}>
                    <CardHeader
                        title="CALCULATION RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2} style={{ marginTop: '-1.5rem' }}>
                    {contractAPIDetails && contractAPIDetails.calculation_method == 'Price Difference' ?
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {calculationStore.length > 0 ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                    <TableCell align='center'>Attribute Value</TableCell>
                                                    <TableCell align='center'>Acquisition Price</TableCell>
                                                    <TableCell align='center'>Contract Price</TableCell>
                                                    <TableCell align='center'>Rate Type</TableCell>
                                                    <TableCell align='center'>UoM</TableCell>
                                                    <TableCell align='center'>Start Date</TableCell>
                                                    <TableCell align='center'>End Date</TableCell>
                                                    <TableCell align='center'>Options</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {calculationStore
                                                    .map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item}>
                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                    {attributeNameArray && attributeNameArray['material_number'] ? attributeNameArray['material_number'] : 'Material Number'}
                                                                </TableCell>
                                                                <TableCell align='center'>{item.attribute_key_values && item.attribute_key_values.filter(item => item.attribute_value).map(e => e.attribute_value)}</TableCell>
                                                                <TableCell align='center' >{item.attribute_key_values && item.attribute_key_values.filter(item => item.acquisition_price).map(e => e.acquisition_price.toFixed(2))}</TableCell>
                                                                <TableCell align='center'>{item.attribute_key_values && item.attribute_key_values.filter(item => item.contract_price).map(e => e.contract_price.toFixed(2))}</TableCell>
                                                                <TableCell align='center'>{item.rate_type}</TableCell>
                                                                <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center' style={{ display: 'flex' }}>
                                                                    <IconButton
                                                                        onClick={() => editcalculationRule(item, index, 'CB')}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() => handleDeleteLocally(index)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography variant='h4'>
                                        There is no data to show now.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        :
                        contractAPIDetails && contractAPIDetails.calculation_method === 'Fixed Amount' && contractAPIDetails.incentive_rate_type === 'Attribute' ?
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {calculationStore.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                        <TableCell align='center'>Attribute Value</TableCell>
                                                        <TableCell align='center'>Key Figure Name</TableCell>
                                                        <TableCell align='center'>Rate</TableCell>
                                                        <TableCell align='center'>Rate Type</TableCell>
                                                        <TableCell align='center'>UoM</TableCell>
                                                        <TableCell align='center'>Start Date</TableCell>
                                                        <TableCell align='center'>End Date</TableCell>
                                                        <TableCell align='center'>Options</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {calculationStore
                                                        // .filter(item=>item.enabled)
                                                        .map((item, index) => {
                                                            return (
                                                                <StyledTableRow key={item}>
                                                                    <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                        {item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_name ?
                                                                            attributeNameArray && attributeNameArray[item.attribute_key_values[0].attribute_name] ? attributeNameArray[item.attribute_key_values[0].attribute_name] : item.attribute_key_values[0].attribute_name.replace(/_/g, ' ')
                                                                            : ''}

                                                                    </TableCell>
                                                                    <TableCell align='center'>{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ? item.attribute_key_values[0].attribute_value : ''}</TableCell>
                                                                    <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                        {/* {item.key_figure_names ? item.key_figure_names.join(',').replace(/_/g, ' ') : ''} */}
                                                                        {item.key_figure_names && item.key_figure_names.length > 0 && item.key_figure_names.map((item2, i) => {
                                                                            console.log(item2)
                                                                            return <>{
                                                                                mappingValuesSet && mappingValuesSet[item2] ? mappingValuesSet[item2] : item2.replace(/_/g, ' ')
                                                                            }{item.operations && item.operations[i] ? item.operations[i] : ''}</>;
                                                                        })}


                                                                    </TableCell>
                                                                    <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                    <TableCell align='center'>{item.rate_type}</TableCell>
                                                                    <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                    <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                    <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                    <TableCell align='center' style={{ display: 'flex' }}>
                                                                        <IconButton
                                                                            onClick={() => editcalculationRule(item, index, 'FA')}
                                                                            classes={{ root: classes.IconButton }}
                                                                            size="large">
                                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() => handleDeleteLocally(index)}
                                                                            classes={{ root: classes.IconButton }}
                                                                            size="large">
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                            There is no data to show now.
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            :
                            contractAPIDetails && contractAPIDetails.calculation_method && contractAPIDetails.calculation_method === 'Flat Rate' && contractAPIDetails.incentive_rate_type === 'Attribute'
                                ?
                                <Grid container >
                                    <Grid
                                        item
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {calculationStore.length > 0 ?
                                            <TableContainer>
                                                <Table>
                                                    <TableHead >
                                                        <TableRow >
                                                            <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                            <TableCell align='center'>Attribute Value</TableCell>
                                                            <TableCell align='center'>Formula Type</TableCell>
                                                            <TableCell align='center'>Formula</TableCell>
                                                            <TableCell align='center'>Key Figure Name</TableCell>
                                                            <TableCell align='center'>Rate</TableCell>
                                                            <TableCell align='center'>Rate Type</TableCell>
                                                            <TableCell align='center'>UoM</TableCell>
                                                            <TableCell align='center'>Start Date</TableCell>
                                                            <TableCell align='center'>End Date</TableCell>
                                                            <TableCell align='center'>Options</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody classes={{ root: classes.table }} >
                                                        {calculationStore
                                                            // .filter(item=>item.enabled)
                                                            .map((item, index) => {
                                                                return (
                                                                    <StyledTableRow key={item}>
                                                                        <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                            {item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_name ?
                                                                                attributeNameArray && attributeNameArray[item.attribute_key_values[0].attribute_name] ? attributeNameArray[item.attribute_key_values[0].attribute_name] : item.attribute_key_values[0].attribute_name.replace(/_/g, ' ') : ''

                                                                            }

                                                                        </TableCell>
                                                                        <TableCell align='center'>{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ? item.attribute_key_values[0].attribute_value : ''}</TableCell>
                                                                        <TableCell align='center'>{item.formula_type}</TableCell>
                                                                        <TableCell align='center'>{item.formula_id ?
                                                                            props.formulaListData && props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                return item3.ID === item.formula_id ? item3.formula_name : null
                                                                            }) : ''}
                                                                        </TableCell>
                                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                            {item.key_figure_names ?
                                                                                item.key_figure_names.map((item) => {
                                                                                    return mappingValuesSet[item] + '  '
                                                                                })
                                                                                :
                                                                                ''
                                                                            }

                                                                        </TableCell>
                                                                        <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                        <TableCell align='center'>{item.rate_type}</TableCell>
                                                                        <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                        <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center' style={{ display: 'flex' }}>
                                                                            <IconButton
                                                                                onClick={() => editcalculationRule(item, index, 'MR')}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                onClick={() => handleDeleteLocally(index)}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </StyledTableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            :
                                            <Typography variant='h4'>
                                                There is no data to show now.
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>

                                :

                                contractAPIDetails && contractAPIDetails.calculation_method === 'Quota Achievement' || contractAPIDetails.calculation_method === 'PPM' ?
                                    <Grid container >{console.log(calculationStore)}
                                        <Grid
                                            item
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            {calculationStore.length > 0 ?
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead >
                                                            <TableRow >
                                                                <TableCell align='center'>Key Figure Name</TableCell>
                                                                <TableCell align='center'>Rate Type</TableCell>
                                                                <TableCell align='center'>UoM</TableCell>
                                                                <TableCell align='center'>From</TableCell>
                                                                <TableCell align='center'>Rate</TableCell>
                                                                <TableCell align='center'>Start Date</TableCell>
                                                                <TableCell align='center'>End Date</TableCell>
                                                                <TableCell align='center' style={{ width: 100 }}>Options</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody classes={{ root: classes.table }} >
                                                            {calculationStore
                                                                .map((item, index) => {
                                                                    return (
                                                                        <StyledTableRow key={item}>
                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                {item.key_figure_names ?
                                                                                    item.key_figure_names.map((item2, i) => {
                                                                                        return (<> {item.key_figure_names[i] && mappingValuesSet[item.key_figure_names[i]]
                                                                                            //checkLabel(item.key_figure_names[i]) ? checkLabel(item.key_figure_names[i]) : item.key_figure_names[i].replace(/_/g, ' ')
                                                                                        } {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                    })
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </TableCell>

                                                                            <TableCell align='center'>{item.rate_type}</TableCell>
                                                                            <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                            <TableCell align='center'>
                                                                                {item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ?
                                                                                    parseFloat(item.attribute_key_values[0].attribute_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
                                                                                }</TableCell>
                                                                            <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                            <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                            <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                            <TableCell align='center' style={{ display: 'flex' }}>
                                                                                <IconButton
                                                                                    onClick={() => editcalculationRule(item, index, 'Quota')}
                                                                                    classes={{ root: classes.IconButton }}
                                                                                    size="large">
                                                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    onClick={() => handleDeleteLocally(index, 'tier')}
                                                                                    classes={{ root: classes.IconButton }}
                                                                                    size="large">
                                                                                    <DeleteForeverIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </StyledTableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                :
                                                <Typography variant='h4'>
                                                    There is no data to show now.
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    contractAPIDetails && contractAPIDetails.calculation_method && contractAPIDetails.calculation_method === 'BOM Explosion' ?
                                        <Grid container >
                                            <Grid
                                                item
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                {calculationStore.length > 0 ?
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead >
                                                                <TableRow >
                                                                    <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                                    <TableCell align='center'>Attribute Value</TableCell>
                                                                    <TableCell align='center'>Key Figure Name</TableCell>
                                                                    <TableCell align='center'>Rate</TableCell>
                                                                    <TableCell align='center'>Rate Type</TableCell>
                                                                    <TableCell align='center'>UoM</TableCell>
                                                                    <TableCell align='center'>Start Date</TableCell>
                                                                    <TableCell align='center'>End Date</TableCell>
                                                                    <TableCell align='center'>Options</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody classes={{ root: classes.table }} >
                                                                {calculationStore
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <StyledTableRow key={item}>
                                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                                    {item.attribute_name ? attributeNameArray[item.attribute_name] : ''}
                                                                                </TableCell>
                                                                                <TableCell align='center'>{item.attribute_value}</TableCell>
                                                                                <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.key_figure_names ? mappingValuesSet[item.key_figure_names] : ''}</TableCell>
                                                                                <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                <TableCell align='center'>{item.rate_type}</TableCell>
                                                                                <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                                <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                <TableCell align='center' style={{ display: 'flex' }}>
                                                                                    <IconButton
                                                                                        onClick={() => editcalculationRule(item, index, 'BOM Explosion')}
                                                                                        classes={{ root: classes.IconButton }}
                                                                                        size="large">
                                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                    </IconButton>
                                                                                    <IconButton
                                                                                        onClick={() => handleDeleteLocally(index)}
                                                                                        classes={{ root: classes.IconButton }}
                                                                                        size="large">
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                            </StyledTableRow>
                                                                        );
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    :
                                                    <Typography variant='h4'>
                                                        There is no data to show now.
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                        :
                                        (contractAPIDetails && contractAPIDetails.calculation_method && (contractAPIDetails.calculation_method === 'Tiered Volume' || contractAPIDetails.calculation_method === 'Tiered Growth'))
                                            ?
                                            <Grid container >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    {calculationStore.length > 0 ?

                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead >
                                                                    <TableRow >
                                                                        <TableCell align='center'>Calculation Type</TableCell>
                                                                        <TableCell align='center'>Formula Type</TableCell>
                                                                        <TableCell align='center'>Key Figure Name</TableCell>
                                                                        <TableCell align='center'>Formula</TableCell>
                                                                        <TableCell align='center'>From</TableCell>
                                                                        {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                            <TableCell align='center'>Maximum Amount</TableCell>
                                                                        }
                                                                        <TableCell align='center'>Rate</TableCell>
                                                                        <TableCell align='center'>Rate Type</TableCell>
                                                                        <TableCell align='center'>UoM</TableCell>
                                                                        <TableCell align='center'>Start Date</TableCell>
                                                                        <TableCell align='center'>End Date</TableCell>
                                                                        <TableCell align='center' style={{ width: 100 }}>Options</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody classes={{ root: classes.table }} >
                                                                    {calculationStore
                                                                        .sort(function (a, b) {
                                                                            return calculationTypeData.indexOf(a.type) - calculationTypeData.indexOf(b.type);
                                                                        }).map((item, index) => {
                                                                            return (
                                                                                <StyledTableRow key={item}>
                                                                                    <TableCell align='center' >  {item.type}</TableCell>
                                                                                    <TableCell align='center'>{item.formula_id ? 'Formula' : 'Key Figure'}</TableCell>
                                                                                    <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                        {item.key_figure_names ?
                                                                                            item.key_figure_names.map((item2, i) => {
                                                                                                return (<> {item.key_figure_names[i] && mappingValuesSet[item.key_figure_names[i]]
                                                                                                    //checkLabel(item.key_figure_names[i]) ? checkLabel(item.key_figure_names[i]) : item.key_figure_names[i].replace(/_/g, ' ')
                                                                                                }  {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                            })
                                                                                            :
                                                                                            ''
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell align='center' >
                                                                                        {props.formulaListData && props.formulaListData.records && props.formulaListData.records.map((item2) => {
                                                                                            return item2.ID === item.formula_id ? item2.formula_name : null
                                                                                        }
                                                                                        )
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell align='center'>
                                                                                        {item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ?
                                                                                            parseFloat(item.attribute_key_values[0].attribute_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
                                                                                        }
                                                                                    </TableCell>
                                                                                    {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                                        <TableCell align='center'>{parseFloat(item.maximum_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>

                                                                                    }
                                                                                    <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                    <TableCell align='center'>{item.type != 'Rebate' ? item.rate_type : ''}</TableCell>
                                                                                    <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                                    <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                    <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                    <TableCell align='center' style={{ display: 'flex' }}>
                                                                                        <IconButton
                                                                                            onClick={() => editcalculationRule(item, index, 'Tiered Volume - Simple')}
                                                                                            classes={{ root: classes.IconButton }}
                                                                                            size="large">
                                                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                        </IconButton>
                                                                                        <IconButton
                                                                                            onClick={() => handleDeleteLocally(index)}
                                                                                            classes={{ root: classes.IconButton }}
                                                                                            size="large">
                                                                                            <DeleteForeverIcon />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                </StyledTableRow>
                                                                            );
                                                                        })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        <Typography variant='h4'>
                                                            There is no data to show now.
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                            :
                                            contractAPIDetails && contractAPIDetails.calculation_method == 'Multi Axis' ?
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        {calculationStoreMX.length > 0 ?
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead >
                                                                        <TableCell align='center'>Calculation Type</TableCell>
                                                                        <TableCell align='center'>Formula Type</TableCell>
                                                                        <TableCell align='center'>Key Figure Name - Rebate</TableCell>
                                                                        <TableCell align='center'>Formula Name - Rebate</TableCell>
                                                                        {props.incentiveRateType === 'Conditional Attribute' &&
                                                                            <>
                                                                                <TableCell align='center' >Attribute Name</TableCell>
                                                                                <TableCell align='center' >Conditional Attribute Count</TableCell>
                                                                            </>
                                                                        }
                                                                        {Array.apply(null, {
                                                                            length: keyFigureArrayMXSColumns ? keyFigureArrayMXSColumns : 0
                                                                        }).map((e, i) => (
                                                                            <>
                                                                                <TableCell align='center' >
                                                                                    {props.incentiveRateType === 'Conditional Attribute' ?
                                                                                        'Conditional Key Figure Name' : 'Key Figure Name'}
                                                                                </TableCell>
                                                                                <TableCell align='center' >Key Figure Value</TableCell>
                                                                            </>
                                                                        ))}
                                                                        {Array.apply(null, {
                                                                            length: attributeArrayMXSColumns ? attributeArrayMXSColumns : 0
                                                                        }).map((e, i) => (
                                                                            <>
                                                                                <TableCell align='center' >Attribute Name</TableCell>
                                                                                <TableCell align='center' >Attribute Value</TableCell>
                                                                            </>
                                                                        ))}
                                                                        {Array.apply(null, {
                                                                            length: formulaArrayMXSColumns ? formulaArrayMXSColumns : 0
                                                                        }).map((e, i) => (
                                                                            <>
                                                                                <TableCell align='center' >
                                                                                    {props.incentiveRateType === 'Conditional Attribute' ?
                                                                                        'Conditional Formula Name' : 'Formula Name'}
                                                                                </TableCell>
                                                                                <TableCell align='center' >Formula Value</TableCell>
                                                                            </>
                                                                        ))}
                                                                        {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                            <TableCell align='center'>Maximum Amount</TableCell>
                                                                        }
                                                                        <TableCell align='center'>Rate</TableCell>
                                                                        <TableCell align='center'>Start Date</TableCell>
                                                                        <TableCell align='center'>End Date</TableCell>
                                                                        <TableCell align='center'>Actions</TableCell>
                                                                    </TableHead>
                                                                    <TableBody classes={{ root: classes.table }} >
                                                                        {calculationStoreMX
                                                                            .sort(function (a, b) {
                                                                                return calculationTypeDataMXS.indexOf(a.type) - calculationTypeDataMXS.indexOf(b.type);
                                                                            })
                                                                            /*  .sort((a, b) =>a.type < b.type ? 1 : -1)
                                                                              .sort((a, b) => a.type <= b.type&&a.effective_start_date < b.effective_start_date  ? 1 : -1)
                                                                              .sort((a, b) =>a.type <= b.type&& (a.effective_start_date >= b.effective_start_date) || (a.effective_end_date < b.calculation_end_date) ? 1 : -1)
                                                                             */
                                                                            .map((item, index) => {
                                                                                return (
                                                                                    <StyledTableRow key={item.ID}>
                                                                                        <TableCell align='center' >  {item.type}</TableCell>
                                                                                        <TableCell align='center'>{item.formula_type}</TableCell>
                                                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>{
                                                                                            item.type === 'Rebate' &&
                                                                                            item.key_figure_names && item.key_figure_names.map((item2, i) => {
                                                                                                return (<> {item.key_figure_names[i] && mappingValuesSet[item.key_figure_names[i]]
                                                                                                    // checkLabel(item.key_figure_names[i]) ? checkLabel(item.key_figure_names[i]) : item.key_figure_names[i].replace(/_/g, ' ')
                                                                                                } {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                            })
                                                                                        }</TableCell>
                                                                                        <TableCell align='center'>{
                                                                                            item.type === 'Rebate' && item.formula_id && props.formulaListData && props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                                return item3.ID === item.formula_id ? item3.formula_name : null
                                                                                            })
                                                                                        }</TableCell>
                                                                                        {props.incentiveRateType === 'Conditional Attribute' &&
                                                                                            <>
                                                                                                <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.attribute_name ? attributeNameArray[item.attribute_name] ? attributeNameArray[item.attribute_name] : item.attribute_name.replace(/_/g, ' ') : ''}
                                                                                                </TableCell>
                                                                                                <TableCell>{item.attribute_count ? parseFloat(item.attribute_count).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>
                                                                                            </>
                                                                                        }

                                                                                        {Array.apply(null, {
                                                                                            length: keyFigureArrayMXSColumns ? keyFigureArrayMXSColumns : 0
                                                                                        }).map((e, i) => (
                                                                                            <>
                                                                                                <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.type === 'Tier' && item.key_fig_api && item.key_fig_api[i] && item.key_fig_api[i].key &&
                                                                                                        mappingValuesSet[item.key_fig_api[i].key]
                                                                                                        //checkLabel(item.key_fig_api[i].key) && checkLabel(item.key_fig_api[i].key)
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.type === 'Tier' && item.key_fig_api && item.key_fig_api[i] && (item.key_fig_api[i].value || item.key_fig_api[i].value == 0) &&
                                                                                                        parseFloat(item.key_fig_api[i].value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                                </TableCell>
                                                                                            </>
                                                                                        ))}
                                                                                        {Array.apply(null, {
                                                                                            length: attributeArrayMXSColumns ? attributeArrayMXSColumns : 0
                                                                                        }).map((e, i) => (
                                                                                            <>
                                                                                                <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.type === 'Tier' && item.attri_api && item.attri_api[i] && item.attri_api[i].key ?
                                                                                                        attributeNameArray[item.attri_api[i].key] ? attributeNameArray[item.attri_api[i].key] : item.attri_api[i].key.replace(/_/g, ' ') : ''}
                                                                                                </TableCell>
                                                                                                <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.type === 'Tier' && item.attri_api && item.attri_api[i] && item.attri_api[i].value && item.attri_api[i].value}
                                                                                                </TableCell>

                                                                                            </>
                                                                                        ))}
                                                                                        {Array.apply(null, {
                                                                                            length: formulaArrayMXSColumns
                                                                                                ? formulaArrayMXSColumns : null
                                                                                        }).map((e, i) => (
                                                                                            <>
                                                                                                <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.type === 'Tier' && item.form_api && item.form_api[i] && item.form_api[i].key && props.formulaListData && props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                                        return item3.ID == item.form_api[i].key ? item3.formula_name : null
                                                                                                    })}
                                                                                                </TableCell>
                                                                                                <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.type === 'Tier' && item.form_api && item.form_api[i] && (item.form_api[i].value || item.form_api[i].value == 0) &&
                                                                                                        parseFloat(item.form_api[i].value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                                </TableCell>
                                                                                            </>
                                                                                        ))}
                                                                                        {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                                            <TableCell align='center'>{parseFloat(item.maximum_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>

                                                                                        }
                                                                                        <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                        <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                        <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                        <TableCell align='center' >
                                                                                            <div style={{ display: 'flex' }}>
                                                                                                <IconButton
                                                                                                    onClick={() => editcalculationRule(item, index, 'Multi Axis')}
                                                                                                    classes={{ root: classes.IconButton }}
                                                                                                    size="large">
                                                                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                                </IconButton>
                                                                                                {item.type == 'Tier' &&
                                                                                                    <IconButton onClick={() => clone(item)} classes={{ root: classes.IconButton }} size={'small'}>
                                                                                                        <img src={require('../../../../library/images/Copy.png')} alt='' />
                                                                                                    </IconButton>
                                                                                                }
                                                                                                <IconButton
                                                                                                    onClick={() => handleDeleteLocally(index, 'Multi Axis')}
                                                                                                    classes={{ root: classes.IconButton }}
                                                                                                    size="large">
                                                                                                    <DeleteForeverIcon />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        </TableCell>
                                                                                    </StyledTableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            :
                                                            <Typography variant='h4'>
                                                                There is no data to show now.
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        {calculationStore.length > 0 ?
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead >
                                                                        <TableRow >
                                                                            {props.calculationMethod === 'Flat Rate' && props.incentiveRateType == 'Contract' &&
                                                                                <TableCell classes={{ root: classes.leftAlign }}>Formula Type</TableCell>
                                                                            }
                                                                            {props.calculationMethod !== 'Fixed Amount' &&
                                                                                <TableCell classes={{ root: classes.leftAlign }}>Formula</TableCell>}
                                                                            {props.calculationMethod !== 'Price Point' &&
                                                                                <TableCell classes={{ root: classes.leftAlign }}>Key Figure Name</TableCell>} {/*{props.calculationMethod === 'Price Point' || formulaTypeTier === 'Formula' ? 'Formula' : 'Key Figure Name'} */}
                                                                            <TableCell align='center'>Rate</TableCell>
                                                                            <TableCell align='center'>Rate Type</TableCell>
                                                                            <TableCell align='center'>UoM</TableCell>
                                                                            <TableCell align='center' >Start Date</TableCell>
                                                                            <TableCell align='center' >End Date</TableCell>
                                                                            <TableCell align='center'>Options</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody classes={{ root: classes.table }} >
                                                                        {calculationStore && calculationStore
                                                                            .map((item, index) => {
                                                                                return (
                                                                                    <StyledTableRow key={item}>
                                                                                        {props.calculationMethod === 'Flat Rate' && props.incentiveRateType == 'Contract' &&
                                                                                            <TableCell align='center'>{item.formula_id ? 'Formula' : 'Key Figure'}</TableCell>}
                                                                                        {props.calculationMethod !== 'Fixed Amount' && <TableCell align='center'>{props.calculationMethod === 'Price Point' ?
                                                                                            props.pricingFormulaList && props.pricingFormulaList.records && props.pricingFormulaList.records.map((item2) => {
                                                                                                return item2.ID === item.formula_id ? item2.formula_name : null
                                                                                            })
                                                                                            :
                                                                                            item.formula_id ?
                                                                                                props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                                    return item3.ID === item.formula_id ? item3.formula_name : null
                                                                                                }) : ''}
                                                                                        </TableCell>}
                                                                                        {props.calculationMethod !== 'Price Point' &&
                                                                                            <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                                                {
                                                                                                    item.key_figure_names ?
                                                                                                        item.key_figure_names.map((item2, i) => {
                                                                                                            return (<> {
                                                                                                                //checkLabel(item2) ? checkLabel(item2) : 
                                                                                                                mappingValuesSet[item2]
                                                                                                            } {item.operations && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                                        })
                                                                                                        :
                                                                                                        ''
                                                                                                }
                                                                                            </TableCell>}
                                                                                        <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                        <TableCell align='center'>{item.rate_type}</TableCell>
                                                                                        <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                                        <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                        <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                        <TableCell align='center' style={{ display: 'flex' }}>
                                                                                            <IconButton
                                                                                                onClick={() => editcalculationRule(item, index)}
                                                                                                classes={{ root: classes.IconButton }}
                                                                                                size="large">
                                                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                            </IconButton>
                                                                                            <IconButton
                                                                                                onClick={() => handleDeleteLocally(index)}
                                                                                                classes={{ root: classes.IconButton }}
                                                                                                size="large">
                                                                                                <DeleteForeverIcon />
                                                                                            </IconButton>
                                                                                        </TableCell>
                                                                                    </StyledTableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            :
                                                            <Typography variant='h4'>
                                                                There is no data to show now.
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                    }
                </div>
            </Card>
        </div >
    );

});

const mapDispatchToProps = dispatch => {
    return {
        getFormulasList: () => dispatch(getFormulasList(1, 1000)),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
        getGeneralUOM: () => dispatch(getGeneralUOM()),
        getPricingCalculationFormula: (page, limit) => dispatch(getPriceFormula(page, limit)),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        uploadCalculationRules: (file, calculationMethod, appType, sourceDataType) => dispatch(uploadCalculationRules(file, calculationMethod, appType, sourceDataType)),
        onLoadingAttribute: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),

    }
}

const mapStateToProps = state => {
    return {
        // tableData: state.addContractData.calculationRulesData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        // data: state.addContractData.contractGeneralData,
        formulaListData: state.FormulaLabData.formulaListData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        //labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
        supplierMasterData: state.customerData.supplierMasterData,
        generalUOMAll: state.customerData.generalUOMAll,
        employeeMasterData: state.customerData.employeeMasterData,
        pricingFormulaList: state.pricingData.priceFormulaList,
        uploadedRules: state.addContractData.calculationRulesUploadData,
        //dropdownAttributeData: state.addMultipleConfigurationData.multipleConfigurationDataAV,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationRules);