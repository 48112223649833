import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { FormLabel, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { ContractCell } from "../../Dashboard/components/ContractCell";
import Moment from "moment";
import {
  NewCust,
  TopStatus,
  GeoChart,
  Rebate
} from "./Components";
import { getRebateDashboardData, dashboardDashBoardNames } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: -20,
    borderRadius: 10,
    padding: "27px 10px 27px 10px",
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  gridContainer: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
    paddingTop: 10,
  },
  formLabel: {
    width: '100%',
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: theme.typography.h1.fontSize,
    display: 'flex',
    justifyContent: 'center',

  },
}));
const PostingDetailAnalytics = (props) => {
  const classes = useStyles();
  const [dashboardData, setDashboardData] = React.useState({});
  // useEffect(() => {
  //   var data = {
  //     start_date: Moment.utc()
  //       .subtract(1, "months")
  //       .startOf("month")
  //       .format("MM/DD/YYYY"),
  //     end_date: Moment.utc()
  //       .subtract(1, "months")
  //       .endOf("month")
  //       .format("MM/DD/YYYY"),
  //   };

  //   props.getDashboardData(data);
  // }, []);
  useEffect(() => {
    props.allowedDashBoardApps(sessionStorage.getItem("application"));
  }, []);
  useEffect(() => {
    if (props.dasboardList) setDashboardData(props.dasboardList);
  }, [props.dasboardList]);
  return (
    <div className={classes.root}>
      {/* <div style={{ flex: 1, display: "flex" }}> */}
      {/* <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.activeContracts}
                heading={"Sales"}
                value={
                  dashboardData && dashboardData["Sales"]
                    ? dashboardData["Sales"]
                    : 0
                }
              />
            </div> */}
      {/* <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.newContracts}
                heading={"Rebate"}
                value={
                  dashboardData && dashboardData["Rebate"]
                    ? dashboardData["Rebate"]
                    : 0
                }
              />
            </div> */}
      {/* <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.waitingApprovals}
                heading={"Rebate Ratio"}
                value={
                  dashboardData && dashboardData["Ratio"]
                    ? dashboardData["Ratio"]
                    : 0
                }
              />
            </div> */}
      {/* <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.expiredContracts}
                heading={"Accrual"}
                value={
                  dashboardData && dashboardData["Accrual"]
                    ? dashboardData["Accrual"]
                    : 0
                }
              />
            </div> */}
      {/* <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.totalContracts}
                heading={"Payment"}
                value={
                  dashboardData && dashboardData["Payment"]
                    ? dashboardData["Payment"]
                    : 0
                }
              />
            </div> */}
      {/* </div> */}
      <div>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className={classes.gridContainer}>
            <Typography color="primary" className={classes.formLabel}>
              New Customer
            </Typography>
            <NewCust />
          </Grid>
          {/* <Grid
                         item
                         md={4}
                         xs={12}
                        >
                            Rebate Ratio Trend
    
                        </Grid> */}
          {/* <Grid item md={6} xs={12} className={classes.gridContainer}>
                <Typography color="primary" className={classes.formLabel}>
                  {" "}
                  Rebate Trends
                </Typography>
                <RebateTrends />
              </Grid> */}
          <Grid item md={6} xs={12} className={classes.gridContainer}>
            <Typography color="primary" className={classes.formLabel}>
              TopStats
            </Typography>
            <TopStatus />
          </Grid>

          <Grid item md={6} xs={12} className={classes.gridContainer}>
            <Typography color="primary" className={classes.formLabel}>
              Regional Analysis
            </Typography>
            {" "}
            <GeoChart />
          </Grid>

          <Grid item md={6} xs={12} className={classes.gridContainer}>
            <Typography color="primary" className={classes.formLabel}>
              Rebate Trends
            </Typography>
            <Rebate />
          </Grid>
          {/* <Grid item md={4} xs={12} className={classes.gridContainer}>
                <Typography color="primary" className={classes.formLabel}>
                  {" "}
                  Region{" "}
                </Typography>
                <GeoAnalysis />
              </Grid> */}
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    dasboardList: state.initialData.dashboardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardData: (data) => dispatch(getRebateDashboardData(data)),
    allowedDashBoardApps: (type) => dispatch(dashboardDashBoardNames(type, "dashboard")),
    // loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostingDetailAnalytics);
