import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Typography, Radio, RadioGroup,
    FormControlLabel, FormLabel,
    TextField, Checkbox,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown } from '../../../../components/Inputs';
import SimpleDialog from '../../../../components/Dialog';
import { getOutgoingClaims, runOutgoingClaimPostings } from '../../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../../components/Inputs";
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
        marginTop: 5,
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        // height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        // height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        // height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    chargeBackLabel: {
        // height: 34,
        // display: 'flex',
        // alignItems: 'flex-end'
        fontSize: theme.typography.h3.fontSize
    },
    spinner: {
        height: '80vh'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        // marginTop: 25
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
}));



const PostingForm = props => {

    const classes = useStyles();
    const { className } = props;
    const [claimNumbersList, setClaimNumbersList] = React.useState([]);
    const [claimNumber, setClaimNumber] = React.useState([]);

    useEffect(() => {
        props.getOutgoingClaims();
    }, []);

    useEffect(() => {
        setCalculationPeriodStart(Moment().subtract(1, 'months').startOf('month'));
        setCalculationPeriodEnd(Moment().subtract(1, 'months').endOf('month'));
        setPostingDate(Moment().startOf('month'))
    }, []);

    useEffect(() => {
        var tempNameArray = [];
        if (props.outgoingClaimListData) {
            props.outgoingClaimListData.map(item => {
                tempNameArray.push(item.claim_number.toString());
            })
            setClaimNumbersList(tempNameArray);
        }
    }, [props.outgoingClaimListData])

    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [contractNumber, setContractNumber] = useState([]);
    const [supplierNumber, setSupplierNumber] = useState([]);
    const [postingDate, setPostingDate] = React.useState(null);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);

    const handleClear = () => {
        setClaimNumber([]);
        setSelected([])
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setSupplierNumber([])
        setPostingDate(null)
        setTags([])
        setRadioGroupValue('')
    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleDialog = (bool) => {
        if (bool) {
            var sd = [Moment.utc(calculationPeriodStart).format('YYYY/MM/DD')];
            var ed = [Moment.utc(calculationPeriodEnd).format('YYYY/MM/DD')];
            var pd = [Moment.utc(postingDate).format('YYYY/MM/DD')];

            var formData = {
                "filters": {
                    "claim_number": claimNumber,
                    "calculation_start_date": sd,
                    "calculation_end_date": ed,
                    "supplier_number": supplierNumber,
                }
            }
            props.onSubmit(formData, radioGroupValue, pd);
            setOpen(false)
        }
        else
            setOpen(false)
    }

    function handleCalculationPeriodStart(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodStart(Moment(newValue));

        }
        else {
            setCalculationPeriodStart(newValue);
            if (!newValue)
                setCalculationPeriodEnd(null)
        }
    }
    function handleCalculationPeriodEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodEnd(Moment(newValue));

        }
        else {
            setCalculationPeriodEnd(newValue);
        }

    }
    function handleClaimNumber(e, value) {

        let newSelected = value;
        if (value.length > 0) {

            setSelected(newSelected)
            setClaimNumber(newSelected);
        }
        else {
            setSelected([])
            setClaimNumber([]);
        }

    }
    function handleTagsAdd(newValue) {
        // setTags([...tags, newValue]);
        setTags(newValue)
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }
    function handleSupplierNumberAdd(newValue) {
        // setSupplierNumber([...supplierNumber, newValue]);
        setSupplierNumber(newValue)
    }
    function handleSupplierNumberDelete(newValue) {
        var deletedTags = supplierNumber.filter(item => item !== newValue)
        setSupplierNumber(deletedTags)
    }

    function handlePostingDate(newValue) {

        if (newValue && newValue.isValid()) {
            setPostingDate(Moment(newValue));
        }
        else {

            setPostingDate(newValue);
        }

    }
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 16 }}> {props.page == 'payment' ? 'Payment Postings' : 'Accrual Postings'}</Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <Dropdown classes={{ root: classes.fontSetting }} heading={'Application Type'} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >


                                <FormLabel className={classes.chargeBackLabel} style={{ marginTop: '1.1rem' }} required={supplierNumber.length == 0 ? true : false}>
                                    Claim Number
                                </FormLabel>
                                <Autocomplete
                                    classes={{
                                        inputRoot: { borderRadius: '0px !important' }
                                    }}
                                    multiple
                                    limitTags={3}
                                    id="checkboxes-tags-demo"
                                    options={claimNumbersList}
                                    getOptionLabel={(option) => option}
                                    defaultValue={selected}
                                    value={selected}
                                    renderInput={params => (

                                        <TextField {...params} variant="outlined" size="small" style={{ borderRadius: '0px !important' }} />

                                    )}


                                    disableCloseOnSelect
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                color='primary'
                                                checked={selected} />
                                            {option}

                                        </li>
                                    )}
                                    style={{ width: "100%", marginTop: '0rem' }}
                                    onChange={(event, newValue) => { handleClaimNumber(event, newValue) }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                s
                            >
                                <FormLabel className={classes.chargeBackLabel} required={claimNumber.length == 0 ? true : false}>
                                    Supplier Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierNumber}
                                    blurBehavior='add'
                                    onAdd={(chips) => handleSupplierNumberAdd(chips)}
                                    onDelete={(chip) => handleSupplierNumberDelete(chip)}

                                    disableUnderline={true}
                                /> */}
                                <ChipInput data={supplierNumber} onChange={handleSupplierNumberAdd} />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >

                                <FormLabel required className={classes.chargeBackLabel} >
                                    Posting Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 11px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 0,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: '-2.5rem',
                                                    marginBottom: 10
                                                }
                                            }}
                                            value={postingDate}
                                            onChange={handlePostingDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel}>
                                    Claim Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 11px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 1,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: '2.3rem'
                                                }
                                            }}
                                            value={calculationPeriodStart}
                                            onChange={handleCalculationPeriodStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel}>
                                    Claim End Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 11px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 1,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: '2.3rem'
                                                }
                                            }}
                                            value={calculationPeriodEnd}
                                            onChange={handleCalculationPeriodEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} style={{ marginTop: '1rem' }}>
                                    Tags
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    blurBehavior='add'
                                    onAdd={(chips) => handleTagsAdd(chips)}
                                    onDelete={(chip) => handleTagsDelete(chip)}

                                    disableUnderline={true}
                                /> */}
                                <ChipInput data={tags} onChange={handleTagsAdd} />
                            </Grid>
                            {props.page == 'accrual' ?
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                        <FormControlLabel
                                            value="Accrual"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.chargeBackLabel }} > Accrual </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />
                                        <FormControlLabel
                                            value="Accrual Reversal"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.chargeBackLabel }} > Accrual Reversal </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />
                                    </RadioGroup>
                                </Grid>
                                :
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                        <FormControlLabel
                                            value="Payment"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.chargeBackLabel }} > Payment </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />
                                        <FormControlLabel
                                            value="Payment Reversal"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.chargeBackLabel }} > Payment Reversal </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />

                                    </RadioGroup>
                                </Grid>
                            }


                        </Grid>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                            Clear
                        </Button>
                        <Button
                            disabled={
                                (claimNumber.length > 0 || supplierNumber.length > 0) &&
                                    calculationPeriodStart && calculationPeriodEnd && radioGroupValue ? false : true}
                            variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                        >
                            {props.loading ?
                                <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                :
                                'Run'
                            }
                        </Button>
                    </div>
                </form>
                <SimpleDialog open={open} content={'Do you want to submit outgoing claim ' + props.page + ' postings?'} handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData, type, postDate) => dispatch(runOutgoingClaimPostings(formData, type, postDate)),
        getOutgoingClaims: () => dispatch(getOutgoingClaims()),
    }
}


const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        outgoingClaimListData: state.initialData.outgoingClaimListData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostingForm);