import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getSingleSalesBasis } from '../../redux/actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    }
}));

const ViewSalesBasis = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [attributeValue, setAttributeValue] = React.useState([]);
    const [contractType, setContractType] = React.useState('');
    const [attributeName, setAttributeName] = React.useState('');
    const [conditionID, setConditionID] = React.useState(0);
    const [applicationType, setApplicationType] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [optionData, setOptionData] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);

    useEffect(() => {
        setOpen(true);
        
         var pathname = window.location.pathname;
         var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
         if (appId)
             props.getSalesBasisDataSingle(appId);
    }, []);
    useEffect(()=>{
        if (props.salesBasisSingle && Object.keys(props.salesBasisSingle).length > 0) {
            setApplicationType(props.salesBasisSingle['app_type']);
            setContractType(props.salesBasisSingle['contract_type']);
            setSourceDataType(props.salesBasisSingle['data_source_type']);
            setAttributeName(props.salesBasisSingle['attribute_name']);
            setOptionData(props.salesBasisSingle['option']);
            setEffectiveStartDate(props.salesBasisSingle['effective_start_date']);
            setEffectiveEndDate(props.salesBasisSingle['effective_end_date']);
            setAttributeValue(props.salesBasisSingle['attribute_value']);
            setConditionID(props.salesBasisSingle['condition_id']);
        }
    }, [props.salesBasisSingle]);
   
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                to='/sales-basis'
                >
                  Global Eligibility
                </Link>
                <Typography color="textPrimary" variant='h4'>View Global Eligibility</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ paddingBottom: 15 }}
                        >
                            {props.salesBasisSingle ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'>Application Type</TableCell>
                                                <TableCell align='center'>Contract Type</TableCell>
                                                <TableCell align='center'>Source Data Type</TableCell>
                                                <TableCell align='center'>Condition ID</TableCell>
                                                <TableCell align='center'>Attribute Name</TableCell>
                                                <TableCell align='center'>Option</TableCell>
                                                <TableCell align='center'>Effective Start Date</TableCell>
                                                <TableCell align='center'>Effective End Date</TableCell>
                                                <TableCell align='center'>Attribute Value</TableCell>
                                                 </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                      
                                                    <StyledTableRow >
                                                        <TableCell align='center' >{applicationType}</TableCell>
                                                        <TableCell align='center'>{contractType}</TableCell>
                                                        <TableCell align='center'>{sourceDataType}</TableCell>
                                                        <TableCell align='center'>{conditionID}</TableCell>
                                                        <TableCell align='center' style={{textTransform:'capitalize'}}>{attributeName.replace(/_/g, ' ')}</TableCell>
                                                        <TableCell align='center'>{optionData}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(effectiveStartDate).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(effectiveEndDate).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>{attributeValue.toString()}</TableCell>

                                                    </StyledTableRow>
                                               
                                           
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {    
        getSalesBasisDataSingle: (id) => dispatch(getSingleSalesBasis(id)),      
    }
}

const mapStateToProps = state => {
    return {
        salesBasisSingle: state.customerData.salesBasisSingle,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSalesBasis);