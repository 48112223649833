import React, { useEffect, forwardRef, useRef, useState } from "react";

import MaterialTable from 'material-table';
import { styled } from '@mui/material/styles';
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import {
  updatedContractsAllList,
  downloadAllContracts,
  getContractsTableDataList,
  getAccessData,
  uploadEligibilityRulesClear,
  cloneContract,
  deleteContract,
  getContractViewData,
  downloadSheet,
  changeRequestDetails,
  fpHistory,
  ediUpload, uploadMassFile, getContractsTableDataBySearchEligibilty, getAcquireLockDetails, runcontractChangeReports
} from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { getContractDetailsData } from "../../../redux/actions";
import LoadingOverlay from "react-loading-overlay";
import { DeleteOutline, Announcement, DownloadForOfflineRounded } from "@mui/icons-material";
import PacmanLoader from "react-spinners/PacmanLoader";
import BeatLoader from "react-spinners/BeatLoader";
import ChangeCircleIcon from "@mui/icons-material/BubbleChart";
import Moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import HashLoader from "react-spinners/HashLoader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import secureLocalStorage from "react-secure-storage";
import { tableIcons } from "../../../components/Icons/TableIcons";
import {
  OpenInBrowser,
  Loop,
  SearchSharp,
  Update
} from "@mui/icons-material";
import { UploadFile } from "@mui/icons-material";
import {
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions, Fab, MenuItem, FormLabel, Select
} from "@mui/material";
import { CloudUpload, LibraryAdd } from '@mui/icons-material';
import { MessageDialogue } from "../../MessageDialogue";
import { TextInputWithLabel } from "../../../components/Inputs";
import { Refresh } from '@mui/icons-material';
import moment from 'moment-timezone';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '20px 16px',
    //fontSize: theme.typography.h4.fontSize,
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  description: {
    textAlign: "left",
    paddingLeft: 28,
  },
  IconButton: {
    padding: 0,
    marginRight: 10
    //fontSize: 10,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 15,
    marginTop: 15,
  },
  header: {
    backgroudColor: "red",
  },
  buttonCreate: {
    // textTransform: 'none', backgroundColor: theme.palette.primary.main, color: 'white'
    marginTop: 10,
    color: "black",
  },
  spinner: {
    // height: '100vh'
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    borderRadius: 20,
    color: theme.palette.primary.main,
    zIndex: 1
  },
  formLabel: {
    fontSize: theme.typography.h3.fontSize,
  },
  select: {
    width: '100%',
    marginTop: 10,
    height: 'webkitFillAvailalble'
  },
  paper: { minWidth: window.screen.width * .50 },
}));

const ContractSetupTable = (props) => {

  const { className } = props;
  const classes = useStyles();
  const inputFile = useRef(null);
  const history = useHistory();
  const [dataRows, setDataRows] = React.useState([]);
  const [contractsHaveCR, setContractsHaveCR] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [contractsTableDataAPI, setContractsTableDataAPI] = React.useState([]);
  const [comparingLoad, setComparingLoad] = React.useState(false);
  const [changeRequestLoader, setChangeRequestLoader] = React.useState(false);
  const [currentDeleteContractNumber, setCurrentDeleteContractNumber] = React.useState("");
  const [selectedContractNumber, setSelectedContractNumber] = React.useState("");
  const [currentDeleteContractNumberDisplay, setCurrentDeleteContractNumberDisplay] = React.useState("");
  const theme = useTheme();
  const [dialog, setDialog] = React.useState(false)
  const [errorList, setErrorList] = React.useState([])
  const [application, setApplication] = useState(sessionStorage.getItem('application') ? sessionStorage.getItem('application') : '')
  const [actionType, setActionType] = React.useState('')
  const [internalDesc, setInternalDesc] = React.useState('')
  const [format, setFormat] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [trigger, setTrigger] = React.useState('');
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const status = history.location.status

  useEffect(() => {
    if (history.location.state !== "searchByContract") {
      var filter = {}
      if (status == 'New')
        filter = {
          "approval_status": 'new',
          "start_date": Moment.utc(new Date()).format("MM/DD/YYYY"),
          "end_date": Moment.utc(new Date()).format("MM/DD/YYYY"),
        }
      else if (status == 'Active')
        filter = {
          'approval_status.in': "acc,changes_acc",
          // "start_date": sessionStorage.getItem('dashboard-count-sd'),
          // "end_date": sessionStorage.getItem('dashboard-count-ed'),
          "start_date": Moment.utc(new Date()).format("MM/DD/YYYY"),
          "end_date": Moment.utc(new Date()).format("MM/DD/YYYY"),
        }
      else if (status == 'Submit_approval')
        filter = {
          "approval_status": 'submit_approval',
          "start_date": Moment.utc(new Date()).format("MM/DD/YYYY"),
          "end_date": Moment.utc(new Date()).format("MM/DD/YYYY"),
        }
      else if (status == 'Rejected') {
        var today = Moment.utc(new Date()).format("MM/DD/YYYY");
        filter = { 'valid_to.lessthan': today }
      }
      else
        filter = null
      if (filter != null && filter["approval_status"] === "submit_approval") {
        props.onCheckContractsTable(filter, "IsUpdateApprovalContractFalse")
      }
      else {
        props.onCheckContractsTable(filter);
      }

    }
    else {
      props.onSubmit(sessionStorage.getItem("search-contract-data"))
    }
  }, [status]);
  useEffect(() => {
    setErrorList(props.uploadMessage)
    if (props.uploadMessage && props.uploadMessage.messageLog && props.uploadMessage.messageLog.length > 0) {
      setDialog(true);
    }
  }, [props.uploadMessage])
  function handleError() {
    setDialog(false)
  }
  const handleFormat = () => {
    setOpen(false)
    runQuery()
    if (format === 'Standard Format') {
      setTrigger('v2');
      setFormat('');
    } else {
      setTrigger('');
      setFormat('');
    }
  }
  function cancelEdit() {
    setOpen(false)
    setFormat('')
  }
  function runQuery() {
    inputFile.current.click();
  }
  const handleDrop2 = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    props.uploadMassFile(application, data, trigger);
    event.target.value = '';
  }
  const clone = (id) => {
    setSelectedContractNumber(id);
    setActionType('clone')
    setOpenConfirm(true);
    //props.cloneContract(id);
  };
  const deleteCon = (id, number) => {
    setSelectedContractNumber(id);
    setCurrentDeleteContractNumberDisplay(number);
    setOpenConfirm(true);
    setActionType('delete')
  };
  const changeRequestPage = (id, updatedId) => {
    props.getAccessData();
    props.changeRequestDetails(id, updatedId);
  };
  const handleSheetDownload = () => {
    props.downloadAllContracts();
  };
  function editContract(id, approvalStatus) {
    checkEditingStatus(id)
    //props.getcontractdetails(id, approvalStatus, 'edit');
    props.getcontractdetails(id, approvalStatus);
    if (approvalStatus === "submit_approval") {
      navigateToSubmitApproval(id);
    } else {
      navigateToEdit(id);
    }
  }
  function navigateToEdit(id) {
    if (history.location.state === "searchByContract") {
      history.push({
        pathname: "/contract-setup/edit-contract/" + id,
        state: "searchByContract",
        id: id,
      });
      //localStorage.setItem("mode", "edit");
      //localStorage.setItem("currentAddContractID", id);
    }
    else {
      history.push({
        pathname: "/contract-setup/edit-contract/" + id,
        state: "editContract",
        id: id,
      });
      // localStorage.setItem("mode", "edit");
      // localStorage.setItem("currentAddContractID", id);
    }

  }
  function navigateToSubmitApproval(id) {
    props.uploadEligibilityRulesClear();
    if (history.location.state === "searchByContract") {
      history.push({
        pathname: "/contract-setup/edit-contract-submit-approval/" + id,
        state: "searchByContract",
        id: id,
      });
      //localStorage.setItem("mode", "edit");
      //localStorage.setItem("currentAddContractID", id);
    }
    else {
      history.push({
        pathname: "/contract-setup/edit-contract-submit-approval/" + id,
        state: "editContract",
        id: id,
      });
      //localStorage.setItem("mode", "edit");
      //localStorage.setItem("currentAddContractID", id);
    }

  }

  useEffect(() => {
    if (props.contractablesdata /*&& props.contractablesdata.length>0*/) {
      if (props.contractablesdata.length > 0) {
        setContractsTableDataAPI(props.contractablesdata);
        var contractsThatHaveChangeRequest = [];
        props.contractablesdata.map((item) => {
          if (item["has_active_update_request"]) {
            contractsThatHaveChangeRequest.push(item.contract_number);
          }
        });
        if (contractsThatHaveChangeRequest.length > 0) {
          props.updatedContractsList(contractsThatHaveChangeRequest);
          setContractsHaveCR(true);
        }
      }
      else {
        setContractsTableDataAPI([])
      }
    }
  }, [props.contractablesdata]);
  const addContract = () => {
    props.uploadEligibilityRulesClear();
    //localStorage.removeItem("currentAddContractID");
    //localStorage.setItem("mode", "add");
    history.push("/contract-setup/add-contract");
  };
  const searchContract = () => {
    history.push("/search-contract");
  };
  const handleRefresh = () => {
    props.onCheckContractsTable()
  };
  const massUpdate = () => {
    history.push("/mass-contract-update");
  };
  const contractUpload = () => {
    history.push("/mass-upload");
  };
  function handleItemClick(id) {
    // localStorage.setItem("currentAddContractID", id);
    props.getcontractViewdetails(id);
  }
  useEffect(() => {
    setChangeRequestLoader(true);
    if (props.updatedContractList /*&& props.updatedContractList.length > 0*/) {
      props.updatedContractList && props.updatedContractList.length > 0 &&
        props.updatedContractList.map((item1) => {
          contractsTableDataAPI &&
            contractsTableDataAPI.length > 0 &&
            contractsTableDataAPI.map((item2, index) => {
              if (item1.update_contract_id === item2.contract_number) {
                contractsTableDataAPI[index]["hereIsTheUpdatedContractID"] =
                  item1.id;
                contractsTableDataAPI[index]["hereIsTheUpdatedContractStatus"] =
                  item1.approval_status;
                contractsTableDataAPI[index][
                  "hereIsTheUpdatedContractIDToShow"
                ] = item1.change_request_id;
                contractsTableDataAPI[index][
                  "hereIsTheUpdatedContractIDToStatus"
                ] = item1.contract_status;
              }
            });
        });
      var tempRows = [];
      if (contractsTableDataAPI && contractsTableDataAPI.length > 0) {
        contractsTableDataAPI.map((e) => {
          tempRows.push({
            contract_number: e.contract_number,
            id: e.id,
            calculation_method: e.calc_method ? e.calc_method : e.calculation_method,
            hereIsTheUpdatedContractIDToShow:
              e.hereIsTheUpdatedContractIDToShow,
            hereIsTheUpdatedContractIDToStatus: e.hereIsTheUpdatedContractIDToStatus,
            update_request_closed: e.update_request_closed,
            contract_type: e.contract_type,
            internal_description: e.internal_description,
            approval_status: e.approval_status,
            contract_status: e.contract_status,
            accrual_amount: e.accrual_amount
              ? parseFloat(e.accrual_amount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : 0.0,
            payment_amount: e.payment_amount
              ? parseFloat(e.payment_amount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : 0.0,
            valid_from: Moment.utc(e.valid_from).format("MM/DD/YYYY"),
            valid_to: Moment.utc(e.valid_to).format("MM/DD/YYYY"),
            hereIsTheUpdatedContractID: e.hereIsTheUpdatedContractID,
            hereIsTheUpdatedContractStatus: e.hereIsTheUpdatedContractStatus,
          });
        });
        setDataRows(tempRows);

      }
      setChangeRequestLoader(false);
    } else {
      var tempRows = [];
      // if (props.contractablesdata /*&& props.contractablesdata.length > 0*/) {
      //   if (props.contractablesdata.length > 0) {
      //     props.contractablesdata
      if (contractsTableDataAPI) {
        if (contractsTableDataAPI.length > 0) {
          contractsTableDataAPI
            .sort((a, b) => (a.id < b.id ? 1 : -1))
            .map((e) => {
              tempRows.push({
                contract_number: e.contract_number,
                id: e.id,
                calculation_method: e.calc_method ? e.calc_method : e.calculation_method,
                hereIsTheUpdatedContractIDToShow:
                  e.hereIsTheUpdatedContractIDToShow,
                update_request_closed: e.update_request_closed,
                contract_type: e.contract_type,
                internal_description: e.internal_description,
                approval_status: e.approval_status,
                contract_status: e.contract_status,
                accrual_amount: e.accrual_amount
                  ? parseFloat(e.accrual_amount).toLocaleString()
                  : e.accrual_amount,
                payment_amount: e.payment_amount
                  ? parseFloat(e.payment_amount).toLocaleString()
                  : e.payment_amount,
                valid_from: Moment.utc(e.valid_from).format("MM/DD/YYYY"),
                valid_to: Moment.utc(e.valid_to).format("MM/DD/YYYY"),
                hereIsTheUpdatedContractID: e.hereIsTheUpdatedContractID,
                hereIsTheUpdatedContractStatus: e.hereIsTheUpdatedContractStatus,
              });
            });
          setDataRows(tempRows);
        }
        else {
          setDataRows([])
        }
      }
      setChangeRequestLoader(false);
    }
  }, [props.updatedContractList, contractsTableDataAPI]);
  const columns = [
    {
      field: "contract_number",
      title: "Contract Number",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div
          onClick={() => handleItemClick(rowData.id)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#2877B1",
            textAlign: "center"
          }}
        >
          {rowData.contract_number}
        </div>
      ),
    },
    {
      field: "hereIsTheUpdatedContractIDToShow",
      title: "Pending Change",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div
          style={{ whiteSpace: "nowrap", cursor: "pointer", textAlign: "center" }}
          onClick={() =>
            editContract(
              rowData.hereIsTheUpdatedContractID,
              rowData.hereIsTheUpdatedContractStatus
            )
          }
        >
          {contractsHaveCR ? (
            <BeatLoader
              color={"rgb(54, 215, 183)"}
              loading={
                props.updatedContractList &&
                  props.updatedContractList.length > 0 ?
                  changeRequestLoader : false
              }
              size={6}
            />
          ) : (
            ""
          )}
          {rowData.hereIsTheUpdatedContractIDToShow ? (
            <div style={{ color: "green" }}>
              {rowData.hereIsTheUpdatedContractIDToShow + ' - ' + rowData.hereIsTheUpdatedContractIDToStatus}{" "}
            </div>
          ) : (
            <div style={{ color: "grey" }}> </div>
          )}
        </div>
      ),
    },
    {
      field: "calculation_method",
      title: "Calculation Method",
      editable: "never",
      type: "string",
    },
    {
      field: "contract_type",
      title: "Contract Type",
      editable: "never",
      type: "string",
    },
    {
      field: "internal_description",
      title: "Internal Description",
      editable: "never",
      type: "string",
      // render: (rowData) => (
      //   <Tooltip arrow title={rowData.internal_description}><div style={{ width: '100%' }}>
      //     {rowData.internal_description}
      //   </div></Tooltip>)
    },
    {
      field: "contract_status",
      title: "Approval Status",
      editable: "never",
      type: "string",
      // render: (rowData) => (
      //   <div>
      //     { rowData.contract_status
      //     /*rowData.approval_status === "new"
      //       ? "New"
      //       : rowData.approval_status === "acc"
      //         ? "Accepted"
      //         : rowData.approval_status === "rej"
      //           ? "Rejected"
      //           : rowData.approval_status === "changes_acc"
      //             ? "Changes Accepted"
      //             : "Submit for Approval"*/}
      //   </div>
      // ),
    },
    {
      field: "accrual_amount",
      title: "Accrual Amt",
      editable: "never",
      type: "string",
      render: (rowData) =>
        rowData.accrual_amount ? (
          <div
            onClick={() => props.fpHistory(rowData.contract_number, 'Accrual,Accrual Reversal,Delta Accrual')}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#2877B1",
            }}
          >
            {rowData.accrual_amount}{" "}
          </div>
        ) : (
          <div>0.00</div>
        ),
    },
    {
      field: "payment_amount",
      title: "Payment Amt",
      editable: "never",
      type: "string",
      render: (rowData) =>
        rowData.payment_amount ? (
          <div
            onClick={() => props.fpHistory(rowData.contract_number, 'Payment,Payment Reversal,Delta Payment')}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#2877B1",
            }}
          >
            {rowData.payment_amount}{" "}
          </div>
        ) : (
          <div>0.00</div>
        ),
    },
    {
      field: "valid_from",
      title: "Start Date",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div>
          {rowData.valid_from === "0001-01-01T00:00:00Z"
            ? ""
            : rowData.valid_from}{" "}
        </div>
      ),
    },
    {
      field: "valid_to",
      title: "End Date",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div>
          {
            rowData.valid_to === "0001-01-01T00:00:00Z"
              ? ""
              : rowData.valid_to /*Moment.utc(rowData.valid_to).format('MM/DD/YYYY')*/
          }
        </div>
      ),
    },
    {
      field: "Actions",
      title: "Actions",
      type: "string",
      editable: "never",
      sorting: false,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div>
            {secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role.rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_contract-" + sessionStorage.getItem("application")
                ) && (
                <IconButton
                  style={{ display: rowData.approval_status === "rej" ? 'none' : '' }}
                  classes={{ root: classes.IconButton }}
                  onClick={() =>
                    editContract(rowData.id, rowData.approval_status)
                  }
                  disabled={
                    rowData.hereIsTheUpdatedContractIDToShow ? true : false
                  }
                  size={"small"}
                >
                  <EditIcon color="disabled" style={{ fontSize: 15 }} />
                </IconButton>
              )}
            {secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role.rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_contract-" + sessionStorage.getItem("application")
                ) && (
                <IconButton
                  onClick={() => clone(rowData.id)}
                  classes={{ root: classes.IconButton }}
                  size={"small"}
                >
                  <img
                    src={require("../../../library/images/Copy.png")}
                    alt=""
                  />
                </IconButton>
              )}
            {rowData.hereIsTheUpdatedContractIDToShow && (
              <Tooltip title="Show change history">
                <IconButton
                  classes={{ root: classes.IconButton }}
                  size={"small"}
                  onClick={() => handleChangeLog(rowData)}
                // onClick={() =>
                //   history.push(
                //     "/contract-setup/compare/" + rowData.contract_number
                //   )
                // }
                >
                  <ChangeCircleIcon />
                </IconButton>
              </Tooltip>
            )}

            {sessionStorage.getItem("application") === "Supplier Chargeback" && (
              <Tooltip title="EDI Download">
                <IconButton
                  classes={{ root: classes.IconButton }}
                  size={"small"}
                  onClick={() => ediDownload(rowData.id)}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div style={{ marginLeft: 5, borderLeft: "1px solid lightgray" }}>
            {(secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role.rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_contract-" + sessionStorage.getItem("application")
                ) &&
              <IconButton
                onClick={() => deleteCon(rowData.id, rowData.contract_number)}
                classes={{ root: classes.IconButton }}
                style={{
                  color:
                    rowData.approval_status === "acc" ||
                      rowData.approval_status === "submit_approval"
                      ? "white"
                      : "#B3B3B3",
                }}
                size={"small"}
                // disabled={
                //   Math.abs(rowData.accrual_amount) != 0 ||
                //     Math.abs(rowData.payment_amount) != 0
                //     ? true
                //     : false
                // }
                disabled={
                  rowData.approval_status === "acc" ||
                    rowData.approval_status === "submit_approval"
                    ? true
                    : false
                }
              >
                <DeleteOutline />
              </IconButton>
            )}

            {rowData.update_contract_id ? (
              <IconButton
                onClick={() =>
                  changeRequestPage(rowData.id, rowData.update_contract_id)
                }
                classes={{ root: classes.IconButton }}
                style={{ color: "green" }}
                size="large">
                <Announcement />
              </IconButton>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    },
  ];

  function handleDialog(bool) {
    if (bool === "No") {
      setOpenConfirm(true);
    } else {
      if (actionType == 'clone') {
        var data = { 'internal_description': internalDesc }
        props.cloneContract(selectedContractNumber, data);
      }
      else
        props.deleteContract(selectedContractNumber);
      setOpenConfirm(false);
      setInternalDesc('')
      setActionType('')
    }
  }
  const ediDownload = (id) => {
    props.ediUpload(null, "download", id);
  };
  const ediUpload = () => {
    inputFile.current.click();
  };
  function checkEditingStatus(id) {
    let data = {
      "id": id,
      "lock": "contract_data"
    }
    props.getAcquireLockDetails(data, 'contract_data')
  }
  const handleChangeLog = (data) => {
    console.log(data)
    var formData = {
      "contract_number.in": data.contract_number,
      "current_zone": moment.tz.guess() ? moment.tz.guess() : '',
    };

    sessionStorage.setItem("ContractChangeReportsData", JSON.stringify(formData));
    sessionStorage.setItem("ContractChangeReportType", JSON.stringify('all-changes'));
    props.runcontractChangeReports(1, 0, 'all-changes');
  }
  const handleDrop = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    props.ediUpload(data);
    event.target.value = "";
  };
  function handleInternalDescription(newValue) {
    setInternalDesc(newValue);
  }
  function handleDialogClose() {
    setOpenConfirm(false)
    setActionType('')
  }
  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        spinner={<HashLoader />}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        className={classes.spinner}
      >

        <Dialog open={openConfirm}>
          <DialogContent
            classes={{
              root: classes.dialogContent,
            }}
          >
            <Typography variant="h4">
              {actionType == 'clone' ?
                <DialogContentText>
                  <TextInputWithLabel mandatory classes={{ root: classes.fontSetting }}
                    heading='Internal Description' placeholder='Internal Description'
                    twoline='true' {...props} onChange={handleInternalDescription}
                  />

                </DialogContentText>
                :
                <DialogContentText>
                  Are you sure you want to delete contract number{" "}
                  {currentDeleteContractNumberDisplay} ?
                </DialogContentText>
              }
            </Typography>
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.dialog,
            }}
          >
            <Button
              onClick={handleDialogClose}
              autoFocus
              color="primary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={() => handleDialog("Yes")}
              color="primary"
              autoFocus
              variant="contained"
              disabled={actionType == 'clone' ? !internalDesc : false}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: '-0.5rem' }}>

          <Typography variant="h1" color='primary' style={{ marginTop: "5px" }} >
            {" "}
            {sessionStorage.getItem("application") === "Sales Commission"
              ? "Incentive Plan Setup"
              : "Contract Setup"}{" "}
          </Typography>
          <div
            className={clsx({
              [classes.row]: isDesktop,
            })}
          >
            {(
              <div>
                {sessionStorage.getItem("application") ===
                  "Customer Chargeback" && (
                    <Fab aria-label="edit" variant="extended"
                      size='medium'
                      classes={{ root: classes.fabContainer }}
                      className={classes.fabContainer}
                      onClick={ediUpload}
                    >
                      <CloudUpload color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>EDI Upload</Typography>
                    </Fab>
                  )}
                {
                  secureLocalStorage.getItem("dYtz").role &&
                  secureLocalStorage.getItem("dYtz").role.rights &&
                  secureLocalStorage
                    .getItem("dYtz")
                    .role.rights.includes(
                      "get_contracts-" + sessionStorage.getItem("application")
                    ) &&
                  <Fab aria-label="edit" variant="extended"
                    size='medium'
                    className={classes.fabContainer}
                    onClick={handleRefresh}
                  >
                    <Refresh color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Refresh</Typography>
                  </Fab>}
                {
                  secureLocalStorage.getItem("dYtz").role &&
                  secureLocalStorage.getItem("dYtz").role.rights &&
                  secureLocalStorage
                    .getItem("dYtz")
                    .role.rights.includes(
                      "get_contracts-" + sessionStorage.getItem("application")
                    ) &&
                  <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={searchContract}
                  >
                    <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                  </Fab>}
                {

                  secureLocalStorage.getItem("dYtz").role &&
                  secureLocalStorage.getItem("dYtz").role.rights &&
                  secureLocalStorage
                    .getItem("dYtz")
                    .role.rights.includes(
                      "mass_update_contract-" + sessionStorage.getItem("application")
                    ) &&
                  <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={massUpdate}
                  >
                    <Update color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Mass Update</Typography>
                  </Fab>}
                {/* {sessionStorage.getItem('application') === 'Supplier Chargeback' &&
                                                <Button
                                                    variant="contained"
                                                    style={{ marginRight: 5 }}
                                                    classes={{
                                                        startIcon: !isDesktop && classes.startIcon,
                                                        root: !isDesktop && classes.container
                                                    }}
                                                    size={'small'}
                                                    onClick={ediDownload}
                                                    className={classes.buttonCreate}
                                                >
                                                    EDI Download
                                                </Button>
                                            } */}
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(e) => handleDrop(e)}
                />

                {
                  secureLocalStorage.getItem("dYtz").role &&
                  secureLocalStorage.getItem("dYtz").role.rights &&
                  secureLocalStorage
                    .getItem("dYtz")
                    .role.rights.includes(
                      "post_contract-" + sessionStorage.getItem("application")
                    ) &&
                  <Fab aria-label="edit" variant="extended"
                    size='medium'
                    className={classes.fabContainer}
                    // onClick={() => runQuery()}
                    onClick={() => setOpen(true)}
                  >
                    <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                  </Fab>}
                <input type='file'
                  accept=".xlsx, .xls, .csv"
                  id='file' ref={inputFile} style={{ display: 'none' }}
                  onChange={(e) => handleDrop2(e)} />
                <Fab aria-label="edit" variant="extended"
                  size='medium'
                  className={classes.fabContainer}
                  onClick={handleSheetDownload}
                >
                  <DownloadForOfflineRounded color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Download</Typography>
                </Fab>
                {sessionStorage.getItem("application") === "Pricing" && (
                  <Fab
                    aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={addContract}
                  >
                    {isDesktop ? (
                      <><LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography></>
                    ) : (
                      ""
                    )}
                  </Fab>
                )}
                {sessionStorage.getItem("application") !== "Pricing"
                  &&
                  secureLocalStorage.getItem("dYtz").role &&
                  secureLocalStorage.getItem("dYtz").role.rights &&
                  secureLocalStorage
                    .getItem("dYtz")
                    .role.rights.includes(
                      "post_contract-" + sessionStorage.getItem("application")
                    ) &&
                  <Fab
                    aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={addContract}
                  >
                    {isDesktop ? secureLocalStorage.getItem("dYtz").role &&
                      secureLocalStorage.getItem("dYtz").role.rights &&
                      secureLocalStorage
                        .getItem("dYtz")
                        .role.rights.includes(
                          "post_contract-" + sessionStorage.getItem("application")
                        ) && (
                        sessionStorage.getItem("application") ===
                          "Sales Commission" ? (
                          <><LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography></>
                        ) : (
                          <><LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography></>
                        )
                      ) : secureLocalStorage.getItem("dYtz").role &&
                      secureLocalStorage.getItem("dYtz").role.rights &&
                      secureLocalStorage
                        .getItem("dYtz")
                        .role.rights.includes(
                          "post_contract-" + sessionStorage.getItem("application")
                        ) && (
                      ""
                    )}
                  </Fab>
                }
              </div>
            )}
          </div>
        </div>
        {/*</div>*/}

        {dataRows && dataRows.length > 0 ? (
          dataRows &&
          dataRows.length > 0 && (
            <div>
              <MaterialTable
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        height: "0px",
                        marginTop: '-1rem'
                      }}
                    ></div>
                  ),
                }}
                title={" "}
                editable={true}
                icons={tableIcons}
                columns={columns}
                data={dataRows}
                style={{}}
                options={{
                  search: false,
                  sorting: true,
                  emptyRowsWhenPaging: false,
                  // headerStyle: theme.mixins.MaterialHeader,
                  // cellStyle: theme.mixins.MaterialCell,
                  // filtering: true,
                  headerStyle: {
                    fontSize: '.90rem',
                    whiteSpace: 'nowrap',
                    padding: '5px 0px 5px 10px',
                    textAlign: 'center',
                    color: 'white',
                    borderRight: "1px solid #EEEEEE",
                  },
                  filtering: true,
                  cellStyle: {
                    fontSize: '.90rem',
                    textAlign: "center",
                    borderRight: "1px solid #EEEEEE",
                    color: "black !important",
                    padding: '5px 0px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 300,
                    width: 80,
                    minWidth: 50,

                  },
                  pageSize: 15,
                  pageSizeOptions: [
                    15,
                    20,
                    50,
                    { value: dataRows.length, label: "Show all" },
                  ],
                }}
              />

            </div>
          )
        ) : (
          <div>There is no data to show now.</div>
        )}
        {/*</LoadingOverlay>*/}
        {dialog &&
          <MessageDialogue open={dialog} messageList={errorList} onChange={handleError} />
        }
      </LoadingOverlay>
      <Dialog open={open}
        aria-labelledby="simple-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel}
            style={{
              height: '50px',
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            Select Format
          </FormLabel>
          <Select
            value={format}
            onChange={(e) => setFormat(e.target.value)}
            displayEmpty
            className={clsx({
              [classes.select]: true
            })}
            classes={{
              selectMenu: classes.selectedItem
            }}
            style={{ textTransform: "capitalize" }}
          >
            {/* <MenuItem value={'Fixed Format'} key={'Fixed Format'} style={{ textTransform: 'capitalize' }}> Fixed Format </MenuItem> */}
            <MenuItem value={'Standard Format'} key={'Standard Format'} style={{ textTransform: 'capitalize' }}> Standard Format </MenuItem>                  </Select>
        </div>
        <DialogActions>
          <Button onClick={cancelEdit} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handleFormat} disabled={format ? false : true}>
            Submit
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
};

ContractSetupTable.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    contractablesdata: state.addContractData.contractAllListData,
    loading: state.addContractData.loading,
    contractTabledataComplete: state.addContractData.contractListDataComplete,
    updatedContractList: state.addContractData.updatedContractAllList,
    uploadMessage: state.addMultipleConfigurationData.massUploadMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckContractsTable: (filter, type) => dispatch(getContractsTableDataList(null, null, type, filter)),
    cloneContract: (id, data) => dispatch(cloneContract(id, data)),
    deleteContract: (id) => dispatch(deleteContract(id)),
    getcontractdetails: (id, status, type) =>
      dispatch(getContractDetailsData(id, status, type)),
    getcontractViewdetails: (id) => dispatch(getContractViewData(id)),
    downloadSheet: (data) => dispatch(downloadSheet(data, "contracts")),
    changeRequestDetails: (id, updatedId) =>
      dispatch(changeRequestDetails(id, updatedId)),
    getAccessData: () => dispatch(getAccessData()),
    updatedContractsList: (data) => dispatch(updatedContractsAllList(data)),
    uploadEligibilityRulesClear: () => dispatch(uploadEligibilityRulesClear()),
    downloadAllContracts: () => dispatch(downloadAllContracts()),
    fpHistory: (id, type) => dispatch(fpHistory(id, 1, 10, type)),
    ediUpload: (data, type, id) => dispatch(ediUpload(data, type, id)),
    uploadMassFile: (appType, data, version) => dispatch(uploadMassFile(appType, data, version)),
    onSubmit: (data) => dispatch(getContractsTableDataBySearchEligibilty(data)),
    getAcquireLockDetails: (data, type) => dispatch(getAcquireLockDetails(data, type)),
    runcontractChangeReports: (pagination, limit, type) => dispatch(runcontractChangeReports(pagination, limit, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractSetupTable);
