import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { createQuota, getQuotaSingleItem, getLblDispDesFieldValue } from '../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { LabelText} from '../../../components/Inputs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import BeatLoader from "react-spinners/BeatLoader";
import MasterDataComponent from '../../../components/Inputs/FilledComponents/MasterDataComponent';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from 'react-spinners';
var editableId=''
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: '20px 10px 0px 10px',
        backgroundColor: 'white',
        marginTop: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
}));

const  ViewQuotaManagement = props => {
    const classes = useStyles();
    const [ViewID, setViewID] = React.useState(0);
    const [externalFields, setExternalFields] = React.useState([]);
    const [externalData, setExternalData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        setViewID(appId)
        if (appId)
            props.quotaSingleItem(appId);
        var data = { "data_source": "quota_data" };
        props.loadSourceDataType(data, 'post');
    }, []);

    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_details)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    if (item.mandatory || item.sort_details) {
                        newArray.push(item)
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setExternalFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.tableData])
    useEffect(() => {
        if (props.quotaData) {
            setViewID(props.quotaData.ID)
            setExternalData(props.quotaData)
        }
    }, [props.quotaData])

    function handleSubmit() {
        if (externalData['start_date'])
            externalData['start_date'] = Moment.utc(externalData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        if (externalData['end_date'])
            externalData['end_date'] = Moment.utc(externalData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        props.onSubmitView(externalData, ViewID)
        handleClearAll();
    }

    function handleClearAll() {
        setExternalData({})
    }
    const handleExternalData = (value, key, type) => {
        if (type == 'reference' && value) {
            value = value.key
        }
        else {
            if (key == 'quota_value' && value)
                value = parseFloat(value)
        }
        setExternalData({ ...externalData, [key]: value })
    }
    return (
        <LoadingOverlay
            active={props.loadingAPI}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/quota-management'
                    >
                        Quota Management
                    </Link>
                    <Typography color="textPrimary" variant='h4'>View Quota </Typography>
                </Breadcrumbs>
                            <div className={classes.container}>
                                <Grid container spacing={2} className={classes.gridContainer}>
                                    {externalFields && externalFields.length > 0 &&
                                        externalFields.map((item, index) => (

                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                <LabelText classes={{ root: classes.formLabel }} heading={item.current} data={externalData[item.key] ? externalData[item.key] : null} twoline='true' />
                                                </div>
                                            </Grid>

                                        ))
                                    }
                                </Grid>
                </div >
            </div>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.addMultipleConfigurationData.loading,
        quotaData: state.quotaManagementData.quotaSingleData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        quotaSingleItem: (id) => dispatch(getQuotaSingleItem(id)),
        onSubmitView: (data, ViewID) => dispatch(createQuota(data, 'view', ViewID)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotaManagement);