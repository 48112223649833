import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getPerformanceMetricWeightageFormatData,
    deletePerformanceMetricWeightageFormatData,
    uploadPerformanceMetricWeightageFile
} from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeatLoader from "react-spinners/BeatLoader";

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
}));
const PerformanceMetricExcelUpload = props => {
    useEffect(() => {
        props.getPerformanceMetricWeightageFormatData();
    }, []);
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [performanceData, setPerformanceData] = useState([]);

    const handleDrop = (event, id) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadPerformanceMetricWeightageFile(id, data);
    }
    useEffect(() => {
        props.getPerformanceMetricWeightageFormatData();
    }, []);
    useEffect(() => {
        if (props.PerformanceMetricWeightageFormatData) {
            setPerformanceData(props.PerformanceMetricWeightageFormatData)
        }
    }, [props.PerformanceMetricWeightageFormatData]);
    //dynamic query functions
    function runQuery(item) {
        setCurrentEditableID(item.ID);
        inputFile.current.click();
    }
    function editQuery(item) {
        sessionStorage.setItem('performance-metric-query', JSON.stringify(item))
        // props.getCustomerMasterQuery(item.id);
        history.push('/performance-metric-weightage/performance-metric-weightage-excel-upload/edit/' + item.ID);

    }
    function viewQuery(item) {
        //props.getCustomerMasterQuery(item.id);
        sessionStorage.setItem('performance-metric-query', JSON.stringify(item))
        history.push('/performance-metric-weightage/performance-metric-weightage-excel-upload/view/' + item.ID);
    }
    function deleteQuery(item) {
        props.deletePerformanceMetricWeightageFormatData(item.ID);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h2">
                    Performance Metric Weightage Excel Upload</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => history.push('/performance-metric-weightage/performance-metric-weightage-excel-upload/add-query')}
                >
                    {isDesktop ? 'Add Format' : ''}
                </Button>
            </div>
            {//props.productGroupFormatData && props.productGroupFormatData.length > 0 ?
                performanceData && performanceData.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center'>Format ID</TableCell>
                                    <TableCell align='center'>Format</TableCell>
                                    <TableCell align='center'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {performanceData
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.ID} >
                                                <TableCell align='center' style={{ cursor: 'pointer' }}>{item.ID}</TableCell>
                                                <TableCell align='center' style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' width={300}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <VisibilityIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleDrop(e, item.ID)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runQuery(item)}
                                                            size="large">
                                                            <PublishIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => deleteQuery(item)}
                                                            size="large">
                                                            <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        PerformanceMetricWeightageFormatData: state.performanceMetricData.performanceWeightFormatData,
        loading: state.dataSetupData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadPerformanceMetricWeightageFile: (id, file) => dispatch(uploadPerformanceMetricWeightageFile(id, file)),
        //getCustomerMasterQuery: (id) => dispatch(getCustomerMasterQuery(id)),
        deletePerformanceMetricWeightageFormatData: (id) => dispatch(deletePerformanceMetricWeightageFormatData(id)),
        getPerformanceMetricWeightageFormatData: () => dispatch(getPerformanceMetricWeightageFormatData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceMetricExcelUpload);