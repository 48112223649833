import React, { useEffect } from 'react';
import {
    Typography, Switch, Stack, FormControlLabel, FormGroup, Checkbox,
    InputAdornment, IconButton, TextField,
    OutlinedInput, Grid, Button, FormLabel, MenuItem, Select
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ArrowLeft, AddCommentOutlined, Circle, ErrorOutlineOutlined, Close, WarningAmber } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getMaterialFilters } from '../../../../redux/actions';
import { MultiSelectDropdown, MultiSelectDropdownKeyValPromo } from '../../../../components/Inputs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../../Styles/global.css';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: "1px solid #EEEEEE",
        borderRadius: 5,
        // padding: '0px 16px',
        paddingBottom: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        padding: 15,
        borderBottom: '1px solid #EEEEEE'
        // backgroundColor: '#E8E8E8'
    },
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    gridContainer: {
        padding: '15px 16px 0px 16px',
        justifyContent: 'flex-start'
        // display: 'flex'
    },
    subContainer: {
        // padding: '10px 16px 16px 16px',
        alignItems: 'center',
        //marginLeft: 30,
    },
    iconContainer: {
        marginTop: 40
    },
    IconButton: {
        marginTop: 35,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    stickerLabel: {
        textTransform: 'capitalize',
    },
    header: {
        fontSize: theme.typography.h7.fontSize,
        marginRight: 15,
        color: theme.palette.text.grey,
        paddingLeft: 0,
        whiteSpace: 'wrap',
        marginBottom: 0
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    contentSection: {
        marginRight: -20
    },
    navStatusContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '30%',
        marginLeft: 17
    },
    searchBox: {
        backgroundColor: theme.palette.white
    },
    toggleButton: {
        //marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        // height: 34,
        //width: 61,
        //display: 'flex',
        alignItems: 'center',
    },
    // country: {
    //     width: 180,
    //     height: 20
    // },
}));
const StickerHeader = (props) => {

    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([{ option: '' }]);
    const [productLine, setProductLine] = React.useState([]);
    const [country, setCountry] = React.useState('USA');
    const [countries, setCountries] = React.useState([]);
    const [enableDesc, setEnableDesc] = React.useState(true);
    const [externalDesc, setExternalDesc] = React.useState('');
    const [overwriting, setOverwriting] = React.useState({ english: '', french: '' });
    const [stickerDesc, setStickerDesc] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [status, setStatus] = React.useState('');
    const [version, setVersion] = React.useState(1);
    const [promoVariability, setPromoVariability] = React.useState(false);
    const [totalTakerate, setTotalTakerate] = React.useState('');
    const [isTakerate, setIsTakerate] = React.useState('');
    const [isValidate, setIsValidate] = React.useState(true);
    const countryArray = [{ key: 'USA', desc: 'USA' }, { key: 'CA', desc: 'CANADA' }]

    useEffect(() => {
        props.getFilters()
    }, []);

    useEffect(() => {
        setIsValidate(props.isValidate)
    }, [props.isValidate])

    useEffect(() => {
        if (props.takerate !== undefined || props.takerate !== '')
            setTotalTakerate(props.takerate)
        if (props.isTakerate !== undefined || props.isTakerate !== '')
            setIsTakerate(props.isTakerate)
    }, [props.takerate, props.isTakerate]);

    useEffect(() => {
        setStickerDesc(props.stickerName)
        if (props.defaultDivison)
            setProductLine(props.defaultDivison)
    }, [props.stickerName, props.defaultDivison]);

    useEffect(() => {
        setChecked(props.country && props.country == 'CA' ? true : false)
        if (props.country) {
            setCountries([props.country])
            setCountry(props.country)
        }
    }, [props.country]);

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group'])
        }
    }, [props.filterData]);
    useEffect(() => {
        if (props.data) {
            setStickerDesc(props.data.sticker_description)
            setExternalDesc(props.data.external_description)
            setOverwriting(props.data.overwriting)
            setCountry(props.data.country)
            setCountries([props.data.country])
            setChecked(props.data.country && props.data.country == 'CA' ? true : false)
            setProductLine(props.data.product_lines)
            setPromoVariability(props.data.promo_variability)
            setVersion(props.data.version)
            setStatus(props.data.approval_status)
        }
    }, [props.data])
    useEffect(() => {
        var data = {
            "product_lines": productLine,
            "sticker_description": stickerDesc,
            "external_description": externalDesc,
            "overwriting": overwriting,
            "country": country,
            'countries': countries,
            "promo_variability": promoVariability,
            "version": version,
            "approval_status": status,
        }
        props.onChange(data)
    }, [productLine, stickerDesc, externalDesc, overwriting, version, country, countries, promoVariability])

    function handleChange(value) {
        if (value && value.length > 0)
            setProductLine(value)
        else
            setProductLine([])
    }
    function handlePromoVariablility(e) {
        setPromoVariability(e.target.checked)
    }
    function handleReturn() {
        console.log("f" + props.page)
        if (props.page == 'Scenario')
            history.push({ pathname: "/promotion-scenario-setup", data: { page: 'Scenario' } });
        else
            history.push({ pathname: "/promotion-sticker-setup", data: { country: country, productLine: productLine, isVisited: true } })
    }
    function handleOnAdd() {
        setEnableDesc(!enableDesc)
    }

    function handleOnDelete() {
        setEnableDesc(false);
    }
    function handleCountry(event) {
        // setChecked(event.target.checked)
        // if (event.target.checked)
        // setCountry('CA')
        // else
        setCountry(event.target.value)

    }
    function handleCountries(event) {
        if (event && event.length > 0) {
            setCountries(event)
            setCountry(event[0])
        }
        else {
            setCountries([])
        }
    }

    function handleSave() {
        props.onSave();
    }

    function handleStatus(event) {
        setStatus(event.target.value);
    };
    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <div className={classes.navStatusContainer}>
                    <Button className='return-button promo-font' variant="outlined" onClick={handleReturn}
                        startIcon={<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none" className='arrowLeft-icon'>
                            <path d="M5.24355 1.77083L2.01022 5.00417L5.24355 8.2375C5.56855 8.5625 5.56855 9.0875 5.24355 9.4125C4.91855 9.7375 4.39355 9.7375 4.06855 9.4125L0.243554 5.5875C-0.0814459 5.2625 -0.0814459 4.7375 0.243554 4.4125L4.06855 0.5875C4.39355 0.2625 4.91855 0.2625 5.24355 0.5875C5.56022 0.9125 5.56855 1.44583 5.24355 1.77083Z" fill="#4E4E4E" />
                        </svg>}
                    >
                        Return
                    </Button>
                    <Typography color='grey'>{props.page === 'sticker_bank_edit' ? 'STICKER UPDATION' : 'STICKER CREATION'} </Typography>
                    <Typography variant='h5' color='black' style={{ marginTop: 3 }}>
                        <Circle style={{ marginLeft: 15, height: 10, color: 'red' }} />
                        {props.page === 'sticker_bank_edit' ? (status ? status : 'Pending') : 'In Progress'}
                    </Typography>
                </div>

                {/* <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant='h4' color='black'>USA</Typography>
                    <Switch sx={{ m: 1 }} checked={checked} className={classes.toggleButton}
                        onChange={handleCountry} />
                    <Typography variant='h4' color='black'>CANADA</Typography>
                </Stack> */}
            </div>
            <Grid container spacing={1}
                style={{ padding: '15px 0px 0px 30px' }}
            >

                <Grid item md={1.1} xs={12} spacing={1} style={{ marginTop: -12 }} className={classes.subContainer}>
                    <FormLabel className={classes.header}>Product Line </FormLabel>
                    <MultiSelectDropdown brpColor={true} btn={true} onChange={handleChange} data={productLine} listArray={productLinesList} style={{ marginTop: -18 }} />
                </Grid>

                <Grid item md={1.1} xs={12} spacing={1} style={{ marginTop: -12 }} className={classes.subContainer}>
                    <FormLabel className={classes.header}>Regions </FormLabel>
                    {props.page === 'sticker_bank_edit' ?
                        <div className={classes.country}>
                            <Select
                                id={'selected-country'}
                                value={country}
                                onChange={handleCountry}
                                style={{ backgroundColor: '#f5f5f5', marginTop: 2 }}
                            >
                                <MenuItem value={'USA'}>USA</MenuItem>
                                <MenuItem value={'CA'}>CANADA</MenuItem>
                            </Select>
                        </div>
                        :
                        <div className={classes.country}>
                            {/* <MultiSelectDropdown brpColor={true} btn={true} onChange={handleCountries} data={countries} listArray={['USA', 'CA']} style={{ marginTop: -18 }} /> */}
                            <MultiSelectDropdownKeyValPromo brpColor={true} onChange={handleCountries} data={countries} listArray={countryArray} style={{ marginTop: -23 }} />
                        </div>
                    }
                </Grid>

                <Grid item md={7} xs={12} spacing={1} style={{ marginTop: -12 }} className={classes.subContainer}>
                    <FormLabel className={classes.header} style={{ whiteSpace: 'wrap', display: 'flex' }}>STICKER NAME
                        <Typography variant='h5' color='grey'>( This field is automatically fill out while building the sticker )</Typography>
                    </FormLabel>
                    <OutlinedInput
                        value={stickerDesc}
                        onChange={(event) => setStickerDesc(event.target.value)}
                        style={{ width: '100%', height: 37 }}
                        readOnly
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleOnAdd}
                                    edge="end"
                                    color={enableDesc ? 'default' : 'primary'}
                                >
                                    <AddCommentOutlined />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
                <Grid item md={2} xs={12} style={{ marginTop: 11 }} className={classes.subContainer}>
                    <div >
                        <FormControlLabel
                            //value={promoVariability}
                            control={<Checkbox checked={promoVariability}
                                onChange={handlePromoVariablility} />}
                            label="Promo Variability"
                            labelPlacement="end"
                        />
                    </div>
                </Grid>
                <Grid item md={enableDesc ? 2.2 : 3} xs={12} style={{ marginTop: enableDesc ? 20 : 0 }}>
                    <div style={{ display: 'flex' }}>
                        <ErrorOutlineOutlined style={{ marginLeft: 8, marginTop: enableDesc ? 8 : 0, marginRight: 2 }} />
                        <Typography variant='h5' color='black'>You are currently creating sticker {productLine && productLine.length > 0 ? 'for' : ''} {productLine && productLine.join(', ').replace(/,([^,]*)$/, ' and$1')} in {countries && countries.length == 2 ? 'USA and CANADA' : country && country == 'CA' ? 'CANADA' : 'USA'}</Typography>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {totalTakerate > 100 ? <><WarningAmber color='error' style={{ marginLeft: 9 }} /> <Typography variant='h5' color='red'>The total of the takerate options is above 100%   </Typography></> : <Typography variant='h5' color='black' style={{ marginLeft: 34, marginTop: 4 }} >Total takerate is {totalTakerate ? totalTakerate : 0}%   </Typography>}
                    </div>
                </Grid>

                <Grid item md={enableDesc ? 7 : 6.2} xs={12} spacing={1} className={classes.subContainer} style={{ paddingTop: 0 }}>
                    {enableDesc &&
                        <>
                            <FormLabel className={classes.header} style={{ whiteSpace: 'wrap' }}>OVERWRITING STICKER NAME FOR DEALERS</FormLabel>
                            <OutlinedInput
                                placeholder='English Text'
                                value={overwriting && overwriting.english}
                                onChange={(event) => setOverwriting({ ...overwriting, english: event.target.value })}
                                style={{ width: '100%', marginBottom: '5px' }}
                            />
                            <OutlinedInput
                                placeholder='French Text'
                                value={overwriting && overwriting.french}
                                onChange={(event) => setOverwriting({ ...overwriting, french: event.target.value })}
                                style={{ width: '100%' }}
                            />
                        </>
                    }
                </Grid>
                <Grid item md={.5} xs={12}>
                </Grid>
                <Grid
                    item
                    md={2}
                    xs={12}
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"

                >
                    <div style={{ display: 'flex' }}>
                        <Button
                            className='cancel-btn'
                            variant="text"
                            onClick={handleReturn}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginLeft: 10 }}
                            className='create-btn'
                            variant="contained"
                            disabled={totalTakerate <= 100 && isTakerate && stickerDesc && isValidate && productLine && productLine.length > 0 ? false : true}
                            onClick={handleSave}
                        >
                            {props.page === 'sticker_bank_edit' ? 'Update Sticker' : 'Create Sticker'}
                        </Button>
                    </div>
                </Grid>

                {/* {enableDesc &&
                    <>
                        <Grid item md={1.7} xs={12}>
                        </Grid>
                        <Grid item md={6} xs={12} spacing={1} className={classes.subContainer}>
                            <FormLabel className={classes.header} style={{ whiteSpace: 'wrap' }}>EXTERNAL STICKER DESCRIPTION</FormLabel>
                            <OutlinedInput
                                value={externalDesc}
                                onChange={(event) => setExternalDesc(event.target.value)}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={handleOnDelete}
                                size="large">
                                <Close style={{ fontSize: 20 }} />
                            </IconButton>
                        </Grid>
                    </>
                } */}
                {/* <Grid item md={11.8} xs={12} spacing={1} className={classes.subContainer}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            {totalTakerate > 100 ? <><WarningAmber color='error' /> <Typography variant='h5' color='red'>The total of the takerate options is above 100%   </Typography></> : <Typography variant='h5' color='black'>Total takerate is {totalTakerate ? totalTakerate : 0}%   </Typography>}
                            <ErrorOutlineOutlined style={{ marginLeft: 40 }} /> <Typography variant='h5' color='black'>You are currently creating sticker {productLine && productLine.length > 0 ? 'for' : ''} {productLine && productLine.join(', ').replace(/,([^,]*)$/, ' and$1')} in {countries && countries.length == 2 ? 'USA and CANADA' : country && country == 'CA' ? 'CANADA' : 'USA'}</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button style={{ height: 35 }} className='cancel-btn' variant="text" onClick={handleReturn}>Cancel</Button>
                            <Button style={{ marginLeft: 10, height: 35 }} className='create-btn' variant="outlined" disabled={totalTakerate <= 100 && isTakerate && stickerDesc && isValidate && productLine && productLine.length > 0 ? false : true}
                                onClick={handleSave}
                            >{props.page === 'sticker_bank_edit' ? 'Update Sticker' : 'Create Sticker'}</Button>
                        </div>
                    </div>
                </Grid> */}
            </Grid>
            {/* </Grid> */}
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        filterData: state.promotionData.promoFilters,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerHeader);