import React, { useEffect } from 'react';
import {
    Typography, Button, Dialog, DialogActions, DialogContent,
    DialogTitle, DialogContentText, Checkbox, TextField,
    IconButton
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { BalanceOutlined, DoneAll, ErrorOutlineOutlined, WarningAmberRounded } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
const useStyles = makeStyles(theme => ({

    errorIcon: {
        width: 50,
        height: 36,
        border: '.5px solid green',
        borderRadius: '50%',
        background: 'green',
        paddingLeft: 6,
        paddingTop: 4,
        marginTop: 12,
        marginRight: 6,

    },
    box: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 40,
        marginBottom: 40,
    },
    iconBox: {
        width: 10,
        height: 10,
        border: '.5px black'
    }

}));

const AccessScenarioDialog = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const [open, setOpen] = React.useState(true);


    function handleClose() {
        setOpen(false)
    }

    function handleSubmit() {
        setOpen(false)
    }


    return (
        <div className={classes.root}>
            {
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                >
                    <DialogTitle id="dialog-title">
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.errorIcon}><DoneAll style={{ color: 'white' }} /> </div>
                                <Typography fontSize='17px' color='green'>Congratulation you are done reviewing your Scenario!</Typography>
                            </div>
                            <hr></hr>
                            <div className={classes.box}>
                                <div>
                                    <Typography color='grey' >NEED FEEDBACK?</Typography>
                                    <Typography color='grey'>TIME TO BUILD SOME SLIDES</Typography>
                                </div>
                                <IconButton>
                                    <BalanceOutlined />
                                </IconButton>
                            </div>

                            <hr></hr>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: 10 }}>
                        <Button autoFocus onClick={handleClose}>
                            Later
                        </Button>
                        <Button variant='contained' onClick={handleSubmit} >Access Scenario Comparison</Button>
                    </DialogActions>
                </Dialog>
            }
        </div >
    );

};



export default AccessScenarioDialog;