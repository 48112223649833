import React from 'react';
import { SvgIcon } from '@mui/material';

const FinancialIcon = (props) => (
    <SvgIcon {...props}>
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.78 13.049L9.348 8.533l5.58-2.897c1.063 2.533.626 5.34-1.148 7.413z" stroke="#B4BDE9" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.305 3.843L8.987 6.605 9.92.59c1.887.434 3.395 1.552 4.384 3.253z" stroke="#B4BDE9" strokeLinejoin="round" />
            <path d="M6.966 8.325a.5.5 0 00.137.426l4.916 5.012a6.86 6.86 0 01-9.478-.338c-2.721-2.771-2.721-7.273 0-10.044 1.517-1.545 3.425-2.252 5.511-2.053L6.966 8.325z" stroke="#B4BDE9" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
)
export default FinancialIcon;
