import React, { useEffect } from "react";
import Moment from "moment";
import clsx from "clsx";
import { makeStyles, useTheme } from  "@mui/styles";
import {
  Grid,
  Select,
  MenuItem,
  Button,
  TableCell,
  Typography,
  TableRow,
  Checkbox,
  ListItemText,
  Input,
  FormLabel,TextField
} from "@mui/material";
import {
  getCustomerMasterData,
  claimErrorCount,
  getDashboardCount,
} from "../../../redux/actions";
import { connect } from "react-redux";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContractCell from "./components/ContractCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider,DatePicker } from '@mui/x-date-pickers';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '10px 0px 0px 0px',
    marginTop: 5,
        
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
},
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  textInput: {
    border: "1px solid #E0E0E0",
    width: "100%",
    borderRadius: 5,
    color: "#1675e0",
  },
  select: {
    width: 209,
  },
  dropdownAction: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid #ddd",
    //zIndex:1,
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  btn: {
    marginRight: 25,
    padding: "10px,15px",
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: theme.palette.primary.main,
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  fontSetting:{
    fontSize:theme.typography.h3.fontSize
},
selectRoot: {
    padding: '0px 0px 15px 0px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
},
buttonRoot: {
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 28,
  paddingTop: 30
},
button: {
  marginRight: 25,
  width: 140
},
bodyContainer: {
  backgroundColor: theme.palette.white,
  border: '1px solid #EEEEEE',
  borderRadius: 6,
  marginBottom: 23,
  padding:'0.7rem'
},
}));

const SupplierClaimStatus = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [viewBy,setViewBy] = React.useState('');
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 450,
        overflowY: "scroll",
        scrollBehaviour: " smooth",
        MarginTop: 0,
      },
    },
    getContentAnchorEl: null,
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [keyFigureKeyArray, setKeyFigureKeyArray] = React.useState([]);
  const [keyFigureData, setKeyFigureData] = React.useState([]);
  const [keyFigureObject, setKeyFigureObject] = React.useState([]);

  function onAdd(item) {
    setDashboardCountData({});
    setSupplierNumber(selectedSupplier);
    setListOpenSupplier(false);
  }
  function onCancel(item) {
    setListOpenSupplier(false);
    setSelectedSupplier([]);
  }
  const handleStartDate = (e) => {
    setDashboardCountData({});
    setStartDate(e);
    var date =
      "start_date=" +
      Moment(e).format("MM/DD/YYYY") +
      "&end_date=" +
      Moment(endDate).format("MM/DD/YYYY");
  };
  const handleEndDate = (e) => {
    setDashboardCountData({});
    setEndDate(e);
    var date =
      "start_date=" +
      Moment(startDate).format("MM/DD/YYYY") +
      "&end_date=" +
      Moment(e).format("MM/DD/YYYY");
  };
  useEffect(() => {
    if (
      props.dropdownDataCR &&
      props.dropdownDataCR.records &&
      props.dropdownDataCR.records[0]
    ) {
      if (props.dropdownDataCR.records[0].field_id === "key_figure_name") {
        setKeyFigureKeyArray(
          props.dropdownDataCR.records[0].drop_down_value_keys
        );
        setKeyFigureData(props.dropdownDataCR.records[0].drop_down_value_keys);
        setKeyFigureObject(props.dropdownDataCR.records[0]);
      }
    }
  }, [props.dropdownDataCR]);

  const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
  const [customerNumberList, setCustomerNumberList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleSubmit = () => {
    setApplyPressed(true);
    var data = {
      start_date: startDate,
      end_date: endDate,
      channel_partner_id: supplierNumber.length> 0 ? supplierNumber : [],
      stat_level: statLevel,
      application_type: "supplier_chargeback",
    };
    var sd = startDate.format("MM/DD/YYYY");
    var ed = endDate.format("MM/DD/YYYY");
    props.loadDashboardCount(data, viewBy, null, null, "supplier");
    handleClear()
  };
  const handleClear=()=>{
    setStartDate(null)
    setEndDate(null)
    setStatLevel('')
    setViewBy('')
    setSupplierNumber([])
}
  const [headerArray, setHeaderArray] = React.useState([]);
  const [historicalTrendDataAPI, setHistoricalTrendDataAPI] = React.useState(
    []
  );
  useEffect(() => {
    setHistoricalTrendDataAPI(props.historicalTrendsData);
    setHeaderArray([
      "previous_incentive_basis",
      "current_incentive_basis",
      "incentive_basis_variance",
      "previous_incentive_amounts",
      "current_incentive_amounts",
      "incentive_amounts_variance",
      "previous_incentive_ratio",
      "current_incentive_ratio",
      "incentive_ratio_variance",
    ]);
  }, [props.historicalTrendsData]);
  //supplier number
  useEffect(() => {
    props.getCustomerMasterData();
  }, []);
  const [statLevel, setStatLevel] = React.useState("aggregate");
  const [supplierNumber, setSupplierNumber] = React.useState([]);
  const [supplierNameArray, seSupplierNameArray] = React.useState([]);
  const [
    supplierMasterArrayMaterialNumber,
    setSupplierMasterArrayMaterialNumber,
  ] = React.useState([]);
  const [listOpenSupplier, setListOpenSupplier] = React.useState("");
  const [selectedSupplier, setSelectedSupplier] = React.useState([]);
  function handleOpenSupplier() {
    setSelectedSupplier(supplierNumber);
    setListOpenSupplier(true);
  }
  const handleSupplierNumber = (event, value) => {
    let newSelected = [];
    const selectedIndex = selectedSupplier.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSupplier, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSupplier.slice(1));
    } else if (selectedIndex === selectedSupplier.length - 1) {
      newSelected = newSelected.concat(selectedSupplier.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSupplier.slice(0, selectedIndex),
        selectedSupplier.slice(selectedIndex + 1)
      );
    }
    setSelectedSupplier(newSelected);
  };
  useEffect(() => {
    if (
      props.customerMasterData &&
      props.customerMasterData.records &&
      props.customerMasterData.records.length > 0
    ) {
      var tempArray = [];
      var tempNameArray = [];
      props.customerMasterData.records.map((item) => {
        tempArray.push(item.customer_number);
        tempNameArray.push(item.customer_name);
      });
      setSupplierMasterArrayMaterialNumber(tempArray);
      seSupplierNameArray(tempNameArray);
    }
  }, [props.supplierMasterData]);
  const handleCell = (type) => {
    if (type === "rejected") {
      var sd = startDate.format("MM/DD/YYYY");
      var ed = endDate.format("MM/DD/YYYY");
      props.claimErrorCount(sd, ed, supplierNumber);
    }
  };
  const [dashboardCountData, setDashboardCountData] = React.useState({});
  const [applyPressed, setApplyPressed] = React.useState(false);
  useEffect(() => {
    setDashboardCountData(props.dashboardCountData);
  }, [props.dashboardCountData]);
  const handleStatLevel = (e) => {
    setDashboardCountData({});
    setStatLevel(e.target.value);
  };
  return (
    <div className={classes.root}>
      <div className={classes.row}>
      <Typography variant="h1" color='primary' style={{ marginLeft: 16 }}> Claim Status</Typography>
      </div>
      <div className={classes.bodyContainer}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <div className={classes.selectRoot}>
                <FormLabel classes={{root:classes.fontSetting}} required >
                  Start Date
                </FormLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          padding: '1px 11px 1px 11px',                          
                          alignSelf: 'center',
                          alignItems: 'center',
                          border: '1px solid #E0E0E0',
                          width: '100%',
                          borderRadius: 5,
                          color: '#1675e0'
                        },
                      }}
                      value={startDate}
                      onChange={handleStartDate}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
            <div className={classes.selectRoot}>
                <FormLabel classes={{root:classes.fontSetting}} required >
                  End Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          padding: '1px 11px 1px 11px',                          
                          alignSelf: 'center',
                          alignItems: 'center',
                          border: '1px solid #E0E0E0',
                          width: '100%',
                          borderRadius: 5,
                          color: '#1675e0'
                        },
                      }}
                      value={endDate}
                      onChange={handleEndDate}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                      minDate={startDate ? startDate : false}
                    />
                  </Grid>
                </LocalizationProvider>
                      </div>
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingRight: 15 }} 
            // tyle={{marginTop:'-1rem'}}
             >
              <div className={classes.selectRoot}>
                <FormLabel classes={{root:classes.fontSetting}} >
                  Channel Partner ID
                </FormLabel>
                <Select
                  value={supplierNumber}
                  displayEmpty
                  MenuProps={{ ...MenuProps, autoFocus: true }}
                  onOpen={handleOpenSupplier}
                  onClose={() => setListOpenSupplier(false)}
                  open={listOpenSupplier}
                  renderValue={(user) => {
                    let name = "";
                    name = user.toString().replace(/_/g, " ");
                    return (
                      <div
                        style={{
                          textTransform: "capitalize",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {name}
                      </div>
                    );
                  }}
                  style={{ maxHeight: "50px", width: '100%'}}
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                 
                  multiple
                >
                  {supplierMasterArrayMaterialNumber?.map((item) => {
                    return (
                      <MenuItem
                        value={item}
                        key={item}
                        style={{ textTransform: "capitalize" }}
                        onClick={(event) => handleSupplierNumber(event, item)}
                      >
                        <Checkbox
                          color="primary"
                          checked={selectedSupplier.indexOf(item) > -1}
                        />
                        <ListItemText style={{marginTop:10}}
                          primary={
                            supplierNameArray[
                              supplierMasterArrayMaterialNumber.indexOf(item)
                            ] +
                            "(" +
                            item +
                            ")"
                          }
                        />
                      </MenuItem>
                    );
                  })}
                  <div className={classes.dropdownAction}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.btn}
                      onClick={() => onCancel("supplier")}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={() => onAdd("supplier")}
                    >
                      Apply
                    </Button>
                  </div>
                </Select>
                </div>
            </Grid>
            <Grid item md={6} xs={12}>
            <div className={classes.selectRoot}>
                <FormLabel classes={{root:classes.fontSetting}} required >
                  Stat Level
                </FormLabel>
                <Select
                  value={statLevel}
                  onChange={handleStatLevel}
                  displayEmpty
                  style={{ maxHeight: "50px", width: '100%'}}
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                >
                  <MenuItem value={"aggregate"} key={1}>
                    Aggregate
                  </MenuItem>
                  <MenuItem value={"channel_partner_id"} key={2}>
                    Channel Partner ID
                  </MenuItem>
                </Select>
                </div>
            </Grid>   
            <Grid
            item
            md={6}
            xs={12}
            classes={{ root: classes.gridContainer }}
        >
            <div className={classes.selectRoot}>
            <FormLabel className={classes.chargeBackLabel} required>
                View By
            </FormLabel>
            <Select
            value={viewBy}
            onChange={(e) => setViewBy(e.target.value)}
            MenuProps={{ ...MenuProps, autoFocus: true }}
            displayEmpty
            className={clsx({
                [classes.select]: true
            })}
            classes={{
                selectMenu: classes.selectedItem
            }}
            style={{ maxHeight: "50px", width: '100%'}}
            >
          
                <MenuItem value={'claim_value'} key={'claim_value'}> Claim Value </MenuItem>
                <MenuItem value={'claim_lines'} key={'claim_lines'}> Claim Lines </MenuItem>
        </Select>     
        </div>            
        </Grid>       
          </Grid>
          </div>
          <div className={classes.buttonRoot}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.button}
              disabled={!startDate || !endDate || !statLevel||!viewBy ? true : false}
            >
              Run
            </Button>
            </div>
          {applyPressed && (
      
          <div style={{width:'100%',marginTop:'1rem'}}>
            {statLevel === "aggregate" &&
            dashboardCountData &&
            dashboardCountData[0] &&
            Object.keys(dashboardCountData[0]).length > 0 ? (
              <div style={{ flex: 1, display: "flex" }}>
                <div
                  style={{ flex: 0.2, padding: 4 }}
                  classes={{ root: classes.contractCellContainer }}
                >
                  <ContractCell
                    className={classes.activeContracts}
                    heading={"Total Processed"}
                    value={
                      dashboardCountData[0].processed
                        ? dashboardCountData[0].processed
                        : 0
                    }
                  />
                </div>
                <div
                  style={{ flex: 0.2, padding: 4 }}
                  classes={{ root: classes.contractCellContainer }}
                >
                  <ContractCell
                    className={classes.waitingApprovals}
                    heading={"In Progress"}
                    value={
                      dashboardCountData[0].in_progress
                        ? dashboardCountData[0].in_progress
                        : 0
                    }
                  />
                </div>
                <div
                  style={{ flex: 0.2, padding: 4 }}
                  classes={{ root: classes.contractCellContainer }}
                >
                  <ContractCell
                    className={classes.newContracts}
                    heading={"Adjusted"}
                    value={
                      dashboardCountData[0].waiting_for_information
                        ? dashboardCountData[0].waiting_for_information
                        : 0
                    }
                  />
                </div>
                <div
                  style={{ flex: 0.2, padding: 4 }}
                  classes={{ root: classes.contractCellContainer }}
                >
                  <ContractCell
                    className={classes.expiredContracts}
                    heading={"Rejected"}
                    value={
                      dashboardCountData[0].rejected
                        ? dashboardCountData[0].rejected
                        : 0
                    }
                    onClick={() => handleCell("rejected")}
                  />
                </div>
                <div
                  style={{ flex: 0.2, padding: 4 }}
                  classes={{ root: classes.contractCellContainer }}
                >
                  <ContractCell
                    className={classes.totalContracts}
                    heading={"Total Claim Lines"}
                    value={
                      dashboardCountData[0].total
                        ? dashboardCountData[0].total
                        : 0
                    }
                  />
                </div>
              </div>
            ) : statLevel === "channel_partner_id" &&
              dashboardCountData &&
              dashboardCountData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#445AC7", color: "white" }}
                    >
                      <TableCell align="right">Approved</TableCell>
                      <TableCell align="right">Adjusted</TableCell>
                      <TableCell align="right">In Progress</TableCell>
                      <TableCell align="right">Rejected</TableCell>
                      <TableCell align="right">Total Claim Lines</TableCell>
                      <TableCell align="right">Paid</TableCell>                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardCountData.map((item) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {item.approved}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.adjusted}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.in_progress}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.rejected}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.total}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {item.processed}
                          </TableCell>                        
                        </TableRow>
                      );
                    })}
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography classes={{root:classes.fontSetting}} variant="h4">
                There is no data to show now.
              </Typography>
            )}
          </div>
        
      )}
     
     
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    historicalTrendsData: state.initialData.historicalTrends,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    dropdownDataCR:
      state.addMultipleConfigurationData.multipleConfigurationDataKF,
    dropdownData: state.addMultipleConfigurationData.attributeNameData,
    customerMasterData: state.customerData.customerMasterData,
    allowedAppsData: state.initialData.allowedApps,
    promPlanningSalesInc: state.initialData.promPlanningSalesInc,
    supplierMasterData: state.customerData.supplierMasterData,
    dashboardCountData: state.initialData.dashboardCountData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
    getCustomerMasterData: (pagination, limit) =>
      dispatch(getCustomerMasterData(pagination, limit)),
    claimErrorCount: (sd, ed) => dispatch(claimErrorCount(sd, ed)),
    loadDashboardCount: (sd, ed, supplier, type, page) =>
      dispatch(getDashboardCount(sd, ed, supplier, type, page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierClaimStatus);
