import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Select,
    MenuItem,
    Card,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { createMembershipContractGroup, getMembershipContractGroupById,getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import MasterDataComponent from '../../components/Inputs/FilledComponents/MasterDataComponent';
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize:theme.typography.h3.fontSize,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    }
}));

const EditMembershipContractGroup = props => {
    const classes = useStyles();
    const history = useHistory();
    const [editID,setEditID] = React.useState(0);
    const [contractGroup,setContractGroup] = React.useState('');
    const [contractGroupValue,setContractGroupValue] = React.useState('');
    const [contractGroupDescription,setContractGroupDescription] = React.useState('');
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getMembershipDetails(appId);
    }, []);
    useEffect(()=>{
        if(props.membership) {
            setEditID(props.membership.ID)
            setContractGroup(props.membership.contract_group)
            setContractGroupDescription(props.membership.contract_group_description)
            setContractGroupValue(props.membership.contract_group_value)
        }
    },[props.membership])    
    const handleContractGroup = (e) => {
        setContractGroup(e.target.value);
    }
    const handleContractGroupValue = (e) => {
        setContractGroupValue(e.target.value);
    }
    const handleContractGroupDescription = (e) => {
        setContractGroupDescription(e.target.value);
    }
    function handleSubmit() {
        var data = {
            "contract_group":contractGroup,
            "contract_group_value":contractGroupValue,
            "contract_group_description":contractGroupDescription,
        };
        props.onSubmitEdit(data,editID);
        handleClear();
    }
    function handleClear() {
        setContractGroup('')
        setContractGroupDescription('')
        setContractGroupValue('')
    }
    function handleClearAll() {
        handleClear();
    }  
    return (
        <div className={classes.root}>
        <Typography color="primary" variant='h1'>Edit Contract Group</Typography>
        <div className={classes.bodyContainer}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2} style={{ padding: '16px 25px 0px 25px' }}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel required className={classes.formLabel}>Contract Group</FormLabel>
                                    <OutlinedInput
                                        value={contractGroup}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleContractGroup}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel  className={classes.formLabel}>Contract Group Value</FormLabel>
                                    <OutlinedInput
                                        value={contractGroupValue}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleContractGroupValue}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>Contract Group Description</FormLabel>
                                    <OutlinedInput
                                        value={contractGroupDescription}
                                        inputProps={{ min: "0", step: "1" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleContractGroupDescription}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Card>
            <div className={classes.buttonRoot}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                    Clear
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                    disabled={ contractGroup ? false : true}
                >
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Save'
                    }
                </Button>
            </div>
        </div >
    </div>
    );
};
const mapStateToProps = state => {
    return {
        membership: state.customerData.membershipContractGroupSingleData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipDetails: (id) => dispatch(getMembershipContractGroupById(id)),
        onSubmitEdit: (data, editID) => dispatch(createMembershipContractGroup(data, editID,'edit')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMembershipContractGroup);