import palette from "./palette";

export default {
  fontFamily: `Roboto Slab, serif`,
  h1: {
    color: palette.text.primary,
    fontSize: "1.3em",
    fontWeight: 200,
  },
  h2: {
    color: palette.text.primary,
    fontSize: "1.2em",
  },
  h3: {
    color: palette.primary.main,
    fontSize: "1.1em",
  },
  h4: {
    color: palette.text.primary,
    fontSize: "0.90em",//".75em",
  },
  h5: {
    color: palette.text.primary,
    fontSize: "0.8em",
  },
  h6: {
    fontSize: 13,
    color: palette.text.helper,
  },
  subtitle1: {
    color: palette.white,
    fontSize: 36,
  },
  button: {
    textTransform: "none",
  },
  caption: {
    color: "black",
  },
};
