import React, { useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Card, CardHeader, Button, Dialog, IconButton, DialogActions, FormLabel, DialogTitle, DialogContent, Typography, Grid, Box
} from '@mui/material';
import MaterialTable from "material-table";
import Moment from "moment";
import { tableIcons } from "../../../components/Icons/TableIcons";
import { styled } from '@mui/material/styles';
import { Close, Article } from '@mui/icons-material';
import { Link } from '@mui/material';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: 29,
    marginTop: 16
  },
  headerRoot: {
    margin: 0,
    padding: '10px 0 15px 0'
  },
  dialogTitleRoot: {
    padding: '9px 24px'
  },
  dialogController: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10
  },
  buttonContainer: {
    fontSize: 12,
  },
  dialogTitle: {
    marginLeft: 5,
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary.main
  }

}));


const DocHistory = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = useState('');
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const columns = [
    {
      field: "current_status",
      title: "Changed Status",
    },
    {
      field: "previous_status",
      title: "Previous Status",
    },
    {
      field: "changed_user",
      title: "Changed By",
    },
    {
      field: "status_changed_at",
      title: "Changed At",
      render: (rowData) => (
        <div>
          {rowData.status_changed_at ?
            Moment.utc(rowData.status_changed_at).format('MM/DD/YYYY hh:mm:ss')
            : ''}
        </div>
      )
    },
    {
      field: "duration",
      title: "Duration",
    },
    {
      field: "submit_comments",
      title: "Comments",
      render: (rowData) => (
        <div style={{ padding: 5, justifyContent: 'center' }}>
          {rowData.submit_comments && rowData.submit_comments.length > 45 ? rowData.submit_comments.slice(0, 45) + '...' : rowData.submit_comments}
          {rowData.submit_comments && rowData.submit_comments.length > 45 && (
            <Link
              component="button"
              variant="body2"
              onClick={(e) => handleToggleExpand(e, rowData.submit_comments)}
              className={classes.buttonContainer}
            >
              Read More
            </Link>
          )}
        </div>
        // <div>
        //   {rowData.submit_comments && rowData.submit_comments.length > 0 ?
        //     rowData.submit_comments.join(",")
        //     : ''}
        // </div>
      )
    },
  ]
  function handleToggleExpand(event, text) {
    event.preventDefault();
    setOpen(true)
    setNotes(text)
  }
  function handleClose() {
    setOpen(false)
  }
  return (
    <Card>
      <CardHeader
        title="HISTORY"
        titleTypographyProps={{ variant: 'h3' }}
        classes={{ root: classes.headerRoot }}
      />
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              style={{
                height: "0px",
              }}
            ></div>
          ),
        }}
        title={" "}
        editable={true}
        icons={tableIcons}
        columns={columns}
        data={props.data}
        style={{ marginTop: '-18px', fontSize: theme.typography.h4.fontSize }}
        options={{
          search: false,
          sorting: true,
          emptyRowsWhenPaging: false,
          headerStyle: theme.mixins.MaterialHeader,
          cellStyle: theme.mixins.MaterialCell,
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [
            10,
            20,
            50,
            { value: props.data.length, label: "Show all" },
          ],
        }}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" className={{ root: classes.dialogTitleRoot }}>
          <Grid container direction="row" style={{ justifyContent: 'space-between' }} >
            <div style={{ display: 'flex' }}><Article />
              <FormLabel className={classes.dialogTitle}>Note</FormLabel>
            </div>
            <Box>
              <IconButton onClick={handleClose} style={{ paddingTop: 5 }} size="large">
                <Close />
              </IconButton>
            </Box>
          </Grid>
        </DialogTitle>


        <DialogContent dividers>
          <Typography gutterBottom>
            {notes}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Card>

  );

};

export default DocHistory;