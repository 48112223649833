import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Typography,
  Link,
  useMediaQuery,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import Moment from "moment";
import { history } from "../../../components/Helpers";
import ChangeCircleIcon from "@mui/icons-material/Timeline";
import {
  nextTier,
  runContractTrends,
  downloadContractTrendsResltsSheet,
} from "../../../redux/actions";
import { MessageDialogue } from "../../MessageDialogue";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import NextPlanOutlinedIcon from "@mui/icons-material/NextPlanOutlined";
import { Tooltip } from "@mui/material";
import {
  Insights,
  AllInclusive,
  NextPlanOutlined,
  SummarizeOutlined,
  SummarizeRounded,
  GetApp,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: "20px 16px",
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  tableCell: {
    paddingLeft: 18,
    fontSize: theme.typography.h4.fontSize,
  },
  spinner: {
    height: "100vh",
  },
}));
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.table.row,
      height: 46,
    },
    "&:nth-of-type(even)": {
      height: 40,
    },
  },
}))(TableRow);
const tableHeads = [
  { id: 0, heading: "Contract Number", align: "left" },
  { id: 1, heading: "Calculation Method" },
  { id: 8, heading: "Contract Type" },
  { id: 2, heading: "Contract Internal Description" },
  { id: 3, heading: "Calculation Start Date" },
  { id: 4, heading: "Calculation End Date" },
  { id: 5, heading: "Calculation Simulation Number" },
  { id: 8, heading: "Incentive Amount" },
  { id: 9, heading: "Tags" },
  { id: 10, heading: "Action" },
];
const CalcSimulationResult = (props) => {
  const classes = useStyles();
  const { className } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  useEffect(() => {
    sessionStorage.removeItem("contractTrendsQuery");
  }, []);
  const handleCalculationAnalysis = (item, type, pge) => {
    var formData = {
      start_date: Moment.utc(item.start_date).format("MM/DD/YYYY"),
      end_date: Moment.utc(item.end_date).format("MM/DD/YYYY"),
      application_type: item.application_type,
      data_source_type: "Direct - Sales Data",
      contract_number: item.contract_number,
      posting_number: item.calculation_simulation_number,
      posting_type: "",
    };
    sessionStorage.setItem("contractTrendsQuery", JSON.stringify(formData));
    props.runContractTrends(formData, type, "integration");
  };
  const handleNextTier = (item) => {
    var formData = {
      contract_number: item.contract_number,
      calculation_simulation_number: item.calculation_simulation_number,
    };
    props.getNextTier(formData);
    history.push({
      pathname: "/next-tier-simulation-result",
      //data: { 'price_type_name': item.price_type_name, 'price_type': item.price_type },
      //id: item.price_list_id
    });
  };
  const [errorList, setErrorList] = React.useState([]);
  const [dialog, setDialog] = React.useState(false);
  useEffect(() => {
    setErrorList(props.calculationSimErrorList);
    if (
      props.calculationSimErrorList &&
      props.calculationSimErrorList.length > 0
    ) {
      setDialog(true);
    }
  }, [props.calculationSimErrorList]);
  function handleError() {
    setDialog(false);
  }
  const handleSheetDownload = (item) => {
    var formData = {
      start_date: Moment.utc(item.start_date).format("MM/DD/YYYY"),
      end_date: Moment.utc(item.end_date).format("MM/DD/YYYY"),
      application_type: sessionStorage.getItem("application"),
      data_source_type: "Direct - Sales Data",
      contract_number: item.contract_number,
      posting_number: item.calculation_simulation_number,
      posting_type: "",
    };
    if (formData) {
      console.log("formd", formData);
      props.downloadSheet(formData);
    }
  };
  return (
    <LoadingOverlay
      active={props.loading}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#64C4B4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "#64C4B4",
        }),
      }}
      spinner={<HashLoader />}
      className={classes.spinner}
    >
      <div>
        <div className={clsx(classes.root, className)}>
          <div
            className={clsx({
              [classes.row]: isDesktop,
            })}
          >
            <Typography variant="h1" color="primary">
              {sessionStorage.getItem("application") &&
                sessionStorage.getItem("application") == "Supplier Chargeback"
                ? "Accrual Simulation Results "
                : "Calculation Simulation Results"}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <SearchInput /> */}
              <div
                onClick={() => {
                  setDialog(true);
                }}
                style={{
                  cursor: "pointer",
                  color: "red",
                  fontSize: theme.typography.h4.fontSize,
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid red",
                  padding: 4,
                  marginLeft: 10,
                }}
              >
                Show Message Summary
              </div>
            </div>
          </div>
          {dialog && (
            <MessageDialogue
              open={dialog}
              messageList={errorList}
              onChange={handleError}
            />
          )}
          {props.calculationResults && props.calculationResults.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeads.map((item) => {
                      return (
                        <TableCell
                          align={item.align ? "left" : "center"}
                          className={classes.tableCell}
                          key={item.id}
                        >
                          {item.heading}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody classes={{ root: classes.table }}>
                  {props.calculationResults &&
                    props.calculationResults.map((item) => {
                      return (
                        <StyledTableRow key={item.contract_number}>
                          <TableCell align="left" className={classes.tableCell}>
                            {item.contract_number}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {item.calculation_method}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {item.contract_type}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {item.contract_internal_description}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {Moment.utc(item.start_date)
                              .format("MM/DD/YYYY")
                              .toString()}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {Moment.utc(item.end_date)
                              .format("MM/DD/YYYY")
                              .toString()}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {item.calculation_simulation_number}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {parseFloat(item.incentive_amount).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {item.tags.toString()}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                            style={{ display: "flex" }}
                          >
                            {/* <Tooltip title='Version 1'>
                            <IconButton
                              classes={{ root: classes.IconButton }}
                              size={"small"}
                              onClick={() =>
                                handleCalculationAnalysis(item, "contractV1")
                              }
                            >
                              <SummarizeOutlined fontSize="small"/>
                            </IconButton>
                            </Tooltip> */}
                            {/*<Tooltip title='Syncfusion'>
                            <IconButton
                              classes={{ root: classes.IconButton }}
                              size={"small"}
                              onClick={() =>
                                handleCalculationAnalysis(
                                  item,
                                  "contractV1",
                                  "sf"
                                )
                              }
                            >
                              <SummarizeRounded fontSize="small" /> sf{" "}
                            </IconButton>
                            </Tooltip> */}
                            <Tooltip title="Details">
                              <IconButton
                                classes={{ root: classes.IconButton }}
                                size={"small"}
                                onClick={() =>
                                  handleCalculationAnalysis(item, "contractV2")
                                }
                              >
                                <SummarizeOutlined fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            {/* {(item.calculation_method == "Tiered Volume" ||
                              item.calculation_method == "Tiered Growth") && (
                                <Tooltip title='Next Tier Result'>
                              <IconButton
                                classes={{ root: classes.IconButton }}
                                size={"small"}
                                onClick={() => handleNextTier(item)}
                              >
                                <NextPlanOutlined fontSize="small"/>
                              </IconButton>
                              </Tooltip>
                            )} */}
                            <Tooltip title="Download">
                              <IconButton
                                classes={{ root: classes.IconButton }}
                                size={"small"}
                                onClick={() => handleSheetDownload(item)}
                              >
                                <GetApp fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="h4">There is no data to show now.</Typography>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    runContractTrends: (formData, type, pge) =>
      dispatch(runContractTrends(formData, type, pge)),
    getNextTier: (formData) => dispatch(nextTier(formData)),
    downloadSheet: (formData) =>
      dispatch(downloadContractTrendsResltsSheet(formData)),
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    calculationResults: state.simulationData.calcSimulationData,
    calculationSimErrorList: state.simulationData.calcSimErrorList,
    tierData: state.simulationData.nextTierData,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalcSimulationResult);
