import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject,
    DataLabel, BarSeries, Category, Legend, Tooltip, ILoadedEventArgs, ChartTheme, Highlight
} from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';

const SyncfusionChart = (props) => {
    const [historicalData, setHistoricalData] = React.useState([]);
    const [targetData, setTargetData] = React.useState([]);
    const [forecastData, setForecastData] = React.useState([]);
    const size = { width: '100%', height: '100%' }
    useEffect(() => {
        var historical = []
        var target = []
        var forecast = []
        if (props.data && props.data.length > 0) {
            props.data.map((item, i) => {
                historical.push({ x: item[props.analysisLevel], y: item.margin_historical_percentage, color: '#449724' })
                target.push({ x: item[props.analysisLevel], y: item.margin_target_percentage, color: '#3e3ef3' })
                forecast.push({ x: item[props.analysisLevel], y: item.margin_forecast_percentage, color: '#F3993E' })
            })
            setHistoricalData(historical)
            setTargetData(target)
            setForecastData(forecast)

        }
    }, [props.data]);
    return (
        <div style={{ width: '100%', height: '100%', padding: 0 }}>
            <ChartComponent id={props.id} style={{ textAlign: "center" }} legendSettings={{ enableHighlight: true }}
                primaryXAxis={{
                    valueType: 'Category',
                    majorGridLines: { width: 0 },
                }}
                primaryYAxis={{
                    labelFormat: '{value}%',
                    edgeLabelPlacement: 'Shift',
                    majorTickLines: { width: 0 },
                    lineStyle: { width: 0 },
                    titleStyle: {
                        display: 'inline-block',
                        width: '180px',
                        overflow: 'hidden'
                    }
                }}
                //size = {size}
                chartArea={{ border: { width: 0 } }}
                width={Browser.isDevice ? '100%' : props.height ? props.height : '85%'}
                height={props.id == 'combined' ? '100%' : '90%'}//{Browser.isDevice ? '100%' : '75%'}
                tooltip={{ enable: true }}
            >
                <Inject services={[BarSeries, DataLabel, Category, Legend, Tooltip, Highlight]} />
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={historicalData} xName='x' yName='y' type='Bar' columnSpacing={0.1} name="Historical" width={2} pointColorMapping='color'
                    >
                    </SeriesDirective>
                    <SeriesDirective dataSource={forecastData} xName='x' yName='y' type='Bar' columnSpacing={0.1} name='Forecast' width={2} pointColorMapping='color' marker={{
                    }}>
                    </SeriesDirective>
                    <SeriesDirective dataSource={targetData} xName='x' yName='y' type='Bar' columnSpacing={0.1} name="Target" width={2} pointColorMapping='color'
                    >
                    </SeriesDirective>
                </SeriesCollectionDirective>
            </ChartComponent>
        </div >
    );
}

export default connect(null, null)(SyncfusionChart);