import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
    navBgColor: {
        backgroundColor: theme.palette.primary.main
    }
}));

const MasterData = (props) => {
    const handleClick = (pge) => {
        history.push(pge)
    }
    const application = sessionStorage.getItem("application");

    const hasRight = (right) => {
        const user = secureLocalStorage.getItem("dYtz");
        return (
            user.role.rights &&
            user.role.rights.includes(`${right}-${application}`)
        );
    };
    const hasCustomerMasterRight = hasRight("get_customer_master");
    const hasMaterialMasterRight = hasRight("get_material_master");
    const hasProductGroupRight = hasRight("get_product_group");
    const hasProductFeatureRight = hasRight("get_product_feature");
    const hasSupplierMasterRight = hasRight("get_supplier_master");
    const hasMembershipRight = hasRight("get_membership");
    const hasEmployeeMasterRight = hasRight("get_employee");
    const hasTerritoryMasterRight = hasRight("get_territory_master");
    const hasAttributesRight = hasRight("get_attributes");
    const hasCurrencyConversionRight = hasRight("get_currency_conversions");
    const hasUoMRight = hasRight("get_uom");
    const hasCustomerXREFRight = hasRight("get_customer_xref");
    const hasMaterialXREFRight = hasRight("get_material_xref");
    const hasContractXREFRight = hasRight("get_mem_create_contract_xref");
    const hasCOTXREFRight = hasRight("get_cot_xref");
    const hasConfigurationRight = hasRight("get_configuration");
    const hasSalesBundleRight = hasRight("get_sales_bundle");
    const hasContractGroupRight = hasRight("get_mem_create_contract_group");
    const hasOnDemandQueryRight = hasRight("get_report");
    const hasContratXREFRight = hasRight("get_contract_xref");
    const hasGPORosterRight = hasRight("get_mem_GPO_roster");
    const hasTierActivationRight = hasRight("get_mem_tier_activation");

    const menuItems = [
        ...(hasCustomerMasterRight ? [{ 'Customer Master': '/customer-master' }] : []),
        {
            'Product Master': [
                ...(hasMaterialMasterRight ? [{ "Material Master": "/material-master" }] : []),
                // { 'Sales Bundle': '/bom' },
                // { 'Product Group': '/product-group' },
                // { 'Product Feature': '/product-feature' },
                ...(hasSalesBundleRight ? [{ "Sales Bundle": "/bom" }] : []),
                ...(hasProductGroupRight ? [{ "Product Group": "/product-group" }] : []),
                ...(hasProductFeatureRight ? [{ "Product Feature": "/product-feature" }] : [])

            ]
        },
        // { 'Supplier Master': '/supplier-master' },
        ...(hasSupplierMasterRight ? [{ 'Supplier Master': '/supplier-master' }] : []),
        {
            'Membership': [
                // { 'Master Roster': '/membership' },
                // { 'Contract XREF': '/membership-contract' },
                // { 'Contract Group': '/membership-contract-group' },
                // { 'On Demand Query': '/dynamic-query' }
                ...(hasMembershipRight ? [{ 'Master Roster': '/membership' }] : []),
                ...(hasContractXREFRight ? [{ 'Contract XREF': '/membership-contract' }] : []),
                ...(hasContractGroupRight ? [{ 'Contract Group': '/membership-contract-group' }] : []),
                ...(hasOnDemandQueryRight ? [{ 'On Demand Query': '/dynamic-query' }] : []),

            ]
        },
        // { 'Employee Master': '/employee' },
        // { 'Territory Master': '/terittory' },
        // { 'Attributes': '/attributes' },
        // { 'Currency Conversion': '/currency-conversion' },
        // { 'UoM': '/uom' },
        ...(hasEmployeeMasterRight ? [{ 'Employee Master': '/employee' }] : []),
        ...(hasTerritoryMasterRight ? [{ 'Territory Master': '/terittory' }] : []),
        {
            'Attributes': [
                ...(hasAttributesRight ? [{ 'Attributes Simple': '/attributes' }] : []),
                { 'Attributes Hierarchy': '/attributes-hierarchy' },
            ]
        },
        ...(hasCurrencyConversionRight ? [{ 'Currency Conversion': '/currency-conversion' }] : []),
        ...(hasUoMRight ? [{ 'UoM': '/uom' }] : []),

        {
            'XREF': [
                // { 'Customer XREF': '/customer-x-master' },
                // { 'Material XREF': '/material-x-master' },
                // { 'Contract XREF': '/contract-xref' },
                // { 'COT XREF': '/cot-xref' },
                ...(hasCustomerXREFRight ? [{ 'Customer XREF': '/customer-x-master' }] : []),
                ...(hasMaterialXREFRight ? [{ 'Material XREF': '/material-x-master' }] : []),
                ...(hasContratXREFRight ? [{ 'Contract XREF': '/contract-xref' }] : []),
                ...(hasCOTXREFRight ? [{ 'COT XREF': '/cot-xref' }] : []),
            ]
        },
        ...(hasConfigurationRight ? [{ 'Configuration': '/master-data-config' }] : []),
    ]
    const subMenuItems = [
        ...(hasGPORosterRight ? [{
            'GPO Roster': [
                { 'Excel Upload': { pathname: '/membership/membership-excel-upload', state: 'MembershipGPORosterFormats' } },
                { 'Roster Search': '/gpo-address-search' }
            ]
        }] : []),
        ...(hasTierActivationRight ? [{
            'Tier Activation': [
                { 'Excel Upload': { pathname: '/membership/membership-excel-upload', state: 'MembershipGPOTierActivationFormats' } },
                { 'Activation Search': '/tier-address-search' }
            ]
        }] : []),
    ];
    const classes = useStyles();
    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}
                    >{Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]} style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                            {Object.keys(item)[0] == 'Membership' && subMenuItems.map(item => {
                                return (
                                    <NavDropdown title={Object.keys(item)[0]} className={classes.navBgColor} style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px' }}>
                                        {Object.values(item)[0].map(e => {
                                            return (
                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                    {Object.keys(e)[0]}
                                                </NavDropdown.Item>
                                            )

                                        })}
                                    </NavDropdown>
                                )
                            })}
                        </NavDropdown>
                    )
                }
            })}
        </Nav >
    );
};
const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MasterData);