import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
    TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createInventoryData, getInventoryDataSingleItem } from '../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
}));

const EditInventoryData = props => {
    const classes = useStyles();
    const [channelPartnerID, setChannelPartnerID] = React.useState('');
    const [channelPartnerName, setChannelPartnerName] = React.useState('');
    const [material, setMaterial] = React.useState('');
    const [startInventory, setStartInventory] = React.useState('');
    const [endInventory, setEndInventory] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [editID, setEditID] = React.useState(0);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.inventorySingleItem(appId);
    }, []);
    useEffect(() => {
        if (props.inventoryData) {
            setEditID(props.inventoryData.ID)
            setChannelPartnerID(props.inventoryData.channel_partner_id)
            setChannelPartnerName(props.inventoryData.channel_partner_name)
            setMaterial(props.inventoryData.material)
            setStartDate(props.inventoryData.start_date)
            setEndDate(props.inventoryData.end_date)
            setStartInventory(props.inventoryData.starting_inventory)
            setEndInventory(props.inventoryData.ending_inventory)
        }
    }, [props.inventoryData])
    const handleStartDate = (value) => {
        setStartDate(value);
    }
    const handleEndDate = (value) => {
        setEndDate(value);
    }
    function handleSubmit(editIndex) {
        var data = {
            "channel_partner_id": channelPartnerID,
            "channel_partner_name": channelPartnerName,
            "material": material,
            "start_date": startDate ? Moment(startDate).local().format('YYYY-MM-DD') : '',
            "end_date": endDate ? Moment(endDate).local().format('YYYY-MM-DD') : '',
            "starting_inventory": parseFloat(startInventory),
            "ending_inventory": parseFloat(endInventory)
        }
        props.onSubmitEdit(data, editID)
    }
    function handleClear() {
        setChannelPartnerID('')
        setChannelPartnerName('')
        setMaterial('')
        setStartInventory('')
        setEndInventory('')
        setStartDate(null)
        setEndDate(null)
    }
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>Edit Inventory </Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formLabel}  >
                                            Channel Partner ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerID}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setChannelPartnerID(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formLabel}  >
                                            Channel Partner Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerName}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setChannelPartnerName(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formLabel}  >
                                            Material
                                        </FormLabel>
                                        <OutlinedInput
                                            value={material}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setMaterial(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formLabel}>
                                            Starting Inventory
                                        </FormLabel>
                                        <OutlinedInput
                                            value={startInventory}
                                            type={'number'}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setStartInventory(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formLabel}>
                                            Ending Inventory
                                        </FormLabel>
                                        <OutlinedInput
                                            value={endInventory}
                                            type={'number'}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setEndInventory(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formLabel} >
                                            Start Date
                                        </FormLabel>
                                        <Grid container justifyContent="space-around">
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={startDate}
                                                    onChange={date => handleStartDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </LocalizationProvider>

                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formLabel}>
                                            End Date
                                        </FormLabel>
                                        <Grid container justifyContent="space-around">
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={endDate}
                                                    onChange={date => handleEndDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    minDate={startDate ? startDate : ''}
                                                    format="MM/DD/YYYY"
                                                />
                                            </LocalizationProvider>

                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                        disabled={channelPartnerID && channelPartnerName && endDate && startDate && startInventory && endInventory ? false : true} >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        inventoryData: state.dataSetupData.inventorySingleData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        inventorySingleItem: (id) => dispatch(getInventoryDataSingleItem(id)),
        onSubmitEdit: (data, editID) => dispatch(createInventoryData(data, 'edit', editID)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditInventoryData);