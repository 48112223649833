export const BasisArray = [
    {sourceDataType:'Direct - Sales Data', transactionType:'SO',evalutionTypeKey:'sales_order_date',evalutionType:'Sales Order Date' },
    {sourceDataType:'Direct - Sales Data', transactionType:'DEL',evalutionTypeKey:'delivery_date',evalutionType:'Delivery Date'},
    {sourceDataType:'Direct - Sales Data', transactionType:'INV',evalutionTypeKey:'billing_date',evalutionType:'Billing Date'},
    {sourceDataType:'Direct - Sales Data', transactionType:'INV',evalutionTypeKey:'pricing_date',evalutionType:'Pricing Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'PO',evalutionTypeKey:'po_date',evalutionType:'PO Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'GR',evalutionTypeKey:'gr_date',evalutionType:'GR Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'IR',evalutionTypeKey:'po_date',evalutionType:'PO Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'IR',evalutionTypeKey:'ir_date',evalutionType:'IR Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'IR',evalutionTypeKey:'gr_date',evalutionType:'GR Date'},
    {sourceDataType:'Indirect - Sales Data', transactionType:'TRACING',evalutionTypeKey:'billing_date',evalutionType:'Billing Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'GR',evalutionTypeKey:'adr_date',evalutionType:'ADR Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'GR',evalutionTypeKey:'asn_date',evalutionType:'ASN Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'IR',evalutionTypeKey:'adr_date',evalutionType:'ADR Date'},
    {sourceDataType:'Direct - Purchase Data', transactionType:'IR',evalutionTypeKey:'asn_date',evalutionType:'ASN Date'},
 //new
 {sourceDataType:'Direct - Sales Data', transactionType:'DEL',evalutionTypeKey:'sales_order_date',evalutionType:'Sales Order Date' },
 {sourceDataType:'Direct - Sales Data', transactionType:'INV',evalutionTypeKey:'delivery_date',evalutionType:'Delivery Date'},
 {sourceDataType:'Direct - Sales Data', transactionType:'INV',evalutionTypeKey:'sales_order_date',evalutionType:'Sales Order Date' },
 {sourceDataType:'Direct - Purchase Data', transactionType:'GR',evalutionTypeKey:'po_date',evalutionType:'PO Date'},
 {sourceDataType:'Indirect - Sales Data', transactionType:'TRACING',evalutionTypeKey:'sales_tracing_date',evalutionType:'Sales Tracing Date'},
 {sourceDataType:'Indirect - Sales Data', transactionType:'TRACING',evalutionTypeKey:'resale_date',evalutionType:'Resale Date'},
 {sourceDataType:'Indirect - Sales Data', transactionType:'TRACING',evalutionTypeKey:'pricing_date',evalutionType:'Pricing Date'},
 {sourceDataType:'Indirect - Sales Data', transactionType:'CLAIM',evalutionTypeKey:'claim_date',evalutionType:'Claim Date'},
 {sourceDataType:'Indirect - Sales Data', transactionType:'CLAIM',evalutionTypeKey:'resale_date',evalutionType:'Resale Date'},
 {sourceDataType:'Indirect - Sales Data', transactionType:'CLAIM',evalutionTypeKey:'pricing_date',evalutionType:'Pricing Date'},
    
         
]