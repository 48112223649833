import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, TextField, OutlinedInput, FormLabel, Switch, Dialog, DialogActions, Select, MenuItem, Paper, Checkbox, FormControlLabel
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getDefaultValuesAllDataNew,
    getCalculationSimulationDataFields, getDynamicQueryFields,
    postDynamicQuery, getDynamicQuerySalesDataFields, getPurchaseDataFields, getDefaultValuesAllData, getDynamicQuery,
    getDynamicQueryFieldsNew,
    getDefaultValues, getTableDeterminationData
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import { RingLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: "0px 5px 0px 5px",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        // marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        // padding: '0px 16px 0px 16px'
        marginTop: '-1rem'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 },
    select: {
        width: '100%',
        marginTop: 10
    },
    center: {
        textAlign: 'center'
    },
    tableCellStyle: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const AddDynamicQuery = props => {
    const classes = useStyles();
    const [currentPage, setCurrentPage] = React.useState('');
    const location = useLocation();
    useEffect(() => {
        var [first, second, third] = (location.pathname).split("/");
        setCurrentPage(third)
    }, [location])

    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [filterFieldsArray, setFilterFieldsArray] = React.useState([...(location.pathname.substring(location.pathname.lastIndexOf('/') + 1) === 'edit-collaboration-portal' ? ['customer_number', 'supplier_number'] : [])]);
    const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
    const [averageFieldsArray, setAverageFieldsArray] = React.useState([]);
    const [outputFieldsArray, setOutputFieldsArray] = React.useState({});
    const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
    const [sortOrderArray, setSortOrderArray] = React.useState([]);
    const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] = React.useState([]);
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const [currentEditId, setCurrentEditId] = React.useState('');
    const [keyValuePairObjectAll, setKeyValuePairObjectAll] = React.useState([]);
    const [disabledItems, setDisabledItems] = React.useState([]);
    const [maximumSortOrder, setMaximumSortOrder] = React.useState([]);
    const [sortCounterEnabler, setSortCounterEnabler] = React.useState(true);
    const [sortCounter, setSortCounter] = React.useState(1);
    const [dynamicFields, setDynamicFields] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState('');
    const [groupedFields, setGroupedFields] = React.useState('');
    const [tableDeterminationArray, setTableDeterminationArray] = React.useState([]);

    const [editMode, setEditMode] = React.useState(false);
    const [userStore, setUserStore] = React.useState([]);

    const [tableName, setTableName] = React.useState('');
    const [tableDesc, setTableDesc] = React.useState('');
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const handleInitialSetup = () => {
        setOpen(false);
    }
    console.log(props.loading)
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getDynamicQuery(appId);
        setOpen(true);
        props.getDefaultValuesAllData();
        props.getTableDeterminationData();
    }, []);
    useEffect(() => {
        if (props.tableDetermination && props.tableDetermination.length > 0) {
            setTableDeterminationArray(props.tableDetermination);
        }
    }, [props.tableDetermination]);
    useEffect(() => {
        if (tableDesc && tableDeterminationArray.length > 0) {
            var data = tableDeterminationArray && tableDeterminationArray.filter(item => item.table_description == tableDesc)
            if (data) {
                setGroupedFields(data[0])
            }
            else
                setGroupedFields('')
        }
    }, [tableDesc, tableDeterminationArray]);
    useEffect(() => {
        var tempObj = {};
        if (apiArrayFieldsConcatinated && apiArrayFieldsConcatinated.length > 0) {
            setKeyValuePairObjectAll(apiArrayFieldsConcatinated.sort((a, b) => a.label > b.label ? 1 : -1));
        }
        var dis = [];
        props.formFieldsAllData &&
            props.formFieldsAllData.records.length > 0 &&
            props.formFieldsAllData.records.map((item) => {
                if (item.contract_display === false) {
                    dis.push(item.field_id);
                }
            });
        setDisabledItems(dis);
        if (dis.length == 0 && apiArrayFieldsConcatinated && apiArrayFieldsConcatinated.length > 0) {
            setKeyValuePairObject(apiArrayFieldsConcatinated.sort((a, b) => a.label > b.label ? 1 : -1));
        }
    }, [apiArrayFieldsConcatinated, props.formFieldsAllData])
    useEffect(() => {
        if (disabledItems.length > 0) {
            const filtered = keyValuePairObjectAll
                .filter((item, index) => !disabledItems.includes(item.key))
                .reduce((obj, key) => {
                    obj[key] = keyValuePairObjectAll[key];
                    return obj;
                }, {});
            setKeyValuePairObject(filtered);
        }
    }, [disabledItems])

    useEffect(() => {
        if (props.dynamicQueryFieldsData) {
            setDynamicFields(props.dynamicQueryFieldsData)
            var concatinated = []
            if (props.dynamicQueryFieldsData.numeric_fields && props.dynamicQueryFieldsData.numeric_fields.length > 0)
                concatinated.push(...props.dynamicQueryFieldsData.numeric_fields)
            if (props.dynamicQueryFieldsData.string_fields && props.dynamicQueryFieldsData.string_fields.length > 0)
                concatinated.push(...props.dynamicQueryFieldsData.string_fields)
            if (props.dynamicQueryFieldsData.date_fields && props.dynamicQueryFieldsData.date_fields.length > 0)
                concatinated.push(...props.dynamicQueryFieldsData.date_fields)
            setApiArrayFieldsConcatinated(concatinated)
        }
    }, [props.dynamicQueryFieldsData]);
    useEffect(() => {
        if (props.dynamicQueryDetailsData && Object.keys(props.dynamicQueryDetailsData).length > 0) {

            if (props.dynamicQueryDetailsData.query_type) {
                if (props.dynamicQueryDetailsData.query_type === 3) {
                    // setCurrentPage('partner-statement')
                } else {
                    // setCurrentPage('dynamic-query')
                }
            }

            props.onLoadingDefault(props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.data_source_type, null, null, 'Display Designer');
            //props.onLoadingLabelDesignerNew('field-label-designer', props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.data_source_type)
            props.getDynamicQueryFields(props.dynamicQueryDetailsData.app_type, props.dynamicQueryDetailsData.table_description)
            setTableName(props.dynamicQueryDetailsData.table_name);
            setTableDesc(props.dynamicQueryDetailsData.table_description);
            setDescription(props.dynamicQueryDetailsData.query_description);
            setApplicationType(props.dynamicQueryDetailsData.app_type)
            setQueryName(props.dynamicQueryDetailsData.format_name);
            if (props.dynamicQueryDetailsData.filter_fields)
                setFilterFieldsArray(props.dynamicQueryDetailsData.filter_fields.map((item, i) => item.sort_field));
            setAverageFieldsArray(props.dynamicQueryDetailsData.avg_fields);
            setSumFieldsArray(props.dynamicQueryDetailsData.sum_fields);
            setAggregateFieldsArray(props.dynamicQueryDetailsData.groupby_fields);
            setCurrentEditId(props.dynamicQueryDetailsData.id);
            if (props.dynamicQueryDetailsData && props.dynamicQueryDetailsData.sort_fields) {
                var arrayToObject = Object.assign({}, ...props.dynamicQueryDetailsData.sort_fields.map((x) => ({ [x.sort_field]: x.sort_order })));
                setOutputFieldsArray(arrayToObject);
            }
            if (props.dynamicQueryDetailsData && props.dynamicQueryDetailsData.sort_fields) {
                const maxValueOfY = Math.max(...props.dynamicQueryDetailsData.sort_fields.map(o => o.sort_order), 0);
                if (maxValueOfY) {
                    setMaximumSortOrder(maxValueOfY + 1);
                    setSortCounter(maxValueOfY + 1);
                }
            }

        }
    }, [props.dynamicQueryDetailsData]);

    function handleOnSubmit() {
        var unsortedFields = [];
        if (outputFieldsArray || (sumFieldsArray.length > 0 && filterFieldsArray.length === 0
            && outputFieldsArray.length === 0 && aggregateFieldsArray.length === 0)) {
            for (var propName in outputFieldsArray) {
                unsortedFields.push(propName);
                if (outputFieldsArray[propName] === 0) {
                    delete outputFieldsArray[propName];
                }
            }
            var allFields = {
                "filter_fields": filterFieldsArray,
                "sum_fields": sumFieldsArray,
                "avg_fields": averageFieldsArray,
                "sort_fields": outputFieldsArray,
                "output_fields": unsortedFields,
                "groupby_fields": aggregateFieldsArray,
                "format_name": queryName,
                "table_name": tableName,
                "id": currentEditId,
                "query_description": description,
                "table_description": tableDesc
            };
            if (currentPage === 'partner-statement') {
                props.onSubmitPartnerStatement(allFields, 'edit', currentPage);
            } else if (currentPage === 'collaboration-portal') {
                props.onSubmitDynamicQuery(allFields, 'edit', currentPage);
            } else {
                props.onSubmit(allFields, 'edit', currentPage);
            }
        } else {
            alert('Please select atleast one output or only sum field')
        }
    }
    function handleClearAll() {
        setEditMode(false);
        setUserStore([]);
    }
    function handleCancelDialog() {
        // history.push('/dynamic-query');
        {
            currentPage === 'edit-collaboration-portal' ? history.push('/collaboration-portal') :
                currentPage === 'edit-partner-statement' ? history.push('/partner-statement') : history.push('/dynamic-query')
        }

    }
    const handleChange = (event, item, type) => {
        if (type === 'filter_fields') {
            if (filterFieldsArray.includes(item)) {
                setFilterFieldsArray(filterFieldsArray.filter(item1 => item1 !== item));
            } else {
                setFilterFieldsArray([...filterFieldsArray, item]);
            }
        }
        if (type === 'aggregate_fields') {
            if (aggregateFieldsArray.includes(item)) {
                if (sumFieldsArray.includes(item)) {
                    if (outputFieldsArray.hasOwnProperty(item)) {
                    }
                } else {
                    setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
                }
            } else {
                setAggregateFieldsArray([...aggregateFieldsArray, item]);
                if (sortCounterEnabler) {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: outputFieldsArray[item] });//aggregate and output should be in sync
                } else {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: null });//aggregate and output should be in sync
                }
            }
        }
        if (type === 'sum_fields') {
            if (sumFieldsArray.includes(item)) {
                setSumFieldsArray(sumFieldsArray.filter(item1 => item1 !== item));
            } else {
                if (sortCounterEnabler) {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: outputFieldsArray[item] });//aggregate and output should be in sync
                } else {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: null });//aggregate and output should be in sync
                }
                setSumFieldsArray([...sumFieldsArray, item]);
            }
        }
        if (type === 'avg_fields') {
            if (averageFieldsArray.includes(item)) {
                setAverageFieldsArray(averageFieldsArray.filter(item1 => item1 !== item));
            } else {
                if (sortCounterEnabler) {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: outputFieldsArray[item] });//aggregate and output should be in sync
                } else {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: null });//aggregate and output should be in sync
                }
                setAverageFieldsArray([...averageFieldsArray, item]);
            }
        }
        if (type === 'output_fields') {
            if (outputFieldsArray.hasOwnProperty(item)) {
                var tempArray = outputFieldsArray;
                delete tempArray[item];
                setOutputFieldsArray({ ...tempArray })

            } else {
                if (sortCounterEnabler) {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: sortCounter });
                } else {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
                }
                setSortCounter(prevCount => prevCount + 1);
                // setAggregateFieldsArray([...aggregateFieldsArray, item])//aggregate and output should be in sync
            }
        }
        if (type === 'sort_order') {
            setSortCounterEnabler(false);
            setSortOrderArray(event.target.value)
            setOutputFieldsArray({ ...outputFieldsArray, [item]: event.target.value ? parseInt(event.target.value) : null });
            event.preventDefault();
        }
        // event.preventDefault();
    };

    const [allOutput, setAllOutput] = React.useState(false);
    const handleAllOutput = (event) => {
        var tempObj = {};
        var tempArray = [];
        var i = 1;
        setAllOutput(event.target.checked);
        if (event.target.checked === true) {
            if (keyValuePairObject.length > 0) {
                // Object.entries(keyValuePairObject)
                keyValuePairObject && keyValuePairObject
                    .sort()
                    .filter((item, index) => item.key != "")
                    .map((item, index) => {
                        tempArray.push(item.key)
                        tempObj[item.key] = i;
                        ++i;
                    })
            }

            setOutputFieldsArray(tempObj);
        } else {
            setOutputFieldsArray(tempObj);
        }
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to={currentPage === 'edit-collaboration-portal' ? '/collaboration-portal' :
                            currentPage === 'edit-partner-statement' ? '/partner-statement' : '/dynamic-query'}
                    >
                        {currentPage === 'edit-partner-statement' ? 'Partner Statement' :
                            currentPage === 'edit-collaboration-portal' ? 'On Demand Query' :
                                'On Demand Query'}
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Edit Query</Typography>
                </Breadcrumbs>
                <Dialog
                    onClose={() => setOpen(false)}
                    aria-labelledby="simple-dialog-title"
                    open={open}
                    disableEscapeKeyDown
                    classes={{ paper: classes.paper }}>
                    <div style={{ padding: 20 }}>
                        <FormLabel className={classes.formLabel} required>
                            Query Name
                        </FormLabel>
                        <OutlinedInput
                            value={queryName}
                            classes={{ root: classes.inputTwoLine }}
                            onChange={handleQueryName}
                        />
                    </div>
                    <div style={{ padding: 20 }}>
                        <FormLabel className={classes.formLabel} required>
                            Description
                        </FormLabel>
                        <OutlinedInput
                            value={description}
                            // inputProps={{ min: "0", step: "1" }}
                            classes={{ root: classes.inputTwoLine }}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <DialogActions>
                        <Button color="primary" onClick={handleCancelDialog}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleInitialSetup} disabled={queryName && description ? false : true}>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.container2}>{console.log(groupedFields)}
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {apiArrayFieldsConcatinated && apiArrayFieldsConcatinated.length > 0 && groupedFields && Object.entries(groupedFields).length > 0 ?
                                    <Paper className={classes.root}>
                                        <div style={{ margin: 15 }}>
                                            <TableContainer style={{ maxHeight: 550, overflowY: 'auto' }}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell align='center' className={classes.tableCellStyle}>Field Name</TableCell>
                                                            <TableCell align='center' className={classes.tableCellStyle}>Filter</TableCell>
                                                            {groupedFields.grouping_required ?
                                                                <>
                                                                    <TableCell align='center' className={classes.tableCellStyle}>Aggregate</TableCell>
                                                                    <TableCell align='center' className={classes.tableCellStyle}>Average</TableCell>
                                                                    <TableCell align='center' className={classes.tableCellStyle}>Sum</TableCell>
                                                                </> : ''
                                                            }
                                                            <TableCell align='center' className={classes.tableCellStyle}> <FormControlLabel
                                                                value="external"
                                                                control={<Checkbox color="primary"
                                                                    onChange={handleAllOutput}
                                                                    checked={allOutput}
                                                                />}
                                                                label={<div style={{ fontSize: 14 }}>Output</div>}
                                                                labelPlacement="right"
                                                                style={{ fontSize: 14 }}
                                                                classes={{ root: 'fontSize: 14px' }}
                                                            /></TableCell>
                                                            <TableCell align='center' className={classes.tableCellStyle} >Sort Order</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody classes={{ root: classes.table }} >
                                                        {//Object.entries(keyValuePairObject)
                                                            keyValuePairObject && keyValuePairObject.length > 0 && keyValuePairObject
                                                                .sort()
                                                                .filter((item, index) => item.key != "" && item.display)
                                                                .map((item, i) => {
                                                                    return (
                                                                        <TableRow key={item.key}>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{item.label}</TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                <Switch
                                                                                    checked={filterFieldsArray && filterFieldsArray.includes(item.key) ? filterFieldsArray.includes(item.key) : ''}
                                                                                    color='primary'
                                                                                    onChange={(e) => handleChange(e, item.key, 'filter_fields')}
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}

                                                                                />
                                                                            </TableCell>
                                                                            {(groupedFields.grouping_required == true) ?
                                                                                <>
                                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                        <Switch
                                                                                            checked={aggregateFieldsArray && aggregateFieldsArray.includes(item.key) ? aggregateFieldsArray.includes(item.key) : ''}
                                                                                            color='primary'
                                                                                            onChange={(e) => handleChange(e, item.key, 'aggregate_fields')}
                                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                        <Switch
                                                                                            checked={averageFieldsArray && averageFieldsArray.includes(item.key) ? averageFieldsArray.includes(item.key) : ''}
                                                                                            color='primary'
                                                                                            onChange={(e) => handleChange(e, item.key, 'avg_fields')}
                                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                            disabled={dynamicFields && dynamicFields.numeric_fields &&
                                                                                                dynamicFields.numeric_fields.findIndex(({ key }) => key == item.key) > -1 ? false : true}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                        <Switch
                                                                                            checked={sumFieldsArray && sumFieldsArray.includes(item.key) ? sumFieldsArray.includes(item.key) : ""}
                                                                                            color='primary'
                                                                                            onChange={(e) => handleChange(e, item.key, 'sum_fields')}
                                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                            disabled={dynamicFields && dynamicFields.numeric_fields &&
                                                                                                dynamicFields.numeric_fields.findIndex(({ key }) => key == item.key) > -1 ? false : true}
                                                                                        />
                                                                                    </TableCell>
                                                                                </> : ''
                                                                            }
                                                                            <TableCell classes={{ root: classes.fontSetting }} align='center'>
                                                                                <Switch
                                                                                    checked={outputFieldsArray && outputFieldsArray.hasOwnProperty(item.key) ? outputFieldsArray.hasOwnProperty(item.key) : ''}
                                                                                    color='primary'
                                                                                    onChange={(e) => handleChange(e, item.key, 'output_fields')}
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                                <TextField
                                                                                    key={['recipient', item.key].join('_')}
                                                                                    type="number"
                                                                                    variant="outlined"
                                                                                    value={(outputFieldsArray && outputFieldsArray[item.key]) ? (outputFieldsArray[item.key] || outputFieldsArray[item.key] === 0 ? outputFieldsArray[item.key] : '') : ''}
                                                                                    onChange={(e) => handleChange(e, item.key, 'sort_order')}
                                                                                    disabled={outputFieldsArray.hasOwnProperty(item.key) ? false : true}
                                                                                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                                                />
                                                                            </TableCell>

                                                                        </TableRow>
                                                                    );
                                                                })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Paper>
                                    :
                                    <Typography variant='h4'>
                                        There is no data to show now.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                            Submit
                        </Button>
                    </div>
                </div >
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        // getDynamicQueryFields: () => dispatch(getDynamicQueryFields()),
        getDynamicQuerySalesDataFields: () => dispatch(getDynamicQuerySalesDataFields()),
        getCalculationSimulationDataFields: () => dispatch(getCalculationSimulationDataFields()),
        onSubmit: (data, edit, currentPage) => dispatch(postDynamicQuery(data, edit, currentPage)),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
        getPurchaseDataFields: () => dispatch(getPurchaseDataFields()),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getDynamicQueryFields: (applicationType, tableDesc) => dispatch(getDynamicQueryFieldsNew(applicationType, tableDesc)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, null, null, 'Display Designer')),
        onSubmitPartnerStatement: (data) => dispatch(postDynamicQuery(data, null, 'partner')),
        onSubmitDynamicQuery: (data, edit) => dispatch(postDynamicQuery(data, edit, 'collaboration')),
        getTableDeterminationData: () => dispatch(getTableDeterminationData()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsDataNew,
        calculationFieldsData: state.customerData.calculationSimulationData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        dynamicQueryDetailsData: state.operationalReportsData.dynamicQueryDetailsData,
        formFieldsAllData: state.addMultipleConfigurationData.multipleConfigurationData,
        tableDetermination: state.operationalReportsData.tableDetermination,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicQuery);