import React, { useEffect, useMemo, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Select,
    FormLabel,
    MenuItem,
    OutlinedInput,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Input,
    Checkbox,
    ListItemText,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText, IconButton,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getOrganizationEmailLink, postOrganizationAccess, onLoadingOrganizationAccessList, secondGetConfiguration, getDefaultValuesCustomer, formFieldRefresh, allowedApps } from '../../redux/actions';
import countryList from 'react-select-country-list'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { DropdownArray } from '../../components/Inputs';
import { getDefaultValues } from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoopIcon from '@mui/icons-material/Loop';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        marginBottom: 23,
        marginTop: 16,
        paddingTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '10px 45px 0px 45px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 7,
    },
    region: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 7,
        height: 38
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',

    },

    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    copyLinkColor: {
        color: theme.palette.primary.main
    }
}));

const OrganisationAccess = props => {
    const classes = useStyles();
    const countriesList = useMemo(() => countryList().getLabels(), [])
    const { className } = props;
    const [organizationName, setOrganizationName] = React.useState('');
    const [IndustryType, setIndustryType] = React.useState('');
    const [applicationType, setApplicationType] = React.useState([]);
    const [streetAddress, setStreetAddress] = React.useState('');
    const [state, setState] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [orgEmail, setOrgEmail] = React.useState('');
    const [orgPhone, setOrgPhone] = React.useState('');
    const [administrativeUserName, setAdministrativeUserName] = React.useState('');
    const [administrativeUserEmail, setAdministrativeUserEmail] = React.useState('');
    const [applicationTypeArray, setApplicationTypeArray] = React.useState([]);
    const [industryTypeArray, setIndustryTypeArray] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [orgID, setOrgID] = React.useState(0);
    const [editMode, setEditMode] = React.useState(false);
    const [currentApp, setCurrentApp] = React.useState([]);
    const [addressValidationService, setAddressValidationService] = React.useState('')
    const applicationTypeStatic = [
        { key: 'Customer Rebate', value: 'Customer Rebate' },
        { key: 'Customer Chargeback', value: 'Customer Chargeback' },
        { key: 'Royalty', value: 'Royalty' },
        { key: 'Supplier Rebate', value: 'Supplier Rebate' },
        { key: 'Supplier Chargeback', value: 'Supplier Chargeback' },
        { key: 'Promotions', value: 'Promotions' },
        { key: 'Collaboration Portal', value: 'Collaboration Portal' },
    ]



    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    const isAllSelected =
        allowedApps.length > 0 && selected.length === allowedApps.length;


    const handleClear = () => {
        if (!editMode) {
            setOrganizationName('');
            setIndustryType('');
            setApplicationType([]);
            setStreetAddress('');
            setState('');
            setZipCode('');
            setCountry('');
            setOrgEmail('');
            setOrgPhone('');
            setAdministrativeUserName('');
            setAdministrativeUserEmail('');
            setAddressValidationService('');
        }
    }
    const contractCustomisationUpdation = useRef();
    const handleStreet = (event) => {
        var array = [...event.label.split(",")]
        if (array.length > 1)
            array.pop();
        if (array.length > 1)
            array.pop();
        if (array.length > 1)
            array.pop();
        setStreetAddress(array.toString());
        setCountry(event.label.split(",").slice(-1)[0]);
        setState(event.label.split(",").slice(-2)[0]);
    }
    const handleSubmit = () => {

        if (editMode) {
            var formData = {
                "application_type": applicationType,
                "address_validation_service": addressValidationService
            }
            props.postOrganizationAccess(formData, orgID, 'edit');
            setOrganizationName('');
            setIndustryType('');
            setApplicationType([]);
            setStreetAddress('');
            setState('');
            setZipCode('');
            setCountry('');
            setOrgEmail('');
            setOrgPhone('');
            setAdministrativeUserName('');
            setAdministrativeUserEmail('');
            setAddressValidationService('');
        }
        else {
            var formData = {
                "organization": {
                    "name": organizationName,
                    "street_address": streetAddress,
                    "state": state,
                    "zipcode": zipCode,
                    "country": country,
                    "industry_type": IndustryType,
                    "application_type": applicationType,
                    "email": orgEmail,
                    "phone": orgPhone,
                    "address_validation_service": addressValidationService
                },
                "admin": {
                    "name": administrativeUserName,
                    "email": administrativeUserEmail
                }
            }
            props.postOrganizationAccess(formData);

        }
        setEditMode(false)
        handleClear()

    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        props.onLoadingOrganizationAccessList();
        props.onLoadingDefaultMaterialXRef();
        props.onLoadingDefaultMaterialXRef();
        props.secondGetConfiguration()
    }, []);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        if (props.industryData && props.industryData.records) {
            props.industryData.records.map((item) => {
                if (item.field_id === 'industry_type') {
                    setIndustryTypeArray(item.drop_down_value_keys);
                }
            })
        }
    }, [props.industryData]);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'application_type') {
                            setApplicationTypeArray(item);
                        }
                    })
                }

            }
        }
    });
    const [organizationAccessDataAPI, setOrganizationAccessDataAPI] = React.useState([]);
    useEffect(() => {
        if (props.organizationAccessData && props.organizationAccessData.length > 0) {
            setOrganizationAccessDataAPI(props.organizationAccessData)
        }
    }, [props.organizationAccessData])

    const [openOrgPopup, setOpenOrgPopup] = React.useState(false);
    useEffect(() => {
        if (props.organisationPostResponse) {
            setOpenOrgPopup(true);
        }
    }, [props.organisationPostResponse])
    const handleCopyLink = () => {
        toast.success('URL copied successfully.')
        setOpenOrgPopup(false)
    }
    const handleGetEmailLink = (id) => {
        props.getOrganizationEmailLink(id);
    }
    const handleUpdateFormField = (id) => {
        props.updateFormField(id);
    }
    const [copyText, setCopyText] = React.useState('');
    useEffect(() => {
        if (props.organisationPostResponse) {
            setCopyText(props.organisationPostResponse)
        }
    }, [props.organisationPostResponse])
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === allowedApps.length ? [] : allowedApps);
        }
        else
            setSelected(newSelected)

    }
    function onAdd() {

        setApplicationType(selected)
        setOpen(false)
        setSelected([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])

    }
    function handleOpen(type) {
        setSelected(applicationType)
        setOpen(true)
    }
    const editOrganization = (item) => {
        setOrgID(item['organization'].id)
        setOrganizationName(item['organization'].organization_name)
        setIndustryType(item['organization'].industry_type)
        setApplicationType(item['organization'].application_type)
        setStreetAddress(item['organization'].street_address)
        setState(item['organization'].state)
        setZipCode(item['organization'].zipcode)
        setCountry(item['organization'].country)
        setOrgEmail(item['organization'].organization_email)
        setOrgPhone(item['organization'].phone)
        setAdministrativeUserName(item['admin'].admin_user_name)
        setAdministrativeUserEmail(item['admin'].admin_user_email)
        setEditMode(true)
        setCurrentApp(item['organization'].application_type)
        setAddressValidationService(item['organization'].address_validation_service)

    }
    return (
        <div>
            <Typography variant="h1"> Organization Setup </Typography>

            <Dialog open={
                openOrgPopup}
                fullScreen
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            Your link for reseting email is generated.

                            <CopyToClipboard text={copyText}
                            >
                                <Button className={classes.copyLinkColor} onClick={handleCopyLink}>Click to copy text</Button>
                            </CopyToClipboard>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                </DialogActions>
            </Dialog>


            <div
                className={clsx(classes.root, className)}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}

                            >
                                <FormLabel required disabled={editMode ? true : false}>
                                    Organization Name
                                </FormLabel>
                                <OutlinedInput
                                    value={organizationName}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setOrganizationName(e.target.value)}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required disabled={editMode ? true : false}>
                                    Industry Type
                                </FormLabel>

                                <div className={classes.dropdownContainer}>
                                    <Select
                                        value={IndustryType}
                                        disabled={editMode ? true : false}
                                        onChange={e => setIndustryType(e.target.value)}
                                        style={{ marginTop: 5 }}
                                        color={'grey'}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}

                                    >
                                        <MenuItem value="">
                                            Select industry type
                                        </MenuItem>
                                        {industryTypeArray.map((item) => {
                                            return (
                                                <MenuItem value={item} key={item} >
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required>
                                    Application Type
                                </FormLabel>
                                <div>

                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={applicationType}
                                        style={{ maxHeight: "50px", width: '100%', marginTop: 10 }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpen()}
                                        onClose={() => setOpen(false)}
                                        open={open}
                                        renderValue={(appType) => appType.join(", ")}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }} disabled={editMode ? true : false}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {allowedApps && allowedApps
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={index} disabled={currentApp.indexOf(item) > -1 ? true : false}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item) > -1} />
                                                        <ListItemText primary={item} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownbottom}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>


                                    </Select>
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel disabled={editMode ? true : false}>
                                    Street Address
                                </FormLabel>
                                <OutlinedInput
                                    value={streetAddress}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setStreetAddress(e.target.value)}
                                    disabled={editMode ? true : false}
                                />
                                {/* <div style={{ marginTop: 10 }}>
                                    <GooglePlacesAutocomplete
                                        apiKey="AIzaSyB7pRbk3nJvj3eY48s5dFPKck0z4TsxAGQ"
                                        selectProps={{
                                            streetAddress,
                                            onChange: handleStreet,
                                        }}
                                    />
                                </div> */}
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required disabled={editMode ? true : false}>
                                    Country
                                </FormLabel>
                                <CountryDropdown
                                    value={country}
                                    onChange={(val) => setCountry(val)}
                                    classes={
                                        classes.region
                                    }
                                    priorityOptions={['US']}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel disabled={editMode ? true : false}>
                                    State
                                </FormLabel>
                                <RegionDropdown
                                    classes={
                                        classes.region
                                    }
                                    country={country}
                                    value={state}
                                    onChange={(val) => setState(val)}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel disabled={editMode ? true : false}>
                                    Zip Code
                                </FormLabel>
                                <OutlinedInput
                                    value={zipCode}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setZipCode(e.target.value)}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>

                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel disabled={editMode ? true : false}>
                                    Organization Email
                                </FormLabel>
                                <OutlinedInput
                                    value={orgEmail}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setOrgEmail(e.target.value)}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel disabled={editMode ? true : false}>
                                    Organization Phone
                                </FormLabel>
                                <OutlinedInput
                                    value={orgPhone}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setOrgPhone(e.target.value)}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required disabled={editMode ? true : false}>
                                    Administrative User Name
                                </FormLabel>
                                <OutlinedInput
                                    value={administrativeUserName}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setAdministrativeUserName(e.target.value)}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required disabled={editMode ? true : false}>
                                    Administrative User Email
                                </FormLabel>
                                <OutlinedInput
                                    value={administrativeUserEmail}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setAdministrativeUserEmail(e.target.value)}
                                    disabled={editMode ? true : false}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel> Address Validation Service  </FormLabel>
                                <div className={classes.dropdownContainer}>
                                    <Select
                                        value={addressValidationService}
                                        onChange={e => setAddressValidationService(e.target.value)}
                                        style={{ marginTop: 5 }}
                                        color={'grey'}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={'Map_Box'} key={'Map_Box'} > {'Map Box'}</MenuItem>
                                        <MenuItem value={'Geoapify'} key={'Geoapify'} > {'Geoapify'}</MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                        </Grid>


                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                                disabled={organizationName && administrativeUserName
                                    && administrativeUserEmail
                                    && IndustryType && applicationType.length > 0
                                    && country
                                    ? false : true}
                            >
                                {props.loadingAPI ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                    :
                                    'Submit'
                                }
                            </Button>
                        </div>
                        {organizationAccessDataAPI && organizationAccessDataAPI.length > 0 &&
                            <div style={{ margin: 15 }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'>ID</TableCell>
                                                <TableCell align='center'>Organization Name</TableCell>
                                                <TableCell align='center'>Industry Type</TableCell>
                                                <TableCell align='center'>Application Type</TableCell>
                                                <TableCell align='center'>Street Address</TableCell>
                                                <TableCell align='center'>State</TableCell>
                                                <TableCell align='center'>Zip Code</TableCell>
                                                <TableCell align='center'>Country</TableCell>
                                                <TableCell align='center'>Organization Email</TableCell>
                                                <TableCell align='center'>Organization Phone</TableCell>
                                                <TableCell align='center'>Administrative User Name</TableCell>
                                                <TableCell align='center'>Admnistrative User Email</TableCell>
                                                <TableCell align='center'>Email Link</TableCell>
                                                <TableCell align='center'>Update Form Field</TableCell>
                                                <TableCell align='center'>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {organizationAccessDataAPI.map(item => {
                                                return (
                                                    <StyledTableRow>
                                                        <TableCell align='center'>{item['organization'].id}</TableCell>
                                                        <TableCell align='center'>{item['organization'].organization_name}</TableCell>
                                                        <TableCell align='center'>{item['organization'].industry_type}</TableCell>
                                                        <TableCell align='center'>{item['organization'].application_type}</TableCell>
                                                        <TableCell align='center'>{item['organization'].street_address}</TableCell>
                                                        <TableCell align='center'>{item['organization'].state}</TableCell>
                                                        <TableCell align='center'>{item['organization'].zipcode}</TableCell>
                                                        <TableCell align='center'>{item['organization'].country}</TableCell>
                                                        <TableCell align='center'>{item['organization'].organization_email}</TableCell>
                                                        <TableCell align='center'>{item['organization'].phone}</TableCell>
                                                        <TableCell align='center'>{item['admin'].admin_user_name}</TableCell>
                                                        <TableCell align='center'>{item['admin'].admin_user_email}</TableCell>
                                                        <TableCell align='center'
                                                            className={classes.copyLinkColor}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleGetEmailLink(item['organization'].id)} >Generate Link</TableCell>
                                                        <TableCell align='center' >
                                                            <IconButton
                                                                onClick={() => handleUpdateFormField(item['organization'].id)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                < LoopIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => editOrganization(item)}
                                                                    size="large">
                                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                    </div>
                </form>
            </div >
        </div >
    );
};

const mapDispatchToProps = dispatch => {
    return {
        postOrganizationAccess: (data, id, type) => dispatch(postOrganizationAccess(data, id, type)),
        onLoadingOrganizationAccessList: () => dispatch(onLoadingOrganizationAccessList()),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        onLoadingDefaultMaterialXRef: () => dispatch(getDefaultValuesCustomer()),
        secondGetConfiguration: () => dispatch(secondGetConfiguration('industry_type')),
        getOrganizationEmailLink: (id) => dispatch(getOrganizationEmailLink(id)),
        updateFormField: (id) => dispatch(formFieldRefresh(id)),
        allowedApps: () => dispatch(allowedApps())
    }
}

const mapStateToProps = state => {
    return {
        organizationAccessData: state.addMultipleConfigurationData.organizationAccessData,
        loadingAPI: state.addMultipleConfigurationData.loadingOrg,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        industryData: state.addMultipleConfigurationData.secondApiData,
        organisationPostResponse: state.addMultipleConfigurationData.organisationPostResponse,
        allowedAppsData: state.initialData.allowedApps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationAccess);