import React from 'react';
import {
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Typography,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const OrganizationData = props => {
    const classes = useStyles();
    const { className } = props;

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <div className={classes.container}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.customerMasterData && props.customerMasterData.organizations && props.customerMasterData.organizations.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'>Sales Organization</TableCell>
                                                <TableCell align='center'>Distrubution Channel</TableCell>
                                                <TableCell align='center'>Division</TableCell>
                                                <TableCell align='center'>Company Code</TableCell>
                                                <TableCell align='center'>Price List Type</TableCell>
                                                <TableCell align='center'>Price Group</TableCell>
                                                <TableCell align='center'>Ship to Party</TableCell>
                                                <TableCell align='center'>Bill to Party</TableCell>
                                                <TableCell align='center'>Payer</TableCell>
                                                <TableCell align='center'>Flex Attribute 5</TableCell>
                                                <TableCell align='center'>Flex Attribute 6</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {props.customerMasterData.organizations.map((item) => {
                                                return (
                                                    <StyledTableRow key={item.ID}>
                                                        <TableCell align='center'> {item.sales_organization}</TableCell>
                                                        <TableCell align='center'> {item.distribution_channel}</TableCell>
                                                        <TableCell align='center'> {item.division}</TableCell>
                                                        <TableCell align='center'> {item.company_code}</TableCell>
                                                        <TableCell align='center'> {item.price_list_type}</TableCell>
                                                        <TableCell align='center'> {item.price_group}</TableCell>
                                                        <TableCell align='center'> {item.ship_to_party}</TableCell>
                                                        <TableCell align='center'> {item.bill_to_party}</TableCell>
                                                        <TableCell align='center'> {item.payer}</TableCell>
                                                        <TableCell align='center'> {item.flex_attribute_5}</TableCell>
                                                        <TableCell align='center'> {item.flex_attribute_6}</TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        customerMasterData: state.customerData.customerMasterViewData
    }
}

export default connect(mapStateToProps)(OrganizationData);