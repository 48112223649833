import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs,
    Typography,
    // Link,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button
} from '@mui/material';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import Moment from 'moment';
import { runTargetReports, downloadSheet, getDefaultValues, getDefaultValuesCalculationSimulation, getLblDispDesFieldValue } from '../../../redux/actions';
import CheckIcon from '@mui/icons-material/Check';
import { Paper, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
        marginBottom: 15
    },
    rootSearch: {
        borderBottomColor: theme.palette.table.border,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        alignItems: 'center',
        display: 'flex',
        alignSelf: 'flex-end',
        borderRadius: 0,
        padding: 0,
        minWidth: 200,
        [theme.breakpoints.down('lg')]: {
            minWidth: 0,
            marginTop: 10,
            marginRight: 15,
        },
    },
    input: {
        fontSize: 14,
        flexGrow: 1,
        lineHeight: '16px',
        letterSpacing: '-0.05px',
        paddingLeft: 10,
    },
    iconButton: {
        padding: 0,
    },
    icon: {
        fontSize: 20,
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

var enabledFormFields = [];
const TargetReportsTable = props => {
    var newArray = [];
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [tabHeads, setTabHeads] = React.useState([]);
    const [tabKeys, setTabKeys] = React.useState([]);
    const [targetDataResultsArray, setTargetDataResultsArray] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.runTargetReports(newPage + 1, rowsPerPage, '');
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.runTargetReports(1, event.target.value, '');
    };
    const handleSheetDownload = () => {
        props.downloadSheet(targetDataResultsArray.records);
    }
    const [searchText, setSearchText] = React.useState('');
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    };
    const contractCustomisationUpdation = useRef();
    useEffect(() => {
        if (props.targetDataCompleteResults) {
            setTargetDataResultsArray(props.targetDataCompleteResults);
        }
    }, [props.targetDataCompleteResults]);
    useEffect(() => {
        // props.onLoadingDefault();
        props.getLblDispDesFieldValues({ "data_source": "commitment_data", "application_type": sessionStorage.getItem('application') }, 'post')
    }, []);

    useEffect(() => {
        var keywordsArray = ['calendar_year_month', 'contract_number', 'start_date', 'end_date', 'customer_number', 'target_type',
            'target', 'material_number', 'supplier_number', 'employee_number', 'sold_to_party', 'ship_to_party', 'bill_to_party', 'payer'
        ];

        if (!contractCustomisationUpdation.current) {
            if (props.tableData && props.tableData.field_label_attributes) {
                keywordsArray.forEach(function (elem) {
                    if (elem === 'contract_number') {
                        setTabHeads(tabHeads => [...tabHeads, 'Contract Number']);
                        setTabKeys(tabKeys => [...tabKeys, 'contract_number']);
                    }
                    else if (elem === 'target_type') {
                        setTabHeads(tabHeads => [...tabHeads, 'Target Type']);
                        setTabKeys(tabKeys => [...tabKeys, 'target_type']);
                    }
                    else if (elem === 'target') {
                        setTabHeads(tabHeads => [...tabHeads, 'Target']);
                        setTabKeys(tabKeys => [...tabKeys, 'target']);
                    }
                    else {
                        if (props.tableData.field_label_attributes[elem].display) {
                            setTabHeads(tabHeads => [...tabHeads, props.tableData.field_label_attributes[elem].current]);
                            setTabKeys(tabKeys => [...tabKeys, elem]);
                        }
                    }
                });
            }
        }
    }, [props.tableData]);

    // useEffect(() => {
    //     var keywordsArray = ['calendar_year_month', 'contract_number', 'start_date', 'end_date', 'customer_number', 'target_type',
    //         'target', 'material_number', 'supplier_number', 'employee_number', 'sold_to_party', 'ship_to_party', 'bill_to_party', 'payer'
    //     ];

    //     if (!contractCustomisationUpdation.current) {
    //         if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
    //             enabledFormFields = props.tableData.records.filter(itemY => itemY.enabled != false).map(itemY => { return itemY.field_id });
    //         }
    //         if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
    //             keywordsArray.forEach(function (elem) {
    //                 if (elem === 'contract_number') {
    //                     setTabHeads(tabHeads => [...tabHeads, 'Contract Number']);
    //                     setTabKeys(tabKeys => [...tabKeys, 'contract_number']);
    //                 }
    //                 else if (elem === 'target_type') {
    //                     setTabHeads(tabHeads => [...tabHeads, 'Target Type']);
    //                     setTabKeys(tabKeys => [...tabKeys, 'target_type']);
    //                 }
    //                 else if (elem === 'target') {
    //                     setTabHeads(tabHeads => [...tabHeads, 'Target']);
    //                     setTabKeys(tabKeys => [...tabKeys, 'target']);
    //                 }
    //                 else {
    //                     props.tableData.records
    //                         .filter(itemY => itemY.field_id === elem)
    //                         .filter(itemY => itemY.enabled == true)
    //                         .map(itemY => {
    //                             setTabHeads(tabHeads => [...tabHeads, itemY.name]);
    //                             setTabKeys(tabKeys => [...tabKeys, itemY.field_id]);
    //                         });
    //                 }
    //             });
    //         }

    //     }
    // }, [props.tableData]);

    useEffect(() => {
        setTabHeads(tabHeads.concat(newArray))
    }, []);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    // onClick={() => history.push('/target-data-reports')}
                    to="/target-data-reports"
                >
                    Target Data Reports
                </Link>
                <Typography color="textPrimary" variant='h4'>Results</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}
            >
                <div className={clsx({
                    [classes.row]: isDesktop,
                })} >
                    <Typography variant="h2"> Target Data Reports </Typography>
                    <div className={clsx({ [classes.searchInput]: isDesktop })}>
                        <Paper
                            className={clsx(classes.rootSearch, className)}
                        >
                            <SearchIcon className={classes.icon} />
                            <Input
                                className={classes.input}
                                disableUnderline
                                placeholder="Search"
                                onChange={handleSearchOnChange}
                            />
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="Close"
                                onClick={() => props.runTargetReports(0, 10, searchText)}
                                size="large">
                                <CheckIcon className={classes.icon} />
                            </IconButton>
                        </Paper>
                    </div>
                </div>
                {props.targetDataResults && props.targetDataResults.records && props.targetDataResults.records.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    {tabHeads
                                        .filter(function (elem, pos) { return tabHeads.indexOf(elem) == pos })
                                        .map(item => {
                                            return (
                                                <TableCell align={item.align ? 'left' : 'center'} className={classes.tableCell} key={item.ID}>
                                                    {item}

                                                </TableCell>
                                            )
                                        })}
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {props.targetDataResults.records.map((item) => {
                                    return (
                                        <StyledTableRow>
                                            {tabKeys.includes('calendar_year_month') ?
                                                <TableCell>{item.calendar_year_month}</TableCell>
                                                : ''}
                                            {tabKeys.includes('contract_number') ?
                                                <TableCell className={classes.tableCell}> {item['contract_number']}</TableCell>
                                                : ''}
                                            {tabKeys.includes('start_date') ?
                                                <TableCell>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                : ''}
                                            {tabKeys.includes('end_date') ?
                                                <TableCell>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                                : ''}
                                            {tabKeys.includes('customer_number') ?
                                                <TableCell>{item.customer_number}</TableCell>
                                                : ''}
                                            {tabKeys.includes('target_type') ?
                                                <TableCell>{item.target_type}</TableCell>
                                                : ''}
                                            {tabKeys.includes('target') ?
                                                <TableCell>{item.target}</TableCell>
                                                : ''}
                                            {tabKeys.includes('material_number') ?
                                                <TableCell>{item.material_number}</TableCell>
                                                : ''}
                                            {tabKeys.includes('supplier_number') ?
                                                <TableCell>{item.supplier_number}</TableCell>
                                                : ''}
                                            {tabKeys.includes('employee_number') ?
                                                <TableCell>{item.employee_number}</TableCell>
                                                : ''}
                                            {tabKeys.includes('sold_to_party') ?
                                                <TableCell>{item.sold_to_party}</TableCell>
                                                : ''}
                                            {tabKeys.includes('ship_to_party') ?
                                                <TableCell>{item.ship_to_party}</TableCell>
                                                : ''}
                                            {tabKeys.includes('bill_to_party') ?
                                                <TableCell>{item.bill_to_party}</TableCell>
                                                : ' '}
                                            {tabKeys.includes('payer') ?
                                                <TableCell>{item.payer}</TableCell>
                                                : ''}

                                        </StyledTableRow>

                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={props.targetDataResults.total_record}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        />
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No Results
                    </Typography>
                }
            </div>
            {props.targetDataCompleteResults &&
                <div className={classes.buttonContainer}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                </div>
            }
        </div>
    );

};

const mapStateToProps = state => {
    return {
        targetDataResults: state.operationalReportsData.targetReportsData,
        targetDataCompleteResults: state.operationalReportsData.targetReportsCompleteData,
        // tableData: state.addMultipleConfigurationData.multipleConfigurationData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        runTargetReports: (pagination, limit, keyword) => dispatch(runTargetReports(pagination, limit, keyword)),
        downloadSheet: (data) => dispatch(downloadSheet(data, 'calculation-simulations')),
        onLoadingDefault: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), 'Direct - Sales Data', null, null, 'Display Designer')),
        getLblDispDesFieldValues: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),



    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetReportsTable);