import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel, Button, Paper, Checkbox
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(theme => ({
    root: {
        //position: 'sticky',
        zIndex: 4,
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        marginTop: 10,
        width: 'auto',


    },
    container: {
        maxHeight: '290px', // Set maximum height
        overflowY: 'auto',
        borderRadius: '4px',
        border: '0.8px solid #E2E2E2',
        background: '#FFF'
    },
    dropdownContainer: {
        maxHeight: '350px', // Set maximum height
        overflowY: 'auto', // Enable vertical scrolling
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,
        backgroundColor: 'white'
    },
    accordionMenu: {
        padding: 4,
        margin: 0
        // border: '1px solid #E2E2E2',
    },
    menuHead: {
        color: '#19170F',
        fontSize: 12,
        fontWeight: 700,
        textTransform: 'uppercase'
        // lineHeight: 16
    },
    menuItem: {
        color: '#8D8D8D',
        fontSize: 12,
    },
    accordionHead: {
        padding: 7,
        minHeight: 36,
        margin: " 0px !important",
        width: 'fit-content'
    },
    accordionAction: {
        display: 'inline-flex',
        height: '44px',
        padding: '6px 6px 6px 22px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexShrink: 0,
        marginTop: '2px',
        borderRadius: '4px',
        border: ' 0.8px solid #E2E2E2',
        background: '#FFF',
        width: '100%'
    },
    accordionRoot: {
        backgroundColor: 'transparent',
        margin: 0,
        borderRadius: '0px 0px 0px 4px',
        border: ' 0.8px solid #E2E2E2',
        gap: 0

    },
    muiExpand: {
        minHeight: "44px !important",
        margin: '0px !important',
        alignItems: 'center'
    },
    confirmButton: {
        display: 'flex',
        height: '32px',
        padding: '3px 40px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '2px',
        border: '1px solid #FFF',
        background: '#19170F',
        color: '#FFF !important'
    },
    cancelBtn: {
        display: 'flex',
        height: '32px',
        padding: '3px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flexShrink: 0,
        borderRadius: 4,
        color: '#A1A1A1 !important'
    }

}));
const CustomGroup = (props) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState('');
    const [expanded, setExpanded] = useState(false);

    const handleChange = (item) => (event) => {
        setSelectedValue([...selectedValue, item.id]);
    };

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };
    const handleCancel = (event) => {
        event.stopPropagation();
        setExpanded([])
        props.onChange(null)
        // Handle cancel button click
    };

    const handleConfirm = (event) => {
        setExpanded([])
        props.onChange(selectedValue)
        //props.onSubmit(checkedData, checkedItems, selectedChild)
    };
    useEffect(() => {
        if (props.customId)
            setSelectedValue(props.customId)
    }, [props.customId])
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {props.customGroup && Object.entries(props.customGroup).map(([key, value]) => (
                    < Accordion expanded={expanded} onChange={handleAccordionChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            classes={{
                                expanded: classes.muiExpand, // Override styles when Accordion is expanded
                            }}
                        >
                            <Typography className={classes.menuHead}>{key}

                            </Typography>
                        </AccordionSummary>
                        <svg xmlns="http://www.w3.org/2000/svg" width="252" height="2" viewBox="0 0 252 2" fill="none">
                            <path d="M0 1L252 1.00002" stroke="#E2E2E2" marginLeft='0' />
                        </svg>
                        <AccordionDetails style={{ padding: 0 }} >
                            <FormControl fullWidth>
                                {value && value.filter(item => props.productLine?.includes(item.product_line)).length > 0 ?
                                    value.filter(item => props.productLine?.includes(item.product_line)).map(item =>
                                        <>
                                            <Typography className={`promo-font ${classes.menuItem}`} key={item.id}
                                                color={selectedValue.includes(item.id) ? '#19170F' : '#8D8D8D'}
                                            >
                                                <Checkbox key={item.id}
                                                    checked={selectedValue.includes(item.id) ? true : false}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={handleChange(item, key)}
                                                />
                                                {item.custom_group_name ? `${item.business_rule_name?.join("-")}- ${item.custom_group_name}` : item.business_rule_name?.join("-")}
                                            </Typography>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="252" height="2" viewBox="0 0 252 2" fill="none">
                                                <path d="M0 1L252 1.00002" stroke="#E2E2E2" />
                                            </svg>
                                        </>

                                    ) : <Typography className={`promo-font ${classes.menuItem}`}>No data</Typography>}
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                ))
                }
            </div>
            < Paper
                style={{
                    zindex: 2
                }}
                className={classes.accordionAction}
            >
                <Button onClick={handleCancel} style={{ cursor: 'pointer' }} className={classes.cancelBtn}>Cancel</Button>
                <Button onClick={handleConfirm} style={{ cursor: 'pointer' }} className={classes.confirmButton}>
                    Confirm
                </Button>
            </Paper>
        </div >
    );
};

export default CustomGroup;
