import React, { useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import clsx from 'clsx';
import { makeStyles, useTheme } from "@mui/styles";
import {
    Grid, Select, FormLabel, MenuItem, Button, Typography, Checkbox,
    ListItemText, Drawer, AppBar, Toolbar, CssBaseline, Divider, IconButton, TextField
} from '@mui/material';
import { getCustomerMasterData, getQualifierConfig, getPricingMarginRevenueData } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';
import SyncFusionColumnChart from '../../Analytics/SyncFusion/SyncFusionColumnChart';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '16px 0px 16px 0px'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        width: '100%'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textInput: {
        border: '1px solid #E0E0E0',
        width: '100%',
        borderRadius: 5,
        color: '#1675e0'
    },
    select: {
        width: 209,
        height: 36
    },
    selectedItem: {
        borderRadius: 5
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    }
}));

const RevenueMarginAnalytics = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        props.getConfigData(1, 0);
        props.onCheckCustomerTable();
    }, []);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,


    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [periodicity, setPeriodicity] = React.useState('day');
    const [listOpen, setListOpen] = React.useState('');
    const [type, setType] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [analysislevel, setAnalysisLevel] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const analysisLevelArray = ['customer_number', 'customer_group', 'customer_chain', 'material_number', 'material_group', 'product_hierarchy']
    const isAllSelected =
        analysisLevelArray.length > 0 && selected.length === analysisLevelArray.length;


    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (type == 'filter')
                setSelected(selected.length === analysisLevelArray ? [] : analysisLevelArray);
            else
                setSelected(selected.length === analysisLevelArray ? [] : analysisLevelArray);
            return;
        }
        setSelected(newSelected)

    }
    function onAdd() {
        if (type == 'filter')
            setFilters(selected)
        else
            setAnalysisLevel(selected)
        setListOpen(false)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])
        setType('')

    }
    const handleStartDate = (e) => {
        setStartDate(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }

    function handleOpen(type) {
        setType(type)
        if (type == 'filter')
            setSelected(filters)
        else
            setSelected(analysislevel)
        setListOpen(true)
    }


    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [forecastStartDate, setForecastStartDate] = React.useState(null);
    const [forecastEndDate, setForecastEndDate] = React.useState(null);
    const [filterList, setFilterList] = React.useState([]);
    const [active, setActive] = React.useState(false);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = [];
            var tempNameArray = [];
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNumberList(tempArray)
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.configData && props.configData.records) {
            const list = props.configData.records.map(e => e.qualifier_key)
            setFilterList(list);
        }
    }, [props.configData]);



    const handleSubmit = () => {
        setActive(true)
        filterList.map((item) => {
            if (filters.includes(item)) {

            } else {
                delete attributeObjectAPI[item]
            }
        })
        var sdate = Moment.utc(startDate).format('MM/DD/YYYY')
        var edate = Moment.utc(endDate).format('MM/DD/YYYY')

        props.pricingRevenueMargin(sdate, edate, analysislevel);

    }


    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});


    return (
        <div>
            <div className={classes.rootDiv}>
                <Grid className={classes.rootDiv}>
                    <Grid container className={classes.root} style={{ justifyContent: 'space-between' }} >
                        <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                    <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }} required={forecastStartDate && forecastEndDate ? false : true}>
                                        Start Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        color: '#1675e0',
                                                        width: 209,
                                                    }
                                                }}
                                                value={startDate}
                                                onChange={handleStartDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                    <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }}
                                        required={forecastStartDate && forecastEndDate ? false : true}>
                                        End Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        // alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        color: '#1675e0',
                                                        width: 209,
                                                    }
                                                }}
                                                value={endDate}
                                                onChange={handleEndDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={startDate ? startDate : false}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>


                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                    <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }} required>
                                        Analysis Level
                                    </FormLabel>
                                    <Select
                                        value={analysislevel}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={() => handleOpen('analysis_level')}
                                        onClose={() => setListOpen(false)}
                                        open={listOpen && type == 'analysis_level'}
                                        renderValue={(level) => level.toString().replace(/_/g, ' ')}
                                        style={{ textTransform: 'capitalize' }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        multiple
                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {analysisLevelArray.map(item => {
                                            return (
                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selected.indexOf(item) > -1} />
                                                    <ListItemText primary={item.replace(/_/g, ' ')} />
                                                </MenuItem>
                                            );
                                        })}
                                        <div className={classes.dropdownAction}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={1}
                                xs={12}
                                style={{ marginTop: 8 }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                                    <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit}
                                        disabled={startDate && endDate && analysislevel && analysislevel.length > 0 ? false : true} >
                                        Apply
                                    </Button>
                                </div>
                            </Grid>



                        </Grid>
                        {active ?
                            <div style={{ height: '100%', width: '100%' }}>
                                <SyncFusionColumnChart id={'combined-column'} loading={props.loading} data={props.pricingData} analysisLevel={analysislevel && analysislevel.length > 0 ? analysislevel[0] : ''} />

                            </div>
                            :
                            <Typography variant='h4'>
                                There is no data to show now.
                            </Typography>
                        }
                        <div style={{ width: '100%', borderBottom: '1px solid #e5e5e5' }} />
                        {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                        <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit}
                            disabled={startDate && endDate  && analysislevel && analysislevel.length > 0 ? false : true} >
                            Apply
                        </Button>
                    </div> */}
                    </Grid>
                </Grid>
            </div>
            {/* <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar style={{ background: '#FAFAFA' }}>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar> */}
            {/* <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                
                        
                   
            </main> */}

            {/* <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider />
                <Grid container className={classes.root} >
                    <Grid container style={{ margin: 10, padding: 5 }}>
                        <Grid
                            item
                            md={3}
                            xs={12}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel  style={{ color: 'grey', fontSize: 14, marginBottom: 5 }} required ={forecastStartDate&&forecastEndDate?false:true}>
                                  Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justify="space-around">
                                       <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel  style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}
                                required ={forecastStartDate&&forecastEndDate?false:true}>
                                     End Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justify="space-around">
                                       <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                      
                       
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }} required>
                                    Analysis Level
                                </FormLabel>
                                <Select
                                    value={analysislevel}

                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={() => handleOpen('analysis_level')}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen && type == 'analysis_level'}
                                    renderValue={(level) => level.toString().replace(/_/g, ' ')}
                                    style={{ textTransform: 'capitalize' }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {analysisLevelArray.map(item => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                      
                        
                    </Grid>
                    <div style={{ width: '100%', borderBottom: '1px solid #e5e5e5' }} />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                        <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit}
                            disabled={startDate && endDate  && analysislevel && analysislevel.length > 0 ? false : true} >
                            Apply
                        </Button>
                    </div>
                </Grid>
            </Drawer> */}
        </div >
    );
}


const mapStateToProps = state => {
    return {
        customerMasterData: state.customerData.customerMasterData,
        pricingData: state.initialData.pricingRevenueMarginData,
        loading: state.initialData.loading,
        configData: state.pricingData.qualifierConfigData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        pricingRevenueMargin: (startDate, endDate, analysislevel) => dispatch(getPricingMarginRevenueData(startDate, endDate, analysislevel)),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
        getConfigData: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RevenueMarginAnalytics);