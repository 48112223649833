import React, { useEffect } from 'react';
import {
    Grid, Button, Typography,
    Card, Table, TableCell, TableHead, TableRow,
    TableContainer, TableBody, TextField, OutlinedInput,
    FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getMembershipContractGroupFormatFields,
    updateMembershipContractGroupFormatData,
    getMembershipContractGroupFormatDataById
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const MembershipContractGroupEditQuery = props => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [currentId, setCurrentId] = React.useState(0);
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('membership-query') ? JSON.parse(sessionStorage.getItem('membership-query')) : []);
    const [requiredFields, setRequiredFields] = React.useState([
        'contract_group'
    ]);
    const [queryObject, setQueryObject] = React.useState({});
    const [defaultValue, setDefaultValue] = React.useState({});
    const [contractGroup, setContractGroup] = React.useState('');
    useEffect(() => {
        setOpen(true);
        props.getMembershipContractGroupFormatFields();
        props.getMembershipContractGroupFormatDataById(purchaseQueryDataStorage.id)
    }, []);
    useEffect(() => {
        if (props.configData) {
            setCurrentId(props.configData.format.id)
            setQueryName(props.configData.format.format_name)
            setQueryObject(props.configData.format)
            if (props.configData.default_values != null) {
                setDefaultValue(props.configData.default_values)
                setContractGroup(props.configData.default_values.contract_group)
            }
            if (props.configData.format.contract_group !== 0)
                setContractGroup(props.configData.format.contract_group)
        }
    }, [props.configData]);
    const handleInitialSetup = () => {
        // setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
        setDefaultValue({ ...defaultValue });
        setContractGroup('');
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit(currentId, { ...queryObject, 'format_name': queryName, 'default_values': defaultValue });
    }
    function handleClearAll() {
        handleClear();
    }
    function handleCancelDialog() {
        history.push({ pathname: '/membership-contract-group-excel-upload' });
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'contract_group') {
            setContractGroup(event.target.value);
        }
    };
    const handleChange2 = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
        if (item === 'contract_group') {
            setContractGroup(event.target.value);
        }
    };
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>Edit Format</Typography>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.queryListData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead}>Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.queryListData
                                                        .filter(item => item != 'format_name' && item != 'default_values' && item != 'created_by_id' && item != 'updated_by_id' && item != '' && item != '0')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        {requiredFields.includes(item) ? <p>{item.replace(/_/g, ' ')}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}

                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item] ? queryObject[item] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={defaultValue[item] ? defaultValue[item] : ''}
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange2(e, item)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={contractGroup ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data, type) => dispatch(updateMembershipContractGroupFormatData(id, data)),
        getMembershipContractGroupFormatFields: (type) => dispatch(getMembershipContractGroupFormatFields()),
        getMembershipContractGroupFormatDataById: (id, type) => dispatch(getMembershipContractGroupFormatDataById(id)),
    }
}

const mapStateToProps = state => {
    return {
        queryListData: state.customerData.membershipContractGroupFormatFieldData,
        configData: state.customerData.membershipContractGroupFormatDataById,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipContractGroupEditQuery);