import React, { useEffect } from 'react';
import {
    Typography,
    Breadcrumbs,
    Button,
    Grid,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { AssignSticker, SetupData, Region } from './ViewSetup';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { getPromotionMaterialData, getPromotionSetupById, getCountryData } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader'
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },

    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));
const tabs = {
    GeneralData: 0,
    Stickers: 1,
    Region: 2,
};

function getSteps() {
    return ['GeneralData', 'Stickers', 'Region'];
}

const PromotionSetup = props => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [completed, setCompleted] = React.useState(new Set());
    const [promoData, setPromoData] = React.useState([])
    const [promoMaterials, setPromoMaterials] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [editID, setEditID] = React.useState(0);
    const [country, setCountry] = React.useState('')
    const skipped = new Set()
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getPromotionSetup(appId);

    }, []);
    useEffect(() => {
        if (props.promoData && Object.entries(props.promoData).length > 0) {
            setPromoData(props.promoData)
        }
    }, [props.promoData])

    useEffect(() => {
        setCountry(localStorage.getItem('Country'))
        props.getCountryData(localStorage.getItem('Country'))
    }, [localStorage.getItem('Country')])
    useEffect(() => {

        var temp = []
        if (props.countryData && props.countryData.length > 0) {
            props.countryData
                .map((item, index) => {
                    temp.push(item.state)

                })


            setStates(temp)
        }
    }, [props.countryData])
    useEffect(() => {
        if (props.materialData && props.materialData.length > 0) {
            setPromoMaterials(props.materialData)
        }
    }, [props.materialData])
    useEffect(() => {
        if (promoMaterials && promoMaterials.length > 0 && states && states.length > 0) {

            promoMaterials.map((item, index) => {
                var temp = []
                promoMaterials[index]['country'] = country
                promoMaterials[index]['id'] = index
                states.map(state => {
                    temp.push({ 'promotion_name': '', 'promotion_description': '', 'state': state })

                })
                promoMaterials[index]['promotion_state'] = temp
            })
            setPromoMaterials(promoMaterials)
        }
    }, [promoMaterials, states])

    const totalSteps = () => {
        return getSteps().length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/promotion-setup");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const skippedSteps = () => {
        return skipped.size;
    };
    const completedSteps = () => {
        return completed.size;
    };

    function getStepContent(step) {
        const allSteps = [
            <SetupData
                handleNext={handleNext}
                editMode={true}
            />,
            <AssignSticker
                editMode={true}
            />,
            <Region
                regionData={promoData.promotion_budget_eligibility && promoData.promotion_budget_eligibility.length > 0 ? promoData.promotion_budget_eligibility : promoMaterials}
                states={states}
            />,
        ];

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div>
                <div className={classes.root}>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link variant='h1' classes={{
                            root: classes.link
                        }}
                            to='/promotion-setup'
                        >
                            Promotion Setup
                        </Link>
                        <Typography color="textPrimary" variant='h4'>View Setup</Typography>
                    </Breadcrumbs>
                    <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                    <Grid container >
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {activeStep !== 0 &&
                                <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                    Previous Step
                                </Button>
                            }
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >

                            <Button variant="outlined" color='primary' className={classes.button} onClick={() => history.push('/promotion-setup')}>
                                Cancel
                            </Button>


                            {activeStep != tabs.Region &&
                                <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}>
                                    Next Step
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        materialData: state.promotionData.promotionMaterialData,
        promoData: state.promotionData.promotionSetupSingleData,
        countryData: state.promotionData.countryData,
        loading: state.promotionData.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMaterialData: () => dispatch(getPromotionMaterialData()),
        getPromotionSetup: (id) => dispatch(getPromotionSetupById(id)),
        getCountryData: (country) => dispatch(getCountryData(country)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSetup);