export default {
    root: {
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#445AC7',
            color: 'white'
        },
        '& .MuiDataGrid-footer': {
            display: 'none'
        }
    }
};