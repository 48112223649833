import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Typography
} from '@mui/material';
import Chart from "react-apexcharts";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '20px 30px 20px 30px',
        borderRadius: 10
    },
    chartContainer: {
        marginTop: 30,
        height: 300
    },
    formLabel: {
        width: '100%',
        paddingLeft: 20,
        paddingTop: 10,
        fontSize: theme.typography.h1.fontSize,
        display: 'flex',
        justifyContent: 'center',

    },
}));

const PromotionCostPercentage = props => {
    const classes = useStyles();
    const [series, setSeries] = React.useState([]);
    const [options, setOptions] = React.useState({});

    useEffect(() => {
        var seriesArr = [];
        if (props.promoAnalyCost && props.promoAnalyCost.length > 0) {
            if (props.promoAnalyCost && props.promoAnalyCost[0]) {
                var item = props.promoAnalyCost[0];
                seriesArr = [
                    item['cost_coverage'] ? item['cost_coverage'] : 0,
                    item['cost_extra_rebate'] ? item['cost_extra_rebate'] : 0,
                    item['cost_promo_finance'] ? item['cost_promo_finance'] : 0,
                    item['cost_rebate'] ? item['cost_rebate'] : 0,
                    item['cost_spiff'] ? item['cost_spiff'] : 0,
                    item['cost_std_finance'] ? item['cost_std_finance'] : 0,
                ]
                setSeries(seriesArr);
            }
            setOptions(
                {
                    chart: {
                        type: 'pie',
                        height: 350
                    },
                    colors: ['#FF6633', '#FFB399', '#FF33FF', '#00B3E6',
                        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
                    labels: ['Coverage', 'Extra Rebate', 'Promo Finance', 'Rebate', 'Spiff', 'Standard Finance'],
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: 250
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                }
            )
        }

    }, [props.promoAnalyCost])

    return (

        <div className={classes.root}>
            <Typography color="primary" className={classes.formLabel}> Cost Allocation by Promotion Type </Typography>
            <div className={classes.chartContainer}>
                {
                    series && series.length > 0 && options && Object.keys(options) && Object.keys(options).length > 0 &&
                    <Chart options={options} type="pie" series={series} height={300} />
                }

            </div>
        </div>
    );
};

export default PromotionCostPercentage;