import React, { useEffect } from 'react';
import {
    Button, ToggleButton, ToggleButtonGroup, Accordion, AccordionSummary, AccordionDetails, Typography,
    Divider, IconButton, Dialog, DialogActions,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { CountertopsOutlined, ExpandMore, Label } from '@mui/icons-material';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { RingLoader } from 'react-spinners';
import { validateMarkets } from '../../../../redux/actions';
import { DialogContent } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1,
        gap: 10
    },
    menuHead: {
        display: 'flex',
        flexDirection: 'column'
    },
    menuDetail: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    container: {
        marginTop: 15,
    },
    listItem: {
        width: '70%'
    },
    accordion: {
        marginRight: 3, border: '0.8px solid #E2E2E2',
    },
    accordionSelected: {
        marginRight: 3, border: '0.8px solid',
        borderColor: theme.palette.primary.main
    },
    statusBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 30
    }

}))

const Market = (props) => {

    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const [reviewType, setReviewType] = React.useState('Pending');
    const [select, setSelect] = React.useState('-1');
    const [selectedStates, setSelectedStates] = React.useState([]);
    const [validate, setValidate] = React.useState([]);
    const [formattedStates, setFormattedStates] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedMarket, setSelectedMarket] = React.useState([]);
    const [totalPending, setTotalPending] = React.useState(0);
    const [totalCompleted, setTotalCompleted] = React.useState(0);

    useEffect(() => {
        let pendingCount = 0;
        let completedCount = 0;

        if (props.markets?.market) {
            Object.values(props.markets.market).forEach(element => {
                if (element.promo_team_review === 'Pending') {
                    pendingCount++
                }
                else
                    completedCount++
            });
        }
        setTotalPending(pendingCount)
        setTotalCompleted(completedCount)
    }, [props.markets]);

    const handleStates = (state, region) => {
        var data = { 'state': state, 'region': region }
        setSelectedStates(data)
        props.handleStates(data)
    }
    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null)
            setReviewType(newAlignment)

    }
    const handlefilter = (event, key) => {
        event.stopPropagation();
        //setSelect(key)
        if (!selectedMarket.includes(key))
            setSelectedMarket([...selectedMarket, key])
        else
            setSelectedMarket(selectedMarket.filter(item => item != key))

        //props.handleRegion(item)
    }
    const handleAccordionClick = (event, item, key) => {
        event.stopPropagation()
        if (key != select)
            props.handleRegion(item)
        setSelect(key);
    };
    const handleValidate = (key) => {
        if (key) {
            setValidate([...validate, key])
            setSelect('')
            var data = {
                "scenario_number": props.markets.scenario_number,
                "market_name": key,
                "is_review_completed": false
            }
            props.updateMarket(data)
            setSelectedMarket(selectedMarket.filter(item => item != key))
        }

    }
    function handleClose() {
        setOpen(false)
    }

    function handleSend() {
        setOpen(false)
        var data = {
            "scenario_number": props.markets.scenario_number,
            "is_review_completed": true
        }
        props.updateMarket(data)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "30px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader />}
        >
            <div>
                <ToggleButtonGroup
                    color="primary"
                    variant='contained'
                    value={reviewType}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    fullWidth
                >
                    <ToggleButton value="Pending" variant='contained'>Pending Review</ToggleButton>
                    <ToggleButton value="Done">Completed</ToggleButton>
                </ToggleButtonGroup>
                <div className={classes.container}>
                    {props.markets && props.markets.market &&
                        Object.entries(props.markets.market).filter(([key, value]) => value.promo_team_review == reviewType).length > 0 ?
                        Object.entries(props.markets.market).filter(([key, value]) => value.promo_team_review == reviewType).map(([key, value]) => (

                            <Accordion
                                className={key == select ? classes.accordionSelected : classes.accordion}
                                //onClick={() => handleAccordionClick(key)} // Change border color on click
                                onChange={(event, isExpanded) => handleAccordionClick(event, value, key)} // Prevent onChange from expanding accordion
                                expanded={selectedMarket && selectedMarket.includes(key)}
                            >

                                <AccordionSummary expandIcon={<ExpandMore color='primary' onClick={(event) => handlefilter(event, key)} />}>
                                    <div className={classes.menuHead}>
                                        <Typography variant='h5' color={'grey'} >
                                            {key + " ( " + (value.states ? Object.values(value.states).flatMap(e => e).length : 0) + ' States )'}
                                        </Typography>
                                        {!(selectedMarket && selectedMarket.includes(key)) &&
                                            <Typography className='promo-font' color={'black'} >{value.label}</Typography>
                                        }
                                    </div>
                                </AccordionSummary>

                                <AccordionDetails style={{ width: '100%', marginTop: 2, marginBottom: 15 }}>
                                    {value.states && Object.entries(value.states).map(([k, v]) => (
                                        <div className={classes.menuHead} style={{ width: '100%' }}>
                                            <Typography className='promo-font' color={'black'} >{k}</Typography>
                                            <div className={classes.menuDetail}>
                                                <Typography className={`promo-font ${classes.listItem}`} color={'grey'}  >{console.log(selectedStates)}
                                                    {v ? v.map(state => (
                                                        <IconButton aria-label={state} style={{ fontSize: '14px' }} key={`${k}-${state}`}
                                                            color={(selectedStates && selectedStates.region == k && selectedStates.state == state) ? 'primary' : ''}
                                                            onClick={() => handleStates(state, k)}
                                                        >
                                                            {state}
                                                        </IconButton>
                                                    )) : ''}
                                                </Typography>
                                                <Typography variant='h5' color={'black'} >{v && v.length + ' States '}</Typography>
                                            </div>
                                        </div>

                                    ))}

                                    <Divider />
                                    <div className={classes.menuHead} style={{ paddingTop: 5, paddingBottom: 5 }}>
                                        <Typography variant='h5' className='promo-font' color={'grey'} >Total Promo Cost on this market</Typography>
                                        <Typography className='promo-font' color={'black'} >{`${value.total_cost ? value.total_cost.toFixed(2) : ''} ${props.markets?.summary?.summary?.promotion_currency}`}</Typography>
                                    </div>

                                    {reviewType === 'Pending' &&
                                        <>
                                            <Divider />
                                            <div className={classes.buttonContainer}>
                                                {props.page == 'costing_approval' &&
                                                    <Button disabled={key !== select} variant='outlined' style={{ marginTop: 10 }} onClick={() => handleValidate(key)}>Decline Market</Button>
                                                }
                                                <Button disabled={key !== select} variant='outlined' style={{ marginTop: 10 }} onClick={() => handleValidate(key)}>Validate Market</Button>
                                            </div>
                                        </>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        ))
                        : <Typography className='promo-font' textAlign='center' color='grey'>No markets available</Typography>
                    }
                </div>
                {reviewType == 'Done' && props.markets?.promo_team_review != 'Done' &&
                    <div className='review-btn-container'>
                        <Button disabled={totalPending !== 0 || totalCompleted === 0} variant='contained' className='review-btn' style={{ marginTop: 30 }} onClick={() => setOpen(true)}>Reviewed</Button>
                    </div>
                }
                {
                    props.markets.promo_team_review == 'Done' &&
                    <div className={classes.statusBox}>
                        <Typography className='promo-font status-label' >Promo Team  Revision : <span className='status-done'>{props.markets?.promo_team_review}</span></Typography>
                        <Typography className='promo-font status-label' >Finance Team  Revision :
                            {props.markets?.finance_team_review == 'Pending' &&
                                <span className={`status-pending`}>{props.markets?.finance_team_review}</span>}
                            {props.markets?.finance_team_review == 'Done' &&
                                <span className={`status-done`}>{props.markets?.finance_team_review}</span>}
                        </Typography>
                    </div>
                }
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
            //

            >
                <DialogContent style={{ display: 'flex', gap: 10 }}>
                    <div>
                        <Typography className='tickicon-container'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="12" fill="#55C262" />
                            <path d="M18.0002 6.99984L16.5902 5.58984L10.2502 11.9298L11.6602 13.3398L18.0002 6.99984ZM22.2402 5.58984L11.6602 16.1698L7.48016 11.9998L6.07016 13.4098L11.6602 18.9998L23.6602 6.99984L22.2402 5.58984ZM0.410156 13.4098L6.00016 18.9998L7.41016 17.5898L1.83016 11.9998L0.410156 13.4098Z" fill="white" fill-opacity="0.56" />
                        </svg>
                        </Typography>
                    </div>
                    <Typography className='promo-font popup-title' variant="body1" >
                        Congratulation you are done reviewing <br /> your Scenario!<br />
                        It will be now shared with finance.
                    </Typography>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', marginBottom: 10 }}>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleSend} >Share for review</Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay>
    )
}
const mapStateToProps = state => {
    return {
        loading: state.promotionData.marketloading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        // getConfigData: () => dispatch(getBudgetingConfig()),
        updateMarket: (data) => dispatch(validateMarkets(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Market)

