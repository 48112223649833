import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../../Analytics/Charts/SplineChart";
import Moment from "moment";
import {
    promPlanSalsIncentives
} from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        padding: "20px 30px 20px 30px",
        borderRadius: 10,
    },
    formLabel: {
        fontSize: 13, //theme.typography.h4.fontSize,
        textTransform: "capitalize",
    },
    chartHead: {
        width: "100%",
        paddingLeft: 20,
        // paddingTop: 10,
        fontSize: theme.typography.h2.fontSize,
        display: "flex",
        justifyContent: "center",
        marginBottom: 30,
    },
}));
const SalesIncentivesProfit = (props) => {
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);

    // console.log(props.data)
    useEffect(() => {
        if (props.data) {
            if (
                props.data &&
                (
                    (props.data.base_price &&
                        props.data.base_price !== null &&
                        props.data.base_price.length > 0) ||
                    (props.data.costs &&
                        props.data.costs !== null &&
                        props.data.costs.length > 0) ||
                    (props.data.off_invoice_adjustments &&
                        props.data.off_invoice_adjustments !== null &&
                        props.data.off_invoice_adjustments.length > 0) ||
                    (props.data.on_invoice_adjustments &&
                        props.data.on_invoice_adjustments !== null &&
                        props.data.on_invoice_adjustments.length > 0) ||
                    (props.data.profit &&
                        props.data.profit !== null &&
                        props.data.profit.length > 0))
            ) {
                var data = [
                    {
                        data:
                            props.data &&
                                props.data.base_price
                                ? props.data.base_price
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Base Price",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#0000FF",
                    },
                    {
                        data:
                            props.data &&
                                props.data.costs
                                ? props.data.costs
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Cost",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#CA4218",
                    },
                    {
                        data:
                            props.data &&
                                props.data.off_invoice_adjustments
                                ? props.data.off_invoice_adjustments
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Off Invoice Adjustment",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#1cfcf1",
                    },
                    {
                        data:
                            props.data &&
                                props.data.on_invoice_adjustments
                                ? props.data.on_invoice_adjustments
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "On Invoice Adjustment",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#fff836",
                    },
                    {
                        data:
                            props.data &&
                                props.data.profit
                                ? props.data.profit
                                : [],
                        xName: "period",
                        yName: "data",
                        legend: "Profit",
                        type: "Spline",
                        shape: "Diamond",
                        color: "#58FF33",
                    },
                ];
                setAvssGraphData(data);
            } else setAvssGraphData([]);
        } else setAvssGraphData([]);
    }, [props.data]);
    return (
        <div className={classes.root}>
            <Typography variant="h2" className={classes.chartHead} style={{ marginTop: props.margin === 'm' ? "-7px" : "0px" }}>
                Revenue-Profit Trending
            </Typography>
            <div style={{ marginTop: "-15px" }}>
                <SplineChart id="claim-chart" data={avssGraphData}
                    min={props.data.end} max={props.data.start}
                />
            </div>
        </div >
    );
};
const mapStateToProps = (state) => {
    return {
        promPlanSalsIncentives: state.initialData.dashboardCountData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        promPlanSalsIncentives: (sd, ed) => dispatch(promPlanSalsIncentives(sd, ed)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesIncentivesProfit);