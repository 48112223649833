import React, { useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Button,
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    FormLabel,
    Select,
    MenuItem,
    Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import secureLocalStorage from 'react-secure-storage';
import { getDefaultValues, addFiscalYearData, fetchFiscalYearData, fetchDropdownConfig, addDropdownConfig } from '../../redux/actions';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'left',
        paddingLeft: 28
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    gridContainer: {
        width: '100%'
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 40
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));
const FiscalYearTab = props => {
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [monthArray, setMonthArray] = React.useState(Moment.months() ? Moment.months() : []);
    const [type, setType] = React.useState('');
    const [putActive, setPutActive] = React.useState(false);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [currentID, setCurrentID] = React.useState(0);
    const [dropdownID, setDropdownID] = React.useState(0);
    const [dropdownData, setDropdownData] = React.useState({});
    const [dropdownValue, setDropdownValue] = React.useState({});
    const classes = useStyles();
    const { className } = props;
    const dropdownList = ['customer_number', 'material_number', 'supplier_number', 'employee_number']

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        props.onLoadingDefault(sessionStorage.getItem('application'));
        props.getFiscalYearData();
        props.getDropdownData();
    }, []);
    useEffect(() => {
        if (props.defaultFiscalYearData && props.defaultFiscalYearData[0]) {
            if (props.defaultFiscalYearData[0].fiscal_start_month) {
                setStartDate(props.defaultFiscalYearData[0].fiscal_start_month)
            }
            if (props.defaultFiscalYearData[0].fiscal_end_month) {
                setEndDate(props.defaultFiscalYearData[0].fiscal_end_month)
            }
            if (props.defaultFiscalYearData[0].id) {
                setCurrentID(props.defaultFiscalYearData[0].id)
            }
        }
    }, [props.defaultFiscalYearData])
    useEffect(() => {
        console.log(props.dropdownConfigData)
        if (props.dropdownConfigData && props.dropdownConfigData[0]) {
            if (props.dropdownConfigData[0].default_configuration) {
                setDropdownData({ ...props.dropdownConfigData[0].default_configuration })
                setDropdownValue({ ...props.dropdownConfigData[0].default_configuration })
            }
            setDropdownID(props.dropdownConfigData[0].id)
        }
    }, [props.dropdownConfigData])
    const handleChange = (item, type) => {
        setSelectedRowName(item);
        setPutActive(true);
        setType(type)
    };
    function handleFiscalValue(value) {
        if (selectedRowName == 'startDate')
            setStartDate(value)
        else
            setEndDate(value)
        setPutActive(true)
    }
    function handleDropdownValue(value) {
        setDropdownValue({ ...dropdownValue, [selectedRowName]: value })
        setPutActive(true)
    }
    function handleSubmit(clear) {
        if (clear === 'clear') {
            if (type == 'dropdown') {
                var data = {
                    'default_configuration': { ...dropdownValue, [selectedRowName]: '' },
                    "key_name": "dropdown_configuration"
                }
                props.submitDropdownConfig(data, dropdownID);
            }
            else {
                var data = {
                    "fiscal_start_month": selectedRowName != 'startDate' ? startDate : '',
                    "fiscal_end_month": selectedRowName != 'endDate' ? endDate : '',
                }
                props.onSubmit(data, currentID, 'clear');
            }
        } else {
            if (type == 'dropdown') {
                var data = {
                    'default_configuration': dropdownValue,
                    "key_name": "dropdown_configuration"
                }
                props.submitDropdownConfig(data, dropdownID);
            }
            else {
                var data = {
                    "fiscal_start_month": startDate,
                    "fiscal_end_month": endDate,
                }
                props.onSubmit(data, currentID);
            }
        }
        setPutActive(false)
        setStartDate('')
        setEndDate('')
        setSelectedRowName('')
    }
    return (
        <div className={clsx(classes.root, className)}>
            {type &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{type == 'dropdown' ? 'Dropdown Configuration' : ' Fiscal Year Definition'}</Typography>
                </div>}
            <div
                className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} capitalize heading='Field Name' data={selectedRowName ? (selectedRowName === 'startDate' ? 'Fiscal Year Start Date' : selectedRowName === 'endDate' ? 'Fiscal Year End Date' : selectedRowName.replace(/_/g, ' ')) : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    className={classes.gridContainer}
                                    style={{ padding: "15px 15px 15px" }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} error={false}>
                                        Field Value
                                    </FormLabel>
                                    <div>

                                        {type == 'dropdown' ?
                                            <Select
                                                value={dropdownValue && dropdownValue[selectedRowName]}
                                                onChange={(e) => handleDropdownValue(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value={true} key={true} style={{ textTransform: 'capitalize' }}>
                                                    Yes
                                                </MenuItem>
                                                <MenuItem value={false} key={false} style={{ textTransform: 'capitalize' }}>
                                                    No
                                                </MenuItem>

                                            </Select>
                                            :
                                            <Select
                                                value={selectedRowName ? selectedRowName === 'startDate' ? startDate : endDate : ''}
                                                onChange={(e) => {
                                                    handleFiscalValue(e.target.value)
                                                    setPutActive(true)
                                                }}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                {monthArray && monthArray.length > 0 && monthArray
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>}
                                    </div>
                                </Grid>
                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_fiscal_year_definition-' + sessionStorage.getItem('application')) &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        className={classes.buttonContainer}
                                        style={{ paddingLeft: 20, marginRight: 10, justifyContent: 'center' }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                            classes={{
                                                root: classes.checkIconContainer
                                            }}
                                            disabled={!putActive}
                                            onClick={() => handleSubmit()}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<CheckIcon />}
                                            classes={{
                                                root: classes.checkIconContainer
                                            }}
                                            onClick={() => handleSubmit('clear')}
                                            style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="Fiscal Year Definition"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Field Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('startDate', 'fiscalYear')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Fiscal Year Start Date
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {props.defaultFiscalYearData && props.defaultFiscalYearData[0] && props.defaultFiscalYearData[0].fiscal_start_month ? props.defaultFiscalYearData[0].fiscal_start_month : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('endDate', 'fiscalYear')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Fiscal Year End Date
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }} >
                                                    {props.defaultFiscalYearData && props.defaultFiscalYearData[0] && props.defaultFiscalYearData[0].fiscal_end_month ? props.defaultFiscalYearData[0].fiscal_end_month : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>

                </Card>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        defaultFiscalYearData: state.financialCloseData.fiscalYearData,
        dropdownConfigData: state.financialCloseData.dropdownConfig
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id, type) => dispatch(addFiscalYearData(data, id, type)),
        getFiscalYearData: (id) => dispatch(fetchFiscalYearData(id)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues('Customer Rebate', null, null, null, 'Contract Designer')),
        getDropdownData: () => dispatch(fetchDropdownConfig()),
        submitDropdownConfig: (data, id) => dispatch(addDropdownConfig(data, id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FiscalYearTab);