import React, { useEffect, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { TextInputWithLabel } from '../../../../../components/Inputs';
import { getContractDetailsData } from '../../../../../redux/actions';
import clsx from 'clsx';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        paddingLeft: 16,
        paddingRight: 16,
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    }
}));

const FlexFields = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const { className } = props;
    const [flexFields5Array, setFlexFields5Array] = React.useState([]);
    const [flexFields5, setFlexFields5] = React.useState('');
    const [flexFields6Array, setFlexFields6Array] = React.useState([]);
    const [flexFields6, setFlexFields6] = React.useState('');
    var flexFieldValues = {
        flex_fields_5: flexFields5,
        flex_fields_6: flexFields6
    };
    function handleFlexFields5(newValue) {
        setFlexFields5(newValue);
    }
    function handleFlexFields6(newValue) {
        setFlexFields6(newValue);
    }

    const [apiResponse2, setApiResponse2] = React.useState([]);
    useEffect(() => {
        props.onChange(flexFieldValues);
    }, [apiResponse2]);

    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'flex_fields_5') {
                            setFlexFields5Array(item);
                        }
                        if (item.field_id === 'flex_fields_6') {
                            setFlexFields6Array(item);
                        }
                    })
                }
            }
        }
    }, [props.dropdownData.records]);


    return (
        <div
            className={clsx(classes.root, className)}
        >
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="CUSTOMER SPECIFIC FIELDS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <div className={classes.container}>
                        <Grid container >
                            {flexFields5Array && flexFields5Array.enabled &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={flexFields5Array.name} twoline="true" onChange={handleFlexFields5} />
                                </Grid>
                            }
                            {flexFields6Array && flexFields6Array.enabled &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={flexFields6Array.name} twoline="true" onChange={handleFlexFields6} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div>
    );

};

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FlexFields);