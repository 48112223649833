import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Fab, Typography } from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1
  }
}));
const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const IntegrationSetupToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  console.log(secureLocalStorage.getItem("dYtz").role.rights)
  return (
    <div
      {...rest}
      className={classes.root}
    >
      {sessionStorage.getItem("application") === 'Customer Rebate' &&
        secureLocalStorage.getItem("dYtz").role.rights &&
        secureLocalStorage
          .getItem("dYtz")
          .role.rights.includes(
            "post_batch_job-" + sessionStorage.getItem("application")
          )
        //  sessionStorage.getItem("application") ===
        //    "Sales Commission"
        && <Fab aria-label="edit" variant="extended"
          size='medium'
          classes={{ root: classes.fabContainer }}
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/batchjob-setup/add-batchjob-setup'
        >
          <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
        </Fab>}
      {sessionStorage.getItem("application") === 'Supplier Rebate'
        && <Fab aria-label="edit" variant="extended"
          size='medium'
          classes={{ root: classes.fabContainer }}
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/batchjob-setup/add-batchjob-setup'
        >
          <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
        </Fab>}
      {sessionStorage.getItem("application") == 'Customer Chargeback'
        && <Fab aria-label="edit" variant="extended"
          size='medium'
          classes={{ root: classes.fabContainer }}
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/add-batchjob-setup-customer-chargeback'
        >
          <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
        </Fab>

      }
      {sessionStorage.getItem("application") == 'Supplier Chargeback'
        && <Fab aria-label="edit" variant="extended"
          size='medium'
          classes={{ root: classes.fabContainer }}
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/add-batchjob-setup-customer-chargeback'
        >
          <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
        </Fab>

      }
      {sessionStorage.getItem("application") === 'Transaction Data' &&
        secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role &&
        secureLocalStorage.getItem('dYtz').role.rights &&
        secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-' + sessionStorage.getItem('application')) && (
          <Fab aria-label="edit" variant="extended"
            size='medium'
            classes={{ root: classes.fabContainer }}
            className={classes.fabContainer}
            component={CustomRouterLink}
            to='/transaction-data-batchjob-setup/add-batchjob-setup'
          >
            <LibraryAdd color='primary' />
            <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
          </Fab>
        )}
      {sessionStorage.getItem("application") === 'Pricing' && <Fab aria-label="edit" variant="extended"
        size='medium'
        classes={{ root: classes.fabContainer }}
        className={classes.fabContainer}
        component={CustomRouterLink}
        to='/pricing-batchjob-setup/add-batchjob-setup'
      >
        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
      </Fab>}
      {sessionStorage.getItem("application") === 'Profit Optimization' && <Fab aria-label="edit" variant="extended"
        size='medium'
        classes={{ root: classes.fabContainer }}
        className={classes.fabContainer}
        component={CustomRouterLink}
        to='/profitopt-batchjob-setup/add-batchjob-setup'
      >
        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
      </Fab>}
      {sessionStorage.getItem("application") === 'Integration Studio' && <Fab aria-label="edit" variant="extended"
        size='medium'
        classes={{ root: classes.fabContainer }}
        className={classes.fabContainer}
        component={CustomRouterLink}
        to='/integration-batchjob-setup/add-integration-batchjob-setup'
      >
        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
      </Fab>}
    </div >
  );

};


export default connect(null, null)(IntegrationSetupToolbar);