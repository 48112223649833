import React, { useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery,IconButton,Paper
} from '@mui/material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getMembershipData
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { MembershipToolbar } from './';
import Moment from 'moment';
import HashLoader from "react-spinners/HashLoader";
import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Aggregate,
    ExcelExport, Toolbar, PdfExport, Search, CommandColumn, AggregateDirective, AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, ColumnMenu, VirtualScroll
} from '@syncfusion/ej2-react-grids';

import {DataUtil} from '@syncfusion/ej2-data';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        //width:'100%',
        width:'fit-content',
        overflow: 'scroll'
        
    },
    gridBox: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginBottom:10,
        //overflowX: 'hidden',
        overflowY: 'scroll'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableCell: {
        paddingLeft: 18
    },
    buttonContainer: {
        display: 'flex',
    },
    button: {
        marginLeft: 15
    },
    sumContainer: {
        // backgroundColor: theme.palette.primary.main,
        // color: 'white',
        color: theme.palette.primary.main
    },
    cell: {
        backgroundColor: 'white !important',
        background: 'white !important',
        color: 'black'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const MasterReportResults = props => {
    const { className } = props;
    let grid;
    const method=props.type
    const memberID=props.memberId
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    useEffect(() => {
        var newArray=[]
        if (props.masterReportData && props.masterReportData.length > 0) {            
            Object.values(props.masterReportData).map(item=>{
               Object.keys(item).map((e,index)=>{
                    newArray.push({'field':e,'title':e ? e.replace(/_/g, ' '):e})
                })
                
            }) 
            setColumns(newArray)
            setDataRows(props.masterReportData);
            setDataCount(props.masterReportData.length)
        }
    }, [props.masterReportData]);
    const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search']
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
            grid.showSpinner();
            const exportProperties = {
                pageOrientation: 'Landscape',
                pageSize: 'A0',
                fileName: Moment.utc(new Date()).format('MM/DD/YYYY')+'.pdf',
                theme: {
                    header: {
                        bold: true,
                        fontSize: 14,
                    },
                    record: {
                        bold: false,
                        fontSize: 14,
                    },
                    caption: {
                        bold: true,
                        fontSize: 14,
                    },
                }
            };
            grid.pdfExport(exportProperties);
        }
        if (grid && args.item.id === 'grid_excelexport') {
            grid.showSpinner();
            const excelExportProperties = {
                dataSource: [...dataRows],
            };
            grid.excelExport(excelExportProperties);
        }
    };
   
    const pdfExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const excelExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const filterSettings = { type: 'CheckBox' };
    const groupOptions={showGroupedColumn:true}
    const valueFormat = (field, data, column) => {
        if (dataRows && dataRows.includes(field)) {
            return parseFloat(data[field]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).toString()

        }
       else if (dataRows && dataRows.includes(field)) {
            return Moment(data[field], true).isValid()
                ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
        }
        else
            return data[field];
    };
    const dataBound = () => {
        if (grid) {
          grid.autoFitColumns([
            'customer_name',
            'street',
            'GPO_member_name',
            'action_code',
            'city',
          ])
    
        }
      };
    return (
        
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner = {<HashLoader />}
                className={classes.spinner}
            >
                {props.masterReportData && props.masterReportData.length > 0 ?
                <Paper className={classes.gridBox} style={{ paddingTop: 0,}}>
                   <div style={{ marginTop: 10, overflow: 'scroll'}}>
                     <GridComponent
                    dataSource={dataRows}
                    id='grid' 
                    dataBound={dataBound} 
                    allowSorting={true} allowGrouping={true} showColumnMenu={true}
                    groupSettings={groupOptions}
                    filterSettings={filterSettings}
                    allowFiltering={true}
                    ref={g => grid = g}
                    toolbar={toolbarOptions}
                    toolbarClick={toolbarClick}
                    allowPdfExport={true} allowExcelExport={true}
                    pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
                    height={window.screen.height * .80}
                    gridLines='Both' allowResizing={true}
                    enableVirtualization={true} enableColumnVirtualization={false}
                    >
                    <ColumnsDirective>
                    {columns.map((item, order) => {
                        return (
                        <ColumnDirective
                        field={item.field}
                        format={item.field === 'member_start_date' ? 'MM/dd/yyyy' : item.field === 'member_end_date' ? 'MM/dd/yyyy': item.field === 'action_effective_date' ? 'MM/dd/yyyy': item.field === 'contract_start_date' ? 'MM/dd/yyyy' : item.field === 'contract_end_date' ? 'MM/dd/yyyy': null}
                        type={item.field === 'member_start_date' ? 'dateTime' : item.field === 'member_end_date' ? 'dateTime': item.field === 'action_effective_date' ? 'dateTime': item.field === 'contract_start_date' ? 'dateTime': item.field === 'contract_end_date' ? 'dateTime': null }
                        headerText={item.title}
                        width="200px" minWidth='100px' maxWidth='300px'
                        valueAccessor={valueFormat}
                    />
                    );
                    })
                    }
                    </ColumnsDirective>
                    <Inject services={[Toolbar, PdfExport, ExcelExport,Aggregate,Group,Sort,ColumnMenu,Filter,VirtualScroll,Resize]} />
                    </GridComponent>
                   </div>
                   </Paper>
                    :
                    <Typography variant='h4'>
                         There is no data to show now.
                    </Typography>
                
                }
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading:state.customerData.loading,
        // masterReportData: state.customerData.masterReportData,
        masterReportData:state.customerData.masterReportData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembership: (pagination,limit) => dispatch(getMembershipData(pagination,limit,'displaybyMember')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MasterReportResults);