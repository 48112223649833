import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Breadcrumbs, Typography, Link,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    Dialog, DialogActions, Button, IconButton
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 5,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 10
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    iconButton:{
        padding:'12px 5px'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const ProductFeatureCalculateResult = props => {
    const history = useHistory();
    const classes = useStyles();
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                classes={{ paper: classes.paperContainer }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
               

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            
            <div className={clsx(classes.root, className)}>
                <div style={{padding:'19px'}}>
                        <Breadcrumbs aria-label="breadcrumb">
                        <Link variant='h4' classes={{
                            root: classes.link
                        }}
                            component="button"
                            onClick={() => history.push('/product-feature-calculate')}
                        >
                            Product Feature Calculate
                        </Link>
                        <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
                    </Breadcrumbs>
                </div>
                {props.priceCalForSimulationData && props.priceCalForSimulationData ?
                    <TableContainer >
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell className={classes.tableCell}> Material Number </TableCell>
                                    <TableCell className={classes.tableCell}> Market Price </TableCell>
                                    <TableCell className={classes.tableCell}> List Price </TableCell>
                                    <TableCell className={classes.tableCell}> Cost </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {props.priceCalForSimulationData && props.priceCalForSimulationData.map(item => {
                                    return (
                                        <StyledTableRow>
                                            <TableCell align='center'>{props.priceCalForSimulationData.material_number}</TableCell>
                                            <TableCell align='center'>{props.priceCalForSimulationData.market_price != '0' ? props.priceCalForSimulationData.market_price : ''}</TableCell>
                                            <TableCell align='center'>{props.priceCalForSimulationData.list_price != '0' ? props.priceCalForSimulationData.list_price : ''}</TableCell>
                                            <TableCell align='center'>{props.priceCalForSimulationData.cost != '0' ? props.priceCalForSimulationData.cost : ''}</TableCell>
                                        </StyledTableRow>
                                     );
                                })} 
                            </TableBody>
                        </Table>

                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No Results
                    </Typography>
                }
            </div >
        </div >
    );

};


const mapStateToProps = state => {
    return {
        priceCalForSimulationData: state.pricingData.productFeatureCalculateData,
    }
};


export default connect(mapStateToProps, null)(ProductFeatureCalculateResult);