import React, { useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs,
    Typography,
    TableCell,
    TableRow,
} from '@mui/material';
import { connect } from 'react-redux';
import Moment from 'moment';
import { runcontractChangeReports } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { HashLoader } from 'react-spinners';
import { tableIcons } from '../../../components/Icons/TableIcons';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '5px 10px 0px 10px',
    },
    link: {
        color: theme.palette.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    formLabel: {
        color: theme.palette.text.primary,
        // color: "red"
    }
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.table.row,

            //height: 46
        },
        '&:nth-of-type(odd)': {

            //height: 40
        }
    }
}))(TableRow);
const ContractChangeReportsTable = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    var result = [];
    const [changelog, setChangelog] = React.useState(localStorage.getItem('ContractChangeReportType'));
    const [columns, setColumns] = React.useState([
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
        },
        {
            field: 'Contract Status',
            title: 'Contract Status',
            type: 'string',
        },
        {
            field: 'contract_pending_id',
            title: 'Contract Change ID',
            type: 'string',
        },
        {
            field: 'contract_status',
            title: 'Change ID Status',
            type: 'string',
        },
        {
            field: 'field_name',
            title: 'Field Name',
            type: 'string',
        },
        {
            field: 'current_sub_tbl_value',
            title: 'Old Value',
            type: 'string',

        },
        {
            field: 'changed_sub_tbl_value',
            title: 'New Value',
            type: 'string',

        },
        {
            field: 'changed_by',
            title: 'Updated By',
            type: 'string',
        },
        {
            field: 'UpdatedAt',
            title: 'Updated At',
            type: 'string',
        },
    ]);

    const [dataRows, setDataRows] = React.useState([]);


    useEffect(() => {
        if (props.contractData) {
            if (props.contractData.records && props.contractData.records[0]) {
                setChangelog(props.contractData.records[0].type)
                setDataRows(props.contractData.records.sort((a, b) => a['contract_number'] < b['contract_number'] ? 1 : -1))
                if (props.contractData.records[0].type === "all_changes") {
                    const updatedColumns = columns.map(column => {
                        if (column.field === 'changed_sub_tbl_value') {
                            return { ...column, title: 'New/Proposed Value' };
                        }
                        return column;
                    });
                    setColumns(updatedColumns);
                } else if (props.contractData.records[0].type === "pending_changes") {
                    const updatedColumns = columns.map(column => {
                        if (column.field === 'changed_sub_tbl_value') {
                            return { ...column, title: 'Proposed Value' };
                        }
                        return column;
                    });
                    setColumns(updatedColumns);
                }

            }

        }
    }, [props.contractData])
    function groupByKey(array, key) {
        return array
            .reduce((hash, obj) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {})
    }
    const markDifference = (currentValue, pendingValue) => {

        const currentValueArray = currentValue && currentValue.split(' ; ');
        const pendingValueArray = pendingValue && pendingValue.split(' ; ');
        const diffArray = [];
        currentValueArray && currentValueArray.forEach((item, index) => {
            if (item !== pendingValueArray[index]) {
                diffArray.push(`<span style="color:#0037A2;">${currentValueArray[index]}</span>`);
            } else {
                diffArray.push(item);
            }
        });
        const highlightedValue = diffArray.join(' ; ');

        return <a className={classes.hover} dangerouslySetInnerHTML={{ __html: highlightedValue }} />;

    }
    return (
        <div className={clsx(classes.root, className)}>
            {/* <Breadcrumbs aria-label="breadcrumb">
                <Link
                    variant="h4"
                    classes={{
                        root: classes.link,
                    }}
                    color='Primary'
                    to="/contract-change-reports"
                >Contract Change</Link>
                <Typography color="textPrimary" variant="h4">Results </Typography>
            </Breadcrumbs> */}
            <Typography variant="h1" color='primary' style={{ marginLeft: 4, marginBottom: -3 }}> Change Log</Typography>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >
                {changelog === "all_changes" || changelog === "pending_changes" || changelog === "approved_changes" && dataRows && dataRows.length > 0 ?

                    <MaterialTable
                        columns={columns}
                        data={dataRows}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        options={{
                            //maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            emptyRowsWhenPaging: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                            Row: ({ data, index }) => {
                                result = data['change_log'] ? groupByKey(data['change_log'], 'field_name') : data
                                var len = []
                                var l = 0
                                var l1 = 0
                                var l1 = (Object.keys(result).length) + 1;
                                Object.entries(result).map((key, index) => {

                                    if (key[0] === 'calculation_rules' || key[0] === 'eligibility_rules' || key[0] === 'notes'
                                        || key[0] === 'target_rules' || key[0] === 'chargeback_calculation_rules' || key[0] === 'contract_dependency_rules') {
                                        key[1].map((k, index) => {
                                            var l = (k.changed_sub_tbl_value.length) + 1
                                            l1 = l1 + l
                                        })
                                    }
                                    else {
                                        l1 = l1 + (Object.keys(key[1]).length)
                                    }

                                })
                                l = l1 + l

                                return (
                                    <Fragment >
                                        <TableRow style={{ backgroundColor: index % 2 != 0 ? 'rgba(242, 242, 242, 0.3)' : 'none', marginTop: 50 }}>
                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={l} >
                                                {data.contract_number}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={l} >
                                                {'Approved'}
                                            </TableCell>
                                        </TableRow>
                                        {
                                            Object.entries(result).map((key, index) => {

                                                key[1].map(x => {
                                                    if (!len[x.field_name])
                                                        len[x.field_name] = 0;
                                                    if (x.field_name == 'calculation_rules' || x.field_name == 'eligibility_rules' || x.field_name === 'notes'
                                                        || x.field_name == 'target_rules' || x.field_name === 'chargeback_calculation_rules' || x.field_name === 'contract_dependency_rules') {
                                                        len[x.field_name] = x.changed_sub_tbl_value.length + parseInt(len[x.field_name])
                                                    }
                                                })
                                                return (
                                                    <Fragment>
                                                        <TableRow>
                                                            {/* <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ textTransform: 'capitalize', borderTop: '2px solid #ddd' }} rowSpan={(Object.keys(key[1]).length) + 1 + len[key[0]]} >
                                                                {key[0].replace(/_/g, ' ')}
                                                            </TableCell> */}
                                                        </TableRow>
                                                        {key[1]
                                                            .sort((a, b) => a['contract_pending_id'] > b['contract_pending_id'] ? 1 : -1)
                                                            .sort((a, b) => a['contract_pending_id'] > b['contract_pending_id'] && a['UpdatedAt'] > b['UpdatedAt'] ? 1 : -1)
                                                            .map((k, index) => (
                                                                (k['field_name'] == 'calculation_rules' || k['field_name'] == 'eligibility_rules' || k['field_name'] === 'notes'
                                                                    || k['field_name'] == 'target_rules' || k['field_name'] === 'chargeback_calculation_rules' || k['field_name'] === 'contract_dependency_rules') ?
                                                                    < >
                                                                        <TableRow >
                                                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={(Object.keys(k['changed_sub_tbl_value']).length) + 1}>
                                                                                {k['contract_pending_id']}
                                                                            </TableCell>
                                                                        </TableRow>

                                                                        {k['current_sub_tbl_value'].map((k1, index) => (

                                                                            <StyledTableRow>
                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                                    {k['contract_status']}
                                                                                </TableCell>
                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                                    {k['field_name'].replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}
                                                                                </TableCell>
                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}>
                                                                                    {/* {k1} */}{markDifference(k1, k['changed_sub_tbl_value'][index])}
                                                                                </TableCell>

                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}>
                                                                                    {/* {k['changed_sub_tbl_value'][index]} */}{markDifference(k['changed_sub_tbl_value'][index], k1)}
                                                                                </TableCell>

                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                                    {k['changed_by']}
                                                                                </TableCell>
                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                                    {Moment(k['UpdatedAt']).local().format('MM/DD/YYYY HH:mm')}
                                                                                </TableCell>

                                                                            </StyledTableRow>

                                                                        ))}


                                                                    </>
                                                                    :
                                                                    <TableRow>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {k['contract_pending_id']}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {k['contract_status']}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {k['field_name'].replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {/* {k['current_value']} */}{markDifference(k['current_value'], k['changed_value'])}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {/* {k['changed_value']} */} {markDifference(k['changed_value'], k['current_value'])}
                                                                        </TableCell>

                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {k['changed_by']}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {Moment(k['UpdatedAt']).local().format('MM/DD/YYYY HH:mm')}
                                                                        </TableCell>
                                                                    </TableRow>

                                                            )

                                                            )}


                                                    </Fragment>
                                                );
                                            })
                                        }


                                    </Fragment>
                                );
                            }

                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );

};
const mapStateToProps = state => {
    return {
        contractData: state.operationalReportsData.contractChangeReportsData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getContractChangelogData: (pagination, limit, keyword) => dispatch(runcontractChangeReports(pagination, limit, keyword)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractChangeReportsTable);


