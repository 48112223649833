import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    FormLabel,
    TextField,
    Checkbox,
    Select,
    Input, OutlinedInput,
    MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown } from '../../../components/Inputs';
import { connect } from 'react-redux';
import RotateLoader from "react-spinners/RotateLoader";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader'
import { getPromotionSetup, getApprovalGroupData, getWorkflowRequests } from '../../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    contractContiner: {
        padding: '15px 0px 18px'
    },
    spinner: {
        height: '100vh'
    },
    multiSelect: {
        marginTop: 5,
        height: 37
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '15px 0px 18px'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    autocompleteInputRoot: {
        "&.MuiAutocomplete-inputRoot": {
            padding: "4.5px !important", // Adjust as needed
        },
    },
    autocompleteInput: {
        padding: "6px",
    },
}));

const CostPostingForm = props => {
    const classes = useStyles();
    const { className } = props;
    const [selected, setSelected] = React.useState([]);
    const [promoStart, setPromoStart] = useState(null);
    const [promoEnd, setPromoEnd] = useState(null);
    const [costingArray, setcostingArray] = React.useState([]);
    const [costingNumber, setCostingNumber] = React.useState('');
    const [approvalGroupData, setApprovalGroupData] = React.useState([]);
    const [approvalGroup, setApprovalGroup] = React.useState([]);

    useEffect(() => {
        props.getPromoData(1, 0)
        props.getApprovalGroupData();
    }, []);
    useEffect(() => {
        setPromoStart(Moment.utc().subtract(1, 'months').startOf('month'));
        setPromoEnd(Moment.utc().subtract(1, 'months').endOf('month'));
    }, []);
    useEffect(() => {
        if (props.approvalGroupData && props.approvalGroupData.records && props.approvalGroupData.records.length > 0) {
            setApprovalGroupData(props.approvalGroupData)
        }
    }, [props.approvalGroupData]);


    useEffect(() => {
        var tempRows = [];

        if (props.promoData && props.promoData.total_record > 0 && props.promoData.records)
            props.promoData.records
                .map(e => {
                    tempRows.push(e.costing_number)
                })

        setcostingArray(tempRows);
    }, [props.promoData]);
    const onSubmitHandler = () => {

        var sd = promoStart ? Moment.utc(promoStart).toISOString().split('T')[0].concat('T00:00:00.000Z') : '';
        var ed = promoEnd ? Moment.utc(promoEnd).toISOString().split('T')[0].concat('T23:59:59.000Z') : '';

        const formData = {
            "functionality": 'Accrual',
            "application_type": sessionStorage.getItem('application'),
            "contract_number": costingNumber,
            "start_date": sd,
            "end_date": ed,
            "approval_group": parseInt(approvalGroup) ? parseInt(approvalGroup) : '',
        };
        props.onSubmit('promotion', formData);
    }
    const handleClear = () => {
        setCostingNumber([])
        setSelected([])
        setApprovalGroup('')
        setPromoStart(null)
        setPromoEnd(null)
    }
    const handleApprovalGroup = (event) => {
        setApprovalGroup(event.target.value);
    }

    function handlePromoStart(newValue) {
        if (newValue && newValue.isValid()) {
            setPromoStart(Moment(newValue).format('MM/DD/YYYY'));
            setPromoEnd(Moment(newValue).endOf('month').format('MM/DD/YYYY'))
        }
        else {
            setPromoStart(newValue)
            if (!newValue)
                setPromoEnd(null)
        }

    }
    function handlePromoEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setPromoEnd(Moment(newValue).format('MM/DD/YYYY'));
        }
        else {
            setPromoEnd(newValue)
        }
    }
    function handleContractNumber(e, value) {
        let newSelected = value;
        if (value.length > 0) {
            setSelected(newSelected)
            setCostingNumber(newSelected.map(Number));
        }
        else {
            setSelected([])
            setCostingNumber([]);
        }
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary'>
                        Costing Approvals

                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <Dropdown heading={'Application Type'} classes={{ root: classes.fontSetting }} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div className={classes.contractContiner}>
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: "13px" }} className={classes.chargeBackLabel}> Costing Number </FormLabel>

                                    <Autocomplete
                                        multiple
                                        limitTags={3}
                                        id="checkboxes-tags-demo"
                                        options={costingArray.map(String)}
                                        defaultValue={selected}
                                        value={selected}
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined" size="small" style={{ borderRadius: '0px !important' }} />
                                        )}
                                        classes={{
                                            inputRoot: classes.autocompleteInputRoot,
                                        }}
                                        InputProps={{
                                            classes: { input: classes.autocompleteInput },
                                            disableUnderline: true
                                        }}
                                        disableCloseOnSelect
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected} />
                                                {option}

                                            </li>
                                        )}
                                        style={{ width: "100%", marginBottom: 15 }}
                                        onChange={(event, newValue) => { handleContractNumber(event, newValue) }}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Approval Group</FormLabel>
                                    <Select
                                        id="appoval-mutiple-name"
                                        value={approvalGroup}
                                        input={<OutlinedInput />}
                                        onChange={handleApprovalGroup}
                                        className={classes.multiSelect}
                                    >
                                        <MenuItem value=''> </MenuItem>
                                        {approvalGroupData && approvalGroupData.records
                                            && approvalGroupData.records.length > 0
                                            && approvalGroupData.records.map((item) => {
                                                return (
                                                    <MenuItem value={item.ID} key={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingBottom: 15 }}

                            >
                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                    Promotion Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    height: 37,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={promoStart}
                                            onChange={handlePromoStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>


                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingBottom: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel}>
                                    Promotion End Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    height: 37,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={promoEnd}
                                            onChange={handlePromoEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            minDate={promoStart ? promoStart : ''}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>


                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button

                                variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>

            </div >
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (functionality, data) => dispatch(getWorkflowRequests(functionality, data)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
        getPromoData: (pagination, limit) => dispatch(getPromotionSetup(pagination, limit)),

    }
}

const mapStateToProps = state => {
    return {
        promoData: state.promotionData.promotionSetupData,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        loading: state.promotionData.loading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CostPostingForm);
