import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    IconButton,
    Dialog,
    Grid,
    DialogContent, DialogTitle, Box
} from '@mui/material';
import { LabelText } from '../../../components/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import 'react-edit-text/dist/index.css';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    gridContainer: {
        width: '100%'
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
    baseColor:{
        color:theme.palette.primary.main,
    }
}));

const PopupContainer = props => {
    const classes = useStyles();
    const [outgoingClaimData,setOutgoingClaimData]= React.useState([]);
    useEffect(()=>{
        if(props.outgoingClaimID && props.outgoingClaimDetailData && props.outgoingClaimDetailData.length>0){
            var data=props.outgoingClaimDetailData.filter(item=>item.ID ==parseInt(props.outgoingClaimID))
            setOutgoingClaimData(data)
        }
    },[props.outgoingClaimDetailData,props.outgoingClaimID])

   
    const keywords = ['unique_identifier', 'claim_number', 'claim_amount', 'approved_amount', 'claim_date',
    'response_date', 'pricing_date', 'currency', 'claim_status', 'claim_error', 'rejection_reason',
    'action_code', 'adjustment_code', 'claim_reason', 'contract_number', 'acquistion_price', 'contract_price',
    'claim_per_unit', 'quantity', 'end_customer_id', 'end_customer_name', 'street_address', 'city', 'region', 'postal_code',
    'country', 'material_number', 'material_description', 'supplier_number', 'supplier_name', 'claim_submission_type', 'credit_memo',
    'posting_date', 'billing_date', 'billing_doc_number', 'billing_doc_line', 'base_uom', 'billed_uom', 'debit_card_indicator',
    'net_value', 'calendar_year_month', 'fiscal_year_month', 'class_of_trade', 'customer_classification', 'customer_group', 'customer_industry',
    'material_group', 'product_hierarchy', 'order_reason', 'delivery_tracking_number', 'maximum_quantity_allowed', 'membership_owner_id',
    'membership_owner_name', 'end_customer_id_type', 'end_customer_id_number', 'material_id_type', 'material_id_number', 'mfr_part_number',
    'original_invoice_for_return', 'original_invoice_line_for_return', 'billing_doc_cancelled', 'cancelled_billing_doc', 'document_category',
    'company_code', 'sales_org', 'distribution_channel', 'division', 'profit_center', 'cost_center', 'plant', 'supplier_customer_number', 'supplier_group',
    'flex_attribute01', 'flex_attribute02', 'flex_attribute03', 'flex_attribute04', 'flex_attribute05', 'flex_key_figure01', 'flex_key_figure02', 'flex_key_figure03',
    'flex_key_figure04', 'flex_key_figure05', 'unit_price', 'customer_number', 'customer_name', 'transaction_type', //'created_at',
    'created_by', 'changed_by'

]
    function handleClose(){
        props.onClose(false)
    }

    return (
        <Dialog open={
            props.open}
            fullScreen
            style={{ margin: 15 ,height:'auto'}}
        >
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <h2 className={classes.baseColor}>  Outgoing Claim Details</h2>
                    <Box>
                        <IconButton onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>

            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialogContent
            }}
            >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div>
                            <Grid container >
                                {outgoingClaimData &&  
                                keywords.map(item=>{
                                    
                                        return (
                                            <Grid
                                            item
                                            md={2}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                                <LabelText heading={outgoingClaimData[0]&& Object.keys(outgoingClaimData[0]).includes(item) ? item.replace(/_/g, ' ') : ''} style={{ textTransform: 'capitalize' }} data={outgoingClaimData[0]&&outgoingClaimData[0][item] ? Moment(outgoingClaimData[0][item], Moment.ISO_8601, true).isValid() && outgoingClaimData[0][item].toString().charAt(outgoingClaimData[0][item].length - 1) === 'Z' ? Moment(outgoingClaimData[0][item]).format('MM/DD/YYYY') : outgoingClaimData[0][item]:''} twoline='true' />
                                           
                                        </Grid>
                                        );
                                    }

                                )}
                            
                            </Grid>
                        </div>
                    </form>
                </Card>
            </DialogContent>
        </Dialog>
    );
};



const mapStateToProps = state => {
    return {
        outgoingClaimDetailData: state.initialData.outgoingClaimDetailData
    }
};

export default connect(mapStateToProps, null)(PopupContainer);