import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Link,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem, TextField
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { createProductFeaturePricing, getProductFeaturePricingSingleItem, getProductFeatureData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { useHistory } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import Moment from 'moment';
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 6,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: theme.typography.h6.fontSize,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 28,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    }
}));

const EditProductFeaturePricing = props => {
    const classes = useStyles();
    const history = useHistory();
    const [productFeature, setProductFeature] = React.useState('');
    const [productValue, setProductValue] = React.useState('');
    const [productFeatureArray, setProductFeatureArray] = React.useState([]);
    const [productValuesArray, setProductValuesArray] = React.useState([]);
    const [cost, setCost] = React.useState('');
    const [marketPrice, setMarketPrice] = React.useState('');
    const [listPrice, setListPrice] = React.useState('');
    const [editID, setEditID] = React.useState(0);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleProductFeature(appId)
        setEditID(appId)
        props.getAllProcductFeature(1, 0);
    }, []);
    useEffect(() => {
        var newArray = []
        if (props.productFeatureAll && props.productFeatureAll.records && props.productFeatureAll.records.length > 0) {
            props.productFeatureAll.records.map(item => {
                newArray.push(item.product_feature)
            })
            setProductFeatureArray(newArray)
        }
    }, [props.productFeatureAll])
    useEffect(() => {
        if (props.productFeatureData) {
            setProductFeature(props.productFeatureData.product_feature)
            setProductValue(props.productFeatureData.product_feature_value)
            setCost(props.productFeatureData.cost)
            setMarketPrice(props.productFeatureData.market_price)
            setListPrice(props.productFeatureData.list_price)
            if (props.productFeatureData.start_date !== "")
                setStartDate(props.productFeatureData.start_date)
            if (props.productFeatureData.end_date !== "")
                setEndDate(props.productFeatureData.end_date)
            if (props.productFeatureAll && props.productFeatureAll.records && props.productFeatureAll.records.length > 0) {
                props.productFeatureAll.records.map(item => {
                    if (item.product_feature === props.productFeatureData.product_feature) {
                        setProductValuesArray(item.product_feature_values)
                    }
                })
            }
        }
    }, [props.productFeatureData]);
    const handleProductFeature = (e) => {
        setProductFeature(e.target.value)
        if (props.productFeatureAll && props.productFeatureAll.records && props.productFeatureAll.records.length > 0) {
            props.productFeatureAll.records.map(item => {
                if (item.product_feature === e.target.value) {
                    setProductValuesArray(item.product_feature_values)
                }
            })
        }
    }
    function handleClearAll() {
        setProductFeature('')
        setProductValue('')
        setCost('')
        setMarketPrice('')
        setListPrice('')
        setStartDate(null)
        setEndDate(null)
    }
    function handleOnSubmit() {
        var data =
        {
            "product_feature": productFeature,
            "product_feature_value": productValue,
            "cost": parseFloat(cost),
            "market_price": parseFloat(marketPrice),
            "list_price": parseFloat(listPrice),
            "start_date": Moment.utc(startDate),
            "end_date": Moment.utc(endDate)
        }

        props.onSubmit(data, 'edit', editID);
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    component="button"
                    onClick={() => history.push('/product-feature-pricing')}
                >
                    Product Feature Pricing
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Product Feature</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Product Feature
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={productFeature}
                                        onChange={handleProductFeature}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {productFeatureArray && productFeatureArray
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: "capitalize" }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formDiv}>
                                        Product Feature Value
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={productValue}
                                        onChange={(e) => { setProductValue(e.target.value) }}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: "capitalize" }}
                                    >

                                        {productValuesArray && productValuesArray
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: "capitalize" }} >
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Cost
                                    </FormLabel>
                                    <OutlinedInput
                                        value={cost}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setCost(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Market Price
                                    </FormLabel>
                                    <OutlinedInput
                                        value={marketPrice}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setMarketPrice(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        List Price
                                    </FormLabel>
                                    <OutlinedInput
                                        value={listPrice}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setListPrice(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required >Start Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 5,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={startDate}
                                                onChange={(e) => { setStartDate(e) }}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required >End Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 5,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={endDate}
                                                onChange={(e) => { setEndDate(e) }}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={productValue && productFeature && cost && marketPrice && listPrice && startDate && endDate ? false : true}  >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        productFeatureData: state.pricingData.productFeaturePricingSingleData,
        productFeatureAll: state.productFeatureData.productFeatureData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(createProductFeaturePricing(data, type, id)),
        getSingleProductFeature: (id) => dispatch(getProductFeaturePricingSingleItem(id)),
        getAllProcductFeature: (pagination, limit) => dispatch(getProductFeatureData(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProductFeaturePricing);