// import React, { useEffect,useRef } from 'react';
// import {
//     Card,
//     Grid,
//     IconButton,  FormLabel,  Select,
//     MenuItem,
// } from '@mui/material';
// import clsx from 'clsx';
// import { makeStyles } from "@mui/styles";
// import { TextInputWithLabel} from '../../../../components/Inputs';
// import { getDefaultValuesMaterial } from '../../../../redux/actions';
// import { connect } from 'react-redux';
// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: theme.palette.white,
//         border: '1px solid #EEEEEE',
//         borderRadius: 6,
//         marginBottom: 23
//     },
//     container: {
//         padding: '0px 10px 0px 10px'
//     },
//     gridContainer: {
//         width: '100%',
//         padding: '0px 16px 0px 16px'
//     },
//     select: {
//         width: '100%',
//         marginTop: 10
//     },
// }));
// const GroupingIdentifers = (props) => {
//     const classes = useStyles();
//     //variable declarations
//     const contractCustomisationUpdation = useRef();
//     const [materialMasterData,setMaterialMaster] = React.useState({});
//     const [productHierarchy, setProductHierarchy] = React.useState('');
//     const [materialGroup, setMaterialGroup] = React.useState('');
//     const [materialGroup1, setMaterialGroup1] = React.useState('');
//     const [materialGroup2, setMaterialGroup2] = React.useState('');
//     const [materialGroup3, setMaterialGroup3] = React.useState('');
//     const [materialGroup4, setMaterialGroup4] = React.useState('');
//     const [materialGroup5, setMaterialGroup5] = React.useState('');
//     const [flexAttribute3, setFlexAttribute3] = React.useState('');
//     const [flexAttribute4, setFlexAttribute4] = React.useState('');
//     const [materialIDType, setMaterialIDType] = React.useState([]);
//     const [materialIDTypeArray, setMaterialIDTypeArray] = React.useState([]);
//     const [materialIDNumber, setMaterialIDNumber] = React.useState([]);
//     const [addMaterial, setAddMaterial] = React.useState(1);
//     const MenuProps = {
//         PaperProps: {
//             style: {
//                 maxHeight: 250,
//             }
//         }
//     }
//     useEffect(() => {
//         props.onLoadingDefault();
//     },[]);
//     useEffect(() => {
//         props.onChange(formData);
//     });
//     useEffect(() => {
//         if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
//             props.dropdownData.records.map((item) => {
//                 if (item.field_id === 'material_id_type' && item.type==='DROPDOWN.STRING') {
//                     setMaterialIDTypeArray(item);
//                 }
//             })
//         }
// },[props.dropdownData]);
//     useEffect(() => {
//         if (props.materialMasterData) {
//             setMaterialMaster(props.materialMasterData)
//             setProductHierarchy(props.materialMasterData.product_hierarchy)
//             setMaterialGroup(props.materialMasterData.material_group)
//             setMaterialGroup1(props.materialMasterData.material_group1)
//             setMaterialGroup2(props.materialMasterData.material_group2)
//             setMaterialGroup3(props.materialMasterData.material_group3)
//             setMaterialGroup4(props.materialMasterData.material_group4)
//             setMaterialGroup5(props.materialMasterData.material_group5)
//             setFlexAttribute3(props.materialMasterData.flex_attribute3)
//             setFlexAttribute4(props.materialMasterData.flex_attribute4)
//             var newArray= props.materialMasterData.material_id_type
//             setMaterialIDType(newArray);
//             setMaterialIDNumber(props.materialMasterData.material_id_number)
//             setAddMaterial(props.materialMasterData.material_id_number&&props.materialMasterData.material_id_number.length>0?props.materialMasterData.material_id_number.length:1)
//         }
//     }, [props.materialMasterData]);
//     var formData = {
//         material_id_type: materialIDType,
//         material_id_number: materialIDNumber,
//         product_hierarchy: productHierarchy,
//         material_group: materialGroup,
//         material_group1: materialGroup1,
//         material_group2: materialGroup2,
//         material_group3: materialGroup3,
//         material_group4: materialGroup4,
//         material_group5: materialGroup5,
//         flex_attribute3: flexAttribute3,
//         flex_attribute4: flexAttribute4
//     }
//     //functions
//     function handleMaterialIDType(newValue,index) {
//         materialIDType[index]=newValue
//         props.onChange(formData);
//     }
//     function handleMaterialIDNumber(newValue,index) {
//         materialIDNumber[index]=newValue
//         props.onChange(formData);
//     }
//     function handleProductHierarchy(newValue) {
//         setProductHierarchy(newValue);
//     }
//     function handleMaterialGroup(newValue) {
//         setMaterialGroup(newValue);
//     }
//     function handleMaterialGroup1(newValue) {
//         setMaterialGroup1(newValue);
//     }
//     function handleMaterialGroup2(newValue) {
//         setMaterialGroup2(newValue);
//     }
//     function handleMaterialGroup3(newValue) {
//         setMaterialGroup3(newValue);
//     }
//     function handleMaterialGroup4(newValue) {
//         setMaterialGroup4(newValue);
//     }
//     function handleMaterialGroup5(newValue) {
//         setMaterialGroup5(newValue);
//     }
//     function handleFlexAttribute3(newValue) {
//         setFlexAttribute3(newValue);
//     }
//     function handleFlexAttribute4(newValue) {
//         setFlexAttribute4(newValue);
//     }
//     function handleOnAdd(){
//         // materialIDType[addMaterial]=''
//         // materialIDNumber[addMaterial]=''
//         setAddMaterial(addMaterial+1)
//     }
//     function handleOnDelete(e,index){
//         if(materialIDType[addMaterial] && materialIDType[addMaterial].length>0)
//     materialIDType.pop(addMaterial);
//     if(materialIDNumber[addMaterial] && materialIDNumber[addMaterial].length>0)
//     materialIDNumber.pop(addMaterial);
//         setAddMaterial(addMaterial-1)
//     }
//     return (
//         <div
//             className={classes.root}>
//             {materialMasterData &&
//                 <Card>
//                     <form
//                         autoComplete="off"
//                         noValidate
//                     >
//                         <div className={classes.container}>
//                         <Grid container spacing={2}
//                            style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
//                         >
//                             {(() => {
//                                 let td = [];
//                                 for (let i = 0; i <addMaterial; i++) {
//                                     td.push(<>
//                             <Grid
//                                 item
//                                 md={3}
//                                 xs={12}
//                                 classes={{ root: classes.gridContainer }}>
//                                 <div className={classes.selectRoot}>
//                                      {i==0 &&  <FormLabel className={classes.formLabel}>
//                                         Material ID Type
//                                         </FormLabel> }
//                                         <Select
//                                             value={materialIDType[i]}
//                                             onChange={(e) => handleMaterialIDType(e.target.value,i)}
//                                             MenuProps={{ ...MenuProps, autoFocus: true }}
//                                             displayEmpty
//                                             className={clsx({
//                                                 [classes.select]: true
//                                             })}
//                                             classes={{
//                                                 selectMenu: classes.selectedItem
//                                             }}
//                                         >
//                                             {materialIDTypeArray.drop_down_values && materialIDTypeArray.drop_down_values.map((item, index) => {
//                                                 return (
//                                                     <MenuItem value={item} key={item}>
//                                                         {item}
//                                                     </MenuItem>
//                                                 )
//                                             })}
//                                         </Select>
//                                     </div>
//                             </Grid>
//                                         <Grid
//                                             item
//                                             md={3}
//                                             xs={2}
//                                             classes={{ root: classes.gridContainer }}
//                                         >  <TextInputWithLabel heading={i==0 && 'Material ID Number'} twoline='true' onChange={(e)=>handleMaterialIDNumber(e,i)}
//                                             prevalue={materialIDNumber&& materialIDNumber[i]?materialIDNumber[i]:''}/>
//                                         </Grid>
//                                         <Grid
//                                             item
//                                             md={2}
//                                             xs={12}
//                                             classes={{ root: classes.gridContainer }}
//                                         >   <IconButton style={{ visibility: i == addMaterial-1 ? 'visible' : 'hidden', marginTop:i==0?40:25}} onClick={handleOnAdd} classes={{ root: classes.IconButton }}>
//                                                 <AddBoxOutlinedIcon />
//                                             </IconButton>
//                                         </Grid>   
//                                         <Grid
//                                             item
//                                             md={2}
//                                             xs={12}
//                                             classes={{ root: classes.gridContainer }}
//                                         >
//                                             <IconButton style={{ visibility: i == addMaterial - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 45 : 30 }} onClick={(e) => handleOnDelete(e, addMaterial)} classes={{ root: classes.IconButton }}>
//                                                 <DeleteOutlinedIcon />
//                                             </IconButton>
//                                         </Grid>
//                                     </>)
//                                 }
//                                 return td;
//                             })()}
//                         </Grid>
//                             <Grid container spacing={2} style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Product Hierarchy'} twoline='true' onChange={handleProductHierarchy} prevalue={materialMasterData.product_hierarchy} />
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Material Group'} twoline='true' onChange={handleMaterialGroup} prevalue={materialMasterData.material_group} />
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Material Group 1'} twoline='true' onChange={handleMaterialGroup1} prevalue={materialMasterData.material_group1} />
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Material Group 2'} twoline='true' onChange={handleMaterialGroup2} prevalue={materialMasterData.material_group2} />
//                                 </Grid>

//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Material Group 3'} twoline='true' onChange={handleMaterialGroup3} prevalue={materialMasterData.material_group3} />
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Material Group 4'} twoline='true' onChange={handleMaterialGroup4} prevalue={materialMasterData.material_group4} />
//                                 </Grid>

//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Material Group 5'} twoline='true' onChange={handleMaterialGroup5} prevalue={materialMasterData.material_group5} />
//                                 </Grid>
//                                <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Flex Attribute 3'} twoline='true' onChange={handleFlexAttribute3} prevalue={materialMasterData.flex_attribute3} />
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     classes={{ root: classes.gridContainer }}
//                                 >
//                                     <TextInputWithLabel heading={'Flex Attribute 4'} twoline='true' onChange={handleFlexAttribute4} prevalue={materialMasterData.flex_attribute4} />
//                                 </Grid>
//                             </Grid>
//                         </div>
//                     </form>
//                 </Card>
//             }
//         </div >
//     );
// };
// const mapStateToProps = state => {
//     return {
//         materialMasterData: state.customerData.materialMasterViewData,
//         dropdownData: state.customerData.dropdownDataMaterial
//     }
// };
// const mapDispatchToProps = dispatch => {
//     return {
//         onLoadingDefault: () => dispatch(getDefaultValuesMaterial())
//     }
// }
// export default connect(mapStateToProps,mapDispatchToProps, null)(GroupingIdentifers);
import React, { useEffect } from 'react';
import {
    Card,
    Grid,
    IconButton, FormLabel, Select,
    MenuItem,
    CardHeader,
    Button, Typography, Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { TextInputWithLabel } from '../../../../components/Inputs';
import { getContractDetailsData, getDefaultValuesMaterial } from '../../../../redux/actions';
import { connect } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { AllOtherAttributeMasterComponent } from "../../../../components/Inputs";
import { Form } from 'react-bootstrap';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        width: '100%',
        padding: '0px 45px 0px 45px !important'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
    },
    container2: {
        padding: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 10
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const GroupingIdentifers = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        props.onLoadingDefault()
    }, [])
    //variable declarations
    const [materialMasterData, setMaterialMasterData] = React.useState({})
    const [productHierarchy, setProductHierarchy] = React.useState('');
    const [materialGroup, setMaterialGroup] = React.useState('');
    const [materialGroup1, setMaterialGroup1] = React.useState('');
    const [materialGroup2, setMaterialGroup2] = React.useState('');
    const [materialGroup3, setMaterialGroup3] = React.useState('');
    const [materialGroup4, setMaterialGroup4] = React.useState('');
    const [materialGroup5, setMaterialGroup5] = React.useState('');
    const [materialIdentifierType1, setMaterialIdentifierType1] = React.useState('');
    const [materialIdentifierNumber1, setMaterialIdentifierNumber1] = React.useState('');
    const [materialIdentifierType2, setMaterialIdentifierType2] = React.useState('');
    const [materialIdentifierNumber2, setMaterialIdentifierNumber2] = React.useState('');
    const [idNumber1, setIdNumber1] = React.useState('');
    const [idNumber2, setIdNumber2] = React.useState('');
    const [idNumber3, setIdNumber3] = React.useState('');
    const [idNumber4, setIdNumber4] = React.useState('');
    const [idNumber5, setIdNumber5] = React.useState('');
    const [idNumber6, setIdNumber6] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [materialIDTypeArray, setMaterialIDTypeArray] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [identifiersStore, setIdentifiersStore] = React.useState([]);
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    //options
    const [customerIdType, setCustomerIdType] = React.useState('');
    const [customerIdNumber, setCustomerIdNumber] = React.useState('');
    const [finalCustomerIDType, setFinalCustomerIDType] = React.useState([]);
    const [finalCustomerIDNumber, setFinalCustomerIDNumber] = React.useState([]);
    const [fieldLabel, setFieldLabel] = React.useState([]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);

    useEffect(() => {
        if (props.materialMasterData) {
            setMaterialMasterData(props.materialMasterData)
            setProductHierarchy(props.materialMasterData.product_hierarchy)
            setMaterialGroup(props.materialMasterData.material_group)
            setMaterialGroup1(props.materialMasterData.material_group1)
            setMaterialGroup2(props.materialMasterData.material_group2)
            setMaterialGroup3(props.materialMasterData.material_group3)
            setMaterialGroup4(props.materialMasterData.material_group4)
            setMaterialGroup5(props.materialMasterData.material_group5)
            setIdNumber1(props.materialMasterData.id_number01);
            setIdNumber2(props.materialMasterData.id_number02);
            setIdNumber3(props.materialMasterData.id_number03);
            setIdNumber4(props.materialMasterData.id_number04);
            setIdNumber5(props.materialMasterData.id_number05);
            setIdNumber6(props.materialMasterData.id_number06);
            // setFlexAttribute3(props.materialMasterData.flex_attribute3)
            // setFlexAttribute4(props.materialMasterData.flex_attribute4)
            var tempOrg = [];
            // for (var i = 0; i < props.materialMasterData.material_id_type.length; i++) {
            //     var data = {
            //         "customer_id_type": props.materialMasterData.material_id_type[i],
            //         "customer_id_number": props.materialMasterData.material_id_number[i],
            //     };
            //     tempOrg.push(data);
            // }
            setIdentifiersStore(tempOrg)
        }
    }, [props.materialMasterData]);

    function handleCustomerIDType(newValue) {
        if (newValue && newValue.length > 0) {
            setCustomerIdType(newValue);
        } else setCustomerIdType("");
        props.onChange(formData);
    }
    function handleCustomerIDNumber(event) {
        setCustomerIdNumber(event);
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        handleCustomerIDType(item.customer_id_type);
        handleCustomerIDNumber(item.customer_id_number);
    }
    useEffect(() => {
        var tempIDType = [];
        var tempIDNumber = [];
        for (var i = 0; i < identifiersStore.length; i++) {
            tempIDType.push(identifiersStore[i].customer_id_type)
            tempIDNumber.push(identifiersStore[i].customer_id_number)
        }
        setFinalCustomerIDType(tempIDType)
        setFinalCustomerIDNumber(tempIDNumber)
    }, [identifiersStore]);
    function handleSubmit() {
        var data = {
            "customer_id_type": customerIdType,
            "customer_id_number": customerIdNumber,
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...identifiersStore];
            editedArray[editIndex] = data;
            setIdentifiersStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setIdentifiersStore([...identifiersStore, data]);
            props.onChange([...identifiersStore, data])
        }
        setCustomerIdType('');
        setCustomerIdNumber('');
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setCustomerIdType('');
        setCustomerIdNumber('');
    }
    const handleDeleteLocally = (index) => {
        setIdentifiersStore(item => item.filter((item, i) => i !== index));
        props.onChange(identifiersStore.filter((item, i) => i !== index))
    }
    useEffect(() => {
        props.onChange(formData);
    });

    var formData = {
        material_id_type: finalCustomerIDType,
        material_id_number: finalCustomerIDNumber,
        product_hierarchy: productHierarchy,
        material_group: materialGroup,
        material_group1: materialGroup1,
        material_group2: materialGroup2,
        material_group3: materialGroup3,
        material_group4: materialGroup4,
        material_group5: materialGroup5,
        material_id_type1: materialIdentifierType1,
        material_id_number1: materialIdentifierNumber1,
        material_id_type2: materialIdentifierType2,
        material_id_number2: materialIdentifierNumber2,
        id_number01: idNumber1,
        id_number02: idNumber2,
        id_number03: idNumber3,
        id_number04: idNumber4,
        id_number05: idNumber5,
        id_number06: idNumber6,
        // flex_attribute3: flexAttribute3,
        // flex_attribute4: flexAttribute4
    }
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'material_id_type' && item.type === 'DROPDOWN.STRING') {
                    setMaterialIDTypeArray(item);
                }

            })
        }

    }, [props.dropdownData]);
    //functions
    function handleProductHierarchy(newValue) {
        setProductHierarchy(newValue);
    }
    function handleMaterialGroup(newValue) {
        setMaterialGroup(newValue);
    }
    function handleMaterialGroup1(newValue) {
        setMaterialGroup1(newValue);
    }
    function handleMaterialGroup2(newValue) {
        setMaterialGroup2(newValue);
    }
    function handleMaterialGroup3(newValue) {
        setMaterialGroup3(newValue);
    }
    function handleMaterialGroup4(newValue) {
        setMaterialGroup4(newValue);
    }
    function handleMaterialGroup5(newValue) {
        setMaterialGroup5(newValue);
    }
    function handleIdNumber1(newValue) {
        setIdNumber1(newValue);
    }
    function handleIdNumber2(newValue) {
        setIdNumber2(newValue);
    }
    function handleIdNumber3(newValue) {
        setIdNumber3(newValue);
    }
    function handleIdNumber4(newValue) {
        setIdNumber4(newValue);
    }
    function handleIdNumber5(newValue) {
        setIdNumber5(newValue);
    }
    function handleIdNumber6(newValue) {
        setIdNumber6(newValue);
    }

    // function handleFlexAttribute3(newValue) {
    //     setFlexAttribute3(newValue);
    // }
    // function handleFlexAttribute4(newValue) {
    //     setFlexAttribute4(newValue);
    // }

    return (
        <div>
            <div
                className={classes.root}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <CardHeader
                            title="GROUPINGS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <Grid container spacing={2}
                            style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }}
                                 required={fieldLabel['product_hierarchy'] ? fieldLabel['product_hierarchy']['mandatory'] : false}>
                                 {fieldLabel['product_hierarchy'] ? fieldLabel['product_hierarchy']['current'] : 'Product Hierarchy'} 
                                </FormLabel>
                                <TextInputWithLabel twoline='true' onChange={handleProductHierarchy} prevalue={materialMasterData.product_hierarchy} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} 
                                  required={fieldLabel['material_group'] ? fieldLabel['material_group']['mandatory'] : false} >
                                  {fieldLabel['material_group'] ? fieldLabel['material_group']['current'] : 'Material Group'} 
                                </FormLabel>
                                <TextInputWithLabel twoline='true' onChange={handleMaterialGroup} prevalue={materialMasterData.material_group} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} 
                                required={fieldLabel['material_group1'] ? fieldLabel['material_group1']['mandatory'] : false}>
                                      {fieldLabel['material_group1'] ? fieldLabel['material_group1']['current'] : 'Material Group 1'} 

                                </FormLabel>
                                <TextInputWithLabel twoline='true' onChange={handleMaterialGroup1} prevalue={materialMasterData.material_group1} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel  classes={{ root: classes.formLabel }} required={fieldLabel['material_group2'] ? fieldLabel['material_group2']['mandatory'] : false} >
                                {fieldLabel['material_group2'] ? fieldLabel['material_group2']['current'] : 'Material Group 2'} 
                                </FormLabel>
                                <TextInputWithLabel twoline='true' onChange={handleMaterialGroup2} prevalue={materialMasterData.material_group2} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} 
                                required={fieldLabel['material_group3'] ? fieldLabel['material_group3']['mandatory'] : false}>
                                     {fieldLabel['material_group3'] ? fieldLabel['material_group3']['current'] : 'Material Group 3'}

                                </FormLabel>
                                <TextInputWithLabel  twoline='true' onChange={handleMaterialGroup3} prevalue={materialMasterData.material_group3} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['material_group4'] ? fieldLabel['material_group4']['mandatory'] : false} >
                                {fieldLabel['material_group4'] ? fieldLabel['material_group4']['current'] : 'Material Group 4'}
                                </FormLabel>
                                <TextInputWithLabel   twoline='true' onChange={handleMaterialGroup4} prevalue={materialMasterData.material_group4} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} 
                                required={fieldLabel['material_group5'] ? fieldLabel['material_group5']['mandatory'] : false} > 
                                {fieldLabel['material_group5'] ? fieldLabel['material_group5']['current'] : 'Material Group 5'}
                                </FormLabel>
                                <TextInputWithLabel   twoline='true' onChange={handleMaterialGroup5} prevalue={materialMasterData.material_group5} />
                            </Grid>
                            {/* <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['mandatory'] : false} heading={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'} twoline='true' onChange={handleFlexAttribute3} prevalue={materialMasterData.flex_attribute3} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['mandatory'] : false} heading={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'} twoline='true' onChange={handleFlexAttribute4} prevalue={materialMasterData.flex_attribute4} />
                            </Grid> */}
                        </Grid>
                    </div>
                </form>
            </div>
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IDENTIFIERS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}  required={fieldLabel['id_number01'] ? fieldLabel['id_number01']['mandatory'] : false}>
                                        {fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'ID Number 1'} 
                                        </FormLabel>
                                        <TextInputWithLabel  twoline='true' onChange={handleIdNumber1} prevalue={materialMasterData.id_number01} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}  required={fieldLabel['id_number02'] ? fieldLabel['id_number02']['mandatory'] : false}>
                                        {fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'ID Number 2'} 
                                        </FormLabel>
                                        <TextInputWithLabel  twoline='true' onChange={handleIdNumber2} prevalue={materialMasterData.id_number02} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['id_number03'] ? fieldLabel['id_number03']['mandatory'] : false}  >
                                        {fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'ID Number 3'}
                                        </FormLabel>
                                        <TextInputWithLabel 
                                        twoline='true' onChange={handleIdNumber3} prevalue={materialMasterData.id_number03} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                         required={fieldLabel['id_number04'] ? fieldLabel['id_number04']['mandatory'] : false}  >
                                         {fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'ID Number 4'}
                                        </FormLabel>
                                        <TextInputWithLabel
                                       twoline='true' onChange={handleIdNumber4} prevalue={materialMasterData.id_number04} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} 
                                         required={fieldLabel['id_number05'] ? fieldLabel['id_number05']['mandatory'] : false}>
                                        {fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'ID Number 5'}
                                        </FormLabel>
                                        <TextInputWithLabel  twoline='true' onChange={handleIdNumber5} prevalue={materialMasterData.id_number05} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['id_number06'] ? fieldLabel['id_number06']['mandatory'] : false} >
                                            {fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'ID Number 6'}

                                        </FormLabel>
                                        <TextInputWithLabel   twoline='true' onChange={handleIdNumber6} prevalue={materialMasterData.id_number06} />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel style={{ marginTop: '10px' }}>{fieldLabel['material_id_type'] ? fieldLabel['material_id_type']['current'] : "material_id_type"}</FormLabel>
                                        <AllOtherAttributeMasterComponent
                                            classes={{ root: classes.fontSetting }}
                                            withOutLabel
                                            attributeName="material_id_type"
                                            value={customerIdType}
                                            onChange={handleCustomerIDType}
                                            prevalue={customerIdType}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "0.8rem" }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                {fieldLabel['material_id_number'] ? fieldLabel['material_id_number']['current'] : 'Material ID number'}
                                            </FormLabel>

                                            <TextField
                                                value={customerIdNumber}
                                                onChange={(e) => handleCustomerIDNumber(e.target.value)}
                                                className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item md={1} classes={{ root: classes.centerButton }}>
                                        <Grid container style={{ paddingTop: 26, marginTop: '1.2rem' }} >
                                            <Grid item md={6} >
                                                {editMode ?
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<EditIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                    :
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                }
                                            </Grid>
                                            <Grid item
                                                md={6}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<DeleteForeverIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.deleteIconContainer,
                                                    }}
                                                    onClick={handleRemove}
                                                >
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </div>
                        </form>

                        {/* <div className={classes.container2}>
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {identifiersStore.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} classes={{ root: classes.fontSetting }} >Material ID Type</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} classes={{ root: classes.fontSetting }} >Material ID number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} classes={{ root: classes.fontSetting }} style={{ minWidth: 100 }}>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {identifiersStore.map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.customer_id_type}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.customer_id_number}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                                    <IconButton classes={{ root: classes.IconButton }} onClick={() => handleEditRow(item, index)}>
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => handleDeleteLocally(index)} classes={{ root: classes.IconButton }}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </div> */}

                    </Card>
                </div >
            </div >
        </div >
    );

};

const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.materialMasterViewData,
        dropdownData: state.customerData.dropdownDataMaterial
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesMaterial())
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null)(GroupingIdentifers);
