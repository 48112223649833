const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    gradient: {
        appLauncher: 'linear-gradient(90deg, #B98CAD 20%, #E4D7E0 100%)'
    },
    notification: {
        grey: '#DADFF2'
    },
    primary: {
        main: '#583246'
    },
    secondary: {
        main: '#FBFBFB'
    },
    appLauncher: {
        tabStyle: '#583246',
        tabStyleFont: 'white',
        tabBackground: 'linear-gradient(135deg, #583246 0%, #CCAEBE 100%)', 
        paper: '#583246'
    },
    icon: {
        active: '#B4BDE9'
    },
    text: {
        primary: '#0D1333',
        grey: '#828282',
        helper: '#4F4F4F',
        yellow: '#F2F5A9'
    },
    table: {
        row: 'rgba(242, 242, 242, 0.3)',
        border: '#828282'
    },
    border: {
        main: '#E0E0E0',
        divider: '#7F8CC4'
    },
    button: {
        red: '#EB5757',
        green: '#27AE60'
    },
    tabs: {
        tabActive: '#858AA3',
        text: '#ECEFF8'
    },
    body:{
        main: 'red'
    }
};
