import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, Link, useMediaQuery, Paper,
} from '@mui/material';
import { connect } from 'react-redux';
import { getLblDispDesFieldValue, getContractsTableDataBySearchEligibilty } from '../../redux/actions';
import Moment from 'moment';
import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Aggregate,
    ExcelExport, Toolbar, PdfExport, Search, CommandColumn, AggregateDirective, AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, ColumnMenu, VirtualScroll,
    dataReady
} from '@syncfusion/ej2-react-grids';
import { number } from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        //width:'100%',
        // width: 'fit-content',
        width: '100%',
        overflowX: 'auto',  // Enable horizontal scrolling

    },
    gridBox: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginBottom: 10,
        //overflowX: 'hidden',
        // overflowY: 'scroll',
        overflowX: 'auto', // Ensure grid can scroll horizontally
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableCell: {
        paddingLeft: 18
    },
    buttonContainer: {
        display: 'flex',
    },
    button: {
        marginLeft: 15
    },
    sumContainer: {
        // backgroundColor: theme.palette.primary.main,
        // color: 'white',
        color: theme.palette.primary.main
    },
    cell: {
        backgroundColor: 'white !important',
        background: 'white !important',
        color: 'black'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));


const ContractSetupList = props => {
    var grid = useRef(null);

    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [tableHeaders, setTableHeaders] = React.useState([]);
    const [sortedTableHeader, setSortedTableHeader] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [columns1, setColumns1] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [dateField, setDateFields] = React.useState([]);
    const [numericField, setNumericFields] = React.useState([]);
    const [contractFormFields, setContractFormFields] = React.useState([])

    useEffect(() => {
        props.onSubmit(sessionStorage.getItem("search-contract-data"), "List")
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
    }, [])

    useEffect(() => {
        var tempRows = [];
        if (props.contractablesdata /*&& props.contractablesdata.length>0*/) {
            if (props.contractablesdata.length > 0) {
                props.contractablesdata
                    .sort((a, b) => (a.id < b.id ? 1 : -1))
                    .map((e) => {
                        tempRows.push({
                            contract_number: e.contract_number,
                            contract_type: e.contract_type,
                            internal_description: e.internal_description,
                            contract_purpose: e.contract_purpose,
                            currency: e.currency,
                            valid_from: Moment.utc(e.valid_from).format("MM/DD/YYYY"),
                            valid_to: Moment.utc(e.valid_to).format("MM/DD/YYYY"),
                            posting_schema: e.posting_schema,
                            contract_group: e.contract_group,
                            contract_sub_group: e.contract_sub_group,
                            contract_objective: e.contract_objective,
                            contract_strategy: e.contract_strategy,
                            contract_origin: e.contract_origin,
                            workflow_name: e.workflow_name,
                            source_data_type: e.source_data_type,
                            calculation_method: e.calc_method ? e.calc_method : e.calculation_method,
                            incentive_rate_type: e.incentive_rate_type,
                            commitment_achievement: e.commitment_achievement,
                            contract_dependency: e.contract_dependency,
                            calculation_rule_modifier: e.calculation_rule_modifier,
                            formula_exclusive: e.formula_exclusive,
                            evaluate_sales_bundle: e.evaluate_sales_bundle,
                            maximum_amount_level: e.maximum_amount_level,
                            maximum_amount_period: e.maximum_amount_period,
                            maximum_incentive_amount: e.maximum_incentive_amount,
                            external_description: e.external_description,
                            negotiation_reason: e.negotiation_reason,
                            negotiation_outcome: e.negotiation_outcome,
                            flex_fields_1: e.flex_fields_1,
                            flex_fields_2: e.flex_fields_2,
                            flex_fields_3: e.flex_fields_3,
                            legacy_contract_id: e.legacy_contract_id,
                            go_live_date: Moment.utc(e.go_live_date).format("MM/DD/YYYY"),
                            // created_by: e.created_by ? e.created_by.username : '',
                            // updated_by: e.updated_by ? e.updated_by.username : '',


                            accrual_level: e.accrual_level,
                            copa_level: e.copa_level,
                            accrual_frequency: e.accrual_frequency,
                            accrual_calendar: e.accrual_calendar,
                            accrual_gl_account_credit: e.accrual_gl_account_credit,
                            accrual_gl_account_debit: e.accrual_gl_account_debit,
                            accrual_credit_profit_center: e.accrual_credit_profit_center,
                            accrual_debit_profit_center: e.accrual_debit_profit_center,
                            accrual_run: e.accrual_run,
                            flex_fields_4: e.flex_fields_4,
                            payment_level: e.payment_level,
                            payment_partner_role: e.payment_partner_role,
                            payment_frequency: e.payment_frequency,
                            payment_method: e.payment_method,
                            payment_due_date: e.payment_due_date,
                            external_reference: e.external_reference,
                            flex_fields_5: e.flex_fields_5,
                            auto_run_ppa: e.auto_run_ppa,
                            postings_start_date: Moment.utc(e.postings_start_date).format("MM/DD/YYYY"),
                            postings_end_date: Moment.utc(e.postings_end_date).format("MM/DD/YYYY"),
                            postings_block: e.postings_block,
                            suppress_negative_rebate: e.suppress_negative_rebate,


                            contract_start_date: Moment.utc(e.contract_start_date).format("MM/DD/YYYY"),
                            contract_end_date: Moment.utc(e.contract_end_date).format("MM/DD/YYYY"),
                            document_id: e.document_id,
                            contract_analyst: e.contract_analyst,
                            contract_manager: e.contract_manager,
                            pricing_strategy: e.pricing_strategy,
                            external_signed_date: Moment.utc(e.external_signed_date).format("MM/DD/YYYY"),
                            image_reference_id: e.image_reference_id,
                            contract_owner: e.contract_owner,
                            contract_owner_type: e.contract_owner_type,
                            external_contract_type: e.external_contract_type,
                            external_contract_number: e.external_contract_number,
                            sales_analyst: e.sales_analyst,
                            sales_manager: e.sales_manager,
                            finance_analyst: e.finance_analyst,
                            finance_manager: e.finance_manager,
                            external_contact_name: e.external_contact_name,
                            external_delivery_options: e.external_delivery_options,
                            external_contact_email: e.external_contact_email,
                            external_contact_phone: e.external_contact_phone,
                            flex_fields_6: e.flex_fields_6,
                            flex_fields_7: e.flex_fields_7,
                            flex_fields_8: e.flex_fields_8,
                            flex_fields_9: e.flex_fields_9,
                            flex_fields_10: e.flex_fields_10,
                            // accrual_amount: e.accrual_amount
                            //     ? parseFloat(e.accrual_amount).toLocaleString()
                            //     : e.accrual_amount,
                            // payment_amount: e.payment_amount
                            //     ? parseFloat(e.payment_amount).toLocaleString()
                            //     : e.payment_amount,
                        });
                    });

                // Dynamically generate columns from the keys of the first row
                const tempColumns = Object.keys(tempRows[0]).map((key) => {
                    return {
                        field: key,
                        title: key
                            .replace(/_/g, ' ') // Replace underscores with spaces
                            .replace(/\b\w/g, (char) => char.toUpperCase()), // Capitalize first letter of each word
                    };
                });

                // Set columns and data rows state
                setColumns1(tempColumns);
                setDataRows(tempRows);
            }
            else {
                setDataRows([])
                setColumns1([]);
            }
        }

    }, [props.contractablesdata]);

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes)
                .reduce((result, [key, value]) => {
                    result[key] = { current: value.current, key: key }; // Store current and key
                    return result;
                }, {});
            setContractFormFields(tempObject); // Update the state
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])


    useEffect(() => {
        if (contractFormFields && columns1.length > 0) {
            // Map over the columns array to update the title if the field exists in contractFormFields
            const updatedColumns = columns1.map((column) => {
                const fieldKey = column.field; // Get the field key
                if (contractFormFields[fieldKey]) {
                    setShow(true);
                    // If a matching key exists in contractFormFields, update the title
                    return {
                        ...column,
                        title: contractFormFields[fieldKey].current, // Set the title to current value from contractFormFields
                    };
                } else {
                    return column; // Return the column as is if no matching key is found
                }
            });

            setColumns(updatedColumns); // Update state with modified columns
        }
    }, [contractFormFields, columns1]);



    // console.log("label", contractFormFields)
    // console.log("columns", columns)
    // console.log("columns111", columns1)
    // console.log("data", dataRows)
    // console.log("123", show)

    //pdf download

    const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search']
    const [sumField, setSumField] = React.useState({});

    const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
            grid.showSpinner();
            const exportProperties = {
                pageOrientation: 'Landscape',
                pageSize: 'A0',
                fileName: 'Contract List' + '_' + Moment.utc(new Date()).format('MM/DD/YYYY') + '.pdf',
                theme: {
                    header: {
                        bold: true,
                        fontSize: 14,
                    },
                    record: {
                        bold: false,
                        fontSize: 14,
                    },
                    caption: {
                        bold: true,
                        fontSize: 14,
                    },
                }
            };
            grid.pdfExport(exportProperties);
        }
        if (grid && args.item.id === 'grid_excelexport') {
            grid.showSpinner();
            const excelExportProperties = {
                dataSource: [...dataRows, sumField],
                fileName: 'Contract List' + '_' + Moment.utc(new Date()).format('MM/DD/YYYY') + '.xlsx',
            };
            grid.excelExport(excelExportProperties);
        }
    };

    const pdfExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const excelExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const filterSettings = { type: 'CheckBox' };
    const groupOptions = { showGroupedColumn: true }
    const valueFormat = (field, data, column) => {
        if (numericField && numericField.includes(field) && field != 'contract_number') {
            return parseFloat(data[field]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).toString()

        }
        else if (dateField && dateField.includes(field)) {
            return Moment(data[field], true).isValid()
                ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
        }
        else
            return data[field];
    };
    const sortComparer = (reference, comparer) => {

        if (grid && grid.sortModule.direction == 'Ascending') {
            if (numericField.includes(grid.sortModule.columnName)) {
                return Number(reference) < Number(comparer) ? -1 : 1
            }
            else {
                return reference < comparer ? -1 : 1
            }
        }
        else {
            if (numericField.includes(grid.sortModule.columnName)) {
                return Number(reference) < Number(comparer) ? 1 : -1
            }
            else {
                return reference < comparer ? 1 : -1
            }
        }
        return 0;
    };
    return (
        <div>
            <div className={clsx(classes.root, className)} >
                {
                    columns && columns.length > 0 &&
                    dataRows && dataRows.length > 0
                    && show &&
                    < Paper className={classes.gridBox} style={{ paddingTop: 0, }}>
                        <div style={{ marginTop: 10 }}>
                            <GridComponent
                                dataSource={dataRows}
                                id='grid'
                                allowSorting={true} allowGrouping={true} showColumnMenu={true}
                                // allowPaging={true}
                                //pageSettings={{ pageSize: 10000 }}
                                groupSettings={groupOptions}
                                filterSettings={filterSettings}
                                allowFiltering={true}
                                ref={g => grid = g}
                                toolbar={toolbarOptions}
                                toolbarClick={toolbarClick}
                                allowPdfExport={true} allowExcelExport={true}
                                pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
                                height={window.screen.height * .75}
                                gridLines='Both' allowResizing={true}
                                enableVirtualization={true}
                                enableColumnVirtualization={false}
                            >
                                <ColumnsDirective>
                                    {columns.map((item, order) => {
                                        return (
                                            <ColumnDirective
                                                field={item.field}
                                                valueAccessor={valueFormat}
                                                headerText={item.title}
                                                width="200px" minWidth='200px' maxWidth='600px'

                                            />
                                        );
                                    })
                                    }
                                </ColumnsDirective>
                                <Inject services={[Toolbar, PdfExport, ExcelExport, Aggregate, Group, Sort, ColumnMenu, Filter, VirtualScroll, Resize, Page]} />

                            </GridComponent>
                        </div>
                    </Paper>
                }

            </div >
        </div >
    );

};

const mapStateToProps = state => {
    return {
        contractablesdata: state.addContractData.contractAllListData,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        onSubmit: (data, List) => dispatch(getContractsTableDataBySearchEligibilty(data, List)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSetupList);