import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card, FormLabel,
    Checkbox, MenuItem, Select, OutlinedInput, Input, ListItemText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { createPriceListMaster, getPriceFieldValues, getPriceListMasterById } from '../../../redux/actions/Pricing/AuthAction';
import { getApprovalGroupData } from '../../../redux/actions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: theme.typography.h6.fontSize,
        paddingRight: 10
    },
    container: {
        padding: 15,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        height: 35
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll"
    },
    formlabel: {
        marginBottom: 8,
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },

}));

const EditPriceList = props => {
    const classes = useStyles();
    useEffect(() => {
        props.getFieldValues('qualifier_key')
        props.getFieldValues('price_type_name')
        props.getApprovalGroup()
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getConfigData(appId);
    }, [])

    const [priceTypeName, setPriceTypeName] = React.useState([]);
    const [qualifierName, setQualifierName] = React.useState([]);
    const [approvalGroup, setapprovalGroup] = React.useState('');
    const [externalMappingRule, setExternalMappingRule] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [selected2, setSelected2] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [open2, setOpen2] = React.useState('');
    const [approvalGroupArray, setApprovalGroupArray] = React.useState([]);
    const [qualifierNameArray, setQualifierNameArray] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [editID, setEditID] = React.useState(0);
    const [priority, setPriority] = React.useState('');
    const [priceListIdDescription, setPriceListIdDescription] = React.useState('');

    const isAllSelected =
        qualifierNameArray.value && selected && qualifierNameArray.value.length > 0 && selected.length === qualifierNameArray.value.length;
    const isAllSelected2 =
        priceTypeNameArray.value && selected2 && priceTypeNameArray.value.length > 0 && selected2.length === priceTypeNameArray.value.length;
    function handleOnSubmit() {
        var data = {
            "qualifier_key": qualifierName,
            "price_list_id_description": priceListIdDescription,
            "price_type_name": priceTypeName,
            "external_mapping_rule": externalMappingRule,
            "approval_group_id": approvalGroup,
            "priority": priority ? parseInt(priority) : 0
        }
        props.onSubmit(data, editID);
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === qualifierNameArray.value.length ? [] : qualifierNameArray.value);
        }
        else
            setSelected(newSelected)

    }
    const handleSelectValues2 = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected2(selected2.length === priceTypeNameArray.value.length ? [] : priceTypeNameArray.value);
        }
        else
            setSelected2(newSelected)

    }
    function onAdd() {

        setQualifierName(selected)
        setOpen(false)
        setSelected([])
    }
    function onAdd2() {
        setPriceTypeName(selected2)
        setOpen2(false)
        setSelected2([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])
    }
    function onCancel2() {
        setOpen2(false)
        setSelected2([])

    }
    function handleOpen() {
        setSelected(qualifierName)
        setOpen(true)
    }
    function handleOpen2() {
        setSelected2(priceTypeName)
        setOpen2(true)
    }
    useEffect(() => {
        if (props.configData)
            setQualifierName(props.configData.qualifier_key ? props.configData.qualifier_key : [])
        setSelected(props.configData.qualifier_key)
        setPriceTypeName(props.configData.price_type_name ? props.configData.price_type_name : [])
        setSelected2(props.configData.price_type_name)
        setExternalMappingRule(props.configData.external_mapping_rule)
        setapprovalGroup(props.configData.approval_group_id)
        setPriority(props.configData.priority)
        setPriceListIdDescription(props.configData.price_list_id_description)
    }, [props.configData])
    useEffect(() => {
        if (props.qualifierNameList)
            setQualifierNameArray(props.qualifierNameList)
    }, [props.qualifierNameList])

    useEffect(() => {
        if (props.priceTypeNameList)
            setPriceTypeNameArray(props.priceTypeNameList)
    }, [props.priceTypeNameList])

    useEffect(() => {
        var newArray = []
        if (props.approvalGroup && props.approvalGroup.records) {
            props.approvalGroup.records
                .map(item => {
                    newArray.push({ 'name': item.name, 'id': item.ID })

                })

        }
        setApprovalGroupArray(newArray)
    }, [props.approvalGroup])

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/pricing-list-master'
                >
                    Price List Master
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Price List Master</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formlabel}>
                                            Price List ID Description
                                        </FormLabel>
                                        <OutlinedInput
                                            value={priceListIdDescription}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setPriceListIdDescription(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Qualifier Name
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                labelId="demo-mutiple-name-label"
                                                id="demo-mutiple-name"
                                                multiple
                                                value={qualifierName}
                                                style={{ maxHeight: "50px", height: 36, width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                input={<OutlinedInput />}
                                                onOpen={() => handleOpen()}
                                                onClose={() => setOpen(false)}
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                open={open}
                                                renderValue={(appType) => {
                                                    var qualifier = []
                                                    appType
                                                        .map(item => {
                                                            qualifier.push(item.replace(/_/g, ' '))

                                                        })
                                                    return qualifier.join(", ")
                                                }}
                                                className={classes.multiSelect}

                                            >
                                                <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={isAllSelected} />
                                                    <ListItemText primary='Select All' />
                                                </MenuItem>
                                                {qualifierNameArray && qualifierNameArray.value && qualifierNameArray.value
                                                    .map(item => {
                                                        return (
                                                            <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked={selected?.includes(item) ? true : false}
                                                                />
                                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                <div className={classes.dropdownbottom}>
                                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                        Cancel
                                                    </Button>

                                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formDiv} required>
                                            Price Type Name
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                labelId="demo-mutiple-name-label"
                                                id="demo-mutiple-name"
                                                multiple
                                                value={priceTypeName}
                                                style={{ maxHeight: "50px", height: 36, width: '100%', marginTop: 10 }}
                                                input={<OutlinedInput />}
                                                onOpen={() => handleOpen2()}
                                                onClose={() => setOpen2(false)}
                                                open={open2}
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                renderValue={(appType) => {
                                                    var priceType = []
                                                    appType
                                                        .map(item => {
                                                            priceType.push(item.replace(/_/g, ' '))

                                                        })
                                                    return priceType.join(", ")
                                                }}
                                                className={classes.multiSelect}

                                            >
                                                <MenuItem value='all' onClick={(event) => handleSelectValues2(event, 'all')}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={isAllSelected2} />
                                                    <ListItemText primary='Select All' />
                                                </MenuItem>
                                                {priceTypeNameArray && priceTypeNameArray.value && priceTypeNameArray.value
                                                    .map(item => {
                                                        return (
                                                            <MenuItem onClick={(event) => handleSelectValues2(event, item)} value={item} key={item}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked={selected2?.includes(item) ? true : false} />
                                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                <div className={classes.dropdownbottom}>
                                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel2}>
                                                        Cancel
                                                    </Button>

                                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd2} >
                                                        Apply
                                                    </Button>
                                                </div>


                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formlabel}>
                                            External Mapping Rule
                                        </FormLabel>
                                        <OutlinedInput
                                            value={externalMappingRule}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setExternalMappingRule(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} >
                                            Approval Group
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={approvalGroup}
                                                onChange={(e) => setapprovalGroup(e.target.value)}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ textTransform: 'capitalize', marginTop: 10 }}
                                            >

                                                {approvalGroupArray && approvalGroupArray
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.id} key={item.id} style={{ textTransform: 'capitalize' }}>
                                                                {item.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formlabel} required>
                                            Priority
                                        </FormLabel>
                                        <OutlinedInput
                                            value={priority}
                                            type={'number'}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setPriority(e.target.value)} />
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </form>
                </Card >

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={qualifierName.length > 0 && priceTypeName && priority ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(createPriceListMaster(data, id, 'edit')),
        getConfigData: (id) => dispatch(getPriceListMasterById(id)),
        getFieldValues: (key) => dispatch(getPriceFieldValues(key)),
        getApprovalGroup: () => dispatch(getApprovalGroupData())
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.pricingData.loading,
        configData: state.pricingData.priceListMasterSingleData,
        qualifierNameList: state.pricingData.priceQualifierNameList,
        priceTypeNameList: state.pricingData.priceTypeNameList,
        approvalGroup: state.addMultipleConfigurationData.approvalGroupData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPriceList);