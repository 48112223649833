import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Table, TableCell,
    TableHead, TableRow, TableContainer, TableBody, IconButton,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Fab
} from '@mui/material';
import { Publish, AddBoxOutlined, Visibility, DeleteForever, Edit, LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    uploadMembershipContractGroupFile, getMembershipContractGroupFormatData, deleteMembershipContractGroupFormatData
} from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from "@mui/material/Tooltip";
import { UploadFile } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fontSizeicon: {
        fontSize: theme.typography.h4.fontSize
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const MembershipContractGroupExcelUpload = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [membershipData, setMembershipData] = React.useState([]);
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        props.getMembershipContractGroupFormatData();
        sessionStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        if (props.membershipFormatData) {
            setMembershipData(props.membershipFormatData)
        }
    }, [props.membershipFormatData]);
    useEffect(() => {
        if (props.membershipErrorList) {
            setErrorList(props.membershipErrorList)
            if (Object.entries(props.membershipErrorList).length > 0 && props.membershipErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.membershipErrorList])
    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadMembershipContractGroupFile(currentEditableID, data);
        setOpen(true)
    }
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        sessionStorage.setItem('membership-query', JSON.stringify(item))
        history.push({ pathname: '/membership-contract-group-excel-upload/edit/' + item.id });

    }
    function viewQuery(item) {
        sessionStorage.setItem('membership-query', JSON.stringify(item))
        history.push({ pathname: '/membership-contract-group-excel-upload/view/' + item.id });
    }
    function deleteQuery(item) {
        props.deleteMembershipContractGroupFormatData(item.id);
    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'> Contract Group Upload </Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={() => history.push({ pathname: '/membership-contract-group-excel-upload/add-query' })}
                >
                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                </Fab>
            </div>
            {membershipData && membershipData.length > 0 ?
                <TableContainer style={{ marginTop: -25 }}>
                    <Table stickyHeader>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {membershipData
                                .sort(function (a, b) {
                                    return a.id > b.id ? 1 : -1;
                                })
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.id} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.id}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.format_name}</TableCell>
                                            {props.loading ?
                                                <TableCell width={300} align='center'>
                                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                </TableCell>
                                                :
                                                <TableCell align='center' width={300}>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => editQuery(item)}
                                                        size="large">  <Tooltip title="Edit">
                                                            <Edit color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => viewQuery(item)}
                                                        size="large">  <Tooltip title="View">
                                                            <Visibility color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <input type='file'
                                                        accept=".xlsx, .xls, .csv"
                                                        id='file' ref={inputFile} style={{ display: 'none' }}
                                                        onChange={(e) => handleDrop(e)} />
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => runQuery(item)}
                                                        size="large">  <Tooltip title="Upload File">
                                                            <Publish color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <input type='file'
                                                        accept=".xlsx, .xls, .csv"
                                                        id='file' ref={inputFile} style={{ display: 'none' }}
                                                        onChange={(e) => handleDrop(e)} />
                                                    {/* <Tooltip title="Batch Upload">
                                                    <IconButton classes={{ root: classes.IconButton }}
                                                     onClick={() => runQuery(item)}
                                                     >
                                                        <UploadFile  color="disabled" classes={{root:classes.fontSizeicon}} disabled={true}/>
                                                    </IconButton>
                                                    </Tooltip>  */}
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => deleteQuery(item)}
                                                        size="large">  <Tooltip title="Delete">
                                                            <DeleteForever color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                        </Tooltip>
                                                    </IconButton>
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography variant='h4'>
                    No invoice query list added.
                </Typography>
            }
            <Dialog
                fullWidth
                maxWidth="xs"
                open={errorDialogOpen}
            >
                <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                    <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                </DialogActions>
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>

                    <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        membershipFormatData: state.customerData.membershipContractGroupFormatData,
        membershipErrorList: state.customerData.membershipContractGroupErrorList,
        loading: state.dataSetupData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadMembershipContractGroupFile: (id, file) => dispatch(uploadMembershipContractGroupFile(id, file)),
        deleteMembershipContractGroupFormatData: (id) => dispatch(deleteMembershipContractGroupFormatData(id)),
        getMembershipContractGroupFormatData: () => dispatch(getMembershipContractGroupFormatData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipContractGroupExcelUpload);