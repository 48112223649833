import React, { useState, useEffect } from 'react';
import secureLocalStorage from "react-secure-storage";
function IdleTimerContainer() {
    const [isIdle, setIsIdle] = useState(false);

    useEffect(() => {
        let idleTimeout;
        let idleInterval;

        const logout = () => {
            console.log("timeout");
            // Perform logout action here
            localStorage.setItem('loggedIn', false);
            localStorage.clear();
            secureLocalStorage.clear();
            window.location.href = '/login';
        };

        const handleIdle = () => {
            setIsIdle(true);
            idleTimeout = window.setTimeout(logout, 15 * 60 * 1000); // 15 minutes timeout
        };

        const handleActive = () => {
            setIsIdle(false);
            clearTimeout(idleTimeout); // Clear the idle timeout when activity is detected
            clearTimeout(idleInterval); // Reset the initial idle check timer
            idleInterval = window.setTimeout(handleIdle, 5000); // Set the time for idle
        };

        idleInterval = window.setTimeout(handleIdle, 5000); // Set initial idle timeout

        window.addEventListener('mousemove', handleActive);
        window.addEventListener('keydown', handleActive);
        window.addEventListener('click', handleActive);

        return () => {
            clearTimeout(idleTimeout);
            clearTimeout(idleInterval);
            window.removeEventListener('mousemove', handleActive);
            window.removeEventListener('keydown', handleActive);
            window.removeEventListener('click', handleActive);
        };
    }, []);
}
export default IdleTimerContainer;
