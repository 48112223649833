import React, { useEffect} from 'react';
import {
    Grid,Typography,Breadcrumbs,
    Card
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getPricingBatchJobDetail } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { LabelText } from '../../../../components/Inputs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    }
}));

const ViewPricingBatchJobSetup = props => {
    const classes = useStyles();
    const [batchSetupID,setBatchSetupID] = React.useState(0);
    const [customerNumber,setCustomerNumber] = React.useState([]);
    const [materialNumber,setMaterialNumber] = React.useState([]);
    const [quantity,setQuantity] = React.useState([]);
    const [priceCalcFormula,setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray,setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [calculationDateType, setCalculationDateType] = React.useState('');
    const [numDays,setNumDays] = React.useState(0);
    useEffect(() => {
        if (props.batchJobDetail && props.batchJobDetail.app_type) {
            setBatchSetupID(props.batchJobDetail.ID)
            setBatchJobType(props.batchJobDetail.batch_job_type);
            setBatchJobName(props.batchJobDetail.batch_job_name);
            setPlannedStartDate(props.batchJobDetail.planned_start_date);
            setFrequency(props.batchJobDetail.frequency);
            setPlannedStartTime(props.batchJobDetail.planned_start_time)
            setPlannedDateType(props.batchJobDetail.planned_date_type)
            setPlannedStartCalendar(props.batchJobDetail.planned_start_calendar)
            if (props.batchJobDetail.batch_filter_criteria) {
                props.batchJobDetail.batch_filter_criteria.map((item, index) => {
                    setCustomerNumber(item.customer_number)
                    setMaterialNumber(item.material_number)
                    setPriceCalcFormula(item.formula)
                    setQuantity([item.quantity])
                    setPricingDateStart(item.pricing_date)
                    setNumDays(item.num_days)
                    setCalculationDateType(item.calculation_date_type)
                })
            }
        }
    }, [props.batchJobDetail])

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getBatchJobDetail(appId);

    }, []);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/pricing-batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>View Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}
                            >

                                {batchJobType &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <LabelText heading='Batch Job Type' data={batchJobType} twoline='true' />
                                    </Grid>
                                }
                                {batchJobName &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <LabelText heading='Batch Job Name' data={batchJobName} twoline='true' />
                                    </Grid>
                                }
                                {plannedDateType &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText heading='Planned Date Type' data={plannedDateType} twoline='true' />
                                    </Grid>
                                }
                                {frequency &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText heading='Frequency' data={frequency} twoline='true' />
                                    </Grid>
                                }

                                {plannedStartDate &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText heading='Planned Start Date ' data={Moment(plannedStartDate).format('MM/DD/YYYY')} twoline='true' />
                                    </Grid>
                                }
                                {plannedStartCalendar &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText heading='Planned Start Calendar ' data={plannedStartCalendar} twoline='true' />
                                    </Grid>
                                }
                                {plannedStartTime &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText heading='Planned Start Time' data={plannedStartTime} twoline='true' />
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={2}
                            >
                                {customerNumber &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText heading='Customer Number' data={customerNumber} twoline='true' />
                                    </Grid>
                                }

                                {materialNumber &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText heading='Material Number' data={materialNumber} twoline='true' />
                                    </Grid>
                                }
                                {quantity > 0 &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText heading='Quantity' data={quantity} twoline='true' />
                                    </Grid>
                                }
                                {priceCalcFormula &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText heading='Formula' data={priceCalcFormula} twoline='true' />

                                    </Grid>
                                }
                                {pricingDateStart &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <LabelText heading='Pricing Date' data={Moment(pricingDateStart).format('MM/DD/YYYY')} twoline='true' />
                                    </Grid>
                                }
                                {calculationDateType &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText heading='Calculation Date Type' data={calculationDateType} twoline='true' />
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
        </div>
    );


};

const mapDispatchToProps = dispatch => {
    return {
        getBatchJobDetail: (id) => dispatch(getPricingBatchJobDetail(id)),
        }
}

const mapStateToProps = state => {
    return {
        batchJobDetail: state.customerData.pricingBatchJobDetail,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPricingBatchJobSetup);