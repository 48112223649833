import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
import { getMessageLogs } from '../../redux/actions';
import Moment from 'moment';
import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Aggregate,
    ExcelExport, Toolbar, PdfExport, Search, CommandColumn, ColumnMenu, VirtualScroll
} from '@syncfusion/ej2-react-grids';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        width: 'fit-content',
        overflow: 'scroll'
    },
    hover: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    gridBox: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginBottom: 10,
        overflowY: 'scroll'
    },
}));

const MessageLog = (props) => {
    const grid = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const [dataRows, setDataRows] = useState([]);
    const [count, setCount] = useState(0);
    const pageOptions = { pageSizes: true };
    const filterSettings = { type: 'CheckBox' };
    const groupOptions = { showGroupedColumn: true };
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const batchSize = 100;

    useEffect(() => {
        props.getMessageLog(currentPage, batchSize);
    }, []);
    useEffect(() => {
        if (props.messageLogData && props.messageLogData.records) {
            const tempRows = props.messageLogData.records.map(e => ({
                id: e.ID,
                app: e.app,
                functionality: e.functionality,
                input_type: e.input_type,
                input_number: e.input_number,
                transaction_number: e.transaction_number,
                message_type: e.message_type,
                message_description: e.message_description,
                user_name: e.user_name,
                updated_at: Moment(e.UpdatedAt).local().format('YYYY-MM-DD HH:mm:ss')
            }));
            setDataRows(prevRows => [...prevRows, ...tempRows]);
            setIsLoading(false);
        }
    }, [props.messageLogData])

    const onScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = grid.current.ej2_instances[0].contentModule.getPanel().firstChild;

        if (scrollTop + clientHeight >= scrollHeight - 100 && !isLoading) {
            setIsLoading(true);
            setCurrentPage(prevPage => prevPage + 1);
            props.getMessageLog(currentPage + 1, batchSize);
        }
    };

    const onPageChange = (args) => {
        props.getMessageLog(args.currentPage, args.pageSize);
    };

    return (
        <div>
            <Typography variant="h1" color='primary' style={{ marginLeft: 16, marginTop: 18 }}>Activity Logs</Typography>
            <div className={classes.root}>
                {props.messageLogData && props.messageLogData.records && props.messageLogData.total_record > 0 ? (
                    <GridComponent
                        dataSource={dataRows}
                        id='grid'
                        ref={grid}
                        allowPaging={false}
                        allowSorting={true}
                        allowFiltering={true}
                        toolbar={['Search']}
                        gridLines='Both'
                        filterSettings={filterSettings}
                        enableVirtualization={true}
                        enableColumnVirtualization={false}
                        groupSettings={groupOptions}
                        showColumnMenu={true}
                        allowResizing={true}
                        height={window.screen.height * .75}
                        onScroll={onScroll}

                    >
                        <ColumnsDirective>
                            <ColumnDirective field='app' headerText='Application' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                            <ColumnDirective field='functionality' headerText='Functionality' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                            <ColumnDirective field='input_type' headerText='Input Type' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                            <ColumnDirective field='input_number' headerText='Input Number' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                            <ColumnDirective field='transaction_number' headerText='Transaction Number' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                            <ColumnDirective field='message_type' headerText='Message Type' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                            <ColumnDirective field='message_description' headerText='Message Description' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" 
                             template={data => <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{data.message_description}</div>} 
                            />
                            <ColumnDirective field='user_name' headerText='User Name' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                            <ColumnDirective field='updated_at' headerText='Created At' width="150px" minWidth='100px' maxWidth='300px' textAlign="Left" />
                        </ColumnsDirective>
                        <Inject services={[Page, Resize, Sort, Filter, Group, Toolbar, CommandColumn, Aggregate, VirtualScroll, ColumnMenu]} />
                    </GridComponent>
                ) : (
                    <div>There is no data to show now.</div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        messageLogData: state.auditLabData.messageLogData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMessageLog: (pagination, limit) => dispatch(getMessageLogs(pagination, limit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageLog);
