import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";
import Moment from "moment";
import {
  pricingDashboardMargin,
  getPricingMarginRevenueData,
  getPricingMarginRevenueDashboardData, dashboardDashBoardNames
} from "../../redux/actions";
import SyncFusionColumnChart from "../Analytics/SyncFusion/SyncFusionColumnChart";
import SyncFusionChart from "../Analytics/SyncFusion/SyncFusionChart";
import { WidthProvider, Responsive } from "react-grid-layout";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
    height: "100%",
  },
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: -20,
    borderRadius: 10,
    padding: "27px 10px 27px 10px",
  },
  pdRoot: {
    backgroundColor: "white",
    // padding: "10px 30px",
    borderRadius: 10,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
    //paddingTop: 10,
  },
  // gridContainer: {
  //   boxShadow:
  //     "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
  //   paddingTop: 10,
  // },
  formLabel: {
    width: '100%',
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: theme.typography.h1.fontSize,
    display: 'flex',
    justifyContent: 'center',

  },
}));
const PricingDashboard = (props) => {
  const classes = useStyles();
  const ResponsiveReactGridLayout = WidthProvider(Responsive);
  const originalLayouts = getFromLS("layouts") || {};
  const [historicalData, setHistoricalData] = React.useState([]);
  const [layouts, setLayouts] = React.useState("");
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [allowedTilesFilter1, setAllowedTilesFilter1] = React.useState([]);
  useEffect(() => {
    props.allowedDashBoardApps(sessionStorage.getItem("application"));
  }, []);
  function resetLayout() {
    this.setState({ layouts: {} });
  }
  function onLayoutChange(layout, layouts) {
    // saveToLS("layouts", layouts);
    setLayouts(layouts);
  }
  function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
        // /Ignore/
      }
    }
    return ls[key];
  }
  const [historicalDataMaterial, setHistoricalDataMaterial] = React.useState(
    []
  );
  const colors = [
    "#00B3E6",
    "#FF6633",
    "#9900B3",
    "#E6B333",
    "#3366E6",
    "#E6331A",
    "#B34D4D",
    "#809900",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Margin/Revenue Contribution" && item.dashboard_name === "Default Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])
  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Margin-Actual/Target/Forecast" && item.dashboard_name === "Default Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter1([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter1([]);
      }
    }
  }, [props.allowedDashBoardData])
  console.log("1", allowedTilesFilter1)
  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      props.pricingRevenueMargin(Moment(allowedTilesFilter[0].start_date).format('MM/DD/YYYY'), Moment(allowedTilesFilter[0].end_date).format('MM/DD/YYYY'), "customer_number");
      props.pricingRevenueMarginDashboard(Moment(allowedTilesFilter[0].start_date).format('MM/DD/YYYY'), Moment(allowedTilesFilter[0].end_date).format('MM/DD/YYYY'), "material_number");
    }
  }, [allowedTilesFilter]);
  useEffect(() => {
    if (allowedTilesFilter1 && allowedTilesFilter1.length > 0) {
      var data = {
        //customer_number: [],
        //material_number: [],
        historical_start_date:Moment.utc().subtract(3, "months").startOf("month"), //allowedTilesFilter1[0].historical_start_date,
        historical_end_date: Moment.utc().subtract(1, "months").endOf("month"),//allowedTilesFilter1[0].historical_end_date,
        forecast_start_date: Moment.utc().subtract(3, "months").startOf("month"),//allowedTilesFilter1[0].forecast_start_date,
        forecast_end_date: Moment.utc().subtract(1, "months").endOf("month"),//allowedTilesFilter1[0].forecast_end_date,
        periodicity: "cumulative",
        attribute_name: ["customer_group"],
        simulation_category: "both",
        limit: 10,
        orientation:'top'
      };
      var data2 = {
        //customer_number: [],
        //material_number: [],
        historical_start_date:Moment.utc().subtract(3, "months").startOf("month"), //allowedTilesFilter1[0].historical_start_date,
        historical_end_date: Moment.utc().subtract(1, "months").endOf("month"),//allowedTilesFilter1[0].historical_end_date,
        forecast_start_date: Moment.utc().subtract(3, "months").startOf("month"),//allowedTilesFilter1[0].forecast_start_date,
        forecast_end_date: Moment.utc().subtract(1, "months").endOf("month"),//allowedTilesFilter1[0].forecast_end_date,
       periodicity: "cumulative",
       attribute_name: ["material_group"],
        simulation_category: "both",
        limit: 10,
        orientation:'top'
      };
      props.pricingDashboardMarginAPI("customer", data);
      props.pricingDashboardMarginAPI("material", data2);
    }
  }, [allowedTilesFilter1]);
  function convertToSnakeCase(string) {
    return string.replace(/\s+/g, '_').toLowerCase();
  }

  useEffect(() => {
    var sdate = Moment()
      .subtract(5, "months")
      .startOf("month")
      .format("MM/DD/YYYY");
    var edate = Moment().endOf("month").format("MM/DD/YYYY");
    // var data = {
    //   customer_number: [],
    //   material_number: [],
    //   historical_start_date: Moment().subtract(1, "months").startOf("month"),
    //   historical_end_date: Moment().subtract(1, "months").endOf("month"),
    //   forecast_start_date: Moment().startOf("month"),
    //   forecast_end_date: Moment().endOf("month"),
    //   periodicity: "cumulative",
    //   key_figure_name: ["customer_number"],
    //   simulation_category: "both",
    //   limit: 10,
    // };
    // var data2 = {
    //   customer_number: [],
    //   material_number: [],
    //   historical_start_date: Moment().subtract(1, "months").startOf("month"),
    //   historical_end_date: Moment().subtract(1, "months").endOf("month"),
    //   forecast_start_date: Moment().startOf("month"),
    //   forecast_end_date: Moment().endOf("month"),
    //   periodicity: "cumulative",
    //   key_figure_name: ["material_number"],
    //   simulation_category: "both",
    //   limit: 10,
    // };
    // props.pricingDashboardMarginAPI("customer", data);
    // props.pricingDashboardMarginAPI("material", data2);
    // props.pricingRevenueMargin(sdate, edate, "customer_number");
    // props.pricingRevenueMarginDashboard(sdate, edate, "material_number");
  }, []);
  useEffect(() => {
    if (
      props.pricingDashboardCustomer &&
      props.pricingDashboardCustomer.length > 0
    ) {
      setHistoricalData(
        props.pricingDashboardCustomer
          .sort((a, b) =>
            a.margin_historical_percentage < b.margin_historical_percentage
              ? 1
              : -1
          )
          .filter((item, idx) => idx < 10)
      );
    }
  }, [props.pricingDashboardCustomer]);
  useEffect(() => {
    if (
      props.pricingDashboardMaterial &&
      props.pricingDashboardMaterial.length > 0
    ) {
      setHistoricalDataMaterial(
        props.pricingDashboardMaterial
          .sort((a, b) => a.margin_historical_percentage < b.margin_historical_percentage ? 1 : -1)
          .filter((item, idx) => idx < 10)
      );
    }
  }, [props.pricingDashboardMaterial]);
  return (
    <div className={classes.root}>
      {/* <ResponsiveReactGridLayout
        className="layout"
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={30}
        layouts={layouts}
        onLayoutChange={(layout, layouts) =>onLayoutChange (layout, layouts)}
        compactType='horizontal'
      >
        <div key="1" id={'chart1'} data-grid={{ w: 6, h: 3, x: 0, y: 0, minW: 2, minH: 3 }}>
            <div className={classes.pdRoot}>
            <Typography variant="h2">
              {" "}
              Customers with Highest Margin %
            </Typography>
            {historicalData&&historicalData.length>0 ? (
              <SyncFusionChart
                id="customer"
                data={historicalData}
                analysisLevel={"customer_number"}
              />
            ) : (
              <Typography variant="h4">
                There is no data to show now.
              </Typography>
            )}
          </div>
        </div>
        <div key="2" data-grid={{ w: 6, h: 3, x:6, y: 0, minW: 2, minH: 3 }}>
        <div className={classes.pdRoot}>
              <Typography variant="h2">
                Revenue/Margin % by Customers
              </Typography>
              {props.pricingData ? (
                <SyncFusionColumnChart
                  id={"chart-customer"}
                  data={props.pricingData}
                  analysisLevel={"customer_number"}
                />
              ) : (
                <Typography variant="h4">
                  There is no data to show now.
                </Typography>
              )}
            </div>
        </div>
        <div key="3" data-grid={{ w: 6, h: 3, x: 6, y: 0, minW: 2, minH: 3 }}>
     <div className={classes.pdRoot}>
              <Typography variant="h2">
                {" "}
                Products with Highest Margin %
              </Typography>
              {historicalDataMaterial ? (
                <SyncFusionChart
                  id="product"
                  data={historicalDataMaterial}
                  analysisLevel={"material_description"}
                />
              ) : (
                <Typography variant="h4">
                  There is no data to show now.
                </Typography>
              )}
            </div>
        </div>
        <div key="4" data-grid={{ w: 6, h: 3, x:0, y: 6, minW: 2, minH: 3 }}>
           <div className={classes.pdRoot}>
              <Typography variant="h2">
                {" "}
                Revenue/Margin % by Products
              </Typography>
              {props.pricingMaterialData ? (
                <SyncFusionColumnChart
                  id={"chart-material"}
                  data={props.pricingMaterialData}
                  analysisLevel={"material_number"}
                />
              ) : (
                <div style={{ height: "100%", justifyContent: "center" }}>
                  <Typography variant="h4">
                    There is no data to show now.
                  </Typography>
                </div>
              )}
            </div>
              </div>
              </ResponsiveReactGridLayout>*/}
      <Grid container className={classes.container} spacing={1}>
        <Grid item md={6} xs={12} >
          <div className={classes.pdRoot} style={{ padding: 3 }}>
            <Typography color="primary" className={classes.formLabel}>
              {" "}
              Customer Segments with Lowest Margin
            </Typography>
            {historicalData/*&&historicalData.length>0*/ ? (
              <SyncFusionChart
                id="customer"
                data={historicalData}
                analysisLevel={"customer_group"}
              />
            ) : (
              <Typography variant="h4">
                There is no data to show now.
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item md={6} xs={12} >
          <div className={classes.pdRoot} style={{ padding: 3 }}>
            <Typography color="primary" className={classes.formLabel}>
              Revenue/Margin Contributed by Customers
            </Typography>
            {props.pricingData ? (
              <SyncFusionColumnChart
                id={"chart-customer"}
                data={props.pricingData}
                analysisLevel={"customer_number"}
              />
            ) : (
              <Typography variant="h4">
                There is no data to show now.
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item md={6} xs={12} >
          <div className={classes.pdRoot} style={{ padding: 3 }}>
            <Typography color="primary" className={classes.formLabel}>
              {" "}
              Revenue/Margin Contributed by Products
            </Typography>
            {props.pricingMaterialData ? (
              <SyncFusionColumnChart
                id={"chart-material"}
                data={props.pricingMaterialData}
                analysisLevel={"material_number"}
              />
            ) : (
              <div style={{ height: "100%", justifyContent: "center" }}>
                <Typography variant="h4">
                  There is no data to show now.
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        <Grid item md={6} xs={12} >
          <div className={classes.pdRoot}style={{ padding: 3 }} >
            <Typography color="primary" className={classes.formLabel}>
              {" "}
              Product Categories with Lowest Margin
            </Typography>
            {historicalDataMaterial ? (
              <SyncFusionChart
                id="product"
                data={historicalDataMaterial}
                analysisLevel={"material_group"}
              />
            ) : (
              <Typography variant="h4">
                There is no data to show now.
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    pricingDashboardCustomer: state.initialData.pricingDashboardCustomer,
    pricingDashboardMaterial: state.initialData.pricingDashboardMaterial,
    pricingData: state.initialData.pricingRevenueMarginData,
    pricingMaterialData: state.initialData.pricingRevenueMarginDashboardData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pricingDashboardMarginAPI: (id, data) =>dispatch(pricingDashboardMargin(id, data)),
    pricingRevenueMargin: (startDate, endDate, analysislevel) =>dispatch(getPricingMarginRevenueData(startDate, endDate, analysislevel,sessionStorage.getItem('application'))),
    pricingRevenueMarginDashboard: (startDate, endDate, analysislevel) => dispatch(getPricingMarginRevenueDashboardData(startDate, endDate, analysislevel,sessionStorage.getItem('application'))),
    allowedDashBoardApps: (type) => dispatch(dashboardDashBoardNames(type, "dashboard")),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PricingDashboard);