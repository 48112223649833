import { createTheme } from '@mui/material';

import palette from './paletteDark';
import typography from './typography';
import overrides from './overridesDark';
const dark = createTheme({
  palette,
  typography,
  overrides,
  shadows: Array(25).fill("none") //removing shadows globally,
});

export default dark;