import React from 'react';
import { connect } from 'react-redux';
import { IntegrationSetupUserTable } from './components';
const IntegrationSetup = () => {
    return (
        <div>
            <IntegrationSetupUserTable />
        </div >
    );
};
export default connect(null, null)(IntegrationSetup);