import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { history } from "../../../../../../components/Helpers";
import { Divider } from "@mui/material";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import Avatar from "@mui/material/Avatar";
import { getProfile, getHelpDeskData } from "../../../../../../redux/actions";
import { connect } from "react-redux";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    color: theme.palette.text.yellow,
    fontSize: "0.83em",
  },
  logout: {
    color: theme.palette.white,
    cursor: "pointer",
    marginBottom: 15,
    marginTop: 5,
  },
  applauncher: {
    textAlign: "center",
    color: "#CEE3F6",
    cursor: "pointer",
    padding: 0,
  },
  divider: {
    background: theme.palette.border.divider,
    margin: "5px 0px 5px 0px",
    width: "100%",
  },
  white: {
    cursor: "pointer",
  },
  trigApp: {
    display: "flex",
  },
  app: {
    color: "#A9BCF5",
    fontSize: "0.83em",
  },
  dialogContent: {
    padding: 30,
  },
}));

const Profile = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  var getUserDetails = secureLocalStorage.getItem("dYtz");
  const [openModal, setOpenModal] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    props.getProfile();
  }, []);

  const handleUserNameClick = () => {
    history.push("/profile");
  };
  const triggerAppLauncher = () => {
    history.push("/applaunchernew");
  };
  const [profileDataAPI, setProfileDataAPI] = React.useState([]);
  useEffect(() => {
    if (props.profileData && Object.keys(props.profileData).length > 0)
      setProfileDataAPI(props.profileData);
  }, [props.profileData]);
  const [logoutPopup, setLogoutPopup] = React.useState(false);
  function handleDialog(bool) {
    if (bool === "No") {
      setLogoutPopup(false);
    } else {
      localStorage.setItem("loggedIn", false);

      localStorage.clear();
      secureLocalStorage.clear();
      history.push("/login");
      setLogoutPopup(false);
    }
  }
  const [currentPage, setCurrentPage] = React.useState("");
  const [showUrl, setShowUrl] = React.useState(false);
  const [selectedUrl, setSelectedUrl] = React.useState(false);
  const [helpDesk, setHelpDesk] = React.useState([]);

  useEffect(() => {
    setShowUrl(false);
    props.getAttributeMapData();
    setCurrentPage(location.pathname);
  }, [location]);

  useEffect(() => {
    if (
      props.helpDeskData &&
      props.helpDeskData.records &&
      props.helpDeskData.records &&
      props.helpDeskData.records.length > 0
    ) {
      setHelpDesk(props.helpDeskData.records);
    }
  }, [props.helpDeskData]);
  useEffect(() => {
    helpDesk &&
      helpDesk.map((item) => {
        if (
          item.application_type === sessionStorage.getItem("application") &&
          item.url === location.pathname
        ) {
          setSelectedUrl(item.link);
          setShowUrl(true);
        }
      });
  }, [helpDesk]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {getUserDetails && getUserDetails.username ? (
        profileDataAPI && profileDataAPI.profile_image_path ? (
          <>
            <Avatar
              className={classes.avatar}
              src={profileDataAPI && profileDataAPI.profile_image_path}
              onClick={handleUserNameClick}
              style={{ cursor: "pointer" }}
            />
            <Typography
              className={classes.name}
              variant="h3"
              onClick={handleUserNameClick}
            >
              {getUserDetails && getUserDetails.username}
            </Typography>
          </>
        ) : (
          <Avatar onClick={handleUserNameClick} className={classes.white}>
            {getUserDetails &&
              getUserDetails.username
                .split(" ")
                .map(function (item) {
                  return item[0];
                })
                .join("")
                .toUpperCase()}
          </Avatar>
        )
      ) : (
        <Typography
          className={classes.name}
          variant="h3"
          onClick={handleUserNameClick}
        >
          {getUserDetails && getUserDetails.username}
        </Typography>
      )}
      <Divider
        className={classes.divider}
        sx={{
          opacity: "0.6"
        }} />
      <div className={classes.app}>
        {sessionStorage.getItem("application")}
        {/* {((currentPage === 'customer-master') || (currentPage === 'product-group')) ?
                    <IconButton color='primary'
                        className={classes.applauncher} onClick={() => setOpenModal(true)} style={{ marginLeft: 10 }}>
                        <LiveHelpIcon style={{ fontSize: 20 }} />
                    </IconButton>
                    :
                    null
                } */}
        {showUrl ? (
          <IconButton
            color="primary"
            className={classes.applauncher}
            onClick={() => setOpenModal(true)}
            style={{ marginLeft: 10 }}
            size="large">
            <LiveHelpIcon style={{ fontSize: 20 }} />
          </IconButton>
        ) : null}
      </div>
      <Divider
        className={classes.divider}
        sx={{
          opacity: "0.6"
        }} />
      <Dialog open={openModal} fullScreen>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Typography variant="h4">
            <DialogContentText>
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                }}
              >
                {/* {currentPage === 'customer-master' ?
                                    <iframe src="https://www.loom.com/embed/73ec272073984a91b6dc826ff0251041" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
                                    :
                                    <iframe src="https://www.loom.com/embed/076f1d86a11f46d2b803c0622d2da740" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>

                                } */}
                <iframe
                  src={selectedUrl}
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions classes={{}}>
          <Button
            onClick={() => setOpenModal(false)}
            color="primary"
            autoFocus
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.trigApp}>
        <div onClick={triggerAppLauncher}>
          <Typography className={classes.applauncher} variant="h6">
            <AirplanemodeActiveIcon style={{ fontSize: 12, marginRight: 5 }} />
            App Launcher
          </Typography>
        </div>
        <div style={{ borderLeft: "1px solid white", marginLeft: 10 }}>
          <IconButton
            onClick={() => setLogoutPopup(true)}
            color="primary"
            className={classes.applauncher}
            size="large">
            <ExitToAppIcon style={{ fontSize: 16, marginLeft: 10 }} />
            <Typography
              className={classes.applauncher}
              variant="h6"
              style={{ marginLeft: 5 }}
            >
              Logout
            </Typography>
          </IconButton>
        </div>
      </div>
      <Divider
        className={classes.divider}
        sx={{
          opacity: "0.6"
        }} />
      {logoutPopup && (
        <Dialog open={logoutPopup}>
          <DialogContent
            classes={{
              root: classes.dialogContent,
            }}
          >
            <Typography variant="h4">
              <DialogContentText>
                Are you sure you want to logout ?
              </DialogContentText>
            </Typography>
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.dialog,
            }}
          >
            <Button
              onClick={() => handleDialog("No")}
              autoFocus
              color="primary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={() => handleDialog("Yes")}
              color="primary"
              autoFocus
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.profileData,
    helpDeskData: state.HelpDeskData.HelpDeskData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getProfile()),
    getAttributeMapData: () => dispatch(getHelpDeskData("noLimit")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
