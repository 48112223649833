
import * as React from "react";
import { useEffect } from 'react';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, DataLabel,
    Inject, Legend, Category, ScatterSeries, Tooltip, Highlight
} from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }`;
const Scatter = (props) => {
    const onChartLoad = (args) => {
        let chart = document.getElementById('charts');
        chart.setAttribute('title', '');
    };
    const load = (args) => {
        let selectedTheme = ' Material';
        args.chart.theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');
    };
    return (<div className='control-pane'>
        <style>{SAMPLE_CSS}</style>
        <div className='control-section'>
            <ChartComponent id='charts' style={{ textAlign: "center" }}
                primaryXAxis={{ valueType: 'Category', labels: props.labels, majorGridLines: { width: 0 }, edgeLabelPlacement: 'Shift', title: props.xLegend }}
                primaryYAxis={{ majorTickLines: { width: 0 }, lineStyle: { width: 0 }, title: props.yLegend, rangePadding: 'None' }}
                load={load.bind(this)} loaded={onChartLoad.bind(this)} legendSettings={{ visible: true, enableHighlight: true }} tooltip={{ enable: true }} width={'100%'}
                chartArea={{ border: { width: 0 } }}>
                <Inject services={[ScatterSeries, Legend, Tooltip, Category, Highlight, DataLabel]} />
                <SeriesCollectionDirective>
                    {props.scatterData && props.scatterData.map((item, index) => (
                        <SeriesDirective dataSource={item.data} width={2}
                            xName={item.xName} yName={item.yName} name={item.name}
                            type={item.type} marker={item.marker} />

                    ))}
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    </div >);
};
export default Scatter;