import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import { Button, Card, Typography, Grid, Table, TableCell, OutlinedInput, ListItemText, Checkbox, TableHead, TableRow, TableContainer, TableBody, CardHeader, FormLabel, MenuItem, Select } from '@mui/material';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { getContractAttributes, updateContractAttributes, getContractAttributesAllData } from '../../redux/actions';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 41,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    gridContainer: {
        width: '100%'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));

const ContractAttributes = (props) => {
    const classes = useStyles();
    const { className } = props;
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [workflowNameArray, setWorkflowNameArray] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState([]);
    const [tierPeriod, setTierPeriod] = React.useState([]);
    const [paymentPartnerRole, setPaymentPartnerRole] = React.useState([]);
    const [paymentMethod, setPaymentMethod] = React.useState([]);
    const [paymentAggregationLevel, setpaymentAggregationLevel] = React.useState([]);
    const isAllSelected = workflowNameArray && Object.entries(workflowNameArray).length > 0 && selected.length === Object.entries(workflowNameArray).length;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        props.getContractAttributesAllData({
            data_source: "contract_header",
            application_type: "Customer Rebate",
        })
    }, [])
    useEffect(() => {
        if (props.contractAttributeAllData && props.contractAttributeAllData.field_label_attributes && Object.entries(props.contractAttributeAllData.field_label_attributes).length > 0) {
            Object.values(props.contractAttributeAllData.field_label_attributes).map((item) => {
                if (item.key === "calculation_method") {
                    setCalculationMethod(item.drop_down_reference_value);
                }
                if (item.key === "tier_period") {
                    setTierPeriod(item.drop_down_reference_value.map(e => e.key));
                }
                if (item.key === "payment_partner_role") {
                    setPaymentPartnerRole(item.drop_down_reference_value.map(e => e.key));
                }
                if (item.key === "payment_method") {
                    setPaymentMethod(item.drop_down_reference_value.map(e => e.key));
                }
                if (item.key === "payment_aggregation_level") {
                    setpaymentAggregationLevel(item.drop_down_reference_value.map(e => e.key));
                }
            })

        }
    }, [props.contractAttributeAllData && props.contractAttributeAllData.field_label_attributes]);
    useEffect(() => {
        if (props.contractAttributeData && props.contractAttributeData.records && props.contractAttributeData.records) {
            setWorkflowNameArray(props.contractAttributeData.records[0].label_names)
            if (props.contractAttributeData.records[0].drop_down_value_keys_modified != null) {
                setSelectedValue(props.contractAttributeData.records[0].drop_down_value_keys_modified)
            }
        }

    }, [props.contractAttributeData])
    function handleOpen() {
        setSelected(selectedValue)
        setOpen(true)
    }
    function onCancel() {
        setOpen(false)
        setSelected([])
    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === Object.entries(workflowNameArray).length ? [] : Object.entries(workflowNameArray).map(([key, value]) => key));
        }
        else
            setSelected(newSelected)
    }
    function onAdd() {
        setSelectedValue(selected)
        setOpen(false)
        setSelected([])
    }
    function handleClose() {
        setOpen(false)
    }
    const handleChange = (fieldName) => {
        setSelectedRowName(fieldName);
        props.getContractAttributes(fieldName)
        if (fieldName === "calculation_method") {
            setSelectedValue(calculationMethod && calculationMethod.map(e => e.key))
        }
        if (fieldName === "tier_period") {
            setSelectedValue(tierPeriod)
        }
        if (fieldName === "payment_partner_role") {
            setSelectedValue(paymentPartnerRole)
        }
        if (fieldName === "payment_method") {
            setSelectedValue(paymentMethod)
        }
        if (fieldName === "payment_aggregation_level") {
            setSelectedValue(paymentAggregationLevel)
        }
    };
    function handleSubmit(clear) {
        if (clear) {
            var data = {
                "drop_down_values_modified": [],
                "drop_down_value_keys_modified": []
            }
            props.onSubmit(data, selectedRowName);
            setSelectedValue([])
        }
        else {
            var data = {
                "drop_down_values_modified": selectedValue.map(item => workflowNameArray[item] || item),
                "drop_down_value_keys_modified": selectedValue
            }
            props.onSubmit(data, selectedRowName);

        }
    }


    return (
        <div className={clsx(classes.root, className)}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Contract Attribute Configuration</Typography>
            </div>
            <div className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>

                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container} >
                            <Grid container >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Field Name' capitalize={true} data={selectedRowName ? selectedRowName.replace(/_/g, ' ') : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        style={{ paddingLeft: 15, marginTop: 20 }}
                                    >
                                        <FormLabel error={false}>
                                            Field Value
                                        </FormLabel>
                                        <div>{
                                            (
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    multiple
                                                    value={selectedValue}
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    style={{ maxHeight: "50px", width: '100%', height: 36, textTransform: 'capitalize' }}
                                                    input={<OutlinedInput />}
                                                    onOpen={() => handleOpen()}
                                                    onClose={handleClose}
                                                    open={open}
                                                    renderValue={(appType) => {
                                                        var priceType = []
                                                        priceType = Object.entries(workflowNameArray).filter(([key, value]) => appType.includes(key)).map(([key, value]) => value)
                                                        return priceType.join(", ")
                                                    }}
                                                    className={classes.multiSelect}
                                                >
                                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')}>
                                                        <Checkbox color='primary' checked={isAllSelected} />
                                                        <ListItemText primary='Select All' />
                                                    </MenuItem>
                                                    {workflowNameArray &&
                                                        Object.entries(workflowNameArray)
                                                            .map(([key, value]) => (
                                                                <MenuItem onClick={(event) => handleSelectValues(event, key)} value={key} key={key}>
                                                                    <Checkbox color='primary' checked={selected.indexOf(key) > -1} />
                                                                    <ListItemText primary={value} />
                                                                </MenuItem>
                                                            ))}
                                                    <div className={classes.dropdownAction}>
                                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('identifier')}>
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </Select>
                                            )}
                                        </div>
                                    </Grid>
                                }
                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract_attribute-' + sessionStorage.getItem('application')) &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        className={classes.buttonContainer}
                                        style={{ paddingLeft: 10 }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ padding: 5 }}
                                            startIcon={<CheckIcon />}
                                            classes={{
                                                root: classes.checkIconContainer
                                            }}
                                            disabled={selectedValue && selectedValue.length > 0 ? false : true}
                                            onClick={() => handleSubmit()}
                                        > Edit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            classes={{
                                                root: classes.checkIconContainer
                                            }}
                                            style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                            startIcon={<CheckIcon />}
                                            disabled={selectedValue && selectedValue.length > 0 ? false : true}
                                            onClick={() => handleSubmit("clear")}
                                        >Reset
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Field Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('calculation_method')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Calculation Method
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {calculationMethod && calculationMethod.length > 0 ? calculationMethod.map(e => e.desc).join(", ")/*?.toString().replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })*/ : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('tier_period')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Tier Period
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {tierPeriod && tierPeriod.length > 0 ? tierPeriod?.toString().replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('payment_partner_role')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Payment Partner Role
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {paymentPartnerRole && paymentPartnerRole.length > 0 ? paymentPartnerRole?.toString().replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('payment_method')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Payment Method
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {paymentMethod && paymentMethod.length > 0 ? paymentMethod?.toString().replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('payment_aggregation_level')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                    Payment Aggregation Level
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                    {paymentAggregationLevel && paymentAggregationLevel.length > 0 ? paymentAggregationLevel?.toString().replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : ''}
                                                </TableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div >
    );
};

const mapStateToProps = state => ({
    contractAttributeData: state.addMultipleConfigurationData.contractAttributeData,
    contractAttributeAllData: state.addMultipleConfigurationData.contractAttributeAllData,
});

const mapDispatchToProps = dispatch => ({
    getContractAttributes: (fieldName) => dispatch(getContractAttributes(fieldName)),
    onSubmit: (data, fieldName) => dispatch(updateContractAttributes(data, fieldName)),
    getContractAttributesAllData: (data) => dispatch(getContractAttributesAllData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractAttributes);
