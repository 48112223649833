import React, { useEffect, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../components/Inputs';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const GeneralData = props => {
    const classes = useStyles();
    const { className } = props;
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [customerMasterData, setCustomerMasterData] = React.useState([]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        setCustomerMasterData(props.masdata);
    }, [props.masdata]);
    return (
        <div className={clsx(classes.root, className)}>
            <Card>
                <CardHeader
                    title="HEADER FIELDS"
                    titleTypographyProps={{ variant: 'h3' }}
                />
                {customerMasterData &&
                    <div className={classes.container}>
                        <Grid container className={classes.gridContainer}>
                            {customerMasterData.customer_number &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_number'] ? fieldLabel['customer_number']['current'] : 'Customer Number'} data={customerMasterData.customer_number} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.customer_name &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_name'] ? fieldLabel['customer_name']['current'] : 'Customer Name'} data={customerMasterData.customer_name} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.customer_type &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_type'] ? fieldLabel['customer_type']['current'] : 'customer_type'} data={customerMasterData.customer_type} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.street &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['street'] ? fieldLabel['street']['current'] : 'street'} data={customerMasterData.street} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.city &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['city'] ? fieldLabel['city']['current'] : 'City'} data={customerMasterData.city} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.country &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['country'] ? fieldLabel['country']['current'] : 'Country'} data={customerMasterData.country} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.state &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='State' data={customerMasterData.state} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.region &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['region'] ? fieldLabel['region']['current'] : 'Country'} data={customerMasterData.region} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.postal_code &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['postal_code'] ? fieldLabel['postal_code']['current'] : 'Postal Code'} data={customerMasterData.postal_code} twoline='true' />
                                </Grid>
                            }

                            {customerMasterData.customer_industry &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_industry'] ? fieldLabel['customer_industry']['current'] : 'customer_industry'} data={customerMasterData.customer_industry} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.customer_classification &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_classification'] ? fieldLabel['customer_classification']['current'] : 'customer_classification'} data={customerMasterData.customer_classification} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.contract_purpose &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Contract Purpose' data={customerMasterData.contract_purpose} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.class_of_trade &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['class_of_trade'] ? fieldLabel['class_of_trade']['current'] : 'class_of_trade'} data={customerMasterData.class_of_trade} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.account_group &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['account_group'] ? fieldLabel['account_group']['current'] : 'account_group'} data={customerMasterData.account_group} twoline='true' />
                                </Grid>
                            }

                            {customerMasterData.head_office &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['head_office'] ? fieldLabel['head_office']['current'] : 'Head Office'} data={customerMasterData.head_office} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.payment_terms &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['payment_terms'] ? fieldLabel['payment_terms']['current'] : 'Payment Terms'} data={customerMasterData.payment_terms} twoline='true' />
                                </Grid>
                            }
                            {/* {customerMasterData.flex_attribute1 &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} data={customerMasterData.flex_attribute1} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.flex_attribute2 &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} data={customerMasterData.flex_attribute2} twoline='true' />
                                </Grid>
                            } */}
                            {customerMasterData.created_by && customerMasterData.created_by.username &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText heading='Created by' data={customerMasterData.created_by.username} twoline='true' />
                                </Grid>
                            }

                            {customerMasterData.created_by && customerMasterData.created_by.created_at && customerMasterData.created_by.created_at != '0001-01-01T00:00:00Z' &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText heading='Created on' data={customerMasterData.created_by.created_at} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.created_by && customerMasterData.created_by.username &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText heading='Changed by' data={customerMasterData.created_by.username} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.created_by && customerMasterData.created_by.updated_at && customerMasterData.created_by.updated_at != '0001-01-01T00:00:00Z' &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText heading='Changed on' data={customerMasterData.created_by.updated_at} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.customer_status &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['customer_status'] ? fieldLabel['customer_status']['current'] : 'Customer Status'} data={customerMasterData.customer_status} twoline='true' />
                                </Grid>
                            }
                            {customerMasterData.price_list &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText heading={fieldLabel['price_list'] ? fieldLabel['price_list']['current'] : 'Price List'} data={customerMasterData.price_list} twoline='true' />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </Card>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        // customerMasterData: state.customerData.customerMasterViewData
    }
}

export default connect(mapStateToProps, null)(GeneralData);