import React from 'react';
import { PaymentPostingsForm } from './containers';

const FinancialPostings = props => {

    return (
        <PaymentPostingsForm />
    );

};

export default FinancialPostings;