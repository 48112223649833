import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    TablePagination, Fab
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { getPriceFormula, createPriceFormula } from '../../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LibraryAdd } from '@mui/icons-material';
import { TableArray } from './FormulaFields';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const FormulaLabMain = props => {
    useEffect(() => {
        props.getFormulaList(1, 100);
    }, []);

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [formulaList, setFormulaList] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getFormulaList(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getFormulaList(1, event.target.value);
    };

    useEffect(() => {
        if (props.formulaList && props.formulaList.records) {

            setFormulaList(props.formulaList.records);
        }
    }, [props.formulaList]);


    const handleEdit = (id, item) => {
        history.push({
            pathname: '/pricing-formula/edit-formula/' + id,
            id: item.ID
        });
        sessionStorage.setItem('formula-details', JSON.stringify(item));
    }
    function viewFormula(item) {
        sessionStorage.setItem('formula-details', JSON.stringify(item))
        history.push({
            pathname: '/pricing-formula/view-formula/' + item.ID,
            id: item.ID
        });
    }
    function handleFormula(item) {
        var temp = []
        var label = []
        if (item.formula && item.formula_type) {
            item.formula_type
                .map((i, index) => {
                    if (i == "op" || i == "const")
                        temp.push(item.formula[index])
                    else {
                        label = TableArray.filter(value => value.key == i)
                        var field = label && label[0] ? label[0].name && label[0].name.replace(/_/g, ' ') + "[" + item.formula[index].replace(/_/g, ' ') + "]" : ''
                        temp.push(field)
                    }
                })
            return temp;
        }

    }



    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{ marginLeft: 10 }}> Formula - Price Points</Typography>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_history-' + sessionStorage.getItem('application')) &&
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        // component={CustomRouterLink}
                        onClick={() => history.push('/pricing-formula/add-formula')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                }
            </div>
            {formulaList && formulaList.length > 0 ?
                <TableContainer style={{ marginTop: -26 }}>
                    <Table stickyHeader>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Formula ID</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Formula Name</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Formula Expression</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {formulaList.map(item => {
                                return (
                                    <StyledTableRow key={item.id} >
                                        <TableCell align='center' onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.ID}</TableCell>
                                        <TableCell align='center' onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.formula_name}</TableCell>
                                        <TableCell align='center' onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>
                                            {item.formula && handleFormula(item)}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_history-' + sessionStorage.getItem('application')) &&
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => handleEdit(item.ID, item)}
                                                        size="large">
                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>}
                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_history-' + sessionStorage.getItem('application')) &&
                                                    <IconButton
                                                        onClick={() => props.onDelete(item.ID)}
                                                        classes={{ root: classes.IconButton }}
                                                        size="large">
                                                        <DeleteForeverIcon />
                                                    </IconButton>}
                                            </div>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    {/* <TablePagination
                        component="div"
                        count={props.formulaList && props.formulaList.total_record ? props.formulaList.total_record : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangePage={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    /> */}

                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        formulaList: state.pricingData.priceFormulaList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFormulaList: (page, limit) => dispatch(getPriceFormula(page, limit)),
        onDelete: (id) => dispatch(createPriceFormula(null, id, 'delete')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormulaLabMain);