import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab, FormLabel,
    Select, OutlinedInput,
    MenuItem, FormControl, InputLabel, Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { connect } from 'react-redux';
import { getReportTypeData, getConfigurationDesigner, getReportOnRollData, getDynamicQuery, runDynamicQuery, runPredefinedQuery, getExcelPredefinedFileDownlaod, downloadDynamicQuery } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import Tooltip from "@mui/material/Tooltip";
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { AddBoxOutlined, LibraryAdd, DeleteForever, Edit, DirectionsRun } from '@mui/icons-material';
import { FlightTakeoff, Reorder, GetApp } from '@mui/icons-material';
import SimpleDialog from '../../components/Dialog/SimpleDialog';
import FileDownload from '@mui/icons-material/FileDownload';
import Email from '@mui/icons-material/Email';
import Table from '../../components/Inputs/Table';
import { useLocation } from "react-router-dom";
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    select: {
        color: '#1675e0',
        // backgroundColor: theme.palette.white,
        backgroundColor: "#fdf8f8",
        // width: "50%",
    },
}));

const AnalyticsDesignerMain = props => {
    useEffect(() => {
        props.getReportTypeData();
        props.getClaimData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [reportingCategoryArray, setReportingCategoryArray] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [currentId, setCurrentId] = React.useState("");
    const [reportingCategory, setReportingCategory] = React.useState("");
    const [preDefineNameArray, setPreDefineNameArray] = React.useState([]);
    const [value, setValue] = React.useState([]);

    const [openConfirmEmail, setOpenConfirmEmail] = React.useState(false);
    const [selectedQuery, setSelectedQuery] = React.useState({});
    const [currentPage, setCurrentPage] = React.useState("");
    const location = useLocation();
    const [emailId, setEmailId] = React.useState(sessionStorage.getItem('loginData') ? JSON.parse(sessionStorage.getItem('loginData')).email : '');

    // console.log("1", history.location.state)

    useEffect(() => {
        setCurrentPage(
            location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
        );
    }, [location]);
    useEffect(() => {
        sessionStorage.removeItem("SFDQ");
        // if (history.location.state === "deleteData") {
        //     sessionStorage.removeItem("set-reporting-category")
        // }
        // else {
        setReportingCategory(sessionStorage.getItem("set-reporting-category"));
        props.getReportOnRollData(sessionStorage.getItem("set-reporting-category"));
        // }
    }, []);


    useEffect(() => {
        if (props.claimData && props.claimData.length > 0) {
            props.claimData.forEach(data => {
                if (data.key_name === "reporting_category") {
                    if (data.config_fields != null) {
                        setReportingCategoryArray(data.config_fields)
                    }
                    else {
                        setReportingCategoryArray([])
                    }
                }
            });
        }
    }, [props.claimData]);

    useEffect(() => {
        if (props.analysisData) {
            const reportKey = Object.keys(props.analysisData).find(key => props.analysisData[key].report_names);

            if (reportKey) {
                const { analytics, on_demand_query, dashboard, predefined_query, on_demand_analytics, operational_report } = props.analysisData[reportKey].report_names;
                const addFormatType = (array, type) => {
                    return array.map(obj => ({ ...obj, format_type: type }));
                };
                const modifiedAnalytics = analytics ? addFormatType(Object.values(analytics), "analytics") : [];
                const modifiedDashboard = dashboard ? addFormatType(Object.values(dashboard), "dashboard") : [];
                const modifiedOnDemandQuery = on_demand_query ? addFormatType(Object.values(on_demand_query), "on_demand_query") : [];
                const modifiedOnDemandAnalytics = on_demand_analytics ? addFormatType(Object.values(on_demand_analytics), "on_demand_analytics") : [];
                const modifiedPreDefinedQuery = predefined_query ? addFormatType(Object.values(predefined_query), "predefined_query") : [];
                const modifiedOperationalReport = operational_report ? addFormatType(Object.values(operational_report), "operational_report") : [];

                const mergedObjects = [...modifiedAnalytics, ...modifiedOnDemandQuery, ...modifiedDashboard, ...modifiedPreDefinedQuery, ...modifiedOnDemandAnalytics, ...modifiedOperationalReport];

                setAttributeData(mergedObjects);
            }
            else {
                setAttributeData([]);
            }
        }
    }, [props.analysisData]);


    function handleReportingCategory(e) {
        setReportingCategory(e.target.value)
        props.getReportOnRollData(e.target.value);
        sessionStorage.setItem('set-reporting-category', e.target.value)
    }

    const handleBoxClick = (value) => {
        if (value === "Postings Summary by Contract Groupings") {
            history.push("/analytics");
        } else if (value == "Monthly Accrual vs Payment") {
            history.push("/analytics-comparison");
        } else if (value == "Monthly Gross to Net") {
            history.push("/sales-payment-comparison");
        } else if (value === "Incentive Ratio by Attribute") {
            history.push("/geographical-chart-us");
        } else if (value === "Top Incentives by Attribute") {
            history.push("/top25");
        } else if (value === "Postings Variance by Attribute") {
            history.push("/rebate-trends");
        } else if (value === "Postings Variance by Contract Groupings") {
            history.push("/variance-combined");
        } else if (value === "Trending by Contract Number") {
            history.push("/incentive-trends");
        } else if (value === "New Master Data Incentives") {
            history.push("/new-master-data-incentives");
        } else if (value === "Incentive Stats") {
            history.push("/incentive-stats");
        } else if (value === "Contract Stats") {
            history.push("/contract-stats");
        } else if (value === "Monthly Active Contracts") {
            history.push("/monthly-active-contracts");
        } else {
            history.push("#");
        }
    };
    const handleBoxClickDashBoard = (value) => {
        if (value === "Postings Summary Analysis") {
            history.push("/posting-summary-analysis");
        } else if (value === "Summarized Dashboard") {
            history.push("/rebate-analytics");
        } else if (value === "Postings Detail Analysis") {
            history.push("/posting-detail-analysis");
        } else if (value === "Default Dashboard") {
            history.push("/dashboard");
        }
        else {
            history.push("#");
        }
    };

    function runOnDemandAnalyticsQuery(item) {
        props.getDynamicQuery(item.ID);
        sessionStorage.removeItem("dyamicQueryDownloadData");
        var data = {
            id: item.ID.toString(),
        };
        if (item.filter_fields.length > 0) {
            var object = {
                id: item.ID,
                fields: item.filter_fields,
                sort_fields: [],
                data_source_type: item.data_source_type,
            };
            sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
            history.push({
                pathname: "/on-demand-analytics/on-demand-analytics-form",
                filterValues: item.filter_fields,
            });
        } else {
            var object = {
                id: item.ID,
                fields: [],
                sort_fields: item.sort_fields,
                data_source_type: item.data_source_type,
            };
            sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
            props.runDynamicQuery(data, item.ID, 'dynamicAnalytics');
        }
    }

    function runOperationalReport(url, type, item) {
        sessionStorage.setItem('operationalReportsSourceDataType', item.sourceDataType);
        if (type === 'API') {
            sessionStorage.removeItem('dyamicQueryDownloadData')
            var data = {
                id: item.allItem.id.toString()
            };
            if (item.allItem && item.allItem.filter_fields && item.allItem.filter_fields.length > 0) {
                var object = { id: item.allItem.id, fields: item.allItem.filter_fields }
                sessionStorage.setItem('dynamic_filter_fields', JSON.stringify(object));
                history.push({ pathname: '/dynamic-query/dynamic-query-form', filterValues: item.allItem.filter_fields });
            } else {
                props.runDynamicQuery(data, item.allItem.id);
            }
        } else {
            history.push(url);
        }
    }

    function runPredefinedQuerys(item, type) {
        sessionStorage.setItem('mode', type)
        sessionStorage.setItem('current-id', item.ID)
        if (item.filters == null) {
            handlePreDefineSubmit()
        } else {
            setOpen(true)
        }
        // setCurrentId(item.ID)
        if (item.filters !== null) {
            setPreDefineNameArray(item.filters)
        }
        setValue([])
    }
    function handlePreDefineSubmit() {
        var json = {}
        setOpen(false);
        preDefineNameArray.map((item, index) => {
            var [one, two, three] = preDefineNameArray[index].split(" ")
            if (item.includes('IN')) {
                json[three] = [value[index]]
            }
            else if (item.includes('BETWEEN')) {
                if (!value[index].includes('and')) {
                    toast.error('Please Enter date like this 01/01/2020 and 12/31/2020');
                } else {
                    var [value1, value2] = value[index].split('and')
                    json[three] = value1.trim()
                    json['$' + (index + 2)] = value2.trim()
                }
            }
            else {
                json[three] = value[index]
            }
        })
        if (sessionStorage.getItem('mode') === 'Excel') {
            props.getExcelPredefinedFileDownlaod(json, sessionStorage.getItem('current-id'));
        }
        else if (sessionStorage.getItem('mode') === 'Email') {
            props.getExcelPredefinedFileDownlaod(json, sessionStorage.getItem('current-id'), 'Email');
        }
        else {
            props.runPredefinedQuery(json, sessionStorage.getItem('current-id'));
        }
    }
    function runOnDemandQuery(item, type) {
        if (type === "sf") {
            sessionStorage.setItem("SFDQ", true);
        }
        if ((type == 'email' || type == 'download') && item.filter_fields.length == 0) {
            if (type == 'email') {
                setOpenConfirmEmail(true)
                setSelectedQuery(item)
            }
            else
                handleDownloadExcel(item.ID, type)
        }
        else if (currentPage === "partner-statement") {
            props.getDynamicQuery(item.ID);
            sessionStorage.removeItem("dyamicQueryDownloadData");
            var data = {
                id: item.ID.toString(),
            };
            if (item.filter_fields.length > 0) {
                var object = {
                    id: item.ID,
                    fields: item.filter_fields,
                    sort_fields: item.sort_fields,
                    data_source_type: item.data_source_type,
                };
                sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
                history.push({
                    pathname: "/partner-statement/partner-statement-form",
                    filterValues: item.filter_fields,

                });
            } else {
                var object = {
                    id: item.ID,
                    fields: [],
                    sort_fields: item.sort_fields,
                    data_source_type: item.data_source_type,
                };
                sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
                props.runDynamicQueryPS(data, item.ID);
            }
        }
        else if (currentPage === "collaboration-portal") {
            props.getDynamicQuery(item.ID);
            sessionStorage.removeItem("dyamicQueryDownloadData");
            var data = {
                id: item.ID.toString(),
            };
            if (item.filter_fields.length > 0) {
                var object = {
                    id: item.ID,
                    fields: item.filter_fields,
                    sort_fields: item.sort_fields,
                    data_source_type: item.data_source_type,
                };
                sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
                history.push({
                    pathname: "/collaboration-portal/collaboration-portal-form",
                    filterValues: item.filter_fields,
                });
            } else {
                var object = {
                    id: item.ID,
                    fields: [],
                    sort_fields: item.sort_fields,
                    data_source_type: item.data_source_type,
                };
                sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
                props.runDynamicQueryCP(data, item.ID);
            }
        } else {
            sessionStorage.setItem("SFDQ", true);
            props.getDynamicQuery(item.ID);
            sessionStorage.removeItem("dyamicQueryDownloadData");
            var data = {
                id: item.ID.toString(),
            };
            if (item.filter_fields.length > 0) {
                var object = {
                    id: item.ID,
                    fields: item.filter_fields,
                    sort_fields: item.sort_fields,
                    data_source_type: item.data_source_type,
                };
                sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
                history.push({
                    pathname: "/dynamic-query/dynamic-query-form",
                    filterValues: item.filter_fields,
                    type: type == 'sf' ? 'sync-data' : type
                });
            } else {
                if (type != 'download' && type != 'email') {
                    var object = {
                        id: item.ID,
                        fields: [],
                        sort_fields: item.sort_fields,
                        data_source_type: item.data_source_type,
                    };
                    sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
                    props.runDynamicQuery(data, item.ID);
                }

            }
        }
    }

    function handleDownloadExcel(id, type) {
        setOpenConfirmEmail(false)
        var data = {
            "id": id.toString(),
        }
        props.downloadDynamicQuery(data, type, emailId, currentPage);
    }

    const columns = [
        {
            field: 'format_type',
            title: 'Report Type',
            type: 'string',
            render: (item) => (
                <div
                    style={{ cursor: 'pointer' }}
                >
                    {item.format_type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </div>
            ),
        },
        {
            field: "application_type",
            title: "Data Source",
            editable: 'never',
            type: 'string',
            render: (item) => (
                <div
                    style={{ cursor: 'pointer' }}
                >
                    {item.format_type === 'analytics' ? " " :
                        item.format_type === 'dashboard' ? " " :
                            item.format_type === 'predefined_query' ? " " :
                                item.format_type === 'operational_report' ? item.functionality :
                                    item.format_type === 'on_demand_analytics' ? item.table_description :
                                        item.table_description
                    }
                </div>
            ),
        },
        {
            field: 'report_name',
            title: 'Report Name',
            type: 'string',
            render: (item) => (
                <div
                    style={{ cursor: 'pointer' }}
                >
                    {item.format_type === 'analytics' ?
                        item.analytic_name :
                        item.format_type === 'dashboard' ? item.dashboard_name :
                            item.format_type === 'predefined_query' ? item.Name :
                                item.format_type === 'operational_report' ? item.report_name :
                                    item.format_type === 'on_demand_analytics' ? item.format_name :
                                        item.format_name
                    }
                </div>
            ),
        },
        {
            field: 'reporting_category',
            title: 'Report Description',
            type: 'string',
            render: (item) => (
                <div
                    style={{ cursor: 'pointer' }}
                >
                    {item.format_type === 'analytics' ?
                        item.analytic_description :
                        item.format_type === 'dashboard' ? item.dashboard_description.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') :
                            item.format_type === 'predefined_query' ? item.Description :
                                item.format_type === 'operational_report' ? item.report_description :
                                    item.format_type === 'on_demand_analytics' ? item.query_description :
                                        item.query_description
                    }
                </div>
            ),
        },
        {
            field: 'action',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {rowData.format_type === 'analytics' ? (
                            <Tooltip title='Run'>
                                <IconButton
                                    classes={{ root: classes.IconButton }}
                                    onClick={() => handleBoxClick(rowData.analytic_name)}
                                    size="large">
                                    <DirectionsRun color="disabled" style={{ fontSize: 20 }} />
                                </IconButton>
                            </Tooltip>
                        ) : rowData.format_type === 'dashboard' ? (
                            <Tooltip title='Run'>
                                <IconButton
                                    classes={{ root: classes.IconButton }}
                                    onClick={() => handleBoxClickDashBoard(rowData.dashboard_name)}
                                    size="large">
                                    <DirectionsRun color="disabled" style={{ fontSize: 20 }} />
                                </IconButton>
                            </Tooltip>
                        ) : rowData.format_type === 'on_demand_analytics' ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title='Run'>
                                    <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runOnDemandAnalyticsQuery(rowData)}
                                        size="large">
                                        <DirectionsRun color="disabled" style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : rowData.format_type === 'operational_report' ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title='Run'>
                                    <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runOperationalReport(rowData.url, rowData.type, rowData)}
                                        size="large">
                                        <DirectionsRun color="disabled" style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : rowData.format_type === 'predefined_query' ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title='Run'>
                                    <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runPredefinedQuerys(rowData)}
                                        size="large">
                                        <DirectionsRun color="disabled" style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip title='Downlaod Excel'>
                                    <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runPredefinedQuerys(rowData, 'Excel')}
                                        style={{ marginLeft: 5 }}
                                        size="large">
                                        <FileDownload color="disabled" style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Email'>
                                    <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runPredefinedQuerys(rowData, 'Email')}
                                        style={{ marginLeft: 5 }}
                                        size="large">
                                        <Email color="disabled" style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip> */}
                            </div>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title='Run - Show Pages'>
                                    <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runOnDemandQuery(rowData, 'table-data')}
                                        size="large">
                                        <DirectionsRun color="disabled" style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Run - Show All">
                                    <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runOnDemandQuery(rowData, "sf")}
                                        style={{
                                            marginLeft: 5, fontSize: 14,
                                        }}
                                        size="large">
                                        <FlightTakeoff color="disabled" style={{ fontSize: 20 }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <div
                                    style={{
                                        marginLeft: 5,
                                        borderLeft: "1px solid lightgray",
                                    }}
                                >
                                    <Tooltip title="Excel Download">
                                        <IconButton
                                            classes={{ root: classes.IconButton }}
                                            onClick={() => runOnDemandQuery(rowData, 'download')}
                                            size="large">
                                            <GetApp color="disabled" style={{ fontSize: 20 }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Email">
                                        <IconButton
                                            classes={{ root: classes.IconButton }}
                                            onClick={() => runOnDemandQuery(rowData, 'email')}
                                            size="large">
                                            <Email color="disabled" style={{ fontSize: 20 }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }

    ];

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Process Reporting  </Typography>

                </div>
                <div style={{
                    display: 'flex', flexDirection: 'column', marginBottom: 5, marginTop: 26, justifyContent: "center", height: 80,
                    // border: '2px solid #E8E8E8'
                }}>
                    <div style={{ width: "40%" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" style={{ marginTop: -10, }}>Reporting Category</InputLabel>
                            <Select
                                value={reportingCategory}
                                onChange={handleReportingCategory}
                                // displayEmpty
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Reporting Category"
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {reportingCategoryArray &&
                                    reportingCategoryArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ marginTop: -10 }}>
                    {attributeData && attributeData.length > 0 ?
                        <Table columns={columns} dataRows={attributeData} />
                        :
                        <div>
                            There is no data to be shown
                        </div>
                    }
                    {/* <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} /> */}
                </div>
            </LoadingOverlay>
            <Dialog open={openConfirmEmail}>
                <DialogContent
                    classes={{
                        root: classes.dialogContent,
                    }}
                >
                    <Typography variant="h4">
                        <DialogContentText>
                            <FormLabel classes={{ root: classes.fontSetting }}>Email Id</FormLabel>
                            <OutlinedInput
                                classes={{ root: classes.inputTwoLine }}
                                type={'email'}
                                value={emailId}
                                onChange={(e) => setEmailId(e.target.value)}
                            />
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialog,
                    }}
                >
                    <Button
                        onClick={() => setOpenConfirmEmail(false)}
                        autoFocus
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDownloadExcel(selectedQuery.ID, 'email')}
                        color="primary"
                        autoFocus
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >

    );
};
const mapStateToProps = state => {
    return {
        // analysisData: state.addMultipleConfigurationData.reportypedataAll,
        analysisData: state.addMultipleConfigurationData.reportonrolldataAll,
        claimData: state.addMultipleConfigurationData.configdesigner,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getReportTypeData: () => dispatch(getReportTypeData()),
        getReportOnRollData: (type) => dispatch(getReportOnRollData(type)),
        // deleteReportTypeData: (id) => dispatch(deleteReportTypeData(id, "ReportingByFunction"))
        getClaimData: () => dispatch(getConfigurationDesigner()),
        runDynamicQuery: (data, id, type) => dispatch(runDynamicQuery(data, id, type)),
        getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
        getExcelPredefinedFileDownlaod: (data, id, type) => dispatch(getExcelPredefinedFileDownlaod(data, id, type)),
        runPredefinedQuery: (data, id) => dispatch(runPredefinedQuery(data, id)),
        runDynamicQueryCP: (data, id) =>
            dispatch(runDynamicQuery(data, id, null, "collaboration")),
        downloadDynamicQuery: (data, type, email, page) => dispatch(downloadDynamicQuery(data, type, email, page))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDesignerMain);