import React, { useEffect } from 'react';
import {
    Button,
    Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Stickers, GridMain } from './components';
import { useHistory, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { createPromotionSetup, getCountryData, getPromotionMaterialData, getMaterialFilters, getModels, getBudgetingConfig, runCostingSimulation, createPromotionScenario, getGroupedCustomGroups } from '../../../redux/actions';
import MarketDefinition from './components/MarketDefinition/MarketDefinition';
import secureLocalStorage from "react-secure-storage";
import ScenarioSetting from './ScenarioSetting';
import { ReviewScenario } from '../ReviewScenario';
import { color } from 'd3';
import { ConfirmPrompt } from '../../../components/Containers';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        height: '138vh'
        //height: '100%'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },

    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },

    pageControls: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },

}));
const tabs = {
    ScenarioSetting: 0,
    Stickers: 1,
    Region: 2,
};

function getSteps() {
    return ['ScenarioSetting', 'Stickers', 'Market', 'Grid', 'Review'];
}

const PromotionSetup = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const classes = useStyles();
    var mode = location.data && location.data.page ? location.data.page : 'add';
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [completed, setCompleted] = React.useState(new Set());
    const [promoData, setPromoData] = React.useState([])
    const [promoStickerData, setPromoStickerData] = React.useState([])
    const [prefillData, setPrefillData] = React.useState([])
    const [prefillId, setPrefillId] = React.useState('');
    const [version, setVersion] = React.useState('');
    const [isSaved, setIsSaved] = React.useState(false);
    const [country, setCountry] = React.useState([]);
    const skipped = new Set()
    const [ID, setID] = React.useState(0)
    const [sticker, setSticker] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [defineMarket, setDefineMarket] = React.useState({});
    const [colorPicker, setColorPicker] = React.useState({});
    const [locations, setLocations] = React.useState([]);
    const [promoLoc, setPromoLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [refresh, setRefresh] = React.useState(0);
    const [promoModelFilter, setPromoModelFilter] = React.useState({});
    const [models, setModels] = React.useState([]);
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    const [promoModelYearArray, setPromoModelYearArray] = React.useState([]);
    const [customGroupData, setCustomGroupData] = React.useState({});
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [flag, setFlag] = React.useState(true);
    const [stickerKeys, setStickerKeys] = React.useState({});
    const [calCount, setCalCount] = React.useState(0);
    const [type, setType] = React.useState('');


    useEffect(() => {
        props.getFilters()
        var location = {
            table_name: 'territory'
        }
        props.getFilterData('territory', location)
        sessionStorage.setItem("functionality-name", 'promotion_scenario')
    }, [])

    // useEffect(() => {
    //     // Prevent browser's default back/forward navigation when the page loads
    //     window.history.pushState(null, null, window.location.href);

    //     const handlePopState = (event) => {
    //         // Prevent back/forward navigation by re-pushing the current state
    //         window.history.pushState(null, null, window.location.href);
    //     };

    //     window.addEventListener('popstate', handlePopState);

    //     return () => {
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, []);

    useEffect(() => {
        var loc = promoData.promo_location?.location ? Object.keys(promoData.promo_location.location) : []
        if (promoData.promotion_type && promoData.product_lines && promoData.material_group1 && loc && !isLastStep()) {
            var data = {
                "promotion_type": promoData.promotion_type,
                "product_lines": promoData.product_lines,
                'model_year': promoData.material_group1,
                'location': loc
            }
            props.getCustomGroups(data)
        }
    }, [promoData && promoData.promotion_type && promoData.product_lines && promoData.material_group1 && promoData.promo_location])


    useEffect(() => {
        if (props.locationData)
            setLocations(props.locationData)
    }, [props.locationData])

    useEffect(() => {
        if (props.filterData) {
            setFilters(props.filterData)
        }
    }, [props.filterData])
    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setPromoModelYearArray(props.filterData['material_master'][0]['material_group1'])
            setPromoDivisionArray(props.filterData['material_master'][0]['material_group'])
        }
    }, [props.filterData]);
    useEffect(() => {
        if (promoData && promoData.material_group1 && promoData.material_group1.length > 0 && promoData.product_lines && !isLastStep()) {
            var model = {
                table_name: 'material_master',
                filter: {
                    material_group1: promoData.material_group1,
                    material_group: promoData.product_lines
                }
            }
            props.getFilterData('material_master', model)
        }
    }, [promoData && promoData.material_group1])
    useEffect(() => {
        if (props.modelData && props.modelData.length > 0) {

            setModels(props.modelData)
        }
    }, [props.modelData]);
    useEffect(() => {
        if (props.customGroups) {

            setCustomGroupData(props.customGroups)
        }
    }, [props.customGroups]);

    useEffect(() => {
        if (activeStep == 1)
            dispatch({ type: 'SAVED_SCENARIO', data: scenarioData });
    }, [activeStep])
    const totalSteps = () => {
        return getSteps().length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleNext = () => {
        const newActiveStep = activeStep + 1
        setActiveStep(newActiveStep);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handlePrefill = (id) => {
        setPrefillId(id);
        setFlag(true)
        if (id)
            props.fetchScenario(id, 'prefill')
    };
    function handleCheckedLoc(selected) {
        setSelectedLocItems(selected)
        const array2 = [];
        selected && selected.forEach(obj => {
            const { country, region, state } = obj;
            const existingCategoryA = array2.find(item => item.category === region);
            if (existingCategoryA) {
                existingCategoryA.children.push({ category: state, type: 'state', relationship: { country: country, region: region, state: state }, children: [] });

            } else {
                array2.push({
                    category: region, type: 'region',
                    relationship: { country: country, region: region },
                    children: [{
                        category: state, type: 'state',
                        relationship: { country: country, region: region, state: state },
                        children: []
                    }]
                });
            }
        });
        setPromoLoc([...array2])
    };
    function handleGeneralDataChange(values, model) {
        setPromoData(values);
        if (values.country)
            setCountry(values.country)
        handleNext()
        if (model)
            handleCheckedLoc(Object.values(model))
    };
    function handlePromoStickers(selected, item, keyData) {
        setPromoStickerData(selected)
        if (keyData)
            setStickerKeys(keyData)
        if (item) {
            setSticker(item)
            handleNext()
        }
    }
    function handleDefine(value, page, stickerId) {
        if (value) {

            setDefineMarket(prev => ({
                ...prev, ...value.grid_data
            }))
            setPromoModelFilter(prev => ({
                ...prev, ...value.display_data
            }))
        }
        else {
            delete defineMarket[stickerId]
            delete promoModelFilter[stickerId]
            setDefineMarket({ ...defineMarket })
            setPromoModelFilter({ ...promoModelFilter })
        }
        if (page != 'grid' && page != 'save')
            handleNext()
    }
    useEffect(() => {
        if (defineMarket && Object.entries(defineMarket).length > 0 && promoData.promotion_start_date && promoData.promotion_end_date &&
            stickerKeys && Object.keys(stickerKeys).length > 0 &&
            promoData.promo_location && promoData.material_group1 && promoData.country && promoData.promotion_currency && promoData.product_lines && !isLastStep()) {
            var data = {
                "promotion_start_date": promoData.promotion_start_date,
                "promotion_end_date": promoData.promotion_end_date,
                // "defined_stickers": defineMarket ? Object.keys(defineMarket) && Object.keys(defineMarket).map(Number) : [],
                "promotion_grids": defineMarket,
                "promo_location": promoData.promo_location,
                "material_group1": promoData.material_group1,
                "country": promoData.country,
                "promotion_currency": promoData.promotion_currency,
                "product_lines": promoData.product_lines,
                "selected_stickers": stickerKeys,
            }
            props.runSimulation(data)
            setCalCount(calCount + 1)
        }
    }, [defineMarket, refresh, promoData.promotion_start_date, promoData.promotion_end_date, promoData.material_group1])
    var scenarioData = {
        ...promoData,
        "country": promoData.country,
        "selected_stickers": stickerKeys,
        "promotion_grids": defineMarket,
        "promotion_display": promoModelFilter
    }
    function handleSubmit(type) {
        setIsSaved(true)
        setType(type)
        if (ID) {
            var data = {
                ID: ID,
                ...scenarioData,

                version: version
            }
            if (calCount > 1)
                data['is_costing'] = true
            props.onUpdate(data, ID, 'edit', type)
            // if (type == 'review')
            //     handleNext()
        }

    }
    function handleGrid(type) {
        if (type == 'refresh')
            setRefresh(refresh + 1)
        else if (type == 'return')
            handleBack()
        else if (type == 'review')
            handleSubmit(type)
        else
            handleSubmit()
    };
    useEffect(() => {
        console.log("test")
        if (props.savedData && (mode == 'Scenario' || isSaved)) {
            if (props.savedData.ID) {
                setID(props.savedData.ID)
                setVersion(props.savedData.version)
            }
            setDefineMarket(props.savedData.promotion_grids)
            setPromoModelFilter(props.savedData.promotion_display)
            setPrefillData(props.savedData)
            setPromoData(props.savedData)
            setCountry(props.savedData.country)
            setStickerKeys(props.savedData.selected_stickers)
            handleCheckedLoc(props.savedData.promo_location?.location && Object.values(props.savedData.promo_location.location))
            if (props.savedData.selected_stickers) {
                getRandomColor(props.savedData.selected_stickers)
            }
            if (mode == 'Scenario' && !isSaved) {
                setActiveStep(1)
                mode = ''
            }
            else
                mode = 'edit'
            setIsSaved(false)
            if (type == 'review')
                handleNext()
        }
        else if (props.scenario && flag && (prefillId || mode == 'edit')) {

            if (mode == 'edit') {
                setID(props.scenario.ID)
                setVersion(props.scenario.version)
            }
            setDefineMarket(props.scenario.promotion_grids)
            setPromoModelFilter(props.scenario.promotion_display)
            setPrefillData(props.scenario)
            setPromoData(props.scenario)
            setCountry(props.scenario.country)
            setStickerKeys(props.scenario.selected_stickers)
            handleCheckedLoc(props.scenario.promo_location?.location && Object.values(props.scenario.promo_location.location))
            if (props.scenario.selected_stickers) {
                getRandomColor(props.scenario.selected_stickers)
            }
            if (mode == 'Scenario') {
                setActiveStep(1)
                mode = ''
            }
            setFlag(false)
        }

    }, [props.scenario, props.savedData])

    function getRandomColor(stickers) {
        const letters = '0123456789ABCDEF';
        var temp = { ...colorPicker }
        stickers && Object.entries(stickers).filter(([key, value]) => !(colorPicker && colorPicker[value.name])).map(([key, value]) => {
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            temp = { ...temp, [value.name]: color }
        }
        )
        setColorPicker(temp)

    }
    const handleColorPicker = (value) => {
        setColorPicker(value)
    }
    const skippedSteps = () => {
        return skipped.size;
    };
    const completedSteps = () => {
        return completed.size;
    };
    function getStepContent(step) {
        const allSteps = [
            <ScenarioSetting onChange={handleGeneralDataChange}
                locations={locations} scenario={promoData}
                handlePrefill={handlePrefill} mode='edit'
            />,
            <Stickers onChange={handlePromoStickers}
                //  promoData={promotion}
                country={country}
                productLine={promoData.product_lines}
                handleBack={handleBack}
                handleColor={handleColorPicker}
                colorPicker={colorPicker}
                filterData={filters} // from materal master unique values eh: material_group and material_group1
                promoProductLines={promoDivisionArray}
                prefillData={prefillData}
                mode={mode}
                handleSubmit={handleSubmit}
            />,
            <MarketDefinition
                sticker={sticker}
                onChange={handleDefine}
                filters={filters}
                stickers={promoStickerData}
                country={country}
                handleBack={handleBack}
                colorPicker={colorPicker}
                locations={promoLoc}
                modelYears={promoData.material_group1}
                modelData={models}
                defineMarketChild={promoModelFilter}
                defineMarket={defineMarket}
                productLines={promoData.product_lines}
                selectedCountry={selectedCountry}
                customGroup={customGroupData}
            />,
            <GridMain //data={filters} 
                country={promoData.country}
                definedData={defineMarket}
                stickers={promoStickerData}
                colorPicker={colorPicker}
                locations={promoLoc}
                modelYears={promoData.material_group1}
                modelData={models}
                gridFilter={promoModelFilter}
                promoProductLines={promoDivisionArray}
                currency={promoData.promotion_currency}
                handleGrid={handleGrid}
                handleDefine={handleDefine}
                productLine={promoData.product_lines}
                customGroup={customGroupData}
            />,
            ...((isLastStep() && ID && type == 'review') ? [<ReviewScenario productLine={promoData.product_lines}
                promoProductLines={promoDivisionArray}
                region={promoLoc}
                promoData={promoData}
                definedData={defineMarket}
                handleGrid={handleGrid}
                colorPicker={colorPicker}
                modelYears={promoData.material_group1}
                stickers={promoStickerData}
                scenarioID={ID}
            />] : [])

        ];

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }



    return (
        <div>
            <div className={classes.root}>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        locationData: state.promotionData.locationFilters,
        modelData: state.promotionData.modelFilters,
        filterData: state.promotionData.promoFilters,
        scenario: state.promotionData.scenarioData,
        savedData: state.promotionData.savedScenario,
        customGroups: state.promotionData.groupedCustom
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id, type) => dispatch(createPromotionScenario(data, id, type)),
        onUpdate: (data, id, type, page) => dispatch(createPromotionScenario(data, id, type, page)),
        getFilters: () => dispatch(getMaterialFilters()),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter')),
        runSimulation: (data) => dispatch(runCostingSimulation(data)),
        fetchScenario: (id, page) => dispatch(createPromotionScenario(null, id, 'fetch_id', page)),
        getCustomGroups: (data) => dispatch(getGroupedCustomGroups(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSetup);