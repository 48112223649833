import React, { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Grid, Paper, AppBar, Toolbar, Button, Switch
} from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import PollIcon from '@mui/icons-material/Poll';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import CardTravelIcon from '@mui/icons-material/CardTravelOutlined';
import HomeWorkIcon from '@mui/icons-material/HomeWorkOutlined';
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import { history } from '../../components/Helpers';
import ExitToAppIcon from '@mui/icons-material/ExitToAppOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { connect } from 'react-redux';
import { onLoadingSingleUser, getAccessData, getProfile, currentApplication } from '../../redux/actions';
import PricingIcon from '@mui/icons-material/AttachMoney';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import secureLocalStorage from "react-secure-storage";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: theme.palette.gradient.appLauncher
    },
    innerContainer: {
        [theme.breakpoints.down('xl')]: {
            minWidth: 0,
            marginTop: 10,
            marginRight: 15,
            fontSize: 10
        }
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 84,
        width: 150,
        justifyContent: 'center',
        "&:hover": {
            transform: 'scale(1.1)',
            transition: '0.6s',
            cursor: 'pointer',
            animationDuration: '4s',
            animationDelay: '2s'
        },
        color: 'white',
        margin: '8px 16px 8px 8px',
        borderRadius: 9,

        backgroundColor: theme.palette.appLauncher.paper
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
    mgBottom10: {
        marginBottom: 10,
        color: '#A9B3E4'
    },
    h4: {
        marginTop: 30,
        paddingLeft: 15
    },
    tabStyle: {
        backgroundColor: theme.palette.appLauncher.tabStyle,
        padding: 12,
        borderRadius: '10px 10px 0 0',
        color: theme.palette.appLauncher.tabStyleFont,
        width: 170,
        fontSize: '0.76rem'
    },
    tabContainer: {
        padding: 10,
        borderRadius: '8px 8px 8px 8px',
        marginTop: 10,
    },
    button: {
        position: 'absolute',
        top: 10, right: 10,
        backgroundColor: theme.palette.primary.main
    },
    switchTheme: {
        color: 'black'
    },
    tabBackground: {
        display: 'flex',
        flexDirection: 'row',
        backgroundImage: theme.palette.appLauncher.tabBackground,
        borderRadius: '0px 20px 20px 20px',
        padding: 15
    }
}));
const triggerLogout = () => {
    localStorage.setItem('loggedIn', false)

    localStorage.clear();
    secureLocalStorage.clear();
    history.push('/login');
}
const AppLauncher = (props) => {
    const classes = useStyles();

    const handleBoxClick = (value) => {
        props.currentApplication(value)
        sessionStorage.setItem("application", value);
        if (value === 'Designer Studio') {
            history.push('default-page');
        } else if (value == 'Master Data') {
            history.push('default-page');
        }
        else if (value == 'Transaction Data') {
            history.push('default-page');
        }
        else if (value === 'Security Cockpit') {
            if (userDetails.role.name === 'admin') {
                history.push('organisation-setup');
            } else {
                history.push('default-page');
            }
        }
        else if (value === 'Integration Studio') {
            history.push('default-page');
        }
        else if (value === 'Collaboration Portal') {
            history.push('default-page');
        }
        else if (value === 'Analytics') {
            history.push('default-page');
        }
        else if (value === 'Audit Lab') {
            history.push('default-page');
        }
        else if (value === 'Pricing') {
            history.push('pricing-dashboard');
        }
        else if (value === 'Promotions') {
            history.push('promo-plan-analytics');
        }
        else {
            history.push('dashboard');
        }
    }
    const [userDetails, setUserDetails] = React.useState(secureLocalStorage.getItem("dYtz"));
    useEffect(() => {
        props.getAccessData(userDetails.role['name']);
        props.getProfile();
    }, [])

    useEffect(() => {
        if (props.userRoleSingle && props.userRoleSingle.role) {
            props.getAccessData(props.userRoleSingle.role);
        }
    }, [props.userRoleSingle])

    useEffect(() => {
        if (props.userRoleSingle) {
        }
    }, [props.userRoleSingle])

    useEffect(() => {
        if (props.userData) {
        }
    }, [props.userData])

    return (
        <div className={classes.root}>
            <div className={classes.innerContainer}>
                <Button
                    variant="contained"
                    className={classes.button}
                    size="small"
                    onClick={triggerLogout}
                    style={{
                        fontSize: '0.74rem', padding: 10,
                        borderRadius: 10, color: 'white', boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
                    }}
                >
                    <PowerSettingsNewIcon />
                </Button>
                {userDetails.role.name != 'admin' &&
                    userDetails.role != 'external' &&
                    (userDetails.role.authorized_apps.includes('Customer Rebate') ||
                        userDetails.role.authorized_apps.includes('Customer Chargeback') ||
                        userDetails.role.authorized_apps.includes('Royalty') ||
                        userDetails.role.authorized_apps.includes('Promotions') ||
                        userDetails.role.authorized_apps.includes('Supplier Rebate') ||
                        userDetails.role.authorized_apps.includes('Supplier Chargeback') ||
                        userDetails.role.authorized_apps.includes('Sales Commission') ||
                        userDetails.role.authorized_apps.includes('Pricing') ||
                        userDetails.role.authorized_apps.includes('Profit Optimization')
                    )
                    &&
                    <div className={classes.tabContainer}>
                        <div className={classes.tabStyle}>
                            Functional Apps
                        </div>
                        <div className={classes.tabBackground}>
                            <div style={{ width: '75%' }}>
                                <div style={{ width: 'auto', flexDirection: 'row', display: 'flex' }}>
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Customer Rebate') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Customer Rebate')}>
                                            <Paper className={classes.paper}

                                            >
                                                <RecordVoiceOverIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Customer Rebate</div>
                                            </Paper>
                                        </div>

                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Customer Chargeback') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Customer Chargeback')}>
                                            <Paper className={classes.paper}
                                            >
                                                <BusinessCenterOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Customer Chargeback </div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Royalty') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Royalty')}>
                                            <Paper className={classes.paper}
                                            >
                                                <MonetizationOnOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Incoming Royalty</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Sales Commission') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Sales Commission')}>
                                            <Paper className={classes.paper}
                                            >
                                                <AccountBalanceWalletOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}>  Sales Commission </div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Pricing') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Pricing')}>
                                            <Paper className={classes.paper}
                                            >
                                                <PricingIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}>  Pricing</div>
                                            </Paper>
                                        </div>
                                    }
                                </div>
                                <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>

                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Supplier Rebate') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Supplier Rebate')}>
                                            <Paper className={classes.paper}
                                            >
                                                <GroupIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Supplier Rebate </div>
                                            </Paper>
                                        </div>
                                    }

                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Supplier Chargeback') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Supplier Chargeback')}>
                                            <Paper className={classes.paper}
                                            >
                                                <CardTravelIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Supplier Chargeback </div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Royalty') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Royalty')}>
                                            <Paper className={classes.paper}
                                            >
                                                <MonetizationOnIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Outgoing Royalty</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Promotions') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Promotions')}>
                                            <Paper className={classes.paper}
                                            >
                                                <HomeWorkIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}>  Promotions</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Profit Optimization') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Profit Optimization')}>
                                            <Paper className={classes.paper}>
                                                <CurrencyExchangeIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}>  Profit Optimization</div>
                                            </Paper>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {userDetails.role.name != 'admin' && userDetails.role.authorized_apps &&
                    (userDetails.role.authorized_apps.includes('Master Data') ||
                        userDetails.role.authorized_apps.includes('Transaction Data') ||
                        userDetails.role.authorized_apps.includes('Analytics') ||
                        userDetails.role.authorized_apps.includes('Collaboration Portal'))
                    &&
                    < div className={classes.tabContainer}>
                        <div className={classes.tabStyle}>
                            Cross Functional Apps
                        </div>
                        <div className={classes.tabBackground}>
                            <div style={{ width: '75%' }}>
                                <div style={{ width: 'auto', flexDirection: 'row', display: 'flex' }}>
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Master Data') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Master Data')}>
                                            <Paper className={classes.paper}
                                            >
                                                <SupervisedUserCircleOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}>Master Data</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Transaction Data') &&
                                        <div item xs={12} md={3} onClick={() => handleBoxClick('Transaction Data')}>
                                            <Paper className={classes.paper}
                                            >
                                                <AccountBalanceOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Transaction Data</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Analytics') &&
                                        <div item xs={12} md={3} onClick={() => handleBoxClick('Analytics')}>
                                            <Paper className={classes.paper}
                                            >
                                                <PollIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Analytics</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role && userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Collaboration Portal') &&
                                        <div item xs={12} md={3} onClick={() => handleBoxClick('Collaboration Portal')}>
                                            <Paper className={classes.paper}
                                            >
                                                <AssessmentIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Collaboration Portal</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role && userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Audit Lab') &&
                                        <div item xs={12} md={3} onClick={() => handleBoxClick('Audit Lab')}>
                                            <Paper className={classes.paper}
                                            >
                                                <VerifiedUserIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Audit Lab</div>
                                            </Paper>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {userDetails.role.name != 'admin' &&
                    (
                        userDetails.role.authorized_apps.includes('Designer Studio') ||
                        userDetails.role.authorized_apps.includes('Security Cockpit') ||
                        userDetails.role.authorized_apps.includes('Integration Studio')
                    )
                    &&
                    <div className={classes.tabContainer}>
                        <div className={classes.tabStyle}>
                            Technical Apps
                        </div>
                        <div className={classes.tabBackground}>
                            <div style={{ width: '75%' }}>
                                <div style={{ width: 'auto', flexDirection: 'row', display: 'flex' }}>
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Designer Studio') &&
                                        <div item xs={12} md={3} onClick={() => handleBoxClick('Designer Studio')}>
                                            <Paper className={classes.paper}
                                            >
                                                <ColorLensOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}>Designer Studio</div>
                                            </Paper>
                                        </div>
                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Security Cockpit') &&
                                        < div item xs={12} md={3} onClick={() => handleBoxClick('Security Cockpit')}>
                                            <Paper className={classes.paper}
                                            >
                                                <SecurityOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Security Cockpit</div>
                                            </Paper>
                                        </div>

                                    }
                                    {userDetails.role.authorized_apps && userDetails.role.authorized_apps.includes('Integration Studio') &&
                                        <div item xs={12} md={3} onClick={() => handleBoxClick('Integration Studio')}>
                                            <Paper className={classes.paper}
                                            >
                                                <MemoryOutlinedIcon className={classes.mgBottom10} />
                                                <div style={{ fontSize: '0.75rem' }}> Integration Studio</div>
                                            </Paper>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {userDetails.role.name === 'admin' &&
                    < Grid item xs={12} md={3} onClick={() => handleBoxClick('Security Cockpit')}>
                        <Paper className={classes.paper}
                        >
                            <SecurityOutlinedIcon className={classes.mgBottom10} />
                            <div style={{ fontSize: '0.75rem' }}> Security Cockpit</div>
                        </Paper>
                    </Grid>
                }
            </div>
        </div >
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadingSingleUser: (id) => dispatch(onLoadingSingleUser(id)),
        getAccessData: (userRole) => dispatch(getAccessData(userRole)),
        getProfile: () => dispatch(getProfile()),
        currentApplication: (app) => dispatch(currentApplication(app))
    }
}

const mapStateToProps = state => {
    return {
        userRoleSingle: state.addMultipleConfigurationData.userRoleSingle,
        accessData: state.addMultipleConfigurationData.accessData,
        profileData: state.profileData.profileData,
        userData: state.loginData.userData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLauncher);