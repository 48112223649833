import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Paper, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles(theme => ({
  root: {
    borderBottomColor: theme.palette.table.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const SearchInput = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
    >
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        placeholder="Search"
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="Close"
        size="large">
        <CloseIcon className={classes.icon} />
      </IconButton>
    </Paper>
  );
  
};

SearchInput.propTypes = {
  className: PropTypes.string,
};

export default SearchInput;