import React, { useEffect } from 'react';
import {
    Card, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Typography, CardHeader,
    Dialog, DialogActions, Button, IconButton, Paper, Chip, Breadcrumbs
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles, styled } from '@mui/styles';
import { InvertColors, Details } from '@mui/icons-material';
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import { WaterFall, Funnel } from '../../../Graphs';
import { connect } from 'react-redux';
import { getDefaultValuesAllDataNew } from '../../../../../../redux/actions';
import { Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { LabelText } from '../../../../../../components/Inputs';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from "react-spinners/HashLoader";

var data = [];
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0px 0px 20px',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    stackList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 5
    },
    stackContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rootHeader: {
        paddingLeft: 0,
        marginTop: 0
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: "none",
        border: 0,
        //fontSize: 16,
        paddingRight: 10,
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        '&:last-child': {
            backgroundColor: '#98DFD6',
            height: 46
        },
    },
}))(TableRow);
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    border: '1px solid',
    borderColor: theme.palette.text.secondary,
    justifyContent: 'space-between',
    display: 'flex'
}));
const DetailCPQ = props => {
    const [summary, setSummary] = React.useState({});
    const classes = useStyles();
    const history = useHistory();
    const { className } = props;
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    const [graphData, setGraphData] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [chart, setChart] = React.useState('waterfall');
    const [header, setHeader] = React.useState([]);
    const [priceData, setPriceData] = React.useState([]);
    const handleClose = () => {
        setOpen(false);
    };
    const [typeOfChart, setTypeOfChart] = React.useState('');
    const handleWaterfall = (item, type) => {
        setTypeOfChart(type);
        setOpen(true);
        setGraphData(item);
    }
    useEffect(() => {
        if (props.data && props.data.application_type && props.data.source_data_type)
            props.onLoadingLabelDesignerNew('field-label-designer', props.data.application_type, props.data.source_data_type)
    }, [])

    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function handlechart(item) {
        setChart(item)
    }
    useEffect(() => {
        var head = []
        console.log(props.priceCalForSimulationData)
        if (props.priceCalForSimulationData && props.priceCalForSimulationData.results && props.priceCalForSimulationData.results.length > 0) {
            var head = props.priceCalForSimulationData.summary && props.priceCalForSimulationData.summary.price_type_name_values && Object.entries(props.priceCalForSimulationData.summary.price_type_name_values).sort(([k1, v1], [k2, v2]) => v1.formula_step > v2.formula_step ? 1 : -1).map(([k, v]) => k)
            setSummary(props.priceCalForSimulationData.summary);
            setHeader(head)
            var data = {
                "simulation_number": props.priceCalForSimulationData.results && props.priceCalForSimulationData.results[0] && props.priceCalForSimulationData.results[0].calculation_simulation_number,
                "quote_amount": props.priceCalForSimulationData.summary && props.priceCalForSimulationData.summary.invoice_price,
            }
            setPriceData([...props.priceCalForSimulationData.results, props.priceCalForSimulationData.summary])
            if (props.page != 'price-policy')
                props.onChange(data)
        }
    }, [props.priceCalForSimulationData]);
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >

            <div className={classes.root}>
                {props.page == 'price-policy' &&
                    <Breadcrumbs aria-label="breadcrumb" style={{ padding: 10 }}>
                        <Link
                            variant="h5"
                            classes={{
                                root: classes.link,
                            }}
                            to="/price-policy-exceptions-report"
                        >
                            Price Policy Report
                        </Link>
                        <Typography color="textPrimary" variant="h4">
                            Quote Data
                        </Typography>
                    </Breadcrumbs>
                }
                <Dialog
                    classes={{ paper: classes.paperContainer }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    {typeOfChart === 'waterfall' ?
                        <WaterFall data={graphData} id='detailed-chart' name='Pricing Simulation Analysis' />
                        :
                        <Funnel data={graphData} id='detailed-chart' />
                    }

                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {props.priceCalForSimulationData && props.priceCalForSimulationData.results && props.priceCalForSimulationData.results.length > 0 && props.active ?
                    <>
                        <Card>
                            <div className={classes.row} >
                                <CardHeader
                                    title="Quote Summary"
                                    titleTypographyProps={{ variant: 'h3' }}
                                    classes={{
                                        root: classes.rootHeader
                                    }}
                                />
                            </div>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex' }}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >

                                    <Box sx={{ width: '100%' }}>
                                        <Stack direction="column" spacing={1} justifyContent="space-between">
                                            <Item style={{ backgroundColor: '#FFF4D2', borderColor: '#FFF4D2' }}>
                                                <div className={classes.stackList} >
                                                    <label > Base Price</label>
                                                    <Chip label={summary.base_price != '0' ? summary.base_price : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label > Invoice Price</label>
                                                    <Chip label={summary.invoice_price != '0' ? summary.invoice_price : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label >Net Price</label>
                                                    <Chip label={summary.off_invoice_price != '0' ? summary.off_invoice_price : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label > Margin Price</label>
                                                    <Chip label={summary.margin_price != '0' ? summary.margin_price : 0} color="primary" variant="outlined" />
                                                </div>
                                            </Item>
                                            <Item style={{ display: 'block', backgroundColor: '#f2d2f8', borderColor: '#f2d2f8' }}>
                                                <div className={classes.stackContainer}>
                                                    <div className={classes.stackList}>
                                                        <label> On-Invoice Adjustments</label>
                                                        <Chip label={summary.on_invoice_adjustments != '0' ? summary.on_invoice_adjustments : 0} color="primary" variant="outlined" />
                                                    </div>
                                                    <div className={classes.stackList} >
                                                        <label >On-Invoice Discounts</label>
                                                        <Chip label={summary.on_invoice_discounts != '0' ? summary.on_invoice_discounts : 0} color="primary" variant="outlined" />
                                                    </div>
                                                    <div className={classes.stackList} >
                                                        <label >On-Invoice Freight</label>
                                                        <Chip label={summary.on_invoice_freight != '0' ? summary.on_invoice_freight : 0} color="primary" variant="outlined" />
                                                    </div>
                                                    <div className={classes.stackList} >
                                                        <label >On-Invoice Surcharges</label>
                                                        <Chip label={summary.on_invoice_surcharges != '0' ? summary.on_invoice_surcharges : 0} color="primary" variant="outlined" />
                                                    </div>
                                                </div>
                                            </Item>
                                            <Item style={{ display: 'block', backgroundColor: '#d8f8d2', borderColor: '#d8f8d2' }}>
                                                <div className={classes.stackContainer}>
                                                    <div className={classes.stackList}>
                                                        <label>Off-Invoice Adjustments</label>
                                                        <Chip label={summary.off_invoice_adjustments != '0' ? summary.off_invoice_adjustments : 0} color="primary" variant="outlined" />
                                                    </div>

                                                    <div className={classes.stackList} >
                                                        <label >Off-Invoice Expense</label>
                                                        <Chip label={summary.off_invoice_expense != '0' ? summary.off_invoice_expense : 0} color="primary" variant="outlined" />
                                                    </div>
                                                    <div className={classes.stackList} >
                                                        <label >Off-Invoice Revenue</label>
                                                        <Chip label={summary.off_invoice_revenue != '0' ? summary.off_invoice_revenue : 0} color="primary" variant="outlined" />
                                                    </div>
                                                </div>
                                            </Item>
                                            <Item style={{ backgroundColor: '#C9EEFF', borderColor: '#C9EEFF' }}>
                                                <div className={classes.stackList} >
                                                    <label >Cost</label>
                                                    <Chip label={summary.cost != '0' ? summary.cost : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label >Cost Direct</label>
                                                    <Chip label={summary.cost_direct != '0' ? summary.cost_direct : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label >Cost Indirect</label>
                                                    <Chip label={summary.cost_indirect != '0' ? summary.cost_indirect : 0} color="primary" variant="outlined" />
                                                </div>
                                            </Item>
                                            <Item style={{ backgroundColor: '#f8d2d8', borderColor: '#f8d2d8' }} >
                                                <div className={classes.stackList} >
                                                    <label > Invoice Price %</label>
                                                    <Chip label={summary.invoice_price_percentage != '0' ? summary.invoice_price_percentage : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label >Net Price %</label>
                                                    <Chip label={summary.off_invoice_price_percentage != '0' ? summary.off_invoice_price_percentage : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label > Margin Price %</label>
                                                    <Chip label={summary.margin_price_percentage != '0' ? summary.margin_price_percentage : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label > Margin Target %</label>
                                                    <Chip label={summary.margin_target_percentage != '0' ? summary.margin_target_percentage : 0} color="primary" variant="outlined" />
                                                </div>
                                            </Item>
                                            <Item style={{ backgroundColor: '#FFF4D2', borderColor: '#FFF4D2' }}>
                                                <div className={classes.stackList} >
                                                    <label > Market Price</label>
                                                    <Chip label={summary.market_price != '0' ? summary.market_price : 0} color="primary" variant="contained" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label > Market Price %</label>
                                                    <Chip label={summary.market_price_percentage != '0' ? summary.market_price_percentage : 0} color="primary" variant="outlined" />
                                                </div>
                                                <div className={classes.stackList} >
                                                    <label > Market Target %</label>
                                                    <Chip label={summary.market_target_percentage != '0' ? summary.market_target_percentage : 0} color="primary" variant="outlined" />
                                                </div>
                                            </Item>

                                        </Stack>
                                    </Box>

                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Funnel data={summary} id='funnel-chart' />


                                </Grid>
                            </Grid>


                        </Card>
                        <Card>
                            <div className={classes.row} style={{ paddingBottom: 0 }}>
                                <CardHeader
                                    title="Line Level Summary"
                                    titleTypographyProps={{ variant: 'h3' }}
                                    classes={{
                                        root: classes.rootHeader
                                    }}
                                />
                            </div>
                            <div className={classes.container} style={{ paddingTop: 0 }} >
                                <Grid>
                                    {props.priceCalForSimulationData && props.priceCalForSimulationData.results && props.priceCalForSimulationData.results.length > 0 &&
                                        <TableContainer >
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell className={classes.tableCell}> Customer Number </TableCell>
                                                        <TableCell className={classes.tableCell}> Material Number </TableCell>
                                                        <TableCell className={classes.tableCell} style={{ width: 100 }}> Pricing Date </TableCell>
                                                        <TableCell className={classes.tableCell}> Base Price </TableCell>
                                                        <TableCell className={classes.tableCell}> On-Invoice Adjustments </TableCell>
                                                        <TableCell className={classes.tableCell}> Invoice Price </TableCell>
                                                        <TableCell className={classes.tableCell}> Invoice Price % </TableCell>
                                                        <TableCell className={classes.tableCell}> Off-Invoice Adjustments </TableCell>
                                                        <TableCell className={classes.tableCell}> Net Price </TableCell>
                                                        <TableCell className={classes.tableCell}> Net Price % </TableCell>
                                                        <TableCell className={classes.tableCell}> Cost </TableCell>
                                                        <TableCell className={classes.tableCell}> Margin Price </TableCell>
                                                        <TableCell className={classes.tableCell}> Margin Price % </TableCell>
                                                        <TableCell className={classes.tableCell}> Margin Target % </TableCell>
                                                        <TableCell className={classes.tableCell}> Market Price </TableCell>
                                                        <TableCell className={classes.tableCell}> Market Price %</TableCell>
                                                        <TableCell className={classes.tableCell}> Market Target %</TableCell>
                                                        <TableCell className={classes.tableCell}> Analytics</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {//props.priceCalForSimulationData && props.priceCalForSimulationData.results && props.priceCalForSimulationData.results.map(item => {
                                                        priceData && priceData.length > 0 && priceData.map((item, i) => {
                                                            return (
                                                                <StyledTableRow>
                                                                    <TableCell align='center'>{item.customer_number}</TableCell>
                                                                    <TableCell align='center'>{item.material_number}</TableCell>
                                                                    <TableCell align='center' style={{ width: 100 }}>{item.pricing_date != '0001-01-01' ? item.pricing_date : ''}</TableCell>
                                                                    <TableCell align='center'>{item.base_price != '0' ? item.base_price : ''}</TableCell>
                                                                    <TableCell align='center'>{item.on_invoice_adjustments != '0' ? item.on_invoice_adjustments : ''}</TableCell>
                                                                    <TableCell align='center'>{item.invoice_price != '0' ? item.invoice_price : ''}</TableCell>
                                                                    <TableCell align='center'>{item.invoice_price_percentage != '0' ? item.invoice_price_percentage : ''}</TableCell>
                                                                    <TableCell align='center'>{item.off_invoice_adjustments != '0' ? item.off_invoice_adjustments : ''}</TableCell>
                                                                    <TableCell align='center'>{item.off_invoice_price != '0' ? item.off_invoice_price : ''}</TableCell>
                                                                    <TableCell align='center'>{item.off_invoice_price_percentage != '0' ? item.off_invoice_price_percentage : ''}</TableCell>
                                                                    <TableCell align='center'>{item.cost != '0' ? item.cost : ''}</TableCell>
                                                                    <TableCell align='center'>{item.margin_price != '0' ? item.margin_price : ''}</TableCell>
                                                                    <TableCell align='center' style={{ color: item.margin_target_percentage && (item.margin_target_percentage > item.margin_price_percentage) ? 'red' : '#0D1333' }}>{item.margin_price_percentage != '0' ? item.margin_price_percentage : ''}</TableCell>
                                                                    <TableCell align='center'>{item.margin_target_percentage != '0' ? item.margin_target_percentage : ''}</TableCell>
                                                                    <TableCell align='center'>{item.market_price != '0' ? item.market_price : ''}</TableCell>
                                                                    <TableCell align='center' style={{ color: item.market_target_percentage && (item.market_target_percentage > item.market_price_percentage) ? 'red' : '#0D1333' }}>{item.market_price_percentage != '0' ? item.market_price_percentage : ''}</TableCell>
                                                                    <TableCell align='center'>{item.market_target_percentage != '0' ? item.market_target_percentage : ''}</TableCell>
                                                                    <TableCell style={{ display: 'flex' }}>
                                                                        <Tooltip
                                                                            title="Waterfall Chart"
                                                                        >
                                                                            <IconButton
                                                                                onClick={() => handleWaterfall(item, 'waterfall')}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <InvertColors color="disabled" style={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title="Funnel Chart"
                                                                        >
                                                                            <IconButton
                                                                                onClick={() => handleWaterfall(item, 'funnel')}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <Details color="disabled" style={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>

                                                                </StyledTableRow>
                                                            );
                                                        })
                                                    }

                                                </TableBody>
                                            </Table>

                                        </TableContainer>}
                                </Grid>
                            </div>
                        </Card>
                        <Card>
                            <div className={classes.row} >
                                <CardHeader
                                    title="Line Level Details"
                                    titleTypographyProps={{ variant: 'h3' }}
                                    classes={{
                                        root: classes.rootHeader
                                    }}
                                />
                            </div>
                            <div className={classes.container} style={{ paddingTop: 0 }}>
                                <Grid>
                                    {props.priceCalForSimulationData && props.priceCalForSimulationData.results && props.priceCalForSimulationData.results.length > 0 &&
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell className={classes.tableCell}> Customer Number </TableCell>
                                                        <TableCell className={classes.tableCell}> Material Number </TableCell>
                                                        <TableCell className={classes.tableCell}> Pricing Date </TableCell>
                                                        {header && header.length > 0 && header.map(item => {
                                                            return (
                                                                <TableCell className={classes.tableCell}>{item} </TableCell>
                                                            )
                                                        })}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {//props.priceCalForSimulationData && props.priceCalForSimulationData.results && props.priceCalForSimulationData.results.map(item => {
                                                        priceData && priceData.length > 0 && priceData.map((item, i) => {
                                                            return (
                                                                <StyledTableRow>
                                                                    <TableCell align='center'>{item.customer_number}</TableCell>
                                                                    <TableCell align='center'>{item.material_number}</TableCell>
                                                                    <TableCell align='center'>{item.pricing_date != '0001-01-01' ? item.pricing_date : ''}</TableCell>
                                                                    {header && header.length > 0 && header.map(k => {
                                                                        return (
                                                                            item.price_type_name_values && item.price_type_name_values[k] ?
                                                                                <TableCell align='center'>{item.price_type_name_values[k]['value']}</TableCell>
                                                                                :
                                                                                <TableCell align='center'></TableCell>
                                                                        )
                                                                    })}
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>

                                        </TableContainer>}
                                </Grid>
                            </div>
                        </Card>
                        {/*<Card  style={{ width:'100%'}}>*/}

                        <WaterFall data={props.priceCalForSimulationData && props.priceCalForSimulationData.summary} id='summary-waterfall' name='Pricing Simulation Analysis' />


                        {/*</Card>*/}
                    </>
                    : ''}
            </div>
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        data: state.addContractData.contractGeneralData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
        loading: state.pricingData.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCPQ);