import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery,
    TableRow,
    Breadcrumbs, Fab, Grid, FormControlLabel, Radio, RadioGroup, Button
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getApplicationLogs } from '../../redux/actions';
import { RotateLoader, RingLoader } from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: '0px 10px 0px 10px',
        marginBottom: 20
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    fabContainer: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
    },
    button: {
        marginLeft: '31rem',
        marginTop: '-3rem',
        marginBottom: '1rem'
    },
    spinner: {
        height: '80vh'
    },
}));
const ApplicationLogs = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [logsValue, setLogsValue] = React.useState([]);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    useEffect(() => {
        if (props.logsData) {
            setLogsValue(props.logsData)
        }
        else {
            setLogsValue([])
        }
    }, [props.logsData])
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleRun = () => {
        props.getApplicationLogs(radioGroupValue);
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="primary" variant='h1'>Application Logs</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container >
                    <Grid
                        item
                        md={12}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                            <FormControlLabel
                                value="Deployment A"
                                control={<Radio color="primary" />}
                                label={<Typography classes={{ root: classes.fontSetting }} > Deployment A </Typography>}
                                classes={{ labelPlacementStart: classes.radioLabel }}
                            />
                            <FormControlLabel
                                value="Deployment B"
                                control={<Radio color="primary" />}
                                label={<Typography classes={{ root: classes.fontSetting }} > Deployment B </Typography>}
                                classes={{ labelPlacementStart: classes.radioLabel }}
                            />
                            <FormControlLabel
                                value="Deployment C"
                                control={<Radio color="primary" />}
                                label={<Typography classes={{ root: classes.fontSetting }} > Deployment C </Typography>}
                                classes={{ labelPlacementStart: classes.radioLabel }}
                            />
                        </RadioGroup>
                    </Grid>
                    <Button disabled={!radioGroupValue ? true : false}
                        variant="contained" color="primary" className={classes.button} onClick={handleRun}
                        twoline='true'>
                        {
                            props.loading ?
                                <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                :
                                'Run'
                        }
                    </Button>
                </Grid>

                {logsValue && logsValue.length > 0 ?
                    <div className={classes.fabContainer}>
                        <Typography color="textprimary" variant='h3'>{logsValue}</Typography>
                    </div>
                    :
                    <Typography color="textprimary" variant='h3'>There is not data to show</Typography>
                }

            </div >
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        logsData: state.dataSetupData.ApplicationLogs,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getApplicationLogs: (type) => dispatch(getApplicationLogs(type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLogs);