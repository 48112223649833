import React, { useEffect } from 'react';
import {
    Grid,
    Breadcrumbs,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../components/Inputs';
import { connect } from 'react-redux';
import { getExceptionManagementViewDetails, getExceptionTierEvals } from '../../redux/actions';
import * as moment from 'moment'
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '20px 10px 0px 10px',
        backgroundColor: 'white',
        marginTop: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        height: '100%'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const ViewException = props => {
    const classes = useStyles();
    const { className } = props;
    const [externalData, setExternalData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);
    const [appType, setAppType] = React.useState('');
    const [contractData, setContractData] = React.useState('');


    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getExceptionManagementViewDetails(appId);
    }, []);

    useEffect(() => {
        if (props.exceptionManagementView) {
            setAppType(props.exceptionManagementView.application_type)
            setContractData(props.exceptionManagementView.contract_number)
        }
    }, [props.exceptionManagementView])
    useEffect(() => {
        if (appType && contractData) {
            props.getExceptionTierEvals(appType, contractData);
        }
    }, [appType, contractData]);

    function formatExceptionType(exceptionType) {
        const words = exceptionType.split('_');
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/exception-management'
                >
                    Exception Management
                </Link>
                <Typography color="textPrimary" variant='h4'>View Exception Management</Typography>
            </Breadcrumbs>
            {props.exceptionManagementView &&
                <div className={classes.container}>
                    <Grid container className={classes.gridContainer}>
                        {props.exceptionManagementView.application_type &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Application Type' data={props.exceptionManagementView.application_type} twoline='true' />
                            </Grid>
                        }
                        {props.exceptionManagementView.approval_status
                            &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Approval Status' data={props.exceptionManagementView.approval_status === 'acc' ? 'Accepted' : (
                                    props.exceptionManagementView.approval_status === 'rej' ? 'Rejected' : formatExceptionType(props.exceptionManagementView.approval_status)
                                )} twoline='true' />
                            </Grid>
                        }
                        {props.exceptionManagementView.contract_number &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Contract Number' data={props.exceptionManagementView.contract_number} twoline='true' />
                            </Grid>
                        }

                        {props.exceptionManagementView.exception_mapping && (
                            Object.entries(props.exceptionManagementView.exception_mapping).map(([key, value]) => (
                                key !== "rule_exception" && (
                                    <Grid item md={3} xs={12} key={key}>
                                        <LabelText
                                            classes={{ root: classes.formLabel }}
                                            heading={formatExceptionType(key)}
                                            data={typeof value === 'object' ? value.key : value}
                                            twoline="true"
                                        />
                                    </Grid>
                                )
                            ))
                        )}


                        {/* {props.exceptionManagementTierEvals &&
                            Object.keys(props.exceptionManagementTierEvals).map(key => {
                                if (key !== "rule_exception") {
                                    if (props.exceptionManagementView.exception_mapping[key]) {
                                        return (
                                            <Grid item md={3} xs={12} key={key}>
                                                <LabelText
                                                    classes={{ root: classes.formLabel }}
                                                    heading={props.exceptionManagementTierEvals[key].name}
                                                    data={props.exceptionManagementView.exception_mapping[key]}
                                                    twoline="true"
                                                />
                                            </Grid>
                                        );
                                    }
                                    return null;
                                }
                                return null;
                            })} */}

                        {/* {props.exceptionManagementTierEvals &&
                            Object.keys(props.exceptionManagementTierEvals).map(key => {
                                if (props.exceptionManagementView.exception_mapping.rule_exception) {
                                    if (props.exceptionManagementView.exception_mapping.rule_exception[key]) {
                                        return (
                                            <Grid item md={3} xs={12} key={key}>
                                                <LabelText
                                                    classes={{ root: classes.formLabel }}
                                                    heading={props.exceptionManagementTierEvals[key].name}
                                                    data={props.exceptionManagementView.exception_mapping.rule_exception[key]}
                                                    twoline="true"
                                                />
                                            </Grid>
                                        );
                                    }
                                    return null;
                                }
                            })
                        } */}


                        {props.exceptionManagementView.exception_mapping.rule_exception && (
                            Object.keys(props.exceptionManagementView.exception_mapping.rule_exception).map((key) => (
                                <Grid item md={3} xs={12} key={key}>
                                    <LabelText
                                        classes={{ root: classes.formLabel }}
                                        heading={formatExceptionType(key)}
                                        data={props.exceptionManagementView.exception_mapping.rule_exception.net_value}
                                        twoline="true"
                                    />
                                </Grid>
                            ))
                        )}

                        {props.exceptionManagementView.start_date &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText heading='Start Date' data={moment.utc(props.exceptionManagementView.start_date).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {props.exceptionManagementView.end_date &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText heading='End Date' data={moment.utc(props.exceptionManagementView.end_date).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {props.exceptionManagementView.exception_type &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Exception Type' data={formatExceptionType(props.exceptionManagementView.exception_type)} twoline='true' />
                            </Grid>
                        }
                        {props.exceptionManagementView.exception_value > 0 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Exception Value' data={props.exceptionManagementView.exception_value} twoline='true' />
                            </Grid>
                        }
                        {props.exceptionManagementView.exception_reason &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Exception Reason' data={props.exceptionManagementView.exception_reason} twoline='true' />
                            </Grid>
                        }

                        {props.exceptionManagementView.comments &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Comments' data={props.exceptionManagementView.comments} twoline='true' />
                            </Grid>
                        }
                    </Grid>
                </div>
            }
        </div>
    );

}
const mapStateToProps = state => {
    return {
        exceptionManagementTierEvals: state.customerData.exceptionManagementTierEvals,
        exceptionManagementView: state.customerData.exceptionManagementViews,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getExceptionManagementViewDetails: (id) => dispatch(getExceptionManagementViewDetails(id)),
        getExceptionTierEvals: (app, data) => dispatch(getExceptionTierEvals(app, data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewException);