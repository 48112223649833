import React, { useEffect } from 'react';
import {
    IconButton,
    Button,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    OutlinedInput,
    FormLabel,
    TablePagination, Fab
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getCollaborationPortal, postCollaborationPortal } from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditIcon from '@mui/icons-material/Edit';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    table: {
        marginRight: 15,
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    rootButton: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 14
    },
    selectedItem: {
        width: '100%'
    },
    textRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        padding: 30
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 6,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    spinner: {
        height: '100vh'
    },
    inputRoot: {
        width: '543px'
    }
}));

const CollaborationID = props => {
    useEffect(() => {
        props.getCollaborationPortal(1, 10)
    }, []);
    const classes = useStyles();
    const { className } = props;
    const [currentEditId, setCurrentEditId] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [collaborationName, setCollaborationName] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [collaborationData, setCollaborationData] = React.useState([]);
    const [employeeNumber, setEmployeeNumber] = React.useState([]);
    const [contractNumber, setContractNumber] = React.useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [selectedCollaborationId, setSelectedCollaborationId] = React.useState(null);

    useEffect(() => {
        if (props.collaborationPortalData && props.collaborationPortalData.records
            && props.collaborationPortalData.records.length > 0) {
            setCollaborationData(props.collaborationPortalData.records)
        }
    }, [props.collaborationPortalData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getCollaborationPortal(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getCollaborationPortal(1, event.target.value);
    };
    const handleSupplierNumber = (newValue) => {
        setSupplierNumber(newValue);
    }
    const handleSupplierNumberDeletion = (newValue) => {
        var deleteFieldValue = supplierNumber.filter(item => item !== newValue)
        setSupplierNumber(deleteFieldValue)
    }
    const handleCustomerNumber = (newValue) => {
        setCustomerNumber(newValue);
    }
    const handleCustomerNumberDeletion = (newValue) => {
        var deleteFieldValue = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deleteFieldValue)
    }
    const handleEmployeeNumber = (newValue) => {
        setEmployeeNumber(newValue);
    }
    const handleEmployeeNumberDeletion = (newValue) => {
        var deleteFieldValue = employeeNumber.filter(item => item !== newValue)
        setEmployeeNumber(deleteFieldValue)
    }
    const handleContractNumber = (newValue) => {
        setContractNumber(newValue);
    }
    const handleContractNumberDeletion = (newValue) => {
        var deleteFieldValue = contractNumber.filter(item => item !== newValue)
        setContractNumber(deleteFieldValue)
    }
    const handleCollaborationName = (e) => {
        setCollaborationName(e.target.value);
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    const handleSubmit = () => {
        var formData = {
            "name": collaborationName,
            "supplier_number": supplierNumber,
            "customer_number": customerNumber,
            "employee_number": employeeNumber,
            "contract_number": contractNumber,
        }
        if (currentEditId) {
            props.postCollaborationPortal(formData, currentEditId, 'edit');
            handleClear();
        } else {
            props.postCollaborationPortal(formData);
            handleClear();
        }
    }
    const handleClear = () => {
        setOpen(false);
        setCollaborationName('');
        setSupplierNumber([]);
        setCustomerNumber([]);
        setContractNumber([]);
        setCurrentEditId('');
        setEmployeeNumber([])
    }
    const editCollaborationPortal = (item) => {
        setOpen(true);
        setCollaborationName(item.name);
        setSupplierNumber(item.supplier_number);
        setCustomerNumber(item.customer_number);
        setCurrentEditId(item.ID);
        setEmployeeNumber(item.employee_number);
        setContractNumber(item.contract_number);
    }
    const handleDeleteClick = (collaborationId) => {
        setSelectedCollaborationId(collaborationId);
        setOpenConfirmDialog(true);
    };
    
    const handleConfirmDelete = () => {
        if (selectedCollaborationId) {
            props.postCollaborationPortal(null, selectedCollaborationId, 'delete');
        }
        setOpenConfirmDialog(false);
    };
    
    const handleCloseDialog = () => {
        setOpenConfirmDialog(false);
    };

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div>

                <div
                    className={clsx(classes.root, className)}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 14, marginTop: 10 }}> Collaboration ID </Typography>
                    <div
                        className={classes.rootButton} style={{ marginTop: "-30px" }}
                    >

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            onClick={() => setOpen(true)}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                        {/* <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxOutlinedIcon />}
                        onClick={() => setOpen(true)}
                    >
                        Add Collaboration
                    </Button> */}
                    </div >

                    <Dialog open={open}>
                        <div style={{ padding: 20 }}>
                            <Grid
                                container
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <FormLabel required>
                                        Collaboration Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={collaborationName}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCollaborationName}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: -15 }}
                                    className={classes.gridContainer}
                                >
                                    <FormLabel required={employeeNumber?.length == 0 && supplierNumber.length == 0 ? true : false}>
                                        Customer Master Number
                                    </FormLabel>
                                    <ChipInput data={customerNumber} onChange={handleCustomerNumber} />

                                </Grid>
                                <Grid
                                    item
                                    style={{ marginTop: -15 }}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <FormLabel required={customerNumber.length == 0 && employeeNumber.length == 0 ? true : false}>
                                        Supplier Master Number
                                    </FormLabel>
                                    <ChipInput data={supplierNumber} onChange={handleSupplierNumber} />

                                </Grid>
                                <Grid
                                    item
                                    style={{ marginTop: -15 }}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <FormLabel required={customerNumber.length == 0 && supplierNumber.length == 0 ? true : false}>
                                        Employee Number
                                    </FormLabel>
                                    <ChipInput data={employeeNumber} onChange={handleEmployeeNumber} />

                                </Grid>
                                <Grid
                                    item
                                    style={{ marginTop: -15 }}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <FormLabel>
                                        Contract Number
                                    </FormLabel>
                                    <ChipInput data={contractNumber} onChange={handleContractNumber} />

                                </Grid>
                            </Grid>
                        </div>

                        <DialogActions>
                            <Button onClick={handleClear} color="primary" style={{ marginTop: -30, marginLeft: 25 }}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={handleSubmit} style={{ marginTop: -30 }}
                                disabled={collaborationName && (supplierNumber.length > 0 || employeeNumber?.length > 0 || customerNumber.length > 0) ? false : true}>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {collaborationData.length > 0 ?
                        <div style={{ margin: 15 }}>
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow >
                                            <TableCell align='center' className={classes.tabHead}>Collaboration ID</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Collaboration Name</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Contract Number</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Customer Number</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Supplier Number</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Employee Number</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {
                                            collaborationData.map(item => {
                                                return (
                                                    <StyledTableRow>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.ID}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.name}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.contract_number ? item.contract_number.join(',') : ''}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.customer_number.join(',')}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.supplier_number ? item.supplier_number.join(',') : ''}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.employee_number ? item.employee_number.join(',') : ''}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton
                                                                onClick={() => editCollaborationPortal(item)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleDeleteClick(item.ID)}
                                                                size="large">
                                                                <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={props.collaborationPortalData && props.collaborationPortalData.total_record ? props.collaborationPortalData.total_record : 0}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    classes={{
                                        caption: classes.caption,
                                        root: classes.paginationRoot
                                    }}
                                />
                            </TableContainer>
                        </div>
                        :
                        <Typography variant='h4' style={{ textAlign: 'center', padding: 15 }}>
                            No collaboration ID's have been created
                        </Typography>
                    }
                </div>
            </div >
            <Dialog
                open={openConfirmDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this collaboration ID?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant='contained'
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay>
        
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getCollaborationPortal: (pagination, item) => dispatch(getCollaborationPortal(pagination, item)),
        postCollaborationPortal: (data, editId, type) => dispatch(postCollaborationPortal(data, editId, type))
    }
}

const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        collaborationPortalData: state.addMultipleConfigurationData.collaborationPortalData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationID);