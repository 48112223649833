import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    IconButton,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { addIntegrationSetup, getDefaultValuesCustomerXRef, getFilterNameList, getIntegrationFieldValues } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Clear } from '@mui/icons-material';
import clsx from 'clsx';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextInputWithLabel, DropdownArray } from '../../../components/Inputs';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 6,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 13
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    addIcon: {
        marginTop: 93,
        marginLeft: 11,
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.2)',
            cursor: 'pointer'
        },
    },
}));

const AddIntegrationFilter = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [channelPartnerTypeArray, setChannelPartnerTypeArray] = React.useState([]);
    const [filterValue, setFiltervalue] = React.useState(['']);
    const [filterName, setFilterName] = React.useState([]);
    const [integrationNameArray, setintegrationNameArray] = React.useState([]);
    const [filterNameArray, setFilterNameArray] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [filterValueType, setFilterValueType] = React.useState([]);
    const [filterValueTypeArray, setFilterValueTypeArray] = React.useState(['Static', 'Dynamic']);
    const [activityArray, setActivityArray] = React.useState([]);
    const [dataSourceArray, setDataSourceArray] = React.useState([]);
    const [userStore, setUserStore] = React.useState([]);
    const [activity, setActivity] = React.useState('');
    const [dataSource, setDataSource] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [integrationName, setIntegrationName] = React.useState('');
    const [topics, setTopics] = React.useState('');
    const [groupId, setGroupId] = React.useState('');
    const [securityProtocol, setSecurityProtocol] = React.useState('');
    const [mechanism, setMechanism] = React.useState('PLAIN');
    const [sslKey, setSslKey] = React.useState('');
    const [sslCa, setSslCa] = React.useState('');
    const [sslClientCertificate, setSslClientCertificate] = React.useState('');
    const securityProtocolArray = ['SASL', 'SSL', 'None']
    // const [boxCount, set] = React.useState(1);
    const [allDataNew, setAllDataNew] = React.useState([]);

    const MenuProps = {
        PaperProps: {
            style: {

                maxHeight: 250,
                //width: 250
            }
        }
    }
    useEffect(() => {
        props.onLoadingDefault();
        props.getIntegraionFieldValues('activityList')
        props.getIntegraionFieldValues('dataSource')
    }, []);
    useEffect(() => {
        if (props.activityList) {
            setActivityArray(props.activityList)
        }
        if (props.dataSourceList) {
            setDataSourceArray(props.dataSourceList)
        }
    }, [props.activityList, props.dataSourceList]);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'channel_partner_type') {
                            setChannelPartnerTypeArray(item);
                        }
                    })
                }
            }
        }
    });
    useEffect(() => {
        if (props.filterNameList) {
            setFilterNameArray(props.filterNameList);
        }
    }, [props.filterNameList]);
    var data = {
        "name": integrationName,
        "activity": activity,
        "datasource": dataSource,
        "url": url,
        "user_id": userId,
        "password": password,
        "filter_name": filterName,
        "filter_value_type": filterValueType,
        "filter_value": filterValue,
        "topic": topics,
    };
    function handleActivity(e) {
        setActivity(e.target.value);
        setFilterName([])
    }
    function handleDataSource(e) {
        setDataSource(e.target.value);
    }
    function handleSecurityProtocol(e) {
        setSecurityProtocol(e.target.value);
    }
    function handleUrl(e) {
        setUrl(e.target.value);
    }
    function handleUserId(e) {
        setUserId(e.target.value);
    }
    function handlePassword(e) {
        setPassword(e.target.value);
    }
    function handleSubmit(editIndex) {
        var data = {
            "name": integrationName,
            // "activity": activity,
            // "datasource": dataSource,
            // "url": url,
            // "user_id": userId,
            // "password": password,
            "filter_name": filterName,
            "filter_value_type": filterValueType,
            "filter_value": filterValue,
            // "topic": topics,
            // "group_id": groupId,
            // "security_protocol": securityProtocol,
            // "mechanisms": mechanism,
            // "ssl_ca": sslCa,
            // "ssl_key": sslKey,
            // "ssl_client_certificate": sslClientCertificate,
        };
        props.onSubmit(data)
    }
    function handleClear() {
        setActivity('');
        setDataSource('');
        setUrl('');
        setUserId('');
        setPassword('');
        setIntegrationName('')
        setTopics('')
        setGroupId('')
        setSecurityProtocol('')
        setMechanism('')
        setSslCa('')
        setSslKey('')
        setSslClientCertificate('')
    }
    function handleOnSubmit() {
        props.onSubmit(userStore);
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    function handleintegrationName(newValue, index) {
        integrationName[index] = newValue;
    }
    function handleFilterName(newValue, index) {
        filterName[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        filterValue[index] = newValue;
    }
    function handleFilterValueType(newValue, index) {
        filterValueType[index] = newValue;
    }
    function handleOnAdd() {
        filterName[addMember] = ''
        filterValueType[addMember] = ''
        filterValue[addMember] = ''
        setAddMember(addMember + 1)
    }
    useEffect(() => {
        if (activity) {
            props.getFilterNameList(activity);
        }
    }, [activity]);
    const handleDeleteLocally = (e, index) => {
        setFilterName(item => item.filter((item, i) => i !== index));
        setFilterValueType(item => item.filter((item, i) => i !== index));
        setFiltervalue(item => item.filter((item, i) => i != index));
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
    }
    function addaddMember() {
        setAddMember(addMember + 1)
    }

    function removeaddMember() {
        if (addMember > 1) {
            setAddMember(addMember - 1);
            allDataNew.pop();
        }
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/integration-filter'
                >
                    Integration Filter
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Integration</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={integrationName}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setIntegrationName(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Activity
                                    </FormLabel>
                                    <Select
                                        onChange={handleActivity}
                                        value={activity}

                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        <MenuItem value="">
                                            {props.placeholder}
                                        </MenuItem>
                                        {activityArray && activityArray.length > 0 && activityArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} name={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >   <FormLabel className={classes.formLabel} required>
                                        Data Source
                                    </FormLabel>
                                    <Select
                                        onChange={handleDataSource}
                                        value={dataSource}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        <MenuItem value="">
                                            {props.placeholder}
                                        </MenuItem>
                                        {dataSourceArray && dataSourceArray.length > 0 && dataSourceArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} name={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            URL
                                        </FormLabel>
                                        <OutlinedInput
                                            value={url}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleUrl} />
                                    </div>
                                </Grid> */}
                                {dataSource === 'Kafka Consumer' || dataSource === 'Kafka Producer' ? (
                                    <>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel} required>
                                                    Topic
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={topics}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setTopics(e.target.value)} />
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel} >
                                                    GroupId
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={groupId}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setGroupId(e.target.value)} />
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >   <FormLabel className={classes.formLabel} required>
                                                Security Protocol
                                            </FormLabel>
                                            <Select
                                                onChange={handleSecurityProtocol}
                                                value={securityProtocol}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value="">
                                                    {props.placeholder}
                                                </MenuItem>
                                                {securityProtocolArray && securityProtocolArray.length > 0 && securityProtocolArray.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} name={item}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                        {securityProtocol === 'SASL' ? (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        Mechanisms
                                                    </FormLabel>
                                                    <OutlinedInput
                                                        value={mechanism}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={(e) => setMechanism(e.target.value)} />
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {securityProtocol === 'SASL' || securityProtocol === 'SSL' ? (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        Ssl Ca
                                                    </FormLabel>
                                                    <OutlinedInput
                                                        value={sslCa}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={(e) => setSslCa(e.target.value)} />
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {securityProtocol === 'SSL' ? (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        Ssl Key
                                                    </FormLabel>
                                                    <OutlinedInput
                                                        value={sslKey}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={(e) => setSslKey(e.target.value)} />
                                                </div>
                                            </Grid>
                                        ) : null}
                                        {securityProtocol === 'SSL' ? (
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} >
                                                        Ssl Client Certificate
                                                    </FormLabel>
                                                    <OutlinedInput
                                                        value={sslClientCertificate}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={(e) => setSslClientCertificate(e.target.value)} />
                                                </div>
                                            </Grid>
                                        ) : null}
                                    </>
                                ) : null}
                                {/* {securityProtocol !== 'SSL' && securityProtocol !== 'None' && (
                                    <>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel} required>
                                                    User ID
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={userId}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handleUserId}
                                                />

                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel} required>
                                                    Password
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={password}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={handlePassword}
                                                    type="password"
                                                />
                                            </div>
                                        </Grid>
                                    </>
                                )} */}
                                {/* <Grid container spacing={2}
                                    style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                                >
                                    {(() => {
                                        let td = [];
                                        for (let i = 0; i < addMember; i++) {
                                            td.push(<>
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >  <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Integration Name'}
                                                    placeholder={'Please select'}
                                                    twoline='true'
                                                    onChange={(e) => handleFilterName(e, i)}
                                                    data={integrationNameArray}
                                                    prevalue={'init'} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >  <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Filter Name'}
                                                    placeholder={'Please select'}
                                                    twoline='true'
                                                    onChange={(e) => handleFilterName(e, i)}
                                                    data={filterNameArray}
                                                    prevalue={'init'} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Filter Value Type'}
                                                        placeholder={'Please select'}
                                                        twoline='true'
                                                        onChange={(e) => handleFilterValueType(e, i)}
                                                        data={filterValueTypeArray}
                                                        prevalue={'init'} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                    style={{ marginTop: i === 0 ? '15px' : '-44px' }}
                                                >
                                                    <FormLabel className={classes.formLabel} style={{ marginBottom: -7 }}>Filter Value</FormLabel>
                                                    <TextInputWithLabel style={{ marginBottom: 3 }} classes={{ root: classes.formLabel }} twoline='true' onChange={(e) => handleFilterValue(e, i)} />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                    style={{ paddingTop: 20, marginTop: i === 0 ? '40px' : '0px' }}
                                                >
                                                    <IconButton
                                                        style={{ marginTop: i == 0 ? 32 : 10 }}
                                                        onClick={(e) => handleDeleteLocally(e, i)}
                                                        classes={{ root: classes.IconButton }}
                                                        size="medium">
                                                        <Clear />
                                                    </IconButton>
                                                    <IconButton
                                                        style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 32 : 10 }}
                                                        onClick={handleOnAdd}
                                                        classes={{ root: classes.IconButton }}
                                                        size="medium">
                                                        <AddBoxOutlinedIcon />
                                                    </IconButton>
                                                </Grid>
                                            </>)
                                        }
                                        return td;
                                    })()}
                                </Grid> */}
                                <Grid container spacing={2}>
                                    <>
                                        {(() => {
                                            let td = [];
                                            for (let i = 0; i < addMember; i++) {
                                                td.push(
                                                    <>
                                                        <Grid item md={11} xs={12} style={{ display: 'flex', gap: 15 }}>
                                                            <Grid
                                                                item
                                                                md={3}
                                                                xs={12}
                                                                classes={{ root: classes.gridContainer }}
                                                            >  <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Integration Name'}
                                                                placeholder={'Please select'}
                                                                twoline='true'
                                                                onChange={(e) => handleFilterName(e, i)}
                                                                data={integrationNameArray}
                                                                prevalue={'init'} />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={3}
                                                                xs={12}
                                                                classes={{ root: classes.gridContainer }}
                                                            >  <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Filter Name'}
                                                                placeholder={'Please select'}
                                                                twoline='true'
                                                                onChange={(e) => handleFilterName(e, i)}
                                                                data={filterNameArray}
                                                                prevalue={'init'} />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={3}
                                                                xs={12}
                                                                classes={{ root: classes.gridContainer }}
                                                            >
                                                                <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Filter Value Type'}
                                                                    placeholder={'Please select'}
                                                                    twoline='true'
                                                                    onChange={(e) => handleFilterValueType(e, i)}
                                                                    data={filterValueTypeArray}
                                                                    prevalue={'init'} />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={3}
                                                                xs={12}
                                                                classes={{ root: classes.gridContainer }}
                                                                style={{ marginTop: i === 0 ? '15px' : '0px' }}
                                                            >
                                                                {
                                                                    i == 0 &&
                                                                    <FormLabel className={classes.formLabel} style={{ marginBottom: -7 }}>Filter Value</FormLabel>}
                                                                <TextInputWithLabel style={{ marginBottom: 3 }} classes={{ root: classes.formLabel }} twoline='true' onChange={(e) => handleFilterValue(e, i)} />
                                                            </Grid>
                                                            {/* <Grid
                                                    item
                                                        md={3}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                        style={{ paddingTop: 20, marginTop: i === 0 ? '40px' : '0px' }}
                                                    >
                                                        <IconButton
                                                            style={{ marginTop: i == 0 ? 32 : 10 }}
                                                            onClick={(e) => handleDeleteLocally(e, i)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="medium">
                                                            <Clear />
                                                        </IconButton>
                                                        <IconButton
                                                            style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 32 : 10 }}
                                                            onClick={handleOnAdd}
                                                            classes={{ root: classes.IconButton }}
                                                            size="medium">
                                                            <AddBoxOutlinedIcon />
                                                        </IconButton>
                                                    </Grid> */}

                                                        </Grid>
                                                        <Grid style={{ marginTop: i == 0 ? '0' : '-55' }}>
                                                            {i == addMember - 1 &&
                                                                <>
                                                                    <RemoveCircleIcon
                                                                        className={classes.addIcon}
                                                                        color={"primary"}
                                                                        onClick={removeaddMember}
                                                                    />
                                                                    <AddCircleIcon
                                                                        className={classes.addIcon}
                                                                        color={"primary"}
                                                                        onClick={addaddMember}
                                                                    />
                                                                </>
                                                            }
                                                        </Grid>
                                                    </>

                                                )
                                            }
                                            return td;
                                        })()}

                                    </>

                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        filterNameList: state.customerData.filterNameList,
        activityList: state.customerData.activityList,
        dataSourceList: state.customerData.dataSourceList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addIntegrationSetup(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef()),
        getFilterNameList: (activity) => dispatch(getFilterNameList(activity)),
        getIntegraionFieldValues: (option) => dispatch(getIntegrationFieldValues(option))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddIntegrationFilter);
