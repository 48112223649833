import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography,
    Radio, RadioGroup, FormControlLabel, FormLabel,
    Select, MenuItem, TextField,
    Checkbox, Dialog,
    DialogActions,
    OutlinedInput, Card
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Dropdown, DropdownArray } from '../../../components/Inputs';
import { SearchDropDownMasterDataNew, SearchDropDownMasterData } from '../../../components/Inputs';
import SimpleDialog from '../../../components/Dialog';
import { toast } from 'react-toastify';
import { runTLP, getDefaultValues, getContractsTableDataList, checkingPostingDate, addBatchJobSetup, getLblDispDesFieldValue } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from '../../../components/Inputs';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { RotateLoader, RingLoader } from "react-spinners";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ContractNumberAC } from '../../../components/Inputs';
import { set } from 'date-fns';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        // padding: '10px 0px 0px 0px',
        padding: '20px 16px',
        marginTop: 5,
    },
    container: {
        padding: '0px 10px 0px 10px',
        marginBottom: 20
    },
    gridContainer: {
        padding: '0px 10px 0px 10px',
        // display: 'block !important',
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30,
        width: "60%"
    },
    button: {
        marginRight: 25,
        width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17,
        fontSize: theme.typography.h3.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: -12,
        marginTop: 0,
        // height: 42,
        // height: 36,
        padding: 0,
        marginBottom: 14,
        height: 35,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    menuPaper: {
        maxHeight: 300
    },
    errorMsg: {
        color: '#f44336',
        fontSize: 12,
        paddingTop: 5
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    paper: { minWidth: window.screen.width * .50 },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
        width: "60%",
        marginLeft: "1.5rem"
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        borderRadius: '1rem',
        height: '36px'
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '2rem',
        marginTop: '0.5rem',
        marginBottom: '5px',
    }
}));

const TlpForm = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className, application_type, button_hidden, setFormat3 } = props;
    const contractCustomisationUpdation = useRef();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [accrualFrequency, setAccrualFrequency] = React.useState('Monthly');
    const [accrualFrequencyArray, setAccrualFrequencyArray] = React.useState([]);
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [minimumDate, setMinDate] = React.useState(null);
    const [maximumDate, setMaxDate] = React.useState(null);
    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [contractNumber, setContractNumber] = useState('');
    const [contractType, setContractType] = React.useState('');
    const [postingDate, setPostingDate] = React.useState(null);
    const [createdOn, setCreatedOn] = React.useState(null);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [externalPostingDocumentNumber, setExternalPostingDocumentNumber] = React.useState('');
    const [selected, setSelected] = useState([]);
    const [startValid, setStartValid] = useState(true);
    const [endValid, setEndValid] = useState(true);
    const [postValid, setPostValid] = useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [endErrorMessage, setEndErrorMessage] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [open2, setOpen2] = useState(false);
    const [masterValue, setMasterValue] = React.useState([]);
    const [contractGroupArray, setContractGroupArray] = React.useState([]);
    const [contractSubGroupArray, setContractSubGroupArray] = React.useState([]);
    const [contractGroup, setContractGroup] = React.useState("");
    const [contractSubGroup, setContractSubGroup] = React.useState("");
    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timeStamp = Moment.tz(timezoneOffset).format().toString().split("T")
    const [copaData, setCopaData] = React.useState();

    useEffect(() => {
        // props.onLoadingDefault();
        var data = {
            "data_source": "contract_header",
            "application_type": application_type ? application_type : sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
        props.onCheckContractsTable();
        props.onCheckpostingDate();
    }, []);

    // useEffect(() => {

    //     if (props.checkPostingDate) {
    //         var date = new Date();
    //         const oneDayBefore = Moment().subtract(1, 'days');
    //         if (props.checkPostingDate == 'Allowed') {
    //             setMinDate(Moment(date).subtract(1, 'months').startOf('month').format('MM/DD/YYYY'));
    //             setMaxDate(Moment(date).endOf('month'))
    //             setPostingDate(Moment(date).subtract(1, 'months').startOf('month'));
    //         }
    //         else {
    //             setMinDate(Moment(date).startOf('month').format('MM/DD/YYYY'));
    //             setPostingDate(Moment(date).startOf('month'));
    //             setMaxDate(Moment(date).format('MM/DD/YYYY'))
    //         }
    //         // setCreatedOn(Moment(date).subtract(1, 'days').format('MM/DD/YYYY'));
    //         setCreatedOn(Moment(date).format('MM-DD-YYYY'));

    //     }
    // }, [props.checkPostingDate]);


    useEffect(() => {
        var contracts = [];
        var tempNameArray = [];
        if (props.contractTabledataComplete) {
            props.contractTabledataComplete.map(item => {
                contracts.push(item.contract_number.toString())
                tempNameArray.push(item.internal_description);
            })
            setContractNumbersList(contracts);
            setInternalDescriptionArray(tempNameArray);
        }


    }, [props.contractTabledataComplete])
    useEffect(() => {
        // setAccrualFrequency('Monthly')
        // setCalculationPeriodStart(Moment.utc().subtract(1, 'months').startOf('month'));
        // setCalculationPeriodEnd(Moment.utc().subtract(1, 'months').endOf('month'));
        var date = new Date();
        setCreatedOn(Moment(date).format('MM-DD-YYYY'));


    }, []);
    // useEffect(() => {
    //     if (!contractCustomisationUpdation.current) {
    //         {
    //             if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
    //                 props.dropdownData.records.map((item) => {
    //                     if (item.field_id === 'contract_type') {
    //                         setContractTypeArray(item);
    //                     }
    //                     if (item.field_id === 'calculation_method') {
    //                         setCalculationMethodArray(item);
    //                     }
    //                     if (item.field_id === 'accrual_frequency') {
    //                         setAccrualFrequencyArray(item);
    //                     }
    //                 })
    //             }
    //         }
    //     }
    // });
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {

            setContractTypeArray(props.dropdownData.field_label_attributes.contract_type);
            setContractGroupArray(props.dropdownData.field_label_attributes.contract_group);
            setContractSubGroupArray(props.dropdownData.field_label_attributes.contract_sub_group);
            setCalculationMethodArray(props.dropdownData.field_label_attributes.calculation_method);
            setAccrualFrequencyArray(props.dropdownData.field_label_attributes.accrual_frequency)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    function handleTags(newValue) {
        setExternalPostingDocumentNumber(newValue)
    }


    const typeRef = useRef(null);
    const subTypeRef = useRef(null);
    const afRef = useRef(null);
    const postingDateRef = useRef(null);
    const handleClear = () => {
        setContractNumber('');
        // typeRef.current.reset();
        // subTypeRef.current.reset();
        // postingDateRef.current.reset();
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setCreatedOn(null);
        setPostingDate(null);
        setAccrualFrequency('');
        setAccrualFrequency('');
        setRadioGroupValue('');
        setContractGroup('');
        setContractSubGroup('');
        setContractType('');
        setCalculationMethod('');
        setMasterValue([]);
        setExternalPostingDocumentNumber([]);
        // afRef.current.reset();
    }
    const handleClickOpen = () => {
        if (calculationPeriodStart <= calculationPeriodEnd) {
            if (createdOn) {
                setOpen(true);
            }
        }
        else {
            toast.error('Please Enter a Valid Calculation Period!');
        }
    };
    // const handleClickOpen2 = () => {
    //     if (contractNumber.length > 0) {
    //         if (calculationPeriodEnd && calculationPeriodStart) {
    //             setOpen2(true)
    //         } else {
    //             if (!calculationPeriodEnd)
    //                 setCalculationPeriodEnd(false);
    //             if (!calculationPeriodStart)
    //                 setCalculationPeriodStart(false);
    //         }
    //     }
    //     else if (calculationPeriodEnd && calculationPeriodStart
    //         // && calculationMethod && contractType 
    //         && postingDate)
    //         setOpen2(true);
    //     else {
    //         toast.error('Please fill all the required fields.');
    //         if (!calculationPeriodEnd)
    //             setCalculationPeriodEnd(false);
    //         if (!calculationPeriodStart)
    //             setCalculationPeriodStart(false);
    //         if (!calculationMethod)
    //             setCalculationMethod(false);
    //         if (!contractType)
    //             setContractType(false);
    //         if (!postingDate)
    //             setPostingDate(false);
    //     }
    // };
    var formData = {
        // "application_type": sessionStorage.getItem('application'),
        "created_on": createdOn ? Moment(createdOn).format('MM-DD-YYYY') : '',
        "contract_number": contractNumber,
        "contract_type": contractType,
        "contract_group": contractGroup,
        "contract_sub_group": contractSubGroup,
        "calculation_method": calculationMethod,
        // "calculation_period_start": calculationPeriodStart ? Moment.utc(calculationPeriodStart) : '',
        // "calculation_period_end": calculationPeriodEnd ? Moment.utc(calculationPeriodEnd) : '',
        "posting_date": postingDate ? Moment(postingDate).format('MM-DD-YYYY') : '',
        // "accrual_type": radioGroupValue,
        "external_posting_document_number": externalPostingDocumentNumber
    };
    const format2 = {
        "app_type": sessionStorage.getItem('application'),
        "batch_job_type": "Accrual Postings",
        "batch_job_name": batchJobName,
        "planned_date_type": "static",
        "planned_start_date": Moment.utc(new Date()).add(1, 'minute').format('YYYY-MM-DDT') + timeStamp[1],
        "planned_start_calendar": "",
        "planned_start_time": Moment.tz(timezoneOffset).add(1, 'minute').format('HH:mm:00'),
        "frequency": "One Time",
        "batch_filter_criteria": [
            {
                "filters": {
                    ...formData, "calculation_date_type": 'static',
                    'calculation_start_date': calculationPeriodStart ? Moment.utc(calculationPeriodStart) : '',
                    'calculation_end_date': calculationPeriodEnd ? Moment.utc(calculationPeriodEnd) : '',
                    'posting_type': radioGroupValue
                }
            }]
    }

    const format3 = {
        "filters": {
            ...formData,

            'calculation_start_date': calculationPeriodStart ? Moment.utc(calculationPeriodStart) : '',
            'calculation_end_date': calculationPeriodEnd ? Moment.utc(calculationPeriodEnd) : '',

        }

    }



    useEffect(() => {
        setFormat3(format3)
    }, [
        formData['contract_number'],
        formData['contract_type'],
        formData['contract_group'],
        formData['contract_sub_group'],
        formData['calculation_method'],
        formData['posting_date'],
        formData['external_posting_document_number'],
        formData['created_on'],
        formData['calculation_period_start'],
        formData['calculation_period_end'],
        formData['accrual_type'],
        formData['eligibility_rules'],
        formData['calculation_date_type'],
        formData['calculation_start_date'],
        formData['calculation_end_date'],
        externalPostingDocumentNumber
    ])

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            sessionStorage.setItem('page', 'Accrual');
            // formData['contract_number'] = contractNumber.map(Number)
            if (masterValue && masterValue.length > 0) {
                formData['eligibility_rules'] = [
                    {
                        "option": "EQUAL",
                        "effective_start_date": calculationPeriodStart ? Moment.utc(calculationPeriodStart) : '',
                        "effective_end_date": calculationPeriodEnd ? Moment.utc(calculationPeriodEnd) : '',
                        "enabled": true,
                        "type": "Both",
                        "sequence": 0,
                        "attribute_key_values": [{
                            "attribute_name": sessionStorage.getItem("application") === 'Customer Rebate' ? "customer_number" : 'supplier_number',
                            "attribute_value": masterValue,
                            "attribute_type": "Attribute"
                        }]
                    }
                ];
            }
            if (open) {
                props.onSubmit(formData);
            }
        } else {
            setOpen(false);
        }
    }

    function handleCalculationPeriodStart(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodStart(newValue);
            if (accrualFrequency === 'Monthly') {
                if (Moment(newValue).startOf('month').format('MM/DD/YYYY') === Moment(newValue).format('MM/DD/YYYY')) {
                    setErrorMessage('')
                    setCalculationPeriodEnd(Moment(newValue).endOf('month'))
                    setStartValid(true)

                }
                else {
                    setErrorMessage("Date should be starting of a Month. For Eg:" + Moment(newValue).startOf('month').format('MM/DD/YYYY'))
                    setStartValid(false)
                }


            }
        }
        else {
            setCalculationPeriodStart(newValue);
            if (!newValue)
                setCalculationPeriodEnd(null)
            setErrorMessage('')
            setStartValid(false)
        }


    }
    function handleCalculationPeriodEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodEnd(newValue);
            if (accrualFrequency === 'Monthly') {
                if (Moment(calculationPeriodStart).endOf('month').format('MM/DD/YYYY') === Moment(newValue).format('MM/DD/YYYY')) {
                    setEndErrorMessage('')
                    setEndValid(true)
                }
                else {
                    setEndErrorMessage("Date should be ending of a current start Month. For Eg: " + Moment(calculationPeriodStart).endOf('month').format('MM/DD/YYYY'))
                    setEndValid(false)
                }
            }
        }
        else {
            setCalculationPeriodEnd(newValue)
            setEndErrorMessage('')
            setEndValid(false)
        }
    }
    function handleContractNumber(e, value) {

        let newSelected = value;
        if (value.length > 0) {
            setSelected(newSelected)
            setContractNumber(newSelected);
        }
        else {
            setSelected([])
            setContractNumber([]);
        }

    }
    function handleContractType(newValue) {
        setContractType(newValue);
    }
    function handleContractGroup(newValue) {
        setContractGroup(newValue);
    }
    function handleContractSubGroup(newValue) {
        setContractSubGroup(newValue);
    }
    function handleCalculationMethod(newValue) {
        setCalculationMethod(newValue.target.value);
    }
    function handlePostingDate(newValue) {

        if (newValue && newValue.isValid()) {
            setPostingDate(Moment(newValue));
            if ((Moment(newValue).format('MM/DD/YYYY')) < (Moment(minimumDate).format('MM/DD/YYYY')) ||
                (Moment(newValue).format('MM/DD/YYYY') > Moment(maximumDate).format('MM/DD/YYYY')))
                setPostValid(false)
            else
                setPostValid(true)
        }
        else {
            setPostValid(false)
            setPostingDate(newValue);
        }
    }
    function handleCreatedOnDate(newValue) {

        if (newValue && newValue.isValid()) {
            setCreatedOn(Moment(newValue));
        }
        else {
            setCreatedOn(newValue);
        }
    }
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    function handleAccrualFrequency(newValue) {
        setAccrualFrequency(newValue.target.value);
        if (newValue.target.value === 'Daily') {
            setCalculationPeriodStart(Moment());
            setCalculationPeriodEnd(Moment());
        } else if (newValue.target.value === 'Weekly') {
            setCalculationPeriodStart(Moment().startOf('week').format('MM/DD/YYYY'));
            setCalculationPeriodEnd(Moment().endOf('week').format('MM/DD/YYYY'));
        } else if (newValue.target.value === 'Monthly') {
            setCalculationPeriodStart(Moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'));
            setCalculationPeriodEnd(Moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY'));
        }
        handlePostingDate(minimumDate)
    }
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    const handleBatchJobName = (e) => {
        setBatchJobName(e.target.value);
    }
    function handleBatchOnSubmit() {
        setOpen2(false)
        setBatchJobName('');
        delete format2.batch_filter_criteria[0].filters['calculation_period_start'];
        delete format2.batch_filter_criteria[0].filters['calculation_period_end'];
        delete format2.batch_filter_criteria[0].filters['accrual_type'];
        props.onSubmitBatchJob(format2)
    }
    function handleCancelDialog() {
        setOpen2(false)
        setBatchJobName('');
    }
    function handleContractNumberAC(value) {
        let newSelected = value;
        if (value.length > 0) {
            setSelected(newSelected);
            setContractNumber(newSelected);
        } else {
            setSelected([]);
            setContractNumber('');
        }
    }
    function handleMasterValue(value) {
        setMasterValue(value)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 22 }}>
                        {props.heading ? props.heading : 'TLPs'}
                    </Typography>
                </div>
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container
                        >
                            <div style={{
                                padding: "0px 10px 20px 10px", width: "100%"
                            }}>
                                <div className={classes.titleSetting}
                                >
                                    <Typography classes={{ root: classes.fontSetting1 }} variant="h2" color="secondary" style={{ marginLeft: "20px" }}  >
                                        Financial Posting Data
                                    </Typography>
                                </div>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    cclasses={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "4px", marginLeft: "10px" }}>
                                        <div style={{ width: '40%', marginTop: "-3px" }}>
                                            <FormLabel className={classes.chargeBackLabel} required={props.creaded_on ? props.creaded_on : ""}>
                                                Created on
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginLeft: "-6px" }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '99%'
                                                            }
                                                        }}
                                                        value={createdOn}
                                                        onChange={handleCreatedOnDate}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        minDate={minimumDate ? minimumDate : ''}
                                                        maxDate={maximumDate ? maximumDate : ''}
                                                        format="MM/DD/YYYY"
                                                        minDateMessage={'Date should not be before ' + Moment(minimumDate).format('MM/DD/YYYY')}
                                                        maxDateMessage={'Date should not be after ' + Moment(maximumDate).format('MM/DD/YYYY')}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ marginTop: 3 }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "-5px" }}>
                                            <FormLabel className={classes.chargeBackLabel}>
                                                External Posting Document Number
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "5px" }}>
                                            <ChipInput data={externalPostingDocumentNumber} onChange={handleTags} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    cclasses={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "7px", marginLeft: "10px" }}>
                                        <div style={{ width: '40%', marginTop: "-3px" }}>
                                            <FormLabel className={classes.chargeBackLabel} >
                                                Posting Date
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginLeft: "-6px" }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '99%'
                                                            }
                                                        }}
                                                        value={postingDate}
                                                        onChange={handlePostingDate}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        minDate={minimumDate ? minimumDate : ''}
                                                        maxDate={maximumDate ? maximumDate : ''}
                                                        format="MM/DD/YYYY"
                                                        minDateMessage={'Date should not be before ' + Moment(minimumDate).format('MM/DD/YYYY')}
                                                        maxDateMessage={'Date should not be after ' + Moment(maximumDate).format('MM/DD/YYYY')}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "14px" }}>
                                        <div style={{ width: '40%', marginTop: "-7px" }}>
                                            <FormLabel className={classes.chargeBackLabel}>
                                                Calculation Period
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-12px", display: 'flex' }}>
                                            {/* <div> */}
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                // padding: '0px 11px 0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: "95%",
                                                                marginLeft: -11,
                                                            }
                                                        }}
                                                        value={calculationPeriodStart}
                                                        onChange={handleCalculationPeriodStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            {/* <FormLabel style={{ display: errorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                                    {errorMessage}
                                                </FormLabel> */}
                                            {/* </div> */}
                                            {/* <div> */}
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                // padding: '0px 11px 0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: "95%",
                                                                marginRight: -11
                                                            }
                                                        }}
                                                        value={calculationPeriodEnd}
                                                        onChange={handleCalculationPeriodEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            {/* <FormLabel style={{ display: endErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                                    {endErrorMessage}
                                                </FormLabel> */}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </Grid>

                            </div>
                        </Grid>
                    </form>
                </Card>
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container
                        >
                            <div style={{
                                padding: "0px  10px 20px 10px", width: "100%"
                            }}>
                                <div className={classes.titleSetting}>
                                    <div style={{ alignItems: 'center' }}>
                                        <Typography classes={{ root: classes.fontSetting1 }} variant="h2" color="secondary" style={{ marginLeft: "20px" }} >
                                            Contracts Data
                                            {/* <span style={{ color: 'red', marginLeft: '2px' }}>*</span> */}
                                        </Typography>
                                    </div>
                                </div>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "8px" }}>
                                            <FormLabel
                                            >
                                                Contract Number
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '61.7%', marginRight: "-5px", marginTop: "-8px" }}>
                                            <SearchDropDownMasterDataNew id='contract_number_multiple'
                                                // multiple
                                                prevalue={contractNumber}
                                                // required={calculationMethod ? false : true}
                                                table='contract_header' keyName='contract_number'
                                                description={true}
                                                // heading='Contract Number'
                                                onChange={handleContractNumberAC}
                                                applicationType={application_type ? application_type : sessionStorage.getItem('application')}
                                            />
                                        </div>
                                    </div>

                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                {
                                                    contractTypeArray && contractTypeArray.current
                                                        ? contractTypeArray.current
                                                        : "Contract Type"
                                                }
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-9px" }}>
                                            <DropdownArray classes={{ root: classes.fontSetting }}
                                                // required={contractNumber && contractNumber.length > 0 ? false : true}
                                                // heading={contractTypeArray && contractTypeArray.current ? contractTypeArray.current : 'Contract Type'}
                                                defaultValue={contractType}
                                                data={contractTypeArray && contractTypeArray.drop_down_values ? contractTypeArray.drop_down_values : []}
                                                placeholder={contractTypeArray && contractTypeArray.drop_down_values && contractTypeArray.drop_down_values.length == 0 && '- Please configure contract type -'}
                                                twoline='true' onChange={handleContractType} /*error={typeof calculationPeriodStart === 'boolean' && !calculationPeriodStart ? true : false} ref={startRef}*/ error={typeof contractType === 'boolean' && !contractType ? true : false} ref={typeRef} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{ marginTop: -15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "-12px" }}>
                                            <FormLabel
                                                // required={contractNumber.length > 0 ? false : true}
                                                classes={{ root: classes.fontSetting }}>
                                                {
                                                    contractGroupArray && contractGroupArray.current
                                                        ? contractGroupArray.current
                                                        : "Contract Group"
                                                }
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-20px" }}>
                                            <DropdownArray
                                                classes={{ root: classes.fontSetting }}
                                                defaultValue={contractGroup}
                                                data={
                                                    contractGroupArray && contractGroupArray.drop_down_values
                                                        ? contractGroupArray.drop_down_values
                                                        : []
                                                }
                                                placeholder={
                                                    contractGroupArray && contractGroupArray.drop_down_values &&
                                                    contractGroupArray.drop_down_values.length == 0 &&
                                                    "- Please configure contract type -"
                                                }
                                                twoline="true"
                                                onChange={handleContractGroup}
                                                error={
                                                    typeof contractGroup === "boolean" && !contractGroup
                                                        ? true
                                                        : false
                                                }
                                                ref={typeRef}

                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                //style={{ marginTop: -15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "-12px" }}>
                                            <FormLabel
                                                // required={contractNumber.length > 0 ? false : true}
                                                classes={{ root: classes.fontSetting }}>
                                                {
                                                    contractSubGroupArray && contractSubGroupArray.current
                                                        ? contractSubGroupArray.current
                                                        : "Contract Subgroup"
                                                }
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-20px" }}>
                                            <DropdownArray
                                                classes={{ root: classes.fontSetting }}
                                                defaultValue={contractSubGroup}
                                                data={
                                                    contractSubGroupArray && contractSubGroupArray.drop_down_values
                                                        ? contractSubGroupArray.drop_down_values
                                                        : []
                                                }
                                                placeholder={
                                                    contractSubGroupArray && contractSubGroupArray.drop_down_values &&
                                                    contractSubGroupArray.drop_down_values.length == 0 &&
                                                    "- Please configure contract type -"
                                                }
                                                twoline="true"
                                                onChange={handleContractSubGroup}
                                                error={
                                                    typeof contractSubGroup === "boolean" && !contractSubGroup
                                                        ? true
                                                        : false
                                                }
                                                ref={typeRef}

                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: "4px" }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "-12px" }}>
                                            <FormLabel className={classes.chargeBackLabel} >
                                                {calculationMethodArray && calculationMethodArray.current ? calculationMethodArray.current : 'Calculation Method'}
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-19px" }}>
                                            <Select
                                                onChange={handleCalculationMethod}
                                                value={calculationMethod}
                                                displayEmpty
                                                className={classes.select}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                MenuProps={{ classes: { paper: classes.menuPaper }, disableScrollLock: true }}
                                            >

                                                <MenuItem value="" style={{ height: 30 }}>
                                                </MenuItem>

                                                {calculationMethodArray && calculationMethodArray.drop_down_reference_value && calculationMethodArray.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} name={item.key}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </form>
                </Card>

                {/* <Grid
                    item
                    md={12}
                    xs={12}
                    classes={{ root: classes.gridContainer }}
                    style={{ marginTop: "1.5rem", marginLeft: "1rem" }}
                >
                    <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                        <FormControlLabel
                            value="Accrual"
                            control={<Radio color="primary" />}
                            label={<Typography classes={{ root: classes.fontSetting }} > Accrual </Typography>}
                            classes={{ labelPlacementStart: classes.radioLabel }}
                        />
                        <FormControlLabel
                            value="Accrual Reversal"
                            control={<Radio color="primary" />}
                            label={<Typography classes={{ root: classes.fontSetting }} > Accrual Reversal </Typography>}
                            classes={{ labelPlacementStart: classes.radioLabel }}
                        />
                    </RadioGroup>
                </Grid> */}
                {button_hidden ? null :
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                            Clear
                        </Button>
                        <Button
                            disabled={!createdOn}
                            variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                            {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run Online'
                            }
                        </Button>
                        {/* <Button
                        variant="contained" color="primary" className={classes.button} onClick={handleClickOpen2} twoline='true'
                        style={{ width: 170 }}
                        disabled={
                            (contractNumber && contractNumber.length > 0) || calculationMethod || contractType || contractGroup || contractSubGroup
                                ? !(postValid && startValid && endValid && radioGroupValue)
                                : true
                        }
                    >
                        {
                            props.loading ?
                                <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                :
                                'Schedule Batch Job'
                        }
                    </Button> */}
                    </div>
                }
                {/* </div>


                </form > */}
                <SimpleDialog open={open} content='Do you want to Run TLP?' handleDialog={handleDialog} />
            </div >
            <Dialog
                onClose={() => setOpen2(false)}
                aria-labelledby="simple-dialog-title"
                open={open2}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Batch Job Name
                    </FormLabel>
                    <OutlinedInput
                        value={batchJobName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleBatchJobName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleBatchOnSubmit} disabled={batchJobName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runTLP(formData)),
        onSubmitBatchJob: (data) => dispatch(addBatchJobSetup(data)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        onCheckContractsTable: () => dispatch(getContractsTableDataList()),
        onCheckpostingDate: () => dispatch(checkingPostingDate()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),

    }
}


const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        // dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        contractTabledataComplete: state.addContractData.contractAllListData,
        checkPostingDate: state.financialCloseData.checkpostingDate,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TlpForm);