import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography, Breadcrumbs, Link, Grid, OutlinedInput
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        padding: 20
    },
    treeFont: {
        font: '13px',
        color: '#0D1333',
        padding: '10px',
        height:35,
        width:window.screen.width * .55,
    },
    nodeContainer: {
        padding: 20, 
        width: '100%',
        overflowY: 'scroll ',
        flex:1,
        height:'100%'

    }

}));

const MembershipHierarchy = props => {
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [treeData, setTreeData] = React.useState([]);
    const memberOwner=props.location.data
    

    useEffect(() => {
        var data = []
        var treeValue=[]
        if (props.membershipData)
            Object.entries(props.membershipData).map((item, index) => {
                if (item[1].children) {
                    data.push({ 'customer_id': item[0], 'name': item[1].name, 'children': item[1]['children'][0] })
                }
                else{
                    data.push({ 'customer_id': item[0],'name': item[1].name, })
                }
                    
                
            })
            if(data.length>0)
              treeValue.push({'customer_id':memberOwner.membership_owner_id,'name':memberOwner.membership_owner_name,'children':data})
        setTreeData([...treeValue])
    }, [props.membershipData]);

    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/membership')}
                >
                    Membership
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Hierarchy</Typography>
            </Breadcrumbs>
            <Grid container
                style={{ paddingTop: 20 }}>
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ height: '100vh' }}
                >

                    {treeData && treeData.length>0  ?
                            <div className={classes.nodeContainer}>
                                <SortableTree
                                    treeData={toggleExpandedForAll({
                                        treeData: treeData,
                                        expanded: true
                                    })}
                                    style={{ marginTop: 10, align: 'center',height:10,width:'100%' }}
                                    
                                    isVirtualized={false}
                                    generateNodeProps={({ node, path }) => ({
                                        title: (
                                            <div style={{ width: '100%' }}>
                                                <OutlinedInput
                                                    style={{ width: '100px' }}
                                                    className={classes.treeFont}
                                                    value={node.customer_id}
                                                    readOnly
                                                />
                                                <OutlinedInput
                                                    className={classes.treeFont}
                                                    value={node.name}
                                                    readOnly
                                                />
                                            </div>
                                        )
                                    })}
                                />
                            </div>
                        :
                        <Typography style={{ marginTop: 20 }} variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </Grid>
            </Grid>

        </div>


    );
};

const mapStateToProps = state => {
    return {
        membershipData: state.customerData.membershipHierarchyData
    }
};



export default connect(mapStateToProps, null)(MembershipHierarchy);
