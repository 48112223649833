import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card, CardHeader, Grid, OutlinedInput, FormLabel, TextField, Select, MenuItem, Tooltip
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { TextInputWithLabel, DropdownArray, AllOtherAttributeMasterComponent, SearchDropDownMasterDataNew, DropdownKeyValue } from '../../../../../components/Inputs';
import { connect } from 'react-redux';
import { getContractDetailsData, getEmployeeData, getSupplierMasterData, getCustomerMasterData } from '../../../../../redux/actions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        //border: '1px solid #EEEEEE',
        //borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        // padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 36,
        paddingTop: 15
    },
    inputTwoLineOutlined: {
        //marginTop: 7,
        width: '100%',
        paddingLeft: 10,
        height: 36,
    },
    borderRad0: {
        borderRadius: '0px !important',
        padding: '3px 6px !important'

    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2.5rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));
const Payment = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();
    const [paymentFrequencyData, setPaymentFrequencyData] = React.useState([]);
    const [paymentTermsData, setPaymentTermsData] = React.useState([]);
    const [paymentFrequencyName, setPaymentFrequencyName] = React.useState('');
    const [paymentLevelData, setPaymentLevelData] = React.useState([]);
    const [paymentLevelName, setPaymentLevelName] = React.useState('');
    const [paymentMethodData, setPaymentMethodData] = React.useState([]);
    const [paymentMethodName, setPaymentMethodName] = React.useState('');
    const [paymentPartnerRoleData, setPaymentPartnerRoleData] = React.useState([]);
    const [paymentPartnerRoleName, setPaymentPartnerRoleName] = React.useState('');
    const [paymentCalendarArray, setPaymentCalendarArray] = React.useState([]);
    const [paymentTermsArray, setPaymentTermsArray] = React.useState([]);
    const [paymentPartnerArray, setPaymentPartnerArray] = React.useState([]);
    const [externalReferenceArray, setExternalReferenceArray] = React.useState([]);
    const [externalReference, setExternalReference] = React.useState('');
    const [flexFields5Array, setFlexFields5Array] = React.useState([]);
    const [paymentAggregationLevel, setPaymentAggregationLevel] = React.useState('');
    const [paymentAggregationLevelArray, setPaymentAggregationLevelArray] = React.useState([]);
    const [paymentAggregationLevelData, setPaymentAggregationLevelData] = React.useState([]);
    const [customerNameArray, setCustomerNameArray] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [paymentMethodArray, setPaymentMethodArray] = React.useState([]);
    const [passThroughPaymentPartnerRoleArray, setPassThroughPaymentPartnerRoleArray] = React.useState([]);
    const [passThroughPercentageArray, setPassThroughPercentageArray] = React.useState([]);
    const [passThrough, setPassThrough] = React.useState('');
    const [passThroughPercentage, setPassThroughPercentage] = React.useState('');
    const [passThroughPaymentPartnerRole, setPassThroughPaymentPartnerRole] = React.useState('');
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    const [paymentDueDate, setPaymentDueDate] = React.useState('');
    const [paymentDueDateName, setPaymentDueDateName] = React.useState('');

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNameArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.employeeMasterData.records.map((item) => {
                tempArray.push(item.employee_number);
                tempNameArray.push(item.employee_name);
            })
            setCustomerNameArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.employeeMasterData])
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setCustomerNameArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.supplierMasterData])

    useEffect(() => {
        setExternalReference(props.externalReference);
    }, [props.externalReference])
    useEffect(() => {
        props.onChange(paymentValues, mandatroyFields);

    });
    const classes = useStyles();
    const { className } = props;
    const [paymentFrequency, setPaymentFrequency] = React.useState('');
    const [paymentLevel, setPaymentLevel] = React.useState('');
    const [paymentTerms, setPaymentTerms] = React.useState('');
    const [paymentCalendar, setPaymentCalendar] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [paymentPartnerRole, setPaymentPartnerRole] = React.useState('');
    const [paymentPartner, setPaymentPartner] = React.useState('');
    const [flexFields5, setFlexFields5] = React.useState('');
    const [paymentDocumentType, setPaymentDocumentType] = React.useState('');
    const [paymentRun, setPaymentRun] = React.useState('');
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const [listActive, setListActive] = React.useState(false);
    const paymentFrequencyRef = useRef(null);
    const paymentLevelRef = useRef(null);
    const paymentTermsRef = useRef(null);
    const paymentCalenderRef = useRef(null);
    const paymentMethodRef = useRef(null);
    const paymentPartnerRoleRef = useRef(null);
    const paymentPartnerRef = useRef(null);
    //var mode = localStorage.getItem('mode');
    var paymentValues = {
        payment_frequency: paymentFrequency,
        payment_level: paymentLevel,
        payment_terms: paymentTerms,
        payment_calendar: paymentCalendar,
        payment_method: paymentMethod,
        payment_partner_role: paymentPartnerRole,
        payment_partner: paymentLevel === 'Individual Payment' ? '' : paymentPartner,
        external_reference: externalReference,
        flex_fields_5: flexFields5,
        payment_document_type: paymentDocumentType,
        payment_run: paymentRun,
        payment_aggregation_level: paymentAggregationLevel,
        pass_through: passThrough,
        pass_through_percentage: parseInt(passThroughPercentage),
        pass_through_payment_partner_role: passThroughPaymentPartnerRole,
        payment_due_date: paymentDueDate ? parseInt(paymentDueDate) : 0
    };
    const [paymentDataListofFields, setPaymentDataListofFields] = React.useState(
        [

            'payment_level',
            'payment_frequency',
            'payment_calendar',
            'payment_method',
            'payment_terms',
            'payment_due_date',
            'external_reference',
            'flex_fields_5',
            'pass_through',
            'pass_through_percentage',
            'pass_through_payment_partner_role',
            'payment_document_type',
            'payment_run',
            // 'payment_partner',
        ]
    )
    const [mandatroyFields, setManddatoryFields] = React.useState([]);
    function handlePaymentLevel(newValue) {
        setPaymentLevel(newValue.target.value);
        // handlePaymentPartnerRole('');
        // setPaymentPartner('');
        // handlePaymentAggregationLevel('');
        if (newValue.target.value === 'Aggregated Payment') {
            // if (!paymentDataListofFields.includes('payment_partner')) {
            setPaymentDataListofFields(prevFields => ['payment_partner_role', 'payment_partner', ...prevFields]);
            setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_aggregation_level'))

            setPaymentDataListofFields(prevFields => prevFields.filter(item => item !== 'payment_aggregation_level'));
            // }
        }

        if (newValue.target.value === 'Individual Payment') {
            // if (!paymentDataListofFields.includes('payment_partner_role')) {
            setPaymentDataListofFields(prevFields => ['payment_partner_role', ...prevFields]);
            setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_aggregation_level'))
            setPaymentDataListofFields(prevFields => prevFields.filter(item => !['payment_partner', 'payment_aggregation_level'].includes(item)));
            // }
        }

        if (newValue.target.value === 'Flexible Payment') {
            // setPaymentDataListofFields(prevFields => ['payment_partner_role', 'payment_partner', ...prevFields]);
            // setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_partner_role'))

            // setPaymentDataListofFields(prevFields => prevFields.filter(item => !['payment_partner', 'payment_partner_role'].includes(item)));
            // if (!paymentDataListofFields.includes('payment_aggregation_level')) {
            // setPaymentDataListofFields(prevFields => ['payment_aggregation_level', ...prevFields]);


            // setPaymentDataListofFields(prevFields => prevFields.filter(item => !['payment_partner', 'payment_partner_role'].includes(item)));
            // }
        }

    }
    useEffect(() => {
        let tempArray = []
        if (props.fieldData && Object.values(props.fieldData) && Object.values(props.fieldData).length > 0) {
            Object.values(props.fieldData)
                .filter(item => paymentDataListofFields.includes(item.key))
                .map(item => {
                    if (item.mandatory) {
                        tempArray.push(item.key)
                    }
                })
        }
        setManddatoryFields(tempArray)
    }, [props.fieldData, paymentDataListofFields]);
    // useImperativeHandle(ref, () => ({
    //     validationCheck() {
    //         if (mode === 'edit')
    //             props.getcontractdetails(localStorage.getItem('currentAddContractID'));
    //         if (!paymentFrequencyRef.current.value || paymentFrequencyRef.current.value === '')
    //             setPaymentFrequency(false);
    //         if (!paymentLevelRef.current.value || paymentLevelRef.current.value === '')
    //             setPaymentLevel(false);
    //         if (!paymentTermsRef.current.value || paymentTermsRef.current.value === '')
    //             setPaymentTerms(false);
    //         if (!paymentCalenderRef.current.value || paymentCalenderRef.current.value === '')
    //             setPaymentCalendar(false);
    //         if (!paymentMethodRef.current.value || paymentMethodRef.current.value === '')
    //             setPaymentMethod(false);
    //         if (!paymentPartnerRoleRef.current.value || paymentPartnerRoleRef.current.value === '')
    //             setPaymentPartnerRole(false);
    //         if (!paymentPartnerRef.current.value || paymentPartnerRef.current.value === '')
    //             setPaymentPartner(false);
    //     },
    //     getValues() {
    //         paymentValues = {
    //             payment_frequency: paymentFrequencyRef.current.value,
    //             payment_level: paymentLevelRef.current.value,
    //             payment_terms: paymentTermsRef.current.value,
    //             payment_calendar: paymentCalenderRef.current.value,
    //             payment_method: paymentMethodRef.current.value,
    //             payment_partner_role: paymentPartnerRoleRef.current.value,
    //             payment_partner: paymentPartnerRef.current.value
    //         };
    //         return paymentValues;
    //     }
    // }));
    useEffect(() => {
        if (props.activeDropdown)
            setActiveDropdown(props.activeDropdown)
    }, [props.activeDropdown])
    function handlePaymentFrequency(e) {
        setPaymentFrequency(e);
    }

    function handlePaymentTerms(e) {
        setPaymentTerms(e);
    }
    function handlePaymentCalendar(newValue) {
        setPaymentCalendar(newValue);
    }
    function handlePaymentMethod(newValue) {
        setPaymentMethod(newValue);
    }
    function handlePaymentPartnerRole(newValue) {
        setPaymentPartner('');
        setCustomerNameArray([])
        setPaymentPartnerRole(newValue);
    }
    useEffect(() => {
        if (props.fieldData?.payment_method?.drop_down_reference_value) {
            if (paymentPartnerRole == 'Supplier Number') {
                setPaymentMethodArray(props.fieldData['payment_method']['drop_down_reference_value'].filter(item => item.key == 'EFT' || item.key == 'Check'))
            }
            else if (paymentPartnerRole == 'Customer Number') {
                setPaymentMethodArray(props.fieldData['payment_method']['drop_down_reference_value'].filter(item => item.key.includes('Credit Memo')))
            }
            else {
                setPaymentMethodArray(props.fieldData['payment_method']['drop_down_reference_value'])
            }
        }
    }, [paymentPartnerRole, props.fieldData])
    function handlePaymentPartnerRoleFlexiable(newValue) {
        setPaymentPartnerRole(newValue);
        setPaymentPartner('')
    }

    function handlePaymentPartnerFlexiable(e) {
        setPaymentPartner(e);

    }
    function handlePaymentAggregationLevel(newValue) {
        setPaymentAggregationLevel(newValue)
    }
    //new fields
    function handleExternalReference(e) {
        setExternalReference(e.target.value);
    }
    function handleFlexFields5(newValue) {
        setFlexFields5(newValue);
    }
    function handlePaymentDocumentType(newValue) {
        setPaymentDocumentType(newValue);
    }
    function handlePaymentRun(newValue) {
        setPaymentRun(newValue);
    }
    function handlePaymentDueDate(newValue) {
        setPaymentDueDate(newValue);
        // props.onChange('payment_due_date', newValue);
    }
    //handledefault
    useEffect(() => {
        if (props.contractDefaultData) {
            setPaymentFrequency(props.contractDefaultData.payment_frequency);
            setPaymentCalendar(props.contractDefaultData.payment_calendar);
            setPaymentMethod(props.contractDefaultData.payment_method);
            setPaymentPartnerRole(props.contractDefaultData.payment_partner_role);
            setPaymentTerms(props.contractDefaultData.payment_terms);
            setPaymentDueDate(props.contractDefaultData.payment_due_date)
            setPaymentAggregationLevel(props.contractDefaultData.payment_aggregation_level)
            if (props.contractDefaultData && props.contractDefaultData.payment_partner_role && activeDropdown) {
                if (props.contractDefaultData && props.contractDefaultData.payment_partner_role
                    && props.contractDefaultData.payment_partner_role === 'Supplier' && activeDropdown['supplier_number']) {
                    props.getSupplierMasterData();
                } else if (props.contractDefaultData && props.contractDefaultData.payment_partner_role && props.contractDefaultData.payment_partner_role === 'Employee'
                    && activeDropdown['employee_number']) {
                    props.getEmployeeData();
                } else {
                    if (activeDropdown['customer_number'])
                        props.getCustomerMasterData();
                }
            }

        } else {
            setPaymentFrequency('');
            setPaymentCalendar('');
            setPaymentMethod('');
            setPaymentPartnerRole('');
            setPaymentTerms('');
        }

    }, [props.contractDefaultData]);
    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNameArray.indexOf(option)] + option
    });

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-2rem' }}
                >
                    <CardHeader
                        title="PAYMENT"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1rem' }}>
                        <Grid container >
                            {props.fieldData && props.fieldData['payment_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                ><Tooltip arrow placement="top" title={<h3 //style={{ color: "lightblue" }}
                                >Controls how payments are made to customers e.g., Aggregated payment is made for entire contract, Individual payments are made for each customer using same payment method and Flexible payments are made for different payment methods.</h3>}
                                >
                                        <FormLabel required={props.fieldData && props.fieldData['payment_level'] ? props.fieldData['payment_level']['mandatory'] : false}>
                                            {props.fieldData['payment_level']['current']}
                                        </FormLabel>
                                    </Tooltip>
                                    <Select
                                        disableUnderline
                                        value={paymentLevel}
                                        onChange={handlePaymentLevel}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ marginTop: "5px" }}
                                    >
                                        {props.fieldData['payment_level']['drop_down_reference_value'] && props.fieldData['payment_level']['drop_down_reference_value']
                                            .map((item) => {
                                                return (
                                                    <MenuItem
                                                        value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                        {item.key}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                            }
                            {/* {!(paymentLevel === 'Aggregated Payment') && !(paymentLevel === 'Individual Payment') && !(paymentLevel === 'Flexible Payment') && props.fieldData && props.fieldData['payment_aggregation_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_aggregation_level']['current']} data={props.fieldData['payment_aggregation_level']['drop_down_reference_value']}
                                        onChange={handlePaymentAggregationLevel} required={props.fieldData && props.fieldData['payment_aggregation_level'] ? props.fieldData['payment_aggregation_level']['mandatory'] : false}
                                        twoline="true" placeholder={' '} labelFormat={'desc'}
                                        prevalue={paymentAggregationLevel}
                                    />
                                </Grid>
                            } */}
                            {!(paymentLevel === 'Aggregated Payment') && !(paymentLevel === 'Individual Payment') && !(paymentLevel === 'Flexible Payment') && props.fieldData && props.fieldData['payment_aggregation_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_partner_role']['current']} data={props.fieldData['payment_partner_role']['drop_down_reference_value']} onChange={handlePaymentPartnerRole}
                                        required twoline="true" placeholder={' '} error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false} ref={paymentPartnerRoleRef}
                                        prevalue={paymentPartnerRole}
                                        labelFormat={'desc'}
                                    />
                                </Grid>
                            }
                            {(paymentLevel === 'Flexible Payment') && props.fieldData && props.fieldData['payment_partner_role'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_partner_role']['current']} data={props.fieldData['payment_partner_role']['drop_down_reference_value']} onChange={handlePaymentPartnerRoleFlexiable}
                                        twoline="true" placeholder={' '} error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false} ref={paymentPartnerRoleRef}
                                        prevalue={paymentPartnerRole}
                                        labelFormat={'desc'}
                                        required={false}
                                    />
                                </Grid>
                            }
                            {(paymentLevel === 'Individual Payment') && props.fieldData && props.fieldData['payment_partner_role'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_partner_role']['current']} data={props.fieldData['payment_partner_role']['drop_down_reference_value']} onChange={handlePaymentPartnerRole}
                                        twoline="true" placeholder={' '} error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false} ref={paymentPartnerRoleRef}
                                        prevalue={paymentPartnerRole}
                                        labelFormat={'desc'}
                                        required={props.fieldData && props.fieldData['payment_partner_role'] ? props.fieldData['payment_partner_role']['mandatory'] : false}
                                    />
                                </Grid>
                            }
                            {(paymentLevel === 'Aggregated Payment') && props.fieldData && props.fieldData['payment_partner_role'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_partner_role']['current']} data={props.fieldData['payment_partner_role']['drop_down_reference_value']} onChange={handlePaymentPartnerRole}
                                        twoline="true" placeholder={' '} error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false} ref={paymentPartnerRoleRef}
                                        prevalue={paymentPartnerRole}
                                        labelFormat={'desc'}
                                        required={props.fieldData && props.fieldData['payment_partner_role'] ? props.fieldData['payment_partner_role']['mandatory'] : false}
                                    />
                                </Grid>
                            }
                            {(paymentLevel === 'Flexible Payment') && props.fieldData && props.fieldData['payment_partner'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel style={{ marginTop: -2 }} required={false}>
                                        {props.fieldData['payment_partner']['current']}
                                    </FormLabel>
                                    <div style={{ marginTop: '-10px' }}>
                                        <SearchDropDownMasterDataNew id='payment_single'
                                            table={paymentPartnerRole == 'Supplier Number' ? 'supplier_master' : paymentPartnerRole == 'Employee Number' ? 'employee_master' : paymentPartnerRole == 'Customer Number' ? 'customer_master' : ''}
                                            keyName={paymentPartnerRole == 'Supplier Number' ? 'supplier_number' : paymentPartnerRole == 'Employee Number' ? 'employee_number' : paymentPartnerRole == 'Customer Number' ? 'customer_number' : ''}
                                            description={true}
                                            onChange={(value) => handlePaymentPartnerFlexiable(value)}
                                            prevalue={paymentPartner}
                                        />
                                    </div>
                                </Grid>
                            }
                            {paymentPartnerRole && !(paymentLevel === 'Individual Payment') && !(paymentLevel === 'Flexible Payment') && props.fieldData && props.fieldData['payment_partner'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel style={{ marginTop: -2 }} required={props.fieldData && props.fieldData['payment_partner'] ? props.fieldData['payment_partner']['mandatory'] : false} >
                                        {props.fieldData['payment_partner']['current']}
                                    </FormLabel>
                                    <div style={{ marginTop: '-10px' }}>
                                        <SearchDropDownMasterDataNew id='payment_single'
                                            table={paymentPartnerRole == 'Supplier Number' ? 'supplier_master' : paymentPartnerRole == 'Employee Number' ? 'employee_master' : 'customer_master'}
                                            keyName={paymentPartnerRole == 'Supplier Number' ? 'supplier_number' : paymentPartnerRole == 'Employee Number' ? 'employee_number' : 'customer_number'}
                                            description={true}
                                            onChange={(value) => setPaymentPartner(value)}
                                            prevalue={paymentPartner}
                                        />
                                    </div>

                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_frequency'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div>
                                        <DropdownKeyValue heading={props.fieldData['payment_frequency']['current']} data={props.fieldData['payment_frequency']['drop_down_reference_value']}
                                            onChange={handlePaymentFrequency} required={props.fieldData && props.fieldData['payment_frequency'] ? props.fieldData['payment_frequency']['mandatory'] : false} twoline="true" placeholder={' '} labelFormat={'desc'}
                                            prevalue={props.contractDefaultData && props.contractDefaultData.payment_frequency ? props.contractDefaultData.payment_frequency : ''}
                                        />
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_calendar'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray heading={props.fieldData['payment_calendar']['current']} data={props.fieldData['payment_calendar']['drop_down_values']}
                                        twoline='true' onChange={handlePaymentCalendar}
                                        required={props.fieldData && props.fieldData['payment_calendar'] ? props.fieldData['payment_calendar']['mandatory'] : false}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.payment_calendar ? props.contractDefaultData.payment_calendar : ''}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_method'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_method']['current']}
                                        data={paymentMethodArray}
                                        onChange={handlePaymentMethod} required={props.fieldData && props.fieldData['payment_method'] ? props.fieldData['payment_method']['mandatory'] : false} placeholder={' '} twoline='true'
                                        error={typeof paymentMethod === 'boolean' && !paymentMethod ? true : false} ref={paymentMethodRef}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.payment_method ? props.contractDefaultData.payment_method : ''} labelFormat={'desc'}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_terms'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '15px 0px 5px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel required={props.fieldData && props.fieldData['payment_terms'] ? props.fieldData['payment_terms']['mandatory'] : false} >
                                            {props.fieldData['payment_terms']['current']}
                                        </FormLabel>
                                        <div style={{ marginTop: -10 }}>
                                            <AllOtherAttributeMasterComponent attributeName={'payment_terms'}
                                                value={paymentTerms} onChange={(e) => handlePaymentTerms(e)}
                                                prevalue={paymentTerms} withOutLabel={true} id={'payment_terms'}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_due_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={props.fieldData['payment_due_date']['current']} twoline="true"
                                        mandatory={props.fieldData && props.fieldData['payment_due_date'] ? props.fieldData['payment_due_date']['mandatory'] : false}
                                        onChange={handlePaymentDueDate}
                                        inputProps={{ min: 0 }}
                                        onKeyPress={(event) => {
                                            if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                            }
                                        }} type={'Number'} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_reference'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel required={props.fieldData && props.fieldData['external_reference'] ? props.fieldData['external_reference']['mandatory'] : false} >
                                        {props.fieldData['external_reference']['current']}
                                    </FormLabel>
                                    <OutlinedInput
                                        classes={{ root: classes.inputTwoLineOutlined }}
                                        onChange={handleExternalReference}
                                        value={externalReference}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_5'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_5']['drop_down_reference_value'] && props.fieldData['flex_fields_5']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel required={props.fieldData && props.fieldData['flex_fields_5'] ? props.fieldData['flex_fields_5']['mandatory'] : false} >
                                                {props.fieldData['flex_fields_5']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields5}
                                                onChange={(e) => setFlexFields5(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_5']['drop_down_reference_value'] && props.fieldData['flex_fields_5']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel mandatory={props.fieldData && props.fieldData['flex_fields_5'] ? props.fieldData['flex_fields_5']['mandatory'] : false} heading={props.fieldData['flex_fields_5']['current']} twoline="true" onChange={handleFlexFields5} />}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['pass_through']['current']}
                                        required={props.fieldData && props.fieldData['pass_through'] ? props.fieldData['pass_through']['mandatory'] : false}
                                        data={props.fieldData['pass_through']['drop_down_reference_value']} labelFormat={'desc'}
                                        onChange={(e) => setPassThrough(e)} twoline="true" placeholder={' '} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through_percentage'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <FormLabel required={props.fieldData && props.fieldData['pass_through_percentage'] ? props.fieldData['pass_through_percentage']['mandatory'] : false} >
                                        {props.fieldData['pass_through_percentage']['current']}
                                    </FormLabel>
                                    <OutlinedInput
                                        type={'number'}
                                        classes={{ root: classes.inputTwoLineOutlined }}
                                        onChange={(e) => setPassThroughPercentage(e.target.value)}
                                        value={passThroughPercentage}
                                        inputProps={{ min: 1 }}
                                        onKeyPress={(event) => {
                                            if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through_payment_partner_role'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['pass_through_payment_partner_role']['current']}
                                        required={props.fieldData && props.fieldData['pass_through_payment_partner_role'] ? props.fieldData['pass_through_payment_partner_role']['mandatory'] : false}
                                        data={props.fieldData['pass_through_payment_partner_role']['drop_down_reference_value']} labelFormat={'desc'}
                                        onChange={(e) => setPassThroughPaymentPartnerRole(e)} twoline="true" placeholder={' '} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_document_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['payment_document_type']['drop_down_reference_value'] && props.fieldData['payment_document_type']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel required={props.fieldData && props.fieldData['payment_document_type'] ? props.fieldData['payment_document_type']['mandatory'] : false} >
                                                {props.fieldData['payment_document_type']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={paymentDocumentType}
                                                onChange={(e) => setPaymentDocumentType(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['payment_document_type']['drop_down_reference_value'] && props.fieldData['payment_document_type']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel mandatory={props.fieldData && props.fieldData['payment_document_type'] ? props.fieldData['payment_document_type']['mandatory'] : false} heading={props.fieldData['payment_document_type']['current']} twoline="true" onChange={handlePaymentDocumentType} />}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_run'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['payment_run']['drop_down_reference_value'] && props.fieldData['payment_run']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel required={props.fieldData && props.fieldData['payment_run'] ? props.fieldData['payment_run']['mandatory'] : false} >
                                                {props.fieldData['payment_run']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={paymentRun}
                                                onChange={(e) => setPaymentRun(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['payment_run']['drop_down_reference_value'] && props.fieldData['payment_run']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel mandatory={props.fieldData && props.fieldData['payment_run'] ? props.fieldData['payment_run']['mandatory'] : false} heading={props.fieldData['payment_run']['current']} twoline="true" onChange={handlePaymentRun} />}
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    customerMasterData: state.customerData.customerMasterData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    employeeMasterData: state.customerData.employeeMasterData,
    supplierMasterData: state.customerData.supplierMasterData,
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData()),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getCustomerMasterData: (pagination, limit) => dispatch(getCustomerMasterData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Payment);