import React from 'react';
import { SvgIcon } from '@mui/material';

const CalculationIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M14.167 16H1.833A1.835 1.835 0 010 14.167V1.833C0 .822.822 0 1.833 0h12.334C15.178 0 16 .822 16 1.833v12.334A1.835 1.835 0 0114.167 16zM1.833 1A.835.835 0 001 1.833v12.334c0 .459.374.833.833.833h12.334c.46 0 .833-.374.833-.833V1.833A.835.835 0 0014.167 1H1.833z" fill='#B4BDE9'  className={props.className}/>
    <path d="M8 16a.5.5 0 01-.5-.5V.5a.5.5 0 011 0v15a.5.5 0 01-.5.5z" fill='#B4BDE9'  className={props.className}/>
    <path d="M15.5 8.5H.5a.5.5 0 010-1h15a.5.5 0 010 1zM4 6a.5.5 0 01-.5-.5V3.167a.5.5 0 011 0V5.5A.5.5 0 014 6z" fill='#B4BDE9'  className={props.className} />
    <path d="M5.167 4.833H2.833a.5.5 0 010-1h2.334a.5.5 0 010 1zM3.167 13.333a.5.5 0 01-.353-.854l1.666-1.666a.5.5 0 11.707.707L3.52 13.187a.498.498 0 01-.353.146z" fill='#B4BDE9'  className={props.className} />
    <path d="M4.833 13.333a.497.497 0 01-.353-.146L2.813 11.52a.5.5 0 11.708-.707l1.666 1.667a.5.5 0 01-.354.853zM13.5 4.667h-3a.5.5 0 010-1h3a.5.5 0 010 1zM13.5 13.334h-3a.5.5 0 010-1h3a.5.5 0 010 1zM13.5 11.334h-3a.5.5 0 010-1h3a.5.5 0 010 1z" fill='#B4BDE9'  className={props.className} />
  </SvgIcon>
)
export default CalculationIcon;
