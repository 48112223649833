import React, { useEffect, forwardRef } from 'react';
import {
    Grid, Typography, Card,
    Table, TableCell, TableHead, TableRow,
    TableContainer, TableBody, CardHeader,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import { getEligibilityRulesTableData, getDefaultValuesAllDataNew, editEligibilityRules, getDefaultValues, getDefaultValuesAllData, getContractsTableDataList } from '../../../../redux/actions';
import Moment from 'moment';
import momentt from 'moment-timezone';

momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26,

    },
    container2: {
        padding: 10,

    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const ContractDependency = forwardRef((props, ref) => {

    const classes = useStyles();
    const { className } = props;
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    const [dependencyStore, setDependencyStore] = React.useState([]);



    useEffect(() => {
        if (props.data) {
            if (props.data.contract_dependency_rules && props.data.contract_dependency_rules.length > 0)
                setDependencyStore(props.data.contract_dependency_rules);
        }
    }, [props.data]);




    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);




    return (
        <div>
            <div className={clsx(classes.root, className)}>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="CONTRACT DEPENDENCY"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {dependencyStore && dependencyStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}> {props.fieldData && props.fieldData.adjustment_period}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>Adjustment Contract Number</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                    {props.fieldData && props.fieldData.adjustment_driver}
                                                </TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} > {props.fieldData && props.fieldData.adjusted_driver} </TableCell>
                                                <TableCell align='center'>Start Date </TableCell>
                                                <TableCell align='center'>End Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {dependencyStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={item.ID}>
                                                        <TableCell align='center'>{item.adjustment_period}</TableCell>
                                                        <TableCell align='center'>{item.adjustment_contract_number}</TableCell>
                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.adjustment_driver && item.adjustment_driver.replace(/_/g, ' ')}</TableCell>
                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.adjusted_driver && item.adjusted_driver.replace(/_/g, ' ')}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>

                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
            {/* Eligibility Rules Table Ends here */}
        </div>
    );

});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingLabelDesignerNew: () => dispatch(getDefaultValuesAllDataNew()),
    }
}

const mapStateToProps = state => {
    return {
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,


    }
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ContractDependency);
