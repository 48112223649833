import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Paper, Input, Button, useMediaQuery, Fab, Typography } from '@mui/material';
import { Search, Check, AddBoxOutlined, ContactSupportOutlined, Visibility, CloudUpload, LibraryAdd, Update } from '@mui/icons-material';
import { connect } from 'react-redux';
import { addContractGeneralData, getCustomerMasterXRefData, updateMembershipData } from '../../../redux/actions';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0
  },
  searchInput: {
    marginRight: 47
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  rootSearch: {
    borderBottomColor: theme.palette.table.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    zIndex: 1
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const MembershipToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [searchText, setSearchText] = React.useState('');
  const addContract = () => {
    localStorage.removeItem('currentAddContractID');
    sessionStorage.setItem('mode', 'add');
  }
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  }
  const updateMembership = () => {
    props.updateMembershipData()
  }

  return (
    <div
      {...rest}
      className={classes.root}
    >
      {/* <div className={clsx({ [classes.searchInput]: isDesktop })}>
        <Paper
          {...rest}
          className={clsx(classes.rootSearch, className)}
        >
          <Search className={classes.icon} />
          <Input
            {...rest}
            className={classes.input}
            disableUnderline
            placeholder="Search"
            onChange={handleSearchOnChange}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="Close"
            onClick={() => props.onSearch(searchText)}>
            <Check className={classes.icon} />
          </IconButton>
        </Paper>
      </div> */}
      {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_membership-' + sessionStorage.getItem('application')) &&
        <>
          <Fab aria-label="edit" variant="extended"
            size='medium'
            classes={{ root: classes.fabContainer }}
            className={classes.fabContainer}
            component={CustomRouterLink}
            to='/membership/add-membership'
          >
            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
          </Fab>

          <Fab aria-label="edit" variant="extended"
            size='medium'
            className={classes.fabContainer}
            component={CustomRouterLink}
            to={{ pathname: '/membership/membership-excel-upload', state: 'MembershipDataFormats' }}
            onClick={addContract}
          >
            <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
          </Fab>
        </>
      }
      {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_membership-' + sessionStorage.getItem('application')) &&
        <>
          <Fab aria-label="edit" variant="extended"
            size='medium'
            classes={{ root: classes.fabContainer }}
            className={classes.fabContainer}
            component={CustomRouterLink}
            to='/mass-membership-update'
          >
            <Update color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Mass Update</Typography>
          </Fab>
          <Fab aria-label="edit" variant="extended"
            size='medium'
            className={classes.fabContainer}
            // component={CustomRouterLink}
            onClick={updateMembership}
          >
            <Update color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Auto Update</Typography>
          </Fab>
        </>
      }
      {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_membership-' + sessionStorage.getItem('application')) &&
        <>
          <Fab aria-label="edit" variant="extended"
            size='medium'
            classes={{ root: classes.fabContainer }}
            className={classes.fabContainer}
            component={CustomRouterLink}
            to={{ pathname: '/master-report' }}
            onClick={addContract}
          >
            <Visibility color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Master Report</Typography>
          </Fab>
          <Fab aria-label="edit" variant="extended"
            size='medium'
            className={classes.fabContainer}
            component={CustomRouterLink}
            to='/membership-by-filter'
            onClick={addContract}
          >
            <Visibility color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Member view</Typography>
          </Fab>
        </>
      }

      {/* <Button
        variant="contained"
        color="primary"
        // startIcon={<AddBoxOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to={{ pathname: '/membership', state: 'MembershipGPOView' }}
        onClick={addContract}
        style={{ marginLeft: 10 }}
      >
        GPO View
      </Button> */}
      {/* <Button
        variant="contained"
        color="primary"
        startIcon={<Visibility />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        // to='/membership-by-member' //direct display by member
        to='/membership-by-filter'
        onClick={addContract}
        style={{ marginLeft: 10 }}
      >
        Member view
      </Button> */}
      {/* <Button
        variant="contained"
        color="primary"
        // startIcon={<AddBoxOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to={{pathname:'/membership/membership-excel-upload',state:'MembershipGPOTierActivationFormats'}}
        onClick={addContract}
        style={{ marginLeft: 10 }}
      >
        Tier Activation
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ContactSupportOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to={{pathname:'/dynamic-query',state:'membership-dynamic-query'}}
        onClick={addContract}
        style={{ marginLeft: 10 }}
      >
        Queries
      </Button> */}
    </div >
  );

};

const mapDispatchToProps = dispatch => {
  return {
    onSearch: (keyword) => dispatch(getCustomerMasterXRefData(0, 100, keyword)),
    updateMembershipData: () => dispatch(updateMembershipData())
  }
}

export default connect(null, mapDispatchToProps)(MembershipToolbar);