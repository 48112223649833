import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    Link, Fab
} from '@mui/material';
import { CloudUpload, LibraryAdd, Visibility } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    runDynamicQuery, getCustomerMasterQuery, getCustomerMasterOrgQueryList,
    fetchSalesInvoiceQuerySingle, customerMasterUpload,
    getCustomerMasterQueryList, deleteMasterQuery, getAllForecastLevelData, createForecastLevel
} from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        border: 0,
        fontSize: 14,
        paddingRight: 10
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));

const ForecastingConfigMain = props => {
    useEffect(() => {
        props.getAllForecastLevelData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    function editQuery(id) {
        history.push({
            pathname: '/forecasting-config/edit-config/' + id,
            id: id
        });
    }
    function deleteQuery(id) {
        props.onDelete(id);
    }
    function handleItemClick(id) {
        history.push({
            pathname: '/forecasting-config/view-config/' + id,
            id: id
        });
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>
                    Forecasting Config</Typography>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_forecast_config-' + sessionStorage.getItem('application')) &&
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => history.push('/forecasting-config/add-config')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                    // <Button
                    //     variant="contained"
                    //     color="primary"
                    //     startIcon={<AddBoxOutlinedIcon />}
                    //     classes={{
                    //         startIcon: !isDesktop && classes.startIcon,
                    //         root: !isDesktop && classes.container
                    //     }}
                    //     onClick={() => history.push('/forecasting-config/add-config')}
                    // >
                    //     {isDesktop ? 'Add Config' : ''}
                    // </Button> }
                }
            </div>
            {props.queryListData && props.queryListData.length > 0 ?
                <TableContainer style={{ marginTop: -20 }}>
                    <Table>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Forecast Level</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Forecast Level Description</TableCell>
                                <TableCell align='center' className={classes.tabHead}>AI Model Name</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>

                            {props.queryListData
                                .sort(function (a, b) {
                                    return a.ID < b.ID ? 1 : -1;
                                })
                                .map(item => {

                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => handleItemClick(item.ID)} style={{ cursor: 'pointer' }}>
                                                {item.forecasting_level}
                                            </TableCell>
                                            <TableCell align="center" classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>
                                                {item.forecasting_level_description}
                                            </TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>
                                                {item.ai_algorithm}
                                            </TableCell>
                                            {props.loading ?
                                                <TableCell width={300} align='center'>
                                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                </TableCell>
                                                :
                                                <TableCell align='center' width={300}>
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_forecast_config-' + sessionStorage.getItem('application')) &&
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => handleItemClick(item.ID)}
                                                            size="large">
                                                            <Visibility color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>}
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_forecast_config-' + sessionStorage.getItem('application')) &&
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item.ID)}
                                                            size="large">
                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>}
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_forecast_config-' + sessionStorage.getItem('application')) &&
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => deleteQuery(item.ID)}
                                                            size="large">
                                                            <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>}
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography variant='h4'>
                    No invoice query list added.
                </Typography>
            }

        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.forecastData.foreCastLevelData,
        loading: state.forecastData.loading,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllForecastLevelData: () => dispatch(getAllForecastLevelData()),
        onDelete: (id) => dispatch(createForecastLevel(null, id, 'delete')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastingConfigMain);