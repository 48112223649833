import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Button, Typography, Grid } from '@mui/material';
import ChipInput from 'material-ui-chip-input';
import { getSalesBasisData, postSalesBasisData, updateSalesBasisData } from '../../../redux/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '0px 40px 40px 40px'
    },
    container: {
        paddingTop: 55
    },
    submitContainer: {
        marginRight: -25,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 35,
        paddingBottom: 28
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 40,
        padding: 0
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginRight: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    }
}));

const SetupSalesBasisTab = props => {
    const classes = useStyles();
    const { className } = props;
    const [billingType, setBillingType] = React.useState([]);
    const [itemCategory, setItemCategory] = React.useState([]);
    const [eligible, setEligible] = React.useState([]);
    useEffect(() => {
        props.getSalesBais();
    }, []);
    function handleBillingType(newValue) {
        setBillingType(newValue);
    }
    function handleItemCategory(newValue) {
        setItemCategory(newValue);
    }
    function handleEligible(newValue) {
        setEligible(newValue);
    }
    var formData = {
        "billing_type": billingType,
        "item_category": itemCategory,
        "eligible": eligible
    }
    const onSubmit = () => {
        if (props.salesData &&  props.salesData.ID) {
            props.onUpdate(formData, props.salesData.ID);
        } else {
            props.onPost(formData);
        }
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div>
                <Grid
                    item
                    md={6}
                    sm={12}
                    className={classes.container}
                >
                    <Typography variant="h4"> Billing Type </Typography>
                    <ChipInput
                        classes={{
                            root: classes.rootContainer,
                            chip: classes.chip,
                            input: classes.input,
                            inputRoot: classes.inputRoot,
                            chipContainer: classes.chipContainer,
                            label: classes.chipLabel
                        }}
                        defaultValue={props.salesData ? props.salesData.billing_type : ''}
                        onChange={(chips) => handleBillingType(chips)}
                        disableUnderline={true}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    sm={12}
                    className={classes.container}
                >
                    <Typography variant="h4"> Item Category </Typography>
                    <ChipInput
                        classes={{
                            root: classes.rootContainer,
                            chip: classes.chip,
                            input: classes.input,
                            inputRoot: classes.inputRoot,
                            chipContainer: classes.chipContainer,
                            label: classes.chipLabel
                        }}
                        defaultValue={props.salesData ? props.salesData.item_category : ''}
                        onChange={(chips) => handleItemCategory(chips)}
                        disableUnderline={true}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    sm={12}
                    className={classes.container}
                >
                    <Typography variant="h4"> Eligible </Typography>
                    <ChipInput
                        classes={{
                            root: classes.rootContainer,
                            chip: classes.chip,
                            input: classes.input,
                            inputRoot: classes.inputRoot,
                            chipContainer: classes.chipContainer,
                            label: classes.chipLabel
                        }}
                        defaultValue={props.salesData ? props.salesData.eligible : ''}
                        onChange={(chips) => handleEligible(chips)}
                        disableUnderline={true}
                    />
                </Grid>
            </div>
            <div className={classes.submitContainer}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={onSubmit}>
                    Save
                </Button>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        salesData: state.addMultipleConfigurationData.salesBasisData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSalesBais: () => dispatch(getSalesBasisData()),
        onPost: (formData) => dispatch(postSalesBasisData(formData)),
        onUpdate: (formData, id) => dispatch(updateSalesBasisData(formData, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupSalesBasisTab);