import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";

const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [
    { 'Dashboard': '/dashboard' },
    {
        'Contracts': [
            { 'Contract Setup': '/contract-setup' },
            { 'Contract Approval': '/approval-reject' },
            { 'Expiring Contracts': '/expiring-contracts' },
            { 'Expired Contracts': '/expired-contracts' },
            { "Document Management": "/documents" },
        ]
    },
    {
        'Claims Management': [
            { 'Manage Single Claim': '/supplier-incoming-claims' },
            { 'Manage Multiple Claims': '/supplier-multiple-claims' },
            { 'Claim Status': '/supplierChargeback-claim-status' }
        ]
    },
    {
        'Claims Provision': [
            { 'Accrual Postings': '/financial-postings' },
        ]
    },
    {
        'Payments': [
            { 'Payment Postings': '/supplier-payment-postings' },
            { 'Payment Approvals': '/payment-approvals' },
        ]
    },
    {
        'Reporting & Analytics': [
            { 'Operational Reports': '/select-operational-reports' },
            { 'On Demand Queries': '/dynamic-query' },
            { "On Demand Analytics": "/on-demand-analytics" },
            { "Predefined Queries": "/predefined-query" },
            { "Dashboard": '/rebate-analytics' },
            { "Predefined Analytics": "/analytics-main" },
        ]
    },
    {
        'Utilities': [
            { 'Batch Job': '/batchjob-setup' },
            { 'Batch Job Status': '/batchjob-status' },
            { "On Demand Videos": "/help-desk-cp" },
        ]
    },
    {
        'Configuration': [
            { 'Claim Designer': '/claim-configuration' },
            { 'Rejection Reasons': '/rejection-reasons' },
        ]
    },
]

const SupplierChargeback = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', fontSize: '16px', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierChargeback);