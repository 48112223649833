import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs,
    Typography,
    Link,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    Checkbox,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { batchApproveWorkflow, getContractsTableData, getWorkFlowAnchors, newAPIForAccRe, getWorkflowRequests, changeRequestDetails, getApprovalGroupData, getContractViewData, downloadSheet, batchApprove } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import { getContractDetailsData } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { DataGrid } from '@material-ui/data-grid';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CompareIcon from '@mui/icons-material/Compare';
import { RingLoader } from 'react-spinners';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        cursor: 'pointer'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '-1rem'
    },
    caption: {
        fontSize: 12
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    head: {
        backgroundColor: 'white',
        position: 'fixed',
        top: 1,
        zIndex: 1,
        width: 'calc(100% - 17)',
        height: 31,
    },
    checkbox: {
        color: 'white'

    },
    spinner: {
        // height: '100vh'
    },
}));

const ContractSetupTable = props => {
    useEffect(() => {
        props.getWorkFlowAnchors();
        props.getApprovalGroupData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [selectedApproved, setSelectedApproved] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [workflowRequestsAccrual, setWorkflowRequestsAccrual] = React.useState([])
    const [selectedContract, setSelectedContract] = React.useState({});
    const [isAllSelected, setAllSeleted] = React.useState(false);
    const approveContract = () => {
        props.batchApprove(selected, true, selectedApproved)
    };
    const rejectContract = () => {
        props.batchApprove(selected, false, selectedApproved)
    };

    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);
    const handleSelect = (event, checked) => {

        let newSelected = [];
        if (!checked) {
            workflowRequestsAccrual.map(item => {
                newSelected = newSelected.concat(selected, item.id);

            })
            setSelected(newSelected);
        }
        else {
            setSelected([])
        }
        setAllSeleted(!isAllSelected)
    }
    const handleClick = (event, name, item, type) => {
        setSelectedContract(item);
        let newSelected = [];
        const selectedIndex = selected.indexOf(name);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        if (isAllSelected)
            setAllSeleted(false)
        if (newSelected.length === workflowRequestsAccrual.length)
            setAllSeleted(true)
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    useEffect(() => {
        if (props.workflowRequestsAccural)
            setWorkflowRequestsAccrual(props.workflowRequestsAccural)
    }, [props.workflowRequestsAccural])
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <LoadingOverlay
                    active={props.loading}
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '50px',
                            '& svg circle': {
                                stroke: '#045FB4'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(52, 52, 52, 0)'
                        }),
                        content: (base) => ({
                            ...base,
                            color: 'black'
                        })
                    }}
                    spinner={<RingLoader size={100} />}
                    className={classes.spinner}
                >

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-1rem' }}>
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <div style={{ marginTop: '1rem' }}><Typography variant="h1" color='primary'> Payment Approval Results</Typography></div>
                            {workflowRequestsAccrual && workflowRequestsAccrual.length > 0 &&
                                <div
                                    className={classes.buttonRoot}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        classes={{
                                            startIcon: !isDesktop && classes.startIcon,
                                            root: !isDesktop && classes.container
                                        }}
                                        onClick={approveContract}
                                        startIcon={<CheckIcon />}
                                        style={{ margin: 10 }}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<ClearIcon />}
                                        classes={{
                                            startIcon: !isDesktop && classes.startIcon,
                                            root: !isDesktop && classes.container
                                        }}
                                        onClick={rejectContract}
                                        style={{ margin: 10, backgroundColor: '#FA5858' }}
                                    >
                                        Reject
                                    </Button>
                                </div >
                            }
                        </div>
                    </div>
                    {workflowRequestsAccrual && workflowRequestsAccrual.length > 0 ?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                className={classes.checkbox}
                                                onChange={(event) => handleSelect(event, isAllSelected)}
                                                checked={isAllSelected}
                                                key='all'
                                                selected={isAllSelected}
                                            />
                                        </TableCell>
                                        <TableCell align='center'>Contract Type</TableCell>
                                        <TableCell align='center'>Contract Number</TableCell>
                                        <TableCell align='center'>Description</TableCell>
                                        <TableCell align='center'>Calculation Start Date</TableCell>
                                        <TableCell align='center'>Calculation End Date</TableCell>
                                        <TableCell align='center'> Step Number</TableCell>
                                        <TableCell align='center'> Step Description</TableCell>
                                        <TableCell align='center'>Approval Group</TableCell>
                                        <TableCell align='center'> Incentive Amount</TableCell>
                                        <TableCell align='center'> Incentive Varience</TableCell>
                                        <TableCell align='center'> Threshold Type</TableCell>
                                        <TableCell align='center'>Threshold Value</TableCell>
                                        <TableCell align='center'>Threshold Unit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }}>
                                    {workflowRequestsAccrual
                                        .sort((a, b) => a.contract_type > b.contract_type ? 1 : -1)
                                        .sort((a, b) => (a.contract_type >= b.contract_type) && (a.contract_number < b.contract_number) ? 1 : -1)
                                        .sort((a, b) => (a.contract_type >= b.contract_type) && (a.contract_number >= b.contract_number) && (a.calculation_start_date < b.calculation_start_date) ? 1 : -1)
                                        .sort((a, b) => (a.contract_type >= b.contract_type) && (a.contract_number >= b.contract_number) && (a.calculation_start_date >= b.calculation_start_date) && (a.calculation_end_date < b.calculation_end_date) ? 1 : -1)
                                        .sort((a, b) => (a.contract_type >= b.contract_type) && (a.contract_number >= b.contract_number) && (a.calculation_start_date >= b.calculation_start_date) && (a.calculation_end_date >= b.calculation_end_date) && (a.step_number > b.step_number) ? 1 : -1)
                                        .map(item => {
                                            const isItemSelected = isSelected(item.id);

                                            return (
                                                <StyledTableRow key={item.contract_number} className={classes.hover}
                                                    hover
                                                    onClick={(event) => handleClick(event, item.id, item)}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color='primary'
                                                            checked={isItemSelected}
                                                            key={item.id}
                                                            selected={isItemSelected}
                                                        />
                                                    </TableCell>
                                                    <TableCell classes={{ root: classes.description }}>{item.contract_type}</TableCell>
                                                    <TableCell align='center' >{item.contract_number} </TableCell>
                                                    <TableCell classes={{ root: classes.description }}>{item.description}</TableCell>
                                                    <TableCell align='center'> {item.calculation_start_date === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item.calculation_start_date).format('MM/DD/YYYY')} </TableCell>
                                                    <TableCell align='center'>{item.calculation_end_date === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item.calculation_end_date).format('MM/DD/YYYY')}</TableCell>
                                                    <TableCell align='center' >{item.step_number} </TableCell>
                                                    <TableCell align='center' >{item.step_description} </TableCell>
                                                    <TableCell align='center' >
                                                        {item.approval_group ?
                                                            (props.approvalGroupData && props.approvalGroupData.records && props.approvalGroupData.records.length > 0) &&
                                                            props.approvalGroupData.records.map((itemA) => {
                                                                if (itemA.ID === item.approval_group) {
                                                                    return itemA.name;
                                                                }
                                                            })
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell align='center' >{parseFloat(item.incentive_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </TableCell>
                                                    <TableCell align='center' >{parseFloat(item.incentive_amount_variance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </TableCell>
                                                    <TableCell classes={{ root: classes.description }} style={{ textTransform: 'capitalize' }}>{item.incentive_threshold_type ? item.incentive_threshold_type.replace(/_/g, ' ') : ''}</TableCell>
                                                    <TableCell align='center' >{parseFloat(item.threshold_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </TableCell>
                                                    <TableCell classes={{ root: classes.description }}>{item.currency}</TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <div style={{ marginTop: 15 }}>
                            <Typography variant='h4'>
                                There is no data to show now.
                            </Typography>
                        </div>
                    }
                </LoadingOverlay>

            </div >
        </div>
    );
};

ContractSetupTable.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = state => {
    return {
        loading: state.customerData.loading,
        workflowRequestsAccural: state.addMultipleConfigurationData.workflowRequestsPayment,
        workflowAnchors: state.customerData.workflowAnchors,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        batchApprove: (items, status) => dispatch(batchApproveWorkflow(items, status, 'Payment')),
        changeRequestDetails: (id, updatedId, type) => dispatch(changeRequestDetails(id, updatedId, type)),
        getWorkflowRequests: (applicationType, functionality) => dispatch(getWorkflowRequests(applicationType, functionality)),
        newAPIForAccRe: (status, bulk, id) => dispatch(newAPIForAccRe(status, bulk, id)),
        getWorkFlowAnchors: () => dispatch(getWorkFlowAnchors()),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSetupTable);