import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getProductGroupSingleItem } from '../../redux/actions';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { Link } from 'react-router-dom';
import * as moment from 'moment'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formLabels: {
        fontSize: theme.typography.h3.fontSize,
    },

}));

const ViewProductGroup = props => {
    const classes = useStyles();
    const [productGroupId, setProductGroupId] = React.useState('');
    const [productGroupName, setProductGroupName] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [materialDescription, setMaterialDescription] = React.useState('');
    const [materialIdType, setMaterialIdType] = React.useState([]);
    const [materialIdNumber, setMaterialIdNumber] = React.useState([]);
    const [primaryManufacturer, setPrimaryManufacturer] = React.useState('');
    const [secondaryManufacturer, setSecondaryManufacturer] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [baseUom, setBaseUom] = React.useState('');
    const [salesUom, setSalesUom] = React.useState('');
    const [chemicalName, setChemicalName] = React.useState('');
    const [brandReference, setBrandReference] = React.useState('');
    const [quantity, setQuantity] = React.useState('');


    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.productGroupSingleItem(appId);
    }, []);
    useEffect(() => {

        if (props.productGroup) {
            setProductGroupId(props.productGroup.product_group_id)
            setProductGroupName(props.productGroup.product_group_name)
            setMaterialNumber(props.productGroup.material_number)
            setMaterialDescription(props.productGroup.material_description)
            setMaterialIdType(props.productGroup.material_id_type)
            setMaterialIdNumber(props.productGroup.material_id_number)
            setPrimaryManufacturer(props.productGroup.primary_manufacturer)
            setSecondaryManufacturer(props.productGroup.secondary_manufacturer)
            setStartDate(props.productGroup.start_date)
            setEndDate(props.productGroup.end_date)
            setBaseUom(props.productGroup.base_uom)
            setSalesUom(props.productGroup.sales_uom)
            setChemicalName(props.productGroup.chemical_name)
            setBrandReference(props.productGroup.brand_reference)
            setQuantity(props.productGroup.quantity)
        }
    }, [props.productGroup])

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/product-group'
                >
                    Product Group
                </Link>
                <Typography color="textPrimary" variant='h4'>View Product</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container spacing={2} style={{ paddingLeft: 10 }}>
                        {productGroupId &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Product Group ID' data={productGroupId} twoline='true' />
                            </Grid>
                        }
                        {productGroupName &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Membership Owner Name' data={productGroupName} twoline='true' />
                            </Grid>
                        }
                        {materialNumber &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Material Number' data={materialNumber} twoline='true' />
                            </Grid>
                        }
                        {materialDescription &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Material Description' data={materialDescription} twoline='true' />
                            </Grid>
                        }
                        {materialIdType && materialIdType != "" ?
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Material ID Type ' data={materialIdType + ''} twoline='true' />
                            </Grid>
                            : ""
                        }
                        {materialIdNumber && materialIdNumber != "" ?
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Material ID Number ' data={materialIdNumber + ''} twoline='true' />
                            </Grid>
                            : ""
                        }
                        {primaryManufacturer &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Primary Manufacturer' data={primaryManufacturer} twoline='true' />
                            </Grid>
                        }
                        {secondaryManufacturer &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Secondary Manufacturer' data={secondaryManufacturer} twoline='true' />
                            </Grid>
                        }
                        {startDate &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Start Date' data={moment.utc(startDate).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {endDate &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='End Date' data={moment.utc(endDate).format('MM/DD/YYYY')} twoline='true' />
                            </Grid>
                        }
                        {baseUom &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Base UoM' data={baseUom} twoline='true' />
                            </Grid>
                        }
                        {salesUom &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Sales UoM' data={salesUom} twoline='true' />
                            </Grid>
                        }
                        {quantity != 0 ?
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Quantity' data={quantity} twoline='true' />
                            </Grid>
                            : ""
                        }

                        {chemicalName &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Chemical Name' data={chemicalName} twoline='true' />
                            </Grid>
                        }
                        {brandReference &&
                            <Grid
                                item
                                md={3}
                                xs={12}

                            >
                                <LabelText classes={{ root: classes.formLabels }} heading='Brand Reference' data={brandReference} twoline='true' />
                            </Grid>
                        }

                    </Grid>

                </div>


            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        productGroupSingleItem: (id) => dispatch(getProductGroupSingleItem(id)),
    }
}

const mapStateToProps = state => {
    return {
        productGroup: state.customerData.productGroupSingleData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProductGroup);