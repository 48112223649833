import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs,Checkbox,
    Link, Card, IconButton, OutlinedInput, FormLabel, MenuItem, Select,
    Dialog, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { createPriceCalForList, getPriceCalForListByName, deletePricingCalcStep } from '../../../redux/actions/Pricing/AuthAction';
import { history } from '../../../components/Helpers';
import { DeleteForever, GetAppSharp, PublishSharp } from '@mui/icons-material';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 10,
        padding: '1px 5px 1px 5px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 5
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: '0px 15px',
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'end',
        paddingTop: 3,
        paddingBottom: 28,
    },
    paper: { minWidth: window.screen.width * .50 },
    select: {
        width: '100%',
        marginTop: 10,
        height: 'webkitFillAvailalble',
        fontSize: theme.typography.h4.fontSize,
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    selectedItem:{
        fontSize: theme.typography.h3.fontSize,
    }
}));
const EditPriceCalculationFormula = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [formulaName, setFormulaName] = React.useState('');
    const [formulaDes, setFormulaDes] = React.useState('');
    const [allFormula, setAllFormula] = React.useState([]);
    const [stepList, setStepList] = React.useState([{ key: 'Step Total 1', 'value': true }, { key: 'Step Total 2', 'value': true }, { key: 'Step Total 3', 'value': true }, { key: 'Step Total 4', 'value': true }, { key: 'Step Total 5', 'value': true }, { key: 'Step Total 6', 'value': true }]);
    const priceTypeList = ['Base Price', 'On-Invoice Discount', 'On-Invoice Freight', 'On-Invoice Surcharge', 'On-Invoice Surcharge', 
                            'Off-Invoice Revenue','Off-Invoice Expense', 'Cost - Direct', 'Cost - Indirect','Transfer Price',
                            "Base Sales Adjustments","Price Elasticity Adjustments","Cost Adjustments"]
    const costPlusList = ['Cost - Direct', 'Cost - Indirect','On-Invoice Surcharge', 'On-Invoice Freight','On-Invoice Discount','Off-Invoice Revenue','Off-Invoice Expense', 'Transfer Price']
    const calculationList = ['Running Total', ...stepList.filter(e => e.value == false).map(e => e.key)]
    const revenuExpenseList=['Revenue','Expense']
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        setOpen(true);
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getPriceCalForListByName(appId);
    }, [])


    function handleOnSubmit() {
        var data = []
        var step = 10
        var apply = 0
        allFormula.map((item, i) => {
            if(i!=0){
                if(item.price_type=='Base Price')
                  step=10
                else
                  step=parseInt(allFormula[i-1]['step'])+10
             }
            allFormula[i]['step'] = step
            if(item.apply_to_step)
            apply=item.apply_to_step
         if (item.step_total) {
             var index = -1
             index = (allFormula.findIndex(({ calculate_based_on }) => calculate_based_on == item.step_total))
             if (index != -1 && allFormula[index]) {
                 if (index > i)
                     allFormula[index]['apply_to_step']= step 
                 else {
                     if (index == i)
                         apply = step
                     else
                         data[index]['apply_to_step'] = step
                 }
             }
         }
            if (item.calculate_based_on) {
                if (item.calculate_based_on == 'Running Total') {
                    apply = step - 10

                }
                else if(item.calculate_based_on=='Base Price'){
                    apply=10
               }
                allFormula[i]['apply_to_step'] = apply
            }
            data.push({ ...allFormula[i] })
        })
        props.onSubmit(data, 'edit')
    }
    function handleDeleteLocally(index,id) {
        var i
        if (allFormula.length > 1) {
            var data = allFormula.filter((item, i) => i === index)
            var updatedData = allFormula.filter((item, i) => i !== index)
            setAllFormula(item => item.filter((item, i) => i !== index))
            if (data && data[0] && data[0].step_total) {
                var stepIndex = stepList.findIndex(({ key }) => key == data[0].step_total)
                setStepList(prevState => {
                    let data = [...prevState];
                    data[stepIndex]['value'] = true
                    return [...data];
                })
                 i = allFormula.findIndex(({ calculate_based_on }) => calculate_based_on == data[0].step_total)
            }
            if(data&&data[0]&&data[0].price_type=='Base Price'){
                if(updatedData.findIndex(({price_type})=>price_type=='Base Price')==-1){
                 i= allFormula.findIndex(({calculate_based_on})=>calculate_based_on=='Base Price')
                }
             }
            if (i > -1) {
                if (allFormula[i]['calculation_type'] == '%')
                    allFormula[i]['calculate_based_on'] = 'Running Total'
                else
                    allFormula[i]['calculate_based_on'] = ''
            }
            props.OnDelete(id,formulaName)
        }
        
    }
    function handleOnMove(index, type) {
        if (type == 'up') {
            setAllFormula(prevState => {
                let data = [...prevState];

                let temp = data[index - 1];
                data[index - 1] = data[index];
                data[index] = temp;
                return [...data];
            })
        }
        else {
            setAllFormula(prevState => {
                let data = [...prevState];

                let temp = data[index + 1];
                data[index + 1] = data[index];
                data[index] = temp;

                return [...data];
            })
        }
    }
    useEffect(() => {

        if (props.formula && props.formula.length > 0) {
            setFormulaName(props.formula[0].formula_name)
            setFormulaDes(props.formula[0].formula_description)
            const sorted = props.formula.sort(function (a, b) {
                if(props.formula[0].pricing_strategy === 'Cost Plus') {
                    return costPlusList.indexOf(a.price_type) - costPlusList.indexOf(b.price_type);
                } else {
                    return priceTypeList.indexOf(a.price_type) - priceTypeList.indexOf(b.price_type);
                }
            });
            stepList.map((item, i) => {
                if (sorted.findIndex(({step_total})=>step_total==item.key)>-1){
                  setStepList(prevState => {
                    let data = [...prevState];
                    data[i]['value'] = false
                    return [...data];
                })
               }
            })
            setStepList(stepList)
            setAllFormula(sorted)
        }
    }, [props.formula]);
    function handleFormulaName(newValue, index) {
        setFormulaName(newValue.target.value)
    }
    function handleFormulaDesc(newValue, index) {
        setFormulaDes(newValue.target.value)
    }
    function handleCancelDialog() {
        history.push('/pricing-calculation-formula');
    }
    function handleOnChange(event, index, type) {
        var prevData=''
        if(type=='formula_priority')
        allFormula[index][type] = parseInt(event.target.value)
        else if(type==='display_enabled')
        allFormula[index][type]=event.target.checked
        else{
            prevData=allFormula[index][type]
            allFormula[index][type] = event.target.value
        }
        if(type=='step_total'){
            if(event.target.value){
              var stepIndex=stepList.findIndex(({key})=>key==event.target.value);
            }
            if(prevData)
              var prevIndex=stepList.findIndex(({key})=>key==prevData);
              
            setStepList(prevState => {
                let data = [...prevState];
                 if(stepIndex>-1)
                 data[stepIndex]['value']=false
                if (prevIndex>-1)
                 data[prevIndex]['value']=true
                return [...data ];
            })
        }
    
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h5' classes={{
                    root: classes.link
                }}
                component="button"
                onClick={()=> {history.push('/pricing-calculation-formula')}}
                >
                    Formula Procedure
                </Link>
                <Typography color="textPrimary" variant='h5'>Edit Formula</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required
                        style={{
                            height: '50px',
                            display: 'flex',
                            fontSize: '13px',
                            alignItems: 'flex-end'
                        }}
                    >
                        Formula Name
                    </FormLabel>
                    <OutlinedInput
                        value={formulaName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleFormulaName} />
                    <FormLabel className={classes.formLabel} required
                        style={{
                            height: '50px',
                            display: 'flex',
                            fontSize: '13px',
                            alignItems: 'flex-end'
                        }}
                    >
                        Formula Description
                    </FormLabel>
                    <OutlinedInput
                        value={formulaDes}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleFormulaDesc} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={formulaName && formulaDes ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >

                                                <TableCell align='center' >Price Type Category</TableCell>
                                                <TableCell align='center' >Price Type </TableCell>
                                                <TableCell align='center' >Price Type Name </TableCell>
                                                <TableCell align='center' >Calculation Type </TableCell>
                                                <TableCell align='center' >Revenue Expense </TableCell>
                                                <TableCell align='center' >Step Total </TableCell>
                                                <TableCell align='center' >Calculate Based On </TableCell>
                                                <TableCell align='center' style={{ width: 70, maxWidth: 70 }}>Priority</TableCell>
                                                <TableCell align='center' style={{ width: 60, maxWidth: 60 }}>Display</TableCell>
                                                <TableCell align='center' >Actions </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {allFormula && allFormula
                                                .map((itemX, index) => {
                                                    return (
                                                        <StyledTableRow >

                                                            <TableCell align='center' >{allFormula[index]['price_type_category']}</TableCell>
                                                            <TableCell align='center' > {allFormula[index]['price_type']}</TableCell>
                                                            <TableCell align='center' > {allFormula[index]['price_type_name']}</TableCell>
                                                            <TableCell align='center' > {allFormula[index]['calculation_type']}</TableCell>
                                                            <TableCell align='center' >
                                                                <Select
                                                                    disableUnderline
                                                                    defaultValue={allFormula[index]['revenue_expense_indicator']}
                                                                    onChange={(e) => handleOnChange(e,index,'revenue_expense_indicator')}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    SelectDisplayProps={{ style: { height: '100%' } }}
                                                                    style={{ textTransform: 'capitalize', margin: 0 }}
                                                                >
                                                                    {revenuExpenseList && revenuExpenseList
                                                                        .map((item,index) => {
                                                                            return (
                                                                                <MenuItem value={item} name={item} style={{ textTransform: 'capitalize' }}>
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                            </TableCell>
                                                            <TableCell align='center' >
                                                                <Select
                                                                    disableUnderline
                                                                    defaultValue={allFormula[index]['step_total']}
                                                                    onChange={(e) => handleOnChange(e, index, 'step_total')}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    disabled={allFormula[index]['price_type'] == 'Base Price' ? true:false}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    SelectDisplayProps={{ style: { height: '100%' } }}
                                                                    style={{ textTransform: 'capitalize', margin: 0 }}
                                                                >
                                                                    <MenuItem value='' style={{ textTransform: 'capitalize' }}>

                                                                    </MenuItem>
                                                                    {stepList && stepList
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <MenuItem value={item.key} name={item.key} style={{ textTransform: 'capitalize' }}
                                                                                    disabled={!stepList[index]['value'] ? true : false}>
                                                                                    {item.key}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                            </TableCell>
                                                            <TableCell align='center' >
                                                                <Select
                                                                    disableUnderline
                                                                    defaultValue={allFormula[index]['calculate_based_on']}
                                                                    onChange={(e) => handleOnChange(e, index, 'calculate_based_on')}
                                                                    displayEmpty
                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                    disabled={allFormula[index]['calculation_type'] == '%' ? false : true}
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    SelectDisplayProps={{ style: { height: '100%' } }}
                                                                    style={{ textTransform: 'capitalize', margin: 0 }}
                                                                >
                                                                    <MenuItem value='' style={{ textTransform: 'capitalize' }}>

                                                                    </MenuItem>
                                                                    {allFormula.findIndex(({price_type})=>price_type=='Base Price')>-1&&
                                                                     <MenuItem value='Base Price' style={{ textTransform: 'capitalize'}}>
                                                                    Base Price
                                                                     </MenuItem>
                                                                    }
                                                                    {calculationList && calculationList
                                                                        .map((item) => {
                                                                            return (
                                                                                <MenuItem value={item} name={item} style={{ textTransform: 'capitalize' }}>
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                            </TableCell>
                                                            <TableCell align='center' >
                                                                <OutlinedInput twoline='true'
                                                                    key={'priority-' + index}
                                                                    type={'number'}
                                                                    id={index}
                                                                    onChange={(e) => handleOnChange(e, index, 'formula_priority')}
                                                                    defaultValue={allFormula[index]['formula_priority']!=0?allFormula[index]['formula_priority']:''}
                                                                    disabled={allFormula[index]['price_type'] == 'Base Price' ? false : true}
                                                                />
                                                            </TableCell>
                                                            <TableCell align='center' > 
                                                            <Checkbox
                                                                  onChange={(e) => handleOnChange(e,index,'display_enabled')}
                                                                color='primary'
                                                                classes={{
                                                                    root: classes.formControlRoot
                                                                }}
                                                                style={{ paddingTop: 10 }}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                            </TableCell>
                                                            <TableCell style={{ display: 'flex' }}>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleDeleteLocally(index,itemX.ID)}
                                                                    size="large">
                                                                    <DeleteForever />
                                                                </IconButton>
                                                                <IconButton
                                                                    style={{ marginRight: 5 }}
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleOnMove(index, 'down')}
                                                                    disabled={index != allFormula.length - 1 ? allFormula[index]['price_type'] == allFormula[index + 1]['price_type'] ? false : true : true}
                                                                    size="large">
                                                                    <GetAppSharp />
                                                                </IconButton>
                                                                <IconButton
                                                                    style={{ padding: 0 }}
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleOnMove(index, 'up')}
                                                                    disabled={index != 0 ? allFormula[index]['price_type'] == allFormula[index - 1]['price_type'] ? false : true : true}
                                                                    size="large">
                                                                    <PublishSharp />
                                                                </IconButton>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type) => dispatch(createPriceCalForList(data, null, type)),
        getPriceCalForListByName: (name) => dispatch(getPriceCalForListByName(name)),
        OnDelete: (id,formulaName) => dispatch(deletePricingCalcStep(id,formulaName))
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.pricingData.loading,
        formula: state.pricingData.priceListCalForDataByName,

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPriceCalculationFormula);


