import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {  CalculationSummaryTab } from './containers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        marginBottom: 23,
        marginTop: 14
    },
    dropdownContainer: {
        padding: 0,
        marginTop: -5,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

const CalculationSummaryReports = props => {
    const classes = useStyles();
    const { className } = props;
    return (          
            <div
                className={clsx(classes.root, className)}>
                <CalculationSummaryTab />
            </div>
    );

};
export default CalculationSummaryReports;