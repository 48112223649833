import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    IconButton, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getCustomerMasterXRefData, addCustomerMasterXRefUser, editCustomerMasterXRefUser, getContractDefaultDesigner, getApprovalGroupData, addContractDefaultDesigner } from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Toolbar1 } from '.';
import { useHistory } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Visibility } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    container: {
        padding: 10
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: -1, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const ContractDefaultDesignerMain = props => {
    useEffect(() => {
        props.getContractDefaultDesigner(1, 100);
        props.getApprovalGroupData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [editMode, setEditMode] = React.useState(false);
    const [currentEditableId, setCurrentEditableId] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [organizationId, setOrganizationId] = React.useState('');
    const [channelPartnerType, setChannelPartnerType] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerEndCustomerId, setChannelPartnerEndCustomerId] = React.useState('');
    const [streetAddress, setStreetAddress] = React.useState('');
    const [state, setState] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    const [city, setCity] = React.useState('');
    const [contractDefaultData, setContractDefaultData] = React.useState([]);
    const [approvalData, setApprovalData] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [deleteItemId, setDeleteItemId] = React.useState(null);
    useEffect(() => {
        if (props.customerMasterXRefData) {
            setContractsTableComplete(props.customerMasterXRefData);
        }
    }, [props.customerMasterXRefData]);
    function editCustomer(id) {
        history.push({
            pathname: '/contract-default-designer/edit-contract-default/' + id,
            id: id
        });
    }
    function viewCustomer(id) {
        history.push({
            pathname: '/contract-default-designer/view-contract-default/' + id,
            id: id
        });
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getContractDefaultDesigner(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        // props.getContractDefaultDesigner(1, event.target.value);
        props.getContractDefaultDesigner(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    function handleItemClick(id) {
        localStorage.setItem('currentAddContractID', id);
        props.getcontractViewdetails(id);
    }
    function handleChannelPartnerType(event) {
        setChannelPartnerType(event.target.value);
    }
    function handleChannelPartnerId(event) {
        setChannelPartnerId(event.target.value);
    }
    function handleChannelPartnerEndCustomerId(event) {
        setChannelPartnerEndCustomerId(event.target.value);
    }
    function handleStreetAddress(event) {
        setStreetAddress(event.target.value);
    }
    function handleState(event) {
        setState(event.target.value);
    }
    function handleZipCode(event) {
        setZipCode(event.target.value);
    }
    function handleCountry(event) {
        setCountry(event.target.value);
    }
    function handleCity(event) {
        setCity(event.target.value);
    }
    function handleSubmit() {
        var data = {
            "organization_id": organizationId,
            "channel_partner_type": channelPartnerType,
            "channel_partner_id": channelPartnerId,
            "channel_partner_end_customer_id": channelPartnerEndCustomerId,
            "street_address": streetAddress,
            "state": state,
            "zipcode": zipCode,
            "country": country,
            "city": city
        };
        setUserStore([...userStore, data]);
        if (editMode) {
            props.onEdit(data, currentEditableId);
            setEditMode(false);
        } else {
            props.onSubmit(data);
        }
        handleClear();
    }
    function handleClear() {
        setEditMode(false);
        setOrganizationId('');
        setChannelPartnerType('');
        setChannelPartnerId('');
        setChannelPartnerEndCustomerId('');
        setStreetAddress('');
        setCity('');
        setState('');
        setZipCode('');
        setCountry('');
    }
    function handleDelete(id) {
        props.onDelete(id);
    }
    useEffect(() => {
        if (props.contractDefaultData) {
            setContractDefaultData(props.contractDefaultData);
        }
    }, [props.contractDefaultData])

    useEffect(() => {
        if (props.approvalData && props.approvalData.records && props.approvalData.records.length > 0)
            setApprovalData(props.approvalData)
    }, [props.approvalData])

    function handleOpenDialog(id) {
        setDeleteItemId(id);
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
        setDeleteItemId(null);
    }

    function handleConfirmDelete() {
        if (deleteItemId) {
            props.deleteContractDefaultDesigner('', 'delete', deleteItemId);
        }
        handleCloseDialog();
    }

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Default Designer</Typography>
                    <div style={{
                        marginTop: "-5px", marginBottom: "-15px"
                    }}>
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_default_designer-' + sessionStorage.getItem('application')) &&
                            <Toolbar1 />}
                    </div>

                </div>
                {contractDefaultData && contractDefaultData.total_record > 0 ?
                    <TableContainer style={{ marginTop: -12 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Contract Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Accrual Level</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Copa Level</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Postings Schema</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Payment Frequency</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Payment Method</TableCell>
                                    {/* <TableCell align='center'className={classes.tabHead}>Approval Group</TableCell> */}
                                    <TableCell align='center' className={classes.tabHead} style={{ width: 62 }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {contractDefaultData.records
                                    .sort((a, b) => a.ID > b.ID ? -1 : 1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.contract_number}>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.app_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.contract_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.accrual_level}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.copa_level}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.posting_schema}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.source_data_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.payment_frequency}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.payment_method}</TableCell>
                                                {/* <TableCell align='center' classes={{root:classes.fontSetting}}  style={{ cursor: 'pointer' }}>
                                                {approvalData && approvalData.records && approvalData.records.length > 0 && approvalData.records.filter(function (v) {
                                                    return v.ID === item.approval_group
                                                })[0] ? 
                                                approvalData && approvalData.records && approvalData.records.length > 0 && approvalData.records.filter(function (v) {
                                                    return v.ID === item.approval_group
                                                })[0].name
                                                :
                                                ''
                                            }
                                                {/* {item.approval_group} */}
                                                {/* </TableCell> */}

                                                <TableCell align='center'>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewCustomer(item.ID)}
                                                            size="large">
                                                            <Visibility color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_default_designer-' + sessionStorage.getItem('application')
                                                        ) && (
                                                                <>
                                                                    <IconButton
                                                                        classes={{ root: classes.IconButton }}
                                                                        onClick={() => editCustomer(item.ID)}
                                                                        size="large">
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        // onClick={() => props.deleteContractDefaultDesigner('', 'delete', item.ID)}
                                                                        onClick={() => handleOpenDialog(item.ID)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                    </div>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            component="div"
                            count={props.contractDefaultData && props.contractDefaultData.records ? props.contractDefaultData.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                        {props.contractDefaultData &&
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                            </div>
                        }
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        contractDefaultData: state.customerData.contractDefaultDesignerGetAll,
        approvalData: state.addMultipleConfigurationData.approvalGroupData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getCustomerMasterXRefData: (pagination, limit) => dispatch(getCustomerMasterXRefData(pagination, limit)),
        onSubmit: (data) => dispatch(addCustomerMasterXRefUser(data)),
        onEdit: (data, id) => dispatch(editCustomerMasterXRefUser(data, id)),
        getContractDefaultDesigner: (pagination, limit) => dispatch(getContractDefaultDesigner(pagination, limit)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
        deleteContractDefaultDesigner: (data, type, id) => dispatch(addContractDefaultDesigner(data, type, id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractDefaultDesignerMain);