import React, { useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TextField,
  OutlinedInput,
  FormLabel,
  Switch,
  Dialog,
  DialogActions,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import {
  getDefaultValuesAllDataNew,
  getCalculationSimulationDataFields,
  getDynamicQueryFields,
  postDynamicQuery,
  getDynamicQuerySalesDataFields,
  getDefaultValuesAllData,
  getPurchaseDataFields,
  loadValuesBasedOnAppTypeAndField,
  getDynamicQueryFieldsNew,
  getDefaultValues,
  getAllApprovalDesigner,
  allowedApps,
  getTableDeterminationData,
} from "../../redux/actions";
import { connect } from "react-redux";
import { history } from "../../components/Helpers";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: 'none',
    border: 0,
    fontSize: 16,
    paddingRight: 10
  },
  container: {
    padding: 10,
  },
  gridContainer: {
    padding: "0px 16px 0px 16px",
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  inputTwoLine: {
    marginTop: 10,
    width: "100%",
    paddingLeft: 10,
  },
  error: {
    border: "1px solid red !important",
  },
  formLabel: {
    fontSize: 13,
    display: "flex",
    alignItems: "flex-end",
    fontSize: theme.typography.h3.fontSize
  },
  deleteIcon: {
    backgroundColor: "red",
    borderRadius: 0,
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 28,
    paddingTop: 30,
  },
  paper: { minWidth: window.screen.width * 0.5 },
  select: {
    width: "100%",
    marginTop: 10,
  },
  selectedItem: {
    color: theme.palette.text.grey,
  },
  center: {
    textAlign: "center",
  },
  tableStyle: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize
  },
}));

const AddDynamicQuery = (props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState("");
  const location = useLocation();

  useEffect(() => {
    // setCurrentPage(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    var [first, second, third] = location.pathname.split("/");
    setCurrentPage(third);
  }, [location]);
  const [disabledItems, setDisabledItems] = React.useState([]);
  const handleInitialSetup = () => {
    var tempObj = {};
    setKeyValuePairObjectAll(apiArrayFieldsConcatinated.sort((a, b) => a.label > b.label ? 1 : -1));
    var dis = [];
    props.formFieldsAllData &&
      props.formFieldsAllData.records.length > 0 &&
      props.formFieldsAllData.records.map((item) => {
        if (item.contract_display === false) {
          dis.push(item.field_id);
        }
      });
    setDisabledItems(dis);
    if (dis.length == 0) {
      setKeyValuePairObject(apiArrayFieldsConcatinated.sort((a, b) => a.label > b.label ? 1 : -1));
    }
    setOpen(false);
  };
  useEffect(() => {
    props.allowedApps();
    props.getTableDeterminationData();
  }, []);

  const [allowedApps, setAllowedApps] = React.useState([]);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps([
        ...props.allowedAppsData,
        "Master Data",
        "Transaction Data",
      ]);
    }
  }, [props.allowedAppsData]);
  useEffect(() => {
    if (disabledItems.length > 0) {
      const filtered = keyValuePairObjectAll
        .filter((item, index) => !disabledItems.includes(item.key))
        .reduce((obj, key) => {
          console.log(key)
          obj[key] = keyValuePairObjectAll[key];
          return obj;
        }, {});
      setKeyValuePairObject(filtered);
    }
  }, [disabledItems]);
  const [open, setOpen] = React.useState(false);
  const [queryName, setQueryName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [bomPartName, setBomPartName] = React.useState("");
  const [tableName, setTableName] = React.useState("");
  const [bomPartNumber, setBomPartNumber] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [uom, setUom] = React.useState("");
  const [incomingRoyalty, setIncomingRoyalty] = React.useState("");
  const [outgoingRoyalty, setOutgoingRoyalty] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [filterFieldsArray, setFilterFieldsArray] = React.useState([
    ...(location.pathname.substring(location.pathname.lastIndexOf("/") + 1) ===
      "add-collaboration-portal"
      ? ["customer_number", "supplier_number"]
      : []),
  ]);
  const [tableDeterminationArray, setTableDeterminationArray] = React.useState(
    []
  );
  const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
  const [averageFieldsArray, setAverageFieldsArray] = React.useState([]);
  const [outputFieldsArray, setOutputFieldsArray] = React.useState([]);
  const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
  const [sortOrderArray, setSortOrderArray] = React.useState([]);
  const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] =
    React.useState([]);
  const [keyValuePairObjectAll, setKeyValuePairObjectAll] = React.useState([]);
  const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
  const [sortCounter, setSortCounter] = React.useState(1);
  const [sortCounterEnabler, setSortCounterEnabler] = React.useState(true);
  const [labelNewaApiData, setLabelNewApiData] = React.useState([]);
  const financialPostingsFields = [
    "Contract Number",
    "Posting Type",
    "Billing Doc Number",
    "Billing Doc Line",
  ];
  const handleQueryName = (e) => {
    setQueryName(e.target.value);
  };
  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (
      props.dynamicQueryFieldsData &&
      props.dynamicQueryFieldsData.numeric_fields
    ) {
      var concatinated = props.dynamicQueryFieldsData.numeric_fields
        .concat(props.dynamicQueryFieldsData.string_fields)
        .concat(props.dynamicQueryFieldsData.date_fields);
      setApiArrayFieldsConcatinated(concatinated);
    }
  }, [props.dynamicQueryFieldsData]);

  useEffect(() => {
    if (props.tableDetermination && props.tableDetermination.length > 0) {
      setTableDeterminationArray(props.tableDetermination);
    }
  }, [props.tableDetermination]);
  useEffect(() => {
    if (props.labelNewAPIData) {
      setLabelNewApiData(props.labelNewAPIData)
    }
  }, [props.labelNewAPIData])
  const [editIndex, setEditIndex] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);
  const [userStore, setUserStore] = React.useState([]);
  const [chartType, setChartType] = React.useState("");
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: 250,
  //       //width: 250
  //     },
  //   },
  // };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
        overflowY: 'scroll',
        scrollBehaviour: ' smooth',
        MarginTop: 0,
        menuPlacement: "top"
      }
    }, getContentAnchorEl: null,
  }
  function handleClear() {
    setEditIndex("");
    setEditMode("");
    setBomPartNumber("");
    setBomPartName("");
    setQuantity("");
    setUom("");
    setIncomingRoyalty("");
    setOutgoingRoyalty("");
    setNotes("");
  }
  function handleOnSubmit() {
    var unsortedFields = [];
    if (
      outputFieldsArray ||
      (sumFieldsArray.length > 0 &&
        filterFieldsArray.length === 0 &&
        outputFieldsArray.length === 0 &&
        aggregateFieldsArray.length === 0)
    ) {
      for (var propName in outputFieldsArray) {
        unsortedFields.push(propName);
        if (outputFieldsArray[propName] === 0) {
          delete outputFieldsArray[propName];
        }
      }
      var ob = {};
      outputFieldsArray.map((item, ix) => {
        ob[item] = ix;
      });
      var allFields = {
        filter_fields: filterFieldsArray,
        sum_fields: sumFieldsArray,
        sort_fields: ob,
        output_fields: outputFieldsArray,
        groupby_fields: aggregateFieldsArray,
        format_name: queryName,
        table_name: props.dynamicQueryFieldsData.table_name,
        query_description: description,
        avg_fields: averageFieldsArray,
        query_type: 4,
        app_type: applicationType,
        chart_type: chartType,
        //"data_source_type": sourceDataType,
        //"data_source": functionalityName,
        table_description: tableName,
      };
      if (currentPage === "add-partner-statement") {
        props.onSubmitPartnerStatement(allFields);
      } else if (currentPage === "add-collaboration-portal") {
        props.onSubmitCollaborationPortal(allFields);
      } else {
        props.onSubmit(allFields);
      }
    } else {
      alert("Please select atleast one output or only sum field");
    }
  }
  function handleClearAll() {
    setSortOrderArray([]);
    handleClear();
    setEditMode(false);
    setUserStore([]);
    setFilterFieldsArray([]);
    setAggregateFieldsArray([]);
    setAverageFieldsArray([]);
    setOutputFieldsArray([]);
    setSumFieldsArray([]);
  }
  function handleCancelDialog() {
    history.push("/on-demand-analytics");
  }
  const handleChange = (event, item, type) => {
    if (type === "filter_fields") {
      if (filterFieldsArray.includes(item)) {
        setFilterFieldsArray(
          filterFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        if (
          filterFieldsArray.length <= 1 &&
          aggregateFieldsArray.length <= 1 &&
          averageFieldsArray.length <= 1 &&
          sumFieldsArray.length <= 1 &&
          outputFieldsArray.length <= 1
        ) {
          setFilterFieldsArray([...filterFieldsArray, item]);
        } else {
          if (
            aggregateFieldsArray.includes(item) ||
            averageFieldsArray.includes(item) ||
            sumFieldsArray.includes(item) ||
            outputFieldsArray.includes(item)
          ) {
            setFilterFieldsArray([...filterFieldsArray, item]);
          }
        }
      }
    }
    if (type === "aggregate_fields") {
      if (aggregateFieldsArray.includes(item)) {
        setAggregateFieldsArray(
          aggregateFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        if (
          filterFieldsArray.length <= 1 &&
          aggregateFieldsArray.length <= 1 &&
          averageFieldsArray.length <= 1 &&
          sumFieldsArray.length <= 1 &&
          outputFieldsArray.length <= 1
        ) {
          setAggregateFieldsArray([...aggregateFieldsArray, item]);
        } else {
          if (
            filterFieldsArray.includes(item) ||
            averageFieldsArray.includes(item) ||
            sumFieldsArray.includes(item) ||
            outputFieldsArray.includes(item)
          ) {
            setAggregateFieldsArray([...aggregateFieldsArray, item]);
          }
        }
      }
    }
    if (type === "avg_fields") {
      if (averageFieldsArray.includes(item)) {
        setAverageFieldsArray(
          averageFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        if (
          filterFieldsArray.length <= 1 &&
          aggregateFieldsArray.length <= 1 &&
          averageFieldsArray.length <= 1 &&
          sumFieldsArray.length <= 1 &&
          outputFieldsArray.length <= 1
        ) {
          setAverageFieldsArray([...averageFieldsArray, item]);
        } else {
          if (
            filterFieldsArray.includes(item) ||
            aggregateFieldsArray.includes(item) ||
            sumFieldsArray.includes(item) ||
            outputFieldsArray.includes(item)
          ) {
            setAverageFieldsArray([...averageFieldsArray, item]);
          }
        }
      }
    }
    if (type === "sum_fields") {
      if (sumFieldsArray.includes(item)) {
        setSumFieldsArray(sumFieldsArray.filter((item1) => item1 !== item));
      } else {
        if (
          filterFieldsArray.length <= 1 &&
          aggregateFieldsArray.length <= 1 &&
          averageFieldsArray.length <= 1 &&
          sumFieldsArray.length <= 1 &&
          outputFieldsArray.length <= 1
        ) {
          setSumFieldsArray([...sumFieldsArray, item]);
        } else {
          if (
            filterFieldsArray.includes(item) ||
            aggregateFieldsArray.includes(item) ||
            sumFieldsArray.includes(item) ||
            outputFieldsArray.includes(item)
          ) {
            setSumFieldsArray([...sumFieldsArray, item]);
          }
        }
      }
    }

    if (type === "output_fields") {
      if (outputFieldsArray.includes(item)) {
        setOutputFieldsArray(
          outputFieldsArray.filter((item1) => item1 !== item)
        );
      } else {
        if (
          filterFieldsArray.length <= 1 &&
          aggregateFieldsArray.length <= 1 &&
          averageFieldsArray.length <= 1 &&
          sumFieldsArray.length <= 1 &&
          outputFieldsArray.length <= 1
        ) {
          setOutputFieldsArray([...outputFieldsArray, item]);
        } else {
          if (
            filterFieldsArray.includes(item) ||
            aggregateFieldsArray.includes(item) ||
            sumFieldsArray.includes(item) ||
            averageFieldsArray.includes(item)
          ) {
            setOutputFieldsArray([...outputFieldsArray, item]);
          }
        }
      }
    }
  };
  const [functionalityName, setFunctionalityName] = React.useState("");
  const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeArray(props.sourceDataTypeValue["drop_down_value_keys"]);
    }
  }, [props.sourceDataTypeValue]);
  const handleFunctionalityName = (e) => {
    setFunctionalityName(e.target.value);
    props.getDynamicQueryFields(
      applicationType,
      sourceDataType,
      e.target.value,
      tableName
    );
  };
  const handleQueryType = (e) => { };
  const [allOutput, setAllOutput] = React.useState(false);
  const handleAllOutput = (event) => {
    var tempObj = {};
    var tempArray = [];
    var i = 1;
    setAllOutput(event.target.checked);
    if (event.target.checked === true) {
      // if (apiArrayFieldsConcatinated.length > 0 && props.labelNewAPIData) {
      //   apiArrayFieldsConcatinated.map((item1) => {
      //     Object.entries(props.labelNewAPIData)
      //       .sort()
      //       .map(([key, value]) => {
      //         if (item1 === key) {
      //           tempObj[key] = 0;
      //         } else if (
      //           localStorage.getItem("dq_table_name") === "purchase_data"
      //         ) {
      //           tempObj[item1] = 0;
      //         }
      //       });
      //   });
      // }
      if (keyValuePairObject.length > 0) {
        // Object.entries(keyValuePairObject)
        keyValuePairObject && keyValuePairObject
          .sort()
          .filter((item, index) => item.key != "")
          .map((item, index) => {
            tempArray.push(item.key)
            tempObj[item.key] = i;
            ++i;
          })
      }
      setOutputFieldsArray(tempObj);
    } else {
      setOutputFieldsArray(tempObj);
    }
  };
  const [dataSourceData, setDataSourceData] = React.useState([]);
  const [dataSource, setDataSource] = React.useState("");
  const [applicationType, setApplicationType] = React.useState("");
  const [sourceDataType, setSourceDataType] = React.useState("");
  const handleApplicationType = (e) => {
    setApplicationType(e.target.value);
    setSourceDataType("");
    setDataSourceData([]);
    setSourceDataTypeArray([]);
    props.loadSourceDataType(e.target.value, "source_data_type");
    if (e.target.value === "Sales Commission") {
      if (currentPage === "add-collaboration-portal") {
        setFilterFieldsArray(["employee_number"]);
      }
    }
  };
  const handleSourceDataType = (e) => {
    props.onLoadingDefault(
      applicationType,
      e.target.value,
      null,
      null,
      "Display Designer"
    );
    setSourceDataType(e.target.value);
    if (applicationType && e.target.value) {
      if (
        applicationType === "Supplier Rebate" &&
        e.target.value === "Direct - Purchase Data"
      ) {
        setDataSourceData([
          "Purchase Data",
          "Calculation Simulation",
          "Financial Postings",
        ]);
      } else if (
        applicationType === "Customer Chargeback" &&
        e.target.value === "Direct - Sales Data"
      ) {
        setDataSourceData(["Sales Data", "Outgoing Claim"]);
      } else if (
        applicationType === "Supplier Chargeback" &&
        e.target.value === "Indirect - Sales Data"
      ) {
        setDataSourceData(["Incoming Claim"]);
      } else if (
        applicationType === "Promotions" &&
        e.target.value === "Promotion Budget"
      ) {
        setDataSourceData(["Budget Simulation", "Budget Postings"]);
      } else {
        setDataSourceData([
          "Sales Data",
          "Calculation Simulation",
          "Financial Postings",
        ]);
      }
    } else {
      setDataSourceData([]);
    }
  };

  useEffect(() => {
    if (sourceDataType && applicationType)
      props.onLoadingLabelDesignerNew(
        "field-label-designer",
        applicationType,
        sourceDataType
      );
  }, [sourceDataType]);
  function handleTableName(e) {
    setTableName(e.target.value);
    props.getDynamicQueryFields(applicationType, e.target.value);
  }
  console.log(keyValuePairObject)
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h4"
          classes={{
            root: classes.link,
          }}
          // component="button"
          onClick={() => {
            history.push("/on-demand-analytics");
          }}
        >
          {currentPage === "add-partner-statement"
            ? "Partner Statement"
            : currentPage === "add-collaboration-portal"
              ? "Collaboration Portal"
              : "On Demand Analytics"}
        </Link>
        <Typography color="textPrimary" variant="h4">
          Add Query
        </Typography>
      </Breadcrumbs>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        disableEscapeKeyDown
        classes={{ paper: classes.paper }}>
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel} required>
            Query Name
          </FormLabel>
          <OutlinedInput
            value={queryName}
            classes={{ root: classes.inputTwoLine }}
            onChange={handleQueryName}
          />
        </div>
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel} required>
            Description
          </FormLabel>
          <OutlinedInput
            value={description}
            // inputProps={{ min: "0", step: "1" }}
            classes={{ root: classes.inputTwoLine }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel} required>
            Application Type
          </FormLabel>
          <Select
            value={applicationType}
            onChange={handleApplicationType}
            className={clsx({
              [classes.select]: true,
            })}
            classes={{
              selectMenu: classes.selectedItem,
            }}
            MenuProps={{ ...MenuProps, autoFocus: true }}
          >
            <MenuItem value="">Select Option</MenuItem>
            {allowedApps.map((item) => {
              return (
                <MenuItem
                  value={item}
                  key={item}
                  style={{ textTransform: "capitalize" }}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel} required>
            Data Source
          </FormLabel>
          <Select
            value={tableName}
            onChange={handleTableName}
            className={clsx({
              [classes.select]: true,
            })}
            MenuProps={{ ...MenuProps, autoFocus: true }}
            style={{ textTransform: "capitalize" }}
            classes={{
              selectMenu: classes.selectedItem,
            }}
          >
            <MenuItem value="">Select Option</MenuItem>
            {tableDeterminationArray &&
              tableDeterminationArray.map((item) => {
                return (
                  <MenuItem
                    value={item.table_description}
                    key={item.table_decription}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.table_description.replace(/_/g, " ")}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel} required>
            Chart Type
          </FormLabel>
          <Select
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            className={clsx({
              [classes.select]: true,
            })}
            MenuProps={{ ...MenuProps, autoFocus: true }}
            style={{ textTransform: "capitalize" }}
            classes={{
              selectMenu: classes.selectedItem,
            }}
          >
            <MenuItem value="">Select Option</MenuItem>
            <MenuItem value="Pie Chart">Pie Chart</MenuItem>
            <MenuItem value="Bar Chart">Bar Chart</MenuItem>
          </Select>
        </div>
        <DialogActions>
          <Button color="primary" onClick={handleCancelDialog}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleInitialSetup}
            disabled={
              queryName && tableName && applicationType && description
                ? false
                : true
            }
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.bodyContainer}>
        <Card>
          <form autoComplete="off" noValidate>
            <div className={classes.container}>
              <Grid container spacing={2}></Grid>
            </div>
          </form>
        </Card>

        <div className={classes.container2}>
          <Grid container>
            <Grid item xs={12} classes={{ root: classes.gridContainer }}>
              {props.dynamicQueryFieldsData &&
                props.dynamicQueryFieldsData.numeric_fields ? (
                <Paper className={classes.root}>
                  <div style={{ margin: 15 }}>
                    <TableContainer style={{ overflowX: "initial" }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableStyle}
                            >
                              Field Name
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableStyle}
                            >
                              Filter
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableStyle}
                            >
                              Aggregate
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableStyle}
                            >
                              Average
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableStyle}
                            >
                              Sum
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableStyle}
                            >
                              Output
                            </TableCell>
                            {/* <TableCell align='center' className={classes.tableStyle}>Sort Order</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                          {keyValuePairObject && keyValuePairObject.length > 0 && keyValuePairObject
                            .sort()
                            .filter((item, index) => item.key != "")
                            .map((item, index) => {
                              return (
                                <TableRow key={item.key}>
                                  <TableCell
                                    style={{
                                      textAlign: "center",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.label}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={filterFieldsArray.includes(item.key)}
                                      color="primary"
                                      onChange={(e) =>
                                        handleChange(e, item.key, "filter_fields")
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                      disabled={
                                        currentPage ===
                                          "add-collaboration-portal"
                                          ? applicationType ===
                                            "Sales Commission"
                                            ? item.key === "employee_number"
                                              ? true
                                              : false
                                            : item.key === "customer_number" ||
                                              item.key === "supplier_number"
                                              ? true
                                              : false
                                          : false
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={aggregateFieldsArray.includes(
                                        item.key
                                      )}
                                      color="primary"
                                      onChange={(e) =>
                                        handleChange(e, item.key, "aggregate_fields")
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={averageFieldsArray.includes(item.key)}
                                      color="primary"
                                      onChange={(e) =>
                                        handleChange(e, item.key, "avg_fields")
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={sumFieldsArray.includes(item.key)}
                                      color="primary"
                                      onChange={(e) =>
                                        handleChange(e, item.key, "sum_fields")
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Switch
                                      checked={outputFieldsArray.includes(item.key)}
                                      color="primary"
                                      onChange={(e) =>
                                        handleChange(e, item.key, "output_fields")
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  {/* <TableCell style={{ width: 70 }}>
                                    <TextField
                                      key={["recipient", key].join("_")}
                                      type="number"
                                      variant="outlined"
                                      value={
                                        outputFieldsArray[key] ||
                                        outputFieldsArray[key] === 0
                                          ? outputFieldsArray[key]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleChange(e, key, "sort_order")
                                      }
                                      disabled={
                                        outputFieldsArray.hasOwnProperty(key)
                                          ? false
                                          : true
                                      }
                                      inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                      }}
                                    />
                                  </TableCell> */}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Paper>
              ) : (
                <Typography variant="h4">
                  There is no data to show now.
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttonRoot}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleClearAll}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOnSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTableDeterminationData: () => dispatch(getTableDeterminationData()),
    getDynamicQuerySalesDataFields: () =>
      dispatch(getDynamicQuerySalesDataFields()),
    getCalculationSimulationDataFields: () =>
      dispatch(getCalculationSimulationDataFields()),
    onSubmit: (data) =>
      dispatch(postDynamicQuery(data, null, "on-demand-analytics")),
    onSubmitPartnerStatement: (data) =>
      dispatch(postDynamicQuery(data, null, "partner")),
    getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
    onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) =>
      dispatch(
        getDefaultValuesAllDataNew(command, applicationType, sourceDataType)
      ),
    getPurchaseDataFields: () => dispatch(getPurchaseDataFields()),
    loadSourceDataType: (applicationType, fieldId) =>
      dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    getDynamicQueryFields: (applicationType, tableName) =>
      dispatch(getDynamicQueryFieldsNew(applicationType, tableName)),
    onLoadingDefault: (applicationType, sourceDataType) =>
      dispatch(
        getDefaultValues(
          applicationType,
          sourceDataType,
          null,
          null,
          "Display Designer"
        )
      ),
    allowedApps: () => dispatch(allowedApps()),
    onSubmitCollaborationPortal: (data) =>
      dispatch(postDynamicQuery(data, "", "collaboration")),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    dropdownData: state.customerData.dropdownData,
    dropdownDataCustomer: state.customerData.dropdownDataCustomer,
    dynamicQueryFieldsData:
      state.operationalReportsData.dynamicQueryFieldsDataNew,
    calculationFieldsData: state.customerData.calculationSimulationData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    queryTypes: state.operationalReportsData.queryTypesSuccess,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    formFieldsAllData:
      state.addMultipleConfigurationData.multipleConfigurationData,
    allowedAppsData: state.initialData.allowedApps,
    tableDetermination: state.operationalReportsData.tableDetermination,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicQuery);
