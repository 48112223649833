import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,IconButton,Dialog,
    Grid,FormLabel,DialogContent,DialogTitle, Box
} from '@mui/material';
import clsx from 'clsx';
import { LabelText } from '../../../components/Inputs';
import CloseIcon from '@mui/icons-material/Close';
import { EditText} from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import Moment from 'moment';
import { updateIncomingClaimData } from '../../../redux/actions';
const useStyles = makeStyles(theme => ({
   
    gridContainer: {
        width: '100%'
    },
    rootIcon: {
        padding: 0
    },
    root1: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    baseColor:{
        color:theme.palette.primary.main,
    },
    fontSettings:{
        fontSize:theme.typography.h3.fontSize
    }
}));

const PopupContainer = props => {
    const classes = useStyles();
    const [incomingClaimData,setIncomingClaimData]= React.useState([]);
    useEffect(()=>{
        if(props.incomingClaimID && props.incomingClaimDetailData && props.incomingClaimDetailData.length>0){
            var data=props.incomingClaimDetailData.filter(item=>item.unique_identifier ==props.incomingClaimID)
            setIncomingClaimData(data)
        }
    },[props.incomingClaimDetailData,props.incomingClaimID])

    const keywords = ['unique_identifier', 'claim_number','channel_partner_claim_number','claim_date','pricing_date','posting_date', 'contract_number','acquistion_price',
    'contract_price','quantity','calculated_amount','approved_amount','channel_partner_contract_id','channel_partner_acquistion_price','channel_partner_contract_price','channel_partner_quantity',
    'billed_uom','claim_amount','adjusted_amount','adjustment_code','claim_status','rejection_reason','claim_error','notes','channel_partner_type','channel_partner_id',
    'channel_partner_name','supplier_id_type','supplier_id_number','channel_partner_debit_memo','claim_submission_type','resubmit_number','credit_memo',' claim_per_unit',
    'base_uom','customer_number','channel_partner_end_customer_id','channel_partner_end_customer_name','end_customer_id','end_customer_name','membership_owner_id','membership_owner_name',
    'channel_partner_id_type','channel_partner_id_number','end_customer_id_type','end_customer_id_number','membership_owner_id_type','membership_owner_id_number','material_number',
    'material_description','material_id_type','material_id_number','channel_partner_material','product_hierarchy','customer_name','street_address','city','region','postal_code','country'
    ,'billing_date','billing_doc_number','billing_doc_line','net_value','currency','debit_credit_indicator','company_code','sales_org','distribution_channel','division','profit_center',
    'cost_center','class_of_trade','customer_classification','customer_group','customer_industry','delivery_tracking_number','maximum_quantity_allowed',
    'flex_attribute1','flex_attribute2','flex_attribute3','flex_attribute4','flex_attribute5','plant','flex_key_figure1','flex_key_figure2','flex_key_figure3','flex_key_figure4','flex_key_figure5','unit_price','billing_doc_cancelled','cancelled_billing_doc','original_invoice_for_return',
    'original_invoice_line_for_return','calendar_year_month','fiscal_year_month','transaction_type','processed','createdAt','updatedAt','created_by','changed_by',
]
    const editableLines = [
        'end_customer_id',
        'pricing_date', 
        'contract_number',
        'acquistion_price',
        'contract_price',
        'quantity',
        'approved_amount',
        'adjusted_amount',
        'adjustment_code',
        'rejection_reason',
        'notes',
        'membership_owner_id',
        'material_number',
    ]

    const handleOnSaveEdit = ({ name, value, previousValue }) => {
        var data = props.incomingClaimData;
        if (typeof props.incomingClaimData[name] === 'number') {
            data[name] = parseFloat(value);
        } else {
            data[name] = value;
        }
        props.updateIncomingClaimData(data);
    }
    function handleClose(){
        props.onClose(false)
    }

    return (
        <Dialog open={
            props.open}
            fullScreen
            style={{ margin: 15 ,height:'auto'}}
        >
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <h2 className={classes.baseColor}>  Incoming Claim Details</h2>
                    <Box>
                        <IconButton onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>

            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialogContent
            }}
            >
                {/* {JSON.stringify(currentViewData)} */}
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div>
                            <Grid container >
                                {incomingClaimData &&  
                                keywords.map(item=>{
                                    
                                        return (
                                            <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >


                                            {editableLines.includes(item) ?
                                                <Grid container
                                                    className={clsx(classes.root1)}
                                                    style={{paddingTop: 10}}
                                                >
                                                    <Grid item style={{ width: '100%',  marginLeft:-5,paddingRight:25 }}>
                                                        <FormLabel className={classes.fontSettings} style={{ paddingLeft: 5, textTransform: 'capitalize',paddingTop:15}}>
                                                            {incomingClaimData[0]&&Object.keys(incomingClaimData[0]).includes(item) ? item.replace(/_/g, ' ') : ''}
                                                        </FormLabel>
                                                        <div className={classes.data}
                                                      >
                                                            <EditText
                                                                name={item}
                                                                defaultValue={incomingClaimData[0]&&incomingClaimData[0][item] ? Moment(incomingClaimData[0][item], Moment.ISO_8601, true).isValid() && incomingClaimData[0][item].toString().charAt(incomingClaimData[0][item].length - 1) === 'Z' ? Moment(incomingClaimData[0][item]).format('MM/DD/YYYY') : incomingClaimData[0][item]:''}
                                                                // inputClassName='bg-success'
                                                                onSave={handleOnSaveEdit}
                                                                showEditButton
                                                                 />
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                :
                                                <LabelText className={classes.fontSettings} heading={incomingClaimData[0]&& Object.keys(incomingClaimData[0]).includes(item) ? item.replace(/_/g, ' ') : ''} style={{ textTransform: 'capitalize' }} data={incomingClaimData[0]&&incomingClaimData[0][item] ? Moment(incomingClaimData[0][item], Moment.ISO_8601, true).isValid() && incomingClaimData[0][item].toString().charAt(incomingClaimData[0][item].length - 1) === 'Z' ? Moment(incomingClaimData[0][item]).format('MM/DD/YYYY') : incomingClaimData[0][item]:''} twoline='true' />
                                            }
                                        </Grid>
                                        );
                                    }

                                )}
                            
                            </Grid>
                        </div>
                    </form>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

const mapDispatchToProps = dispatch => {
    return {       
        updateIncomingClaimData: (formData) => dispatch(updateIncomingClaimData(formData)),
         }

}

const mapStateToProps = state => {
    return {
        incomingClaimDetailData: state.initialData.incomingClaimDetailData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);