import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    TableRow,
    Button, Fab, Dialog, DialogContent, DialogContentText, DialogActions, TextField, Container, FormLabel, TableCell, TableBody, TableContainer, Table, TableHead
} from '@mui/material';
import { Chat } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { getchatGptData } from '../../../redux/actions';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    chatBar: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#f2f2f2',
        padding: '10px',
        borderTop: '1px solid #ccc',
    },
    input: {
        width: '80%',
        padding: '5px',
        marginRight: '10px',
        fontSize: '14px',
    },
    button: {
        padding: '5px 10px',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    answer: {
        margin: '10px 11px 20px',
        wordWrap: 'break-word',
    },
    bodyContainer: {
        backgroundColor: theme.palette.secondary.main,
        border: '2px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 31
    },
}));
const ChatGptApp = props => {
    const [showTextArea, setShowTextArea] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [types, setTypes] = useState('');

    const [insightData, setInsightData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [currentQuery, setCurrentQuery] = useState('');
    const [insightDataNull, setInsightDataNull] = useState(false);

    const [data, setData] = React.useState('');
    const [submit, setSubmit] = useState(false);
    const handleChange = event => {
        setSearchQuery(event.target.value);
    };
    const [thread, setThread] = useState([]);

    useEffect(() => {
        console.log(submit);
        if (types === 'Normal' && searchQuery && submit) {
            const queryResponse = {
                query: searchQuery,
                response: props.result ? props.result : '',
            };
            setThread(prevThread => [...prevThread, queryResponse]);
            setSearchQuery('');
            setSubmit(false);
        }
        else if (types === 'Insight' && props.result) {
            const responseData = props.result;

            // Check if responseData is an array and not empty
            if (Array.isArray(responseData) && responseData.length > 0) {
                // Extract column names dynamically from the first row of the response
                const firstRow = responseData[0];
                const columns = Object.keys(firstRow);

                // Update the state variables
                setInsightData(responseData);
                setDynamicColumns(columns);
                setInsightDataNull(false);
            } else {
                // If responseData is empty or not an array, set the insightData to an empty array
                setInsightData([]);
                setDynamicColumns([]);
                setInsightDataNull(true);
            }

        }

    }, [props.result, searchQuery]);


    const handleSubmit = event => {
        event.preventDefault();
        if (!searchQuery.trim()) {
            return; // If empty, do nothing and return from the function
        }
        setSubmit(true);

        if (types === 'Insight') {
            // setSearchQuery('');
            const requestData = {
                prompt: searchQuery,
            };
            props.onSubmit(requestData, types);
            setCurrentQuery(searchQuery);
            setSearchQuery('');
            return;
        }
        // setSearchQuery('');
        // setCurrentQuery('');
        const requestData = {
            prompt: searchQuery,
            // thread: thread.map(queryResponse => ({
            //     query: queryResponse.query,
            //     response: queryResponse.response
            // }))
        };

        props.onSubmit(requestData, types);

    };
    const formatColumnName = (columnName) => {
        // Split the column name by underscores and capitalize each part
        const words = columnName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1));
        // Join the words with a space
        return words.join(' ');
    };

    const handliButtonClick = (value, item) => {
        setSearchQuery('');
        setTypes(item);
        setShowTextArea(true);
    }

    const handleClear = () => {
        setThread([]);
        setInsightData([]);
        setDynamicColumns([]);
        setCurrentQuery('');

    };
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary' style={{ marginTop: 10 }} > GPT </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            onClick={(e) => handliButtonClick(e, 'Normal')}

                        >
                            <Chat color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Context Help</Typography>
                        </Fab>
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            onClick={(e) => handliButtonClick(e, 'Insight')}
                        >
                            <Chat color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Data Help</Typography>
                        </Fab>
                    </div>

                </div>
                <div className={classes.bodyContainer}>
                    <div className={classes.answer}>
                        {(types == 'Normal') ?
                            thread.map((queryResponse, index) => (
                                <div style={{ marginBottom: '12px' }} key={index}>
                                    <FormLabel variant="h3">User :{queryResponse.query}</FormLabel>
                                    <Typography style={{ fontSize: '14px', color: 'blue' }}>System : {queryResponse.response}</Typography>

                                </div>
                            ))
                            :
                            <div>
                                {insightDataNull ? (
                                    <Typography variant="body1">No data available.</Typography>
                                ) : (
                                    insightData.length > 0 && (
                                        <Container maxWidth="lg">
                                            <div style={{ marginBottom: '12px' }}>
                                                <FormLabel variant="h3">User : {currentQuery}</FormLabel>
                                            </div>
                                            <TableContainer>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {dynamicColumns.map((columnName, index) => (
                                                                <TableCell key={index}>{formatColumnName(columnName)}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {insightData.map((row, rowIndex) => (
                                                            <TableRow key={rowIndex}>
                                                                {dynamicColumns.map((columnName, colIndex) => (
                                                                    <TableCell key={colIndex}>
                                                                        {row[columnName]}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Container>
                                    )
                                )}
                            </div>
                        }
                    </div>

                </div>
                {showTextArea && (
                    <div className={classes.chatBar}>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Enter your search query..."
                                value={searchQuery}
                                onChange={handleChange}
                                className={classes.input}
                            />
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit" >
                                Search
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleClear}
                                style={{ marginLeft: '10px' }}
                            >
                                Clear
                            </Button>
                        </form>
                    </div>
                )}

            </LoadingOverlay>
        </div>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData, types) => dispatch(getchatGptData(formData, types)),

    }
}
const mapStateToProps = state => {
    return {
        result: state.ChatGptData.chatgptnormalData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatGptApp);