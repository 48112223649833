import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';
import { Edit, DeleteForever } from '@mui/icons-material';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getCotXRefData, getCotXRefDetails, deleteCotXRefUser } from '../../../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { CotXREFToolbar } from '.';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const CotXREFUserTable = props => {
    useEffect(() => {
        props.getCotXRefData(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);



    useEffect(() => {
        if (props.cotXrefData) {
            setDataRows(props.cotXrefData.records);
        }
    }, [props.cotXrefData]);

    function editMaterial(id) {
        navigateToEdit(id);
    }

    function navigateToEdit(id) {
        localStorage.setItem('materialMasterXRefEditId', id);
        props.getCotXRefDetails(id);
        history.push({
            pathname: '/cot-xref/edit-cot-xref/' + id,
            id: id
        });
    }
    // const deleteData = (id) => {
    //     props.deleteCotXRefUser(id)
    // }
    const handleDeleteClick = (id) => {
        setSelectedId(id);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        props.deleteCotXRefUser(selectedId);
        setOpenDialog(false);
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
        setSelectedId(null);
    };

    function viewMaterial(id) {
        props.getCotXRefData(id);
        history.push({
            pathname: '/cot-xref/view-cot-xref/' + id,
            id: id
        });
    }

    const columns = [
        {
            field: 'channel_partner_id',
            title: 'Channel Partner ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_id}</a>
        },
        {
            field: 'channel_partner_name',
            title: 'Channel Partner Name',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_name}</a>
        }
        , {
            field: 'channel_partner_class_of_trade',
            title: 'Channel Partner Class of Trade',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_class_of_trade}</a>
        },
        {
            field: 'ima_class_of_trade',
            title: 'IMA Class of Trade',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.ima_class_of_trade}</a>
        },
        {
            field: 'ima_class_of_trade_description',
            title: 'IMA Class of Trade Description',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.ima_class_of_trade_description}</a>

        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_cot_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editMaterial(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_cot_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleDeleteClick(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>

        },
    ];
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading COT XREF. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> COT XREF</Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_cot_xref-' + sessionStorage.getItem('application')) &&
                        <CotXREFToolbar />}
                </div>
                {props.cotXrefData && props.cotXrefData.records && props.cotXrefData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />

                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        cotXrefData: state.customerData.cotXrefData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCotXRefData: (pagination, limit) => dispatch(getCotXRefData(pagination, limit)),
        getCotXRefDetails: (id) => dispatch(getCotXRefDetails(id)),
        deleteCotXRefUser: (id) => dispatch(deleteCotXRefUser(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CotXREFUserTable);