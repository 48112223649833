import React, { useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Button,
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    FormLabel,
    
} from '@mui/material';
import { connect } from 'react-redux';
import ChipInput from 'material-ui-chip-input';
import { LabelText} from '../../../components/Inputs';
import {
    createOffInvoiceConfig, getOffInvoiceConfig
} from '../../../redux/actions';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '40px 40px 40px 40px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 25,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    gridContainer: {
        width: '100%'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
        paddingTop: 8
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    select: {
        width: '100%',
        marginTop: 10
    }
}));

const ClaimConfigurationTab = props => {
    useEffect(() => {
        props.getOffInvoiceData();

    }, []);
    
    const [fieldValue, setFieldValue] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
 
    const [selectedRowName, setSelectedRowName] = React.useState('');
  
    const [offInvoiceCost, setOffInvoiceCost] = React.useState([]);
    const [offInvoiceData,setOffInvoiceData]= React.useState([])
    
    const classes = useStyles();
    const { className } = props;
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);

    const [currentID, setCurrentID] = React.useState(0);
    useEffect(() => {

        if (props.offInvoiceData&&props.offInvoiceData.length>0) {
            setOffInvoiceData(props.offInvoiceData[0])
            setCurrentID(props.offInvoiceData[0].id)
            setOffInvoiceCost(props.offInvoiceData[0].off_invoice_cost_types)
        }

    }, [props.offInvoiceData])

    const handleChange = (fieldName) => {
        setSelectedRowName(fieldName);
       
       if(fieldName=='off_invoice_cost_types'){
        setFieldValue(offInvoiceCost)
       }

    };

    function handleSubmit(clear) {

       console.log(selectedRowName)
        var data;
        if (clear === 'clear') {
             data = {
                "off_invoice_cost_types":[]   
            };

        }else{
            data = {
                "off_invoice_cost_types":offInvoiceCost
    
            };
        }
        console.log(data)
        if(currentID!=0){
            props.onSubmit(data, 'edit', currentID);
        }
        else {
            props.onSubmit(data, 'create');
        }
        
        setSelectedRowName('');
        setEditMode(false)
        setCurrentID(0)
        setFieldValue([])

    }
   
    const handleFieldValue = (newValue) => {
        
        setFieldValue([...fieldValue, newValue]);
        if(selectedRowName=='off_invoice_cost_types'){
            setOffInvoiceCost([...offInvoiceCost, newValue]);
        }
    }
    const handleDeleteFieldValue = (newValue) => {
        
        var deleteFieldValue = fieldValue.filter(item => item !== newValue)
        setFieldValue(deleteFieldValue)
        if(selectedRowName=='off_invoice_cost_types'){
            setOffInvoiceCost(deleteFieldValue);
        }
        
    }
    

    return (
        <div className={clsx(classes.root, className)}>

            <div
                className={clsx(classes.addRoot, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container >

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <LabelText heading='Field Name' capitalize={true} data={selectedRowName ? selectedRowName.replace(/_/g, ' ') : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                {selectedRowName &&
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        style={{ paddingLeft: 15, marginTop: 20 }}
                                    >
                                        <FormLabel error={false}>
                                            Field Value
                                        </FormLabel>
                                        <div>
                                            
                                           
                                                <ChipInput
                                                    classes={{
                                                        root: classes.rootContainer,
                                                        chip: classes.chip,
                                                        input: classes.input,
                                                        inputRoot: classes.inputRoot,
                                                        label: classes.chipLabel
                                                    }}
                                                    value={fieldValue}
                                                    onAdd={(chips) => handleFieldValue(chips)}
                                                    onDelete={(chip) => handleDeleteFieldValue(chip)}
                                                    disableUnderline={true}
                                                    className={clsx({
                                                        [classes.textInput]: true
                                                    })}
                                                    blurBehavior='add'
                                                />                                          
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.buttonContainer}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ padding: 5 }}
                                        startIcon={<CheckIcon />}
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        onClick={() => handleSubmit()}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        classes={{
                                            root: classes.checkIconContainer
                                        }}
                                        style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        startIcon={<CheckIcon />}
                                        onClick={() => handleSubmit('clear')}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >

            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>

                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Field Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            
                                            <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('off_invoice_cost_types')}>
                                                <TableCell classes={{ root: classes.leftAlign }}>
                                                Off Invoice Cost Types
                                                </TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>

                                                {offInvoiceData&& offInvoiceData.off_invoice_cost_types ? offInvoiceData.off_invoice_cost_types.toString():''}
                                                </TableCell>

                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Grid>
                    </div>

                </Card>
            </div>
        </div >
    );

};

const mapStateToProps = state => {
    return {
        loading: state.pricingData.loading,
        offInvoiceData:  state.pricingData.offInvoiceConfigData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, field, type) => dispatch(createOffInvoiceConfig(data, field, type)),
        getOffInvoiceData: () => dispatch(getOffInvoiceConfig()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimConfigurationTab);