import Palette from '../palette';

export default {
    root: {
        borderRadius: 0,
        border: '1px solid',
        borderColor: Palette.border.main,
        "&$focused $notchedOutline": {
            "border": "0px solid grey !important"
        }
    },
    input: {
        padding: '8px 0px 9px',
        border: 0,
        textOverflow: 'ellipsis'
    },
    notchedOutline: {
        border: '0px solid'
    }
}