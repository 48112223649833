import React from 'react';
import { connect } from 'react-redux';
import { BatchJobSetupTable } from './components';

const IntegrationSetup = () => {
    return (
        <div>
            <BatchJobSetupTable />
        </div >
    );
};


export default connect(null,null)(IntegrationSetup);