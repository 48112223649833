import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";
const AuditLab = (props) => {
    const handleClick = (pge) => {
        history.push(pge)
    }
    
    const [menuItems,setMenuItems] = React.useState([
        { 'Contract Change Report': '/contract-change-reports' },
        { 'Financial Postings History': '/financial-approvals-reports' },
        { 'Data Log': '/error-logs' },
        { 'Activity Log': '/message-logs' },
        { 'Exception Management': '/exception-management-view' },
        { 'On Demand Query': '/dynamic-query' },
    ]);
    useEffect(()=>{
        var newArray=[]
        menuItems.map(item=>{
            if(secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights.includes(
                "contract_change_report-" + sessionStorage.getItem("application")
              )){
            newArray.push({ 'Contract Change Report': '/contract-change-reports' })
            }
            if(secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights.includes(
                "financial_postings_history-" + sessionStorage.getItem("application")
              )){
            newArray.push({ 'Financial Postings History': '/financial-approvals-reports' })
            }
            if(secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights.includes(
                "data_log-" + sessionStorage.getItem("application")
              )){
            newArray.push({ 'Data Log': '/error-logs' })
            }
            if(secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights.includes(
                "activity_log-" + sessionStorage.getItem("application")
              )){
            newArray.push({ 'Activity Log': '/message-logs' })
            }
            if(secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights.includes(
                "exception_management-" + sessionStorage.getItem("application")
              )){
            newArray.push({ 'Exception Management': '/exception-management-view' })
            }
            if(secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights.includes(
                "get_report-" + sessionStorage.getItem("application")
              )){
            newArray.push({ 'On Demand Query': '/dynamic-query' })
            }
            // setMenuItems(newArray)
        })
        var jsonObject = newArray.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        setMenuItems(uniqueArray)
    },[])
    
    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between',margin:'auto' }}
            navbarScroll
        >
            {menuItems.map((item,i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })} 
                    style={{borderRight: i!==menuItems.length-1? '1px solid lightgrey':'none '}}>
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                        style={{borderRight: i!==menuItems.length-1? '1px solid lightgrey':'none '}}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLab);