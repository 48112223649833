import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Link,
    Card,
    IconButton,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { editMembership, getMembershipDetails,getAttributeListData,getCustomerMasterData,getLblDispDesFieldValue,getMembershipContractXref} from '../../redux/actions';
import { connect } from 'react-redux';import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextInputWithLabel} from '../../components/Inputs';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import MasterDataComponent from '../../components/Inputs/FilledComponents/MasterDataComponent';
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize:theme.typography.h3.fontSize,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const EditMembership = props => {
    const classes = useStyles();
    const [editID, setEditID] = React.useState(0);
    const [membershipFields, setMembershipFields] = React.useState([]);
    const [membershipData, setMembershipData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField,setRequiredField] = React.useState([]);
    const [contractNumberArray,setContractNumberArray] = React.useState([]);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getMembershipDetails(appId);
            props.getAttributeListData({
                attribute_name : ''
            })
        props.getLblDispDesFieldValue({"data_source":"Membership Data"},'post');
        props.getMembershipContractXref(1,0)
    }, []);
    useEffect(() => {
        var newArray=[]
        if (props.membershipData && props.membershipData.records && props.membershipData.records.length > 0) {
            props.membershipData.records.map(item=>{
                newArray.push(item.contract_number)
            })
          setContractNumberArray(newArray)
        }
    }, [props.membershipData]);
    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields=[]
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item=>item.sort_details)
                .sort((a, b) => a.sort_details > b.sort_details  ? 1 : -1)
                .map((item) => {
                    if (item.mandatory || item.sort_details) {
                        newArray.push(item)
                        if (item.mandatory){
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setMembershipFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields)
        }
    }, [props.tableData])
    useEffect(() => {
        if (props.membership) {
            setEditID(props.membership.ID)
            setMembershipData(props.membership)
        }
    }, [props.membership])
    function handleSubmit() {
        if (membershipData['contract_start_date'])
        membershipData['contract_start_date'] = Moment.utc(membershipData['contract_start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
    if (membershipData['contract_end_date'])
        membershipData['contract_end_date'] = Moment.utc(membershipData['contract_end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
    if (membershipData['action_effective_date'])
        membershipData['action_effective_date'] = Moment.utc(membershipData['action_effective_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
    if (membershipData['member_start_date'])
        membershipData['member_start_date'] = Moment.utc(membershipData['member_start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
    if (membershipData['member_end_date'])
        membershipData['member_end_date'] = Moment.utc(membershipData['member_end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        props.onSubmitEdit(membershipData, editID)
        handleClearAll();
    }
   
    function handleClearAll() {
        setMembershipData({})
    }
    const handleMembershipData = (value, key, type) => {
         if (type == 'reference' && value) {
            value = value.key
        }
        else {
            if (key == 'formula_result' && value)
                value = parseFloat(value)
        }

        setMembershipData({ ...membershipData, [key]: value })
    }
    return (
        <div className={classes.root}>
        <Typography color="primary" variant='h1'>Edit Member </Typography>
        <div className={classes.bodyContainer}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >

                    <div className={classes.container}>
                        <Grid container spacing={2}>
                            {membershipFields && membershipFields.length > 0 &&
                                membershipFields.map((item, index) => (

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required={requiredField.includes(item.current) }>
                                                {item.current}
                                            </FormLabel>
                                            {item.key == 'contract_start_date' || item.key == 'contract_end_date'|| item.key=='action_effective_date'|| item.key=='member_start_date'||
                                            item.key=='member_end_date' ?
                                                <Grid container justifyContent="space-around">
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 0 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 10,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={membershipData[item.key] ? membershipData[item.key] : null}
                                                            onChange={date => handleMembershipData(date, item.key)}
                                                            minDate={item.key == 'contract_end_date' && membershipData['contract_start_date'] ? membershipData['contract_start_date'] : item.key=='member_end_date' && membershipData['member_start_date'] ? membershipData['member_start_date']:item.key=='action_effective_date' }
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </MuiPickersUtilsProvider>

                                                </Grid>
                                                :
                                                item.drop_down_values && item.drop_down_values.length > 0 ?
                                                    <Select
                                                        value={membershipData[item.key] ? membershipData[item.key] : ''}
                                                        onChange={(e) => handleMembershipData(e.target.value, item.key)}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        style={{ marginTop: 8 }}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >   {item.drop_down_values.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                    </Select>
                                                    :
                                                    item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                        <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key} attributeList={item.drop_down_reference_value ? item.drop_down_reference_value : []}
                                                            onChange={(value) => handleMembershipData(value, item.key, 'reference')}
                                                            prevalue={{ 'key': membershipData[item.key] ? membershipData[item.key] : '', 'desc': '' }}
                                                        />
                                                        :   item.default=='Primary Contract Number'||item.default==='Secondary Contract Number'||item.default==='Tertiary Contract Number'||item.default==='Quarternary Contract Number'? 
                                                        <Select
                                                        value={membershipData[item.key] ? membershipData[item.key] : ''}
                                                        onChange={(e) => handleMembershipData(e.target.value, item.key)}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        style={{ marginTop: 8 }}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >   {contractNumberArray.length > 0 && contractNumberArray.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                    </Select>
                                                        :
                                                        <OutlinedInput
                                                            value={membershipData[item.key] ? membershipData[item.key] : ''}
                                                            type={item.key == 'formula_result' ? 'Number' : 'String'}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(e) => handleMembershipData(e.target.value, item.key)} />
                                            }
                                        </div>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
            <div className={classes.buttonRoot}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                    Clear
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                    disabled={Object.entries(membershipData).filter(([k, v]) => allowedField.includes(k) && v).length == allowedField.length ? false : true}
                >
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Save'
                    }
                </Button>
            </div>
        </div >
    </div>
    );
};
const mapStateToProps = state => {
    return {
        membership: state.customerData.membershipDetailsData,
        attributeData: state.attributeListData.AttributeData,
        customerMasterData: state.customerData.customerMasterData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        membershipData: state.customerData.membershipContractXREFData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipDetails: (id) => dispatch(getMembershipDetails(id)),
        onSubmitEdit: (data, editID) => dispatch(editMembership(data, editID)),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        getCustomerMasterData:() => dispatch(getCustomerMasterData(1,10000)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getMembershipContractXref: (pagination,limit) => dispatch(getMembershipContractXref(pagination,limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMembership);