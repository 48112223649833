import React from 'react';
import {
    Typography,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { CalculationReportsTab, FinancialPostingsTab, ContractsReportsTab, InvoiceReportsTab, ContractsOverlapReportsTab } from './containers';
import { Dropdown, DropdownArray } from '../../components/Inputs';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        marginBottom: 23,
        marginTop: 14
    },
    dropdownContainer: {
        padding: 0,
        marginTop: -5,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

const ContractReports = props => {
    const classes = useStyles();
    const { className } = props;
    const [value, setValue] = React.useState(0);
    const handleChange = (event) => {
        setValue(event);
        // localStorage.setItem('operationalReportsDropdown', event);
    };
    const data = [
        { key: '0', value: 'Contract Reports' },
        { key: '1', value: 'Contract Overlap Reports' },
    ];

    return (
        <div>
            <Grid container spacing={1} classes={{ root: classes.flexEnd }}    style={{
                        marginTop: 10, position: 'absolute',
                        zIndex: 100,
                        top: 33,
                        right: 60
                    }}>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                  
                >
                    <DropdownArray data={data} className={classes.dropdownContainer} onChange={(e) => handleChange(parseInt(e))} defaultValue='true' />
                </Grid>
            </Grid>
            <div
                className={clsx(classes.root, className)}>
                <TabPanel value={value} index={0}>
                    <ContractsReportsTab />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ContractsOverlapReportsTab />
                </TabPanel>
            </div>
        </div >
    );

};

export default ContractReports;