import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Button, Breadcrumbs, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Fab
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getTargetInvoiceQueryList, runDynamicQuery, getDynamicQuery, fetchTargetInvoiceQuerySingle, targetDataUploadNew, putTargetDataQuery } from '../../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import BeatLoader from "react-spinners/BeatLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { AddBoxOutlined, Publish, Edit, Visibility, DeleteForever } from '@mui/icons-material';
import { cloneMasterQuery } from '../../../redux/actions/DataSetup/DataSetupTransaction/AuthAction';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    }
    ,
    dialog: {
        justifyContent: 'center',
        padding: '20px 20px 27px 20px'
    },
    dialogContent: {
        padding: '43px 37px 0px 37px !important'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const TargetInvoiceQueryList = props => {
    useEffect(() => {
        props.getTargetInvoiceQueryList();
        sessionStorage.removeItem('dynamic_filter_fields');
    }, []);
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)
    const inputBatchFile = useRef(null)
    const [currentBatchFile, setCurrentBatchFile] = useState(undefined);
    const [currentBatchFileName, setCurrentBatchFileName] = useState('');
    const [formatBatch, setFormatBatch] = useState('');
    const [currentBatchEditableID, setCurrentBatchEditableID] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    useEffect(() => {
        if (props.targetDataErrorList) {
            setErrorList(props.targetDataErrorList)
            if (Object.entries(props.targetDataErrorList).length > 0 && props.targetDataErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.targetDataErrorList])

    const handleDrop = (event, item) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.uploadSalesData(data, currentEditableID);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
        setOpen(true);
    }
    const clone = (item) => {
        props.cloneMasterQuery(item.id);
    }
    const handleBatchDrop = (event, item) => {
        setCurrentBatchFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentBatchFile(data);
        props.uploadSalesData(data, currentBatchEditableID);
        setCurrentBatchFile(undefined);
        setCurrentBatchFileName('');
        event.target.value = '';
        setOpen(true)
    }
    function runBatchQuery(item, type) {
        setCurrentBatchEditableID(item.id);
        inputBatchFile.current.click();
    }
    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        sessionStorage.setItem('target-invoice-query', JSON.stringify(item))
        // props.fetchTargetInvoiceQuerySingle(item.id);
        history.push('/target-invoice-query/excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        props.getDynamicQuery(item.id);
        sessionStorage.setItem('target-invoice-query', JSON.stringify(item))
        history.push('/target-invoice-query/excel-upload/view/' + item.id);
    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }

    return (
        <div>
            <div className={clsx(classes.root, className)}>

                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Commitment Data File Upload </Typography>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => history.push('/target-invoice-query/excel-upload/add-query')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                </div>
                {props.queryListData && props.queryListData.length > 0 ?
                    <TableContainer style={{ marginTop: -20 }}>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {props.queryListData.map(item => {
                                    return (
                                        <StyledTableRow key={item.id} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                            {props.loading ?
                                                <TableCell width={300} align='center'>
                                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                </TableCell>
                                                :
                                                <TableCell align='center' width={300}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        <IconButton onClick={() => clone(item)} classes={{ root: classes.IconButton }} size={'small'}>
                                                            <img src={require('../../../library/images/Copy.png')} alt='' />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleDrop(e, item)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runQuery(item)}
                                                            size="large">
                                                            <PublishIcon color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputBatchFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleBatchDrop(e, item)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runBatchQuery(item, 'Material Master Data')}
                                                            size="large">
                                                            <UploadFileIcon color="disabled" classes={{ root: classes.caption }} disabled={true} />
                                                        </IconButton>
                                                        <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => {
                                                                    setOpenDelete(true);
                                                                }}
                                                                size="large">
                                                                <DeleteForever color="disabled" style={{ fontSize: 20 }} classes={{ root: classes.caption }} disabled={true} />
                                                            </IconButton>
                                                            <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>

                                                                <DialogContent classes={{
                                                                    root: classes.dialogContent
                                                                }}>
                                                                    <Typography variant="h4">
                                                                        <DialogContentText>
                                                                            Do you want to delete data?
                                                                        </DialogContentText>
                                                                    </Typography>
                                                                </DialogContent>
                                                                <DialogActions classes={{
                                                                    root: classes.dialog
                                                                }}>
                                                                    <Button onClick={() => setOpenDelete(false)} autoFocus color="primary" variant="outlined">No</Button>
                                                                    <Button onClick={() => {
                                                                        props.onDelete(item, item.id, 'delete');
                                                                        setOpenDelete(false);
                                                                    }} color="primary" autoFocus variant="contained">Yes</Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
                }
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={errorDialogOpen}
                >
                    <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                        <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                    </DialogActions>
                    <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                    <DialogContent classes={{
                        root: { padding: '43px 37px 0px 37px !important' }
                    }}>

                        <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                        <span style={{ color: 'grey' }}>Error Details</span>
                        <List>
                            {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                                return (<ListItem>
                                    <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                    <ListItemText style={{ color: '#FF1A1A' }}
                                        primary={item}
                                    />
                                </ListItem>
                                )
                            })}
                        </List>
                    </DialogContent>
                </Dialog>
            </div >
        </div>
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.dataSetupData.targetInvoiceQueryListData,
        loading: state.dataSetupData.loading,
        targetDataErrorList: state.dataSetupData.targetDataErrorList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTargetInvoiceQueryList: () => dispatch(getTargetInvoiceQueryList()),
        runDynamicQuery: (data) => dispatch(runDynamicQuery(data)),
        getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
        uploadSalesData: (file, currentEditableID) => dispatch(targetDataUploadNew(file, currentEditableID)),
        fetchTargetInvoiceQuerySingle: (id) => dispatch(fetchTargetInvoiceQuerySingle(id)),
        onDelete: (data, id, type) => dispatch(putTargetDataQuery(data, id, type)),
        cloneMasterQuery: (id) => dispatch(cloneMasterQuery(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetInvoiceQueryList);