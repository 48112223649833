import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Card,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getMaterialMasterXRefDetails, getDefaultValuesMaterialXRef } from '../../redux/actions';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { Link } from 'react-router-dom';
var editableId = '';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
}));

const ViewMaterialMasterXMaster = props => {
    const classes = useStyles();
    const [channelPartnerTypeArray, setChannelPartnerTypeArray] = React.useState([]);
    const [materialStore, setMaterialStore] = React.useState({});
    const [channelPartnerType, setChannelPartnerType] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerMaterialId, setChannelPartnerMaterialId] = React.useState('');
    const [channelPartnerUoM, setChannelPartnerUoM] = React.useState('');
    const [imaMaterialId, setImaMaterialId] = React.useState('');
    const [imaUoM, setImaUoM] = React.useState('');
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getMaterialMasterXRefDetails(appId);
    }, []);
    const contractCustomisationUpdation = useRef();
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'channel_partner_type') {
                            setChannelPartnerTypeArray(item);
                        }
                    })
                }
            }
        }
    });
    useEffect(() => {
        if (props.materialMasterXRefData) {
            setChannelPartnerType(props.materialMasterXRefData.channel_partner_type);
            setChannelPartnerId(props.materialMasterXRefData.channel_partner_id);
            setChannelPartnerMaterialId(props.materialMasterXRefData.channel_partner_material_id);
            setChannelPartnerUoM(props.materialMasterXRefData.channel_partner_uo_m);
            setImaMaterialId(props.materialMasterXRefData.ima_material_id);
            setImaUoM(props.materialMasterXRefData.ima_uo_m);
        }
    }, [props.materialMasterXRefData]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/material-x-master'
                >
                    Material Master XREF
                </Link>
                <Typography color="textPrimary" variant='h1'>View Material XREF</Typography>
            </Breadcrumbs>
            {props.materialMasterXRefData &&
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    { channelPartnerType &&
                                    <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{root:classes.formLabel}} heading='Channel Partner Type' data={channelPartnerType} twoline='true' />  
                                </Grid>
                                    }
                                    { channelPartnerId &&
                                    <Grid
                                    item
                                    md={3}
                                    xs={12}
                                > 
                                <LabelText classes={{root:classes.formLabel}} heading='Channel Partner ID' data={channelPartnerId} twoline='true' />   
                                </Grid>
                                    }
                                    { channelPartnerMaterialId &&<Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{root:classes.formLabel}} heading='Channel Partner Material ID' data={channelPartnerMaterialId} twoline='true' />   
                                    </Grid>}
                                    { channelPartnerUoM &&<Grid
                                        item
                                        md={1}
                                        xs={12}
                                    >
                                        <LabelText classes={{root:classes.formLabel}} heading='Channel Partner UoM' data={channelPartnerUoM} twoline='true' />   
                                    </Grid>}
                                    { imaMaterialId &&<Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{root:classes.formLabel}} heading='IMA Material ID' data={imaMaterialId} twoline='true' />   
                                    </Grid>}
                                    { imaUoM &&<Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                      <LabelText classes={{root:classes.formLabel}} heading='IMA UoM' data={imaUoM} twoline='true' />   
                                    </Grid>}
                                </Grid>
                            </div>
                        </form>
                    </Card>                    
                </div >
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        materialMasterXRefData: state.customerData.materialMasterXRefViewData,
        dropdownData: state.customerData.dropdownDataMaterialXRef,
        loadingAPI: state.customerData.loading
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMaterialMasterXRefDetails: (id) => dispatch(getMaterialMasterXRefDetails(id)),
        onLoadingDefault: () => dispatch(getDefaultValuesMaterialXRef())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewMaterialMasterXMaster);