import React, { useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Dropdown, Datepicker } from '../../components/Inputs';
import clsx from 'clsx';
import  secureLocalStorage  from  "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 45px 0px 45px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px',
        marginTop: 46
    },
    data: {
        color: theme.palette.text.grey,
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        marginBottom: 30
    }
}));

const AdminDataContainer = forwardRef((props, ref) => {
    useEffect(() => {
        if (props.editable)
            props.onChange(adminValues);
    });
    const classes = useStyles();
    const { className } = props;
    const createdByRef = useRef(null);
    const createdOnRef = useRef(null);
    const changedByRef = useRef(null);
    const changedOnRef = useRef(null);
    const [createdBy, setCreatedBy] = React.useState('');
    const [createdOn, setCreatedOn] = React.useState('');
    const [changedBy, setChangedBy] = React.useState('');
    const [changedOn, setChangedOn] = React.useState('');
    const adminValues = {
        createdBy: createdBy,
        createdOn: createdOn,
        changedBy: changedBy,
        changedOn: changedOn
    };
    const reset = () => {
        setCreatedBy('');
        setCreatedOn('');
        setChangedBy('');
        setChangedOn('');
        createdByRef.current.reset();
        createdOnRef.current.reset();
        changedByRef.current.reset();
        changedOnRef.current.reset();
    };

    useImperativeHandle(ref, () => {
        return {
            reset: reset
        };
    });

    function handleCreatedBy(newValue) {
        setCreatedBy(newValue);
    }
    function handleCreatedOn(newValue) {
        setCreatedOn(newValue);
    }
    function handleChangedBy(newValue) {
        setChangedBy(newValue);
    }
    function handleChangedOn(newValue) {
        setChangedOn(newValue);
    }

    return (
        <div
            className={clsx(classes.root, className)}
        >
            {props.editable ?
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="ADMIN DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown heading='Created By' twoline='true' placeholder='Select' onChange={handleCreatedBy} data={createdBy} ref={createdByRef} />
                                    <Datepicker heading='Created On' twoline='true' onChange={handleCreatedOn} ref={createdOnRef} />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown heading='Changed By' twoline='true' placeholder='Select' onChange={handleChangedBy} data={changedBy} ref={changedByRef} />
                                    <Datepicker heading='Changed On' twoline='true' onChange={handleChangedOn} data={changedOn} ref={changedOnRef} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                :
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="ADMIN DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel >
                                        Created by
                                    </FormLabel>
                                    <div className={classes.data} >
                                        {secureLocalStorage.getItem('dYtz').username}
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel>
                                        Created On
                                    </FormLabel>
                                    <div className={classes.data} >
                                        
                                    </div>
                                </Grid>
                               
                            </Grid>
                        </div>
                    </form>
                </Card>
            }
        </div>
    );

});

export default AdminDataContainer;
