import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button,
    Checkbox, Dialog, DialogTitle,Box
} from '@mui/material';
import { connect } from 'react-redux';
import {  massUpdateMembershipAction, getLblDispDesFieldValue } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import CheckIcon from '@mui/icons-material/Check';
import {  Close } from '@mui/icons-material';

import HashLoader from 'react-spinners/HashLoader'
import ViewContract from '../../ViewContract/ViewContract';
import { MessageDialogue } from '../../MessageDialogue';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16,
        marginBottom: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    spinner: {
        height: '100vh'
    },
    table: {
        height: "100%",
        // width: "100%"
    },
    list: {},
    thead: {},
    tbody: {
        width: "100%",
        height: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        // alignItems: "center",
        boxSizing: "border-box",
        //maxWidth: "100%",
        //width: "100%"
    },
    paper: { minWidth: window.screen.width, height: '100%' },
    headerRow: {},
    cell: {
        display: "block",
        flexGrow: 0,
        flexShrink: 0
    },
    tableCell: {
        paddingLeft: 10,
        paddingRight: 10,
        textTransform: 'capitalize',
        flexWrap: "nowrap",
        //height:'auto'
    },
    expandingCell: {
        flex: 1
    },
}));


const MembershipMassUpdateResult = props => {

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [selectedApproved, setSelectedApproved] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [headings, setHeadings] = React.useState([]);
    const [contractTablesdata, setContractTablesdata] = React.useState([]);
    const headsNotAllowed = ['application_type', 'source_data_type', 'calculation_method', 'calculation_rules', 'eligibility_rules', 'incentive_rate_type', 'id', 'contract_id']
    const [dialogue, setDialogue] = React.useState(false);
    const [messageOpen, setMessageOpen] = React.useState(false);
    const [contractID, setContractID] = React.useState(0);
    const [contractRequestBody, setContractRequestBody] = React.useState([]);
    const [errorList, setErrorList] = React.useState([])
    const handleCheckAll = () => {
        var IDS = [];
            // setSelected(contractRequestBody.contract_ids);
            contractRequestBody&&contractRequestBody.contract_ids.map(item =>{
                IDS.push(item)
            })
            setSelected(IDS)
            if(selected.length>0) {
                setSelected([])
            }
        }
        // setIsCheckAll(!isCheckAll);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const approveContract = () => {
        contractRequestBody.contract_ids = selected
        props.onSubmit(contractRequestBody)
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
    };

    const handleClick = (event, name, item, type) => {
        let newSelected = [];
        let newSelectedApproved = [];
        const selectedIndex = selected.indexOf(name);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    function handleError() {
        setMessageOpen(false)
    }
    useEffect(() => {
        var head = ['ID']
        var newArray2=[]
        if (props.contractData && props.contractData.selected_contracts) {
            if (props.tableData) {
                Object.values(props.tableData.field_label_attributes).map((item, index) => {
                    if (item.sort_list) {
                        newArray2.push(item.key)
                    }
                })}
            props.contractData.selected_contracts[0] && Object.entries(props.contractData.selected_contracts[0])
             .filter(([k,v])=> newArray2.includes(k))   
            .map(([k, v]) => {
                    if (!headsNotAllowed.includes(k))
                        head.push(k)
                })
            setHeadings(head)
            setContractTablesdata(props.contractData.selected_contracts)
            if (props.contractData.request_body && props.contractData.request_body.contract_ids) {
                setContractRequestBody(props.contractData.request_body)
                setSelected(props.contractData.request_body.contract_ids)
                setIsCheckAll(!isCheckAll);
            }
        }
    }, [props.contractData,props.tableData])
    useEffect(() => {
        setErrorList(props.message)
        if (props.message && props.message.messageLog && props.message.messageLog.length > 0) {
            setMessageOpen(true);
        }
    }, [props.message])
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} style={{ justifyContent: 'space-between' }} >
                    <Typography variant="h2"> Mass Update Membership </Typography>
                    {contractTablesdata && contractTablesdata.length > 0 &&
                        <div className={classes.buttonRoot}>
                            <Button
                                variant="contained"
                                color="primary"
                                classes={{
                                    startIcon: !isDesktop && classes.startIcon,
                                    root: !isDesktop && classes.container
                                }}
                                onClick={approveContract}
                                startIcon={<CheckIcon />}
                                style={{ margin: 10 }}
                                disabled={!selected.length > 0 && !selectedApproved.length > 0 ? true : false}
                            >
                                Confirm
                            </Button>
                        </div >
                    }
                </div>
                {contractTablesdata &&  contractTablesdata.sort(function (a, b) {
                return a.ID < b.ID ? 1 : -1;
            }) && contractTablesdata.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell>
                                        <Checkbox
                                            onChange={handleCheckAll}
                                        />
                                    </TableCell>
                                    {headings && headings.map(item => (
                                        <TableCell align='center' classes={{ root: classes.cID }} style={{ textTransform: 'capitalize' }}>{item.replace(/_/g, ' ')} </TableCell>
                                    ))}
                                    {/* <TableCell>Actions</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {contractTablesdata && contractTablesdata
                                    .sort((a, b) => a.contract_id < b.contract_id ? 1 : -1)
                                    .map((item, i) => 
                                    { const isItemSelected = isSelected(contractTablesdata[i] && contractTablesdata[i]['ID']);
                                        return(
                                        
                                        <StyledTableRow key={contractTablesdata[i] && contractTablesdata[i]['ID']} className={classes.hover}
                                            hover
                                            onClick={(event) => handleClick(event, contractTablesdata[i]['ID'], contractTablesdata[i])}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color='primary'
                                                    checked={isItemSelected}
                                                    key={contractTablesdata[i] && contractTablesdata[i]['ID']}
                                                    selected={isItemSelected}

                                                />
                                            </TableCell>
                                            {contractTablesdata[i] && Object.entries(contractTablesdata[i]).filter(([k, v], i) => headings.includes(k))
                                                .sort(([k, v], [k1, v1]) => headings.indexOf(k) - headings.indexOf(k1))
                                                .map(([k, v]) => {
                                                    return (
                                                        <>

                                                            <TableCell align='center' >
                                                                {v  === '0001-01-01T00:00:00Z'  &&  Moment.utc(v).format('MM/DD/YYYY')||v}
                                                            </TableCell>
                                                        </>

                                                    )
                                                })}
                                            {/* <TableCell align='center' ><Tooltip title='view More'>
                                                <IconButton classes={{ root: classes.IconButton }} size={'small'} onClick={(e) => handleItemClick(e, item.id)}>
                                                    <Visibility color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                </IconButton>
                                            </Tooltip></TableCell> */}
                                        </StyledTableRow>
                                    )}
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog
                onClose={() => setDialogue(false)}
                aria-labelledby="simple-dialog-title"
                open={dialogue}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <DialogTitle style={{ justifyContent:'flex-end'}}>
                    <Box>
                    <IconButton
                        onClick={() => setDialogue(false)}
                        style={{float:'right',color:'black'}}
                        size="large">
                       <Close />
                   </IconButton>
                    </Box>
                </DialogTitle>
                <ViewContract mode='massUpdate' id={contractID} />
            </Dialog>
            {messageOpen&&
            <MessageDialogue open={messageOpen} messageList={errorList} onChange={handleError}/>
            }
        </div >
    );
};


const mapStateToProps = state => {
    return {
        loading: state.customerData.loading,
        contractData: state.customerData.membershipMassUpdate,
        message: state.customerData.membershipMassUpdateAction,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(massUpdateMembershipAction(data)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),     
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipMassUpdateResult);


