import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { getAnalysisDesignerData, deleteAnalysisDesigner } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { AddBoxOutlined, LibraryAdd, DeleteForever, Edit } from '@mui/icons-material';
import SimpleDialog from '../../components/Dialog/SimpleDialog';
import Table from '../../components/Inputs/Table';
import { Visibility } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const AnalyticsDesignerMain = props => {
    useEffect(() => {
        props.getAttributeMapData(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [currentId, setCurrentId] = React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAttributeMapData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAttributeMapData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.analysisData) {
            const filteredData = props.analysisData.filter(item => item.dashboard_name === "");
            filteredData.sort((a, b) => (a.application_type > b.application_type ? 1 : -1));
            setAttributeData(filteredData);
        }
    }, [props.analysisData]);
    function editAttribute(id) {
        history.push({
            pathname: '/analytics-designer/edit-analytics/' + id,
            id: id
        });
    }
    function viewAttribute(id) {
        history.push({
            pathname: '/analytics-designer/view-analytics/' + id,
            id: id
        });
    }
    const deleteData = (id) => {
        props.deleteAnalysisDesigner(id);
    }

    const handleClickOpen = (Id) => {
        setOpen(true);
        setCurrentId(Id);
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            deleteData(currentId);
        } else {
            setOpen(false);
        }
    }
    const capitalizeWords = (rolesArray) => {
        return rolesArray.map(role => role.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ');
    };
    const arrayItem = [];
    const columns = [
        {
            field: "application_type",
            title: "Application Type",
            editable: 'never',
            type: 'string',
            render: (item) => (
                <div

                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.application_type}
                </div>
            ),
        },
        // {
        //     field: 'security_role',
        //     title: 'Persona',
        //     type: 'string',
        //     render: rowData =>
        //         Array.isArray(rowData.security_role) ? rowData.security_role.join(', ') : rowData.security_role,
        // },
        {
            field: 'security_role',
            title: 'Persona',
            type: 'string',
            render: rowData => {
                const roles = Array.isArray(rowData.security_role) ? capitalizeWords(rowData.security_role) : rowData.security_role;
                return (
                    <div

                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        {roles}
                    </div>
                );
            },
        },
        {
            field: 'analytic_name',
            title: 'Analytic Name',
            type: 'string',
            render: (item) => (
                <div

                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.analytic_name}
                </div>
            ),
        },
        {
            field: 'analytic_description',
            title: 'Analytic Description',
            type: 'string',
            render: (item) => (
                <div

                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.analytic_description}
                </div>
            ),
        },
        {
            field: 'action',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => viewAttribute(rowData.ID)}
                        size="large">
                        <Visibility color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_analytics_designer-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editAttribute(rowData.ID)}>
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    }
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_analytics_designer-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleClickOpen(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    }
                </div>

        },
    ];


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Analytics Designer </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_analytics_designer-' + sessionStorage.getItem('application')) &&
                        <div>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                //    component={CustomRouterLink}
                                onClick={() => history.push('/analytics-designer/add-analytics')}
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                        </div>}

                </div>
                {/* {attributeData && attributeData.length > 0 ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Persona</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Analytic Name </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Analytic Description </TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {attributeData.map(item => {
                                    arrayItem.push(item.application_type)
                                    arrayItem.sort(function (a, b) {
                                        return a.localeCompare(b); //using String.prototype.localCompare()
                                    });
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  style={{ cursor: 'pointer' }}>
                                                {item.application_type}{
                                                }</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  style={{ cursor: 'pointer' }}>
                                                {Array.isArray(item.security_role) ? item.security_role.join(', ') : item.security_role}
                                            </TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.analytic_name}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.analytic_description}</TableCell>
                                            <TableCell align='center'> */}

                { /*<IconButton classes={{ root: classes.IconButton }} onClick={() => editAttribute(item.ID)}>
                                                    <Edit color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton> 
                                            */}
                {/* <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => deleteData(item.ID)}
                                                    size="large">
                                                    <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table> */}
                {/* <TablePagination
                            component="div"
                            count={props.analysisData && props.analysisData.length > 0 ? props.analysisData.length : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                {/* {props.attributeData &&
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>onClick={() => viewAttribute(item.ID)}
                            </div>
                        }
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                } */}
                {attributeData && attributeData.length > 0 ?
                    <Table columns={columns} dataRows={attributeData} />
                    :
                    <div>
                        There is no data to be shown
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        analysisData: state.AnalyticsDesignerData.AnalysisData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAttributeMapData: (pagination, limit) => dispatch(getAnalysisDesignerData(pagination, limit, "analytics")),
        deleteAnalysisDesigner: (id) => dispatch(deleteAnalysisDesigner(id, "analytics"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDesignerMain);