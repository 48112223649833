
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { Card } from '@mui/material';
import { editOutgoingClaim } from '../../../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import clsx from 'clsx';
import Moment from 'moment';
import 'react-edit-text/dist/index.css';
import { PopupContainer } from '..';

const useStyles = makeStyles(theme => ({
    rootMain: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
}));

const InitialData = props => {
    const classes = useStyles();
    const [dataRows, setDataRows] = React.useState([]);
    const { className } = props;
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.editOutgoingClaim(appId);

    }, []);

    const theme = createTheme({
        overrides: {
            MuiTableCell: {
                root: {
                    padding: 10
                },
            },
            MuiToolbar: {
                root: {
                    minHeight: 0
                },
            },
        }
    });

    useEffect(() => {
        var tempRows = [];
        if (props.outgoingClaimDetailData && props.outgoingClaimDetailData.length > 0)
            props.outgoingClaimDetailData
                .filter(e => e.claim_status == 'Initial')
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        unique_identifier: e.unique_identifier,
                        claim_amount: e.claim_amount,
                        approved_amount: e.approved_amount,
                        end_customer_id: e.end_customer_id,
                        end_customer_name: e.end_customer_name,
                        material_id_number: e.material_id_number,
                        contract_number: e.contract_number,
                        claim_status: e.claim_status,
                        claim_action: e.claim_action,
                        claim_error: e.claim_error,
                        material_number: e.material_number,
                        material_description: e.material_description,
                        pricing_date: e.pricing_date
                    });
                })
        setDataRows(tempRows);
    }, [props.outgoingClaimDetailData]);
    const [currentViewID, setCurrentViewID] = React.useState(0);
    const handlePopupClick = (rowData) => {
        setDialogOpen(true);
        setCurrentViewID(rowData.id);

    }



    const columns = [
        {
            field: 'unique_identifier',
            title: 'Unique Identifier',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.unique_identifier ? <div style={{
                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
            }} onClick={() => handlePopupClick(rowData)}>{rowData.unique_identifier}</div> : <div style={{ color: 'white' }}>-</div>

        },
        {
            field: 'end_customer_id',
            title: 'End Customer ID',
            render: rowData => rowData.end_customer_id ? rowData.end_customer_id : <div style={{ color: 'white' }}>-</div>,
            type: 'string',
        },
        {
            field: 'end_customer_name',
            title: 'End Customer Name',
            render: rowData => rowData.end_customer_name,
            type: 'string',
        },

        {
            field: 'material_number',
            title: '  Material Number',
            type: 'string',
            render: rowData => rowData.material_number ? rowData.material_number : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'material_description',
            title: '  Material Description',
            type: 'string',
            render: rowData => rowData.material_description,
        },
        {
            field: 'contract_number',
            title: '  Contract Number',
            type: 'number',
            render: rowData => rowData.contract_number ? rowData.contract_number : <div style={{ color: 'white' }}>-</div>,
        },
        {
            field: 'pricing_date',
            title: 'Pricing Date',
            type: 'date',
            editable: 'never',
            render: rowData => rowData.pricing_date ? Moment(rowData.pricing_date).format('MM/DD/YYYY') : ''
        },
        {
            field: 'claim_amount',
            title: 'Claim Amount',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.claim_amount ? rowData.claim_amount.toFixed(2) : ''
        },

        {
            field: 'approved_amount',
            title: 'Approved Amount',
            type: 'string',
            render: rowData => rowData.approved_amount ? rowData.approved_amount.toFixed(2) : <div style={{ color: 'white' }}>-</div>,
        },

    ];
    const [dialogOpen, setDialogOpen] = React.useState(false)




    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider className={clsx(classes.rootMain, className)}>
                <PopupContainer open={dialogOpen} onClose={(value) => setDialogOpen(value)} outgoingClaimID={currentViewID} />

                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div>
                                <MaterialTable
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    columns={columns}
                                    data={dataRows}
                                    style={{ marginTop: -60 }}

                                    options={{
                                        search: false,
                                        filtering: true,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                                    }}

                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        editOutgoingClaim: (id) => dispatch(editOutgoingClaim(id)),
    }
}

const mapStateToProps = state => {
    return {
        outgoingClaimDetailData: state.initialData.outgoingClaimDetailData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialData);