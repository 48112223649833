import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Breadcrumbs,
} from '@mui/material';
import { connect } from 'react-redux';
import { getDefaultValues, batchApproveFinancial, getCalculationSummaryData, runContractTrends } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { tableIcons } from '../../../components/Icons/TableIcons';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { HashLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: "none",
        border: 0,
        fontSize: 16,
        paddingRight: 10,
    },
    spinner: {
        // height:'100vh'
    }
}));
const CalculationSummaryReportsTable = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([])
    const [count, setCount] = React.useState(0);
    const columns = [
        {
            field: 'contract_type',
            title: 'Contract Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.contract_type}</a>
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_number}</a>
        },
        {
            field: 'start_date',
            title: 'Start Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.start_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'end_date',
            title: 'End Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.end_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'calculation_simulation_number',
            title: 'Calculation Simulation Number',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => handleCalculationAnalysis(rowData)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}> {rowData.calculation_simulation_number}</a>

        },
        // {
        //     field: 'tier_basis',
        //     title: 'Tier Basis',
        //     type: 'string',
        //     render: rowData => <a className={classes.hover}> {parseFloat(rowData.tier_basis).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>

        // },
        {
            field: 'incentive_basis',
            title: 'Incentive Basis',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.incentive_basis).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'incentive_rate',
            title: 'Incentive Rate',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.incentive_rate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'incentive_amount',
            title: 'Incentive Amount',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.incentive_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'created_date',
            title: 'Created Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_date}</a>
        },
        {
            field: 'created_time',
            title: 'Created Time',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>
                {rowData.created_time}
                {/* {Moment.utc(rowData.created_time).local().format('HH:mm:ss')} */}
            </a>
        },
        {
            field: 'created_by',
            title: 'Created By',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.created_by}</a>
        },
    ];
    useEffect(() => {
        if(!props.calculationSummaryData){
            props.getCalculationSummaryData(1,0,JSON.parse(localStorage.getItem('R001')))
        }
    }, [])
    useEffect(() => {
        if (props.calculationSummaryData && props.calculationSummaryData.records && props.calculationSummaryData.total_record) {
            setDataRows(props.calculationSummaryData.records)
            setCount(props.calculationSummaryData.total_record)
        }
    }, [props.calculationSummaryData])
    const handleCalculationAnalysis = (item) => {
        var formData = {
            "start_date": Moment.utc(item.start_date).format('MM/DD/YYYY'),
            "end_date": Moment.utc(item.end_date).format('MM/DD/YYYY'),
            "application_type": sessionStorage.getItem('application'),
            "data_source_type": 'Direct - Sales Data',
            "contract_number": item.contract_number,
            //"posting_number": item.posting_number,
            "posting_number": item.calculation_simulation_number,
            "posting_type": item.posting_type === 'Delta Payment' ? 'payment' :
                item.posting_type === 'Delta Accrual' ? 'accrual' :
                    item.posting_type === 'Accrual Reversal' ? 'accrual' :
                        item.posting_type === 'Payment Reversal' ? 'payment' :
                            item.posting_type
        };
        props.runContractTrends(formData);
        sessionStorage.setItem('contractTrendsQuery', JSON.stringify(formData))
    }
    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    variant="h4"
                    classes={{
                        root: classes.link,
                    }}
                    color='Primary'
                    to="/calculation-summary-reports"
                >Calculation Summary</Link>
                <Typography color="textPrimary" variant="h4">Results </Typography>
            </Breadcrumbs>

            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >


                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        // style={{marginTop:'-18px'}}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.addContractData.loading,
        contractTabledataComplete: state.addContractData.contractListDataComplete,
        calculationSummaryData: state.addMultipleConfigurationData.calculationSummaryData,
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getCalculationSummaryData: (data) => dispatch(getCalculationSummaryData(data)),
        onCheckContractsTable: (pagination, limit, keyword) => dispatch(getCalculationSummaryData(pagination, limit, keyword)),
        batchApproveFinancial: (items, status) => dispatch(batchApproveFinancial(items, status)),
        getCalculationSummaryData: (pagination, limit, keyword) => dispatch(getCalculationSummaryData(pagination, limit, keyword)),
        onLoadingDefault: (application) => dispatch(getDefaultValues(application, 'Direct - Sales Data', null, null, 'Display Designer')),
        runContractTrends: (formData) => dispatch(runContractTrends(formData, "contractV3")),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CalculationSummaryReportsTable);