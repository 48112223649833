import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,

} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getDefaultValues, getApprovalDesignerDetails, getApprovalGroupData } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from 'react-spinners/RingLoader';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 16px 16px'
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const EditApprovalDesigner = props => {
    const classes = useStyles();
    const [approvalGroupData, setApprovalGroupData] = React.useState([]);
    useEffect(() => {
        if (props.approvalGroupData) {
            setApprovalGroupData(props.approvalGroupData)
        }
    }, [props.approvalGroupData])
    useEffect(() => {
        props.onLoadingDefault();
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getApprovalDesignerDetails(appId)
        props.getApprovalGroupData();
    }, []);

    useEffect(() => {
        if (props.approvalData) {

            setUserStore([props.approvalData])
        }
    }, [props.approvalData]);



    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [userStore, setUserStore] = React.useState([]);



    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/approval-limits'
                    >
                        Approval Levels
                    </Link>
                    <Typography color="textPrimary" variant='h4'>View Approval</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <div className={classes.container2}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {userStore.length > 0 ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Functionality</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Primary Anchor</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Primary Anchor Value</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Secondary Anchor</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Secondary Anchor Value</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Step Number</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Step Description</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}> Threshold Type</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Positive Threshold Value</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Negative Threshold Value</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Threshold Unit</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Approval Group</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Notification Group</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>External Approval</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {userStore.map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={index}>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.application_type}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.functionality.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.primary_anchor.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.primary_anchor_value}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.secondary_anchor.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.secondary_anchor_value}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.step_number}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.step_description}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.incentive_threshold_type.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.incentive_amount != 0 ? item.incentive_amount : ''}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.negative_threshold_value != 0 ? item.negative_threshold_value : ''}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.currency}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>
                                                                {approvalGroupData && approvalGroupData.records && approvalGroupData.records.length > 0 &&
                                                                    approvalGroupData.records.map(item2 => {
                                                                        if (item2.ID === item.approval_group) {
                                                                            return item2['name']
                                                                        }
                                                                    })
                                                                }

                                                            </TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.notification_group}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.external_approval}</TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography variant='h4'>
                                        No Data
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                    </div>

                </div >
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {

        onLoadingDefault: () => dispatch(getDefaultValues()),
        getApprovalDesignerDetails: (id) => dispatch(getApprovalDesignerDetails(id)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetDetail,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditApprovalDesigner);