import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,

} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getCustomerMasterXRefDetails, getDefaultValuesCustomerXRef } from '../../redux/actions';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { Link } from 'react-router-dom';

var editableId = '';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
}));

const ViewCustomerXMaster = props => {
    const classes = useStyles();
    const [channelPartnerType, setChannelPartnerType] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerEndCustomerId, setChannelPartnerEndCustomerId] = React.useState('');
    const [imaEndCustomerId, setImaEndCustomerId] = React.useState('');
    const [streetAddress, setStreetAddress] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getCustomerMasterXRefDetails(appId);
    }, []);
    useEffect(() => {
        if (props.customerMasterXRefData) {
            setChannelPartnerType(props.customerMasterXRefData.channel_partner_type);
            setChannelPartnerId(props.customerMasterXRefData.channel_partner_id);
            setChannelPartnerEndCustomerId(props.customerMasterXRefData.channel_partner_end_customer_id);
            setImaEndCustomerId(props.customerMasterXRefData.ima_end_customer_id);
            setStreetAddress(props.customerMasterXRefData.street_address);
            setCity(props.customerMasterXRefData.city);
            setRegion(props.customerMasterXRefData.region);
            setPostalCode(props.customerMasterXRefData.postal_code);
            setCountry(props.customerMasterXRefData.country);
        }
    }, [props.customerMasterXRefData]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/customer-x-master'
                >
                    Customer Master XREF
                </Link>
                <Typography color="textPrimary" variant='h1'>View Customer XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container}>
                    <Grid container spacing={2} style={{ paddingLeft: 10 }}>
                        {channelPartnerType &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Channel Partner Type' data={channelPartnerType} twoline='true' />
                            </Grid>
                        }
                        {channelPartnerId &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Channel Partner ID' data={channelPartnerId} twoline='true' />
                            </Grid>
                        }

                        {channelPartnerEndCustomerId &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Channel Partner End Customer ID' data={channelPartnerEndCustomerId} twoline='true' />
                            </Grid>
                        }
                        {imaEndCustomerId &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='IMA End Customer ID' data={imaEndCustomerId} twoline='true' />
                            </Grid>
                        }
                        {streetAddress &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Address' data={streetAddress} twoline='true' />
                            </Grid>
                        }
                        {country &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Country' data={country} twoline='true' />
                            </Grid>
                        }
                        {region &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Region' data={region} twoline='true' />
                            </Grid>
                        }
                        {postalCode &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Postal Code' data={postalCode} twoline='true' />
                            </Grid>
                        }
                    </Grid>
                </div>
            </div >
        </div>
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        customerMasterXRefData: state.customerData.customerMasterXRefViewData,
        loadingAPI: state.customerData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getCustomerMasterXRefDetails: (id) => dispatch(getCustomerMasterXRefDetails(id)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomerXMaster);