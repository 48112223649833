import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, Button, IconButton, Fab, Card, CardContent, CardActions,
    Checkbox, CardHeader
} from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import { Close, FilterList, Filter1, Filter2, Filter3 } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { createPriceListData, getAllDistinctPriceListData, getPriceMaintenanceFields, getPriceMaintenanceFieldsByPriceType, getUniquePriceData } from '../../../../redux/actions/Pricing/AuthAction';

const useStyles = makeStyles(theme => ({

    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    filterList: {
        height: 350,
        maxHeight: 350,
        overflowY: 'scroll',
        scrollBehaviour: ' smooth',
        MarginTop: 0,
        fontSize: 16,
        paddingLeft: 15,
    },
    SnackbarContainer: {
        position: "absolute",
        zIndex: 2000,
        top: 50,
        width: 500,
        height: 500,
        maxHeight: 500,
        color: '#0D1333',
        border: '1px solid #565866',
        fontWeight: 'bold'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    cardTitle: {
        padding: 10,
        marginTop: 0,
    },
    pointer: {
        display: 'block',
        width: ' 20px',
        height: '20px',
        border: '1px solid transparent',
        borderTopColor: 'black',
        borderLeftColor: 'black',
        position: 'absolute',
        top: '12px',
        left: '-11px',
        //transform:Rotate,
        background: 'white',
    },
    tabTitle: {
        textTransform: 'capitalize',
        fontSize: 16,
        paddingRight: 15,
        alignItems: 'flex-start'
    },
    tabbody: {
        fontSize: 16,
        paddingLeft: 15,

    }
}));
const FilterComponent = props => {
    useEffect(() => {
        props.getConfigData(null, null, filterValue);
        props.getUniquePriceData()
    }, []);
    var formdata = [];
    var selectedId = [];
    const tableRef = useRef(null);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [priceListId, setPriceListId] = React.useState([]);
    const [priceListType, setPriceListType] = React.useState([]);
    const [priceListName, setPriceListName] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [configData, setConfigData] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [priceListUnique, setPriceListUnique] = React.useState([]);
    const [priceIDUnique, setPriceIDUnique] = React.useState([]);
    const [priceTypeUnique, setPriceTypeUnique] = React.useState([]);
    const [priceType, setPriceType] = React.useState('');
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [formData, setFormData] = React.useState([]);
    const [isValid, setIsValid] = React.useState(false);
    const [filterData, setFilterData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [filterValue, setFilterValue] = React.useState({ 'price_list_id': [], 'price_type_name': [], 'price_type': [] });
    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(filterValue)
    };
    var selectedData = []
    useEffect(() => {
        if (props.filterList) {
            if (props.filterList.price_type_name)
                setPriceListUnique(props.filterList.price_type_name)
            if (props.filterList.price_list_id) {
                setPriceIDUnique(props.filterList.price_list_id)
            }
            if (props.filterList.price_type)
                setPriceTypeUnique(props.filterList.price_type)
        }
    }, [props.filterList])

    const handleClick = (event, item, type) => {
        let newSelected = [];
        let newPriceId = []
        const selectedIndex = filterValue[type] && filterValue[type].indexOf(item);
        var array = filterValue && filterValue[type] ? filterValue[type] : [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(array, item)
        }
        else {
            if (selectedIndex === 0) {
                newSelected = newSelected.concat(array.slice(1));
            } else if (selectedIndex === filterValue[type] && filterValue[type].length - 1) {
                newSelected = newSelected.concat(array.slice(0, -1));
            } else
                newSelected = newSelected.concat(
                    array.slice(0, selectedIndex),
                    array.slice(selectedIndex + 1),
                );
        }
        console.log(newSelected)
        filterValue[type] = newSelected
        setFilterValue(filterValue)
        if (type == 'price_type_name')
            setPriceListName(newSelected)
        else if (type == 'price_list_id')
            setPriceListId(newSelected)
        else
            setPriceListType(newSelected)
    };

    const handleCheckAll = () => {
        console.log(tableRef)
        var IDS = [];
        var data = []
        if (!isCheckAll) {
            configData && configData.map((item, i) => {
                data.push({ 'price_list_id': item.price_list_id, "price_type": item.price_type, "price_type_name": item.price_type_name })
                IDS.push(i + 1)
            })
            setFormData(data)
            setSelected(IDS);
        } else {
            setSelected([]);
            setFormData([])
        }
        setIsCheckAll(!isCheckAll);
    }
    const isSelected = (id) => selected.indexOf(id) !== -1;

    function handleSelect(e) {
        selectedData = e
        var formdata = []
        var selectedId = []
        selectedData.map((item, i) => {
            formdata.push({
                'price_list_id': item.price_list_id,
                "price_type": item.price_type,
                "price_type_name": item.price_type_name,
                "calculation_type": item.calculation_type
            })
            selectedId.push(item.id)
        })
        console.log(formdata)
        setFormData(formdata)
        //var selectedId=selectedData.map(item=>item.id)
        setSelected(selectedId)
        if (selectedData && selectedData.length > 0) {
            var len = (dataRows.filter((k, i) => k.number_of_records == 0 && selectedData.findIndex(({ id }) => id == k.id) > -1)).length
            if (len > 0 && (len == selectedData.length)) {
                setIsValid(false)
            }
            else
                setIsValid(true)
        }
    }
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    function handleFilter() {
        props.getConfigData(null, null, filterValue)
        setOpen(false)
    }
    function handleClear() {
        filterValue['price_type_name'] = []
        filterValue['price_type'] = []
        filterValue['price_list_id'] = []
        setFilterValue(filterValue)
        setPriceListId([])
        setPriceListName([])
        setPriceListType([])
    }
    return <>

        <Fab aria-label="edit" variant="extended"
            size='medium'
            className={classes.fabContainer}
            onClick={() => setOpen(!open)}
        >
            <FilterList color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Filter</Typography>
        </Fab>
        {open &&
            <Card variant="outlined" classes={{ root: classes.SnackbarContainer }} >
                <CardHeader classes={{ root: classes.cardTitle }}
                    title={<span style={{ fontSize: '18px' }}>Filters</span>}
                    action={
                        <IconButton aria-label="add" onClick={() => setOpen(!open)} size="large">
                            <Close color="disabled" />
                        </IconButton>
                    }
                    titleTypographyProps={{ variant: 'h3', fontsize: 16 }}
                />
                <CardContent>
                    <div style={{ display: 'flex' }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            style={{ alignItems: 'left' }}
                        >
                            {['Price List ID', 'Price Type Name', 'PriceType'].map((text, index) => (
                                <Tab label={text} className={classes.tabTitle} style={{
                                    textTransform: 'capitalize',
                                    fontSize: 16,
                                    paddingRight: 15,
                                    alignItems: 'flex-start'
                                }}
                                />
                            ))}
                        </Tabs>
                        {['Price List ID', 'Price Type Name', 'PriceType'].map((item, index) => (
                            <div className={classes.filterList}
                                role="tabpanel"
                                hidden={value !== index}
                                id={`vertical-tabpanel-${index}`}
                                aria-labelledby={`vertical-tab-${index}`}>
                                <List>
                                    {value == 0 ? priceIDUnique && priceIDUnique.map((text, index) => (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton onClick={(event) => handleClick(event, text, 'price_list_id')}>
                                                <Checkbox
                                                    color='primary'
                                                    size='small'
                                                    style={{ padding: '0px 10px 0px 0px' }}
                                                    checked={priceListId && priceListId.includes(text)}
                                                    key={text}
                                                    selected={priceListId && priceListId.includes(text)}

                                                />
                                                <ListItemText primary={text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                        :
                                        value == 1 ? priceListUnique && priceListUnique.map((text, index) => (
                                            <ListItem key={text} disablePadding>
                                                <ListItemButton onClick={(event) => handleClick(event, text, 'price_type_name')}>
                                                    <Checkbox
                                                        color='primary'
                                                        size='small'
                                                        style={{ padding: '0px 10px 0px 0px' }}
                                                        checked={priceListName && priceListName.includes(text)}
                                                        key={text}
                                                        selected={priceListName && priceListName.includes(text)}
                                                    />
                                                    <ListItemText primary={text} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                            :
                                            priceTypeUnique && priceTypeUnique.map((text, index) => (
                                                <ListItem key={text} disablePadding>
                                                    <ListItemButton onClick={(event) => handleClick(event, text, 'price_type')}>
                                                        <Checkbox
                                                            color='primary'
                                                            size='small'
                                                            style={{ padding: '0px 10px 0px 0px' }}
                                                            checked={priceListType && priceListType.includes(text)}
                                                            key={text}
                                                            selected={priceListType && priceListType.includes(text)}
                                                        />
                                                        <ListItemText primary={text} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))
                                    }
                                </List>
                            </div>
                        ))}
                    </div>

                </CardContent>
                <CardActions>
                    <Button size="large" color='disabled' style={{ width: '50%' }} variant='contained' onClick={handleClear}>Clear All</Button>
                    <Button size="large" color='primary' style={{ width: '50%' }} variant='contained' onClick={handleFilter}>Done</Button>
                </CardActions>
            </Card>}
    </>;
};

const mapStateToProps = state => {
    return {
        configData: state.pricingData.distinctPriceList,
        filterList: state.pricingData.uniquePriceData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit, data) => dispatch(getAllDistinctPriceListData(pagination, limit, '', data)),
        onDelete: (id) => dispatch(createPriceListData(null, id, null, 'delete')),
        getPriceMaintenanceFields: (data) => dispatch(getPriceMaintenanceFieldsByPriceType(data)),
        getUniquePriceData: () => dispatch(getUniquePriceData())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);