import React, { useEffect, forwardRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, Fab, Typography, TablePagination } from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { AddBoxOutlined, Edit, DeleteForever, CloudUpload, LibraryAdd, SearchSharp } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getSupplierMasterData, getSupplierMasterViewDetails, deleteCustomerMasterData, getLblDispDesFieldValue, getAcquireLockDetails } from '../../../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { NavLink as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../../../components/Dialog';
import HashLoader from "react-spinners/HashLoader";
import RingLoader from "react-spinners/RingLoader";
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        //marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }

    },
    buttonStyle2: {
        marginLeft: 10, backgroundColor: theme.palette.primary.main, textTransform: 'none'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const SupplierMasterUserTable = props => {
    useEffect(() => {
        // props.getSupplierMasterData(1, 500);
    }, []);
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [count, setCount] = React.useState(0);
    const [currentId, setCurrentId] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [columns2, setColumns2] = React.useState([]);
    // const [columns, setColumns] = React.useState([
    //     {
    //         field: 'supplier_number',
    //         title: 'Supplier Number',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_number}</a>
    //     },
    //     {
    //         field: 'supplier_name',
    //         title: 'Supplier Name',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_name}</a>
    //     },
    //     {
    //         field: 'supplier_type',
    //         title: 'Supplier Type',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_type}</a>
    //     },
    //     {
    //         field: 'street',
    //         title: 'Street',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.street}</a>
    //     },
    //     {
    //         field: 'city',
    //         title: 'City',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.city}</a>
    //     },
    //     {
    //         field: 'region',
    //         title: 'Region',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.region}</a>
    //     },
    //     {
    //         field: 'postal_code',
    //         title: 'Postal Code',
    //         type: 'number',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.postal_code}</a>
    //     },
    //     {
    //         field: 'country',
    //         title: 'Country',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.country}</a>
    //     },
    //     {
    //         field: 'claim_edi_format',
    //         title: 'Claim Edi Format',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.claim_edi_format}</a>
    //     },
    //     {
    //         field: 'claim_email_address',
    //         title: 'Claim Email Address',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.claim_email_address}</a>
    //     },
    //     {
    //         field: 'claim_excel_format',
    //         title: 'Claim Excel Format',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.claim_excel_format}</a>
    //     },
    //     {
    //         field: 'supplier_customer_number',
    //         title: 'Supplier Customer Number',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_customer_number}</a>
    //     },
    //     {
    //         field: 'external',
    //         title: 'External',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.external}</a>
    //     },
    //     {
    //         field: 'flex_attribute1',
    //         title: 'Flex Attribute 1',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.flex_attribute1}</a>
    //     },
    //     {
    //         field: 'flex_attribute2',
    //         title: 'Flex Attribute 2',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.flex_attribute2}</a>
    //     },
    //     {
    //         field: 'id_number01',
    //         title: 'Id Number 01',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number01}</a>

    //     },
    //     {
    //         field: 'id_number02',
    //         title: 'Id Number 02',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number02}</a>

    //     },
    //     {
    //         field: 'id_number03',
    //         title: 'Id Number 03',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number03}</a>

    //     },
    //     {
    //         field: 'id_number04',
    //         title: 'Id Number 04',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number04}</a>

    //     },
    //     {
    //         field: 'id_number05',
    //         title: 'Id Number 05',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number05}</a>

    //     },
    //     {
    //         field: 'id_number06',
    //         title: 'Id Number 06',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number06}</a>

    //     },
    //     {
    //         field: 'supplier_group',
    //         title: 'Supplier Group',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_group}</a>
    //     },
    //     {
    //         field: 'supplier_group1',
    //         title: 'Supplier Group 1',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_group1}</a>
    //     },
    //     {
    //         field: 'supplier_group2',
    //         title: 'Supplier Group 2',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_group2}</a>
    //     },
    //     {
    //         field: 'supplier_id_number',
    //         title: 'Supplier ID Number',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_id_number}</a>
    //     },
    //     {
    //         field: 'supplier_id_type',
    //         title: 'Supplier ID Type',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_id_type}</a>
    //     },
    //     {
    //         field: 'supplier_industry',
    //         title: 'Supplier Industry',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_industry}</a>
    //     },
    //     {
    //         field: 'supplier_status',
    //         title: 'Supplier Status',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.supplier_status}</a>
    //     },
    //     {
    //         field: 'Actions',
    //         title: 'Actions',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData =>
    //             <div style={{ whiteSpace: 'nowrap' }}>
    //                 {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_supplier_master-' + sessionStorage.getItem('application')) &&
    //                     <IconButton classes={{ root: classes.IconButton }} onClick={() => editContract(rowData.ID)}>
    //                         <Edit color="disabled" style={{ fontSize: 20 }} />
    //                     </IconButton>}
    //                 {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_supplier_master-' + sessionStorage.getItem('application')) &&
    //                     <IconButton classes={{ root: classes.IconButton }} onClick={() => handleClickOpen(rowData.ID)}>
    //                         <DeleteForever color="disabled" style={{ fontSize: 20 }} />
    //                     </IconButton>}
    //             </div>
    //     },
    // ]
    // );

    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length >= 0) {
            setDataRows(props.supplierMasterData.records);
            setCount(props.supplierMasterData.total_record)
            var data = { "data_source": "supplier_master" };
            props.getLblDispDesFieldValue(data, 'post');
        }
    }, [props.supplierMasterData]);

    useEffect(() => {
        var newArray = []
        var newArray2 = ['Actions']
        var tempObj = []

        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter((item, i) => item.sort_list)
                .sort((a, b) => {
                    return a.sort_list - b.sort_list;
                })
                .map((item, index) => {
                    if (item.sort_list) {
                        newArray2.push(item.default)
                        if (item.key === 'product_features') {
                            tempObj.push({
                                field: item.key,
                                title: item.current,
                                type: 'string',
                                editable: 'never',
                                render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {""}</a>
                            });
                        }
                        else {
                            tempObj.push({
                                field: item.key,
                                title: item.current,
                                type: 'string',
                                editable: 'never',
                                render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData[item.key]}</a>
                            });
                        }

                    }

                })
            tempObj.push({
                field: 'Actions',
                title: 'Actions',
                type: 'string',
                editable: 'never',
                render: rowData =>
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_supplier_master-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editContract(rowData)}
                                size="large">
                                <Edit color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>}
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_supplier_master-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleClickOpen(rowData.ID)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>}
                    </div>
            })
            setColumns2(tempObj)
        }
    }, [props.tableData])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getsupplierMasterData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setRowsPerPage(event.target.value);
        props.getsupplierMasterData(1, event.target.value);
    };

    function editContract(id) {
        checkEditingStatus(id)
        navigateToEdit(id.ID);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/supplier-master/edit-supplier/' + id,
            state: 'editContract',
            id: id
        });
        // localStorage.setItem('supplierMasterEditId', id);
    }

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteCustomerMasterData(currentId);
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };
    function handleItemClick(id) {
        props.getSupplierMasterViewDetails(id);
    }
    function checkEditingStatus(rowData) {
        let data = {
            "id": rowData.ID,
            "lock": "supplier_data"
        }
        props.getAcquireLockDetails(data)
    }
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Supplier Master </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_supplier_master-' + sessionStorage.getItem('application')) &&
                            <>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    classes={{ root: classes.fabContainer }}
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/supplier-master/add-supplier'
                                >
                                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                                </Fab>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/supplier-master/supplier-master-excel-upload'
                                >
                                    <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                                </Fab>
                            </>
                        }
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            component={CustomRouterLink}
                            to='/supplier-master/search-supplier'
                        >
                            <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                        </Fab>
                    </div>
                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns2}
                        // columns={columns2.filter((value, index, self) =>
                        //     index === self.findIndex((t) => (
                        //         t.title === value.title
                        //     ))
                        // )}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    /> :
                    <div className={classes.noDataContainer}>
                        Display Suppliers by clicking on Search Icon.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        supplierMasterData: state.customerData.supplierMasterData,
        loading: state.customerData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getSupplierMasterViewDetails: (id) => dispatch(getSupplierMasterViewDetails(id)),
        getAcquireLockDetails: (data) => dispatch(getAcquireLockDetails(data)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'SupplierMaster')),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierMasterUserTable);