import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getPromotionMappingFormatDataById
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const QuotaManagementViewQuery = props => {
    const classes = useStyles();
    const [quotaData, setQuotaData] = React.useState([]);

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getPromotionFormatDataById(appId);

    }, []);
    useEffect(() => {
        if (props.promotionData)
            setQuotaData(props.promotionData)
    }, [props.promotionData]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">

                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/promo-mapping/promo-mapping-excel-upload'
                >
                    Promotion External Mapping Format
                </Link>
                <Typography color="textPrimary" variant='h4'>View Format</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {quotaData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(quotaData).length > 0 && Object.entries(quotaData)

                                                        .filter(([k, v]) => k != 'id' && k != 'format_name' && k != 'organization_id' && k != 'created_by_id' && k != 'updated_by_id' && v > 0)
                                                        .sort(function (a, b) {
                                                            return a[1] < b[1] ? -1 : 1;
                                                        })
                                                        .map(([k, v]) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        {v}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getPromotionFormatDataById: (id) => dispatch(getPromotionMappingFormatDataById(id))
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        promotionData: state.promotionData.promoMapFormatSingleData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotaManagementViewQuery);