import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Typography
} from '@mui/material';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page,Toolbar,PdfExport, ExcelExport, Sort, ColumnChooser,Resize,Reorder } from '@syncfusion/ej2-react-grids';
import Moment from 'moment';
import {DataUtil} from '@syncfusion/ej2-data';
DataUtil.serverTimezoneOffset = 0;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '20px 30px 20px 30px',
        borderRadius: 10
    },
    chartContainer: {
        marginTop: 30,
        height: 250
    },
    gridAdjustment: {
        marginTop:30,
        marginLeft:-68
    },
    customcss :{
        color:'black',
        fontSize: '1em',
    }
}));

const MonthlyAnalytics = props => {
    const classes = useStyles();
    let grid;
    const [columns, setColumns] = React.useState([]);
    const [periodicity, setPeriodicity] = React.useState('');
    const [monthColumn, setMonthColumn] = React.useState([]);
    const [optionsLow, setOptionsLow] = React.useState({});
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        if (props.pricingData && props.pricingData.length > 0) {
            setDataRows(props.pricingData.sort((a, b) => a.margin_percentage < b.margin_percentage ? 1 : -1))

        }
        setPeriodicity(props.type)
        if(props.type=='month'){
          setMonthColumn([{
            field: "month",
            title: "Month",
            type: "string",
            editable: "never",

        }])
        }
    }, [props.pricingData, props.type])
    useEffect(() => {
        const analysisData = []

        if (props.analysisLevel && props.analysisLevel.length > 0) {
            props.analysisLevel.map(item => {
                var mappingName = item.replace(/_/g, ' ').split(" ")
                for (var i = 0; i < mappingName.length; i++) {
                    mappingName[i] = mappingName[i].charAt(0).toUpperCase() + mappingName[i].slice(1);

                }
                analysisData.push(
                    {
                        field: item,
                        title: mappingName.join(" "),
                        type: "string",
                        width: 150
                    },
                )
            })
        }
        const column = [
            ...analysisData,
            {
                field: "base_price",
                title: "Base Price",
                editable: "never",
                width: 120
            },
            ...monthColumn,
            {
                field: "on_invoice_discounts",
                title: "On Invoice Discounts",
                type: "string",
                width: 120           
            },
            {
                field: "on_invoice_surcharges",
                title: "On Invoice Surcharges",
                type: "string",
                width: 120          
            },
            {
                field: "on_invoice_freight",
                title: "On Invoice Freight",
                type: "string",
                width: 120           
            },
            {
                field: "off_invoice_adjustments",
                title: "Off Invoice Adjustments",
                type: "string",
                width: 150            
            },
            {
                field: "cost",
                title: "Cost",
                type: "string",
                width: 90            
            },
            {
                field: "margin_price",
                title: "Margin Price",
                type: "string",
                width: 100            
            },
            {
                field: "margin_historical_percentage",
                title: "Margin Historical Percentage",
                type: "string",
                width: 120            
            },
            {
                field: "margin_forecast_percentage",
                title: "Margin Forecast Percentage",
                editable: "never",
                type: "string",
                width: 120            
            },
            {
                field: "margin_target_percentage",
                title: "Margin Target Percentage",
                editable: "never",
                type: "string",
                width: 120            
            },
            {
                field: "historical_start_date",
                title: "Historical Start Date",
                editable: "never",
                type: 'date',
                width: 120,
                render: (rowData) => <a className={classes.hover} > {Moment(rowData.forecast_start_date).format('YYYY-DD-MM')}</a>
            },
            {
                field: "historical_end_date",
                title: "Historical End Date",
                editable: "never",
                type: 'date',
                width: 120,
                render: (rowData) => <a className={classes.hover} > {Moment(rowData.forecast_end_date).format('YYYY-DD-MM')}</a>
            },
            {
                field: "forecast_start_date",
                title: "Forecast Start Date",
                editable: "never",
                type: 'date',
                width: 120,
                render: (rowData) => <a className={classes.hover} > {Moment(rowData.forecast_start_date).format('YYYY-DD-MM')}</a>
            },
            {
                field: "forecast_end_date",
                title: "Forecast End Date",
                editable: "never",
                type: 'date',
                width: 120,
                render: (rowData) => <a className={classes.hover} > {Moment(rowData.forecast_end_date).format('YYYY-DD-MM')}</a>
            },
    
        ];
        setColumns(column)
    }, [props.analysisLevel])
    const pageSettings = { pageSize: 15 };
      const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
          grid.pdfExport();
        }
        if (grid && args.item.id === 'grid_excelexport') {
          grid.excelExport();
        }
      };
    const customAttributes = { class: classes.customcss };
    const settings = { wrapMode: 'Content' };
    return (

        <div>
            {props.pricingData && props.pricingData.length > 0 && props.active ?
                <div>
                    {props.type == 'cumulative' &&
                        <GridComponent id='grid' 
                        gridLines='Both'
                        allowGrouping={true}
                        allowReordering={true}
                        height={600}
                        dataSource={DataUtil.parse.parseJson(JSON.stringify(dataRows))} 
                        pageSettings={pageSettings} 
                        showColumnChooser={true} 
                        allowTextWrap={true}
                        toolbar={['PdfExport', 'ExcelExport', 'Search']} 
                        allowPdfExport={true} 
                        allowExcelExport={true} 
                        allowResizing={true}
                        toolbarClick={toolbarClick} 
                        ref={g => grid = g}
                        // className={classes.gridAdjustment}
                        >
                            <ColumnsDirective>
                                {columns.map(item => {
                                    return <ColumnDirective style={{ textTransform: 'capitalize' }} headerText={item.title} field={item.field} width={item.width} customAttributes={customAttributes}
                                        visible={periodicity == 'cumulative' && item.field == 'month' ? false : true}
                                        format={item.field === 'historical_start_date' || item.field === 'historical_end_date' ||
                                            item.field === 'forecast_start_date' || item.field === 'forecast_end_date' ? 'MM/dd/yyyy' : 'N2'}
                                        type={item.field === 'historical_start_date' || item.field === 'historical_end_date' ||
                                            item.field === 'forecast_start_date' || item.field === 'forecast_end_date' ? 'dateTime' : null}

                                    />
                                })}
                            </ColumnsDirective>
                            <Inject services={[Page,Toolbar,PdfExport, ExcelExport,Sort, Filter, Group, ColumnChooser,Reorder,Resize]} />
                        </GridComponent>
                    }
                    {props.type == 'month' &&
                        <GridComponent id='grid' 
                        gridLines='Both'
                        allowGrouping={true}
                        allowReordering={true}
                        height={600}
                        dataSource={DataUtil.parse.parseJson(JSON.stringify(dataRows))} 
                        pageSettings={pageSettings} 
                        showColumnChooser={true} 
                        allowTextWrap={true}
                        toolbar={['PdfExport', 'ExcelExport', 'Search']} 
                        allowPdfExport={true} 
                        allowExcelExport={true} 
                        allowResizing={true}
                        toolbarClick={toolbarClick} 
                        ref={g => grid = g}
                        // className={classes.gridAdjustment}
                        >
                            <ColumnsDirective>
                                {columns.map(item => {
                                    return <ColumnDirective style={{ textTransform: 'capitalize' }} headerText={item.title} field={item.field}  width={item.width} customAttributes={customAttributes}
                                        format={item.field === 'historical_start_date' || item.field === 'historical_end_date' ||
                                            item.field === 'forecast_start_date' || item.field === 'forecast_end_date' ? 'MM/dd/yyyy' : 'N2'}
                                        type={item.field === 'historical_start_date' || item.field === 'historical_end_date' ||
                                            item.field === 'forecast_start_date' || item.field === 'forecast_end_date' ? 'dateTime' : null}

                                    />
                                })}
                            </ColumnsDirective>
                            <Inject services={[Page,Toolbar,PdfExport, ExcelExport,Sort, Filter, Group, ColumnChooser,Reorder,Resize]} />
                        </GridComponent>
                    }
                </div>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
        </div>
    );
};

export default MonthlyAnalytics;