import React from 'react';
import { CalculationSimulationForm } from './containers';

const CalculationSimulation = () => {
    return (
        <CalculationSimulationForm />
    );

};

export default CalculationSimulation;