import React from 'react';
import { CompareRebateCalculationForm } from './containers';

const CompareRebateCalculation = props => {

    return (
        <CompareRebateCalculationForm />
    );

};

export default CompareRebateCalculation;