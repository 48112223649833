import React, { useRef, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { addMaterialMasterXRefUser, getDefaultValuesMaterialXRef, getDefaultValuesCustomer } from '../../redux/actions';
import { connect } from 'react-redux';
import { DropdownArrayForMaster } from '../../components/Inputs';
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
}));

const AddMaterialMasterXMaster = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [channelPartnerTypeArray, setChannelPartnerTypeArray] = React.useState([]);
    const [materialStore, setMaterialStore] = React.useState([]);
    const [channelPartnerType, setChannelPartnerType] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerMaterialId, setChannelPartnerMaterialId] = React.useState('');
    const [channelPartnerUoM, setChannelPartnerUoM] = React.useState('');
    const [imaMaterialId, setImaMaterialId] = React.useState('');
    const [imaUoM, setImaUoM] = React.useState('');
    const [materialDescription, setMaterialDescription] = React.useState('');
    const [channelPartnerName, setChannelPartnerName] = React.useState('');
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'channel_partner_type') {
                            setChannelPartnerTypeArray(item);
                        }
                    })
                }
            }
        }
    });
    function handleChannelPartnerType(event) {
        setChannelPartnerType(event);
    }
    function handleChannelPartnerId(event) {
        setChannelPartnerId(event.target.value);
    }
    function handleChannelPartnerMaterialId(event) {
        setChannelPartnerMaterialId(event.target.value);
    }
    function handleChannelPartnerUoM(event) {
        setChannelPartnerUoM(event.target.value);
    }
    function handleImaUom(event) {
        setImaUoM(event.target.value);
    }
    function handleMaterialDescription(event) {
        setMaterialDescription(event.target.value);
    }
    function handleChannelPartnerName(event) {
        setChannelPartnerName(event.target.value);
    }
    function handleImaMaterialId(event) {
        setImaMaterialId(event.target.value);
    }
    function handleClear() {
        setChannelPartnerTypeArray('');
        setChannelPartnerType('');
        setChannelPartnerId('');
        setChannelPartnerMaterialId('');
        setChannelPartnerUoM('');
        setImaUoM('');
        setImaMaterialId('');
        setMaterialDescription('');
        setChannelPartnerName('');
        // setEditMode(false);
    }
    function handleOnSubmit() {
        var formData = {
            "channel_partner_type": channelPartnerType,
            "channel_partner_id": channelPartnerId,
            "channel_partner_material_id": channelPartnerMaterialId,
            "channel_partner_uom": channelPartnerUoM,
            "ima_uom": imaUoM,
            "ima_material_id": imaMaterialId,
            "material_description": materialDescription,
            "channel_partner_name": channelPartnerName,

        }
        props.onSubmit(formData);
    }
    function handleOnClear() {
        setMaterialStore([]);
        handleClear();
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/material-x-master'
                >
                    Material Master XREF
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Material XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {/* {channelPartnerTypeArray && channelPartnerTypeArray.enabled &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        Channel Partner Type
                                    </FormLabel>
                                    <DropdownArrayForMaster 
                                    placeholder={'Select type'} 
                                    twoline='true' 
                                    onChange={handleChannelPartnerType} 
                                    data={channelPartnerTypeArray.drop_down_values} />
                                </Grid> } */}
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner Material ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerMaterialId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerMaterialId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner UoM
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerUoM}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerUoM} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA Material ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaMaterialId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleImaMaterialId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA UoM
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaUoM}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleImaUom} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            Material Description
                                        </FormLabel>
                                        <OutlinedInput
                                            value={materialDescription}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleMaterialDescription} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            Channel Partner Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerName}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerName} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={channelPartnerId && channelPartnerMaterialId && channelPartnerUoM
                            && imaMaterialId && imaUoM ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        dropdownData: state.customerData.dropdownDataMaterialXRef,
        loadingAPI: state.customerData.loading
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addMaterialMasterXRefUser(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesMaterialXRef()),
        onLoadingDefaultCustomerMaster: () => dispatch(getDefaultValuesCustomer()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMaterialMasterXMaster);