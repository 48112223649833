import React, { useRef, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    OutlinedInput,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    Select,
    MenuItem,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, DropdownArray, Dropdown } from '../../components/Inputs';
import SimpleDialog from '../../components/Dialog';
import { runBuildOffInvoiceCostData, getDefaultValues,getPriceTypeConfig } from '../../redux/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import ChipInput from 'material-ui-chip-input';
import LoadingOverlay from 'react-loading-overlay';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
}));

const BuildOffInvoiceCostQuery = props => {
    const classes = useStyles();
    const { className } = props;
    const contractCustomisationUpdation = useRef();
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    
    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');

    useEffect(() => {
        props.onLoadingDefault();
        props. getConfigData(1, 10);
    }, []);
    const [transactionType,setTransactionType] = React.useState('');
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [offInvoiceCostType, setOffInvoiceCostType] = useState('');
    const [offInvoiceCostTypeArray, setOffInvoiceCostTypeArray] = useState([]);
    
    const [open, setOpen] = useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            }
        }
    }
    const formData = {
        "transaction_type":transactionType,
         "start_date": billingDateStart ? Moment(billingDateStart).local().format('YYYY-MM-DD') : '',
        "end_date": billingDateEnd ? Moment(billingDateEnd).local().format('YYYY-MM-DD') : '',
        "off_invoice_cost_type":offInvoiceCostType ,
        "records": radioGroupValue ? radioGroupValue : ''

    };

    useEffect(()=> {
        var newArray = []
        if(props.configData && props.configData?.records?.length>0) {
            props.configData.records
            .filter(e => e.price_type_category === 'Off-Invoice Adjustments')
            .map(e => {
                newArray.push(e.price_type_name)
            })
        }
        setOffInvoiceCostTypeArray(newArray)
    },[props.configData])
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setOffInvoiceCostType('');
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setTransactionType();
    }
    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleOffInvoiceCostType = (value) => {
        setOffInvoiceCostType(value);
    }
    const handleTransactionType = (value) => {
        setTransactionType(value);
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
        <div
            className={clsx(classes.root, className)}>
            <div className={classes.row} >
                <Typography variant="h2"> Build Off Invoice Cost Data </Typography>
            </div>
            <form
                autoComplete="off"
                noValidate
            >
                <div className={classes.container}>
                    <Grid container >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        >
                        <div className={classes.contractContiner}>
                            <FormLabel className={classes.formLabel} required>
                                        Transaction Type
                            </FormLabel>
                            <Select
                            value={transactionType}
                            onChange={(e) => handleTransactionType(e.target.value)}
                            displayEmpty
                            className={clsx({
                                [classes.select]: true
                                })}
                                classes={{
                                selectMenu: classes.selectedItem
                                }} >
                                <MenuItem value={'INV'} key={'INV'}>
                                INV 
                                </MenuItem>
                                <MenuItem value={'SO'} key={'SO'}>
                                 SO
                                </MenuItem>
                                <MenuItem value={'DEL'} key={'DEL'}>
                                DEL
                                </MenuItem>        
                                </Select>
                             </div>
                        </Grid>
                        { transactionType==='INV' && 
                        <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}

                    >
                        <div style={{ display: 'flex' }}>

                            <div className={classes.contractContiner}>
                                <FormLabel >Billing Date</FormLabel>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={billingDateStart}
                                            onChange={handleBillingDateStart}
                                            format="MM/dd/yyyy"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.contractContiner}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 29,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginLeft: 10
                                                }
                                            }}
                                            value={billingDateEnd}
                                            onChange={handleBillingDateEnd}
                                            format="MM/dd/yyyy"
                                            minDate={billingDateStart ? billingDateStart : ''}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                    </Grid>
                    }
                    { transactionType==='SO' && 
                        <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}

                    >
                        <div style={{ display: 'flex' }}>

                            <div className={classes.contractContiner}>
                                <FormLabel >Sales Order Date</FormLabel>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={billingDateStart}
                                            onChange={handleBillingDateStart}
                                            format="MM/dd/yyyy"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.contractContiner}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 29,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginLeft: 10
                                                }
                                            }}
                                            value={billingDateEnd}
                                            onChange={handleBillingDateEnd}
                                            format="MM/dd/yyyy"
                                            minDate={billingDateStart ? billingDateStart : ''}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                    </Grid>
                    }
                    { transactionType==='DEL' && 
                        <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}

                    >
                        <div style={{ display: 'flex' }}>

                            <div className={classes.contractContiner}>
                                <FormLabel >Delivery Date</FormLabel>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={billingDateStart}
                                            onChange={handleBillingDateStart}
                                            format="MM/dd/yyyy"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.contractContiner}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 29,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginLeft: 10
                                                }
                                            }}
                                            value={billingDateEnd}
                                            onChange={handleBillingDateEnd}
                                            format="MM/dd/yyyy"
                                            minDate={billingDateStart ? billingDateStart : ''}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                    </Grid>
                    }
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <Typography variant="h4" className={classes.contractContiner}> Off Invoice Cost Type </Typography>
                            <Select
                                            value={offInvoiceCostType}
                                            onChange={(e) => handleOffInvoiceCostType(e.target.value)}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                            { offInvoiceCostTypeArray && offInvoiceCostTypeArray.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={item}>
                                    {item}
                                   </MenuItem>
                                   )
                                })}
                            </Select>                 
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                <FormControlLabel
                                    value="non-processed"
                                    control={<Radio color="primary" />}
                                    label="Process New Records"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                                <FormControlLabel
                                    value="all"
                                    control={<Radio color="primary" />}
                                    label="Process All Records"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                            </RadioGroup>
                        </Grid>

                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                            Clear
                        </Button>
                        <Button
                            variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                            disabled={ transactionType 
                                ? false : true}
                            >
                            {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                            }
                        </Button>
                    </div>
                </div>
            </form>
            <SimpleDialog open={open} content='Do you want to map stage Off Invoice Cost data?' handleDialog={handleDialog} />
        </div>
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runBuildOffInvoiceCostData(formData)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        getConfigData: (pagination, limit) => dispatch(getPriceTypeConfig(pagination, limit)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        configData: state.pricingData.priceTypeData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildOffInvoiceCostQuery);
