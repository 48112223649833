import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Typography, useMediaQuery,
  TablePagination, Button, IconButton, Fab
} from "@mui/material";
import { Edit, DeleteForever, AddBoxOutlined, LibraryAdd } from "@mui/icons-material";
import MaterialTable from "material-table";
import { tableIcons } from "../../../components/Icons/TableIcons";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { createDrivers, getDrivers } from "../../../redux/actions/ProfitOptimizationConfig/AuthAction";
import secureLocalStorage from "react-secure-storage";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '20px 16px',
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: 10,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    zIndex: 1
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize
  },
  tabHead: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize
  }
}));
const DriversMain = (props) => {
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const [dataRows, setDataRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [configData, setConfigData] = React.useState([]);
  useEffect(() => {
    props.getConfigData(1, 10);
  }, []);
  useEffect(() => {
    var tempRows = [];
    if (props.configData && props.configData.length > 0) {
      setConfigData(props.configData);

      props.configData.map((e) => {
        tempRows.push({
          id: e.ID,
          driver_name: e.driver_name,
          driver: e.driver,
          // calculation_type: e.calculation_type,
          // scales: e.scales == true ? "Yes" : "No",
          external_driver_name: e.external_driver_name,
          driver_category: e.driver_category,
        });
      });
      setCount(props.configData.length);
      setDataRows(tempRows);
    }
  }, [props.configData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.getConfigData(newPage + 1, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    props.getConfigData(1, event.target.value);
  };
  function editConfig(id) {
    history.push({
      pathname: "/drivers/edit-drivers/" + id,
      id: id,
    });
  }
  const deleteConfig = (id) => {
    props.onDelete(id);
  };
  const columns = [
    {
      field: "driver_name",
      title: "Driver Name",
      editable: "never",
      type: "string",
    },
    {
      field: "driver",
      title: "Driver",
      type: "string",
      editable: "never",
    },
    {
      field: "driver_category",
      title: "Driver Category",
      type: "string",
      editable: "never",
    },
    // {
    //   field: "calculation_type",
    //   title: "Calculation Type",
    //   type: "string",
    //   editable: "never",
    // },
    // {
    //   field: "scales",
    //   title: "Scales",
    //   type: "string",
    //   editable: "never",
    // },
    {
      field: "external_driver_name",
      title: "External Driver Name",
      editable: "never",
      type: "string",
    },
    {
      field: "Actions",
      title: "Actions",
      type: "string",
      editable: "never",
      render: (rowData) => (
        <div>
          {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_drivers-' + sessionStorage.getItem('application')) &&
            <IconButton
              classes={{ root: classes.IconButton }}
              onClick={() => editConfig(rowData.id)}
              size="large">
              <Edit color="disabled" style={{ fontSize: 20 }} />
            </IconButton>}
          {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_drivers-' + sessionStorage.getItem('application')) &&
            <IconButton
              classes={{ root: classes.IconButton }}
              onClick={() => deleteConfig(rowData.id)}
              size="large">
              <DeleteForever color="disabled" style={{ fontSize: 20 }} />
            </IconButton>}
        </div>
      ),
    },
  ];
  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        text="Loading . Please wait ..."
        className={classes.spinner}
      >
        <div
          className={clsx({
            [classes.row]: isDesktop,
          })}
        >
          <Typography variant="h1" color="primary"> Drivers </Typography>
          {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_drivers-' + sessionStorage.getItem('application')) &&
            <Fab aria-label="edit" variant="extended"
              size='medium'
              classes={{ root: classes.fabContainer }}
              className={classes.fabContainer}
              onClick={() => history.push("/drivers/add-drivers")}
            >
              <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
            </Fab>
            // <Button
            //   variant="contained"
            //   color="primary"
            //   startIcon={<AddBoxOutlined />}
            //   classes={{
            //     startIcon: !isDesktop && classes.startIcon,
            //     root: !isDesktop && classes.container,
            //   }}
            //   onClick={() => history.push("/drivers/add-drivers")}
            // >
            //   {isDesktop ? "Add Drivers" : ""}
            // </Button> }
          }
        </div>

        {configData ? (
          <MaterialTable
            components={{
              // Pagination: (props) => (
              //   <TablePagination
              //     {...props}
              //     rowsPerPageOptions={[5, 10,15, 20]}
              //     count={count}
              //     page={page}
              //     rowsPerPage={rowsPerPage}
              //     onChangePage={handleChangePage}
              //     onChangeRowsPerPage={handleChangeRowsPerPage}
              //   />
              // ),
              Toolbar: (props) => (
                <div
                  style={{
                    height: "0px",
                  }}
                ></div>
              ),
            }}
            title={" "}
            editable={true}
            icons={tableIcons}
            columns={columns}
            data={dataRows}
            style={{ marginTop: '-18px' }}
            options={{
              maxBodyHeight: '100vh',
              search: false,
              filtering: true,
              paging: false,
              // pageSize: rowsPerPage,
              // count: count,
              // page: page,
              headerStyle: theme.mixins.MaterialHeader,
              cellStyle: theme.mixins.MaterialCell,
            }}
          />
        ) : (
          <Typography variant="h4">There is no data to show now.</Typography>
        )}
      </LoadingOverlay>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    configData: state.profitOptimizationData.priceTypeData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getConfigData: (pagination, limit) => dispatch(getDrivers(pagination, limit)),
    onDelete: (id) => dispatch(createDrivers(null, id, "delete")),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DriversMain);