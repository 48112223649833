import React, { useEffect } from 'react';
import {
    Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Chip, TextField
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';
const useStyles = makeStyles(theme => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}))
const OverWrite = (props) => {
    const [data, setData] = React.useState([]);
    const classes = useStyles();
    function handleClose() {
        props.onChange('cancel')
    }
    function handleSubmit() {
        props.onChange(data)
    }
    function handleData(e) {
        setData(e.target.value)
    }
    useEffect(() => {
        setData(props.data)
    }, [props.data])
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            fullWidth
            maxWidth={'sm'}
        >
            <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                OVERWRITE SECTION
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.dialogContent}>
                    <Typography variant='h4' color='grey'>{props.label}</Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder="1200 character limit"
                        multiline
                        rows={6}
                        onChange={handleData}
                        value={data}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSubmit}>Save</Button>
            </DialogActions>
        </Dialog>)
}
export default OverWrite