import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid,
    Select,
    FormLabel,
    MenuItem,
    OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { TextInputWithLabel } from '../../../../components/Inputs';
import { getDefaultValuesSupplier } from '../../../../redux/actions';
import { AllOtherAttributeMasterComponent } from '../../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    selectContainer: {
        padding: '10px 45px 0px 45px !important'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
    gridContainer: {
        padding: '0px 45px 0px 45px !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const GeneralData = props => {
    const classes = useStyles();
    const supplierTypeData = [
        { key: 'Direct', value: 'Direct' },
        { key: 'Indirect', value: 'Indirect' },
        { key: 'GPO', value: 'GPO' },
    ];

    useEffect(() => {
        props.onLoadingDefaultMaterialXRef();
    }, []);
    const [supplierName, setSupplierName] = React.useState('');
    const [supplierType, setSupplierType] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [industrialType, setIndustrialType] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [active, setActive] = React.useState('');
    const [industryTypeArray, setIndustryTypeArray] = React.useState([]);
    const [fieldLabel, setFieldLabel] = React.useState([]);
    useEffect(() => {
        if (props.dropdownDataCustomer && props.dropdownDataCustomer.records && props.dropdownDataCustomer.records.length > 0) {
            props.dropdownDataCustomer.records.map((item) => {
                if (item.field_id === 'supplier_industry') {
                    setIndustryTypeArray(item.drop_down_value_keys);
                }
            })
        }
    }, [props.dropdownDataCustomer]);

    var formData = {
        supplier_name: supplierName,
        supplier_type: supplierType,
        street: street,
        city: city,
        region: state,
        postal_code: postalCode,
        country: country,
        supplier_industry: industrialType,
        supplier_customer_number: customerNumber,
        supplier_status: active,
    };
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        props.onChange(formData);
    }, [formData]);
    //functions 
    function handleSupplierName(newValue) {
        setSupplierName(newValue.target.value);
    }
    function handleSupplierType(newValue) {
        if (newValue && newValue.length > 0) {
            setSupplierType(newValue);
        }
        else
            setSupplierType('')
        props.onChange(formData);
    }
    function handleStreet(event) {
        setStreet(event.target.value);
    }
    function handleCity(newValue) {
        setCity(newValue.target.value);
    }
    function handleState(newValue) {
        setState(newValue.target.value);
    }
    function handlePostalCode(newValue) {
        setPostalCode(newValue.target.value);
    }
    function handleCountry(e) {
        setCountry(e.target.value);
    }
    function handleIndustrialType(newValue) {
        if (newValue && newValue.length > 0) {
            setIndustrialType(newValue);
        }
        else
            setIndustrialType('')
        props.onChange(formData);
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue.target.value);
    }
    function handleActive(event) {
        setActive(event.target.value);
    }
    return (
        <div className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="HEADER FIELDS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <div className={classes.container}>
                        <Grid container
                            style={{ marginBottom: 16 }}
                            spacing={2}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 10 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required>
                                    {fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['current'] : 'Supplier Name'}
                                </FormLabel>
                                <OutlinedInput
                                    value={supplierName}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleSupplierName} />
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required heading={fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['current'] : 'Supplier Name'} twoline='true' onChange={handleSupplierName} /> */}
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: -10 }}
                                classes={{ root: classes.selectContainer }}
                            >
                                <FormLabel required classes={{ root: classes.formLabel }} style={{ marginTop: '12px' }}>
                                    {fieldLabel['supplier_type'] ? fieldLabel['supplier_type']['current'] : 'Supplier Type'}
                                </FormLabel>
                                <AllOtherAttributeMasterComponent style={{ marginTop: "-4px" }} classes={{ root: classes.formLabel }} withOutLabel value={supplierType} onChange={handleSupplierType} prevalue={supplierType} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 10 }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required>
                                    {fieldLabel['street'] ? fieldLabel['street']['current'] : 'Street Address'}
                                </FormLabel>
                                <OutlinedInput
                                    value={street}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleStreet} />
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required heading={fieldLabel['street'] ? fieldLabel['street']['current'] : 'Street Address'} twoline='true' onChange={handleStreet} /> */}
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 8 }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required>
                                    {fieldLabel['city'] ? fieldLabel['city']['current'] : 'City'}
                                </FormLabel>
                                <OutlinedInput
                                    value={city}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleCity} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required>
                                    {fieldLabel['country'] ? fieldLabel['country']['current'] : 'Country'}
                                </FormLabel>
                                <OutlinedInput
                                    value={country}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleCountry} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required>
                                    {fieldLabel['region'] ? fieldLabel['region']['current'] : 'State'}
                                </FormLabel>
                                <OutlinedInput
                                    value={state}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleState} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 13 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required heading={fieldLabel['postal_code'] ? fieldLabel['postal_code']['current'] : 'Postal Code'} twoline='true' onChange={handlePostalCode} /> */}
                                <FormLabel classes={{ root: classes.formLabel }} required>
                                    {fieldLabel['postal_code'] ? fieldLabel['postal_code']['current'] : 'Postal Code'}
                                </FormLabel>
                                <OutlinedInput
                                    value={postalCode}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handlePostalCode} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.selectContainer }}
                            >
                                <FormLabel required={fieldLabel['supplier_industry'] ? fieldLabel['supplier_industry']['mandatory'] : false} style={{ marginTop: '3px' }}>{fieldLabel['supplier_industry'] ? fieldLabel['supplier_industry']['current'] : 'supplier_industry'}</FormLabel>
                                <AllOtherAttributeMasterComponent classes={{ root: classes.formLabel }}
                                    withOutLabel
                                    attributeName='supplier_industry' value={industrialType} onChange={handleIndustrialType} prevalue={industrialType} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 10 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_customer_number'] ? fieldLabel['supplier_customer_number']['mandatory'] : false}>
                                    {fieldLabel['supplier_customer_number'] ? fieldLabel['supplier_customer_number']['current'] : 'Customer Number'}
                                </FormLabel>
                                <OutlinedInput
                                    value={customerNumber}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={handleCustomerNumber} />
                                {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_customer_number'] ? fieldLabel['supplier_customer_number']['mandatory'] : false}
                                    heading={fieldLabel['supplier_customer_number'] ? fieldLabel['supplier_customer_number']['current'] : 'Customer Number'} twoline='true' onChange={handleCustomerNumber} /> */}
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 8 }}
                            >
                                <FormLabel required={fieldLabel['supplier_status'] ? fieldLabel['supplier_status']['mandatory'] : false} classes={{ root: classes.formLabel }}>
                                    {fieldLabel['supplier_status'] ? fieldLabel['supplier_status']['current'] : 'Supplier Status'}
                                </FormLabel>
                                <Select
                                    value={active}
                                    onChange={handleActive}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={"Active"}>
                                        Active
                                    </MenuItem>
                                    <MenuItem value={"Inactive"}>
                                        Inactive
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Card>
        </div>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefaultMaterialXRef: () => dispatch(getDefaultValuesSupplier()),
    }
}
const mapStateToProps = state => {
    return {
        dropdownDataCustomer: state.customerData.dropdownDataSupplier
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);