import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Select,
    MenuItem,
    FormLabel,
    IconButton,
    OutlinedInput,
    Input, Checkbox, ListItemText
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { connect } from 'react-redux';
import { getGlobalSetupData } from '../../../../../redux/actions';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '26px 0px 26px 0px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',

    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));

const AssignSticker = (props) => {

    const history = useHistory();
    const classes = useStyles();
    const { className } = props;
    const [promoFields, setPromoFields] = React.useState([]);
    const [addMaterial, setAddMaterial] = React.useState(1);
    const [promotionName, setPromotionName] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [promotion, setPromotion] = React.useState([]);
    const [valid, setValid] = React.useState(true);
    const [open, setOpen] = React.useState('');
    const [globalPromo, setGlobalPromo] = React.useState([]);
    const isAllSelected =
        promotion && promotion.length > 0 && selected.length === promotion.length;

    const format = {
        promotion_name: '',
        promotion_description: '',
        benefit_rebate: 0, benefit_coverage_type: '', benefit_coverage_length: 0, benefit_spiff: 0, benefit_promo_finance_interest: 0, benefit_promo_finance_term_structure: '', benefit_extra_rebate: 0,
        takerate_global: 0, takerate_rebate: 0, takerate_coverage: 0, takerate_spiff: 0, takerate_std_finance: 0, takerate_promo_finance: 0, takerate_extra_rebate: 0
    }

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        props.getGlobalSetupData(props.country)
    }, [props.country]);
    useEffect(() => {
        if (!props.editMode) {
            setPromoFields([format]);
        }
    }, []);

    useEffect(() => {
        var temp = []
        if (props.globalData && props.globalData.records && props.globalData.records.length > 0) {
            setGlobalPromo(props.globalData.records)
            props.globalData.records.map((item, index) => {
                if (!temp.includes(item.promotion_name)) {
                    temp.push(item.promotion_name)
                }
            })
            setPromotion(temp)
        }
    }, [props.globalData]);
    function handleChangeValue(event) {
        if (event.target.name == 'benefit_coverage_type' || event.target.name == 'benefit_promo_finance_term_structure'
            || event.target.name == 'promotion_name' || event.target.name == 'promotion_description') {
            promoFields[event.target.id][event.target.name] = event.target.value

        }
        else {
            promoFields[event.target.id][event.target.name] = parseFloat(event.target.value)
        }
        setPromoFields(promoFields)
    }
    function handleDeleteLocally(index, id) {
        var len = promoFields.length
        setPromoFields(item => item.filter((item, i) => i !== index));
        if (addMaterial - 1 > 0)
            setAddMaterial(addMaterial - 1)
        if (len - 1 == 0) {
            setPromoFields([format]);
            setValid(true)
        }
    }

    function handleOnAdd() {
        var newValue = promoFields
        newValue = [...newValue, format]
        setPromoFields(newValue);
        setAddMaterial(addMaterial + 1)
        setValid(true)
    }
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === promotion.length ? [] : promotion);
        }
        else
            setSelected(newSelected)
    }
    function onAdd() {
        handleGlobalPromotion(selected)
        setPromotionName(selected)
        setOpen(false)
    }
    function onCancel() {
        setOpen(false)
        setSelected([])
    }
    function handleOpen() {
        setSelected([])
        setOpen(true)
    }
    function handleGlobalPromotion(list) {
        var flag = 0
        var len = addMaterial
        var data = []
        var global = globalPromo.filter(item => list.includes(item.promotion_name))
        global.map((itemX, i) => {
            for (var propName in itemX) {
                if (propName == 'CreatedAt' || propName == 'UpdatedAt' || propName == 'ID' || propName == 'updated_by_id'
                    || propName == 'created_by_id' || propName == 'organization_id' || propName == 'DeletedAt') {
                    delete global[i][propName]
                }
            }
        })
        setPromoFields([...promoFields, ...global])
        setAddMaterial(addMaterial + global.length)
        Object.entries(promoFields[len - 1]).map(([k, v]) => {
            if (v != '' || v != 0)
                flag = flag + 1

        })
        if (flag == 0) {
            data = promoFields.filter((item, i) => i !== len - 1)
            setPromoFields(item => item.filter((item, i) => i !== len - 1));
            setAddMaterial(addMaterial + global.length - 1)
        }
        setPromotionName([])

    }
    function handleAddData() {
        var promoArray = []
        var promoname = []
        var flag = false
        promoFields.map((item, index) => {

            if (!promoname.includes(item.promotion_name)) {
                promoname.push(item.promotion_name)
                promoArray.push({ 'promotion_name': item.promotion_name, 'promotion_description': item.promotion_description })
            }
            if (item.promotion_name == '' && item.promotion_description == '' || (item.benefit_rebate == 0 && item.benefit_coverage_type == '' && item.benefit_coverage_length == 0 && item.benefit_spiff == 0 && item.benefit_promo_finance_interest == 0 && item.benefit_promo_finance_term_structure == '' && item.benefit_extra_rebate == 0
                && item.takerate_global == 0 && item.takerate_rebate == 0 && item.takerate_coverage == 0 && item.takerate_spiff == 0 && item.takerate_std_finance == 0 && item.takerate_promo_finance == 0 && item.takerate_extra_rebate == 0)) {
                flag = true
            }
        })
        if (flag)
            toast.error('Mandatory Fields are missing')
        else
            props.onChange(promoFields, promoArray)
    }
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container spacing={2} style={{ padding: 20 }}>

                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel variant="h5" className={classes.formDiv}>
                                        Promotion Name
                                    </FormLabel>
                                    <div className={classes.dropdownContainer}>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={promotionName}
                                            style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                            input={<OutlinedInput />}
                                            onOpen={() => handleOpen()}
                                            onClose={() => setOpen(false)}
                                            open={open}
                                            renderValue={(appType) => {
                                                if (selected.length > 0)
                                                    return appType.join(", ")
                                                else
                                                    return ""
                                            }}
                                            className={classes.multiSelect}

                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={isAllSelected} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {promotion && promotion
                                                .map(item => {
                                                    return (
                                                        <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected.indexOf(item) > -1} />
                                                            <ListItemText primary={item} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            <div className={classes.dropdownbottom}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                    Apply
                                                </Button>
                                            </div>


                                        </Select>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>
                        <div className={classes.container} >
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow>
                                            <TableCell align='center' style={{ minWidth: 100 }} rowSpan={2} >Promotion Name <span color='red'>*</span></TableCell>
                                            <TableCell align='center' style={{ minWidth: 150 }} rowSpan={2} >Promotion Description</TableCell>
                                            <TableCell align='center' colSpan={7}>Cost</TableCell>
                                            <TableCell align='center' colSpan={7}>Take Rate</TableCell>
                                            <TableCell align='center' style={{ width: 50, paddingLeft: 2, paddingRight: 2 }} rowSpan={2}>Actions</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Rebate</TableCell>
                                            <TableCell align="center" style={{ minWidth: 50 }}>Cov Type</TableCell>
                                            <TableCell align="center">Cov Length</TableCell>
                                            <TableCell align="center">SPIFF</TableCell>
                                            <TableCell align="center">F2 Interest</TableCell>
                                            <TableCell align="center">F2 Fin Structure</TableCell>
                                            <TableCell align="center">Extra</TableCell>
                                            <TableCell align="center">Global</TableCell>
                                            <TableCell align="center">Rebate</TableCell>
                                            <TableCell align="center" style={{ minWidth: 30 }}>Cov</TableCell>
                                            <TableCell align="center">SPIFF</TableCell>
                                            <TableCell align="center" style={{ minWidth: 50 }}>Std Fin</TableCell>
                                            <TableCell align="center">Promo Fin</TableCell>
                                            <TableCell align="center">Extra</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {promoFields && promoFields
                                            .map((formItem, i) => {
                                                return <>
                                                    <StyledTableRow key={i}>
                                                        <TableCell >
                                                            <OutlinedInput twoline='true'
                                                                key={'promotion_name-' + i}
                                                                name={'promotion_name'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['promotion_name'] ? promoFields[i]['promotion_name'] : ''}
                                                                disabled={props.promoData.findIndex(({ promotion_name }) => promotion_name == formItem.promotion_name) == -1 ? false : true}
                                                            />

                                                        </TableCell>
                                                        <TableCell >
                                                            <OutlinedInput twoline='true'
                                                                style={{ width: '100%' }}
                                                                key={'promotion_desc-' + i}
                                                                name={'promotion_description'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['promotion_description'] ? promoFields[i]['promotion_description'] : ''}
                                                            />

                                                        </TableCell>

                                                        <TableCell >
                                                            <OutlinedInput twoline='true'
                                                                key={'benefit_reb-' + i}
                                                                type={'number'}
                                                                name={'benefit_rebate'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['benefit_rebate'] ? promoFields[i]['benefit_rebate'] : ''}

                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'benefit_cov-' + i}
                                                                name={'benefit_coverage_type'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['benefit_coverage_type'] ? promoFields[i]['benefit_coverage_type'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'benefit_covlen-' + i}
                                                                type={'number'}
                                                                name={'benefit_coverage_length'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['benefit_coverage_length'] ? promoFields[i]['benefit_coverage_length'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'benefit_spiff-' + i}
                                                                type={'number'}
                                                                name={'benefit_spiff'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['benefit_spiff'] ? promoFields[i]['benefit_spiff'] : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'benefit_promoFin-' + i}
                                                                type={'number'}
                                                                name={'benefit_promo_finance_interest'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['benefit_promo_finance_interest'] ? promoFields[i]['benefit_promo_finance_interest'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>
                                                            <OutlinedInput twoline='true'
                                                                key={'benefit_FinSt-' + i}
                                                                name={'benefit_promo_finance_term_structure'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['benefit_promo_finance_term_structure'] ? promoFields[i]['benefit_promo_finance_term_structure'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'benefit_extra_reb-' + i}
                                                                type={'number'}
                                                                name={'benefit_extra_rebate'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['benefit_extra_rebate'] ? promoFields[i]['benefit_extra_rebate'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'take_global-' + i}
                                                                type={'number'}
                                                                name={'takerate_global'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['takerate_global'] ? promoFields[i]['takerate_global'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'take_reb-' + i}
                                                                type={'number'}
                                                                name={'takerate_rebate'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['takerate_rebate'] ? promoFields[i]['takerate_rebate'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'take_cov-' + i}
                                                                type={'number'}
                                                                name={'takerate_coverage'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['takerate_coverage'] ? promoFields[i]['takerate_coverage'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'take_spiff-' + i}
                                                                type={'number'}
                                                                name={'takerate_spiff'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['takerate_spiff'] ? promoFields[i]['takerate_spiff'] : ''}
                                                            />

                                                        </TableCell>
                                                        <TableCell>
                                                            <OutlinedInput twoline='true'
                                                                key={'take_stdFin-' + i}
                                                                type={'number'}
                                                                name={'takerate_std_finance'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['takerate_std_finance'] ? promoFields[i]['takerate_std_finance'] : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'take_promoFin-' + i}
                                                                type={'number'}
                                                                name={'takerate_promo_finance'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['takerate_promo_finance'] ? promoFields[i]['takerate_promo_finance'] : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>

                                                            <OutlinedInput twoline='true'
                                                                key={'take_extra_reb-' + i}
                                                                type={'number'}
                                                                name={'takerate_extra_rebate'}
                                                                id={i}
                                                                onBlur={handleChangeValue}
                                                                onClick={e => e.target.focus()}
                                                                defaultValue={promoFields[i]['takerate_extra_rebate'] ? promoFields[i]['takerate_extra_rebate'] : ''}
                                                            />

                                                        </TableCell>

                                                        <TableCell style={{ display: 'flex', paddingTop: 30, paddingBottom: 30 }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleDeleteLocally(i)}
                                                                size="large">
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                style={{ visibility: i === promoFields.length - 1 ? 'visible' : 'hidden', padding: 0 }}
                                                                onClick={handleOnAdd}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <AddBoxOutlinedIcon />
                                                            </IconButton>

                                                        </TableCell>
                                                    </StyledTableRow>

                                                </>;

                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </form>
                </Card>

            </div >
            <Grid container >
                <Grid
                    item
                    md={6}
                    xs={12}
                    classes={{ root: classes.gridContainer }}
                >
                    <Button variant="outlined" color="primary" onClick={() => props.handleBack()} className={classes.previous}>
                        Previous Step
                    </Button>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    classes={{ root: classes.gridContainer }}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button variant="outlined" color='primary' className={classes.button} onClick={() => history.push('/promotion-setup')}>
                        Cancel
                    </Button>
                    <Button tabIndex={-1} variant="contained" color='primary' className={classes.button} onClick={handleAddData}>
                        Next Step
                    </Button>
                </Grid>
            </Grid>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getGlobalSetupData: (country) => dispatch(getGlobalSetupData(country)),
    }
}

const mapStateToProps = state => {
    return {
        budgetData: state.promotionData.promotionSetupSingleData,
        globalData: state.promotionData.globalSetupData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignSticker);