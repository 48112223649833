import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid,
    Select,
    MenuItem,
    FormLabel,
    OutlinedInput
} from '@mui/material';
import { TextInputWithLabel, DropdownArray, LabelText } from '../../../../components/Inputs';
import { addCustomerMasterXRefUser, getDefaultValuesCustomer, getAttributeListData, getCustomerMasterViewDetails, getLblDispDesFieldValue } from '../../../../redux/actions';
import { AllOtherAttributeMasterComponentNew } from '../../../../components/Inputs';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    selectRoot: {
        padding: '20px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    selectedItemDropdown: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 9
    },
    inputTwoLine: {
        marginTop: 8,
        width: '100%',
        height: 36,
        paddingLeft: 10
    },
    gridContainer: {
        padding: '0px 45px 0px 45px !important'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const GeneralData = props => {
    const classes = useStyles();
    const [customerMasterData, setCustomerMasterData] = React.useState({})
    const [industryTypeArray, setIndustryTypeArray] = React.useState([]);
    const [classificationArray, setClassificationArray] = React.useState([]);
    const [classOfTradeArray, setClassOfTradeArray] = React.useState([]);
    const [accountGroupArray, setAccountGroupArray] = React.useState([]);
    const [customerTypeArray, setCustomerTypeArray] = React.useState([]);
    const contractCustomisationUpdation = useRef();
    const [active, setActive] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [customerName, setCustomerName] = React.useState('');
    const [customerType, setCustomerType] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [industrialType, setIndustrialType] = React.useState('');
    const [classification, setClassification] = React.useState('');
    const [classOfTrade, setClassOfTrade] = React.useState('');
    const [accountGroup, setAccountGroup] = React.useState('');
    const [headOffice, setHeadOffice] = React.useState('');
    const [paymentTerms, setPaymentTerms] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [priceList, setPriceList] = React.useState('');
    const [sortedData, setSortedData] = React.useState([]);
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.onLoadingDefault();
        props.getAttributeListData()
    }, []);
    useEffect(() => {
        if (props.attributeData && props.attributeData.length > 0) { }
    }, [props.attributeData])
    useEffect(() => {
        props.onChange(formData);
    });

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);

    useEffect(() => {
        if (props.customerMasterData) {
            setCustomerMasterData(props.customerMasterData)
            setCountry(props.customerMasterData.country);
            setState(props.customerMasterData.region);
            setStreet(props.customerMasterData.street);
            setCity(props.customerMasterData.city);
            setCustomerType(props.customerMasterData.customer_type);
            setCustomerNumber(props.customerMasterData.customer_number);
            setCustomerName(props.customerMasterData.customer_name);
            setPostalCode(props.customerMasterData.postal_code);
            setPaymentTerms(props.customerMasterData.payment_terms);
            setIndustrialType(props.customerMasterData.customer_industry);
            setClassification(props.customerMasterData.customer_classification);
            setClassOfTrade(props.customerMasterData.class_of_trade);
            setAccountGroup(props.customerMasterData.account_group);
            setHeadOffice(props.customerMasterData.head_office);
            setFlexAttribute1(props.customerMasterData.flex_attribute_1);
            setFlexAttribute2(props.customerMasterData.flex_attribute_2);
            setPriceList(props.customerMasterData.price_list);
            setActive(props.customerMasterData.customer_status)
        }

    }, [props.customerMasterData]);
    useEffect(() => {
        var newArray1 = []
        if (props.attributeData && props.attributeData.length > 0) {
            props.attributeData
                .filter(item => item.attribute_name === 'class_of_trade')
                .map((item) => {
                    newArray1.push(item.attribute_value)
                })
            setClassOfTradeArray(newArray1)
            var newArray2 = []
            props.attributeData
                .filter(item => item.attribute_name === 'account_group')
                .map((item) => {
                    newArray2.push(item.attribute_value)
                })
            setAccountGroupArray(newArray2)
            var newArray3 = []
            props.attributeData
                .filter(item => item.attribute_name === 'customer_classification')
                .map((item) => {
                    newArray3.push(item.attribute_value)
                })
            setClassificationArray(newArray3)
            var newArray4 = []
            props.attributeData
                .filter(item => item.attribute_name === 'customer_industry')
                .map((item) => {
                    newArray4.push(item.attribute_value)
                })
            setIndustryTypeArray(newArray4);
        }
    }, [props.attributeData]);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                var newArray5 = [];
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.filter((item) =>
                        item.field_id === 'customer_type')
                        .map((item) => {
                            newArray5 = item.drop_down_values
                        })
                    setCustomerTypeArray(newArray5)
                }
            }
        }
    });
    var formData = {
        customer_number: customerNumber,
        customer_name: customerName,
        street: street,
        city: city,
        region: state,
        postal_code: postalCode,
        country: country,
        customer_industry: industrialType,
        customer_classification: classification,
        class_of_trade: classOfTrade,
        account_group: accountGroup,
        head_office: headOffice,
        payment_terms: paymentTerms,
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2,
        customer_status: active,
        price_list: priceList,
        customer_type: customerType
    }
    function handleActive(event) {
        setActive(event.target.value);
    }
    function handleCustomerName(newValue) {
        setCustomerName(newValue);
        props.onChange(formData);
    }
    function handleCustomerType(newValue) {
        if (newValue && newValue.length > 0) {
            setCustomerType(newValue);
        }
        else
            setCustomerType('');
        props.onChange(formData);
    }
    function handleStreet(newValue) {
        setStreet(newValue.target.value)
        props.onChange(formData);
    }
    function handleCity(newValue) {
        setCity(newValue.target.value);
        props.onChange(formData);
    }
    function handleState(newValue) {
        setState(newValue.target.value);
        props.onChange(formData);
    }
    function handlePostalCode(newValue) {
        setPostalCode(newValue);
        props.onChange(formData);
    }
    function handleCountry(newValue) {
        setCountry(newValue.target.value);
        props.onChange(formData);
    }
    function handleIndustrialType(newValue) {
        if (newValue && newValue.length > 0) {
            setIndustrialType(newValue);
        }
        else
            setIndustrialType('')
        props.onChange(formData);
    }
    function handleClassification(newValue) {
        if (newValue && newValue.length > 0) {
            setClassification(newValue);
        }
        else
            setClassification('')
        props.onChange(formData);
    }
    function handleClassOfTrade(newValue) {
        if (newValue && newValue.length > 0) {
            setClassOfTrade(newValue);
        }
        else
            setClassOfTrade('');
        props.onChange(formData);
    }
    function handleAccountGroup(newValue) {
        if (newValue && newValue.length > 0) {
            setAccountGroup(newValue);
        }
        else
            setAccountGroup('')
        props.onChange(formData);
    }
    function handleHeadOffice(newValue) {
        setHeadOffice(newValue);
        props.onChange(formData);
    }
    function handlePaymentTerms(newValue) {
        if (newValue && newValue.length > 0) {
            setPaymentTerms(newValue);
        } else setPaymentTerms("");
        props.onChange(formData);
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue);
        props.onChange(formData);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue);
        props.onChange(formData);
    }
    function handlePriceList(newValue) {
        setPriceList(newValue);
        props.onChange(formData);
    }
    return (
        <div className={classes.root}>
            {customerMasterData &&
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <CardHeader
                                title="HEADER FIELDS"
                                titleTypographyProps={{ variant: 'h3' }}
                            />

                            <Grid container
                                style={{ marginBottom: 16, margin: 10 }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: "-7px" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['customer_number'] ? fieldLabel['customer_number']['mandatory'] : false}
                                        style={{ marginTop: "13px" }} >
                                        {fieldLabel['customer_number'] ? fieldLabel['customer_number']['current'] : 'Customer Number'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={customerMasterData.customer_number}
                                        classes={{ root: classes.inputTwoLine }}
                                        disabled />
                                    {/* <TextInputWithLabel style={{ marginTop: "0" }} classes={{ root: classes.fontSetting }} twoline='true' prevalue={customerMasterData.customer_number} disabled /> */}
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: 3 }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['customer_name'] ? fieldLabel['customer_name']['mandatory'] : false} >
                                        {fieldLabel['customer_name'] ? fieldLabel['customer_name']['current'] : 'Customer Name'}

                                    </FormLabel>
                                    <div style={{ marginTop: -3 }}>
                                        <TextInputWithLabel
                                            twoline='true'
                                            onChange={handleCustomerName}
                                            prevalue={customerMasterData.customer_name} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: "-6px" }}
                                    classes={{ root: classes.gridContainer }}>
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['customer_type'] ? fieldLabel['customer_type']['mandatory'] : false}
                                        style={{ marginTop: '10px' }}>
                                        {fieldLabel['customer_type'] ? fieldLabel['customer_type']['current'] : 'customer_type'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponentNew
                                        page={'masterData'}
                                        withOutLabel
                                        attributeName="customer_type"
                                        value={customerType}
                                        onChange={handleCustomerType}
                                        prevalue={customerMasterData.customer_type} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 6 }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['street'] ? fieldLabel['street']['mandatory'] : false}
                                    >
                                        {fieldLabel['street'] ? fieldLabel['street']['current'] : 'Street Address'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={street}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleStreet} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 16 }}
                                >

                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['city'] ? fieldLabel['city']['mandatory'] : false}
                                    >
                                        {fieldLabel['city'] ? fieldLabel['city']['current'] : 'City'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={city}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCity} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['country'] ? fieldLabel['country']['mandatory'] : false}
                                    >
                                        {fieldLabel['country'] ? fieldLabel['country']['current'] : 'Country'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={country}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCountry} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['region'] ? fieldLabel['region']['mandatory'] : false}
                                    >
                                        {fieldLabel['region'] ? fieldLabel['region']['current'] : 'Country'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={state}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleState} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: 14 }}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['postal_code'] ? fieldLabel['postal_code']['mandatory'] : false}>
                                        {fieldLabel['postal_code'] ? fieldLabel['postal_code']['current'] : 'Postal Code'}
                                    </FormLabel>
                                    <div style={{ marginTop: -7 }}>
                                        <TextInputWithLabel
                                            twoline='true'
                                            onChange={handlePostalCode}
                                            prevalue={customerMasterData.postal_code} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel

                                        required={fieldLabel['customer_industry'] ? fieldLabel['customer_industry']['mandatory'] : false}
                                        style={{ marginTop: '22px' }}>
                                        {fieldLabel['customer_industry'] ? fieldLabel['customer_industry']['current'] : 'customer_industry'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponentNew
                                        page={'masterData'}
                                        classes={{ root: classes.fontSetting }}
                                        withOutLabel
                                        attributeName="customer_industry" value={industrialType} onChange={handleIndustrialType} prevalue={industrialType} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel

                                        required={fieldLabel['customer_classification'] ? fieldLabel['customer_classification']['mandatory'] : false}
                                        style={{ marginTop: '21px' }}>
                                        {fieldLabel['customer_classification'] ? fieldLabel['customer_classification']['current'] : 'customer_classification'}</FormLabel>
                                    <AllOtherAttributeMasterComponentNew
                                        page={'masterData'}
                                        classes={{ root: classes.fontSetting }}
                                        withOutLabel
                                        attributeName="customer_classification" value={classification} onChange={handleClassification} prevalue={classification} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['class_of_trade'] ? fieldLabel['class_of_trade']['mandatory'] : false}
                                        style={{ marginTop: '21px' }}>
                                        {fieldLabel['class_of_trade'] ? fieldLabel['class_of_trade']['current'] : 'class_of_trade'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponentNew
                                        page={'masterData'}
                                        withOutLabel
                                        attributeName='class_of_trade'
                                        value={classOfTrade}
                                        onChange={handleClassOfTrade}
                                        prevalue={classOfTrade} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['account_group'] ? fieldLabel['account_group']['mandatory'] : false}
                                        style={{ marginTop: '21px' }}>
                                        {fieldLabel['account_group'] ? fieldLabel['account_group']['current'] : 'account_group'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponentNew
                                        page={'masterData'}
                                        withOutLabel
                                        attributeName="account_group" value={accountGroup} onChange={handleAccountGroup} prevalue={accountGroup} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 7 }}
                                >
                                    <FormLabel

                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['head_office'] ? fieldLabel['head_office']['mandatory'] : false}
                                        style={{ marginTop: '9px' }}>
                                        {fieldLabel['head_office'] ? fieldLabel['head_office']['current'] : 'Head Office'}

                                    </FormLabel>
                                    <TextInputWithLabel
                                        twoline='true'
                                        onChange={handleHeadOffice}
                                        prevalue={customerMasterData.head_office} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['payment_terms'] ? fieldLabel['payment_terms']['mandatory'] : false}
                                        style={{ marginTop: '18px' }}>
                                        {fieldLabel['payment_terms'] ? fieldLabel['payment_terms']['current'] : 'Payment Terms'}</FormLabel>
                                    <div style={{ marginTop: 3 }}>
                                        <AllOtherAttributeMasterComponentNew
                                            page={'masterData'}
                                            withOutLabel
                                            attributeName="payment_terms"
                                            value={paymentTerms}
                                            onChange={handlePaymentTerms}
                                            prevalue={customerMasterData.payment_terms} />
                                    </div>
                                </Grid>
                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} twoline='true' onChange={handleFlexAttribute1} prevalue={customerMasterData.flex_attribute1} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} twoline='true' onChange={handleFlexAttribute2} prevalue={customerMasterData.flex_attribute2} />
                                </Grid> */}
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 16 }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['customer_status'] ? fieldLabel['customer_status']['mandatory'] : false} >
                                        {fieldLabel['customer_status'] ? fieldLabel['customer_status']['current'] : 'Customer Status'}
                                    </FormLabel>
                                    <div style={{ marginTop: 15 }}>
                                        <Select
                                            value={active}
                                            onChange={handleActive}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value={"Active"}>
                                                Active
                                            </MenuItem>
                                            <MenuItem value={"Inactive"}>
                                                Inactive
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: '15px' }}
                                    classes={{ root: classes.gridContainer }}>
                                    <FormLabel
                                        classes={{ root: classes.fontSetting }}
                                        required={fieldLabel['price_list'] ? fieldLabel['price_list']['mandatory'] : false} >
                                        {fieldLabel['price_list'] ? fieldLabel['price_list']['current'] : 'Price List'}

                                    </FormLabel>

                                    <TextInputWithLabel


                                        twoline='true' onChange={handlePriceList} prevalue={customerMasterData.price_list} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        attributeData: state.attributeListData.AttributeData,
        customerMasterData: state.customerData.customerMasterViewData,
        dropdownData: state.customerData.dropdownDataCustomer,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addCustomerMasterXRefUser(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomer()),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null)(GeneralData);