//New Ui Designer
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import Tooltip from "@mui/material/Tooltip";
import { FormLabel, MenuItem, Select, Grid, Checkbox, OutlinedInput, Button } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import BeatLoader from "react-spinners/BeatLoader";
import { TextField } from '@mui/material';
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
//import ChipInput from 'material-ui-chip-input';
import { ChipInput } from '../../components/Inputs'
import { FormControl } from '@mui/material';
import RingLoader from 'react-spinners/RingLoader';
import { getTableName, getLblDispDesFieldValue, allowedApps } from '../../redux/actions';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 30px 0px'
    },
    container: {
        backgroundColor: theme.palette.white,
        paddingTop: 18,
        width: '100%',
        height: '100%'
    },
    rootDropdown: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        padding: '0px 47px 12px 10px',
        borderTop: '0.1px solid',
        borderTopColor: theme.palette.border.main,
        paddingTop: 5,
    },
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    headerContainer: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        // justifyContent: 'space-between',
        padding: '0px 47px 12px 10px',
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.primary.main,
        position: 'sticky',
        top: '64px',
        zIndex: 1,
    },
    select: {
        color: '#1675e0',
        backgroundColor: theme.palette.white,
    },
    spinner: {
        //justifyContent: 'center'
        //height: '100vh'
    },
    formControlRoot: {
        justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    rootContainer: {
        padding: '10px'
        //     border: '1px solid',
        //     borderColor: theme.palette.border.main,
        //     borderRadius: 3,
        //     marginTop: 10,
        //     padding: 20,
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: '-2rem',
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    inputRoot: {
        padding: '10px 0px 0px 10px'
        // height: 21,
        // paddingLeft: 8,
        // paddingBottom: 25,

        //paddingTop: 8
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        overflowWrap: 'break-word',
        paddingLeft: 10
    },
    gridDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        overflowWrap: 'break-word',
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 6,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    rootContainer: {
        borderRadius: 3,
        padding: "4px 6px",
    },
}));

const LabelDisplayDesigner = props => {
    const classes = useStyles();
    const [APIKeyValueData, setAPIKeyValueData] = React.useState('');
    const [dataSource, setDataSource] = React.useState('')
    const [dataSourceArray, setDataSourceArray] = React.useState([])
    const [tableName, setTableName] = React.useState({})
    const [applicationType, setApplicationType] = React.useState('')
    const [allowedApps, setAllowedApps] = React.useState([])
    const [appDisabled, setAppDisabled] = React.useState(true)
    const [currentID, setCurrentID] = React.useState('')
    const [orgID, setOrgID] = React.useState('')
    useEffect(() => {
        // var data = {
        //     "data_source": "Direct - Sales Data",
        // }
        // props.fieldDesigner(data, 'post');
        props.allowedApps();
        props.getTableName()

    }, []);
    useEffect(() => {
        if (props.tableData && props.tableData.field_label_attributes) {
            setAPIKeyValueData(props.tableData.field_label_attributes)
            setCurrentID(props.tableData.ID)
            setOrgID(props.tableData.organization_id)
        }
    }, [props.tableData]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            // Filter out 'Master Data' and 'Transaction Data'
            const filteredApps = props.allowedAppsData.filter(app => app !== 'Master Data' && app !== 'Transaction Data');
            setAllowedApps(filteredApps);
        }
    }, [props.allowedAppsData])
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        if (props.dataSource && Object.entries(props.dataSource).length > 0) {

            setDataSourceArray(props.dataSource)
            setDataSource('application data')
            if (props.dataSource['application data'] && props.dataSource['application data'][0]) {
                setTableName(props.dataSource['application data'][0])
                var data = {
                    "data_source": props.dataSource['application data'][0]['data_source_key']
                }
                if (props.dataSource['application data'][0]['application_type'] &&
                    props.dataSource['application data'][0]['application_type'] != 'NA') {
                    setAppDisabled(false)
                    data['application_type'] = allowedApps && allowedApps[0]
                    setApplicationType(allowedApps && allowedApps[0])
                }
                else
                    setAppDisabled(true)

                props.fieldDesigner(data, 'post');
            }

        }
    }, [props.dataSource])
    function handleSelectChange(value, key, type) {
        if (type === 'sort_details' || type === 'sort_list') {
            APIKeyValueData[key][type] = Number(value);
        }
        else {
            APIKeyValueData[key][type] = value;
        }
    }
    function handleDeleteChip(deletedChip, key, deletedChipIndex) {
        const currentChips = APIKeyValueData.field_label_attributes[key]['drop_down_values'];

        const updatedChips = currentChips.filter((chip, index) => index !== deletedChipIndex);

        const updatedAPIKeyValueData = {
            ...APIKeyValueData,
            field_label_attributes: {
                ...APIKeyValueData.field_label_attributes,
                [key]: {
                    ...APIKeyValueData.field_label_attributes[key],
                    drop_down_values: updatedChips,
                },
            },
        };
        setAPIKeyValueData(updatedAPIKeyValueData);
    }
    const handleSubmit = () => {
        var data = {
            ID: currentID,
            organization_id: orgID,
            field_label_attributes: APIKeyValueData,
            table_name: tableName.data_source_key
        }
        if (applicationType) {
            data['application_type'] = applicationType
        }
        props.fieldDesigner(data, 'edit')
    }
    const handleClear = () => {
        var data = {
            data_source: tableName.data_source_key,
            application_type: applicationType
        }
        props.fieldDesigner(data, 'reset')
    }
    const handleDataSource = (e) => {
        setDataSource(e.target.value);
        setTableName({})
        setApplicationType('')
        setAPIKeyValueData('')
    }
    const handleTableName = (item) => {
        setTableName(item);
        setAPIKeyValueData('')
        var data;
        if (item.application_type == 'NA') {
            data = {
                data_source: item.data_source_key
            }
            setAppDisabled(true)
        }
        else {
            if (applicationType)
                data = {
                    data_source: item.data_source_key,
                    application_type: applicationType
                }
            setAppDisabled(false)
        }
        setApplicationType('')
        if (data)
            props.fieldDesigner(data, 'post');
    }
    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value)
        setAPIKeyValueData('')
        var data = {
            data_source: tableName.data_source_key,
            application_type: e.target.value
        }
        props.fieldDesigner(data, 'post');
    }
    const toolTipTheme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "2em",
                        color: "yellow",
                        backgroundColor: "red"
                    }
                }
            }
        }
    });
    return (
        <div className={classes.rootDiv} style={{ backgroundColor: 'white' }}>
            <Grid container className={classes.root} style={{ backgroundColor: 'white' }}>
                <Grid container style={{ margin: 10, backgroundColor: 'white', padding: 10, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        style={{ paddingRight: 15 }}
                    >{console.log(dataSourceArray)}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel classes={{ root: classes.formLabel }} style={{ marginBottom: 5 }} required>
                                Data Source
                            </FormLabel>
                            <Select
                                value={dataSource}
                                onChange={handleDataSource}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                style={{ textTransform: 'capitalize' }}
                            >
                                {dataSourceArray && Object.entries(dataSourceArray).length > 0 &&
                                    Object.entries(dataSourceArray)
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item[0]} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item[0]}
                                                </MenuItem>
                                            )
                                        })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        style={{ paddingRight: 15 }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormLabel classes={{ root: classes.formLabel }} style={{ marginBottom: 5 }} required>
                                Table Name
                            </FormLabel>
                            <Select
                                value={tableName}
                                onChange={(e) => handleTableName(e.target.value)}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                style={{ textTransform: 'capitalize' }}
                            >
                                {dataSourceArray && dataSourceArray[dataSource] && dataSourceArray[dataSource].length > 0 &&
                                    dataSourceArray[dataSource]
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item.data_source_name}
                                                </MenuItem>
                                            )
                                        })}
                            </Select>
                        </div>
                    </Grid>
                    {!appDisabled &&
                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.formLabel }}
                                    style={{ marginBottom: 5 }}
                                    required={!appDisabled}>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    disabled={appDisabled}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid>
                    }
                </Grid>

                <div className={classes.container} >
                    <LoadingOverlay
                        active={props.loading}
                        spinner={<RingLoader size={80} />}
                        styles={{
                            spinner: (base) => ({
                                ...base,
                                width: '50px',
                                '& svg circle': {
                                    stroke: '#045FB4'
                                }
                            }),
                            overlay: (base) => ({
                                ...base,
                                background: 'rgba(52, 52, 52, 0)'
                            }),
                            content: (base) => ({
                                ...base,
                                color: 'black'
                            })
                        }}

                        className={classes.spinner}
                    >
                        <div className={classes.headerContainer}>
                            <Grid
                                item
                                md={tableName && tableName.sequence_detail_screen ? 5 : 6}
                                xs={12}
                                //className={classes.gridContainer}
                                style={{ display: 'flex' }}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <div>Default Field Label</div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <Tooltip title='Click to update'>
                                        <div style={{ marginLeft: '-5px' }} >Current Field Label </div>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                //className={classes.gridContainer}
                                style={{ display: 'flex' }}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <Tooltip title='Select required fields'>
                                        <div> Mandatory </div>
                                    </Tooltip>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.gridContainer}
                                >
                                    <Tooltip title='Select fields to display'>
                                        <div> Display </div>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            {tableName && tableName.sequence_detail_screen &&
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                    className={classes.gridContainer}
                                    style={{ marginLeft: "-20px" }}
                                >
                                    <Tooltip title='Add field order for detail  screen'>
                                        <div> Seq Detail </div>
                                    </Tooltip>
                                </Grid>
                            }
                            {tableName && tableName.sequence_list_screen &&
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                    className={classes.gridContainer}

                                >
                                    <Tooltip title='Add field order for list  screen'>
                                        <div> Seq List  </div>
                                    </Tooltip>
                                </Grid>
                            }
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.gridContainer}
                            >
                                <Tooltip title='Separate values with Enter'>
                                    <div>Drop Down Value </div>
                                </Tooltip>
                            </Grid>
                        </div>
                        {APIKeyValueData && APIKeyValueData !== null && Object.keys(APIKeyValueData).length > 0 &&
                            Object.entries(APIKeyValueData)
                                .filter(([key, value]) => !value.ignore_fields)
                                .sort(([k1, v1], [k2, v2]) => v1.default > v2.default ? 1 : -1)
                                .map(([key, value], index) => (
                                    <div >
                                        <div className={clsx({
                                            [classes.rootDropdown]: true
                                        })}
                                        ><Grid
                                            item
                                            md={tableName && tableName.sequence_detail_screen ? 5 : 6}
                                            xs={12}
                                            //className={classes.gridContainer}
                                            style={{ display: 'flex' }}
                                        >
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    className={classes.gridContainer}
                                                >
                                                    <div style={{ color: 'lightslategrey' }}>
                                                        {value['default']}
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    className={classes.gridContainer}
                                                >
                                                    <input
                                                        key={key}
                                                        type="text"
                                                        defaultValue={value['current']}
                                                        style={{ border: 'none', width: '250px' }}
                                                        onChange={(event) => handleSelectChange(event.target.value, key, 'current')}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                                //className={classes.gridContainer}
                                                style={{ display: 'flex' }}
                                            >
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    className={classes.gridDataContainer}
                                                >
                                                    <Checkbox
                                                        key={key}
                                                        defaultChecked={value['mandatory']}
                                                        onChange={(event) => handleSelectChange(event.target.checked, key, 'mandatory')}
                                                        // color='primary'
                                                        color={value['required'] ? 'default' : 'primary'}
                                                        disabled={value['required']}
                                                        classes={{
                                                            root: classes.formControlRoot
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    style={{ justifyContent: 'center' }}
                                                    className={classes.gridDataContainer}
                                                >
                                                    <Checkbox
                                                        key={key}
                                                        //checked={APIKeyValueData[key]['display']}
                                                        defaultChecked={value['display']}
                                                        onChange={(event) => handleSelectChange(event.target.checked, key, 'display')}
                                                        // color='primary'
                                                        color={value['required'] ? 'default' : 'primary'}
                                                        disabled={value['required']}
                                                        classes={{
                                                            root: classes.formControlRoot
                                                        }}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {tableName && tableName.sequence_detail_screen &&
                                                <Grid
                                                    item
                                                    md={1}
                                                    xs={12}
                                                    className={classes.gridDataContainer}
                                                >
                                                    <TextField
                                                        key={key}
                                                        type="number"
                                                        variant="outlined"
                                                        defaultValue={value['sort_details'] && value['sort_details'] !== 'null' ? value['sort_details'] : 'null'}
                                                        style={{ width: '70px', height: '30px' }}
                                                        inputProps={{ style: { padding: '12px 12px' } }}
                                                        onChange={(event) => handleSelectChange(event.target.value, key, 'sort_details')}
                                                    />
                                                </Grid>
                                            }
                                            {tableName && tableName.sequence_list_screen &&
                                                <Grid
                                                    item
                                                    md={1}
                                                    xs={12}
                                                    className={classes.gridDataContainer}
                                                >

                                                    <TextField
                                                        key={key}
                                                        type="number"
                                                        variant="outlined"
                                                        defaultValue={value['sort_list'] && value['sort_list'] !== null ? value['sort_list'] : 'null'}
                                                        style={{
                                                            width: '70px', marginLeft: '3rem',
                                                            //backgroundColor: value['data_type'] == 'jsonb' && '#EBEBE4',
                                                        }}
                                                        inputProps={{ style: { padding: '12px 12px', backgroundColor: value['data_type'] == 'jsonb' && '#EBEBE4', } }}
                                                        onChange={(event) => handleSelectChange(event.target.value, key, 'sort_list')}
                                                        disabled={value['data_type'] == 'jsonb' ? true : false}

                                                    />
                                                </Grid>
                                            }
                                            <Grid
                                                item
                                                md={3}
                                                xs={12} style={{ marginLeft: '5rem' }}>
                                                {value['drop_down_reference'] ?
                                                    <div style={{ color: 'lightslategrey', marginLeft: '3rem' }}>
                                                        {value['drop_down_reference']}
                                                    </div>
                                                    :

                                                    <Tooltip title=''
                                                        classes={{
                                                            tooltip: {
                                                                fontSize: "2em",
                                                                color: "yellow",
                                                                backgroundColor: "red"
                                                            }
                                                        }}>

                                                        <div style={{ marginLeft: '-4rem' }}>

                                                            <FormControl variant="outlined" fullWidth>
                                                                <ChipInput data={value['drop_down_values'] !== 'null' ? value['drop_down_values'] : []}
                                                                    onChange={newValue => handleSelectChange(newValue, key, 'drop_down_values')}
                                                                    size='large' id={value['key']}
                                                                    disabled={value['data_type'] !== 'string' || value['ignore_dropdown']}
                                                                />
                                                                {/* <ChipInput
                                                                    size='small'
                                                                    key={key}
                                                                    defaultValue={value['drop_down_values'] !== 'null' ? value['drop_down_values'] : []}
                                                                    onChange={newValue => handleSelectChange(newValue, key, 'drop_down_values')}
                                                                    onDelete={(deletedChip, index) => handleDeleteChip(deletedChip, key, index)}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    blurBehavior='add'
                                                                    style={{ backgroundColor: (value['data_type'] !== 'string' || value['ignore_dropdown']) && '#EBEBE4' }}
                                                                    classes={{
                                                                        root: classes.rootContainer,
                                                                    }}
                                                                    disabled={value['data_type'] !== 'string' || value['ignore_dropdown']}
                                                                /> */}
                                                            </FormControl>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Grid>


                                        </div>
                                    </div>

                                ))}
                        <div className={classes.buttonRoot}>
                            {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_ui_designer-' + sessionStorage.getItem('application')
                            ) && (
                                    <>
                                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear} >
                                            Reset
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={handleSubmit}
                                        >
                                            {props.loading ?
                                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                :
                                                'Save'
                                            }
                                        </Button>
                                    </>
                                )}
                        </div>
                    </LoadingOverlay>
                </div>
            </Grid>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        dataSource: state.addMultipleConfigurationData.tableName,
        allowedAppsData: state.initialData.allowedApps

    }
};
const mapDispatchToProps = dispatch => {
    return {
        getTableName: () => dispatch(getTableName()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        allowedApps: () => dispatch(allowedApps())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LabelDisplayDesigner);