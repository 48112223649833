import React, { useEffect } from 'react';
import Chart from "react-apexcharts";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import clsx from 'clsx';
import { makeStyles, useTheme } from "@mui/styles";
import {
    Grid, Select, FormLabel, MenuItem, Button, Typography, Checkbox, Radio, RadioGroup,
    FormControlLabel, ListItemText, TextField, Drawer, AppBar, Toolbar, CssBaseline, Divider, IconButton
} from '@mui/material';
import { pricingDashboardMargin, getCustomerMasterData, getQualifierConfig } from '../../../redux/actions';
import { connect } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject,
    Legend, Tooltip, WaterfallSeries, DataLabel, Category, Crosshair, Zoom
} from '@syncfusion/ej2-react-charts';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    root2: {
        marginTop: 15,
        backgroundColor: 'white',
        padding: '20px 30px 20px 30px',
        borderRadius: 10,
        height: '100%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: 65
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        width: '100%',
        height: '800px'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textInput: {
        border: '1px solid #E0E0E0',
        width: '100%',
        borderRadius: 5,
        color: '#1675e0'
    },
    select: {
        width: 209
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));

const PricingWaterfallChart = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [max, setMax] = React.useState(0)
    const [min, setMin] = React.useState(0)
    const [periodicity, setPeriodicity] = React.useState('day');
    const [listOpen, setListOpen] = React.useState('');
    const [type, setType] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [analysislevel, setAnalysisLevel] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const analysisLevelArray = ['customer_number', 'customer_group', 'customer_chain', 'material_number', 'material_group', 'product_hierarchy']
    const isAllSelected =
        analysisLevelArray.length > 0 && selected.length === analysisLevelArray.length;
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [forecastStartDate, setForecastStartDate] = React.useState(null);
    const [forecastEndDate, setForecastEndDate] = React.useState(null);
    const [filterList, setFilterList] = React.useState([]);
    const [simulationCategory, setSimulationCategory] = React.useState('');
    const [active, setActive] = React.useState(false);
    const tooltip = { enable: true, shared: false };
    const legendSettings = { visible: false };
    const formatter = (v) => v.toFixed(2);
    const textRender = (args) => {
        let value = Number(args.text);
        value = value.toFixed(2)
        args.text = value
    };
    const primaryxAxis = { valueType: 'Category', majorGridLines: { width: 0 } };
    const primaryyAxis = {
        title: 'Prices',
        minimum: 0,
        maximum: max + 10,
        interval: max / 9,
        majorTickLines: { width: 0 },
        lineStyle: { width: 0 },
    };
    const marker = { dataLabel: { visible: true, font: { color: '#ffffff' } } };
    const connector = { color: '#5F6A6A', width: 2 };
    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState({});
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        props.getConfigData(1, 0);
        props.onCheckCustomerTable();
        var formData = {
            "historical_start_date": simulationCategory === 'sales data - historical' && startDate ? Moment.utc(startDate) : null,
            "historical_end_date": simulationCategory === 'sales data - historical' && endDate ? Moment.utc(endDate) : null,
            "forecast_start_date": simulationCategory === 'sales data - forecast' && forecastStartDate ? Moment.utc(forecastStartDate) : null,
            "forecast_end_date": simulationCategory === 'sales data - forecast' && forecastEndDate ? Moment.utc(forecastEndDate) : null,
            "periodicity": 'cumulative',
            "simulation_category": simulationCategory,
            "key_figure_name": analysislevel,
            "filter": attributeObjectAPI,
        }
        props.pricingDashboardMarginAPI(formData);
    }, []);
    useEffect(() => {
        if (props.pricingCombined && props.pricingCombined[0] && Object.keys(props.pricingCombined[0]).length > 0) {
            var obj = [props.pricingCombined[0]?.base_price,
            props.pricingCombined[0]?.on_invoice_discounts,
            props.pricingCombined[0]?.on_invoice_surcharges,
            props.pricingCombined[0]?.on_invoice_freight,
            props.pricingCombined[0]?.invoice_price,
            props.pricingCombined[0]?.off_invoice_adjustments,
            props.pricingCombined[0]?.off_invoice_price,
            props.pricingCombined[0]?.cost,
            props.pricingCombined[0]?.margin_price
            ];
            var obj1 = Object.values(props.pricingCombined[0]?.price_type_name_values)
            if (radioGroupValue === 'Summary') {
                setMax(Math.max(...obj))
                setMin(Math.min(...obj))
            } else {
                setMax(Math.max(...obj1))
                setMin(Math.min(...obj1))
            }
        }
    }, [props.pricingCombined])
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = [];
            var tempNameArray = [];
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNumberList(tempArray)
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.configData && props.configData.records) {
            const list = props.configData.records.map(e => e.qualifier_key)
            setFilterList(list);
        }
    }, [props.configData]);
    // useEffect(() => {
    //     if (periodicity=='month'||(analysislevel&&analysislevel.length>1)) {
    //       setRadioGroupValue('List')
    //     }
    //     else{
    //         setRadioGroupValue('Chart')
    //     }
    // }, [periodicity,analysislevel]);
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (type == 'filter')
                setSelected(selected.length === analysisLevelArray ? [] : analysisLevelArray);
            else
                setSelected(selected.length === analysisLevelArray ? [] : analysisLevelArray);
            return;
        }
        setSelected(newSelected)

    }
    function onAdd() {
        if (type == 'filter')
            setFilters(selected)
        else
            setAnalysisLevel(selected)
        setListOpen(false)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])
        setType('')

    }
    const handleStartDate = (e) => {
        setStartDate(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }
    const handleForecastStartDate = (e) => {
        setForecastStartDate(e);
    }
    const handleForecastEndDate = (e) => {
        setForecastEndDate(e);
    }
    function handleOpen(type) {
        setType(type)
        if (type == 'filter')
            setSelected(filters)
        else
            setSelected(analysislevel)
        setListOpen(true)
    }
    const handleSubmit = () => {
        setActive(true)
        filterList.map((item) => {
            if (filters.includes(item)) {

            } else {
                delete attributeObjectAPI[item]
            }
        })
        var formData = {
            "historical_start_date": simulationCategory === 'sales data - historical' && startDate ? Moment.utc(startDate) : null,
            "historical_end_date": simulationCategory === 'sales data - historical' && endDate ? Moment.utc(endDate) : null,
            "forecast_start_date": simulationCategory === 'sales data - forecast' && forecastStartDate ? Moment.utc(forecastStartDate) : null,
            "forecast_end_date": simulationCategory === 'sales data - forecast' && forecastEndDate ? Moment.utc(forecastEndDate) : null,
            "periodicity": 'cumulative',
            "simulation_category": simulationCategory,
            "key_figure_name": analysislevel,
            "filter": attributeObjectAPI,
        }
        props.pricingDashboardMarginAPI(formData);
        setOpen(false);
    }



    const handleAnalysisLevelValues = (value, item) => {
        var attributeObject = [];
        attributeObject.push({
            [item]: value
        });
        if (item === 'customer_number') {
            value = [value]
        }
        setAttributeObjectAPI(prevState => ({
            ...prevState,
            [item]: value
        }));

    }
    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}

            >
                <Toolbar style={{ background: '#FAFAFA', position: 'absolute', marginTop: 65 }}>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        className={clsx(open && classes.hide)}
                        size="large">
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                {props.pricingCombined[0] && Object.keys(props.pricingCombined[0]).length > 0 &&
                    <div className={classes.root2}>
                        <ChartComponent id='charts'
                            // pointColorMapping='color'
                            textRender={textRender}
                            primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} tooltip={tooltip} legendSettings={legendSettings} title='Pricing Simulation Analysis'>
                            <Inject services={[WaterfallSeries, Category, Tooltip, Zoom, Crosshair, Legend, DataLabel]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective
                                    sumIndexes={[4, 6, 8]}
                                    pointColorMapping="color"
                                    dataSource={
                                        radioGroupValue === 'Summary' ?
                                            [
                                                {
                                                    x: 'Base Price',
                                                    y: props.pricingCombined[0]?.base_price,
                                                    color: '#CB4335'
                                                },
                                                {
                                                    x: 'On-Invoice Discounts',
                                                    y: props.pricingCombined[0]?.on_invoice_discounts,
                                                    color: '#F5F530'
                                                },
                                                {
                                                    x: 'On-Invoice Surcharges',
                                                    y: props.pricingCombined[0]?.on_invoice_surcharges,
                                                    color: '#3084F5'
                                                },
                                                {
                                                    x: 'On-Invoice Freight',
                                                    y: props.pricingCombined[0]?.on_invoice_freight,
                                                    color: '#B930F5'
                                                },
                                                {
                                                    x: 'Invoice Price',
                                                    y: props.pricingCombined[0]?.invoice_price,
                                                    color: '#F72051'
                                                },
                                                {
                                                    x: 'Off-Invoice Adjustments',
                                                    y: props.pricingCombined[0]?.off_invoice_adjustments,
                                                    color: '#8542E1'
                                                },
                                                {
                                                    x: 'Off-Invoice Price',
                                                    y: props.pricingCombined[0]?.off_invoice_price,
                                                    color: '#92C31A'
                                                },
                                                {
                                                    x: 'Cost',
                                                    y: props.pricingCombined[0]?.cost,
                                                    color: '#0E9AB6'
                                                },
                                                {
                                                    x: 'Margin Price',
                                                    y: props.pricingCombined[0]?.margin_price,
                                                    color: '#1F618D'
                                                }
                                            ]
                                            :
                                            [
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[0],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[0],
                                                    color: '#A7C2E1'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[1],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[1],
                                                    color: '#99EF3E'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[2],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[2],
                                                    color: '#5D520C'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[3],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[3],
                                                    color: '#B04B18'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[4],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[4],
                                                    color: '#CB4335'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[5],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[5],
                                                    color: '#F5F530'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[6],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[6],
                                                    color: '#3084F5'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[7],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[7],
                                                    color: '#B930F5'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[8],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[8],
                                                    color: '#F72051'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[9],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[9],
                                                    color: '#8542E1'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[10],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[10],
                                                    color: '#92C31A'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[11],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[11],
                                                    color: '#0E9AB6'
                                                },
                                                {
                                                    x: Object.keys(props.pricingCombined[0]?.price_type_name_values)[12],
                                                    y: Object.values(props.pricingCombined[0]?.price_type_name_values)[12],
                                                    color: '#1F618D'
                                                },
                                            ]
                                    }
                                    columnWidth={0.7} connector={{ color: '#445AC7', width: 2 }} border={{ color: 'black', width: 2 }}
                                    xName='x' yName='y' name='Pricing Simulation Analysis' type='Waterfall' marker={marker} >
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    </div>
                }
            </main>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                </div>
                <Divider
                    sx={{
                        opacity: "0.6"
                    }} />
                <Grid container className={classes.root} >
                    <Grid container style={{ margin: 10, padding: 5 }}>
                        <Grid
                            item
                            md={12}
                            xs={12}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel required style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}>
                                    Simulation Category
                                </FormLabel>
                                <Select
                                    value={simulationCategory}
                                    onChange={(e) => setSimulationCategory(e.target.value)}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'sales data - historical'}>
                                        Sales data - historical
                                    </MenuItem>
                                    <MenuItem value={'sales data - forecast'}>
                                        Sales data - Forecast
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: (simulationCategory === 'sales data - historical' ? 'block' : 'none') }}

                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }} required={forecastStartDate && forecastEndDate ? false : true}>
                                    Historical Start Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: (simulationCategory === 'sales data - historical' ? 'block' : 'none') }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}
                                    required={forecastStartDate && forecastEndDate ? false : true}>
                                    Historical End Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: (simulationCategory === 'sales data - forecast' ? 'block' : 'none') }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }}
                                    required={startDate && endDate ? false : true}>
                                    Forecast Start Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={forecastStartDate}
                                            onChange={handleForecastStartDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: (simulationCategory === 'sales data - forecast' ? 'block' : 'none') }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5 }} required={startDate && endDate ? false : true}>
                                    Forecast End Date
                                </FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    color: '#1675e0',
                                                    width: 209
                                                }
                                            }}
                                            value={forecastEndDate}
                                            onChange={handleForecastEndDate}
                                            format="MM/DD/YYYY"
                                            minDate={forecastStartDate ? forecastStartDate : false}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>

                        {/* <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }} required>
                                    Analysis Level
                                </FormLabel>
                                <Select
                                    value={analysislevel}

                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={() => handleOpen('analysis_level')}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen && type == 'analysis_level'}
                                    renderValue={(level) => level.toString().replace(/_/g, ' ')}
                                    style={{ textTransform: 'capitalize' }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {analysisLevelArray.map(item => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid> */}
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <FormLabel style={{ color: 'grey', fontSize: 14, marginBottom: 5, marginRight: 15 }}>
                                    Filters
                                </FormLabel>
                                <Select
                                    value={filters}

                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={() => handleOpen('filter')}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen && type == 'filter'}
                                    renderValue={(level) => level.toString().replace(/_/g, ' ')}
                                    style={{ textTransform: 'capitalize' }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {filterList && filterList.map(item => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item) > -1} />
                                                <ListItemText primary={item.replace(/_/g, ' ')} />
                                            </MenuItem>
                                        );
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </div>
                        </Grid>
                        {filters && filters.length > 0 &&
                            filters.map((item) => {
                                return (
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                            <FormLabel
                                                style={{ color: 'grey', fontSize: 14, marginBottom: 5, textTransform: 'capitalize' }}>
                                                {item.replace(/_/g, ' ')}
                                            </FormLabel>
                                            <ChipInput
                                                classes={{
                                                    root: classes.rootContainer,
                                                    chip: classes.chip,
                                                    input: classes.input,
                                                    inputRoot: classes.inputRoot,
                                                    chipContainer: classes.chipContainer,
                                                    label: classes.chipLabel
                                                }}
                                                onChange={(value) => handleAnalysisLevelValues(value, item)}
                                                disableUnderline={true}
                                                className={clsx({
                                                    [classes.textInput]: true
                                                })}
                                                style={
                                                    item != 'customer_number' ?
                                                        { display: 'block', marginRight: 15, width: 209 } : { display: 'none' }
                                                }
                                            />

                                            <Autocomplete
                                                options={customerNumberList}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                                )}
                                                filterOptions={filterOptions}
                                                value={attributeObjectAPI['customer_number']}
                                                style={{ marginBottom: 10, display: item != 'customer_number' ? 'none' : 'block', width: 209 }}
                                                onChange={(event, newValue) => handleAnalysisLevelValues(newValue, item)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>{customerNameListArray[customerNumberList.indexOf(option)] ? customerNameListArray[customerNumberList.indexOf(option)] : ''} - ({option})
                                                    </li>
                                                )}
                                                classes={{
                                                    option: {
                                                        borderBottom: `1px solid red`,
                                                        // Hover
                                                        '&[data-focus="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                        // Selected
                                                        '&[aria-selected="true"]': {
                                                            backgroundColor: 'red',
                                                            borderColor: 'transparent',
                                                        },
                                                    },
                                                    listbox: {
                                                        padding: 0,
                                                    },
                                                    inputRoot: {
                                                        borderRadius: '0px !important'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                );
                            })}
                        <Grid
                            item
                            md={12}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <FormLabel
                                style={{ color: 'grey', fontSize: 14, marginBottom: 5, textTransform: 'capitalize' }} required >
                                Display Option
                            </FormLabel>
                            <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                <FormControlLabel
                                    value="Summary"
                                    control={<Radio color="primary" size='small' />}
                                    label="Summary"
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                                <FormControlLabel
                                    value="Details"
                                    control={<Radio color="primary" size='small' />}
                                    label="Details"
                                    // disabled={periodicity=='month'||(analysislevel&&analysislevel.length>1)?true:false}
                                    classes={{ labelPlacementStart: classes.radioLabel }}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <div style={{ width: '100%', borderBottom: '1px solid #e5e5e5' }} />
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 10 }}>
                        <Button variant="contained" size={'small'} color="primary" onClick={handleSubmit}
                            disabled={((startDate && endDate && simulationCategory && radioGroupValue) || (forecastStartDate && forecastEndDate && simulationCategory && radioGroupValue)) ? false : true} >
                            Apply
                        </Button>
                    </div>
                </Grid>
            </Drawer>
        </div >
    );
}

const mapStateToProps = state => {
    return {
        customerMasterData: state.customerData.customerMasterData,
        pricingCombined: state.initialData.pricingCombinedData,
        configData: state.pricingData.qualifierConfigData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        pricingDashboardMarginAPI: (data) => dispatch(pricingDashboardMargin('waterfall', data)),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
        getConfigData: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PricingWaterfallChart);