import React, { useEffect, forwardRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    useMediaQuery, Button, IconButton, Fab, Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload, Edit } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import Moment from 'moment';
import { deletePromotionCurrencyData, getPromotionCurrencyData } from '../../redux/actions/Promotion/AuthAction';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const PromotionCurrency = props => {

    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const columns = [
        {
            field: 'source_currency',
            title: 'Source Currency',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.source_currency}</a>
        },
        {
            field: 'target_currency',
            title: 'Target Currency',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.target_currency}</a>

        },
        {
            field: 'rate',
            title: 'Rate',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.rate}</a>
        },
        {
            field: 'valid_from',
            title: 'Valid From',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.valid_from).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.valid_from).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'valid_to',
            title: 'Valid To',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.valid_to).format('MM/DD/YYYY')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.valid_to).format('MM/DD/YYYY');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    <>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleEdit(rowData.id)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => props.deletePromotionCurrency(rowData.id)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                    </>
                </div >
        },
    ];

    useEffect(() => {
        props.getPromotionCurrencyData();
    }, []);

    useEffect(() => {
        var tempRows = [];
        if (props.promotionCurrencyData && props.promotionCurrencyData.records && props.promotionCurrencyData.records.length > 0) {
            props.promotionCurrencyData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        source_currency: e.source_currency,
                        target_currency: e.target_currency,
                        rate: e.rate,
                        valid_from: e.valid_from,
                        valid_to: e.valid_to
                    });
                })
            setDataRows(tempRows);
        }
    }, [props.promotionCurrencyData]);


    const addContract = () => {
        localStorage.removeItem('currentAddContractID');
        sessionStorage.setItem('mode', 'add');
    }

    const handleEdit = (id) => {
        navigateToEdit(id);
    }

    function navigateToEdit(id) {
        history.push({
            pathname: '/promo-currency/edit-currency/' + id,
            state: 'editPromoCurrency',
            id: id
        });
        localStorage.setItem('promoCurrencyEditId', id);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}

        >
            <div className={clsx(classes.root, className)}>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'>  Promotion Currency Rates </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            component={CustomRouterLink}
                            to='/promo-currency/excel-upload'
                            onClick={addContract}
                        >
                            <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                        </Fab>
                    </div>
                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </div >
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        promotionCurrencyData: state.promotionData.promotionCurrencyListData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPromotionCurrencyData: () => dispatch(getPromotionCurrencyData()),
        deletePromotionCurrency: (id) => dispatch(deletePromotionCurrencyData(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionCurrency);