import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
    MenuItem, Select, TextField
} from '@mui/material';
import clsx from "clsx";
import { makeStyles, withStyles } from '@mui/styles';
import { addBom, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import MasterDataComponent from '../../components/Inputs/FilledComponents/MasterDataComponent';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: "100%",
        marginTop: 10,
    },
    selectedItem: {
        color: "black",
        border: "1px solid #E0E0E0",
        width: "100%",
    },

    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
}));

const AddBom = props => {


    const classes = useStyles();
    const [externalFields, setExternalFields] = React.useState([]);
    const [externalData, setExternalData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);


    useEffect(() => {
        var data = { "data_source": "sales_bundle_master" };
        props.getLblDispDesFieldValue(data, 'post');
    }, [])

    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    if (item.mandatory || item.sort_details) {
                        newArray.push(item)
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setExternalFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.tableData])



    function handleSubmit() {
        if (externalData['start_date'])
            externalData['start_date'] = Moment.utc(externalData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        if (externalData['end_date'])
            externalData['end_date'] = Moment.utc(externalData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")

        props.onSubmit(externalData);
        handleClearAll();
    }

    function handleClearAll() {
        setExternalData({});
    }

    const handleExternalData = (value, key, type) => {
        if (type == 'reference' && value) {
            value = value.key;
        }
        else {
            if ((key == 'material_cost' || key == 'quantity') && value)
                value = parseFloat(value);

        }
        setExternalData({ ...externalData, [key]: value });
    }


    return (
        <LoadingOverlay
            active={props.loadingAPI}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/bom'
                    >
                        Sales Bundle
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Add Sales Bundle</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <Card>

                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>

                                <Grid container spacing={2}>
                                    {externalFields && externalFields.length > 0 &&
                                        externalFields.map((item, index) => (

                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} required={requiredField.includes(item.current)}>
                                                        {item.current}
                                                    </FormLabel>
                                                    {item.key == 'start_date' || item.key == 'end_date' ?
                                                        <Grid container justifyContent="space-around">
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            // padding: '0px 11px 0px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            marginTop: 10,
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={externalData[item.key] ? externalData[item.key] : null}
                                                                    onChange={date => handleExternalData(date, item.key)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        :
                                                        item.drop_down_values && item.drop_down_values.length > 0 ?
                                                            <Select
                                                                value={externalData[item.key] ? externalData[item.key] : ''}
                                                                onChange={(e) => handleExternalData(e.target.value, item.key)}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                style={{ marginTop: 8 }}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                            >   {item.drop_down_values.map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item === 'Yes' ? true : (item === 'No' ? false : item)} key={index} style={{ textTransform: 'capitalize' }}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                            :
                                                            item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                                <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key} attributeList={item.drop_down_reference_value ? item.drop_down_reference_value : []}
                                                                    onChange={(value) => handleExternalData(value, item.key, 'reference')} />
                                                                :
                                                                <OutlinedInput
                                                                    value={externalData[item.key] ? externalData[item.key] : ''}
                                                                    type={(item.key == 'quantity' || item.key == 'material_cost') ? 'Number' : 'String'}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) => handleExternalData(e.target.value, item.key)} />
                                                    }
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </div>
                        </form>

                    </Card>

                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                            disabled={Object.entries(externalData).filter(([k, v]) => allowedField.includes(k) && v).length == allowedField.length ? false : true}>
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Save'
                            }
                        </Button>
                    </div>

                </div >
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addBom(data)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.addMultipleConfigurationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBom);