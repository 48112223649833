import React, { useState, useEffect, useMemo, useRef, } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Button, IconButton, formControlClasses } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDown, ExpandMore, DeleteOutlined } from '@mui/icons-material';
import { getModels } from '../../../../../../redux/actions';
import DeleteDialog from './DeleteDialog';
import { accessInnerChildren, generateCombinations } from '../../../../Components/ManipulateData';
const useStyles = makeStyles(theme => ({
    accordionMenu: {
        backgroundColor: '#EEEEEE',
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        fontSize: 12,
        height: 36,
        width: 250,
        paddingLeft: 10,
        "&.Mui-expanded": {
            minHeight: 48
        }
    },
    gridButton: {
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        Padding: "8px 12px 8px 12px",
        height: 36,
        width: 54,
        margin: 12,
        backgroundColor: "#E2E2E2"
    },
    locButton: {
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        Padding: "8px 12px 8px 12px",
        height: 40,
        width: '100%',
        color: 'black !important',
        margin: 2,
        backgroundColor: "#E2E2E2",
        // width: 120,
        '&:hover': {
            backgroundColor: '#E2E2E2'
        },


    },
    locMenu: {
        backgroundColor: '#EEEEEE',
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        fontSize: 12,
        height: 36,
        maxHeight: 36,
        width: '100%',
        margin: "10px 10px 0px 0px",
        // display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        webkitFlexWrap: 'nowrap',
        minWidth: 56, padding: 0,//marginBottom:2,
        "&.Mui-expanded": {
            minHeight: 48
        }
    },
    locDetail: {
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        webkitFlexWrap: 'nowrap', marginTop: 2,

    },
    deleteIcon: {
        position: 'absolute',
        top: '50%',
        right: '-30px', /* Adjust as necessary */
        transform: 'translateY(-50%)',
        fontSize: '16px',
        cursor: 'pointer',
        opacity: 0,
        transition: 'opacity 0.3s',
        color: 'white'
    },
    container: {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex_start',
        marginTop: 0,
        position: 'sticky'
    }

}))

const PromoRegion = (props) => {

    const classes = useStyles();
    const [activeSticker, setActiveSticker] = useState([]);
    const [data, setData] = useState([]);
    const [locations, setLocations] = useState([]);
    const [marketExpanded, setMarketExpanded] = React.useState([]);
    const [localFilter, setLocalFilter] = React.useState([]);
    const [modelFilter, setModelFilter] = React.useState([]);
    const [onDelete, setOnDelete] = React.useState(false);
    const [selectedModel, setSelectedModel] = React.useState({});
    const [selectedLoc, setSelectedLoc] = React.useState({});
    const [selectedSticker, setSelectedSticker] = React.useState({});
    const [defaultExpanded, setDefaultExpanded] = React.useState([]);
    const [activeModel, setActiveModel] = useState([]);
    const [activeLoc, setActiveLoc] = useState([]);

    const scrollDivRef = useRef(null);


    // const handleScroll = (event) => {
    //     const element = event.currentTarget;

    //     // Determine if the user is trying to scroll beyond the child component's bounds
    //     if (
    //         (element.scrollLeft === 0 && event.deltaY < 0) ||
    //         (element.scrollLeft === element.scrollWidth - element.clientWidth && event.deltaY > 0)
    //     ) {
    //         event.preventDefault(); // Prevent default behavior which could trigger browser navigation
    //     }
    // };
    useEffect(() => {
        if (props.modelData)
            setData(props.modelData)
    }, [props.modelData])

    useEffect(() => {
        if (props.locationData)
            setLocations(props.locationData)
    }, [props.locationData])
    useEffect(() => {
        if (props.definedSticker)
            setActiveSticker(Object.keys(props.definedSticker))
    }, [props.definedSticker])
    useEffect(() => {
        const temp = []
        const locate = []
        if (props.pageFilter && Object.values(props.pageFilter).length > 0) {
            if (props.pageFilter['model'] &&
                (Object.keys(props.pageFilter['model']).filter(itemx => itemx.toString().startsWith(props.modelYear + "_"))).length > 0) {
                setModelFilter(Object.keys(props.pageFilter['model']))
            }
            else {
                if (props.activeSticker && props.activeSticker.length > 0)
                    setModelFilter(activeModel)
                else
                    setModelFilter([])
            }
            if (props.pageFilter && props.pageFilter['location']) {
                setLocalFilter(Object.keys(props.pageFilter['location']))
            }
            else {
                if (props.activeSticker && props.activeSticker.length > 0)
                    setLocalFilter(activeLoc)
                else
                    setLocalFilter([])
            }
        }
        else if (props.gridFilter && props.activeSticker && props.activeSticker.length > 0) {
            setActiveSticker(props.activeSticker)
            props.activeSticker.map(item => {
                //var key = props.stickerKeys && props.stickerKeys[item.ID] ? props.stickerKeys[item.ID].name : ''
                if (props.gridFilter && props.gridFilter[item] && props.gridFilter[item]['model']) {
                    temp.push(...Object.keys(props.gridFilter[item]['model']))
                }
                if (props.gridFilter && props.gridFilter[item] && props.gridFilter[item]['location']) {
                    locate.push(...Object.keys(props.gridFilter[item]['location']))
                }
                // if (props.definedData && props.definedData[item.ID] && props.definedData[item.ID]['include']) {
                //     Object.values(props.definedData[item.ID]['include']).map((item, i) => {
                //         if (item.relationship)
                //             temp.push(...item.relationship)
                //         if (item.location && item.location[0])
                //             locate.push(Object.values(item.location[0]).join("_") + "_")
                //     })

                // }
            })
            setModelFilter(temp)
            setActiveModel(temp)
            setActiveLoc(locate)
            setLocalFilter(locate)
        }
        else {
            if (props.gridFilter) {
                const allModels = Object.values(props.gridFilter)
                    .map(entry => entry.model && Object.keys(entry.model))
                    .flat();
                setDefaultExpanded(allModels)
            }
            setActiveSticker(Object.keys(props.definedSticker))
            setModelFilter([])
            setLocalFilter([])
        }
    }, [props.gridFilter, props.pageFilter, props.activeSticker, props.modelYear, props.stickerKeys])
    const handleDelete = (model, loc, sticker) => {
        if (sticker) {
            setSelectedModel(model)
            setSelectedLoc(loc)
            setSelectedSticker(sticker)
            setOnDelete(true)

        }
    }
    const handleAccordionChange = (panel, type, relationship) => (event, isExpanded) => {
        if (isExpanded) {
            if (defaultExpanded.includes(Object.values(relationship).join("_")))
                setDefaultExpanded(defaultExpanded.filter(item => item != Object.values(relationship).join("_")))
            else

                setMarketExpanded([...defaultExpanded, Object.values(relationship).join("_")])
        }
        else {
            if (defaultExpanded.includes(Object.values(relationship).join("_")))
                setMarketExpanded(defaultExpanded.filter(item => item != Object.values(relationship).join("_")))
            else
                setDefaultExpanded([...defaultExpanded, Object.values(relationship).join("_")])
        }
    };
    const handleDeletedData = (value) => {
        setOnDelete(false)
        if (value !== 'change') {
            if (value == 'yes')
                props.handleOnDelete(selectedSticker, selectedModel, selectedLoc)
            handleReset()
        }
        if (value == 'change')
            props.handleChangeSticker(selectedSticker, selectedModel, selectedLoc)
    }
    function handleReset() {
        setSelectedModel({})
        setSelectedLoc({})
        setSelectedSticker({})
    }
    const renderNestedItems = (items) => {
        return items && items.filter((item, index) =>
            modelFilter && modelFilter.length > 0 ? modelFilter.some(filterItem => filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`)) : item)
            .map((item, index) => (
                <>
                    {item.type == 'material_group1' ?
                        renderNestedItems(item.children)
                        :
                        item.children && item.children.length > 0 ? (
                            <div key={index} style={{ flexGrow: 1, width: '100%', height: 'auto' }} >

                                < Accordion
                                    expanded={defaultExpanded && (defaultExpanded.includes(Object.values(item.relationship).join("_")) &&
                                        defaultExpanded.some(filterItem => filterItem && filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`))) ? false :
                                        (!(defaultExpanded.includes(Object.values(item.relationship).join("_"))) &&
                                            defaultExpanded.some(filterItem => filterItem && filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`))) ? true :
                                            marketExpanded.includes(Object.values(item.relationship).join("_"))}
                                    onChange={handleAccordionChange(item.category, item.type, item.relationship)}
                                    style={{ margin: 0, }}
                                    fullWidth
                                    slotProps={{ transition: { unmountOnExit: true } }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <AccordionSummary
                                            expandIcon={
                                                <ExpandMore color={(defaultExpanded.includes(Object.values(item.relationship).join("_")) &&
                                                    defaultExpanded.some(filterItem => filterItem && filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`))) ? 'black' :
                                                    (!(defaultExpanded.includes(Object.values(item.relationship).join("_"))) &&
                                                        defaultExpanded.some(filterItem => filterItem && filterItem.startsWith(`${Object.values(item.relationship).join("_")}_`))) ? 'primary' :
                                                        marketExpanded.includes(Object.values(item.relationship).join("_")) ? 'primary' : 'black'}
                                                    onChange={handleAccordionChange(item.category, item.type, item.relationship)}
                                                />}
                                            className={classes.accordionMenu}
                                            style={{ paddingLeft: item.type == 'material_group3' ? 20 : 10, }}

                                        >
                                            <Typography variant='h4' color={'black'} style={{ marginLeft: 10 }} >
                                                {item.category}
                                            </Typography>
                                        </AccordionSummary>
                                        {renderStates(item)}
                                    </div>
                                    <AccordionDetails style={{ padding: 0,/* display: item.type == 'material_group3' && 'flex':'block', flexDirection: item.type == 'material_group3' && 'row'*/ }}
                                        className={(item.type == 'material_group3') && classes.chipContainer}
                                    >
                                        {renderNestedItems(item.children)}
                                    </AccordionDetails>
                                </Accordion>
                            </div >
                        ) :
                            (
                                <div style={{ display: 'flex', alignItems: 'flex-start', height: 48, }}>
                                    <div key={item.category} className={classes.accordionMenu} style={{ height: '100%', paddingLeft: 40,/* position: 'fixed', zIndex: 1999, left: 16 */ }}>
                                        <Typography variant='h4' color={'black'} >{item.category}</Typography>
                                    </div>
                                    {renderStates(item)}
                                </div>
                            )
                    }
                </>
            ));
    };

    const renderStates = (data, type) => {
        return type && type == 'head' ? locations && locations.filter((item, index) =>
            localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_") + "_".toString())) : item)
            .map((parent, index) =>

            (
                <div key={parent.category} style={{ width: '100%', justifyContent: 'center', marginBottom: 10 }}>
                    <Accordion style={{ marginRight: 3 }} expanded>
                        <AccordionSummary expandIcon={<ExpandMore color='primary' />}
                            className={classes.locMenu}
                        >
                            <Typography variant='h4' color={'black'} >{parent.category}</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ width: '100%', padding: 0, marginTop: 2, height: 36 }}>
                            <div className={classes.locDetail} >
                                {parent.children.filter((itemx, i) => localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(itemx.relationship).join("_").toString())) : itemx)
                                    .map((child) => (
                                        <Button variant="outlined" className={classes.locButton}>{child.category}</Button>
                                    ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div >
            ))
            :
            locations && locations.filter((item, index) =>
                localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : item)
                .map((parent, index) =>
                (<div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', webkitFlexWrap: 'nowrap', left: 250, minWidth: 120, marginRight: 3 }} >

                    {parent.children.filter((itemx, i) => localFilter && localFilter.length > 0 ?
                        localFilter.some(filterItem => filterItem.startsWith(Object.values(itemx.relationship).join("_").toString())) : itemx)
                        .map((child, i) => {
                            var clr;
                            var sticker = {}
                            var modelKey = data?.relationship ? [...Object.values(data.relationship), ...(data.fields?.product_line ? data.fields.product_line : [])].join("_") : ''
                            var locKey = child.relationship && Object.values(child.relationship).join("_").toString()
                            activeSticker && activeSticker.length > 0 && activeSticker.map((item, index) => {
                                if ((props.gridFilter && props.gridFilter[item] && props.gridFilter[item]['model'] && props.gridFilter[item]['model'][modelKey])) {

                                    if ((props.definedData && props.definedData[item] && (props.definedData[item]['location'] && props.definedData[item]['location'][locKey]) ||
                                        (props.gridFilter[item]['model'][modelKey]['include'] && props.gridFilter[item]['model'][modelKey]['include'].includes(locKey))) &&
                                        !(props.gridFilter[item]['model'][modelKey]['exclude'] && props.gridFilter[item]['model'][modelKey]['exclude'].includes(locKey))) {


                                        clr = props.colorPicker && props.colorPicker[item];
                                        sticker = item
                                    }
                                }
                            });
                            return (
                                <Button key={`${data.category}${child.category}${i}`} className={classes.locButton} style={{ backgroundColor: clr }}
                                    onClick={(e) => handleDelete(data, child, sticker)} disabled={sticker && Object.keys(sticker).length > 0 ? false : true}>
                                    {/* <DeleteOutlined /> */}
                                </Button>
                            )

                        })}
                </div>))
    }
    //     )


    // }


    return (
        data && data.length > 0 && (data.filter((item, index) => modelFilter && modelFilter.length > 0 ? modelFilter.some(filterItem => filterItem.startsWith(`${props.modelYear}_`)) : item)).length > 0 ?
            <Grid container spacing={2} className={classes.container} ref={scrollDivRef}>

                <div style={{ display: 'flex' }}>
                    <div style={{ backgroundColor: 'white', minWidth: '250px', justifyContent: 'center' }}>
                    </div>
                    <div style={{ display: 'flex', width: '100%', position: '-webkit-sticky', position: 'sticky', paddingLeft: 2, top: '10px' }}>

                        {renderStates(null, 'head')}
                    </div>
                </div>
                <div style={{ maxHeight: 600, overflowY: 'scroll', paddingLeft: 2, top: '10px' }}>
                    {data && data.length > 0 &&
                        renderNestedItems(data.filter((item, index) => item.category == props.modelYear))
                    }
                </div>
                {onDelete && selectedSticker &&
                    <DeleteDialog onChange={handleDeletedData} color={selectedSticker && props.colorPicker ? props.colorPicker[selectedSticker] : ''}
                        sticker={selectedSticker && props.stickers?.[selectedSticker] ? (props.stickers[selectedSticker].promo_variability ? 'PV -' : '') + props.stickers[selectedSticker].sticker_name : ''}
                        model={selectedModel?.category} location={selectedLoc?.category} open={onDelete} />

                }

            </Grid>
            : <Grid container alignItems={'center'}><Typography>No data to show!!</Typography></Grid>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        // getFilterData: (table, data) => dispatch(getModels(table, data))
    }
}

const mapStateToProps = state => {
    return {
        //modelData: state.promotionData.modelList,
        //  locationData: state.promotionData.locationFilters,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PromoRegion);
