import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: theme.spacing(0.3),
    },
    inputContainer: {
        border: '1px solid #ccc',
        maxHeight: '70px',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
    },
}));

const FormulaInput = (props) => {
    const classes = useStyles();
    const [chips, setChips] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const handleDeleteChip = (chipToDelete) => () => {
        setChips(chips.filter((chip, i) => i !== chipToDelete));
        props.onChange(chips.filter((chip, i) => i !== chipToDelete), props.label.filter((chip, i) => i !== chipToDelete))
    };
    useEffect(() => {
        if (props.data) {
            setChips(props.data)
            setInputValue('')
        }
    }, [props.data])

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleInputChange = (event, newValue) => {
        setInputValue(newValue);
    };

    const handleAddChip = (event, type) => {
        let temp = []
        if (event.key === 'Enter' && inputValue.trim()) {
            setChips([...chips, inputValue.trim()]);

            props.onChange([...chips, inputValue.trim()])
        }
        if (type === "pastedItem") {
            event.map((arg) => {
                if (arg) temp.push(arg.trim());
            });
            setChips([...chips, ...temp])
            props.onChange([...chips, ...temp])
        }
        setInputValue('');
        // if (type == 'entered') {
        //     setChips([...chips, event])
        //     props.onChange([...chips, event])
        // }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
        if (inputValue) {
            setChips([...chips, inputValue.trim()]);
            props.onChange([...chips, inputValue.trim()])
        }
        setInputValue('');
    };
    return (
        <div id={props.id} style={{
            height: props.size === "large" ? '50px' : '60px', overflowY: 'auto',
            border: isFocused ? 'transparent' : '1px solid #ccc',
        }}
            onClick={handleClick}
        >
            <Autocomplete
                multiple
                id={props.id}
                options={[]} // You can provide options for autocomplete here
                freeSolo
                value={props.label}
                onChange={(event, newValue) => setChips(newValue)}
                onInputChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        size={props.size ? props.size : "small"}
                        onKeyDown={handleAddChip}
                        onPaste={(event) => {
                            const clipboardText = event.clipboardData
                                .getData("Text")
                                .split("\n");

                            event.preventDefault();
                            handleAddChip(clipboardText, 'pastedItem');
                            if (props.onPaste) {
                                props.onPaste(event);
                            }
                        }}

                        InputProps={{
                            ...params.InputProps,
                            style: {
                                padding: '3px 6px', border: 'none'// border: '1px solid #ccc'
                            },
                        }}
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={option}
                            label={option}
                            size="small"
                            //variant="outlined" color="primary"
                            onDelete={handleDeleteChip(index)}
                            className={classes.chip}

                        />
                    ))
                }
                disabled={props.disabled ? props.disabled : false}
            />
        </div>
    );
};

export default FormulaInput;
