import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card, OutlinedInput,
    CardHeader,
    Grid,
    FormLabel,
    Select, MenuItem,
    TextField, Tooltip
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown, Datepicker, TextInputWithLabel, DropdownArray, AllOtherAttributeMasterComponent, DropdownKeyValue, SearchDropDownMasterDataNew } from '../../../../../components/Inputs';
import { connect } from 'react-redux';
import { getContractDetailsData } from '../../../../../redux/actions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // marginBottom: 23
    },
    container: {
        padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 15px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    gridStyle: {
        paddingLeft: 16,
        paddingRight: 16
    },
    select: {
        width: '100%',
        marginTop: 6
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLineOutlined: {
        width: '100%',
        paddingLeft: 10,
        height: 36,
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2.5rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));

const Payment = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();
    //constants
    const [paymentFrequencyData, setPaymentFrequencyData] = React.useState([]);
    const [paymentFrequencyName, setPaymentFrequencyName] = React.useState('');
    const [paymentLevelData, setPaymentLevelData] = React.useState([]);
    const [paymentLevelName, setPaymentLevelName] = React.useState('');
    const [paymentMethodData, setPaymentMethodData] = React.useState([]);
    const [paymentMethodName, setPaymentMethodName] = React.useState('');
    const [paymentPartnerRoleData, setPaymentPartnerRoleData] = React.useState([]);
    const [paymentPartnerRoleName, setPaymentPartnerRoleName] = React.useState('');
    //new fields
    const [paymentCalendarArray, setPaymentCalendarArray] = React.useState([]);
    const [paymentTermsArray, setPaymentTermsArray] = React.useState([]);
    const [paymentPartnerArray, setPaymentPartnerArray] = React.useState([]);
    const [externalReferenceArray, setExternalReferenceArray] = React.useState([]);
    const [externalReference, setExternalReference] = React.useState('');
    const [flexFields5, setFlexFields5] = React.useState('');
    const [paymentDocumentType, setPaymentDocumentType] = React.useState('');
    const [paymentRun, setPaymentRun] = React.useState('');
    const [flexFields5Array, setFlexFields5Array] = React.useState([]);
    const [passThroughArray, setPassThroughArray] = React.useState([]);
    const [passThroughPaymentPartnerRoleArray, setPassThroughPaymentPartnerRoleArray] = React.useState([]);
    const [passThroughPercentageArray, setPassThroughPercentageArray] = React.useState([]);
    const [passThrough, setPassThrough] = React.useState('');
    const [passThroughPercentage, setPassThroughPercentage] = React.useState('');
    const [passThroughPaymentPartnerRole, setPassThroughPaymentPartnerRole] = React.useState('');
    const [paymentMethodArray, setPaymentMethodArray] = React.useState([]);
    const [paymentDueDate, setPaymentDueDate] = React.useState('');
    const [paymentDueDateName, setPaymentDueDateName] = React.useState('');

    useEffect(() => {
        if (props.fieldData && props.fieldData.length > 0) {
            props.fieldData
                .map((item) => {
                    if (item.field_id === 'payment_calendar') {
                        setPaymentCalendarArray(item);
                    }
                    if (item.field_id === 'payment_partner') {
                        setPaymentPartnerArray(item);
                    }
                    if (item.field_id === 'payment_terms') {
                        setPaymentTermsArray(item);
                    }
                    if (item.field_id === 'payment_frequency') {
                        setPaymentFrequencyData(item.drop_down_values);
                        setPaymentFrequencyName(item.field_label_name);
                    }
                    if (item.field_id === 'payment_level') {
                        setPaymentLevelData(item.drop_down_values);
                        setPaymentLevelName(item.field_label_name);
                    }
                    if (item.field_id === 'payment_method') {
                        setPaymentMethodData(item.drop_down_values);
                        setPaymentMethodName(item.field_label_name);
                    }
                    if (item.field_id === 'payment_partner_role') {
                        setPaymentPartnerRoleData(item.drop_down_values);
                        setPaymentPartnerRoleName(item.field_label_name);
                    }
                    if (item.field_id === 'external_reference') {
                        setExternalReferenceArray(item);
                    }
                    if (item.field_id === 'flex_fields_5') {
                        setFlexFields5Array(item);
                    }
                    if (item.field_id === 'payment_aggregation_level') {
                        setPaymentAggregationLevelArray(item);
                    }
                    if (item.field_id === 'pass_through') {
                        setPassThroughArray(item);
                    }
                    if (item.field_id === 'pass_through_percentage') {
                        setPassThroughPercentageArray(item);
                    }
                    if (item.field_id === 'pass_through_payment_partner_role') {
                        setPassThroughPaymentPartnerRoleArray(item);
                    }
                    if (item.field_id === 'payment_due_date') {
                        setPaymentDueDateName(item.field_label_name);
                    }
                })
            //     }
            // }
        }
    }, [props.fieldData]);


    useEffect(() => {

        if (props.data && props.data.payment_partner_role) {
            setPaymentPartnerRole(props.data.payment_partner_role)
        }
        if (props.data && props.data.payment_level) {
            setPaymentLevel(props.data.payment_level)
        }
        if (props.data && props.data.payment_aggregation_level) {
            setPaymentAggregationLevel(props.data.payment_aggregation_level)
        }
        if (props.data && props.data.payment_level) {
            setPaymentLevel(props.data.payment_level)
            if (props.data.payment_level === 'Aggregated Payment') {
                // if (!paymentDataListofFields.includes('payment_partner')) {
                setPaymentDataListofFields(prevFields => ['payment_partner_role', 'payment_partner', ...prevFields]);
                setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_aggregation_level'))

                setPaymentDataListofFields(prevFields => prevFields.filter(item => item !== 'payment_aggregation_level'));
                // }
            }

            if (props.data.payment_level === 'Individual Payment') {
                // if (!paymentDataListofFields.includes('payment_partner_role')) {
                setPaymentDataListofFields(prevFields => ['payment_partner_role', ...prevFields]);
                setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_aggregation_level'))
                setPaymentDataListofFields(prevFields => prevFields.filter(item => !['payment_partner', 'payment_aggregation_level'].includes(item)));
                // }
            }

            if (props.data.payment_level === 'Flexible Payment') {
                setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_aggregation_level'))
                setPaymentDataListofFields(prevFields => prevFields.filter(item => !['payment_partner', 'payment_aggregation_level'].includes(item)));
            }
        }
        if (props.data && props.data.payment_partner) {
            setPaymentPartner(props.data.payment_partner)
        }
        if (props.data && props.data.payment_frequency) {
            setPaymentFrequency(props.data.payment_frequency)
        }
        if (props.data && props.data.payment_terms) {
            setPaymentTerms(props.data.payment_terms)
        }
        // if (props.data && props.data.payment_partner_role && activeDropdown) {
        //     if (props.data.payment_partner_role === 'Supplier' && activeDropdown['supplier_number']) {
        //         props.getSupplierMasterData();
        //     } else if (props.data.payment_partner_role === 'Employee' && activeDropdown['employee_number']) {
        //         props.getEmployeeData();
        //     } else {
        //         if (activeDropdown['customer_number'])
        //             props.getCustomerMasterData();
        //     }
        // }
        if (props.data && props.data.pass_through) {
            setPassThrough(props.data.pass_through)
        }
        if (props.data && props.data.pass_through) {
            setPassThrough(props.data.pass_through)
        }
        if (props.data && props.data.pass_through_percentage) {
            setPassThroughPercentage(props.data.pass_through_percentage)
        }
        if (props.data && props.data.pass_through_payment_partner_role) {
            setPassThroughPaymentPartnerRole(props.data.pass_through_payment_partner_role)
        }
    }, [props.data])

    const classes = useStyles();
    const { className } = props;
    const [paymentFrequency, setPaymentFrequency] = React.useState('');
    const [paymentLevel, setPaymentLevel] = React.useState('');
    const [paymentTerms, setPaymentTerms] = React.useState('');
    const [paymentCalendar, setPaymentCalendar] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [paymentPartnerRole, setPaymentPartnerRole] = React.useState('');
    const [paymentPartner, setPaymentPartner] = React.useState('');
    const paymentFrequencyRef = useRef(null);
    const paymentLevelRef = useRef(null);
    const paymentTermsRef = useRef(null);
    const paymentCalenderRef = useRef(null);
    const paymentMethodRef = useRef(null);
    const paymentPartnerRoleRef = useRef(null);
    const paymentPartnerRef = useRef(null);
    const [paymentAggregationLevel, setPaymentAggregationLevel] = React.useState('');
    const [paymentAggregationLevelArray, setPaymentAggregationLevelArray] = React.useState([]);
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const [enableFlexible, setEnableFlexible] = React.useState(false);
    //  var mode = localStorage.getItem('mode');
    var paymentValues = {
        payment_frequency: paymentFrequency,
        payment_level: paymentLevel,
        payment_terms: paymentTerms,
        payment_calendar: paymentCalendar,
        payment_method: paymentMethod,
        payment_partner_role: paymentPartnerRole,
        payment_partner: paymentPartner,
        external_reference: externalReference,
        flex_fields_5: flexFields5,
        payment_document_type: paymentDocumentType,
        payment_run: paymentRun,
        payment_aggregation_level: paymentAggregationLevel,
        payment_aggregation_level: paymentAggregationLevel,
        pass_through: passThrough,
        pass_through_percentage: parseInt(passThroughPercentage),
        pass_through_payment_partner_role: passThroughPaymentPartnerRole
    };
    useImperativeHandle(ref, () => ({
        validationCheck() {
            // if (mode === 'edit')
            //     props.getcontractdetails(localStorage.getItem('currentAddContractID'));
            if (!paymentFrequencyRef.current.value || paymentFrequencyRef.current.value === '')
                setPaymentFrequency(false);
            if (!paymentLevelRef.current.value || paymentLevelRef.current.value === '')
                setPaymentLevel(false);
            if (!paymentTermsRef.current.value || paymentTermsRef.current.value === '')
                setPaymentTerms(false);
            if (!paymentCalenderRef.current.value || paymentCalenderRef.current.value === '')
                setPaymentCalendar(false);
            if (!paymentMethodRef.current.value || paymentMethodRef.current.value === '')
                setPaymentMethod(false);
            if (!paymentPartnerRoleRef.current.value || paymentPartnerRoleRef.current.value === '')
                setPaymentPartnerRole(false);
            if (!paymentPartnerRef.current.value || paymentPartnerRef.current.value === '')
                setPaymentPartner(false);
        },
        getValues() {
            paymentValues = {
                payment_frequency: paymentFrequencyRef.current.value,
                payment_level: paymentLevelRef.current.value,
                payment_terms: paymentTermsRef.current.value,
                payment_calendar: paymentCalenderRef.current.value,
                payment_method: paymentMethodRef.current.value,
                payment_partner_role: paymentPartnerRoleRef.current.value,
                payment_partner: paymentPartnerRef.current.value
            };
            return paymentValues;
        }
    }));
    const [paymentDataListofFields, setPaymentDataListofFields] = React.useState(
        [

            'payment_level',
            'payment_frequency',
            'payment_calendar',
            'payment_method',
            'payment_terms',
            'payment_due_date',
            'external_reference',
            'flex_fields_5',
            'pass_through',
            'pass_through_percentage',
            'pass_through_payment_partner_role',
            'payment_document_type',
            'payment_run',
            // 'payment_partner',
        ]
    )
    const [mandatroyFields, setManddatoryFields] = React.useState([]);
    useEffect(() => {
        let tempArray = []
        if (props.fieldData && Object.values(props.fieldData) && Object.values(props.fieldData).length > 0) {
            Object.values(props.fieldData)
                .filter(item => paymentDataListofFields.includes(item.key))
                .map(item => {
                    if (item.mandatory) {
                        tempArray.push(item.key)
                    }
                })
        }
        setManddatoryFields(tempArray)
        props.onChange('', '', tempArray);
    }, [props.fieldData, paymentDataListofFields]);

    useEffect(() => {
        if (props.data && Object.entries(props.data).length > 0) {
            setFlexFields5(props.data.flex_fields_5)
            setPaymentDocumentType(props.data.payment_document_type)
            setPaymentRun(props.data.payment_run)
        }
    }, [props.data])
    function handlePaymentFrequency(e) {
        setPaymentFrequency(e.target.value);
        props.onChange('payment_frequency', e.target.value, mandatroyFields);
    }
    function handlePaymentLevel(newValue) {
        setPaymentLevel(newValue);
        // handlePaymentAggregatinLevel('');
        handlePaymentPartnerRole('');
        if (newValue === 'Aggregated Payment') {
            // if (!paymentDataListofFields.includes('payment_partner')) {
            setPaymentDataListofFields(prevFields => ['payment_partner_role', 'payment_partner', ...prevFields]);
            setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_aggregation_level'))

            setPaymentDataListofFields(prevFields => prevFields.filter(item => item !== 'payment_aggregation_level'));
            // }
        }

        if (newValue === 'Individual Payment') {
            // if (!paymentDataListofFields.includes('payment_partner_role')) {
            setPaymentDataListofFields(prevFields => ['payment_partner_role', ...prevFields]);
            setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_aggregation_level'))
            setPaymentDataListofFields(prevFields => prevFields.filter(item => !['payment_partner', 'payment_aggregation_level'].includes(item)));
            // }
        }

        if (newValue === 'Flexible Payment') {
            setManddatoryFields(mandatroyFields && mandatroyFields.filter(item => item != 'payment_partner_role'))
            setPaymentDataListofFields(prevFields => prevFields.filter(item => !['payment_partner', 'payment_partner_role'].includes(item)));
        }
        props.onChange('payment_level', newValue, mandatroyFields);
    }
    function handlePaymentTerms(newValue) {
        setPaymentTerms(newValue);
        props.onChange('payment_terms', newValue, mandatroyFields);
    }
    function handlePaymentCalendar(newValue) {
        setPaymentCalendar(newValue);
        props.onChange('payment_calendar', newValue, mandatroyFields);
    }
    function handlePaymentMethod(newValue) {
        setPaymentMethod(newValue);
        props.onChange('payment_method', newValue, mandatroyFields);
    }
    function handlePaymentPartnerRole(newValue) {
        handlePaymentPartner('');

        setPaymentPartnerRole(newValue);
        props.onChange('payment_partner_role', newValue, mandatroyFields);
    }
    function handlePaymentPartner(e) {
        setPaymentPartner(e);
        props.onChange('payment_partner', e, mandatroyFields);
    }
    function handlePaymentPartnerRoleFlexiable(newValue) {
        // if (newValue === 'Supplier' && activeDropdown && activeDropdown['supplier_number']) {
        //     props.getSupplierMasterData();
        // } else if (newValue === 'Employee' && activeDropdown && activeDropdown['employee_number']) {
        //     props.getEmployeeData();
        // } else {
        //     if (activeDropdown && activeDropdown['customer_number'])
        //         props.getCustomerMasterData();
        // }

        setPaymentPartnerRole(newValue);
        setPaymentPartner('')
        props.onChange('payment_partner_role', newValue, mandatroyFields);
    }
    function handlePaymentPartnerFlexiable(e) {
        setPaymentPartner(e);
        props.onChange('payment_partner', e, mandatroyFields);
    }
    function handleExternalReference(newValue) {
        setExternalReference(newValue);
        props.onChange('external_reference', newValue, mandatroyFields);
    }
    function handlePaymentDueDate(newValue) {
        setPaymentDueDate(newValue);
        props.onChange('payment_due_date', newValue ? parseInt(newValue) : 0, mandatroyFields);
    }
    function handleFlexFields5(newValue) {
        setFlexFields5(newValue);
        props.onChange('flex_fields_5', newValue, mandatroyFields);
    }
    function handlePaymentDocumentType(newValue) {
        setPaymentDocumentType(newValue);
        props.onChange('payment_document_type', newValue, mandatroyFields);
    }
    function handlePaymentRun(newValue) {
        setPaymentRun(newValue);
        props.onChange('payment_run', newValue, mandatroyFields);
    }
    function handlePaymentAggregatinLevel(newValue) {
        setPaymentAggregationLevel(newValue);
        props.onChange('payment_aggregation_level', newValue, mandatroyFields);
    }
    function handlePassThrough(e) {
        setPassThrough(e);
        props.onChange('pass_through', e, mandatroyFields);
    }
    function handlePassThroughPercentage(e) {
        setPassThroughPercentage(e.target.value);
        props.onChange('pass_through_percentage', Number(e.target.value), mandatroyFields);
    }
    function handlePassThroughPaymentParterRole(e) {
        setPassThroughPaymentPartnerRole(e);
        props.onChange('pass_through_payment_partner_role', e, mandatroyFields);
    }
    useEffect(() => {
        if (props.fieldData?.payment_method?.drop_down_reference_value) {

            if (paymentPartnerRole == 'Supplier Number') {
                setPaymentMethodArray(props.fieldData['payment_method']['drop_down_reference_value'].filter(item => item.key == 'EFT' || item.key == 'Check'))
            }
            else if (paymentPartnerRole == 'Customer Number') {
                setPaymentMethodArray(props.fieldData['payment_method']['drop_down_reference_value'].filter(item => item.key.includes('Credit Memo')))
            }
            else {
                setPaymentMethodArray(props.fieldData['payment_method']['drop_down_reference_value'])
            }
        }
    }, [paymentPartnerRole, props.fieldData])
    // useEffect(() => {
    //     if (props.activeDropdown)
    //         setActiveDropdown(props.activeDropdown)
    // }, [props.activeDropdown])
    // useEffect(() => {
    //     if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
    //         var tempArray = []
    //         var tempNameArray = []

    //         var customerTempArray = []
    //         props.customerMasterData.records.map((item) => {
    //             tempArray.push(item.customer_number);
    //             tempNameArray.push(item.customer_name);
    //         })
    //         setCustomerNameArray(tempArray);
    //         setCustomerNameListArray(tempNameArray);
    //     }
    // }, [props.customerMasterData])
    // useEffect(() => {
    //     if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
    //         var tempArray = []
    //         var tempNameArray = []
    //         props.employeeMasterData.records.map((item) => {
    //             tempArray.push(item.employee_number);
    //             tempNameArray.push(item.employee_name);
    //         })
    //         setEmployerNameArray(tempArray);
    //         setEmployerNameListArray(tempNameArray);
    //     }
    // }, [props.employeeMasterData])


    // useEffect(() => {
    //     if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
    //         var tempArray = []
    //         var tempNameArray = []
    //         props.supplierMasterData.records.map((item) => {
    //             tempArray.push(item.supplier_number);
    //             tempNameArray.push(item.supplier_name);
    //         })
    //         setSupplierNameArray(tempArray);
    //         setSupplierNameListArray(tempNameArray);
    //     }
    // }, [props.supplierMasterData])


    return (
        <div
            className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-2rem' }}
                >
                    <CardHeader
                        title="PAYMENT"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1rem' }}>
                        <Grid container >
                            {props.fieldData && props.fieldData['payment_level'] &&

                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div>
                                        <Tooltip arrow placement="top" title={<h3 >Controls how payments are made to customers e.g., Aggregated payment is made for entire contract, Individual payments are made for each customer using same payment method and Flexible payments are made for different payment methods.</h3>}>
                                            <FormLabel style={{ marginTop: '1rem' }} classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['payment_level'] ? props.fieldData['payment_level']['mandatory'] : false}>
                                                {props.fieldData['payment_level']['current']}
                                            </FormLabel>
                                        </Tooltip>
                                        <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                            style={{ marginTop: '-0.9rem' }}
                                            //heading={props.fieldData['payment_level']['current']}
                                            // required={props.fieldData && props.fieldData['payment_level'] ? props.fieldData['payment_level']['mandatory'] : false}
                                            data={props.fieldData['payment_level']['drop_down_reference_value']} onChange={handlePaymentLevel} labelFormat={'desc'}
                                            placeholder={' '} twoline='true' error={typeof paymentLevel === 'boolean' && !paymentLevel ? true : false} ref={paymentLevelRef}
                                            prevalue={props.data ? props.data.payment_level : ''} />
                                    </div>


                                </Grid>

                            }
                            {props.fieldData && props.fieldData['payment_aggregation_level'] && !(paymentLevel === 'Aggregated Payment') && !(paymentLevel === 'Individual Payment') && !(paymentLevel === 'Flexible Payment') &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }} heading={props.fieldData['payment_aggregation_level']['current']}
                                        data={props.fieldData['payment_aggregation_level']['drop_down_reference_value']}
                                        required={props.fieldData && props.fieldData['payment_aggregation_level'] ? props.fieldData['payment_aggregation_level']['mandatory'] : false}
                                        onChange={handlePaymentAggregatinLevel} twoline="true" labelFormat={'desc'}
                                        prevalue={props.data ? props.data.payment_aggregation_level : ''}
                                    />
                                </Grid>
                            }
                            {/* {props.fieldData && props.fieldData['payment_partner_role'] && !(paymentLevel === 'Flexible Payment') &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['payment_partner_role']['current']} labelFormat={'desc'}
                                        data={props.fieldData['payment_partner_role']['drop_down_reference_value']}
                                        onChange={handlePaymentPartnerRole} required twoline="true" placeholder={' '}
                                        error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false}
                                        ref={paymentPartnerRoleRef} prevalue={props.data ? props.data.payment_partner_role : ''} />
                                </Grid>
                            } */}
                            {props.fieldData && props.fieldData['payment_partner_role'] && (paymentLevel === 'Aggregated Payment') &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['payment_partner_role'] ? props.fieldData['payment_partner_role']['mandatory'] : false}
                                        heading={props.fieldData['payment_partner_role']['current']} labelFormat={'desc'}
                                        data={props.fieldData['payment_partner_role']['drop_down_reference_value']}
                                        onChange={handlePaymentPartnerRole} twoline="true" placeholder={' '}
                                        error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false}
                                        ref={paymentPartnerRoleRef} prevalue={paymentPartnerRole} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_partner_role'] && (paymentLevel === 'Individual Payment') &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['payment_partner_role'] ? props.fieldData['payment_partner_role']['mandatory'] : false}
                                        heading={props.fieldData['payment_partner_role']['current']} labelFormat={'desc'}
                                        data={props.fieldData['payment_partner_role']['drop_down_reference_value']}
                                        onChange={handlePaymentPartnerRole} twoline="true" placeholder={' '}
                                        error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false}
                                        ref={paymentPartnerRoleRef} prevalue={paymentPartnerRole} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_partner_role'] && (paymentLevel === 'Flexible Payment') &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        // required={props.fieldData && props.fieldData['payment_partner_role'] ? props.fieldData['payment_partner_role']['mandatory'] : false}
                                        required={false}
                                        heading={props.fieldData['payment_partner_role']['current']} labelFormat={'desc'}
                                        data={props.fieldData['payment_partner_role']['drop_down_reference_value']}
                                        onChange={handlePaymentPartnerRoleFlexiable} twoline="true" placeholder={' '}
                                        error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false}
                                        ref={paymentPartnerRoleRef} prevalue={paymentPartnerRole} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_partner'] && (paymentLevel === 'Flexible Payment') &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 0, }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={false}>
                                        {props.fieldData['payment_partner']['current']}
                                    </FormLabel>
                                    <div style={{ marginTop: -10 }}>
                                        <SearchDropDownMasterDataNew id='payment_single'
                                            table={paymentPartnerRole == 'Supplier Number' ? 'supplier_master' : paymentPartnerRole == 'Employee Number' ? 'employee_master' : paymentPartnerRole == 'Customer Number' ? 'customer_master' : ''}
                                            keyName={paymentPartnerRole == 'Supplier Number' ? 'supplier_number' : paymentPartnerRole == 'Employee Number' ? 'employee_number' : paymentPartnerRole == 'Customer Number' ? 'customer_number' : ''}
                                            description={true}
                                            onChange={(value) => handlePaymentPartnerFlexiable(value)}
                                            prevalue={paymentPartner}
                                        /> </div>

                                </Grid>
                            }
                            {paymentPartnerRole && props.fieldData && props.fieldData['payment_partner'] && !(paymentLevel === 'Individual Payment') && !(paymentLevel === 'Flexible Payment') &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 0, }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['payment_partner'] ? props.fieldData['payment_partner']['mandatory'] : false}>
                                        {props.fieldData['payment_partner']['current']}
                                    </FormLabel>
                                    <div style={{ marginTop: -10 }}>
                                        <SearchDropDownMasterDataNew id='payment_single'
                                            table={paymentPartnerRole == 'Supplier Number' ? 'supplier_master' : paymentPartnerRole == 'Employee Number' ? 'employee_master' : 'customer_master'}
                                            keyName={paymentPartnerRole == 'Supplier Number' ? 'supplier_number' : paymentPartnerRole == 'Employee Number' ? 'employee_number' : 'customer_number'}
                                            description={true}
                                            onChange={(value) => handlePaymentPartner(value)}
                                            prevalue={paymentPartner}
                                        /> </div>

                                </Grid>
                            }


                            {props.fieldData && props.fieldData['payment_frequency'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '-0.3rem' }}
                                >
                                    <div style={{
                                        padding: '10px 0px 5px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['payment_frequency'] ? props.fieldData['payment_frequency']['mandatory'] : false}>
                                            {props.fieldData['payment_frequency']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={paymentFrequency}
                                            onChange={handlePaymentFrequency}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem>
                                            {props.fieldData['payment_frequency']['drop_down_reference_value'] && props.fieldData['payment_frequency']['drop_down_reference_value']
                                                .map((item) => {
                                                    return (
                                                        <MenuItem
                                                            disabled={item.key === 'Weekly' || item.key === 'Semi Monthly' || item.key === 'Quarterly' ? true : false}
                                                            value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_calendar'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['payment_calendar']['current']}
                                        required={props.fieldData && props.fieldData['payment_calendar'] ? props.fieldData['payment_calendar']['mandatory'] : false}
                                        data={props.fieldData['payment_calendar']['drop_down_values']}
                                        twoline='true' onChange={handlePaymentCalendar} ref={paymentCalenderRef} prevalue={props.data ? props.data.payment_calendar : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_method'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }} heading={props.fieldData['payment_method']['current']}
                                        required={props.fieldData && props.fieldData['payment_method'] ? props.fieldData['payment_method']['mandatory'] : false}
                                        data={paymentMethodArray} labelFormat={'desc'} onChange={handlePaymentMethod} placeholder={' '} twoline='true'
                                        error={typeof paymentMethod === 'boolean' && !paymentMethod ? true : false} ref={paymentMethodRef}
                                        prevalue={props.data ? props.data.payment_method : ''} />
                                </Grid>
                            }


                            {props.fieldData && props.fieldData['payment_terms'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '15px 0px 5px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel required={props.fieldData && props.fieldData['payment_terms'] ? props.fieldData['payment_terms']['mandatory'] : false}>
                                            {props.fieldData['payment_terms']['current']}
                                        </FormLabel>
                                        <div style={{ marginTop: -10 }}>
                                            <AllOtherAttributeMasterComponent attributeName={'payment_terms'}
                                                value={paymentTerms} onChange={(e) => handlePaymentTerms(e)}
                                                prevalue={props.data ? props.data.payment_terms : ''} withOutLabel={true} id={'payment_terms'}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_due_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['payment_due_date'] ? props.fieldData['payment_due_date']['mandatory'] : false}
                                        heading={props.fieldData['payment_due_date']['current']} twoline="true"
                                        onChange={handlePaymentDueDate} prevalue={props.data ? props.data.payment_due_date : ''}
                                        inputProps={{ min: 0 }}
                                        onKeyPress={(event) => {
                                            if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                            }
                                        }}
                                        type={'Number'}

                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_reference'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['external_reference'] ? props.fieldData['external_reference']['mandatory'] : false}
                                        heading={props.fieldData['external_reference']['current']} twoline="true"
                                        onChange={handleExternalReference} prevalue={props.data ? props.data.external_reference : ''}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_5'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_5']['drop_down_reference_value'] && props.fieldData['flex_fields_5']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['flex_fields_5'] ? props.fieldData['flex_fields_5']['mandatory'] : false}>
                                                {props.fieldData['flex_fields_5']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields5}
                                                onChange={(e) => handleFlexFields5(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_5']['drop_down_reference_value'] && props.fieldData['flex_fields_5']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_5']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            mandatory={props.fieldData && props.fieldData['flex_fields_5'] ? props.fieldData['flex_fields_5']['mandatory'] : false}
                                            heading={props.fieldData['flex_fields_5']['current']} twoline="true"
                                            onChange={handleFlexFields5} prevalue={props.data ? props.data.flex_fields_5 : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['pass_through'] ? props.fieldData['pass_through']['mandatory'] : false}
                                        prevalue={props.data ? props.data.pass_through : ''}
                                        heading={props.fieldData['pass_through']['current']} labelFormat={'desc'}
                                        data={props.fieldData['pass_through']['drop_down_reference_value']} onChange={handlePassThrough} twoline="true" placeholder={' '} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through_percentage'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['pass_through_percentage'] ? props.fieldData['pass_through_percentage']['mandatory'] : false}>
                                        {props.fieldData['pass_through_percentage']['current']}
                                    </FormLabel>
                                    <OutlinedInput
                                        type={'number'}
                                        classes={{ root: classes.inputTwoLineOutlined }}
                                        onChange={handlePassThroughPercentage}
                                        value={passThroughPercentage}
                                        inputProps={{ min: 1 }}
                                        onKeyPress={(event) => {
                                            if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through_payment_partner_role'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['pass_through_payment_partner_role'] ? props.fieldData['pass_through_payment_partner_role']['mandatory'] : false}
                                        prevalue={props.data ? props.data.pass_through_payment_partner_role : ''}
                                        heading={props.fieldData['pass_through_payment_partner_role']['current']} labelFormat={'desc'}
                                        data={props.fieldData['pass_through_payment_partner_role']['drop_down_reference_value']}
                                        onChange={handlePassThroughPaymentParterRole} twoline="true" placeholder={' '} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_document_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['payment_document_type']['drop_down_reference_value'] && props.fieldData['payment_document_type']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['payment_document_type'] ? props.fieldData['payment_document_type']['mandatory'] : false}>
                                                {props.fieldData['payment_document_type']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={paymentDocumentType}
                                                onChange={(e) => handlePaymentDocumentType(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['payment_document_type']['drop_down_reference_value'] && props.fieldData['payment_document_type']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['payment_document_type']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            mandatory={props.fieldData && props.fieldData['payment_document_type'] ? props.fieldData['payment_document_type']['mandatory'] : false}
                                            heading={props.fieldData['payment_document_type']['current']} twoline="true"
                                            onChange={handlePaymentDocumentType} prevalue={props.data ? props.data.payment_document_type : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_run'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['payment_run']['drop_down_reference_value'] && props.fieldData['payment_run']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['payment_run'] ? props.fieldData['payment_run']['mandatory'] : false}>
                                                {props.fieldData['payment_run']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={paymentRun}
                                                onChange={(e) => handlePaymentRun(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['payment_run']['drop_down_reference_value'] && props.fieldData['payment_run']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['payment_run']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            mandatory={props.fieldData && props.fieldData['payment_run'] ? props.fieldData['payment_run']['mandatory'] : false}
                                            heading={props.fieldData['payment_run']['current']} twoline="true"
                                            onChange={handlePaymentRun} prevalue={props.data ? props.data.payment_run : ''} />
                                    }
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});



const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
    }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(Payment);