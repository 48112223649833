import React, { useEffect } from 'react';
import {
    Grid,
    Breadcrumbs,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../components/Inputs';
import { connect } from 'react-redux';
import { getEmployeeMasterViewDetails } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '20px 10px 0px 10px',
        backgroundColor: 'white',
        marginTop: 10
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
}));
const ViewEmployee = props => {
    const classes = useStyles();
    const { className } = props;
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getEmployeeMasterViewDetails(appId);
    }, []);
    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/employee'
                >
                    Employee Master
                </Link>
                <Typography color="textPrimary" variant='h1'>View Employee</Typography>
            </Breadcrumbs>
            {props.materialMasterData &&
                <div className={classes.container}>
                    <Grid container className={classes.gridContainer}>
                        {props.materialMasterData.employee_number &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Emplyee Number' data={props.materialMasterData.employee_number} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.employee_name &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Employee Name' data={props.materialMasterData.employee_name} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.employee_type &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Employee Type' data={props.materialMasterData.employee_type} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.department &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Department' data={props.materialMasterData.department} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.street_address &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Street Address' data={props.materialMasterData.street_address} twoline='true' />
                            </Grid>
                        }
                         {props.materialMasterData.country &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Country' data={props.materialMasterData.country} twoline='true' />
                            </Grid>
                        }
                         {props.materialMasterData.region &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='State' data={props.materialMasterData.region} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.city &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='City' data={props.materialMasterData.city} twoline='true' />
                            </Grid>
                        }
                       
                        {props.materialMasterData.postal_code &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Postal Code' data={props.materialMasterData.postal_code} twoline='true' />
                            </Grid>
                        }
                       
                        {props.materialMasterData.sales_office &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Sales Office' data={props.materialMasterData.sales_office} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.territory_name &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Territory Name' data={props.materialMasterData.territory_name} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.territory_id &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Territory ID' data={props.materialMasterData.territory_id} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.flex_attribute1 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Flex Attribute 1' data={props.materialMasterData.flex_attribute1} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.flex_attribute2 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Flex Attribute 2' data={props.materialMasterData.flex_attribute2} twoline='true' />
                            </Grid>
                        }
                        {props.materialMasterData.base_salary && props.materialMasterData.base_salary!=0
                         ?
                         <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}} heading='Base Salary' data={props.materialMasterData.base_salary} twoline='true' />
                            </Grid> 
                        :" "
                        }
                        {props.materialMasterData.employee_status &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{root:classes.formLabel}}  heading='Active' data={props.materialMasterData.employee_status.charAt(0).toUpperCase()+ props.materialMasterData.employee_status.substr(1)} twoline='true' />
                            </Grid>
                        }
                    </Grid>
                </div>
            }
        </div>
    );

}
const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.employeeMasterViewData
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getEmployeeMasterViewDetails: (id) => dispatch(getEmployeeMasterViewDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEmployee);