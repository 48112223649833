import React from 'react';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from "@mui/styles";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import NotificationsIcon from '@mui/icons-material/Notifications';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: theme.palette.white
    },
    flexGrow: {
        flexGrow: 1
    },
    badge: {
        height: 7,
        minWidth: 7,
        borderRadius: 7,
        borderWidth: 0.5,
        border: '0.5px solid white'
    },
    notification: {
        color: theme.palette.notification.grey,
        fontSize: 27
    },
    profileImage: {
        marginRight: 6,
        marginLeft: 21
    },
    toolbar: {
        minHeight: 80
    },
    toolbarButtons: {
        paddingRight: 16
    },
    circle: {
        top: '26%',
        right: '34%'
    }
}));

const Header = props => {
    const { className, onSidebarOpen, ...rest } = props;
    const classes = useStyles();

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton onClick={onSidebarOpen} color='primary' size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <div className={classes.flexGrow} />
                <Badge
                    badgeContent={2}
                    color="error"
                    overlap="circular"
                    variant="dot"
                    classes={{
                        dot: classes.badge,
                        anchorOriginTopRightCircular: classes.circle
                    }}
                >
                    <NotificationsIcon className={classes.notification} />
                </Badge>
                <div className={classes.profileImage}>
                    <img src={require('../../../../library/images/Profile.jpg')} alt='' />
                </div>
                <div className={classes.toolbarButtons}>
                    <img src={require('../../../../library/images/Dropdown.png')} alt='' />
                </div>
            </Toolbar>
        </AppBar >
    );

};

Header.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
}

export default Header;