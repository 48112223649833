import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput, FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getEDIMappings, createEDIMappings } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { DropdownArray } from '../../components/Inputs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const EdiMappingAdd = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [supplierNumberpopup, setSupplierNumberpopup] = React.useState('');
    const [formatType, setFormatType] = React.useState('')
    const [sortedData, setSortedData] = React.useState([]);
    const formatTypeArray = [
        '844', '845', '849'
    ];
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const handleSupplierNumber = (e) => {
        setSupplierNumberpopup(e.target.value);
    }
    function handleFormatType(newValue) {
        setFormatType(newValue);
    };

    useEffect(() => {
        setOpen(true);
        if (formatType)
            props.getEDIMappings(formatType);
        setSortedData([]);
    }, [formatType]);

    console.log(props.ediSegmentData)
    useEffect(() => {
        var tempObj = []
        if (props.ediSegmentData) {
            Object.values(props.ediSegmentData).map(e => {
                tempObj.push(e)
            })
            setSortedData(tempObj);
        }
    }, [props.ediSegmentData])

    const clearData = () => {
        setSortedData([]);
    };

    function handleOnSubmit() {
        var data = []
        sortedData.map((item, i) => {
            const { CreatedAt, ID, UpdatedAt, ...filteredItem } = sortedData[i];
            const newItem = { ...filteredItem, 'edi_map_name': queryName, 'supplier_number': supplierNumberpopup, 'edi_map': formatType };
            data.push(newItem);
        })
        console.log(data)
        props.onSubmit(data);
        clearData();
    }
    function handleCancelDialog() {
        history.push('/edi-mappings');
    }
    const handleSourceFieldName = (event, index, type) => {
        sortedData[index][type] = (event.target.value).toString();
    };
    const handleDefaultValue = (event, index, type) => {
        sortedData[index][type] = (event.target.value).toString();
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/edi-mappings'
                >
                    Edi Mappings
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        EDI Map Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                    <FormLabel className={classes.formLabel} required >
                        EDI Map
                    </FormLabel>
                    <DropdownArray
                        data={formatTypeArray}
                        placeholder='Please select Map type'
                        twoline='true'
                        onChange={handleFormatType} />
                    <FormLabel className={classes.formLabel} >
                        Supplier Number
                    </FormLabel>
                    <OutlinedInput
                        value={supplierNumberpopup}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleSupplierNumber} />
                </div>

                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName && formatType ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div>
                                <div style={{ margin: 15 }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell align='center' className={classes.tabHead}>Segment ID</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Segment Name</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Property</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Repeat</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Loop Start</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Loop End</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Ref Des</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Field Sequence</TableCell>
                                                    <TableCell align='center' className={classes.tabHead}>Field Name</TableCell>
                                                    <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Source Field Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {sortedData && sortedData
                                                    .map((itemX, index) => {
                                                        return (
                                                            <TableRow >
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['segment_id']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['segment_name']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['property']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['repeat']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['loop_start']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['loop_end']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['ref_des']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['field_sequence']}</TableCell>
                                                                <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{sortedData[index]['field_name']}</TableCell>
                                                                <TableCell align='center' >
                                                                    <OutlinedInput twoline='true'
                                                                        key={'priority-' + index}
                                                                        type={'string'}
                                                                        id={index}
                                                                        onChange={(e) => handleDefaultValue(e, index, 'default_value')}
                                                                        defaultValue={sortedData[index]['default_value']}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align='center' >
                                                                    <OutlinedInput twoline='true'
                                                                        // key={'priority-' + index}
                                                                        type={'string'}
                                                                        id={index}
                                                                        onChange={(e) => handleSourceFieldName(e, index, 'source_field_name')}
                                                                        defaultValue={sortedData[index]['source_field_name']}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    {/* <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button> */}
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createEDIMappings(data)),
        getEDIMappings: (formatType) => dispatch(getEDIMappings(formatType)),
    }
}

const mapStateToProps = state => {
    return {
        ediSegmentData: state.pricingData.edimappingData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EdiMappingAdd);