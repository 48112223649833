import React from 'react';
import { ExceptionSetupTable } from './components';

const ExceptionSetup = props => {

    return (
        <div>
            <ExceptionSetupTable />
        </div>
    );
};

export default ExceptionSetup;