import React from 'react';
import { Accrual, Payment, AdditionalData } from './containers'
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    
}));
const FinancialPostings = props => {
    const classes = useStyles();
    const { className } = props;
    return (
        <div className={clsx(classes.root, className)}>
            {(props.contractData && props.contractData.posting_schema != 'Payment Only' && props.contractData.posting_schema != 'No Postings') &&
                <Accrual data={props.contractData} fieldData={props.fieldData}/>
            }
            {(props.contractData && props.contractData.posting_schema != 'Accrual Only' && props.contractData.posting_schema != 'No Postings') &&
                <Payment data={props.contractData} fieldData={props.fieldData}/>
            }
            {(props.contractData && props.contractData.posting_schema != 'No Postings') &&
                <AdditionalData data={props.contractData} fieldData={props.fieldData}/>
            }
        </div>
    );

}

const mapStateToProps = state => {
    return {
        //financialData: state.addContractData.contractGeneralData
    }
}

export default connect(mapStateToProps)(FinancialPostings);