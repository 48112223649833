import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Typography, IconButton, Paper, Button,
    AccordionActions
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        zIndex: 1000,
        width: 330
    },
    dropdownContainer: {
        maxHeight: '350px', // Set maximum height
        overflowY: 'auto', // Enable vertical scrolling
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,
        backgroundColor: 'white'
    },
    accordionMenu: {
        padding: 10,
        maxHeight: 30,
        margin: 0,
        // borderBottom: "0.8px solid #E2E2E2",
    },

    accordionHead: {
        padding: 0,
        minHeight: 30,
        margin: " 0px !important",
        width: 'fit-content'
        // backgroundColor: 'transparent'
        //  borderBottom: "0.8px solid #E2E2E2",
    },
    accordionAction: {
        backgroundColor: 'white',
        border: "0.8px solid #E2E2E2",
        margin: '0px 5px'
    },
    accordionRoot: {
        backgroundColor: 'transparent',
        margin: 0,

    },
    muiExpand: {
        minHeight: "30px !important",
        margin: '0px !important'
    }
}));

const SingleSelectMultiLevel = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState([]);
    const [checkedData, setCheckedData] = useState('');

    useEffect(() => {
        setExpanded(props.expand)
    }, [props.expand])
    useEffect(() => {
        setCheckedData(props.prevalue ? props.prevalue : '')
    }, [props.prevalue])

    const handleCheckboxChange = (item) => (event) => {
        event.stopPropagation(); // Stop event propagation
        setCheckedData(item.scenario_name)
        setExpanded([])
        if (event.target.checked)
            props.onSubmit(item.scenario_name, item.scenario_id)
        else
            props.onSubmit()


    };


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? [...expanded, panel] : expanded.filter(item => item != panel));
    };

    const renderNestedItems = (items) => {
        return items && items.map((item, index) => (
            <div key={index}>
                {item.scenarios && item.scenarios.length > 0 ? (
                    <Accordion
                        expanded={expanded.includes(item.product_line)}
                        onChange={handleAccordionChange(item.product_line)}
                        style={{
                            margin: 0, borderBottom: (item.type == 'product_line')
                                && "0.8px solid #E2E2E2"
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            className={classes.accordionMenu}
                            style={{ padding: item.type == 'material_group3' || item.type == 'state' ? 20 : 10 }}
                        >
                            <Typography variant='h4' color='black'
                            >

                                {item.product_line}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}
                        >
                            {renderNestedItems(item.scenarios)}
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <div style={{ display: 'flex', paddingLeft: 5 }}>
                        <Typography variant='h4' color='black'>
                            {' '}
                            <Checkbox
                                checked={checkedData == item.scenario_name ? true : false
                                }
                                onChange={handleCheckboxChange(item)}
                            />
                            {item.scenario_name}
                        </Typography>
                    </div>
                )}
            </div>
        ));
    };


    return (
        <div className={classes.root}>
            {expanded && expanded.length > 0 &&
                <Accordion
                    id={props.id}
                    expanded={expanded.includes(props.type)}
                    className={classes.accordionRoot}
                >
                    <AccordionSummary
                        //expandIcon={<ExpandMore />}
                        className={classes.accordionHead}
                        classes={{
                            expanded: classes.muiExpand,
                        }}
                        style={{ display: 'none' }}
                    >
                        <Typography variant='h4' color='grey'>
                            {props.head}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 5 }}>
                        <div className={classes.dropdownContainer}>
                            {renderNestedItems(props.data)}
                        </div>
                    </AccordionDetails>
                </Accordion>
            }
        </div>
    );
};

export default SingleSelectMultiLevel;
