import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { ContractCell } from '../ContractCell';
import { Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import {
    getDashboardCount, promPlanSalsIncentives, getPricingMarginRevenueData, pricingDashboardMargin
} from '../../../../redux/actions';
import GeographicalChartProfit from './GeographicalChartProfit';
import SyncFusionChart from '../../../Analytics/SyncFusion/SyncFusionChart';
import SalesIncentivesProfit from './SalesIncentivesProfit';
import SyncFusionProfitChart from './SyncFusionProfitChart';
// import SyncFusionColumnChart from '../../../Analytics/SyncFusion/SyncFusionColumnChart';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 16,
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
        },
    },
    gridContainer: {
        boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
        paddingTop: 10,
    },
    formLabel: {
        width: '100%',
        paddingLeft: 20,
        paddingTop: 10,
        fontSize: theme.typography.h1.fontSize,
        display: 'flex',
        justifyContent: 'center',

    },
    activeContracts: {
        backgroundColor: '#38d138'
    },
    expiredContracts: {
        backgroundColor: '#d43333'
    },
    profitability: {
        backgroundColor: '#b37109'
    },
    totalContracts: {
        backgroundColor: theme.palette.primary.main
    },
    waitingApprovals: {
        backgroundColor: '#FFBC03'
    },
    newContracts: {
        backgroundColor: '#7843DF'
    },
    contractCellContainer: {
        [theme.breakpoints.down('lg')]: {
            padding: '0px 16px 18px 16px !important'
        }
    }
}));
const DBPromoPlan = props => {
    const classes = useStyles();
    const [historicalDataMaterial, setHistoricalDataMaterial] = React.useState([]);
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [allowedTilesFilter1, setAllowedTilesFilter1] = React.useState([]);
    const [allowedTilesFilter2, setAllowedTilesFilter2] = React.useState([]);
    const [allowedTilesFilter3, setAllowedTilesFilter3] = React.useState([]);
    // useEffect(() => {
    //     props.allowedDashBoardApps(sessionStorage.getItem("application"));
    // }, []);
    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Profit Optimization Stats" && item.dashboard_name === "Default Dashboard"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
            }
        }
    }, [props.allowedDashBoardData])
    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Margin Trending" && item.dashboard_name === "Default Dashboard"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter1([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter1([]);
            }
        }
    }, [props.allowedDashBoardData])
    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Margin/Revenue Contribution" && item.dashboard_name === "Default Dashboard"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter2([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter2([]);
            }
        }
    }, [props.allowedDashBoardData])
    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Margin-Actual/Target/Forecast" && item.dashboard_name === "Default Dashboard"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter3([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter3([]);
            }
        }
    }, [props.allowedDashBoardData])

    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            props.loadDashboardCount(Moment(allowedTilesFilter[0].start_date).format('MM/DD/YYYY'), Moment(allowedTilesFilter[0].end_date).format('MM/DD/YYYY'), allowedTilesFilter[0].formula);
        }
    }, [allowedTilesFilter]);
    useEffect(() => {
        if (allowedTilesFilter1 && allowedTilesFilter1.length > 0 && allowedTilesFilter1[0].periodicity) {
            var formData = {
                "start_date": Moment(allowedTilesFilter1[0].start_date).format('MM/DD/YYYY'),
                "end_date": Moment(allowedTilesFilter1[0].end_date).format('MM/DD/YYYY'),
                "periodicity": convertToSnakeCase(allowedTilesFilter1[0].periodicity),
                "formula": allowedTilesFilter1[0].formula
            }
            props.promPlanSalsIncentives(formData);
        }
    }, [allowedTilesFilter1]);
    useEffect(() => {
        if (allowedTilesFilter2 && allowedTilesFilter2.length > 0) {
            props.pricingRevenueMargin(Moment(allowedTilesFilter2[0].start_date).format('MM/DD/YYYY'), Moment(allowedTilesFilter2[0].end_date).format('MM/DD/YYYY'), (allowedTilesFilter2[0].attribute_name), sessionStorage.getItem("application"));
        }
    }, [allowedTilesFilter2]);

    useEffect(() => {
        if (allowedTilesFilter3 && allowedTilesFilter3.length > 0 && allowedTilesFilter3[0].periodicity && allowedTilesFilter3[0].key_figure_name) {
            var data2 = {
                customer_number: [],
                material_number: [],
                historical_start_date: allowedTilesFilter3[0].historical_start_date,
                historical_end_date: allowedTilesFilter3[0].historical_end_date,
                forecast_start_date: allowedTilesFilter3[0].forecast_start_date,
                forecast_end_date: allowedTilesFilter3[0].forecast_end_date,
                periodicity: convertToSnakeCase(allowedTilesFilter3[0].periodicity),
                key_figure_name: [allowedTilesFilter3[0].key_figure_name[0]],
                simulation_category: allowedTilesFilter3[0].simulation_category,
                limit: 10,
            };
            props.pricingDashboardMarginAPI("profit", data2);
        }
    }, [allowedTilesFilter3]);
    // console.log("a", allowedTilesFilter3)
    function convertToSnakeCase(string) {
        return string.replace(/\s+/g, '_').toLowerCase();
    }
    useEffect(() => {
        var application = sessionStorage.getItem('application');
        var ed = Moment().subtract(0, 'months').endOf('month').format('MM/DD/YYYY');
        var sd = Moment().subtract(5, 'months').startOf('month').format('MM/DD/YYYY');
        // props.loadDashboardCount("12/01/2023", "12/31/2023");
        // var formData = {
        //     "application_type": 'Customer Rebate',
        //     "data_source_type": 'Direct - Sales Data',
        //     "posting_type": 'payment',
        //     "start_date": sd,
        //     "end_date": ed,
        //     "periodicity": 'month',
        //     "key_figure_name": 'net_value',
        // }
        // props.promPlanSalsIncentives(formData);
        // var edp = Moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
        // var sdp = Moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
        // props.pricingRevenueMargin(sdp, edp, 'customer_number', sessionStorage.getItem("application"));
        // var data2 = {
        //     customer_number: [],
        //     material_number: [],
        //     historical_start_date: Moment().subtract(1, "months").startOf("month"),
        //     historical_end_date: Moment().subtract(1, "months").endOf("month"),
        //     forecast_start_date: Moment().startOf("month"),
        //     forecast_end_date: Moment().endOf("month"),
        //     periodicity: "cumulative",
        //     key_figure_name: ["material_number"],
        //     simulation_category: "Historical - Actual",
        //     limit: 10,
        // };
        // props.pricingDashboardMarginAPI("profit", data2);
    }, []);
    useEffect(() => {
        if (
            props.pricingDashboardMaterial &&
            props.pricingDashboardMaterial.length > 0
        ) {
            setHistoricalDataMaterial(
                props.pricingDashboardMaterial
            );
        }
    }, [props.pricingDashboardMaterial]);
    return (
        <div style={{ height: sessionStorage.getItem('application') !== 'Promotions' ? '100%' : 'auto' }}>
            <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.activeContracts} heading={'Revenue'} value={props.dashboardCountData && props.dashboardCountData.revenue ? parseFloat(props.dashboardCountData.revenue) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.waitingApprovals} heading={'On-Invoice Adjustments'} value={props.dashboardCountData && props.dashboardCountData.on_invoice_adjustments ? parseFloat(props.dashboardCountData.on_invoice_adjustments) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.newContracts} heading={'Off-Invoice Adjustments'} value={props.dashboardCountData && props.dashboardCountData.off_invoice_adjustments ? parseFloat(props.dashboardCountData.off_invoice_adjustments) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.totalContracts} heading={'Total Cost'} value={props.dashboardCountData && props.dashboardCountData.total_cost ? parseFloat(props.dashboardCountData.total_cost) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.expiredContracts} heading={'Margin'} value={props.dashboardCountData && props.dashboardCountData.margin ? parseFloat(props.dashboardCountData.margin) : 0} />
                </div>
                <div style={{ flex: 0.2, padding: 4 }}
                    classes={{ root: classes.contractCellContainer }}
                >
                    <ContractCell className={classes.profitability} heading={'Profitability'} value={props.dashboardCountData && props.dashboardCountData.profitability ? parseFloat(props.dashboardCountData.profitability) : 0} />
                </div>
            </div>
            {sessionStorage.getItem('application') !== 'Promotions' &&
                <>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            lg={6}
                            sm={12}
                            xl={6}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <SalesIncentivesProfit data={props.promPlanningSalesInc} margin={"m"} />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            sm={12}
                            xl={6}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <Typography color="primary" className={classes.formLabel}>
                                Revenue-Margin Contributions
                            </Typography>
                            <SyncFusionProfitChart id={'chart-customer'} loading={props.loading} data={props.pricingData} analysisLevel={'customer_number'} />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            sm={12}
                            xl={6}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <GeographicalChartProfit />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            sm={12}
                            xl={6}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <Typography color="primary" style={{ marginBottom: "10px" }} className={classes.formLabel}>
                                {" "}
                                Products with Highest Margin %
                            </Typography>
                            <SyncFusionChart
                                id="product"
                                data={historicalDataMaterial}
                                analysisLevel={"material_description"}
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </div >
    );

};

const mapStateToProps = state => {
    return {
        dashboardCountData: state.initialData.dashboardCountData,
        promPlanningSalesInc: state.initialData.promPlanningSalesInc,
        pricingData: state.initialData.pricingRevenueMarginData,
        pricingDashboardMaterial: state.initialData.pricingDashboardMaterial,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadDashboardCount: (sd, ed, formula) => dispatch(getDashboardCount(sd, ed, 'Profit Optimization', formula)),
        promPlanSalsIncentives: (data) => dispatch(promPlanSalsIncentives(data)),
        pricingRevenueMargin: (startDate, endDate, analysislevel, applicationType) => dispatch(getPricingMarginRevenueData(startDate, endDate, analysislevel, applicationType)),
        pricingDashboardMarginAPI: (id, data) => dispatch(pricingDashboardMargin(id, data, 'Profit Optimization')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DBPromoPlan);