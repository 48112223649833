import React from 'react';
import { connect } from 'react-redux';
import { MembershipContractGroupUserTable } from './components';
const MembershipContractGroup = (props) => {
    return (
        <div>
            <MembershipContractGroupUserTable/>
        </div >
    );
};
export default connect(null, null)(MembershipContractGroup);