import React, { useEffect } from 'react';
import {
    Typography, Paper, IconButton,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CircularProgress
} from '@mui/material';
import { NavLink, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getMasterDataDashboard, getMasterDataDashboardChanges, getMasterDataByOperation, getMasterDataDashboardChart } from '../../redux/actions';
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import GridLoader from "react-spinners/GridLoader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ContractCell } from '../Dashboard/components/ContractCell';
import { tableIcons } from '../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import clsx from 'clsx';
import BarLineGraph from '../Dashboard/components/BarLineGraph';
import { SearchSharp } from '@mui/icons-material';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 100,
        width: 220,
        justifyContent: 'center',
        "&:hover": {
            transform: 'scale(1.1)',
            transition: '0.6s',
            cursor: 'pointer',
            animationDuration: '4s',
            animationDelay: '2s'
        },
        color: 'black',
        margin: '8px 16px 8px 8px',
        borderRadius: 9,
        backgroundColor: 'white'

    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
    mgBottom10: {
        marginBottom: 10,
        color: '#A9B3E4'
    },
    h4: {
        marginTop: 30,
        paddingLeft: 15
    },
    tabStyle: {
        backgroundColor: theme.palette.appLauncher.tabStyle,
        padding: 12,
        borderRadius: '10px 10px 0 0',
        color: theme.palette.appLauncher.tabStyleFont,
        width: 170,
        fontSize: '0.76rem'
    },
    tabContainer: {
        borderRadius: '8px 8px 8px 8px',
        marginTop: 0,
    },
    button: {
        position: 'absolute',
        top: 10, right: 10,
        backgroundColor: theme.palette.primary.main
    },
    switchTheme: {
        color: 'black'
    },
    tabBackground: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '0px 20px 20px 20px',
        padding: 15,
        height: 670,
        marginLeft: -31
    },
    navLink: {
        textDecoration: "none"
    },
    logoContainer: {
        justifyContent: "center",
        display: "flex",
    },
    logo: {
        [theme.breakpoints.down('xl')]: {
            width: window.screen.width * 0.5,
        },
        [theme.breakpoints.up("md")]: {
            width: window.screen.width * 0.2,
        },
    },
    bgColor: {
        backgroundColor: theme.palette.primary.main
    },
    customerMaster: {
        backgroundColor: "#38d138",
    },
    materialMaster: {
        backgroundColor: "#d43333",
    },
    supplierMaster: {
        backgroundColor: "#8F00FF",
    },
    membership: {
        backgroundColor: "#D2691E",
    },
    salesBundle: {
        backgroundColor: "#8F004C",
    },
    employeeMaster: {
        backgroundColor: "#787C82",
    },
    productGroup: {
        backgroundColor: "#94544F",
    },
    customerXREF: {
        backgroundColor: "#1928A4",
    },
    //   contractXREF: {
    //     backgroundColor: "#8F00FF",
    //   },
    materailXREF: {
        backgroundColor: "#1A9F79",
    },
    contractXREF: {
        backgroundColor: "#A79279",
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    container: {
        marginTop: 16,
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
        },
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    gridContainer: {
        boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
        paddingTop: 10,
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);
function DefaultPage(props) {
    const theme = createTheme();
    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [color, setColor] = React.useState('');
    const [dataRows, setDataRows] = React.useState([]);
    const [masterDataCount, setMasterDataCount] = React.useState([]);
    const [flag, setFlag] = React.useState(true);
    const [flag1, setFlag1] = React.useState(true);
    const [masterDataTransactionCount, setMasterDataTransactionCount] = React.useState([]);
    const chartData = [
        { x: 'Food', y: 90, y1: 40, y2: 70, y3: 120 }, { x: 'Transport', y: 80, y1: 90, y2: 110, y3: 70 }, { x: 'Medical', y: 50, y1: 80, y2: 120, y3: 50 },
        { x: 'Clothes', y: 70, y1: 30, y2: 60, y3: 180 }, { x: 'Personal Care', y: 30, y1: 80, y2: 80, y3: 30 }, { x: 'Books', y: 10, y1: 40, y2: 30, y3: 270 },
        { x: 'Fitness', y: 100, y1: 30, y2: 70, y3: 40 }, { x: 'Electricity', y: 55, y1: 95, y2: 55, y3: 75 }, { x: 'Tax', y: 20, y1: 50, y2: 40, y3: 65 },
        { x: 'Pet Care', y: 40, y1: 20, y2: 80, y3: 95 }, { x: 'Education', y: 45, y1: 15, y2: 45, y3: 195 }, { x: 'Entertainment', y: 75, y1: 45, y2: 65, y3: 115 }
    ];
    useEffect(() => {
        var tempRows = [];
        if (props.masterDataDashboardChanges && props.masterDataDashboardChanges.length > 0)
            props.masterDataDashboardChanges
                .map(e => {
                    tempRows.push({
                        app_functionality: e.app_functionality,
                        removed_customers: e.removed_customers,
                        added_customers: e.added_customers,
                        updated_customers: e.updated_customers,
                    });
                })
        setDataRows(tempRows);
    }, [props.masterDataDashboardChanges, props.masterDataDashboard]);

    useEffect(() => {
        if (props.masterDataDashboard && Object.keys(props.masterDataDashboard).length > 0) {
            setMasterDataCount(props.masterDataDashboard);
            setFlag(false);
        }
        if (props.masterDataDashboard && props.masterDataDashboard?.title == "unauthorized") {
            setFlag(true);

        }
    }, [props.masterDataDashboard]);


    useEffect(() => {
        if (props.masterDataTransactionDashboard && Object.keys(props.masterDataTransactionDashboard).length > 0) {
            setMasterDataTransactionCount(props.masterDataTransactionDashboard);
            setFlag1(false);
        }
        if (props.masterDataTransactionDashboard && props.masterDataTransactionDashboard?.title == "unauthorized") {
            setFlag1(true);
        }
    }, [props.masterDataTransactionDashboard]);


    useEffect(() => {
        if (sessionStorage.getItem('application') === 'Master Data') {
            props.getMasterDataDashboardChanges(sessionStorage.getItem('application'));
            props.getMasterDataDashboardChart(sessionStorage.getItem('application'));
        }
        if (sessionStorage.getItem('application') === 'Master Data' /*|| sessionStorage.getItem('application') === 'Transaction Data'*/) {
            props.getMasterDataDashboard(sessionStorage.getItem('application'))
        }

    }, [])
    useEffect(() => {
        if (props.profileData && Object.keys(props.profileData).length > 0) {
            setColor(props.profileData.color_theme)
        }
    }, [props.profileData]);
    function handleItem(data, type) {
        props.getMasterDataByOperation(data, type);
    }
    function handleActions(data) {
        sessionStorage.setItem('action-data', data)
        history.push({
            pathname: "master-data-filters",
            type: data
        })
    }
    const handleApplication = (type) => {
        if (type == "customer_master") {
            history.push('/customer-master/search-customer')
        }
        if (type == "material_master") {
            history.push('/material-master/search-material')
        }
        if (type == "supplier_master") {
            history.push('/supplier-master/search-supplier')
        }
        if (type == "bom") {
            history.push('/bom')
        }
        if (type == "employee_master") {
            history.push('/employee')
        }
        if (type == "product_group") {
            history.push('/product-group')
        }
        if (type == "customer_xref") {
            history.push('/customer-x-master')
        }
        if (type == "material_xref") {
            history.push('/material-x-master')
        }
        if (type == "contract_xref") {
            history.push('/contract-xref')
        }
        if (type == "membership_master") {
            history.push('/membership')
        }
    }
    const handleContractCell = (type) => {
        if (type == "Customer_Master") {
            history.push('/customer-master')
        }
        if (type == "Material_Master") {
            history.push('/material-master')
        }
        if (type == "Supplier_Master") {
            history.push('/supplier-master')
        }
        if (type == "Sales_Bundle") {
            history.push('/bom')
        }
        if (type == "Employee_Master") {
            history.push('/employee')
        }
        if (type == "Product_Group") {
            history.push('/product-group')
        }
        if (type == "Customer_XREF") {
            history.push('/customer-x-master')
        }
        if (type == "Material_XREF") {
            history.push('/material-x-master')
        }
        if (type == "Contract_XREF") {
            history.push('/contract-xref')
        }
        if (type == "Membership") {
            history.push('/membership')
        }
    }
    return <>
        {sessionStorage.getItem('application') === 'Master Data' ?
            <LoadingOverlay
                active={flag}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: "50px",
                        "& svg circle": {
                            stroke: "#64C4B4",
                        },
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(52, 52, 52, 0)",
                    }),
                    content: (base) => ({
                        ...base,
                        color: "#64C4B4",
                    }),
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >
                <div className={clsx(classes.root, className)}>
                    <div style={{ height: "100%", marginTop: 10 }}>
                        <div style={{ flex: 1, display: "flex" }}>
                            <div
                                style={{ flex: 0.2, padding: 4, marginTop: '-1rem' }}
                                classes={{ root: classes.contractCellContainer }}
                            >
                                <ContractCell
                                    className={classes.customerMaster}
                                    heading={"Customers"}
                                    value={masterDataCount.customer_master ? masterDataCount.customer_master.toLocaleString() : '0'}
                                    onClick={() => handleContractCell('Customer_Master')}
                                />
                            </div>
                            <div
                                style={{ flex: 0.2, padding: 4, marginTop: '-1rem' }}
                                classes={{ root: classes.contractCellContainer }}
                            >
                                <ContractCell
                                    className={classes.materialMaster}
                                    heading={"Materials"}
                                    value={masterDataCount.material_master ? masterDataCount.material_master.toLocaleString() : '0'}
                                    onClick={() => handleContractCell('Material_Master')}
                                />
                            </div>
                            <div
                                style={{ flex: 0.2, padding: 4, marginTop: '-1rem' }}
                                classes={{ root: classes.contractCellContainer }}
                            >
                                <ContractCell
                                    className={classes.supplierMaster}
                                    heading={"Suppliers"}
                                    value={masterDataCount.supplier_master ? masterDataCount.supplier_master.toLocaleString() : '0'}
                                    onClick={() => handleContractCell('Supplier_Master')}
                                />
                            </div>
                            <div
                                style={{ flex: 0.2, padding: 4, marginTop: '-1rem' }}
                                classes={{ root: classes.contractCellContainer }}
                            >
                                <ContractCell
                                    className={classes.membership}
                                    heading={"Memberships"}
                                    value={masterDataCount.membership_masters ? masterDataCount.membership_masters.toLocaleString() : '0'}
                                    onClick={() => handleContractCell('Membership')}
                                />
                            </div>
                            <div
                                style={{ flex: 0.2, padding: 4, marginTop: '-1rem' }}
                                classes={{ root: classes.contractCellContainer }}
                            >
                                <ContractCell
                                    className={classes.employeeMaster}
                                    heading={"Employees"}
                                    value={masterDataCount.employee_master ? masterDataCount.employee_master.toLocaleString() : '0'}
                                    onClick={() => handleContractCell('Employee_Master')}
                                />
                            </div>
                            <div
                                style={{ flex: 0.2, padding: 4, marginTop: '-1rem' }}
                                classes={{ root: classes.contractCellContainer }}
                            >
                                <ContractCell
                                    className={classes.customerXREF}
                                    heading={"Customer XREFs"}
                                    value={masterDataCount.customer_xref ? masterDataCount.customer_xref.toLocaleString() : '0'}
                                    onClick={() => handleContractCell('Customer_XREF')}
                                />
                            </div>
                            <div
                                style={{ flex: 0.2, padding: 4, marginTop: '-1rem' }}
                                classes={{ root: classes.contractCellContainer }}
                            >
                                <ContractCell
                                    className={classes.materailXREF}
                                    heading={"Material XREFs"}
                                    value={masterDataCount.material_xref ? masterDataCount.material_xref.toLocaleString() : '0'}
                                    onClick={() => handleContractCell('Material_XREF')}
                                />
                            </div>
                        </div>
                    </div>
                    <Grid container className={classes.container} spacing={2}>
                        <Grid item lg={4} sm={6} xl={4} xs={6} className={classes.gridContainer}>
                            <BarLineGraph
                                text={"New"}
                                newData={props.masterDataChart.CREATE}
                                id='chart1'
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xl={4} xs={6} className={classes.gridContainer}>
                            <BarLineGraph
                                text={"Updates"}
                                newData={props.masterDataChart.UPDATE}
                                id='chart2'
                            />
                        </Grid>
                        <Grid item lg={4} sm={6} xl={4} xs={6} className={classes.gridContainer}>
                            <BarLineGraph
                                text={"Deletions"}
                                newData={props.masterDataChart.DELETE}
                                id='chart3'
                            />
                        </Grid>
                    </Grid>

                    {dataRows && dataRows.length > 0 ?
                        <TableContainer>
                            <Table >
                                <TableHead >
                                    <TableRow >
                                        <TableCell classes={{ root: classes.fontSetting }}>Master Data</TableCell>
                                        <TableCell classes={{ root: classes.fontSetting }}>New</TableCell>
                                        <TableCell classes={{ root: classes.fontSetting }}>Updates </TableCell>
                                        <TableCell classes={{ root: classes.fontSetting }}>Deletions </TableCell>
                                        <TableCell classes={{ root: classes.fontSetting }}>Actions </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }} >
                                    {dataRows
                                        .map(item => {
                                            return (
                                                <StyledTableRow key={item.app_functionality}>
                                                    <TableCell classes={{ root: classes.fontSetting }} onClick={() => handleApplication(item.app_functionality)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007bff' }}>{item.app_functionality.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') })}</TableCell>
                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007bff' }} onClick={() => handleItem(item.app_functionality, "CREATE")}>{item.added_customers}</TableCell>
                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007bff' }} onClick={() => handleItem(item.app_functionality, "UPDATE")}>{item.updated_customers}</TableCell>
                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007bff' }} onClick={() => handleItem(item.app_functionality, "DELETE")}>{item.removed_customers}</TableCell>
                                                    <TableCell align='center'>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => handleActions(item.app_functionality)}
                                                            size="large">
                                                            <SearchSharp color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <div style={{ paddingTop: 15 }}>
                            {
                                dataRows && dataRows.length === 0 ?
                                    <Typography variant='h4'>
                                        There is no data to show now.
                                    </Typography>
                                    :
                                    <CircularProgress />
                            }
                        </div>
                    }
                </div>
            </LoadingOverlay>
            // :
            // sessionStorage.getItem('application') === 'Transaction Data' ?
            //     <LoadingOverlay
            //         active={flag1}
            //         styles={{
            //             spinner: (base) => ({
            //                 ...base,
            //                 width: "50px",
            //                 "& svg circle": {
            //                     stroke: "#64C4B4",
            //                 },
            //             }),
            //             overlay: (base) => ({
            //                 ...base,
            //                 background: "rgba(52, 52, 52, 0)",
            //             }),
            //             content: (base) => ({
            //                 ...base,
            //                 color: "#64C4B4",
            //             }),
            //         }}
            //         spinner={<HashLoader />}
            //         className={classes.spinner}
            //     >
            //         <div className={classes.tabContainer}>
            //             <div className={classes.tabBackground}>
            //                 <div style={{ width: '100%' }}>
            //                     <div style={{ width: 'auto', flexDirection: 'row', display: 'flex', color: "white" }}>
            //                         <div style={{ width: "20%", marginLeft: "35px" }}>
            //                             <div >
            //                                 <NavLink to="/display-sales-invoice" className={classes.navLink}>
            //                                     <Paper className={classes.paper} >

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}>Staging Direct Sales Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.staging_direct_sales_data ? masterDataTransactionCount.staging_direct_sales_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>

            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>
            //                             <div style={{ marginTop: "20%" }}>
            //                                 <NavLink to="/display-sales-invoice" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}> Direct Sales Data </div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.direct_sales_data ? masterDataTransactionCount.direct_sales_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>
            //                             <div style={{ marginTop: "20%" }}>
            //                                 <NavLink to="/competitor-data" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}> Competitor Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.competitor_data ? masterDataTransactionCount.competitor_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>
            //                         </div>


            //                         <div style={{ width: "20%", marginLeft: "5%" }}>

            //                             <div >
            //                                 <NavLink to="/display-indirect-sales-invoice" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}> Staging Indirect Sales Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.staging_indirect_sales_data ? masterDataTransactionCount.staging_indirect_sales_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>


            //                             <div style={{ marginTop: "20%" }}>
            //                                 <NavLink to="/display-indirect-sales-invoice" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}> Indirect Sales Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.indirect_sales_data ? masterDataTransactionCount.indirect_sales_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>
            //                             <div style={{ marginTop: "20%" }}>
            //                                 <NavLink to="/demand-supply" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}>Demand Supply </div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.demand_supply ? masterDataTransactionCount.demand_supply.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>
            //                         </div>

            //                         <div style={{ width: "20%", marginLeft: "5%" }}>

            //                             <div>
            //                                 <NavLink to="/display-purchase-invoice" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}>Staging Purchase Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.staging_purchase_data ? masterDataTransactionCount.staging_purchase_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>
            //                             <div style={{ marginTop: "20%" }}>

            //                                 <NavLink to="/display-purchase-invoice" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}>Purchase Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.purchase_data ? masterDataTransactionCount.purchase_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>

            //                                     </Paper>

            //                                 </NavLink>
            //                             </div>


            //                         </div>


            //                         <div style={{ width: "20%", marginLeft: "5%" }}>
            //                             <div >
            //                                 <NavLink to="/target-invoice-query" className={classes.navLink}>
            //                                     <Paper className={classes.paper}
            //                                     >

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}> Commitment Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.commitment_data ? masterDataTransactionCount.commitment_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>

            //                                 </NavLink>
            //                             </div>
            //                             <div style={{ marginTop: "20%" }}>
            //                                 <NavLink to="/inventory-data" className={classes.navLink}>
            //                                     <Paper className={classes.paper}>

            //                                         <div style={{ fontSize: '1.35rem', color: 'blue' }}> Inventory Data</div>
            //                                         <div style={{ fontSize: '1.25rem', marginTop: '5%' }}>Count: {masterDataTransactionCount.inventory_data ? masterDataTransactionCount.inventory_data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</div>
            //                                     </Paper>
            //                                 </NavLink>
            //                             </div>
            //                         </div>

            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </LoadingOverlay>
            :
            <StyledEngineProvider injectFirst>
                {/* <Typography variant='h1'>Dashboard</Typography> */}
                <ThemeProvider theme={theme}>
                    {/* <LoadingOverlay active={props.loading} spinner={<GridLoader />}> */}
                    <Grid container component="main" sx={{ height: "89vh" }}>
                        <CssBaseline />
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            md={5}
                            // component={Paper}
                            // elevation={6}
                            // square
                            className={classes.bgColor}
                            style={{ backgroundColor: color }}
                            sx={{
                                // backgroundImage: 'url(https://source.unsplash.com/random)',
                                // backgroundColor: "#445AC7",
                                backgroundRepeat: "no-repeat",
                                // backgroundColor: (t) =>
                                //   t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    my: 8,
                                    mx: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography component="h1" variant="h2" style={{ color: 'white', fontSize: 40 }}>
                                    {sessionStorage.getItem('application')}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={7}
                            sx={{
                                // backgroundImage: 'url(https://source.unsplash.com/random)',
                                // backgroundColor: color,
                                backgroundRepeat: "no-repeat",
                                // backgroundColor: (t) =>
                                //   t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                className={classes.bgColor}
                                style={{
                                    backgroundColor: color,
                                    borderRadius: 50, padding: 20, boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                                }}>
                                <img
                                    alt="Logo"
                                    src={require("../../library/images/new_logo.png")}
                                    className={classes.logo}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    {/* </LoadingOverlay> */}
                </ThemeProvider>
            </StyledEngineProvider>
        }
    </>;
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        masterDataDashboard: state.dataSetupData.masterDataDashboard,
        masterDataTransactionDashboard: state.dataSetupData.masterDataTransactionDashboard,
        masterDataDashboardChanges: state.dataSetupData.masterDataDashboardChanges,
        masterDataChart: state.dataSetupData.masterDataChart,
        profileData: state.profileData.profileData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMasterDataDashboard: (type) => dispatch(getMasterDataDashboard(type)),
        getMasterDataDashboardChanges: (type) => dispatch(getMasterDataDashboardChanges(type)),
        getMasterDataByOperation: (appfunctionality, operation) => dispatch(getMasterDataByOperation(appfunctionality, operation)),
        getMasterDataDashboardChart: (type) => dispatch(getMasterDataDashboardChart(type))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);