import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { DropdownKeyValue, DropdownArray } from '../../../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues, getApprovalGroupData } from '../../../../../../redux/actions';
import { connect } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    headerRoot: {
        margin: 0,
        padding: '10px 0 0 0'
    }
}));

const ApprovalStatus = (props, ref) => {
    const contractCustomisationUpdation = useRef();
    const [approvalStatusArray, setApprovalStatusArray] = React.useState([]);
    const [approvalStatus, setApprovalStatus] = React.useState('new');
    const [approvalGroup, setApprovalGroup] = React.useState('');
    const [approvalStatusFetched, setApprovalStatusFetched] = React.useState('Approval Status');
    const steps = getSteps();
    const [activeStep, setActiveStep] = React.useState(0);
    const optionData = [
        { key: 'new', value: 'New' },
    ];
    const submitStatus = [
        { key: 'submit_approval', value: 'Submit for Approval' }
    ]

    const rejectData = [
        { key: 'rej', value: 'Rejected' },
    ];
    const acceptedData = [
        { key: 'acc', value: 'Approved' },
    ];
    function getSteps() {
        return ['New', 'Submit for approval', approvalStatusFetched];
    }

    useEffect(() => {
        props.getApprovalGroupData();
    }, []);
    useEffect(() => {
        for (var propName in approvalStatusValues) {
            if (approvalStatusValues[propName] === '' || approvalStatusValues[propName] === null
            ) {
                delete approvalStatusValues[propName];
            }
        }
        props.onChange(approvalStatusValues);
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'approval_status') {
                            setApprovalStatusArray(item);
                        }
                    })
                }
            }
        }
    });
    // var mode = localStorage.getItem('mode');
    const classes = useStyles();
    const { className } = props;
    const approvalStatusValues = {
        approval_status: approvalStatus,
        approval_group_id: approvalGroup
    };
    const [approvalGroupAPIData, setApprovalGroupAPIData] = React.useState([]);

    //new fields
    function handleApprovalStatus(newValue) {
        setApprovalStatus(newValue);
    }
    function handleApprovalGroup(e) {
        setApprovalGroup(e.target.value);
    }
    useEffect(() => {
        if (props.approvalGroupData && props.approvalGroupData.records) {
            setApprovalGroupAPIData(props.approvalGroupData.records)
        }
    }, [props.approvalGroupData]);

    useEffect(() => {
        if (props.contractDefaultData) {
            setApprovalGroup(props.contractDefaultData.approval_group);
        } else {
            setApprovalGroup('');
        }
    }, [props.contractDefaultData]);
    useEffect(() => {
        if (props.data && props.editMode) {
            console.log(props.data)
            setApprovalGroup(props.data.approval_group_id)
            setApprovalStatus(props.data.approval_status)
            if (props.data.approval_status === 'acc') {
                setApprovalStatusFetched('Approved')
                setActiveStep(2);
            }
            if (props.data.approval_status === 'rej') {
                setApprovalStatusFetched('Rejected')
                setActiveStep(2);
            }
            if (props.data.approval_status === 'new') {
                setActiveStep(0);
            }
            if (props.data.approval_status === 'submit_approval') {
                setActiveStep(1);
            }
        }
    }, [props.data]);

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>

                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {approvalStatusArray && props.type != 'viewQuote' &&
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ width: '50%', padding: 15 }}>
                                                <CardHeader
                                                    title="APPROVAL STATUS"
                                                    titleTypographyProps={{ variant: 'h3' }}
                                                    classes={{ root: classes.headerRoot }}
                                                />
                                                <DropdownKeyValue heading={''} placeholder={''} twoline='true' onChange={handleApprovalStatus}
                                                    data={props.editMode ? props.data.approval_status == 'new' ? [...optionData, ...submitStatus] : props.data.approval_status == 'submit_approval' ? [...acceptedData, ...rejectData] : props.data.approval_status == 'acc' ? acceptedData : submitStatus : optionData}
                                                    prevalue={approvalStatus} />
                                            </div>
                                            <div style={{ width: '50%', padding: 10 }}>
                                                <CardHeader
                                                    title={"APPROVAL GROUP*"}
                                                    titleTypographyProps={{ variant: 'h3' }}
                                                    classes={{ root: classes.headerRoot }}
                                                    style={{ paddingBottom: 20 }}
                                                />{console.log(approvalGroup)}
                                                {approvalGroupAPIData &&

                                                    <FormControl >
                                                        <Select
                                                            disableUnderline
                                                            onChange={handleApprovalGroup}
                                                            // displayEmpty
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            defaultValue={approvalGroup}
                                                            value={approvalGroup}
                                                        >
                                                            {/* <MenuItem value={props.contractDefaultData && props.contractDefaultData.approval_group ? props.contractDefaultData.approval_group : ""}>{props.contractDefaultData && props.contractDefaultData.approval_group ? props.contractDefaultData.approval_group : ""}</MenuItem> */}
                                                            {approvalGroupAPIData
                                                                .map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.ID} key={item.ID}>
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                        </div>

                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <CardHeader
                                        title="CURRENT STATUS"
                                        titleTypographyProps={{ variant: 'h3' }}
                                        classes={{ root: classes.headerRoot }}
                                    />
                                    <Stepper alternativeLabel activeStep={activeStep}>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

}

const mapStateToProps = state => ({
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    approvalGroupData: state.addMultipleConfigurationData.approvalGroupData
});

const mapDispatchToProps = dispatch => {
    return {
        getApprovalGroupData: () => dispatch(getApprovalGroupData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ApprovalStatus);