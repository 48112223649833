import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography, Radio, RadioGroup,
    FormControlLabel, FormLabel, Select, MenuItem,
    OutlinedInput, TextField, Checkbox,
    ListItemText, Input, useTheme, Card
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getContractsTableDataList, getCalculationSimulationNumbers, getCustomerMasterData, postCollaborationPortal, runContractTrends, loadValuesBasedOnAppTypeAndField, allowedApps, fetchDropdownConfig, downloadContractTrendsResltsSheet } from '../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import { toast } from 'react-toastify';
import MomentUtils from '@date-io/moment';
import RotateLoader from "react-spinners/RotateLoader";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import secureLocalStorage from 'react-secure-storage';
import { SearchDropDownMasterDataNew, ChipInput, SearchDropDownMasterData } from '../../../components/Inputs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import BeatLoader from "react-spinners/BeatLoader";
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        // padding: '10px 0px 0px 0px',
        padding: '20px 16px',
        marginTop: 5,
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px',
        marginTop: '1.5rem'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30,
        width: "60%"
    },
    button: {
        marginRight: 25,
        width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        fontSize: theme.typography.h3.fontSize,
        marginTop: '1.7rem'
    },
    spinner: {
        height: '100vh'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: '2.6rem',
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll",
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    menuItem: {
        height: '500px'
    },

    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        marginBottom: 6
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
        width: "60%",
        marginLeft: "1.5rem"
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    button: {
        marginRight: 25,
        width: 160
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30,
        width: "60%"
    },
    gridContainer: {
        padding: "0px 15px 10px 10px",
    },
    gridContainerOuter: {
        padding: "0px 0px 0px 0px",
    },
    select: {
        width: "100%",
        // color: "#1675e0",
        backgroundColor: "white",
    },
    selectedItem: {
        borderRadius: 5,
        textTransform: "capitalize",
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        borderRadius: '1rem',
        height: '36px'
    },
    container: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    leftGridContainer: {
        paddingRight: 10
    },
    calendarLeftGridContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        padding: '0px 10px 0px 10px'
    },
    rightGridContainer: {
        paddingLeft: 10
    },
    submitContainer: {
        marginTop: 15
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dropdownSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 15
    },
    multiSelect: {
        marginTop: 10
    },
    textInput: {
        marginTop: 10
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        width: "100%"
        // marginBottom: 14
    },
    textInputHeight: {
        height: 17
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '2rem',
        marginTop: '0.5rem',
        marginBottom: '5px',
    }
}));

const ContractTrends = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [calculationSimulationNumber, setCalculationSimulationNumber] = React.useState('');
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [displayLevel, setDisplayLevel] = React.useState([]);
    const [open2, setOpen2] = React.useState("");
    const [selected2, setSelected2] = React.useState([]);
    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [contractNumber, setContractNumber] = useState('');
    const [radioGroupValue, setRadioGroupValue] = React.useState('calculation_simulation');
    const [open, setOpen] = React.useState(false);
    const [postingNumber, setPostingNumber] = React.useState('');
    const [calculationSimulationNumbers, setCalculationSimulationNumbers] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [tags, setTags] = useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const AggregationLevelArray = [
        "bill_to_party",
        "customer_number",
        "customer_group",
        "customer_chain",
        "flex_attribute1",
        "material_number",
        "material_group",
        "payer",
        "product_hierarchy",
        "profit_center",
        "plant",
        "sold_to_party",
        "supplier_number",
        "parent_customer_number",
        "top_parent_customer_number",
        "employee_number",
        "territory_id",
    ];
    const isAllSelected2 =
        AggregationLevelArray &&
        AggregationLevelArray.length > 0 && selected2.length === AggregationLevelArray.length;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const [status, setStatus] = React.useState(false);

    useEffect(() => {
        props.onCheckContractsTable();
        sessionStorage.removeItem('contractTrendsQuery')
        props.getdropdownConfig('customer_number')
        setCalculationPeriodStart(Moment.utc().subtract(1, "months").startOf("month"));
        setCalculationPeriodEnd(Moment.utc().subtract(1, "months").endOf("month"));
    }, []);
    useEffect(() => {
        props.allowedApps();
        //props.onCheckCustomerTable();
        if (secureLocalStorage.getItem("dYtz").collaboration_id != '0') {
            props.postCollaborationPortal(null, secureLocalStorage.getItem("dYtz").collaboration_id, 'get')
        }
    }, [])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
    }, []);
    useEffect(() => {
        var tempArray = [];
        var tempNameArray = [];
        var temp1 = [];
        if (props.collaborationPortalData && props.collaborationPortalData.records && props.collaborationPortalData.records[0].customer_number) {
            temp1 = props.collaborationPortalData.records[0].customer_number
        }
        if (temp1 && temp1.length > 0 && props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {

            props.customerMasterData.records
                .filter(item => temp1.includes(item.customer_number))
                .map((item) => {
                    tempArray.push(item.customer_number);
                    tempNameArray.push(item.customer_name);
                })

            setCustomerNameListArray(tempNameArray);
            setCustomerNumberList(tempArray)
        }
    }, [props.customerMasterData, props.collaborationPortalData])
    useEffect(() => {
        var tempArray = [];
        var tempNameArray = [];
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            props.customerMasterData.records
                .map((item) => {
                    tempArray.push(item.customer_number);
                    tempNameArray.push(item.customer_name);
                })

            setCustomerNameListArray(tempNameArray);
            setCustomerNumberList(tempArray)
        }
    }, [props.customerMasterData])

    useEffect(() => {
        if (props.dropdownConfig)
            setActiveDropdown(props.dropdownConfig)

    }, [props.dropdownConfig])
    const filterOptions2 = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    function handleTags(newValue) {
        setTags(newValue)
    }
    function handleCustomerDelete(newValue) {
        var deletedTags = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedTags)
    }
    var formData = {
        "customer_number": customerNumber.toString(),
        "start_date": Moment(calculationPeriodStart).format('MM/DD/YYYY'),
        "end_date": Moment(calculationPeriodEnd).format('MM/DD/YYYY'),
        "contract_number": contractNumber,
        "posting_number": postingNumber,//radioGroupValue === 'calculation_simulation' ? calculationSimulationNumber : postingNumber,
        "posting_type": radioGroupValue === 'calculation_simulation' ? '' : radioGroupValue,
        "display_level": displayLevel.toString()
    };
    function handleOpen2() {
        setSelected2(displayLevel);
        setOpen2(true);
    }
    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            if (sourceDataType == "Direct - Sales Data")
                setSelected2(
                    selected2.length ===
                        AggregationLevelArray.length
                        ? []
                        : AggregationLevelArray
                );
            else
                setSelected2(
                    selected2.length === AggregationLevelArray.length
                        ? []
                        : AggregationLevelArray
                );
        } else setSelected2(newSelected);
    };
    function onAdd2() {
        setDisplayLevel(selected2);
        setOpen2(false);
        setSelected2([]);
    }
    function onCancel2() {
        setOpen2(false);
        setSelected2([]);
    }
    const handleClickOpen = () => {
        if (calculationPeriodStart <= calculationPeriodEnd) {
            sessionStorage.setItem("contractTrendsQuery", JSON.stringify(formData));
            props.runContractTrends(formData);
        }
        else {
            toast.error('Please a The Valid Calculation Period!');
        }
    };
    const handleClickOpen1 = () => {
        if (calculationPeriodStart <= calculationPeriodEnd) {
            props.downloadSheet(formData)
        }
        else {
            toast.error('Please a The Valid Calculation Period!');
        }
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);

            props.onSubmit(formData);
        } else {
            setOpen(false);
        }
    }

    function handleCalculationPeriodStart(newValue) {
        setCalculationPeriodStart(newValue);
    }
    function handleCalculationPeriodEnd(newValue) {
        setCalculationPeriodEnd(newValue);
    }
    function handleContractNumber(e) {
        setContractNumber(e);
    }
    useEffect(() => {
        if (contractNumber && radioGroupValue && calculationPeriodStart && calculationPeriodEnd)
            if ((calculationPeriodStart && calculationPeriodStart.isValid()) && (calculationPeriodEnd && calculationPeriodEnd.isValid())) {
                props.getCalculationSimulationNumbers(contractNumber, Moment(calculationPeriodStart).format('MM/DD/YYYY'), Moment(calculationPeriodEnd).format('MM/DD/YYYY'), radioGroupValue)
            }
            else
                setCalculationSimulationNumbers([])
    }, [contractNumber, radioGroupValue, calculationPeriodStart, calculationPeriodEnd])
    function handleRadioGroup(newValue) {
        setPostingNumber('');
        setRadioGroupValue(newValue.target.value);
    }
    function handleCustomerNumber(newValue, type) {
        // if (type == 'custom')
        //     setCustomerNumber([...customerNumber, newValue])
        // else
        setCustomerNumber(newValue)
    }
    useEffect(() => {
        if (props.calculationSimulationNumbers)
            setCalculationSimulationNumbers(props.calculationSimulationNumbers)
        else
            setCalculationSimulationNumbers([])
    }, [props.calculationSimulationNumbers])

    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    useEffect(() => {
        var contracts = [];
        var tempNameArray = []
        if (props.contractablesdata && props.contractablesdata.length > 0) {
            props.contractablesdata.map(item => {
                contracts.push(item.contract_number.toString())
                tempNameArray.push(item.internal_description);
            })
            setContractNumbersList(contracts);
            setInternalDescriptionArray(tempNameArray);

        }

    }, [props.contractablesdata])
    const handleClear = () => {
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setContractNumber('');
        setTags([]);
        setRadioGroupValue('calculation_simulation');
        setPostingNumber('');
        setCalculationSimulationNumbers([]);
        setDisplayLevel([]);
        setCustomerNumber([]);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 22 }} >Calculation Analysis</Typography>
                </div>
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container
                        >
                            <div style={{
                                padding: "0px  10px 20px 10px", width: "100%"
                            }}>
                                <div className={classes.titleSetting}>
                                    <div style={{ alignItems: 'center' }}>
                                        <Typography classes={{ root: classes.fontSetting1 }} variant="h2" color="secondary" style={{ marginLeft: "20px" }} >
                                            Contracts Data
                                            <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                                        </Typography>
                                    </div>
                                </div>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%', marginTop: "8px" }}>
                                            <FormLabel
                                            >
                                                Contract Number
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <SearchDropDownMasterDataNew id='contract_number'
                                                table='contract_header' keyName='contract_number'
                                                description={true}
                                                onChange={handleContractNumber}
                                                prevalue={contractNumber}
                                                applicationType={sessionStorage.getItem('application')}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </form>
                </Card>
                <Card className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container
                        >
                            <div style={{
                                padding: "0px 10px 20px 10px", width: "100%"
                            }}>
                                <div className={classes.titleSetting}
                                >
                                    <Typography classes={{ root: classes.fontSetting1 }} variant="h2" color="secondary" style={{ marginLeft: "20px" }}  >
                                        Calculation Data
                                    </Typography>
                                </div>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ marginTop: "1.25rem" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "-5px" }}>
                                        <div style={{ width: '40%', marginTop: "-7px" }}>
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                Calculation Period
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginTop: "-4px", display: 'flex' }}>
                                            {/* <div> */}
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        size="medium"
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                // padding: '0px 11px 0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: "95%",
                                                                marginLeft: -11,
                                                            }
                                                        }}
                                                        value={calculationPeriodStart}
                                                        onChange={handleCalculationPeriodStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                // padding: '0px 11px 0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: "95%",
                                                                marginRight: -11
                                                            }
                                                        }}
                                                        value={calculationPeriodEnd}
                                                        onChange={handleCalculationPeriodEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ marginTop: "-7px" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                Processing Type
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <Select
                                                value={radioGroupValue}
                                                // onChange={(e) => setPostingNumber(e.target.value)}
                                                onChange={handleRadioGroup}
                                                displayEmpty

                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value={'calculation_simulation'} key={'calculation_simulation'}>{'Calculation Simulation'}</MenuItem>
                                                <MenuItem value={'accrual'} key={'accrual'}>{'Accrual'}</MenuItem>
                                                <MenuItem value={'payment'} key={'payment'}>{'Payment'}</MenuItem>

                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ marginTop: "-7px" }}
                                    cclasses={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel required className={classes.chargeBackLabel} >
                                                {radioGroupValue != 'calculation_simulation' ? 'Posting Number' : 'Calculation Simulation Number'}
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginRight: "15px" }}>
                                            <Select
                                                value={postingNumber}
                                                onChange={(e) => setPostingNumber(e.target.value)}
                                                displayEmpty

                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {calculationSimulationNumbers && Object.entries(calculationSimulationNumbers).sort(([a1, b1], [a2, b2]) => a2 - a1).map(([key, value]) => {
                                                    return (
                                                        <MenuItem value={key} key={key}>
                                                            {value ? key + " - " + value : key}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: "2px" }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel classes={{ root: classes.fontSetting }}>
                                                Customer Number
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <SearchDropDownMasterDataNew id='customer_number_multiple'
                                                multiple
                                                table='customer_master' keyName='customer_number'
                                                description={true}
                                                onChange={handleCustomerNumber}
                                                prevalue={customerNumber}

                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    cclasses={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "-6px", marginLeft: "10px" }}>
                                        <div style={{ width: '40%', marginTop: "-5px" }}>
                                            <FormLabel className={classes.chargeBackLabel} >
                                                Display Level
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%', marginLeft: "-6px" }}>
                                            <Select
                                                labelId="demo-mutiple-name-label"
                                                id="demo-mutiple-name"
                                                multiple
                                                value={displayLevel}
                                                style={{ width: "98.5%", textTransform: "capitalize" }}
                                                input={<OutlinedInput sx={{ padding: '4px' }} />}
                                                onOpen={() => handleOpen2()}
                                                onClose={() => setOpen2(false)}
                                                open={open2}
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                renderValue={(appType) => {
                                                    var priceType = [];
                                                    appType.map((item) => {
                                                        priceType.push(item.replace(/_/g, " "));
                                                    });
                                                    return priceType.join(", ");
                                                }}
                                            >
                                                <MenuItem
                                                    value="all"
                                                    onClick={(event) => handleSelectValues2(event, "all")}
                                                    style={{ textTransform: "capitalize", display: 'flex', justifyConent: 'center' }}
                                                >
                                                    <Checkbox color="primary" checked={isAllSelected2} />
                                                    <ListItemText primary="Select All" />
                                                </MenuItem>
                                                {AggregationLevelArray &&
                                                    AggregationLevelArray.map((item) => {
                                                        return (
                                                            <MenuItem
                                                                onClick={(event) =>
                                                                    handleSelectValues2(event, item)
                                                                }
                                                                value={item}
                                                                key={item}
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={selected2.indexOf(item) > -1}
                                                                />
                                                                <ListItemText primary={item.replace(/_/g, " ")} />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                <div className={classes.dropdownAction}>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        className={classes.btn}
                                                        onClick={onCancel2}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.btn}
                                                        onClick={onAdd2}
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ marginTop: "5px" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ width: '40%' }}>
                                            <FormLabel className={classes.chargeBackLabel}>
                                                Tags
                                            </FormLabel>
                                        </div>
                                        <div style={{ width: '60%' }}>
                                            <ChipInput data={tags} onChange={handleTags} />
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleClickOpen}
                        disabled={
                            (radioGroupValue === 'calculation_simulation' && !postingNumber) || !contractNumber || !calculationPeriodStart || !calculationPeriodEnd ? true : false}
                    >
                        {props.loading ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Run Online'
                        }
                    </Button>
                    <Button
                        disabled={
                            (radioGroupValue === 'calculation_simulation' && !postingNumber) || !contractNumber || !calculationPeriodStart || !calculationPeriodEnd ? true : false}
                        variant="contained" color="primary" className={classes.button}
                        onClick={handleClickOpen1}
                        // onClick={() => { props.downloadSheet(formData) }}
                        twoline='true'>
                        {props.loading ?
                            <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                            :
                            'Download'
                        }
                    </Button>
                </div>
            </div>
            {/* </div>
            </div > */}
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        runContractTrends: (formData) => dispatch(runContractTrends(formData, "contractV3")),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        getCalculationSimulationNumbers: (e, sd, ed, postingType) => dispatch(getCalculationSimulationNumbers(e, sd, ed, postingType)),
        onCheckContractsTable: () => dispatch(getContractsTableDataList('AllApps')),
        allowedApps: () => dispatch(allowedApps()),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 0)),
        postCollaborationPortal: (data, editId, type) => dispatch(postCollaborationPortal(data, editId, type)),
        getdropdownConfig: (field) => dispatch(fetchDropdownConfig(field)),
        downloadSheet: (formData) => dispatch(downloadContractTrendsResltsSheet(formData)),
    }
}


const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        calculationSimulationNumbers: state.addMultipleConfigurationData.calculationSimulationNumbers,
        contractablesdata: state.addContractData.contractAllListData,
        allowedAppsData: state.initialData.allowedApps,
        customerMasterData: state.customerData.customerMasterData,
        collaborationPortalData: state.addMultipleConfigurationData.collaborationPortalData,
        dropdownConfig: state.financialCloseData.dropdownFieldConfig
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractTrends);