import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    Button, Tooltip, Fab, Typography
} from '@mui/material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { DeleteForever, AddBoxOutlined, Edit, LibraryAdd } from '@mui/icons-material';
import MaterialTable from 'material-table';
import { NavLink as RouterLink } from 'react-router-dom';
import { createTheme } from "@mui/material/styles";
import Moment from 'moment';
import { getEnv } from "../../../env";
import { clonePromotionSetup, createPromotionSetup, getPromotionSetup } from '../../../redux/actions';
import { ContentCopy, FileDownload } from '@mui/icons-material';
import secureLocalStorage from "react-secure-storage";

const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    }
}));

const PromotionSetupMain = props => {

    const { className } = props;
    const theme = useTheme();
    var baseUrl = getEnv()
    const classes = useStyles();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const columns = [
        {
            field: 'costing_number',
            title: 'Costing Number',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.costing_number}</a>
        },

        {
            field: 'promotion_theme',
            title: 'Promotion Theme',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.promotion_theme}</a>
        },
        {
            field: 'promotion_category',
            title: 'Promotion Category',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.promotion_category}</a>
        },
        {
            field: 'promotion_type',
            title: 'Promotion Type',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.promotion_type}</a>
        },
        {
            field: 'costing_description',
            title: 'Costing Description',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.costing_description}</a>
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.country}</a>
        },
        {
            field: 'division',
            title: 'Division',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {rowData.division}</a>
        },
        {
            field: 'promotion_start_date',
            title: 'Promotion Start Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}> {Moment.utc(rowData.promotion_start_date).format('MM/DD/YYYY')}</a>
        },

        {
            field: 'promotion_end_date',
            title: 'Promotion End Date',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.id)}>{Moment.utc(rowData.promotion_end_date).format('MM/DD/YYYY')}</a>
        },

        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ minWidth: 150 }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_promotion_setup-' + sessionStorage.getItem('application')) &&
                        <Tooltip title="Edit">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editQuota(rowData.id)}
                                size="large">
                                <Edit color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        </Tooltip>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('clone_promotion_setup-' + sessionStorage.getItem('application')) &&
                        <Tooltip title="Clone">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => props.clonePromotion(rowData.id)}
                                size="large">
                                <ContentCopy color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        </Tooltip>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_promotion_setup-' + sessionStorage.getItem('application')) &&
                        <Tooltip title="Delete">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => props.onDelete(rowData, rowData.id)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        </Tooltip>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('download_promotion_setup-' + sessionStorage.getItem('application')) &&
                        <Tooltip title="Download">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleDownload(rowData.id, rowData.costing_number)}
                                size="large">
                                <FileDownload color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        </Tooltip>}
                </div>
        },
    ];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    useEffect(() => {
        props.getPromoData(1, 0);
    }, []);
    function editQuota(id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/promotion-setup/edit-setup/' + id,
            id: id
        });

    }

    const handleDownload = (id, costingNumber) => {
        fetch(baseUrl['BASE_URL'] + 'download-promotion-budget/' + id, {
            //fetch('https://stage-api.ima360.app/v1/api/download-promotion-budget/' + id, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                var date = Date.now();
                var file = 'promotion_' + costingNumber;
                link.setAttribute('download', file + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    }

    function handleItemClick(id) {
        history.push({
            pathname: '/promotion-setup/view-setup/' + id,
            id: id
        });
    }

    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));

    useEffect(() => {
        var tempRows = [];

        if (props.promoData && props.promoData.total_record > 0 && props.promoData.records)
            props.promoData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        costing_number: e.costing_number,
                        country: e.country,
                        division: e.division,
                        promotion_type: e.promotion_type,
                        promotion_theme: e.promotion_theme,
                        promotion_category: e.promotion_category,
                        promotion_start_date: e.promotion_start_date,
                        promotion_end_date: e.promotion_end_date,
                        costing_description: e.costing_description
                    });
                })
        setCount(props.promoData.total_record)
        setDataRows(tempRows);
    }, [props.promoData]);
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading material master. Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Promotion Setup </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_promotion_setup-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/promotion-setup/add-setup'
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                            {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddBoxOutlined />}
                            classes={{
                                startIcon: !isDesktop && classes.startIcon,
                                root: !isDesktop && classes.container
                            }}
                            component={CustomRouterLink}
                            to='/promotion-setup/add-setup'
                            size="small"
                            className={classes.buttonStyle}

                        >
                            {isDesktop ? 'Add Promotion Setup' : ''}
                        </Button> */}

                        </div>}
                </div>
                {props.promoData && props.promoData.records && props.promoData.records.length > 0 ?
                    <MaterialTable
                        components={{

                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            pageSize: rowsPerPage,
                            count: count,
                            page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],

                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        promoData: state.promotionData.promotionSetupData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPromoData: () => dispatch(getPromotionSetup()),
        onDelete: (data, id) => dispatch(createPromotionSetup(data, id, 'delete')),
        clonePromotion: (id) => dispatch(clonePromotionSetup(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSetupMain);