import React from 'react';
import { connect } from 'react-redux';
import { CustomerMasterUserTable } from './components';

const CustomerMaster = props => {


    return (
        <div>
            <CustomerMasterUserTable />
        </div >
    );
};


export default connect(null,null)(CustomerMaster);