import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput, FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    updateExternalFormulaFormatData, getLblDispDesFieldValue
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const ExternalFormulaResultsEditQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName.trim() });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');

    const [externalFields, setExternalFields] = React.useState([]);
    const [allowedField, setAllowedField] = React.useState('');

    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(history.location.format ? history.location.format : []);

    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    //newfields
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        var data = { "data_source": "external_formula_results" };
        props.loadListControlData(data, 'post');
    }, []);
    useEffect(() => {
        var newArray = []
        var mandatory = []
        if (props.listControlData) {
            Object.values(props.listControlData.field_label_attributes)
                .filter(item => item.sort_details || item.mandatory)
                .sort((a, b) => a.sort_details && a.sort_details > b.sort_details && b.sort_details ? 1 : -1)
                .map((item) => {
                    newArray.push(item)
                    if (item.mandatory)
                        mandatory.push(item.key)
                })
            setExternalFields(newArray)
            setAllowedField(mandatory)
        }
    }, [props.listControlData])
    useEffect(() => {
        if (purchaseQueryDataStorage) {
            setQueryName(purchaseQueryDataStorage.format_name)
            setQueryObject(purchaseQueryDataStorage)
        }
    }, [purchaseQueryDataStorage]);

    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, queryObject);
    }
    function handleClearAll() {
        handleClear();
    }
    function handleCancelDialog() {
        history.push('/external-formula-results/external-formula-results-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
    };
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>Edit Format</Typography>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {externalFields ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.fontSetting} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.fontSetting} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {externalFields
                                                        .filter(item => item.key != 'format_name' && item.key != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item.key}>
                                                                    <TableCell className={classes.fontSetting} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        {allowedField.includes(item.key) ? <p>{item.current}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.current}
                                                                    </TableCell>
                                                                    <TableCell className={classes.fontSetting} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item.key] ? queryObject[item.key] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item.key)}
                                                                        //defaultValue={JSON.parse(localStorage.getItem('quota-management-query'))[item.key] ? JSON.parse(localStorage.getItem('quota-management-query'))[item.key] : ''}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={Object.entries(queryObject).filter(([k, v]) => allowedField.includes(k) && v).length == allowedField.length ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateExternalFormulaFormatData(id, data)),
        loadListControlData: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        queryListData: state.externalFormulaResultsData.externalFormulaFormatFieldData,
        listControlData: state.addMultipleConfigurationData.defaultValueLblDisp,
        formatData: state.externalFormulaResultsData.externalFormulaSingleFormatData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalFormulaResultsEditQuery);