import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Breadcrumbs, Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { getAttributesConfig, getDrivers } from '../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 29px,29px,29px',
        marginTop: 16,
        height: '100vh'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18,
        textTransform: 'capitalize'
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
}));


const MarginPolicyExceptionsResult = props => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [dataRow, setDataRow] = React.useState([]);
    const columns = [
        {
            field: 'customer_number',
            title: 'Customer Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.customer_master_attributes['customer_number']}</a>
        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.material_master_attributes['material_number']}</a>
        },
        {
            field: 'start_date',
            title: 'Start Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.start_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'end_date',
            title: 'End Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.end_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'margin_price_percentage',
            title: 'Margin Price%',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.margin_price_percentage).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        },
        {
            field: 'margin_target_percentage',
            title: 'Margin Target%',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{parseFloat(rowData.margin_target_percentage).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</a>
        }
    ]
    useEffect(() => {
        if (props.configData && props.configData.records && props.configData.records.length > 0) {
            setDataRow(props.configData.records)
        }
    }, [props.configData])
    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/margin-policy-exceptions'
                >
                    Margin Policy Exceptions
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}
            >
                {props.configData && props.configData.records && props.configData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRow}
                        enablePagination={false}
                        enableRowVirtualization
                        // style={{marginTop:'-18px'}}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            paging: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                        }}
                    />


                    :
                    <Typography variant='h4'>
                        No Results
                    </Typography>
                }
            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        configData: state.profitOptimizationData.marginPolicyExpectionData,
    }
};
export default connect(mapStateToProps, null)(MarginPolicyExceptionsResult);