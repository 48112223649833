import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText, DropdownArray, AutoCompleteDropdown } from '../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues, getApprovalGroupData, getApprovalLevelData, fetchDocumentHistory } from '../../../../redux/actions';
import { connect } from 'react-redux';
import DocHistory from '../DocHistory';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    headerRoot: {
        margin: 0,
        padding: '10px 0 0 0'
    },
    cardRoot: {
        backgroundColor: theme.palette.white,
        boxShadow: '1px 2px 4px #9E9E9E',
        borderRadius: 6,
        margin: '10px 20px 10px 20px',
        padding: 15,
        overflow: 'hidden'
    },
}));

const ApprovalStatus = (props, ref) => {
    const [history, setHistory] = React.useState([]);
    const [docAnalyst, setDocAnalyst] = React.useState({ desc: '', key: 0 });
    const [docManager, setDocManager] = React.useState({ desc: '', key: 0 });
    const [excecuted, setExecuted] = React.useState(true);
    const [comments, setComments] = React.useState('');

    useEffect(() => {
        if (props.docId && props.mode != 'create') {
            var data = {
                "functionality": "document_header",
                "functionality_id": props.docId,
                "application_type": sessionStorage.getItem('application')
            }
            props.getHistory(data)
        }
    }, [props.docId]);
    useEffect(() => {
        if (props.docHistory)
            setHistory(props.docHistory)
    }, [props.docHistory]);

    useEffect(() => {
        if (props.mode == 'create' && props.docStatus && props.docStatus.status_description)
            props.onChange('document_status', props.docStatus.status_description)
    }, [props.docStatus]);

    useEffect(() => {
        if (props.mode != 'create' && props.approvalStatusData && props.fieldData && props.fieldData['document_analyst'] && excecuted) {
            if (props.fieldData['document_analyst'] && props.fieldData['document_analyst']['drop_down_reference_value']
                && props.fieldData['document_analyst']['drop_down_reference_value'].length > 0) {
                var data = props.fieldData['document_analyst']['drop_down_reference_value'].filter((item, i) => item.key == props.approvalStatusData['document_analyst'])
                if (data && data[0])
                    setDocAnalyst(data[0])
            }
            if (props.fieldData['document_manager'] && props.fieldData['document_manager']['drop_down_reference_value']
                && props.fieldData['document_manager']['drop_down_reference_value'].length > 0) {
                var data = props.fieldData['document_manager']['drop_down_reference_value'].filter((item, i) => item.key == props.approvalStatusData['document_manager'])
                if (data && data[0])
                    setDocManager(data[0])
            }
            setExecuted(false)
        }
    }, [props.fieldData, props.approvalStatusData]);


    const classes = useStyles();
    const { className } = props;


    const handleStatusData = (value) => {
        props.onChange('document_status', value)
    }
    const handleAnalyst = (value) => {
        setDocAnalyst(value)
        var analyst = value && value.key ? value.key : ''
        props.onChange('document_analyst', analyst)
    }
    const handleManager = (value) => {
        setDocManager(value)
        var manager = value && value.key ? value.key : ''
        props.onChange('document_manager', manager)
    }
    function handleNotes(note) {
        setComments(note)
        var data = [{
            "text": note,
            "note_type": "Submit Decision",

        }]
        props.onAddComment(data)
    }


    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>

                        <Grid container >


                            <Grid
                                item
                                md={props.mode !== 'view' ? 8 : 12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '30%', padding: 10 }}>
                                        {/* <CardHeader
                                            title="DOCUMENT STATUS"
                                            titleTypographyProps={{ variant: 'h3' }}
                                            classes={{ root: classes.headerRoot }}
                                        /> */}
                                        {props.mode == 'view' ?
                                            <div style={{ marginTop: -10 }}>
                                                <LabelText classes={{ root: classes.fontSetting }} heading={'Document Status'} data={props.approvalStatusData.document_status} twoline='true' border={true} />
                                            </div>
                                            :
                                            <div style={{ marginTop: '0.2rem' }}>
                                                <DropdownArray heading={"Document Status"} twoline='true' onChange={(value) => handleStatusData(value)}
                                                    data={props.docStatus && props.mode != 'create' ? (props.docStatus.possible_statuses ? props.docStatus.possible_statuses : []) :
                                                        (props.docStatus && props.docStatus.status_description ? [props.docStatus.status_description] : [])}
                                                    prevalue={props.docStatus ? props.docStatus.status_description : ''}
                                                    style={{ paddingBottom: '0.3rem' }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div style={{ width: '35%', padding: 10 }}>
                                        {/* <CardHeader
                                            title={"DOCUMENT ANALYST*"}
                                            titleTypographyProps={{ variant: 'h3' }}
                                            classes={{ root: classes.headerRoot }}
                                        /> */}
                                        {props.mode == 'view' ?
                                            <div style={{ marginTop: -10 }}>
                                                <LabelText classes={{ root: classes.fontSetting }} heading={'Document Analyst'} data={docAnalyst && docAnalyst.desc} twoline='true' border={true} />
                                            </div>
                                            :
                                            <AutoCompleteDropdown heading={'Document Analyst'} data={props.fieldData && props.fieldData['document_analyst'] && props.fieldData['document_analyst'].drop_down_reference_value &&
                                                props.fieldData['document_analyst'].drop_down_reference_value.length > 0 ? props.fieldData['document_analyst'].drop_down_reference_value : []}
                                                prevalue={docAnalyst} id={'document_analyst'}
                                                onChange={(value) => handleAnalyst(value)} />
                                        }

                                    </div>
                                    <div style={{ width: '35%', padding: 10 }}>
                                        {/* <CardHeader
                                            title="DOCUMENT MANAGER*"
                                            titleTypographyProps={{ variant: 'h3' }}
                                            classes={{ root: classes.headerRoot }}
                                        /> */}
                                        {props.mode == 'view' ?
                                            <div style={{ marginTop: -10 }}>
                                                <LabelText classes={{ root: classes.fontSetting }} heading={'Document Manager'} data={docManager && docManager.desc} twoline='true' border={true} />
                                            </div>
                                            :
                                            <AutoCompleteDropdown heading={'Document Manager'} data={props.fieldData && props.fieldData['document_manager'] && props.fieldData['document_manager'].drop_down_reference_value &&
                                                props.fieldData['document_manager'].drop_down_reference_value.length > 0 ? props.fieldData['document_manager'].drop_down_reference_value : []}
                                                id={'document_manager'} onChange={(value) => handleManager(value)}
                                                prevalue={docManager}
                                            />
                                        }

                                    </div>
                                </div>

                            </Grid>
                            {props.mode !== 'view' && props.approvalStatusData?.approval_status === 'submit_approval' &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <CardHeader
                                        title="COMMENT"
                                        titleTypographyProps={{ variant: 'h3' }}
                                        classes={{ root: classes.headerRoot }}
                                    />

                                    <div style={{ width: '100%', paddingTop: 15 }}>
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            multiline
                                            fullWidth
                                            minRows={3}
                                            maxRows={4}
                                            variant='filled'
                                            value={comments}
                                            placeholder='Enter comments here...'
                                            onChange={(e) => handleNotes(e.target.value)}

                                        />
                                    </div>
                                </Grid>
                            }

                            {history && history.length > 0 && props.mode != 'create' &&
                                < Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DocHistory data={history} />
                                </Grid>
                            }
                        </Grid>
                        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

}

const mapStateToProps = state => ({
    docHistory: state.DocumentManagement.documentHistory
});

const mapDispatchToProps = dispatch => {
    return {
        getHistory: (data) => dispatch(fetchDocumentHistory(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ApprovalStatus);