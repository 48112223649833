import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput, FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { postCustomerMasterQuery, loadCustomerMasterFormatFields, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { DropdownArray } from '../../components/Inputs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const SalesInvoiceAddQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [supplierName, setSupplierName] = React.useState('');
    const [supplierType, setSupplierType] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [formatType, setFormatType] = React.useState('')
    const [headOffice, setHeadOffice] = React.useState('');
    const [purchaseOrg, setPurchaseOrg,] = React.useState('');
    const [alternatePayee, setAlternatePayee] = React.useState('');
    const [companyCode, setCompanyCode] = React.useState('');
    const [defaultValue, setDefaultValue] = React.useState({});
    const [sortedData, setSortedData] = React.useState([]);
    const requiredFields = [
        'supplier_name', 'supplier_type', 'street', 'city', 'country', 'region', 'postal_code',
        'supplier_number', 'head_office', 'purchase_org', 'alternate_payee', 'company_code'
    ]
    const formatTypeArray = [
        'Supplier Master Data',
        'Supplier Master Organization Data'
    ];
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const [queryObject, setQueryObject] = React.useState({});
    function handleFormatType(newValue) {
        setFormatType(newValue);
    };
    useEffect(() => {
        setOpen(true);
        props.loadSalesDataFormatFields(formatType);
    }, [formatType]);

    useEffect(() => {
         // if (formatType === 'Supplier Master Data') {
        var data = { "data_source": "supplier_master" };
        props.getLblDispDesFieldValue(data, 'post');
         // }
    }, [])
    useEffect(() => {
        if (formatType === 'Supplier Master Data') {
            var tempObj = []
            if (props.tableData) {
                Object.values(props.tableData.field_label_attributes).map(e => {

                    tempObj.push(e)

                })

                setSortedData(tempObj);
            }
        }

    }, [props.queryListData, props.tableData])

    function handleClear() {
            for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
        setDefaultValue({ ...defaultValue });
            }
    function handleOnSubmit() {
        delete queryObject['id'];
        for (var propName in queryObject) {
            if (queryObject[propName] === '' || queryObject[propName] === null || queryObject[propName] === undefined) {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit({ ...queryObject, 'format_name': queryName, 'default_values': defaultValue }, formatType);
    }
    function handleClearAll() {
    handleClear();
    setSupplierNumber('')
    setSupplierName('')
    setSupplierType('')
    setCity('')
    setStreet('')
    setPostalCode('')
    setCountry('')
    setState('')
    setHeadOffice('')
    setPurchaseOrg('')
    setAlternatePayee('')
    setCompanyCode('')
    }
    function handleCancelDialog() {
        history.push('/supplier-master/supplier-master-excel-upload');
    }
    const handleDefaultValue = (e) => {
        setDefaultValue(e.target.value);
    }
    const handleChange = (event, item) => {
        setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        if (item === 'supplier_number') {
            setSupplierNumber(event.target.value)
        }
        if (item === 'supplier_name') {
            setSupplierName(event.target.value)
        }
        if (item === 'supplier_type') {
            setSupplierType(event.target.value)
        }
        if (item === 'city') {
            setCity(event.target.value)
        }
        if (item === 'street') {
            setStreet(event.target.value)
        }
        if (item === 'postal_code') {
            setPostalCode(event.target.value)
        }
        if (item === 'country') {
            setCountry(event.target.value)
        }
        if (item === 'region') {
            setState(event.target.value)
        }
        if (item === 'head_office') {
            setHeadOffice(event.target.value)
        }
        if (item === 'purchase_org') {
            setPurchaseOrg(event.target.value)
        }
        if (item === 'alternate_payee') {
            setAlternatePayee(event.target.value)
        }
        if (item === 'company_code') {
            setCompanyCode(event.target.value)
        }
    };
    const handleChange2 = (event, item) => {
        const fieldMappings = {
            'supplier_number': setSupplierNumber,
            'supplier_name': setSupplierName,
            'supplier_type': setSupplierType,
            'city': setCity,
            'street': setStreet,
            'postal_code': setPostalCode,
            'country': setCountry,
            'region': setState,
            'head_office': setHeadOffice,
            'purchase_org': setPurchaseOrg,
            'alternate_payee': setAlternatePayee,
            'company_code': setCompanyCode,
        };
    
        setDefaultValue({ ...defaultValue, [item]: event.target.value });
    
        const setField = fieldMappings[item];
        if (setField) {
            setField(event.target.value);
    
            if (event.target.value.length === 0) {
                setField(" ");
            }
        }
    };
        return (
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/supplier-master/supplier-master-excel-upload'
                    >
                        Supplier Master Format
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Add Format</Typography>
                </Breadcrumbs>
                <Dialog
                    onClose={() => setOpen(false)}
                    aria-labelledby="simple-dialog-title"
                    open={open}
                    disableEscapeKeyDown
                    classes={{ paper: classes.paper }}>
                    <div style={{ padding: 20 }}>
                        <FormLabel className={classes.formLabel} required>
                            Format Name
                        </FormLabel>
                        <OutlinedInput
                            value={queryName}
                            classes={{ root: classes.inputTwoLine }}
                            onChange={handleQueryName} />
                        <FormLabel className={classes.formLabel} required >
                            Format Type
                        </FormLabel>
                        <DropdownArray
                            data={formatTypeArray}
                            placeholder='Please select format type'
                            twoline='true'
                            onChange={handleFormatType} />
                    </div>
                    <DialogActions>
                        <Button color="primary" onClick={handleCancelDialog}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleInitialSetup} disabled={queryName && formatType ? false : true}>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.container2}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {formatType === 'Supplier Master Data' ? (
                                    Object.values(sortedData) ?
                                        <div>
                                            <div style={{ margin: 15 }}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead >
                                                            <TableRow >
                                                                <TableCell align='center' className={classes.tabHead}>Field Name</TableCell>
                                                                <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                                <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody classes={{ root: classes.table }} >
                                                            {Object.values(sortedData)

                                                                .map((item, index) => {
                                                                    return (
                                                                        <TableRow key={item}>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                                {requiredFields.includes(item.key) ? <p>{item.current}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.current}

                                                                            </TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                                <TextField
                                                                                    value={queryObject[item]}
                                                                                    type="number"
                                                                                    variant="outlined"
                                                                                    onChange={(e) => handleChange(e, item.key)}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                                <TextField
                                                                                    value={defaultValue[item]}
                                                                                    variant="outlined"
                                                                                    onChange={(e) => handleChange2(e, item.key)}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                        :
                                        <Typography variant='h4'>
                                            There is no data to show now.
                                        </Typography>

                                ) : (
                                    props.queryListData ?
                                        <div>
                                            <div style={{ margin: 15 }}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead >
                                                            <TableRow >
                                                                <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                                <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                                <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody >
                                                            {props.queryListData
                                                                .filter(item => item != 'id' && item != 'format_name' && item != '')
                                                                .map((item) => {
                                                                    return (
                                                                        <TableRow>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                                {requiredFields.includes(item) ? <p>{item.replace(/_/g, ' ')}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                            </TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                                <TextField
                                                                                    value={queryObject[item]}
                                                                                    type="number"
                                                                                    variant="outlined"
                                                                                    onChange={(e) => handleChange(e, item)}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70, paddingLeft: 10 }}>
                                                                                <TextField
                                                                                    value={defaultValue[item]}
                                                                                    type="string"
                                                                                    variant="outlined"
                                                                                    onChange={(e) => handleChange2(e, item)}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                        :
                                        <Typography variant='h4'>
                                            There is no data to show now.
                                        </Typography>
                                )
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                            disabled={formatType === 'Supplier Master Organization Data' ? ((supplierNumber || defaultValue?.['supplier_number']) && (alternatePayee || defaultValue?.['alternate_payee']) && (headOffice || defaultValue?.['head_office']) && (companyCode || defaultValue?.['company_code']) && (purchaseOrg || defaultValue?.['purchase_org']) ? false : true) : (supplierName || defaultValue?.['supplier_name']) && (supplierNumber || defaultValue?.['supplier_number']) && (supplierType || defaultValue?.['supplier_type']) && (street || defaultValue?.['street']) && (state || defaultValue?.['region']) && (city || defaultValue?.['city'])
                                && (postalCode || defaultValue?.['postal_code']) && (country || defaultValue?.['country']) ? false : true}>
                            Submit
                        </Button>
                    </div>
                </div >
            </div>
        );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, formatType) => dispatch(postCustomerMasterQuery(data, null, null, 'SupplierMaster', formatType)),
        loadSalesDataFormatFields: (formatType) => dispatch(loadCustomerMasterFormatFields('SupplierMaster', formatType)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        queryListData: state.dataSetupData.customerMasterQueryFieldsData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceAddQuery);