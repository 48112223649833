import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Link, FormLabel, OutlinedInput, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Paper,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../components/Dialog';
import { runCalculationSimulation, getDefaultValues, runDynamicQuery, getDefaultValuesAllDataNew, getOrgDefaults } from '../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { useHistory } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';
import { PDFExport } from "@progress/kendo-react-pdf";
import { toast } from 'react-toastify';
import { Zoom } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '10px 45px 10px 45px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    spinner: {
        height: '100vh'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonContainer: {
        display: 'flex',
    },
    button: {
        marginLeft: 15
    },
}));

const DynamicQueryForm = props => {
    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [headingsToConvertToDollor, setHeadingToConvertToDollor] = React.useState(['flex_key_figure1', 'flex_key_figure2', 'flex_key_figure3', 'flex_key_figure4'
        , 'flex_key_figure5', 'flex_key_figure6', 'flex_key_figure7', 'flex_key_figure8', 'flex_key_figure9', 'flex_key_figure10', 'flex_key_figure11', 'flex_key_figure12', 'flex_key_figure13', 'flex_key_figure14', 'flex_key_figure15'
        , 'flex_key_figure16', 'flex_key_figure17', 'flex_key_figure18', 'flex_key_figure19', 'flex_key_figure20', 'net_value',
        'incentive_basis', 'tier_basis', 'incentive_amount', 'quantity', 'unit_price', 'incentive_basis1', 'incentive_basis2',
        'incentive_basis3', 'incentive_basis4', 'incentive_basis5', 'maximum_incentive_amount', 'incentive_calculated'
    ]);
    const contractCustomisationUpdation = useRef();
    const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
    const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = useState({});
    const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState('');
    const handleDynamicFieldValues = (e, fieldName, type) => {
        if (type === 'range') {
            if (dynamicFieldValues[fieldName] && dynamicFieldValues[fieldName].length > 0) {
                if (e.target.value) {
                    setDynamicFieldValuesRange(e.target.value)
                    setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [fieldName]: { "from": dynamicFieldValues[fieldName][0], "to": e.target.value } })
                } else {
                    setDynamicFieldValuesRange(e.target.value)
                    setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [fieldName]: dynamicFieldValues })
                }
            } else {
                setDynamicFieldValuesRange('');
            }
        } else {
            if (e.length == 0) {
                delete dynamicFieldValues[fieldName]
                setDynamicFieldValues({ ...dynamicFieldValues });
                delete dynamicFieldValuesFormSubmit[fieldName]
                setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit })
            } else {
                setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
                setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [fieldName]: e })
            }
        }
    };

    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [applicationTypeArray, setApplicationTypeArray] = React.useState([]);
    const [calculationSchemaArray, setCalculationSchemaArray] = React.useState([]);
    const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
    const [currentQueryID, setCurrentQueryID] = React.useState('');
    useEffect(() => {
        props.restDynamicQuery();
        props.onLoadingDefault();
        props.getOrgDefaults();
        if (sessionStorage.getItem("dynamic_filter_fields")) {
            var object = JSON.parse(sessionStorage.getItem("dynamic_filter_fields"))
            if (object.app_type)
                setApplicationType(object.app_type)
            if (object.source_type)
                setSourceDataType(object.source_type)
            if (object.fields)
                setFilterFieldsArray(object.fields);
            if (object.id)
                setCurrentQueryID(object.id);
        }
        if (object && object.app_type && object.source_type)
            props.onLoadingLabelDesignerNew('field-label-designer', object.app_type, object.source_type)
    }, []);
    const [orgDefaults, setOrgDefaults] = React.useState({});

    useEffect(() => {
        if (props.orgDefaults && Object.keys(props.orgDefaults).length > 0) {
            setOrgDefaults(props.orgDefaults);
        }
    }, [props.orgDefaults])
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'application_type') {
                            setApplicationTypeArray(item);
                        }
                        if (item.field_id === 'calculation_schema') {
                            setCalculationSchemaArray(item);
                        }
                    })
                }
            }
        }
    });
    const [calculationPeriodStart, setCalculationPeriodStart] = useState('');
    const [calculationPeriodEnd, setCalculationPeriodEnd] = useState('');
    const [applicationType, setApplicationType] = useState('');
    const [contractNumber, setContractNumber] = useState([]);
    const [contractType, setContractType] = useState('');
    const [contractSubtype, setContractSubtype] = useState('');
    const [sourceDataType, setSourceDataType] = useState('');
    const [open, setOpen] = useState(false);
    const formData = {
        "application_type": sessionStorage.getItem('application'),
        "contract_number": contractNumber,
        "contract_type": contractType,
        "calculation_schema": contractSubtype,
        "calculation_period_start": Moment.utc(calculationPeriodStart),
        "calculation_period_end": Moment.utc(calculationPeriodEnd),
    };
    const startRef = useRef(null);
    const endRef = useRef(null);
    const typeRef = useRef(null);
    const subTypeRef = useRef(null);
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setContractNumber([]);
        startRef.current.reset();
        endRef.current.reset();
        typeRef.current.reset();
        subTypeRef.current.reset();
    }
    const [clickRun, setClickRun] = React.useState(false);
    const handleRun = () => {
        setClickRun(true);
        props.getOrgDefaults();
        if (dynamicFieldValuesFormSubmit) {
            var formData = {
                id: currentQueryID.toString(),
                filter_fields: dynamicFieldValuesFormSubmit
            }
            sessionStorage.setItem('dyamicQueryDownloadData', JSON.stringify(formData));
        } else {
            var formData = {
                id: currentQueryID.toString()
            }
            sessionStorage.setItem('dyamicQueryDownloadData', JSON.stringify(formData));
        }

        var data = {
            "id": currentQueryID.toString(),
            "filter_fields": dynamicFieldValuesFormSubmit
        }
        props.runDynamicQuery(data, currentQueryID);
    };
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })
    }
    const [tableHeaders, setTableHeaders] = React.useState([]);
    const [sortedTableHeader, setSortedTableHeader] = React.useState([]);
    useEffect(() => {
        if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
            var singleValue = props.queryResultsFinalData[0][0];
            setTableHeaders(Object.keys(singleValue));
            var lastElement = props.queryResultsFinalData[0][props.queryResultsFinalData[0].length - 1].sortOrder
            setSortedTableHeader(lastElement);
        }
    }, [props.queryResultsFinalData]);
    const [sumField, setSumField] = React.useState({});
    useEffect(() => {
        if (props.queryResultsFinalData) {
            var sum = props.queryResultsFinalData.map(e => {
                if (e.total_sum) {
                    setSumField(e.total_sum);
                }
            })
        }
    }, [props.queryResultsFinalData])

    useEffect(() => {
        if (orgDefaults && Object.keys(orgDefaults).length > 0 && clickRun && sortedTableHeader && sortedTableHeader.length > 0 && props.queryResultsFinalData && props.queryResultsFinalData[0] && props.queryResultsFinalData[0].length > 0) {
            toast.info('Download will start soon.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                transition: Zoom
            });
            if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
                setClickRun(false);
            }
        }

    }, [props.queryResultsFinalData, clickRun, sortedTableHeader, props.orgDefaults])
    const pdfExportComponent = React.useRef(null);
    return (
        <LoadingOverlay
            active={props.loading}
            spinner
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            text='Loading results...'
            className={classes.spinner}
        >
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/partner-statement')}
                >
                    Partner Statement
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp; Partner Statement Form</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h2"> Partner Statement Form </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            {filterFieldsArray && filterFieldsArray
                                .map(item => {
                                    return (
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel style={{ textTransform: 'capitalize' }}>
                                                {item && checkLabel(item) && Array.isArray(checkLabel(item)) && checkLabel(item).filter(item => item).length === 0 ? item.replace(/_/g, ' ') : checkLabel(item)}

                                            </FormLabel>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%', marginRight: 5 }}>
                                                    <ChipInput
                                                        classes={{
                                                            root: classes.rootContainer,
                                                            chip: classes.chip,
                                                            input: classes.input,
                                                            inputRoot: classes.inputRoot,
                                                            label: classes.chipLabel,
                                                            chipContainer: classes.chipContainer,
                                                        }}
                                                        onChange={(e) => handleDynamicFieldValues(e, item)}
                                                        disableUnderline={true}
                                                    />
                                                </div>
                                                <div style={{ width: '50%', marginLeft: 5 }}>
                                                    <OutlinedInput
                                                        classes={{ root: classes.inputTwoLine }}
                                                        type={props.type}
                                                        value={dynamicFieldValues[item] && dynamicFieldValues[item].length > 0 ? dynamicFieldValuesRange[item] : ''}
                                                        onChange={(e) => handleDynamicFieldValues(e, item, 'range')}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={handleRun}
                                twoline='true'>
                                Run
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to simulate the calculation results?' handleDialog={handleDialog} />
            </div>
            {sortedTableHeader && sortedTableHeader.length > 0 && props.queryResultsFinalData && props.queryResultsFinalData[0] && props.queryResultsFinalData[0].length > 0 &&
                <div
                    style={{
                        position: "absolute",
                        left: "-1000000000px",
                        top: "-1000000000px",
                    }}
                >
                    <PDFExport
                        margin="2cm"
                        ref={pdfExportComponent}>
                        <Paper className={classes.root} >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <img
                                    src={require('../../library/images/finalWhite.jpg')}
                                    alt='' width={100} />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <div style={{ padding: '5px 0px 5px' }}>Address</div>
                                    </div>
                                    <div style={{ width: 200, marginLeft: 20 }}>
                                        <div style={{ padding: '5px 0px 5px' }}>{orgDefaults && orgDefaults.address ? orgDefaults.address : '-Please define address from settings-'}</div>
                                    </div>
                                </div>
                                <h3 style={{ color: '#4C657D' }}>{orgDefaults && orgDefaults.headerText ? orgDefaults.headerText : '-Please define header from settings-'}</h3>

                            </div>
                            <div>
                                <TableContainer >
                                    <Table>
                                        <TableHead >
                                            <TableRow>
                                                {sortedTableHeader && sortedTableHeader.length > 0 && sortedTableHeader
                                                    .map(item => {
                                                        return (
                                                            <TableCell className={classes.cell} style={{ fontSize: 10 }}>
                                                                {item && checkLabel(item) && Array.isArray(checkLabel(item)) && checkLabel(item).filter(item => item).length === 0 ? item.replace(/_/g, ' ') : checkLabel(item)}
                                                            </TableCell>
                                                        );
                                                    })
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {props.queryResultsFinalData[0] && props.queryResultsFinalData[0].length > 0 && props.queryResultsFinalData[0]
                                                .slice(0, 5)
                                                .filter((element, index) => index < props.queryResultsFinalData[0].length - 1)
                                                .map((row) => (
                                                    <TableRow>
                                                        {sortedTableHeader && sortedTableHeader.length > 0 && sortedTableHeader
                                                            .map((heading) =>
                                                                <TableCell>{
                                                                    row[heading].indexOf('Z') > -1 && row[heading].indexOf('T') > -1 && Moment(row[heading], true).isValid()
                                                                        ? Moment.utc(row[heading]).format('MM/DD/YYYY') :
                                                                        (headingsToConvertToDollor.includes(heading)) ? parseFloat(row[heading]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : row[heading]
                                                                }</TableCell>)}
                                                    </TableRow>
                                                ))}
                                            <TableRow className={classes.cell}>
                                                {sortedTableHeader && sortedTableHeader.length > 0 && sortedTableHeader.map((sh, index) => {
                                                    if (index == 1) {
                                                        return (<TableCell style={{ color: 'black' }}>
                                                            Total</TableCell>)

                                                    }
                                                    if (sumField[sh]) {
                                                        return (<TableCell style={{ color: 'black' }}>
                                                            {sumField[sh] ? parseFloat(sumField[sh]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>)
                                                    } else {
                                                        return (<TableCell style={{ color: 'black' }}></TableCell>)
                                                    }
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
                            <h4>{orgDefaults && orgDefaults.footerText ? orgDefaults.footerText : '-Please define footer from settings-'}</h4>
                        </div>
                    </PDFExport>
                </div>
            }
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runCalculationSimulation(formData)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        runDynamicQuery: (data) => dispatch(runDynamicQuery(data, null, null, 'paymentpartner')),
        restDynamicQuery: () => dispatch(runDynamicQuery(null, null, null, null, 'reset')),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getOrgDefaults: () => dispatch(getOrgDefaults())
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        queryResultsFinalData: state.operationalReportsData.queryResultsFinalData,
        orgDefaults: state.addMultipleConfigurationData.orgDefaults
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQueryForm);