import { useEffect, useRef } from "react";
import * as React from "react";
import { MapsTooltip, LayersDirective, LayerDirective, MapsComponent, Inject, Legend, Zoom, DataLabel } from '@syncfusion/ej2-react-maps';
import usa from "../../../Analytics/Charts/usa.json";
import Moment from "moment";
import {
    geographicalIncentive,
    loadValuesBasedOnAppTypeAndField,
    allowedApps, getGrossToNet, dashboardDashBoardNames
} from "../../../../redux/actions";
import { connect } from "react-redux";
import { endOfMonth, subMonths, startOfMonth } from "date-fns";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";


const SAMPLE_CSS = `
    .e-play-icon::before {
        content: "\\e34b";
    }

    .e-view.fluent .e-play-icon::before, .e-view.fluent-dark .e-play-icon::before {
        content: '\\e75d';
    }

    .e-view.fabric .e-play-icon::before, .e-view.fabric-dark .e-play-icon::before
    {
        content: '\\e7df';
    }

    .e-view.bootstrap .e-play-icon::before {
        content: '\\ebd2';
    }

    .e-view.bootstrap4 .e-play-icon::before {
        content: '\\e743';
    }

    .e-view.tailwind .e-play-icon::before, .e-view.tailwind-dark .e-play-icon::before {
        content: '\\e76c';
    }

    .e-view.highcontrast .e-play-icon::before {
        content: '\\ebf9';
    }

    .e-view.bootstrap5 .e-play-icon::before, .e-view.bootstrap5-dark .e-play-icon::before {
        content: '\\e75d';
    }`;

const GeographicalChartProfit = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "white",
            padding: "20px 30px 0px 30px",
            borderRadius: 10,
        },
        rootDiv: {
            backgroundColor: theme.palette.white,
            borderRadius: 10,
            margin: "16px 0px 16px 0px",
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 32px 0px 20px",
            alignItems: "center",
        },
        container: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
        select: {
            width: "100%",
            color: "#1675e0",
            backgroundColor: "white",
        },
        formLabel: {
            width: '100%',
            paddingLeft: 20,
            marginTop: "-10px",
            fontSize: theme.typography.h1.fontSize,
            display: 'flex',
            justifyContent: 'center',

        },
    }));

    let mapInstance = useRef(null);
    let colorMap = [
        {
            from: -1000000,
            to: 0,
            color: '#64CCC5',
            label: '<0%',
        },
        {
            from: 0,
            to: 25,
            color: '#8E8FFA',
            label: '0% - 25%',
        },
        {
            from: 25,
            to: 50,
            color: '#A6FF96',
            label: '25% - 50%',
        },
        {
            from: 50,
            to: 75,
            color: '#3182bd',
            label: '50% - 75%',
        },
        {
            from: 75,
            to: 100,
            color: '#FA9884',
            label: '75% - 100%',
        },
        {
            color: '#b0cde1',

        }
    ];

    const onMapsLoad = () => {
        let maps = document.getElementById('maps');
        if (maps) {
            maps.setAttribute("title", "");
        }
    };

    const load = (args) => {
    };

    const tooltipRender = (args) => {
        if (!args.options.data) {
            args.cancel = true;
        }
    };

    const [offsets, setOffsets] = React.useState([]);
    const [allowedTilesFilter4, setAllowedTilesFilter4] = React.useState([]);
    const [grossNetData, setGrossNetData] = React.useState([]);
    const [tempData, setTempData] = React.useState('');
    const handleStartDate = (e) => {
        if (e && e.isValid()) {
            setStartDate(e);
        } else {
            setStartDate(null);
        }
        if (e && e.isValid())
            props.geographicalIncentive(
                Moment(e).format("MM/DD/YYYY"),
                Moment(endDate).format("MM/DD/YYYY"),
                postingType,
                applicationType,
                sourceDataType
            );
    };
    const handleEndDate = (e) => {
        if (e && e.isValid()) {
            setEndDate(e);
        } else {
            setEndDate(null);
        }
        if (e && e.isValid())
            props.geographicalIncentive(
                Moment(startDate).format("MM/DD/YYYY"),
                Moment(e).format("MM/DD/YYYY"),
                postingType,
                applicationType,
                sourceDataType
            );
    };

    useEffect(() => {
        if (props.grossNetData && props.grossNetData.length > 0) {
            const uniqueData = props.grossNetData.reduce((acc, curr) => {
                // Check if there's already an object with the same region
                const existingItemIndex = acc.findIndex(item => item.Region === curr.Region);
                if (existingItemIndex === -1) {
                    // If not found, add it to the accumulator array
                    acc.push(curr);
                }
                return acc;
            }, []);

            setGrossNetData(uniqueData);
            props.grossNetData.map(item => {
                if (item.keys) {
                    setTempData(item.keys.margin_price_percentage)
                }
            })
        }
        else
            setGrossNetData([])
    }, [props.grossNetData])
    console.log("t", props.grossNetData)
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [dateRangeConvertedStart, setDateRangeConvertedStart] =
        React.useState("");
    const [dateRangeConvertedEnd, setDateRangeConvertedEnd] = React.useState("");
    const [dateRangeValue, setDateRangeValue] = React.useState([
        new Date("2020-06-01T05:30:00"),
        new Date("2020-10-01T05:30:00"),
    ]);
    const classes = useStyles();
    useEffect(() => {
        setStartDate(
            Moment().subtract(2, "months").startOf("month").format("MM/DD/YYYY")
        );
        setEndDate(
            Moment().subtract(1, "months").endOf("month").format("MM/DD/YYYY")
        );
        setSourceDataType("Direct - Sales Data");
        setDateRangeValue([
            startOfMonth(subMonths(new Date(), 2)),
            endOfMonth(subMonths(new Date(), 1)),
        ]);
        setDateRangeConvertedStart(
            Moment(startOfMonth(subMonths(new Date(), 2))).format("MM/DD/YYYY")
        );
        setDateRangeConvertedEnd(
            Moment(startOfMonth(subMonths(new Date(), 1))).format("MM/DD/YYYY")
        );
        if (props.page === "dashboard")
            props.geographicalIncentive(
                Moment().subtract(2, "months").startOf("month").format("MM/DD/YYYY"),
                Moment().subtract(1, "months").endOf("month").format("MM/DD/YYYY"),
                "Accrual,Accrual Reversal",
                "Customer Rebate",
                "Direct - Sales Data"
            );
    }, []);

    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Margin-Regional Analysis" && item.dashboard_name === "Default Dashboard"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter4([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter4([]);
            }
        }
    }, [props.allowedDashBoardData])

    useEffect(() => {
        if (allowedTilesFilter4 && allowedTilesFilter4.length > 0) {
            var data = {
                "formula_name": allowedTilesFilter4[0].formula_name,
                "start_date": allowedTilesFilter4[0].start_date,
                "end_date": allowedTilesFilter4[0].end_date,
                "simulation_category": allowedTilesFilter4[0].simulation_category,
                "attributes": ['region'],
            }
            props.onSubmit(data)
        }
    }, [allowedTilesFilter4]);
    useEffect(() => {
        props.allowedApps();
        // var data = {
        //     "formula_name": "Distribution - Sell Side Economics",
        //     "start_date": "2023-11-01T00:00:00.000Z",
        //     "end_date": "2023-11-30T23:59:59.000Z",
        //     "simulation_category": "Historical - Actual",
        //     "attributes": ['region'],
        // }
        // props.onSubmit(data)
    }, []);

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData]);

    const [postingType, setPostingType] = React.useState("Accrual");
    const handlePostingType = (e) => {
        setPostingType(e.target.value);
        if (sourceDataType)
            props.geographicalIncentive(
                Moment(startDate).format("MM/DD/YYYY"),
                Moment(endDate).format("MM/DD/YYYY"),
                e.target.value,
                applicationType,
                sourceDataType
            );
    };

    const [sourceDataType, setSourceDataType] = React.useState("");
    const [applicationType, setApplicationType] =
        React.useState("Customer Rebate");
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] =
        React.useState([]);
    useEffect(() => {
        if (
            props.sourceDataTypeValue &&
            Object.keys(props.sourceDataTypeValue).length > 0
        ) {
            setSourceDataTypeDataFromAPI(
                props.sourceDataTypeValue["drop_down_value_keys"]
            );
        }
    }, [props.sourceDataTypeValue]);
    useEffect(() => {
        props.loadSourceDataType("Customer Rebate", "source_data_type");
    }, []);

    return (
        <div className={classes.root}>
            <Typography color="primary" className={classes.formLabel} >Regional Analysis </Typography>
            <Grid container className={classes.root}>
                {grossNetData && grossNetData.length > 0 ?
                    <div>
                        <style>{SAMPLE_CSS}</style>
                        <div className='control-section row'>
                            <div className='col-md-12'>
                                <MapsComponent id="mapss" height="525" tooltipRender={tooltipRender} loaded={onMapsLoad} load={load} allowPrint={true} ref={mapInstance}
                                    useGroupingSeparator={true} format={"n"} legendSettings={{
                                        visible: true, mode: 'Interactive', position: 'Bottom', height: '10', width: '80%', title: 'Net Value',
                                        labelDisplayMode: 'Trim', alignment: 'Center', textStyle: { color: '#757575' }
                                    }} zoomSettings={{ enable: true, enablePanning: true }}
                                >
                                    <Inject services={[Legend, MapsTooltip, Zoom, DataLabel]} />
                                    <LayersDirective>
                                        <LayerDirective shapeData={usa} shapePropertyPath='iso_3166_2' shapeDataPath='Region' dataSource={grossNetData} tooltipSettings={{
                                            visible: 'point.Region' ? true : false, valuePath: 'Base Price',
                                            format: 'State :  ${name} <br> Base Price :  ${Base Price} <br> Cost : ${Cost} <br> Margin % :  ${Margin %} <br> Margin Target % :  ${Margin Target %} <br> Net Margin :  ${Net Margin} <br>'
                                        }}
                                            shapeSettings={{ colorValuePath: 'Margin %', fill: '#FBFFB1', colorMapping: colorMap }}
                                            dataLabelSettings={{
                                                visible: true,
                                                labelPath: 'iso_3166_2',
                                                smartLabelMode: 'Trim'
                                            }}
                                        />
                                    </LayersDirective>
                                </MapsComponent>

                            </div>
                        </div>
                    </div>
                    :
                    <Typography variant='h4' style={{ marginTop: '5rem' }} >
                        No Data
                    </Typography>
                }
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        // geographicalIncentiveData: state.initialData.georgraphicalIncentive,
        grossNetData: state.profitOptimizationData.grossNetData,
        sourceDataTypeValue:
            state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // geographicalIncentive: (s, e, postingType, applicationType, sourceDataType) => dispatch(geographicalIncentive(s, e, postingType,
        //     applicationType, sourceDataType)),
        onSubmit: (data) => dispatch(getGrossToNet(data, "driver_category")),
        loadSourceDataType: (applicationType, fieldId) =>
            dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps()),
        // allowedDashBoardApps: (type) => dispatch(dashboardDashBoardNames(type, "dashboard")),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeographicalChartProfit);
