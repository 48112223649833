import React, { useEffect, useRef } from 'react';
import {
    Grid, Card,
    CardHeader,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../components/Inputs';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const GeneralData = props => {
    const classes = useStyles();
    const { className } = props;
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [materialMasterData, setMaterialMasterData] = React.useState([]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        setMaterialMasterData(props.masdata);
    }, [props.masdata]);

    return (
        <div>
            <div className={clsx(classes.root, className)}>
                {materialMasterData &&
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <CardHeader
                                title="HEADER FIELDS"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <div className={classes.container}>
                                <Grid container className={classes.gridContainer}>
                                    {materialMasterData.material_number &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_number'] ? fieldLabel['material_number']['current'] : 'Material Number'} data={materialMasterData.material_number} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_description &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_description'] ? fieldLabel['material_description']['current'] : 'Material Description'} data={materialMasterData.material_description} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_type &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_type'] ? fieldLabel['material_type']['current'] : 'Material Type'} data={materialMasterData.material_type} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.supplier_name &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['current'] : 'Supplier Name'} data={materialMasterData.supplier_name} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.supplier_material_number &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_material_number'] ? fieldLabel['supplier_material_number']['current'] : 'Supplier Material Number'} data={materialMasterData.supplier_material_number} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.base_uom &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['base_uom'] ? fieldLabel['base_uom']['current'] : 'Base UoM'} data={materialMasterData.base_uom} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.external_material_group &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['external_material_group'] ? fieldLabel['external_material_group']['current'] : 'External Material Group'} data={materialMasterData.external_material_group} twoline='true' />
                                        </Grid>
                                    }
                                    {/* {materialMasterData.flex_attribute1 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} data={materialMasterData.flex_attribute1} twoline='true' />
                            </Grid>
                        }
                        {materialMasterData.flex_attribute2 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} data={materialMasterData.flex_attribute2} twoline='true' />
                            </Grid>
                        } */}
                                    {materialMasterData.material_status &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_status'] ? fieldLabel['material_status']['current'] : 'Material Status'} data={materialMasterData.material_status} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.supplier_number &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['supplier_number'] ? fieldLabel['supplier_number']['current'] : 'Supplier Number'} data={materialMasterData.supplier_number} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_status &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_status'] ? fieldLabel['material_status']['current'] : 'Material Status'} data={materialMasterData.material_status} twoline='true' />
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        </form>
                    </Card>
                }

            </div>
            <div className={clsx(classes.root, className)}>
                {materialMasterData &&
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <CardHeader
                                title="GROUPINGS AND IDENTIFIERS"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <div className={classes.container}>
                                <Grid container className={classes.gridContainer}>
                                    {materialMasterData.product_hierarchy &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['product_hierarchy'] ? fieldLabel['product_hierarchy']['current'] : 'Product Hierarchy'} data={materialMasterData.product_hierarchy} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_group &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_group'] ? fieldLabel['material_group']['current'] : 'Material Group'} data={materialMasterData.material_group} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_group1 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_group1'] ? fieldLabel['material_group1']['current'] : 'Material Group 1'} data={materialMasterData.material_group1} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_group2 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_group2'] ? fieldLabel['material_group2']['current'] : 'Material Group 2'} data={materialMasterData.material_group2} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_group3 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_group3'] ? fieldLabel['material_group3']['current'] : 'Material Group 3'} data={materialMasterData.material_group3} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_group4 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_group4'] ? fieldLabel['material_group4']['current'] : 'Material Group 4'} data={materialMasterData.material_group4} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.material_group5 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['material_group5'] ? fieldLabel['material_group5']['current'] : 'Material Group 5'} data={materialMasterData.material_group5} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.id_number01 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'Id Number 1'} data={materialMasterData.id_number01} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.id_number02 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'Id Number 2'} data={materialMasterData.id_number02} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.id_number03 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'Id Number 3'} data={materialMasterData.id_number03} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.id_number04 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'Id Number 4'} data={materialMasterData.id_number04} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.id_number05 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'Id Number 5'} data={materialMasterData.id_number05} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.id_number06 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'Id Number 6'} data={materialMasterData.id_number06} twoline='true' />
                                        </Grid>
                                    }
                                </Grid>

                            </div>
                        </form>
                    </Card>
                }
            </div>
            {materialMasterData && (materialMasterData.cost !== 0 || materialMasterData.msrp !== 0 || materialMasterData.pricing_inventory_alert_qty !== 0 || materialMasterData.flex_attribute1 !== "" || materialMasterData.flex_attribute2 !== "" || materialMasterData.flex_attribute3 !== "" || materialMasterData.flex_attribute4 !== "") && (
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <CardHeader
                                title="IMA360 DATA"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <div className={classes.container}>
                                <Grid container className={classes.gridContainer}>
                                    {materialMasterData.cost && materialMasterData.cost != 0 ?
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['cost'] ? fieldLabel['cost']['current'] : 'Cost'} data={materialMasterData.cost} twoline='true' />
                                        </Grid>
                                        : ""
                                    }
                                    {materialMasterData.msrp && materialMasterData.msrp != 0 ?
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['msrp'] ? fieldLabel['msrp']['current'] : 'MSRP'} data={materialMasterData.msrp} twoline='true' />
                                        </Grid>
                                        : ""
                                    }
                                    {materialMasterData.pricing_inventory_alert_qty && materialMasterData.pricing_inventory_alert_qty != 0 ?
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['current'] : 'Pricing Inventory Alert Qty'} data={materialMasterData.pricing_inventory_alert_qty} twoline='true' />
                                        </Grid>
                                        : ""
                                    }
                                    {materialMasterData.flex_attribute1 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} data={materialMasterData.flex_attribute1} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.flex_attribute2 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} data={materialMasterData.flex_attribute2} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.flex_attribute3 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'} data={materialMasterData.flex_attribute3} twoline='true' />
                                        </Grid>
                                    }
                                    {materialMasterData.flex_attribute4 &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'} data={materialMasterData.flex_attribute4} twoline='true' />
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div>
            )}
        </div>
    );
}
const mapStateToProps = state => {
    return {
        // materialMasterData: state.customerData.materialMasterViewData
    }
}
export default connect(mapStateToProps, null)(GeneralData);