import React, { useEffect, forwardRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    useMediaQuery, Button, IconButton, Fab, Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { AddBoxOutlined, DeleteForever, CloudUpload, SearchSharp, Edit } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import secureLocalStorage from 'react-secure-storage';
import { getMaterialPricingData, deleteMaterialPricingData, } from '../../redux/actions/Promotion/AuthAction';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const MaterialPricing = props => {

    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const page = history.location.state;
    const [dataRows, setDataRows] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const columns = [
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.material_number}</a>
        },
        {
            field: 'country',
            title: 'Country',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.country}</a>

        },
        {
            field: 'currency',
            title: 'Currency',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.currency}</a>
        },
        {
            field: 'dealer_cost',
            title: 'Dealer Cost',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.dealer_cost}</a>
        },
        {
            field: 'msrp',
            title: 'Msrp',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.msrp}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_coverage_cost-' + sessionStorage.getItem('application')) &&
                        <>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editMaterialPricing(rowData.id)}
                                size="large">
                                <Edit color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => props.deleteMaterialPricing(rowData.id)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>
                        </>
                    }
                </div>
        },
    ];

    // useEffect(() => {
    //     props.getMaterialPricingData();
    // }, []);

    useEffect(() => {
        var tempRows = [];
        if (page === "MaterialPricing" && props.materialPricingData && props.materialPricingData.records && props.materialPricingData.records.length > 0) {
            props.materialPricingData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        material_number: e.material_number,
                        country: e.country,
                        currency: e.currency,
                        dealer_cost: e.dealer_cost,
                        msrp: e.msrp
                    });
                })
            setDataRows(tempRows);
        }
    }, [props.materialPricingData]);

    function editMaterialPricing(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/material-pricing/edit-material/' + id,
            state: 'editMaterialPricing',
            id: id
        });
        localStorage.setItem('materialPricingEditId', id);
    }

    // console.log(props.materialPricingData)
    const addContract = () => {
        localStorage.removeItem('currentAddContractID');
        sessionStorage.setItem('mode', 'add');
    }

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'> Material Pricing </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_coverage_cost-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/material-pricing/excel-upload'
                                onClick={addContract}
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                component={CustomRouterLink}
                                to='/material-pricing/search'
                            >
                                <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                            </Fab>
                        </div>}
                </div>
                {page === "MaterialPricing" && dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div className={classes.noDataContainer}>
                        Display Material Pricing by clicking on Search Icon.
                    </div>
                }
            </LoadingOverlay >
        </div >

    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        materialPricingData: state.promotionData.promotionsSearchData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMaterialPricingData: () => dispatch(getMaterialPricingData()),
        deleteMaterialPricing: (id) => dispatch(deleteMaterialPricingData(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialPricing);