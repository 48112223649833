import React from 'react';
import { ExternalAccuralPostingForm } from './containers';

const ExternalAccuralPosting = props => {
    const [accrualData, setAccrualData] = React.useState();
    return (
        <ExternalAccuralPostingForm setNewPayload={setAccrualData}/>
    );

};

export default ExternalAccuralPosting;