import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions,
    Select,
    MenuItem
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { getPriceMaintenanceFields,getPriceFieldValues, createPriceMaintenanceUpload, getDriverFormatFields, addFormatData} from '../../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 },
    select: {
        width: '100%',

    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
}));

const PriceListDataAddQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName.trim() ,'price_list_number': priceListID,'mandatory': true});
        
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [priceListID, setPriceListID] = React.useState('');
    const [priceListArray, setPriceListArray] = React.useState([]);
    const [fieldLength, setFieldLength] = React.useState(0);
    const [queryObject, setQueryObject] = React.useState({});
    const [valid, setValid] = React.useState(false);

    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }

    useEffect(() => {
        setOpen(true);
        props.getFieldValues()
    }, []);
    function handleOnSubmit() {
        delete queryObject['id'];
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === "") {
                delete queryObject[propName];
            }
        }
        props.onSubmit(queryObject);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
       
    }
    function handleCancelDialog() {
        history.push('/driver-maintenance/driver-maintenance-excel-upload');
    }
    function CheckValid(item){
        var data=Object.entries(queryObject).filter(([k,v])=>k!=item&&k!='mandatory'&& k!='format_name'&& k!='price_list_number')
        if(((props.maintenanceFields.value).length!=Object.entries(data).length+1)||
        ((Object.entries(queryObject).filter(([k,v])=>k!=item&&k!='mandatory'&& k!='format_name'&& k!='price_list_number'&&isNaN(v)).length>0)))
            setValid(false)
        else
            setValid(true)
    }
    const handleChange = (event, item) => {
        setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        if(event.target.value){
            CheckValid(item)
        }
         else
         setValid(false)

    };
    useEffect(() => {
        if (props.priceListId)
            setPriceListArray(props.priceListId)
    }, [props.priceListId])
    useEffect(() => {
        if (props.maintenanceFields&&props.maintenanceFields.value)
            setFieldLength(props.maintenanceFields.value.length)
    }, [props.maintenanceFields])
    function handlePriceList(value) {
        setPriceListID(value)
        props.getPriceMaintenanceFields(value)
    }
    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>Add Format</Typography>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <Grid container spacing={2} style={{ padding: '16px 25px 16px 25px' }}>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <FormLabel className={classes.formLabel} required>
                            Format Name
                        </FormLabel>
                        <OutlinedInput
                            value={queryName}
                            classes={{ root: classes.inputTwoLine }}
                            onChange={handleQueryName} />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName  ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}

                        >
                            {props.maintenanceFields &&props.maintenanceFields ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.maintenanceFields
                                                        .filter(item => item != 'format_name' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                         <p>{item.replace(/_/g, ' ')}</p>                                                                      
                                                                    </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item]}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit} //disabled={!valid?true:false}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
       onSubmit: (data) => dispatch(addFormatData(data)),
        getFieldValues: () => dispatch(getDriverFormatFields()),
        getPriceMaintenanceFields: (priceListId) => dispatch(getPriceMaintenanceFields(priceListId)),
       
    }
}

const mapStateToProps = state => {
    return {
        priceListId: state.pricingData.priceTypeNameList,
        maintenanceFields: state.profitOptimizationData.driverFormatFields,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListDataAddQuery);