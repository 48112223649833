import React, { useRef, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    OutlinedInput,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, DropdownArray, Dropdown, ChipInput } from '../../components/Inputs';
import SimpleDialog from '../../components/Dialog';
import { buildForecast, getDefaultValues } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import LoadingOverlay from 'react-loading-overlay';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: -2,
        marginTop: 17,
        height: 36,
        padding: 0,
        // marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        // display: 'flex',
        // alignItems: 'center',
        // overflowX: 'overlay',
        // flexFlow: 'unset'
        marginTop: 9
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const BuildForecastData = props => {
    const classes = useStyles();
    const { className } = props;
    const contractCustomisationUpdation = useRef();
    const [radioGroupValue, setRadioGroupValue] = React.useState('');

    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');

    useEffect(() => {
        props.onLoadingDefault();
    }, []);

    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [customerNumber, setCustomerNumber] = useState([]);
    const [materialNumber, setMaterialNumber] = useState([]);
    const [transactionType, setTransactionType] = React.useState('');

    const [open, setOpen] = useState(false);
    const formData = {
        "filters": {
            "billing_date": [billingDateStart ? Moment.utc(billingDateStart).format('YYYY-MM-DD') : '', billingDateEnd ? Moment.utc(billingDateEnd).format('YYYY-MM-DD') : ''],
            "customer_number": customerNumber,
            "material_number": materialNumber,
            "transaction_type": [transactionType],
        }
    };
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setTransactionType();
    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    function handleCustomerNumber(newValue) {
        // setCustomerNumber([...customerNumber, newValue]);
        setCustomerNumber(newValue);
    }
    function handleMaterialNumber(newValue) {
        // setMaterialNumber([...materialNumber, newValue]);
        setMaterialNumber(newValue)
    }
    function handleDeleteChip(value, index, field) {
        if (field == 'Customer Number') {
            var deletedCustomerNumber = customerNumber.filter(item => item !== value)
            setCustomerNumber(deletedCustomerNumber)
        }
        else {
            var deletedMaterialNumber = materialNumber.filter(item => item !== value)
            setMaterialNumber(deletedMaterialNumber)
        }
    }
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleTransactionType = (value) => {
        setTransactionType(value);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 18 }}> Build Forecast Data</Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div className={classes.contractContiner}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required>
                                        Transaction Type
                                    </FormLabel>
                                    <Select
                                        value={transactionType}
                                        onChange={(e) => handleTransactionType(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }} >
                                        <MenuItem value={'INV'} key={'INV'}>
                                            INV
                                        </MenuItem>
                                        <MenuItem value={'SO'} key={'SO'}>
                                            SO
                                        </MenuItem>
                                        <MenuItem value={'DEL'} key={'DEL'}>
                                            DEL
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                            {transactionType === 'INV' &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel >Billing Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 9,
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '98%',
                                                                height: 38,
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={handleBillingDateStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            {/* </div>
                                        <div className={classes.contractContiner}> */}
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 9,
                                                                border: '1px solid #E0E0E0',
                                                                width: '98%',
                                                                marginLeft: 10,
                                                                height: 37,
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={handleBillingDateEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart ? billingDateStart : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                </Grid>
                            }
                            {transactionType === 'SO' &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel >Sales Order Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 9,
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '98%',
                                                                height: 38
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={handleBillingDateStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            {/* </div>
                                        <div className={classes.contractContiner}> */}
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 9,
                                                                border: '1px solid #E0E0E0',
                                                                width: '98%',
                                                                marginLeft: 10,
                                                                height: 37,
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={handleBillingDateEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart ? billingDateStart : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                </Grid>
                            }
                            {transactionType === 'DEL' &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel >Delivery Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 9,
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '98%',
                                                                height: 38
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={handleBillingDateStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            {/* </div>
                                        <div className={classes.contractContiner}> */}
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 9,
                                                                border: '1px solid #E0E0E0',
                                                                width: '98%',
                                                                marginLeft: 10,
                                                                height: 37
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={handleBillingDateEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart ? billingDateStart : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                </Grid>
                            }
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <Typography classes={{ root: classes.fontSetting }} className={classes.contractContiner}> Customer Number </Typography>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerNumber}
                                    onAdd={(chips) => handleCustomerNumber(chips)}
                                    onDelete={(chip, index) => handleDeleteChip(chip, index, 'Customer Number')}
                                    disableUnderline={true}
                                /> */}
                                <div className={classes.chipContainer}>
                                    <ChipInput data={customerNumber} onChange={handleCustomerNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginBottom: 10 }}
                            >
                                <Typography classes={{ root: classes.fontSetting }} className={classes.contractContiner}> Material Number</Typography>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleMaterialNumber(chips)}
                                    onDelete={(chip, index) => handleDeleteChip(chip, index, 'Material Number')}
                                    disableUnderline={true}
                                /> */}
                                <div className={classes.chipContainer}>
                                    <ChipInput data={materialNumber} onChange={handleMaterialNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="non-processed"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Process New Records </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Process All Records </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={transactionType
                                    ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to map build sales forecast data?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (file) => dispatch(buildForecast(file)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildForecastData);
