import React from 'react';
import {
    Typography,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import ClaimConfigurationTab from './ClaimConfigurationTab';


const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 23,
        marginTop: 16
    },

}));


const ClaimConfigurationMain = props => {
    const classes = useStyles();
    const { className } = props;

    return (
        <div>
            <Grid container spacing={1} classes={{ root: classes.flexEnd }} >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="h1" color='primary' style={{ margin: '10px 0px 0px 20px' }}> Configuration</Typography>
                </Grid>
            </Grid>
            <div
                className={clsx(classes.root, className)}>
                <ClaimConfigurationTab />
            </div>
        </div >
    );

};

export default ClaimConfigurationMain;