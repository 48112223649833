import React, {useEffect} from 'react';
import {
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getPromotionMaterialData } from '../../../../../redux/actions';
import { connect } from 'react-redux';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    stickycolhead: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 0,
        backgroundColor: '#ffffff',
        width: 55
    },

    stickycolbody: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 0,
        backgroundColor: '#ffffff',
        width: 55,

    },
    stickycolhead1: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 55,
        backgroundColor: '#ffffff',
        maxWidth: 300,
        minWidth: 140,
        width: 200
    },

    stickycolbody1: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 55,
        backgroundColor: '#ffffff',

    },
    stickycolhead2: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 2,
        left: 210,
        backgroundColor: '#ffffff',
        minWidth: 300
    },

    stickycolbody2: {
        position: 'webkitSticky',
        position: 'sticky',
        zIndex: 1,
        left: 210,
        backgroundColor: '#ffffff',
        width: 150
    }
}));

const Region = (props) => {

    const classes = useStyles();
    const [regionData, setRegionData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (props.regionData && props.regionData.length > 0) {
            setRegionData(props.regionData)
        }
    }, [props.regionData])

    return (
        <div
            className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>

                        <Grid container spacing={2}
                            style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                        >
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow >

                                            <TableCell align='center' className={classes.stickycolhead}>Model Year</TableCell>
                                            <TableCell align='center' className={classes.stickycolhead1}>Market Segment</TableCell>

                                            {props.states && props.states.length > 0 && props.states
                                                .sort()
                                                .map(item => (
                                                    <TableCell align='center' style={{ minWidth: '50px' }}>{item}</TableCell>
                                                ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >

                                        {regionData && regionData
                                            .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                                            .map((itemX, index) => {
                                                return (
                                                    <StyledTableRow >

                                                        <TableCell align='center' className={classes.stickycolbody}>{itemX.material_group1}</TableCell>
                                                        <TableCell align='center' className={classes.stickycolbody1} style={{ width: '118px' }}> {itemX.material_group3}</TableCell>
                                                        

                                                        {props.states && props.states.length > 0 && props.states.map(item => (
                                                            itemX['promotion_state'] && itemX['promotion_state'].map((state, i) => {
                                                                if (itemX['promotion_state'][i] && itemX['promotion_state'][i]['state'] == item) {
                                                                    return (
                                                                        <TableCell align='center'>{state.state == item ? state.promotion_name : ''}

                                                                        </TableCell>
                                                                    )
                                                                }
                                                            })))
                                                        }

                                                    </StyledTableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Grid>
                        <TablePagination
                            rowsPerPageOptions={[10,15,20,25,30]}
                            component="div"
                            count={props.regionData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </form>
            </Card>
        </div >
    );

};

const mapStateToProps = state => ({
    materialData: state.promotionData.promotionMaterialData
});

const mapDispatchToProps = dispatch => {
    return {

        getMaterialData: () => dispatch(getPromotionMaterialData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(Region);