import React, { useEffect, forwardRef } from 'react';
import {
    Grid, Button, Typography, Card,
    Table, TableCell, TableHead, TableRow, TextField,
    TableContainer, TableBody, CardHeader, IconButton,
    OutlinedInput, MenuItem, Select, FormLabel,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import Moment from 'moment';
import momentt from 'moment-timezone';
import { DropdownArray, SearchDropDownMasterDataNew, Datepicker } from '../../../../components/Inputs';
var deleteFlag = false;

momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26,

    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        marginTop: 52
    },
    container2: {
        padding: 10,

    },
    gridContainer: {
        //padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '20px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 3
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    formDiv: {
        height: 32,
        display: 'flex',
        alignItems: 'flex-end'
    }
}));

const ContractDependency = forwardRef((props, ref) => {

    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [validFrom, setValidFrom] = React.useState(null);
    const [validTo, setValidTo] = React.useState(null);
    const [adjustmentPeriod, setAdjustmentPeriod] = React.useState('');
    const [adjustmentContract, setAdjustmentContract] = React.useState('');
    const [adjustmentDriver, setAdjustmentDriver] = React.useState('');
    const [adjustedDriver, setAdjustedDriver] = React.useState('');
    const [adjustmentDriverList, setAdjustmentDriverList] = React.useState({});
    const [adjustedDriverList, setAdjustedDriverList] = React.useState({});
    const [adjustmentPeriodList, setAdjustmentPeriodList] = React.useState({});
    const [dependencyStore, setDependencyStore] = React.useState([]);

    const [eligibilityStore, setEligibilityStore] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [contractID, setContractID] = React.useState('');

    useEffect(() => {
        if (props.startDate)
            setValidFrom(props.startDate)
        if (props.endDate)
            setValidTo(props.endDate)

    }, [props.startDate, props.endDate])
    useEffect(() => {
        if (props.data) {
            if (props.data.contract_dependency_rules && props.data.contract_dependency_rules.length > 0)
                setDependencyStore(props.data.contract_dependency_rules);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.contractId) {
            setContractID(props.contractId.toString())
        }

    }, [props.contractId, props.description])
    function handleValidFrom(newValue) {
        if (newValue && newValue.isValid())
            setValidFrom(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setValidFrom(newValue);
    }
    function handleValidTo(newValue) {
        if (newValue && newValue.isValid())
            setValidTo(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setValidTo(newValue);
    }

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    const editContractDependency = (rule, index) => {
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setContractID(rule.contract_number);
        setAdjustmentPeriod(rule.adjustment_period);
        setAdjustmentContract(rule.adjustment_contract_number.toString());
        setValidFrom(rule.start_date);
        setValidTo(rule.end_date);
        setAdjustmentDriver(rule.adjustment_driver)
        setAdjustedDriver(rule.adjusted_driver)

    }

    function handleSubmit(id) {
        var data = {
            "adjustment_period": adjustmentPeriod,
            "adjustment_contract_number": adjustmentContract ? parseInt(adjustmentContract) : props.contractId,
            "adjustment_driver": adjustmentDriver,
            "adjusted_driver": adjustedDriver,
            "start_date": validFrom,
            "end_date": validTo
        };
        if (id || id === 0) {
            var editedArray = [...dependencyStore];
            editedArray[editIndex] = data;
            setDependencyStore(editedArray);
            props.onChange(editedArray)

        } else {
            setDependencyStore([...dependencyStore, data]);
            props.onChange([...dependencyStore, data])
        }
        handleRemove();
    }

    function handleRemove() {
        setEditMode(false);
        setContractID('');
        setAdjustmentPeriod('');
        setAdjustmentContract('')
        setAdjustmentDriver('');
        setAdjustedDriver('');
        if (validFrom != props.startDate)
            setValidFrom('');
        if (validTo != props.endDate)
            setValidTo('');
    }
    useEffect(() => {
        if (props.formFields && props.formFields.length > 0) {
            props.formFields
                .map((item) => {
                    if (item.field_id === 'adjustment_period') {
                        setAdjustmentPeriodList(item)
                    }
                    if (item.field_id === 'adjustment_driver') {
                        setAdjustmentDriverList(item)
                    }
                    if (item.field_id === 'adjusted_driver') {
                        setAdjustedDriverList(item)
                    }
                })
        }
    }, [props.formFields]);

    const handleContractNumber = (value) => {
        setAdjustmentContract(value)
    }


    const handleDeleteLocally = (index) => {
        deleteFlag = true;
        setDependencyStore(item => item.filter((item, i) => i !== index));
    }

    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...eligibilityStore];
            props.submitValues(edited);
            deleteFlag = false;
        }
    }, [eligibilityStore])

    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={1} >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    container spacing={1}
                                >

                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: '-0.2rem' }}
                                    >
                                        <div className={classes.selectRoot} >
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                Adjustment Period
                                            </FormLabel>
                                            <Select
                                                value={adjustmentPeriod}
                                                onChange={(e) => setAdjustmentPeriod(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value="" style={{ height: 30 }}>

                                                </MenuItem>
                                                {adjustmentPeriodList && adjustmentPeriodList.drop_down_values && adjustmentPeriodList.drop_down_values.map(item => {
                                                    return (
                                                        <MenuItem value={item} key={item} >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={7}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                Adjustment Contract Number
                                            </FormLabel>
                                            <div style={{ marginTop: -8 }}>
                                                <SearchDropDownMasterDataNew id='contract_header'
                                                    table={'contract_header'}
                                                    keyName={'contract_number'}
                                                    description={true}
                                                    onChange={(value) => handleContractNumber(value)}
                                                    prevalue={adjustmentContract}
                                                    applicationType={sessionStorage.getItem('application')}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    container spacing={1}
                                >

                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div style={{ paddingTop: 5 }} >
                                            <DropdownArray classes={{ root: classes.fontSetting }} heading={'Adjustment Driver'}
                                                data={adjustmentDriverList.drop_down_value_keys ? adjustmentDriverList.drop_down_value_keys : []} placeholder={' '} twoline='true'
                                                onChange={(value) => setAdjustmentDriver(value)} defaultValue={adjustmentDriver} capitalize required />
                                        </div>
                                    </Grid>


                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div style={{ paddingTop: 5 }} >
                                            <DropdownArray classes={{ root: classes.fontSetting }} heading={'Adjusted Driver'}
                                                data={adjustedDriverList.drop_down_value_keys ? adjustedDriverList.drop_down_value_keys : []} placeholder={' '} twoline='true'
                                                onChange={(value) => setAdjustedDriver(value)} defaultValue={adjustedDriver} capitalize required />

                                        </div>
                                    </Grid>

                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                    container spacing={1}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div className={classes.selectRoot} >
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                Start Date
                                            </FormLabel>
                                            <Datepicker classes={{ root: classes.fontSetting }}
                                                twoline='true'{...props} onChange={handleValidFrom} error={typeof validFrom === 'boolean' && !validFrom ? true : false}
                                                prevalue={validFrom ? Moment(validFrom) : Moment()} />

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div className={classes.selectRoot} >
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                End Date
                                            </FormLabel>
                                            <Datepicker classes={{ root: classes.fontSetting }}
                                                twoline='true'{...props} onChange={handleValidTo} error={typeof validTo === 'boolean' && !validTo ? true : false}
                                                prevalue={validTo ? Moment(validTo) : null} />
                                        </div>

                                    </Grid>
                                    <Grid item md={4} classes={{ root: classes.centerButton }}>
                                        <Grid container style={{ paddingBottom: 15, marginTop: '0.3rem' }} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    onClick={() => handleSubmit(editIndex)}
                                                    disabled={!adjustmentPeriod || !adjustmentContract || !adjustmentDriver || !adjustedDriver || !(validFrom && Moment(validFrom).isValid() && validTo && (Moment(validTo).isValid()) && (Moment(validTo) >= Moment(validFrom)) && (Moment(validFrom) >= Moment(props.startDate)))}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    onClick={() => handleSubmit('')}
                                                    disabled={!adjustmentPeriod || !adjustmentContract || !adjustmentDriver || !adjustedDriver || !(validFrom && Moment(validFrom).isValid() && validTo && (Moment(validTo).isValid()) && (Moment(validTo) >= Moment(validFrom)) && (Moment(validFrom) >= Moment(props.startDate)))}

                                                >
                                                </Button>
                                            }
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                                style={{ marginLeft: 10 }}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >

            <Card style={{ marginTop: '-1rem' }}>
                <div className={classes.row} style={{ marginTop: '-1rem' }} >
                    <CardHeader
                        title="CONTRACT DEPENDENCY"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2} style={{ marginTop: '-1.5rem' }}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {dependencyStore && dependencyStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' >Adjustment Period</TableCell>
                                                <TableCell align='center'> Adjustment Contract Number'</TableCell>
                                                <TableCell align='center' >Adjustment Driver </TableCell>
                                                <TableCell align='center' >Adjusted Driver</TableCell>
                                                <TableCell align='center'> Start Date</TableCell>
                                                <TableCell align='center'>End Date</TableCell>
                                                <TableCell align='center'>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {dependencyStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={item.ID}>
                                                        <TableCell align='center'>{item.adjustment_period}</TableCell>
                                                        <TableCell align='center'>{item.adjustment_contract_number}</TableCell>
                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.adjustment_driver && item.adjustment_driver.replace(/_/g, ' ')}</TableCell>
                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.adjusted_driver && item.adjusted_driver.replace(/_/g, ' ')}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton
                                                                onClick={() => editContractDependency(item, index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteLocally(index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <DeleteForeverIcon />
                                                            </IconButton>

                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
            {/* Eligibility Rules Table Ends here */}
        </div>
    );

});


export default connect(null, null, null, { forwardRef: true })(ContractDependency);
