import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Breadcrumbs, Typography, 
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    Dialog, DialogActions, Button, IconButton
} from '@mui/material';
import { InvertColors, Details } from '@mui/icons-material';
import Tooltip from "@mui/material/Tooltip";
import { WaterFall, Funnel } from './Graphs';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 5,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        //fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 10
    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    iconButton:{
        padding:'12px 5px'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const PriceSimulationCPQResult = props => {
    const history = useHistory();
    const classes = useStyles();
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const [graphData, setGraphData] = React.useState({});
    const handleClose = () => {
        setOpen(false);
    };
    const [typeOfChart, setTypeOfChart] = React.useState('');
    const handleWaterfall = (item, type) => {
        setTypeOfChart(type);
        setOpen(true);
        setGraphData(item);
    }

    return (
        <div>
            <Dialog
                classes={{ paper: classes.paperContainer }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                {typeOfChart === 'waterfall' ?
                    <WaterFall data={graphData} />
                    :
                    <Funnel data={graphData} />
                }

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Breadcrumbs aria-label="breadcrumb" style={{padding:10}}>
                <Link
                     variant="h5"
                     classes={{
                       root: classes.link,
                     }}
                     to='/price-simulation-cpq'
                >
                    Price Simulation 
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div className={clsx(classes.root, className)}>
                <div >
                    <Typography variant="h2">
                        Price Simulation Results
                    </Typography>
                </div>
                {props.priceCalForSimulationData && props.priceCalForSimulationData.results ?
                    <TableContainer >
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell className={classes.tableCell}> Customer Number </TableCell>
                                    <TableCell className={classes.tableCell}> Material Number </TableCell>
                                    <TableCell className={classes.tableCell} style={{width:100}}> Pricing Date </TableCell>
                                    <TableCell className={classes.tableCell}> Base Price </TableCell>
                                    <TableCell className={classes.tableCell}> On-Invoice Adjustments </TableCell>
                                    <TableCell className={classes.tableCell}> Invoice Price </TableCell>
                                    <TableCell className={classes.tableCell}> Invoice Price % </TableCell>
                                    <TableCell className={classes.tableCell}> Floor Price </TableCell>
                                    <TableCell className={classes.tableCell}> Off-Invoice Adjustments </TableCell>
                                    <TableCell className={classes.tableCell}> Off Invoice Price </TableCell>
                                    <TableCell className={classes.tableCell}> Off Invoice Price % </TableCell>
                                    <TableCell className={classes.tableCell}> Cost </TableCell>
                                    <TableCell className={classes.tableCell}> Margin Price </TableCell>
                                    <TableCell className={classes.tableCell}> Margin % </TableCell>
                                    <TableCell className={classes.tableCell}> Margin Target % </TableCell>
                                    <TableCell className={classes.tableCell}> Market Price </TableCell>
                                    <TableCell className={classes.tableCell}> Market Price %</TableCell>
                                    <TableCell className={classes.tableCell}> Market Target %</TableCell>
                                    <TableCell className={classes.tableCell}> Analytics</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {props.priceCalForSimulationData && props.priceCalForSimulationData.results && props.priceCalForSimulationData.results.map(item => {
                                    return (
                                        <StyledTableRow>
                                            <TableCell align='center'>{item.customer_number}</TableCell>
                                            <TableCell align='center'>{item.material_number}</TableCell>
                                            <TableCell align='center' style={{width:100}}>{item.pricing_date}</TableCell>
                                            <TableCell align='center'>{item.base_price != '0' ? item.base_price : ''}</TableCell>
                                            <TableCell align='center'>{item.on_invoice_adjustments != '0' ? item.on_invoice_adjustments : ''}</TableCell>
                                            <TableCell align='center' style={{ color: item.floor_price && (item.floor_price > item.invoice_price) && 'red' }}>{item.invoice_price != '0' ? item.invoice_price : ''}</TableCell>
                                            <TableCell align='center'>{item.invoice_price_percentage != '0' ? item.invoice_price_percentage : ''}</TableCell>
                                            <TableCell align='center' >{item.floor_price != '0' ? item.floor_price : ''}</TableCell> 
                                            <TableCell align='center'>{item.off_invoice_adjustments != '0' ? item.off_invoice_adjustments : ''}</TableCell>
                                            <TableCell align='center'>{item.off_invoice_price != '0' ? item.off_invoice_price : ''}</TableCell>
                                            <TableCell align='center'>{item.off_invoice_price_percentage != '0' ? item.off_invoice_price_percentage : ''}</TableCell>
                                            <TableCell align='center'>{item.cost != '0' ? item.cost : ''}</TableCell>
                                            <TableCell align='center'>{item.margin_price != '0' ? item.margin_price : ''}</TableCell>
                                            <TableCell align='center' style={{ color: item.margin_target_percentage && (item.margin_target_percentage > item.margin_price_percentage) ? 'red' : '#0D1333' }}>{item.margin_price_percentage != '0' ? item.margin_price_percentage : ''}</TableCell>
                                            <TableCell align='center'>{item.margin_target_percentage != '0' ? item.margin_target_percentage : ''}</TableCell>
                                            <TableCell align='center'>{item.market_price != '0' ? item.market_price : ''}</TableCell>
                                            <TableCell align='center' style={{ color: item.market_target_percentage && (item.market_target_percentage > item.market_price_percentage) ? 'red' : '#0D1333' }}>{item.market_price_percentage != '0' ? item.market_price_percentage : ''}</TableCell>
                                            <TableCell align='center'>{item.market_target_percentage != '0' ? item.market_target_percentage : ''}</TableCell>
                                            <TableCell style={{ display: 'flex' }}>
                                                <Tooltip
                                                    title="Waterfall Chart"
                                                >
                                                    <IconButton
                                                        onClick={() => handleWaterfall(item, 'waterfall')}
                                                        classes={{ root: classes.iconButton }}
                                                        size="large">
                                                        <InvertColors color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title="Funnel Chart"
                                                >
                                                    <IconButton
                                                        onClick={() => handleWaterfall(item, 'funnel')}
                                                        classes={{ root: classes.iconButton }}
                                                        size="large">
                                                        <Details color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>

                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No Results
                    </Typography>
                }
            </div >
        </div >
    );

};


const mapStateToProps = state => {
    return {
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
    }
};


export default connect(mapStateToProps, null)(PriceSimulationCPQResult);