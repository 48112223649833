import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    MenuItem,
    OutlinedInput
} from '@mui/material';
import { Link } from 'react-router-dom';
import { TextInputWithLabel, LabelText } from '../../components/Inputs';
import { putEmployeeData, getEmployeeMasterViewDetails } from '../../redux/actions';
import countryList from 'react-select-country-list'
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        marginRight: -25,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 35,
        paddingBottom: 28
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const EditEmployee = props => {
    const classes = useStyles();
    const [employeeNumber, setEmployeeNumber] = React.useState('');
    const [employeeName, setEmployeeName] = React.useState('');
    const [employeeType, setEmployeeType] = React.useState('');
    const [department, setDepartment] = React.useState('');
    const [streetAddress, setStreetAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [salesOffice, setSalesOffice] = React.useState('');
    const [terittoryName, setTerittoryName] = React.useState('');
    const [terittoryID, setTerittoryID] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [baseSalary, setBaseSalary] = React.useState('');
    const [active, setActive] = React.useState('');
    const countriesList = useMemo(() => countryList().getLabels(), [])
    function handleEmployeeName(newValue) {
        setEmployeeName(newValue.target.value);
    }
    function handleEmployeeType(newValue) {
        setEmployeeType(newValue.target.value);
    }
    function handleDepartment(newValue) {
        setDepartment(newValue.target.value);
    }
    function handleStreetAddress(event) {
        setStreetAddress(event.target.value);
    }
    function handleCity(newValue) {
        setCity(newValue.target.value);
    }
    function handleState(newValue) {
        setState(newValue.target.value);
    }
    function handlePostalCode(newValue) {
        setPostalCode(newValue.target.value);
    }
    function handleCountry(e) {
        setCountry(e.target.value);
    }
    function handleSalesOffice(newValue) {
        setSalesOffice(newValue.target.value);
    }
    function handleTerittoryName(newValue) {
        setTerittoryName(newValue.target.value);
    }
    function handleTerittoryID(newValue) {
        setTerittoryID(newValue.target.value);
    }
    function handleActive(event) {
        setActive(event.target.value);
    }
    function handleFlexAttribute1(event) {
        setFlexAttribute1(event.target.value);
    }
    function handleFlexAttribute2(event) {
        setFlexAttribute2(event.target.value);
    }
    const handleBaseSalary = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setBaseSalary(e.target.value);
        }
    }
    const handleSubmit = () => {
        var formData = {
            employee_number: employeeNumber,
            employee_name: employeeName,
            employee_type: employeeType,
            department: department,
            street_address: streetAddress,
            city: city,
            region: state,
            postal_code: postalCode.toString(),
            country: country,
            sales_office: salesOffice,
            territory_name: terittoryName,
            territory_id: terittoryID,
            employee_status: active,
            flex_attribute1: flexAttribute1,
            flex_attribute2: flexAttribute2,
            base_salary: Number(baseSalary)
        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(formData, appId);
    };
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getEmployeeMasterViewDetails(appId);
    }, []);
    useEffect(() => {
        if (props.employeeMasterData) {
            if (props.employeeMasterData) {
                setEmployeeNumber(props.employeeMasterData.employee_number);
                setEmployeeName(props.employeeMasterData.employee_name);
                setEmployeeType(props.employeeMasterData.employee_type);
                setDepartment(props.employeeMasterData.department);
                setStreetAddress(props.employeeMasterData.street_address);
                setCity(props.employeeMasterData.city);
                setState(props.employeeMasterData.region);
                setPostalCode(props.employeeMasterData.postal_code);
                setCountry(props.employeeMasterData.country);
                setSalesOffice(props.employeeMasterData.sales_office);
                setTerittoryName(props.employeeMasterData.territory_name)
                setTerittoryID(props.employeeMasterData.territory_id)
                setFlexAttribute1(props.employeeMasterData.flex_attribute1)
                setFlexAttribute2(props.employeeMasterData.flex_attribute2)
                setActive(props.employeeMasterData.employee_status)
                setBaseSalary(props.employeeMasterData.base_salary)
            }
        }
    }, [props.employeeMasterData]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/employee'
                >
                    Employee Master
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Employee</Typography>
            </Breadcrumbs>
            {props.employeeMasterData &&
                <Card
                    style={{ marginTop: 15 }}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: "15px" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Employee Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={props.employeeMasterData.employee_number}
                                        classes={{ root: classes.inputTwoLine }}
                                        disabled
                                    />
                                    {/* <LabelText classes={{ root: classes.formLabel }} required
                                        // heading={'Employee Number'} 
                                        twoline='true' data={props.employeeMasterData.employee_number} /> */}
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Employee Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={employeeName}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleEmployeeName}
                                        prevalue={props.employeeMasterData.employee_name} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Employee Type
                                    </FormLabel>
                                    <Select
                                        value={employeeType}
                                        onChange={handleEmployeeType}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={'Contractor'}>
                                            Contractor
                                        </MenuItem>
                                        <MenuItem value={'Employee'}>
                                            Employee
                                        </MenuItem>
                                    </Select>

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Department
                                    </FormLabel>
                                    <OutlinedInput
                                        value={department}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleDepartment}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Street Address
                                    </FormLabel>
                                    <OutlinedInput
                                        value={streetAddress}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleStreetAddress}
                                        prevalue={props.employeeMasterData.streetAddress}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Country
                                    </FormLabel>
                                    <OutlinedInput
                                        value={country}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCountry} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        State
                                    </FormLabel>
                                    <OutlinedInput
                                        value={state}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleState} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        City
                                    </FormLabel>
                                    <OutlinedInput
                                        value={city}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCity} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        Postal Code
                                    </FormLabel>
                                    <OutlinedInput
                                        value={postalCode}
                                        inputProps={{ type: "number", step: "1", min: "0" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handlePostalCode} />
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}>
                                        Sales Office
                                    </FormLabel>
                                    <OutlinedInput
                                        value={salesOffice}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleSalesOffice} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}>
                                        Territory Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={terittoryName}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleTerittoryName} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}>
                                        Territory ID
                                    </FormLabel>
                                    <OutlinedInput
                                        value={terittoryID}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleTerittoryID} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}>
                                        Flex Attribute 1
                                    </FormLabel>
                                    <OutlinedInput
                                        value={flexAttribute1}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleFlexAttribute1} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}>
                                        Flex Attribute 2
                                    </FormLabel>
                                    <OutlinedInput
                                        value={flexAttribute2}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleFlexAttribute2} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}>
                                        Base Salary
                                    </FormLabel>
                                    <OutlinedInput
                                        value={baseSalary === 0 ? '' : baseSalary}
                                        inputProps={{ type: "number", step: "1", min: "0" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleBaseSalary} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}>
                                        Employee Status
                                    </FormLabel>
                                    <Select
                                        value={active}
                                        onChange={handleActive}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={"Active"}>
                                            Active
                                        </MenuItem>
                                        <MenuItem value={"Inactive"}>
                                            Inactive
                                        </MenuItem>
                                    </Select>

                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            }
            <div className={classes.submitContainer}>
                <Button color="primary" variant="contained" onClick={handleSubmit} disabled={employeeName
                    && streetAddress && city && state && postalCode && country && department && employeeType ? false : true}>
                    Submit
                </Button>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        employeeMasterData: state.customerData.employeeMasterViewData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(putEmployeeData(data, id)),
        getEmployeeMasterViewDetails: (id) => dispatch(getEmployeeMasterViewDetails(id, 'edit')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditEmployee);