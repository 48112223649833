import React from 'react';
import { connect } from 'react-redux';
import { MasterReportResults } from './components';

const MasterReportResult = (props) => {
    return (
        <div>
            <MasterReportResults />
        </div >
    );
};

export default connect(null, null)(MasterReportResult);