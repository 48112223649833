import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Button,
  InputAdornment,
  Dialog,
  DialogActions,
  FormLabel,
  OutlinedInput,
} from "@mui/material";
import { connect } from 'react-redux';
import { getExpiringContractData, getContractViewData, downloadSheet } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { getContractDetailsData } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from "material-table";
import { RingLoader } from 'react-spinners';
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.table.row,
      height: 46
    },
    '&:nth-of-type(even)': {
      height: 40
    }
  }
}))(TableRow);
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '10px 0px 0px 0px',
    // marginTop: 16
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  caption: {
    fontSize: 12
  },
  paginationRoot: {
    border: '1px solid #EEEEEE',
    borderTop: 0
  },
  description: {
    textAlign: 'left',
    paddingLeft: 28,
    fontSize: theme.typography.h4.fontSize
  },
  hover: {
    cursor: 'pointer'
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: 10
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 15,
    marginTop: 15
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize
  },
  spinner: {
    height: '80vh'
  }
}));
const ContractSetupTable = props => {
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  function handleCancelDialog() {
    history.push('/dashboard');
  }
  const [open, setOpen] = React.useState(true);
  const [expiringIn, setExpiringIn] = React.useState('');
  const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
  const [dataRows, setDataRows] = React.useState([]);
  const handleSheetDownload = () => {
    if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
      props.downloadSheet(contractsTableComplete.records);
  }
  useEffect(() => {
    var tempRows = [];
    if (props.contractablesdata && props.contractablesdata.length > 0) {
      props.contractablesdata.map((e) => {
        tempRows.push({
          contract_number: e.contract_number,
          id: e.id,
          calculation_method: e.calculation_method,
          update_request_closed: e.update_request_closed,
          contract_type: e.contract_type,
          internal_description: e.internal_description,
          approval_status: e.approval_status,
          contract_status: e.contract_status,
          expires_on: e.expires_on,
          accrual_amount: e.accrual_amount
            ? parseFloat(e.accrual_amount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            : 0.0,
          payment_amount: e.payment_amount
            ? parseFloat(e.payment_amount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            : 0.0,
          valid_from: Moment.utc(e.valid_from).format("MM/DD/YYYY"),
          valid_to: Moment.utc(e.valid_to).format("MM/DD/YYYY"),
          change_request_id: e.change_request_id,
        });
      });
      setDataRows(tempRows);

    } else {
      setDataRows([])
    }
  }, [props.contractablesdata])
  useEffect(() => {
    if (props.contractTabledataComplete) {
      setContractsTableComplete(props.contractTabledataComplete);
    }
  }, [props.contractTabledataComplete]);
  function handleItemClick(id) {
    localStorage.setItem('currentAddContractID', id);
    props.getcontractViewdetails(id);
  }
  function handleContinue() {
    setOpen(false);
    props.onCheckContractsTable(expiringIn);
  }
  const columns = [
    {
      field: "contract_number",
      title: "Contract Number",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div
          onClick={() => handleItemClick(rowData.id)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#2877B1",
            textAlign: "center"
          }}
        >
          {rowData.contract_number}
        </div>
      ),
    },
    {
      field: "change_request_id",
      title: "Pending Change",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div
          style={{ whiteSpace: "nowrap", cursor: "pointer", textAlign: "center" }}
        >
          <div>
            {rowData.change_request_id}{" "}
          </div>
        </div>
      ),
    },
    {
      field: "calculation_method",
      title: "Calculation Method",
      editable: "never",
      type: "string",
    },
    {
      field: "contract_type",
      title: "Contract Type",
      editable: "never",
      type: "string",
    },
    {
      field: "internal_description",
      title: "Internal Description",
      editable: "never",
      type: "string",
    },
    {
      field: "contract_status",
      title: "Approval Status",
      editable: "never",
      type: "string",
    },
    {
      field: "accrual_amount",
      title: "Accrual Amount",
      editable: "never",
      type: "string",
      render: (rowData) =>
        rowData.accrual_amount ? (
          <div>
            {rowData.accrual_amount}{" "}
          </div>
        ) : (
          <div>0.00</div>
        ),
    },
    {
      field: "payment_amount",
      title: "Payment Amount",
      editable: "never",
      type: "string",
      render: (rowData) =>
        rowData.payment_amount ? (
          <div>
            {rowData.payment_amount}{" "}
          </div>
        ) : (
          <div>0.00</div>
        ),
    },
    {
      field: "valid_from",
      title: "Start Date",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div>
          {rowData.valid_from === "0001-01-01T00:00:00Z"
            ? ""
            : rowData.valid_from}{" "}
        </div>
      ),
    },
    {
      field: "valid_to",
      title: "End Date",
      editable: "never",
      type: "string",
      render: (rowData) => (
        <div>
          {
            rowData.valid_to === "0001-01-01T00:00:00Z"
              ? ""
              : rowData.valid_to /*Moment.utc(rowData.valid_to).format('MM/DD/YYYY')*/
          }
        </div>
      ),
    },
    {
      field: "expires_on",
      title: "Expires In",
      editable: "never",
      type: "string",
    },
  ];
  return (
    <div className={clsx(classes.root, className)}>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        disableEscapeKeyDown
        classes={{ paper: classes.paper }}>
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel}>Show the contracts expiring in</FormLabel>
          <OutlinedInput
            value={expiringIn}
            classes={{ root: classes.inputTwoLine }}
            style={{ marginLeft: 10 }}
            onChange={(e) => setExpiringIn(e.target.value)}
            endAdornment={<InputAdornment position="end">Days</InputAdornment>} />
        </div>
        <DialogActions>
          <Button color="primary" onClick={handleCancelDialog}> Cancel </Button>
          <Button color="primary" onClick={handleContinue} disabled={expiringIn ? false : true}> Continue </Button>
        </DialogActions>
      </Dialog>
      <LoadingOverlay
        active={props.loading}
        spinner={<RingLoader size={100} />}
        styles={{
          spinner: (base) => ({
            ...base,
            width: '50px',
            '& svg circle': {
              stroke: '#045FB4'
            }
          }),
          overlay: (base) => ({
            ...base,
            background: 'rgba(52, 52, 52, 0)'
          }),
          content: (base) => ({
            ...base,
            color: 'black'
          })
        }}
        className={classes.spinner} >
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
          <h4> Showing contracts expiring in {expiringIn} days.</h4>
          <h4 style={{ color: 'blue', cursor: 'pointer', fontStyle: 'italic' }} onClick={() => setOpen(true)}>Change ?</h4>
        </div>
        {dataRows && dataRows.length > 0 ? (
          dataRows &&
          dataRows.length > 0 && (
            <div>
              <MaterialTable
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        height: "0px",
                      }}
                    ></div>
                  ),
                }}
                title={" "}
                editable={true}
                icons={tableIcons}
                columns={columns}
                data={dataRows}
                style={{ marginTop: '-18px', fontSize: theme.typography.h4.fontSize }}
                options={{
                  search: false,
                  sorting: true,
                  emptyRowsWhenPaging: false,
                  headerStyle: theme.mixins.MaterialHeader,
                  cellStyle: theme.mixins.MaterialCell,
                  filtering: true,
                  pageSize: 15,
                  pageSizeOptions: [
                    15,
                    20,
                    50,
                    { value: dataRows.length, label: "Show all" },
                  ],
                }}
              />
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleSheetDownload}
                  style={{ textTransform: "none", color: "#0B3662" }}
                  size={"small"}
                >
                  {" "}
                  Download{" "}
                </Button>
              </div>
            </div>
          )
        ) : (
          <div style={{ marginLeft: '3rem' }}>There is no data to show now.</div>
        )}
      </LoadingOverlay>
    </div >
  );
};
ContractSetupTable.propTypes = {
  className: PropTypes.string
};
const mapStateToProps = state => {
  return {
    contractablesdata: state.addContractData.expiredData,
    loading: state.addContractData.loading,
    contractTabledataComplete: state.addContractData.contractListDataComplete,
  }
};
const mapDispatchToProps = dispatch => {
  return {
    onCheckContractsTable: (expiry) => dispatch(getExpiringContractData(expiry)),
    getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
    getcontractViewdetails: (id) => dispatch(getContractViewData(id)),
    downloadSheet: (data) => dispatch(downloadSheet(data, 'contracts'))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractSetupTable);