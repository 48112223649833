import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";

const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [
    { 'Dashboard': '/dashboard' },
    {
        'Budgeting': [
            { 'Global Setup': '/promotion-global-setup' },
            { 'Sticker Bank': '/promotion-sticker-setup' },
            { 'Promotion Setup': '/promotion-setup' },
            { 'Costing Simulation': '/costing-simulation' },
            { 'Costing Postings': '/costing-postings' },
            { 'Costing Approvals': '/costing-approvals' }
        ]
    },
    {
        'Analytics': [
            { 'Promotion Type': '/monthly-prom-type' },
            { 'Cost to Revenue': '/cost-to-revenue' },
            { 'Cost Allocation': '/cost-allocation' },
            { 'Regional Analysis': '/geographical-chart-us' },
            { 'Market Share': '/market-share' },
            { 'Time to Market': '/time-to-market' }
        ]
    },
    {
        'Master Data': [
            { 'Material Master': '/material-master-promo' },
            { 'Coverage Cost': '/coverage-cost' },
            { 'Finance Cost': '/finance-cost' },
            { 'Promotion Territory': '/promotion-territory' },
        ]
    },
    {
        'Transaction Data': [
            { 'Upload Sales Forecast': '/sales-forecasting/sales-forecasting-excel-upload' },
            { 'Display Sales Forecast': '/sales-forecasting-display' },
            { 'Upload Indirect Sales Data': '/indirect-sales-inovoice-query' },
            { 'Build Indirect Sales Data': '/build-indirect-sales-invoice' },
            { 'Delete Indirect Sales Data': '/delete-indirect-sales-invoice' },
        ]
    },
    {
        'Pricing': [
            { 'Pricing Attributes': '/pricing-qualifier-config' },
            { 'Price Types': '/pricing-type-config' },
            { 'Price List Master': '/pricing-list-master' },
            { 'Price Maintenance': '/pricing-maintenance' }
        ]
    },
    {
        'Configuration': [
            { 'Dropdown Designer': '/promo-configuration' },
            { 'Promotion Mapping': '/promo-mapping' }
        ]
    },
    {
        'Reporting & Analytics': [
            { "Predefined Analytics": "/analytics-main" },
            { 'On Demand Query': '/dynamic-query' },
        ]
    },
    {
        'Utilities': [
            { "On Demand Videos": "/help-desk-cp" },
        ]
    },

]

const PromotionPlanning = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', fontSize: '16px', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionPlanning);