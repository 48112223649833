import React from 'react';
import { MaterialXMasterUserTable } from './components/MaterialXMaster';

const MaterialMaster = () => {
    return (
        <div>
            <MaterialXMasterUserTable />
        </div >
    );
};

export default MaterialMaster;