import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem,
    Button, OutlinedInput, Checkbox, ListItemText,
    Dialog, DialogContent, DialogContentText, DialogActions, TextField
} from '@mui/material';
import {
    getFormulasList
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { ChipInput } from "../../../components/Inputs";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import {
    AllOtherAttributeMasterComponent,
    MasterDataComponent, SearchDropDownWithoutApply, SearchDropDownMasterDataNew
} from "../../../components/Inputs";
import momentt from "moment-timezone";
import BeatLoader from "react-spinners/BeatLoader";
import DynamicQueryFormRef from '../../DynamicQuery/DynamicQueryFormRef';
import FilterDialoge from '../../DynamicQuery/FilterDialoge';
import { useHistory } from "react-router-dom";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { getDailySimulationsReport } from '../../../redux/actions/Configuration/MultipleConfiguration/AuthAction';
import { runRatioKeyFiguresReports, getDefaultValues, getDefaultValuesAllData, getAttributeListData, getQueryRefById, updateQueryRefData } from '../../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10,
        marginTop: '-1rem'
    },
    container: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    multiSelect: {
        marginTop: 10
    },
    textInput: {
        marginTop: 10
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        // marginBottom: 14,
        width: "100%"
    },
    spinner: {
        height: '90vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30,
    },
    select: {
        width: "100%",
        // color: "#1675e0",
        backgroundColor: "white",
    },
    selectedItem: {
        borderRadius: 5,
        textTransform: "capitalize",
    },
    button: {
        marginRight: 25,
        width: 160
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    gridContainer: {
        padding: "0px 15px 10px 10px",
    },
    gridContainerOuter: {
        padding: "0px 0px 0px 0px",
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        borderRadius: '1rem',
        // backgroundColor: theme.palette.primary.main,
        height: '36px'
    },
}));

const DailySimulationsReportTab = props => {
    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const currentQueryID = "R016"
    const [filterID, setFilterID] = React.useState('');
    const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = useState([]);
    const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState("");
    const [filterAttributeName, setFilterAttributeName] = React.useState([]);
    const sortOrderObj = [
        { "label_name": "Contract Number", "sort_field": "contract_number" },
        { "label_name": "Start Date", "sort_field": "start_date" },
        { "label_name": "End Date", "sort_field": "end_date" },
        { "label_name": "Formula Name", "sort_field": "formula_name" },
        { "label_name": "Calculation Type", "sort_field": "calculation_type" },
        { "label_name": "Aggregation Attribute Name", "sort_field": "attribute_name" },
    ]
    const [newSortOrder, setNewSortOrder] = React.useState([]);
    const [numericFields, setNumericFields] = React.useState([]);
    const [formulaNameArray, setFormulaNameArray] = React.useState([]);
    const [dateFields, setDateFields] = React.useState(["start_date", "end_date"]);
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [listArray, setListArray] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [SelectOpen, setSelectOpen] = useState(false);
    const [contractType, setContractType] = useState([]);
    const [status, setStatus] = React.useState(false);
    const [level, setLevel] = React.useState("");
    const [optionsList, setOptionsList] = React.useState([]);
    const [contractData, setContractData] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [description, setDescription] = React.useState([]);
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const [filterName, setFilterName] = React.useState("");
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openConfirmEmail, setOpenConfirmEmail] = React.useState(false);
    const [emailId, setEmailId] = React.useState(sessionStorage.getItem('loginData') ? JSON.parse(sessionStorage.getItem('loginData')).email : '');
    const [currentPage, setCurrentPage] = React.useState("R016");
    const [attributeNameArray, setAttributeNameArray] = React.useState('');
    //     const isAllSelected3 = attributeNameArray && Object.entries(attributeNameArray).length > 0 && selected3.length === Object.entries(attributeNameArray).length;
    //   const isAllSelected4 = attributeNameArray && Object.entries(attributeNameArray).length > 0 && selected4.length === Object.entries(attributeNameArray).length;
    const postingTypeArray = [
        "Accrual",
        "Accrual Reversal",
        "Payment",
        "Payment Reversal",
        "Delta Payment",
        "Delta Accrual",
    ];
    const calculationTypeArray = ["Tier", "Rebate"];
    const rangeFields = ['contract_number']
    const options = ["range", "not equal", 'greater than', 'less than', 'greater than equal to', 'less than equal to', "pattern"];
    const [trigger, setTigger] = React.useState(false);
    const attributesSortFields = [
        "accrual_credit_profit_center",
        "accrual_debit_profit_center",
        "accrual_document_type",
        "accrual_gl_account_credit",
        "accrual_gl_account_debit",
        "currency",
        "payment_document_type",
        "payment_terms",
        "customer_classification",
        "class_of_trade",
        "account_group",
        "customer_chain",
        "customer_group",
        "customer_group1",
        "customer_group2",
        "customer_group3",
        "customer_group4",
        "customer_group5",
        "customer_industry",
        "material_type",
        "base_uom",
        "external_material_group",
        "product_hierarchy",
        "material_group",
        "material_group1",
        "material_group2",
        "material_group3",
        "material_group4",
        "material_group5",
        "supplier_type",
        "supplier_industry",
        "billed_uom",
        "billing_type",
        "company_code",
        "controlling_area",
        "cost_center",
        "delivery_type",
        "distribution_channel",
        "division",
        "document_category",
        "item_category",
        "order_reason",
        "plant",
        "profit_center",
        "sales_district",
        "sales_group",
        "sales_office",
        "sales_order_type",
        "sales_org",
        "contract_owner",
        "customer_id_type",
        "customer_type",
        "channel_partner_type",
        "supplier_id_type",
        "material_id_type",
        "price_list",
        "action_code",
        "member_status",
        "current_tier_status",
        "accounts_type",
        "price_group",
        "exception_reason"
    ];
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: "scroll",
                scrollBehaviour: " smooth",
                MarginTop: 0,
                menuPlacement: "top",
            },
        },
        getContentAnchorEl: null,
    };

    useEffect(() => {
        props.onLoadingDefault('Customer Rebate', 'Direct - Sales Data');
        // props.onLoadingDefaultFlexKeyFigure('Customer Rebate', 'Direct - Sales Data');
    }, [])
    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records.length > 0) {
            setAttributeNameArray(props.dropdownAttributeData.records[0].label_names)
        }
    }, [props.dropdownAttributeData])
    var dataType = history.location.type;
    useEffect(() => {
        if (dataType == 'email')
            setOpenConfirmEmail(true)

    }, [dataType])

    useEffect(() => {
        // props.getDefaultValuesAllData();
        props.getAttributeListData({ attribute_name: "" });
        props.getFormulasList(1, 0);
    }, [])


    useEffect(() => {
        var newArray = []
        if (props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0) {
            props.formulaListData.records.map(item => {
                newArray.push(item.formula_name)
            })
            setFormulaNameArray(newArray);
        }
    }, [props.formulaListData]);
    useEffect(() => {
        if (
            props.dropdownData && props.dropdownData.field_label_attributes) {
            setContractData(props.dropdownData.field_label_attributes);
        }
    }, [props.dropdownData]);


    useEffect(() => {
        if (props.attributeData && props.attributeData.length > 0)
            setAttributeData(props.attributeData);
    }, [props.attributeData]);
    // console.log("attributeData", props.attributeData);

    useEffect(() => {
        var temp;
        var range
        if (props.predefinedFilter && props.predefinedFilter.filter_fields && filterName === props.predefinedFilter.filter_name) {
            setDynamicFieldValuesFormSubmit(props.predefinedFilter.filter_fields)
            setFilterName(props.predefinedFilter.filter_name)
            Object.values(props.predefinedFilter.filter_fields).map((item, i) => {
                if (item.filter_name === "attribute_name") {
                    setFilterAttributeName(item.filter_value)
                }
                temp = { ...temp, [item.filter_name]: item.filter_value && item.filter_value.from ? item.filter_value.from : item.filter_value }
                if (item.filter_value && item.filter_value.to)
                    range = { ...range, [item.filter_name]: item.filter_value.to }
            })
            setDynamicFieldValues(temp)
            setDynamicFieldValuesRange(range)
        }
        else {
            setDynamicFieldValues({})
            setDynamicFieldValuesFormSubmit({})
            setDynamicFieldValuesRange({})
        }
    }, [props.predefinedFilter])
    // useEffect(() => {
    //     let obj = []
    //     if (filterAttributeName.length > 0) {
    //       filterAttributeName.map(item => {
    //         obj.push({ "label_name": item.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }), "sort_field": item })
    //       })
    //       setNewSortOrder(obj)
    //     }
    //   }, [filterAttributeName])
    let labelNames = {
        "customer_chain": "Customer Chain",
        "customer_group": "Customer Group",
        "customer_group1": "National group",
        "customer_group2": "Sub Group",
        "customer_group3": "Region",
        "customer_number": "Customer Number",
        "material_number": "Material Number",
        "supplier_group": "Supplier Group",
        "supplier_number": "Supplier Number"
    };

    useEffect(() => {
        let obj = [];
        if (filterAttributeName && filterAttributeName.length > 0) {
            filterAttributeName.forEach(item => {
                const labelName = labelNames[item];
                if (labelName) {
                    obj.push({ "label_name": labelName, "sort_field": item });
                }
            });
        }
        if (obj.length > 0) {
            setNewSortOrder(obj);
        } else {
            setNewSortOrder([]);
        }
    }, [filterAttributeName]);

    const handleFilter = (item) => {
        setFilterID(item.id)
        setFilterName(item.filter_name)
        props.getPredefinedFilter(item.id)
    }
    const handleOpen = (item, type) => {
        if (type == "select_box") {
            setSelectOpen(true);
            setLevel(item);
            if (item === "attribute_name") {
                setSelected(filterAttributeName);
                setListArray(attributeNameArray);
                setAllSelect(attributeNameArray && Object.entries(attributeNameArray).length > 0 && selected.length === Object.entries(attributeNameArray).length);
            }
            if (item === "contract_type") {
                const contractTypeObj = contractData.contract_type;

                // Extract the drop_down_values array from the contract_type object
                const dropDownValues = contractTypeObj.drop_down_values || [];

                // Set the extracted drop_down_values array as the value of listArray
                setListArray(dropDownValues);

                // Update other states as needed
                setSelected(contractType);
                setAllSelect(
                    contractData.hasOwnProperty("contract_type") &&
                    contractType.length === dropDownValues.length
                );
            }
            //   setSelected(contractType);
            //   setListArray(
            //     contractData.filter((item) => item.field_id == "contract_type")[0][
            //     "drop_down_value_keys"
            //     ]
            //   );
            //   setAllSelect(
            //     contractData.filter((item) => item.field_id == "contract_type") &&
            //     contractData
            //       .filter((item) => item.field_id == "contract_type")
            //       .map((e) => e.drop_down_value_keys).length > 0 &&
            //     contractType.length ===
            //     contractData
            //       .filter((item) => item.field_id == "contract_type")
            //       .map((e) => e.drop_down_value_keys).length
            //   );
            // }
        } else {
            if (item === "contract_number") {
                setOptionsList(contractNumbersList);
                setInternalDescriptionArray(description);
            }
        }
    };
    const handleBillingDate = (e, type, fieldName) => {
        if (type == "start") {
            setBillingDateStart(e);
            setDynamicFieldValues({
                ...dynamicFieldValues,
                [fieldName]: Moment(e).format("YYYY-MM-DD"),
            });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = Moment(e).format("YYYY-MM-DD")
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: Moment(e).format("YYYY-MM-DD")
                    }
                });
            }
        } else {
            setBillingDateEnd(e);
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: dynamicFieldValues[fieldName], to: Moment(e).format("YYYY-MM-DD") },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        }
    };
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        if (value === "all") {
            if (level === "posting_type") {
                setSelected(
                    selected.length === postingTypeArray.length ? [] : postingTypeArray
                );
                setAllSelect(
                    selected.length === postingTypeArray.length ? false : true
                );
            } else if (level === "attribute_name") {
                setSelected(
                    selected.length === Object.entries(attributeNameArray).length ? [] : Object.entries(attributeNameArray).map(([key, value]) => key));
                setAllSelect(
                    selected.length === Object.entries(attributeNameArray).length ? false : true
                )
            } else if (level === "calculation_type") {
                setSelected(
                    selected.length === calculationTypeArray.length
                        ? []
                        : calculationTypeArray
                );
                setAllSelect(
                    selected.length === calculationTypeArray.length ? false : true
                );
            } else {
                setSelected(selected.length === listArray.length ? [] : listArray);
                setAllSelect(selected.length === listArray.length ? false : true);
            }
        } else {
            setSelected(newSelected);
            setAllSelect(newSelected.length === listArray.length ? true : false);
        }
    };
    function onAdd() {
        setSelectOpen(false);
        if (level == "contract_type") {
            setContractType(selected);
        }
        if (level == "attribute_name") {
            setFilterAttributeName(selected)
        }
        setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
        setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            //[level]: selected,
            [level]: {
                ...dynamicFieldValuesFormSubmit[level],
                filter_name: level,
                filter_value: selected
            }
        });
    }
    function onCancel() {
        setSelectOpen(false);
        setSelected([]);
        setLevel("");
    }
    const handleDynamicFieldValues = (e, fieldName, type, page) => {
        if (!dynamicFieldValuesFormSubmit[fieldName]) {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: { filter_name: fieldName },
            });
        }
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    var value = page == 'master' ? e.key : e
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: value });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: {
                                from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName]) : dynamicFieldValues[fieldName],
                                to: numericFields.includes(fieldName) ? Number(value) : value.toString()
                            },
                        },
                    });
                    // console.log(dynamicFieldValuesFormSubmit)
                } else {
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: '' });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                // setDynamicFieldValuesRange("");
            }
        } else if (type === "option") {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: {
                    ...dynamicFieldValuesFormSubmit[fieldName],
                    filter_name: fieldName,
                    filter_option: e,
                },
            });
        } else {
            //console.log(e);
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(e) : e
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? e.map(Number) : e,
                    },
                });
            }
        }
    };

    const handleDynamicFieldValuesAdd = (e, fieldName, type, ix) => {
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName][0]) : dynamicFieldValues[fieldName][0], to: numericFields.includes(fieldName) ? Number(e) : e },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_name: fieldName,
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        } else {
            var temp = dynamicFieldValues[fieldName]
                ? dynamicFieldValues[fieldName]
                : [];
            if (type === "pastedItem") {
                e.map((arg) => {
                    if (arg) temp.push(arg.trim());
                });
            } else {
                // temp.push(e);
                temp = e;
            }
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(temp) : temp
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? temp.map(Number) : temp,
                    },
                });
            }
        }
    };


    const formatParams = (params) => {
        const formattedParams = {};

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                let filterValue = params[key].filter_value;
                const filterOption = params[key].filter_option;
                let formattedKey = key;

                if (key === 'start_date' || key === 'end_date') {
                    filterValue = Moment(filterValue).format('MM-DD-YYYY');
                }

                if (filterOption === 'range' && (key === 'start_date' || key === 'end_date')) {
                    formattedKey = `${key}.range`;
                } else if (filterOption === 'not equal' && (key === 'start_date' || key === 'end_date')) {
                    formattedKey = `${key}.not`;
                } else if ((key === 'customer_number' || key === 'formula_name') && Array.isArray(filterValue) && filterValue.length > 1) {
                    formattedKey = `${key}.in`;
                }

                if (Array.isArray(filterValue)) {
                    formattedParams[formattedKey] = filterValue.join(',');
                } else if (typeof filterValue === 'object' && filterValue !== null) {
                    if (filterOption === 'range' && filterValue.min !== undefined && filterValue.max !== undefined) {
                        formattedParams[formattedKey] = `${filterValue.min},${filterValue.max}`;
                    } else {
                        formattedParams[formattedKey] = Object.values(filterValue).join(',');
                    }
                } else {
                    formattedParams[formattedKey] = filterValue;
                }
            }
        }

        return formattedParams;
    };

    // console.log(formatParams(dynamicFieldValuesFormSubmit))
    const onSubmitHandler = () => {
        for (var key in dynamicFieldValuesFormSubmit) {
            if (dynamicFieldValuesFormSubmit.hasOwnProperty(key)) {

                if (!dynamicFieldValuesFormSubmit[key].filter_option || dynamicFieldValuesFormSubmit[key].filter_option === "") {
                    dynamicFieldValuesFormSubmit[key].filter_option = "equal";
                }

                var sortedProperties = {};
                Object.keys(dynamicFieldValuesFormSubmit[key]).sort().forEach(function (prop) {
                    sortedProperties[prop] = dynamicFieldValuesFormSubmit[key][prop];
                });
                dynamicFieldValuesFormSubmit[key] = sortedProperties;
                if (key === 'attribute_name') {
                    delete dynamicFieldValuesFormSubmit[key];
                }
            }
        }

        props.onSubmit(1, 0, formatParams(dynamicFieldValuesFormSubmit));
        localStorage.setItem('R016', JSON.stringify(dynamicFieldValuesFormSubmit))
    }

    const onClearHandler = () => {

        setDynamicFieldValues([]);

        setDynamicFieldValuesFormSubmit([]);

        setDynamicFieldValuesRange("");

        setSelected([]);

        setNewSortOrder([]);

        setFilterAttributeName([]);

    };


    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ textTransform: 'capitalize', marginLeft: '1rem' }}>Daily Simulation Report</Typography>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.container} style={{ width: '80%' }}>

                            <Grid container>
                                {sortOrderObj &&
                                    sortOrderObj.length > 0 &&
                                    sortOrderObj
                                        .map((item, ix) => {
                                            return <>
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainerOuter }}
                                                    style={{ display: "flex" }}
                                                >
                                                    <Grid
                                                        item
                                                        md={3}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                        style={{
                                                            position: 'relative'
                                                        }}
                                                    >

                                                        <FormLabel classes={{ root: classes.fontSetting }} required={['Start Date', 'End Date', 'Aggregation Attribute Name'].includes(item.label_name)}
                                                            style={{
                                                                // position: 'absolute',
                                                                // top: '50%',
                                                                // left: '25%',
                                                                // transform: 'translate(-50%, -50%)'
                                                                marginTop: ix === 0 ? '0.7rem' : '-1.3rem'
                                                                // marginTop: '0.7rem'
                                                            }}>
                                                            <Typography variant="h3" color='primary' className={ix == 0 && classes.fontSetup} style={{
                                                                marginTop: '-0.6rem',
                                                                width: '14.5rem',
                                                                // position: 'absolute'
                                                            }}>{ix == 0 && "Filter Name"}</Typography>
                                                            {/* {
                                                                ix == 0 ? <div style={{ marginTop: '10px' }} > {item.label_name} <span style={{ color: 'red' }}>*</span> </div> : item.label_name
                                                            } */}
                                                            {
                                                                ix == 0 ? (
                                                                    <div style={{ marginTop: '10px' }}>
                                                                        {item.label_name}
                                                                        {(item.label_name === 'Start Date' || item.label_name === 'End Date' || item.label_name === 'Aggregation Attribute Name') && (
                                                                            <span style={{ color: 'red' }}>*</span>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    item.label_name
                                                                )
                                                            }
                                                        </FormLabel>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <FormLabel
                                                            classes={{ root: classes.fontSetting }}
                                                        >
                                                            <Typography variant="h3" color='primary' style={{ width: '10.5rem' }} className={ix == 0 && classes.fontSetup}>{ix === 0 && "Option"}</Typography>
                                                        </FormLabel>
                                                        <Select
                                                            value={
                                                                dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                ] &&
                                                                    dynamicFieldValuesFormSubmit[item.sort_field][
                                                                    "filter_option"
                                                                    ]
                                                                    ? dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"]
                                                                    : ""
                                                            }
                                                            onChange={(e) =>
                                                                handleDynamicFieldValues(
                                                                    e.target.value,
                                                                    item.sort_field,
                                                                    "option"
                                                                )
                                                            }
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            displayEmpty
                                                            style={{ marginTop: ix === 0 ? '0px' : '-21px', textTransform: 'capitalize' }}
                                                            className={clsx({
                                                                [classes.select]: true,
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem,
                                                            }}
                                                            disabled={(item.sort_field == "customer_number" || item.sort_field == "supplier_number" || item.sort_field == "employee_number") ? status : false}
                                                        >
                                                            <MenuItem value=""></MenuItem>
                                                            {options &&
                                                                options
                                                                    .filter(itemX => (numericFields.includes(item.sort_field) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                                                        ((!numericFields.includes(item.sort_field) && !dateFields.includes(item.sort_field) && !rangeFields.includes(item.sort_field)) ? itemX != 'range' : itemX)
                                                                        && (item.sort_field === 'contract_number' ? itemX != 'pattern' : itemX)
                                                                        && (dateFields.includes(item.sort_field) ? itemX != 'pattern' : itemX)
                                                                        && (item.sort_field === 'attribute_name' ? itemX != 'pattern' : itemX)
                                                                        && (item.sort_field === 'customer_number' ? itemX != 'pattern' : itemX))
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={item}
                                                                                key={item}
                                                                                style={{ textTransform: "capitalize" }}
                                                                            >
                                                                                {item}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                        </Select>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={7}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <FormLabel
                                                            classes={{ root: classes.fontSetting }}
                                                        >
                                                            <Typography variant="h3" color='primary' style={{ width: '24.5rem' }} className={ix == 0 && classes.fontSetup}>{ix == 0 && "Filter Value"}</Typography>
                                                        </FormLabel>
                                                        {dateFields.includes(item.sort_field) ? (
                                                            <div style={{ display: "flex", marginTop: item.sort_field === 'start_date' ? "-22px" : "-1.4rem" }}>
                                                                <div style={{ width: "60%", marginRight: 5 }}>
                                                                    <LocalizationProvider
                                                                        libInstance={momentt}
                                                                        dateAdapter={AdapterMoment}
                                                                    >
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        // padding: "0px 11px 0px 11px",
                                                                                        alignSelf: "center",
                                                                                        alignItems: "center",
                                                                                        border: "1px solid #E0E0E0",
                                                                                        width: "100%",
                                                                                        height: 36,
                                                                                        // borderRadius: 5,
                                                                                        // color: "#1675e0",
                                                                                    },
                                                                                }}
                                                                                value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : null}
                                                                                onChange={(e) =>
                                                                                    handleBillingDate(e, "start", item.sort_field)
                                                                                }
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider >
                                                                </div >
                                                                {
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"] == "range" &&
                                                                    <div style={{ width: "40%", marginLeft: 5 }}>
                                                                        <LocalizationProvider
                                                                            libInstance={momentt}
                                                                            dateAdapter={AdapterMoment}
                                                                        >
                                                                            <Grid container justifyContent="space-around">
                                                                                <DatePicker
                                                                                    disableToolbar
                                                                                    clearable
                                                                                    InputProps={{
                                                                                        padding: 0,
                                                                                        disableUnderline: true,
                                                                                        style: {
                                                                                            // padding: "0px 11px 0px 11px",
                                                                                            alignSelf: "center",
                                                                                            alignItems: "center",
                                                                                            border: "1px solid #E0E0E0",
                                                                                            width: "100%",
                                                                                            height: 36,
                                                                                            // borderRadius: 5,
                                                                                            //color: "#1675e0",
                                                                                        },
                                                                                    }}
                                                                                    value={dynamicFieldValuesFormSubmit[item.sort_field] && dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']
                                                                                        && dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']['to'] ? dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']['to'] : null}
                                                                                    onChange={(e) =>
                                                                                        handleBillingDate(e, "end", item.sort_field)
                                                                                    }
                                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                    format="MM/DD/YYYY"
                                                                                    disabled={
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                        ] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] == "range"
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </LocalizationProvider >
                                                                    </div >}
                                                            </div >
                                                        ) :
                                                            item.sort_field === 'posting_type' || item.sort_field === 'contract_type' || item.sort_field === 'contract_group' || item.sort_field === 'contract_sub_group' || item.sort_field === 'attribute_name' ?
                                                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.9rem" }}>
                                                                    {dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                    ] &&
                                                                        dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                        ]["filter_option"] &&
                                                                        dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                        ]["filter_option"] == "pattern" ?
                                                                        <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                            <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                                ? dynamicFieldValues[item.sort_field]
                                                                                : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />

                                                                        </div>
                                                                        : <Select
                                                                            labelId="demo-mutiple-name-label"
                                                                            id="demo-mutiple-name"
                                                                            multiple
                                                                            value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : []}
                                                                            style={{ maxHeight: "50px", height: "36px", width: '60%', textTransform: 'capitalize' }}
                                                                            input={<OutlinedInput />}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            onOpen={() => handleOpen(item.sort_field, 'select_box')}
                                                                            onClose={() => setSelectOpen(false)}
                                                                            open={SelectOpen && level == item.sort_field}
                                                                            renderValue={(appType) => {
                                                                                var priceType = []
                                                                                priceType = Object.entries(attributeNameArray).filter(([key, value]) => appType.includes(key)).map(([key, value]) => value)
                                                                                return priceType.join(", ")
                                                                            }}
                                                                            className={classes.multiSelect}

                                                                        >
                                                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                                                <Checkbox
                                                                                    color='primary'
                                                                                    checked={allSelect} />
                                                                                <ListItemText primary='Select All' />
                                                                            </MenuItem>
                                                                            {listArray &&
                                                                                Object.entries(listArray)
                                                                                    .map(([key, value]) => {
                                                                                        return (
                                                                                            <MenuItem onClick={(event) => handleSelectValues(event, key)} value={key} key={key}>
                                                                                                <Checkbox
                                                                                                    color='primary'
                                                                                                    checked={selected.indexOf(key) > -1} />
                                                                                                <ListItemText primary={value} />
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}

                                                                            <div className={classes.dropdownAction}>
                                                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                                                    Cancel
                                                                                </Button>

                                                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                                                    Apply
                                                                                </Button>
                                                                            </div>
                                                                        </Select>}
                                                                </div>
                                                                :
                                                                item.sort_field === "formula_name" ?
                                                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.9rem" }}>
                                                                        {dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                        ] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                            ]["filter_option"] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                            ]["filter_option"] == "pattern" ?
                                                                            <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                                <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                                    ? dynamicFieldValues[item.sort_field]
                                                                                    : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />
                                                                            </div>
                                                                            : <Select
                                                                                value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : []}
                                                                                style={{ maxHeight: "50px", width: '60%', textTransform: 'capitalize' }}
                                                                                onChange={(e, newValue) =>
                                                                                    handleDynamicFieldValues(
                                                                                        [e.target.value],
                                                                                        item.sort_field
                                                                                    )
                                                                                }
                                                                                displayEmpty
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                className={classes.multiSelect}

                                                                            >
                                                                                {formulaNameArray && formulaNameArray.length > 0 && formulaNameArray
                                                                                    .map(item => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                value={item}
                                                                                                key={item}
                                                                                                style={{ textTransform: "capitalize" }}
                                                                                            >
                                                                                                {item}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}
                                                                            </Select>}
                                                                    </div>
                                                                    :
                                                                    item.sort_field === "calculation_type" ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.9rem" }}>
                                                                            {dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                            ] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                                ]["filter_option"] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                                ]["filter_option"] == "pattern" ?
                                                                                <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                                    <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                                        ? dynamicFieldValues[item.sort_field]
                                                                                        : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />
                                                                                </div>
                                                                                : <Select
                                                                                    value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : ''}
                                                                                    style={{ maxHeight: "50px", width: '60%', textTransform: 'capitalize' }}
                                                                                    onChange={(e) => handleDynamicFieldValues([e.target.value], item.sort_field)}
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    className={classes.multiSelect}
                                                                                >
                                                                                    <MenuItem value="Rebate" style={{ textTransform: "capitalize" }}>
                                                                                        Rebate
                                                                                    </MenuItem>
                                                                                    <MenuItem value="Tier" style={{ textTransform: "capitalize" }}>
                                                                                        Tier
                                                                                    </MenuItem>
                                                                                </Select>}
                                                                        </div>
                                                                        :
                                                                        item.sort_field === "class_of_trade" ||
                                                                            item.sort_field === "customer_group" ||
                                                                            item.sort_field === "customer_chain" ||
                                                                            item.sort_field === "material_group" ||
                                                                            item.sort_field === "product_hierarchy" ||
                                                                            item.sort_field === "membership_owner_id" ? (
                                                                            <div style={{ display: "flex", marginTop: '-10px' }}>
                                                                                <div style={{ width: "60%", marginRight: 5 }}>
                                                                                    <AllOtherAttributeMasterComponent
                                                                                        attributeName={item.sort_field}
                                                                                        multiple
                                                                                        withOutLabel
                                                                                        prevalue={
                                                                                            dynamicFieldValues[item.sort_field]
                                                                                                ? dynamicFieldValues[item.sort_field]
                                                                                                : []
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            handleDynamicFieldValues(
                                                                                                e,
                                                                                                item.sort_field
                                                                                            )
                                                                                        }
                                                                                        id={item.sort_field}
                                                                                    />
                                                                                </div>
                                                                                {dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                ] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] == "range" &&
                                                                                    <div style={{ width: "40%" }}>
                                                                                        <AllOtherAttributeMasterComponent
                                                                                            attributeName={item.sort_field}
                                                                                            withOutLabel
                                                                                            prevalue={
                                                                                                dynamicFieldValues[item.sort_field]
                                                                                                    ? dynamicFieldValues[item.sort_field]
                                                                                                    : []
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleDynamicFieldValues(
                                                                                                    e,
                                                                                                    item.sort_field,
                                                                                                    "range"
                                                                                                )
                                                                                            }
                                                                                            disabled={
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                ] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] == "range"
                                                                                                    ? false
                                                                                                    : true
                                                                                            }
                                                                                            id={item.sort_field}
                                                                                        />
                                                                                    </div>}
                                                                            </div>
                                                                        )
                                                                            :
                                                                            item.sort_field === "contract_number" ||
                                                                                (item.sort_field === "material_number" ||
                                                                                    item.sort_field === "customer_number" ||
                                                                                    item.sort_field === "employee_number" ||
                                                                                    item.sort_field === "supplier_number") &&
                                                                                activeDropdown &&
                                                                                activeDropdown[item.sort_field] ? (
                                                                                <div style={{ display: "flex", marginTop: '-0.3rem' }}>
                                                                                    <div style={{ width: "60%", marginRight: 5, height: '1rem' }}>
                                                                                        {dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                        ] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.sort_field
                                                                                            ]["filter_option"] == "range" ?
                                                                                            <SearchDropDownWithoutApply id='contract_number_multiple' //multiple
                                                                                                table='contract_header' keyName='contract_number'
                                                                                                prevalue={
                                                                                                    dynamicFieldValues[item.sort_field]
                                                                                                        ? dynamicFieldValues[item.sort_field]
                                                                                                        : ''
                                                                                                }
                                                                                                description={true} //heading='Contract Number'
                                                                                                onChange={(e, newValue) =>
                                                                                                    handleDynamicFieldValues(
                                                                                                        e,
                                                                                                        item.sort_field
                                                                                                    )
                                                                                                }
                                                                                                applicationType={sessionStorage.getItem("application")}
                                                                                            />
                                                                                            : dynamicFieldValuesFormSubmit[
                                                                                                item.sort_field
                                                                                            ] &&
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                item.sort_field
                                                                                                ]["filter_option"] &&
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                item.sort_field
                                                                                                ]["filter_option"] == "pattern" ?
                                                                                                <div style={{ marginRight: 5, marginTop: "5px", width: "100%" }}>
                                                                                                    <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                                                        ? dynamicFieldValues[item.sort_field]
                                                                                                        : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />
                                                                                                    {/* <ChipInput
                                                                                        classes={{
                                                                                        root: classes.rootContainer,
                                                                                        chip: classes.chip,
                                                                                        input: classes.input,
                                                                                        inputRoot: classes.inputRoot,
                                                                                        label: classes.chipLabel,
                                                                                        chipContainer: classes.chipContainer,
                                                                                        }}
                                                                                        value={
                                                                                        dynamicFieldValues[item.sort_field]
                                                                                            ? dynamicFieldValues[item.sort_field]
                                                                                            : []
                                                                                        }
                                                                                        onAdd={(e) =>
                                                                                        handleDynamicFieldValuesAdd(
                                                                                            e,
                                                                                            item.sort_field,
                                                                                            null,
                                                                                            ix
                                                                                        )
                                                                                        }
                                                                                        onDelete={(e) =>
                                                                                        handleDynamicFieldValuesDelete(
                                                                                            e,
                                                                                            item.sort_field,
                                                                                            null,
                                                                                            ix
                                                                                        )
                                                                                        }
                                                                                        onPaste={(event) => {
                                                                                        const clipboardText = event.clipboardData
                                                                                            .getData("Text")
                                                                                            .split("\n");

                                                                                        event.preventDefault();
                                                                                        handleDynamicFieldValuesAdd(
                                                                                            clipboardText,
                                                                                            item.sort_field,
                                                                                            "pastedItem",
                                                                                            ix
                                                                                        );
                                                                                        if (props.onPaste) {
                                                                                            props.onPaste(event);
                                                                                        }
                                                                                        }}
                                                                                        disableUnderline={true}
                                                                                        blurBehavior="add"
                                                                                    /> */}
                                                                                                </div>

                                                                                                :
                                                                                                <SearchDropDownWithoutApply id='contract_number_multiple' multiple
                                                                                                    table='contract_header' keyName='contract_number'
                                                                                                    prevalue={
                                                                                                        dynamicFieldValues[item.sort_field]
                                                                                                            ? dynamicFieldValues[item.sort_field]
                                                                                                            : []
                                                                                                    }
                                                                                                    description={true} //heading='Contract Number'
                                                                                                    onChange={(e, newValue) =>
                                                                                                        handleDynamicFieldValues(
                                                                                                            e,
                                                                                                            item.sort_field
                                                                                                        )
                                                                                                    }
                                                                                                    applicationType={sessionStorage.getItem("application")}
                                                                                                />}
                                                                                    </div>
                                                                                    {dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] == "range" ?
                                                                                        <div style={{ width: "40%", marginRight: 5, marginTop: '6px' }}>
                                                                                            {item.sort_field == 'contract_number' ?
                                                                                                <div style={{ width: "100%", marginTop: '-6px' }}>
                                                                                                    <SearchDropDownWithoutApply id='contract_number_multiple' // multiple
                                                                                                        prevalue={dynamicFieldValuesRange &&
                                                                                                            dynamicFieldValuesRange[item.sort_field]
                                                                                                            ? dynamicFieldValuesRange[item.sort_field]
                                                                                                            : ''
                                                                                                        }
                                                                                                        table='contract_header' keyName='contract_number'
                                                                                                        description={true} //heading='Contract Number'
                                                                                                        onChange={(e) =>
                                                                                                            handleDynamicFieldValues(e, item.sort_field, 'range')
                                                                                                        }
                                                                                                        applicationType={sessionStorage.getItem("application")}
                                                                                                    />
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{ width: "100%" }}>
                                                                                                    <MasterDataComponent attributeName={item.sort_field} id={item.sort_field} withOutLabel
                                                                                                        onChange={(e) =>
                                                                                                            handleDynamicFieldValues(e, item.sort_field, 'range', 'master')
                                                                                                        }
                                                                                                        prevalue={{
                                                                                                            'key': dynamicFieldValuesRange[item.sort_field]
                                                                                                                ? dynamicFieldValuesRange[item.sort_field]
                                                                                                                : '', 'desc': ''
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            dynamicFieldValuesFormSubmit[
                                                                                                                item.sort_field
                                                                                                            ] &&
                                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                                item.sort_field
                                                                                                                ]["filter_option"] &&
                                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                                item.sort_field
                                                                                                                ]["filter_option"] == "range"
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                        style={{ paddingTop: -10 }}
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                        </div> : ''}
                                                                                </div>
                                                                            ) : (
                                                                                <div style={{ display: "flex", marginTop: '-21px' }}>
                                                                                    <div style={{ width: "60%", marginRight: 5 }}>
                                                                                        <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                                            ? dynamicFieldValues[item.sort_field]
                                                                                            : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} disabled={item.sort_field !== "material_number" || item.sort_field !== "contract_number" ? status : false} />

                                                                                    </div>
                                                                                    {dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] == "range" &&
                                                                                        <div style={{ width: "40%", marginRight: 5 }}>
                                                                                            <OutlinedInput
                                                                                                style={{ width: "40%", marginRight: 5 }}
                                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                                type={props.type}
                                                                                                value={
                                                                                                    dynamicFieldValues[item.sort_field] &&
                                                                                                        dynamicFieldValues[item.sort_field]
                                                                                                            .length > 0
                                                                                                        ? dynamicFieldValuesRange[
                                                                                                        item.sort_field
                                                                                                        ]
                                                                                                        : ""
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    handleDynamicFieldValues(
                                                                                                        e.target.value,
                                                                                                        item.sort_field,
                                                                                                        "range"
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                        item.sort_field
                                                                                                    ] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.sort_field
                                                                                                        ]["filter_option"] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.sort_field
                                                                                                        ]["filter_option"] == "range"
                                                                                                        ? false
                                                                                                        : true
                                                                                                }
                                                                                            />
                                                                                        </div>}
                                                                                </div>
                                                                            )}
                                                    </Grid >

                                                </Grid >

                                            </>;
                                        })}
                                {newSortOrder &&
                                    newSortOrder.length > 0 &&
                                    newSortOrder.map((item, ix) => {
                                        return <>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                classes={{ root: classes.gridContainerOuter }}
                                                style={{ display: "flex" }}
                                            >
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                    style={{
                                                        position: 'relative'
                                                    }}
                                                >

                                                    <FormLabel classes={{ root: classes.fontSetting }}
                                                        style={{
                                                            marginTop: "0.3rem"
                                                        }}>
                                                        {
                                                            ix == 0 ? <div> {item.label_name} </div> : item.label_name
                                                        }
                                                    </FormLabel>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    {/* <FormLabel
                                                        classes={{ root: classes.fontSetting }}
                                                        >
                                                        {ix===0 && "Option"}
                                                        </FormLabel> */}
                                                    <Select
                                                        value={
                                                            dynamicFieldValuesFormSubmit[
                                                                item.sort_field
                                                            ] &&
                                                                dynamicFieldValuesFormSubmit[item.sort_field][
                                                                "filter_option"
                                                                ]
                                                                ? dynamicFieldValuesFormSubmit[
                                                                item.sort_field
                                                                ]["filter_option"]
                                                                : ""
                                                        }
                                                        onChange={(e) =>
                                                            handleDynamicFieldValues(
                                                                e.target.value,
                                                                item.sort_field,
                                                                "option"
                                                            )
                                                        }
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        style={{ marginTop: ix === 0 ? '2px' : '-2px', textTransform: "capitalize" }}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                        disabled={(item.sort_field == "customer_number" || item.sort_field == "supplier_number" || item.sort_field == "employee_number") ? status : false}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        {options &&
                                                            options
                                                                .filter(itemX => (numericFields.includes(item.sort_field) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                                                    ((!numericFields.includes(item.sort_field) && !dateFields.includes(item.sort_field) && !rangeFields.includes(item.sort_field)) ? itemX != 'range' : itemX)
                                                                    && (dateFields.includes(item.sort_field) ? itemX != 'pattern' : itemX))
                                                                .map((item, index) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={item}
                                                                            key={item}
                                                                            style={{ textTransform: "capitalize" }}
                                                                        >
                                                                            {item}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                    </Select>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={7}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    {/* <FormLabel
                                                        classes={{ root: classes.fontSetting }}
                                                        >
                                                        {ix===0 && "Value"}
                                                        </FormLabel> */}
                                                    {dateFields.includes(item.sort_field) ? (
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ width: "60%", marginRight: 5, marginTop: '0.6rem' }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    // padding: '0px 11px 0px 11px',
                                                                                    alignSelf: "center",
                                                                                    alignItems: "center",
                                                                                    border: "1px solid #E0E0E0",
                                                                                    width: "100%",
                                                                                    height: 36,
                                                                                    //  borderRadius: 5,
                                                                                    // color: "#1675e0",
                                                                                },
                                                                            }}
                                                                            value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : null}
                                                                            onChange={(e) =>
                                                                                handleBillingDate(e, "start", item.sort_field)
                                                                            }
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>
                                                            {dynamicFieldValuesFormSubmit[
                                                                item.sort_field
                                                            ] &&
                                                                dynamicFieldValuesFormSubmit[
                                                                item.sort_field
                                                                ]["filter_option"] &&
                                                                dynamicFieldValuesFormSubmit[
                                                                item.sort_field
                                                                ]["filter_option"] == "range" &&
                                                                <div style={{ width: "40%", marginLeft: 5 }}>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        // padding: '0px 11px 0px 11px',
                                                                                        alignSelf: "center",
                                                                                        alignItems: "center",
                                                                                        border: "1px solid #E0E0E0",
                                                                                        width: "100%",
                                                                                        height: 36,
                                                                                        //  borderRadius: 5,
                                                                                        //color: "#1675e0",
                                                                                    },
                                                                                }}
                                                                                value={dynamicFieldValuesFormSubmit[item.sort_field] && dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']
                                                                                    && dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']['to'] ? dynamicFieldValuesFormSubmit[item.sort_field]['filter_value']['to'] : null}
                                                                                onChange={(e) =>
                                                                                    handleBillingDate(e, "end", item.sort_field)
                                                                                }
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                disabled={
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] == "range"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>}
                                                        </div>
                                                    ) :
                                                        item.sort_field === 'posting_type' || item.sort_field === 'contract_type' || item.sort_field === 'contract_group' || item.sort_field === 'contract_sub_group' || item.sort_field === 'aggregation_field' || item.sort_field === 'attribute_name' ?
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.3rem" }}>
                                                                {dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                ] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"] &&
                                                                    dynamicFieldValuesFormSubmit[
                                                                    item.sort_field
                                                                    ]["filter_option"] == "pattern" ?
                                                                    <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                        <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                            ? dynamicFieldValues[item.sort_field]
                                                                            : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />
                                                                        {/* <ChipInput
                                                                classes={{
                                                                    root: classes.rootContainer,
                                                                    chip: classes.chip,
                                                                    input: classes.input,
                                                                    inputRoot: classes.inputRoot,
                                                                    label: classes.chipLabel,
                                                                    chipContainer: classes.chipContainer,
                                                                }}
                                                                value={
                                                                    dynamicFieldValues[item.sort_field]
                                                                    ? dynamicFieldValues[item.sort_field]
                                                                    : []
                                                                }
                                                                onAdd={(e) =>
                                                                    handleDynamicFieldValuesAdd(
                                                                    e,
                                                                    item.sort_field,
                                                                    null,
                                                                    ix
                                                                    )
                                                                }
                                                                onDelete={(e) =>
                                                                    handleDynamicFieldValuesDelete(
                                                                    e,
                                                                    item.sort_field,
                                                                    null,
                                                                    ix
                                                                    )
                                                                }
                                                                onPaste={(event) => {
                                                                    const clipboardText = event.clipboardData
                                                                    .getData("Text")
                                                                    .split("\n");

                                                                    event.preventDefault();
                                                                    handleDynamicFieldValuesAdd(
                                                                    clipboardText,
                                                                    item.sort_field,
                                                                    "pastedItem",
                                                                    ix
                                                                    );
                                                                    if (props.onPaste) {
                                                                    props.onPaste(event);
                                                                    }
                                                                }}
                                                                disableUnderline={true}
                                                                blurBehavior="add"
                                                                />  */}
                                                                    </div>
                                                                    : <Select
                                                                        labelId="demo-mutiple-name-label"
                                                                        id="demo-mutiple-name"
                                                                        multiple
                                                                        value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : []}
                                                                        style={{ maxHeight: "50px", width: '60%', height: 36, textTransform: 'capitalize' }}
                                                                        input={<OutlinedInput />}
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        onOpen={() => handleOpen(item.sort_field, 'select_box')}
                                                                        onClose={() => setSelectOpen(false)}
                                                                        open={SelectOpen && level == item.sort_field}
                                                                        renderValue={(appType) => {
                                                                            var priceType = []
                                                                            priceType = Object.entries(attributeNameArray).filter(([key, value]) => appType.includes(key)).map(([key, value]) => value)
                                                                            return priceType.join(", ")
                                                                        }}
                                                                        className={classes.multiSelect}

                                                                    >
                                                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                                            <Checkbox
                                                                                color='primary'
                                                                                checked={allSelect} />
                                                                            <ListItemText primary='Select All' />
                                                                        </MenuItem>
                                                                        {listArray &&
                                                                            Object.entries(listArray)
                                                                                .map(([key, value]) => {
                                                                                    return (
                                                                                        <MenuItem onClick={(event) => handleSelectValues(event, key)} value={key} key={key}>
                                                                                            <Checkbox
                                                                                                color='primary'
                                                                                                checked={selected.indexOf(key) > -1} />
                                                                                            <ListItemText primary={value} />
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}

                                                                        <div className={classes.dropdownAction}>
                                                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                                                Cancel
                                                                            </Button>

                                                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                                                Apply
                                                                            </Button>
                                                                        </div>
                                                                    </Select>}
                                                            </div>
                                                            :
                                                            attributesSortFields.includes(item.sort_field) ? (
                                                                <div style={{ display: "flex" }}>
                                                                    <div style={{ width: "60%", marginRight: 5, marginTop: ix === 0 ? '-3px' : '-8px', height: '1rem' }}>
                                                                        <SearchDropDownMasterDataNew id={item.sort_field}
                                                                            table={'attributes_masters'}
                                                                            keyName={item.sort_field}
                                                                            description={true}
                                                                            onChange={(newValue) => handleDynamicFieldValues(
                                                                                newValue,
                                                                                item.sort_field
                                                                            )}
                                                                            prevalue={dynamicFieldValues[item.sort_field]
                                                                                ? dynamicFieldValues[item.sort_field]
                                                                                : []}
                                                                            multiple
                                                                        />
                                                                    </div>
                                                                    {dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                    ] &&
                                                                        dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                        ]["filter_option"] &&
                                                                        dynamicFieldValuesFormSubmit[
                                                                        item.sort_field
                                                                        ]["filter_option"] == "range" &&
                                                                        <div style={{ width: "40%" }}>
                                                                            <SearchDropDownMasterDataNew id={item.sort_field}
                                                                                table={'attributes_masters'}
                                                                                keyName={item.sort_field}
                                                                                description={true}
                                                                                onChange={(newValue) => handleDynamicFieldValues(
                                                                                    newValue,
                                                                                    item.sort_field,
                                                                                    "range"
                                                                                )}
                                                                                prevalue={dynamicFieldValues[item.sort_field]
                                                                                    ? dynamicFieldValues[item.sort_field]
                                                                                    : []}
                                                                                multiple
                                                                                disabled={
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                    ] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] &&
                                                                                        dynamicFieldValuesFormSubmit[
                                                                                        item.sort_field
                                                                                        ]["filter_option"] == "range"
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                            />
                                                                        </div>}
                                                                </div>
                                                            )
                                                                :
                                                                (item.sort_field === "material_number" ||
                                                                    item.sort_field === "customer_number" ||
                                                                    item.sort_field === "employee_number" ||
                                                                    item.sort_field === "supplier_number") ? (
                                                                    <div style={{ display: "flex", marginTop: '-5px', marginBottom: "-7px" }}>
                                                                        <div style={{ width: "60%", marginRight: 5 }}>
                                                                            <SearchDropDownMasterDataNew id='contract_number_multiple'
                                                                                multiple
                                                                                table={
                                                                                    item.sort_field === "material_number"
                                                                                        ? "material_master"
                                                                                        : item.sort_field === "customer_number"
                                                                                            ? "customer_master"
                                                                                            : item.sort_field === "employee_number"
                                                                                                ? "employee_master"
                                                                                                : item.sort_field === "supplier_number"
                                                                                                    ? "supplier_master"
                                                                                                    : ""
                                                                                }
                                                                                keyName={item.sort_field}
                                                                                prevalue={
                                                                                    dynamicFieldValues[item.sort_field]
                                                                                        ? dynamicFieldValues[item.sort_field]
                                                                                        : []
                                                                                }
                                                                                description={true} //heading='Contract Number'
                                                                                onChange={(e, newValue) =>
                                                                                    handleDynamicFieldValues(
                                                                                        e,
                                                                                        item.sort_field
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        {dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                        ] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                            ]["filter_option"] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.sort_field
                                                                            ]["filter_option"] == "range" ?
                                                                            <div style={{ width: "40%", marginRight: 5, marginTop: '6px' }}>
                                                                                {item.sort_field == 'contract_number' ?
                                                                                    <div style={{ width: "100%", marginTop: '-6px' }}>
                                                                                        <SearchDropDownMasterDataNew id='contract_number_multiple' multiple
                                                                                            prevalue={dynamicFieldValuesRange &&
                                                                                                dynamicFieldValuesRange[item.sort_field]
                                                                                                ? dynamicFieldValuesRange[item.sort_field]
                                                                                                : []
                                                                                            }
                                                                                            table={
                                                                                                item.sort_field === "material_number"
                                                                                                    ? "material_master"
                                                                                                    : item.sort_field === "customer_number"
                                                                                                        ? "customer_master"
                                                                                                        : item.sort_field === "employee_number"
                                                                                                            ? "employee_master"
                                                                                                            : item.sort_field === "supplier_number"
                                                                                                                ? "supplier_master"
                                                                                                                : ""
                                                                                            }
                                                                                            keyName={item.sort_field}
                                                                                            description={true} //heading='Contract Number'
                                                                                            onChange={(e) =>
                                                                                                handleDynamicFieldValues(e, item.sort_field, 'range')
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{ width: "100%" }}>
                                                                                        <SearchDropDownMasterDataNew id='contract_number_multiple' multiple
                                                                                            prevalue={dynamicFieldValuesRange &&
                                                                                                dynamicFieldValuesRange[item.sort_field]
                                                                                                ? dynamicFieldValuesRange[item.sort_field]
                                                                                                : []
                                                                                            }
                                                                                            table={
                                                                                                item.sort_field === "material_number"
                                                                                                    ? "material_master"
                                                                                                    : item.sort_field === "customer_number"
                                                                                                        ? "customer_master"
                                                                                                        : item.sort_field === "employee_number"
                                                                                                            ? "employee_master"
                                                                                                            : item.sort_field === "supplier_number"
                                                                                                                ? "supplier_master"
                                                                                                                : ""
                                                                                            }
                                                                                            keyName={item.sort_field}
                                                                                            description={true} //heading='Contract Number'
                                                                                            onChange={(e) =>
                                                                                                handleDynamicFieldValues(e, item.sort_field, 'range')
                                                                                            }
                                                                                            disabled={
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                ] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] == "range"
                                                                                                    ? false
                                                                                                    : true
                                                                                            }
                                                                                        />
                                                                                        {/* <MasterDataComponent attributeName={item.sort_field} id={item.sort_field} withOutLabel
                                                                                            onChange={(e) =>
                                                                                                handleDynamicFieldValues(e, item.sort_field, 'range', 'master')
                                                                                            }
                                                                                            prevalue={{
                                                                                                'key': dynamicFieldValuesRange[item.sort_field]
                                                                                                    ? dynamicFieldValuesRange[item.sort_field]
                                                                                                    : '', 'desc': ''
                                                                                            }}
                                                                                            disabled={
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                ] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] == "range"
                                                                                                    ? false
                                                                                                    : true
                                                                                            }
                                                                                            style={{ paddingTop: -10 }}
                                                                                        /> */}
                                                                                    </div>
                                                                                }
                                                                            </div> : ''}
                                                                    </div>
                                                                ) :
                                                                    item.sort_field === "first_key_figure" || item.sort_field === "second_key_figure" ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-1.3rem" }}>
                                                                            {dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                            ] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                                ]["filter_option"] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.sort_field
                                                                                ]["filter_option"] == "pattern" ?
                                                                                <div style={{ width: "60%", marginRight: 5, marginTop: 9 }}>
                                                                                    <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                                        ? dynamicFieldValues[item.sort_field]
                                                                                        : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} />
                                                                                    {/* <ChipInput
                                                                        classes={{
                                                                        root: classes.rootContainer,
                                                                        chip: classes.chip,
                                                                        input: classes.input,
                                                                        inputRoot: classes.inputRoot,
                                                                        label: classes.chipLabel,
                                                                        chipContainer: classes.chipContainer,
                                                                        }}
                                                                        value={
                                                                        dynamicFieldValues[item.sort_field]
                                                                            ? dynamicFieldValues[item.sort_field]
                                                                            : []
                                                                        }
                                                                        onAdd={(e) =>
                                                                        handleDynamicFieldValuesAdd(
                                                                            e,
                                                                            item.sort_field,
                                                                            null,
                                                                            ix
                                                                        )
                                                                        }
                                                                        onDelete={(e) =>
                                                                        handleDynamicFieldValuesDelete(
                                                                            e,
                                                                            item.sort_field,
                                                                            null,
                                                                            ix
                                                                        )
                                                                        }
                                                                        onPaste={(event) => {
                                                                        const clipboardText = event.clipboardData
                                                                            .getData("Text")
                                                                            .split("\n");

                                                                        event.preventDefault();
                                                                        handleDynamicFieldValuesAdd(
                                                                            clipboardText,
                                                                            item.sort_field,
                                                                            "pastedItem",
                                                                            ix
                                                                        );
                                                                        if (props.onPaste) {
                                                                            props.onPaste(event);
                                                                        }
                                                                        }}
                                                                        disableUnderline={true}
                                                                        blurBehavior="add"
                                                                    />  */}
                                                                                </div>
                                                                                : <Select
                                                                                    value={dynamicFieldValues[item.sort_field] ? dynamicFieldValues[item.sort_field] : []}
                                                                                    style={{ maxHeight: "50px", width: '60%', textTransform: 'capitalize' }}
                                                                                    onChange={(e, newValue) =>
                                                                                        handleDynamicFieldValues(
                                                                                            e.target.value,
                                                                                            item.sort_field
                                                                                        )
                                                                                    }
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    className={classes.multiSelect}
                                                                                >
                                                                                    {/* {flexKeyFigureArray &&
                                                                        Object.entries(flexKeyFigureArray).map(([key, value]) => {
                                                                        return (
                                                                            <MenuItem
                                                                            value={key}
                                                                            key={key}
                                                                            style={{ textTransform: "capitalize" }}
                                                                            >
                                                                            {value}
                                                                            </MenuItem>
                                                                        );
                                                                        })} */}
                                                                                </Select>}
                                                                        </div>
                                                                        :

                                                                        (
                                                                            <div style={{ display: "flex", marginTop: '-0.7rem' }}>
                                                                                <div style={{ width: "60%", marginRight: 5, marginTop: 11 }}>
                                                                                    <ChipInput paste data={dynamicFieldValues[item.sort_field]
                                                                                        ? dynamicFieldValues[item.sort_field]
                                                                                        : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)} disabled={item.sort_field !== "material_number" || item.sort_field !== "contract_number" ? status : false} />
                                                                                    {/* <ChipInput
                                                                        classes={{
                                                                        root: classes.rootContainer,
                                                                        chip: classes.chip,
                                                                        input: classes.input,
                                                                        inputRoot: classes.inputRoot,
                                                                        label: classes.chipLabel,
                                                                        chipContainer: classes.chipContainer,
                                                                        }}
                                                                        disabled={item.sort_field !== "material_number" || item.sort_field !== "contract_number" ? status : false}
                                                                        value={
                                                                        dynamicFieldValues[item.sort_field]
                                                                            ? dynamicFieldValues[item.sort_field]
                                                                            : []
                                                                        }
                                                                        onAdd={(e) =>
                                                                        handleDynamicFieldValuesAdd(
                                                                            e,
                                                                            item.sort_field,
                                                                            null,
                                                                            ix
                                                                        )
                                                                        }
                                                                        onDelete={(e) =>
                                                                        handleDynamicFieldValuesDelete(
                                                                            e,
                                                                            item.sort_field,
                                                                            null,
                                                                            ix
                                                                        )
                                                                        }
                                                                        onPaste={(event) => {
                                                                        const clipboardText = event.clipboardData
                                                                            .getData("Text")
                                                                            .split("\n");

                                                                        event.preventDefault();
                                                                        handleDynamicFieldValuesAdd(
                                                                            clipboardText,
                                                                            item.sort_field,
                                                                            "pastedItem",
                                                                            ix
                                                                        );
                                                                        if (props.onPaste) {
                                                                            props.onPaste(event);
                                                                        }
                                                                        }}
                                                                        disableUnderline={true}
                                                                        blurBehavior="add"
                                                                    /> */}
                                                                                </div>
                                                                                {dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                ] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] &&
                                                                                    dynamicFieldValuesFormSubmit[
                                                                                    item.sort_field
                                                                                    ]["filter_option"] == "range" &&
                                                                                    <div style={{ width: "40%", marginRight: 5 }}>
                                                                                        <OutlinedInput
                                                                                            classes={{ root: classes.inputTwoLine }}
                                                                                            type={props.type}
                                                                                            value={
                                                                                                dynamicFieldValues[item.sort_field] &&
                                                                                                    dynamicFieldValues[item.sort_field]
                                                                                                        .length > 0
                                                                                                    ? dynamicFieldValuesRange[
                                                                                                    item.sort_field
                                                                                                    ]
                                                                                                    : ""
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleDynamicFieldValues(
                                                                                                    e.target.value,
                                                                                                    item.sort_field,
                                                                                                    "range"
                                                                                                )
                                                                                            }
                                                                                            disabled={
                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                ] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.sort_field
                                                                                                    ]["filter_option"] == "range"
                                                                                                    ? false
                                                                                                    : true
                                                                                            }
                                                                                        />
                                                                                    </div>}
                                                                            </div>
                                                                        )}
                                                </Grid>

                                            </Grid >

                                        </>;
                                    })}
                            </Grid >
                            <div className={classes.buttonRoot}>
                                <Button variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler}
                                    // disabled={Object.entries(dynamicFieldValuesFormSubmit).length > 0 ? false : true}
                                    // disabled={!Object.values(dynamicFieldValuesFormSubmit).some(obj => Object.keys(obj).length > 0 && (Array.isArray(obj.filter_value) ? obj.filter_value.length > 0 : obj.filter_value))}
                                    // disabled={
                                    //     !Object.values(dynamicFieldValuesFormSubmit).some(obj =>
                                    //         Object.keys(obj).length > 0 &&
                                    //         (
                                    //             Array.isArray(obj.filter_value) ?
                                    //                 obj.filter_value.length > 0 :
                                    //                 obj.filter_value
                                    //         ) &&
                                    //         (
                                    //             obj.filter_option !== "range" ||
                                    //             (obj.filter_option === "range" && obj.filter_value.from && obj.filter_value.to)
                                    //         )
                                    //     )
                                    // }
                                    disabled={dynamicFieldValuesFormSubmit['start_date'] && dynamicFieldValuesFormSubmit['end_date'] && dynamicFieldValuesFormSubmit['attribute_name'] ? false : true} >

                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Run'
                                    }
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={onClearHandler}
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Clear'
                                    }

                                </Button>
                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    // onClick={() => handleSearch('Save Search')}
                                    twoline="true"
                                    // disabled={Object.entries(dynamicFieldValuesFormSubmit).length > 0 ? false : true}
                                    // disabled={!Object.values(dynamicFieldValuesFormSubmit).some(obj => Object.keys(obj).length > 0 && (Array.isArray(obj.filter_value) ? obj.filter_value.length > 0 : obj.filter_value))}
                                    disabled={
                                        !Object.values(dynamicFieldValuesFormSubmit).some(obj =>
                                            Object.keys(obj).length > 0 &&
                                            (
                                                Array.isArray(obj.filter_value) ?
                                                    obj.filter_value.length > 0 :
                                                    obj.filter_value
                                            ) &&
                                            (
                                                obj.filter_option !== "range" ||
                                                (obj.filter_option === "range" && obj.filter_value.from && obj.filter_value.to)
                                            )
                                        )
                                    }
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search'
                                    }
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    // onClick={() => handleSearch('Save Search & Run')}
                                    twoline="true"
                                    // disabled={Object.entries(dynamicFieldValuesFormSubmit).length > 0 ? false : true}
                                    // disabled={!Object.values(dynamicFieldValuesFormSubmit).some(obj => Object.keys(obj).length > 0 && (Array.isArray(obj.filter_value) ? obj.filter_value.length > 0 : obj.filter_value))}
                                    disabled={
                                        !Object.values(dynamicFieldValuesFormSubmit).some(obj =>
                                            Object.keys(obj).length > 0 &&
                                            (
                                                Array.isArray(obj.filter_value) ?
                                                    obj.filter_value.length > 0 :
                                                    obj.filter_value
                                            ) &&
                                            (
                                                obj.filter_option !== "range" ||
                                                (obj.filter_option === "range" && obj.filter_value.from && obj.filter_value.to)
                                            )
                                        )
                                    }
                                >
                                    {props.loading ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search & Run'
                                    }
                                </Button> */}
                            </div>
                        </div >
                        {/* <div style={{ width: '20%' }} className={classes.container}>
                            <DynamicQueryFormRef id={''} onChange={handleFilter} />
                        </div> */}
                    </div >
                </div >

            </div >
            {/* {
                openFilter &&
                <FilterDialoge open={openFilter} filterName={filterName}
                    action={filterID ? 'edit' : 'create'}
                    onChange={handleSaveAndRun}
                    onCancel={() => setOpenFilter(false)}
                />

            } */}
            {/* <Dialog open={openConfirmEmail}>
                <DialogContent
                    classes={{
                        root: classes.dialogContent,
                    }}
                >
                    <Typography variant="h4">
                        <DialogContentText>
                            <FormLabel classes={{ root: classes.fontSetting }}>Email Id</FormLabel>
                            <OutlinedInput
                                classes={{ root: classes.inputTwoLine }}
                                type={'email'}
                                value={emailId}
                                onChange={(e) => setEmailId(e.target.value)}
                            />
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions
                    classes={{
                        root: classes.dialog,
                    }}
                >
                    <Button
                        onClick={() => history.push('/calculation-summary-reports')}
                        autoFocus
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => setOpenConfirmEmail(false)}
                        color="primary"
                        autoFocus
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog> */}
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        dailySimulationData: state.addMultipleConfigurationData.dailySimulationsReportData,
        formulaListData: state.FormulaLabData.formulaListData,
        predefinedFilter: state.operationalReportsData.dynamicQueryFilterData,
        attributeData: state.attributeListData.AttributeData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit, data) => dispatch(getDailySimulationsReport(data)),
        getFormulasList: (pagination, limit) => dispatch(getFormulasList(pagination, limit)),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DailySimulationsReportTab);