import React, { useEffect } from 'react';
import {
    Typography, IconButton
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import LoadingOverlay from "react-loading-overlay";
import GridLoader from "react-spinners/GridLoader";
import { getAllCommunityCollabChannelsData, getAllCommunityChatData } from '../../redux/actions';
import MaterialTable from 'material-table';
import { QuestionAnswerSharp } from '@mui/icons-material';
import { tableIcons } from '../../components/Icons/TableIcons';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({

}));
const CommunityChatMain = props => {
    const theme = createTheme();
    const classes = useStyles();
    const [dataRows, setDataRows] = React.useState([]);
    useEffect(() => {
        props.onLoading()
    }, []);
    useEffect(() => {
        if (props.queryData && props.queryData.length > 0) {
            setDataRows(props.queryData)

        }
    }, [props.queryData]);
    const columns = [
        {
            field: 'id',
            title: 'Collaboration ID',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.id}</a>
        },
        {
            field: 'name',
            title: 'Collaboration Name',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.name}</a>
        },
        {
            field: 'last_communication',
            title: 'Last Communication',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>  {rowData.last_communication ? Moment(rowData.last_communication).format('MM-DD-YYYY: HH:mm:ss') : ''}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleSubmit(rowData)}
                        size="large">
                        <QuestionAnswerSharp color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                </div>

        },
    ];
    const handleSubmit = (item) => {
        props.getAllCommunityChatData(item.id)
        sessionStorage.setItem('community-id', item.id)
        sessionStorage.setItem('community-name', item.name)
    }
    return <>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LoadingOverlay active={props.loading} spinner={<GridLoader />}>
                    {props.queryData && props.queryData.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRows}
                            style={{ padding: '2rem' }}
                            options={{
                                // maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 15,
                                pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        />
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </LoadingOverlay>
            </ThemeProvider>
        </StyledEngineProvider>
    </>;
};
const mapStateToProps = state => {
    return {
        queryData: state.ChatGptData.communityChatData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLoading: () => dispatch(getAllCommunityCollabChannelsData()),
        getAllCommunityChatData: (id) => dispatch(getAllCommunityChatData(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CommunityChatMain);