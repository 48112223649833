import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Typography,
  useMediaQuery,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Button,
  TablePagination,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import {
  getQueryList,
  runDynamicQuery,
  getDynamicQuery,
  deleteDynamicQuery,
  cloneDynamicQuery,
} from "../../redux/actions";
import { LibraryAdd } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.table.row,
      height: 46,
    },
    "&:nth-of-type(even)": {
      height: 40,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '20px 16px',
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  caption: {
    fontSize: 12,
  },
  paginationRoot: {
    border: "1px solid #EEEEEE",
    borderTop: 0,
  },
  description: {
    textAlign: "left",
    paddingLeft: 28,
  },
  hover: {
    cursor: "pointer",
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: 10,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
  },
  tableCellStyle: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize
  }
}));
const DynamicQuery = (props) => {
  const [currentPage, setCurrentPage] = React.useState("");
  const [queryListID, setQueryListID] = React.useState(1);
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(
      location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    );
  }, [location]);

  useEffect(() => {
    props.getQueryList(1, 10, 4, sessionStorage.getItem("application"));
  }, []);

  const { className } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [contractsTableComplete, setContractsTableComplete] = React.useState(
    []
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  useEffect(() => {
    if (props.contractTabledataComplete) {
      setContractsTableComplete(props.contractTabledataComplete);
    }
  }, [props.contractTabledataComplete]);
  function runQuery(item) {
    props.getDynamicQuery(item.ID);
    sessionStorage.removeItem("dyamicQueryDownloadData");
    var data = {
      id: item.ID.toString(),
    };
    if (item.filter_fields.length > 0) {
      var object = {
        id: item.ID,
        fields: item.filter_fields,
        sort_fields: [],
        data_source_type: item.data_source_type,
      };
      sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
      history.push({
        pathname: "/on-demand-analytics/on-demand-analytics-form",
        filterValues: item.filter_fields,
      });
    } else {
      var object = {
        id: item.ID,
        fields: [],
        sort_fields: item.sort_fields,
        data_source_type: item.data_source_type,
      };
      sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
      props.runDynamicQuery(data, item.ID);
    }
  }
  function editQuery(item) {
    sessionStorage.removeItem("dq_table_name");
    props.getDynamicQuery(item.ID);
    history.push("/on-demand-analytics/edit-on-demand-analytics/" + item.ID);
  }
  function viewQuery(item) {
    sessionStorage.removeItem("dq_table_name");
    history.push("/on-demand-analytics/view-on-demand-analytics/" + item.ID);
  }
  function deleteQuery(item) {
    props.deleteDynamicQuery(
      item.ID,
      1,
      10,
      1,
      sessionStorage.getItem("application")
    );
  }
  function cloneQuery(item) {
    props.cloneDynamicQuery(
      item.ID,
      1,
      10,
      1,
      sessionStorage.getItem("application")
    );
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([
    10, 20, 30,
  ]);
  const [queryListData, setQueryListData] = React.useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.getQueryList(
      newPage + 1,
      rowsPerPage,
      4,
      sessionStorage.getItem("application")
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    props.getQueryList(
      1,
      event.target.value,
      4,
      sessionStorage.getItem("application")
    );
  };

  useEffect(() => {
    if (props.queryListData && props.queryListData.records) {
      console.log(props.queryListData);
      setQueryListData(props.queryListData);
    }
  }, [props.queryListData]);
  const handleRunPage = () => {
    history.push("/on-demand-analytics/add-on-demand-analytics");
  };
  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      props.deleteDynamicQuery(
        itemToDelete.ID,
        1,
        10,
        4,
        sessionStorage.getItem("application")
      );
    }
    setOpenDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setItemToDelete(null);
  };
  return (
    <LoadingOverlay
      active={props.loading}
      spinner={<HashLoader />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#045FB4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "black",
        }),
      }}
      className={classes.spinner}
    >
      <div className={clsx(classes.root, className)}>
        <div
          className={clsx({
            [classes.row]: isDesktop,
          })}
        >
          <Typography variant="h1" color="primary" style={{ marginLeft: 7, marginTop: 10 }}>
            {currentPage === "partner-statement"
              ? "Partner Statement"
              : currentPage === "collaboration-portal"
                ? "Collaboration Portal"
                : "On Demand Analytics"}
          </Typography>
          {secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_on_demand_analytics-" + sessionStorage.getItem("application")
              ) && (
              <Fab aria-label="edit" variant="extended"
                size='medium'
                classes={{ root: classes.fabContainer }}
                className={classes.fabContainer}
                onClick={() => handleRunPage()}
              >
                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
              </Fab>
            )}
        </div>
        {queryListData &&
          queryListData.records &&
          queryListData.records.length > 0 ? (
          <TableContainer style={{ marginTop: -15 }} >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Query ID
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Data Source
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Query Name
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Query Description
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Chart Type
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellStyle}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody classes={{ root: classes.table }}>
                {queryListData &&
                  queryListData.records
                    // .filter(item => item.query_type_id === queryListID)
                    .sort(function (a, b) {
                      return a.data_source.localeCompare(b.data_source); //using String.prototype.localCompare()
                    })
                    .map((item) => {
                      return (
                        <StyledTableRow key={item.ID}>
                          <TableCell
                            align="center" classes={{ root: classes.fontSetting }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.ID}
                          </TableCell>
                          <TableCell
                            align="center" classes={{ root: classes.fontSetting }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.table_description}
                          </TableCell>
                          <TableCell
                            align="center" classes={{ root: classes.fontSetting }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.format_name}
                          </TableCell>
                          <TableCell
                            align="center" classes={{ root: classes.fontSetting }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.query_description}
                          </TableCell>
                          <TableCell
                            align="center" classes={{ root: classes.fontSetting }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.chart_type}
                          </TableCell>
                          <TableCell align="center" classes={{ root: classes.fontSetting }} >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                {secureLocalStorage.getItem("dYtz") &&
                                  secureLocalStorage.getItem("dYtz").role &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage
                                    .getItem("dYtz")
                                    .role.rights.includes(
                                      "get_on_demand_analytics-" +
                                      sessionStorage.getItem("application")
                                    ) && (
                                    <Tooltip title="View">
                                      <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => viewQuery(item)}
                                        size="large">
                                        <VisibilityIcon
                                          color="disabled"
                                          style={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {secureLocalStorage.getItem("dYtz") &&
                                  secureLocalStorage.getItem("dYtz").role &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage
                                    .getItem("dYtz")
                                    .role.rights.includes(
                                      "put_on_demand_analytics-" +
                                      sessionStorage.getItem("application")
                                    ) && (
                                    <Tooltip title="Edit">
                                      <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => editQuery(item)}
                                        size="large">
                                        <EditIcon
                                          color="disabled"
                                          style={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {secureLocalStorage.getItem("dYtz") &&
                                  secureLocalStorage.getItem("dYtz").role &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage
                                    .getItem("dYtz")
                                    .role.rights.includes(
                                      "post_on_demand_analytics-" +
                                      sessionStorage.getItem("application")
                                    ) && (
                                    <Tooltip title="Clone">
                                      <IconButton
                                        onClick={() => cloneQuery(item)}
                                        classes={{ root: classes.IconButton }}
                                        size="large">
                                        <img
                                          src={require("../../library/images/Copy.png")}
                                          alt=""
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {secureLocalStorage.getItem("dYtz") &&
                                  secureLocalStorage.getItem("dYtz").role &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage
                                    .getItem("dYtz")
                                    .role.rights.includes(
                                      "put_on_demand_analytics-" +
                                      sessionStorage.getItem("application")
                                    ) && (
                                    <Tooltip title="Delete">
                                      <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => handleDeleteClick(item)}
                                        size="large">
                                        <DeleteIcon
                                          color="disabled"
                                          style={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {secureLocalStorage.getItem("dYtz") &&
                                  secureLocalStorage.getItem("dYtz").role &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage
                                    .getItem("dYtz")
                                    .role.rights.includes(
                                      "run_on_demand_analytics-" +
                                      sessionStorage.getItem("application")
                                    ) && (
                                    <Tooltip title="Mail">
                                      <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() =>
                                          toast.success("Email succssfully sent.")
                                        }
                                        size="large">
                                        <MailOutlineIcon
                                          color="disabled"
                                          style={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </div>
                              <div
                                style={{
                                  marginLeft: 5,
                                  borderLeft: "1px solid lightgray",
                                }}
                              >
                                {secureLocalStorage.getItem("dYtz") &&
                                  secureLocalStorage.getItem("dYtz").role &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage.getItem("dYtz").role
                                    .rights &&
                                  secureLocalStorage
                                    .getItem("dYtz")
                                    .role.rights.includes(
                                      "run_on_demand_analytics-" +
                                      sessionStorage.getItem("application")
                                    ) && (
                                    <Tooltip title="Run">
                                      <IconButton
                                        classes={{ root: classes.IconButton }}
                                        onClick={() => runQuery(item)}
                                        style={{ marginLeft: 5 }}
                                        size="large">
                                        <DirectionsRunIcon
                                          color="disabled"
                                          style={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </div>
                            </div>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={
                queryListData && queryListData.total_record
                  ? queryListData.total_record
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          <Typography variant="h4">There is no data to show now.</Typography>
        )}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} variant="contained" color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    queryListData: state.operationalReportsData.queryListSuccessData,
    loading: state.simulationData.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQueryList: (pagination, limit, type, app) =>
      dispatch(getQueryList(pagination, limit, type, app)),
    runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id, 'dynamicAnalytics')),
    getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
    deleteDynamicQuery: (id, pagination, limit, queryType, app) =>
      dispatch(deleteDynamicQuery(id, pagination, limit, queryType, app)),
    cloneDynamicQuery: (id, pagination, limit, type, app) =>
      dispatch(cloneDynamicQuery(id, pagination, limit, type, app)),
    runDynamicQueryCP: (data, id) =>
      dispatch(runDynamicQuery(data, id, null, "collaboration")),
    runDynamicQueryPS: (data, id) =>
      dispatch(runDynamicQuery(data, id, null, "paymentpartner")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQuery);
