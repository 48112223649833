import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { imHistory } from '../../redux/actions';
import { connect } from 'react-redux';
import {
    Typography,
    useMediaQuery,
    Button,
    DialogTitle,
    Dialog, DialogActions, DialogContent
} from '@mui/material';
import Moment from 'moment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MaterialTable from 'material-table';
import LoadingOverlay from 'react-loading-overlay';
import { tableIcons } from '../../components/Icons/TableIcons';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
}));
const IntegrationManagerHistory = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [currentID, setCurrentID] = React.useState('');
    const { className } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [contractDataArray, setContractDataArray] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [dataRows, setDataRows] = React.useState([]);
    const [errorID, setErrorID] = React.useState(0);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setCurrentID(appId)
        if (appId)
            props.imHistory(appId, 1, 10);

    }, []);
    useEffect(() => {
        if (props.contractCompleteData) {
            setContractDataArray(props.contractCompleteData);
        }
    }, [props.contractCompleteData]);
    function handleItem(id) {
        props.imHistory(id);
    }
    useEffect(() => {
        var tempRows = [];
        if (props.imHistoryData && props.imHistoryData.length > 0)
            props.imHistoryData
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        CreatedAt: e.CreatedAt,
                        name: e.integration.name,
                        activity: e.integration.activity,
                        trigger_mode: e.trigger_mode,
                        status: e.status,
                        integrationLastDataLoaadedOn: e.integration.last_data_loaded_on,
                        filterName: e.integration.filter_name,
                        records_datasource: e.records_datasource,
                        records_created: e.records_created,
                        records_updated: e.records_updated,
                        error_list: e.error_list
                    });
                })
        setDataRows(tempRows);
    }, [props.imHistoryData]);
    const columns = [
        {
            field: 'CreatedAt',
            title: 'Triggered At',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {Moment.utc(rowData.CreatedAt).local().format('MM/DD/YYYY HH:mm A')}</a>

        },
        {
            field: 'name',
            title: 'Integration Name',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.name} </a>
        },
        {
            field: 'activity',
            title: 'Activity',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.activity}</a>
        },
        {
            field: 'trigger_mode',
            title: 'Trigger Mode',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.trigger_mode}</a>
        },
        {
            field: 'status',
            title: 'Status',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.status}</a>

        },
        // {
        //     field: 'filterName',
        //     title: 'Filter',
        //     type: 'string',
        //     render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.filterName + '\n'}</a>

        // },

        {
            field: 'records_datasource',
            title: 'Records Datasource',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}>{rowData.records_datasource} </a>
        },
        {
            field: 'records_created',
            title: 'Records Saved',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}>{rowData.records_created} </a>
        },
        {
            field: 'records_updated',
            title: 'Records Updated',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}>{rowData.records_updated} </a>
        },
        {
            // field: '',
            title: 'Error List',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} >{rowData.error_list != null ? <Button onClick={() => (setOpen(true), setErrorID(rowData.id))} > Show Error </Button> : ''}  </a>
        },
    ];
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <LoadingOverlay
                    active={props.loading}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '50px',
                            '& svg circle': {
                                stroke: '#045FB4'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(52, 52, 52, 0)'
                        }),
                        content: (base) => ({
                            ...base,
                            color: 'black'
                        })
                    }}
                    text='Loading contracts. Please wait ...'
                    className={classes.spinner}
                >
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Typography variant="h2"> Integration Manager History</Typography>
                    </div>
                    {props.imHistoryData && props.imHistoryData.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRows}
                            options={{
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        /> :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </LoadingOverlay>
            </div >
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
            >   <DialogTitle>Error</DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}> <List>
                        {props.imHistoryData && props.imHistoryData.length > 0 ? props.imHistoryData.map((item, index) => {
                            return (<ListItem>
                                {item.ID === errorID ?
                                    <ListItemText
                                        primary={item.error_list}
                                    /> : ' '}
                            </ListItem>
                            )
                        }) :
                            <Typography variant='h4'>
                                There is no data to show now.
                            </Typography>
                        }
                    </List>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={() => setOpen(false)}>Continue</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        imHistoryData: state.customerData.imHistoryData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        imHistory: (id, page, limit) => dispatch(imHistory(id, page, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationManagerHistory);