import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import { List, ListItem, Button, Collapse, ListItemText } from '@mui/material';
import { DashIcon } from '../../../../Icons';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import RowingOutlinedIcon from '@mui/icons-material/RowingOutlined';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PieChartIcon from '@mui/icons-material/PieChart';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { AnalyticsIcon, CalculationIcon, ConfigIcon, ContractIcon, DataSetupIcon, DataSetupTransactionIcon, FinancialIcon, OperationalIcon, HourGlassIcon } from '../../../../Icons';
import { history } from '../../../../../../components/Helpers';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import CallMissedOutlinedIcon from '@mui/icons-material/CallMissedOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import OutlinedFlagOutlinedIcon from '@mui/icons-material/OutlinedFlagOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import Filter1Icon from '@mui/icons-material/Filter1';
import  secureLocalStorage  from  "react-secure-storage";
//navbar
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import './BSNavbar.css';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { connect } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
const useStyles = makeStyles(theme => ({
  root: {},
  icon: {
    color: theme.palette.icon,
    width: 16,
    height: 16,
    display: 'flex',
    paddingLeft: 3
  },
  button: {
    color: theme.palette.white,
    padding: 10,
    paddingTop: 9,
    paddingRight: 0,
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    textTransform: 'none',
    borderRadius: 6
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white,
    textTransform: 'none',
    '&.active, &:hover, &.active:hover': {
      '& path': {
        color: theme.palette.primary.main
      },
      '& span': {
        color: theme.palette.primary.main
      },
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  buttonSub: {
    color: theme.palette.white,
    paddingBottom: 10,
    paddingRight: 0,
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    textTransform: 'none',
    borderRadius: 6,
  },
  activeSub: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white,
    textTransform: 'none',
    '&.active, &:hover, &.active:hover': {
      '& path': {
        color: theme.palette.primary.main
      },
      '& span': {
        color: theme.palette.primary.main
      },
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    paddingLeft: 9,
    fontFamily: 'Roboto Slab, serif',
    fontSize: '1em'
  },
  dividerColor: {
    background: theme.palette.border.divider,
    margin: '20px 0px 20px 0px'
  },
  expandIcon: {
    display: 'flex',
    justifyContent: 'center'
  },
  listSideMenu: {
    fontSize: 13,
    marginTop: 7,
    paddingLeft: 14
  },
  submenuButton: {
    marginTop: 0
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const adminPages = [
  {
    title: 'On Demand Query',
    icon:
      <OperationalIcon />,
    href: '/dynamic-query'
  }
];

const AuditLab = [
  {
    title: 'Contract Change Report',
    icon:
      <DashIcon />,
    href: '/contract-change-reports'
  },


  {
    title: 'Financial Postings History',
    icon:
      <DashIcon />,
    href: '/financial-approvals-reports'
  },
  {
    title: 'Error Log',
    icon:
      <DashIcon />,
    href: '/error-logs'
  },
  {
    title: 'Message Log',
    icon:
      <DashIcon />,
    href: '/message-logs'
  },
  {
    title: 'Exception Management',
    icon:
      <DashIcon />,
    href: '/exception-management-view'
  },

  {
    title: 'On Demand Query',
    icon:
      <OperationalIcon />,
    href: '/dynamic-query'
  },

];

const SideBarNavBS = props => {
  const { pages, superUserPages, className, ...rest } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openContractSetup, setOpenContractSetup] = React.useState(false);
  const [openSupplierChargeBack, setOpenSupplierChargeBack] = React.useState(false);
  // const [openExpectedAccruals, setOpenExpectedAccruals] = React.useState(false);
  const [openCustomerChargeBack, setOpenCustomerChargeBack] = React.useState(false);
  const [openFinancialPostings, setOpenFinancialPostings] = React.useState(false);
  const [openDesigner, setOpenDesigner] = React.useState(false);
  const [openCalculationDesigner, setOpenCalculationDesigner] = React.useState(false);
  const [openClaimDesigner, setOpenClaimDesigner] = React.useState(false);
  const [openFinancialDesigner, setOpenFinancialDesigner] = React.useState(false);
  const [openApprovalDesigner, setOpenApprovalDesigner] = React.useState(false);
  const [openPaymentVariance, setOpenPaymentVariance] = React.useState(false);
  const [openAccrualVariance, setOpenAccrualVariance] = React.useState(false);
  const [openPAC, setOpenPAC] = React.useState(false);
  const [openAccrualPostings, setOpenAccrualPostings] = React.useState(false);
  const [openPaymentPostings, setOpenPaymentPostings] = React.useState(false);
  const [openBudgeting, setOpenBudgeting] = React.useState(false);
  const [openBatchJob, setOpenBatchJob] = React.useState(false);
  const [openTerritory, setOpenTerritory] = React.useState(false);
  const [openDirectSales, setOpenDirectSales] = React.useState(true);
  const [openIndirectSales, setOpenIndirectSales] = React.useState(false);
  const [openPurchase, setOpenPurchase] = React.useState(false);
  const [openForecasting, setOpenForecasting] = React.useState(false);
  const [openHistoricalAnalysis, setOpenHistoricalAnalysis] = React.useState(false);
  const [openAnalytics, setOpenAnalytics] = React.useState(false);
  const [openExternalInputs, setOpenExternalInputs] = React.useState(false);
  const [openPricingConfig, setOpenPricingConfig] = React.useState(false);
  const [openPricingManagment, setOpenPricingManagment] = React.useState(false);
  const [openIntegrationBatchJob, setOpenIntegrationBatchJob] = React.useState(false);
  const [OffinvoiceAdjustments, setOffinvoiceAdjustments] = React.useState(false);
  const [openPriceMaintenance, setOpenPriceMaintenance] = React.useState(false);
  const [openPriceSimulation, setOpenPriceSimulation] = React.useState(false);
  const [openPromoConfig, setOpenPromoConfig] = React.useState(false);
  const [openPromoTransaction, setOpenPromoTransaction] = React.useState(false);
  const [openPromoPricing, setOpenPromoPricing] = React.useState(false);
  const [openPromoIndirectSales, setPromoIndirectSales] = React.useState(false);
  const [openPromoSalesForecast,setPromoSalesForecase] = React.useState(false);
  const [openXREF, setOpenXREF] = React.useState(false);
  const [openPriceOptimization, setOpenPriceOptimization] = React.useState(false);
  const [openConfig, setOpenConfig] = React.useState(false);
  const [openPriceCatalog, setOpenPriceCatalog] = React.useState(false);
  const [openMarketPrice, setOpenMarketPrice] = React.useState(false);
  const [openPriceAgreement, setOpenPriceAgreement] = React.useState(false);
  const [openPricingBatchJob,setOpenPricingBatchJob] = React.useState(false);
  const [openExternalData,setOpenExternalData] = React.useState(false)
  const [openPriceAnalytics,setOpenPriceAnalytics] = React.useState(false)
  const [openPricingExternalData,setOpenPricingExternalData] = React.useState(false);
  const [openPriceReporting,setOpenPriceReporting] = React.useState(false);
  const handleAccrualApprovals = () => {
    history.push('/accrual-approvals')
  }
  const handlePaymentApprovals = () => {
    history.push('/payment-approvals')
  }

  const handleExpansion = (page) => {

    if (page.title === 'Master Data') {
      setOpen(!open);
    }
    if (page.title === 'Contracts') {
      setOpenContractSetup(!openContractSetup);
    }
    if (page.title === 'Potential Action Items') {
      setOpenPAC(!openPAC);
    }
    if (page.title === 'Claims Management') {
      setOpenCustomerChargeBack(!openCustomerChargeBack);
    }
    if (page.title === 'Accrual Postings') {
      setOpenAccrualPostings(!openAccrualPostings);
    }
    if (page.title === 'Payment Postings') {
      setOpenPaymentPostings(!openPaymentPostings);
    }
    // if (page.title === 'Expected Accruals') {
    //   setOpenExpectedAccruals(!openExpectedAccruals);
    // }
    if (page.title === 'Batch Job') {
      setOpenBatchJob(!openBatchJob);
    }
    if (page.title === 'Direct - Sales Data') {
      setOpenDirectSales(!openDirectSales);
    }
    if (page.title === 'Indirect - Sales Data') {
      setOpenIndirectSales(!openIndirectSales);
    }
    if (page.title === 'Direct -Purchase Data') {
      setOpenPurchase(!openPurchase);
    }
    if (page.title === 'Price Config') {
      setOpenPricingConfig(!openPricingConfig);
    }
    if(page.title==='Batch Job') {
      setOpenPricingBatchJob(!openPricingBatchJob);
    }
    if (page.title === 'Price Maintenance') {
      setOpenPriceMaintenance(!openPriceMaintenance);
    }
    if (page.title === 'Price Management') {
      setOpenPricingManagment(!openPricingManagment);
    }
    if (page.title === 'Price Simulation') {
      setOpenPriceSimulation(!openPriceSimulation)
    }
    if (page.title === 'Price Analytics') {
      setOpenPriceAnalytics(!openPriceAnalytics)
    }
    if (page.title === 'Price Reporting') {
      setOpenPriceReporting(!openPriceReporting)
    }
    if (page.title === 'Integration Batch Job') {
      setOpenIntegrationBatchJob(!openIntegrationBatchJob);
    }
    if (page.title === 'XREF') {
      setOpenXREF(!openXREF)
    }
    if (page.title === 'Price Optimization') {
      setOpenPriceOptimization(!openPriceOptimization)
    }
    if (page.title === 'Price Catalog') {
      setOpenPriceCatalog(!openPriceCatalog);
    }
    if (page.title === 'Market Price') {
      setOpenMarketPrice(!openMarketPrice);
    }
    if (page.title === 'Price Contracts') {
      setOpenPriceAgreement(!openPriceAgreement)
    }
    if(page.title === 'External Data') {
      setOpenPricingExternalData(!openPricingExternalData)
    }
  };
  const handleExpansionDesigner = (page) => {
    if (page.title === 'Contract Designer') {
      setOpenDesigner(!openDesigner);
    }
    if (page.title === 'Calculation Designer') {
      setOpenCalculationDesigner(!openCalculationDesigner);
    }
    if (page.title === 'Claim Designer') {
      setOpenClaimDesigner(!openClaimDesigner);
    }
    if (page.title === 'Postings Designer') {
      setOpenFinancialDesigner(!openFinancialDesigner);
    }
    if (page.title === 'Approval Designer') {
      setOpenApprovalDesigner(!openApprovalDesigner);
    }
    if (page.title === 'Budgeting') {
      setOpenBudgeting(!openBudgeting);
    }
    if (page.title === 'Master Data') {
      setOpenExternalInputs(!openExternalInputs);
    }
    if (page.title === 'Historical Analysis') {
      setOpenHistoricalAnalysis(!openHistoricalAnalysis);
    }
    if (page.title === 'Forecasting') {
      setOpenForecasting(!openForecasting);
    }
    if (page.title === 'Analytics') {
      setOpenAnalytics(!openAnalytics);
    }
    if (page.title === 'Configuration') {
      setOpenPromoConfig(!openPromoConfig);
    }
    if (page.title === 'Transaction Data') {
      setOpenPromoTransaction(!openPromoTransaction);
    }
    if (page.title === 'Pricing') {
      setOpenPromoPricing(!openPromoPricing);
    }
    if (page.title === 'Config') {
      setOpenConfig(!openConfig);
    }
    if(page.title === 'External Data') {
      setOpenExternalData(!openExternalData)
    }
  };

  const customerChargeBackPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/dashboard',
      displayName: 'Dashboard'
    },
    {
      title: 'Contracts',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role &&  secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application'))) ?false : 'hide',
      displayName: sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan' : 'Contracts'
    },
    {
      title: 'Calculation Simulation',
      icon:
        <CalculationIcon />,
      href: '/calculation-simulation',
      hidden: true,
      displayName: 'Calculation Simulation'
    },
    {
      title: 'Supplier Claims',
      icon:
        <CalculationIcon />,
      href: '/supplier-incoming-claims',
      displayName: 'Claims Management '
    },
    {
      title: 'Claims Management',
      icon:
        <CalculationIcon />,
      href: '#',
      displayName: 'Claims Management',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('get_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_multiple_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_single_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_claim-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Accrual Postings',
      icon:
        <FinancialIcon />,
      href: '/outgoing-claims-accrual-postings',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&secureLocalStorage.getItem('dYtz').role.rights.includes('run_accrual_post-'+sessionStorage.getItem('application')) ?false : 'hide',
      displayName: 'Accrual Postings',
    },
    {
      title: 'Payment Postings',
      icon:
        <FinancialIcon />,
      href: '/outgoing-claims-payment-postings',
      displayName: 'Payment Postings',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_payment_post-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Operational Reports',
      icon:
        <OperationalIcon />,
      href: '/select-operational-reports',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
      displayName: 'Operational Reports'
    },
    {
      title: 'On Demand Query',
      icon:
        <OperationalIcon />,
      href: '/dynamic-query',
      displayName: 'On Demand Query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Quota Management',
      icon:
        <OperationalIcon />,
      href: '/quota-management',
      displayName: 'Quota Management',
      hidden: sessionStorage.getItem('application') === 'Sales Commission' || sessionStorage.getItem('application') === 'Supplier Rebate' || sessionStorage.getItem('application') === 'Customer Rebate' ? false : 'hide'
    },
    {
      title: 'Performance Metric',
      icon:
        <OperationalIcon />,
      href: '/performance-metric',
      displayName: 'Performance Metric',
      hidden: sessionStorage.getItem('application') === 'Sales Commission' ? false : 'hide'
    },
    {
      title: 'Performance Weightage',
      icon:
        <OperationalIcon />,
      href: '/performance-metric-weightage',
      displayName: 'Performance Weightage',
      hidden: sessionStorage.getItem('application') === 'Sales Commission' ? false : 'hide'
    },
    {
      title: 'Exception Management',
      icon:
        <OperationalIcon />,
      href: '/exception-management',
      hidden: true,
      displayName: 'Exception Management'
    },
    {
      title: 'Potential Action Items',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application')) ? false : 'hide',
      displayName: 'Potential Action Items'
    },
    {
      title: 'Batch Job',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))) ? false : 'hide',
      displayName: 'Batch Job'
    }
  ];
  const masterDataPages = [
    {
      title: 'Customer Master',
      icon:
        <DashIcon />,
      href: '/customer-master',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_customer_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_customer_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
    {
      title: 'Material Master',
      icon:
        <DashIcon />,
      href: '/material-master',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
    {
      title: 'Supplier Master',
      icon:
        <DashIcon />,
      href: '/supplier-master',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_supplier_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_supplier_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_supplier_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Membership',
      icon:
        <DashIcon />,
      href: '/membership',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_membership-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_membership-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_membership-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Sales Bundle',
      icon:
        <DashIcon />,
      href: '/bom',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_sales_bundle-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_sales_bundle-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_sales_bundle-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Employee Master',
      icon:
        <DashIcon />,
      href: '/employee',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_employee-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_employee-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_employee-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_employee_territory_assignment-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Territory Master',
      icon:
        <DashIcon />,
      href: '/terittory',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_territory_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_territory_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_territory_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Attributes',
      icon:
        <DashIcon />,
      href: '/attributes',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_attributes-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Product Group',
      icon:
        <DashIcon />,
      href: '/product-group',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_product_group-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_product_group-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_product_group-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Currency Conversion',
      icon:
        <DashIcon />,
      href: '/currency-conversion',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_currency_conversions-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'UoM',
      icon:
        <DashIcon />,
      href: '/uom',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_uom-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_uom-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_uom-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Product Feature',
      icon:
        <DashIcon />,
      href: '/product-feature',
     // hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_product_feature-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_product_feature-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_product_feature-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'XREF',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_customer_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_customer_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_xref-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contract_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_cot_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_cot_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_cot_xref-'+sessionStorage.getItem('application'))
      ) ?false : 'hide',
    },
  ];
  const transactionDataPages = [
    {
      title: 'Direct - Sales Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_direct_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_direct_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_direct_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_direct_sales_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Indirect - Sales Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_indirect_sales_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Direct -Purchase Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_purchase_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_purchase_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_purchase_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Commitment Data',
      icon:
        <DashIcon />,
      href: '/target-invoice-query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_commitment_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_commitment_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_commitment_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Inventory Data',
      icon:
        <DashIcon />,
      href: '/inventory-data',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_inventory_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Demand Supply',
      icon:
        <DashIcon />,
      href: '/demand-supply',
      //hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_inventory_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Competitor Data',
      icon:
        <DashIcon />,
      href: '/competitor-data',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_competitor_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
  ];
  const PricingPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/pricing-dashboard',
      displayName: 'Dashboard'
    },
    {
      title: 'Price Maintenance',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_price_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_market_price_calculation-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Management',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('run_price_calculation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_price_catalog-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_edi_file-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_policy_exceptions-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Optimization',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('run_forecast_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_historical_price_simulation-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'CPQ',
      icon:
        <DashIcon />,
      href: '/price-simulation-cpq',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('run_cpq-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_aggregated_margin-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_pricing_sim_report-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Analytics',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('get_customer_level_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_level_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_reference_level_analysis-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Reporting',
      icon:
        <DashIcon />,
      href: '#',
    },
    {
      title: 'Price Contracts',
      icon:
      <DashIcon />,
      href:'#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Config',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_formula_price_simulation-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_type-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_list-'+sessionStorage.getItem('application'))
      ) ?false : 'hide',
    },
    {
      title: 'Error Log',
      icon:
        <DashIcon />,
      href: '/error-logs',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Message Log',
      icon:
        <DashIcon />,
      href: '/message-logs',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Batch Job',
      icon:
          <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_error_logs-'+sessionStorage.getItem('application')) ?false : 'hide',
      displayName: 'Batch Job'
  }
  
  ];
  const promotionsPlanningPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/promo-plan-analytics'
    },
    {
      title: 'Budgeting',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('download_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('clone_promotion_setup-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_global_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_global_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_global_setup-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('run_cost_sim-'+sessionStorage.getItem('application')) ||secureLocalStorage.getItem('dYtz').role.rights.includes('run_cost_postings-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_costings-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Analytics',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_analytics-'+sessionStorage.getItem('application'))? false : 'hide'
    },
    {
      title: 'Master Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_coverage_cost-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_coverage_cost-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_finance_cost-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_finance_cost-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_territory-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_territory-'+sessionStorage.getItem('application')) 
      ) ? false : 'hide'
    },
    {
      title: 'Transaction Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_sales_forecast-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_indirect_sales_data-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('build_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_indirect_sales_data-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'On Demand Query',
      icon:
        <DashIcon />,
      href: '/dynamic-query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Configuration',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_dropdown_values-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('create_promotion_planning-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_promotion_planning-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_promotion_planning-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Pricing',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_pricing_attributes-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_type-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_list-'+sessionStorage.getItem('application')) 
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    // {
    //   title: 'Dashboard',
    //   icon:
    //     <DashIcon />,
    //   href: '/dashboard'
    // },
    // {
    //   title: 'Gross to Net Trends',
    //   icon:
    //     <DashIcon />,
    //   href: '/historical-trends'
    // },
    // {
    //   title: 'Forecasting',
    //   icon:
    //     <DashIcon />,
    //   href: '/forecasting'
    // },
    // {
    //   title: 'Budgeting',
    //   icon:
    //     <DashIcon />,
    //   href: '#'
    // },
    // {
    //   title: 'Promotion Plan',
    //   icon:
    //     <DashIcon />,
    //   href: '/promotion-trends'
    // },
    // {
    //   title: 'Profitability Analysis',
    //   icon:
    //     <DashIcon />,
    //   href: '/promotion-sales-analysis'
    // },
    // {
    //   title: 'Time To Market',
    //   icon:
    //     <DashIcon />,
    //   href: '/time-to-market'
    // },
    // {
    //   title: 'Market Share',
    //   icon:
    //     <DashIcon />,
    //   href: '/market-share'
    // }
  ];
  const supplierChargeBackPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/dashboard'
    },
    {
      title: 'Contracts',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
  
    {
      title: 'Claims Management',
      icon:
        <CalculationIcon />,
      href: '/supplier-incoming-claims',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  (secureLocalStorage.getItem('dYtz').role.rights.includes('post_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_response_format-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Claims Status',
      icon:
        <OperationalIcon />,
      href: '/supplierChargeback-claim-status',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('get_claim_status-'+sessionStorage.getItem('application')) ?false : 'hide',
    },
    {
      title: 'Multiple Claims',
      icon:
        <CalculationIcon />,
      href: '/supplier-multiple-claims',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_multiple_claims-'+sessionStorage.getItem('application')) ?false : 'hide',
    },
    {
      title: 'Claims Provision',
      icon:
        <CalculationIcon />,
      href: '/financial-postings',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_claims_provision-'+sessionStorage.getItem('application')) ?false : 'hide',
    },
    {
      title: 'Payment Postings',
      icon:
        <FinancialIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_payment_postings-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_payment_postings-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_payment_post-'+sessionStorage.getItem('application'))) ? false : 'hide'
    },
    {
      title: 'Operational Reports',
      icon:
        <OperationalIcon />,
      href: '/select-operational-reports',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
    {
      title: 'On Demand Query',
      icon:
        <OperationalIcon />,
      href: '/dynamic-query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Potential Action Items',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application')) ? false : 'hide',
    }
  ];
  const designerStudioPages = [
    {
      title: 'Contract Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('contract_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Calculation Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('calculation_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Claim Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('claim_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Postings Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('postings_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Master Data Designer',
      icon:
        <DashIcon />,
      href: '/master-data-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('master_data_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Approval Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approval_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Label Designer',
      icon:
        <DashIcon />,
      href: '/label-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('label_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Display Designer',
      icon:
        <DashIcon />,
      href: '/common-display-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('display_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Analytics Designer',
      icon:
        <DashIcon />,
      href: '/analytics-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('analytics_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Help Desk',
      icon:
        <DashIcon />,
      href: '/help-desk',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('help_desk-'+sessionStorage.getItem('application')) ? false : 'hide',
    }
  ];
  const securityPages = [
    {
      title: 'Organization Setup',
      icon:
        <DashIcon />,
      href: '/organisation-setup'
    },
    {
      title: 'Security Role Designer',
      icon:
        <DashIcon />,
      href: '/access',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_security_role') ? false : 'hide',
    },
    {
      title: 'User Setup',
      icon:
        <DashIcon />,
      href: '/user-role',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_user') ? false : 'hide',
    },
    {
      title: 'Collaboration ID',
      icon:
        <DashIcon />,
      href: '/collaboration-id',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_collaboration') ? false : 'hide',
    }
  ];
  const profitOptimization = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/dashboard'
    },
    {
      title: 'Driver Maintenance',
      icon:
        <DashIcon />,
      href: '/driver-maintenance',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_driver_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_driver_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_driver_data-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Historical Analysis',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('run_flexible_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_flexible_analysis_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_promotion_plan-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_gross_to_net_trends-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('get_profitability_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_regional_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_market_share-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_time_to_market-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Forecasting',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_forecast_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_forecast_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_forecast_config-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_historical_sales_forecast-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_sales_forecast-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_profitability_forecasting-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Config',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_attributes-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_drivers-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_drivers-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_drivers-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_analysis_level-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_level-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_analysis_level-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_analysis_formula-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_formula-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_analysis_formula-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'External Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_analysis_data-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_external_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_external_config-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
  ];
  const IntegrationStudioPages = [
    {
      title: 'Integration Setup',
      icon:
        <DashIcon />,
      href: '/integration-setup',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_integration_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_integration_setup-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Integration Manager',
      icon:
        <DashIcon />,
      href: '/integration-manager',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_integration_setup-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Integration Mapping',
      icon:
        <DashIcon />,
      href: '/integration-mapping',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_integration_mapping-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_mapping-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_integration_mapping-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Integration Batch Job',
      icon:
        <ContractIcon />,
      href: '#',
      displayName: 'Integration Batch Job',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job_status-'+sessionStorage.getItem('application'))) ? false : 'hide',
    }
  
  ];
  const handleClick = (pge) =>{
    history.push(pge)
  }
  return (
    <Navbar
      className={classes.nav}
      variant="dark"
      fixed="top"
      // bg='#445AC7'  bg="#4CAF50" variant="dark"
    >
      
      <Navbar.Brand href="#home">
        <img
          src={require("../../../../../../library/images/new_logo.png")}
          className={classes.logo}
          alt=""
          onClick={() => history.push("/dashboard")}
          style={{ cursor: "pointer" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      
      {sessionStorage.getItem('application') === 'Security Cockpit' &&
        <div>
          {securityPages
            .filter(page => secureLocalStorage.getItem('dYtz').role.name != 'admin' ? page.title != 'Organization Setup' : page)
            .filter(page => secureLocalStorage.getItem('dYtz').role.name === 'admin' ? page.title === 'Organization Setup' : page)
            .filter(item => item.hidden != 'hide')
            .map(page => (
                <Navbar.Collapse id="navbarScroll">
                <Nav.Link onClick={(()=>{handleClick('pricing-dashboard')})} >Dashboard</Nav.Link>
                <NavDropdown title={page.title} id="basic-nav-dropdown">
                <Button
                  className={classes.button}
                  activeClassName={classes.active}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                </Button>
              </NavDropdown>
         </Navbar.Collapse>
            ))}
        </div>
      }
    </Navbar>

  );

};

SideBarNavBS.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SideBarNavBS;
