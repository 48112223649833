import React, { useEffect} from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
} from '@mui/material';
import { makeStyles} from '@mui/styles';
import { viewForecastLevelById } from '../../redux/actions';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const ViewConfig = props => {
    const classes = useStyles();
  const [forecastingPeriod, setForecastingPeriod] = React.useState("");
  const [forecastingAttributes, setForecastingAttributes] = React.useState([]);
  const [forecastingTarget, setForecastingTarget] = React.useState("");
  const [aiAlgorithm, setAiAlgorithm] = React.useState('');
   
    useEffect(() => {
         var pathname = window.location.pathname;
         var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
         if (appId)
             props.viewForecastLevelById(appId);
    }, []);
    function humanize(str) {
      var i, frags = str.split('_');
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }
    function readFormat(array){
      var temp=[];
      for (var i=0; i<array.length; i++) {
        temp.push(humanize(array[i])+", ")
      }
      return temp;
    }
    useEffect(() => {

      if (props.foreCastLevelSingleData) {
               setAiAlgorithm(props.foreCastLevelSingleData.ai_algorithm)
               setForecastingPeriod(props.foreCastLevelSingleData.forecasting_period);
              props.foreCastLevelSingleData &&
                props.foreCastLevelSingleData.forecasting_attributes &&
                props.foreCastLevelSingleData.forecasting_attributes.length > 0 &&
                setForecastingAttributes(
                  props.foreCastLevelSingleData.forecasting_attributes
                );
              setForecastingTarget(props.foreCastLevelSingleData.forecasting_target);
            }
          }, [props.foreCastLevelSingleData]);

    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>View Config</Typography>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container  spacing={2} style={{paddingLeft:10}}>
                           {forecastingPeriod &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                   
                                >
                                    <LabelText heading='Forecasting Period' data={humanize(forecastingPeriod)} twoline='true' />
                                </Grid>
                            }
                            {forecastingAttributes &&
                                <Grid
                                    item
                                    md={9}
                                    xs={12}
                                   
                                >
                                    <LabelText heading='Forecasting Attributes' data={readFormat(forecastingAttributes)} twoline='true' />
                                </Grid>
                            }
                            {forecastingTarget &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                   
                                >
                                    <LabelText heading='Forecasting Target' data={humanize(forecastingTarget)} twoline='true' />
                                </Grid>
                            }
                            {aiAlgorithm &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                   
                                >
                                    <LabelText heading='AI Model' data={humanize(aiAlgorithm)} twoline='true' />
                                </Grid>
                            }
                    </Grid>

                </div>

               
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
      viewForecastLevelById: (id) => dispatch(viewForecastLevelById(id)),
    }
}

const mapStateToProps = state => {
    return {
      foreCastLevelSingleData: state.forecastData.foreCastLevelSingleData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewConfig);