import React, { useEffect, useState } from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, Typography, OutlinedInput, Select, Button, FormLabel, MenuItem, TextField, FormControl } from '@mui/material';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, BarSeries, Highlight, } from '@syncfusion/ej2-react-charts';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadVarianceCombinedNew, loadValuesBasedOnAppTypeAndField, allowedApps, dashboardAnalyticsNames } from '../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Chart } from "react-google-charts";
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Paper } from '@mui/material';
import LoadingOverlay from "react-loading-overlay";
import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Page, Sort, Resize, Aggregate,
    ExcelExport, Toolbar, PdfExport, Search, CommandColumn, AggregateDirective, AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, ColumnMenu, VirtualScroll,
} from '@syncfusion/ej2-react-grids';
import RingLoader from "react-spinners/RingLoader";
import { Browser } from "@syncfusion/ej2-base";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { ChipInput } from "../../components/Inputs";


const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px'
    },
    rangeContainer: {
        padding: '20px 30px 20px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    select: {
        width: '90%',
        color: '#1675e0',
    },
    select1: {
        width: '80%',
        color: '#1675e0',
    },
    selectedItem: {
        borderRadius: 5
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 34,
        paddingBottom: 15,
    },
    button: {
        marginRight: 25,
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    gridBox: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginBottom: 10,
        //overflowX: 'hidden',
        overflowY: 'scroll'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableCell: {
        paddingLeft: 18
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: -1,
        height: 37,
        padding: 0,
        marginBottom: 14,
        width: "14rem"
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    spinner: {
        height: '100vh'
    },
}));
const AccrualVariance = (props) => {
    let grid;
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [accrualFilter, setAccrualFilter] = React.useState();
    const [accrualFilterEnd, setAccrualFilterEnd] = React.useState();
    //
    const options = ["=", '>', '<', '>=', '<='];
    const [optionType, setOptionType] = React.useState('');
    const [output, setOutput] = React.useState('Chart');
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const [showTable, setShowTable] = useState(true);
    const [filterData, setFilterData] = React.useState([]);
    const [postingType, setPostingType] = React.useState('accrual');
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [varianceType, setVarianceType] = React.useState('Contract Variance');
    const [aggregationField, setAggregationField] = React.useState('contract_type');
    //

    useEffect(() => {
        props.allowedAnalyticsApps(sessionStorage.getItem("application"));
        props.loadSourceDataType(sessionStorage.getItem("application"), 'source_data_type');
    }, []);

    useEffect(() => {
        if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
            const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
                item.analytic_name === "Postings Variance by Contract Groupings"
            );
            if (postingsSummaryAnalytic && postingsSummaryAnalytic.filters) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
            }
        }
    }, [props.allowedAnalyticsAppsData])

    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
            setSourceDataType(allowedTilesFilter[0].data_source_type)
            setPostingType(allowedTilesFilter[0].posting_type)
            setAggregationField(convertToSnakeCase(allowedTilesFilter[0].aggregation_field))
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        // setStartDate(Moment().subtract(2, 'months').startOf('month'));
        // setEndDate(Moment().subtract(1, 'months').endOf('month'));
        var temp = [['Aggregation Field', 'Variance']];
        setAvssGraphData(temp)
    }, []);
    useEffect(() => {
        if (postingType && sourceDataType && startDate && endDate) {
            props.loadVarianceGraph(startDate, endDate, postingType, sessionStorage.getItem("application"), sourceDataType, aggregationField);
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        if (props.varianceCombinedData && accrualFilterEnd === undefined) {
            if (props.varianceCombinedData && props.varianceCombinedData.length > 0) {
                var data = [];
                props.varianceCombinedData.forEach((item, index) => {
                    data.push({ x: item.aggregation_field, y: item.incentive_amount_variance });
                });
                var graphData = [
                    {
                        data: data,
                        xName: "x",
                        yName: "y",
                        legend: "Incentive Amount Variance",
                        type: "Column",
                        color: ['#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200']
                    },
                ];
                setAvssGraphData(graphData);
            }
            else {
                setAvssGraphData([]);
            }
        }
        else if (accrualFilterEnd !== undefined) {
            if (props.varianceCombinedData && props.varianceCombinedData.length > 0) {
                var data = [];
                props.varianceCombinedData.forEach((item, index) => {
                    data.push({ x: item.aggregation_field, y: item.incentive_amount_variance });
                });
                var filteredData = data.filter(item => {
                    switch (optionType) {
                        case '=':
                            return item.y === accrualFilterEnd;
                        case '>':
                            return item.y > accrualFilterEnd;
                        case '<':
                            return item.y < accrualFilterEnd;
                        case '>=':
                            return item.y >= accrualFilterEnd;
                        case '<=':
                            return item.y <= accrualFilterEnd;
                        default:
                            return true; // No filter or unknown option
                    }
                });
                var graphData = [
                    {
                        data: filteredData,
                        xName: "x",
                        yName: "y",
                        legend: "Incentive Amount Variance",
                        type: "Column",
                        color: ['#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200', '#2485FA', '#FEC200', '#ACC200']
                    },
                ];
                setAvssGraphData(graphData);
            }
            else {
                setAvssGraphData([]);
            }

        }
    }, [props.varianceCombinedData, accrualFilterEnd]);

    useEffect(() => {
        if (filterData.length > 0) {
            props.loadVarianceGraph(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, applicationType, sourceDataType, aggregationField, filterData);
        }
    }, [filterData]);

    useEffect(() => {
        var newArray = []
        var newArray1 = []
        if (props.varianceCombinedData && props.varianceCombinedData.length > 0) {
            props.varianceCombinedData.map(item => {
                const newItem = {};
                Object.entries(item).map(([key, value]) => {
                    newArray.push({ 'field': key, 'title': key.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) });
                    const formattedKey = key.replace(/(?:_| |\b)(\w)/g, function ($1) {
                        return $1.toUpperCase().replace('_', ' ');
                    });
                    newItem[formattedKey] = value;
                });
                newArray1.push(newItem);
            })
            setDataRows(newArray1);
            setDataCount(props.varianceCombinedData.length)
        }
    }, [props.varianceCombinedData]);


    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])


    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }

    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    }

    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])


    const handlePostingType = (e) => {
        setPostingType(e.target.value);
        setVarianceType('');
    }

    const handleAggregationField = (e) => {
        setAggregationField(e.target.value);
    }

    const handleStartDate = (e) => {
        setStartDate(e);
    }

    const handleEndDate = (e) => {
        setEndDate(e);
    }

    const handleVarience = (e) => {
        setOptionType(e.target.value);
    }

    const handleOutput = (e) => {
        setOutput(e.target.value);
    }

    const handleRun = () => {
        if (postingType && sourceDataType && startDate && endDate) {
            props.loadVarianceGraph(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, sessionStorage.getItem("application"), sourceDataType, aggregationField);
        }
    }

    const filterSettings = { type: 'CheckBox' };
    const groupOptions = { showGroupedColumn: true };
    const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search'];
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
            grid.showSpinner();
            const exportProperties = {
                pageOrientation: 'Landscape',
                pageSize: 'A0',
                fileName: Moment.utc(new Date()).format('MM/DD/YYYY') + '.pdf',
                theme: {
                    header: {
                        bold: true,
                        fontSize: 14,
                    },
                    record: {
                        bold: false,
                        fontSize: 14,
                    },
                    caption: {
                        bold: true,
                        fontSize: 14,
                    },
                }
            };
            grid.pdfExport(exportProperties);
        }
        if (grid && args.item.id === 'grid_excelexport') {
            grid.showSpinner();
            const excelExportProperties = {
                dataSource: [...dataRows],
            };
            grid.excelExport(excelExportProperties);
        }
    };

    const pdfExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const excelExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const valueFormat = (field, data, column) => {
        if (data && data[field].includes('.')) {
            return parseFloat(data[field]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).toString()

        }
        else if (data && data[field].includes('T00:00:00Z')) {
            return Moment(data[field], true).isValid()
                ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
        }
        else
            return data[field];
    };

    // function handleFilterData(newValue, type) {
    //     let temp = [...filterData];
    //     temp.push(newValue)
    //     setFilterData(temp);
    // };
    function handleFilterData(newValue) {
        setFilterData(newValue)
    }
    function handleDeleteChip(value, index) {
        var deletedFilterData = filterData.filter(item => item !== value)
        setFilterData(deletedFilterData)
    };
    const loaded = (args) => {
        let chart = document.getElementById("charts");
        chart.setAttribute("title", "");
    };
    const load = (args) => {
        let selectedTheme = "Material";
        args.chart.theme = (
            selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
        )
            .replace(/-dark/i, "Dark")
            .replace(/contrast/i, "Contrast");
        if (selectedTheme === "highcontrast") {
            args.chart.series[0].marker.dataLabel.font.color = "#000000";
            args.chart.series[1].marker.dataLabel.font.color = "#000000";
            args.chart.series[2].marker.dataLabel.font.color = "#000000";
        }
    };
    function convertToSnakeCase(string) {
        return string.replace(/\s+/g, '_').toLowerCase();
    }
    // console.log("graph Data", avssGraphData)

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>

                    <Grid container style={{ display: 'flex', flexWrap: 'nowrap', margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid container md={6} xs={12}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            //style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Source Data Type</Typography>
                                    <Select
                                        value={sourceDataType}
                                        onChange={handleSourceDataType}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {sourceDataTypeDataFromAPI
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid item container md={6} xs={12} >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                //style={{ paddingRight: 15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '1px 0 1px 11px',
                                                            // alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '90%',
                                                            borderRadius: 5,
                                                            height: 37,
                                                            color: '#1675e0'
                                                        }
                                                    }}
                                                    value={startDate}
                                                    onChange={handleStartDate}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                //style={{ paddingRight: 15 }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '1px 0 1px 11px',
                                                            // alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '90%',
                                                            borderRadius: 5,
                                                            height: 37,
                                                            color: '#1675e0'
                                                        }
                                                    }}
                                                    value={endDate}
                                                    onChange={handleEndDate}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={startDate ? startDate : false}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            //style={{ paddingRight: 15 }}
                            >
                                <Typography classes={{ root: classes.fontSetting }} >Posting Type</Typography>

                                <Select
                                    value={postingType}
                                    onChange={handlePostingType}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'accrual'}>
                                        Accrual
                                    </MenuItem>
                                    <MenuItem value={'payment'}>
                                        Payment
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container md={6} xs={12}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            //style={{ paddingRight: 15 }}
                            >
                                <Typography classes={{ root: classes.fontSetting }} >Aggregation Field</Typography>
                                <Select
                                    value={aggregationField}
                                    onChange={handleAggregationField}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'contract_type'}>
                                        Contract Type
                                    </MenuItem>
                                    <MenuItem value={'contract_group'}>
                                        Contract Group
                                    </MenuItem>
                                    <MenuItem value={'contract_sub_group'}>
                                        Contract Sub Group
                                    </MenuItem>
                                    <MenuItem value={'calculation_method'}>
                                        Calculation Method
                                    </MenuItem>
                                </Select>
                            </Grid>

                            <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ paddingRight: 25 }}
                            // classes={{ root: classes.gridContainer }}
                            >
                                <Typography classes={{ root: classes.fontSetting }}
                                    style={{ marginLeft: "5rem" }}
                                >Filter </Typography>
                                <ChipInput style={{ height: 38 }} data={filterData} onChange={handleFilterData} />
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={filterData}
                                    onAdd={(chips) => handleFilterData(chips)}
                                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                /> */}
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ paddingLeft: 10 }}
                            >
                                <Typography classes={{ root: classes.fontSetting }} style={{ marginLeft: 15 }} >Output</Typography>
                                <Select
                                    value={output}
                                    onChange={handleOutput}
                                    className={clsx({
                                        [classes.select1]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'List'}>
                                        List
                                    </MenuItem>
                                    {aggregationField !== 'customer_number' && aggregationField !== 'material_number' && (
                                        <MenuItem value={'Chart'}>Chart</MenuItem>
                                    )}
                                    {/* <MenuItem value={'Chart'}>
                                Chart
                            </MenuItem> */}
                                </Select>
                            </Grid>

                            <Grid
                                item
                                md={2}
                                xs={12}
                            //style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    <Typography style={{ marginLeft: 20 }} classes={{ root: classes.fontSetting }} >Variance</Typography>

                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 37 }}>
                                        <Select
                                            value={optionType}
                                            onChange={handleVarience}
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                        >
                                            {options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <OutlinedInput
                                            value={accrualFilterEnd}
                                            type={'number'}
                                            inputProps={{ min: "0", step: "1" }}
                                            onChange={e => setAccrualFilterEnd(e.target.value)}
                                            style={{ borderRadius: 5, height: 36, marginLeft: 10 }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleRun}
                                twoline="true"
                                disabled={(!postingType || !sourceDataType || !startDate || !endDate) ? true : false}
                            >
                                <DirectionsRunIcon />
                            </Button>
                        </div>
                    </Grid>

                    <Grid
                        item
                        md={12}
                        xs={6}
                        className={classes.container}
                    >
                        {/* {props.varianceCombinedData && props.varianceCombinedData.length > 0 ? ( */}
                        {output === 'Chart' && props.varianceCombinedData && props.varianceCombinedData.length > 0 ? (
                            <>
                                <div>
                                    <div>
                                        <ChartComponent
                                            id='charts'
                                            style={{ textAlign: "center" }}
                                            legendSettings={{ enableHighlight: true }}
                                            primaryXAxis={{
                                                valueType: 'Category',
                                                labelIntersectAction: Browser.isDevice ? "None" : "Trim",
                                                labelRotation: Browser.isDevice ? -45 : 0,
                                                interval: 1,
                                                majorGridLines: { width: 0 },
                                                majorTickLines: { width: 0 },
                                            }}
                                            primaryYAxis={{
                                                labelFormat: '{value}',
                                                // title: 'Varience',
                                                edgeLabelPlacement: 'Shift',
                                                majorTickLines: { width: 0 },
                                                lineStyle: { width: 0 }
                                            }}
                                            chartArea={{ border: { width: 0 } }}
                                            load={load.bind(this)}
                                            loaded={loaded.bind(this)}
                                            title='Incentive Amount Varience'
                                            tooltip={{ enable: true }}>
                                            <Inject services={[BarSeries, DataLabel, Category, Legend, Tooltip, Highlight]} />
                                            <SeriesCollectionDirective>
                                                {avssGraphData &&
                                                    avssGraphData.length > 0 &&
                                                    avssGraphData.map((item, i) => (
                                                        <SeriesDirective
                                                            key={i}
                                                            dataSource={item.data}
                                                            xName={item.xName}
                                                            yName={item.yName}
                                                            columnSpacing={0.1}
                                                            width={2}
                                                            name='Varience'
                                                            type='Bar'
                                                        // fill={'#ACC200'}
                                                        />
                                                    ))}
                                            </SeriesCollectionDirective>
                                        </ChartComponent>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Typography variant="h5" style={{ textAlign: 'center' }} color="primary">
                                No data to show
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={6}
                        className={classes.container}
                    >
                        {output === 'List' && props.varianceCombinedData && props.varianceCombinedData.length > 0 ? (
                            <Paper className={classes.gridBox} style={{ paddingTop: 0, }}>
                                <div style={{ marginTop: 10, overflow: 'scroll' }}>
                                    {/* {console.log(dataRows)} */}
                                    <GridComponent
                                        dataSource={dataRows}
                                        id='grid'
                                        allowSorting={true} allowGrouping={true} showColumnMenu={true}
                                        // allowPaging={true}
                                        // pageSettings={{ pageSize: 10000 }}
                                        groupSettings={groupOptions}
                                        filterSettings={filterSettings}
                                        allowFiltering={true}
                                        ref={g => grid = g}
                                        toolbar={toolbarOptions}
                                        toolbarClick={toolbarClick}
                                        allowPdfExport={true} allowExcelExport={true}
                                        pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
                                        height={window.screen.height * .64}
                                        gridLines='Both' allowResizing={true}
                                        enableVirtualization={true} enableColumnVirtualization={false}
                                    >
                                        <ColumnsDirective>
                                            {columns.map((item, order) => {
                                                return (
                                                    <ColumnDirective
                                                        field={item.field}
                                                        valueAccessor={valueFormat}
                                                        headerText={item.title}
                                                        width="200px" minWidth='100px' maxWidth='300px'

                                                    />
                                                );
                                            })
                                            }
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, PdfExport, ExcelExport, Aggregate, Group, Sort, ColumnMenu, Filter, VirtualScroll, Resize, Page]} />
                                    </GridComponent>
                                </div>
                            </Paper>

                        ) : (
                            <Typography variant='h4'>
                                {/* There is no data to show noww. */}
                            </Typography>
                        )

                        }

                    </Grid>

                </Grid>
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        varianceCombinedData: state.initialData.varianceCombinedData,
        loading: state.initialData.loading,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadVarianceGraph: (s, e, type, applicationType, sourceDataType, aggregationField, filterData) => dispatch(loadVarianceCombinedNew(s, e, type, applicationType, sourceDataType, aggregationField, filterData)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps()),
        allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccrualVariance);