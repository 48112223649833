import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    FormLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { runBuildAnalysis, getAnalysisLevel, getDrivers } from '../../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const BuildAnalysisDataQuery = props => {
    const classes = useStyles();
    const { className } = props;
    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [analysisLevelID, setAnalysisLevelID] = React.useState('');
    const [driverName, setDriverName] = React.useState([]);
    const [analysisLevelArray, setAnalysisLevelArray] = React.useState([]);
    const [driverNameArray, setDriverNameArray] = React.useState([]);
    const [open, setOpen] = useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.getConfigData(1, 0);
        props.getAnalysisLevel(1, 0)
    }, []);
    useEffect(() => {
        var newArray = []
        if (props.configData && props.configData.length > 0) {
            props.configData.map(e => {
                newArray.push(e.driver_name)
            })
        }
        setDriverNameArray(newArray)
    }, [props.configData]);
    useEffect(() => {
        var newArray = []
        if (props.analysisLevelArrayData && props.analysisLevelArrayData.records) {
            props.analysisLevelArrayData.records.map(e => {
                newArray.push(e.analysis_level_id)
            })
        }
        setAnalysisLevelArray(newArray)
    }, [props.analysisLevelArrayData])
    const formData = {
        "start_date": billingDateStart ? Moment(billingDateStart).format('YYYY-MM-DD') : '',
        "end_date": billingDateEnd ? Moment(billingDateEnd).format('YYYY-MM-DD') : '',
        // "analysis_level_id":analysisLevelID,
        "driver_name": driverName
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData, analysisLevelID);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setDriverName([]);
        setAnalysisLevelID('');
    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleDriverName = (value) => {
        setDriverName(value);
    }
    function handleAnlysisLevel(newValue) {
        setAnalysisLevelID(newValue)
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 18 }}> Build Analysis Data </Typography>
                </div>
                <div className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container >

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >

                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%',
                                                                height: '2.3rem'
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={handleBillingDateStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                            {/* </div> */}
                                            {/* <div className={classes.contractContiner}> */}
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: 10,
                                                                border: '1px solid #E0E0E0',
                                                                width: '96%',
                                                                marginLeft: 20,
                                                                height: '2.3rem'
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={handleBillingDateEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart ? billingDateStart : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>
                                            Analysis Level ID
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={analysisLevelID}
                                            onChange={(e) => handleAnlysisLevel(e.target.value)}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {analysisLevelArray &&
                                                analysisLevelArray.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            value={item}
                                                            key={item}
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                            Driver Name
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={driverName}
                                            onChange={(e) => handleDriverName(e.target.value)}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {driverNameArray &&
                                                driverNameArray.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            value={item}
                                                            key={item}
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                    Clear
                                </Button>
                                <Button
                                    variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                    disabled={billingDateStart && billingDateEnd && analysisLevelID
                                        ? false : true}
                                >
                                    {
                                        props.loading ?
                                            <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                            :
                                            'Run'
                                    }
                                </Button>
                            </div>
                        </div>
                    </form>
                    <SimpleDialog open={open} content='Do you want to Build Analysis Data?' handleDialog={handleDialog} />
                </div>
            </div>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.profitOptimizationData.priceTypeData,
        analysisLevelArrayData: state.profitOptimizationData.priceListMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData, analysisLevelID) => dispatch(runBuildAnalysis(formData, analysisLevelID)),
        getConfigData: (pagination, limit) => dispatch(getDrivers(pagination, limit)),
        getAnalysisLevel: (pagination, limit) => dispatch(getAnalysisLevel(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BuildAnalysisDataQuery);