import Palette from '../palettePink'
export default {
  root: {
    border: '1px solid #EEEEEE',
    borderBottomWidth: '1px',
    padding: 7
  },
  head: {
    backgroundColor: Palette.primary.main+'!important' ,
    color: 'white'
  },
};
