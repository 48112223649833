import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button, Breadcrumbs
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getIncomingClaimResponseFormat,
    incomingClaimResponseFormat
} from '../../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const IncomingClaimFormatMain = props => {
    useEffect(() => {
        props.getIncomingClaimFormatData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [quota, setQuota] = useState([]);



    useEffect(() => {
        if (props.incomingClaimFormatData) {
            setQuota(props.incomingClaimFormatData)
        }
    }, [props.incomingClaimFormatData]);

    function editQuery(item) {
        sessionStorage.setItem('incoming-claim-format', JSON.stringify(item))
        history.push('/supplier-incoming-claim-format/edit-claims-query/' + item.id);

    }
    function viewQuery(item) {
        sessionStorage.setItem('incoming-claim-format', JSON.stringify(item))
        history.push('/supplier-incoming-claim-format/view-claims-query/' + item.id);
    }
    function deleteQuery(item) {
        props.deleteIncomingClaimFormatFormatData(item, item.id);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/supplier-incoming-claims'
                >
                    Incoming Claims
                </Link>
                <Typography color="textPrimary" variant='h4'>Incoming Claim Response Format</Typography>
            </Breadcrumbs>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h2"></Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => history.push('/supplier-incoming-claim-format/add-claims-query')}
                >
                    {isDesktop ? 'Add Format' : ''}
                </Button>
            </div>
            {
                quota && quota.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center'>Format ID</TableCell>
                                    <TableCell align='center'>Format</TableCell>
                                    <TableCell align='center'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {quota
                                    .sort((a, b) => a.id > b.id ? 1 : -1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.id} >
                                                <TableCell align='center' style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                                <TableCell align='center' style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' width={300}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <VisibilityIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>

                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => deleteQuery(item)}
                                                            size="large">
                                                            <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        incomingClaimFormatData: state.dataSetupData.incomingClaimFormatData,
        loading: state.dataSetupData.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteIncomingClaimFormatFormatData: (data, id) => dispatch(incomingClaimResponseFormat(data, id, 'delete')),
        getIncomingClaimFormatData: () => dispatch(getIncomingClaimResponseFormat()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomingClaimFormatMain);