import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest} render={props => (
            secureLocalStorage.getItem('rFtk')
                ? <Redirect to="/applauncher" /> // redirct to dahboard to applauncher
                : <Component {...props} />
        )} />
)