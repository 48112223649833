import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Paper, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { addContractGeneralData, searchContracts } from '../../../redux/actions';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  searchInput: {
    marginRight: 47
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  rootSearch: {
    borderBottomColor: theme.palette.table.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const ContractSetupToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [searchText, setSearchText] = React.useState('');
  const addContract = () => {
    localStorage.removeItem('currentAddContractID');
    sessionStorage.setItem('mode', 'add');
    history.push('contract-setup/add-contract');
  }
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  }

  return (
    <div
      {...rest}
      className={classes.root}
    >
      <div className={clsx({ [classes.searchInput]: isDesktop })}>
        <Paper
          {...rest}
          className={clsx(classes.rootSearch, className)}
        >
          <SearchIcon className={classes.icon} />
          <Input
            {...rest}
            className={classes.input}
            disableUnderline
            placeholder="Search"
            onChange={handleSearchOnChange}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="Close"
            onClick={() => props.onSearch(searchText)}
            size="large">
            <CheckIcon className={classes.icon} />
          </IconButton>
        </Paper>
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddBoxOutlinedIcon />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to='/contract-setup/add-contract'
        onClick={addContract}
      >
        {isDesktop ? 'Create Contract' : ''}
      </Button>
    </div >
  );

};

ContractSetupToolbar.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (data) => dispatch(addContractGeneralData(data)),
    onSearch: (keyword) => dispatch(searchContracts(keyword))
  }
}

export default connect(null, mapDispatchToProps)(ContractSetupToolbar);