import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
import { Select, MenuItem, FormLabel, Typography, Grid, Button, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import BulletChart from '../../Charts/BulletChart';
import Moment from 'moment';
import clsx from 'clsx';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { geographicalIncentive, getNewCustomers, loadAccrualVsPaymentGraph } from '../../../../redux/actions';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  root: {
    padding: '15px 0px 15px 0px',
    display: 'flex',
    alignItems: 'center'
  },
  rootDiv: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    margin: '5px 0px 16px 0px',
  },
  select: {
    width: "100%",
    color: "#1675e0",
  },
  selectedItem: {
    borderRadius: 5,
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 31,
    paddingBottom: 5,
  },
  button: {
    marginRight: 10,
    // width: 80,
  },
}));
const NewCust = props => {
  const classes = useStyles();
  const [customerGraphData, setCustomerGraphData] = React.useState([]);
  const [maxData, setMaxData] = React.useState({});
  const [type, setType] = React.useState("Customer Master");
  const masterDataArray = ["Customer Master", "Employee Master", "Material Master", 'Supplier Master']
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [sourceDataType, setSourceDataType] = React.useState("Customer Master");

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "New Master Data Incentives" && item.dashboard_name === "Postings Detail Analysis"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  // useEffect(() => {
  //   if (allowedTilesFilter.length > 0) {
  //     setStartDate(allowedTilesFilter[0].start_date);
  //     setEndDate(allowedTilesFilter[0].end_date);
  //     setSourceDataType(allowedTilesFilter[0].master_data_type);
  //   }
  // }, [allowedTilesFilter]);
  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      const firstTile = allowedTilesFilter[0];
      if (firstTile && firstTile.start_date && firstTile.end_date) {
        setStartDate(firstTile.start_date);
        setEndDate(firstTile.end_date);
      }
      if (firstTile && firstTile.master_data_type) {
        setSourceDataType(firstTile.master_data_type);
      }
    }
  }, [allowedTilesFilter]);


  useEffect(() => {
    if (startDate && endDate && sourceDataType) {
      props.getNewCust({
        start_date: Moment(startDate).format('MM/DD/YYYY'),
        end_date: Moment(endDate).format('MM/DD/YYYY'),
        master_data_type: sourceDataType
      });
    }
  }, [allowedTilesFilter]);

  const handleStartDate = (e) => {
    setStartDate(e);
    // if (endDate && e && e.isValid()) {
    //   props.getNewCust({
    //     start_date: Moment(e).format('MM/DD/YYYY'),
    //     end_date: Moment(endDate).format('MM/DD/YYYY'),
    //     master_data_type: sourceDataType
    //   });
    // }
  }
  const handleEndDate = (e) => {
    setEndDate(e);
    // if (startDate && e && e.isValid()) {
    //   props.getNewCust({
    //     start_date: Moment(startDate).format('MM/DD/YYYY'),
    //     end_date: Moment(e).format('MM/DD/YYYY'),
    //     master_data_type: sourceDataType
    //   });
    // }
  }

  const handlemasterdataType = (e) => {
    setSourceDataType(e.target.value);
    // var data = {
    //   'start_date': Moment(startDate).format('MM/DD/YYYY'),
    //   'end_date': Moment(endDate).format('MM/DD/YYYY'),
    //   'master_data_type': e.target.value
    // }
    // props.getNewCust(data)
  }
  function handleSubmit() {
    props.getNewCust({
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      master_data_type: sourceDataType
    });
  }

  // useEffect(() => {
  //   // var data = {
  //   //   start_date: Moment.utc()
  //   //     .subtract(1, "months")
  //   //     .startOf("month")
  //   //     .format("MM/DD/YYYY"),
  //   //   end_date: Moment.utc()
  //   //     .endOf("month")
  //   //     .format("MM/DD/YYYY"),
  //   // };
  //   var data = {
  //     'start_date': '01/01/2023',
  //     'end_date': '02/28/2023',
  //     'master_data_type': type
  //   }
  //   props.getNewCust(data)
  // }, [type])


  useEffect(() => {
    if (props.newCustomer && Object.values(props.newCustomer).length > 0) {
      setCustomerGraphData(props.newCustomer)
      var max = 100
      var maxValue = {
        'countMax': props.newCustomer.Count ? parseInt(props.newCustomer.Count) + 100 : 100,
        'salesMax': props.newCustomer.Sales ? (parseInt(props.newCustomer.Sales) + 100) : 100,
        'accrualMax': props.newCustomer.Accrual ? (parseInt(props.newCustomer.Accrual) + 100) : 100,
        'paymentMax': props.newCustomer.Payment ? (parseInt(props.newCustomer.Payment) + 100) : 100,
      }
      setMaxData(maxValue)
    }
  }, [props.newCustomer]);
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid container style={{ display: 'flex', flexWrap: 'nowrap', padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}
        >
          <Grid
            item
            md={3}
            xs={12}
            style={{ paddingRight: 15 }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container justifyContent="space-around">
                  <DatePicker
                    disableToolbar
                    clearable
                    InputProps={{
                      padding: 0,
                      disableUnderline: true,
                      style: {
                        // padding: '1px 0 1px 11px',
                        alignSelf: 'center',
                        alignItems: 'center',
                        border: '1px solid #E0E0E0',
                        width: '100%',
                        borderRadius: 5,
                        height: 37,
                        color: '#1675e0'
                      }
                    }}
                    value={startDate}
                    onChange={handleStartDate}
                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                    format="MM/DD/YYYY"
                  />
                </Grid>
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            style={{ paddingRight: 15 }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container justifyContent="space-around">
                  <DatePicker
                    disableToolbar
                    clearable
                    InputProps={{
                      padding: 0,
                      disableUnderline: true,
                      style: {
                        // padding: '1px 0 1px 11px',
                        alignSelf: 'center',
                        alignItems: 'center',
                        border: '1px solid #E0E0E0',
                        width: '100%',
                        borderRadius: 5,
                        height: 37,
                        color: '#1675e0'
                      }
                    }}
                    value={endDate}
                    minDate={startDate ? startDate : false}
                    onChange={handleEndDate}
                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                    format="MM/DD/YYYY"
                  />
                </Grid>
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ paddingRight: 15 }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography classes={{ root: classes.fontSetting }} >Type</Typography>
              <Select
                value={sourceDataType}
                onChange={handlemasterdataType}
                displayEmpty
                className={clsx({
                  [classes.select]: true
                })}
                classes={{
                  selectMenu: classes.selectedItem
                }}
              >
                {masterDataArray && masterDataArray.map((item, index) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
              twoline="true"
              disabled={startDate && endDate && sourceDataType ? false : true}
            >
              <DirectionsRunIcon />
            </Button>
          </div>
        </Grid>
        <Grid container className={classes.root}>
          <Grid
            item
            md={12}
            xs={6}
            className={classes.container}
          >
            {/* <Typography variant="h4" style={{ textAlign: 'center' }} color="primary" > Sales vs. Payment </Typography> */}
            {customerGraphData && Object.values(customerGraphData).length > 0 ?
              <BulletChart bulletChartData1={customerGraphData.Count}
                bulletChartData2={customerGraphData.Sales}
                bulletChartData3={customerGraphData.Accrual}
                bulletChartData4={customerGraphData.Payment}
                maxData={maxData}
              />
              :
              <div className={classes.emptyContainer}> No data</div>
            }
          </Grid>
        </Grid>

      </Grid >
    </div>
  );
};
const mapStateToProps = state => {
  return {
    newCustomer: state.initialData.newCustomerData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getNewCust: (data) => dispatch(getNewCustomers(data)),
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType) => dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCust);