import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { Button,useMediaQuery } from '@mui/material';
import {AddBoxOutlined} from '@mui/icons-material';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  rootSearch: {
    borderBottomColor: theme.palette.table.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const PricingBatchJobSetupToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  return (
    <div
      {...rest}
      className={classes.root}
    >
      
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddBoxOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to='/pricing-batchjob-setup/add-batchjob-setup'
      >
        {isDesktop ? 'Add Batch Job' : ''}
      </Button>
    </div >
  );

};


export default connect(null, null)(PricingBatchJobSetupToolbar);