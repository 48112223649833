import React, { useEffect, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    IconButton,
    Dialog,
    Grid,
    FormLabel,
    DialogContent, DialogTitle, Box, Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';

import { LabelText } from '../../../../components/Inputs';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {createTheme } from "@mui/material/styles";
import { EditText} from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import Moment from 'moment';
import { updateOutgoingClaimData, getClaimConfiguration } from '../../../../redux/actions';
const useStyles = makeStyles(theme => ({
    rootMain: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 9
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        width: '100%'
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
    data: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
        alignItems: 'center',
        height: 36,
        display: 'flex'
    },
    root1: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    baseColor:{
        color:theme.palette.primary.main,
    }
}));

const PopupContainer = props => {
    const classes = useStyles();
    const [outgoingClaimData,setOutgoingClaimData]= React.useState([]);
    const [claimAdjustmentCodes, setClaimAdjustmentCodes] = React.useState([]);
    const [onEdit, setOnEdit] = React.useState(false);
    useEffect(()=>{
        if(props.outgoingClaimID && props.outgoingClaimDetailData && props.outgoingClaimDetailData.records &&props.outgoingClaimDetailData.records.length>0){
            var data=props.outgoingClaimDetailData.records.filter(item=>item.ID ==parseInt(props.outgoingClaimID))
            setOutgoingClaimData(data)
        }
    },[props.outgoingClaimDetailData,props.outgoingClaimID])
    

    const keywords = [ 'unique_identifier', 'claim_number','channel_partner_claim_number','claim_date','pricing_date','posting_date', 'contract_number','acquistion_price',
    'contract_price','quantity','calculated_amount','approved_amount','channel_partner_contract_id','channel_partner_acquistion_price','channel_partner_contract_price','channel_partner_quantity',
    'billed_uom','claim_amount','adjusted_amount','adjustment_code','claim_status','rejection_reason','claim_error','notes','channel_partner_type','channel_partner_id',
    'channel_partner_name','supplier_id_type','supplier_id_number','channel_partner_debit_memo','claim_submission_type','resubmit_number','credit_memo',' claim_per_unit',
    'base_uom','customer_number','channel_partner_end_customer_id','channel_partner_end_customer_name','end_customer_id','end_customer_name','membership_owner_id','membership_owner_name',
    'channel_partner_id_type','channel_partner_id_number','end_customer_id_type','end_customer_id_number','membership_owner_id_type','membership_owner_id_number','material_number',
    'material_description','material_id_type','material_id_number','channel_partner_material','product_hierarchy','customer_name','street_address','city','region','postal_code','country'
    ,'billing_date','billing_doc_number','billing_doc_line','net_value','currency','debit_credit_indicator','company_code','sales_org','distribution_channel','division','profit_center',
    'cost_center','class_of_trade','customer_classification','customer_group','customer_industry','delivery_tracking_number','maximum_quantity_allowed',
    'flex_attribute1','flex_attribute2','flex_attribute3','flex_attribute4','flex_attribute5','plant','flex_key_figure1','flex_key_figure2','flex_key_figure3','flex_key_figure4','flex_key_figure5','unit_price','billing_doc_cancelled','cancelled_billing_doc','original_invoice_for_return',
    'original_invoice_line_for_return','calendar_year_month','fiscal_year_month','transaction_type','processed','CreatedAt','UpdatedAt','created_by','changed_by',

]
const editableLines = [
        'end_customer_id',
        'pricing_date', 
        'contract_number',
        'acquistion_price',
        'contract_price',
        'quantity',
        'approved_amount',
        'adjusted_amount',
        'adjustment_code',
        'rejection_reason',
        'notes',
        'membership_owner_id',
        'material_number',
]
useEffect(() => {
    props.getClaimData();

}, []);
useEffect(() => {

    if (props.claimData && props.claimData.length > 0) {
        setClaimAdjustmentCodes(props.claimData[0].claim_adjustment_codes)
    }

}, [props.claimData])
const handleSelectcell = (e) => {
    setOnEdit(!onEdit)

}
const handleAdjustmentCode = (event) => {
    var currentObject = {}
    outgoingClaimData.map((item, index) => {
        if (item.ID === props.outgoingClaimID) {
            currentObject = item
        }
    })
    currentObject['adjustment_code'] = event.target.value
    props.updateOutgoingClaimData(props.outgoingClaimID, currentObject)
    setOnEdit(false)

}
const handleOnSaveEdit = ({ name, value}) => {
    
    var data = outgoingClaimData;

    if (typeof (outgoingClaimData[0][name]) === 'number') {
        data[0][name] = parseInt(value);
    } else {
        data[0][name] = value;
    }
    props.updateOutgoingClaimData(props.outgoingClaimID, data[0]);

}
    function handleClose(){
        props.onClose(false)
    }

    return (
        <Dialog open={
            props.open}
            fullScreen
            style={{ margin: 15 ,height:'auto'}}
        >
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <h2 className={classes.baseColor}>  Multiple Claims Details</h2>
                    <Box>
                        <IconButton onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>

            </DialogTitle>
            <DialogContent classes={{
                root: classes.dialogContent
            }}
            >
                
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div>
                            <Grid container >
                                {outgoingClaimData &&  
                                keywords.map(item=>{
                                    
                                        return (
                                            <Grid
                                            item
                                            md={2}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >


                                            {editableLines.includes(item) ?
                                                <Grid container
                                                className={clsx(classes.root1)}
                                                style={{ paddingTop: 10 }}
                                            >
                                                <Grid item style={{ width: '100%', marginLeft: -5, paddingRight: 25 }}>
                                                    <FormLabel style={{ paddingLeft: 5, textTransform: 'capitalize', paddingTop: 15 }}>
                                                        {outgoingClaimData[0] && Object.keys(outgoingClaimData[0]).includes(item) ? item.replace(/_/g, ' ') : ''}
                                                    </FormLabel>
                                                    <div className={classes.data}
                                                    >
                                                        {item == 'adjustment_code' ?
                                                            <div style={{ whiteSpace: 'nowrap',paddingLeft:10 }} >
                                                                <Select
                                                                    style={{ display: onEdit ? 'inline-block' : 'none' }}
                                                                    value={outgoingClaimData[0] && outgoingClaimData[0][item] ? outgoingClaimData[0][item] : ''}
                                                                    onChange={(e) => handleAdjustmentCode(e)}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    {claimAdjustmentCodes.map((item) => {
                                                                        return (
                                                                            <MenuItem value={item} key={item}>
                                                                                {item}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>

                                                                <div style={{ display: onEdit ? 'none' : 'block',paddingLeft:10 }}>
                                                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                                        <div style={{marginTop:2,marginRight:5}}>
                                                                            {outgoingClaimData[0] && outgoingClaimData[0][item] ? outgoingClaimData[0][item] : ''}
                                                                        </div>
                                                                        <div style={{ color: 'black' }}>
                                                                            <EditIcon  fontSize="small" onClick={handleSelectcell}/>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            <EditText
                                                                name={item}
                                                                defaultValue={outgoingClaimData[0] && outgoingClaimData[0][item] ? Moment(outgoingClaimData[0][item], Moment.ISO_8601, true).isValid() && outgoingClaimData[0][item].charAt(outgoingClaimData[0][item].length - 1) === 'Z' ? Moment(outgoingClaimData[0][item]).format('MM/DD/YYYY') : outgoingClaimData[0][item] : ''}
                                                                inputClassName='bg-success'
                                                                onSave={handleOnSaveEdit}
                                                                showEditButton

                                                            />
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>

                                                :
                                                <LabelText heading={outgoingClaimData[0]&& Object.keys(outgoingClaimData[0]).includes(item) ? item.replace(/_/g, ' ') : ''} style={{ textTransform: 'capitalize' }} data={outgoingClaimData[0]&&outgoingClaimData[0][item] ? Moment(outgoingClaimData[0][item], Moment.ISO_8601, true).isValid() && outgoingClaimData[0][item].toString().charAt(outgoingClaimData[0][item].length - 1) === 'Z' ? Moment(outgoingClaimData[0][item]).format('MM/DD/YYYY') : outgoingClaimData[0][item]:''} twoline='true' />
                                            }
                                        </Grid>
                                        );
                                    }

                                )}
                            
                            </Grid>
                        </div>
                    </form>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        updateOutgoingClaimData: (id,formData) => dispatch(updateOutgoingClaimData(id,formData)),
        getClaimData: () => dispatch(getClaimConfiguration()),
         }
}

const mapStateToProps = state => {
    return {
        outgoingClaimDetailData:state.dataSetupData.multipleClaims,
        claimData: state.calculationAnalysisData.claimData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);