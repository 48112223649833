import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Grid,
    Button,
    Typography,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem, TextField
} from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import { ChipInput } from "../../components/Inputs";
import RingLoader from "react-spinners/RingLoader";
import RotateLoader from "react-spinners/RotateLoader";
import { MultiSelectDropdownKeyMaster } from '../../components/Inputs';
import DynamicQueryFormRef from '../DynamicQuery/DynamicQueryFormRef';
import FilterDialoge from '../DynamicQuery/FilterDialoge';
import { getPromotionsSearch } from '../../redux/actions/Promotion/AuthAction';
import { getQueryRefById, updateQueryRefDataMaster } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10,
        marginTop: '-1rem'
    },
    container: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    gridContainer: {
        padding: "0px 15px 0px 10px",
    },
    gridContainerOuter: {
        padding: "0px 0px 0px 0px",
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 160
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 38,
        padding: 0,
        width: "98.5%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: "100%",
        backgroundColor: "white",
    },
    select1: {
        width: "99%",
        backgroundColor: "white",
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    paper: { minWidth: window.screen.width * .50 },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    baseColor: {
        color: theme.palette.primary.main,
    },
    inputTwoLineOutlined: {
        width: '100%',
        paddingLeft: 10,
        height: 38,
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: "100%",
        textTransform: "capitalize",
    },
    selectedItem1: {
        color: theme.palette.text.grey,
        marginTop: 10,
        // width: "100%",
        textTransform: "capitalize",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        paddingBottom: 16,
        width: '100%'
    },
    spinner: {
        height: '90vh'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    multiSelect: {
        marginTop: 10
    },
    multiSelect1: {
        marginTop: 0
    },
    textInput: {
        marginTop: 8
    },
    flex: {
        flex: 1
    },
    selectRoot: {
        padding: "0px 0px 15px 15px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const MasterDataSearch = props => {
    const classes = useStyles();
    const { className } = props;
    const location = useLocation();
    const [requiredFieldArray, setRequiredFieldArray] = React.useState();
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [filterData, setFilterData] = React.useState({});
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [trigger, setTigger] = React.useState(false);
    const [filterID, setFilterID] = React.useState('');
    const [filterName, setFilterName] = React.useState("");
    const options = ["range", "equal", "not equal", "pattern"];
    const [numericFields, setNumericFields] = React.useState(['coverage_length', 'coverage_cost', 'msrp']);
    const [rangeOption, setRangeOption] = React.useState([]);
    const [dateFields, setDateFields] = React.useState(['valid_from', 'valid_to', 'billing_date']);
    const [rangeFields, setRangeFields] = React.useState([]);
    const [table, setTable] = React.useState();
    const currentQueryID = props.table;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    useEffect(() => {
        if (props.data) {
            setRequiredFieldArray(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.table)
            setTable(props.table)
    }, [props.table]);

    useEffect(() => {
        if (props.predefinedFilter && props.predefinedFilter.filter_fields && filterName == props.predefinedFilter.filter_name) {
            setFilterData(props.predefinedFilter.filter_fields)
            setFilterName(props.predefinedFilter.filter_name)
        }
        else {
            setFilterData({})
        }
    }, [props.predefinedFilter])

    const handleOption = (e, item) => {
        setFilterData({
            ...filterData,
            [item.field_id]: {
                ...filterData[item.field_id],
                filter_option: e,
                filter_value: item.type === "TIME" && e !== "range" ? null : []
            }
        });

    };
    const handleRangeFilterData = (data, item, data2) => {
        if (item.field_id === 'valid_from' || item.field_id === 'valid_to' || item.field_id === 'billing_date') {
            setFilterData({
                ...filterData, [item.field_id]:
                {
                    ...filterData[item.field_id],
                    "filter_name": item.field_id,
                    "filter_value": {
                        "from": Moment(data).format("YYYY-MM-DD"), "to": Moment(data2).format("YYYY-MM-DD")
                    }
                }

            })
        }
        else {
            setFilterData({
                ...filterData, [item.field_id]:
                {
                    ...filterData[item.field_id],
                    "filter_name": item.field_id,
                    "filter_value": {
                        "from": data.toString(), "to": data2
                    }
                }

            })
        }
    }

    const handleFilterData = (data, item, type) => {
        let temp = {}
        if (type == 'valid_from' || type == 'valid_to' || type == 'billing_date') {
            temp = filterData[item.field_id] ? filterData[item.field_id] : {}
            var date = data ? Moment(data).format("YYYY-MM-DD") : null;
            setFilterData({
                ...filterData, [item.field_id]: {
                    ...temp,
                    "filter_name": item.field_id,
                    "filter_value": date,
                }
            })
        }
        if (data && data.length > 0) {
            if (type == 'chip') {
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": data
                    }
                });

            }
            else if (type == 'NUMERIC')
                setFilterData({
                    ...filterData, [item.field_id]:
                    {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": Number(data)
                    }
                })
            else
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": data
                    }
                })
        }

    }

    const setDefaultFilterOptions = (filterData) => {
        const updatedFilterData = { ...filterData };
        Object.keys(updatedFilterData).forEach(field_id => {
            if (!updatedFilterData[field_id].filter_option || updatedFilterData[field_id].filter_option === "") {
                updatedFilterData[field_id].filter_option = "equal";
            }
        });
        return updatedFilterData;
    };

    // console.log(attributesData)
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            const updatedFilterData = setDefaultFilterOptions(filterData);
            const formData1 = {
                filter_fields: {
                    ...updatedFilterData
                }
            };
            props.onSubmit(1, 0, 'search', formData1, table);
            sessionStorage.setItem('search-data', JSON.stringify(formData1))
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setAttributesData([])
        setFilterData({})
    }
    const formData = {
        "filter_fields": {
            ...filterData
        }
    };
    const handleSearch = (type) => {
        if (type === 'Save Search') {
            setOpenFilter(true)
        }
        if (type === 'Save Search & Run') {
            setOpenFilter(true)
            setTigger(true)
        }
    }
    const handleFilter = (item) => {
        setFilterID(item.id)
        setFilterName(item.filter_name)
        props.getPredefinedFilter(item.id)
    }
    const handleSaveAndRun = (name, type) => {
        setOpenFilter(false)
        const updatedFilterData = setDefaultFilterOptions(filterData);
        if (updatedFilterData) {
            var formData1 = {
                query_id: currentQueryID,
                filter_name: name,
                filter_fields: updatedFilterData,
            };
            if (type == 'edit')
                formData1['ID'] = filterID
            props.saveAndRun(type, formData1);
            if (trigger) {
                var formData2 = {
                    filter_fields: {
                        ...updatedFilterData
                    }
                };
                props.onSubmit(1, 0, 'search', formData2, table);
                sessionStorage.setItem('search-data', JSON.stringify(formData2))
            }
        }

    }

    // console.log(formData)
    // console.log(filterData)


    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <Typography color="primary" variant='h1'>Search {props.title}</Typography>
                <div style={{ display: 'flex' }}>
                    <div className={classes.container} style={{ width: '80%' }}>
                        {/* <div className={classes.bodyContainer} > */}
                        <Grid container>
                            {requiredFieldArray && requiredFieldArray?.map((item, i) => {
                                return <>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainerOuter }}
                                        style={{ display: "flex" }}
                                    >
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{
                                                position: 'relative'
                                            }}
                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }}
                                                style={{
                                                    marginTop: '0.7rem'
                                                }}>
                                                <Typography variant="h3" color='primary' style={{
                                                    marginTop: '-0.6rem',
                                                    // position: 'absolute' 
                                                }}>{i == 0 && "Filter Name"}</Typography>
                                                {
                                                    i == 0 ? <div style={{ marginTop: '1rem' }} > {item.current} </div> : item.current
                                                }
                                            </FormLabel>
                                        </Grid>
                                        <Grid
                                            md={2}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel
                                                classes={{ root: classes.fontSetting }}
                                            >
                                                <Typography variant="h3" color='primary'>{i === 0 && "Option"}</Typography>
                                            </FormLabel>
                                            <Select
                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_option'] ? filterData[item.field_id]['filter_option'] : []}
                                                onChange={(e) => handleOption(e.target.value, item)}
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                displayEmpty
                                                style={{ marginTop: i === 0 ? '7px' : '-15px' }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem,
                                                }}
                                            >
                                                <MenuItem value=""></MenuItem>
                                                {options &&
                                                    options
                                                        .filter(itemX => (numericFields.includes(item.field_id) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                                            ((!numericFields.includes(item.field_id) && !dateFields.includes(item.field_id) && !rangeFields.includes(item.field_id)) ? itemX != 'range' : itemX)
                                                            && (dateFields.includes(item.field_id) ? itemX != 'pattern' : itemX))
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem MenuItem
                                                                    value={item}
                                                                    key={item}
                                                                    style={{ textTransform: "capitalize" }
                                                                    }
                                                                >
                                                                    {/* {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()} */}
                                                                    {item.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                            </Select>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel
                                                classes={{ root: classes.fontSetting }}
                                            >
                                                <Typography variant="h3" color='primary'>{i == 0 && "Value"}</Typography>
                                            </FormLabel>

                                            {item.type == 'DROPDOWN.STRING' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                <div style={{ marginTop: i === 0 ? '-3px' : '-22px', }}>
                                                    <MultiSelectDropdownKeyMaster capitalize={true}
                                                        listArray={item.drop_down_value_keys} data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} id={item.key} onChange={(e) => handleFilterData(e, item)} />
                                                </div>
                                                :
                                                item.type == 'BOOL' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                    <div style={{
                                                        marginTop: "-15px",
                                                        // width: "24.2rem"
                                                    }}>
                                                        <Select
                                                            value={filterData[item.field_id] ? filterData[item.field_id.filter_value] : ''}
                                                            onChange={(e) => handleFilterData(e.target.value, item)}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select1]: true,
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem1,
                                                            }}
                                                        >
                                                            <MenuItem value={'true'} key={'true'}>
                                                                Yes
                                                            </MenuItem>
                                                            <MenuItem value={'false'} key={'false'}>
                                                                No
                                                            </MenuItem>
                                                        </Select>
                                                    </div>
                                                    :
                                                    item.type == 'TIME' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] === 'range' ?
                                                        <div>
                                                            <div style={{ display: 'flex' }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 11px 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginTop: i === 0 ? '7px' : '-14px',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '99%',
                                                                                    marginRight: 5
                                                                                }
                                                                            }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : null}
                                                                            onChange={(e) => handleRangeFilterData(e, item, filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['to'] ? filterData[item.field_id]['filter_value']['to'] : '')}
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                        :
                                                        item.type == 'TIME' ?
                                                            <div>
                                                                <div style={{ display: 'flex' }}>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 11px 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        marginTop: i === 0 ? '7px' : '-14px',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '99%',
                                                                                        marginRight: 5
                                                                                    }
                                                                                }}
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : null}
                                                                                onChange={(e) => handleFilterData(e, item, item.field_id)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </div>

                                                            :
                                                            item.type == "DROPDOWN.SINGLE" && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                <div
                                                                    style={{ marginTop: i === 0 ? '-3px' : '-22px', }}
                                                                >
                                                                    <MultiSelectDropdownKeyMaster capitalize={true}
                                                                        listArray={item.drop_down_value_keys} data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} id={item.key} onChange={(e) => handleFilterData(e, item)} />
                                                                </div>

                                                                : item.type == 'NUMERIC' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                    <div style={{ marginRight: 5, marginTop: i === 0 ? '7px' : '-15px', }}>
                                                                        <OutlinedInput
                                                                            type={'number'}
                                                                            classes={{ root: classes.inputTwoLineOutlined }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : ''}
                                                                            onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                                        />

                                                                    </div> :
                                                                    !filterData[item.field_id] || !filterData[item.field_id]['filter_option'] || filterData[item.field_id]['filter_option'] === '' ?
                                                                        <div style={{ marginTop: i === 0 ? '7px' : '-14px' }}>
                                                                            <div style={{ width: '99%', marginLeft: '-1' }}>
                                                                                <ChipInput
                                                                                    paste
                                                                                    data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                                    onChange={newValue => handleFilterData(newValue, item, 'chip')}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                            <div style={{ marginTop: i === 0 ? '7px' : '-14px', padding: "0px 5px 0px 0px" }}>
                                                                                <div>
                                                                                    <ChipInput paste data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} onChange={newValue => handleFilterData(newValue, item, 'chip')} />
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div style={{
                                                                                marginRight: 5,
                                                                                //  marginTop: "-15px"
                                                                                marginTop: i === 0 ? '7px' : '-15px',
                                                                            }}>
                                                                                <OutlinedInput
                                                                                    classes={{ root: classes.inputTwoLineOutlined }}
                                                                                    value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : ''}
                                                                                    onChange={(e) => handleRangeFilterData(e.target.value, item)}
                                                                                />
                                                                            </div>
                                            }

                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ display: filterData[item.field_id] && filterData[item.field_id]['filter_option'] && filterData[item.field_id]['filter_option'] == 'range' ? 'block' : 'none' }}
                                        >
                                            <FormLabel
                                                classes={{ root: classes.fontSetting }}
                                            >
                                                <Typography variant="h3" color='primary'>{i == 0 && "Range"}</Typography>
                                            </FormLabel>

                                            <div style={{ marginRight: 5, marginTop: i === 0 ? '7px' : '-15px', }}>
                                                {item.type == 'TIME' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] === 'range' ?
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 11px 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        // marginTop: i === 0 ? '10px' : '-14px',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_value']['to'] ? filterData[item.field_id]['filter_value']['to'] : null}
                                                                onChange={(e) => handleRangeFilterData(filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : '', item, e)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                    :
                                                    <OutlinedInput
                                                        classes={{ root: classes.inputTwoLineOutlined }}
                                                        value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['to'] ? filterData[item.field_id]['filter_value']['to'] : ''}
                                                        onChange={(e) => handleRangeFilterData(filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : '', item, e.target.value)}
                                                    />

                                                }
                                            </div>
                                        </Grid>

                                    </Grid >
                                </>;
                            })
                            }
                        </Grid >
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button}
                                onClick={handleDialog}
                                twoline='true'
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => handleSearch('Save Search')}
                                twoline="true"
                            >
                                {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                    :
                                    'Save Search'
                                }
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => handleSearch('Save Search & Run')}
                                twoline="true"
                            >
                                {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                    :
                                    'Save Search & Run'
                                }
                            </Button>
                        </div>
                    </div>
                    <div style={{ width: '20%' }} className={classes.container}>
                        <DynamicQueryFormRef
                            id={currentQueryID}
                            onChange={handleFilter}
                        />
                    </div>
                </div>
            </div >
            {
                openFilter &&
                <FilterDialoge open={openFilter} filterName={filterName}
                    action={filterID ? 'edit' : 'create'}
                    onChange={handleSaveAndRun}
                    onCancel={() => setOpenFilter(false)}
                />
            }

        </LoadingOverlay >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        predefinedFilter: state.operationalReportsData.dynamicQueryFilterData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPredefinedFilter: (id) => dispatch(getQueryRefById(id)),
        saveAndRun: (type, data) => dispatch(updateQueryRefDataMaster(type, data, null, 'promotions')),
        onSubmit: (pagination, limit, keyword, data, table) => dispatch(getPromotionsSearch(pagination, limit, keyword, data, table)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataSearch);