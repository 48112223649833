import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, TextField, FormControl, Button } from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import {
  geographicalIncentive,
  loadAccrualVsPaymentGraph,
} from "../../../../redux/actions";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white, //secondary.main,
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    height: "253px"
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: '1px solid #EEEEEE',
    borderRadius: 6,
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    width: "133%",
    marginBottom: -10,
    // marginTop: 25,
    boxShadow: "rgba(50, 50, 93, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.15) 0px 2px 4px -2px",
    height: "70px",
    padding: "7px 5px 5px 5px"
  },
  formControlStyle: {
    // border: "1px solid #8F00FF",
  },
  noBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "1px solid",
        borderColor: theme.palette.border.main,
      },
    },
  },
}));
const RebateTrends = (props) => {
  const classes = useStyles();
  const [avssGraphData, setAvssGraphData] = React.useState([]);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [allowedTilesFilter1, setAllowedTilesFilter1] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [aggregationField, setAggregationField] = React.useState('contract_type');
  const [showLoaderRebate, setShowLoaderRebate] = React.useState(true);



  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Monthly Accrual vs Payment" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter1([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter1([]);
      }
    }
  }, [props.allowedDashBoardData]);
  useEffect(() => {
    if (allowedTilesFilter1.length > 0) {
      if (allowedTilesFilter1[0].start_date)
        setStartDate(allowedTilesFilter1[0].start_date);
      if (allowedTilesFilter1[0].end_date)
        setEndDate(allowedTilesFilter1[0].end_date);
      if (allowedTilesFilter1[0].data_source_type)
        setSourceDataType(allowedTilesFilter1[0].data_source_type);
      if (allowedTilesFilter1[0].groupBy)
        setAggregationField(convertToSnakeCase(allowedTilesFilter1[0].groupBy))
    }
  }, [allowedTilesFilter1]);


  useEffect(() => {
    if (startDate && endDate && sourceDataType && aggregationField) {
      setShowLoaderRebate(true);
      props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, startDate, endDate, aggregationField);
    }

  }, [allowedTilesFilter1]);

  function convertToSnakeCase(string) {
    return string.replace(/\s+/g, '_').toLowerCase();
  }

  // useEffect(() => {
  //   if (props.accrualDashBoardPaymentGraphData)
  //     setAvssGraphData(props.accrualDashBoardPaymentGraphData);
  // }, [props.accrualDashBoardPaymentGraphData]);

  useEffect(() => {
    setShowLoaderRebate(false);
    if (props.accrualDashBoardPaymentGraphData) {
      if (
        props.accrualDashBoardPaymentGraphData &&
        ((props.accrualDashBoardPaymentGraphData.accrual_result &&
          props.accrualDashBoardPaymentGraphData.accrual_result !== null &&
          props.accrualDashBoardPaymentGraphData.accrual_result.length > 0) ||
          (props.accrualDashBoardPaymentGraphData.payment_result &&
            props.accrualDashBoardPaymentGraphData.payment_result !== null &&
            props.accrualDashBoardPaymentGraphData.payment_result.length > 0))
      ) {
        var data = [
          {
            data:
              props.accrualDashBoardPaymentGraphData &&
                props.accrualDashBoardPaymentGraphData.accrual_result
                ? props.accrualDashBoardPaymentGraphData.accrual_result
                : [],
            xName: "Date",
            yName: "Total",
            legend: "Accrual",
            type: "Spline",
            shape: "Diamond",
            color: "#0000FF",
          },
          {
            data:
              props.accrualDashBoardPaymentGraphData &&
                props.accrualDashBoardPaymentGraphData.payment_result
                ? props.accrualDashBoardPaymentGraphData.payment_result
                : [],
            xName: "Date",
            yName: "Total",
            legend: "Payment",
            type: "Spline",
            shape: "Diamond",
            color: "#CA4218",
          },
        ];
        setAvssGraphData(data);
        //setAvssGraphData(props.accrualDashBoardPaymentGraphData);
      } else setAvssGraphData([]);
    } else setAvssGraphData([]);
  }, [props.accrualDashBoardPaymentGraphData]);

  function handleSubmit() {
    if (startDate && endDate) {
      setShowLoaderRebate(true);
      props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, Moment(startDate).format("YYYY-MM-DDTHH:mm:ss"), Moment(endDate).format("YYYY-MM-DDTHH:mm:ss"), aggregationField);
    }
  }
  function handleStartDateNew(event) {
    if (event && event.isValid()) {
      setStartDate(event);
      // setShowLoaderRebate(true);
      // props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, Moment(event).format("YYYY-MM-DDTHH:mm:ss"), endDate, aggregationField);
    }
    else
      setStartDate(event);
  }
  function handleEndDateNew(event) {
    if (event && event.isValid()) {
      setEndDate(event);
      // setShowLoaderRebate(true);
      // props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType,
      //   // Moment(startDate).format('MM/DD/YYYY'), Moment(event).format('MM/DD/YYYY')
      //   startDate, Moment(event).format("YYYY-MM-DDTHH:mm:ss")
      //   , aggregationField);
    }
    else
      setEndDate(event);
  }
  return (
    <LoadingOverlay
      active={showLoaderRebate}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div>
        <div className={classes.bodyContainer} style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="start_date"
                id="start_date-select"
                label="Start Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.3rem',
                    border: 'none'
                  }
                }}
                value={startDate}
                onChange={date => handleStartDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 180 }} size="small" className={classes.formControlStyle}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                labelId="end_date"
                id="end_date-select"
                label="End Date"
                disableToolbar
                clearable
                InputProps={{
                  padding: 0,
                  disableUnderline: true,
                  style: {
                    padding: '1px 10 1px 11px',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: '1px solid #E0E0E0',
                    width: '100%',
                    height: '2.3rem',
                    border: 'none'
                  }
                }}
                value={endDate}
                onChange={date => handleEndDateNew(date)}
                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                format="MM/DD/YYYY"
                minDate={startDate ? startDate : ''}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, maxWidth: 80 }} size="small" className={classes.formControlStyle}>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "35px", marginRight: 3 }}
              onClick={handleSubmit}
              twoline="true"
              disabled={startDate && endDate && aggregationField ? false : true}
            >
              <DirectionsRunIcon />
            </Button>
          </FormControl>
        </div>
        <div className={classes.root}>
          <SplineChart
            id="rebate-dashboard-charts"
            data={avssGraphData}
            height={"small"}
          //splineData1={avssGraphData && avssGraphData.accrual_result}
          //splineData2={avssGraphData && avssGraphData.payment_result}
          />
        </div>
      </div>
    </LoadingOverlay >
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.initialData.loading,
    accrualDashBoardPaymentGraphData: state.initialData.accrualPaymentGraphData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geographicalIncentive: (s, e, postingType) =>
      dispatch(
        geographicalIncentive(
          s,
          e,
          postingType,
          "Customer Rebate",
          "Direct - Sales Data"
        )
      ),
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType, startDate, endDate, aggregationField) =>
      dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType, startDate, endDate, aggregationField, "no aggregationField")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateTrends);
