import React, { useEffect } from 'react';
import {
    Grid, Typography, Breadcrumbs, Link, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getMembershipFormatFields, getMembershipFormatDataById, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const MembershipViewQuery = props => {
    const classes = useStyles();
    const history = useHistory();
    const [salesQueryData, setSalesQueryData] = React.useState(sessionStorage.getItem('membership-query') ? JSON.parse(sessionStorage.getItem('membership-query')) : []);
    const [sortedData, setSortedData] = React.useState([]);
    const type = history.location.state;
    useEffect(() => {
        props.getMembershipFormatFields(type);
        props.getMembershipFormatDataById(salesQueryData.id, type)
        if (type === 'MembershipContractXREFFormats') {
            props.getLblDispDesFieldValue({ "data_source": "Membership Data" }, 'post');
        }
        else {
            props.getLblDispDesFieldValue({ "data_source": "Membership Data" }, 'post');
        }
    }, []);
    useEffect(() => {
        var tempObj = {}
        if (props.configData && props.configData.format) {
            Object.entries(props.configData.format).map(item => {
                if (props.tableData) {
                    Object.values(props.tableData.field_label_attributes).map(e => {
                        if (e.key === item[0]) {
                            tempObj[e.current] = item[1]
                        }
                    })
                }
            })
            setSortedData(tempObj)
        }
    }, [props.configData, props.tableData])
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>View Format</Typography>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {sortedData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(sortedData).length > 0 && Object.entries(sortedData)

                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'organization_id' && v > 0)
                                                        .sort(function (a, b) {
                                                            return a[1] > b[1] ? 1 : -1;
                                                        })
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow >
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        {v}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getMembershipFormatFields: (type) => dispatch(getMembershipFormatFields(type)),
        getMembershipFormatDataById: (id, type) => dispatch(getMembershipFormatDataById(id, type)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        queryListData: state.customerData.membershipFormatFieldData,
        configData: state.customerData.membershipFormatDataById,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipViewQuery);