import React, { useEffect } from 'react';
import {
    Typography, Button, Grid, Stack, Skeleton, IconButton,
    InputAdornment, OutlinedInput, Select, MenuItem, Link
} from '@mui/material';
import {
    ArrowLeft, Circle, TableView
} from '@mui/icons-material';

import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import '../../Styles/Review.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { StickerTableHeader } from '../../GlobalSetup/Components';
import { getMaterialFilters, getPreviousScenarioList, getScenarioMarkets, runScenarioComparison } from '../../../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import { Market, PromoData } from '../Components';
import Moment from 'moment';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '0px 16px',
        height: '100%',
        overflowX: 'hidden'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        //padding: 8,
    },

    mainHeader: {
        marginLeft: 5,
        padding: '17px 27px 18px 32px',
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
    },
    filterGrid: {
        backgroundColor: '#FFFFFF',
        border: '0.8px solid #E2E2E2',
        padding: 8
    },
    detailGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    defaultContainer: {
        display: 'grid',
        justifyContent: 'center',
        height: 300,

    },
    skeletonContainer: {
        justifyContent: 'center',
        display: 'inline-grid',
        alignContent: 'center',
        //width: 300
    },
    reviewBox: {
        backgroundColor: '#F8F8F8',
        marginLeft: 5,
        display: 'flex',
        minHeight: '904px',
        padding: 0
    },
    headContainer: {
        marginLeft: '5px',
        display: 'flex',
        padding: "16px 16px 16px 32px ",
        justifyContent: 'space-between'
        //justifyContent: 'space-between',
        //height: 50,
        // border: '1px solid #E8E8E8',
        // borderTop: 0,
        //borderRadius: 2,
        // paddingLeft: 10
    },
    selectBox: {
        height: 37,
        //marginTop: 23,
        // marginLeft: 500,
        paddingLeft: 5,
        border: "1px solid",
        borderRadius: 2,
        backgroundColor: 'white',
        flexDirection: 'row',
        display: 'flex',
    },
    prefillOutlined: {
        width: '100%',
        height: '37px',
        border: '0px'
    },
    selectContainer: {
        marginLeft: '19px'
    },
    country: {
        width: 150,
    },
    iconContainer: {
        margin: '17px 12px 17px 24px',
    }
}));






const FinanceApprove = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [modelYear, setModelYear] = React.useState('2020');
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [expandedRows, setExpandedRows] = React.useState([]);
    const [activeSticker, setActiveSticker] = React.useState([]);
    const [activeStickerID, setActiveStickerID] = React.useState([]);
    const [definedSticker, setDefinedSticker] = React.useState([]);

    const [selectedModels, setSelectedModels] = React.useState([]);
    const [comparedState, setComparedState] = React.useState({});
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [reviewData, setReviewData] = React.useState({});
    const [costData, setCostData] = React.useState({});
    const [changeStickerMode, SetChangeStickerMode] = React.useState(false);
    const [markets, setMarkets] = React.useState({});
    const [rowHeader, setRowHeader] = React.useState([]);
    const [selectedDetails, setSelectedDetails] = React.useState({});
    const [selectedMarket, setSelectedMarket] = React.useState({});
    const [previousScenario, setPreviousScenario] = React.useState('');
    const [prevID, setPrevID] = React.useState('');
    const [preScenarioList, setPreScenarioList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState({});
    const [gridData, setGridData] = React.useState('');
    const [prefillId, setPrefillId] = React.useState('');

    const countries = ['USA', 'CA'];
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    };
    useEffect(() => {
        if (selectedDetails) {
            var data = {
                "scenario_id": selectedDetails.scenario_id,
                "product_line": selectedDetails.product_line,
                "promotion_type": selectedDetails.promotion_type,
                "promotion_start_date": selectedDetails.promotion_start_date,
                "promotion_end_date": selectedDetails.promotion_end_date,
                "country": selectedDetails.country
            }
            props.getPreviousList(data, 'approved')
        }
    }, [selectedDetails]);
    useEffect(() => {
        setCostData(props.costingData)
    }, [props.costingData]);
    useEffect(() => {
        // props.getFilters()
        if (props.modelYears && props.modelYears.length > 0)
            setModelYear(props.modelYears[0])
    }, [props.modelYears]);
    useEffect(() => {
        if (props.markets) {
            setMarkets(props.markets)
        }
    }, [props.markets])
    useEffect(() => {
        if (props.productLine)
            setProductType(props.productLine[0])
        else {
            if (props.promoProductLines && !productType)
                setProductType(props.promoProductLines[0])
        }
    }, [props.promoProductLines, props.productLine]);
    useEffect(() => {
        if (props.data)
            setGridData(props.data)
    }, [props.data]);

    useEffect(() => {
        if (props.scenarioID) {
            console.log(props.scenarioID)
            setMarkets({})
            setReviewData({})
            setPrefillId(props.scenarioID)
            var data = { "scenario_id": props.scenarioID }
            props.getMarkets(data)
        }
    }, [props.scenarioID]);
    useEffect(() => {
        if (props.promoData) {
            setSelectedDetails({ ...props.promoData, 'model_year': props.promoData.material_group1, 'product_line': productType })
        }
    }, [props.promoData]);
    useEffect(() => {
        if (props.reviewedData) {
            setReviewData(props.reviewedData)
            setRowHeader(Object.keys(props.reviewedData))
        }
    }, [props.reviewedData])
    useEffect(() => {
        if (props.previousList) {
            setPreScenarioList(props.previousList)
        }
    }, [props.previousList])
    useEffect(() => {
        if (selectedMarket && Object.values(selectedMarket).length > 0) {
            handleRegion(selectedMarket, prevID)
            var data = {
                "scenario_id": prefillId,
                "states": selectedMarket.states,
                "sticker_ids": selectedMarket.sticker_ids
            }
            if (prevID)
                data['previous_scenario_id'] = prevID
            props.runMarkets(data)
        }
    }, [prevID, selectedMarket])
    const handleStates = (value) => {
        setComparedState(value)
    };
    function handleChange(e) {
        setProductType(e.target.value);
        props.handleGrid(e.target.value)
    }
    const handleRegion = (values, prevId) => {
        setReviewData({})
        setSelectedMarket(values)


    }
    function handleReturn() {
        if (changeStickerMode)
            SetChangeStickerMode(false)
        else
            props.handleGrid('return')
    }
    function handleCountry(e) {
        setSelectedCountry(e.target.value)
    }

    function handlePrefill(id) {
        setPrefillId(id)
        var currentScenario = gridData.filter(item => item.scenario_id == id).map(e => e)
        console.log(currentScenario)
        props.handleGrid(currentScenario[0].sticker_names, 'sticker_names')
        setSelectedDetails(currentScenario[0])
        var data = { "scenario_id": id }
        props.getMarkets(data)
    }
    function handleScenario(id) {
        setPrevID(id)
    }
    return (

        <div className={classes.root}>
            <StickerTableHeader data={gridData} handlePrefill={handlePrefill} productLineData={props.promoProductLines} page='review'
                defaultDivison={productType} onChange={handleChange} isDisabled={false} enabledList={props.productLine} />

            <div className={classes.mainHeader}>
                <div className={classes.row}>
                    <Button className='return-button promo-font' variant="outlined" startIcon={<ArrowLeft />} onClick={handleReturn}>
                        Return
                    </Button>
                    <Typography style={{ fontSize: 13, marginTop: 3 }}>
                        <Circle style={{ marginLeft: 15, height: 10, color: 'red' }} />
                        In Progress
                    </Typography>

                </div>
                <div className={classes.country}>
                    <Select
                        id={'selected-country'}
                        value={selectedCountry}
                        onChange={handleCountry}
                        style={{ backgroundColor: 'white', borderRadius: 5 }}
                    //readOnly={props.page === "Scenario" ? true : false}

                    >
                        {countries && countries
                            .filter(item => props.countryList ? props.countryList.includes(item) : item)
                            .map(item =>
                                <MenuItem value={item}>{item == 'CA' ? 'CANADA' : item}</MenuItem>
                            )
                        }
                        {/* <MenuItem value={'USA'}>USA</MenuItem>
                                <MenuItem value={'CA'}>CANADA</MenuItem> */}
                    </Select>
                </div>


            </div>
            <div className={`${classes.headContainer} review-head-container`}>
                <div style={{ display: 'flex' }}>
                    <div className={classes.iconContainer}>
                        <IconButton><TableView /></IconButton>
                    </div>
                    <div >
                        <Typography className='review-head'>
                            {selectedDetails && Object.keys(selectedDetails).length > 0 ? ` ${selectedDetails.product_line} ${selectedDetails.promotion_type}, from ${Moment.utc(selectedDetails.promotion_start_date).format("MMMM D, YYYY")} to ${Moment.utc(selectedDetails.promotion_end_date).format("MMMM D, YYYY")}`
                                : ''}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <Typography className='review-label'>Costing Number PWC-24-17</Typography>

                        </div>
                    </div>
                </div>
                <div className={classes.row} style={{ marginLeft: 72, display: 'flex', justifyContent: 'space-between', width: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '656px' }}>

                        <Typography className='promo-font sub-label'>Tot. Promo Cost:
                            <span className='promo-font price-typography'>{markets && markets.summary?.summary && markets.summary.summary.total_cost ? markets.summary.summary.total_cost.toLocaleString() : 0}</span> <span className='promo-font currency'> {markets?.summary?.summary?.promotion_currency}</span>

                        </Typography>
                        <div className="vertical-line"></div>
                        <Typography className='promo-font sub-label'> Tot Unit Vol. <span className='promo-font currency'> {markets && markets.summary?.summary && markets.summary.summary.total_volume ? markets.summary.summary.total_volume.toFixed(2) : 0}</span>
                        </Typography>
                        <div className="vertical-line"></div>
                        <Typography className='promo-font sub-label'> Tot Avg/Unit. <>
                            <span className='promo-font price-typography'>{markets && markets.summary?.summary && markets.summary.summary.avg_cost_per_volume ?
                                parseFloat(
                                    markets.summary.summary.avg_cost_per_volume
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }) : 0}</span> <span className='promo-font currency'> {" " + markets?.summary?.summary?.promotion_currency}</span>  </> </Typography>
                    </div>
                    {/* <Link
                        component="button"
                        //variant="body2"
                        className={breakDown ? 'breakdown-active mt-2 ml-3 mr-3' : 'breakdown-inactive mt-2 ml-3 mr-3'}
                        onClick={() => {
                            setBreakDown(!breakDown);
                        }}
                    > <span className='mr-2'>Breakdown</span>
                        {breakDown ? <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none" >
                            <path d="M5.26016 1.76644L2.02682 4.99977L5.26016 8.23311C5.58516 8.55811 5.58516 9.08311 5.26016 9.40811C4.93516 9.73311 4.41016 9.73311 4.08516 9.40811L0.260156 5.58311C-0.0648443 5.25811 -0.0648443 4.73311 0.260156 4.40811L4.08516 0.583105C4.41016 0.258105 4.93516 0.258105 5.26016 0.583105C5.57682 0.908105 5.58516 1.44144 5.26016 1.76644Z" fill="#FFC400" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                            <path d="M0.739844 8.23356L3.97318 5.00023L0.739843 1.76689C0.414843 1.44189 0.414843 0.916894 0.739843 0.591894C1.06484 0.266894 1.58984 0.266894 1.91484 0.591894L5.73984 4.41689C6.06484 4.74189 6.06484 5.26689 5.73984 5.59189L1.91484 9.41689C1.58984 9.74189 1.06484 9.74189 0.739844 9.41689C0.423177 9.09189 0.414844 8.55856 0.739844 8.23356Z" fill="#A1A1A1" />
                        </svg>}

                    </Link> */}
                    {/* {breakDown &&
                        <div className='breakdown-container' style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <Typography className='promo-font sub-label'>Current
                                <span className='promo-font price-typography  ml-2'>{markets?.summary?.summary?.costing_breakdown?.current ?
                                    parseFloat(
                                        markets.summary.summary.costing_breakdown.current
                                    ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : 0}</span>

                                <span className='promo-font currency' > {" " + markets?.summary?.summary?.promotion_currency}</span>
                            </Typography>
                            <div className="vertical-line"></div>
                            <Typography className='promo-font sub-label'>Non-Current
                                <span className='promo-font price-typography ml-2'>{markets?.summary?.summary?.costing_breakdown?.non_current ?
                                    parseFloat(
                                        markets.summary.summary.costing_breakdown.non_current
                                    ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }) : 0}</span>
                                <span className='promo-font currency'> {" " + markets?.summary?.summary?.promotion_currency}</span>
                            </Typography>
                        </div>

                    } */}

                </div>
                <div >
                    <Button variant='outlined' style={{ marginTop: 30, marginRight: 10 }}>Cancel</Button>
                    <Button variant='contained' style={{ marginTop: 30 }} disabled>Send for Signature</Button>
                </div>

            </div>
            <div className={classes.reviewBox}>
                <Grid item xs={12} md={12} container>
                    {selectedMarket && Object.keys(selectedMarket).length > 0 ?
                        <Grid item xs={12} md={9}>
                            <PromoData
                                filteredData={reviewData}
                                rowHeaders={rowHeader}
                                columnHeaders={selectedDetails.model_year}
                                colorPicker={props.colorPicker}
                                compare={comparedState && prevID ? true : false}
                                comparedState={comparedState}
                            />
                        </Grid>
                        :
                        <Grid item xs={12} md={9} className={classes.detailGrid}>

                            <div className={classes.defaultContainer} >
                                <Stack spacing={1} className={classes.skeletonContainer}>
                                    <Skeleton variant="rounded" width={300} height={60} />
                                    <Skeleton variant="rounded" width={300} height={60} />
                                    <Skeleton variant="rounded" width={300} height={60} />
                                </Stack>
                                <Typography variant='h3' color='black' textAlign={'center'} >Select a market on the right to begin</Typography>
                                <Typography variant='h4' color='grey'>
                                    Once a market is selected, their associated promotions will be displayed and  you can begin your review
                                </Typography>

                            </div>

                        </Grid>}
                    <Grid item xs={12} md={3}
                        className={classes.filterGrid}>
                        <Market markets={prefillId ? markets : {}}
                            handleRegion={handleRegion}
                            handleStates={handleStates}
                            page="costing_approval"
                        />
                    </Grid>

                </Grid>

            </div>
        </div >
    );
};


const mapDispatchToProps = dispatch => {
    return {
        // getConfigData: () => dispatch(getBudgetingConfig()),
        getFilters: () => dispatch(getMaterialFilters()),
        getMarkets: (data) => dispatch(getScenarioMarkets(data)),
        getPreviousList: (data, type) => dispatch(getPreviousScenarioList(data, type)),
        runMarkets: (data) => dispatch(runScenarioComparison(data))
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
        loader: state.promotionData.oncostingLoad,
        costingData: state.promotionData.costingSimulationResult,
        modelData: state.promotionData.modelFilters,
        loading: state.promotionData.loading,

        markets: state.promotionData.scenarioMarkets,
        previousList: state.promotionData.previousScenario,
        reviewedData: state.promotionData.reviewScenario
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceApprove);