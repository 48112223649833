import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Card,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getTierActivationAddressSearchData } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting:{
        fontSize:theme.typography.h3.fontSize
    }
}));
const TierActivationAddressSearch = props => {
    const classes = useStyles();
    const [actionCode,setActionCode] = React.useState('');
    const [GPOName, setGPOName] = React.useState('');
    const [GPOMemberNumber,setGPOMemberNumber] = React.useState('');
    const [GPOMemberName, setGPOMemberName] = React.useState('');
    const [GLNID,setGLNID] = React.useState('');
    const [HINID,setHINID] = React.useState('');
    const [activationID,setActivationID] = React.useState('');
    const [tierApproved,setTierApproved] = React.useState('');
    const [directParentMemberNumber,setDirectParentMemberNumber] = React.useState('');
    const [directParentMemberName, setDirectParentMemberName] = React.useState('');
    const [topMemberNumber,setTopMemberNumber] = React.useState('');
    const [topMemberName, setTopMemberName] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const handleGPOName = (e) => {
        setGPOName(e.target.value);
    }
    const handleGPOMemberName = (e) => {
        setGPOMemberName(e.target.value);
    }
    const handleDirectParentMemberName = (e) => {
        setDirectParentMemberName(e.target.value);
    }
    const handleTopMemberName = (e) => {
        setTopMemberName(e.target.value);
    }
    const handleStreet = (e) => {
        setStreet(e.target.value);
    }
    const handleCity = (e) => {
        setCity(e.target.value);
    }
    const handleState = (e) => {
        setState(e.target.value);
    }
    const handlePostalCode = (e) => {
        setPostalCode(e.target.value);
    }
    function handleSubmit() {
        var data = {
            "action_code":actionCode,
            "GPO_name": GPOName,
            "GPO_member_number":GPOMemberNumber,
            "GPO_member_name": GPOMemberName,
            "ID_GLN": GLNID,
            "ID_HIN": HINID,
            "activation_id":activationID,
            "tier_approved":tierApproved,
            "direct_parent_member_number": directParentMemberNumber,
            "direct_parent_member_name": directParentMemberName,
            "top_member_number":topMemberNumber,
            "top_member_name":topMemberName,
            "state": state,
            "city": city,
            "street": street,
            "postal_code": postalCode,
        };
        props.onSubmit(1,0,data,null);
        handleClear();
    }
    function handleSubmitStatic() {
        var data = {
            "action_code":actionCode,
            "GPO_name": GPOName,
            "GPO_member_number":GPOMemberNumber,
            "GPO_member_name": GPOMemberName,
            "ID_GLN": GLNID,
            "ID_HIN": HINID,
            "activation_id":activationID,
            "tier_approved":tierApproved,
            "direct_parent_member_number": directParentMemberNumber,
            "direct_parent_member_name": directParentMemberName,
            "top_member_number":topMemberNumber,
            "top_member_name":topMemberName,
            "state": state,
            "city": city,
            "street": street,
            "postal_code": postalCode,
        };
        props.onSubmit(1,0,data,'static');
        handleClear();
    }
    function handleClear() {
        setActionCode('')
        setGPOName('')
        setGPOMemberNumber('')
        setGPOMemberName('')
        setGLNID('')
        setHINID('')
        setActivationID('')
        setTierApproved('')
        setDirectParentMemberNumber('')
        setDirectParentMemberName('')
        setTopMemberNumber('')
        setTopMemberName('');
        setStreet('')
        setCity('')
        setState('')
        setPostalCode('')
    }
    return (
        <div className={classes.root}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2} style={{ padding: '16px 25px 0px 25px' }}>
                            <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Action Code
                                        </FormLabel>
                                        <OutlinedInput
                                            value={actionCode}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setActionCode(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            GPO Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={GPOName}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleGPOName} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            GPO Member Number
                                        </FormLabel>
                                        <OutlinedInput
                                            value={GPOMemberNumber}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setGPOMemberNumber(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            GPO Member Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={GPOMemberName}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleGPOMemberName} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            GLN ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={GLNID}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setGLNID(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            HIN ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={HINID}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setHINID(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Activation ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={activationID}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setActivationID(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Tier Approved
                                        </FormLabel>
                                        <OutlinedInput
                                            value={tierApproved}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setTierApproved(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Direct Parent Member Number
                                        </FormLabel>
                                        <OutlinedInput
                                            value={directParentMemberNumber}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setDirectParentMemberNumber(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Direct Parent Member Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={directParentMemberName}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleDirectParentMemberName} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Top Member Number
                                        </FormLabel>
                                        <OutlinedInput
                                            value={topMemberNumber}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e)=>setTopMemberNumber(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                        Top Member Name
                                        </FormLabel>
                                        <OutlinedInput
                                            value={topMemberName}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleTopMemberName}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Street Address
                                        </FormLabel>
                                        <OutlinedInput
                                            value={street}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleStreet}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            City
                                        </FormLabel>
                                        <OutlinedInput
                                            value={city}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCity}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            State
                                        </FormLabel>
                                        <OutlinedInput
                                            value={state}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleState}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{root:classes.fontSetting}} className={classes.formLabel}>
                                            Postal Code
                                        </FormLabel>
                                        <OutlinedInput
                                            value={postalCode}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePostalCode}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClear}>
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitStatic}>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Static Run'
                        }
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Dynamic Run'
                        }
                    </Button>
                </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
        attributeData: state.attributeListData.AttributeData,
        customerMasterData: state.customerData.customerMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination,limit,data,type) => dispatch(getTierActivationAddressSearchData(pagination,limit,data,type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TierActivationAddressSearch);