import React, { useEffect } from "react";
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    FormLabel,
    MenuItem,
    Select,
    ListItemText,
    TextField,
    Checkbox,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
    allowedApps, addReportTypeFilter, getSingleReportTypeData, getConfigurationDesigner, getReportTypeFilter,
    getAccessDataNew,

} from "../../redux/actions";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import ChipInput from "material-ui-chip-input";
import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: "27px 29px 27px 29px",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: "1px solid #EEEEEE",
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
    },
    gridContainer: {
        padding: "0px 16px 0px 16px",
    },
    button: {
        marginRight: 25,
        width: 140,
    },
    selectRoot: {
        padding: "0px 0px 15px 0px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    select: {
        width: "100%",
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: "100%",
    },
    buttonRoot: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 3,
        paddingBottom: 28,
    },
    paper: { minWidth: window.screen.width * 0.5 },
    input: {
        padding: 0,
        height: 21,
        fontFamily: "ProximaNova",
        fontSize: 13,
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: "#E1E4F3",
        "&&:hover": {
            backgroundColor: "#E1E4F3",
            color: theme.palette.black,
        },
        fontFamily: "ProximaNova",
        padding: 0,
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll",
    },
    rootContainer: {
        border: "1px solid",
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 36,
        padding: 0,
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginRight: 8,
        backgroundColor: "#E1E4F3",
        "&&:hover": {
            backgroundColor: "#E1E4F3",
            color: theme.palette.black,
        },
        fontFamily: "ProximaNova",
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: "ProximaNova",
        fontSize: 13,
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
    },
    chipContainer: {
        display: "flex",
        alignItems: "center",
        overflowX: "overlay",
        flexFlow: "unset",
        borderRadius: 5,
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
}));

const AddAnalyticsDesigner = (props) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const classes = useStyles();
    const [editID, setEditID] = React.useState(0);
    const [applicationType, setApplicationType] = React.useState("");
    const [accessData, setAccessData] = React.useState([]);
    const [fApplicationType, setfApplicationType] = React.useState("");
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [analyticName, setAnalyticName] = React.useState("");
    const [analyticNameArray, setAnalyticNameArray] = React.useState("");
    const [filters, setFilters] = React.useState([]);
    const [filtersArray, setFiltersArray] = React.useState([]);
    const [listOpen, setListOpen] = React.useState("");
    const [listOpen1, setListOpen1] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [selected1, setSelected1] = React.useState([]);
    const isAllSelected =
        filtersArray.length > 0 && selected.length === filtersArray.length;
    const isAllSelected1 =
        accessData.length > 0 && selected1.length === accessData.length;
    const [analyticDescription, setAnalyticDescription] = React.useState("");
    const [apps, setApps] = React.useState([]);
    const formatTypeValues = [
        "operational_report",
        "on_demand_query",
        "on_demand_analytics",
        "predefined_query",
        "analytics",
        "dashboard"
    ];
    const operationalReportValues = [
        "Calculation Summary",
        "Contract List",
        "Postings Summary",
        "Commitment Details",
        "Contract Change",
        "Calculate Formula values",
        "Ratio of Two Key Figures",
        "Accrual vs. Payment",
        "Flexible Stats",
        "Incentive Variance Analysis",
        "Duplicate Payments",
        "Anamoly Report",
        "Approval History",
        "Calculate Formula values by Contract Attribute",
    ];

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf("/") + 1);
        setEditID(appId);
        if (appId) props.getSingleAttributeMapping(appId);
    }, []);

    useEffect(() => {
        props.allowedApps();
        props.getAccessData();
        props.getClaimData();
    }, []);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData]);

    useEffect(() => {
        if (props.accessData) {
            const names = []
            props.accessData.map(item =>
                names.push(item.name));
            setAccessData(names);
            // setApps(props.accessData.security_role);
        }
    }, [props.accessData]);

    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
    };

    function handleAnalyticDescription(e) {
        setFilters([])
        setAnalyticDescription(e.target.value)
        if (e.target.value === "operational_report") {
            setFiltersArray(operationalReportValues)
        }
        else {
            props.getReportType("Customer Rebate", e.target.value);
        }
    }

    function handleAnalysisName(e) {
        setAnalyticName(e.target.value);
    }
    useEffect(() => {
        if (props.claimData && props.claimData.length > 0) {
            props.claimData.forEach(data => {
                if (data.key_name === "reporting_category") {
                    if (data.config_fields != null) {
                        setAnalyticNameArray(data.config_fields)
                    }
                    else {
                        setAnalyticNameArray([])
                    }
                }
            });
        }
    }, [props.claimData]);

    useEffect(() => {
        if (props.reportTypeData && props.reportTypeData.length > 0) {
            setFiltersArray(props.reportTypeData)
        }
        else {
            setFiltersArray([])
        }
    }, [props.reportTypeData]);


    useEffect(() => {
        if (props.attributeMapData) {
            setApplicationType(props.attributeMapData.application_type);
            setApps(props.attributeMapData.persona);
            setAnalyticName(props.attributeMapData.reporting_category);
            setAnalyticDescription(props.attributeMapData.report_type);
            setFilters(props.attributeMapData.report_details);
            if (props.attributeMapData.report_type === "operational_report") {
                setFiltersArray(operationalReportValues)
            }
            else {
                props.getReportType("Customer Rebate", props.attributeMapData.report_type);
            }
        }
    }, [props.attributeMapData]);


    function handleOnSubmit() {
        var data = {
            application_type: applicationType,
            persona: apps,
            reporting_category: analyticName,
            report_type: analyticDescription,
            report_details: filters,
        };
        props.onSubmit(data, editID);
    }

    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            setSelected(selected.length === filtersArray.length ? [] : filtersArray);
            return;
        }
        setSelected(newSelected);
    };
    const handleSelectValues1 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected1.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected1, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected1.slice(1));
        } else if (selectedIndex === selected1.length - 1) {
            newSelected = newSelected.concat(selected1.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected1.slice(0, selectedIndex),
                selected1.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            setSelected1(selected1.length === accessData.length ? [] : accessData);
            return;
        }
        setSelected1(newSelected);
    };
    function onAdd() {
        setFilters(selected);
        setListOpen(false);
    }
    function onAdd1() {
        setApps(selected1);
        setListOpen1(false);
    }
    function onCancel() {
        setListOpen(false);
        setSelected([]);
    }
    function onCancel1() {
        setListOpen1(false);
        setSelected1([]);
    }
    function handleOpen() {
        setSelected(filters);
        setListOpen(true);
    }
    function handleOpen1() {
        setSelected1(apps);
        setListOpen1(true);
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    variant="h4"
                    classes={{
                        root: classes.link,
                    }}
                    to="/reporting-by-function"
                >
                    Process Reporting Designer
                </Link>
                <Typography color="textPrimary" variant="h4">
                    Edit Process Report
                </Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form autoComplete="off" noValidate>
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid item md={3} xs={12}>
                                    <FormLabel className={classes.formLabel} required>
                                        Application Type
                                    </FormLabel>
                                    <Select
                                        value={applicationType}
                                        onChange={handleApplicationtype}
                                        displayEmpty
                                        style={{ textTransform: "capitalize" }}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem,
                                        }}
                                    >
                                        {allowedApps && allowedApps.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    value={item}
                                                    key={index}
                                                    style={{ textTransform: "capitalize" }}
                                                >
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormLabel className={classes.formLabel} required>
                                        Persona
                                    </FormLabel>
                                    <Select
                                        value={apps}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={handleOpen1}
                                        onClose={() => setListOpen1(false)}
                                        open={listOpen1}
                                        // renderValue={(selected1) => selected1}
                                        renderValue={(appType) => {
                                            var priceType = [];
                                            appType.map((item) => {
                                                priceType.push(item.replace(/_/g, " "));
                                            });
                                            return priceType.join(", ");
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem,
                                        }}
                                        multiple
                                    >
                                        <MenuItem
                                            value="all"
                                            onClick={(event) => handleSelectValues1(event, "all")}
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            <Checkbox color="primary" checked={isAllSelected1} />
                                            <ListItemText primary="Select All" />
                                        </MenuItem>
                                        {accessData && accessData.length > 0 &&
                                            accessData.map((item) => {
                                                return (
                                                    <MenuItem
                                                        onClick={(event) =>
                                                            handleSelectValues1(event, item)
                                                        }
                                                        value={item}
                                                        key={item}
                                                        style={{ textTransform: "capitalize" }}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            checked={selected1.indexOf(item) > -1}
                                                        />
                                                        <ListItemText primary={item.replace(/_/g, " ")} />
                                                    </MenuItem>
                                                );
                                            })}
                                        <div className={classes.dropdownAction}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className={classes.btn}
                                                onClick={onCancel1}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.btn}
                                                onClick={onAdd1}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Reporting Category
                                        </FormLabel>
                                        <Select
                                            value={analyticName}
                                            onChange={handleAnalysisName}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ textTransform: "capitalize" }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                        >
                                            {analyticNameArray &&
                                                analyticNameArray.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={item}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Reporting Type
                                        </FormLabel>
                                        <Select
                                            value={analyticDescription}
                                            onChange={handleAnalyticDescription}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ textTransform: "capitalize" }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                        >
                                            {formatTypeValues &&
                                                formatTypeValues.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={item}>
                                                            {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Values
                                        </FormLabel>
                                        <Select
                                            value={filters}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            onOpen={handleOpen}
                                            onClose={() => setListOpen(false)}
                                            open={listOpen}
                                            renderValue={(appType) => {
                                                var priceType = [];
                                                appType.map((item) => {
                                                    priceType.push(item.replace(/_/g, " "));
                                                });
                                                return priceType.join(", ");
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                            multiple
                                        >
                                            <MenuItem
                                                value="all"
                                                onClick={(event) => handleSelectValues(event, "all")}
                                                style={{ textTransform: "capitalize" }}
                                            >
                                                <Checkbox color="primary" checked={isAllSelected} />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {filtersArray &&
                                                filtersArray.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            onClick={(event) =>
                                                                handleSelectValues(event, item)
                                                            }
                                                            value={item}
                                                            key={item}
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={selected.indexOf(item) > -1}
                                                            />
                                                            <ListItemText primary={item.replace(/_/g, " ")} />
                                                        </MenuItem>
                                                    );
                                                })}
                                            <div className={classes.dropdownAction}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.btn}
                                                    onClick={onCancel}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btn}
                                                    onClick={onAdd}
                                                >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleOnSubmit}
                    // disabled={
                    //     applicationType && analyticName && filters
                    //         ? false
                    //         : true
                    // }
                    >
                        {props.loadingAPI ? (
                            <BeatLoader
                                color={"rgb(54, 215, 183)"}
                                loading={props.loadingAPI}
                                size={6}
                            />
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        attributeMapData: state.addMultipleConfigurationData.reportypedataSingle,
        allowedAppsData: state.initialData.allowedApps,
        accessData: state.addMultipleConfigurationData.accessDataNew,
        claimData: state.addMultipleConfigurationData.configdesigner,
        reportTypeData: state.addMultipleConfigurationData.reportypefilter,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (data, id) => dispatch(addReportTypeFilter(data, id, "edit")),
        getSingleAttributeMapping: (id) => dispatch(getSingleReportTypeData(id)),
        allowedApps: () => dispatch(allowedApps()),
        getAccessData: () => dispatch(getAccessDataNew()),
        getClaimData: () => dispatch(getConfigurationDesigner()),
        getReportType: (app, data) => dispatch(getReportTypeFilter(app, data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAnalyticsDesigner);
