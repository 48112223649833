import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {  RatioKeyFiguresTab } from './containers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        marginBottom: 23,
        marginTop: 14
    }
}));
const RatioKeyFiguresReposts = props => {
    const classes = useStyles();
    const { className } = props;

    return (
            <div
                className={clsx(classes.root, className)}>
                <RatioKeyFiguresTab />
            </div>
    );

};
export default RatioKeyFiguresReposts;