import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card, IconButton, OutlinedInput, FormLabel
} from '@mui/material';
import { makeStyles, } from '@mui/styles';
import { createClaimError } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { AddBoxOutlined, DeleteForever } from '@mui/icons-material';
import { TextInputWithLabel } from '../../components/Inputs';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    error: {
        border: '1px solid red !important'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 28,
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }, inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const EditClaimError = props => {
    const classes = useStyles();
    const history = useHistory();
    const [rejectionReasonCode, setRejectionReasonCode] = React.useState([]);
    const [rejectionReasonDescription, setRejectionReasonDescription] = React.useState([]);
    const [claimError, setClaimError] = React.useState([]);
    const [editID, setEditID] = React.useState(0);
    const format = { rejection_reason_code: '', rejection_reason_description: '', claim_error: '' }
    const [allData, setAllData] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [allDataNew, setAllDataNew] = React.useState([]);
    console.log(history.location.state)
    useEffect(() => {
        var pathname = window.location.pathname;
        console.log(pathname)
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleUom(null, 'editMode', appId, history.location.state)
        setEditID(appId)
    }, []);
    useEffect(() => {
        if (props.uomData && props.uomData.rejection_reason) {

            setClaimError(props.uomData.rejection_reason[0].claim_error)
            setRejectionReasonCode(props.uomData.rejection_reason[0].rejection_reason_code)
            setRejectionReasonDescription(props.uomData.rejection_reason[0].rejection_reason_description)
        }

    }, [props.uomData]);
    useEffect(() => {
        if (props.claimError && props.uomData && props.uomData.rejection_reason) {
            setAllDataNew(props.claimError[0]['rejection_reason'].filter(item => item.rejection_reason_code != props.uomData.rejection_reason[0].rejection_reason_code))
        }
    }, [props.claimError, props.uomData])
    console.log(allDataNew)
    function handleClearAll() {
        setRejectionReasonCode('')
        setRejectionReasonDescription('')
        setClaimError('')
    }
    function handleOnSubmit() {
        var data = {
            "rejection_reason_code": rejectionReasonCode,
            "rejection_reason_description": rejectionReasonDescription,
            "claim_error": claimError
        }
        allDataNew.push(data)
        props.onSubmit(allDataNew, 'edit', editID);
    }
    function handleStep(newValue, index) {
        allData[index]['rejection_reason_code'] = (newValue)
    }
    function handlePriority(newValue, index) {
        allData[index]['rejection_reason_description'] = (newValue)
    }
    function handleApplyToStep(newValue, index) {
        allData[index]['claim_error'] = (newValue)
    }

    function handleOnAdd() {
        rejectionReasonCode[addMember] = ''
        rejectionReasonDescription[addMember] = ''
        claimError[addMember] = ''
        setAllData([...allData, format])
        setAddMember(addMember + 1)
    }
    const handleDeleteLocally = (e, index) => {
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
        setAllData(allData.filter((item, i) => i != index));
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/rejection-reasons'
                >
                    Rejection Reasons
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Rejection Reasons</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Rejection Reason Code
                                    </FormLabel>
                                    <OutlinedInput
                                        value={rejectionReasonCode}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setRejectionReasonCode(e.target.value)}
                                        placeholder={props.placeholder}
                                        required />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Rejection Reason Description
                                    </FormLabel>
                                    <OutlinedInput
                                        value={rejectionReasonDescription}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setRejectionReasonDescription(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Claim Error
                                    </FormLabel>
                                    <OutlinedInput
                                        value={claimError}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setClaimError(e.target.value)}
                                        placeholder={props.placeholder} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={
                            !rejectionReasonCode || !claimError || !rejectionReasonDescription ? true : false
                        }
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(createClaimError(data, type, id)),
        getSingleUom: (data, type, id, rejectionReason) => dispatch(createClaimError(data, type, id, rejectionReason)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        claimError: state.calculationAnalysisData.claimError,
        uomData: state.calculationAnalysisData.claimErrorSingle,

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClaimError);