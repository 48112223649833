import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";

const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [

    { 'Incentive Strategy': '/analytics' },
    { 'Postings Summary': '/analytics-comparison' },
    { 'Gross to Net': '/sales-payment-comparison' },
    { 'Regional Analysis': '/geographical-chart-us' },
    { 'Top Incentives': '/top25' },
    {
        'Trending': [
            { 'Incentive Drivers': '/rebate-trends' },
            { 'Incentive Amount': '/variance-combined' },
            { ' Incentive Basis': '/incentive-trends' },
        ]
    },

    // { 'Calculation Analysis': '/contract-trends' },
]

const Analytics = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}
                    >{Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);