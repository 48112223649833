import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Typography, useMediaQuery, FormLabel, OutlinedInput,
  Table, TableCell, TableHead, TableRow, TableContainer,
  TableBody, Button, TablePagination,
  Fab, Dialog, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import {
  getQueryList,
  runDynamicQuery,
  getDynamicQuery,
  deleteDynamicQuery,
  cloneDynamicQuery, downloadDynamicQuery
} from "../../redux/actions";
import { LibraryAdd, Email } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { FlightTakeoff, Reorder, GetApp } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.table.row,
      height: 46,
    },
    "&:nth-of-type(even)": {
      height: 40,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: "20px 16px",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  caption: {
    fontSize: 12,
  },
  paginationRoot: {
    border: "1px solid #EEEEEE",
    borderTop: 0,
  },
  description: {
    textAlign: "left",
    paddingLeft: 28,
  },
  hover: {
    cursor: "pointer",
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: 10,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
  },
  tableCellStyle: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize,
  },
  fabContainer: {
    marginRight: 5,
    padding: 3,
    border: "2px solid",
    backgroundColor: "#ffff",
    borderColor: theme.palette.primary.main,
    zIndex: 1,
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize,
  },
  inputTwoLine: {
    marginTop: 10,
    width: "100%",
    paddingLeft: 10,
    height: 35,
  }
}));
const DynamicQuery = (props) => {
  const [currentPage, setCurrentPage] = React.useState("");
  const [queryListID, setQueryListID] = React.useState(1);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [selectedQuery, setSelectedQuery] = React.useState({});
  const history = useHistory();
  const location = useLocation();
  const [openConfirmEmail, setOpenConfirmEmail] = React.useState(false);
  const [emailId, setEmailId] = React.useState(sessionStorage.getItem('loginData') ? JSON.parse(sessionStorage.getItem('loginData')).email : '');
  const columns = [
    { title: 'Query ID', field: 'query_id' },
    { title: 'Data Source', field: 'table_description' },
    { title: 'Query Name', field: 'format_name' },
    { title: 'Query Description', field: 'query_description' },
    {
      title: 'Actions',
      field: 'actions',
      render: item =>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div>
            {secureLocalStorage.getItem("dYtz") &&
              secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_partner_statement-" +
                  sessionStorage.getItem("application")
                ) && (
                <Tooltip title="View">
                  <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => viewQuery(item, 'view')}
                    size="large">
                    <VisibilityIcon
                      color="disabled"
                      style={{ fontSize: 20 }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            {secureLocalStorage.getItem("dYtz") &&
              secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage
                .getItem("dYtz")
                // .role.rights.includes(
                //   "put_report-" +
                //   sessionStorage.getItem("application")
                .role.rights.includes(
                  (currentPage === "partner-statement" ? "put_partner_statement" : "put_report") +
                  "-" +
                  sessionStorage.getItem("application")
                ) && (
                <Tooltip title="Edit">
                  <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => editQuery(item)}
                    size="large">
                    <EditIcon
                      color="disabled"
                      style={{ fontSize: 20 }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            {secureLocalStorage.getItem("dYtz") &&
              secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  (currentPage === "partner-statement" ? "put_partner_statement" : "put_report") +
                  "-" +
                  sessionStorage.getItem("application")
                ) && (
                <Tooltip title="Re-arrange">
                  <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => viewQuery(item, 'Re-arrange')}
                    size="large">
                    <Reorder
                      color="disabled"
                      style={{ fontSize: 20 }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            {secureLocalStorage.getItem("dYtz") &&
              secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  (currentPage === "partner-statement" ? "post_partner_statement" : "post_report") +
                  "-" +
                  sessionStorage.getItem("application")
                ) && (
                <Tooltip title="Clone">
                  <IconButton
                    onClick={() => cloneQuery(item)}
                    classes={{ root: classes.IconButton }}
                    size="large">
                    <img
                      src={require("../../library/images/Copy.png")}
                      alt=""
                    />
                  </IconButton>
                </Tooltip>
              )}
          </div>
          <div
            style={{
              marginLeft: 5,
              borderLeft: "1px solid lightgray",
            }}
          >
            {secureLocalStorage.getItem("dYtz") &&
              secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  (currentPage === "partner-statement" ? "put_partner_statement" : "put_report") +
                  "-" +
                  sessionStorage.getItem("application")
                ) && (
                <Tooltip title="Delete">
                  <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() => deleteQuery(item)}
                    size="large">
                    <DeleteIcon
                      color="disabled"
                      style={{ fontSize: 20 }}
                    />
                  </IconButton>
                </Tooltip>
              )}

            {/* {currentPage == "partner-statement" && (
        <Tooltip title="Mail">
          <IconButton
            classes={{ root: classes.IconButton }}
            onClick={() =>
              toast.success("Email successfully sent.")
            }
          >
            <MailOutlineIcon
              color="disabled"
              style={{ fontSize: 20 }}
            />
          </IconButton>
        </Tooltip>
      )} */}
          </div>
          <div
            style={{
              marginLeft: 5,
              borderLeft: "1px solid lightgray",
            }}
          >
            {secureLocalStorage.getItem("dYtz") &&
              secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage
                .getItem("dYtz")
                // .role.rights.includes(
                //   "execute_report-" +
                //   sessionStorage.getItem("application")
                .role.rights.includes(
                  (currentPage === "partner-statement" ? "run_partner_statement" : "execute_report") +
                  "-" +
                  sessionStorage.getItem("application")
                ) && (
                <>
                  <Tooltip title="Run - Show Pages">
                    <IconButton
                      classes={{ root: classes.IconButton }}
                      onClick={() => runQuery(item, 'table-data')}
                      style={{ marginLeft: 5 }}
                      size="large">
                      <DirectionsRunIcon
                        color="disabled"
                        style={{ fontSize: 20 }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Run - Show All">
                    <IconButton
                      classes={{ root: classes.IconButton }}
                      onClick={() => runQuery(item, "sf")}
                      style={{
                        marginLeft: 5,
                        fontSize: 14,
                      }}
                      size="large">
                      <FlightTakeoff color="disabled"
                        style={{ fontSize: 20 }}
                      />
                    </IconButton>
                  </Tooltip>

                </>
              )}
          </div>
          <div
            style={{
              marginLeft: 5,
              borderLeft: "1px solid lightgray",
            }}
          >
            {secureLocalStorage.getItem("dYtz") &&
              secureLocalStorage.getItem("dYtz").role &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage.getItem("dYtz").role
                .rights &&
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  (currentPage === "partner-statement" ? "run_partner_statement" : "execute_report") +
                  "-" +
                  sessionStorage.getItem("application")
                ) && (
                <>
                  <Tooltip title="Excel Download">
                    <IconButton
                      classes={{ root: classes.IconButton }}
                      onClick={() => runQuery(item, 'download')}
                      size="large">
                      <GetApp color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Email">
                    <IconButton
                      classes={{ root: classes.IconButton }}
                      onClick={() => runQuery(item, 'email')}
                      size="large">
                      <Email color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>

                </>
              )}
          </div>
        </div>
    }

  ]

  useEffect(() => {
    sessionStorage.removeItem("SFDQ");
  }, []);
  useEffect(() => {
    setCurrentPage(
      location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    );
  }, [location]);

  useEffect(() => {
    if (currentPage) {
      if (currentPage === "partner-statement") {
        setQueryListID(3);
        props.getQueryList(1, 0, 3, sessionStorage.getItem("application"));
      } else if (currentPage === "collaboration-portal") {
        setQueryListID(2);
        props.getQueryList(1, 0, 2, sessionStorage.getItem("application"));
      } else {
        setQueryListID(1);
        props.getQueryList(1, 0, 1, sessionStorage.getItem("application"));
      }
    }
  }, [currentPage]);

  const { className } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [contractsTableComplete, setContractsTableComplete] = React.useState(
    []
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  useEffect(() => {
    if (props.contractTabledataComplete) {
      setContractsTableComplete(props.contractTabledataComplete);
    }
  }, [props.contractTabledataComplete]);

  function runQuery(item, type) {
    if (type === "sf") {
      sessionStorage.setItem("SFDQ", true);
    }
    if ((type == 'email' || type == 'download') && item.filter_fields.length == 0) {
      if (type == 'email') {
        setOpenConfirmEmail(true)
        setSelectedQuery(item)
      }
      else
        handleDownloadExcel(item.ID, type)
    }
    else if (currentPage === "partner-statement") {
      props.getDynamicQuery(item.ID);
      sessionStorage.removeItem("dyamicQueryDownloadData");
      var data = {
        id: item.ID.toString(),
      };
      if (item.filter_fields.length > 0) {
        var object = {
          id: item.ID,
          fields: item.filter_fields,
          sort_fields: item.sort_fields,
          data_source_type: item.data_source_type,
        };
        sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
        history.push({
          pathname: "/partner-statement/partner-statement-form",
          filterValues: item.filter_fields,

        });
      } else {
        var object = {
          id: item.ID,
          fields: [],
          sort_fields: item.sort_fields,
          data_source_type: item.data_source_type,
        };
        sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
        props.runDynamicQueryPS(data, item.ID);
      }
    }
    else if (currentPage === "collaboration-portal") {
      props.getDynamicQuery(item.ID);
      sessionStorage.removeItem("dyamicQueryDownloadData");
      var data = {
        id: item.ID.toString(),
      };
      if (item.filter_fields.length > 0) {
        var object = {
          id: item.ID,
          fields: item.filter_fields,
          sort_fields: item.sort_fields,
          data_source_type: item.data_source_type,
        };
        sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
        history.push({
          pathname: "/collaboration-portal/collaboration-portal-form",
          filterValues: item.filter_fields,
        });
      } else {
        var object = {
          id: item.ID,
          fields: [],
          sort_fields: item.sort_fields,
          data_source_type: item.data_source_type,
        };
        sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
        props.runDynamicQueryCP(data, item.ID);
      }
    } else {
      sessionStorage.setItem("SFDQ", true);
      props.getDynamicQuery(item.ID);
      sessionStorage.removeItem("dyamicQueryDownloadData");
      var data = {
        id: item.ID.toString(),
      };
      if (item.filter_fields.length > 0) {
        var object = {
          id: item.ID,
          fields: item.filter_fields,
          sort_fields: item.sort_fields,
          data_source_type: item.data_source_type,
        };
        sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
        history.push({
          pathname: "/dynamic-query/dynamic-query-form",
          filterValues: item.filter_fields,
          type: type == 'sf' ? 'sync-data' : type
        });
      } else {
        if (type != 'download' && type != 'email') {
          var object = {
            id: item.ID,
            fields: [],
            sort_fields: item.sort_fields,
            data_source_type: item.data_source_type,
          };
          sessionStorage.setItem("dynamic_filter_fields", JSON.stringify(object));
          if (type === 'table-data')
            props.runDynamicQuery(data, null, "dq", "dynamicQuery", type)
          else
            props.runDynamicQuery(data, item.ID);
        }

      }
    }
  }

  function editQuery(item) {
    sessionStorage.removeItem("dq_table_name");
    props.getDynamicQuery(item.ID);
    if (currentPage === "partner-statement") {
      history.push("/partner-statement/edit-partner-statement/" + item.ID);
    } else if (currentPage === "collaboration-portal") {
      history.push(
        "/collaboration-portal/edit-collaboration-portal/" + item.ID
      );
    } else {
      history.push("/dynamic-query/edit-dynamic-query/" + item.ID);
    }
  }
  function viewQuery(item, type) {
    sessionStorage.removeItem("dq_table_name");
    props.getDynamicQuery(item.ID);
    if (currentPage === "partner-statement") {
      history.push({
        pathname: type == 'view' ? "/partner-statement/view-partner-statement/" + item.ID :
          "/partner-statement/re-arrange-partner-statement/" + item.ID,
        dynamicType: type
      });
    } else if (currentPage === "collaboration-portal") {
      history.push(
        {
          pathname: type == 'view' ? "/collaboration-portal/view-collaboration-portal/" + item.ID :
            "/collaboration-portal/re-arrange-collaboration-portal/" + item.ID,
          dynamicType: type
        }
      );
    } else {
      history.push({
        pathname: type == 'view' ? "/dynamic-query/view-dynamic-query/" + item.ID :
          "/dynamic-query/re-arrange-dynamic-query/" + item.ID,
        dynamicType: type
      });
    }
  }

  function handleDownloadExcel(id, type) {
    setOpenConfirmEmail(false)
    var data = {
      "id": id.toString(),
    }
    props.downloadDynamicQuery(data, type, emailId, currentPage);
  }
  function deleteQuery(item) {
    setOpenConfirmDelete(true)
    setSelectedQuery(item)

  }
  function onDeleteConfirm() {
    setOpenConfirmDelete(false)
    props.deleteDynamicQuery(
      selectedQuery.ID,
      1,
      10,
      queryListID,
      sessionStorage.getItem("application")
    );
  }
  function cloneQuery(item) {
    props.cloneDynamicQuery(
      item.ID,
      1,
      10,
      queryListID,
      sessionStorage.getItem("application")
    );
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([
    10, 20, 30,
  ]);
  const [queryListData, setQueryListData] = React.useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.getQueryList(
      newPage + 1,
      rowsPerPage,
      queryListID,
      sessionStorage.getItem("application")
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    props.getQueryList(
      1,
      event.target.value,
      queryListID,
      sessionStorage.getItem("application")
    );
  };

  useEffect(() => {
    if (props.queryListData && props.queryListData.records) {
      setQueryListData(props.queryListData);
    }
  }, [props.queryListData]);
  const handleRunPage = () => {
    if (currentPage === "partner-statement") {
      history.push("/partner-statement/add-partner-statement");
    } else if (currentPage === "collaboration-portal") {
      history.push("/collaboration-portal/add-collaboration-portal");
    } else {
      history.push("/dynamic-query/add-dynamic-query");
    }
  };
  return (
    <LoadingOverlay
      active={props.loading}
      spinner={<HashLoader />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#045FB4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "black",
        }),
      }}
      className={classes.spinner}
    >
      <div className={clsx(classes.root, className)}>
        <div
          className={clsx({
            [classes.row]: isDesktop,
          })}
        >
          <Typography variant="h1" color="primary" style={{ marginTop: 7, marginLeft: 9 }}>
            {currentPage === "partner-statement"
              ? "Partner Statement"
              : currentPage === "collaboration-portal"
                ? "On Demand Query"
                : "On Demand Query"}
          </Typography>
          {secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                (currentPage === "partner-statement" ? "post_partner_statement" : "post_report") +
                "-" +
                sessionStorage.getItem("application")
              ) && (
              <Fab
                aria-label="edit"
                variant="extended"
                size="medium"
                classes={{ root: classes.fabContainer }}
                className={classes.fabContainer}
                onClick={() => handleRunPage()}
              >
                <LibraryAdd color="primary" />{" "}
                <Typography color="primary" style={{ marginLeft: 3 }}>
                  Add
                </Typography>
              </Fab>
            )}
        </div>
        {queryListData &&
          queryListData.records &&
          queryListData.records.length > 0 ? (
          <MaterialTable
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    height: "0px",
                  }}
                ></div>
              ),
            }}
            title={" "}
            editable={true}
            icons={tableIcons}
            columns={columns}
            data={queryListData.records}
            options={{
              search: false,
              filtering: true,
              paging: false,
              // pageSize: rowsPerPage,
              // pageSizeOptions: rowsPerPageOptions,
              emptyRowsWhenPaging: true,
              toolbarButtonAlignment: 'left',
              headerStyle: theme.mixins.MaterialHeader,
              cellStyle: {
                fontSize: '.90rem',
                textAlign: "center",
                borderRight: "1px solid #EEEEEE",
                color: "black !important",
                padding: '5px 0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 800,
                width: 30,
                minWidth: 5,
              },
            }}
          />

        ) : (
          <Typography variant="h4">There is no data to show now.</Typography>
        )}
        {/* <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={
                queryListData && queryListData.total_record
                  ? queryListData.total_record
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
      </div>
      <Dialog open={openConfirmDelete}>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Typography variant="h4">
            <DialogContentText>
              Are you sure you want to delete query{" "}
              {selectedQuery.format_name} ?
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.dialog,
          }}
        >
          <Button
            onClick={() => setOpenConfirmDelete(false)}
            autoFocus
            color="primary"
            variant="outlined"
          >
            No
          </Button>
          <Button
            onClick={onDeleteConfirm}
            color="primary"
            autoFocus
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmEmail}>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Typography variant="h4">
            <DialogContentText>
              <FormLabel classes={{ root: classes.fontSetting }}>Email Id</FormLabel>
              <OutlinedInput
                classes={{ root: classes.inputTwoLine }}
                type={'email'}
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.dialog,
          }}
        >
          <Button
            onClick={() => setOpenConfirmEmail(false)}
            autoFocus
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDownloadExcel(selectedQuery.ID, 'email')}
            color="primary"
            autoFocus
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    queryListData: state.operationalReportsData.queryListSuccessData,
    loading: state.simulationData.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQueryList: (pagination, limit, type, app) =>
      dispatch(getQueryList(pagination, limit, type, app)),
    // runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id)),
    runDynamicQuery: (data, id, dq, page, reset) => dispatch(runDynamicQuery(data, id, dq, page, reset)), //data, null, "dq", "dynamicQuery", type)),
    getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
    deleteDynamicQuery: (id, pagination, limit, queryType, app) =>
      dispatch(deleteDynamicQuery(id, pagination, limit, queryType, app)),
    cloneDynamicQuery: (id, pagination, limit, type, app) =>
      dispatch(cloneDynamicQuery(id, pagination, limit, type, app)),
    runDynamicQueryCP: (data, id) =>
      dispatch(runDynamicQuery(data, id, null, "collaboration")),
    runDynamicQueryPS: (data, id) =>
      dispatch(runDynamicQuery(data, id, null, "paymentpartner")),
    downloadDynamicQuery: (data, type, email, page) => dispatch(downloadDynamicQuery(data, type, email, page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQuery);
