import React, {useEffect } from 'react';
import {
    Button,
    Grid,
} from '@mui/material';
import { makeStyles} from '@mui/styles';
import { AssignSticker, SetupData, Region } from './components';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { createPromotionSetup, getCountryData, getPromotionMaterialData } from '../../../redux/actions';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },

    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },

    pageControls: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },

}));
const tabs = {
    GeneralData: 0,
    Stickers: 1,
    Region: 2,
};

function getSteps() {
    return ['GeneralData', 'Stickers', 'Region'];
}

const PromotionSetup = props => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [completed, setCompleted] = React.useState(new Set());
    const [promoData, setPromoData] = React.useState([])
    const [promoStickerData, setPromoStickerData] = React.useState([])
    const [promoRegionData, setPromoRegionData] = React.useState([])
    const [promoMaterials, setPromoMaterials] = React.useState([]);
    const [promoFilter, setPromoFilter] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [promotion, setPromotion] = React.useState([]);
    const [valid, setValid] = React.useState(false);
    const [country, setCountry] = React.useState(localStorage.getItem('Country') ? localStorage.getItem('Country') : '');
    const skipped = new Set()
    const [materialFilter, setMaterialFilter] = React.useState([])
    useEffect(() => {
        props.getMaterialData()
    }, [])
   useEffect(() => {
        setCountry(localStorage.getItem('Country'))
        props.getCountryData(localStorage.getItem('Country'))
    }, [localStorage.getItem('Country')])
    useEffect(() => {
        if (props.materialData && props.materialData.length > 0) {
            setPromoMaterials(props.materialData)
            setPromoFilter(props.materialData)
        }
    }, [props.materialData])
    useEffect(()=>{
        if (promoMaterials && promoMaterials.length > 0 && states && states.length > 0) {
            promoMaterials.map((item, index) => {
                var temp = []
                promoFilter[index]['ID'] = index
                promoFilter[index]['model_level'] = 'Market Segment'
                states && states.map(x => {
                    temp.push({ 'promotion_name': '', 'promotion_description': '', 'state': x.state })
                })
                promoFilter[index]['promotion_state'] = temp

            })
            setPromoFilter(promoFilter)
        }
    },[promoMaterials,states])
   
    useEffect(() => {

        if (props.countryData && props.countryData.length > 0) {
            setStates(props.countryData)
        }
    }, [props.countryData])

    const totalSteps = () => {
        return getSteps().length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/promotion-setup");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    function handleGeneralDataChange(values, model) {
        setPromoData(values);
        if(values.country&&values.division)
         setValid(true)
    };
    function handlePromoStickers(values, promotion) {
        setPromoStickerData(values)
        setPromotion(promotion)
        handleNext()
    };
    function handleSubmit() {
        var eligibility = []
        if (promoRegionData && promoRegionData.length > 0) {
            promoRegionData.map((itemX, i) => {
                var array = []
                promoRegionData[i] && promoRegionData[i]['promotion_state'] && promoRegionData[i]['promotion_state'].map((item, idx) => {
                    if (item.promotion_name != '' && item.promotion_description != ''
                         && promotion.findIndex(({promotion_name})=>promotion_name==item.promotion_name)!=-1)
                        array.push(item)
                })
                if (array.length > 0) {
                    var keyData = {
                        'material_group1': itemX.material_group1,
                        'material_group2': itemX.material_group2,
                        'material_group3': itemX.material_group3,
                        'country': localStorage.getItem('Country'),
                        'promotion_state': array
                    }
                    eligibility.push(keyData)

                }
            })
        }

        var data = {
            "promotion": promotion,
            "promotion_budget_benefits": promoStickerData,
            "promotion_budget_eligibility": eligibility
        }
        data = { ...data, ...promoData }
        props.onSubmit(data)
    }
    function handleRegionData(values, filter) {
        setPromoRegionData(values)
        setPromoFilter(values)
        setMaterialFilter(filter)
    };
    const skippedSteps = () => {
        return skipped.size;
    };
    const completedSteps = () => {
        return completed.size;
    };
    function getStepContent(step) {
        const allSteps = [
            <SetupData onChange={handleGeneralDataChange} enable={valid} handleNext={handleNext} />,
            <AssignSticker onChange={handlePromoStickers} 
            promoData={promotion} 
            country={promoData.country}
            handleBack={handleBack}
            />,
            <Region onChange={handleRegionData} promoData={promotion}
                regionData={promoFilter}
                promoFilter={materialFilter&&materialFilter.length>0?materialFilter:promoFilter}
                promoMaterials={promoMaterials}
                start={materialFilter&&materialFilter.length>0?false:true}
                editMode={false}
            />,

        ];

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }



    return (
        <div>
            <div className={classes.root}>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                <Grid container >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        {activeStep == 2 &&
                            <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                Previous Step
                            </Button>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        {activeStep == tabs.Region &&
                            <Button variant="contained" color='primary' className={classes.button} onClick={handleSubmit}>
                                Save
                            </Button>
                        }
                        {activeStep == tabs.Region &&
                            <Button variant="outlined" color='primary' className={classes.button} onClick={() => history.push('/promotion-setup')}>
                                Cancel
                            </Button>
                        }
    
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        materialData: state.promotionData.promotionMaterialData,
        countryData: state.promotionData.countryData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createPromotionSetup(data)),
        getMaterialData: () => dispatch(getPromotionMaterialData()),
        getCountryData: (country) => dispatch(getCountryData(country)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSetup);