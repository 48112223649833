import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TextField, OutlinedInput, FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getOutgoingClaimDownloadFormatFields, outgoingClaimDownloadFormat, getLblDispDesFieldValue, getOutGoingClaimById
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const OutgoingClaimDownloadFormatEditQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [outgoingFields, setOutgoingFields] = React.useState([]);
    const [queryName, setQueryName] = React.useState('');
    const [sortedData, setSortedData] = React.useState([]);
    const [defaultValue, setDefaultValue] = React.useState({})
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('outgoing-claim-format') ? JSON.parse(sessionStorage.getItem('outgoing-claim-format')) : []);
    const [queryObject, setQueryObject] = React.useState({});
    const requiredFields = [
        'claim_date', 'claim_number', 'billing_date', 'billing_doc_number', 'material_number', 'supplier_number', 'acquisition_price', 'quantity', 'unique_identifier', 'net_value'
    ]
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }


    useEffect(() => {
        setOpen(true);
        props.getOutgoingClaimDownloadFormatFields();
        props.getOutGoingClaimById(purchaseQueryDataStorage.id)
        let data = { "data_source": "Outgoing Claim Calculation" }
        props.getLblDispDesFieldValue(data)
    }, []);
    useEffect(() => {
        if (props.outGoingSingleData) {
            if (purchaseQueryDataStorage) {
                setQueryName(purchaseQueryDataStorage.format_name)
                setQueryObject(purchaseQueryDataStorage)
                if (props.outGoingSingleData.default_values != null) {
                    setDefaultValue(props.outGoingSingleData.default_values)
                }
            }
        }
    }, [props.outGoingSingleData, purchaseQueryDataStorage]);

    useEffect(() => {
        let newArray = []
        let newArray2 = []
        if (props.tableData && props.tableData.field_label_attributes && props.outgoingClaimDownloadFormatFields) {
            if (props.outgoingClaimDownloadFormatFields.date_fields) {
                newArray.push(...props.outgoingClaimDownloadFormatFields.date_fields)
            }
            if (props.outgoingClaimDownloadFormatFields.numeric_fields) {
                newArray.push(...props.outgoingClaimDownloadFormatFields.numeric_fields)
            }
            if (props.outgoingClaimDownloadFormatFields.string_fields) {
                newArray.push(...props.outgoingClaimDownloadFormatFields.string_fields)
            }
            Object.values(props.tableData.field_label_attributes)
                .filter(item => newArray.includes(item.key))
                .map(item => {
                    if (item.display) {
                        newArray2.push({ [item.key]: item.current })
                    }
                })
            setSortedData(newArray2)
        }
    }, [props.tableData, props.outgoingClaimDownloadFormatFields])
    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName] === 0) {
                delete obj[propName];
            }
        }
        return obj
    }
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
        setDefaultValue({ ...defaultValue });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit({ ...queryObject, 'format_name': queryName, 'default_values': defaultValue }, purchaseQueryDataStorage.id);
    }
    function handleClearAll() {
        handleClear();
    }
    function handleCancelDialog() {
        history.push('/customer-outgoing-claim-download-format');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }

    };
    const handleChange2 = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/customer-outgoing-claim-download-format'
                >
                    Outgoing Claim Download Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {Object.values(sortedData) ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Field Name</TableCell>
                                                        <TableCell align='center' style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.values(sortedData)
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        {requiredFields.includes(Object.keys(item).toString()) ? <p>{Object.values(item)}<span style={{ color: 'red' }}> &nbsp;*</span></p> : Object.values(item)}
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[Object.keys(item)] ? queryObject[Object.keys(item)] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, Object.keys(item))}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={defaultValue[Object.keys(item)] ? defaultValue[Object.keys(item)] : ''}
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange2(e, Object.keys(item))}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={(queryObject['claim_date'] || defaultValue['claim_date']) && (queryObject['claim_number'] || defaultValue['claim_number']) && (queryObject['billing_date'] || defaultValue['billing_date'])
                            && (queryObject['billing_doc_number'] || defaultValue['billing_doc_number']) && (queryObject['material_number'] || defaultValue['material_number']) && (queryObject['supplier_number'] || defaultValue['supplier_number']) && (queryObject['acquisition_price'] || defaultValue['acquisition_price'])
                            && (queryObject['quantity'] || defaultValue['quantity']) && (queryObject['unique_identifier'] || defaultValue['unique_identifier']) && (queryObject['net_value'] || defaultValue['net_value']) ? false : true}

                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(outgoingClaimDownloadFormat('download', data, id, 'edit')),
        getOutGoingClaimById: (id) => dispatch(getOutGoingClaimById(id)),
        getOutgoingClaimDownloadFormatFields: () => dispatch(getOutgoingClaimDownloadFormatFields()),
        getLblDispDesFieldValue: (data) => dispatch(getLblDispDesFieldValue(data, 'post'))
    }
}

const mapStateToProps = state => {
    return {
        outgoingClaimDownloadFormatFields: state.dataSetupData.outgoingClaimDownloadFormatFields,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        outGoingSingleData: state.dataSetupData.outGoingSingleData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingClaimDownloadFormatEditQuery);