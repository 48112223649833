import React, { forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormLabel, TextField, Chip } from "@mui/material";
import { getAttributeListData } from "../../../redux/actions";
import { connect } from "react-redux";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    marginTop: 10,
  },
  error: {
    border: "1px solid red !important",
  },
  contractContiner: {
    padding: "15px 0px 18px",
  },
  container: {
    padding: "5px 0px 18px",
  },
  borderRad0: {
    borderRadius: "0px !important",
    height: "37px",
    '&.MuiAutocomplete-tag': {
      height: 21
    }
  },
  textFieldStyle: {
    marginTop: 5,
  },
  chargeBackLabel: {
    fontSize: theme.typography.h3.fontSize,
  },
  rootContainer: {
    border: "1px solid",
    borderColor: theme.palette.border.main,
    borderRadius: 3,
    marginTop: 10,
    //height: 33,
    padding: 0,
    marginBottom: 14,
    height: 21
  },
  chip: {
    margin: 0,
    marginLeft: 8,
    marginBottom: 8,
    backgroundColor: "#E1E4F3",
    "&&:hover": {
      backgroundColor: "#E1E4F3",
      color: theme.palette.black,
    },
    // fontFamily: "ProximaNova",
    padding: 0,
    border: 'none'
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    //fontSize: 13,
  },
  inputRoot: {
    height: 21,
    paddingLeft: 8,
    paddingBottom: 25,
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "overlay",
    flexFlow: "unset",
  },
  chipLabel: {
    fontFamily: "ProximaNova",
  },
}));
const CustomerChainComponent = forwardRef((props, ref) => {
  const classes = useStyles();
  const [contractNumbersList, setContractNumbersList] = React.useState([]);
  const [attributeValueArray, setattributeValueArray] = React.useState([]);
  const [attributeDescArray, setattributeDescArray] = React.useState([]);
  const [contractNumber, setContractNumber] = React.useState("");
  const [attributeValue, setAttributeValue] = React.useState([]);
  const [label, setLabel] = React.useState(props.withOutLabel ? false : true);
  const [isFocused, setIsFocused] = useState(false);
  const filter = createFilterOptions();
  useEffect(() => {
    props.getAttributeListData();
  }, []);
  useEffect(() => {
    var temp = [];
    var attr = [];
    var desc = [];
    if (props.attributeData) {
      props.attributeData.map((item) => {
        if (item.attribute_name === props.attributeName) {
          temp.push(item.attribute_value + " - " + item.attribute_desc);
          attr.push(item.attribute_value);
          desc.push(item.attribute_desc);
        }
      });
      setContractNumbersList(temp);
      setattributeValueArray(attr);
      setattributeDescArray(desc);
      if (props.prevalue)
        setContractNumber(props.prevalue);
      if (props.prevalue && props.multiple)
        setAttributeValue(props.prevalue)
    }
  }, [props.attributeData, props.attributeName, props.prevalue]);
  const handleContractNumber = (e, value) => {
    if (value != null && value.length > 0) {
      var index = value.indexOf("-");
      setContractNumber(value.substring(0, index - 1));
    } else {
      setContractNumber("");
    }
    props.onChange(value);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleAttribute = (e, value) => {
    let temp = []
    if (value === "pastedItem") {
      e.map((arg) => {
        if (arg) temp.push(arg.trim());
      });
      setAttributeValue([...attributeValue, ...temp]);
      props.onChange([...attributeValue, ...temp]);
    }
    else {
      console.log(value);
      setAttributeValue(value);
      props.onChange(value);
    }
  };
  function humanize(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }
  return (
    <div
      className={
        props.withOutLabel ? classes.container : classes.contractContiner
      }
    >
      {label && (
        <FormLabel className={classes.chargeBackLabel}>
          {" "}
          {humanize(props.attributeName)}{" "}
        </FormLabel>
      )}
      <div className={classes.textFieldStyle}
        style={{
          height: '36px',
          overflowY: 'auto',
          marginBottom: '-0.7rem',
          border: isFocused ? 'transparent' : '1px solid #ccc',
        }}
      >
        {props.multiple ? (
          <Autocomplete
            key={props.id ? props.id : ""}
            id="multiple-limit-tags"
            freeSolo
            multiple
            options={attributeValueArray}
            getOptionLabel={(option) => option}
            defaultValue={props.prevalue ? props.prevalue : ""}
            value={props.prevalue ? props.prevalue : attributeValue}
            // inputValue={attributeValue ? attributeValue : ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            closeOnChange
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" size="small" classes={{ root: classes.chip }} label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                style={{ borderRadius: "0px !important", height: '21px' }}

              />
            )}
            classes={{
              //root: classes.rootContainer,
              //chip: classes.chip,
              //input: classes.input,
              inputRoot: classes.borderRad0,
              // label: classes.chipLabel,
              //chipContainer: classes.chipContainer,
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              var list = [];
              const { inputValue } = params;
              const isExisting = options
                ? attributeValueArray.includes(inputValue)
                  ? true
                  : false
                : false;
              if (inputValue !== "" && !isExisting) {
                list = [inputValue, ...filtered];
              } else {
                list = filtered;
              }

              return list;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {attributeDescArray[attributeValueArray.indexOf(option)]
                  ? attributeDescArray[attributeValueArray.indexOf(option)]
                  : ""}{" "}
                - ({option}){" "}
              </li>
            )}
            onChange={(event, value) => {
              if (value && value.inputValue) {
                handleAttribute(event, value.inputValue);
              } else {
                handleAttribute(event, value);
              }
            }}
            onPaste={(event) => {
              const clipboardText = event.clipboardData
                .getData("Text")
                .split("\n");

              event.preventDefault();
              handleAttribute(clipboardText, 'pastedItem');
              if (props.onPaste) {
                props.onPaste(event);
              }
            }}
          />
        ) : (
          <Autocomplete
            key={props.id ? props.id : ""}
            id="multiple-limit-tags"
            freeSolo
            disabled={props.disabled ? props.disabled : false}
            // options={contractNumbersList}
            options={attributeValueArray}
            getOptionLabel={(option) => option}
            defaultValue={props.prevalue ? props.prevalue : ""}
            value={props.prevalue ? props.prevalue : contractNumber}
            // inputValue={props.prevalue ? props.prevalue : contractNumber}
            closeOnChange
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                style={{ borderRadius: "0px !important" }}
              />
            )}
            classes={{
              inputRoot: classes.borderRad0,
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              var list = [];
              const { inputValue } = params;
              const isExisting = options
                ? attributeValueArray.includes(inputValue)
                  ? true
                  : false
                : false;
              if (inputValue !== "" && !isExisting) {
                list = [inputValue, ...filtered];
              } else {
                list = filtered;
              }

              return list;
            }}
            onChange={(event, value) => {
              if (value && value.inputValue) {
                handleContractNumber(event, value.inputValue);
              } else {
                handleContractNumber(event, value);
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {attributeDescArray[attributeValueArray.indexOf(option)]
                  ? attributeDescArray[attributeValueArray.indexOf(option)]
                  : ""}{" "}
                - ({option}){" "}
              </li>
            )}
          />
        )}
      </div>
    </div >
  );
});
const mapDispatchToProps = (dispatch) => {
  return {
    getAttributeListData: (data) => dispatch(getAttributeListData(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    attributeData: state.attributeListData.AttributeData,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerChainComponent);
