import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,useMediaQuery,Table,TableCell,TableHead,
    TableRow,TableContainer,TableBody,TablePagination,IconButton
} from '@mui/material';
import {Edit,DeleteForever,Visibility} from '@mui/icons-material';
import { connect } from 'react-redux';
import { getPricingBatchJobSetup,addPricingBatchJobSetup } from '../../../../redux/actions';
import { TransactionDataBatchJobSetupToolbar } from '.';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    }
}));

const TransactionDataBatchJobSetupTable = props => {
    useEffect(() => {
        props.getBatchJobSetup('Sales Data');
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getBatchJobSetup(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getBatchJobSetup(1, event.target.value);
    };
    
    function viewFilter(item){
        history.push('/transaction-data-batchjob-setup/view-batchjob-setup/'+item.ID)
    }

    function editIntegration(item) {
        history.push('/transaction-data-batchjob-setup/edit-batchjob-setup/'+item.ID)
    }
    return (
        <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}

                className={classes.spinner}
            >
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h2"> Batch Job Setup</Typography>
                <TransactionDataBatchJobSetupToolbar />
            </div>
            {props.integrationSetupData && props.integrationSetupData.length > 0 ?
                <TableContainer>
                    <Table> 
                        <TableHead >
                            <TableRow >
                                <TableCell align='center'>Application Type</TableCell>
                                <TableCell align='center'>Batch Job Name</TableCell>
                                <TableCell align='center'>Batch Job Type</TableCell>
                                <TableCell align='center'>Planned Start Calendar</TableCell>
                                <TableCell align='center'>Planned Date Type</TableCell>
                                <TableCell align='center'>Planned Start Date</TableCell>
                                
                                {/* <TableCell align='center'>Planned Start Time</TableCell> */}
                                <TableCell align='center'>Frequency</TableCell>
                                <TableCell align='center'>Created By</TableCell>
                                <TableCell align='center'>Changed By</TableCell>
                                <TableCell align='center'>Created On</TableCell>
                                <TableCell align='center'>Changed On</TableCell>
                                <TableCell align='center' >Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.integrationSetupData
                            .sort((a, b) => a.ID> b.ID ? 1 : -1)
                            .map(item => {
                                return (
                                    <StyledTableRow key={item.ID}>
                                        <TableCell align='center'>{item.app_type}</TableCell>
                                        <TableCell align='center'>{item.batch_job_name}</TableCell>
                                        <TableCell align='center'>{item.batch_job_type}</TableCell>
                                        <TableCell align='center'>{item.planned_start_calendar}</TableCell>
                                        <TableCell align='center'>{item.planned_date_type}</TableCell>
                                        <TableCell align='center'>{Moment(item.planned_start_date).format('MM/DD/YYYY HH:mm:ss') }</TableCell>
                                        
                                        {/* <TableCell align='center'>{item.planned_start_time}</TableCell> */}
                                        <TableCell align='center'>{item.frequency}</TableCell>
                                        <TableCell align='center'>{item.created_by}</TableCell>
                                        <TableCell align='center'>{item.changed_by}</TableCell>
                                        <TableCell align='center'>{Moment(item.CreatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</TableCell>
                                        <TableCell align='center'>{item.CreatedAt===item.UpdatedAt?'': Moment(item.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss') }</TableCell>
                                        <TableCell align='center'>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                          <IconButton
                                              classes={{ root: classes.IconButton }}
                                              onClick={() => viewFilter(item)}
                                              size="large">
                                                <Visibility color="disabled" style={{ fontSize: 20 }} />
                                            </IconButton>
                                            <IconButton
                                                classes={{ root: classes.IconButton }}
                                                onClick={() => editIntegration(item)}
                                                size="large">
                                                <Edit color="disabled" style={{ fontSize: 20 }} />
                                            </IconButton>
                                            <IconButton
                                                classes={{ root: classes.IconButton }}
                                                onClick={() => props.deleteData('','delete',item.ID)}
                                                size="large">
                                                    <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={props.integrationSetupData && props.integrationSetupData.length ? props.integrationSetupData.length : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    />
                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                    </Typography>
            }
            
        </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading:state.customerData.loading,
        integrationSetupData: state.customerData.pricingBatchJobAll,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBatchJobSetup: (type) => dispatch(getPricingBatchJobSetup(type)),
        deleteData:(data,type,id) => dispatch(addPricingBatchJobSetup(data,type,id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDataBatchJobSetupTable);