import React, { useEffect } from 'react';
import {
    Button,
    Grid,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { EditAssignSticker, SetupData, RegionList } from './components';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { createPromotionSetup, getPromotionMaterialData, getPromotionSetupById, getCountryData } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '10px 29px 27px 29px'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
}));
const tabs = {
    GeneralData: 0,
    Stickers: 1,
    Region: 2,
};

function getSteps() {
    return ['GeneralData', 'Stickers', 'Region'];
}

const PromotionSetup = props => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [completed, setCompleted] = React.useState(new Set());
    const [promoData, setPromoData] = React.useState([])
    const [headerData, setHeaderData] = React.useState([])
    const [promoStickerData, setPromoStickerData] = React.useState([])
    const [promoMaterials, setPromoMaterials] = React.useState([]);
    const [editID, setEditID] = React.useState(0);
    const [valid, setValid] = React.useState(false);
    const skipped = new Set()
    const [promotion, setPromotion] = React.useState([]);
    const [promoFilter, setPromoFilter] = React.useState([]);
    useEffect(() => {

        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        props.getMaterialData()
        if (appId)
            props.getPromotionSetup(appId);

    }, []);
    useEffect(() => {
        if (props.promoData && Object.entries(props.promoData).length > 0) {
            props.getCountryData(props.promoData.country)
            setPromoFilter(props.promoData.promotion_budget_eligibility)
            setPromoData(props.promoData)
            setPromoStickerData(props.promoData.promotion_budget_benefits)
            setPromotion(props.promoData.promotion)
            setValid(false)
        }
    }, [props.promoData])
    useEffect(() => {
        if (props.materialData && props.materialData.length > 0) {
            setPromoMaterials(props.materialData)
        }
    }, [props.materialData])


    const totalSteps = () => {
        return getSteps().length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/promotion-setup");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    function handleGeneralDataChange(values, model) {
        setHeaderData(values)
    };
    function handleAddData() {
        var promoArray = []
        var promoname = []
        setValid(false)
        promoStickerData.map((item, index) => {

            if (!promoname.includes(item.promotion_name)) {
                promoname.push(item.promotion_name)
                promoArray.push({ 'promotion_name': item.promotion_name, 'promotion_description': item.promotion_description })
            }
            if (item.promotion_name == '') {
                setValid(true)
            }
        })
        setPromotion(promoArray)
        handleNext()

    }
    function handlePromoStickers(values) {
        setPromoStickerData(values)
    };

    function handleSubmit() {
        var eligibility = []
        if (promoFilter && promoFilter.length > 0) {
            promoFilter.map((itemX, i) => {
                var array = []
                promoFilter[i] && promoFilter[i]['promotion_state'] && promoFilter[i]['promotion_state'].map((item, idx) => {
                    if (item.promotion_name != '' && item.promotion_description != '')
                        array.push(item)
                })
                if (array.length > 0) {
                    var keyData = {
                        'material_group1': itemX.material_group1,
                        'material_group2': itemX.material_group2,
                        'material_group3': itemX.material_group3,
                        'country': itemX.country,
                        'promotion_state': array
                    }
                    eligibility.push(keyData)

                }
            })

        }


        var data = {
            "promotion": promotion,
            "promotion_budget_benefits": promoStickerData,
            "promotion_budget_eligibility": eligibility
        }
        data = { ...data, ...headerData }

        props.onSubmit(data, editID)
    }
    function handleRegionData(values) {
        setPromoFilter(values)
    };
    const skippedSteps = () => {
        return skipped.size;
    };
    const completedSteps = () => {
        return completed.size;
    };
    function getStepContent(step) {
        const allSteps = [
            <SetupData
                onChange={handleGeneralDataChange}
                handleNext={handleNext}
                editMode={true}
            />,
            <EditAssignSticker
                onChange={handlePromoStickers}
                promoData={headerData}
                editMode={true}
                country={promoData.country}
            />,
            <RegionList
                onChange={handleRegionData}
                promoData={promotion}
                regionData={promoFilter}
            />,


        ];

        return (
            <>

                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }



    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div>
                <div className={classes.root}>
                    <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                    <Grid container >
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {activeStep !== 0 &&
                                <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                    Previous Step
                                </Button>
                            }
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >

                            {activeStep == tabs.Region &&
                                <Button variant="contained" color='primary' className={classes.button} onClick={handleSubmit}>
                                    Save
                                </Button>
                            }
                            {activeStep != tabs.GeneralData &&
                                <Button variant="outlined" color='primary' className={classes.button} onClick={() => history.push('/promotion-setup')}>
                                    Cancel
                                </Button>
                            }
                            {activeStep == tabs.Stickers &&
                                <Button variant="contained" color='primary' className={classes.button} onClick={handleAddData}
                                    disabled={promoStickerData && promoStickerData.length > 0 ? false : true
                                    }>
                                    Next Step
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        materialData: state.promotionData.promotionMaterialData,
        promoData: state.promotionData.promotionSetupSingleData,
        countryData: state.promotionData.countryData,
        loading: state.promotionData.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(createPromotionSetup(data, id, 'edit')),
        getMaterialData: () => dispatch(getPromotionMaterialData()),
        getPromotionSetup: (id) => dispatch(getPromotionSetupById(id)),
        getCountryData: (country) => dispatch(getCountryData(country)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSetup);