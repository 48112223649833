import React, { useEffect } from "react";
import Moment from 'moment';
import { timeToMarket } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Chart from "react-apexcharts";

const TimToMarket = (props) => {

    const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: 'white',
            padding: '20px 30px 20px 30px',
            borderRadius: 10
        },
        rootDiv: {
            backgroundColor: theme.palette.white,
            borderRadius: 10,
            margin: '16px 0px 16px 0px',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 32px 0px 20px',
            alignItems: 'center'
        },
        rangeContainer: {
            padding: '20px 0px 20px 30px',
            display: 'flex',
            flexDiretion: 'row',
            justifyContent: 'space-between',
            width: '100%'
        },
        graphContainer: {
            width: '80%',
            height: '100%',
            marginTop: 10,
            display: 'flex',
            alignSelf: 'center'
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        select: {
            width: '100%',
            color: '#1675e0',
            backgroundColor: 'white'
        },
        selectedItem: {
            borderRadius: 5
        },
        chartContainer: {
            marginTop: 30,
            height: 200
        }
    }));

    const classes = useStyles();
    useEffect(() => {
        var formData = {
            "start_date": Moment().subtract(2, 'months').startOf('month').format('MM/DD/YYYY'),
            "end_date": Moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
            "aggregate_field": 'material_group'
        }
        props.onSubmit(formData);
    }, [])
    const [chartOptionsPP, setChartOptionsPP] = React.useState({});
    const [chartSeriesPP, setChartSeriesPP] = React.useState([]);
    const [filterItem, setFilterItem] = React.useState('');
    useEffect(() => {
        if (props.timeToMarket && props.timeToMarket.length > 0 && props.timeToMarket[0]) {
            var forDeletion = ['delivery_doc_number', 'fastest', 'slowest', 'average']
            var keys = Object.keys(props.timeToMarket[0]);
            var arr = keys.filter(item => !forDeletion.includes(item));
            setFilterItem(arr);
        }
    }, [props.timeToMarket])
    useEffect(() => {
        var falstestArr = [];
        var slowestArr = [];
        var averageArr = [];
        var filteringArr = [];
        if (props.timeToMarket && Object.keys(props.timeToMarket).length > 0) {
            props.timeToMarket.map(item => {
                falstestArr.push(item['fastest'] ? parseInt(item['fastest']) : 0);
                slowestArr.push(item['slowest'] ? parseInt(item['slowest']) : 0);
                averageArr.push(item['average'] ? parseInt(item['average']) : 0);
                filteringArr.push(item[filterItem]);
            })
            setChartSeriesPP([{
                name: 'Fastest',
                data: falstestArr
            }, {
                name: 'Slowest',
                data: slowestArr
            },
            {
                name: 'Average',
                data: averageArr
            }])
            setChartOptionsPP(
                {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: filteringArr,
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    fill: {
                        opacity: 1
                    }
                }
            )
        }

    }, [filterItem])

    return (
        <div className={classes.root}>
            <Typography variant="h2" > {'Time To Market'} </Typography>
            {props.timeToMarket && props.timeToMarket.length > 20 ?
                <Chart options={chartOptionsPP} type="bar" series={chartSeriesPP} height={265} />
                :
                <div style={{
                    height: 277, display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                </div>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        timeToMarket: state.initialData.timeToMarket
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(timeToMarket(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimToMarket);