import React, { useEffect } from "react";
import {
  Grid, Button, Typography, Breadcrumbs,
  Card, FormLabel,
  MenuItem, Select, OutlinedInput, Input, ListItemText, Checkbox
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { createExternalConfig, allowedApps, getAnalysisLevel, getAnalysisLevelValues, getDefaultValues, getDefaultValuesAllData, loadValuesBasedOnAppTypeAndField } from "../../../../redux/actions";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  container: {
    padding: 15,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  selectRoot: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  select: {
    width: "100%",
  },
  selectedItem: {
    color: theme.palette.text.grey,
    marginTop: 10,
    width: "100%",
  },
  inputTwoLine: {
    marginTop: 3,
    width: "100%",
    paddingLeft: 10,
    height: 35,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 28,
  },
  formDiv: {
    fontSize: 13,
  },
  dropdownContainer: {
    maxHeight: 50,
    overflowY: "scroll",
  },
  formlabel: {
    marginBottom: 8,
    fontSize: 13,
  },
  dropdownbottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: '1px solid #ddd',
  },
  btn: {
    marginRight: 25,
    padding: '10px,15px'
  }
}));

const AddPricingExternalConfig = (props) => {
  const classes = useStyles();
  const [allowedApps, setAllowedApps] = React.useState([]);
  const [applicationType, setApplicationType] = React.useState("");
  const [analysisLevel, setAnalysisLevel] = React.useState("");
  const [analysisLevelArray, setAnalysisLevelArray] = React.useState([]);
  const [driverName, setDriverName] = React.useState("");
  const [driverNameArray, setDriverNameArray] = React.useState([])
  const [allocationType, setAllocationType] = React.useState("");
  const [sourceDataType, setSourceDataType] = React.useState("");
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
  const [dateType, setDateType] = React.useState("");
  const [sourceDataAttributes, setSourceDataAttributes] = React.useState([]);
  const [externalAttributeNames, setExternalAttributeNames] = React.useState([]);
  const [allocationKeyFigure, setAllocationKeyFigure] = React.useState("");
  const [allocationKeyFigureArray, setAllocationKeyFigureArray] = React.useState([]);
  const [attributeDataArray, setAttributeDataArray] = React.useState([]);
  const [open, setOpen] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [open2, setOpen2] = React.useState('');
  const [selected2, setSelected2] = React.useState([]);
  const allocationTypeArray = ['Roll Up', 'Roll Down'];
  const dateTypeArray = ['Billing Date', 'Pricing Date'];
  const isAllSelected =
    attributeDataArray && attributeDataArray.length > 0 && selected.length === attributeDataArray.length;
  const isAllSelected2 =
    attributeDataArray && attributeDataArray.length > 0 && selected2.length === attributeDataArray.length;
  useEffect(() => {
    props.allowedApps();
    props.getAnalysisLevel(1, 0);
    props.getFieldValues('driver_name');
  }, [])
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData])
  useEffect(() => {
    var newArray = []
    if (props.analysisLevelArrayData && props.analysisLevelArrayData.records) {
      props.analysisLevelArrayData.records.map(e => {
        newArray.push(e.analysis_level_id)
      })
    }
    setAnalysisLevelArray(newArray)
  }, [props.analysisLevelArrayData])
  useEffect(() => {
    if (props.dropdownAllocationKeyFigureData && props.dropdownAllocationKeyFigureData.records) {
      if (props.dropdownAllocationKeyFigureData && props.dropdownAllocationKeyFigureData.records[0] && props.dropdownAllocationKeyFigureData.records[0].drop_down_value_keys)
        if (props.dropdownAttributeData.records[0].field_id == 'key_figure_name') {
          setAllocationKeyFigureArray(props.dropdownAttributeData.records[0].drop_down_values);
        }
    }
  }, [props.dropdownAllocationKeyFigureData]);
  useEffect(() => {
    if (props.dropdownAttributeData && props.dropdownAttributeData.records) {
      if (props.dropdownAttributeData && props.dropdownAttributeData.records[0] && props.dropdownAttributeData.records[0].drop_down_value_keys)
        if (props.dropdownAttributeData.records[0].field_id == 'attribute_name') { setAttributeDataArray(props.dropdownAllocationKeyFigureData.records[0].drop_down_values); }
    }
  }, [props.dropdownAttributeData]);
  useEffect(() => {
    var newArray = []
    if (props.priceTypeNameList && props.priceTypeNameList.records)
      props.priceTypeNameList.records.map(item => {
        newArray.push(item.driver_name)
      })
    setDriverNameArray(newArray)
  }, [props.priceTypeNameList])
  useEffect(() => {
    if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
      setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
    }
  }, [props.sourceDataTypeValue])
  function handleDriverName(value) {
    setDriverName(value)
  }
  function handleAllocationType(value) {
    setAllocationType(value);
  }
  function handleDateType(value) {
    setDateType(value);
    props.getDefaultValuesAllData('key_figure_name', sourceDataType, applicationType);
  }
  const handleApplicationtype = (e) => {
    setApplicationType(e.target.value);
    props.loadSourceDataType(e.target.value, 'source_data_type');

  }
  function handleAnlysisLevel(newValue) {
    setAnalysisLevel(newValue)
  }
  function handleAllocationKeyFigure(value) {
    setAllocationKeyFigure(value)
  }
  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
    props.getDefaultValuesAllData('attribute_name', e.target.value, applicationType);
  }
  const handleSelectValues = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    if (value === "all") {
      setSelected(selected.length === attributeDataArray.length ? [] : attributeDataArray);
    }
    else
      setSelected(newSelected)
  }
  function onAdd() {
    setSourceDataAttributes(selected)
    setOpen(false)
    setSelected([])
  }
  function onCancel() {
    setOpen(false)
    setSelected([])
  }
  function handleOpen() {
    setSelected(sourceDataAttributes)
    setOpen(true)
  }
  const handleSelectValues2 = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected2.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected2, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected2.slice(1));
    } else if (selectedIndex === selected2.length - 1) {
      newSelected = newSelected.concat(selected2.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected2.slice(0, selectedIndex),
        selected2.slice(selectedIndex + 1),
      );
    }
    if (value === "all") {
      setSelected2(selected2.length === attributeDataArray.length ? [] : attributeDataArray);
    }
    else
      setSelected2(newSelected)
  }
  function onAdd2() {
    setExternalAttributeNames(selected2)
    setOpen2(false)
    setSelected2([])
  }
  function onCancel2() {
    setOpen2(false)
    setSelected2([])
  }
  function handleOpen2() {
    setSelected2(externalAttributeNames)
    setOpen2(true)
  }
  function handleOnSubmit() {
    var data = {
      "application_type": applicationType,
      "analysis_level": analysisLevel,
      "driver_name": driverName,
      "allocation_type": allocationType,
      "source_data_type": sourceDataType,
      "date_type": dateType,
      "source_data_attributes": sourceDataAttributes,
      "external_attribute_names": externalAttributeNames,
      "allocation_key_figure": allocationKeyFigure
    };
    props.onSubmit(data);
  }
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h4"
          classes={{
            root: classes.link,
          }}
          to="/external-config"
        >
          External Config
        </Link>
        <Typography color="textPrimary" variant="h4">
          Add External Config
        </Typography>
      </Breadcrumbs>

      <div className={classes.bodyContainer}>
        <Card>
          <form autoComplete="off" noValidate>
            <div className={classes.container}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}
                >
                  <FormLabel className={classes.formLabel} required>
                    Application Type
                  </FormLabel>
                  <Select
                    value={applicationType}
                    onChange={handleApplicationtype}
                    displayEmpty
                    style={{ textTransform: 'capitalize' }}
                    className={clsx({
                      [classes.select]: true
                    })}
                    classes={{
                      selectMenu: classes.selectedItem
                    }}
                  >
                    {allowedApps.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                          {item}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel variant="h5" className={classes.formlabel} required>
                      Analysis Level
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={analysisLevel}
                        onChange={(e) => handleAnlysisLevel(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        {analysisLevelArray &&
                          analysisLevelArray.map((item) => {
                            return (
                              <MenuItem
                                value={item}
                                key={item}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12} required>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Driver Name
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={driverName}
                        onChange={(e) => handleDriverName(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        {driverNameArray &&
                          driverNameArray.map((item) => {
                            return (
                              <MenuItem
                                value={item}
                                key={item}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Allocation Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={allocationType}
                        onChange={(e) => handleAllocationType(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        {allocationTypeArray &&
                          allocationTypeArray.map((item) => {
                            return (
                              <MenuItem
                                value={item}
                                key={item}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  md={3}
                  xs={12}
                  style={{ display: allocationType === 'Roll Down' ? 'block' : 'none' }}
                >
                  <div className={classes.selectRoot}>
                    <FormLabel variant="h5" className={classes.formDiv}>
                      Source Data Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={sourceDataType}
                        onChange={handleSourceDataType}
                        displayEmpty
                        classes={{
                          selectMenu: classes.selectedItem
                        }}
                        className={clsx({
                          [classes.select]: true
                        })}
                      >
                        {sourceDataTypeDataFromAPI && sourceDataTypeDataFromAPI
                          .map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}
                    style={{ display: allocationType === 'Roll Down' ? 'block' : 'none' }}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                    >
                      Date Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={dateType}
                        onChange={(e) => handleDateType(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        {dateTypeArray &&
                          dateTypeArray.map((item) => {
                            return (
                              <MenuItem
                                value={item}
                                key={item}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}
                  style={{ display: allocationType === 'Roll Down' ? 'block' : 'none' }}>
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formlabel}>
                      Source Data Attributes
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        value={sourceDataAttributes}
                        style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                        input={<OutlinedInput />}
                        onOpen={() => handleOpen()}
                        onClose={() => setOpen(false)}
                        open={open}
                        renderValue={(appType) => {
                          var priceType = []
                          appType
                            .map(item => {
                              priceType.push(item.replace(/_/g, ' '))

                            })
                          return priceType.join(", ")
                        }}
                        className={classes.multiSelect}

                      >
                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                          <Checkbox
                            color='primary'
                            checked={isAllSelected} />
                          <ListItemText primary='Select All' />
                        </MenuItem>
                        {attributeDataArray && attributeDataArray
                          .map(item => {
                            return (
                              <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                <Checkbox
                                  color='primary'
                                  checked={selected.indexOf(item) > -1} />
                                <ListItemText primary={item.replace(/_/g, ' ')} />
                              </MenuItem>
                            );
                          })}
                        <div className={classes.dropdownbottom}>
                          <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                            Cancel
                          </Button>

                          <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                            Apply
                          </Button>
                        </div>
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}
                  style={{ display: allocationType === 'Roll Down' ? 'block' : 'none' }}>
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formlabel}>
                      External Attributes Names
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        value={externalAttributeNames}
                        style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                        input={<OutlinedInput />}
                        onOpen={() => handleOpen2()}
                        onClose={() => setOpen2(false)}
                        open={open2}
                        renderValue={(appType) => {
                          var priceType = []
                          appType
                            .map(item => {
                              priceType.push(item.replace(/_/g, ' '))

                            })
                          return priceType.join(", ")
                        }}
                        className={classes.multiSelect}

                      >
                        <MenuItem value='all' onClick={(event) => handleSelectValues2(event, 'all')} style={{ textTransform: 'capitalize' }}>
                          <Checkbox
                            color='primary'
                            checked={isAllSelected2} />
                          <ListItemText primary='Select All' />
                        </MenuItem>
                        {attributeDataArray && attributeDataArray
                          .map(item => {
                            return (
                              <MenuItem onClick={(event) => handleSelectValues2(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                <Checkbox
                                  color='primary'
                                  checked={selected2.indexOf(item) > -1} />
                                <ListItemText primary={item.replace(/_/g, ' ')} />
                              </MenuItem>
                            );
                          })}
                        <div className={classes.dropdownbottom}>
                          <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel2}>
                            Cancel
                          </Button>

                          <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd2} >
                            Apply
                          </Button>
                        </div>
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}
                  style={{ display: allocationType === 'Roll Down' ? 'block' : 'none' }}
                >
                  <div className={classes.selectRoot}>
                    <FormLabel className={classes.formlabel}>
                      Allocation Key Figure
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={allocationKeyFigure}
                        onChange={(e) => handleAllocationKeyFigure(e.target.value)}
                        displayEmpty
                        MenuProps={{ ...MenuProps, autoFocus: true }}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                        style={{ textTransform: "capitalize" }}
                      >
                        {allocationKeyFigureArray &&
                          allocationKeyFigureArray.map((item) => {
                            return (
                              <MenuItem
                                value={item}
                                key={item}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </Card >
        <div className={classes.buttonRoot}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOnSubmit}
            disabled={applicationType && analysisLevel && driverName && allocationType ? false : true}
          >
            {props.loadingAPI ? (
              <BeatLoader color={"rgb(54, 215, 183)"} loading={props.loadingAPI} size={6} />
            ) : ("Submit"
            )}
          </Button>
        </div>
      </div >
    </div >
  );
};
const mapStateToProps = (state) => {
  return {
    loadingAPI: state.profitOptimizationData.loading,
    allowedAppsData: state.initialData.allowedApps,
    analysisLevelArrayData: state.profitOptimizationData.priceListMasterData,
    priceTypeNameList: state.profitOptimizationData.priceTypeNameList,
    sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    dropdownAllocationKeyFigureData: state.addMultipleConfigurationData.formFieldsAllData,
    dropdownAttributeData: state.addMultipleConfigurationData.formFieldsAllData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(createExternalConfig(data)),
    allowedApps: () => dispatch(allowedApps()),
    getAnalysisLevel: (pagination, limit) => dispatch(getAnalysisLevel(pagination, limit)),
    getFieldValues: (key) => dispatch(getAnalysisLevelValues(key)),
    getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
    loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPricingExternalConfig);