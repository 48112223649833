import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Card, IconButton, Button, Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { updateIncomingClaimData, getClaimConfiguration, getClaimError, reConcile } from '../../../../redux/actions';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import 'react-edit-text/dist/index.css';
import PopupContainer from '../PopupContainer';

const useStyles = makeStyles(theme => ({
    rootMain: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
    rootIcon: {
        padding: 0
    }
}));

const GeneralData = props => {
    const classes = useStyles();
    const { className } = props;
    const [dataRows, setDataRows] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowsAPI, setSelectedRowsAPI] = React.useState([]);
    const tableRef = useRef()
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [onEdit, setOnEdit] = React.useState(false);
    const [onEdit2, setOnEdit2] = React.useState(false);
    const theme = useTheme();
    const [currentID, setCurrentID] = React.useState('');
    const [claimAdjustmentCodes, setClaimAdjustmentCodes] = React.useState([]);
    const [rejestionReasionArray, setRejectionReasonArray] = React.useState([])
    useEffect(() => {
        props.getClaimData();
        props.getAllUom(1, 0);
    }, []);
    useEffect(() => {
        var tempRows = [];
        if (props.incomingClaimDetailData && props.incomingClaimDetailData.length > 0)
            props.incomingClaimDetailData
                .filter(e => props.claimStatus == 'Rejected' ? e.claim_status == 'Rejected' : (e.claim_status == 'Review' || e.claim_status == ''))
                .sort((a, b) => a.unique_identifier > b.unique_identifier ? 1 : -1)
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        unique_identifier: e.unique_identifier,
                        claim_amount: e.claim_amount,
                        calculated_amount: e.calculated_amount,
                        approved_amount: e.approved_amount,
                        end_customer_id: e.end_customer_id,
                        material_id_number: e.material_id_number,
                        contract_number: e.contract_number,
                        claim_status: e.claim_status,
                        claim_action: e.claim_action,
                        claim_error: e.claim_error,
                        material_number: e.material_number,
                        pricing_date: e.pricing_date,
                        channel_partner_end_customer_id: e.channel_partner_end_customer_id,
                        channel_partner_end_customer_name: e.channel_partner_end_customer_name,
                        street_address: e.street_address,
                        city: e.city,
                        region: e.region,
                        postal_code: e.postal_code,
                        country: e.country,
                        channel_parter_material: e.channel_parter_materal,
                        channel_partner_contract_id: e.channel_partner_contract_id,
                        debit_credit_indicator: e.debit_credit_indicator,
                        channel_partner_contract_price: e.channel_partner_contract_price,
                        billed_uom: e.billed_uom,
                        quantity: e.quantity,
                        acquistion_price: e.acquistion_price,
                        contract_price: e.contract_price,
                        channel_partner_acquistion_price: e.channel_partner_acquistion_price,
                        material_description: e.material_description,
                        rejection_reason: e.rejection_reason,
                        adjustment_code: e.adjustment_code,
                        claim_status: e.claim_status,
                    });
                })
        setDataRows(tempRows);
    }, [props.incomingClaimDetailData]);
    useEffect(() => {

        if (props.claimData && props.claimData.length > 0) {
            setClaimAdjustmentCodes(props.claimData[0].claim_adjustment_codes)
        }

    }, [props.claimData])
    useEffect(() => {
        var newArray = []
        if (props.uomAll && props.uomAll[0] && props.uomAll[0].rejection_reason) {
            // setRejectionReasonArray(props.uomAll)
            props.uomAll[0].rejection_reason.map(item => {
                newArray.push(item.rejection_reason_code)
            })
            setRejectionReasonArray(newArray)
        }
    }, [props.uomAll])
    const [currentViewID, setCurrentViewID] = React.useState(0);
    const handlePopupClick = (rowData) => {
        setDialogOpen(true);
        setCurrentViewID(rowData.unique_identifier);
    }
    const handleSelectcell = (e, id) => {
        setOnEdit(!onEdit)
        setCurrentID(id)

    }
    const handleSelectcell2 = (e, id) => {
        setOnEdit2(!onEdit2)
        setCurrentID(id)
    }
    const handleAdjustmentCode = (event, rowData) => {
        var currentObject = {}
        props.incomingClaimDetailData.map((item, index) => {
            if (item.ID === rowData.id) {
                currentObject = item
            }
        })
        currentObject['adjustment_code'] = event.target.value
        props.updateIncomingClaimData(currentObject)
        setOnEdit(false)
        setCurrentID('')
    }
    const handleRejectionReason = (event, rowData) => {
        var currentObject = {}
        props.incomingClaimDetailData.map((item, index) => {
            if (item.ID === rowData.id) {
                currentObject = item
            }
        })
        currentObject['rejection_reason'] = event.target.value
        props.updateIncomingClaimData(currentObject)
        setOnEdit2(false)
        setCurrentID('')
    }


    const columns = [
        {
            field: 'id',
            title: 'Reconcile',
            editable: 'never',
            type: 'numeric',
            render: rowData =>
                <IconButton
                    onClick={() => handleReconcileSingle(rowData)}
                    color='primary'
                    style={{ padding: 0 }}
                    classes={{
                        root: classes.rootIcon,

                    }}
                    size="large">
                    <RotateLeftIcon />
                </IconButton>

        },
        {
            field: 'unique_identifier',
            title: 'Unique Identifier',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.unique_identifier ? <div style={{
                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
            }} onClick={() => handlePopupClick(rowData)}>{rowData.unique_identifier}</div> : <div style={{ color: 'white' }}>-</div>
        },

        {
            field: 'end_customer_id',
            title: 'End Customer ID',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.end_customer_id ? rowData.end_customer_id : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'channel_partner_end_customer_name',
            title: 'End Customer Name',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.channel_partner_end_customer_name ? rowData.channel_partner_end_customer_name : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.material_number ? rowData.material_number : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'material_description',
            title: '  Material Description',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.material_description ? rowData.material_description : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'contract_number',
            title: '  Contract Number',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.contract_number ? rowData.contract_number : <div style={{ color: 'white' }}>-</div>
        },

        {
            field: 'claim_amount',
            title: 'Claim Amount',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.claim_amount ? rowData.claim_amount.toFixed(2) : ''

        },
        {
            field: 'calculated_amount',
            title: 'Calculated Amount',
            type: 'numeric',
            editable: 'never',
            render: rowData => rowData.calculated_amount ? rowData.calculated_amount.toFixed(2) : ''
        },
        {
            field: 'approved_amount',
            title: 'Approved Amount',
            type: 'numeric',
            editable: 'onUpdate',
            render: rowData => rowData.approved_amount ? rowData.approved_amount : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'claim_error',
            title: 'Claim Error',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.claim_error}</div>
        },
        {
            field: 'rejection_reason',
            title: 'Rejection Reason',
            type: 'string',
            editable: 'never',
            // render: rowData => rowData.rejection_reason ? rowData.rejection_reason : <div style={{ color: 'white' }}>-</div>
            render: rowData => <div style={{ whiteSpace: 'nowrap' }} onClick={(e) => handleSelectcell2(e, rowData.id)}>
                <Select
                    style={{ display: onEdit2 && currentID == rowData.id ? 'inline-block' : 'none' }}
                    value={rowData.rejection_reason}
                    onChange={(e) => handleRejectionReason(e, rowData)}
                    displayEmpty
                    className={clsx({
                        [classes.select]: true
                    })}
                    classes={{
                        selectMenu: classes.selectedItem
                    }}
                >
                    {rejestionReasionArray.map((item) => {
                        return (
                            <MenuItem value={item} key={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>

                <div style={{ display: onEdit2 ? currentID != rowData.id ? 'inline-block' : 'none' : 'inline-block' }}>
                    {rowData.rejection_reason ?
                        <label style={{ textDecoration: 'underline dotted', cursor: 'pointer' }}>{rowData.rejection_reason}</label>
                        :
                        <div style={{ cursor: 'pointer' }}>-</div>
                    }
                </div>

            </div>
        },
        {
            field: 'adjustment_code',
            title: 'Adjustment Code',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }} onClick={(e) => handleSelectcell(e, rowData.id)}>
                <Select
                    style={{ display: onEdit && currentID == rowData.id ? 'inline-block' : 'none' }}
                    value={rowData.adjustment_code}
                    onChange={(e) => handleAdjustmentCode(e, rowData)}
                    displayEmpty
                    className={clsx({
                        [classes.select]: true
                    })}
                    classes={{
                        selectMenu: classes.selectedItem
                    }}
                >
                    {claimAdjustmentCodes.map((item) => {
                        return (
                            <MenuItem value={item} key={item}>
                                {item}
                            </MenuItem>
                        )
                    })}
                </Select>

                <div style={{ display: onEdit ? currentID != rowData.id ? 'inline-block' : 'none' : 'inline-block' }}>
                    {rowData.adjustment_code ?
                        <label style={{ textDecoration: 'underline dotted', cursor: 'pointer' }}>{rowData.adjustment_code}</label>
                        :
                        <div style={{ cursor: 'pointer' }}>-</div>
                    }
                </div>

            </div>
        },
        {
            field: 'claim_status',
            title: 'Claim Status',
            type: 'string',
            editable: 'never',
            render: rowData => rowData.claim_status ? rowData.claim_status : <div style={{ color: 'white' }}>-</div>,
        },


    ];

    const handleReconcile = () => {
        var temp = [];
        selectedRows.map((item1) => {
            props.incomingClaimDetailData.map((item2) => {
                if (item2.ID === item1.id) {
                    temp.push(item2)
                }

            })
        })
        setSelectedRowsAPI(temp)

        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        props.reConcile(temp, appId);
        setSelectedRowsAPI([]);
        setSelectedRows([]);
        tableRef.current.onAllSelected(false)
    }

    const handleReconcileSingle = (e) => {
        var temp = [];
        [e].map((item1) => {
            props.incomingClaimDetailData.map((item2) => {
                if (item2.ID === item1.id) {
                    temp.push(item2)
                }

            })
        })
        setSelectedRowsAPI(temp)
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        props.reConcile(temp, appId);
        setSelectedRowsAPI([]);
        setSelectedRows([]);
        tableRef.current.onAllSelected(false)
    }

    const handleEditSingleCell = (item) => {

        var temp = {};
        props.incomingClaimDetailData.map((item2) => {
            if (item2.ID === item.ID) {
                temp = item2
            }

        })
        props.updateIncomingClaimData(temp);
    }



    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider className={clsx(classes.rootMain, className)}>
                <PopupContainer open={dialogOpen} onClose={(value) => setDialogOpen(value)} incomingClaimID={currentViewID} />


                <div className={clsx(classes.root, className)}>
                    <Button size="small" onClick={handleReconcile} autoFocus color="primary"
                        variant="contained" disableRipple
                        startIcon={<RotateLeftIcon />}>
                        Reprocess
                    </Button>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div>
                                <MaterialTable
                                    tableRef={tableRef}
                                    title={' '}
                                    editable={true}
                                    icons={tableIcons}
                                    columns={columns}
                                    data={dataRows}
                                    style={{ marginTop: -60 }}
                                    options={{
                                        filtering: true,
                                        selection: true,
                                        headerStyle: theme.mixins.MaterialHeader,
                                        cellStyle: theme.mixins.MaterialCell,
                                        searchFieldStyle: {
                                            fontSize: 13
                                        },
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                                    }}
                                    onSelectionChange={(rows) => setSelectedRows(rows)}
                                    cellEditable={{
                                        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                            return new Promise((resolve, reject) => {
                                                var currentObject = props.incomingClaimDetailData.find(item => item.ID === rowData.id);
                                                currentObject[columnDef.field] = newValue;
                                                handleEditSingleCell(currentObject)
                                                setTimeout(resolve, 1000);
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </form>
                    </Card>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        updateIncomingClaimData: (formData) => dispatch(updateIncomingClaimData(formData)),
        reConcile: (data, claimNumber) => dispatch(reConcile(data, claimNumber)),
        getClaimData: () => dispatch(getClaimConfiguration()),
        getAllUom: (pagination, limit) => dispatch(getClaimError(pagination, limit)),
    }
}

const mapStateToProps = state => {
    return {
        incomingClaimDetailData: state.initialData.incomingClaimDetailData,
        claimData: state.calculationAnalysisData.claimData,
        uomAll: state.calculationAnalysisData.claimError,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);