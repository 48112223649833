import React, { useState, useEffect } from 'react';
import {
    Card,
    Button,
    Divider,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DialogTitle from '@mui/material/DialogTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { connect } from 'react-redux';
import { uploadAttachment } from '../../../../../../redux/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import  secureLocalStorage  from  "react-secure-storage";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    notesDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 10
    },
    cardRoot: {
        backgroundColor: theme.palette.white,
        boxShadow: '1px 2px 4px #9E9E9E',
        borderRadius: 6,
        margin: '10px 20px 10px 20px',
        padding: 15,
        overflow: 'hidden'
    },
    commentHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'grey',
        fontSize: 13
    },
    dividerColor: {
        background: theme.palette.border.main,
        margin: '6px 0px 10px 0px'
    },
    dialogTitleRoot: {
        padding: '9px 24px'
    },
    dialogController: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));

const InternalNotes = (props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const [notesArray, setNotesArray] = React.useState([]);
    const [fileName, setFileName] = useState('');
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentFileUrl, setCurrentFileUrl] = useState('');
    const [currentNote, setCurrentNote] = useState('');
    const [note, setNote] = React.useState('');
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setNote('');
        setCurrentFileName('');
        setCurrentFileUrl('');
        setOpen(false);
    };
    const handleSave = (file) => {
        setNote('');
        setCurrentNote(note);
        setCurrentFileName(fileName);
        setOpen(false);
        var data = {
            text: note,
            attachments: file ? [currentFileUrl] : [],
            currentFileName: fileName
        };
        setNotesArray([...notesArray, data]);
        props.onChange([...notesArray, data]);
        setCurrentFileName('');
        setFileName('')
    };
    const handleFileUpload = (event) => {
        setFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        // setCurrentFile(data);
        setCurrentFileName(event.target.files[0].name)
        props.uploadAttachment(data);
    };
    const handleRemoveCard = (index) => {
        props.onChange([
            ...notesArray.slice(0, index),
            ...notesArray.slice(index + 1, notesArray.length)
        ]);
        setNotesArray([
            ...notesArray.slice(0, index),
            ...notesArray.slice(index + 1, notesArray.length)
        ]);


    }
    useEffect(() => {
        if (props.uploadFileUrl)
            setCurrentFileUrl(props.uploadFileUrl)
    }, [props.uploadFileUrl]);
    const handleNote = (e) => {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
        if (format.test(e.target.value)) {

        } else {
            setNote(e.target.value)
        }
    }
    useEffect(()=>{
        if(props.notes&&props.editMode){
              setNotesArray(props.notes)  
        }
    },[props.notes])
    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    {!props.viewMode&&
                    <div className={classes.notesDiv}>
                        <Button
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            Add Note
                        </Button>
                    </div>
                  }
                    {notesArray.map((item, index) =>

                        <div className={classes.cardRoot}>
                            <div className={classes.commentHeader}>
                                <div>{secureLocalStorage.getItem('dYtz').username}</div>
                            </div>
                            <div className={classes.commentHeader}>
                            </div>
                            <Divider
                                classes={{ root: classes.dividerColor }}
                                sx={{
                                    opacity: "0.6"
                                }} />
                            <div>
                                {item.text}
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    {item.currentFileName &&
                                        <div>
                                            {
                                                (item.currentFileName.substring(item.currentFileName).split('.').pop() === 'pdf' || item.currentFileName.substring(item.currentFileName).split('.').pop() === 'PDF') ?
                                                    <div>
                                                        <img src={require('../../../../../../library/images/pdf.png')} height={25} style={{ paddingRight: 10 }} />
                                                       {item.currentFileName}
                                                    </div>
                                                    :
                                                    <div>
                                                        <img src={require('../../../../../../library/images/excel.png')} height={25} style={{ paddingRight: 10 }} />
                                                        {item.currentFileName}
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div>
                                    <IconButton
                                        aria-label="delete"
                                        className={classes.margin}
                                        onClick={() => handleRemoveCard(index)}
                                        size="large">
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </div>

                        </div>
                    )}


                </form>
            </Card>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
                maxWidth={'lg'}>
                {/* <DialogTitle id="form-dialog-title" onClose={handleClose} classes={{ root: classes.dialogTitleRoot }}>
                    <div style={{ fontSize: 17 }}> Add notes and attachments</div>
                </DialogTitle> */}
                <DialogContent dividers>
                    {/* <TextareaAutosize aria-label="minimum height" rowsMin={5} placeholder="Enter your notes here" style={{ width: '100%' }} onChange={(event) => setNote(event.target.value)} /> */}
                    <TextField
                        value={note}
                        label="Enter your notes here"
                        multiLine={true}
                        maxRows={4}
                        onChange={handleNote}
                        variant="filled"
                    />
                    <div className={classes.dialogController}>
                        <div>
                            <Button
                                component="label"
                                endIcon={<AttachFileIcon />}
                            >
                                Add Attachment
                                <input
                                    type="file"
                                    hidden
                                    accept=".xls,.xlsx,.pdf,"
                                    onChange={handleFileUpload}
                                />
                            </Button>
                            <div style={{ fontSize: 12, color: 'green' }}>{currentFileName}</div>
                        </div>
                        <div>
                            <Button autoFocus onClick={() => handleSave(fileName)} color="primary" disabled={(props.upladingFile || !note.trim()) ?
                                true : false}>
                                Save Note
                            </Button>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    );

}

const mapStateToProps = state => ({
    uploadFileUrl: state.addContractData.uploadUrl,
    upladingFile: state.addContractData.loadingFile
});

const mapDispatchToProps = dispatch => {
    return {
        uploadAttachment: (file) => dispatch(uploadAttachment(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(InternalNotes);