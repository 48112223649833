import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import {
  IconButton, Tooltip
} from '@mui/material';
import { useMediaQuery, Fab, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { addContractGeneralData, getCustomerMasterXRefData } from '../../redux/actions';
import { AddBoxOutlined, LibraryAdd } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  addColor: {
    color: 'black',
    marginRight: 20,
    padding: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: 10
    }
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1
  }
}));
const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));
const Toolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [searchText, setSearchText] = React.useState('');
  const addContract = () => {
    localStorage.removeItem('currentAddContractID');
    sessionStorage.setItem('mode', 'add');
    // history.push('contract-setup/add-contract');
  }
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  }
  return (
    <div
      {...rest}
      className={classes.root}
    >
      {sessionStorage.getItem("application") === 'Customer Rebate' &&
        secureLocalStorage.getItem("dYtz").role.rights &&
        secureLocalStorage
          .getItem("dYtz")
          .role.rights.includes(
            "post_exception-" + sessionStorage.getItem("application")
          )
        //  sessionStorage.getItem("application") ===
        //    "Sales Commission"
        && <Fab aria-label="edit" variant="extended"
          size='medium'
          classes={{ root: classes.fabContainer }}
          className={classes.fabContainer}
          component={CustomRouterLink}
          to='/exception-management/add'
        >
          <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
        </Fab>}

    </div >
  );
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (data) => dispatch(addContractGeneralData(data)),
    onSearch: (keyword) => dispatch(getCustomerMasterXRefData(0, 100, keyword))
  }
}
export default connect(null, mapDispatchToProps)(Toolbar);