import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions,

} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getPriceMaintenanceFields, createPriceMaintenanceUpload
} from '../../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const PriceListDataEditQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName.trim(), 'price_list_number': priceListID });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [priceListID, setPriceListID] = React.useState('');
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(sessionStorage.getItem('pricing-maintenance-query') ? JSON.parse(sessionStorage.getItem('pricing-maintenance-query')) : []);
    const [valid, setValid] = React.useState(true);

    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        if (purchaseQueryDataStorage) {
            setQueryName(purchaseQueryDataStorage.format_name)
            setQueryObject(purchaseQueryDataStorage)
            if (purchaseQueryDataStorage.price_list_number) {
                setPriceListID(purchaseQueryDataStorage.price_list_number)
                props.getPriceMaintenanceFields(purchaseQueryDataStorage.price_list_number);
            }
        }
    }, [purchaseQueryDataStorage]);

    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, queryObject);
    }
    function handleClearAll() {
        handleClear();
    }
    function handleCancelDialog() {
        history.push('/pricing-maintenance/pricing-maintenance-excel-upload');
    }
    function CheckValid(item) {
        var data = Object.entries(queryObject).filter(([k, v]) => k != item && k != 'mandatory' && k != 'format_name' && k != 'price_list_number')
        if (((props.maintenanceFields.value).length != Object.entries(data).length + 1) ||
            ((Object.entries(queryObject).filter(([k, v]) => k != item && k != 'mandatory' && k != 'format_name' && k != 'price_list_number' && isNaN(v)).length > 0)))
            setValid(false)
        else
            setValid(true)
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
            CheckValid(item)
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
            setValid(false)
        }
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/pricing-maintenance/pricing-maintenance-excel-upload'
                >
                    Price Maintenance Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <Grid container spacing={2} style={{ padding: '16px 25px 16px 25px' }}>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <FormLabel className={classes.formLabel} required>
                            Format Name
                        </FormLabel>
                        <OutlinedInput
                            value={queryName}
                            classes={{ root: classes.inputTwoLine }}
                            onChange={handleQueryName} />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <FormLabel className={classes.formLabel} required>
                            Price List ID
                        </FormLabel>
                        <OutlinedInput
                            value={priceListID}
                            classes={{ root: classes.inputTwoLine }}
                            disabled
                        />
                    </Grid>

                </Grid>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.maintenanceFields && props.maintenanceFields.value ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Field Name</TableCell>
                                                        <TableCell align='center' style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.maintenanceFields.value
                                                        .filter(item => item != 'format_name' && item != '')
                                                        .sort()
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        <p>{item.replace(/_/g, ' ')}<span style={{ color: 'red' }}> &nbsp;*</span></p>
                                                                    </TableCell>
                                                                    <TableCell style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item] ? queryObject[item] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                            defaultValue={JSON.parse(sessionStorage.getItem('pricing-maintenance-query'))[item] ? JSON.parse(sessionStorage.getItem('pricing-maintenance-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={!valid ? true : false}       >
                        Submit
                    </Button>
                </div>
            </div >
        </div >
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(createPriceMaintenanceUpload(data, id, 'edit')),
        getPriceMaintenanceFields: (priceListId) => dispatch(getPriceMaintenanceFields(priceListId)),

    }
}

const mapStateToProps = state => {
    return {
        maintenanceFields: state.pricingData.priceMaintenanceFields,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListDataEditQuery);