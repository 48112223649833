import {
  ANAMOLY_LIST_SUCCESS,
  ANAMOLY_DETAIL_SUCCESS,
  ANAMOLY_CONFIG_SINGLE,
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { history } from "../../../components/Helpers";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

export const getAnamolyasListSuccess = (data) => {
  return {
    type: ANAMOLY_LIST_SUCCESS,
    data,
  };
};

export const getAnamolyasList = (pagination, limit, id) => {
  return (dispatch) => {
    getAnamolyasListAPI(pagination, limit, id)
      .then((response) => {
        dispatch(getAnamolyasListSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getAnamolyasListAPI = (pagination, limit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("default-config?key_name=ai_anamoly_detection", {
    headers: headers,
  });
  return result;
};

export const submitAnamoly = (data, aimodelName, id, type) => {
  return (dispatch) => {
    submitAnamolyAPI(data, aimodelName, id, type)
      .then((response) => {
        history.push("/anomaly-config");
        dispatch(getAnamolyasList());
        toast.success(response.data.response);
      })
      .catch((error) => {
        //toast.error(error.response.data);
      });
  };
};

const submitAnamolyAPI = (data, aimodelName, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "put") {
    result = axios.put("default-config/" + id, data, { headers: headers });
  } else if (type === "delete") {
    result = axios.delete("default-config/" + id, data, { headers: headers });
  } else {
    result = axios.post("default-config?key_name=ai_anamoly_detection", data, {
      headers: headers,
    });
  }
  return result;
};

//edit formula
export const getAnamolyDetails = (id) => {
  return (dispatch) => {
    getAnamolyDetailsAPI(id)
      .then((response) => {
        dispatch(getAnamolyDetailsSuccess(response.data.response));
        //history.push('/query-field/edit-query-field/' + id);
      })
      .catch((error) => {});
  };
};

const getAnamolyDetailsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("default-config/" + id, { headers: headers });
  return result;
};

export const getAnamolyDetailsSuccess = (data) => {
  return {
    type: ANAMOLY_DETAIL_SUCCESS,
    data,
  };
};
//view
export const getSingleAnamolyConfig = (id) => {
  return (dispatch) => {
    getSingleAnamolyConfigAPI(id)
      .then((response) => {
        dispatch(getAnamolyConfigSuccessSingle(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error!');
      });
  };
};

export const getSingleAnamolyConfigAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("default-config/" + id, { headers: headers });
  return result;
};

export const getAnamolyConfigSuccessSingle = (data) => {
  return {
    type: ANAMOLY_CONFIG_SINGLE,
    data,
  };
};

export const runAnamoly = (data, id) => {
  return (dispatch) => {
    runAnamolyAPI(data, id)
      .then((response) => {
        history.push("/anomaly-config");
        dispatch(getAnamolyasList());
        toast.success(response.data.response);
      })
      .catch((error) => {
        //toast.error(error.response.data);
      });
  };
};

const runAnamolyAPI = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;

  result = axios.post("detect-anamoly/" + id, data, { headers: headers });

  return result;
};
