import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    allowedApps, getConfigurationDesigner, addWorkFlowAssignments,
    getLblDispDesFieldValue, onLoadingUserRoleAccessList
} from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { MultiSelectDropdownKeyVal } from '../../components/Inputs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: "0px 0px 15px 15px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    select: {
        width: "100%",
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: "100%",
        textTransform: "capitalize",
    },
    inputTwoLine: {
        height: '2.3rem',
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        width: "100%",
    },
}));

const AddWorkFlowAssignments = props => {
    const classes = useStyles();
    const [applicationType, setApplicationType] = React.useState('');
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [workflowName, setWorkflowName] = React.useState([]);
    const [dynamicFields, setDynamicFields] = React.useState([])
    const [documentManager, setDocumentManager] = React.useState([]);
    const [documentAnalystArray, setDocumentAnalystArray] = React.useState({});
    const [documentAnalyst, setDocumentAnalyst] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [filterData, setFilterData] = React.useState({});
    useEffect(() => {
        props.allowedApps();
        props.onLoadingUserRoleAccessList();
        props.getConfigurationDesigner();
    }, [])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
            var data = {
                "data_source": "document_header",
                "application_type": props.allowedAppsData[0],
                "assignment_workflow": true
            }
            props.fieldDesigner(data, 'post');
            props.onLoadingUserRoleAccessList();
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (props.configdesigner && props.configdesigner.length > 0) {
            props.configdesigner.map(item => {
                if (item.key_name === 'document_assignment_config') {
                    setWorkflowName(item.config_fields)
                }
            })
        }
    }, [props.configdesigner])
    useEffect(() => {
        var listData = []
        if (props.dropdownData) {
            Object.values(props.dropdownData.field_label_attributes)
                .filter(item => workflowName.includes(item.key))
                .map((item, i) => {
                    if (item.key != 'application_type' && item.key != 'approval_status') {
                        listData.push({
                            'field_id': item.key, 'name': item.current, 'type': item.key === 'contract_purpose' || item.key === 'accrual_level' || item.key === 'accrual_frequency'
                                || item.key === 'contract_dependency' || item.key === 'amortization_value' || item.key === 'commitment_achievement'
                                || item.key === 'copa_level' || item.key === 'currency' || item.key === 'evaluate_sales_bundle' || item.key === 'maximum_amount_level'
                                || item.key === 'maximum_amount_period' || item.key === 'pass_through_payment_partner_role' || item.key === 'pass_through' || item.key === 'payment_aggregation_level'
                                || item.key === 'payment_frequency' || item.key === 'payment_level' || item.key === 'payment_method' || item.key === 'payment_partner_role'
                                || item.key === 'posting_approval' || item.key === 'postings_block' || item.key === 'sales_analyst'
                                || item.key === 'source_data_type'
                                ? 'DROPDOWN.SINGLE' : item.type,
                            'drop_down_value_keys': item.drop_down_reference_value ?
                                item.drop_down_reference_value
                                : []
                        })

                    }
                    if (item.key === "approval_status") {
                        var temp = [
                            {
                                "desc": "New",
                                "key": "new"
                            },
                            {
                                "desc": "Submit for approval",
                                "key": "submit_approval"
                            },
                            {
                                "desc": "Accepted",
                                "key": "acc"
                            },
                            {
                                "desc": "Rejected",
                                "key": "rej"
                            },
                            {
                                "desc": "Changes Approved",
                                "key": "changes_acc"
                            }
                        ]

                        listData.push({ 'field_id': item.key, 'name': item.current, 'type': 'DROPDOWN.SINGLE', 'drop_down_value_keys': temp })
                    }
                })
            setAttributeArray(listData.filter((v, i, a) => a.findIndex(v2 => (v2.field_id === v.field_id)) === i))
        }
    }, [props.dropdownData])
    useEffect(() => {
        if (props.userRoleAccessData && props.userRoleAccessData.length > 0) {
            setDocumentAnalystArray(props.userRoleAccessData)
        }
    }, [props.userRoleAccessData]);
    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
    }
    function handleClear() {
        setDocumentAnalyst([])
        setDocumentManager([])
        setApplicationType('')
        setFilterData({})
    }
    function handleOnSubmit() {
        var data = {
            "document_analyst": documentAnalyst,
            "document_manager": documentManager,
            "application_type": applicationType
        };
        data["filters"] = filterData;
        props.onSubmit(data)
        handleClear();
    }
    function handleClearAll() {
        handleClear();
    }
    function handleDocumentAnalyst(e) {
        setDocumentAnalyst(e.target.value)
    }
    function handleDocumentManager(e) {
        setDocumentManager(e.target.value)
    }
    const handleFilterData = (data, item, type) => {
        var chipData = []
        var temp = {}
        if (type == 'start_date' || type == 'end_date') {
            temp = filterData[item.field_id] ? filterData[item.field_id] : {}
            setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
        }
        if (data && data.length > 0) {
            if (type == 'chip') {
                chipData = filterData[item.field_id] ? filterData[item.field_id] : []
                chipData = [...chipData, data]
                setFilterData({ ...filterData, [item.field_id]: chipData })
            }
            else if (type == 'NUMERIC')
                setFilterData({ ...filterData, [item.field_id]: Number(data) })
            else {
                setFilterData({ ...filterData, [item.field_id]: data })
            }
        }
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/workflow-assignment'
                >
                    WorkFlow Assignment
                </Link>
                <Typography color="textPrimary" variant='h4'>Add WorkFlow Assignment</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={1.5}>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required style={{ paddingBottom: 8 }}>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    style={{ textTransform: 'capitalize' }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} style={{ paddingBottom: 8 }}>
                                    Document Manager
                                </FormLabel>
                                <div className={classes.dropdownContainer}>
                                    <Select
                                        disableUnderline
                                        value={documentManager}
                                        onChange={handleDocumentManager}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}

                                    >
                                        {documentAnalystArray && documentAnalystArray.length > 0 &&
                                            documentAnalystArray.map((item, index) => (
                                                <MenuItem value={item.id} key={item}>
                                                    {item.username}
                                                </MenuItem>
                                            ))}
                                    </Select>

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required style={{ paddingBottom: 8 }}>
                                    Document Analyst
                                </FormLabel>
                                <div className={classes.dropdownContainer}>
                                    <Select
                                        disableUnderline
                                        value={documentAnalyst}
                                        onChange={handleDocumentAnalyst}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}

                                    >
                                        {documentAnalystArray && documentAnalystArray.length > 0 &&
                                            documentAnalystArray.map((item, index) => (
                                                <MenuItem value={item.id} key={item}>
                                                    {item.username}
                                                </MenuItem>
                                            ))}
                                    </Select>

                                </div>
                            </Grid>

                            {attributeArray &&
                                attributeArray.map((item, i) => {
                                    return (
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                            style={{ marginTop: 9 }}
                                        >
                                            {item.type == 'DROPDOWN.STRING' ?
                                                <div style={{ height: 40 }}>
                                                    <MultiSelectDropdownKeyVal capitalize={true} heading={item.name} listArray={item.drop_down_value_keys} data={filterData[item.field_id] ? filterData[item.field_id] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                                </div>
                                                :
                                                item.type == 'BOOL' ?
                                                    <div style={{ paddingTop: 15 }}>
                                                        <FormLabel required>
                                                            {item.name}
                                                        </FormLabel>
                                                        <Select
                                                            value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                            onChange={(e) => handleFilterData(e.target.value, item)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value={'true'} key={'true'}>
                                                                Yes
                                                            </MenuItem>
                                                            <MenuItem value={'false'} key={'false'}>
                                                                No
                                                            </MenuItem>
                                                        </Select>
                                                    </div>
                                                    :
                                                    item.type == 'TIME' ?
                                                        <div style={{ paddingTop: 15 }}>
                                                            <FormLabel>
                                                                {item.name}
                                                            </FormLabel>
                                                            <div style={{ display: 'flex' }}>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginTop: 10,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%',
                                                                                    marginRight: 5
                                                                                }
                                                                            }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                            onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    marginTop: 10,
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                            onChange={(e) => handleFilterData(e, item, 'end_date')}

                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>
                                                        :
                                                        item.type == "DROPDOWN.SINGLE" ?
                                                            <div style={{ paddingTop: 15 }}>
                                                                <FormLabel required>
                                                                    {item.name}
                                                                </FormLabel>
                                                                <Select
                                                                    value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                                    onChange={(e) => handleFilterData(e.target.value, item)}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                    </MenuItem>
                                                                    {item.drop_down_value_keys && Object.values(item.drop_down_value_keys).length > 0 && Object.values(item.drop_down_value_keys)
                                                                        .map((item) => {
                                                                            return (
                                                                                <MenuItem value={item.key} key={item.key} >
                                                                                    {item.desc}
                                                                                </MenuItem>
                                                                            )
                                                                        })}


                                                                </Select>
                                                            </div>
                                                            :
                                                            item.type == 'NUMERIC' ?
                                                                <div style={{ paddingTop: 15 }}>
                                                                    <FormLabel required>
                                                                        {item.name}
                                                                    </FormLabel>
                                                                    <OutlinedInput
                                                                        type={'number'}
                                                                        classes={{ root: classes.inputTwoLine }}
                                                                        value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                                        onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                                    />

                                                                </div> :
                                                                <div style={{ paddingTop: 15 }}>
                                                                    <FormLabel>
                                                                        {item.name}
                                                                    </FormLabel>
                                                                    <OutlinedInput
                                                                        classes={{ root: classes.inputTwoLine }}
                                                                        value={filterData[item.field_id] ? filterData[item.field_id] : ''}
                                                                        onChange={(e) => handleFilterData(e.target.value, item)}
                                                                    />
                                                                </div>
                                            }
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </div>
                </form>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={props.loadingAPI ? '' : handleOnSubmit}
                        disabled={applicationType && documentAnalyst && attributeArray.length == Object.entries(filterData).length ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        loadingAPI: state.customerData.loading,
        allowedAppsData: state.initialData.allowedApps,
        userRoleAccessData: state.addMultipleConfigurationData.userRoleAccessData,
        configdesigner: state.addMultipleConfigurationData.configdesigner,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addWorkFlowAssignments(data)),
        allowedApps: () => dispatch(allowedApps()),
        getConfigurationDesigner: () => dispatch(getConfigurationDesigner()),
        onLoadingUserRoleAccessList: () => dispatch(onLoadingUserRoleAccessList()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddWorkFlowAssignments);