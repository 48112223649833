import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, TextField,
    Card, OutlinedInput, FormLabel, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, Radio,
    RadioGroup,
    FormControlLabel, ListItemText, Checkbox,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addPricingBatchJobSetup, getIntegrationSetupData, getPriceCalForList, getDefaultValues, getBuildSalesDataFieldValue } from '../../../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MultiSelectDropdownKeyVal } from '../../../../components/Inputs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../../components/Inputs";
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
        marginTop: 20

    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 1,
        width: '100%',
        paddingLeft: 10,
        borderRadius: '3px'
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 1,
        height: 34,
        borderRadius: '3px',
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    contractContiner: {
        marginTop: 17
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        //padding: '1px 0 1px 11px',
        alignSelf: 'center',
        //marginTop: 10,
        //border: '1px solid #E0E0E0',
        width: '100%',
        borderRadius: '3px'
    },

    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 30,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    bodyContainer1: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        width: '100%'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    inputTwoLineOutlined: {
        // marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 38,
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
}));

const AddTransactionDataBatchJobSetup = props => {
    const classes = useStyles();
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [customerNumber, setCustomerNumber] = useState([]);
    const [materialNumber, setMaterialNumber] = useState([]);
    const [supplierNumber, setSupplierNumber] = useState([]);
    const [employeeNumber, setEmployeeNumber] = useState([]);
    const [materialGroup, setMaterialGroup] = React.useState([]);
    const [customerGroup, setCustomerGroup] = React.useState([]);
    const [transactionType, setTransactionType] = React.useState('');
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');

    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const [packetSize, setPacketSize] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [filterData, setFilterData] = React.useState({});
    const options = ["range", "equal", "not equal", 'greater than', 'less than', 'greater than equal to', 'less than equal to', "pattern"];

    const history = useHistory();
    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }

    useEffect(() => {
        props.getIntegrationSetupData();
        props.getConfigData(1, 10);
        props.onLoadingDefault("Customer Rebate");
        props.getBuildSalesDataFieldValue({ "table_name": "Direct - Sales Data", "app-functionality": 'SalesDataUploadFormat' });
    }, []);
    useEffect(() => {

        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'accrual_calendar') {
                    setPlannedStartCalendarArray(item)
                }
            })
        }
    }, [props.dropdownData])

    useEffect(() => {
        if (frequency === 'Hourly' || frequency === 'Daily' || frequency === 'One Time') {
            setPlannedDateType('static');
        }
        else {
            setPlannedDateType('');
        }
    }, [frequency])

    const BatchJobTypesArray = [
        'Build Sales Data'
    ];
    const frequencyTypesArray = [
        'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue);
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue);
    }
    function handleMaterialNumber(newValue) {
        setMaterialNumber(newValue);
    }
    function handleEmployeeNumber(newValue) {
        setEmployeeNumber(newValue);
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber(newValue);
    }
    function handleMaterialGroup(newValue) {
        setMaterialGroup(newValue);
    }
    function handleCustomerGroup(newValue) {
        setCustomerGroup(newValue);
    }


    function handleDeleteChip(value, index, field) {
        if (field == 'Customer Number') {
            var deletedCustomerNumber = customerNumber.filter(item => item !== value)
            setCustomerNumber(deletedCustomerNumber)
        }
        if (field == 'Employee Number') {
            var deletedEmployeeNumber = employeeNumber.filter(item => item !== value)
            setEmployeeNumber(deletedEmployeeNumber)
        }
        if (field == 'Supplier Number') {
            var deletedSupplierNumber = supplierNumber.filter(item => item !== value)
            setSupplierNumber(deletedSupplierNumber)
        }
        if (field == 'Material Group') {
            var deletedMaterialGroup = materialGroup.filter(item => item !== value)
            setMaterialGroup(deletedMaterialGroup)
        }
        if (field == 'Customer Group') {
            var deletedCustomerGroup = customerGroup.filter(item => item !== value)
            setCustomerGroup(deletedCustomerGroup)
        }
        else {
            var deletedMaterialNumber = materialNumber.filter(item => item !== value)
            setMaterialNumber(deletedMaterialNumber)
        }

    }
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleTransactionType = (value) => {
        setTransactionType(value);
    }
    function handleContinue() {
        setOpen(false);
    }

    function handleClear() {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setSupplierNumber([]);
        setEmployeeNumber([]);
        setMaterialGroup([]);
        setCustomerGroup([]);
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setTransactionType();

        setFilterData({})
    }
    function handleOnSubmit() {
        let timeStamp = Moment.tz(timezoneOffset).format().toString().split("T")
        var data = {
            "app_type": "Sales Data",
            "batch_job_type": batchJobType,
            "batch_job_name": batchJobName,
            "planned_date_type": plannedDateType,
            // "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ'),
            // "planned_start_calendar": plannedStartCalendar,
            "planned_start_time": plannedStartTime.concat(":00"),
            "frequency": frequency,
            "batch_filter_criteria": [{
                "filters": {
                    "billing_start_date": billingDateStart ? Moment.utc(billingDateStart).format('YYYY-MM-DD') : '',
                    "billing_end_date": billingDateEnd ? Moment.utc(billingDateEnd).format('YYYY-MM-DD') : '',
                    // "customer_number": customerNumber.length > 1 ? customerNumber : customerNumber.toString(),
                    // "material_number": materialNumber.length > 1 ? materialNumber : materialNumber.toString(),
                    "records": radioGroupValue ? radioGroupValue : '',
                    "transaction_type": transactionType,
                    "packet_size": packetSize,
                    "filter_fields": {
                        ...filterData
                    },
                    // "customer_group": customerGroup.length > 1 ? customerGroup : customerGroup.toString(),
                    // 'material_group': materialGroup.length > 1 ? materialGroup : materialGroup.toString(),
                    // "supplier_number": supplierNumber.length > 1 ? supplierNumber : supplierNumber.toString(),
                    // "employee_number": employeeNumber.length > 1 ? employeeNumber : employeeNumber.toString(),
                }
            }]
        }
        if (plannedDateType == 'dynamic') {
            data["planned_start_calendar"] = plannedStartCalendar
        } else {
            data["planned_start_date"] = plannedStartDate ? Moment.utc(plannedStartDate).format('YYYY-MM-DDT') + timeStamp[1].replace(/^(\d{2}):(\d{2})/, plannedStartTime.slice(0, 5)) : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ')
        }
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
    }

    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value)
    }

    //
    useEffect(() => {
        let listData = []
        if (props.tableData && props.tableData.field_label_attributes) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.key != 'key_figure_names' && item.key != 'attribute_names' && item.key != 'accrual_amount' && item.key != 'change_request_id'
                    && item.key != 'chargeback_calculation_rules' && item.key != 'has_active_update_request' && item.key != 'is_update_approval_contract' && item.key != 'payment_amount'
                    && item.key != 'update_contract_id' && item.key != 'update_request_closed' && item.key != 'target_rules'
                )
                .map((item) => {
                    if (item.key != 'approval_status') {
                        listData.push({
                            'field_id': item.key, 'name': item.current, 'type': item.type
                            , 'drop_down_value_keys': item.type === 'DROPDOWN.STRING' ?
                                item.drop_down_reference_value
                                : []
                        })

                    }
                    if (item.key === "approval_status") {
                        let temp = [
                            {
                                "desc": "New",
                                "key": "new"
                            },
                            {
                                "desc": "Submit for approval",
                                "key": "submit_approval"
                            },
                            {
                                "desc": "Accepted",
                                "key": "acc"
                            },
                            {
                                "desc": "Rejected",
                                "key": "rej"
                            },
                            {
                                "desc": "Changes Approved",
                                "key": "changes_acc"
                            }
                        ]

                        listData.push({ 'field_id': item.key, 'name': item.current, 'type': 'DROPDOWN.SINGLE', 'drop_down_value_keys': temp })
                    }
                })
            setAttributeArray(listData.filter((v, i, a) => a.findIndex(v2 => (v2.field_id === v.field_id)) === i))
        }
    }, [props.tableData])


    const handleFilterData = (data, item, type) => {
        let chipData = []
        let temp = {}

        if (type == 'start_date' || type == 'end_date') {
            temp = filterData[item.field_id] ? filterData[item.field_id] : {}
            setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
        }
        if (data && data.length > 0) {
            if (type == 'chip') {
                chipData = filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []
                // chipData = [...chipData, data]
                chipData = data
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": chipData
                    }
                })
            }
            else if (type == 'NUMERIC')
                setFilterData({
                    ...filterData, [item.field_id]:
                    {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": Number(data)
                    }
                })
            else
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": data
                    }
                })
        }

    }
    const handleDeleteFilterData = (newValue, index, item) => {
        let deleteFilterData = filterData[item.field_id]['filter_value'].filter(item => item !== newValue)
        setFilterData({
            ...filterData, [item.field_id]: {
                ...filterData[item.field_id],
                "filter_name": item.field_id,
                "filter_value": deleteFilterData


            }
        })
    }
    const handleOption = (e, item) => {
        setFilterData({
            ...filterData, [item.field_id]: {
                ...filterData[item.field_id],
                "filter_option": e
            }
        })
    }

    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === attributeArray.length ? [] : attributeArray.map((item) => item.field_id));
            setAllSelect(selected.length === attributeArray.length ? false : true)
            setSelectedDetail(selected.length === attributeArray.length ? [] : attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        setSelectedDetail([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(attributeArray && attributeArray.length > 0 && attributes.length === attributeArray.length)
        setSelectedDetail(attributesData)
    }
    const handleRangeFilterData = (data, item, data2) => {
        setFilterData({
            ...filterData, [item.field_id]:
            {
                ...filterData[item.field_id],
                "filter_name": item.field_id,
                "filter_value": {
                    "from": data.toString(), "to": data2
                }
            }

        })
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/transaction-data-batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Automatic Integration' ? 'none' : 'flex') }}>

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>
                                            Transaction Type
                                        </FormLabel>
                                        <Select
                                            value={transactionType}
                                            onChange={(e) => handleTransactionType(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }} >
                                            <MenuItem value={'INV'} key={'INV'}>
                                                INV
                                            </MenuItem>
                                            <MenuItem value={'SO'} key={'SO'}>
                                                SO
                                            </MenuItem>
                                            <MenuItem value={'DEL'} key={'DEL'}>
                                                DEL
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                {transactionType === 'INV' &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none') }}
                                    >
                                        <div style={{ display: 'flex' }}>

                                            <div className={classes.contractContiner}>
                                                <FormLabel >Billing Date</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 1,
                                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={billingDateStart}
                                                            onChange={handleBillingDateStart}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                            <div className={classes.contractContiner}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 34,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%',
                                                                    marginLeft: 10
                                                                }
                                                            }}
                                                            value={billingDateEnd}
                                                            onChange={handleBillingDateEnd}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={billingDateStart ? billingDateStart : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                    </Grid>
                                }
                                {transactionType === 'SO' &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}

                                    >
                                        <div style={{ display: 'flex' }}>

                                            <div className={classes.contractContiner}>
                                                <FormLabel >Sales Order Date</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 1,
                                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={billingDateStart}
                                                            onChange={handleBillingDateStart}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                            <div className={classes.contractContiner}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 34,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%',
                                                                    marginLeft: 10
                                                                }
                                                            }}
                                                            value={billingDateEnd}
                                                            onChange={handleBillingDateEnd}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={billingDateStart ? billingDateStart : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                    </Grid>
                                }
                                {transactionType === 'DEL' &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}

                                    >
                                        <div style={{ display: 'flex' }}>

                                            <div className={classes.contractContiner}>
                                                <FormLabel >Delivery Date</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 1,
                                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={billingDateStart}
                                                            onChange={handleBillingDateStart}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                            <div className={classes.contractContiner}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    // padding: '0px 11px',
                                                                    borderRadius: '3px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: 34,
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%',
                                                                    marginLeft: 10
                                                                }
                                                            }}
                                                            value={billingDateEnd}
                                                            onChange={handleBillingDateEnd}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={billingDateStart ? billingDateStart : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                    </Grid>
                                }

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "1px" }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                            Packet Size
                                        </FormLabel>
                                        <OutlinedInput
                                            type={'number'}
                                            value={packetSize}
                                            // classes={{ root: classes.inputTwoLineOutlined }}
                                            onChange={(e) => setPacketSize(e.target.value)}
                                        >
                                        </OutlinedInput>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginLeft: "-15px" }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>
                                            Processing Type
                                        </FormLabel>
                                        <Select
                                            value={radioGroupValue}
                                            onChange={(e) => handleRadioGroup(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }} >
                                            <MenuItem value={'non-processed'} key={'non-processed'}>
                                                Process New Records
                                            </MenuItem>
                                            <MenuItem value={'all'} key={'all'}>
                                                Process All Records
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Grid>

                                <Grid container style={{ marginBottom: 20, marginTop: '2rem', marginLeft: "1rem" }}>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ display: 'flex' }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} style={{ marginRight: 20, paddingTop: 5 }}>
                                            Select Required Filters
                                        </FormLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id={'filter-list'}
                                            multiple
                                            value={attributes ? attributes : []}
                                            style={{ maxHeight: "50px", width: '70%', color: 'grey' }}
                                            input={<OutlinedInput />}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            onOpen={() => handleOpen()}
                                            onClose={() => setSelectOpen(false)}
                                            open={selectOpen}
                                            renderValue={(filter) => {
                                                let type = []
                                                filter.map(itemX => {
                                                    attributeArray.filter(item => item.field_id == itemX).map((item) => {
                                                        if (item.name)
                                                            type.push(item.name)
                                                    })

                                                })
                                                return type.join(',')
                                            }}
                                            className={classes.multiSelect}

                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={allSelect} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {attributeArray && attributeArray.length > 0 && attributeArray
                                                .sort((a, b) => a.name > b.name ? 1 : -1)
                                                .map((item) => {
                                                    return (

                                                        <MenuItem onClick={(event) => handleSelectValues(event, item.field_id, item)} value={item.field_id} key={item.field_id} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected && selected.indexOf(item.field_id) > -1}
                                                            />
                                                            <ListItemText primary={item.name} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            <MenuItem value='' ></MenuItem>

                                            <div className={classes.dropdownAction}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </Grid>
                                    <div className={classes.bodyContainer1} >
                                        {attributesData?.map((item, i) => {
                                            return (
                                                <Grid
                                                    container
                                                >
                                                    <Grid
                                                        md={2}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <div className={classes.row} >
                                                            <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Filter Name"}</Typography>
                                                        </div>
                                                        <FormLabel classes={{ root: classes.formLabel }} style={{ marginTop: '1rem' }} >  {item.name} </FormLabel>

                                                    </Grid>
                                                    <Grid
                                                        md={2}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <div className={classes.row} >
                                                            <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Option"}</Typography>
                                                        </div>
                                                        <Select
                                                            value={filterData[item.field_id] && filterData[item.field_id]['filter_option'] ? filterData[item.field_id]['filter_option'] : []}
                                                            onChange={(e) => handleOption(e.target.value, item)}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select1]: true,
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem,
                                                            }}
                                                        >
                                                            <MenuItem value=""></MenuItem>
                                                            {options &&
                                                                options
                                                                    .map((item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={item}
                                                                                key={item}
                                                                                style={{ textTransform: "capitalize" }}
                                                                            >
                                                                                {item}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                        </Select>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        <div className={classes.row} >
                                                            <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Value"}</Typography>
                                                        </div>

                                                        {item.type == 'DROPDOWN.STRING' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                            <MultiSelectDropdownKeyVal capitalize={true}
                                                                listArray={item.drop_down_value_keys} data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                                            :
                                                            item.type == 'BOOL' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                <div>

                                                                    <Select
                                                                        value={filterData[item.field_id] ? filterData[item.field_id.filter_value] : ''}
                                                                        onChange={(e) => handleFilterData(e.target.value, item)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        <MenuItem value={'true'} key={'true'}>
                                                                            Yes
                                                                        </MenuItem>
                                                                        <MenuItem value={'false'} key={'false'}>
                                                                            No
                                                                        </MenuItem>
                                                                    </Select>
                                                                </div>
                                                                :
                                                                item.type == 'TIME' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                    <div>
                                                                        <div style={{ display: 'flex' }}>
                                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                                <Grid container justifyContent="space-around">
                                                                                    <DatePicker
                                                                                        disableToolbar
                                                                                        clearable
                                                                                        InputProps={{
                                                                                            padding: 0,
                                                                                            disableUnderline: true,
                                                                                            style: {
                                                                                                padding: '0px 11px',
                                                                                                alignSelf: 'center',
                                                                                                alignItems: 'center',
                                                                                                marginTop: 10,
                                                                                                border: '1px solid #E0E0E0',
                                                                                                width: '100%',
                                                                                                marginRight: 5
                                                                                            }
                                                                                        }}
                                                                                        value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                                        onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                        format="MM/DD/YYYY"
                                                                                    />
                                                                                </Grid>
                                                                            </LocalizationProvider>
                                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                                <Grid container justifyContent="space-around">
                                                                                    <DatePicker
                                                                                        disableToolbar
                                                                                        clearable
                                                                                        InputProps={{
                                                                                            padding: 0,
                                                                                            disableUnderline: true,
                                                                                            style: {
                                                                                                padding: '0px 11px',
                                                                                                alignSelf: 'center',
                                                                                                alignItems: 'center',
                                                                                                marginTop: 10,
                                                                                                border: '1px solid #E0E0E0',
                                                                                                width: '100%'
                                                                                            }
                                                                                        }}
                                                                                        value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                                        onChange={(e) => handleFilterData(e, item, 'end_date')}
                                                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                        format="MM/DD/YYYY"
                                                                                    />
                                                                                </Grid>
                                                                            </LocalizationProvider>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    item.type == "DROPDOWN.SINGLE" && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                        <div>
                                                                            <Select
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                                onChange={(e) => handleFilterData(e.target.value, item)}
                                                                                displayEmpty
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                </MenuItem>
                                                                                {item.drop_down_value_keys && Object.values(item.drop_down_value_keys).length > 0 && Object.values(item.drop_down_value_keys)
                                                                                    .map((item) => {
                                                                                        return (
                                                                                            <MenuItem value={item.key} key={item.key} >
                                                                                                {item.desc}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}


                                                                            </Select>
                                                                        </div>
                                                                        :
                                                                        item.type == 'NUMERIC' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                            <div>
                                                                                <OutlinedInput
                                                                                    type={'number'}
                                                                                    classes={{ root: classes.inputTwoLineOutlined }}
                                                                                    value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : ''}
                                                                                    onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                                                />

                                                                            </div> :
                                                                            filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                                <div>
                                                                                    <ChipInput data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} onChange={newValue => handleFilterData(newValue, item, 'chip')} />
                                                                                    {/* <ChipInput
                                                                                        classes={{
                                                                                            root: classes.rootContainer,
                                                                                            chip: classes.chip,
                                                                                            input: classes.input,
                                                                                            inputRoot: classes.inputRoot,
                                                                                            chipContainer: classes.chipContainer,
                                                                                            label: classes.chipLabel
                                                                                        }}
                                                                                        value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                                        onAdd={(chips) => handleFilterData(chips, item, 'chip')}
                                                                                        onDelete={(chip, index) => handleDeleteFilterData(chip, index, item)}
                                                                                        disableUnderline={true}
                                                                                        className={clsx({
                                                                                            [classes.textInput]: true,
                                                                                        })}
                                                                                    /> */}

                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <OutlinedInput
                                                                                        classes={{ root: classes.inputTwoLineOutlined }}
                                                                                        value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : ''}
                                                                                        onChange={(e) => handleRangeFilterData(e.target.value, item)}
                                                                                    />
                                                                                </div>
                                                        }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={2}
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                        style={{ display: filterData[item.field_id] && filterData[item.field_id]['filter_option'] == 'range' ? 'block' : 'none' }}
                                                    >
                                                        <div className={classes.row} >
                                                            <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Range"}</Typography>
                                                        </div>
                                                        <div>
                                                            <OutlinedInput
                                                                classes={{ root: classes.inputTwoLineOutlined }}
                                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['to'] ? filterData[item.field_id]['filter_value']['to'] : ''}
                                                                onChange={(e) => handleRangeFilterData(filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : '', item, e.target.value)}
                                                            />
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            );
                                        })
                                        }
                                    </div>
                                </Grid>

                                {/* <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "1px" }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Customer Number </Typography>
                                    <ChipInput data={customerNumber} onChange={handleCustomerNumber} />
                                   
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "-8px" }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Material Number</Typography>
                                    <ChipInput data={materialNumber} onChange={handleMaterialNumber} />
                                    
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "-8px" }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Supplier Number </Typography>
                                    <ChipInput data={supplierNumber} onChange={handleSupplierNumber} />
                                   
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "-8px" }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Employee Number </Typography>
                                    <ChipInput data={employeeNumber} onChange={handleEmployeeNumber} />
                                   
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "-8px" }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Customer Group </Typography>
                                    <ChipInput data={customerGroup} onChange={handleCustomerGroup} />
                                    
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), marginTop: "-8px" }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} style={{ color: 'black' }} className={classes.contractContiner}> Material Group </Typography>
                                    <ChipInput data={materialGroup} onChange={handleMaterialGroup} />
                                </Grid> */}
                                {/* <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: (batchJobType === 'Build Sales Data' ? 'block' : 'none'), }}
                                >
                                    <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                        <FormControlLabel
                                            value="non-processed"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.fontSetting }} > Process New Records </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />
                                        <FormControlLabel
                                            value="all"
                                            control={<Radio color="primary" />}
                                            label={<Typography classes={{ root: classes.fontSetting }} >Process All Records </Typography>}
                                            classes={{ labelPlacementStart: classes.radioLabel }}
                                        />
                                    </RadioGroup>
                                </Grid> */}
                            </Grid>
                        </div>
                    </form>
                </Card >

                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Type
                                            </FormLabel>
                                            <Select
                                                value={batchJobType}
                                                onChange={(e) => setBatchJobType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {BatchJobTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => setFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {frequencyTypesArray
                                                    .filter(item => item !== "Weekly" && item !== "Quarterly")
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>


                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Name
                                            </FormLabel>
                                            <OutlinedInput
                                                value={batchJobName}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setBatchJobName(e.target.value)} />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => setPlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency || (frequency === 'Hourly' || frequency === 'Daily' || frequency === 'One Time')}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                <MenuItem value='static'>
                                                    Static
                                                </MenuItem>
                                                <MenuItem value='dynamic'
                                                    style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>

                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            borderRadius: '3px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 1,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select
                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                    .filter(item => item != 'Manual')
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>

                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>

                                            <TextField
                                                id="time"
                                                type="time"
                                                //defaultValue=""
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                className={classes.textField}
                                                //style={{ paddingLeft: 8, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>


                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/transaction-data-batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleContinue()} color="primary" autoFocus variant="contained"
                            disabled={!batchJobType || !batchJobName || !plannedStartTime || !frequency || !plannedDateType ? true
                                : plannedStartDate || plannedStartCalendar ? false : true
                            }
                        >
                            Continue

                        </Button>
                    </DialogActions>
                </Dialog>



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>


                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}

                        disabled={batchJobType === 'Build Sales Data' && transactionType && radioGroupValue ? false : true}
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addPricingBatchJobSetup(data, null, null, 'Salse Data')),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getBuildSalesDataFieldValue: (data) => dispatch(getBuildSalesDataFieldValue(data))
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        integrationSetupData: state.customerData.integrationSetupData,
        configData: state.pricingData.priceCalForListData,
        tableData: state.dataSetupData.buildSalesData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionDataBatchJobSetup);