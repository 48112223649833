import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { getContractXRefDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LabelText } from '../../components/Inputs';
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
}));

const ViewContractXREF = props => {
    const classes = useStyles();
    const [organizationId, setOrganizationId] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerContractId, setChannelPartnerContractId] = React.useState('');
    const [imaContractId, setImaContractId] = React.useState('');
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getContractXRefDetails(appId);
    }, []);
    useEffect(() => {
        if (props.customerMasterXRefData) {
            setChannelPartnerId(props.customerMasterXRefData.channel_partner_id);
            setChannelPartnerContractId(props.customerMasterXRefData.channel_partner_contract_id);
            setImaContractId(props.customerMasterXRefData.ima_contract_id);
        }
    }, [props.customerMasterXRefData]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/contract-xref'
                >  Contract XREF
                </Link>
                <Typography color="textPrimary" variant='h1'>View Contract XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <div className={classes.container}>
                            <Grid container spacing={2} style={{paddingLeft:10}}>
                            {channelPartnerId &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{root:classes.formLabel}} heading='Channel Partner ID' data={channelPartnerId} twoline='true' />
                                    </Grid>
                                }
                                {channelPartnerContractId &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{root:classes.formLabel}} heading='Channel Partner Contract ID' data={channelPartnerContractId} twoline='true' />
                                    </Grid>
                                }
                                {imaContractId &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{root:classes.formLabel}} heading='IMA Contract ID' data={imaContractId} twoline='true' />
                                    </Grid>
                                }
                            </Grid>
                        </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        customerMasterXRefData: state.customerData.contractXRefViewData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getContractXRefDetails: (id) => dispatch(getContractXRefDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewContractXREF);