import React from "react";
import {
  Grid, Button, Typography, Breadcrumbs,
  Card, FormLabel,
  MenuItem, Select, OutlinedInput,
} from "@mui/material";
import { Link } from 'react-router-dom';
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { createDrivers } from "../../../redux/actions";
import LoadingOverlay from 'react-loading-overlay';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  container: {
    padding: 15,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  selectRoot: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  select: {
    width: "100%",
  },
  selectedItem: {
    color: theme.palette.text.grey,
    marginTop: 1,
    width: "100%",
  },
  inputTwoLine: {
    marginTop: 2,
    width: "100%",
    paddingLeft: 10,
    height: 37,
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 28,
  },
  formDiv: {
    fontSize: theme.typography.h3.fontSize,
  },
  dropdownContainer: {
    maxHeight: 50,
    overflowY: "scroll",
  },
  formlabel: {
    marginBottom: 8,
    fontSize: theme.typography.h3.fontSize,
  },
}));

const AddDrivers = (props) => {
  const classes = useStyles();
  const [priceTypeName, setPriceTypeName] = React.useState("");
  const [priceType, setPriceType] = React.useState("");
  const [calculationType, setCalculationType] = React.useState("");
  const [externalPriceTypeName, setExternalPriceTypeName] = React.useState("");
  const [scales, setScales] = React.useState("");
  const [priceTypeCategory, setPriceTypeCategory] = React.useState("");
  const priceTypeArray = [
    "Base Price",
    "On-Invoice Discount",
    "On-Invoice Surcharge",
    "On-Invoice Freight",
    "Off-Invoice Revenue",
    "Off-Invoice Expense",
    "Cost - Indirect",
    "Cost - Direct",
    "Cost - Extra",
    "Floor Price",
    "Margin Target %",
    "Market Price",
    "Market Target %",
    "Transfer Price",
    "Base Sales Adjustments",
    "Price Elasticity Adjustments",
    "Cost Adjustments"
  ];
  const calculationTypeArray = ["Qty", "%", "Fixed"];

  function handlePriceType(value) {
    setPriceType(value);
    if (value === 'Base Price') {
      setPriceTypeCategory('Base Price')
    }
    else if (value === 'Cost - Indirect' || value === 'Cost - Direct' || value === 'Cost - Extra') {
      setPriceTypeCategory('Cost')
    }
    else if (value === 'Margin Target %' || value === 'Market Price' || value === 'Market Target %' || value == 'Floor Price') {
      setPriceTypeCategory('Analytical')
    }
    else if (value === 'Off-Invoice Revenue' || value === 'Off-Invoice Expense') {
      setPriceTypeCategory('Off-Invoice Adjustments')
    }
    else if (value === 'On-Invoice Discount' || value === 'On-Invoice Surcharge' || value === 'On-Invoice Freight') {
      setPriceTypeCategory('On-Invoice Adjustments')
    }
    else if (value === 'Transfer Price') {
      setPriceTypeCategory('Transfer Price')
    }
    else {
      setPriceTypeCategory("Optimization")
    }
  }
  function handleOnSubmit() {
    var data = {
      driver_name: priceTypeName,
      driver: priceType,
      // calculation_type: calculationType,
      // scales: scales == "true" ? true : false,
      external_driver_name: externalPriceTypeName,
      driver_category: priceTypeCategory,
    };
    props.onSubmit(data);
  }
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };
  return (
    <LoadingOverlay
      active={props.loadingAPI}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#64C4B4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: '#64C4B4'
        })
      }}

    >
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            variant="h4"
            classes={{
              root: classes.link,
            }}
            to="/drivers"
          >
            Drivers
          </Link>
          <Typography color="textPrimary" variant="h4">
            Add Drivers
          </Typography>
        </Breadcrumbs>

        <div className={classes.bodyContainer}>
          <Card>
            <form autoComplete="off" noValidate>
              <div className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <div className={classes.selectRoot}>
                      <FormLabel className={classes.formlabel} required>
                        Driver Name
                      </FormLabel>
                      <OutlinedInput
                        value={priceTypeName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={(e) => setPriceTypeName(e.target.value)}
                      />
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <div className={classes.selectRoot}>
                      <FormLabel
                        variant="h5"
                        className={classes.formDiv}
                        required
                      >
                        Driver Type
                      </FormLabel>
                      <div className={classes.dropdownContainer}>
                        <Select
                          disableUnderline
                          value={priceType}
                          onChange={(e) => handlePriceType(e.target.value)}
                          displayEmpty
                          MenuProps={{ ...MenuProps, autoFocus: true }}
                          classes={{
                            selectMenu: classes.selectedItem,
                          }}
                          className={clsx({
                            [classes.select]: true,
                          })}
                          style={{ textTransform: "capitalize", marginTop: 2, height: 37 }}
                        >
                          {priceTypeArray &&
                            priceTypeArray.map((item) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={item}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <div className={classes.selectRoot}>
                      <FormLabel variant="h5" className={classes.formlabel}>
                        Driver Category
                      </FormLabel>
                      <div className={classes.dropdownContainer}>
                        <OutlinedInput
                          value={priceTypeCategory}
                          classes={{ root: classes.inputTwoLine }}
                          onChange={(e) => setPriceTypeCategory(e.target.value)}
                          prevalue={priceTypeCategory}
                          disabled={'disabled'}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <div className={classes.selectRoot}>
                      <FormLabel className={classes.formlabel}>
                        External Driver Name
                      </FormLabel>
                      <OutlinedInput
                        value={externalPriceTypeName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={(e) => setExternalPriceTypeName(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Card>
          <div className={classes.buttonRoot}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleOnSubmit}
              disabled={
                !priceTypeName && !priceType
                  // && calculationType
                  // ? scales == ""
                  ? true
                  : false
                // : true
              }
            >
              {props.loadingAPI ? (
                <BeatLoader
                  color={"rgb(54, 215, 183)"}
                  loading={props.loadingAPI}
                  size={6}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
const mapStateToProps = (state) => {
  return {
    loadingAPI: state.profitOptimizationData.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(createDrivers(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddDrivers);
