import React from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    }
}));

const ViewPricingExternalDataQuery = props => {
    const classes = useStyles();
    const [salesQueryData, setSalesQueryData] = React.useState(sessionStorage.getItem('external-data-query') ? JSON.parse(sessionStorage.getItem('external-data-query')) : []);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/external-data-excel-upload'
                >
                    External Data Format
                </Link>
                <Typography color="textPrimary" variant='h4'>View Query</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="h3" color={'primary'}>
                                    {salesQueryData ? salesQueryData['format_name'] : ''}
                                </Typography>
                            </div>
                            {salesQueryData ?
                                <div
                                >
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Field Name</TableCell>
                                                        <TableCell align='center'>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(salesQueryData).length > 0 && Object.entries(salesQueryData)
                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'org_id' && k != 'organization_id' && v == 1 && k != 'changed_by_id')
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell align='center'> {v} </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {Object.keys(salesQueryData).length > 0 && Object.entries(salesQueryData)

                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'org_id' && k != 'organization_id' && v > 1 && k != 'ID' && k != 'changed_by_id')
                                                        .sort(function (first, second) {
                                                            return first[1] - second[1];
                                                        })
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell align='center'> {v} </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div >
    );
};
export default connect(null, null)(ViewPricingExternalDataQuery);