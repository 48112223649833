import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Fab
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
//import { buildForecast } from '../../redux/actions';
import { getSalesForecastFormatList, salesForecastDataUploadNew, deleteSalesForecastDataQuery } from '../../redux/actions/Forecasting/AuthAction';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import BeatLoader from "react-spinners/BeatLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));
const SalesInvoiceQueryList = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        props.getSalesForecastFormatList();
        sessionStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        if (props.forecastErrorList) {
            setErrorList(props.forecastErrorList)
            if (Object.entries(props.forecastErrorList).length > 0 && props.forecastErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.forecastErrorList])
    const handleDrop = (event, item) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.salesForecastDataUploadNew(data, currentEditableID);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
        setOpen(true)
    }
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        sessionStorage.setItem('sales-forecast-query', JSON.stringify(item))
        history.push({ pathname: '/historical-sales-upload/edit-format/' + item.id, state: "SalesForecastupload" });

    }
    function viewQuery(item) {
        sessionStorage.setItem('sales-forecast-query', JSON.stringify(item))
        history.push({ pathname: '/historical-sales-upload/view-format/' + item.id, state: "SalesForecastupload" });

    }
    function deleteQuery(item) {
        props.deleteSalesForecastDataQuery(item.id);
    }
    function buildForecast() {
        props.buildForecast();
    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>Sales Forecast File Upload</Typography>
                <div
                    style={{ marginRight: 10 }}>
                    {/* <Button
                        style={{
                            marginRight: 10
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<BeenhereIcon />}
                        classes={{
                            startIcon: !isDesktop && classes.startIcon,
                            root: !isDesktop && classes.container
                        }}
                        onClick={() => buildForecast()}

                    >
                        Build Forecast Data
                    </Button> */}
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => history.push({ pathname: '/historical-sales-upload/add-format', state: "SalesForecastupload" })}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                </div>
            </div>
            {props.queryListData && props.queryListData.length > 0 ?
                <TableContainer style={{ marginTop: -20 }}>
                    <Table>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.queryListData
                                .sort(function (a, b) {
                                    return a.id < b.id ? 1 : -1;
                                })
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.id} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                            {props.loading ?
                                                <TableCell width={300} align='center'>
                                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                </TableCell>
                                                :
                                                <TableCell align='center' width={300}>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => editQuery(item)}
                                                        size="large">
                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => viewQuery(item)}
                                                        size="large">
                                                        <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                    <input type='file'
                                                        accept=".xlsx, .xls, .csv"
                                                        id='file' ref={inputFile} style={{ display: 'none' }}
                                                        onChange={(e) => handleDrop(e, item)} />
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => runQuery(item)}
                                                        size="large">
                                                        <PublishIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => deleteQuery(item)}
                                                        size="large">
                                                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                    </IconButton>
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography variant='h4'>
                    No invoice query list added.
                </Typography>
            }
            <Dialog
                fullWidth
                maxWidth="xs"
                open={errorDialogOpen}>
                <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                    <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                </DialogActions>
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>
                    <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        queryListData: state.forecastData.forecastFormatList,
        loading: state.forecastData.loading,
        forecastErrorList: state.forecastData.forecastErrorList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getSalesForecastFormatList: () => dispatch(getSalesForecastFormatList()),
        salesForecastDataUploadNew: (file, currentEditableID) => dispatch(salesForecastDataUploadNew(file, currentEditableID, 'Sales')),
        deleteSalesForecastDataQuery: (id) => dispatch(deleteSalesForecastDataQuery(id, 'salesForecastUpload')),
        // buildForecast: (file) => dispatch(buildForecast(file)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceQueryList);