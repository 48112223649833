import React, { useEffect } from 'react';
import {
    Typography, Breadcrumbs, Divider, Link, Fab,
    TableHead, TableRow, TableContainer, TableBody, IconButton,
    OutlinedInput, Grid, Button, Select, MenuItem, FormLabel, ToggleButton, ToggleButtonGroup, Checkbox,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ErrorOutlineOutlined, NavigateNext, SaveOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createStickers, getBudgetingConfig, getPromoTypes } from '../../../redux/actions';
import { StickerContainer, StickerHeader } from './Components'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        // borderRadius: 10,
        // padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    },
    container: {
        // margin: 20,
        backgroundColor: '#f5f5f5'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    link: {
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        cursor: 'pointer'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    checkBox: {
        display: 'flex',
        marginLeft: 26,
        marginBottom: 25,
        padding: 5
    },
}));

const EditStickers = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation()
    const { className } = props;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [ID, setID] = React.useState('');
    const [data, setData] = React.useState({});
    const [stickerName, setStickerName] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [headerData, setHeaderData] = React.useState({});
    const [isCopy, setIsCopy] = React.useState(false);
    const [takeRate, setTakeRate] = React.useState('');
    const [totalTakerate, setTotalTakerate] = React.useState('');
    const [isTakerate, setIsTakerate] = React.useState('');
    const [isValidate, setIsValidate] = React.useState();

    const page = location.data && location.data.page;

    useEffect(() => {
        if (props.sticker && props.sticker.country)
            setCountry(props.sticker.country)
        if (props.sticker && props.sticker.product_lines)
            setProductType(props.sticker.product_lines)
    }, [props.sticker.country, props.sticker.product_lines])

    useEffect(() => {
        if (props.sticker) {
            setHeaderData(props.sticker)
            setStickerData(props.sticker.sticker_type)
            setData(props.sticker)
            setID(props.sticker.ID)
            setTakeRate(props.sticker.takerate_name)

        }
    }, [props.sticker]);
    function handleHeaderData(value) {
        setHeaderData(value)
        if (value.country != country)
            setCountry(value.country)
    }
    function handleStickerData(value, takerate, total, istakerate, isValidate) {
        setStickerData(value)
        setTakeRate(takerate)
        setTotalTakerate(total)
        setIsTakerate(istakerate)
        setIsValidate(isValidate)
    }
    function handleStickerDesc(desc) {
        setStickerName(desc)
    }
    function handleSave() {
        delete headerData.countries
        var formData = {
            ...headerData,
            'ID': ID,
            "sticker_type": stickerData,
            "takerate_name": takeRate
        }
        props.createSticker(formData, ID, page)
    }

    function handleCopy(e) {
        setIsCopy(e)
    }

    return (
        <div className={classes.root}>
            <div>
                <StickerHeader onSave={handleSave} /*configData={productLinesList}*/ defaultDivison={productType} isValidate={isValidate}
                    onChange={handleHeaderData} stickerName={stickerName} data={data} page={'sticker_bank_edit'} takerate={totalTakerate} isTakerate={isTakerate} />
            </div>
            <div className={classes.container} >
                <div className={classes.checkBox}>
                    <Checkbox
                        checked={isCopy}
                        onChange={(e) => setIsCopy(e.target.checked)}
                        color='primary'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Typography style={{ marginTop: 8 }} variant='h4' color='black'>Copy Options(OR)</Typography>
                </div>
                <StickerContainer country={country} productLines={productType} isCopy={isCopy} onCopy={handleCopy} onChange={handleStickerData} getSticker={handleStickerDesc} data={data} mode='edit-sticker' />
            </div>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        //  getConfigData: () => dispatch(getBudgetingConfig()),
        createSticker: (data, id, page) => dispatch(createStickers(data, 'edit', id, page)),
        getPromotionTypes: (country, productLines) => dispatch(getPromoTypes(country, productLines)),
    }
}

const mapStateToProps = state => {
    return {
        // configData: state.calculationAnalysisData.budgetData,
        sticker: state.promotionData.stickerData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStickers);