import React from 'react';
import { DailySimulationForm } from './containers';

const DailySimulation = props => {
    
    return (
        <DailySimulationForm />
    );

};

export default DailySimulation;