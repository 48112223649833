import React, { useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Button,
    Card,
    Grid,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    FormLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { getDefaultValues, addDefaultPostingDate, fetchDefaultPostingDate } from '../../redux/actions';
import Moment from 'moment';
import moment from 'moment-timezone';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        fontSize: theme.typography.h4.fontSize,
        textAlign: 'left',
        paddingLeft: 28
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    gridContainer: {
        width: '100%'
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    textField: {
        marginRight: theme.spacing(1),
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));
const FinancialCloseTab = props => {
    const [CalendarArray, setCalendarArray] = React.useState([]);
    const [postingDate, setPostingDate] = React.useState('');
    const [postingTime, setPostingTime] = React.useState('');
    const [putActive, setPutActive] = React.useState(false);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [currentID, setCurrentID] = React.useState(0);
    const classes = useStyles();
    const { className } = props;
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        props.onLoadingDefault(sessionStorage.getItem('application'));
        props.getDefaultPostingDate();
    }, []);
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'accrual_calendar') {
                    setCalendarArray(item)
                }
            })
        }
    }, [props.dropdownData])
    useEffect(() => {
        if (props.defaultPostingDate && props.defaultPostingDate[0]) {
            if (props.defaultPostingDate[0].posting_date) {
                setPostingDate(props.defaultPostingDate[0].posting_date)
            }
            if (props.defaultPostingDate[0].posting_time) {
                setPostingTime(props.defaultPostingDate[0].posting_time)
            }
            if (props.defaultPostingDate[0].id) {
                setCurrentID(props.defaultPostingDate[0].id)
            }
        }
    }, [props.defaultPostingDate])
    const handleChange = (item) => {
        setSelectedRowName(item);
        setPutActive(true);
    };
    function handlePostingTime(event) {
        setPostingTime(event.target.value.concat(":00"))
        setPutActive(true)
    }
    function handleSubmit(clear) {
        if (clear === 'clear') {
            var data = {
                "posting_date": selectedRowName != 'posting_date' ? postingDate : '',
                "posting_time": selectedRowName != 'posting_time' ? postingTime : '',
                "posting_time_zone": selectedRowName == 'posting_date' ? (postingTime ? moment.tz.guess() ? moment.tz.guess() : '' : '')
                    : (postingDate ? moment.tz.guess() ? moment.tz.guess() : '' : '')
            }
            props.onSubmit(data, currentID, 'clear');
        } else {
            var data = {
                "posting_date": postingDate,
                "posting_time": postingTime,
                "posting_time_zone": moment.tz.guess() ? moment.tz.guess() : ''
            }
            props.onSubmit(data, currentID);
        }
        setPutActive(false)
        setPostingDate('')
        setPostingTime('')
        setSelectedRowName('')
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Financial Close</Typography>
            </div>
            <div
                className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading='Field Name' data={selectedRowName ? (selectedRowName === 'posting_date' ? 'Financial Close Period' : 'Financial Close Time') : 'Your selected field name appears here.'} twoline='true' />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    className={classes.gridContainer}
                                    style={{ padding: "15px 15px 15px" }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} error={false}>
                                        Field Value
                                    </FormLabel>
                                    <div style={{ display: selectedRowName === 'posting_date' ? 'block' : 'none' }}>
                                        <Select
                                            value={postingDate}
                                            onChange={(e) => {
                                                setPostingDate(e.target.value)
                                                setPutActive(true)
                                            }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {CalendarArray && CalendarArray.drop_down_values && CalendarArray.drop_down_values
                                                .filter(item => item != 'Manual')
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </div>
                                    <TextField
                                        id="time"
                                        type="time"
                                        fullWidth
                                        value={postingTime}
                                        onChange={handlePostingTime}
                                        className={classes.textField}
                                        style={{ paddingLeft: 8, width: '98%', display: selectedRowName === 'posting_time' ? 'block' : 'none' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_financial_close-' + sessionStorage.getItem('application')) &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        className={classes.buttonContainer}
                                        style={{ paddingLeft: 20, marginRight: 10, justifyContent: 'center' }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                            classes={{
                                                root: classes.checkIconContainer
                                            }}
                                            disabled={!putActive}
                                            onClick={() => handleSubmit()}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<CheckIcon />}
                                            classes={{
                                                root: classes.checkIconContainer
                                            }}
                                            onClick={() => handleSubmit('clear')}
                                            style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>}
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {props.defaultPostingDate ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell classes={{ root: classes.leftAlign }} style={{ width: '40%' }}>Field Name</TableCell>
                                                    <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('posting_date')}>
                                                    <TableCell classes={{ root: classes.leftAlign }}>
                                                        Financial Close Period
                                                    </TableCell>
                                                    <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                        {props.defaultPostingDate && props.defaultPostingDate[0] && props.defaultPostingDate[0].posting_date ? props.defaultPostingDate[0].posting_date : ''}
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange('posting_time')}>
                                                    <TableCell classes={{ root: classes.leftAlign }}>
                                                        Financial Close Time
                                                    </TableCell>
                                                    <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'uppercase' }} >
                                                        {props.defaultPostingDate && props.defaultPostingDate[0] && props.defaultPostingDate[0].posting_time ? Moment(props.defaultPostingDate[0].posting_time, 'hh:mm:ss').format(("hh:mm a")) : ''}
                                                    </TableCell>
                                                </StyledTableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography variant='h4'>
                                        Loading ...
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        defaultPostingDate: state.financialCloseData.postingDateData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id, type) => dispatch(addDefaultPostingDate(data, id, type)),
        getDefaultPostingDate: (id) => dispatch(fetchDefaultPostingDate(id)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues('Customer Rebate', null, null, null, 'Contract Designer')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FinancialCloseTab);