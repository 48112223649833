import React, { useEffect } from 'react';
import {
    Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Chip,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';
const useStyles = makeStyles(theme => ({
    dialogContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    }
}))
const DeleteDialog = (props) => {
    const classes = useStyles();
    function handleClose() {
        props.onChange('cancel')
    }
    function handleRemove() {
        props.onChange('yes')
    }
    function handleChange() {
        props.onChange('change')
    }
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            fullWidth
            maxWidth={'sm'}
        // style={{ height: 400 }}
        >
            <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                STICKER INFORMATION
            </DialogTitle>
            <DialogContent style={{ height: 200 }}>
                <DialogContentText className={classes.dialogContent}>
                    <Chip
                        id={'sticker'}
                        label={props.sticker}
                        variant="outlined"
                        sx={{ backgroundColor: 'white' }}
                        icon={<CircleIcon style={{ color: props.color }} size='small' />}
                    />
                    <Typography variant='h4' color='grey'>{props.model}-{props.location}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {/* <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button> */}
                <Button variant='contained' onClick={handleRemove}>Remove Sticker</Button>
                <Button variant='outlined' onClick={handleChange}>Change Sticker</Button>
            </DialogActions>
        </Dialog>)
}
export default DeleteDialog