import React from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, Divider, Typography } from "@mui/material";
import Logo from "./components/Logo";
import clsx from "clsx";
import {
  DashIcon,
  AnalyticsIcon,
  CalculationIcon,
  ConfigIcon,
  ContractIcon,
  DataSetupIcon,
  DataSetupTransactionIcon,
  FinancialIcon,
  OperationalIcon,
  HourGlassIcon,
} from "../../Icons";
import { SideBarNavBS, BSNavbar } from "./components/SidebarNav";
import Profile from "./components/Profile";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 209,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
  },
  icon: {
    color: theme.palette.icon.active,
  },
  divider: {
    background: theme.palette.border.divider,
    margin: "20px 0px 6px 0px",
  },
  applauncher: {
    textAlign: "center",
    color: "white",
  },
  disable: {
    pointerEvents: "none",
    opacity: 0.7,
  },
}));
const SideBar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const pages = [
    {
      title: "Dashboard",
      icon: <DashIcon />,
      href: "/dashboard",
      displayName: "Dashboard",
    },
    {
      title: "Contracts",
      icon: <ContractIcon />,
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_contract-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_contracts-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_contract-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_contract-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName:
        localStorage.getItem("application") === "Sales Commission"
          ? "Incentive Plan"
          : "Contracts",
    },
    {
      title: "Calculation Simulation",
      icon: <CalculationIcon />,
      href: "/calculation-simulation",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_calc_sim-" + localStorage.getItem("application")
            )
          ? false
          : "hide",
      displayName: "Calculation Simulation",
    },
    {
      title: "Supplier Claims",
      icon: <CalculationIcon />,
      href: "/supplier-incoming-claims",
      displayName: "Claims Management ",
    },
    {
      title: "Claims Management",
      icon: <CalculationIcon />,
      href: "/outgoing-claims-post",
      displayName: "Claims Management",
    },
    {
      title: "Accrual Postings",
      icon: <FinancialIcon />,
      href: "#",
      displayName: "Accrual Postings",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_accrual_post-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_accrual_posting-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      title: "Payment Postings",
      icon: <FinancialIcon />,
      href: "#",
      displayName: "Payment Postings",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "run_payment_post-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "approve_payment_posting-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_payment_post-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      title: "Operational Reports",
      icon: <OperationalIcon />,
      href: "/select-operational-reports",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_report-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_report-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_report-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "execute_report-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Operational Reports",
    },
    {
      title: "On Demand Query",
      icon: <OperationalIcon />,
      href: "/dynamic-query",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_report-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_report-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_report-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "execute_report-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "On Demand Query",
    },
    {
      title: "Quota Management",
      icon: <OperationalIcon />,
      href: "/quota-management",
      displayName: "Quota Management",
      hidden:
        (localStorage.getItem("application") === "Sales Commission" &&
          secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_report-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_quota-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_quota-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_quota-" + localStorage.getItem("application")
              ))) ||
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_quota-" + localStorage.getItem("application")
            ) &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + localStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + localStorage.getItem("application")
                ))) ||
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_quota-" + localStorage.getItem("application")
            ) &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + localStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + localStorage.getItem("application")
                ))) ||
          (localStorage.getItem("application") === "Supplier Rebate" &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + localStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + localStorage.getItem("application")
                ))) ||
          (localStorage.getItem("application") === "Customer Rebate" &&
            secureLocalStorage.getItem("dYtz") &&
            secureLocalStorage.getItem("dYtz").role &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            secureLocalStorage.getItem("dYtz").role.rights &&
            (secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_report-" + localStorage.getItem("application")
              ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "put_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "post_quota-" + localStorage.getItem("application")
                ) ||
              secureLocalStorage
                .getItem("dYtz")
                .role.rights.includes(
                  "get_quota-" + localStorage.getItem("application")
                )))
          ? false
          : "hide",
    },
    {
      title: "Performance Metric",
      icon: <OperationalIcon />,
      href: "/performance-metric",
      displayName: "Performance Metric",
      hidden:
        localStorage.getItem("application") === "Sales Commission" &&
          secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "get_performance_metric-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_performance_metric-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_performance_metric-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
    },
    {
      title: "Performance Weightage",
      icon: <OperationalIcon />,
      href: "/performance-metric-weightage",
      displayName: "Performance Weightage",
      hidden:
        localStorage.getItem("application") === "Sales Commission" &&
          secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_performance_metric_weightage-" +
              localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_performance_metric_weightage-" +
                localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_performance_metric_weightage-" +
                localStorage.getItem("application")
              ))
          ? false
          : "hide",
    },

    {
      title: "Formula Lab",
      icon: <OperationalIcon />,
      href: "/formula-lab",
      displayName: "Formula Lab",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_formula-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "put_formula-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_formula-" + localStorage.getItem("application")
              ))
          ? localStorage.getItem("application") === "Customer Chargeback"
            ? "hide"
            : false
          : "hide",
    },
    {
      title: "Exception Management",
      icon: <OperationalIcon />,
      href: "/exception-management",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "put_exception-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "post_exception-" + localStorage.getItem("application")
              ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_exception-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Exception Management",
    },
    {
      title: "Potential Action Items",
      icon: <ContractIcon />,
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "get_contracts-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_incentive_plan_setup-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Potential Action Items",
    },
    {
      title: "Batch Job",
      icon: <ContractIcon />,
      href: "#",
      hidden:
        secureLocalStorage.getItem("dYtz") &&
          secureLocalStorage.getItem("dYtz").role &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          secureLocalStorage.getItem("dYtz").role.rights &&
          (secureLocalStorage
            .getItem("dYtz")
            .role.rights.includes(
              "post_batch_job-" + localStorage.getItem("application")
            ) ||
            secureLocalStorage
              .getItem("dYtz")
              .role.rights.includes(
                "get_batch_job-" + localStorage.getItem("application")
              ))
          ? false
          : "hide",
      displayName: "Batch Job",
    },
    {
      title: 'Query Field',
      icon:
          <OperationalIcon />,
      href: '/query-field',
      displayName: 'Query Field',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights  ? sessionStorage.getItem('application') !== 'Customer Rebate' ? 'hide': false : 'hide',        
  }
    // {
    //     title: 'Syncfusion',
    //     icon:
    //         <ContractIcon />,
    //     href: 'sync-fusion',
    //     displayName: 'Syncfusion'
    // }
  ];
  const superUserPages = [
    {
      title: "Designer",
      icon: <ConfigIcon />,
      href: "/configuration",
    },
    {
      title: "Access",
      icon: <DashIcon />,
      href: "/access",
    },
    {
      title: "Contract Customisation",
      icon: <DashIcon />,
      href: "/contract-customisation",
    },
  ];

  return (

    // <div>
    //   {localStorage.getItem("application") === "Pricing" ?

        <BSNavbar  pages={pages}  superUserPages={superUserPages}/>

    //     :
    //     <SideBarNavBS
    //       className={classes.nav}
    //       pages={pages}
    //       superUserPages={superUserPages}
    //     />
    //   }
    // </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
