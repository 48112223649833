import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, FormLabel, Select,
    ListItemText, MenuItem, Checkbox, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { getClaimError, runDisputedClaim, getSupplierMasterData, getMaterialMasterData, getCustomerMasterData, getOutgoingClaims, downloadClaimSubmissions } from '../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LoadingOverlay from 'react-loading-overlay';
import { RotateLoader, HashLoader } from "react-spinners";
import MomentUtils from '@date-io/moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
        marginTop: 5,
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    calendarLeftGridContainer: {
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        padding: '0px 45px 0px 10px'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        paddingBottom: '1rem'
        // marginTop: 25
    },
}));

const ClaimSubmissions = props => {
    const classes = useStyles();
    const { className } = props;
    const [claimDateStart, setClaimDateStart] = React.useState(null);
    const [claimDateEnd, setClaimDateEnd] = React.useState(null);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [rejectionReason, setRejectionReason] = React.useState([]);
    const [claimStatus, setClaimStatus] = React.useState([]);
    const [claimNumber, setClaimNumber] = React.useState([]);
    const status = ['New', 'Rejected']
    const handleClear = () => {
        setBillingDateEnd(null)
        setBillingDateStart(null)
        setClaimDateEnd(null)
        setClaimDateStart(null)
        setSupplierNumber([])
        setCustomerNumber([])
        setRejectionReason([])
        setClaimStatus([])
        setClaimNumber([])

    }
    function handleOpen() {
        setSelected(rejectionReason)
        setListOpen(true)
    }
    function handleOpenStatus() {
        setSelectedStatus(claimStatus)
        setListOpenClaimStatus(true)
    }
    function handleOpenSupplier() {
        setSelectedSupplier(supplierNumber)
        setListOpenSupplier(true)
    }
    function handleOpenMaterial() {
        setSelectedMaterial(materialNumber)
        setListOpenMaterial(true)
    }
    function handleOpenCustomer() {
        setSelectedCustomer(customerNumber)
        setListOpenCustomer(true)
    }
    function handleOpenClaimNumber() {
        setSelectedClaim(claimNumber)
        setListOpenClaimNumber(true)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,


    }
    var formData = {
        "billing_date": billingDateEnd ? { "start_date": Moment(billingDateStart).format('YYYY-MM-DD'), "end_date": Moment(billingDateEnd).format('YYYY-MM-DD') }
            : billingDateStart ? { "start_date": Moment(billingDateStart).format('YYYY-MM-DD'), "end_date": Moment(billingDateStart).format('YYYY-MM-DD') } : null,
        "claim_date": claimDateEnd ? { "start_date": Moment(claimDateStart).format('YYYY-MM-DD'), "end_date": Moment(claimDateEnd).format('YYYY-MM-DD') }
            : { "start_date": Moment(claimDateStart).format('YYYY-MM-DD'), "end_date": Moment(claimDateStart).format('YYYY-MM-DD') },
        "supplier_number": supplierNumber,
        "material_number": materialNumber,
        "customer_number": customerNumber,
        "rejection_reason": rejectionReason,
        "claim_status": claimStatus,
        "claim_number": claimNumber.length > 0 ? claimNumber.join().split(',') : []
    }
    const handleRun = () => {
        props.onsubmit(formData);
    }

    const [listOpen, setListOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [listOpenSupplier, setListOpenSupplier] = React.useState('');
    const [listOpenMaterial, setListOpenMaterial] = React.useState('');
    const [selectedSupplier, setSelectedSupplier] = React.useState([]);
    const [selectedMaterial, setSelectedMaterial] = React.useState([]);
    const [listOpenCustomer, setListOpenCustomer] = React.useState('');
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [listOpenClaimStatus, setListOpenClaimStatus] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState([]);
    const [listOpenClaimNumber, setListOpenClaimNumber] = React.useState('');
    const [selectedClaim, setSelectedClaim] = React.useState([]);

    useEffect(() => {
        props.getClaimError();
        props.getSupplierMasterData();
        props.getCustomerMasterData();
        props.getMaterialMasterData();
        props.getOutgoingClaims()
    }, [])
    const [claimErrArr, setClaimErrArr] = React.useState([]);
    useEffect(() => {
        if (props.claimErrorList && props.claimErrorList[0] && props.claimErrorList[0]['rejection_reason']) {
            const unique = props.claimErrorList[0]['rejection_reason']
                .map(e => e['claim_error'])

            const filtered = unique.filter((item, index) => unique.indexOf(item) === index)
            setClaimErrArr(filtered);
        }
    }, [props.claimErrorList])

    const handleRejectionReason = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected)
    };
    const handleClaimStatus = (event, value, selectedList, type) => {
        let newSelected = [];
        const selectedIndex = selectedList.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedList, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedList.slice(1));
        } else if (selectedIndex === selectedList.length - 1) {
            newSelected = newSelected.concat(selectedList.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedList.slice(0, selectedIndex),
                selectedList.slice(selectedIndex + 1),
            );
        }
        if (type == 'claim status')
            setSelectedStatus(newSelected)
        if (type == 'claim number')
            setSelectedClaim(newSelected)
    };

    const handleSupplierNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedSupplier.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedSupplier, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedSupplier.slice(1));
        } else if (selectedIndex === selectedSupplier.length - 1) {
            newSelected = newSelected.concat(selectedSupplier.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedSupplier.slice(0, selectedIndex),
                selectedSupplier.slice(selectedIndex + 1),
            );
        }
        setSelectedSupplier(newSelected)
    };
    const handleMaterialNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedMaterial.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedMaterial, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedMaterial.slice(1));
        } else if (selectedIndex === selectedMaterial.length - 1) {
            newSelected = newSelected.concat(selectedMaterial.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedMaterial.slice(0, selectedIndex),
                selectedMaterial.slice(selectedIndex + 1),
            );
        }
        setSelectedMaterial(newSelected)
    };
    const handleCustomerNumber = (event, value) => {
        let newSelected = [];
        const selectedIndex = selectedCustomer.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCustomer, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCustomer.slice(1));
        } else if (selectedIndex === selectedCustomer.length - 1) {
            newSelected = newSelected.concat(selectedCustomer.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCustomer.slice(0, selectedIndex),
                selectedCustomer.slice(selectedIndex + 1),
            );
        }
        setSelectedCustomer(newSelected)
    };


    function onAdd(item) {
        if (item === 'supplier') {
            setSupplierNumber(selectedSupplier)
            setListOpenSupplier(false)
        } else if (item === 'material') {
            setMaterialNumber(selectedMaterial)
            setListOpenMaterial(false)
        } else if (item === 'customer') {
            setCustomerNumber(selectedCustomer)
            setListOpenCustomer(false)
        } else if (item === 'claim status') {
            setClaimStatus(selectedStatus)
            setListOpenClaimStatus(false)
        } else if (item === 'claim number') {
            setClaimNumber(selectedClaim)
            setListOpenClaimNumber(false)
        }
        else {
            setRejectionReason(selected)
            setListOpen(false)
        }
    }
    function onCancel(item) {
        if (item === 'supplier') {
            setListOpenSupplier(false)
            setSelectedSupplier([])
        } else if (item === 'material') {
            setListOpenMaterial(false)
            setSelectedMaterial([])
        } else if (item === 'customer') {
            setListOpenCustomer(false)
            setSelectedCustomer([])
        } else if (item === 'claim status') {
            setSelectedStatus([])
            setListOpenClaimStatus(false)
        } else if (item === 'claim number') {
            setSelectedClaim([])
            setListOpenClaimNumber(false)
        } else {
            setListOpen(false)
            setSelected([])
        }
    }
    const [claimNumberArray, setClaimNumberArray] = React.useState([]);
    const [supplierNameArray, seSupplierNameArray] = React.useState([]);
    const [customerNameArray, seCustomerNameArray] = React.useState([]);
    const [materialNameArray, setMaterialNameArray] = React.useState([]);
    const [supplierMasterArrayMaterialNumber, setSupplierMasterArrayMaterialNumber] = React.useState([]);
    const [customerMasterArrayMaterialNumber, setCustomerMasterArrayMaterialNumber] = React.useState([]);
    const [materialMasterArrayMaterialNumber, setMaterialMasterArrayMaterialNumber] = React.useState([]);
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setSupplierMasterArrayMaterialNumber(tempArray)
            seSupplierNameArray(tempNameArray)
        }
    }, [props.supplierMasterData])

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerMasterArrayMaterialNumber(tempArray)
            seCustomerNameArray(tempNameArray)
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
            })
            setMaterialMasterArrayMaterialNumber(tempArray)
            setMaterialNameArray(tempNameArray)
        }
    }, [props.materialMasterData])

    useEffect(() => {
        if (props.outgoingClaimListData && props.outgoingClaimListData.length > 0) {
            var tempArray = []
            props.outgoingClaimListData.map((item) => {
                tempArray.push(item.claim_number);
            })
            setClaimNumberArray(tempArray)
        }
    }, [props.outgoingClaimListData])
    const handleEmail = () => {
        props.downloadClaimSubmissions(formData, 'email')
    }
    const handleDownloadExcel = () => {
        props.downloadClaimSubmissions(formData)
    }
    const handleEDI = () => {
        props.downloadClaimSubmissions(formData, 'EDI')
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 16 }}> Claim Submissions </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required>Claim Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 15,
                                                        width: '100%',
                                                        border: '1px solid #E0E0E0',
                                                    }
                                                }}
                                                value={claimDateStart}
                                                onChange={e => setClaimDateStart(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                                <div className={classes.dateSecondColumn}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 33,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={claimDateEnd}
                                                onChange={e => setClaimDateEnd(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={claimDateStart ? claimDateStart : ''}
                                                disabled={claimDateStart ? false : true}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dateColumn}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 15,
                                                        width: '100%',
                                                        border: '1px solid #E0E0E0',
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={e => setBillingDateStart(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                                <div className={classes.dateSecondColumn}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 11px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 33,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={e => setBillingDateEnd(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                                disabled={billingDateStart ? false : true}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Supplier Number
                                </FormLabel>
                                <Select
                                    value={supplierNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenSupplier}
                                    onClose={() => setListOpenSupplier(false)}
                                    open={listOpenSupplier}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {supplierMasterArrayMaterialNumber?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSupplierNumber(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedSupplier.indexOf(item) > -1} />
                                                <ListItemText primary={supplierNameArray[supplierMasterArrayMaterialNumber.indexOf(item)] + '(' + item + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('supplier')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('supplier')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    End Customer Number
                                </FormLabel>
                                <Select
                                    value={customerNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenCustomer}
                                    onClose={() => setListOpenCustomer(false)}
                                    open={listOpenCustomer}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {customerMasterArrayMaterialNumber?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleCustomerNumber(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedCustomer.indexOf(item) > -1} />
                                                <ListItemText primary={customerNameArray[customerMasterArrayMaterialNumber.indexOf(item)] + ' (' + item + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('customer')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('customer')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Material Number
                                </FormLabel>
                                <Select
                                    value={materialNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenMaterial}
                                    onClose={() => setListOpenMaterial(false)}
                                    open={listOpenMaterial}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {materialMasterArrayMaterialNumber?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleMaterialNumber(event, item)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedMaterial.indexOf(item) > -1} />
                                                <ListItemText primary={materialNameArray[materialMasterArrayMaterialNumber.indexOf(item)] + '(' + item + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('material')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('material')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Claim Number
                                </FormLabel>
                                <Select
                                    value={claimNumber}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenClaimNumber}
                                    onClose={() => setListOpenClaimNumber(false)}
                                    open={listOpenClaimNumber}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {claimNumberArray?.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleClaimStatus(event, item, selectedClaim, 'claim number')}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selectedClaim.indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('claim number')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('claim number')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} required>
                                    Claim Status
                                </FormLabel>
                                <Select
                                    value={claimStatus}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpenStatus}
                                    onClose={() => setListOpenClaimStatus(false)}
                                    open={listOpenClaimStatus}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {status
                                        .map((item) => {
                                            return (
                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }} onClick={(event) => handleClaimStatus(event, item, selectedStatus, 'claim status')}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selectedStatus.indexOf(item) > -1} />
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            )
                                        })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={() => onCancel('claim status')}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('claim status')} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: 15 }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Rejection Reason
                                </FormLabel>

                                <Select
                                    value={rejectionReason}
                                    displayEmpty
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    onOpen={handleOpen}
                                    onClose={() => setListOpen(false)}
                                    open={listOpen}
                                    renderValue={(user) => {
                                        let name = '';
                                        name = user.toString().replace(/_/g, ' ');
                                        return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                    }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    multiple
                                >
                                    {props.claimErrorList[0] && props.claimErrorList[0]['rejection_reason']?.map((item) => {
                                        return (
                                            <MenuItem value={item.rejection_reason_code} key={item.rejection_reason_code} style={{ textTransform: 'capitalize' }} onClick={(event) => handleRejectionReason(event, item.rejection_reason_code)}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected.indexOf(item.rejection_reason_code) > -1} />
                                                <ListItemText primary={item.rejection_reason_code + ' (' + item.rejection_reason_description + ')'} />
                                            </MenuItem>
                                        )
                                    })}
                                    <div className={classes.dropdownAction}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>
                            </Grid>


                        </Grid>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear}>
                            Clear
                        </Button>
                        <Button variant="contained" color="primary"
                            className={classes.button} twoline='true' onClick={handleRun}
                            disabled={claimDateStart && (claimStatus.length > 0 ? true : false) ? false : true}
                        >
                            {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                            }
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} twoline='true' onClick={handleDownloadExcel}
                            disabled={claimDateStart && (claimStatus.length > 0 ? true : false) ? false : true}
                        > {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Download'
                            }
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} twoline='true' onClick={handleEDI}
                            disabled={claimDateStart && (claimStatus.length > 0 ? true : false) ? false : true}
                        > {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'EDI'
                            }
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} twoline='true' onClick={handleEmail}
                            disabled={claimDateStart && (claimStatus.length > 0 ? true : false) ? false : true}
                        > {
                                props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Email'
                            }
                        </Button>
                    </div>
                </form>
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onsubmit: (formData) => dispatch(runDisputedClaim(formData, 'Claim Submission')),
        downloadClaimSubmissions: (data, type, email) => dispatch(downloadClaimSubmissions(data, type, email)),
        getClaimError: () => dispatch(getClaimError()),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: (pagination, limit) => dispatch(getMaterialMasterData(pagination, 0)),
        getOutgoingClaims: () => dispatch(getOutgoingClaims()),
    }
}
const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        claimErrorList: state.calculationAnalysisData.claimError,
        supplierMasterData: state.customerData.supplierMasterData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
        outgoingClaimListData: state.initialData.outgoingClaimListData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSubmissions);