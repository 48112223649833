import React, { useEffect } from 'react';
import {
    Grid, Button,
    Typography, FormLabel,
    Select, MenuItem, OutlinedInput, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getPriceCalForList, runCustomerMargin, getQualifierConfig } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../components/Inputs";
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import RotateLoader from "react-spinners/RotateLoader";
import HashLoader from 'react-spinners/HashLoader'
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 7
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        heigh: 38,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
    chipContainers: {
        marginBottom: 17
    }
}));

const CustomerMargin = props => {
    const classes = useStyles();
    const { className } = props;
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [quantity, setQuantity] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [distributionChannel, setDistributionChannel] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [plant, setPlant] = React.useState('');
    const [salesOrganization, setSalesOrganization] = React.useState('');
    const [optionalFieldArray, setOptionalFieldArray] = React.useState([]);
    const [companyCode, setcompanyCode] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getConfigData(1, 10);
        setPricingDateStart(Moment.utc(new Date().getMonth() + 1 + "/" + (new Date().getDate()) + "/" + new Date().getFullYear()))
        props.getQualifierConfig(1, 0);
    }, []);
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push(e.formula_name)
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {
        var newArray = [];
        if (props.optionalFieldData && props.optionalFieldData.records) {
            props.optionalFieldData.records.map(e => {
                newArray.push(e.qualifier_key)
            })
            setOptionalFieldArray(newArray)
        }
    }, [props.optionalFieldData]);
    const [open, setOpen] = React.useState(false);

    function handleCustomerNumber(newValue) {
        // setCustomerNumber([...customerNumber, newValue])
        setCustomerNumber(newValue)
    }
    function handleCustomerNumberDelete(newValue) {
        var deletedCustomerNumber = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomerNumber)
    }
    function handleMaterialNumber(newValue) {
        // setMaterialNumber([...materialNumber, newValue])
        setMaterialNumber(newValue)
    }
    function handleMaterialNumberDelete(newValue) {
        var deletedMaterialNumber = materialNumber.filter(item => item !== newValue)
        setMaterialNumber(deletedMaterialNumber)
    }

    function handleQuantityAdd(newValue) {
        // setQuantity([...quantity, newValue]);
        setQuantity(newValue)
    }
    function handleQuantityDelete(newValue) {
        var deletedQuantity = quantity.filter(item => item !== newValue)
        setQuantity(deletedQuantity)
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    function handleTagsAdd(newValue) {
        // setTags([...tags, newValue]);
        setTags(newValue);
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }
    const handleClear = () => {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setQuantity([]);
        setPriceCalcFormula('');
        setPricingDateStart(null);
        setSalesOrganization('');
        setcompanyCode('');
        setFlexAttribute1('');
        setFlexAttribute2('');
        setFlexAttribute3('');
        setFlexAttribute4('');
        setDistributionChannel('');
        setDivision('');
        setPlant('');
        setTags([]);
    }
    const handleClickOpen = () => {
        setOpen(true)

    };
    var formData = {
        "customer_number": customerNumber.length > 0 ? customerNumber.toString() : null,
        "material_number": materialNumber.length > 0 ? materialNumber.toString() : null,
        "formula_name": priceCalcFormula.length > 0 ? priceCalcFormula.toString() : null,
        "quantity": quantity.length > 0 ? parseInt(quantity).toString() : null,
        "pricing_date": pricingDateStart ? Moment.utc(pricingDateStart).format("YYYY-MM-DD") : null,
        "aggregate": 'customer_number',
        "sales_organization": salesOrganization.length > 0 ? salesOrganization.toString() : null,
        "company_code": companyCode.length > 0 ? companyCode.toString() : null,
        "distribution_channel": distributionChannel.length > 0 ? distributionChannel.toString() : null,
        "division": division.length > 0 ? division.toString() : null,
        "plant": plant.length > 0 ? plant.toString() : null,
        "flex_attribute1": flexAttribute1.length > 0 ? flexAttribute1.toString() : null,
        "flex_attribute2": flexAttribute2.length > 0 ? flexAttribute2.toString() : null,
        "flex_attribute3": flexAttribute3.length > 0 ? flexAttribute3.toString() : null,
        "flex_attribute4": flexAttribute4.length > 0 ? flexAttribute4.toString() : null,
        "tags": tags.length > 0 ? tags.toString() : null
    };
    const handleDialog = (bool) => {
        setOpen(false);
        props.onSubmit(formData);
        handleClear();
    }


    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h2">
                        Portfolio Margin
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginBottom: 14 }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerNumber}
                                    onAdd={(chips) => handleCustomerNumber(chips)}
                                    onDelete={(chip) => handleCustomerNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipContainers}>
                                    <ChipInput data={customerNumber} onAdd={handleCustomerNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Number
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleMaterialNumber(chips)}
                                    onDelete={(chip) => handleMaterialNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipContainers}>
                                    <ChipInput data={materialNumber} onAdd={handleMaterialNumber} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '-14px', marginBottom: 10 }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Quantity
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={quantity}
                                    onAdd={(chips) => handleQuantityAdd(chips)}
                                    onDelete={(chip) => handleQuantityDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipContainers}>
                                    <ChipInput data={quantity} onAdd={handleQuantityAdd} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '-14px' }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Pricing Calculation Formula
                                </FormLabel>
                                <Select
                                    value={priceCalcFormula}
                                    onChange={(e) => handlePriceCalFormula(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginBottom: -12 }}
                            ><FormLabel required>Pricing Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 7,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: 34
                                                }
                                            }}
                                            value={pricingDateStart}
                                            onChange={handlePricingDateStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '-2px', marginBottom: -12 }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Tags
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    onAdd={(chips) => handleTagsAdd(chips)}
                                    onDelete={(chip) => handleTagsDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div className={classes.chipContainers}>
                                    <ChipInput data={tags} onAdd={handleTagsAdd} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('sales_organization') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Sales Organization
                                </FormLabel>
                                <OutlinedInput
                                    value={salesOrganization}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setSalesOrganization(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('company_code') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Company Code
                                </FormLabel>
                                <OutlinedInput
                                    value={companyCode}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setcompanyCode(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('distribution_channel') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Distribution Channel
                                </FormLabel>
                                <OutlinedInput
                                    value={distributionChannel}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setDistributionChannel(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('division') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Division
                                </FormLabel>
                                <OutlinedInput
                                    value={division}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setDivision(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('plant') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Plant
                                </FormLabel>
                                <OutlinedInput
                                    value={plant}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setPlant(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 1
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute1}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute1(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 2
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute2}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute2(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute3') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 3
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute3}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute3(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute4') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 4
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute4}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute4(e.target.value)}
                                />
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    pricingDateStart
                                        ? false : true}

                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Customer Margin?' handleDialog={handleDialog} />
            </div >
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.pricingData.priceCalForListData,
        customerMarginData: state.pricingData.customerMarginData,
        optionalFieldData: state.pricingData.qualifierConfigData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit)),
        onSubmit: (formData) => dispatch(runCustomerMargin(formData)),
        getQualifierConfig: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerMargin);