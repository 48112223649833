import React, { useEffect, useRef } from 'react';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";
import { registerLicense } from '@syncfusion/ej2-base';

const Syncfusion = props => {
    // registerLicense("Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiW39ZcHVXT2NVUUV1Ww==")
    registerLicense("Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxccnRSR2FZUEFyV0M=")


    // registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlljS39Vc0RmX3dWeHY=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpTdERlXH5bdn1VRWI=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdkdiX3pfdXJcR2he;MTU4ODYyNEAzMjMxMmUzMTJlMzMzNUdnNDRpdXQ3WTd2WlBzVVJMK0dyQ1lnVmxxU3dQMStEeWVPbEgxMUZPaUk9;MTU4ODYyNUAzMjMxMmUzMTJlMzMzNU4zVkgwU0Z6WnhkQVZKRWkrQnRrdWJVdlBoTzVGRGU1UG95TktJY1hxZW89;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckVhWHlbcXFTT2lYUw==;MTU4ODYyN0AzMjMxMmUzMTJlMzMzNU03eXRuVHpkN1gweWFYdjBpYjd6U3RJeExsNnlLZTFSbERmQ3NhWEJrNDQ9;MTU4ODYyOEAzMjMxMmUzMTJlMzMzNWdqcGRFUGQ2YVJLV0xCcTExTW9TT1Rkb2J4MFNSdzYzak9oN2RsYUl2V2c9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdkdiX3pfdXNVQWJe;MTU4ODYzMEAzMjMxMmUzMTJlMzMzNW5MZEFTK2FtL1FTaW9uM0RKVTNPM0ZaMlF4VGRBMUJZczdtbVVzZ2J6UEE9;MTU4ODYzMUAzMjMxMmUzMTJlMzMzNUYyS0xTM1hxNDV5eCtCZmoxQmFvS1hTVFgzdVh1U0NwRXdhYVdYamVVcFE9;MTU4ODYzMkAzMjMxMmUzMTJlMzMzNU03eXRuVHpkN1gweWFYdjBpYjd6U3RJeExsNnlLZTFSbERmQ3NhWEJrNDQ9');
    return (
        <div style={{ height: '100vh' }}>
            <SpreadsheetComponent />
        </div>
    );

};

export default Syncfusion;