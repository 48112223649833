import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Button, FormLabel, Select, MenuItem, Breadcrumbs, Link, Typography, Dialog,
    OutlinedInput, IconButton,
    DialogActions, Grid, ListItem, ListItemText, Input, Checkbox
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';
import { TableArray, FieldArray, AggregationLevel } from './FormulaFields'
import { createPriceFormula, getPriceFormulaById } from '../../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import ClearIcon from '@mui/icons-material/Clear';
import { FixedSizeList } from 'react-window';


const useStyles = makeStyles(theme => ({

    select: {
        width: '100%',

    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    button: {
        marginLeft: 15,
        width: 80
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 50,
        padding: 0,
        margin: 10,
        backgroundColor: 'white',
        width: '100%',
        overflowY: 'auto',
        overflowX: "hidden",
        textOverflow: "ellipsis",

    },
    input: {
        width: '100%',
        paddingLeft: 10,

    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        textAlign: 'center',
        height: 'auto'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 30,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 6,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    table: {
        border: 'none'
    },
    centerText: {
        textAlign: 'center',
        border: '1px solid #e2e2e2',
        cursor: 'pointer'
    },
    centerTextAlign: {
        textAlign: 'center'
    },
    IconButton: {
        marginLeft: 10
    },
    headerDiv: {
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        paddingTop: 10,
        paddingBottom: 10
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },

}));
const operationData = [
    { key: '(', value: '(' },
    { key: ')', value: ')' },
    { key: '+', value: '+' },
    { key: '-', value: '-' },
    { key: '/', value: '/' },
    { key: '*', value: '*' },
];


const AddPricingFormula = props => {
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    useEffect(() => {

        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getFormulaList(appId);
    }, [])

    const [aggregationLevel, setAggregationLevel] = React.useState([]);
    const [constant, setConstant] = React.useState('');
    const [decimal, setDecimal] = React.useState('');
    const [formulaName, setFormulaName] = React.useState('');
    const [formula, setFormula] = React.useState([]);
    const [formulaAPI, setFormulaAPI] = React.useState([]);
    const [formulaType, setFormulaType] = React.useState([]);
    const [tableName, setTableName] = React.useState('sales_data');
    const [open, setOpen] = useState('');
    const [selected, setSelected] = React.useState([]);
    const [dialogue, setDialogue] = useState(true);
    const [editID, setEditID] = useState(0);

    const isAllSelected =
        AggregationLevel && AggregationLevel.length > 0 && selected.length === AggregationLevel.length;

    useEffect(() => {
        var temp = []
        if (props.formulaList) {
            if (props.formulaList.formula_type) {
                setFormulaType(props.formulaList.formula_type);

            }
            if (props.formulaList.formula)
                setFormulaAPI(props.formulaList.formula)
            if (props.formulaList.formula_name)
                setFormulaName(props.formulaList.formula_name)
            if (props.formulaList.aggregation_level) {
                setAggregationLevel(props.formulaList.aggregation_level)
                setSelected(props.formulaList.aggregation_level)
            }
            if (props.formulaList.formula && props.formulaList.formula_type) {
                props.formulaList.formula_type
                    .map((item, index) => {
                        if (item == "op" || item == "const")
                            temp.push(props.formulaList.formula[index])
                        else {
                            var label = TableArray.filter(value => value.key == item)
                            var field = label[0] && label[0].name && label[0].name.replace(/_/g, ' ') + "[" + props.formulaList.formula[index].replace(/_/g, ' ') + "]"
                            temp.push(field)
                        }
                    })
            }
        }
        setFormula(temp)

    }, [props.formulaList]);




    const handleAdd = (type, value) => {
        var field;
        if (type == 'field') {
            var label = TableArray.filter(item => item.key == tableName)
            field = label[0].name.replace(/_/g, ' ') + "[" + value.replace(/_/g, ' ') + "]"
            setFormulaType([...formulaType, tableName])
        }
        else if (type == 'operator') {
            field = value
            setFormulaType([...formulaType, "op"])
        }
        else {
            field = value
            setFormulaType([...formulaType, "const"])
            setConstant('');
        }
        setFormula([...formula, field])
        setFormulaAPI([...formulaAPI, value])

    };

    const handleFormulaDelete = (newValue, index) => {
        setFormula(formula.filter((item, i) => i !== index));
        setFormulaAPI(formulaAPI.filter((item, i) => i !== index));
        setFormulaType(formulaType.filter((item, i) => i !== index))

    }
    const handleConstant = (e) => {
        setConstant(e.target.value);
    }
    const handleSubmitFormula = () => {
        var data = {
            "formula_name": formulaName,
            "formula": formulaAPI,
            "formula_type": formulaType,
            "aggregation_level": aggregationLevel,
            "decimal_points": parseInt(decimal)
        }
        props.submitFormula(data, editID);
    }
    const handleClear = () => {
        setFormula([]);
        setFormulaAPI([])
        setFormulaType([])
    }
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === AggregationLevel.length ? [] : AggregationLevel);
        }
        else
            setSelected(newSelected)

    }
    function onAdd() {

        setAggregationLevel(selected)
        setOpen(false)
        setSelected([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])

    }
    function handleOpen() {
        setSelected(aggregationLevel)
        setOpen(true)
    }


    function renderRow(props) {
        const { index, style } = props;

        return (
            <ListItem style={{ style }} key={index} component="div" disablePadding onClick={() => handleAdd('field', FieldArray[tableName][index])}>
                <ListItemButton >
                    <ListItemText primary={FieldArray[tableName][index].replace(/_/g, ' ')} />
                </ListItemButton>
            </ListItem>
        );
    }
    return (
        <div className={clsx(classes.root, className)}>
            <Dialog
                onClose={() => setDialogue(false)}
                aria-labelledby="simple-dialog-title"
                open={dialogue}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel style={{ marginRight: 10 }}>
                        Formula Name
                    </FormLabel>
                    <OutlinedInput
                        inputProps={{ min: "0", step: "1" }}
                        classes={{ root: classes.inputTwoLine }}
                        value={formulaName}
                        onChange={(e) => setFormulaName(e.target.value.toUpperCase())}
                    />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={() => history.push('/pricing-formula')}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={() => setDialogue(false)}
                        disabled={formulaName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ padding: 29 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        onClick={() => history.push('/pricing-formula')}
                    >
                        Formula - Price Points
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp;Add Formula</Typography>
                </Breadcrumbs>

                <div style={{ marginTop: 29 }}>
                    <Grid container style={{ marginTop: 12 }}>
                        <div style={{
                            backgroundColor: '#FAFAFA', alignItems: 'center',
                            color: 'white', justifyContent: 'center', flexDirection: 'row', display: 'flex',
                            borderRadius: 5, minHeight: 80, maxHeight: 'auto', width: '100%'
                        }}>

                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{ color: '#0B3662', fontSize: 14, textAlign: 'center' }}>{formulaName}</div>
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        label: classes.chipLabel
                                    }}
                                    allowDuplicates
                                    value={formula}
                                    style={{ textTransform: 'capitalize', minHeight: 80, maxHeight: 'auto', }}
                                    onDelete={(chip, index) => handleFormulaDelete(chip, index)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ diplay: 'flex' }}
                            >


                                <IconButton onClick={handleClear} classes={{ root: classes.IconButton }} size="large">
                                    <ClearIcon />
                                </IconButton>
                                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitFormula}
                                    disabled={formula.length === 0 ? true : false}
                                >
                                    {props.loadingAPI ?
                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Submit'
                                    }
                                </Button>

                            </Grid>

                        </div>
                    </Grid>


                    <Grid
                        md={12}
                        xs={12}
                        container style={{ marginTop: 12, display: 'flex' }}>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div className={classes.headerDiv}>
                                Table Name
                            </div>

                            <Select
                                disableUnderline
                                onChange={(e) => setTableName(e.target.value)}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                value={tableName}
                                style={{ textTransform: 'capitalize' }}
                                className={clsx({
                                    [classes.select]: true
                                })}

                            >
                                <MenuItem value="" style={{ height: 30, textTransform: 'capitalize' }} >
                                    Choose a Table
                                </MenuItem>
                                {TableArray
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                {item.name}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            classes={{ root: classes.gridContainer }}

                        >
                            <div className={classes.headerDiv} style={{ marginLeft: 10 }}>
                                Field Name
                            </div>
                            {tableName && FieldArray[tableName] && FieldArray[tableName].length > 0 ?

                                <FixedSizeList
                                    height={600}
                                    itemSize={16}
                                    itemCount={FieldArray[tableName].length}
                                    overscanCount={5}
                                    style={{ textTransform: 'capitalize', paddingLeft: 10 }}
                                >
                                    {renderRow}
                                </FixedSizeList>
                                :
                                <Typography variant='h4' style={{ padding: '10px 0 0 15px' }}>
                                    There is no fields to show now.
                                </Typography>
                            }
                        </Grid>

                        <Grid
                            item
                            md={4}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div className={classes.headerDiv} style={{ marginLeft: 10 }}>
                                Formula Elements
                            </div>
                            <Grid container spacing={1}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: 20, paddingLeft: 10 }}
                            >
                                <Grid container item xs={12} spacing={3}>
                                    {operationData.map((item) => {
                                        return (
                                            <Grid item xs={4} className={classes.centerText} onClick={() => handleAdd('operator', item.value)}>
                                                {item.value}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            < Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                container spacing={1}
                                style={{ display: 'flex', justifyContent: 'center', padding: 10 }}
                            >
                                <Grid
                                    item
                                    md={8}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <OutlinedInput
                                        value={constant}
                                        style={{ width: '100%', height: 40, marginTop: 10, textAlign: 'center' }}
                                        classes={{ input: classes.centerTextAlign }}
                                        onChange={handleConstant}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Button variant="contained" color="primary" disableElevation fullWidth
                                        style={{ marginTop: 10 }}
                                        onClick={() => handleAdd('const', constant)}
                                        disabled={!constant ? true : false}
                                    >
                                        Add Constant
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}

                            >
                                <div className={classes.headerDiv} style={{ marginLeft: 10 }}>
                                    Aggregation Level
                                </div>

                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={aggregationLevel}
                                    style={{ maxHeight: "50px", height: 36, width: '98%', marginTop: 10, marginLeft: 10, textTransform: 'capitalize' }}
                                    input={<OutlinedInput />}
                                    onOpen={() => handleOpen()}
                                    onClose={() => setOpen(false)}
                                    open={open}
                                    renderValue={(appType) => {
                                        var aggregation = []
                                        appType
                                            .map(item => {
                                                aggregation.push(item.replace(/_/g, ' '))

                                            })
                                        return aggregation.join(", ")
                                    }}
                                    className={classes.multiSelect}

                                >
                                    <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                        <Checkbox
                                            color='primary'
                                            checked={isAllSelected} />
                                        <ListItemText primary='Select All' />
                                    </MenuItem>
                                    {AggregationLevel && AggregationLevel
                                        .map((item) => {
                                            return (
                                                <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                    <Checkbox
                                                        color='primary'
                                                        checked={selected.indexOf(item) > -1} />
                                                    <ListItemText primary={item.replace(/_/g, ' ')} />
                                                </MenuItem>
                                            );
                                        })}
                                    <div className={classes.dropdownbottom}>
                                        <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                            Cancel
                                        </Button>

                                        <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                            Apply
                                        </Button>
                                    </div>


                                </Select>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            </div>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        formulaList: state.pricingData.priceFormulaSingle,
        loadingAPI: state.pricingData.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        submitFormula: (data, id) => dispatch(createPriceFormula(data, id, 'edit')),
        getFormulaList: (id) => dispatch(getPriceFormulaById(id)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPricingFormula);














