// import React, { useEffect, useRef } from 'react';
// import clsx from 'clsx';
// import { makeStyles, useTheme, withStyles } from '@mui/styles';
// import {
//     Breadcrumbs,
//     Typography,
//     Link,
//     useMediaQuery,
//     Table,
//     TableCell,
//     TableHead,
//     TableRow,
//     TableContainer,
//     TableBody,
//     TablePagination,
//     Button
// } from '@mui/material';
// import { SearchInput } from '../../../components';
// import { connect } from 'react-redux';
// import { history } from '../../../components/Helpers';
// import { runFinancialReports, downloadSheet, getDefaultValuesFinancialSimulation } from '../../../redux/actions';
// import { Paper, Input } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import IconButton from '@mui/material/IconButton';
// import CheckIcon from '@mui/icons-material/Check';
// import Moment from 'moment';

// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: theme.palette.white,
//         borderRadius: 10,
//         padding: 29,
//         marginTop: 16
//     },
//     link: {
//         color: theme.palette.text.primary,
//         opacity: 0.3,
//         textDecoration: 'none',
//         border: 0,
//         fontSize: 16,
//         paddingRight: 10
//     },
//     row: {
//         flexDirection: 'row',
//         display: 'flex',
//         justifyContent: 'space-between',
//         marginBottom: 10
//     },
//     tableCell: {
//         paddingLeft: 18
//     },
//     buttonContainer: {
//         display: 'flex',
//         justifyContent: 'flex-end',
//         marginTop: 10,
//         marginBottom: 15
//     },
//     rootSearch: {
//         borderBottomColor: theme.palette.table.border,
//         borderBottomWidth: 1,
//         borderBottomStyle: 'solid',
//         alignItems: 'center',
//         display: 'flex',
//         alignSelf: 'flex-end',
//         borderRadius: 0,
//         padding: 0,
//         minWidth: 200,
//         [theme.breakpoints.down('sm')]: {
//             minWidth: 0,
//             marginTop: 10,
//             marginRight: 15,
//         },
//     },
//     input: {
//         fontSize: 14,
//         flexGrow: 1,
//         lineHeight: '16px',
//         letterSpacing: '-0.05px',
//         paddingLeft: 10,
//     },
//     iconButton: {
//         padding: 0,
//     },
//     icon: {
//         fontSize: 20,
//         color: 'rgba(0, 0, 0, 0.54)'
//     }
// }));

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.table.row,
//             height: 46
//         },
//         '&:nth-of-type(even)': {
//             height: 40
//         }
//     }
// }))(TableRow);
// var enabledFormFields = [];

// // const tableHeads = [
// //     { id: 0, heading: 'Contract Number' },
// //     { id: 1, heading: 'Posting Type' },
// //     { id: 4, heading: 'Billing Date' },
// //     { id: 5, heading: 'Billing Doc Number' },
// //     { id: 6, heading: 'Billing Doc Line' },
// //     { id: 7, heading: 'Sold To Party' },
// //     { id: 8, heading: 'Material Number' },
// //     { id: 9, heading: 'Incentive Basis' },
// //     { id: 10, heading: 'Incentive Rate' },
// //     { id: 11, heading: 'Incentive Amount' },
// //     { id: 12, heading: 'New Incentive Amount' },
// //     { id: 13, heading: 'Old Incentive Amount' },
// //     { id: 14, heading: 'Net Value' },
// //     { id: 15, heading: 'Quantity' },
// //     { id: 16, heading: 'Billed UoM' },
// //     { id: 17, heading: 'Posting Date' },
// //     { id: 18, heading: 'supplier' },
// //     { id: 19, heading: 'Profit Center' },
// //     { id: 20, heading: 'Plant' },
// //     { id: 21, heading: 'Settlement Partner Type' },
// //     { id: 22, heading: 'Settlement Partner Number' },
// //     { id: 23, heading: 'Application Type' },
// //     { id: 24, heading: 'Contract Type' },
// //     { id: 25, heading: 'Calculation Schema' },
// //     { id: 26, heading: 'Material Group' },
// //     { id: 27, heading: 'Posting Document Number' },
// //     { id: 28, heading: 'Material Type' },
// //     { id: 31, heading: 'Product Hierarchy' },
// //     { id: 34, heading: 'Posting Start Date' },
// //     { id: 35, heading: 'Posting End Date' },
// //     { id: 36, heading: 'Approval Code' },
// //     { id: 37, heading: 'Fiscal Year Month' },
// //     { id: 38, heading: 'Adjustment Code' },
// //     { id: 39, heading: 'Calendar Year Month' },
// //     { id: 41, heading: 'Company Code' },
// //     { id: 43, heading: 'Sales Org' },
// //     { id: 44, heading: 'IMA Ineligible' },
// //     { id: 45, heading: 'Customer Chain' },
// //     { id: 47, heading: 'IMA Ineligible Application' },
// //     { id: 48, heading: 'Customer Group' },
// //     { id: 50, heading: 'Billing Type' },
// //     { id: 51, heading: 'supplier Group' },
// //     { id: 53, heading: 'DebitCredit Indicator' },
// //     { id: 55, heading: 'Item Category' },
// //     { id: 58, heading: 'Distribution Channel' },
// //     { id: 59, heading: 'Division' },
// //     { id: 60, heading: 'Sales District' },
// //     { id: 61, heading: 'Sales Group' },
// //     { id: 62, heading: 'Sales Office' },
// //     { id: 63, heading: 'Pricing Date' },
// //     { id: 64, heading: 'Payer' },
// //     { id: 65, heading: 'Ship to Party' },
// //     { id: 66, heading: 'Bill to Party' },
// //     { id: 67, heading: 'Customer Group 1' },
// //     { id: 68, heading: 'Customer Group 2' },
// //     { id: 69, heading: 'Customer Group 3' },
// //     { id: 70, heading: 'Customer Group 4' },
// //     { id: 71, heading: 'Material Group 1' },
// //     { id: 72, heading: 'Material Group 2' },
// //     { id: 73, heading: 'Mfr Part Number' },
// //     { id: 74, heading: 'Product Hierarchy - Level 1' },
// //     { id: 75, heading: 'Product Hierarchy - Level 2' },
// //     { id: 76, heading: 'Product Hierarchy - Level 3' },
// //     { id: 77, heading: 'Product Hierarchy - Level 4' },
// //     { id: 78, heading: 'Product Hierarchy - Level 5' },
// //     { id: 79, heading: 'Flex Attribute 1' },
// //     { id: 80, heading: 'Flex Attribute 2' },
// //     { id: 81, heading: 'Flex Attribute 3' },
// //     { id: 82, heading: 'Flex Attribute 4' },
// //     { id: 83, heading: 'Flex Attribute 5' },
// //     { id: 84, heading: 'Incentive Basis 1' },
// //     { id: 85, heading: 'Incentive Basis 2' },
// //     { id: 86, heading: 'Incentive Basis 3' },
// //     { id: 87, heading: 'Incentive Basis 4' },
// //     { id: 88, heading: 'Incentive Basis 5' },
// //     { id: 89, heading: 'Flex Key Figure 1' },
// //     { id: 90, heading: 'Flex Key Figure 2' },
// //     { id: 91, heading: 'Flex Key Figure 3' },
// //     { id: 92, heading: 'Flex Key Figure 4' },
// //     { id: 93, heading: 'Flex Key Figure 5' },
// //     { id: 94, heading: 'Currency' },
// //     { id: 95, heading: 'Sales Order Type' },
// //     { id: 96, heading: 'Sales Order Number' },
// //     { id: 97, heading: 'Sales Order Line' },
// //     { id: 98, heading: 'Delivery Type' },
// //     { id: 99, heading: 'Delivery Doc Number' },
// //     { id: 100, heading: 'Delivery Doc Line' },
// //     { id: 101, heading: 'Accounting Doc Number' },
// //     { id: 102, heading: 'Accounting Doc Line' },
// //     { id: 103, heading: 'Billing Doc Cancelled' },
// //     { id: 104, heading: 'Cancelled Billing Doc' },
// //     { id: 105, heading: 'Original Invoice for Return' },
// //     { id: 106, heading: 'Original Invoice Line for Return' },
// //     { id: 107, heading: 'Document Category' },
// //     { id: 108, heading: 'Payment Terms' },
// //     { id: 109, heading: 'Higher Level BOM Item' },
// //     { id: 110, heading: 'Order Reason' },
// //     { id: 111, heading: 'Deleted' },
// //     { id: 112, heading: 'Zero Value Item' },
// //     { id: 113, heading: 'Pricing Procedure' },
// //     { id: 114, heading: 'Cost Center' },
// //     { id: 115, heading: 'Controlling Area' },
// //     { id: 116, heading: 'PA Segment Number (CO-PA)' },
// //     { id: 117, heading: 'Source System' },
// //     { id: 118, heading: 'Billing Document Created By' },
// //     { id: 119, heading: 'Billing Document Created On' },
// //     { id: 121, heading: 'Billing Document Changed By' },
// //     { id: 122, heading: 'Billing Document Changed Date' },
// //     { id: 124, heading: 'Extracted By' },
// //     { id: 125, heading: 'Extracted On' },
// //     { id: 125, heading: 'Postings Created By' },
// //     { id: 125, heading: 'Postings Created On' },
// // ];

// const FinancialPostingsReportTable = props => {
//     var newArray = [];
//     const classes = useStyles();
//     const { className } = props;
//     const theme = useTheme();
//     const contractCustomisationUpdation = useRef();
//     const [tabHeads, setTabHeads] = React.useState([]);
//     const [tabKeys, setTabKeys] = React.useState([]);
//     const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
//         defaultMatches: true
//     });
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);
//     const [financialPostingCompleteResultsArray, setFinancialPostingCompleteResultsArray] = React.useState([]);
//     const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//         props.runFinancialReports(newPage + 1, rowsPerPage);
//     };
//     const handleChangeRowsPerPage = (event, newValue) => {
//         setPage(0);
//         setRowsPerPage(event.target.value);
//         props.runFinancialReports(1, event.target.value);
//     };
//     const handleSheetDownload = () => {
//         props.downloadSheet(financialPostingCompleteResultsArray.records);
//     }
//     const [searchText, setSearchText] = React.useState('');
//     const handleSearchOnChange = (event) => {
//         setSearchText(event.target.value);
//     }
//     useEffect(() => {
//         if (props.financialPostingsCompleteResults) {
//             setFinancialPostingCompleteResultsArray(props.financialPostingsCompleteResults);
//         }
//     }, [props.financialPostingsCompleteResults]);

//     useEffect(() => {
//         props.onLoadingDefault();
//     }, []);
//     useEffect(() => {
//         var keywordsArray = ['contract_number', 'tags', 'posting_number', 'posting_type', 'billing_date', 'billing_doc_number', 'billing_doc_line', 'invoice_date', 'invoice_number', 'invoice_line', 'sold_to_party', 'material_number', 'supplier', 'quantity', 'billed_uom', 'net_value', 'incentive_basis', 'incentive_rate', 'incentive_amount',
//             'customer_name', 'supplier_name',
//             'material_description', 'new_incentive_amount',
//             'old_incentive_amount', 'posting_date', 'profit_center', 'plant',
//             'settlement_partner_type', 'settlement_partner_number', 'application_type', 'contract_type', 'calculation_schema',
//             'material_group', 'posting_number', 'material_type', 'product_hierarchy', 'item.start_date', 'item.end_date', 'approval_code',
//             'fiscal_year_month', 'adjustment_code', 'calendar_year_month', 'company_code', 'sales_org', 'employee_number', 'employee_name',
//             'territory_id', 'ima_ineligible', 'customer_chain', 'ima_ineligible_application', 'customer_group', 'billing_type',
//             'supplier_group', 'debit_credit_indicator', 'item_category', 'distribution_channel', 'division', 'sales_district',
//             'sales_group', 'sales_office', 'pricing_date', 'payer', 'ship_to_party', 'bill_to_party', 'customer_group1',
//             'customer_group2', 'customer_group3', 'customer_group4', 'material_group1', 'material_group2', 'mfr_part_number', 'product_hierarchy_level1',
//             'product_hierarchy_level2', 'product_hierarchy_level3', 'product_hierarchy_level4', 'product_hierarchy_level5', 'flex_attribute1', 'flex_attribute2', 'flex_attribute3', 'flex_attribute4', 'flex_attribute5', 'incentive_basis1', 'incentive_basis2', 'incentive_basis3', 'incentive_basis4', 'incentive_basis5', 'flex_key_figure1', 'flex_key_figure2', 'flex_key_figure3', 'flex_key_figure4', 'flex_key_figure_5', 'currency', 'sales_order_type', 'sales_order_number', 'sales_order_line', 'delivery_type', 'delivery_doc_number', 'delivery_doc_line', 'accounting_doc_number', 'accounting_doc_line', 'billing_doc_cancelled', 'cancelled_billing_doc', 'original_invoice_for_return', 'original_invoice_line_for_return', 'document_category', 'payment_terms', 'higher_level_bom_item', 'order_reason', 'deleted', 'zero_value_item', 'pricing_procedure', 'cost_center', 'controlling_area', 'pa_segment_number', 'collaboration_id', 'source_system', 'billing_document_created_by', 'item.billing_document_created_date', 'billing_document_changed_by', 'item.billing_document_changed_date', 'uploaded_by.name', 'username', 'CreatedAt']
//         if (!contractCustomisationUpdation.current) {
//             if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
//                 enabledFormFields = props.tableData.records.filter(itemY => itemY.enabled != false).map(itemY => { return itemY.field_id });
//             }
//             setTabHeads(tabHeads => [...tabHeads, ...['Contract Number', 'Tags']])
//             setTabKeys(tabKeys => [...tabKeys, ...['contract_number', 'tags']]);
//             if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
//                 keywordsArray.forEach(function (elem) {
//                     props.tableData.records
//                         .filter(itemY => itemY.field_id === elem)
//                         .filter(itemY => itemY.enabled == true)
//                         .map(itemY => {
//                             setTabHeads(tabHeads => [...tabHeads, itemY.name]);
//                             setTabKeys(tabKeys => [...tabKeys, itemY.field_id]);
//                         });
//                 });
//             }
//         }
//     }, [props.tableData]);
//     useEffect(() => {
//         setTabHeads(tabHeads.concat(newArray))
//     }, []);
//     return (
//         <div>
//             <Breadcrumbs aria-label="breadcrumb">
//                 <Link variant='h4' classes={{
//                     root: classes.link
//                 }}
//                     onClick={() => history.push('/financial-postings-reports')}
//                 >
//                     Financial Postings Reports
//                 </Link>
//                 <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
//             </Breadcrumbs>
//             <div
//                 className={clsx(classes.root, className)}
//             >
//                 <div className={clsx({
//                     [classes.row]: isDesktop,
//                 })} >
//                     <Typography variant="h2"> Financial Postings Report Results </Typography>
//                     <div className={clsx({ [classes.searchInput]: isDesktop })}>
//                         <Paper
//                             className={clsx(classes.rootSearch, className)}
//                         >
//                             <SearchIcon className={classes.icon} />
//                             <Input
//                                 className={classes.input}
//                                 disableUnderline
//                                 placeholder="Search"
//                                 onChange={handleSearchOnChange}
//                             />
//                             <IconButton type="submit" className={classes.iconButton} aria-label="Close"
//                                 onClick={() => props.runFinancialReports(0, 10, searchText)}>
//                                 <CheckIcon className={classes.icon} />
//                             </IconButton>
//                         </Paper>
//                     </div>
//                 </div>
//                 {props.financialPostingsResults && props.financialPostingsResults.total_record > 0 ?
//                     <TableContainer>
//                         <Table>
//                             <TableHead >
//                                 <TableRow >
//                                     {tabHeads.map(item => {
//                                         return (
//                                             <TableCell align={item.align ? 'left' : 'center'} className={classes.tableCell} key={item.ID}>
//                                                 {item === 'Billing Doc Number' ? 'Invoice Number' :
//                                                     item === 'Billing Doc Line' ? 'Invoice Line'
//                                                         :
//                                                         item}
//                                             </TableCell>
//                                         )
//                                     })}
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody classes={{ root: classes.table }} >
//                                 {props.financialPostingsResults && props.financialPostingsResults.records && props.financialPostingsResults.records.map(item => {
//                                     return (
//                                         <StyledTableRow key={item.ID}>
//                                             {tabKeys.includes('contract_number') ?
//                                                 <TableCell align='left' className={classes.tableCell}>{item.contract_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('tags') ?
//                                                 <TableCell align='left' className={classes.tableCell}>{item.tags}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('posting_number') ?
//                                                 <TableCell align='left' className={classes.tableCell}>{item.posting_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('posting_type') ?
//                                                 <TableCell align='center'>{item.posting_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_date') ?
//                                                 <TableCell align='center'>{Moment(item.billing_date).format('MM/DD/YYYY')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_doc_number') ?
//                                                 <TableCell align='center'>{item.billing_doc_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_doc_line') ?
//                                                 <TableCell align='center'>{item.billing_doc_line}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('invoice_date') ?
//                                                 <TableCell align='center'>{Moment(item.invoice_date).format('MM/DD/YYYY')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('invoice_number') ?
//                                                 <TableCell align='center'>{item.invoice_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('invoice_line') ?
//                                                 <TableCell align='center'>{item.inoice_line}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('sold_to_party') ?
//                                                 <TableCell align='center'>{item.sold_to_party}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('material_number') ?
//                                                 <TableCell align='center'>{item.material_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('supplier') ?
//                                                 <TableCell align='left' className={classes.tableCell}>{item.supplier_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('quantity') ?
//                                                 <TableCell align='center'>{item.quantity}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billed_uom') ?
//                                                 <TableCell align='center'>{item.billed_uom}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('net_value') ?
//                                                 <TableCell align='center'>{item.net_value.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_basis') ?
//                                                 <TableCell aligincentive_raten='center'>{item.incentive_basis && item.incentive_basis.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_rate') ?
//                                                 <TableCell align='center'>{item.incentive_rate && item.incentive_rate.toFixed(4)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_amount') ?
//                                                 <TableCell align='center'>{item.incentive_amount && item.incentive_amount.toFixed(4)}</TableCell>
//                                                 : ''}


//                                             {tabKeys.includes('customer_name') ?
//                                                 <TableCell align='center'>{item.customer_name}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('supplier_name') ?
//                                                 <TableCell align='center'>{item.supplier_name}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('material_description') ?
//                                                 <TableCell align='center'>{item.material_description}</TableCell>
//                                                 : ''}

//                                             {tabKeys.includes('incentive_amount') ?
//                                                 <TableCell align='center'>{item.incentive_rate && item.incentive_rate.toFixed(4)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('old_incentive_amount') ?
//                                                 <TableCell align='center'>{item.old_incentive_amount && item.old_incentive_amount.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('posting_date') ?
//                                                 <TableCell align='center'>{item.posting_date && Moment(item.posting_date).format('MM/DD/YYYY')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('profit_center') ?
//                                                 <TableCell align='center'>{item.profit_center}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('plant') ?
//                                                 <TableCell align='center'>{item.plant}</TableCell>
//                                                 : ''}

//                                             {/* {tabKeys.includes('new_incentive_amount') ?
//                                                 <TableCell align='center'>{item.incentive_amount && item.incentive_amount.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('new_incentive_amount') ?
//                                                 <TableCell align='center'>{item.new_incentive_amount && item.new_incentive_amount.toFixed(2)}</TableCell>
//                                                 : ''} */}
//                                             {tabKeys.includes('settlement_partner_type') ?
//                                                 <TableCell align='center'>{item.settlement_partner_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('settlement_partner_number') ?
//                                                 <TableCell align='center'>{item.settlement_partner_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('application_type') ?
//                                                 <TableCell align='center'>{item.application_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('contract_type') ?
//                                                 <TableCell align='center'>{item.contract_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('calculation_schema') ?
//                                                 <TableCell align='center'>{item.calculation_schema}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('material_group') ?
//                                                 <TableCell align='center'>{item.material_group}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('posting_number') ?
//                                                 <TableCell align='center'>{item.posting_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('material_type') ?
//                                                 <TableCell align='center'>{item.material_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('product_hierarchy') ?
//                                                 <TableCell align='center'>{item.product_hierarchy}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('start_date') ?
//                                                 <TableCell align='center'>{item.start_date && item.start_date != '0001-01-01T00:00:00Z' && Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('end_date') ?
//                                                 <TableCell align='center'>{item.end_date && item.end_date != '0001-01-01T00:00:00Z' && Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('approval_code') ?
//                                                 <TableCell align='center'>{item.approval_code}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('fiscal_year_month') ?
//                                                 <TableCell align='center'>{item.fiscal_year_month}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('adjustment_code') ?
//                                                 <TableCell align='center'>{item.adjustment_code}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('calendar_year_month') ?
//                                                 <TableCell align='center'>{item.calendar_year_month}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('company_code') ?
//                                                 <TableCell align='center'>{item.company_code}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('sales_org') ?
//                                                 <TableCell align='center'>{item.sales_org}</TableCell>
//                                                 : ''}

//                                             {tabKeys.includes('employee_number') ?
//                                                 <TableCell align='center'>{item.employee_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('employee_name') ?
//                                                 <TableCell align='center'>{item.employee_name}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('territory_id') ?
//                                                 <TableCell align='center'>{item.territory_id}</TableCell>
//                                                 : ''}

//                                             {tabKeys.includes('ima_ineligible') ?
//                                                 <TableCell align='center'>{item.ima_ineligible}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('customer_chain') ?
//                                                 <TableCell align='center'>{item.customer_chain}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('ima_ineligible_application') ?
//                                                 <TableCell align='center'>{item.ima_ineligible_application}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('customer_group') ?
//                                                 <TableCell align='center'>{item.customer_group}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_type') ?
//                                                 <TableCell align='center'>{item.billing_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('supplier_group') ?
//                                                 <TableCell align='center'>{item.supplier_group}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('debit_credit_indicator') ?
//                                                 <TableCell align='center'>{item.debit_credit_indicator}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('item_category') ?
//                                                 <TableCell align='center'>{item.item_category}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('distribution_channel') ?
//                                                 <TableCell align='center'>{item.distribution_channel}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('division') ?
//                                                 <TableCell align='center'>{item.division}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('sales_district') ?
//                                                 <TableCell align='center'>{item.sales_district}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('sales_group') ?
//                                                 <TableCell align='center'>{item.sales_group}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('sales_office') ?
//                                                 <TableCell align='center'>{item.sales_office}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('pricing_date') ?
//                                                 <TableCell align='center'>{item.pricing_date && item.pricing_date != '0001-01-01T00:00:00Z' && Moment(item.pricing_date).format('MM/DD/YYYY')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('payer') ?
//                                                 <TableCell align='center'>{item.payer}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('ship_to_party') ?
//                                                 <TableCell align='center'>{item.ship_to_party}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('bill_to_party') ?
//                                                 <TableCell align='center'>{item.bill_to_party}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('customer_group1') ?
//                                                 <TableCell align='center'>{item.customer_group1}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('customer_group2') ?
//                                                 <TableCell align='center'>{item.customer_group2}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('customer_group3') ?
//                                                 <TableCell align='center'>{item.customer_group3}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('customer_group4') ?
//                                                 <TableCell align='center'>{item.customer_group4}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('material_group1') ?
//                                                 <TableCell align='center'>{item.material_group1}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('material_group2') ?
//                                                 <TableCell align='center'>{item.material_group2}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('mfr_part_number') ?
//                                                 <TableCell align='center'>{item.mfr_part_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('product_hierarchy_level1') ?
//                                                 <TableCell align='center'>{item.product_hierarchy_level1}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('product_hierarchy_level2') ?
//                                                 <TableCell align='center'>{item.product_hierarchy_level2}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('product_hierarchy_level3') ?
//                                                 <TableCell align='center'>{item.product_hierarchy_level3}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('product_hierarchy_level4') ?
//                                                 <TableCell align='center'>{item.product_hierarchy_level4}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('product_hierarchy_level5') ?
//                                                 <TableCell align='center'>{item.product_hierarchy_level5}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_attribute1') ?
//                                                 <TableCell align='center'>{item.flex_attribute1}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_attribute2') ?
//                                                 <TableCell align='center'>{item.flex_attribute2}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_attribute3') ?
//                                                 <TableCell align='center'>{item.flex_attribute3}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_attribute4') ?
//                                                 <TableCell align='center'>{item.flex_attribute4}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_attribute5') ?
//                                                 <TableCell align='center'>{item.flex_attribute5}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_basis1') ?
//                                                 <TableCell align='center'>{item.incentive_basis1 && item.incentive_basis1.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_basis2') ?
//                                                 <TableCell align='center'>{item.incentive_basis2 && item.incentive_basis2.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_basis3') ?
//                                                 <TableCell align='center'>{item.incentive_basis3 && item.incentive_basis3.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_basis4') ?
//                                                 <TableCell align='center'>{item.incentive_basis4 && item.incentive_basis4.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('incentive_basis5') ?
//                                                 <TableCell align='center'>{item.incentive_basis5 && item.incentive_basis5.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_key_figure1') ?
//                                                 <TableCell align='center'>{item.flex_key_figure1 && item.flex_key_figure1.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_key_figure2') ?
//                                                 <TableCell align='center'>{item.flex_key_figure2 && item.flex_key_figure2.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_key_figure3') ?
//                                                 <TableCell align='center'>{item.flex_key_figure13 && item.flex_key_figure3.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_key_figure4') ?
//                                                 <TableCell align='center'>{item.flex_key_figure4 && item.flex_key_figure4.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('flex_key_figure_5') ?
//                                                 <TableCell align='center'>{item.flex_key_figure_5 && item.flex_key_figure_5.toFixed(2)}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('currency') ?
//                                                 <TableCell align='center'>{item.currency}</TableCell>
//                                                 : ''}

//                                             {tabKeys.includes('sales_order_type') ?
//                                                 <TableCell align='center'>{item.sales_order_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('sales_order_number') ?
//                                                 <TableCell align='center'>{item.sales_order_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('sales_order_line') ?
//                                                 <TableCell align='center'>{item.sales_order_line}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('delivery_type') ?
//                                                 <TableCell align='center'>{item.delivery_type}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('delivery_doc_number') ?
//                                                 <TableCell align='center'>{item.delivery_doc_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('delivery_doc_line') ?
//                                                 <TableCell align='center'>{item.delivery_doc_line}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('accounting_doc_number') ?
//                                                 <TableCell align='center'>{item.accounting_doc_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('accounting_doc_line') ?
//                                                 <TableCell align='center'>{item.accounting_doc_line}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_doc_cancelled') ?
//                                                 <TableCell align='center'>{item.billing_doc_cancelled}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('cancelled_billing_doc') ?
//                                                 <TableCell align='center'>{item.cancelled_billing_doc}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('original_invoice_for_return') ?
//                                                 <TableCell align='center'>{item.original_invoice_for_return}</TableCell>
//                                                 : ''}

//                                             {tabKeys.includes('original_invoice_line_for_return') ?
//                                                 <TableCell align='center'>{item.original_invoice_line_for_return}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('document_category') ?
//                                                 <TableCell align='center'>{item.document_category}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('payment_terms') ?
//                                                 <TableCell align='center'>{item.payment_terms}</TableCell>
//                                                 : ''}

//                                             {tabKeys.includes('higher_level_bom_item') ?
//                                                 <TableCell align='center'>{item.higher_level_bom_item}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('order_reason') ?
//                                                 <TableCell align='center'>{item.order_reason}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('deleted') ?
//                                                 <TableCell align='center'>{item.deleted}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('zero_value_item') ?
//                                                 <TableCell align='center'>{item.zero_value_item}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('pricing_procedure') ?
//                                                 <TableCell align='center'>{item.pricing_procedure}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('cost_center') ?
//                                                 <TableCell align='center'>{item.cost_center}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('controlling_area') ?
//                                                 <TableCell align='center'>{item.controlling_area}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('pa_segment_number') ?
//                                                 <TableCell align='center'>{item.pa_segment_number}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('collaboration_id') ?
//                                                 <TableCell align='center'>{item.collaboration_id}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('source_system') ?
//                                                 <TableCell align='center'>{item.source_system}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_document_created_by') ?
//                                                 <TableCell align='center'>{item.billing_document_created_by}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_document_created_date') ?
//                                                 <TableCell align='center'>{item.billing_document_created_date != '0001-01-01T00:00:00Z' && Moment(item.billing_document_created_date).format('MM/DD/YYYY HH:mm')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_document_changed_by') ?
//                                                 <TableCell align='center'>{item.billing_document_changed_by}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('billing_document_changed_date') ?
//                                                 <TableCell align='center'>{item.billing_document_changed_date != '0001-01-01T00:00:00Z' && Moment(item.billing_document_changed_date).format('MM/DD/YYYY HH:mm')}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('name') ?
//                                                 <TableCell align='center'>{item.uploaded_by && item.uploaded_by.name}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('username') ?
//                                                 <TableCell align='center'>{item.accrual_created_by && item.accrual_created_by.username}</TableCell>
//                                                 : ''}
//                                             {tabKeys.includes('CreatedAt') ?
//                                                 <TableCell align='center'>{Moment(item.CreatedAt).format('MM/DD/YYYY HH:mm')}</TableCell>
//                                                 : ''}






//                                         </StyledTableRow>
//                                     );
//                                 })}
//                             </TableBody>
//                         </Table>
//                         <TablePagination
//                             component="div"
//                             count={props.financialPostingsResults.total_record}
//                             page={page}
//                             rowsPerPage={rowsPerPage}
//                             onChangePage={handleChangePage}
//                             rowsPerPageOptions={rowsPerPageOptions}
//                             onChangeRowsPerPage={handleChangeRowsPerPage}
//                             classes={{
//                                 caption: classes.caption,
//                                 root: classes.paginationRoot
//                             }}
//                         />
//                     </TableContainer>
//                     :
//                     <Typography variant='h4'>
//                         No Results
//                     </Typography>
//                 }
//             </div>
//             {/* {props.financialPostingsCompleteResults &&
//                 <div className={classes.buttonContainer}>
//                     <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
//                 </div>
//             } */}
//         </div>
//     );

// };

// const mapDispatchToProps = dispatch => {
//     return {
//         runFinancialReports: (pagination, limit, keyword) => dispatch(runFinancialReports(pagination, limit, keyword)),
//         downloadSheet: (data) => dispatch(downloadSheet(data, 'financial-postings')),
//         onLoadingDefault: () => dispatch(getDefaultValuesFinancialSimulation())
//     }
// }

// const mapStateToProps = state => {
//     return {
//         financialPostingsResults: state.operationalReportsData.financialReportsData,
//         financialPostingsCompleteResults: state.operationalReportsData.financialReportsCompleteData,
//         tableData: state.addMultipleConfigurationData.financialSimulationDefaultData
//     }
// };


// export default connect(mapStateToProps, mapDispatchToProps)(FinancialPostingsReportTable);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Breadcrumbs,
    Link,
    Checkbox
} from '@mui/material';
import { connect } from 'react-redux';
import { getContractsTableData, cloneContract, getContractViewData, batchApproveFinancial, getFinacialPostingsApprovalData } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { getContractDetailsData } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));

const FinancialPostingReportsApproval = props => {
    useEffect(() => {
        props.getFinacialPostingsApprovalData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.onCheckContractsTable(newPage + 1, rowsPerPage);
    };
    const approveContract = () => {
        props.batchApproveFinancial(selected, 'acc')
    };
    const rejectContract = () => {
        props.batchApproveFinancial(selected, 're')
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.onCheckContractsTable(1, event.target.value);
    };

    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/financial-approvals-reports')}
                >
                    Financial Postings Approval  Reports
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            {props.financialApprovalData && props.financialApprovalData.records && props.financialApprovalData.records.length > 0 ?
                <TableContainer>
                    <Table>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center'>Contract Type</TableCell>
                                <TableCell align='center'>Contract ID</TableCell>
                                <TableCell align='center'>Description </TableCell>
                                <TableCell align='center'>Calculation Start Date </TableCell>
                                <TableCell align='center'>Calculation End Date </TableCell>
                                <TableCell align='center'>Posting Type </TableCell>
                                <TableCell align='center'>Posting Number </TableCell>
                                <TableCell align='center'>Posting Date </TableCell>
                                <TableCell align='center'>Tier Basis </TableCell>
                                <TableCell align='center'>Incentive Basis </TableCell>
                                <TableCell align='center'>Incentive Rate </TableCell>
                                <TableCell align='center'>Incentive Amount </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.financialApprovalData.records.map(item => {
                                const isItemSelected = isSelected(item.ID);
                                return (
                                    <StyledTableRow key={item.contract_number} className={classes.hover}
                                        hover
                                        onClick={(event) => handleClick(event, item.ID)}
                                    >
                                        <TableCell align='center'>{item.contract_type}</TableCell>
                                        <TableCell align='left' className={classes.tableCell} style={{ textAlign: 'center' }}>
                                            {item.contract_number}
                                        </TableCell>
                                        <TableCell align='center'>{item.contract_internal_description}</TableCell>
                                        <TableCell align='center'>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell align='center'>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell align='center'>{item.posting_type}</TableCell>
                                        <TableCell align='center'>{item.posting_number}</TableCell>
                                        <TableCell align='center'>{Moment.utc(item.posting_date).format('MM/DD/YYYY')}</TableCell>
                                        <TableCell align='center'>{item.tier_basis}</TableCell>
                                        <TableCell align='center'>{item.incentive_basis}</TableCell>
                                        <TableCell align='center'>{item.incentive_rate}</TableCell>
                                        <TableCell align='center'>{item.incentive_amount}</TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addContractData.loading,
        contractTabledataComplete: state.addContractData.contractListDataComplete,
        financialApprovalData: state.addMultipleConfigurationData.financialApprovalData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckContractsTable: (pagination, limit) => dispatch(getContractsTableData(pagination, limit, 'submit')),
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        getcontractViewdetails: (id) => dispatch(getContractViewData(id)),
        batchApproveFinancial: (items, status) => dispatch(batchApproveFinancial(items, status)),
        // approval
        getFinacialPostingsApprovalData: () => dispatch(getFinacialPostingsApprovalData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialPostingReportsApproval);