import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    FormLabel,
    Select,
    MenuItem,
    OutlinedInput, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getPriceTypeConfig, runMarketPrice, getPriceListMaster } from '../../../redux/actions';
import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 34
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 34,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    contractContiner: {
        marginTop: 7,
        width: '49%',
        padding: 6
    },
    inputTwoLine: {
        marginTop: -5,
        width: "100%",
        paddingLeft: 10,
        height: 35,
    },
}));

const MarketPrice = props => {
    const classes = useStyles();
    const { className } = props;
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [priceListID, setPriceListID] = React.useState('');
    const [priceTypeName, setPriceTypeName] = React.useState('');
    const [priceListIDArray, setPriceListIDArray] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = useState(null);
    const [billingDateEnd, setBillingDateEnd] = useState(null);
    const [pricingDateStart, setPricingDateStart] = useState(null);
    const [pricingDateEnd, setPricingDateEnd] = useState(null);
    const [currency, setCurrency] = React.useState('');
    const [method, setMethod] = React.useState('');
    const methodArray = ['Average', 'Index by Value', 'Index by Volume', 'Highest', 'Lowest']
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getPriceTypeName(1, 10);
        props.getConfigData(1, 10);
    }, []);
    /* useEffect(()=>{
         var newArray=[];
         if(props.configData && props.configData.records) {
             props.configData.records.map(e => {
                 newArray.push({'price_list_id':e.price_list_id,'price_type_name':e.price_type_name})
             })
         setPriceListIDArray(newArray);
         }
     },[props.configData])*/
    useEffect(() => {
        var newArray = [];
        var newArray1 = []
        if (props.priceTypeName && props.priceTypeName.records) {
            props.priceTypeName.records.map(e => {
                if (e.price_type == 'Market Price') {
                    newArray.push(e.price_type_name)
                    props.configData && props.configData.records && props.configData.records.filter(item => item.price_type_name.includes(e.price_type_name)).map(e => {
                        newArray1.push(e.price_list_id)
                    })
                }
            })
            setPriceTypeNameArray(newArray)
            setPriceListIDArray(newArray1);
        }
        /*var newArray1=[];
        if(props.configData && props.configData.records) {
            props.configData.records.map(e => {
                newArray1.push(e.price_list_id)
            })
        
        }*/

    }, [props.priceTypeName, props.configData])
    const [open, setOpen] = React.useState(false);

    function handleMaterialNumber(newValue) {
        setMaterialNumber([...materialNumber, newValue])
    }
    function handleMaterialNumberDelete(newValue) {
        var deletedMaterialNumber = materialNumber.filter(item => item !== newValue)
        setMaterialNumber(deletedMaterialNumber)
    }

    function handlePriceListID(newValue) {
        setPriceListID(newValue)
    }
    function handleMethod(newValue) {
        setMethod(newValue)
    }
    function handlePriceTypeName(newValue) {
        setPriceTypeName(newValue)
    }
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(Moment(newValue));
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(Moment(newValue));
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    const handlePricingDateEnd = (newValue) => {
        setPricingDateEnd(Moment(newValue));
    }
    const handleClear = () => {
        setMaterialNumber([]);
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setPriceListID('');
        setPriceTypeName('');
        setPricingDateStart(null);
        setPricingDateEnd(null);
        setCurrency('');
        setMethod('');
    }
    const handleClickOpen = () => {
        setOpen(true)

    };

    const handleDialog = () => {
        var sd = Moment.utc(billingDateStart).local();
        var ed = Moment.utc(billingDateEnd).local();
        var psd = Moment.utc(pricingDateStart).local();
        var ped = Moment.utc(pricingDateEnd).local();

        const formData = {
            "material_number": materialNumber,
            "start_date": sd,
            "end_date": ed,
            "price_list_id": priceListID,
            "price_type_name": priceTypeName,
            "pricing_start_date": psd,
            "pricing_end_date": ped,
            "currency": currency,
            "method": method
        };
        setOpen(false);
        props.onSubmit(formData);
        handleClear();

    }


    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h2">
                        Market Price Calculation
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Material Number
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleMaterialNumber(chips)}
                                    onDelete={(chip) => handleMaterialNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel} >Start Date</FormLabel>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={billingDateStart}
                                            onChange={handleBillingDateStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel} >End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginLeft: 10
                                                }
                                            }}
                                            value={billingDateEnd}
                                            onChange={handleBillingDateEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                            minDate={billingDateStart ? billingDateStart : ''}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>

                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Price List ID
                                </FormLabel>
                                <Select
                                    value={priceListID}
                                    onChange={(e) => handlePriceListID(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceListIDArray && priceListIDArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Price Type Name
                                </FormLabel>
                                <Select
                                    value={priceTypeName}
                                    onChange={(e) => handlePriceTypeName(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceTypeNameArray && priceTypeNameArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required
                                    style={{ padding: 3, marginTop: "6px" }}>
                                    Currency
                                </FormLabel>
                                <OutlinedInput
                                    value={currency}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setCurrency(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ marginTop: 15 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Method
                                </FormLabel>
                                <Select
                                    value={method}
                                    onChange={(e) => handleMethod(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {methodArray && methodArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ marginTop: 15 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel} >Pricing Start Date</FormLabel>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    // marginTop: 2,
                                                    border: !pricingDateStart && pricingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={pricingDateStart}
                                            onChange={handlePricingDateStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                style={{ marginTop: 15 }}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel} >Pricing End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    // marginTop: 2,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginLeft: 10
                                                }
                                            }}
                                            value={pricingDateEnd}
                                            onChange={handlePricingDateEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                            minDate={pricingDateStart ? pricingDateStart : ''}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={materialNumber && billingDateStart && billingDateEnd && priceTypeName && priceListID && pricingDateStart && pricingDateEnd && currency && method
                                    ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Market Price?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay >
    );
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPriceListMaster(pagination, limit)),
        getPriceTypeName: (pagination, limit) => dispatch(getPriceTypeConfig(pagination, limit)),
        onSubmit: (formData) => dispatch(runMarketPrice(formData)),
    }
}
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.pricingData.priceListMasterData,
        priceTypeName: state.pricingData.priceTypeData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MarketPrice);