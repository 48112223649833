import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs,
    Card, FormLabel, MenuItem, Select, OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { createAttributesConfig, getAttributesFields } from '../../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 15,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    inputTwoLine: {
        //marginTop: 11,
        width: '100%',
        paddingLeft: 10,
        height: 37
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll"
    },
    formlabel: {
        marginBottom: 8,
        fontSize: theme.typography.h3.fontSize,
    }

}));
const AddAttributesConfig = props => {
    const classes = useStyles();
    useEffect(() => {
        props.getPriceListFields()
    }, []);
    const [qualifierName, setQualifierName] = React.useState('');
    const [qualifierLabel, setQualifierLabel] = React.useState('');
    const [externalQualifierName, setExternalQualifierName] = React.useState('');
    const [qualifierType, setQualifierType] = React.useState('');
    const [priceFields, setPriceFields] = React.useState([]);
    const qualifierTypeArray = [
        "Customer Attribute",
        "Material Attribute",
        "Flex Attribute",
        "Organization Attribute",
        "Supplier Attribute"
    ];
    const customerAttributeArray = [
        "customer_number",
        "customer_group",
        "class_of_trade", "country",
        "customer_classification",
        "customer_group1",
        "customer_group2",
        "customer_group3",
        "customer_group4",
        "customer_group5",
        "customer_hierarchy",
        "customer_chain",
        "customer_flex_attribute1",
        "customer_flex_attribute2",
        "customer_flex_attribute3",
        "customer_flex_attribute4",
        "region",
    ];
    const materailAttributeArray = [
        "material_number",
        "material_group",
        "product_hierarchy",
        "material_group1",
        "material_group2",
        "material_group3",
        "material_group4",
        "material_group5",
        "material_flex_attribute1",
        "material_flex_attribute2",
        "material_flex_attribute3",
        "material_flex_attribute4",
        "material_type"
    ];
    const flexAttributeArray = [
        "flex_attribute1",
        "flex_attribute2",
        "flex_attribute3",
        "flex_attribute4"
    ];
    const organizationAttributeArray = [
        "sales_organization", "company_code", "distribution_channel", "division", "plant"
    ];
    const SupplierAttributeArray = [
        "supplier_number", "supplier_group", "supplier_flex_attribute1", "supplier_flex_attribute2", "supplier_group1", "supplier_group2", "supplier_type"
    ];
    function handleOnSubmit() {
        var data = {
            "qualifier_key": qualifierName,
            "external_qualifier_name": externalQualifierName,
            "qualifier_label": qualifierLabel,
            "qualifier_type": qualifierType
        }

        props.onSubmit(data);

    }
    useEffect(() => {
        if (props.priceListFields) {
            setPriceFields(props.priceListFields)

        }
    }, [props.priceListFields]);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    return (
        <LoadingOverlay
            active={props.loadingAPI}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}

        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/attributes-config'
                    >
                        Attributes
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Add Attributes</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel variant="h5" className={classes.formDiv} required>
                                                Attribute Type
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    disableUnderline
                                                    value={qualifierType}
                                                    onChange={(e) => setQualifierType(e.target.value)}
                                                    displayEmpty
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    {qualifierTypeArray && qualifierTypeArray
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel variant="h5" className={classes.formDiv} required>
                                                Attribute Name
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    disableUnderline
                                                    value={qualifierName}
                                                    onChange={(e) => setQualifierName(e.target.value)}
                                                    displayEmpty
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    style={{ textTransform: 'capitalize' }}
                                                >

                                                    {qualifierType === 'Customer Attribute' && customerAttributeArray && customerAttributeArray
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    {qualifierType === 'Material Attribute' && materailAttributeArray && materailAttributeArray
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    {qualifierType === 'Flex Attribute' && flexAttributeArray && flexAttributeArray
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    {qualifierType === 'Organization Attribute' && organizationAttributeArray && organizationAttributeArray
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    {qualifierType === 'Supplier Attribute' && SupplierAttributeArray && SupplierAttributeArray
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formlabel}>
                                                Attribute Label
                                            </FormLabel>
                                            <OutlinedInput
                                                value={qualifierLabel}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setQualifierLabel(e.target.value)} />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formlabel}>
                                                External Attribute Name
                                            </FormLabel>
                                            <OutlinedInput
                                                value={externalQualifierName}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setExternalQualifierName(e.target.value)} />
                                        </div>
                                    </Grid>


                                </Grid>

                            </div>
                        </form>
                    </Card>
                    <div className={classes.buttonRoot}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                            disabled={qualifierName && qualifierType ? false : true}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Submit'
                            }
                        </Button>
                    </div>
                </div >
            </div >
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.profitOptimizationData.loading,
        priceListFields: state.profitOptimizationData.ListFields
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createAttributesConfig(data)),
        getPriceListFields: () => dispatch(getAttributesFields())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAttributesConfig);