import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    TableRow,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions,
    Checkbox,
    Chip,
    Input,
    MenuItem,
    Select,
    ListItemText,
} from '@mui/material';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import momentt from 'moment-timezone';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { getAllForecastLevelDropDwon, createForecastLevel } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { submitAnamoly, getSingleAnamolyConfig } from '../../redux/actions/AnamolyConfig/AuthAction';
import { id } from 'date-fns/locale';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 37
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize,
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },

    paper: { minWidth: window.screen.width * .50 }
}));

const EditAnamolyConfig = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [anamolyName, setAnamolyName] = React.useState('');
    const [anamolyDescription, setAnamolyDescription] = React.useState('');
    const [aimodelName, setaiModelName] = React.useState('');
    const [sourceData, setSourceData] = React.useState('');
    const [evaluationDate, setEvaluationDate] = React.useState('');
    const [attributes, setAttributes] = React.useState([]);
    const [keyFigures, setKeyFigures] = React.useState([]);
    const [contaminationFactor, setContaminationFactor] = React.useState('');
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleAnamolyConfig(appId)
    }, []);
    useEffect(() => {
        if (props.queryListData) {
            setAnamolyName(props.queryListData.KeyValueAttrs['anamoly_name'])
            setAnamolyDescription(props.queryListData.KeyValueAttrs['anamoly_description'])
            setaiModelName(props.queryListData.KeyValueAttrs['ai_model_name'])
            setSourceData(props.queryListData.KeyValueAttrs['source_data'])
            setEvaluationDate(props.queryListData.KeyValueAttrs['evaluation_date'])
            setAttributes(props.queryListData.KeyValueAttrs['attributes'])
            setKeyFigures(props.queryListData.KeyValueAttrs['key_figures'])
            setContaminationFactor(props.queryListData.KeyValueAttrs['contamination_factor'])
        }
    }, [props.queryListData]);
    const handleaimodelName = (e) => {
        setaiModelName(e.target.value);
    }
    const handleAnamolyName = (e) => {
        setAnamolyName(e.target.value);
    }
    const handleAnamolyDes = (e) => {
        setAnamolyDescription(e.target.value);
    }
    const handleSourceData = (e) => {
        setSourceData(e.target.value)
    }
    const handleEvaluationDate = (e) => {
        setEvaluationDate(e.target.value)
    }
    const handleAttributes = (e) => {
        setAttributes(e.target.value)
    }
    const handleKeyFigures = (e) => {
        setKeyFigures(e.target.value)
    }
    const handleContaminationFactor = (e) => {
        setContaminationFactor(e.target.value)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,
    }
    const [listOpen, setListOpen] = React.useState('');
    const [type, setType] = React.useState('');
    const [selected, setSelected] = React.useState([]);

    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected)
    }

    useEffect(() => {
        setOpen(true);
    }, []);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    function handleOnSubmit() {
        var data = {
            "anamoly_name": anamolyName,
            "anamoly_description": anamolyDescription,
            "ai_model_name": aimodelName,
            "source_data": sourceData,
            "evaluation_date": evaluationDate,
            "attributes": attributes,
            "key_figures": keyFigures,
            "contamination_factor": contaminationFactor
        }
        props.onSubmit(data, null, props.queryListData.ID, 'put');
    }
    function handleCancelDialog() {
        history.push('/anomaly-config');
    }
    function humanize(str) {
        var i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    variant="h4"
                    classes={{
                        root: classes.link,
                    }}
                    to="/anomaly-config"
                >
                    AI Model - Anomaly
                </Link>
                <Typography color="textPrimary" variant="h4">
                    Edit Anomaly
                </Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Anomaly Name
                    </FormLabel>
                    <OutlinedInput
                        value={anamolyName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleAnamolyName} />
                    <FormLabel className={classes.formLabel} required>
                        Anomaly Description
                    </FormLabel>
                    <OutlinedInput
                        value={anamolyDescription}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleAnamolyDes} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={anamolyName && anamolyDescription ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required >
                                        AI Model Name
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={aimodelName}
                                        onChange={(e) => handleaimodelName(e)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        <MenuItem key="iso" value="Isolation Forest">Isolation Forest</MenuItem>
                                        <MenuItem key="svm" value="One Class SVM">One Class SVM</MenuItem>
                                        <MenuItem key="lof" value="Local Outlier Factor">Local Outlier Factor</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Source Data
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={sourceData}
                                        onChange={(e) => handleSourceData(e)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        <MenuItem key="direct_sales_data" value="Direct - Sales Data">Direct - Sales Data</MenuItem>
                                        <MenuItem key="purchase_data" value="Direct - Purchase Data">Direct - Purchase Data</MenuItem>
                                        <MenuItem key="indirect_sales_data" value="Indirect - Sales Data">Indirect - Sales Data</MenuItem>
                                        <MenuItem key="profit_optimization_simulations" value="Profit Optimization Simulations">Profit Optimization Simulations</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required >
                                        Evaluation Date
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={evaluationDate}
                                        onChange={(e) => handleEvaluationDate(e)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        <MenuItem key="start_date" value="Start Date"> Start Date </MenuItem>
                                        <MenuItem key="end_date" value="End Date"> End Date</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Attributes
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={attributes}
                                        onChange={(e) => handleAttributes(e)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        <MenuItem key="customer_number" value="Customer Number">Customer Number</MenuItem>
                                        <MenuItem key="material_number" value="Material Number">Material Number</MenuItem>
                                        <MenuItem key="customer_group" value="Customer Group">Customer Group</MenuItem>
                                        <MenuItem key="customer_chain" value="Customer Chain">Customer Chain</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Key Figures
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={keyFigures}
                                        onChange={(e) => handleKeyFigures(e)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        <MenuItem key="flex_key_figure1" value="Flex Key Figure 1"> Flex Key Figure 1</MenuItem>
                                        <MenuItem key="flex_key_figure5" value="Flex Key Figure 5"> Flex Key Figure 5</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel variant="h5" className={classes.formDiv} required>
                                        Contamination Factor
                                    </FormLabel>
                                    <OutlinedInput
                                        value={contaminationFactor}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => handleContaminationFactor(e)}
                                        placeholder={props.placeholder}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={aimodelName && sourceData && evaluationDate
                            && keyFigures && contaminationFactor
                            ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, aimodelName, id, type) => dispatch(submitAnamoly(data, aimodelName, id, type)),
        getSingleAnamolyConfig: (id) => dispatch(getSingleAnamolyConfig(id)),
    }
}
const mapStateToProps = state => {
    return {
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        tableData: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        queryListData: state.AnamolyData.anamolySingleData
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAnamolyConfig);