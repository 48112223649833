import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton, Button, Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { Edit, DeleteForever, Storage } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getMembershipData } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import {
    getMembershipDetails,
    deleteMembership, getMembershipHierarchyData, getLblDispDesFieldValue
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { MembershipToolbar } from './';
import AccountTreeRounded from '@mui/icons-material/AccountTreeRounded';
import secureLocalStorage from 'react-secure-storage';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
        // marginTop:5
    },
    caption: {
        fontSize: 20
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const MembershipUserTable = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [openPopUp, setOpenPopUp] = React.useState(false);
    const [memberId, setMemberId] = React.useState(0);
    const action = {
        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div style={{ whiteSpace: 'nowrap' }}>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_membership-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => MemberHierarchy(rowData, 'hierarchy')}
                        size="large">
                        <AccountTreeRounded fontSize='small' color="disabled" classes={{ root: classes.caption }} />
                    </IconButton>}
                {/* { secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('put_membership-'+sessionStorage.getItem('application')) &&
                <IconButton classes={{ root: classes.IconButton }} onClick={() => MemberHierarchy(rowData,'tree')}>
                    <Storage fontSize='small' color="disabled" classes={{root:classes.caption}} />
                </IconButton> } */}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_membership-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editMember(rowData.ID)}
                        size="large">
                        <Edit color="disabled" classes={{ root: classes.caption }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_membership-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => deleteMembership(rowData.ID)}
                        size="large">
                        <DeleteForever color="disabled" classes={{ root: classes.caption }} />
                    </IconButton>}
            </div>

    }
    const [columns, setColumns] = React.useState([]);
    useEffect(() => {
        props.getMembership(1, 0);
        props.getLblDispDesFieldValue({ "data_source": "Membership Data" }, 'post');
    }, []);

    useEffect(() => {
        if (props.membershipData && props.membershipData.records && props.membershipData.records.length > 0) {
            setDataRows(props.membershipData.records);
        }
    }, [props.membershipData]);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    newArray.push({
                        field: item.key,
                        title: item.current,
                        type: item.key != 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                        render: (rowData) => <div>{item.key == 'action_effective_date' || item.key == 'member_start_date' || item.key == 'member_end_date' ?
                            Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    })

                })
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])
    function editMember(id) {
        //    props.getMembershipDetails(id,'MembershipID');
        props.getMembershipDetails(id)
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/membership/edit-member/' + id,
            id: id,
            // type:{'method':false,'memberID':id}
        });
    }
    function MemberHierarchy(item, type) {
        props.getMembershipHierarchyData(item.GPO_member_number)
        if (type == 'hierarchy') {
            history.push({
                pathname: '/membership/hierarchy/' + item.GPO_member_number,
                // id: item.membership_owner_id,
                // data:item
            });
        }
        else {
            history.push({
                pathname: '/membership/membership-tree/' + item.membership_owner_id,
                id: item.membership_owner_id,
                data: item
            });
        }
    }
    function viewMembership(id) {
        // props.getMembershipDetails(id,'MembershipID');
        props.getMembershipDetails(id);
        history.push({
            pathname: '/membership/view-member/' + id,
            // id: id,
            // type:{'method':true,'memberID':id}
        });
    }
    function deleteMembership(id) {
        setMemberId(id)
        setOpenPopUp(true)
    }
    function handleDialog() {
        setOpenPopUp(false)
        props.deleteMembershipData(memberId)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Master Roster </Typography>
                    {/* {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_membership-' + sessionStorage.getItem('application')) && */}
                    <MembershipToolbar />

                </div>
                {columns && columns.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog open={openPopUp}>
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            Are you sure you want to delete these membership records ?
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpenPopUp(false)} autoFocus color="primary" variant="outlined" >
                        No
                    </Button>
                    <Button onClick={handleDialog} color="primary" autoFocus variant="contained"  >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        membershipData: state.customerData.membershipData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMembership: (pagination, limit, type) => dispatch(getMembershipData(pagination, limit, 'displaybyMember')),
        getMembershipDetails: (id, type) => dispatch(getMembershipDetails(id, type)),
        deleteMembershipData: (id) => dispatch(deleteMembership(id, 'multiple')),
        getMembershipHierarchyData: (id) => dispatch(getMembershipHierarchyData(id, 'GPONumber')),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipUserTable);