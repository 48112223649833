import React, { useEffect, useRef, useState } from 'react';
import {
    Card, Grid, Divider, Button
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { uploadAttachment } from '../../../../redux/actions';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { NotesContainer } from '../../../SharedPages';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    notesDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 10,
        marginBottom: 10
    },
    cardRoot: {
        backgroundColor: theme.palette.white,
        boxShadow: '1px 2px 4px #9E9E9E',
        borderRadius: 6,
        margin: '10px 20px 10px 20px',
        padding: 15,
        overflow: 'hidden'
    },
    commentHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'grey',
        fontSize: 13
    },
    dividerColor: {
        background: theme.palette.border.main,
        margin: '6px 0px 10px 0px'
    },
    dialogTitleRoot: {
        padding: '9px 24px'
    },
    dialogController: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
    },
    fileContainer: {
        fontSize: 12,
        justifyContent: 'center',
        height: '200',
        margin: '10px 20px 10px 10px'
    },
    contentBox: {
        height: '40px',
        paddingBottom: '4rem'
    }
}));

const InternalNotes = (props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentFileUrl, setCurrentFileUrl] = useState('');
    const [currentNote, setCurrentNote] = useState('');
    const [note, setNote] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [notesArray, setNotesArray] = React.useState([]);
    const [fileName, setFileName] = useState('');
    const [executed, setExecuted] = useState(false);

    useEffect(() => {
        if (props.attachments && props.attachments.length > 0 && !executed) {
            setNotesArray(props.attachments);
            setExecuted(true)
        }
    }, [props.attachments])
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setCurrentFileName('');
        setNote('');
        setCurrentFileUrl('');
        setOpen(false);
        setFileName('')
    };
    useEffect(() => {
        if (props.uploadUrl)
            setCurrentFileUrl(props.uploadUrl)
    }, [props.uploadUrl]);

    const handleFileUpload = (event) => {
        if (event.target.files) {
            setCurrentFileName(event.target.files[0].name)
            setFileName(event.target.files[0].name);
            const data = new FormData();
            data.append('file', event.target.files[0]);
            props.uploadAttachment(data);
        }
    };
    const handleSave = (file) => {
        setNote('');
        setCurrentNote(note);
        setCurrentFileName(fileName);
        setOpen(false);
        var data = {
            text: note,
            attachments: currentFileUrl ? [currentFileUrl] : [],
            file_name: currentFileUrl ? fileName : '',
            created_on: Moment.utc()
        };

        setNotesArray([...notesArray, data]);
        props.submitValues([...notesArray, data], 'attachments');
        setCurrentFileName('');
        setCurrentFileUrl('')
        setFileName('')
    };
    const handleRemoveCard = (value) => {
        setNotesArray(value);
        props.submitValues(value, 'attachments')
        // setNotesArray([
        //     ...notesArray.slice(0, index),
        //     ...notesArray.slice(index + 1, notesArray.length)
        // ]);
        // props.submitValues([
        //     ...notesArray.slice(0, index),
        //     ...notesArray.slice(index + 1, notesArray.length)
        // ], 'attachments');
    }
    const handleNote = (e) => {
        setNote(e.target.value)
        // var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
        // if (format.test(e.target.value)) {

        // } else {
        //     setNote(e.target.value)
        // }
    }
    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.notesDiv}>
                        <Button
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            Add Note
                        </Button>
                    </div>

                    {notesArray && notesArray.length > 0 &&
                        <NotesContainer data={notesArray} onChange={handleRemoveCard} mode='edit' />

                    }

                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
                        maxWidth={'lg'}>
                        <DialogTitle id="form-dialog-title" onClose={handleClose} classes={{ root: classes.dialogTitleRoot }}>
                            <div style={{ fontSize: 17 }}> Add Notes and Attachments</div>
                        </DialogTitle>
                        <DialogContent dividers>
                            <TextField
                                value={note}
                                label="Enter your notes here"
                                multiLine={true}
                                maxRows={4}
                                onChange={handleNote}
                                variant="filled"
                                className={classes.contentBox}
                            />
                            <div className={classes.dialogController}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        component="label"
                                        endIcon={<AttachFileIcon />}
                                        variant='outlined'
                                        color='primary'
                                    >
                                        Add Attachment
                                        <input
                                            type="file"
                                            hidden
                                            accept=".xls,.xlsx,.pdf,"
                                            onChange={handleFileUpload}
                                        />
                                    </Button>
                                    <div className={classes.fileContainer}
                                        style={{ color: currentFileUrl ? 'green' : props.uploadingFile ? 'grey' : 'red' }}>
                                        {currentFileName}
                                    </div>
                                    {props.uploadingFile && <CircularProgress color="primary" />}
                                </div>
                                <div>
                                    <Button autoFocus onClick={() => handleSave(fileName)} color="primary" disabled={(props.uploadingFile || !note.trim()) ?
                                        true : false}>
                                        Save Note
                                    </Button>
                                    <Button autoFocus onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </form>
            </Card>
        </div >
    );

}

const mapStateToProps = state => ({
    uploadUrl: state.addContractData.uploadUrl,
    uploadingFile: state.addContractData.loadingFile
});

const mapDispatchToProps = dispatch => {
    return {
        uploadAttachment: (file) => dispatch(uploadAttachment(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(InternalNotes);