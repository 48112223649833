import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Select,
    MenuItem,
    FormLabel
} from '@mui/material';
import { TextInputWithLabel } from '../../../components/Inputs';
import { getAttributeNameList } from '../../../redux/actions';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '10px'
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        padding: '0px 45px 0px 45px !important'
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        height: 37

    },
}));

const CommitmentData = props => {
    const classes = useStyles();
    useEffect(() => {
        // props.getAttributeNameList()
        props.onChange(formData);
    }, []);

    const [itemId, setItemId] = React.useState('');
    const [attributeName, setName] = React.useState('');
    const [attributeValue, setValue] = React.useState('');
    const [attributeDesc, setDes] = React.useState('');
    const attributeNameArray = [
        { key: "accrual_credit_profit_center", value: "Accrual Credit Profit Center" },
        { key: "accrual_debit_profit_center", value: "Accrual Debit Profit Center" },
        { key: "accrual_document_type", value: "Accrual Document Type" },
        { key: "accrual_gl_account_credit", value: "Accrual GL Account Credit" },
        { key: "accrual_gl_account_debit", value: "Accrual GL Account Debit" },
        { key: "currency", value: "Currency" },
        { key: "payment_document_type", value: "Payment Document Type" },
        { key: "payment_terms", value: "Payment Terms" },
        { key: "customer_classification", value: "Customer Classification" },
        { key: "class_of_trade", value: "Class Of Trade" },
        { key: "account_group", value: "Account Group" },
        { key: "customer_chain", value: "Customer Chain" },
        { key: "customer_group", value: "Customer Group" },
        { key: "customer_group1", value: "Customer Group 1" },
        { key: "customer_group2", value: "Customer Group 2" },
        { key: "customer_group3", value: "Customer Group 3" },
        { key: "customer_group4", value: "Customer Group 4" },
        { key: "customer_group5", value: "Customer Group 5" },
        { key: "customer_industry", value: "Customer Industry" },
        { key: "material_type", value: "Material Type" },
        { key: "base_uom", value: "Base Uo M" },
        { key: "external_material_group", value: "External Material Group" },
        { key: "product_hierarchy", value: "Product Hierarchy" },
        { key: "material_group", value: "Material Group" },
        { key: "material_group1", value: "Material Group 1" },
        { key: "material_group2", value: "Material Group 2" },
        { key: "material_group3", value: "Material Group 3" },
        { key: "material_group4", value: "Material Group 4" },
        { key: "material_group5", value: "Material Group 5" },
        { key: "supplier_type", value: "Supplier Type" },
        { key: "supplier_industry", value: "Supplier Industry" },
        { key: "billed_uom", value: "Billed Uo M" },
        { key: "billing_type", value: "Billing Type" },
        { key: "company_code", value: "Company Code" },
        { key: "controlling_area", value: "Controlling Area" },
        { key: "cost_center", value: "Cost Center" },
        { key: "delivery_type", value: "Delivery Type" },
        { key: "distribution_channel", value: "Distribution Channel" },
        { key: "division", value: "Division" },
        { key: "document_category", value: "Document Category" },
        { key: "item_category", value: "Item Category" },
        { key: "order_reason", value: "Order Reason" },
        { key: "plant", value: "Plant" },
        { key: "profit_center", value: "Profit Center" },
        { key: "sales_district", value: "Sales District" },
        { key: "sales_group", value: "Sales Group" },
        { key: "sales_office", value: "Sales Office" },
        { key: "sales_order_type", value: "Sales Order Type" },
        { key: "sales_org", value: "Sales Org" },
        { key: 'contract_owner', value: 'Contract Owner' },
        { key: "customer_id_type", value: "Customer ID Type" },
        { key: "customer_type", value: "Customer Type" },
        { key: "channel_partner_type", value: "Channel Partner Type" },
        { key: "supplier_id_type", value: "Supplier ID Type" },
        { key: "material_id_type", value: "Material ID Type" },
        { key: "price_list", value: "Price List" },
        { key: "action_code", value: "Action Code" },
        { key: "member_status", value: "Member Status" },
        { key: "current_tier_status", value: "Current Tier Status" },
        { key: "accounts_type", value: "Accounts Type" },
        { key: "price_group", value: "Price Group" },
        { key: "exception_reason", value: "Exception Reason" },
    ];
    const sortedAttributeNameArray = attributeNameArray.sort((a, b) => a.value.localeCompare(b.value));
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            }
        }
    }
    useEffect(() => {
        if (props.data) {
            setItemId(props.data.id);
            setName(props.data.attribute_name);
            setValue(props.data.attribute_value);
            setDes(props.data.attribute_desc);
            props.onChange(formData)
        }
    }, [props.data]);

    var formData = {
        id: itemId,
        attribute_name: attributeName,
        attribute_value: attributeValue,
        attribute_desc: attributeDesc,
    }
    //functions 
    function handleAttributeName(newValue) {
        setName(newValue);
        props.onChange(formData);
    }

    function handleAttributeValue(newValue) {
        setValue(newValue);
        props.onChange(formData);
    }

    function handleAttributeDes(newValue) {
        setDes(newValue);
        props.onChange(formData);
    }
    props.onChange(formData);
    return (
        <div className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container
                            spacing={2}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: 15 }}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Attribute Name
                                </FormLabel>
                                <Select
                                    value={attributeName}
                                    onChange={(e) => handleAttributeName(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {sortedAttributeNameArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item.key} key={item.key}>
                                                {item.value}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel className={classes.formLabel} required heading={'Attribute Value'} twoline='true' onChange={handleAttributeValue} prevalue={formData.attribute_value} />
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <TextInputWithLabel className={classes.formLabel} heading={'Attribute Description'} twoline='true' onChange={handleAttributeDes} prevalue={formData.attribute_desc} />
                            </Grid>

                        </Grid>
                    </div>
                </form>
            </Card>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getAttributeNameList: () => dispatch(getAttributeNameList())
    }

}

const mapStateToProps = state => {
    return {
        attributeName: state.attributeListData.attributeNameList,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentData);