import React, { useRef, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    OutlinedInput,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, DropdownArray, Dropdown, ChipInput } from '../../components/Inputs';
import SimpleDialog from '../../components/Dialog';
import { runBuildPurchaseData, getDefaultValues } from '../../redux/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import LoadingOverlay from 'react-loading-overlay';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
import MomentUtils from '@date-io/moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset',
        width: '339px'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    calendarLeftGridContainer: {
        padding: '15px 0px',
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }

}));

const DeletePurchaseInvoice = props => {
    const classes = useStyles();
    const { className } = props;

    const [poDateStart, setPODateStart] = React.useState(null);
    const [poDateEnd, setPODateEnd] = React.useState(null);
    const [grDateStart, setGRDateStart] = React.useState(null);
    const [grDateEnd, setGRDateEnd] = React.useState(null);
    const [irDateStart, setIRDateStart] = React.useState(null);
    const [irDateEnd, setIRDateEnd] = React.useState(null);
    const [asnDateStart, setASNDateStart] = React.useState(null);
    const [asnDateEnd, setASNDateEnd] = React.useState(null);
    const [transactionType, setTransactionType] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [purchaseOrder, setPurchaseOrder] = React.useState([]);
    const [purchaseOrderLine, setPurchaseOrderLine] = React.useState([]);
    const [grDocumentNumber, setGRDocumentNumber] = React.useState([]);
    const [grLineNumber, setGRLineNumber] = React.useState([]);
    const [irDocumentNumber, setIRDocumentNumber] = React.useState([]);
    const [irLineNumber, setIRLineNumber] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [companyCodeMultiple, setCompanyCodeMultiple] = React.useState([]);
    const [purchaseOrg, setPurchaseOrg] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);
    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');





    useEffect(() => {
        props.onLoadingDefault();
    }, []);



    const [open, setOpen] = useState(false);
    const formData = {
        "po_date.range": (poDateStart ? Moment(poDateStart).local().format('YYYY-MM-DD') : '') + ',' + (poDateEnd ? Moment(poDateEnd).local().format('YYYY-MM-DD') : ''),
        "gr_date.range": (grDateStart ? Moment(grDateStart).local().format('YYYY-MM-DD') : '') + ',' + (grDateEnd ? Moment(grDateEnd).local().format('YYYY-MM-DD') : ''),
        "ir_date.range": (irDateStart ? Moment(irDateStart).local().format('YYYY-MM-DD') : '') + ',' + (irDateEnd ? Moment(irDateEnd).local().format('YYYY-MM-DD') : ''),
        "asn_date.range": (asnDateStart ? Moment(asnDateStart).local().format('YYYY-MM-DD') : '') + ',' + (asnDateEnd ? Moment(asnDateEnd).local().format('YYYY-MM-DD') : ''),
        "transaction_type.in": transactionType.toString(),
        "supplier_number.in": supplierNumber.toString(),
        "material_number.in": materialNumberMultiple.toString(),
        "material_group.in": materialGroupMultiple.toString(),
        "purchase_order.in": purchaseOrder.toString(),
        "purchase_order_line.in": purchaseOrderLine.toString(),
        "gr_document_number.in": grDocumentNumber.toString(),
        "gr_line_number.in": grLineNumber.toString(),
        "ir_document_number.in": irDocumentNumber.toString(),
        "ir_line_number.in": irLineNumber.toString(),
        "flex_attribute1.in": flexAttribute1.toString(),
        "flex_attribute2.in": flexAttribute2.toString(),
        "company_code.in": companyCodeMultiple.toString(),
        "purchase_org.in": purchaseOrg.toString(),
        "plant.in": plantMultiple.toString(),
        "profit_center.in": profitCenterMultiple.toString(),


    };
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData, 'delete', radioGroupValue,);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setRadioGroupValue('');
        setPODateStart(null);
        setPODateEnd(null);
        setGRDateStart(null);
        setGRDateEnd(null);
        setIRDateStart(null);
        setIRDateEnd(null);
        setASNDateStart(null);
        setASNDateEnd(null);
        setTransactionType([]);
        setSupplierNumber([]);
        setMaterialGroupMultiple([])
        setMaterialNumberMultiple([])
        setPurchaseOrder([])
        setPurchaseOrderLine([])
        setGRDocumentNumber([])
        setGRLineNumber([])
        setIRDocumentNumber([])
        setIRLineNumber([])
        setFlexAttribute1([]);
        setFlexAttribute2([]);
        setCompanyCodeMultiple([]);
        setPurchaseOrg([]);
        setPlantMultiple([]);
        setProfitCenterMultiple([]);
    }
    const handleClickOpen = () => {
        setOpen(true)
    };





    const handleCompanyCodeMultiple = (newValue) => {
        setCompanyCodeMultiple(newValue);
    }

    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }

    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }

    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }

    const handleTransactionType = (newValue) => {
        setTransactionType(newValue);
    }

    const handleSupplierNumber = (newValue) => {
        setSupplierNumber(newValue);
    }

    const handlePurchaseOrder = (newValue) => {
        setPurchaseOrder(newValue);
    }

    const handlePurchaseOrderLine = (newValue) => {
        setPurchaseOrderLine(newValue);
    }

    const handleGRDocumentNumber = (newValue) => {
        setGRDocumentNumber(newValue);
    }

    const handleGRLineNumber = (newValue) => {
        setGRLineNumber(newValue);
    }

    const handleIRDocumentNumber = (newValue) => {
        setIRDocumentNumber(newValue);
    }

    const handleIRLineNumber = (newValue) => {
        setIRLineNumber(newValue);
    }

    const handleFlexAttribute1 = (newValue) => {
        setFlexAttribute1(newValue);
    }

    const handleFlexAttribute2 = (newValue) => {
        setFlexAttribute2(newValue);
    }

    const handlePurchaseOrg = (newValue) => {
        setPurchaseOrg(newValue);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Delete Purchase Data </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >


                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            // style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>PO Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: !poDateStart && poDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={poDateStart}
                                                onChange={date => setPODateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={poDateEnd}
                                                onChange={date => setPODateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={poDateStart ? poDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            // style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>GR Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: !grDateStart && grDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={grDateStart}
                                                onChange={date => setGRDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={grDateEnd}
                                                onChange={date => setGRDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={grDateStart ? grDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            // style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>IR Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: !irDateStart && irDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={irDateStart}
                                                onChange={date => setIRDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={irDateEnd}
                                                onChange={date => setIRDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={irDateStart ? irDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            // style={{ paddingBottom: 10 }}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>ASN Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: !asnDateStart && asnDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={asnDateStart}
                                                onChange={date => setASNDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={asnDateEnd}
                                                onChange={date => setASNDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={asnDateStart ? asnDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ marginTop: -10 }}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Transaction Type</FormLabel>
                                    <ChipInput
                                        data={transactionType}
                                        onChange={newValue => handleTransactionType(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setTransactionType(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: -10 }}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Number</FormLabel>
                                    <ChipInput
                                        data={supplierNumber}
                                        onChange={newValue => handleSupplierNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSupplierNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: -10 }}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Number</FormLabel>
                                    <ChipInput
                                        data={materialNumberMultiple}
                                        onChange={newValue => handleMaterialNumberMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialNumberMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: -10 }}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Group</FormLabel>
                                    <ChipInput
                                        data={materialGroupMultiple}
                                        onChange={newValue => handleMaterialGroupMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialGroupMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Purchase Order</FormLabel>
                                    <ChipInput
                                        data={purchaseOrder}
                                        onChange={newValue => handlePurchaseOrder(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setPurchaseOrder(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Purchase Order Line</FormLabel>
                                    <ChipInput
                                        data={purchaseOrderLine}
                                        onChange={newValue => handlePurchaseOrderLine(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setPurchaseOrderLine(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>GR Document Number</FormLabel>
                                    <ChipInput
                                        data={grDocumentNumber}
                                        onChange={newValue => handleGRDocumentNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setGRDocumentNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>GR Line Number</FormLabel>
                                    <ChipInput
                                        data={grLineNumber}
                                        onChange={newValue => handleGRLineNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setGRLineNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>IR Document Number</FormLabel>
                                    <ChipInput
                                        data={irDocumentNumber}
                                        onChange={newValue => handleIRDocumentNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setIRDocumentNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>IR Line Number</FormLabel>
                                    <ChipInput
                                        data={irLineNumber}
                                        onChange={newValue => handleIRLineNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setIRLineNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 1</FormLabel>
                                    <ChipInput
                                        data={flexAttribute1}
                                        onChange={newValue => handleFlexAttribute1(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexAttribute1(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 2</FormLabel>
                                    <ChipInput
                                        data={flexAttribute2}
                                        onChange={newValue => handleFlexAttribute2(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexAttribute2(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Company Code</FormLabel>
                                    <ChipInput
                                        data={companyCodeMultiple}
                                        onChange={newValue => handleCompanyCodeMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleCompanyCodeMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Purchase Org</FormLabel>
                                    <ChipInput
                                        data={purchaseOrg}
                                        onChange={newValue => handlePurchaseOrg(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setPurchaseOrg(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Plant</FormLabel>
                                    <ChipInput
                                        data={plantMultiple}
                                        onChange={newValue => handlePlantMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handlePlantMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Profit Center</FormLabel>
                                    <ChipInput
                                        data={profitCenterMultiple}
                                        onChange={newValue => handleProfitCenterMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleProfitCenterMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>





                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="Staging purchase data"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Delete Staging Purchase Data </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="Purchase data"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Delete Purchase Data</Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                disabled={!radioGroupValue ? true : false}
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content={'Do you want to Delete ' + radioGroupValue + '?'} handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData, type, item) => dispatch(runBuildPurchaseData(formData, type, item)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeletePurchaseInvoice);