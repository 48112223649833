import React, { useEffect} from 'react';
import {
    Grid,Typography,Breadcrumbs,
    Card,Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { makeStyles,withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {getAnalysisFormulaByName } from '../../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 5
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding:'0px,15px',
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },

}));

const ViewAnalysisFormula = props => {
    const classes = useStyles(); 
    const priceTypeList = ['Base Price', 'On-Invoice Discount', 'On-Invoice Freight', 'On-Invoice Surcharge', 'Off-Invoice Revenue',
        'Off-Invoice Expense', 'Cost - Direct', 'Cost - Indirect', 'Transfer Price', "Base Sales Adjustments",
        "Price Elasticity Adjustments", "Cost Adjustments","Floor Price","Margin Target %", "Market Price", "Market Target %",]
  
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getPriceCalForListByName(appId);
    }, [])

     const [allData,setAllData] = React.useState([]);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

   
    useEffect(() => {
        if(props.configDataByName){
            setAllData(props.configDataByName)
        }
    }, [props.configDataByName])

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/analysis-formula'
                >
                    Analysis Formula
                </Link>
                <Typography color="textPrimary" variant='h4'>View Analysis Formula</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            
                        <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                            >
                               <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >

                                                <TableCell align='center' className={classes.tabHead}>Driver Category</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Driver </TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Driver Name </TableCell>
                                                {/* <TableCell align='center' >Calculation Type </TableCell> */}
                                                {/* <TableCell align='center' >Step Total </TableCell> */}
                                                {/* <TableCell align='center' >Calculate Based On </TableCell> */}
                                                {/* <TableCell align='center' style={{ width: 50, maxWidth: 50 }}>Priority</TableCell> */}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {allData && allData
                                                .sort((a,b)=>a.step>b.step?1:-1)
                                                .map((itemX, index) => {
                                                    return (
                                                        <StyledTableRow >

                                                            <TableCell align='center' classes={{root:classes.fontSetting}} >{allData[index]['driver_category']}</TableCell>
                                                            <TableCell align='center' classes={{root:classes.fontSetting}} > {allData[index]['driver']}</TableCell>
                                                            <TableCell align='center' classes={{root:classes.fontSetting}} > {allData[index]['driver_name']}</TableCell>
                                                            {/* <TableCell align='center' > {allData[index]['calculation_type']}</TableCell> */}
                                                            {/* <TableCell align='center' >{allData[index]['step_total']} </TableCell> */}
                                                            {/* <TableCell align='center' >{allData[index]['calculation_based_on']}</TableCell> */}
                                                            {/* <TableCell align='center' >{allData[index]['formula_priority']!=0?allData[index]['formula_priority']:''}</TableCell> */}
                                                        </StyledTableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Grid>
                        </div>
                    </form>
                </Card>            
          </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        configDataByName:state.profitOptimizationData.priceListCalForDataByName,      
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getPriceCalForListByName:(name) => dispatch(getAnalysisFormulaByName(name))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewAnalysisFormula);