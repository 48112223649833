// import React, { useEffect } from 'react';
// import clsx from 'clsx';
// import { makeStyles, useTheme, withStyles } from '@mui/styles';
// import {
//     Typography,
//     useMediaQuery,
//     Table,
//     TableCell,
//     TableHead,
//     TableRow,
//     TableContainer,
//     TableBody,
//     Breadcrumbs,Link
// } from '@mui/material';
// import { connect } from 'react-redux';
// import { useHistory } from "react-router-dom";
// import { runContractReports, runDynamicQuery, runCalculationReports, runFinancialReports, 
//     runInvoiceReports, getQueryListAdmin,loadTargetData } from '../../redux/actions';
// import Moment from 'Moment';

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.table.row,
//             height: 46
//         },
//         '&:nth-of-type(even)': {
//             height: 40
//         }
//     }
// }))(TableRow);


// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: theme.palette.white,
//         borderRadius: 10,
//         padding: 29,
//         marginTop: 16
//     },
//     row: {
//         flexDirection: 'row',
//         display: 'flex',
//         justifyContent: 'space-between',
//         marginBottom: 10
//     },
//     caption: {
//         fontSize: 12
//     },
//     paginationRoot: {
//         border: '1px solid #EEEEEE',
//         borderTop: 0
//     },
//     description: {
//         textAlign: 'left',
//         paddingLeft: 28
//     },
//     hover: {
//         cursor: 'pointer'
//     },
//     IconButton: {
//         padding: 0,
//         [theme.breakpoints.up('md')]: {
//             paddingRight: 10
//         }
//     },
//     buttonContainer: {
//         display: 'flex',
//         justifyContent: 'flex-end',
//         marginBottom: 15,
//         marginTop: 15
//     },
//     startIcon: {
//         marginLeft: 0,
//         marginRight: 0,
//         minWidth: 0
//     },
//     container: {
//         minWidth: 0,
//         [theme.breakpoints.down('sm')]: {
//             marginTop: 10
//         }
//     },
//     tableRoot: {
//         display:'flex',flexDirection:'column'
//     },
//     link: {
//         color: theme.palette.text.primary,
//         opacity: 0.3,
//         textDecoration: 'none',
//         border: 0,
//         fontSize: 16,
//         paddingRight: 10
//     },
// }));
// const SelectOperationalReports = props => {
//     const { className } = props;
//     const classes = useStyles();
//     const theme = useTheme();
//     const history = useHistory();
//     const [currentTargetData, setCurrentTargetData] = React.useState({});

//     useEffect(()=>{
//         props.contractNumberVariance();
//     },[])

//     return (
//         <div className={clsx(classes.root, className)}>
//            <Breadcrumbs aria-label="breadcrumb">
//                 <Link variant='h4' classes={{
//                     root: classes.link
//                 }}
//                     to='/select-operational-reports'
//                 >
//                     Operational Reports
//                 </Link>
//                 <Typography color="textPrimary" variant='h4'>Report</Typography>
//             </Breadcrumbs>
//             <TableContainer>
//                 <Table>
//                     <TableHead >
//                         <TableRow  >
//                         {props.targetData && props.targetData[0] &&
//                             Object.entries(props.targetData[0]).map(([key, value]) => {
//                             return (
//                                     <TableCell align='center' style={{textTransform:'capitalize',whiteSpace:'noWrap'}}>{key.replace(/_/g, ' ')}</TableCell>
//                             )})
//                             }
//                         </TableRow>
//                     </TableHead>
//                     <TableBody classes={{ root: classes.table }} >
//                         {props.targetData && props.targetData.map(item => {
//                         return (
//                             <StyledTableRow>
//                                     {Object.entries(item).map(([key, value]) => {
//                                 return (
//                                         <TableCell align='center' style={{textTransform:'capitalize',whiteSpace:'noWrap'}}>
//                                             {Moment(value, moment.ISO_8601, true).isValid()?moment(value).format('MM/DD/YYYY'):value}
//                                             </TableCell>
//                                 )})}
//                             </StyledTableRow>
//                         );})}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </div >
//     );
// };

// const mapStateToProps = state => {
//     return {
//         loading: state.operationalReportsData.loading,
//         queryListAdminData: state.operationalReportsData.queryListAdminSuccessData,
//         targetData: state.operationalReportsData.targetData
//     }
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         contractNumberVariance: ()=>dispatch(contractNumberVariance()),
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(SelectOperationalReports);




import React, { useEffect } from 'react';
import '../../library/scss/rsuite-default.css';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Grid, Typography, Breadcrumbs, Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadSalesVsPaymentGraph, loadAccrualVarianceGraph } from '../../redux/actions';
import { connect } from 'react-redux';
import { DateRangePicker } from 'rsuite';
import { endOfMonth, subMonths, startOfMonth } from 'date-fns';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '16px 0px 16px 0px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 32px 0px 20px',
        alignItems: 'center'
    },
    rangeContainer: {
        padding: '20px 30px 20px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    graphContainer: {
        width: '80%',
        height: '100%',
        marginTop: 10,
        display: 'flex',
        alignSelf: 'center'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const ContractNumberVarianceReports = (props) => {
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [accrualFilter, setAccrualFilter] = React.useState();
    const [accrualFilterEnd, setAccrualFilterEnd] = React.useState();
    const [dateRangeValue, setDateRangeValue] = React.useState([new Date('2020-06-01T05:30:00'), new Date('2020-10-01T05:30:00')]);
    const handleDateRange = (value) => {
        setDateRangeValue(value);
        props.loadAccrualVarianceGraph(Moment(value[0]).format('MM/YYYY'), Moment(value[1]).format('MM/YYYY'));
    }

    useEffect(() => {
        props.loadAccrualVarianceGraph(Moment(startOfMonth(subMonths(new Date(), 2))).format('MM/YYYY'), Moment(startOfMonth(subMonths(new Date(), 1))).format('MM/YYYY'));
        setDateRangeValue([startOfMonth(subMonths(new Date(), 2)), endOfMonth(subMonths(new Date(), 1))]);
        var temp = [['Contract Number', 'Variance']];
        setAvssGraphData(temp)
    }, []);

    useEffect(() => {
        setStartDate();
        setEndDate();

        if (props.accrualVarianceGraphData && props.accrualVarianceGraphData.length > 0) {
            var temp = [['contract_number', 'variance']];
            props.accrualVarianceGraphData && props.accrualVarianceGraphData.forEach(e => {
                temp.push([e.contract_number, e.variance])
            })
            setAvssGraphData(temp);
        }
    }, [props.accrualVarianceGraphData]);

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/select-operational-reports'
                    >
                        Operational Reports
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Report</Typography>
                </Breadcrumbs>
            </div>
            <div style={{ width: 350 }}>
                <DateRangePicker
                    block
                    hoverRange="month" ranges={[]}
                    placeholder="Date range"
                    width={500}
                    style={{ marginTop: 10 }}
                    value={dateRangeValue}
                    onChange={handleDateRange}
                />
            </div>
            <Grid container className={classes.root}>
                {/* <div className={classes.rangeContainer} style={{ borderBottom: '1px solid #e5e5e5' }}>
                    <DateRangePicker
                        block
                        hoverRange="month" ranges={[]}
                        placeholder="Date range"
                        width={500}
                        style={{ paddingRight: 48 }}
                        value={dateRangeValue}
                        onChange={handleDateRange}
                    />
                </div> */}

                <Grid
                    item
                    md={12}
                    xs={6}
                    className={classes.container}
                >
                    {props.accrualVarianceGraphData && props.accrualVarianceGraphData.length > 0 ?
                        <TableContainer>
                            <Table>
                                <TableHead >
                                    <TableRow  >
                                        <TableCell align='center' style={{ textTransform: 'capitalize', whiteSpace: 'noWrap' }}>Contract Number</TableCell>
                                        <TableCell align='center' style={{ textTransform: 'capitalize', whiteSpace: 'noWrap' }}>Contract Internal Description</TableCell>
                                        <TableCell align='center' style={{ textTransform: 'capitalize', whiteSpace: 'noWrap' }}>Incentive Amount Variance</TableCell>
                                        <TableCell align='center' style={{ textTransform: 'capitalize', whiteSpace: 'noWrap' }}>Incentive Basis Variance</TableCell>
                                        <TableCell align='center' style={{ textTransform: 'capitalize', whiteSpace: 'noWrap' }}>Tier Basis Variance</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }} >
                                    {props.accrualVarianceGraphData && props.accrualVarianceGraphData.map(item => {
                                        return (
                                            <StyledTableRow>
                                                <TableCell align='center'>{item.contract_number}</TableCell>
                                                <TableCell align='center'>{item.contract_internal_description}</TableCell>
                                                <TableCell align='center'>{item.incentive_amount_variance.toFixed(2)}</TableCell>
                                                <TableCell align='center'>{item.incentive_basis_variance.toFixed(2)}</TableCell>
                                                <TableCell align='center'>{item.tier_basis_variance.toFixed(2)}</TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Typography variant="h5" style={{ textAlign: 'center' }} color="primary" > No data to show</Typography>
                    }
                </Grid>
            </Grid>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        accrualVarianceGraphData: state.initialData.accrualVarianceGraphData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSalesVsPaymentGraph: () => dispatch(loadSalesVsPaymentGraph()),
        loadAccrualVarianceGraph: (s, e) => dispatch(loadAccrualVarianceGraph(s, e)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractNumberVarianceReports);