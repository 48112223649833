import React, { useEffect } from 'react';
import {
    Card, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Typography, CardHeader
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { SearchInput } from '../../../../components';
import Moment from 'moment';
import { useSelector } from 'react-redux'
import { connect } from 'react-redux';
import { getDefaultValuesAllDataNew } from '../../../../redux/actions';

var data = [];
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    fontSetting:{
        fontSize: theme.typography.h4.fontSize
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const EligibilityRules = props => {
    const [eligibilityStore, setEligibilityStore] = React.useState([]);
    const classes = useStyles();
    const { className } = props;
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    const [currentApplication, setCurrentApplication] = React.useState(sessionStorage.getItem('application'));
    const eligibiltyTypeData = ['Tier','Rebate','Both']
    useEffect(() => {
        if (props.data && props.data.application_type && props.data.source_data_type)
            props.onLoadingLabelDesignerNew('field-label-designer', props.data.application_type, props.data.source_data_type)
    }, [])

    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })
    }
    useEffect(() => {
        if (props.data && props.data.eligibility_rules) {
            var data = props.data.eligibility_rules;
            var attribute_names = [];
            var attribute_values = [];
            data.map((item, ix) => {
                item.attribute_key_values&&item.attribute_key_values.length>0 && item.attribute_key_values.map((one, ix2) => {
                    attribute_names.push(one.attribute_name);
                    attribute_values.push(one.attribute_value);
                })
                data[ix].attribute_names = attribute_names;
                data[ix].attribute_values = attribute_values;
            })
            setEligibilityStore(data);
        }
    },[props.data]);
    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="ELIGIBILITY RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.calculationMethod && eligibilityStore.length > 0 ?
                                (props.data && props.data.calculation_method && props.data.calculation_method === 'Tiered Volume' || props.data && props.data.calculation_method && props.data.calculation_method === 'Tiered Growth' || props.data && props.data.calculation_method && props.data.calculation_method === 'Multi Axis') ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Eligibility Type</TableCell>
                                                    <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Option</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{
                                                        display: (currentApplication === 'Customer Chargeback' ||
                                                            currentApplication === 'Supplier Chargeback') ? 'none' : null
                                                    }}>Condition ID</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Attribute Value</TableCell>
                                                    {eligibilityStore.filter(item=>item.maximum_amount&&item.maximum_amount!=0).length>0&&
                                                        <TableCell align='center'>Max Amt</TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {eligibilityStore
                                                .sort(function (a, b) {
                                                    return eligibiltyTypeData.indexOf(a.type) - eligibiltyTypeData.indexOf(b.type);
                                                })
                                                    .map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item.ID}>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.type.replace(/_/g, ' ')}</TableCell>
                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                    {item.attribute_key_values&&item.attribute_key_values.length>0 && item.attribute_key_values.map((it2, ix) => {
                                                                        return checkLabel(it2.attribute_name) ? checkLabel(it2.attribute_name) : it2.attribute_name.replace(/_/g, ' ');
                                                                    })}
                                                                </TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.option}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{
                                                                    display: (currentApplication === 'Customer Chargeback' ||
                                                                        currentApplication === 'Supplier Chargeback') ? 'none' : null
                                                                }}>{item.sequence}</TableCell>
                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                    {item.attribute_key_values&&item.attribute_key_values.length>0 && item.attribute_key_values.map((it2, ix) => {
                                                                        return (
                                                                            it2.attribute_value ? it2.attribute_value : it2.attribute_value
                                                                        )
                                                                    })}

                                                                </TableCell>
                                                                {eligibilityStore.filter(item=>item.maximum_amount&&item.maximum_amount!=0).length>0&&
                                                                    <TableCell align='center'>{item.maximum_amount&&item.maximum_amount!=0?
                                                                        parseFloat(
                                                                            item.maximum_amount
                                                                          ).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }):''}
                                                                        </TableCell>
                                                                }
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    (props.calculationMethod && (props.calculationMethod === 'Flat Rate' && props.incentiveRateType === 'Contract') || (props.calculationMethod === 'Fixed Amount' && props.incentiveRateType === 'Contract')) ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >Option</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} style={{
                                                            display: (currentApplication === 'Customer Chargeback' ||
                                                                currentApplication === 'Supplier Chargeback') ? 'none' : null
                                                        }}>Condition ID</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>Attribute Value</TableCell>
                                                        {eligibilityStore.filter(item=>item.maximum_amount&&item.maximum_amount!=0).length>0&&
                                                        <TableCell align='center'>Max Amt</TableCell>
                                                    }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.data && props.data.eligibility_rules && props.data.eligibility_rules
                                                        .map((item) => {
                                                            return (
                                                                <StyledTableRow key={item.ID}>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                                        {item.attribute_key_values&&item.attribute_key_values.length>0 ?
                                                                            item.attribute_key_values.map((item2, i) => {
                                                                                return checkLabel(item2.attribute_name) ? checkLabel(item2.attribute_name) : item2.attribute_name.replace(/_/g, ' ');
                                                                            })
                                                                            :
                                                                            ''
                                                                        }
                                                                    </TableCell>

                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.option}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{
                                                                        display: (currentApplication === 'Customer Chargeback' ||
                                                                            currentApplication === 'Supplier Chargeback') ? 'none' : null
                                                                    }}>{item.sequence}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                                        {item.attribute_key_values&&item.attribute_key_values.length>0 ?
                                                                            item.attribute_key_values.map((item2, i) => {
                                                                                return (
                                                                                    item2.attribute_value ? item2.attribute_value : item2.attribute_value
                                                                                )
                                                                            })
                                                                            :
                                                                            ''
                                                                        }
                                                                    </TableCell>
                                                                    {eligibilityStore.filter(item=>item.maximum_amount&&item.maximum_amount!=0).length>0&&
                                                                    <TableCell align='center'>{item.maximum_amount&&item.maximum_amount!=0?
                                                                        parseFloat(
                                                                            item.maximum_amount
                                                                          ).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }):''}
                                                                        </TableCell>
                                                                }
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        props.calculationMethod === 'Price Difference' ?
                                            <TableContainer>
                                                <Table>
                                                    <TableHead >
                                                        <TableRow >
                                                            <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Option</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{
                                                                display: (currentApplication === 'Customer Chargeback' ||currentApplication === 'Pricing' ||
                                                                    currentApplication === 'Supplier Chargeback') ? 'none' : null
                                                            }}>Condition ID</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>Attribute Value</TableCell>
                                                           
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody classes={{ root: classes.table }} >
                                                        {props.data && props.data.eligibility_rules && props.data.eligibility_rules
                                                            .map((item) => {
                                                                return (
                                                                    <StyledTableRow key={item.ID}>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} style={{textTransform:'capitalize'}}>
                                                                            {item.attribute_key_values && item.attribute_key_values.length>0 ?
                                                                                item.attribute_key_values.map((item2, i) => {
                                                                                    return checkLabel(item2.attribute_name) ? checkLabel(item2.attribute_name) : item2.attribute_name.replace(/_/g, ' ');
                                                                                })
                                                                                :
                                                                                ''
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.option}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} style={{
                                                                            display: (currentApplication === 'Customer Chargeback' ||currentApplication === 'Pricing' ||
                                                                                currentApplication === 'Supplier Chargeback') ? 'none' : null
                                                                        }}>{item.sequence}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.attribute_key_values && item.attribute_key_values.length>0 ?
                                                                            item.attribute_key_values.map((item2, i) => {
                                                                                return (
                                                                                    item2.attribute_value ? item2.attribute_value : item2.attribute_value
                                                                                )
                                                                            })
                                                                            :
                                                                            ''
                                                                        }</TableCell>
                                                                    </StyledTableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            :

                                            <TableContainer>
                                                <Table>
                                                    <TableHead >
                                                        <TableRow >
                                                            <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Option</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Condition ID</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>Attribute Value</TableCell>
                                                            {eligibilityStore.filter(item=>item.maximum_amount&&item.maximum_amount!=0).length>0&&
                                                        <TableCell align='center'>Max Amt</TableCell>
                                                    }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody classes={{ root: classes.table }} >
                                                        {props.data && props.data.eligibility_rules && props.data.eligibility_rules
                                                            .map((item) => {
                                                                return (
                                                                    <StyledTableRow key={item.ID}>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} style={{textTransform:'capitalize'}}>
                                                                            {item.attribute_key_values&&item.attribute_key_values[0]&&item.attribute_key_values[0].attribute_name?
                                                                            checkLabel(item.attribute_key_values[0].attribute_name) ? checkLabel(item.attribute_key_values[0].attribute_name) : item.attribute_key_values[0].attribute_name.replace(/_/g, ' ')
                                                                            :''
                                                                        }
                                                                            
                                                                        </TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.option}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.sequence}</TableCell>
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.attribute_key_values && item.attribute_key_values.length>0 ?
                                                                            item.attribute_key_values.map((item2, i) => {
                                                                                return (
                                                                                    item2.attribute_value ? item2.attribute_value : item2.attribute_value
                                                                                )
                                                                            })
                                                                            :
                                                                            ''
                                                                        }</TableCell>
                                                                        {eligibilityStore.filter(item=>item.maximum_amount&&item.maximum_amount!=0).length>0&&
                                                                       <TableCell align='center'>{item.maximum_amount&&item.maximum_amount!=0?
                                                                        parseFloat(
                                                                            item.maximum_amount
                                                                          ).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }):''}
                                                                        </TableCell>
                                                                       }
                                                                    </StyledTableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        //data: state.addContractData.contractGeneralData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityRules);