import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import {
    FormLabel, MenuItem, Select, Grid,
    Table, TableCell, TableHead, TableRow, TableContainer,
    TableBody, OutlinedInput, IconButton, Button, Typography, TextField
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader, RotateLoader } from 'react-spinners'
import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import { allowedApps, loadValuesBasedOnAppTypeAndField, getDefaultValuesAllData, getDefaultValues, createPromotionPlan } from '../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '10px 29px 29px 29px',
        margin: '30px 0px 30px'
    },
    container: {
        paddingTop: 18,
        width: '100%'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30,
        width: '100%'
    },
    button: {
        marginRight: 25,
        width: 140,
        height: 36
    },
    gridContainer: {
        paddingRight: 15,
        marginBottom: 20
    },

    label: {
        marginTop: 10
    },
    select: {
        color: '#1675e0',
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    fontSetting1: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));
var td = [];
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const PromotionForm = props => {


    const classes = useStyles();
    const { className } = props;

    const [sourceDataType, setSourceDataType] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [postingType, setPostingType] = React.useState('')
    const [keyFigureData, setKeyFigureData] = React.useState([])
    const [promotionLevel, setPromotionLevel] = React.useState('')
    const [keyFigureName, setKeyFigureName] = React.useState('')
    const [addeligiblity, setAddEligibility] = React.useState(1)
    const [validItem, setValidItem] = React.useState(true)
    const [formData, setFormData] = React.useState([])
    const [attributeNameArray, setAttributeNameArray] = React.useState([])
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const optionData = ['EQUAL', 'NOT EQUAL', 'RANGE', 'NOT RANGE']
    var eligibility = { attribute_name: '', option: '', condition_id: 0, attribute_value: [] }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,


    }

    function handleSubmit() {
        localStorage.setItem('key-figure', JSON.stringify(keyFigureName))
        localStorage.setItem('promotion-level', JSON.stringify(promotionLevel))
        formData.map((item, index) => {
            if (item.attribute_name === '' && item.attribute_value.length === 0 && item.option === '' && item.condition_id === 0) {
                delete formData[index]
            }

        })
        var data = {
            "application_type": applicationType,
            "data_source_type": sourceDataType,
            "posting_type": postingType,
            "start_date": Moment(startDate).local().format('MM/DD/YYYY'),
            "end_date": Moment(endDate).local().format('MM/DD/YYYY'),
            "promotion_level": promotionLevel,
            "key_figure_name": keyFigureName,
            "eligibility_rule": formData.filter(value => Object.keys(value).length !== 0)
        }
        props.onSubmit(data)
    }
    useEffect(() => {
        setFormData([eligibility])
        props.allowedApps();

    }, []);
    useEffect(() => {
        if (applicationType && sourceDataType) {
            props.getAttributeData('attribute_name', sourceDataType, applicationType);
            props.getKeyfigureData(applicationType, sourceDataType, 'key_figure_name');
            props.getKeyfigureData(applicationType, sourceDataType, 'attribute_name');
        }

    }, [applicationType, sourceDataType]);

    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);

    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);

    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
            setSourceDataType(props.sourceDataTypeValue['drop_down_value_keys'][0])
        }
    }, [props.sourceDataTypeValue])

    useEffect(() => {
        var tempObj = {};
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records[0]) {
            var commonKeys = props.formFieldsAllData.records[0].drop_down_value_keys;
            var keys = props.formFieldsAllData.records[0].drop_down_values;
            var myobj = {};
            var output = keys.map(function (obj, index) {
                myobj[commonKeys[index]] = obj;
            });
            setKeyValuePairObjectNew(myobj);
        }
    }, [props.formFieldsAllData])
    useEffect(() => {
        if (props.attributeData && props.attributeData.records && props.attributeData.records.length > 0) {
            setAttributeNameArray(props.attributeData.records[0].drop_down_value_keys);

        }
    }, [props.attributeData]);

    useEffect(() => {
        if (props.dropdownKeyFigureData && props.dropdownKeyFigureData.records && props.dropdownKeyFigureData.total_record > 0) {
            if (props.dropdownKeyFigureData && props.dropdownKeyFigureData.records[0] && props.dropdownKeyFigureData.records[0].drop_down_value_keys && props.dropdownKeyFigureData.records[0].drop_down_value_keys != null)
                setKeyFigureData(props.dropdownKeyFigureData.records[0].drop_down_value_keys);
        }
    }, [props.dropdownKeyFigureData]);

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
            props.loadSourceDataType(props.allowedAppsData[0], 'source_data_type');
        }
    }, [props.allowedAppsData])
    const handleStartDate = (value) => {
        setStartDate(value);
    }
    const handleEndDate = (value) => {
        setEndDate(value);
    }
    function handleDeleteLocally(index) {
        formData.map((item, i) => {
            if (i == index - 1) {
                delete formData[i]
            }

        })
        setAddEligibility(addeligiblity - 1)
        checkvalues()
    }
    function handleChangeValue(field, index, value) {

        if (field == 'attribute_value')
            value = [value]
        formData[index - 1][field] = value
        setFormData([...formData])
        checkvalues()

    }
    function handleOnAdd() {
        var newValue = formData
        newValue = [...newValue, eligibility]
        setFormData(newValue);
        setAddEligibility(addeligiblity + 1)
        setValidItem(false)
    }

    function checkvalues() {
        formData.map((item, index) => {
            if (item.attribute_name === '' || item.attribute_value.length === 0 || item.option === '' || item.condition_id === 0) {
                setValidItem(false)
            }
            else
                setValidItem(true)

        })

    }


    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>
                    <Typography color="Primary" variant='h1' style={{ marginLeft: 17, marginBottom: 15 }}>Promotion Plan</Typography>
                    <Grid container style={{ margin: 10, padding: 20, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }} required>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }} required>
                                    Source Data Type
                                </FormLabel>
                                <Select
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {sourceDataTypeDataFromAPI
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    height: 37,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required style={{ marginBottom: 5 }}>
                                    End Date
                                </FormLabel>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    height: 37,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={endDate}
                                            minDate={startDate ? startDate : null}
                                            onChange={handleEndDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.fontSetting }}
                                    style={{ marginBottom: 5 }} required>
                                    Posting Type
                                </FormLabel>
                                <Select
                                    value={postingType}
                                    onChange={(event) => setPostingType(event.target.value)}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value={'Accrual'}>
                                        Accrual
                                    </MenuItem>
                                    <MenuItem value={'Payment'}>
                                        Payment
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.fontSetting }} required
                                    style={{ marginBottom: 5 }}>
                                    Promotion Level
                                </FormLabel>
                                <Select
                                    value={promotionLevel}
                                    onChange={(event) => setPromotionLevel(event.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                        Object.entries(keyValuePairObjectNew)
                                            .sort()
                                            .map(([key, value]) => {
                                                return (
                                                    <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                        {value}
                                                    </MenuItem>
                                                )
                                            })
                                    }
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.gridContainer}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 5 }} required>
                                    Key Figure Name
                                </FormLabel>
                                <Select
                                    value={keyFigureName}
                                    onChange={(event) => setKeyFigureName(event.target.value)}
                                    style={{ textTransform: 'capitalize' }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value="">
                                        {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                    </MenuItem>
                                    {keyFigureData
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item.replace(/_/g, ' ')}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </div>
                        </Grid>


                    </Grid>
                    <div className={classes.container}>
                        <TableContainer>
                            <Table>
                                <TableHead >
                                    <TableRow>
                                        <TableCell align='center' className={classes.tabHead}>Attribute Name <span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center' className={classes.tabHead}>Attribute Value<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center' className={classes.tabHead} style={{ width: '160px' }}>Option<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center' className={classes.tabHead} style={{ width: '160px' }}>Condition ID<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                        <TableCell align='center' className={classes.tabHead} style={{ width: '100px' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }} >
                                    {(() => {
                                        let td = [];
                                        for (let i = 1; i <= addeligiblity; i++) {
                                            td.push(<>
                                                <StyledTableRow>
                                                    <TableCell>
                                                        <Select
                                                            value={formData[i - 1] && formData[i - 1]['attribute_name'] ? formData[i - 1]['attribute_name'] : ''}
                                                            onChange={(event) => handleChangeValue('attribute_name', i, event.target.value)}
                                                            style={{ width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            {attributeNameArray
                                                                .map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                            {item.replace(/_/g, ' ')}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <ChipInput
                                                            classes={{
                                                                root: classes.rootContainer,
                                                                chip: classes.chip,
                                                                input: classes.input,
                                                                inputRoot: classes.inputRoot,
                                                                label: classes.chipLabel,
                                                                chipContainer: classes.chipContainer,
                                                            }}
                                                            value={formData[i - 1] && formData[i - 1]['attribute_value'] ? formData[i - 1]['attribute_value'] : ''}
                                                            onAdd={(chips) => handleChangeValue('attribute_value', i, chips)}
                                                            disableUnderline={true}
                                                        />

                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            value={formData[i - 1] && formData[i - 1]['option'] ? formData[i - 1]['option'] : ''}
                                                            onChange={(event) => handleChangeValue('option', i, event.target.value)}
                                                            style={{ width: '100%', marginTop: 10 }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            {optionData
                                                                .map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item} key={index}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <OutlinedInput
                                                            value={formData[i - 1] && formData[i - 1]['condition_id'] != 0 ? formData[i - 1]['condition_id'] : ''}
                                                            style={{ width: '100%', marginTop: 10 }}
                                                            inputProps={{ min: "0", step: "1" }}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(event) => handleChangeValue('condition_id', i, parseInt(event.target.value))}
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        <IconButton
                                                            style={{ visibility: i > 1 ? 'visible' : 'hidden', padding: 0 }}
                                                            onClick={() => handleDeleteLocally(i)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForever />
                                                        </IconButton>
                                                        <IconButton
                                                            style={{ visibility: i == addeligiblity ? 'visible' : 'hidden', padding: 0 }}
                                                            onClick={handleOnAdd}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <AddBoxOutlined />
                                                        </IconButton>

                                                    </TableCell>
                                                </StyledTableRow>

                                            </>);
                                        }
                                        return td;
                                    })()}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                            disabled={applicationType && sourceDataType && startDate && endDate && postingType && promotionLevel && keyFigureName && validItem ? false : true}>

                            {props.loading ?
                                <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                :
                                'Run'
                            }
                        </Button>
                    </div>
                </Grid>


            </div >
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        attributeData: state.addMultipleConfigurationData.attributeNameData,
        dropdownKeyFigureData: state.addMultipleConfigurationData.keyFigureNameData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        loading: state.promotionData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createPromotionPlan(data)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps()),
        getAttributeData: (attribute, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(attribute, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
        getKeyfigureData: (applicationType, sourceDataType, field) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', field)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionForm);