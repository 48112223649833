import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    TableRow,
    OutlinedInput,
    FormLabel
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { editMaterialMasterXRefUser, getMaterialMasterXRefDetails, getDefaultValuesMaterialXRef } from '../../redux/actions';
import { connect } from 'react-redux';
import { DropdownArrayForMaster } from '../../components/Inputs';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
}));

const EditMaterialMasterXMaster = props => {
    const classes = useStyles();
    const [channelPartnerTypeArray, setChannelPartnerTypeArray] = React.useState([]);
    const [materialStore, setMaterialStore] = React.useState({});
    const [channelPartnerType, setChannelPartnerType] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerMaterialId, setChannelPartnerMaterialId] = React.useState('');
    const [channelPartnerUoM, setChannelPartnerUoM] = React.useState('');
    const [imaMaterialId, setImaMaterialId] = React.useState('');
    const [imaUoM, setImaUoM] = React.useState('');
    const [materialDescription, setMaterialDescription] = React.useState('');
    const [channelPartnerName, setChannelPartnerName] = React.useState('');
    const channelPartnerTypeRef = useRef(null);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getMaterialMasterXRefDetails(appId);
    }, []);
    const contractCustomisationUpdation = useRef();
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'channel_partner_type') {
                            setChannelPartnerTypeArray(item);
                        }
                    })
                }
            }
        }
    });
    useEffect(() => {
        if (props.materialMasterXRefData) {
            setChannelPartnerType(props.materialMasterXRefData.channel_partner_type);
            setChannelPartnerId(props.materialMasterXRefData.channel_partner_id);
            setChannelPartnerMaterialId(props.materialMasterXRefData.channel_partner_material_id);
            setChannelPartnerUoM(props.materialMasterXRefData.channel_partner_uom);
            setImaMaterialId(props.materialMasterXRefData.ima_material_id);
            setImaUoM(props.materialMasterXRefData.ima_uom);
            setMaterialDescription(props.materialMasterXRefData.material_description);
            setChannelPartnerName(props.materialMasterXRefData.channel_partner_name);
        }
    }, [props.materialMasterXRefData]);
    function handleChannelPartnerType(event) {
        setChannelPartnerType(event);
    }
    function handleChannelPartnerId(event) {
        setChannelPartnerId(event.target.value);
    }
    function handleChannelPartnerMaterialId(event) {
        setChannelPartnerMaterialId(event.target.value);
    }
    function handleChannelPartnerUoM(event) {
        setChannelPartnerUoM(event.target.value);
    }
    function handleImaUom(event) {
        setImaUoM(event.target.value);
    }
    function handleImaMaterialId(event) {
        setImaMaterialId(event.target.value);
    }
    function handleMaterialDescription(event) {
        setMaterialDescription(event.target.value);
    }
    function handleChannelPartnerName(event) {
        setChannelPartnerName(event.target.value);
    }
    function handleClear() {
        setChannelPartnerType('');
        setChannelPartnerId('');
        setChannelPartnerMaterialId('');
        setChannelPartnerUoM('');
        setImaUoM('');
        setImaMaterialId('');
        setMaterialDescription('');
        setChannelPartnerName('');
        channelPartnerTypeRef.current.reset();
    }
    function handleOnSubmit() {
        var data = {
            "channel_partner_type": channelPartnerType,
            "channel_partner_id": channelPartnerId,
            "channel_partner_material_id": channelPartnerMaterialId,
            "channel_partner_uom": channelPartnerUoM,
            "ima_uom": imaUoM,
            "ima_material_id": imaMaterialId,
            "material_description": materialDescription,
            "channel_partner_name": channelPartnerName,
        };
        props.onSubmit(data);
    }
    function handleOnClear() {
        setMaterialStore({});
        handleClear();
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/material-x-master'
                >
                    Material Master XREF
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Material XREF</Typography>
            </Breadcrumbs>
            {props.materialMasterXRefData &&
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    {/* <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                Channel Partner Type
                                        </FormLabel>
                                            <DropdownArrayForMaster placeholder={'Select type'} twoline='true' onChange={handleChannelPartnerType} data={channelPartnerTypeArray.drop_down_values} prevalue={props.materialMasterXRefData.channel_partner_type} ref={channelPartnerTypeRef} />
                                        </div>
                                    </Grid> */}
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Channel Partner ID
                                            </FormLabel>
                                            <OutlinedInput
                                                value={channelPartnerId}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleChannelPartnerId} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Channel Partner Material ID
                                            </FormLabel>
                                            <OutlinedInput
                                                value={channelPartnerMaterialId}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleChannelPartnerMaterialId} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Channel Partner UoM
                                            </FormLabel>
                                            <OutlinedInput
                                                value={channelPartnerUoM}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleChannelPartnerUoM} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                IMA Material ID
                                            </FormLabel>
                                            <OutlinedInput
                                                value={imaMaterialId}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleImaMaterialId} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                IMA UoM
                                            </FormLabel>
                                            <OutlinedInput
                                                value={imaUoM}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleImaUom} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} >
                                                Material Description
                                            </FormLabel>
                                            <OutlinedInput
                                                value={materialDescription}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleMaterialDescription} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} >
                                                Channel Partner Name
                                            </FormLabel>
                                            <OutlinedInput
                                                value={channelPartnerName}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleChannelPartnerName} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                            disabled={channelPartnerId && channelPartnerMaterialId && channelPartnerUoM &&
                                imaMaterialId && imaUoM ? false : true
                            }
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Submit'
                            }
                        </Button>
                    </div>
                </div >
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        materialMasterXRefData: state.customerData.materialMasterXRefViewData,
        dropdownData: state.customerData.dropdownDataMaterialXRef,
        loadingAPI: state.customerData.loading
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(editMaterialMasterXRefUser(data, editableId)),
        getMaterialMasterXRefDetails: (id) => dispatch(getMaterialMasterXRefDetails(id)),
        onLoadingDefault: () => dispatch(getDefaultValuesMaterialXRef())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMaterialMasterXMaster);