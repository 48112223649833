import React from 'react';
import { SvgIcon } from '@mui/material';

const ContractIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M13.854 18.9999C11.0166 18.9999 8.70807 16.6913 8.70807 13.854C8.70807 11.0166 11.0166 8.70801 13.854 8.70801C16.6914 8.70801 19 11.0166 19 13.854C19 16.6913 16.6914 18.9999 13.854 18.9999ZM13.854 9.89588C11.6713 9.89588 9.89557 11.6716 9.89557 13.8543C9.89557 16.0371 11.6716 17.8124 13.854 17.8124C16.0364 17.8124 17.8125 16.0367 17.8125 13.854C17.8125 11.6712 16.0368 9.89588 13.854 9.89588Z" fill='#B4BDE9'  className={props.className} />
    <path d="M13.8541 16.625C13.5264 16.625 13.2603 16.359 13.2603 16.0313V11.6772C13.2603 11.3496 13.5264 11.0835 13.8541 11.0835C14.1817 11.0835 14.4478 11.3496 14.4478 11.6772V16.0313C14.4478 16.359 14.1821 16.625 13.8541 16.625Z"fill='#B4BDE9'  className={props.className} />
    <path d="M16.0312 14.4478H11.6772C11.3495 14.4478 11.0834 14.1817 11.0834 13.854C11.0834 13.5263 11.3495 13.2603 11.6772 13.2603H16.0312C16.3589 13.2603 16.625 13.5263 16.625 13.854C16.625 14.1817 16.3589 14.4478 16.0312 14.4478Z" fill='#B4BDE9'  className={props.className} />
    <path d="M7.27529 16.625H2.17721C0.975977 16.625 0 15.649 0 14.4478V2.17721C0 0.975977 0.975977 0 2.17721 0H11.2813C12.4821 0 13.4585 0.975977 13.4585 2.17721V6.99846C13.4585 7.32613 13.1924 7.59221 12.8647 7.59221C12.537 7.59221 12.271 7.32613 12.271 6.99846V2.17721C12.271 1.6317 11.8268 1.1875 11.2813 1.1875H2.17721C1.6317 1.1875 1.1875 1.6317 1.1875 2.17721V14.4482C1.1875 14.9933 1.6317 15.4375 2.17721 15.4375H7.27566C7.60334 15.4375 7.86941 15.7036 7.86941 16.0312C7.86904 16.3589 7.60334 16.625 7.27529 16.625Z" fill='#B4BDE9'  className={props.className} />
    <path d="M10.4897 7.521H2.96875C2.64107 7.521 2.375 7.25492 2.375 6.92725C2.375 6.59957 2.64107 6.3335 2.96875 6.3335H10.4897C10.8174 6.3335 11.0835 6.59957 11.0835 6.92725C11.0835 7.25492 10.8174 7.521 10.4897 7.521Z" fill='#B4BDE9'  className={props.className} />
    <path d="M7.32279 10.6875H2.96875C2.64107 10.6875 2.375 10.4214 2.375 10.0938C2.375 9.76607 2.64107 9.5 2.96875 9.5H7.32279C7.65047 9.5 7.91654 9.76607 7.91654 10.0938C7.91654 10.4214 7.65084 10.6875 7.32279 10.6875Z" fill='#B4BDE9'  className={props.className} />
    <path d="M6.53125 4.354H2.96875C2.64107 4.354 2.375 4.08793 2.375 3.76025C2.375 3.43258 2.64107 3.1665 2.96875 3.1665H6.53125C6.85893 3.1665 7.125 3.43258 7.125 3.76025C7.125 4.08793 6.85893 4.354 6.53125 4.354Z" fill='#B4BDE9'  className={props.className} />
  </SvgIcon>
)
export default ContractIcon;
