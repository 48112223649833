import React, { useEffect } from 'react';
import '../../../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadValuesBasedOnAppTypeAndField } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Chart } from "react-google-charts";


const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    // rootDiv: {
    //     backgroundColor: theme.palette.white,
    //     borderRadius: 10,
    //     margin: '16px 0px 16px 0px'
    // }
}));
const AccrualCharts = (props) => {
    const classes = useStyles();
    const [paymentCalculationSchemaAccrualData, setPaymentCalculationSchemaAccrualData] = React.useState([]);
    const [paymentContractTypeAccrualData, setPaymentContractTypeAccrualData] = React.useState([]);
    const [paymentContractGroupAccrualData, setPaymentContractGroupAccrualData] = React.useState([]);
    const [paymentContractSubGroupAccrualData, setPaymentContractSubGroupAccrualData] = React.useState([]);

    useEffect(() => {
        const months = [];
        var i;
        var label = ['Month', 'Accrual', 'Payment'];
        var accrualData = props.accrualPaymentGraphData.accrual_result;
        var paymentData = props.accrualPaymentGraphData.payment_result;
        const dateEnd = Moment();
        const dateStart = Moment().subtract(11, 'month');
        while (dateEnd.diff(dateStart, 'months') >= 0) {
            months.push([dateStart.format('MMM YYYY')])
            dateStart.add(1, 'month')
        }
        months.forEach(e => {
            e.push(0, 0)
        })
        months.unshift(label);
        if (props.accrualPaymentGraphData &&
            props.accrualPaymentGraphData.accrual_result && props.accrualPaymentGraphData.accrual_result.length > 0
        ) {
            for (i = 0; i < 12; i++) {
                accrualData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][1] = e.Total
                    }
                })
                paymentData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][2] = e.Total
                    }
                })
            }
        }
    }, [props.accrualPaymentGraphData]);

    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
    }, []);

    //accrual
    useEffect(() => {
        if (props.paymentGraphCalculationSchemaAccrualSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphCalculationSchemaAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentCalculationSchemaAccrualData(newArray);
        }

    }, [props.paymentGraphCalculationSchemaAccrualSuccessData]);
    useEffect(() => {
        if (props.paymentGraphContractGroupAccrualSuccessData) {
            var label = ['Contract group', 'Percentage'];
            var output = props.paymentGraphContractGroupAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractGroupAccrualData(newArray);
        }
    }, [props.paymentGraphContractGroupAccrualSuccessData]);

    useEffect(() => {
        if (props.paymentGraphContractSubGroupAccrualSuccessData) {
            var label = ['Contract sub group', 'Percentage'];
            var output = props.paymentGraphContractSubGroupAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractSubGroupAccrualData(newArray);
        }
    }, [props.paymentGraphContractSubGroupAccrualSuccessData]);

    useEffect(() => {
        if (props.paymentGraphContractTypeAccrualSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphContractTypeAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractTypeAccrualData(newArray);
        }
    }, [props.paymentGraphContractTypeAccrualSuccessData]);

    return (
        <Grid container className={classes.root}>
            {props.show ? (
                <>
                    {
                        props.data === 'Contract Group' && <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                        >
                            <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Contract Group</Typography>
                            {paymentContractGroupAccrualData.length > 1 &&
                                < div style={{ width: '100%' }}>
                                    <Chart
                                        height={400}
                                        width={'100%'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={paymentContractGroupAccrualData}
                                        options={{
                                            pieHole: 0.4,
                                            colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                            legend: { position: 'top', alignment: 'center' },
                                            chartArea: { 'width': '100%', 'height': '80%' },
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            }
                            {(paymentContractGroupAccrualData.length === 1) &&

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', alignItems: 'center', height: "46vh"
                                }}>
                                    <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                                </div>
                            }
                        </Grid>
                    }

                    {
                        props.data === 'Sub Contract Group' && <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                        >
                            <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Sub Contract Group</Typography>
                            {paymentContractSubGroupAccrualData.length > 1 ?
                                <Chart
                                    height={400}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={paymentContractSubGroupAccrualData}
                                    options={{
                                        pieHole: 0.4,
                                        colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                        legend: { position: 'top', alignment: 'center' },
                                        chartArea: { 'width': '100%', 'height': '80%' },
                                    }}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                                :
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', alignItems: 'center', height: "46vh"
                                }}>
                                    <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                                </div>
                            }
                        </Grid>
                    }

                    {
                        props.data === 'Contract Type' && <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                        >
                            <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Contract Type</Typography>
                            {paymentContractTypeAccrualData.length > 1 ?
                                <Chart
                                    height={400}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={paymentContractTypeAccrualData}
                                    options={{
                                        is3D: true,
                                        colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                        legend: { position: 'top', alignment: 'center' },
                                        chartArea: { 'width': '100%', 'height': '80%' },
                                    }}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                                :
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', alignItems: 'center', height: "46vh"
                                }}>
                                    <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                                </div>
                            }
                        </Grid>
                    }

                    {
                        props.data === 'Calculation Method' && <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                        >
                            <Typography variant="h1" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" >Calculation Method</Typography>
                            {paymentCalculationSchemaAccrualData.length > 1 ?
                                <Chart
                                    height={400}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={paymentCalculationSchemaAccrualData}
                                    options={{
                                        is3D: true,
                                        colors: ['#449724', '#329AF7', '#F3993E', '#DC3A2F', '#7401DF',],
                                        legend: { position: 'top', alignment: 'center' },
                                        chartArea: { 'width': '100%', 'height': '80%' },
                                    }}
                                    rootProps={{ 'data-testid': '2' }}
                                />
                                :
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', alignItems: 'center', height: "46vh"
                                }}>
                                    <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                                </div>
                            }
                        </Grid>
                    }
                </>
            ) : (
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', alignItems: 'center', height: "55vh"
                    }}>
                        <Typography variant="h1" style={{ textAlign: 'center' }} color="primary" >No Data to Display !</Typography>
                    </div>
                </Grid>
            )}
        </Grid >
    );
};

const mapStateToProps = state => {
    return {
        accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
        paymentGraphContractTypeAccrualSuccessData: state.initialData.paymentGraphContractTypeSuccessData,
        paymentGraphCalculationSchemaAccrualSuccessData: state.initialData.paymentGraphCalculationSchemaSuccessData,
        paymentGraphContractGroupAccrualSuccessData: state.initialData.paymentGraphContractGroupSuccessData,
        paymentGraphContractSubGroupAccrualSuccessData: state.initialData.paymentGraphContractSubGroupSuccessData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccrualCharts);