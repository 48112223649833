import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { createExternalFormula, getExternalFormulaSingleItem, getLblDispDesFieldValue, getEmployeeData, getDefaultValues, getCustomerMasterData, getMaterialMasterData, getSupplierMasterData, getFormulasList } from '../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import MasterDataComponent from '../../../components/Inputs/FilledComponents/MasterDataComponent';
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from 'react-spinners';
import { Datepicker, SearchDropDownMasterDataNew } from '../../../components/Inputs'

var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px',
        height: '100%'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 4,
        height: 37,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        marginTop: '1.6rem'
    },
    spinner: {
        height: "100vh",
    },
}));

const EditProductGroup = props => {
    const classes = useStyles();
    const [editID, setEditID] = React.useState(0);
    const [externalFields, setExternalFields] = React.useState([]);
    const [externalData, setExternalData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);
    const [formulaNameArray, setFormualNameArray] = React.useState([]);
    const attributesSortFields = [
        "accrual_credit_profit_center",
        "accrual_debit_profit_center",
        "accrual_document_type",
        "accrual_gl_account_credit",
        "accrual_gl_account_debit",
        "currency",
        "payment_document_type",
        "payment_terms",
        "customer_classification",
        "class_of_trade",
        "account_group",
        "customer_chain",
        "customer_group",
        "customer_group1",
        "customer_group2",
        "customer_group3",
        "customer_group4",
        "customer_group5",
        "customer_industry",
        "material_type",
        "base_uom",
        "external_material_group",
        "product_hierarchy",
        "material_group",
        "material_group1",
        "material_group2",
        "material_group3",
        "material_group4",
        "material_group5",
        "supplier_type",
        "supplier_industry",
        "billed_uom",
        "billing_type",
        "company_code",
        "controlling_area",
        "cost_center",
        "delivery_type",
        "distribution_channel",
        "division",
        "document_category",
        "item_category",
        "order_reason",
        "plant",
        "profit_center",
        "sales_district",
        "sales_group",
        "sales_office",
        "sales_order_type",
        "sales_org",
        "contract_owner",
        "customer_id_type",
        "customer_type",
        "channel_partner_type",
        "supplier_id_type",
        "material_id_type",
        "price_list",
        "action_code",
        "member_status",
        "current_tier_status",
        "accounts_type",
        "price_group",
        "exception_reason"
    ];
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        setEditID(appId)
        if (appId)
            props.quotaSingleItem(appId);
        var data = { "data_source": "external_formula_results" };
        props.loadListControlData(data, 'post');
        props.getFormulasList(1, 0);
    }, []);
    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_details)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    if (item.mandatory || item.sort_details) {
                        newArray.push(item)
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setExternalFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.tableData])
    useEffect(() => {

        if (props.quotaData) {
            setEditID(props.quotaData.ID)
            setExternalData(props.quotaData)
        }
    }, [props.quotaData])
    useEffect(() => {
        let newArray = []
        if (props.formulaData && props.formulaData.records && props.formulaData.records.length > 0) {
            props.formulaData.records.map(item => {
                if (item.source_data_type === 'External') {
                    newArray.push(item.formula_name)
                }
            })
            setFormualNameArray(newArray)
        }
    }, [props.formulaData])

    function handleSubmit() {
        if (externalData['start_date'])
            externalData['start_date'] = Moment.utc(externalData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        if (externalData['end_date'])
            externalData['end_date'] = Moment.utc(externalData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")


        props.onSubmitEdit(externalData, editID)
        handleClearAll();
    }

    function handleClearAll() {
        setExternalData({})
    }
    const handleExternalData = (value, key, type) => {
        if (type == 'reference' && value) {
            value = value
        }
        else {
            if (key == 'formula_result' && value)
                value = parseFloat(value)
        }

        setExternalData({ ...externalData, [key]: value })
    }

    return (
        <LoadingOverlay
            active={props.loadingAPI}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<HashLoader />}
            className={classes.spinner} >
            <div className={classes.root}>
                <Typography color="primary" variant='h1'>Edit External Formula </Typography>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >

                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    {externalFields && externalFields.length > 0 &&
                                        externalFields.map((item, index) => (

                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} required={requiredField.includes(item.current)}>
                                                        {item.current}
                                                    </FormLabel>
                                                    {item.key == 'start_date' || item.key == 'end_date' ?
                                                        <Datepicker
                                                            twoline='true' {...props} onChange={date => handleExternalData(date, item.key)}
                                                            minimum={item.key == 'end_date' && externalData['start_date'] ? externalData['start_date'] : ''}
                                                            prevalue={externalData[item.key] ? Moment(externalData[item.key]) : null} />

                                                        :
                                                        item.key == 'material_number' || item.key == 'customer_number' || item.key == 'supplier_number' || item.key == 'employee_number' ?
                                                            <div style={{ marginTop: "-4.5px" }}>
                                                                <SearchDropDownMasterDataNew id='item.key'
                                                                    prevalue={externalData[item.key] ? externalData[item.key] : ''}
                                                                    table={
                                                                        item.key === "material_number"
                                                                            ? "material_master"
                                                                            : item.key === "customer_number"
                                                                                ? "customer_master"
                                                                                : item.key === "employee_number"
                                                                                    ? "employee_master"
                                                                                    : item.key === "supplier_number"
                                                                                        ? "supplier_master"
                                                                                        : ""
                                                                    }
                                                                    keyName={item.key}
                                                                    description={true} //heading='Contract Number'
                                                                    onChange={(e) =>
                                                                        handleExternalData(e, item.key)
                                                                    }
                                                                />
                                                            </div>

                                                            :
                                                            attributesSortFields.includes(item.key) ?
                                                                <div style={{ marginTop: "-5px" }}>
                                                                    <SearchDropDownMasterDataNew id={item.key}
                                                                        table={'attributes_masters'}
                                                                        keyName={item.key}
                                                                        description={true}
                                                                        onChange={(e) =>
                                                                            handleExternalData(e, item.key)
                                                                        }
                                                                        prevalue={externalData[item.key] ? externalData[item.key] : ''}
                                                                    />
                                                                </div>

                                                                :
                                                                item.drop_down_values && item.drop_down_values.length > 0 ?
                                                                    <Select
                                                                        value={externalData[item.key] ? externalData[item.key] : ''}
                                                                        onChange={(e) => handleExternalData(e.target.value, item.key)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        style={{ marginTop: 4 }}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >   {item.drop_down_values.map((item, index) => {
                                                                        return (
                                                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                {item}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                    </Select>
                                                                    :
                                                                    item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                                        <Select
                                                                            value={externalData[item.key] ? externalData[item.key] : ''}
                                                                            onChange={(e) => handleExternalData(e.target.value, item.key, 'reference')}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            style={{ marginTop: 4 }}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >   {item.drop_down_reference_value ? (
                                                                            item.drop_down_reference_value
                                                                                .filter(item => formulaNameArray.includes(item.key))
                                                                                .map((item, index) => (
                                                                                    <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                        {item.key}
                                                                                    </MenuItem>
                                                                                ))
                                                                        ) : (
                                                                            item.drop_down_reference_value.map((item, index) => (
                                                                                <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                    {item.key}
                                                                                </MenuItem>
                                                                            ))
                                                                        )}
                                                                        </Select>
                                                                        // <div style={{ marginTop: -10 }}>
                                                                        //     <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key}
                                                                        //         attributeList={item.drop_down_reference_value ? item.drop_down_reference_value : []}
                                                                        //         onChange={(value) => handleExternalData(value, item.key, 'reference')}
                                                                        //         prevalue={{ 'key': externalData[item.key] ? externalData[item.key] : '', 'desc': '' }}
                                                                        //     />
                                                                        // </div>
                                                                        :
                                                                        <OutlinedInput
                                                                            value={externalData[item.key] ? externalData[item.key] : ''}
                                                                            type={item.key == 'formula_result' ? 'Number' : 'String'}
                                                                            classes={{ root: classes.inputTwoLine }}
                                                                            onChange={(e) => handleExternalData(e.target.value, item.key)} />
                                                    }
                                                </div>
                                            </Grid>

                                        ))
                                    }
                                </Grid>
                            </div>
                        </form>
                    </Card>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                            disabled={Object.entries(externalData).filter(([k, v]) => allowedField.includes(k) && v).length == allowedField.length ? false : true}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Save'
                            }
                        </Button>
                    </div>
                </div >
            </div>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
        quotaData: state.externalFormulaResultsData.externalFormulaSingleData,
        quotaLevelData: state.addMultipleConfigurationData.externalFormulaData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        formulaData: state.FormulaLabData.formulaListData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        quotaSingleItem: (id) => dispatch(getExternalFormulaSingleItem(id)),
        onSubmitEdit: (data, editID) => dispatch(createExternalFormula(data, 'edit', editID)),
        loadListControlData: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getFormulasList: (pagination, limit, sourceType) => dispatch(getFormulasList(pagination, limit, sourceType)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProductGroup);